function WEBFullText() {
    return ["WEB", {
        1: {
            1: [
                "In the beginning God created the heavens and the earth.",
                "Now the earth was formless and empty. Darkness was on the surface of the deep. God\u2019s Spirit was hovering over the surface of the waters.",
                "God said, \"Let there be light,\" and there was light.",
                "God saw the light, and saw that it was good. God divided the light from the darkness.",
                "God called the light \"day,\" and the darkness he called \"night.\" There was evening and there was morning, one day.",
                "God said, \"Let there be an expanse in the middle of the waters, and let it divide the waters from the waters.\"",
                "God made the expanse, and divided the waters which were under the expanse from the waters which were above the expanse; and it was so.",
                "God called the expanse \"sky.\" There was evening and there was morning, a second day.",
                "God said, \"Let the waters under the sky be gathered together to one place, and let the dry land appear;\" and it was so.",
                "God called the dry land \"earth,\" and the gathering together of the waters he called \"seas.\" God saw that it was good.",
                "God said, \"Let the earth yield grass, herbs yielding seed, and fruit trees bearing fruit after their kind, with its seed in it, on the earth;\" and it was so.",
                "The earth yielded grass, herbs yielding seed after their kind, and trees bearing fruit, with its seed in it, after their kind; and God saw that it was good.",
                "There was evening and there was morning, a third day.",
                "God said, \"Let there be lights in the expanse of sky to divide the day from the night; and let them be for signs, and for seasons, and for days and years;",
                "and let them be for lights in the expanse of sky to give light on the earth;\" and it was so.",
                "God made the two great lights: the greater light to rule the day, and the lesser light to rule the night. He also made the stars.",
                "God set them in the expanse of sky to give light to the earth,",
                "and to rule over the day and over the night, and to divide the light from the darkness. God saw that it was good.",
                "There was evening and there was morning, a fourth day.",
                "God said, \"Let the waters swarm with swarms of living creatures, and let birds fly above the earth in the open expanse of sky.\"",
                "God created the large sea creatures, and every living creature that moves, with which the waters swarmed, after their kind, and every winged bird after its kind. God saw that it was good.",
                "God blessed them, saying, \"Be fruitful, and multiply, and fill the waters in the seas, and let birds multiply on the earth.\"",
                "There was evening and there was morning, a fifth day.",
                "God said, \"Let the earth produce living creatures after their kind, livestock, creeping things, and animals of the earth after their kind;\" and it was so.",
                "God made the animals of the earth after their kind, and the livestock after their kind, and everything that creeps on the ground after its kind. God saw that it was good.",
                "God said, \"Let us make man in our image, after our likeness: and let them have dominion over the fish of the sea, and over the birds of the sky, and over the livestock, and over all the earth, and over every creeping thing that creeps on the earth.\"",
                "God created man in his own image. In God\u2019s image he created him; male and female he created them.",
                "God blessed them. God said to them, \"Be fruitful, multiply, fill the earth, and subdue it. Have dominion over the fish of the sea, over the birds of the sky, and over every living thing that moves on the earth.\"",
                "God said, \"Behold, I have given you every herb yielding seed, which is on the surface of all the earth, and every tree, which bears fruit yielding seed. It will be your food.",
                "To every animal of the earth, and to every bird of the sky, and to everything that creeps on the earth, in which there is life, I have given every green herb for food;\" and it was so.",
                "God saw everything that he had made, and, behold, it was very good. There was evening and there was morning, a sixth day."
            ],
            2: [
                "The heavens and the earth were finished, and all their vast array.",
                "On the seventh day God finished his work which he had made; and he rested on the seventh day from all his work which he had made.",
                "God blessed the seventh day, and made it holy, because he rested in it from all his work which he had created and made.",
                "This is the history of the generations of the heavens and of the earth when they were created, in the day that Yahweh God made the earth and the heavens.",
                "No plant of the field was yet in the earth, and no herb of the field had yet sprung up; for Yahweh God had not caused it to rain on the earth. There was not a man to till the ground,",
                "but a mist went up from the earth, and watered the whole surface of the ground.",
                "Yahweh God formed man from the dust of the ground, and breathed into his nostrils the breath of life; and man became a living soul.",
                "Yahweh God planted a garden eastward, in Eden, and there he put the man whom he had formed.",
                "Out of the ground Yahweh God made every tree to grow that is pleasant to the sight, and good for food; the tree of life also in the middle of the garden, and the tree of the knowledge of good and evil.",
                "A river went out of Eden to water the garden; and from there it was parted, and became four heads.",
                "The name of the first is Pishon: this is the one which flows through the whole land of Havilah, where there is gold;",
                "and the gold of that land is good. There is aromatic resin and the onyx stone.",
                "The name of the second river is Gihon: the same river that flows through the whole land of Cush.",
                "The name of the third river is Hiddekel: this is the one which flows in front of Assyria. The fourth river is the Euphrates.",
                "Yahweh God took the man, and put him into the garden of Eden to dress it and to keep it.",
                "Yahweh God commanded the man, saying, \"Of every tree of the garden you may freely eat;",
                "but of the tree of the knowledge of good and evil, you shall not eat of it; for in the day that you eat of it you will surely die.\"",
                "Yahweh God said, \"It is not good that the man should be alone; I will make him a helper suitable for him.\"",
                "Out of the ground Yahweh God formed every animal of the field, and every bird of the sky, and brought them to the man to see what he would call them. Whatever the man called every living creature, that was its name.",
                "The man gave names to all livestock, and to the birds of the sky, and to every animal of the field; but for man there was not found a helper suitable for him.",
                "Yahweh God caused a deep sleep to fall on the man, and he slept; and he took one of his ribs, and closed up the flesh in its place.",
                "He made the rib, which Yahweh God had taken from the man, into a woman, and brought her to the man.",
                "The man said, \"This is now bone of my bones, and flesh of my flesh. She will be called \u2018woman,\u2019 because she was taken out of Man.\"",
                "Therefore a man will leave his father and his mother, and will join with his wife, and they will be one flesh.",
                "They were both naked, the man and his wife, and were not ashamed."
            ],
            3: [
                "Now the serpent was more subtle than any animal of the field which Yahweh God had made. He said to the woman, \"Has God really said, \u2018You shall not eat of any tree of the garden?\u2019\"",
                "The woman said to the serpent, \"Of the fruit of the trees of the garden we may eat,",
                "but of the fruit of the tree which is in the middle of the garden, God has said, \u2018You shall not eat of it, neither shall you touch it, lest you die.\u2019\"",
                "The serpent said to the woman, \"You won\u2019t surely die,",
                "for God knows that in the day you eat it, your eyes will be opened, and you will be like God, knowing good and evil.\"",
                "When the woman saw that the tree was good for food, and that it was a delight to the eyes, and that the tree was to be desired to make one wise, she took of its fruit, and ate; and she gave some to her husband with her, and he ate.",
                "The eyes of both of them were opened, and they knew that they were naked. They sewed fig leaves together, and made themselves aprons.",
                "They heard the voice of Yahweh God walking in the garden in the cool of the day, and the man and his wife hid themselves from the presence of Yahweh God among the trees of the garden.",
                "Yahweh God called to the man, and said to him, \"Where are you?\"",
                "The man said, \"I heard your voice in the garden, and I was afraid, because I was naked; and I hid myself.\"",
                "God said, \"Who told you that you were naked? Have you eaten from the tree that I commanded you not to eat from?\"",
                "The man said, \"The woman whom you gave to be with me, she gave me of the tree, and I ate.\"",
                "Yahweh God said to the woman, \"What is this you have done?\" The woman said, \"The serpent deceived me, and I ate.\"",
                "Yahweh God said to the serpent, \"Because you have done this, you are cursed above all livestock, and above every animal of the field. On your belly you shall go, and you shall eat dust all the days of your life.",
                "I will put enmity between you and the woman, and between your offspring and her offspring. He will bruise your head, and you will bruise his heel.\"",
                "To the woman he said, \"I will greatly multiply your pain in childbirth. In pain you will bear children. Your desire will be for your husband, and he will rule over you.\"",
                "To Adam he said, \"Because you have listened to your wife\u2019s voice, and have eaten of the tree, of which I commanded you, saying, \u2018You shall not eat of it,\u2019 cursed is the ground for your sake. In toil you will eat of it all the days of your life.",
                "It will yield thorns and thistles to you; and you will eat the herb of the field.",
                "By the sweat of your face will you eat bread until you return to the ground, for out of it you were taken. For you are dust, and to dust you shall return.\"",
                "The man called his wife Eve, because she was the mother of all living.",
                "Yahweh God made coats of skins for Adam and for his wife, and clothed them.",
                "Yahweh God said, \"Behold, the man has become like one of us, knowing good and evil. Now, lest he reach out his hand, and also take of the tree of life, and eat, and live forever...\"",
                "Therefore Yahweh God sent him out from the garden of Eden, to till the ground from which he was taken.",
                "So he drove out the man; and he placed Cherubs at the east of the garden of Eden, and the flame of a sword which turned every way, to guard the way to the tree of life."
            ],
            4: [
                "The man knew Eve his wife. She conceived, and gave birth to Cain, and said, \"I have gotten a man with Yahweh\u2019s help.\"",
                "Again she gave birth, to Cain\u2019s brother Abel. Abel was a keeper of sheep, but Cain was a tiller of the ground.",
                "As time passed, it happened that Cain brought an offering to Yahweh from the fruit of the ground.",
                "Abel also brought some of the firstborn of his flock and of its fat. Yahweh respected Abel and his offering,",
                "but he didn\u2019t respect Cain and his offering. Cain was very angry, and the expression on his face fell.",
                "Yahweh said to Cain, \"Why are you angry? Why has the expression of your face fallen?",
                "If you do well, will it not be lifted up? If you don\u2019t do well, sin crouches at the door. Its desire is for you, but you are to rule over it.\"",
                "Cain said to Abel, his brother, \"Let\u2019s go into the field.\" It happened when they were in the field, that Cain rose up against Abel, his brother, and killed him.",
                "Yahweh said to Cain, \"Where is Abel, your brother?\" He said, \"I don\u2019t know. Am I my brother\u2019s keeper?\"",
                "Yahweh said, \"What have you done? The voice of your brother\u2019s blood cries to me from the ground.",
                "Now you are cursed because of the ground, which has opened its mouth to receive your brother\u2019s blood from your hand.",
                "From now on, when you till the ground, it won\u2019t yield its strength to you. You shall be a fugitive and a wanderer in the earth.\"",
                "Cain said to Yahweh, \"My punishment is greater than I can bear.",
                "Behold, you have driven me out this day from the surface of the ground. I will be hidden from your face, and I will be a fugitive and a wanderer in the earth. It will happen that whoever finds me will kill me.\"",
                "Yahweh said to him, \"Therefore whoever slays Cain, vengeance will be taken on him sevenfold.\" Yahweh appointed a sign for Cain, lest any finding him should strike him.",
                "Cain went out from Yahweh\u2019s presence, and lived in the land of Nod, east of Eden.",
                "Cain knew his wife. She conceived, and gave birth to Enoch. He built a city, and called the name of the city, after the name of his son, Enoch.",
                "To Enoch was born Irad. Irad became the father of Mehujael. Mehujael became the father of Methushael. Methushael became the father of Lamech.",
                "Lamech took two wives: the name of the one was Adah, and the name of the other Zillah.",
                "Adah gave birth to Jabal, who was the father of those who dwell in tents and have livestock.",
                "His brother\u2019s name was Jubal, who was the father of all who handle the harp and pipe.",
                "Zillah also gave birth to Tubal Cain, the forger of every cutting instrument of brass and iron. Tubal Cain\u2019s sister was Naamah.",
                "Lamech said to his wives, \"Adah and Zillah, hear my voice. You wives of Lamech, listen to my speech, for I have slain a man for wounding me, a young man for bruising me.",
                "If Cain will be avenged seven times, truly Lamech seventy-seven times.\"",
                "Adam knew his wife again. She gave birth to a son, and named him Seth, \"for God has appointed me another child instead of Abel, for Cain killed him.\"",
                "There was also born a son to Seth, and he named him Enosh. Then men began to call on Yahweh\u2019s name."
            ],
            5: [
                "This is the book of the generations of Adam. In the day that God created man, he made him in God\u2019s likeness.",
                "He created them male and female, and blessed them, and called their name \"Adam,\" in the day when they were created.",
                "Adam lived one hundred thirty years, and became the father of a son in his own likeness, after his image, and named him Seth.",
                "The days of Adam after he became the father of Seth were eight hundred years, and he became the father of sons and daughters.",
                "All the days that Adam lived were nine hundred thirty years, then he died.",
                "Seth lived one hundred five years, and became the father of Enosh.",
                "Seth lived after he became the father of Enosh eight hundred seven years, and became the father of sons and daughters.",
                "All the days of Seth were nine hundred twelve years, then he died.",
                "Enosh lived ninety years, and became the father of Kenan.",
                "Enosh lived after he became the father of Kenan, eight hundred fifteen years, and became the father of sons and daughters.",
                "All the days of Enosh were nine hundred five years, then he died.",
                "Kenan lived seventy years, and became the father of Mahalalel.",
                "Kenan lived after he became the father of Mahalalel eight hundred forty years, and became the father of sons and daughters",
                "and all the days of Kenan were nine hundred ten years, then he died.",
                "Mahalalel lived sixty-five years, and became the father of Jared.",
                "Mahalalel lived after he became the father of Jared eight hundred thirty years, and became the father of sons and daughters.",
                "All the days of Mahalalel were eight hundred ninety-five years, then he died.",
                "Jared lived one hundred sixty-two years, and became the father of Enoch.",
                "Jared lived after he became the father of Enoch eight hundred years, and became the father of sons and daughters.",
                "All the days of Jared were nine hundred sixty-two years, then he died.",
                "Enoch lived sixty-five years, and became the father of Methuselah.",
                "Enoch walked with God after he became the father of Methuselah three hundred years, and became the father of sons and daughters.",
                "All the days of Enoch were three hundred sixty-five years.",
                "Enoch walked with God, and he was not, for God took him.",
                "Methuselah lived one hundred eighty-seven years, and became the father of Lamech.",
                "Methuselah lived after he became the father of Lamech seven hundred eighty-two years, and became the father of sons and daughters.",
                "All the days of Methuselah were nine hundred sixty-nine years, then he died.",
                "Lamech lived one hundred eighty-two years, and became the father of a son,",
                "and he named him Noah, saying, \"This same will comfort us in our work and in the toil of our hands, because of the ground which Yahweh has cursed.\"",
                "Lamech lived after he became the father of Noah five hundred ninety-five years, and became the father of sons and daughters.",
                "All the days of Lamech were seven hundred seventy-seven years, then he died.",
                "Noah was five hundred years old, and Noah became the father of Shem, Ham, and Japheth."
            ],
            6: [
                "It happened, when men began to multiply on the surface of the ground, and daughters were born to them,",
                "that God\u2019s sons saw that men\u2019s daughters were beautiful, and they took for themselves wives of all that they chose.",
                "Yahweh said, \"My Spirit will not strive with man forever, because he also is flesh; yet will his days be one hundred twenty years.\"",
                "The Nephilim were in the earth in those days, and also after that, when God\u2019s sons came in to men\u2019s daughters. They bore children to them. Those were the mighty men who were of old, men of renown.",
                "Yahweh saw that the wickedness of man was great in the earth, and that every imagination of the thoughts of his heart was only evil continually.",
                "Yahweh was sorry that he had made man on the earth, and it grieved him in his heart.",
                "Yahweh said, \"I will destroy man whom I have created from the surface of the ground; man, along with animals, creeping things, and birds of the sky; for I am sorry that I have made them.\"",
                "But Noah found favor in Yahweh\u2019s eyes.",
                "This is the history of the generations of Noah. Noah was a righteous man, blameless among the people of his time. Noah walked with God.",
                "Noah became the father of three sons: Shem, Ham, and Japheth.",
                "The earth was corrupt before God, and the earth was filled with violence.",
                "God saw the earth, and saw that it was corrupt, for all flesh had corrupted their way on the earth.",
                "God said to Noah, \"The end of all flesh has come before me, for the earth is filled with violence through them. Behold, I will destroy them with the earth.",
                "Make a ship of gopher wood. You shall make rooms in the ship, and shall seal it inside and outside with pitch.",
                "This is how you shall make it. The length of the ship will be three hundred cubits, its breadth fifty cubits, and its height thirty cubits.",
                "You shall make a roof in the ship, and you shall finish it to a cubit upward. You shall set the door of the ship in its side. You shall make it with lower, second, and third levels.",
                "I, even I, do bring the flood of waters on this earth, to destroy all flesh having the breath of life from under the sky. Everything that is in the earth will die.",
                "But I will establish my covenant with you. You shall come into the ship, you, your sons, your wife, and your sons\u2019 wives with you.",
                "Of every living thing of all flesh, you shall bring two of every sort into the ship, to keep them alive with you. They shall be male and female.",
                "Of the birds after their kind, of the livestock after their kind, of every creeping thing of the ground after its kind, two of every sort shall come to you, to keep them alive.",
                "Take with you of all food that is eaten, and gather it to yourself; and it will be for food for you, and for them.\"",
                "Thus Noah did. According to all that God commanded him, so he did."
            ],
            7: [
                "Yahweh said to Noah, \"Come with all of your household into the ship, for I have seen your righteousness before me in this generation.",
                "You shall take seven pairs of every clean animal with you, the male and his female. Of the animals that are not clean, take two, the male and his female.",
                "Also of the birds of the sky, seven and seven, male and female, to keep seed alive on the surface of all the earth.",
                "In seven days, I will cause it to rain on the earth for forty days and forty nights. Every living thing that I have made, I will destroy from the surface of the ground.\"",
                "Noah did everything that Yahweh commanded him.",
                "Noah was six hundred years old when the flood of waters came on the earth.",
                "Noah went into the ship with his sons, his wife, and his sons\u2019 wives, because of the waters of the flood.",
                "Clean animals, animals that are not clean, birds, and everything that creeps on the ground",
                "went by pairs to Noah into the ship, male and female, as God commanded Noah.",
                "It happened after the seven days, that the waters of the flood came on the earth.",
                "In the six hundredth year of Noah\u2019s life, in the second month, on the seventeenth day of the month, on the same day all the fountains of the great deep were burst open, and the sky\u2019s windows were opened.",
                "The rain was on the earth forty days and forty nights.",
                "In the same day Noah, and Shem, Ham, and Japheth, the sons of Noah, and Noah\u2019s wife, and the three wives of his sons with them, entered into the ship;",
                "they, and every animal after its kind, all the livestock after their kind, every creeping thing that creeps on the earth after its kind, and every bird after its kind, every bird of every sort.",
                "They went to Noah into the ship, by pairs of all flesh with the breath of life in them.",
                "Those who went in, went in male and female of all flesh, as God commanded him; and Yahweh shut him in.",
                "The flood was forty days on the earth. The waters increased, and lifted up the ship, and it was lifted up above the earth.",
                "The waters prevailed, and increased greatly on the earth; and the ship floated on the surface of the waters.",
                "The waters prevailed exceedingly on the earth. All the high mountains that were under the whole sky were covered.",
                "The waters prevailed fifteen cubits upward, and the mountains were covered.",
                "All flesh died that moved on the earth, including birds, livestock, animals, every creeping thing that creeps on the earth, and every man.",
                "All in whose nostrils was the breath of the spirit of life, of all that was on the dry land, died.",
                "Every living thing was destroyed that was on the surface of the ground, including man, livestock, creeping things, and birds of the sky. They were destroyed from the earth. Only Noah was left, and those who were with him in the ship.",
                "The waters prevailed on the earth one hundred fifty days."
            ],
            8: [
                "God remembered Noah, all the animals, and all the livestock that were with him in the ship; and God made a wind to pass over the earth. The waters subsided.",
                "The deep\u2019s fountains and the sky\u2019s windows were also stopped, and the rain from the sky was restrained.",
                "The waters receded from the earth continually. After the end of one hundred fifty days the waters decreased.",
                "The ship rested in the seventh month, on the seventeenth day of the month, on Ararat\u2019s mountains.",
                "The waters receded continually until the tenth month. In the tenth month, on the first day of the month, the tops of the mountains were seen.",
                "It happened at the end of forty days, that Noah opened the window of the ship which he had made,",
                "and he sent out a raven. It went back and forth, until the waters were dried up from the earth.",
                "He sent out a dove from him, to see if the waters were abated from the surface of the ground,",
                "but the dove found no place to rest her foot, and she returned to him into the ship; for the waters were on the surface of the whole earth. He put out his hand, and took her, and brought her to him into the ship.",
                "He stayed yet another seven days; and again he sent the dove out of the ship.",
                "The dove came back to him at evening, and, behold, in her mouth was an olive leaf plucked off. So Noah knew that the waters were abated from the earth.",
                "He stayed yet another seven days, and sent out the dove; and she didn\u2019t return to him any more.",
                "It happened in the six hundred first year, in the first month, the first day of the month, the waters were dried up from the earth. Noah removed the covering of the ship, and looked. He saw that the surface of the ground was dried.",
                "In the second month, on the twenty-seventh day of the month, the earth was dry.",
                "God spoke to Noah, saying,",
                "\"Go out of the ship, you, and your wife, and your sons, and your sons\u2019 wives with you.",
                "Bring out with you every living thing that is with you of all flesh, including birds, livestock, and every creeping thing that creeps on the earth, that they may breed abundantly in the earth, and be fruitful, and multiply on the earth.\"",
                "Noah went out, with his sons, his wife, and his sons\u2019 wives with him.",
                "Every animal, every creeping thing, and every bird, whatever moves on the earth, after their families, went out of the ship.",
                "Noah built an altar to Yahweh, and took of every clean animal, and of every clean bird, and offered burnt offerings on the altar.",
                "Yahweh smelled the pleasant aroma. Yahweh said in his heart, \"I will not again curse the ground any more for man\u2019s sake, because the imagination of man\u2019s heart is evil from his youth; neither will I ever again strike everything living, as I have done.",
                "While the earth remains, seed time and harvest, and cold and heat, and summer and winter, and day and night shall not cease.\""
            ],
            9: [
                "God blessed Noah and his sons, and said to them, \"Be fruitful, and multiply, and replenish the earth.",
                "The fear of you and the dread of you will be on every animal of the earth, and on every bird of the sky. Everything that the ground teems with, and all the fish of the sea are delivered into your hand.",
                "Every moving thing that lives will be food for you. As the green herb, I have given everything to you.",
                "But flesh with its life, its blood, you shall not eat.",
                "I will surely require your blood of your lives. At the hand of every animal I will require it. At the hand of man, even at the hand of every man\u2019s brother, I will require the life of man.",
                "Whoever sheds man\u2019s blood, his blood will be shed by man, for God made man in his own image.",
                "Be fruitful and multiply. Increase abundantly in the earth, and multiply in it.\"",
                "God spoke to Noah and to his sons with him, saying,",
                "\"As for me, behold, I establish my covenant with you, and with your offspring after you,",
                "and with every living creature that is with you: the birds, the livestock, and every animal of the earth with you, of all that go out of the ship, even every animal of the earth.",
                "I will establish my covenant with you: all flesh will not be cut off any more by the waters of the flood, neither will there ever again be a flood to destroy the earth.\"",
                "God said, \"This is the token of the covenant which I make between me and you and every living creature that is with you, for perpetual generations:",
                "I set my rainbow in the cloud, and it will be for a sign of a covenant between me and the earth.",
                "It will happen, when I bring a cloud over the earth, that the rainbow will be seen in the cloud,",
                "and I will remember my covenant, which is between me and you and every living creature of all flesh, and the waters will no more become a flood to destroy all flesh.",
                "The rainbow will be in the cloud. I will look at it, that I may remember the everlasting covenant between God and every living creature of all flesh that is on the earth.\"",
                "God said to Noah, \"This is the token of the covenant which I have established between me and all flesh that is on the earth.\"",
                "The sons of Noah who went out from the ship were Shem, Ham, and Japheth. Ham is the father of Canaan.",
                "These three were the sons of Noah, and from these, the whole earth was populated.",
                "Noah began to be a farmer, and planted a vineyard.",
                "He drank of the wine and got drunk. He was uncovered within his tent.",
                "Ham, the father of Canaan, saw the nakedness of his father, and told his two brothers outside.",
                "Shem and Japheth took a garment, and laid it on both their shoulders, went in backwards, and covered the nakedness of their father. Their faces were backwards, and they didn\u2019t see their father\u2019s nakedness.",
                "Noah awoke from his wine, and knew what his youngest son had done to him.",
                "He said, \"Canaan is cursed. He will be servant of servants to his brothers.\"",
                "He said, \"Blessed be Yahweh, the God of Shem. Let Canaan be his servant.",
                "May God enlarge Japheth. Let him dwell in the tents of Shem. Let Canaan be his servant.\"",
                "Noah lived three hundred fifty years after the flood.",
                "All the days of Noah were nine hundred fifty years, then he died."
            ],
            10: [
                "Now this is the history of the generations of the sons of Noah and of Shem, Ham, and Japheth. Sons were born to them after the flood.",
                "The sons of Japheth: Gomer, Magog, Madai, Javan, Tubal, Meshech, and Tiras.",
                "The sons of Gomer: Ashkenaz, Riphath, and Togarmah.",
                "The sons of Javan: Elishah, Tarshish, Kittim, and Dodanim.",
                "Of these were the islands of the nations divided in their lands, everyone after his language, after their families, in their nations.",
                "The sons of Ham: Cush, Mizraim, Put, and Canaan.",
                "The sons of Cush: Seba, Havilah, Sabtah, Raamah, and Sabteca. The sons of Raamah: Sheba and Dedan.",
                "Cush became the father of Nimrod. He began to be a mighty one in the earth.",
                "He was a mighty hunter before Yahweh. Therefore it is said, \"Like Nimrod, a mighty hunter before Yahweh.\"",
                "The beginning of his kingdom was Babel, Erech, Accad, and Calneh, in the land of Shinar.",
                "Out of that land he went into Assyria, and built Nineveh, Rehoboth Ir, Calah,",
                "and Resen between Nineveh and Calah (the same is the great city).",
                "Mizraim became the father of Ludim, Anamim, Lehabim, Naphtuhim,",
                "Pathrusim, Casluhim (which the Philistines descended from), and Caphtorim.",
                "Canaan became the father of Sidon (his firstborn), Heth,",
                "the Jebusite, the Amorite, the Girgashite,",
                "the Hivite, the Arkite, the Sinite,",
                "the Arvadite, the Zemarite, and the Hamathite. Afterward the families of the Canaanites were spread abroad.",
                "The border of the Canaanites was from Sidon, as you go toward Gerar, to Gaza; as you go toward Sodom, Gomorrah, Admah, and Zeboiim, to Lasha.",
                "These are the sons of Ham, after their families, after their languages, in their lands, in their nations.",
                "To Shem, the father of all the children of Eber, the elder brother of Japheth, to him also were children born.",
                "The sons of Shem: Elam, Asshur, Arpachshad, Lud, and Aram.",
                "The sons of Aram: Uz, Hul, Gether, and Mash.",
                "Arpachshad became the father of Shelah. Shelah became the father of Eber.",
                "To Eber were born two sons. The name of the one was Peleg, for in his days the earth was divided. His brother\u2019s name was Joktan.",
                "Joktan became the father of Almodad, Sheleph, Hazarmaveth, Jerah,",
                "Hadoram, Uzal, Diklah,",
                "Obal, Abimael, Sheba,",
                "Ophir, Havilah, and Jobab. All these were the sons of Joktan.",
                "Their dwelling was from Mesha, as you go toward Sephar, the mountain of the east.",
                "These are the sons of Shem, after their families, after their languages, in their lands, after their nations.",
                "These are the families of the sons of Noah, after their generations, in their nations. Of these were the nations divided in the earth after the flood."
            ],
            11: [
                "The whole earth was of one language and of one speech.",
                "It happened, as they traveled east, that they found a plain in the land of Shinar, and they lived there.",
                "They said one to another, \"Come, let\u2019s make bricks, and burn them thoroughly.\" They had brick for stone, and they used tar for mortar.",
                "They said, \"Come, let\u2019s build ourselves a city, and a tower whose top reaches to the sky, and let\u2019s make ourselves a name, lest we be scattered abroad on the surface of the whole earth.\"",
                "Yahweh came down to see the city and the tower, which the children of men built.",
                "Yahweh said, \"Behold, they are one people, and they have all one language, and this is what they begin to do. Now nothing will be withheld from them, which they intend to do.",
                "Come, let\u2019s go down, and there confuse their language, that they may not understand one another\u2019s speech.\"",
                "So Yahweh scattered them abroad from there on the surface of all the earth. They stopped building the city.",
                "Therefore its name was called Babel, because there Yahweh confused the language of all the earth. From there, Yahweh scattered them abroad on the surface of all the earth.",
                "This is the history of the generations of Shem. Shem was one hundred years old and became the father of Arpachshad two years after the flood.",
                "Shem lived five hundred years after he became the father of Arpachshad, and became the father of sons and daughters.",
                "Arpachshad lived thirty-five years and became the father of Shelah.",
                "Arpachshad lived four hundred three years after he became the father of Shelah, and became the father of sons and daughters.",
                "Shelah lived thirty years, and became the father of Eber:",
                "and Shelah lived four hundred three years after he became the father of Eber, and became the father of sons and daughters.",
                "Eber lived thirty-four years, and became the father of Peleg.",
                "Eber lived four hundred thirty years after he became the father of Peleg, and became the father of sons and daughters.",
                "Peleg lived thirty years, and became the father of Reu.",
                "Peleg lived two hundred nine years after he became the father of Reu, and became the father of sons and daughters.",
                "Reu lived thirty-two years, and became the father of Serug.",
                "Reu lived two hundred seven years after he became the father of Serug, and became the father of sons and daughters.",
                "Serug lived thirty years, and became the father of Nahor.",
                "Serug lived two hundred years after he became the father of Nahor, and became the father of sons and daughters.",
                "Nahor lived twenty-nine years, and became the father of Terah.",
                "Nahor lived one hundred nineteen years after he became the father of Terah, and became the father of sons and daughters.",
                "Terah lived seventy years, and became the father of Abram, Nahor, and Haran.",
                "Now this is the history of the generations of Terah. Terah became the father of Abram, Nahor, and Haran. Haran became the father of Lot.",
                "Haran died before his father Terah in the land of his birth, in Ur of the Chaldees.",
                "Abram and Nahor took wives. The name of Abram\u2019s wife was Sarai, and the name of Nahor\u2019s wife, Milcah, the daughter of Haran who was also the father of Iscah.",
                "Sarai was barren. She had no child.",
                "Terah took Abram his son, Lot the son of Haran, his son\u2019s son, and Sarai his daughter-in-law, his son Abram\u2019s wife. They went from Ur of the Chaldees, to go into the land of Canaan. They came to Haran and lived there.",
                "The days of Terah were two hundred five years. Terah died in Haran."
            ],
            12: [
                "Now Yahweh said to Abram, \"Get out of your country, and from your relatives, and from your father\u2019s house, to the land that I will show you.",
                "I will make of you a great nation. I will bless you and make your name great. You will be a blessing.",
                "I will bless those who bless you, and I will curse him who curses you. All of the families of the earth will be blessed in you.\"",
                "So Abram went, as Yahweh had spoken to him. Lot went with him. Abram was seventy-five years old when he departed out of Haran.",
                "Abram took Sarai his wife, Lot his brother\u2019s son, all their substance that they had gathered, and the souls whom they had gotten in Haran, and they went to go into the land of Canaan. Into the land of Canaan they came.",
                "Abram passed through the land to the place of Shechem, to the oak of Moreh. The Canaanite was then in the land.",
                "Yahweh appeared to Abram and said, \"I will give this land to your seed.\" He built an altar there to Yahweh, who appeared to him.",
                "He left from there to the mountain on the east of Bethel, and pitched his tent, having Bethel on the west, and Ai on the east. There he built an altar to Yahweh and called on the name of Yahweh.",
                "Abram traveled, going on still toward the South.",
                "There was a famine in the land. Abram went down into Egypt to live as a foreigner there, for the famine was severe in the land.",
                "It happened, when he had come near to enter Egypt, that he said to Sarai his wife, \"See now, I know that you are a beautiful woman to look at.",
                "It will happen, when the Egyptians will see you, that they will say, \u2018This is his wife.\u2019 They will kill me, but they will save you alive.",
                "Please say that you are my sister, that it may be well with me for your sake, and that my soul may live because of you.\"",
                "It happened that when Abram had come into Egypt, the Egyptians saw that the woman was very beautiful.",
                "The princes of Pharaoh saw her, and praised her to Pharaoh; and the woman was taken into Pharaoh\u2019s house.",
                "He dealt well with Abram for her sake. He had sheep, cattle, male donkeys, male servants, female servants, female donkeys, and camels.",
                "Yahweh plagued Pharaoh and his house with great plagues because of Sarai, Abram\u2019s wife.",
                "Pharaoh called Abram and said, \"What is this that you have done to me? Why didn\u2019t you tell me that she was your wife?",
                "Why did you say, \u2018She is my sister,\u2019 so that I took her to be my wife? Now therefore, see your wife, take her, and go your way.\"",
                "Pharaoh commanded men concerning him, and they brought him on the way with his wife and all that he had."
            ],
            13: [
                "Abram went up out of Egypt: he, his wife, all that he had, and Lot with him, into the South.",
                "Abram was very rich in livestock, in silver, and in gold.",
                "He went on his journeys from the South even to Bethel, to the place where his tent had been at the beginning, between Bethel and Ai,",
                "to the place of the altar, which he had made there at the first. There Abram called on the name of Yahweh.",
                "Lot also, who went with Abram, had flocks, and herds, and tents.",
                "The land was not able to bear them, that they might live together: for their substance was great, so that they could not live together.",
                "There was a strife between the herdsmen of Abram\u2019s livestock and the herdsmen of Lot\u2019s livestock: and the Canaanite and the Perizzite lived in the land at that time.",
                "Abram said to Lot, \"Please, let there be no strife between me and you, and between my herdsmen and your herdsmen; for we are relatives.",
                "Isn\u2019t the whole land before you? Please separate yourself from me. If you go to the left hand, then I will go to the right. Or if you go to the right hand, then I will go to the left.\"",
                "Lot lifted up his eyes, and saw all the plain of the Jordan, that it was well-watered everywhere, before Yahweh destroyed Sodom and Gomorrah, like the garden of Yahweh, like the land of Egypt, as you go to Zoar.",
                "So Lot chose the Plain of the Jordan for himself. Lot traveled east, and they separated themselves the one from the other.",
                "Abram lived in the land of Canaan, and Lot lived in the cities of the plain, and moved his tent as far as Sodom.",
                "Now the men of Sodom were exceedingly wicked and sinners against Yahweh.",
                "Yahweh said to Abram, after Lot was separated from him, \"Now, lift up your eyes, and look from the place where you are, northward and southward and eastward and westward,",
                "for all the land which you see, I will give to you, and to your offspring forever.",
                "I will make your offspring as the dust of the earth, so that if a man can number the dust of the earth, then your seed may also be numbered.",
                "Arise, walk through the land in its length and in its breadth; for I will give it to you.\"",
                "Abram moved his tent, and came and lived by the oaks of Mamre, which are in Hebron, and built an altar there to Yahweh."
            ],
            14: [
                "It happened in the days of Amraphel, king of Shinar, Arioch, king of Ellasar, Chedorlaomer, king of Elam, and Tidal, king of Goiim,",
                "that they made war with Bera, king of Sodom, and with Birsha, king of Gomorrah, Shinab, king of Admah, and Shemeber, king of Zeboiim, and the king of Bela (the same is Zoar).",
                "All these joined together in the valley of Siddim (the same is the Salt Sea).",
                "Twelve years they served Chedorlaomer, and in the thirteenth year, they rebelled.",
                "In the fourteenth year Chedorlaomer came, and the kings who were with him, and struck the Rephaim in Ashteroth Karnaim, and the Zuzim in Ham, and the Emim in Shaveh Kiriathaim,",
                "and the Horites in their Mount Seir, to Elparan, which is by the wilderness.",
                "They returned, and came to En Mishpat (the same is Kadesh), and struck all the country of the Amalekites, and also the Amorites, that lived in Hazazon Tamar.",
                "The king of Sodom, and the king of Gomorrah, and the king of Admah, and the king of Zeboiim, and the king of Bela (the same is Zoar) went out; and they set the battle in array against them in the valley of Siddim;",
                "against Chedorlaomer king of Elam, and Tidal king of Goiim, and Amraphel king of Shinar, and Arioch king of Ellasar; four kings against the five.",
                "Now the valley of Siddim was full of tar pits; and the kings of Sodom and Gomorrah fled, and they fell there, and those who remained fled to the hills.",
                "They took all the goods of Sodom and Gomorrah, and all their food, and went their way.",
                "They took Lot, Abram\u2019s brother\u2019s son, who lived in Sodom, and his goods, and departed.",
                "One who had escaped came and told Abram, the Hebrew. Now he lived by the oaks of Mamre, the Amorite, brother of Eshcol, and brother of Aner; and these were allies of Abram.",
                "When Abram heard that his relative was taken captive, he led out his trained men, born in his house, three hundred and eighteen, and pursued as far as Dan.",
                "He divided himself against them by night, he and his servants, and struck them, and pursued them to Hobah, which is on the left hand of Damascus.",
                "He brought back all the goods, and also brought back his relative, Lot, and his goods, and the women also, and the people.",
                "The king of Sodom went out to meet him, after his return from the slaughter of Chedorlaomer and the kings who were with him, at the valley of Shaveh (that is, the King\u2019s Valley).",
                "Melchizedek king of Salem brought out bread and wine: and he was priest of God Most High.",
                "He blessed him, and said, \"Blessed be Abram of God Most High, possessor of heaven and earth:",
                "and blessed be God Most High, who has delivered your enemies into your hand.\" Abram gave him a tenth of all.",
                "The king of Sodom said to Abram, \"Give me the people, and take the goods to yourself.\"",
                "Abram said to the king of Sodom, \"I have lifted up my hand to Yahweh, God Most High, possessor of heaven and earth,",
                "that I will not take a thread nor a sandal strap nor anything that is yours, lest you should say, \u2018I have made Abram rich.\u2019",
                "I will accept nothing from you except that which the young men have eaten, and the portion of the men who went with me: Aner, Eshcol, and Mamre. Let them take their portion.\""
            ],
            15: [
                "After these things the word of Yahweh came to Abram in a vision, saying, \"Don\u2019t be afraid, Abram. I am your shield, your exceedingly great reward.\"",
                "Abram said, \"Lord Yahweh, what will you give me, since I go childless, and he who will inherit my estate is Eliezer of Damascus?\"",
                "Abram said, \"Behold, to me you have given no seed: and, behold, one born in my house is my heir.\"",
                "Behold, the word of Yahweh came to him, saying, \"This man will not be your heir, but he who will come out of your own body will be your heir.\"",
                "Yahweh brought him outside, and said, \"Look now toward the sky, and count the stars, if you are able to count them.\" He said to Abram, \"So shall your seed be.\"",
                "He believed in Yahweh; and he reckoned it to him for righteousness.",
                "He said to him, \"I am Yahweh who brought you out of Ur of the Chaldees, to give you this land to inherit it.\"",
                "He said, \"Lord Yahweh, how will I know that I will inherit it?\"",
                "He said to him, \"Bring me a heifer three years old, a female goat three years old, a ram three years old, a turtledove, and a young pigeon.\"",
                "He brought him all of these, and divided them in the middle, and laid each half opposite the other; but he didn\u2019t divide the birds.",
                "The birds of prey came down on the carcasses, and Abram drove them away.",
                "When the sun was going down, a deep sleep fell on Abram. Now terror and great darkness fell on him.",
                "He said to Abram, \"Know for sure that your seed will live as foreigners in a land that is not theirs, and will serve them. They will afflict them four hundred years.",
                "I will also judge that nation, whom they will serve. Afterward they will come out with great wealth,",
                "but you will go to your fathers in peace. You will be buried in a good old age.",
                "In the fourth generation they will come here again, for the iniquity of the Amorite is not yet full.\"",
                "It came to pass that, when the sun went down, and it was dark, behold, a smoking furnace, and a flaming torch passed between these pieces.",
                "In that day Yahweh made a covenant with Abram, saying, \"To your seed I have given this land, from the river of Egypt to the great river, the river Euphrates:",
                "the Kenites, the Kenizzites, the Kadmonites,",
                "the Hittites, the Perizzites, the Rephaim,",
                "the Amorites, the Canaanites, the Girgashites, and the Jebusites.\""
            ],
            16: [
                "Now Sarai, Abram\u2019s wife, bore him no children. She had a handmaid, an Egyptian, whose name was Hagar.",
                "Sarai said to Abram, \"See now, Yahweh has restrained me from bearing. Please go in to my handmaid. It may be that I will obtain children by her.\" Abram listened to the voice of Sarai.",
                "Sarai, Abram\u2019s wife, took Hagar the Egyptian, her handmaid, after Abram had lived ten years in the land of Canaan, and gave her to Abram her husband to be his wife.",
                "He went in to Hagar, and she conceived. When she saw that she had conceived, her mistress was despised in her eyes.",
                "Sarai said to Abram, \"This wrong is your fault. I gave my handmaid into your bosom, and when she saw that she had conceived, I was despised in her eyes. Yahweh judge between me and you.\"",
                "But Abram said to Sarai, \"Behold, your maid is in your hand. Do to her whatever is good in your eyes.\" Sarai dealt harshly with her, and she fled from her face.",
                "The angel of Yahweh found her by a fountain of water in the wilderness, by the fountain in the way to Shur.",
                "He said, \"Hagar, Sarai\u2019s handmaid, where did you come from? Where are you going?\" She said, \"I am fleeing from the face of my mistress Sarai.\"",
                "The angel of Yahweh said to her, \"Return to your mistress, and submit yourself under her hands.\"",
                "The angel of Yahweh said to her, \"I will greatly multiply your seed, that they will not be numbered for multitude.\"",
                "The angel of Yahweh said to her, \"Behold, you are with child, and will bear a son. You shall call his name Ishmael, because Yahweh has heard your affliction.",
                "He will be like a wild donkey among men. His hand will be against every man, and every man\u2019s hand against him. He will live opposite all of his brothers.\"",
                "She called the name of Yahweh who spoke to her, \"You are a God who sees,\" for she said, \"Have I even stayed alive after seeing him?\"",
                "Therefore the well was called Beer Lahai Roi. Behold, it is between Kadesh and Bered.",
                "Hagar bore a son for Abram. Abram called the name of his son, whom Hagar bore, Ishmael.",
                "Abram was eighty-six years old when Hagar bore Ishmael to Abram."
            ],
            17: [
                "When Abram was ninety-nine years old, Yahweh appeared to Abram, and said to him, \"I am God Almighty. Walk before me, and be blameless.",
                "I will make my covenant between me and you, and will multiply you exceedingly.\"",
                "Abram fell on his face. God talked with him, saying,",
                "\"As for me, behold, my covenant is with you. You will be the father of a multitude of nations.",
                "Neither will your name any more be called Abram, but your name will be Abraham; for I have made you the father of a multitude of nations.",
                "I will make you exceedingly fruitful, and I will make nations of you. Kings will come out of you.",
                "I will establish my covenant between me and you and your seed after you throughout their generations for an everlasting covenant, to be a God to you and to your seed after you.",
                "I will give to you, and to your seed after you, the land where you are traveling, all the land of Canaan, for an everlasting possession. I will be their God.\"",
                "God said to Abraham, \"As for you, you will keep my covenant, you and your seed after you throughout their generations.",
                "This is my covenant, which you shall keep, between me and you and your seed after you. Every male among you shall be circumcised.",
                "You shall be circumcised in the flesh of your foreskin. It will be a token of the covenant between me and you.",
                "He who is eight days old will be circumcised among you, every male throughout your generations, he who is born in the house, or bought with money from any foreigner who is not of your seed.",
                "He who is born in your house, and he who is bought with your money, must be circumcised. My covenant will be in your flesh for an everlasting covenant.",
                "The uncircumcised male who is not circumcised in the flesh of his foreskin, that soul shall be cut off from his people. He has broken my covenant.\"",
                "God said to Abraham, \"As for Sarai your wife, you shall not call her name Sarai, but her name will be Sarah.",
                "I will bless her, and moreover I will give you a son by her. Yes, I will bless her, and she will be a mother of nations. Kings of peoples will come from her.\"",
                "Then Abraham fell on his face, and laughed, and said in his heart, \"Will a child be born to him who is one hundred years old? Will Sarah, who is ninety years old, give birth?\"",
                "Abraham said to God, \"Oh that Ishmael might live before you!\"",
                "God said, \"No, but Sarah, your wife, will bear you a son. You shall call his name Isaac. I will establish my covenant with him for an everlasting covenant for his seed after him.",
                "As for Ishmael, I have heard you. Behold, I have blessed him, and will make him fruitful, and will multiply him exceedingly. He will become the father of twelve princes, and I will make him a great nation.",
                "But my covenant I establish with Isaac, whom Sarah will bear to you at this set time next year.\"",
                "When he finished talking with him, God went up from Abraham.",
                "Abraham took Ishmael his son, all who were born in his house, and all who were bought with his money; every male among the men of Abraham\u2019s house, and circumcised the flesh of their foreskin in the same day, as God had said to him.",
                "Abraham was ninety-nine years old, when he was circumcised in the flesh of his foreskin.",
                "Ishmael, his son, was thirteen years old when he was circumcised in the flesh of his foreskin.",
                "In the same day both Abraham and Ishmael, his son, were circumcised.",
                "All the men of his house, those born in the house, and those bought with money of a foreigner, were circumcised with him."
            ],
            18: [
                "Yahweh appeared to him by the oaks of Mamre, as he sat in the tent door in the heat of the day.",
                "He lifted up his eyes and looked, and saw that three men stood opposite him. When he saw them, he ran to meet them from the tent door, and bowed himself to the earth,",
                "and said, \"My lord, if now I have found favor in your sight, please don\u2019t go away from your servant.",
                "Now let a little water be fetched, wash your feet, and rest yourselves under the tree.",
                "I will get a morsel of bread so you can refresh your heart. After that you may go your way, now that you have come to your servant.\" They said, \"Very well, do as you have said.\"",
                "Abraham hurried into the tent to Sarah, and said, \"Quickly prepare three measures of fine meal, knead it, and make cakes.\"",
                "Abraham ran to the herd, and fetched a tender and good calf, and gave it to the servant. He hurried to dress it.",
                "He took butter, milk, and the calf which he had dressed, and set it before them. He stood by them under the tree, and they ate.",
                "They asked him, \"Where is Sarah, your wife?\" He said, \"See, in the tent.\"",
                "He said, \"I will certainly return to you when the season comes round. Behold, Sarah your wife will have a son.\" Sarah heard in the tent door, which was behind him.",
                "Now Abraham and Sarah were old, well advanced in age. Sarah had passed the age of childbearing.",
                "Sarah laughed within herself, saying, \"After I have grown old will I have pleasure, my lord being old also?\"",
                "Yahweh said to Abraham, \"Why did Sarah laugh, saying, \u2018Will I really bear a child, yet I am old?\u2019",
                "Is anything too hard for Yahweh? At the set time I will return to you, when the season comes round, and Sarah will have a son.\"",
                "Then Sarah denied, saying, \"I didn\u2019t laugh,\" for she was afraid. He said, \"No, but you did laugh.\"",
                "The men rose up from there, and looked toward Sodom. Abraham went with them to see them on their way.",
                "Yahweh said, \"Will I hide from Abraham what I do,",
                "since Abraham has surely become a great and mighty nation, and all the nations of the earth will be blessed in him?",
                "For I have known him, to the end that he may command his children and his household after him, that they may keep the way of Yahweh, to do righteousness and justice; to the end that Yahweh may bring on Abraham that which he has spoken of him.\"",
                "Yahweh said, \"Because the cry of Sodom and Gomorrah is great, and because their sin is very grievous,",
                "I will go down now, and see whether their deeds are as bad as the reports which have come to me. If not, I will know.\"",
                "The men turned from there, and went toward Sodom, but Abraham stood yet before Yahweh.",
                "Abraham drew near, and said, \"Will you consume the righteous with the wicked?",
                "What if there are fifty righteous within the city? Will you consume and not spare the place for the fifty righteous who are in it?",
                "Be it far from you to do things like that, to kill the righteous with the wicked, so that the righteous should be like the wicked. May that be far from you. Shouldn\u2019t the Judge of all the earth do right?\"",
                "Yahweh said, \"If I find in Sodom fifty righteous within the city, then I will spare all the place for their sake.\"",
                "Abraham answered, \"See now, I have taken it on myself to speak to the Lord, who am but dust and ashes.",
                "What if there will lack five of the fifty righteous? Will you destroy all the city for lack of five?\" He said, \"I will not destroy it, if I find forty-five there.\"",
                "He spoke to him yet again, and said, \"What if there are forty found there?\" He said, \"I will not do it for the forty\u2019s sake.\"",
                "He said, \"Oh don\u2019t let the Lord be angry, and I will speak. What if there are thirty found there?\" He said, \"I will not do it, if I find thirty there.\"",
                "He said, \"See now, I have taken it on myself to speak to the Lord. What if there are twenty found there?\" He said, \"I will not destroy it for the twenty\u2019s sake.\"",
                "He said, \"Oh don\u2019t let the Lord be angry, and I will speak just once more. What if ten are found there?\" He said, \"I will not destroy it for the ten\u2019s sake.\"",
                "Yahweh went his way, as soon as he had finished communing with Abraham, and Abraham returned to his place."
            ],
            19: [
                "The two angels came to Sodom at evening. Lot sat in the gate of Sodom. Lot saw them, and rose up to meet them. He bowed himself with his face to the earth,",
                "and he said, \"See now, my lords, please turn aside into your servant\u2019s house, stay all night, wash your feet, and you can rise up early, and go on your way.\" They said, \"No, but we will stay in the street all night.\"",
                "He urged them greatly, and they came in with him, and entered into his house. He made them a feast, and baked unleavened bread, and they ate.",
                "But before they lay down, the men of the city, the men of Sodom, surrounded the house, both young and old, all the people from every quarter.",
                "They called to Lot, and said to him, \"Where are the men who came in to you this night? Bring them out to us, that we may have sex with them.\"",
                "Lot went out to them to the door, and shut the door after him.",
                "He said, \"Please, my brothers, don\u2019t act so wickedly.",
                "See now, I have two virgin daughters. Please let me bring them out to you, and you may do to them what seems good to you. Only don\u2019t do anything to these men, because they have come under the shadow of my roof.\"",
                "They said, \"Stand back!\" Then they said, \"This one fellow came in to live as a foreigner, and he appoints himself a judge. Now will we deal worse with you, than with them!\" They pressed hard on the man Lot, and drew near to break the door.",
                "But the men reached out their hand, and brought Lot into the house to them, and shut the door.",
                "They struck the men who were at the door of the house with blindness, both small and great, so that they wearied themselves to find the door.",
                "The men said to Lot, \"Do you have anybody else here? Sons-in-law, your sons, your daughters, and whoever you have in the city, bring them out of the place:",
                "for we will destroy this place, because the outcry against them has grown great before Yahweh that Yahweh has sent us to destroy it.\"",
                "Lot went out, and spoke to his sons-in-law, who were pledged to marry his daughters, and said, \"Get up! Get out of this place, for Yahweh will destroy the city.\" But he seemed to his sons-in-law to be joking.",
                "When the morning came, then the angels hurried Lot, saying, \"Get up! Take your wife, and your two daughters who are here, lest you be consumed in the iniquity of the city.\"",
                "But he lingered; and the men grabbed his hand, his wife\u2019s hand, and his two daughters\u2019 hands, Yahweh being merciful to him; and they took him out, and set him outside of the city.",
                "It came to pass, when they had taken them out, that he said, \"Escape for your life! Don\u2019t look behind you, and don\u2019t stay anywhere in the plain. Escape to the mountains, lest you be consumed!\"",
                "Lot said to them, \"Oh, not so, my lord.",
                "See now, your servant has found favor in your sight, and you have magnified your loving kindness, which you have shown to me in saving my life. I can\u2019t escape to the mountain, lest evil overtake me, and I die.",
                "See now, this city is near to flee to, and it is a little one. Oh let me escape there (isn\u2019t it a little one?), and my soul will live.\"",
                "He said to him, \"Behold, I have granted your request concerning this thing also, that I will not overthrow the city of which you have spoken.",
                "Hurry, escape there, for I can\u2019t do anything until you get there.\" Therefore the name of the city was called Zoar.",
                "The sun had risen on the earth when Lot came to Zoar.",
                "Then Yahweh rained on Sodom and on Gomorrah sulfur and fire from Yahweh out of the sky.",
                "He overthrew those cities, all the plain, all the inhabitants of the cities, and that which grew on the ground.",
                "But his wife looked back from behind him, and she became a pillar of salt.",
                "Abraham got up early in the morning to the place where he had stood before Yahweh.",
                "He looked toward Sodom and Gomorrah, and toward all the land of the plain, and looked, and saw that the smoke of the land went up as the smoke of a furnace.",
                "It happened, when God destroyed the cities of the plain, that God remembered Abraham, and sent Lot out of the middle of the overthrow, when he overthrew the cities in which Lot lived.",
                "Lot went up out of Zoar, and lived in the mountain, and his two daughters with him; for he was afraid to live in Zoar. He lived in a cave with his two daughters.",
                "The firstborn said to the younger, \"Our father is old, and there is not a man in the earth to come in to us in the way of all the earth.",
                "Come, let\u2019s make our father drink wine, and we will lie with him, that we may preserve our father\u2019s seed.\"",
                "They made their father drink wine that night: and the firstborn went in, and lay with her father. He didn\u2019t know when she lay down, nor when she arose.",
                "It came to pass on the next day, that the firstborn said to the younger, \"Behold, I lay last night with my father. Let us make him drink wine again, tonight. You go in, and lie with him, that we may preserve our father\u2019s seed.\"",
                "They made their father drink wine that night also. The younger went and lay with him. He didn\u2019t know when she lay down, nor when she got up.",
                "Thus both of Lot\u2019s daughters were with child by their father.",
                "The firstborn bore a son, and named him Moab. He is the father of the Moabites to this day.",
                "The younger also bore a son, and called his name Ben Ammi. He is the father of the children of Ammon to this day."
            ],
            20: [
                "Abraham traveled from there toward the land of the South, and lived between Kadesh and Shur. He lived as a foreigner in Gerar.",
                "Abraham said about Sarah his wife, \"She is my sister.\" Abimelech king of Gerar sent, and took Sarah.",
                "But God came to Abimelech in a dream of the night, and said to him, \"Behold, you are a dead man, because of the woman whom you have taken. For she is a man\u2019s wife.\"",
                "Now Abimelech had not come near her. He said, \"Lord, will you kill even a righteous nation?",
                "Didn\u2019t he tell me, \u2018She is my sister?\u2019 She, even she herself, said, \u2018He is my brother.\u2019 In the integrity of my heart and the innocence of my hands have I done this.\"",
                "God said to him in the dream, \"Yes, I know that in the integrity of your heart you have done this, and I also withheld you from sinning against me. Therefore I didn\u2019t allow you to touch her.",
                "Now therefore, restore the man\u2019s wife. For he is a prophet, and he will pray for you, and you will live. If you don\u2019t restore her, know for sure that you will die, you, and all who are yours.\"",
                "Abimelech rose early in the morning, and called all his servants, and told all these things in their ear. The men were very scared.",
                "Then Abimelech called Abraham, and said to him, \"What have you done to us? How have I sinned against you, that you have brought on me and on my kingdom a great sin? You have done deeds to me that ought not to be done!\"",
                "Abimelech said to Abraham, \"What did you see, that you have done this thing?\"",
                "Abraham said, \"Because I thought, \u2018Surely the fear of God is not in this place. They will kill me for my wife\u2019s sake.\u2019",
                "Besides, she is indeed my sister, the daughter of my father, but not the daughter of my mother; and she became my wife.",
                "It happened, when God caused me to wander from my father\u2019s house, that I said to her, \u2018This is your kindness which you shall show to me. Everywhere that we go, say of me, \"He is my brother.\"\u2019\"",
                "Abimelech took sheep and cattle, male servants and female servants, and gave them to Abraham, and restored Sarah, his wife, to him.",
                "Abimelech said, \"Behold, my land is before you. Dwell where it pleases you.\"",
                "To Sarah he said, \"Behold, I have given your brother a thousand pieces of silver. Behold, it is for you a covering of the eyes to all that are with you. In front of all you are vindicated.\"",
                "Abraham prayed to God. God healed Abimelech, and his wife, and his female servants, and they bore children.",
                "For Yahweh had closed up tight all the wombs of the house of Abimelech, because of Sarah, Abraham\u2019s wife."
            ],
            21: [
                "Yahweh visited Sarah as he had said, and Yahweh did to Sarah as he had spoken.",
                "Sarah conceived, and bore Abraham a son in his old age, at the set time of which God had spoken to him.",
                "Abraham called his son who was born to him, whom Sarah bore to him, Isaac.",
                "Abraham circumcised his son, Isaac, when he was eight days old, as God had commanded him.",
                "Abraham was one hundred years old when his son, Isaac, was born to him.",
                "Sarah said, \"God has made me laugh. Everyone who hears will laugh with me.\"",
                "She said, \"Who would have said to Abraham, that Sarah would nurse children? For I have borne him a son in his old age.\"",
                "The child grew, and was weaned. Abraham made a great feast on the day that Isaac was weaned.",
                "Sarah saw the son of Hagar the Egyptian, whom she had borne to Abraham, mocking.",
                "Therefore she said to Abraham, \"Cast out this handmaid and her son! For the son of this handmaid will not be heir with my son, Isaac.\"",
                "The thing was very grievous in Abraham\u2019s sight on account of his son.",
                "God said to Abraham, \"Don\u2019t let it be grievous in your sight because of the boy, and because of your handmaid. In all that Sarah says to you, listen to her voice. For from Isaac will your seed be called.",
                "I will also make a nation of the son of the handmaid, because he is your seed.\"",
                "Abraham rose up early in the morning, and took bread and a bottle of water, and gave it to Hagar, putting it on her shoulder; and gave her the child, and sent her away. She departed, and wandered in the wilderness of Beersheba.",
                "The water in the bottle was spent, and she cast the child under one of the shrubs.",
                "She went and sat down opposite him, a good way off, about a bow shot away. For she said, \"Don\u2019t let me see the death of the child.\" She sat over against him, and lifted up her voice, and wept.",
                "God heard the voice of the boy. The angel of God called to Hagar out of the sky, and said to her, \"What ails you, Hagar? Don\u2019t be afraid. For God has heard the voice of the boy where he is.",
                "Get up, lift up the boy, and hold him in your hand. For I will make him a great nation.\"",
                "God opened her eyes, and she saw a well of water. She went, filled the bottle with water, and gave the boy drink.",
                "God was with the boy, and he grew. He lived in the wilderness, and became, as he grew up, an archer.",
                "He lived in the wilderness of Paran. His mother took a wife for him out of the land of Egypt.",
                "It happened at that time, that Abimelech and Phicol the captain of his army spoke to Abraham, saying, \"God is with you in all that you do.",
                "Now, therefore, swear to me here by God that you will not deal falsely with me, nor with my son, nor with my son\u2019s son. But according to the kindness that I have done to you, you shall do to me, and to the land in which you have lived as a foreigner.\"",
                "Abraham said, \"I will swear.\"",
                "Abraham complained to Abimelech because of a water well, which Abimelech\u2019s servants had violently taken away.",
                "Abimelech said, \"I don\u2019t know who has done this thing. You didn\u2019t tell me, neither did I hear of it, until today.\"",
                "Abraham took sheep and cattle, and gave them to Abimelech. Those two made a covenant.",
                "Abraham set seven ewe lambs of the flock by themselves.",
                "Abimelech said to Abraham, \"What do these seven ewe lambs which you have set by themselves mean?\"",
                "He said, \"You shall take these seven ewe lambs from my hand, that it may be a witness to me, that I have dug this well.\"",
                "Therefore he called that place Beersheba, because they both swore there.",
                "So they made a covenant at Beersheba. Abimelech rose up with Phicol, the captain of his army, and they returned into the land of the Philistines.",
                "Abraham planted a tamarisk tree in Beersheba, and called there on the name of Yahweh, the Everlasting God.",
                "Abraham lived as a foreigner in the land of the Philistines many days."
            ],
            22: [
                "It happened after these things, that God tested Abraham, and said to him, \"Abraham!\" He said, \"Here I am.\"",
                "He said, \"Now take your son, your only son, whom you love, even Isaac, and go into the land of Moriah. Offer him there for a burnt offering on one of the mountains which I will tell you of.\"",
                "Abraham rose early in the morning, and saddled his donkey, and took two of his young men with him, and Isaac his son. He split the wood for the burnt offering, and rose up, and went to the place of which God had told him.",
                "On the third day Abraham lifted up his eyes, and saw the place far off.",
                "Abraham said to his young men, \"Stay here with the donkey. The boy and I will go yonder. We will worship, and come back to you.\"",
                "Abraham took the wood of the burnt offering and laid it on Isaac his son. He took in his hand the fire and the knife. They both went together.",
                "Isaac spoke to Abraham his father, and said, \"My father?\" He said, \"Here I am, my son.\" He said, \"Here is the fire and the wood, but where is the lamb for a burnt offering?\"",
                "Abraham said, \"God will provide himself the lamb for a burnt offering, my son.\" So they both went together.",
                "They came to the place which God had told him of. Abraham built the altar there, and laid the wood in order, bound Isaac his son, and laid him on the altar, on the wood.",
                "Abraham stretched out his hand, and took the knife to kill his son.",
                "The angel of Yahweh called to him out of the sky, and said, \"Abraham, Abraham!\" He said, \"Here I am.\"",
                "He said, \"Don\u2019t lay your hand on the boy, neither do anything to him. For now I know that you fear God, since you have not withheld your son, your only son, from me.\"",
                "Abraham lifted up his eyes, and looked, and saw that behind him was a ram caught in the thicket by his horns. Abraham went and took the ram, and offered him up for a burnt offering instead of his son.",
                "Abraham called the name of that place Yahweh Will Provide. As it is said to this day, \"On Yahweh\u2019s mountain, it will be provided.\"",
                "The angel of Yahweh called to Abraham a second time out of the sky,",
                "and said, \"I have sworn by myself, says Yahweh, because you have done this thing, and have not withheld your son, your only son,",
                "that I will bless you greatly, and I will multiply your seed greatly like the stars of the heavens, and like the sand which is on the seashore. Your seed will possess the gate of his enemies.",
                "In your seed will all the nations of the earth be blessed, because you have obeyed my voice.\"",
                "So Abraham returned to his young men, and they rose up and went together to Beersheba. Abraham lived at Beersheba.",
                "It happened after these things, that it was told Abraham, saying, \"Behold, Milcah, she also has borne children to your brother Nahor:",
                "Uz his firstborn, Buz his brother, Kemuel the father of Aram,",
                "Chesed, Hazo, Pildash, Jidlaph, and Bethuel.\"",
                "Bethuel became the father of Rebekah. These eight Milcah bore to Nahor, Abraham\u2019s brother.",
                "His concubine, whose name was Reumah, also bore Tebah, Gaham, Tahash, and Maacah."
            ],
            23: [
                "Sarah lived one hundred twenty-seven years. This was the length of Sarah\u2019s life.",
                "Sarah died in Kiriath Arba (the same is Hebron), in the land of Canaan. Abraham came to mourn for Sarah, and to weep for her.",
                "Abraham rose up from before his dead, and spoke to the children of Heth, saying,",
                "\"I am a stranger and a foreigner living with you. Give me a possession of a burying-place with you, that I may bury my dead out of my sight.\"",
                "The children of Heth answered Abraham, saying to him,",
                "\"Hear us, my lord. You are a prince of God among us. Bury your dead in the best of our tombs. None of us will withhold from you his tomb. Bury your dead.\"",
                "Abraham rose up, and bowed himself to the people of the land, even to the children of Heth.",
                "He talked with them, saying, \"If it be your mind that I should bury my dead out of my sight, hear me, and entreat for me to Ephron the son of Zohar,",
                "that he may give me the cave of Machpelah, which he has, which is in the end of his field. For the full price let him give it to me among you for a possession of a burying-place.\"",
                "Now Ephron was sitting in the middle of the children of Heth. Ephron the Hittite answered Abraham in the hearing of the children of Heth, even of all who went in at the gate of his city, saying,",
                "\"No, my lord, hear me. I give you the field, and I give you the cave that is in it. In the presence of the children of my people I give it to you. Bury your dead.\"",
                "Abraham bowed himself down before the people of the land.",
                "He spoke to Ephron in the audience of the people of the land, saying, \"But if you will, please hear me. I will give the price of the field. Take it from me, and I will bury my dead there.\"",
                "Ephron answered Abraham, saying to him,",
                "\"My lord, listen to me. What is a piece of land worth four hundred shekels of silver between me and you? Therefore bury your dead.\"",
                "Abraham listened to Ephron. Abraham weighed to Ephron the silver which he had named in the audience of the children of Heth, four hundred shekels of silver, according to the current merchants\u2019 standard.",
                "So the field of Ephron, which was in Machpelah, which was before Mamre, the field, the cave which was in it, and all the trees that were in the field, that were in all of its borders, were deeded",
                "to Abraham for a possession in the presence of the children of Heth, before all who went in at the gate of his city.",
                "After this, Abraham buried Sarah his wife in the cave of the field of Machpelah before Mamre (that is, Hebron), in the land of Canaan.",
                "The field, and the cave that is in it, were deeded to Abraham for a possession of a burying place by the children of Heth."
            ],
            24: [
                "Abraham was old, and well stricken in age. Yahweh had blessed Abraham in all things.",
                "Abraham said to his servant, the elder of his house, who ruled over all that he had, \"Please put your hand under my thigh.",
                "I will make you swear by Yahweh, the God of heaven and the God of the earth, that you shall not take a wife for my son of the daughters of the Canaanites, among whom I live.",
                "But you shall go to my country, and to my relatives, and take a wife for my son Isaac.\"",
                "The servant said to him, \"What if the woman isn\u2019t willing to follow me to this land? Must I bring your son again to the land you came from?\"",
                "Abraham said to him, \"Beware that you don\u2019t bring my son there again.",
                "Yahweh, the God of heaven, who took me from my father\u2019s house, and from the land of my birth, who spoke to me, and who swore to me, saying, \u2018I will give this land to your seed.\u2019 He will send his angel before you, and you shall take a wife for my son from there.",
                "If the woman isn\u2019t willing to follow you, then you shall be clear from this my oath. Only you shall not bring my son there again.\"",
                "The servant put his hand under the thigh of Abraham his master, and swore to him concerning this matter.",
                "The servant took ten camels, of his master\u2019s camels, and departed, having a variety of good things of his master\u2019s with him. He arose, and went to Mesopotamia, to the city of Nahor.",
                "He made the camels kneel down outside the city by the well of water at the time of evening, the time that women go out to draw water.",
                "He said, \"Yahweh, the God of my master Abraham, please give me success this day, and show kindness to my master Abraham.",
                "Behold, I am standing by the spring of water. The daughters of the men of the city are coming out to draw water.",
                "Let it happen, that the young lady to whom I will say, \u2018Please let down your pitcher, that I may drink,\u2019 and she will say, \u2018Drink, and I will also give your camels a drink,\u2019\u2014let her be the one you have appointed for your servant Isaac. By this I will know that you have shown kindness to my master.\"",
                "It happened, before he had finished speaking, that behold, Rebekah came out, who was born to Bethuel the son of Milcah, the wife of Nahor, Abraham\u2019s brother, with her pitcher on her shoulder.",
                "The young lady was very beautiful to look at, a virgin, neither had any man known her. She went down to the spring, filled her pitcher, and came up.",
                "The servant ran to meet her, and said, \"Please give me a drink, a little water from your pitcher.\"",
                "She said, \"Drink, my lord.\" She hurried, and let down her pitcher on her hand, and gave him drink.",
                "When she had done giving him drink, she said, \"I will also draw for your camels, until they have done drinking.\"",
                "She hurried, and emptied her pitcher into the trough, and ran again to the well to draw, and drew for all his camels.",
                "The man looked steadfastly at her, remaining silent, to know whether Yahweh had made his journey prosperous or not.",
                "It happened, as the camels had done drinking, that the man took a golden ring of half a shekel weight, and two bracelets for her hands of ten shekels weight of gold,",
                "and said, \"Whose daughter are you? Please tell me. Is there room in your father\u2019s house for us to lodge in?\"",
                "She said to him, \"I am the daughter of Bethuel the son of Milcah, whom she bore to Nahor.\"",
                "She said moreover to him, \"We have both straw and provender enough, and room to lodge in.\"",
                "The man bowed his head, and worshiped Yahweh.",
                "He said, \"Blessed be Yahweh, the God of my master Abraham, who has not forsaken his loving kindness and his truth toward my master. As for me, Yahweh has led me in the way to the house of my master\u2019s relatives.\"",
                "The young lady ran, and told her mother\u2019s house about these words.",
                "Rebekah had a brother, and his name was Laban. Laban ran out to the man, to the spring.",
                "It happened, when he saw the ring, and the bracelets on his sister\u2019s hands, and when he heard the words of Rebekah his sister, saying, \"This is what the man said to me,\" that he came to the man. Behold, he was standing by the camels at the spring.",
                "He said, \"Come in, you blessed of Yahweh. Why do you stand outside? For I have prepared the house, and room for the camels.\"",
                "The man came into the house, and he unloaded the camels. He gave straw and provender for the camels, and water to wash his feet and the feet of the men who were with him.",
                "Food was set before him to eat, but he said, \"I will not eat until I have told my message.\" He said, \"Speak on.\"",
                "He said, \"I am Abraham\u2019s servant.",
                "Yahweh has blessed my master greatly. He has become great. He has given him flocks and herds, silver and gold, male servants and female servants, and camels and donkeys.",
                "Sarah, my master\u2019s wife, bore a son to my master when she was old. He has given all that he has to him.",
                "My master made me swear, saying, \u2018You shall not take a wife for my son of the daughters of the Canaanites, in whose land I live,",
                "but you shall go to my father\u2019s house, and to my relatives, and take a wife for my son.\u2019",
                "I asked my master, \u2018What if the woman will not follow me?\u2019",
                "He said to me, \u2018Yahweh, before whom I walk, will send his angel with you, and prosper your way. You shall take a wife for my son of my relatives, and of my father\u2019s house.",
                "Then will you be clear from my oath, when you come to my relatives. If they don\u2019t give her to you, you shall be clear from my oath.\u2019",
                "I came this day to the spring, and said, \u2018Yahweh, the God of my master Abraham, if now you do prosper my way which I go\u2014",
                "behold, I am standing by this spring of water. Let it happen, that the maiden who comes out to draw, to whom I will say, \"Please give me a little water from your pitcher to drink,\"",
                "and she will tell me, \"Drink, and I will also draw for your camels,\"\u2014let her be the woman whom Yahweh has appointed for my master\u2019s son.\u2019",
                "Before I had finished speaking in my heart, behold, Rebekah came out with her pitcher on her shoulder. She went down to the spring, and drew. I said to her, \u2018Please let me drink.\u2019",
                "She hurried and let down her pitcher from her shoulder, and said, \u2018Drink, and I will also give your camels a drink.\u2019 So I drank, and she also gave the camels a drink.",
                "I asked her, and said, \u2018Whose daughter are you?\u2019 She said, \u2018The daughter of Bethuel, Nahor\u2019s son, whom Milcah bore to him.\u2019 I put the ring on her nose, and the bracelets on her hands.",
                "I bowed my head, and worshiped Yahweh, and blessed Yahweh, the God of my master Abraham, who had led me in the right way to take my master\u2019s brother\u2019s daughter for his son.",
                "Now if you will deal kindly and truly with my master, tell me. If not, tell me, that I may turn to the right hand, or to the left.\"",
                "Then Laban and Bethuel answered, \"The thing proceeds from Yahweh. We can\u2019t speak to you bad or good.",
                "Behold, Rebekah is before you. Take her, and go, and let her be your master\u2019s son\u2019s wife, as Yahweh has spoken.\"",
                "It happened that when Abraham\u2019s servant heard their words, he bowed himself down to the earth to Yahweh.",
                "The servant brought out jewels of silver, and jewels of gold, and clothing, and gave them to Rebekah. He also gave precious things to her brother and her mother.",
                "They ate and drank, he and the men who were with him, and stayed all night. They rose up in the morning, and he said, \"Send me away to my master.\"",
                "Her brother and her mother said, \"Let the young lady stay with us a few days, at least ten. After that she will go.\"",
                "He said to them, \"Don\u2019t hinder me, since Yahweh has prospered my way. Send me away that I may go to my master.\"",
                "They said, \"We will call the young lady, and ask her.\"",
                "They called Rebekah, and said to her, \"Will you go with this man?\" She said, \"I will go.\"",
                "They sent away Rebekah, their sister, with her nurse, Abraham\u2019s servant, and his men.",
                "They blessed Rebekah, and said to her, \"Our sister, may you be the mother of thousands of ten thousands, and let your seed possess the gate of those who hate them.\"",
                "Rebekah arose with her ladies. They rode on the camels, and followed the man. The servant took Rebekah, and went his way.",
                "Isaac came from the way of Beer Lahai Roi, for he lived in the land of the South.",
                "Isaac went out to meditate in the field at the evening. He lifted up his eyes, and saw, and, behold, there were camels coming.",
                "Rebekah lifted up her eyes, and when she saw Isaac, she dismounted from the camel.",
                "She said to the servant, \"Who is the man who is walking in the field to meet us?\" The servant said, \"It is my master.\" She took her veil, and covered herself.",
                "The servant told Isaac all the things that he had done.",
                "Isaac brought her into his mother Sarah\u2019s tent, and took Rebekah, and she became his wife. He loved her. Isaac was comforted after his mother\u2019s death."
            ],
            25: [
                "Abraham took another wife, and her name was Keturah.",
                "She bore him Zimran, Jokshan, Medan, Midian, Ishbak, and Shuah.",
                "Jokshan became the father of Sheba, and Dedan. The sons of Dedan were Asshurim, Letushim, and Leummim.",
                "The sons of Midian: Ephah, Epher, Hanoch, Abida, and Eldaah. All these were the children of Keturah.",
                "Abraham gave all that he had to Isaac,",
                "but to the sons of Abraham\u2019s concubines, Abraham gave gifts. He sent them away from Isaac his son, while he yet lived, eastward, to the east country.",
                "These are the days of the years of Abraham\u2019s life which he lived: one hundred seventy-five years.",
                "Abraham gave up the spirit, and died in a good old age, an old man, and full of years, and was gathered to his people.",
                "Isaac and Ishmael, his sons, buried him in the cave of Machpelah, in the field of Ephron, the son of Zohar the Hittite, which is before Mamre,",
                "the field which Abraham purchased of the children of Heth. Abraham was buried there with Sarah, his wife.",
                "It happened after the death of Abraham that God blessed Isaac, his son. Isaac lived by Beer Lahai Roi.",
                "Now this is the history of the generations of Ishmael, Abraham\u2019s son, whom Hagar the Egyptian, Sarah\u2019s handmaid, bore to Abraham.",
                "These are the names of the sons of Ishmael, by their names, according to the order of their birth: the firstborn of Ishmael, Nebaioth, then Kedar, Adbeel, Mibsam,",
                "Mishma, Dumah, Massa,",
                "Hadad, Tema, Jetur, Naphish, and Kedemah.",
                "These are the sons of Ishmael, and these are their names, by their villages, and by their encampments: twelve princes, according to their nations.",
                "These are the years of the life of Ishmael: one hundred thirty-seven years. He gave up the spirit and died, and was gathered to his people.",
                "They lived from Havilah to Shur that is before Egypt, as you go toward Assyria. He lived opposite all his relatives.",
                "This is the history of the generations of Isaac, Abraham\u2019s son. Abraham became the father of Isaac.",
                "Isaac was forty years old when he took Rebekah, the daughter of Bethuel the Syrian of Paddan Aram, the sister of Laban the Syrian, to be his wife.",
                "Isaac entreated Yahweh for his wife, because she was barren. Yahweh was entreated by him, and Rebekah his wife conceived.",
                "The children struggled together within her. She said, \"If it be so, why do I live?\" She went to inquire of Yahweh.",
                "Yahweh said to her, \"Two nations are in your womb. Two peoples will be separated from your body. The one people will be stronger than the other people. The elder will serve the younger.\"",
                "When her days to be delivered were fulfilled, behold, there were twins in her womb.",
                "The first came out red all over, like a hairy garment. They named him Esau.",
                "After that, his brother came out, and his hand had hold on Esau\u2019s heel. He was named Jacob. Isaac was sixty years old when she bore them.",
                "The boys grew. Esau was a skillful hunter, a man of the field. Jacob was a quiet man, living in tents.",
                "Now Isaac loved Esau, because he ate his venison. Rebekah loved Jacob.",
                "Jacob boiled stew. Esau came in from the field, and he was famished.",
                "Esau said to Jacob, \"Please feed me with that same red stew, for I am famished.\" Therefore his name was called Edom.",
                "Jacob said, \"First, sell me your birthright.\"",
                "Esau said, \"Behold, I am about to die. What good is the birthright to me?\"",
                "Jacob said, \"Swear to me first.\" He swore to him. He sold his birthright to Jacob.",
                "Jacob gave Esau bread and stew of lentils. He ate and drank, rose up, and went his way. So Esau despised his birthright."
            ],
            26: [
                "There was a famine in the land, besides the first famine that was in the days of Abraham. Isaac went to Abimelech king of the Philistines, to Gerar.",
                "Yahweh appeared to him, and said, \"Don\u2019t go down into Egypt. Live in the land I will tell you about.",
                "Live in this land, and I will be with you, and will bless you. For to you, and to your seed, I will give all these lands, and I will establish the oath which I swore to Abraham your father.",
                "I will multiply your seed as the stars of the sky, and will give to your seed all these lands. In your seed will all the nations of the earth be blessed,",
                "because Abraham obeyed my voice, and kept my requirements, my commandments, my statutes, and my laws.\"",
                "Isaac lived in Gerar.",
                "The men of the place asked him about his wife. He said, \"She is my sister,\" for he was afraid to say, \"My wife,\" lest, he thought, \"the men of the place might kill me for Rebekah, because she is beautiful to look at.\"",
                "It happened, when he had been there a long time, that Abimelech king of the Philistines looked out at a window, and saw, and, behold, Isaac was caressing Rebekah, his wife.",
                "Abimelech called Isaac, and said, \"Behold, surely she is your wife. Why did you say, \u2018She is my sister?\u2019\" Isaac said to him, \"Because I said, \u2018Lest I die because of her.\u2019\"",
                "Abimelech said, \"What is this you have done to us? One of the people might easily have lain with your wife, and you would have brought guilt on us!\"",
                "Abimelech commanded all the people, saying, \"He who touches this man or his wife will surely be put to death.\"",
                "Isaac sowed in that land, and reaped in the same year one hundred times what he planted. Yahweh blessed him.",
                "The man grew great, and grew more and more until he became very great.",
                "He had possessions of flocks, possessions of herds, and a great household. The Philistines envied him.",
                "Now all the wells which his father\u2019s servants had dug in the days of Abraham his father, the Philistines had stopped, and filled with earth.",
                "Abimelech said to Isaac, \"Go from us, for you are much mightier than we.\"",
                "Isaac departed from there, encamped in the valley of Gerar, and lived there.",
                "Isaac dug again the wells of water, which they had dug in the days of Abraham his father. For the Philistines had stopped them after the death of Abraham. He called their names after the names by which his father had called them.",
                "Isaac\u2019s servants dug in the valley, and found there a well of springing water.",
                "The herdsmen of Gerar argued with Isaac\u2019s herdsmen, saying, \"The water is ours.\" He called the name of the well Esek, because they contended with him.",
                "They dug another well, and they argued over that, also. He called its name Sitnah.",
                "He left that place, and dug another well. They didn\u2019t argue over that one. He called it Rehoboth. He said, \"For now Yahweh has made room for us, and we will be fruitful in the land.\"",
                "He went up from there to Beersheba.",
                "Yahweh appeared to him the same night, and said, \"I am the God of Abraham your father. Don\u2019t be afraid, for I am with you, and will bless you, and multiply your seed for my servant Abraham\u2019s sake.\"",
                "He built an altar there, and called on the name of Yahweh, and pitched his tent there. There Isaac\u2019s servants dug a well.",
                "Then Abimelech went to him from Gerar, and Ahuzzath his friend, and Phicol the captain of his army.",
                "Isaac said to them, \"Why have you come to me, since you hate me, and have sent me away from you?\"",
                "They said, \"We saw plainly that Yahweh was with you. We said, \u2018Let there now be an oath between us, even between us and you, and let us make a covenant with you,",
                "that you will do us no harm, as we have not touched you, and as we have done to you nothing but good, and have sent you away in peace.\u2019 You are now the blessed of Yahweh.\"",
                "He made them a feast, and they ate and drank.",
                "They rose up some time in the morning, and swore one to another. Isaac sent them away, and they departed from him in peace.",
                "It happened the same day, that Isaac\u2019s servants came, and told him concerning the well which they had dug, and said to him, \"We have found water.\"",
                "He called it Shibah. Therefore the name of the city is Beersheba to this day.",
                "When Esau was forty years old, he took as wife Judith, the daughter of Beeri the Hittite, and Basemath, the daughter of Elon the Hittite.",
                "They grieved Isaac\u2019s and Rebekah\u2019s spirits."
            ],
            27: [
                "It happened, that when Isaac was old, and his eyes were dim, so that he could not see, he called Esau his elder son, and said to him, \"My son?\" He said to him, \"Here I am.\"",
                "He said, \"See now, I am old. I don\u2019t know the day of my death.",
                "Now therefore, please take your weapons, your quiver and your bow, and go out to the field, and take me venison.",
                "Make me savory food, such as I love, and bring it to me, that I may eat, and that my soul may bless you before I die.\"",
                "Rebekah heard when Isaac spoke to Esau his son. Esau went to the field to hunt for venison, and to bring it.",
                "Rebekah spoke to Jacob her son, saying, \"Behold, I heard your father speak to Esau your brother, saying,",
                "\u2018Bring me venison, and make me savory food, that I may eat, and bless you before Yahweh before my death.\u2019",
                "Now therefore, my son, obey my voice according to that which I command you.",
                "Go now to the flock, and get me from there two good young goats. I will make them savory food for your father, such as he loves.",
                "You shall bring it to your father, that he may eat, so that he may bless you before his death.\"",
                "Jacob said to Rebekah his mother, \"Behold, Esau my brother is a hairy man, and I am a smooth man.",
                "What if my father touches me? I will seem to him as a deceiver, and I would bring a curse on myself, and not a blessing.\"",
                "His mother said to him, \"Let your curse be on me, my son. Only obey my voice, and go get them for me.\"",
                "He went, and got them, and brought them to his mother. His mother made savory food, such as his father loved.",
                "Rebekah took the good clothes of Esau, her elder son, which were with her in the house, and put them on Jacob, her younger son.",
                "She put the skins of the young goats on his hands, and on the smooth of his neck.",
                "She gave the savory food and the bread, which she had prepared, into the hand of her son Jacob.",
                "He came to his father, and said, \"My father?\" He said, \"Here I am. Who are you, my son?\"",
                "Jacob said to his father, \"I am Esau your firstborn. I have done what you asked me to do. Please arise, sit and eat of my venison, that your soul may bless me.\"",
                "Isaac said to his son, \"How is it that you have found it so quickly, my son?\" He said, \"Because Yahweh your God gave me success.\"",
                "Isaac said to Jacob, \"Please come near, that I may feel you, my son, whether you are really my son Esau or not.\"",
                "Jacob went near to Isaac his father. He felt him, and said, \"The voice is Jacob\u2019s voice, but the hands are the hands of Esau.\"",
                "He didn\u2019t recognize him, because his hands were hairy, like his brother, Esau\u2019s hands. So he blessed him.",
                "He said, \"Are you really my son Esau?\" He said, \"I am.\"",
                "He said, \"Bring it near to me, and I will eat of my son\u2019s venison, that my soul may bless you.\" He brought it near to him, and he ate. He brought him wine, and he drank.",
                "His father Isaac said to him, \"Come near now, and kiss me, my son.\"",
                "He came near, and kissed him. He smelled the smell of his clothing, and blessed him, and said, \"Behold, the smell of my son is as the smell of a field which Yahweh has blessed.",
                "God give you of the dew of the sky, of the fatness of the earth, and plenty of grain and new wine.",
                "Let peoples serve you, and nations bow down to you. Be lord over your brothers. Let your mother\u2019s sons bow down to you. Cursed be everyone who curses you. Blessed be everyone who blesses you.\"",
                "It happened, as soon as Isaac had made an end of blessing Jacob, and Jacob had just gone out from the presence of Isaac his father, that Esau his brother came in from his hunting.",
                "He also made savory food, and brought it to his father. He said to his father, \"Let my father arise, and eat of his son\u2019s venison, that your soul may bless me.\"",
                "Isaac his father said to him, \"Who are you?\" He said, \"I am your son, your firstborn, Esau.\"",
                "Isaac trembled violently, and said, \"Who, then, is he who has taken venison, and brought it me, and I have eaten of all before you came, and have blessed him? Yes, he will be blessed.\"",
                "When Esau heard the words of his father, he cried with an exceeding great and bitter cry, and said to his father, \"Bless me, even me also, my father.\"",
                "He said, \"Your brother came with deceit, and has taken away your blessing.\"",
                "He said, \"Isn\u2019t he rightly named Jacob? For he has supplanted me these two times. He took away my birthright. See, now he has taken away my blessing.\" He said, \"Haven\u2019t you reserved a blessing for me?\"",
                "Isaac answered Esau, \"Behold, I have made him your lord, and all his brothers have I given to him for servants. With grain and new wine have I sustained him. What then will I do for you, my son?\"",
                "Esau said to his father, \"Have you but one blessing, my father? Bless me, even me also, my father.\" Esau lifted up his voice, and wept.",
                "Isaac his father answered him, \"Behold, of the fatness of the earth will be your dwelling, and of the dew of the sky from above.",
                "By your sword will you live, and you will serve your brother. It will happen, when you will break loose, that you shall shake his yoke from off your neck.\"",
                "Esau hated Jacob because of the blessing with which his father blessed him. Esau said in his heart, \"The days of mourning for my father are at hand. Then I will kill my brother Jacob.\"",
                "The words of Esau, her elder son, were told to Rebekah. She sent and called Jacob, her younger son, and said to him, \"Behold, your brother Esau comforts himself about you by planning to kill you.",
                "Now therefore, my son, obey my voice. Arise, flee to Laban, my brother, in Haran.",
                "Stay with him a few days, until your brother\u2019s fury turns away;",
                "until your brother\u2019s anger turn away from you, and he forgets what you have done to him. Then I will send, and get you from there. Why should I be bereaved of you both in one day?\"",
                "Rebekah said to Isaac, \"I am weary of my life because of the daughters of Heth. If Jacob takes a wife of the daughters of Heth, such as these, of the daughters of the land, what good will my life do me?\""
            ],
            28: [
                "Isaac called Jacob, blessed him, and commanded him, \"You shall not take a wife of the daughters of Canaan.",
                "Arise, go to Paddan Aram, to the house of Bethuel your mother\u2019s father. Take a wife from there from the daughters of Laban, your mother\u2019s brother.",
                "May God Almighty bless you, and make you fruitful, and multiply you, that you may be a company of peoples,",
                "and give you the blessing of Abraham, to you, and to your seed with you, that you may inherit the land where you travel, which God gave to Abraham.\"",
                "Isaac sent Jacob away. He went to Paddan Aram to Laban, son of Bethuel the Syrian, Rebekah\u2019s brother, Jacob\u2019s and Esau\u2019s mother.",
                "Now Esau saw that Isaac had blessed Jacob and sent him away to Paddan Aram, to take him a wife from there, and that as he blessed him he gave him a command, saying, \"You shall not take a wife of the daughters of Canaan,\"",
                "and that Jacob obeyed his father and his mother, and was gone to Paddan Aram.",
                "Esau saw that the daughters of Canaan didn\u2019t please Isaac, his father.",
                "Esau went to Ishmael, and took, besides the wives that he had, Mahalath the daughter of Ishmael, Abraham\u2019s son, the sister of Nebaioth, to be his wife.",
                "Jacob went out from Beersheba, and went toward Haran.",
                "He came to a certain place, and stayed there all night, because the sun had set. He took one of the stones of the place, and put it under his head, and lay down in that place to sleep.",
                "He dreamed. Behold, a stairway set upon the earth, and its top reached to heaven. Behold, the angels of God ascending and descending on it.",
                "Behold, Yahweh stood above it, and said, \"I am Yahweh, the God of Abraham your father, and the God of Isaac. The land whereon you lie, to you will I give it, and to your seed.",
                "Your seed will be as the dust of the earth, and you will spread abroad to the west, and to the east, and to the north, and to the south. In you and in your seed will all the families of the earth be blessed.",
                "Behold, I am with you, and will keep you, wherever you go, and will bring you again into this land. For I will not leave you, until I have done that which I have spoken of to you.\"",
                "Jacob awakened out of his sleep, and he said, \"Surely Yahweh is in this place, and I didn\u2019t know it.\"",
                "He was afraid, and said, \"How dreadful is this place! This is none other than God\u2019s house, and this is the gate of heaven.\"",
                "Jacob rose up early in the morning, and took the stone that he had put under his head, and set it up for a pillar, and poured oil on its top.",
                "He called the name of that place Bethel, but the name of the city was Luz at the first.",
                "Jacob vowed a vow, saying, \"If God will be with me, and will keep me in this way that I go, and will give me bread to eat, and clothing to put on,",
                "so that I come again to my father\u2019s house in peace, and Yahweh will be my God,",
                "then this stone, which I have set up for a pillar, will be God\u2019s house. Of all that you will give me I will surely give the tenth to you.\""
            ],
            29: [
                "Then Jacob went on his journey, and came to the land of the children of the east.",
                "He looked, and behold, a well in the field, and, behold, three flocks of sheep lying there by it. For out of that well they watered the flocks. The stone on the well\u2019s mouth was large.",
                "There all the flocks were gathered. They rolled the stone from the well\u2019s mouth, and watered the sheep, and put the stone again on the well\u2019s mouth in its place.",
                "Jacob said to them, \"My relatives, where are you from?\" They said, \"We are from Haran.\"",
                "He said to them, \"Do you know Laban, the son of Nahor?\" They said, \"We know him.\"",
                "He said to them, \"Is it well with him?\" They said, \"It is well. See, Rachel, his daughter, is coming with the sheep.\"",
                "He said, \"Behold, it is still the middle of the day, not time to gather the livestock together. Water the sheep, and go and feed them.\"",
                "They said, \"We can\u2019t, until all the flocks are gathered together, and they roll the stone from the well\u2019s mouth. Then we water the sheep.\"",
                "While he was yet speaking with them, Rachel came with her father\u2019s sheep, for she kept them.",
                "It happened, when Jacob saw Rachel the daughter of Laban, his mother\u2019s brother, and the sheep of Laban, his mother\u2019s brother, that Jacob went near, and rolled the stone from the well\u2019s mouth, and watered the flock of Laban his mother\u2019s brother.",
                "Jacob kissed Rachel, and lifted up his voice, and wept.",
                "Jacob told Rachel that he was her father\u2019s brother, and that he was Rebekah\u2019s son. She ran and told her father.",
                "It happened, when Laban heard the news of Jacob, his sister\u2019s son, that he ran to meet Jacob, and embraced him, and kissed him, and brought him to his house. Jacob told Laban all these things.",
                "Laban said to him, \"Surely you are my bone and my flesh.\" He lived with him for a month.",
                "Laban said to Jacob, \"Because you are my brother, should you therefore serve me for nothing? Tell me, what will your wages be?\"",
                "Laban had two daughters. The name of the elder was Leah, and the name of the younger was Rachel.",
                "Leah\u2019s eyes were weak, but Rachel was beautiful in form and attractive.",
                "Jacob loved Rachel. He said, \"I will serve you seven years for Rachel, your younger daughter.\"",
                "Laban said, \"It is better that I give her to you, than that I should give her to another man. Stay with me.\"",
                "Jacob served seven years for Rachel. They seemed to him but a few days, for the love he had for her.",
                "Jacob said to Laban, \"Give me my wife, for my days are fulfilled, that I may go in to her.\"",
                "Laban gathered together all the men of the place, and made a feast.",
                "It happened in the evening, that he took Leah his daughter, and brought her to him. He went in to her.",
                "Laban gave Zilpah his handmaid to his daughter Leah for a handmaid.",
                "It happened in the morning that, behold, it was Leah. He said to Laban, \"What is this you have done to me? Didn\u2019t I serve with you for Rachel? Why then have you deceived me?\"",
                "Laban said, \"It is not done so in our place, to give the younger before the firstborn.",
                "Fulfill the week of this one, and we will give you the other also for the service which you will serve with me yet seven other years.\"",
                "Jacob did so, and fulfilled her week. He gave him Rachel his daughter as wife.",
                "Laban gave to Rachel his daughter Bilhah, his handmaid, to be her handmaid.",
                "He went in also to Rachel, and he loved also Rachel more than Leah, and served with him yet seven other years.",
                "Yahweh saw that Leah was hated, and he opened her womb, but Rachel was barren.",
                "Leah conceived, and bore a son, and she named him Reuben. For she said, \"Because Yahweh has looked at my affliction. For now my husband will love me.\"",
                "She conceived again, and bore a son, and said, \"Because Yahweh has heard that I am hated, he has therefore given me this son also.\" She named him Simeon.",
                "She conceived again, and bore a son. Said, \"Now this time will my husband be joined to me, because I have borne him three sons.\" Therefore was his name called Levi.",
                "She conceived again, and bore a son. She said, \"This time will I praise Yahweh.\" Therefore she named him Judah. Then she stopped bearing."
            ],
            30: [
                "When Rachel saw that she bore Jacob no children, Rachel envied her sister. She said to Jacob, \"Give me children, or else I will die.\"",
                "Jacob\u2019s anger was kindled against Rachel, and he said, \"Am I in God\u2019s place, who has withheld from you the fruit of the womb?\"",
                "She said, \"Behold, my maid Bilhah. Go in to her, that she may bear on my knees, and I also may obtain children by her.\"",
                "She gave him Bilhah her handmaid as wife, and Jacob went in to her.",
                "Bilhah conceived, and bore Jacob a son.",
                "Rachel said, \"God has judged me, and has also heard my voice, and has given me a son.\" Therefore called she his name Dan.",
                "Bilhah, Rachel\u2019s handmaid, conceived again, and bore Jacob a second son.",
                "Rachel said, \"With mighty wrestlings have I wrestled with my sister, and have prevailed.\" She named him Naphtali.",
                "When Leah saw that she had finished bearing, she took Zilpah, her handmaid, and gave her to Jacob as a wife.",
                "Zilpah, Leah\u2019s handmaid, bore Jacob a son.",
                "Leah said, \"How fortunate!\" She named him Gad.",
                "Zilpah, Leah\u2019s handmaid, bore Jacob a second son.",
                "Leah said, \"Happy am I, for the daughters will call me happy.\" She named him Asher.",
                "Reuben went in the days of wheat harvest, and found mandrakes in the field, and brought them to his mother, Leah. Then Rachel said to Leah, \"Please give me some of your son\u2019s mandrakes.\"",
                "She said to her, \"Is it a small matter that you have taken away my husband? Would you take away my son\u2019s mandrakes, also?\" Rachel said, \"Therefore he will lie with you tonight for your son\u2019s mandrakes.\"",
                "Jacob came from the field in the evening, and Leah went out to meet him, and said, \"You must come in to me; for I have surely hired you with my son\u2019s mandrakes.\" He lay with her that night.",
                "God listened to Leah, and she conceived, and bore Jacob a fifth son.",
                "Leah said, \"God has given me my hire, because I gave my handmaid to my husband.\" She named him Issachar.",
                "Leah conceived again, and bore a sixth son to Jacob.",
                "Leah said, \"God has endowed me with a good dowry. Now my husband will live with me, because I have borne him six sons.\" She named him Zebulun.",
                "Afterwards, she bore a daughter, and named her Dinah.",
                "God remembered Rachel, and God listened to her, and opened her womb.",
                "She conceived, bore a son, and said, \"God has taken away my reproach.\"",
                "She named him Joseph, saying, \"May Yahweh add another son to me.\"",
                "It happened, when Rachel had borne Joseph, that Jacob said to Laban, \"Send me away, that I may go to my own place, and to my country.",
                "Give me my wives and my children for whom I have served you, and let me go; for you know my service with which I have served you.\"",
                "Laban said to him, \"If now I have found favor in your eyes, stay here, for I have divined that Yahweh has blessed me for your sake.\"",
                "He said, \"Appoint me your wages, and I will give it.\"",
                "He said to him, \"You know how I have served you, and how your livestock have fared with me.",
                "For it was little which you had before I came, and it has increased to a multitude. Yahweh has blessed you wherever I turned. Now when will I provide for my own house also?\"",
                "He said, \"What shall I give you?\" Jacob said, \"You shall not give me anything. If you will do this thing for me, I will again feed your flock and keep it.",
                "I will pass through all your flock today, removing from there every speckled and spotted one, and every black one among the sheep, and the spotted and speckled among the goats. This will be my hire.",
                "So my righteousness will answer for me hereafter, when you come concerning my hire that is before you. Every one that is not speckled and spotted among the goats, and black among the sheep, that might be with me, will be counted stolen.\"",
                "Laban said, \"Behold, let it be according to your word.\"",
                "That day, he removed the male goats that were streaked and spotted, and all the female goats that were speckled and spotted, every one that had white in it, and all the black ones among the sheep, and gave them into the hand of his sons.",
                "He set three days\u2019 journey between himself and Jacob, and Jacob fed the rest of Laban\u2019s flocks.",
                "Jacob took to himself rods of fresh poplar, almond, plane tree, peeled white streaks in them, and made the white appear which was in the rods.",
                "He set the rods which he had peeled opposite the flocks in the gutters in the watering-troughs where the flocks came to drink. They conceived when they came to drink.",
                "The flocks conceived before the rods, and the flocks produced streaked, speckled, and spotted.",
                "Jacob separated the lambs, and set the faces of the flocks toward the streaked and all the black in the flock of Laban: and he put his own droves apart, and didn\u2019t put them into Laban\u2019s flock.",
                "It happened, whenever the stronger of the flock conceived, that Jacob laid the rods before the eyes of the flock in the gutters, that they might conceive among the rods;",
                "but when the flock were feeble, he didn\u2019t put them in. So the feebler were Laban\u2019s, and the stronger Jacob\u2019s.",
                "The man increased exceedingly, and had large flocks, female servants and male servants, and camels and donkeys."
            ],
            31: [
                "He heard the words of Laban\u2019s sons, saying, \"Jacob has taken away all that was our father\u2019s. From that which was our father\u2019s, has he gotten all this wealth.\"",
                "Jacob saw the expression on Laban\u2019s face, and, behold, it was not toward him as before.",
                "Yahweh said to Jacob, \"Return to the land of your fathers, and to your relatives, and I will be with you.\"",
                "Jacob sent and called Rachel and Leah to the field to his flock,",
                "and said to them, \"I see the expression on your father\u2019s face, that it is not toward me as before; but the God of my father has been with me.",
                "You know that I have served your father with all of my strength.",
                "Your father has deceived me, and changed my wages ten times, but God didn\u2019t allow him to hurt me.",
                "If he said this, \u2018The speckled will be your wages,\u2019 then all the flock bore speckled. If he said this, \u2018The streaked will be your wages,\u2019 then all the flock bore streaked.",
                "Thus God has taken away your father\u2019s livestock, and given them to me.",
                "It happened during mating season that I lifted up my eyes, and saw in a dream, and behold, the male goats which leaped on the flock were streaked, speckled, and grizzled.",
                "The angel of God said to me in the dream, \u2018Jacob,\u2019 and I said, \u2018Here I am.\u2019",
                "He said, \u2018Now lift up your eyes, and behold, all the male goats which leap on the flock are streaked, speckled, and grizzled, for I have seen all that Laban does to you.",
                "I am the God of Bethel, where you anointed a pillar, where you vowed a vow to me. Now arise, get out from this land, and return to the land of your birth.\u2019\"",
                "Rachel and Leah answered him, \"Is there yet any portion or inheritance for us in our father\u2019s house?",
                "Aren\u2019t we accounted by him as foreigners? For he has sold us, and has also quite devoured our money.",
                "For all the riches which God has taken away from our father, that is ours and our children\u2019s. Now then, whatever God has said to you, do.\"",
                "Then Jacob rose up, and set his sons and his wives on the camels,",
                "and he took away all his livestock, and all his possessions which he had gathered, including the livestock which he had gained in Paddan Aram, to go to Isaac his father, to the land of Canaan.",
                "Now Laban had gone to shear his sheep: and Rachel stole the teraphim that were her father\u2019s.",
                "Jacob deceived Laban the Syrian, in that he didn\u2019t tell him that he was running away.",
                "So he fled with all that he had. He rose up, passed over the River, and set his face toward the mountain of Gilead.",
                "Laban was told on the third day that Jacob had fled.",
                "He took his relatives with him, and pursued after him seven days\u2019 journey. He overtook him in the mountain of Gilead.",
                "God came to Laban, the Syrian, in a dream of the night, and said to him, \"Take heed to yourself that you don\u2019t speak to Jacob either good or bad.\"",
                "Laban caught up with Jacob. Now Jacob had pitched his tent in the mountain, and Laban with his relatives encamped in the mountain of Gilead.",
                "Laban said to Jacob, \"What have you done, that you have deceived me, and carried away my daughters like captives of the sword?",
                "Why did you flee secretly, and deceive me, and didn\u2019t tell me, that I might have sent you away with mirth and with songs, with tambourine and with harp;",
                "and didn\u2019t allow me to kiss my sons and my daughters? Now have you done foolishly.",
                "It is in the power of my hand to hurt you, but the God of your father spoke to me last night, saying, \u2018Take heed to yourself that you don\u2019t speak to Jacob either good or bad.\u2019",
                "Now, you want to be gone, because you greatly longed for your father\u2019s house, but why have you stolen my gods?\"",
                "Jacob answered Laban, \"Because I was afraid, for I said, \u2018Lest you should take your daughters from me by force.\u2019",
                "Anyone you find your gods with shall not live. Before our relatives, discern what is yours with me, and take it.\" For Jacob didn\u2019t know that Rachel had stolen them.",
                "Laban went into Jacob\u2019s tent, into Leah\u2019s tent, and into the tent of the two female servants; but he didn\u2019t find them. He went out of Leah\u2019s tent, and entered into Rachel\u2019s tent.",
                "Now Rachel had taken the teraphim, put them in the camel\u2019s saddle, and sat on them. Laban felt about all the tent, but didn\u2019t find them.",
                "She said to her father, \"Don\u2019t let my lord be angry that I can\u2019t rise up before you; for I\u2019m having my period.\" He searched, but didn\u2019t find the teraphim.",
                "Jacob was angry, and argued with Laban. Jacob answered Laban, \"What is my trespass? What is my sin, that you have hotly pursued after me?",
                "Now that you have felt around in all my stuff, what have you found of all your household stuff? Set it here before my relatives and your relatives, that they may judge between us two.",
                "\"These twenty years I have been with you. Your ewes and your female goats have not cast their young, and I haven\u2019t eaten the rams of your flocks.",
                "That which was torn of animals, I didn\u2019t bring to you. I bore its loss. Of my hand you required it, whether stolen by day or stolen by night.",
                "This was my situation: in the day the drought consumed me, and the frost by night; and my sleep fled from my eyes.",
                "These twenty years I have been in your house. I served you fourteen years for your two daughters, and six years for your flock, and you have changed my wages ten times.",
                "Unless the God of my father, the God of Abraham, and the fear of Isaac, had been with me, surely now you would have sent me away empty. God has seen my affliction and the labor of my hands, and rebuked you last night.\"",
                "Laban answered Jacob, \"The daughters are my daughters, the children are my children, the flocks are my flocks, and all that you see is mine: and what can I do this day to these my daughters, or to their children whom they have borne?",
                "Now come, let us make a covenant, you and I; and let it be for a witness between me and you.\"",
                "Jacob took a stone, and set it up for a pillar.",
                "Jacob said to his relatives, \"Gather stones.\" They took stones, and made a heap. They ate there by the heap.",
                "Laban called it Jegar Sahadutha, but Jacob called it Galeed.",
                "Laban said, \"This heap is witness between me and you this day.\" Therefore it was named Galeed",
                "and Mizpah, for he said, \"Yahweh watch between me and you, when we are absent one from another.",
                "If you afflict my daughters, or if you take wives besides my daughters, no man is with us; behold, God is witness between me and you.\"",
                "Laban said to Jacob, \"See this heap, and see the pillar, which I have set between me and you.",
                "May this heap be a witness, and the pillar be a witness, that I will not pass over this heap to you, and that you will not pass over this heap and this pillar to me, for harm.",
                "The God of Abraham, and the God of Nahor, the God of their father, judge between us.\" Then Jacob swore by the fear of his father, Isaac.",
                "Jacob offered a sacrifice in the mountain, and called his relatives to eat bread. They ate bread, and stayed all night in the mountain.",
                "Early in the morning, Laban rose up, and kissed his sons and his daughters, and blessed them. Laban departed and returned to his place."
            ],
            32: [
                "Jacob went on his way, and the angels of God met him.",
                "When he saw them, Jacob said, \"This is God\u2019s army.\" He called the name of that place Mahanaim.",
                "Jacob sent messengers in front of him to Esau, his brother, to the land of Seir, the field of Edom.",
                "He commanded them, saying, \"This is what you shall tell my lord, Esau: \u2018This is what your servant, Jacob, says. I have lived as a foreigner with Laban, and stayed until now.",
                "I have cattle, donkeys, flocks, male servants, and female servants. I have sent to tell my lord, that I may find favor in your sight.\u2019\"",
                "The messengers returned to Jacob, saying, \"We came to your brother Esau. Not only that, but he comes to meet you, and four hundred men with him.\"",
                "Then Jacob was greatly afraid and was distressed. He divided the people who were with him, and the flocks, and the herds, and the camels, into two companies;",
                "and he said, \"If Esau comes to the one company, and strikes it, then the company which is left will escape.\"",
                "Jacob said, \"God of my father Abraham, and God of my father Isaac, Yahweh, who said to me, \u2018Return to your country, and to your relatives, and I will do you good,\u2019",
                "I am not worthy of the least of all the loving kindnesses, and of all the truth, which you have shown to your servant; for with just my staff I passed over this Jordan; and now I have become two companies.",
                "Please deliver me from the hand of my brother, from the hand of Esau: for I fear him, lest he come and strike me, and the mothers with the children.",
                "You said, \u2018I will surely do you good, and make your seed as the sand of the sea, which can\u2019t be numbered because there are so many.\u2019\"",
                "He lodged there that night, and took from that which he had with him, a present for Esau, his brother:",
                "two hundred female goats and twenty male goats, two hundred ewes and twenty rams,",
                "thirty milk camels and their colts, forty cows, ten bulls, twenty female donkeys and ten foals.",
                "He delivered them into the hands of his servants, every herd by itself, and said to his servants, \"Pass over before me, and put a space between herd and herd.\"",
                "He commanded the foremost, saying, \"When Esau, my brother, meets you, and asks you, saying, \u2018Whose are you? Where are you going? Whose are these before you?\u2019",
                "Then you shall say, \u2018They are your servant, Jacob\u2019s. It is a present sent to my lord, Esau. Behold, he also is behind us.\u2019\"",
                "He commanded also the second, and the third, and all that followed the herds, saying, \"This is how you shall speak to Esau, when you find him.",
                "You shall say, \u2018Not only that, but behold, your servant, Jacob, is behind us.\u2019\" For, he said, \"I will appease him with the present that goes before me, and afterward I will see his face. Perhaps he will accept me.\"",
                "So the present passed over before him, and he himself lodged that night in the camp.",
                "He rose up that night, and took his two wives, and his two handmaids, and his eleven sons, and passed over the ford of the Jabbok.",
                "He took them, and sent them over the stream, and sent over that which he had.",
                "Jacob was left alone, and wrestled with a man there until the breaking of the day.",
                "When he saw that he didn\u2019t prevail against him, he touched the hollow of his thigh, and the hollow of Jacob\u2019s thigh was strained, as he wrestled.",
                "The man said, \"Let me go, for the day breaks.\" Jacob said, \"I won\u2019t let you go, unless you bless me.\"",
                "He said to him, \"What is your name?\" He said, \"Jacob.\"",
                "He said, \"Your name will no longer be called Jacob, but Israel; for you have fought with God and with men, and have prevailed.\"",
                "Jacob asked him, \"Please tell me your name.\" He said, \"Why is it that you ask what my name is?\" He blessed him there.",
                "Jacob called the name of the place Peniel: for, he said, \"I have seen God face to face, and my life is preserved.\"",
                "The sun rose on him as he passed over Peniel, and he limped because of his thigh.",
                "Therefore the children of Israel don\u2019t eat the sinew of the hip, which is on the hollow of the thigh, to this day, because he touched the hollow of Jacob\u2019s thigh in the sinew of the hip."
            ],
            33: [
                "Jacob lifted up his eyes, and looked, and, behold, Esau was coming, and with him four hundred men. He divided the children between Leah, Rachel, and the two handmaids.",
                "He put the handmaids and their children in front, Leah and her children after, and Rachel and Joseph at the rear.",
                "He himself passed over in front of them, and bowed himself to the ground seven times, until he came near to his brother.",
                "Esau ran to meet him, embraced him, fell on his neck, kissed him, and they wept.",
                "He lifted up his eyes, and saw the women and the children; and said, \"Who are these with you?\" He said, \"The children whom God has graciously given your servant.\"",
                "Then the handmaids came near with their children, and they bowed themselves.",
                "Leah also and her children came near, and bowed themselves. After them, Joseph came near with Rachel, and they bowed themselves.",
                "Esau said, \"What do you mean by all this company which I met?\" Jacob said, \"To find favor in the sight of my lord.\"",
                "Esau said, \"I have enough, my brother; let that which you have be yours.\"",
                "Jacob said, \"Please, no, if I have now found favor in your sight, then receive my present at my hand, because I have seen your face, as one sees the face of God, and you were pleased with me.",
                "Please take the gift that I brought to you, because God has dealt graciously with me, and because I have enough.\" He urged him, and he took it.",
                "Esau said, \"Let us take our journey, and let us go, and I will go before you.\"",
                "Jacob said to him, \"My lord knows that the children are tender, and that the flocks and herds with me have their young, and if they overdrive them one day, all the flocks will die.",
                "Please let my lord pass over before his servant, and I will lead on gently, according to the pace of the livestock that are before me and according to the pace of the children, until I come to my lord to Seir.\"",
                "Esau said, \"Let me now leave with you some of the folk who are with me.\" He said, \"Why? Let me find favor in the sight of my lord.\"",
                "So Esau returned that day on his way to Seir.",
                "Jacob traveled to Succoth, built himself a house, and made shelters for his livestock. Therefore the name of the place is called Succoth.",
                "Jacob came in peace to the city of Shechem, which is in the land of Canaan, when he came from Paddan Aram; and encamped before the city.",
                "He bought the parcel of ground where he had spread his tent, at the hand of the children of Hamor, Shechem\u2019s father, for one hundred pieces of money.",
                "He erected an altar there, and called it El Elohe Israel."
            ],
            34: [
                "Dinah, the daughter of Leah, whom she bore to Jacob, went out to see the daughters of the land.",
                "Shechem the son of Hamor the Hivite, the prince of the land, saw her. He took her, lay with her, and humbled her.",
                "His soul joined to Dinah, the daughter of Jacob, and he loved the young lady, and spoke kindly to the young lady.",
                "Shechem spoke to his father, Hamor, saying, \"Get me this young lady as a wife.\"",
                "Now Jacob heard that he had defiled Dinah, his daughter; and his sons were with his livestock in the field. Jacob held his peace until they came.",
                "Hamor the father of Shechem went out to Jacob to talk with him.",
                "The sons of Jacob came in from the field when they heard it. The men were grieved, and they were very angry, because he had done folly in Israel in lying with Jacob\u2019s daughter; a which thing ought not to be done.",
                "Hamor talked with them, saying, \"The soul of my son, Shechem, longs for your daughter. Please give her to him as a wife.",
                "Make marriages with us. Give your daughters to us, and take our daughters for yourselves.",
                "You shall dwell with us, and the land will be before you. Live and trade in it, and get possessions in it.\"",
                "Shechem said to her father and to her brothers, \"Let me find favor in your eyes, and whatever you will tell me I will give.",
                "Ask me a great amount for a dowry, and I will give whatever you ask of me, but give me the young lady as a wife.\"",
                "The sons of Jacob answered Shechem and Hamor his father with deceit, and spoke, because he had defiled Dinah their sister,",
                "and said to them, \"We can\u2019t do this thing, to give our sister to one who is uncircumcised; for that is a reproach to us.",
                "Only on this condition will we consent to you. If you will be as we are, that every male of you be circumcised;",
                "then will we give our daughters to you, and we will take your daughters to us, and we will dwell with you, and we will become one people.",
                "But if you will not listen to us, to be circumcised, then we will take our sister, and we will be gone.\"",
                "Their words pleased Hamor and Shechem, Hamor\u2019s son.",
                "The young man didn\u2019t wait to do this thing, because he had delight in Jacob\u2019s daughter, and he was honored above all the house of his father.",
                "Hamor and Shechem, his son, came to the gate of their city, and talked with the men of their city, saying,",
                "\"These men are peaceful with us. Therefore let them live in the land and trade in it. For behold, the land is large enough for them. Let us take their daughters to us for wives, and let us give them our daughters.",
                "Only on this condition will the men consent to us to live with us, to become one people, if every male among us is circumcised, as they are circumcised.",
                "Won\u2019t their livestock and their possessions and all their animals be ours? Only let us give our consent to them, and they will dwell with us.\"",
                "All who went out of the gate of his city listened to Hamor, and to Shechem his son; and every male was circumcised, all who went out of the gate of his city.",
                "It happened on the third day, when they were sore, that two of Jacob\u2019s sons, Simeon and Levi, Dinah\u2019s brothers, each took his sword, came upon the unsuspecting city, and killed all the males.",
                "They killed Hamor and Shechem, his son, with the edge of the sword, and took Dinah out of Shechem\u2019s house, and went away.",
                "Jacob\u2019s sons came on the dead, and plundered the city, because they had defiled their sister.",
                "They took their flocks, their herds, their donkeys, that which was in the city, that which was in the field,",
                "and all their wealth. They took captive all their little ones and their wives, and took as plunder everything that was in the house.",
                "Jacob said to Simeon and Levi, \"You have troubled me, to make me odious to the inhabitants of the land, among the Canaanites and the Perizzites. I am few in number. They will gather themselves together against me and strike me, and I will be destroyed, I and my house.\"",
                "They said, \"Should he deal with our sister as with a prostitute?\""
            ],
            35: [
                "God said to Jacob, \"Arise, go up to Bethel, and live there. Make there an altar to God, who appeared to you when you fled from the face of Esau your brother.\"",
                "Then Jacob said to his household, and to all who were with him, \"Put away the foreign gods that are among you, purify yourselves, change your garments.",
                "Let us arise, and go up to Bethel. I will make there an altar to God, who answered me in the day of my distress, and was with me in the way which I went.\"",
                "They gave to Jacob all the foreign gods which were in their hands, and the rings which were in their ears; and Jacob hid them under the oak which was by Shechem.",
                "They traveled, and a terror of God was on the cities that were around them, and they didn\u2019t pursue the sons of Jacob.",
                "So Jacob came to Luz (that is, Bethel), which is in the land of Canaan, he and all the people who were with him.",
                "He built an altar there, and called the place El Beth El; because there God was revealed to him, when he fled from the face of his brother.",
                "Deborah, Rebekah\u2019s nurse, died, and she was buried below Bethel under the oak; and its name was called Allon Bacuth.",
                "God appeared to Jacob again, when he came from Paddan Aram, and blessed him.",
                "God said to him, \"Your name is Jacob. Your name shall not be Jacob any more, but your name will be Israel.\" He named him Israel.",
                "God said to him, \"I am God Almighty. Be fruitful and multiply. A nation and a company of nations will be from you, and kings will come out of your body.",
                "The land which I gave to Abraham and Isaac, I will give it to you, and to your seed after you will I give the land.\"",
                "God went up from him in the place where he spoke with him.",
                "Jacob set up a pillar in the place where he spoke with him, a pillar of stone. He poured out a drink offering on it, and poured oil on it.",
                "Jacob called the name of the place where God spoke with him \"Bethel.\"",
                "They traveled from Bethel. There was still some distance to come to Ephrath, and Rachel travailed. She had hard labor.",
                "When she was in hard labor, the midwife said to her, \"Don\u2019t be afraid, for now you will have another son.\"",
                "It happened, as her soul was departing (for she died), that she named him Benoni, but his father named him Benjamin.",
                "Rachel died, and was buried in the way to Ephrath (the same is Bethlehem).",
                "Jacob set up a pillar on her grave. The same is the Pillar of Rachel\u2019s grave to this day.",
                "Israel traveled, and spread his tent beyond the tower of Eder.",
                "It happened, while Israel lived in that land, that Reuben went and lay with Bilhah, his father\u2019s concubine, and Israel heard of it. Now the sons of Jacob were twelve.",
                "The sons of Leah: Reuben (Jacob\u2019s firstborn), Simeon, Levi, Judah, Issachar, and Zebulun.",
                "The sons of Rachel: Joseph and Benjamin.",
                "The sons of Bilhah (Rachel\u2019s handmaid): Dan and Naphtali.",
                "The sons of Zilpah (Leah\u2019s handmaid): Gad and Asher. These are the sons of Jacob, who were born to him in Paddan Aram.",
                "Jacob came to Isaac his father, to Mamre, to Kiriath Arba (which is Hebron), where Abraham and Isaac lived as foreigners.",
                "The days of Isaac were one hundred eighty years.",
                "Isaac gave up the spirit, and died, and was gathered to his people, old and full of days. Esau and Jacob, his sons, buried him."
            ],
            36: [
                "Now this is the history of the generations of Esau (that is, Edom).",
                "Esau took his wives from the daughters of Canaan: Adah the daughter of Elon, the Hittite; and Oholibamah the daughter of Anah, the daughter of Zibeon, the Hivite;",
                "and Basemath, Ishmael\u2019s daughter, sister of Nebaioth.",
                "Adah bore to Esau Eliphaz. Basemath bore Reuel.",
                "Oholibamah bore Jeush, Jalam, and Korah. These are the sons of Esau, who were born to him in the land of Canaan.",
                "Esau took his wives, his sons, his daughters, and all the members of his household, with his livestock, all his animals, and all his possessions, which he had gathered in the land of Canaan, and went into a land away from his brother Jacob.",
                "For their substance was too great for them to dwell together, and the land of their travels couldn\u2019t bear them because of their livestock.",
                "Esau lived in the hill country of Seir. Esau is Edom.",
                "This is the history of the generations of Esau the father of the Edomites in the hill country of Seir:",
                "these are the names of Esau\u2019s sons: Eliphaz, the son of Adah, the wife of Esau; and Reuel, the son of Basemath, the wife of Esau.",
                "The sons of Eliphaz were Teman, Omar, Zepho, and Gatam, and Kenaz.",
                "Timna was concubine to Eliphaz, Esau\u2019s son; and she bore to Eliphaz Amalek. These are the sons of Adah, Esau\u2019s wife.",
                "These are the sons of Reuel: Nahath, Zerah, Shammah, and Mizzah. These were the sons of Basemath, Esau\u2019s wife.",
                "These were the sons of Oholibamah, the daughter of Anah, the daughter of Zibeon, Esau\u2019s wife: she bore to Esau Jeush, Jalam, and Korah.",
                "These are the chiefs of the sons of Esau: the sons of Eliphaz the firstborn of Esau: chief Teman, chief Omar, chief Zepho, chief Kenaz,",
                "chief Korah, chief Gatam, chief Amalek: these are the chiefs who came of Eliphaz in the land of Edom; these are the sons of Adah.",
                "These are the sons of Reuel, Esau\u2019s son: chief Nahath, chief Zerah, chief Shammah, chief Mizzah: these are the chiefs who came of Reuel in the land of Edom; these are the sons of Basemath, Esau\u2019s wife.",
                "These are the sons of Oholibamah, Esau\u2019s wife: chief Jeush, chief Jalam, chief Korah: these are the chiefs who came of Oholibamah the daughter of Anah, Esau\u2019s wife.",
                "These are the sons of Esau (that is, Edom), and these are their chiefs.",
                "These are the sons of Seir the Horite, the inhabitants of the land: Lotan, Shobal, Zibeon, Anah,",
                "Dishon, Ezer, and Dishan. These are the chiefs who came of the Horites, the children of Seir in the land of Edom.",
                "The children of Lotan were Hori and Heman. Lotan\u2019s sister was Timna.",
                "These are the children of Shobal: Alvan, Manahath, Ebal, Shepho, and Onam.",
                "These are the children of Zibeon: Aiah and Anah. This is Anah who found the hot springs in the wilderness, as he fed the donkeys of Zibeon his father.",
                "These are the children of Anah: Dishon and Oholibamah, the daughter of Anah.",
                "These are the children of Dishon: Hemdan, Eshban, Ithran, and Cheran.",
                "These are the children of Ezer: Bilhan, Zaavan, and Akan.",
                "These are the children of Dishan: Uz and Aran.",
                "These are the chiefs who came of the Horites: chief Lotan, chief Shobal, chief Zibeon, chief Anah,",
                "chief Dishon, chief Ezer, and chief Dishan: these are the chiefs who came of the Horites, according to their chiefs in the land of Seir.",
                "These are the kings who reigned in the land of Edom, before any king reigned over the children of Israel.",
                "Bela, the son of Beor, reigned in Edom. The name of his city was Dinhabah.",
                "Bela died, and Jobab, the son of Zerah of Bozrah, reigned in his place.",
                "Jobab died, and Husham of the land of the Temanites reigned in his place.",
                "Husham died, and Hadad, the son of Bedad, who struck Midian in the field of Moab, reigned in his place. The name of his city was Avith.",
                "Hadad died, and Samlah of Masrekah reigned in his place.",
                "Samlah died, and Shaul of Rehoboth by the river, reigned in his place.",
                "Shaul died, and Baal Hanan, the son of Achbor reigned in his place.",
                "Baal Hanan the son of Achbor died, and Hadar reigned in his place. The name of his city was Pau. His wife\u2019s name was Mehetabel, the daughter of Matred, the daughter of Mezahab.",
                "These are the names of the chiefs who came from Esau, according to their families, after their places, and by their names: chief Timna, chief Alvah, chief Jetheth,",
                "chief Oholibamah, chief Elah, chief Pinon,",
                "chief Kenaz, chief Teman, chief Mibzar,",
                "chief Magdiel, and chief Iram. These are the chiefs of Edom, according to their habitations in the land of their possession. This is Esau, the father of the Edomites."
            ],
            37: [
                "Jacob lived in the land of his father\u2019s travels, in the land of Canaan.",
                "This is the history of the generations of Jacob. Joseph, being seventeen years old, was feeding the flock with his brothers. He was a boy with the sons of Bilhah and Zilpah, his father\u2019s wives. Joseph brought an evil report of them to their father.",
                "Now Israel loved Joseph more than all his children, because he was the son of his old age, and he made him a coat of many colors.",
                "His brothers saw that their father loved him more than all his brothers, and they hated him, and couldn\u2019t speak peaceably to him.",
                "Joseph dreamed a dream, and he told it to his brothers, and they hated him all the more.",
                "He said to them, \"Please hear this dream which I have dreamed:",
                "for behold, we were binding sheaves in the field, and behold, my sheaf arose and also stood upright; and behold, your sheaves came around, and bowed down to my sheaf.\"",
                "His brothers said to him, \"Will you indeed reign over us? Or will you indeed have dominion over us?\" They hated him all the more for his dreams and for his words.",
                "He dreamed yet another dream, and told it to his brothers, and said, \"Behold, I have dreamed yet another dream: and behold, the sun and the moon and eleven stars bowed down to me.\"",
                "He told it to his father and to his brothers. His father rebuked him, and said to him, \"What is this dream that you have dreamed? Will I and your mother and your brothers indeed come to bow ourselves down to you to the earth?\"",
                "His brothers envied him, but his father kept this saying in mind.",
                "His brothers went to feed their father\u2019s flock in Shechem.",
                "Israel said to Joseph, \"Aren\u2019t your brothers feeding the flock in Shechem? Come, and I will send you to them.\" He said to him, \"Here I am.\"",
                "He said to him, \"Go now, see whether it is well with your brothers, and well with the flock; and bring me word again.\" So he sent him out of the valley of Hebron, and he came to Shechem.",
                "A certain man found him, and behold, he was wandering in the field. The man asked him, \"What are you looking for?\"",
                "He said, \"I am looking for my brothers. Tell me, please, where they are feeding the flock.\"",
                "The man said, \"They have left here, for I heard them say, \u2018Let us go to Dothan.\u2019\" Joseph went after his brothers, and found them in Dothan.",
                "They saw him afar off, and before he came near to them, they conspired against him to kill him.",
                "They said one to another, \"Behold, this dreamer comes.",
                "Come now therefore, and let\u2019s kill him, and cast him into one of the pits, and we will say, \u2018An evil animal has devoured him.\u2019 We will see what will become of his dreams.\"",
                "Reuben heard it, and delivered him out of their hand, and said, \"Let\u2019s not take his life.\"",
                "Reuben said to them, \"Shed no blood. Throw him into this pit that is in the wilderness, but lay no hand on him\"\u2014that he might deliver him out of their hand, to restore him to his father.",
                "It happened, when Joseph came to his brothers, that they stripped Joseph of his coat, the coat of many colors that was on him;",
                "and they took him, and threw him into the pit. The pit was empty. There was no water in it.",
                "They sat down to eat bread, and they lifted up their eyes and looked, and saw a caravan of Ishmaelites was coming from Gilead, with their camels bearing spices and balm and myrrh, going to carry it down to Egypt.",
                "Judah said to his brothers, \"What profit is it if we kill our brother and conceal his blood?",
                "Come, and let\u2019s sell him to the Ishmaelites, and not let our hand be on him; for he is our brother, our flesh.\" His brothers listened to him.",
                "Midianites who were merchants passed by, and they drew and lifted up Joseph out of the pit, and sold Joseph to the Ishmaelites for twenty pieces of silver. They brought Joseph into Egypt.",
                "Reuben returned to the pit; and saw that Joseph wasn\u2019t in the pit; and he tore his clothes.",
                "He returned to his brothers, and said, \"The child is no more; and I, where will I go?\"",
                "They took Joseph\u2019s coat, and killed a male goat, and dipped the coat in the blood.",
                "They took the coat of many colors, and they brought it to their father, and said, \"We have found this. Examine it, now, whether it is your son\u2019s coat or not.\"",
                "He recognized it, and said, \"It is my son\u2019s coat. An evil animal has devoured him. Joseph is without doubt torn in pieces.\"",
                "Jacob tore his clothes, and put sackcloth on his waist, and mourned for his son many days.",
                "All his sons and all his daughters rose up to comfort him, but he refused to be comforted. He said, \"For I will go down to Sheol to my son mourning.\" His father wept for him.",
                "The Midianites sold him into Egypt to Potiphar, an officer of Pharaoh\u2019s, the captain of the guard."
            ],
            38: [
                "It happened at that time, that Judah went down from his brothers, and visited a certain Adullamite, whose name was Hirah.",
                "Judah saw there a daughter of a certain Canaanite whose name was Shua. He took her, and went in to her.",
                "She conceived, and bore a son; and he named him Er.",
                "She conceived again, and bore a son; and she named him Onan.",
                "She yet again bore a son, and named him Shelah: and he was at Chezib, when she bore him.",
                "Judah took a wife for Er, his firstborn, and her name was Tamar.",
                "Er, Judah\u2019s firstborn, was wicked in the sight of Yahweh. Yahweh killed him.",
                "Judah said to Onan, \"Go in to your brother\u2019s wife, and perform the duty of a husband\u2019s brother to her, and raise up seed to your brother.\"",
                "Onan knew that the seed wouldn\u2019t be his; and it happened, when he went in to his brother\u2019s wife, that he spilled it on the ground, lest he should give seed to his brother.",
                "The thing which he did was evil in the sight of Yahweh, and he killed him also.",
                "Then Judah said to Tamar, his daughter-in-law, \"Remain a widow in your father\u2019s house, until Shelah, my son, is grown up;\" for he said, \"Lest he also die, like his brothers.\" Tamar went and lived in her father\u2019s house.",
                "After many days, Shua\u2019s daughter, the wife of Judah, died. Judah was comforted, and went up to his sheepshearers to Timnah, he and his friend Hirah, the Adullamite.",
                "It was told Tamar, saying, \"Behold, your father-in-law is going up to Timnah to shear his sheep.\"",
                "She took off of her the garments of her widowhood, and covered herself with her veil, and wrapped herself, and sat in the gate of Enaim, which is by the way to Timnah; for she saw that Shelah was grown up, and she wasn\u2019t given to him as a wife.",
                "When Judah saw her, he thought that she was a prostitute, for she had covered her face.",
                "He turned to her by the way, and said, \"Please come, let me come in to you,\" for he didn\u2019t know that she was his daughter-in-law. She said, \"What will you give me, that you may come in to me?\"",
                "He said, \"I will send you a young goat from the flock.\" She said, \"Will you give me a pledge, until you send it?\"",
                "He said, \"What pledge will I give you?\" She said, \"Your signet and your cord, and your staff that is in your hand.\" He gave them to her, and came in to her, and she conceived by him.",
                "She arose, and went away, and put off her veil from her, and put on the garments of her widowhood.",
                "Judah sent the young goat by the hand of his friend, the Adullamite, to receive the pledge from the woman\u2019s hand, but he didn\u2019t find her.",
                "Then he asked the men of her place, saying, \"Where is the prostitute, that was at Enaim by the road?\" They said, \"There has been no prostitute here.\"",
                "He returned to Judah, and said, \"I haven\u2019t found her; and also the men of the place said, \u2018There has been no prostitute here.\u2019\"",
                "Judah said, \"Let her keep it, lest we be shamed. Behold, I sent this young goat, and you haven\u2019t found her.\"",
                "It happened about three months later, that it was told Judah, saying, \"Tamar, your daughter-in-law, has played the prostitute; and moreover, behold, she is with child by prostitution.\" Judah said, \"Bring her out, and let her be burnt.\"",
                "When she was brought out, she sent to her father-in-law, saying, \"By the man, whose these are, I am with child.\" She also said, \"Please discern whose are these\u2014the signet, and the cords, and the staff.\"",
                "Judah acknowledged them, and said, \"She is more righteous than I, because I didn\u2019t give her to Shelah, my son.\" He knew her again no more.",
                "It happened in the time of her travail, that behold, twins were in her womb.",
                "When she travailed, one put out a hand, and the midwife took and tied a scarlet thread on his hand, saying, \"This came out first.\"",
                "It happened, as he drew back his hand, that behold, his brother came out, and she said, \"Why have you made a breach for yourself?\" Therefore his name was called Perez.",
                "Afterward his brother came out, that had the scarlet thread on his hand, and his name was called Zerah."
            ],
            39: [
                "Joseph was brought down to Egypt. Potiphar, an officer of Pharaoh\u2019s, the captain of the guard, an Egyptian, bought him from the hand of the Ishmaelites that had brought him down there.",
                "Yahweh was with Joseph, and he was a prosperous man. He was in the house of his master the Egyptian.",
                "His master saw that Yahweh was with him, and that Yahweh made all that he did prosper in his hand.",
                "Joseph found favor in his sight. He ministered to him, and he made him overseer over his house, and all that he had he put into his hand.",
                "It happened from the time that he made him overseer in his house, and over all that he had, that Yahweh blessed the Egyptian\u2019s house for Joseph\u2019s sake; and the blessing of Yahweh was on all that he had, in the house and in the field.",
                "He left all that he had in Joseph\u2019s hand. He didn\u2019t concern himself with anything, except for the food which he ate. Joseph was well-built and handsome.",
                "It happened after these things, that his master\u2019s wife cast her eyes on Joseph; and she said, \"Lie with me.\"",
                "But he refused, and said to his master\u2019s wife, \"Behold, my master doesn\u2019t know what is with me in the house, and he has put all that he has into my hand.",
                "He isn\u2019t greater in this house than I, neither has he kept back anything from me but you, because you are his wife. How then can I do this great wickedness, and sin against God?\"",
                "As she spoke to Joseph day by day, he didn\u2019t listen to her, to lie by her, or to be with her.",
                "About this time, he went into the house to do his work, and there were none of the men of the house inside.",
                "She caught him by his garment, saying, \"Lie with me!\" He left his garment in her hand, and ran outside.",
                "When she saw that he had left his garment in her hand, and had run outside,",
                "she called to the men of her house, and spoke to them, saying, \"Behold, he has brought in a Hebrew to us to mock us. He came in to me to lie with me, and I cried with a loud voice.",
                "It happened, when he heard that I lifted up my voice and cried, that he left his garment by me, and ran outside.\"",
                "She laid up his garment by her, until his master came home.",
                "She spoke to him according to these words, saying, \"The Hebrew servant, whom you have brought to us, came in to me to mock me,",
                "and it happened, as I lifted up my voice and cried, that he left his garment by me, and ran outside.\"",
                "It happened, when his master heard the words of his wife, which she spoke to him, saying, \"This is what your servant did to me,\" that his wrath was kindled.",
                "Joseph\u2019s master took him, and put him into the prison, the place where the king\u2019s prisoners were bound, and he was there in custody.",
                "But Yahweh was with Joseph, and showed kindness to him, and gave him favor in the sight of the keeper of the prison.",
                "The keeper of the prison committed to Joseph\u2019s hand all the prisoners who were in the prison. Whatever they did there, he was responsible for it.",
                "The keeper of the prison didn\u2019t look after anything that was under his hand, because Yahweh was with him; and that which he did, Yahweh made it prosper."
            ],
            40: [
                "It happened after these things, that the butler of the king of Egypt and his baker offended their lord, the king of Egypt.",
                "Pharaoh was angry with his two officers, the chief cupbearer and the chief baker.",
                "He put them in custody in the house of the captain of the guard, into the prison, the place where Joseph was bound.",
                "The captain of the guard assigned them to Joseph, and he took care of them. They stayed in prison many days.",
                "They both dreamed a dream, each man his dream, in one night, each man according to the interpretation of his dream, the cupbearer and the baker of the king of Egypt, who were bound in the prison.",
                "Joseph came in to them in the morning, and saw them, and saw that they were sad.",
                "He asked Pharaoh\u2019s officers who were with him in custody in his master\u2019s house, saying, \"Why do you look so sad today?\"",
                "They said to him, \"We have dreamed a dream, and there is no one who can interpret it.\" Joseph said to them, \"Don\u2019t interpretations belong to God? Please tell it to me.\"",
                "The chief cupbearer told his dream to Joseph, and said to him, \"In my dream, behold, a vine was in front of me,",
                "and in the vine were three branches. It was as though it budded, it blossomed, and its clusters produced ripe grapes.",
                "Pharaoh\u2019s cup was in my hand; and I took the grapes, and pressed them into Pharaoh\u2019s cup, and I gave the cup into Pharaoh\u2019s hand.\"",
                "Joseph said to him, \"This is its interpretation: the three branches are three days.",
                "Within three more days, Pharaoh will lift up your head, and restore you to your office. You will give Pharaoh\u2019s cup into his hand, the way you did when you were his cupbearer.",
                "But remember me when it will be well with you, and please show kindness to me, and make mention of me to Pharaoh, and bring me out of this house.",
                "For indeed, I was stolen away out of the land of the Hebrews, and here also have I done nothing that they should put me into the dungeon.\"",
                "When the chief baker saw that the interpretation was good, he said to Joseph, \"I also was in my dream, and behold, three baskets of white bread were on my head.",
                "In the uppermost basket there was all kinds of baked food for Pharaoh, and the birds ate them out of the basket on my head.\"",
                "Joseph answered, \"This is its interpretation. The three baskets are three days.",
                "Within three more days, Pharaoh will lift up your head from off you, and will hang you on a tree; and the birds will eat your flesh from off you.\"",
                "It happened the third day, which was Pharaoh\u2019s birthday, that he made a feast for all his servants, and he lifted up the head of the chief cupbearer and the head of the chief baker among his servants.",
                "He restored the chief cupbearer to his position again, and he gave the cup into Pharaoh\u2019s hand;",
                "but he hanged the chief baker, as Joseph had interpreted to them.",
                "Yet the chief cupbearer didn\u2019t remember Joseph, but forgot him."
            ],
            41: [
                "It happened at the end of two full years, that Pharaoh dreamed: and behold, he stood by the river.",
                "Behold, there came up out of the river seven cattle, sleek and fat, and they fed in the marsh grass.",
                "Behold, seven other cattle came up after them out of the river, ugly and thin, and stood by the other cattle on the brink of the river.",
                "The ugly and thin cattle ate up the seven sleek and fat cattle. So Pharaoh awoke.",
                "He slept and dreamed a second time: and behold, seven heads of grain came up on one stalk, healthy and good.",
                "Behold, seven heads of grain, thin and blasted with the east wind, sprung up after them.",
                "The thin heads of grain swallowed up the seven healthy and full ears. Pharaoh awoke, and behold, it was a dream.",
                "It happened in the morning that his spirit was troubled, and he sent and called for all of Egypt\u2019s magicians and wise men. Pharaoh told them his dreams, but there was no one who could interpret them to Pharaoh.",
                "Then the chief cupbearer spoke to Pharaoh, saying, \"I remember my faults today.",
                "Pharaoh was angry with his servants, and put me in custody in the house of the captain of the guard, me and the chief baker.",
                "We dreamed a dream in one night, I and he. We dreamed each man according to the interpretation of his dream.",
                "There was with us there a young man, a Hebrew, servant to the captain of the guard, and we told him, and he interpreted to us our dreams. To each man according to his dream he interpreted.",
                "It happened, as he interpreted to us, so it was: he restored me to my office, and he hanged him.\"",
                "Then Pharaoh sent and called Joseph, and they brought him hastily out of the dungeon. He shaved himself, changed his clothing, and came in to Pharaoh.",
                "Pharaoh said to Joseph, \"I have dreamed a dream, and there is no one who can interpret it. I have heard it said of you, that when you hear a dream you can interpret it.\"",
                "Joseph answered Pharaoh, saying, \"It isn\u2019t in me. God will give Pharaoh an answer of peace.\"",
                "Pharaoh spoke to Joseph, \"In my dream, behold, I stood on the brink of the river:",
                "and behold, there came up out of the river seven cattle, fat and sleek. They fed in the marsh grass,",
                "and behold, seven other cattle came up after them, poor and very ugly and thin, such as I never saw in all the land of Egypt for ugliness.",
                "The thin and ugly cattle ate up the first seven fat cattle,",
                "and when they had eaten them up, it couldn\u2019t be known that they had eaten them, but they were still ugly, as at the beginning. So I awoke.",
                "I saw in my dream, and behold, seven heads of grain came up on one stalk, full and good:",
                "and behold, seven heads of grain, withered, thin, and blasted with the east wind, sprung up after them.",
                "The thin heads of grain swallowed up the seven good heads of grain. I told it to the magicians, but there was no one who could explain it to me.\"",
                "Joseph said to Pharaoh, \"The dream of Pharaoh is one. What God is about to do he has declared to Pharaoh.",
                "The seven good cattle are seven years; and the seven good heads of grain are seven years. The dream is one.",
                "The seven thin and ugly cattle that came up after them are seven years, and also the seven empty heads of grain blasted with the east wind; they will be seven years of famine.",
                "That is the thing which I spoke to Pharaoh. What God is about to do he has shown to Pharaoh.",
                "Behold, there come seven years of great plenty throughout all the land of Egypt.",
                "There will arise after them seven years of famine, and all the plenty will be forgotten in the land of Egypt. The famine will consume the land,",
                "and the plenty will not be known in the land by reason of that famine which follows; for it will be very grievous.",
                "The dream was doubled to Pharaoh, because the thing is established by God, and God will shortly bring it to pass.",
                "\"Now therefore let Pharaoh look for a discreet and wise man, and set him over the land of Egypt.",
                "Let Pharaoh do this, and let him appoint overseers over the land, and take up the fifth part of the land of Egypt\u2019s produce in the seven plenteous years.",
                "Let them gather all the food of these good years that come, and lay up grain under the hand of Pharaoh for food in the cities, and let them keep it.",
                "The food will be for a store to the land against the seven years of famine, which will be in the land of Egypt; that the land not perish through the famine.\"",
                "The thing was good in the eyes of Pharaoh, and in the eyes of all his servants.",
                "Pharaoh said to his servants, \"Can we find such a one as this, a man in whom is the Spirit of God?\"",
                "Pharaoh said to Joseph, \"Because God has shown you all of this, there is none so discreet and wise as you.",
                "You shall be over my house, and according to your word will all my people be ruled. Only in the throne I will be greater than you.\"",
                "Pharaoh said to Joseph, \"Behold, I have set you over all the land of Egypt.\"",
                "Pharaoh took off his signet ring from his hand, and put it on Joseph\u2019s hand, and arrayed him in robes of fine linen, and put a gold chain about his neck,",
                "and he made him to ride in the second chariot which he had. They cried before him, \"Bow the knee!\" He set him over all the land of Egypt.",
                "Pharaoh said to Joseph, \"I am Pharaoh, and without you shall no man lift up his hand or his foot in all the land of Egypt.\"",
                "Pharaoh called Joseph\u2019s name Zaphenath-Paneah; and he gave him Asenath, the daughter of Potiphera priest of On as a wife. Joseph went out over the land of Egypt.",
                "Joseph was thirty years old when he stood before Pharaoh king of Egypt. Joseph went out from the presence of Pharaoh, and went throughout all the land of Egypt.",
                "In the seven plenteous years the earth produced abundantly.",
                "He gathered up all the food of the seven years which were in the land of Egypt, and laid up the food in the cities: the food of the field, which was around every city, he laid up in the same.",
                "Joseph laid up grain as the sand of the sea, very much, until he stopped counting, for it was without number.",
                "To Joseph were born two sons before the year of famine came, whom Asenath, the daughter of Potiphera priest of On, bore to him.",
                "Joseph called the name of the firstborn Manasseh, \"For,\" he said, \"God has made me forget all my toil, and all my father\u2019s house.\"",
                "The name of the second, he called Ephraim: \"For God has made me fruitful in the land of my affliction.\"",
                "The seven years of plenty, that were in the land of Egypt, came to an end.",
                "The seven years of famine began to come, just as Joseph had said. There was famine in all lands, but in all the land of Egypt there was bread.",
                "When all the land of Egypt was famished, the people cried to Pharaoh for bread, and Pharaoh said to all the Egyptians, \"Go to Joseph. What he says to you, do.\"",
                "The famine was over all the surface of the earth. Joseph opened all the store houses, and sold to the Egyptians. The famine was severe in the land of Egypt.",
                "All countries came into Egypt, to Joseph, to buy grain, because the famine was severe in all the earth."
            ],
            42: [
                "Now Jacob saw that there was grain in Egypt, and Jacob said to his sons, \"Why do you look at one another?\"",
                "He said, \"Behold, I have heard that there is grain in Egypt. Go down there, and buy for us from there, so that we may live, and not die.\"",
                "Joseph\u2019s ten brothers went down to buy grain from Egypt.",
                "But Jacob didn\u2019t send Benjamin, Joseph\u2019s brother, with his brothers; for he said, \"Lest perhaps harm happen to him.\"",
                "The sons of Israel came to buy among those who came, for the famine was in the land of Canaan.",
                "Joseph was the governor over the land. It was he who sold to all the people of the land. Joseph\u2019s brothers came, and bowed themselves down to him with their faces to the earth.",
                "Joseph saw his brothers, and he recognized them, but acted like a stranger to them, and spoke roughly with them. He said to them, \"Where did you come from?\" They said, \"From the land of Canaan to buy food.\"",
                "Joseph recognized his brothers, but they didn\u2019t recognize him.",
                "Joseph remembered the dreams which he dreamed about them, and said to them, \"You are spies! You have come to see the nakedness of the land.\"",
                "They said to him, \"No, my lord, but your servants have come to buy food.",
                "We are all one man\u2019s sons; we are honest men. Your servants are not spies.\"",
                "He said to them, \"No, but you have come to see the nakedness of the land!\"",
                "They said, \"We, your servants, are twelve brothers, the sons of one man in the land of Canaan; and behold, the youngest is this day with our father, and one is no more.\"",
                "Joseph said to them, \"It is like I told you, saying, \u2018You are spies!\u2019",
                "By this you shall be tested. By the life of Pharaoh, you shall not go out from here, unless your youngest brother comes here.",
                "Send one of you, and let him get your brother, and you shall be bound, that your words may be tested, whether there is truth in you, or else by the life of Pharaoh surely you are spies.\"",
                "He put them all together into custody for three days.",
                "Joseph said to them the third day, \"Do this, and live, for I fear God.",
                "If you are honest men, then let one of your brothers be bound in your prison; but you go, carry grain for the famine of your houses.",
                "Bring your youngest brother to me; so will your words be verified, and you won\u2019t die.\" They did so.",
                "They said one to another, \"We are certainly guilty concerning our brother, in that we saw the distress of his soul, when he begged us, and we wouldn\u2019t listen. Therefore this distress has come upon us.\"",
                "Reuben answered them, saying, \"Didn\u2019t I tell you, saying, \u2018Don\u2019t sin against the child,\u2019 and you wouldn\u2019t listen? Therefore also, behold, his blood is required.\"",
                "They didn\u2019t know that Joseph understood them; for there was an interpreter between them.",
                "He turned himself away from them, and wept. Then he returned to them, and spoke to them, and took Simeon from among them, and bound him before their eyes.",
                "Then Joseph gave a command to fill their bags with grain, and to restore each man\u2019s money into his sack, and to give them food for the way. So it was done to them.",
                "They loaded their donkeys with their grain, and departed from there.",
                "As one of them opened his sack to give his donkey food in the lodging place, he saw his money. Behold, it was in the mouth of his sack.",
                "He said to his brothers, \"My money is restored! Behold, it is in my sack!\" Their hearts failed them, and they turned trembling one to another, saying, \"What is this that God has done to us?\"",
                "They came to Jacob their father, to the land of Canaan, and told him all that had happened to them, saying,",
                "\"The man, the lord of the land, spoke roughly with us, and took us for spies of the country.",
                "We said to him, \u2018We are honest men. We are no spies.",
                "We are twelve brothers, sons of our father; one is no more, and the youngest is this day with our father in the land of Canaan.\u2019",
                "The man, the lord of the land, said to us, \u2018By this I will know that you are honest men: leave one of your brothers with me, and take grain for the famine of your houses, and go your way.",
                "Bring your youngest brother to me. Then I will know that you are not spies, but that you are honest men. So I will deliver your brother to you, and you shall trade in the land.\u2019\"",
                "It happened as they emptied their sacks, that behold, each man\u2019s bundle of money was in his sack. When they and their father saw their bundles of money, they were afraid.",
                "Jacob, their father, said to them, \"You have bereaved me of my children! Joseph is no more, Simeon is no more, and you want to take Benjamin away. All these things are against me.\"",
                "Reuben spoke to his father, saying, \"Kill my two sons, if I don\u2019t bring him to you. Entrust him to my care, and I will bring him to you again.\"",
                "He said, \"My son shall not go down with you; for his brother is dead, and he only is left. If harm happens to him along the way in which you go, then you will bring down my gray hairs with sorrow to Sheol.\""
            ],
            43: [
                "The famine was severe in the land.",
                "It happened, when they had eaten up the grain which they had brought out of Egypt, their father said to them, \"Go again, buy us a little more food.\"",
                "Judah spoke to him, saying, \"The man solemnly warned us, saying, \u2018You shall not see my face, unless your brother is with you.\u2019",
                "If you\u2019ll send our brother with us, we\u2019ll go down and buy you food,",
                "but if you\u2019ll not send him, we\u2019ll not go down, for the man said to us, \u2018You shall not see my face, unless your brother is with you.\u2019\"",
                "Israel said, \"Why did you treat me so badly, telling the man that you had another brother?\"",
                "They said, \"The man asked directly concerning ourselves, and concerning our relatives, saying, \u2018Is your father still alive? Have you another brother?\u2019 We just answered his questions. Is there any way we could know that he would say, \u2018Bring your brother down?\u2019\"",
                "Judah said to Israel, his father, \"Send the boy with me, and we\u2019ll get up and go, so that we may live, and not die, both we, and you, and also our little ones.",
                "I\u2019ll be collateral for him. From my hand will you require him. If I don\u2019t bring him to you, and set him before you, then let me bear the blame forever,",
                "for if we hadn\u2019t delayed, surely we would have returned a second time by now.\"",
                "Their father, Israel, said to them, \"If it must be so, then do this. Take from the choice fruits of the land in your bags, and carry down a present for the man, a little balm, a little honey, spices and myrrh, nuts, and almonds;",
                "and take double money in your hand, and take back the money that was returned in the mouth of your sacks. Perhaps it was an oversight.",
                "Take your brother also, get up, and return to the man.",
                "May God Almighty give you mercy before the man, that he may release to you your other brother and Benjamin. If I am bereaved of my children, I am bereaved.\"",
                "The men took that present, and they took double money in their hand, and Benjamin; and got up, went down to Egypt, and stood before Joseph.",
                "When Joseph saw Benjamin with them, he said to the steward of his house, \"Bring the men into the house, and butcher an animal, and prepare; for the men will dine with me at noon.\"",
                "The man did as Joseph commanded, and the man brought the men to Joseph\u2019s house.",
                "The men were afraid, because they were brought to Joseph\u2019s house; and they said, \"Because of the money that was returned in our sacks at the first time, we\u2019re brought in; that he may seek occasion against us, attack us, and seize us as slaves, along with our donkeys.\"",
                "They came near to the steward of Joseph\u2019s house, and they spoke to him at the door of the house,",
                "and said, \"Oh, my lord, we indeed came down the first time to buy food.",
                "When we came to the lodging place, we opened our sacks, and behold, each man\u2019s money was in the mouth of his sack, our money in full weight. We have brought it back in our hand.",
                "We have brought down other money in our hand to buy food. We don\u2019t know who put our money in our sacks.\"",
                "He said, \"Peace be to you. Don\u2019t be afraid. Your God, and the God of your father, has given you treasure in your sacks. I received your money.\" He brought Simeon out to them.",
                "The man brought the men into Joseph\u2019s house, and gave them water, and they washed their feet. He gave their donkeys fodder.",
                "They prepared the present for Joseph\u2019s coming at noon, for they heard that they should eat bread there.",
                "When Joseph came home, they brought him the present which was in their hand into the house, and bowed themselves down to him to the earth.",
                "He asked them of their welfare, and said, \"Is your father well, the old man of whom you spoke? Is he yet alive?\"",
                "They said, \"Your servant, our father, is well. He is still alive.\" They bowed down humbly.",
                "He lifted up his eyes, and saw Benjamin, his brother, his mother\u2019s son, and said, \"Is this your youngest brother, of whom you spoke to me?\" He said, \"God be gracious to you, my son.\"",
                "Joseph hurried, for his heart yearned over his brother; and he sought a place to weep. He entered into his room, and wept there.",
                "He washed his face, and came out. He controlled himself, and said, \"Serve the meal.\"",
                "They served him by himself, and them by themselves, and the Egyptians, that ate with him, by themselves, because the Egyptians don\u2019t eat bread with the Hebrews, for that is an abomination to the Egyptians.",
                "They sat before him, the firstborn according to his birthright, and the youngest according to his youth, and the men marveled one with another.",
                "He sent portions to them from before him, but Benjamin\u2019s portion was five times as much as any of theirs. They drank, and were merry with him."
            ],
            44: [
                "He commanded the steward of his house, saying, \"Fill the men\u2019s sacks with food, as much as they can carry, and put each man\u2019s money in his sack\u2019s mouth.",
                "Put my cup, the silver cup, in the sack\u2019s mouth of the youngest, with his grain money.\" He did according to the word that Joseph had spoken.",
                "As soon as the morning was light, the men were sent away, they and their donkeys.",
                "When they had gone out of the city, and were not yet far off, Joseph said to his steward, \"Up, follow after the men. When you overtake them, ask them, \u2018Why have you rewarded evil for good?",
                "Isn\u2019t this that from which my lord drinks, and by which he indeed divines? You have done evil in so doing.\u2019\"",
                "He overtook them, and he spoke these words to them.",
                "They said to him, \"Why does my lord speak such words as these? Far be it from your servants that they should do such a thing!",
                "Behold, the money, which we found in our sacks\u2019 mouths, we brought again to you out of the land of Canaan. How then should we steal silver or gold out of your lord\u2019s house?",
                "With whomever of your servants it is found, let him die, and we also will be my lord\u2019s bondservants.\"",
                "He said, \"Now also let it be according to your words: he with whom it is found will be my bondservant; and you will be blameless.\"",
                "Then they hurried, and each man took his sack down to the ground, and each man opened his sack.",
                "He searched, beginning with the eldest, and ending at the youngest. The cup was found in Benjamin\u2019s sack.",
                "Then they tore their clothes, and each man loaded his donkey, and returned to the city.",
                "Judah and his brothers came to Joseph\u2019s house, and he was still there. They fell on the ground before him.",
                "Joseph said to them, \"What deed is this that you have done? Don\u2019t you know that such a man as I can indeed divine?\"",
                "Judah said, \"What will we tell my lord? What will we speak? Or how will we clear ourselves? God has found out the iniquity of your servants. Behold, we are my lord\u2019s bondservants, both we, and he also in whose hand the cup is found.\"",
                "He said, \"Far be it from me that I should do so. The man in whose hand the cup is found, he will be my bondservant; but as for you, go up in peace to your father.\"",
                "Then Judah came near to him, and said, \"Oh, my lord, please let your servant speak a word in my lord\u2019s ears, and don\u2019t let your anger burn against your servant; for you are even as Pharaoh.",
                "My lord asked his servants, saying, \u2018Have you a father, or a brother?\u2019",
                "We said to my lord, \u2018We have a father, an old man, and a child of his old age, a little one; and his brother is dead, and he alone is left of his mother; and his father loves him.\u2019",
                "You said to your servants, \u2018Bring him down to me, that I may set my eyes on him.\u2019",
                "We said to my lord, \u2018The boy can\u2019t leave his father: for if he should leave his father, his father would die.\u2019",
                "You said to your servants, \u2018Unless your youngest brother comes down with you, you will see my face no more.\u2019",
                "It happened when we came up to your servant my father, we told him the words of my lord.",
                "Our father said, \u2018Go again, buy us a little food.\u2019",
                "We said, \u2018We can\u2019t go down. If our youngest brother is with us, then we will go down: for we may not see the man\u2019s face, unless our youngest brother is with us.\u2019",
                "Your servant, my father, said to us, \u2018You know that my wife bore me two sons:",
                "and the one went out from me, and I said, \"Surely he is torn in pieces;\" and I haven\u2019t seen him since.",
                "If you take this one also from me, and harm happens to him, you will bring down my gray hairs with sorrow to Sheol.\u2019",
                "Now therefore when I come to your servant my father, and the boy is not with us; since his life is bound up in the boy\u2019s life;",
                "it will happen, when he sees that the boy is no more, that he will die. Your servants will bring down the gray hairs of your servant, our father, with sorrow to Sheol.",
                "For your servant became collateral for the boy to my father, saying, \u2018If I don\u2019t bring him to you, then I will bear the blame to my father forever.\u2019",
                "Now therefore, please let your servant stay instead of the boy, a bondservant to my lord; and let the boy go up with his brothers.",
                "For how will I go up to my father, if the boy isn\u2019t with me?\u2014lest I see the evil that will come on my father.\""
            ],
            45: [
                "Then Joseph couldn\u2019t control himself before all those who stood before him, and he cried, \"Cause everyone to go out from me!\" No one else stood with him, while Joseph made himself known to his brothers.",
                "He wept aloud. The Egyptians heard, and the house of Pharaoh heard.",
                "Joseph said to his brothers, \"I am Joseph! Does my father still live?\" His brothers couldn\u2019t answer him; for they were terrified at his presence.",
                "Joseph said to his brothers, \"Come near to me, please.\" They came near. \"He said, I am Joseph, your brother, whom you sold into Egypt.",
                "Now don\u2019t be grieved, nor angry with yourselves, that you sold me here, for God sent me before you to preserve life.",
                "For these two years the famine has been in the land, and there are yet five years, in which there will be neither plowing nor harvest.",
                "God sent me before you to preserve for you a remnant in the earth, and to save you alive by a great deliverance.",
                "So now it wasn\u2019t you who sent me here, but God, and he has made me a father to Pharaoh, lord of all his house, and ruler over all the land of Egypt.",
                "Hurry, and go up to my father, and tell him, \u2018This is what your son Joseph says, \"God has made me lord of all Egypt. Come down to me. Don\u2019t wait.",
                "You shall dwell in the land of Goshen, and you will be near to me, you, your children, your children\u2019s children, your flocks, your herds, and all that you have.",
                "There I will nourish you; for there are yet five years of famine; lest you come to poverty, you, and your household, and all that you have.\"\u2019",
                "Behold, your eyes see, and the eyes of my brother Benjamin, that it is my mouth that speaks to you.",
                "You shall tell my father of all my glory in Egypt, and of all that you have seen. You shall hurry and bring my father down here.\"",
                "He fell on his brother Benjamin\u2019s neck, and wept, and Benjamin wept on his neck.",
                "He kissed all his brothers, and wept on them. After that his brothers talked with him.",
                "The report of it was heard in Pharaoh\u2019s house, saying, \"Joseph\u2019s brothers have come.\" It pleased Pharaoh well, and his servants.",
                "Pharaoh said to Joseph, \"Tell your brothers, \u2018Do this. Load your animals, and go, travel to the land of Canaan.",
                "Take your father and your households, and come to me, and I will give you the good of the land of Egypt, and you will eat the fat of the land.\u2019",
                "Now you are commanded: do this. Take wagons out of the land of Egypt for your little ones, and for your wives, and bring your father, and come.",
                "Also, don\u2019t concern yourselves about your belongings, for the good of all of the land of Egypt is yours.\"",
                "The sons of Israel did so. Joseph gave them wagons, according to the commandment of Pharaoh, and gave them provision for the way.",
                "He gave each one of them changes of clothing, but to Benjamin he gave three hundred pieces of silver and five changes of clothing.",
                "He sent the following to his father: ten donkeys loaded with the good things of Egypt, and ten female donkeys loaded with grain and bread and provision for his father by the way.",
                "So he sent his brothers away, and they departed. He said to them, \"See that you don\u2019t quarrel on the way.\"",
                "They went up out of Egypt, and came into the land of Canaan, to Jacob their father.",
                "They told him, saying, \"Joseph is still alive, and he is ruler over all the land of Egypt.\" His heart fainted, for he didn\u2019t believe them.",
                "They told him all the words of Joseph, which he had said to them. When he saw the wagons which Joseph had sent to carry him, the spirit of Jacob, their father, revived.",
                "Israel said, \"It is enough. Joseph my son is still alive. I will go and see him before I die.\""
            ],
            46: [
                "Israel traveled with all that he had, and came to Beersheba, and offered sacrifices to the God of his father, Isaac.",
                "God spoke to Israel in the visions of the night, and said, \"Jacob, Jacob!\" He said, \"Here I am.\"",
                "He said, \"I am God, the God of your father. Don\u2019t be afraid to go down into Egypt, for there I will make of you a great nation.",
                "I will go down with you into Egypt. I will also surely bring you up again. Joseph will close your eyes.\"",
                "Jacob rose up from Beersheba, and the sons of Israel carried Jacob, their father, their little ones, and their wives, in the wagons which Pharaoh had sent to carry him.",
                "They took their livestock, and their goods, which they had gotten in the land of Canaan, and came into Egypt\u2014Jacob, and all his seed with him,",
                "his sons, and his sons\u2019 sons with him, his daughters, and his sons\u2019 daughters, and he brought all his seed with him into Egypt.",
                "These are the names of the children of Israel, who came into Egypt, Jacob and his sons: Reuben, Jacob\u2019s firstborn.",
                "The sons of Reuben: Hanoch, Pallu, Hezron, and Carmi.",
                "The sons of Simeon: Jemuel, Jamin, Ohad, Jachin, Zohar, and Shaul the son of a Canaanite woman.",
                "The sons of Levi: Gershon, Kohath, and Merari.",
                "The sons of Judah: Er, Onan, Shelah, Perez, and Zerah; but Er and Onan died in the land of Canaan. The sons of Perez were Hezron and Hamul.",
                "The sons of Issachar: Tola, Puvah, Iob, and Shimron.",
                "The sons of Zebulun: Sered, Elon, and Jahleel.",
                "These are the sons of Leah, whom she bore to Jacob in Paddan Aram, with his daughter Dinah. All the souls of his sons and his daughters were thirty-three.",
                "The sons of Gad: Ziphion, Haggi, Shuni, Ezbon, Eri, Arodi, and Areli.",
                "The sons of Asher: Imnah, Ishvah, Ishvi, Beriah, and Serah their sister. The sons of Beriah: Heber and Malchiel.",
                "These are the sons of Zilpah, whom Laban gave to Leah, his daughter, and these she bore to Jacob, even sixteen souls.",
                "The sons of Rachel, Jacob\u2019s wife: Joseph and Benjamin.",
                "To Joseph in the land of Egypt were born Manasseh and Ephraim, whom Asenath, the daughter of Potiphera, priest of On, bore to him.",
                "The sons of Benjamin: Bela, Becher, Ashbel, Gera, Naaman, Ehi, Rosh, Muppim, Huppim, and Ard.",
                "These are the sons of Rachel, who were born to Jacob: all the souls were fourteen.",
                "The son of Dan: Hushim.",
                "The sons of Naphtali: Jahzeel, Guni, Jezer, and Shillem.",
                "These are the sons of Bilhah, whom Laban gave to Rachel, his daughter, and these she bore to Jacob: all the souls were seven.",
                "All the souls who came with Jacob into Egypt, who were his direct descendants, besides Jacob\u2019s sons\u2019 wives, all the souls were sixty-six.",
                "The sons of Joseph, who were born to him in Egypt, were two souls. All the souls of the house of Jacob, who came into Egypt, were seventy.",
                "He sent Judah before him to Joseph, to show the way before him to Goshen, and they came into the land of Goshen.",
                "Joseph prepared his chariot, and went up to meet Israel, his father, in Goshen. He presented himself to him, and fell on his neck, and wept on his neck a good while.",
                "Israel said to Joseph, \"Now let me die, since I have seen your face, that you are still alive.\"",
                "Joseph said to his brothers, and to his father\u2019s house, \"I will go up, and speak with Pharaoh, and will tell him, \u2018My brothers, and my father\u2019s house, who were in the land of Canaan, have come to me.",
                "These men are shepherds, for they have been keepers of livestock, and they have brought their flocks, and their herds, and all that they have.\u2019",
                "It will happen, when Pharaoh summons you, and will say, \u2018What is your occupation?\u2019",
                "that you shall say, \u2018Your servants have been keepers of livestock from our youth even until now, both we, and our fathers:\u2019 that you may dwell in the land of Goshen; for every shepherd is an abomination to the Egyptians.\""
            ],
            47: [
                "Then Joseph went in and told Pharaoh, and said, \"My father and my brothers, with their flocks, their herds, and all that they own, have come out of the land of Canaan; and behold, they are in the land of Goshen.\"",
                "From among his brothers he took five men, and presented them to Pharaoh.",
                "Pharaoh said to his brothers, \"What is your occupation?\" They said to Pharaoh, \"Your servants are shepherds, both we, and our fathers.\"",
                "They said to Pharaoh, \"We have come to live as foreigners in the land, for there is no pasture for your servants\u2019 flocks. For the famine is severe in the land of Canaan. Now therefore, please let your servants dwell in the land of Goshen.\"",
                "Pharaoh spoke to Joseph, saying, \"Your father and your brothers have come to you.",
                "The land of Egypt is before you. Make your father and your brothers dwell in the best of the land. Let them dwell in the land of Goshen. If you know any able men among them, then put them in charge of my livestock.\"",
                "Joseph brought in Jacob, his father, and set him before Pharaoh, and Jacob blessed Pharaoh.",
                "Pharaoh said to Jacob, \"How many are the days of the years of your life?\"",
                "Jacob said to Pharaoh, \"The days of the years of my pilgrimage are one hundred thirty years. Few and evil have been the days of the years of my life, and they have not attained to the days of the years of the life of my fathers in the days of their pilgrimage.\"",
                "Jacob blessed Pharaoh, and went out from the presence of Pharaoh.",
                "Joseph placed his father and his brothers, and gave them a possession in the land of Egypt, in the best of the land, in the land of Rameses, as Pharaoh had commanded.",
                "Joseph nourished his father, his brothers, and all of his father\u2019s household, with bread, according to their families.",
                "There was no bread in all the land; for the famine was very severe, so that the land of Egypt and the land of Canaan fainted by reason of the famine.",
                "Joseph gathered up all the money that was found in the land of Egypt, and in the land of Canaan, for the grain which they bought: and Joseph brought the money into Pharaoh\u2019s house.",
                "When the money was all spent in the land of Egypt, and in the land of Canaan, all the Egyptians came to Joseph, and said, \"Give us bread, for why should we die in your presence? For our money fails.\"",
                "Joseph said, \"Give me your livestock; and I will give you food for your livestock, if your money is gone.\"",
                "They brought their livestock to Joseph, and Joseph gave them bread in exchange for the horses, and for the flocks, and for the herds, and for the donkeys: and he fed them with bread in exchange for all their livestock for that year.",
                "When that year was ended, they came to him the second year, and said to him, \"We will not hide from my lord how our money is all spent, and the herds of livestock are my lord\u2019s. There is nothing left in the sight of my lord, but our bodies, and our lands.",
                "Why should we die before your eyes, both we and our land? Buy us and our land for bread, and we and our land will be servants to Pharaoh. Give us seed, that we may live, and not die, and that the land won\u2019t be desolate.\"",
                "So Joseph bought all the land of Egypt for Pharaoh, for every man of the Egyptians sold his field, because the famine was severe on them, and the land became Pharaoh\u2019s.",
                "As for the people, he moved them to the cities from one end of the border of Egypt even to the other end of it.",
                "Only he didn\u2019t buy the land of the priests, for the priests had a portion from Pharaoh, and ate their portion which Pharaoh gave them. That is why they didn\u2019t sell their land.",
                "Then Joseph said to the people, \"Behold, I have bought you and your land today for Pharaoh. Behold, here is seed for you, and you shall sow the land.",
                "It will happen at the harvests, that you shall give a fifth to Pharaoh, and four parts will be your own, for seed of the field, for your food, for them of your households, and for food for your little ones.\"",
                "They said, \"You have saved our lives! Let us find favor in the sight of my lord, and we will be Pharaoh\u2019s servants.\"",
                "Joseph made it a statute concerning the land of Egypt to this day, that Pharaoh should have the fifth. Only the land of the priests alone didn\u2019t become Pharaoh\u2019s.",
                "Israel lived in the land of Egypt, in the land of Goshen; and they got themselves possessions therein, and were fruitful, and multiplied exceedingly.",
                "Jacob lived in the land of Egypt seventeen years. So the days of Jacob, the years of his life, were one hundred forty-seven years.",
                "The time drew near that Israel must die, and he called his son Joseph, and said to him, \"If now I have found favor in your sight, please put your hand under my thigh, and deal kindly and truly with me. Please don\u2019t bury me in Egypt,",
                "but when I sleep with my fathers, you shall carry me out of Egypt, and bury me in their burying place.\" He said, \"I will do as you have said.\"",
                "He said, \"Swear to me,\" and he swore to him. Israel bowed himself on the bed\u2019s head."
            ],
            48: [
                "It happened after these things, that someone said to Joseph, \"Behold, your father is sick.\" He took with him his two sons, Manasseh and Ephraim.",
                "Someone told Jacob, and said, \"Behold, your son Joseph comes to you,\" and Israel strengthened himself, and sat on the bed.",
                "Jacob said to Joseph, \"God Almighty appeared to me at Luz in the land of Canaan, and blessed me,",
                "and said to me, \u2018Behold, I will make you fruitful, and multiply you, and I will make of you a company of peoples, and will give this land to your seed after you for an everlasting possession.\u2019",
                "Now your two sons, who were born to you in the land of Egypt before I came to you into Egypt, are mine; Ephraim and Manasseh, even as Reuben and Simeon, will be mine.",
                "Your issue, whom you become the father of after them, will be yours. They will be called after the name of their brothers in their inheritance.",
                "As for me, when I came from Paddan, Rachel died by me in the land of Canaan in the way, when there was still some distance to come to Ephrath, and I buried her there in the way to Ephrath (the same is Bethlehem).\"",
                "Israel saw Joseph\u2019s sons, and said, \"Who are these?\"",
                "Joseph said to his father, \"They are my sons, whom God has given me here.\" He said, \"Please bring them to me, and I will bless them.\"",
                "Now the eyes of Israel were dim for age, so that he couldn\u2019t see. He brought them near to him; and he kissed them, and embraced them.",
                "Israel said to Joseph, \"I didn\u2019t think I would see your face, and behold, God has let me see your seed also.\"",
                "Joseph brought them out from between his knees, and he bowed himself with his face to the earth.",
                "Joseph took them both, Ephraim in his right hand toward Israel\u2019s left hand, and Manasseh in his left hand toward Israel\u2019s right hand, and brought them near to him.",
                "Israel stretched out his right hand, and laid it on Ephraim\u2019s head, who was the younger, and his left hand on Manasseh\u2019s head, guiding his hands knowingly, for Manasseh was the firstborn.",
                "He blessed Joseph, and said, \"The God before whom my fathers Abraham and Isaac walked, the God who has fed me all my life long to this day,",
                "the angel who has redeemed me from all evil, bless the lads, and let my name be named on them, and the name of my fathers Abraham and Isaac. Let them grow into a multitude in the midst of the earth.\"",
                "When Joseph saw that his father laid his right hand on the head of Ephraim, it displeased him. He held up his father\u2019s hand, to remove it from Ephraim\u2019s head to Manasseh\u2019s head.",
                "Joseph said to his father, \"Not so, my father; for this is the firstborn; put your right hand on his head.\"",
                "His father refused, and said, \"I know, my son, I know. He also will become a people, and he also will be great. However, his younger brother will be greater than he, and his seed will become a multitude of nations.\"",
                "He blessed them that day, saying, \"In you will Israel bless, saying, \u2018God make you as Ephraim and as Manasseh\u2019\" He set Ephraim before Manasseh.",
                "Israel said to Joseph, \"Behold, I am dying, but God will be with you, and bring you again to the land of your fathers.",
                "Moreover I have given to you one portion above your brothers, which I took out of the hand of the Amorite with my sword and with my bow.\""
            ],
            49: [
                "Jacob called to his sons, and said: \"Gather yourselves together, that I may tell you that which will happen to you in the days to come.",
                "Assemble yourselves, and hear, you sons of Jacob. Listen to Israel, your father.",
                "\"Reuben, you are my firstborn, my might, and the beginning of my strength; excelling in dignity, and excelling in power.",
                "Boiling over as water, you shall not excel; because you went up to your father\u2019s bed, then defiled it. He went up to my couch.",
                "\"Simeon and Levi are brothers. Their swords are weapons of violence.",
                "My soul, don\u2019t come into their council. My glory, don\u2019t be united to their assembly; for in their anger they killed men. In their self-will they hamstrung cattle.",
                "Cursed be their anger, for it was fierce; and their wrath, for it was cruel. I will divide them in Jacob, and scatter them in Israel.",
                "\"Judah, your brothers will praise you. Your hand will be on the neck of your enemies. Your father\u2019s sons will bow down before you.",
                "Judah is a lion\u2019s cub. From the prey, my son, you have gone up. He stooped down, he crouched as a lion, as a lioness. Who will rouse him up?",
                "The scepter will not depart from Judah, nor the ruler\u2019s staff from between his feet, until he comes to whom it belongs. To him will the obedience of the peoples be.",
                "Binding his foal to the vine, his donkey\u2019s colt to the choice vine; he has washed his garments in wine, his robes in the blood of grapes.",
                "His eyes will be red with wine, his teeth white with milk.",
                "\"Zebulun will dwell at the haven of the sea. He will be for a haven of ships. His border will be on Sidon.",
                "\"Issachar is a strong donkey, lying down between the saddlebags.",
                "He saw a resting place, that it was good, the land, that it was pleasant. He bows his shoulder to the burden, and becomes a servant doing forced labor.",
                "\"Dan will judge his people, as one of the tribes of Israel.",
                "Dan will be a serpent in the way, an adder in the path, That bites the horse\u2019s heels, so that his rider falls backward.",
                "I have waited for your salvation, Yahweh.",
                "\"A troop will press on Gad, but he will press on their heel.",
                "\"Asher\u2019s food will be rich. He will yield royal dainties.",
                "\"Naphtali is a doe set free, who bears beautiful fawns.",
                "\"Joseph is a fruitful vine, a fruitful vine by a spring. His branches run over the wall.",
                "The archers have severely grieved him, shot at him, and persecute him:",
                "But his bow remained strong. The arms of his hands were made strong, by the hands of the Mighty One of Jacob, (from there is the shepherd, the stone of Israel),",
                "even by the God of your father, who will help you; by the Almighty, who will bless you, with blessings of heaven above, blessings of the deep that lies below, blessings of the breasts, and of the womb.",
                "The blessings of your father have prevailed above the blessings of your ancestors, above the boundaries of the ancient hills. They will be on the head of Joseph, on the crown of the head of him who is separated from his brothers.",
                "\"Benjamin is a ravenous wolf. In the morning he will devour the prey. At evening he will divide the spoil.\"",
                "All these are the twelve tribes of Israel, and this is what their father spoke to them and blessed them. He blessed everyone according to his blessing.",
                "He instructed them, and said to them, \"I am to be gathered to my people. Bury me with my fathers in the cave that is in the field of Ephron the Hittite,",
                "in the cave that is in the field of Machpelah, which is before Mamre, in the land of Canaan, which Abraham bought with the field from Ephron the Hittite as a burial place.",
                "There they buried Abraham and Sarah, his wife. There they buried Isaac and Rebekah, his wife, and there I buried Leah:",
                "the field and the cave that is therein, which was purchased from the children of Heth.\"",
                "When Jacob made an end of charging his sons, he gathered up his feet into the bed, and yielded up the spirit, and was gathered to his people."
            ],
            50: [
                "Joseph fell on his father\u2019s face, wept on him, and kissed him.",
                "Joseph commanded his servants, the physicians, to embalm his father; and the physicians embalmed Israel.",
                "Forty days were fulfilled for him, for that is how many the days it takes to embalm. The Egyptians wept for him for seventy days.",
                "When the days of weeping for him were past, Joseph spoke to the house of Pharaoh, saying, \"If now I have found favor in your eyes, please speak in the ears of Pharaoh, saying,",
                "\u2018My father made me swear, saying, \"Behold, I am dying. Bury me in my grave which I have dug for myself in the land of Canaan.\" Now therefore, please let me go up and bury my father, and I will come again.\u2019\"",
                "Pharaoh said, \"Go up, and bury your father, just like he made you swear.\"",
                "Joseph went up to bury his father; and with him went up all the servants of Pharaoh, the elders of his house, all the elders of the land of Egypt,",
                "all the house of Joseph, his brothers, and his father\u2019s house. Only their little ones, their flocks, and their herds, they left in the land of Goshen.",
                "There went up with him both chariots and horsemen. It was a very great company.",
                "They came to the threshing floor of Atad, which is beyond the Jordan, and there they lamented with a very great and severe lamentation. He mourned for his father seven days.",
                "When the inhabitants of the land, the Canaanites, saw the mourning in the floor of Atad, they said, \"This is a grievous mourning by the Egyptians.\" Therefore, its name was called Abel Mizraim, which is beyond the Jordan.",
                "His sons did to him just as he commanded them,",
                "for his sons carried him into the land of Canaan, and buried him in the cave of the field of Machpelah, which Abraham bought with the field, for a possession of a burial site, from Ephron the Hittite, before Mamre.",
                "Joseph returned into Egypt\u2014he, and his brothers, and all that went up with him to bury his father, after he had buried his father.",
                "When Joseph\u2019s brothers saw that their father was dead, they said, \"It may be that Joseph will hate us, and will fully pay us back for all of the evil which we did to him.\"",
                "They sent a message to Joseph, saying, \"Your father commanded before he died, saying,",
                "\u2018You shall tell Joseph, \"Now please forgive the disobedience of your brothers, and their sin, because they did evil to you.\"\u2019 Now, please forgive the disobedience of the servants of the God of your father.\" Joseph wept when they spoke to him.",
                "His brothers also went and fell down before his face; and they said, \"Behold, we are your servants.\"",
                "Joseph said to them, \"Don\u2019t be afraid, for am I in the place of God?",
                "As for you, you meant evil against me, but God meant it for good, to bring to pass, as it is this day, to save many people alive.",
                "Now therefore don\u2019t be afraid. I will nourish you and your little ones.\" He comforted them, and spoke kindly to them.",
                "Joseph lived in Egypt, he, and his father\u2019s house. Joseph lived one hundred ten years.",
                "Joseph saw Ephraim\u2019s children to the third generation. The children also of Machir, the son of Manasseh, were born on Joseph\u2019s knees.",
                "Joseph said to his brothers, \"I am dying, but God will surely visit you, and bring you up out of this land to the land which he swore to Abraham, to Isaac, and to Jacob.\"",
                "Joseph took an oath of the children of Israel, saying, \"God will surely visit you, and you shall carry up my bones from here.\"",
                "So Joseph died, being one hundred ten years old, and they embalmed him, and he was put in a coffin in Egypt."
            ]
        },
        2: {
            1: [
                "Now these are the names of the sons of Israel, who came into Egypt (every man and his household came with Jacob):",
                "Reuben, Simeon, Levi, and Judah,",
                "Issachar, Zebulun, and Benjamin,",
                "Dan and Naphtali, Gad and Asher.",
                "All the souls who came out of Jacob\u2019s body were seventy souls, and Joseph was in Egypt already.",
                "Joseph died, as did all his brothers, and all that generation.",
                "The children of Israel were fruitful, and increased abundantly, and multiplied, and grew exceedingly mighty; and the land was filled with them.",
                "Now there arose a new king over Egypt, who didn\u2019t know Joseph.",
                "He said to his people, \"Behold, the people of the children of Israel are more and mightier than we.",
                "Come, let us deal wisely with them, lest they multiply, and it happen that when any war breaks out, they also join themselves to our enemies, and fight against us, and escape out of the land.\"",
                "Therefore they set taskmasters over them to afflict them with their burdens. They built storage cities for Pharaoh: Pithom and Raamses.",
                "But the more they afflicted them, the more they multiplied and the more they spread out. They were grieved because of the children of Israel.",
                "The Egyptians ruthlessly made the children of Israel serve,",
                "and they made their lives bitter with hard service, in mortar and in brick, and in all kinds of service in the field, all their service, in which they ruthlessly made them serve.",
                "The king of Egypt spoke to the Hebrew midwives, of whom the name of the one was Shiphrah, and the name of the other Puah,",
                "and he said, \"When you perform the duty of a midwife to the Hebrew women, and see them on the birth stool; if it is a son, then you shall kill him; but if it is a daughter, then she shall live.\"",
                "But the midwives feared God, and didn\u2019t do what the king of Egypt commanded them, but saved the baby boys alive.",
                "The king of Egypt called for the midwives, and said to them, \"Why have you done this thing, and have saved the boys alive?\"",
                "The midwives said to Pharaoh, \"Because the Hebrew women aren\u2019t like the Egyptian women; for they are vigorous, and give birth before the midwife comes to them.\"",
                "God dealt well with the midwives, and the people multiplied, and grew very mighty.",
                "It happened, because the midwives feared God, that he gave them families.",
                "Pharaoh commanded all his people, saying, \"You shall cast every son who is born into the river, and every daughter you shall save alive.\""
            ],
            2: [
                "A man of the house of Levi went and took a daughter of Levi as his wife.",
                "The woman conceived, and bore a son. When she saw that he was a fine child, she hid him three months.",
                "When she could no longer hide him, she took a papyrus basket for him, and coated it with tar and with pitch. She put the child in it, and laid it in the reeds by the river\u2019s bank.",
                "His sister stood far off, to see what would be done to him.",
                "Pharaoh\u2019s daughter came down to bathe at the river. Her maidens walked along by the riverside. She saw the basket among the reeds, and sent her handmaid to get it.",
                "She opened it, and saw the child, and behold, the baby cried. She had compassion on him, and said, \"This is one of the Hebrews\u2019 children.\"",
                "Then his sister said to Pharaoh\u2019s daughter, \"Should I go and call a nurse for you from the Hebrew women, that she may nurse the child for you?\"",
                "Pharaoh\u2019s daughter said to her, \"Go.\" The maiden went and called the child\u2019s mother.",
                "Pharaoh\u2019s daughter said to her, \"Take this child away, and nurse him for me, and I will give you your wages.\" The woman took the child, and nursed it.",
                "The child grew, and she brought him to Pharaoh\u2019s daughter, and he became her son. She named him Moses, and said, \"Because I drew him out of the water.\"",
                "It happened in those days, when Moses had grown up, that he went out to his brothers, and looked at their burdens. He saw an Egyptian striking a Hebrew, one of his brothers.",
                "He looked this way and that way, and when he saw that there was no one, he killed the Egyptian, and hid him in the sand.",
                "He went out the second day, and behold, two men of the Hebrews were fighting with each other. He said to him who did the wrong, \"Why do you strike your fellow?\"",
                "He said, \"Who made you a prince and a judge over us? Do you plan to kill me, as you killed the Egyptian?\" Moses was afraid, and said, \"Surely this thing is known.\"",
                "Now when Pharaoh heard this thing, he sought to kill Moses. But Moses fled from the face of Pharaoh, and lived in the land of Midian, and he sat down by a well.",
                "Now the priest of Midian had seven daughters. They came and drew water, and filled the troughs to water their father\u2019s flock.",
                "The shepherds came and drove them away; but Moses stood up and helped them, and watered their flock.",
                "When they came to Reuel, their father, he said, \"How is it that you have returned so early today?\"",
                "They said, \"An Egyptian delivered us out of the hand of the shepherds, and moreover he drew water for us, and watered the flock.\"",
                "He said to his daughters, \"Where is he? Why is it that you have left the man? Call him, that he may eat bread.\"",
                "Moses was content to dwell with the man. He gave Moses Zipporah, his daughter.",
                "She bore a son, and he named him Gershom, for he said, \"I have lived as a foreigner in a foreign land.\"",
                "It happened in the course of those many days, that the king of Egypt died, and the children of Israel sighed because of the bondage, and they cried, and their cry came up to God because of the bondage.",
                "God heard their groaning, and God remembered his covenant with Abraham, with Isaac, and with Jacob.",
                "God saw the children of Israel, and God was concerned about them."
            ],
            3: [
                "Now Moses was keeping the flock of Jethro, his father-in-law, the priest of Midian, and he led the flock to the back of the wilderness, and came to God\u2019s mountain, to Horeb.",
                "The angel of Yahweh appeared to him in a flame of fire out of the midst of a bush. He looked, and behold, the bush burned with fire, and the bush was not consumed.",
                "Moses said, \"I will turn aside now, and see this great sight, why the bush is not burnt.\"",
                "When Yahweh saw that he turned aside to see, God called to him out of the midst of the bush, and said, \"Moses! Moses!\" He said, \"Here I am.\"",
                "He said, \"Don\u2019t come close. Take your sandals off of your feet, for the place you are standing on is holy ground.\"",
                "Moreover he said, \"I am the God of your father, the God of Abraham, the God of Isaac, and the God of Jacob.\" Moses hid his face; for he was afraid to look at God.",
                "Yahweh said, \"I have surely seen the affliction of my people who are in Egypt, and have heard their cry because of their taskmasters, for I know their sorrows.",
                "I have come down to deliver them out of the hand of the Egyptians, and to bring them up out of that land to a good and large land, to a land flowing with milk and honey; to the place of the Canaanite, the Hittite, the Amorite, the Perizzite, the Hivite, and the Jebusite.",
                "Now, behold, the cry of the children of Israel has come to me. Moreover I have seen the oppression with which the Egyptians oppress them.",
                "Come now therefore, and I will send you to Pharaoh, that you may bring my people, the children of Israel, out of Egypt.\"",
                "Moses said to God, \"Who am I, that I should go to Pharaoh, and that I should bring the children of Israel out of Egypt?\"",
                "He said, \"Certainly I will be with you. This will be the token to you, that I have sent you: when you have brought the people out of Egypt, you shall serve God on this mountain.\"",
                "Moses said to God, \"Behold, when I come to the children of Israel, and tell them, \u2018The God of your fathers has sent me to you;\u2019 and they ask me, \u2018What is his name?\u2019 What should I tell them?\"",
                "God said to Moses, \"I AM WHO I AM,\" and he said, \"You shall tell the children of Israel this: \u2018I AM has sent me to you.\u2019\"",
                "God said moreover to Moses, \"You shall tell the children of Israel this, \u2018Yahweh, the God of your fathers, the God of Abraham, the God of Isaac, and the God of Jacob, has sent me to you.\u2019 This is my name forever, and this is my memorial to all generations.",
                "Go, and gather the elders of Israel together, and tell them, \u2018Yahweh, the God of your fathers, the God of Abraham, of Isaac, and of Jacob, has appeared to me, saying, \"I have surely visited you, and seen that which is done to you in Egypt;",
                "and I have said, I will bring you up out of the affliction of Egypt to the land of the Canaanite, the Hittite, the Amorite, the Perizzite, the Hivite, and the Jebusite, to a land flowing with milk and honey.\"\u2019",
                "They will listen to your voice, and you shall come, you and the elders of Israel, to the king of Egypt, and you shall tell him, \u2018Yahweh, the God of the Hebrews, has met with us. Now please let us go three days\u2019 journey into the wilderness, that we may sacrifice to Yahweh, our God.\u2019",
                "I know that the king of Egypt won\u2019t give you permission to go, no, not by a mighty hand.",
                "I will reach out my hand and strike Egypt with all my wonders which I will do in its midst, and after that he will let you go.",
                "I will give this people favor in the sight of the Egyptians, and it will happen that when you go, you shall not go empty-handed.",
                "But every woman shall ask of her neighbor, and of her who visits her house, jewels of silver, jewels of gold, and clothing; and you shall put them on your sons, and on your daughters. You shall plunder the Egyptians.\""
            ],
            4: [
                "Moses answered, \"But, behold, they will not believe me, nor listen to my voice; for they will say, \u2018Yahweh has not appeared to you.\u2019\"",
                "Yahweh said to him, \"What is that in your hand?\" He said, \"A rod.\"",
                "He said, \"Throw it on the ground.\" He threw it on the ground, and it became a snake; and Moses ran away from it.",
                "Yahweh said to Moses, \"Stretch out your hand, and take it by the tail.\" He stretched out his hand, and took hold of it, and it became a rod in his hand.",
                "\"That they may believe that Yahweh, the God of their fathers, the God of Abraham, the God of Isaac, and the God of Jacob, has appeared to you.\"",
                "Yahweh said furthermore to him, \"Now put your hand inside your cloak.\" He put his hand inside his cloak, and when he took it out, behold, his hand was leprous, as white as snow.",
                "He said, \"Put your hand inside your cloak again.\" He put his hand inside his cloak again, and when he took it out of his cloak, behold, it had turned again as his other flesh.",
                "\"It will happen, if they will neither believe you nor listen to the voice of the first sign, that they will believe the voice of the latter sign.",
                "It will happen, if they will not believe even these two signs, neither listen to your voice, that you shall take of the water of the river, and pour it on the dry land. The water which you take out of the river will become blood on the dry land.\"",
                "Moses said to Yahweh, \"O Lord, I am not eloquent, neither before now, nor since you have spoken to your servant; for I am slow of speech, and of a slow tongue.\"",
                "Yahweh said to him, \"Who made man\u2019s mouth? Or who makes one mute, or deaf, or seeing, or blind? Isn\u2019t it I, Yahweh?",
                "Now therefore go, and I will be with your mouth, and teach you what you shall speak.\"",
                "He said, \"Oh, Lord, please send someone else.\"",
                "The anger of Yahweh was kindled against Moses, and he said, \"What about Aaron, your brother, the Levite? I know that he can speak well. Also, behold, he comes out to meet you. When he sees you, he will be glad in his heart.",
                "You shall speak to him, and put the words in his mouth. I will be with your mouth, and with his mouth, and will teach you what you shall do.",
                "He will be your spokesman to the people; and it will happen, that he will be to you a mouth, and you will be to him as God.",
                "You shall take this rod in your hand, with which you shall do the signs.\"",
                "Moses went and returned to Jethro his father-in-law, and said to him, \"Please let me go and return to my brothers who are in Egypt, and see whether they are still alive.\" Jethro said to Moses, \"Go in peace.\"",
                "Yahweh said to Moses in Midian, \"Go, return into Egypt; for all the men who sought your life are dead.\"",
                "Moses took his wife and his sons, and set them on a donkey, and he returned to the land of Egypt. Moses took God\u2019s rod in his hand.",
                "Yahweh said to Moses, \"When you go back into Egypt, see that you do before Pharaoh all the wonders which I have put in your hand, but I will harden his heart and he will not let the people go.",
                "You shall tell Pharaoh, \u2018Thus says Yahweh, Israel is my son, my firstborn,",
                "and I have said to you, \"Let my son go, that he may serve me;\" and you have refused to let him go. Behold, I will kill your son, your firstborn.\u2019\"",
                "It happened on the way at a lodging place, that Yahweh met Moses and wanted to kill him.",
                "Then Zipporah took a flint, and cut off the foreskin of her son, and cast it at his feet; and she said, \"Surely you are a bridegroom of blood to me.\"",
                "So he let him alone. Then she said, \"You are a bridegroom of blood,\" because of the circumcision.",
                "Yahweh said to Aaron, \"Go into the wilderness to meet Moses.\" He went, and met him on God\u2019s mountain, and kissed him.",
                "Moses told Aaron all the words of Yahweh with which he had sent him, and all the signs with which he had instructed him.",
                "Moses and Aaron went and gathered together all the elders of the children of Israel.",
                "Aaron spoke all the words which Yahweh had spoken to Moses, and did the signs in the sight of the people.",
                "The people believed, and when they heard that Yahweh had visited the children of Israel, and that he had seen their affliction, then they bowed their heads and worshiped."
            ],
            5: [
                "Afterward Moses and Aaron came, and said to Pharaoh, \"This is what Yahweh, the God of Israel, says, \u2018Let my people go, that they may hold a feast to me in the wilderness.\u2019\"",
                "Pharaoh said, \"Who is Yahweh, that I should listen to his voice to let Israel go? I don\u2019t know Yahweh, and moreover I will not let Israel go.\"",
                "They said, \"The God of the Hebrews has met with us. Please let us go three days\u2019 journey into the wilderness, and sacrifice to Yahweh, our God, lest he fall on us with pestilence, or with the sword.\"",
                "The king of Egypt said to them, \"Why do you, Moses and Aaron, take the people from their work? Get back to your burdens!\"",
                "Pharaoh said, \"Behold, the people of the land are now many, and you make them rest from their burdens.\"",
                "The same day Pharaoh commanded the taskmasters of the people, and their officers, saying,",
                "\"You shall no longer give the people straw to make brick, as before. Let them go and gather straw for themselves.",
                "The number of the bricks, which they made before, you require from them. You shall not diminish anything of it, for they are idle; therefore they cry, saying, \u2018Let us go and sacrifice to our God.\u2019",
                "Let heavier work be laid on the men, that they may labor therein; and don\u2019t let them pay any attention to lying words.\"",
                "The taskmasters of the people went out, and their officers, and they spoke to the people, saying, \"This is what Pharaoh says: \u2018I will not give you straw.",
                "Go yourselves, get straw where you can find it, for nothing of your work shall be diminished.\u2019\"",
                "So the people were scattered abroad throughout all the land of Egypt to gather stubble for straw.",
                "The taskmasters were urgent saying, \"Fulfill your work quota daily, as when there was straw!\"",
                "The officers of the children of Israel, whom Pharaoh\u2019s taskmasters had set over them, were beaten, and demanded, \"Why haven\u2019t you fulfilled your quota both yesterday and today, in making brick as before?\"",
                "Then the officers of the children of Israel came and cried to Pharaoh, saying, \"Why do you deal this way with your servants?",
                "No straw is given to your servants, and they tell us, \u2018Make brick!\u2019 and behold, your servants are beaten; but the fault is in your own people.\"",
                "But he said, \"You are idle! You are idle! Therefore you say, \u2018Let us go and sacrifice to Yahweh.\u2019",
                "Go therefore now, and work, for no straw shall be given to you, yet you shall deliver the same number of bricks!\"",
                "The officers of the children of Israel saw that they were in trouble, when it was said, \"You shall not diminish anything from your daily quota of bricks!\"",
                "They met Moses and Aaron, who stood in the way, as they came out from Pharaoh:",
                "and they said to them, \"May Yahweh look at you, and judge, because you have made us a stench to be abhorred in the eyes of Pharaoh, and in the eyes of his servants, to put a sword in their hand to kill us.\"",
                "Moses returned to Yahweh, and said, \"Lord, why have you brought trouble on this people? Why is it that you have sent me?",
                "For since I came to Pharaoh to speak in your name, he has brought trouble on this people; neither have you delivered your people at all.\""
            ],
            6: [
                "Yahweh said to Moses, \"Now you shall see what I will do to Pharaoh, for by a strong hand he shall let them go, and by a strong hand he shall drive them out of his land.\"",
                "God spoke to Moses, and said to him, \"I am Yahweh;",
                "and I appeared to Abraham, to Isaac, and to Jacob, as God Almighty; but by my name Yahweh I was not known to them.",
                "I have also established my covenant with them, to give them the land of Canaan, the land of their travels, in which they lived as aliens.",
                "Moreover I have heard the groaning of the children of Israel, whom the Egyptians keep in bondage, and I have remembered my covenant.",
                "Therefore tell the children of Israel, \u2018I am Yahweh, and I will bring you out from under the burdens of the Egyptians, and I will rid you out of their bondage, and I will redeem you with an outstretched arm, and with great judgments:",
                "and I will take you to me for a people, and I will be to you a God; and you shall know that I am Yahweh your God, who brings you out from under the burdens of the Egyptians.",
                "I will bring you into the land which I swore to give to Abraham, to Isaac, and to Jacob; and I will give it to you for a heritage: I am Yahweh.\u2019\"",
                "Moses spoke so to the children of Israel, but they didn\u2019t listen to Moses for anguish of spirit, and for cruel bondage.",
                "Yahweh spoke to Moses, saying,",
                "\"Go in, speak to Pharaoh king of Egypt, that he let the children of Israel go out of his land.\"",
                "Moses spoke before Yahweh, saying, \"Behold, the children of Israel haven\u2019t listened to me. How then shall Pharaoh listen to me, who am of uncircumcised lips?\"",
                "Yahweh spoke to Moses and to Aaron, and gave them a command to the children of Israel, and to Pharaoh king of Egypt, to bring the children of Israel out of the land of Egypt.",
                "These are the heads of their fathers\u2019 houses. The sons of Reuben the firstborn of Israel: Hanoch, and Pallu, Hezron, and Carmi; these are the families of Reuben.",
                "The sons of Simeon: Jemuel, and Jamin, and Ohad, and Jachin, and Zohar, and Shaul the son of a Canaanite woman; these are the families of Simeon.",
                "These are the names of the sons of Levi according to their generations: Gershon, and Kohath, and Merari; and the years of the life of Levi were one hundred thirty-seven years.",
                "The sons of Gershon: Libni and Shimei, according to their families.",
                "The sons of Kohath: Amram, and Izhar, and Hebron, and Uzziel; and the years of the life of Kohath were one hundred thirty-three years.",
                "The sons of Merari: Mahli and Mushi. These are the families of the Levites according to their generations.",
                "Amram took Jochebed his father\u2019s sister to himself as wife; and she bore him Aaron and Moses: and the years of the life of Amram were a hundred and thirty-seven years.",
                "The sons of Izhar: Korah, and Nepheg, and Zichri.",
                "The sons of Uzziel: Mishael, and Elzaphan, and Sithri.",
                "Aaron took Elisheba, the daughter of Amminadab, the sister of Nahshon, as his wife; and she bore him Nadab and Abihu, Eleazar and Ithamar.",
                "The sons of Korah: Assir, and Elkanah, and Abiasaph; these are the families of the Korahites.",
                "Eleazar Aaron\u2019s son took one of the daughters of Putiel as his wife; and she bore him Phinehas. These are the heads of the fathers\u2019 houses of the Levites according to their families.",
                "These are that Aaron and Moses, to whom Yahweh said, \"Bring out the children of Israel from the land of Egypt according to their armies.\"",
                "These are those who spoke to Pharaoh king of Egypt, to bring out the children of Israel from Egypt. These are that Moses and Aaron.",
                "It happened on the day when Yahweh spoke to Moses in the land of Egypt,",
                "that Yahweh spoke to Moses, saying, \"I am Yahweh. Speak to Pharaoh king of Egypt all that I speak to you.\"",
                "Moses said before Yahweh, \"Behold, I am of uncircumcised lips, and how shall Pharaoh listen to me?\""
            ],
            7: [
                "Yahweh said to Moses, \"Behold, I have made you as God to Pharaoh; and Aaron your brother shall be your prophet.",
                "You shall speak all that I command you; and Aaron your brother shall speak to Pharaoh, that he let the children of Israel go out of his land.",
                "I will harden Pharaoh\u2019s heart, and multiply my signs and my wonders in the land of Egypt.",
                "But Pharaoh will not listen to you, and I will lay my hand on Egypt, and bring out my armies, my people the children of Israel, out of the land of Egypt by great judgments.",
                "The Egyptians shall know that I am Yahweh, when I stretch out my hand on Egypt, and bring out the children of Israel from among them.\"",
                "Moses and Aaron did so. As Yahweh commanded them, so they did.",
                "Moses was eighty years old, and Aaron eighty-three years old, when they spoke to Pharaoh.",
                "Yahweh spoke to Moses and to Aaron, saying,",
                "\"When Pharaoh speaks to you, saying, \u2018Perform a miracle!\u2019 then you shall tell Aaron, \u2018Take your rod, and cast it down before Pharaoh, that it become a serpent.\u2019\"",
                "Moses and Aaron went in to Pharaoh, and they did so, as Yahweh had commanded: and Aaron cast down his rod before Pharaoh and before his servants, and it became a serpent.",
                "Then Pharaoh also called for the wise men and the sorcerers. They also, the magicians of Egypt, did the same thing with their enchantments.",
                "For they each cast down their rods, and they became serpents: but Aaron\u2019s rod swallowed up their rods.",
                "Pharaoh\u2019s heart was hardened, and he didn\u2019t listen to them; as Yahweh had spoken.",
                "Yahweh said to Moses, \"Pharaoh\u2019s heart is stubborn. He refuses to let the people go.",
                "Go to Pharaoh in the morning. Behold, he goes out to the water; and you shall stand by the river\u2019s bank to meet him; and the rod which was turned to a serpent you shall take in your hand.",
                "You shall tell him, \u2018Yahweh, the God of the Hebrews, has sent me to you, saying, \"Let my people go, that they may serve me in the wilderness:\" and behold, until now you haven\u2019t listened.",
                "Thus says Yahweh, \"In this you shall know that I am Yahweh. Behold, I will strike with the rod that is in my hand on the waters which are in the river, and they shall be turned to blood.",
                "The fish that are in the river shall die, and the river shall become foul; and the Egyptians shall loathe to drink water from the river.\"\u2019\"",
                "Yahweh said to Moses, \"Tell Aaron, \u2018Take your rod, and stretch out your hand over the waters of Egypt, over their rivers, over their streams, and over their pools, and over all their ponds of water, that they may become blood; and there shall be blood throughout all the land of Egypt, both in vessels of wood and in vessels of stone.\u2019\"",
                "Moses and Aaron did so, as Yahweh commanded; and he lifted up the rod, and struck the waters that were in the river, in the sight of Pharaoh, and in the sight of his servants; and all the waters that were in the river were turned to blood.",
                "The fish that were in the river died; and the river became foul, and the Egyptians couldn\u2019t drink water from the river; and the blood was throughout all the land of Egypt.",
                "The magicians of Egypt did the same thing with their enchantments; and Pharaoh\u2019s heart was hardened, and he didn\u2019t listen to them; as Yahweh had spoken.",
                "Pharaoh turned and went into his house, and he didn\u2019t even take this to heart.",
                "All the Egyptians dug around the river for water to drink; for they couldn\u2019t drink of the water of the river.",
                "Seven days were fulfilled, after Yahweh had struck the river."
            ],
            8: [
                "Yahweh spoke to Moses, Go in to Pharaoh, and tell him, \"This is what Yahweh says, \u2018Let my people go, that they may serve me.",
                "If you refuse to let them go, behold, I will plague all your borders with frogs:",
                "and the river shall swarm with frogs, which shall go up and come into your house, and into your bedroom, and on your bed, and into the house of your servants, and on your people, and into your ovens, and into your kneading troughs:",
                "and the frogs shall come up both on you, and on your people, and on all your servants.\u2019\"",
                "Yahweh said to Moses, \"Tell Aaron, \u2018Stretch out your hand with your rod over the rivers, over the streams, and over the pools, and cause frogs to come up on the land of Egypt.\u2019\"",
                "Aaron stretched out his hand over the waters of Egypt; and the frogs came up, and covered the land of Egypt.",
                "The magicians did the same thing with their enchantments, and brought up frogs on the land of Egypt.",
                "Then Pharaoh called for Moses and Aaron, and said, \"Entreat Yahweh, that he take away the frogs from me, and from my people; and I will let the people go, that they may sacrifice to Yahweh.\"",
                "Moses said to Pharaoh, \"I give you the honor of setting the time that I should pray for you, and for your servants, and for your people, that the frogs be destroyed from you and your houses, and remain in the river only.\"",
                "He said, \"Tomorrow.\" He said, \"Be it according to your word, that you may know that there is none like Yahweh our God.",
                "The frogs shall depart from you, and from your houses, and from your servants, and from your people. They shall remain in the river only.\"",
                "Moses and Aaron went out from Pharaoh, and Moses cried to Yahweh concerning the frogs which he had brought on Pharaoh.",
                "Yahweh did according to the word of Moses, and the frogs died out of the houses, out of the courts, and out of the fields.",
                "They gathered them together in heaps, and the land stank.",
                "But when Pharaoh saw that there was a respite, he hardened his heart, and didn\u2019t listen to them, as Yahweh had spoken.",
                "Yahweh said to Moses, \"Tell Aaron, \u2018Stretch out your rod, and strike the dust of the earth, that it may become lice throughout all the land of Egypt.\u2019\"",
                "They did so; and Aaron stretched out his hand with his rod, and struck the dust of the earth, and there were lice on man, and on animal; all the dust of the earth became lice throughout all the land of Egypt.",
                "The magicians tried with their enchantments to produce lice, but they couldn\u2019t. There were lice on man, and on animal.",
                "Then the magicians said to Pharaoh, \"This is the finger of God:\" and Pharaoh\u2019s heart was hardened, and he didn\u2019t listen to them; as Yahweh had spoken.",
                "Yahweh said to Moses, \"Rise up early in the morning, and stand before Pharaoh; behold, he comes out to the water; and tell him, \u2018This is what Yahweh says, \"Let my people go, that they may serve me.",
                "Else, if you will not let my people go, behold, I will send swarms of flies on you, and on your servants, and on your people, and into your houses: and the houses of the Egyptians shall be full of swarms of flies, and also the ground whereon they are.",
                "I will set apart in that day the land of Goshen, in which my people dwell, that no swarms of flies shall be there; to the end you may know that I am Yahweh in the midst of the earth.",
                "I will put a division between my people and your people: by tomorrow shall this sign be.\"\u2019\"",
                "Yahweh did so; and there came grievous swarms of flies into the house of Pharaoh, and into his servants\u2019 houses: and in all the land of Egypt the land was corrupted by reason of the swarms of flies.",
                "Pharaoh called for Moses and for Aaron, and said, \"Go, sacrifice to your God in the land!\"",
                "Moses said, \"It isn\u2019t appropriate to do so; for we shall sacrifice the abomination of the Egyptians to Yahweh our God. Behold, shall we sacrifice the abomination of the Egyptians before their eyes, and won\u2019t they stone us?",
                "We will go three days\u2019 journey into the wilderness, and sacrifice to Yahweh our God, as he shall command us.\"",
                "Pharaoh said, \"I will let you go, that you may sacrifice to Yahweh your God in the wilderness, only you shall not go very far away. Pray for me.\"",
                "Moses said, \"Behold, I go out from you, and I will pray to Yahweh that the swarms of flies may depart from Pharaoh, from his servants, and from his people, tomorrow; only don\u2019t let Pharaoh deal deceitfully any more in not letting the people go to sacrifice to Yahweh.\"",
                "Moses went out from Pharaoh, and prayed to Yahweh.",
                "Yahweh did according to the word of Moses, and he removed the swarms of flies from Pharaoh, from his servants, and from his people. There remained not one.",
                "Pharaoh hardened his heart this time also, and he didn\u2019t let the people go."
            ],
            9: [
                "Then Yahweh said to Moses, \"Go in to Pharaoh, and tell him, \u2018This is what Yahweh, the God of the Hebrews, says: \"Let my people go, that they may serve me.",
                "For if you refuse to let them go, and hold them still,",
                "behold, the hand of Yahweh is on your livestock which are in the field, on the horses, on the donkeys, on the camels, on the herds, and on the flocks with a very grievous pestilence.",
                "Yahweh will make a distinction between the livestock of Israel and the livestock of Egypt; and nothing shall die of all that belongs to the children of Israel.\"\u2019\"",
                "Yahweh appointed a set time, saying, \"Tomorrow Yahweh shall do this thing in the land.\"",
                "Yahweh did that thing on the next day; and all the livestock of Egypt died, but of the livestock of the children of Israel, not one died.",
                "Pharaoh sent, and, behold, there was not so much as one of the livestock of the Israelites dead. But the heart of Pharaoh was stubborn, and he didn\u2019t let the people go.",
                "Yahweh said to Moses and to Aaron, \"Take to you handfuls of ashes of the furnace, and let Moses sprinkle it toward the sky in the sight of Pharaoh.",
                "It shall become small dust over all the land of Egypt, and shall be a boil breaking out with boils on man and on animal, throughout all the land of Egypt.\"",
                "They took ashes of the furnace, and stood before Pharaoh; and Moses sprinkled it up toward the sky; and it became a boil breaking out with boils on man and on animal.",
                "The magicians couldn\u2019t stand before Moses because of the boils; for the boils were on the magicians, and on all the Egyptians.",
                "Yahweh hardened the heart of Pharaoh, and he didn\u2019t listen to them, as Yahweh had spoken to Moses.",
                "Yahweh said to Moses, \"Rise up early in the morning, and stand before Pharaoh, and tell him, \u2018This is what Yahweh, the God of the Hebrews, says: \"Let my people go, that they may serve me.",
                "For this time I will send all my plagues against your heart, against your officials, and against your people; that you may know that there is none like me in all the earth.",
                "For now I would have stretched out my hand, and struck you and your people with pestilence, and you would have been cut off from the earth;",
                "but indeed for this cause I have made you stand: to show you my power, and that my name may be declared throughout all the earth;",
                "as you still exalt yourself against my people, that you won\u2019t let them go.",
                "Behold, tomorrow about this time I will cause it to rain a very grievous hail, such as has not been in Egypt since the day it was founded even until now.",
                "Now therefore command that all of your livestock and all that you have in the field be brought into shelter. Every man and animal that is found in the field, and isn\u2019t brought home, the hail shall come down on them, and they shall die.\"\u2019\"",
                "Those who feared the word of Yahweh among the servants of Pharaoh made their servants and their livestock flee into the houses.",
                "Whoever didn\u2019t respect the word of Yahweh left his servants and his livestock in the field.",
                "Yahweh said to Moses, \"Stretch out your hand toward the sky, that there may be hail in all the land of Egypt, on man, and on animal, and on every herb of the field, throughout the land of Egypt.\"",
                "Moses stretched out his rod toward the heavens, and Yahweh sent thunder, hail, and lightning flashed down to the earth. Yahweh rained hail on the land of Egypt.",
                "So there was very severe hail, and lightning mixed with the hail, such as had not been in all the land of Egypt since it became a nation.",
                "The hail struck throughout all the land of Egypt all that was in the field, both man and animal; and the hail struck every herb of the field, and broke every tree of the field.",
                "Only in the land of Goshen, where the children of Israel were, there was no hail.",
                "Pharaoh sent, and called for Moses and Aaron, and said to them, \"I have sinned this time. Yahweh is righteous, and I and my people are wicked.",
                "Pray to Yahweh; for there has been enough of mighty thunderings and hail. I will let you go, and you shall stay no longer.\"",
                "Moses said to him, \"As soon as I have gone out of the city, I will spread abroad my hands to Yahweh. The thunders shall cease, neither shall there be any more hail; that you may know that the earth is Yahweh\u2019s.",
                "But as for you and your servants, I know that you don\u2019t yet fear Yahweh God.\"",
                "The flax and the barley were struck, for the barley was in the ear, and the flax was in bloom.",
                "But the wheat and the spelt were not struck, for they had not grown up.",
                "Moses went out of the city from Pharaoh, and spread abroad his hands to Yahweh; and the thunders and hail ceased, and the rain was not poured on the earth.",
                "When Pharaoh saw that the rain and the hail and the thunders were ceased, he sinned yet more, and hardened his heart, he and his servants.",
                "The heart of Pharaoh was hardened, and he didn\u2019t let the children of Israel go, just as Yahweh had spoken through Moses."
            ],
            10: [
                "Yahweh said to Moses, \"Go in to Pharaoh, for I have hardened his heart, and the heart of his servants, that I may show these my signs in their midst,",
                "and that you may tell in the hearing of your son, and of your son\u2019s son, what things I have done to Egypt, and my signs which I have done among them; that you may know that I am Yahweh.\"",
                "Moses and Aaron went in to Pharaoh, and said to him, \"This is what Yahweh, the God of the Hebrews, says: \u2018How long will you refuse to humble yourself before me? Let my people go, that they may serve me.",
                "Or else, if you refuse to let my people go, behold, tomorrow I will bring locusts into your country,",
                "and they shall cover the surface of the earth, so that one won\u2019t be able to see the earth. They shall eat the residue of that which has escaped, which remains to you from the hail, and shall eat every tree which grows for you out of the field.",
                "Your houses shall be filled, and the houses of all your servants, and the houses of all the Egyptians; as neither your fathers nor your fathers\u2019 fathers have seen, since the day that they were on the earth to this day.\u2019\" He turned, and went out from Pharaoh.",
                "Pharaoh\u2019s servants said to him, \"How long will this man be a snare to us? Let the men go, that they may serve Yahweh, their God. Don\u2019t you yet know that Egypt is destroyed?\"",
                "Moses and Aaron were brought again to Pharaoh, and he said to them, \"Go, serve Yahweh your God; but who are those who will go?\"",
                "Moses said, \"We will go with our young and with our old; with our sons and with our daughters, with our flocks and with our herds will we go; for we must hold a feast to Yahweh.\"",
                "He said to them, \"Yahweh be with you if I will let you go with your little ones! See, evil is clearly before your faces.",
                "Not so! Go now you who are men, and serve Yahweh; for that is what you desire!\" They were driven out from Pharaoh\u2019s presence.",
                "Yahweh said to Moses, \"Stretch out your hand over the land of Egypt for the locusts, that they may come up on the land of Egypt, and eat every herb of the land, even all that the hail has left.\"",
                "Moses stretched out his rod over the land of Egypt, and Yahweh brought an east wind on the land all that day, and all the night; and when it was morning, the east wind brought the locusts.",
                "The locusts went up over all the land of Egypt, and rested in all the borders of Egypt. They were very grievous. Before them there were no such locusts as they, neither after them shall be such.",
                "For they covered the surface of the whole earth, so that the land was darkened, and they ate every herb of the land, and all the fruit of the trees which the hail had left. There remained nothing green, either tree or herb of the field, through all the land of Egypt.",
                "Then Pharaoh called for Moses and Aaron in haste, and he said, \"I have sinned against Yahweh your God, and against you.",
                "Now therefore please forgive my sin again, and pray to Yahweh your God, that he may also take away from me this death.\"",
                "He went out from Pharaoh, and prayed to Yahweh.",
                "Yahweh turned an exceeding strong west wind, which took up the locusts, and drove them into the Red Sea. There remained not one locust in all the borders of Egypt.",
                "But Yahweh hardened Pharaoh\u2019s heart, and he didn\u2019t let the children of Israel go.",
                "Yahweh said to Moses, \"Stretch out your hand toward the sky, that there may be darkness over the land of Egypt, even darkness which may be felt.\"",
                "Moses stretched out his hand toward the sky, and there was a thick darkness in all the land of Egypt three days.",
                "They didn\u2019t see one another, neither did anyone rise from his place for three days; but all the children of Israel had light in their dwellings.",
                "Pharaoh called to Moses, and said, \"Go, serve Yahweh. Only let your flocks and your herds stay behind. Let your little ones also go with you.\"",
                "Moses said, \"You must also give into our hand sacrifices and burnt offerings, that we may sacrifice to Yahweh our God.",
                "Our livestock also shall go with us. Not a hoof shall be left behind, for of it we must take to serve Yahweh our God; and we don\u2019t know with what we must serve Yahweh, until we come there.\"",
                "But Yahweh hardened Pharaoh\u2019s heart, and he wouldn\u2019t let them go.",
                "Pharaoh said to him, \"Get away from me! Be careful to see my face no more; for in the day you see my face you shall die!\"",
                "Moses said, \"You have spoken well. I will see your face again no more.\""
            ],
            11: [
                "Yahweh said to Moses, \"Yet one plague more will I bring on Pharaoh, and on Egypt; afterwards he will let you go. When he lets you go, he will surely thrust you out altogether.",
                "Speak now in the ears of the people, and let them ask every man of his neighbor, and every woman of her neighbor, jewels of silver, and jewels of gold.\"",
                "Yahweh gave the people favor in the sight of the Egyptians. Moreover the man Moses was very great in the land of Egypt, in the sight of Pharaoh\u2019s servants, and in the sight of the people.",
                "Moses said, \"This is what Yahweh says: \u2018About midnight I will go out into the midst of Egypt,",
                "and all the firstborn in the land of Egypt shall die, from the firstborn of Pharaoh who sits on his throne, even to the firstborn of the female servant who is behind the mill; and all the firstborn of livestock.",
                "There shall be a great cry throughout all the land of Egypt, such as there has not been, nor shall be any more.",
                "But against any of the children of Israel a dog won\u2019t even bark or move its tongue, against man or animal; that you may know that Yahweh makes a distinction between the Egyptians and Israel.",
                "All these your servants shall come down to me, and bow down themselves to me, saying, \"Get out, with all the people who follow you;\" and after that I will go out.\u2019\" He went out from Pharaoh in hot anger.",
                "Yahweh said to Moses, \"Pharaoh won\u2019t listen to you, that my wonders may be multiplied in the land of Egypt.\"",
                "Moses and Aaron did all these wonders before Pharaoh, and Yahweh hardened Pharaoh\u2019s heart, and he didn\u2019t let the children of Israel go out of his land."
            ],
            12: [
                "Yahweh spoke to Moses and Aaron in the land of Egypt, saying,",
                "\"This month shall be to you the beginning of months. It shall be the first month of the year to you.",
                "Speak to all the congregation of Israel, saying, \u2018On the tenth day of this month, they shall take to them every man a lamb, according to their fathers\u2019 houses, a lamb for a household;",
                "and if the household is too little for a lamb, then he and his neighbor next to his house shall take one according to the number of the souls; according to what everyone can eat you shall make your count for the lamb.",
                "Your lamb shall be without blemish, a male a year old. You shall take it from the sheep, or from the goats:",
                "and you shall keep it until the fourteenth day of the same month; and the whole assembly of the congregation of Israel shall kill it at evening.",
                "They shall take some of the blood, and put it on the two doorposts and on the lintel, on the houses in which they shall eat it.",
                "They shall eat the flesh in that night, roasted with fire, and unleavened bread. They shall eat it with bitter herbs.",
                "Don\u2019t eat it raw, nor boiled at all with water, but roasted with fire; with its head, its legs and its inner parts.",
                "You shall let nothing of it remain until the morning; but that which remains of it until the morning you shall burn with fire.",
                "This is how you shall eat it: with your belt on your waist, your shoes on your feet, and your staff in your hand; and you shall eat it in haste: it is Yahweh\u2019s Passover.",
                "For I will go through the land of Egypt in that night, and will strike all the firstborn in the land of Egypt, both man and animal. Against all the gods of Egypt I will execute judgments: I am Yahweh.",
                "The blood shall be to you for a token on the houses where you are: and when I see the blood, I will pass over you, and there shall no plague be on you to destroy you, when I strike the land of Egypt.",
                "This day shall be to you for a memorial, and you shall keep it a feast to Yahweh: throughout your generations you shall keep it a feast by an ordinance forever.",
                "\"\u2018Seven days you shall eat unleavened bread; even the first day you shall put away yeast out of your houses, for whoever eats leavened bread from the first day until the seventh day, that soul shall be cut off from Israel.",
                "In the first day there shall be to you a holy convocation, and in the seventh day a holy convocation; no kind of work shall be done in them, except that which every man must eat, that only may be done by you.",
                "You shall observe the feast of unleavened bread; for in this same day have I brought your armies out of the land of Egypt: therefore you shall observe this day throughout your generations by an ordinance forever.",
                "In the first month, on the fourteenth day of the month at evening, you shall eat unleavened bread, until the twenty first day of the month at evening.",
                "There shall be no yeast found in your houses for seven days, for whoever eats that which is leavened, that soul shall be cut off from the congregation of Israel, whether he be a foreigner, or one who is born in the land.",
                "You shall eat nothing leavened. In all your habitations you shall eat unleavened bread.\u2019\"",
                "Then Moses called for all the elders of Israel, and said to them, \"Draw out, and take lambs according to your families, and kill the Passover.",
                "You shall take a bunch of hyssop, and dip it in the blood that is in the basin, and strike the lintel and the two doorposts with the blood that is in the basin; and none of you shall go out of the door of his house until the morning.",
                "For Yahweh will pass through to strike the Egyptians; and when he sees the blood on the lintel, and on the two doorposts, Yahweh will pass over the door, and will not allow the destroyer to come in to your houses to strike you.",
                "You shall observe this thing for an ordinance to you and to your sons forever.",
                "It shall happen when you have come to the land which Yahweh will give you, according as he has promised, that you shall keep this service.",
                "It will happen, when your children ask you, \u2018What do you mean by this service?\u2019",
                "that you shall say, \u2018It is the sacrifice of Yahweh\u2019s Passover, who passed over the houses of the children of Israel in Egypt, when he struck the Egyptians, and spared our houses.\u2019\" The people bowed their heads and worshiped.",
                "The children of Israel went and did so; as Yahweh had commanded Moses and Aaron, so they did.",
                "It happened at midnight, that Yahweh struck all the firstborn in the land of Egypt, from the firstborn of Pharaoh who sat on his throne to the firstborn of the captive who was in the dungeon; and all the firstborn of livestock.",
                "Pharaoh rose up in the night, he, and all his servants, and all the Egyptians; and there was a great cry in Egypt, for there was not a house where there was not one dead.",
                "He called for Moses and Aaron by night, and said, \"Rise up, get out from among my people, both you and the children of Israel; and go, serve Yahweh, as you have said!",
                "Take both your flocks and your herds, as you have said, and be gone; and bless me also!\"",
                "The Egyptians were urgent with the people, to send them out of the land in haste, for they said, \"We are all dead men.\"",
                "The people took their dough before it was leavened, their kneading troughs being bound up in their clothes on their shoulders.",
                "The children of Israel did according to the word of Moses; and they asked of the Egyptians jewels of silver, and jewels of gold, and clothing.",
                "Yahweh gave the people favor in the sight of the Egyptians, so that they let them have what they asked. They despoiled the Egyptians.",
                "The children of Israel traveled from Rameses to Succoth, about six hundred thousand on foot who were men, besides children.",
                "A mixed multitude went up also with them, with flocks, herds, and even very much livestock.",
                "They baked unleavened cakes of the dough which they brought out of Egypt; for it wasn\u2019t leavened, because they were thrust out of Egypt, and couldn\u2019t wait, neither had they prepared for themselves any food.",
                "Now the time that the children of Israel lived in Egypt was four hundred thirty years.",
                "It happened at the end of four hundred thirty years, even the same day it happened, that all the armies of Yahweh went out from the land of Egypt.",
                "It is a night to be much observed to Yahweh for bringing them out from the land of Egypt. This is that night of Yahweh, to be much observed of all the children of Israel throughout their generations.",
                "Yahweh said to Moses and Aaron, \"This is the ordinance of the Passover. No foreigner shall eat of it,",
                "but every man\u2019s servant who is bought for money, when you have circumcised him, then shall he eat of it.",
                "A foreigner and a hired servant shall not eat of it.",
                "In one house shall it be eaten; you shall not carry out anything of the flesh abroad out of the house; neither shall you break a bone of it.",
                "All the congregation of Israel shall keep it.",
                "When a stranger shall live as a foreigner with you, and will keep the Passover to Yahweh, let all his males be circumcised, and then let him come near and keep it; and he shall be as one who is born in the land: but no uncircumcised person shall eat of it.",
                "One law shall be to him who is born at home, and to the stranger who lives as a foreigner among you.\"",
                "All the children of Israel did so. As Yahweh commanded Moses and Aaron, so they did.",
                "It happened the same day, that Yahweh brought the children of Israel out of the land of Egypt by their armies."
            ],
            13: [
                "Yahweh spoke to Moses, saying,",
                "\"Sanctify to me all of the firstborn, whatever opens the womb among the children of Israel, both of man and of animal. It is mine.\"",
                "Moses said to the people, \"Remember this day, in which you came out from Egypt, out of the house of bondage; for by strength of hand Yahweh brought you out from this place. No leavened bread shall be eaten.",
                "This day you go out in the month Abib.",
                "It shall be, when Yahweh shall bring you into the land of the Canaanite, and the Hittite, and the Amorite, and the Hivite, and the Jebusite, which he swore to your fathers to give you, a land flowing with milk and honey, that you shall keep this service in this month.",
                "Seven days you shall eat unleavened bread, and in the seventh day shall be a feast to Yahweh.",
                "Unleavened bread shall be eaten throughout the seven days; and no leavened bread shall be seen with you, neither shall yeast be seen with you, in all your borders.",
                "You shall tell your son in that day, saying, \u2018It is because of that which Yahweh did for me when I came out of Egypt.\u2019",
                "It shall be for a sign to you on your hand, and for a memorial between your eyes, that the law of Yahweh may be in your mouth; for with a strong hand Yahweh has brought you out of Egypt.",
                "You shall therefore keep this ordinance in its season from year to year.",
                "\"It shall be, when Yahweh shall bring you into the land of the Canaanite, as he swore to you and to your fathers, and shall give it you,",
                "that you shall set apart to Yahweh all that opens the womb, and every firstborn which you have that comes from an animal. The males shall be Yahweh\u2019s.",
                "Every firstborn of a donkey you shall redeem with a lamb; and if you will not redeem it, then you shall break its neck; and you shall redeem all the firstborn of man among your sons.",
                "It shall be, when your son asks you in time to come, saying, \u2018What is this?\u2019 that you shall tell him, \u2018By strength of hand Yahweh brought us out from Egypt, from the house of bondage;",
                "and it happened, when Pharaoh would hardly let us go, that Yahweh killed all the firstborn in the land of Egypt, both the firstborn of man, and the firstborn of animal. Therefore I sacrifice to Yahweh all that opens the womb, being males; but all the firstborn of my sons I redeem.\u2019",
                "It shall be for a sign on your hand, and for symbols between your eyes: for by strength of hand Yahweh brought us out of Egypt.\"",
                "It happened, when Pharaoh had let the people go, that God didn\u2019t lead them by the way of the land of the Philistines, although that was near; for God said, \"Lest perhaps the people change their minds when they see war, and they return to Egypt;\"",
                "but God led the people around by the way of the wilderness by the Red Sea; and the children of Israel went up armed out of the land of Egypt.",
                "Moses took the bones of Joseph with him, for he had made the children of Israel swear, saying, \"God will surely visit you, and you shall carry up my bones away from here with you.\"",
                "They took their journey from Succoth, and encamped in Etham, in the edge of the wilderness.",
                "Yahweh went before them by day in a pillar of cloud, to lead them on their way, and by night in a pillar of fire, to give them light, that they might go by day and by night:",
                "the pillar of cloud by day, and the pillar of fire by night, didn\u2019t depart from before the people."
            ],
            14: [
                "Yahweh spoke to Moses, saying,",
                "\"Speak to the children of Israel, that they turn back and encamp before Pihahiroth, between Migdol and the sea, before Baal Zephon. You shall encamp opposite it by the sea.",
                "Pharaoh will say of the children of Israel, \u2018They are entangled in the land. The wilderness has shut them in.\u2019",
                "I will harden Pharaoh\u2019s heart, and he will follow after them; and I will get honor over Pharaoh, and over all his armies; and the Egyptians shall know that I am Yahweh.\" They did so.",
                "It was told the king of Egypt that the people had fled; and the heart of Pharaoh and of his servants was changed towards the people, and they said, \"What is this we have done, that we have let Israel go from serving us?\"",
                "He prepared his chariot, and took his army with him;",
                "and he took six hundred chosen chariots, and all the chariots of Egypt, and captains over all of them.",
                "Yahweh hardened the heart of Pharaoh king of Egypt, and he pursued after the children of Israel; for the children of Israel went out with a high hand.",
                "The Egyptians pursued after them: all the horses and chariots of Pharaoh, his horsemen, and his army; and overtook them encamping by the sea, beside Pihahiroth, before Baal Zephon.",
                "When Pharaoh drew near, the children of Israel lifted up their eyes, and behold, the Egyptians were marching after them; and they were very afraid. The children of Israel cried out to Yahweh.",
                "They said to Moses, \"Because there were no graves in Egypt, have you taken us away to die in the wilderness? Why have you treated us this way, to bring us out of Egypt?",
                "Isn\u2019t this the word that we spoke to you in Egypt, saying, \u2018Leave us alone, that we may serve the Egyptians?\u2019 For it were better for us to serve the Egyptians, than that we should die in the wilderness.\"",
                "Moses said to the people, \"Don\u2019t be afraid. Stand still, and see the salvation of Yahweh, which he will work for you today: for the Egyptians whom you have seen today, you shall never see them again.",
                "Yahweh will fight for you, and you shall be still.\"",
                "Yahweh said to Moses, \"Why do you cry to me? Speak to the children of Israel, that they go forward.",
                "Lift up your rod, and stretch out your hand over the sea, and divide it: and the children of Israel shall go into the midst of the sea on dry ground.",
                "I, behold, I will harden the hearts of the Egyptians, and they shall go in after them: and I will get myself honor over Pharaoh, and over all his armies, over his chariots, and over his horsemen.",
                "The Egyptians shall know that I am Yahweh, when I have gotten myself honor over Pharaoh, over his chariots, and over his horsemen.\"",
                "The angel of God, who went before the camp of Israel, moved and went behind them; and the pillar of cloud moved from before them, and stood behind them.",
                "It came between the camp of Egypt and the camp of Israel; and there was the cloud and the darkness, yet gave it light by night: and the one didn\u2019t come near the other all the night.",
                "Moses stretched out his hand over the sea, and Yahweh caused the sea to go back by a strong east wind all the night, and made the sea dry land, and the waters were divided.",
                "The children of Israel went into the midst of the sea on the dry ground, and the waters were a wall to them on their right hand, and on their left.",
                "The Egyptians pursued, and went in after them into the midst of the sea: all of Pharaoh\u2019s horses, his chariots, and his horsemen.",
                "It happened in the morning watch, that Yahweh looked out on the Egyptian army through the pillar of fire and of cloud, and confused the Egyptian army.",
                "He took off their chariot wheels, and they drove them heavily; so that the Egyptians said, \"Let\u2019s flee from the face of Israel, for Yahweh fights for them against the Egyptians!\"",
                "Yahweh said to Moses, \"Stretch out your hand over the sea, that the waters may come again on the Egyptians, on their chariots, and on their horsemen.\"",
                "Moses stretched out his hand over the sea, and the sea returned to its strength when the morning appeared; and the Egyptians fled against it. Yahweh overthrew the Egyptians in the midst of the sea.",
                "The waters returned, and covered the chariots and the horsemen, even all Pharaoh\u2019s army that went in after them into the sea. There remained not so much as one of them.",
                "But the children of Israel walked on dry land in the midst of the sea, and the waters were a wall to them on their right hand, and on their left.",
                "Thus Yahweh saved Israel that day out of the hand of the Egyptians; and Israel saw the Egyptians dead on the seashore.",
                "Israel saw the great work which Yahweh did to the Egyptians, and the people feared Yahweh; and they believed in Yahweh, and in his servant Moses."
            ],
            15: [
                "Then Moses and the children of Israel sang this song to Yahweh, and said, \"I will sing to Yahweh, for he has triumphed gloriously. The horse and his rider he has thrown into the sea.",
                "Yah is my strength and song. He has become my salvation. This is my God, and I will praise him; my father\u2019s God, and I will exalt him.",
                "Yahweh is a man of war. Yahweh is his name.",
                "He has cast Pharaoh\u2019s chariots and his army into the sea. His chosen captains are sunk in the Red Sea.",
                "The deeps cover them. They went down into the depths like a stone.",
                "Your right hand, Yahweh, is glorious in power. Your right hand, Yahweh, dashes the enemy in pieces.",
                "In the greatness of your excellency, you overthrow those who rise up against you. You send forth your wrath. It consumes them as stubble.",
                "With the blast of your nostrils, the waters were piled up. The floods stood upright as a heap. The deeps were congealed in the heart of the sea.",
                "The enemy said, \u2018I will pursue. I will overtake. I will divide the spoil. My desire shall be satisfied on them. I will draw my sword, my hand shall destroy them.\u2019",
                "You blew with your wind. The sea covered them. They sank like lead in the mighty waters.",
                "Who is like you, Yahweh, among the gods? Who is like you, glorious in holiness, fearful in praises, doing wonders?",
                "You stretched out your right hand. The earth swallowed them.",
                "\"You, in your loving kindness, have led the people that you have redeemed. You have guided them in your strength to your holy habitation.",
                "The peoples have heard. They tremble. Pangs have taken hold on the inhabitants of Philistia.",
                "Then the chiefs of Edom were dismayed. Trembling takes hold of the mighty men of Moab. All the inhabitants of Canaan are melted away.",
                "Terror and dread falls on them. By the greatness of your arm they are as still as a stone\u2014until your people pass over, Yahweh, until the people pass over who you have purchased.",
                "You shall bring them in, and plant them in the mountain of your inheritance, the place, Yahweh, which you have made for yourself to dwell in; the sanctuary, Lord, which your hands have established.",
                "Yahweh shall reign forever and ever.\"",
                "For the horses of Pharaoh went in with his chariots and with his horsemen into the sea, and Yahweh brought back the waters of the sea on them; but the children of Israel walked on dry land in the midst of the sea.",
                "Miriam the prophetess, the sister of Aaron, took a tambourine in her hand; and all the women went out after her with tambourines and with dances.",
                "Miriam answered them, \"Sing to Yahweh, for he has triumphed gloriously. The horse and his rider he has thrown into the sea.\"",
                "Moses led Israel onward from the Red Sea, and they went out into the wilderness of Shur; and they went three days in the wilderness, and found no water.",
                "When they came to Marah, they couldn\u2019t drink from the waters of Marah, for they were bitter. Therefore its name was called Marah.",
                "The people murmured against Moses, saying, \"What shall we drink?\"",
                "Then he cried to Yahweh. Yahweh showed him a tree, and he threw it into the waters, and the waters were made sweet. There he made a statute and an ordinance for them, and there he tested them;",
                "and he said, \"If you will diligently listen to the voice of Yahweh your God, and will do that which is right in his eyes, and will pay attention to his commandments, and keep all his statutes, I will put none of the diseases on you, which I have put on the Egyptians; for I am Yahweh who heals you.\"",
                "They came to Elim, where there were twelve springs of water, and seventy palm trees: and they encamped there by the waters."
            ],
            16: [
                "They took their journey from Elim, and all the congregation of the children of Israel came to the wilderness of Sin, which is between Elim and Sinai, on the fifteenth day of the second month after their departing out of the land of Egypt.",
                "The whole congregation of the children of Israel murmured against Moses and against Aaron in the wilderness;",
                "and the children of Israel said to them, \"We wish that we had died by the hand of Yahweh in the land of Egypt, when we sat by the meat pots, when we ate our fill of bread, for you have brought us out into this wilderness, to kill this whole assembly with hunger.\"",
                "Then Yahweh said to Moses, \"Behold, I will rain bread from the sky for you, and the people shall go out and gather a day\u2019s portion every day, that I may test them, whether they will walk in my law, or not.",
                "It shall come to pass on the sixth day, that they shall prepare that which they bring in, and it shall be twice as much as they gather daily.\"",
                "Moses and Aaron said to all the children of Israel, \"At evening, then you shall know that Yahweh has brought you out from the land of Egypt;",
                "and in the morning, then you shall see the glory of Yahweh; because he hears your murmurings against Yahweh. Who are we, that you murmur against us?\"",
                "Moses said, \"Now Yahweh shall give you meat to eat in the evening, and in the morning bread to satisfy you; because Yahweh hears your murmurings which you murmur against him. And who are we? Your murmurings are not against us, but against Yahweh.\"",
                "Moses said to Aaron, \"Tell all the congregation of the children of Israel, \u2018Come near before Yahweh, for he has heard your murmurings.\u2019\"",
                "It happened, as Aaron spoke to the whole congregation of the children of Israel, that they looked toward the wilderness, and behold, the glory of Yahweh appeared in the cloud.",
                "Yahweh spoke to Moses, saying,",
                "\"I have heard the murmurings of the children of Israel. Speak to them, saying, \u2018At evening you shall eat meat, and in the morning you shall be filled with bread: and you shall know that I am Yahweh your God.\u2019\"",
                "It happened at evening that quail came up and covered the camp; and in the morning the dew lay around the camp.",
                "When the dew that lay had gone, behold, on the surface of the wilderness was a small round thing, small as the frost on the ground.",
                "When the children of Israel saw it, they said one to another, \"What is it?\" For they didn\u2019t know what it was. Moses said to them, \"It is the bread which Yahweh has given you to eat.\"",
                "This is the thing which Yahweh has commanded: \"Gather of it everyone according to his eating; an omer a head, according to the number of your persons, you shall take it, every man for those who are in his tent.\"",
                "The children of Israel did so, and gathered some more, some less.",
                "When they measured it with an omer, he who gathered much had nothing over, and he who gathered little had no lack. They gathered every man according to his eating.",
                "Moses said to them, \"Let no one leave of it until the morning.\"",
                "Notwithstanding they didn\u2019t listen to Moses, but some of them left of it until the morning, and it bred worms, and became foul: and Moses was angry with them.",
                "They gathered it morning by morning, everyone according to his eating. When the sun grew hot, it melted.",
                "It happened that on the sixth day they gathered twice as much bread, two omers for each one, and all the rulers of the congregation came and told Moses.",
                "He said to them, \"This is that which Yahweh has spoken, \u2018Tomorrow is a solemn rest, a holy Sabbath to Yahweh. Bake that which you want to bake, and boil that which you want to boil; and all that remains over lay up for yourselves to be kept until the morning.\u2019\"",
                "They laid it up until the morning, as Moses asked, and it didn\u2019t become foul, neither was there any worm in it.",
                "Moses said, \"Eat that today, for today is a Sabbath to Yahweh. Today you shall not find it in the field.",
                "Six days you shall gather it, but on the seventh day is the Sabbath. In it there shall be none.\"",
                "It happened on the seventh day, that some of the people went out to gather, and they found none.",
                "Yahweh said to Moses, \"How long do you refuse to keep my commandments and my laws?",
                "Behold, because Yahweh has given you the Sabbath, therefore he gives you on the sixth day the bread of two days. Everyone stay in his place. Let no one go out of his place on the seventh day.\"",
                "So the people rested on the seventh day.",
                "The house of Israel called its name Manna, and it was like coriander seed, white; and its taste was like wafers with honey.",
                "Moses said, \"This is the thing which Yahweh has commanded, \u2018Let an omer-full of it be kept throughout your generations, that they may see the bread with which I fed you in the wilderness, when I brought you forth from the land of Egypt.\u2019\"",
                "Moses said to Aaron, \"Take a pot, and put an omer-full of manna in it, and lay it up before Yahweh, to be kept throughout your generations.\"",
                "As Yahweh commanded Moses, so Aaron laid it up before the Testimony, to be kept.",
                "The children of Israel ate the manna forty years, until they came to an inhabited land. They ate the manna until they came to the borders of the land of Canaan.",
                "Now an omer is the tenth part of an ephah."
            ],
            17: [
                "All the congregation of the children of Israel traveled from the wilderness of Sin, by their journeys, according to Yahweh\u2019s commandment, and encamped in Rephidim; but there was no water for the people to drink.",
                "Therefore the people quarreled with Moses, and said, \"Give us water to drink.\" Moses said to them, \"Why do you quarrel with me? Why do you test Yahweh?\"",
                "The people were thirsty for water there; and the people murmured against Moses, and said, \"Why have you brought us up out of Egypt, to kill us, our children, and our livestock with thirst?\"",
                "Moses cried to Yahweh, saying, \"What shall I do with these people? They are almost ready to stone me.\"",
                "Yahweh said to Moses, \"Walk on before the people, and take the elders of Israel with you, and take the rod in your hand with which you struck the Nile, and go.",
                "Behold, I will stand before you there on the rock in Horeb. You shall strike the rock, and water will come out of it, that the people may drink.\" Moses did so in the sight of the elders of Israel.",
                "He called the name of the place Massah, and Meribah, because the children of Israel quarreled, and because they tested Yahweh, saying, \"Is Yahweh among us, or not?\"",
                "Then Amalek came and fought with Israel in Rephidim.",
                "Moses said to Joshua, \"Choose men for us, and go out, fight with Amalek. Tomorrow I will stand on the top of the hill with God\u2019s rod in my hand.\"",
                "So Joshua did as Moses had told him, and fought with Amalek; and Moses, Aaron, and Hur went up to the top of the hill.",
                "It happened, when Moses held up his hand, that Israel prevailed; and when he let down his hand, Amalek prevailed.",
                "But Moses\u2019 hands were heavy; and they took a stone, and put it under him, and he sat on it. Aaron and Hur held up his hands, the one on the one side, and the other on the other side. His hands were steady until sunset.",
                "Joshua defeated Amalek and his people with the edge of the sword.",
                "Yahweh said to Moses, \"Write this for a memorial in a book, and rehearse it in the ears of Joshua: that I will utterly blot out the memory of Amalek from under the sky.\"",
                "Moses built an altar, and called its name Yahweh our Banner.",
                "He said, \"Yah has sworn: \u2018Yahweh will have war with Amalek from generation to generation.\u2019\""
            ],
            18: [
                "Now Jethro, the priest of Midian, Moses\u2019 father-in-law, heard of all that God had done for Moses, and for Israel his people, how that Yahweh had brought Israel out of Egypt.",
                "Jethro, Moses\u2019 father-in-law, received Zipporah, Moses\u2019 wife, after he had sent her away,",
                "and her two sons. The name of one son was Gershom, for Moses said, \"I have lived as a foreigner in a foreign land\".",
                "The name of the other was Eliezer, for he said, \"My father\u2019s God was my help and delivered me from Pharaoh\u2019s sword.\"",
                "Jethro, Moses\u2019 father-in-law, came with his sons and his wife to Moses into the wilderness where he was encamped, at the Mountain of God.",
                "He said to Moses, \"I, your father-in-law Jethro, have come to you with your wife, and her two sons with her.\"",
                "Moses went out to meet his father-in-law, and bowed and kissed him. They asked each other of their welfare, and they came into the tent.",
                "Moses told his father-in-law all that Yahweh had done to Pharaoh and to the Egyptians for Israel\u2019s sake, all the hardships that had come on them on the way, and how Yahweh delivered them.",
                "Jethro rejoiced for all the goodness which Yahweh had done to Israel, in that he had delivered them out of the hand of the Egyptians.",
                "Jethro said, \"Blessed be Yahweh, who has delivered you out of the hand of the Egyptians, and out of the hand of Pharaoh; who has delivered the people from under the hand of the Egyptians.",
                "Now I know that Yahweh is greater than all gods because of the thing in which they dealt arrogantly against them.\"",
                "Jethro, Moses\u2019 father-in-law, took a burnt offering and sacrifices for God. Aaron came with all of the elders of Israel, to eat bread with Moses\u2019 father-in-law before God.",
                "It happened on the next day, that Moses sat to judge the people, and the people stood around Moses from the morning to the evening.",
                "When Moses\u2019 father-in-law saw all that he did to the people, he said, \"What is this thing that you do for the people? Why do you sit alone, and all the people stand around you from morning to evening?\"",
                "Moses said to his father-in-law, \"Because the people come to me to inquire of God.",
                "When they have a matter, they come to me, and I judge between a man and his neighbor, and I make them know the statutes of God, and his laws.\"",
                "Moses\u2019 father-in-law said to him, \"The thing that you do is not good.",
                "You will surely wear away, both you, and this people that is with you; for the thing is too heavy for you. You are not able to perform it yourself alone.",
                "Listen now to my voice. I will give you counsel, and God be with you. You represent the people before God, and bring the causes to God.",
                "You shall teach them the statutes and the laws, and shall show them the way in which they must walk, and the work that they must do.",
                "Moreover you shall provide out of all the people able men, such as fear God: men of truth, hating unjust gain; and place such over them, to be rulers of thousands, rulers of hundreds, rulers of fifties, and rulers of tens.",
                "Let them judge the people at all times. It shall be that every great matter they shall bring to you, but every small matter they shall judge themselves. So shall it be easier for you, and they shall share the load with you.",
                "If you will do this thing, and God commands you so, then you will be able to endure, and all of these people also will go to their place in peace.\"",
                "So Moses listened to the voice of his father-in-law, and did all that he had said.",
                "Moses chose able men out of all Israel, and made them heads over the people, rulers of thousands, rulers of hundreds, rulers of fifties, and rulers of tens.",
                "They judged the people at all times. They brought the hard causes to Moses, but every small matter they judged themselves.",
                "Moses let his father-in-law depart, and he went his way into his own land."
            ],
            19: [
                "In the third month after the children of Israel had gone out of the land of Egypt, on that same day they came into the wilderness of Sinai.",
                "When they had departed from Rephidim, and had come to the wilderness of Sinai, they encamped in the wilderness; and there Israel encamped before the mountain.",
                "Moses went up to God, and Yahweh called to him out of the mountain, saying, \"This is what you shall tell the house of Jacob, and tell the children of Israel:",
                "\u2018You have seen what I did to the Egyptians, and how I bore you on eagles\u2019 wings, and brought you to myself.",
                "Now therefore, if you will indeed obey my voice, and keep my covenant, then you shall be my own possession from among all peoples; for all the earth is mine;",
                "and you shall be to me a kingdom of priests, and a holy nation.\u2019 These are the words which you shall speak to the children of Israel.\"",
                "Moses came and called for the elders of the people, and set before them all these words which Yahweh commanded him.",
                "All the people answered together, and said, \"All that Yahweh has spoken we will do.\" Moses reported the words of the people to Yahweh.",
                "Yahweh said to Moses, \"Behold, I come to you in a thick cloud, that the people may hear when I speak with you, and may also believe you forever.\" Moses told the words of the people to Yahweh.",
                "Yahweh said to Moses, \"Go to the people, and sanctify them today and tomorrow, and let them wash their garments,",
                "and be ready against the third day; for on the third day Yahweh will come down in the sight of all the people on Mount Sinai.",
                "You shall set bounds to the people all around, saying, \u2018Be careful that you don\u2019t go up onto the mountain, or touch its border. Whoever touches the mountain shall be surely put to death.",
                "No hand shall touch him, but he shall surely be stoned or shot through; whether it is animal or man, he shall not live.\u2019 When the trumpet sounds long, they shall come up to the mountain.\"",
                "Moses went down from the mountain to the people, and sanctified the people; and they washed their clothes.",
                "He said to the people, \"Be ready by the third day. Don\u2019t have sexual relations with a woman.\"",
                "It happened on the third day, when it was morning, that there were thunders and lightnings, and a thick cloud on the mountain, and the sound of an exceedingly loud trumpet; and all the people who were in the camp trembled.",
                "Moses led the people out of the camp to meet God; and they stood at the lower part of the mountain.",
                "Mount Sinai, all it, smoked, because Yahweh descended on it in fire; and its smoke ascended like the smoke of a furnace, and the whole mountain quaked greatly.",
                "When the sound of the trumpet grew louder and louder, Moses spoke, and God answered him by a voice.",
                "Yahweh came down on Mount Sinai, to the top of the mountain. Yahweh called Moses to the top of the mountain, and Moses went up.",
                "Yahweh said to Moses, \"Go down, warn the people, lest they break through to Yahweh to gaze, and many of them perish.",
                "Let the priests also, who come near to Yahweh, sanctify themselves, lest Yahweh break forth on them.\"",
                "Moses said to Yahweh, \"The people can\u2019t come up to Mount Sinai, for you warned us, saying, \u2018Set bounds around the mountain, and sanctify it.\u2019\"",
                "Yahweh said to him, \"Go down and you shall bring Aaron up with you, but don\u2019t let the priests and the people break through to come up to Yahweh, lest he break forth on them.\"",
                "So Moses went down to the people, and told them."
            ],
            20: [
                "God spoke all these words, saying,",
                "\"I am Yahweh your God, who brought you out of the land of Egypt, out of the house of bondage.",
                "\"You shall have no other gods before me.",
                "\"You shall not make for yourselves an idol, nor any image of anything that is in the heavens above, or that is in the earth beneath, or that is in the water under the earth:",
                "you shall not bow yourself down to them, nor serve them, for I, Yahweh your God, am a jealous God, visiting the iniquity of the fathers on the children, on the third and on the fourth generation of those who hate me,",
                "and showing loving kindness to thousands of those who love me and keep my commandments.",
                "\"You shall not take the name of Yahweh your God in vain, for Yahweh will not hold him guiltless who takes his name in vain.",
                "\"Remember the Sabbath day, to keep it holy.",
                "You shall labor six days, and do all your work,",
                "but the seventh day is a Sabbath to Yahweh your God. You shall not do any work in it, you, nor your son, nor your daughter, your male servant, nor your female servant, nor your livestock, nor your stranger who is within your gates;",
                "for in six days Yahweh made heaven and earth, the sea, and all that is in them, and rested the seventh day; therefore Yahweh blessed the Sabbath day, and made it holy.",
                "\"Honor your father and your mother, that your days may be long in the land which Yahweh your God gives you.",
                "\"You shall not murder.",
                "\"You shall not commit adultery.",
                "\"You shall not steal.",
                "\"You shall not give false testimony against your neighbor.",
                "\"You shall not covet your neighbor\u2019s house. You shall not covet your neighbor\u2019s wife, nor his male servant, nor his female servant, nor his ox, nor his donkey, nor anything that is your neighbor\u2019s.\"",
                "All the people perceived the thunderings, the lightnings, the sound of the trumpet, and the mountain smoking. When the people saw it, they trembled, and stayed at a distance.",
                "They said to Moses, \"Speak with us yourself, and we will listen; but don\u2019t let God speak with us, lest we die.\"",
                "Moses said to the people, \"Don\u2019t be afraid, for God has come to test you, and that his fear may be before you, that you won\u2019t sin.\"",
                "The people stayed at a distance, and Moses drew near to the thick darkness where God was.",
                "Yahweh said to Moses, \"This is what you shall tell the children of Israel: \u2018You yourselves have seen that I have talked with you from heaven.",
                "You shall most certainly not make alongside of me gods of silver, or gods of gold for yourselves.",
                "You shall make an altar of earth for me, and shall sacrifice on it your burnt offerings and your peace offerings, your sheep and your cattle. In every place where I record my name I will come to you and I will bless you.",
                "If you make me an altar of stone, you shall not build it of cut stones; for if you lift up your tool on it, you have polluted it.",
                "Neither shall you go up by steps to my altar, that your nakedness may not be exposed to it.\u2019"
            ],
            21: [
                "\"Now these are the ordinances which you shall set before them.",
                "\"If you buy a Hebrew servant, he shall serve six years and in the seventh he shall go out free without paying anything.",
                "If he comes in by himself, he shall go out by himself. If he is married, then his wife shall go out with him.",
                "If his master gives him a wife and she bears him sons or daughters, the wife and her children shall be her master\u2019s, and he shall go out by himself.",
                "But if the servant shall plainly say, \u2018I love my master, my wife, and my children. I will not go out free;\u2019",
                "then his master shall bring him to God, and shall bring him to the door or to the doorpost, and his master shall bore his ear through with an awl, and he shall serve him for ever.",
                "\"If a man sells his daughter to be a female servant, she shall not go out as the male servants do.",
                "If she doesn\u2019t please her master, who has married her to himself, then he shall let her be redeemed. He shall have no right to sell her to a foreign people, since he has dealt deceitfully with her.",
                "If he marries her to his son, he shall deal with her as a daughter.",
                "If he takes another wife to himself, he shall not diminish her food, her clothing, and her marital rights.",
                "If he doesn\u2019t do these three things for her, she may go free without paying any money.",
                "\"One who strikes a man so that he dies shall surely be put to death,",
                "but not if it is unintentional, but God allows it to happen: then I will appoint you a place where he shall flee.",
                "If a man schemes and comes presumptuously on his neighbor to kill him, you shall take him from my altar, that he may die.",
                "\"Anyone who attacks his father or his mother shall be surely put to death.",
                "\"Anyone who kidnaps someone and sells him, or if he is found in his hand, he shall surely be put to death.",
                "\"Anyone who curses his father or his mother shall surely be put to death.",
                "\"If men quarrel and one strikes the other with a stone, or with his fist, and he doesn\u2019t die, but is confined to bed;",
                "if he rises again and walks around with his staff, then he who struck him shall be cleared: only he shall pay for the loss of his time, and shall provide for his healing until he is thoroughly healed.",
                "\"If a man strikes his servant or his maid with a rod, and he dies under his hand, he shall surely be punished.",
                "Notwithstanding, if he gets up after a day or two, he shall not be punished, for he is his property.",
                "\"If men fight and hurt a pregnant woman so that she gives birth prematurely, and yet no harm follows, he shall be surely fined as much as the woman\u2019s husband demands and the judges allow.",
                "But if any harm follows, then you must take life for life,",
                "eye for eye, tooth for tooth, hand for hand, foot for foot,",
                "burning for burning, wound for wound, and bruise for bruise.",
                "\"If a man strikes his servant\u2019s eye, or his maid\u2019s eye, and destroys it, he shall let him go free for his eye\u2019s sake.",
                "If he strikes out his male servant\u2019s tooth, or his female servant\u2019s tooth, he shall let him go free for his tooth\u2019s sake.",
                "\"If a bull gores a man or a woman to death, the bull shall surely be stoned, and its flesh shall not be eaten; but the owner of the bull shall not be held responsible.",
                "But if the bull had a habit of goring in the past, and it has been testified to its owner, and he has not kept it in, but it has killed a man or a woman, the bull shall be stoned, and its owner shall also be put to death.",
                "If a ransom is laid on him, then he shall give for the redemption of his life whatever is laid on him.",
                "Whether it has gored a son or has gored a daughter, according to this judgment it shall be done to him.",
                "If the bull gores a male servant or a female servant, thirty shekels of silver shall be given to their master, and the ox shall be stoned.",
                "\"If a man opens a pit, or if a man digs a pit and doesn\u2019t cover it, and a bull or a donkey falls into it,",
                "the owner of the pit shall make it good. He shall give money to its owner, and the dead animal shall be his.",
                "\"If one man\u2019s bull injures another\u2019s, so that it dies, then they shall sell the live bull, and divide its price; and they shall also divide the dead animal.",
                "Or if it is known that the bull was in the habit of goring in the past, and its owner has not kept it in, he shall surely pay bull for bull, and the dead animal shall be his own."
            ],
            22: [
                "\"If a man steals an ox or a sheep, and kills it, or sells it; he shall pay five oxen for an ox, and four sheep for a sheep.",
                "If the thief is found breaking in, and is struck so that he dies, there shall be no guilt of bloodshed for him.",
                "If the sun has risen on him, guilt of bloodshed shall be for him; he shall make restitution. If he has nothing, then he shall be sold for his theft.",
                "If the stolen property is found in his hand alive, whether it is ox, donkey, or sheep, he shall pay double.",
                "\"If a man causes a field or vineyard to be eaten, and lets his animal loose, and it grazes in another man\u2019s field, he shall make restitution from the best of his own field, and from the best of his own vineyard.",
                "\"If fire breaks out, and catches in thorns so that the shocks of grain, or the standing grain, or the field are consumed; he who kindled the fire shall surely make restitution.",
                "\"If a man delivers to his neighbor money or stuff to keep, and it is stolen out of the man\u2019s house; if the thief is found, he shall pay double.",
                "If the thief isn\u2019t found, then the master of the house shall come near to God, to find out if he hasn\u2019t put his hand to his neighbor\u2019s goods.",
                "For every matter of trespass, whether it be for ox, for donkey, for sheep, for clothing, or for any kind of lost thing, about which one says, \u2018This is mine,\u2019 the cause of both parties shall come before God. He whom God condemns shall pay double to his neighbor.",
                "\"If a man delivers to his neighbor a donkey, an ox, a sheep, or any animal to keep, and it dies or is injured, or driven away, no man seeing it;",
                "the oath of Yahweh shall be between them both, whether he hasn\u2019t put his hand to his neighbor\u2019s goods; and its owner shall accept it, and he shall not make restitution.",
                "But if it is stolen from him, he shall make restitution to its owner.",
                "If it is torn in pieces, let him bring it for evidence. He shall not make good that which was torn.",
                "\"If a man borrows anything of his neighbor\u2019s, and it is injured, or dies, its owner not being with it, he shall surely make restitution.",
                "If its owner is with it, he shall not make it good. If it is a leased thing, it came for its lease.",
                "\"If a man entices a virgin who isn\u2019t pledged to be married, and lies with her, he shall surely pay a dowry for her to be his wife.",
                "If her father utterly refuses to give her to him, he shall pay money according to the dowry of virgins.",
                "\"You shall not allow a sorceress to live.",
                "\"Whoever has sex with an animal shall surely be put to death.",
                "\"He who sacrifices to any god, except to Yahweh only, shall be utterly destroyed.",
                "\"You shall not wrong an alien, neither shall you oppress him, for you were aliens in the land of Egypt.",
                "\"You shall not take advantage of any widow or fatherless child.",
                "If you take advantage of them at all, and they cry at all to me, I will surely hear their cry;",
                "and my wrath will grow hot, and I will kill you with the sword; and your wives shall be widows, and your children fatherless.",
                "\"If you lend money to any of my people with you who is poor, you shall not be to him as a creditor; neither shall you charge him interest.",
                "If you take your neighbor\u2019s garment as collateral, you shall restore it to him before the sun goes down,",
                "for that is his only covering, it is his garment for his skin. What would he sleep in? It will happen, when he cries to me, that I will hear, for I am gracious.",
                "\"You shall not blaspheme God, nor curse a ruler of your people.",
                "\"You shall not delay to offer from your harvest and from the outflow of your presses. \"You shall give the firstborn of your sons to me.",
                "You shall do likewise with your cattle and with your sheep. Seven days it shall be with its mother, then on the eighth day you shall give it to me.",
                "\"You shall be holy men to me, therefore you shall not eat any flesh that is torn by animals in the field. You shall cast it to the dogs."
            ],
            23: [
                "\"You shall not spread a false report. Don\u2019t join your hand with the wicked to be a malicious witness.",
                "\"You shall not follow a crowd to do evil; neither shall you testify in court to side with a multitude to pervert justice;",
                "neither shall you favor a poor man in his cause.",
                "\"If you meet your enemy\u2019s ox or his donkey going astray, you shall surely bring it back to him again.",
                "If you see the donkey of him who hates you fallen down under his burden, don\u2019t leave him, you shall surely help him with it.",
                "\"You shall not deny justice to your poor people in their lawsuits.",
                "\"Keep far from a false charge, and don\u2019t kill the innocent and righteous: for I will not justify the wicked.",
                "\"You shall take no bribe, for a bribe blinds those who have sight and perverts the words of the righteous.",
                "\"You shall not oppress an alien, for you know the heart of an alien, since you were aliens in the land of Egypt.",
                "\"For six years you shall sow your land, and shall gather in its increase,",
                "but the seventh year you shall let it rest and lie fallow, that the poor of your people may eat; and what they leave the animal of the field shall eat. In the same way, you shall deal with your vineyard and with your olive grove.",
                "\"Six days you shall do your work, and on the seventh day you shall rest, that your ox and your donkey may have rest, and the son of your handmaid, and the alien may be refreshed.",
                "\"Be careful to do all things that I have said to you; and don\u2019t invoke the name of other gods, neither let them be heard out of your mouth.",
                "\"You shall observe a feast to me three times a year.",
                "You shall observe the feast of unleavened bread. Seven days you shall eat unleavened bread, as I commanded you, at the time appointed in the month Abib (for in it you came out from Egypt), and no one shall appear before me empty.",
                "And the feast of harvest, the first fruits of your labors, which you sow in the field: and the feast of harvest, at the end of the year, when you gather in your labors out of the field.",
                "Three times in the year all your males shall appear before the Lord Yahweh.",
                "\"You shall not offer the blood of my sacrifice with leavened bread, neither shall the fat of my feast remain all night until the morning.",
                "The first of the first fruits of your ground you shall bring into the house of Yahweh your God. \"You shall not boil a young goat in its mother\u2019s milk.",
                "\"Behold, I send an angel before you, to keep you by the way, and to bring you into the place which I have prepared.",
                "Pay attention to him, and listen to his voice. Don\u2019t provoke him, for he will not pardon your disobedience, for my name is in him.",
                "But if you indeed listen to his voice, and do all that I speak, then I will be an enemy to your enemies, and an adversary to your adversaries.",
                "For my angel shall go before you, and bring you in to the Amorite, the Hittite, the Perizzite, the Canaanite, the Hivite, and the Jebusite; and I will cut them off.",
                "You shall not bow down to their gods, nor serve them, nor follow their practices, but you shall utterly overthrow them and demolish their pillars.",
                "You shall serve Yahweh your God, and he will bless your bread and your water, and I will take sickness away from your midst.",
                "No one will miscarry or be barren in your land. I will fulfill the number of your days.",
                "I will send my terror before you, and will confuse all the people to whom you come, and I will make all your enemies turn their backs to you.",
                "I will send the hornet before you, which will drive out the Hivite, the Canaanite, and the Hittite, from before you.",
                "I will not drive them out from before you in one year, lest the land become desolate, and the animals of the field multiply against you.",
                "Little by little I will drive them out from before you, until you have increased and inherit the land.",
                "I will set your border from the Red Sea even to the sea of the Philistines, and from the wilderness to the River; for I will deliver the inhabitants of the land into your hand, and you shall drive them out before you.",
                "You shall make no covenant with them, nor with their gods.",
                "They shall not dwell in your land, lest they make you sin against me, for if you serve their gods, it will surely be a snare to you.\""
            ],
            24: [
                "He said to Moses, \"Come up to Yahweh, you, and Aaron, Nadab, and Abihu, and seventy of the elders of Israel; and worship from a distance.",
                "Moses alone shall come near to Yahweh, but they shall not come near, neither shall the people go up with him.\"",
                "Moses came and told the people all the words of Yahweh, and all the ordinances; and all the people answered with one voice, and said, \"All the words which Yahweh has spoken will we do.\"",
                "Moses wrote all the words of Yahweh, and rose up early in the morning, and built an altar under the mountain, and twelve pillars for the twelve tribes of Israel.",
                "He sent young men of the children of Israel, who offered burnt offerings and sacrificed peace offerings of cattle to Yahweh.",
                "Moses took half of the blood and put it in basins, and half of the blood he sprinkled on the altar.",
                "He took the book of the covenant and read it in the hearing of the people, and they said, \"All that Yahweh has spoken will we do, and be obedient.\"",
                "Moses took the blood, and sprinkled it on the people, and said, \"Look, this is the blood of the covenant, which Yahweh has made with you concerning all these words.\"",
                "Then Moses, Aaron, Nadab, Abihu, and seventy of the elders of Israel went up.",
                "They saw the God of Israel. Under his feet was like a paved work of sapphire stone, like the skies for clearness.",
                "He didn\u2019t lay his hand on the nobles of the children of Israel. They saw God, and ate and drank.",
                "Yahweh said to Moses, \"Come up to me on the mountain, and stay here, and I will give you the tables of stone with the law and the commands that I have written, that you may teach them.\"",
                "Moses rose up with Joshua, his servant, and Moses went up onto God\u2019s Mountain.",
                "He said to the elders, \"Wait here for us, until we come again to you. Behold, Aaron and Hur are with you. Whoever is involved in a dispute can go to them.\"",
                "Moses went up on the mountain, and the cloud covered the mountain.",
                "The glory of Yahweh settled on Mount Sinai, and the cloud covered it six days. The seventh day he called to Moses out of the midst of the cloud.",
                "The appearance of the glory of Yahweh was like devouring fire on the top of the mountain in the eyes of the children of Israel.",
                "Moses entered into the midst of the cloud, and went up on the mountain; and Moses was on the mountain forty days and forty nights."
            ],
            25: [
                "Yahweh spoke to Moses, saying,",
                "\"Speak to the children of Israel, that they take an offering for me. From everyone whose heart makes him willing you shall take my offering.",
                "This is the offering which you shall take from them: gold, silver, brass,",
                "blue, purple, scarlet, fine linen, goats\u2019 hair,",
                "rams\u2019 skins dyed red, sea cow hides, acacia wood,",
                "oil for the light, spices for the anointing oil and for the sweet incense,",
                "onyx stones, and stones to be set for the ephod and for the breastplate.",
                "Let them make me a sanctuary, that I may dwell among them.",
                "According to all that I show you, the pattern of the tabernacle, and the pattern of all of its furniture, even so you shall make it.",
                "\"They shall make an ark of acacia wood. Its length shall be two and a half cubits, its breadth a cubit and a half, and a cubit and a half its height.",
                "You shall overlay it with pure gold. You shall overlay it inside and outside, and you shall make a gold molding around it.",
                "You shall cast four rings of gold for it, and put them in its four feet. Two rings shall be on the one side of it, and two rings on the other side of it.",
                "You shall make poles of acacia wood, and overlay them with gold.",
                "You shall put the poles into the rings on the sides of the ark to carry the ark.",
                "The poles shall be in the rings of the ark. They shall not be taken from it.",
                "You shall put the testimony which I shall give you into the ark.",
                "You shall make a mercy seat of pure gold. Two and a half cubits shall be its length, and a cubit and a half its breadth.",
                "You shall make two cherubim of hammered gold. You shall make them at the two ends of the mercy seat.",
                "Make one cherub at the one end, and one cherub at the other end. You shall make the cherubim on its two ends of one piece with the mercy seat.",
                "The cherubim shall spread out their wings upward, covering the mercy seat with their wings, with their faces toward one another. The faces of the cherubim shall be toward the mercy seat.",
                "You shall put the mercy seat on top of the ark, and in the ark you shall put the testimony that I will give you.",
                "There I will meet with you, and I will tell you from above the mercy seat, from between the two cherubim which are on the ark of the testimony, all that I command you for the children of Israel.",
                "\"You shall make a table of acacia wood. Two cubits shall be its length, and a cubit its breadth, and one and a half cubits its height.",
                "You shall overlay it with pure gold, and make a gold molding around it.",
                "You shall make a rim of a handbreadth around it. You shall make a golden molding on its rim around it.",
                "You shall make four rings of gold for it, and put the rings in the four corners that are on its four feet.",
                "the rings shall be close to the rim, for places for the poles to carry the table.",
                "You shall make the poles of acacia wood, and overlay them with gold, that the table may be carried with them.",
                "You shall make its dishes, its spoons, its ladles, and its bowls to pour out offerings with. You shall make them of pure gold.",
                "You shall set bread of the presence on the table before me always.",
                "\"You shall make a lampstand of pure gold. Of hammered work shall the lampstand be made, even its base, its shaft, its cups, its buds, and its flowers, shall be of one piece with it.",
                "There shall be six branches going out of its sides: three branches of the lampstand out of its one side, and three branches of the lampstand out of its other side;",
                "three cups made like almond blossoms in one branch, a bud and a flower; and three cups made like almond blossoms in the other branch, a bud and a flower, so for the six branches going out of the lampstand;",
                "and in the lampstand four cups made like almond blossoms, its buds and its flowers;",
                "and a bud under two branches of one piece with it, and a bud under two branches of one piece with it, and a bud under two branches of one piece with it, for the six branches going out of the lampstand.",
                "Their buds and their branches shall be of one piece with it, all of it one beaten work of pure gold.",
                "You shall make its lamps seven, and they shall light its lamps to give light to the space in front of it.",
                "Its snuffers and its snuff dishes shall be of pure gold.",
                "It shall be made of a talent of pure gold, with all these accessories.",
                "See that you make them after their pattern, which has been shown to you on the mountain."
            ],
            26: [
                "\"Moreover you shall make the tabernacle with ten curtains; of fine twined linen, and blue, and purple, and scarlet, with cherubim. The work of the skillful workman you shall make them.",
                "The length of each curtain shall be twenty-eight cubits, and the breadth of each curtain four cubits: all the curtains shall have one measure.",
                "Five curtains shall be coupled together one to another; and the other five curtains shall be coupled one to another.",
                "You shall make loops of blue on the edge of the one curtain from the edge in the coupling; and likewise you shall make in the edge of the curtain that is outmost in the second coupling.",
                "You shall make fifty loops in the one curtain, and you shall make fifty loops in the edge of the curtain that is in the second coupling. The loops shall be opposite one to another.",
                "You shall make fifty clasps of gold, and couple the curtains one to another with the clasps: and the tabernacle shall be a unit.",
                "\"You shall make curtains of goats\u2019 hair for a covering over the tabernacle. You shall make them eleven curtains.",
                "The length of each curtain shall be thirty cubits, and the breadth of each curtain four cubits: the eleven curtains shall have one measure.",
                "You shall couple five curtains by themselves, and six curtains by themselves, and shall double over the sixth curtain in the forefront of the tent.",
                "You shall make fifty loops on the edge of the one curtain that is outmost in the coupling, and fifty loops on the edge of the curtain which is outmost in the second coupling.",
                "You shall make fifty clasps of brass, and put the clasps into the loops, and couple the tent together, that it may be one.",
                "The overhanging part that remains of the curtains of the tent, the half curtain that remains, shall hang over the back of the tabernacle.",
                "The cubit on the one side, and the cubit on the other side, of that which remains in the length of the curtains of the tent, shall hang over the sides of the tabernacle on this side and on that side, to cover it.",
                "You shall make a covering for the tent of rams\u2019 skins dyed red, and a covering of sea cow hides above.",
                "\"You shall make the boards for the tabernacle of acacia wood, standing up.",
                "Ten cubits shall be the length of a board, and one and a half cubits the breadth of each board.",
                "There shall be two tenons in each board, joined to one another: thus you shall make for all the boards of the tabernacle.",
                "You shall make the boards for the tabernacle, twenty boards for the south side southward.",
                "You shall make forty sockets of silver under the twenty boards; two sockets under one board for its two tenons, and two sockets under another board for its two tenons.",
                "For the second side of the tabernacle, on the north side, twenty boards,",
                "and their forty sockets of silver; two sockets under one board, and two sockets under another board.",
                "For the far part of the tabernacle westward you shall make six boards.",
                "You shall make two boards for the corners of the tabernacle in the far part.",
                "They shall be double beneath, and in the same way they shall be whole to its top to one ring: thus shall it be for them both; they shall be for the two corners.",
                "There shall be eight boards, and their sockets of silver, sixteen sockets; two sockets under one board, and two sockets under another board.",
                "\"You shall make bars of acacia wood: five for the boards of the one side of the tabernacle,",
                "and five bars for the boards of the other side of the tabernacle, and five bars for the boards of the side of the tabernacle, for the far part westward.",
                "The middle bar in the midst of the boards shall pass through from end to end.",
                "You shall overlay the boards with gold, and make their rings of gold for places for the bars: and you shall overlay the bars with gold.",
                "You shall set up the tabernacle according to the way that it was shown to you on the mountain.",
                "\"You shall make a veil of blue, and purple, and scarlet, and fine twined linen, with cherubim. The work of the skillful workman shall it be made.",
                "You shall hang it on four pillars of acacia overlaid with gold; their hooks shall be of gold, on four sockets of silver.",
                "You shall hang up the veil under the clasps, and shall bring the ark of the testimony in there within the veil: and the veil shall separate the holy place from the most holy for you.",
                "You shall put the mercy seat on the ark of the testimony in the most holy place.",
                "You shall set the table outside the veil, and the lampstand over against the table on the side of the tabernacle toward the south: and you shall put the table on the north side.",
                "\"You shall make a screen for the door of the Tent, of blue, and purple, and scarlet, and fine twined linen, the work of the embroiderer.",
                "You shall make for the screen five pillars of acacia, and overlay them with gold: their hooks shall be of gold: and you shall cast five sockets of brass for them."
            ],
            27: [
                "\"You shall make the altar of acacia wood, five cubits long, and five cubits broad; the altar shall be foursquare: and its height shall be three cubits.",
                "You shall make its horns on its four corners; its horns shall be of one piece with it; and you shall overlay it with brass.",
                "You shall make its pots to take away its ashes, its shovels, its basins, its flesh hooks, and its fire pans: all its vessels you shall make of brass.",
                "You shall make a grating for it of network of brass: and on the net you shall make four bronze rings in its four corners.",
                "You shall put it under the ledge around the altar beneath, that the net may reach halfway up the altar.",
                "You shall make poles for the altar, poles of acacia wood, and overlay them with brass.",
                "Its poles shall be put into the rings, and the poles shall be on the two sides of the altar, when carrying it.",
                "You shall make it with hollow planks. They shall make it as it has been shown you on the mountain.",
                "\"You shall make the court of the tabernacle: for the south side southward there shall be hangings for the court of fine twined linen one hundred cubits long for one side:",
                "and its pillars shall be twenty, and their sockets twenty, of brass; the hooks of the pillars and their fillets shall be of silver.",
                "Likewise for the north side in length there shall be hangings one hundred cubits long, and its pillars twenty, and their sockets twenty, of brass; the hooks of the pillars, and their fillets, of silver.",
                "For the breadth of the court on the west side shall be hangings of fifty cubits; their pillars ten, and their sockets ten.",
                "The breadth of the court on the east side eastward shall be fifty cubits.",
                "The hangings for the one side of the gate shall be fifteen cubits; their pillars three, and their sockets three.",
                "For the other side shall be hangings of fifteen cubits; their pillars three, and their sockets three.",
                "For the gate of the court shall be a screen of twenty cubits, of blue, and purple, and scarlet, and fine twined linen, the work of the embroiderer; their pillars four, and their sockets four.",
                "All the pillars of the court around shall be filleted with silver; their hooks of silver, and their sockets of brass.",
                "The length of the court shall be one hundred cubits, and the breadth fifty every where, and the height five cubits, of fine twined linen, and their sockets of brass.",
                "All the instruments of the tabernacle in all its service, and all its pins, and all the pins of the court, shall be of brass.",
                "\"You shall command the children of Israel, that they bring to you pure olive oil beaten for the light, to cause a lamp to burn continually.",
                "In the Tent of Meeting, outside the veil which is before the testimony, Aaron and his sons shall keep it in order from evening to morning before Yahweh: it shall be a statute forever throughout their generations on the behalf of the children of Israel."
            ],
            28: [
                "\"Bring Aaron your brother, and his sons with him, near to you from among the children of Israel, that he may minister to me in the priest\u2019s office, even Aaron, Nadab and Abihu, Eleazar and Ithamar, Aaron\u2019s sons.",
                "You shall make holy garments for Aaron your brother, for glory and for beauty.",
                "You shall speak to all who are wise-hearted, whom I have filled with the spirit of wisdom, that they make Aaron\u2019s garments to sanctify him, that he may minister to me in the priest\u2019s office.",
                "These are the garments which they shall make: a breastplate, and an ephod, and a robe, and a coat of checker work, a turban, and a sash: and they shall make holy garments for Aaron your brother, and his sons, that he may minister to me in the priest\u2019s office.",
                "They shall take the gold, and the blue, and the purple, and the scarlet, and the fine linen.",
                "\"They shall make the ephod of gold, of blue, and purple, scarlet, and fine twined linen, the work of the skillful workman.",
                "It shall have two shoulder straps joined to the two ends of it, that it may be joined together.",
                "The skillfully woven band, which is on it, that is on him, shall be like its work and of the same piece; of gold, of blue, and purple, and scarlet, and fine twined linen.",
                "You shall take two onyx stones, and engrave on them the names of the children of Israel:",
                "six of their names on the one stone, and the names of the six that remain on the other stone, in the order of their birth.",
                "With the work of an engraver in stone, like the engravings of a signet, you shall engrave the two stones, according to the names of the children of Israel: you shall make them to be enclosed in settings of gold.",
                "You shall put the two stones on the shoulder straps of the ephod, to be stones of memorial for the children of Israel: and Aaron shall bear their names before Yahweh on his two shoulders for a memorial.",
                "You shall make settings of gold,",
                "and two chains of pure gold; you shall make them like cords of braided work: and you shall put the braided chains on the settings.",
                "\"You shall make a breastplate of judgment, the work of the skillful workman; like the work of the ephod you shall make it; of gold, of blue, and purple, and scarlet, and fine twined linen, you shall make it.",
                "It shall be square and folded double; a span shall be its length of it, and a span its breadth.",
                "You shall set in it settings of stones, four rows of stones: a row of ruby, topaz, and beryl shall be the first row;",
                "and the second row a turquoise, a sapphire, and an emerald;",
                "and the third row a jacinth, an agate, and an amethyst;",
                "and the fourth row a chrysolite, an onyx, and a jasper: they shall be enclosed in gold in their settings.",
                "The stones shall be according to the names of the children of Israel, twelve, according to their names; like the engravings of a signet, everyone according to his name, they shall be for the twelve tribes.",
                "You shall make on the breastplate chains like cords, of braided work of pure gold.",
                "You shall make on the breastplate two rings of gold, and shall put the two rings on the two ends of the breastplate.",
                "You shall put the two braided chains of gold in the two rings at the ends of the breastplate.",
                "The other two ends of the two braided chains you shall put on the two settings, and put them on the shoulder straps of the ephod in its forepart.",
                "You shall make two rings of gold, and you shall put them on the two ends of the breastplate, on its edge, which is toward the side of the ephod inward.",
                "You shall make two rings of gold, and shall put them on the two shoulder straps of the ephod underneath, in its forepart, close by its coupling, above the skillfully woven band of the ephod.",
                "They shall bind the breastplate by its rings to the rings of the ephod with a lace of blue, that it may be on the skillfully woven band of the ephod, and that the breastplate may not swing out from the ephod.",
                "Aaron shall bear the names of the children of Israel in the breastplate of judgment on his heart, when he goes in to the holy place, for a memorial before Yahweh continually.",
                "You shall put in the breastplate of judgment the Urim and the Thummim; and they shall be on Aaron\u2019s heart, when he goes in before Yahweh: and Aaron shall bear the judgment of the children of Israel on his heart before Yahweh continually.",
                "\"You shall make the robe of the ephod all of blue.",
                "It shall have a hole for the head in its midst: it shall have a binding of woven work around its hole, as it were the hole of a coat of mail, that it not be torn.",
                "On its hem you shall make pomegranates of blue, and of purple, and of scarlet, around its hem; and bells of gold between and around them:",
                "a golden bell and a pomegranate, a golden bell and a pomegranate, around the hem of the robe.",
                "It shall be on Aaron to minister: and its sound shall be heard when he goes in to the holy place before Yahweh, and when he comes out, that he not die.",
                "\"You shall make a plate of pure gold, and engrave on it, like the engravings of a signet, \u2018HOLY TO YAHWEH.\u2019",
                "You shall put it on a lace of blue, and it shall be on the sash; on the front of the sash it shall be.",
                "It shall be on Aaron\u2019s forehead, and Aaron shall bear the iniquity of the holy things, which the children of Israel shall make holy in all their holy gifts; and it shall be always on his forehead, that they may be accepted before Yahweh.",
                "You shall weave the coat in checker work of fine linen, and you shall make a turban of fine linen, and you shall make a sash, the work of the embroiderer.",
                "\"You shall make coats for Aaron\u2019s sons, and you shall make sashes for them and you shall make headbands for them, for glory and for beauty.",
                "You shall put them on Aaron your brother, and on his sons with him, and shall anoint them, and consecrate them, and sanctify them, that they may minister to me in the priest\u2019s office.",
                "You shall make them linen breeches to cover the flesh of their nakedness; from the waist even to the thighs they shall reach:",
                "They shall be on Aaron, and on his sons, when they go in to the Tent of Meeting, or when they come near to the altar to minister in the holy place; that they don\u2019t bear iniquity, and die: it shall be a statute forever to him and to his descendants after him."
            ],
            29: [
                "\"This is the thing that you shall do to them to make them holy, to minister to me in the priest\u2019s office: take one young bull and two rams without blemish,",
                "unleavened bread, unleavened cakes mixed with oil, and unleavened wafers anointed with oil: you shall make them of fine wheat flour.",
                "You shall put them into one basket, and bring them in the basket, with the bull and the two rams.",
                "You shall bring Aaron and his sons to the door of the Tent of Meeting, and shall wash them with water.",
                "You shall take the garments, and put on Aaron the coat, the robe of the ephod, the ephod, and the breastplate, and clothe him with the skillfully woven band of the ephod;",
                "and you shall set the turban on his head, and put the holy crown on the turban.",
                "Then you shall take the anointing oil, and pour it on his head, and anoint him.",
                "You shall bring his sons, and put coats on them.",
                "You shall clothe them with belts, Aaron and his sons, and bind headbands on them: and they shall have the priesthood by a perpetual statute: and you shall consecrate Aaron and his sons.",
                "\"You shall bring the bull before the Tent of Meeting: and Aaron and his sons shall lay their hands on the head of the bull.",
                "You shall kill the bull before Yahweh, at the door of the Tent of Meeting.",
                "You shall take of the blood of the bull, and put it on the horns of the altar with your finger; and you shall pour out all the blood at the base of the altar.",
                "You shall take all the fat that covers the innards, the cover of the liver, the two kidneys, and the fat that is on them, and burn them on the altar.",
                "But the flesh of the bull, and its skin, and its dung, you shall burn with fire outside of the camp: it is a sin offering.",
                "\"You shall also take the one ram; and Aaron and his sons shall lay their hands on the head of the ram.",
                "You shall kill the ram, and you shall take its blood, and sprinkle it around on the altar.",
                "You shall cut the ram into its pieces, and wash its innards, and its legs, and put them with its pieces, and with its head.",
                "You shall burn the whole ram on the altar: it is a burnt offering to Yahweh; it is a pleasant aroma, an offering made by fire to Yahweh.",
                "\"You shall take the other ram; and Aaron and his sons shall lay their hands on the head of the ram.",
                "Then you shall kill the ram, and take some of its blood, and put it on the tip of the right ear of Aaron, and on the tip of the right ear of his sons, and on the thumb of their right hand, and on the big toe of their right foot, and sprinkle the blood around on the altar.",
                "You shall take of the blood that is on the altar, and of the anointing oil, and sprinkle it on Aaron, and on his garments, and on his sons, and on the garments of his sons with him: and he shall be made holy, and his garments, and his sons, and his sons\u2019 garments with him.",
                "Also you shall take some of the ram\u2019s fat, the fat tail, the fat that covers the innards, the cover of the liver, the two kidneys, the fat that is on them, and the right thigh (for it is a ram of consecration),",
                "and one loaf of bread, one cake of oiled bread, and one wafer out of the basket of unleavened bread that is before Yahweh.",
                "You shall put all of this in Aaron\u2019s hands, and in his sons\u2019 hands, and shall wave them for a wave offering before Yahweh.",
                "You shall take them from their hands, and burn them on the altar on the burnt offering, for a pleasant aroma before Yahweh: it is an offering made by fire to Yahweh.",
                "\"You shall take the breast of Aaron\u2019s ram of consecration, and wave it for a wave offering before Yahweh: and it shall be your portion.",
                "You shall sanctify the breast of the wave offering, and the thigh of the wave offering, which is waved, and which is heaved up, of the ram of consecration, even of that which is for Aaron, and of that which is for his sons:",
                "and it shall be for Aaron and his sons as their portion forever from the children of Israel; for it is a wave offering: and it shall be a wave offering from the children of Israel of the sacrifices of their peace offerings, even their wave offering to Yahweh.",
                "\"The holy garments of Aaron shall be for his sons after him, to be anointed in them, and to be consecrated in them.",
                "Seven days shall the son who is priest in his place put them on, when he comes into the Tent of Meeting to minister in the holy place.",
                "\"You shall take the ram of consecration, and boil its flesh in a holy place.",
                "Aaron and his sons shall eat the flesh of the ram, and the bread that is in the basket, at the door of the Tent of Meeting.",
                "They shall eat those things with which atonement was made, to consecrate and sanctify them: but a stranger shall not eat of it, because they are holy.",
                "If anything of the flesh of the consecration, or of the bread, remains to the morning, then you shall burn the remainder with fire: it shall not be eaten, because it is holy.",
                "\"You shall do so to Aaron, and to his sons, according to all that I have commanded you. You shall consecrate them seven days.",
                "Every day you shall offer the bull of sin offering for atonement: and you shall cleanse the altar, when you make atonement for it; and you shall anoint it, to sanctify it.",
                "Seven days you shall make atonement for the altar, and sanctify it: and the altar shall be most holy; whatever touches the altar shall be holy.",
                "\"Now this is that which you shall offer on the altar: two lambs a year old day by day continually.",
                "The one lamb you shall offer in the morning; and the other lamb you shall offer at evening:",
                "and with the one lamb a tenth part of an ephah of fine flour mixed with the fourth part of a hin of beaten oil, and the fourth part of a hin of wine for a drink offering.",
                "The other lamb you shall offer at evening, and shall do to it according to the meal offering of the morning, and according to its drink offering, for a pleasant aroma, an offering made by fire to Yahweh.",
                "It shall be a continual burnt offering throughout your generations at the door of the Tent of Meeting before Yahweh, where I will meet with you, to speak there to you.",
                "There I will meet with the children of Israel; and the place shall be sanctified by my glory.",
                "I will sanctify the Tent of Meeting and the altar: Aaron also and his sons I will sanctify, to minister to me in the priest\u2019s office.",
                "I will dwell among the children of Israel, and will be their God.",
                "They shall know that I am Yahweh their God, who brought them forth out of the land of Egypt, that I might dwell among them: I am Yahweh their God."
            ],
            30: [
                "\"You shall make an altar to burn incense on. You shall make it of acacia wood.",
                "Its length shall be a cubit, and its breadth a cubit. It shall be square, and its height shall be two cubits. Its horns shall be of one piece with it.",
                "You shall overlay it with pure gold, its top, its sides around it, and its horns; and you shall make a gold molding around it.",
                "You shall make two golden rings for it under its molding; on its two ribs, on its two sides you shall make them; and they shall be for places for poles with which to bear it.",
                "You shall make the poles of acacia wood, and overlay them with gold.",
                "You shall put it before the veil that is by the ark of the testimony, before the mercy seat that is over the testimony, where I will meet with you.",
                "Aaron shall burn incense of sweet spices on it every morning. When he tends the lamps, he shall burn it.",
                "When Aaron lights the lamps at evening, he shall burn it, a perpetual incense before Yahweh throughout your generations.",
                "You shall offer no strange incense on it, nor burnt offering, nor meal offering; and you shall pour no drink offering on it.",
                "Aaron shall make atonement on its horns once in the year; with the blood of the sin offering of atonement once in the year he shall make atonement for it throughout your generations. It is most holy to Yahweh.\"",
                "Yahweh spoke to Moses, saying,",
                "\"When you take a census of the children of Israel, according to those who are numbered among them, then each man shall give a ransom for his soul to Yahweh, when you number them; that there be no plague among them when you number them.",
                "They shall give this, everyone who passes over to those who are numbered, half a shekel after the shekel of the sanctuary; (the shekel is twenty gerahs;) half a shekel for an offering to Yahweh.",
                "Everyone who passes over to those who are numbered, from twenty years old and upward, shall give the offering to Yahweh.",
                "The rich shall not give more, and the poor shall not give less, than the half shekel, when they give the offering of Yahweh, to make atonement for your souls.",
                "You shall take the atonement money from the children of Israel, and shall appoint it for the service of the Tent of Meeting; that it may be a memorial for the children of Israel before Yahweh, to make atonement for your souls.\"",
                "Yahweh spoke to Moses, saying,",
                "\"You shall also make a basin of brass, and its base of brass, in which to wash. You shall put it between the Tent of Meeting and the altar, and you shall put water in it.",
                "Aaron and his sons shall wash their hands and their feet in it.",
                "When they go into the Tent of Meeting, they shall wash with water, that they not die; or when they come near to the altar to minister, to burn an offering made by fire to Yahweh.",
                "So they shall wash their hands and their feet, that they not die: and it shall be a statute forever to them, even to him and to his descendants throughout their generations.\"",
                "Moreover Yahweh spoke to Moses, saying,",
                "\"Also take fine spices: of liquid myrrh, five hundred shekels; and of fragrant cinnamon half as much, even two hundred and fifty; and of fragrant cane, two hundred and fifty;",
                "and of cassia five hundred, after the shekel of the sanctuary; and a hin of olive oil.",
                "You shall make it a holy anointing oil, a perfume compounded after the art of the perfumer: it shall be a holy anointing oil.",
                "You shall use it to anoint the Tent of Meeting, the ark of the testimony,",
                "the table and all its articles, the lampstand and its accessories, the altar of incense,",
                "the altar of burnt offering with all its utensils, and the basin with its base.",
                "You shall sanctify them, that they may be most holy. Whatever touches them shall be holy.",
                "You shall anoint Aaron and his sons, and sanctify them, that they may minister to me in the priest\u2019s office.",
                "You shall speak to the children of Israel, saying, \u2018This shall be a holy anointing oil to me throughout your generations.",
                "It shall not be poured on man\u2019s flesh, neither shall you make any like it, according to its composition: it is holy. It shall be holy to you.",
                "Whoever compounds any like it, or whoever puts any of it on a stranger, he shall be cut off from his people.\u2019\"",
                "Yahweh said to Moses, \"Take to yourself sweet spices, gum resin, and onycha, and galbanum; sweet spices with pure frankincense: there shall be an equal weight of each;",
                "and you shall make incense of it, a perfume after the art of the perfumer, seasoned with salt, pure and holy:",
                "and you shall beat some of it very small, and put some of it before the testimony in the Tent of Meeting, where I will meet with you. It shall be to you most holy.",
                "The incense which you shall make, according to its composition you shall not make for yourselves: it shall be to you holy for Yahweh.",
                "Whoever shall make any like that, to smell of it, he shall be cut off from his people.\""
            ],
            31: [
                "Yahweh spoke to Moses, saying,",
                "\"Behold, I have called by name Bezalel the son of Uri, the son of Hur, of the tribe of Judah:",
                "and I have filled him with the Spirit of God, in wisdom, and in understanding, and in knowledge, and in all kinds of workmanship,",
                "to devise skillful works, to work in gold, and in silver, and in brass,",
                "and in cutting of stones for setting, and in carving of wood, to work in all kinds of workmanship.",
                "I, behold, I have appointed with him Oholiab, the son of Ahisamach, of the tribe of Dan; and in the heart of all who are wise-hearted I have put wisdom, that they may make all that I have commanded you:",
                "the Tent of Meeting, the ark of the testimony, the mercy seat that is on it, all the furniture of the Tent,",
                "the table and its vessels, the pure lampstand with all its vessels, the altar of incense,",
                "the altar of burnt offering with all its vessels, the basin and its base,",
                "the finely worked garments\u2014the holy garments for Aaron the priest\u2014the garments of his sons to minister in the priest\u2019s office,",
                "the anointing oil, and the incense of sweet spices for the holy place: according to all that I have commanded you they shall do.\"",
                "Yahweh spoke to Moses, saying,",
                "\"Speak also to the children of Israel, saying, \u2018Most certainly you shall keep my Sabbaths: for it is a sign between me and you throughout your generations; that you may know that I am Yahweh who sanctifies you.",
                "You shall keep the Sabbath therefore; for it is holy to you. Everyone who profanes it shall surely be put to death; for whoever does any work therein, that soul shall be cut off from among his people.",
                "Six days shall work be done, but on the seventh day is a Sabbath of solemn rest, holy to Yahweh. Whoever does any work on the Sabbath day shall surely be put to death.",
                "Therefore the children of Israel shall keep the Sabbath, to observe the Sabbath throughout their generations, for a perpetual covenant.",
                "It is a sign between me and the children of Israel forever; for in six days Yahweh made heaven and earth, and on the seventh day he rested, and was refreshed.\u2019\"",
                "He gave to Moses, when he finished speaking with him on Mount Sinai, the two tablets of the testimony, stone tablets, written with God\u2019s finger."
            ],
            32: [
                "When the people saw that Moses delayed to come down from the mountain, the people gathered themselves together to Aaron, and said to him, \"Come, make us gods, which shall go before us; for as for this Moses, the man who brought us up out of the land of Egypt, we don\u2019t know what has become of him.\"",
                "Aaron said to them, \"Take off the golden rings, which are in the ears of your wives, of your sons, and of your daughters, and bring them to me.\"",
                "All the people took off the golden rings which were in their ears, and brought them to Aaron.",
                "He received what they handed him, and fashioned it with an engraving tool, and made it a molten calf; and they said, \"These are your gods, Israel, which brought you up out of the land of Egypt.\"",
                "When Aaron saw this, he built an altar before it; and Aaron made a proclamation, and said, \"Tomorrow shall be a feast to Yahweh.\"",
                "They rose up early on the next day, and offered burnt offerings, and brought peace offerings; and the people sat down to eat and to drink, and rose up to play.",
                "Yahweh spoke to Moses, \"Go, get down; for your people, who you brought up out of the land of Egypt, have corrupted themselves!",
                "They have turned aside quickly out of the way which I commanded them. They have made themselves a molten calf, and have worshiped it, and have sacrificed to it, and said, \u2018These are your gods, Israel, which brought you up out of the land of Egypt.\u2019\"",
                "Yahweh said to Moses, \"I have seen these people, and behold, they are a stiff-necked people.",
                "Now therefore leave me alone, that my wrath may burn hot against them, and that I may consume them; and I will make of you a great nation.\"",
                "Moses begged Yahweh his God, and said, \"Yahweh, why does your wrath burn hot against your people, that you have brought out of the land of Egypt with great power and with a mighty hand?",
                "Why should the Egyptians speak, saying, \u2018He brought them forth for evil, to kill them in the mountains, and to consume them from the surface of the earth?\u2019 Turn from your fierce wrath, and repent of this evil against your people.",
                "Remember Abraham, Isaac, and Israel, your servants, to whom you swore by your own self, and said to them, \u2018I will multiply your seed as the stars of the sky, and all this land that I have spoken of I will give to your seed, and they shall inherit it forever.\u2019\"",
                "Yahweh repented of the evil which he said he would do to his people.",
                "Moses turned, and went down from the mountain, with the two tablets of the testimony in his hand; tablets that were written on both their sides; on the one side and on the other they were written.",
                "The tablets were the work of God, and the writing was the writing of God, engraved on the tables.",
                "When Joshua heard the noise of the people as they shouted, he said to Moses, \"There is the noise of war in the camp.\"",
                "He said, \"It isn\u2019t the voice of those who shout for victory, neither is it the voice of those who cry for being overcome; but the noise of those who sing that I hear.\"",
                "It happened, as soon as he came near to the camp, that he saw the calf and the dancing: and Moses\u2019 anger grew hot, and he threw the tablets out of his hands, and broke them beneath the mountain.",
                "He took the calf which they had made, and burnt it with fire, ground it to powder, and scattered it on the water, and made the children of Israel drink of it.",
                "Moses said to Aaron, \"What did these people do to you, that you have brought a great sin on them?\"",
                "Aaron said, \"Don\u2019t let the anger of my lord grow hot. You know the people, that they are set on evil.",
                "For they said to me, \u2018Make us gods, which shall go before us; for as for this Moses, the man who brought us up out of the land of Egypt, we don\u2019t know what has become of him.\u2019",
                "I said to them, \u2018Whoever has any gold, let them take it off:\u2019 so they gave it to me; and I threw it into the fire, and out came this calf.\"",
                "When Moses saw that the people had broken loose, (for Aaron had let them loose for a derision among their enemies),",
                "then Moses stood in the gate of the camp, and said, \"Whoever is on Yahweh\u2019s side, come to me!\" All the sons of Levi gathered themselves together to him.",
                "He said to them, \"Thus says Yahweh, the God of Israel, \u2018Every man put his sword on his thigh, and go back and forth from gate to gate throughout the camp, and every man kill his brother, and every man his companion, and every man his neighbor.\u2019\"",
                "The sons of Levi did according to the word of Moses: and there fell of the people that day about three thousand men.",
                "Moses said, \"Consecrate yourselves today to Yahweh, yes, every man against his son, and against his brother; that he may bestow on you a blessing this day.\"",
                "It happened on the next day, that Moses said to the people, \"You have sinned a great sin. Now I will go up to Yahweh. Perhaps I shall make atonement for your sin.\"",
                "Moses returned to Yahweh, and said, \"Oh, this people have sinned a great sin, and have made themselves gods of gold.",
                "Yet now, if you will, forgive their sin\u2014and if not, please blot me out of your book which you have written.\"",
                "Yahweh said to Moses, \"Whoever has sinned against me, him will I blot out of my book.",
                "Now go, lead the people to the place of which I have spoken to you. Behold, my angel shall go before you. Nevertheless in the day when I punish, I will punish them for their sin.\"",
                "Yahweh struck the people, because they made the calf, which Aaron made."
            ],
            33: [
                "Yahweh spoke to Moses, \"Depart, go up from here, you and the people that you have brought up out of the land of Egypt, to the land of which I swore to Abraham, to Isaac, and to Jacob, saying, \u2018I will give it to your seed.\u2019",
                "I will send an angel before you; and I will drive out the Canaanite, the Amorite, and the Hittite, and the Perizzite, the Hivite, and the Jebusite:",
                "to a land flowing with milk and honey: for I will not go up in the midst of you, for you are a stiff-necked people, lest I consume you in the way.\"",
                "When the people heard this evil news, they mourned: and no one put on his jewelry.",
                "Yahweh said to Moses, \"Tell the children of Israel, \u2018You are a stiff-necked people. If I were to go up into your midst for one moment, I would consume you. Therefore now take off your jewelry from you, that I may know what to do to you.\u2019\"",
                "The children of Israel stripped themselves of their jewelry from Mount Horeb onward.",
                "Now Moses used to take the tent and to pitch it outside the camp, far away from the camp, and he called it \"The Tent of Meeting.\" It happened that everyone who sought Yahweh went out to the Tent of Meeting, which was outside the camp.",
                "It happened that when Moses went out to the Tent, that all the people rose up, and stood, everyone at their tent door, and watched Moses, until he had gone into the Tent.",
                "It happened, when Moses entered into the Tent, that the pillar of cloud descended, stood at the door of the Tent, and spoke with Moses.",
                "All the people saw the pillar of cloud stand at the door of the Tent, and all the people rose up and worshiped, everyone at their tent door.",
                "Yahweh spoke to Moses face to face, as a man speaks to his friend. He turned again into the camp, but his servant Joshua, the son of Nun, a young man, didn\u2019t depart out of the Tent.",
                "Moses said to Yahweh, \"Behold, you tell me, \u2018Bring up this people:\u2019 and you haven\u2019t let me know whom you will send with me. Yet you have said, \u2018I know you by name, and you have also found favor in my sight.\u2019",
                "Now therefore, if I have found favor in your sight, please show me now your ways, that I may know you, so that I may find favor in your sight: and consider that this nation is your people.\"",
                "He said, \"My presence will go with you, and I will give you rest.\"",
                "He said to him, \"If your presence doesn\u2019t go with me, don\u2019t carry us up from here.",
                "For how would people know that I have found favor in your sight, I and your people? Isn\u2019t it in that you go with us, so that we are separated, I and your people, from all the people who are on the surface of the earth?\"",
                "Yahweh said to Moses, \"I will do this thing also that you have spoken; for you have found favor in my sight, and I know you by name.\"",
                "He said, \"Please show me your glory.\"",
                "He said, \"I will make all my goodness pass before you, and will proclaim the name of Yahweh before you. I will be gracious to whom I will be gracious, and will show mercy on whom I will show mercy.\"",
                "He said, \"You cannot see my face, for man may not see me and live.\"",
                "Yahweh also said, \"Behold, there is a place by me, and you shall stand on the rock.",
                "It will happen, while my glory passes by, that I will put you in a cleft of the rock, and will cover you with my hand until I have passed by;",
                "then I will take away my hand, and you will see my back; but my face shall not be seen.\""
            ],
            34: [
                "Yahweh said to Moses, \"Chisel two stone tablets like the first: and I will write on the tablets the words that were on the first tablets, which you broke.",
                "Be ready by the morning, and come up in the morning to Mount Sinai, and present yourself there to me on the top of the mountain.",
                "No one shall come up with you; neither let anyone be seen throughout all the mountain; neither let the flocks nor herds feed before that mountain.\"",
                "He chiseled two tablets of stone like the first; and Moses rose up early in the morning, and went up to Mount Sinai, as Yahweh had commanded him, and took in his hand two stone tablets.",
                "Yahweh descended in the cloud, and stood with him there, and proclaimed the name of Yahweh.",
                "Yahweh passed by before him, and proclaimed, \"Yahweh! Yahweh, a merciful and gracious God, slow to anger, and abundant in loving kindness and truth,",
                "keeping loving kindness for thousands, forgiving iniquity and disobedience and sin; and that will by no means clear the guilty, visiting the iniquity of the fathers on the children, and on the children\u2019s children, on the third and on the fourth generation.\"",
                "Moses hurried and bowed his head toward the earth, and worshiped.",
                "He said, \"If now I have found favor in your sight, Lord, please let the Lord go in the midst of us; although this is a stiff-necked people; pardon our iniquity and our sin, and take us for your inheritance.\"",
                "He said, \"Behold, I make a covenant: before all your people I will do marvels, such as have not been worked in all the earth, nor in any nation; and all the people among which you are shall see the work of Yahweh; for it is an awesome thing that I do with you.",
                "Observe that which I command you this day. Behold, I drive out before you the Amorite, the Canaanite, the Hittite, the Perizzite, the Hivite, and the Jebusite.",
                "Be careful, lest you make a covenant with the inhabitants of the land where you are going, lest it be for a snare in the midst of you:",
                "but you shall break down their altars, and dash in pieces their pillars, and you shall cut down their Asherim;",
                "for you shall worship no other god: for Yahweh, whose name is Jealous, is a jealous God.",
                "\"Don\u2019t make a covenant with the inhabitants of the land, lest they play the prostitute after their gods, and sacrifice to their gods, and one call you and you eat of his sacrifice;",
                "and you take of their daughters to your sons, and their daughters play the prostitute after their gods, and make your sons play the prostitute after their gods.",
                "\"You shall make no cast idols for yourselves.",
                "\"You shall keep the feast of unleavened bread. Seven days you shall eat unleavened bread, as I commanded you, at the time appointed in the month Abib; for in the month Abib you came out from Egypt.",
                "\"All that opens the womb is mine; and all your livestock that is male, the firstborn of cow and sheep.",
                "The firstborn of a donkey you shall redeem with a lamb: and if you will not redeem it, then you shall break its neck. All the firstborn of your sons you shall redeem. No one shall appear before me empty.",
                "\"Six days you shall work, but on the seventh day you shall rest: in plowing time and in harvest you shall rest.",
                "\"You shall observe the feast of weeks with the first fruits of wheat harvest, and the feast of harvest at the year\u2019s end.",
                "Three times in the year all your males shall appear before the Lord Yahweh, the God of Israel.",
                "For I will drive out nations before you and enlarge your borders; neither shall any man desire your land when you go up to appear before Yahweh, your God, three times in the year.",
                "\"You shall not offer the blood of my sacrifice with leavened bread; neither shall the sacrifice of the feast of the Passover be left to the morning.",
                "\"You shall bring the first of the first fruits of your ground to the house of Yahweh your God. \"You shall not boil a young goat in its mother\u2019s milk.\"",
                "Yahweh said to Moses, \"Write you these words: for in accordance with these words I have made a covenant with you and with Israel.\"",
                "He was there with Yahweh forty days and forty nights; he neither ate bread, nor drank water. He wrote on the tablets the words of the covenant, the ten commandments.",
                "It happened, when Moses came down from Mount Sinai with the two tablets of the testimony in Moses\u2019 hand, when he came down from the mountain, that Moses didn\u2019t know that the skin of his face shone by reason of his speaking with him.",
                "When Aaron and all the children of Israel saw Moses, behold, the skin of his face shone; and they were afraid to come near him.",
                "Moses called to them, and Aaron and all the rulers of the congregation returned to him; and Moses spoke to them.",
                "Afterward all the children of Israel came near, and he gave them all of the commandments that Yahweh had spoken with him on Mount Sinai.",
                "When Moses was done speaking with them, he put a veil on his face.",
                "But when Moses went in before Yahweh to speak with him, he took the veil off, until he came out; and he came out, and spoke to the children of Israel that which he was commanded.",
                "The children of Israel saw Moses\u2019 face, that the skin of Moses\u2019 face shone: and Moses put the veil on his face again, until he went in to speak with him."
            ],
            35: [
                "Moses assembled all the congregation of the children of Israel, and said to them, \"These are the words which Yahweh has commanded, that you should do them.",
                "\u2018Six days shall work be done, but on the seventh day there shall be a holy day for you, a Sabbath of solemn rest to Yahweh: whoever does any work in it shall be put to death.",
                "You shall kindle no fire throughout your habitations on the Sabbath day.\u2019\"",
                "Moses spoke to all the congregation of the children of Israel, saying, \"This is the thing which Yahweh commanded, saying,",
                "\u2018Take from among you an offering to Yahweh. Whoever is of a willing heart, let him bring it, Yahweh\u2019s offering: gold, silver, brass,",
                "blue, purple, scarlet, fine linen, goats\u2019 hair,",
                "rams\u2019 skins dyed red, sea cow hides, acacia wood,",
                "oil for the light, spices for the anointing oil and for the sweet incense,",
                "onyx stones, and stones to be set for the ephod and for the breastplate.",
                "\"\u2018Let every wise-hearted man among you come, and make all that Yahweh has commanded:",
                "the tabernacle, its outer covering, its roof, its clasps, its boards, its bars, its pillars, and its sockets;",
                "the ark, and its poles, the mercy seat, the veil of the screen;",
                "the table with its poles and all its vessels, and the show bread;",
                "the lampstand also for the light, with its vessels, its lamps, and the oil for the light;",
                "and the altar of incense with its poles, the anointing oil, the sweet incense, the screen for the door, at the door of the tabernacle;",
                "the altar of burnt offering, with its grating of brass, it poles, and all its vessels, the basin and its base;",
                "the hangings of the court, its pillars, their sockets, and the screen for the gate of the court;",
                "the pins of the tabernacle, the pins of the court, and their cords;",
                "the finely worked garments, for ministering in the holy place, the holy garments for Aaron the priest, and the garments of his sons, to minister in the priest\u2019s office.\u2019\"",
                "All the congregation of the children of Israel departed from the presence of Moses.",
                "They came, everyone whose heart stirred him up, and everyone whom his spirit made willing, and brought Yahweh\u2019s offering, for the work of the Tent of Meeting, and for all of its service, and for the holy garments.",
                "They came, both men and women, as many as were willing-hearted, and brought brooches, earrings, signet rings, and armlets, all jewels of gold; even every man who offered an offering of gold to Yahweh.",
                "Everyone, with whom was found blue, purple, scarlet, fine linen, goats\u2019 hair, rams\u2019 skins dyed red, and sea cow hides, brought them.",
                "Everyone who offered an offering of silver and brass brought Yahweh\u2019s offering; and everyone, with whom was found acacia wood for any work of the service, brought it.",
                "All the women who were wise-hearted spun with their hands, and brought that which they had spun, the blue, the purple, the scarlet, and the fine linen.",
                "All the women whose heart stirred them up in wisdom spun the goats\u2019 hair.",
                "The rulers brought the onyx stones, and the stones to be set, for the ephod and for the breastplate;",
                "and the spice, and the oil for the light, for the anointing oil, and for the sweet incense.",
                "The children of Israel brought a freewill offering to Yahweh; every man and woman, whose heart made them willing to bring for all the work, which Yahweh had commanded to be made by Moses.",
                "Moses said to the children of Israel, \"Behold, Yahweh has called by name Bezalel the son of Uri, the son of Hur, of the tribe of Judah.",
                "He has filled him with the Spirit of God, in wisdom, in understanding, in knowledge, and in all kinds of workmanship;",
                "and to make skillful works, to work in gold, in silver, in brass,",
                "in cutting of stones for setting, and in carving of wood, to work in all kinds of skillful workmanship.",
                "He has put in his heart that he may teach, both he, and Oholiab, the son of Ahisamach, of the tribe of Dan.",
                "He has filled them with wisdom of heart, to work all kinds of workmanship, of the engraver, of the skillful workman, and of the embroiderer, in blue, in purple, in scarlet, and in fine linen, and of the weaver, even of those who do any workmanship, and of those who make skillful works."
            ],
            36: [
                "\"Bezalel and Oholiab shall work with every wise-hearted man, in whom Yahweh has put wisdom and understanding to know how to work all the work for the service of the sanctuary, according to all that Yahweh has commanded.\"",
                "Moses called Bezalel and Oholiab, and every wise-hearted man, in whose heart Yahweh had put wisdom, even everyone whose heart stirred him up to come to the work to do it:",
                "and they received from Moses all the offering which the children of Israel had brought for the work of the service of the sanctuary, with which to make it. They brought yet to him freewill offerings every morning.",
                "All the wise men, who performed all the work of the sanctuary, each came from his work which they did.",
                "They spoke to Moses, saying, \"The people bring much more than enough for the service of the work which Yahweh commanded to make.\"",
                "Moses gave commandment, and they caused it to be proclaimed throughout the camp, saying, \"Let neither man nor woman make anything else for the offering for the sanctuary.\" So the people were restrained from bringing.",
                "For the stuff they had was sufficient for all the work to make it, and too much.",
                "All the wise-hearted men among those who did the work made the tabernacle with ten curtains; of fine twined linen, blue, purple, and scarlet, with cherubim, the work of the skillful workman, they made them.",
                "The length of each curtain was twenty-eight cubits, and the breadth of each curtain four cubits. All the curtains had one measure.",
                "He coupled five curtains to one another, and the other five curtains he coupled one to another.",
                "He made loops of blue on the edge of the one curtain from the edge in the coupling. Likewise he made in the edge of the curtain that was outmost in the second coupling.",
                "He made fifty loops in the one curtain, and he made fifty loops in the edge of the curtain that was in the second coupling. The loops were opposite one to another.",
                "He made fifty clasps of gold, and coupled the curtains one to another with the clasps: so the tabernacle was a unit.",
                "He made curtains of goats\u2019 hair for a covering over the tabernacle. He made them eleven curtains.",
                "The length of each curtain was thirty cubits, and four cubits the breadth of each curtain. The eleven curtains had one measure.",
                "He coupled five curtains by themselves, and six curtains by themselves.",
                "He made fifty loops on the edge of the curtain that was outmost in the coupling, and he made fifty loops on the edge of the curtain which was outmost in the second coupling.",
                "He made fifty clasps of brass to couple the tent together, that it might be a unit.",
                "He made a covering for the tent of rams\u2019 skins dyed red, and a covering of sea cow hides above.",
                "He made the boards for the tabernacle of acacia wood, standing up.",
                "Ten cubits was the length of a board, and a cubit and a half the breadth of each board.",
                "Each board had two tenons, joined one to another. He made all the boards of the tabernacle this way.",
                "He made the boards for the tabernacle: twenty boards for the south side southward.",
                "He made forty sockets of silver under the twenty boards; two sockets under one board for its two tenons, and two sockets under another board for its two tenons.",
                "For the second side of the tabernacle, on the north side, he made twenty boards,",
                "and their forty sockets of silver; two sockets under one board, and two sockets under another board.",
                "For the far part of the tabernacle westward he made six boards.",
                "He made two boards for the corners of the tabernacle in the far part.",
                "They were double beneath, and in the same way they were all the way to its top to one ring. He did this to both of them in the two corners.",
                "There were eight boards, and their sockets of silver, sixteen sockets; under every board two sockets.",
                "He made bars of acacia wood; five for the boards of the one side of the tabernacle,",
                "and five bars for the boards of the other side of the tabernacle, and five bars for the boards of the tabernacle for the hinder part westward.",
                "He made the middle bar to pass through in the midst of the boards from the one end to the other.",
                "He overlaid the boards with gold, and made their rings of gold for places for the bars, and overlaid the bars with gold.",
                "He made the veil of blue, purple, scarlet, and fine twined linen: with cherubim. He made it the work of a skillful workman.",
                "He made four pillars of acacia for it, and overlaid them with gold. Their hooks were of gold. He cast four sockets of silver for them.",
                "He made a screen for the door of the tent, of blue, purple, scarlet, and fine twined linen, the work of an embroiderer;",
                "and the five pillars of it with their hooks. He overlaid their capitals and their fillets with gold, and their five sockets were of brass."
            ],
            37: [
                "Bezalel made the ark of acacia wood. Its length was two and a half cubits, and its breadth a cubit and a half, and a cubit and a half its height.",
                "He overlaid it with pure gold inside and outside, and made a molding of gold for it around it.",
                "He cast four rings of gold for it, in its four feet; even two rings on its one side, and two rings on its other side.",
                "He made poles of acacia wood, and overlaid them with gold.",
                "He put the poles into the rings on the sides of the ark, to bear the ark.",
                "He made a mercy seat of pure gold. Its length was two and a half cubits, and a cubit and a half its breadth.",
                "He made two cherubim of gold. He made them of beaten work, at the two ends of the mercy seat;",
                "one cherub at the one end, and one cherub at the other end. He made the cherubim of one piece with the mercy seat at its two ends.",
                "The cherubim spread out their wings on high, covering the mercy seat with their wings, with their faces toward one another. The faces of the cherubim were toward the mercy seat.",
                "He made the table of acacia wood. Its length was two cubits, and its breadth was a cubit, and its height was a cubit and a half.",
                "He overlaid it with pure gold, and made a gold molding around it.",
                "He made a border of a handbreadth around it, and made a golden molding on its border around it.",
                "He cast four rings of gold for it, and put the rings in the four corners that were on its four feet.",
                "The rings were close by the border, the places for the poles to carry the table.",
                "He made the poles of acacia wood, and overlaid them with gold, to carry the table.",
                "He made the vessels which were on the table, its dishes, its spoons, its bowls, and its pitchers with which to pour out, of pure gold.",
                "He made the lampstand of pure gold. He made the lampstand of beaten work. Its base, its shaft, its cups, its buds, and its flowers were of one piece with it.",
                "There were six branches going out of its sides: three branches of the lampstand out of its one side, and three branches of the lampstand out of its other side:",
                "three cups made like almond blossoms in one branch, a bud and a flower, and three cups made like almond blossoms in the other branch, a bud and a flower: so for the six branches going out of the lampstand.",
                "In the lampstand were four cups made like almond blossoms, its buds and its flowers;",
                "and a bud under two branches of one piece with it, and a bud under two branches of one piece with it, and a bud under two branches of one piece with it, for the six branches going out of it.",
                "Their buds and their branches were of one piece with it. The whole thing was one beaten work of pure gold.",
                "He made its seven lamps, and its snuffers, and its snuff dishes, of pure gold.",
                "He made it of a talent of pure gold, with all its vessels.",
                "He made the altar of incense of acacia wood. It was square: its length was a cubit, and its breadth a cubit. Its height was two cubits. Its horns were of one piece with it.",
                "He overlaid it with pure gold, its top, its sides around it, and its horns. He made a gold molding around it.",
                "He made two golden rings for it under its molding crown, on its two ribs, on its two sides, for places for poles with which to carry it.",
                "He made the poles of acacia wood, and overlaid them with gold.",
                "He made the holy anointing oil and the pure incense of sweet spices, after the art of the perfumer."
            ],
            38: [
                "He made the altar of burnt offering of acacia wood. It was square. Its length was five cubits, its breadth was five cubits, and its height was three cubits.",
                "He made its horns on its four corners. Its horns were of one piece with it, and he overlaid it with brass.",
                "He made all the vessels of the altar, the pots, the shovels, the basins, the forks, and the fire pans. He made all its vessels of brass.",
                "He made for the altar a grating of a network of brass, under the ledge around it beneath, reaching halfway up.",
                "He cast four rings for the four ends of brass grating, to be places for the poles.",
                "He made the poles of acacia wood, and overlaid them with brass.",
                "He put the poles into the rings on the sides of the altar, with which to carry it. He made it hollow with planks.",
                "He made the basin of brass, and its base of brass, out of the mirrors of the ministering women who ministered at the door of the Tent of Meeting.",
                "He made the court: for the south side southward the hangings of the court were of fine twined linen, one hundred cubits;",
                "their pillars were twenty, and their sockets twenty, of brass; the hooks of the pillars and their fillets were of silver.",
                "For the north side one hundred cubits, their pillars twenty, and their sockets twenty, of brass; the hooks of the pillars, and their fillets, of silver.",
                "For the west side were hangings of fifty cubits, their pillars ten, and their sockets ten; the hooks of the pillars, and their fillets, of silver.",
                "For the east side eastward fifty cubits.",
                "The hangings for the one side were fifteen cubits; their pillars three, and their sockets three;",
                "and so for the other side: on this hand and that hand by the gate of the court were hangings of fifteen cubits; their pillars three, and their sockets three.",
                "All the hangings around the court were of fine twined linen.",
                "The sockets for the pillars were of brass. The hooks of the pillars and their fillets were of silver; and the overlaying of their capitals, of silver; and all the pillars of the court were filleted with silver.",
                "The screen for the gate of the court was the work of the embroiderer, of blue, purple, scarlet, and fine twined linen. Twenty cubits was the length, and the height in the breadth was five cubits, like to the hangings of the court.",
                "Their pillars were four, and their sockets four, of brass; their hooks of silver, and the overlaying of their capitals, and their fillets, of silver.",
                "All the pins of the tabernacle, and around the court, were of brass.",
                "This is the amount of material used for the tabernacle, even the Tabernacle of the Testimony, as they were counted, according to the commandment of Moses, for the service of the Levites, by the hand of Ithamar, the son of Aaron the priest.",
                "Bezalel the son of Uri, the son of Hur, of the tribe of Judah, made all that Yahweh commanded Moses.",
                "With him was Oholiab, the son of Ahisamach, of the tribe of Dan, an engraver, and a skillful workman, and an embroiderer in blue, in purple, in scarlet, and in fine linen.",
                "All the gold that was used for the work in all the work of the sanctuary, even the gold of the offering, was twenty-nine talents, and seven hundred thirty shekels, after the shekel of the sanctuary.",
                "The silver of those who were numbered of the congregation was one hundred talents, and one thousand seven hundred seventy-five shekels, after the shekel of the sanctuary:",
                "a beka a head, that is, half a shekel, after the shekel of the sanctuary, for everyone who passed over to those who were numbered, from twenty years old and upward, for six hundred three thousand five hundred fifty men.",
                "The one hundred talents of silver were for casting the sockets of the sanctuary, and the sockets of the veil; one hundred sockets for the one hundred talents, a talent for a socket.",
                "Of the one thousand seven hundred seventy-five shekels he made hooks for the pillars, overlaid their capitals, and made fillets for them.",
                "The brass of the offering was seventy talents, and two thousand four hundred shekels.",
                "With this he made the sockets to the door of the Tent of Meeting, the bronze altar, the bronze grating for it, all the vessels of the altar,",
                "the sockets around the court, the sockets of the gate of the court, all the pins of the tabernacle, and all the pins around the court."
            ],
            39: [
                "Of the blue, purple, and scarlet, they made finely worked garments, for ministering in the holy place, and made the holy garments for Aaron; as Yahweh commanded Moses.",
                "He made the ephod of gold, blue, purple, scarlet, and fine twined linen.",
                "They beat the gold into thin plates, and cut it into wires, to work it in the blue, in the purple, in the scarlet, and in the fine linen, the work of the skillful workman.",
                "They made shoulder straps for it, joined together. At the two ends it was joined together.",
                "The skillfully woven band that was on it, with which to fasten it on, was of the same piece, like its work; of gold, of blue, purple, scarlet, and fine twined linen; as Yahweh commanded Moses.",
                "They worked the onyx stones, enclosed in settings of gold, engraved with the engravings of a signet, according to the names of the children of Israel.",
                "He put them on the shoulder straps of the ephod, to be stones of memorial for the children of Israel, as Yahweh commanded Moses.",
                "He made the breastplate, the work of a skillful workman, like the work of the ephod; of gold, of blue, purple, scarlet, and fine twined linen.",
                "It was square. They made the breastplate double. Its length was a span, and its breadth a span, being double.",
                "They set in it four rows of stones. A row of ruby, topaz, and beryl was the first row;",
                "and the second row, a turquoise, a sapphire, and an emerald;",
                "and the third row, a jacinth, an agate, and an amethyst;",
                "and the fourth row, a chrysolite, an onyx, and a jasper. They were enclosed in gold settings.",
                "The stones were according to the names of the children of Israel, twelve, according to their names; like the engravings of a signet, everyone according to his name, for the twelve tribes.",
                "They made on the breastplate chains like cords, of braided work of pure gold.",
                "They made two settings of gold, and two gold rings, and put the two rings on the two ends of the breastplate.",
                "They put the two braided chains of gold in the two rings at the ends of the breastplate.",
                "The other two ends of the two braided chains they put on the two settings, and put them on the shoulder straps of the ephod, in its front.",
                "They made two rings of gold, and put them on the two ends of the breastplate, on its edge, which was toward the side of the ephod inward.",
                "They made two rings of gold, and put them on the two shoulder straps of the ephod underneath, in its front, close by its coupling, above the skillfully woven band of the ephod.",
                "They bound the breastplate by its rings to the rings of the ephod with a lace of blue, that it might be on the skillfully woven band of the ephod, and that the breastplate might not come loose from the ephod, as Yahweh commanded Moses.",
                "He made the robe of the ephod of woven work, all of blue.",
                "The opening of the robe in its midst was like the opening of a coat of mail, with a binding around its opening, that it should not be torn.",
                "They made on the skirts of the robe pomegranates of blue, purple, scarlet, and twined linen.",
                "They made bells of pure gold, and put the bells between the pomegranates around the skirts of the robe, between the pomegranates;",
                "a bell and a pomegranate, a bell and a pomegranate, around the skirts of the robe, to minister in, as Yahweh commanded Moses.",
                "They made the coats of fine linen of woven work for Aaron, and for his sons,",
                "and the turban of fine linen, and the linen headbands of fine linen, and the linen breeches of fine twined linen,",
                "and the sash of fine twined linen, and blue, and purple, and scarlet, the work of the embroiderer, as Yahweh commanded Moses.",
                "They made the plate of the holy crown of pure gold, and wrote on it a writing, like the engravings of a signet: \"HOLY TO YAHWEH.\"",
                "They tied to it a lace of blue, to fasten it on the turban above, as Yahweh commanded Moses.",
                "Thus all the work of the tabernacle of the Tent of Meeting was finished. The children of Israel did according to all that Yahweh commanded Moses; so they did.",
                "They brought the tabernacle to Moses, the tent, with all its furniture, its clasps, its boards, its bars, its pillars, its sockets,",
                "the covering of rams\u2019 skins dyed red, the covering of sea cow hides, the veil of the screen,",
                "the ark of the testimony with its poles, the mercy seat,",
                "the table, all its vessels, the show bread,",
                "the pure lampstand, its lamps, even the lamps to be set in order, all its vessels, the oil for the light,",
                "the golden altar, the anointing oil, the sweet incense, the screen for the door of the Tent,",
                "the bronze altar, its grating of brass, its poles, all of its vessels, the basin and its base,",
                "the hangings of the court, its pillars, its sockets, the screen for the gate of the court, its cords, its pins, all the instruments of the service of the tabernacle, for the Tent of Meeting,",
                "the finely worked garments for ministering in the holy place, the holy garments for Aaron the priest, and the garments of his sons, to minister in the priest\u2019s office.",
                "According to all that Yahweh commanded Moses, so the children of Israel did all the work.",
                "Moses saw all the work, and behold, they had done it as Yahweh had commanded, even so had they done it: and Moses blessed them."
            ],
            40: [
                "Yahweh spoke to Moses, saying,",
                "\"On the first day of the first month you shall raise up the tabernacle of the Tent of Meeting.",
                "You shall put the ark of the testimony in it, and you shall screen the ark with the veil.",
                "You shall bring in the table, and set in order the things that are on it. You shall bring in the lampstand, and light its lamps.",
                "You shall set the golden altar for incense before the ark of the testimony, and put the screen of the door to the tabernacle.",
                "\"You shall set the altar of burnt offering before the door of the tabernacle of the Tent of Meeting.",
                "You shall set the basin between the Tent of Meeting and the altar, and shall put water therein.",
                "You shall set up the court around it, and hang up the screen of the gate of the court.",
                "\"You shall take the anointing oil, and anoint the tabernacle, and all that is in it, and shall make it holy, and all its furniture: and it will be holy.",
                "You shall anoint the altar of burnt offering, with all its vessels, and sanctify the altar: and the altar will be most holy.",
                "You shall anoint the basin and its base, and sanctify it.",
                "\"You shall bring Aaron and his sons to the door of the Tent of Meeting, and shall wash them with water.",
                "You shall put on Aaron the holy garments; and you shall anoint him, and sanctify him, that he may minister to me in the priest\u2019s office.",
                "You shall bring his sons, and put coats on them.",
                "You shall anoint them, as you anointed their father, that they may minister to me in the priest\u2019s office. Their anointing shall be to them for an everlasting priesthood throughout their generations.\"",
                "Moses did so. According to all that Yahweh commanded him, so he did.",
                "It happened in the first month in the second year, on the first day of the month, that the tabernacle was raised up.",
                "Moses raised up the tabernacle, and laid its sockets, and set up its boards, and put in its bars, and raised up its pillars.",
                "He spread the covering over the tent, and put the roof of the tabernacle above on it, as Yahweh commanded Moses.",
                "He took and put the testimony into the ark, and set the poles on the ark, and put the mercy seat above on the ark.",
                "He brought the ark into the tabernacle, and set up the veil of the screen, and screened the ark of the testimony, as Yahweh commanded Moses.",
                "He put the table in the Tent of Meeting, on the side of the tabernacle northward, outside of the veil.",
                "He set the bread in order on it before Yahweh, as Yahweh commanded Moses.",
                "He put the lampstand in the Tent of Meeting, opposite the table, on the side of the tabernacle southward.",
                "He lit the lamps before Yahweh, as Yahweh commanded Moses.",
                "He put the golden altar in the Tent of Meeting before the veil;",
                "and he burnt incense of sweet spices on it, as Yahweh commanded Moses.",
                "He put up the screen of the door to the tabernacle.",
                "He set the altar of burnt offering at the door of the tabernacle of the Tent of Meeting, and offered on it the burnt offering and the meal offering, as Yahweh commanded Moses.",
                "He set the basin between the Tent of Meeting and the altar, and put water therein, with which to wash.",
                "Moses, Aaron, and his sons washed their hands and their feet there.",
                "When they went into the Tent of Meeting, and when they came near to the altar, they washed, as Yahweh commanded Moses.",
                "He raised up the court around the tabernacle and the altar, and set up the screen of the gate of the court. So Moses finished the work.",
                "Then the cloud covered the Tent of Meeting, and the glory of Yahweh filled the tabernacle.",
                "Moses wasn\u2019t able to enter into the Tent of Meeting, because the cloud stayed on it, and Yahweh\u2019s glory filled the tabernacle.",
                "When the cloud was taken up from over the tabernacle, the children of Israel went onward, throughout all their journeys;",
                "but if the cloud wasn\u2019t taken up, then they didn\u2019t travel until the day that it was taken up.",
                "For the cloud of Yahweh was on the tabernacle by day, and there was fire in the cloud by night, in the sight of all the house of Israel, throughout all their journeys."
            ]
        },
        3: {
            1: [
                "Yahweh called to Moses, and spoke to him out of the Tent of Meeting, saying,",
                "\"Speak to the children of Israel, and tell them, \u2018When anyone of you offers an offering to Yahweh, you shall offer your offering of the livestock, from the herd and from the flock.",
                "\"\u2018If his offering is a burnt offering from the herd, he shall offer a male without blemish. He shall offer it at the door of the Tent of Meeting, that he may be accepted before Yahweh.",
                "He shall lay his hand on the head of the burnt offering, and it shall be accepted for him to make atonement for him.",
                "He shall kill the bull before Yahweh. Aaron\u2019s sons, the priests, shall present the blood and sprinkle the blood around on the altar that is at the door of the Tent of Meeting.",
                "He shall flay the burnt offering, and cut it into pieces.",
                "The sons of Aaron the priest shall put fire on the altar, and lay wood in order on the fire;",
                "and Aaron\u2019s sons, the priests, shall lay the pieces, the head, and the fat in order on the wood that is on the fire which is on the altar;",
                "but its innards and its legs he shall wash with water. The priest shall burn the whole on the altar, for a burnt offering, an offering made by fire, of a pleasant aroma to Yahweh.",
                "\"\u2018If his offering is from the flock, from the sheep, or from the goats, for a burnt offering, he shall offer a male without blemish.",
                "He shall kill it on the north side of the altar before Yahweh. Aaron\u2019s sons, the priests, shall sprinkle its blood around on the altar.",
                "He shall cut it into its pieces, with its head and its fat. The priest shall lay them in order on the wood that is on the fire which is on the altar,",
                "but the innards and the legs he shall wash with water. The priest shall offer the whole, and burn it on the altar. It is a burnt offering, an offering made by fire, of a pleasant aroma to Yahweh.",
                "\"\u2018If his offering to Yahweh is a burnt offering of birds, then he shall offer his offering of turtledoves, or of young pigeons.",
                "The priest shall bring it to the altar, and wring off its head, and burn it on the altar; and its blood shall be drained out on the side of the altar;",
                "and he shall take away its crop with its filth, and cast it beside the altar on the east part, in the place of the ashes.",
                "He shall tear it by its wings, but shall not divide it apart. The priest shall burn it on the altar, on the wood that is on the fire. It is a burnt offering, an offering made by fire, of a pleasant aroma to Yahweh."
            ],
            2: [
                "\"\u2018When anyone offers an offering of a meal offering to Yahweh, his offering shall be of fine flour; and he shall pour oil on it, and put frankincense on it.",
                "He shall bring it to Aaron\u2019s sons, the priests; and he shall take his handful of its fine flour, and of its oil, with all its frankincense; and the priest shall burn its memorial on the altar, an offering made by fire, of a pleasant aroma to Yahweh.",
                "That which is left of the meal offering shall be Aaron\u2019s and his sons\u2019. It is a most holy thing of the offerings of Yahweh made by fire.",
                "\"\u2018When you offer an offering of a meal offering baked in the oven, it shall be unleavened cakes of fine flour mixed with oil, or unleavened wafers anointed with oil.",
                "If your offering is a meal offering of the griddle, it shall be of unleavened fine flour, mixed with oil.",
                "You shall cut it in pieces, and pour oil on it. It is a meal offering.",
                "If your offering is a meal offering of the pan, it shall be made of fine flour with oil.",
                "You shall bring the meal offering that is made of these things to Yahweh: and it shall be presented to the priest, and he shall bring it to the altar.",
                "The priest shall take from the meal offering its memorial, and shall burn it on the altar, an offering made by fire, of a pleasant aroma to Yahweh.",
                "That which is left of the meal offering shall be Aaron\u2019s and his sons\u2019. It is a thing most holy of the offerings of Yahweh made by fire.",
                "\"\u2018No meal offering, which you shall offer to Yahweh, shall be made with yeast; for you shall burn no yeast, nor any honey, as an offering made by fire to Yahweh.",
                "As an offering of firstfruits you shall offer them to Yahweh: but they shall not ascend for a pleasant aroma on the altar.",
                "Every offering of your meal offering you shall season with salt; neither shall you allow the salt of the covenant of your God to be lacking from your meal offering. With all your offerings you shall offer salt.",
                "\"\u2018If you offer a meal offering of first fruits to Yahweh, you shall offer for the meal offering of your first fruits grain in the ear parched with fire, bruised grain of the fresh ear.",
                "You shall put oil on it, and lay frankincense on it: it is a meal offering.",
                "The priest shall burn as its memorial, part of its bruised grain, and part of its oil, along with all its frankincense: it is an offering made by fire to Yahweh."
            ],
            3: [
                "\"\u2018If his offering is a sacrifice of peace offerings; if he offers it from the herd, whether male or female, he shall offer it without blemish before Yahweh.",
                "He shall lay his hand on the head of his offering, and kill it at the door of the Tent of Meeting: and Aaron\u2019s sons, the priests shall sprinkle the blood around on the altar.",
                "He shall offer of the sacrifice of peace offerings an offering made by fire to Yahweh; the fat that covers the innards, and all the fat that is on the innards,",
                "and the two kidneys, and the fat that is on them, which is by the loins, and the cover on the liver, with the kidneys, he shall take away.",
                "Aaron\u2019s sons shall burn it on the altar on the burnt offering, which is on the wood that is on the fire: it is an offering made by fire, of a pleasant aroma to Yahweh.",
                "\"\u2018If his offering for a sacrifice of peace offerings to Yahweh is from the flock; male or female, he shall offer it without blemish.",
                "If he offers a lamb for his offering, then he shall offer it before Yahweh;",
                "and he shall lay his hand on the head of his offering, and kill it before the Tent of Meeting: and Aaron\u2019s sons shall sprinkle its blood around on the altar.",
                "He shall offer from the sacrifice of peace offerings an offering made by fire to Yahweh; its fat, the entire tail fat, he shall take away close to the backbone; and the fat that covers the inwards, and all the fat that is on the inwards,",
                "and the two kidneys, and the fat that is on them, which is by the loins, and the cover on the liver, with the kidneys, he shall take away.",
                "The priest shall burn it on the altar: it is the food of the offering made by fire to Yahweh.",
                "\"\u2018If his offering is a goat, then he shall offer it before Yahweh:",
                "and he shall lay his hand on its head, and kill it before the Tent of Meeting; and the sons of Aaron shall sprinkle its blood around on the altar.",
                "He shall offer from it as his offering, an offering made by fire to Yahweh; the fat that covers the innards, and all the fat that is on the innards,",
                "and the two kidneys, and the fat that is on them, which is by the loins, and the cover on the liver, with the kidneys, he shall take away.",
                "The priest shall burn them on the altar: it is the food of the offering made by fire, for a pleasant aroma; all the fat is Yahweh\u2019s.",
                "\"\u2018It shall be a perpetual statute throughout your generations in all your dwellings, that you shall eat neither fat nor blood.\u2019\""
            ],
            4: [
                "Yahweh spoke to Moses, saying,",
                "\"Speak to the children of Israel, saying, \u2018If anyone sins unintentionally, in any of the things which Yahweh has commanded not to be done, and does any one of them:",
                "if the anointed priest sins so as to bring guilt on the people, then let him offer for his sin, which he has sinned, a young bull without blemish to Yahweh for a sin offering.",
                "He shall bring the bull to the door of the Tent of Meeting before Yahweh; and he shall lay his hand on the head of the bull, and kill the bull before Yahweh.",
                "The anointed priest shall take some of the blood of the bull, and bring it to the Tent of Meeting.",
                "The priest shall dip his finger in the blood, and sprinkle some of the blood seven times before Yahweh, before the veil of the sanctuary.",
                "The priest shall put some of the blood on the horns of the altar of sweet incense before Yahweh, which is in the Tent of Meeting; and he shall pour out all of rest of the blood of the bull at the base of the altar of burnt offering, which is at the door of the Tent of Meeting.",
                "He shall take all the fat of the bull of the sin offering off of it; the fat that covers the innards, and all the fat that is on the innards,",
                "and the two kidneys, and the fat that is on them, which is by the loins, and the cover on the liver, with the kidneys, he shall take away,",
                "as it is taken off of the bull of the sacrifice of peace offerings. The priest shall burn them on the altar of burnt offering.",
                "The bull\u2019s skin, all its flesh, with its head, and with its legs, its innards, and its dung,",
                "even the whole bull shall he carry forth outside the camp to a clean place, where the ashes are poured out, and burn it on wood with fire. Where the ashes are poured out it shall be burned.",
                "\"\u2018If the whole congregation of Israel sins, and the thing is hidden from the eyes of the assembly, and they have done any of the things which Yahweh has commanded not to be done, and are guilty;",
                "when the sin in which they have sinned is known, then the assembly shall offer a young bull for a sin offering, and bring it before the Tent of Meeting.",
                "The elders of the congregation shall lay their hands on the head of the bull before Yahweh; and the bull shall be killed before Yahweh.",
                "The anointed priest shall bring of the blood of the bull to the Tent of Meeting:",
                "and the priest shall dip his finger in the blood, and sprinkle it seven times before Yahweh, before the veil.",
                "He shall put some of the blood on the horns of the altar which is before Yahweh, that is in the Tent of Meeting; and the rest of the blood he shall pour out at the base of the altar of burnt offering, which is at the door of the Tent of Meeting.",
                "All its fat he shall take from it, and burn it on the altar.",
                "Thus shall he do with the bull; as he did with the bull of the sin offering, so shall he do with this; and the priest shall make atonement for them, and they shall be forgiven.",
                "He shall carry forth the bull outside the camp, and burn it as he burned the first bull. It is the sin offering for the assembly.",
                "\"\u2018When a ruler sins, and unwittingly does any one of all the things which Yahweh his God has commanded not to be done, and is guilty;",
                "if his sin, in which he has sinned, is made known to him, he shall bring as his offering a goat, a male without blemish.",
                "He shall lay his hand on the head of the goat, and kill it in the place where they kill the burnt offering before Yahweh. It is a sin offering.",
                "The priest shall take some of the blood of the sin offering with his finger, and put it on the horns of the altar of burnt offering. He shall pour out the rest of its blood at the base of the altar of burnt offering.",
                "All its fat he shall burn on the altar, like the fat of the sacrifice of peace offerings; and the priest shall make atonement for him concerning his sin, and he will be forgiven.",
                "\"\u2018If anyone of the common people sins unwittingly, in doing any of the things which Yahweh has commanded not to be done, and is guilty;",
                "if his sin, which he has sinned, is made known to him, then he shall bring for his offering a goat, a female without blemish, for his sin which he has sinned.",
                "He shall lay his hand on the head of the sin offering, and kill the sin offering in the place of burnt offering.",
                "The priest shall take some of its blood with his finger, and put it on the horns of the altar of burnt offering; and the rest of its blood he shall pour out at the base of the altar.",
                "All its fat he shall take away, like the fat is taken away from off of the sacrifice of peace offerings; and the priest shall burn it on the altar for a pleasant aroma to Yahweh; and the priest shall make atonement for him, and he will be forgiven.",
                "\"\u2018If he brings a lamb as his offering for a sin offering, he shall bring a female without blemish.",
                "He shall lay his hand on the head of the sin offering, and kill it for a sin offering in the place where they kill the burnt offering.",
                "The priest shall take some of the blood of the sin offering with his finger, and put it on the horns of the altar of burnt offering; and all the rest of its blood he shall pour out at the base of the altar.",
                "All its fat he shall take away, like the fat of the lamb is taken away from the sacrifice of peace offerings; and the priest shall burn them on the altar, on the offerings of Yahweh made by fire; and the priest shall make atonement for him concerning his sin that he has sinned, and he will be forgiven."
            ],
            5: [
                "\"\u2018If anyone sins, in that he hears the voice of adjuration, he being a witness, whether he has seen or known, if he doesn\u2019t report it, then he shall bear his iniquity.",
                "\"\u2018Or if anyone touches any unclean thing, whether it is the carcass of an unclean animal, or the carcass of unclean livestock, or the carcass of unclean creeping things, and it is hidden from him, and he is unclean, then he shall be guilty.",
                "\"\u2018Or if he touches the uncleanness of man, whatever his uncleanness is with which he is unclean, and it is hidden from him; when he knows of it, then he shall be guilty.",
                "\"\u2018Or if anyone swears rashly with his lips to do evil, or to do good, whatever it is that a man might utter rashly with an oath, and it is hidden from him; when he knows of it, then he shall be guilty of one of these.",
                "It shall be, when he is guilty of one of these, he shall confess that in which he has sinned:",
                "and he shall bring his trespass offering to Yahweh for his sin which he has sinned, a female from the flock, a lamb or a goat, for a sin offering; and the priest shall make atonement for him concerning his sin.",
                "\"\u2018If he can\u2019t afford a lamb, then he shall bring his trespass offering for that in which he has sinned, two turtledoves, or two young pigeons, to Yahweh; one for a sin offering, and the other for a burnt offering.",
                "He shall bring them to the priest, who shall first offer the one which is for the sin offering, and wring off its head from its neck, but shall not sever it completely.",
                "He shall sprinkle some of the blood of the sin offering on the side of the altar; and the rest of the blood shall be drained out at the base of the altar. It is a sin offering.",
                "He shall offer the second for a burnt offering, according to the ordinance; and the priest shall make atonement for him concerning his sin which he has sinned, and he shall be forgiven.",
                "\"\u2018But if he can\u2019t afford two turtledoves, or two young pigeons, then he shall bring his offering for that in which he has sinned, the tenth part of an ephah of fine flour for a sin offering. He shall put no oil on it, neither shall he put any frankincense on it, for it is a sin offering.",
                "He shall bring it to the priest, and the priest shall take his handful of it as the memorial portion, and burn it on the altar, on the offerings of Yahweh made by fire. It is a sin offering.",
                "The priest shall make atonement for him concerning his sin that he has sinned in any of these things, and he will be forgiven; and the rest shall be the priest\u2019s, as the meal offering.\u2019\"",
                "Yahweh spoke to Moses, saying,",
                "\"If anyone commits a trespass, and sins unwittingly, in the holy things of Yahweh; then he shall bring his trespass offering to Yahweh, a ram without blemish from the flock, according to your estimation in silver by shekels, after the shekel of the sanctuary, for a trespass offering.",
                "He shall make restitution for that which he has done wrong in the holy thing, and shall add a fifth part to it, and give it to the priest; and the priest shall make atonement for him with the ram of the trespass offering, and he will be forgiven.",
                "\"If anyone sins, and does any of the things which Yahweh has commanded not to be done; though he didn\u2019t know it, yet he is guilty, and shall bear his iniquity.",
                "He shall bring a ram without blemish from of the flock, according to your estimation, for a trespass offering, to the priest; and the priest shall make atonement for him concerning the thing in which he sinned and didn\u2019t know it, and he will be forgiven.",
                "It is a trespass offering. He is certainly guilty before Yahweh.\""
            ],
            6: [
                "Yahweh spoke to Moses, saying,",
                "\"If anyone sins, and commits a trespass against Yahweh, and deals falsely with his neighbor in a matter of deposit, or of bargain, or of robbery, or has oppressed his neighbor,",
                "or has found that which was lost, and dealt falsely therein, and swearing to a lie; in any of all these things that a man does, sinning therein;",
                "then it shall be, if he has sinned, and is guilty, he shall restore that which he took by robbery, or the thing which he has gotten by oppression, or the deposit which was committed to him, or the lost thing which he found,",
                "or any thing about which he has sworn falsely; he shall restore it even in full, and shall add a fifth part more to it. To him to whom it belongs he shall give it, in the day of his being found guilty.",
                "He shall bring his trespass offering to Yahweh, a ram without blemish from the flock, according to your estimation, for a trespass offering, to the priest.",
                "The priest shall make atonement for him before Yahweh, and he will be forgiven concerning whatever he does to become guilty.\"",
                "Yahweh spoke to Moses, saying,",
                "\"Command Aaron and his sons, saying, \u2018This is the law of the burnt offering: the burnt offering shall be on the hearth on the altar all night until the morning; and the fire of the altar shall be kept burning on it.",
                "The priest shall put on his linen garment, and he shall put on his linen breeches upon his body; and he shall remove the ashes from where the fire has consumed the burnt offering on the altar, and he shall put them beside the altar.",
                "He shall take off his garments, and put on other garments, and carry the ashes outside the camp to a clean place.",
                "The fire on the altar shall be kept burning on it, it shall not go out; and the priest shall burn wood on it every morning: and he shall lay the burnt offering in order upon it, and shall burn on it the fat of the peace offerings.",
                "Fire shall be kept burning on the altar continually; it shall not go out.",
                "\"\u2018This is the law of the meal offering: the sons of Aaron shall offer it before Yahweh, before the altar.",
                "He shall take from there his handful of the fine flour of the meal offering, and of its oil, and all the frankincense which is on the meal offering, and shall burn it on the altar for a pleasant aroma, as its memorial, to Yahweh.",
                "That which is left of it Aaron and his sons shall eat. It shall be eaten without yeast in a holy place. They shall eat it in the court of the Tent of Meeting.",
                "It shall not be baked with yeast. I have given it as their portion of my offerings made by fire. It is most holy, as the sin offering, and as the trespass offering.",
                "Every male among the children of Aaron shall eat of it, as their portion forever throughout your generations, from the offerings of Yahweh made by fire. Whoever touches them shall be holy.\u2019\"",
                "Yahweh spoke to Moses, saying,",
                "\"This is the offering of Aaron and of his sons, which they shall offer to Yahweh in the day when he is anointed: the tenth part of an ephah of fine flour for a meal offering perpetually, half of it in the morning, and half of it in the evening.",
                "It shall be made with oil in a griddle. When it is soaked, you shall bring it in. You shall offer the meal offering in baked pieces for a pleasant aroma to Yahweh.",
                "The anointed priest that will be in his place from among his sons shall offer it. By a statute forever, it shall be wholly burnt to Yahweh.",
                "Every meal offering of a priest shall be wholly burned. It shall not be eaten.\"",
                "Yahweh spoke to Moses, saying,",
                "\"Speak to Aaron and to his sons, saying, \u2018This is the law of the sin offering: in the place where the burnt offering is killed, the sin offering shall be killed before Yahweh. It is most holy.",
                "The priest who offers it for sin shall eat it. It shall be eaten in a holy place, in the court of the Tent of Meeting.",
                "Whatever shall touch its flesh shall be holy. When there is any of its blood sprinkled on a garment, you shall wash that on which it was sprinkled in a holy place.",
                "But the earthen vessel in which it is boiled shall be broken; and if it is boiled in a bronze vessel, it shall be scoured, and rinsed in water.",
                "Every male among the priests shall eat of it: it is most holy.",
                "No sin offering, of which any of the blood is brought into the Tent of Meeting to make atonement in the Holy Place, shall be eaten: it shall be burned with fire."
            ],
            7: [
                "\"\u2018This is the law of the trespass offering. It is most holy.",
                "In the place where they kill the burnt offering, he shall kill the trespass offering; and its blood he shall sprinkle around on the altar.",
                "He shall offer all of its fat: the fat tail, and the fat that covers the innards,",
                "and the two kidneys, and the fat that is on them, which is by the loins, and the cover on the liver, with the kidneys, shall he take away;",
                "and the priest shall burn them on the altar for an offering made by fire to Yahweh: it is a trespass offering.",
                "Every male among the priests may eat of it. It shall be eaten in a holy place. It is most holy.",
                "\"\u2018As is the sin offering, so is the trespass offering; there is one law for them. The priest who makes atonement with them shall have it.",
                "The priest who offers any man\u2019s burnt offering, even the priest shall have for himself the skin of the burnt offering which he has offered.",
                "Every meal offering that is baked in the oven, and all that is dressed in the pan, and on the griddle, shall be the priest\u2019s who offers it.",
                "Every meal offering, mixed with oil or dry, belongs to all the sons of Aaron, one as well as another.",
                "\"\u2018This is the law of the sacrifice of peace offerings, which one shall offer to Yahweh.",
                "If he offers it for a thanksgiving, then he shall offer with the sacrifice of thanksgiving unleavened cakes mixed with oil, and unleavened wafers anointed with oil, and cakes mixed with oil.",
                "With cakes of leavened bread he shall offer his offering with the sacrifice of his peace offerings for thanksgiving.",
                "Of it he shall offer one out of each offering for a heave offering to Yahweh. It shall be the priest\u2019s who sprinkles the blood of the peace offerings.",
                "The flesh of the sacrifice of his peace offerings for thanksgiving shall be eaten on the day of his offering. He shall not leave any of it until the morning.",
                "\"\u2018But if the sacrifice of his offering is a vow, or a freewill offering, it shall be eaten on the day that he offers his sacrifice; and on the next day what remains of it shall be eaten:",
                "but what remains of the flesh of the sacrifice on the third day shall be burned with fire.",
                "If any of the flesh of the sacrifice of his peace offerings is eaten on the third day, it will not be accepted, neither shall it be imputed to him who offers it. It will be an abomination, and the soul who eats any of it will bear his iniquity.",
                "\"\u2018The flesh that touches any unclean thing shall not be eaten. It shall be burned with fire. As for the flesh, everyone who is clean may eat it;",
                "but the soul who eats of the flesh of the sacrifice of peace offerings, that belongs to Yahweh, having his uncleanness on him, that soul shall be cut off from his people.",
                "When anyone touches any unclean thing, the uncleanness of man, or an unclean animal, or any unclean abomination, and eats some of the flesh of the sacrifice of peace offerings, which belong to Yahweh, that soul shall be cut off from his people.\u2019\"",
                "Yahweh spoke to Moses, saying,",
                "\"Speak to the children of Israel, saying, \u2018You shall eat no fat, of bull, or sheep, or goat.",
                "The fat of that which dies of itself, and the fat of that which is torn of animals, may be used for any other service, but you shall in no way eat of it.",
                "For whoever eats the fat of the animal, of which men offer an offering made by fire to Yahweh, even the soul who eats it shall be cut off from his people.",
                "You shall not eat any blood, whether it is of bird or of animal, in any of your dwellings.",
                "Whoever it is who eats any blood, that soul shall be cut off from his people.\u2019\"",
                "Yahweh spoke to Moses, saying,",
                "\"Speak to the children of Israel, saying, \u2018He who offers the sacrifice of his peace offerings to Yahweh shall bring his offering to Yahweh out of the sacrifice of his peace offerings.",
                "With his own hands he shall bring the offerings of Yahweh made by fire. He shall bring the fat with the breast, that the breast may be waved for a wave offering before Yahweh.",
                "The priest shall burn the fat on the altar, but the breast shall be Aaron\u2019s and his sons\u2019.",
                "The right thigh you shall give to the priest for a heave offering out of the sacrifices of your peace offerings.",
                "He among the sons of Aaron who offers the blood of the peace offerings, and the fat, shall have the right thigh for a portion.",
                "For the waved breast and the heaved thigh I have taken from the children of Israel out of the sacrifices of their peace offerings, and have given them to Aaron the priest and to his sons as their portion forever from the children of Israel.\u2019\"",
                "This is the anointing portion of Aaron, and the anointing portion of his sons, out of the offerings of Yahweh made by fire, in the day when he presented them to minister to Yahweh in the priest\u2019s office;",
                "which Yahweh commanded to be given them of the children of Israel, in the day that he anointed them. It is their portion forever throughout their generations.",
                "This is the law of the burnt offering, of the meal offering, and of the sin offering, and of the trespass offering, and of the consecration, and of the sacrifice of peace offerings;",
                "which Yahweh commanded Moses in Mount Sinai, in the day that he commanded the children of Israel to offer their offerings to Yahweh, in the wilderness of Sinai."
            ],
            8: [
                "Yahweh spoke to Moses, saying,",
                "\"Take Aaron and his sons with him, and the garments, and the anointing oil, and the bull of the sin offering, and the two rams, and the basket of unleavened bread;",
                "and assemble all the congregation at the door of the Tent of Meeting.\"",
                "Moses did as Yahweh commanded him; and the congregation was assembled at the door of the Tent of Meeting.",
                "Moses said to the congregation, \"This is the thing which Yahweh has commanded to be done.\"",
                "Moses brought Aaron and his sons, and washed them with water.",
                "He put the coat on him, tied the sash on him, clothed him with the robe, put the ephod on him, and he tied the skillfully woven band of the ephod on him, and fastened it to him with it.",
                "He placed the breastplate on him; and in the breastplate he put the Urim and the Thummim.",
                "He set the turban on his head; and on the turban, in front, he set the golden plate, the holy crown; as Yahweh commanded Moses.",
                "Moses took the anointing oil, and anointed the tabernacle and all that was in it, and sanctified them.",
                "He sprinkled it on the altar seven times, and anointed the altar and all its vessels, and the basin and its base, to sanctify them.",
                "He poured some of the anointing oil on Aaron\u2019s head, and anointed him, to sanctify him.",
                "Moses brought Aaron\u2019s sons, and clothed them with coats, and tied sashes on them, and put headbands on them; as Yahweh commanded Moses.",
                "He brought the bull of the sin offering, and Aaron and his sons laid their hands on the head of the bull of the sin offering.",
                "He killed it; and Moses took the blood, and put it around on the horns of the altar with his finger, and purified the altar, and poured out the blood at the base of the altar, and sanctified it, to make atonement for it.",
                "He took all the fat that was on the innards, and the cover of the liver, and the two kidneys, and their fat; and Moses burned it on the altar.",
                "But the bull, and its skin, and its flesh, and its dung, he burned with fire outside the camp; as Yahweh commanded Moses.",
                "He presented the ram of the burnt offering: and Aaron and his sons laid their hands on the head of the ram.",
                "He killed it; and Moses sprinkled the blood around on the altar.",
                "He cut the ram into its pieces; and Moses burned the head, and the pieces, and the fat.",
                "He washed the innards and the legs with water; and Moses burned the whole ram on the altar. It was a burnt offering for a pleasant aroma. It was an offering made by fire to Yahweh; as Yahweh commanded Moses.",
                "He presented the other ram, the ram of consecration: and Aaron and his sons laid their hands on the head of the ram.",
                "He killed it; and Moses took some of its blood, and put it on the tip of Aaron\u2019s right ear, and on the thumb of his right hand, and on the great toe of his right foot.",
                "He brought Aaron\u2019s sons; and Moses put some of the blood on the tip of their right ear, and on the thumb of their right hand, and on the great toe of their right foot; and Moses sprinkled the blood around on the altar.",
                "He took the fat, and the fat tail, and all the fat that was on the innards, and the cover of the liver, and the two kidneys, and their fat, and the right thigh;",
                "and out of the basket of unleavened bread, that was before Yahweh, he took one unleavened cake, and one cake of oiled bread, and one wafer, and placed them on the fat, and on the right thigh.",
                "He put all these in Aaron\u2019s hands and in his sons\u2019 hands, and waved them for a wave offering before Yahweh.",
                "Moses took them from their hands, and burned them on the altar on the burnt offering. They were a consecration for a pleasant aroma. It was an offering made by fire to Yahweh.",
                "Moses took the breast, and waved it for a wave offering before Yahweh. It was Moses\u2019 portion of the ram of consecration, as Yahweh commanded Moses.",
                "Moses took some of the anointing oil, and some of the blood which was on the altar, and sprinkled it on Aaron, on his garments, and on his sons, and on his sons\u2019 garments with him, and sanctified Aaron, his garments, and his sons, and his sons\u2019 garments with him.",
                "Moses said to Aaron and to his sons, \"Boil the flesh at the door of the Tent of Meeting, and there eat it and the bread that is in the basket of consecration, as I commanded, saying, \u2018Aaron and his sons shall eat it.\u2019",
                "What remains of the flesh and of the bread you shall burn with fire.",
                "You shall not go out from the door of the Tent of Meeting seven days, until the days of your consecration are fulfilled: for he shall consecrate you seven days.",
                "What has been done this day, so Yahweh has commanded to do, to make atonement for you.",
                "You shall stay at the door of the Tent of Meeting day and night seven days, and keep Yahweh\u2019s command, that you don\u2019t die: for so I am commanded.\"",
                "Aaron and his sons did all the things which Yahweh commanded by Moses."
            ],
            9: [
                "It happened on the eighth day, that Moses called Aaron and his sons, and the elders of Israel;",
                "and he said to Aaron, \"Take a calf from the herd for a sin offering, and a ram for a burnt offering, without blemish, and offer them before Yahweh.",
                "You shall speak to the children of Israel, saying, \u2018Take a male goat for a sin offering; and a calf and a lamb, both a year old, without blemish, for a burnt offering;",
                "and a bull and a ram for peace offerings, to sacrifice before Yahweh; and a meal offering mixed with oil: for today Yahweh appears to you.\u2019\"",
                "They brought what Moses commanded before the Tent of Meeting: and all the congregation drew near and stood before Yahweh.",
                "Moses said, \"This is the thing which Yahweh commanded that you should do: and the glory of Yahweh shall appear to you.\"",
                "Moses said to Aaron, \"Draw near to the altar, and offer your sin offering, and your burnt offering, and make atonement for yourself, and for the people; and offer the offering of the people, and make atonement for them; as Yahweh commanded.\"",
                "So Aaron drew near to the altar, and killed the calf of the sin offering, which was for himself.",
                "The sons of Aaron presented the blood to him; and he dipped his finger in the blood, and put it on the horns of the altar, and poured out the blood at the base of the altar:",
                "but the fat, and the kidneys, and the cover from the liver of the sin offering, he burned upon the altar; as Yahweh commanded Moses.",
                "The flesh and the skin he burned with fire outside the camp.",
                "He killed the burnt offering; and Aaron\u2019s sons delivered the blood to him, and he sprinkled it around on the altar.",
                "They delivered the burnt offering to him, piece by piece, and the head: and he burned them upon the altar.",
                "He washed the innards and the legs, and burned them on the burnt offering on the altar.",
                "He presented the people\u2019s offering, and took the goat of the sin offering which was for the people, and killed it, and offered it for sin, like the first.",
                "He presented the burnt offering, and offered it according to the ordinance.",
                "He presented the meal offering, and filled his hand from there, and burned it upon the altar, besides the burnt offering of the morning.",
                "He also killed the bull and the ram, the sacrifice of peace offerings, which was for the people: and Aaron\u2019s sons delivered to him the blood, which he sprinkled around on the altar,",
                "and the fat of the bull and of the ram, the fat tail, and that which covers the innards, and the kidneys, and the cover of the liver:",
                "and they put the fat upon the breasts, and he burned the fat on the altar:",
                "and the breasts and the right thigh Aaron waved for a wave offering before Yahweh, as Moses commanded.",
                "Aaron lifted up his hands toward the people, and blessed them; and he came down from offering the sin offering, and the burnt offering, and the peace offerings.",
                "Moses and Aaron went into the Tent of Meeting, and came out, and blessed the people: and the glory of Yahweh appeared to all the people.",
                "There came forth fire from before Yahweh, and consumed the burnt offering and the fat upon the altar: and when all the people saw it, they shouted, and fell on their faces."
            ],
            10: [
                "Nadab and Abihu, the sons of Aaron, each took his censer, and put fire in it, and laid incense on it, and offered strange fire before Yahweh, which he had not commanded them.",
                "And fire came forth from before Yahweh, and devoured them, and they died before Yahweh.",
                "Then Moses said to Aaron, \"This is what Yahweh spoke of, saying, \u2018I will show myself holy to those who come near me, and before all the people I will be glorified.\u2019\" Aaron held his peace.",
                "Moses called Mishael and Elzaphan, the sons of Uzziel the uncle of Aaron, and said to them, \"Draw near, carry your brothers from before the sanctuary out of the camp.\"",
                "So they drew near, and carried them in their coats out of the camp, as Moses had said.",
                "Moses said to Aaron, and to Eleazar and to Ithamar, his sons, \"Don\u2019t let the hair of your heads go loose, neither tear your clothes; that you don\u2019t die, and that he not be angry with all the congregation: but let your brothers, the whole house of Israel, bewail the burning which Yahweh has kindled.",
                "You shall not go out from the door of the Tent of Meeting, lest you die; for the anointing oil of Yahweh is on you.\" They did according to the word of Moses.",
                "Yahweh spoke to Aaron, saying,",
                "\"Drink no wine nor strong drink, you, nor your sons with you, when you go into the Tent of Meeting, that you don\u2019t die: it shall be a statute forever throughout your generations:",
                "and that you are to make a distinction between the holy and the common, and between the unclean and the clean;",
                "and that you are to teach the children of Israel all the statutes which Yahweh has spoken to them by Moses.\"",
                "Moses spoke to Aaron, and to Eleazar and to Ithamar, his sons who were left, \"Take the meal offering that remains of the offerings of Yahweh made by fire, and eat it without yeast beside the altar; for it is most holy;",
                "and you shall eat it in a holy place, because it is your portion, and your sons\u2019 portion, of the offerings of Yahweh made by fire: for so I am commanded.",
                "The waved breast and the heaved thigh you shall eat in a clean place, you, and your sons, and your daughters with you: for they are given as your portion, and your sons\u2019 portion, out of the sacrifices of the peace offerings of the children of Israel.",
                "The heaved thigh and the waved breast they shall bring with the offerings made by fire of the fat, to wave it for a wave offering before Yahweh: and it shall be yours, and your sons\u2019 with you, as a portion forever; as Yahweh has commanded.\"",
                "Moses diligently inquired about the goat of the sin offering, and, behold, it was burned: and he was angry with Eleazar and with Ithamar, the sons of Aaron who were left, saying,",
                "\"Why haven\u2019t you eaten the sin offering in the place of the sanctuary, since it is most holy, and he has given it you to bear the iniquity of the congregation, to make atonement for them before Yahweh?",
                "Behold, its blood was not brought into the inner part of the sanctuary: you certainly should have eaten it in the sanctuary, as I commanded.\"",
                "Aaron spoke to Moses, \"Behold, this day they have offered their sin offering and their burnt offering before Yahweh; and such things as these have happened to me: and if I had eaten the sin offering today, would it have been pleasing in the sight of Yahweh?\"",
                "When Moses heard that, it was pleasing in his sight."
            ],
            11: [
                "Yahweh spoke to Moses and to Aaron, saying to them,",
                "\"Speak to the children of Israel, saying, \u2018These are the living things which you may eat among all the animals that are on the earth.",
                "Whatever parts the hoof, and is cloven-footed, and chews the cud among the animals, that you may eat.",
                "\"\u2018Nevertheless these you shall not eat of those that chew the cud, or of those who part the hoof: the camel, because he chews the cud but doesn\u2019t have a parted hoof, he is unclean to you.",
                "The coney, because he chews the cud but doesn\u2019t have a parted hoof, he is unclean to you.",
                "The hare, because she chews the cud but doesn\u2019t part the hoof, she is unclean to you.",
                "The pig, because he has a split hoof, and is cloven-footed, but doesn\u2019t chew the cud, he is unclean to you.",
                "Of their flesh you shall not eat, and their carcasses you shall not touch; they are unclean to you.",
                "\"\u2018These you may eat of all that are in the waters: whatever has fins and scales in the waters, in the seas, and in the rivers, that you may eat.",
                "All that don\u2019t have fins and scales in the seas, and in the rivers, of all that move in the waters, and of all the living creatures that are in the waters, they are an abomination to you,",
                "and you detest them. You shall not eat of their flesh, and you shall detest their carcasses.",
                "Whatever has no fins nor scales in the waters, that is an abomination to you.",
                "\"\u2018These you shall detest among the birds; they shall not be eaten, they are an abomination: the eagle, and the vulture, and the black vulture,",
                "and the red kite, any kind of black kite,",
                "any kind of raven,",
                "the horned owl, the screech owl, and the gull, any kind of hawk,",
                "the little owl, the cormorant, the great owl,",
                "the white owl, the desert owl, the osprey,",
                "the stork, any kind of heron, the hoopoe, and the bat.",
                "\"\u2018All flying insects that walk on all fours are an abomination to you.",
                "Yet you may eat these: of all winged creeping things that go on all fours, which have legs above their feet, with which to hop on the earth.",
                "Even of these you may eat: any kind of locust, any kind of katydid, any kind of cricket, and any kind of grasshopper.",
                "But all winged creeping things which have four feet, are an abomination to you.",
                "\"\u2018By these you will become unclean: whoever touches their carcass shall be unclean until the evening.",
                "Whoever carries any part of their carcass shall wash his clothes, and be unclean until the evening.",
                "\"\u2018Every animal which parts the hoof, and is not cloven-footed, nor chews the cud, is unclean to you. Everyone who touches them shall be unclean.",
                "Whatever goes on its paws, among all animals that go on all fours, they are unclean to you. Whoever touches their carcass shall be unclean until the evening.",
                "He who carries their carcass shall wash his clothes, and be unclean until the evening. They are unclean to you.",
                "\"\u2018These are they which are unclean to you among the creeping things that creep on the earth: the weasel, the rat, any kind of great lizard,",
                "the gecko, and the monitor lizard, the wall lizard, the skink, and the chameleon.",
                "These are they which are unclean to you among all that creep. Whoever touches them when they are dead, shall be unclean until the evening.",
                "On whatever any of them falls when they are dead, it shall be unclean; whether it is any vessel of wood, or clothing, or skin, or sack, whatever vessel it is, with which any work is done, it must be put into water, and it shall be unclean until the evening; then it will be clean.",
                "Every earthen vessel, into which any of them falls, all that is in it shall be unclean, and you shall break it.",
                "All food which may be eaten, that on which water comes, shall be unclean; and all drink that may be drunk in every such vessel shall be unclean.",
                "Everything whereupon part of their carcass falls shall be unclean; whether oven, or range for pots, it shall be broken in pieces: they are unclean, and shall be unclean to you.",
                "Nevertheless a spring or a cistern in which water is a gathered shall be clean: but that which touches their carcass shall be unclean.",
                "If part of their carcass falls on any sowing seed which is to be sown, it is clean.",
                "But if water is put on the seed, and part of their carcass falls on it, it is unclean to you.",
                "\"\u2018If any animal, of which you may eat, dies; he who touches its carcass shall be unclean until the evening.",
                "He who eats of its carcass shall wash his clothes, and be unclean until the evening. He also who carries its carcass shall wash his clothes, and be unclean until the evening.",
                "\"\u2018Every creeping thing that creeps on the earth is an abomination. It shall not be eaten.",
                "Whatever goes on its belly, and whatever goes on all fours, or whatever has many feet, even all creeping things that creep on the earth, them you shall not eat; for they are an abomination.",
                "You shall not make yourselves abominable with any creeping thing that creeps, neither shall you make yourselves unclean with them, that you should be defiled thereby.",
                "For I am Yahweh your God. Sanctify yourselves therefore, and be holy; for I am holy: neither shall you defile yourselves with any kind of creeping thing that moves on the earth.",
                "For I am Yahweh who brought you up out of the land of Egypt, to be your God. You shall therefore be holy, for I am holy.",
                "\"\u2018This is the law of the animal, and of the bird, and of every living creature that moves in the waters, and of every creature that creeps on the earth,",
                "to make a distinction between the unclean and the clean, and between the living thing that may be eaten and the living thing that may not be eaten.\u2019\""
            ],
            12: [
                "Yahweh spoke to Moses, saying,",
                "\"Speak to the children of Israel, saying, \u2018If a woman conceives, and bears a male child, then she shall be unclean seven days; as in the days of her monthly period she shall be unclean.",
                "In the eighth day the flesh of his foreskin shall be circumcised.",
                "She shall continue in the blood of purification thirty-three days. She shall not touch any holy thing, nor come into the sanctuary, until the days of her purifying are completed.",
                "But if she bears a female child, then she shall be unclean two weeks, as in her period; and she shall continue in the blood of purification sixty-six days.",
                "\"\u2018When the days of her purification are completed, for a son, or for a daughter, she shall bring to the priest at the door of the Tent of Meeting, a year old lamb for a burnt offering, and a young pigeon, or a turtledove, for a sin offering:",
                "and he shall offer it before Yahweh, and make atonement for her; and she shall be cleansed from the fountain of her blood. \"\u2018This is the law for her who bears, whether a male or a female.",
                "If she cannot afford a lamb, then she shall take two turtledoves, or two young pigeons; the one for a burnt offering, and the other for a sin offering: and the priest shall make atonement for her, and she shall be clean.\u2019\""
            ],
            13: [
                "Yahweh spoke to Moses and to Aaron, saying,",
                "\"When a man shall have a rising in his body\u2019s skin, or a scab, or a bright spot, and it becomes in the skin of his body the plague of leprosy, then he shall be brought to Aaron the priest, or to one of his sons, the priests:",
                "and the priest shall examine the plague in the skin of the body: and if the hair in the plague has turned white, and the appearance of the plague is deeper than the body\u2019s skin, it is the plague of leprosy; and the priest shall examine him, and pronounce him unclean.",
                "If the bright spot is white in the skin of his body, and its appearance isn\u2019t deeper than the skin, and its hair hasn\u2019t turned white, then the priest shall isolate the infected person for seven days.",
                "The priest shall examine him on the seventh day, and, behold, if in his eyes the plague is arrested, and the plague hasn\u2019t spread in the skin, then the priest shall isolate him for seven more days.",
                "The priest shall examine him again on the seventh day; and behold, if the plague has faded, and the plague hasn\u2019t spread in the skin, then the priest shall pronounce him clean. It is a scab. He shall wash his clothes, and be clean.",
                "But if the scab spreads on the skin, after he has shown himself to the priest for his cleansing, he shall show himself to the priest again.",
                "The priest shall examine him; and behold, if the scab has spread on the skin, then the priest shall pronounce him unclean. It is leprosy.",
                "\"When the plague of leprosy is in a man, then he shall be brought to the priest;",
                "and the priest shall examine him. Behold, if there is a white rising in the skin, and it has turned the hair white, and there is raw flesh in the rising,",
                "it is a chronic leprosy in the skin of his body, and the priest shall pronounce him unclean. He shall not isolate him, for he is unclean.",
                "\"If the leprosy breaks out all over the skin, and the leprosy covers all the skin of the infected person from his head even to his feet, as far as it appears to the priest;",
                "then the priest shall examine him; and, behold, if the leprosy has covered all his flesh, he shall pronounce him clean of the plague. It has all turned white: he is clean.",
                "But whenever raw flesh appears in him, he shall be unclean.",
                "The priest shall examine the raw flesh, and pronounce him unclean: the raw flesh is unclean. It is leprosy.",
                "Or if the raw flesh turns again, and is changed to white, then he shall come to the priest;",
                "and the priest shall examine him; and, behold, if the plague has turned white, then the priest shall pronounce him clean of the plague. He is clean.",
                "\"When the body has a boil on its skin, and it has healed,",
                "and in the place of the boil there is a white rising, or a bright spot, reddish-white, then it shall be shown to the priest;",
                "and the priest shall examine it; and behold, if its appearance is lower than the skin, and its hair has turned white, then the priest shall pronounce him unclean. It is the plague of leprosy. It has broken out in the boil.",
                "But if the priest examines it, and behold, there are no white hairs in it, and it isn\u2019t deeper than the skin, but is dim, then the priest shall isolate him seven days.",
                "If it spreads in the skin, then the priest shall pronounce him unclean. It is a plague.",
                "But if the bright spot stays in its place, and hasn\u2019t spread, it is the scar from the boil; and the priest shall pronounce him clean.",
                "\"Or when the body has a burn from fire on its skin, and the raw flesh of the burn becomes a bright spot, reddish-white, or white,",
                "then the priest shall examine it; and behold, if the hair in the bright spot has turned white, and its appearance is deeper than the skin; it is leprosy. It has broken out in the burning, and the priest shall pronounce him unclean. It is the plague of leprosy.",
                "But if the priest examines it, and behold, there is no white hair in the bright spot, and it isn\u2019t lower than the skin, but is faded; then the priest shall isolate him seven days.",
                "The priest shall examine him on the seventh day. If it has spread in the skin, then the priest shall pronounce him unclean. It is the plague of leprosy.",
                "If the bright spot stays in its place, and hasn\u2019t spread in the skin, but is faded, it is the swelling from the burn, and the priest shall pronounce him clean; for it is the scar from the burn.",
                "\"When a man or woman has a plague on the head or on the beard,",
                "then the priest shall examine the plague; and behold, if its appearance is deeper than the skin, and the hair in it is yellow and thin, then the priest shall pronounce him unclean: it is an itch, it is leprosy of the head or of the beard.",
                "If the priest examines the plague of itching, and behold, its appearance isn\u2019t deeper than the skin, and there is no black hair in it, then the priest shall isolate him the person infected with itching seven days.",
                "On the seventh day the priest shall examine the plague; and behold, if the itch hasn\u2019t spread, and there is no yellow hair in it, and the appearance of the itch isn\u2019t deeper than the skin,",
                "then he shall be shaved, but he shall not shave the itch; and the priest shall shut him up who has the itch seven more days.",
                "On the seventh day, the priest shall examine the itch; and behold, if the itch hasn\u2019t spread in the skin, and its appearance isn\u2019t deeper than the skin, then the priest shall pronounce him clean. He shall wash his clothes, and be clean.",
                "But if the itch spreads in the skin after his cleansing,",
                "then the priest shall examine him; and behold, if the itch has spread in the skin, the priest shall not look for the yellow hair; he is unclean.",
                "But if in his eyes the itch is arrested, and black hair has grown in it; the itch is healed, he is clean. The priest shall pronounce him clean.",
                "\"When a man or a woman has bright spots in the skin of the body, even white bright spots;",
                "then the priest shall examine them; and behold, if the bright spots on the skin of their body are a dull white, it is a harmless rash, it has broken out in the skin; he is clean.",
                "\"If a man\u2019s hair has fallen from his head, he is bald. He is clean.",
                "If his hair has fallen off from the front part of his head, he is forehead bald. He is clean.",
                "But if there is in the bald head, or the bald forehead, a reddish-white plague; it is leprosy breaking out in his bald head, or his bald forehead.",
                "Then the priest shall examine him; and, behold, if the rising of the plague is reddish-white in his bald head, or in his bald forehead, like the appearance of leprosy in the skin of the flesh,",
                "he is a leprous man. He is unclean. The priest shall surely pronounce him unclean. His plague is on his head.",
                "\"The leper in whom the plague is shall wear torn clothes, and the hair of his head shall hang loose. He shall cover his upper lip, and shall cry, \u2018Unclean! Unclean!\u2019",
                "All the days in which the plague is in him he shall be unclean. He is unclean. He shall dwell alone. Outside of the camp shall be his dwelling.",
                "\"The garment also that the plague of leprosy is in, whether it is a woolen garment, or a linen garment;",
                "whether it is in warp, or woof; of linen, or of wool; whether in a skin, or in anything made of skin;",
                "if the plague is greenish or reddish in the garment, or in the skin, or in the warp, or in the woof, or in anything made of skin; it is the plague of leprosy, and shall be shown to the priest.",
                "The priest shall examine the plague, and isolate the plague seven days.",
                "He shall examine the plague on the seventh day. If the plague has spread in the garment, either in the warp, or in the woof, or in the skin, whatever use the skin is used for, the plague is a destructive mildew. It is unclean.",
                "He shall burn the garment, whether the warp or the woof, in wool or in linen, or anything of skin, in which the plague is: for it is a destructive mildew. It shall be burned in the fire.",
                "\"If the priest examines it, and behold, the plague hasn\u2019t spread in the garment, either in the warp, or in the woof, or in anything of skin;",
                "then the priest shall command that they wash the thing in which the plague is, and he shall isolate it seven more days.",
                "Then the priest shall examine it, after the plague is washed; and behold, if the plague hasn\u2019t changed its color, and the plague hasn\u2019t spread, it is unclean; you shall burn it in the fire. It is a mildewed spot, whether the bareness is inside or outside.",
                "If the priest looks, and behold, the plague has faded after it is washed, then he shall tear it out of the garment, or out of the skin, or out of the warp, or out of the woof:",
                "and if it appears again in the garment, either in the warp, or in the woof, or in anything of skin, it is spreading. You shall burn with fire that in which the plague is.",
                "The garment, either the warp, or the woof, or whatever thing of skin it is, which you shall wash, if the plague has departed from them, then it shall be washed the second time, and it will be clean.\"",
                "This is the law of the plague of mildew in a garment of wool or linen, either in the warp, or the woof, or in anything of skin, to pronounce it clean, or to pronounce it unclean."
            ],
            14: [
                "Yahweh spoke to Moses, saying,",
                "\"This shall be the law of the leper in the day of his cleansing. He shall be brought to the priest,",
                "and the priest shall go forth out of the camp. The priest shall examine him, and behold, if the plague of leprosy is healed in the leper,",
                "then the priest shall command them to take for him who is to be cleansed two living clean birds, and cedar wood, and scarlet, and hyssop.",
                "The priest shall command them to kill one of the birds in an earthen vessel over running water.",
                "As for the living bird, he shall take it, and the cedar wood, and the scarlet, and the hyssop, and shall dip them and the living bird in the blood of the bird that was killed over the running water.",
                "He shall sprinkle on him who is to be cleansed from the leprosy seven times, and shall pronounce him clean, and shall let the living bird go into the open field.",
                "\"He who is to be cleansed shall wash his clothes, and shave off all his hair, and bathe himself in water; and he shall be clean. After that he shall come into the camp, but shall dwell outside his tent seven days.",
                "It shall be on the seventh day, that he shall shave all his hair off his head and his beard and his eyebrows, even all his hair he shall shave off. He shall wash his clothes, and he shall bathe his body in water, then he shall be clean.",
                "\"On the eighth day he shall take two male lambs without blemish, and one ewe lamb a year old without blemish, and three tenths of an ephah of fine flour for a meal offering, mingled with oil, and one log of oil.",
                "The priest who cleanses him shall set the man who is to be cleansed, and those things, before Yahweh, at the door of the Tent of Meeting.",
                "\"The priest shall take one of the male lambs, and offer him for a trespass offering, with the log of oil, and wave them for a wave offering before Yahweh.",
                "He shall kill the male lamb in the place where they kill the sin offering and the burnt offering, in the place of the sanctuary; for as the sin offering is the priest\u2019s, so is the trespass offering. It is most holy.",
                "The priest shall take some of the blood of the trespass offering, and the priest shall put it on the tip of the right ear of him who is to be cleansed, and on the thumb of his right hand, and on the big toe of his right foot.",
                "The priest shall take some of the log of oil, and pour it into the palm of his own left hand.",
                "The priest shall dip his right finger in the oil that is in his left hand, and shall sprinkle some of the oil with his finger seven times before Yahweh.",
                "The priest shall put some of the rest of the oil that is in his hand on the tip of the right ear of him who is to be cleansed, and on the thumb of his right hand, and on the big toe of his right foot, upon the blood of the trespass offering.",
                "The rest of the oil that is in the priest\u2019s hand he shall put on the head of him who is to be cleansed, and the priest shall make atonement for him before Yahweh.",
                "\"The priest shall offer the sin offering, and make atonement for him who is to be cleansed because of his uncleanness: and afterward he shall kill the burnt offering;",
                "and the priest shall offer the burnt offering and the meal offering on the altar. The priest shall make atonement for him, and he shall be clean.",
                "\"If he is poor, and can\u2019t afford so much, then he shall take one male lamb for a trespass offering to be waved, to make atonement for him, and one tenth of an ephah of fine flour mingled with oil for a meal offering, and a log of oil;",
                "and two turtledoves, or two young pigeons, such as he is able to afford; and the one shall be a sin offering, and the other a burnt offering.",
                "\"On the eighth day he shall bring them for his cleansing to the priest, to the door of the Tent of Meeting, before Yahweh.",
                "The priest shall take the lamb of the trespass offering, and the log of oil, and the priest shall wave them for a wave offering before Yahweh.",
                "He shall kill the lamb of the trespass offering. The priest shall take some of the blood of the trespass offering and put it on the tip of the right ear of him who is to be cleansed, and on the thumb of his right hand, and on the big toe of his right foot.",
                "The priest shall pour some of the oil into the palm of his own left hand;",
                "and the priest shall sprinkle with his right finger some of the oil that is in his left hand seven times before Yahweh.",
                "Then the priest shall put some of the oil that is in his hand on the tip of the right ear of him who is to be cleansed, and on the thumb of his right hand, and on the big toe of his right foot, on the place of the blood of the trespass offering.",
                "The rest of the oil that is in the priest\u2019s hand he shall put on the head of him who is to be cleansed, to make atonement for him before Yahweh.",
                "He shall offer one of the turtledoves, or of the young pigeons, such as he is able to afford,",
                "even such as he is able to afford, the one for a sin offering, and the other for a burnt offering, with the meal offering. The priest shall make atonement for him who is to be cleansed before Yahweh.\"",
                "This is the law for him in whom is the plague of leprosy, who is not able to afford the sacrifice for his cleansing.",
                "Yahweh spoke to Moses and to Aaron, saying,",
                "\"When you have come into the land of Canaan, which I give to you for a possession, and I put a spreading mildew in a house in the land of your possession,",
                "then he who owns the house shall come and tell the priest, saying, \u2018There seems to me to be some sort of plague in the house.\u2019",
                "The priest shall command that they empty the house, before the priest goes in to examine the plague, that all that is in the house not be made unclean: and afterward the priest shall go in to inspect the house.",
                "He shall examine the plague; and behold, if the plague is in the walls of the house with hollow streaks, greenish or reddish, and it appears to be deeper than the wall;",
                "then the priest shall go out of the house to the door of the house, and shut up the house seven days.",
                "The priest shall come again on the seventh day, and look. If the plague has spread in the walls of the house,",
                "then the priest shall command that they take out the stones in which is the plague, and cast them into an unclean place outside of the city:",
                "and he shall cause the inside of the house to be scraped all over, and they shall pour out the mortar, that they scraped off, outside of the city into an unclean place.",
                "They shall take other stones, and put them in the place of those stones; and he shall take other mortar, and shall plaster the house.",
                "\"If the plague comes again, and breaks out in the house, after he has taken out the stones, and after he has scraped the house, and after it was plastered;",
                "then the priest shall come in and look; and behold, if the plague has spread in the house, it is a destructive mildew in the house. It is unclean.",
                "He shall break down the house, its stones, and its timber, and all the house\u2019s mortar. He shall carry them out of the city into an unclean place.",
                "\"Moreover he who goes into the house while it is shut up shall be unclean until the evening.",
                "He who lies down in the house shall wash his clothes; and he who eats in the house shall wash his clothes.",
                "\"If the priest shall come in, and examine it, and behold, the plague hasn\u2019t spread in the house, after the house was plastered, then the priest shall pronounce the house clean, because the plague is healed.",
                "To cleanse the house he shall take two birds, and cedar wood, and scarlet, and hyssop.",
                "He shall kill one of the birds in an earthen vessel over running water.",
                "He shall take the cedar wood, and the hyssop, and the scarlet, and the living bird, and dip them in the blood of the slain bird, and in the running water, and sprinkle the house seven times.",
                "He shall cleanse the house with the blood of the bird, and with the running water, with the living bird, with the cedar wood, with the hyssop, and with the scarlet;",
                "but he shall let the living bird go out of the city into the open field. So shall he make atonement for the house; and it shall be clean.\"",
                "This is the law for any plague of leprosy, and for an itch,",
                "and for the destructive mildew of a garment, and for a house,",
                "and for a rising, and for a scab, and for a bright spot;",
                "to teach when it is unclean, and when it is clean. This is the law of leprosy."
            ],
            15: [
                "Yahweh spoke to Moses and to Aaron, saying,",
                "\"Speak to the children of Israel, and tell them, \u2018When any man has a discharge from his body, because of his discharge he is unclean.",
                "This shall be his uncleanness in his discharge: whether his body runs with his discharge, or his body has stopped from his discharge, it is his uncleanness.",
                "\"\u2018Every bed whereon he who has the discharge lies shall be unclean; and everything he sits on shall be unclean.",
                "Whoever touches his bed shall wash his clothes, and bathe himself in water, and be unclean until the evening.",
                "He who sits on anything whereon the man who has the discharge sat shall wash his clothes, and bathe himself in water, and be unclean until the evening.",
                "\"\u2018He who touches the body of him who has the discharge shall wash his clothes, and bathe himself in water, and be unclean until the evening.",
                "\"\u2018If he who has the discharge spits on him who is clean, then he shall wash his clothes, and bathe himself in water, and be unclean until the evening.",
                "\"\u2018Whatever saddle he who has the discharge rides on shall be unclean.",
                "Whoever touches anything that was under him shall be unclean until the evening. He who carries those things shall wash his clothes, and bathe himself in water, and be unclean until the evening.",
                "\"\u2018Whoever he who has the discharge touches, without having rinsed his hands in water, he shall wash his clothes, and bathe himself in water, and be unclean until the evening.",
                "\"\u2018The earthen vessel, which he who has the discharge touches, shall be broken; and every vessel of wood shall be rinsed in water.",
                "\"\u2018When he who has a discharge is cleansed of his discharge, then he shall count to himself seven days for his cleansing, and wash his clothes; and he shall bathe his flesh in running water, and shall be clean.",
                "\"\u2018On the eighth day he shall take two turtledoves, or two young pigeons, and come before Yahweh to the door of the Tent of Meeting, and give them to the priest:",
                "and the priest shall offer them, the one for a sin offering, and the other for a burnt offering. The priest shall make atonement for him before Yahweh for his discharge.",
                "\"\u2018If any man has an emission of semen, then he shall bathe all his flesh in water, and be unclean until the evening.",
                "Every garment, and every skin, whereon the semen is, shall be washed with water, and be unclean until the evening.",
                "If a man lies with a woman and there is an emission of semen, they shall both bathe themselves in water, and be unclean until the evening.",
                "\"\u2018If a woman has a discharge, and her discharge in her flesh is blood, she shall be in her impurity seven days: and whoever touches her shall be unclean until the evening.",
                "\"\u2018Everything that she lies on in her impurity shall be unclean. Everything also that she sits on shall be unclean.",
                "Whoever touches her bed shall wash his clothes, and bathe himself in water, and be unclean until the evening.",
                "Whoever touches anything that she sits on shall wash his clothes, and bathe himself in water, and be unclean until the evening.",
                "If it is on the bed, or on anything whereon she sits, when he touches it, he shall be unclean until the evening.",
                "\"\u2018If any man lies with her, and her monthly flow is on him, he shall be unclean seven days; and every bed whereon he lies shall be unclean.",
                "\"\u2018If a woman has a discharge of her blood many days not in the time of her period, or if she has a discharge beyond the time of her period; all the days of the discharge of her uncleanness shall be as in the days of her period: she is unclean.",
                "Every bed whereon she lies all the days of her discharge shall be to her as the bed of her period: and everything whereon she sits shall be unclean, as the uncleanness of her period.",
                "Whoever touches these things shall be unclean, and shall wash his clothes, and bathe himself in water, and be unclean until the evening.",
                "\"\u2018But if she is cleansed of her discharge, then she shall count to herself seven days, and after that she shall be clean.",
                "On the eighth day she shall take two turtledoves, or two young pigeons, and bring them to the priest, to the door of the Tent of Meeting.",
                "The priest shall offer the one for a sin offering, and the other for a burnt offering; and the priest shall make atonement for her before Yahweh for the uncleanness of her discharge.",
                "\"\u2018Thus you shall separate the children of Israel from their uncleanness, so they will not die in their uncleanness, when they defile my tabernacle that is in their midst.\u2019\"",
                "This is the law of him who has a discharge, and of him who has an emission of semen, so that he is unclean thereby;",
                "and of her who has her period, and of a man or woman who has a discharge, and of him who lies with her who is unclean."
            ],
            16: [
                "Yahweh spoke to Moses, after the death of the two sons of Aaron, when they drew near before Yahweh, and died;",
                "and Yahweh said to Moses, \"Tell Aaron your brother, not to come at all times into the Most Holy Place within the veil, before the mercy seat which is on the ark; lest he die: for I will appear in the cloud on the mercy seat.",
                "\"Herewith shall Aaron come into the sanctuary: with a young bull for a sin offering, and a ram for a burnt offering.",
                "He shall put on the holy linen coat, and he shall have the linen breeches on his body, and shall put on the linen sash, and he shall be clothed with the linen turban. They are the holy garments. He shall bathe his body in water, and put them on.",
                "He shall take from the congregation of the children of Israel two male goats for a sin offering, and one ram for a burnt offering.",
                "\"Aaron shall offer the bull of the sin offering, which is for himself, and make atonement for himself and for his house.",
                "He shall take the two goats, and set them before Yahweh at the door of the Tent of Meeting.",
                "Aaron shall cast lots for the two goats; one lot for Yahweh, and the other lot for the scapegoat.",
                "Aaron shall present the goat on which the lot fell for Yahweh, and offer him for a sin offering.",
                "But the goat, on which the lot fell for the scapegoat, shall be presented alive before Yahweh, to make atonement for him, to send him away for the scapegoat into the wilderness.",
                "\"Aaron shall present the bull of the sin offering, which is for himself, and shall make atonement for himself and for his house, and shall kill the bull of the sin offering which is for himself.",
                "He shall take a censer full of coals of fire from off the altar before Yahweh, and two handfuls of sweet incense beaten small, and bring it within the veil:",
                "and he shall put the incense on the fire before Yahweh, that the cloud of the incense may cover the mercy seat that is on the testimony, so that he will not die.",
                "He shall take some of the blood of the bull, and sprinkle it with his finger on the mercy seat on the east; and before the mercy seat he shall sprinkle some of the blood with his finger seven times.",
                "\"Then he shall kill the goat of the sin offering, that is for the people, and bring his blood within the veil, and do with his blood as he did with the blood of the bull, and sprinkle it on the mercy seat, and before the mercy seat:",
                "and he shall make atonement for the Holy Place, because of the uncleanness of the children of Israel, and because of their transgressions, even all their sins; and so he shall do for the Tent of Meeting, that dwells with them in the midst of their uncleanness.",
                "No one shall be in the Tent of Meeting when he enters to make atonement in the Holy Place, until he comes out, and has made atonement for himself and for his household, and for all the assembly of Israel.",
                "\"He shall go out to the altar that is before Yahweh and make atonement for it, and shall take some of the bull\u2019s blood, and some of the goat\u2019s blood, and put it around on the horns of the altar.",
                "He shall sprinkle some of the blood on it with his finger seven times, and cleanse it, and make it holy from the uncleanness of the children of Israel.",
                "\"When he has made an end of atoning for the Holy Place, the Tent of Meeting, and the altar, he shall present the live goat.",
                "Aaron shall lay both his hands on the head of the live goat, and confess over him all the iniquities of the children of Israel, and all their transgressions, even all their sins; and he shall put them on the head of the goat, and shall send him away into the wilderness by the hand of a man who is in readiness.",
                "The goat shall carry all their iniquities on himself to a solitary land, and he shall let the goat go in the wilderness.",
                "\"Aaron shall come into the Tent of Meeting, and shall take off the linen garments, which he put on when he went into the Holy Place, and shall leave them there.",
                "Then he shall bathe himself in water in a holy place, and put on his garments, and come out and offer his burnt offering and the burnt offering of the people, and make atonement for himself and for the people.",
                "The fat of the sin offering he shall burn on the altar.",
                "\"He who lets the goat go for the scapegoat shall wash his clothes, and bathe his flesh in water, and afterward he shall come into the camp.",
                "The bull for the sin offering, and the goat for the sin offering, whose blood was brought in to make atonement in the Holy Place, shall be carried forth outside the camp; and they shall burn their skins, their flesh, and their dung with fire.",
                "He who burns them shall wash his clothes, and bathe his flesh in water, and afterward he shall come into the camp.",
                "\"It shall be a statute to you forever: in the seventh month, on the tenth day of the month, you shall afflict your souls, and shall do no kind of work, the native-born, or the stranger who lives as a foreigner among you:",
                "for on this day shall atonement be made for you, to cleanse you; from all your sins you shall be clean before Yahweh.",
                "It is a Sabbath of solemn rest to you, and you shall afflict your souls; it is a statute forever.",
                "The priest, who is anointed and who is consecrated to be priest in his father\u2019s place, shall make the atonement, and shall put on the linen garments, even the holy garments.",
                "Then he shall make atonement for the Holy Sanctuary; and he shall make atonement for the Tent of Meeting and for the altar; and he shall make atonement for the priests and for all the people of the assembly.",
                "\"This shall be an everlasting statute for you, to make atonement for the children of Israel once in the year because of all their sins.\" It was done as Yahweh commanded Moses."
            ],
            17: [
                "Yahweh spoke to Moses, saying,",
                "\"Speak to Aaron, and to his sons, and to all the children of Israel, and say to them: \u2018This is the thing which Yahweh has commanded,",
                "Whatever man there is of the house of Israel, who kills a bull, or lamb, or goat, in the camp, or who kills it outside the camp,",
                "and hasn\u2019t brought it to the door of the Tent of Meeting, to offer it as an offering to Yahweh before the tabernacle of Yahweh: blood shall be imputed to that man. He has shed blood; and that man shall be cut off from among his people.",
                "This is to the end that the children of Israel may bring their sacrifices, which they sacrifice in the open field, that they may bring them to Yahweh, to the door of the Tent of Meeting, to the priest, and sacrifice them for sacrifices of peace offerings to Yahweh.",
                "The priest shall sprinkle the blood on the altar of Yahweh at the door of the Tent of Meeting, and burn the fat for a pleasant aroma to Yahweh.",
                "They shall no more sacrifice their sacrifices to the goat idols, after which they play the prostitute. This shall be a statute forever to them throughout their generations.\u2019",
                "\"You shall say to them, \u2018Any man there is of the house of Israel, or of the strangers who live as foreigners among them, who offers a burnt offering or sacrifice,",
                "and doesn\u2019t bring it to the door of the Tent of Meeting, to sacrifice it to Yahweh; that man shall be cut off from his people.",
                "\"\u2018Any man of the house of Israel, or of the strangers who live as foreigners among them, who eats any kind of blood, I will set my face against that soul who eats blood, and will cut him off from among his people.",
                "For the life of the flesh is in the blood; and I have given it to you on the altar to make atonement for your souls: for it is the blood that makes atonement by reason of the life.",
                "Therefore I have said to the children of Israel, \"No person among you shall eat blood, neither shall any stranger who lives as a foreigner among you eat blood.\"",
                "\"\u2018Whatever man there is of the children of Israel, or of the strangers who live as foreigners among them, who takes in hunting any animal or bird that may be eaten; he shall pour out its blood, and cover it with dust.",
                "For as to the life of all flesh, its blood is with its life: therefore I said to the children of Israel, \"You shall not eat the blood of any kind of flesh; for the life of all flesh is its blood. Whoever eats it shall be cut off.\"",
                "\"\u2018Every person that eats what dies of itself, or that which is torn by animals, whether he is native-born or a foreigner, he shall wash his clothes, and bathe himself in water, and be unclean until the evening: then he shall be clean.",
                "But if he doesn\u2019t wash them, or bathe his flesh, then he shall bear his iniquity.\u2019\""
            ],
            18: [
                "Yahweh said to Moses,",
                "\"Speak to the children of Israel, and say to them, \u2018I am Yahweh your God.",
                "You shall not do as they do in the land of Egypt, where you lived: and you shall not do as they do in the land of Canaan, where I am bringing you; neither shall you walk in their statutes.",
                "You shall do my ordinances, and you shall keep my statutes, and walk in them: I am Yahweh your God.",
                "You shall therefore keep my statutes and my ordinances; which if a man does, he shall live in them: I am Yahweh.",
                "\"\u2018None of you shall approach anyone who are his close relatives, to uncover their nakedness: I am Yahweh.",
                "\"\u2018You shall not uncover the nakedness of your father, nor the nakedness of your mother: she is your mother. You shall not uncover her nakedness.",
                "\"\u2018You shall not uncover the nakedness of your father\u2019s wife: it is your father\u2019s nakedness.",
                "\"\u2018You shall not uncover the nakedness of your sister, the daughter of your father, or the daughter of your mother, whether born at home, or born abroad.",
                "\"\u2018You shall not uncover the nakedness of your son\u2019s daughter, or of your daughter\u2019s daughter, even their nakedness: for theirs is your own nakedness.",
                "\"\u2018You shall not uncover the nakedness of your father\u2019s wife\u2019s daughter, conceived by your father, since she is your sister.",
                "\"\u2018You shall not uncover the nakedness of your father\u2019s sister: she is your father\u2019s near kinswoman.",
                "\"\u2018You shall not uncover the nakedness of your mother\u2019s sister: for she is your mother\u2019s near kinswoman.",
                "\"\u2018You shall not uncover the nakedness of your father\u2019s brother, you shall not approach his wife: she is your aunt.",
                "\"\u2018You shall not uncover the nakedness of your daughter-in-law: she is your son\u2019s wife. You shall not uncover her nakedness.",
                "\"\u2018You shall not uncover the nakedness of your brother\u2019s wife: it is your brother\u2019s nakedness.",
                "\"\u2018You shall not uncover the nakedness of a woman and her daughter. You shall not take her son\u2019s daughter, or her daughter\u2019s daughter, to uncover her nakedness; they are near kinswomen: it is wickedness.",
                "\"\u2018You shall not take a wife to her sister, to be a rival, to uncover her nakedness, while her sister is yet alive.",
                "\"\u2018You shall not approach a woman to uncover her nakedness, as long as she is impure by her uncleanness.",
                "\"\u2018You shall not lie carnally with your neighbor\u2019s wife, and defile yourself with her.",
                "\"\u2018You shall not give any of your children to sacrifice to Molech; neither shall you profane the name of your God: I am Yahweh.",
                "\"\u2018You shall not lie with a man, as with a woman. That is detestable.",
                "\"\u2018You shall not lie with any animal to defile yourself with it; neither shall any woman give herself to an animal, to lie down with it: it is a perversion.",
                "\"\u2018Don\u2019t defile yourselves in any of these things: for in all these the nations which I am casting out before you were defiled.",
                "The land was defiled: therefore I punished its iniquity, and the land vomited out her inhabitants.",
                "You therefore shall keep my statutes and my ordinances, and shall not do any of these abominations; neither the native-born, nor the stranger who lives as a foreigner among you;",
                "(for all these abominations have the men of the land done, that were before you, and the land became defiled);",
                "that the land not vomit you out also, when you defile it, as it vomited out the nation that was before you.",
                "\"\u2018For whoever shall do any of these abominations, even the souls that do them shall be cut off from among their people.",
                "Therefore you shall keep my requirements, that you do not practice any of these abominable customs, which were practiced before you, and that you do not defile yourselves with them: I am Yahweh your God.\u2019\""
            ],
            19: [
                "Yahweh spoke to Moses, saying,",
                "\"Speak to all the congregation of the children of Israel, and tell them, \u2018You shall be holy; for I Yahweh your God am holy.",
                "\"\u2018Each one of you shall respect his mother and his father. You shall keep my Sabbaths. I am Yahweh your God.",
                "\"\u2018Don\u2019t turn to idols, nor make molten gods for yourselves. I am Yahweh your God.",
                "\"\u2018When you offer a sacrifice of peace offerings to Yahweh, you shall offer it so that you may be accepted.",
                "It shall be eaten the same day you offer it, and on the next day: and if anything remains until the third day, it shall be burned with fire.",
                "If it is eaten at all on the third day, it is an abomination. It will not be accepted;",
                "but everyone who eats it shall bear his iniquity, because he has profaned the holy thing of Yahweh, and that soul shall be cut off from his people.",
                "\"\u2018When you reap the harvest of your land, you shall not wholly reap the corners of your field, neither shall you gather the gleanings of your harvest.",
                "You shall not glean your vineyard, neither shall you gather the fallen grapes of your vineyard; you shall leave them for the poor and for the foreigner. I am Yahweh your God.",
                "\"\u2018You shall not steal. \"\u2018You shall not lie. \"\u2018You shall not deceive one another.",
                "\"\u2018You shall not swear by my name falsely, and profane the name of your God. I am Yahweh.",
                "\"\u2018You shall not oppress your neighbor, nor rob him. \"\u2018The wages of a hired servant shall not remain with you all night until the morning.",
                "\"\u2018You shall not curse the deaf, nor put a stumbling block before the blind; but you shall fear your God. I am Yahweh.",
                "\"\u2018You shall do no injustice in judgment: you shall not be partial to the poor, nor show favoritism to the great; but you shall judge your neighbor in righteousness.",
                "\"\u2018You shall not go up and down as a slanderer among your people. \"\u2018You shall not endanger the life of your neighbor. I am Yahweh.",
                "\"\u2018You shall not hate your brother in your heart. You shall surely rebuke your neighbor, and not bear sin because of him.",
                "\"\u2018You shall not take vengeance, nor bear any grudge against the children of your people; but you shall love your neighbor as yourself. I am Yahweh.",
                "\"\u2018You shall keep my statutes. \"\u2018You shall not crossbreed different kinds of animals. \"\u2018you shall not sow your field with two kinds of seed; \"\u2018neither shall there come upon on you a garment made of two kinds of material.",
                "\"\u2018If a man lies carnally with a woman who is a slave girl, pledged to be married to another man, and not ransomed, or given her freedom; they shall be punished. They shall not be put to death, because she was not free.",
                "He shall bring his trespass offering to Yahweh, to the door of the Tent of Meeting, even a ram for a trespass offering.",
                "The priest shall make atonement for him with the ram of the trespass offering before Yahweh for his sin which he has committed: and the sin which he has committed shall be forgiven him.",
                "\"\u2018When you come into the land, and have planted all kinds of trees for food, then you shall count their fruit as forbidden. Three years shall they be forbidden to you. It shall not be eaten.",
                "But in the fourth year all its fruit shall be holy, for giving praise to Yahweh.",
                "In the fifth year you shall eat its fruit, that it may yield its increase to you. I am Yahweh your God.",
                "\"\u2018You shall not eat any meat with the blood still in it; neither shall you use enchantments, nor practice sorcery.",
                "\"\u2018You shall not cut the hair on the sides of your heads, neither shall you clip off the edge of your beard.",
                "\"\u2018You shall not make any cuttings in your flesh for the dead, nor tattoo any marks on you. I am Yahweh.",
                "\"\u2018Don\u2019t profane your daughter, to make her a prostitute; lest the land fall to prostitution, and the land become full of wickedness.",
                "\"\u2018You shall keep my Sabbaths, and reverence my sanctuary; I am Yahweh.",
                "\"\u2018Don\u2019t turn to those who are mediums, nor to the wizards. Don\u2019t seek them out, to be defiled by them. I am Yahweh your God.",
                "\"\u2018You shall rise up before the gray head, and honor the face of an old man, and you shall fear your God. I am Yahweh.",
                "\"\u2018If a stranger lives as a foreigner with you in your land, you shall not do him wrong.",
                "The stranger who lives as a foreigner with you shall be to you as the native-born among you, and you shall love him as yourself; for you lived as foreigners in the land of Egypt. I am Yahweh your God.",
                "\"\u2018You shall do no unrighteousness in judgment, in measures of length, of weight, or of quantity.",
                "You shall have just balances, just weights, a just ephah, and a just hin. I am Yahweh your God, who brought you out of the land of Egypt.",
                "\"\u2018You shall observe all my statutes, and all my ordinances, and do them. I am Yahweh.\u2019\""
            ],
            20: [
                "Yahweh spoke to Moses, saying,",
                "\"Moreover, you shall tell the children of Israel, \u2018Anyone of the children of Israel, or of the strangers who live as foreigners in Israel, who gives any of his seed to Molech; he shall surely be put to death. The people of the land shall stone him with stones.",
                "I also will set my face against that person, and will cut him off from among his people because he has given of his seed to Molech, to defile my sanctuary, and to profane my holy name.",
                "If the people of the land all hide their eyes from that person, when he gives of his seed to Molech, and don\u2019t put him to death;",
                "then I will set my face against that man, and against his family, and will cut him off, and all who play the prostitute after him, to play the prostitute with Molech, from among their people.",
                "\"\u2018The person that turns to those who are mediums, and to the wizards, to play the prostitute after them, I will even set my face against that person, and will cut him off from among his people.",
                "\"\u2018Sanctify yourselves therefore, and be holy; for I am Yahweh your God.",
                "You shall keep my statutes, and do them. I am Yahweh who sanctifies you.",
                "\"\u2018For everyone who curses his father or his mother shall surely be put to death: he has cursed his father or his mother; his blood shall be upon him.",
                "\"\u2018The man who commits adultery with another man\u2019s wife, even he who commits adultery with his neighbor\u2019s wife, the adulterer and the adulteress shall surely be put to death.",
                "\"\u2018The man who lies with his father\u2019s wife has uncovered his father\u2019s nakedness: both of them shall surely be put to death; their blood shall be upon them.",
                "\"\u2018If a man lies with his daughter-in-law, both of them shall surely be put to death: they have committed a perversion; their blood shall be upon them.",
                "\"\u2018If a man lies with a male, as with a woman, both of them have committed an abomination: they shall surely be put to death; their blood shall be upon them.",
                "\"\u2018If a man takes a wife and her mother, it is wickedness: they shall be burned with fire, both he and they; that there may be no wickedness among you.",
                "\"\u2018If a man lies with an animal, he shall surely be put to death; and you shall kill the animal.",
                "\"\u2018If a woman approaches any animal, and lies down with it, you shall kill the woman, and the animal: they shall surely be put to death; their blood shall be upon them.",
                "\"\u2018If a man takes his sister, his father\u2019s daughter, or his mother\u2019s daughter, and sees her nakedness, and she sees his nakedness; it is a shameful thing; and they shall be cut off in the sight of the children of their people: he has uncovered his sister\u2019s nakedness; he shall bear his iniquity.",
                "\"\u2018If a man lies with a woman having her monthly period, and uncovers her nakedness; he has made naked her fountain, and she has uncovered the fountain of her blood: and both of them shall be cut off from among their people.",
                "\"\u2018You shall not uncover the nakedness of your mother\u2019s sister, nor of your father\u2019s sister; for he has made naked his close relative: they shall bear their iniquity.",
                "If a man lies with his uncle\u2019s wife, he has uncovered his uncle\u2019s nakedness: they shall bear their sin; they shall die childless.",
                "\"\u2018If a man takes his brother\u2019s wife, it is an impurity: he has uncovered his brother\u2019s nakedness; they shall be childless.",
                "\"\u2018You shall therefore keep all my statutes, and all my ordinances, and do them; that the land, where I am bringing you to dwell, may not vomit you out.",
                "You shall not walk in the customs of the nation, which I am casting out before you: for they did all these things, and therefore I abhorred them.",
                "But I have said to you, \"You shall inherit their land, and I will give it to you to possess it, a land flowing with milk and honey.\" I am Yahweh your God, who has separated you from the peoples.",
                "\"\u2018You shall therefore make a distinction between the clean animal and the unclean, and between the unclean fowl and the clean: and you shall not make yourselves abominable by animal, or by bird, or by anything with which the ground teems, which I have separated from you as unclean for you.",
                "You shall be holy to me: for I, Yahweh, am holy, and have set you apart from the peoples, that you should be mine.",
                "\"\u2018A man or a woman that is a medium, or is a wizard, shall surely be put to death: they shall stone them with stones; their blood shall be upon them.\u2019\""
            ],
            21: [
                "Yahweh said to Moses, \"Speak to the priests, the sons of Aaron, and say to them, \u2018A priest shall not defile himself for the dead among his people;",
                "except for his relatives that are near to him: for his mother, for his father, for his son, for his daughter, for his brother,",
                "and for his virgin sister who is near to him, who has had no husband; for her he may defile himself.",
                "He shall not defile himself, being a chief man among his people, to profane himself.",
                "\"\u2018They shall not shave their heads, neither shall they shave off the corners of their beards, nor make any cuttings in their flesh.",
                "They shall be holy to their God, and not profane the name of their God; for they offer the offerings of Yahweh made by fire, the bread of their God; therefore they shall be holy.",
                "\"\u2018They shall not marry a woman who is a prostitute, or profane; neither shall they marry a woman divorced from her husband: for he is holy to his God.",
                "You shall sanctify him therefore; for he offers the bread of your God: he shall be holy to you: for I Yahweh, who sanctify you, am holy.",
                "\"\u2018The daughter of any priest, if she profanes herself by playing the prostitute, she profanes her father: she shall be burned with fire.",
                "\"\u2018He who is the high priest among his brothers, upon whose head the anointing oil is poured, and that is consecrated to put on the garments, shall not let the hair of his head hang loose, nor tear his clothes;",
                "neither shall he go in to any dead body, nor defile himself for his father, or for his mother;",
                "neither shall he go out of the sanctuary, nor profane the sanctuary of his God; for the crown of the anointing oil of his God is upon him. I am Yahweh.",
                "\"\u2018He shall take a wife in her virginity.",
                "A widow, or one divorced, or a woman who has been defiled, or a prostitute, these he shall not marry: but a virgin of his own people shall he take as a wife.",
                "He shall not profane his seed among his people: for I am Yahweh who sanctifies him.\u2019\"",
                "Yahweh spoke to Moses, saying,",
                "\"Say to Aaron, \u2018None of your seed throughout their generations who has a blemish, may approach to offer the bread of his God.",
                "For whatever man he is that has a blemish, he shall not draw near: a blind man, or a lame, or he who has a flat nose, or any deformity,",
                "or a man who has an injured foot, or an injured hand,",
                "or hunchbacked, or a dwarf, or one who has a defect in his eye, or an itching disease, or scabs, or who has damaged testicles;",
                "no man of the seed of Aaron the priest who has a blemish, shall come near to offer the offerings of Yahweh made by fire. Since he has a blemish, he shall not come near to offer the bread of his God.",
                "He shall eat the bread of his God, both of the most holy, and of the holy.",
                "He shall not come near to the veil, nor come near to the altar, because he has a blemish; that he may not profane my sanctuaries, for I am Yahweh who sanctifies them.\u2019\"",
                "So Moses spoke to Aaron, and to his sons, and to all the children of Israel."
            ],
            22: [
                "Yahweh spoke to Moses, saying,",
                "\"Tell Aaron and his sons to separate themselves from the holy things of the children of Israel, which they make holy to me, and that they not profane my holy name. I am Yahweh.",
                "\"Tell them, \u2018If anyone of all your seed throughout your generations approaches the holy things, which the children of Israel make holy to Yahweh, having his uncleanness on him, that soul shall be cut off from before me. I am Yahweh.",
                "\"\u2018Whoever of the seed of Aaron is a leper or has an issue; he shall not eat of the holy things, until he is clean. Whoever touches anything that is unclean by the dead, or a man whose seed goes from him;",
                "or whoever touches any creeping thing, whereby he may be made unclean, or a man of whom he may take uncleanness, whatever uncleanness he has;",
                "the person that touches any such shall be unclean until the evening, and shall not eat of the holy things, unless he bathe his body in water.",
                "When the sun is down, he shall be clean; and afterward he shall eat of the holy things, because it is his bread.",
                "That which dies of itself, or is torn by animals, he shall not eat, defiling himself by it. I am Yahweh.",
                "\"\u2018They shall therefore follow my requirements, lest they bear sin for it, and die therein, if they profane it. I am Yahweh who sanctifies them.",
                "\"\u2018No stranger shall eat of the holy thing: a foreigner living with the priests, or a hired servant, shall not eat of the holy thing.",
                "But if a priest buys a slave, purchased by his money, he shall eat of it; and such as are born in his house, they shall eat of his bread.",
                "If a priest\u2019s daughter is married to an outsider, she shall not eat of the heave offering of the holy things.",
                "But if a priest\u2019s daughter is a widow, or divorced, and has no child, and has returned to her father\u2019s house, as in her youth, she may eat of her father\u2019s bread: but no stranger shall eat any of it.",
                "\"\u2018If a man eats something holy unwittingly, then he shall add the fifth part of its value to it, and shall give the holy thing to the priest.",
                "The priests shall not profane the holy things of the children of Israel, which they offer to Yahweh,",
                "and so cause them to bear the iniquity that brings guilt, when they eat their holy things: for I am Yahweh who sanctifies them.\u2019\"",
                "Yahweh spoke to Moses, saying,",
                "\"Speak to Aaron, and to his sons, and to all the children of Israel, and say to them, \u2018Whoever is of the house of Israel, or of the foreigners in Israel, who offers his offering, whether it be any of their vows, or any of their freewill offerings, which they offer to Yahweh for a burnt offering;",
                "that you may be accepted, you shall offer a male without blemish, of the bulls, of the sheep, or of the goats.",
                "But whatever has a blemish, that you shall not offer: for it shall not be acceptable for you.",
                "Whoever offers a sacrifice of peace offerings to Yahweh to accomplish a vow, or for a freewill offering, of the herd or of the flock, it shall be perfect to be accepted; no blemish shall be therein.",
                "Blind, injured, maimed, having a wart, festering, or having a running sore, you shall not offer these to Yahweh, nor make an offering by fire of them on the altar to Yahweh.",
                "Either a bull or a lamb that has any deformity or lacking in his parts, that you may offer for a freewill offering; but for a vow it shall not be accepted.",
                "That which has its testicles bruised, crushed, broken, or cut, you shall not offer to Yahweh; neither shall you do thus in your land.",
                "Neither shall you offer the bread of your God from the hand of a foreigner of any of these; because their corruption is in them. There is a blemish in them. They shall not be accepted for you.\u2019\"",
                "Yahweh spoke to Moses, saying,",
                "\"When a bull, or a sheep, or a goat, is born, then it shall remain seven days with its mother; and from the eighth day and thenceforth it shall be accepted for the offering of an offering made by fire to Yahweh.",
                "Whether it is a cow or ewe, you shall not kill it and its young both in one day.",
                "\"When you sacrifice a sacrifice of thanksgiving to Yahweh, you shall sacrifice it so that you may be accepted.",
                "It shall be eaten on the same day; you shall leave none of it until the morning. I am Yahweh.",
                "\"Therefore you shall keep my commandments, and do them. I am Yahweh.",
                "You shall not profane my holy name, but I will be made holy among the children of Israel. I am Yahweh who makes you holy,",
                "who brought you out of the land of Egypt, to be your God. I am Yahweh.\""
            ],
            23: [
                "Yahweh spoke to Moses, saying,",
                "\"Speak to the children of Israel, and tell them, \u2018The set feasts of Yahweh, which you shall proclaim to be holy convocations, even these are my set feasts.",
                "\"\u2018Six days shall work be done: but on the seventh day is a Sabbath of solemn rest, a holy convocation; you shall do no kind of work. It is a Sabbath to Yahweh in all your dwellings.",
                "\"\u2018These are the set feasts of Yahweh, even holy convocations, which you shall proclaim in their appointed season.",
                "In the first month, on the fourteenth day of the month in the evening, is Yahweh\u2019s Passover.",
                "On the fifteenth day of the same month is the feast of unleavened bread to Yahweh. Seven days you shall eat unleavened bread.",
                "In the first day you shall have a holy convocation. You shall do no regular work.",
                "But you shall offer an offering made by fire to Yahweh seven days. In the seventh day is a holy convocation: you shall do no regular work.\u2019\"",
                "Yahweh spoke to Moses, saying,",
                "\"Speak to the children of Israel, and tell them, \u2018When you have come into the land which I give to you, and shall reap its the harvest, then you shall bring the sheaf of the first fruits of your harvest to the priest:",
                "and he shall wave the sheaf before Yahweh, to be accepted for you. On the next day after the Sabbath the priest shall wave it.",
                "On the day when you wave the sheaf, you shall offer a male lamb without blemish a year old for a burnt offering to Yahweh.",
                "The meal offering with it shall be two tenth parts of an ephah of fine flour mingled with oil, an offering made by fire to Yahweh for a pleasant aroma; and the drink offering with it shall be of wine, the fourth part of a hin.",
                "You shall eat neither bread, nor roasted grain, nor fresh grain, until this same day, until you have brought the offering of your God. This is a statute forever throughout your generations in all your dwellings.",
                "\"\u2018You shall count from the next day after the Sabbath, from the day that you brought the sheaf of the wave offering; seven Sabbaths shall be completed:",
                "even to the next day after the seventh Sabbath you shall number fifty days; and you shall offer a new meal offering to Yahweh.",
                "You shall bring out of your habitations two loaves of bread for a wave offering made of two tenth parts of an ephah of fine flour. They shall be baked with yeast, for first fruits to Yahweh.",
                "You shall present with the bread seven lambs without blemish a year old, one young bull, and two rams. They shall be a burnt offering to Yahweh, with their meal offering, and their drink offerings, even an offering made by fire, of a sweet aroma to Yahweh.",
                "You shall offer one male goat for a sin offering, and two male lambs a year old for a sacrifice of peace offerings.",
                "The priest shall wave them with the bread of the first fruits for a wave offering before Yahweh, with the two lambs. They shall be holy to Yahweh for the priest.",
                "You shall make proclamation on the same day: there shall be a holy convocation to you; you shall do no regular work. This is a statute forever in all your dwellings throughout your generations.",
                "\"\u2018When you reap the harvest of your land, you shall not wholly reap into the corners of your field, neither shall you gather the gleanings of your harvest: you shall leave them for the poor, and for the foreigner. I am Yahweh your God.\u2019\"",
                "Yahweh spoke to Moses, saying,",
                "\"Speak to the children of Israel, saying, \u2018In the seventh month, on the first day of the month, shall be a solemn rest to you, a memorial of blowing of trumpets, a holy convocation.",
                "You shall do no regular work; and you shall offer an offering made by fire to Yahweh.\u2019\"",
                "Yahweh spoke to Moses, saying,",
                "\"However on the tenth day of this seventh month is the day of atonement: it shall be a holy convocation to you, and you shall afflict yourselves; and you shall offer an offering made by fire to Yahweh.",
                "You shall do no kind of work in that same day; for it is a day of atonement, to make atonement for you before Yahweh your God.",
                "For whoever it is who shall not deny himself in that same day; shall be cut off from his people.",
                "Whoever it is who does any kind of work in that same day, that person I will destroy from among his people.",
                "You shall do no kind of work: it is a statute forever throughout your generations in all your dwellings.",
                "It shall be a Sabbath of solemn rest for you, and you shall deny yourselves. In the ninth day of the month at evening, from evening to evening, you shall keep your Sabbath.\"",
                "Yahweh spoke to Moses, saying,",
                "\"Speak to the children of Israel, and say, \u2018On the fifteenth day of this seventh month is the feast of tents for seven days to Yahweh.",
                "On the first day shall be a holy convocation: you shall do no regular work.",
                "Seven days you shall offer an offering made by fire to Yahweh. On the eighth day shall be a holy convocation to you; and you shall offer an offering made by fire to Yahweh. It is a solemn assembly; you shall do no regular work.",
                "\"\u2018These are the appointed feasts of Yahweh, which you shall proclaim to be holy convocations, to offer an offering made by fire to Yahweh, a burnt offering, and a meal offering, a sacrifice, and drink offerings, each on its own day;",
                "besides the Sabbaths of Yahweh, and besides your gifts, and besides all your vows, and besides all your freewill offerings, which you give to Yahweh.",
                "\"\u2018So on the fifteenth day of the seventh month, when you have gathered in the fruits of the land, you shall keep the feast of Yahweh seven days: on the first day shall be a solemn rest, and on the eighth day shall be a solemn rest.",
                "You shall take on the first day the fruit of goodly trees, branches of palm trees, and boughs of thick trees, and willows of the brook; and you shall rejoice before Yahweh your God seven days.",
                "You shall keep it a feast to Yahweh seven days in the year: it is a statute forever throughout your generations; you shall keep it in the seventh month.",
                "You shall dwell in booths seven days. All who are native-born in Israel shall dwell in booths,",
                "that your generations may know that I made the children of Israel to dwell in booths, when I brought them out of the land of Egypt. I am Yahweh your God.\u2019\"",
                "Moses declared to the children of Israel the appointed feasts of Yahweh."
            ],
            24: [
                "Yahweh spoke to Moses, saying,",
                "\"Command the children of Israel, that they bring to you pure olive oil beaten for the light, to cause a lamp to burn continually.",
                "Outside of the veil of the Testimony, in the Tent of Meeting, shall Aaron keep it in order from evening to morning before Yahweh continually: it shall be a statute forever throughout your generations.",
                "He shall keep in order the lamps on the pure gold lampstand before Yahweh continually.",
                "\"You shall take fine flour, and bake twelve cakes of it: two tenth parts of an ephah shall be in one cake.",
                "You shall set them in two rows, six on a row, on the pure gold table before Yahweh.",
                "You shall put pure frankincense on each row, that it may be to the bread for a memorial, even an offering made by fire to Yahweh.",
                "Every Sabbath day he shall set it in order before Yahweh continually. It is on the behalf of the children of Israel an everlasting covenant.",
                "It shall be for Aaron and his sons; and they shall eat it in a holy place: for it is most holy to him of the offerings of Yahweh made by fire by a perpetual statute.\"",
                "The son of an Israelite woman, whose father was an Egyptian, went out among the children of Israel; and the son of the Israelite woman and a man of Israel strove together in the camp.",
                "The son of the Israelite woman blasphemed the Name, and cursed; and they brought him to Moses. His mother\u2019s name was Shelomith, the daughter of Dibri, of the tribe of Dan.",
                "They put him in custody, until the will of Yahweh should be declared to them.",
                "Yahweh spoke to Moses, saying,",
                "\"Bring out of the camp him who cursed; and let all who heard him lay their hands on his head, and let all the congregation stone him.",
                "You shall speak to the children of Israel, saying, \u2018Whoever curses his God shall bear his sin.",
                "He who blasphemes the name of Yahweh, he shall surely be put to death; all the congregation shall certainly stone him: the foreigner as well as the native-born, when he blasphemes the Name, shall be put to death.",
                "\"\u2018He who strikes any man mortally shall surely be put to death.",
                "He who strikes an animal mortally shall make it good, life for life.",
                "If anyone injures his neighbor; as he has done, so shall it be done to him:",
                "fracture for fracture, eye for eye, tooth for tooth; as he has injured someone, so shall it be done to him.",
                "He who kills an animal shall make it good; and he who kills a man shall be put to death.",
                "You shall have one kind of law, for the foreigner as well as the native-born: for I am Yahweh your God.\u2019\"",
                "Moses spoke to the children of Israel; and they brought out him who had cursed out of the camp, and stoned him with stones. The children of Israel did as Yahweh commanded Moses."
            ],
            25: [
                "Yahweh said to Moses in Mount Sinai,",
                "\"Speak to the children of Israel, and tell them, \u2018When you come into the land which I give you, then the land shall keep a Sabbath to Yahweh.",
                "Six years you shall sow your field, and six years you shall prune your vineyard, and gather in its fruits;",
                "but in the seventh year there shall be a Sabbath of solemn rest for the land, a Sabbath to Yahweh. You shall not sow your field or prune your vineyard.",
                "What grows of itself in your harvest you shall not reap, and the grapes of your undressed vine you shall not gather. It shall be a year of solemn rest for the land.",
                "The Sabbath of the land shall be for food for you; for yourself, for your servant, for your maid, for your hired servant, and for your stranger, who lives as a foreigner with you.",
                "For your livestock also, and for the animals that are in your land, shall all its increase be for food.",
                "\"\u2018You shall count off seven Sabbaths of years, seven times seven years; and there shall be to you the days of seven Sabbaths of years, even forty-nine years.",
                "Then you shall sound the loud trumpet on the tenth day of the seventh month. On the Day of Atonement you shall sound the trumpet throughout all your land.",
                "You shall make the fiftieth year holy, and proclaim liberty throughout the land to all its inhabitants. It shall be a jubilee to you; and each of you shall return to his own property, and each of you shall return to his family.",
                "That fiftieth year shall be a jubilee to you. In it you shall not sow, neither reap that which grows of itself, nor gather from the undressed vines.",
                "For it is a jubilee; it shall be holy to you. You shall eat of its increase out of the field.",
                "\"\u2018In this Year of Jubilee each of you shall return to his property.",
                "\"\u2018If you sell anything to your neighbor, or buy from your neighbor, you shall not wrong one another.",
                "According to the number of years after the Jubilee you shall buy from your neighbor. According to the number of years of the crops he shall sell to you.",
                "According to the length of the years you shall increase its price, and according to the shortness of the years you shall diminish its price; for he is selling the number of the crops to you.",
                "You shall not wrong one another; but you shall fear your God: for I am Yahweh your God.",
                "\"\u2018Therefore you shall do my statutes, and keep my ordinances and do them; and you shall dwell in the land in safety.",
                "The land shall yield its fruit, and you shall eat your fill, and dwell therein in safety.",
                "If you said, \"What shall we eat the seventh year? Behold, we shall not sow, nor gather in our increase;\"",
                "then I will command my blessing on you in the sixth year, and it shall bring forth fruit for the three years.",
                "You shall sow the eighth year, and eat of the fruits, the old store; until the ninth year, until its fruits come in, you shall eat the old store.",
                "\"\u2018The land shall not be sold in perpetuity, for the land is mine; for you are strangers and live as foreigners with me.",
                "In all the land of your possession you shall grant a redemption for the land.",
                "\"\u2018If your brother becomes poor, and sells some of his possessions, then his kinsman who is next to him shall come, and redeem that which his brother has sold.",
                "If a man has no one to redeem it, and he becomes prosperous and finds sufficient means to redeem it;",
                "then let him reckon the years since its sale, and restore the surplus to the man to whom he sold it; and he shall return to his property.",
                "But if he isn\u2019t able to get it back for himself, then what he has sold shall remain in the hand of him who has bought it until the Year of Jubilee: and in the Jubilee it shall be released, and he shall return to his property.",
                "\"\u2018If a man sells a dwelling house in a walled city, then he may redeem it within a whole year after it has been sold. For a full year he shall have the right of redemption.",
                "If it isn\u2019t redeemed within the space of a full year, then the house that is in the walled city shall be made sure in perpetuity to him who bought it, throughout his generations. It shall not be released in the Jubilee.",
                "But the houses of the villages which have no wall around them shall be reckoned with the fields of the country: they may be redeemed, and they shall be released in the Jubilee.",
                "\"\u2018Nevertheless the cities of the Levites, the houses in the cities of their possession, the Levites may redeem at any time.",
                "The Levites may redeem the house that was sold, and the city of his possession, and it shall be released in the Jubilee; for the houses of the cities of the Levites are their possession among the children of Israel.",
                "But the field of the suburbs of their cities may not be sold; for it is their perpetual possession.",
                "\"\u2018If your brother has become poor, and his hand can\u2019t support him among you; then you shall uphold him. He shall live with you like an alien and a temporary resident.",
                "Take no interest from him or profit, but fear your God; that your brother may live among you.",
                "You shall not lend him your money at interest, nor give him your food for profit.",
                "I am Yahweh your God, who brought you forth out of the land of Egypt, to give you the land of Canaan, and to be your God.",
                "\"\u2018If your brother has grown poor among you, and sells himself to you; you shall not make him to serve as a slave.",
                "As a hired servant, and as a temporary resident, he shall be with you; he shall serve with you until the Year of Jubilee:",
                "then he shall go out from you, he and his children with him, and shall return to his own family, and to the possession of his fathers.",
                "For they are my servants, whom I brought out of the land of Egypt. They shall not be sold as slaves.",
                "You shall not rule over him with harshness, but shall fear your God.",
                "\"\u2018As for your male and your female slaves, whom you may have; of the nations that are around you, from them you may buy male and female slaves.",
                "Moreover of the children of the aliens who live among you, of them you may buy, and of their families who are with you, which they have conceived in your land; and they will be your property.",
                "You may make them an inheritance for your children after you, to hold for a possession; of them may you take your slaves forever: but over your brothers the children of Israel you shall not rule, one over another, with harshness.",
                "\"\u2018If an alien or temporary resident with you becomes rich, and your brother beside him has grown poor, and sells himself to the stranger or foreigner living among you, or to a member of the stranger\u2019s family;",
                "after he is sold he may be redeemed. One of his brothers may redeem him;",
                "or his uncle, or his uncle\u2019s son, may redeem him, or any who is a close relative to him of his family may redeem him; or if he has grown rich, he may redeem himself.",
                "He shall reckon with him who bought him from the year that he sold himself to him to the Year of Jubilee: and the price of his sale shall be according to the number of years; according to the time of a hired servant shall he be with him.",
                "If there are yet many years, according to them he shall give back the price of his redemption out of the money that he was bought for.",
                "If there remain but a few years to the year of jubilee, then he shall reckon with him; according to his years of service he shall give back the price of his redemption.",
                "As a servant hired year by year shall he be with him: he shall not rule with harshness over him in your sight.",
                "If he isn\u2019t redeemed by these means, then he shall be released in the Year of Jubilee, he, and his children with him.",
                "For to me the children of Israel are servants; they are my servants whom I brought out of the land of Egypt. I am Yahweh your God."
            ],
            26: [
                "\"\u2018You shall make for yourselves no idols, neither shall you raise up an engraved image or a pillar, neither shall you place any figured stone in your land, to bow down to it: for I am Yahweh your God.",
                "\"\u2018You shall keep my Sabbaths, and have reverence for my sanctuary. I am Yahweh.",
                "\"\u2018If you walk in my statutes, and keep my commandments, and do them;",
                "then I will give you your rains in their season, and the land shall yield its increase, and the trees of the field shall yield their fruit.",
                "Your threshing shall reach to the vintage, and the vintage shall reach to the sowing time; and you shall eat your bread to the full, and dwell in your land safely.",
                "\"\u2018I will give peace in the land, and you shall lie down, and no one will make you afraid; and I will remove evil animals out of the land, neither shall the sword go through your land.",
                "You shall chase your enemies, and they shall fall before you by the sword.",
                "Five of you shall chase a hundred, and a hundred of you shall chase ten thousand; and your enemies shall fall before you by the sword.",
                "\"\u2018I will have respect for you, and make you fruitful, and multiply you, and will establish my covenant with you.",
                "You shall eat old store long kept, and you shall move out the old because of the new.",
                "I will set my tent among you: and my soul won\u2019t abhor you.",
                "I will walk among you, and will be your God, and you will be my people.",
                "I am Yahweh your God, who brought you forth out of the land of Egypt, that you should not be their slaves; and I have broken the bars of your yoke, and made you go upright.",
                "\"\u2018But if you will not listen to me, and will not do all these commandments;",
                "and if you shall reject my statutes, and if your soul abhors my ordinances, so that you will not do all my commandments, but break my covenant;",
                "I also will do this to you: I will appoint terror over you, even consumption and fever, that shall consume the eyes, and make the soul to pine away; and you will sow your seed in vain, for your enemies will eat it.",
                "I will set my face against you, and you will be struck before your enemies. Those who hate you will rule over you; and you will flee when no one pursues you.",
                "\"\u2018If you in spite of these things will not listen to me, then I will chastise you seven times more for your sins.",
                "I will break the pride of your power, and I will make your sky like iron, and your soil like brass;",
                "and your strength will be spent in vain; for your land won\u2019t yield its increase, neither will the trees of the land yield their fruit.",
                "\"\u2018If you walk contrary to me, and won\u2019t listen to me, then I will bring seven times more plagues on you according to your sins.",
                "I will send the wild animals among you, which will rob you of your children, destroy your livestock, and make you few in number; and your roads will become desolate.",
                "\"\u2018If by these things you won\u2019t be reformed to me, but will walk contrary to me;",
                "then I will also walk contrary to you; and I will strike you, even I, seven times for your sins.",
                "I will bring a sword upon you, that will execute the vengeance of the covenant; and you will be gathered together within your cities: and I will send the pestilence among you; and you will be delivered into the hand of the enemy.",
                "When I break your staff of bread, ten women shall bake your bread in one oven, and they shall deliver your bread again by weight: and you shall eat, and not be satisfied.",
                "\"\u2018If you in spite of this won\u2019t listen to me, but walk contrary to me;",
                "then I will walk contrary to you in wrath; and I also will chastise you seven times for your sins.",
                "You will eat the flesh of your sons, and you will eat the flesh of your daughters.",
                "I will destroy your high places, and cut down your incense altars, and cast your dead bodies upon the bodies of your idols; and my soul will abhor you.",
                "I will lay your cities waste, and will bring your sanctuaries to desolation, and I will not take delight in the sweet fragrance of your offerings.",
                "I will bring the land into desolation; and your enemies that dwell therein will be astonished at it.",
                "I will scatter you among the nations, and I will draw out the sword after you: and your land will be a desolation, and your cities shall be a waste.",
                "Then the land will enjoy its sabbaths as long as it lies desolate and you are in your enemies\u2019 land. Even then the land will rest and enjoy its sabbaths.",
                "As long as it lies desolate it shall have rest, even the rest which it didn\u2019t have in your sabbaths, when you lived on it.",
                "\"\u2018As for those of you who are left, I will send a faintness into their hearts in the lands of their enemies: and the sound of a driven leaf will put them to flight; and they shall flee, as one flees from the sword; and they will fall when no one pursues.",
                "They will stumble over one another, as it were before the sword, when no one pursues: and you will have no power to stand before your enemies.",
                "You will perish among the nations, and the land of your enemies will eat you up.",
                "Those of you who are left will pine away in their iniquity in your enemies\u2019 lands; and also in the iniquities of their fathers shall they pine away with them.",
                "\"\u2018If they confess their iniquity, and the iniquity of their fathers, in their trespass which they trespassed against me, and also that, because they walked contrary to me,",
                "I also walked contrary to them, and brought them into the land of their enemies: if then their uncircumcised heart is humbled, and they then accept the punishment of their iniquity;",
                "then I will remember my covenant with Jacob; and also my covenant with Isaac, and also my covenant with Abraham; and I will remember the land.",
                "The land also will be left by them, and will enjoy its sabbaths while it lies desolate without them: and they will accept the punishment of their iniquity; because, even because they rejected my ordinances, and their soul abhorred my statutes.",
                "Yet for all that, when they are in the land of their enemies, I will not reject them, neither will I abhor them, to destroy them utterly, and to break my covenant with them; for I am Yahweh their God;",
                "but I will for their sake remember the covenant of their ancestors, whom I brought out of the land of Egypt in the sight of the nations, that I might be their God. I am Yahweh.\u2019\"",
                "These are the statutes, ordinances and laws, which Yahweh made between him and the children of Israel in Mount Sinai by Moses."
            ],
            27: [
                "Yahweh spoke to Moses, saying,",
                "\"Speak to the children of Israel, and say to them, \u2018When a man makes a vow, the persons shall be for Yahweh by your valuation.",
                "Your valuation shall be of a male from twenty years old even to sixty years old, even your valuation shall be fifty shekels of silver, after the shekel of the sanctuary.",
                "If it is a female, then your valuation shall be thirty shekels.",
                "If the person is from five years old even to twenty years old, then your valuation shall be for a male twenty shekels, and for a female ten shekels.",
                "If the person is from a month old even to five years old, then your valuation shall be for a male five shekels of silver, and for a female your valuation shall be three shekels of silver.",
                "If the person is from sixty years old and upward; if it is a male, then your valuation shall be fifteen shekels, and for a female ten shekels.",
                "But if he is poorer than your valuation, then he shall be set before the priest, and the priest shall value him; according to the ability of him who vowed shall the priest value him.",
                "\"\u2018If it is an animal, of which men offer an offering to Yahweh, all that any man gives of such to Yahweh becomes holy.",
                "He shall not alter it, nor change it, a good for a bad, or a bad for a good: and if he shall at all change animal for animal, then both it and that for which it is changed shall be holy.",
                "If it is any unclean animal, of which they do not offer as an offering to Yahweh, then he shall set the animal before the priest;",
                "and the priest shall value it, whether it is good or bad. As you the priest values it, so shall it be.",
                "But if he will indeed redeem it, then he shall add the fifth part of it to its valuation.",
                "\"\u2018When a man dedicates his house to be holy to Yahweh, then the priest shall evaluate it, whether it is good or bad: as the priest shall evaluate it, so shall it stand.",
                "If he who dedicates it will redeem his house, then he shall add the fifth part of the money of your valuation to it, and it shall be his.",
                "\"\u2018If a man dedicates to Yahweh part of the field of his possession, then your valuation shall be according to the seed for it: the sowing of a homer of barley shall be valued at fifty shekels of silver.",
                "If he dedicates his field from the Year of Jubilee, according to your valuation it shall stand.",
                "But if he dedicates his field after the Jubilee, then the priest shall reckon to him the money according to the years that remain to the Year of Jubilee; and an abatement shall be made from your valuation.",
                "If he who dedicated the field will indeed redeem it, then he shall add the fifth part of the money of your valuation to it, and it shall remain his.",
                "If he will not redeem the field, or if he has sold the field to another man, it shall not be redeemed any more;",
                "but the field, when it goes out in the Jubilee, shall be holy to Yahweh, as a field devoted; it shall be owned by the priests.",
                "\"\u2018If he dedicates to Yahweh a field which he has bought, which is not of the field of his possession,",
                "then the priest shall reckon to him the worth of your valuation up to the Year of Jubilee; and he shall give your valuation on that day, as a holy thing to Yahweh.",
                "In the Year of Jubilee the field shall return to him from whom it was bought, even to him to whom the possession of the land belongs.",
                "All your valuations shall be according to the shekel of the sanctuary: twenty gerahs to the shekel.",
                "\"\u2018Only the firstborn among animals, which is made a firstborn to Yahweh, no man may dedicate it; whether an ox or sheep, it is Yahweh\u2019s.",
                "If it is an unclean animal, then he shall buy it back according to your valuation, and shall add to it the fifth part of it: or if it isn\u2019t redeemed, then it shall be sold according to your valuation.",
                "\"\u2018Notwithstanding, no devoted thing, that a man shall devote to Yahweh of all that he has, whether of man or animal, or of the field of his possession, shall be sold or redeemed: every devoted thing is most holy to Yahweh.",
                "\"\u2018No one devoted, who shall be devoted from among men, shall be ransomed; he shall surely be put to death.",
                "\"\u2018All the tithe of the land, whether of the seed of the land or of the fruit of the trees, is Yahweh\u2019s. It is holy to Yahweh.",
                "If a man redeems anything of his tithe, he shall add a fifth part to it.",
                "All the tithe of the herds or the flocks, whatever passes under the rod, the tenth shall be holy to Yahweh.",
                "He shall not search whether it is good or bad, neither shall he change it: and if he changes it at all, then both it and that for which it is changed shall be holy. It shall not be redeemed.\u2019\"",
                "These are the commandments which Yahweh commanded Moses for the children of Israel on Mount Sinai."
            ]
        },
        4: {
            1: [
                "Yahweh spoke to Moses in the wilderness of Sinai, in the Tent of Meeting, on the first day of the second month, in the second year after they had come out of the land of Egypt, saying,",
                "\"Take a census of all the congregation of the children of Israel, by their families, by their fathers\u2019 houses, according to the number of the names, every male, one by one;",
                "from twenty years old and upward, all who are able to go out to war in Israel. You and Aaron shall number them by their divisions.",
                "With you there shall be a man of every tribe; everyone head of his fathers\u2019 house.",
                "These are the names of the men who shall stand with you: Of Reuben: Elizur the son of Shedeur.",
                "Of Simeon: Shelumiel the son of Zurishaddai.",
                "Of Judah: Nahshon the son of Amminadab.",
                "Of Issachar: Nethanel the son of Zuar.",
                "Of Zebulun: Eliab the son of Helon.",
                "Of the children of Joseph: Of Ephraim: Elishama the son of Ammihud. Of Manasseh: Gamaliel the son of Pedahzur.",
                "Of Benjamin: Abidan the son of Gideoni.",
                "Of Dan: Ahiezer the son of Ammishaddai.",
                "Of Asher: Pagiel the son of Ochran.",
                "Of Gad: Eliasaph the son of Deuel.",
                "Of Naphtali: Ahira the son of Enan.\"",
                "These are those who were called of the congregation, the princes of the tribes of their fathers; they were the heads of the thousands of Israel.",
                "Moses and Aaron took these men who are mentioned by name.",
                "They assembled all the congregation together on the first day of the second month; and they declared their ancestry by their families, by their fathers\u2019 houses, according to the number of the names, from twenty years old and upward, one by one.",
                "As Yahweh commanded Moses, so he numbered them in the wilderness of Sinai.",
                "The children of Reuben, Israel\u2019s firstborn, their generations, by their families, by their fathers\u2019 houses, according to the number of the names, one by one, every male from twenty years old and upward, all who were able to go out to war;",
                "those who were numbered of them, of the tribe of Reuben, were forty-six thousand five hundred.",
                "Of the children of Simeon, their generations, by their families, by their fathers\u2019 houses, those who were numbered of it, according to the number of the names, one by one, every male from twenty years old and upward, all who were able to go out to war;",
                "those who were numbered of them, of the tribe of Simeon, were fifty-nine thousand three hundred.",
                "Of the children of Gad, their generations, by their families, by their fathers\u2019 houses, according to the number of the names, from twenty years old and upward, all who were able to go out to war;",
                "those who were numbered of them, of the tribe of Gad, were forty-five thousand six hundred fifty.",
                "Of the children of Judah, their generations, by their families, by their fathers\u2019 houses, according to the number of the names, from twenty years old and upward, all who were able to go out to war;",
                "those who were numbered of them, of the tribe of Judah, were sixty-four thousand six hundred.",
                "Of the children of Issachar, their generations, by their families, by their fathers\u2019 houses, according to the number of the names, from twenty years old and upward, all who were able to go out to war;",
                "those who were numbered of them, of the tribe of Issachar, were fifty-four thousand four hundred.",
                "Of the children of Zebulun, their generations, by their families, by their fathers\u2019 houses, according to the number of the names, from twenty years old and upward, all who were able to go out to war;",
                "those who were numbered of them, of the tribe of Zebulun, were fifty-seven thousand four hundred.",
                "Of the children of Joseph, of the children of Ephraim, their generations, by their families, by their fathers\u2019 houses, according to the number of the names, from twenty years old and upward, all who were able to go out to war;",
                "those who were numbered of them, of the tribe of Ephraim, were forty thousand five hundred.",
                "Of the children of Manasseh, their generations, by their families, by their fathers\u2019 houses, according to the number of the names, from twenty years old and upward, all who were able to go out to war;",
                "those who were numbered of them, of the tribe of Manasseh, were thirty-two thousand two hundred.",
                "Of the children of Benjamin, their generations, by their families, by their fathers\u2019 houses, according to the number of the names, from twenty years old and upward, all who were able to go out to war;",
                "those who were numbered of them, of the tribe of Benjamin, were thirty-five thousand four hundred.",
                "Of the children of Dan, their generations, by their families, by their fathers\u2019 houses, according to the number of the names, from twenty years old and upward, all who were able to go forth to war;",
                "those who were numbered of them, of the tribe of Dan, were sixty-two thousand seven hundred.",
                "Of the children of Asher, their generations, by their families, by their fathers\u2019 houses, according to the number of the names, from twenty years old and upward, all who were able to go forth to war;",
                "those who were numbered of them, of the tribe of Asher, were forty-one thousand five hundred.",
                "Of the children of Naphtali, their generations, by their families, by their fathers\u2019 houses, according to the number of the names, from twenty years old and upward, all who were able to go forth to war;",
                "those who were numbered of them, of the tribe of Naphtali, were fifty-three thousand four hundred.",
                "These are those who were numbered, whom Moses and Aaron numbered, and the princes of Israel, being twelve men: they were each one for his fathers\u2019 house.",
                "So all those who were numbered of the children of Israel by their fathers\u2019 houses, from twenty years old and upward, all who were able to go out to war in Israel;",
                "even all those who were numbered were six hundred three thousand five hundred fifty.",
                "But the Levites after the tribe of their fathers were not numbered among them.",
                "For Yahweh spoke to Moses, saying,",
                "\"Only the tribe of Levi you shall not number, neither shall you take a census of them among the children of Israel;",
                "but appoint the Levites over the Tabernacle of the Testimony, and over all its furnishings, and over all that belongs to it. They shall carry the tabernacle, and all its furnishings; and they shall take care of it, and shall encamp around it.",
                "When the tabernacle is to move, the Levites shall take it down; and when the tabernacle is to be set up, the Levites shall set it up. The stranger who comes near shall be put to death.",
                "The children of Israel shall pitch their tents, every man by his own camp, and every man by his own standard, according to their divisions.",
                "But the Levites shall encamp around the Tabernacle of the Testimony, that there may be no wrath on the congregation of the children of Israel: and the Levites shall be responsible for the Tabernacle of the Testimony.\"",
                "Thus the children of Israel did. According to all that Yahweh commanded Moses, so they did."
            ],
            2: [
                "Yahweh spoke to Moses and to Aaron, saying,",
                "\"The children of Israel shall encamp every man by his own standard, with the banners of their fathers\u2019 houses: at a distance from the Tent of Meeting shall they encamp around it.\"",
                "Those who encamp on the east side toward the sunrise shall be of the standard of the camp of Judah, according to their divisions: and the prince of the children of Judah shall be Nahshon the son of Amminadab.",
                "His division, and those who were numbered of them, were seventy-four thousand six hundred.",
                "Those who encamp next to him shall be the tribe of Issachar: and the prince of the children of Issachar shall be Nethanel the son of Zuar.",
                "His division, and those who were numbered of it, were fifty-four thousand four hundred.",
                "The tribe of Zebulun: and the prince of the children of Zebulun shall be Eliab the son of Helon.",
                "His division, and those who were numbered of it, were fifty-seven thousand four hundred.",
                "All who were numbered of the camp of Judah were one hundred eighty-six thousand four hundred, according to their divisions. They shall set out first.",
                "\"On the south side shall be the standard of the camp of Reuben according to their divisions. The prince of the children of Reuben shall be Elizur the son of Shedeur.",
                "His division, and those who were numbered of it, were forty-six thousand five hundred.",
                "\"Those who encamp next to him shall be the tribe of Simeon. The prince of the children of Simeon shall be Shelumiel the son of Zurishaddai.",
                "His division, and those who were numbered of them, were fifty-nine thousand three hundred.",
                "\"The tribe of Gad: and the prince of the children of Gad shall be Eliasaph the son of Reuel.",
                "His division, and those who were numbered of them, were forty-five thousand six hundred fifty.",
                "\"All who were numbered of the camp of Reuben were one hundred fifty-one thousand four hundred fifty, according to their armies. They shall set out second.",
                "\"Then the Tent of Meeting shall set out, with the camp of the Levites in the midst of the camps. As they encamp, so shall they set out, every man in his place, by their standards.",
                "\"On the west side shall be the standard of the camp of Ephraim according to their divisions: and the prince of the children of Ephraim shall be Elishama the son of Ammihud.",
                "His division, and those who were numbered of them, were forty thousand five hundred.",
                "\"Next to him shall be the tribe of Manasseh: and the prince of the children of Manasseh shall be Gamaliel the son of Pedahzur.",
                "His division, and those who were numbered of them, were thirty-two thousand two hundred.",
                "\"The tribe of Benjamin: and the prince of the children of Benjamin shall be Abidan the son of Gideoni.",
                "His army, and those who were numbered of them, were thirty-five thousand four hundred.",
                "\"All who were numbered of the camp of Ephraim were one hundred eight thousand one hundred, according to their divisions. They shall set out third.",
                "\"On the north side shall be the standard of the camp of Dan according to their divisions: and the prince of the children of Dan shall be Ahiezer the son of Ammishaddai.",
                "His division, and those who were numbered of them, were sixty-two thousand seven hundred.",
                "\"Those who encamp next to him shall be the tribe of Asher: and the prince of the children of Asher shall be Pagiel the son of Ochran.",
                "His division, and those who were numbered of them, were forty-one thousand and five hundred.",
                "\"The tribe of Naphtali: and the prince of the children of Naphtali shall be Ahira the son of Enan.",
                "His division, and those who were numbered of them, were fifty-three thousand four hundred.",
                "\"All who were numbered of the camp of Dan were one hundred fifty-seven thousand six hundred. They shall set out last by their standards.\"",
                "These are those who were numbered of the children of Israel by their fathers\u2019 houses. All who were numbered of the camps according to their armies were six hundred three thousand five hundred fifty.",
                "But the Levites were not numbered among the children of Israel; as Yahweh commanded Moses.",
                "Thus the children of Israel did. According to all that Yahweh commanded Moses, so they encamped by their standards, and so they set out, everyone by their families, according to their fathers\u2019 houses."
            ],
            3: [
                "Now this is the history of the generations of Aaron and Moses in the day that Yahweh spoke with Moses in Mount Sinai.",
                "These are the names of the sons of Aaron: Nadab the firstborn, and Abihu, Eleazar, and Ithamar.",
                "These are the names of the sons of Aaron, the priests who were anointed, whom he consecrated to minister in the priest\u2019s office.",
                "Nadab and Abihu died before Yahweh, when they offered strange fire before Yahweh, in the wilderness of Sinai, and they had no children. Eleazar and Ithamar ministered in the priest\u2019s office in the presence of Aaron their father.",
                "Yahweh spoke to Moses, saying,",
                "\"Bring the tribe of Levi near, and set them before Aaron the priest, that they may minister to him.",
                "They shall keep his requirements, and the requirements of the whole congregation before the Tent of Meeting, to do the service of the tabernacle.",
                "They shall keep all the furnishings of the Tent of Meeting, and the obligations of the children of Israel, to do the service of the tabernacle.",
                "You shall give the Levites to Aaron and to his sons. They are wholly given to him on the behalf of the children of Israel.",
                "You shall appoint Aaron and his sons, and they shall keep their priesthood. The stranger who comes near shall be put to death.\"",
                "Yahweh spoke to Moses, saying,",
                "\"Behold, I have taken the Levites from among the children of Israel instead of all the firstborn who open the womb among the children of Israel; and the Levites shall be mine:",
                "for all the firstborn are mine. On the day that I struck down all the firstborn in the land of Egypt I made holy to me all the firstborn in Israel, both man and animal. They shall be mine. I am Yahweh.\"",
                "Yahweh spoke to Moses in the wilderness of Sinai, saying,",
                "\"Count the children of Levi by their fathers\u2019 houses, by their families. You shall count every male from a month old and upward.\"",
                "Moses numbered them according to the word of Yahweh, as he was commanded.",
                "These were the sons of Levi by their names: Gershon, and Kohath, and Merari.",
                "These are the names of the sons of Gershon by their families: Libni and Shimei.",
                "The sons of Kohath by their families: Amram, and Izhar, Hebron, and Uzziel.",
                "The sons of Merari by their families: Mahli and Mushi. These are the families of the Levites according to their fathers\u2019 houses.",
                "Of Gershon was the family of the Libnites, and the family of the Shimeites: these are the families of the Gershonites.",
                "Those who were numbered of them, according to the number of all the males, from a month old and upward, even those who were numbered of them were seven thousand five hundred.",
                "The families of the Gershonites shall encamp behind the tabernacle westward.",
                "The prince of the fathers\u2019 house of the Gershonites shall be Eliasaph the son of Lael.",
                "The duty of the sons of Gershon in the Tent of Meeting shall be the tabernacle, and the tent, its covering, and the screen for the door of the Tent of Meeting,",
                "and the hangings of the court, and the screen for the door of the court, which is by the tabernacle, and around the altar, and its cords for all of its service.",
                "Of Kohath was the family of the Amramites, and the family of the Izharites, and the family of the Hebronites, and the family of the Uzzielites: these are the families of the Kohathites.",
                "According to the number of all the males, from a month old and upward, there were eight thousand six hundred, keeping the requirements of the sanctuary.",
                "The families of the sons of Kohath shall encamp on the south side of the tabernacle.",
                "The prince of the fathers\u2019 house of the families of the Kohathites shall be Elizaphan the son of Uzziel.",
                "Their duty shall be the ark, the table, the lamp stand, the altars, the vessels of the sanctuary with which they minister, and the screen, and all its service.",
                "Eleazar the son of Aaron the priest shall be prince of the princes of the Levites, with the oversight of those who keep the requirements of the sanctuary.",
                "Of Merari was the family of the Mahlites, and the family of the Mushites. These are the families of Merari.",
                "Those who were numbered of them, according to the number of all the males, from a month old and upward, were six thousand two hundred.",
                "The prince of the fathers\u2019 house of the families of Merari was Zuriel the son of Abihail. They shall encamp on the north side of the tabernacle.",
                "The appointed duty of the sons of Merari shall be the tabernacle\u2019s boards, its bars, its pillars, its sockets, all its instruments, all its service,",
                "the pillars of the court around it, their sockets, their pins, and their cords.",
                "Those who encamp before the tabernacle eastward, in front of the Tent of Meeting toward the sunrise, shall be Moses, and Aaron and his sons, keeping the requirements of the sanctuary for the duty of the children of Israel. The stranger who comes near shall be put to death.",
                "All who were numbered of the Levites, whom Moses and Aaron numbered at the commandment of Yahweh, by their families, all the males from a month old and upward, were twenty-two thousand.",
                "Yahweh said to Moses, \"Number all the firstborn males of the children of Israel from a month old and upward, and take the number of their names.",
                "You shall take the Levites for me (I am Yahweh) instead of all the firstborn among the children of Israel; and the livestock of the Levites instead of all the firstborn among the livestock of the children of Israel.\"",
                "Moses numbered, as Yahweh commanded him, all the firstborn among the children of Israel.",
                "All the firstborn males according to the number of names, from a month old and upward, of those who were numbered of them, were twenty-two thousand two hundred seventy-three.",
                "Yahweh spoke to Moses, saying,",
                "\"Take the Levites instead of all the firstborn among the children of Israel, and the livestock of the Levites instead of their livestock; and the Levites shall be mine. I am Yahweh.",
                "For the redemption of the two hundred seventy-three of the firstborn of the children of Israel, who exceed the number of the Levites,",
                "you shall take five shekels apiece for each one; after the shekel of the sanctuary you shall take them (the shekel is twenty gerahs):",
                "and you shall give the money, with which their remainder is redeemed, to Aaron and to his sons.\"",
                "Moses took the redemption money from those who exceeded the number of those who were redeemed by the Levites;",
                "from the firstborn of the children of Israel he took the money, one thousand three hundred sixty-five shekels, after the shekel of the sanctuary:",
                "and Moses gave the redemption money to Aaron and to his sons, according to the word of Yahweh, as Yahweh commanded Moses."
            ],
            4: [
                "Yahweh spoke to Moses and to Aaron, saying,",
                "\"Take a census of the sons of Kohath from among the sons of Levi, by their families, by their fathers\u2019 houses,",
                "from thirty years old and upward even until fifty years old, all who enter into the service, to do the work in the Tent of Meeting.",
                "\"This is the service of the sons of Kohath in the Tent of Meeting, the most holy things.",
                "When the camp moves forward, Aaron shall go in, and his sons, and they shall take down the veil of the screen, and cover the ark of the Testimony with it,",
                "and shall put a covering of sealskin on it, and shall spread over it a cloth all of blue, and shall put in its poles.",
                "\"On the table of show bread they shall spread a blue cloth, and put on it the dishes, the spoons, the bowls, and the cups with which to pour out; and the continual bread shall be on it.",
                "They shall spread on them a scarlet cloth, and cover the same with a covering of sealskin, and shall put in its poles.",
                "\"They shall take a blue cloth, and cover the lampstand of the light, and its lamps, and its snuffers, and its snuff dishes, and all its oil vessels, with which they minister to it.",
                "They shall put it and all its vessels within a covering of sealskin, and shall put it on the frame.",
                "\"On the golden altar they shall spread a blue cloth, and cover it with a covering of sealskin, and shall put in its poles.",
                "\"They shall take all the vessels of ministry, with which they minister in the sanctuary, and put them in a blue cloth, and cover them with a covering of sealskin, and shall put them on the frame.",
                "\"They shall take away the ashes from the altar, and spread a purple cloth on it.",
                "They shall put on it all its vessels, with which they minister about it, the fire pans, the flesh hooks, the shovels, and the basins; all the vessels of the altar; and they shall spread on it a covering of sealskin, and put in its poles.",
                "\"When Aaron and his sons have finished covering the sanctuary, and all the furniture of the sanctuary, as the camp moves forward; after that, the sons of Kohath shall come to carry it: but they shall not touch the sanctuary, lest they die. These things are the burden of the sons of Kohath in the Tent of Meeting.",
                "\"The duty of Eleazar the son of Aaron the priest shall be the oil for the light, the sweet incense, the continual meal offering, and the anointing oil, the requirements of all the tabernacle, and of all that is in it, the sanctuary, and its furnishings.\"",
                "Yahweh spoke to Moses and to Aaron, saying,",
                "\"Don\u2019t cut off the tribe of the families of the Kohathites from among the Levites;",
                "but thus do to them, that they may live, and not die, when they approach to the most holy things: Aaron and his sons shall go in, and appoint them everyone to his service and to his burden;",
                "but they shall not go in to see the sanctuary even for a moment, lest they die.\"",
                "Yahweh spoke to Moses, saying,",
                "\"Take a census of the sons of Gershon also, by their fathers\u2019 houses, by their families;",
                "you shall count them from thirty years old and upward until fifty years old; all who enter in to wait on the service, to do the work in the Tent of Meeting.",
                "\"This is the service of the families of the Gershonites, in serving and in bearing burdens:",
                "they shall carry the curtains of the tabernacle, and the Tent of Meeting, its covering, and the covering of sealskin that is above on it, and the screen for the door of the Tent of Meeting,",
                "and the hangings of the court, and the screen for the door of the gate of the court, which is by the tabernacle and around the altar, and their cords, and all the instruments of their service, and whatever shall be done with them. Therein shall they serve.",
                "At the commandment of Aaron and his sons shall be all the service of the sons of the Gershonites, in all their burden, and in all their service; and you shall appoint their duty to them in all their responsibilities.",
                "This is the service of the families of the sons of the Gershonites in the Tent of Meeting: and their duty shall be under the hand of Ithamar the son of Aaron the priest.",
                "\"As for the sons of Merari, you shall number them by their families, by their fathers\u2019 houses;",
                "you shall count them from thirty years old and upward even to fifty years old, everyone who enters on the service, to do the work of the Tent of Meeting.",
                "This is the duty of their burden, according to all their service in the Tent of Meeting: the tabernacle\u2019s boards, its bars, its pillars, its sockets,",
                "and the pillars of the court around it, and their sockets, and their pins, and their cords, with all their instruments, and with all their service: and by name you shall appoint the instruments of the duty of their burden.",
                "This is the service of the families of the sons of Merari, according to all their service, in the Tent of Meeting, under the hand of Ithamar the son of Aaron the priest.\"",
                "Moses and Aaron and the princes of the congregation numbered the sons of the Kohathites by their families, and by their fathers\u2019 houses,",
                "from thirty years old and upward even to fifty years old, everyone who entered into the service, for work in the Tent of Meeting.",
                "Those who were numbered of them by their families were two thousand seven hundred fifty.",
                "These are those who were numbered of the families of the Kohathites, all who served in the Tent of Meeting, whom Moses and Aaron numbered according to the commandment of Yahweh by Moses.",
                "Those who were numbered of the sons of Gershon, their families, and by their fathers\u2019 houses,",
                "from thirty years old and upward even to fifty years old, everyone who entered into the service, for work in the Tent of Meeting,",
                "even those who were numbered of them, by their families, by their fathers\u2019 houses, were two thousand six hundred thirty.",
                "These are those who were numbered of the families of the sons of Gershon, all who served in the Tent of Meeting, whom Moses and Aaron numbered according to the commandment of Yahweh.",
                "Those who were numbered of the families of the sons of Merari, by their families, by their fathers\u2019 houses,",
                "from thirty years old and upward even to fifty years old, everyone who entered into the service, for work in the Tent of Meeting,",
                "even those who were numbered of them by their families, were three thousand two hundred.",
                "These are those who were numbered of the families of the sons of Merari, whom Moses and Aaron numbered according to the commandment of Yahweh by Moses.",
                "All those who were numbered of the Levites, whom Moses and Aaron and the princes of Israel numbered, by their families, and by their fathers\u2019 houses,",
                "from thirty years old and upward even to fifty years old, everyone who entered in to do the work of service, and the work of bearing burdens in the Tent of Meeting,",
                "even those who were numbered of them, were eight thousand five hundred eighty.",
                "According to the commandment of Yahweh they were numbered by Moses, everyone according to his service, and according to his burden. Thus were they numbered by him, as Yahweh commanded Moses."
            ],
            5: [
                "Yahweh spoke to Moses, saying,",
                "\"Command the children of Israel that they put out of the camp every leper, and everyone who has an issue, and whoever is unclean by the dead.",
                "Both you shall put male and female outside of the camp; that they not defile their camp, in the midst of which I dwell.\"",
                "The children of Israel did so, and put them out outside of the camp; as Yahweh spoke to Moses, so did the children of Israel.",
                "Yahweh spoke to Moses, saying,",
                "\"Speak to the children of Israel: \u2018When a man or woman commits any sin that men commit, so as to trespass against Yahweh, and that soul is guilty;",
                "then he shall confess his sin which he has done, and he shall make restitution for his guilt in full, and add to it the fifth part of it, and give it to him in respect of whom he has been guilty.",
                "But if the man has no kinsman to whom restitution may be made for the guilt, the restitution for guilt which is made to Yahweh shall be the priest\u2019s; besides the ram of the atonement, by which atonement shall be made for him.",
                "Every heave offering of all the holy things of the children of Israel, which they present to the priest, shall be his.",
                "Every man\u2019s holy things shall be his: whatever any man gives the priest, it shall be his.\u2019\"",
                "Yahweh spoke to Moses, saying,",
                "\"Speak to the children of Israel, and tell them: \u2018If any man\u2019s wife goes astray, and is unfaithful to him,",
                "and a man lies with her carnally, and it is hidden from the eyes of her husband, and is kept close, and she is defiled, and there is no witness against her, and she isn\u2019t taken in the act;",
                "and the spirit of jealousy comes on him, and he is jealous of his wife, and she is defiled: or if the spirit of jealousy comes on him, and he is jealous of his wife, and she isn\u2019t defiled:",
                "then the man shall bring his wife to the priest, and shall bring her offering for her: the tenth part of an ephah of barley meal. He shall pour no oil on it, nor put frankincense on it, for it is a meal offering of jealousy, a meal offering of memorial, bringing iniquity to memory.",
                "The priest shall bring her near, and set her before Yahweh;",
                "and the priest shall take holy water in an earthen vessel; and of the dust that is on the floor of the tabernacle the priest shall take, and put it into the water.",
                "The priest shall set the woman before Yahweh, and let the hair of the woman\u2019s head go loose, and put the meal offering of memorial in her hands, which is the meal offering of jealousy. The priest shall have in his hand the water of bitterness that brings a curse.",
                "The priest shall cause her to swear, and shall tell the woman, \"If no man has lain with you, and if you haven\u2019t gone aside to uncleanness, being under your husband, be free from this water of bitterness that brings a curse.",
                "But if you have gone astray, being under your husband, and if you are defiled, and some man has lain with you besides your husband:\"",
                "then the priest shall cause the woman to swear with the oath of cursing, and the priest shall tell the woman, \"Yahweh make you a curse and an oath among your people, when Yahweh allows your thigh to fall away, and your body to swell;",
                "and this water that brings a curse will go into your bowels, and make your body swell, and your thigh fall away.\" The woman shall say, \"Amen, Amen.\"",
                "\"\u2018The priest shall write these curses in a book, and he shall blot them out into the water of bitterness.",
                "He shall make the woman drink the water of bitterness that causes the curse; and the water that causes the curse shall enter into her and become bitter.",
                "The priest shall take the meal offering of jealousy out of the woman\u2019s hand, and shall wave the meal offering before Yahweh, and bring it to the altar.",
                "The priest shall take a handful of the meal offering, as its memorial, and burn it on the altar, and afterward shall make the woman drink the water.",
                "When he has made her drink the water, then it shall happen, if she is defiled, and has committed a trespass against her husband, that the water that causes the curse will enter into her and become bitter, and her body will swell, and her thigh will fall away: and the woman will be a curse among her people.",
                "If the woman isn\u2019t defiled, but is clean; then she shall be free, and shall conceive seed.",
                "\"\u2018This is the law of jealousy, when a wife, being under her husband, goes astray, and is defiled;",
                "or when the spirit of jealousy comes on a man, and he is jealous of his wife; then he shall set the woman before Yahweh, and the priest shall execute on her all this law.",
                "The man shall be free from iniquity, and that woman shall bear her iniquity.\u2019\""
            ],
            6: [
                "Yahweh spoke to Moses, saying,",
                "\"Speak to the children of Israel, and tell them: \u2018When either man or woman shall make a special vow, the vow of a Nazirite, to separate himself to Yahweh,",
                "he shall separate himself from wine and strong drink. He shall drink no vinegar of wine, or vinegar of fermented drink, neither shall he drink any juice of grapes, nor eat fresh grapes or dried.",
                "All the days of his separation he shall eat nothing that is made of the grapevine, from the seeds even to the skins.",
                "\"\u2018All the days of his vow of separation no razor shall come on his head, until the days are fulfilled, in which he separates himself to Yahweh. He shall be holy. He shall let the locks of the hair of his head grow long.",
                "\"\u2018All the days that he separates himself to Yahweh he shall not go near a dead body.",
                "He shall not make himself unclean for his father, or for his mother, for his brother, or for his sister, when they die; because his separation to God is on his head.",
                "All the days of his separation he is holy to Yahweh.",
                "\"\u2018If any man dies very suddenly beside him, and he defiles the head of his separation; then he shall shave his head in the day of his cleansing. On the seventh day he shall shave it.",
                "On the eighth day he shall bring two turtledoves or two young pigeons to the priest, to the door of the Tent of Meeting.",
                "The priest shall offer one for a sin offering, and the other for a burnt offering, and make atonement for him, because he sinned by reason of the dead, and shall make his head holy that same day.",
                "He shall separate to Yahweh the days of his separation, and shall bring a male lamb a year old for a trespass offering; but the former days shall be void, because his separation was defiled.",
                "\"\u2018This is the law of the Nazirite: when the days of his separation are fulfilled, he shall be brought to the door of the Tent of Meeting,",
                "and he shall offer his offering to Yahweh, one male lamb a year old without blemish for a burnt offering, and one ewe lamb a year old without blemish for a sin offering, and one ram without blemish for peace offerings,",
                "and a basket of unleavened bread, cakes of fine flour mixed with oil, and unleavened wafers anointed with oil, and their meal offering, and their drink offerings.",
                "The priest shall present them before Yahweh, and shall offer his sin offering, and his burnt offering.",
                "He shall offer the ram for a sacrifice of peace offerings to Yahweh, with the basket of unleavened bread. The priest shall offer also its meal offering, and its drink offering.",
                "The Nazirite shall shave the head of his separation at the door of the Tent of Meeting, and shall take the hair of the head of his separation, and put it on the fire which is under the sacrifice of peace offerings.",
                "The priest shall take the boiled shoulder of the ram, and one unleavened cake out of the basket, and one unleavened wafer, and shall put them on the hands of the Nazirite, after he has shaved the head of his separation;",
                "and the priest shall wave them for a wave offering before Yahweh. This is holy for the priest, together with the breast that is waved and the thigh that is offered. After that the Nazirite may drink wine.",
                "\"\u2018This is the law of the Nazirite who vows, and of his offering to Yahweh for his separation, besides that which he is able to get. According to his vow which he vows, so he must do after the law of his separation.\u2019\"",
                "Yahweh spoke to Moses, saying,",
                "\"Speak to Aaron and to his sons, saying, \u2018This is how you shall bless the children of Israel.\u2019 You shall tell them,",
                "\u2018Yahweh bless you, and keep you.",
                "Yahweh make his face to shine on you, and be gracious to you.",
                "Yahweh lift up his face toward you, and give you peace.\u2019",
                "\"So they shall put my name on the children of Israel; and I will bless them.\""
            ],
            7: [
                "It happened on the day that Moses had finished setting up the tabernacle, and had anointed it and sanctified it, with all its furniture, and the altar with all its vessels, and had anointed and sanctified them;",
                "that the princes of Israel, the heads of their fathers\u2019 houses, offered. These were the princes of the tribes. These are they who were over those who were numbered:",
                "and they brought their offering before Yahweh, six covered wagons, and twelve oxen; a wagon for every two of the princes, and for each one an ox: and they presented them before the tabernacle.",
                "Yahweh spoke to Moses, saying,",
                "\"Accept these from them, that they may be used in doing the service of the Tent of Meeting; and you shall give them to the Levites, to every man according to his service.\"",
                "Moses took the wagons and the oxen, and gave them to the Levites.",
                "He gave two wagons and four oxen to the sons of Gershon, according to their service:",
                "and he gave four wagons and eight oxen to the sons of Merari, according to their service, under the direction of Ithamar the son of Aaron the priest.",
                "But to the sons of Kohath he gave none, because the service of the sanctuary belonged to them; they carried it on their shoulders.",
                "The princes gave offerings for the dedication of the altar in the day that it was anointed, even the princes gave their offerings before the altar.",
                "Yahweh said to Moses, \"They shall offer their offering, each prince on his day, for the dedication of the altar.\"",
                "He who offered his offering the first day was Nahshon the son of Amminadab, of the tribe of Judah,",
                "and his offering was: one silver platter, the weight of which was one hundred thirty shekels, one silver bowl of seventy shekels, after the shekel of the sanctuary; both of them full of fine flour mixed with oil for a meal offering;",
                "one golden ladle of ten shekels, full of incense;",
                "one young bull, one ram, one male lamb a year old, for a burnt offering;",
                "one male goat for a sin offering;",
                "and for the sacrifice of peace offerings, two head of cattle, five rams, five male goats, and five male lambs a year old. This was the offering of Nahshon the son of Amminadab.",
                "On the second day Nethanel the son of Zuar, prince of Issachar, gave his offering.",
                "He offered for his offering: one silver platter, the weight of which was one hundred thirty shekels, one silver bowl of seventy shekels, after the shekel of the sanctuary; both of them full of fine flour mixed with oil for a meal offering;",
                "one golden ladle of ten shekels, full of incense;",
                "one young bull, one ram, one male lamb a year old, for a burnt offering;",
                "one male goat for a sin offering;",
                "and for the sacrifice of peace offerings, two head of cattle, five rams, five male goats, five male lambs a year old. This was the offering of Nethanel the son of Zuar.",
                "On the third day Eliab the son of Helon, prince of the children of Zebulun",
                "gave his offering: one silver platter, the weight of which was a hundred and thirty shekels, one silver bowl of seventy shekels, after the shekel of the sanctuary; both of them full of fine flour mixed with oil for a meal offering;",
                "one golden ladle of ten shekels, full of incense;",
                "one young bull, one ram, one male lamb a year old, for a burnt offering;",
                "one male goat for a sin offering;",
                "and for the sacrifice of peace offerings, two head of cattle, five rams, five male goats, and five male lambs a year old. This was the offering of Eliab the son of Helon.",
                "On the fourth day Elizur the son of Shedeur, prince of the children of Reuben",
                "gave his offering: one silver platter, the weight of which was one hundred thirty shekels, one silver bowl of seventy shekels, after the shekel of the sanctuary; both of them full of fine flour mixed with oil for a meal offering;",
                "one golden ladle of ten shekels, full of incense;",
                "one young bull, one ram, one male lamb a year old, for a burnt offering;",
                "one male goat for a sin offering;",
                "and for the sacrifice of peace offerings, two head of cattle, five rams, five male goats, and five male lambs a year old. This was the offering of Elizur the son of Shedeur.",
                "On the fifth day Shelumiel the son of Zurishaddai, prince of the children of Simeon",
                "gave his offering: one silver platter, the weight of which was one hundred thirty shekels, one silver bowl of seventy shekels, after the shekel of the sanctuary; both of them full of fine flour mixed with oil for a meal offering;",
                "one golden ladle of ten shekels, full of incense;",
                "one young bull, one ram, one male lamb a year old, for a burnt offering;",
                "one male goat for a sin offering;",
                "and for the sacrifice of peace offerings, two head of cattle, five rams, five male goats, and five male lambs a year old: this was the offering of Shelumiel the son of Zurishaddai.",
                "On the sixth day, Eliasaph the son of Deuel, prince of the children of Gad",
                "gave his offering: one silver platter, the weight of which was one hundred thirty shekels, one silver bowl of seventy shekels, after the shekel of the sanctuary; both of them full of fine flour mixed with oil for a meal offering;",
                "one golden ladle of ten shekels, full of incense;",
                "one young bull, one ram, one male lamb a year old, for a burnt offering;",
                "one male goat for a sin offering;",
                "and for the sacrifice of peace offerings, two head of cattle, five rams, five male goats, and five male lambs a year old. This was the offering of Eliasaph the son of Deuel.",
                "On the seventh day Elishama the son of Ammihud, prince of the children of Ephraim",
                "gave his offering: one silver platter, the weight of which was one hundred thirty shekels, one silver bowl of seventy shekels, after the shekel of the sanctuary; both of them full of fine flour mixed with oil for a meal offering;",
                "one golden ladle of ten shekels, full of incense;",
                "one young bull, one ram, one male lamb a year old, for a burnt offering;",
                "one male goat for a sin offering;",
                "and for the sacrifice of peace offerings, two head of cattle, five rams, five male goats, and five male lambs a year old. This was the offering of Elishama the son of Ammihud.",
                "On the eighth day Gamaliel the son of Pedahzur, prince of the children of Manasseh",
                "gave his offering: one silver platter, the weight of which was one hundred thirty shekels, one silver bowl of seventy shekels, after the shekel of the sanctuary; both of them full of fine flour mixed with oil for a meal offering;",
                "one golden ladle of ten shekels, full of incense;",
                "one young bull, one ram, one male lamb a year old, for a burnt offering;",
                "one male goat for a sin offering;",
                "and for the sacrifice of peace offerings, two head of cattle, five rams, five male goats, and five male lambs a year old. This was the offering of Gamaliel the son of Pedahzur.",
                "On the ninth day Abidan the son of Gideoni, prince of the children of Benjamin",
                "gave his offering: one silver platter, the weight of which was one hundred thirty shekels, one silver bowl of seventy shekels, after the shekel of the sanctuary; both of them full of fine flour mixed with oil for a meal offering;",
                "one golden ladle of ten shekels, full of incense;",
                "one young bull, one ram, one male lamb a year old, for a burnt offering;",
                "one male goat for a sin offering;",
                "and for the sacrifice of peace offerings, two head of cattle, five rams, five male goats, and five male lambs a year old. This was the offering of Abidan the son of Gideoni.",
                "On the tenth day Ahiezer the son of Ammishaddai, prince of the children of Dan",
                "gave his offering: one silver platter, the weight of which was one hundred thirty shekels, one silver bowl of seventy shekels, after the shekel of the sanctuary; both of them full of fine flour mixed with oil for a meal offering;",
                "one golden ladle of ten shekels, full of incense;",
                "one young bull, one ram, one male lamb a year old, for a burnt offering;",
                "one male goat for a sin offering;",
                "and for the sacrifice of peace offerings, two head of cattle, five rams, five male goats, and five male lambs a year old. This was the offering of Ahiezer the son of Ammishaddai.",
                "On the eleventh day Pagiel the son of Ochran, prince of the children of Asher",
                "gave his offering: one silver platter, the weight of which was one hundred thirty shekels, one silver bowl of seventy shekels, after the shekel of the sanctuary; both of them full of fine flour mixed with oil for a meal offering;",
                "one golden ladle of ten shekels, full of incense;",
                "one young bull, one ram, one male lamb a year old, for a burnt offering;",
                "one male goat for a sin offering;",
                "and for the sacrifice of peace offerings, two head of cattle, five rams, five male goats, and five male lambs a year old. This was the offering of Pagiel the son of Ochran.",
                "On the twelfth day Ahira the son of Enan, prince of the children of Naphtali",
                "gave his offering: one silver platter, the weight of which was one hundred thirty shekels, one silver bowl of seventy shekels, after the shekel of the sanctuary; both of them full of fine flour mixed with oil for a meal offering;",
                "one golden spoon of ten shekels, full of incense;",
                "one young bull, one ram, one male lamb a year old, for a burnt offering;",
                "one male goat for a sin offering;",
                "and for the sacrifice of peace offerings, two head of cattle, five rams, five male goats, and five male lambs a year old. This was the offering of Ahira the son of Enan.",
                "This was the dedication of the altar, on the day when it was anointed, by the princes of Israel: twelve silver platters, twelve silver bowls, twelve golden ladles;",
                "each silver platter weighing one hundred thirty shekels, and each bowl seventy; all the silver of the vessels two thousand four hundred shekels, after the shekel of the sanctuary;",
                "the twelve golden ladles, full of incense, weighing ten shekels apiece, after the shekel of the sanctuary; all the gold of the ladles weighed one hundred twenty shekels;",
                "all the cattle for the burnt offering twelve bulls, the rams twelve, the male lambs a year old twelve, and their meal offering; and the male goats for a sin offering twelve;",
                "and all the cattle for the sacrifice of peace offerings twenty-four bulls, the rams sixty, the male goats sixty, the male lambs a year old sixty. This was the dedication of the altar, after it was anointed.",
                "When Moses went into the Tent of Meeting to speak with Yahweh, he heard his voice speaking to him from above the mercy seat that was on the ark of the Testimony, from between the two cherubim: and he spoke to him."
            ],
            8: [
                "Yahweh spoke to Moses, saying,",
                "\"Speak to Aaron, and tell him, \u2018When you light the lamps, the seven lamps shall give light in front of the lampstand.\u2019\"",
                "Aaron did so. He lit its lamps to light the area in front of the lampstand, as Yahweh commanded Moses.",
                "This was the workmanship of the lampstand, beaten work of gold. From its base to its flowers, it was beaten work: according to the pattern which Yahweh had shown Moses, so he made the lampstand.",
                "Yahweh spoke to Moses, saying,",
                "\"Take the Levites from among the children of Israel, and cleanse them.",
                "You shall do this to them, to cleanse them: sprinkle the water of cleansing on them, let them shave their whole bodies with a razor, and let them wash their clothes, and cleanse themselves.",
                "Then let them take a young bull, and its meal offering, fine flour mixed with oil; and another young bull you shall take for a sin offering.",
                "You shall present the Levites before the Tent of Meeting. You shall assemble the whole congregation of the children of Israel.",
                "You shall present the Levites before Yahweh. The children of Israel shall lay their hands on the Levites,",
                "and Aaron shall offer the Levites before Yahweh for a wave offering, on the behalf of the children of Israel, that it may be theirs to do the service of Yahweh.",
                "\"The Levites shall lay their hands on the heads of the bulls, and you shall offer the one for a sin offering, and the other for a burnt offering to Yahweh, to make atonement for the Levites.",
                "You shall set the Levites before Aaron, and before his sons, and offer them as a wave offering to Yahweh.",
                "Thus you shall separate the Levites from among the children of Israel, and the Levites shall be mine.",
                "\"After that, the Levites shall go in to do the service of the Tent of Meeting: and you shall cleanse them, and offer them as a wave offering.",
                "For they are wholly given to me from among the children of Israel; instead of all who open the womb, even the firstborn of all the children of Israel, I have taken them to me.",
                "For all the firstborn among the children of Israel are mine, both man and animal. On the day that I struck all the firstborn in the land of Egypt, I sanctified them for myself.",
                "I have taken the Levites instead of all the firstborn among the children of Israel.",
                "I have given the Levites as a gift to Aaron and to his sons from among the children of Israel, to do the service of the children of Israel in the Tent of Meeting, and to make atonement for the children of Israel; that there be no plague among the children of Israel, when the children of Israel come near to the sanctuary.\"",
                "Moses, and Aaron, and all the congregation of the children of Israel did so to the Levites. According to all that Yahweh commanded Moses concerning the Levites, so the children of Israel did to them.",
                "The Levites purified themselves from sin, and they washed their clothes; and Aaron offered them for a wave offering before Yahweh; and Aaron made atonement for them to cleanse them.",
                "After that, the Levites went in to do their service in the Tent of Meeting before Aaron, and before his sons: as Yahweh had commanded Moses concerning the Levites, so they did to them.",
                "Yahweh spoke to Moses, saying,",
                "\"This is that which belongs to the Levites: from twenty-five years old and upward they shall go in to wait on the service in the work of the Tent of Meeting;",
                "and from the age of fifty years they shall cease waiting on the work, and shall serve no more,",
                "but shall minister with their brothers in the Tent of Meeting, to perform the duty, and shall do no service. You shall do thus to the Levites concerning their duties.\""
            ],
            9: [
                "Yahweh spoke to Moses in the wilderness of Sinai, in the first month of the second year after they had come out of the land of Egypt, saying,",
                "\"Moreover let the children of Israel keep the Passover in its appointed season.",
                "On the fourteenth day of this month, at evening, you shall keep it in its appointed season\u2014according to all its statutes, and according to all its ordinances, you shall keep it.\"",
                "Moses spoke to the children of Israel, that they should keep the Passover.",
                "They kept the Passover in the first month, on the fourteenth day of the month, at evening, in the wilderness of Sinai. According to all that Yahweh commanded Moses, so the children of Israel did.",
                "There were certain men, who were unclean because of the dead body of a man, so that they could not keep the Passover on that day, and they came before Moses and before Aaron on that day.",
                "Those men said to him, \"We are unclean because of the dead body of a man. Why are we kept back, that we may not offer the offering of Yahweh in its appointed season among the children of Israel?\"",
                "Moses answered them, \"Wait, that I may hear what Yahweh will command concerning you.\"",
                "Yahweh spoke to Moses, saying,",
                "\"Say to the children of Israel, \u2018If any man of you or of your generations is unclean by reason of a dead body, or is on a journey far away, he shall still keep the Passover to Yahweh.",
                "In the second month, on the fourteenth day at evening they shall keep it; they shall eat it with unleavened bread and bitter herbs.",
                "They shall leave none of it until the morning, nor break a bone of it. According to all the statute of the Passover they shall keep it.",
                "But the man who is clean, and is not on a journey, and fails to keep the Passover, that soul shall be cut off from his people. Because he didn\u2019t offer the offering of Yahweh in its appointed season, that man shall bear his sin.",
                "\"\u2018If a foreigner lives among you, and desires to keep the Passover to Yahweh; according to the statute of the Passover, and according to its ordinance, so shall he do. You shall have one statute, both for the foreigner, and for him who is born in the land.\u2019\"",
                "On the day that the tabernacle was raised up, the cloud covered the tabernacle, even the Tent of the Testimony: and at evening it was over the tabernacle as it were the appearance of fire, until morning.",
                "So it was continually. The cloud covered it, and the appearance of fire by night.",
                "Whenever the cloud was taken up from over the Tent, then after that the children of Israel traveled; and in the place where the cloud remained, there the children of Israel encamped.",
                "At the commandment of Yahweh, the children of Israel traveled, and at the commandment of Yahweh they encamped. As long as the cloud remained on the tabernacle they remained encamped.",
                "When the cloud stayed on the tabernacle many days, then the children of Israel kept Yahweh\u2019s command, and didn\u2019t travel.",
                "Sometimes the cloud was a few days on the tabernacle; then according to the commandment of Yahweh they remained encamped, and according to the commandment of Yahweh they traveled.",
                "Sometimes the cloud was from evening until morning; and when the cloud was taken up in the morning, they traveled: or by day and by night, when the cloud was taken up, they traveled.",
                "Whether it was two days, or a month, or a year that the cloud stayed on the tabernacle, remaining on it, the children of Israel remained encamped, and didn\u2019t travel; but when it was taken up, they traveled.",
                "At the commandment of Yahweh they encamped, and at the commandment of Yahweh they traveled. They kept Yahweh\u2019s command, at the commandment of Yahweh by Moses."
            ],
            10: [
                "Yahweh spoke to Moses, saying,",
                "\"Make two trumpets of silver. You shall make them of beaten work. You shall use them for the calling of the congregation, and for the journeying of the camps.",
                "When they blow them, all the congregation shall gather themselves to you at the door of the Tent of Meeting.",
                "If they blow just one, then the princes, the heads of the thousands of Israel, shall gather themselves to you.",
                "When you blow an alarm, the camps that lie on the east side shall go forward.",
                "When you blow an alarm the second time, the camps that lie on the south side shall go forward. They shall blow an alarm for their journeys.",
                "But when the assembly is to be gathered together, you shall blow, but you shall not sound an alarm.",
                "\"The sons of Aaron, the priests, shall blow the trumpets. This shall be to you for a statute forever throughout your generations.",
                "When you go to war in your land against the adversary who oppresses you, then you shall sound an alarm with the trumpets. Then you will be remembered before Yahweh your God, and you will be saved from your enemies.",
                "\"Also in the day of your gladness, and in your set feasts, and in the beginnings of your months, you shall blow the trumpets over your burnt offerings, and over the sacrifices of your peace offerings; and they shall be to you for a memorial before your God. I am Yahweh your God.\"",
                "It happened in the second year, in the second month, on the twentieth day of the month, that the cloud was taken up from over the tabernacle of the testimony.",
                "The children of Israel went forward according to their journeys out of the wilderness of Sinai; and the cloud stayed in the wilderness of Paran.",
                "They first went forward according to the commandment of Yahweh by Moses.",
                "First, the standard of the camp of the children of Judah went forward according to their armies. Nahshon the son of Amminadab was over his army.",
                "Nethanel the son of Zuar was over the army of the tribe of the children of Issachar.",
                "Eliab the son of Helon was over the army of the tribe of the children of Zebulun.",
                "The tabernacle was taken down; and the sons of Gershon and the sons of Merari, who bore the tabernacle, went forward.",
                "The standard of the camp of Reuben went forward according to their armies. Elizur the son of Shedeur was over his army.",
                "Shelumiel the son of Zurishaddai was over the army of the tribe of the children of Simeon.",
                "Eliasaph the son of Deuel was over the army of the tribe of the children of Gad.",
                "The Kohathites set forward, bearing the sanctuary. The others set up the tabernacle before they arrived.",
                "The standard of the camp of the children of Ephraim set forward according to their armies. Elishama the son of Ammihud was over his army.",
                "Gamaliel the son of Pedahzur was over the army of the tribe of the children of Manasseh.",
                "Abidan the son of Gideoni was over the army of the tribe of the children of Benjamin.",
                "The standard of the camp of the children of Dan, which was the rear guard of all the camps, set forward according to their armies. Ahiezer the son of Ammishaddai was over his army.",
                "Pagiel the son of Ochran was over the army of the tribe of the children of Asher.",
                "Ahira the son of Enan was over the army of the tribe of the children of Naphtali.",
                "Thus were the travels of the children of Israel according to their armies; and they went forward.",
                "Moses said to Hobab, the son of Reuel the Midianite, Moses\u2019 father-in-law, \"We are journeying to the place of which Yahweh said, \u2018I will give it to you.\u2019 Come with us, and we will treat you well; for Yahweh has spoken good concerning Israel.\"",
                "He said to him, \"I will not go; but I will depart to my own land, and to my relatives.\"",
                "He said, \"Don\u2019t leave us, please; because you know how we are to encamp in the wilderness, and you can be our eyes.",
                "It shall be, if you go with us, yes, it shall be, that whatever good Yahweh does to us, we will do the same to you.\"",
                "They set forward from the Mount of Yahweh three days\u2019 journey. The ark of the covenant of Yahweh went before them three days\u2019 journey, to seek out a resting place for them.",
                "The cloud of Yahweh was over them by day, when they set forward from the camp.",
                "It happened, when the ark went forward, that Moses said, \"Rise up, Yahweh, and let your enemies be scattered! Let those who hate you flee before you!\"",
                "When it rested, he said, \"Return, Yahweh, to the ten thousands of the thousands of Israel.\""
            ],
            11: [
                "The people were complaining in the ears of Yahweh. When Yahweh heard it, his anger was kindled; and Yahweh\u2019s fire burnt among them, and consumed some of the outskirts of the camp.",
                "The people cried to Moses; and Moses prayed to Yahweh, and the fire abated.",
                "The name of that place was called Taberah, because Yahweh\u2019s fire burnt among them.",
                "The mixed multitude that was among them lusted exceedingly: and the children of Israel also wept again, and said, \"Who will give us flesh to eat?",
                "We remember the fish, which we ate in Egypt for nothing; the cucumbers, and the melons, and the leeks, and the onions, and the garlic;",
                "but now we have lost our appetite. There is nothing at all except this manna to look at.\"",
                "The manna was like coriander seed, and its appearance like the appearance of bdellium.",
                "The people went around, gathered it, and ground it in mills, or beat it in mortars, and boiled it in pots, and made cakes of it. Its taste was like the taste of fresh oil.",
                "When the dew fell on the camp in the night, the manna fell on it.",
                "Moses heard the people weeping throughout their families, every man at the door of his tent; and the anger of Yahweh was kindled greatly; and Moses was displeased.",
                "Moses said to Yahweh, \"Why have you treated with your servant so badly? Why haven\u2019t I found favor in your sight, that you lay the burden of all this people on me?",
                "Have I conceived all this people? Have I brought them forth, that you should tell me, \u2018Carry them in your bosom, as a nurse carries a nursing infant, to the land which you swore to their fathers?\u2019",
                "Where could I get meat to give to all this people? For they weep to me, saying, \u2018Give us meat, that we may eat.\u2019",
                "I am not able to bear all this people alone, because it is too heavy for me.",
                "If you treat me this way, please kill me right now, if I have found favor in your sight; and don\u2019t let me see my wretchedness.\"",
                "Yahweh said to Moses, \"Gather to me seventy men of the elders of Israel, whom you know to be the elders of the people, and officers over them; and bring them to the Tent of Meeting, that they may stand there with you.",
                "I will come down and talk with you there. I will take of the Spirit which is on you, and will put it on them; and they shall bear the burden of the people with you, that you not bear it yourself alone.",
                "\"Say to the people, \u2018Sanctify yourselves against tomorrow, and you will eat flesh; for you have wept in the ears of Yahweh, saying, \"Who will give us flesh to eat? For it was well with us in Egypt.\" Therefore Yahweh will give you flesh, and you will eat.",
                "You will not eat one day, nor two days, nor five days, neither ten days, nor twenty days,",
                "but a whole month, until it come out at your nostrils, and it is loathsome to you; because that you have rejected Yahweh who is among you, and have wept before him, saying, \"Why did we come out of Egypt?\"\u2019\"",
                "Moses said, \"The people, among whom I am, are six hundred thousand men on foot; and you have said, \u2018I will give them flesh, that they may eat a whole month.\u2019",
                "Shall flocks and herds be slaughtered for them, to be sufficient for them? Shall all the fish of the sea be gathered together for them, to be sufficient for them?\"",
                "Yahweh said to Moses, \"Has Yahweh\u2019s hand grown short? Now you will see whether my word will happen to you or not.\"",
                "Moses went out, and told the people the words of Yahweh; and he gathered seventy men of the elders of the people, and set them around the Tent.",
                "Yahweh came down in the cloud, and spoke to him, and took of the Spirit that was on him, and put it on the seventy elders: and it happened that when the Spirit rested on them, they prophesied, but they did so no more.",
                "But two men remained in the camp. The name of one was Eldad, and the name of the other Medad: and the Spirit rested on them; and they were of those who were written, but had not gone out to the Tent; and they prophesied in the camp.",
                "A young man ran, and told Moses, and said, \"Eldad and Medad are prophesying in the camp!\"",
                "Joshua the son of Nun, the servant of Moses, one of his chosen men, answered, \"My lord Moses, forbid them!\"",
                "Moses said to him, \"Are you jealous for my sake? I wish that all Yahweh\u2019s people were prophets, that Yahweh would put his Spirit on them!\"",
                "Moses went into the camp, he and the elders of Israel.",
                "A wind from Yahweh went out and brought quails from the sea, and let them fall by the camp, about a day\u2019s journey on this side, and a day\u2019s journey on the other side, around the camp, and about two cubits above the surface of the earth.",
                "The people rose up all that day, and all the night, and all the next day, and gathered the quails. He who gathered least gathered ten homers; and they spread them all abroad for themselves around the camp.",
                "While the flesh was yet between their teeth, before it was chewed, the anger of Yahweh was kindled against the people, and Yahweh struck the people with a very great plague.",
                "The name of that place was called Kibroth Hattaavah, because there they buried the people who lusted.",
                "From Kibroth Hattaavah the people traveled to Hazeroth; and they stayed at Hazeroth."
            ],
            12: [
                "Miriam and Aaron spoke against Moses because of the Cushite woman whom he had married; for he had married a Cushite woman.",
                "They said, \"Has Yahweh indeed spoken only with Moses? Hasn\u2019t he spoken also with us?\" And Yahweh heard it.",
                "Now the man Moses was very humble, above all the men who were on the surface of the earth.",
                "Yahweh spoke suddenly to Moses, to Aaron, and to Miriam, \"You three come out to the Tent of Meeting!\" The three of them came out.",
                "Yahweh came down in a pillar of cloud, and stood at the door of the Tent, and called Aaron and Miriam; and they both came forward.",
                "He said, \"Hear now my words. If there is a prophet among you, I Yahweh will make myself known to him in a vision. I will speak with him in a dream.",
                "My servant Moses is not so. He is faithful in all my house.",
                "With him I will speak mouth to mouth, even plainly, and not in riddles; and he shall see Yahweh\u2019s form. Why then were you not afraid to speak against my servant, against Moses?\"",
                "The anger of Yahweh was kindled against them; and he departed.",
                "The cloud departed from over the Tent; and behold, Miriam was leprous, as white as snow. Aaron looked at Miriam, and behold, she was leprous.",
                "Aaron said to Moses, \"Oh, my lord, please don\u2019t count this sin against us, in which we have done foolishly, and in which we have sinned.",
                "Let her not, I pray, be as one dead, of whom the flesh is half consumed when he comes out of his mother\u2019s womb.\"",
                "Moses cried to Yahweh, saying, \"Heal her, God, I beg you!\"",
                "Yahweh said to Moses, \"If her father had but spit in her face, shouldn\u2019t she be ashamed seven days? Let her be shut up outside of the camp seven days, and after that she shall be brought in again.\"",
                "Miriam was shut up outside of the camp seven days, and the people didn\u2019t travel until Miriam was brought in again.",
                "Afterward the people traveled from Hazeroth, and encamped in the wilderness of Paran."
            ],
            13: [
                "Yahweh spoke to Moses, saying,",
                "\"Send men, that they may spy out the land of Canaan, which I give to the children of Israel. Of every tribe of their fathers, you shall send a man, every one a prince among them.\"",
                "Moses sent them from the wilderness of Paran according to the commandment of Yahweh: all of them men who were heads of the children of Israel.",
                "These were their names: Of the tribe of Reuben, Shammua the son of Zaccur.",
                "Of the tribe of Simeon, Shaphat the son of Hori.",
                "Of the tribe of Judah, Caleb the son of Jephunneh.",
                "Of the tribe of Issachar, Igal the son of Joseph.",
                "Of the tribe of Ephraim, Hoshea the son of Nun.",
                "Of the tribe of Benjamin, Palti the son of Raphu.",
                "Of the tribe of Zebulun, Gaddiel the son of Sodi.",
                "Of the tribe of Joseph, of the tribe of Manasseh, Gaddi the son of Susi.",
                "Of the tribe of Dan, Ammiel the son of Gemalli.",
                "Of the tribe of Asher, Sethur the son of Michael.",
                "Of the tribe of Naphtali, Nahbi the son of Vophsi.",
                "Of the tribe of Gad, Geuel the son of Machi.",
                "These are the names of the men who Moses sent to spy out the land. Moses called Hoshea the son of Nun Joshua.",
                "Moses sent them to spy out the land of Canaan, and said to them, \"Go up this way by the South, and go up into the hill country:",
                "and see the land, what it is; and the people who dwell therein, whether they are strong or weak, whether they are few or many;",
                "and what the land is that they dwell in, whether it is good or bad; and what cities they are that they dwell in, whether in camps, or in strongholds;",
                "and what the land is, whether it is fat or lean, whether there is wood therein, or not. Be courageous, and bring of the fruit of the land. Now the time was the time of the first-ripe grapes.\"",
                "So they went up, and spied out the land from the wilderness of Zin to Rehob, to the entrance of Hamath.",
                "They went up by the South, and came to Hebron; and Ahiman, Sheshai, and Talmai, the children of Anak, were there. (Now Hebron was built seven years before Zoan in Egypt.)",
                "They came to the valley of Eshcol, and cut down from there a branch with one cluster of grapes, and they bore it on a staff between two. They also brought some of the pomegranates and figs.",
                "That place was called the valley of Eshcol, because of the cluster which the children of Israel cut down from there.",
                "They returned from spying out the land at the end of forty days.",
                "They went and came to Moses, and to Aaron, and to all the congregation of the children of Israel, to the wilderness of Paran, to Kadesh; and brought back word to them, and to all the congregation, and showed them the fruit of the land.",
                "They told him, and said, \"We came to the land where you sent us; and surely it flows with milk and honey; and this is its fruit.",
                "However the people who dwell in the land are strong, and the cities are fortified and very large. Moreover, we saw the children of Anak there.",
                "Amalek dwells in the land of the South: and the Hittite, and the Jebusite, and the Amorite, dwell in the hill country; and the Canaanite dwells by the sea, and along by the side of the Jordan.\"",
                "Caleb stilled the people before Moses, and said, \"Let us go up at once, and possess it; for we are well able to overcome it.\"",
                "But the men who went up with him said, \"We aren\u2019t able to go up against the people; for they are stronger than we.\"",
                "They brought up an evil report of the land which they had spied out to the children of Israel, saying, \"The land, through which we have gone to spy it out, is a land that eats up its inhabitants; and all the people who we saw in it are men of great stature.",
                "There we saw the Nephilim, the sons of Anak, who come of the Nephilim: and we were in our own sight as grasshoppers, and so we were in their sight.\""
            ],
            14: [
                "All the congregation lifted up their voice, and cried; and the people wept that night.",
                "All the children of Israel murmured against Moses and against Aaron: and the whole congregation said to them, \"Would that we had died in the land of Egypt! or would that we had died in this wilderness!",
                "Why does Yahweh bring us to this land, to fall by the sword? Our wives and our little ones will be a prey: wouldn\u2019t it be better for us to return into Egypt?\"",
                "They said one to another, \"Let us make a captain, and let us return into Egypt.\"",
                "Then Moses and Aaron fell on their faces before all the assembly of the congregation of the children of Israel.",
                "Joshua the son of Nun and Caleb the son of Jephunneh, who were of those who spied out the land, tore their clothes:",
                "and they spoke to all the congregation of the children of Israel, saying, \"The land, which we passed through to spy it out, is an exceeding good land.",
                "If Yahweh delights in us, then he will bring us into this land, and give it to us; a land which flows with milk and honey.",
                "Only don\u2019t rebel against Yahweh, neither fear the people of the land; for they are bread for us: their defense is removed from over them, and Yahweh is with us. Don\u2019t fear them.\"",
                "But all the congregation threatened to stone them with stones. The glory of Yahweh appeared in the Tent of Meeting to all the children of Israel.",
                "Yahweh said to Moses, \"How long will this people despise me? and how long will they not believe in me, for all the signs which I have worked among them?",
                "I will strike them with the pestilence, and disinherit them, and will make of you a nation greater and mightier than they.\"",
                "Moses said to Yahweh, \"Then the Egyptians will hear it; for you brought up this people in your might from among them;",
                "and they will tell it to the inhabitants of this land. They have heard that you Yahweh are in the midst of this people; for you Yahweh are seen face to face, and your cloud stands over them, and you go before them, in a pillar of cloud by day, and in a pillar of fire by night.",
                "Now if you killed this people as one man, then the nations which have heard the fame of you will speak, saying,",
                "\u2018Because Yahweh was not able to bring this people into the land which he swore to them, therefore he has slain them in the wilderness.\u2019",
                "Now please let the power of the Lord be great, according as you have spoken, saying,",
                "\u2018Yahweh is slow to anger, and abundant in loving kindness, forgiving iniquity and disobedience; and that will by no means clear the guilty, visiting the iniquity of the fathers on the children, on the third and on the fourth generation.\u2019",
                "Please pardon the iniquity of this people according to the greatness of your loving kindness, and according as you have forgiven this people, from Egypt even until now.\"",
                "Yahweh said, \"I have pardoned according to your word:",
                "but in very deed, as I live, and as all the earth shall be filled with the glory of Yahweh;",
                "because all those men who have seen my glory, and my signs, which I worked in Egypt and in the wilderness, yet have tempted me these ten times, and have not listened to my voice;",
                "surely they shall not see the land which I swore to their fathers, neither shall any of those who despised me see it:",
                "but my servant Caleb, because he had another spirit with him, and has followed me fully, him will I bring into the land into which he went; and his seed shall possess it.",
                "Now the Amalekite and the Canaanite dwell in the valley: tomorrow turn, and go into the wilderness by the way to the Red Sea.\"",
                "Yahweh spoke to Moses and to Aaron, saying,",
                "\"How long shall I bear with this evil congregation, that murmur against me? I have heard the murmurings of the children of Israel, which they murmur against me.",
                "Tell them, \u2018As I live, says Yahweh, surely as you have spoken in my ears, so will I do to you:",
                "your dead bodies shall fall in this wilderness; and all who were numbered of you, according to your whole number, from twenty years old and upward, who have murmured against me,",
                "surely you shall not come into the land, concerning which I swore that I would make you dwell therein, except Caleb the son of Jephunneh, and Joshua the son of Nun.",
                "But your little ones, that you said should be a prey, them will I bring in, and they shall know the land which you have rejected.",
                "But as for you, your dead bodies shall fall in this wilderness.",
                "Your children shall be wanderers in the wilderness forty years, and shall bear your prostitution, until your dead bodies be consumed in the wilderness.",
                "After the number of the days in which you spied out the land, even forty days, for every day a year, you will bear your iniquities, even forty years, and you will know my alienation.\u2019",
                "I, Yahweh, have spoken, surely this will I do to all this evil congregation, who are gathered together against me: in this wilderness they shall be consumed, and there they shall die.\"",
                "The men, whom Moses sent to spy out the land, who returned, and made all the congregation to murmur against him, by bringing up an evil report against the land,",
                "even those men who brought up an evil report of the land, died by the plague before Yahweh.",
                "But Joshua the son of Nun, and Caleb the son of Jephunneh, remained alive of those men who went to spy out the land.",
                "Moses told these words to all the children of Israel: and the people mourned greatly.",
                "They rose up early in the morning, and went up to the top of the mountain, saying, \"Behold, we are here, and will go up to the place which Yahweh has promised: for we have sinned.\"",
                "Moses said, \"Why now do you disobey the commandment of Yahweh, since it shall not prosper?",
                "Don\u2019t go up, for Yahweh isn\u2019t among you; that you not be struck down before your enemies.",
                "For there the Amalekite and the Canaanite are before you, and you shall fall by the sword: because you are turned back from following Yahweh, therefore Yahweh will not be with you.\"",
                "But they presumed to go up to the top of the mountain: nevertheless the ark of the covenant of Yahweh, and Moses, didn\u2019t depart out of the camp.",
                "Then the Amalekite came down, and the Canaanite who lived in that mountain, and struck them and beat them down, even to Hormah."
            ],
            15: [
                "Yahweh spoke to Moses, saying,",
                "\"Speak to the children of Israel, and tell them, \u2018When you have come into the land of your habitations, which I give to you,",
                "and will make an offering by fire to Yahweh, a burnt offering, or a sacrifice, to accomplish a vow, or as a freewill offering, or in your set feasts, to make a pleasant aroma to Yahweh, of the herd, or of the flock;",
                "then he who offers his offering shall offer to Yahweh a meal offering of a tenth part of an ephah of fine flour mixed with the fourth part of a hin of oil:",
                "and wine for the drink offering, the fourth part of a hin, you shall prepare with the burnt offering, or for the sacrifice, for each lamb.",
                "\"\u2018Or for a ram, you shall prepare for a meal offering two tenth parts of an ephah of fine flour mixed with the third part of a hin of oil:",
                "and for the drink offering you shall offer the third part of a hin of wine, of a pleasant aroma to Yahweh.",
                "When you prepare a bull for a burnt offering, or for a sacrifice, to accomplish a vow, or for peace offerings to Yahweh;",
                "then shall he offer with the bull a meal offering of three tenth parts of an ephah of fine flour mixed with half a hin of oil:",
                "and you shall offer for the drink offering half a hin of wine, for an offering made by fire, of a pleasant aroma to Yahweh.",
                "Thus shall it be done for each bull, or for each ram, or for each of the male lambs, or of the young goats.",
                "According to the number that you shall prepare, so you shall do to everyone according to their number.",
                "\"\u2018All who are native-born shall do these things in this way, in offering an offering made by fire, of a pleasant aroma to Yahweh.",
                "If a stranger lives as a foreigner with you, or whoever may be among you throughout your generations, and will offer an offering made by fire, of a pleasant aroma to Yahweh; as you do, so he shall do.",
                "For the assembly, there shall be one statute for you, and for the stranger who lives as a foreigner, a statute forever throughout your generations: as you are, so shall the foreigner be before Yahweh.",
                "One law and one ordinance shall be for you, and for the stranger who lives as a foreigner with you.\u2019\"",
                "Yahweh spoke to Moses, saying,",
                "\"Speak to the children of Israel, and tell them, \u2018When you come into the land where I bring you,",
                "then it shall be that when you eat of the bread of the land, you shall offer up a wave offering to Yahweh.",
                "Of the first of your dough you shall offer up a cake for a wave offering: as the wave offering of the threshing floor, so you shall heave it.",
                "Of the first of your dough you shall give to Yahweh a wave offering throughout your generations.",
                "\"\u2018When you shall err, and not observe all these commandments, which Yahweh has spoken to Moses,",
                "even all that Yahweh has commanded you by Moses, from the day that Yahweh gave commandment, and onward throughout your generations;",
                "then it shall be, if it be done unwittingly, without the knowledge of the congregation, that all the congregation shall offer one young bull for a burnt offering, for a pleasant aroma to Yahweh, with the meal offering of it, and the drink offering of it, according to the ordinance, and one male goat for a sin offering.",
                "The priest shall make atonement for all the congregation of the children of Israel, and they shall be forgiven; for it was an error, and they have brought their offering, an offering made by fire to Yahweh, and their sin offering before Yahweh, for their error:",
                "and all the congregation of the children of Israel shall be forgiven, and the stranger who lives as a foreigner among them; for in respect of all the people it was done unwittingly.",
                "\"\u2018If one person sins unwittingly, then he shall offer a female goat a year old for a sin offering.",
                "The priest shall make atonement for the soul who errs, when he sins unwittingly, before Yahweh, to make atonement for him; and he shall be forgiven.",
                "You shall have one law for him who does anything unwittingly, for him who is native-born among the children of Israel, and for the stranger who lives as a foreigner among them.",
                "\"\u2018But the soul who does anything with a high hand, whether he is native-born or a foreigner, the same blasphemes Yahweh; and that soul shall be cut off from among his people.",
                "Because he has despised the word of Yahweh, and has broken his commandment, that soul shall utterly be cut off; his iniquity shall be on him.\u2019\"",
                "While the children of Israel were in the wilderness, they found a man gathering sticks on the Sabbath day.",
                "Those who found him gathering sticks brought him to Moses and Aaron, and to all the congregation.",
                "They put him in custody, because it had not been declared what should be done to him.",
                "Yahweh said to Moses, \"The man shall surely be put to death: all the congregation shall stone him with stones outside of the camp.\"",
                "All the congregation brought him outside of the camp, and stoned him to death with stones; as Yahweh commanded Moses.",
                "Yahweh spoke to Moses, saying,",
                "\"Speak to the children of Israel, and tell them that they should make themselves fringes in the borders of their garments throughout their generations, and that they put on the fringe of each border a cord of blue:",
                "and it shall be to you for a fringe, that you may look on it, and remember all the commandments of Yahweh, and do them; and that you not follow after your own heart and your own eyes, after which you use to play the prostitute;",
                "that you may remember and do all my commandments, and be holy to your God.",
                "I am Yahweh your God, who brought you out of the land of Egypt, to be your God: I am Yahweh your God.\""
            ],
            16: [
                "Now Korah, the son of Izhar, the son of Kohath, the son of Levi, with Dathan and Abiram, the sons of Eliab, and On, the son of Peleth, sons of Reuben, took men:",
                "and they rose up before Moses, with certain of the children of Israel, two hundred fifty princes of the congregation, called to the assembly, men of renown;",
                "and they assembled themselves together against Moses and against Aaron, and said to them, \"You take too much on yourself, since all the congregation are holy, everyone of them, and Yahweh is among them: why then lift yourselves up above the assembly of Yahweh?\"",
                "When Moses heard it, he fell on his face:",
                "and he spoke to Korah and to all his company, saying, \"In the morning Yahweh will show who are his, and who is holy, and will cause him to come near to him: even him whom he shall choose he will cause to come near to him.",
                "Do this: take censers, Korah, and all his company;",
                "and put fire in them, and put incense on them before Yahweh tomorrow: and it shall be that the man whom Yahweh chooses, he shall be holy. You have gone too far, you sons of Levi!\"",
                "Moses said to Korah, \"Hear now, you sons of Levi!",
                "Is it a small thing to you, that the God of Israel has separated you from the congregation of Israel, to bring you near to himself, to do the service of the tabernacle of Yahweh, and to stand before the congregation to minister to them;",
                "and that he has brought you near, and all your brothers the sons of Levi with you? and do you seek the priesthood also?",
                "Therefore you and all your company are gathered together against Yahweh: and Aaron, what is he that you murmur against him?\"",
                "Moses sent to call Dathan and Abiram, the sons of Eliab; and they said, \"We won\u2019t come up:",
                "is it a small thing that you have brought us up out of a land flowing with milk and honey, to kill us in the wilderness, but you must also make yourself a prince over us?",
                "Moreover you haven\u2019t brought us into a land flowing with milk and honey, nor given us inheritance of fields and vineyards: will you put out the eyes of these men? We won\u2019t come up.\"",
                "Moses was very angry, and said to Yahweh, \"Don\u2019t respect their offering: I have not taken one donkey from them, neither have I hurt one of them.\"",
                "Moses said to Korah, \"You and all your company go before Yahweh, you, and they, and Aaron, tomorrow:",
                "and each man take his censer, and put incense on them, and each man bring before Yahweh his censer, two hundred fifty censers; you also, and Aaron, each his censer.\"",
                "They each took his censer, and put fire in them, and laid incense thereon, and stood at the door of the Tent of Meeting with Moses and Aaron.",
                "Korah assembled all the congregation against them to the door of the Tent of Meeting: and the glory of Yahweh appeared to all the congregation.",
                "Yahweh spoke to Moses and to Aaron, saying,",
                "\"Separate yourselves from among this congregation, that I may consume them in a moment!\"",
                "They fell on their faces, and said, \"God, the God of the spirits of all flesh, shall one man sin, and will you be angry with all the congregation?\"",
                "Yahweh spoke to Moses, saying,",
                "\"Speak to the congregation, saying, \u2018Get away from around the tent of Korah, Dathan, and Abiram!\u2019\"",
                "Moses rose up and went to Dathan and Abiram; and the elders of Israel followed him.",
                "He spoke to the congregation, saying, \"Depart, please, from the tents of these wicked men, and touch nothing of theirs, lest you be consumed in all their sins!\"",
                "So they went away from the tent of Korah, Dathan, and Abiram, on every side: and Dathan and Abiram came out, and stood at the door of their tents, and their wives, and their sons, and their little ones.",
                "Moses said, \"Hereby you shall know that Yahweh has sent me to do all these works; for they are not from my own mind.",
                "If these men die the common death of all men, or if they be visited after the visitation of all men; then Yahweh hasn\u2019t sent me.",
                "But if Yahweh make a new thing, and the ground open its mouth, and swallow them up, with all that appertain to them, and they go down alive into Sheol; then you shall understand that these men have despised Yahweh.\"",
                "It happened, as he made an end of speaking all these words, that the ground split apart that was under them;",
                "and the earth opened its mouth, and swallowed them up, and their households, and all the men who appertained to Korah, and all their goods.",
                "So they, and all that appertained to them, went down alive into Sheol: and the earth closed on them, and they perished from among the assembly.",
                "All Israel that were around them fled at their cry; for they said, \"Lest the earth swallow us up!\"",
                "Fire came forth from Yahweh, and devoured the two hundred fifty men who offered the incense.",
                "Yahweh spoke to Moses, saying,",
                "\"Speak to Eleazar the son of Aaron the priest, that he take up the censers out of the burning, and scatter the fire yonder; for they are holy,",
                "even the censers of these sinners against their own lives; and let them be made beaten plates for a covering of the altar: for they offered them before Yahweh; therefore they are holy; and they shall be a sign to the children of Israel.\"",
                "Eleazar the priest took the bronze censers, which those who were burnt had offered; and they beat them out for a covering of the altar,",
                "to be a memorial to the children of Israel, to the end that no stranger, who isn\u2019t of the seed of Aaron, comes near to burn incense before Yahweh; that he not be as Korah, and as his company: as Yahweh spoke to him by Moses.",
                "But on the next day all the congregation of the children of Israel murmured against Moses and against Aaron, saying, \"You have killed Yahweh\u2019s people!\"",
                "It happened, when the congregation was assembled against Moses and against Aaron, that they looked toward the Tent of Meeting: and behold, the cloud covered it, and the glory of Yahweh appeared.",
                "Moses and Aaron came to the front of the Tent of Meeting.",
                "Yahweh spoke to Moses, saying,",
                "\"Get away from among this congregation, that I may consume them in a moment!\" They fell on their faces.",
                "Moses said to Aaron, \"Take your censer, and put fire from off the altar in it, and lay incense on it, and carry it quickly to the congregation, and make atonement for them; for wrath has gone out from Yahweh! The plague has begun.\"",
                "Aaron did as Moses said, and ran into the midst of the assembly; and behold, the plague has begun among the people: and he put on the incense, and made atonement for the people.",
                "He stood between the dead and the living; and the plague was stayed.",
                "Now those who died by the plague were fourteen thousand and seven hundred, besides those who died about the matter of Korah.",
                "Aaron returned to Moses to the door of the Tent of Meeting: and the plague was stayed."
            ],
            17: [
                "Yahweh spoke to Moses, saying,",
                "\"Speak to the children of Israel, and take of them rods, one for each fathers\u2019 house, of all their princes according to their fathers\u2019 houses, twelve rods: write every man\u2019s name on his rod.",
                "You shall write Aaron\u2019s name on the rod of Levi; for there shall be one rod for each head of their fathers\u2019 houses.",
                "You shall lay them up in the Tent of Meeting before the testimony, where I meet with you.",
                "It shall happen, that the rod of the man whom I shall choose shall bud: and I will make to cease from me the murmurings of the children of Israel, which they murmur against you.\"",
                "Moses spoke to the children of Israel; and all their princes gave him rods, for each prince one, according to their fathers\u2019 houses, even twelve rods: and the rod of Aaron was among their rods.",
                "Moses laid up the rods before Yahweh in the tent of the testimony.",
                "It happened on the next day, that Moses went into the tent of the testimony; and behold, the rod of Aaron for the house of Levi was budded, and put forth buds, and produced blossoms, and bore ripe almonds.",
                "Moses brought out all the rods from before Yahweh to all the children of Israel: and they looked, and took every man his rod.",
                "Yahweh said to Moses, \"Put back the rod of Aaron before the testimony, to be kept for a token against the children of rebellion; that you may make an end of their murmurings against me, that they not die.\"",
                "Moses did so. As Yahweh commanded him, so he did.",
                "The children of Israel spoke to Moses, saying, \"Behold, we perish! We are undone! We are all undone!",
                "Everyone who comes near, who comes near to the tabernacle of Yahweh, dies! Will we all perish?\""
            ],
            18: [
                "Yahweh said to Aaron, \"You and your sons and your fathers\u2019 house with you shall bear the iniquity of the sanctuary; and you and your sons with you shall bear the iniquity of your priesthood.",
                "Your brothers also, the tribe of Levi, the tribe of your father, bring near with you, that they may be joined to you, and minister to you: but you and your sons with you shall be before the tent of the testimony.",
                "They shall keep your commands, and the duty of all the Tent: only they shall not come near to the vessels of the sanctuary and to the altar, that they not die, neither they, nor you.",
                "They shall be joined to you, and keep the responsibility of the Tent of Meeting, for all the service of the Tent: and a stranger shall not come near to you.",
                "\"You shall perform the duty of the sanctuary, and the duty of the altar; that there be no more wrath on the children of Israel.",
                "I, behold, I have taken your brothers the Levites from among the children of Israel: to you they are a gift, given to Yahweh, to do the service of the Tent of Meeting.",
                "You and your sons with you shall keep your priesthood for everything of the altar, and for that within the veil; and you shall serve: I give you the priesthood as a service of gift: and the stranger who comes near shall be put to death.\"",
                "Yahweh spoke to Aaron, \"I, behold, I have given you the command of my wave offerings, even all the holy things of the children of Israel; to you have I given them by reason of the anointing, and to your sons, as a portion forever.",
                "This shall be yours of the most holy things from the fire: every offering of theirs, even every meal offering of theirs, and every sin offering of theirs, and every trespass offering of theirs, which they shall render to me, shall be most holy for you and for your sons.",
                "You shall eat of it like the most holy things. Every male shall eat of it. It shall be holy to you.",
                "\"This is yours, too: the wave offering of their gift, even all the wave offerings of the children of Israel. I have given them to you, and to your sons and to your daughters with you, as a portion forever. Everyone who is clean in your house shall eat of it.",
                "\"All the best of the oil, and all the best of the vintage, and of the grain, the first fruits of them which they give to Yahweh, to you have I given them.",
                "The first-ripe fruits of all that is in their land, which they bring to Yahweh, shall be yours; everyone who is clean in your house shall eat of it.",
                "\"Everything devoted in Israel shall be yours.",
                "Everything that opens the womb, of all flesh which they offer to Yahweh, both of man and animal shall be yours: nevertheless you shall surely redeem the firstborn of man, and you shall redeem the firstborn of unclean animals.",
                "You shall redeem those who are to be redeemed of them from a month old, according to your estimation, for five shekels of money, after the shekel of the sanctuary (the same is twenty gerahs).",
                "\"But you shall not redeem the firstborn of a cow, or the firstborn of a sheep, or the firstborn of a goat. They are holy. You shall sprinkle their blood on the altar, and shall burn their fat for an offering made by fire, for a pleasant aroma to Yahweh.",
                "Their flesh shall be yours, as the wave offering breast and as the right thigh, it shall be yours.",
                "All the wave offerings of the holy things, which the children of Israel offer to Yahweh, have I given you, and your sons and your daughters with you, as a portion forever: it is a covenant of salt forever before Yahweh to you and to your seed with you.\"",
                "Yahweh said to Aaron, \"You shall have no inheritance in their land, neither shall you have any portion among them. I am your portion and your inheritance among the children of Israel.",
                "\"To the children of Levi, behold, I have given all the tithe in Israel for an inheritance, in return for their service which they serve, even the service of the Tent of Meeting.",
                "Henceforth the children of Israel shall not come near the Tent of Meeting, lest they bear sin, and die.",
                "But the Levites shall do the service of the Tent of Meeting, and they shall bear their iniquity: it shall be a statute forever throughout your generations; and among the children of Israel they shall have no inheritance.",
                "For the tithe of the children of Israel, which they offer as a wave offering to Yahweh, I have given to the Levites for an inheritance: therefore I have said to them, \u2018Among the children of Israel they shall have no inheritance.\u2019\"",
                "Yahweh spoke to Moses, saying,",
                "\"Moreover you shall speak to the Levites, and tell them, \u2018When you take of the children of Israel the tithe which I have given you from them for your inheritance, then you shall offer up a wave offering of it for Yahweh, a tithe of the tithe.",
                "Your wave offering shall be reckoned to you, as though it were the grain of the threshing floor, and as the fullness of the winepress.",
                "Thus you also shall offer a wave offering to Yahweh of all your tithes, which you receive of the children of Israel; and of it you shall give Yahweh\u2019s wave offering to Aaron the priest.",
                "Out of all your gifts you shall offer every wave offering of Yahweh, of all its best, even the holy part of it out of it.\u2019",
                "\"Therefore you shall tell them, \u2018When you heave its best from it, then it shall be reckoned to the Levites as the increase of the threshing floor, and as the increase of the winepress.",
                "You shall eat it in every place, you and your households: for it is your reward in return for your service in the Tent of Meeting.",
                "You shall bear no sin by reason of it, when you have heaved from it its best: and you shall not profane the holy things of the children of Israel, that you not die.\u2019\""
            ],
            19: [
                "Yahweh spoke to Moses and to Aaron, saying,",
                "\"This is the statute of the law which Yahweh has commanded: Speak to the children of Israel, that they bring you a red heifer without spot, in which is no blemish, and which was never yoked.",
                "You shall give her to Eleazar the priest, and he shall bring her forth outside of the camp, and one shall kill her before his face:",
                "and Eleazar the priest shall take of her blood with his finger, and sprinkle her blood toward the front of the Tent of Meeting seven times.",
                "One shall burn the heifer in his sight; her skin, and her flesh, and her blood, with her dung, shall he burn:",
                "and the priest shall take cedar wood, and hyssop, and scarlet, and cast it into the midst of the burning of the heifer.",
                "Then the priest shall wash his clothes, and he shall bathe his flesh in water, and afterward he shall come into the camp, and the priest shall be unclean until the evening.",
                "He who burns her shall wash his clothes in water, and bathe his flesh in water, and shall be unclean until the evening.",
                "\"A man who is clean shall gather up the ashes of the heifer, and lay them up outside of the camp in a clean place; and it shall be kept for the congregation of the children of Israel for a water for impurity: it is a sin offering.",
                "He who gathers the ashes of the heifer shall wash his clothes, and be unclean until the evening: and it shall be to the children of Israel, and to the stranger who lives as a foreigner among them, for a statute forever.",
                "\"He who touches the dead body of any man shall be unclean seven days:",
                "the same shall purify himself with water on the third day, and on the seventh day he shall be clean: but if he doesn\u2019t purify himself the third day, then the seventh day he shall not be clean.",
                "Whoever touches a dead person, the body of a man who has died, and doesn\u2019t purify himself, defiles the tabernacle of Yahweh; and that soul shall be cut off from Israel: because the water for impurity was not sprinkled on him, he shall be unclean; his uncleanness is yet on him.",
                "\"This is the law when a man dies in a tent: everyone who comes into the tent, and everyone who is in the tent, shall be unclean seven days.",
                "Every open vessel, which has no covering bound on it, is unclean.",
                "\"Whoever in the open field touches one who is slain with a sword, or a dead body, or a bone of a man, or a grave, shall be unclean seven days.",
                "\"For the unclean they shall take of the ashes of the burning of the sin offering; and running water shall be put thereto in a vessel:",
                "and a clean person shall take hyssop, and dip it in the water, and sprinkle it on the tent, and on all the vessels, and on the persons who were there, and on him who touched the bone, or the slain, or the dead, or the grave:",
                "and the clean person shall sprinkle on the unclean on the third day, and on the seventh day: and on the seventh day he shall purify him; and he shall wash his clothes, and bathe himself in water, and shall be clean at evening.",
                "But the man who shall be unclean, and shall not purify himself, that soul shall be cut off from the midst of the assembly, because he has defiled the sanctuary of Yahweh: the water for impurity has not been sprinkled on him; he is unclean.",
                "It shall be a perpetual statute to them: and he who sprinkles the water for impurity shall wash his clothes, and he who touches the water for impurity shall be unclean until evening.",
                "\"Whatever the unclean person touches shall be unclean; and the soul that touches it shall be unclean until evening.\""
            ],
            20: [
                "The children of Israel, even the whole congregation, came into the wilderness of Zin in the first month: and the people stayed in Kadesh; and Miriam died there, and was buried there.",
                "There was no water for the congregation: and they assembled themselves together against Moses and against Aaron.",
                "The people strove with Moses, and spoke, saying, \"We wish that we had died when our brothers died before Yahweh!",
                "Why have you brought the assembly of Yahweh into this wilderness, that we should die there, we and our animals?",
                "Why have you made us to come up out of Egypt, to bring us in to this evil place? It is no place of seed, or of figs, or of vines, or of pomegranates; neither is there any water to drink.\"",
                "Moses and Aaron went from the presence of the assembly to the door of the Tent of Meeting, and fell on their faces: and the glory of Yahweh appeared to them.",
                "Yahweh spoke to Moses, saying,",
                "\"Take the rod, and assemble the congregation, you, and Aaron your brother, and speak to the rock before their eyes, that it give forth its water; and you shall bring forth to them water out of the rock; so you shall give the congregation and their livestock drink.\"",
                "Moses took the rod from before Yahweh, as he commanded him.",
                "Moses and Aaron gathered the assembly together before the rock, and he said to them, \"Hear now, you rebels; shall we bring you water out of this rock for you?\"",
                "Moses lifted up his hand, and struck the rock with his rod twice: and water came forth abundantly, and the congregation drank, and their livestock.",
                "Yahweh said to Moses and Aaron, \"Because you didn\u2019t believe in me, to sanctify me in the eyes of the children of Israel, therefore you shall not bring this assembly into the land which I have given them.\"",
                "These are the waters of Meribah; because the children of Israel strove with Yahweh, and he was sanctified in them.",
                "Moses sent messengers from Kadesh to the king of Edom, saying: \"Thus says your brother Israel: You know all the travail that has happened to us:",
                "how our fathers went down into Egypt, and we lived in Egypt a long time; and the Egyptians dealt ill with us, and our fathers:",
                "and when we cried to Yahweh, he heard our voice, and sent an angel, and brought us forth out of Egypt: and behold, we are in Kadesh, a city in the uttermost of your border.",
                "\"Please let us pass through your land: we will not pass through field or through vineyard, neither will we drink of the water of the wells: we will go along the king\u2019s highway; we will not turn aside to the right hand nor to the left, until we have passed your border.\"",
                "Edom said to him, \"You shall not pass through me, lest I come out with the sword against you.\"",
                "The children of Israel said to him, \"We will go up by the highway; and if we drink of your water, I and my livestock, then will I give its price: let me only, without doing anything else, pass through on my feet.\"",
                "He said, \"You shall not pass through.\" Edom came out against him with many people, and with a strong hand.",
                "Thus Edom refused to give Israel passage through his border, so Israel turned away from him.",
                "They traveled from Kadesh: and the children of Israel, even the whole congregation, came to Mount Hor.",
                "Yahweh spoke to Moses and Aaron in Mount Hor, by the border of the land of Edom, saying,",
                "\"Aaron shall be gathered to his people; for he shall not enter into the land which I have given to the children of Israel, because you rebelled against my word at the waters of Meribah.",
                "Take Aaron and Eleazar his son, and bring them up to Mount Hor;",
                "and strip Aaron of his garments, and put them on Eleazar his son: and Aaron shall be taken, and shall die there.\"",
                "Moses did as Yahweh commanded: and they went up into Mount Hor in the sight of all the congregation.",
                "Moses stripped Aaron of his garments, and put them on Eleazar his son; and Aaron died there on the top of the mountain: and Moses and Eleazar came down from the mountain.",
                "When all the congregation saw that Aaron was dead, they wept for Aaron thirty days, even all the house of Israel."
            ],
            21: [
                "The Canaanite, the king of Arad, who lived in the South, heard tell that Israel came by the way of Atharim; and he fought against Israel, and took some of them captive.",
                "Israel vowed a vow to Yahweh, and said, \"If you will indeed deliver this people into my hand, then I will utterly destroy their cities.\"",
                "Yahweh listened to the voice of Israel, and delivered up the Canaanites; and they utterly destroyed them and their cities: and the name of the place was called Hormah.",
                "They traveled from Mount Hor by the way to the Red Sea, to compass the land of Edom: and the soul of the people was much discouraged because of the way.",
                "The people spoke against God, and against Moses, \"Why have you brought us up out of Egypt to die in the wilderness? For there is no bread, and there is no water; and our soul loathes this light bread.\"",
                "Yahweh sent fiery serpents among the people, and they bit the people; and many people of Israel died.",
                "The people came to Moses, and said, \"We have sinned, because we have spoken against Yahweh, and against you. Pray to Yahweh, that he take away the serpents from us.\" Moses prayed for the people.",
                "Yahweh said to Moses, \"Make a fiery serpent, and set it on a standard: and it shall happen, that everyone who is bitten, when he sees it, shall live.\"",
                "Moses made a serpent of brass, and set it on the standard: and it happened, that if a serpent had bitten any man, when he looked to the serpent of brass, he lived.",
                "The children of Israel traveled, and encamped in Oboth.",
                "They traveled from Oboth, and encamped at Iyeabarim, in the wilderness which is before Moab, toward the sunrise.",
                "From there they traveled, and encamped in the valley of Zered.",
                "From there they traveled, and encamped on the other side of the Arnon, which is in the wilderness, that comes out of the border of the Amorites: for the Arnon is the border of Moab, between Moab and the Amorites.",
                "Therefore it is said in the book of the Wars of Yahweh, \"Vaheb in Suphah, the valleys of the Arnon,",
                "the slope of the valleys that incline toward the dwelling of Ar, leans on the border of Moab.\"",
                "From there they traveled to Beer: that is the well of which Yahweh said to Moses, \"Gather the people together, and I will give them water.\"",
                "Then sang Israel this song: \"Spring up, well; sing to it:",
                "the well, which the princes dug, which the nobles of the people dug, with the scepter, and with their poles.\" From the wilderness they traveled to Mattanah;",
                "and from Mattanah to Nahaliel; and from Nahaliel to Bamoth;",
                "and from Bamoth to the valley that is in the field of Moab, to the top of Pisgah, which looks down on the desert.",
                "Israel sent messengers to Sihon king of the Amorites, saying,",
                "\"Let me pass through your land: we will not turn aside into field, or into vineyard; we will not drink of the water of the wells: we will go by the king\u2019s highway, until we have passed your border.\"",
                "Sihon would not allow Israel to pass through his border: but Sihon gathered all his people together, and went out against Israel into the wilderness, and came to Jahaz; and he fought against Israel.",
                "Israel struck him with the edge of the sword, and possessed his land from the Arnon to the Jabbok, even to the children of Ammon; for the border of the children of Ammon was strong.",
                "Israel took all these cities: and Israel lived in all the cities of the Amorites, in Heshbon, and in all its towns.",
                "For Heshbon was the city of Sihon the king of the Amorites, who had fought against the former king of Moab, and taken all his land out of his hand, even to the Arnon.",
                "Therefore those who speak in proverbs say, \"Come to Heshbon. Let the city of Sihon be built and established;",
                "for a fire has gone out of Heshbon, a flame from the city of Sihon. It has devoured Ar of Moab, The lords of the high places of the Arnon.",
                "Woe to you, Moab! You are undone, people of Chemosh! He has given his sons as fugitives, and his daughters into captivity, to Sihon king of the Amorites.",
                "We have shot at them. Heshbon has perished even to Dibon. We have laid waste even to Nophah, Which reaches to Medeba.\"",
                "Thus Israel lived in the land of the Amorites.",
                "Moses sent to spy out Jazer; and they took its towns, and drove out the Amorites who were there.",
                "They turned and went up by the way of Bashan: and Og the king of Bashan went out against them, he and all his people, to battle at Edrei.",
                "Yahweh said to Moses, \"Don\u2019t fear him: for I have delivered him into your hand, and all his people, and his land; and you shall do to him as you did to Sihon king of the Amorites, who lived at Heshbon.\"",
                "So they struck him, and his sons and all his people, until there was none left him remaining: and they possessed his land."
            ],
            22: [
                "The children of Israel traveled, and encamped in the plains of Moab beyond the Jordan at Jericho.",
                "Balak the son of Zippor saw all that Israel had done to the Amorites.",
                "Moab was very afraid of the people, because they were many: and Moab was distressed because of the children of Israel.",
                "Moab said to the elders of Midian, \"Now this multitude will lick up all that is around us, as the ox licks up the grass of the field.\" Balak the son of Zippor was king of Moab at that time.",
                "He sent messengers to Balaam the son of Beor, to Pethor, which is by the River, to the land of the children of his people, to call him, saying, \"Behold, there is a people who came out from Egypt. Behold, they cover the surface of the earth, and they are staying opposite me.",
                "Please come now therefore curse me this people; for they are too mighty for me: perhaps I shall prevail, that we may strike them, and that I may drive them out of the land; for I know that he whom you bless is blessed, and he whom you curse is cursed.\"",
                "The elders of Moab and the elders of Midian departed with the rewards of divination in their hand; and they came to Balaam, and spoke to him the words of Balak.",
                "He said to them, \"Lodge here this night, and I will bring you word again, as Yahweh shall speak to me.\" The princes of Moab stayed with Balaam.",
                "God came to Balaam, and said, \"Who are these men with you?\"",
                "Balaam said to God, \"Balak the son of Zippor, king of Moab, has said to me,",
                "\u2018Behold, the people that has come out of Egypt, it covers the surface of the earth: now, come curse me them; perhaps I shall be able to fight against them, and shall drive them out.\u2019\"",
                "God said to Balaam, \"You shall not go with them. You shall not curse the people; for they are blessed.\"",
                "Balaam rose up in the morning, and said to the princes of Balak, \"Go to your land; for Yahweh refuses to permit me to go with you.\"",
                "The princes of Moab rose up, and they went to Balak, and said, \"Balaam refuses to come with us.\"",
                "Balak sent yet again princes, more, and more honorable than they.",
                "They came to Balaam, and said to him, \"Thus says Balak the son of Zippor, \u2018Please let nothing hinder you from coming to me:",
                "for I will promote you to very great honor, and whatever you say to me I will do. Please come therefore, and curse this people for me.\u2019\"",
                "Balaam answered the servants of Balak, \"If Balak would give me his house full of silver and gold, I can\u2019t go beyond the word of Yahweh my God, to do less or more.",
                "Now therefore, please wait also here this night, that I may know what Yahweh will speak to me more.\"",
                "God came to Balaam at night, and said to him, \"If the men have come to call you, rise up, go with them; but only the word which I speak to you, that you shall do.\"",
                "Balaam rose up in the morning, and saddled his donkey, and went with the princes of Moab.",
                "God\u2019s anger was kindled because he went; and the angel of Yahweh placed himself in the way for an adversary against him. Now he was riding on his donkey, and his two servants were with him.",
                "The donkey saw the angel of Yahweh standing in the way, with his sword drawn in his hand; and the donkey turned aside out of the way, and went into the field: and Balaam struck the donkey, to turn her into the way.",
                "Then the angel of Yahweh stood in a narrow path between the vineyards, a wall being on this side, and a wall on that side.",
                "The donkey saw the angel of Yahweh, and she thrust herself to the wall, and crushed Balaam\u2019s foot against the wall: and he struck her again.",
                "The angel of Yahweh went further, and stood in a narrow place, where there was no way to turn either to the right hand or to the left.",
                "The donkey saw the angel of Yahweh, and she lay down under Balaam: and Balaam\u2019s anger was kindled, and he struck the donkey with his staff.",
                "Yahweh opened the mouth of the donkey, and she said to Balaam, \"What have I done to you, that you have struck me these three times?\"",
                "Balaam said to the donkey, \"Because you have mocked me, I wish there were a sword in my hand, for now I would have killed you.\"",
                "The donkey said to Balaam, \"Am I not your donkey, on which you have ridden all your life long to this day? Was I ever in the habit of doing so to you?\" He said, \"No.\"",
                "Then Yahweh opened the eyes of Balaam, and he saw the angel of Yahweh standing in the way, with his sword drawn in his hand; and he bowed his head, and fell on his face.",
                "The angel of Yahweh said to him, \"Why have you struck your donkey these three times? Behold, I have come forth as an adversary, because your way is perverse before me:",
                "and the donkey saw me, and turned aside before me these three times. Unless she had turned aside from me, surely now I would have killed you, and saved her alive.\"",
                "Balaam said to the angel of Yahweh, \"I have sinned; for I didn\u2019t know that you stood in the way against me. Now therefore, if it displeases you, I will go back again.\"",
                "The angel of Yahweh said to Balaam, \"Go with the men; but only the word that I shall speak to you, that you shall speak.\" So Balaam went with the princes of Balak.",
                "When Balak heard that Balaam had come, he went out to meet him to the City of Moab, which is on the border of the Arnon, which is in the utmost part of the border.",
                "Balak said to Balaam, \"Didn\u2019t I earnestly send to you to call you? Why didn\u2019t you come to me? Am I not able indeed to promote you to honor?\"",
                "Balaam said to Balak, \"Behold, I have come to you: have I now any power at all to speak anything? The word that God puts in my mouth, that shall I speak.\"",
                "Balaam went with Balak, and they came to Kiriath Huzoth.",
                "Balak sacrificed cattle and sheep, and sent to Balaam, and to the princes who were with him.",
                "It happened in the morning, that Balak took Balaam, and brought him up into the high places of Baal; and he saw from there the utmost part of the people."
            ],
            23: [
                "Balaam said to Balak, \"Build me here seven altars, and prepare me here seven bulls and seven rams.\"",
                "Balak did as Balaam had spoken; and Balak and Balaam offered on every altar a bull and a ram.",
                "Balaam said to Balak, \"Stand by your burnt offering, and I will go: perhaps Yahweh will come to meet me; and whatever he shows me I will tell you.\" He went to a bare height.",
                "God met Balaam: and he said to him, \"I have prepared the seven altars, and I have offered up a bull and a ram on every altar.\"",
                "Yahweh put a word in Balaam\u2019s mouth, and said, \"Return to Balak, and thus you shall speak.\"",
                "He returned to him, and behold, he was standing by his burnt offering, he, and all the princes of Moab.",
                "He took up his parable, and said, \"From Aram has Balak brought me, the king of Moab from the mountains of the East. Come, curse Jacob for me. Come, defy Israel.",
                "How shall I curse whom God has not cursed? How shall I defy whom Yahweh has not defied?",
                "For from the top of the rocks I see him. From the hills I see him. Behold, it is a people that dwells alone, and shall not be reckoned among the nations.",
                "Who can count the dust of Jacob, or number the fourth part of Israel? Let me die the death of the righteous! Let my last end be like his!\"",
                "Balak said to Balaam, \"What have you done to me? I took you to curse my enemies, and behold, you have blessed them altogether.\"",
                "He answered and said, \"Must I not take heed to speak that which Yahweh puts in my mouth?\"",
                "Balak said to him, \"Please come with me to another place, where you may see them; you shall see but the utmost part of them, and shall not see them all: and curse me them from there.\"",
                "He took him into the field of Zophim, to the top of Pisgah, and built seven altars, and offered up a bull and a ram on every altar.",
                "He said to Balak, \"Stand here by your burnt offering, while I meet over there.\"",
                "Yahweh met Balaam, and put a word in his mouth, and said, \"Return to Balak, and say this.\"",
                "He came to him, and behold, he was standing by his burnt offering, and the princes of Moab with him. Balak said to him, \"What has Yahweh spoken?\"",
                "He took up his parable, and said, \"Rise up, Balak, and hear! Listen to me, you son of Zippor.",
                "God is not a man, that he should lie, nor the son of man, that he should repent. Has he said, and will he not do it? Or has he spoken, and will he not make it good?",
                "Behold, I have received a command to bless. He has blessed, and I can\u2019t reverse it.",
                "He has not seen iniquity in Jacob. Neither has he seen perverseness in Israel. Yahweh his God is with him. The shout of a king is among them.",
                "God brings them out of Egypt. He has as it were the strength of the wild ox.",
                "Surely there is no enchantment with Jacob; Neither is there any divination with Israel. Now it shall be said of Jacob and of Israel, What has God done!",
                "Behold, the people rises up as a lioness, As a lion he lifts himself up. He shall not lie down until he eat of the prey, and drinks the blood of the slain.\"",
                "Balak said to Balaam, \"Neither curse them at all, nor bless them at all.\"",
                "But Balaam answered Balak, \"Didn\u2019t I tell you, saying, \u2018All that Yahweh speaks, that I must do?\u2019\"",
                "Balak said to Balaam, \"Come now, I will take you to another place; perhaps it will please God that you may curse me them from there.\"",
                "Balak took Balaam to the top of Peor, that looks down on the desert.",
                "Balaam said to Balak, \"Build me here seven altars, and prepare me here seven bulls and seven rams.\"",
                "Balak did as Balaam had said, and offered up a bull and a ram on every altar."
            ],
            24: [
                "When Balaam saw that it pleased Yahweh to bless Israel, he didn\u2019t go, as at the other times, to meet with enchantments, but he set his face toward the wilderness.",
                "Balaam lifted up his eyes, and he saw Israel dwelling according to their tribes; and the Spirit of God came on him.",
                "He took up his parable, and said, \"Balaam the son of Beor says, the man whose eye was closed says;",
                "he says, who hears the words of God, who sees the vision of the Almighty, falling down, and having his eyes open:",
                "How goodly are your tents, Jacob, and your tents, Israel!",
                "As valleys they are spread forth, as gardens by the riverside, as aloes which Yahweh has planted, as cedar trees beside the waters.",
                "Water shall flow from his buckets. His seed shall be in many waters. His king shall be higher than Agag. His kingdom shall be exalted.",
                "God brings him out of Egypt. He has as it were the strength of the wild ox. He shall eat up the nations his adversaries, shall break their bones in pieces, and pierce them with his arrows.",
                "He couched, he lay down as a lion, as a lioness; who shall rouse him up? Everyone who blesses you is blessed. Everyone who curses you is cursed.\"",
                "Balak\u2019s anger was kindled against Balaam, and he struck his hands together; and Balak said to Balaam, \"I called you to curse my enemies, and, behold, you have altogether blessed them these three times.",
                "Therefore now flee you to your place! I thought to promote you to great honor; but, behold, Yahweh has kept you back from honor.\"",
                "Balaam said to Balak, \"Didn\u2019t I also tell your messengers who you sent to me, saying,",
                "\u2018If Balak would give me his house full of silver and gold, I can\u2019t go beyond the word of Yahweh, to do either good or bad of my own mind. I will say what Yahweh says\u2019?",
                "Now, behold, I go to my people: come, I will inform you what this people shall do to your people in the latter days.\"",
                "He took up his parable, and said, \"Balaam the son of Beor says, the man whose eye was closed says;",
                "he says, who hears the words of God, knows the knowledge of the Most High, and who sees the vision of the Almighty, Falling down, and having his eyes open:",
                "I see him, but not now. I see him, but not near. A star will come out of Jacob. A scepter will rise out of Israel, and shall strike through the corners of Moab, and break down all the sons of Sheth.",
                "Edom shall be a possession. Seir, his enemies, also shall be a possession, while Israel does valiantly.",
                "Out of Jacob shall one have dominion, and shall destroy the remnant from the city.\"",
                "He looked at Amalek, and took up his parable, and said, \"Amalek was the first of the nations, But his latter end shall come to destruction.\"",
                "He looked at the Kenite, and took up his parable, and said, \"Your dwelling place is strong. Your nest is set in the rock.",
                "Nevertheless Kain shall be wasted, until Asshur carries you away captive.\"",
                "He took up his parable, and said, \"Alas, who shall live when God does this?",
                "But ships shall come from the coast of Kittim. They shall afflict Asshur, and shall afflict Eber. He also shall come to destruction.\"",
                "Balaam rose up, and went and returned to his place; and Balak also went his way."
            ],
            25: [
                "Israel stayed in Shittim; and the people began to play the prostitute with the daughters of Moab:",
                "for they called the people to the sacrifices of their gods; and the people ate, and bowed down to their gods.",
                "Israel joined himself to Baal Peor: and the anger of Yahweh was kindled against Israel.",
                "Yahweh said to Moses, \"Take all the chiefs of the people, and hang them up to Yahweh before the sun, that the fierce anger of Yahweh may turn away from Israel.\"",
                "Moses said to the judges of Israel, \"Everyone kill his men who have joined themselves to Baal Peor.\"",
                "Behold, one of the children of Israel came and brought to his brothers a Midianite woman in the sight of Moses, and in the sight of all the congregation of the children of Israel, while they were weeping at the door of the Tent of Meeting.",
                "When Phinehas, the son of Eleazar, the son of Aaron the priest, saw it, he rose up from the midst of the congregation, and took a spear in his hand;",
                "and he went after the man of Israel into the pavilion, and thrust both of them through, the man of Israel, and the woman through her body. So the plague was stayed from the children of Israel.",
                "Those who died by the plague were twenty-four thousand.",
                "Yahweh spoke to Moses, saying,",
                "\"Phinehas, the son of Eleazar, the son of Aaron the priest, has turned my wrath away from the children of Israel, in that he was jealous with my jealousy among them, so that I didn\u2019t consume the children of Israel in my jealousy.",
                "Therefore say, \u2018Behold, I give to him my covenant of peace:",
                "and it shall be to him, and to his seed after him, the covenant of an everlasting priesthood; because he was jealous for his God, and made atonement for the children of Israel.\u2019\"",
                "Now the name of the man of Israel that was slain, who was slain with the Midianite woman, was Zimri, the son of Salu, a prince of a fathers\u2019 house among the Simeonites.",
                "The name of the Midianite woman who was slain was Cozbi, the daughter of Zur; he was head of the people of a fathers\u2019 house in Midian.",
                "Yahweh spoke to Moses, saying,",
                "\"Harass the Midianites, and strike them;",
                "for they harassed you with their wiles, with which they have deceived you in the matter of Peor, and in the matter of Cozbi, the daughter of the prince of Midian, their sister, who was slain on the day of the plague in the matter of Peor.\""
            ],
            26: [
                "It happened after the plague, that Yahweh spoke to Moses and to Eleazar the son of Aaron the priest, saying,",
                "\"Take a census of all the congregation of the children of Israel, from twenty years old and upward, by their fathers\u2019 houses, all who are able to go forth to war in Israel.\"",
                "Moses and Eleazar the priest spoke with them in the plains of Moab by the Jordan at Jericho, saying,",
                "\"Take a census, from twenty years old and upward; as Yahweh commanded Moses and the children of Israel.\" These are those that came out of the land of Egypt.",
                "Reuben, the firstborn of Israel; the sons of Reuben: of Hanoch, the family of the Hanochites; of Pallu, the family of the Palluites;",
                "of Hezron, the family of the Hezronites; of Carmi, the family of the Carmites.",
                "These are the families of the Reubenites; and those who were numbered of them were forty-three thousand seven hundred thirty.",
                "The sons of Pallu: Eliab.",
                "The sons of Eliab: Nemuel, and Dathan, and Abiram. These are that Dathan and Abiram, who were called of the congregation, who strove against Moses and against Aaron in the company of Korah, when they strove against Yahweh,",
                "and the earth opened its mouth, and swallowed them up together with Korah, when that company died; what time the fire devoured two hundred fifty men, and they became a sign.",
                "Notwithstanding, the sons of Korah didn\u2019t die.",
                "The sons of Simeon after their families: of Nemuel, the family of the Nemuelites; of Jamin, the family of the Jaminites; of Jachin, the family of the Jachinites;",
                "of Zerah, the family of the Zerahites; of Shaul, the family of the Shaulites.",
                "These are the families of the Simeonites, twenty-two thousand two hundred.",
                "The sons of Gad after their families: of Zephon, the family of the Zephonites; of Haggi, the family of the Haggites; of Shuni, the family of the Shunites;",
                "of Ozni, the family of the Oznites; of Eri, the family of the Erites;",
                "of Arod, the family of the Arodites; of Areli, the family of the Arelites.",
                "These are the families of the sons of Gad according to those who were numbered of them, forty thousand and five hundred.",
                "The sons of Judah: Er and Onan; and Er and Onan died in the land of Canaan.",
                "The sons of Judah after their families were: of Shelah, the family of the Shelanites; of Perez, the family of the Perezites; of Zerah, the family of the Zerahites.",
                "The sons of Perez were: of Hezron, the family of the Hezronites; of Hamul, the family of the Hamulites.",
                "These are the families of Judah according to those who were numbered of them, seventy-six thousand five hundred.",
                "The sons of Issachar after their families: of Tola, the family of the Tolaites; of Puvah, the family of the Punites;",
                "of Jashub, the family of the Jashubites; of Shimron, the family of the Shimronites.",
                "These are the families of Issachar according to those who were numbered of them, sixty-four thousand three hundred.",
                "The sons of Zebulun after their families: of Sered, the family of the Seredites; of Elon, the family of the Elonites; of Jahleel, the family of the Jahleelites.",
                "These are the families of the Zebulunites according to those who were numbered of them, sixty thousand five hundred.",
                "The sons of Joseph after their families: Manasseh and Ephraim.",
                "The sons of Manasseh: of Machir, the family of the Machirites; and Machir became the father of Gilead; of Gilead, the family of the Gileadites.",
                "These are the sons of Gilead: of Iezer, the family of the Iezerites; of Helek, the family of the Helekites;",
                "and Asriel, the family of the Asrielites; and Shechem, the family of the Shechemites;",
                "and Shemida, the family of the Shemidaites; and Hepher, the family of the Hepherites.",
                "Zelophehad the son of Hepher had no sons, but daughters: and the names of the daughters of Zelophehad were Mahlah, and Noah, Hoglah, Milcah, and Tirzah.",
                "These are the families of Manasseh; and those who were numbered of them were fifty-two thousand seven hundred.",
                "These are the sons of Ephraim after their families: of Shuthelah, the family of the Shuthelahites; of Becher, the family of the Becherites; of Tahan, the family of the Tahanites.",
                "These are the sons of Shuthelah: of Eran, the family of the Eranites.",
                "These are the families of the sons of Ephraim according to those who were numbered of them, thirty-two thousand five hundred. These are the sons of Joseph after their families.",
                "The sons of Benjamin after their families: of Bela, the family of the Belaites; of Ashbel, the family of the Ashbelites; of Ahiram, the family of the Ahiramites;",
                "of Shephupham, the family of the Shuphamites; of Hupham, the family of the Huphamites.",
                "The sons of Bela were Ard and Naaman: the family of the Ardites; of Naaman, the family of the Naamites.",
                "These are the sons of Benjamin after their families; and those who were numbered of them were forty-five thousand six hundred.",
                "These are the sons of Dan after their families: of Shuham, the family of the Shuhamites. These are the families of Dan after their families.",
                "All the families of the Shuhamites, according to those who were numbered of them, were sixty-four thousand four hundred.",
                "The sons of Asher after their families: of Imnah, the family of the Imnites; of Ishvi, the family of the Ishvites; of Beriah, the family of the Berites.",
                "Of the sons of Beriah: of Heber, the family of the Heberites; of Malchiel, the family of the Malchielites.",
                "The name of the daughter of Asher was Serah.",
                "These are the families of the sons of Asher according to those who were numbered of them, fifty-three thousand and four hundred.",
                "The sons of Naphtali after their families: of Jahzeel, the family of the Jahzeelites; of Guni, the family of the Gunites;",
                "of Jezer, the family of the Jezerites; of Shillem, the family of the Shillemites.",
                "These are the families of Naphtali according to their families; and those who were numbered of them were forty-five thousand four hundred.",
                "These are those who were numbered of the children of Israel, six hundred one thousand seven hundred thirty.",
                "Yahweh spoke to Moses, saying,",
                "\"To these the land shall be divided for an inheritance according to the number of names.",
                "To the more you shall give the more inheritance, and to the fewer you shall give the less inheritance: to everyone according to those who were numbered of him shall his inheritance be given.",
                "Notwithstanding, the land shall be divided by lot: according to the names of the tribes of their fathers they shall inherit.",
                "According to the lot shall their inheritance be divided between the more and the fewer.\"",
                "These are those who were numbered of the Levites after their families: of Gershon, the family of the Gershonites; of Kohath, the family of the Kohathites; of Merari, the family of the Merarites.",
                "These are the families of Levi: the family of the Libnites, the family of the Hebronites, the family of the Mahlites, the family of the Mushites, the family of the Korahites. Kohath became the father of Amram.",
                "The name of Amram\u2019s wife was Jochebed, the daughter of Levi, who was born to Levi in Egypt: and she bore to Amram Aaron and Moses, and Miriam their sister.",
                "To Aaron were born Nadab and Abihu, Eleazar and Ithamar.",
                "Nadab and Abihu died, when they offered strange fire before Yahweh.",
                "Those who were numbered of them were twenty-three thousand, every male from a month old and upward: for they were not numbered among the children of Israel, because there was no inheritance given them among the children of Israel.",
                "These are those who were numbered by Moses and Eleazar the priest, who numbered the children of Israel in the plains of Moab by the Jordan at Jericho.",
                "But among these there was not a man of them who were numbered by Moses and Aaron the priest, who numbered the children of Israel in the wilderness of Sinai.",
                "For Yahweh had said of them, They shall surely die in the wilderness. There was not left a man of them, except Caleb the son of Jephunneh, and Joshua the son of Nun."
            ],
            27: [
                "Then drew near the daughters of Zelophehad, the son of Hepher, the son of Gilead, the son of Machir, the son of Manasseh, of the families of Manasseh the son of Joseph; and these are the names of his daughters: Mahlah, Noah, and Hoglah, and Milcah, and Tirzah.",
                "They stood before Moses, and before Eleazar the priest, and before the princes and all the congregation, at the door of the Tent of Meeting, saying,",
                "\"Our father died in the wilderness, and he was not among the company of those who gathered themselves together against Yahweh in the company of Korah: but he died in his own sin; and he had no sons.",
                "Why should the name of our father be taken away from among his family, because he had no son? Give to us a possession among the brothers of our father.\"",
                "Moses brought their cause before Yahweh.",
                "Yahweh spoke to Moses, saying,",
                "\"The daughters of Zelophehad speak right: you shall surely give them a possession of an inheritance among their father\u2019s brothers; and you shall cause the inheritance of their father to pass to them.",
                "You shall speak to the children of Israel, saying, \u2018If a man dies, and has no son, then you shall cause his inheritance to pass to his daughter.",
                "If he has no daughter, then you shall give his inheritance to his brothers.",
                "If he has no brothers, then you shall give his inheritance to his father\u2019s brothers.",
                "If his father has no brothers, then you shall give his inheritance to his kinsman who is next to him of his family, and he shall possess it: and it shall be to the children of Israel a statute and ordinance, as Yahweh commanded Moses.\u2019\"",
                "Yahweh said to Moses, \"Go up into this mountain of Abarim, and see the land which I have given to the children of Israel.",
                "When you have seen it, you also shall be gathered to your people, as Aaron your brother was gathered;",
                "because you rebelled against my word in the wilderness of Zin, in the strife of the congregation, to sanctify me at the waters before their eyes.\" (These are the waters of Meribah of Kadesh in the wilderness of Zin.)",
                "Moses spoke to Yahweh, saying,",
                "\"Let Yahweh, the God of the spirits of all flesh, appoint a man over the congregation,",
                "who may go out before them, and who may come in before them, and who may lead them out, and who may bring them in; that the congregation of Yahweh not be as sheep which have no shepherd.\"",
                "Yahweh said to Moses, \"Take Joshua the son of Nun, a man in whom is the Spirit, and lay your hand on him;",
                "and set him before Eleazar the priest, and before all the congregation; and commission him in their sight.",
                "You shall put of your honor on him, that all the congregation of the children of Israel may obey.",
                "He shall stand before Eleazar the priest, who shall inquire for him by the judgment of the Urim before Yahweh: at his word shall they go out, and at his word they shall come in, both he, and all the children of Israel with him, even all the congregation.\"",
                "Moses did as Yahweh commanded him; and he took Joshua, and set him before Eleazar the priest, and before all the congregation:",
                "and he laid his hands on him, and commissioned him, as Yahweh spoke by Moses."
            ],
            28: [
                "Yahweh spoke to Moses, saying,",
                "\"Command the children of Israel, and tell them, \u2018My offering, my food for my offerings made by fire, of a pleasant aroma to me, you shall observe to offer to me in their due season.\u2019",
                "You shall tell them, \u2018This is the offering made by fire which you shall offer to Yahweh: male lambs a year old without blemish, two day by day, for a continual burnt offering.",
                "You shall offer the one lamb in the morning, and you shall offer the other lamb at evening;",
                "with the tenth part of an ephah of fine flour for a meal offering, mixed with the fourth part of a hin of beaten oil.",
                "It is a continual burnt offering, which was ordained in Mount Sinai for a pleasant aroma, an offering made by fire to Yahweh.",
                "Its drink offering shall be the fourth part of a hin for the one lamb. You shall pour out a drink offering of strong drink to Yahweh in the holy place.",
                "The other lamb you shall offer at evening: as the meal offering of the morning, and as the drink offering of it, you shall offer it, an offering made by fire, of a pleasant aroma to Yahweh.",
                "\"\u2018On the Sabbath day two male lambs a year old without blemish, and two tenth parts of an ephah of fine flour for a meal offering, mixed with oil, and the drink offering of it:",
                "this is the burnt offering of every Sabbath, besides the continual burnt offering, and the drink offering of it.",
                "\"\u2018In the beginnings of your months you shall offer a burnt offering to Yahweh: two young bulls, and one ram, seven male lambs a year old without blemish;",
                "and three tenth parts of an ephah of fine flour for a meal offering, mixed with oil, for each bull; and two tenth parts of fine flour for a meal offering, mixed with oil, for the one ram;",
                "and a tenth part of fine flour mixed with oil for a meal offering to every lamb; for a burnt offering of a pleasant aroma, an offering made by fire to Yahweh.",
                "Their drink offerings shall be half a hin of wine for a bull, and the third part of a hin for the ram, and the fourth part of a hin for a lamb: this is the burnt offering of every month throughout the months of the year.",
                "One male goat for a sin offering to Yahweh; it shall be offered besides the continual burnt offering, and the drink offering of it.",
                "\"\u2018In the first month, on the fourteenth day of the month, is Yahweh\u2019s Passover.",
                "On the fifteenth day of this month shall be a feast: seven days shall unleavened bread be eaten.",
                "In the first day shall be a holy convocation: you shall do no servile work;",
                "but you shall offer an offering made by fire, a burnt offering to Yahweh: two young bulls, and one ram, and seven male lambs a year old; they shall be to you without blemish;",
                "and their meal offering, fine flour mixed with oil: you shall offer three tenth parts for a bull, and two tenth parts for the ram.",
                "You shall offer a tenth part for every lamb of the seven lambs;",
                "and one male goat for a sin offering, to make atonement for you.",
                "You shall offer these besides the burnt offering of the morning, which is for a continual burnt offering.",
                "In this way you shall offer daily, for seven days, the food of the offering made by fire, of a pleasant aroma to Yahweh: it shall be offered besides the continual burnt offering, and the drink offering of it.",
                "On the seventh day you shall have a holy convocation: you shall do no servile work.",
                "\"\u2018Also in the day of the first fruits, when you offer a new meal offering to Yahweh in your feast of weeks, you shall have a holy convocation; you shall do no servile work;",
                "but you shall offer a burnt offering for a pleasant aroma to Yahweh: two young bulls, one ram, seven male lambs a year old;",
                "and their meal offering, fine flour mixed with oil, three tenth parts for each bull, two tenth parts for the one ram,",
                "a tenth part for every lamb of the seven lambs;",
                "one male goat, to make atonement for you.",
                "Besides the continual burnt offering, and the meal offering of it, you shall offer them (they shall be to you without blemish), and their drink offerings."
            ],
            29: [
                "\"\u2018In the seventh month, on the first day of the month, you shall have a holy convocation; you shall do no servile work: it is a day of blowing of trumpets to you.",
                "You shall offer a burnt offering for a pleasant aroma to Yahweh: one young bull, one ram, seven male lambs a year old without blemish;",
                "and their meal offering, fine flour mixed with oil, three tenth parts for the bull, two tenth parts for the ram,",
                "and one tenth part for every lamb of the seven lambs;",
                "and one male goat for a sin offering, to make atonement for you;",
                "besides the burnt offering of the new moon, and the meal offering of it, and the continual burnt offering and the meal offering of it, and their drink offerings, according to their ordinance, for a pleasant aroma, an offering made by fire to Yahweh.",
                "\"\u2018On the tenth day of this seventh month you shall have a holy convocation; and you shall afflict your souls: you shall do no kind of work;",
                "but you shall offer a burnt offering to Yahweh for a pleasant aroma: one young bull, one ram, seven male lambs a year old; they shall be to you without blemish;",
                "and their meal offering, fine flour mixed with oil, three tenth parts for the bull, two tenth parts for the one ram,",
                "a tenth part for every lamb of the seven lambs:",
                "one male goat for a sin offering; besides the sin offering of atonement, and the continual burnt offering, and the meal offering of it, and their drink offerings.",
                "\"\u2018On the fifteenth day of the seventh month you shall have a holy convocation; you shall do no servile work, and you shall keep a feast to Yahweh seven days:",
                "and you shall offer a burnt offering, an offering made by fire, of a pleasant aroma to Yahweh; thirteen young bulls, two rams, fourteen male lambs a year old; they shall be without blemish;",
                "and their meal offering, fine flour mixed with oil, three tenth parts for every bull of the thirteen bulls, two tenth parts for each ram of the two rams,",
                "and a tenth part for every lamb of the fourteen lambs;",
                "and one male goat for a sin offering, besides the continual burnt offering, the meal offering of it, and the drink offering of it.",
                "\"\u2018On the second day you shall offer twelve young bulls, two rams, fourteen male lambs a year old without blemish;",
                "and their meal offering and their drink offerings for the bulls, for the rams, and for the lambs, according to their number, after the ordinance;",
                "and one male goat for a sin offering; besides the continual burnt offering, and the meal offering of it, and their drink offerings.",
                "\"\u2018On the third day eleven bulls, two rams, fourteen male lambs a year old without blemish;",
                "and their meal offering and their drink offerings for the bulls, for the rams, and for the lambs, according to their number, after the ordinance;",
                "and one male goat for a sin offering; besides the continual burnt offering, and the meal offering of it, and the drink offering of it.",
                "\"\u2018On the fourth day ten bulls, two rams, fourteen male lambs a year old without blemish;",
                "their meal offering and their drink offerings for the bulls, for the rams, and for the lambs, according to their number, after the ordinance;",
                "and one male goat for a sin offering; besides the continual burnt offering, the meal offering of it, and the drink offering of it.",
                "\"\u2018On the fifth day nine bulls, two rams, fourteen male lambs a year old without blemish;",
                "and their meal offering and their drink offerings for the bulls, for the rams, and for the lambs, according to their number, after the ordinance;",
                "and one male goat for a sin offering, besides the continual burnt offering, and the meal offering of it, and the drink offering of it.",
                "\"\u2018On the sixth day eight bulls, two rams, fourteen male lambs a year old without blemish;",
                "and their meal offering and their drink offerings for the bulls, for the rams, and for the lambs, according to their number, after the ordinance;",
                "and one male goat for a sin offering; besides the continual burnt offering, the meal offering of it, and the drink offerings of it.",
                "\"\u2018On the seventh day seven bulls, two rams, fourteen male lambs a year old without blemish;",
                "and their meal offering and their drink offerings for the bulls, for the rams, and for the lambs, according to their number, after the ordinance;",
                "and one male goat for a sin offering; besides the continual burnt offering, the meal offering of it, and the drink offering of it.",
                "\"\u2018On the eighth day you shall have a solemn assembly: you shall do no servile work;",
                "but you shall offer a burnt offering, an offering made by fire, of a pleasant aroma to Yahweh: one bull, one ram, seven male lambs a year old without blemish;",
                "their meal offering and their drink offerings for the bull, for the ram, and for the lambs, shall be according to their number, after the ordinance:",
                "and one male goat for a sin offering, besides the continual burnt offering, and the meal offering of it, and the drink offering of it.",
                "\"\u2018You shall offer these to Yahweh in your set feasts, besides your vows, and your freewill offerings, for your burnt offerings, and for your meal offerings, and for your drink offerings, and for your peace offerings.\u2019\"",
                "Moses told the children of Israel according to all that Yahweh commanded Moses."
            ],
            30: [
                "Moses spoke to the heads of the tribes of the children of Israel, saying, \"This is the thing which Yahweh has commanded.",
                "When a man vows a vow to Yahweh, or swears an oath to bind his soul with a bond, he shall not break his word; he shall do according to all that proceeds out of his mouth.",
                "\"Also when a woman vows a vow to Yahweh, and binds herself by a bond, being in her father\u2019s house, in her youth,",
                "and her father hears her vow, and her bond with which she has bound her soul, and her father holds his peace at her; then all her vows shall stand, and every bond with which she has bound her soul shall stand.",
                "But if her father disallow her in the day that he hears, none of her vows, or of her bonds with which she has bound her soul, shall stand: and Yahweh will forgive her, because her father disallowed her.",
                "\"If she has a husband, while her vows are on her, or the rash utterance of her lips, with which she has bound her soul,",
                "and her husband hears it, and hold his peace at her in the day that he hears it; then her vows shall stand, and her bonds with which she has bound her soul shall stand.",
                "But if her husband forbids her in the day that he hears it, then he shall make void her vow which is on her, and the rash utterance of her lips, with which she has bound her soul: and Yahweh will forgive her.",
                "\"But the vow of a widow, or of her who is divorced, everything with which she has bound her soul, shall stand against her.",
                "\"If she vowed in her husband\u2019s house, or bound her soul by a bond with an oath,",
                "and her husband heard it, and held his peace at her, and didn\u2019t disallow her; then all her vows shall stand, and every bond with which she bound her soul shall stand.",
                "But if her husband made them null and void in the day that he heard them, then whatever proceeded out of her lips concerning her vows, or concerning the bond of her soul, shall not stand: her husband has made them void; and Yahweh will forgive her.",
                "Every vow, and every binding oath to afflict the soul, her husband may establish it, or her husband may make it void.",
                "But if her husband altogether hold his peace at her from day to day, then he establishes all her vows, or all her bonds, which are on her: he has established them, because he held his peace at her in the day that he heard them.",
                "But if he shall make them null and void after that he has heard them, then he shall bear her iniquity.\"",
                "These are the statutes, which Yahweh commanded Moses, between a man and his wife, between a father and his daughter, being in her youth, in her father\u2019s house."
            ],
            31: [
                "Yahweh spoke to Moses, saying,",
                "\"Avenge the children of Israel for the Midianites. Afterward you shall be gathered to your people.\"",
                "Moses spoke to the people, saying, \"Arm men from among you for the war, that they may go against Midian, to execute Yahweh\u2019s vengeance on Midian.",
                "Of every tribe one thousand, throughout all the tribes of Israel, you shall send to the war.\"",
                "So there were delivered, out of the thousands of Israel, a thousand of every tribe, twelve thousand armed for war.",
                "Moses sent them, one thousand of every tribe, to the war, them and Phinehas the son of Eleazar the priest, to the war, with the vessels of the sanctuary and the trumpets for the alarm in his hand.",
                "They warred against Midian, as Yahweh commanded Moses; and they killed every male.",
                "They killed the kings of Midian with the rest of their slain: Evi, and Rekem, and Zur, and Hur, and Reba, the five kings of Midian: Balaam also the son of Beor they killed with the sword.",
                "The children of Israel took captive the women of Midian and their little ones; and all their livestock, and all their flocks, and all their goods, they took for a prey.",
                "All their cities in the places in which they lived, and all their encampments, they burnt with fire.",
                "They took all the spoil, and all the prey, both of man and of animal.",
                "They brought the captives, and the prey, and the spoil, to Moses, and to Eleazar the priest, and to the congregation of the children of Israel, to the camp at the plains of Moab, which are by the Jordan at Jericho.",
                "Moses, and Eleazar the priest, and all the princes of the congregation, went forth to meet them outside of the camp.",
                "Moses was angry with the officers of the army, the captains of thousands and the captains of hundreds, who came from the service of the war.",
                "Moses said to them, \"Have you saved all the women alive?",
                "Behold, these caused the children of Israel, through the counsel of Balaam, to commit trespass against Yahweh in the matter of Peor, and so the plague was among the congregation of Yahweh.",
                "Now therefore kill every male among the little ones, and kill every woman who has known man by lying with him.",
                "But all the girls, who have not known man by lying with him, keep alive for yourselves.",
                "\"Encamp outside of the camp seven days: whoever has killed any person, and whoever has touched any slain, purify yourselves on the third day and on the seventh day, you and your captives.",
                "As to every garment, and all that is made of skin, and all work of goats\u2019 hair, and all things made of wood, you shall purify yourselves.\"",
                "Eleazar the priest said to the men of war who went to the battle, \"This is the statute of the law which Yahweh has commanded Moses:",
                "however the gold, and the silver, the brass, the iron, the tin, and the lead,",
                "everything that may withstand the fire, you shall make to go through the fire, and it shall be clean; nevertheless it shall be purified with the water for impurity: and all that doesn\u2019t withstand the fire you shall make to go through the water.",
                "You shall wash your clothes on the seventh day, and you shall be clean; and afterward you shall come into the camp.\"",
                "Yahweh spoke to Moses, saying,",
                "\"Take the sum of the prey that was taken, both of man and of animal, you, and Eleazar the priest, and the heads of the fathers\u2019 households of the congregation;",
                "and divide the prey into two parts: between the men skilled in war, who went out to battle, and all the congregation.",
                "Levy a tribute to Yahweh of the men of war who went out to battle: one soul of five hundred; of the persons, of the cattle, of the donkeys, and of the flocks.",
                "Take it of their half, and give it to Eleazar the priest, for Yahweh\u2019s wave offering.",
                "Of the children of Israel\u2019s half, you shall take one drawn out of every fifty, of the persons, of the cattle, of the donkeys, and of the flocks, of all the livestock, and give them to the Levites, who perform the duty of the tabernacle of Yahweh.\"",
                "Moses and Eleazar the priest did as Yahweh commanded Moses.",
                "Now the prey, over and above the booty which the men of war took, was six hundred seventy-five thousand sheep,",
                "and seventy-two thousand head of cattle,",
                "and sixty-one thousand donkeys,",
                "and thirty-two thousand persons in all, of the women who had not known man by lying with him.",
                "The half, which was the portion of those who went out to war, was in number three hundred thirty-seven thousand five hundred sheep:",
                "and Yahweh\u2019s tribute of the sheep was six hundred seventy-five.",
                "The cattle were thirty-six thousand; of which Yahweh\u2019s tribute was seventy-two.",
                "The donkeys were thirty thousand five hundred; of which Yahweh\u2019s tribute was sixty-one.",
                "The persons were sixteen thousand; of whom Yahweh\u2019s tribute was thirty-two persons.",
                "Moses gave the tribute, which was Yahweh\u2019s wave offering, to Eleazar the priest, as Yahweh commanded Moses.",
                "Of the children of Israel\u2019s half, which Moses divided off from the men who warred",
                "(now the congregation\u2019s half was three hundred thirty-seven thousand five hundred sheep,",
                "and thirty-six thousand head of cattle,",
                "and thirty thousand five hundred donkeys,",
                "and sixteen thousand persons),",
                "even of the children of Israel\u2019s half, Moses took one drawn out of every fifty, both of man and of animal, and gave them to the Levites, who performed the duty of the tabernacle of Yahweh; as Yahweh commanded Moses.",
                "The officers who were over the thousands of the army, the captains of thousands, and the captains of hundreds, came near to Moses;",
                "and they said to Moses, \"Your servants have taken the sum of the men of war who are under our command, and there lacks not one man of us.",
                "We have brought Yahweh\u2019s offering, what every man has gotten, of jewels of gold, armlets, and bracelets, signet rings, earrings, and necklaces, to make atonement for our souls before Yahweh.\"",
                "Moses and Eleazar the priest took their gold, even all worked jewels.",
                "All the gold of the wave offering that they offered up to Yahweh, of the captains of thousands, and of the captains of hundreds, was sixteen thousand seven hundred fifty shekels.",
                "(The men of war had taken booty, every man for himself.)",
                "Moses and Eleazar the priest took the gold of the captains of thousands and of hundreds, and brought it into the Tent of Meeting, for a memorial for the children of Israel before Yahweh."
            ],
            32: [
                "Now the children of Reuben and the children of Gad had a very great multitude of livestock: and when they saw the land of Jazer, and the land of Gilead, that behold, the place was a place for livestock;",
                "the children of Gad and the children of Reuben came and spoke to Moses, and to Eleazar the priest, and to the princes of the congregation, saying,",
                "\"Ataroth, and Dibon, and Jazer, and Nimrah, and Heshbon, and Elealeh, and Sebam, and Nebo, and Beon,",
                "the land which Yahweh struck before the congregation of Israel, is a land for livestock; and your servants have livestock.\"",
                "They said, \"If we have found favor in your sight, let this land be given to your servants for a possession; don\u2019t bring us over the Jordan.\"",
                "Moses said to the children of Gad, and to the children of Reuben, \"Shall your brothers go to the war, and shall you sit here?",
                "Why do you discourage the heart of the children of Israel from going over into the land which Yahweh has given them?",
                "Your fathers did so when I sent them from Kadesh Barnea to see the land.",
                "For when they went up to the valley of Eshcol, and saw the land, they discouraged the heart of the children of Israel, that they should not go into the land which Yahweh had given them.",
                "Yahweh\u2019s anger was kindled in that day, and he swore, saying,",
                "\u2018Surely none of the men who came up out of Egypt, from twenty years old and upward, shall see the land which I swore to Abraham, to Isaac, and to Jacob; because they have not wholly followed me:",
                "except Caleb the son of Jephunneh the Kenizzite, and Joshua the son of Nun; because they have followed Yahweh completely.\u2019",
                "Yahweh\u2019s anger was kindled against Israel, and he made them wander back and forth in the wilderness forty years, until all the generation, who had done evil in the sight of Yahweh, was consumed.",
                "\"Behold, you have risen up in your fathers\u2019 place, an increase of sinful men, to augment yet the fierce anger of Yahweh toward Israel.",
                "For if you turn away from after him, he will yet again leave them in the wilderness; and you will destroy all this people.\"",
                "They came near to him, and said, \"We will build sheepfolds here for our livestock, and cities for our little ones:",
                "but we ourselves will be ready armed to go before the children of Israel, until we have brought them to their place: and our little ones shall dwell in the fortified cities because of the inhabitants of the land.",
                "We will not return to our houses, until the children of Israel have inherited every man his inheritance.",
                "For we will not inherit with them on the other side of the Jordan, and forward; because our inheritance is fallen to us on this side of the Jordan eastward.\"",
                "Moses said to them, \"If you will do this thing, if you will arm yourselves to go before Yahweh to the war,",
                "and every armed man of you will pass over the Jordan before Yahweh, until he has driven out his enemies from before him,",
                "and the land is subdued before Yahweh; then afterward you shall return, and be guiltless towards Yahweh, and towards Israel; and this land shall be to you for a possession before Yahweh.",
                "\"But if you will not do so, behold, you have sinned against Yahweh; and be sure your sin will find you out.",
                "Build cities for your little ones, and folds for your sheep; and do that which has proceeded out of your mouth.\"",
                "The children of Gad and the children of Reuben spoke to Moses, saying, \"Your servants will do as my lord commands.",
                "Our little ones, our wives, our flocks, and all our livestock, shall be there in the cities of Gilead;",
                "but your servants will pass over, every man who is armed for war, before Yahweh to battle, as my lord says.\"",
                "So Moses commanded concerning them to Eleazar the priest, and to Joshua the son of Nun, and to the heads of the fathers\u2019 households of the tribes of the children of Israel.",
                "Moses said to them, \"If the children of Gad and the children of Reuben will pass with you over the Jordan, every man who is armed to battle, before Yahweh, and the land shall be subdued before you; then you shall give them the land of Gilead for a possession:",
                "but if they will not pass over with you armed, they shall have possessions among you in the land of Canaan.\"",
                "The children of Gad and the children of Reuben answered, saying, \"As Yahweh has said to your servants, so will we do.",
                "We will pass over armed before Yahweh into the land of Canaan, and the possession of our inheritance shall remain with us beyond the Jordan.\"",
                "Moses gave to them, even to the children of Gad, and to the children of Reuben, and to the half-tribe of Manasseh the son of Joseph, the kingdom of Sihon king of the Amorites, and the kingdom of Og king of Bashan, the land, according to its cities and borders, even the cities of the surrounding land.",
                "The children of Gad built Dibon, and Ataroth, and Aroer,",
                "and Atrothshophan, and Jazer, and Jogbehah,",
                "and Beth Nimrah, and Beth Haran: fortified cities, and folds for sheep.",
                "The children of Reuben built Heshbon, and Elealeh, and Kiriathaim,",
                "and Nebo, and Baal Meon, (their names being changed), and Sibmah: and they gave other names to the cities which they built.",
                "The children of Machir the son of Manasseh went to Gilead, and took it, and dispossessed the Amorites who were therein.",
                "Moses gave Gilead to Machir the son of Manasseh; and he lived therein.",
                "Jair the son of Manasseh went and took its towns, and called them Havvoth Jair.",
                "Nobah went and took Kenath, and its villages, and called it Nobah, after his own name."
            ],
            33: [
                "These are the journeys of the children of Israel, when they went forth out of the land of Egypt by their armies under the hand of Moses and Aaron.",
                "Moses wrote their goings out according to their journeys by the commandment of Yahweh: and these are their journeys according to their goings out.",
                "They traveled from Rameses in the first month, on the fifteenth day of the first month; on the next day after the Passover the children of Israel went out with a high hand in the sight of all the Egyptians,",
                "while the Egyptians were burying all their firstborn, whom Yahweh had struck among them: on their gods also Yahweh executed judgments.",
                "The children of Israel traveled from Rameses, and encamped in Succoth.",
                "They traveled from Succoth, and encamped in Etham, which is in the edge of the wilderness.",
                "They traveled from Etham, and turned back to Pihahiroth, which is before Baal Zephon: and they encamped before Migdol.",
                "They traveled from before Hahiroth, and passed through the midst of the sea into the wilderness: and they went three days\u2019 journey in the wilderness of Etham, and encamped in Marah.",
                "They traveled from Marah, and came to Elim: and in Elim were twelve springs of water, and seventy palm trees; and they encamped there.",
                "They traveled from Elim, and encamped by the Red Sea.",
                "They traveled from the Red Sea, and encamped in the wilderness of Sin.",
                "They traveled from the wilderness of Sin, and encamped in Dophkah.",
                "They traveled from Dophkah, and encamped in Alush.",
                "They traveled from Alush, and encamped in Rephidim, where there was no water for the people to drink.",
                "They traveled from Rephidim, and encamped in the wilderness of Sinai.",
                "They traveled from the wilderness of Sinai, and encamped in Kibroth Hattaavah.",
                "They traveled from Kibroth Hattaavah, and encamped in Hazeroth.",
                "They traveled from Hazeroth, and encamped in Rithmah.",
                "They traveled from Rithmah, and encamped in Rimmon Perez.",
                "They traveled from Rimmon Perez, and encamped in Libnah.",
                "They traveled from Libnah, and encamped in Rissah.",
                "They traveled from Rissah, and encamped in Kehelathah.",
                "They traveled from Kehelathah, and encamped in Mount Shepher.",
                "They traveled from Mount Shepher, and encamped in Haradah.",
                "They traveled from Haradah, and encamped in Makheloth.",
                "They traveled from Makheloth, and encamped in Tahath.",
                "They traveled from Tahath, and encamped in Terah.",
                "They traveled from Terah, and encamped in Mithkah.",
                "They traveled from Mithkah, and encamped in Hashmonah.",
                "They traveled from Hashmonah, and encamped in Moseroth.",
                "They traveled from Moseroth, and encamped in Bene Jaakan.",
                "They traveled from Bene Jaakan, and encamped in Hor Haggidgad.",
                "They traveled from Hor Haggidgad, and encamped in Jotbathah.",
                "They traveled from Jotbathah, and encamped in Abronah.",
                "They traveled from Abronah, and encamped in Ezion Geber.",
                "They traveled from Ezion Geber, and encamped in the wilderness of Zin (the same is Kadesh).",
                "They traveled from Kadesh, and encamped in Mount Hor, in the edge of the land of Edom.",
                "Aaron the priest went up into Mount Hor at the commandment of Yahweh, and died there, in the fortieth year after the children of Israel had come out of the land of Egypt, in the fifth month, on the first day of the month.",
                "Aaron was one hundred twenty-three years old when he died in Mount Hor.",
                "The Canaanite, the king of Arad, who lived in the South in the land of Canaan, heard of the coming of the children of Israel.",
                "They traveled from Mount Hor, and encamped in Zalmonah.",
                "They traveled from Zalmonah, and encamped in Punon.",
                "They traveled from Punon, and encamped in Oboth.",
                "They traveled from Oboth, and encamped in Iye Abarim, in the border of Moab.",
                "They traveled from Iyim, and encamped in Dibon Gad.",
                "They traveled from Dibon Gad, and encamped in Almon Diblathaim.",
                "They traveled from Almon Diblathaim, and encamped in the mountains of Abarim, before Nebo.",
                "They traveled from the mountains of Abarim, and encamped in the plains of Moab by the Jordan at Jericho.",
                "They encamped by the Jordan, from Beth Jeshimoth even to Abel Shittim in the plains of Moab.",
                "Yahweh spoke to Moses in the plains of Moab by the Jordan at Jericho, saying,",
                "Speak to the children of Israel, and tell them, \"When you pass over the Jordan into the land of Canaan,",
                "then you shall drive out all the inhabitants of the land from before you, destroy all their stone idols, destroy all their molten images, and demolish all their high places.",
                "You shall take possession of the land, and dwell therein; for I have given given the land to you to possess it.",
                "You shall inherit the land by lot according to your families; to the more you shall give the more inheritance, and to the fewer you shall give the less inheritance: wherever the lot falls to any man, that shall be his. You shall inherit according to the tribes of your fathers.",
                "\"But if you do not drive out the inhabitants of the land from before you, then those you let remain of them will be as pricks in your eyes and as thorns in your sides, and they will harass you in the land in which you dwell.",
                "It shall happen that as I thought to do to them, so will I do to you.\""
            ],
            34: [
                "Yahweh spoke to Moses, saying,",
                "\"Command the children of Israel, and tell them, \u2018When you come into the land of Canaan (this is the land that shall fall to you for an inheritance, even the land of Canaan according to its borders),",
                "then your south quarter shall be from the wilderness of Zin along by the side of Edom, and your south border shall be from the end of the Salt Sea eastward;",
                "and your border shall turn about southward of the ascent of Akrabbim, and pass along to Zin; and the goings out of it shall be southward of Kadesh Barnea; and it shall go forth to Hazar Addar, and pass along to Azmon;",
                "and the border shall turn about from Azmon to the brook of Egypt, and the goings out of it shall be at the sea.",
                "\"\u2018For the western border, you shall have the great sea and its border: this shall be your west border.",
                "\"\u2018This shall be your north border: from the great sea you shall mark out for you Mount Hor;",
                "from Mount Hor you shall mark out to the entrance of Hamath; and the goings out of the border shall be at Zedad;",
                "and the border shall go forth to Ziphron, and the goings out of it shall be at Hazar Enan: this shall be your north border.",
                "\"\u2018You shall mark out your east border from Hazar Enan to Shepham;",
                "and the border shall go down from Shepham to Riblah, on the east side of Ain; and the border shall go down, and shall reach to the side of the sea of Chinnereth eastward;",
                "and the border shall go down to the Jordan, and the goings out of it shall be at the Salt Sea. This shall be your land according to its borders around it.\u2019\"",
                "Moses commanded the children of Israel, saying, \"This is the land which you shall inherit by lot, which Yahweh has commanded to give to the nine tribes, and to the half-tribe;",
                "for the tribe of the children of Reuben according to their fathers\u2019 houses, and the tribe of the children of Gad according to their fathers\u2019 houses, have received, and the half-tribe of Manasseh have received, their inheritance:",
                "the two tribes and the half-tribe have received their inheritance beyond the Jordan at Jericho eastward, toward the sunrise.\"",
                "Yahweh spoke to Moses, saying,",
                "\"These are the names of the men who shall divide the land to you for inheritance: Eleazar the priest, and Joshua the son of Nun.",
                "You shall take one prince of every tribe, to divide the land for inheritance.",
                "These are the names of the men: Of the tribe of Judah, Caleb the son of Jephunneh.",
                "Of the tribe of the children of Simeon, Shemuel the son of Ammihud.",
                "Of the tribe of Benjamin, Elidad the son of Chislon.",
                "Of the tribe of the children of Dan a prince, Bukki the son of Jogli.",
                "Of the children of Joseph: of the tribe of the children of Manasseh a prince, Hanniel the son of Ephod.",
                "Of the tribe of the children of Ephraim a prince, Kemuel the son of Shiphtan.",
                "Of the tribe of the children of Zebulun a prince, Elizaphan the son of Parnach.",
                "Of the tribe of the children of Issachar a prince, Paltiel the son of Azzan.",
                "Of the tribe of the children of Asher a prince, Ahihud the son of Shelomi.",
                "Of the tribe of the children of Naphtali a prince, Pedahel the son of Ammihud.\"",
                "These are they whom Yahweh commanded to divide the inheritance to the children of Israel in the land of Canaan."
            ],
            35: [
                "Yahweh spoke to Moses in the plains of Moab by the Jordan at Jericho, saying,",
                "\"Command the children of Israel that they give to the Levites of the inheritance of their possession cities to dwell in; and you shall give suburbs for the cities around them to the Levites.",
                "The cities shall they have to dwell in; and their suburbs shall be for their livestock, and for their substance, and for all their animals.",
                "\"The suburbs of the cities, which you shall give to the Levites, shall be from the wall of the city and outward one thousand cubits around it.",
                "You shall measure outside of the city for the east side two thousand cubits, and for the south side two thousand cubits, and for the west side two thousand cubits, and for the north side two thousand cubits, the city being in the midst. This shall be to them the suburbs of the cities.",
                "\"The cities which you shall give to the Levites, they shall be the six cities of refuge, which you shall give for the manslayer to flee to: and besides them you shall give forty-two cities.",
                "All the cities which you shall give to the Levites shall be forty-eight cities together with their suburbs.",
                "Concerning the cities which you shall give of the possession of the children of Israel, from the many you shall take many; and from the few you shall take few: everyone according to his inheritance which he inherits shall give of his cities to the Levites.\"",
                "Yahweh spoke to Moses, saying,",
                "\"Speak to the children of Israel, and tell them, \u2018When you pass over the Jordan into the land of Canaan,",
                "then you shall appoint you cities to be cities of refuge for you, that the manslayer who kills any person unwittingly may flee there.",
                "The cities shall be to you for refuge from the avenger, that the manslayer not die, until he stands before the congregation for judgment.",
                "The cities which you shall give shall be for you six cities of refuge.",
                "You shall give three cities beyond the Jordan, and you shall give three cities in the land of Canaan; they shall be cities of refuge.",
                "For the children of Israel, and for the stranger and for the foreigner living among them, shall these six cities be for refuge; that everyone who kills any person unwittingly may flee there.",
                "\"\u2018But if he struck him with an instrument of iron, so that he died, he is a murderer: the murderer shall surely be put to death.",
                "If he struck him with a stone in the hand, by which a man may die, and he died, he is a murderer: the murderer shall surely be put to death.",
                "Or if he struck him with a weapon of wood in the hand, by which a man may die, and he died, he is a murderer: the murderer shall surely be put to death.",
                "The avenger of blood shall himself put the murderer to death: when he meets him, he shall put him to death.",
                "If he thrust him of hatred, or hurled at him, lying in wait, so that he died,",
                "or in enmity struck him with his hand, so that he died; he who struck him shall surely be put to death; he is a murderer: the avenger of blood shall put the murderer to death, when he meets him.",
                "\"\u2018But if he thrust him suddenly without enmity, or hurled on him anything without lying in wait,",
                "or with any stone, by which a man may die, not seeing him, and cast it on him, so that he died, and he was not his enemy, neither sought his harm;",
                "then the congregation shall judge between the striker and the avenger of blood according to these ordinances;",
                "and the congregation shall deliver the manslayer out of the hand of the avenger of blood, and the congregation shall restore him to his city of refuge, where he was fled: and he shall dwell therein until the death of the high priest, who was anointed with the holy oil.",
                "\"\u2018But if the manslayer shall at any time go beyond the border of his city of refuge, where he flees,",
                "and the avenger of blood find him outside of the border of his city of refuge, and the avenger of blood kill the manslayer; he shall not be guilty of blood,",
                "because he should have remained in his city of refuge until the death of the high priest: but after the death of the high priest the manslayer shall return into the land of his possession.",
                "\"\u2018These things shall be for a statute and ordinance to you throughout your generations in all your dwellings.",
                "\"\u2018Whoever kills any person, the murderer shall be slain at the mouth of witnesses: but one witness shall not testify against any person that he die.",
                "\"\u2018Moreover you shall take no ransom for the life of a murderer who is guilty of death; but he shall surely be put to death.",
                "\"\u2018You shall take no ransom for him who is fled to his city of refuge, that he may come again to dwell in the land, until the death of the priest.",
                "\"\u2018So you shall not pollute the land in which you are: for blood, it pollutes the land; and no expiation can be made for the land for the blood that is shed therein, but by the blood of him who shed it.",
                "You shall not defile the land which you inhabit, in the midst of which I dwell: for I, Yahweh, dwell in the midst of the children of Israel.\u2019\""
            ],
            36: [
                "The heads of the fathers\u2019 households of the family of the children of Gilead, the son of Machir, the son of Manasseh, of the families of the sons of Joseph, came near, and spoke before Moses, and before the princes, the heads of the fathers\u2019 households of the children of Israel:",
                "and they said, \"Yahweh commanded my lord to give the land for inheritance by lot to the children of Israel: and my lord was commanded by Yahweh to give the inheritance of Zelophehad our brother to his daughters.",
                "If they are married to any of the sons of the other tribes of the children of Israel, then will their inheritance be taken away from the inheritance of our fathers, and will be added to the inheritance of the tribe whereunto they shall belong: so will it be taken away from the lot of our inheritance.",
                "When the jubilee of the children of Israel shall be, then will their inheritance be added to the inheritance of the tribe whereunto they shall belong: so will their inheritance be taken away from the inheritance of the tribe of our fathers.\"",
                "Moses commanded the children of Israel according to the word of Yahweh, saying, \"The tribe of the sons of Joseph speaks right.",
                "This is the thing which Yahweh does command concerning the daughters of Zelophehad, saying, Let them be married to whom they think best; only into the family of the tribe of their father shall they be married.",
                "So shall no inheritance of the children of Israel remove from tribe to tribe; for the children of Israel shall all keep the inheritance of the tribe of his fathers.",
                "Every daughter who possesses an inheritance in any tribe of the children of Israel shall be wife to one of the family of the tribe of her father, that the children of Israel may possess every man the inheritance of his fathers.",
                "So shall no inheritance remove from one tribe to another tribe; for the tribes of the children of Israel shall each keep his own inheritance.\"",
                "The daughters of Zelophehad did as Yahweh commanded Moses:",
                "for Mahlah, Tirzah, and Hoglah, and Milcah, and Noah, the daughters of Zelophehad, were married to their father\u2019s brothers\u2019 sons.",
                "They were married into the families of the sons of Manasseh the son of Joseph; and their inheritance remained in the tribe of the family of their father.",
                "These are the commandments and the ordinances which Yahweh commanded by Moses to the children of Israel in the plains of Moab by the Jordan at Jericho."
            ]
        },
        5: {
            1: [
                "These are the words which Moses spoke to all Israel beyond the Jordan in the wilderness, in the Arabah over against Suph, between Paran, and Tophel, and Laban, and Hazeroth, and Dizahab.",
                "It is eleven days\u2019 journey from Horeb by the way of Mount Seir to Kadesh Barnea.",
                "It happened in the fortieth year, in the eleventh month, on the first day of the month, that Moses spoke to the children of Israel, according to all that Yahweh had given him in commandment to them;",
                "after he had struck Sihon the king of the Amorites, who lived in Heshbon, and Og the king of Bashan, who lived in Ashtaroth, at Edrei.",
                "Beyond the Jordan, in the land of Moab, began Moses to declare this law, saying,",
                "\"Yahweh our God spoke to us in Horeb, saying, You have lived long enough in this mountain:",
                "turn, and take your journey, and go to the hill country of the Amorites, and to all the places near there, in the Arabah, in the hill country, and in the lowland, and in the South, and by the seashore, the land of the Canaanites, and Lebanon, as far as the great river, the river Euphrates.",
                "Behold, I have set the land before you: go in and possess the land which Yahweh swore to your fathers, to Abraham, to Isaac, and to Jacob, to give to them and to their seed after them.\"",
                "I spoke to you at that time, saying, \"I am not able to bear you myself alone:",
                "Yahweh your God has multiplied you, and behold, you are this day as the stars of the sky for multitude.",
                "Yahweh, the God of your fathers, make you a thousand times as many as you are, and bless you, as he has promised you!",
                "How can I myself alone bear your encumbrance, and your burden, and your strife?",
                "Take wise men of understanding and well known according to your tribes, and I will make them heads over you.\"",
                "You answered me, and said, \"The thing which you have spoken is good to do.\"",
                "So I took the heads of your tribes, wise men, and known, and made them heads over you, captains of thousands, and captains of hundreds, and captains of fifties, and captains of tens, and officers, according to your tribes.",
                "I commanded your judges at that time, saying, \"Hear cases between your brothers, and judge righteously between a man and his brother, and the foreigner who is living with him.",
                "You shall not show partiality in judgment; you shall hear the small and the great alike; you shall not be afraid of the face of man; for the judgment is God\u2019s. The case that is too hard for you, you shall bring to me, and I will hear it.\"",
                "I commanded you at that time all the things which you should do.",
                "We traveled from Horeb, and went through all that great and terrible wilderness which you saw, by the way to the hill country of the Amorites, as Yahweh our God commanded us; and we came to Kadesh Barnea.",
                "I said to you, \"You have come to the hill country of the Amorites, which Yahweh our God gives to us.",
                "Behold, Yahweh your God has set the land before you: go up, take possession, as Yahweh, the God of your fathers, has spoken to you; don\u2019t be afraid, neither be dismayed.\"",
                "You came near to me everyone of you, and said, \"Let us send men before us, that they may search the land for us, and bring us word again of the way by which we must go up, and the cities to which we shall come.\"",
                "The thing pleased me well; and I took twelve men of you, one man for every tribe:",
                "and they turned and went up into the hill country, and came to the valley of Eshcol, and spied it out.",
                "They took of the fruit of the land in their hands, and brought it down to us, and brought us word again, and said, \"It is a good land which Yahweh our God gives to us.\"",
                "Yet you wouldn\u2019t go up, but rebelled against the commandment of Yahweh your God:",
                "and you murmured in your tents, and said, \"Because Yahweh hated us, he has brought us forth out of the land of Egypt, to deliver us into the hand of the Amorites, to destroy us.",
                "Where are we going up? our brothers have made our heart to melt, saying, \u2018The people are greater and taller than we; the cities are great and fortified up to the sky; and moreover we have seen the sons of the Anakim there.\u2019\"",
                "Then I said to you, \"Don\u2019t dread, neither be afraid of them.",
                "Yahweh your God who goes before you, he will fight for you, according to all that he did for you in Egypt before your eyes,",
                "and in the wilderness, where you have seen how that Yahweh your God bore you, as a man does bear his son, in all the way that you went, until you came to this place.\"",
                "Yet in this thing you didn\u2019t believe Yahweh your God,",
                "who went before you in the way, to seek you out a place to pitch your tents in, in fire by night, to show you by what way you should go, and in the cloud by day.",
                "Yahweh heard the voice of your words, and was angry, and swore, saying,",
                "\"Surely not one of these men of this evil generation shall see the good land, which I swore to give to your fathers,",
                "except Caleb the son of Jephunneh: he shall see it; and to him will I give the land that he has trodden on, and to his children, because he has wholly followed Yahweh.\"",
                "Also Yahweh was angry with me for your sakes, saying, \"You also shall not go in there:",
                "Joshua the son of Nun, who stands before you, he shall go in there: encourage you him; for he shall cause Israel to inherit it.",
                "Moreover your little ones, whom you said should be a prey, and your children, who this day have no knowledge of good or evil, they shall go in there, and to them will I give it, and they shall possess it.",
                "But as for you, turn, and take your journey into the wilderness by the way to the Red Sea.\"",
                "Then you answered and said to me, \"We have sinned against Yahweh, we will go up and fight, according to all that Yahweh our God commanded us.\" Every man of you put on his weapons of war, and presumed to go up into the hill country.",
                "Yahweh said to me, \"Tell them, \u2018Don\u2019t go up, neither fight; for I am not among you; lest you be struck before your enemies.\u2019\"",
                "So I spoke to you, and you didn\u2019t listen; but you rebelled against the commandment of Yahweh, and were presumptuous, and went up into the hill country.",
                "The Amorites, who lived in that hill country, came out against you, and chased you, as bees do, and beat you down in Seir, even to Hormah.",
                "You returned and wept before Yahweh; but Yahweh didn\u2019t listen to your voice, nor gave ear to you.",
                "So you stayed in Kadesh many days, according to the days that you remained."
            ],
            2: [
                "Then we turned, and took our journey into the wilderness by the way to the Red Sea, as Yahweh spoke to me; and we encircled Mount Seir many days.",
                "Yahweh spoke to me, saying,",
                "\"You have encircled this mountain long enough. Turn northward.",
                "Command the people, saying, \u2018You are to pass through the border of your brothers the children of Esau, who dwell in Seir; and they will be afraid of you: take good heed to yourselves therefore;",
                "don\u2019t contend with them; for I will not give you of their land, no, not so much as for the sole of the foot to tread on; because I have given Mount Seir to Esau for a possession.",
                "You shall purchase food of them for money, that you may eat; and you shall also buy water of them for money, that you may drink.\u2019\"",
                "For Yahweh your God has blessed you in all the work of your hand; he has known your walking through this great wilderness: these forty years Yahweh your God has been with you; you have lacked nothing.",
                "So we passed by from our brothers the children of Esau, who dwell in Seir, from the way of the Arabah from Elath and from Ezion Geber. We turned and passed by the way of the wilderness of Moab.",
                "Yahweh said to me, \"Don\u2019t bother Moab, neither contend with them in battle; for I will not give you of his land for a possession; because I have given Ar to the children of Lot for a possession.\"",
                "(The Emim lived therein before, a people great, and many, and tall, as the Anakim:",
                "these also are accounted Rephaim, as the Anakim; but the Moabites call them Emim.",
                "The Horites also lived in Seir before, but the children of Esau succeeded them; and they destroyed them from before them, and lived in their place; as Israel did to the land of his possession, which Yahweh gave to them.)",
                "\"Now rise up, and cross over the brook Zered.\" We went over the brook Zered.",
                "The days in which we came from Kadesh Barnea, until we had come over the brook Zered, were thirty-eight years; until all the generation of the men of war were consumed from the midst of the camp, as Yahweh swore to them.",
                "Moreover the hand of Yahweh was against them, to destroy them from the midst of the camp, until they were consumed.",
                "So it happened, when all the men of war were consumed and dead from among the people,",
                "that Yahweh spoke to me, saying,",
                "\"You are this day to pass over Ar, the border of Moab:",
                "and when you come near over against the children of Ammon, don\u2019t bother them, nor contend with them; for I will not give you of the land of the children of Ammon for a possession; because I have given it to the children of Lot for a possession.\"",
                "(That also is accounted a land of Rephaim: Rephaim lived therein before; but the Ammonites call them Zamzummim,",
                "a people great, and many, and tall, as the Anakim; but Yahweh destroyed them before them; and they succeeded them, and lived in their place;",
                "as he did for the children of Esau, who dwell in Seir, when he destroyed the Horites from before them; and they succeeded them, and lived in their place even to this day:",
                "and the Avvim, who lived in villages as far as Gaza, the Caphtorim, who came forth out of Caphtor, destroyed them, and lived in their place.)",
                "\"Rise up, take your journey, and pass over the valley of the Arnon: behold, I have given into your hand Sihon the Amorite, king of Heshbon, and his land; begin to possess it, and contend with him in battle.",
                "This day will I begin to put the dread of you and the fear of you on the peoples who are under the whole sky, who shall hear the report of you, and shall tremble, and be in anguish because of you.\"",
                "I sent messengers out of the wilderness of Kedemoth to Sihon king of Heshbon with words of peace, saying,",
                "\"Let me pass through your land: I will go along by the highway, I will turn neither to the right hand nor to the left.",
                "You shall sell me food for money, that I may eat; and give me water for money, that I may drink: only let me pass through on my feet,",
                "as the children of Esau who dwell in Seir, and the Moabites who dwell in Ar, did to me; until I shall pass over the Jordan into the land which Yahweh our God gives us.\"",
                "But Sihon king of Heshbon would not let us pass by him; for Yahweh your God hardened his spirit, and made his heart obstinate, that he might deliver him into your hand, as at this day.",
                "Yahweh said to me, \"Behold, I have begun to deliver up Sihon and his land before you: begin to possess, that you may inherit his land.\"",
                "Then Sihon came out against us, he and all his people, to battle at Jahaz.",
                "Yahweh our God delivered him up before us; and we struck him, and his sons, and all his people.",
                "We took all his cities at that time, and utterly destroyed every inhabited city, with the women and the little ones; we left none remaining:",
                "only the livestock we took for a prey to ourselves, with the spoil of the cities which we had taken.",
                "From Aroer, which is on the edge of the valley of the Arnon, and the city that is in the valley, even to Gilead, there was not a city too high for us; Yahweh our God delivered up all before us:",
                "only to the land of the children of Ammon you didn\u2019t come near; all the side of the river Jabbok, and the cities of the hill country, and wherever Yahweh our God forbade us."
            ],
            3: [
                "Then we turned, and went up the way to Bashan: and Og the king of Bashan came out against us, he and all his people, to battle at Edrei.",
                "Yahweh said to me, \"Don\u2019t fear him; for I have delivered him, and all his people, and his land, into your hand; and you shall do to him as you did to Sihon king of the Amorites, who lived at Heshbon.\"",
                "So Yahweh our God delivered into our hand Og also, the king of Bashan, and all his people: and we struck him until none was left to him remaining.",
                "We took all his cities at that time; there was not a city which we didn\u2019t take from them; sixty cities, all the region of Argob, the kingdom of Og in Bashan.",
                "All these were cities fortified with high walls, gates, and bars; besides the unwalled towns a great many.",
                "We utterly destroyed them, as we did to Sihon king of Heshbon, utterly destroying every inhabited city, with the women and the little ones.",
                "But all the livestock, and the spoil of the cities, we took for a prey to ourselves.",
                "We took the land at that time out of the hand of the two kings of the Amorites who were beyond the Jordan, from the valley of the Arnon to Mount Hermon.",
                "(The Sidonians call Hermon Sirion, and the Amorites call it Senir.)",
                "We took all the cities of the plain, and all Gilead, and all Bashan, to Salecah and Edrei, cities of the kingdom of Og in Bashan.",
                "(For only Og king of Bashan remained of the remnant of the Rephaim; behold, his bedstead was a bedstead of iron; isn\u2019t it in Rabbah of the children of Ammon? nine cubits was its length, and four cubits its breadth, after the cubit of a man.)",
                "This land we took in possession at that time: from Aroer, which is by the valley of the Arnon, and half the hill country of Gilead, and its cities, gave I to the Reubenites and to the Gadites:",
                "and the rest of Gilead, and all Bashan, the kingdom of Og, gave I to the half-tribe of Manasseh; all the region of Argob, even all Bashan. (The same is called the land of Rephaim.",
                "Jair the son of Manasseh took all the region of Argob, to the border of the Geshurites and the Maacathites, and called them, even Bashan, after his own name, Havvoth Jair, to this day.)",
                "I gave Gilead to Machir.",
                "To the Reubenites and to the Gadites I gave from Gilead even to the valley of the Arnon, the middle of the valley, and its border, even to the river Jabbok, which is the border of the children of Ammon;",
                "the Arabah also, and the Jordan and its border, from Chinnereth even to the sea of the Arabah, the Salt Sea, under the slopes of Pisgah eastward.",
                "I commanded you at that time, saying, \"Yahweh your God has given you this land to possess it: you shall pass over armed before your brothers the children of Israel, all the men of valor.",
                "But your wives, and your little ones, and your livestock, (I know that you have much livestock), shall live in your cities which I have given you,",
                "until Yahweh gives rest to your brothers, as to you, and they also possess the land which Yahweh your God gives them beyond the Jordan: then you shall return every man to his possession, which I have given you.\"",
                "I commanded Joshua at that time, saying, \"Your eyes have seen all that Yahweh your God has done to these two kings: so shall Yahweh do to all the kingdoms where you go over.",
                "You shall not fear them; for Yahweh your God, he it is who fights for you.\"",
                "I begged Yahweh at that time, saying,",
                "\"Lord Yahweh, you have begun to show your servant your greatness, and your strong hand: for what god is there in heaven or in earth, that can do according to your works, and according to your mighty acts?",
                "Please let me go over and see the good land that is beyond the Jordan, that goodly mountain, and Lebanon.\"",
                "But Yahweh was angry with me for your sakes, and didn\u2019t listen to me; and Yahweh said to me, \"Let it suffice you; speak no more to me of this matter.",
                "Go up to the top of Pisgah, and lift up your eyes westward, and northward, and southward, and eastward, and see with your eyes: for you shall not go over this Jordan.",
                "But commission Joshua, and encourage him, and strengthen him; for he shall go over before this people, and he shall cause them to inherit the land which you shall see.\"",
                "So we stayed in the valley over against Beth Peor."
            ],
            4: [
                "Now, Israel, listen to the statutes and to the ordinances, which I teach you, to do them; that you may live, and go in and possess the land which Yahweh, the God of your fathers, gives you.",
                "You shall not add to the word which I command you, neither shall you diminish from it, that you may keep the commandments of Yahweh your God which I command you.",
                "Your eyes have seen what Yahweh did because of Baal Peor; for all the men who followed Baal Peor, Yahweh your God has destroyed them from the midst of you.",
                "But you who were faithful to Yahweh your God are all alive this day.",
                "Behold, I have taught you statutes and ordinances, even as Yahweh my God commanded me, that you should do so in the midst of the land where you go in to possess it.",
                "Keep therefore and do them; for this is your wisdom and your understanding in the sight of the peoples, who shall hear all these statutes, and say, \"Surely this great nation is a wise and understanding people.\"",
                "For what great nation is there, that has a god so near to them, as Yahweh our God is whenever we call on him?",
                "What great nation is there, that has statutes and ordinances so righteous as all this law, which I set before you this day?",
                "Only take heed to yourself, and keep your soul diligently, lest you forget the things which your eyes saw, and lest they depart from your heart all the days of your life; but make them known to your children and your children\u2019s children;",
                "the day that you stood before Yahweh your God in Horeb, when Yahweh said to me, \"Assemble me the people, and I will make them hear my words, that they may learn to fear me all the days that they live on the earth, and that they may teach their children.\"",
                "You came near and stood under the mountain; and the mountain burned with fire to the heart of the sky, with darkness, cloud, and thick darkness.",
                "Yahweh spoke to you out of the midst of the fire: you heard the voice of words, but you saw no form; you only heard a voice.",
                "He declared to you his covenant, which he commanded you to perform, even the ten commandments; and he wrote them on two tables of stone.",
                "Yahweh commanded me at that time to teach you statutes and ordinances, that you might do them in the land where you go over to possess it.",
                "Take therefore good heed to yourselves; for you saw no kind of form on the day that Yahweh spoke to you in Horeb out of the midst of the fire.",
                "Lest you corrupt yourselves, and make yourself an engraved image in the form of any figure, the likeness of male or female,",
                "the likeness of any animal that is on the earth, the likeness of any winged bird that flies in the sky,",
                "the likeness of anything that creeps on the ground, the likeness of any fish that is in the water under the earth;",
                "and lest you lift up your eyes to the sky, and when you see the sun and the moon and the stars, even all the army of the sky, you are drawn away and worship them, and serve them, which Yahweh your God has allotted to all the peoples under the whole sky.",
                "But Yahweh has taken you, and brought you forth out of the iron furnace, out of Egypt, to be to him a people of inheritance, as at this day.",
                "Furthermore Yahweh was angry with me for your sakes, and swore that I should not go over the Jordan, and that I should not go in to that good land, which Yahweh your God gives you for an inheritance:",
                "but I must die in this land, I must not go over the Jordan; but you shall go over, and possess that good land.",
                "Take heed to yourselves, lest you forget the covenant of Yahweh your God, which he made with you, and make you an engraved image in the form of anything which Yahweh your God has forbidden you.",
                "For Yahweh your God is a devouring fire, a jealous God.",
                "When you shall father children, and children\u2019s children, and you shall have been long in the land, and shall corrupt yourselves, and make an engraved image in the form of anything, and shall do that which is evil in the sight of Yahweh your God, to provoke him to anger;",
                "I call heaven and earth to witness against you this day, that you shall soon utterly perish from off the land whereunto you go over the Jordan to possess it; you shall not prolong your days on it, but shall utterly be destroyed.",
                "Yahweh will scatter you among the peoples, and you shall be left few in number among the nations, where Yahweh shall lead you away.",
                "There you shall serve gods, the work of men\u2019s hands, wood and stone, which neither see, nor hear, nor eat, nor smell.",
                "But from there you shall seek Yahweh your God, and you shall find him, when you search after him with all your heart and with all your soul.",
                "When you are in oppression, and all these things have come on you, in the latter days you shall return to Yahweh your God, and listen to his voice:",
                "for Yahweh your God is a merciful God; he will not fail you, neither destroy you, nor forget the covenant of your fathers which he swore to them.",
                "For ask now of the days that are past, which were before you, since the day that God created man on the earth, and from the one end of the sky to the other, whether there has been anything as this great thing is, or has been heard like it?",
                "Did a people ever hear the voice of God speaking out of the midst of the fire, as you have heard, and live?",
                "Or has God tried to go and take a nation for himself from the midst of another nation, by trials, by signs, and by wonders, and by war, and by a mighty hand, and by an outstretched arm, and by great terrors, according to all that Yahweh your God did for you in Egypt before your eyes?",
                "It was shown to you so that you might know that Yahweh is God. There is no one else besides him.",
                "Out of heaven he made you to hear his voice, that he might instruct you: and on earth he made you to see his great fire; and you heard his words out of the midst of the fire.",
                "Because he loved your fathers, therefore he chose their seed after them, and brought you out with his presence, with his great power, out of Egypt;",
                "to drive out nations from before you greater and mightier than you, to bring you in, to give you their land for an inheritance, as at this day.",
                "Know therefore this day, and lay it to your heart, that Yahweh he is God in heaven above and on the earth beneath; there is none else.",
                "You shall keep his statutes, and his commandments, which I command you this day, that it may go well with you, and with your children after you, and that you may prolong your days in the land, which Yahweh your God gives you, forever.",
                "Then Moses set apart three cities beyond the Jordan toward the sunrise;",
                "that the manslayer might flee there, who kills his neighbor unawares, and didn\u2019t hate him in time past; and that fleeing to one of these cities he might live:",
                "Bezer in the wilderness, in the plain country, for the Reubenites; and Ramoth in Gilead, for the Gadites; and Golan in Bashan, for the Manassites.",
                "This is the law which Moses set before the children of Israel:",
                "these are the testimonies, and the statutes, and the ordinances, which Moses spoke to the children of Israel, when they came forth out of Egypt,",
                "beyond the Jordan, in the valley over against Beth Peor, in the land of Sihon king of the Amorites, who lived at Heshbon, whom Moses and the children of Israel struck, when they came forth out of Egypt.",
                "They took his land in possession, and the land of Og king of Bashan, the two kings of the Amorites, who were beyond the Jordan toward the sunrise;",
                "from Aroer, which is on the edge of the valley of the Arnon, even to Mount Sion (the same is Hermon),",
                "and all the Arabah beyond the Jordan eastward, even to the sea of the Arabah, under the slopes of Pisgah."
            ],
            5: [
                "Moses called to all Israel, and said to them, Hear, Israel, the statutes and the ordinances which I speak in your ears this day, that you may learn them, and observe to do them.",
                "Yahweh our God made a covenant with us in Horeb.",
                "Yahweh didn\u2019t make this covenant with our fathers, but with us, even us, who are all of us here alive this day.",
                "Yahweh spoke with you face to face on the mountain out of the midst of the fire,",
                "(I stood between Yahweh and you at that time, to show you the word of Yahweh: for you were afraid because of the fire, and didn\u2019t go up onto the mountain;) saying,",
                "\"I am Yahweh your God, who brought you out of the land of Egypt, out of the house of bondage.",
                "\"You shall have no other gods before me.",
                "\"You shall not make an engraved image for yourself, any likeness of what is in heaven above, or what is in the earth beneath, or that is in the water under the earth:",
                "you shall not bow down yourself to them, nor serve them; for I, Yahweh, your God, am a jealous God, visiting the iniquity of the fathers on the children, and on the third and on the fourth generation of those who hate me;",
                "and showing loving kindness to thousands of those who love me and keep my commandments.",
                "\"You shall not take the name of Yahweh your God in vain: for Yahweh will not hold him guiltless who takes his name in vain.",
                "\"Observe the Sabbath day, to keep it holy, as Yahweh your God commanded you.",
                "You shall labor six days, and do all your work;",
                "but the seventh day is a Sabbath to Yahweh your God, in which you shall not do any work, you, nor your son, nor your daughter, nor your male servant, nor your female servant, nor your ox, nor your donkey, nor any of your livestock, nor your stranger who is within your gates; that your male servant and your female servant may rest as well as you.",
                "You shall remember that you were a servant in the land of Egypt, and Yahweh your God brought you out of there by a mighty hand and by an outstretched arm: therefore Yahweh your God commanded you to keep the Sabbath day.",
                "\"Honor your father and your mother, as Yahweh your God commanded you; that your days may be long, and that it may go well with you, in the land which Yahweh your God gives you.",
                "\"You shall not murder.",
                "\"Neither shall you commit adultery.",
                "\"Neither shall you steal.",
                "\"Neither shall you give false testimony against your neighbor.",
                "\"Neither shall you covet your neighbor\u2019s wife; neither shall you desire your neighbor\u2019s house, his field, or his male servant, or his female servant, his ox, or his donkey, or anything that is your neighbor\u2019s.\"",
                "These words Yahweh spoke to all your assembly on the mountain out of the midst of the fire, of the cloud, and of the thick darkness, with a great voice: and he added no more. He wrote them on two tables of stone, and gave them to me.",
                "It happened, when you heard the voice out of the midst of the darkness, while the mountain was burning with fire, that you came near to me, even all the heads of your tribes, and your elders;",
                "and you said, \"Behold, Yahweh our God has shown us his glory and his greatness, and we have heard his voice out of the midst of the fire: we have seen this day that God does speak with man, and he lives.",
                "Now therefore why should we die? For this great fire will consume us: if we hear the voice of Yahweh our God any more, then we shall die.",
                "For who is there of all flesh, that has heard the voice of the living God speaking out of the midst of the fire, as we have, and lived?",
                "Go near, and hear all that Yahweh our God shall say: and tell us all that Yahweh our God shall tell you; and we will hear it, and do it.\"",
                "Yahweh heard the voice of your words, when you spoke to me; and Yahweh said to me, \"I have heard the voice of the words of this people, which they have spoken to you: they have well said all that they have spoken.",
                "Oh that there were such a heart in them, that they would fear me, and keep all my commandments always, that it might be well with them, and with their children forever!",
                "\"Go tell them, Return to your tents.",
                "But as for you, stand here by me, and I will tell you all the commandment, and the statutes, and the ordinances, which you shall teach them, that they may do them in the land which I give them to possess it.\"",
                "You shall observe to do therefore as Yahweh your God has commanded you: you shall not turn aside to the right hand or to the left.",
                "You shall walk in all the way which Yahweh your God has commanded you, that you may live, and that it may be well with you, and that you may prolong your days in the land which you shall possess."
            ],
            6: [
                "Now this is the commandment, the statutes, and the ordinances, which Yahweh your God commanded to teach you, that you might do them in the land where you go over to possess it;",
                "that you might fear Yahweh your God, to keep all his statutes and his commandments, which I command you, you, and your son, and your son\u2019s son, all the days of your life; and that your days may be prolonged.",
                "Hear therefore, Israel, and observe to do it; that it may be well with you, and that you may increase mightily, as Yahweh, the God of your fathers, has promised to you, in a land flowing with milk and honey.",
                "Hear, Israel: Yahweh is our God; Yahweh is one:",
                "and you shall love Yahweh your God with all your heart, and with all your soul, and with all your might.",
                "These words, which I command you this day, shall be on your heart;",
                "and you shall teach them diligently to your children, and shall talk of them when you sit in your house, and when you walk by the way, and when you lie down, and when you rise up.",
                "You shall bind them for a sign on your hand, and they shall be for symbols between your eyes.",
                "You shall write them on the door posts of your house, and on your gates.",
                "It shall be, when Yahweh your God shall bring you into the land which he swore to your fathers, to Abraham, to Isaac, and to Jacob, to give you, great and goodly cities, which you didn\u2019t build,",
                "and houses full of all good things, which you didn\u2019t fill, and cisterns dug out, which you didn\u2019t dig, vineyards and olive trees, which you didn\u2019t plant, and you shall eat and be full;",
                "then beware lest you forget Yahweh, who brought you forth out of the land of Egypt, out of the house of bondage.",
                "You shall fear Yahweh your God; and you shall serve him, and shall swear by his name.",
                "You shall not go after other gods, of the gods of the peoples who are around you;",
                "for Yahweh your God in the midst of you is a jealous God; lest the anger of Yahweh your God be kindled against you, and he destroy you from off the face of the earth.",
                "You shall not tempt Yahweh your God, as you tempted him in Massah.",
                "You shall diligently keep the commandments of Yahweh your God, and his testimonies, and his statutes, which he has commanded you.",
                "You shall do that which is right and good in the sight of Yahweh; that it may be well with you, and that you may go in and possess the good land which Yahweh swore to your fathers,",
                "to thrust out all your enemies from before you, as Yahweh has spoken.",
                "When your son asks you in time to come, saying, \"What do the testimonies, the statutes, and the ordinances, which Yahweh our God has commanded you mean?\"",
                "then you shall tell your son, \"We were Pharaoh\u2019s bondservants in Egypt: and Yahweh brought us out of Egypt with a mighty hand;",
                "and Yahweh showed great and awesome signs and wonders on Egypt, on Pharaoh, and on all his house, before our eyes;",
                "and he brought us out from there, that he might bring us in, to give us the land which he swore to our fathers.",
                "Yahweh commanded us to do all these statutes, to fear Yahweh our God, for our good always, that he might preserve us alive, as at this day.",
                "It shall be righteousness to us, if we observe to do all this commandment before Yahweh our God, as he has commanded us.\""
            ],
            7: [
                "When Yahweh your God shall bring you into the land where you go to possess it, and shall cast out many nations before you, the Hittite, and the Girgashite, and the Amorite, and the Canaanite, and the Perizzite, and the Hivite, and the Jebusite, seven nations greater and mightier than you;",
                "and when Yahweh your God shall deliver them up before you, and you shall strike them; then you shall utterly destroy them: you shall make no covenant with them, nor show mercy to them;",
                "neither shall you make marriages with them; your daughter you shall not give to his son, nor shall you take his daughter for your son.",
                "For he will turn away your son from following me, that they may serve other gods: so the anger of Yahweh would be kindled against you, and he would destroy you quickly.",
                "But you shall deal with them like this: you shall break down their altars, and dash their pillars in pieces, and cut down their Asherim, and burn their engraved images with fire.",
                "For you are a holy people to Yahweh your God: Yahweh your God has chosen you to be a people for his own possession, above all peoples who are on the face of the earth.",
                "Yahweh didn\u2019t set his love on you, nor choose you, because you were more in number than any people; for you were the fewest of all peoples:",
                "but because Yahweh loves you, and because he would keep the oath which he swore to your fathers, has Yahweh brought you out with a mighty hand, and redeemed you out of the house of bondage, from the hand of Pharaoh king of Egypt.",
                "Know therefore that Yahweh your God, he is God, the faithful God, who keeps covenant and loving kindness with them who love him and keep his commandments to a thousand generations,",
                "and repays those who hate him to their face, to destroy them: he will not be slack to him who hates him, he will repay him to his face.",
                "You shall therefore keep the commandment, and the statutes, and the ordinances, which I command you this day, to do them.",
                "It shall happen, because you listen to these ordinances, and keep and do them, that Yahweh your God will keep with you the covenant and the loving kindness which he swore to your fathers:",
                "and he will love you, and bless you, and multiply you; he will also bless the fruit of your body and the fruit of your ground, your grain and your new wine and your oil, the increase of your livestock and the young of your flock, in the land which he swore to your fathers to give you.",
                "You shall be blessed above all peoples: there shall not be male or female barren among you, or among your livestock.",
                "Yahweh will take away from you all sickness; and none of the evil diseases of Egypt, which you know, he will put on you, but will lay them on all those who hate you.",
                "You shall consume all the peoples whom Yahweh your God shall deliver to you; your eye shall not pity them: neither shall you serve their gods; for that will be a snare to you.",
                "If you shall say in your heart, \"These nations are more than I; how can I dispossess them?\"",
                "you shall not be afraid of them: you shall well remember what Yahweh your God did to Pharaoh, and to all Egypt;",
                "the great trials which your eyes saw, and the signs, and the wonders, and the mighty hand, and the outstretched arm, by which Yahweh your God brought you out: so shall Yahweh your God do to all the peoples of whom you are afraid.",
                "Moreover Yahweh your God will send the hornet among them, until those who are left, and hide themselves, perish from before you.",
                "You shall not be scared of them; for Yahweh your God is in the midst of you, a great and awesome God.",
                "Yahweh your God will cast out those nations before you by little and little: you may not consume them at once, lest the animals of the field increase on you.",
                "But Yahweh your God will deliver them up before you, and will confuse them with a great confusion, until they be destroyed.",
                "He will deliver their kings into your hand, and you shall make their name to perish from under the sky: no man shall be able to stand before you, until you have destroyed them.",
                "You shall burn the engraved images of their gods with fire. You shall not covet the silver or the gold that is on them, nor take it for yourself, lest you be snared in it; for it is an abomination to Yahweh your God.",
                "You shall not bring an abomination into your house, and become a devoted thing like it. You shall utterly detest it, and you shall utterly abhor it; for it is a devoted thing."
            ],
            8: [
                "You shall observe to do all the commandment which I command you this day, that you may live, and multiply, and go in and possess the land which Yahweh swore to your fathers.",
                "You shall remember all the way which Yahweh your God has led you these forty years in the wilderness, that he might humble you, to prove you, to know what was in your heart, whether you would keep his commandments, or not.",
                "He humbled you, and allowed you to be hungry, and fed you with manna, which you didn\u2019t know, neither did your fathers know; that he might make you know that man does not live by bread only, but man lives by everything that proceeds out of the mouth of Yahweh.",
                "Your clothing didn\u2019t grow old on you, neither did your foot swell, these forty years.",
                "You shall consider in your heart that as a man chastens his son, so Yahweh your God chastens you.",
                "You shall keep the commandments of Yahweh your God, to walk in his ways, and to fear him.",
                "For Yahweh your God brings you into a good land, a land of brooks of water, of springs, and underground water flowing into valleys and hills;",
                "a land of wheat and barley, and vines and fig trees and pomegranates; a land of olive trees and honey;",
                "a land in which you shall eat bread without scarceness, you shall not lack anything in it; a land whose stones are iron, and out of whose hills you may dig copper.",
                "You shall eat and be full, and you shall bless Yahweh your God for the good land which he has given you.",
                "Beware lest you forget Yahweh your God, in not keeping his commandments, and his ordinances, and his statutes, which I command you this day:",
                "lest, when you have eaten and are full, and have built goodly houses, and lived therein;",
                "and when your herds and your flocks multiply, and your silver and your gold is multiplied, and all that you have is multiplied;",
                "then your heart be lifted up, and you forget Yahweh your God, who brought you forth out of the land of Egypt, out of the house of bondage;",
                "who led you through the great and terrible wilderness, with fiery serpents and scorpions, and thirsty ground where there was no water; who brought you forth water out of the rock of flint;",
                "who fed you in the wilderness with manna, which your fathers didn\u2019t know; that he might humble you, and that he might prove you, to do you good at your latter end:",
                "and lest you say in your heart, \"My power and the might of my hand has gotten me this wealth.\"",
                "But you shall remember Yahweh your God, for it is he who gives you power to get wealth; that he may establish his covenant which he swore to your fathers, as at this day.",
                "It shall be, if you shall forget Yahweh your God, and walk after other gods, and serve them, and worship them, I testify against you this day that you shall surely perish.",
                "As the nations that Yahweh makes to perish before you, so you shall perish; because you wouldn\u2019t listen to the voice of Yahweh your God."
            ],
            9: [
                "Hear, Israel: you are to pass over the Jordan this day, to go in to dispossess nations greater and mightier than yourself, cities great and fortified up to the sky,",
                "a people great and tall, the sons of the Anakim, whom you know, and of whom you have heard say, \"Who can stand before the sons of Anak?\"",
                "Know therefore this day, that Yahweh your God is he who goes over before you as a devouring fire; he will destroy them, and he will bring them down before you: so you shall drive them out, and make them to perish quickly, as Yahweh has spoken to you.",
                "Don\u2019t say in your heart, after Yahweh your God has thrust them out from before you, saying, \"For my righteousness Yahweh has brought me in to possess this land;\" because Yahweh drives them out before you because of the wickedness of these nations.",
                "Not for your righteousness, or for the uprightness of your heart, do you go in to possess their land; but for the wickedness of these nations Yahweh your God does drive them out from before you, and that he may establish the word which Yahweh swore to your fathers, to Abraham, to Isaac, and to Jacob.",
                "Know therefore, that Yahweh your God doesn\u2019t give you this good land to possess it for your righteousness; for you are a stiff-necked people.",
                "Remember, don\u2019t forget, how you provoked Yahweh your God to wrath in the wilderness: from the day that you went forth out of the land of Egypt, until you came to this place, you have been rebellious against Yahweh.",
                "Also in Horeb you provoked Yahweh to wrath, and Yahweh was angry with you to destroy you.",
                "When I was gone up onto the mountain to receive the tables of stone, even the tables of the covenant which Yahweh made with you, then I stayed on the mountain forty days and forty nights; I neither ate bread nor drank water.",
                "Yahweh delivered to me the two tables of stone written with the finger of God; and on them were all the words which Yahweh spoke with you on the mountain out of the midst of the fire in the day of the assembly.",
                "It came to pass at the end of forty days and forty nights, that Yahweh gave me the two tables of stone, even the tables of the covenant.",
                "Yahweh said to me, \"Arise, get down quickly from here; for your people whom you have brought out of Egypt have corrupted themselves; they have quickly turned aside out of the way which I commanded them; they have made them a molten image.\"",
                "Furthermore Yahweh spoke to me, saying, \"I have seen this people, and behold, it is a stiff-necked people:",
                "let me alone, that I may destroy them, and blot out their name from under the sky; and I will make of you a nation mightier and greater than they.\"",
                "So I turned and came down from the mountain, and the mountain was burning with fire: and the two tables of the covenant were in my two hands.",
                "I looked, and behold, you had sinned against Yahweh your God; you had made yourselves a molten calf: you had turned aside quickly out of the way which Yahweh had commanded you.",
                "I took hold of the two tables, and cast them out of my two hands, and broke them before your eyes.",
                "I fell down before Yahweh, as at the first, forty days and forty nights; I neither ate bread nor drank water; because of all your sin which you sinned, in doing that which was evil in the sight of Yahweh, to provoke him to anger.",
                "For I was afraid of the anger and hot displeasure, with which Yahweh was angry against you to destroy you. But Yahweh listened to me that time also.",
                "Yahweh was very angry with Aaron to destroy him: and I prayed for Aaron also at the same time.",
                "I took your sin, the calf which you had made, and burnt it with fire, and stamped it, grinding it very small, until it was as fine as dust: and I cast its dust into the brook that descended out of the mountain.",
                "At Taberah, and at Massah, and at Kibroth Hattaavah, you provoked Yahweh to wrath.",
                "When Yahweh sent you from Kadesh Barnea, saying, \"Go up and possess the land which I have given you;\" then you rebelled against the commandment of Yahweh your God, and you didn\u2019t believe him, nor listen to his voice.",
                "You have been rebellious against Yahweh from the day that I knew you.",
                "So I fell down before Yahweh the forty days and forty nights that I fell down, because Yahweh had said he would destroy you.",
                "I prayed to Yahweh, and said, \"Lord Yahweh, don\u2019t destroy your people and your inheritance, that you have redeemed through your greatness, that you have brought out of Egypt with a mighty hand.",
                "Remember your servants, Abraham, Isaac, and Jacob; don\u2019t look to the stubbornness of this people, nor to their wickedness, nor to their sin,",
                "lest the land you brought us out from say, \u2018Because Yahweh was not able to bring them into the land which he promised to them, and because he hated them, he has brought them out to kill them in the wilderness.\u2019",
                "Yet they are your people and your inheritance, which you brought out by your great power and by your outstretched arm.\""
            ],
            10: [
                "At that time Yahweh said to me, \"Cut two tables of stone like the first, and come up to me onto the mountain, and make an ark of wood.",
                "I will write on the tables the words that were on the first tables which you broke, and you shall put them in the ark.\"",
                "So I made an ark of acacia wood, and cut two tables of stone like the first, and went up onto the mountain, having the two tables in my hand.",
                "He wrote on the tables, according to the first writing, the ten commandments, which Yahweh spoke to you on the mountain out of the midst of the fire in the day of the assembly: and Yahweh gave them to me.",
                "I turned and came down from the mountain, and put the tables in the ark which I had made; and there they are as Yahweh commanded me.",
                "(The children of Israel traveled from Beeroth Bene Jaakan to Moserah. There Aaron died, and there he was buried; and Eleazar his son ministered in the priest\u2019s office in his place.",
                "From there they traveled to Gudgodah; and from Gudgodah to Jotbathah, a land of brooks of water.",
                "At that time Yahweh set apart the tribe of Levi, to bear the ark of the covenant of Yahweh, to stand before Yahweh to minister to him, and to bless in his name, to this day.",
                "Therefore Levi has no portion nor inheritance with his brothers; Yahweh is his inheritance, according as Yahweh your God spoke to him.)",
                "I stayed on the mountain, as at the first time, forty days and forty nights: and Yahweh listened to me that time also; Yahweh would not destroy you.",
                "Yahweh said to me, \"Arise, take your journey before the people; and they shall go in and possess the land, which I swore to their fathers to give to them.\"",
                "Now, Israel, what does Yahweh your God require of you, but to fear Yahweh your God, to walk in all his ways, and to love him, and to serve Yahweh your God with all your heart and with all your soul,",
                "to keep the commandments of Yahweh, and his statutes, which I command you this day for your good?",
                "Behold, to Yahweh your God belongs heaven and the heaven of heavens, the earth, with all that is therein.",
                "Only Yahweh had a delight in your fathers to love them, and he chose their seed after them, even you above all peoples, as at this day.",
                "Circumcise therefore the foreskin of your heart, and be no more stiff-necked.",
                "For Yahweh your God, he is God of gods, and Lord of lords, the great God, the mighty, and the awesome, who doesn\u2019t respect persons, nor takes reward.",
                "He does execute justice for the fatherless and widow, and loves the foreigner, in giving him food and clothing.",
                "Therefore love the foreigner; for you were foreigners in the land of Egypt.",
                "You shall fear Yahweh your God; you shall serve him; and you shall cling to him, and you shall swear by his name.",
                "He is your praise, and he is your God, who has done for you these great and awesome things, which your eyes have seen.",
                "Your fathers went down into Egypt with seventy persons; and now Yahweh your God has made you as the stars of the sky for multitude."
            ],
            11: [
                "Therefore you shall love Yahweh your God, and keep his instructions, and his statutes, and his ordinances, and his commandments, always.",
                "Know this day: for I don\u2019t speak with your children who have not known, and who have not seen the chastisement of Yahweh your God, his greatness, his mighty hand, and his outstretched arm,",
                "and his signs, and his works, which he did in the midst of Egypt to Pharaoh the king of Egypt, and to all his land;",
                "and what he did to the army of Egypt, to their horses, and to their chariots; how he made the water of the Red Sea to overflow them as they pursued after you, and how Yahweh has destroyed them to this day;",
                "and what he did to you in the wilderness, until you came to this place;",
                "and what he did to Dathan and Abiram, the sons of Eliab, the son of Reuben; how the earth opened its mouth, and swallowed them up, and their households, and their tents, and every living thing that followed them, in the midst of all Israel:",
                "but your eyes have seen all the great work of Yahweh which he did.",
                "Therefore you shall keep all the commandment which I command you this day, that you may be strong, and go in and possess the land, where you go over to possess it;",
                "and that you may prolong your days in the land, which Yahweh swore to your fathers to give to them and to their seed, a land flowing with milk and honey.",
                "For the land, where you go in to possess it, isn\u2019t as the land of Egypt, that you came out from, where you sowed your seed, and watered it with your foot, as a garden of herbs;",
                "but the land, where you go over to possess it, is a land of hills and valleys which drinks water of the rain of the sky,",
                "a land which Yahweh your God cares for: the eyes of Yahweh your God are always on it, from the beginning of the year even to the end of the year.",
                "It shall happen, if you shall listen diligently to my commandments which I command you this day, to love Yahweh your God, and to serve him with all your heart and with all your soul,",
                "that I will give the rain of your land in its season, the former rain and the latter rain, that you may gather in your grain, and your new wine, and your oil.",
                "I will give grass in your fields for your livestock, and you shall eat and be full.",
                "Take heed to yourselves, lest your heart be deceived, and you turn aside, and serve other gods, and worship them;",
                "and the anger of Yahweh be kindled against you, and he shut up the sky, so that there shall be no rain, and the land shall not yield its fruit; and you perish quickly from off the good land which Yahweh gives you.",
                "Therefore you shall lay up these my words in your heart and in your soul; and you shall bind them for a sign on your hand, and they shall be for symbols between your eyes.",
                "You shall teach them your children, talking of them, when you sit in your house, and when you walk by the way, and when you lie down, and when you rise up.",
                "You shall write them on the door posts of your house, and on your gates;",
                "that your days may be multiplied, and the days of your children, in the land which Yahweh swore to your fathers to give them, as the days of the heavens above the earth.",
                "For if you shall diligently keep all this commandment which I command you, to do it, to love Yahweh your God, to walk in all his ways, and to cling to him;",
                "then will Yahweh drive out all these nations from before you, and you shall dispossess nations greater and mightier than yourselves.",
                "Every place whereon the sole of your foot shall tread shall be yours: from the wilderness, and Lebanon, from the river, the river Euphrates, even to the hinder sea shall be your border.",
                "No man shall be able to stand before you: Yahweh your God shall lay the fear of you and the dread of you on all the land that you shall tread on, as he has spoken to you.",
                "Behold, I set before you this day a blessing and a curse:",
                "the blessing, if you shall listen to the commandments of Yahweh your God, which I command you this day;",
                "and the curse, if you shall not listen to the commandments of Yahweh your God, but turn aside out of the way which I command you this day, to go after other gods, which you have not known.",
                "It shall happen, when Yahweh your God shall bring you into the land where you go to possess it, that you shall set the blessing on Mount Gerizim, and the curse on Mount Ebal.",
                "Aren\u2019t they beyond the Jordan, behind the way of the going down of the sun, in the land of the Canaanites who dwell in the Arabah, over against Gilgal, beside the oaks of Moreh?",
                "For you are to pass over the Jordan to go in to possess the land which Yahweh your God gives you, and you shall possess it, and dwell therein.",
                "You shall observe to do all the statutes and the ordinances which I set before you this day."
            ],
            12: [
                "These are the statutes and the ordinances which you shall observe to do in the land which Yahweh, the God of your fathers, has given you to possess it, all the days that you live on the earth.",
                "You shall surely destroy all the places in which the nations that you shall dispossess served their gods, on the high mountains, and on the hills, and under every green tree:",
                "and you shall break down their altars, and dash in pieces their pillars, and burn their Asherim with fire; and you shall cut down the engraved images of their gods; and you shall destroy their name out of that place.",
                "You shall not do so to Yahweh your God.",
                "But to the place which Yahweh your God shall choose out of all your tribes, to put his name there, even to his habitation you shall seek, and there you shall come;",
                "and there you shall bring your burnt offerings, and your sacrifices, and your tithes, and the wave offering of your hand, and your vows, and your freewill offerings, and the firstborn of your herd and of your flock:",
                "and there you shall eat before Yahweh your God, and you shall rejoice in all that you put your hand to, you and your households, in which Yahweh your God has blessed you.",
                "You shall not do after all the things that we do here this day, every man whatever is right in his own eyes;",
                "for you haven\u2019t yet come to the rest and to the inheritance, which Yahweh your God gives you.",
                "But when you go over the Jordan, and dwell in the land which Yahweh your God causes you to inherit, and he gives you rest from all your enemies around you, so that you dwell in safety;",
                "then it shall happen that to the place which Yahweh your God shall choose, to cause his name to dwell there, there you shall bring all that I command you: your burnt offerings, and your sacrifices, your tithes, and the wave offering of your hand, and all your choice vows which you vow to Yahweh.",
                "You shall rejoice before Yahweh your God, you, and your sons, and your daughters, and your male servants, and your female servants, and the Levite who is within your gates, because he has no portion nor inheritance with you.",
                "Take heed to yourself that you don\u2019t offer your burnt offerings in every place that you see;",
                "but in the place which Yahweh shall choose in one of your tribes, there you shall offer your burnt offerings, and there you shall do all that I command you.",
                "Notwithstanding, you may kill and eat flesh within all your gates, after all the desire of your soul, according to the blessing of Yahweh your God which he has given you: the unclean and the clean may eat of it, as of the gazelle, and as of the hart.",
                "Only you shall not eat the blood; you shall pour it out on the earth as water.",
                "You may not eat within your gates the tithe of your grain, or of your new wine, or of your oil, or the firstborn of your herd or of your flock, nor any of your vows which you vow, nor your freewill offerings, nor the wave offering of your hand;",
                "but you shall eat them before Yahweh your God in the place which Yahweh your God shall choose, you, and your son, and your daughter, and your male servant, and your female servant, and the Levite who is within your gates: and you shall rejoice before Yahweh your God in all that you put your hand to.",
                "Take heed to yourself that you don\u2019t forsake the Levite as long as you live in your land.",
                "When Yahweh your God shall enlarge your border, as he has promised you, and you shall say, \"I want to eat meat,\" because your soul desires to eat meat; you may eat meat, after all the desire of your soul.",
                "If the place which Yahweh your God shall choose, to put his name there, is too far from you, then you shall kill of your herd and of your flock, which Yahweh has given you, as I have commanded you; and you may eat within your gates, after all the desire of your soul.",
                "Even as the gazelle and as the hart is eaten, so you shall eat of it: the unclean and the clean may eat of it alike.",
                "Only be sure that you don\u2019t eat the blood: for the blood is the life; and you shall not eat the life with the flesh.",
                "You shall not eat it; you shall pour it out on the earth as water.",
                "You shall not eat it; that it may go well with you, and with your children after you, when you shall do that which is right in the eyes of Yahweh.",
                "Only your holy things which you have, and your vows, you shall take, and go to the place which Yahweh shall choose:",
                "and you shall offer your burnt offerings, the flesh and the blood, on the altar of Yahweh your God; and the blood of your sacrifices shall be poured out on the altar of Yahweh your God; and you shall eat the flesh.",
                "Observe and hear all these words which I command you, that it may go well with you, and with your children after you forever, when you do that which is good and right in the eyes of Yahweh your God.",
                "When Yahweh your God shall cut off the nations from before you, where you go in to dispossess them, and you dispossess them, and dwell in their land;",
                "take heed to yourself that you not be ensnared to follow them, after that they are destroyed from before you; and that you not inquire after their gods, saying, \"How do these nations serve their gods? I will do likewise.\"",
                "You shall not do so to Yahweh your God: for every abomination to Yahweh, which he hates, have they done to their gods; for even their sons and their daughters do they burn in the fire to their gods.",
                "Whatever thing I command you, that you shall observe to do: you shall not add thereto, nor diminish from it."
            ],
            13: [
                "If there arise in the midst of you a prophet, or a dreamer of dreams, and he give you a sign or a wonder,",
                "and the sign or the wonder come to pass, of which he spoke to you, saying, \"Let us go after other gods\" (which you have not known) \"and let us serve them;\"",
                "you shall not listen to the words of that prophet, or to that dreamer of dreams: for Yahweh your God proves you, to know whether you love Yahweh your God with all your heart and with all your soul.",
                "You shall walk after Yahweh your God, and fear him, and keep his commandments, and obey his voice, and you shall serve him, and cling to him.",
                "That prophet, or that dreamer of dreams, shall be put to death, because he has spoken rebellion against Yahweh your God, who brought you out of the land of Egypt, and redeemed you out of the house of bondage, to draw you aside out of the way which Yahweh your God commanded you to walk in. So you shall put away the evil from the midst of you.",
                "If your brother, the son of your mother, or your son, or your daughter, or the wife of your bosom, or your friend, who is as your own soul, entice you secretly, saying, \"Let us go and serve other gods,\" which you have not known, you, nor your fathers;",
                "of the gods of the peoples who are around you, near to you, or far off from you, from the one end of the earth even to the other end of the earth;",
                "you shall not consent to him, nor listen to him; neither shall your eye pity him, neither shall you spare, neither shall you conceal him:",
                "but you shall surely kill him; your hand shall be first on him to put him to death, and afterwards the hand of all the people.",
                "You shall stone him to death with stones, because he has sought to draw you away from Yahweh your God, who brought you out of the land of Egypt, out of the house of bondage.",
                "All Israel shall hear, and fear, and shall not do any more such wickedness as this is in the midst of you.",
                "If you shall hear tell concerning one of your cities, which Yahweh your God gives you to dwell there, saying,",
                "Certain base fellows are gone out from the midst of you, and have drawn away the inhabitants of their city, saying, \"Let us go and serve other gods,\" which you have not known;",
                "then you shall inquire, and make search, and ask diligently; and, behold, if it be truth, and the thing certain, that such abomination is done in the midst of you,",
                "you shall surely strike the inhabitants of that city with the edge of the sword, destroying it utterly, and all that is therein and its livestock, with the edge of the sword.",
                "You shall gather all its spoil into the midst of its street, and shall burn with fire the city, and all its spoil every whit, to Yahweh your God: and it shall be a heap forever; it shall not be built again.",
                "Nothing of the devoted thing shall cling to your hand; that Yahweh may turn from the fierceness of his anger, and show you mercy, and have compassion on you, and multiply you, as he has sworn to your fathers;",
                "when you shall listen to the voice of Yahweh your God, to keep all his commandments which I command you this day, to do that which is right in the eyes of Yahweh your God."
            ],
            14: [
                "You are the children of Yahweh your God: you shall not cut yourselves, nor make any baldness between your eyes for the dead.",
                "For you are a holy people to Yahweh your God, and Yahweh has chosen you to be a people for his own possession, above all peoples who are on the face of the earth.",
                "You shall not eat any abominable thing.",
                "These are the animals which you may eat: the ox, the sheep, and the goat,",
                "the hart, and the gazelle, and the roebuck, and the wild goat, and the ibex, and the antelope, and the chamois.",
                "Every animal that parts the hoof, and has the hoof cloven in two and chews the cud, among the animals, that may you eat.",
                "Nevertheless these you shall not eat of them that chew the cud, or of those who have the hoof cloven: the camel, and the hare, and the rabbit; because they chew the cud but don\u2019t part the hoof, they are unclean to you.",
                "The pig, because it has a split hoof but doesn\u2019t chew the cud, is unclean to you: of their flesh you shall not eat, and their carcasses you shall not touch.",
                "These you may eat of all that are in the waters: whatever has fins and scales may you eat;",
                "and whatever doesn\u2019t have fins and scales you shall not eat; it is unclean to you.",
                "Of all clean birds you may eat.",
                "But these are they of which you shall not eat: the eagle, and the vulture, and the osprey,",
                "and the red kite, and the falcon, and the kite after its kind,",
                "and every raven after its kind,",
                "and the ostrich, and the owl, and the seagull, and the hawk after its kind,",
                "the little owl, and the great owl, and the horned owl,",
                "and the pelican, and the vulture, and the cormorant,",
                "and the stork, and the heron after its kind, and the hoopoe, and the bat.",
                "All winged creeping things are unclean to you: they shall not be eaten.",
                "Of all clean birds you may eat.",
                "You shall not eat of anything that dies of itself: you may give it to the foreigner living among you who is within your gates, that he may eat it; or you may sell it to a foreigner: for you are a holy people to Yahweh your God. You shall not boil a young goat in its mother\u2019s milk.",
                "You shall surely tithe all the increase of your seed, that which comes forth from the field year by year.",
                "You shall eat before Yahweh your God, in the place which he shall choose, to cause his name to dwell there, the tithe of your grain, of your new wine, and of your oil, and the firstborn of your herd and of your flock; that you may learn to fear Yahweh your God always.",
                "If the way is too long for you, so that you are not able to carry it, because the place is too far from you, which Yahweh your God shall choose, to set his name there, when Yahweh your God shall bless you;",
                "then you shall turn it into money, and bind up the money in your hand, and shall go to the place which Yahweh your God shall choose:",
                "and you shall bestow the money for whatever your soul desires, for cattle, or for sheep, or for wine, or for strong drink, or for whatever your soul asks of you; and you shall eat there before Yahweh your God, and you shall rejoice, you and your household.",
                "The Levite who is within your gates, you shall not forsake him; for he has no portion nor inheritance with you.",
                "At the end of every three years you shall bring forth all the tithe of your increase in the same year, and shall lay it up within your gates:",
                "and the Levite, because he has no portion nor inheritance with you, and the foreigner living among you, and the fatherless, and the widow, who are within your gates, shall come, and shall eat and be satisfied; that Yahweh your God may bless you in all the work of your hand which you do."
            ],
            15: [
                "At the end of every seven years you shall make a release.",
                "This is the way of the release: every creditor shall release that which he has lent to his neighbor; he shall not exact it of his neighbor and his brother; because Yahweh\u2019s release has been proclaimed.",
                "Of a foreigner you may exact it: but whatever of your is with your brother your hand shall release.",
                "However there shall be no poor with you; (for Yahweh will surely bless you in the land which Yahweh your God gives you for an inheritance to possess it;)",
                "if only you diligently listen to the voice of Yahweh your God, to observe to do all this commandment which I command you this day.",
                "For Yahweh your God will bless you, as he promised you: and you shall lend to many nations, but you shall not borrow; and you shall rule over many nations, but they shall not rule over you.",
                "If a poor man, one of your brothers, is with you within any of your gates in your land which Yahweh your God gives you, you shall not harden your heart, nor shut your hand from your poor brother;",
                "but you shall surely open your hand to him, and shall surely lend him sufficient for his need, which he lacks.",
                "Beware that there not be a base thought in your heart, saying, \"The seventh year, the year of release, is at hand;\" and your eye be evil against your poor brother, and you give him nothing; and he cry to Yahweh against you, and it be sin to you.",
                "You shall surely give him, and your heart shall not be grieved when you give to him; because that for this thing Yahweh your God will bless you in all your work, and in all that you put your hand to.",
                "For the poor will never cease out of the land: therefore I command you, saying, You shall surely open your hand to your brother, to your needy, and to your poor, in your land.",
                "If your brother, a Hebrew man, or a Hebrew woman, is sold to you, and serves you six years; then in the seventh year you shall let him go free from you.",
                "When you let him go free from you, you shall not let him go empty:",
                "you shall furnish him liberally out of your flock, and out of your threshing floor, and out of your winepress; as Yahweh your God has blessed you, you shall give to him.",
                "You shall remember that you were a bondservant in the land of Egypt, and Yahweh your God redeemed you: therefore I command you this thing today.",
                "It shall be, if he tells you, \"I will not go out from you;\" because he loves you and your house, because he is well with you;",
                "then you shall take an awl, and thrust it through his ear to the door, and he shall be your servant forever. Also to your female servant you shall do likewise.",
                "It shall not seem hard to you, when you let him go free from you; for to the double of the hire of a hireling has he served you six years: and Yahweh your God will bless you in all that you do.",
                "All the firstborn males that are born of your herd and of your flock you shall sanctify to Yahweh your God: you shall do no work with the firstborn of your herd, nor shear the firstborn of your flock.",
                "You shall eat it before Yahweh your God year by year in the place which Yahweh shall choose, you and your household.",
                "If it has any blemish, is lame or blind, or has any defect whatever, you shall not sacrifice it to Yahweh your God.",
                "You shall eat it within your gates: the unclean and the clean shall eat it alike, as the gazelle, and as the hart.",
                "Only you shall not eat its blood; you shall pour it out on the ground as water."
            ],
            16: [
                "Observe the month of Abib, and keep the Passover to Yahweh your God; for in the month of Abib Yahweh your God brought you forth out of Egypt by night.",
                "You shall sacrifice the Passover to Yahweh your God, of the flock and the herd, in the place which Yahweh shall choose, to cause his name to dwell there.",
                "You shall eat no leavened bread with it. You shall eat unleavened bread with it seven days, even the bread of affliction; for you came forth out of the land of Egypt in haste: that you may remember the day when you came forth out of the land of Egypt all the days of your life.",
                "No yeast shall be seen with you in all your borders seven days; neither shall any of the flesh, which you sacrifice the first day at evening, remain all night until the morning.",
                "You may not sacrifice the Passover within any of your gates, which Yahweh your God gives you;",
                "but at the place which Yahweh your God shall choose, to cause his name to dwell in, there you shall sacrifice the Passover at evening, at the going down of the sun, at the season that you came forth out of Egypt.",
                "You shall roast and eat it in the place which Yahweh your God shall choose: and you shall turn in the morning, and go to your tents.",
                "Six days you shall eat unleavened bread. On the seventh day shall be a solemn assembly to Yahweh your God; you shall do no work.",
                "You shall count for yourselves seven weeks: from the time you begin to put the sickle to the standing grain you shall begin to number seven weeks.",
                "You shall keep the feast of weeks to Yahweh your God with a tribute of a freewill offering of your hand, which you shall give, according as Yahweh your God blesses you:",
                "and you shall rejoice before Yahweh your God, you, and your son, and your daughter, and your male servant, and your female servant, and the Levite who is within your gates, and the foreigner, and the fatherless, and the widow, who are in the midst of you, in the place which Yahweh your God shall choose, to cause his name to dwell there.",
                "You shall remember that you were a bondservant in Egypt: and you shall observe and do these statutes.",
                "You shall keep the feast of tents seven days, after that you have gathered in from your threshing floor and from your winepress:",
                "and you shall rejoice in your feast, you, and your son, and your daughter, and your male servant, and your female servant, and the Levite, and the foreigner, and the fatherless, and the widow, who are within your gates.",
                "You shall keep a feast to Yahweh your God seven days in the place which Yahweh shall choose; because Yahweh your God will bless you in all your increase, and in all the work of your hands, and you shall be altogether joyful.",
                "Three times in a year shall all your males appear before Yahweh your God in the place which he shall choose: in the feast of unleavened bread, and in the feast of weeks, and in the feast of tents; and they shall not appear before Yahweh empty:",
                "every man shall give as he is able, according to the blessing of Yahweh your God which he has given you.",
                "You shall make judges and officers in all your gates, which Yahweh your God gives you, according to your tribes; and they shall judge the people with righteous judgment.",
                "You shall not wrest justice: you shall not respect persons; neither shall you take a bribe; for a bribe does blind the eyes of the wise, and pervert the words of the righteous.",
                "You shall follow that which is altogether just, that you may live, and inherit the land which Yahweh your God gives you.",
                "You shall not plant for yourselves an Asherah of any kind of tree beside the altar of Yahweh your God, which you shall make for yourselves.",
                "Neither shall you set yourself up a pillar; which Yahweh your God hates."
            ],
            17: [
                "You shall not sacrifice to Yahweh your God an ox, or a sheep, in which is a blemish, or anything evil; for that is an abomination to Yahweh your God.",
                "If there is found in the midst of you, within any of your gates which Yahweh your God gives you, man or woman, who does that which is evil in the sight of Yahweh your God, in transgressing his covenant,",
                "and has gone and served other gods, and worshiped them, or the sun, or the moon, or any of the army of the sky, which I have not commanded;",
                "and it be told you, and you have heard of it, then you shall inquire diligently; and behold, if it be true, and the thing certain, that such abomination is done in Israel,",
                "then you shall bring forth that man or that woman, who has done this evil thing, to your gates, even the man or the woman; and you shall stone them to death with stones.",
                "At the mouth of two witnesses, or three witnesses, shall he who is to die be put to death; at the mouth of one witness he shall not be put to death.",
                "The hand of the witnesses shall be first on him to put him to death, and afterward the hand of all the people. So you shall put away the evil from the midst of you.",
                "If there arises a matter too hard for you in judgment, between blood and blood, between plea and plea, and between stroke and stroke, being matters of controversy within your gates; then you shall arise, and go up to the place which Yahweh your God shall choose;",
                "and you shall come to the priests the Levites, and to the judge who shall be in those days: and you shall inquire; and they shall show you the sentence of judgment.",
                "You shall do according to the tenor of the sentence which they shall show you from that place which Yahweh shall choose; and you shall observe to do according to all that they shall teach you:",
                "according to the tenor of the law which they shall teach you, and according to the judgment which they shall tell you, you shall do; you shall not turn aside from the sentence which they shall show you, to the right hand, nor to the left.",
                "The man who does presumptuously, in not listening to the priest who stands to minister there before Yahweh your God, or to the judge, even that man shall die: and you shall put away the evil from Israel.",
                "All the people shall hear, and fear, and do no more presumptuously.",
                "When you have come to the land which Yahweh your God gives you, and shall possess it, and shall dwell therein, and shall say, \"I will set a king over me, like all the nations that are around me;\"",
                "you shall surely set him king over yourselves, whom Yahweh your God shall choose: one from among your brothers you shall set king over you; you may not put a foreigner over you, who is not your brother.",
                "Only he shall not multiply horses to himself, nor cause the people to return to Egypt, to the end that he may multiply horses; because Yahweh has said to you, \"You shall not go back that way again.\"",
                "Neither shall he multiply wives to himself, that his heart not turn away: neither shall he greatly multiply to himself silver and gold.",
                "It shall be, when he sits on the throne of his kingdom, that he shall write him a copy of this law in a book, out of that which is before the priests the Levites:",
                "and it shall be with him, and he shall read therein all the days of his life; that he may learn to fear Yahweh his God, to keep all the words of this law and these statutes, to do them;",
                "that his heart not be lifted up above his brothers, and that he not turn aside from the commandment, to the right hand, or to the left: to the end that he may prolong his days in his kingdom, he and his children, in the midst of Israel."
            ],
            18: [
                "The priests the Levites, all the tribe of Levi, shall have no portion nor inheritance with Israel: they shall eat the offerings of Yahweh made by fire, and his inheritance.",
                "They shall have no inheritance among their brothers: Yahweh is their inheritance, as he has spoken to them.",
                "This shall be the priests\u2019 due from the people, from those who offer a sacrifice, whether it be ox or sheep, that they shall give to the priest the shoulder, and the two cheeks, and the maw.",
                "The first fruits of your grain, of your new wine, and of your oil, and the first of the fleece of your sheep, you shall give him.",
                "For Yahweh your God has chosen him out of all your tribes, to stand to minister in the name of Yahweh, him and his sons for ever.",
                "If a Levite comes from any of your gates out of all Israel, where he lives as a foreigner, and comes with all the desire of his soul to the place which Yahweh shall choose;",
                "then he shall minister in the name of Yahweh his God, as all his brothers the Levites do, who stand there before Yahweh.",
                "They shall have like portions to eat, besides that which comes of the sale of his patrimony.",
                "When you have come into the land which Yahweh your God gives you, you shall not learn to do after the abominations of those nations.",
                "There shall not be found with you anyone who makes his son or his daughter to pass through the fire, one who uses divination, one who practices sorcery, or an enchanter, or a sorcerer,",
                "or a charmer, or a consulter with a familiar spirit, or a wizard, or a necromancer.",
                "For whoever does these things is an abomination to Yahweh: and because of these abominations Yahweh your God does drive them out from before you.",
                "You shall be perfect with Yahweh your God.",
                "For these nations, that you shall dispossess, listen to those who practice sorcery, and to diviners; but as for you, Yahweh your God has not allowed you so to do.",
                "Yahweh your God will raise up to you a prophet from the midst of you, of your brothers, like me. You shall listen to him.",
                "This is according to all that you desired of Yahweh your God in Horeb in the day of the assembly, saying, \"Let me not hear again the voice of Yahweh my God, neither let me see this great fire any more, that I not die.\"",
                "Yahweh said to me, \"They have well said that which they have spoken.",
                "I will raise them up a prophet from among their brothers, like you; and I will put my words in his mouth, and he shall speak to them all that I shall command him.",
                "It shall happen, that whoever will not listen to my words which he shall speak in my name, I will require it of him.",
                "But the prophet, who shall speak a word presumptuously in my name, which I have not commanded him to speak, or who shall speak in the name of other gods, that same prophet shall die.\"",
                "If you say in your heart, \"How shall we know the word which Yahweh has not spoken?\"",
                "when a prophet speaks in the name of Yahweh, if the thing doesn\u2019t follow, nor happen, that is the thing which Yahweh has not spoken: the prophet has spoken it presumptuously, you shall not be afraid of him."
            ],
            19: [
                "When Yahweh your God shall cut off the nations, whose land Yahweh your God gives you, and you succeed them, and dwell in their cities, and in their houses;",
                "you shall set apart three cities for you in the midst of your land, which Yahweh your God gives you to possess it.",
                "You shall prepare you the way, and divide the borders of your land, which Yahweh your God causes you to inherit, into three parts, that every manslayer may flee there.",
                "This is the case of the manslayer, that shall flee there and live: whoever kills his neighbor unawares, and didn\u2019t hate him in time past;",
                "as when a man goes into the forest with his neighbor to chop wood, and his hand fetches a stroke with the axe to cut down the tree, and the head slips from the handle, and lights on his neighbor, so that he dies; he shall flee to one of these cities and live:",
                "lest the avenger of blood pursue the manslayer, while his heart is hot, and overtake him, because the way is long, and strike him mortally; whereas he was not worthy of death, inasmuch as he didn\u2019t hate him in time past.",
                "Therefore I command you, saying, You shall set apart three cities for yourselves.",
                "If Yahweh your God enlarges your border, as he has sworn to your fathers, and gives you all the land which he promised to give to your fathers;",
                "if you keep all this commandment to do it, which I command you this day, to love Yahweh your God, and to walk ever in his ways; then you shall add three cities more for yourselves, besides these three:",
                "that innocent blood not be shed in the midst of your land, which Yahweh your God gives you for an inheritance, and so blood be on you.",
                "But if any man hates his neighbor, and lies in wait for him, and rises up against him, and strikes him mortally so that he dies, and he flees into one of these cities;",
                "then the elders of his city shall send and bring him there, and deliver him into the hand of the avenger of blood, that he may die.",
                "Your eye shall not pity him, but you shall put away the innocent blood from Israel, that it may go well with you.",
                "You shall not remove your neighbor\u2019s landmark, which they of old time have set, in your inheritance which you shall inherit, in the land that Yahweh your God gives you to possess it.",
                "One witness shall not rise up against a man for any iniquity, or for any sin, in any sin that he sins: at the mouth of two witnesses, or at the mouth of three witnesses, shall a matter be established.",
                "If an unrighteous witness rise up against any man to testify against him of wrongdoing,",
                "then both the men, between whom the controversy is, shall stand before Yahweh, before the priests and the judges who shall be in those days;",
                "and the judges shall make diligent inquisition: and, behold, if the witness is a false witness, and has testified falsely against his brother;",
                "then you shall do to him as he had thought to do to his brother: so you shall put away the evil from the midst of you.",
                "Those who remain shall hear, and fear, and shall henceforth commit no more any such evil in the midst of you.",
                "Your eyes shall not pity: life for life, eye for eye, tooth for tooth, hand for hand, foot for foot."
            ],
            20: [
                "When you go forth to battle against your enemies, and see horses, chariots, and a people more than you, you shall not be afraid of them; for Yahweh your God is with you, who brought you up out of the land of Egypt.",
                "It shall be, when you draw near to the battle, that the priest shall approach and speak to the people,",
                "and shall tell them, \"Hear, Israel, you draw near this day to battle against your enemies: don\u2019t let your heart faint; don\u2019t be afraid, nor tremble, neither be scared of them;",
                "for Yahweh your God is he who goes with you, to fight for you against your enemies, to save you.\"",
                "The officers shall speak to the people, saying, \"What man is there who has built a new house, and has not dedicated it? Let him go and return to his house, lest he die in the battle, and another man dedicate it.",
                "What man is there who has planted a vineyard, and has not used its fruit? Let him go and return to his house, lest he die in the battle, and another man use its fruit.",
                "What man is there who has pledged to be married a wife, and has not taken her? Let him go and return to his house, lest he die in the battle, and another man take her.\"",
                "The officers shall speak further to the people, and they shall say, \"What man is there who is fearful and fainthearted? Let him go and return to his house, lest his brother\u2019s heart melt as his heart.\"",
                "It shall be, when the officers have made an end of speaking to the people, that they shall appoint captains of armies at the head of the people.",
                "When you draw near to a city to fight against it, then proclaim peace to it.",
                "It shall be, if it makes you answer of peace, and opens to you, then it shall be, that all the people who are found therein shall become tributary to you, and shall serve you.",
                "If it will make no peace with you, but will make war against you, then you shall besiege it:",
                "and when Yahweh your God delivers it into your hand, you shall strike every male of it with the edge of the sword:",
                "but the women, and the little ones, and the livestock, and all that is in the city, even all its spoil, you shall take for a prey to yourself; and you shall eat the spoil of your enemies, which Yahweh your God has given you.",
                "Thus you shall do to all the cities which are very far off from you, which are not of the cities of these nations.",
                "But of the cities of these peoples, that Yahweh your God gives you for an inheritance, you shall save alive nothing that breathes;",
                "but you shall utterly destroy them: the Hittite, and the Amorite, the Canaanite, and the Perizzite, the Hivite, and the Jebusite; as Yahweh your God has commanded you;",
                "that they not teach you to do after all their abominations, which they have done to their gods; so would you sin against Yahweh your God.",
                "When you shall besiege a city a long time, in making war against it to take it, you shall not destroy its trees by wielding an axe against them; for you may eat of them, and you shall not cut them down; for is the tree of the field man, that it should be besieged of you?",
                "Only the trees of which you know that they are not trees for food, you shall destroy and cut them down; and you shall build bulwarks against the city that makes war with you, until it fall."
            ],
            21: [
                "If one be found slain in the land which Yahweh your God gives you to possess it, lying in the field, and it isn\u2019t known who has struck him;",
                "then your elders and your judges shall come forth, and they shall measure to the cities which are around him who is slain:",
                "and it shall be, that the city which is nearest to the slain man, even the elders of that city shall take a heifer of the herd, which hasn\u2019t been worked with, and which has not drawn in the yoke;",
                "and the elders of that city shall bring down the heifer to a valley with running water, which is neither plowed nor sown, and shall break the heifer\u2019s neck there in the valley.",
                "The priests the sons of Levi shall come near; for them Yahweh your God has chosen to minister to him, and to bless in the name of Yahweh; and according to their word shall every controversy and every stroke be.",
                "All the elders of that city, who are nearest to the slain man, shall wash their hands over the heifer whose neck was broken in the valley;",
                "and they shall answer and say, \"Our hands have not shed this blood, neither have our eyes seen it.",
                "Forgive, Yahweh, your people Israel, whom you have redeemed, and don\u2019t allow innocent blood in the midst of your people Israel.\" The blood shall be forgiven them.",
                "So you shall put away the innocent blood from the midst of you, when you shall do that which is right in the eyes of Yahweh.",
                "When you go forth to battle against your enemies, and Yahweh your God delivers them into your hands, and you carry them away captive,",
                "and see among the captives a beautiful woman, and you have a desire to her, and would take her to you as wife;",
                "then you shall bring her home to your house; and she shall shave her head, and pare her nails;",
                "and she shall put the clothing of her captivity from off her, and shall remain in your house, and bewail her father and her mother a full month: and after that you shall go in to her, and be her husband, and she shall be your wife.",
                "It shall be, if you have no delight in her, then you shall let her go where she will; but you shall not sell her at all for money, you shall not deal with her as a slave, because you have humbled her.",
                "If a man have two wives, the one beloved, and the other hated, and they have borne him children, both the beloved and the hated; and if the firstborn son be hers who was hated;",
                "then it shall be, in the day that he causes his sons to inherit that which he has, that he may not make the son of the beloved the firstborn before the son of the hated, who is the firstborn:",
                "but he shall acknowledge the firstborn, the son of the hated, by giving him a double portion of all that he has; for he is the beginning of his strength; the right of the firstborn is his.",
                "If a man have a stubborn and rebellious son, who will not obey the voice of his father, or the voice of his mother, and, though they chasten him, will not listen to them;",
                "then shall his father and his mother lay hold on him, and bring him out to the elders of his city, and to the gate of his place;",
                "and they shall tell the elders of his city, \"This our son is stubborn and rebellious, he will not obey our voice; he is a glutton, and a drunkard.\"",
                "All the men of his city shall stone him to death with stones: so you shall put away the evil from the midst of you; and all Israel shall hear, and fear.",
                "If a man have committed a sin worthy of death, and he be put to death, and you hang him on a tree;",
                "his body shall not remain all night on the tree, but you shall surely bury him the same day; for he who is hanged is accursed of God; that you don\u2019t defile your land which Yahweh your God gives you for an inheritance."
            ],
            22: [
                "You shall not see your brother\u2019s ox or his sheep go astray, and hide yourself from them: you shall surely bring them again to your brother.",
                "If your brother isn\u2019t near to you, or if you don\u2019t know him, then you shall bring it home to your house, and it shall be with you until your brother seek after it, and you shall restore it to him.",
                "So you shall do with his donkey; and so you shall do with his garment; and so you shall do with every lost thing of your brother\u2019s, which he has lost, and you have found: you may not hide yourself.",
                "You shall not see your brother\u2019s donkey or his ox fallen down by the way, and hide yourself from them: you shall surely help him to lift them up again.",
                "A woman shall not wear men\u2019s clothing, neither shall a man put on women\u2019s clothing; for whoever does these things is an abomination to Yahweh your God.",
                "If a bird\u2019s nest chance to be before you in the way, in any tree or on the ground, with young ones or eggs, and the hen sitting on the young, or on the eggs, you shall not take the hen with the young:",
                "you shall surely let the hen go, but the young you may take to yourself; that it may be well with you, and that you may prolong your days.",
                "When you build a new house, then you shall make a battlement for your roof, that you don\u2019t bring blood on your house, if any man fall from there.",
                "You shall not sow your vineyard with two kinds of seed, lest the whole fruit be forfeited, the seed which you have sown, and the increase of the vineyard.",
                "You shall not plow with an ox and a donkey together.",
                "You shall not wear a mixed stuff, wool and linen together.",
                "You shall make yourselves fringes on the four borders of your cloak, with which you cover yourself.",
                "If any man takes a wife, and goes in to her, and hates her,",
                "and accuses her of shameful things, and brings up an evil name on her, and says, \"I took this woman, and when I came near to her, I didn\u2019t find in her the tokens of virginity;\"",
                "then shall the father of the young lady, and her mother, take and bring forth the tokens of the young lady\u2019s virginity to the elders of the city in the gate;",
                "and the young lady\u2019s father shall tell the elders, \"I gave my daughter to this man to wife, and he hates her;",
                "and behold, he has accused her of shameful things, saying, \u2018I didn\u2019t find in your daughter the tokens of virginity;\u2019 and yet these are the tokens of my daughter\u2019s virginity.\" They shall spread the cloth before the elders of the city.",
                "The elders of that city shall take the man and chastise him;",
                "and they shall fine him one hundred shekels of silver, and give them to the father of the young lady, because he has brought up an evil name on a virgin of Israel: and she shall be his wife; he may not put her away all his days.",
                "But if this thing be true, that the tokens of virginity were not found in the young lady;",
                "then they shall bring out the young lady to the door of her father\u2019s house, and the men of her city shall stone her to death with stones, because she has done folly in Israel, to play the prostitute in her father\u2019s house: so you shall put away the evil from the midst of you.",
                "If a man be found lying with a woman married to a husband, then they shall both of them die, the man who lay with the woman, and the woman: so you shall put away the evil from Israel.",
                "If there is a young lady who is a virgin pledged to be married to a husband, and a man find her in the city, and lie with her;",
                "then you shall bring them both out to the gate of that city, and you shall stone them to death with stones; the lady, because she didn\u2019t cry, being in the city; and the man, because he has humbled his neighbor\u2019s wife: so you shall put away the evil from the midst of you.",
                "But if the man find the lady who is pledged to be married in the field, and the man force her, and lie with her; then the man only who lay with her shall die:",
                "but to the lady you shall do nothing; there is in the lady no sin worthy of death: for as when a man rises against his neighbor, and kills him, even so is this matter;",
                "for he found her in the field, the pledged to be married lady cried, and there was none to save her.",
                "If a man find a lady who is a virgin, who is not pledged to be married, and lay hold on her, and lie with her, and they be found;",
                "then the man who lay with her shall give to the lady\u2019s father fifty shekels of silver, and she shall be his wife, because he has humbled her; he may not put her away all his days.",
                "A man shall not take his father\u2019s wife, and shall not uncover his father\u2019s skirt."
            ],
            23: [
                "He who is wounded in the stones, or has his privy member cut off, shall not enter into the assembly of Yahweh.",
                "A bastard shall not enter into the assembly of Yahweh; even to the tenth generation shall none of his enter into the assembly of Yahweh.",
                "An Ammonite or a Moabite shall not enter into the assembly of Yahweh; even to the tenth generation shall none belonging to them enter into the assembly of Yahweh forever:",
                "because they didn\u2019t meet you with bread and with water in the way, when you came forth out of Egypt, and because they hired against you Balaam the son of Beor from Pethor of Mesopotamia, to curse you.",
                "Nevertheless Yahweh your God wouldn\u2019t listen to Balaam; but Yahweh your God turned the curse into a blessing to you, because Yahweh your God loved you.",
                "You shall not seek their peace nor their prosperity all your days forever.",
                "You shall not abhor an Edomite; for he is your brother: you shall not abhor an Egyptian, because you lived as a foreigner in his land.",
                "The children of the third generation who are born to them shall enter into the assembly of Yahweh.",
                "When you go forth in camp against your enemies, then you shall keep yourselves from every evil thing.",
                "If there is among you any man who is not clean by reason of that which happens him by night, then shall he go outside of the camp. He shall not come within the camp:",
                "but it shall be, when evening comes on, he shall bathe himself in water; and when the sun is down, he shall come within the camp.",
                "You shall have a place also outside of the camp, where you shall go forth abroad:",
                "and you shall have a paddle among your weapons; and it shall be, when you sit down abroad, you shall dig therewith, and shall turn back and cover that which comes from you:",
                "for Yahweh your God walks in the midst of your camp, to deliver you, and to give up your enemies before you; therefore your camp shall be holy, that he may not see an unclean thing in you, and turn away from you.",
                "You shall not deliver to his master a servant who is escaped from his master to you:",
                "he shall dwell with you, in the midst of you, in the place which he shall choose within one of your gates, where it pleases him best: you shall not oppress him.",
                "There shall be no prostitute of the daughters of Israel, neither shall there be a sodomite of the sons of Israel.",
                "You shall not bring the hire of a prostitute, or the wages of a dog, into the house of Yahweh your God for any vow: for even both these are an abomination to Yahweh your God.",
                "You shall not lend on interest to your brother; interest of money, interest of food, interest of anything that is lent on interest:",
                "to a foreigner you may lend on interest; but to your brother you shall not lend on interest, that Yahweh your God may bless you in all that you put your hand to, in the land where you go in to possess it.",
                "When you shall vow a vow to Yahweh your God, you shall not be slack to pay it: for Yahweh your God will surely require it of you; and it would be sin in you.",
                "But if you shall forbear to vow, it shall be no sin in you.",
                "That which is gone out of your lips you shall observe and do; according as you have vowed to Yahweh your God, a freewill offering, which you have promised with your mouth.",
                "When you come into your neighbor\u2019s vineyard, then you may eat of grapes your fill at your own pleasure; but you shall not put any in your vessel.",
                "When you come into your neighbor\u2019s standing grain, then you may pluck the ears with your hand; but you shall not move a sickle to your neighbor\u2019s standing grain."
            ],
            24: [
                "When a man takes a wife, and marries her, then it shall be, if she find no favor in his eyes, because he has found some unseemly thing in her, that he shall write her a bill of divorce, and give it in her hand, and send her out of his house.",
                "When she is departed out of his house, she may go and be another man\u2019s wife.",
                "If the latter husband hate her, and write her a bill of divorce, and give it in her hand, and send her out of his house; or if the latter husband die, who took her to be his wife;",
                "her former husband, who sent her away, may not take her again to be his wife, after that she is defiled; for that is abomination before Yahweh: and you shall not cause the land to sin, which Yahweh your God gives you for an inheritance.",
                "When a man takes a new wife, he shall not go out in the army, neither shall he be assigned any business: he shall be free at home one year, and shall cheer his wife whom he has taken.",
                "No man shall take the mill or the upper millstone to pledge; for he takes a life in pledge.",
                "If a man be found stealing any of his brothers of the children of Israel, and he deal with him as a slave, or sell him; then that thief shall die: so you shall put away the evil from the midst of you.",
                "Take heed in the plague of leprosy, that you observe diligently, and do according to all that the priests the Levites shall teach you: as I commanded them, so you shall observe to do.",
                "Remember what Yahweh your God did to Miriam, by the way as you came forth out of Egypt.",
                "When you do lend your neighbor any kind of loan, you shall not go into his house to get his pledge.",
                "You shall stand outside, and the man to whom you do lend shall bring forth the pledge outside to you.",
                "If he be a poor man, you shall not sleep with his pledge;",
                "you shall surely restore to him the pledge when the sun goes down, that he may sleep in his garment, and bless you: and it shall be righteousness to you before Yahweh your God.",
                "You shall not oppress a hired servant who is poor and needy, whether he be of your brothers, or of your foreigners who are in your land within your gates:",
                "in his day you shall give him his hire, neither shall the sun go down on it; for he is poor, and sets his heart on it: lest he cry against you to Yahweh, and it be sin to you.",
                "The fathers shall not be put to death for the children, neither shall the children be put to death for the fathers: every man shall be put to death for his own sin.",
                "You shall not deprive the foreigner, or the fatherless of justice, nor take a widow\u2019s clothing in pledge;",
                "but you shall remember that you were a bondservant in Egypt, and Yahweh your God redeemed you there: therefore I command you to do this thing.",
                "When you reap your harvest in your field, and have forgot a sheaf in the field, you shall not go again to get it: it shall be for the foreigner, for the fatherless, and for the widow; that Yahweh your God may bless you in all the work of your hands.",
                "When you beat your olive tree, you shall not go over the boughs again: it shall be for the foreigner, for the fatherless, and for the widow.",
                "When you harvest your vineyard, you shall not glean it after yourselves: it shall be for the foreigner, for the fatherless, and for the widow.",
                "You shall remember that you were a bondservant in the land of Egypt: therefore I command you to do this thing."
            ],
            25: [
                "If there is a controversy between men, and they come to judgment, and the judges judge them; then they shall justify the righteous, and condemn the wicked;",
                "and it shall be, if the wicked man be worthy to be beaten, that the judge shall cause him to lie down, and to be beaten before his face, according to his wickedness, by number.",
                "Forty stripes he may give him, he shall not exceed; lest, if he should exceed, and beat him above these with many stripes, then your brother should seem vile to you.",
                "You shall not muzzle the ox when he treads out the grain.",
                "If brothers dwell together, and one of them die, and have no son, the wife of the dead shall not be married outside to a stranger: her husband\u2019s brother shall go in to her, and take her to him as wife, and perform the duty of a husband\u2019s brother to her.",
                "It shall be, that the firstborn whom she bears shall succeed in the name of his brother who is dead, that his name not be blotted out of Israel.",
                "If the man doesn\u2019t want to take his brother\u2019s wife, then his brother\u2019s wife shall go up to the gate to the elders, and say, \"My husband\u2019s brother refuses to raise up to his brother a name in Israel; he will not perform the duty of a husband\u2019s brother to me.\"",
                "Then the elders of his city shall call him, and speak to him: and if he stand, and say, \"I don\u2019t want to take her;\"",
                "then his brother\u2019s wife shall come to him in the presence of the elders, and loose his shoe from off his foot, and spit in his face; and she shall answer and say, \"So shall it be done to the man who does not build up his brother\u2019s house.\"",
                "His name shall be called in Israel, The house of him who has his shoe untied.",
                "When men strive together one with another, and the wife of the one draws near to deliver her husband out of the hand of him who strikes him, and puts forth her hand, and takes him by the secrets;",
                "then you shall cut off her hand, your eye shall have no pity.",
                "You shall not have in your bag diverse weights, a great and a small.",
                "You shall not have in your house diverse measures, a great and a small.",
                "You shall have a perfect and just weight. You shall have a perfect and just measure, that your days may be long in the land which Yahweh your God gives you.",
                "For all who do such things, all who do unrighteously, are an abomination to Yahweh your God.",
                "Remember what Amalek did to you by the way as you came forth out of Egypt;",
                "how he met you by the way, and struck the hindmost of you, all who were feeble behind you, when you were faint and weary; and he didn\u2019t fear God.",
                "Therefore it shall be, when Yahweh your God has given you rest from all your enemies all around, in the land which Yahweh your God gives you for an inheritance to possess it, that you shall blot out the memory of Amalek from under the sky; you shall not forget."
            ],
            26: [
                "It shall be, when you have come in to the land which Yahweh your God gives you for an inheritance, and possess it, and dwell therein,",
                "that you shall take of the first of all the fruit of the ground, which you shall bring in from your land that Yahweh your God gives you; and you shall put it in a basket, and shall go to the place which Yahweh your God shall choose, to cause his name to dwell there.",
                "You shall come to the priest who shall be in those days, and tell him, \"I profess this day to Yahweh your God, that I am come to the land which Yahweh swore to our fathers to give us.\"",
                "The priest shall take the basket out of your hand, and set it down before the altar of Yahweh your God.",
                "You shall answer and say before Yahweh your God, \"A Syrian ready to perish was my father; and he went down into Egypt, and lived there, few in number; and he became there a nation, great, mighty, and populous.",
                "The Egyptians dealt ill with us, and afflicted us, and laid on us hard bondage:",
                "and we cried to Yahweh, the God of our fathers, and Yahweh heard our voice, and saw our affliction, and our toil, and our oppression;",
                "and Yahweh brought us forth out of Egypt with a mighty hand, and with an outstretched arm, and with great terror, and with signs, and with wonders;",
                "and he has brought us into this place, and has given us this land, a land flowing with milk and honey.",
                "Now, behold, I have brought the first of the fruit of the ground, which you, Yahweh, have given me.\" You shall set it down before Yahweh your God, and worship before Yahweh your God.",
                "You shall rejoice in all the good which Yahweh your God has given to you, and to your house, you, and the Levite, and the foreigner who is in the midst of you.",
                "When you have made an end of tithing all the tithe of your increase in the third year, which is the year of tithing, then you shall give it to the Levite, to the foreigner, to the fatherless, and to the widow, that they may eat within your gates, and be filled.",
                "You shall say before Yahweh your God, \"I have put away the holy things out of my house, and also have given them to the Levite, and to the foreigner, to the fatherless, and to the widow, according to all your commandment which you have commanded me: I have not transgressed any of your commandments, neither have I forgotten them:",
                "I have not eaten of it in my mourning, neither have I put away of it, being unclean, nor given of it for the dead: I have listened to the voice of Yahweh my God; I have done according to all that you have commanded me.",
                "Look down from your holy habitation, from heaven, and bless your people Israel, and the ground which you have given us, as you swore to our fathers, a land flowing with milk and honey.\"",
                "This day Yahweh your God commands you to do these statutes and ordinances: you shall therefore keep and do them with all your heart, and with all your soul.",
                "You have declared Yahweh this day to be your God, and that you would walk in his ways, and keep his statutes, and his commandments, and his ordinances, and listen to his voice:",
                "and Yahweh has declared you this day to be a people for his own possession, as he has promised you, and that you should keep all his commandments;",
                "and to make you high above all nations that he has made, in praise, and in name, and in honor; and that you may be a holy people to Yahweh your God, as he has spoken."
            ],
            27: [
                "Moses and the elders of Israel commanded the people, saying, \"Keep all the commandment which I command you this day.",
                "It shall be on the day when you shall pass over the Jordan to the land which Yahweh your God gives you, that you shall set yourself up great stones, and plaster them with plaster:",
                "and you shall write on them all the words of this law, when you have passed over; that you may go in to the land which Yahweh your God gives you, a land flowing with milk and honey, as Yahweh, the God of your fathers, has promised you.",
                "It shall be, when you have passed over the Jordan, that you shall set up these stones, which I command you this day, in Mount Ebal, and you shall plaster them with plaster.",
                "There you shall build an altar to Yahweh your God, an altar of stones: you shall lift up no iron on them.",
                "You shall build the altar of Yahweh your God of uncut stones; and you shall offer burnt offerings thereon to Yahweh your God:",
                "and you shall sacrifice peace offerings, and shall eat there; and you shall rejoice before Yahweh your God.",
                "You shall write on the stones all the words of this law very plainly.\"",
                "Moses and the priests the Levites spoke to all Israel, saying, \"Keep silence, and listen, Israel: this day you have become the people of Yahweh your God.",
                "You shall therefore obey the voice of Yahweh your God, and do his commandments and his statutes, which I command you this day.\"",
                "Moses commanded the people the same day, saying,",
                "\"These shall stand on Mount Gerizim to bless the people, when you have passed over the Jordan: Simeon, and Levi, and Judah, and Issachar, and Joseph, and Benjamin.",
                "These shall stand on Mount Ebal for the curse: Reuben, Gad, and Asher, and Zebulun, Dan, and Naphtali.",
                "The Levites shall answer, and tell all the men of Israel with a loud voice,",
                "\u2018Cursed is the man who makes an engraved or molten image, an abomination to Yahweh, the work of the hands of the craftsman, and sets it up in secret.\u2019 All the people shall answer and say, \u2018Amen.\u2019",
                "\u2018Cursed is he who sets light by his father or his mother.\u2019 All the people shall say, \u2018Amen.\u2019",
                "\u2018Cursed is he who removes his neighbor\u2019s landmark.\u2019 All the people shall say, \u2018Amen.\u2019",
                "\u2018Cursed is he who makes the blind to wander out of the way.\u2019 All the people shall say, \u2018Amen.\u2019",
                "\u2018Cursed is he who the foreigner, fatherless, and widow of justice.\u2019 All the people shall say, \u2018Amen.\u2019",
                "\u2018Cursed is he who lies with his father\u2019s wife, because he has uncovered his father\u2019s skirt.\u2019 All the people shall say, \u2018Amen.\u2019",
                "\u2018Cursed is he who lies with any kind of animal.\u2019 All the people shall say, \u2018Amen.\u2019",
                "\u2018Cursed is he who lies with his sister, the daughter of his father, or the daughter of his mother.\u2019 All the people shall say, \u2018Amen.\u2019",
                "\u2018Cursed is he who lies with his mother-in-law.\u2019 All the people shall say, \u2018Amen.\u2019",
                "\u2018Cursed is he who strikes his neighbor in secret.\u2019 All the people shall say, \u2018Amen.\u2019",
                "\u2018Cursed is he who takes a bribe to kill an innocent person.\u2019 All the people shall say, \u2018Amen.\u2019",
                "\u2018Cursed is he who doesn\u2019t confirm the words of this law to do them.\u2019 All the people shall say, \u2018Amen.\u2019\""
            ],
            28: [
                "It shall happen, if you shall listen diligently to the voice of Yahweh your God, to observe to do all his commandments which I command you this day, that Yahweh your God will set you on high above all the nations of the earth:",
                "and all these blessings shall come on you, and overtake you, if you shall listen to the voice of Yahweh your God.",
                "You shall be blessed in the city, and you shall be blessed in the field.",
                "You shall be blessed in the fruit of your body, the fruit of your ground, the fruit of your animals, the increase of your livestock, and the young of your flock.",
                "Your basket and your kneading trough shall be blessed.",
                "You shall be blessed when you come in, and you shall be blessed when you go out.",
                "Yahweh will cause your enemies who rise up against you to be struck before you. They will come out against you one way, and will flee before you seven ways.",
                "Yahweh will command the blessing on you in your barns, and in all that you put your hand to; and he will bless you in the land which Yahweh your God gives you.",
                "Yahweh will establish you for a holy people to himself, as he has sworn to you; if you shall keep the commandments of Yahweh your God, and walk in his ways.",
                "All the peoples of the earth shall see that you are called by the name of Yahweh; and they shall be afraid of you.",
                "Yahweh will make you plenteous for good, in the fruit of your body, and in the fruit of your livestock, and in the fruit of your ground, in the land which Yahweh swore to your fathers to give you.",
                "Yahweh will open to you his good treasure in the sky, to give the rain of your land in its season, and to bless all the work of your hand: and you shall lend to many nations, and you shall not borrow.",
                "Yahweh will make you the head, and not the tail; and you shall be above only, and you shall not be beneath; if you shall listen to the commandments of Yahweh your God, which I command you this day, to observe and to do,",
                "and shall not turn aside from any of the words which I command you this day, to the right hand, or to the left, to go after other gods to serve them.",
                "But it shall come to pass, if you will not listen to the voice of Yahweh your God, to observe to do all his commandments and his statutes which I command you this day, that all these curses shall come on you, and overtake you.",
                "You shall be cursed in the city, and you shall be cursed in the field.",
                "Your basket and your kneading trough shall be cursed.",
                "The fruit of your body, the fruit of your ground, the increase of your livestock, and the young of your flock shall be cursed.",
                "You shall be cursed when you come in, and you shall be cursed when you go out.",
                "Yahweh will send on you cursing, confusion, and rebuke, in all that you put your hand to do, until you are destroyed, and until you perish quickly; because of the evil of your doings, by which you have forsaken me.",
                "Yahweh will make the pestilence cling to you, until he has consumed you from off the land, where you go in to possess it.",
                "Yahweh will strike you with consumption, and with fever, and with inflammation, and with fiery heat, and with the sword, and with blight, and with mildew; and they shall pursue you until you perish.",
                "Your sky that is over your head shall be brass, and the earth that is under you shall be iron.",
                "Yahweh will make the rain of your land powder and dust: from the sky shall it come down on you, until you are destroyed.",
                "Yahweh will cause you to be struck before your enemies; you shall go out one way against them, and shall flee seven ways before them: and you shall be tossed back and forth among all the kingdoms of the earth.",
                "Your dead body shall be food to all birds of the sky, and to the animals of the earth; and there shall be none to frighten them away.",
                "Yahweh will strike you with the boil of Egypt, and with the tumors, and with the scurvy, and with the itch, of which you can not be healed.",
                "Yahweh will strike you with madness, and with blindness, and with astonishment of heart;",
                "and you shall grope at noonday, as the blind gropes in darkness, and you shall not prosper in your ways: and you shall be only oppressed and robbed always, and there shall be none to save you.",
                "You shall betroth a wife, and another man shall lie with her: you shall build a house, and you shall not dwell therein: you shall plant a vineyard, and shall not use its fruit.",
                "Your ox shall be slain before your eyes, and you shall not eat of it: your donkey shall be violently taken away from before your face, and shall not be restored to you: your sheep shall be given to your enemies, and you shall have none to save you.",
                "Your sons and your daughters shall be given to another people; and your eyes shall look, and fail with longing for them all the day: and there shall be nothing in the power of your hand.",
                "The fruit of your ground, and all your labors, shall a nation which you don\u2019t know eat up; and you shall be only oppressed and crushed always;",
                "so that you shall be mad for the sight of your eyes which you shall see.",
                "Yahweh will strike you in the knees, and in the legs, with a sore boil, of which you can not be healed, from the sole of your foot to the crown of your head.",
                "Yahweh will bring you, and your king whom you shall set over you, to a nation that you have not known, you nor your fathers; and there you shall serve other gods, wood and stone.",
                "You shall become an astonishment, a proverb, and a byword, among all the peoples where Yahweh shall lead you away.",
                "You shall carry much seed out into the field, and shall gather little in; for the locust shall consume it.",
                "You shall plant vineyards and dress them, but you shall neither drink of the wine, nor harvest; for the worm shall eat them.",
                "You shall have olive trees throughout all your borders, but you shall not anoint yourself with the oil; for your olives shall drop off.",
                "You shall father sons and daughters, but they shall not be yours; for they shall go into captivity.",
                "All your trees and the fruit of your ground shall the locust possess.",
                "The foreigner who is in the midst of you shall mount up above you higher and higher; and you shall come down lower and lower.",
                "He shall lend to you, and you shall not lend to him: he shall be the head, and you shall be the tail.",
                "All these curses shall come on you, and shall pursue you, and overtake you, until you are destroyed; because you didn\u2019t listen to the voice of Yahweh your God, to keep his commandments and his statutes which he commanded you:",
                "and they shall be on you for a sign and for a wonder, and on your seed forever.",
                "Because you didn\u2019t serve Yahweh your God with joyfulness, and with gladness of heart, by reason of the abundance of all things;",
                "therefore you shall serve your enemies whom Yahweh shall send against you, in hunger, and in thirst, and in nakedness, and in want of all things: and he shall put a yoke of iron on your neck, until he has destroyed you.",
                "Yahweh will bring a nation against you from far, from the end of the earth, as the eagle flies; a nation whose language you shall not understand;",
                "a nation of fierce facial expressions, that shall not respect the person of the old, nor show favor to the young,",
                "and shall eat the fruit of your livestock, and the fruit of your ground, until you are destroyed; that also shall not leave you grain, new wine, or oil, the increase of your livestock, or the young of your flock, until they have caused you to perish.",
                "They shall besiege you in all your gates, until your high and fortified walls come down, in which you trusted, throughout all your land; and they shall besiege you in all your gates throughout all your land, which Yahweh your God has given you.",
                "You shall eat the fruit of your own body, the flesh of your sons and of your daughters, whom Yahweh your God has given you, in the siege and in the distress with which your enemies shall distress you.",
                "The man who is tender among you, and very delicate, his eye shall be evil toward his brother, and toward the wife of his bosom, and toward the remnant of his children whom he has remaining;",
                "so that he will not give to any of them of the flesh of his children whom he shall eat, because he has nothing left him, in the siege and in the distress with which your enemy shall distress you in all your gates.",
                "The tender and delicate woman among you, who would not adventure to set the sole of her foot on the ground for delicateness and tenderness, her eye shall be evil toward the husband of her bosom, and toward her son, and toward her daughter,",
                "and toward her young one who comes out from between her feet, and toward her children whom she shall bear; for she shall eat them for want of all things secretly, in the siege and in the distress with which your enemy shall distress you in your gates.",
                "If you will not observe to do all the words of this law that are written in this book, that you may fear this glorious and fearful name, YAHWEH YOUR GOD;",
                "then Yahweh will make your plagues wonderful, and the plagues of your seed, even great plagues, and of long continuance, and severe sicknesses, and of long continuance.",
                "He will bring on you again all the diseases of Egypt, which you were afraid of; and they shall cling to you.",
                "Also every sickness, and every plague, which is not written in the book of this law, Yahweh will bring them on you, until you are destroyed.",
                "You shall be left few in number, whereas you were as the stars of the sky for multitude; because you didn\u2019t listen to the voice of Yahweh your God.",
                "It shall happen that as Yahweh rejoiced over you to do you good, and to multiply you, so Yahweh will rejoice over you to cause you to perish, and to destroy you; and you shall be plucked from off the land where you go in to possess it.",
                "Yahweh will scatter you among all peoples, from the one end of the earth even to the other end of the earth; and there you shall serve other gods, which you have not known, you nor your fathers, even wood and stone.",
                "Among these nations you shall find no ease, and there shall be no rest for the sole of your foot: but Yahweh will give you there a trembling heart, and failing of eyes, and pining of soul;",
                "and your life shall hang in doubt before you; and you shall fear night and day, and shall have no assurance of your life.",
                "In the morning you shall say, \"I wish it were evening!\" and at evening you shall say, \"I wish it were morning!\" for the fear of your heart which you shall fear, and for the sight of your eyes which you shall see.",
                "Yahweh will bring you into Egypt again with ships, by the way of which I said to you, You shall see it no more again: and there you shall sell yourselves to your enemies for bondservants and for bondmaids, and no man shall buy you."
            ],
            29: [
                "These are the words of the covenant which Yahweh commanded Moses to make with the children of Israel in the land of Moab, besides the covenant which he made with them in Horeb.",
                "Moses called to all Israel, and said to them, You have seen all that Yahweh did before your eyes in the land of Egypt to Pharaoh, and to all his servants, and to all his land;",
                "the great trials which your eyes saw, the signs, and those great wonders:",
                "but Yahweh has not given you a heart to know, and eyes to see, and ears to hear, to this day.",
                "I have led you forty years in the wilderness: your clothes have not grown old on you, and your shoes have not grown old on your feet.",
                "You have not eaten bread, neither have you drunk wine or strong drink; that you may know that I am Yahweh your God.",
                "When you came to this place, Sihon the king of Heshbon, and Og the king of Bashan, came out against us to battle, and we struck them:",
                "and we took their land, and gave it for an inheritance to the Reubenites, and to the Gadites, and to the half-tribe of the Manassites.",
                "Keep therefore the words of this covenant, and do them, that you may prosper in all that you do.",
                "You stand this day all of you before Yahweh your God; your heads, your tribes, your elders, and your officers, even all the men of Israel,",
                "your little ones, your wives, and your foreigner who is in the midst of your camps, from the one who cuts your wood to the one who draws your water;",
                "that you may enter into the covenant of Yahweh your God, and into his oath, which Yahweh your God makes with you this day;",
                "that he may establish you this day to himself for a people, and that he may be to you a God, as he spoke to you, and as he swore to your fathers, to Abraham, to Isaac, and to Jacob.",
                "Neither with you only do I make this covenant and this oath,",
                "but with him who stands here with us this day before Yahweh our God, and also with him who is not here with us this day",
                "(for you know how we lived in the land of Egypt, and how we came through the midst of the nations through which you passed;",
                "and you have seen their abominations, and their idols, wood and stone, silver and gold, which were among them);",
                "lest there should be among you man, or woman, or family, or tribe, whose heart turns away this day from Yahweh our God, to go to serve the gods of those nations; lest there should be among you a root that bears gall and wormwood;",
                "and it happen, when he hears the words of this curse, that he bless himself in his heart, saying, \"I shall have peace, though I walk in the stubbornness of my heart, to destroy the moist with the dry.\"",
                "Yahweh will not pardon him, but then the anger of Yahweh and his jealousy will smoke against that man, and all the curse that is written in this book shall lie on him, and Yahweh will blot out his name from under the sky.",
                "Yahweh will set him apart to evil out of all the tribes of Israel, according to all the curses of the covenant that is written in this book of the law.",
                "The generation to come, your children who shall rise up after you, and the foreigner who shall come from a far land, shall say, when they see the plagues of that land, and the sicknesses with which Yahweh has made it sick;",
                "and that the whole land of it is sulfur, salt, and burning, that it is not sown, nor bears, nor any grass grows therein, like the overthrow of Sodom and Gomorrah, Admah and Zeboiim, which Yahweh overthrew in his anger, and in his wrath:",
                "even all the nations shall say, \"Why has Yahweh done thus to this land? What does the heat of this great anger mean?\"",
                "Then men shall say, \"Because they forsook the covenant of Yahweh, the God of their fathers, which he made with them when he brought them forth out of the land of Egypt,",
                "and went and served other gods, and worshiped them, gods that they didn\u2019t know, and that he had not given to them:",
                "therefore the anger of Yahweh was kindled against this land, to bring on it all the curse that is written in this book;",
                "and Yahweh rooted them out of their land in anger, and in wrath, and in great indignation, and cast them into another land, as at this day.\"",
                "The secret things belong to Yahweh our God; but the things that are revealed belong to us and to our children forever, that we may do all the words of this law."
            ],
            30: [
                "It shall happen, when all these things have come on you, the blessing and the curse, which I have set before you, and you shall call them to mind among all the nations, where Yahweh your God has driven you,",
                "and shall return to Yahweh your God, and shall obey his voice according to all that I command you this day, you and your children, with all your heart, and with all your soul;",
                "that then Yahweh your God will turn your captivity, and have compassion on you, and will return and gather you from all the peoples, where Yahweh your God has scattered you.",
                "If your outcasts are in the uttermost parts of the heavens, from there will Yahweh your God gather you, and from there he will bring you back:",
                "and Yahweh your God will bring you into the land which your fathers possessed, and you shall possess it; and he will do you good, and multiply you above your fathers.",
                "Yahweh your God will circumcise your heart, and the heart of your seed, to love Yahweh your God with all your heart, and with all your soul, that you may live.",
                "Yahweh your God will put all these curses on your enemies, and on those who hate you, who persecuted you.",
                "You shall return and obey the voice of Yahweh, and do all his commandments which I command you this day.",
                "Yahweh your God will make you plenteous in all the work of your hand, in the fruit of your body, and in the fruit of your livestock, and in the fruit of your ground, for good: for Yahweh will again rejoice over you for good, as he rejoiced over your fathers;",
                "if you shall obey the voice of Yahweh your God, to keep his commandments and his statutes which are written in this book of the law; if you turn to Yahweh your God with all your heart, and with all your soul.",
                "For this commandment which I command you this day, it is not too hard for you, neither is it far off.",
                "It is not in heaven, that you should say, \"Who shall go up for us to heaven, and bring it to us, and make us to hear it, that we may do it?\"",
                "Neither is it beyond the sea, that you should say, \"Who shall go over the sea for us, and bring it to us, and make us to hear it, that we may do it?\"",
                "But the word is very near to you, in your mouth, and in your heart, that you may do it.",
                "Behold, I have set before you this day life and good, and death and evil;",
                "in that I command you this day to love Yahweh your God, to walk in his ways, and to keep his commandments and his statutes and his ordinances, that you may live and multiply, and that Yahweh your God may bless you in the land where you go in to possess it.",
                "But if your heart turns away, and you will not hear, but shall be drawn away, and worship other gods, and serve them;",
                "I denounce to you this day, that you shall surely perish; you shall not prolong your days in the land, where you pass over the Jordan to go in to possess it.",
                "I call heaven and earth to witness against you this day, that I have set before you life and death, the blessing and the curse: therefore choose life, that you may live, you and your seed;",
                "to love Yahweh your God, to obey his voice, and to cling to him; for he is your life, and the length of your days; that you may dwell in the land which Yahweh swore to your fathers, to Abraham, to Isaac, and to Jacob, to give them."
            ],
            31: [
                "Moses went and spoke these words to all Israel.",
                "He said to them, \"I am one hundred twenty years old this day; I can no more go out and come in: and Yahweh has said to me, \u2018You shall not go over this Jordan.\u2019",
                "Yahweh your God, he will go over before you; he will destroy these nations from before you, and you shall dispossess them. Joshua shall go over before you, as Yahweh has spoken.",
                "Yahweh will do to them as he did to Sihon and to Og, the kings of the Amorites, and to their land; whom he destroyed.",
                "Yahweh will deliver them up before you, and you shall do to them according to all the commandment which I have commanded you.",
                "Be strong and courageous, don\u2019t be afraid, nor be scared of them: for Yahweh your God, he it is who does go with you; he will not fail you, nor forsake you.\"",
                "Moses called to Joshua, and said to him in the sight of all Israel, \"Be strong and courageous: for you shall go with this people into the land which Yahweh has sworn to their fathers to give them; and you shall cause them to inherit it.",
                "Yahweh, he it is who does go before you; he will be with you, he will not fail you, neither forsake you: don\u2019t be afraid, neither be dismayed.\"",
                "Moses wrote this law, and delivered it to the priests the sons of Levi, who bore the ark of the covenant of Yahweh, and to all the elders of Israel.",
                "Moses commanded them, saying, \"At the end of every seven years, in the set time of the year of release, in the feast of tents,",
                "when all Israel has come to appear before Yahweh your God in the place which he shall choose, you shall read this law before all Israel in their hearing.",
                "Assemble the people, the men and the women and the little ones, and your foreigner who is within your gates, that they may hear, and that they may learn, and fear Yahweh your God, and observe to do all the words of this law;",
                "and that their children, who have not known, may hear, and learn to fear Yahweh your God, as long as you live in the land where you go over the Jordan to possess it.\"",
                "Yahweh said to Moses, \"Behold, your days approach that you must die: call Joshua, and present yourselves in the Tent of Meeting, that I may commission him.\" Moses and Joshua went, and presented themselves in the Tent of Meeting.",
                "Yahweh appeared in the Tent in a pillar of cloud: and the pillar of cloud stood over the door of the Tent.",
                "Yahweh said to Moses, \"Behold, you shall sleep with your fathers; and this people will rise up, and play the prostitute after the strange gods of the land, where they go to be among them, and will forsake me, and break my covenant which I have made with them.",
                "Then my anger shall be kindled against them in that day, and I will forsake them, and I will hide my face from them, and they shall be devoured, and many evils and troubles shall come on them; so that they will say in that day, \u2018Haven\u2019t these evils come on us because our God is not among us?\u2019",
                "I will surely hide my face in that day for all the evil which they shall have worked, in that they are turned to other gods.",
                "\"Now therefore write this song for yourselves, and teach it to the children of Israel: put it in their mouths, that this song may be a witness for me against the children of Israel.",
                "For when I shall have brought them into the land which I swore to their fathers, flowing with milk and honey, and they shall have eaten and filled themselves, and grown fat; then will they turn to other gods, and serve them, and despise me, and break my covenant.",
                "It shall happen, when many evils and troubles have come on them, that this song shall testify before them as a witness; for it shall not be forgotten out of the mouths of their seed: for I know their imagination which they frame this day, before I have brought them into the land which I swore.\"",
                "So Moses wrote this song the same day, and taught it the children of Israel.",
                "He commissioned Joshua the son of Nun, and said, \"Be strong and courageous; for you shall bring the children of Israel into the land which I swore to them: and I will be with you.\"",
                "It happened, when Moses had made an end of writing the words of this law in a book, until they were finished,",
                "that Moses commanded the Levites, who bore the ark of the covenant of Yahweh, saying,",
                "\"Take this book of the law, and put it by the side of the ark of the covenant of Yahweh your God, that it may be there for a witness against you.",
                "For I know your rebellion, and your stiff neck: behold, while I am yet alive with you this day, you have been rebellious against Yahweh; and how much more after my death?",
                "Assemble to me all the elders of your tribes, and your officers, that I may speak these words in their ears, and call heaven and earth to witness against them.",
                "For I know that after my death you will utterly corrupt yourselves, and turn aside from the way which I have commanded you; and evil will happen to you in the latter days; because you will do that which is evil in the sight of Yahweh, to provoke him to anger through the work of your hands.\"",
                "Moses spoke in the ears of all the assembly of Israel the words of this song, until they were finished."
            ],
            32: [
                "Give ear, you heavens, and I will speak. Let the earth hear the words of my mouth.",
                "My doctrine shall drop as the rain. My speech shall condense as the dew, as the small rain on the tender grass, as the showers on the herb.",
                "For I will proclaim the name of Yahweh. Ascribe greatness to our God!",
                "The Rock, his work is perfect, for all his ways are justice: a God of faithfulness and without iniquity, just and right is he.",
                "They have dealt corruptly with him. They are not his children, because of their defect. They are a perverse and crooked generation.",
                "Do you thus requite Yahweh, foolish people and unwise? Isn\u2019t he your father who has bought you? He has made you, and established you.",
                "Remember the days of old. Consider the years of many generations. Ask your father, and he will show you; your elders, and they will tell you.",
                "When the Most High gave to the nations their inheritance, when he separated the children of men, he set the bounds of the peoples according to the number of the children of Israel.",
                "For Yahweh\u2019s portion is his people. Jacob is the lot of his inheritance.",
                "He found him in a desert land, in the waste howling wilderness. He surrounded him. He cared for him. He kept him as the apple of his eye.",
                "As an eagle that stirs up her nest, that flutters over her young, he spread abroad his wings, he took them, he bore them on his feathers.",
                "Yahweh alone led him. There was no foreign god with him.",
                "He made him ride on the high places of the earth. He ate the increase of the field. He caused him to suck honey out of the rock, oil out of the flinty rock;",
                "Butter of the herd, and milk of the flock, with fat of lambs, rams of the breed of Bashan, and goats, with the finest of the wheat. Of the blood of the grape you drank wine.",
                "But Jeshurun grew fat, and kicked. You have grown fat. You have grown thick. You have become sleek. Then he forsook God who made him, and lightly esteemed the Rock of his salvation.",
                "They moved him to jealousy with strange gods. They provoked him to anger with abominations.",
                "They sacrificed to demons, not God, to gods that they didn\u2019t know, to new gods that came up recently, which your fathers didn\u2019t dread.",
                "Of the Rock who became your father, you are unmindful, and have forgotten God who gave you birth.",
                "Yahweh saw and abhorred, because of the provocation of his sons and his daughters.",
                "He said, \"I will hide my face from them. I will see what their end shall be; for they are a very perverse generation, children in whom is no faithfulness.",
                "They have moved me to jealousy with that which is not God. They have provoked me to anger with their vanities. I will move them to jealousy with those who are not a people. I will provoke them to anger with a foolish nation.",
                "For a fire is kindled in my anger, Burns to the lowest Sheol, Devours the earth with its increase, and sets the foundations of the mountains on fire.",
                "\"I will heap evils on them. I will spend my arrows on them.",
                "They shall be wasted with hunger, and devoured with burning heat and bitter destruction. I will send the teeth of animals on them, With the poison of crawling things of the dust.",
                "Outside the sword shall bereave, and in the rooms, terror; on both young man and virgin, The nursing infant with the gray-haired man.",
                "I said, I would scatter them afar. I would make their memory to cease from among men;",
                "were it not that I feared the provocation of the enemy, lest their adversaries should judge wrongly, lest they should say, \u2018Our hand is exalted, Yahweh has not done all this.\u2019\"",
                "For they are a nation void of counsel. There is no understanding in them.",
                "Oh that they were wise, that they understood this, that they would consider their latter end!",
                "How could one chase a thousand, and two put ten thousand to flight, unless their Rock had sold them, and Yahweh had delivered them up?",
                "For their rock is not as our Rock, even our enemies themselves being judges.",
                "For their vine is of the vine of Sodom, of the fields of Gomorrah. Their grapes are grapes of gall, Their clusters are bitter.",
                "Their wine is the poison of serpents, The cruel venom of asps.",
                "\"Isn\u2019t this laid up in store with me, sealed up among my treasures?",
                "Vengeance is mine, and recompense, at the time when their foot slides; for the day of their calamity is at hand. The things that are to come on them shall make haste.\"",
                "For Yahweh will judge his people, and have compassion on his servants, when he sees that their power is gone, There is none remaining, shut up or left at large.",
                "He will say, \"Where are their gods, The rock in which they took refuge;",
                "Which ate the fat of their sacrifices, And drank the wine of their drink offering? Let them rise up and help you! Let them be your protection.",
                "\"See now that I, even I, am he, There is no god with me. I kill, and I make alive. I wound, and I heal. There is no one who can deliver out of my hand.",
                "For I lift up my hand to heaven, And say, As I live forever,",
                "if I whet my glittering sword, My hand take hold on judgment; I will render vengeance to my adversaries, and will recompense those who hate me.",
                "I will make my arrows drunk with blood. My sword shall devour flesh with the blood of the slain and the captives, from the head of the leaders of the enemy.\"",
                "Rejoice, you nations, with his people, for he will avenge the blood of his servants. He will render vengeance to his adversaries, And will make expiation for his land, for his people.",
                "Moses came and spoke all the words of this song in the ears of the people, he and Joshua the son of Nun.",
                "Moses made an end of speaking all these words to all Israel;",
                "He said to them, \"Set your heart to all the words which I testify to you this day, which you shall command your children to observe to do, all the words of this law.",
                "For it is no vain thing for you; because it is your life, and through this thing you shall prolong your days in the land, where you go over the Jordan to possess it.\"",
                "Yahweh spoke to Moses that same day, saying,",
                "\"Go up into this mountain of Abarim, to Mount Nebo, which is in the land of Moab, that is over against Jericho; and see the land of Canaan, which I give to the children of Israel for a possession;",
                "and die on the mountain where you go up, and be gathered to your people, as Aaron your brother died on Mount Hor, and was gathered to his people:",
                "because you trespassed against me in the midst of the children of Israel at the waters of Meribah of Kadesh, in the wilderness of Zin; because you didn\u2019t sanctify me in the midst of the children of Israel.",
                "For you shall see the land before you; but you shall not go there into the land which I give the children of Israel.\""
            ],
            33: [
                "This is the blessing, with which Moses the man of God blessed the children of Israel before his death.",
                "He said, \"Yahweh came from Sinai, And rose from Seir to them. He shone forth from Mount Paran. He came from the ten thousands of holy ones. At his right hand was a fiery law for them.",
                "Yes, he loves the people. All his saints are in your hand. They sat down at your feet; each receives your words.",
                "Moses commanded us a law, An inheritance for the assembly of Jacob.",
                "He was king in Jeshurun, When the heads of the people were gathered, All the tribes of Israel together.",
                "\"Let Reuben live, and not die; Nor let his men be few.\"",
                "This is for Judah. He said, \"Hear, Yahweh, the voice of Judah. Bring him in to his people. With his hands he contended for himself. You shall be a help against his adversaries.\"",
                "Of Levi he said, \"Your Thummim and your Urim are with your godly one, whom you proved at Massah, with whom you strove at the waters of Meribah;",
                "who said of his father, and of his mother, \u2018I have not seen him;\u2019 Neither did he acknowledge his brothers, Nor did he know his own children: For they have observed your word, and keep your covenant.",
                "They shall teach Jacob your ordinances, and Israel your law. They shall put incense before you, and whole burnt offering on your altar.",
                "Yahweh, bless his substance. Accept the work of his hands. Strike through the hips of those who rise up against him, of those who hate him, that they not rise again.\"",
                "Of Benjamin he said, \"The beloved of Yahweh shall dwell in safety by him. He covers him all the day long. He dwells between his shoulders.\"",
                "Of Joseph he said, \"His land is blessed by Yahweh, for the precious things of the heavens, for the dew, for the deep that couches beneath,",
                "for the precious things of the fruits of the sun, for the precious things of the growth of the moons,",
                "for the chief things of the ancient mountains, for the precious things of the everlasting hills,",
                "for the precious things of the earth and its fullness, the good will of him who lived in the bush. Let this come on the head of Joseph, on the crown of the head of him who was separate from his brothers.",
                "The firstborn of his herd, majesty is his. His horns are the horns of the wild ox. With them he shall push all of the peoples, to the ends of the earth: They are the ten thousands of Ephraim. They are the thousands of Manasseh.\"",
                "Of Zebulun he said, \"Rejoice, Zebulun, in your going out; and Issachar, in your tents.",
                "They shall call the peoples to the mountain. There they will offer sacrifices of righteousness, for they shall draw out the abundance of the seas, the hidden treasures of the sand.\"",
                "Of Gad he said, \"He who enlarges Gad is blessed. He dwells as a lioness, and tears the arm, yes, the crown of the head.",
                "He provided the first part for himself, for there was the lawgiver\u2019s portion reserved. He came with the heads of the people. He executed the righteousness of Yahweh, His ordinances with Israel.\"",
                "Of Dan he said, \"Dan is a lion\u2019s cub that leaps out of Bashan.\"",
                "Of Naphtali he said, \"Naphtali, satisfied with favor, full of the blessing of Yahweh, Possess the west and the south.\"",
                "Of Asher he said, \"Asher is blessed with children. Let him be acceptable to his brothers. Let him dip his foot in oil.",
                "Your bars shall be iron and brass. As your days, so your strength will be.",
                "\"There is none like God, Jeshurun, who rides on the heavens for your help, In his excellency on the skies.",
                "The eternal God is your dwelling place. Underneath are the everlasting arms. He thrust out the enemy from before you, and said, \u2018Destroy!\u2019",
                "Israel dwells in safety; the fountain of Jacob alone, In a land of grain and new wine. Yes, his heavens drop down dew.",
                "You are happy, Israel. Who is like you, a people saved by Yahweh, the shield of your help, the sword of your excellency! Your enemies shall submit themselves to you. You shall tread on their high places.\""
            ],
            34: [
                "Moses went up from the plains of Moab to Mount Nebo, to the top of Pisgah, that is over against Jericho. Yahweh showed him all the land of Gilead, to Dan,",
                "and all Naphtali, and the land of Ephraim and Manasseh, and all the land of Judah, to the hinder sea,",
                "and the South, and the Plain of the valley of Jericho the city of palm trees, to Zoar.",
                "Yahweh said to him, \"This is the land which I swore to Abraham, to Isaac, and to Jacob, saying, \u2018I will give it to your seed.\u2019 I have caused you to see it with your eyes, but you shall not go over there.\"",
                "So Moses the servant of Yahweh died there in the land of Moab, according to the word of Yahweh.",
                "He buried him in the valley in the land of Moab over against Beth Peor: but no man knows of his tomb to this day.",
                "Moses was one hundred twenty years old when he died: his eye was not dim, nor his natural force abated.",
                "The children of Israel wept for Moses in the plains of Moab thirty days: so the days of weeping in the mourning for Moses were ended.",
                "Joshua the son of Nun was full of the spirit of wisdom; for Moses had laid his hands on him: and the children of Israel listened to him, and did as Yahweh commanded Moses.",
                "There has not arisen a prophet since in Israel like Moses, whom Yahweh knew face to face,",
                "in all the signs and the wonders, which Yahweh sent him to do in the land of Egypt, to Pharaoh, and to all his servants, and to all his land,",
                "and in all the mighty hand, and in all the great terror, which Moses worked in the sight of all Israel."
            ]
        },
        6: {
            1: [
                "Now it happened after the death of Moses the servant of Yahweh, that Yahweh spoke to Joshua the son of Nun, Moses\u2019 servant, saying,",
                "\"Moses my servant is dead; now therefore arise, go over this Jordan, you, and all this people, to the land which I give to them, even to the children of Israel.",
                "I have given you every place that the sole of your foot will tread on, as I told Moses.",
                "From the wilderness, and this Lebanon, even to the great river, the river Euphrates, all the land of the Hittites, and to the great sea toward the going down of the sun, shall be your border.",
                "No man will be able to stand before you all the days of your life. As I was with Moses, so I will be with you. I will not fail you nor forsake you.",
                "\"Be strong and courageous; for you shall cause this people to inherit the land which I swore to their fathers to give them.",
                "Only be strong and very courageous, to observe to do according to all the law, which Moses my servant commanded you. Don\u2019t turn from it to the right hand or to the left, that you may have good success wherever you go.",
                "This book of the law shall not depart out of your mouth, but you shall meditate on it day and night, that you may observe to do according to all that is written therein: for then you shall make your way prosperous, and then you shall have good success.",
                "Haven\u2019t I commanded you? Be strong and courageous. Don\u2019t be afraid, neither be dismayed: for Yahweh your God is with you wherever you go.\"",
                "Then Joshua commanded the officers of the people, saying,",
                "\"Pass through the midst of the camp, and command the people, saying, \u2018Prepare food; for within three days you are to pass over this Jordan, to go in to possess the land, which Yahweh your God gives you to possess it.\u2019\"",
                "Joshua spoke to the Reubenites, and to the Gadites, and to the half-tribe of Manasseh, saying,",
                "\"Remember the word which Moses the servant of Yahweh commanded you, saying, \u2018Yahweh your God gives you rest, and will give you this land.",
                "Your wives, your little ones, and your livestock, shall live in the land which Moses gave you beyond the Jordan; but you shall pass over before your brothers armed, all the mighty men of valor, and shall help them",
                "until Yahweh has given your brothers rest, as he has given you, and they have also possessed the land which Yahweh your God gives them. Then you shall return to the land of your possession, and possess it, which Moses the servant of Yahweh gave you beyond the Jordan toward the sunrise.\u2019\"",
                "They answered Joshua, saying, \"All that you have commanded us we will do, and wherever you send us we will go.",
                "Just as we listened to Moses in all things, so will we listen to you. Only may Yahweh your God be with you, as he was with Moses.",
                "Whoever rebels against your commandment, and doesn\u2019t listen to your words in all that you command him, he shall be put to death. Only be strong and courageous.\""
            ],
            2: [
                "Joshua the son of Nun secretly sent two men out of Shittim as spies, saying, \"Go, view the land, including Jericho.\" They went and came into the house of a prostitute whose name was Rahab, and slept there.",
                "The king of Jericho was told, \"Behold, men of the children of Israel came in here tonight to spy out the land.\"",
                "The king of Jericho sent to Rahab, saying, \"Bring out the men who have come to you, who have entered into your house; for they have come to spy out all the land.\"",
                "The woman took the two men and hid them. Then she said, \"Yes, the men came to me, but I didn\u2019t know where they came from.",
                "It happened about the time of the shutting of the gate, when it was dark, that the men went out. Where the men went, I don\u2019t know. Pursue them quickly; for you will overtake them.\"",
                "But she had brought them up to the roof, and hid them with the stalks of flax, which she had laid in order on the roof.",
                "The men pursued them the way to the Jordan to the fords: and as soon as those who pursued them had gone out, they shut the gate.",
                "Before they had laid down, she came up to them on the roof;",
                "and she said to the men, \"I know that Yahweh has given you the land, and that the fear of you has fallen on us, and that all the inhabitants of the land melt away before you.",
                "For we have heard how Yahweh dried up the water of the Red Sea before you, when you came out of Egypt; and what you did to the two kings of the Amorites, who were beyond the Jordan, to Sihon and to Og, whom you utterly destroyed.",
                "As soon as we had heard it, our hearts melted, neither did there remain any more spirit in any man, because of you: for Yahweh your God, he is God in heaven above, and on earth beneath.",
                "Now therefore, please swear to me by Yahweh, since I have dealt kindly with you, that you also will deal kindly with my father\u2019s house, and give me a true token;",
                "and that you will save alive my father, my mother, my brothers, and my sisters, and all that they have, and will deliver our lives from death.\"",
                "The men said to her, \"Our life for yours, if you don\u2019t talk about this business of ours; and it shall be, when Yahweh gives us the land, that we will deal kindly and truly with you.\"",
                "Then she let them down by a cord through the window; for her house was on the side of the wall, and she lived on the wall.",
                "She said to them, \"Go to the mountain, lest the pursuers find you; and hide yourselves there three days, until the pursuers have returned. Afterward, you may go your way.\"",
                "The men said to her, \"We will be guiltless of this your oath which you have made us to swear.",
                "Behold, when we come into the land, you shall bind this line of scarlet thread in the window which you used to let us down. You shall gather to yourself into the house your father, your mother, your brothers, and all your father\u2019s household.",
                "It shall be that whoever goes out of the doors of your house into the street, his blood will be on his head, and we will be guiltless. Whoever is with you in the house, his blood shall be on our head, if any hand is on him.",
                "But if you talk about this business of ours, then we shall be guiltless of your oath which you have made us to swear.\"",
                "She said, \"According to your words, so be it.\" She sent them away, and they departed. She tied the scarlet line in the window.",
                "They went, and came to the mountain, and stayed there three days, until the pursuers had returned. The pursuers sought them throughout all the way, but didn\u2019t find them.",
                "Then the two men returned, descended from the mountain, passed over, and came to Joshua the son of Nun; and they told him all that had happened to them.",
                "They said to Joshua, \"Truly Yahweh has delivered into our hands all the land. Moreover, all the inhabitants of the land melt away before us.\""
            ],
            3: [
                "Joshua rose up early in the morning; and they moved from Shittim, and came to the Jordan, he and all the children of Israel. They lodged there before they passed over.",
                "It happened after three days, that the officers went through the midst of the camp;",
                "and they commanded the people, saying, \"When you see the ark of the covenant of Yahweh your God, and the priests the Levites bearing it, then you shall move from your place, and follow it.",
                "Yet there shall be a space between you and it, about two thousand cubits by measure. Don\u2019t come near to it, that you may know the way by which you must go; for you have not passed this way before.\"",
                "Joshua said to the people, \"Sanctify yourselves; for tomorrow Yahweh will do wonders among you.\"",
                "Joshua spoke to the priests, saying, \"Take up the ark of the covenant, and pass over before the people.\" They took up the ark of the covenant, and went before the people.",
                "Yahweh said to Joshua, \"Today I will begin to magnify you in the sight of all Israel, that they may know that as I was with Moses, so I will be with you.",
                "You shall command the priests who bear the ark of the covenant, saying, \u2018When you come to the brink of the waters of the Jordan, you shall stand still in the Jordan.\u2019\"",
                "Joshua said to the children of Israel, \"Come here, and hear the words of Yahweh your God.\"",
                "Joshua said, \"Hereby you shall know that the living God is among you, and that he will without fail drive the Canaanite, and the Hittite, and the Hivite, and the Perizzite, and the Girgashite, and the Amorite, and the Jebusite out from before you.",
                "Behold, the ark of the covenant of the Lord of all the earth passes over before you into the Jordan.",
                "Now therefore take twelve men out of the tribes of Israel, for every tribe a man.",
                "It shall come to pass, when the soles of the feet of the priests who bear the ark of Yahweh, the Lord of all the earth, rest in the waters of the Jordan, that the waters of the Jordan will be cut off, even the waters that come down from above; and they shall stand in one heap.\"",
                "It happened, when the people moved from their tents to pass over the Jordan, the priests who bore the ark of the covenant being before the people,",
                "and when those who bore the ark had come to the Jordan, and the feet of the priests who bore the ark had dipped in the edge of the water (for the Jordan overflows all its banks all the time of harvest),",
                "that the waters which came down from above stood, and rose up in one heap, a great way off, at Adam, the city that is beside Zarethan; and those that went down toward the sea of the Arabah, even the Salt Sea, were wholly cut off. Then the people passed over right against Jericho.",
                "The priests who bore the ark of the covenant of Yahweh stood firm on dry ground in the middle of the Jordan; and all Israel passed over on dry ground, until all the nation had passed completely over the Jordan."
            ],
            4: [
                "It happened, when all the nation had completely passed over the Jordan, that Yahweh spoke to Joshua, saying,",
                "\"Take twelve men out of the people, out of every tribe a man,",
                "and command them, saying, \u2018Take from out of the middle of the Jordan, out of the place where the priests\u2019 feet stood firm, twelve stones, and carry them over with you, and lay them down in the lodging place, where you will lodge tonight.\u2019\"",
                "Then Joshua called the twelve men, whom he had prepared of the children of Israel, out of every tribe a man.",
                "Joshua said to them, \"Pass over before the ark of Yahweh your God into the middle of the Jordan, and each of you pick up a stone and put it on your shoulder, according to the number of the tribes of the children of Israel;",
                "that this may be a sign among you, that when your children ask in time to come, saying, \u2018What do you mean by these stones?\u2019",
                "then you shall tell them, \u2018Because the waters of the Jordan were cut off before the ark of the covenant of Yahweh. When it passed over the Jordan, the waters of the Jordan were cut off. These stones shall be for a memorial to the children of Israel forever.\u2019\"",
                "The children of Israel did as Joshua commanded, and took up twelve stones out of the middle of the Jordan, as Yahweh spoke to Joshua, according to the number of the tribes of the children of Israel; and they carried them over with them to the place where they lodged, and laid them down there.",
                "Joshua set up twelve stones in the middle of the Jordan, in the place where the feet of the priests who bore the ark of the covenant stood; and they are there to this day.",
                "For the priests who bore the ark stood in the middle of the Jordan, until everything was finished that Yahweh commanded Joshua to speak to the people, according to all that Moses commanded Joshua; and the people hurried and passed over.",
                "It happened, when all the people had completely passed over, that the ark of Yahweh passed over, with the priests, in the presence of the people.",
                "The children of Reuben, and the children of Gad, and the half-tribe of Manasseh, passed over armed before the children of Israel, as Moses spoke to them.",
                "About forty thousand men, ready and armed for war passed over before Yahweh to battle, to the plains of Jericho.",
                "On that day, Yahweh magnified Joshua in the sight of all Israel; and they feared him, as they feared Moses, all the days of his life.",
                "Yahweh spoke to Joshua, saying,",
                "\"Command the priests who bear the ark of the testimony, that they come up out of the Jordan.\"",
                "Joshua therefore commanded the priests, saying, \"Come up out of the Jordan!\"",
                "It happened, when the priests who bore the ark of the covenant of Yahweh had come up out of the middle of the Jordan, and the soles of the priests\u2019 feet were lifted up to the dry ground, that the waters of the Jordan returned to their place, and went over all its banks, as before.",
                "The people came up out of the Jordan on the tenth day of the first month, and encamped in Gilgal, on the east border of Jericho.",
                "Joshua set up those twelve stones, which they took out of the Jordan, in Gilgal.",
                "He spoke to the children of Israel, saying, \"When your children ask their fathers in time to come, saying, \u2018What do these stones mean?\u2019",
                "Then you shall let your children know, saying, \u2018Israel came over this Jordan on dry land.",
                "For Yahweh your God dried up the waters of the Jordan from before you, until you had passed over, as Yahweh your God did to the Red Sea, which he dried up from before us, until we had passed over;",
                "that all the peoples of the earth may know the hand of Yahweh, that it is mighty; that you may fear Yahweh your God forever.\u2019\""
            ],
            5: [
                "It happened, when all the kings of the Amorites, who were beyond the Jordan westward, and all the kings of the Canaanites, who were by the sea, heard how that Yahweh had dried up the waters of the Jordan from before the children of Israel, until we had passed over, that their heart melted, neither was there spirit in them any more, because of the children of Israel.",
                "At that time, Yahweh said to Joshua, \"Make flint knives, and circumcise again the children of Israel the second time.\"",
                "Joshua made himself flint knives, and circumcised the children of Israel at the hill of the foreskins.",
                "This is the reason Joshua circumcised: all the people who came out of Egypt, who were males, even all the men of war, died in the wilderness by the way, after they came out of Egypt.",
                "For all the people who came out were circumcised; but all the people who were born in the wilderness by the way as they came out of Egypt had not been circumcised.",
                "For the children of Israel walked forty years in the wilderness, until all the nation, even the men of war who came out of Egypt, were consumed, because they didn\u2019t listen to the voice of Yahweh. Yahweh swore to them that he wouldn\u2019t let them see the land which Yahweh swore to their fathers that he would give us, a land flowing with milk and honey.",
                "Their children, whom he raised up in their place, were circumcised by Joshua; for they were uncircumcised, because they had not circumcised them on the way.",
                "It happened, when they were done circumcising all the nation, that they stayed in their places in the camp until they were healed.",
                "Yahweh said to Joshua, \"Today I have rolled away the reproach of Egypt from off you.\" Therefore the name of that place was called Gilgal, to this day.",
                "The children of Israel encamped in Gilgal. They kept the Passover on the fourteenth day of the month at evening in the plains of Jericho.",
                "They ate unleavened cakes and parched grain of the produce of the land on the next day after the Passover, in the same day.",
                "The manna ceased on the next day, after they had eaten of the produce of the land. The children of Israel didn\u2019t have manna any more; but they ate of the fruit of the land of Canaan that year.",
                "It happened, when Joshua was by Jericho, that he lifted up his eyes and looked, and behold, a man stood in front of him with his sword drawn in his hand. Joshua went to him, and said to him, \"Are you for us, or for our adversaries?\"",
                "He said, \"No; but I have come now as commander of Yahweh\u2019s army.\" Joshua fell on his face to the earth, and worshipped, and said to him, \"What does my lord say to his servant?\"",
                "The prince of Yahweh\u2019s army said to Joshua, \"Take your shoes off of your feet; for the place on which you stand is holy.\" Joshua did so."
            ],
            6: [
                "Now Jericho was tightly shut up because of the children of Israel. No one went out, and no one came in.",
                "Yahweh said to Joshua, \"Behold, I have given Jericho into your hand, with its king and the mighty men of valor.",
                "All your men of war shall march around the city, going around the city once. You shall do this six days.",
                "Seven priests shall bear seven trumpets of rams\u2019 horns before the ark. On the seventh day, you shall march around the city seven times, and the priests shall blow the trumpets.",
                "It shall be that when they make a long blast with the ram\u2019s horn, and when you hear the sound of the trumpet, all the people shall shout with a great shout; and the wall of the city shall fall down flat, and the people shall go up every man straight before him.\"",
                "Joshua the son of Nun called the priests, and said to them, \"Take up the ark of the covenant, and let seven priests bear seven trumpets of rams\u2019 horns before the ark of Yahweh.\"",
                "They said to the people, \"Advance! March around the city, and let the armed men pass on before Yahweh\u2019s ark.\"",
                "It was so, that when Joshua had spoken to the people, the seven priests bearing the seven trumpets of rams\u2019 horns before Yahweh advanced, and blew the trumpets; and the ark of the covenant of Yahweh followed them.",
                "The armed men went before the priests who blew the trumpets, and the ark went after them. The trumpets sounded as they went.",
                "Joshua commanded the people, saying, \"You shall not shout, nor let your voice be heard, neither shall any word proceed out of your mouth, until the day I tell you to shout. Then you shall shout.\"",
                "So he caused the ark of Yahweh to go around the city, going about it once. Then they came into the camp, and lodged in the camp.",
                "Joshua rose early in the morning, and the priests took up the ark of Yahweh.",
                "The seven priests bearing the seven trumpets of rams\u2019 horns before the ark of Yahweh went on continually, and blew the trumpets: and the armed men went before them. The rear guard came after the ark of Yahweh. The trumpets sounded as they went.",
                "The second day they marched around the city once, and returned into the camp. They did this six days.",
                "It happened on the seventh day, that they rose early at the dawning of the day, and marched around the city in the same way seven times. Only on this day they marched around the city seven times.",
                "It happened at the seventh time, when the priests blew the trumpets, Joshua said to the people, \"Shout, for Yahweh has given you the city!",
                "The city shall be devoted, even it and all that is in it, to Yahweh. Only Rahab the prostitute shall live, she and all who are with her in the house, because she hid the messengers that we sent.",
                "But as for you, only keep yourselves from the devoted thing, lest when you have devoted it, you take of the devoted thing; so would you make the camp of Israel accursed, and trouble it.",
                "But all the silver, and gold, and vessels of brass and iron, are holy to Yahweh. They shall come into Yahweh\u2019s treasury.\"",
                "So the people shouted, and the priests blew the trumpets. It happened, when the people heard the sound of the trumpet, that the people shouted with a great shout, and the wall fell down flat, so that the people went up into the city, every man straight before him, and they took the city.",
                "They utterly destroyed all that was in the city, both man and woman, both young and old, and ox, and sheep, and donkey, with the edge of the sword.",
                "Joshua said to the two men who had spied out the land, \"Go into the prostitute\u2019s house, and bring out from there the woman and all that she has, as you swore to her.\"",
                "The young men who were spies went in, and brought out Rahab with her father, her mother, her brothers, and all that she had. They also brought out all her relatives, and they set them outside of the camp of Israel.",
                "They burnt the city with fire, and all that was in it. Only they put the silver, the gold, and the vessels of brass and of iron into the treasury of Yahweh\u2019s house.",
                "But Rahab the prostitute, her father\u2019s household, and all that she had, Joshua saved alive. She lived in the midst of Israel to this day, because she hid the messengers, whom Joshua sent to spy out Jericho.",
                "Joshua commanded them with an oath at that time, saying, \"Cursed is the man before Yahweh, who rises up and builds this city Jericho. With the loss of his firstborn shall he lay its foundation, and with the loss of his youngest son shall he set up its gates.\"",
                "So Yahweh was with Joshua; and his fame was in all the land."
            ],
            7: [
                "But the children of Israel committed a trespass in the devoted things; for Achan, the son of Carmi, the son of Zabdi, the son of Zerah, of the tribe of Judah, took some of the devoted things. Therefore Yahweh\u2019s anger burned against the children of Israel.",
                "Joshua sent men from Jericho to Ai, which is beside Beth Aven, on the east side of Bethel, and spoke to them, saying, \"Go up and spy out the land.\" The men went up and spied out Ai.",
                "They returned to Joshua, and said to him, \"Don\u2019t let all the people go up; but let about two or three thousand men go up and strike Ai. Don\u2019t make all the people to toil there, for there are only a few of them.\"",
                "So about three thousand men of the people went up there, and they fled before the men of Ai.",
                "The men of Ai struck about thirty-six men of them, and they chased them from before the gate even to Shebarim, and struck them at the descent. The hearts of the people melted, and became like water.",
                "Joshua tore his clothes, and fell to the earth on his face before the ark of Yahweh until the evening, he and the elders of Israel; and they put dust on their heads.",
                "Joshua said, \"Alas, Lord Yahweh, why have you brought this people over the Jordan at all, to deliver us into the hand of the Amorites, to cause us to perish? I wish that we had been content and lived beyond the Jordan!",
                "Oh, Lord, what shall I say, after that Israel has turned their backs before their enemies!",
                "For the Canaanites and all the inhabitants of the land will hear of it, and will surround us, and cut off our name from the earth. What will you do for your great name?\"",
                "Yahweh said to Joshua, \"Get up! Why are you fallen on your face like that?",
                "Israel has sinned. Yes, they have even transgressed my covenant which I commanded them. Yes, they have even taken of the devoted things, and have also stolen, and also deceived. They have even put it among their own stuff.",
                "Therefore the children of Israel can\u2019t stand before their enemies. They turn their backs before their enemies, because they have become devoted for destruction. I will not be with you any more, unless you destroy the devoted things from among you.",
                "\"Get up! Sanctify the people, and say, \u2018Sanctify yourselves for tomorrow, for Yahweh, the God of Israel, says, \"There is a devoted thing in the midst of you, Israel. You cannot stand before your enemies until you take away the devoted thing from among you.\"",
                "\"\u2018In the morning therefore you shall be brought near by your tribes. It shall be that the tribe which Yahweh selects shall come near by families. The family which Yahweh selects shall come near by households. The household which Yahweh selects shall come near man by man.",
                "It shall be, that he who is taken with the devoted thing shall be burnt with fire, he and all that he has, because he has transgressed the covenant of Yahweh, and because he has done a disgraceful thing in Israel.\u2019\"",
                "So Joshua rose up early in the morning and brought Israel near by their tribes. The tribe of Judah was selected.",
                "He brought near the family of Judah; and he selected the family of the Zerahites. He brought near the family of the Zerahites man by man, and Zabdi was selected.",
                "He brought near his household man by man, and Achan, the son of Carmi, the son of Zabdi, the son of Zerah, of the tribe of Judah, was selected.",
                "Joshua said to Achan, \"My son, please give glory to Yahweh, the God of Israel, and make confession to him. Tell me now what you have done! Don\u2019t hide it from me!\"",
                "Achan answered Joshua, and said, \"I have truly sinned against Yahweh, the God of Israel, and this is what I have done.",
                "When I saw among the spoil a beautiful Babylonian robe, two hundred shekels of silver, and a wedge of gold weighing fifty shekels, then I coveted them and took them. Behold, they are hidden in the ground in the middle of my tent, with the silver under it.\"",
                "So Joshua sent messengers, and they ran to the tent. Behold, it was hidden in his tent, with the silver under it.",
                "They took them from the middle of the tent, and brought them to Joshua and to all the children of Israel. They laid them down before Yahweh.",
                "Joshua, and all Israel with him, took Achan the son of Zerah, the silver, the robe, the wedge of gold, his sons, his daughters, his cattle, his donkeys, his sheep, his tent, and all that he had; and they brought them up to the valley of Achor.",
                "Joshua said, \"Why have you troubled us? Yahweh will trouble you this day.\" All Israel stoned him with stones, and they burned them with fire and stoned them with stones.",
                "They raised over him a great heap of stones that remains to this day. Yahweh turned from the fierceness of his anger. Therefore the name of that place was called \"The valley of Achor\" to this day."
            ],
            8: [
                "Yahweh said to Joshua, \"Don\u2019t be afraid, neither be dismayed. Take all the people of war with you, and arise, go up to Ai. Behold, I have given into your hand the king of Ai, with his people, his city, and his land.",
                "You shall do to Ai and her king as you did to Jericho and her king, except its spoil and its livestock, you shall take for a plunder for yourselves. Set an ambush for the city behind it.\"",
                "So Joshua arose, and all the people of war, to go up to Ai. Joshua chose thirty thousand men, the mighty men of valor, and sent them out by night.",
                "He commanded them, saying, \"Behold, you shall lie in ambush against the city, behind the city. Don\u2019t go very far from the city, but all of you be ready.",
                "I, and all the people who are with me, will approach to the city. It shall happen, when they come out against us, as at the first, that we will flee before them.",
                "They will come out after us, until we have drawn them away from the city; for they will say, \u2018They flee before us, like the first time.\u2019 So we will flee before them,",
                "and you shall rise up from the ambush, and take possession of the city; for Yahweh your God will deliver it into your hand.",
                "It shall be, when you have seized on the city, that you shall set the city on fire. You shall do this according to the word of Yahweh. Behold, I have commanded you.\"",
                "Joshua sent them out; and they went to set up the ambush, and stayed between Bethel and Ai, on the west side of Ai; but Joshua stayed among the people that night.",
                "Joshua rose up early in the morning, mustered the people, and went up, he and the elders of Israel, before the people to Ai.",
                "All the people, even the men of war who were with him, went up, and drew near, and came before the city, and encamped on the north side of Ai. Now there was a valley between him and Ai.",
                "He took about five thousand men, and set them in ambush between Bethel and Ai, on the west side of the city.",
                "So they set the people, even all the army who was on the north of the city, and their ambush on the west of the city; and Joshua went that night into the midst of the valley.",
                "It happened, when the king of Ai saw it, that they hurried and rose up early, and the men of the city went out against Israel to battle, he and all his people, at the time appointed, before the Arabah; but he didn\u2019t know that there was an ambush against him behind the city.",
                "Joshua and all Israel made as if they were beaten before them, and fled by the way of the wilderness.",
                "All the people who were in the city were called together to pursue after them. They pursued Joshua, and were drawn away from the city.",
                "There was not a man left in Ai or Beth El who didn\u2019t go out after Israel. They left the city open, and pursued Israel.",
                "Yahweh said to Joshua, \"Stretch out the javelin that is in your hand toward Ai, for I will give it into your hand.\" Joshua stretched out the javelin that was in his hand toward the city.",
                "The ambush arose quickly out of their place, and they ran as soon as he had stretched out his hand, and entered into the city, and took it. They hurried and set the city on fire.",
                "When the men of Ai looked behind them, they saw, and behold, the smoke of the city ascended up to heaven, and they had no power to flee this way or that way. The people who fled to the wilderness turned back on the pursuers.",
                "When Joshua and all Israel saw that the ambush had taken the city, and that the smoke of the city ascended, then they turned again, and killed the men of Ai.",
                "The others came out of the city against them, so they were in the midst of Israel, some on this side, and some on that side. They struck them, so that they let none of them remain or escape.",
                "They captured the king of Ai alive, and brought him to Joshua.",
                "It happened, when Israel had made an end of killing all the inhabitants of Ai in the field, in the wilderness in which they pursued them, and they had all fallen by the edge of the sword, until they were consumed, that all Israel returned to Ai, and struck it with the edge of the sword.",
                "All that fell that day, both of men and women, were twelve thousand, even all the men of Ai.",
                "For Joshua didn\u2019t draw back his hand, with which he stretched out the javelin, until he had utterly destroyed all the inhabitants of Ai.",
                "Only the livestock and the spoil of that city Israel took for prey to themselves, according to the word of Yahweh which he commanded Joshua.",
                "So Joshua burnt Ai, and made it a heap forever, even a desolation, to this day.",
                "He hanged the king of Ai on a tree until the evening, and at the sundown Joshua commanded, and they took his body down from the tree, and threw it at the entrance of the gate of the city, and raised a great heap of stones on it that remains to this day.",
                "Then Joshua built an altar to Yahweh, the God of Israel, in Mount Ebal,",
                "as Moses the servant of Yahweh commanded the children of Israel, as it is written in the book of the law of Moses, an altar of uncut stones, on which no man had lifted up any iron. They offered burnt offerings on it to Yahweh, and sacrificed peace offerings.",
                "He wrote there on the stones a copy of the law of Moses, which he wrote in the presence of the children of Israel.",
                "All Israel, and their elders and officers, and their judges, stood on this side of the ark and on that side before the priests the Levites, who carried the ark of Yahweh\u2019s covenant, the foreigner as well as the native; half of them in front of Mount Gerizim, and half of them in front of Mount Ebal, as Moses the servant of Yahweh had commanded at the first, that they should bless the people of Israel.",
                "Afterward he read all the words of the law, the blessing and the curse, according to all that is written in the book of the law.",
                "There was not a word of all that Moses commanded, which Joshua didn\u2019t read before all the assembly of Israel, with the women, the little ones, and the foreigners who were among them."
            ],
            9: [
                "It happened, when all the kings who were beyond the Jordan, in the hill country, and in the lowland, and on all the shore of the great sea in front of Lebanon, the Hittite, the Amorite, the Canaanite, the Perizzite, the Hivite, and the Jebusite, heard of it",
                "that they gathered themselves together to fight with Joshua and with Israel, with one accord.",
                "But when the inhabitants of Gibeon heard what Joshua had done to Jericho and to Ai,",
                "they also resorted to a ruse, and went and made as if they had been ambassadors, and took old sacks on their donkeys, and wineskins, old and torn and bound up,",
                "and old and patched shoes on their feet, and wore old garments. All the bread of their provision was dry and moldy.",
                "They went to Joshua to the camp at Gilgal, and said to him, and to the men of Israel, \"We have come from a far country. Now therefore make a covenant with us.\"",
                "The men of Israel said to the Hivites, \"What if you live among us. How could we make a covenant with you?\"",
                "They said to Joshua, \"We are your servants.\" Joshua said to them, \"Who are you? Where do you come from?\"",
                "They said to him, \"Your servants have come from a very far country because of the name of Yahweh your God; for we have heard of his fame, all that he did in Egypt,",
                "and all that he did to the two kings of the Amorites who were beyond the Jordan, to Sihon king of Heshbon and to Og king of Bashan, who was at Ashtaroth.",
                "Our elders and all the inhabitants of our country spoke to us, saying, \u2018Take provision in your hand for the journey, and go to meet them, and tell them, \"We are your servants. Now make a covenant with us.\"\u2019",
                "This our bread we took hot for our provision out of our houses on the day we went out to go to you; but now, behold, it is dry, and has become moldy.",
                "These wineskins, which we filled, were new; and behold, they are torn. These our garments and our shoes have become old because of the very long journey.\"",
                "The men sampled their provisions, and didn\u2019t ask counsel from the mouth of Yahweh.",
                "Joshua made peace with them, and made a covenant with them, to let them live. The princes of the congregation swore to them.",
                "It happened at the end of three days after they had made a covenant with them, that they heard that they were their neighbors, and that they lived among them.",
                "The children of Israel traveled and came to their cities on the third day. Now their cities were Gibeon, Chephirah, Beeroth, and Kiriath Jearim.",
                "The children of Israel didn\u2019t strike them, because the princes of the congregation had sworn to them by Yahweh, the God of Israel. All the congregation murmured against the princes.",
                "But all the princes said to all the congregation, \"We have sworn to them by Yahweh, the God of Israel: now therefore we may not touch them.",
                "This we will do to them, and let them live; lest wrath be on us, because of the oath which we swore to them.\"",
                "The princes said to them, \"Let them live, so they became wood cutters and drawers of water for all the congregation, as the princes had spoken to them.\"",
                "Joshua called for them, and he spoke to them, saying, \"Why have you deceived us, saying, \u2018We are very far from you,\u2019 when you live among us?",
                "Now therefore you are cursed, and some of you will never fail to be bondservants, both wood cutters and drawers of water for the house of my God.\"",
                "They answered Joshua, and said, \"Because your servants were certainly told how Yahweh your God commanded his servant Moses to give you all the land, and to destroy all the inhabitants of the land from before you. Therefore we were very afraid for our lives because of you, and have done this thing.",
                "Now, behold, we are in your hand. Do to us as it seems good and right to you to do.\"",
                "He did so to them, and delivered them out of the hand of the children of Israel, so that they didn\u2019t kill them.",
                "That day Joshua made them wood cutters and drawers of water for the congregation and for the altar of Yahweh, to this day, in the place which he should choose."
            ],
            10: [
                "Now it happened when Adoni-Zedek king of Jerusalem heard how Joshua had taken Ai, and had utterly destroyed it; as he had done to Jericho and her king, so he had done to Ai and her king; and how the inhabitants of Gibeon had made peace with Israel, and were among them;",
                "that they were very afraid, because Gibeon was a great city, as one of the royal cities, and because it was greater than Ai, and all its men were mighty.",
                "Therefore Adoni-Zedek king of Jerusalem sent to Hoham king of Hebron, to Piram king of Jarmuth, to Japhia king of Lachish, and to Debir king of Eglon, saying,",
                "\"Come up to me, and help me, and let us strike Gibeon; for it has made peace with Joshua and with the children of Israel.\"",
                "Therefore the five kings of the Amorites, the king of Jerusalem, the king of Hebron, the king of Jarmuth, the king of Lachish, the king of Eglon, gathered themselves together, and went up, they and all their armies, and encamped against Gibeon, and made war against it.",
                "The men of Gibeon sent to Joshua to the camp to Gilgal, saying, \"Don\u2019t abandon your servants! Come up to us quickly, and save us, and help us; for all the kings of the Amorites that dwell in the hill country have gathered together against us.\"",
                "So Joshua went up from Gilgal, he, and all the people of war with him, and all the mighty men of valor.",
                "Yahweh said to Joshua, \"Don\u2019t fear them, for I have delivered them into your hands. Not a man of them will stand before you.\"",
                "Joshua therefore came on them suddenly. He went up from Gilgal all night.",
                "Yahweh confused them before Israel, and he killed them with a great slaughter at Gibeon, and chased them by the way of the ascent of Beth Horon, and struck them to Azekah and to Makkedah.",
                "It happened, as they fled from before Israel, while they were at the descent of Beth Horon, that Yahweh cast down great stones from the sky on them to Azekah, and they died. There were more who died from the hailstones than who the children of Israel killed with the sword.",
                "Then Joshua spoke to Yahweh in the day when Yahweh delivered up the Amorites before the children of Israel; and he said in the sight of Israel, \"Sun, stand still on Gibeon! You, moon, stop in the valley of Aijalon!\"",
                "The sun stood still, and the moon stayed, until the nation had avenged themselves of their enemies. Isn\u2019t this written in the book of Jashar? The sun stayed in the midst of the sky, and didn\u2019t hurry to go down about a whole day.",
                "There was no day like that before it or after it, that Yahweh listened to the voice of a man; for Yahweh fought for Israel.",
                "Joshua returned, and all Israel with him, to the camp to Gilgal.",
                "These five kings fled, and hid themselves in the cave at Makkedah.",
                "Joshua was told, saying, \"The five kings are found, hidden in the cave at Makkedah.\"",
                "Joshua said, \"Roll large stones to the mouth of the cave, and set men by it to guard them;",
                "but don\u2019t stay. Pursue your enemies, and them from the rear. Don\u2019t allow them to enter into their cities; for Yahweh your God has delivered them into your hand.\"",
                "It happened, when Joshua and the children of Israel had finished killing them with a very great slaughter until they were consumed, and the remnant which remained of them had entered into the fortified cities,",
                "that all the people returned to the camp to Joshua at Makkedah in peace. None moved his tongue against any of the children of Israel.",
                "Then Joshua said, \"Open the mouth of the cave, and bring those five kings out of the cave to me.\"",
                "They did so, and brought those five kings out of the cave to him: the king of Jerusalem, the king of Hebron, the king of Jarmuth, the king of Lachish, and the king of Eglon.",
                "It happened, when they brought those kings out to Joshua, that Joshua called for all the men of Israel, and said to the chiefs of the men of war who went with him, \"Come near, put your feet on the necks of these kings.\" They came near, and put their feet on their necks.",
                "Joshua said to them, \"Don\u2019t be afraid, nor be dismayed. Be strong and courageous, for Yahweh will do this to all your enemies against whom you fight.\"",
                "Afterward Joshua struck them, put them to death, and hanged them on five trees. They were hanging on the trees until the evening.",
                "It happened at the time of the going down of the sun, that Joshua commanded, and they took them down off the trees, and cast them into the cave in which they had hidden themselves, and laid great stones on the mouth of the cave, which remain to this very day.",
                "Joshua took Makkedah on that day, and struck it with the edge of the sword, with its king. He utterly destroyed them and all the souls who were in it. He left none remaining. He did to the king of Makkedah as he had done to the king of Jericho.",
                "Joshua passed from Makkedah, and all Israel with him, to Libnah, and fought against Libnah.",
                "Yahweh delivered it also, with its king, into the hand of Israel. He struck it with the edge of the sword, and all the souls who were in it. He left none remaining in it. He did to its king as he had done to the king of Jericho.",
                "Joshua passed from Libnah, and all Israel with him, to Lachish, and encamped against it, and fought against it.",
                "Yahweh delivered Lachish into the hand of Israel. He took it on the second day, and struck it with the edge of the sword, with all the souls who were in it, according to all that he had done to Libnah.",
                "Then Horam king of Gezer came up to help Lachish; and Joshua struck him and his people, until he had left him none remaining.",
                "Joshua passed from Lachish, and all Israel with him, to Eglon; and they encamped against it fought against it.",
                "They took it on that day, and struck it with the edge of the sword. He utterly destroyed all the souls who were in it that day, according to all that he had done to Lachish.",
                "Joshua went up from Eglon, and all Israel with him, to Hebron; and they fought against it.",
                "They took it, and struck it with the edge of the sword, with its king and all its cities, and all the souls who were in it. He left none remaining, according to all that he had done to Eglon; but he utterly destroyed it, and all the souls who were in it.",
                "Joshua returned, and all Israel with him, to Debir, and fought against it.",
                "He took it, with its king and all its cities. They struck them with the edge of the sword, and utterly destroyed all the souls who were in it. He left none remaining. As he had done to Hebron, so he did to Debir, and to its king; as he had done also to Libnah, and to its king.",
                "So Joshua struck all the land, the hill country, and the South, and the lowland, and the slopes, and all their kings. He left none remaining, but he utterly destroyed all that breathed, as Yahweh, the God of Israel, commanded.",
                "Joshua struck them from Kadesh Barnea even to Gaza, and all the country of Goshen, even to Gibeon.",
                "Joshua took all these kings and their land at one time, because Yahweh, the God of Israel, fought for Israel.",
                "Joshua returned, and all Israel with him, to the camp to Gilgal."
            ],
            11: [
                "It happened, when Jabin king of Hazor heard of it, that he sent to Jobab king of Madon, to the king of Shimron, to the king of Achshaph,",
                "and to the kings who were on the north, in the hill country, in the Arabah south of Chinneroth, in the lowland, and in the heights of Dor on the west,",
                "to the Canaanite on the east and on the west, and the Amorite, and the Hittite, and the Perizzite, and the Jebusite in the hill country, and the Hivite under Hermon in the land of Mizpah.",
                "They went out, they and all their armies with them, many people, even as the sand that is on the seashore in multitude, with very many horses and chariots.",
                "All these kings met together; and they came and encamped together at the waters of Merom, to fight with Israel.",
                "Yahweh said to Joshua, \"Don\u2019t be afraid because of them; for tomorrow at this time, I will deliver them up all slain before Israel. You shall hamstring their horses and burn their chariots with fire.\"",
                "So Joshua came, and all the people of war with him, against them by the waters of Merom suddenly, and fell on them.",
                "Yahweh delivered them into the hand of Israel, and they struck them, and chased them to great Sidon, and to Misrephoth Maim, and to the valley of Mizpeh eastward. They struck them until they left them none remaining.",
                "Joshua did to them as Yahweh told him. He hamstrung their horses and burnt their chariots with fire.",
                "Joshua turned back at that time, and took Hazor, and struck its king with the sword: for Hazor used to be the head of all those kingdoms.",
                "They struck all the souls who were in it with the edge of the sword, utterly destroying them. There was no one left who breathed. He burnt Hazor with fire.",
                "Joshua captured all the cities of those kings, with their kings, and he struck them with the edge of the sword, and utterly destroyed them; as Moses the servant of Yahweh commanded.",
                "But as for the cities that stood on their mounds, Israel burned none of them, except Hazor only. Joshua burned that.",
                "The children of Israel took all the spoil of these cities, with the livestock, as spoils for themselves; but every man they struck with the edge of the sword, until they had destroyed them. They didn\u2019t leave any who breathed.",
                "As Yahweh commanded Moses his servant, so Moses commanded Joshua. Joshua did so. He left nothing undone of all that Yahweh commanded Moses.",
                "So Joshua captured all that land, the hill country, all the South, all the land of Goshen, the lowland, the Arabah, the hill country of Israel, and the lowland of the same;",
                "from Mount Halak, that goes up to Seir, even to Baal Gad in the valley of Lebanon under Mount Hermon. He took all their kings, struck them, and put them to death.",
                "Joshua made war a long time with all those kings.",
                "There was not a city that made peace with the children of Israel, except the Hivites, the inhabitants of Gibeon. They took all in battle.",
                "For it was of Yahweh to harden their hearts, to come against Israel in battle, that he might utterly destroy them, that they might have no favor, but that he might destroy them, as Yahweh commanded Moses.",
                "Joshua came at that time, and cut off the Anakim from the hill country, from Hebron, from Debir, from Anab, and from all the hill country of Judah, and from all the hill country of Israel: Joshua utterly destroyed them with their cities.",
                "There were none of the Anakim left in the land of the children of Israel. Only in Gaza, in Gath, and in Ashdod, did some remain.",
                "So Joshua took the whole land, according to all that Yahweh spoke to Moses; and Joshua gave it for an inheritance to Israel according to their divisions by their tribes. The land had rest from war."
            ],
            12: [
                "Now these are the kings of the land, whom the children of Israel struck, and possessed their land beyond the Jordan toward the sunrise, from the valley of the Arnon to Mount Hermon, and all the Arabah eastward:",
                "Sihon king of the Amorites, who lived in Heshbon, and ruled from Aroer, which is on the edge of the valley of the Arnon, and the middle of the valley, and half Gilead, even to the river Jabbok, the border of the children of Ammon;",
                "and the Arabah to the sea of Chinneroth, eastward, and to the sea of the Arabah, even the Salt Sea, eastward, the way to Beth Jeshimoth; and on the south, under the slopes of Pisgah:",
                "and the border of Og king of Bashan, of the remnant of the Rephaim, who lived at Ashtaroth and at Edrei,",
                "and ruled in Mount Hermon, and in Salecah, and in all Bashan, to the border of the Geshurites and the Maacathites, and half Gilead, the border of Sihon king of Heshbon.",
                "Moses the servant of Yahweh and the children of Israel struck them. Moses the servant of Yahweh gave it for a possession to the Reubenites, and the Gadites, and the half-tribe of Manasseh.",
                "These are the kings of the land whom Joshua and the children of Israel struck beyond the Jordan westward, from Baal Gad in the valley of Lebanon even to Mount Halak, that goes up to Seir. Joshua gave it to the tribes of Israel for a possession according to their divisions;",
                "in the hill country, and in the lowland, and in the Arabah, and in the slopes, and in the wilderness, and in the South; the Hittite, the Amorite, and the Canaanite, the Perizzite, the Hivite, and the Jebusite:",
                "the king of Jericho, one; the king of Ai, which is beside Bethel, one;",
                "the king of Jerusalem, one; the king of Hebron, one;",
                "the king of Jarmuth, one; the king of Lachish, one;",
                "the king of Eglon, one; the king of Gezer, one;",
                "the king of Debir, one; the king of Geder, one;",
                "the king of Hormah, one; the king of Arad, one;",
                "the king of Libnah, one; the king of Adullam, one;",
                "the king of Makkedah, one; the king of Bethel, one;",
                "the king of Tappuah, one; the king of Hepher, one;",
                "the king of Aphek, one; the king of Lassharon, one;",
                "the king of Madon, one; the king of Hazor, one;",
                "the king of Shimron Meron, one; the king of Achshaph, one;",
                "the king of Taanach, one; the king of Megiddo, one;",
                "the king of Kedesh, one; the king of Jokneam in Carmel, one;",
                "the king of Dor in the height of Dor, one; the king of Goiim in Gilgal, one;",
                "the king of Tirzah, one: all the kings thirty-one."
            ],
            13: [
                "Now Joshua was old and well advanced in years. Yahweh said to him, \"You are old and advanced in years, and there remains yet very much land to be possessed.",
                "\"This is the land that still remains: all the regions of the Philistines, and all the Geshurites;",
                "from the Shihor, which is before Egypt, even to the border of Ekron northward, which is counted as Canaanite; the five lords of the Philistines; the Gazites, and the Ashdodites, the Ashkelonites, the Gittites, and the Ekronites; also the Avvim,",
                "on the south; all the land of the Canaanites, and Mearah that belongs to the Sidonians, to Aphek, to the border of the Amorites;",
                "and the land of the Gebalites, and all Lebanon, toward the sunrise, from Baal Gad under Mount Hermon to the entrance of Hamath;",
                "all the inhabitants of the hill country from Lebanon to Misrephoth Maim, even all the Sidonians; them will I drive out from before the children of Israel: only allocate it to Israel for an inheritance, as I have commanded you.",
                "Now therefore divide this land for an inheritance to the nine tribes and the half-tribe of Manasseh.\"",
                "With him the Reubenites and the Gadites received their inheritance, which Moses gave them, beyond the Jordan eastward, even as Moses the servant of Yahweh gave them:",
                "from Aroer, that is on the edge of the valley of the Arnon, and the city that is in the middle of the valley, and all the plain of Medeba to Dibon;",
                "and all the cities of Sihon king of the Amorites, who reigned in Heshbon, to the border of the children of Ammon;",
                "and Gilead, and the border of the Geshurites and Maacathites, and all Mount Hermon, and all Bashan to Salecah;",
                "all the kingdom of Og in Bashan, who reigned in Ashtaroth and in Edrei (the same was left of the remnant of the Rephaim); for Moses attacked these, and drove them out.",
                "Nevertheless the children of Israel didn\u2019t drive out the Geshurites, nor the Maacathites: but Geshur and Maacath dwell in the midst of Israel to this day.",
                "Only he gave no inheritance to the tribe of Levi. The offerings of Yahweh, the God of Israel, made by fire are his inheritance, as he spoke to him.",
                "Moses gave to the tribe of the children of Reuben according to their families.",
                "Their border was from Aroer, that is on the edge of the valley of the Arnon, and the city that is in the middle of the valley, and all the plain by Medeba;",
                "Heshbon, and all its cities that are in the plain; Dibon, Bamoth Baal, Beth Baal Meon,",
                "Jahaz, Kedemoth, Mephaath,",
                "Kiriathaim, Sibmah, Zereth Shahar in the mount of the valley,",
                "Beth Peor, the slopes of Pisgah, Beth Jeshimoth,",
                "all the cities of the plain, and all the kingdom of Sihon king of the Amorites, who reigned in Heshbon, whom Moses struck with the chiefs of Midian, Evi, Rekem, Zur, Hur, and Reba, the princes of Sihon, who lived in the land.",
                "The children of Israel alse killed Balaam also the son of Beor, the soothsayer, with the sword, among the rest of their slain.",
                "The border of the children of Reuben was the bank of the Jordan. This was the inheritance of the children of Reuben according to their families, the cities and its villages.",
                "Moses gave to the tribe of Gad, to the children of Gad, according to their families.",
                "Their border was Jazer, and all the cities of Gilead, and half the land of the children of Ammon, to Aroer that is before Rabbah;",
                "and from Heshbon to Ramath Mizpeh, and Betonim; and from Mahanaim to the border of Debir;",
                "and in the valley, Beth Haram, Beth Nimrah, Succoth, and Zaphon, the rest of the kingdom of Sihon king of Heshbon, the Jordan\u2019s bank, to the uttermost part of the sea of Chinnereth beyond the Jordan eastward.",
                "This is the inheritance of the children of Gad according to their families, the cities and its villages.",
                "Moses gave an inheritance to the half-tribe of Manasseh. It was for the half-tribe of the children of Manasseh according to their families.",
                "Their border was from Mahanaim, all Bashan, all the kingdom of Og king of Bashan, and all the towns of Jair, which are in Bashan, sixty cities.",
                "Half Gilead, Ashtaroth, and Edrei, the cities of the kingdom of Og in Bashan, were for the children of Machir the son of Manasseh, even for the half of the children of Machir according to their families.",
                "These are the inheritances which Moses distributed in the plains of Moab, beyond the Jordan at Jericho, eastward.",
                "But to the tribe of Levi Moses gave no inheritance. Yahweh, the God of Israel, is their inheritance, as he spoke to them."
            ],
            14: [
                "These are the inheritances which the children of Israel took in the land of Canaan, which Eleazar the priest, Joshua the son of Nun, and the heads of the fathers\u2019 houses of the tribes of the children of Israel, distributed to them,",
                "by the lot of their inheritance, as Yahweh commanded by Moses, for the nine tribes, and for the half-tribe.",
                "For Moses had given the inheritance of the two tribes and the half-tribe beyond the Jordan; but to the Levites he gave no inheritance among them.",
                "For the children of Joseph were two tribes, Manasseh and Ephraim: and they gave no portion to the Levites in the land, except cities to dwell in, with their suburbs for their livestock and for their property.",
                "The children of Israel did as Yahweh commanded Moses, and they divided the land.",
                "Then the children of Judah drew near to Joshua in Gilgal. Caleb the son of Jephunneh the Kenizzite said to him, \"You know the thing that Yahweh spoke to Moses the man of God concerning me and concerning you in Kadesh Barnea.",
                "I was forty years old when Moses the servant of Yahweh sent me from Kadesh Barnea to spy out the land. I brought him word again as it was in my heart.",
                "Nevertheless, my brothers who went up with me made the heart of the people melt; but I wholly followed Yahweh my God.",
                "Moses swore on that day, saying, \u2018Surely the land where you walked shall be an inheritance to you and to your children forever, because you have wholly followed Yahweh my God.\u2019",
                "\"Now, behold, Yahweh has kept me alive, as he spoke, these forty-five years, from the time that Yahweh spoke this word to Moses, while Israel walked in the wilderness. Now, behold, I am eighty-five years old, today.",
                "As yet I am as strong today as I was in the day that Moses sent me: as my strength was then, even so is my strength now for war, to go out and to come in.",
                "Now therefore give me this hill country, of which Yahweh spoke in that day; for you heard in that day how the Anakim were there, and great and fortified cities. It may be that Yahweh will be with me, and I shall drive them out, as Yahweh spoke.\"",
                "Joshua blessed him; and he gave Hebron to Caleb the son of Jephunneh for an inheritance.",
                "Therefore Hebron became the inheritance of Caleb the son of Jephunneh the Kenizzite to this day; because he wholly followed Yahweh, the God of Israel.",
                "Now the name of Hebron before was Kiriath Arba, after the greatest man among the Anakim. The land had rest from war."
            ],
            15: [
                "The lot for the tribe of the children of Judah according to their families was to the border of Edom, even to the wilderness of Zin southward, at the uttermost part of the south.",
                "Their south border was from the uttermost part of the Salt Sea, from the bay that looks southward;",
                "and it went out southward of the ascent of Akrabbim, and passed along to Zin, and went up by the south of Kadesh Barnea, and passed along by Hezron, went up to Addar, and turned about to Karka;",
                "and it passed along to Azmon, went out at the brook of Egypt; and the border ended at the sea. This shall be your south border.",
                "The east border was the Salt Sea, even to the end of the Jordan. The border of the north quarter was from the bay of the sea at the end of the Jordan.",
                "The border went up to Beth Hoglah, and passed along by the north of Beth Arabah; and the border went up to the stone of Bohan the son of Reuben.",
                "The border went up to Debir from the valley of Achor, and so northward, looking toward Gilgal, that is over against the ascent of Adummim, which is on the south side of the river. The border passed along to the waters of En Shemesh, and ended at En Rogel.",
                "The border went up by the valley of the son of Hinnom to the side of the Jebusite southward (the same is Jerusalem); and the border went up to the top of the mountain that lies before the valley of Hinnom westward, which is at the farthest part of the valley of Rephaim northward.",
                "The border extended from the top of the mountain to the spring of the waters of Nephtoah, and went out to the cities of Mount Ephron; and the border extended to Baalah (the same is Kiriath Jearim);",
                "and the border turned about from Baalah westward to Mount Seir, and passed along to the side of Mount Jearim on the north (the same is Chesalon), and went down to Beth Shemesh, and passed along by Timnah;",
                "and the border went out to the side of Ekron northward; and the border extended to Shikkeron, and passed along to Mount Baalah, and went out at Jabneel; and the goings out of the border were at the sea.",
                "The west border was to the shore of the great sea. This is the border of the children of Judah according to their families.",
                "To Caleb the son of Jephunneh he gave a portion among the children of Judah, according to the commandment of Yahweh to Joshua, even Kiriath Arba, named after the father of Anak (the same is Hebron).",
                "Caleb drove out the three sons of Anak: Sheshai, and Ahiman, and Talmai, the children of Anak.",
                "He went up against the inhabitants of Debir: now the name of Debir before was Kiriath Sepher.",
                "Caleb said, \"He who strikes Kiriath Sepher, and takes it, to him will I give Achsah my daughter as wife.\"",
                "Othniel the son of Kenaz, the brother of Caleb, took it: and he gave him Achsah his daughter as wife.",
                "It happened, when she came, that she had him ask her father fore a field. She got off of her donkey, and Caleb said, \"What do you want?\"",
                "She said, \"Give me a blessing. Because you have set me in the land of the South, give me also springs of water.\" He gave her the upper springs and the lower springs.",
                "This is the inheritance of the tribe of the children of Judah according to their families.",
                "The farthest cities of the tribe of the children of Judah toward the border of Edom in the South were Kabzeel, Eder, Jagur,",
                "Kinah, Dimonah, Adadah,",
                "Kedesh, Hazor, Ithnan,",
                "Ziph, Telem, Bealoth,",
                "Hazor Hadattah, Kerioth Hezron (the same is Hazor),",
                "Amam, Shema, Moladah,",
                "Hazar Gaddah, Heshmon, Beth Pelet,",
                "Hazar Shual, Beersheba, Biziothiah,",
                "Baalah, Iim, Ezem,",
                "Eltolad, Chesil, Hormah,",
                "Ziklag, Madmannah, Sansannah,",
                "Lebaoth, Shilhim, Ain, and Rimmon. All the cities are twenty-nine, with their villages.",
                "In the lowland, Eshtaol, Zorah, Ashnah,",
                "Zanoah, En Gannim, Tappuah, Enam,",
                "Jarmuth, Adullam, Socoh, Azekah,",
                "Shaaraim, Adithaim and Gederah (or Gederothaim); fourteen cities with their villages.",
                "Zenan, Hadashah, Migdal Gad,",
                "Dilean, Mizpeh, Joktheel,",
                "Lachish, Bozkath, Eglon,",
                "Cabbon, Lahmam, Chitlish,",
                "Gederoth, Beth Dagon, Naamah, and Makkedah; sixteen cities with their villages.",
                "Libnah, Ether, Ashan,",
                "Iphtah, Ashnah, Nezib,",
                "Keilah, Achzib, and Mareshah; nine cities with their villages.",
                "Ekron, with its towns and its villages;",
                "from Ekron even to the sea, all that were by the side of Ashdod, with their villages.",
                "Ashdod, its towns and its villages; Gaza, its towns and its villages; to the brook of Egypt, and the great sea with its coastline.",
                "In the hill country, Shamir, Jattir, Socoh,",
                "Dannah, Kiriath Sannah (which is Debir),",
                "Anab, Eshtemoh, Anim,",
                "Goshen, Holon, and Giloh; eleven cities with their villages.",
                "Arab, Dumah, Eshan,",
                "Janim, Beth Tappuah, Aphekah,",
                "Humtah, Kiriath Arba (the same is Hebron), and Zior; nine cities with their villages.",
                "Maon, Carmel, Ziph, Jutah,",
                "Jezreel, Jokdeam, Zanoah,",
                "Kain, Gibeah, and Timnah; ten cities with their villages.",
                "Halhul, Beth Zur, Gedor,",
                "Maarath, Beth Anoth, and Eltekon; six cities with their villages.",
                "Kiriath Baal (the same is Kiriath Jearim), and Rabbah; two cities with their villages.",
                "In the wilderness, Beth Arabah, Middin, Secacah,",
                "Nibshan, the City of Salt, and En Gedi; six cities with their villages.",
                "As for the Jebusites, the inhabitants of Jerusalem, the children of Judah couldn\u2019t drive them out; but the Jebusites live with the children of Judah at Jerusalem to this day."
            ],
            16: [
                "The lot came out for the children of Joseph from the Jordan at Jericho, at the waters of Jericho on the east, even the wilderness, going up from Jericho through the hill country to Bethel.",
                "It went out from Bethel to Luz, and passed along to the border of the Archites to Ataroth;",
                "and it went down westward to the border of the Japhletites, to the border of Beth Horon the lower, even to Gezer; and ended at the sea.",
                "The children of Joseph, Manasseh and Ephraim, took their inheritance.",
                "This was the border of the children of Ephraim according to their families. The border of their inheritance eastward was Ataroth Addar, to Beth Horon the upper.",
                "The border went out westward at Michmethath on the north. The border turned about eastward to Taanath Shiloh, and passed along it on the east of Janoah.",
                "It went down from Janoah to Ataroth, to Naarah, reached to Jericho, and went out at the Jordan.",
                "From Tappuah the border went along westward to the brook of Kanah; and ended at the sea. This is the inheritance of the tribe of the children of Ephraim according to their families;",
                "together with the cities which were set apart for the children of Ephraim in the midst of the inheritance of the children of Manasseh, all the cities with their villages.",
                "They didn\u2019t drive out the Canaanites who lived in Gezer; but the Canaanites dwell in the midst of Ephraim to this day, and have become servants to do forced labor."
            ],
            17: [
                "This was the lot for the tribe of Manasseh, for he was the firstborn of Joseph. As for Machir the firstborn of Manasseh, the father of Gilead, because he was a man of war, therefore he had Gilead and Bashan.",
                "So this was for the rest of the children of Manasseh according to their families: for the children of Abiezer, for the children of Helek, for the children of Asriel, for the children of Shechem, for the children of Hepher, and for the children of Shemida: these were the male children of Manasseh the son of Joseph according to their families.",
                "But Zelophehad, the son of Hepher, the son of Gilead, the son of Machir, the son of Manasseh, had no sons, but daughters: and these are the names of his daughters: Mahlah, Noah, Hoglah, Milcah, and Tirzah.",
                "They came near before Eleazar the priest, and before Joshua the son of Nun, and before the princes, saying, \"Yahweh commanded Moses to give us an inheritance among our brothers.\" Therefore according to the commandment of Yahweh he gave them an inheritance among the brothers of their father.",
                "Ten parts fell to Manasseh, besides the land of Gilead and Bashan, which is beyond the Jordan;",
                "because the daughters of Manasseh had an inheritance among his sons. The land of Gilead belonged to the rest of the sons of Manasseh.",
                "The border of Manasseh was from Asher to Michmethath, which is before Shechem. The border went along to the right hand, to the inhabitants of En Tappuah.",
                "The land of Tappuah belonged to Manasseh; but Tappuah on the border of Manasseh belonged to the children of Ephraim.",
                "The border went down to the brook of Kanah, southward of the brook. These cities belonged to Ephraim among the cities of Manasseh. The border of Manasseh was on the north side of the brook, and ended at the sea.",
                "Southward it was Ephraim\u2019s, and northward it was Manasseh\u2019s, and the sea was his border. They reached to Asher on the north, and to Issachar on the east.",
                "Manasseh had three heights in Issachar, in Asher Beth Shean and its towns, and Ibleam and its towns, and the inhabitants of Dor and its towns, and the inhabitants of Endor and its towns, and the inhabitants of Taanach and its towns, and the inhabitants of Megiddo and its towns.",
                "Yet the children of Manasseh couldn\u2019t drive out the inhabitants of those cities; but the Canaanites would dwell in that land.",
                "It happened, when the children of Israel had grown strong, that they put the Canaanites to forced labor, and didn\u2019t utterly drive them out.",
                "The children of Joseph spoke to Joshua, saying, \"Why have you given me just one lot and one part for an inheritance, since I am a great people, because Yahweh has blessed me so far?\"",
                "Joshua said to them, \"If you are a great people, go up to the forest, and clear land for yourself there in the land of the Perizzites and of the Rephaim; since the hill country of Ephraim is too narrow for you.\"",
                "The children of Joseph said, \"The hill country is not enough for us. All the Canaanites who dwell in the land of the valley have chariots of iron, both those who are in Beth Shean and its towns, and those who are in the valley of Jezreel.\"",
                "Joshua spoke to the house of Joseph, even to Ephraim and to Manasseh, saying, \"You are a great people, and have great power. You shall not have one lot only;",
                "but the hill country shall be yours. Although it is a forest, you shall cut it down, and it\u2019s farthest extent shall be yours; for you shall drive out the Canaanites, though they have chariots of iron, and though they are strong.\""
            ],
            18: [
                "The whole congregation of the children of Israel assembled themselves together at Shiloh, and set up the Tent of Meeting there. The land was subdued before them.",
                "Seven tribes remained among the children of Israel, which had not yet divided their inheritance.",
                "Joshua said to the children of Israel, \"How long will you neglect to go in to possess the land, which Yahweh, the God of your fathers, has given you?",
                "Appoint for yourselves three men from each tribe. I will send them, and they shall arise, walk through the land, and describe it according to their inheritance; and they shall come to me.",
                "They shall divide it into seven portions. Judah shall live in his borders on the south, and the house of Joseph shall live in their borders on the north.",
                "You shall survey the land into seven parts, and bring the description here to me; and I will cast lots for you here before Yahweh our God.",
                "For the Levites have no portion among you; for the priesthood of Yahweh is their inheritance. Gad, Reuben, and the half-tribe of Manasseh have received their inheritance beyond the Jordan eastward, which Moses the servant of Yahweh gave them.\"",
                "The men arose and went. Joshua commanded those who went to survey the land, saying, \"Go walk through the land, survey it, and come again to me. I will cast lots for you here before Yahweh in Shiloh.\"",
                "The men went and passed through the land, and surveyed it by cities into seven portions in a book. They came to Joshua to the camp at Shiloh.",
                "Joshua cast lots for them in Shiloh before Yahweh. There Joshua divided the land to the children of Israel according to their divisions.",
                "The lot of the tribe of the children of Benjamin came up according to their families. The border of their lot went out between the children of Judah and the children of Joseph.",
                "Their border on the north quarter was from the Jordan. The border went up to the side of Jericho on the north, and went up through the hill country westward. It ended at the wilderness of Beth Aven.",
                "The border passed along from there to Luz, to the side of Luz (the same is Bethel), southward. The border went down to Ataroth Addar, by the mountain that lies on the south of Beth Horon the lower.",
                "The border extended, and turned around on the west quarter southward, from the mountain that lies before Beth Horon southward; and ended at Kiriath Baal (the same is Kiriath Jearim), a city of the children of Judah. This was the west quarter.",
                "The south quarter was from the farthest part of Kiriath Jearim. The border went out westward, and went out to the spring of the waters of Nephtoah.",
                "The border went down to the farthest part of the mountain that lies before the valley of the son of Hinnom, which is in the valley of Rephaim northward. It went down to the valley of Hinnom, to the side of the Jebusite southward, and went down to En Rogel.",
                "It extended northward, went out at En Shemesh, and went out to Geliloth, which is over against the ascent of Adummim. It went down to the stone of Bohan the son of Reuben.",
                "It passed along to the side over against the Arabah northward, and went down to the Arabah.",
                "The border passed along to the side of Beth Hoglah northward; and the border ended at the north bay of the Salt Sea, at the south end of the Jordan. This was the south border.",
                "The Jordan was its border on the east quarter. This was the inheritance of the children of Benjamin, by the borders around it, according to their families.",
                "Now the cities of the tribe of the children of Benjamin according to their families were Jericho, Beth Hoglah, Emek Keziz,",
                "Beth Arabah, Zemaraim, Bethel,",
                "Avvim, Parah, Ophrah,",
                "Chephar Ammoni, Ophni, and Geba; twelve cities with their villages.",
                "Gibeon, Ramah, Beeroth,",
                "Mizpeh, Chephirah, Mozah,",
                "Rekem, Irpeel, Taralah,",
                "Zelah, Eleph, the Jebusite (the same is Jerusalem), Gibeath, and Kiriath; fourteen cities with their villages. This is the inheritance of the children of Benjamin according to their families."
            ],
            19: [
                "The second lot came out for Simeon, even for the tribe of the children of Simeon according to their families. Their inheritance was in the midst of the inheritance of the children of Judah.",
                "They had for their inheritance Beersheba (or Sheba), Moladah,",
                "Hazar Shual, Balah, Ezem,",
                "Eltolad, Bethul, Hormah,",
                "Ziklag, Beth Marcaboth, Hazar Susah,",
                "Beth Lebaoth, and Sharuhen; thirteen cities with their villages;",
                "Ain, Rimmon, Ether, and Ashan; four cities with their villages;",
                "and all the villages that were around these cities to Baalath Beer, Ramah of the South. This is the inheritance of the tribe of the children of Simeon according to their families.",
                "Out of the part of the children of Judah was the inheritance of the children of Simeon; for the portion of the children of Judah was too much for them. Therefore the children of Simeon had inheritance in the midst of their inheritance.",
                "The third lot came up for the children of Zebulun according to their families. The border of their inheritance was to Sarid.",
                "Their border went up westward, even to Maralah, and reached to Dabbesheth. It reached to the brook that is before Jokneam.",
                "It turned from Sarid eastward toward the sunrise to the border of Chisloth Tabor. It went out to Daberath, and went up to Japhia.",
                "From there it passed along eastward to Gath Hepher, to Ethkazin; and it went out at Rimmon which stretches to Neah.",
                "The border turned around it on the north to Hannathon; and it ended at the valley of Iphtah El;",
                "Kattath, Nahalal, Shimron, Idalah, and Bethlehem: twelve cities with their villages.",
                "This is the inheritance of the children of Zebulun according to their families, these cities with their villages.",
                "The fourth lot came out for Issachar, even for the children of Issachar according to their families.",
                "Their border was to Jezreel, Chesulloth, Shunem,",
                "Hapharaim, Shion, Anaharath,",
                "Rabbith, Kishion, Ebez,",
                "Remeth, Engannim, En Haddah, and Beth Pazzez.",
                "The border reached to Tabor, Shahazumah, and Beth Shemesh. Their border ended at the Jordan: sixteen cities with their villages.",
                "This is the inheritance of the tribe of the children of Issachar according to their families, the cities with their villages.",
                "The fifth lot came out for the tribe of the children of Asher according to their families.",
                "Their border was Helkath, Hali, Beten, Achshaph,",
                "Allammelech, Amad, Mishal. It reached to Carmel westward, and to Shihorlibnath.",
                "It turned toward the sunrise to Beth Dagon, and reached to Zebulun, and to the valley of Iphtah El northward to Beth Emek and Neiel. It went out to Cabul on the left hand,",
                "and Ebron, Rehob, Hammon, and Kanah, even to great Sidon.",
                "The border turned to Ramah, to the fortified city of Tyre; and the border turned to Hosah. It ended at the sea by the region of Achzib;",
                "Ummah also, and Aphek, and Rehob: twenty-two cities with their villages.",
                "This is the inheritance of the tribe of the children of Asher according to their families, these cities with their villages.",
                "The sixth lot came out for the children of Naphtali, even for the children of Naphtali according to their families.",
                "Their border was from Heleph, from the oak in Zaanannim, Adaminekeb, and Jabneel, to Lakkum. It ended at the Jordan.",
                "The border turned westward to Aznoth Tabor, and went out from there to Hukkok. It reached to Zebulun on the south, and reached to Asher on the west, and to Judah at the Jordan toward the sunrise.",
                "The fortified cities were Ziddim, Zer, Hammath, Rakkath, Chinnereth,",
                "Adamah, Ramah, Hazor,",
                "Kedesh, Edrei, En Hazor,",
                "Iron, Migdal El, Horem, Beth Anath, and Beth Shemesh; nineteen cities with their villages.",
                "This is the inheritance of the tribe of the children of Naphtali according to their families, the cities with their villages.",
                "The seventh lot came out for the tribe of the children of Dan according to their families.",
                "The border of their inheritance was Zorah, Eshtaol, Irshemesh,",
                "Shaalabbin, Aijalon, Ithlah,",
                "Elon, Timnah, Ekron,",
                "Eltekeh, Gibbethon, Baalath,",
                "Jehud, Bene Berak, Gath Rimmon,",
                "Me Jarkon, and Rakkon, with the border over against Joppa.",
                "The border of the children of Dan went out beyond them; for the children of Dan went up and fought against Leshem, and took it, and struck it with the edge of the sword, and possessed it, and lived therein, and called Leshem, Dan, after the name of Dan their father.",
                "This is the inheritance of the tribe of the children of Dan according to their families, these cities with their villages.",
                "So they made an end of distributing the land for inheritance by its borders. The children of Israel gave an inheritance to Joshua the son of Nun in their midst.",
                "According to the commandment of Yahweh, they gave him the city which he asked, even Timnathserah in the hill country of Ephraim; and he built the city, and lived there.",
                "These are the inheritances, which Eleazar the priest, Joshua the son of Nun, and the heads of the fathers\u2019 houses of the tribes of the children of Israel, distributed for inheritance by lot in Shiloh before Yahweh, at the door of the Tent of Meeting. So they made an end of dividing the land."
            ],
            20: [
                "Yahweh spoke to Joshua, saying,",
                "\"Speak to the children of Israel, saying, \u2018Assign the cities of refuge, of which I spoke to you by Moses,",
                "that the manslayer who kills any person accidentally or unintentionally may flee there. They shall be to you for a refuge from the avenger of blood.",
                "He shall flee to one of those cities, and shall stand at the entrance of the gate of the city, and declare his cause in the ears of the elders of that city. They shall take him into the city with them, and give him a place, that he may live among them.",
                "If the avenger of blood pursue after him, then they shall not deliver up the manslayer into his hand; because he struck his neighbor unintentionally, and didn\u2019t hate him before.",
                "He shall dwell in that city until he stands before the congregation for judgment, until the death of the high priest that shall be in those days. Then the manslayer shall return, and come to his own city, and to his own house, to the city he fled from.\u2019\"",
                "They set apart Kedesh in Galilee in the hill country of Naphtali, Shechem in the hill country of Ephraim, and Kiriath Arba (the same is Hebron) in the hill country of Judah.",
                "Beyond the Jordan at Jericho eastward, they assigned Bezer in the wilderness in the plain out of the tribe of Reuben, Ramoth in Gilead out of the tribe of Gad, and Golan in Bashan out of the tribe of Manasseh.",
                "These were the appointed cities for all the children of Israel, and for the alien who lives among them, that whoever kills any person unintentionally might flee there, and not die by the hand of the avenger of blood, until he stands before the congregation."
            ],
            21: [
                "Then the heads of fathers\u2019 houses of the Levites came near to Eleazar the priest, and to Joshua the son of Nun, and to the heads of fathers\u2019 houses of the tribes of the children of Israel.",
                "They spoke to them at Shiloh in the land of Canaan, saying, \"Yahweh commanded Moses to give us cities to dwell in, with their suburbs for our livestock.\"",
                "The children of Israel gave to the Levites out of their inheritance, according to the commandment of Yahweh, these cities with their suburbs.",
                "The lot came out for the families of the Kohathites. The children of Aaron the priest, who were of the Levites, had thirteen cities by lot out of the tribe of Judah, out of the tribe of the Simeonites, and out of the tribe of Benjamin.",
                "The rest of the children of Kohath had ten cities by lot out of the families of the tribe of Ephraim, out of the tribe of Dan, and out of the half-tribe of Manasseh.",
                "The children of Gershon had thirteen cities by lot out of the families of the tribe of Issachar, out of the tribe of Asher, out of the tribe of Naphtali, and out of the half-tribe of Manasseh in Bashan.",
                "The children of Merari according to their families had twelve cities out of the tribe of Reuben, out of the tribe of Gad, and out of the tribe of Zebulun.",
                "The children of Israel gave these cities with their suburbs by lot to the Levites, as Yahweh commanded by Moses.",
                "They gave out of the tribe of the children of Judah, and out of the tribe of the children of Simeon, these cities which are mentioned by name:",
                "and they were for the children of Aaron, of the families of the Kohathites, who were of the children of Levi; for theirs was the first lot.",
                "They gave them Kiriath Arba, named after the father of Anak (the same is Hebron), in the hill country of Judah, with its suburbs around it.",
                "But they gave the fields of the city and its villages to Caleb the son of Jephunneh for his possession.",
                "To the children of Aaron the priest they gave Hebron with its suburbs, the city of refuge for the manslayer, Libnah with its suburbs,",
                "Jattir with its suburbs, Eshtemoa with its suburbs,",
                "Holon with its suburbs, Debir with its suburbs,",
                "Ain with its suburbs, Juttah with its suburbs, and Beth Shemesh with its suburbs; nine cities out of those two tribes.",
                "Out of the tribe of Benjamin, Gibeon with its suburbs, Geba with its suburbs,",
                "Anathoth with its suburbs, and Almon with its suburbs; four cities.",
                "All the cities of the children of Aaron, the priests, were thirteen cities with their suburbs.",
                "The families of the children of Kohath, the Levites, even the rest of the children of Kohath, had the cities of their lot out of the tribe of Ephraim.",
                "They gave them Shechem with its suburbs in the hill country of Ephraim, the city of refuge for the manslayer, and Gezer with its suburbs,",
                "Kibzaim with its suburbs, and Beth Horon with its suburbs; four cities.",
                "Out of the tribe of Dan, Elteke with its suburbs, Gibbethon with its suburbs,",
                "Aijalon with its suburbs, Gath Rimmon with its suburbs; four cities.",
                "Out of the half-tribe of Manasseh, Taanach with its suburbs, and Gath Rimmon with its suburbs; two cities.",
                "All the cities of the families of the rest of the children of Kohath were ten with their suburbs.",
                "They gave to the children of Gershon, of the families of the Levites, out of the half-tribe of Manasseh Golan in Bashan with its suburbs, the city of refuge for the manslayer, and Be Eshterah with its suburbs; two cities.",
                "Out of the tribe of Issachar, Kishion with its suburbs, Daberath with its suburbs,",
                "Jarmuth with its suburbs, En Gannim with its suburbs; four cities.",
                "Out of the tribe of Asher, Mishal with its suburbs, Abdon with its suburbs,",
                "Helkath with its suburbs, and Rehob with its suburbs; four cities.",
                "Out of the tribe of Naphtali, Kedesh in Galilee with its suburbs, the city of refuge for the manslayer, Hammothdor with its suburbs, and Kartan with its suburbs; three cities.",
                "All the cities of the Gershonites according to their families were thirteen cities with their suburbs.",
                "To the families of the children of Merari, the rest of the Levites, out of the tribe of Zebulun, Jokneam with its suburbs, Kartah with its suburbs,",
                "Dimnah with its suburbs, and Nahalal with its suburbs; four cities.",
                "Out of the tribe of Reuben, Bezer with its suburbs, Jahaz with its suburbs,",
                "Kedemoth with its suburbs, and Mephaath with its suburbs; four cities.",
                "Out of the tribe of Gad, Ramoth in Gilead with its suburbs, the city of refuge for the manslayer, and Mahanaim with its suburbs,",
                "Heshbon with its suburbs, Jazer with its suburbs; four cities in all.",
                "All these were the cities of the children of Merari according to their families, even the rest of the families of the Levites. Their lot was twelve cities.",
                "All the cities of the Levites in the midst of the possession of the children of Israel were forty-eight cities with their suburbs.",
                "Each of these cities included their suburbs around them. It was this way with all these cities.",
                "So Yahweh gave to Israel all the land which he swore to give to their fathers. They possessed it, and lived in it.",
                "Yahweh gave them rest all around, according to all that he swore to their fathers. Not a man of all their enemies stood before them. Yahweh delivered all their enemies into their hand.",
                "Nothing failed of any good thing which Yahweh had spoken to the house of Israel. All came to pass."
            ],
            22: [
                "Then Joshua called the Reubenites, the Gadites, and the half-tribe of Manasseh,",
                "and said to them, \"You have kept all that Moses the servant of Yahweh commanded you, and have listened to my voice in all that I commanded you.",
                "You have not left your brothers these many days to this day, but have performed the duty of the commandment of Yahweh your God.",
                "Now Yahweh your God has given rest to your brothers, as he spoke to them. Therefore now return and go to your tents, to the land of your possession, which Moses the servant of Yahweh gave you beyond the Jordan.",
                "Only take diligent heed to do the commandment and the law which Moses the servant of Yahweh commanded you, to love Yahweh your God, to walk in all his ways, to keep his commandments, to hold fast to him, and to serve him with all your heart and with all your soul.\"",
                "So Joshua blessed them, and sent them away; and they went to their tents.",
                "Now to the one half-tribe of Manasseh Moses had given inheritance in Bashan; but to the other half gave Joshua among their brothers beyond the Jordan westward. Moreover when Joshua sent them away to their tents, he blessed them,",
                "and spoke to them, saying, \"Return with much wealth to your tents, with very much livestock, with silver, with gold, with brass, with iron, and with very much clothing. Divide the spoil of your enemies with your brothers.\"",
                "The children of Reuben and the children of Gad and the half-tribe of Manasseh returned, and departed from the children of Israel out of Shiloh, which is in the land of Canaan, to go to the land of Gilead, to the land of their possession, which they owned, according to the commandment of Yahweh by Moses.",
                "When they came to the region about the Jordan, that is in the land of Canaan, the children of Reuben and the children of Gad and the half-tribe of Manasseh built there an altar by the Jordan, a great altar to look at.",
                "The children of Israel heard this, \"Behold, the children of Reuben and the children of Gad and the half-tribe of Manasseh have built an altar in the forefront of the land of Canaan, in the region about the Jordan, on the side that pertains to the children of Israel.\"",
                "When the children of Israel heard of it, the whole congregation of the children of Israel gathered themselves together at Shiloh, to go up against them to war.",
                "The children of Israel sent to the children of Reuben, and to the children of Gad, and to the half-tribe of Manasseh, into the land of Gilead, Phinehas the son of Eleazar the priest,",
                "and with him ten princes, one prince of a fathers\u2019 house for each of the tribes of Israel; and they were everyone of them head of their fathers\u2019 houses among the thousands of Israel.",
                "They came to the children of Reuben, and to the children of Gad, and to the half-tribe of Manasseh, to the land of Gilead, and they spoke with them, saying,",
                "\"Thus says the whole congregation of Yahweh, \u2018What trespass is this that you have committed against the God of Israel, to turn away this day from following Yahweh, in that you have built yourselves an altar, to rebel this day against Yahweh?",
                "Is the iniquity of Peor too little for us, from which we have not cleansed ourselves to this day, although there came a plague on the congregation of Yahweh,",
                "that you must turn away this day from following Yahweh? It will be, since you rebel today against Yahweh, that tomorrow he will be angry with the whole congregation of Israel.",
                "However, if the land of your possession is unclean, then pass over to the land of the possession of Yahweh, in which Yahweh\u2019s tabernacle dwells, and take possession among us; but don\u2019t rebel against Yahweh, nor rebel against us, in building an altar other than the altar of Yahweh our God.",
                "Didn\u2019t Achan the son of Zerah commit a trespass in the devoted thing, and wrath fell on all the congregation of Israel? That man didn\u2019t perish alone in his iniquity.\u2019\"",
                "Then the children of Reuben and the children of Gad and the half-tribe of Manasseh answered, and spoke to the heads of the thousands of Israel,",
                "\"The Mighty One, God, Yahweh, the Mighty One, God, Yahweh, he knows; and Israel shall know: if it was in rebellion, or if in trespass against Yahweh (don\u2019t save us this day),",
                "that we have built us an altar to turn away from following Yahweh; or if to offer burnt offering or meal offering, or if to offer sacrifices of peace offerings, let Yahweh himself require it.",
                "\"If we have not out of concern done this, and for a reason, saying, \u2018In time to come your children might speak to our children, saying, \"What have you to do with Yahweh, the God of Israel?",
                "For Yahweh has made the Jordan a border between us and you, you children of Reuben and children of Gad. You have no portion in Yahweh.\"\u2019 So your children might make our children cease from fearing Yahweh.",
                "\"Therefore we said, \u2018Let\u2019s now prepare to build ourselves an altar, not for burnt offering, nor for sacrifice;",
                "but it will be a witness between us and you, and between our generations after us, that we may perform the service of Yahweh before him with our burnt offerings, with our sacrifices, and with our peace offerings;\u2019 that your children may not tell our children in time to come, \u2018You have no portion in Yahweh.\u2019",
                "\"Therefore we said, \u2018It shall be, when they tell us or our generations this in time to come, that we shall say, \"Behold the pattern of the altar of Yahweh, which our fathers made, not for burnt offering, nor for sacrifice; but it is a witness between us and you.\"\u2019",
                "\"Far be it from us that we should rebel against Yahweh, and turn away this day from following Yahweh, to build an altar for burnt offering, for meal offering, or for sacrifice, besides the altar of Yahweh our God that is before his tabernacle!\"",
                "When Phinehas the priest, and the princes of the congregation, even the heads of the thousands of Israel that were with him, heard the words that the children of Reuben and the children of Gad and the children of Manasseh spoke, it pleased them well.",
                "Phinehas the son of Eleazar the priest said to the children of Reuben, to the children of Gad, and to the children of Manasseh, \"Today we know that Yahweh is in the midst of us, because you have not committed this trespass against Yahweh. Now you have delivered the children of Israel out of the hand of Yahweh.\"",
                "Phinehas the son of Eleazar the priest, and the princes, returned from the children of Reuben, and from the children of Gad, out of the land of Gilead, to the land of Canaan, to the children of Israel, and brought them word again.",
                "The thing pleased the children of Israel; and the children of Israel blessed God, and spoke no more of going up against them to war, to destroy the land in which the children of Reuben and the children of Gad lived.",
                "The children of Reuben and the children of Gad named the altar \"A Witness Between Us that Yahweh is God.\""
            ],
            23: [
                "It happened after many days, when Yahweh had given rest to Israel from their enemies all around, and Joshua was old and well advanced in years,",
                "that Joshua called for all Israel, for their elders and for their heads, and for their judges and for their officers, and said to them, \"I am old and well advanced in years.",
                "You have seen all that Yahweh your God has done to all these nations because of you; for it is Yahweh your God who has fought for you.",
                "Behold, I have allotted to you these nations that remain, to be an inheritance for your tribes, from the Jordan, with all the nations that I have cut off, even to the great sea toward the going down of the sun.",
                "Yahweh your God will thrust them out from before you, and drive them from out of your sight. You shall possess their land, as Yahweh your God spoke to you.",
                "\"Therefore be very courageous to keep and to do all that is written in the book of the law of Moses, that you not turn aside from it to the right hand or to the left;",
                "that you not come among these nations, these that remain among you; neither make mention of the name of their gods, nor cause to swear by them, neither serve them, nor bow down yourselves to them;",
                "but hold fast to Yahweh your God, as you have done to this day.",
                "\"For Yahweh has driven great and strong nations out from before you. But as for you, no man has stood before you to this day.",
                "One man of you shall chase a thousand; for it is Yahweh your God who fights for you, as he spoke to you.",
                "Take good heed therefore to yourselves, that you love Yahweh your God.",
                "\"But if you do at all go back, and hold fast to the remnant of these nations, even these who remain among you, and make marriages with them, and go in to them, and they to you;",
                "know for a certainty that Yahweh your God will no longer drive these nations from out of your sight; but they shall be a snare and a trap to you, a scourge in your sides, and thorns in your eyes, until you perish from off this good land which Yahweh your God has given you.",
                "\"Behold, today I am going the way of all the earth. You know in all your hearts and in all your souls that not one thing has failed of all the good things which Yahweh your God spoke concerning you. All have happened to you. Not one thing has failed of it.",
                "It shall happen that as all the good things have come on you of which Yahweh your God spoke to you, so Yahweh will bring on you all the evil things, until he has destroyed you from off this good land which Yahweh your God has given you,",
                "when you disobey the covenant of Yahweh your God, which he commanded you, and go and serve other gods, and bow down yourselves to them. Then the anger of Yahweh will be kindled against you, and you will perish quickly from off the good land which he has given to you.\""
            ],
            24: [
                "Joshua gathered all the tribes of Israel to Shechem, and called for the elders of Israel, for their heads, for their judges, and for their officers; and they presented themselves before God.",
                "Joshua said to all the people, \"Thus says Yahweh, the God of Israel, \u2018Your fathers lived of old time beyond the River, even Terah, the father of Abraham, and the father of Nahor: and they served other gods.",
                "I took your father Abraham from beyond the River, and led him throughout all the land of Canaan, and multiplied his seed, and gave him Isaac.",
                "I gave to Isaac Jacob and Esau: and I gave to Esau Mount Seir, to possess it. Jacob and his children went down into Egypt.",
                "\"\u2018I sent Moses and Aaron, and I plagued Egypt, according to that which I did in its midst: and afterward I brought you out.",
                "I brought your fathers out of Egypt: and you came to the sea. The Egyptians pursued after your fathers with chariots and with horsemen to the Red Sea.",
                "When they cried out to Yahweh, he put darkness between you and the Egyptians, and brought the sea on them, and covered them; and your eyes saw what I did in Egypt: and you lived in the wilderness many days.",
                "\"\u2018I brought you into the land of the Amorites, that lived beyond the Jordan: and they fought with you; and I gave them into your hand. You possessed their land; and I destroyed them from before you.",
                "Then Balak the son of Zippor, king of Moab, arose and fought against Israel. He sent and called Balaam the son of Beor to curse you;",
                "but I would not listen to Balaam; therefore he blessed you still. So I delivered you out of his hand.",
                "\"\u2018You went over the Jordan, and came to Jericho. The men of Jericho fought against you, the Amorite, the Perizzite, the Canaanite, the Hittite, the Girgashite, the Hivite, and the Jebusite; and I delivered them into your hand.",
                "I sent the hornet before you, which drove them out from before you, even the two kings of the Amorites; not with your sword, nor with your bow.",
                "I gave you a land whereon you had not labored, and cities which you didn\u2019t build, and you live in them. You eat of vineyards and olive groves which you didn\u2019t plant.\u2019",
                "\"Now therefore fear Yahweh, and serve him in sincerity and in truth. Put away the gods which your fathers served beyond the River, in Egypt; and serve Yahweh.",
                "If it seems evil to you to serve Yahweh, choose this day whom you will serve; whether the gods which your fathers served that were beyond the River, or the gods of the Amorites, in whose land you dwell: but as for me and my house, we will serve Yahweh.\"",
                "The people answered, \"Far be it from us that we should forsake Yahweh, to serve other gods;",
                "for it is Yahweh our God who brought us and our fathers up out of the land of Egypt, from the house of bondage, and who did those great signs in our sight, and preserved us in all the way in which we went, and among all the peoples through the midst of whom we passed.",
                "Yahweh drove out from before us all the peoples, even the Amorites who lived in the land. Therefore we also will serve Yahweh; for he is our God.\"",
                "Joshua said to the people, \"You can\u2019t serve Yahweh; for he is a holy God. He is a jealous God. He will not forgive your disobedience nor your sins.",
                "If you forsake Yahweh, and serve foreign gods, then he will turn and do you evil, and consume you, after he has done you good.\"",
                "The people said to Joshua, \"No; but we will serve Yahweh.\"",
                "Joshua said to the people, \"You are witnesses against yourselves that you have chosen Yahweh yourselves, to serve him.\" They said, \"We are witnesses.\"",
                "\"Now therefore put away the foreign gods which are among you, and incline your heart to Yahweh, the God of Israel.\"",
                "The people said to Joshua, \"We will serve Yahweh our God, and we will listen to his voice.\"",
                "So Joshua made a covenant with the people that day, and made for them a statute and an ordinance in Shechem.",
                "Joshua wrote these words in the book of the law of God; and he took a great stone, and set it up there under the oak that was by the sanctuary of Yahweh.",
                "Joshua said to all the people, \"Behold, this stone shall be a witness against us; for it has heard all the words of Yahweh which he spoke to us. It shall be therefore a witness against you, lest you deny your God.\"",
                "So Joshua sent the people away, every man to his inheritance.",
                "It happened after these things, that Joshua the son of Nun, the servant of Yahweh, died, being one hundred and ten years old.",
                "They buried him in the border of his inheritance in Timnathserah, which is in the hill country of Ephraim, on the north of the mountain of Gaash.",
                "Israel served Yahweh all the days of Joshua, and all the days of the elders who outlived Joshua, and had known all the work of Yahweh, that he had worked for Israel.",
                "They buried the bones of Joseph, which the children of Israel brought up out of Egypt, in Shechem, in the parcel of ground which Jacob bought of the sons of Hamor the father of Shechem for a hundred pieces of money. They became the inheritance of the children of Joseph.",
                "Eleazar the son of Aaron died. They buried him in the hill of Phinehas his son, which was given him in the hill country of Ephraim."
            ]
        },
        7: {
            1: [
                "It happened after the death of Joshua, the children of Israel asked of Yahweh, saying, \"Who should go up for us first against the Canaanites, to fight against them?\"",
                "Yahweh said, \"Judah shall go up. Behold, I have delivered the land into his hand.\"",
                "Judah said to Simeon his brother, \"Come up with me into my lot, that we may fight against the Canaanites; and I likewise will go with you into your lot.\" So Simeon went with him.",
                "Judah went up; and Yahweh delivered the Canaanites and the Perizzites into their hand: and they struck of them in Bezek ten thousand men.",
                "They found Adoni-Bezek in Bezek; and they fought against him, and they struck the Canaanites and the Perizzites.",
                "But Adoni-Bezek fled; and they pursued after him, and caught him, and cut off his thumbs and his great toes.",
                "Adoni-Bezek said, \"Seventy kings, having their thumbs and their great toes cut off, scavenged under my table: as I have done, so God has requited me.\" They brought him to Jerusalem, and he died there.",
                "The children of Judah fought against Jerusalem, and took it, and struck it with the edge of the sword, and set the city on fire.",
                "Afterward the children of Judah went down to fight against the Canaanites who lived in the hill country, and in the South, and in the lowland.",
                "Judah went against the Canaanites who lived in Hebron (now the name of Hebron before was Kiriath Arba); and they struck Sheshai, and Ahiman, and Talmai.",
                "From there he went against the inhabitants of Debir. (Now the name of Debir before was Kiriath Sepher.)",
                "Caleb said, \"He who strikes Kiriath Sepher, and takes it, to him will I give Achsah my daughter as wife.\"",
                "Othniel the son of Kenaz, Caleb\u2019s younger brother, took it: and he gave him Achsah his daughter as wife.",
                "It happened, when she came, that she got him to ask her father for a field: and she alighted from off her donkey; and Caleb said to her, \"What would you like?\"",
                "She said to him, \"Give me a blessing; for that you have set me in the land of the South, give me also springs of water.\" Then Caleb gave her the upper springs and the lower springs.",
                "The children of the Kenite, Moses\u2019 brother-in-law, went up out of the city of palm trees with the children of Judah into the wilderness of Judah, which is in the south of Arad; and they went and lived with the people.",
                "Judah went with Simeon his brother, and they struck the Canaanites who inhabited Zephath, and utterly destroyed it. The name of the city was called Hormah.",
                "Also Judah took Gaza with its border, and Ashkelon with its border, and Ekron with its border.",
                "Yahweh was with Judah; and drove out the inhabitants of the hill country; for he could not drive out the inhabitants of the valley, because they had chariots of iron.",
                "They gave Hebron to Caleb, as Moses had spoken: and he drove out there the three sons of Anak.",
                "The children of Benjamin did not drive out the Jebusites who inhabited Jerusalem; but the Jebusites dwell with the children of Benjamin in Jerusalem to this day.",
                "The house of Joseph, they also went up against Bethel; and Yahweh was with them.",
                "The house of Joseph sent to spy out Bethel. (Now the name of the city before was Luz.)",
                "The watchers saw a man come forth out of the city, and they said to him, \"Please show us the entrance into the city, and we will deal kindly with you.\"",
                "He showed them the entrance into the city; and they struck the city with the edge of the sword; but they let the man go and all his family.",
                "The man went into the land of the Hittites, and built a city, and called its name Luz, which is its name to this day.",
                "Manasseh did not drive out the inhabitants of Beth Shean and its towns, nor Taanach and its towns, nor the inhabitants of Dor and its towns, nor the inhabitants of Ibleam and its towns, nor the inhabitants of Megiddo and its towns; but the Canaanites would dwell in that land.",
                "It happened, when Israel had grown strong, that they put the Canaanites to forced labor, and did not utterly drive them out.",
                "Ephraim didn\u2019t drive out the Canaanites who lived in Gezer; but the Canaanites lived in Gezer among them.",
                "Zebulun didn\u2019t drive out the inhabitants of Kitron, nor the inhabitants of Nahalol; but the Canaanites lived among them, and became subject to forced labor.",
                "Asher didn\u2019t drive out the inhabitants of Acco, nor the inhabitants of Sidon, nor of Ahlab, nor of Achzib, nor of Helbah, nor of Aphik, nor of Rehob;",
                "but the Asherites lived among the Canaanites, the inhabitants of the land; for they did not drive them out.",
                "Naphtali didn\u2019t drive out the inhabitants of Beth Shemesh, nor the inhabitants of Beth Anath; but he lived among the Canaanites, the inhabitants of the land: nevertheless the inhabitants of Beth Shemesh and of Beth Anath became subject to forced labor.",
                "The Amorites forced the children of Dan into the hill country; for they would not allow them to come down to the valley;",
                "but the Amorites would dwell in Mount Heres, in Aijalon, and in Shaalbim: yet the hand of the house of Joseph prevailed, so that they became subject to forced labor.",
                "The border of the Amorites was from the ascent of Akrabbim, from the rock, and upward."
            ],
            2: [
                "The angel of Yahweh came up from Gilgal to Bochim. He said, \"I made you to go up out of Egypt, and have brought you to the land which I swore to your fathers; and I said, \u2018I will never break my covenant with you:",
                "and you shall make no covenant with the inhabitants of this land; you shall break down their altars.\u2019 But you have not listened to my voice: why have you done this?",
                "Therefore I also said, I will not drive them out from before you; but they shall be in your sides, and their gods shall be a snare to you.\"",
                "It happened, when the angel of Yahweh spoke these words to all the children of Israel, that the people lifted up their voice, and wept.",
                "They called the name of that place Bochim: and they sacrificed there to Yahweh.",
                "Now when Joshua had sent the people away, the children of Israel went every man to his inheritance to possess the land.",
                "The people served Yahweh all the days of Joshua, and all the days of the elders who outlived Joshua, who had seen all the great work of Yahweh that he had worked for Israel.",
                "Joshua the son of Nun, the servant of Yahweh, died, being one hundred ten years old.",
                "They buried him in the border of his inheritance in Timnath Heres, in the hill country of Ephraim, on the north of the mountain of Gaash.",
                "Also all that generation were gathered to their fathers: and there arose another generation after them, who didn\u2019t know Yahweh, nor yet the work which he had worked for Israel.",
                "The children of Israel did that which was evil in the sight of Yahweh, and served the Baals;",
                "and they forsook Yahweh, the God of their fathers, who brought them out of the land of Egypt, and followed other gods, of the gods of the peoples who were around them, and bowed themselves down to them: and they provoked Yahweh to anger.",
                "They forsook Yahweh, and served Baal and the Ashtaroth.",
                "The anger of Yahweh was kindled against Israel, and he delivered them into the hands of spoilers who despoiled them; and he sold them into the hands of their enemies all around, so that they could not any longer stand before their enemies.",
                "Wherever they went out, the hand of Yahweh was against them for evil, as Yahweh had spoken, and as Yahweh had sworn to them: and they were very distressed.",
                "Yahweh raised up judges, who saved them out of the hand of those who despoiled them.",
                "Yet they didn\u2019t listen to their judges; for they played the prostitute after other gods, and bowed themselves down to them: they turned aside quickly out of the way in which their fathers walked, obeying the commandments of Yahweh. They didn\u2019t do so.",
                "When Yahweh raised them up judges, then Yahweh was with the judge, and saved them out of the hand of their enemies all the days of the judge: for it grieved Yahweh because of their groaning by reason of those who oppressed them and troubled them.",
                "But it happened, when the judge was dead, that they turned back, and dealt more corruptly than their fathers, in following other gods to serve them, and to bow down to them; they didn\u2019t cease from their doings, nor from their stubborn way.",
                "The anger of Yahweh was kindled against Israel; and he said, \"Because this nation have transgressed my covenant which I commanded their fathers, and have not listened to my voice;",
                "I also will not henceforth drive out any from before them of the nations that Joshua left when he died;",
                "that by them I may prove Israel, whether they will keep the way of Yahweh to walk therein, as their fathers kept it, or not.\"",
                "So Yahweh left those nations, without driving them out hastily; neither delivered he them into the hand of Joshua."
            ],
            3: [
                "Now these are the nations which Yahweh left, to prove Israel by them, even as many as had not known all the wars of Canaan;",
                "only that the generations of the children of Israel might know, to teach them war, at the least such as before knew nothing of it:",
                "the five lords of the Philistines, and all the Canaanites, and the Sidonians, and the Hivites who lived on Mount Lebanon, from Mount Baal Hermon to the entrance of Hamath.",
                "They were left to test Israel by them, to know whether they would listen to the commandments of Yahweh, which he commanded their fathers by Moses.",
                "The children of Israel lived among the Canaanites, the Hittites, and the Amorites, and the Perizzites, and the Hivites, and the Jebusites:",
                "and they took their daughters to be their wives, and gave their own daughters to their sons and served their gods.",
                "The children of Israel did that which was evil in the sight of Yahweh, and forgot Yahweh their God, and served the Baals and the Asheroth.",
                "Therefore the anger of Yahweh was kindled against Israel, and he sold them into the hand of Cushan Rishathaim king of Mesopotamia: and the children of Israel served Cushan Rishathaim eight years.",
                "When the children of Israel cried to Yahweh, Yahweh raised up a savior to the children of Israel, who saved them, even Othniel the son of Kenaz, Caleb\u2019s younger brother.",
                "The Spirit of Yahweh came on him, and he judged Israel; and he went out to war, and Yahweh delivered Cushan Rishathaim king of Mesopotamia into his hand: and his hand prevailed against Cushan Rishathaim.",
                "The land had rest forty years. Othniel the son of Kenaz died.",
                "The children of Israel again did that which was evil in the sight of Yahweh: and Yahweh strengthened Eglon the king of Moab against Israel, because they had done that which was evil in the sight of Yahweh.",
                "He gathered to him the children of Ammon and Amalek; and he went and struck Israel, and they possessed the city of palm trees.",
                "The children of Israel served Eglon the king of Moab eighteen years.",
                "But when the children of Israel cried to Yahweh, Yahweh raised them up a savior, Ehud the son of Gera, the Benjamite, a man left-handed. The children of Israel sent tribute by him to Eglon the king of Moab.",
                "Ehud made him a sword which had two edges, a cubit in length; and he wore it under his clothing on his right thigh.",
                "He offered the tribute to Eglon king of Moab: now Eglon was a very fat man.",
                "When he had made an end of offering the tribute, he sent away the people who bore the tribute.",
                "But he himself turned back from the quarries that were by Gilgal, and said, \"I have a secret errand to you, king.\" The king said, \"Keep silence!\" All who stood by him went out from him.",
                "Ehud came to him; and he was sitting by himself alone in the cool upper room. Ehud said, \"I have a message from God to you.\" He arose out of his seat.",
                "Ehud put forth his left hand, and took the sword from his right thigh, and thrust it into his body:",
                "and the handle also went in after the blade; and the fat closed on the blade, for he didn\u2019t draw the sword out of his body; and it came out behind.",
                "Then Ehud went forth into the porch, and shut the doors of the upper room on him, and locked them.",
                "Now when he was gone out, his servants came; and they saw, and behold, the doors of the upper room were locked; and they said, \"Surely he is covering his feet in the upper room.\"",
                "They waited until they were ashamed; and behold, he didn\u2019t open the doors of the upper room: therefore they took the key, and opened them, and behold, their lord was fallen down dead on the earth.",
                "Ehud escaped while they waited, and passed beyond the quarries, and escaped to Seirah.",
                "It happened, when he had come, that he blew a trumpet in the hill country of Ephraim; and the children of Israel went down with him from the hill country, and he before them.",
                "He said to them, \"Follow me; for Yahweh has delivered your enemies the Moabites into your hand.\" They followed him, and took the fords of the Jordan against the Moabites, and didn\u2019t allow any man to pass over.",
                "They struck of Moab at that time about ten thousand men, every lusty man, and every man of valor; and there escaped not a man.",
                "So Moab was subdued that day under the hand of Israel. The land had rest eighty years.",
                "After him was Shamgar the son of Anath, who struck of the Philistines six hundred men with an oxgoad: and he also saved Israel."
            ],
            4: [
                "The children of Israel again did that which was evil in the sight of Yahweh, when Ehud was dead.",
                "Yahweh sold them into the hand of Jabin king of Canaan, who reigned in Hazor; the captain of whose army was Sisera, who lived in Harosheth of the Gentiles.",
                "The children of Israel cried to Yahweh: for he had nine hundred chariots of iron; and twenty years he mightily oppressed the children of Israel.",
                "Now Deborah, a prophetess, the wife of Lappidoth, she judged Israel at that time.",
                "She lived under the palm tree of Deborah between Ramah and Bethel in the hill country of Ephraim: and the children of Israel came up to her for judgment.",
                "She sent and called Barak the son of Abinoam out of Kedesh Naphtali, and said to him, \"Hasn\u2019t Yahweh, the God of Israel, commanded, \u2018Go and draw to Mount Tabor, and take with you ten thousand men of the children of Naphtali and of the children of Zebulun?",
                "I will draw to you, to the river Kishon, Sisera, the captain of Jabin\u2019s army, with his chariots and his multitude; and I will deliver him into your hand.\u2019\"",
                "Barak said to her, \"If you will go with me, then I will go; but if you will not go with me, I will not go.\"",
                "She said, \"I will surely go with you: nevertheless, the journey that you take shall not be for your honor; for Yahweh will sell Sisera into the hand of a woman.\" Deborah arose, and went with Barak to Kedesh.",
                "Barak called Zebulun and Naphtali together to Kedesh; and there went up ten thousand men at his feet: and Deborah went up with him.",
                "Now Heber the Kenite had separated himself from the Kenites, even from the children of Hobab the brother-in-law of Moses, and had pitched his tent as far as the oak in Zaanannim, which is by Kedesh.",
                "They told Sisera that Barak the son of Abinoam was gone up to Mount Tabor.",
                "Sisera gathered together all his chariots, even nine hundred chariots of iron, and all the people who were with him, from Harosheth of the Gentiles, to the river Kishon.",
                "Deborah said to Barak, \"Go; for this is the day in which Yahweh has delivered Sisera into your hand. Hasn\u2019t Yahweh gone out before you?\" So Barak went down from Mount Tabor, and ten thousand men after him.",
                "Yahweh confused Sisera, and all his chariots, and all his army, with the edge of the sword before Barak; and Sisera alighted from his chariot, and fled away on his feet.",
                "But Barak pursued after the chariots, and after the army, to Harosheth of the Gentiles: and all the army of Sisera fell by the edge of the sword; there was not a man left.",
                "However Sisera fled away on his feet to the tent of Jael the wife of Heber the Kenite; for there was peace between Jabin the king of Hazor and the house of Heber the Kenite.",
                "Jael went out to meet Sisera, and said to him, \"Turn in, my lord, turn in to me; don\u2019t be afraid.\" He came in to her into the tent, and she covered him with a rug.",
                "He said to her, \"Please give me a little water to drink; for I am thirsty.\" She opened a bottle of milk, and gave him drink, and covered him.",
                "He said to her, \"Stand in the door of the tent, and it shall be, when any man comes and inquires of you, and says, \u2018Is there any man here?\u2019 that you shall say, \u2018No.\u2019\"",
                "Then Jael Heber\u2019s wife took a tent peg, and took a hammer in her hand, and went softly to him, and struck the pin into his temples, and it pierced through into the ground; for he was in a deep sleep; so he swooned and died.",
                "Behold, as Barak pursued Sisera, Jael came out to meet him, and said to him, \"Come, and I will show you the man whom you seek.\" He came to her; and behold, Sisera lay dead, and the tent peg was in his temples.",
                "So God subdued on that day Jabin the king of Canaan before the children of Israel.",
                "The hand of the children of Israel prevailed more and more against Jabin the king of Canaan, until they had destroyed Jabin king of Canaan."
            ],
            5: [
                "Then Deborah and Barak the son of Abinoam sang on that day, saying,",
                "\"Because the leaders took the lead in Israel, because the people offered themselves willingly, be blessed, Yahweh!",
                "\"Hear, you kings! Give ear, you princes! I, even I, will sing to Yahweh. I will sing praise to Yahweh, the God of Israel.",
                "\"Yahweh, when you went forth out of Seir, when you marched out of the field of Edom, the earth trembled, the sky also dropped. Yes, the clouds dropped water.",
                "The mountains quaked at the presence of Yahweh, even Sinai, at the presence of Yahweh, the God of Israel.",
                "\"In the days of Shamgar the son of Anath, in the days of Jael, the highways were unoccupied. The travelers walked through byways.",
                "The rulers ceased in Israel. They ceased until I, Deborah, arose; Until I arose a mother in Israel.",
                "They chose new gods. Then war was in the gates. Was there a shield or spear seen among forty thousand in Israel?",
                "My heart is toward the governors of Israel, who offered themselves willingly among the people. Bless Yahweh!",
                "\"Speak, you who ride on white donkeys, you who sit on rich carpets, and you who walk by the way.",
                "Far from the noise of archers, in the places of drawing water, there they will rehearse the righteous acts of Yahweh, the righteous acts of his rule in Israel. \"Then the people of Yahweh went down to the gates.",
                "\u2018Awake, awake, Deborah! Awake, awake, utter a song! Arise, Barak, and lead away your captives, you son of Abinoam.\u2019",
                "\"Then a remnant of the nobles and the people came down. Yahweh came down for me against the mighty.",
                "Those whose root is in Amalek came out of Ephraim, after you, Benjamin, among your peoples. Governors come down out of Machir. Those who handle the marshal\u2019s staff came out of Zebulun.",
                "The princes of Issachar were with Deborah. As was Issachar, so was Barak. They rushed into the valley at his feet. By the watercourses of Reuben, there were great resolves of heart.",
                "Why did you sit among the sheepfolds, To hear the whistling for the flocks? At the watercourses of Reuben There were great searchings of heart.",
                "Gilead lived beyond the Jordan. Why did Dan remain in ships? Asher sat still at the haven of the sea, and lived by his creeks.",
                "Zebulun was a people that jeopardized their lives to the deaths; Naphtali also, on the high places of the field.",
                "\"The kings came and fought, then the kings of Canaan fought at Taanach by the waters of Megiddo. They took no plunder of silver.",
                "From the sky the stars fought. From their courses, they fought against Sisera.",
                "The river Kishon swept them away, that ancient river, the river Kishon. My soul, march on with strength.",
                "Then the horse hoofs stamped because of the prancings, the prancings of their strong ones.",
                "\u2018Curse Meroz,\u2019 said the angel of Yahweh. \u2018Curse bitterly its inhabitants, because they didn\u2019t come to help Yahweh, to help Yahweh against the mighty.\u2019",
                "\"Jael shall be blessed above women, the wife of Heber the Kenite; blessed shall she be above women in the tent.",
                "He asked for water. She gave him milk. She brought him butter in a lordly dish.",
                "She put her hand to the tent peg, and her right hand to the workmen\u2019s hammer. With the hammer she struck Sisera. She struck through his head. Yes, she pierced and struck through his temples.",
                "At her feet he bowed, he fell, he lay. At her feet he bowed, he fell. Where he bowed, there he fell down dead.",
                "\"Through the window she looked out, and cried: Sisera\u2019s mother looked through the lattice. \u2018Why is his chariot so long in coming? Why do the wheels of his chariots wait?\u2019",
                "Her wise ladies answered her, Yes, she returned answer to herself,",
                "\u2018Have they not found, have they not divided the spoil? A lady, two ladies to every man; to Sisera a spoil of dyed garments, a spoil of dyed garments embroidered, of dyed garments embroidered on both sides, on the necks of the spoil?\u2019",
                "\"So let all your enemies perish, Yahweh, but let those who love him be as the sun when it rises forth in its strength.\" Then the land had rest forty years."
            ],
            6: [
                "The children of Israel did that which was evil in the sight of Yahweh: and Yahweh delivered them into the hand of Midian seven years.",
                "The hand of Midian prevailed against Israel; and because of Midian the children of Israel made them the dens which are in the mountains, and the caves, and the strongholds.",
                "So it was, when Israel had sown, that the Midianites came up, and the Amalekites, and the children of the east; they came up against them;",
                "and they encamped against them, and destroyed the increase of the earth, until you come to Gaza, and left no sustenance in Israel, neither sheep, nor ox, nor donkey.",
                "For they came up with their livestock and their tents; they came in as locusts for multitude; both they and their camels were without number: and they came into the land to destroy it.",
                "Israel was brought very low because of Midian; and the children of Israel cried to Yahweh.",
                "It happened, when the children of Israel cried to Yahweh because of Midian,",
                "that Yahweh sent a prophet to the children of Israel: and he said to them, \"Thus says Yahweh, the God of Israel, \u2018I brought you up from Egypt, and brought you forth out of the house of bondage;",
                "and I delivered you out of the hand of the Egyptians, and out of the hand of all who oppressed you, and drove them out from before you, and gave you their land;",
                "and I said to you, \"I am Yahweh your God; you shall not fear the gods of the Amorites, in whose land you dwell.\" But you have not listened to my voice.\u2019\"",
                "The angel of Yahweh came, and sat under the oak which was in Ophrah, that pertained to Joash the Abiezrite: and his son Gideon was beating out wheat in the winepress, to hide it from the Midianites.",
                "The angel of Yahweh appeared to him, and said to him, \"Yahweh is with you, you mighty man of valor!\"",
                "Gideon said to him, \"Oh, my lord, if Yahweh is with us, why then has all this happened to us? Where are all his wondrous works which our fathers told us of, saying, \u2018Didn\u2019t Yahweh bring us up from Egypt?\u2019 But now Yahweh has cast us off, and delivered us into the hand of Midian.\"",
                "Yahweh looked at him, and said, \"Go in this your might, and save Israel from the hand of Midian. Haven\u2019t I sent you?\"",
                "He said to him, \"O Lord, how shall I save Israel? Behold, my family is the poorest in Manasseh, and I am the least in my father\u2019s house.\"",
                "Yahweh said to him, \"Surely I will be with you, and you shall strike the Midianites as one man.\"",
                "He said to him, \"If now I have found favor in your sight, then show me a sign that it is you who talk with me.",
                "Please don\u2019t go away, until I come to you, and bring out my present, and lay it before you.\" He said, \"I will wait until you come back.\"",
                "Gideon went in, and prepared a young goat, and unleavened cakes of an ephah of meal. He put the meat in a basket and he put the broth in a pot, and brought it out to him under the oak, and presented it.",
                "The angel of God said to him, \"Take the meat and the unleavened cakes, and lay them on this rock, and pour out the broth.\" He did so.",
                "Then the angel of Yahweh stretched out the end of the staff that was in his hand, and touched the meat and the unleavened cakes; and fire went up out of the rock, and consumed the meat and the unleavened cakes; and the angel of Yahweh departed out of his sight.",
                "Gideon saw that he was the angel of Yahweh; and Gideon said, \"Alas, Lord Yahweh! Because I have seen the angel of Yahweh face to face!\"",
                "Yahweh said to him, \"Peace be to you! Don\u2019t be afraid. You shall not die.\"",
                "Then Gideon built an altar there to Yahweh, and called it \"Yahweh is Peace.\" To this day it is still in Ophrah of the Abiezrites.",
                "It happened the same night, that Yahweh said to him, \"Take your father\u2019s bull, even the second bull seven years old, and throw down the altar of Baal that your father has, and cut down the Asherah that is by it;",
                "and build an altar to Yahweh your God on the top of this stronghold, in an orderly way, and take the second bull, and offer a burnt offering with the wood of the Asherah which you shall cut down.\"",
                "Then Gideon took ten men of his servants, and did as Yahweh had spoken to him: and it happened, because he feared his father\u2019s household and the men of the city, so that he could not do it by day, that he did it by night.",
                "When the men of the city arose early in the morning, behold, the altar of Baal was broken down, and the Asherah was cut down that was by it, and the second bull was offered on the altar that was built.",
                "They said one to another, \"Who has done this thing?\" When they inquired and asked, they said, \"Gideon the son of Joash has done this thing.\"",
                "Then the men of the city said to Joash, \"Bring out your son, that he may die, because he has broken down the altar of Baal, and because he has cut down the Asherah that was by it.\"",
                "Joash said to all who stood against him, \"Will you contend for Baal? Or will you save him? He who will contend for him, let him be put to death by morning. If he is a god, let him contend for himself, because someone has broken down his altar.\"",
                "Therefore on that day he named him Jerub-Baal, saying, \"Let Baal contend against him, because he has broken down his altar.\"",
                "Then all the Midianites and the Amalekites and the children of the east assembled themselves together; and they passed over, and encamped in the valley of Jezreel.",
                "But the Spirit of Yahweh came on Gideon; and he blew a trumpet; and Abiezer was gathered together after him.",
                "He sent messengers throughout all Manasseh; and they also were gathered together after him: and he sent messengers to Asher, and to Zebulun, and to Naphtali; and they came up to meet them.",
                "Gideon said to God, \"If you will save Israel by my hand, as you have spoken,",
                "behold, I will put a fleece of wool on the threshing floor; if there is dew on the fleece only, and it is dry on all the ground, then shall I know that you will save Israel by my hand, as you have spoken.\"",
                "It was so; for he rose up early on the next day, and pressed the fleece together, and wrung the dew out of the fleece, a bowl full of water.",
                "Gideon said to God, \"Don\u2019t let your anger be kindled against me, and I will speak but this once. Please let me make a trial just this once with the fleece. Let it now be dry only on the fleece, and on all the ground let there be dew.\"",
                "God did so that night: for it was dry on the fleece only, and there was dew on all the ground."
            ],
            7: [
                "Then Jerubbaal, who is Gideon, and all the people who were with him, rose up early, and encamped beside the spring of Harod: and the camp of Midian was on the north side of them, by the hill of Moreh, in the valley.",
                "Yahweh said to Gideon, \"The people who are with you are too many for me to give the Midianites into their hand, lest Israel vaunt themselves against me, saying, \u2018My own hand has saved me.\u2019",
                "Now therefore proclaim in the ears of the people, saying, \u2018Whoever is fearful and trembling, let him return and depart from Mount Gilead.\u2019\" Twenty-two thousand of the people returned, and ten thousand remained.",
                "Yahweh said to Gideon, \"The people are still too many. Bring them down to the water, and I will test them for you there. It shall be, that of whom I tell you, \u2018This shall go with you,\u2019 the same shall go with you; and of whoever I tell you, \u2018This shall not go with you,\u2019 the same shall not go.\"",
                "So he brought down the people to the water; and Yahweh said to Gideon, \"Everyone who laps of the water with his tongue, like a dog laps, you shall set him by himself; likewise everyone who bows down on his knees to drink.\"",
                "The number of those who lapped, putting their hand to their mouth, was three hundred men; but all the rest of the people bowed down on their knees to drink water.",
                "Yahweh said to Gideon, \"By the three hundred men who lapped will I save you, and deliver the Midianites into your hand. Let all the other people go, each to his own place.\"",
                "So the people took food in their hand, and their trumpets; and he sent all the men of Israel every man to his tent, but retained the three hundred men: and the camp of Midian was beneath him in the valley.",
                "It happened the same night, that Yahweh said to him, \"Arise, go down into the camp; for I have delivered it into your hand.",
                "But if you are afraid to go down, go with Purah your servant down to the camp:",
                "and you shall hear what they say; and afterward your hands will be strengthened to go down into the camp.\" Then went he down with Purah his servant to the outermost part of the armed men who were in the camp.",
                "The Midianites and the Amalekites and all the children of the east lay along in the valley like locusts for multitude; and their camels were without number, as the sand which is on the seashore for multitude.",
                "When Gideon had come, behold, there was a man telling a dream to his fellow; and he said, \"Behold, I dreamed a dream; and behold, a cake of barley bread tumbled into the camp of Midian, and came to the tent, and struck it so that it fell, and turned it upside down, so that the tent lay flat.\"",
                "His fellow answered, \"This is nothing other than the sword of Gideon the son of Joash, a man of Israel. God has delivered Midian into his hand, with all the army.\"",
                "It was so, when Gideon heard the telling of the dream, and its interpretation, that he worshiped; and he returned into the camp of Israel, and said, \"Arise; for Yahweh has delivered the army of Midian into your hand!\"",
                "He divided the three hundred men into three companies, and he put into the hands of all of them trumpets, and empty pitchers, with torches within the pitchers.",
                "He said to them, \"Watch me, and do likewise. Behold, when I come to the outermost part of the camp, it shall be that, as I do, so you shall do.",
                "When I blow the trumpet, I and all who are with me, then blow the trumpets also on every side of all the camp, and shout, \u2018For Yahweh and for Gideon!\u2019\"",
                "So Gideon, and the hundred men who were with him, came to the outermost part of the camp in the beginning of the middle watch, when they had but newly set the watch: and they blew the trumpets, and broke in pieces the pitchers that were in their hands.",
                "The three companies blew the trumpets, and broke the pitchers, and held the torches in their left hands, and the trumpets in their right hands with which to blow; and they shouted, \"The sword of Yahweh and of Gideon!\"",
                "They each stood in his place around the camp; and all the army ran; and they shouted, and put them to flight.",
                "They blew the three hundred trumpets, and Yahweh set every man\u2019s sword against his fellow, and against all the army; and the army fled as far as Beth Shittah toward Zererah, as far as the border of Abel Meholah, by Tabbath.",
                "The men of Israel were gathered together out of Naphtali, and out of Asher, and out of all Manasseh, and pursued after Midian.",
                "Gideon sent messengers throughout all the hill country of Ephraim, saying, \"Come down against Midian, and take before them the waters, as far as Beth Barah, even the Jordan!\" So all the men of Ephraim were gathered together, and took the waters as far as Beth Barah, even the Jordan.",
                "They took the two princes of Midian, Oreb and Zeeb; and they killed Oreb at the rock of Oreb, and Zeeb they killed at the winepress of Zeeb, and pursued Midian: and they brought the heads of Oreb and Zeeb to Gideon beyond the Jordan."
            ],
            8: [
                "The men of Ephraim said to him, \"Why have you treated us this way, that you didn\u2019t call us, when you went to fight with Midian?\" They rebuked him sharply.",
                "He said to them, \"What have I now done in comparison with you? Isn\u2019t the gleaning of the grapes of Ephraim better than the vintage of Abiezer?",
                "God has delivered into your hand the princes of Midian, Oreb and Zeeb! What was I able to do in comparison with you?\" Then their anger was abated toward him, when he had said that.",
                "Gideon came to the Jordan, and passed over, he, and the three hundred men who were with him, faint, yet pursuing.",
                "He said to the men of Succoth, \"Please give loaves of bread to the people who follow me; for they are faint, and I am pursuing after Zebah and Zalmunna, the kings of Midian.\"",
                "The princes of Succoth said, \"Are the hands of Zebah and Zalmunna now in your hand, that we should give bread to your army?\"",
                "Gideon said, \"Therefore when Yahweh has delivered Zebah and Zalmunna into my hand, then I will tear your flesh with the thorns of the wilderness and with briers.\"",
                "He went up there to Penuel, and spoke to them in the same way; and the men of Penuel answered him as the men of Succoth had answered.",
                "He spoke also to the men of Penuel, saying, \"When I come again in peace, I will break down this tower.\"",
                "Now Zebah and Zalmunna were in Karkor, and their armies with them, about fifteen thousand men, all who were left of all the army of the children of the east; for there fell one hundred twenty thousand men who drew sword.",
                "Gideon went up by the way of those who lived in tents on the east of Nobah and Jogbehah, and struck the army; for the army was secure.",
                "Zebah and Zalmunna fled; and he pursued after them; and he took the two kings of Midian, Zebah and Zalmunna, and confused all the army.",
                "Gideon the son of Joash returned from the battle from the ascent of Heres.",
                "He caught a young man of the men of Succoth, and inquired of him: and he described for him the princes of Succoth, and its elders, seventy-seven men.",
                "He came to the men of Succoth, and said, \"See Zebah and Zalmunna, concerning whom you taunted me, saying, \u2018Are the hands of Zebah and Zalmunna now in your hand, that we should give bread to your men who are weary?\u2019\"",
                "He took the elders of the city, and thorns of the wilderness and briers, and with them he taught the men of Succoth.",
                "He broke down the tower of Penuel, and killed the men of the city.",
                "Then he said to Zebah and Zalmunna, \"What kind of men were they whom you killed at Tabor?\" They answered, \"They were like you. Each one resembled the children of a king.\"",
                "He said, \"They were my brothers, the sons of my mother. As Yahweh lives, if you had saved them alive, I would not kill you.\"",
                "He said to Jether his firstborn, \"Get up, and kill them!\" But the youth didn\u2019t draw his sword; for he was afraid, because he was yet a youth.",
                "Then Zebah and Zalmunna said, \"Rise and fall on us; for as the man is, so is his strength.\" Gideon arose, and killed Zebah and Zalmunna, and took the crescents that were on their camels\u2019 necks.",
                "Then the men of Israel said to Gideon, \"Rule over us, both you, and your son, and your son\u2019s son also; for you have saved us out of the hand of Midian.\"",
                "Gideon said to them, \"I will not rule over you, neither shall my son rule over you. Yahweh shall rule over you.\"",
                "Gideon said to them, \"I would make a request of you, that you would give me every man the earrings of his spoil.\" (For they had golden earrings, because they were Ishmaelites.)",
                "They answered, \"We will willingly give them.\" They spread a garment, and every man threw the earrings of his spoil into it.",
                "The weight of the golden earrings that he requested was one thousand and seven hundred shekels of gold, besides the crescents, and the pendants, and the purple clothing that was on the kings of Midian, and besides the chains that were about their camels\u2019 necks.",
                "Gideon made an ephod of it, and put it in his city, even in Ophrah: and all Israel played the prostitute after it there; and it became a snare to Gideon, and to his house.",
                "So Midian was subdued before the children of Israel, and they lifted up their heads no more. The land had rest forty years in the days of Gideon.",
                "Jerubbaal the son of Joash went and lived in his own house.",
                "Gideon had seventy sons conceived from his body; for he had many wives.",
                "His concubine who was in Shechem, she also bore him a son, and he named him Abimelech.",
                "Gideon the son of Joash died in a good old age, and was buried in the tomb of Joash his father, in Ophrah of the Abiezrites.",
                "It happened, as soon as Gideon was dead, that the children of Israel turned again, and played the prostitute after the Baals, and made Baal Berith their god.",
                "The children of Israel didn\u2019t remember Yahweh their God, who had delivered them out of the hand of all their enemies on every side;",
                "neither did they show kindness to the house of Jerubbaal, Gideon, according to all the goodness which he had shown to Israel."
            ],
            9: [
                "Abimelech the son of Jerubbaal went to Shechem to his mother\u2019s brothers, and spoke with them, and with all the family of the house of his mother\u2019s father, saying,",
                "\"Please speak in the ears of all the men of Shechem, \u2018Is it better for you that all the sons of Jerubbaal, who are seventy persons, rule over you, or that one rule over you?\u2019 Remember also that I am your bone and your flesh.\"",
                "His mother\u2019s brothers spoke of him in the ears of all the men of Shechem all these words: and their hearts inclined to follow Abimelech; for they said, \"He is our brother.\"",
                "They gave him seventy pieces of silver out of the house of Baal Berith, with which Abimelech hired vain and light fellows, who followed him.",
                "He went to his father\u2019s house at Ophrah, and killed his brothers the sons of Jerubbaal, being seventy persons, on one stone: but Jotham the youngest son of Jerubbaal was left; for he hid himself.",
                "All the men of Shechem assembled themselves together, and all the house of Millo, and went and made Abimelech king, by the oak of the pillar that was in Shechem.",
                "When they told it to Jotham, he went and stood on the top of Mount Gerizim, and lifted up his voice, and cried, and said to them, \"Listen to me, you men of Shechem, that God may listen to you.",
                "The trees went forth on a time to anoint a king over them; and they said to the olive tree, \u2018Reign over us.\u2019",
                "\"But the olive tree said to them, \u2018Should I leave my fatness, with which by me they honor God and man, and go to wave back and forth over the trees?\u2019",
                "\"The trees said to the fig tree, \u2018Come and reign over us.\u2019",
                "\"But the fig tree said to them, \u2018Should I leave my sweetness, and my good fruit, and go to wave back and forth over the trees?\u2019",
                "\"The trees said to the vine, \u2018Come and reign over us.\u2019",
                "\"The vine said to them, \u2018Should I leave my new wine, which cheers God and man, and go to wave back and forth over the trees?\u2019",
                "\"Then all the trees said to the bramble, \u2018Come and reign over us.\u2019",
                "\"The bramble said to the trees, \u2018If in truth you anoint me king over you, then come and take refuge in my shade; and if not, let fire come out of the bramble, and devour the cedars of Lebanon.\u2019",
                "\"Now therefore, if you have dealt truly and righteously, in that you have made Abimelech king, and if you have dealt well with Jerubbaal and his house, and have done to him according to the deserving of his hands",
                "(for my father fought for you, and risked his life, and delivered you out of the hand of Midian:",
                "and you have risen up against my father\u2019s house this day, and have slain his sons, seventy persons, on one stone, and have made Abimelech, the son of his female servant, king over the men of Shechem, because he is your brother);",
                "if you then have dealt truly and righteously with Jerubbaal and with his house this day, then rejoice in Abimelech, and let him also rejoice in you:",
                "but if not, let fire come out from Abimelech, and devour the men of Shechem, and the house of Millo; and let fire come out from the men of Shechem, and from the house of Millo, and devour Abimelech.\"",
                "Jotham ran away, and fled, and went to Beer, and lived there, for fear of Abimelech his brother.",
                "Abimelech was prince over Israel three years.",
                "God sent an evil spirit between Abimelech and the men of Shechem; and the men of Shechem dealt treacherously with Abimelech:",
                "that the violence done to the seventy sons of Jerubbaal might come, and that their blood might be laid on Abimelech their brother, who killed them, and on the men of Shechem, who strengthened his hands to kill his brothers.",
                "The men of Shechem set an ambush for him on the tops of the mountains, and they robbed all who came along that way by them: and it was told Abimelech.",
                "Gaal the son of Ebed came with his brothers, and went over to Shechem; and the men of Shechem put their trust in him.",
                "They went out into the field, and harvested their vineyards, and trod the grapes, and held festival, and went into the house of their god, and ate and drank, and cursed Abimelech.",
                "Gaal the son of Ebed said, \"Who is Abimelech, and who is Shechem, that we should serve him? Isn\u2019t he the son of Jerubbaal? and Zebul his officer? Serve the men of Hamor the father of Shechem: but why should we serve him?",
                "Would that this people were under my hand! Then I would remove Abimelech.\" He said to Abimelech, \"Increase your army, and come out!\"",
                "When Zebul the ruler of the city heard the words of Gaal the son of Ebed, his anger was kindled.",
                "He sent messengers to Abimelech craftily, saying, \"Behold, Gaal the son of Ebed and his brothers have come to Shechem; and behold, they incite the city against you.",
                "Now therefore, go up by night, you and the people who are with you, and lie in wait in the field:",
                "and it shall be, that in the morning, as soon as the sun is up, you shall rise early, and rush on the city; and behold, when he and the people who are with him come out against you, then may you do to them as you shall find occasion.\"",
                "Abimelech rose up, and all the people who were with him, by night, and they laid wait against Shechem in four companies.",
                "Gaal the son of Ebed went out, and stood in the entrance of the gate of the city: and Abimelech rose up, and the people who were with him, from the ambush.",
                "When Gaal saw the people, he said to Zebul, \"Behold, people are coming down from the tops of the mountains.\" Zebul said to him, \"You see the shadow of the mountains as if they were men.\"",
                "Gaal spoke again and said, \"Behold, people are coming down by the middle of the land, and one company comes by the way of the oak of Meonenim.\"",
                "Then Zebul said to him, \"Now where is your mouth, that you said, \u2018Who is Abimelech, that we should serve him?\u2019 Isn\u2019t this the people that you have despised? Please go out now and fight with them.\"",
                "Gaal went out before the men of Shechem, and fought with Abimelech.",
                "Abimelech chased him, and he fled before him, and many fell wounded, even to the entrance of the gate.",
                "Abimelech lived at Arumah: and Zebul drove out Gaal and his brothers, that they should not dwell in Shechem.",
                "It happened on the next day, that the people went out into the field; and they told Abimelech.",
                "He took the people, and divided them into three companies, and laid wait in the field; and he looked, and behold, the people came forth out of the city; He rose up against them, and struck them.",
                "Abimelech, and the companies that were with him, rushed forward, and stood in the entrance of the gate of the city: and the two companies rushed on all who were in the field, and struck them.",
                "Abimelech fought against the city all that day; and he took the city, and killed the people who were therein: and he beat down the city, and sowed it with salt.",
                "When all the men of the tower of Shechem heard of it, they entered into the stronghold of the house of Elberith.",
                "It was told Abimelech that all the men of the tower of Shechem were gathered together.",
                "Abimelech went up to Mount Zalmon, he and all the people who were with him; and Abimelech took an axe in his hand, and cut down a bough from the trees, and took it up, and laid it on his shoulder: and he said to the people who were with him, \"What you have seen me do, make haste, and do as I have done!\"",
                "All the people likewise each cut down his bough, and followed Abimelech, and put them at the base of the stronghold, and set the stronghold on fire on them; so that all the people of the tower of Shechem died also, about a thousand men and women.",
                "Then went Abimelech to Thebez, and encamped against Thebez, and took it.",
                "But there was a strong tower within the city, and there fled all the men and women, and all they of the city, and shut themselves in, and went up to the roof of the tower.",
                "Abimelech came to the tower, and fought against it, and drew near to the door of the tower to burn it with fire.",
                "A certain woman cast an upper millstone on Abimelech\u2019s head, and broke his skull.",
                "Then he called hastily to the young man his armor bearer, and said to him, \"Draw your sword, and kill me, that men not say of me, \u2018A woman killed him.\u2019 His young man thrust him through, and he died.\"",
                "When the men of Israel saw that Abimelech was dead, they departed every man to his place.",
                "Thus God requited the wickedness of Abimelech, which he did to his father, in killing his seventy brothers;",
                "and all the wickedness of the men of Shechem did God requite on their heads: and on them came the curse of Jotham the son of Jerubbaal."
            ],
            10: [
                "After Abimelech there arose to save Israel Tola the son of Puah, the son of Dodo, a man of Issachar; and he lived in Shamir in the hill country of Ephraim.",
                "He judged Israel twenty-three years, and died, and was buried in Shamir.",
                "After him arose Jair, the Gileadite; and he judged Israel twenty-two years.",
                "He had thirty sons who rode on thirty donkey colts, and they had thirty cities, which are called Havvoth Jair to this day, which are in the land of Gilead.",
                "Jair died, and was buried in Kamon.",
                "The children of Israel again did that which was evil in the sight of Yahweh, and served the Baals, and the Ashtaroth, and the gods of Syria, and the gods of Sidon, and the gods of Moab, and the gods of the children of Ammon, and the gods of the Philistines; and they forsook Yahweh, and didn\u2019t serve him.",
                "The anger of Yahweh was kindled against Israel, and he sold them into the hand of the Philistines, and into the hand of the children of Ammon.",
                "They troubled and oppressed the children of Israel that year. For eighteen years, they oppressed all the children of Israel that were beyond the Jordan in the land of the Amorites, which is in Gilead.",
                "The children of Ammon passed over the Jordan to fight also against Judah, and against Benjamin, and against the house of Ephraim; so that Israel was very distressed.",
                "The children of Israel cried to Yahweh, saying, \"We have sinned against you, even because we have forsaken our God, and have served the Baals.\"",
                "Yahweh said to the children of Israel, \"Didn\u2019t I save you from the Egyptians, and from the Amorites, from the children of Ammon, and from the Philistines?",
                "The Sidonians also, and the Amalekites, and the Maonites, oppressed you; and you cried to me, and I saved you out of their hand.",
                "Yet you have forsaken me, and served other gods: therefore I will save you no more.",
                "Go and cry to the gods which you have chosen. Let them save you in the time of your distress!\"",
                "The children of Israel said to Yahweh, \"We have sinned: do you to us whatever seems good to you; only deliver us, please, this day.\"",
                "They put away the foreign gods from among them, and served Yahweh; and his soul was grieved for the misery of Israel.",
                "Then the children of Ammon were gathered together, and encamped in Gilead. The children of Israel assembled themselves together, and encamped in Mizpah.",
                "The people, the princes of Gilead, said one to another, \"What man is he who will begin to fight against the children of Ammon? He shall be head over all the inhabitants of Gilead.\""
            ],
            11: [
                "Now Jephthah the Gileadite was a mighty man of valor, and he was the son of a prostitute: and Gilead became the father of Jephthah.",
                "Gilead\u2019s wife bore him sons; and when his wife\u2019s sons grew up, they drove out Jephthah, and said to him, \"You shall not inherit in our father\u2019s house; for you are the son of another woman.\"",
                "Then Jephthah fled from his brothers, and lived in the land of Tob: and there were gathered vain fellows to Jephthah, and they went out with him.",
                "It happened after a while, that the children of Ammon made war against Israel.",
                "It was so, that when the children of Ammon made war against Israel, the elders of Gilead went to get Jephthah out of the land of Tob;",
                "and they said to Jephthah, \"Come and be our chief, that we may fight with the children of Ammon.\"",
                "Jephthah said to the elders of Gilead, \"Didn\u2019t you hate me, and drive me out of my father\u2019s house? Why have you come to me now when you are in distress?\"",
                "The elders of Gilead said to Jephthah, \"Therefore we have turned again to you now, that you may go with us, and fight with the children of Ammon; and you shall be our head over all the inhabitants of Gilead.\"",
                "Jephthah said to the elders of Gilead, \"If you bring me home again to fight with the children of Ammon, and Yahweh deliver them before me, shall I be your head?\"",
                "The elders of Gilead said to Jephthah, \"Yahweh shall be witness between us; surely according to your word so will we do.\"",
                "Then Jephthah went with the elders of Gilead, and the people made him head and chief over them: and Jephthah spoke all his words before Yahweh in Mizpah.",
                "Jephthah sent messengers to the king of the children of Ammon, saying, \"What have you to do with me, that you have come to me to fight against my land?\"",
                "The king of the children of Ammon answered to the messengers of Jephthah, \"Because Israel took away my land, when he came up out of Egypt, from the Arnon even to the Jabbok, and to the Jordan: now therefore restore that territory again peaceably.\"",
                "Jephthah sent messengers again to the king of the children of Ammon;",
                "and he said to him, \"Thus says Jephthah: Israel didn\u2019t take away the land of Moab, nor the land of the children of Ammon,",
                "but when they came up from Egypt, and Israel went through the wilderness to the Red Sea, and came to Kadesh;",
                "then Israel sent messengers to the king of Edom, saying, \u2018Please let me pass through your land;\u2019 but the king of Edom didn\u2019t listen. In the same way, he sent to the king of Moab; but he would not: and Israel stayed in Kadesh.",
                "Then they went through the wilderness, and went around the land of Edom, and the land of Moab, and came by the east side of the land of Moab, and they encamped on the other side of the Arnon; but they didn\u2019t come within the border of Moab, for the Arnon was the border of Moab.",
                "Israel sent messengers to Sihon king of the Amorites, the king of Heshbon; and Israel said to him, \u2018Please let us pass through your land to my place.\u2019",
                "But Sihon didn\u2019t trust Israel to pass through his border; but Sihon gathered all his people together, and encamped in Jahaz, and fought against Israel.",
                "Yahweh, the God of Israel, delivered Sihon and all his people into the hand of Israel, and they struck them: so Israel possessed all the land of the Amorites, the inhabitants of that country.",
                "They possessed all the border of the Amorites, from the Arnon even to the Jabbok, and from the wilderness even to the Jordan.",
                "So now Yahweh, the God of Israel, has dispossessed the Amorites from before his people Israel, and should you possess them?",
                "Won\u2019t you possess that which Chemosh your god gives you to possess? So whoever Yahweh our God has dispossessed from before us, them will we possess.",
                "Now are you anything better than Balak the son of Zippor, king of Moab? Did he ever strive against Israel, or did he ever fight against them?",
                "While Israel lived in Heshbon and its towns, and in Aroer and its towns, and in all the cities that are along by the side of the Arnon, three hundred years; why didn\u2019t you recover them within that time?",
                "I therefore have not sinned against you, but you do me wrong to war against me. Yahweh, the Judge, be judge this day between the children of Israel and the children of Ammon.\"",
                "However the king of the children of Ammon didn\u2019t listen to the words of Jephthah which he sent him.",
                "Then the Spirit of Yahweh came on Jephthah, and he passed over Gilead and Manasseh, and passed over Mizpeh of Gilead, and from Mizpeh of Gilead he passed over to the children of Ammon.",
                "Jephthah vowed a vow to Yahweh, and said, \"If you will indeed deliver the children of Ammon into my hand,",
                "then it shall be, that whatever comes forth from the doors of my house to meet me, when I return in peace from the children of Ammon, it shall be Yahweh\u2019s, and I will offer it up for a burnt offering.\"",
                "So Jephthah passed over to the children of Ammon to fight against them; and Yahweh delivered them into his hand.",
                "He struck them from Aroer until you come to Minnith, even twenty cities, and to Abelcheramim, with a very great slaughter. So the children of Ammon were subdued before the children of Israel.",
                "Jephthah came to Mizpah to his house; and behold, his daughter came out to meet him with tambourines and with dances: and she was his only child; besides her he had neither son nor daughter.",
                "It happened, when he saw her, that he tore his clothes, and said, \"Alas, my daughter! You have brought me very low, and you are one of those who trouble me; for I have opened my mouth to Yahweh, and I can\u2019t go back.\"",
                "She said to him, \"My father, you have opened your mouth to Yahweh; do to me according to that which has proceeded out of your mouth, because Yahweh has taken vengeance for you on your enemies, even on the children of Ammon.\"",
                "She said to her father, \"Let this thing be done for me: let me alone two months, that I may depart and go down on the mountains, and bewail my virginity, I and my companions.\"",
                "He said, \"Go.\" He sent her away for two months: and she departed, she and her companions, and mourned her virginity on the mountains.",
                "It happened at the end of two months, that she returned to her father, who did with her according to his vow which he had vowed: and she was a virgin. It was a custom in Israel,",
                "that the daughters of Israel went yearly to celebrate the daughter of Jephthah the Gileadite four days in a year."
            ],
            12: [
                "The men of Ephraim were gathered together, and passed northward; and they said to Jephthah, \"Why did you pass over to fight against the children of Ammon, and didn\u2019t call us to go with you? We will burn your house around you with fire!\"",
                "Jephthah said to them, \"I and my people were at great strife with the children of Ammon; and when I called you, you didn\u2019t save me out of their hand.",
                "When I saw that you didn\u2019t save me, I put my life in my hand, and passed over against the children of Ammon, and Yahweh delivered them into my hand. Why then have you come up to me this day, to fight against me?\"",
                "Then Jephthah gathered together all the men of Gilead, and fought with Ephraim; and the men of Gilead struck Ephraim, because they said, \"You are fugitives of Ephraim, you Gileadites, in the midst of Ephraim, and in the midst of Manasseh.\"",
                "The Gileadites took the fords of the Jordan against the Ephraimites. It was so, that when the fugitives of Ephraim said, \"Let me go over,\" the men of Gilead said to him, \"Are you an Ephraimite?\" If he said, \"No;\"",
                "then they said to him, \"Now say \u2018Shibboleth;\u2019\" and he said \"Sibboleth;\" for he couldn\u2019t manage to pronounce it right: then they siezed him, and killed him at the fords of the Jordan. At that time, forty-two thousand of Ephraim fell.",
                "Jephthah judged Israel six years. Then Jephthah the Gileadite died, and was buried in the cities of Gilead.",
                "After him Ibzan of Bethlehem judged Israel.",
                "He had thirty sons; and thirty daughters he sent abroad, and thirty daughters he brought in from abroad for his sons. He judged Israel seven years.",
                "Ibzan died, and was buried at Bethlehem.",
                "After him Elon the Zebulunite judged Israel; and he judged Israel ten years.",
                "Elon the Zebulunite died, and was buried in Aijalon in the land of Zebulun.",
                "After him Abdon the son of Hillel the Pirathonite judged Israel.",
                "He had forty sons and thirty sons\u2019 sons, who rode on seventy donkey colts: and he judged Israel eight years.",
                "Abdon the son of Hillel the Pirathonite died, and was buried in Pirathon in the land of Ephraim, in the hill country of the Amalekites."
            ],
            13: [
                "The children of Israel again did that which was evil in the sight of Yahweh; and Yahweh delivered them into the hand of the Philistines forty years.",
                "There was a certain man of Zorah, of the family of the Danites, whose name was Manoah; and his wife was barren, and didn\u2019t bear.",
                "The angel of Yahweh appeared to the woman, and said to her, \"See now, you are barren, and don\u2019t bear; but you shall conceive, and bear a son.",
                "Now therefore please beware and drink no wine nor strong drink, and don\u2019t eat any unclean thing:",
                "for, behold, you shall conceive, and bear a son; and no razor shall come on his head; for the child shall be a Nazirite to God from the womb: and he shall begin to save Israel out of the hand of the Philistines.\"",
                "Then the woman came and told her husband, saying, \"A man of God came to me, and his face was like the face of the angel of God, very awesome; and I didn\u2019t ask him where he was from, neither did he tell me his name:",
                "but he said to me, \u2018Behold, you shall conceive, and bear a son; and now drink no wine nor strong drink, and eat not any unclean thing; for the child shall be a Nazirite to God from the womb to the day of his death.\u2019\"",
                "Then Manoah entreated Yahweh, and said, \"Oh, Lord, please let the man of God whom you did send come again to us, and teach us what we shall do to the child who shall be born.\"",
                "God listened to the voice of Manoah; and the angel of God came again to the woman as she sat in the field: but Manoah, her husband, wasn\u2019t with her.",
                "The woman made haste, and ran, and told her husband, and said to him, \"Behold, the man has appeared to me, who came to me that day.\"",
                "Manoah arose, and went after his wife, and came to the man, and said to him, \"Are you the man who spoke to the woman?\" He said, \"I am.\"",
                "Manoah said, \"Now let your words happen. What shall the child\u2019s way of life and mission be?\"",
                "The angel of Yahweh said to Manoah, \"Of all that I said to the woman let her beware.",
                "She may not eat of anything that comes of the vine, neither let her drink wine or strong drink, nor eat any unclean thing; all that I commanded her let her observe.\"",
                "Manoah said to the angel of Yahweh, \"Please, let us detain you, that we may make a young goat ready for you.\"",
                "The angel of Yahweh said to Manoah, \"Though you detain me, I won\u2019t eat of your bread; and if you will prepare a burnt offering, you must offer it to Yahweh.\" For Manoah didn\u2019t know that he was the angel of Yahweh.",
                "Manoah said to the angel of Yahweh, \"What is your name, that when your words happen, we may honor you?\"",
                "The angel of Yahweh said to him, \"Why do you ask about my name, since it is wonderful?\"",
                "So Manoah took the young goat with the meal offering, and offered it on the rock to Yahweh. Then the angel did a wonderful thing as Manoah and his wife looked on.",
                "For it happened, when the flame went up toward the sky from off the altar, that the angel of Yahweh ascended in the flame of the altar: and Manoah and his wife looked on; and they fell on their faces to the ground.",
                "But the angel of Yahweh didn\u2019t appear to Manoah or to his wife any more. Then Manoah knew that he was the angel of Yahweh.",
                "Manoah said to his wife, \"We shall surely die, because we have seen God.\"",
                "But his wife said to him, \"If Yahweh were pleased to kill us, he wouldn\u2019t have received a burnt offering and a meal offering at our hand, neither would he have shown us all these things, nor would at this time have told such things as these.\"",
                "The woman bore a son, and named him Samson: and the child grew, and Yahweh blessed him.",
                "The Spirit of Yahweh began to move him in Mahaneh Dan, between Zorah and Eshtaol."
            ],
            14: [
                "Samson went down to Timnah, and saw a woman in Timnah of the daughters of the Philistines.",
                "He came up, and told his father and his mother, and said, \"I have seen a woman in Timnah of the daughters of the Philistines: now therefore get her for me as wife.\"",
                "Then his father and his mother said to him, \"Is there never a woman among the daughters of your brothers, or among all my people, that you go to take a wife of the uncircumcised Philistines?\" Samson said to his father, \"Get her for me; for she pleases me well.\"",
                "But his father and his mother didn\u2019t know that it was of Yahweh; for he sought an occasion against the Philistines. Now at that time the Philistines had rule over Israel.",
                "Then went Samson down, and his father and his mother, to Timnah, and came to the vineyards of Timnah: and behold, a young lion roared against him.",
                "The Spirit of Yahweh came mightily on him, and he tore him as he would have torn a young goat; and he had nothing in his hand: but he didn\u2019t tell his father or his mother what he had done.",
                "He went down, and talked with the woman, and she pleased Samson well.",
                "After a while he returned to take her; and he turned aside to see the carcass of the lion: and behold, there was a swarm of bees in the body of the lion, and honey.",
                "He took it into his hands, and went on, eating as he went; and he came to his father and mother, and gave to them, and they ate: but he didn\u2019t tell them that he had taken the honey out of the body of the lion.",
                "His father went down to the woman: and Samson made there a feast; for so used the young men to do.",
                "It happened, when they saw him, that they brought thirty companions to be with him.",
                "Samson said to them, \"Let me tell you a riddle now. If you can declare it to me within the seven days of the feast, and find it out, then I will give you thirty linen garments and thirty changes of clothing;",
                "but if you can\u2019t declare it to me, then you shall give me thirty linen garments and thirty changes of clothing.\" They said to him, \"Put forth your riddle, that we may hear it.\"",
                "He said to them, \"Out of the eater came forth food. Out of the strong came forth sweetness.\" They couldn\u2019t in three days declare the riddle.",
                "It happened on the seventh day, that they said to Samson\u2019s wife, \"Entice your husband, that he may declare to us the riddle, lest we burn you and your father\u2019s house with fire. Have you called us to impoverish us? Is it not so?\"",
                "Samson\u2019s wife wept before him, and said, \"You just hate me, and don\u2019t love me. You have put forth a riddle to the children of my people, and haven\u2019t told it me.\" He said to her, \"Behold, I haven\u2019t told it my father nor my mother, and shall I tell you?\"",
                "She wept before him the seven days, while their feast lasted: and it happened on the seventh day, that he told her, because she pressed him severely; and she told the riddle to the children of her people.",
                "The men of the city said to him on the seventh day before the sun went down, \"What is sweeter than honey? What is stronger than a lion?\" He said to them, \"If you hadn\u2019t plowed with my heifer, you wouldn\u2019t have found out my riddle.\"",
                "The Spirit of Yahweh came mightily on him, and he went down to Ashkelon, and struck thirty men of them, and took their spoil, and gave the changes of clothing to those who declared the riddle. His anger was kindled, and he went up to his father\u2019s house.",
                "But Samson\u2019s wife was given to his companion, whom he had used as his friend."
            ],
            15: [
                "But it happened after a while, in the time of wheat harvest, that Samson visited his wife with a young goat; and he said, \"I will go in to my wife into the room.\" But her father wouldn\u2019t allow him to go in.",
                "Her father said, \"I most certainly thought that you had utterly hated her; therefore I gave her to your companion. Isn\u2019t her younger sister more beautiful than she? Please take her, instead.\"",
                "Samson said to them, \"This time I will be blameless in regard of the Philistines, when I harm them.\"",
                "Samson went and caught three hundred foxes, and took torches, and turned tail to tail, and put a torch in the midst between every two tails.",
                "When he had set the brands on fire, he let them go into the standing grain of the Philistines, and burnt up both the shocks and the standing grain, and also the olive groves.",
                "Then the Philistines said, \"Who has done this?\" They said, \"Samson, the son-in-law of the Timnite, because he has taken his wife, and given her to his companion.\" The Philistines came up, and burnt her and her father with fire.",
                "Samson said to them, \"If you behave like this, surely I will be avenged of you, and after that I will cease.\"",
                "He struck them hip and thigh with a great slaughter: and he went down and lived in the cleft of the rock of Etam.",
                "Then the Philistines went up, and encamped in Judah, and spread themselves in Lehi.",
                "The men of Judah said, \"Why have you come up against us?\" They said, \"We have come up to bind Samson, to do to him as he has done to us.\"",
                "Then three thousand men of Judah went down to the cleft of the rock of Etam, and said to Samson, \"Don\u2019t you know that the Philistines are rulers over us? What then is this that you have done to us?\" He said to them, \"As they did to me, so have I done to them.\"",
                "They said to him, \"We have come down to bind you, that we may deliver you into the hand of the Philistines.\" Samson said to them, \"Swear to me that you will not fall on me yourselves.\"",
                "They spoke to him, saying, \"No; but we will bind you fast, and deliver you into their hand; but surely we will not kill you.\" They bound him with two new ropes, and brought him up from the rock.",
                "When he came to Lehi, the Philistines shouted as they met him: and the Spirit of Yahweh came mightily on him, and the ropes that were on his arms became as flax that was burnt with fire, and his bands dropped from off his hands.",
                "He found a fresh jawbone of a donkey, and put forth his hand, and took it, and struck a thousand men therewith.",
                "Samson said, \"With the jawbone of a donkey, heaps on heaps; with the jawbone of a donkey I have struck a thousand men.\"",
                "It happened, when he had made an end of speaking, that he cast away the jawbone out of his hand; and that place was called Ramath Lehi.",
                "He was very thirsty, and called on Yahweh, and said, \"You have given this great deliverance by the hand of your servant; and now shall I die for thirst, and fall into the hand of the uncircumcised?\"",
                "But God split the hollow place that is in Lehi, and water came out of it. When he had drunk, his spirit came again, and he revived: therefore its name was called En Hakkore, which is in Lehi, to this day.",
                "He judged Israel in the days of the Philistines twenty years."
            ],
            16: [
                "Samson went to Gaza, and saw there a prostitute, and went in to her.",
                "The Gazites were told, \"Samson is here!\" They surrounded him, and laid wait for him all night in the gate of the city, and were quiet all the night, saying, \"Wait until morning light, then we will kill him.\"",
                "Samson lay until midnight, and arose at midnight, and laid hold of the doors of the gate of the city, and the two posts, and plucked them up, bar and all, and put them on his shoulders, and carried them up to the top of the mountain that is before Hebron.",
                "It came to pass afterward, that he loved a woman in the valley of Sorek, whose name was Delilah.",
                "The lords of the Philistines came up to her, and said to her, \"Entice him, and see in which his great strength lies, and by what means we may prevail against him, that we may bind him to afflict him; and we will each give you eleven hundred pieces of silver.\"",
                "Delilah said to Samson, \"Please tell me where your great strength lies, and what you might be bound to afflict you.\"",
                "Samson said to her, \"If they bind me with seven green cords that were never dried, then shall I become weak, and be as another man.\"",
                "Then the lords of the Philistines brought up to her seven green cords which had not been dried, and she bound him with them.",
                "Now she had an ambush waiting in the inner room. She said to him, \"The Philistines are on you, Samson!\" He broke the cords, as a string of tow is broken when it touches the fire. So his strength was not known.",
                "Delilah said to Samson, \"Behold, you have mocked me, and told me lies: now please tell me with which you might be bound.\"",
                "He said to her, \"If they only bind me with new ropes with which no work has been done, then shall I become weak, and be as another man.\"",
                "So Delilah took new ropes, and bound him therewith, and said to him, \"The Philistines are on you, Samson!\" The ambush was waiting in the inner room. He broke them off his arms like a thread.",
                "Delilah said to Samson, \"Until now, you have mocked me and told me lies. Tell me with what you might be bound.\" He said to her, \"If you weave the seven locks of my head with the web.\"",
                "She fastened it with the pin, and said to him, \"The Philistines are on you, Samson!\" He awakened out of his sleep, and plucked away the pin of the beam, and the web.",
                "She said to him, \"How can you say, \u2018I love you,\u2019 when your heart is not with me? You have mocked me these three times, and have not told me where your great strength lies.\"",
                "It happened, when she pressed him daily with her words, and urged him, that his soul was troubled to death.",
                "He told her all his heart, and said to her, \"No razor has ever come on my head; for I have been a Nazirite to God from my mother\u2019s womb. If I am shaved, then my strength will go from me, and I will become weak, and be like any other man.\"",
                "When Delilah saw that he had told her all his heart, she sent and called for the lords of the Philistines, saying, \"Come up this once, for he has told me all his heart.\" Then the lords of the Philistines came up to her, and brought the money in their hand.",
                "She made him sleep on her knees; and she called for a man, and shaved off the seven locks of his head; and she began to afflict him, and his strength went from him.",
                "She said, \"The Philistines are upon you, Samson!\" He awoke out of his sleep, and said, \"I will go out as at other times, and shake myself free.\" But he didn\u2019t know that Yahweh had departed from him.",
                "The Philistines laid hold on him, and put out his eyes; and they brought him down to Gaza, and bound him with fetters of brass; and he ground at the mill in the prison.",
                "However the hair of his head began to grow again after he was shaved.",
                "The lords of the Philistines gathered them together to offer a great sacrifice to Dagon their god, and to rejoice; for they said, \"Our god has delivered Samson our enemy into our hand.\"",
                "When the people saw him, they praised their god; for they said, \"Our god has delivered our enemy and the destroyer of our country, who has slain many of us, into our hand.\"",
                "It happened, when their hearts were merry, that they said, \"Call for Samson, that he may entertain us.\" They called for Samson out of the prison; and he performed before them. They set him between the pillars;",
                "and Samson said to the boy who held him by the hand, \"Allow me to feel the pillars whereupon the house rests, that I may lean on them.\"",
                "Now the house was full of men and women; and all the lords of the Philistines were there; and there were on the roof about three thousand men and women, who saw while Samson performed.",
                "Samson called to Yahweh, and said, \"Lord Yahweh, remember me, please, and strengthen me, please, only this once, God, that I may be at once avenged of the Philistines for my two eyes.\"",
                "Samson took hold of the two middle pillars on which the house rested, and leaned on them, the one with his right hand, and the other with his left.",
                "Samson said, \"Let me die with the Philistines!\" He bowed himself with all his might; and the house fell on the lords, and on all the people who were therein. So the dead that he killed at his death were more than those who he killed in his life.",
                "Then his brothers and all the house of his father came down, and took him, and brought him up, and buried him between Zorah and Eshtaol in the burial site of Manoah his father. He judged Israel twenty years."
            ],
            17: [
                "There was a man of the hill country of Ephraim, whose name was Micah.",
                "He said to his mother, \"The eleven hundred pieces of silver that were taken from you, about which you uttered a curse, and also spoke it in my ears, behold, the silver is with me; I took it.\" His mother said, \"Blessed be my son of Yahweh.\"",
                "He restored the eleven hundred pieces of silver to his mother; and his mother said, \"I most certainly dedicate the silver to Yahweh from my hand for my son, to make an engraved image and a molten image. Now therefore I will restore it to you.\"",
                "When he restored the money to his mother, his mother took two hundred pieces of silver, and gave them to the founder, who made of it an engraved image and a molten image: and it was in the house of Micah.",
                "The man Micah had a house of gods, and he made an ephod, and teraphim, and consecrated one of his sons, who became his priest.",
                "In those days there was no king in Israel: every man did that which was right in his own eyes.",
                "There was a young man out of Bethlehem Judah, of the family of Judah, who was a Levite; and he lived there.",
                "The man departed out of the city, out of Bethlehem Judah, to live where he could find a place, and he came to the hill country of Ephraim to the house of Micah, as he traveled.",
                "Micah said to him, \"Where did you come from?\" He said to him, \"I am a Levite of Bethlehem Judah, and I am looking for a place to live.\"",
                "Micah said to him, \"Dwell with me, and be to me a father and a priest, and I will give you ten pieces of silver per year, a suit of clothing, and your food.\" So the Levite went in.",
                "The Levite was content to dwell with the man; and the young man was to him as one of his sons.",
                "Micah consecrated the Levite, and the young man became his priest, and was in the house of Micah.",
                "Then Micah said, \"Now know I that Yahweh will do good to me, since I have a Levite to my priest.\""
            ],
            18: [
                "In those days there was no king in Israel: and in those days the tribe of the Danites sought an inheritance to dwell in; for to that day, their inheritance had not fallen to them among the tribes of Israel.",
                "The children of Dan sent of their family five men from their whole number, men of valor, from Zorah, and from Eshtaol, to spy out the land, and to search it; and they said to them, \"Go, explore the land!\" They came to the hill country of Ephraim, to the house of Micah, and lodged there.",
                "When they were by the house of Micah, they knew the voice of the young man the Levite; and they turned aside there, and said to him, \"Who brought you here? What do you do in this place? What do you have here?\"",
                "He said to them, \"Thus and thus has Micah dealt with me, and he has hired me, and I am become his priest.\"",
                "They said to him, \"Please ask counsel of God, that we may know whether our way which we go shall be prosperous.\"",
                "The priest said to them, \"Go in peace. Your way in which you go is before Yahweh.\"",
                "Then the five men departed, and came to Laish, and saw the people who were therein, how they lived in security, in the way of the Sidonians, quiet and secure; for there was none in the land, possessing authority, that might put them to shame in anything, and they were far from the Sidonians, and had no dealings with any man.",
                "They came to their brothers to Zorah and Eshtaol: and their brothers said to them, \"What do you say?\"",
                "They said, \"Arise, and let us go up against them; for we have seen the land, and behold, it is very good. Do you stand still? Don\u2019t be slothful to go and to enter in to possess the land.",
                "When you go, you shall come to a secure people, and the land is large; for God has given it into your hand, a place where there is no want of anything that is in the earth.\"",
                "There set forth from there of the family of the Danites, out of Zorah and out of Eshtaol, six hundred men girt with weapons of war.",
                "They went up, and encamped in Kiriath Jearim, in Judah: therefore they called that place Mahaneh Dan, to this day; behold, it is behind Kiriath Jearim.",
                "They passed there to the hill country of Ephraim, and came to the house of Micah.",
                "Then the five men who went to spy out the country of Laish answered, and said to their brothers, \"Do you know that there is in these houses an ephod, and teraphim, and an engraved image, and a molten image? Now therefore consider what you have to do.\"",
                "They turned aside there, and came to the house of the young man the Levite, even to the house of Micah, and asked him of his welfare.",
                "The six hundred men girt with their weapons of war, who were of the children of Dan, stood by the entrance of the gate.",
                "The five men who went to spy out the land went up, and came in there, and took the engraved image, and the ephod, and the teraphim, and the molten image: and the priest stood by the entrance of the gate with the six hundred men girt with weapons of war.",
                "When these went into Micah\u2019s house, and fetched the engraved image, the ephod, and the teraphim, and the molten image, the priest said to them, \"What are you doing?\"",
                "They said to him, \"Hold your peace, put your hand on your mouth, and go with us, and be to us a father and a priest. Is it better for you to be priest to the house of one man, or to be priest to a tribe and a family in Israel?\"",
                "The priest\u2019s heart was glad, and he took the ephod, and the teraphim, and the engraved image, and went in the midst of the people.",
                "So they turned and departed, and put the little ones and the livestock and the goods before them.",
                "When they were a good way from the house of Micah, the men who were in the houses near to Micah\u2019s house were gathered together, and overtook the children of Dan.",
                "They cried to the children of Dan. They turned their faces, and said to Micah, \"What ails you, that you come with such a company?\"",
                "He said, \"You have taken away my gods which I made, and the priest, and have gone away, and what more do I have? How then do you say to me, \u2018What ails you?\u2019\"",
                "The children of Dan said to him, \"Don\u2019t let your voice be heard among us, lest angry fellows fall on you, and you lose your life, with the lives of your household.\"",
                "The children of Dan went their way: and when Micah saw that they were too strong for him, he turned and went back to his house.",
                "They took that which Micah had made, and the priest whom he had, and came to Laish, to a people quiet and secure, and struck them with the edge of the sword; and they burnt the city with fire.",
                "There was no deliverer, because it was far from Sidon, and they had no dealings with any man; and it was in the valley that lies by Beth Rehob. They built the city, and lived therein.",
                "They called the name of the city Dan, after the name of Dan their father, who was born to Israel: however the name of the city was Laish at the first.",
                "The children of Dan set up for themselves the engraved image: and Jonathan, the son of Gershom, the son of Moses, he and his sons were priests to the tribe of the Danites until the day of the captivity of the land.",
                "So they set them up Micah\u2019s engraved image which he made, all the time that the house of God was in Shiloh."
            ],
            19: [
                "It happened in those days, when there was no king in Israel, that there was a certain Levite living on the farther side of the hill country of Ephraim, who took to him a concubine out of Bethlehem Judah.",
                "His concubine played the prostitute against him, and went away from him to her father\u2019s house to Bethlehem Judah, and was there the space of four months.",
                "Her husband arose, and went after her, to speak kindly to her, to bring her again, having his servant with him, and a couple of donkeys: and she brought him into her father\u2019s house; and when the father of the young lady saw him, he rejoiced to meet him.",
                "His father-in-law, the young lady\u2019s father, retained him; and he stayed with him three days: so they ate and drink, and lodged there.",
                "It happened on the fourth day, that they arose early in the morning, and he rose up to depart: and the young lady\u2019s father said to his son-in-law, \"Strengthen your heart with a morsel of bread, and afterward you shall go your way.\"",
                "So they sat down, ate, and drank, both of them together: and the young lady\u2019s father said to the man, \"Please be pleased to stay all night, and let your heart be merry.\"",
                "The man rose up to depart; but his father-in-law urged him, and he lodged there again.",
                "He arose early in the morning on the fifth day to depart; and the young lady\u2019s father said, \"Please strengthen your heart and stay until the day declines;\" and they both ate.",
                "When the man rose up to depart, he, and his concubine, and his servant, his father-in-law, the young lady\u2019s father, said to him, \"Behold, now the day draws toward evening, please stay all night: behold, the day grows to an end, lodge here, that your heart may be merry; and tomorrow go on your way early, that you may go home.\"",
                "But the man wouldn\u2019t stay that night, but he rose up and departed, and came over against Jebus (the same is Jerusalem): and there were with him a couple of donkeys saddled; his concubine also was with him.",
                "When they were by Jebus, the day was far spent; and the servant said to his master, \"Please come and let us turn aside into this city of the Jebusites, and lodge in it.\"",
                "His master said to him, \"We won\u2019t turn aside into the city of a foreigner, that is not of the children of Israel; but we will pass over to Gibeah.\"",
                "He said to his servant, \"Come and let us draw near to one of these places; and we will lodge in Gibeah, or in Ramah.\"",
                "So they passed on and went their way; and the sun went down on them near to Gibeah, which belongs to Benjamin.",
                "They turned aside there, to go in to lodge in Gibeah: and he went in, and sat him down in the street of the city; for there was no man who took them into his house to lodge.",
                "Behold, there came an old man from his work out of the field at evening: now the man was of the hill country of Ephraim, and he lived in Gibeah; but the men of the place were Benjamites.",
                "He lifted up his eyes, and saw the wayfaring man in the street of the city; and the old man said, \"Where are you going? Where did you come from?\"",
                "He said to him, \"We are passing from Bethlehem Judah to the farther side of the hill country of Ephraim. I am from there, and I went to Bethlehem Judah. I am going to the house of Yahweh; and there is no man who takes me into his house.",
                "Yet there is both straw and provender for our donkeys; and there is bread and wine also for me, and for your handmaid, and for the young man who is with your servants: there is no want of anything.\"",
                "The old man said, \"Peace be to you; howsoever let all your wants lie on me; only don\u2019t lodge in the street.\"",
                "So he brought him into his house, and gave the donkeys fodder; and they washed their feet, and ate and drink.",
                "As they were making their hearts merry, behold, the men of the city, certain base fellows, surrounded the house, beating at the door; and they spoke to the master of the house, the old man, saying, \"Bring out the man who came into your house, that we may have sex with him!\"",
                "The man, the master of the house, went out to them, and said to them, \"No, my brothers, please don\u2019t act so wickedly; since this man has come into my house, don\u2019t do this folly.",
                "Behold, here is my virgin daughter and his concubine. I will bring them out now. Humble them, and do with them what seems good to you; but to this man don\u2019t do any such folly.\"",
                "But the men wouldn\u2019t listen to him: so the man laid hold of his concubine, and brought her out to them; and they had sex with her, and abused her all night until the morning: and when the day began to dawn, they let her go.",
                "Then came the woman in the dawning of the day, and fell down at the door of the man\u2019s house where her lord was, until it was light.",
                "Her lord rose up in the morning, and opened the doors of the house, and went out to go his way; and behold, the woman his concubine was fallen down at the door of the house, with her hands on the threshold.",
                "He said to her, \"Get up, and let us be going!\" but no one answered. Then he took her up on the donkey; and the man rose up, and went to his place.",
                "When he had come into his house, he took a knife, and laid hold on his concubine, and divided her, limb by limb, into twelve pieces, and sent her throughout all the borders of Israel.",
                "It was so, that all who saw it said, \"There was no such deed done nor seen from the day that the children of Israel came up out of the land of Egypt to this day! Consider it, take counsel, and speak.\""
            ],
            20: [
                "Then all the children of Israel went out, and the congregation was assembled as one man, from Dan even to Beersheba, with the land of Gilead, to Yahweh at Mizpah.",
                "The chiefs of all the people, even of all the tribes of Israel, presented themselves in the assembly of the people of God, four hundred thousand footmen who drew sword.",
                "(Now the children of Benjamin heard that the children of Israel had gone up to Mizpah.) The children of Israel said, \"Tell us, how did this wickedness happen?\"",
                "The Levite, the husband of the woman who was murdered, answered, \"I came into Gibeah that belongs to Benjamin, I and my concubine, to lodge.",
                "The men of Gibeah rose against me, and surrounded the house by night. They thought to have slain me, and they forced my concubine, and she is dead.",
                "I took my concubine, and cut her in pieces, and sent her throughout all the country of the inheritance of Israel; for they have committed lewdness and folly in Israel.",
                "Behold, you children of Israel, all of you, give here your advice and counsel.\"",
                "All the people arose as one man, saying, \"None of us will go to his tent, neither will any of us turn to his house.",
                "But now this is the thing which we will do to Gibeah: we will go up against it by lot;",
                "and we will take ten men of one hundred throughout all the tribes of Israel, and one hundred of one thousand, and a thousand out of ten thousand, to get food for the people, that they may do, when they come to Gibeah of Benjamin, according to all the folly that they have worked in Israel.\"",
                "So all the men of Israel were gathered against the city, knit together as one man.",
                "The tribes of Israel sent men through all the tribe of Benjamin, saying, \"What wickedness is this that is happen among you?",
                "Now therefore deliver up the men, the base fellows, who are in Gibeah, that we may put them to death, and put away evil from Israel.\" But Benjamin would not listen to the voice of their brothers the children of Israel.",
                "The children of Benjamin gathered themselves together out of the cities to Gibeah, to go out to battle against the children of Israel.",
                "The children of Benjamin were numbered on that day out of the cities twenty-six thousand men who drew the sword, besides the inhabitants of Gibeah, who were numbered seven hundred chosen men.",
                "Among all this people there were seven hundred chosen men left-handed; everyone could sling stones at a hair-breadth, and not miss.",
                "The men of Israel, besides Benjamin, were numbered four hundred thousand men who drew sword: all these were men of war.",
                "The children of Israel arose, and went up to Bethel, and asked counsel of God; and they said, \"Who shall go up for us first to battle against the children of Benjamin?\" Yahweh said, \"Judah first.\"",
                "The children of Israel rose up in the morning, and encamped against Gibeah.",
                "The men of Israel went out to battle against Benjamin; and the men of Israel set the battle in array against them at Gibeah.",
                "The children of Benjamin came forth out of Gibeah, and destroyed down to the ground of the Israelites on that day twenty-two thousand men.",
                "The people, the men of Israel, encouraged themselves, and set the battle again in array in the place where they set themselves in array the first day.",
                "The children of Israel went up and wept before Yahweh until evening; and they asked of Yahweh, saying, \"Shall I again draw near to battle against the children of Benjamin my brother?\" Yahweh said, \"Go up against him.\"",
                "The children of Israel came near against the children of Benjamin the second day.",
                "Benjamin went forth against them out of Gibeah the second day, and destroyed down to the ground of the children of Israel again eighteen thousand men; all these drew the sword.",
                "Then all the children of Israel, and all the people, went up, and came to Bethel, and wept, and sat there before Yahweh, and fasted that day until evening; and they offered burnt offerings and peace offerings before Yahweh.",
                "The children of Israel asked of Yahweh (for the ark of the covenant of God was there in those days,",
                "and Phinehas, the son of Eleazar, the son of Aaron, stood before it in those days), saying, \"Shall I yet again go out to battle against the children of Benjamin my brother, or shall I cease?\" Yahweh said, \"Go up; for tomorrow I will deliver him into your hand.\"",
                "Israel set ambushes all around Gibeah.",
                "The children of Israel went up against the children of Benjamin on the third day, and set themselves in array against Gibeah, as at other times.",
                "The children of Benjamin went out against the people, and were drawn away from the city; and they began to strike and kill of the people, as at other times, in the highways, of which one goes up to Bethel, and the other to Gibeah, in the field, about thirty men of Israel.",
                "The children of Benjamin said, \"They are struck down before us, as at the first.\" But the children of Israel said, \"Let us flee, and draw them away from the city to the highways.\"",
                "All the men of Israel rose up out of their place, and set themselves in array at Baal Tamar: and the ambushers of Israel broke forth out of their place, even out of Maareh Geba.",
                "There came over against Gibeah ten thousand chosen men out of all Israel, and the battle was severe; but they didn\u2019t know that evil was close on them.",
                "Yahweh struck Benjamin before Israel; and the children of Israel destroyed of Benjamin that day twenty-five thousand one hundred men: all these drew the sword.",
                "So the children of Benjamin saw that they were struck; for the men of Israel gave place to Benjamin, because they trusted the ambushers whom they had set against Gibeah.",
                "The ambushers hurried, and rushed on Gibeah; and the ambushers drew themselves along, and struck all the city with the edge of the sword.",
                "Now the appointed sign between the men of Israel and the ambushers was that they should make a great cloud of smoke rise up out of the city.",
                "The men of Israel turned in the battle, and Benjamin began to strike and kill of the men of Israel about thirty persons; for they said, \"Surely they are struck down before us, as in the first battle.\"",
                "But when the cloud began to arise up out of the city in a pillar of smoke, the Benjamites looked behind them; and behold, the whole of the city went up in smoke to the sky.",
                "The men of Israel turned, and the men of Benjamin were dismayed; for they saw that evil had come on them.",
                "Therefore they turned their backs before the men of Israel to the way of the wilderness; but the battle followed hard after them; and those who came out of the cities destroyed them in its midst.",
                "They surrounded the Benjamites, chased them, and trod them down at their resting place, as far as over against Gibeah toward the sunrise.",
                "There fell of Benjamin eighteen thousand men; all these were men of valor.",
                "They turned and fled toward the wilderness to the rock of Rimmon: and they gleaned of them in the highways five thousand men, and followed hard after them to Gidom, and struck of them two thousand men.",
                "So that all who fell that day of Benjamin were twenty-five thousand men who drew the sword; all these were men of valor.",
                "But six hundred men turned and fled toward the wilderness to the rock of Rimmon, and stayed in the rock of Rimmon four months.",
                "The men of Israel turned again on the children of Benjamin, and struck them with the edge of the sword, both the entire city, and the livestock, and all that they found: moreover all the cities which they found they set on fire."
            ],
            21: [
                "Now the men of Israel had sworn in Mizpah, saying, \"There shall not any of us give his daughter to Benjamin as wife.\"",
                "The people came to Bethel, and sat there until evening before God, and lifted up their voices, and wept severely.",
                "They said, \"Yahweh, the God of Israel, why has this happened in Israel, that there should be today one tribe lacking in Israel?\"",
                "It happened on the next day that the people rose early, and built there an altar, and offered burnt offerings and peace offerings.",
                "The children of Israel said, \"Who is there among all the tribes of Israel who didn\u2019t come up in the assembly to Yahweh?\" For they had made a great oath concerning him who didn\u2019t come up to Yahweh to Mizpah, saying, \"He shall surely be put to death.\"",
                "The children of Israel grieved for Benjamin their brother, and said, \"There is one tribe cut off from Israel this day.",
                "How shall we provide wives for those who remain, since we have sworn by Yahweh that we will not give them of our daughters to wives?\"",
                "They said, \"What one is there of the tribes of Israel who didn\u2019t come up to Yahweh to Mizpah?\" Behold, there came none to the camp from Jabesh Gilead to the assembly.",
                "For when the people were numbered, behold, there were none of the inhabitants of Jabesh Gilead there.",
                "The congregation sent there twelve thousand men of the most valiant, and commanded them, saying, \"Go and strike the inhabitants of Jabesh Gilead with the edge of the sword, with the women and the little ones.",
                "This is the thing that you shall do: you shall utterly destroy every male, and every woman who has lain with a man.\"",
                "They found among the inhabitants of Jabesh Gilead four hundred young virgins, who had not known man by lying with him; and they brought them to the camp to Shiloh, which is in the land of Canaan.",
                "The whole congregation sent and spoke to the children of Benjamin who were in the rock of Rimmon, and proclaimed peace to them.",
                "Benjamin returned at that time; and they gave them the women whom they had saved alive of the women of Jabesh Gilead: and yet so they weren\u2019t enough for them.",
                "The people grieved for Benjamin, because that Yahweh had made a breach in the tribes of Israel.",
                "Then the elders of the congregation said, \"How shall we provide wives for those who remain, since the women are destroyed out of Benjamin?\"",
                "They said, \"There must be an inheritance for those who are escaped of Benjamin, that a tribe not be blotted out from Israel.",
                "However we may not give them wives of our daughters, for the children of Israel had sworn, saying, \u2018Cursed is he who gives a wife to Benjamin.\u2019\"",
                "They said, \"Behold, there is a feast of Yahweh from year to year in Shiloh, which is on the north of Bethel, on the east side of the highway that goes up from Bethel to Shechem, and on the south of Lebonah.\"",
                "They commanded the children of Benjamin, saying, \"Go and lie in wait in the vineyards,",
                "and see, and behold, if the daughters of Shiloh come out to dance in the dances, then come out of the vineyards, and each man catch his wife of the daughters of Shiloh, and go to the land of Benjamin.",
                "It shall be, when their fathers or their brothers come to complain to us, that we will say to them, \u2018Grant them graciously to us, because we didn\u2019t take for each man his wife in battle, neither did you give them to them, otherwise you would now be guilty.\u2019\"",
                "The children of Benjamin did so, and took them wives, according to their number, of those who danced, whom they carried off. They went and returned to their inheritance, built the cities, and lived in them.",
                "The children of Israel departed there at that time, every man to his tribe and to his family, and they went out from there every man to his inheritance.",
                "In those days there was no king in Israel: every man did that which was right in his own eyes."
            ]
        },
        8: {
            1: [
                "It happened in the days when the judges judged, that there was a famine in the land. A certain man of Bethlehem Judah went to live in the country of Moab, he, and his wife, and his two sons.",
                "The name of the man was Elimelech, and the name of his wife Naomi, and the name of his two sons Mahlon and Chilion, Ephrathites of Bethlehem Judah. They came into the country of Moab, and continued there.",
                "Elimelech, Naomi\u2019s husband, died; and she was left, and her two sons.",
                "They took them wives of the women of Moab; the name of the one was Orpah, and the name of the other Ruth: and they lived there about ten years.",
                "Mahlon and Chilion both died, and the woman was bereaved of her two children and of her husband.",
                "Then she arose with her daughters-in-law, that she might return from the country of Moab: for she had heard in the country of Moab how that Yahweh had visited his people in giving them bread.",
                "She went forth out of the place where she was, and her two daughters-in-law with her; and they went on the way to return to the land of Judah.",
                "Naomi said to her two daughters-in-law, \"Go, return each of you to her mother\u2019s house: Yahweh deal kindly with you, as you have dealt with the dead, and with me.",
                "Yahweh grant you that you may find rest, each of you in the house of her husband.\" Then she kissed them, and they lifted up their voice, and wept.",
                "They said to her, \"No, but we will return with you to your people.\"",
                "Naomi said, \"Go back, my daughters. Why do you want to go with me? Do I still have sons in my womb, that they may be your husbands?",
                "Go back, my daughters, go your way; for I am too old to have a husband. If I should say, \u2018I have hope,\u2019 if I should even have a husband tonight, and should also bear sons;",
                "would you then wait until they were grown? Would you then refrain from having husbands? No, my daughters, for it grieves me much for your sakes, for the hand of Yahweh has gone out against me.\"",
                "They lifted up their voice, and wept again: and Orpah kissed her mother-in-law, but Ruth joined with her.",
                "She said, \"Behold, your sister-in-law has gone back to her people, and to her god. Follow your sister-in-law.\"",
                "Ruth said, \"Don\u2019t entreat me to leave you, and to return from following after you, for where you go, I will go; and where you lodge, I will lodge; your people shall be my people, and your God my God;",
                "where you die, will I die, and there will I be buried. Yahweh do so to me, and more also, if anything but death part you and me.\"",
                "When she saw that she was steadfastly minded to go with her, she left off speaking to her.",
                "So they two went until they came to Bethlehem. It happened, when they had come to Bethlehem, that all the city was moved about them, and they asked, \"Is this Naomi?\"",
                "She said to them, \"Don\u2019t call me Naomi. Call me Mara; for the Almighty has dealt very bitterly with me.",
                "I went out full, and Yahweh has brought me home again empty; why do you call me Naomi, since Yahweh has testified against me, and the Almighty has afflicted me?\"",
                "So Naomi returned, and Ruth the Moabitess, her daughter-in-law, with her, who returned out of the country of Moab: and they came to Bethlehem in the beginning of barley harvest."
            ],
            2: [
                "Naomi had a kinsman of her husband\u2019s, a mighty man of wealth, of the family of Elimelech, and his name was Boaz.",
                "Ruth the Moabitess said to Naomi, \"Let me now go to the field, and glean among the ears of grain after him in whose sight I shall find favor.\" She said to her, \"Go, my daughter.\"",
                "She went, and came and gleaned in the field after the reapers: and she happened to come to the portion of the field belonging to Boaz, who was of the family of Elimelech.",
                "Behold, Boaz came from Bethlehem, and said to the reapers, \"Yahweh be with you.\" They answered him, \"Yahweh bless you.\"",
                "Then Boaz said to his servant who was set over the reapers, \"Whose young lady is this?\"",
                "The servant who was set over the reapers answered, \"It is the Moabite lady who came back with Naomi out of the country of Moab.",
                "She said, \u2018Please let me glean and gather after the reapers among the sheaves.\u2019 So she came, and has continued even from the morning until now, except that she stayed a little in the house.\"",
                "Then Boaz said to Ruth, \"Listen, my daughter. Don\u2019t go to glean in another field, and don\u2019t go from here, but stay here close to my maidens.",
                "Let your eyes be on the field that they reap, and go after them. Haven\u2019t I commanded the young men not to touch you? When you are thirsty, go to the vessels, and drink from that which the young men have drawn.\"",
                "Then she fell on her face, and bowed herself to the ground, and said to him, \"Why have I found favor in your sight, that you should take knowledge of me, since I am a foreigner?\"",
                "Boaz answered her, \"It has fully been shown me, all that you have done to your mother-in-law since the death of your husband; and how you have left your father and your mother, and the land of your birth, and have come to a people that you didn\u2019t know before.",
                "May Yahweh repay your work, and a full reward be given you from Yahweh, the God of Israel, under whose wings you have come to take refuge.\"",
                "Then she said, \"Let me find favor in your sight, my lord, because you have comforted me, and because you have spoken kindly to your handmaid, though I am not as one of your handmaidens.\"",
                "At meal time Boaz said to her, \"Come here, and eat of the bread, and dip your morsel in the vinegar.\" She sat beside the reapers, and they reached her parched grain, and she ate, and was satisfied, and left some of it.",
                "When she had risen up to glean, Boaz commanded his young men, saying, \"Let her glean even among the sheaves, and don\u2019t reproach her.",
                "Also pull out some for her from the bundles, and leave it, and let her glean, and don\u2019t rebuke her.\"",
                "So she gleaned in the field until evening; and she beat out that which she had gleaned, and it was about an ephah of barley.",
                "She took it up, and went into the city; and her mother-in-law saw what she had gleaned: and she brought out and gave to her that which she had left after she was sufficed.",
                "Her mother-in-law said to her, \"Where have you gleaned today? Where have you worked? Blessed be he who noticed you.\" She showed her mother-in-law with whom she had worked, and said, \"The man\u2019s name with whom I worked today is Boaz.\"",
                "Naomi said to her daughter-in-law, \"Blessed be he of Yahweh, who has not left off his kindness to the living and to the dead.\" Naomi said to her, \"The man is a close relative to us, one of our near kinsmen.\"",
                "Ruth the Moabitess said, \"Yes, he said to me, \u2018You shall stay close to my young men, until they have ended all my harvest.\u2019\"",
                "Naomi said to Ruth her daughter-in-law, \"It is good, my daughter, that you go out with his maidens, and that they not meet you in any other field.\"",
                "So she stayed close to the maidens of Boaz, to glean to the end of barley harvest and of wheat harvest; and she lived with her mother-in-law."
            ],
            3: [
                "Naomi her mother-in-law said to her, \"My daughter, shall I not seek rest for you, that it may be well with you?",
                "Now isn\u2019t Boaz our kinsman, with whose maidens you were? Behold, he winnows barley tonight in the threshing floor.",
                "Therefore wash yourself, anoint yourself, get dressed, and go down to the threshing floor, but don\u2019t make yourself known to the man until he has finished eating and drinking.",
                "It shall be, when he lies down, that you shall mark the place where he shall lie, and you shall go in, and uncover his feet, and lay down; then he will tell you what you shall do.\"",
                "She said to her, \"All that you say I will do.\"",
                "She went down to the threshing floor, and did according to all that her mother-in-law told her.",
                "When Boaz had eaten and drunk, and his heart was merry, he went to lie down at the end of the heap of grain. She came softly, uncovered his feet, and laid her down.",
                "It happened at midnight, that the man was startled and turned himself; and behold, a woman lay at his feet.",
                "He said, \"Who are you?\" She answered, \"I am Ruth your handmaid. Therefore spread your skirt over your handmaid; for you are a near kinsman.\"",
                "He said, \"Blessed are you by Yahweh, my daughter. You have shown more kindness in the latter end than at the beginning, inasmuch as you didn\u2019t follow young men, whether poor or rich.",
                "Now, my daughter, don\u2019t be afraid; I will do to you all that you say; for all the city of my people does know that you are a worthy woman.",
                "Now it is true that I am a near kinsman; however there is a kinsman nearer than I.",
                "Stay this night, and it shall be in the morning, that if he will perform for you the part of a kinsman, well; let him do the kinsman\u2019s part. But if he will not do the part of a kinsman for you, then will I do the part of a kinsman for you, as Yahweh lives. Lie down until the morning.\"",
                "She lay at his feet until the morning. She rose up before one could discern another. For he said, \"Let it not be known that the woman came to the threshing floor.\"",
                "He said, \"Bring the mantle that is on you, and hold it.\" She held it; and he measured six measures of barley, and laid it on her; and he went into the city.",
                "When she came to her mother-in-law, she said, \"How did it go, my daughter?\" She told her all that the man had done to her.",
                "She said, \"He gave me these six measures of barley; for he said, \u2018Don\u2019t go empty to your mother-in-law.\u2019\"",
                "Then she said, \"Sit still, my daughter, until you know how the matter will fall; for the man will not rest, until he has finished the thing this day.\""
            ],
            4: [
                "Now Boaz went up to the gate, and sat down there. Behold, the near kinsman of whom Boaz spoke came by; to whom he said, \"Come over here, friend, and sit down!\" He turned aside, and sat down.",
                "He took ten men of the elders of the city, and said, \"Sit down here.\" They sat down.",
                "He said to the near kinsman, \"Naomi, who has come back out of the country of Moab, is selling the parcel of land, which was our brother Elimelech\u2019s.",
                "I thought to disclose it to you, saying, \u2018Buy it before those who sit here, and before the elders of my people.\u2019 If you will redeem it, redeem it; but if you will not redeem it, then tell me, that I may know. For there is no one to redeem it besides you; and I am after you.\" He said, \"I will redeem it.\"",
                "Then Boaz said, \"On the day you buy the field from the hand of Naomi, you must buy it also from Ruth the Moabitess, the wife of the dead, to raise up the name of the dead on his inheritance.\"",
                "The near kinsman said, \"I can\u2019t redeem it for myself, lest I mar my own inheritance. Take my right of redemption for yourself; for I can\u2019t redeem it.\"",
                "Now this was the custom in former time in Israel concerning redeeming and concerning exchanging, to confirm all things: a man took off his shoe, and gave it to his neighbor; and this was the way of attestation in Israel.",
                "So the near kinsman said to Boaz, \"Buy it for yourself.\" He took off his shoe.",
                "Boaz said to the elders, and to all the people, \"You are witnesses this day, that I have bought all that was Elimelech\u2019s, and all that was Chilion\u2019s and Mahlon\u2019s, from the hand of Naomi.",
                "Moreover Ruth the Moabitess, the wife of Mahlon, I have purchased to be my wife, to raise up the name of the dead on his inheritance, that the name of the dead not be cut off from among his brothers, and from the gate of his place. You are witnesses this day.\"",
                "All the people who were in the gate, and the elders, said, \"We are witnesses. May Yahweh make the woman who has come into your house like Rachel and like Leah, which two built the house of Israel; and treat you worthily in Ephrathah, and be famous in Bethlehem.",
                "Let your house be like the house of Perez, whom Tamar bore to Judah, of the seed which Yahweh shall give you of this young woman.\"",
                "So Boaz took Ruth, and she became his wife; and he went in to her, and Yahweh gave her conception, and she bore a son.",
                "The women said to Naomi, \"Blessed be Yahweh, who has not left you this day without a near kinsman; and let his name be famous in Israel.",
                "He shall be to you a restorer of life, and sustain you in your old age, for your daughter-in-law, who loves you, who is better to you than seven sons, has borne him.\"",
                "Naomi took the child, and laid it in her bosom, and became nurse to it.",
                "The women, her neighbors, gave him a name, saying, \"There is a son born to Naomi;\" and they named him Obed. He is the father of Jesse, the father of David.",
                "Now this is the history of the generations of Perez: Perez became the father of Hezron,",
                "and Hezron became the father of Ram, and Ram became the father of Amminadab,",
                "and Amminadab became the father of Nahshon, and Nahshon became the father of Salmon,",
                "and Salmon became the father of Boaz, and Boaz became the father of Obed,",
                "and Obed became the father of Jesse, and Jesse became the father of David."
            ]
        },
        9: {
            1: [
                "Now there was a certain man of Ramathaim Zophim, of the hill country of Ephraim, and his name was Elkanah, the son of Jeroham, the son of Elihu, the son of Tohu, the son of Zuph, an Ephraimite:",
                "and he had two wives; the name of the one was Hannah, and the name of other Peninnah: and Peninnah had children, but Hannah had no children.",
                "This man went up out of his city from year to year to worship and to sacrifice to Yahweh of Armies in Shiloh. The two sons of Eli, Hophni and Phinehas, priests to Yahweh, were there.",
                "When the day came that Elkanah sacrificed, he gave to Peninnah his wife, and to all her sons and her daughters, portions:",
                "but to Hannah he gave a double portion; for he loved Hannah, but Yahweh had shut up her womb.",
                "Her rival provoked her severely, to make her fret, because Yahweh had shut up her womb.",
                "As he did so year by year, when she went up to the house of Yahweh, so she provoked her; therefore she wept, and didn\u2019t eat.",
                "Elkanah her husband said to her, \"Hannah, why do you weep? Why don\u2019t you eat? Why is your heart grieved? Am I not better to you than ten sons?\"",
                "So Hannah rose up after they had eaten in Shiloh, and after they had drunk. Now Eli the priest was sitting on his seat by the doorpost of the temple of Yahweh.",
                "She was in bitterness of soul, and prayed to Yahweh, and wept bitterly.",
                "She vowed a vow, and said, \"Yahweh of Armies, if you will indeed look on the affliction of your handmaid, and remember me, and not forget your handmaid, but will give to your handmaid a boy, then I will give him to Yahweh all the days of his life, and no razor shall come on his head.\"",
                "It happened, as she continued praying before Yahweh, that Eli saw her mouth.",
                "Now Hannah spoke in her heart. Only her lips moved, but her voice was not heard. Therefore Eli thought she had been drunken.",
                "Eli said to her, \"How long will you be drunken? Put away your wine from you.\"",
                "Hannah answered, \"No, my lord, I am a woman of a sorrowful spirit. I have drunk neither wine nor strong drink, but I poured out my soul before Yahweh.",
                "Don\u2019t count your handmaid for a wicked woman; for I have been speaking out of the abundance of my complaint and my provocation.\"",
                "Then Eli answered, \"Go in peace; and may the God of Israel grant your petition that you have asked of him.\"",
                "She said, \"Let your handmaid find favor in your sight.\" So the woman went her way, and ate; and her facial expression wasn\u2019t sad any more.",
                "They rose up in the morning early, and worshiped before Yahweh, and returned, and came to their house to Ramah: and Elkanah knew Hannah his wife; and Yahweh remembered her.",
                "It happened, when the time had come, that Hannah conceived, and bore a son; and she named him Samuel, saying, \"Because I have asked him of Yahweh.\"",
                "The man Elkanah, and all his house, went up to offer to Yahweh the yearly sacrifice, and his vow.",
                "But Hannah didn\u2019t go up; for she said to her husband, \"Not until the child is weaned; then I will bring him, that he may appear before Yahweh, and stay there forever.\"",
                "Elkanah her husband said to her, \"Do what seems good to you. Wait until you have weaned him; only may Yahweh establish his word.\" So the woman waited and nursed her son, until she weaned him.",
                "When she had weaned him, she took him up with her, with three bulls, and one ephah of meal, and a bottle of wine, and brought him to Yahweh\u2019s house in Shiloh. The child was young.",
                "They killed the bull, and brought the child to Eli.",
                "She said, \"Oh, my lord, as your soul lives, my lord, I am the woman who stood by you here, praying to Yahweh.",
                "For this child I prayed; and Yahweh has given me my petition which I asked of him.",
                "Therefore also I have granted him to Yahweh. As long as he lives he is granted to Yahweh.\" He worshiped Yahweh there."
            ],
            2: [
                "Hannah prayed, and said: \"My heart exults in Yahweh! My horn is exalted in Yahweh. My mouth is enlarged over my enemies, because I rejoice in your salvation.",
                "There is no one as holy as Yahweh, For there is no one besides you, nor is there any rock like our God.",
                "\"Talk no more so exceeding proudly. Don\u2019t let arrogance come out of your mouth, For Yahweh is a God of knowledge. By him actions are weighed.",
                "\"The bows of the mighty men are broken. Those who stumbled are armed with strength.",
                "Those who were full have hired themselves out for bread. Those who were hungry are satisfied. Yes, the barren has borne seven. She who has many children languishes.",
                "\"Yahweh kills, and makes alive. He brings down to Sheol, and brings up.",
                "Yahweh makes poor, and makes rich. He brings low, he also lifts up.",
                "He raises up the poor out of the dust. He lifts up the needy from the dunghill, To make them sit with princes, and inherit the throne of glory. For the pillars of the earth are Yahweh\u2019s. He has set the world on them.",
                "He will keep the feet of his holy ones, but the wicked shall be put to silence in darkness; for no man shall prevail by strength.",
                "Those who strive with Yahweh shall be broken to pieces. He will thunder against them in the sky. \"Yahweh will judge the ends of the earth. He will give strength to his king, and exalt the horn of his anointed.\"",
                "Elkanah went to Ramah to his house. The child served Yahweh before Eli the priest.",
                "Now the sons of Eli were base men; they didn\u2019t know Yahweh.",
                "The custom of the priests with the people was that when any man offered sacrifice, the priest\u2019s servant came, while the flesh was boiling, with a fork of three teeth in his hand;",
                "and he struck it into the pan, or kettle, or caldron, or pot; all that the fork brought up the priest took therewith. So they did in Shiloh to all the Israelites who came there.",
                "Yes, before they burnt the fat, the priest\u2019s servant came, and said to the man who sacrificed, \"Give meat to roast for the priest; for he will not accept boiled meat from you, but raw.\"",
                "If the man said to him, \"Let the fat be burned first, and then take as much as your soul desires;\" then he would say, \"No, but you shall give it to me now; and if not, I will take it by force.\"",
                "The sin of the young men was very great before Yahweh; for the men despised the offering of Yahweh.",
                "But Samuel ministered before Yahweh, being a child, clothed with a linen ephod.",
                "Moreover his mother made him a little robe, and brought it to him from year to year, when she came up with her husband to offer the yearly sacrifice.",
                "Eli blessed Elkanah and his wife, and said, \"Yahweh give you seed of this woman for the petition which was asked of Yahweh.\" They went to their own home.",
                "Yahweh visited Hannah, and she conceived, and bore three sons and two daughters. The child Samuel grew before Yahweh.",
                "Now Eli was very old; and he heard all that his sons did to all Israel, and how that they lay with the women who served at the door of the Tent of Meeting.",
                "He said to them, \"Why do you do such things? for I hear of your evil dealings from all this people.",
                "No, my sons; for it is no good report that I hear: you make Yahweh\u2019s people disobey.",
                "If one man sin against another, God shall judge him; but if a man sin against Yahweh, who shall entreat for him?\" Notwithstanding, they didn\u2019t listen to the voice of their father, because Yahweh intended to kill them.",
                "The child Samuel grew on, and increased in favor both with Yahweh, and also with men.",
                "A man of God came to Eli, and said to him, \"Thus says Yahweh, \u2018Did I reveal myself to the house of your father, when they were in Egypt in bondage to Pharaoh\u2019s house?",
                "Did I choose him out of all the tribes of Israel to be my priest, to go up to my altar, to burn incense, to wear an ephod before me? Did I give to the house of your father all the offerings of the children of Israel made by fire?",
                "Why do you kick at my sacrifice and at my offering, which I have commanded in my habitation, and honor your sons above me, to make yourselves fat with the best of all the offerings of Israel my people?\u2019",
                "\"Therefore Yahweh, the God of Israel, says, \u2018I said indeed that your house, and the house of your father, should walk before me forever.\u2019 But now Yahweh says, \u2018Be it far from me; for those who honor me I will honor, and those who despise me shall be lightly esteemed.",
                "Behold, the days come, that I will cut off your arm, and the arm of your father\u2019s house, that there shall not be an old man in your house.",
                "You shall see the affliction of my habitation, in all the wealth which I shall give Israel; and there shall not be an old man in your house forever.",
                "The man of yours, whom I shall not cut off from my altar, shall consume your eyes, and grieve your heart; and all the increase of your house shall die in the flower of their age.",
                "\"\u2018This shall be the sign to you, that shall come on your two sons, on Hophni and Phinehas: in one day they shall both die.",
                "I will raise me up a faithful priest, that shall do according to that which is in my heart and in my mind. I will build him a sure house; and he shall walk before my anointed forever.",
                "It shall happen, that everyone who is left in your house shall come and bow down to him for a piece of silver and a loaf of bread, and shall say, \"Please put me into one of the priests\u2019 offices, that I may eat a morsel of bread.\"\u2019\""
            ],
            3: [
                "The child Samuel ministered to Yahweh before Eli. The word of Yahweh was precious in those days; there was no frequent vision.",
                "It happened at that time, when Eli was laid down in his place (now his eyes had begun to grow dim, so that he could not see),",
                "and the lamp of God hadn\u2019t yet gone out, and Samuel had laid down in the temple of Yahweh, where the ark of God was;",
                "that Yahweh called Samuel; and he said, \"Here I am.\"",
                "He ran to Eli, and said, \"Here I am; for you called me.\" He said, \"I didn\u2019t call; lie down again.\" He went and lay down.",
                "Yahweh called yet again, \"Samuel!\" Samuel arose and went to Eli, and said, \"Here I am; for you called me.\" He answered, \"I didn\u2019t call, my son; lie down again.\"",
                "Now Samuel didn\u2019t yet know Yahweh, neither was the word of Yahweh yet revealed to him.",
                "Yahweh called Samuel again the third time. He arose and went to Eli, and said, \"Here I am; for you called me.\" Eli perceived that Yahweh had called the child.",
                "Therefore Eli said to Samuel, \"Go, lie down: and it shall be, if he calls you, that you shall say, \u2018Speak, Yahweh; for your servant hears.\u2019\" So Samuel went and lay down in his place.",
                "Yahweh came, and stood, and called as at other times, \"Samuel! Samuel!\" Then Samuel said, \"Speak; for your servant hears.\"",
                "Yahweh said to Samuel, \"Behold, I will do a thing in Israel, at which both the ears of everyone who hears it shall tingle.",
                "In that day I will perform against Eli all that I have spoken concerning his house, from the beginning even to the end.",
                "For I have told him that I will judge his house forever, for the iniquity which he knew, because his sons brought a curse on themselves, and he didn\u2019t restrain them.",
                "Therefore I have sworn to the house of Eli, that the iniquity of Eli\u2019s house shall not be removed with sacrifice nor offering forever.\"",
                "Samuel lay until the morning, and opened the doors of the house of Yahweh. Samuel feared to show Eli the vision.",
                "Then Eli called Samuel, and said, \"Samuel, my son!\" He said, \"Here I am.\"",
                "He said, \"What is the thing that he has spoken to you? Please don\u2019t hide it from me. God do so to you, and more also, if you hide anything from me of all the things that he spoke to you.\"",
                "Samuel told him every bit, and hid nothing from him. He said, \"It is Yahweh. Let him do what seems good to him.\"",
                "Samuel grew, and Yahweh was with him, and let none of his words fall to the ground.",
                "All Israel from Dan even to Beersheba knew that Samuel was established to be a prophet of Yahweh.",
                "Yahweh appeared again in Shiloh; for Yahweh revealed himself to Samuel in Shiloh by the word of Yahweh."
            ],
            4: [
                "The word of Samuel came to all Israel. Now Israel went out against the Philistines to battle, and encamped beside Ebenezer: and the Philistines encamped in Aphek.",
                "The Philistines put themselves in array against Israel: and when they joined battle, Israel was struck before the Philistines; and they killed of the army in the field about four thousand men.",
                "When the people had come into the camp, the elders of Israel said, \"Why has Yahweh struck us today before the Philistines? Let us get the ark of the covenant of Yahweh out of Shiloh to us, that it may come among us, and save us out of the hand of our enemies.\"",
                "So the people sent to Shiloh; and they brought from there the ark of the covenant of Yahweh of Armies, who sits above the cherubim: and the two sons of Eli, Hophni and Phinehas, were there with the ark of the covenant of God.",
                "When the ark of the covenant of Yahweh came into the camp, all Israel shouted with a great shout, so that the earth rang again.",
                "When the Philistines heard the noise of the shout, they said, \"What does the noise of this great shout in the camp of the Hebrews mean?\" They understood that the ark of Yahweh had come into the camp.",
                "The Philistines were afraid, for they said, \"God has come into the camp.\" They said, \"Woe to us! For there has not been such a thing before.",
                "Woe to us! Who shall deliver us out of the hand of these mighty gods? These are the gods that struck the Egyptians with all kinds of plagues in the wilderness.",
                "Be strong, and behave like men, O you Philistines, that you not be servants to the Hebrews, as they have been to you. Strengthen yourselves like men, and fight!\"",
                "The Philistines fought, and Israel was struck, and they fled every man to his tent: and there was a very great slaughter; for there fell of Israel thirty thousand footmen.",
                "The ark of God was taken; and the two sons of Eli, Hophni and Phinehas, were slain.",
                "There ran a man of Benjamin out of the army, and came to Shiloh the same day, with his clothes torn, and with earth on his head.",
                "When he came, behold, Eli was sitting on his seat by the road watching; for his heart trembled for the ark of God. When the man came into the city, and told it, all the city cried out.",
                "When Eli heard the noise of the crying, he said, \"What does the noise of this tumult mean?\" The man hurried, and came and told Eli.",
                "Now Eli was ninety-eight years old; and his eyes were set, so that he could not see.",
                "The man said to Eli, \"I am he who came out of the army, and I fled today out of the army.\" He said, \"How did the matter go, my son?\"",
                "He who brought the news answered, \"Israel has fled before the Philistines, and there has been also a great slaughter among the people. Your two sons also, Hophni and Phinehas, are dead, and the ark of God has been captured.\"",
                "It happened, when he made mention of the ark of God, that Eli fell from off his seat backward by the side of the gate; and his neck broke, and he died; for he was an old man, and heavy. He had judged Israel forty years.",
                "His daughter-in-law, Phinehas\u2019 wife, was with child, near to be delivered. When she heard the news that the ark of God was taken, and that her father-in-law and her husband were dead, she bowed herself and gave birth; for her pains came on her.",
                "About the time of her death the women who stood by her said to her, \"Don\u2019t be afraid; for you have given birth to a son.\" But she didn\u2019t answer, neither did she regard it.",
                "She named the child Ichabod, saying, \"The glory has departed from Israel;\" because the ark of God was taken, and because of her father-in-law and her husband.",
                "She said, \"The glory has departed from Israel; for the ark of God is taken.\""
            ],
            5: [
                "Now the Philistines had taken the ark of God, and they brought it from Ebenezer to Ashdod.",
                "The Philistines took the ark of God, and brought it into the house of Dagon, and set it by Dagon.",
                "When they of Ashdod arose early on the next day, behold, Dagon was fallen on his face to the ground before the ark of Yahweh. They took Dagon, and set him in his place again.",
                "When they arose early on the next day morning, behold, Dagon was fallen on his face to the ground before the ark of Yahweh; and the head of Dagon and both the palms of his hands were cut off on the threshold. Only Dagon\u2019s torso was intact.",
                "Therefore neither the priests of Dagon, nor any who come into Dagon\u2019s house, tread on the threshold of Dagon in Ashdod, to this day.",
                "But the hand of Yahweh was heavy on them of Ashdod, and he destroyed them, and struck them with tumors, even Ashdod and its borders.",
                "When the men of Ashdod saw that it was so, they said, \"The ark of the God of Israel shall not stay with us; for his hand is severe on us, and on Dagon our god.\"",
                "They sent therefore and gathered all the lords of the Philistines to them, and said, \"What shall we do with the ark of the God of Israel?\" They answered, \"Let the ark of the God of Israel be carried over to Gath.\" They carried the ark of the God of Israel there.",
                "It was so, that after they had carried it about, the hand of Yahweh was against the city with a very great confusion: and he struck the men of the city, both small and great; and tumors broke out on them.",
                "So they sent the ark of God to Ekron. It happened, as the ark of God came to Ekron, that the Ekronites cried out, saying, \"They have brought about the ark of the God of Israel to us, to kill us and our people.\"",
                "They sent therefore and gathered together all the lords of the Philistines, and they said, \"Send away the ark of the God of Israel, and let it go again to its own place, that it not kill us and our people.\" For there was a deadly confusion throughout all the city; the hand of God was very heavy there.",
                "The men who didn\u2019t die were struck with the tumors; and the cry of the city went up to heaven."
            ],
            6: [
                "The ark of Yahweh was in the country of the Philistines seven months.",
                "The Philistines called for the priests and the diviners, saying, \"What shall we do with the ark of Yahweh? Show us with which we shall send it to its place.\"",
                "They said, \"If you send away the ark of the God of Israel, don\u2019t send it empty; but by all means return him a trespass offering: then you shall be healed, and it shall be known to you why his hand is not removed from you.\"",
                "Then they said, \"What shall be the trespass offering which we shall return to him?\" They said, \"Five golden tumors, and five golden mice, for the number of the lords of the Philistines; for one plague was on you all, and on your lords.",
                "Therefore you shall make images of your tumors, and images of your mice that mar the land; and you shall give glory to the God of Israel: perhaps he will lighten his hand from off you, and from off your gods, and from off your land.",
                "Why then do you harden your hearts, as the Egyptians and Pharaoh hardened their hearts? When he had worked wonderfully among them, didn\u2019t they let the people go, and they departed?",
                "\"Now therefore take and prepare yourselves a new cart, and two milk cows, on which there has come no yoke; and tie the cows to the cart, and bring their calves home from them;",
                "and take the ark of Yahweh, and lay it on the cart; and put the jewels of gold, which you return him for a trespass offering, in a coffer by its side; and send it away, that it may go.",
                "Behold; if it goes up by the way of its own border to Beth Shemesh, then he has done us this great evil: but if not, then we shall know that it is not his hand that struck us; it was a chance that happened to us.\"",
                "The men did so, and took two milk cows, and tied them to the cart, and shut up their calves at home;",
                "and they put the ark of Yahweh on the cart, and the coffer with the mice of gold and the images of their tumors.",
                "The cows took the straight way by the way to Beth Shemesh; they went along the highway, lowing as they went, and didn\u2019t turn aside to the right hand or to the left; and the lords of the Philistines went after them to the border of Beth Shemesh.",
                "They of Beth Shemesh were reaping their wheat harvest in the valley; and they lifted up their eyes, and saw the ark, and rejoiced to see it.",
                "The cart came into the field of Joshua of Beth Shemesh, and stood there, where there was a great stone: and they split the wood of the cart, and offered up the cows for a burnt offering to Yahweh.",
                "The Levites took down the ark of Yahweh, and the coffer that was with it, in which the jewels of gold were, and put them on the great stone: and the men of Beth Shemesh offered burnt offerings and sacrificed sacrifices the same day to Yahweh.",
                "When the five lords of the Philistines had seen it, they returned to Ekron the same day.",
                "These are the golden tumors which the Philistines returned for a trespass offering to Yahweh: for Ashdod one, for Gaza one, for Ashkelon one, for Gath one, for Ekron one;",
                "and the golden mice, according to the number of all the cities of the Philistines belonging to the five lords, both of fortified cities and of country villages, even to the great stone, whereon they set down the ark of Yahweh. That stone remains to this day in the field of Joshua of Beth Shemesh.",
                "He struck of the men of Beth Shemesh, because they had looked into the ark of Yahweh, he struck of the people fifty thousand seventy men; and the people mourned, because Yahweh had struck the people with a great slaughter.",
                "The men of Beth Shemesh said, \"Who is able to stand before Yahweh, this holy God? To whom shall he go up from us?\"",
                "They sent messengers to the inhabitants of Kiriath Jearim, saying, \"The Philistines have brought back the ark of Yahweh; come down, and bring it up to yourselves.\""
            ],
            7: [
                "The men of Kiriath Jearim came, and fetched up the ark of Yahweh, and brought it into the house of Abinadab in the hill, and sanctified Eleazar his son to keep the ark of Yahweh.",
                "It happened, from the day that the ark stayed in Kiriath Jearim, that the time was long; for it was twenty years: and all the house of Israel lamented after Yahweh.",
                "Samuel spoke to all the house of Israel, saying, \"If you do return to Yahweh with all your heart, then put away the foreign gods and the Ashtaroth from among you, and direct your hearts to Yahweh, and serve him only; and he will deliver you out of the hand of the Philistines.\"",
                "Then the children of Israel removed the Baals and the Ashtaroth, and served Yahweh only.",
                "Samuel said, \"Gather all Israel to Mizpah, and I will pray for you to Yahweh.\"",
                "They gathered together to Mizpah, and drew water, and poured it out before Yahweh, and fasted on that day, and said there, \"We have sinned against Yahweh.\" Samuel judged the children of Israel in Mizpah.",
                "When the Philistines heard that the children of Israel were gathered together at Mizpah, the lords of the Philistines went up against Israel. When the children of Israel heard it, they were afraid of the Philistines.",
                "The children of Israel said to Samuel, \"Don\u2019t cease to cry to Yahweh our God for us, that he will save us out of the hand of the Philistines.\"",
                "Samuel took a suckling lamb, and offered it for a whole burnt offering to Yahweh: and Samuel cried to Yahweh for Israel; and Yahweh answered him.",
                "As Samuel was offering up the burnt offering, the Philistines drew near to battle against Israel; but Yahweh thundered with a great thunder on that day on the Philistines, and confused them; and they were struck down before Israel.",
                "The men of Israel went out of Mizpah, and pursued the Philistines, and struck them, until they came under Beth Kar.",
                "Then Samuel took a stone, and set it between Mizpah and Shen, and called its name Ebenezer, saying, \"Yahweh helped us until now.\"",
                "So the Philistines were subdued, and they came no more within the border of Israel. The hand of Yahweh was against the Philistines all the days of Samuel.",
                "The cities which the Philistines had taken from Israel were restored to Israel, from Ekron even to Gath; and Israel recovered its border out of the hand of the Philistines. There was peace between Israel and the Amorites.",
                "Samuel judged Israel all the days of his life.",
                "He went from year to year in circuit to Bethel and Gilgal, and Mizpah; and he judged Israel in all those places.",
                "His return was to Ramah, for there was his house; and there he judged Israel: and he built there an altar to Yahweh."
            ],
            8: [
                "It happened, when Samuel was old, that he made his sons judges over Israel.",
                "Now the name of his firstborn was Joel; and the name of his second, Abijah: they were judges in Beersheba.",
                "His sons didn\u2019t walk in his ways, but turned aside after lucre, and took bribes, and perverted justice.",
                "Then all the elders of Israel gathered themselves together, and came to Samuel to Ramah;",
                "and they said to him, \"Behold, you are old, and your sons don\u2019t walk in your ways: now make us a king to judge us like all the nations.\"",
                "But the thing displeased Samuel, when they said, \"Give us a king to judge us.\" Samuel prayed to Yahweh.",
                "Yahweh said to Samuel, \"Listen to the voice of the people in all that they tell you; for they have not rejected you, but they have rejected me, that I should not be king over them.",
                "According to all the works which they have done since the day that I brought them up out of Egypt even to this day, in that they have forsaken me, and served other gods, so do they also to you.",
                "Now therefore listen to their voice: however you shall protest solemnly to them, and shall show them the way of the king who shall reign over them.\"",
                "Samuel told all the words of Yahweh to the people who asked of him a king.",
                "He said, \"This will be the way of the king who shall reign over you: he will take your sons, and appoint them to him, for his chariots, and to be his horsemen; and they shall run before his chariots;",
                "and he will appoint them to him for captains of thousands, and captains of fifties; and he will assign some to plow his ground, and to reap his harvest, and to make his instruments of war, and the instruments of his chariots.",
                "He will take your daughters to be perfumers, and to be cooks, and to be bakers.",
                "He will take your fields, and your vineyards, and your olive groves, even their best, and give them to his servants.",
                "He will take the tenth of your seed, and of your vineyards, and give to his officers, and to his servants.",
                "He will take your male servants, and your female servants, and your best young men, and your donkeys, and put them to his work.",
                "He will take the tenth of your flocks: and you shall be his servants.",
                "You shall cry out in that day because of your king whom you shall have chosen you; and Yahweh will not answer you in that day.\"",
                "But the people refused to listen to the voice of Samuel; and they said, \"No; but we will have a king over us,",
                "that we also may be like all the nations, and that our king may judge us, and go out before us, and fight our battles.\"",
                "Samuel heard all the words of the people, and he rehearsed them in the ears of Yahweh.",
                "Yahweh said to Samuel, \"Listen to their voice, and make them a king.\" Samuel said to the men of Israel, \"Every man go to his city.\""
            ],
            9: [
                "Now there was a man of Benjamin, whose name was Kish, the son of Abiel, the son of Zeror, the son of Becorath, the son of Aphiah, the son of a Benjamite, a mighty man of valor.",
                "He had a son, whose name was Saul, an impressive young man; and there was not among the children of Israel a better person than he. From his shoulders and upward he was higher than any of the people.",
                "The donkeys of Kish, Saul\u2019s father, were lost. Kish said to Saul his son, \"Take now one of the servants with you, and arise, go seek the donkeys.\"",
                "He passed through the hill country of Ephraim, and passed through the land of Shalishah, but they didn\u2019t find them: then they passed through the land of Shaalim, and there they weren\u2019t there: and he passed through the land of the Benjamites, but they didn\u2019t find them.",
                "When they had come to the land of Zuph, Saul said to his servant who was with him, \"Come, and let us return, lest my father stop caring about the donkeys, and be anxious for us.\"",
                "He said to him, \"See now, there is in this city a man of God, and he is a man who is held in honor. All that he says comes surely to pass. Now let us go there. Perhaps he can tell us concerning our journey whereon we go.\"",
                "Then Saul said to his servant, \"But, behold, if we go, what shall we bring the man? For the bread is spent in our vessels, and there is not a present to bring to the man of God. What do we have?\"",
                "The servant answered Saul again, and said, \"Behold, I have in my hand the fourth part of a shekel of silver. I will give that to the man of God, to tell us our way.\"",
                "(In earlier times in Israel, when a man went to inquire of God, thus he said, \"Come, and let us go to the seer;\" for he who is now called a prophet was before called a Seer.)",
                "Then Saul said to his servant, \"Well said. Come, let us go.\" So they went to the city where the man of God was.",
                "As they went up the ascent to the city, they found young maidens going out to draw water, and said to them, \"Is the seer here?\"",
                "They answered them, and said, \"He is. Behold, he is before you. Hurry now, for he has come today into the city; for the people have a sacrifice today in the high place.",
                "As soon as you have come into the city, you shall immediately find him, before he goes up to the high place to eat; for the people will not eat until he come, because he blesses the sacrifice. Afterwards those who are invited eat. Now therefore go up; for at this time you shall find him.\"",
                "They went up to the city. As they came within the city, behold, Samuel came out toward them, to go up to the high place.",
                "Now Yahweh had revealed to Samuel a day before Saul came, saying,",
                "\"Tomorrow about this time I will send you a man out of the land of Benjamin, and you shall anoint him to be prince over my people Israel; and he shall save my people out of the hand of the Philistines: for I have looked on my people, because their cry has come to me.\"",
                "When Samuel saw Saul, Yahweh said to him, \"Behold, the man of whom I spoke to you! this same shall have authority over my people.\"",
                "Then Saul drew near to Samuel in the gate, and said, \"Please tell me where the seer\u2019s house is.\"",
                "Samuel answered Saul, and said, \"I am the seer. Go up before me to the high place, for you shall eat with me today. In the morning I will let you go, and will tell you all that is in your heart.",
                "As for your donkeys who were lost three days ago, don\u2019t set your mind on them; for they are found. For whom is all that is desirable in Israel? Is it not for you, and for all your father\u2019s house?\"",
                "Saul answered, \"Am I not a Benjamite, of the smallest of the tribes of Israel? And my family the least of all the families of the tribe of Benjamin? Why then do you speak to me like this?\"",
                "Samuel took Saul and his servant, and brought them into the guest room, and made them sit in the best place among those who were invited, who were about thirty persons.",
                "Samuel said to the cook, \"Bring the portion which I gave you, of which I said to you, \u2018Set it aside.\u2019\"",
                "The cook took up the thigh, and that which was on it, and set it before Saul. Samuel said, \"Behold, that which has been reserved! Set it before yourself and eat; because for the appointed time has it been kept for you, for I said, \u2018I have invited the people.\u2019\" So Saul ate with Samuel that day.",
                "When they had come down from the high place into the city, he talked with Saul on the housetop.",
                "They arose early: and it happened about the spring of the day, that Samuel called to Saul on the housetop, saying, \"Get up, that I may send you away.\" Saul arose, and they went out both of them, he and Samuel, abroad.",
                "As they were going down at the end of the city, Samuel said to Saul, \"Tell the servant pass on before us\" (and he passed on), \"but stand still first, that I may cause you to hear the word of God.\""
            ],
            10: [
                "Then Samuel took the vial of oil, and poured it on his head, and kissed him, and said, \"Isn\u2019t it that Yahweh has anointed you to be prince over his inheritance?",
                "When you have departed from me today, then you shall find two men by Rachel\u2019s tomb, in the border of Benjamin at Zelzah; and they will tell you, \u2018The donkeys which you went to seek have been found; and behold, your father has stopped caring about the donkeys, and is anxious for you, saying, \"What shall I do for my son?\"\u2019",
                "\"Then you shall go on forward from there, and you shall come to the oak of Tabor; and three men shall meet you there going up to God to Bethel, one carrying three young goats, and another carrying three loaves of bread, and another carrying a bottle of wine:",
                "and they will greet you, and give you two loaves of bread, which you shall receive of their hand.",
                "\"After that you shall come to the hill of God, where is the garrison of the Philistines: and it shall happen, when you have come there to the city, that you shall meet a band of prophets coming down from the high place with a psaltery, and a tambourine, and a pipe, and a harp, before them; and they will be prophesying:",
                "and the Spirit of Yahweh will come mightily on you, and you shall prophesy with them, and shall be turned into another man.",
                "Let it be, when these signs have come to you, that you do as occasion shall serve you; for God is with you.",
                "\"You shall go down before me to Gilgal; and behold, I will come down to you, to offer burnt offerings, and to sacrifice sacrifices of peace offerings: you shall wait seven days, until I come to you, and show you what you shall do.\"",
                "It was so, that when he had turned his back to go from Samuel, God gave him another heart: and all those signs happened that day.",
                "When they came there to the hill, behold, a band of prophets met him; and the Spirit of God came mightily on him, and he prophesied among them.",
                "It happened, when all who knew him before saw that, behold, he prophesied with the prophets, then the people said one to another, \"What is this that has come to the son of Kish? Is Saul also among the prophets?\"",
                "One of the same place answered, \"Who is their father?\" Therefore it became a proverb, \"Is Saul also among the prophets?\"",
                "When he had made an end of prophesying, he came to the high place.",
                "Saul\u2019s uncle said to him and to his servant, \"Where did you go?\" He said, \"To seek the donkeys. When we saw that they were not found, we came to Samuel.\"",
                "Saul\u2019s uncle said, \"Please tell me what Samuel said to you.\"",
                "Saul said to his uncle, \"He told us plainly that the donkeys were found.\" But concerning the matter of the kingdom, of which Samuel spoke, he didn\u2019t tell him.",
                "Samuel called the people together to Yahweh to Mizpah;",
                "and he said to the children of Israel, \"Thus says Yahweh, the God of Israel, \u2018I brought up Israel out of Egypt, and I delivered you out of the hand of the Egyptians, and out of the hand of all the kingdoms that oppressed you:\u2019",
                "but you have this day rejected your God, who himself saves you out of all your calamities and your distresses; and you have said to him, \u2018No! Set a king over us.\u2019 Now therefore present yourselves before Yahweh by your tribes, and by your thousands.\"",
                "So Samuel brought all the tribes of Israel near, and the tribe of Benjamin was taken.",
                "He brought the tribe of Benjamin near by their families; and the family of the Matrites was taken; and Saul the son of Kish was taken: but when they sought him, he could not be found.",
                "Therefore they asked of Yahweh further, \"Is there yet a man to come here?\" Yahweh answered, \"Behold, he has hidden himself among the baggage.\"",
                "They ran and fetched him there; and when he stood among the people, he was higher than any of the people from his shoulders and upward.",
                "Samuel said to all the people, \"You see him whom Yahweh has chosen, that there is none like him among all the people?\" All the people shouted, and said, \"Let the king live!\"",
                "Then Samuel told the people the regulations of the kingdom, and wrote it in a book, and laid it up before Yahweh. Samuel sent all the people away, every man to his house.",
                "Saul also went to his house to Gibeah; and there went with him the army, whose hearts God had touched.",
                "But certain worthless fellows said, \"How shall this man save us?\" They despised him, and brought him no present. But he held his peace."
            ],
            11: [
                "Then Nahash the Ammonite came up, and encamped against Jabesh Gilead: and all the men of Jabesh said to Nahash, \"Make a covenant with us, and we will serve you.\"",
                "Nahash the Ammonite said to them, \"On this condition I will make it with you, that all your right eyes be put out; and I will lay it for a reproach on all Israel.\"",
                "The elders of Jabesh said to him, \"Give us seven day, that we may send messengers to all the borders of Israel; and then, if there is no one to save us, we will come out to you.\"",
                "Then the messengers came to Gibeah of Saul, and spoke these words in the ears of the people: and all the people lifted up their voice, and wept.",
                "Behold, Saul came following the oxen out of the field; and Saul said, \"What ails the people that they weep?\" They told him the words of the men of Jabesh.",
                "The Spirit of God came mightily on Saul when he heard those words, and his anger was kindled greatly.",
                "He took a yoke of oxen, and cut them in pieces, and sent them throughout all the borders of Israel by the hand of messengers, saying, \"Whoever doesn\u2019t come forth after Saul and after Samuel, so shall it be done to his oxen.\" The dread of Yahweh fell on the people, and they came out as one man.",
                "He numbered them in Bezek; and the children of Israel were three hundred thousand, and the men of Judah thirty thousand.",
                "They said to the messengers who came, \"Thus you shall tell the men of Jabesh Gilead, \u2018Tomorrow, by the time the sun is hot, you shall have deliverance.\u2019\" The messengers came and told the men of Jabesh; and they were glad.",
                "Therefore the men of Jabesh said, \"Tomorrow we will come out to you, and you shall do with us all that seems good to you.\"",
                "It was so on the next day, that Saul put the people in three companies; and they came into the midst of the camp in the morning watch, and struck the Ammonites until the heat of the day: and it happened, that those who remained were scattered, so that no two of them were left together.",
                "The people said to Samuel, \"Who is he who said, \u2018Shall Saul reign over us?\u2019 Bring those men, that we may put them to death!\"",
                "Saul said, \"There shall not a man be put to death this day; for today Yahweh has worked deliverance in Israel.\"",
                "Then Samuel said to the people, \"Come, and let us go to Gilgal, and renew the kingdom there.\"",
                "All the people went to Gilgal; and there they made Saul king before Yahweh in Gilgal; and there they offered sacrifices of peace offerings before Yahweh; and there Saul and all the men of Israel rejoiced greatly."
            ],
            12: [
                "Samuel said to all Israel, \"Behold, I have listened to your voice in all that you said to me, and have made a king over you.",
                "Now, behold, the king walks before you; and I am old and gray-headed; and behold, my sons are with you: and I have walked before you from my youth to this day.",
                "Here I am. Witness against me before Yahweh, and before his anointed. Whose ox have I taken? Whose donkey have I taken? Whom have I defrauded? Whom have I oppressed? Of whose hand have I taken a ransom to blind my eyes therewith? I will restore it to you.\"",
                "They said, \"You have not defrauded us, nor oppressed us, neither have you taken anything of any man\u2019s hand.\"",
                "He said to them, \"Yahweh is witness against you, and his anointed is witness this day, that you have not found anything in my hand.\" They said, \"He is witness.\"",
                "Samuel said to the people, \"It is Yahweh who appointed Moses and Aaron, and that brought your fathers up out of the land of Egypt.",
                "Now therefore stand still, that I may plead with you before Yahweh concerning all the righteous acts of Yahweh, which he did to you and to your fathers.",
                "\"When Jacob had come into Egypt, and your fathers cried to Yahweh, then Yahweh sent Moses and Aaron, who brought your fathers out of Egypt, and made them to dwell in this place.",
                "\"But they forgot Yahweh their God; and he sold them into the hand of Sisera, captain of the army of Hazor, and into the hand of the Philistines, and into the hand of the king of Moab; and they fought against them.",
                "They cried to Yahweh, and said, \u2018We have sinned, because we have forsaken Yahweh, and have served the Baals and the Ashtaroth: but now deliver us out of the hand of our enemies, and we will serve you.\u2019",
                "Yahweh sent Jerubbaal, and Bedan, and Jephthah, and Samuel, and delivered you out of the hand of your enemies on every side; and you lived in safety.",
                "\"When you saw that Nahash the king of the children of Ammon came against you, you said to me, \u2018No, but a king shall reign over us;\u2019 when Yahweh your God was your king.",
                "Now therefore see the king whom you have chosen, and whom you have asked for: and behold, Yahweh has set a king over you.",
                "If you will fear Yahweh, and serve him, and listen to his voice, and not rebel against the commandment of Yahweh, then both you and also the king who reigns over you are followers of Yahweh your God.",
                "But if you will not listen to the voice of Yahweh, but rebel against the commandment of Yahweh, then will the hand of Yahweh be against you, as it was against your fathers.",
                "\"Now therefore stand still and see this great thing, which Yahweh will do before your eyes.",
                "Isn\u2019t it wheat harvest today? I will call to Yahweh, that he may send thunder and rain; and you shall know and see that your wickedness is great, which you have done in the sight of Yahweh, in asking for a king.\"",
                "So Samuel called to Yahweh; and Yahweh sent thunder and rain that day: and all the people greatly feared Yahweh and Samuel.",
                "All the people said to Samuel, \"Pray for your servants to Yahweh your God, that we not die; for we have added to all our sins this evil, to ask us a king.\"",
                "Samuel said to the people, \"Don\u2019t be afraid. You have indeed done all this evil; yet don\u2019t turn aside from following Yahweh, but serve Yahweh with all your heart.",
                "Don\u2019t turn aside to go after vain things which can\u2019t profit nor deliver, for they are vain.",
                "For Yahweh will not forsake his people for his great name\u2019s sake, because it has pleased Yahweh to make you a people to himself.",
                "Moreover as for me, far be it from me that I should sin against Yahweh in ceasing to pray for you: but I will instruct you in the good and the right way.",
                "Only fear Yahweh, and serve him in truth with all your heart; for consider how great things he has done for you.",
                "But if you shall still do wickedly, you shall be consumed, both you and your king.\""
            ],
            13: [
                "Saul reigned a year; and when he had reigned two years over Israel,",
                "Saul chose for himself three thousand men of Israel, of which two thousand were with Saul in Michmash and in the Mount of Bethel, and one thousand were with Jonathan in Gibeah of Benjamin: and the rest of the people he sent every man to his tent.",
                "Jonathan struck the garrison of the Philistines that was in Geba: and the Philistines heard of it. Saul blew the trumpet throughout all the land, saying, \"Let the Hebrews hear!\"",
                "All Israel heard that Saul had struck the garrison of the Philistines, and also that Israel was had in abomination with the Philistines. The people were gathered together after Saul to Gilgal.",
                "The Philistines assembled themselves together to fight with Israel, thirty thousand chariots, and six thousand horsemen, and people as the sand which is on the seashore in multitude: and they came up, and encamped in Michmash, eastward of Beth Aven.",
                "When the men of Israel saw that they were in a strait (for the people were distressed), then the people hid themselves in caves, and in thickets, and in rocks, and in coverts, and in pits.",
                "Now some of the Hebrews had gone over the Jordan to the land of Gad and Gilead; but as for Saul, he was yet in Gilgal, and all the people followed him trembling.",
                "He stayed seven days, according to the time set by Samuel: but Samuel didn\u2019t come to Gilgal; and the people were scattered from him.",
                "Saul said, \"Bring here the burnt offering to me, and the peace offerings.\" He offered the burnt offering.",
                "It came to pass that as soon as he had made an end of offering the burnt offering, behold, Samuel came; and Saul went out to meet him, that he might greet him.",
                "Samuel said, \"What have you done?\" Saul said, \"Because I saw that the people were scattered from me, and that you didn\u2019t come within the days appointed, and that the Philistines assembled themselves together at Michmash;",
                "therefore I said, \u2018Now the Philistines will come down on me to Gilgal, and I haven\u2019t entreated the favor of Yahweh.\u2019 I forced myself therefore, and offered the burnt offering.\"",
                "Samuel said to Saul, \"You have done foolishly. You have not kept the commandment of Yahweh your God, which he commanded you; for now Yahweh would have established your kingdom on Israel forever.",
                "But now your kingdom shall not continue. Yahweh has sought for himself a man after his own heart, and Yahweh has appointed him to be prince over his people, because you have not kept that which Yahweh commanded you.\"",
                "Samuel arose, and went from Gilgal to Gibeah of Benjamin. Saul numbered the people who were present with him, about six hundred men.",
                "Saul, and Jonathan his son, and the people who were present with them, stayed in Geba of Benjamin: but the Philistines encamped in Michmash.",
                "The spoilers came out of the camp of the Philistines in three companies: one company turned to the way that leads to Ophrah, to the land of Shual;",
                "and another company turned the way to Beth Horon; and another company turned the way of the border that looks down on the valley of Zeboim toward the wilderness.",
                "Now there was no smith found throughout all the land of Israel; for the Philistines said, \"Lest the Hebrews make them swords or spears;\"",
                "but all the Israelites went down to the Philistines, to sharpen every man his plowshare, mattock, axe, and sickle;",
                "yet they had a file for the mattocks, and for the plowshares, and for the forks, and for the axes, and to set the goads.",
                "So it came to pass in the day of battle, that there was neither sword nor spear found in the hand of any of the people who were with Saul and Jonathan: but with Saul and with Jonathan his son was there found.",
                "The garrison of the Philistines went out to the pass of Michmash."
            ],
            14: [
                "Now it fell on a day, that Jonathan the son of Saul said to the young man who bore his armor, \"Come, and let us go over to the Philistines\u2019 garrison, that is on the other side.\" But he didn\u2019t tell his father.",
                "Saul stayed in the uttermost part of Gibeah under the pomegranate tree which is in Migron: and the people who were with him were about six hundred men;",
                "and Ahijah, the son of Ahitub, Ichabod\u2019s brother, the son of Phinehas, the son of Eli, the priest of Yahweh in Shiloh, wearing an ephod. The people didn\u2019t know that Jonathan was gone.",
                "Between the passes, by which Jonathan sought to go over to the Philistines\u2019 garrison, there was a rocky crag on the one side, and a rocky crag on the other side: and the name of the one was Bozez, and the name of the other Seneh.",
                "The one crag rose up on the north in front of Michmash, and the other on the south in front of Geba.",
                "Jonathan said to the young man who bore his armor, \"Come, and let us go over to the garrison of these uncircumcised. It may be that Yahweh will work for us; for there is no restraint on Yahweh to save by many or by few.\"",
                "His armor bearer said to him, \"Do all that is in your heart. Turn and, behold, I am with you according to your heart.\"",
                "Then Jonathan said, \"Behold, we will pass over to the men, and we will reveal ourselves to them.",
                "If they say thus to us, \u2018Wait until we come to you!\u2019 then we will stand still in our place, and will not go up to them.",
                "But if they say this, \u2018Come up to us!\u2019 then we will go up; for Yahweh has delivered them into our hand. This shall be the sign to us.\"",
                "Both of them revealed themselves to the garrison of the Philistines: and the Philistines said, \"Behold, the Hebrews are coming out of the holes where they had hidden themselves!\"",
                "The men of the garrison answered Jonathan and his armor bearer, and said, \"Come up to us, and we will show you something!\" Jonathan said to his armor bearer, \"Come up after me; for Yahweh has delivered them into the hand of Israel.\"",
                "Jonathan climbed up on his hands and on his feet, and his armor bearer after him: and they fell before Jonathan; and his armor bearer killed them after him.",
                "That first slaughter, which Jonathan and his armor bearer made, was about twenty men, within as it were half a furrow\u2019s length in an acre of land.",
                "There was a trembling in the camp, in the field, and among all the people; the garrison, and the spoilers, they also trembled; and the earth quaked: so there was an exceeding great trembling.",
                "The watchmen of Saul in Gibeah of Benjamin looked; and behold, the multitude melted away, and scattered.",
                "Then Saul said to the people who were with him, \"Count now, and see who is missing from us.\" When they had counted, behold, Jonathan and his armor bearer were not there.",
                "Saul said to Ahijah, \"Bring the ark of God here.\" For the ark of God was with the children of Israel at that time.",
                "It happened, while Saul talked to the priest, that the tumult that was in the camp of the Philistines went on and increased: and Saul said to the priest, \"Withdraw your hand!\"",
                "Saul and all the people who were with him were gathered together, and came to the battle: and behold, every man\u2019s sword was against his fellow: a very great confusion.",
                "Now the Hebrews who were with the Philistines as before, and who went up with them into the camp, from all around, even they also turned to be with the Israelites who were with Saul and Jonathan.",
                "Likewise all the men of Israel who had hidden themselves in the hill country of Ephraim, when they heard that the Philistines fled, even they also followed hard after them in the battle.",
                "So Yahweh saved Israel that day: and the battle passed over by Beth Aven.",
                "The men of Israel were distressed that day; for Saul had adjured the people, saying, \"Cursed is the man who eats any food until it is evening, and I am avenged of my enemies.\" So none of the people tasted food.",
                "All the people came into the forest; and there was honey on the ground.",
                "When the people had come to the forest, behold, the honey dropped: but no man put his hand to his mouth; for the people feared the oath.",
                "But Jonathan didn\u2019t hear when his father commanded the people with the oath: therefore he put forth the end of the rod who was in his hand, and dipped it in the honeycomb, and put his hand to his mouth; and his eyes were enlightened.",
                "Then one of the people answered, and said, \"Your father directly commanded the people with an oath, saying, \u2018Cursed is the man who eats food this day.\u2019\" The people were faint.",
                "Then Jonathan said, \"My father has troubled the land. Please look how my eyes have been enlightened, because I tasted a little of this honey.",
                "How much more, if perhaps the people had eaten freely today of the spoil of their enemies which they found? For now has there been no great slaughter among the Philistines.\"",
                "They struck of the Philistines that day from Michmash to Aijalon. The people were very faint;",
                "and the people flew on the spoil, and took sheep, and cattle, and calves, and killed them on the ground; and the people ate them with the blood.",
                "Then they told Saul, saying, \"Behold, the people are sinning against Yahweh, in that they eat meat with the blood.\" He said, \"You have dealt treacherously. Roll a large stone to me this day!\"",
                "Saul said, \"Disperse yourselves among the people, and tell them, \u2018Bring me here every man his ox, and every man his sheep, and kill them here, and eat; and don\u2019t sin against Yahweh in eating meat with the blood.\u2019\" All the people brought every man his ox with him that night, and killed them there.",
                "Saul built an altar to Yahweh. This was the first altar that he built to Yahweh.",
                "Saul said, \"Let us go down after the Philistines by night, and take spoil among them until the morning light, and let us not leave a man of them.\" They said, \"Do whatever seems good to you.\" Then the priest said, \"Let us draw near here to God.\"",
                "Saul asked counsel of God, \"Shall I go down after the Philistines? Will you deliver them into the hand of Israel?\" But he didn\u2019t answer him that day.",
                "Saul said, \"Draw near here, all you chiefs of the people; and know and see in which this sin has been this day.",
                "For, as Yahweh lives, who saves Israel, though it is in Jonathan my son, he shall surely die.\" But there was not a man among all the people who answered him.",
                "Then he said to all Israel, \"You be on one side, and I and Jonathan my son will be on the other side.\" The people said to Saul, \"Do what seems good to you.\"",
                "Therefore Saul said to Yahweh, the God of Israel, \"Show the right.\" Jonathan and Saul were chosen; but the people escaped.",
                "Saul said, \"Cast lots between me and Jonathan my son.\" Jonathan was selected.",
                "Then Saul said to Jonathan, \"Tell me what you have done!\" Jonathan told him, and said, \"I certainly did taste a little honey with the end of the rod that was in my hand; and behold, I must die.\"",
                "Saul said, \"God do so and more also; for you shall surely die, Jonathan.\"",
                "The people said to Saul, \"Shall Jonathan die, who has worked this great salvation in Israel? Far from it! As Yahweh lives, there shall not one hair of his head fall to the ground; for he has worked with God this day!\" So the people rescued Jonathan, that he didn\u2019t die.",
                "Then Saul went up from following the Philistines; and the Philistines went to their own place.",
                "Now when Saul had taken the kingdom over Israel, he fought against all his enemies on every side, against Moab, and against the children of Ammon, and against Edom, and against the kings of Zobah, and against the Philistines: and wherever he turned himself, he defeated them.",
                "He did valiantly, and struck the Amalekites, and delivered Israel out of the hands of those who despoiled them.",
                "Now the sons of Saul were Jonathan, and Ishvi, and Malchishua; and the names of his two daughters were these: the name of the firstborn Merab, and the name of the younger Michal:",
                "and the name of Saul\u2019s wife was Ahinoam the daughter of Ahimaaz. The name of the captain of his army was Abner the son of Ner, Saul\u2019s uncle.",
                "Kish was the father of Saul; and Ner the father of Abner was the son of Abiel.",
                "There was severe war against the Philistines all the days of Saul: and when Saul saw any mighty man, or any valiant man, he took him to him."
            ],
            15: [
                "Samuel said to Saul, \"Yahweh sent me to anoint you to be king over his people, over Israel. Now therefore listen to the voice of the words of Yahweh.",
                "Thus says Yahweh of Armies, \u2018I have marked that which Amalek did to Israel, how he set himself against him in the way, when he came up out of Egypt.",
                "Now go and strike Amalek, and utterly destroy all that they have, and don\u2019t spare them; but kill both man and woman, infant and nursing baby, ox and sheep, camel and donkey.\u2019\"",
                "Saul summoned the people, and numbered them in Telaim, two hundred thousand footmen, and ten thousand men of Judah.",
                "Saul came to the city of Amalek, and laid wait in the valley.",
                "Saul said to the Kenites, \"Go, depart, go down from among the Amalekites, lest I destroy you with them; for you showed kindness to all the children of Israel, when they came up out of Egypt.\" So the Kenites departed from among the Amalekites.",
                "Saul struck the Amalekites, from Havilah as you go to Shur, that is before Egypt.",
                "He took Agag the king of the Amalekites alive, and utterly destroyed all the people with the edge of the sword.",
                "But Saul and the people spared Agag, and the best of the sheep, and of the cattle, and of the fatlings, and the lambs, and all that was good, and wouldn\u2019t utterly destroy them: but everything that was vile and refuse, that they destroyed utterly.",
                "Then the word of Yahweh came to Samuel, saying,",
                "\"It grieves me that I have set up Saul to be king; for he is turned back from following me, and has not performed my commandments.\" Samuel was angry; and he cried to Yahweh all night.",
                "Samuel rose early to meet Saul in the morning; and it was told Samuel, saying, \"Saul came to Carmel, and behold, he set up a monument for himself, and turned, and passed on, and went down to Gilgal.\"",
                "Samuel came to Saul; and Saul said to him, \"You are blessed by Yahweh! I have performed the commandment of Yahweh.\"",
                "Samuel said, \"Then what does this bleating of the sheep in my ears, and the lowing of the cattle which I hear mean?\"",
                "Saul said, \"They have brought them from the Amalekites; for the people spared the best of the sheep and of the cattle, to sacrifice to Yahweh your God. We have utterly destroyed the rest.\"",
                "Then Samuel said to Saul, \"Stay, and I will tell you what Yahweh has said to me last night.\" He said to him, \"Say on.\"",
                "Samuel said, \"Though you were little in your own sight, weren\u2019t you made the head of the tribes of Israel? Yahweh anointed you king over Israel;",
                "and Yahweh sent you on a journey, and said, \u2018Go, and utterly destroy the sinners the Amalekites, and fight against them until they are consumed.\u2019",
                "Why then didn\u2019t you obey the voice of Yahweh, but took the spoils, and did that which was evil in the sight of Yahweh?\"",
                "Saul said to Samuel, \"But I have obeyed the voice of Yahweh, and have gone the way which Yahweh sent me, and have brought Agag the king of Amalek, and have utterly destroyed the Amalekites.",
                "But the people took of the spoil, sheep and cattle, the chief of the devoted things, to sacrifice to Yahweh your God in Gilgal.\"",
                "Samuel said, \"Has Yahweh as great delight in burnt offerings and sacrifices, as in obeying the voice of Yahweh? Behold, to obey is better than sacrifice, and to listen than the fat of rams.",
                "For rebellion is as the sin of witchcraft, and stubbornness is as idolatry and teraphim. Because you have rejected the word of Yahweh, he has also rejected you from being king.\"",
                "Saul said to Samuel, \"I have sinned; for I have transgressed the commandment of Yahweh, and your words, because I feared the people, and obeyed their voice.",
                "Now therefore, please pardon my sin, and turn again with me, that I may worship Yahweh.\"",
                "Samuel said to Saul, \"I will not return with you; for you have rejected the word of Yahweh, and Yahweh has rejected you from being king over Israel.\"",
                "As Samuel turned about to go away, Saul grabbed the skirt of his robe, and it tore.",
                "Samuel said to him, \"Yahweh has torn the kingdom of Israel from you this day, and has given it to a neighbor of yours who is better than you.",
                "Also the Strength of Israel will not lie nor repent; for he is not a man, that he should repent.\"",
                "Then he said, \"I have sinned: yet please honor me now before the elders of my people, and before Israel, and come back with me, that I may worship Yahweh your God.\"",
                "So Samuel went back with Saul; and Saul worshiped Yahweh.",
                "Then Samuel said, \"Bring here to me Agag the king of the Amalekites!\" Agag came to him cheerfully. Agag said, \"Surely the bitterness of death is past.\"",
                "Samuel said, \"As your sword has made women childless, so your mother will be childless among women!\" Samuel cut Agag in pieces before Yahweh in Gilgal.",
                "Then Samuel went to Ramah; and Saul went up to his house to Gibeah of Saul.",
                "Samuel came no more to see Saul until the day of his death; for Samuel mourned for Saul: and Yahweh grieved that he had made Saul king over Israel."
            ],
            16: [
                "Yahweh said to Samuel, \"How long will you mourn for Saul, since I have rejected him from being king over Israel? Fill your horn with oil, and go. I will send you to Jesse the Bethlehemite; for I have provided a king for myself among his sons.\"",
                "Samuel said, \"How can I go? If Saul hears it, he will kill me.\" Yahweh said, \"Take a heifer with you, and say, I have come to sacrifice to Yahweh.",
                "Call Jesse to the sacrifice, and I will show you what you shall do. You shall anoint to me him whom I name to you.\"",
                "Samuel did that which Yahweh spoke, and came to Bethlehem. The elders of the city came to meet him trembling, and said, \"Do you come peaceably?\"",
                "He said, \"Peaceably; I have come to sacrifice to Yahweh. Sanctify yourselves, and come with me to the sacrifice.\" He sanctified Jesse and his sons, and called them to the sacrifice.",
                "It happened, when they had come, that he looked at Eliab, and said, \"Surely Yahweh\u2019s anointed is before him.\"",
                "But Yahweh said to Samuel, \"Don\u2019t look on his face, or on the height of his stature; because I have rejected him: for I see not as man sees; for man looks at the outward appearance, but Yahweh looks at the heart.\"",
                "Then Jesse called Abinadab, and made him pass before Samuel. He said, \"Neither has Yahweh chosen this one.\"",
                "Then Jesse made Shammah to pass by. He said, \"Neither has Yahweh chosen this one.\"",
                "Jesse made seven of his sons to pass before Samuel. Samuel said to Jesse, \"Yahweh has not chosen these.\"",
                "Samuel said to Jesse, \"Are all your children here?\" He said, \"There remains yet the youngest, and behold, he is keeping the sheep.\" Samuel said to Jesse, \"Send and get him; for we will not sit down until he comes here.\"",
                "He sent, and brought him in. Now he was ruddy, and withal of a beautiful face, and goodly to look on. Yahweh said, \"Arise, anoint him; for this is he.\"",
                "Then Samuel took the horn of oil, and anointed him in the midst of his brothers: and the Spirit of Yahweh came mightily on David from that day forward. So Samuel rose up, and went to Ramah.",
                "Now the Spirit of Yahweh departed from Saul, and an evil spirit from Yahweh troubled him.",
                "Saul\u2019s servants said to him, \"See now, an evil spirit from God troubles you.",
                "Let our lord now command your servants who are before you, to seek out a man who is a skillful player on the harp. It shall happen, when the evil spirit from God is on you, that he shall play with his hand, and you shall be well.\"",
                "Saul said to his servants, \"Provide me now a man who can play well, and bring him to me.\"",
                "Then one of the young men answered, and said, \"Behold, I have seen a son of Jesse the Bethlehemite, who is skillful in playing, a mighty man of valor, a man of war, prudent in speech, and a comely person; and Yahweh is with him.\"",
                "Therefore Saul sent messengers to Jesse, and said, \"Send me David your son, who is with the sheep.\"",
                "Jesse took a donkey loaded with bread, and a bottle of wine, and a young goat, and sent them by David his son to Saul.",
                "David came to Saul, and stood before him. He loved him greatly; and he became his armor bearer.",
                "Saul sent to Jesse, saying, \"Please let David stand before me; for he has found favor in my sight.\"",
                "It happened, when the spirit from God was on Saul, that David took the harp, and played with his hand: so Saul was refreshed, and was well, and the evil spirit departed from him."
            ],
            17: [
                "Now the Philistines gathered together their armies to battle; and they were gathered together at Socoh, which belongs to Judah, and encamped between Socoh and Azekah, in Ephesdammim.",
                "Saul and the men of Israel were gathered together, and encamped in the valley of Elah, and set the battle in array against the Philistines.",
                "The Philistines stood on the mountain on the one side, and Israel stood on the mountain on the other side: and there was a valley between them.",
                "There went out a champion out of the camp of the Philistines, named Goliath, of Gath, whose height was six cubits and a span.",
                "He had a helmet of brass on his head, and he was clad with a coat of mail; and the weight of the coat was five thousand shekels of brass.",
                "He had brass shin armor on his legs, and a javelin of brass between his shoulders.",
                "The staff of his spear was like a weaver\u2019s beam; and his spear\u2019s head weighed six hundred shekels of iron: and his shield bearer went before him.",
                "He stood and cried to the armies of Israel, and said to them, \"Why have you come out to set your battle in array? Am I not a Philistine, and you servants to Saul? Choose a man for yourselves, and let him come down to me.",
                "If he be able to fight with me, and kill me, then will we be your servants; but if I prevail against him, and kill him, then you will be our servants, and serve us.\"",
                "The Philistine said, \"I defy the armies of Israel this day! Give me a man, that we may fight together!\"",
                "When Saul and all Israel heard those words of the Philistine, they were dismayed, and greatly afraid.",
                "Now David was the son of that Ephrathite of Bethlehem Judah, whose name was Jesse; and he had eight sons: and the man was an old man in the days of Saul, stricken among men.",
                "The three eldest sons of Jesse had gone after Saul to the battle: and the names of his three sons who went to the battle were Eliab the firstborn, and next to him Abinadab, and the third Shammah.",
                "David was the youngest; and the three eldest followed Saul.",
                "Now David went back and forth from Saul to feed his father\u2019s sheep at Bethlehem.",
                "The Philistine drew near morning and evening, and presented himself forty days.",
                "Jesse said to David his son, \"Now take for your brothers an ephah of this parched grain, and these ten loaves, and carry them quickly to the camp to your brothers;",
                "and bring these ten cheeses to the captain of their thousand, and see how your brothers are doing, and bring back news.\"",
                "Now Saul, and they, and all the men of Israel, were in the valley of Elah, fighting with the Philistines.",
                "David rose up early in the morning, and left the sheep with a keeper, and took, and went, as Jesse had commanded him; and he came to the place of the wagons, as the army which was going forth to the fight shouted for the battle.",
                "Israel and the Philistines put the battle in array, army against army.",
                "David left his baggage in the hand of the keeper of the baggage, and ran to the army, and came and greeted his brothers.",
                "As he talked with them, behold, there came up the champion, the Philistine of Gath, Goliath by name, out of the ranks of the Philistines, and spoke according to the same words: and David heard them.",
                "All the men of Israel, when they saw the man, fled from him, and were terrified.",
                "The men of Israel said, \"Have you seen this man who has come up? He has surely come up to defy Israel. It shall be, that the man who kills him, the king will enrich him with great riches, and will give him his daughter, and make his father\u2019s house free in Israel.\"",
                "David spoke to the men who stood by him, saying, \"What shall be done to the man who kills this Philistine, and takes away the reproach from Israel? For who is this uncircumcised Philistine, that he should defy the armies of the living God?\"",
                "The people answered him in this way, saying, \"So shall it be done to the man who kills him.\"",
                "Eliab his eldest brother heard when he spoke to the men; and Eliab\u2019s anger was kindled against David, and he said, \"Why have you come down? With whom have you left those few sheep in the wilderness? I know your pride, and the naughtiness of your heart; for you have come down that you might see the battle.\"",
                "David said, \"What have I now done? Is there not a cause?\"",
                "He turned away from him toward another, and spoke like that again; and the people answered him again the same way.",
                "When the words were heard which David spoke, they rehearsed them before Saul; and he sent for him.",
                "David said to Saul, \"Let no man\u2019s heart fail because of him. Your servant will go and fight with this Philistine.\"",
                "Saul said to David, \"You are not able to go against this Philistine to fight with him; for you are but a youth, and he a man of war from his youth.\"",
                "David said to Saul, \"Your servant was keeping his father\u2019s sheep; and when a lion or a bear came, and took a lamb out of the flock,",
                "I went out after him, and struck him, and rescued it out of his mouth. When he arose against me, I caught him by his beard, and struck him, and killed him.",
                "Your servant struck both the lion and the bear. This uncircumcised Philistine shall be as one of them, since he has defied the armies of the living God.\"",
                "David said, \"Yahweh who delivered me out of the paw of the lion, and out of the paw of the bear, he will deliver me out of the hand of this Philistine.\" Saul said to David, \"Go; and Yahweh shall be with you.\"",
                "Saul dressed David with his clothing. He put a helmet of brass on his head, and he clad him with a coat of mail.",
                "David strapped his sword on his clothing, and he tried to move; for he had not tested it. David said to Saul, \"I can\u2019t go with these; for I have not tested them.\" David took them off.",
                "He took his staff in his hand, and chose for himself five smooth stones out of the brook, and put them in the shepherd\u2019s bag which he had, even in his wallet. His sling was in his hand; and he drew near to the Philistine.",
                "The Philistine came on and drew near to David; and the man who bore the shield went before him.",
                "When the Philistine looked about, and saw David, he disdained him; for he was but a youth, and ruddy, and withal of a fair face.",
                "The Philistine said to David, \"Am I a dog, that you come to me with sticks?\" The Philistine cursed David by his gods.",
                "The Philistine said to David, \"Come to me, and I will give your flesh to the birds of the sky, and to the animals of the field.\"",
                "Then David said to the Philistine, \"You come to me with a sword, and with a spear, and with a javelin: but I come to you in the name of Yahweh of Armies, the God of the armies of Israel, whom you have defied.",
                "Today, Yahweh will deliver you into my hand. I will strike you, and take your head from off you. I will give the dead bodies of the army of the Philistines this day to the birds of the sky, and to the wild animals of the earth; that all the earth may know that there is a God in Israel,",
                "and that all this assembly may know that Yahweh doesn\u2019t save with sword and spear: for the battle is Yahweh\u2019s, and he will give you into our hand.\"",
                "It happened, when the Philistine arose, and came and drew near to meet David, that David hurried, and ran toward the army to meet the Philistine.",
                "David put his hand in his bag, took a stone, and slung it, and struck the Philistine in his forehead; and the stone sank into his forehead, and he fell on his face to the earth.",
                "So David prevailed over the Philistine with a sling and with a stone, and struck the Philistine, and killed him; but there was no sword in the hand of David.",
                "Then David ran, and stood over the Philistine, and took his sword, and drew it out of its sheath, and killed him, and cut off his head therewith. When the Philistines saw that their champion was dead, they fled.",
                "The men of Israel and of Judah arose, and shouted, and pursued the Philistines, until you come to Gai, and to the gates of Ekron. The wounded of the Philistines fell down by the way to Shaaraim, even to Gath, and to Ekron.",
                "The children of Israel returned from chasing after the Philistines, and they plundered their camp.",
                "David took the head of the Philistine, and brought it to Jerusalem; but he put his armor in his tent.",
                "When Saul saw David go forth against the Philistine, he said to Abner, the captain of the army, \"Abner, whose son is this youth?\" Abner said, \"As your soul lives, O king, I can\u2019t tell.\"",
                "The king said, \"Inquire whose son the young man is!\"",
                "As David returned from the slaughter of the Philistine, Abner took him, and brought him before Saul with the head of the Philistine in his hand.",
                "Saul said to him, \"Whose son are you, you young man?\" David answered, \"I am the son of your servant Jesse the Bethlehemite.\""
            ],
            18: [
                "It happened, when he had made an end of speaking to Saul, that the soul of Jonathan was knit with the soul of David, and Jonathan loved him as his own soul.",
                "Saul took him that day, and would let him go no more home to his father\u2019s house.",
                "Then Jonathan and David made a covenant, because he loved him as his own soul.",
                "Jonathan stripped himself of the robe that was on him, and gave it to David, and his clothing, even to his sword, and to his bow, and to his sash.",
                "David went out wherever Saul sent him, and behaved himself wisely: and Saul set him over the men of war, and it was good in the sight of all the people, and also in the sight of Saul\u2019s servants.",
                "It happened as they came, when David returned from the slaughter of the Philistine, that the women came out of all the cities of Israel, singing and dancing, to meet king Saul, with tambourines, with joy, and with instruments of music.",
                "The women sang one to another as they played, and said, \"Saul has slain his thousands, David his ten thousands.\"",
                "Saul was very angry, and this saying displeased him; and he said, \"They have ascribed to David ten thousands, and to me they have ascribed but thousands. What can he have more but the kingdom?\"",
                "Saul eyed David from that day and forward.",
                "It happened on the next day, that an evil spirit from God came mightily on Saul, and he prophesied in the midst of the house. David played with his hand, as he did day by day. Saul had his spear in his hand;",
                "and Saul threw the spear; for he said, \"I will pin David even to the wall!\" David escaped from his presence twice.",
                "Saul was afraid of David, because Yahweh was with him, and was departed from Saul.",
                "Therefore Saul removed him from him, and made him his captain over a thousand; and he went out and came in before the people.",
                "David behaved himself wisely in all his ways; and Yahweh was with him.",
                "When Saul saw that he behaved himself very wisely, he stood in awe of him.",
                "But all Israel and Judah loved David; for he went out and came in before them.",
                "Saul said to David, \"Behold, my elder daughter Merab, I will give her to you as wife. Only be valiant for me, and fight Yahweh\u2019s battles.\" For Saul said, \"Don\u2019t let my hand be on him, but let the hand of the Philistines be on him.\"",
                "David said to Saul, \"Who am I, and what is my life, or my father\u2019s family in Israel, that I should be son-in-law to the king?\"",
                "But it happened at the time when Merab, Saul\u2019s daughter, should have been given to David, that she was given to Adriel the Meholathite as wife.",
                "Michal, Saul\u2019s daughter, loved David; and they told Saul, and the thing pleased him.",
                "Saul said, I will give her to him, that she may be a snare to him, and that the hand of the Philistines may be against him. Therefore Saul said to David, \"You shall this day be my son-in-law a second time.\"",
                "Saul commanded his servants, \"Talk with David secretly, and say, \u2018Behold, the king has delight in you, and all his servants love you: now therefore be the king\u2019s son-in-law.\u2019\"",
                "Saul\u2019s servants spoke those words in the ears of David. David said, \"Does it seems to you a light thing to be the king\u2019s son-in-law, since I am a poor man, and lightly esteemed?\"",
                "The servants of Saul told him, saying, \"David spoke like this.\"",
                "Saul said, \"You shall tell David, \u2018The king desires no dowry except one hundred foreskins of the Philistines, to be avenged of the king\u2019s enemies.\u2019\" Now Saul thought to make David fall by the hand of the Philistines.",
                "When his servants told David these words, it pleased David well to be the king\u2019s son-in-law. The days were not expired;",
                "and David arose and went, he and his men, and killed of the Philistines two hundred men; and David brought their foreskins, and they gave them in full number to the king, that he might be the king\u2019s son-in-law. Saul gave him Michal his daughter as wife.",
                "Saul saw and knew that Yahweh was with David; and Michal, Saul\u2019s daughter, loved him.",
                "Saul was yet the more afraid of David; and Saul was David\u2019s enemy continually.",
                "Then the princes of the Philistines went forth: and it happened, as often as they went forth, that David behaved himself more wisely than all the servants of Saul; so that his name was highly esteemed."
            ],
            19: [
                "Saul spoke to Jonathan his son, and to all his servants, that they should kill David. But Jonathan, Saul\u2019s son, delighted much in David.",
                "Jonathan told David, saying, \"Saul my father seeks to kill you. Now therefore, please take care of yourself in the morning, and live in a secret place, and hide yourself.",
                "I will go out and stand beside my father in the field where you are, and I will talk with my father about you; and if I see anything, I will tell you.\"",
                "Jonathan spoke good of David to Saul his father, and said to him, \"Don\u2019t let the king sin against his servant, against David; because he has not sinned against you, and because his works have been very good toward you;",
                "for he put his life in his hand, and struck the Philistine, and Yahweh worked a great victory for all Israel. You saw it, and rejoiced. Why then will you sin against innocent blood, to kill David without a cause?\"",
                "Saul listened to the voice of Jonathan: and Saul swore, \"As Yahweh lives, he shall not be put to death.\"",
                "Jonathan called David, and Jonathan showed him all those things. Jonathan brought David to Saul, and he was in his presence, as before.",
                "There was war again. David went out, and fought with the Philistines, and killed them with a great slaughter; and they fled before him.",
                "An evil spirit from Yahweh was on Saul, as he sat in his house with his spear in his hand; and David was playing with his hand.",
                "Saul sought to pin David even to the wall with the spear; but he slipped away out of Saul\u2019s presence, and he stuck the spear into the wall. David fled, and escaped that night.",
                "Saul sent messengers to David\u2019s house, to watch him, and to kill him in the morning. Michal, David\u2019s wife, told him, saying, \"If you don\u2019t save your life tonight, tomorrow you will be killed.\"",
                "So Michal let David down through the window. He went, fled, and escaped.",
                "Michal took the teraphim, and laid it in the bed, and put a pillow of goats\u2019 hair at its head, and covered it with the clothes.",
                "When Saul sent messengers to take David, she said, \"He is sick.\"",
                "Saul sent the messengers to see David, saying, \"Bring him up to me in the bed, that I may kill him.\"",
                "When the messengers came in, behold, the teraphim was in the bed, with the pillow of goats\u2019 hair at its head.",
                "Saul said to Michal, \"Why have you deceived me thus, and let my enemy go, so that he is escaped?\" Michal answered Saul, \"He said to me, \u2018Let me go! Why should I kill you?\u2019\"",
                "Now David fled, and escaped, and came to Samuel to Ramah, and told him all that Saul had done to him. He and Samuel went and lived in Naioth.",
                "It was told Saul, saying, \"Behold, David is at Naioth in Ramah.\"",
                "Saul sent messengers to take David: and when they saw the company of the prophets prophesying, and Samuel standing as head over them, the Spirit of God came on the messengers of Saul, and they also prophesied.",
                "When it was told Saul, he sent other messengers, and they also prophesied. Saul sent messengers again the third time, and they also prophesied.",
                "Then went he also to Ramah, and came to the great well that is in Secu: and he asked, \"Where are Samuel and David?\" One said, \"Behold, they are at Naioth in Ramah.\"",
                "He went there to Naioth in Ramah. Then the Spirit of God came on him also, and he went on, and prophesied, until he came to Naioth in Ramah.",
                "He also stripped off his clothes, and he also prophesied before Samuel, and lay down naked all that day and all that night. Therefore they say, \"Is Saul also among the prophets?\""
            ],
            20: [
                "David fled from Naioth in Ramah, and came and said before Jonathan, \"What have I done? What is my iniquity? What is my sin before your father, that he seeks my life?\"",
                "He said to him, \"Far from it; you shall not die. Behold, my father does nothing either great or small, but that he discloses it to me; and why should my father hide this thing from me? It is not so.\"",
                "David swore moreover, and said, \"Your father knows well that I have found favor in your eyes; and he says, \u2018Don\u2019t let Jonathan know this, lest he be grieved:\u2019 but truly as Yahweh lives, and as your soul lives, there is but a step between me and death.\"",
                "Then Jonathan said to David, \"Whatever your soul desires, I will even do it for you.\"",
                "David said to Jonathan, \"Behold, tomorrow is the new moon, and I should not fail to dine with the king; but let me go, that I may hide myself in the field to the third day at evening.",
                "If your father miss me at all, then say, \u2018David earnestly asked leave of me that he might run to Bethlehem his city; for it is the yearly sacrifice there for all the family.\u2019",
                "If he says, \u2018It is well;\u2019 your servant shall have peace: but if he be angry, then know that evil is determined by him.",
                "Therefore deal kindly with your servant; for you have brought your servant into a covenant of Yahweh with you: but if there is iniquity in me, kill me yourself; for why should you bring me to your father?\"",
                "Jonathan said, \"Far be it from you; for if I should at all know that evil were determined by my father to come on you, then wouldn\u2019t I tell you that?\"",
                "Then David said to Jonathan, \"Who shall tell me if perchance your father answers you roughly?\"",
                "Jonathan said to David, \"Come, and let us go out into the field.\" They both went out into the field.",
                "Jonathan said to David, \"By Yahweh, the God of Israel, when I have sounded my father about this time tomorrow, or the third day, behold, if there is good toward David, shall I not then send to you, and disclose it to you?",
                "Yahweh do so to Jonathan, and more also, should it please my father to do you evil, if I don\u2019t disclose it to you, and send you away, that you may go in peace: and Yahweh be with you, as he has been with my father.",
                "You shall not only while yet I live show me the loving kindness of Yahweh, that I not die;",
                "but also you shall not cut off your kindness from my house forever; no, not when Yahweh has cut off the enemies of David everyone from the surface of the earth.\"",
                "So Jonathan made a covenant with the house of David, saying, \"Yahweh will require it at the hand of David\u2019s enemies.\"",
                "Jonathan caused David to swear again, for the love that he had to him; for he loved him as he loved his own soul.",
                "Then Jonathan said to him, \"Tomorrow is the new moon: and you will be missed, because your seat will be empty.",
                "When you have stayed three days, you shall go down quickly, and come to the place where you hid yourself when this started, and shall remain by the stone Ezel.",
                "I will shoot three arrows on its side, as though I shot at a mark.",
                "Behold, I will send the boy, saying, \u2018Go, find the arrows!\u2019 If I tell the boy, \u2018Behold, the arrows are on this side of you. Take them;\u2019 then come; for there is peace to you and no hurt, as Yahweh lives.",
                "But if I say this to the boy, \u2018Behold, the arrows are beyond you;\u2019 then go your way; for Yahweh has sent you away.",
                "Concerning the matter which you and I have spoken of, behold, Yahweh is between you and me forever.\"",
                "So David hid himself in the field: and when the new moon had come, the king sat him down to eat food.",
                "The king sat on his seat, as at other times, even on the seat by the wall; and Jonathan stood up, and Abner sat by Saul\u2019s side: but David\u2019s place was empty.",
                "Nevertheless Saul didn\u2019t say anything that day: for he thought, \"Something has happened to him. He is not clean. Surely he is not clean.\"",
                "It happened on the next day after the new moon, the second day, that David\u2019s place was empty. Saul said to Jonathan his son, \"Why doesn\u2019t the son of Jesse come to eat, neither yesterday, nor today?\"",
                "Jonathan answered Saul, \"David earnestly asked leave of me to go to Bethlehem.",
                "He said, \u2018Please let me go, for our family has a sacrifice in the city. My brother has commanded me to be there. Now, if I have found favor in your eyes, please let me go away and see my brothers.\u2019 Therefore he has not come to the king\u2019s table.\"",
                "Then Saul\u2019s anger was kindled against Jonathan, and he said to him, \"You son of a perverse rebellious woman, don\u2019t I know that you have chosen the son of Jesse to your own shame, and to the shame of your mother\u2019s nakedness?",
                "For as long as the son of Jesse lives on the earth, you shall not be established, nor your kingdom. Therefore now send and bring him to me, for he shall surely die!\"",
                "Jonathan answered Saul his father, and said to him, \"Why should he be put to death? What has he done?\"",
                "Saul cast his spear at him to strike him. By this Jonathan knew that his father was determined to put David to death.",
                "So Jonathan arose from the table in fierce anger, and ate no food the second day of the month; for he was grieved for David, because his father had done him shame.",
                "It happened in the morning, that Jonathan went out into the field at the time appointed with David, and a little boy with him.",
                "He said to his boy, \"Run, find now the arrows which I shoot.\" As the boy ran, he shot an arrow beyond him.",
                "When the boy had come to the place of the arrow which Jonathan had shot, Jonathan cried after the boy, and said, \"Isn\u2019t the arrow beyond you?\"",
                "Jonathan cried after the boy, \"Go fast! Hurry! Don\u2019t delay!\" Jonathan\u2019s boy gathered up the arrows, and came to his master.",
                "But the boy didn\u2019t know anything. Only Jonathan and David knew the matter.",
                "Jonathan gave his weapons to his boy, and said to him, \"Go, carry them to the city.\"",
                "As soon as the boy was gone, David arose out of the south, and fell on his face to the ground, and bowed himself three times. They kissed one another, and wept one with another, and David wept the most.",
                "Jonathan said to David, \"Go in peace, because we have both sworn in the name of Yahweh, saying, \u2018Yahweh shall be between me and you, and between my seed and your seed, forever.\u2019\" He arose and departed; and Jonathan went into the city."
            ],
            21: [
                "Then came David to Nob to Ahimelech the priest. Ahimelech came to meet David trembling, and said to him, \"Why are you alone, and no man with you?\"",
                "David said to Ahimelech the priest, \"The king has commanded me a business, and has said to me, \u2018Let no man know anything of the business about which I send you, and what I have commanded you; and I have appointed the young men to such and such a place.\u2019",
                "Now therefore what is under your hand? Give me five loaves of bread in my hand, or whatever there is present.\"",
                "The priest answered David, and said, \"There is no common bread under my hand, but there is holy bread; if only the young men have kept themselves from women.\"",
                "David answered the priest, and said to him, \"Truly, women have been kept from us about these three days. When I came out, the vessels of the young men were holy, though it was but a common journey. How much more then today shall their vessels be holy?\"",
                "So the priest gave him holy bread; for there was no bread there but the show bread, that was taken from before Yahweh, to put hot bread in the day when it was taken away.",
                "Now a certain man of the servants of Saul was there that day, detained before Yahweh; and his name was Doeg the Edomite, the best of the herdsmen who belonged to Saul.",
                "David said to Ahimelech, \"Isn\u2019t there here under your hand spear or sword? For I have neither brought my sword nor my weapons with me, because the king\u2019s business required haste.\"",
                "The priest said, \"The sword of Goliath the Philistine, whom you killed in the valley of Elah, behold, it is here wrapped in a cloth behind the ephod. If you will take that, take it; for there is no other except that here.\" David said, \"There is none like that. Give it to me.\"",
                "David arose, and fled that day for fear of Saul, and went to Achish the king of Gath.",
                "The servants of Achish said to him, \"Isn\u2019t this David the king of the land? Didn\u2019t they sing one to another about him in dances, saying, \u2018Saul has slain his thousands, David his ten thousands?\u2019\"",
                "David laid up these words in his heart, and was very afraid of Achish the king of Gath.",
                "He changed his behavior before them, and pretended to be mad in their hands, and scrabbled on the doors of the gate, and let his spittle fall down on his beard.",
                "Then Achish said to his servants, \"Look, you see the man is mad. Why then have you brought him to me?",
                "Do I lack madmen, that you have brought this fellow to play the madman in my presence? Shall this fellow come into my house?\""
            ],
            22: [
                "David therefore departed there, and escaped to the cave of Adullam. When his brothers and all his father\u2019s house heard it, they went down there to him.",
                "Everyone who was in distress, and everyone who was in debt, and everyone who was discontented, gathered themselves to him; and he became captain over them: and there were with him about four hundred men.",
                "David went there to Mizpeh of Moab, and he said to the king of Moab, \"Please let my father and my mother come out with you, until I know what God will do for me.\"",
                "He brought them before the king of Moab; and they lived with him all the while that David was in the stronghold.",
                "The prophet Gad said to David, \"Don\u2019t stay in the stronghold. Depart, and go into the land of Judah.\" Then David departed, and came into the forest of Hereth.",
                "Saul heard that David was discovered, and the men who were with him. Now Saul was sitting in Gibeah, under the tamarisk tree in Ramah, with his spear in his hand, and all his servants were standing about him.",
                "Saul said to his servants who stood about him, \"Hear now, you Benjamites! Will the son of Jesse give everyone of you fields and vineyards, will he make you all captains of thousands and captains of hundreds,",
                "that all of you have conspired against me, and there is none who discloses to me when my son makes a treaty with the son of Jesse, and there is none of you who is sorry for me, or discloses to me that my son has stirred up my servant against me, to lie in wait, as at this day?\"",
                "Then Doeg the Edomite, who stood by the servants of Saul, answered and said, \"I saw the son of Jesse coming to Nob, to Ahimelech the son of Ahitub.",
                "He inquired of Yahweh for him, gave him food, and gave him the sword of Goliath the Philistine.\"",
                "Then the king sent to call Ahimelech the priest, the son of Ahitub, and all his father\u2019s house, the priests who were in Nob: and they came all of them to the king.",
                "Saul said, \"Hear now, you son of Ahitub.\" He answered, \"Here I am, my lord.\"",
                "Saul said to him, \"Why have you conspired against me, you and the son of Jesse, in that you have given him bread, and a sword, and have inquired of God for him, that he should rise against me, to lie in wait, as at this day?\"",
                "Then Ahimelech answered the king, and said, \"Who among all your servants is so faithful as David, who is the king\u2019s son-in-law, and is taken into your council, and is honorable in your house?",
                "Have I today begun to inquire of God for him? Be it far from me! Don\u2019t let the king impute anything to his servant, nor to all the house of my father; for your servant knows nothing of all this, less or more.\"",
                "The king said, \"You shall surely die, Ahimelech, you, and all your father\u2019s house.\"",
                "The king said to the guard who stood about him, \"Turn, and kill the priests of Yahweh; because their hand also is with David, and because they knew that he fled, and didn\u2019t disclose it to me.\" But the servants of the king wouldn\u2019t put forth their hand to fall on the priests of Yahweh.",
                "The king said to Doeg, \"Turn and attack the priests!\" Doeg the Edomite turned, and he attacked the priests, and he killed on that day eighty-five people who wore a linen ephod.",
                "He struck Nob, the city of the priests, with the edge of the sword, both men and women, children and nursing babies, and cattle and donkeys and sheep, with the edge of the sword.",
                "One of the sons of Ahimelech, the son of Ahitub, named Abiathar, escaped, and fled after David.",
                "Abiathar told David that Saul had slain Yahweh\u2019s priests.",
                "David said to Abiathar, \"I knew on that day, when Doeg the Edomite was there, that he would surely tell Saul. I am responsible for the death of all the persons of your father\u2019s house.",
                "Stay with me, don\u2019t be afraid; for he who seeks my life seeks your life. For with me you shall be in safeguard.\""
            ],
            23: [
                "David was told, \"Behold, the Philistines are fighting against Keilah, and are robbing the threshing floors.\"",
                "Therefore David inquired of Yahweh, saying, \"Shall I go and strike these Philistines?\" Yahweh said to David, \"Go strike the Philistines, and save Keilah.\"",
                "David\u2019s men said to him, \"Behold, we are afraid here in Judah: how much more then if we go to Keilah against the armies of the Philistines?\"",
                "Then David inquired of Yahweh yet again. Yahweh answered him, and said, \"Arise, go down to Keilah; for I will deliver the Philistines into your hand.\"",
                "David and his men went to Keilah, and fought with the Philistines, and brought away their livestock, and killed them with a great slaughter. So David saved the inhabitants of Keilah.",
                "It happened, when Abiathar the son of Ahimelech fled to David to Keilah, that he came down with an ephod in his hand.",
                "It was told Saul that David had come to Keilah. Saul said, \"God has delivered him into my hand; for he is shut in, by entering into a town that has gates and bars.\"",
                "Saul summoned all the people to war, to go down to Keilah, to besiege David and his men.",
                "David knew that Saul was devising mischief against him; and he said to Abiathar the priest, \"Bring the ephod here.\"",
                "Then David said, \"O Yahweh, the God of Israel, your servant has surely heard that Saul seeks to come to Keilah, to destroy the city for my sake.",
                "Will the men of Keilah deliver me up into his hand? Will Saul come down, as your servant has heard? Yahweh, the God of Israel, I beg you, tell your servant.\" Yahweh said, \"He will come down.\"",
                "Then David said, \"Will the men of Keilah deliver me and my men into the hand of Saul?\" Yahweh said, \"They will deliver you up.\"",
                "Then David and his men, who were about six hundred, arose and departed out of Keilah, and went wherever they could go. It was told Saul that David was escaped from Keilah; and he gave up going there.",
                "David stayed in the wilderness in the strongholds, and remained in the hill country in the wilderness of Ziph. Saul sought him every day, but God didn\u2019t deliver him into his hand.",
                "David saw that Saul had come out to seek his life. David was in the wilderness of Ziph in the wood.",
                "Jonathan, Saul\u2019s son, arose, and went to David into the woods, and strengthened his hand in God.",
                "He said to him, \"Don\u2019t be afraid; for the hand of Saul my father shall not find you; and you shall be king over Israel, and I shall be next to you; and that also Saul my father knows.\"",
                "They both made a covenant before Yahweh: and David stayed in the woods, and Jonathan went to his house.",
                "Then the Ziphites came up to Saul to Gibeah, saying, \"Doesn\u2019t David hide himself with us in the strongholds in the wood, in the hill of Hachilah, which is on the south of the desert?",
                "Now therefore, O king, come down, according to all the desire of your soul to come down; and our part shall be to deliver him up into the king\u2019s hand.\"",
                "Saul said, \"You are blessed by Yahweh; for you have had compassion on me.",
                "Please go make yet more sure, and know and see his place where his haunt is, and who has seen him there; for it is told me that he deals very subtly.",
                "See therefore, and take knowledge of all the lurking places where he hides himself, and come again to me with certainty, and I will go with you: and it shall happen, if he is in the land, that I will search him out among all the thousands of Judah.\"",
                "They arose, and went to Ziph before Saul: but David and his men were in the wilderness of Maon, in the Arabah on the south of the desert.",
                "Saul and his men went to seek him. When David was told, he went down to the rock, and stayed in the wilderness of Maon. When Saul heard that, he pursued David in the wilderness of Maon.",
                "Saul went on this side of the mountain, and David and his men on that side of the mountain: and David made haste to get away for fear of Saul; for Saul and his men surrounded David and his men to take them.",
                "But a messenger came to Saul, saying, \"Hurry and come; for the Philistines have made a raid on the land!\"",
                "So Saul returned from pursuing after David, and went against the Philistines: therefore they called that place Sela Hammahlekoth.",
                "David went up from there, and lived in the strongholds of En Gedi."
            ],
            24: [
                "It happened, when Saul was returned from following the Philistines, that it was told him, saying, \"Behold, David is in the wilderness of En Gedi.\"",
                "Then Saul took three thousand chosen men out of all Israel, and went to seek David and his men on the rocks of the wild goats.",
                "He came to the sheep pens by the way, where there was a cave; and Saul went in to relieve himself. Now David and his men were abiding in the innermost parts of the cave.",
                "The men of David said to him, \"Behold, the day of which Yahweh said to you, \u2018Behold, I will deliver your enemy into your hand, and you shall do to him as it shall seem good to you.\u2019\" Then David arose, and cut off the skirt of Saul\u2019s robe secretly.",
                "It happened afterward, that David\u2019s heart struck him, because he had cut off Saul\u2019s skirt.",
                "He said to his men, \"Yahweh forbid that I should do this thing to my lord, Yahweh\u2019s anointed, to put forth my hand against him, since he is Yahweh\u2019s anointed.\"",
                "So David checked his men with these words, and didn\u2019t allow them to rise against Saul. Saul rose up out of the cave, and went on his way.",
                "David also arose afterward, and went out of the cave, and cried after Saul, saying, \"My lord the king!\" When Saul looked behind him, David bowed with his face to the earth, and showed respect.",
                "David said to Saul, \"Why do you listen to men\u2019s words, saying, \u2018Behold, David seeks your hurt?\u2019",
                "Behold, this day your eyes have seen how that Yahweh had delivered you today into my hand in the cave. Some urged me to kill you; but I spared you; and I said, I will not put forth my hand against my lord; for he is Yahweh\u2019s anointed.",
                "Moreover, my father, behold, yes, see the skirt of your robe in my hand; for in that I cut off the skirt of your robe, and didn\u2019t kill you, know and see that there is neither evil nor disobedience in my hand, and I have not sinned against you, though you hunt for my life to take it.",
                "May Yahweh judge between me and you, and may Yahweh avenge me of you; but my hand shall not be on you.",
                "As the proverb of the ancients says, \u2018Out of the wicked comes forth wickedness;\u2019 but my hand shall not be on you.",
                "Against whom has the king of Israel come out? Whom do you pursue? A dead dog? A flea?",
                "May Yahweh therefore be judge, and give sentence between me and you, and see, and plead my cause, and deliver me out of your hand.\"",
                "It came to pass, when David had made an end of speaking these words to Saul, that Saul said, \"Is this your voice, my son David?\" Saul lifted up his voice, and wept.",
                "He said to David, \"You are more righteous than I; for you have done good to me, whereas I have done evil to you.",
                "You have declared this day how you have dealt well with me, because when Yahweh had delivered me up into your hand, you didn\u2019t kill me.",
                "For if a man finds his enemy, will he let him go away unharmed? Therefore may Yahweh reward you good for that which you have done to me this day.",
                "Now, behold, I know that you shall surely be king, and that the kingdom of Israel shall be established in your hand.",
                "Swear now therefore to me by Yahweh, that you will not cut off my seed after me, and that you will not destroy my name out of my father\u2019s house.\"",
                "David swore to Saul. Saul went home; but David and his men went up to the stronghold."
            ],
            25: [
                "Samuel died; and all Israel gathered themselves together, and lamented him, and buried him in his house at Ramah. David arose, and went down to the wilderness of Paran.",
                "There was a man in Maon, whose possessions were in Carmel; and the man was very great, and he had three thousand sheep, and a thousand goats: and he was shearing his sheep in Carmel.",
                "Now the name of the man was Nabal; and the name of his wife Abigail; and the woman was of good understanding, and of a beautiful face: but the man was churlish and evil in his doings; and he was of the house of Caleb.",
                "David heard in the wilderness that Nabal was shearing his sheep.",
                "David sent ten young men, and David said to the young men, \"Go up to Carmel, and go to Nabal, and greet him in my name.",
                "You shall tell him, \u2018Long life to you! Peace be to you, and peace be to your house, and peace be to all that you have.",
                "Now I have heard that you have shearers. Your shepherds have now been with us, and we didn\u2019t hurt them, neither was there anything missing from them, all the while they were in Carmel.",
                "Ask your young men, and they will tell you. Therefore, let the young men find favor in your eyes; for we come in a good day. Please give whatever comes to your hand, to your servants, and to your son David.\u2019\"",
                "When David\u2019s young men came, they spoke to Nabal according to all those words in the name of David, and ceased.",
                "Nabal answered David\u2019s servants, and said, \"Who is David? Who is the son of Jesse? There are many servants who break away from their masters these days.",
                "Shall I then take my bread, and my water, and my meat that I have killed for my shearers, and give it to men who I don\u2019t know where they come from?\"",
                "So David\u2019s young men turned on their way, and went back, and came and told him according to all these words.",
                "David said to his men, \"Every man put on his sword!\" Every man put on his sword. David also put on his sword. About four hundred men followed David; and two hundred stayed by the baggage.",
                "But one of the young men told Abigail, Nabal\u2019s wife, saying, \"Behold, David sent messengers out of the wilderness to Greet our master; and he railed at them.",
                "But the men were very good to us, and we were not hurt, neither missed we anything, as long as we went with them, when we were in the fields.",
                "They were a wall to us both by night and by day, all the while we were with them keeping the sheep.",
                "Now therefore know and consider what you will do; for evil is determined against our master, and against all his house; for he is such a worthless fellow that one can\u2019t speak to him.\"",
                "Then Abigail hurried and took two hundred loaves of bread, two bottles of wine, five sheep ready dressed, five measures of parched grain, one hundred clusters of raisins, and two hundred cakes of figs, and laid them on donkeys.",
                "She said to her young men, \"Go on before me. Behold, I come after you.\" But she didn\u2019t tell her husband, Nabal.",
                "It was so, as she rode on her donkey, and came down by the covert of the mountain, that behold, David and his men came down toward her; and she met them.",
                "Now David had said, \"Surely in vain have I kept all that this fellow has in the wilderness, so that nothing was missed of all that pertained to him. He has returned me evil for good.",
                "God do so to the enemies of David, and more also, if I leave of all that belongs to him by the morning light so much as one who urinates on a wall.\"",
                "When Abigail saw David, she hurried, and alighted from her donkey, and fell before David on her face, and bowed herself to the ground.",
                "She fell at his feet, and said, \"On me, my lord, on me be the iniquity; and please let your handmaid speak in your ears. Hear the words of your handmaid.",
                "Please don\u2019t let my lord regard this worthless fellow, even Nabal; for as his name is, so is he. Nabal is his name, and folly is with him; but I, your handmaid, didn\u2019t see the young men of my lord, whom you sent.",
                "Now therefore, my lord, as Yahweh lives, and as your soul lives, since Yahweh has withheld you from blood guiltiness, and from avenging yourself with your own hand, now therefore let your enemies, and those who seek evil to my lord, be as Nabal.",
                "Now this present which your servant has brought to my lord, let it be given to the young men who follow my lord.",
                "Please forgive the trespass of your handmaid. For Yahweh will certainly make my lord a sure house, because my lord fights the battles of Yahweh; and evil shall not be found in you all your days.",
                "Though men may rise up to pursue you, and to seek your soul, yet the soul of my lord shall be bound in the bundle of life with Yahweh your God. He will sling out the souls of your enemies, as from the hollow of a sling.",
                "It shall come to pass, when Yahweh has done to my lord according to all the good that he has spoken concerning you, and shall have appointed you prince over Israel,",
                "that this shall be no grief to you, nor offense of heart to my lord, either that you have shed blood without cause, or that my lord has avenged himself. When Yahweh has dealt well with my lord, then remember your handmaid.\"",
                "David said to Abigail, \"Blessed is Yahweh, the God of Israel, who sent you this day to meet me!",
                "Blessed is your discretion, and blessed are you, that have kept me this day from blood guiltiness, and from avenging myself with my own hand.",
                "For indeed, as Yahweh, the God of Israel, lives, who has withheld me from hurting you, unless you had hurried and come to meet me, surely there wouldn\u2019t have been left to Nabal by the morning light so much as one who urinates on a wall.\"",
                "So David received of her hand that which she had brought him: and he said to her, \"Go up in peace to your house. Behold, I have listened to your voice, and have granted your request.\"",
                "Abigail came to Nabal; and behold, he held a feast in his house, like the feast of a king. Nabal\u2019s heart was merry within him, for he was very drunken. Therefore she told him nothing, less or more, until the morning light.",
                "It happened in the morning, when the wine was gone out of Nabal, that his wife told him these things, and his heart died within him, and he became as a stone.",
                "It happened about ten days after, that Yahweh struck Nabal, so that he died.",
                "When David heard that Nabal was dead, he said, \"Blessed is Yahweh, who has pleaded the cause of my reproach from the hand of Nabal, and has kept back his servant from evil. Yahweh has returned the evildoing of Nabal on his own head.\" David sent and spoke concerning Abigail, to take her to him as wife.",
                "When the servants of David had come to Abigail to Carmel, they spoke to her, saying, \"David has sent us to you, to take you to him as wife.\"",
                "She arose, and bowed herself with her face to the earth, and said, \"Behold, your handmaid is a servant to wash the feet of the servants of my lord.\"",
                "Abigail hurried, and arose, and rode on a donkey, with five ladies of hers who followed her; and she went after the messengers of David, and became his wife.",
                "David also took Ahinoam of Jezreel; and they both became his wives.",
                "Now Saul had given Michal his daughter, David\u2019s wife, to Palti the son of Laish, who was of Gallim."
            ],
            26: [
                "The Ziphites came to Saul to Gibeah, saying, \"Doesn\u2019t David hide himself in the hill of Hachilah, which is before the desert?\"",
                "Then Saul arose, and went down to the wilderness of Ziph, having three thousand chosen men of Israel with him, to seek David in the wilderness of Ziph.",
                "Saul encamped in the hill of Hachilah, which is before the desert, by the way. But David stayed in the wilderness, and he saw that Saul came after him into the wilderness.",
                "David therefore sent out spies, and understood that Saul had certainly come.",
                "David arose, and came to the place where Saul had encamped; and David saw the place where Saul lay, and Abner the son of Ner, the captain of his army: and Saul lay within the place of the wagons, and the people were encamped around him.",
                "Then answered David and said to Ahimelech the Hittite, and to Abishai the son of Zeruiah, brother to Joab, saying, \"Who will go down with me to Saul to the camp?\" Abishai said, \"I will go down with you.\"",
                "So David and Abishai came to the people by night: and, behold, Saul lay sleeping within the place of the wagons, with his spear stuck in the ground at his head; and Abner and the people lay around him.",
                "Then Abishai said to David, \"God has delivered up your enemy into your hand this day. Now therefore please let me strike him with the spear to the earth at one stroke, and I will not strike him the second time.\"",
                "David said to Abishai, \"Don\u2019t destroy him; for who can put forth his hand against Yahweh\u2019s anointed, and be guiltless?\"",
                "David said, \"As Yahweh lives, Yahweh will strike him; or his day shall come to die; or he shall go down into battle and perish.",
                "Yahweh forbid that I should put forth my hand against Yahweh\u2019s anointed; but now please take the spear that is at his head, and the jar of water, and let us go.\"",
                "So David took the spear and the jar of water from Saul\u2019s head; and they went away: and no man saw it, nor knew it, neither did any awake; for they were all asleep, because a deep sleep from Yahweh was fallen on them.",
                "Then David went over to the other side, and stood on the top of the mountain afar off; a great space being between them;",
                "and David cried to the people, and to Abner the son of Ner, saying, \"Don\u2019t you answer, Abner?\" Then Abner answered, \"Who are you who cries to the king?\"",
                "David said to Abner, \"Aren\u2019t you a man? Who is like you in Israel? Why then have you not kept watch over your lord, the king? For one of the people came in to destroy the king your lord.",
                "This thing isn\u2019t good that you have done. As Yahweh lives, you are worthy to die, because you have not kept watch over your lord, Yahweh\u2019s anointed. Now see where the king\u2019s spear is, and the jar of water that was at his head.\"",
                "Saul knew David\u2019s voice, and said, \"Is this your voice, my son David?\" David said, \"It is my voice, my lord, O king.\"",
                "He said, \"Why does my lord pursue after his servant? For what have I done? Or what evil is in my hand?",
                "Now therefore, please let my lord the king hear the words of his servant. If it is so that Yahweh has stirred you up against me, let him accept an offering. But if it is the children of men, they are cursed before Yahweh; for they have driven me out this day that I shouldn\u2019t cling to Yahweh\u2019s inheritance, saying, \u2018Go, serve other gods!\u2019",
                "Now therefore, don\u2019t let my blood fall to the earth away from the presence of Yahweh; for the king of Israel has come out to seek a flea, as when one hunts a partridge in the mountains.\"",
                "Then Saul said, \"I have sinned. Return, my son David; for I will no more do you harm, because my life was precious in your eyes this day. Behold, I have played the fool, and have erred exceedingly.\"",
                "David answered, \"Behold the spear, O king! Then let one of the young men come over and get it.",
                "Yahweh will render to every man his righteousness and his faithfulness; because Yahweh delivered you into my hand today, and I wouldn\u2019t put forth my hand against Yahweh\u2019s anointed.",
                "Behold, as your life was respected this day in my eyes, so let my life be respected in the eyes of Yahweh, and let him deliver me out of all oppression.\"",
                "Then Saul said to David, \"You are blessed, my son David. You shall both do mightily, and shall surely prevail.\" So David went his way, and Saul returned to his place."
            ],
            27: [
                "David said in his heart, \"I shall now perish one day by the hand of Saul. There is nothing better for me than that I should escape into the land of the Philistines; and Saul will despair of me, to seek me any more in all the borders of Israel. So shall I escape out of his hand.\"",
                "David arose, and passed over, he and the six hundred men who were with him, to Achish the son of Maoch, king of Gath.",
                "David lived with Achish at Gath, he and his men, every man with his household, even David with his two wives, Ahinoam the Jezreelitess, and Abigail the Carmelitess, Nabal\u2019s wife.",
                "It was told Saul that David was fled to Gath: and he sought no more again for him.",
                "David said to Achish, \"If now I have found favor in your eyes, let them give me a place in one of the cities in the country, that I may dwell there. For why should your servant dwell in the royal city with you?\"",
                "Then Achish gave him Ziklag that day: why Ziklag pertains to the kings of Judah to this day.",
                "The number of the days that David lived in the country of the Philistines was a full year and four months.",
                "David and his men went up, and made a raid on the Geshurites, and the Girzites, and the Amalekites; for those were the inhabitants of the land, who were of old, as you go to Shur, even to the land of Egypt.",
                "David struck the land, and saved neither man nor woman alive, and took away the sheep, and the cattle, and the donkeys, and the camels, and the clothing; and he returned, and came to Achish.",
                "Achish said, \"Against whom have you made a raid today?\" David said, \"Against the South of Judah, against the South of the Jerahmeelites, and against the South of the Kenites.\"",
                "David saved neither man nor woman alive, to bring them to Gath, saying, \"Lest they should tell of us, saying, \u2018David this, and this has been his way all the time he has lived in the country of the Philistines.\u2019\"",
                "Achish believed David, saying, \"He has made his people Israel utterly to abhor him. Therefore he shall be my servant forever.\""
            ],
            28: [
                "It happened in those days, that the Philistines gathered their armies together for warfare, to fight with Israel. Achish said to David, \"Know assuredly that you shall go out with me in the army, you and your men.\"",
                "David said to Achish, \"Therefore you shall know what your servant will do.\" Achish said to David, \"Therefore will I make you my bodyguard for ever.\"",
                "Now Samuel was dead, and all Israel had lamented him, and buried him in Ramah, even in his own city. Saul had put away those who had familiar spirits, and the wizards, out of the land.",
                "The Philistines gathered themselves together, and came and encamped in Shunem: and Saul gathered all Israel together, and they encamped in Gilboa.",
                "When Saul saw the army of the Philistines, he was afraid, and his heart trembled greatly.",
                "When Saul inquired of Yahweh, Yahweh didn\u2019t answer him, neither by dreams, nor by Urim, nor by prophets.",
                "Then Saul said to his servants, \"Seek me a woman who has a familiar spirit, that I may go to her, and inquire of her.\" His servants said to him, \"Behold, there is a woman who has a familiar spirit at Endor.\"",
                "Saul disguised himself, and put on other clothing, and went, he and two men with him, and they came to the woman by night: and he said, \"Please divine to me by the familiar spirit, and bring me up whomever I shall name to you.\"",
                "The woman said to him, \"Behold, you know what Saul has done, how he has cut off those who have familiar spirits, and the wizards, out of the land. Why then do you lay a snare for my life, to cause me to die?\"",
                "Saul swore to her by Yahweh, saying, \"As Yahweh lives, no punishment shall happen to you for this thing.\"",
                "Then the woman said, \"Whom shall I bring up to you?\" He said, \"Bring Samuel up for me.\"",
                "When the woman saw Samuel, she cried with a loud voice; and the woman spoke to Saul, saying, \"Why have you deceived me? For you are Saul!\"",
                "The king said to her, \"Don\u2019t be afraid. For what do you see?\" The woman said to Saul, \"I see a god coming up out of the earth.\"",
                "He said to her, \"What does he look like?\" She said, \"An old man comes up. He is covered with a robe.\" Saul perceived that it was Samuel, and he bowed with his face to the ground, and showed respect.",
                "Samuel said to Saul, \"Why have you disturbed me, to bring me up?\" Saul answered, \"I am very distressed; for the Philistines make war against me, and God has departed from me, and answers me no more, neither by prophets, nor by dreams. Therefore I have called you, that you may make known to me what I shall do.\"",
                "Samuel said, \"Why then do you ask of me, since Yahweh has departed from you and has become your adversary?",
                "Yahweh has done to you as he spoke by me. Yahweh has torn the kingdom out of your hand, and given it to your neighbor, even to David.",
                "Because you didn\u2019t obey the voice of Yahweh, and didn\u2019t execute his fierce wrath on Amalek, therefore Yahweh has done this thing to you this day.",
                "Moreover Yahweh will deliver Israel also with you into the hand of the Philistines; and tomorrow you and your sons will be with me. Yahweh will deliver the army of Israel also into the hand of the Philistines.\"",
                "Then Saul fell immediately his full length on the earth, and was terrified, because of the words of Samuel. There was no strength in him; for he had eaten no bread all the day, nor all the night.",
                "The woman came to Saul, and saw that he was very troubled, and said to him, \"Behold, your handmaid has listened to your voice, and I have put my life in my hand, and have listened to your words which you spoke to me.",
                "Now therefore, please listen also to the voice of your handmaid, and let me set a morsel of bread before you; and eat, that you may have strength, when you go on your way.\"",
                "But he refused, and said, I will not eat. But his servants, together with the woman, constrained him; and he listened to their voice. So he arose from the earth, and sat on the bed.",
                "The woman had a fattened calf in the house. She hurried and killed it; and she took flour, and kneaded it, and baked unleavened bread of it.",
                "She brought it before Saul, and before his servants; and they ate. Then they rose up, and went away that night."
            ],
            29: [
                "Now the Philistines gathered together all their armies to Aphek: and the Israelites encamped by the spring which is in Jezreel.",
                "The lords of the Philistines passed on by hundreds, and by thousands; and David and his men passed on in the rear with Achish.",
                "Then the princes of the Philistines said, \"What about these Hebrews?\" Achish said to the princes of the Philistines, \"Isn\u2019t this David, the servant of Saul the king of Israel, who has been with me these days, or rather these years, and I have found no fault in him since he fell away to this day?\"",
                "But the princes of the Philistines were angry with him; and the princes of the Philistines said to him, \"Make the man return, that he may go back to his place where you have appointed him, and let him not go down with us to battle, lest in the battle he become an adversary to us. For with what should this fellow reconcile himself to his lord? Should it not be with the heads of these men?",
                "Is not this David, of whom they sang one to another in dances, saying, \u2018Saul has slain his thousands, David his ten thousands?\u2019\"",
                "Then Achish called David, and said to him, \"As Yahweh lives, you have been upright, and your going out and your coming in with me in the army is good in my sight; for I have not found evil in you since the day of your coming to me to this day. Nevertheless, the lords don\u2019t favor you.",
                "Therefore now return, and go in peace, that you not displease the lords of the Philistines.\"",
                "David said to Achish, \"But what have I done? What have you found in your servant so long as I have been before you to this day, that I may not go and fight against the enemies of my lord the king?\"",
                "Achish answered David, \"I know that you are good in my sight, as an angel of God. Notwithstanding the princes of the Philistines have said, \u2018He shall not go up with us to the battle.\u2019",
                "Therefore now rise up early in the morning with the servants of your lord who have come with you; and as soon as you are up early in the morning, and have light, depart.\"",
                "So David rose up early, he and his men, to depart in the morning, to return into the land of the Philistines. The Philistines went up to Jezreel."
            ],
            30: [
                "It happened, when David and his men had come to Ziklag on the third day, that the Amalekites had made a raid on the South, and on Ziklag, and had struck Ziklag, and burned it with fire,",
                "and had taken captive the women and all who were therein, both small and great. They didn\u2019t kill any, but carried them off, and went their way.",
                "When David and his men came to the city, behold, it was burned with fire; and their wives, and their sons, and their daughters, were taken captive.",
                "Then David and the people who were with him lifted up their voice and wept, until they had no more power to weep.",
                "David\u2019s two wives were taken captive, Ahinoam the Jezreelitess, and Abigail the wife of Nabal the Carmelite.",
                "David was greatly distressed; for the people spoke of stoning him, because the soul of all the people was grieved, every man for his sons and for his daughters: but David strengthened himself in Yahweh his God.",
                "David said to Abiathar the priest, the son of Ahimelech, \"Please bring me here the ephod.\" Abiathar brought the ephod to David.",
                "David inquired of Yahweh, saying, \"If I pursue after this troop, shall I overtake them?\" He answered him, \"Pursue; for you shall surely overtake them, and shall without fail recover all.\"",
                "So David went, he and the six hundred men who were with him, and came to the brook Besor, where those who were left behind stayed.",
                "But David pursued, he and four hundred men; for two hundred stayed behind, who were so faint that they couldn\u2019t go over the brook Besor.",
                "They found an Egyptian in the field, and brought him to David, and gave him bread, and he ate; and they gave him water to drink.",
                "They gave him a piece of a cake of figs, and two clusters of raisins. when he had eaten, his spirit came again to him; for he had eaten no bread, nor drunk any water, three days and three nights.",
                "David asked him, \"To whom do you belong? Where are you from?\" He said, \"I am a young man of Egypt, servant to an Amalekite; and my master left me, because three days ago I fell sick.",
                "We made a raid on the South of the Cherethites, and on that which belongs to Judah, and on the South of Caleb; and we burned Ziklag with fire.\"",
                "David said to him, \"Will you bring me down to this troop?\" He said, \"Swear to me by God that you will neither kill me, nor deliver me up into the hands of my master, and I will bring you down to this troop.\"",
                "When he had brought him down, behold, they were spread around over all the ground, eating, drinking, and dancing, because of all the great spoil that they had taken out of the land of the Philistines, and out of the land of Judah.",
                "David struck them from the twilight even to the evening of the next day. Not a man of them escaped from there, except four hundred young men, who rode on camels and fled.",
                "David recovered all that the Amalekites had taken; and David rescued his two wives.",
                "There was nothing lacking to them, neither small nor great, neither sons nor daughters, neither spoil, nor anything that they had taken to them. David brought back all.",
                "David took all the flocks and the herds, which they drove before those other livestock, and said, \"This is David\u2019s spoil.\"",
                "David came to the two hundred men, who were so faint that they could not follow David, whom also they had made to stay at the brook Besor; and they went forth to meet David, and to meet the people who were with him. When David came near to the people, he greeted them.",
                "Then all the wicked men and base fellows, of those who went with David, answered and said, \"Because they didn\u2019t go with us, we will not give them anything of the spoil that we have recovered, except to every man his wife and his children, that he may lead them away, and depart.\"",
                "Then David said, \"You shall not do so, my brothers, with that which Yahweh has given to us, who has preserved us, and delivered the troop that came against us into our hand.",
                "Who will listen to you in this matter? For as his share is who goes down to the battle, so shall his share be who tarries by the baggage: they shall share alike.\"",
                "It was so from that day forward, that he made it a statute and an ordinance for Israel to this day.",
                "When David came to Ziklag, he sent of the spoil to the elders of Judah, even to his friends, saying, \"Behold, a present for you of the spoil of the enemies of Yahweh.\"",
                "He sent it to those who were in Bethel, and to those who were in Ramoth of the South, and to those who were in Jattir,",
                "and to those who were in Aroer, and to those who were in Siphmoth, and to those who were in Eshtemoa,",
                "and to those who were in Racal, and to those who were in the cities of the Jerahmeelites, and to those who were in the cities of the Kenites,",
                "and to those who were in Hormah, and to those who were in Borashan, and to those who were in Athach,",
                "and to those who were in Hebron, and to all the places where David himself and his men used to stay."
            ],
            31: [
                "Now the Philistines fought against Israel: and the men of Israel fled from before the Philistines, and fell down slain on Mount Gilboa.",
                "The Philistines followed hard on Saul and on his sons; and the Philistines killed Jonathan, and Abinadab, and Malchishua, the sons of Saul.",
                "The battle went hard against Saul, and the archers overtook him; and he was greatly distressed by reason of the archers.",
                "Then Saul said to his armor bearer, \"Draw your sword, and thrust me through with it, lest these uncircumcised come and thrust me through, and abuse me!\" But his armor bearer would not; for he was terrified. Therefore Saul took his sword, and fell on it.",
                "When his armor bearer saw that Saul was dead, he likewise fell on his sword, and died with him.",
                "So Saul died, and his three sons, and his armor bearer, and all his men, that same day together.",
                "When the men of Israel who were on the other side of the valley, and those who were beyond the Jordan, saw that the men of Israel fled, and that Saul and his sons were dead, they forsook the cities, and fled; and the Philistines came and lived in them.",
                "It happened on the next day, when the Philistines came to strip the slain, that they found Saul and his three sons fallen on Mount Gilboa.",
                "They cut off his head, and stripped off his armor, and sent into the land of the Philistines all around, to carry the news to the house of their idols, and to the people.",
                "They put his armor in the house of the Ashtaroth; and they fastened his body to the wall of Beth Shan.",
                "When the inhabitants of Jabesh Gilead heard concerning him that which the Philistines had done to Saul,",
                "all the valiant men arose, and went all night, and took the body of Saul and the bodies of his sons from the wall of Beth Shan; and they came to Jabesh, and burnt them there.",
                "They took their bones, and buried them under the tamarisk tree in Jabesh, and fasted seven days."
            ]
        },
        10: {
            1: [
                "It happened after the death of Saul, when David was returned from the slaughter of the Amalekites, and David had stayed two days in Ziklag;",
                "it happened on the third day, that behold, a man came out of the camp from Saul, with his clothes torn, and earth on his head: and so it was, when he came to David, that he fell to the earth, and showed respect.",
                "David said to him, \"Where do you come from?\" He said to him, \"I have escaped out of the camp of Israel.\"",
                "David said to him, \"How did it go? Please tell me.\" He answered, \"The people have fled from the battle, and many of the people also have fallen and are dead; and Saul and Jonathan his son are dead also.\"",
                "David said to the young man who told him, \"How do you know that Saul and Jonathan his son are dead?\"",
                "The young man who told him said, \"As I happened by chance on Mount Gilboa, behold, Saul was leaning on his spear; and behold, the chariots and the horsemen followed hard after him.",
                "When he looked behind him, he saw me, and called to me. I answered, \u2018Here I am.\u2019",
                "He said to me, \u2018Who are you?\u2019 I answered him, \u2018I am an Amalekite.\u2019",
                "He said to me, \u2018Please stand beside me, and kill me; for anguish has taken hold of me, because my life is yet whole in me.\u2019",
                "So I stood beside him, and killed him, because I was sure that he could not live after that he had fallen. I took the crown that was on his head, and the bracelet that was on his arm, and have brought them here to my lord.\"",
                "Then David took hold on his clothes, and tore them; and likewise all the men who were with him.",
                "They mourned, and wept, and fasted until evening, for Saul, and for Jonathan his son, and for the people of Yahweh, and for the house of Israel; because they were fallen by the sword.",
                "David said to the young man who told him, \"Where are you from?\" He answered, \"I am the son of a foreigner, an Amalekite.\"",
                "David said to him, \"How were you not afraid to put forth your hand to destroy Yahweh\u2019s anointed?\"",
                "David called one of the young men, and said, \"Go near, and fall on him.\" He struck him, so that he died.",
                "David said to him, \"Your blood be on your head; for your mouth has testified against you, saying, \u2018I have slain Yahweh\u2019s anointed.\u2019\"",
                "David lamented with this lamentation over Saul and over Jonathan his son",
                "(and he commanded them to teach the children of Judah the song of the bow: behold, it is written in the book of Jashar):",
                "\"Your glory, Israel, is slain on your high places! How the mighty have fallen!",
                "Don\u2019t tell it in Gath. Don\u2019t publish it in the streets of Ashkelon, lest the daughters of the Philistines rejoice, lest the daughters of the uncircumcised triumph.",
                "You mountains of Gilboa, let there be no dew nor rain on you, neither fields of offerings; For there the shield of the mighty was vilely cast away, The shield of Saul was not anointed with oil.",
                "From the blood of the slain, from the fat of the mighty, Jonathan\u2019s bow didn\u2019t turn back. Saul\u2019s sword didn\u2019t return empty.",
                "Saul and Jonathan were lovely and pleasant in their lives. In their death, they were not divided. They were swifter than eagles. They were stronger than lions.",
                "You daughters of Israel, weep over Saul, who clothed you in scarlet delicately, who put ornaments of gold on your clothing.",
                "How are the mighty fallen in the midst of the battle! Jonathan is slain on your high places.",
                "I am distressed for you, my brother Jonathan. You have been very pleasant to me. Your love to me was wonderful, passing the love of women.",
                "How are the mighty fallen, and the weapons of war perished!\""
            ],
            2: [
                "It happened after this, that David inquired of Yahweh, saying, \"Shall I go up into any of the cities of Judah?\" Yahweh said to him, \"Go up.\" David said, \"Where shall I go up?\" He said, \"To Hebron.\"",
                "So David went up there, and his two wives also, Ahinoam the Jezreelitess, and Abigail the wife of Nabal the Carmelite.",
                "David brought up his men who were with him, every man with his household. They lived in the cities of Hebron.",
                "The men of Judah came, and there they anointed David king over the house of Judah. They told David, saying, \"The men of Jabesh Gilead were those who buried Saul.\"",
                "David sent messengers to the men of Jabesh Gilead, and said to them, \"Blessed are you by Yahweh, that you have shown this kindness to your lord, even to Saul, and have buried him.",
                "Now may Yahweh show loving kindness and truth to you. I also will reward you for this kindness, because you have done this thing.",
                "Now therefore let your hands be strong, and be valiant; for Saul your lord is dead, and also the house of Judah have anointed me king over them.\"",
                "Now Abner the son of Ner, captain of Saul\u2019s army, had taken Ishbosheth the son of Saul, and brought him over to Mahanaim;",
                "and he made him king over Gilead, and over the Ashurites, and over Jezreel, and over Ephraim, and over Benjamin, and over all Israel.",
                "Ishbosheth, Saul\u2019s son, was forty years old when he began to reign over Israel, and he reigned two years. But the house of Judah followed David.",
                "The time that David was king in Hebron over the house of Judah was seven years and six months.",
                "Abner the son of Ner, and the servants of Ishbosheth the son of Saul, went out from Mahanaim to Gibeon.",
                "Joab the son of Zeruiah, and the servants of David, went out, and met them by the pool of Gibeon; and they sat down, the one on the one side of the pool, and the other on the other side of the pool.",
                "Abner said to Joab, \"Please let the young men arise and play before us!\" Joab said, \"Let them arise!\"",
                "Then they arose and went over by number: twelve for Benjamin, and for Ishbosheth the son of Saul, and twelve of the servants of David.",
                "They each caught his opponent by the head, and thrust his sword in his fellow\u2019s side; so they fell down together: therefore that place was called Helkath Hazzurim, which is in Gibeon.",
                "The battle was very severe that day: and Abner was beaten, and the men of Israel, before the servants of David.",
                "The three sons of Zeruiah were there, Joab, and Abishai, and Asahel: and Asahel was as light of foot as a wild gazelle.",
                "Asahel pursued after Abner; and in going he didn\u2019t turn to the right hand nor to the left from following Abner.",
                "Then Abner looked behind him, and said, \"Is it you, Asahel?\" He answered, \"It is I.\"",
                "Abner said to him, \"Turn aside to your right hand or to your left, and grab one of the young men, and take his armor.\" But Asahel would not turn aside from following him.",
                "Abner said again to Asahel, \"Turn aside from following me. Why should I strike you to the ground? How then should I hold up my face to Joab your brother?\"",
                "However he refused to turn aside. Therefore Abner with the back end of the spear struck him in the body, so that the spear came out behind him; and he fell down there, and died in the same place. It happened, that as many as came to the place where Asahel fell down and died stood still.",
                "But Joab and Abishai pursued after Abner: and the sun went down when they had come to the hill of Ammah, that lies before Giah by the way of the wilderness of Gibeon.",
                "The children of Benjamin gathered themselves together after Abner, and became one band, and stood on the top of a hill.",
                "Then Abner called to Joab, and said, \"Shall the sword devour forever? Don\u2019t you know that it will be bitterness in the latter end? How long shall it be then, before you ask the people to return from following their brothers?\"",
                "Joab said, \"As God lives, if you had not spoken, surely then in the morning the people would have gone away, and not each followed his brother.\"",
                "So Joab blew the trumpet; and all the people stood still, and pursued after Israel no more, neither fought they any more.",
                "Abner and his men went all that night through the Arabah; and they passed over the Jordan, and went through all Bithron, and came to Mahanaim.",
                "Joab returned from following Abner: and when he had gathered all the people together, there lacked of David\u2019s servants nineteen men and Asahel.",
                "But the servants of David had struck of Benjamin, and of Abner\u2019s men, so that three hundred sixty men died.",
                "They took up Asahel, and buried him in the tomb of his father, which was in Bethlehem. Joab and his men went all night, and the day broke on them at Hebron."
            ],
            3: [
                "Now there was long war between the house of Saul and the house of David: and David grew stronger and stronger, but the house of Saul grew weaker and weaker.",
                "To David were sons born in Hebron: and his firstborn was Amnon, of Ahinoam the Jezreelitess;",
                "and his second, Chileab, of Abigail the wife of Nabal the Carmelite; and the third, Absalom the son of Maacah the daughter of Talmai king of Geshur;",
                "and the fourth, Adonijah the son of Haggith; and the fifth, Shephatiah the son of Abital;",
                "and the sixth, Ithream, of Eglah, David\u2019s wife. These were born to David in Hebron.",
                "It happened, while there was war between the house of Saul and the house of David, that Abner made himself strong in the house of Saul.",
                "Now Saul had a concubine, whose name was Rizpah, the daughter of Aiah: and Ishbosheth said to Abner, \"Why have you gone in to my father\u2019s concubine?\"",
                "Then was Abner very angry for the words of Ishbosheth, and said, \"Am I a dog\u2019s head that belongs to Judah? Today I show kindness to the house of Saul your father, to his brothers, and to his friends, and have not delivered you into the hand of David; and yet you charge me this day with a fault concerning this woman!",
                "God do so to Abner, and more also, if, as Yahweh has sworn to David, I don\u2019t do even so to him;",
                "to transfer the kingdom from the house of Saul, and to set up the throne of David over Israel and over Judah, from Dan even to Beersheba.\"",
                "He could not answer Abner another word, because he feared him.",
                "Abner sent messengers to David on his behalf, saying, \"Whose is the land?\" and saying, \"Make your alliance with me, and behold, my hand shall be with you, to bring all Israel around to you.\"",
                "He said, \"Good; I will make a treaty with you; but one thing I require of you. That is, you shall not see my face, unless you first bring Michal, Saul\u2019s daughter, when you come to see my face.\"",
                "David sent messengers to Ishbosheth, Saul\u2019s son, saying, \"Deliver me my wife Michal, whom I pledged to be married to me for one hundred foreskins of the Philistines.\"",
                "Ishbosheth sent, and took her from her husband, even from Paltiel the son of Laish.",
                "Her husband went with her, weeping as he went, and followed her to Bahurim. Then Abner said to him, \"Go! Return!\" and he returned.",
                "Abner had communication with the elders of Israel, saying, \"In times past, you sought for David to be king over you.",
                "Now then do it; for Yahweh has spoken of David, saying, \u2018By the hand of my servant David, I will save my people Israel out of the hand of the Philistines, and out of the hand of all their enemies.\u2019\"",
                "Abner also spoke in the ears of Benjamin: and Abner went also to speak in the ears of David in Hebron all that seemed good to Israel, and to the whole house of Benjamin.",
                "So Abner came to David to Hebron, and twenty men with him. David made Abner and the men who were with him a feast.",
                "Abner said to David, \"I will arise and go, and will gather all Israel to my lord the king, that they may make a covenant with you, and that you may reign over all that your soul desires.\" David sent Abner away; and he went in peace.",
                "Behold, the servants of David and Joab came from a foray, and brought in a great spoil with them: but Abner was not with David in Hebron; for he had sent him away, and he was gone in peace.",
                "When Joab and all the army who was with him had come, they told Joab, saying, Abner the son of Ner came to the king, and he has sent him away, and he is gone in peace.",
                "Then Joab came to the king, and said, \"What have you done? Behold, Abner came to you. Why is it that you have sent him away, and he is quite gone?",
                "You know Abner the son of Ner, that he came to deceive you, and to know your going out and your coming in, and to know all that you do.\"",
                "When Joab had come out from David, he sent messengers after Abner, and they brought him back from the well of Sirah; but David didn\u2019t know it.",
                "When Abner was returned to Hebron, Joab took him aside into the midst of the gate to speak with him quietly, and struck him there in the body, so that he died, for the blood of Asahel his brother.",
                "Afterward, when David heard it, he said, \"I and my kingdom are guiltless before Yahweh forever of the blood of Abner the son of Ner.",
                "Let it fall on the head of Joab, and on all his father\u2019s house. Let there not fail from the house of Joab one who has an issue, or who is a leper, or who leans on a staff, or who falls by the sword, or who lacks bread.\"",
                "So Joab and Abishai his brother killed Abner, because he had killed their brother Asahel at Gibeon in the battle.",
                "David said to Joab, and to all the people who were with him, Tear your clothes, and clothe yourselves with sackcloth, and mourn before Abner. King David followed the bier.",
                "They buried Abner in Hebron: and the king lifted up his voice, and wept at the grave of Abner; and all the people wept.",
                "The king lamented for Abner, and said, \"Should Abner die as a fool dies?",
                "Your hands were not bound, nor your feet put into fetters. As a man falls before the children of iniquity, so you fell.\" All the people wept again over him.",
                "All the people came to cause David to eat bread while it was yet day; but David swore, saying, \"God do so to me, and more also, if I taste bread, or anything else, until the sun goes down.\"",
                "All the people took notice of it, and it pleased them; as whatever the king did pleased all the people.",
                "So all the people and all Israel understood that day that it was not of the king to kill Abner the son of Ner.",
                "The king said to his servants, \"Don\u2019t you know that there a prince and a great man has fallen this day in Israel?",
                "I am this day weak, though anointed king; and these men the sons of Zeruiah are too hard for me. May Yahweh reward the evildoer according to his wickedness.\""
            ],
            4: [
                "When Saul\u2019s son heard that Abner was dead in Hebron, his hands became feeble, and all the Israelites were troubled.",
                "Saul\u2019s son had two men who were captains of bands: the name of the one was Baanah, and the name of the other Rechab, the sons of Rimmon the Beerothite, of the children of Benjamin (for Beeroth also is reckoned to Benjamin:",
                "and the Beerothites fled to Gittaim, and have lived as foreigners there until this day).",
                "Now Jonathan, Saul\u2019s son, had a son who was lame of his feet. He was five years old when the news came of Saul and Jonathan out of Jezreel; and his nurse took him up, and fled: and it happened, as she made haste to flee, that he fell, and became lame. His name was Mephibosheth.",
                "The sons of Rimmon the Beerothite, Rechab and Baanah, went, and came about the heat of the day to the house of Ishbosheth, as he took his rest at noon.",
                "They came there into the midst of the house, as though they would have fetched wheat; and they struck him in the body: and Rechab and Baanah his brother escaped.",
                "Now when they came into the house, as he lay on his bed in his bedroom, they struck him, and killed him, and beheaded him, and took his head, and went by the way of the Arabah all night.",
                "They brought the head of Ishbosheth to David to Hebron, and said to the king, \"Behold, the head of Ishbosheth, the son of Saul, your enemy, who sought your life! Yahweh has avenged my lord the king this day of Saul, and of his seed.\"",
                "David answered Rechab and Baanah his brother, the sons of Rimmon the Beerothite, and said to them, \"As Yahweh lives, who has redeemed my soul out of all adversity,",
                "when someone told me, \u2018Behold, Saul is dead,\u2019 thinking to have brought good news, I took hold of him, and killed him in Ziklag, which was the reward I gave him for his news.",
                "How much more, when wicked men have slain a righteous person in his own house on his bed, shall I not now require his blood of your hand, and take you away from the earth?\"",
                "David commanded his young men, and they killed them, and cut off their hands and their feet, and hanged them up beside the pool in Hebron. But they took the head of Ishbosheth, and buried it in the grave of Abner in Hebron."
            ],
            5: [
                "Then came all the tribes of Israel to David to Hebron, and spoke, saying, \"Behold, we are your bone and your flesh.",
                "In times past, when Saul was king over us, it was you who led out and brought in Israel. Yahweh said to you, \u2018You shall be shepherd of my people Israel, and you shall be prince over Israel.\u2019\"",
                "So all the elders of Israel came to the king to Hebron; and king David made a covenant with them in Hebron before Yahweh; and they anointed David king over Israel.",
                "David was thirty years old when he began to reign, and he reigned forty years.",
                "In Hebron he reigned over Judah seven years and six months; and in Jerusalem he reigned thirty-three years over all Israel and Judah.",
                "The king and his men went to Jerusalem against the Jebusites, the inhabitants of the land, who spoke to David, saying, \"Unless you take away the blind and the lame, you shall not come in here;\" thinking, \"David can\u2019t come in here.\"",
                "Nevertheless David took the stronghold of Zion; the same is the city of David.",
                "David said on that day, \"Whoever strikes the Jebusites, let him get up to the watercourse, and strike the lame and the blind, who are hated by David\u2019s soul.\" Therefore they say, \"The blind and the lame can\u2019t come into the house.\"",
                "David lived in the stronghold, and called it the city of David. David built around from Millo and inward.",
                "David grew greater and greater; for Yahweh, the God of Armies, was with him.",
                "Hiram king of Tyre sent messengers to David, and cedar trees, and carpenters, and masons; and they built David a house.",
                "David perceived that Yahweh had established him king over Israel, and that he had exalted his kingdom for his people Israel\u2019s sake.",
                "David took him more concubines and wives out of Jerusalem, after he had come from Hebron; and there were yet sons and daughters born to David.",
                "These are the names of those who were born to him in Jerusalem: Shammua, and Shobab, and Nathan, and Solomon,",
                "and Ibhar, and Elishua, and Nepheg, and Japhia,",
                "and Elishama, and Eliada, and Eliphelet.",
                "When the Philistines heard that they had anointed David king over Israel, all the Philistines went up to seek David; and David heard of it, and went down to the stronghold.",
                "Now the Philistines had come and spread themselves in the valley of Rephaim.",
                "David inquired of Yahweh, saying, \"Shall I go up against the Philistines? Will you deliver them into my hand?\" Yahweh said to David, \"Go up; for I will certainly deliver the Philistines into your hand.\"",
                "David came to Baal Perazim, and David struck them there; and he said, \"Yahweh has broken my enemies before me, like the breach of waters.\" Therefore he called the name of that place Baal Perazim.",
                "They left their images there; and David and his men took them away.",
                "The Philistines came up yet again, and spread themselves in the valley of Rephaim.",
                "When David inquired of Yahweh, he said, \"You shall not go up. Circle around behind them, and attack them over against the mulberry trees.",
                "It shall be, when you hear the sound of marching in the tops of the mulberry trees, that then you shall stir yourself up; for then Yahweh has gone out before you to strike the army of the Philistines.\"",
                "David did so, as Yahweh commanded him, and struck the Philistines from Geba until you come to Gezer."
            ],
            6: [
                "David again gathered together all the chosen men of Israel, thirty thousand.",
                "David arose, and went with all the people who were with him, from Baale Judah, to bring up from there the ark of God, which is called by the Name, even the name of Yahweh of Armies who sits above the cherubim.",
                "They set the ark of God on a new cart, and brought it out of the house of Abinadab that was in the hill: and Uzzah and Ahio, the sons of Abinadab, drove the new cart.",
                "They brought it out of the house of Abinadab, which was in the hill, with the ark of God: and Ahio went before the ark.",
                "David and all the house of Israel played before Yahweh with all kinds of instruments made of fir wood, and with harps, and with stringed instruments, and with tambourines, and with castanets, and with cymbals.",
                "When they came to the threshing floor of Nacon, Uzzah reached for the ark of God, and took hold of it; for the cattle stumbled.",
                "The anger of Yahweh was kindled against Uzzah; and God struck him there for his error; and there he died by the ark of God.",
                "David was displeased, because Yahweh had broken forth on Uzzah; and he called that place Perez Uzzah, to this day.",
                "David was afraid of Yahweh that day; and he said, \"How shall the ark of Yahweh come to me?\"",
                "So David would not move the ark of Yahweh to be with him in the city of David; but David carried it aside into the house of Obed-Edom the Gittite.",
                "The ark of Yahweh remained in the house of Obed-Edom the Gittite three months: and Yahweh blessed Obed-Edom, and all his house.",
                "It was told king David, saying, \"Yahweh has blessed the house of Obed-Edom, and all that pertains to him, because of the ark of God.\" David went and brought up the ark of God from the house of Obed-Edom into the city of David with joy.",
                "It was so, that, when those who bore the ark of Yahweh had gone six paces, he sacrificed an ox and a fattened calf.",
                "David danced before Yahweh with all his might; and David was clothed in a linen ephod.",
                "So David and all the house of Israel brought up the ark of Yahweh with shouting, and with the sound of the trumpet.",
                "It was so, as the ark of Yahweh came into the city of David, that Michal the daughter of Saul looked out at the window, and saw king David leaping and dancing before Yahweh; and she despised him in her heart.",
                "They brought in the ark of Yahweh, and set it in its place, in the midst of the tent that David had pitched for it; and David offered burnt offerings and peace offerings before Yahweh.",
                "When David had made an end of offering the burnt offering and the peace offerings, he blessed the people in the name of Yahweh of Armies.",
                "He gave to all the people, even among the whole multitude of Israel, both to men and women, to everyone a portion of bread, dates, and raisins. So all the people departed everyone to his house.",
                "Then David returned to bless his household. Michal the daughter of Saul came out to meet David, and said, \"How glorious the king of Israel was today, who uncovered himself today in the eyes of the handmaids of his servants, as one of the vain fellows shamelessly uncovers himself!\"",
                "David said to Michal, \"It was before Yahweh, who chose me above your father, and above all his house, to appoint me prince over the people of Yahweh, over Israel. Therefore will I celebrate before Yahweh.",
                "I will be yet more vile than this, and will be base in my own sight. But of the handmaids of whom you have spoken, they shall honor me.\"",
                "Michal the daughter of Saul had no child to the day of her death."
            ],
            7: [
                "It happened, when the king lived in his house, and Yahweh had given him rest from all his enemies all around,",
                "that the king said to Nathan the prophet, \"See now, I dwell in a house of cedar, but the ark of God dwells within curtains.\"",
                "Nathan said to the king, \"Go, do all that is in your heart; for Yahweh is with you.\"",
                "It happened the same night, that the word of Yahweh came to Nathan, saying,",
                "\"Go and tell my servant David, \u2018Thus says Yahweh, \"Shall you build me a house for me to dwell in?",
                "For I have not lived in a house since the day that I brought up the children of Israel out of Egypt, even to this day, but have moved around in a tent and in a tabernacle.",
                "In all places in which I have walked with all the children of Israel, did I say a word to any of the tribes of Israel, whom I commanded to be shepherd of my people Israel, saying, \u2018Why have you not built me a house of cedar?\u2019\"\u2019",
                "Now therefore you shall tell my servant David this, \u2018Thus says Yahweh of Armies, \"I took you from the sheep pen, from following the sheep, that you should be prince over my people, over Israel.",
                "I have been with you wherever you went, and have cut off all your enemies from before you. I will make you a great name, like the name of the great ones who are in the earth.",
                "I will appoint a place for my people Israel, and will plant them, that they may dwell in their own place, and be moved no more; neither shall the children of wickedness afflict them any more, as at the first,",
                "and as from the day that I commanded judges to be over my people Israel. I will cause you to rest from all your enemies. Moreover Yahweh tells you that Yahweh will make you a house.",
                "When your days are fulfilled, and you shall sleep with your fathers, I will set up your seed after you, who shall proceed out of your bowels, and I will establish his kingdom.",
                "He shall build a house for my name, and I will establish the throne of his kingdom forever.",
                "I will be his father, and he shall be my son. If he commits iniquity, I will chasten him with the rod of men, and with the stripes of the children of men;",
                "but my loving kindness shall not depart from him, as I took it from Saul, whom I put away before you.",
                "Your house and your kingdom shall be made sure forever before you. Your throne shall be established forever.\"\u2019\"",
                "According to all these words, and according to all this vision, so Nathan spoke to David.",
                "Then David the king went in, and sat before Yahweh; and he said, \"Who am I, Lord Yahweh, and what is my house, that you have brought me thus far?",
                "This was yet a small thing in your eyes, Lord Yahweh; but you have spoken also of your servant\u2019s house for a great while to come; and this after the way of men, Lord Yahweh!",
                "What more can David say to you? For you know your servant, Lord Yahweh.",
                "For your word\u2019s sake, and according to your own heart, you have worked all this greatness, to make your servant know it.",
                "Therefore you are great, Yahweh God. For there is none like you, neither is there any God besides you, according to all that we have heard with our ears.",
                "What one nation in the earth is like your people, even like Israel, whom God went to redeem to himself for a people, and to make himself a name, and to do great things for you, and awesome things for your land, before your people, whom you redeem to yourself out of Egypt, from the nations and their gods?",
                "You established for yourself your people Israel to be a people to you forever; and you, Yahweh, became their God.",
                "Now, Yahweh God, the word that you have spoken concerning your servant, and concerning his house, confirm it forever, and do as you have spoken.",
                "Let your name be magnified forever, saying, \u2018Yahweh of Armies is God over Israel; and the house of your servant David shall be established before you.\u2019",
                "For you, Yahweh of Armies, the God of Israel, have revealed to your servant, saying, \u2018I will build you a house.\u2019 Therefore your servant has found in his heart to pray this prayer to you.",
                "\"Now, O Lord Yahweh, you are God, and your words are truth, and you have promised this good thing to your servant.",
                "Now therefore let it please you to bless the house of your servant, that it may continue forever before you; for you, Lord Yahweh, have spoken it. Let the house of your servant be blessed forever with your blessing.\""
            ],
            8: [
                "After this it happened that David struck the Philistines, and subdued them: and David took the bridle of the mother city out of the hand of the Philistines.",
                "He struck Moab, and measured them with the line, making them to lie down on the ground; and he measured two lines to put to death, and one full line to keep alive. The Moabites became servants to David, and brought tribute.",
                "David struck also Hadadezer the son of Rehob, king of Zobah, as he went to recover his dominion at the River.",
                "David took from him one thousand seven hundred horsemen, and twenty thousand footmen: and David hamstrung all the chariot horses, but reserved of them for one hundred chariots.",
                "When the Syrians of Damascus came to help Hadadezer king of Zobah, David struck of the Syrians two and twenty thousand men.",
                "Then David put garrisons in Syria of Damascus; and the Syrians became servants to David, and brought tribute. Yahweh gave victory to David wherever he went.",
                "David took the shields of gold that were on the servants of Hadadezer, and brought them to Jerusalem.",
                "From Betah and from Berothai, cities of Hadadezer, king David took exceeding much brass.",
                "When Toi king of Hamath heard that David had struck all the army of Hadadezer,",
                "then Toi sent Joram his son to king David, to Greet him, and to bless him, because he had fought against Hadadezer and struck him: for Hadadezer had wars with Toi. Joram brought with him vessels of silver, and vessels of gold, and vessels of brass:",
                "King David also dedicated these to Yahweh, with the silver and gold that he dedicated of all the nations which he subdued;",
                "of Syria, and of Moab, and of the children of Ammon, and of the Philistines, and of Amalek, and of the spoil of Hadadezer, son of Rehob, king of Zobah.",
                "David earned a reputation when he returned from smiting the Syrians in the Valley of Salt, even eighteen thousand men.",
                "He put garrisons in Edom; throughout all Edom put he garrisons, and all the Edomites became servants to David. Yahweh gave victory to David wherever he went.",
                "David reigned over all Israel; and David executed justice and righteousness to all his people.",
                "Joab the son of Zeruiah was over the army; and Jehoshaphat the son of Ahilud was recorder;",
                "and Zadok the son of Ahitub, and Ahimelech the son of Abiathar, were priests; and Seraiah was scribe;",
                "and Benaiah the son of Jehoiada was over the Cherethites and the Pelethites; and David\u2019s sons were chief ministers."
            ],
            9: [
                "David said, \"Is there yet any who is left of the house of Saul, that I may show him kindness for Jonathan\u2019s sake?\"",
                "There was of the house of Saul a servant whose name was Ziba, and they called him to David; and the king said to him, \"Are you Ziba?\" He said, \"Your servant is he.\"",
                "The king said, \"Is there not yet any of the house of Saul, that I may show the kindness of God to him?\" Ziba said to the king, \"Jonathan has yet a son, who is lame of his feet.\"",
                "The king said to him, \"Where is he?\" Ziba said to the king, \"Behold, he is in the house of Machir the son of Ammiel, in Lo Debar.\"",
                "Then king David sent, and fetched him out of the house of Machir the son of Ammiel, from Lo Debar.",
                "Mephibosheth, the son of Jonathan, the son of Saul, came to David, and fell on his face, and showed respect. David said, \"Mephibosheth.\" He answered, \"Behold, your servant!\"",
                "David said to him, \"Don\u2019t be afraid of him; for I will surely show you kindness for Jonathan your father\u2019s sake, and will restore to you all the land of Saul your father. You shall eat bread at my table continually.\"",
                "He bowed down, and said, \"What is your servant, that you should look on such a dead dog as I am?\"",
                "Then the king called to Ziba, Saul\u2019s servant, and said to him, \"All that pertained to Saul and to all his house have I given to your master\u2019s son.",
                "You shall till the land for him, you, and your sons, and your servants; and you shall bring in the harvest, that your master\u2019s son may have bread to eat: but Mephibosheth your master\u2019s son shall eat bread always at my table.\" Now Ziba had fifteen sons and twenty servants.",
                "Then Ziba said to the king, \"According to all that my lord the king commands his servant, so your shall servant do.\" So Mephibosheth ate at the king\u2019s table, like one of the king\u2019s sons.",
                "Mephibosheth had a young son, whose name was Mica. All that lived in the house of Ziba were servants to Mephibosheth.",
                "So Mephibosheth lived in Jerusalem; for he ate continually at the king\u2019s table. He was lame in both his feet."
            ],
            10: [
                "It happened after this, that the king of the children of Ammon died, and Hanun his son reigned in his place.",
                "David said, \"I will show kindness to Hanun the son of Nahash, as his father showed kindness to me.\" So David sent by his servants to comfort him concerning his father. David\u2019s servants came into the land of the children of Ammon.",
                "But the princes of the children of Ammon said to Hanun their lord, \"Do you think that David honors your father, in that he has sent comforters to you? Hasn\u2019t David sent his servants to you to search the city, and to spy it out, and to overthrow it?\"",
                "So Hanun took David\u2019s servants, and shaved off the one half of their beards, and cut off their garments in the middle, even to their buttocks, and sent them away.",
                "When they told it to David, he sent to meet them; for the men were greatly ashamed. The king said, \"Wait at Jericho until your beards have grown, and then return.\"",
                "When the children of Ammon saw that they were become odious to David, the children of Ammon sent and hired the Syrians of Beth Rehob, and the Syrians of Zobah, twenty thousand footmen, and the king of Maacah with one thousand men, and the men of Tob twelve thousand men.",
                "When David heard of it, he sent Joab, and all the army of the mighty men.",
                "The children of Ammon came out, and put the battle in array at the entrance of the gate: and the Syrians of Zobah and of Rehob, and the men of Tob and Maacah, were by themselves in the field.",
                "Now when Joab saw that the battle was set against him before and behind, he chose of all the choice men of Israel, and put them in array against the Syrians:",
                "The rest of the people he committed into the hand of Abishai his brother; and he put them in array against the children of Ammon.",
                "He said, \"If the Syrians are too strong for me, then you shall help me; but if the children of Ammon are too strong for you, then I will come and help you.",
                "Be courageous, and let us be strong for our people, and for the cities of our God; and Yahweh do that which seems good to him.\"",
                "So Joab and the people who were with him drew near to the battle against the Syrians: and they fled before him.",
                "When the children of Ammon saw that the Syrians had fled, they likewise fled before Abishai, and entered into the city. Then Joab returned from the children of Ammon, and came to Jerusalem.",
                "When the Syrians saw that they were defeated by Israel, they gathered themselves together.",
                "Hadadezer sent, and brought out the Syrians who were beyond the River: and they came to Helam, with Shobach the captain of the army of Hadadezer at their head.",
                "It was told David; and he gathered all Israel together, and passed over the Jordan, and came to Helam. The Syrians set themselves in array against David, and fought with him.",
                "The Syrians fled before Israel; and David killed of the Syrians seven hundred charioteers, and forty thousand horsemen, and struck Shobach the captain of their army, so that he died there.",
                "When all the kings who were servants to Hadadezer saw that they were defeated before Israel, they made peace with Israel, and served them. So the Syrians feared to help the children of Ammon any more."
            ],
            11: [
                "It happened, at the return of the year, at the time when kings go out, that David sent Joab, and his servants with him, and all Israel; and they destroyed the children of Ammon, and besieged Rabbah. But David stayed at Jerusalem.",
                "It happened at evening, that David arose from off his bed, and walked on the roof of the king\u2019s house: and from the roof he saw a woman bathing; and the woman was very beautiful to look on.",
                "David sent and inquired after the woman. One said, \"Isn\u2019t this Bathsheba, the daughter of Eliam, the wife of Uriah the Hittite?\"",
                "David sent messengers, and took her; and she came in to him, and he lay with her (for she was purified from her uncleanness); and she returned to her house.",
                "The woman conceived; and she sent and told David, and said, \"I am with child.\"",
                "David sent to Joab, \"Send me Uriah the Hittite.\" Joab sent Uriah to David.",
                "When Uriah had come to him, David asked of him how Joab did, and how the people fared, and how the war prospered.",
                "David said to Uriah, \"Go down to your house, and wash your feet.\" Uriah departed out of the king\u2019s house, and a gift from the king was sent after him.",
                "But Uriah slept at the door of the king\u2019s house with all the servants of his lord, and didn\u2019t go down to his house.",
                "When they had told David, saying, \"Uriah didn\u2019t go down to his house,\" David said to Uriah, \"Haven\u2019t you come from a journey? Why didn\u2019t you go down to your house?\"",
                "Uriah said to David, \"The ark, Israel, and Judah, are staying in tents; and my lord Joab, and the servants of my lord, are encamped in the open field. Shall I then go into my house to eat and to drink, and to lie with my wife? As you live, and as your soul lives, I will not do this thing!\"",
                "David said to Uriah, \"Stay here today also, and tomorrow I will let you depart.\" So Uriah stayed in Jerusalem that day, and the next day.",
                "When David had called him, he ate and drink before him; and he made him drunk. At evening, he went out to lie on his bed with the servants of his lord, but didn\u2019t go down to his house.",
                "It happened in the morning, that David wrote a letter to Joab, and sent it by the hand of Uriah.",
                "He wrote in the letter, saying, \"Send Uriah to the forefront of the hottest battle, and retreat from him, that he may be struck, and die.\"",
                "It happened, when Joab kept watch on the city, that he assigned Uriah to the place where he knew that valiant men were.",
                "The men of the city went out, and fought with Joab. Some of the people fell, even of the servants of David; and Uriah the Hittite died also.",
                "Then Joab sent and told David all the things concerning the war;",
                "and he commanded the messenger, saying, \"When you have finished telling all the things concerning the war to the king,",
                "it shall be that, if the king\u2019s wrath arise, and he asks you, \u2018Why did you go so near to the city to fight? Didn\u2019t you know that they would shoot from the wall?",
                "who struck Abimelech the son of Jerubbesheth? Didn\u2019t a woman cast an upper millstone on him from the wall, so that he died at Thebez? Why did you go so near the wall?\u2019 then you shall say, \u2018Your servant Uriah the Hittite is dead also.\u2019\"",
                "So the messenger went, and came and showed David all that Joab had sent him for.",
                "The messenger said to David, \"The men prevailed against us, and came out to us into the field, and we were on them even to the entrance of the gate.",
                "The shooters shot at your servants from off the wall; and some of the king\u2019s servants are dead, and your servant Uriah the Hittite is dead also.\"",
                "Then David said to the messenger, \"Thus you shall tell Joab, \u2018Don\u2019t let this thing displease you, for the sword devours one as well as another. Make your battle stronger against the city, and overthrow it.\u2019 Encourage him.\"",
                "When the wife of Uriah heard that Uriah her husband was dead, she made lamentation for her husband.",
                "When the mourning was past, David sent and took her home to his house, and she became his wife, and bore him a son. But the thing that David had done displeased Yahweh."
            ],
            12: [
                "Yahweh sent Nathan to David. He came to him, and said to him, \"There were two men in one city; the one rich, and the other poor.",
                "The rich man had very many flocks and herds,",
                "but the poor man had nothing, except one little ewe lamb, which he had bought and raised. It grew up together with him, and with his children. It ate of his own food, drank of his own cup, and lay in his bosom, and was to him like a daughter.",
                "A traveler came to the rich man, and he spared to take of his own flock and of his own herd, to dress for the wayfaring man who had come to him, but took the poor man\u2019s lamb, and dressed it for the man who had come to him.\"",
                "David\u2019s anger was greatly kindled against the man, and he said to Nathan, \"As Yahweh lives, the man who has done this is worthy to die!",
                "He shall restore the lamb fourfold, because he did this thing, and because he had no pity!\"",
                "Nathan said to David, \"You are the man. This is what Yahweh, the God of Israel, says: \u2018I anointed you king over Israel, and I delivered you out of the hand of Saul.",
                "I gave you your master\u2019s house, and your master\u2019s wives into your bosom, and gave you the house of Israel and of Judah; and if that would have been too little, I would have added to you many more such things.",
                "Why have you despised the word of Yahweh, to do that which is evil in his sight? You have struck Uriah the Hittite with the sword, and have taken his wife to be your wife, and have slain him with the sword of the children of Ammon.",
                "Now therefore the sword will never depart from your house, because you have despised me, and have taken the wife of Uriah the Hittite to be your wife.\u2019",
                "\"This is what Yahweh says: \u2018Behold, I will raise up evil against you out of your own house; and I will take your wives before your eyes, and give them to your neighbor, and he will lie with your wives in the sight of this sun.",
                "For you did it secretly, but I will do this thing before all Israel, and before the sun.\u2019\"",
                "David said to Nathan, \"I have sinned against Yahweh.\" Nathan said to David, \"Yahweh also has put away your sin. You will not die.",
                "However, because by this deed you have given great occasion to Yahweh\u2019s enemies to blaspheme, the child also who is born to you shall surely die.\"",
                "Nathan departed to his house. Yahweh struck the child that Uriah\u2019s wife bore to David, and it was very sick.",
                "David therefore begged God for the child; and David fasted, and went in, and lay all night on the earth.",
                "The elders of his house arose beside him, to raise him up from the earth: but he would not, neither did he eat bread with them.",
                "It happened on the seventh day, that the child died. The servants of David feared to tell him that the child was dead; for they said, \"Behold, while the child was yet alive, we spoke to him, and he didn\u2019t listen to our voice. How will he then harm himself, if we tell him that the child is dead?\"",
                "But when David saw that his servants were whispering together, David perceived that the child was dead; and David said to his servants, \"Is the child dead?\" They said, \"He is dead.\"",
                "Then David arose from the earth, and washed, and anointed himself, and changed his clothing; and he came into the house of Yahweh, and worshiped: then he came to his own house; and when he required, they set bread before him, and he ate.",
                "Then his servants said to him, \"What is this that you have done? You fasted and wept for the child while he was alive; but when the child was dead, you rose up and ate bread.\"",
                "He said, \"While the child was yet alive, I fasted and wept; for I said, \u2018Who knows whether Yahweh will not be gracious to me, that the child may live?\u2019",
                "But now he is dead, why should I fast? Can I bring him back again? I shall go to him, but he will not return to me.\"",
                "David comforted Bathsheba his wife, and went in to her, and lay with her. She bore a son, and he called his name Solomon. Yahweh loved him;",
                "and he sent by the hand of Nathan the prophet; and he named him Jedidiah, for Yahweh\u2019s sake.",
                "Now Joab fought against Rabbah of the children of Ammon, and took the royal city.",
                "Joab sent messengers to David, and said, \"I have fought against Rabbah. Yes, I have taken the city of waters.",
                "Now therefore gather the rest of the people together, and encamp against the city, and take it; lest I take the city, and it be called after my name.\"",
                "David gathered all the people together, and went to Rabbah, and fought against it, and took it.",
                "He took the crown of their king from off his head; and its weight was a talent of gold, and in it were precious stones; and it was set on David\u2019s head. He brought out the spoil of the city, exceeding much.",
                "He brought out the people who were therein, and put them under saws, and under iron picks, and under axes of iron, and made them pass through the brick kiln: and he did so to all the cities of the children of Ammon. David and all the people returned to Jerusalem."
            ],
            13: [
                "It happened after this, that Absalom the son of David had a beautiful sister, whose name was Tamar; and Amnon the son of David loved her.",
                "Amnon was so troubled that he fell sick because of his sister Tamar; for she was a virgin; and it seemed hard to Amnon to do anything to her.",
                "But Amnon had a friend, whose name was Jonadab, the son of Shimeah, David\u2019s brother; and Jonadab was a very subtle man.",
                "He said to him, \"Why, son of the king, are you so sad from day to day? Won\u2019t you tell me?\" Amnon said to him, \"I love Tamar, my brother Absalom\u2019s sister.\"",
                "Jonadab said to him, \"Lay down on your bed, and pretend to be sick. When your father comes to see you, tell him, \u2018Please let my sister Tamar come and give me bread to eat, and dress the food in my sight, that I may see it, and eat it from her hand.\u2019\"",
                "So Amnon lay down and faked being sick. When the king came to see him, Amnon said to the king, \"Please let my sister Tamar come, and make me a couple of cakes in my sight, that I may eat from her hand.\"",
                "Then David sent home to Tamar, saying, \"Go now to your brother Amnon\u2019s house, and prepare food for him.\"",
                "So Tamar went to her brother Amnon\u2019s house; and he was laid down. She took dough, and kneaded it, and made cakes in his sight, and baked the cakes.",
                "She took the pan, and poured them out before him; but he refused to eat. Amnon said, \"Have all men leave me.\" Every man went out from him.",
                "Amnon said to Tamar, \"Bring the food into the room, that I may eat from your hand.\" Tamar took the cakes which she had made, and brought them into the room to Amnon her brother.",
                "When she had brought them near to him to eat, he took hold of her, and said to her, \"Come, lie with me, my sister!\"",
                "She answered him, \"No, my brother, do not force me! For no such thing ought to be done in Israel. Don\u2019t you do this folly.",
                "I, where would I carry my shame? And as for you, you will be as one of the fools in Israel. Now therefore, please speak to the king; for he will not withhold me from you.\"",
                "However he would not listen to her voice; but being stronger than she, he forced her, and lay with her.",
                "Then Amnon hated her with exceeding great hatred; for the hatred with which he hated her was greater than the love with which he had loved her. Amnon said to her, \"Arise, be gone!\"",
                "She said to him, \"Not so, because this great wrong in sending me away is worse than the other that you did to me!\" But he would not listen to her.",
                "Then he called his servant who ministered to him, and said, \"Put now this woman out from me, and bolt the door after her.\"",
                "She had a garment of various colors on her; for with such robes were the king\u2019s daughters who were virgins dressed. Then his servant brought her out, and bolted the door after her.",
                "Tamar put ashes on her head, and tore her garment of various colors that was on her; and she laid her hand on her head, and went her way, crying aloud as she went.",
                "Absalom her brother said to her, \"Has Amnon your brother been with you? But now hold your peace, my sister. He is your brother. Don\u2019t take this thing to heart.\" So Tamar remained desolate in her brother Absalom\u2019s house.",
                "But when king David heard of all these things, he was very angry.",
                "Absalom spoke to Amnon neither good nor bad; for Absalom hated Amnon, because he had forced his sister Tamar.",
                "It happened after two full years, that Absalom had sheepshearers in Baal Hazor, which is beside Ephraim: and Absalom invited all the king\u2019s sons.",
                "Absalom came to the king, and said, \"See now, your servant has sheepshearers. Please let the king and his servants go with your servant.\"",
                "The king said to Absalom, \"No, my son, let us not all go, lest we be burdensome to you.\" He pressed him; however he would not go, but blessed him.",
                "Then Absalom said, \"If not, please let my brother Amnon go with us.\" The king said to him, \"Why should he go with you?\"",
                "But Absalom pressed him, and he let Amnon and all the king\u2019s sons go with him.",
                "Absalom commanded his servants, saying, \"Mark now, when Amnon\u2019s heart is merry with wine; and when I tell you, \u2018Strike Amnon,\u2019 then kill him. Don\u2019t be afraid. Haven\u2019t I commanded you? Be courageous, and be valiant!\"",
                "The servants of Absalom did to Amnon as Absalom had commanded. Then all the king\u2019s sons arose, and every man got up on his mule, and fled.",
                "It happened, while they were in the way, that the news came to David, saying, \"Absalom has slain all the king\u2019s sons, and there is not one of them left!\"",
                "Then the king arose, and tore his garments, and lay on the earth; and all his servants stood by with their clothes torn.",
                "Jonadab, the son of Shimeah, David\u2019s brother, answered, \"Don\u2019t let my lord suppose that they have killed all the young men the king\u2019s sons; for Amnon only is dead; for by the appointment of Absalom this has been determined from the day that he forced his sister Tamar.",
                "Now therefore don\u2019t let my lord the king take the thing to his heart, to think that all the king\u2019s sons are dead; for Amnon only is dead.\"",
                "But Absalom fled. The young man who kept the watch lifted up his eyes, and looked, and behold, many people were coming by way of the hillside behind him.",
                "Jonadab said to the king, \"Behold, the king\u2019s sons are coming! It is as your servant said.\"",
                "It happened, as soon as he had finished speaking, that behold, the king\u2019s sons came, and lifted up their voice, and wept. The king also and all his servants wept bitterly.",
                "But Absalom fled, and went to Talmai the son of Ammihur, king of Geshur. David mourned for his son every day.",
                "So Absalom fled, and went to Geshur, and was there three years.",
                "King David longed to go forth to Absalom: for he was comforted concerning Amnon, since he was dead."
            ],
            14: [
                "Now Joab the son of Zeruiah perceived that the king\u2019s heart was toward Absalom.",
                "Joab sent to Tekoa, and fetched there a wise woman, and said to her, \"Please act like a mourner, and put on mourning clothing, please, and don\u2019t anoint yourself with oil, but be as a woman who has mourned a long time for the dead.",
                "Go in to the king, and speak like this to him.\" So Joab put the words in her mouth.",
                "When the woman of Tekoa spoke to the king, she fell on her face to the ground, showed respect, and said, \"Help, O king!\"",
                "The king said to her, \"What ails you?\" She answered, \"Truly I am a widow, and my husband is dead.",
                "Your handmaid had two sons, and they both fought together in the field, and there was no one to part them, but the one struck the other, and killed him.",
                "Behold, the whole family has risen against your handmaid, and they say, \u2018Deliver him who struck his brother, that we may kill him for the life of his brother whom he killed, and so destroy the heir also.\u2019 Thus they would quench my coal which is left, and would leave to my husband neither name nor remainder on the surface of the earth.\"",
                "The king said to the woman, \"Go to your house, and I will give a command concerning you.\"",
                "The woman of Tekoa said to the king, \"My lord, O king, the iniquity be on me, and on my father\u2019s house; and the king and his throne be guiltless.\"",
                "The king said, \"Whoever says anything to you, bring him to me, and he shall not touch you any more.\"",
                "Then she said, \"Please let the king remember Yahweh your God, that the avenger of blood destroy not any more, lest they destroy my son.\" He said, \"As Yahweh lives, not one hair of your son shall fall to the earth.\"",
                "Then the woman said, \"Please let your handmaid speak a word to my lord the king.\" He said, \"Say on.\"",
                "The woman said, \"Why then have you devised such a thing against the people of God? For in speaking this word the king is as one who is guilty, in that the king does not bring home again his banished one.",
                "For we must die, and are as water split on the ground, which can\u2019t be gathered up again; neither does God take away life, but devises means, that he who is banished not be an outcast from him.",
                "Now therefore seeing that I have come to speak this word to my lord the king, it is because the people have made me afraid: and your handmaid said, \u2018I will now speak to the king; it may be that the king will perform the request of his servant.\u2019",
                "For the king will hear, to deliver his servant out of the hand of the man who would destroy me and my son together out of the inheritance of God.",
                "Then your handmaid said, \u2018Please let the word of my lord the king bring rest; for as an angel of God, so is my lord the king to discern good and bad. May Yahweh, your God, be with you.\u2019\"",
                "Then the king answered the woman, \"Please don\u2019t hide anything from me that I ask you.\" The woman said, \"Let my lord the king now speak.\"",
                "The king said, \"Is the hand of Joab with you in all this?\" The woman answered, \"As your soul lives, my lord the king, no one can turn to the right hand or to the left from anything that my lord the king has spoken; for your servant Joab, he urged me, and he put all these words in the mouth of your handmaid;",
                "to change the face of the matter has your servant Joab done this thing. My lord is wise, according to the wisdom of an angel of God, to know all things that are in the earth.\"",
                "The king said to Joab, \"Behold now, I have done this thing. Go therefore, bring the young man Absalom back.\"",
                "Joab fell to the ground on his face, showed respect, and blessed the king. Joab said, \"Today your servant knows that I have found favor in your sight, my lord, king, in that the king has performed the request of his servant.\"",
                "So Joab arose and went to Geshur, and brought Absalom to Jerusalem.",
                "The king said, \"Let him return to his own house, but let him not see my face.\" So Absalom returned to his own house, and didn\u2019t see the king\u2019s face.",
                "Now in all Israel there was none to be so much praised as Absalom for his beauty: from the sole of his foot even to the crown of his head there was no blemish in him.",
                "When he cut the hair of his head (now it was at every year\u2019s end that he cut it; because it was heavy on him, therefore he cut it); he weighed the hair of his head at two hundred shekels, after the king\u2019s weight.",
                "To Absalom there were born three sons, and one daughter, whose name was Tamar: she was a woman of a beautiful face.",
                "Absalom lived two full years in Jerusalem; and he didn\u2019t see the king\u2019s face.",
                "Then Absalom sent for Joab, to send him to the king; but he would not come to him: and he sent again a second time, but he would not come.",
                "Therefore he said to his servants, \"Behold, Joab\u2019s field is near mine, and he has barley there. Go and set it on fire.\" Absalom\u2019s servants set the field on fire.",
                "Then Joab arose, and came to Absalom to his house, and said to him, \"Why have your servants set my field on fire?\"",
                "Absalom answered Joab, \"Behold, I sent to you, saying, \u2018Come here, that I may send you to the king, to say, \"Why have I come from Geshur? It would be better for me to be there still. Now therefore let me see the king\u2019s face; and if there is iniquity in me, let him kill me.\"\u2019\"",
                "So Joab came to the king, and told him; and when he had called for Absalom, he came to the king, and bowed himself on his face to the ground before the king: and the king kissed Absalom."
            ],
            15: [
                "It happened after this, that Absalom prepared him a chariot and horses, and fifty men to run before him.",
                "Absalom rose up early, and stood beside the way of the gate. It was so, that when any man had a suit which should come to the king for judgment, then Absalom called to him, and said, \"What city are you from?\" He said, \"Your servant is of one of the tribes of Israel.\"",
                "Absalom said to him, \"Behold, your matters are good and right; but there is no man deputized by the king to hear you.\"",
                "Absalom said moreover, \"Oh that I were made judge in the land, that every man who has any suit or cause might come to me, and I would do him justice!\"",
                "It was so, that when any man came near to do him obeisance, he put forth his hand, and took hold of him, and kissed him.",
                "Absalom did this sort of thing to all Israel who came to the king for judgment. So Absalom stole the hearts of the men of Israel.",
                "It happened at the end of forty years, that Absalom said to the king, \"Please let me go and pay my vow, which I have vowed to Yahweh, in Hebron.",
                "For your servant vowed a vow while I stayed at Geshur in Syria, saying, \u2018If Yahweh shall indeed bring me again to Jerusalem, then I will serve Yahweh.\u2019\"",
                "The king said to him, \"Go in peace.\" So he arose, and went to Hebron.",
                "But Absalom sent spies throughout all the tribes of Israel, saying, \"As soon as you hear the sound of the trumpet, then you shall say, \u2018Absalom is king in Hebron!\u2019\"",
                "Two hundred men went with Absalom out of Jerusalem, who were invited, and went in their simplicity; and they didn\u2019t know anything.",
                "Absalom sent for Ahithophel the Gilonite, David\u2019s counselor, from his city, even from Giloh, while he was offering the sacrifices. The conspiracy was strong; for the people increased continually with Absalom.",
                "A messenger came to David, saying, \"The hearts of the men of Israel are after Absalom.\"",
                "David said to all his servants who were with him at Jerusalem, \"Arise, and let us flee; for else none of us shall escape from Absalom. Make speed to depart, lest he overtake us quickly, and bring down evil on us, and strike the city with the edge of the sword.\"",
                "The king\u2019s servants said to the king, \"Behold, your servants are ready to do whatever my lord the king chooses.\"",
                "The king went forth, and all his household after him. The king left ten women, who were concubines, to keep the house.",
                "The king went forth, and all the people after him; and they stayed in Beth Merhak.",
                "All his servants passed on beside him; and all the Cherethites, and all the Pelethites, and all the Gittites, six hundred men who came after him from Gath, passed on before the king.",
                "Then the king said to Ittai the Gittite, \"Why do you also go with us? Return, and stay with the king; for you are a foreigner, and also an exile. Return to your own place.",
                "Whereas you came but yesterday, should I this day make you go up and down with us, since I go where I may? Return, and take back your brothers. Mercy and truth be with you.\"",
                "Ittai answered the king, and said, \"As Yahweh lives, and as my lord the king lives, surely in what place my lord the king shall is, whether for death or for life, even there also will your servant be.\"",
                "David said to Ittai, \"Go and pass over.\" Ittai the Gittite passed over, and all his men, and all the little ones who were with him.",
                "All the country wept with a loud voice, and all the people passed over: the king also himself passed over the brook Kidron, and all the people passed over, toward the way of the wilderness.",
                "Behold, Zadok also came, and all the Levites with him, bearing the ark of the covenant of God; and they set down the ark of God; and Abiathar went up, until all the people finished passing out of the city.",
                "The king said to Zadok, \"Carry back the ark of God into the city. If I find favor in the eyes of Yahweh, he will bring me again, and show me both it, and his habitation;",
                "but if he say thus, \u2018I have no delight in you;\u2019 behold, here am I. Let him do to me as seems good to him.\"",
                "The king said also to Zadok the priest, \"Aren\u2019t you a seer? Return into the city in peace, and your two sons with you, Ahimaaz your son, and Jonathan the son of Abiathar.",
                "Behold, I will stay at the fords of the wilderness, until word comes from you to inform me.\"",
                "Zadok therefore and Abiathar carried the ark of God again to Jerusalem; and they stayed there.",
                "David went up by the ascent of the Mount of Olives, and wept as he went up; and he had his head covered, and went barefoot: and all the people who were with him covered every man his head, and they went up, weeping as they went up.",
                "Someone told David, saying, \"Ahithophel is among the conspirators with Absalom.\" David said, \"Yahweh, please turn the counsel of Ahithophel into foolishness.\"",
                "It happened that when David had come to the top, where God was worshiped, behold, Hushai the Archite came to meet him with his coat torn, and earth on his head.",
                "David said to him, \"If you pass on with me, then you will be a burden to me;",
                "but if you return to the city, and tell Absalom, \u2018I will be your servant, O king. As I have been your father\u2019s servant in time past, so will I now be your servant; then will you defeat for me the counsel of Ahithophel.\u2019",
                "Don\u2019t you have Zadok and Abiathar the priests there with you? Therefore it shall be, that whatever thing you shall hear out of the king\u2019s house, you shall tell it to Zadok and Abiathar the priests.",
                "Behold, they have there with them their two sons, Ahimaaz, Zadok\u2019s son, and Jonathan, Abiathar\u2019s son; and by them you shall send to me everything that you shall hear.\"",
                "So Hushai, David\u2019s friend, came into the city; and Absalom came into Jerusalem."
            ],
            16: [
                "When David was a little past the top, behold, Ziba the servant of Mephibosheth met him, with a couple of donkeys saddled, and on them two hundred loaves of bread, and one hundred clusters of raisins, and one hundred summer fruits, and a bottle of wine.",
                "The king said to Ziba, What do you mean by these? Ziba said, The donkeys are for the king\u2019s household to ride on; and the bread and summer fruit for the young men to eat; and the wine, that such as are faint in the wilderness may drink.",
                "The king said, \"Where is your master\u2019s son?\" Ziba said to the king, \"Behold, he is staying in Jerusalem; for he said, \u2018Today the house of Israel will restore me the kingdom of my father.\u2019\"",
                "Then the king said to Ziba, \"Behold, all that pertains to Mephibosheth is yours.\" Ziba said, \"I do obeisance. Let me find favor in your sight, my lord, O king.\"",
                "When king David came to Bahurim, behold, a man of the family of the house of Saul came out, whose name was Shimei, the son of Gera. He came out, and cursed still as he came.",
                "He cast stones at David, and at all the servants of king David, and all the people and all the mighty men were on his right hand and on his left.",
                "Shimei said when he cursed, \"Be gone, be gone, you man of blood, and base fellow!",
                "Yahweh has returned on you all the blood of the house of Saul, in whose place you have reigned! Yahweh has delivered the kingdom into the hand of Absalom your son! Behold, you are caught by your own mischief, because you are a man of blood!\"",
                "Then Abishai the son of Zeruiah said to the king, \"Why should this dead dog curse my lord the king? Please let me go over and take off his head.\"",
                "The king said, \"What have I to do with you, you sons of Zeruiah? Because he curses, and because Yahweh has said to him, \u2018Curse David;\u2019 who then shall say, \u2018Why have you done so?\u2019\"",
                "David said to Abishai, and to all his servants, \"Behold, my son, who came forth from my bowels, seeks my life. How much more this Benjamite, now? Leave him alone, and let him curse; for Yahweh has invited him.",
                "It may be that Yahweh will look on the wrong done to me, and that Yahweh will repay me good for the cursing of me today.\"",
                "So David and his men went by the way; and Shimei went along on the hillside opposite him, and cursed as he went, threw stones at him, and threw dust.",
                "The king, and all the people who were with him, came weary; and he refreshed himself there.",
                "Absalom, and all the people, the men of Israel, came to Jerusalem, and Ahithophel with him.",
                "It happened, when Hushai the Archite, David\u2019s friend, had come to Absalom, that Hushai said to Absalom, \"Long live the king! Long live the king!\"",
                "Absalom said to Hushai, \"Is this your kindness to your friend? Why didn\u2019t you go with your friend?\"",
                "Hushai said to Absalom, \"No; but whomever Yahweh, and this people, and all the men of Israel have chosen, his will I be, and with him I will stay.",
                "Again, whom should I serve? Shouldn\u2019t I serve in the presence of his son? As I have served in your father\u2019s presence, so will I be in your presence.\"",
                "Then Absalom said to Ahithophel, \"Give your counsel what we shall do.\"",
                "Ahithophel said to Absalom, \"Go in to your father\u2019s concubines, that he has left to keep the house. Then all Israel will hear that you are abhorred by your father. Then the hands of all who are with you will be strong.\"",
                "So they spread Absalom a tent on the top of the house; and Absalom went in to his father\u2019s concubines in the sight of all Israel.",
                "The counsel of Ahithophel, which he gave in those days, was as if a man inquired at the oracle of God: so was all the counsel of Ahithophel both with David and with Absalom."
            ],
            17: [
                "Moreover Ahithophel said to Absalom, \"Let me now choose twelve thousand men, and I will arise and pursue after David tonight.",
                "I will come on him while he is weary and exhausted, and will make him afraid. All the people who are with him shall flee. I will strike the king only;",
                "and I will bring back all the people to you. The man whom you seek is as if all returned. All the people shall be in peace.\"",
                "The saying pleased Absalom well, and all the elders of Israel.",
                "Then Absalom said, \"Now call Hushai the Archite also, and let us hear likewise what he says.\"",
                "When Hushai had come to Absalom, Absalom spoke to him, saying, \"Ahithophel has spoken like this. Shall we do what he says? If not, speak up.\"",
                "Hushai said to Absalom, \"The counsel that Ahithophel has given this time is not good.\"",
                "Hushai said moreover, \"You know your father and his men, that they are mighty men, and they are fierce in their minds, like a bear robbed of her cubs in the field. Your father is a man of war, and will not lodge with the people.",
                "Behold, he is now hidden in some pit, or in some other place. It will happen, when some of them have fallen at the first, that whoever hears it will say, \u2018There is a slaughter among the people who follow Absalom!\u2019",
                "Even he who is valiant, whose heart is as the heart of a lion, will utterly melt; for all Israel knows that your father is a mighty man, and those who are with him are valiant men.",
                "But I counsel that all Israel be gathered together to you, from Dan even to Beersheba, as the sand that is by the sea for multitude; and that you go to battle in your own person.",
                "So shall we come on him in some place where he shall be found, and we will light on him as the dew falls on the ground; and of him and of all the men who are with him we will not leave so much as one.",
                "Moreover, if he be gone into a city, then shall all Israel bring ropes to that city, and we will draw it into the river, until there isn\u2019t one small stone found there.\"",
                "Absalom and all the men of Israel said, \"The counsel of Hushai the Archite is better than the counsel of Ahithophel.\" For Yahweh had ordained to defeat the good counsel of Ahithophel, to the intent that Yahweh might bring evil on Absalom.",
                "Then Hushai said to Zadok and to Abiathar the priests, \"Ahithophel counseled Absalom and the elders of Israel that way; and I have counseled this way.",
                "Now therefore send quickly, and tell David, saying, \u2018Don\u2019t lodge this night at the fords of the wilderness, but by all means pass over; lest the king be swallowed up, and all the people who are with him.\u2019\"",
                "Now Jonathan and Ahimaaz were staying by En Rogel; and a female servant used to go and tell them; and they went and told king David. For they might not be seen to come into the city.",
                "But a boy saw them, and told Absalom. Then they both went away quickly, and came to the house of a man in Bahurim, who had a well in his court; and they went down there.",
                "The woman took and spread the covering over the well\u2019s mouth, and spread out bruised grain on it; and nothing was known.",
                "Absalom\u2019s servants came to the woman to the house; and they said, \"Where are Ahimaaz and Jonathan?\" The woman said to them, \"They have gone over the brook of water.\" When they had sought and could not find them, they returned to Jerusalem.",
                "It happened, after they had departed, that they came up out of the well, and went and told king David; and they said to David, \"Arise and pass quickly over the water; for thus has Ahithophel counseled against you.\"",
                "Then David arose, and all the people who were with him, and they passed over the Jordan. By the morning light there lacked not one of them who had not gone over the Jordan.",
                "When Ahithophel saw that his counsel was not followed, he saddled his donkey, and arose, and went home, to his city, and set his house in order, and hanged himself; and he died, and was buried in the tomb of his father.",
                "Then David came to Mahanaim. Absalom passed over the Jordan, he and all the men of Israel with him.",
                "Absalom set Amasa over the army instead of Joab. Now Amasa was the son of a man, whose name was Ithra the Israelite, who went in to Abigail the daughter of Nahash, sister to Zeruiah, Joab\u2019s mother.",
                "Israel and Absalom encamped in the land of Gilead.",
                "It happened, when David had come to Mahanaim, that Shobi the son of Nahash of Rabbah of the children of Ammon, and Machir the son of Ammiel of Lodebar, and Barzillai the Gileadite of Rogelim,",
                "brought beds, basins, earthen vessels, wheat, barley, meal, parched grain, beans, lentils, roasted grain,",
                "honey, butter, sheep, and cheese of the herd, for David, and for the people who were with him, to eat: for they said, \"The people are hungry, and weary, and thirsty, in the wilderness.\""
            ],
            18: [
                "David numbered the people who were with him, and set captains of thousands and captains of hundreds over them.",
                "David sent forth the people, a third part under the hand of Joab, and a third part under the hand of Abishai the son of Zeruiah, Joab\u2019s brother, and a third part under the hand of Ittai the Gittite. The king said to the people, \"I will surely go forth with you myself also.\"",
                "But the people said, \"You shall not go forth; for if we flee away, they will not care for us; neither if half of us die, will they care for us. But you are worth ten thousand of us. Therefore now it is better that you are ready to help us out of the city.\"",
                "The king said to them, \"I will do what seems best to you.\" The king stood beside the gate, and all the people went out by hundreds and by thousands.",
                "The king commanded Joab and Abishai and Ittai, saying, \"Deal gently for my sake with the young man, even with Absalom.\" All the people heard when the king commanded all the captains concerning Absalom.",
                "So the people went out into the field against Israel: and the battle was in the forest of Ephraim.",
                "The people of Israel were struck there before the servants of David, and there was a great slaughter there that day of twenty thousand men.",
                "For the battle was there spread over the surface of all the country; and the forest devoured more people that day than the sword devoured.",
                "Absalom happened to meet the servants of David. Absalom was riding on his mule, and the mule went under the thick boughs of a great oak, and his head caught hold of the oak, and he was taken up between the sky and earth; and the mule that was under him went on.",
                "A certain man saw it, and told Joab, and said, \"Behold, I saw Absalom hanging in an oak.\"",
                "Joab said to the man who told him, \"Behold, you saw it, and why didn\u2019t you strike him there to the ground? I would have given you ten pieces of silver, and a sash.\"",
                "The man said to Joab, \"Though I should receive a thousand pieces of silver in my hand, I still wouldn\u2019t put forth my hand against the king\u2019s son; for in our hearing the king commanded you and Abishai and Ittai, saying, \u2018Beware that none touch the young man Absalom.\u2019",
                "Otherwise if I had dealt falsely against his life (and there is no matter hidden from the king), then you yourself would have set yourself against me.\"",
                "Then Joab said, \"I\u2019m not going to wait like this with you.\" He took three darts in his hand, and thrust them through the heart of Absalom, while he was yet alive in the midst of the oak.",
                "Ten young men who bore Joab\u2019s armor surrounded and struck Absalom, and killed him.",
                "Joab blew the trumpet, and the people returned from pursuing after Israel; for Joab held back the people.",
                "They took Absalom, and cast him into the great pit in the forest, and raised over him a very great heap of stones. Then all Israel fled everyone to his tent.",
                "Now Absalom in his lifetime had taken and reared up for himself the pillar, which is in the king\u2019s dale; for he said, \"I have no son to keep my name in memory.\" He called the pillar after his own name; and it is called Absalom\u2019s monument, to this day.",
                "Then Ahimaaz the son of Zadok said, \"Let me now run, and bear the king news, how that Yahweh has avenged him of his enemies.\"",
                "Joab said to him, \"You shall not be the bearer of news this day, but you shall bear news another day. But today you shall bear no news, because the king\u2019s son is dead.\"",
                "Then Joab said to the Cushite, \"Go, tell the king what you have seen!\" The Cushite bowed himself to Joab, and ran.",
                "Then Ahimaaz the son of Zadok said yet again to Joab, \"But come what may, please let me also run after the Cushite.\" Joab said, \"Why do you want to run, my son, since that you will have no reward for the news?\"",
                "\"But come what may,\" he said, \"I will run.\" He said to him, \"Run!\" Then Ahimaaz ran by the way of the Plain, and outran the Cushite.",
                "Now David was sitting between the two gates: and the watchman went up to the roof of the gate to the wall, and lifted up his eyes, and looked, and, behold, a man running alone.",
                "The watchman cried, and told the king. The king said, \"If he is alone, there is news in his mouth.\" He came closer and closer.",
                "The watchman saw another man running; and the watchman called to the porter, and said, \"Behold, a man running alone!\" The king said, \"He also brings news.\"",
                "The watchman said, \"I think the running of the first one is like the running of Ahimaaz the son of Zadok.\" The king said, \"He is a good man, and comes with good news.\"",
                "Ahimaaz called, and said to the king, \"All is well.\" He bowed himself before the king with his face to the earth, and said, \"Blessed is Yahweh your God, who has delivered up the men who lifted up their hand against my lord the king!\"",
                "The king said, \"Is it well with the young man Absalom?\" Ahimaaz answered, \"When Joab sent the king\u2019s servant, even me your servant, I saw a great tumult, but I don\u2019t know what it was.\"",
                "The king said, \"Turn aside, and stand here.\" He turned aside, and stood still.",
                "Behold, the Cushite came. The Cushite said, \"News for my lord the king; for Yahweh has avenged you this day of all those who rose up against you.\"",
                "The king said to the Cushite, \"Is it well with the young man Absalom?\" The Cushite answered, \"May the enemies of my lord the king, and all who rise up against you to do you harm, be as that young man is.\"",
                "The king was much moved, and went up to the room over the gate, and wept. As he went, he said, \"My son Absalom! My son, my son Absalom! I wish I had died for you, Absalom, my son, my son!\""
            ],
            19: [
                "It was told Joab, \"Behold, the king weeps and mourns for Absalom.\"",
                "The victory that day was turned into mourning to all the people; for the people heard it said that day, \"The king grieves for his son.\"",
                "The people sneaked into the city that day, as people who are ashamed steal away when they flee in battle.",
                "The king covered his face, and the king cried with a loud voice, \"My son Absalom, Absalom, my son, my son!\"",
                "Joab came into the house to the king, and said, \"You have shamed this day the faces of all your servants, who this day have saved your life, and the lives of your sons and of your daughters, and the lives of your wives, and the lives of your concubines;",
                "in that you love those who hate you, and hate those who love you. For you have declared this day, that princes and servants are nothing to you. For today I perceive that if Absalom had lived, and all we had died this day, then it would have pleased you well.",
                "Now therefore arise, go out, and speak to comfort your servants; for I swear by Yahweh, if you don\u2019t go out, not a man will stay with you this night. That would be worse to you than all the evil that has happened to you from your youth until now.\"",
                "Then the king arose, and sat in the gate. They told to all the people, saying, \"Behold, the king is sitting in the gate.\" All the people came before the king. Now Israel had fled every man to his tent.",
                "All the people were at strife throughout all the tribes of Israel, saying, \"The king delivered us out of the hand of our enemies, and he saved us out of the hand of the Philistines; and now he has fled out of the land from Absalom.",
                "Absalom, whom we anointed over us, is dead in battle. Now therefore why don\u2019t you speak a word of bringing the king back?\"",
                "King David sent to Zadok and to Abiathar the priests, saying, \"Speak to the elders of Judah, saying, \u2018Why are you the last to bring the king back to his house? Since the speech of all Israel has come to the king, to return him to his house.",
                "You are my brothers, you are my bone and my flesh. Why then are you the last to bring back the king?\u2019",
                "Say to Amasa, \u2018Aren\u2019t you my bone and my flesh? God do so to me, and more also, if you aren\u2019t captain of the army before me continually in the room of Joab.\u2019\"",
                "He bowed the heart of all the men of Judah, even as one man; so that they sent to the king, saying, \"Return, you and all your servants.\"",
                "So the king returned, and came to the Jordan. Judah came to Gilgal, to go to meet the king, to bring the king over the Jordan.",
                "Shimei the son of Gera, the Benjamite, who was of Bahurim, hurried and came down with the men of Judah to meet king David.",
                "There were a thousand men of Benjamin with him, and Ziba the servant of the house of Saul, and his fifteen sons and his twenty servants with him; and they went through the Jordan in the presence of the king.",
                "A ferry boat went to bring over the king\u2019s household, and to do what he thought good. Shimei the son of Gera fell down before the king, when he had come over the Jordan.",
                "He said to the king, \"Don\u2019t let my lord impute iniquity to me, nor remember that which your servant did perversely the day that my lord the king went out of Jerusalem, that the king should take it to his heart.",
                "For your servant knows that I have sinned. Therefore, behold, I have come this day the first of all the house of Joseph to go down to meet my lord the king.\"",
                "But Abishai the son of Zeruiah answered, \"Shall Shimei not be put to death for this, because he cursed Yahweh\u2019s anointed?\"",
                "David said, \"What have I to do with you, you sons of Zeruiah, that you should this day be adversaries to me? Shall there any man be put to death this day in Israel? For don\u2019t I know that I am this day king over Israel?\"",
                "The king said to Shimei, \"You shall not die.\" The king swore to him.",
                "Mephibosheth the son of Saul came down to meet the king; and he had neither groomed his feet, nor trimmed his beard, nor washed his clothes, from the day the king departed until the day he came home in peace.",
                "It happened, when he had come to Jerusalem to meet the king, that the king said to him, \"Why didn\u2019t you go with me, Mephibosheth?\"",
                "He answered, \"My lord, O king, my servant deceived me. For your servant said, I will saddle me a donkey, that I may ride thereon, and go with the king; because your servant is lame.",
                "He has slandered your servant to my lord the king; but my lord the king is as an angel of God. Do therefore what is good in your eyes.",
                "For all my father\u2019s house were but dead men before my lord the king; yet you set your servant among those who ate at your own table. What right therefore have I yet that I should cry any more to the king?\"",
                "The king said to him, \"Why do you speak any more of your matters? I say, you and Ziba divide the land.\"",
                "Mephibosheth said to the king, \"Yes, let him take all, because my lord the king has come in peace to his own house.\"",
                "Barzillai the Gileadite came down from Rogelim; and he went over the Jordan with the king, to conduct him over the Jordan.",
                "Now Barzillai was a very aged man, even eighty years old: and he had provided the king with sustenance while he lay at Mahanaim; for he was a very great man.",
                "The king said to Barzillai, \"Come over with me, and I will sustain you with me in Jerusalem.\"",
                "Barzillai said to the king, \"How many are the days of the years of my life, that I should go up with the king to Jerusalem?",
                "I am this day eighty years old. Can I discern between good and bad? Can your servant taste what I eat or what I drink? Can I hear any more the voice of singing men and singing women? Why then should your servant be yet a burden to my lord the king?",
                "Your servant would but just go over the Jordan with the king. Why should the king repay me with such a reward?",
                "Please let your servant turn back again, that I may die in my own city, by the grave of my father and my mother. But behold, your servant Chimham; let him go over with my lord the king; and do to him what shall seem good to you.\"",
                "The king answered, \"Chimham shall go over with me, and I will do to him that which shall seem good to you. Whatever you require of me, that I will do for you.\"",
                "All the people went over the Jordan, and the king went over. Then the king kissed Barzillai, and blessed him; and he returned to his own place.",
                "So the king went over to Gilgal, and Chimham went over with him. All the people of Judah brought the king over, and also half the people of Israel.",
                "Behold, all the men of Israel came to the king, and said to the king, \"Why have our brothers the men of Judah stolen you away, and brought the king, and his household, over the Jordan, and all David\u2019s men with him?\"",
                "All the men of Judah answered the men of Israel, \"Because the king is a close relative to us. Why then are you angry about this matter? Have we eaten at all at the king\u2019s cost? Or has he given us any gift?\"",
                "The men of Israel answered the men of Judah, and said, \"We have ten parts in the king, and we have also more claim to David than you. Why then did you despise us, that our advice should not be first had in bringing back our king?\" The words of the men of Judah were fiercer than the words of the men of Israel."
            ],
            20: [
                "There happened to be there a base fellow, whose name was Sheba, the son of Bichri, a Benjamite: and he blew the trumpet, and said, \"We have no portion in David, neither have we inheritance in the son of Jesse. Every man to his tents, Israel!\"",
                "So all the men of Israel went up from following David, and followed Sheba the son of Bichri; but the men of Judah joined with their king, from the Jordan even to Jerusalem.",
                "David came to his house at Jerusalem; and the king took the ten women his concubines, whom he had left to keep the house, and put them in custody, and provided them with sustenance, but didn\u2019t go in to them. So they were shut up to the day of their death, living in widowhood.",
                "Then the king said to Amasa, \"Call me the men of Judah together within three days, and be here present.\"",
                "So Amasa went to call the men of Judah together; but he stayed longer than the set time which he had appointed him.",
                "David said to Abishai, \"Now Sheba the son of Bichri will do us more harm than Absalom did. Take your lord\u2019s servants, and pursue after him, lest he get himself fortified cities, and escape out of our sight.\"",
                "There went out after him Joab\u2019s men, and the Cherethites and the Pelethites, and all the mighty men; and they went out of Jerusalem, to pursue after Sheba the son of Bichri.",
                "When they were at the great stone which is in Gibeon, Amasa came to meet them. Joab was clothed in his apparel of war that he had put on, and on it was a sash with a sword fastened on his waist in its sheath; and as he went forth it fell out.",
                "Joab said to Amasa, \"Is it well with you, my brother?\" Joab took Amasa by the beard with his right hand to kiss him.",
                "But Amasa took no heed to the sword that was in Joab\u2019s hand. So he struck him with it in the body, and shed out his bowels to the ground, and didn\u2019t strike him again; and he died. Joab and Abishai his brother pursued after Sheba the son of Bichri.",
                "There stood by him one of Joab\u2019s young men, and said, \"He who favors Joab, and he who is for David, let him follow Joab!\"",
                "Amasa lay wallowing in his blood in the midst of the highway. When the man saw that all the people stood still, he carried Amasa out of the highway into the field, and cast a garment over him, when he saw that everyone who came by him stood still.",
                "When he was removed out of the highway, all the people went on after Joab, to pursue after Sheba the son of Bichri.",
                "He went through all the tribes of Israel to Abel, and to Beth Maacah, and all the Berites: and they were gathered together, and went also after him.",
                "They came and besieged him in Abel of Beth Maacah, and they cast up a mound against the city, and it stood against the rampart; and all the people who were with Joab battered the wall, to throw it down.",
                "Then a wise woman cried out of the city, \"Hear, hear! Please say to Joab, \u2018Come near here, that I may speak with you.\u2019\"",
                "He came near to her; and the woman said, \"Are you Joab?\" He answered, \"I am.\" Then she said to him, \"Hear the words of your handmaid.\" He answered, \"I do hear.\"",
                "Then she spoke, saying, \"They were used to say in old times, \u2018They shall surely ask counsel at Abel;\u2019 and so they settled it.",
                "I am among those who are peaceable and faithful in Israel. You seek to destroy a city and a mother in Israel. Why will you swallow up the inheritance of Yahweh?\"",
                "Joab answered, \"Far be it, far be it from me, that I should swallow up or destroy.",
                "The matter is not so. But a man of the hill country of Ephraim, Sheba the son of Bichri by name, has lifted up his hand against the king, even against David. Deliver him only, and I will depart from the city.\" The woman said to Joab, \"Behold, his head shall be thrown to you over the wall.\"",
                "Then the woman went to all the people in her wisdom. They cut off the head of Sheba the son of Bichri, and threw it out to Joab. He blew the trumpet, and they were dispersed from the city, every man to his tent. Joab returned to Jerusalem to the king.",
                "Now Joab was over all the army of Israel; and Benaiah the son of Jehoiada was over the Cherethites and over the Pelethites;",
                "and Adoram was over the men subject to forced labor; and Jehoshaphat the son of Ahilud was the recorder;",
                "and Sheva was scribe; and Zadok and Abiathar were priests;",
                "and also Ira the Jairite was chief minister to David."
            ],
            21: [
                "There was a famine in the days of David three years, year after year; and David sought the face of Yahweh. Yahweh said, \"It is for Saul, and for his bloody house, because he put to death the Gibeonites.\"",
                "The king called the Gibeonites, and said to them (now the Gibeonites were not of the children of Israel, but of the remnant of the Amorites; and the children of Israel had sworn to them: and Saul sought to kill them in his zeal for the children of Israel and Judah);",
                "and David said to the Gibeonites, \"What shall I do for you? And with what shall I make atonement, that you may bless the inheritance of Yahweh?\"",
                "The Gibeonites said to him, \"It is no matter of silver or gold between us and Saul, or his house; neither is it for us to put any man to death in Israel.\" He said, \"Whatever you say, that will I do for you.\"",
                "They said to the king, \"The man who consumed us, and who devised against us, that we should be destroyed from remaining in any of the borders of Israel,",
                "let seven men of his sons be delivered to us, and we will hang them up to Yahweh in Gibeah of Saul, the chosen of Yahweh.\" The king said, \"I will give them.\"",
                "But the king spared Mephibosheth, the son of Jonathan the son of Saul, because of Yahweh\u2019s oath that was between them, between David and Jonathan the son of Saul.",
                "But the king took the two sons of Rizpah the daughter of Aiah, whom she bore to Saul, Armoni and Mephibosheth; and the five sons of Michal the daughter of Saul, whom she bore to Adriel the son of Barzillai the Meholathite.",
                "He delivered them into the hands of the Gibeonites, and they hanged them in the mountain before Yahweh, and all seven of them fell together. They were put to death in the days of harvest, in the first days, at the beginning of barley harvest.",
                "Rizpah the daughter of Aiah took sackcloth, and spread it for her on the rock, from the beginning of harvest until water was poured on them from the sky. She allowed neither the birds of the sky to rest on them by day, nor the animals of the field by night.",
                "It was told David what Rizpah the daughter of Aiah, the concubine of Saul, had done.",
                "David went and took the bones of Saul and the bones of Jonathan his son from the men of Jabesh Gilead, who had stolen them from the street of Beth Shan, where the Philistines had hanged them, in the day that the Philistines killed Saul in Gilboa;",
                "and he brought up from there the bones of Saul and the bones of Jonathan his son: and they gathered the bones of those who were hanged.",
                "They buried the bones of Saul and Jonathan his son in the country of Benjamin in Zela, in the tomb of Kish his father: and they performed all that the king commanded. After that God was entreated for the land.",
                "The Philistines had war again with Israel; and David went down, and his servants with him, and fought against the Philistines. David grew faint;",
                "and Ishbibenob, who was of the sons of the giant, the weight of whose spear was three hundred shekels of brass in weight, he being armed with a new sword, thought to have slain David.",
                "But Abishai the son of Zeruiah helped him, and struck the Philistine, and killed him. Then the men of David swore to him, saying, \"You shall go no more out with us to battle, that you don\u2019t quench the lamp of Israel.\"",
                "It came to pass after this, that there was again war with the Philistines at Gob: then Sibbecai the Hushathite killed Saph, who was of the sons of the giant.",
                "There was again war with the Philistines at Gob; and Elhanan the son of Jaareoregim the Bethlehemite killed Goliath the Gittite\u2019s brother, the staff of whose spear was like a weaver\u2019s beam.",
                "There was again war at Gath, where there was a man of great stature, who had on every hand six fingers, and on every foot six toes, four and twenty in number; and he also was born to the giant.",
                "When he defied Israel, Jonathan the son of Shimei, David\u2019s brother, killed him.",
                "These four were born to the giant in Gath; and they fell by the hand of David, and by the hand of his servants."
            ],
            22: [
                "David spoke to Yahweh the words of this song in the day that Yahweh delivered him out of the hand of all his enemies, and out of the hand of Saul:",
                "and he said, \"Yahweh is my rock, my fortress, and my deliverer, even mine;",
                "God, my rock, in him I will take refuge; my shield, and the horn of my salvation, my high tower, and my refuge. My savior, you save me from violence.",
                "I will call on Yahweh, who is worthy to be praised: So shall I be saved from my enemies.",
                "For the waves of death surrounded me. The floods of ungodliness made me afraid.",
                "The cords of Sheol were around me. The snares of death caught me.",
                "In my distress I called on Yahweh. Yes, I called to my God. He heard my voice out of his temple. My cry came into his ears.",
                "Then the earth shook and trembled. The foundations of heaven quaked and were shaken, because he was angry.",
                "Smoke went up out of his nostrils. Fire out of his mouth devoured. Coals were kindled by it.",
                "He bowed the heavens also, and came down. Thick darkness was under his feet.",
                "He rode on a cherub, and flew. Yes, he was seen on the wings of the wind.",
                "He made darkness pavilions around himself: gathering of waters, and thick clouds of the skies.",
                "At the brightness before him, coals of fire were kindled.",
                "Yahweh thundered from heaven. The Most High uttered his voice.",
                "He sent out arrows, and scattered them; lightning, and confused them.",
                "Then the channels of the sea appeared. The foundations of the world were laid bare by the rebuke of Yahweh, At the blast of the breath of his nostrils.",
                "He sent from on high and he took me. He drew me out of many waters.",
                "He delivered me from my strong enemy, from those who hated me, for they were too mighty for me.",
                "They came on me in the day of my calamity, but Yahweh was my support.",
                "He also brought me out into a large place. He delivered me, because he delighted in me.",
                "Yahweh rewarded me according to my righteousness. He rewarded me according to the cleanness of my hands.",
                "For I have kept the ways of Yahweh, and have not wickedly departed from my God.",
                "For all his ordinances were before me. As for his statutes, I did not depart from them.",
                "I was also perfect toward him. I kept myself from my iniquity.",
                "Therefore Yahweh has rewarded me according to my righteousness, According to my cleanness in his eyesight.",
                "With the merciful you will show yourself merciful. With the perfect man you will show yourself perfect.",
                "With the pure you will show yourself pure. With the crooked you will show yourself shrewd.",
                "You will save the afflicted people, But your eyes are on the haughty, that you may bring them down.",
                "For you are my lamp, Yahweh. Yahweh will light up my darkness.",
                "For by you, I run against a troop. By my God, I leap over a wall.",
                "As for God, his way is perfect. The word of Yahweh is tested. He is a shield to all those who take refuge in him.",
                "For who is God, besides Yahweh? Who is a rock, besides our God?",
                "God is my strong fortress. He makes my way perfect.",
                "He makes his feet like hinds\u2019 feet, and sets me on my high places.",
                "He teaches my hands to war, so that my arms bend a bow of brass.",
                "You have also given me the shield of your salvation. Your gentleness has made me great.",
                "You have enlarged my steps under me. My feet have not slipped.",
                "I have pursued my enemies and destroyed them. I didn\u2019t turn again until they were consumed.",
                "I have consumed them, and struck them through, so that they can\u2019t arise. Yes, they have fallen under my feet.",
                "For you have armed me with strength for the battle. You have subdued under me those who rose up against me.",
                "You have also made my enemies turn their backs to me, that I might cut off those who hate me.",
                "They looked, but there was none to save; even to Yahweh, but he didn\u2019t answer them.",
                "Then I beat them as small as the dust of the earth. I crushed them as the mire of the streets, and spread them abroad.",
                "You also have delivered me from the strivings of my people. You have kept me to be the head of the nations. A people whom I have not known will serve me.",
                "The foreigners will submit themselves to me. As soon as they hear of me, they will obey me.",
                "The foreigners will fade away, and will come trembling out of their close places.",
                "Yahweh lives! Blessed be my rock! Exalted be God, the rock of my salvation,",
                "even the God who executes vengeance for me, who brings down peoples under me,",
                "who brings me away from my enemies. Yes, you lift me up above those who rise up against me. You deliver me from the violent man.",
                "Therefore I will give thanks to you, Yahweh, among the nations. Will sing praises to your name.",
                "He gives great deliverance to his king, and shows loving kindness to his anointed, to David and to his seed, forevermore.\""
            ],
            23: [
                "Now these are the last words of David. David the son of Jesse says, the man who was raised on high says, the anointed of the God of Jacob, the sweet psalmist of Israel:",
                "\"The Spirit of Yahweh spoke by me. His word was on my tongue.",
                "The God of Israel said, the Rock of Israel spoke to me, \u2018One who rules over men righteously, who rules in the fear of God,",
                "shall be as the light of the morning, when the sun rises, a morning without clouds, when the tender grass springs out of the earth, through clear shining after rain.\u2019",
                "Most certainly my house is not so with God, yet he has made with me an everlasting covenant, ordered in all things, and sure, for it is all my salvation, and all my desire, although he doesn\u2019t make it grow.",
                "But all of the ungodly shall be as thorns to be thrust away, because they can\u2019t be taken with the hand,",
                "But the man who touches them must be armed with iron and the staff of a spear. They shall be utterly burned with fire in their place.\"",
                "These are the names of the mighty men whom David had: Josheb Basshebeth a Tahchemonite, chief of the captains; the same was Adino the Eznite, against eight hundred slain at one time.",
                "After him was Eleazar the son of Dodai the son of an Ahohite, one of the three mighty men with David, when they defied the Philistines who were there gathered together to battle, and the men of Israel were gone away.",
                "He arose, and struck the Philistines until his hand was weary, and his hand froze to the sword; and Yahweh worked a great victory that day; and the people returned after him only to take spoil.",
                "After him was Shammah the son of Agee a Hararite. The Philistines were gathered together into a troop, where there was a plot of ground full of lentils; and the people fled from the Philistines.",
                "But he stood in the midst of the plot, and defended it, and killed the Philistines; and Yahweh worked a great victory.",
                "Three of the thirty chief men went down, and came to David in the harvest time to the cave of Adullam; and the troop of the Philistines was encamped in the valley of Rephaim.",
                "David was then in the stronghold; and the garrison of the Philistines was then in Bethlehem.",
                "David longed, and said, \"Oh that one would give me water to drink of the well of Bethlehem, which is by the gate!\"",
                "The three mighty men broke through the army of the Philistines, and drew water out of the well of Bethlehem, that was by the gate, and took it, and brought it to David: but he would not drink of it, but poured it out to Yahweh.",
                "He said, \"Be it far from me, Yahweh, that I should do this! Isn\u2019t it the blood of the men who went in jeopardy of their lives?\" Therefore he would not drink it. The three mighty men did these things.",
                "Abishai, the brother of Joab, the son of Zeruiah, was chief of the three. He lifted up his spear against three hundred and killed them, and had a name among the three.",
                "Wasn\u2019t he most honorable of the three? therefore he was made their captain: however he didn\u2019t attain to the three.",
                "Benaiah the son of Jehoiada, the son of a valiant man of Kabzeel, who had done mighty deeds, he killed the two sons of Ariel of Moab: he went down also and killed a lion in the midst of a pit in time of snow.",
                "He killed an Egyptian, a goodly man: and the Egyptian had a spear in his hand; but he went down to him with a staff, and plucked the spear out of the Egyptian\u2019s hand, and killed him with his own spear.",
                "Benaiah the son of Jehoiada did these things, and had a name among the three mighty men.",
                "He was more honorable than the thirty, but he didn\u2019t attain to the three. David set him over his guard.",
                "Asahel the brother of Joab was one of the thirty; Elhanan the son of Dodo of Bethlehem,",
                "Shammah the Harodite, Elika the Harodite,",
                "Helez the Paltite, Ira the son of Ikkesh the Tekoite,",
                "Abiezer the Anathothite, Mebunnai the Hushathite,",
                "Zalmon the Ahohite, Maharai the Netophathite,",
                "Heleb the son of Baanah the Netophathite, Ittai the son of Ribai of Gibeah of the children of Benjamin,",
                "Benaiah a Pirathonite, Hiddai of the brooks of Gaash.",
                "Abialbon the Arbathite, Azmaveth the Barhumite,",
                "Eliahba the Shaalbonite, the sons of Jashen, Jonathan,",
                "Shammah the Hararite, Ahiam the son of Sharar the Ararite,",
                "Eliphelet the son of Ahasbai, the son of the Maacathite, Eliam the son of Ahithophel the Gilonite,",
                "Hezro the Carmelite, Paarai the Arbite,",
                "Igal the son of Nathan of Zobah, Bani the Gadite,",
                "Zelek the Ammonite, Naharai the Beerothite, armor bearers to Joab the son of Zeruiah,",
                "Ira the Ithrite, Gareb the Ithrite,",
                "Uriah the Hittite: thirty-seven in all."
            ],
            24: [
                "Again the anger of Yahweh was kindled against Israel, and he moved David against them, saying, \"Go, number Israel and Judah.\"",
                "The king said to Joab the captain of the army, who was with him, \"Now go back and forth through all the tribes of Israel, from Dan even to Beersheba, and number the people, that I may know the sum of the people.\"",
                "Joab said to the king, \"Now may Yahweh your God add to the people, however many they may be, one hundred times; and may the eyes of my lord the king see it. But why does my lord the king delight in this thing?\"",
                "Notwithstanding, the king\u2019s word prevailed against Joab, and against the captains of the army. Joab and the captains of the army went out from the presence of the king, to number the people of Israel.",
                "They passed over the Jordan, and encamped in Aroer, on the right side of the city that is in the middle of the valley of Gad, and to Jazer:",
                "then they came to Gilead, and to the land of Tahtim Hodshi; and they came to Dan Jaan, and around to Sidon,",
                "and came to the stronghold of Tyre, and to all the cities of the Hivites, and of the Canaanites; and they went out to the south of Judah, at Beersheba.",
                "So when they had gone back and forth through all the land, they came to Jerusalem at the end of nine months and twenty days.",
                "Joab gave up the sum of the numbering of the people to the king: and there were in Israel eight hundred thousand valiant men who drew the sword; and the men of Judah were five hundred thousand men.",
                "David\u2019s heart struck him after that he had numbered the people. David said to Yahweh, \"I have sinned greatly in that which I have done. But now, Yahweh, put away, I beg you, the iniquity of your servant; for I have done very foolishly.\"",
                "When David rose up in the morning, the word of Yahweh came to the prophet Gad, David\u2019s seer, saying,",
                "\"Go and speak to David, \u2018Thus says Yahweh, \"I offer you three things. Choose one of them, that I may do it to you.\"\u2019\"",
                "So Gad came to David, and told him, and said to him, \"Shall seven years of famine come to you in your land? Or will you flee three months before your foes while they pursue you? Or shall there be three days\u2019 pestilence in your land? Now answer, and consider what answer I shall return to him who sent me.\"",
                "David said to Gad, \"I am in distress. Let us fall now into the hand of Yahweh; for his mercies are great. Let me not fall into the hand of man.\"",
                "So Yahweh sent a pestilence on Israel from the morning even to the appointed time; and there died of the people from Dan even to Beersheba seventy thousand men.",
                "When the angel stretched out his hand toward Jerusalem to destroy it, Yahweh relented of the disaster, and said to the angel who destroyed the people, \"It is enough. Now stay your hand.\" The angel of Yahweh was by the threshing floor of Araunah the Jebusite.",
                "David spoke to Yahweh when he saw the angel who struck the people, and said, \"Behold, I have sinned, and I have done perversely; but these sheep, what have they done? Please let your hand be against me, and against my father\u2019s house.\"",
                "Gad came that day to David, and said to him, \"Go up, build an altar to Yahweh on the threshing floor of Araunah the Jebusite.\"",
                "David went up according to the saying of Gad, as Yahweh commanded.",
                "Araunah looked out, and saw the king and his servants coming on toward him. Then Araunah went out, and bowed himself before the king with his face to the ground.",
                "Araunah said, \"Why has my lord the king come to his servant?\" David said, \"To buy your threshing floor, to build an altar to Yahweh, that the plague may be stopped from afflicting the people.\"",
                "Araunah said to David, \"Let my lord the king take and offer up what seems good to him. Behold, the cattle for the burnt offering, and the threshing instruments and the yokes of the oxen for the wood:",
                "all this, king, does Araunah give to the king.\" Araunah said to the king, \"May Yahweh your God accept you.\"",
                "The king said to Araunah, \"No; but I will most certainly buy it from you for a price. I will not offer burnt offerings to Yahweh my God which cost me nothing.\" So David bought the threshing floor and the oxen for fifty shekels of silver.",
                "David built an altar to Yahweh there, and offered burnt offerings and peace offerings. So Yahweh was entreated for the land, and the plague was stayed from Israel."
            ]
        },
        11: {
            1: [
                "Now king David was old and stricken in years; and they covered him with clothes, but he couldn\u2019t keep warm.",
                "Therefore his servants said to him, \"Let there be sought for my lord the king a young virgin. Let her stand before the king, and cherish him; and let her lie in your bosom, that my lord the king may keep warm.\"",
                "So they sought for a beautiful young lady throughout all the borders of Israel, and found Abishag the Shunammite, and brought her to the king.",
                "The young lady was very beautiful; and she cherished the king, and ministered to him; but the king didn\u2019t know her intimately.",
                "Then Adonijah the son of Haggith exalted himself, saying, \"I will be king.\" Then he prepared him chariots and horsemen, and fifty men to run before him.",
                "His father had not displeased him at any time in saying, \"Why have you done so?\" and he was also a very handsome man; and he was born after Absalom.",
                "He conferred with Joab the son of Zeruiah, and with Abiathar the priest: and they following Adonijah helped him.",
                "But Zadok the priest, and Benaiah the son of Jehoiada, and Nathan the prophet, and Shimei, and Rei, and the mighty men who belonged to David, were not with Adonijah.",
                "Adonijah killed sheep and cattle and fatlings by the stone of Zoheleth, which is beside En Rogel; and he called all his brothers, the king\u2019s sons, and all the men of Judah, the king\u2019s servants:",
                "but Nathan the prophet, and Benaiah, and the mighty men, and Solomon his brother, he didn\u2019t call.",
                "Then Nathan spoke to Bathsheba the mother of Solomon, saying, \"Haven\u2019t you heard that Adonijah the son of Haggith reigns, and David our lord doesn\u2019t know it?",
                "Now therefore come, please let me give you counsel, that you may save your own life, and the life of your son Solomon.",
                "Go in to king David, and tell him, \u2018Didn\u2019t you, my lord, king, swear to your handmaid, saying, Assuredly Solomon your son shall reign after me, and he shall sit on my throne? Why then does Adonijah reign?\u2019",
                "Behold, while you yet talk there with the king, I also will come in after you, and confirm your words.\"",
                "Bathsheba went in to the king into the room. The king was very old; and Abishag the Shunammite was ministering to the king.",
                "Bathsheba bowed, and showed respect to the king. The king said, \"What would you like?\"",
                "She said to him, \"My lord, you swore by Yahweh your God to your handmaid, \u2018Assuredly Solomon your son shall reign after me, and he shall sit on my throne.\u2019",
                "Now, behold, Adonijah reigns; and you, my lord the king, don\u2019t know it.",
                "He has slain cattle and fatlings and sheep in abundance, and has called all the sons of the king, and Abiathar the priest, and Joab the captain of the army; but he hasn\u2019t called Solomon your servant.",
                "You, my lord the king, the eyes of all Israel are on you, that you should tell them who shall sit on the throne of my lord the king after him.",
                "Otherwise it will happen, when my lord the king shall sleep with his fathers, that I and my son Solomon shall be counted offenders.\"",
                "Behold, while she yet talked with the king, Nathan the prophet came in.",
                "They told the king, saying, \"Behold, Nathan the prophet!\" When he had come in before the king, he bowed himself before the king with his face to the ground.",
                "Nathan said, \"My lord, king, have you said, \u2018Adonijah shall reign after me, and he shall sit on my throne?\u2019",
                "For he is gone down this day, and has slain cattle and fatlings and sheep in abundance, and has called all the king\u2019s sons, and the captains of the army, and Abiathar the priest. Behold, they are eating and drinking before him, and say, \u2018Long live king Adonijah!\u2019",
                "But he hasn\u2019t called me, even me your servant, and Zadok the priest, and Benaiah the son of Jehoiada, and your servant Solomon.",
                "Is this thing done by my lord the king, and you haven\u2019t shown to your servants who should sit on the throne of my lord the king after him?\"",
                "Then king David answered, \"Call to me Bathsheba.\" She came into the king\u2019s presence, and stood before the king.",
                "The king swore, and said, \"As Yahweh lives, who has redeemed my soul out of all adversity,",
                "most certainly as I swore to you by Yahweh, the God of Israel, saying, \u2018Assuredly Solomon your son shall reign after me, and he shall sit on my throne in my place;\u2019 most certainly so will I do this day.\"",
                "Then Bathsheba bowed with her face to the earth, and showed respect to the king, and said, \"Let my lord king David live forever!\"",
                "King David said, \"Call to me Zadok the priest, Nathan the prophet, and Benaiah the son of Jehoiada.\" They came before the king.",
                "The king said to them, \"Take with you the servants of your lord, and cause Solomon my son to ride on my own mule, and bring him down to Gihon.",
                "Let Zadok the priest and Nathan the prophet anoint him there king over Israel. Blow the trumpet, and say, \u2018Long live king Solomon!\u2019",
                "Then you shall come up after him, and he shall come and sit on my throne; for he shall be king in my place. I have appointed him to be prince over Israel and over Judah.\"",
                "Benaiah the son of Jehoiada answered the king, and said, \"Amen. May Yahweh, the God of my lord the king, say so.",
                "As Yahweh has been with my lord the king, even so may he be with Solomon, and make his throne greater than the throne of my lord king David.\"",
                "So Zadok the priest, and Nathan the prophet, and Benaiah the son of Jehoiada, and the Cherethites and the Pelethites, went down, and caused Solomon to ride on king David\u2019s mule, and brought him to Gihon.",
                "Zadok the priest took the horn of oil out of the Tent, and anointed Solomon. They blew the trumpet; and all the people said, \"Long live king Solomon!\"",
                "All the people came up after him, and the people piped with pipes, and rejoiced with great joy, so that the earth shook with their sound.",
                "Adonijah and all the guests who were with him heard it as they had made an end of eating. When Joab heard the sound of the trumpet, he said, \"Why is this noise of the city being in an uproar?\"",
                "While he yet spoke, behold, Jonathan the son of Abiathar the priest came: and Adonijah said, \"Come in; for you are a worthy man, and bring good news.\"",
                "Jonathan answered Adonijah, \"Most certainly our lord king David has made Solomon king.",
                "The king has sent with him Zadok the priest, Nathan the prophet, Benaiah the son of Jehoiada, and the Cherethites and the Pelethites; and they have caused him to ride on the king\u2019s mule.",
                "Zadok the priest and Nathan the prophet have anointed him king in Gihon. They have come up from there rejoicing, so that the city rang again. This is the noise that you have heard.",
                "Also, Solomon sits on the throne of the kingdom.",
                "Moreover the king\u2019s servants came to bless our lord king David, saying, \u2018May your God make the name of Solomon better than your name, and make his throne greater than your throne;\u2019 and the king bowed himself on the bed.",
                "Also thus said the king, \u2018Blessed be Yahweh, the God of Israel, who has given one to sit on my throne this day, my eyes even seeing it.\u2019\"",
                "All the guests of Adonijah were afraid, and rose up, and each man went his way.",
                "Adonijah feared because of Solomon; and he arose, and went, and caught hold on the horns of the altar.",
                "It was told Solomon, saying, \"Behold, Adonijah fears king Solomon; for, behold, he has laid hold on the horns of the altar, saying, \u2018Let king Solomon swear to me first that he will not kill his servant with the sword.\u2019\"",
                "Solomon said, \"If he shows himself a worthy man, not a hair of him shall fall to the earth; but if wickedness be found in him, he shall die.\"",
                "So king Solomon sent, and they brought him down from the altar. He came and bowed down to king Solomon; and Solomon said to him, \"Go to your house.\""
            ],
            2: [
                "Now the days of David drew near that he should die; and he commanded Solomon his son, saying,",
                "\"I am going the way of all the earth. You be strong therefore, and show yourself a man;",
                "and keep the instruction of Yahweh your God, to walk in his ways, to keep his statutes, his commandments, his ordinances, and his testimonies, according to that which is written in the law of Moses, that you may prosper in all that you do, and wherever you turn yourself.",
                "That Yahweh may establish his word which he spoke concerning me, saying, \u2018If your children take heed to their way, to walk before me in truth with all their heart and with all their soul, there shall not fail you,\u2019 he said, \u2018a man on the throne of Israel.\u2019",
                "\"Moreover you know also what Joab the son of Zeruiah did to me, even what he did to the two captains of the armies of Israel, to Abner the son of Ner, and to Amasa the son of Jether, whom he killed, and shed the blood of war in peace, and put the blood of war on his sash that was about his waist, and in his shoes that were on his feet.",
                "Do therefore according to your wisdom, and don\u2019t let his gray head go down to Sheol in peace.",
                "But show kindness to the sons of Barzillai the Gileadite, and let them be of those who eat at your table; for so they came to me when I fled from Absalom your brother.",
                "\"Behold, there is with you Shimei the son of Gera, the Benjamite, of Bahurim, who cursed me with a grievous curse in the day when I went to Mahanaim; but he came down to meet me at the Jordan, and I swore to him by Yahweh, saying, \u2018I will not put you to death with the sword.\u2019",
                "Now therefore don\u2019t hold him guiltless, for you are a wise man; and you will know what you ought to do to him, and you shall bring his gray head down to Sheol with blood.\"",
                "David slept with his fathers, and was buried in the city of David.",
                "The days that David reigned over Israel were forty years; he reigned seven years in Hebron, and he reigned thirty-three years in Jerusalem.",
                "Solomon sat on the throne of David his father; and his kingdom was firmly established.",
                "Then Adonijah the son of Haggith came to Bathsheba the mother of Solomon. She said, \"Do you come peaceably?\" He said, \"Peaceably.",
                "He said moreover, I have something to tell you.\" She said, \"Say on.\"",
                "He said, \"You know that the kingdom was mine, and that all Israel set their faces on me, that I should reign. However the kingdom is turned around, and has become my brother\u2019s; for it was his from Yahweh.",
                "Now I ask one petition of you. Don\u2019t deny me.\" She said to him, \"Say on.\"",
                "He said, \"Please speak to Solomon the king (for he will not tell you \u2018no\u2019), that he give me Abishag the Shunammite as wife.\"",
                "Bathsheba said, \"Alright. I will speak for you to the king.\"",
                "Bathsheba therefore went to king Solomon, to speak to him for Adonijah. The king rose up to meet her, and bowed himself to her, and sat down on his throne, and caused a throne to be set for the king\u2019s mother; and she sat on his right hand.",
                "Then she said, \"I ask one small petition of you; don\u2019t deny me.\" The king said to her, \"Ask on, my mother; for I will not deny you.\"",
                "She said, \"Let Abishag the Shunammite be given to Adonijah your brother as wife.\"",
                "King Solomon answered his mother, \"Why do you ask Abishag the Shunammite for Adonijah? Ask for him the kingdom also; for he is my elder brother; even for him, and for Abiathar the priest, and for Joab the son of Zeruiah.\"",
                "Then king Solomon swore by Yahweh, saying, \"God do so to me, and more also, if Adonijah has not spoken this word against his own life.",
                "Now therefore as Yahweh lives, who has established me, and set me on the throne of David my father, and who has made me a house, as he promised, surely Adonijah shall be put to death this day.\"",
                "King Solomon sent by Benaiah the son of Jehoiada; and he fell on him, so that he died.",
                "To Abiathar the priest the king said, \"Go to Anathoth, to your own fields; for you are worthy of death. But I will not at this time put you to death, because you bore the ark of the Lord Yahweh before David my father, and because you were afflicted in all in which my father was afflicted.\"",
                "So Solomon thrust out Abiathar from being priest to Yahweh, that he might fulfill the word of Yahweh, which he spoke concerning the house of Eli in Shiloh.",
                "The news came to Joab; for Joab had turned after Adonijah, though he didn\u2019t turn after Absalom. Joab fled to the Tent of Yahweh, and caught hold on the horns of the altar.",
                "It was told king Solomon, \"Joab has fled to the Tent of Yahweh, and behold, he is by the altar.\" Then Solomon sent Benaiah the son of Jehoiada, saying, \"Go, fall on him.\"",
                "Benaiah came to the Tent of Yahweh, and said to him, \"Thus says the king, \u2018Come forth!\u2019\" He said, \"No; but I will die here.\" Benaiah brought the king word again, saying, \"Thus said Joab, and thus he answered me.\"",
                "The king said to him, \"Do as he has said, and fall on him, and bury him; that you may take away the blood, which Joab shed without cause, from me and from my father\u2019s house.",
                "Yahweh will return his blood on his own head, because he fell on two men more righteous and better than he, and killed them with the sword, and my father David didn\u2019t know it: Abner the son of Ner, captain of the army of Israel, and Amasa the son of Jether, captain of the army of Judah.",
                "So shall their blood return on the head of Joab, and on the head of his seed forever. But to David, and to his seed, and to his house, and to his throne, there shall be peace forever from Yahweh.\"",
                "Then Benaiah the son of Jehoiada went up, and fell on him, and killed him; and he was buried in his own house in the wilderness.",
                "The king put Benaiah the son of Jehoiada in his place over the army; and the king put Zadok the priest in the place of Abiathar.",
                "The king sent and called for Shimei, and said to him, \"Build yourself a house in Jerusalem, and dwell there, and don\u2019t go out from there anywhere.",
                "For on the day you go out, and pass over the brook Kidron, know for certain that you shall surely die: your blood shall be on your own head.\"",
                "Shimei said to the king, \"The saying is good. As my lord the king has said, so will your servant do.\" Shimei lived in Jerusalem many days.",
                "It happened at the end of three years, that two of the servants of Shimei ran away to Achish, son of Maacah, king of Gath. They told Shimei, saying, \"Behold, your servants are in Gath.\"",
                "Shimei arose, and saddled his donkey, and went to Gath to Achish, to seek his servants; and Shimei went, and brought his servants from Gath.",
                "It was told Solomon that Shimei had gone from Jerusalem to Gath, and had come again.",
                "The king sent and called for Shimei, and said to him, \"Didn\u2019t I adjure you by Yahweh, and warn you, saying, \u2018Know for certain, that on the day you go out, and walk abroad any where, you shall surely die?\u2019 You said to me, \u2018The saying that I have heard is good.\u2019",
                "Why then have you not kept the oath of Yahweh, and the commandment that I have instructed you with?\"",
                "The king said moreover to Shimei, \"You know all the wickedness which your heart is privy to, that you did to David my father. Therefore Yahweh shall return your wickedness on your own head.",
                "But king Solomon shall be blessed, and the throne of David shall be established before Yahweh forever.\"",
                "So the king commanded Benaiah the son of Jehoiada; and he went out, and fell on him, so that he died. The kingdom was established in the hand of Solomon."
            ],
            3: [
                "Solomon made affinity with Pharaoh king of Egypt, and took Pharaoh\u2019s daughter, and brought her into the city of David, until he had made an end of building his own house, and the house of Yahweh, and the wall of Jerusalem all around.",
                "Only the people sacrificed in the high places, because there was no house built for the name of Yahweh until those days.",
                "Solomon loved Yahweh, walking in the statutes of David his father: only he sacrificed and burnt incense in the high places.",
                "The king went to Gibeon to sacrifice there; for that was the great high place. Solomon offered a thousand burnt offerings on that altar.",
                "In Gibeon Yahweh appeared to Solomon in a dream by night; and God said, \"Ask what I shall give you.\"",
                "Solomon said, \"You have shown to your servant David my father great loving kindness, according as he walked before you in truth, and in righteousness, and in uprightness of heart with you. You have kept for him this great loving kindness, that you have given him a son to sit on his throne, as it is this day.",
                "Now, Yahweh my God, you have made your servant king instead of David my father. I am but a little child. I don\u2019t know how to go out or come in.",
                "Your servant is in the midst of your people which you have chosen, a great people, that can\u2019t be numbered nor counted for multitude.",
                "Give your servant therefore an understanding heart to judge your people, that I may discern between good and evil; for who is able to judge this your great people?\"",
                "The speech pleased the Lord, that Solomon had asked this thing.",
                "God said to him, \"Because you have asked this thing, and have not asked for yourself long life, neither have asked riches for yourself, nor have asked the life of your enemies, but have asked for yourself understanding to discern justice;",
                "behold, I have done according to your word. Behold, I have given you a wise and an understanding heart; so that there has been none like you before you, neither after you shall any arise like you.",
                "I have also given you that which you have not asked, both riches and honor, so that there shall not be any among the kings like you, all your days.",
                "If you will walk in my ways, to keep my statutes and my commandments, as your father David walked, then I will lengthen your days.\"",
                "Solomon awoke; and behold, it was a dream. Then he came to Jerusalem, and stood before the ark of the covenant of Yahweh, and offered up burnt offerings, offered peace offerings, and made a feast to all his servants.",
                "Then two women who were prostitutes came to the king, and stood before him.",
                "The one woman said, \"Oh, my lord, I and this woman dwell in one house. I delivered a child with her in the house.",
                "It happened the third day after I delivered, that this woman delivered also. We were together. There was no stranger with us in the house, just us two in the house.",
                "This woman\u2019s child died in the night, because she lay on it.",
                "She arose at midnight, and took my son from beside me, while your handmaid slept, and laid it in her bosom, and laid her dead child in my bosom.",
                "When I rose in the morning to nurse my child, behold, it was dead; but when I had looked at it in the morning, behold, it was not my son, whom I bore.\"",
                "The other woman said, \"No; but the living is my son, and the dead is your son.\" This said, \"No; but the dead is your son, and the living is my son.\" Thus they spoke before the king.",
                "Then the king said, \"The one says, \u2018This is my son who lives, and your son is the dead;\u2019 and the other says, \u2018No; but your son is the dead one, and my son is the living one.\u2019\"",
                "The king said, \"Get me a sword.\" They brought a sword before the king.",
                "The king said, \"Divide the living child in two, and give half to the one, and half to the other.\"",
                "Then the woman whose the living child was spoke to the king, for her heart yearned over her son, and she said, \"Oh, my lord, give her the living child, and in no way kill it!\" But the other said, \"It shall be neither mine nor yours. Divide it.\"",
                "Then the king answered, \"Give her the living child, and in no way kill it. She is its mother.\"",
                "All Israel heard of the judgment which the king had judged; and they feared the king: for they saw that the wisdom of God was in him, to do justice."
            ],
            4: [
                "King Solomon was king over all Israel.",
                "These were the princes whom he had: Azariah the son of Zadok, the priest;",
                "Elihoreph and Ahijah, the sons of Shisha, scribes; Jehoshaphat the son of Ahilud, the recorder;",
                "and Benaiah the son of Jehoiada was over the army; and Zadok and Abiathar were priests;",
                "and Azariah the son of Nathan was over the officers; and Zabud the son of Nathan was chief minister, the king\u2019s friend;",
                "and Ahishar was over the household; and Adoniram the son of Abda was over the men subject to forced labor.",
                "Solomon had twelve officers over all Israel, who provided food for the king and his household: each man had to make provision for a month in the year.",
                "These are their names: Ben Hur, in the hill country of Ephraim;",
                "Ben Deker, in Makaz, and in Shaalbim, and Beth Shemesh, and Elon Beth Hanan;",
                "Ben Hesed, in Arubboth (to him belonged Socoh, and all the land of Hepher);",
                "Ben Abinadab, in all the height of Dor (he had Taphath the daughter of Solomon as wife);",
                "Baana the son of Ahilud, in Taanach and Megiddo, and all Beth Shean which is beside Zarethan, beneath Jezreel, from Beth Shean to Abel Meholah, as far as beyond Jokmeam;",
                "Ben Geber, in Ramoth Gilead (to him belonged the towns of Jair the son of Manasseh, which are in Gilead; to him belonged the region of Argob, which is in Bashan, sixty great cities with walls and bronze bars);",
                "Ahinadab the son of Iddo, in Mahanaim;",
                "Ahimaaz, in Naphtali (he also took Basemath the daughter of Solomon as wife);",
                "Baana the son of Hushai, in Asher and Bealoth;",
                "Jehoshaphat the son of Paruah, in Issachar;",
                "Shimei the son of Ela, in Benjamin;",
                "Geber the son of Uri, in the land of Gilead, the country of Sihon king of the Amorites and of Og king of Bashan; and he was the only officer who was in the land.",
                "Judah and Israel were many as the sand which is by the sea in multitude, eating and drinking and making merry.",
                "Solomon ruled over all the kingdoms from the River to the land of the Philistines, and to the border of Egypt: they brought tribute, and served Solomon all the days of his life.",
                "Solomon\u2019s provision for one day was thirty measures of fine flour, and sixty measures of meal,",
                "ten head of fat cattle, and twenty head of cattle out of the pastures, and one hundred sheep, besides harts, and gazelles, and roebucks, and fattened fowl.",
                "For he had dominion over all on this side the River, from Tiphsah even to Gaza, over all the kings on this side the River: and he had peace on all sides around him.",
                "Judah and Israel lived safely, every man under his vine and under his fig tree, from Dan even to Beersheba, all the days of Solomon.",
                "Solomon had forty thousand stalls of horses for his chariots, and twelve thousand horsemen.",
                "Those officers provided food for king Solomon, and for all who came to king Solomon\u2019s table, every man in his month; they let nothing be lacking.",
                "Barley also and straw for the horses and swift steeds brought they to the place where the officers were, every man according to his duty.",
                "God gave Solomon wisdom and understanding exceeding much, and very great understanding, even as the sand that is on the seashore.",
                "Solomon\u2019s wisdom excelled the wisdom of all the children of the east, and all the wisdom of Egypt.",
                "For he was wiser than all men; than Ethan the Ezrahite, and Heman, and Calcol, and Darda, the sons of Mahol: and his fame was in all the nations all around.",
                "He spoke three thousand proverbs; and his songs were one thousand five.",
                "He spoke of trees, from the cedar that is in Lebanon even to the hyssop that springs out of the wall; he spoke also of animals, and of birds, and of creeping things, and of fish.",
                "There came of all peoples to hear the wisdom of Solomon, from all kings of the earth, who had heard of his wisdom."
            ],
            5: [
                "Hiram king of Tyre sent his servants to Solomon; for he had heard that they had anointed him king in the place of his father: for Hiram was ever a lover of David.",
                "Solomon sent to Hiram, saying,",
                "\"You know how that David my father could not build a house for the name of Yahweh his God for the wars which were about him on every side, until Yahweh put them under the soles of his feet.",
                "But now Yahweh my God has given me rest on every side. There is neither adversary, nor evil occurrence.",
                "Behold, I purpose to build a house for the name of Yahweh my God, as Yahweh spoke to David my father, saying, \u2018Your son, whom I will set on your throne in your place, he shall build the house for my name.\u2019",
                "Now therefore command that they cut me cedar trees out of Lebanon. My servants shall be with your servants; and I will give you wages for your servants according to all that you shall say. For you know that there is not among us any who knows how to cut timber like the Sidonians.\"",
                "It happened, when Hiram heard the words of Solomon, that he rejoiced greatly, and said, \"Blessed is Yahweh this day, who has given to David a wise son over this great people.\"",
                "Hiram sent to Solomon, saying, \"I have heard the message which you have sent to me. I will do all your desire concerning timber of cedar, and concerning timber of fir.",
                "My servants shall bring them down from Lebanon to the sea. I will make them into rafts to go by sea to the place that you shall appoint me, and will cause them to be broken up there, and you shall receive them. You shall accomplish my desire, in giving food for my household.\"",
                "So Hiram gave Solomon timber of cedar and timber of fir according to all his desire.",
                "Solomon gave Hiram twenty thousand measures of wheat for food to his household, and twenty measures of pure oil. Solomon gave this to Hiram year by year.",
                "Yahweh gave Solomon wisdom, as he promised him; and there was peace between Hiram and Solomon; and they two made a treaty together.",
                "King Solomon raised a levy out of all Israel; and the levy was thirty thousand men.",
                "He sent them to Lebanon, ten thousand a month by courses; a month they were in Lebanon, and two months at home; and Adoniram was over the men subject to forced labor.",
                "Solomon had seventy thousand who bore burdens, and eighty thousand who were stone cutters in the mountains;",
                "besides Solomon\u2019s chief officers who were over the work, three thousand and three hundred, who bore rule over the people who labored in the work.",
                "The king commanded, and they cut out great stones, costly stones, to lay the foundation of the house with worked stone.",
                "Solomon\u2019s builders and Hiram\u2019s builders and the Gebalites cut them, and prepared the timber and the stones to build the house."
            ],
            6: [
                "It happened in the four hundred and eightieth year after the children of Israel had come out of the land of Egypt, in the fourth year of Solomon\u2019s reign over Israel, in the month Ziv, which is the second month, that he began to build the house of Yahweh.",
                "The house which king Solomon built for Yahweh, its length was sixty cubits, and its breadth twenty, and its height thirty cubits.",
                "The porch before the temple of the house, twenty cubits was its length, according to the breadth of the house. Ten cubits was its breadth before the house.",
                "For the house he made windows of fixed lattice work.",
                "Against the wall of the house he built stories all around, against the walls of the house all around, both of the temple and of the oracle; and he made side rooms all around.",
                "The nethermost story was five cubits broad, and the middle was six cubits broad, and the third was seven cubits broad; for on the outside he made offsets in the wall of the house all around, that the beams should not have hold in the walls of the house.",
                "The house, when it was in building, was built of stone prepared at the quarry; and there was neither hammer nor axe nor any tool of iron heard in the house, while it was in building.",
                "The door for the middle side rooms was in the right side of the house: and they went up by winding stairs into the middle story, and out of the middle into the third.",
                "So he built the house, and finished it; and he covered the house with beams and planks of cedar.",
                "He built the stories against all the house, each five cubits high: and they rested on the house with timber of cedar.",
                "The word of Yahweh came to Solomon, saying,",
                "\"Concerning this house which you are building, if you will walk in my statutes, and execute my ordinances, and keep all my commandments to walk in them; then will I establish my word with you, which I spoke to David your father.",
                "I will dwell among the children of Israel, and will not forsake my people Israel.\"",
                "So Solomon built the house, and finished it.",
                "He built the walls of the house within with boards of cedar: from the floor of the house to the walls of the ceiling, he covered them on the inside with wood; and he covered the floor of the house with boards of fir.",
                "He built twenty cubits on the hinder part of the house with boards of cedar from the floor to the ceiling: he built them for it within, for an oracle, even for the most holy place.",
                "In front of the temple sanctuary was forty cubits.",
                "There was cedar on the house within, carved with buds and open flowers: all was cedar; there was no stone seen.",
                "He prepared an oracle in the midst of the house within, to set there the ark of the covenant of Yahweh.",
                "Within the oracle was twenty cubits in length, and twenty cubits in breadth, and twenty cubits in its height; and he overlaid it with pure gold: and he covered the altar with cedar.",
                "So Solomon overlaid the house within with pure gold: and he drew chains of gold across before the oracle; and he overlaid it with gold.",
                "The whole house he overlaid with gold, until all the house was finished: also the whole altar that belonged to the oracle he overlaid with gold.",
                "In the oracle he made two cherubim of olive wood, each ten cubits high.",
                "Five cubits was the one wing of the cherub, and five cubits the other wing of the cherub: from the uttermost part of the one wing to the uttermost part of the other were ten cubits.",
                "The other cherub was ten cubits: both the cherubim were of one measure and one form.",
                "The height of the one cherub was ten cubits, and so was it of the other cherub.",
                "He set the cherubim within the inner house; and the wings of the cherubim were stretched forth, so that the wing of the one touched the one wall, and the wing of the other cherub touched the other wall; and their wings touched one another in the midst of the house.",
                "He overlaid the cherubim with gold.",
                "He carved all the walls of the house around with carved figures of cherubim and palm trees and open flowers, inside and outside.",
                "The floor of the house he overlaid with gold, inside and outside.",
                "For the entrance of the oracle he made doors of olive wood: the lintel and door posts were a fifth part of the wall.",
                "So he made two doors of olive wood; and he carved on them carvings of cherubim and palm trees and open flowers, and overlaid them with gold; and he spread the gold on the cherubim, and on the palm trees.",
                "So also made he for the entrance of the temple door posts of olive wood, out of a fourth part of the wall;",
                "and two doors of fir wood: the two leaves of the one door were folding, and the two leaves of the other door were folding.",
                "He carved cherubim and palm trees and open flowers; and he overlaid them with gold fitted on the engraved work.",
                "He built the inner court with three courses of cut stone, and a course of cedar beams.",
                "In the fourth year was the foundation of the house of Yahweh laid, in the month Ziv.",
                "In the eleventh year, in the month Bul, which is the eighth month, was the house finished throughout all its parts, and according to all its fashion. So was he seven years in building it."
            ],
            7: [
                "Solomon was building his own house thirteen years, and he finished all his house.",
                "For he built the house of the forest of Lebanon; its length was one hundred cubits, and its breadth fifty cubits, and its height thirty cubits, on four rows of cedar pillars, with cedar beams on the pillars.",
                "It was covered with cedar above over the forty-five beams, that were on the pillars; fifteen in a row.",
                "There were beams in three rows, and window was over against window in three ranks.",
                "All the doors and posts were made square with beams: and window was over against window in three ranks.",
                "He made the porch of pillars; its length was fifty cubits, and its breadth thirty cubits; and a porch before them; and pillars and a threshold before them.",
                "He made the porch of the throne where he was to judge, even the porch of judgment: and it was covered with cedar from floor to floor.",
                "His house where he was to dwell, the other court within the porch, was of the like work. He made also a house for Pharaoh\u2019s daughter (whom Solomon had taken as wife), like this porch.",
                "All these were of costly stones, even of cut stone, according to measure, sawed with saws, inside and outside, even from the foundation to the coping, and so on the outside to the great court.",
                "The foundation was of costly stones, even great stones, stones of ten cubits, and stones of eight cubits.",
                "Above were costly stones, even cut stone, according to measure, and cedar wood.",
                "The great court around had three courses of cut stone, and a course of cedar beams; like as the inner court of the house of Yahweh, and the porch of the house.",
                "King Solomon sent and fetched Hiram out of Tyre.",
                "He was the son of a widow of the tribe of Naphtali, and his father was a man of Tyre, a worker in brass; and he was filled with wisdom and understanding and skill, to work all works in brass. He came to king Solomon, and performed all his work.",
                "For he fashioned the two pillars of brass, eighteen cubits high apiece: and a line of twelve cubits encircled either of them about.",
                "He made two capitals of molten brass, to set on the tops of the pillars: the height of the one capital was five cubits, and the height of the other capital was five cubits.",
                "There were nets of checker work, and wreaths of chain work, for the capitals which were on the top of the pillars; seven for the one capital, and seven for the other capital.",
                "So he made the pillars; and there were two rows around on the one network, to cover the capitals that were on the top of the pillars: and he did so for the other capital.",
                "The capitals that were on the top of the pillars in the porch were of lily work, four cubits.",
                "There were capitals above also on the two pillars, close by the belly which was beside the network: and the pomegranates were two hundred, in rows around on the other capital.",
                "He set up the pillars at the porch of the temple: and he set up the right pillar, and called its name Jachin; and he set up the left pillar, and called its name Boaz.",
                "On the top of the pillars was lily work: so was the work of the pillars finished.",
                "He made the molten sea of ten cubits from brim to brim, round in compass, and its height was five cubits; and a line of thirty cubits encircled it.",
                "Under its brim around there were buds which encircled it, for ten cubits, encircling the sea: the buds were in two rows, cast when it was cast.",
                "It stood on twelve oxen, three looking toward the north, and three looking toward the west, and three looking toward the south, and three looking toward the east; and the sea was set on them above, and all their hinder parts were inward.",
                "It was a handbreadth thick: and its brim was worked like the brim of a cup, like the flower of a lily: it held two thousand baths.",
                "He made the ten bases of brass; four cubits was the length of one base, and four cubits its breadth, and three cubits its height.",
                "The work of the bases was like this: they had panels; and there were panels between the ledges;",
                "and on the panels that were between the ledges were lions, oxen, and cherubim; and on the ledges there was a pedestal above; and beneath the lions and oxen were wreaths of hanging work.",
                "Every base had four bronze wheels, and axles of brass; and the four feet of it had supports: beneath the basin were the supports molten, with wreaths at the side of each.",
                "The mouth of it within the capital and above was a cubit: and its mouth was round after the work of a pedestal, a cubit and a half; and also on its mouth were engravings, and their panels were foursquare, not round.",
                "The four wheels were underneath the panels; and the axles of the wheels were in the base: and the height of a wheel was a cubit and half a cubit.",
                "The work of the wheels was like the work of a chariot wheel: their axles, and their rims, and their spokes, and their naves, were all molten.",
                "There were four supports at the four corners of each base: its supports were of the base itself.",
                "In the top of the base was there a round compass half a cubit high; and on the top of the base its stays and its panels were of the same.",
                "On the plates of its stays, and on its panels, he engraved cherubim, lions, and palm trees, according to the space of each, with wreaths all around.",
                "In this way, he made the ten bases: all of them had one casting, one measure, and one form.",
                "He made ten basins of brass: one basin contained forty baths; and every basin was four cubits; and on every one of the ten bases one basin.",
                "He set the bases, five on the right side of the house, and five on the left side of the house: and he set the sea on the right side of the house eastward, toward the south.",
                "Hiram made the basins, and the shovels, and the basins. So Hiram made an end of doing all the work that he worked for king Solomon in the house of Yahweh:",
                "the two pillars, and the two bowls of the capitals that were on the top of the pillars; and the two networks to cover the two bowls of the capitals that were on the top of the pillars;",
                "and the four hundred pomegranates for the two networks; two rows of pomegranates for each network, to cover the two bowls of the capitals that were on the pillars;",
                "and the ten bases, and the ten basins on the bases;",
                "and the one sea, and the twelve oxen under the sea;",
                "and the pots, and the shovels, and the basins: even all these vessels, which Hiram made for king Solomon, in the house of Yahweh, were of burnished brass.",
                "The king cast them in the plain of the Jordan, in the clay ground between Succoth and Zarethan.",
                "Solomon left all the vessels unweighed, because they were exceeding many: the weight of the brass could not be found out.",
                "Solomon made all the vessels that were in the house of Yahweh: the golden altar, and the table whereupon the show bread was, of gold;",
                "and the lampstands, five on the right side, and five on the left, before the oracle, of pure gold; and the flowers, and the lamps, and the tongs, of gold;",
                "and the cups, and the snuffers, and the basins, and the spoons, and the fire pans, of pure gold; and the hinges, both for the doors of the inner house, the most holy place, and for the doors of the house, of the temple, of gold.",
                "Thus all the work that king Solomon worked in the house of Yahweh was finished. Solomon brought in the things which David his father had dedicated, the silver, and the gold, and the vessels, and put them in the treasuries of the house of Yahweh."
            ],
            8: [
                "Then Solomon assembled the elders of Israel, and all the heads of the tribes, the princes of the fathers\u2019 households of the children of Israel, to king Solomon in Jerusalem, to bring up the ark of the covenant of Yahweh out of the city of David, which is Zion.",
                "All the men of Israel assembled themselves to king Solomon at the feast, in the month Ethanim, which is the seventh month.",
                "All the elders of Israel came, and the priests took up the ark.",
                "They brought up the ark of Yahweh, and the Tent of Meeting, and all the holy vessels that were in the Tent; even these the priests and the Levites brought up.",
                "King Solomon and all the congregation of Israel, who were assembled to him, were with him before the ark, sacrificing sheep and cattle, that could not be counted nor numbered for multitude.",
                "The priests brought in the ark of the covenant of Yahweh to its place, into the oracle of the house, to the most holy place, even under the wings of the cherubim.",
                "For the cherubim spread forth their wings over the place of the ark, and the cherubim covered the ark and its poles above.",
                "The poles were so long that the ends of the poles were seen from the holy place before the oracle; but they were not seen outside: and there they are to this day.",
                "There was nothing in the ark except the two tables of stone which Moses put there at Horeb, when Yahweh made a covenant with the children of Israel, when they came out of the land of Egypt.",
                "It came to pass, when the priests had come out of the holy place, that the cloud filled the house of Yahweh,",
                "so that the priests could not stand to minister by reason of the cloud; for the glory of Yahweh filled the house of Yahweh.",
                "Then Solomon said, \"Yahweh has said that he would dwell in the thick darkness.",
                "I have surely built you a house of habitation, a place for you to dwell in forever.\"",
                "The king turned his face about, and blessed all the assembly of Israel: and all the assembly of Israel stood.",
                "He said, \"Blessed is Yahweh, the God of Israel, who spoke with his mouth to David your father, and has with his hand fulfilled it, saying,",
                "\u2018Since the day that I brought my people Israel out of Egypt, I chose no city out of all the tribes of Israel to build a house, that my name might be there; but I chose David to be over my people Israel.\u2019",
                "\"Now it was in the heart of David my father to build a house for the name of Yahweh, the God of Israel.",
                "But Yahweh said to David my father, \u2018Whereas it was in your heart to build a house for my name, you did well that it was in your heart.",
                "Nevertheless, you shall not build the house; but your son who shall come forth out of your body, he shall build the house for my name.\u2019",
                "Yahweh has established his word that he spoke; for I have risen up in the place of David my father, and I sit on the throne of Israel, as Yahweh promised, and have built the house for the name of Yahweh, the God of Israel.",
                "There I have set a place for the ark, in which is the covenant of Yahweh, which he made with our fathers, when he brought them out of the land of Egypt.\"",
                "Solomon stood before the altar of Yahweh in the presence of all the assembly of Israel, and spread forth his hands toward heaven;",
                "and he said, \"Yahweh, the God of Israel, there is no God like you, in heaven above, or on earth beneath; who keep covenant and loving kindness with your servants, who walk before you with all their heart;",
                "who have kept with your servant David my father that which you promised him. Yes, you spoke with your mouth, and have fulfilled it with your hand, as it is this day.",
                "Now therefore, may Yahweh, the God of Israel, keep with your servant David my father that which you have promised him, saying, \u2018There shall not fail you a man in my sight to sit on the throne of Israel, if only your children take heed to their way, to walk before me as you have walked before me.\u2019",
                "\"Now therefore, God of Israel, please let your word be verified, which you spoke to your servant David my father.",
                "But will God in very deed dwell on the earth? Behold, heaven and the heaven of heavens can\u2019t contain you; how much less this house that I have built!",
                "Yet have respect for the prayer of your servant, and for his supplication, Yahweh my God, to listen to the cry and to the prayer which your servant prays before you this day;",
                "that your eyes may be open toward this house night and day, even toward the place of which you have said, \u2018My name shall be there;\u2019 to listen to the prayer which your servant shall pray toward this place.",
                "Listen to the supplication of your servant, and of your people Israel, when they shall pray toward this place. Yes, hear in heaven, your dwelling place; and when you hear, forgive.",
                "\"If a man sins against his neighbor, and an oath is laid on him to cause him to swear, and he comes and swear before your altar in this house;",
                "then hear in heaven, and do, and judge your servants, condemning the wicked, to bring his way on his own head, and justifying the righteous, to give him according to his righteousness.",
                "\"When your people Israel are struck down before the enemy, because they have sinned against you; if they turn again to you, and confess your name, and pray and make supplication to you in this house:",
                "then hear in heaven, and forgive the sin of your people Israel, and bring them again to the land which you gave to their fathers.",
                "\"When the sky is shut up, and there is no rain, because they have sinned against you; if they pray toward this place, and confess your name, and turn from their sin, when you afflict them:",
                "then hear in heaven, and forgive the sin of your servants, and of your people Israel, when you teach them the good way in which they should walk; and send rain on your land, which you have given to your people for an inheritance.",
                "\"If there is famine in the land, if there is pestilence, if there is blight, mildew, locust or caterpillar; if their enemy besieges them in the land of their cities; whatever plague, whatever sickness there is;",
                "whatever prayer and supplication is made by any man, or by all your people Israel, who shall each know the plague of his own heart, and spread forth his hands toward this house:",
                "then hear in heaven, your dwelling place, and forgive, and do, and render to every man according to all his ways, whose heart you know; (for you, even you only, know the hearts of all the children of men;)",
                "that they may fear you all the days that they live in the land which you gave to our fathers.",
                "\"Moreover concerning the foreigner, who is not of your people Israel, when he shall come out of a far country for your name\u2019s sake",
                "(for they shall hear of your great name, and of your mighty hand, and of your outstretched arm); when he shall come and pray toward this house;",
                "hear in heaven, your dwelling place, and do according to all that the foreigner calls to you for; that all the peoples of the earth may know your name, to fear you, as do your people Israel, and that they may know that this house which I have built is called by your name.",
                "\"If your people go out to battle against their enemy, by whatever way you shall send them, and they pray to Yahweh toward the city which you have chosen, and toward the house which I have built for your name;",
                "then hear in heaven their prayer and their supplication, and maintain their cause.",
                "If they sin against you (for there is no man who doesn\u2019t sin), and you are angry with them, and deliver them to the enemy, so that they carry them away captive to the land of the enemy, far off or near;",
                "yet if they shall repent in the land where they are carried captive, and turn again, and make supplication to you in the land of those who carried them captive, saying, \u2018We have sinned, and have done perversely; we have dealt wickedly;\u2019",
                "if they return to you with all their heart and with all their soul in the land of their enemies, who carried them captive, and pray to you toward their land, which you gave to their fathers, the city which you have chosen, and the house which I have built for your name:",
                "then hear their prayer and their supplication in heaven, your dwelling place, and maintain their cause;",
                "and forgive your people who have sinned against you, and all their transgressions in which they have transgressed against you; and give them compassion before those who carried them captive, that they may have compassion on them",
                "(for they are your people, and your inheritance, which you brought out of Egypt, from the midst of the furnace of iron);",
                "that your eyes may be open to the supplication of your servant, and to the supplication of your people Israel, to listen to them whenever they cry to you.",
                "For you separated them from among all the peoples of the earth, to be your inheritance, as you spoke by Moses your servant, when you brought our fathers out of Egypt, Lord Yahweh.\"",
                "It was so, that when Solomon had made an end of praying all this prayer and supplication to Yahweh, he arose from before the altar of Yahweh, from kneeling on his knees with his hands spread forth toward heaven.",
                "He stood, and blessed all the assembly of Israel with a loud voice, saying,",
                "\"Blessed be Yahweh, who has given rest to his people Israel, according to all that he promised. There has not failed one word of all his good promise, which he promised by Moses his servant.",
                "May Yahweh our God be with us, as he was with our fathers. Let him not leave us, nor forsake us;",
                "that he may incline our hearts to him, to walk in all his ways, and to keep his commandments, and his statutes, and his ordinances, which he commanded our fathers.",
                "Let these my words, with which I have made supplication before Yahweh, be near to Yahweh our God day and night, that he may maintain the cause of his servant, and the cause of his people Israel, as every day shall require;",
                "that all the peoples of the earth may know that Yahweh, he is God. There is none else.",
                "\"Let your heart therefore be perfect with Yahweh our God, to walk in his statutes, and to keep his commandments, as at this day.\"",
                "The king, and all Israel with him, offered sacrifice before Yahweh.",
                "Solomon offered for the sacrifice of peace offerings, which he offered to Yahweh, two and twenty thousand head of cattle, and one hundred twenty thousand sheep. So the king and all the children of Israel dedicated the house of Yahweh.",
                "The same day the king made the middle of the court holy that was before the house of Yahweh; for there he offered the burnt offering, and the meal offering, and the fat of the peace offerings, because the bronze altar that was before Yahweh was too little to receive the burnt offering, and the meal offering, and the fat of the peace offerings.",
                "So Solomon held the feast at that time, and all Israel with him, a great assembly, from the entrance of Hamath to the brook of Egypt, before Yahweh our God, seven days and seven days, even fourteen days.",
                "On the eighth day he sent the people away; and they blessed the king, and went to their tents joyful and glad of heart for all the goodness that Yahweh had shown to David his servant, and to Israel his people."
            ],
            9: [
                "It happened, when Solomon had finished the building of the house of Yahweh, and the king\u2019s house, and all Solomon\u2019s desire which he was pleased to do,",
                "that Yahweh appeared to Solomon the second time, as he had appeared to him at Gibeon.",
                "Yahweh said to him, \"I have heard your prayer and your supplication, that you have made before me. I have made this house holy, which you have built, to put my name there forever; and my eyes and my heart shall be there perpetually.",
                "As for you, if you will walk before me, as David your father walked, in integrity of heart, and in uprightness, to do according to all that I have commanded you, and will keep my statutes and my ordinances;",
                "then I will establish the throne of your kingdom over Israel forever, according as I promised to David your father, saying, \u2018There shall not fail you a man on the throne of Israel.\u2019",
                "But if you turn away from following me, you or your children, and not keep my commandments and my statutes which I have set before you, but shall go and serve other gods, and worship them;",
                "then will I cut off Israel out of the land which I have given them; and this house, which I have made holy for my name, will I cast out of my sight; and Israel shall be a proverb and a byword among all peoples.",
                "Though this house is so high, yet shall everyone who passes by it be astonished, and shall hiss; and they shall say, \u2018Why has Yahweh done thus to this land, and to this house?\u2019",
                "and they shall answer, \u2018Because they forsook Yahweh their God, who brought their fathers out of the land of Egypt, and laid hold of other gods, and worshiped them, and served them. Therefore Yahweh has brought all this evil on them.\u2019\"",
                "It happened at the end of twenty years, in which Solomon had built the two houses, the house of Yahweh and the king\u2019s house",
                "(now Hiram the king of Tyre had furnished Solomon with cedar trees and fir trees, and with gold, according to all his desire), that then king Solomon gave Hiram twenty cities in the land of Galilee.",
                "Hiram came out from Tyre to see the cities which Solomon had given him; and they didn\u2019t please him.",
                "He said, \"What cities are these which you have given me, my brother?\" He called them the land of Cabul to this day.",
                "Hiram sent to the king one hundred twenty talents of gold.",
                "This is the reason of the levy which king Solomon raised, to build the house of Yahweh, and his own house, and Millo, and the wall of Jerusalem, and Hazor, and Megiddo, and Gezer.",
                "Pharaoh king of Egypt had gone up, and taken Gezer, and burnt it with fire, and slain the Canaanites who lived in the city, and given it for a portion to his daughter, Solomon\u2019s wife.",
                "Solomon built Gezer, and Beth Horon the lower,",
                "and Baalath, and Tamar in the wilderness, in the land,",
                "and all the storage cities that Solomon had, and the cities for his chariots, and the cities for his horsemen, and that which Solomon desired to build for his pleasure in Jerusalem, and in Lebanon, and in all the land of his dominion.",
                "As for all the people who were left of the Amorites, the Hittites, the Perizzites, the Hivites, and the Jebusites, who were not of the children of Israel;",
                "their children who were left after them in the land, whom the children of Israel were not able utterly to destroy, of them Solomon raised a levy of bondservants to this day.",
                "But of the children of Israel Solomon made no bondservants; but they were the men of war, and his servants, and his princes, and his captains, and rulers of his chariots and of his horsemen.",
                "These were the chief officers who were over Solomon\u2019s work, five hundred fifty, who bore rule over the people who labored in the work.",
                "But Pharaoh\u2019s daughter came up out of the city of David to her house which Solomon had built for her: then he built Millo.",
                "Solomon offered burnt offerings and peace offerings on the altar which he built to Yahweh three times a year, burning incense with them, on the altar that was before Yahweh. So he finished the house.",
                "King Solomon made a navy of ships in Ezion Geber, which is beside Eloth, on the shore of the Red Sea, in the land of Edom.",
                "Hiram sent in the navy his servants, sailors who had knowledge of the sea, with the servants of Solomon.",
                "They came to Ophir, and fetched from there gold, four hundred and twenty talents, and brought it to king Solomon."
            ],
            10: [
                "When the queen of Sheba heard of the fame of Solomon concerning the name of Yahweh, she came to prove him with hard questions.",
                "She came to Jerusalem with a very great train, with camels that bore spices, and very much gold, and precious stones; and when she had come to Solomon, she talked with him of all that was in her heart.",
                "Solomon told her all her questions: there was not anything hidden from the king which he didn\u2019t tell her.",
                "When the queen of Sheba had seen all the wisdom of Solomon, and the house that he had built,",
                "and the food of his table, and the sitting of his servants, and the attendance of his ministers, and their clothing, and his cup bearers, and his ascent by which he went up to the house of Yahweh; there was no more spirit in her.",
                "She said to the king, \"It was a true report that I heard in my own land of your acts, and of your wisdom.",
                "However I didn\u2019t believe the words, until I came, and my eyes had seen it. Behold, the half was not told me! Your wisdom and prosperity exceed the fame which I heard.",
                "Happy are your men, happy are these your servants, who stand continually before you, who hear your wisdom.",
                "Blessed is Yahweh your God, who delighted in you, to set you on the throne of Israel. Because Yahweh loved Israel forever, therefore made he you king, to do justice and righteousness.\"",
                "She gave the king one hundred twenty talents of gold, and of spices very great store, and precious stones. There came no more such abundance of spices as these which the queen of Sheba gave to king Solomon.",
                "The navy also of Hiram, that brought gold from Ophir, brought in from Ophir great plenty of almug trees and precious stones.",
                "The king made of the almug trees pillars for the house of Yahweh, and for the king\u2019s house, harps also and stringed instruments for the singers: there came no such almug trees, nor were seen, to this day.",
                "King Solomon gave to the queen of Sheba all her desire, whatever she asked, besides that which Solomon gave her of his royal bounty. So she turned, and went to her own land, she and her servants.",
                "Now the weight of gold that came to Solomon in one year was six hundred sixty-six talents of gold,",
                "besides that which the traders brought, and the traffic of the merchants, and of all the kings of the mixed people, and of the governors of the country.",
                "King Solomon made two hundred bucklers of beaten gold; six hundred shekels of gold went to one buckler.",
                "he made three hundred shields of beaten gold; three minas of gold went to one shield: and the king put them in the house of the forest of Lebanon.",
                "Moreover the king made a great throne of ivory, and overlaid it with the finest gold.",
                "There were six steps to the throne, and the top of the throne was round behind; and there were stays on either side by the place of the seat, and two lions standing beside the stays.",
                "Twelve lions stood there on the one side and on the other on the six steps: there was nothing like it made in any kingdom.",
                "All king Solomon\u2019s drinking vessels were of gold, and all the vessels of the house of the forest of Lebanon were of pure gold: none were of silver; it was nothing accounted of in the days of Solomon.",
                "For the king had at sea a navy of Tarshish with the navy of Hiram: once every three years came the navy of Tarshish, bringing gold, and silver, ivory, and apes, and peacocks.",
                "So king Solomon exceeded all the kings of the earth in riches and in wisdom.",
                "All the earth sought the presence of Solomon, to hear his wisdom, which God had put in his heart.",
                "They brought every man his tribute, vessels of silver, and vessels of gold, and clothing, and armor, and spices, horses, and mules, a rate year by year.",
                "Solomon gathered together chariots and horsemen: and he had a thousand and four hundred chariots, and twelve thousand horsemen, that he bestowed in the chariot cities, and with the king at Jerusalem.",
                "The king made silver to be in Jerusalem as stones, and cedars made he to be as the sycamore trees that are in the lowland, for abundance.",
                "The horses which Solomon had were brought out of Egypt; and the king\u2019s merchants received them in droves, each drove at a price.",
                "A chariot came up and went out of Egypt for six hundred shekels of silver, and a horse for one hundred fifty; and so for all the kings of the Hittites, and for the kings of Syria, they brought them out by their means."
            ],
            11: [
                "Now king Solomon loved many foreign women, together with the daughter of Pharaoh, women of the Moabites, Ammonites, Edomites, Sidonians, and Hittites;",
                "of the nations concerning which Yahweh said to the children of Israel, \"You shall not go among them, neither shall they come among you; for surely they will turn away your heart after their gods.\" Solomon joined to these in love.",
                "He had seven hundred wives, princesses, and three hundred concubines; and his wives turned away his heart.",
                "For it happened, when Solomon was old, that his wives turned away his heart after other gods; and his heart was not perfect with Yahweh his God, as was the heart of David his father.",
                "For Solomon went after Ashtoreth the goddess of the Sidonians, and after Milcom the abomination of the Ammonites.",
                "Solomon did that which was evil in the sight of Yahweh, and didn\u2019t go fully after Yahweh, as did David his father.",
                "Then Solomon built a high place for Chemosh the abomination of Moab, on the mountain that is before Jerusalem, and for Molech the abomination of the children of Ammon.",
                "So he did for all his foreign wives, who burnt incense and sacrificed to their gods.",
                "Yahweh was angry with Solomon, because his heart was turned away from Yahweh, the God of Israel, who had appeared to him twice,",
                "and had commanded him concerning this thing, that he should not go after other gods: but he didn\u2019t keep that which Yahweh commanded.",
                "Therefore Yahweh said to Solomon, \"Because this is done by you, and you have not kept my covenant and my statutes, which I have commanded you, I will surely tear the kingdom from you, and will give it to your servant.",
                "Notwithstanding I will not do it in your days, for David your father\u2019s sake; but I will tear it out of the hand of your son.",
                "However I will not tear away all the kingdom; but I will give one tribe to your son, for David my servant\u2019s sake, and for Jerusalem\u2019s sake which I have chosen.\"",
                "Yahweh raised up an adversary to Solomon, Hadad the Edomite: he was of the king\u2019s seed in Edom.",
                "For it happened, when David was in Edom, and Joab the captain of the army was gone up to bury the slain, and had struck every male in Edom",
                "(for Joab and all Israel remained there six months, until he had cut off every male in Edom);",
                "that Hadad fled, he and certain Edomites of his father\u2019s servants with him, to go into Egypt, Hadad being yet a little child.",
                "They arose out of Midian, and came to Paran; and they took men with them out of Paran, and they came to Egypt, to Pharaoh king of Egypt, who gave him a house, and appointed him food, and gave him land.",
                "Hadad found great favor in the sight of Pharaoh, so that he gave him as wife the sister of his own wife, the sister of Tahpenes the queen.",
                "The sister of Tahpenes bore him Genubath his son, whom Tahpenes weaned in Pharaoh\u2019s house; and Genubath was in Pharaoh\u2019s house among the sons of Pharaoh.",
                "When Hadad heard in Egypt that David slept with his fathers, and that Joab the captain of the army was dead, Hadad said to Pharaoh, \"Let me depart, that I may go to my own country.\"",
                "Then Pharaoh said to him, \"But what have you lacked with me, that behold, you seek to go to your own country?\" He answered, \"Nothing, however only let me depart.\"",
                "God raised up an adversary to him, Rezon the son of Eliada, who had fled from his lord Hadadezer king of Zobah.",
                "He gathered men to him, and became captain over a troop, when David killed them of Zobah: and they went to Damascus, and lived therein, and reigned in Damascus.",
                "He was an adversary to Israel all the days of Solomon, besides the mischief of Hadad: and he abhorred Israel, and reigned over Syria.",
                "Jeroboam the son of Nebat, an Ephraimite of Zeredah, a servant of Solomon, whose mother\u2019s name was Zeruah, a widow, he also lifted up his hand against the king.",
                "This was the reason why he lifted up his hand against the king: Solomon built Millo, and repaired the breach of the city of David his father.",
                "The man Jeroboam was a mighty man of valor; and Solomon saw the young man that he was industrious, and he put him in charge of all the labor of the house of Joseph.",
                "It happened at that time, when Jeroboam went out of Jerusalem, that the prophet Ahijah the Shilonite found him in the way; now Ahijah had clad himself with a new garment; and they two were alone in the field.",
                "Ahijah laid hold of the new garment that was on him, and tore it in twelve pieces.",
                "He said to Jeroboam, \"Take ten pieces; for thus says Yahweh, the God of Israel, \u2018Behold, I will tear the kingdom out of the hand of Solomon, and will give ten tribes to you",
                "(but he shall have one tribe, for my servant David\u2019s sake and for Jerusalem\u2019s sake, the city which I have chosen out of all the tribes of Israel);",
                "because that they have forsaken me, and have worshiped Ashtoreth the goddess of the Sidonians, Chemosh the god of Moab, and Milcom the god of the children of Ammon. They have not walked in my ways, to do that which is right in my eyes, and to keep my statutes and my ordinances, as David his father did.",
                "\"\u2018However I will not take the whole kingdom out of his hand; but I will make him prince all the days of his life, for David my servant\u2019s sake whom I chose, who kept my commandments and my statutes;",
                "but I will take the kingdom out of his son\u2019s hand, and will give it to you, even ten tribes.",
                "To his son will I give one tribe, that David my servant may have a lamp always before me in Jerusalem, the city which I have chosen me to put my name there.",
                "I will take you, and you shall reign according to all that your soul desires, and shall be king over Israel.",
                "It shall be, if you will listen to all that I command you, and will walk in my ways, and do that which is right in my eyes, to keep my statutes and my commandments, as David my servant did; that I will be with you, and will build you a sure house, as I built for David, and will give Israel to you.",
                "I will for this afflict the seed of David, but not forever.\u2019\"",
                "Solomon sought therefore to kill Jeroboam; but Jeroboam arose, and fled into Egypt, to Shishak king of Egypt, and was in Egypt until the death of Solomon.",
                "Now the rest of the acts of Solomon, and all that he did, and his wisdom, aren\u2019t they written in the book of the acts of Solomon?",
                "The time that Solomon reigned in Jerusalem over all Israel was forty years.",
                "Solomon slept with his fathers, and was buried in the city of David his father: and Rehoboam his son reigned in his place."
            ],
            12: [
                "Rehoboam went to Shechem: for all Israel had come to Shechem to make him king.",
                "It happened, when Jeroboam the son of Nebat heard of it (for he was yet in Egypt, where he had fled from the presence of king Solomon, and Jeroboam lived in Egypt,",
                "and they sent and called him), that Jeroboam and all the assembly of Israel came, and spoke to Rehoboam, saying,",
                "\"Your father made our yoke grievous: now therefore make you the grievous service of your father, and his heavy yoke which he put on us, lighter, and we will serve you.\"",
                "He said to them, \"Depart for three days, then come back to me.\" The people departed.",
                "King Rehoboam took counsel with the old men, who had stood before Solomon his father while he yet lived, saying, \"What counsel do you give me to return answer to this people?\"",
                "They spoke to him, saying, \"If you will be a servant to this people this day, and will serve them, and answer them, and speak good words to them, then they will be your servants forever.\"",
                "But he forsook the counsel of the old men which they had given him, and took counsel with the young men who had grown up with him, who stood before him.",
                "He said to them, \"What counsel do you give, that we may return answer to this people, who have spoken to me, saying, \u2018Make the yoke that your father put on us lighter?\u2019\"",
                "The young men who had grown up with him spoke to him, saying, \"Thus you shall tell this people who spoke to you, saying, \u2018Your father made our yoke heavy, but make it lighter to us;\u2019 you shall say to them, \u2018My little finger is thicker than my father\u2019s waist.",
                "Now whereas my father burdened you with a heavy yoke, I will add to your yoke: my father chastised you with whips, but I will chastise you with scorpions.\u2019\"",
                "So Jeroboam and all the people came to Rehoboam the third day, as the king asked, saying, \"Come to me again the third day.\"",
                "The king answered the people roughly, and forsook the counsel of the old men which they had given him,",
                "and spoke to them according to the counsel of the young men, saying, \"My father made your yoke heavy, but I will add to your yoke. My father chastised you with whips, but I will chastise you with scorpions.\"",
                "So the king didn\u2019t listen to the people; for it was a thing brought about of Yahweh, that he might establish his word, which Yahweh spoke by Ahijah the Shilonite to Jeroboam the son of Nebat.",
                "When all Israel saw that the king didn\u2019t listen to them, the people answered the king, saying, \"What portion have we in David? Neither do we have an inheritance in the son of Jesse. To your tents, Israel! Now see to your own house, David.\" So Israel departed to their tents.",
                "But as for the children of Israel who lived in the cities of Judah, Rehoboam reigned over them.",
                "Then king Rehoboam sent Adoram, who was over the men subject to forced labor; and all Israel stoned him to death with stones. King Rehoboam made speed to get him up to his chariot, to flee to Jerusalem.",
                "So Israel rebelled against the house of David to this day.",
                "It happened, when all Israel heard that Jeroboam was returned, that they sent and called him to the congregation, and made him king over all Israel: there was none who followed the house of David, but the tribe of Judah only.",
                "When Rehoboam had come to Jerusalem, he assembled all the house of Judah, and the tribe of Benjamin, a hundred and eighty thousand chosen men, who were warriors, to fight against the house of Israel, to bring the kingdom again to Rehoboam the son of Solomon.",
                "But the word of God came to Shemaiah the man of God, saying,",
                "\"Speak to Rehoboam the son of Solomon, king of Judah, and to all the house of Judah and Benjamin, and to the rest of the people, saying,",
                "\u2018Thus says Yahweh, \"You shall not go up, nor fight against your brothers, the children of Israel. Everyone return to his house; for this thing is of me.\"\u2019\" So they listened to the word of Yahweh, and returned and went their way, according to the word of Yahweh.",
                "Then Jeroboam built Shechem in the hill country of Ephraim, and lived in it; and he went out from there, and built Penuel.",
                "Jeroboam said in his heart, \"Now the kingdom will return to the house of David.",
                "If this people goes up to offer sacrifices in the house of Yahweh at Jerusalem, then the heart of this people will turn again to their lord, even to Rehoboam king of Judah; and they will kill me, and return to Rehoboam king of Judah.\"",
                "Whereupon the king took counsel, and made two calves of gold; and he said to them, \"It is too much for you to go up to Jerusalem. Look and see your gods, Israel, which brought you up out of the land of Egypt!\"",
                "He set the one in Bethel, and the other put he in Dan.",
                "This thing became a sin; for the people went to worship before the one, even to Dan.",
                "He made houses of high places, and made priests from among all the people, who were not of the sons of Levi.",
                "Jeroboam ordained a feast in the eighth month, on the fifteenth day of the month, like the feast that is in Judah, and he went up to the altar; he did so in Bethel, sacrificing to the calves that he had made: and he placed in Bethel the priests of the high places that he had made.",
                "He went up to the altar which he had made in Bethel on the fifteenth day in the eighth month, even in the month which he had devised of his own heart: and he ordained a feast for the children of Israel, and went up to the altar, to burn incense."
            ],
            13: [
                "Behold, there came a man of God out of Judah by the word of Yahweh to Beth El: and Jeroboam was standing by the altar to burn incense.",
                "He cried against the altar by the word of Yahweh, and said, \"Altar, altar, thus says Yahweh: \u2018Behold, a son shall be born to the house of David, Josiah by name. On you he shall sacrifice the priests of the high places who burn incense on you, and they will burn men\u2019s bones on you.\u2019\"",
                "He gave a sign the same day, saying, \"This is the sign which Yahweh has spoken: Behold, the altar will be split apart, and the ashes that are on it will be poured out.\"",
                "It happened, when the king heard the saying of the man of God, which he cried against the altar in Bethel, that Jeroboam put out his hand from the altar, saying, \"Seize him!\" His hand, which he put out against him, dried up, so that he could not draw it back again to himself.",
                "The altar also was split apart, and the ashes poured out from the altar, according to the sign which the man of God had given by the word of Yahweh.",
                "The king answered the man of God, \"Now entreat the favor of Yahweh your God, and pray for me, that my hand may be restored me again.\" The man of God entreated Yahweh, and the king\u2019s hand was restored him again, and became as it was before.",
                "The king said to the man of God, \"Come home with me, and refresh yourself, and I will give you a reward.\"",
                "The man of God said to the king, \"Even if you gave me half of your house, I would not go in with you, neither would I eat bread nor drink water in this place;",
                "for so was it commanded me by the word of Yahweh, saying, \u2018You shall eat no bread, nor drink water, neither return by the way that you came.\u2019\"",
                "So he went another way, and didn\u2019t return by the way that he came to Bethel.",
                "Now there lived an old prophet in Bethel; and one of his sons came and told him all the works that the man of God had done that day in Bethel. They also told their father the words which he had spoken to the king.",
                "Their father said to them, \"Which way did he go?\" Now his sons had seen which way the man of God went, who came from Judah.",
                "He said to his sons, \"Saddle the donkey for me.\" So they saddled the donkey for him; and he rode on it.",
                "He went after the man of God, and found him sitting under an oak. He said to him, \"Are you the man of God who came from Judah?\" He said, \"I am.\"",
                "Then he said to him, \"Come home with me, and eat bread.\"",
                "He said, \"I may not return with you, nor go in with you; neither will I eat bread nor drink water with you in this place.",
                "For it was said to me by the word of Yahweh, \u2018You shall eat no bread nor drink water there, nor turn again to go by the way that you came.\u2019\"",
                "He said to him, \"I also am a prophet as you are; and an angel spoke to me by the word of Yahweh, saying, \u2018Bring him back with you into your house, that he may eat bread and drink water.\u2019\" He lied to him.",
                "So he went back with him, and ate bread in his house, and drank water.",
                "It happened, as they sat at the table, that the word of Yahweh came to the prophet who brought him back;",
                "and he cried to the man of God who came from Judah, saying, \"Thus says Yahweh, \u2018Because you have been disobedient to the mouth of Yahweh, and have not kept the commandment which Yahweh your God commanded you,",
                "but came back, and have eaten bread and drunk water in the place of which he said to you, \"Eat no bread, and drink no water;\" your body shall not come to the tomb of your fathers.\u2019\"",
                "It happened, after he had eaten bread, and after he had drunk, that he saddled the donkey for the prophet whom he had brought back.",
                "When he had gone, a lion met him by the way, and killed him. His body was cast in the way, and the donkey stood by it. The lion also stood by the body.",
                "Behold, men passed by, and saw the body cast in the way, and the lion standing by the body; and they came and told it in the city where the old prophet lived.",
                "When the prophet who brought him back from the way heard of it, he said, \"It is the man of God who was disobedient to the mouth of Yahweh. Therefore Yahweh has delivered him to the lion, which has mauled him and slain him, according to the word of Yahweh, which he spoke to him.\"",
                "He spoke to his sons, saying, \"Saddle the donkey for me.\" They saddled it.",
                "He went and found his body cast in the way, and the donkey and the lion standing by the body. The lion had not eaten the body, nor mauled the donkey.",
                "The prophet took up the body of the man of God, and laid it on the donkey, and brought it back. He came to the city of the old prophet to mourn, and to bury him.",
                "He laid his body in his own grave; and they mourned over him, saying, \"Alas, my brother!\"",
                "It happened, after he had buried him, that he spoke to his sons, saying, \"When I am dead, then bury me in the tomb in which the man of God is buried. Lay my bones beside his bones.",
                "For the saying which he cried by the word of Yahweh against the altar in Bethel, and against all the houses of the high places which are in the cities of Samaria, will surely happen.\"",
                "After this thing Jeroboam didn\u2019t return from his evil way, but again made priests of the high places from among all the people. Whoever wanted to, he consecrated him, that there might be priests of the high places.",
                "This thing became sin to the house of Jeroboam, even to cut it off, and to destroy it from off the surface of the earth."
            ],
            14: [
                "At that time Abijah the son of Jeroboam fell sick.",
                "Jeroboam said to his wife, \"Please get up and disguise yourself, that you won\u2019t be recognized as the wife of Jeroboam. Go to Shiloh. Behold, there is Ahijah the prophet, who spoke concerning me that I should be king over this people.",
                "Take with you ten loaves, and cakes, and a jar of honey, and go to him. He will tell you what will become of the child.\"",
                "Jeroboam\u2019s wife did so, and arose, and went to Shiloh, and came to the house of Ahijah. Now Ahijah could not see; for his eyes were set by reason of his age.",
                "Yahweh said to Ahijah, \"Behold, the wife of Jeroboam comes to inquire of you concerning her son; for he is sick. Thus and thus you shall tell her; for it will be, when she comes in, that she will pretend to be another woman.\"",
                "It was so, when Ahijah heard the sound of her feet, as she came in at the door, that he said, \"Come in, you wife of Jeroboam! Why do you pretend to be another? For I am sent to you with heavy news.",
                "Go, tell Jeroboam, \u2018Thus says Yahweh, the God of Israel: \"Because I exalted you from among the people, and made you prince over my people Israel,",
                "and tore the kingdom away from the house of David, and gave it you; and yet you have not been as my servant David, who kept my commandments, and who followed me with all his heart, to do that only which was right in my eyes,",
                "but have done evil above all who were before you, and have gone and made you other gods, and molten images, to provoke me to anger, and have cast me behind your back:",
                "therefore, behold, I will bring evil on the house of Jeroboam, and will cut off from Jeroboam everyone who urinates on a wall, he who is shut up and he who is left at large in Israel, and will utterly sweep away the house of Jeroboam, as a man sweeps away dung, until it is all gone.",
                "He who dies of Jeroboam in the city shall the dogs eat; and he who dies in the field shall the birds of the sky eat: for Yahweh has spoken it.\"\u2019",
                "Arise therefore, and go to your house. When your feet enter into the city, the child shall die.",
                "All Israel shall mourn for him, and bury him; for he only of Jeroboam shall come to the grave, because in him there is found some good thing toward Yahweh, the God of Israel, in the house of Jeroboam.",
                "Moreover Yahweh will raise him up a king over Israel, who shall cut off the house of Jeroboam. This is day! What? Even now.",
                "For Yahweh will strike Israel, as a reed is shaken in the water; and he will root up Israel out of this good land which he gave to their fathers, and will scatter them beyond the River, because they have made their Asherim, provoking Yahweh to anger.",
                "He will give Israel up because of the sins of Jeroboam, which he has sinned, and with which he has made Israel to sin.\"",
                "Jeroboam\u2019s wife arose, and departed, and came to Tirzah. As she came to the threshold of the house, the child died.",
                "All Israel buried him, and mourned for him, according to the word of Yahweh, which he spoke by his servant Ahijah the prophet.",
                "The rest of the acts of Jeroboam, how he warred, and how he reigned, behold, they are written in the book of the chronicles of the kings of Israel.",
                "The days which Jeroboam reigned were two and twenty years: and he slept with his fathers, and Nadab his son reigned in his place.",
                "Rehoboam the son of Solomon reigned in Judah. Rehoboam was forty-one years old when he began to reign, and he reigned seventeen years in Jerusalem, the city which Yahweh had chosen out of all the tribes of Israel, to put his name there: and his mother\u2019s name was Naamah the Ammonitess.",
                "Judah did that which was evil in the sight of Yahweh, and they provoked him to jealousy with their sins which they committed, above all that their fathers had done.",
                "For they also built them high places, and pillars, and Asherim, on every high hill, and under every green tree;",
                "and there were also sodomites in the land: they did according to all the abominations of the nations which Yahweh drove out before the children of Israel.",
                "It happened in the fifth year of king Rehoboam, that Shishak king of Egypt came up against Jerusalem;",
                "and he took away the treasures of the house of Yahweh, and the treasures of the king\u2019s house; he even took away all: and he took away all the shields of gold which Solomon had made.",
                "King Rehoboam made in their place shields of brass, and committed them to the hands of the captains of the guard, who kept the door of the king\u2019s house.",
                "It was so, that as often as the king went into the house of Yahweh, the guard bore them, and brought them back into the guard room.",
                "Now the rest of the acts of Rehoboam, and all that he did, aren\u2019t they written in the book of the chronicles of the kings of Judah?",
                "There was war between Rehoboam and Jeroboam continually.",
                "Rehoboam slept with his fathers, and was buried with his fathers in the city of David: and his mother\u2019s name was Naamah the Ammonitess. Abijam his son reigned in his place."
            ],
            15: [
                "Now in the eighteenth year of king Jeroboam the son of Nebat began Abijam to reign over Judah.",
                "He reigned three years in Jerusalem: and his mother\u2019s name was Maacah the daughter of Abishalom.",
                "He walked in all the sins of his father, which he had done before him; and his heart was not perfect with Yahweh his God, as the heart of David his father.",
                "Nevertheless for David\u2019s sake, Yahweh his God gave him a lamp in Jerusalem, to set up his son after him, and to establish Jerusalem;",
                "because David did that which was right in the eyes of Yahweh, and didn\u2019t turn aside from anything that he commanded him all the days of his life, except only in the matter of Uriah the Hittite.",
                "Now there was war between Rehoboam and Jeroboam all the days of his life.",
                "The rest of the acts of Abijam, and all that he did, aren\u2019t they written in the book of the chronicles of the kings of Judah? There was war between Abijam and Jeroboam.",
                "Abijam slept with his fathers; and they buried him in the city of David: and Asa his son reigned in his place.",
                "In the twentieth year of Jeroboam king of Israel began Asa to reign over Judah.",
                "He reigned forty-one years in Jerusalem: and his mother\u2019s name was Maacah the daughter of Abishalom.",
                "Asa did that which was right in the eyes of Yahweh, as did David his father.",
                "He put away the sodomites out of the land, and removed all the idols that his fathers had made.",
                "Also Maacah his mother he removed from being queen, because she had made an abominable image for an Asherah; and Asa cut down her image, and burnt it at the brook Kidron.",
                "But the high places were not taken away: nevertheless the heart of Asa was perfect with Yahweh all his days.",
                "He brought into the house of Yahweh the things that his father had dedicated, and the things that himself had dedicated, silver, and gold, and vessels.",
                "There was war between Asa and Baasha king of Israel all their days.",
                "Baasha king of Israel went up against Judah, and built Ramah, that he might not allow anyone to go out or come in to Asa king of Judah.",
                "Then Asa took all the silver and the gold that were left in the treasures of the house of Yahweh, and the treasures of the king\u2019s house, and delivered them into the hand of his servants; and king Asa sent them to Ben Hadad, the son of Tabrimmon, the son of Hezion, king of Syria, who lived at Damascus, saying,",
                "\"There is a treaty between me and you, between my father and your father. Behold, I have sent to you a present of silver and gold. Go, break your treaty with Baasha king of Israel, that he may depart from me.\"",
                "Ben Hadad listened to king Asa, and sent the captains of his armies against the cities of Israel, and struck Ijon, and Dan, and Abel Beth Maacah, and all Chinneroth, with all the land of Naphtali.",
                "It happened, when Baasha heard of it, that he left off building Ramah, and lived in Tirzah.",
                "Then king Asa made a proclamation to all Judah; none was exempted: and they carried away the stones of Ramah, and its timber, with which Baasha had built; and king Asa built therewith Geba of Benjamin, and Mizpah.",
                "Now the rest of all the acts of Asa, and all his might, and all that he did, and the cities which he built, aren\u2019t they written in the book of the chronicles of the kings of Judah? But in the time of his old age he was diseased in his feet.",
                "Asa slept with his fathers, and was buried with his fathers in the city of David his father; and Jehoshaphat his son reigned in his place.",
                "Nadab the son of Jeroboam began to reign over Israel in the second year of Asa king of Judah; and he reigned over Israel two years.",
                "He did that which was evil in the sight of Yahweh, and walked in the way of his father, and in his sin with which he made Israel to sin.",
                "Baasha the son of Ahijah, of the house of Issachar, conspired against him; and Baasha struck him at Gibbethon, which belonged to the Philistines; for Nadab and all Israel were laying siege to Gibbethon.",
                "Even in the third year of Asa king of Judah, Baasha killed him, and reigned in his place.",
                "It happened that, as soon as he was king, he struck all the house of Jeroboam: he didn\u2019t leave to Jeroboam any who breathed, until he had destroyed him; according to the saying of Yahweh, which he spoke by his servant Ahijah the Shilonite;",
                "for the sins of Jeroboam which he sinned, and with which he made Israel to sin, because of his provocation with which he provoked Yahweh, the God of Israel, to anger.",
                "Now the rest of the acts of Nadab, and all that he did, aren\u2019t they written in the book of the chronicles of the kings of Israel?",
                "There was war between Asa and Baasha king of Israel all their days.",
                "In the third year of Asa king of Judah, Baasha the son of Ahijah began to reign over all Israel in Tirzah for twenty-four years.",
                "He did that which was evil in the sight of Yahweh, and walked in the way of Jeroboam, and in his sin with which he made Israel to sin."
            ],
            16: [
                "The word of Yahweh came to Jehu the son of Hanani against Baasha, saying,",
                "\"Because I exalted you out of the dust, and made you prince over my people Israel, and you have walked in the way of Jeroboam, and have made my people Israel to sin, to provoke me to anger with their sins;",
                "behold, I will utterly sweep away Baasha and his house; and I will make your house like the house of Jeroboam the son of Nebat.",
                "The dogs will eat Baasha\u2019s descendants who die in the city; and he who dies of his in the field the birds of the sky will eat.\"",
                "Now the rest of the acts of Baasha, and what he did, and his might, aren\u2019t they written in the book of the chronicles of the kings of Israel?",
                "Baasha slept with his fathers, and was buried in Tirzah; and Elah his son reigned in his place.",
                "Moreover by the prophet Jehu the son of Hanani came the word of Yahweh against Baasha, and against his house, both because of all the evil that he did in the sight of Yahweh, to provoke him to anger with the work of his hands, in being like the house of Jeroboam, and because he struck him.",
                "In the twenty-sixth year of Asa king of Judah, Elah the son of Baasha began to reign over Israel in Tirzah for two years.",
                "His servant Zimri, captain of half his chariots, conspired against him. Now he was in Tirzah, drinking himself drunk in the house of Arza, who was over the household in Tirzah:",
                "and Zimri went in and struck him, and killed him, in the twenty-seventh year of Asa king of Judah, and reigned in his place.",
                "It happened, when he began to reign, as soon as he sat on his throne, that he struck all the house of Baasha: he didn\u2019t leave him a single one who urinates on a wall, neither of his relatives, nor of his friends.",
                "Thus Zimri destroyed all the house of Baasha, according to the word of Yahweh, which he spoke against Baasha by Jehu the prophet,",
                "for all the sins of Baasha, and the sins of Elah his son, which they sinned, and with which they made Israel to sin, to provoke Yahweh, the God of Israel, to anger with their vanities.",
                "Now the rest of the acts of Elah, and all that he did, aren\u2019t they written in the book of the chronicles of the kings of Israel?",
                "In the twenty-seventh year of Asa king of Judah, Zimri reigned seven days in Tirzah. Now the people were encamped against Gibbethon, which belonged to the Philistines.",
                "The people who were encamped heard say, Zimri has conspired, and has also struck the king: therefore all Israel made Omri, the captain of the army, king over Israel that day in the camp.",
                "Omri went up from Gibbethon, and all Israel with him, and they besieged Tirzah.",
                "It happened, when Zimri saw that the city was taken, that he went into the castle of the king\u2019s house, and burnt the king\u2019s house over him with fire, and died,",
                "for his sins which he sinned in doing that which was evil in the sight of Yahweh, in walking in the way of Jeroboam, and in his sin which he did, to make Israel to sin.",
                "Now the rest of the acts of Zimri, and his treason that he committed, aren\u2019t they written in the book of the chronicles of the kings of Israel?",
                "Then were the people of Israel divided into two parts: half of the people followed Tibni the son of Ginath, to make him king; and half followed Omri.",
                "But the people who followed Omri prevailed against the people who followed Tibni the son of Ginath: so Tibni died, and Omri reigned.",
                "In the thirty-first year of Asa king of Judah, Omri began to reign over Israel for twelve years. He reigned six years in Tirzah.",
                "He bought the hill Samaria of Shemer for two talents of silver; and he built on the hill, and called the name of the city which he built, after the name of Shemer, the owner of the hill, Samaria.",
                "Omri did that which was evil in the sight of Yahweh, and dealt wickedly above all who were before him.",
                "For he walked in all the way of Jeroboam the son of Nebat, and in his sins with which he made Israel to sin, to provoke Yahweh, the God of Israel, to anger with their vanities.",
                "Now the rest of the acts of Omri which he did, and his might that he showed, aren\u2019t they written in the book of the chronicles of the kings of Israel?",
                "So Omri slept with his fathers, and was buried in Samaria; and Ahab his son reigned in his place.",
                "In the thirty-eighth year of Asa king of Judah began Ahab the son of Omri to reign over Israel: and Ahab the son of Omri reigned over Israel in Samaria twenty-two years.",
                "Ahab the son of Omri did that which was evil in the sight of Yahweh above all that were before him.",
                "It happened, as if it had been a light thing for him to walk in the sins of Jeroboam the son of Nebat, that he took as wife Jezebel the daughter of Ethbaal king of the Sidonians, and went and served Baal, and worshiped him.",
                "He reared up an altar for Baal in the house of Baal, which he had built in Samaria.",
                "Ahab made the Asherah; and Ahab did yet more to provoke Yahweh, the God of Israel, to anger than all the kings of Israel who were before him.",
                "In his days Hiel the Bethelite built Jericho: he laid its foundation with the loss of Abiram his firstborn, and set up its gates with the loss of his youngest son Segub, according to the word of Yahweh, which he spoke by Joshua the son of Nun."
            ],
            17: [
                "Elijah the Tishbite, who was of the foreigners of Gilead, said to Ahab, \"As Yahweh, the God of Israel, lives, before whom I stand, there shall not be dew nor rain these years, but according to my word.\"",
                "The word of Yahweh came to him, saying,",
                "\"Go away from here, turn eastward, and hide yourself by the brook Cherith, that is before the Jordan.",
                "It shall be, that you shall drink of the brook. I have commanded the ravens to feed you there.\"",
                "So he went and did according to the word of Yahweh; for he went and lived by the brook Cherith, that is before the Jordan.",
                "The ravens brought him bread and flesh in the morning, and bread and flesh in the evening; and he drank of the brook.",
                "It happened after a while, that the brook dried up, because there was no rain in the land.",
                "The word of Yahweh came to him, saying,",
                "\"Arise, go to Zarephath, which belongs to Sidon, and stay there. Behold, I have commanded a widow there to sustain you.\"",
                "So he arose and went to Zarephath; and when he came to the gate of the city, behold, a widow was there gathering sticks: and he called to her, and said, \"Please get me a little water in a vessel, that I may drink.\"",
                "As she was going to get it, he called to her, and said, \"Please bring me a morsel of bread in your hand.\"",
                "She said, \"As Yahweh your God lives, I don\u2019t have a cake, but a handful of meal in the jar, and a little oil in the jar. Behold, I am gathering two sticks, that I may go in and bake it for me and my son, that we may eat it, and die.\"",
                "Elijah said to her, \"Don\u2019t be afraid. Go and do as you have said; but make me of it a little cake first, and bring it out to me, and afterward make some for you and for your son.",
                "For thus says Yahweh, the God of Israel, \u2018The jar of meal shall not empty, neither shall the jar of oil fail, until the day that Yahweh sends rain on the earth.\u2019\"",
                "She went and did according to the saying of Elijah: and she, and he, and her house, ate many days.",
                "The jar of meal didn\u2019t empty, neither did the jar of oil fail, according to the word of Yahweh, which he spoke by Elijah.",
                "It happened after these things, that the son of the woman, the mistress of the house, fell sick; and his sickness was so severe, that there was no breath left in him.",
                "She said to Elijah, \"What have I to do with you, you man of God? You have come to me to bring my sin to memory, and to kill my son!\"",
                "He said to her, \"Give me your son.\" He took him out of her bosom, and carried him up into the room where he stayed, and laid him on his own bed.",
                "He cried to Yahweh, and said, \"Yahweh my God, have you also brought evil on the widow with whom I stay, by killing her son?\"",
                "He stretched himself on the child three times, and cried to Yahweh, and said, \"Yahweh my God, please let this child\u2019s soul come into him again.\"",
                "Yahweh listened to the voice of Elijah; and the soul of the child came into him again, and he revived.",
                "Elijah took the child, and brought him down out of the room into the house, and delivered him to his mother; and Elijah said, \"Behold, your son lives.\"",
                "The woman said to Elijah, \"Now I know that you are a man of God, and that the word of Yahweh in your mouth is truth.\""
            ],
            18: [
                "It happened after many days, that the word of Yahweh came to Elijah, in the third year, saying, \"Go, show yourself to Ahab; and I will send rain on the earth.\"",
                "Elijah went to show himself to Ahab. The famine was severe in Samaria.",
                "Ahab called Obadiah, who was over the household. (Now Obadiah feared Yahweh greatly:",
                "for it was so, when Jezebel cut off the prophets of Yahweh, that Obadiah took one hundred prophets, and hid them by fifty in a cave, and fed them with bread and water.)",
                "Ahab said to Obadiah, \"Go through the land, to all the springs of water, and to all the brooks. Perhaps we may find grass and save the horses and mules alive, that we not lose all the animals.\"",
                "So they divided the land between them to pass throughout it: Ahab went one way by himself, and Obadiah went another way by himself.",
                "As Obadiah was in the way, behold, Elijah met him: and he recognized him, and fell on his face, and said, \"Is it you, my lord Elijah?\"",
                "He answered him, \"It is I. Go, tell your lord, \u2018Behold, Elijah is here!\u2019\"",
                "He said, \"Wherein have I sinned, that you would deliver your servant into the hand of Ahab, to kill me?",
                "As Yahweh your God lives, there is no nation or kingdom, where my lord has not sent to seek you. When they said, \u2018He is not here,\u2019 he took an oath of the kingdom and nation, that they didn\u2019t find you.",
                "Now you say, \u2018Go, tell your lord, \"Behold, Elijah is here.\"\u2019",
                "It will happen, as soon as I am gone from you, that the Spirit of Yahweh will carry you I don\u2019t know where; and so when I come and tell Ahab, and he can\u2019t find you, he will kill me. But I, your servant, have feared Yahweh from my youth.",
                "Wasn\u2019t it told my lord what I did when Jezebel killed the prophets of Yahweh, how I hid one hundred men of Yahweh\u2019s prophets with fifty to a cave, and fed them with bread and water?",
                "Now you say, \u2018Go, tell your lord, \"Behold, Elijah is here;\"\u2019 and he will kill me.\"",
                "Elijah said, \"As Yahweh of Armies lives, before whom I stand, I will surely show myself to him today.\"",
                "So Obadiah went to meet Ahab, and told him; and Ahab went to meet Elijah.",
                "It happened, when Ahab saw Elijah, that Ahab said to him, \"Is that you, you troubler of Israel?\"",
                "He answered, \"I have not troubled Israel; but you, and your father\u2019s house, in that you have forsaken the commandments of Yahweh, and you have followed the Baals.",
                "Now therefore send, and gather to me all Israel to Mount Carmel, and four hundred fifty of the prophets of Baal, and four hundred of the prophets of the Asherah, who eat at Jezebel\u2019s table.\"",
                "So Ahab sent to all the children of Israel, and gathered the prophets together to Mount Carmel.",
                "Elijah came near to all the people, and said, \"How long will you waver between the two sides? If Yahweh is God, follow him; but if Baal, then follow him.\" The people answered him not a word.",
                "Then Elijah said to the people, \"I, even I only, am left a prophet of Yahweh; but Baal\u2019s prophets are four hundred fifty men.",
                "Let them therefore give us two bulls; and let them choose one bull for themselves, and cut it in pieces, and lay it on the wood, and put no fire under; and I will dress the other bull, and lay it on the wood, and put no fire under it.",
                "You call on the name of your god, and I will call on the name of Yahweh. The God who answers by fire, let him be God.\" All the people answered, \"It is well said.\"",
                "Elijah said to the prophets of Baal, \"Choose one bull for yourselves, and dress it first; for you are many; and call on the name of your god, but put no fire under it.\"",
                "They took the bull which was given them, and they dressed it, and called on the name of Baal from morning even until noon, saying, Baal, hear us. But there was no voice, nor any who answered. They leaped about the altar which was made.",
                "It happened at noon, that Elijah mocked them, and said, \"Cry aloud; for he is a god. Either he is musing, or he has gone aside, or he is on a journey, or perhaps he sleeps and must be awakened.\"",
                "They cried aloud, and cut themselves in their way with knives and lances, until the blood gushed out on them.",
                "It was so, when midday was past, that they prophesied until the time of the offering of the offering; but there was neither voice, nor any to answer, nor any who regarded.",
                "Elijah said to all the people, \"Come near to me;\" and all the people came near to him. He repaired the altar of Yahweh that was thrown down.",
                "Elijah took twelve stones, according to the number of the tribes of the sons of Jacob, to whom the word of Yahweh came, saying, \"Israel shall be your name.\"",
                "With the stones he built an altar in the name of Yahweh. He made a trench around the altar, large enough to contain two measures of seed.",
                "He put the wood in order, and cut the bull in pieces, and laid it on the wood. He said, \"Fill four jars with water, and pour it on the burnt offering, and on the wood.\"",
                "He said, \"Do it a second time;\" and they did it the second time. He said, \"Do it a third time;\" and they did it the third time.",
                "The water ran around the altar; and he also filled the trench with water.",
                "It happened at the time of the offering of the offering, that Elijah the prophet came near, and said, \"Yahweh, the God of Abraham, of Isaac, and of Israel, let it be known this day that you are God in Israel, and that I am your servant, and that I have done all these things at your word.",
                "Hear me, Yahweh, hear me, that this people may know that you, Yahweh, are God, and that you have turned their heart back again.\"",
                "Then the fire of Yahweh fell, and consumed the burnt offering, and the wood, and the stones, and the dust, and licked up the water that was in the trench.",
                "When all the people saw it, they fell on their faces. They said, \"Yahweh, he is God! Yahweh, he is God!\"",
                "Elijah said to them, \"Seize the prophets of Baal! Don\u2019t let one of them escape!\" They seized them. Elijah brought them down to the brook Kishon, and killed them there.",
                "Elijah said to Ahab, \"Get up, eat and drink; for there is the sound of abundance of rain.\"",
                "So Ahab went up to eat and to drink. Elijah went up to the top of Carmel; and he bowed himself down on the earth, and put his face between his knees.",
                "He said to his servant, \"Go up now, look toward the sea.\" He went up, and looked, and said, \"There is nothing.\" He said, \"Go again\" seven times.",
                "It happened at the seventh time, that he said, \"Behold, a small cloud, like a man\u2019s hand, is rising out of the sea.\" He said, \"Go up, tell Ahab, \u2018Get ready and go down, so that the rain doesn\u2019t stop you.\u2019\"",
                "It happened in a little while, that the sky grew black with clouds and wind, and there was a great rain. Ahab rode, and went to Jezreel.",
                "The hand of Yahweh was on Elijah; and he tucked his cloak into his belt and ran before Ahab to the entrance of Jezreel."
            ],
            19: [
                "Ahab told Jezebel all that Elijah had done, and how he had killed all the prophets with the sword.",
                "Then Jezebel sent a messenger to Elijah, saying, \"So let the gods do to me, and more also, if I don\u2019t make your life as the life of one of them by tomorrow about this time!\"",
                "When he saw that, he arose, and went for his life, and came to Beersheba, which belongs to Judah, and left his servant there.",
                "But he himself went a day\u2019s journey into the wilderness, and came and sat down under a juniper tree: and he requested for himself that he might die, and said, \"It is enough. Now, O Yahweh, take away my life; for I am not better than my fathers.\"",
                "He lay down and slept under a juniper tree; and behold, an angel touched him, and said to him, \"Arise and eat!\"",
                "He looked, and behold, there was at his head a cake baked on the coals, and a jar of water. He ate and drank, and lay down again.",
                "The angel of Yahweh came again the second time, and touched him, and said, \"Arise and eat, because the journey is too great for you.\"",
                "He arose, and ate and drank, and went in the strength of that food forty days and forty nights to Horeb the Mount of God.",
                "He came there to a cave, and lodged there; and behold, the word of Yahweh came to him, and he said to him, \"What are you doing here, Elijah?\"",
                "He said, \"I have been very jealous for Yahweh, the God of Armies; for the children of Israel have forsaken your covenant, thrown down your altars, and slain your prophets with the sword. I, even I only, am left; and they seek my life, to take it away.\"",
                "He said, \"Go out, and stand on the mountain before Yahweh.\" Behold, Yahweh passed by, and a great and strong wind tore the mountains, and broke in pieces the rocks before Yahweh; but Yahweh was not in the wind. After the wind an earthquake; but Yahweh was not in the earthquake.",
                "After the earthquake a fire passed; but Yahweh was not in the fire: and after the fire a still small voice.",
                "It was so, when Elijah heard it, that he wrapped his face in his mantle, and went out, and stood in the entrance of the cave. Behold, a voice came to him, and said, \"What are you doing here, Elijah?\"",
                "He said, \"I have been very jealous for Yahweh, the God of Armies; for the children of Israel have forsaken your covenant, thrown down your altars, and slain your prophets with the sword. I, even I only, am left; and they seek my life, to take it away.\"",
                "Yahweh said to him, \"Go, return on your way to the wilderness of Damascus. When you arrive, you shall anoint Hazael to be king over Syria.",
                "You shall anoint Jehu the son of Nimshi to be king over Israel; and you shall anoint Elisha the son of Shaphat of Abel Meholah to be prophet in your place.",
                "It shall happen, that he who escapes from the sword of Hazael, Jehu will kill; and he who escapes from the sword of Jehu, Elisha will kill.",
                "Yet will I leave seven thousand in Israel, all the knees which have not bowed to Baal, and every mouth which has not kissed him.\"",
                "So he departed there, and found Elisha the son of Shaphat, who was plowing, with twelve yoke of oxen before him, and he with the twelfth: and Elijah passed over to him, and cast his mantle on him.",
                "He left the oxen, and ran after Elijah, and said, \"Let me please kiss my father and my mother, and then I will follow you.\" He said to him, \"Go back again; for what have I done to you?\"",
                "He returned from following him, and took the yoke of oxen, and killed them, and boiled their flesh with the instruments of the oxen, and gave to the people, and they ate. Then he arose, and went after Elijah, and served him."
            ],
            20: [
                "Ben Hadad the king of Syria gathered all his army together; and there were thirty-two kings with him, and horses and chariots: and he went up and besieged Samaria, and fought against it.",
                "He sent messengers to Ahab king of Israel, into the city, and said to him, \"Thus says Ben Hadad,",
                "\u2018Your silver and your gold is mine. Your wives also and your children, even the best, are mine.\u2019\"",
                "The king of Israel answered, \"It is according to your saying, my lord, O king. I am yours, and all that I have.\"",
                "The messengers came again, and said, \"Ben Hadad says, \u2018I sent indeed to you, saying, \"You shall deliver me your silver, and your gold, and your wives, and your children;",
                "but I will send my servants to you tomorrow about this time, and they shall search your house, and the houses of your servants; and it shall be, that whatever is pleasant in your eyes, they shall put it in their hand, and take it away.\"\u2019\"",
                "Then the king of Israel called all the elders of the land, and said, \"Please notice how this man seeks mischief; for he sent to me for my wives, and for my children, and for my silver, and for my gold; and I didn\u2019t deny him.\"",
                "All the elders and all the people said to him, \"Don\u2019t listen, neither consent.\"",
                "Therefore he said to the messengers of Ben Hadad, \"Tell my lord the king, \u2018All that you sent for to your servant at the first I will do; but this thing I cannot do.\u2019\" The messengers departed, and brought him back the message.",
                "Ben Hadad sent to him, and said, \"The gods do so to me, and more also, if the dust of Samaria shall suffice for handfuls for all the people who follow me.\"",
                "The king of Israel answered, \"Tell him, \u2018Don\u2019t let him who puts on his armor brag like he who takes it off.\u2019\"",
                "It happened, when Ben Hadad heard this message, as he was drinking, he and the kings, in the pavilions, that he said to his servants, \"Prepare to attack!\" They prepared to attack the city.",
                "Behold, a prophet came near to Ahab king of Israel, and said, \"Thus says Yahweh, \u2018Have you seen all this great multitude? Behold, I will deliver it into your hand this day; and you shall know that I am Yahweh.\u2019\"",
                "Ahab said, \"By whom?\" He said, \"Thus says Yahweh, \u2018By the young men of the princes of the provinces.\u2019\" Then he said, \"Who shall begin the battle?\" He answered, \"You.\"",
                "Then he mustered the young men of the princes of the provinces, and they were two hundred and thirty-two. After them, he mustered all the people, even all the children of Israel, being seven thousand.",
                "They went out at noon. But Ben Hadad was drinking himself drunk in the pavilions, he and the kings, the thirty-two kings who helped him.",
                "The young men of the princes of the provinces went out first; and Ben Hadad sent out, and they told him, saying, \"Men are coming out from Samaria.\"",
                "He said, \"If they have come out for peace, take them alive; or if they have come out for war, take them alive.\"",
                "So these went out of the city, the young men of the princes of the provinces, and the army which followed them.",
                "They each killed his man. The Syrians fled, and Israel pursued them. Ben Hadad the king of Syria escaped on a horse with horsemen.",
                "The king of Israel went out, and struck the horses and chariots, and killed the Syrians with a great slaughter.",
                "The prophet came near to the king of Israel, and said to him, \"Go, strengthen yourself, and mark, and see what you do; for at the return of the year the king of Syria will come up against you.\"",
                "The servants of the king of Syria said to him, \"Their god is a god of the hills; therefore they were stronger than we. But let us fight against them in the plain, and surely we shall be stronger than they.",
                "Do this thing: take the kings away, every man out of his place, and put captains in their place.",
                "Muster an army, like the army that you have lost, horse for horse, and chariot for chariot. We will fight against them in the plain, and surely we will be stronger than them.\" He listened to their voice, and did so.",
                "It happened at the return of the year, that Ben Hadad mustered the Syrians, and went up to Aphek, to fight against Israel.",
                "The children of Israel were mustered, and were provisioned, and went against them. The children of Israel encamped before them like two little flocks of young goats; but the Syrians filled the country.",
                "A man of God came near and spoke to the king of Israel, and said, \"Thus says Yahweh, \u2018Because the Syrians have said, \"Yahweh is a god of the hills, but he is not a god of the valleys;\" therefore I will deliver all this great multitude into your hand, and you shall know that I am Yahweh.\u2019\"",
                "They encamped one over against the other seven days. So it was, that in the seventh day the battle was joined; and the children of Israel killed one hundred thousand footmen of the Syrians in one day.",
                "But the rest fled to Aphek, into the city; and the wall fell on twenty-seven thousand men who were left. Ben Hadad fled, and came into the city, into an inner room.",
                "His servants said to him, \"See now, we have heard that the kings of the house of Israel are merciful kings. Please let us put sackcloth on our bodies, and ropes on our heads, and go out to the king of Israel. Maybe he will save your life.\"",
                "So they put sackcloth on their bodies and ropes on their heads, and came to the king of Israel, and said, \"Your servant Ben Hadad says, \u2018Please let me live.\u2019\" He said, \"Is he still alive? He is my brother.\"",
                "Now the men observed diligently, and hurried to take this phrase; and they said, \"Your brother Ben Hadad.\" Then he said, \"Go, bring him.\" Then Ben Hadad came out to him; and he caused him to come up into the chariot.",
                "Ben Hadad said to him, \"The cities which my father took from your father I will restore. You shall make streets for yourself in Damascus, as my father made in Samaria.\" \"I,\" said Ahab, \"will let you go with this covenant.\" So he made a covenant with him, and let him go.",
                "A certain man of the sons of the prophets said to his fellow by the word of Yahweh, \"Please strike me!\" The man refused to strike him.",
                "Then he said to him, \"Because you have not obeyed the voice of Yahweh, behold, as soon as you are departed from me, a lion shall kill you.\" As soon as he was departed from him, a lion found him, and killed him.",
                "Then he found another man, and said, \"Please strike me.\" The man struck him, smiting and wounding him.",
                "So the prophet departed, and waited for the king by the way, and disguised himself with his headband over his eyes.",
                "As the king passed by, he cried to the king; and he said, \"Your servant went out into the midst of the battle; and behold, a man turned aside, and brought a man to me, and said, \u2018Guard this man! If by any means he be missing, then your life shall be for his life, or else you shall pay a talent of silver.\u2019",
                "As your servant was busy here and there, he was gone.\" The king of Israel said to him, \"So your judgment shall be; you yourself have decided it.\"",
                "He hurried, and took the headband away from his eyes; and the king of Israel recognized that he was of the prophets.",
                "He said to him, \"Thus says Yahweh, \u2018Because you have let go out of your hand the man whom I had devoted to destruction, therefore your life shall go for his life, and your people for his people.\u2019\"",
                "The king of Israel went to his house sullen and angry, and came to Samaria."
            ],
            21: [
                "It happened after these things, that Naboth the Jezreelite had a vineyard, which was in Jezreel, hard by the palace of Ahab king of Samaria.",
                "Ahab spoke to Naboth, saying, \"Give me your vineyard, that I may have it for a garden of herbs, because it is near to my house; and I will give you for it a better vineyard than it. Or, if it seems good to you, I will give you its worth in money.\"",
                "Naboth said to Ahab, \"May Yahweh forbid me, that I should give the inheritance of my fathers to you!\"",
                "Ahab came into his house sullen and angry because of the word which Naboth the Jezreelite had spoken to him; for he had said, \"I will not give you the inheritance of my fathers.\" He laid himself down on his bed, and turned away his face, and would eat no bread.",
                "But Jezebel his wife came to him, and said to him, \"Why is your spirit so sad, that you eat no bread?\"",
                "He said to her, \"Because I spoke to Naboth the Jezreelite, and said to him, \u2018Give me your vineyard for money; or else, if it pleases you, I will give you another vineyard for it.\u2019 He answered, \u2018I will not give you my vineyard.\u2019\"",
                "Jezebel his wife said to him, \"Do you now govern the kingdom of Israel? Arise, and eat bread, and let your heart be merry. I will give you the vineyard of Naboth the Jezreelite.\"",
                "So she wrote letters in Ahab\u2019s name, and sealed them with his seal, and sent the letters to the elders and to the nobles who were in his city, who lived with Naboth.",
                "She wrote in the letters, saying, \"Proclaim a fast, and set Naboth on high among the people.",
                "Set two men, base fellows, before him, and let them testify against him, saying, \u2018You cursed God and the king!\u2019 Then carry him out, and stone him to death.\"",
                "The men of his city, even the elders and the nobles who lived in his city, did as Jezebel had sent to them, according as it was written in the letters which she had sent to them.",
                "They proclaimed a fast, and set Naboth on high among the people.",
                "The two men, the base fellows, came in and sat before him. The base fellows testified against him, even against Naboth, in the presence of the people, saying, \"Naboth cursed God and the king!\" Then they carried him out of the city, and stoned him to death with stones.",
                "Then they sent to Jezebel, saying, \"Naboth has been stoned, and is dead.\"",
                "It happened, when Jezebel heard that Naboth was stoned, and was dead, that Jezebel said to Ahab, \"Arise, take possession of the vineyard of Naboth the Jezreelite, which he refused to give you for money; for Naboth is not alive, but dead.\"",
                "It happened, when Ahab heard that Naboth was dead, that Ahab rose up to go down to the vineyard of Naboth the Jezreelite, to take possession of it.",
                "The word of Yahweh came to Elijah the Tishbite, saying,",
                "\"Arise, go down to meet Ahab king of Israel, who dwells in Samaria. Behold, he is in the vineyard of Naboth, where he has gone down to take possession of it.",
                "You shall speak to him, saying, \u2018Thus says Yahweh, \"Have you killed and also taken possession?\"\u2019 You shall speak to him, saying, \u2018Thus says Yahweh, \"In the place where dogs licked the blood of Naboth, dogs will lick your blood, even yours.\"\u2019\"",
                "Ahab said to Elijah, \"Have you found me, my enemy?\" He answered, \"I have found you, because you have sold yourself to do that which is evil in the sight of Yahweh.",
                "Behold, I will bring evil on you, and will utterly sweep you away and will cut off from Ahab everyone who urinates against a wall, and him who is shut up and him who is left at large in Israel.",
                "I will make your house like the house of Jeroboam the son of Nebat, and like the house of Baasha the son of Ahijah for the provocation with which you have provoked me to anger, and have made Israel to sin.\"",
                "Yahweh also spoke of Jezebel, saying, \"The dogs shall eat Jezebel by the rampart of Jezreel.",
                "The dogs will eat he who dies of Ahab in the city; and the birds of the sky will eat he who dies in the field.\"",
                "But there was none like Ahab, who sold himself to do that which was evil in the sight of Yahweh, whom Jezebel his wife stirred up.",
                "He did very abominably in following idols, according to all that the Amorites did, whom Yahweh cast out before the children of Israel.",
                "It happened, when Ahab heard those words, that he tore his clothes, and put sackcloth on his flesh, and fasted, and lay in sackcloth, and went softly.",
                "The word of Yahweh came to Elijah the Tishbite, saying,",
                "\"See how Ahab humbles himself before me? Because he humbles himself before me, I will not bring the evil in his days; but in his son\u2019s days will I bring the evil on his house.\""
            ],
            22: [
                "They continued three years without war between Syria and Israel.",
                "It happened in the third year, that Jehoshaphat the king of Judah came down to the king of Israel.",
                "The king of Israel said to his servants, \"You know that Ramoth Gilead is ours, and we are still, and don\u2019t take it out of the hand of the king of Syria?\"",
                "He said to Jehoshaphat, \"Will you go with me to battle to Ramoth Gilead?\" Jehoshaphat said to the king of Israel, \"I am as you are, my people as your people, my horses as your horses.\"",
                "Jehoshaphat said to the king of Israel, \"Please inquire first for the word of Yahweh.\"",
                "Then the king of Israel gathered the prophets together, about four hundred men, and said to them, \"Shall I go against Ramoth Gilead to battle, or shall I forbear?\" They said, \"Go up; for the Lord will deliver it into the hand of the king.\"",
                "But Jehoshaphat said, \"Isn\u2019t there here a prophet of Yahweh, that we may inquire of him?\"",
                "The king of Israel said to Jehoshaphat, \"There is yet one man by whom we may inquire of Yahweh, Micaiah the son of Imlah; but I hate him; for he does not prophesy good concerning me, but evil.\" Jehoshaphat said, \"Don\u2019t let the king say so.\"",
                "Then the king of Israel called an officer, and said, \"Quickly get Micaiah the son of Imlah.\"",
                "Now the king of Israel and Jehoshaphat the king of Judah were sitting each on his throne, arrayed in their robes, in an open place at the entrance of the gate of Samaria; and all the prophets were prophesying before them.",
                "Zedekiah the son of Chenaanah made him horns of iron, and said, \"Thus says Yahweh, \u2018With these you shall push the Syrians, until they are consumed.\u2019\"",
                "All the prophets prophesied so, saying, \"Go up to Ramoth Gilead, and prosper; for Yahweh will deliver it into the hand of the king.\"",
                "The messenger who went to call Micaiah spoke to him, saying, \"See now, the prophets declare good to the king with one mouth. Please let your word be like the word of one of them, and speak good.\"",
                "Micaiah said, \"As Yahweh lives, what Yahweh says to me, that I will speak.\"",
                "When he had come to the king, the king said to him, \"Micaiah, shall we go to Ramoth Gilead to battle, or shall we forbear?\" He answered him, \"Go up and prosper; and Yahweh will deliver it into the hand of the king.\"",
                "The king said to him, \"How many times do I have to adjure you that you speak to me nothing but the truth in the name of Yahweh?\"",
                "He said, \"I saw all Israel scattered on the mountains, as sheep that have no shepherd. Yahweh said, \u2018These have no master. Let them each return to his house in peace.\u2019\"",
                "The king of Israel said to Jehoshaphat, \"Didn\u2019t I tell you that he would not prophesy good concerning me, but evil?\"",
                "Micaiah said, \"Therefore hear the word of Yahweh. I saw Yahweh sitting on his throne, and all the army of heaven standing by him on his right hand and on his left.",
                "Yahweh said, \u2018Who shall entice Ahab, that he may go up and fall at Ramoth Gilead?\u2019 One said one thing; and another said another.",
                "A spirit came out and stood before Yahweh, and said, \u2018I will entice him.\u2019",
                "Yahweh said to him, \u2018How?\u2019 He said, \u2018I will go out and will be a lying spirit in the mouth of all his prophets.\u2019 He said, \u2018You will entice him, and will also prevail. Go out and do so.\u2019",
                "Now therefore, behold, Yahweh has put a lying spirit in the mouth of all these your prophets; and Yahweh has spoken evil concerning you.\"",
                "Then Zedekiah the son of Chenaanah came near, and struck Micaiah on the cheek, and said, \"Which way did the Spirit of Yahweh go from me to speak to you?\"",
                "Micaiah said, \"Behold, you will see on that day, when you go into an inner room to hide yourself.\"",
                "The king of Israel said, \"Take Micaiah, and carry him back to Amon the governor of the city, and to Joash the king\u2019s son.",
                "Say, \u2018Thus says the king, \"Put this fellow in the prison, and feed him with bread of affliction and with water of affliction, until I come in peace.\"\u2019\"",
                "Micaiah said, \"If you return at all in peace, Yahweh has not spoken by me.\" He said, \"Listen, all you people!\"",
                "So the king of Israel and Jehoshaphat the king of Judah went up to Ramoth Gilead.",
                "The king of Israel said to Jehoshaphat, \"I will disguise myself, and go into the battle; but you put on your robes.\" The king of Israel disguised himself, and went into the battle.",
                "Now the king of Syria had commanded the thirty-two captains of his chariots, saying, Fight neither with small nor great, except only with the king of Israel.",
                "It happened, when the captains of the chariots saw Jehoshaphat, that they said, \"Surely that is the king of Israel!\" and they turned aside to fight against him. Jehoshaphat cried out.",
                "It happened, when the captains of the chariots saw that it was not the king of Israel, that they turned back from pursuing him.",
                "A certain man drew his bow at random, and struck the king of Israel between the joints of the armor. Therefore he said to the driver of his chariot, \"Turn your hand, and carry me out of the battle; for I am severely wounded.\"",
                "The battle increased that day. The king was propped up in his chariot facing the Syrians, and died at evening. The blood ran out of the wound into the bottom of the chariot.",
                "A cry went throughout the army about the going down of the sun, saying, \"Every man to his city, and every man to his country!\"",
                "So the king died, and was brought to Samaria; and they buried the king in Samaria.",
                "They washed the chariot by the pool of Samaria; and the dogs licked up his blood where the prostitutes washed themselves; according to the word of Yahweh which he spoke.",
                "Now the rest of the acts of Ahab, and all that he did, and the ivory house which he built, and all the cities that he built, aren\u2019t they written in the book of the chronicles of the kings of Israel?",
                "So Ahab slept with his fathers; and Ahaziah his son reigned in his place.",
                "Jehoshaphat the son of Asa began to reign over Judah in the fourth year of Ahab king of Israel.",
                "Jehoshaphat was thirty-five years old when he began to reign; and he reigned twenty-five years in Jerusalem. His mother\u2019s name was Azubah the daughter of Shilhi.",
                "He walked in all the way of Asa his father; He didn\u2019t turn aside from it, doing that which was right in the eyes of Yahweh: however the high places were not taken away; the people still sacrificed and burnt incense in the high places.",
                "Jehoshaphat made peace with the king of Israel.",
                "Now the rest of the acts of Jehoshaphat, and his might that he showed, and how he warred, aren\u2019t they written in the book of the chronicles of the kings of Judah?",
                "The remnant of the sodomites, that remained in the days of his father Asa, he put away out of the land.",
                "There was no king in Edom: a deputy was king.",
                "Jehoshaphat made ships of Tarshish to go to Ophir for gold: but they didn\u2019t go; for the ships were broken at Ezion Geber.",
                "Then Ahaziah the son of Ahab said to Jehoshaphat, \"Let my servants go with your servants in the ships.\" But Jehoshaphat would not.",
                "Jehoshaphat slept with his fathers, and was buried with his fathers in the city of David his father; Jehoram his son reigned in his place.",
                "Ahaziah the son of Ahab began to reign over Israel in Samaria in the seventeenth year of Jehoshaphat king of Judah, and he reigned two years over Israel.",
                "He did that which was evil in the sight of Yahweh, and walked in the way of his father, and in the way of his mother, and in the way of Jeroboam the son of Nebat, in which he made Israel to sin.",
                "He served Baal, and worshiped him, and provoked to anger Yahweh, the God of Israel, according to all that his father had done."
            ]
        },
        12: {
            1: [
                "Moab rebelled against Israel after the death of Ahab.",
                "Ahaziah fell down through the lattice in his upper room that was in Samaria, and was sick. So he sent messengers, and said to them, \"Go, inquire of Baal Zebub, the god of Ekron, whether I will recover of this sickness.\"",
                "But the angel of Yahweh said to Elijah the Tishbite, \"Arise, go up to meet the messengers of the king of Samaria, and tell them, \u2018Is it because there is no God in Israel, that you go to inquire of Baal Zebub, the god of Ekron?",
                "Now therefore thus says Yahweh, \"You shall not come down from the bed where you have gone up, but shall surely die.\"\u2019\" Elijah departed.",
                "The messengers returned to him, and he said to them, \"Why is it that you have returned?\"",
                "They said to him, \"A man came up to meet us, and said to us, \u2018Go, return to the king who sent you, and tell him, \"Thus says Yahweh, \u2018Is it because there is no God in Israel, that you send to inquire of Baal Zebub, the god of Ekron? Therefore you shall not come down from the bed where you have gone up, but shall surely die.\u2019\"\u2019\"",
                "He said to them, \"What kind of man was he who came up to meet you, and told you these words?\"",
                "They answered him, \"He was a hairy man, and wearing a leather belt around his waist.\" He said, \"It is Elijah the Tishbite.\"",
                "Then the king sent a captain of fifty with his fifty to him. He went up to him; and behold, he was sitting on the top of the hill. He said to him, \"Man of God, the king has said, \u2018Come down!\u2019\"",
                "Elijah answered to the captain of fifty, \"If I am a man of God, let fire come down from the sky, and consume you and your fifty!\" Fire came down from the sky, and consumed him and his fifty.",
                "Again he sent to him another captain of fifty and his fifty. He answered him, \"Man of God, the king has said, \u2018Come down quickly!\u2019\"",
                "Elijah answered them, \"If I am a man of God, let fire come down from the sky, and consume you and your fifty!\" The fire of God came down from the sky, and consumed him and his fifty.",
                "Again he sent the captain of a third fifty with his fifty. The third captain of fifty went up, and came and fell on his knees before Elijah, and begged him, and said to him, \"Man of God, please let my life, and the life of these fifty your servants, be precious in your sight.",
                "Behold, fire came down from the sky, and consumed the two former captains of fifty with their fifties. But now let my life be precious in your sight.\"",
                "The angel of Yahweh said to Elijah, \"Go down with him. Don\u2019t be afraid of him.\" He arose, and went down with him to the king.",
                "He said to him, \"Thus says Yahweh, \u2018Because you have sent messengers to inquire of Baal Zebub, the god of Ekron, is it because there is no God in Israel to inquire of his word? Therefore you shall not come down from the bed where you have gone up, but shall surely die.\u2019\"",
                "So he died according to the word of Yahweh which Elijah had spoken. Jehoram began to reign in his place in the second year of Jehoram the son of Jehoshaphat king of Judah; because he had no son.",
                "Now the rest of the acts of Ahaziah which he did, aren\u2019t they written in the book of the chronicles of the kings of Israel?"
            ],
            2: [
                "It happened, when Yahweh would take up Elijah by a whirlwind into heaven, that Elijah went with Elisha from Gilgal.",
                "Elijah said to Elisha, \"Please wait here, for Yahweh has sent me as far as Bethel.\" Elisha said, \"As Yahweh lives, and as your soul lives, I will not leave you.\" So they went down to Bethel.",
                "The sons of the prophets who were at Bethel came out to Elisha, and said to him, \"Do you know that Yahweh will take away your master from your head today?\" He said, \"Yes, I know it; hold your peace.\"",
                "Elijah said to him, \"Elisha, please wait here, for Yahweh has sent me to Jericho.\" He said, \"As Yahweh lives, and as your soul lives, I will not leave you.\" So they came to Jericho.",
                "The sons of the prophets who were at Jericho came near to Elisha, and said to him, \"Do you know that Yahweh will take away your master from your head today?\" He answered, \"Yes, I know it. Hold your peace.\"",
                "Elijah said to him, \"Please wait here, for Yahweh has sent me to the Jordan.\" He said, \"As Yahweh lives, and as your soul lives, I will not leave you.\" They both went on.",
                "Fifty men of the sons of the prophets went, and stood opposite them at a distance; and they both stood by the Jordan.",
                "Elijah took his mantle, and wrapped it together, and struck the waters, and they were divided here and there, so that they two went over on dry ground.",
                "It happened, when they had gone over, that Elijah said to Elisha, \"Ask what I shall do for you, before I am taken from you.\" Elisha said, \"Please let a double portion of your spirit be on me.\"",
                "He said, \"You have asked a hard thing. If you see me when I am taken from you, it shall be so for you; but if not, it shall not be so.\"",
                "It happened, as they still went on, and talked, that behold, a chariot of fire and horses of fire separated them; and Elijah went up by a whirlwind into heaven.",
                "Elisha saw it, and he cried, \"My father, my father, the chariots of Israel and its horsemen!\" He saw him no more: and he took hold of his own clothes, and tore them in two pieces.",
                "He took up also the mantle of Elijah that fell from him, and went back, and stood by the bank of the Jordan.",
                "He took the mantle of Elijah that fell from him, and struck the waters, and said, \"Where is Yahweh, the God of Elijah?\" When he also had struck the waters, they were divided here and there; and Elisha went over.",
                "When the sons of the prophets who were at Jericho over against him saw him, they said, \"The spirit of Elijah rests on Elisha.\" They came to meet him, and bowed themselves to the ground before him.",
                "They said to him, \"See now, there are with your servants fifty strong men. Please let them go and seek your master. Perhaps the Spirit of Yahweh has taken him up, and put him on some mountain, or into some valley. He said, \"You shall not send them.\"",
                "When they urged him until he was ashamed, he said, \"Send them.\" They sent therefore fifty men; and they searched for three days, but didn\u2019t find him.",
                "They came back to him, while he stayed at Jericho; and he said to them, \"Didn\u2019t I tell you, \u2018Don\u2019t go?\u2019\"",
                "The men of the city said to Elisha, \"Behold, please, the situation of this city is pleasant, as my lord sees; but the water is bad, and the land miscarries.\"",
                "He said, \"Bring me a new jar, and put salt in it.\" They brought it to him.",
                "He went out to the spring of the waters, and threw salt into it, and said, \"Thus says Yahweh, \u2018I have healed these waters. There shall not be from there any more death or miscarrying.\u2019\"",
                "So the waters were healed to this day, according to the word of Elisha which he spoke.",
                "He went up from there to Bethel. As he was going up by the way, some youths came out of the city and mocked him, and said to him, \"Go up, you baldy! Go up, you baldhead!\"",
                "He looked behind him and saw them, and cursed them in the name of Yahweh. Two female bears came out of the woods, and mauled forty-two of those youths.",
                "He went from there to Mount Carmel, and from there he returned to Samaria."
            ],
            3: [
                "Now Jehoram the son of Ahab began to reign over Israel in Samaria in the eighteenth year of Jehoshaphat king of Judah, and reigned twelve years.",
                "He did that which was evil in the sight of Yahweh, but not like his father, and like his mother; for he put away the pillar of Baal that his father had made.",
                "Nevertheless he held to the sins of Jeroboam the son of Nebat, with which he made Israel to sin; he didn\u2019t depart from it.",
                "Now Mesha king of Moab was a sheep breeder; and he rendered to the king of Israel the wool of one hundred thousand lambs, and of one hundred thousand rams.",
                "But it happened, when Ahab was dead, that the king of Moab rebelled against the king of Israel.",
                "King Jehoram went out of Samaria at that time, and mustered all Israel.",
                "He went and sent to Jehoshaphat the king of Judah, saying, \"The king of Moab has rebelled against me. Will you go with me against Moab to battle?\" He said, \"I will go up. I am as you are, my people as your people, my horses as your horses.\"",
                "He said, \"Which way shall we go up?\" He answered, \"The way of the wilderness of Edom.\"",
                "So the king of Israel went, and the king of Judah, and the king of Edom; and they made a circuit of seven days\u2019 journey. There was no water for the army, nor for the animals that followed them.",
                "The king of Israel said, \"Alas! For Yahweh has called these three kings together to deliver them into the hand of Moab.\"",
                "But Jehoshaphat said, \"Isn\u2019t there here a prophet of Yahweh, that we may inquire of Yahweh by him?\" One of the king of Israel\u2019s servants answered, \"Elisha the son of Shaphat is here, who poured water on the hands of Elijah.\"",
                "Jehoshaphat said, \"The word of Yahweh is with him.\" So the king of Israel and Jehoshaphat and the king of Edom went down to him.",
                "Elisha said to the king of Israel, \"What have I to do with you? Go to the prophets of your father, and to the prophets of your mother.\" The king of Israel said to him, \"No; for Yahweh has called these three kings together to deliver them into the hand of Moab.\"",
                "Elisha said, \"As Yahweh of Armies lives, before whom I stand, surely, were it not that I respect the presence of Jehoshaphat the king of Judah, I would not look toward you, nor see you.",
                "But now bring me a minstrel.\" It happened, when the minstrel played, that the hand of Yahweh came on him.",
                "He said, \"Thus says Yahweh, \u2018Make this valley full of trenches.\u2019",
                "For thus says Yahweh, \u2018You will not see wind, neither will you see rain; yet that valley shall be filled with water, and you will drink, both you and your livestock and your animals.",
                "This is but a light thing in the sight of Yahweh. He will also deliver the Moabites into your hand.",
                "You shall strike every fortified city, and every choice city, and shall fell every good tree, and stop all springs of water, and mar every good piece of land with stones.\u2019\"",
                "It happened in the morning, about the time of offering the offering, that behold, water came by the way of Edom, and the country was filled with water.",
                "Now when all the Moabites heard that the kings had come up to fight against them, they gathered themselves together, all who were able to put on armor, and upward, and stood on the border.",
                "They rose up early in the morning, and the sun shone on the water, and the Moabites saw the water over against them as red as blood.",
                "They said, \"This is blood. The kings are surely destroyed, and they have struck each other. Now therefore, Moab, to the spoil!\"",
                "When they came to the camp of Israel, the Israelites rose up and struck the Moabites, so that they fled before them; and they went forward into the land smiting the Moabites.",
                "They beat down the cities; and on every good piece of land they cast every man his stone, and filled it; and they stopped all the springs of water, and felled all the good trees, until in Kir Hareseth only they left its stones; however the men armed with slings went about it, and struck it.",
                "When the king of Moab saw that the battle was too severe for him, he took with him seven hundred men who drew sword, to break through to the king of Edom; but they could not.",
                "Then he took his eldest son who would have reigned in his place, and offered him for a burnt offering on the wall. There was great wrath against Israel: and they departed from him, and returned to their own land."
            ],
            4: [
                "Now there cried a certain woman of the wives of the sons of the prophets to Elisha, saying, \"Your servant my husband is dead. You know that your servant feared Yahweh. Now the creditor has come to take for himself my two children to be slaves.\"",
                "Elisha said to her, \"What shall I do for you? Tell me: what do you have in the house?\" She said, \"Your handmaid has nothing in the house, except a pot of oil.\"",
                "Then he said, \"Go, borrow containers from of all your neighbors, even empty containers. Don\u2019t borrow just a few.",
                "You shall go in, and shut the door on you and on your sons, and pour out into all those containers; and you shall set aside that which is full.\"",
                "So she went from him, and shut the door on her and on her sons; they brought the containers to her, and she poured out.",
                "It happened, when the containers were full, that she said to her son, \"Bring me another container.\" He said to her, \"There isn\u2019t another container.\" The oil stopped flowing.",
                "Then she came and told the man of God. He said, \"Go, sell the oil, and pay your debt; and you and your sons live on the rest.\"",
                "It fell on a day, that Elisha passed to Shunem, where there was a prominent woman; and she persuaded him to eat bread. So it was, that as often as he passed by, he turned in there to eat bread.",
                "She said to her husband, \"See now, I perceive that this is a holy man of God, that passes by us continually.",
                "Please let us make a little room on the wall. Let us set for him there a bed, a table, a chair, and a lamp stand. It shall be, when he comes to us, that he shall turn in there.\"",
                "One day he came there, and he turned into the room and lay there.",
                "He said to Gehazi his servant, \"Call this Shunammite.\" When he had called her, she stood before him.",
                "He said to him, \"Say now to her, \u2018Behold, you have cared for us with all this care. What is to be done for you? Would you like to be spoken for to the king, or to the captain of the army?\u2019\" She answered, \"I dwell among my own people.\"",
                "He said, \"What then is to be done for her?\" Gehazi answered, \"Most certainly she has no son, and her husband is old.\"",
                "He said, \"Call her.\" When he had called her, she stood in the door.",
                "He said, \"At this season, when the time comes around, you will embrace a son.\" She said, \"No, my lord, you man of God, do not lie to your handmaid.\"",
                "The woman conceived, and bore a son at that season, when the time came around, as Elisha had said to her.",
                "When the child was grown, it happened one day that he went out to his father to the reapers.",
                "He said to his father, \"My head! My head!\" He said to his servant, \"Carry him to his mother.\"",
                "When he had taken him, and brought him to his mother, he sat on her knees until noon, and then died.",
                "She went up and laid him on the bed of the man of God, and shut the door on him, and went out.",
                "She called to her husband, and said, \"Please send me one of the servants, and one of the donkeys, that I may run to the man of God, and come again.\"",
                "He said, \"Why would you want go to him today? It is neither new moon nor Sabbath.\" She said, \"It\u2019s alright.\"",
                "Then she saddled a donkey, and said to her servant, \"Drive, and go forward! Don\u2019t slow down for me, unless I ask you to.\"",
                "So she went, and came to the man of God to Mount Carmel. It happened, when the man of God saw her afar off, that he said to Gehazi his servant, \"Behold, there is the Shunammite.",
                "Please run now to meet her, and ask her, \u2018Is it well with you? Is it well with your husband? Is it well with the child?\u2019\" She answered, \"It is well.\"",
                "When she came to the man of God to the hill, she caught hold of his feet. Gehazi came near to thrust her away; but the man of God said, \"Leave her alone; for her soul is troubled within her; and Yahweh has hidden it from me, and has not told me.\"",
                "Then she said, \"Did I desire a son of my lord? Didn\u2019t I say, Do not deceive me?\"",
                "Then he said to Gehazi, \"Tuck your cloak into your belt, take my staff in your hand, and go your way. If you meet any man, don\u2019t greet him; and if anyone greets you, don\u2019t answer him again. Then lay my staff on the face of the child.\"",
                "The mother of the child said, \"As Yahweh lives, and as your soul lives, I will not leave you.\" He arose, and followed her.",
                "Gehazi passed on before them, and laid the staff on the face of the child; but there was neither voice, nor hearing. Therefore he returned to meet him, and told him, saying, \"The child has not awakened.\"",
                "When Elisha had come into the house, behold, the child was dead, and laid on his bed.",
                "He went in therefore, and shut the door on them both, and prayed to Yahweh.",
                "He went up, and lay on the child, and put his mouth on his mouth, and his eyes on his eyes, and his hands on his hands. He stretched himself on him; and the flesh of the child grew warm.",
                "Then he returned, and walked in the house once back and forth; and went up, and stretched himself on him. Then the child sneezed seven times, and the child opened his eyes.",
                "He called Gehazi, and said, \"Call this Shunammite!\" So he called her. When she had come in to him, he said, \"Take up your son.\"",
                "Then she went in, and fell at his feet, and bowed herself to the ground; and she took up her son, and went out.",
                "Elisha came again to Gilgal. There was a famine in the land; and the sons of the prophets were sitting before him; and he said to his servant, \"Set on the great pot, and boil stew for the sons of the prophets.\"",
                "One went out into the field to gather herbs, and found a wild vine, and gathered of it wild gourds his lap full, and came and shred them into the pot of stew; for they didn\u2019t recognize them.",
                "So they poured out for the men to eat. It happened, as they were eating of the stew, that they cried out, and said, \"Man of God, there is death in the pot!\" They could not eat of it.",
                "But he said, \"Then bring meal.\" He cast it into the pot; and he said, \"Pour out for the people, that they may eat.\" There was no harm in the pot.",
                "A man from Baal Shalishah came, and brought the man of God bread of the first fruits, twenty loaves of barley, and fresh ears of grain in his sack. He said, \"Give to the people, that they may eat.\"",
                "His servant said, \"What, should I set this before a hundred men?\" But he said, \"Give the people, that they may eat; for thus says Yahweh, \u2018They will eat, and will have some left over.\u2019\"",
                "So he set it before them, and they ate, and left some of it, according to the word of Yahweh."
            ],
            5: [
                "Now Naaman, captain of the army of the king of Syria, was a great man with his master, and honorable, because by him Yahweh had given victory to Syria: he was also a mighty man of valor, but he was a leper.",
                "The Syrians had gone out in bands, and had brought away captive out of the land of Israel a little maiden; and she waited on Naaman\u2019s wife.",
                "She said to her mistress, \"I wish that my lord were with the prophet who is in Samaria! Then he would heal him of his leprosy.\"",
                "Someone went in, and told his lord, saying, \"The maiden who is from the land of Israel said this.\"",
                "The king of Syria said, \"Go now, and I will send a letter to the king of Israel.\" He departed, and took with him ten talents of silver, and six thousand pieces of gold, and ten changes of clothing.",
                "He brought the letter to the king of Israel, saying, \"Now when this letter has come to you, behold, I have sent Naaman my servant to you, that you may heal him of his leprosy.\"",
                "It happened, when the king of Israel had read the letter, that he tore his clothes, and said, \"Am I God, to kill and to make alive, that this man sends to me to heal a man of his leprosy? But please consider and see how he seeks a quarrel against me.\"",
                "It was so, when Elisha the man of God heard that the king of Israel had torn his clothes, that he sent to the king, saying, \"Why have you torn your clothes? Let him come now to me, and he shall know that there is a prophet in Israel.\"",
                "So Naaman came with his horses and with his chariots, and stood at the door of the house of Elisha.",
                "Elisha sent a messenger to him, saying, \"Go and wash in the Jordan seven times, and your flesh shall come again to you, and you shall be clean.\"",
                "But Naaman was angry, and went away, and said, \"Behold, I thought, \u2018He will surely come out to me, and stand, and call on the name of Yahweh his God, and wave his hand over the place, and heal the leper.\u2019",
                "Aren\u2019t Abanah and Pharpar, the rivers of Damascus, better than all the waters of Israel? Couldn\u2019t I wash in them, and be clean?\" So he turned and went away in a rage.",
                "His servants came near, and spoke to him, and said, \"My father, if the prophet had asked you do some great thing, wouldn\u2019t you have done it? How much rather then, when he says to you, \u2018Wash, and be clean?\u2019\"",
                "Then went he down, and dipped himself seven times in the Jordan, according to the saying of the man of God; and his flesh was restored like the flesh of a little child, and he was clean.",
                "He returned to the man of God, he and all his company, and came, and stood before him; and he said, \"See now, I know that there is no God in all the earth, but in Israel. Now therefore, please take a gift from your servant.\"",
                "But he said, \"As Yahweh lives, before whom I stand, I will receive none.\" He urged him to take it; but he refused.",
                "Naaman said, \"If not, then, please let two mules\u2019 burden of earth be given to your servant; for your servant will from now on offer neither burnt offering nor sacrifice to other gods, but to Yahweh.",
                "In this thing may Yahweh pardon your servant: when my master goes into the house of Rimmon to worship there, and he leans on my hand, and I bow myself in the house of Rimmon. When I bow myself in the house of Rimmon, may Yahweh pardon your servant in this thing.\"",
                "He said to him, \"Go in peace.\" So he departed from him a little way.",
                "But Gehazi the servant of Elisha the man of God, said, \"Behold, my master has spared this Naaman the Syrian, in not receiving at his hands that which he brought. As Yahweh lives, I will run after him, and take something from him.\"",
                "So Gehazi followed after Naaman. When Naaman saw one running after him, he came down from the chariot to meet him, and said, \"Is all well?\"",
                "He said, \"All is well. My master has sent me, saying, \u2018Behold, even now two young men of the sons of the prophets have come to me from the hill country of Ephraim. Please give them a talent of silver and two changes of clothing.\u2019\"",
                "Naaman said, \"Be pleased to take two talents.\" He urged him, and bound two talents of silver in two bags, with two changes of clothing, and laid them on two of his servants; and they carried them before him.",
                "When he came to the hill, he took them from their hand, and stored them in the house. Then he let the men go, and they departed.",
                "But he went in, and stood before his master. Elisha said to him, \"Where did you come from, Gehazi?\" He said, \"Your servant went nowhere.\"",
                "He said to him, \"Didn\u2019t my heart go with you, when the man turned from his chariot to meet you? Is it a time to receive money, and to receive garments, and olive groves and vineyards, and sheep and cattle, and male servants and female servants?",
                "Therefore the leprosy of Naaman will cling to you and to your seed forever.\" He went out from his presence a leper, as white as snow."
            ],
            6: [
                "The sons of the prophets said to Elisha, \"See now, the place where we dwell before you is too small for us.",
                "Please let us go to the Jordan, and every man take a beam from there, and let us make us a place there, where we may dwell.\" He answered, \"Go!\"",
                "One said, \"Please be pleased to go with your servants.\" He answered, \"I will go.\"",
                "So he went with them. When they came to the Jordan, they cut down wood.",
                "But as one was felling a beam, the axe head fell into the water. Then he cried, and said, \"Alas, my master! For it was borrowed.\"",
                "The man of God asked, \"Where did it fall?\" He showed him the place. He cut down a stick, threw it in there, and made the iron float.",
                "He said, \"Take it.\" So he put out his hand and took it.",
                "Now the king of Syria was warring against Israel; and he took counsel with his servants, saying, \"My camp will be in such and such a place.\"",
                "The man of God sent to the king of Israel, saying, \"Beware that you not pass such a place; for the Syrians are coming down there.\"",
                "The king of Israel sent to the place which the man of God told him and warned him of; and he saved himself there, not once nor twice.",
                "The heart of the king of Syria was very troubled about this. He called his servants, and said to them, \"Won\u2019t you show me which of us is for the king of Israel?\"",
                "One of his servants said, \"No, my lord, O king; but Elisha, the prophet who is in Israel, tells the king of Israel the words that you speak in your bedroom.\"",
                "He said, \"Go and see where he is, that I may send and get him.\" It was told him, saying, \"Behold, he is in Dothan.\"",
                "Therefore he sent horses, chariots, and a great army there. They came by night, and surrounded the city.",
                "When the servant of the man of God had risen early, and gone out, behold, an army with horses and chariots was around the city. His servant said to him, \"Alas, my master! What shall we do?\"",
                "He answered, \"Don\u2019t be afraid; for those who are with us are more than those who are with them.\"",
                "Elisha prayed, and said, \"Yahweh, please open his eyes, that he may see.\" Yahweh opened the eyes of the young man; and he saw: and behold, the mountain was full of horses and chariots of fire around Elisha.",
                "When they came down to him, Elisha prayed to Yahweh, and said, \"Please strike this people with blindness.\" He struck them with blindness according to the word of Elisha.",
                "Elisha said to them, \"This is not the way, neither is this the city. Follow me, and I will bring you to the man whom you seek.\" He led them to Samaria.",
                "It happened, when they had come into Samaria, that Elisha said, \"Yahweh, open the eyes of these men, that they may see.\" Yahweh opened their eyes, and they saw; and behold, they were in the midst of Samaria.",
                "The king of Israel said to Elisha, when he saw them, \"My father, shall I strike them? Shall I strike them?\"",
                "He answered, \"You shall not strike them. Would you strike those whom you have taken captive with your sword and with your bow? Set bread and water before them, that they may eat and drink, and go to their master.\"",
                "He prepared great feast for them. When they had eaten and drunk, he sent them away, and they went to their master. The bands of Syria stopped raiding the land of Israel.",
                "It happened after this, that Benhadad king of Syria gathered all his army, and went up and besieged Samaria.",
                "There was a great famine in Samaria. Behold, they besieged it, until a donkey\u2019s head was sold for eighty pieces of silver, and the fourth part of a kab of dove\u2019s dung for five pieces of silver.",
                "As the king of Israel was passing by on the wall, a woman cried to him, saying, \"Help, my lord, O king!\"",
                "He said, \"If Yahweh doesn\u2019t help you, from where could I help you? From of the threshing floor, or from the winepress?\"",
                "The king said to her, \"What ails you?\" She answered, \"This woman said to me, \u2018Give your son, that we may eat him today, and we will eat my son tomorrow.\u2019",
                "So we boiled my son, and ate him: and I said to her on the next day, \u2018Give your son, that we may eat him;\u2019 and she has hidden her son.\"",
                "It happened, when the king heard the words of the woman, that he tore his clothes (now he was passing by on the wall); and the people looked, and behold, he had sackcloth underneath on his flesh.",
                "Then he said, \"God do so to me, and more also, if the head of Elisha the son of Shaphat shall stay on him this day.\"",
                "But Elisha was sitting in his house, and the elders were sitting with him. Then the king sent a man from before him; but before the messenger came to him, he said to the elders, \"Do you see how this son of a murderer has sent to take away my head? Behold, when the messenger comes, shut the door, and hold the door shut against him. Isn\u2019t the sound of his master\u2019s feet behind him?\"",
                "While he was still talking with them, behold, the messenger came down to him. Then he said, \"Behold, this evil is from Yahweh. Why should I wait for Yahweh any longer?\""
            ],
            7: [
                "Elisha said, \"Hear the word of Yahweh. Thus says Yahweh, \u2018Tomorrow about this time a measure of fine flour will be sold for a shekel, and two measures of barley for a shekel, in the gate of Samaria.\u2019\"",
                "Then the captain on whose hand the king leaned answered the man of God, and said, \"Behold, if Yahweh made windows in heaven, could this thing be?\" He said, \"Behold, you shall see it with your eyes, but shall not eat of it.\"",
                "Now there were four leprous men at the entrance of the gate. They said one to another, \"Why do we sit here until we die?",
                "If we say, \u2018We will enter into the city,\u2019 then the famine is in the city, and we shall die there. If we sit still here, we also die. Now therefore come, and let us surrender to the army of the Syrians. If they save us alive, we will live; and if they kill us, we will only die.\"",
                "They rose up in the twilight, to go to the camp of the Syrians. When they had come to the outermost part of the camp of the Syrians, behold, there was no man there.",
                "For the Lord had made the army of the Syrians to hear a noise of chariots, and a noise of horses, even the noise of a great army: and they said one to another, Behold, the king of Israel has hired against us the kings of the Hittites, and the kings of the Egyptians, to come on us.",
                "Therefore they arose and fled in the twilight, and left their tents, and their horses, and their donkeys, even the camp as it was, and fled for their life.",
                "When these lepers came to the outermost part of the camp, they went into one tent, and ate and drink, and carried there silver, and gold, and clothing, and went and hid it. Then they came back, and entered into another tent, and carried there also, and went and hid it.",
                "Then they said one to another, \"We aren\u2019t doing right. This day is a day of good news, and we keep silent. If we wait until the morning light, punishment will overtake us. Now therefore come, let us go and tell the king\u2019s household.\"",
                "So they came and called to the porter of the city; and they told them, saying, \"We came to the camp of the Syrians, and, behold, there was no man there, neither voice of man, but the horses tied, and the donkeys tied, and the tents as they were.\"",
                "He called the porters; and they told it to the king\u2019s household within.",
                "The king arose in the night, and said to his servants, \"I will now show you what the Syrians have done to us. They know that we are hungry. Therefore are they gone out of the camp to hide themselves in the field, saying, \u2018When they come out of the city, we shall take them alive, and get into the city.\u2019\"",
                "One of his servants answered, \"Please let some take five of the horses that remain, which are left in the city. Behold, they are like all the multitude of Israel who are left in it. Behold, they are like all the multitude of Israel who are consumed. Let us send and see.\"",
                "They took therefore two chariots with horses; and the king sent after the army of the Syrians, saying, \"Go and see.\"",
                "They went after them to the Jordan; and behold, all the way was full of garments and vessels, which the Syrians had cast away in their haste. The messengers returned, and told the king.",
                "The people went out, and plundered the camp of the Syrians. So a measure of fine flour was sold for a shekel, and two measures of barley for a shekel, according to the word of Yahweh.",
                "The king appointed the captain on whose hand he leaned to be in charge of the gate: and the people trod on him in the gate, and he died as the man of God had said, who spoke when the king came down to him.",
                "It happened, as the man of God had spoken to the king, saying, \"Two measures of barley for a shekel, and a measure of fine flour for a shekel, shall be tomorrow about this time in the gate of Samaria;\"",
                "and that captain answered the man of God, and said, \"Now, behold, if Yahweh should make windows in heaven, might such a thing be?\" and he said, \"Behold, you shall see it with your eyes, but shall not eat of it.\"",
                "It happened like that to him; for the people trod on him in the gate, and he died."
            ],
            8: [
                "Now Elisha had spoken to the woman, whose son he had restored to life, saying, \"Arise, and go, you and your household, and stay for a while wherever you can; for Yahweh has called for a famine. It shall also come on the land seven years.\"",
                "The woman arose, and did according to the word of the man of God. She went with her household, and lived in the land of the Philistines seven years.",
                "It happened at the seven years\u2019 end, that the woman returned out of the land of the Philistines. Then she went forth to cry to the king for her house and for her land.",
                "Now the king was talking with Gehazi the servant of the man of God, saying, \"Please tell me all the great things that Elisha has done.\"",
                "It happened, as he was telling the king how he had restored to life him who was dead, that behold, the woman, whose son he had restored to life, cried to the king for her house and for her land. Gehazi said, \"My lord, O king, this is the woman, and this is her son, whom Elisha restored to life.\"",
                "When the king asked the woman, she told him. So the king appointed to her a certain officer, saying, \"Restore all that was hers, and all the fruits of the field since the day that she left the land, even until now.\"",
                "Elisha came to Damascus; and Benhadad the king of Syria was sick. It was told him, saying, \"The man of God has come here.\"",
                "The king said to Hazael, \"Take a present in your hand, and go, meet the man of God, and inquire of Yahweh by him, saying, \u2018Will I recover from this sickness?\u2019\"",
                "So Hazael went to meet him, and took a present with him, even of every good thing of Damascus, forty camels\u2019 burden, and came and stood before him, and said, \"Your son Benhadad king of Syria has sent me to you, saying, \u2018Will I recover from this sickness?\u2019\"",
                "Elisha said to him, \"Go, tell him, \u2018You shall surely recover;\u2019 however Yahweh has shown me that he shall surely die.\"",
                "He settled his gaze steadfastly on him, until he was ashamed. Then the man of God wept.",
                "Hazael said, \"Why do you weep, my lord?\" He answered, \"Because I know the evil that you will do to the children of Israel. You will set their strongholds on fire, and you will kill their young men with the sword, and will dash in pieces their little ones, and rip up their women with child.\"",
                "Hazael said, \"But what is your servant, who is but a dog, that he should do this great thing?\" Elisha answered, \"Yahweh has shown me that you will be king over Syria.\"",
                "Then he departed from Elisha, and came to his master, who said to him, \"What did Elisha say to you?\" He answered, \"He told me that you would surely recover.\"",
                "It happened on the next day, that he took a thick cloth, dipped it in water, and spread it on his face, so that he died. Then Hazael reigned in his place.",
                "In the fifth year of Joram the son of Ahab king of Israel, Jehoshaphat being king of Judah then, Jehoram the son of Jehoshaphat king of Judah began to reign.",
                "He was thirty-two years old when he began to reign. He reigned eight years in Jerusalem.",
                "He walked in the way of the kings of Israel, as did the house of Ahab; for he had the daughter of Ahab as wife. He did that which was evil in the sight of Yahweh.",
                "However Yahweh would not destroy Judah, for David his servant\u2019s sake, as he promised him to give to him a lamp for his children always.",
                "In his days Edom revolted from under the hand of Judah, and made a king over themselves.",
                "Then Joram passed over to Zair, and all his chariots with him: and he rose up by night, and struck the Edomites who surrounded him, and the captains of the chariots; and the people fled to their tents.",
                "So Edom revolted from under the hand of Judah to this day. Then Libnah revolted at the same time.",
                "The rest of the acts of Joram, and all that he did, aren\u2019t they written in the book of the chronicles of the kings of Judah?",
                "Joram slept with his fathers, and was buried with his fathers in the city of David; and Ahaziah his son reigned in his place.",
                "In the twelfth year of Joram the son of Ahab king of Israel, Ahaziah the son of Jehoram king of Judah began to reign.",
                "Twenty-two years old was Ahaziah when he began to reign; and he reigned one year in Jerusalem. His mother\u2019s name was Athaliah the daughter of Omri king of Israel.",
                "He walked in the way of the house of Ahab, and did that which was evil in the sight of Yahweh, as did the house of Ahab; for he was the son-in-law of the house of Ahab.",
                "He went with Joram the son of Ahab to war against Hazael king of Syria at Ramoth Gilead: and the Syrians wounded Joram.",
                "King Joram returned to be healed in Jezreel of the wounds which the Syrians had given him at Ramah, when he fought against Hazael king of Syria. Ahaziah the son of Jehoram king of Judah went down to see Joram the son of Ahab in Jezreel, because he was sick."
            ],
            9: [
                "Elisha the prophet called one of the sons of the prophets, and said to him, \"Put your belt on your waist, take this vial of oil in your hand, and go to Ramoth Gilead.",
                "When you come there, find Jehu the son of Jehoshaphat the son of Nimshi, and go in, and make him arise up from among his brothers, and carry him to an inner room.",
                "Then take the vial of oil, and pour it on his head, and say, \u2018Thus says Yahweh, \"I have anointed you king over Israel.\"\u2019 Then open the door, flee, and don\u2019t wait.\"",
                "So the young man, even the young man the prophet, went to Ramoth Gilead.",
                "When he came, behold, the captains of the army were sitting. Then he said, \"I have a message for you, captain.\" Jehu said, \"To which of us all?\" He said, \"To you, O captain.\"",
                "He arose, and went into the house. Then he poured the oil on his head, and said to him, \"Thus says Yahweh, the God of Israel, \u2018I have anointed you king over the people of Yahweh, even over Israel.",
                "You shall strike the house of Ahab your master, that I may avenge the blood of my servants the prophets, and the blood of all the servants of Yahweh, at the hand of Jezebel.",
                "For the whole house of Ahab shall perish. I will cut off from Ahab everyone who urinates against a wall, both him who is shut up and him who is left at large in Israel.",
                "I will make the house of Ahab like the house of Jeroboam the son of Nebat, and like the house of Baasha the son of Ahijah.",
                "The dogs will eat Jezebel on the plot of ground of Jezreel, and there shall be none to bury her.\u2019\" He opened the door, and fled.",
                "Then Jehu came forth to the servants of his lord: and one said to him, \"Is all well? Why did this mad fellow come to you?\" He said to them, \"You know the man and what his talk was.\"",
                "They said, \"That is a lie. Tell us now.\" He said, \"He said to me, \u2018Thus says Yahweh, I have anointed you king over Israel.\u2019\"",
                "Then they hurried, and took every man his garment, and put it under him on the top of the stairs, and blew the trumpet, saying, \"Jehu is king.\"",
                "So Jehu the son of Jehoshaphat the son of Nimshi conspired against Joram. (Now Joram was keeping Ramoth Gilead, he and all Israel, because of Hazael king of Syria;",
                "but king Joram was returned to be healed in Jezreel of the wounds which the Syrians had given him, when he fought with Hazael king of Syria.) Jehu said, \"If this is your thinking, then let no one escape and go out of the city, to go to tell it in Jezreel.\"",
                "So Jehu rode in a chariot, and went to Jezreel; for Joram lay there. Ahaziah king of Judah had come down to see Joram.",
                "Now the watchman was standing on the tower in Jezreel, and he spied the company of Jehu as he came, and said, \"I see a company.\" Joram said, \"Take a horseman, and send to meet them, and let him say, \u2018Is it peace?\u2019\"",
                "So there went one on horseback to meet him, and said, \"Thus says the king, \u2018Is it peace?\u2019\" Jehu said, \"What do you have to do with peace? Fall in behind me!\" The watchman said, \"The messenger came to them, but he isn\u2019t coming back.\"",
                "Then he sent out a second on horseback, who came to them, and said, \"Thus says the king, \u2018Is it peace?\u2019\" Jehu answered, \"What do you have to do with peace? Fall in behind me!\"",
                "The watchman said, \"He came to them, and isn\u2019t coming back. The driving is like the driving of Jehu the son of Nimshi; for he drives furiously.\"",
                "Joram said, \"Get ready!\" They got his chariot ready. Joram king of Israel and Ahaziah king of Judah went out, each in his chariot, and they went out to meet Jehu, and found him in the portion of Naboth the Jezreelite.",
                "It happened, when Joram saw Jehu, that he said, \"Is it peace, Jehu?\" He answered, \"What peace, so long as the prostitution of your mother Jezebel and her witchcraft abound?\"",
                "Joram turned his hands, and fled, and said to Ahaziah, \"There is treason, Ahaziah!\"",
                "Jehu drew his bow with his full strength, and struck Joram between his arms; and the arrow went out at his heart, and he sunk down in his chariot.",
                "Then Jehu said to Bidkar his captain, \"Pick him up, and throw him in the plot of the field of Naboth the Jezreelite; for remember how, when you and I rode together after Ahab his father, Yahweh laid this burden on him:",
                "\u2018Surely I have seen yesterday the blood of Naboth, and the blood of his sons,\u2019 says Yahweh; \u2018and I will repay you in this plot of ground,\u2019 says Yahweh. Now therefore take and cast him onto the plot of ground, according to the word of Yahweh.\"",
                "But when Ahaziah the king of Judah saw this, he fled by the way of the garden house. Jehu followed after him, and said, \"Strike him also in the chariot!\" They struck him at the ascent of Gur, which is by Ibleam. He fled to Megiddo, and died there.",
                "His servants carried him in a chariot to Jerusalem, and buried him in his tomb with his fathers in the city of David.",
                "In the eleventh year of Joram the son of Ahab began Ahaziah to reign over Judah.",
                "When Jehu had come to Jezreel, Jezebel heard of it; and she painted her eyes, and attired her head, and looked out at the window.",
                "As Jehu entered in at the gate, she said, \"Do you come in peace, Zimri, you murderer of your master?\"",
                "He lifted up his face to the window, and said, \"Who is on my side? Who?\" Two or three eunuchs looked out at him.",
                "He said, \"Throw her down!\" So they threw her down; and some of her blood was sprinkled on the wall, and on the horses. Then he trampled her under foot.",
                "When he had come in, he ate and drink; and he said, \"See now to this cursed woman, and bury her; for she is a king\u2019s daughter.\"",
                "They went to bury her; but they found no more of her than the skull, and the feet, and the palms of her hands.",
                "Therefore they came back, and told him. He said, \"This is the word of Yahweh, which he spoke by his servant Elijah the Tishbite, saying, \u2018The dogs will eat the flesh of Jezebel on the plot of Jezreel,",
                "and the body of Jezebel shall be as dung on the face of the field in the portion of Jezreel, so that they shall not say, \"This is Jezebel.\"\u2019\""
            ],
            10: [
                "Now Ahab had seventy sons in Samaria. Jehu wrote letters, and sent to Samaria, to the rulers of Jezreel, even the elders, and to those who brought up the sons of Ahab, saying,",
                "\"Now as soon as this letter comes to you, since your master\u2019s sons are with you, and there are with you chariots and horses, a fortified city also, and armor.",
                "Select the best and fittest of your master\u2019s sons, set him on his father\u2019s throne, and fight for your master\u2019s house.\"",
                "But they were exceedingly afraid, and said, \"Behold, the two kings didn\u2019t stand before him! How then shall we stand?\"",
                "He who was over the household, and he who was over the city, the elders also, and those who raised the children, sent to Jehu, saying, \"We are your servants, and will do all that you ask us. We will not make any man king. You do that which is good in your eyes.\"",
                "Then he wrote a letter the second time to them, saying, \"If you are on my side, and if you will listen to my voice, take the heads of the men your master\u2019s sons, and come to me to Jezreel by tomorrow this time.\" Now the king\u2019s sons, being seventy persons, were with the great men of the city, who brought them up.",
                "It happened, when the letter came to them, that they took the king\u2019s sons, and killed them, even seventy persons, and put their heads in baskets, and sent them to him to Jezreel.",
                "A messenger came, and told him, \"They have brought the heads of the king\u2019s sons.\" He said, \"Lay them in two heaps at the entrance of the gate until the morning.\"",
                "It happened in the morning, that he went out, and stood, and said to all the people, \"You are righteous. Behold, I conspired against my master, and killed him; but who struck all these?",
                "Know now that nothing shall fall to the earth of the word of Yahweh, which Yahweh spoke concerning the house of Ahab. For Yahweh has done that which he spoke by his servant Elijah.\"",
                "So Jehu struck all that remained of the house of Ahab in Jezreel, with all his great men, his familiar friends, and his priests, until he left him none remaining.",
                "He arose and departed, and went to Samaria. As he was at the shearing house of the shepherds on the way,",
                "Jehu met with the brothers of Ahaziah king of Judah, and said, \"Who are you?\" They answered, \"We are the brothers of Ahaziah. We are going down to greet the children of the king and the children of the queen.\"",
                "He said, \"Take them alive!\" They took them alive, and killed them at the pit of the shearing house, even forty-two men. He didn\u2019t leave any of them.",
                "When he had departed from there, he met Jehonadab the son of Rechab coming to meet him. He greeted him, and said to him, \"Is your heart right, as my heart is with your heart?\" Jehonadab answered, \"It is.\" \"If it is, give me your hand.\" He gave him his hand; and he took him up to him into the chariot.",
                "He said, \"Come with me, and see my zeal for Yahweh.\" So they made him ride in his chariot.",
                "When he came to Samaria, he struck all who remained to Ahab in Samaria, until he had destroyed him, according to the word of Yahweh, which he spoke to Elijah.",
                "Jehu gathered all the people together, and said to them, \"Ahab served Baal a little; but Jehu will serve him much.",
                "Now therefore call to me all the prophets of Baal, all of his worshippers, and all of his priests. Let none be absent; for I have a great sacrifice to Baal. Whoever is absent, he shall not live.\" But Jehu did it in subtlety, intending that he might destroy the worshippers of Baal.",
                "Jehu said, \"Sanctify a solemn assembly for Baal!\" They proclaimed it.",
                "Jehu sent through all Israel; and all the worshippers of Baal came, so that there was not a man left that didn\u2019t come. They came into the house of Baal; and the house of Baal was filled from one end to another.",
                "He said to him who was over the vestry, \"Bring out robes for all the worshippers of Baal!\" He brought robes out to them.",
                "Jehu went with Jehonadab the son of Rechab into the house of Baal. Then he said to the worshippers of Baal, \"Search, and look that there are here with you none of the servants of Yahweh, but the worshippers of Baal only.\"",
                "They went in to offer sacrifices and burnt offerings. Now Jehu had appointed him eighty men outside, and said, \"If any of the men whom I bring into your hands escape, he who lets him go, his life shall be for the life of him.\"",
                "It happened, as soon as he had made an end of offering the burnt offering, that Jehu said to the guard and to the captains, \"Go in, and kill them! Let none escape.\" They struck them with the edge of the sword; and the guard and the captains cast them out, and went to the city of the house of Baal.",
                "They brought out the pillars that were in the house of Baal, and burned them.",
                "They broke down the pillar of Baal, and broke down the house of Baal, and made it a latrine, to this day.",
                "Thus Jehu destroyed Baal out of Israel.",
                "However from the sins of Jeroboam the son of Nebat, with which he made Israel to sin, Jehu didn\u2019t depart from after them, the golden calves that were in Bethel, and that were in Dan.",
                "Yahweh said to Jehu, \"Because you have done well in executing that which is right in my eyes, and have done to the house of Ahab according to all that was in my heart, your sons of the fourth generation shall sit on the throne of Israel.\"",
                "But Jehu took no heed to walk in the law of Yahweh, the God of Israel, with all his heart. He didn\u2019t depart from the sins of Jeroboam, with which he made Israel to sin.",
                "In those days Yahweh began to cut off from Israel; and Hazael struck them in all the borders of Israel;",
                "from the Jordan eastward, all the land of Gilead, the Gadites, and the Reubenites, and the Manassites, from Aroer, which is by the valley of the Arnon, even Gilead and Bashan.",
                "Now the rest of the acts of Jehu, and all that he did, and all his might, aren\u2019t they written in the book of the chronicles of the kings of Israel?",
                "Jehu slept with his fathers; and they buried him in Samaria. Jehoahaz his son reigned in his place.",
                "The time that Jehu reigned over Israel in Samaria was twenty-eight years."
            ],
            11: [
                "Now when Athaliah the mother of Ahaziah saw that her son was dead, she arose and destroyed all the seed royal.",
                "But Jehosheba, the daughter of king Joram, sister of Ahaziah, took Joash the son of Ahaziah, and stole him away from among the king\u2019s sons who were slain, even him and his nurse, and put them in the bedroom; and they hid him from Athaliah, so that he was not slain;",
                "He was with her hidden in the house of Yahweh six years. Athaliah reigned over the land.",
                "In the seventh year Jehoiada sent and fetched the captains over hundreds of the Carites and of the guard, and brought them to him into the house of Yahweh; and he made a covenant with them, and took an oath of them in the house of Yahweh, and showed them the king\u2019s son.",
                "He commanded them, saying, \"This is the thing that you shall do: a third part of you, who come in on the Sabbath, shall be keepers of the watch of the king\u2019s house;",
                "A third part shall be at the gate Sur; and a third part at the gate behind the guard. So you shall keep the watch of the house, and be a barrier.",
                "The two companies of you, even all who go out on the Sabbath, shall keep the watch of the house of Yahweh around the king.",
                "You shall surround the king, every man with his weapons in his hand; and he who comes within the ranks, let him be slain. Be with the king when he goes out, and when he comes in.\"",
                "The captains over hundreds did according to all that Jehoiada the priest commanded; and they took every man his men, those who were to come in on the Sabbath, with those who were to go out on the Sabbath, and came to Jehoiada the priest.",
                "The priest delivered to the captains over hundreds the spears and shields that had been king David\u2019s, which were in the house of Yahweh.",
                "The guard stood, every man with his weapons in his hand, from the right side of the house to the left side of the house, along by the altar and the house, around the king.",
                "Then he brought out the king\u2019s son, and put the crown on him, and gave him the testimony; and they made him king, and anointed him; and they clapped their hands, and said, \"Long live the king!\"",
                "When Athaliah heard the noise of the guard and of the people, she came to the people into the house of Yahweh:",
                "and she looked, and behold, the king stood by the pillar, as the tradition was, and the captains and the trumpets by the king; and all the people of the land rejoiced, and blew trumpets. Then Athaliah tore her clothes, and cried, \"Treason! Treason!\"",
                "Jehoiada the priest commanded the captains of hundreds who were set over the army, and said to them, \"Bring her out between the ranks. Kill him who follows her with the sword.\" For the priest said, \"Don\u2019t let her be slain in the house of Yahweh.\"",
                "So they made way for her; and she went by the way of the horses\u2019 entry to the king\u2019s house. She was slain there.",
                "Jehoiada made a covenant between Yahweh and the king and the people, that they should be Yahweh\u2019s people; between the king also and the people.",
                "All the people of the land went to the house of Baal, and broke it down; his altars and his images broke they in pieces thoroughly, and killed Mattan the priest of Baal before the altars. The priest appointed officers over the house of Yahweh.",
                "He took the captains over hundreds, and the Carites, and the guard, and all the people of the land; and they brought down the king from the house of Yahweh, and came by the way of the gate of the guard to the king\u2019s house. He sat on the throne of the kings.",
                "So all the people of the land rejoiced, and the city was quiet. Athaliah they had slain with the sword at the king\u2019s house.",
                "Jehoash was seven years old when he began to reign."
            ],
            12: [
                "In the seventh year of Jehu began Jehoash to reign; and he reigned forty years in Jerusalem: and his mother\u2019s name was Zibiah of Beersheba.",
                "Jehoash did that which was right in the eyes of Yahweh all his days in which Jehoiada the priest instructed him.",
                "However the high places were not taken away; the people still sacrificed and burnt incense in the high places.",
                "Jehoash said to the priests, \"All the money of the holy things that is brought into the house of Yahweh, in current money, the money of the persons for whom each man is rated, and all the money that it comes into any man\u2019s heart to bring into the house of Yahweh,",
                "let the priests take it to them, every man from his acquaintance; and they shall repair the breaches of the house, wherever any breach shall be found.\"",
                "But it was so, that in the three and twentieth year of king Jehoash the priests had not repaired the breaches of the house.",
                "Then king Jehoash called for Jehoiada the priest, and for the other priests, and said to them, \"Why don\u2019t you repair the breaches of the house? Now therefore take no more money from your treasurers, but deliver it for the breaches of the house.\"",
                "The priests consented that they should take no more money from the people, neither repair the breaches of the house.",
                "But Jehoiada the priest took a chest, and bored a hole in its lid, and set it beside the altar, on the right side as one comes into the house of Yahweh: and the priests who kept the threshold put therein all the money that was brought into the house of Yahweh.",
                "It was so, when they saw that there was much money in the chest, that the king\u2019s scribe and the high priest came up, and they put up in bags and counted the money that was found in the house of Yahweh.",
                "They gave the money that was weighed out into the hands of those who did the work, who had the oversight of the house of Yahweh: and they paid it out to the carpenters and the builders, who worked on the house of Yahweh,",
                "and to the masons and the stone cutters, and for buying timber and cut stone to repair the breaches of the house of Yahweh, and for all that was laid out for the house to repair it.",
                "But there were not made for the house of Yahweh cups of silver, snuffers, basins, trumpets, any vessels of gold, or vessels of silver, of the money that was brought into the house of Yahweh;",
                "for they gave that to those who did the work, and repaired therewith the house of Yahweh.",
                "Moreover they didn\u2019t demand an accounting from the men into whose hand they delivered the money to give to those who did the work; for they dealt faithfully.",
                "The money for the trespass offerings, and the money for the sin offerings, was not brought into the house of Yahweh: it was the priests\u2019.",
                "Then Hazael king of Syria went up, and fought against Gath, and took it; and Hazael set his face to go up to Jerusalem.",
                "Jehoash king of Judah took all the holy things that Jehoshaphat and Jehoram and Ahaziah, his fathers, kings of Judah, had dedicated, and his own holy things, and all the gold that was found in the treasures of the house of Yahweh, and of the king\u2019s house, and sent it to Hazael king of Syria: and he went away from Jerusalem.",
                "Now the rest of the acts of Joash, and all that he did, aren\u2019t they written in the book of the chronicles of the kings of Judah?",
                "His servants arose, and made a conspiracy, and struck Joash at the house of Millo, on the way that goes down to Silla.",
                "For Jozacar the son of Shimeath, and Jehozabad the son of Shomer, his servants, struck him, and he died; and they buried him with his fathers in the city of David: and Amaziah his son reigned in his place."
            ],
            13: [
                "In the three and twentieth year of Joash the son of Ahaziah, king of Judah, Jehoahaz the son of Jehu began to reign over Israel in Samaria for seventeen years.",
                "He did that which was evil in the sight of Yahweh, and followed the sins of Jeroboam the son of Nebat, with which he made Israel to sin; he didn\u2019t depart from it.",
                "The anger of Yahweh was kindled against Israel, and he delivered them into the hand of Hazael king of Syria, and into the hand of Benhadad the son of Hazael, continually.",
                "Jehoahaz begged Yahweh, and Yahweh listened to him; for he saw the oppression of Israel, how that the king of Syria oppressed them.",
                "(Yahweh gave Israel a savior, so that they went out from under the hand of the Syrians; and the children of Israel lived in their tents as before.",
                "Nevertheless they didn\u2019t depart from the sins of the house of Jeroboam, with which he made Israel to sin, but walked therein: and there remained the Asherah also in Samaria.)",
                "For he didn\u2019t leave to Jehoahaz of the people any more than fifty horsemen, and ten chariots, and ten thousand footmen; for the king of Syria destroyed them, and made them like the dust in threshing.",
                "Now the rest of the acts of Jehoahaz, and all that he did, and his might, aren\u2019t they written in the book of the chronicles of the kings of Israel?",
                "Jehoahaz slept with his fathers; and they buried him in Samaria: and Joash his son reigned in his place.",
                "In the thirty-seventh year of Joash king of Judah, Jehoash the son of Jehoahaz began to reign over Israel in Samaria for sixteen years.",
                "He did that which was evil in the sight of Yahweh; he didn\u2019t depart from all the sins of Jeroboam the son of Nebat, with which he made Israel to sin; but he walked therein.",
                "Now the rest of the acts of Joash, and all that he did, and his might with which he fought against Amaziah king of Judah, aren\u2019t they written in the book of the chronicles of the kings of Israel?",
                "Joash slept with his fathers; and Jeroboam sat on his throne: and Joash was buried in Samaria with the kings of Israel.",
                "Now Elisha was fallen sick of his sickness of which he died: and Joash the king of Israel came down to him, and wept over him, and said, \"My father, my father, the chariots of Israel and its horsemen!\"",
                "Elisha said to him, \"Take bow and arrows;\" and he took to him bow and arrows.",
                "He said to the king of Israel, \"Put your hand on the bow;\" and he put his hand on it. Elisha laid his hands on the king\u2019s hands.",
                "He said, \"Open the window eastward;\" and he opened it. Then Elisha said, \"Shoot!\" and he shot. He said, \"Yahweh\u2019s arrow of victory, even the arrow of victory over Syria; for you shall strike the Syrians in Aphek, until you have consumed them.\"",
                "He said, \"Take the arrows;\" and he took them. He said to the king of Israel, \"Strike the ground;\" and he struck three times, and stopped.",
                "The man of God was angry with him, and said, \"You should have struck five or six times. Then you would have struck Syria until you had consumed it, whereas now you shall strike Syria just three times.\"",
                "Elisha died, and they buried him. Now the bands of the Moabites invaded the land at the coming in of the year.",
                "It happened, as they were burying a man, that behold, they spied a band; and they cast the man into the tomb of Elisha: and as soon as the man touched the bones of Elisha, he revived, and stood up on his feet.",
                "Hazael king of Syria oppressed Israel all the days of Jehoahaz.",
                "But Yahweh was gracious to them, and had compassion on them, and had respect to them, because of his covenant with Abraham, Isaac, and Jacob, and would not destroy them, neither cast he them from his presence as yet.",
                "Hazael king of Syria died; and Benhadad his son reigned in his place.",
                "Jehoash the son of Jehoahaz took again out of the hand of Benhadad the son of Hazael the cities which he had taken out of the hand of Jehoahaz his father by war. Joash struck him three times, and recovered the cities of Israel."
            ],
            14: [
                "In the second year of Joash son of Joahaz king of Israel began Amaziah the son of Joash king of Judah to reign.",
                "He was twenty-five years old when he began to reign; and he reigned twenty-nine years in Jerusalem: and his mother\u2019s name was Jehoaddin of Jerusalem.",
                "He did that which was right in the eyes of Yahweh, yet not like David his father: he did according to all that Joash his father had done.",
                "However the high places were not taken away: the people still sacrificed and burnt incense in the high places.",
                "It happened, as soon as the kingdom was established in his hand, that he killed his servants who had slain the king his father:",
                "but the children of the murderers he didn\u2019t put to death; according to that which is written in the book of the law of Moses, as Yahweh commanded, saying, \"The fathers shall not be put to death for the children, nor the children be put to death for the fathers; but every man shall die for his own sin.\"",
                "He killed of Edom in the Valley of Salt ten thousand, and took Sela by war, and called its name Joktheel, to this day.",
                "Then Amaziah sent messengers to Jehoash, the son of Jehoahaz son of Jehu, king of Israel, saying, \"Come, let us look one another in the face.\"",
                "Jehoash the king of Israel sent to Amaziah king of Judah, saying, \"The thistle that was in Lebanon sent to the cedar that was in Lebanon, saying, \u2018Give your daughter to my son as wife. Then a wild animal that was in Lebanon passed by, and trampled down the thistle.",
                "You have indeed struck Edom, and your heart has lifted you up. Enjoy the glory of it, and stay at home; for why should you meddle to your harm, that you should fall, even you, and Judah with you?\u2019\"",
                "But Amaziah would not listen. So Jehoash king of Israel went up; and he and Amaziah king of Judah looked one another in the face at Beth Shemesh, which belongs to Judah.",
                "Judah was defeated by Israel; and they fled every man to his tent.",
                "Jehoash king of Israel took Amaziah king of Judah, the son of Jehoash the son of Ahaziah, at Beth Shemesh, and came to Jerusalem, and broke down the wall of Jerusalem from the gate of Ephraim to the corner gate, four hundred cubits.",
                "He took all the gold and silver, and all the vessels that were found in the house of Yahweh, and in the treasures of the king\u2019s house, the hostages also, and returned to Samaria.",
                "Now the rest of the acts of Jehoash which he did, and his might, and how he fought with Amaziah king of Judah, aren\u2019t they written in the book of the chronicles of the kings of Israel?",
                "Jehoash slept with his fathers, and was buried in Samaria with the kings of Israel; and Jeroboam his son reigned in his place.",
                "Amaziah the son of Joash king of Judah lived after the death of Jehoash son of Jehoahaz king of Israel fifteen years.",
                "Now the rest of the acts of Amaziah, aren\u2019t they written in the book of the chronicles of the kings of Judah?",
                "They made a conspiracy against him in Jerusalem; and he fled to Lachish: but they sent after him to Lachish, and killed him there.",
                "They brought him on horses; and he was buried at Jerusalem with his fathers in the city of David.",
                "All the people of Judah took Azariah, who was sixteen years old, and made him king in the place of his father Amaziah.",
                "He built Elath, and restored it to Judah, after that the king slept with his fathers.",
                "In the fifteenth year of Amaziah the son of Joash king of Judah, Jeroboam the son of Joash king of Israel began to reign in Samaria for forty-one years.",
                "He did that which was evil in the sight of Yahweh: he didn\u2019t depart from all the sins of Jeroboam the son of Nebat, with which he made Israel to sin.",
                "He restored the border of Israel from the entrance of Hamath to the sea of the Arabah, according to the word of Yahweh, the God of Israel, which he spoke by his servant Jonah the son of Amittai, the prophet, who was of Gath Hepher.",
                "For Yahweh saw the affliction of Israel, that it was very bitter; for there was none shut up nor left at large, neither was there any helper for Israel.",
                "Yahweh didn\u2019t say that he would blot out the name of Israel from under the sky; but he saved them by the hand of Jeroboam the son of Joash.",
                "Now the rest of the acts of Jeroboam, and all that he did, and his might, how he warred, and how he recovered Damascus, and Hamath, which had belonged to Judah, for Israel, aren\u2019t they written in the book of the chronicles of the kings of Israel?",
                "Jeroboam slept with his fathers, even with the kings of Israel; and Zechariah his son reigned in his place."
            ],
            15: [
                "In the twenty-seventh year of Jeroboam king of Israel began Azariah son of Amaziah king of Judah to reign.",
                "Sixteen years old was he when he began to reign; and he reigned fifty-two years in Jerusalem: and his mother\u2019s name was Jecoliah of Jerusalem.",
                "He did that which was right in the eyes of Yahweh, according to all that his father Amaziah had done.",
                "However the high places were not taken away: the people still sacrificed and burnt incense in the high places.",
                "Yahweh struck the king, so that he was a leper to the day of his death, and lived in a separate house. Jotham the king\u2019s son was over the household, judging the people of the land.",
                "Now the rest of the acts of Azariah, and all that he did, aren\u2019t they written in the book of the chronicles of the kings of Judah?",
                "Azariah slept with his fathers; and they buried him with his fathers in the city of David: and Jotham his son reigned in his place.",
                "In the thirty-eighth year of Azariah king of Judah, Zechariah the son of Jeroboam reigned over Israel in Samaria six months.",
                "He did that which was evil in the sight of Yahweh, as his fathers had done: he didn\u2019t depart from the sins of Jeroboam the son of Nebat, with which he made Israel to sin.",
                "Shallum the son of Jabesh conspired against him, and struck him before the people, and killed him, and reigned in his place.",
                "Now the rest of the acts of Zechariah, behold, they are written in the book of the chronicles of the kings of Israel.",
                "This was the word of Yahweh which he spoke to Jehu, saying, \"Your sons to the fourth generation shall sit on the throne of Israel.\" So it came to pass.",
                "Shallum the son of Jabesh began to reign in the thirty-ninth year of Uzziah king of Judah; and he reigned for a month in Samaria.",
                "Menahem the son of Gadi went up from Tirzah, and came to Samaria, and struck Shallum the son of Jabesh in Samaria, and killed him, and reigned in his place.",
                "Now the rest of the acts of Shallum, and his conspiracy which he made, behold, they are written in the book of the chronicles of the kings of Israel.",
                "Then Menahem struck Tiphsah, and all who were therein, and its borders, from Tirzah: because they didn\u2019t open to him, therefore he struck it; and all the women therein who were with child he ripped up.",
                "In the nine and thirtieth year of Azariah king of Judah, Menahem the son of Gadi began to reign over Israel for ten years in Samaria.",
                "He did that which was evil in the sight of Yahweh: he didn\u2019t depart all his days from the sins of Jeroboam the son of Nebat, with which he made Israel to sin.",
                "There came against the land Pul the king of Assyria; and Menahem gave Pul one thousand talents of silver, that his hand might be with him to confirm the kingdom in his hand.",
                "Menahem exacted the money of Israel, even of all the mighty men of wealth, of each man fifty shekels of silver, to give to the king of Assyria. So the king of Assyria turned back, and didn\u2019t stay there in the land.",
                "Now the rest of the acts of Menahem, and all that he did, aren\u2019t they written in the book of the chronicles of the kings of Israel?",
                "Menahem slept with his fathers; and Pekahiah his son reigned in his place.",
                "In the fiftieth year of Azariah king of Judah Pekahiah the son of Menahem began to reign over Israel in Samaria for two years.",
                "He did that which was evil in the sight of Yahweh: he didn\u2019t depart from the sins of Jeroboam the son of Nebat, with which he made Israel to sin.",
                "Pekah the son of Remaliah, his captain, conspired against him, and struck him in Samaria, in the castle of the king\u2019s house, with Argob and Arieh; and with him were fifty men of the Gileadites: and he killed him, and reigned in his place.",
                "Now the rest of the acts of Pekahiah, and all that he did, behold, they are written in the book of the chronicles of the kings of Israel.",
                "In the two and fiftieth year of Azariah king of Judah Pekah the son of Remaliah began to reign over Israel in Samaria for twenty years.",
                "He did that which was evil in the sight of Yahweh: he didn\u2019t depart from the sins of Jeroboam the son of Nebat, with which he made Israel to sin.",
                "In the days of Pekah king of Israel came Tiglath Pileser king of Assyria, and took Ijon, and Abel Beth Maacah, and Janoah, and Kedesh, and Hazor, and Gilead, and Galilee, all the land of Naphtali; and he carried them captive to Assyria.",
                "Hoshea the son of Elah made a conspiracy against Pekah the son of Remaliah, and struck him, and killed him, and reigned in his place, in the twentieth year of Jotham the son of Uzziah.",
                "Now the rest of the acts of Pekah, and all that he did, behold, they are written in the book of the chronicles of the kings of Israel.",
                "In the second year of Pekah the son of Remaliah king of Israel began Jotham the son of Uzziah king of Judah to reign.",
                "He was twenty-five years old when he began to reign; and he reigned sixteen years in Jerusalem: and his mother\u2019s name was Jerusha the daughter of Zadok.",
                "He did that which was right in the eyes of Yahweh; he did according to all that his father Uzziah had done.",
                "However the high places were not taken away: the people still sacrificed and burned incense in the high places. He built the upper gate of the house of Yahweh.",
                "Now the rest of the acts of Jotham, and all that he did, aren\u2019t they written in the book of the chronicles of the kings of Judah?",
                "In those days Yahweh began to send against Judah Rezin the king of Syria, and Pekah the son of Remaliah.",
                "Jotham slept with his fathers, and was buried with his fathers in the city of David his father: and Ahaz his son reigned in his place."
            ],
            16: [
                "In the seventeenth year of Pekah the son of Remaliah Ahaz the son of Jotham king of Judah began to reign.",
                "Twenty years old was Ahaz when he began to reign; and he reigned sixteen years in Jerusalem: and he didn\u2019t do that which was right in the eyes of Yahweh his God, like David his father.",
                "But he walked in the way of the kings of Israel, yes, and made his son to pass through the fire, according to the abominations of the nations, whom Yahweh cast out from before the children of Israel.",
                "He sacrificed and burnt incense in the high places, and on the hills, and under every green tree.",
                "Then Rezin king of Syria and Pekah son of Remaliah king of Israel came up to Jerusalem to war: and they besieged Ahaz, but could not overcome him.",
                "At that time Rezin king of Syria recovered Elath to Syria, and drove the Jews from Elath; and the Syrians came to Elath, and lived there, to this day.",
                "So Ahaz sent messengers to Tiglath Pileser king of Assyria, saying, \"I am your servant and your son. Come up, and save me out of the hand of the king of Syria, and out of the hand of the king of Israel, who rise up against me.\"",
                "Ahaz took the silver and gold that was found in the house of Yahweh, and in the treasures of the king\u2019s house, and sent it for a present to the king of Assyria.",
                "The king of Assyria listened to him; and the king of Assyria went up against Damascus, and took it, and carried its people captive to Kir, and killed Rezin.",
                "King Ahaz went to Damascus to meet Tiglath Pileser king of Assyria, and saw the altar that was at Damascus; and king Ahaz sent to Urijah the priest the fashion of the altar, and its pattern, according to all its workmanship.",
                "Urijah the priest built an altar: according to all that king Ahaz had sent from Damascus, so Urijah the priest made it for the coming of king Ahaz from Damascus.",
                "When the king had come from Damascus, the king saw the altar: and the king drew near to the altar, and offered on it.",
                "He burnt his burnt offering and his meal offering, and poured his drink offering, and sprinkled the blood of his peace offerings, on the altar.",
                "The bronze altar, which was before Yahweh, he brought from the forefront of the house, from between his altar and the house of Yahweh, and put it on the north side of his altar.",
                "King Ahaz commanded Urijah the priest, saying, \"On the great altar burn the morning burnt offering, and the evening meal offering, and the king\u2019s burnt offering, and his meal offering, with the burnt offering of all the people of the land, and their meal offering, and their drink offerings; and sprinkle on it all the blood of the burnt offering, and all the blood of the sacrifice; but the bronze altar shall be for me to inquire by.\"",
                "Urijah the priest did so, according to all that king Ahaz commanded.",
                "King Ahaz cut off the panels of the bases, and removed the basin from off them, and took down the sea from off the bronze oxen that were under it, and put it on a pavement of stone.",
                "The covered way for the Sabbath that they had built in the house, and the king\u2019s entry outside, turned he to the house of Yahweh, because of the king of Assyria.",
                "Now the rest of the acts of Ahaz which he did, aren\u2019t they written in the book of the chronicles of the kings of Judah?",
                "Ahaz slept with his fathers, and was buried with his fathers in the city of David: and Hezekiah his son reigned in his place."
            ],
            17: [
                "In the twelfth year of Ahaz king of Judah, Hoshea the son of Elah began to reign in Samaria over Israel for nine years.",
                "He did that which was evil in the sight of Yahweh, yet not as the kings of Israel who were before him.",
                "Against him came up Shalmaneser king of Assyria; and Hoshea became his servant, and brought him tribute.",
                "The king of Assyria found conspiracy in Hoshea; for he had sent messengers to So king of Egypt, and offered no tribute to the king of Assyria, as he had done year by year: therefore the king of Assyria shut him up, and bound him in prison.",
                "Then the king of Assyria came up throughout all the land, and went up to Samaria, and besieged it three years.",
                "In the ninth year of Hoshea the king of Assyria took Samaria, and carried Israel away to Assyria, and placed them in Halah, and on the Habor, the river of Gozan, and in the cities of the Medes.",
                "It was so, because the children of Israel had sinned against Yahweh their God, who brought them up out of the land of Egypt from under the hand of Pharaoh king of Egypt, and had feared other gods,",
                "and walked in the statutes of the nations, whom Yahweh cast out from before the children of Israel, and of the kings of Israel, which they made.",
                "The children of Israel did secretly things that were not right against Yahweh their God: and they built them high places in all their cities, from the tower of the watchmen to the fortified city;",
                "and they set them up pillars and Asherim on every high hill, and under every green tree;",
                "and there they burnt incense in all the high places, as did the nations whom Yahweh carried away before them; and they worked wicked things to provoke Yahweh to anger;",
                "and they served idols, of which Yahweh had said to them, \"You shall not do this thing.\"",
                "Yet Yahweh testified to Israel, and to Judah, by every prophet, and every seer, saying, \"Turn from your evil ways, and keep my commandments and my statutes, according to all the law which I commanded your fathers, and which I sent to you by my servants the prophets.\"",
                "Notwithstanding, they would not listen, but hardened their neck, like the neck of their fathers, who didn\u2019t believe in Yahweh their God.",
                "They rejected his statutes, and his covenant that he made with their fathers, and his testimonies which he testified to them; and they followed vanity, and became vain, and followed the nations that were around them, concerning whom Yahweh had commanded them that they should not do like them.",
                "They forsook all the commandments of Yahweh their God, and made them molten images, even two calves, and made an Asherah, and worshiped all the army of the sky, and served Baal.",
                "They caused their sons and their daughters to pass through the fire, and used divination and enchantments, and sold themselves to do that which was evil in the sight of Yahweh, to provoke him to anger.",
                "Therefore Yahweh was very angry with Israel, and removed them out of his sight: there was none left but the tribe of Judah only.",
                "Also Judah didn\u2019t keep the commandments of Yahweh their God, but walked in the statutes of Israel which they made.",
                "Yahweh rejected all the seed of Israel, and afflicted them, and delivered them into the hand of spoilers, until he had cast them out of his sight.",
                "For he tore Israel from the house of David; and they made Jeroboam the son of Nebat king: and Jeroboam drove Israel from following Yahweh, and made them sin a great sin.",
                "The children of Israel walked in all the sins of Jeroboam which he did; they didn\u2019t depart from them;",
                "until Yahweh removed Israel out of his sight, as he spoke by all his servants the prophets. So Israel was carried away out of their own land to Assyria to this day.",
                "The king of Assyria brought men from Babylon, and from Cuthah, and from Avva, and from Hamath and Sepharvaim, and placed them in the cities of Samaria instead of the children of Israel; and they possessed Samaria, and lived in the cities of it.",
                "So it was, at the beginning of their dwelling there, that they didn\u2019t fear Yahweh: therefore Yahweh sent lions among them, which killed some of them.",
                "Therefore they spoke to the king of Assyria, saying, \"The nations which you have carried away, and placed in the cities of Samaria, don\u2019t know the law of the god of the land. Therefore he has sent lions among them, and behold, they kill them, because they don\u2019t know the law of the god of the land.\"",
                "Then the king of Assyria commanded, saying, \"Carry there one of the priests whom you brought from there; and let them go and dwell there, and let him teach them the law of the god of the land.\"",
                "So one of the priests whom they had carried away from Samaria came and lived in Bethel, and taught them how they should fear Yahweh.",
                "However every nation made gods of their own, and put them in the houses of the high places which the Samaritans had made, every nation in their cities in which they lived.",
                "The men of Babylon made Succoth Benoth, and the men of Cuth made Nergal, and the men of Hamath made Ashima,",
                "and the Avvites made Nibhaz and Tartak; and the Sepharvites burnt their children in the fire to Adrammelech and Anammelech, the gods of Sepharvaim.",
                "So they feared Yahweh, and made to them from among themselves priests of the high places, who sacrificed for them in the houses of the high places.",
                "They feared Yahweh, and served their own gods, after the ways of the nations from among whom they had been carried away.",
                "To this day they do what they did before: they don\u2019t fear Yahweh, neither do they follow their statutes, or their ordinances, or the law or the commandment which Yahweh commanded the children of Jacob, whom he named Israel;",
                "with whom Yahweh had made a covenant, and commanded them, saying, \"You shall not fear other gods, nor bow yourselves to them, nor serve them, nor sacrifice to them;",
                "but you shall fear Yahweh, who brought you up out of the land of Egypt with great power and with an outstretched arm, and you shall bow yourselves to him, and you shall sacrifice to him.",
                "The statutes and the ordinances, and the law and the commandment, which he wrote for you, you shall observe to do forevermore. You shall not fear other gods.",
                "You shall not forget the covenant that I have made with you; neither shall you fear other gods.",
                "But you shall fear Yahweh your God; and he will deliver you out of the hand of all your enemies.\"",
                "However they did not listen, but they did what they did before.",
                "So these nations feared Yahweh, and served their engraved images. Their children likewise, and their children\u2019s children, as their fathers did, so they do to this day."
            ],
            18: [
                "Now it happened in the third year of Hoshea son of Elah king of Israel, that Hezekiah the son of Ahaz king of Judah began to reign.",
                "He was twenty-five years old when he began to reign; and he reigned twenty-nine years in Jerusalem: and his mother\u2019s name was Abi the daughter of Zechariah.",
                "He did that which was right in the eyes of Yahweh, according to all that David his father had done.",
                "He removed the high places, and broke the pillars, and cut down the Asherah: and he broke in pieces the bronze serpent that Moses had made; for in those days the children of Israel burned incense to it; and he called it Nehushtan.",
                "He trusted in Yahweh, the God of Israel; so that after him was none like him among all the kings of Judah, nor among them that were before him.",
                "For he joined with Yahweh; he didn\u2019t depart from following him, but kept his commandments, which Yahweh commanded Moses.",
                "Yahweh was with him; wherever he went forth he prospered: and he rebelled against the king of Assyria, and didn\u2019t serve him.",
                "He struck the Philistines to Gaza and its borders, from the tower of the watchmen to the fortified city.",
                "It happened in the fourth year of king Hezekiah, which was the seventh year of Hoshea son of Elah king of Israel, that Shalmaneser king of Assyria came up against Samaria, and besieged it.",
                "At the end of three years they took it: in the sixth year of Hezekiah, which was the ninth year of Hoshea king of Israel, Samaria was taken.",
                "The king of Assyria carried Israel away to Assyria, and put them in Halah, and on the Habor, the river of Gozan, and in the cities of the Medes,",
                "because they didn\u2019t obey the voice of Yahweh their God, but transgressed his covenant, even all that Moses the servant of Yahweh commanded, and would not hear it, nor do it.",
                "Now in the fourteenth year of king Hezekiah, Sennacherib king of Assyria came up against all the fortified cities of Judah, and took them.",
                "Hezekiah king of Judah sent to the king of Assyria to Lachish, saying, \"I have offended; return from me. That which you put on me, I will bear.\" The king of Assyria appointed to Hezekiah king of Judah three hundred talents of silver and thirty talents of gold.",
                "Hezekiah gave him all the silver that was found in the house of Yahweh, and in the treasures of the king\u2019s house.",
                "At that time, Hezekiah cut off the gold from the doors of the temple of Yahweh, and from the pillars which Hezekiah king of Judah had overlaid, and gave it to the king of Assyria.",
                "The king of Assyria sent Tartan and Rabsaris and Rabshakeh from Lachish to king Hezekiah with a great army to Jerusalem. They went up and came to Jerusalem. When they had come up, they came and stood by the conduit of the upper pool, which is in the highway of the fuller\u2019s field.",
                "When they had called to the king, there came out to them Eliakim the son of Hilkiah, who was over the household, and Shebnah the scribe, and Joah the son of Asaph the recorder.",
                "Rabshakeh said to them, \"Say now to Hezekiah, \u2018Thus says the great king, the king of Assyria, \"What confidence is this in which you trust?",
                "You say (but they are but vain words), \u2018There is counsel and strength for war.\u2019 Now on whom do you trust, that you have rebelled against me?",
                "Now, behold, you trust in the staff of this bruised reed, even in Egypt. If a man leans on it, it will go into his hand, and pierce it. So is Pharaoh king of Egypt to all who trust on him.",
                "But if you tell me, \u2018We trust in Yahweh our God;\u2019 isn\u2019t that he whose high places and whose altars Hezekiah has taken away, and has said to Judah and to Jerusalem, \u2018You shall worship before this altar in Jerusalem?\u2019",
                "Now therefore, please give pledges to my master the king of Assyria, and I will give you two thousand horses, if you are able on your part to set riders on them.",
                "How then can you turn away the face of one captain of the least of my master\u2019s servants, and put your trust on Egypt for chariots and for horsemen?",
                "Have I now come up without Yahweh against this place to destroy it? Yahweh said to me, \u2018Go up against this land, and destroy it.\u2019\"\u2019\"",
                "Then Eliakim the son of Hilkiah, and Shebnah, and Joah, said to Rabshakeh, \"Please speak to your servants in the Syrian language; for we understand it. Don\u2019t speak with us in the Jews\u2019 language, in the hearing of the people who are on the wall.\"",
                "But Rabshakeh said to them, \"Has my master sent me to your master, and to you, to speak these words? Hasn\u2019t he sent me to the men who sit on the wall, to eat their own dung, and to drink their own water with you?\"",
                "Then Rabshakeh stood, and cried with a loud voice in the Jews\u2019 language, and spoke, saying, \"Hear the word of the great king, the king of Assyria.",
                "Thus says the king, \u2018Don\u2019t let Hezekiah deceive you; for he will not be able to deliver you out of his hand.",
                "Neither let Hezekiah make you trust in Yahweh, saying, \"Yahweh will surely deliver us, and this city shall not be given into the hand of the king of Assyria.\"",
                "Don\u2019t listen to Hezekiah.\u2019 For thus says the king of Assyria, \u2018Make your peace with me, and come out to me; and everyone of you eat of his vine, and everyone of his fig tree, and everyone drink the waters of his own cistern;",
                "until I come and take you away to a land like your own land, a land of grain and new wine, a land of bread and vineyards, a land of olive trees and of honey, that you may live, and not die. Don\u2019t listen to Hezekiah, when he persuades you, saying, \"Yahweh will deliver us.\"",
                "Has any of the gods of the nations ever delivered his land out of the hand of the king of Assyria?",
                "Where are the gods of Hamath, and of Arpad? Where are the gods of Sepharvaim, of Hena, and Ivvah? Have they delivered Samaria out of my hand?",
                "Who are they among all the gods of the countries, that have delivered their country out of my hand, that Yahweh should deliver Jerusalem out of my hand?\u2019\"",
                "But the people held their peace, and answered him not a word; for the king\u2019s commandment was, \"Don\u2019t answer him.\"",
                "Then Eliakim the son of Hilkiah, who was over the household, came with Shebna the scribe, and Joah the son of Asaph the recorder, to Hezekiah with their clothes torn, and told him the words of Rabshakeh."
            ],
            19: [
                "It happened, when king Hezekiah heard it, that he tore his clothes, and covered himself with sackcloth, and went into the house of Yahweh.",
                "He sent Eliakim, who was over the household, and Shebna the scribe, and the elders of the priests, covered with sackcloth, to Isaiah the prophet the son of Amoz.",
                "They said to him, \"Thus says Hezekiah, \u2018This day is a day of trouble, of rebuke, and of rejection; for the children have come to the point of birth, and there is no strength to deliver them.",
                "It may be Yahweh your God will hear all the words of Rabshakeh, whom the king of Assyria his master has sent to defy the living God, and will rebuke the words which Yahweh your God has heard. Therefore lift up your prayer for the remnant that is left.\u2019\"",
                "So the servants of king Hezekiah came to Isaiah.",
                "Isaiah said to them, \"Thus you shall tell your master, \u2018Thus says Yahweh, \"Don\u2019t be afraid of the words that you have heard, with which the servants of the king of Assyria have blasphemed me.",
                "Behold, I will put a spirit in him, and he will hear news, and will return to his own land. I will cause him to fall by the sword in his own land.\"\u2019\"",
                "So Rabshakeh returned, and found the king of Assyria warring against Libnah; for he had heard that he had departed from Lachish.",
                "When he heard it said of Tirhakah king of Ethiopia, \"Behold, he has come out to fight against you, he sent messengers again to Hezekiah, saying,",
                "\u2018Thus you shall speak to Hezekiah king of Judah, saying, \"Don\u2019t let your God in whom you trust deceive you, saying, Jerusalem will not be given into the hand of the king of Assyria.",
                "Behold, you have heard what the kings of Assyria have done to all lands, by destroying them utterly. Will you be delivered?",
                "Have the gods of the nations delivered them, which my fathers have destroyed, Gozan, and Haran, and Rezeph, and the children of Eden that were in Telassar?",
                "Where is the king of Hamath, and the king of Arpad, and the king of the city of Sepharvaim, of Hena, and Ivvah?\"\u2019\"",
                "Hezekiah received the letter from the hand of the messengers, and read it. Then Hezekiah went up to the house of Yahweh, and spread it before Yahweh.",
                "Hezekiah prayed before Yahweh, and said, \"Yahweh, the God of Israel, who sit above the cherubim, you are the God, even you alone, of all the kingdoms of the earth. You have made heaven and earth.",
                "Incline your ear, Yahweh, and hear. Open your eyes, Yahweh, and see. Hear the words of Sennacherib, with which he has sent to defy the living God.",
                "Truly, Yahweh, the kings of Assyria have laid waste the nations and their lands,",
                "and have cast their gods into the fire; for they were no gods, but the work of men\u2019s hands, wood and stone. Therefore they have destroyed them.",
                "Now therefore, Yahweh our God, save us, I beg you, out of his hand, that all the kingdoms of the earth may know that you, Yahweh, are God alone.\"",
                "Then Isaiah the son of Amoz sent to Hezekiah, saying, \"Thus says Yahweh, the God of Israel, \u2018Whereas you have prayed to me against Sennacherib king of Assyria, I have heard you.",
                "This is the word that Yahweh has spoken concerning him: \"The virgin daughter of Zion has despised you and ridiculed you. The daughter of Jerusalem has shaken her head at you.",
                "Whom have you defied and blasphemed? Against whom have you exalted your voice and lifted up your eyes on high? Against the Holy One of Israel.",
                "By your messengers you have defied the Lord, and have said, \u2018With the multitude of my chariots, I have come up to the height of the mountains, to the innermost parts of Lebanon; and I will cut down its tall cedars, and its choice fir trees; and I will enter into his farthest lodging place, the forest of his fruitful field.",
                "I have dug and drunk strange waters, and with the sole of my feet will I dry up all the rivers of Egypt.\u2019",
                "Haven\u2019t you heard how I have done it long ago, and formed it of ancient times? Now have I brought it to pass, that it should be yours to lay waste fortified cities into ruinous heaps.",
                "Therefore their inhabitants were of small power. They were dismayed and confounded. They were like the grass of the field, and like the green herb, like the grass on the housetops, and like grain blasted before it has grown up.",
                "But I know your sitting down, and your going out, and your coming in, and your raging against me.",
                "Because of your raging against me, and because your arrogance has come up into my ears, therefore will I put my hook in your nose, and my bridle in your lips, and I will turn you back by the way by which you came.\"",
                "\"\u2018This shall be the sign to you: You shall eat this year that which grows of itself, and in the second year that which springs of the same; and in the third year sow, and reap, and plant vineyards, and eat its fruit.",
                "The remnant that has escaped of the house of Judah shall again take root downward, and bear fruit upward.",
                "For out of Jerusalem a remnant will go out, and out of Mount Zion those who shall escape. The zeal of Yahweh will perform this.\u2019",
                "\"Therefore thus says Yahweh concerning the king of Assyria, \u2018He shall not come to this city, nor shoot an arrow there, neither shall he come before it with shield, nor cast up a mound against it.",
                "By the way that he came, by the same shall he return, and he shall not come to this city,\u2019 says Yahweh.",
                "\u2018For I will defend this city to save it, for my own sake, and for my servant David\u2019s sake.\u2019\"",
                "It happened that night, that the angel of Yahweh went out, and struck one hundred eighty-five thousand in the camp of the Assyrians. When men arose early in the morning, behold, these were all dead bodies.",
                "So Sennacherib king of Assyria departed, and went and returned, and lived at Nineveh.",
                "It happened, as he was worshipping in the house of Nisroch his god, that Adrammelech and Sharezer struck him with the sword; and they escaped into the land of Ararat. Esar Haddon his son reigned in his place."
            ],
            20: [
                "In those days was Hezekiah sick to death. Isaiah the prophet the son of Amoz came to him, and said to him, \"Thus says Yahweh, \u2018Set your house in order; for you shall die, and not live.\u2019\"",
                "Then he turned his face to the wall, and prayed to Yahweh, saying,",
                "\"Remember now, Yahweh, I beg you, how I have walked before you in truth and with a perfect heart, and have done that which is good in your sight.\" Hezekiah wept bitterly.",
                "It happened, before Isaiah had gone out into the middle part of the city, that the word of Yahweh came to him, saying,",
                "\"Turn back, and tell Hezekiah the prince of my people, \u2018Thus says Yahweh, the God of David your father, \"I have heard your prayer. I have seen your tears. Behold, I will heal you. On the third day, you shall go up to the house of Yahweh.",
                "I will add to your days fifteen years. I will deliver you and this city out of the hand of the king of Assyria. I will defend this city for my own sake, and for my servant David\u2019s sake.\"\u2019\"",
                "Isaiah said, \"Take a cake of figs.\" They took and laid it on the boil, and he recovered.",
                "Hezekiah said to Isaiah, \"What shall be the sign that Yahweh will heal me, and that I shall go up to the house of Yahweh the third day?\"",
                "Isaiah said, \"This shall be the sign to you from Yahweh, that Yahweh will do the thing that he has spoken: shall the shadow go forward ten steps, or go back ten steps?\"",
                "Hezekiah answered, \"It is a light thing for the shadow to go forward ten steps. Nay, but let the shadow return backward ten steps.\"",
                "Isaiah the prophet cried to Yahweh; and he brought the shadow ten steps backward, by which it had gone down on the dial of Ahaz.",
                "At that time Berodach Baladan the son of Baladan, king of Babylon, sent letters and a present to Hezekiah; for he had heard that Hezekiah had been sick.",
                "Hezekiah listened to them, and showed them all the house of his precious things, the silver, and the gold, and the spices, and the precious oil, and the house of his armor, and all that was found in his treasures: there was nothing in his house, nor in all his dominion, that Hezekiah didn\u2019t show them.",
                "Then Isaiah the prophet came to king Hezekiah, and said to him, \"What did these men say? From where did they come to you?\" Hezekiah said, \"They have come from a far country, even from Babylon.\"",
                "He said, \"What have they seen in your house?\" Hezekiah answered, \"They have seen all that is in my house. There is nothing among my treasures that I have not shown them.\"",
                "Isaiah said to Hezekiah, \"Hear the word of Yahweh.",
                "\u2018Behold, the days come, that all that is in your house, and that which your fathers have laid up in store to this day, shall be carried to Babylon. Nothing shall be left,\u2019 says Yahweh.",
                "\u2018Of your sons who shall issue from you, whom you shall father, shall they take away; and they shall be eunuchs in the palace of the king of Babylon.\u2019\"",
                "Then Hezekiah said to Isaiah, \"The word of Yahweh which you have spoken is good.\" He said moreover, \"Isn\u2019t it so, if peace and truth shall be in my days?\"",
                "Now the rest of the acts of Hezekiah, and all his might, and how he made the pool, and the conduit, and brought water into the city, aren\u2019t they written in the book of the chronicles of the kings of Judah?",
                "Hezekiah slept with his fathers; and Manasseh his son reigned in his place."
            ],
            21: [
                "Manasseh was twelve years old when he began to reign; and he reigned fifty-five years in Jerusalem: and his mother\u2019s name was Hephzibah.",
                "He did that which was evil in the sight of Yahweh, after the abominations of the nations whom Yahweh cast out before the children of Israel.",
                "For he built again the high places which Hezekiah his father had destroyed; and he reared up altars for Baal, and made an Asherah, as did Ahab king of Israel, and worshiped all the army of the sky, and served them.",
                "He built altars in the house of Yahweh, of which Yahweh said, \"I will put my name in Jerusalem.\"",
                "He built altars for all the army of the sky in the two courts of the house of Yahweh.",
                "He made his son to pass through the fire, and practiced sorcery, and used enchantments, and dealt with those who had familiar spirits, and with wizards: he worked much evil in the sight of Yahweh, to provoke him to anger.",
                "He set the engraved image of Asherah, that he had made, in the house of which Yahweh said to David and to Solomon his son, \"In this house, and in Jerusalem, which I have chosen out of all the tribes of Israel, will I put my name forever;",
                "neither will I cause the feet of Israel to wander any more out of the land which I gave their fathers, if only they will observe to do according to all that I have commanded them, and according to all the law that my servant Moses commanded them.\"",
                "But they didn\u2019t listen: and Manasseh seduced them to do that which is evil more than the nations did whom Yahweh destroyed before the children of Israel.",
                "Yahweh spoke by his servants the prophets, saying,",
                "\"Because Manasseh king of Judah has done these abominations, and has done wickedly above all that the Amorites did, who were before him, and has made Judah also to sin with his idols;",
                "therefore thus says Yahweh, the God of Israel, \u2018Behold, I bring such evil on Jerusalem and Judah, that whoever hears of it, both his ears shall tingle.",
                "I will stretch over Jerusalem the line of Samaria, and the plummet of the house of Ahab; and I will wipe Jerusalem as a man wipes a dish, wiping it and turning it upside down.",
                "I will cast off the remnant of my inheritance, and deliver them into the hand of their enemies. They will become a prey and a spoil to all their enemies;",
                "because they have done that which is evil in my sight, and have provoked me to anger, since the day their fathers came forth out of Egypt, even to this day.\u2019\"",
                "Moreover Manasseh shed innocent blood very much, until he had filled Jerusalem from one end to another; besides his sin with which he made Judah to sin, in doing that which was evil in the sight of Yahweh.",
                "Now the rest of the acts of Manasseh, and all that he did, and his sin that he sinned, aren\u2019t they written in the book of the chronicles of the kings of Judah?",
                "Manasseh slept with his fathers, and was buried in the garden of his own house, in the garden of Uzza: and Amon his son reigned in his place.",
                "Amon was twenty-two years old when he began to reign; and he reigned two years in Jerusalem: and his mother\u2019s name was Meshullemeth the daughter of Haruz of Jotbah.",
                "He did that which was evil in the sight of Yahweh, as Manasseh his father did.",
                "He walked in all the way that his father walked in, and served the idols that his father served, and worshiped them:",
                "and he forsook Yahweh, the God of his fathers, and didn\u2019t walk in the way of Yahweh.",
                "The servants of Amon conspired against him, and put the king to death in his own house.",
                "But the people of the land killed all those who had conspired against king Amon; and the people of the land made Josiah his son king in his place.",
                "Now the rest of the acts of Amon which he did, aren\u2019t they written in the book of the chronicles of the kings of Judah?",
                "He was buried in his tomb in the garden of Uzza: and Josiah his son reigned in his place."
            ],
            22: [
                "Josiah was eight years old when he began to reign; and he reigned thirty-one years in Jerusalem: and his mother\u2019s name was Jedidah the daughter of Adaiah of Bozkath.",
                "He did that which was right in the eyes of Yahweh, and walked in all the way of David his father, and didn\u2019t turn aside to the right hand or to the left.",
                "It happened in the eighteenth year of king Josiah, that the king sent Shaphan, the son of Azaliah the son of Meshullam, the scribe, to the house of Yahweh, saying,",
                "\"Go up to Hilkiah the high priest, that he may sum the money which is brought into the house of Yahweh, which the keepers of the threshold have gathered of the people.",
                "Let them deliver it into the hand of the workmen who have the oversight of the house of Yahweh; and let them give it to the workmen who are in the house of Yahweh, to repair the breaches of the house,",
                "to the carpenters, and to the builders, and to the masons, and for buying timber and cut stone to repair the house.",
                "However there was no accounting made with them of the money that was delivered into their hand; for they dealt faithfully.\"",
                "Hilkiah the high priest said to Shaphan the scribe, \"I have found the book of the law in the house of Yahweh.\" Hilkiah delivered the book to Shaphan, and he read it.",
                "Shaphan the scribe came to the king, and brought the king word again, and said, \"Your servants have emptied out the money that was found in the house, and have delivered it into the hand of the workmen who have the oversight of the house of Yahweh.\"",
                "Shaphan the scribe told the king, saying, \"Hilkiah the priest has delivered a book to me.\" Shaphan read it before the king.",
                "It happened, when the king had heard the words of the book of the law, that he tore his clothes.",
                "The king commanded Hilkiah the priest, and Ahikam the son of Shaphan, and Achbor the son of Micaiah, and Shaphan the scribe, and Asaiah the king\u2019s servant, saying,",
                "\"Go inquire of Yahweh for me, and for the people, and for all Judah, concerning the words of this book that is found; for great is the wrath of Yahweh that is kindled against us, because our fathers have not listened to the words of this book, to do according to all that which is written concerning us.\"",
                "So Hilkiah the priest, and Ahikam, and Achbor, and Shaphan, and Asaiah, went to Huldah the prophetess, the wife of Shallum the son of Tikvah, the son of Harhas, keeper of the wardrobe (now she lived in Jerusalem in the second quarter); and they talked with her.",
                "She said to them, \"Thus says Yahweh, the God of Israel: \u2018Tell the man who sent you to me,",
                "\"Thus says Yahweh, \u2018Behold, I will bring evil on this place, and on its inhabitants, even all the words of the book which the king of Judah has read.",
                "Because they have forsaken me, and have burned incense to other gods, that they might provoke me to anger with all the work of their hands, therefore my wrath shall be kindled against this place, and it shall not be quenched.\u2019\"",
                "But to the king of Judah, who sent you to inquire of Yahweh, thus you shall tell him, \"Thus says Yahweh, the God of Israel: \u2018Concerning the words which you have heard,",
                "because your heart was tender, and you humbled yourself before Yahweh, when you heard what I spoke against this place, and against its inhabitants, that they should become a desolation and a curse, and have torn your clothes, and wept before me; I also have heard you,\u2019 says Yahweh.",
                "\u2018Therefore, behold, I will gather you to your fathers, and you shall be gathered to your grave in peace, neither shall your eyes see all the evil which I will bring on this place.\u2019\"\u2019\" They brought back this message to the king."
            ],
            23: [
                "The king sent, and they gathered to him all the elders of Judah and of Jerusalem.",
                "The king went up to the house of Yahweh, and all the men of Judah and all the inhabitants of Jerusalem with him, and the priests, and the prophets, and all the people, both small and great: and he read in their ears all the words of the book of the covenant which was found in the house of Yahweh.",
                "The king stood by the pillar, and made a covenant before Yahweh, to walk after Yahweh, and to keep his commandments, and his testimonies, and his statutes, with all his heart, and all his soul, to confirm the words of this covenant that were written in this book: and all the people stood to the covenant.",
                "The king commanded Hilkiah the high priest, and the priests of the second order, and the keepers of the threshold, to bring forth out of the temple of Yahweh all the vessels that were made for Baal, and for the Asherah, and for all the army of the sky, and he burned them outside of Jerusalem in the fields of the Kidron, and carried their ashes to Bethel.",
                "He put down the idolatrous priests, whom the kings of Judah had ordained to burn incense in the high places in the cities of Judah, and in the places around Jerusalem; those also who burned incense to Baal, to the sun, and to the moon, and to the planets, and to all the army of the sky.",
                "He brought out the Asherah from the house of Yahweh, outside of Jerusalem, to the brook Kidron, and burned it at the brook Kidron, and beat it to dust, and cast its dust on the graves of the common people.",
                "He broke down the houses of the sodomites, that were in the house of Yahweh, where the women wove hangings for the Asherah.",
                "He brought all the priests out of the cities of Judah, and defiled the high places where the priests had burned incense, from Geba to Beersheba; and he broke down the high places of the gates that were at the entrance of the gate of Joshua the governor of the city, which were on a man\u2019s left hand at the gate of the city.",
                "Nevertheless the priests of the high places didn\u2019t come up to the altar of Yahweh in Jerusalem, but they ate unleavened bread among their brothers.",
                "He defiled Topheth, which is in the valley of the children of Hinnom, that no man might make his son or his daughter to pass through the fire to Molech.",
                "He took away the horses that the kings of Judah had given to the sun, at the entrance of the house of Yahweh, by the room of Nathan Melech the officer, who was in the court; and he burned the chariots of the sun with fire.",
                "The king broke down the altars that were on the roof of the upper room of Ahaz, which the kings of Judah had made, and the altars which Manasseh had made in the two courts of the house of Yahweh, and beat them down from there, and cast their dust into the brook Kidron.",
                "The the king defiled the high places that were before Jerusalem, which were on the right hand of the mountain of corruption, which Solomon the king of Israel had built for Ashtoreth the abomination of the Sidonians, and for Chemosh the abomination of Moab, and for Milcom the abomination of the children of Ammon.",
                "He broke in pieces the pillars, and cut down the Asherim, and filled their places with the bones of men.",
                "Moreover the altar that was at Bethel, and the high place which Jeroboam the son of Nebat, who made Israel to sin, had made, even that altar and the high place he broke down; and he burned the high place and beat it to dust, and burned the Asherah.",
                "As Josiah turned himself, he spied the tombs that were there in the mountain; and he sent, and took the bones out of the tombs, and burned them on the altar, and defiled it, according to the word of Yahweh which the man of God proclaimed, who proclaimed these things.",
                "Then he said, \"What monument is that which I see?\" The men of the city told him, \"It is the tomb of the man of God, who came from Judah, and proclaimed these things that you have done against the altar of Bethel.\"",
                "He said, \"Let him be! Let no man move his bones.\" So they let his bones alone, with the bones of the prophet who came out of Samaria.",
                "All the houses also of the high places that were in the cities of Samaria, which the kings of Israel had made to provoke Yahweh to anger, Josiah took away, and did to them according to all the acts that he had done in Bethel.",
                "He killed all the priests of the high places that were there, on the altars, and burned men\u2019s bones on them; and he returned to Jerusalem.",
                "The king commanded all the people, saying, \"Keep the Passover to Yahweh your God, as it is written in this book of the covenant.\"",
                "Surely there was not kept such a Passover from the days of the judges who judged Israel, nor in all the days of the kings of Israel, nor of the kings of Judah;",
                "but in the eighteenth year of king Josiah was this Passover kept to Yahweh in Jerusalem.",
                "Moreover Josiah removed those who had familiar spirits, and the wizards, and the teraphim, and the idols, and all the abominations that were seen in the land of Judah and in Jerusalem, that he might confirm the words of the law which were written in the book that Hilkiah the priest found in the house of Yahweh.",
                "Like him was there no king before him, who turned to Yahweh with all his heart, and with all his soul, and with all his might, according to all the law of Moses; neither after him arose there any like him.",
                "Notwithstanding, Yahweh didn\u2019t turn from the fierceness of his great wrath, with which his anger was kindled against Judah, because of all the provocation with which Manasseh had provoked him.",
                "Yahweh said, \"I will remove Judah also out of my sight, as I have removed Israel, and I will cast off this city which I have chosen, even Jerusalem, and the house of which I said, \u2018My name shall be there.\u2019\"",
                "Now the rest of the acts of Josiah, and all that he did, aren\u2019t they written in the book of the chronicles of the kings of Judah?",
                "In his days Pharaoh Necoh king of Egypt went up against the king of Assyria to the river Euphrates: and king Josiah went against him; and Pharaoh Necoh killed him at Megiddo, when he had seen him.",
                "His servants carried him in a chariot dead from Megiddo, and brought him to Jerusalem, and buried him in his own tomb. The people of the land took Jehoahaz the son of Josiah, and anointed him, and made him king in his father\u2019s place.",
                "Jehoahaz was twenty-three years old when he began to reign; and he reigned three months in Jerusalem: and his mother\u2019s name was Hamutal the daughter of Jeremiah of Libnah.",
                "He did that which was evil in the sight of Yahweh, according to all that his fathers had done.",
                "Pharaoh Necoh put him in bonds at Riblah in the land of Hamath, that he might not reign in Jerusalem; and put the land to a tribute of one hundred talents of silver, and a talent of gold.",
                "Pharaoh Necoh made Eliakim the son of Josiah king in the place of Josiah his father, and changed his name to Jehoiakim: but he took Jehoahaz away; and he came to Egypt, and died there.",
                "Jehoiakim gave the silver and the gold to Pharaoh; but he taxed the land to give the money according to the commandment of Pharaoh: he exacted the silver and the gold of the people of the land, of everyone according to his taxation, to give it to Pharaoh Necoh.",
                "Jehoiakim was twenty-five years old when he began to reign; and he reigned eleven years in Jerusalem: and his mother\u2019s name was Zebidah the daughter of Pedaiah of Rumah.",
                "He did that which was evil in the sight of Yahweh, according to all that his fathers had done."
            ],
            24: [
                "In his days Nebuchadnezzar king of Babylon came up, and Jehoiakim became his servant three years: then he turned and rebelled against him.",
                "Yahweh sent against him bands of the Chaldeans, and bands of the Syrians, and bands of the Moabites, and bands of the children of Ammon, and sent them against Judah to destroy it, according to the word of Yahweh, which he spoke by his servants the prophets.",
                "Surely at the commandment of Yahweh came this on Judah, to remove them out of his sight, for the sins of Manasseh, according to all that he did,",
                "and also for the innocent blood that he shed; for he filled Jerusalem with innocent blood: and Yahweh would not pardon.",
                "Now the rest of the acts of Jehoiakim, and all that he did, aren\u2019t they written in the book of the chronicles of the kings of Judah?",
                "So Jehoiakim slept with his fathers; and Jehoiachin his son reigned in his place.",
                "The king of Egypt didn\u2019t come again out of his land any more; for the king of Babylon had taken, from the brook of Egypt to the river Euphrates, all that pertained to the king of Egypt.",
                "Jehoiachin was eighteen years old when he began to reign; and he reigned in Jerusalem three months: and his mother\u2019s name was Nehushta the daughter of Elnathan of Jerusalem.",
                "He did that which was evil in the sight of Yahweh, according to all that his father had done.",
                "At that time the servants of Nebuchadnezzar king of Babylon came up to Jerusalem, and the city was besieged.",
                "Nebuchadnezzar king of Babylon came to the city, while his servants were besieging it;",
                "and Jehoiachin the king of Judah went out to the king of Babylon, he, and his mother, and his servants, and his princes, and his officers: and the king of Babylon took him in the eighth year of his reign.",
                "He carried out there all the treasures of the house of Yahweh, and the treasures of the king\u2019s house, and cut in pieces all the vessels of gold, which Solomon king of Israel had made in the temple of Yahweh, as Yahweh had said.",
                "He carried away all Jerusalem, and all the princes, and all the mighty men of valor, even ten thousand captives, and all the craftsmen and the smiths; none remained, except the poorest sort of the people of the land.",
                "He carried away Jehoiachin to Babylon; and the king\u2019s mother, and the king\u2019s wives, and his officers, and the chief men of the land, carried he into captivity from Jerusalem to Babylon.",
                "All the men of might, even seven thousand, and the craftsmen and the smiths one thousand, all of them strong and apt for war, even them the king of Babylon brought captive to Babylon.",
                "The king of Babylon made Mattaniah, Jehoiachin\u2019s father\u2019s brother, king is his place, and changed his name to Zedekiah.",
                "Zedekiah was twenty-one years old when he began to reign; and he reigned eleven years in Jerusalem: and his mother\u2019s name was Hamutal the daughter of Jeremiah of Libnah.",
                "He did that which was evil in the sight of Yahweh, according to all that Jehoiakim had done.",
                "For through the anger of Yahweh, it happened in Jerusalem and Judah, until he had cast them out from his presence. Zedekiah rebelled against the king of Babylon."
            ],
            25: [
                "It happened in the ninth year of his reign, in the tenth month, in the tenth day of the month, that Nebuchadnezzar king of Babylon came, he and all his army, against Jerusalem, and encamped against it; and they built forts against it around it.",
                "So the city was besieged to the eleventh year of king Zedekiah.",
                "On the ninth day of the fourth month the famine was severe in the city, so that there was no bread for the people of the land.",
                "Then a breach was made in the city, and all the men of war fled by night by the way of the gate between the two walls, which was by the king\u2019s garden (now the Chaldeans were against the city around it); and the king went by the way of the Arabah.",
                "But the army of the Chaldeans pursued after the king, and overtook him in the plains of Jericho; and all his army was scattered from him.",
                "Then they took the king, and carried him up to the king of Babylon to Riblah; and they gave judgment on him.",
                "They killed the sons of Zedekiah before his eyes, and put out the eyes of Zedekiah, and bound him in fetters, and carried him to Babylon.",
                "Now in the fifth month, on the seventh day of the month, which was the nineteenth year of king Nebuchadnezzar, king of Babylon, came Nebuzaradan the captain of the guard, a servant of the king of Babylon, to Jerusalem.",
                "He burnt the house of Yahweh, and the king\u2019s house; and all the houses of Jerusalem, even every great house, burnt he with fire.",
                "All the army of the Chaldeans, who were with the captain of the guard, broke down the walls around Jerusalem.",
                "Nebuzaradan the captain of the guard carried away captive the residue of the people who were left in the city, and those who fell away, who fell to the king of Babylon, and the residue of the multitude.",
                "But the captain of the guard left some of the poorest of the land to work the vineyards and fields.",
                "The Chaldeans broke up the pillars of brass that were in the house of Yahweh and the bases and the bronze sea that were in the house of Yahweh, and carried the brass pieces to Babylon.",
                "They took away the pots, the shovels, the snuffers, the spoons, and all the vessels of brass with which they ministered.",
                "The captain of the guard took away the fire pans, the basins, that which was of gold, in gold, and that which was of silver, in silver.",
                "The two pillars, the one sea, and the bases, which Solomon had made for the house of Yahweh, the brass of all these vessels was without weight.",
                "The height of the one pillar was eighteen cubits, and a capital of brass was on it; and the height of the capital was three cubits, with network and pomegranates on the capital around it, all of brass: and like to these had the second pillar with network.",
                "The captain of the guard took Seraiah the chief priest, and Zephaniah the second priest, and the three keepers of the threshold:",
                "and out of the city he took an officer who was set over the men of war; and five men of those who saw the king\u2019s face, who were found in the city; and the scribe, the captain of the army, who mustered the people of the land; and sixty men of the people of the land, who were found in the city.",
                "Nebuzaradan the captain of the guard took them, and brought them to the king of Babylon to Riblah.",
                "The king of Babylon struck them, and put them to death at Riblah in the land of Hamath. So Judah was carried away captive out of his land.",
                "As for the people who were left in the land of Judah, whom Nebuchadnezzar king of Babylon had left, even over them he made Gedaliah the son of Ahikam, the son of Shaphan, governor.",
                "Now when all the captains of the forces, they and their men, heard that the king of Babylon had made Gedaliah governor, they came to Gedaliah to Mizpah, even Ishmael the son of Nethaniah, and Johanan the son of Kareah, and Seraiah the son of Tanhumeth the Netophathite, and Jaazaniah the son of the Maacathite, they and their men.",
                "Gedaliah swore to them and to their men, and said to them, \"Don\u2019t be afraid because of the servants of the Chaldeans. Dwell in the land, and serve the king of Babylon, and it shall be well with you.\"",
                "But it happened in the seventh month, that Ishmael the son of Nethaniah, the son of Elishama, of the royal seed came, and ten men with him, and struck Gedaliah, so that he died, and the Jews and the Chaldeans that were with him at Mizpah.",
                "All the people, both small and great, and the captains of the forces, arose, and came to Egypt; for they were afraid of the Chaldeans.",
                "It happened in the seven and thirtieth year of the captivity of Jehoiachin king of Judah, in the twelfth month, on the seven and twentieth day of the month, that Evilmerodach king of Babylon, in the year that he began to reign, lifted up the head of Jehoiachin king of Judah out of prison;",
                "and he spoke kindly to him, and set his throne above the throne of the kings who were with him in Babylon,",
                "and changed his prison garments. Jehoiachin ate bread before him continually all the days of his life:",
                "and for his allowance, there was a continual allowance given him of the king, every day a portion, all the days of his life."
            ]
        },
        13: {
            1: [
                "Adam, Seth, Enosh,",
                "Kenan, Mahalalel, Jared,",
                "Enoch, Methuselah, Lamech,",
                "Noah, Shem, Ham, and Japheth.",
                "The sons of Japheth: Gomer, and Magog, and Madai, and Javan, and Tubal, and Meshech, and Tiras.",
                "The sons of Gomer: Ashkenaz, and Diphath, and Togarmah.",
                "The sons of Javan: Elishah, and Tarshish, Kittim, and Rodanim.",
                "The sons of Ham: Cush, and Mizraim, Put, and Canaan.",
                "The sons of Cush: Seba, and Havilah, and Sabta, and Raama, and Sabteca. The sons of Raamah: Sheba, and Dedan.",
                "Cush became the father of Nimrod; he began to be a mighty one in the earth.",
                "Mizraim became the father of Ludim, and Anamim, and Lehabim, and Naphtuhim,",
                "and Pathrusim, and Casluhim (where the Philistines came from), and Caphtorim.",
                "Canaan became the father of Sidon his firstborn, and Heth,",
                "and the Jebusite, and the Amorite, and the Girgashite,",
                "and the Hivite, and the Arkite, and the Sinite,",
                "and the Arvadite, and the Zemarite, and the Hamathite.",
                "The sons of Shem: Elam, and Asshur, and Arpachshad, and Lud, and Aram, and Uz, and Hul, and Gether, and Meshech.",
                "Arpachshad became the father of Shelah, and Shelah became the father of Eber.",
                "To Eber were born two sons: the name of the one was Peleg; for in his days the earth was divided; and his brother\u2019s name was Joktan.",
                "Joktan became the father of Almodad, and Sheleph, and Hazarmaveth, and Jerah,",
                "and Hadoram, and Uzal, and Diklah,",
                "and Ebal, and Abimael, and Sheba,",
                "and Ophir, and Havilah, and Jobab. All these were the sons of Joktan.",
                "Shem, Arpachshad, Shelah,",
                "Eber, Peleg, Reu,",
                "Serug, Nahor, Terah,",
                "Abram (the same is Abraham).",
                "The sons of Abraham: Isaac, and Ishmael.",
                "These are their generations: the firstborn of Ishmael, Nebaioth; then Kedar, and Adbeel, and Mibsam,",
                "Mishma, and Dumah, Massa, Hadad, and Tema,",
                "Jetur, Naphish, and Kedemah. These are the sons of Ishmael.",
                "The sons of Keturah, Abraham\u2019s concubine: she bore Zimran, and Jokshan, and Medan, and Midian, and Ishbak, and Shuah. The sons of Jokshan: Sheba, and Dedan.",
                "The sons of Midian: Ephah, and Epher, and Hanoch, and Abida, and Eldaah. All these were the sons of Keturah.",
                "Abraham became the father of Isaac. The sons of Isaac: Esau, and Israel.",
                "The sons of Esau: Eliphaz, Reuel, and Jeush, and Jalam, and Korah.",
                "The sons of Eliphaz: Teman, and Omar, Zephi, and Gatam, Kenaz, and Timna, and Amalek.",
                "The sons of Reuel: Nahath, Zerah, Shammah, and Mizzah.",
                "The sons of Seir: Lotan, and Shobal, and Zibeon, and Anah, and Dishon, and Ezer, and Dishan.",
                "The sons of Lotan: Hori, and Homam; and Timna was Lotan\u2019s sister.",
                "The sons of Shobal: Alian, and Manahath, and Ebal, Shephi, and Onam. The sons of Zibeon: Aiah, and Anah.",
                "The sons of Anah: Dishon. The sons of Dishon: Hamran, and Eshban, and Ithran, and Cheran.",
                "The sons of Ezer: Bilhan, and Zaavan, Jaakan. The sons of Dishan: Uz, and Aran.",
                "Now these are the kings who reigned in the land of Edom, before there reigned any king over the children of Israel: Bela the son of Beor; and the name of his city was Dinhabah.",
                "Bela died, and Jobab the son of Zerah of Bozrah reigned in his place.",
                "Jobab died, and Husham of the land of the Temanites reigned in his place.",
                "Husham died, and Hadad the son of Bedad, who struck Midian in the field of Moab, reigned in his place; and the name of his city was Avith.",
                "Hadad died, and Samlah of Masrekah reigned in his place.",
                "Samlah died, and Shaul of Rehoboth by the River reigned in his place.",
                "Shaul died, and Baal Hanan the son of Achbor reigned in his place.",
                "Baal Hanan died, and Hadad reigned in his place; and the name of his city was Pai: and his wife\u2019s name was Mehetabel, the daughter of Matred, the daughter of Mezahab.",
                "Hadad died. The chiefs of Edom were: chief Timna, chief Aliah, chief Jetheth,",
                "chief Oholibamah, chief Elah, chief Pinon,",
                "chief Kenaz, chief Teman, chief Mibzar,",
                "chief Magdiel, chief Iram. These are the chiefs of Edom."
            ],
            2: [
                "These are the sons of Israel: Reuben, Simeon, Levi, and Judah, Issachar, and Zebulun,",
                "Dan, Joseph, and Benjamin, Naphtali, Gad, and Asher.",
                "The sons of Judah: Er, and Onan, and Shelah; which three were born to him of Shua\u2019s daughter the Canaanitess. Er, Judah\u2019s firstborn, was wicked in the sight of Yahweh; and he killed him.",
                "Tamar his daughter-in-law bore him Perez and Zerah. All the sons of Judah were five.",
                "The sons of Perez: Hezron, and Hamul.",
                "The sons of Zerah: Zimri, and Ethan, and Heman, and Calcol, and Dara; five of them in all.",
                "The sons of Carmi: Achar, the troubler of Israel, who committed a trespass in the devoted thing.",
                "The sons of Ethan: Azariah.",
                "The sons also of Hezron, who were born to him: Jerahmeel, and Ram, and Chelubai.",
                "Ram became the father of Amminadab, and Amminadab became the father of Nahshon, prince of the children of Judah;",
                "and Nahshon became the father of Salma, and Salma became the father of Boaz,",
                "and Boaz became the father of Obed, and Obed became the father of Jesse;",
                "and Jesse became the father of his firstborn Eliab, and Abinadab the second, and Shimea the third,",
                "Nethanel the fourth, Raddai the fifth,",
                "Ozem the sixth, David the seventh;",
                "and their sisters were Zeruiah and Abigail. The sons of Zeruiah: Abishai, and Joab, and Asahel, three.",
                "Abigail bore Amasa; and the father of Amasa was Jether the Ishmaelite.",
                "Caleb the son of Hezron became the father of children of Azubah his wife, and of Jerioth; and these were her sons: Jesher, and Shobab, and Ardon.",
                "Azubah died, and Caleb took to him Ephrath, who bore him Hur.",
                "Hur became the father of Uri, and Uri became the father of Bezalel.",
                "Afterward Hezron went in to the daughter of Machir the father of Gilead, whom he took as wife when he was sixty years old; and she bore him Segub.",
                "Segub became the father of Jair, who had twenty-three cities in the land of Gilead.",
                "Geshur and Aram took the towns of Jair from them, with Kenath, and its villages, even sixty cities. All these were the sons of Machir the father of Gilead.",
                "After that Hezron was dead in Caleb Ephrathah, then Abijah Hezron\u2019s wife bore him Ashhur the father of Tekoa.",
                "The sons of Jerahmeel the firstborn of Hezron were Ram the firstborn, and Bunah, and Oren, and Ozem, Ahijah.",
                "Jerahmeel had another wife, whose name was Atarah; she was the mother of Onam.",
                "The sons of Ram the firstborn of Jerahmeel were Maaz, and Jamin, and Eker.",
                "The sons of Onam were Shammai, and Jada. The sons of Shammai: Nadab, and Abishur.",
                "The name of the wife of Abishur was Abihail; and she bore him Ahban, and Molid.",
                "The sons of Nadab: Seled, and Appaim; but Seled died without children.",
                "The sons of Appaim: Ishi. The sons of Ishi: Sheshan. The sons of Sheshan: Ahlai.",
                "The sons of Jada the brother of Shammai: Jether, and Jonathan; and Jether died without children.",
                "The sons of Jonathan: Peleth, and Zaza. These were the sons of Jerahmeel.",
                "Now Sheshan had no sons, but daughters. Sheshan had a servant, an Egyptian, whose name was Jarha.",
                "Sheshan gave his daughter to Jarha his servant as wife; and she bore him Attai.",
                "Attai became the father of Nathan, and Nathan became the father of Zabad,",
                "and Zabad became the father of Ephlal, and Ephlal became the father of Obed,",
                "and Obed became the father of Jehu, and Jehu became the father of Azariah,",
                "and Azariah became the father of Helez, and Helez became the father of Eleasah,",
                "and Eleasah became the father of Sismai, and Sismai became the father of Shallum,",
                "and Shallum became the father of Jekamiah, and Jekamiah became the father of Elishama.",
                "The sons of Caleb the brother of Jerahmeel were Mesha his firstborn, who was the father of Ziph; and the sons of Mareshah the father of Hebron.",
                "The sons of Hebron: Korah, and Tappuah, and Rekem, and Shema.",
                "Shema became the father of Raham, the father of Jorkeam; and Rekem became the father of Shammai.",
                "The son of Shammai was Maon; and Maon was the father of Beth Zur.",
                "Ephah, Caleb\u2019s concubine, bore Haran, and Moza, and Gazez; and Haran became the father of Gazez.",
                "The sons of Jahdai: Regem, and Jothan, and Geshan, and Pelet, and Ephah, and Shaaph.",
                "Maacah, Caleb\u2019s concubine, bore Sheber and Tirhanah.",
                "She bore also Shaaph the father of Madmannah, Sheva the father of Machbena, and the father of Gibea; and the daughter of Caleb was Achsah.",
                "These were the sons of Caleb, the son of Hur, the firstborn of Ephrathah: Shobal the father of Kiriath Jearim,",
                "Salma the father of Bethlehem, Hareph the father of Beth Gader.",
                "Shobal the father of Kiriath Jearim had sons: Haroeh, half of the Menuhoth.",
                "The families of Kiriath Jearim: The Ithrites, and the Puthites, and the Shumathites, and the Mishraites; of them came the Zorathites and the Eshtaolites.",
                "The sons of Salma: Bethlehem, and the Netophathites, Atroth Beth Joab, and half of the Manahathites, the Zorites.",
                "The families of scribes who lived at Jabez: the Tirathites, the Shimeathites, the Sucathites. These are the Kenites who came of Hammath, the father of the house of Rechab."
            ],
            3: [
                "Now these were the sons of David, who were born to him in Hebron: the firstborn, Amnon, of Ahinoam the Jezreelitess; the second, Daniel, of Abigail the Carmelitess;",
                "the third, Absalom the son of Maacah the daughter of Talmai king of Geshur; the fourth, Adonijah the son of Haggith;",
                "the fifth, Shephatiah of Abital; the sixth, Ithream by Eglah his wife:",
                "six were born to him in Hebron; and there he reigned seven years and six months. In Jerusalem he reigned thirty-three years;",
                "and these were born to him in Jerusalem: Shimea, and Shobab, and Nathan, and Solomon, four, of Bathshua the daughter of Ammiel;",
                "and Ibhar, and Elishama, and Eliphelet,",
                "and Nogah, and Nepheg, and Japhia,",
                "and Elishama, and Eliada, and Eliphelet, nine.",
                "All these were the sons of David, besides the sons of the concubines; and Tamar was their sister.",
                "Solomon\u2019s son was Rehoboam, Abijah his son, Asa his son, Jehoshaphat his son,",
                "Joram his son, Ahaziah his son, Joash his son,",
                "Amaziah his son, Azariah his son, Jotham his son,",
                "Ahaz his son, Hezekiah his son, Manasseh his son,",
                "Amon his son, Josiah his son.",
                "The sons of Josiah: the firstborn Johanan, the second Jehoiakim, the third Zedekiah, the fourth Shallum.",
                "The sons of Jehoiakim: Jeconiah his son, Zedekiah his son.",
                "The sons of Jeconiah, the captive: Shealtiel his son,",
                "and Malchiram, and Pedaiah, and Shenazzar, Jekamiah, Hoshama, and Nedabiah.",
                "The sons of Pedaiah: Zerubbabel, and Shimei. The sons of Zerubbabel: Meshullam, and Hananiah; and Shelomith was their sister;",
                "and Hashubah, and Ohel, and Berechiah, and Hasadiah, Jushab Hesed, five.",
                "The sons of Hananiah: Pelatiah, and Jeshaiah; the sons of Rephaiah, the sons of Arnan, the sons of Obadiah, the sons of Shecaniah.",
                "The sons of Shecaniah: Shemaiah. The sons of Shemaiah: Hattush, and Igal, and Bariah, and Neariah, and Shaphat, six.",
                "The sons of Neariah: Elioenai, and Hizkiah, and Azrikam, three.",
                "The sons of Elioenai: Hodaviah, and Eliashib, and Pelaiah, and Akkub, and Johanan, and Delaiah, and Anani, seven."
            ],
            4: [
                "The sons of Judah: Perez, Hezron, and Carmi, and Hur, and Shobal.",
                "Reaiah the son of Shobal became the father of Jahath; and Jahath became the father of Ahumai and Lahad. These are the families of the Zorathites.",
                "These were the sons of the father of Etam: Jezreel, and Ishma, and Idbash; and the name of their sister was Hazzelelponi;",
                "and Penuel the father of Gedor, and Ezer the father of Hushah. These are the sons of Hur, the firstborn of Ephrathah, the father of Bethlehem.",
                "Ashhur the father of Tekoa had two wives, Helah and Naarah.",
                "Naarah bore him Ahuzzam, and Hepher, and Temeni, and Haahashtari. These were the sons of Naarah.",
                "The sons of Helah were Zereth, Izhar, and Ethnan.",
                "Hakkoz became the father of Anub, and Zobebah, and the families of Aharhel the son of Harum.",
                "Jabez was more honorable than his brothers: and his mother named him Jabez, saying, \"Because I bore him with sorrow.\"",
                "Jabez called on the God of Israel, saying, \"Oh that you would bless me indeed, and enlarge my border, and that your hand might be with me, and that you would keep me from evil, that it not be to my sorrow!\" God granted him that which he requested.",
                "Chelub the brother of Shuhah became the father of Mehir, who was the father of Eshton.",
                "Eshton became the father of Beth Rapha, and Paseah, and Tehinnah the father of Ir Nahash. These are the men of Recah.",
                "The sons of Kenaz: Othniel, and Seraiah. The sons of Othniel: Hathath.",
                "Meonothai became the father of Ophrah: and Seraiah became the father of Joab the father of Ge Harashim; for they were craftsmen.",
                "The sons of Caleb the son of Jephunneh: Iru, Elah, and Naam; and the sons of Elah; and Kenaz.",
                "The sons of Jehallelel: Ziph, and Ziphah, Tiria, and Asarel.",
                "The sons of Ezrah: Jether, and Mered, and Epher, and Jalon; and she bore Miriam, and Shammai, and Ishbah the father of Eshtemoa.",
                "His wife the Jewess bore Jered the father of Gedor, and Heber the father of Soco, and Jekuthiel the father of Zanoah. These are the sons of Bithiah the daughter of Pharaoh, whom Mered took.",
                "The sons of the wife of Hodiah, the sister of Naham, were the father of Keilah the Garmite, and Eshtemoa the Maacathite.",
                "The sons of Shimon: Amnon, and Rinnah, Ben Hanan, and Tilon. The sons of Ishi: Zoheth, and Ben Zoheth.",
                "The sons of Shelah the son of Judah: Er the father of Lecah, and Laadah the father of Mareshah, and the families of the house of those who worked fine linen, of the house of Ashbea;",
                "and Jokim, and the men of Cozeba, and Joash, and Saraph, who had dominion in Moab, and Jashubilehem. The records are ancient.",
                "These were the potters, and the inhabitants of Netaim and Gederah: there they lived with the king for his work.",
                "The sons of Simeon: Nemuel, and Jamin, Jarib, Zerah, Shaul;",
                "Shallum his son, Mibsam his son, Mishma his son.",
                "The sons of Mishma: Hammuel his son, Zaccur his son, Shimei his son.",
                "Shimei had sixteen sons and six daughters; but his brothers didn\u2019t have many children, neither did all their family multiply like the children of Judah.",
                "They lived at Beersheba, and Moladah, and Hazarshual,",
                "and at Bilhah, and at Ezem, and at Tolad,",
                "and at Bethuel, and at Hormah, and at Ziklag,",
                "and at Beth Marcaboth, and Hazar Susim, and at Beth Biri, and at Shaaraim. These were their cities to the reign of David.",
                "Their villages were Etam, and Ain, Rimmon, and Tochen, and Ashan, five cities;",
                "and all their villages that were around the same cities, to Baal. These were their habitations, and they have their genealogy.",
                "Meshobab, and Jamlech, and Joshah the son of Amaziah,",
                "and Joel, and Jehu the son of Joshibiah, the son of Seraiah, the son of Asiel,",
                "and Elioenai, and Jaakobah, and Jeshohaiah, and Asaiah, and Adiel, and Jesimiel, and Benaiah,",
                "and Ziza the son of Shiphi, the son of Allon, the son of Jedaiah, the son of Shimri, the son of Shemaiah\u2014",
                "these mentioned by name were princes in their families: and their fathers\u2019 houses increased greatly.",
                "They went to the entrance of Gedor, even to the east side of the valley, to seek pasture for their flocks.",
                "They found fat pasture and good, and the land was wide, and quiet, and peaceable; for those who lived there before were of Ham.",
                "These written by name came in the days of Hezekiah king of Judah, and struck their tents, and the Meunim who were found there, and destroyed them utterly to this day, and lived in their place; because there was pasture there for their flocks.",
                "Some of them, even of the sons of Simeon, five hundred men, went to Mount Seir, having for their captains Pelatiah, and Neariah, and Rephaiah, and Uzziel, the sons of Ishi.",
                "They struck the remnant of the Amalekites who escaped, and have lived there to this day."
            ],
            5: [
                "The sons of Reuben the firstborn of Israel (for he was the firstborn; but, because he defiled his father\u2019s couch, his birthright was given to the sons of Joseph the son of Israel; and the genealogy is not to be reckoned after the birthright.",
                "For Judah prevailed above his brothers, and of him came the prince; but the birthright was Joseph\u2019s:)",
                "the sons of Reuben the firstborn of Israel: Hanoch, and Pallu, Hezron, and Carmi.",
                "The sons of Joel: Shemaiah his son, Gog his son, Shimei his son,",
                "Micah his son, Reaiah his son, Baal his son,",
                "Beerah his son, whom Tilgath Pilneser king of Assyria carried away captive: he was prince of the Reubenites.",
                "His brothers by their families, when the genealogy of their generations was reckoned: the chief, Jeiel, and Zechariah,",
                "and Bela the son of Azaz, the son of Shema, the son of Joel, who lived in Aroer, even to Nebo and Baal Meon:",
                "and eastward he lived even to the entrance of the wilderness from the river Euphrates, because their livestock were multiplied in the land of Gilead.",
                "In the days of Saul, they made war with the Hagrites, who fell by their hand; and they lived in their tents throughout all the land east of Gilead.",
                "The sons of Gad lived over against them, in the land of Bashan to Salecah:",
                "Joel the chief, and Shapham the second, and Janai, and Shaphat in Bashan.",
                "Their brothers of their fathers\u2019 houses: Michael, and Meshullam, and Sheba, and Jorai, and Jacan, and Zia, and Eber, seven.",
                "These were the sons of Abihail, the son of Huri, the son of Jaroah, the son of Gilead, the son of Michael, the son of Jeshishai, the son of Jahdo, the son of Buz;",
                "Ahi the son of Abdiel, the son of Guni, chief of their fathers\u2019 houses.",
                "They lived in Gilead in Bashan, and in its towns, and in all the suburbs of Sharon, as far as their borders.",
                "All these were reckoned by genealogies in the days of Jotham king of Judah, and in the days of Jeroboam king of Israel.",
                "The sons of Reuben, and the Gadites, and the half-tribe of Manasseh, of valiant men, men able to bear buckler and sword, and to shoot with bow, and skillful in war, were forty-four thousand seven hundred and sixty, that were able to go forth to war.",
                "They made war with the Hagrites, with Jetur, and Naphish, and Nodab.",
                "They were helped against them, and the Hagrites were delivered into their hand, and all who were with them; for they cried to God in the battle, and he was entreated of them, because they put their trust in him.",
                "They took away their livestock; of their camels fifty thousand, and of sheep two hundred fifty thousand, and of donkeys two thousand, and of men one hundred thousand.",
                "For there fell many slain, because the war was of God. They lived in their place until the captivity.",
                "The children of the half-tribe of Manasseh lived in the land: they increased from Bashan to Baal Hermon and Senir and Mount Hermon.",
                "These were the heads of their fathers\u2019 houses: even Epher, and Ishi, and Eliel, and Azriel, and Jeremiah, and Hodaviah, and Jahdiel, mighty men of valor, famous men, heads of their fathers\u2019 houses.",
                "They trespassed against the God of their fathers, and played the prostitute after the gods of the peoples of the land, whom God destroyed before them.",
                "The God of Israel stirred up the spirit of Pul king of Assyria, and the spirit of Tilgath Pilneser king of Assyria, and he carried them away, even the Reubenites, and the Gadites, and the half-tribe of Manasseh, and brought them to Halah, and Habor, and Hara, and to the river of Gozan, to this day."
            ],
            6: [
                "The sons of Levi: Gershon, Kohath, and Merari.",
                "The sons of Kohath: Amram, Izhar, and Hebron, and Uzziel.",
                "The children of Amram: Aaron, and Moses, and Miriam. The sons of Aaron: Nadab, and Abihu, Eleazar, and Ithamar.",
                "Eleazar became the father of Phinehas, Phinehas became the father of Abishua,",
                "and Abishua became the father of Bukki, and Bukki became the father of Uzzi,",
                "and Uzzi became the father of Zerahiah, and Zerahiah became the father of Meraioth,",
                "Meraioth became the father of Amariah, and Amariah became the father of Ahitub,",
                "and Ahitub became the father of Zadok, and Zadok became the father of Ahimaaz,",
                "and Ahimaaz became the father of Azariah, and Azariah became the father of Johanan,",
                "and Johanan became the father of Azariah, (he it is who executed the priest\u2019s office in the house that Solomon built in Jerusalem),",
                "and Azariah became the father of Amariah, and Amariah became the father of Ahitub,",
                "and Ahitub became the father of Zadok, and Zadok became the father of Shallum,",
                "and Shallum became the father of Hilkiah, and Hilkiah became the father of Azariah,",
                "and Azariah became the father of Seraiah, and Seraiah became the father of Jehozadak;",
                "Jehozadak went into captivity, when Yahweh carried away Judah and Jerusalem by the hand of Nebuchadnezzar.",
                "The sons of Levi: Gershom, Kohath, and Merari.",
                "These are the names of the sons of Gershom: Libni and Shimei.",
                "The sons of Kohath were Amram, and Izhar, and Hebron, and Uzziel.",
                "The sons of Merari: Mahli and Mushi. These are the families of the Levites according to their fathers\u2019 households.",
                "Of Gershom: Libni his son, Jahath his son, Zimmah his son,",
                "Joah his son, Iddo his son, Zerah his son, Jeatherai his son.",
                "The sons of Kohath: Amminadab his son, Korah his son, Assir his son,",
                "Elkanah his son, and Ebiasaph his son, and Assir his son,",
                "Tahath his son, Uriel his son, Uzziah his son, and Shaul his son.",
                "The sons of Elkanah: Amasai, and Ahimoth.",
                "As for Elkanah, the sons of Elkanah: Zophai his son, and Nahath his son,",
                "Eliab his son, Jeroham his son, Elkanah his son.",
                "The sons of Samuel: the firstborn Joel, and the second Abijah.",
                "The sons of Merari: Mahli, Libni his son, Shimei his son, Uzzah his son,",
                "Shimea his son, Haggiah his son, Asaiah his son.",
                "These are they whom David set over the service of song in the house of Yahweh, after that the ark had rest.",
                "They ministered with song before the tabernacle of the Tent of Meeting, until Solomon had built the house of Yahweh in Jerusalem: and they waited on their office according to their order.",
                "These are those who waited, and their sons. Of the sons of the Kohathites: Heman the singer, the son of Joel, the son of Samuel,",
                "the son of Elkanah, the son of Jeroham, the son of Eliel, the son of Toah,",
                "the son of Zuph, the son of Elkanah, the son of Mahath, the son of Amasai,",
                "the son of Elkanah, the son of Joel, the son of Azariah, the son of Zephaniah,",
                "the son of Tahath, the son of Assir, the son of Ebiasaph, the son of Korah,",
                "the son of Izhar, the son of Kohath, the son of Levi, the son of Israel.",
                "His brother Asaph, who stood on his right hand, even Asaph the son of Berechiah, the son of Shimea,",
                "the son of Michael, the son of Baaseiah, the son of Malchijah,",
                "the son of Ethni, the son of Zerah, the son of Adaiah,",
                "the son of Ethan, the son of Zimmah, the son of Shimei,",
                "the son of Jahath, the son of Gershom, the son of Levi.",
                "On the left hand their brothers the sons of Merari: Ethan the son of Kishi, the son of Abdi, the son of Malluch,",
                "the son of Hashabiah, the son of Amaziah, the son of Hilkiah,",
                "the son of Amzi, the son of Bani, the son of Shemer,",
                "the son of Mahli, the son of Mushi, the son of Merari, the son of Levi.",
                "Their brothers the Levites were appointed for all the service of the tabernacle of the house of God.",
                "But Aaron and his sons offered on the altar of burnt offering, and on the altar of incense, for all the work of the most holy place, and to make atonement for Israel, according to all that Moses the servant of God had commanded.",
                "These are the sons of Aaron: Eleazar his son, Phinehas his son, Abishua his son,",
                "Bukki his son, Uzzi his son, Zerahiah his son,",
                "Meraioth his son, Amariah his son, Ahitub his son,",
                "Zadok his son, Ahimaaz his son.",
                "Now these are their dwelling places according to their encampments in their borders: to the sons of Aaron, of the families of the Kohathites (for theirs was the first lot),",
                "to them they gave Hebron in the land of Judah, and its suburbs around it;",
                "but the fields of the city, and its villages, they gave to Caleb the son of Jephunneh.",
                "To the sons of Aaron they gave the cities of refuge, Hebron; Libnah also with its suburbs, and Jattir, and Eshtemoa with its suburbs,",
                "and Hilen with its suburbs, Debir with its suburbs,",
                "and Ashan with its suburbs, and Beth Shemesh with its suburbs;",
                "and out of the tribe of Benjamin, Geba with its suburbs, and Allemeth with its suburbs, and Anathoth with its suburbs. All their cities throughout their families were thirteen cities.",
                "To the rest of the sons of Kohath were given by lot, out of the family of the tribe, out of the half-tribe, the half of Manasseh, ten cities.",
                "To the sons of Gershom, according to their families, out of the tribe of Issachar, and out of the tribe of Asher, and out of the tribe of Naphtali, and out of the tribe of Manasseh in Bashan, thirteen cities.",
                "To the sons of Merari were given by lot, according to their families, out of the tribe of Reuben, and out of the tribe of Gad, and out of the tribe of Zebulun, twelve cities.",
                "The children of Israel gave to the Levites the cities with their suburbs.",
                "They gave by lot out of the tribe of the children of Judah, and out of the tribe of the children of Simeon, and out of the tribe of the children of Benjamin, these cities which are mentioned by name.",
                "Some of the families of the sons of Kohath had cities of their borders out of the tribe of Ephraim.",
                "They gave to them the cities of refuge, Shechem in the hill country of Ephraim with its suburbs; Gezer also with its suburbs,",
                "and Jokmeam with its suburbs, and Beth Horon with its suburbs,",
                "and Aijalon with its suburbs, and Gath Rimmon with its suburbs;",
                "and out of the half-tribe of Manasseh, Aner with its suburbs, and Bileam with its suburbs, for the rest of the family of the sons of Kohath.",
                "To the sons of Gershom were given, out of the family of the half-tribe of Manasseh, Golan in Bashan with its suburbs, and Ashtaroth with its suburbs;",
                "and out of the tribe of Issachar, Kedesh with its suburbs, Daberath with its suburbs,",
                "and Ramoth with its suburbs, and Anem with its suburbs;",
                "and out of the tribe of Asher, Mashal with its suburbs, and Abdon with its suburbs,",
                "and Hukok with its suburbs, and Rehob with its suburbs;",
                "and out of the tribe of Naphtali, Kedesh in Galilee with its suburbs, and Hammon with its suburbs, and Kiriathaim with its suburbs.",
                "To the rest of the Levites, the sons of Merari, were given, out of the tribe of Zebulun, Rimmono with its suburbs, Tabor with its suburbs;",
                "and beyond the Jordan at Jericho, on the east side of the Jordan, were given them, out of the tribe of Reuben, Bezer in the wilderness with its suburbs, and Jahzah with its suburbs,",
                "and Kedemoth with its suburbs, and Mephaath with its suburbs;",
                "and out of the tribe of Gad, Ramoth in Gilead with its suburbs, and Mahanaim with its suburbs,",
                "and Heshbon with its suburbs, and Jazer with its suburbs."
            ],
            7: [
                "Of the sons of Issachar: Tola, and Puah, Jashub, and Shimron, four.",
                "The sons of Tola: Uzzi, and Rephaiah, and Jeriel, and Jahmai, and Ibsam, and Shemuel, heads of their fathers\u2019 houses, of Tola; mighty men of valor in their generations: their number in the days of David was twenty-two thousand six hundred.",
                "The sons of Uzzi: Izrahiah. The sons of Izrahiah: Michael, and Obadiah, and Joel, Isshiah, five; all of them chief men.",
                "With them, by their generations, after their fathers\u2019 houses, were bands of the army for war, thirty-six thousand; for they had many wives and sons.",
                "Their brothers among all the families of Issachar, mighty men of valor, reckoned in all by genealogy, were eighty-seven thousand.",
                "The sons of Benjamin: Bela, and Becher, and Jediael, three.",
                "The sons of Bela: Ezbon, and Uzzi, and Uzziel, and Jerimoth, and Iri, five; heads of fathers\u2019 houses, mighty men of valor; and they were reckoned by genealogy twenty-two thousand thirty-four.",
                "The sons of Becher: Zemirah, and Joash, and Eliezer, and Elioenai, and Omri, and Jeremoth, and Abijah, and Anathoth, and Alemeth. All these were the sons of Becher.",
                "They were reckoned by genealogy, after their generations, heads of their fathers\u2019 houses, mighty men of valor, twenty thousand two hundred.",
                "The sons of Jediael: Bilhan. The sons of Bilhan: Jeush, and Benjamin, and Ehud, and Chenaanah, and Zethan, and Tarshish, and Ahishahar.",
                "All these were sons of Jediael, according to the heads of their fathers\u2019 households, mighty men of valor, seventeen thousand and two hundred, who were able to go forth in the army for war.",
                "Shuppim also, and Huppim, the sons of Ir, Hushim, the sons of Aher.",
                "The sons of Naphtali: Jahziel, and Guni, and Jezer, and Shallum, the sons of Bilhah.",
                "The sons of Manasseh: Asriel, whom his concubine the Aramitess bore: she bore Machir the father of Gilead:",
                "and Machir took a wife of Huppim and Shuppim, whose sister\u2019s name was Maacah; and the name of the second was Zelophehad: and Zelophehad had daughters.",
                "Maacah the wife of Machir bore a son, and she named him Peresh; and the name of his brother was Sheresh; and his sons were Ulam and Rakem.",
                "The sons of Ulam: Bedan. These were the sons of Gilead the son of Machir, the son of Manasseh.",
                "His sister Hammolecheth bore Ishhod, and Abiezer, and Mahlah.",
                "The sons of Shemida were Ahian, and Shechem, and Likhi, and Aniam.",
                "The sons of Ephraim: Shuthelah, and Bered his son, and Tahath his son, and Eleadah his son, and Tahath his son,",
                "and Zabad his son, and Shuthelah his son, and Ezer, and Elead, whom the men of Gath who were born in the land killed, because they came down to take away their livestock.",
                "Ephraim their father mourned many days, and his brothers came to comfort him.",
                "He went in to his wife, and she conceived, and bore a son, and he named him Beriah, because it went evil with his house.",
                "His daughter was Sheerah, who built Beth Horon the lower and the upper, and Uzzen Sheerah.",
                "Rephah was his son, and Resheph, and Telah his son, and Tahan his son,",
                "Ladan his son, Ammihud his son, Elishama his son,",
                "Nun his son, Joshua his son.",
                "Their possessions and habitations were Bethel and its towns, and eastward Naaran, and westward Gezer, with its towns; Shechem also and its towns, to Azzah and its towns;",
                "and by the borders of the children of Manasseh, Beth Shean and its towns, Taanach and its towns, Megiddo and its towns, Dor and its towns. In these lived the children of Joseph the son of Israel.",
                "The sons of Asher: Imnah, and Ishvah, and Ishvi, and Beriah, and Serah their sister.",
                "The sons of Beriah: Heber, and Malchiel, who was the father of Birzaith.",
                "Heber became the father of Japhlet, and Shomer, and Hotham, and Shua their sister.",
                "The sons of Japhlet: Pasach, and Bimhal, and Ashvath. These are the children of Japhlet.",
                "The sons of Shemer: Ahi, and Rohgah, Jehubbah, and Aram.",
                "The sons of Helem his brother: Zophah, and Imna, and Shelesh, and Amal.",
                "The sons of Zophah: Suah, and Harnepher, and Shual, and Beri, and Imrah,",
                "Bezer, and Hod, and Shamma, and Shilshah, and Ithran, and Beera.",
                "The sons of Jether: Jephunneh, and Pispa, and Ara.",
                "The sons of Ulla: Arah, and Hanniel, and Rizia.",
                "All these were the children of Asher, heads of the fathers\u2019 houses, choice and mighty men of valor, chief of the princes. The number of them reckoned by genealogy for service in war was twenty-six thousand men."
            ],
            8: [
                "Benjamin became the father of Bela his firstborn, Ashbel the second, and Aharah the third,",
                "Nohah the fourth, and Rapha the fifth.",
                "Bela had sons: Addar, and Gera, and Abihud,",
                "and Abishua, and Naaman, and Ahoah,",
                "and Gera, and Shephuphan, and Huram.",
                "These are the sons of Ehud: these are the heads of fathers\u2019 households of the inhabitants of Geba, and they carried them captive to Manahath:",
                "and Naaman, and Ahijah, and Gera, he carried them captive: and he became the father of Uzza and Ahihud.",
                "Shaharaim became the father of children in the field of Moab, after he had sent them away; Hushim and Baara were his wives.",
                "He became the father of Hodesh his wife, Jobab, and Zibia, and Mesha, and Malcam,",
                "and Jeuz, and Shachia, and Mirmah. These were his sons, heads of fathers\u2019 households.",
                "Of Hushim he became the father of Abitub and Elpaal.",
                "The sons of Elpaal: Eber, and Misham, and Shemed, who built Ono and Lod, with its towns;",
                "and Beriah, and Shema, who were heads of fathers\u2019 households of the inhabitants of Aijalon, who put to flight the inhabitants of Gath;",
                "and Ahio, Shashak, and Jeremoth,",
                "and Zebadiah, and Arad, and Eder,",
                "and Michael, and Ishpah, and Joha, the sons of Beriah,",
                "and Zebadiah, and Meshullam, and Hizki, and Heber,",
                "and Ishmerai, and Izliah, and Jobab, the sons of Elpaal,",
                "and Jakim, and Zichri, and Zabdi,",
                "and Elienai, and Zillethai, and Eliel,",
                "and Adaiah, and Beraiah, and Shimrath, the sons of Shimei,",
                "and Ishpan, and Eber, and Eliel,",
                "and Abdon, and Zichri, and Hanan,",
                "and Hananiah, and Elam, and Anthothijah,",
                "and Iphdeiah, and Penuel, the sons of Shashak,",
                "and Shamsherai, and Shehariah, and Athaliah,",
                "and Jaareshiah, and Elijah, and Zichri, the sons of Jeroham.",
                "These were heads of fathers\u2019 households throughout their generations, chief men: these lived in Jerusalem.",
                "In Gibeon there lived the father of Gibeon, whose wife\u2019s name was Maacah;",
                "and his firstborn son Abdon, and Zur, and Kish, and Baal, and Nadab,",
                "and Gedor, and Ahio, and Zecher.",
                "Mikloth became the father of Shimeah. They also lived with their brothers in Jerusalem, over against their brothers.",
                "Ner became the father of Kish; and Kish became the father of Saul; and Saul became the father of Jonathan, and Malchishua, and Abinadab, and Eshbaal.",
                "The son of Jonathan was Merib Baal; and Merib Baal became the father of Micah.",
                "The sons of Micah: Pithon, and Melech, and Tarea, and Ahaz.",
                "Ahaz became the father of Jehoaddah; and Jehoaddah became the father of Alemeth, and Azmaveth, and Zimri; and Zimri became the father of Moza.",
                "Moza became the father of Binea; Raphah was his son, Eleasah his son, Azel his son.",
                "Azel had six sons, whose names are these: Azrikam, Bocheru, and Ishmael, and Sheariah, and Obadiah, and Hanan. All these were the sons of Azel.",
                "The sons of Eshek his brother: Ulam his firstborn, Jeush the second, and Eliphelet the third.",
                "The sons of Ulam were mighty men of valor, archers, and had many sons, and sons\u2019 sons, one hundred fifty. All these were of the sons of Benjamin."
            ],
            9: [
                "So all Israel were reckoned by genealogies; and behold, they are written in the book of the kings of Israel: and Judah was carried away captive to Babylon for their disobedience.",
                "Now the first inhabitants who lived in their possessions in their cities were Israel, the priests, the Levites, and the Nethinim.",
                "In Jerusalem lived of the children of Judah, and of the children of Benjamin, and of the children of Ephraim and Manasseh:",
                "Uthai the son of Ammihud, the son of Omri, the son of Imri, the son of Bani, of the children of Perez the son of Judah.",
                "Of the Shilonites: Asaiah the firstborn, and his sons.",
                "Of the sons of Zerah: Jeuel, and their brothers, six hundred ninety.",
                "Of the sons of Benjamin: Sallu the son of Meshullam, the son of Hodaviah, the son of Hassenuah,",
                "and Ibneiah the son of Jeroham, and Elah the son of Uzzi, the son of Michri, and Meshullam the son of Shephatiah, the son of Reuel, the son of Ibnijah;",
                "and their brothers, according to their generations, nine hundred fifty-six. All these men were heads of fathers\u2019 households by their fathers\u2019 houses.",
                "Of the priests: Jedaiah, and Jehoiarib, Jachin,",
                "and Azariah the son of Hilkiah, the son of Meshullam, the son of Zadok, the son of Meraioth, the son of Ahitub, the ruler of the house of God;",
                "and Adaiah the son of Jeroham, the son of Pashhur, the son of Malchijah, and Maasai the son of Adiel, the son of Jahzerah, the son of Meshullam, the son of Meshillemith, the son of Immer;",
                "and their brothers, heads of their fathers\u2019 houses, one thousand seven hundred sixty; very able men for the work of the service of the house of God.",
                "Of the Levites: Shemaiah the son of Hasshub, the son of Azrikam, the son of Hashabiah, of the sons of Merari;",
                "and Bakbakkar, Heresh, and Galal, and Mattaniah the son of Mica, the son of Zichri, the son of Asaph,",
                "and Obadiah the son of Shemaiah, the son of Galal, the son of Jeduthun, and Berechiah the son of Asa, the son of Elkanah, who lived in the villages of the Netophathites.",
                "The porters: Shallum, and Akkub, and Talmon, and Ahiman, and their brothers (Shallum was the chief),",
                "who previously served in the king\u2019s gate eastward: they were the porters for the camp of the children of Levi.",
                "Shallum the son of Kore, the son of Ebiasaph, the son of Korah, and his brothers, of his father\u2019s house, the Korahites, were over the work of the service, keepers of the thresholds of the tent: and their fathers had been over the camp of Yahweh, keepers of the entry.",
                "Phinehas the son of Eleazar was ruler over them in time past, and Yahweh was with him.",
                "Zechariah the son of Meshelemiah was porter of the door of the Tent of Meeting.",
                "All these who were chosen to be porters in the thresholds were two hundred and twelve. These were reckoned by genealogy in their villages, whom David and Samuel the seer ordained in their office of trust.",
                "So they and their children had the oversight of the gates of the house of Yahweh, even the house of the tent, by wards.",
                "On the four sides were the porters, toward the east, west, north, and south.",
                "Their brothers, in their villages, were to come in every seven days from time to time to be with them:",
                "for the four chief porters, who were Levites, were in an office of trust, and were over the rooms and over the treasuries in the house of God.",
                "They lodged around the house of God, because that duty was on them; and to them pertained its opening morning by morning.",
                "Certain of them were in charge of the vessels of service; for by count were these brought in and by count were these taken out.",
                "Some of them also were appointed over the furniture, and over all the vessels of the sanctuary, and over the fine flour, and the wine, and the oil, and the frankincense, and the spices.",
                "Some of the sons of the priests prepared the confection of the spices.",
                "Mattithiah, one of the Levites, who was the firstborn of Shallum the Korahite, had the office of trust over the things that were baked in pans.",
                "Some of their brothers, of the sons of the Kohathites, were over the show bread, to prepare it every Sabbath.",
                "These are the singers, heads of fathers\u2019 households of the Levites, who lived in the rooms and were free from other service; for they were employed in their work day and night.",
                "These were heads of fathers\u2019 households of the Levites, throughout their generations, chief men: these lived at Jerusalem.",
                "In Gibeon there lived the father of Gibeon, Jeiel, whose wife\u2019s name was Maacah:",
                "and his firstborn son Abdon, and Zur, and Kish, and Baal, and Ner, and Nadab,",
                "and Gedor, and Ahio, and Zechariah, and Mikloth.",
                "Mikloth became the father of Shimeam. They also lived with their brothers in Jerusalem, over against their brothers.",
                "Ner became the father of Kish; and Kish became the father of Saul; and Saul became the father of Jonathan, and Malchishua, and Abinadab, and Eshbaal.",
                "The son of Jonathan was Merib Baal; and Merib Baal became the father of Micah.",
                "The sons of Micah: Pithon, and Melech, and Tahrea, and Ahaz.",
                "Ahaz became the father of Jarah; and Jarah became the father of Alemeth, and Azmaveth, and Zimri; and Zimri became the father of Moza;",
                "and Moza became the father of Binea; and Rephaiah his son, Eleasah his son, Azel his son.",
                "Azel had six sons, whose names are these: Azrikam, Bocheru, and Ishmael, and Sheariah, and Obadiah, and Hanan: these were the sons of Azel."
            ],
            10: [
                "Now the Philistines fought against Israel: and the men of Israel fled from before the Philistines, and fell down slain on Mount Gilboa.",
                "The Philistines followed hard after Saul and after his sons; and the Philistines killed Jonathan, and Abinadab, and Malchishua, the sons of Saul.",
                "The battle went hard against Saul, and the archers overtook him; and he was distressed by reason of the archers.",
                "Then Saul said to his armor bearer, \"Draw your sword, and thrust me through with it, lest these uncircumcised come and abuse me.\" But his armor bearer would not; for he was terrified. Therefore Saul took his sword, and fell on it.",
                "When his armor bearer saw that Saul was dead, he likewise fell on his sword, and died.",
                "So Saul died, and his three sons; and all his house died together.",
                "When all the men of Israel who were in the valley saw that they fled, and that Saul and his sons were dead, they forsook their cities, and fled; and the Philistines came and lived in them.",
                "It happened on the next day, when the Philistines came to strip the slain, that they found Saul and his sons fallen on Mount Gilboa.",
                "They stripped him, and took his head, and his armor, and sent into the land of the Philistines all around, to carry the news to their idols, and to the people.",
                "They put his armor in the house of their gods, and fastened his head in the house of Dagon.",
                "When all Jabesh Gilead heard all that the Philistines had done to Saul,",
                "all the valiant men arose, and took away the body of Saul, and the bodies of his sons, and brought them to Jabesh, and buried their bones under the oak in Jabesh, and fasted seven days.",
                "So Saul died for his trespass which he committed against Yahweh, because of the word of Yahweh, which he didn\u2019t keep; and also because he asked counsel of one who had a familiar spirit, to inquire,",
                "and didn\u2019t inquire of Yahweh: therefore he killed him, and turned the kingdom to David the son of Jesse."
            ],
            11: [
                "Then all Israel gathered themselves to David to Hebron, saying, \"Behold, we are your bone and your flesh.",
                "In times past, even when Saul was king, it was you who led out and brought in Israel. Yahweh your God said to you, \u2018You shall be shepherd of my people Israel, and you shall be prince over my people Israel.\u2019\"",
                "So all the elders of Israel came to the king to Hebron; and David made a covenant with them in Hebron before Yahweh; and they anointed David king over Israel, according to the word of Yahweh by Samuel.",
                "David and all Israel went to Jerusalem (the same is Jebus); and the Jebusites, the inhabitants of the land, were there.",
                "The inhabitants of Jebus said to David, \"You shall not come in here.\" Nevertheless David took the stronghold of Zion. The same is the city of David.",
                "David said, \"Whoever strikes the Jebusites first shall be chief and captain.\" Joab the son of Zeruiah went up first, and was made chief.",
                "David lived in the stronghold; therefore they called it the city of David.",
                "He built the city all around, from Millo even around; and Joab repaired the rest of the city.",
                "David grew greater and greater; for Yahweh of Armies was with him.",
                "Now these are the chief of the mighty men whom David had, who showed themselves strong with him in his kingdom, together with all Israel, to make him king, according to the word of Yahweh concerning Israel.",
                "This is the number of the mighty men whom David had: Jashobeam, the son of a Hachmonite, the chief of the thirty; he lifted up his spear against three hundred and killed them at one time.",
                "After him was Eleazar the son of Dodo, the Ahohite, who was one of the three mighty men.",
                "He was with David at Pasdammim, and there the Philistines were gathered together to battle, where there was a plot of ground full of barley; and the people fled from before the Philistines.",
                "They stood in the midst of the plot, and defended it, and killed the Philistines; and Yahweh saved them by a great victory.",
                "Three of the thirty chief men went down to the rock to David, into the cave of Adullam; and the army of the Philistines were encamped in the valley of Rephaim.",
                "David was then in the stronghold, and the garrison of the Philistines was then in Bethlehem.",
                "David longed, and said, \"Oh that one would give me water to drink of the well of Bethlehem, which is by the gate!\"",
                "The three broke through the army of the Philistines, and drew water out of the well of Bethlehem, that was by the gate, and took it, and brought it to David: but David would not drink of it, but poured it out to Yahweh,",
                "and said, \"My God forbid it me, that I should do this! Shall I drink the blood of these men who have put their lives in jeopardy?\" For they risked their lives to bring it. Therefore he would not drink it. The three mighty men did these things.",
                "Abishai, the brother of Joab, he was chief of the three; for he lifted up his spear against three hundred and killed them, and had a name among the three.",
                "Of the three, he was more honorable than the two, and was made their captain: however he didn\u2019t attain to the three.",
                "Benaiah the son of Jehoiada, the son of a valiant man of Kabzeel, who had done mighty deeds, he killed the two sons of Ariel of Moab: he went down also and killed a lion in the midst of a pit in time of snow.",
                "He killed an Egyptian, a man of great stature, five cubits high; and in the Egyptian\u2019s hand was a spear like a weaver\u2019s beam; and he went down to him with a staff, and plucked the spear out of the Egyptian\u2019s hand, and killed him with his own spear.",
                "Benaiah the son of Jehoiada did these things, and had a name among the three mighty men.",
                "Behold, he was more honorable than the thirty, but he didn\u2019t attain to the three: and David set him over his guard.",
                "Also the mighty men of the armies: Asahel the brother of Joab, Elhanan the son of Dodo of Bethlehem,",
                "Shammoth the Harorite, Helez the Pelonite,",
                "Ira the son of Ikkesh the Tekoite, Abiezer the Anathothite,",
                "Sibbecai the Hushathite, Ilai the Ahohite,",
                "Maharai the Netophathite, Heled the son of Baanah the Netophathite,",
                "Ithai the son of Ribai of Gibeah of the children of Benjamin, Benaiah the Pirathonite,",
                "Hurai of the brooks of Gaash, Abiel the Arbathite,",
                "Azmaveth the Baharumite, Eliahba the Shaalbonite,",
                "the sons of Hashem the Gizonite, Jonathan the son of Shagee the Hararite,",
                "Ahiam the son of Sacar the Hararite, Eliphal the son of Ur,",
                "Hepher the Mecherathite, Ahijah the Pelonite,",
                "Hezro the Carmelite, Naarai the son of Ezbai,",
                "Joel the brother of Nathan, Mibhar the son of Hagri,",
                "Zelek the Ammonite, Naharai the Berothite, the armor bearer of Joab the son of Zeruiah,",
                "Ira the Ithrite, Gareb the Ithrite,",
                "Uriah the Hittite, Zabad the son of Ahlai,",
                "Adina the son of Shiza the Reubenite, a chief of the Reubenites, and thirty with him,",
                "Hanan the son of Maacah, and Joshaphat the Mithnite,",
                "Uzzia the Ashterathite, Shama and Jeiel the sons of Hotham the Aroerite,",
                "Jediael the son of Shimri, and Joha his brother, the Tizite,",
                "Eliel the Mahavite, and Jeribai, and Joshaviah, the sons of Elnaam, and Ithmah the Moabite,",
                "Eliel, and Obed, and Jaasiel the Mezobaite."
            ],
            12: [
                "Now these are those who came to David to Ziklag, while he yet kept himself close because of Saul the son of Kish; and they were among the mighty men, his helpers in war.",
                "They were armed with bows, and could use both the right hand and the left in slinging stones and in shooting arrows from the bow: they were of Saul\u2019s brothers of Benjamin.",
                "The chief was Ahiezer; then Joash, the sons of Shemaah the Gibeathite, and Jeziel, and Pelet, the sons of Azmaveth, and Beracah, and Jehu the Anathothite,",
                "and Ishmaiah the Gibeonite, a mighty man among the thirty, and over the thirty, and Jeremiah, and Jahaziel, and Johanan, and Jozabad the Gederathite,",
                "Eluzai, and Jerimoth, and Bealiah, and Shemariah, and Shephatiah the Haruphite,",
                "Elkanah, and Isshiah, and Azarel, and Joezer, and Jashobeam, the Korahites,",
                "and Joelah, and Zebadiah, the sons of Jeroham of Gedor.",
                "Of the Gadites there separated themselves to David to the stronghold in the wilderness, mighty men of valor, men trained for war, that could handle shield and spear; whose faces were like the faces of lions, and they were as swift as the roes on the mountains;",
                "Ezer the chief, Obadiah the second, Eliab the third,",
                "Mishmannah the fourth, Jeremiah the fifth,",
                "Attai the sixth, Eliel the seventh,",
                "Johanan the eighth, Elzabad the ninth,",
                "Jeremiah the tenth, Machbannai the eleventh.",
                "These of the sons of Gad were captains of the army: he who was least was equal to one hundred, and the greatest to one thousand.",
                "These are those who went over the Jordan in the first month, when it had overflowed all its banks; and they put to flight all them of the valleys, both toward the east, and toward the west.",
                "There came of the children of Benjamin and Judah to the stronghold to David.",
                "David went out to meet them, and answered them, \"If you have come peaceably to me to help me, my heart shall be knit to you; but if you have come to betray me to my adversaries, since there is no wrong in my hands, may the God of our fathers look thereon, and rebuke it.\"",
                "Then the Spirit came on Amasai, who was chief of the thirty, and he said, \"We are yours, David, and on your side, you son of Jesse: peace, peace be to you, and peace be to your helpers; for your God helps you.\" Then David received them, and made them captains of the band.",
                "Of Manasseh also there fell away some to David, when he came with the Philistines against Saul to battle; but they didn\u2019t help them; for the lords of the Philistines sent him away after consultation, saying, \"He will fall away to his master Saul to the jeopardy of our heads.\"",
                "As he went to Ziklag, there fell to him of Manasseh, Adnah, and Jozabad, and Jediael, and Michael, and Jozabad, and Elihu, and Zillethai, captains of thousands who were of Manasseh.",
                "They helped David against the band of rovers: for they were all mighty men of valor, and were captains in the army.",
                "For from day to day men came to David to help him, until there was a great army, like the army of God.",
                "These are the numbers of the heads of those who were armed for war, who came to David to Hebron, to turn the kingdom of Saul to him, according to the word of Yahweh.",
                "The children of Judah who bore shield and spear were six thousand and eight hundred, armed for war.",
                "Of the children of Simeon, mighty men of valor for the war, seven thousand and one hundred.",
                "Of the children of Levi four thousand and six hundred.",
                "Jehoiada was the leader of the household of Aaron; and with him were three thousand and seven hundred,",
                "and Zadok, a young man mighty of valor, and of his father\u2019s house twenty-two captains.",
                "Of the children of Benjamin, the brothers of Saul, three thousand: for hitherto the greatest part of them had kept their allegiance to the house of Saul.",
                "Of the children of Ephraim twenty thousand eight hundred, mighty men of valor, famous men in their fathers\u2019 houses.",
                "Of the half-tribe of Manasseh eighteen thousand, who were mentioned by name, to come and make David king.",
                "Of the children of Issachar, men who had understanding of the times, to know what Israel ought to do, their heads were two hundred; and all their brothers were at their commandment.",
                "Of Zebulun, such as were able to go out in the army, who could set the battle in array, with all kinds of instruments of war, fifty thousand, and who could command and were not of double heart.",
                "Of Naphtali one thousand captains, and with them with shield and spear thirty-seven thousand.",
                "Of the Danites who could set the battle in array, twenty-eight thousand six hundred.",
                "Of Asher, such as were able to go out in the army, who could set the battle in array, forty thousand.",
                "On the other side of the Jordan, of the Reubenites, and the Gadites, and of the half-tribe of Manasseh, with all kinds of instruments of war for the battle, one hundred twenty thousand.",
                "All these being men of war, who could order the battle array, came with a perfect heart to Hebron, to make David king over all Israel: and all the rest also of Israel were of one heart to make David king.",
                "They were there with David three days, eating and drinking; for their brothers had made preparation for them.",
                "Moreover those who were near to them, as far as Issachar and Zebulun and Naphtali, brought bread on donkeys, and on camels, and on mules, and on oxen, food of meal, cakes of figs, and clusters of raisins, and wine, and oil, and cattle, and sheep in abundance: for there was joy in Israel."
            ],
            13: [
                "David consulted with the captains of thousands and of hundreds, even with every leader.",
                "David said to all the assembly of Israel, \"If it seems good to you, and if it is of Yahweh our God, let us send abroad everywhere to our brothers who are left in all the land of Israel, with whom the priests and Levites are in their cities that have suburbs, that they may gather themselves to us;",
                "and let us bring again the ark of our God to us. For we didn\u2019t seek it in the days of Saul.\"",
                "All the assembly said that they would do so; for the thing was right in the eyes of all the people.",
                "So David assembled all Israel together, from the Shihor the brook of Egypt even to the entrance of Hamath, to bring the ark of God from Kiriath Jearim.",
                "David went up, and all Israel, to Baalah, that is, to Kiriath Jearim, which belonged to Judah, to bring up from there the ark of God Yahweh that sits above the cherubim, that is called by the Name.",
                "They carried the ark of God on a new cart, and brought it out of the house of Abinadab: and Uzza and Ahio drove the cart.",
                "David and all Israel played before God with all their might, even with songs, and with harps, and with stringed instruments, and with tambourines, and with cymbals, and with trumpets.",
                "When they came to the threshing floor of Chidon, Uzza put forth his hand to hold the ark; for the oxen stumbled.",
                "The anger of Yahweh was kindled against Uzza, and he struck him, because he put forth his hand to the ark; and there he died before God.",
                "David was displeased, because Yahweh had broken forth on Uzza; and he called that place Perez Uzza, to this day.",
                "David was afraid of God that day, saying, \"How shall I bring the ark of God home to me?\"",
                "So David didn\u2019t move the ark to him into the city of David, but carried it aside into the house of Obed-Edom the Gittite.",
                "The ark of God remained with the family of Obed-Edom in his house three months: and Yahweh blessed the house of Obed-Edom, and all that he had."
            ],
            14: [
                "Hiram king of Tyre sent messengers to David, and cedar trees, and masons, and carpenters, to build him a house.",
                "David perceived that Yahweh had established him king over Israel; for his kingdom was exalted on high, for his people Israel\u2019s sake.",
                "David took more wives at Jerusalem; and David became the father of more sons and daughters.",
                "These are the names of the children whom he had in Jerusalem: Shammua, and Shobab, Nathan, and Solomon,",
                "and Ibhar, and Elishua, and Elpelet,",
                "and Nogah, and Nepheg, and Japhia,",
                "and Elishama, and Beeliada, and Eliphelet.",
                "When the Philistines heard that David was anointed king over all Israel, all the Philistines went up to seek David: and David heard of it, and went out against them.",
                "Now the Philistines had come and made a raid in the valley of Rephaim.",
                "David inquired of God, saying, \"Shall I go up against the Philistines? Will you deliver them into my hand?\" Yahweh said to him, \"Go up; for I will deliver them into your hand.\"",
                "So they came up to Baal Perazim, and David struck them there; and David said, God has broken my enemies by my hand, like the breach of waters. Therefore they called the name of that place Baal Perazim.",
                "They left their gods there; and David gave commandment, and they were burned with fire.",
                "The Philistines yet again made a raid in the valley.",
                "David inquired again of God; and God said to him, \"You shall not go up after them. Turn away from them, and come on them over against the mulberry trees.",
                "It shall be, when you hear the sound of marching in the tops of the mulberry trees, that then you shall go out to battle; for God has gone out before you to strike the army of the Philistines.\"",
                "David did as God commanded him: and they struck the army of the Philistines from Gibeon even to Gezer.",
                "The fame of David went out into all lands; and Yahweh brought the fear of him on all nations."
            ],
            15: [
                "David made him houses in the city of David; and he prepared a place for the ark of God, and pitched for it a tent.",
                "Then David said, \"No one ought to carry the ark of God but the Levites. For Yahweh has chosen them to carry the ark of God, and to minister to him forever.\"",
                "David assembled all Israel at Jerusalem, to bring up the ark of Yahweh to its place, which he had prepared for it.",
                "David gathered together the sons of Aaron, and the Levites:",
                "of the sons of Kohath, Uriel the chief, and his brothers one hundred twenty;",
                "of the sons of Merari, Asaiah the chief, and his brothers two hundred twenty;",
                "of the sons of Gershom, Joel the chief, and his brothers one hundred thirty;",
                "of the sons of Elizaphan, Shemaiah the chief, and his brothers two hundred;",
                "of the sons of Hebron, Eliel the chief, and his brothers eighty;",
                "of the sons of Uzziel, Amminadab the chief, and his brothers one hundred twelve.",
                "David called for Zadok and Abiathar the priests, and for the Levites, for Uriel, Asaiah, and Joel, Shemaiah, and Eliel, and Amminadab,",
                "and said to them, \"You are the heads of the fathers\u2019 households of the Levites. Sanctify yourselves, both you and your brothers, that you may bring up the ark of Yahweh, the God of Israel, to the place that I have prepared for it.",
                "For because you didn\u2019t carry it at first, Yahweh our God made broke out against us, because we didn\u2019t seek him according to the ordinance.\"",
                "So the priests and the Levites sanctified themselves to bring up the ark of Yahweh, the God of Israel.",
                "The children of the Levites bore the ark of God on their shoulders with the poles thereon, as Moses commanded according to the word of Yahweh.",
                "David spoke to the chief of the Levites to appoint their brothers the singers, with instruments of music, stringed instruments and harps and cymbals, sounding aloud and lifting up the voice with joy.",
                "So the Levites appointed Heman the son of Joel; and of his brothers, Asaph the son of Berechiah; and of the sons of Merari their brothers, Ethan the son of Kushaiah;",
                "and with them their brothers of the second degree, Zechariah, Ben, and Jaaziel, and Shemiramoth, and Jehiel, and Unni, Eliab, and Benaiah, and Maaseiah, and Mattithiah, and Eliphelehu, and Mikneiah, and Obed-Edom, and Jeiel, the doorkeepers.",
                "So the singers, Heman, Asaph, and Ethan, were given cymbals of brass to sound aloud;",
                "and Zechariah, and Aziel, and Shemiramoth, and Jehiel, and Unni, and Eliab, and Maaseiah, and Benaiah, with stringed instruments set to Alamoth;",
                "and Mattithiah, and Eliphelehu, and Mikneiah, and Obed-Edom, and Jeiel, and Azaziah, with harps tuned to the eight-stringed lyre, to lead.",
                "Chenaniah, chief of the Levites, was over the song: he instructed about the song, because he was skillful.",
                "Berechiah and Elkanah were doorkeepers for the ark.",
                "Shebaniah, and Joshaphat, and Nethanel, and Amasai, and Zechariah, and Benaiah, and Eliezer, the priests, blew the trumpets before the ark of God: and Obed-Edom and Jehiah were doorkeepers for the ark.",
                "So David, and the elders of Israel, and the captains over thousands, went to bring up the ark of the covenant of Yahweh out of the house of Obed-Edom with joy.",
                "It happened, when God helped the Levites who bore the ark of the covenant of Yahweh, that they sacrificed seven bulls and seven rams.",
                "David was clothed with a robe of fine linen, and all the Levites who bore the ark, and the singers, and Chenaniah the master of the song with the singers: and David had on him an ephod of linen.",
                "Thus all Israel brought up the ark of the covenant of Yahweh with shouting, and with sound of the cornet, and with trumpets, and with cymbals, sounding aloud with stringed instruments and harps.",
                "It happened, as the ark of the covenant of Yahweh came to the city of David, that Michal the daughter of Saul looked out at the window, and saw king David dancing and playing; and she despised him in her heart."
            ],
            16: [
                "They brought in the ark of God, and set it in the midst of the tent that David had pitched for it: and they offered burnt offerings and peace offerings before God.",
                "When David had made an end of offering the burnt offering and the peace offerings, he blessed the people in the name of Yahweh.",
                "He dealt to everyone of Israel, both man and woman, to every one a loaf of bread, and a portion of meat, and a cake of raisins.",
                "He appointed certain of the Levites to minister before the ark of Yahweh, and to celebrate and to thank and praise Yahweh, the God of Israel:",
                "Asaph the chief, and second to him Zechariah, Jeiel, and Shemiramoth, and Jehiel, and Mattithiah, and Eliab, and Benaiah, and Obed-Edom, and Jeiel, with stringed instruments and with harps; and Asaph with cymbals, sounding aloud;",
                "and Benaiah and Jahaziel the priests with trumpets continually, before the ark of the covenant of God.",
                "Then on that day David first ordained to give thanks to Yahweh, by the hand of Asaph and his brothers.",
                "Oh give thanks to Yahweh. Call on his name. Make his doings known among the peoples.",
                "Sing to him. Sing praises to him. Tell of all his marvelous works.",
                "Glory in his holy name. Let the heart of those who seek Yahweh rejoice.",
                "Seek Yahweh and his strength. Seek his face forever more.",
                "Remember his marvelous works that he has done, his wonders, and the judgments of his mouth,",
                "you seed of Israel his servant, you children of Jacob, his chosen ones.",
                "He is Yahweh our God. His judgments are in all the earth.",
                "Remember his covenant forever, the word which he commanded to a thousand generations,",
                "the covenant which he made with Abraham, his oath to Isaac.",
                "He confirmed the same to Jacob for a statute, and to Israel for an everlasting covenant,",
                "saying, \"I will give you the land of Canaan, The lot of your inheritance,\"",
                "when you were but a few men in number, yes, very few, and foreigners were in it.",
                "They went about from nation to nation, from one kingdom to another people.",
                "He allowed no man to do them wrong. Yes, he reproved kings for their sakes,",
                "\"Don\u2019t touch my anointed ones! Do my prophets no harm!\"",
                "Sing to Yahweh, all the earth! Display his salvation from day to day.",
                "Declare his glory among the nations, and his marvelous works among all the peoples.",
                "For great is Yahweh, and greatly to be praised. He also is to be feared above all gods.",
                "For all the gods of the peoples are idols, but Yahweh made the heavens.",
                "Honor and majesty are before him. Strength and gladness are in his place.",
                "Ascribe to Yahweh, you relatives of the peoples, ascribe to Yahweh glory and strength!",
                "Ascribe to Yahweh the glory due to his name. Bring an offering, and come before him. Worship Yahweh in holy array.",
                "Tremble before him, all the earth. The world also is established that it can\u2019t be moved.",
                "Let the heavens be glad, and let the earth rejoice! Let them say among the nations, \"Yahweh reigns!\"",
                "Let the sea roar, and its fullness! Let the field exult, and all that is therein!",
                "Then the trees of the forest will sing for joy before Yahweh, for he comes to judge the earth.",
                "Oh give thanks to Yahweh, for he is good, for his loving kindness endures forever.",
                "Say, \"Save us, God of our salvation! Gather us together and deliver us from the nations, to give thanks to your holy name, to triumph in your praise.\"",
                "Blessed be Yahweh, the God of Israel, from everlasting even to everlasting. All the people said, \"Amen,\" and praised Yahweh.",
                "So he left there, before the ark of the covenant of Yahweh, Asaph and his brothers, to minister before the ark continually, as every day\u2019s work required;",
                "and Obed-Edom with their brothers, sixty-eight; Obed-Edom also the son of Jeduthun and Hosah to be doorkeepers;",
                "and Zadok the priest, and his brothers the priests, before the tabernacle of Yahweh in the high place that was at Gibeon,",
                "to offer burnt offerings to Yahweh on the altar of burnt offering continually morning and evening, even according to all that is written in the law of Yahweh, which he commanded to Israel;",
                "and with them Heman and Jeduthun, and the rest who were chosen, who were mentioned by name, to give thanks to Yahweh, because his loving kindness endures forever;",
                "and with them Heman and Jeduthun with trumpets and cymbals for those that should sound aloud, and with instruments for the songs of God; and the sons of Jeduthun to be at the gate.",
                "All the people departed every man to his house: and David returned to bless his house."
            ],
            17: [
                "It happened, when David lived in his house, that David said to Nathan the prophet, \"Behold, I dwell in a house of cedar, but the ark of the covenant of Yahweh is under curtains.\"",
                "Nathan said to David, \"Do all that is in your heart; for God is with you.\"",
                "It happened the same night, that the word of God came to Nathan, saying,",
                "\"Go and tell David my servant, \u2018Thus says Yahweh, \"You shall not build me a house to dwell in;",
                "for I have not lived in a house since the day that I brought up Israel, to this day, but have gone from tent to tent, and from one tent to another.",
                "In all places in which I have walked with all Israel, did I speak a word with any of the judges of Israel, whom I commanded to be shepherd of my people, saying, \u2018Why have you not built me a house of cedar?\u2019\"\u2019",
                "\"Now therefore, you shall tell my servant David, \u2018Thus says Yahweh of Armies, \"I took you from the sheep pen, from following the sheep, that you should be prince over my people Israel.",
                "I have been with you wherever you have gone, and have cut off all your enemies from before you. I will make you a name, like the name of the great ones who are in the earth.",
                "I will appoint a place for my people Israel, and will plant them, that they may dwell in their own place, and be moved no more; neither shall the children of wickedness waste them any more, as at the first,",
                "and from the day that I commanded judges to be over my people Israel; and I will subdue all your enemies. Moreover I tell you that Yahweh will build you a house.",
                "It shall happen, when your days are fulfilled that you must go to be with your fathers, that I will set up your seed after you, who shall be of your sons; and I will establish his kingdom.",
                "He shall build me a house, and I will establish his throne forever.",
                "I will be his father, and he shall be my son. I will not take my loving kindness away from him, as I took it from him that was before you;",
                "but I will settle him in my house and in my kingdom forever. His throne shall be established forever.\"\u2019\"",
                "According to all these words, and according to all this vision, so Nathan spoke to David.",
                "Then David the king went in, and sat before Yahweh; and he said, \"Who am I, Yahweh God, and what is my house, that you have brought me thus far?",
                "This was a small thing in your eyes, God; but you have spoken of your servant\u2019s house for a great while to come, and have respected me according to the estate of a man of high degree, Yahweh God.",
                "What can David say yet more to you concerning the honor which is done to your servant? For you know your servant.",
                "Yahweh, for your servant\u2019s sake, and according to your own heart, you have worked all this greatness, to make known all these great things.",
                "Yahweh, there is none like you, neither is there any God besides you, according to all that we have heard with our ears.",
                "What one nation in the earth is like your people Israel, whom God went to redeem to himself for a people, to make you a name by great and awesome things, in driving out nations from before your people, whom you redeem out of Egypt?",
                "For your people Israel you made your own people forever; and you, Yahweh, became their God.",
                "Now, Yahweh, let the word that you have spoken concerning your servant, and concerning his house, be established forever, and do as you have spoken.",
                "Let your name be established and magnified forever, saying, \u2018Yahweh of Armies is the God of Israel, even a God to Israel. The house of David your servant is established before you.\u2019",
                "For you, my God, have revealed to your servant that you will build him a house. Therefore your servant has found courage to pray before you.",
                "Now, Yahweh, you are God, and have promised this good thing to your servant.",
                "Now it has pleased you to bless the house of your servant, that it may continue forever before you; for you, Yahweh, have blessed, and it is blessed forever.\""
            ],
            18: [
                "After this it happened, that David struck the Philistines, and subdued them, and took Gath and its towns out of the hand of the Philistines.",
                "He struck Moab; and the Moabites became servants to David, and brought tribute.",
                "David struck Hadadezer king of Zobah to Hamath, as he went to establish his dominion by the river Euphrates.",
                "David took from him one thousand chariots, and seven thousand horsemen, and twenty thousand footmen; and David hamstrung all the chariot horses, but reserved of them for one hundred chariots.",
                "When the Syrians of Damascus came to help Hadadezer king of Zobah, David struck of the Syrians twenty-two thousand men.",
                "Then David put garrisons in Syria of Damascus; and the Syrians became servants to David, and brought tribute. Yahweh gave victory to David wherever he went.",
                "David took the shields of gold that were on the servants of Hadadezer, and brought them to Jerusalem.",
                "From Tibhath and from Cun, cities of Hadadezer, David took very much brass, with which Solomon made the bronze sea, and the pillars, and the vessels of brass.",
                "When Tou king of Hamath heard that David had struck all the army of Hadadezer king of Zobah,",
                "he sent Hadoram his son to king David, to Greet him, and to bless him, because he had fought against Hadadezer and struck him; (for Hadadezer had wars with Tou;) and he had with him all kinds of vessels of gold and silver and brass.",
                "King David also dedicated these to Yahweh, with the silver and the gold that he carried away from all the nations; from Edom, and from Moab, and from the children of Ammon, and from the Philistines, and from Amalek.",
                "Moreover Abishai the son of Zeruiah struck of the Edomites in the Valley of Salt eighteen thousand.",
                "He put garrisons in Edom; and all the Edomites became servants to David. Yahweh gave victory to David wherever he went.",
                "David reigned over all Israel; and he executed justice and righteousness to all his people.",
                "Joab the son of Zeruiah was over the army; and Jehoshaphat the son of Ahilud was recorder;",
                "and Zadok the son of Ahitub, and Abimelech the son of Abiathar, were priests; and Shavsha was scribe;",
                "and Benaiah the son of Jehoiada was over the Cherethites and the Pelethites; and the sons of David were chief about the king."
            ],
            19: [
                "It happened after this, that Nahash the king of the children of Ammon died, and his son reigned in his place.",
                "David said, \"I will show kindness to Hanun the son of Nahash, because his father showed kindness to me.\" So David sent messengers to comfort him concerning his father. David\u2019s servants came into the land of the children of Ammon to Hanun, to comfort him.",
                "But the princes of the children of Ammon said to Hanun, \"Do you think that David honors your father, in that he has sent comforters to you? Haven\u2019t his servants come to you to search, to overthrow, and to spy out the land?\"",
                "So Hanun took David\u2019s servants, and shaved them, and cut off their garments in the middle, even to their buttocks, and sent them away.",
                "Then there went certain persons, and told David how the men were served. He sent to meet them; for the men were greatly ashamed. The king said, \"Stay at Jericho until your beards have grown, and then return.\"",
                "When the children of Ammon saw that they had made themselves odious to David, Hanun and the children of Ammon sent one thousand talents of silver to hire them chariots and horsemen out of Mesopotamia, and out of Arammaacah, and out of Zobah.",
                "So they hired for themselves thirty-two thousand chariots, and the king of Maacah and his people, who came and encamped before Medeba. The children of Ammon gathered themselves together from their cities, and came to battle.",
                "When David heard of it, he sent Joab, and all the army of the mighty men.",
                "The children of Ammon came out, and put the battle in array at the gate of the city: and the kings who had come were by themselves in the field.",
                "Now when Joab saw that the battle was set against him before and behind, he chose of all the choice men of Israel, and put them in array against the Syrians.",
                "The rest of the people he committed into the hand of Abishai his brother; and they put themselves in array against the children of Ammon.",
                "He said, \"If the Syrians are too strong for me, then you are to help me; but if the children of Ammon are too strong for you, then I will help you.",
                "Be courageous, and let us be strong for our people, and for the cities of our God. May Yahweh do that which seems good to him.\"",
                "So Joab and the people who were with him drew near before the Syrians to the battle; and they fled before him.",
                "When the children of Ammon saw that the Syrians had fled, they likewise fled before Abishai his brother, and entered into the city. Then Joab came to Jerusalem.",
                "When the Syrians saw that they were defeated by Israel, they sent messengers, and drew forth the Syrians who were beyond the River, with Shophach the captain of the army of Hadadezer at their head.",
                "It was told David; and he gathered all Israel together, and passed over the Jordan, and came on them, and set the battle in array against them. So when David had put the battle in array against the Syrians, they fought with him.",
                "The Syrians fled before Israel; and David killed of the Syrians the men of seven thousand chariots, and forty thousand footmen, and killed Shophach the captain of the army.",
                "When the servants of Hadadezer saw that they were defeated by Israel, they made peace with David, and served him: neither would the Syrians help the children of Ammon any more."
            ],
            20: [
                "It happened, at the time of the return of the year, at the time when kings go out, that Joab led forth the army, and wasted the country of the children of Ammon, and came and besieged Rabbah. But David stayed at Jerusalem. Joab struck Rabbah, and overthrew it.",
                "David took the crown of their king from off his head, and found it to weigh a talent of gold, and there were precious stones in it; and it was set on David\u2019s head: and he brought forth the spoil of the city, exceeding much.",
                "He brought forth the people who were therein, and cut them with saws, and with iron picks, and with axes. David did so to all the cities of the children of Ammon. David and all the people returned to Jerusalem.",
                "It happened after this, that there arose war at Gezer with the Philistines: then Sibbecai the Hushathite killed Sippai, of the sons of the giant; and they were subdued.",
                "There was again war with the Philistines; and Elhanan the son of Jair killed Lahmi the brother of Goliath the Gittite, the staff of whose spear was like a weaver\u2019s beam.",
                "There was again war at Gath, where there was a man of great stature, whose fingers and toes were twenty-four, six on each hand, and six on each foot; and he also was born to the giant.",
                "When he defied Israel, Jonathan the son of Shimea David\u2019s brother killed him.",
                "These were born to the giant in Gath; and they fell by the hand of David, and by the hand of his servants."
            ],
            21: [
                "Satan stood up against Israel, and moved David to number Israel.",
                "David said to Joab and to the princes of the people, \"Go, number Israel from Beersheba even to Dan; and bring me word, that I may know the sum of them.\"",
                "Joab said, \"May Yahweh make his people a hundred times as many as they are. But, my lord the king, aren\u2019t they all my lord\u2019s servants? Why does my lord require this thing? Why will he be a cause of guilt to Israel?\"",
                "Nevertheless the king\u2019s word prevailed against Joab. Therefore Joab departed, and went throughout all Israel, and came to Jerusalem.",
                "Joab gave up the sum of the numbering of the people to David. All those of Israel were one million one hundred thousand men who drew sword: and in Judah were four hundred seventy thousand men who drew sword.",
                "But he didn\u2019t count Levi and Benjamin among them; for the king\u2019s word was abominable to Joab.",
                "God was displeased with this thing; therefore he struck Israel.",
                "David said to God, \"I have sinned greatly, in that I have done this thing. But now, put away, I beg you, the iniquity of your servant; for I have done very foolishly.\"",
                "Yahweh spoke to Gad, David\u2019s seer, saying,",
                "\"Go and speak to David, saying, \u2018Thus says Yahweh, \"I offer you three things. Choose one of them, that I may do it to you.\"\u2019\"",
                "So Gad came to David, and said to him, \"Thus says Yahweh, \u2018Take your choice:",
                "either three years of famine; or three months to be consumed before your foes, while the sword of your enemies overtakes you; or else three days the sword of Yahweh, even pestilence in the land, and the angel of Yahweh destroying throughout all the borders of Israel. Now therefore consider what answer I shall return to him who sent me.\u2019\"",
                "David said to Gad, \"I am in distress. Let me fall, I pray, into the hand of Yahweh; for his mercies are very great. Let me not fall into the hand of man.\"",
                "So Yahweh sent a pestilence on Israel; and seventy thousand men of Israel fell.",
                "God sent an angel to Jerusalem to destroy it. As he was about to destroy, Yahweh saw, and he relented of the disaster, and said to the destroying angel, \"It is enough; now stay your hand.\" The angel of Yahweh was standing by the threshing floor of Ornan the Jebusite.",
                "David lifted up his eyes, and saw the angel of Yahweh standing between earth and the sky, having a drawn sword in his hand stretched out over Jerusalem. Then David and the elders, clothed in sackcloth, fell on their faces.",
                "David said to God, \"Isn\u2019t it I who commanded the people to be numbered? It is even I who have sinned and done very wickedly; but these sheep, what have they done? Please let your hand, O Yahweh my God, be against me, and against my father\u2019s house; but not against your people, that they should be plagued.\"",
                "Then the angel of Yahweh commanded Gad to tell David that David should go up, and raise an altar to Yahweh in the threshing floor of Ornan the Jebusite.",
                "David went up at the saying of Gad, which he spoke in the name of Yahweh.",
                "Ornan turned back, and saw the angel; and his four sons who were with him hid themselves. Now Ornan was threshing wheat.",
                "As David came to Ornan, Ornan looked and saw David, and went out of the threshing floor, and bowed himself to David with his face to the ground.",
                "Then David said to Ornan, \"Give me the place of this threshing floor, that I may build thereon an altar to Yahweh. You shall sell it to me for the full price, that the plague may be stopped from afflicting the people.\"",
                "Ornan said to David, \"Take it for yourself, and let my lord the king do that which is good in his eyes. Behold, I give the oxen for burnt offerings, and the threshing instruments for wood, and the wheat for the meal offering. I give it all.\"",
                "King David said to Ornan, \"No; but I will most certainly buy it for the full price. For I will not take that which is yours for Yahweh, nor offer a burnt offering without cost.\"",
                "So David gave to Ornan six hundred shekels of gold by weight for the place.",
                "David built an altar to Yahweh there, and offered burnt offerings and peace offerings, and called on Yahweh; and he answered him from the sky by fire on the altar of burnt offering.",
                "Yahweh commanded the angel; and he put up his sword again into its sheath.",
                "At that time, when David saw that Yahweh had answered him in the threshing floor of Ornan the Jebusite, then he sacrificed there.",
                "For the tabernacle of Yahweh, which Moses made in the wilderness, and the altar of burnt offering, were at that time in the high place at Gibeon.",
                "But David couldn\u2019t go before it to inquire of God; for he was afraid because of the sword of the angel of Yahweh."
            ],
            22: [
                "Then David said, \"This is the house of Yahweh God, and this is the altar of burnt offering for Israel.\"",
                "David gave orders to gather together the foreigners who were in the land of Israel; and he set masons to cut worked stones to build the house of God.",
                "David prepared iron in abundance for the nails for the doors of the gates, and for the couplings; and brass in abundance without weight;",
                "and cedar trees without number: for the Sidonians and they of Tyre brought cedar trees in abundance to David.",
                "David said, \"Solomon my son is young and tender, and the house that is to be built for Yahweh must be exceedingly magnificent, of fame and of glory throughout all countries. I will therefore make preparation for it.\" So David prepared abundantly before his death.",
                "Then he called for Solomon his son, and commanded him to build a house for Yahweh, the God of Israel.",
                "David said to Solomon his son, \"As for me, it was in my heart to build a house to the name of Yahweh my God.",
                "But the word of Yahweh came to me, saying, \u2018You have shed blood abundantly, and have made great wars. You shall not build a house to my name, because you have shed much blood on the earth in my sight.",
                "Behold, a son shall be born to you, who shall be a man of rest. I will give him rest from all his enemies all around; for his name shall be Solomon, and I will give peace and quietness to Israel in his days.",
                "He shall build a house for my name; and he shall be my son, and I will be his father; and I will establish the throne of his kingdom over Israel for ever.\u2019",
                "Now, my son, may Yahweh be with you and prosper you, and build the house of Yahweh your God, as he has spoken concerning you.",
                "May Yahweh give you discretion and understanding, and put you in charge of Israel; that so you may keep the law of Yahweh your God.",
                "Then you will prosper, if you observe to do the statutes and the ordinances which Yahweh gave Moses concerning Israel. Be strong, and courageous. Don\u2019t be afraid, neither be dismayed.",
                "Now, behold, in my affliction I have prepared for the house of Yahweh one hundred thousand talents of gold, one million talents of silver, and brass and iron without weight; for it is in abundance. I have also prepared timber and stone; and you may add to them.",
                "There are also workmen with you in abundance, cutters and workers of stone and timber, and all kinds of men who are skillful in every kind of work:",
                "of the gold, the silver, and the brass, and the iron, there is no number. Arise and be doing, and may Yahweh be with you.\"",
                "David also commanded all the princes of Israel to help Solomon his son, saying,",
                "\"Isn\u2019t Yahweh your God with you? Hasn\u2019t he given you rest on every side? For he has delivered the inhabitants of the land into my hand; and the land is subdued before Yahweh, and before his people.",
                "Now set your heart and your soul to seek after Yahweh your God. Arise therefore, and build the sanctuary of Yahweh God, to bring the ark of the covenant of Yahweh, and the holy vessels of God, into the house that is to be built to the name of Yahweh.\""
            ],
            23: [
                "Now David was old and full of days; and he made Solomon his son king over Israel.",
                "He gathered together all the princes of Israel, with the priests and the Levites.",
                "The Levites were numbered from thirty years old and upward: and their number by their polls, man by man, was thirty-eight thousand.",
                "David said, \"Of these, twenty-four thousand were to oversee the work of the house of Yahweh; six thousand were officers and judges;",
                "four thousand were doorkeepers; and four thousand praised Yahweh with the instruments which I made, for giving praise.\"",
                "David divided them into divisions according to the sons of Levi: Gershon, Kohath, and Merari.",
                "Of the Gershonites: Ladan and Shimei.",
                "The sons of Ladan: Jehiel the chief, and Zetham, and Joel, three.",
                "The sons of Shimei: Shelomoth, and Haziel, and Haran, three. These were the heads of the fathers\u2019 households of Ladan.",
                "The sons of Shimei: Jahath, Zina, and Jeush, and Beriah. These four were the sons of Shimei.",
                "Jahath was the chief, and Zizah the second: but Jeush and Beriah didn\u2019t have many sons; therefore they became a fathers\u2019 house in one reckoning.",
                "The sons of Kohath: Amram, Izhar, Hebron, and Uzziel, four.",
                "The sons of Amram: Aaron and Moses; and Aaron was separated, that he should sanctify the most holy things, he and his sons, forever, to burn incense before Yahweh, to minister to him, and to bless in his name, forever.",
                "But as for Moses the man of God, his sons were named among the tribe of Levi.",
                "The sons of Moses: Gershom and Eliezer.",
                "The sons of Gershom: Shebuel the chief.",
                "The sons of Eliezer were: Rehabiah the chief; and Eliezer had no other sons; but the sons of Rehabiah were very many.",
                "The sons of Izhar: Shelomith the chief.",
                "The sons of Hebron: Jeriah the chief, Amariah the second, Jahaziel the third, and Jekameam the fourth.",
                "The sons of Uzziel: Micah the chief, and Isshiah the second.",
                "The sons of Merari: Mahli and Mushi. The sons of Mahli: Eleazar and Kish.",
                "Eleazar died, and had no sons, but daughters only: and their brothers the sons of Kish took them as wives.",
                "The sons of Mushi: Mahli, and Eder, and Jeremoth, three.",
                "These were the sons of Levi after their fathers\u2019 houses, even the heads of the fathers\u2019 houses of those who were counted individually, in the number of names by their polls, who did the work for the service of the house of Yahweh, from twenty years old and upward.",
                "For David said, \"Yahweh, the God of Israel, has given rest to his people; and he dwells in Jerusalem forever.",
                "Also the Levites will no longer need to carry the tabernacle and all its vessels for its service.\"",
                "For by the last words of David the sons of Levi were numbered, from twenty years old and upward.",
                "For their office was to wait on the sons of Aaron for the service of the house of Yahweh, in the courts, and in the rooms, and in the purifying of all holy things, even the work of the service of the house of God;",
                "for the show bread also, and for the fine flour for a meal offering, whether of unleavened wafers, or of that which is baked in the pan, or of that which is soaked, and for all kinds of measure and size;",
                "and to stand every morning to thank and praise Yahweh, and likewise in the evening;",
                "and to offer all burnt offerings to Yahweh, on the Sabbaths, on the new moons, and on the set feasts, in number according to the ordinance concerning them, continually before Yahweh;",
                "and that they should keep the duty of the Tent of Meeting, and the duty of the holy place, and the duty of the sons of Aaron their brothers, for the service of the house of Yahweh."
            ],
            24: [
                "These were the divisions of the sons of Aaron. The sons of Aaron: Nadab and Abihu, Eleazar and Ithamar.",
                "But Nadab and Abihu died before their father, and had no children: therefore Eleazar and Ithamar executed the priest\u2019s office.",
                "David with Zadok of the sons of Eleazar, and Ahimelech of the sons of Ithamar, divided them according to their ordering in their service.",
                "There were more chief men found of the sons of Eleazar than of the sons of Ithamar; and they were divided like this: of the sons of Eleazar there were sixteen, heads of fathers\u2019 houses; and of the sons of Ithamar, according to their fathers\u2019 houses, eight.",
                "Thus were they divided impartially by drawing lots; for there were princes of the sanctuary, and princes of God, both of the sons of Eleazar, and of the sons of Ithamar.",
                "Shemaiah the son of Nethanel the scribe, who was of the Levites, wrote them in the presence of the king, and the princes, and Zadok the priest, and Ahimelech the son of Abiathar, and the heads of the fathers\u2019 households of the priests and of the Levites; one fathers\u2019 house being taken for Eleazar, and one taken for Ithamar.",
                "Now the first lot came forth to Jehoiarib, the second to Jedaiah,",
                "the third to Harim, the fourth to Seorim,",
                "the fifth to Malchijah, the sixth to Mijamin,",
                "the seventh to Hakkoz, the eighth to Abijah,",
                "the ninth to Jeshua, the tenth to Shecaniah,",
                "the eleventh to Eliashib, the twelfth to Jakim,",
                "the thirteenth to Huppah, the fourteenth to Jeshebeab,",
                "the fifteenth to Bilgah, the sixteenth to Immer,",
                "the seventeenth to Hezir, the eighteenth to Happizzez,",
                "the nineteenth to Pethahiah, the twentieth to Jehezkel,",
                "the twenty-first to Jachin, the twenty-second to Gamul,",
                "the twenty-third to Delaiah, the twenty-fourth to Maaziah.",
                "This was their ordering in their service, to come into the house of Yahweh according to the ordinance given to them by Aaron their father, as Yahweh, the God of Israel, had commanded him.",
                "Of the rest of the sons of Levi: of the sons of Amram, Shubael; of the sons of Shubael, Jehdeiah.",
                "Of Rehabiah: of the sons of Rehabiah, Isshiah the chief.",
                "Of the Izharites, Shelomoth; of the sons of Shelomoth, Jahath.",
                "The sons of Hebron: Jeriah, Amariah the second, Jahaziel the third, Jekameam the fourth.",
                "The sons of Uzziel, Micah; of the sons of Micah, Shamir.",
                "The brother of Micah, Isshiah; of the sons of Isshiah, Zechariah.",
                "The sons of Merari: Mahli and Mushi; the sons of Jaaziah: Beno.",
                "The sons of Merari: of Jaaziah, Beno, and Shoham, and Zaccur, and Ibri.",
                "Of Mahli: Eleazar, who had no sons.",
                "Of Kish; the sons of Kish: Jerahmeel.",
                "The sons of Mushi: Mahli, and Eder, and Jerimoth. These were the sons of the Levites after their fathers\u2019 houses.",
                "These likewise cast lots even as their brothers the sons of Aaron in the presence of David the king, and Zadok, and Ahimelech, and the heads of the fathers\u2019 households of the priests and of the Levites; the fathers\u2019 households of the chief even as those of his younger brother."
            ],
            25: [
                "Moreover, David and the captains of the army set apart for the service certain of the sons of Asaph, and of Heman, and of Jeduthun, who should prophesy with harps, with stringed instruments, and with cymbals: and the number of those who did the work according to their service was:",
                "of the sons of Asaph: Zaccur, and Joseph, and Nethaniah, and Asharelah, the sons of Asaph, under the hand of Asaph, who prophesied after the order of the king.",
                "Of Jeduthun; the sons of Jeduthun: Gedaliah, and Zeri, and Jeshaiah, Hashabiah, and Mattithiah, six, under the hands of their father Jeduthun with the harp, who prophesied in giving thanks and praising Yahweh.",
                "Of Heman; the sons of Heman: Bukkiah, Mattaniah, Uzziel, Shebuel, and Jerimoth, Hananiah, Hanani, Eliathah, Giddalti, and Romamti-Ezer, Joshbekashah, Mallothi, Hothir, Mahazioth.",
                "All these were the sons of Heman the king\u2019s seer in the words of God, to lift up the horn. God gave to Heman fourteen sons and three daughters.",
                "All these were under the hands of their father for song in the house of Yahweh, with cymbals, stringed instruments, and harps, for the service of the house of God; Asaph, Jeduthun, and Heman being under the order of the king.",
                "The number of them, with their brothers who were instructed in singing to Yahweh, even all who were skillful, was two hundred eighty-eight.",
                "They cast lots for their offices, all alike, as well the small as the great, the teacher as the scholar.",
                "Now the first lot came forth for Asaph to Joseph: the second to Gedaliah; he and his brothers and sons were twelve:",
                "the third to Zaccur, his sons and his brothers, twelve:",
                "the fourth to Izri, his sons and his brothers, twelve:",
                "the fifth to Nethaniah, his sons and his brothers, twelve:",
                "the sixth to Bukkiah, his sons and his brothers, twelve:",
                "the seventh to Jesharelah, his sons and his brothers, twelve:",
                "the eighth to Jeshaiah, his sons and his brothers, twelve:",
                "the ninth to Mattaniah, his sons and his brothers, twelve:",
                "the tenth to Shimei, his sons and his brothers, twelve:",
                "the eleventh to Azarel, his sons and his brothers, twelve:",
                "the twelfth to Hashabiah, his sons and his brothers, twelve:",
                "for the thirteenth, Shubael, his sons and his brothers, twelve:",
                "for the fourteenth, Mattithiah, his sons and his brothers, twelve:",
                "for the fifteenth to Jeremoth, his sons and his brothers, twelve:",
                "for the sixteenth to Hananiah, his sons and his brothers, twelve:",
                "for the seventeenth to Joshbekashah, his sons and his brothers, twelve:",
                "for the eighteenth to Hanani, his sons and his brothers, twelve:",
                "for the nineteenth to Mallothi, his sons and his brothers, twelve:",
                "for the twentieth to Eliathah, his sons and his brothers, twelve:",
                "for the one and twentieth to Hothir, his sons and his brothers, twelve:",
                "for the two and twentieth to Giddalti, his sons and his brothers, twelve:",
                "for the three and twentieth to Mahazioth, his sons and his brothers, twelve:",
                "for the four and twentieth to Romamti-Ezer, his sons and his brothers, twelve."
            ],
            26: [
                "For the divisions of the doorkeepers: of the Korahites, Meshelemiah the son of Kore, of the sons of Asaph.",
                "Meshelemiah had sons: Zechariah the firstborn, Jediael the second, Zebadiah the third, Jathniel the fourth,",
                "Elam the fifth, Jehohanan the sixth, Eliehoenai the seventh.",
                "Obed-Edom had sons: Shemaiah the firstborn, Jehozabad the second, Joah the third, and Sacar the fourth, and Nethanel the fifth,",
                "Ammiel the sixth, Issachar the seventh, Peullethai the eighth; for God blessed him.",
                "Also to Shemaiah his son were sons born, who ruled over the house of their father; for they were mighty men of valor.",
                "The sons of Shemaiah: Othni, and Rephael, and Obed, Elzabad, whose brothers were valiant men, Elihu, and Semachiah.",
                "All these were of the sons of Obed-Edom: they and their sons and their brothers, able men in strength for the service; sixty-two of Obed-Edom.",
                "Meshelemiah had sons and brothers, valiant men, eighteen.",
                "Also Hosah, of the children of Merari, had sons: Shimri the chief, (for though he was not the firstborn, yet his father made him chief),",
                "Hilkiah the second, Tebaliah the third, Zechariah the fourth: all the sons and brothers of Hosah were thirteen.",
                "Of these were the divisions of the doorkeepers, even of the chief men, having offices like their brothers, to minister in the house of Yahweh.",
                "They cast lots, the small as well as the great, according to their fathers\u2019 houses, for every gate.",
                "The lot eastward fell to Shelemiah. Then for Zechariah his son, a wise counselor, they cast lots; and his lot came out northward.",
                "To Obed-Edom southward; and to his sons the storehouse.",
                "To Shuppim and Hosah westward, by the gate of Shallecheth, at the causeway that goes up, watch against watch.",
                "Eastward were six Levites, northward four a day, southward four a day, and for the storehouse two and two.",
                "For Parbar westward, four at the causeway, and two at Parbar.",
                "These were the divisions of the doorkeepers; of the sons of the Korahites, and of the sons of Merari.",
                "Of the Levites, Ahijah was over the treasures of the house of God, and over the treasures of the dedicated things.",
                "The sons of Ladan, the sons of the Gershonites belonging to Ladan, the heads of the fathers\u2019 households belonging to Ladan the Gershonite: Jehieli.",
                "The sons of Jehieli: Zetham, and Joel his brother, over the treasures of the house of Yahweh.",
                "Of the Amramites, of the Izharites, of the Hebronites, of the Uzzielites:",
                "and Shebuel the son of Gershom, the son of Moses, was ruler over the treasures.",
                "His brothers: of Eliezer, Rehabiah his son, and Jeshaiah his son, and Joram his son, and Zichri his son, and Shelomoth his son.",
                "This Shelomoth and his brothers were over all the treasures of the dedicated things, which David the king, and the heads of the fathers\u2019 households, the captains over thousands and hundreds, and the captains of the army, had dedicated.",
                "They dedicated some of the spoil won in battles to repair the house of Yahweh.",
                "All that Samuel the seer, and Saul the son of Kish, and Abner the son of Ner, and Joab the son of Zeruiah, had dedicated, whoever had dedicated anything, it was under the hand of Shelomoth, and of his brothers.",
                "Of the Izharites, Chenaniah and his sons were for the outward business over Israel, for officers and judges.",
                "Of the Hebronites, Hashabiah and his brothers, men of valor, one thousand seven hundred, had the oversight of Israel beyond the Jordan westward, for all the business of Yahweh, and for the service of the king.",
                "Of the Hebronites was Jerijah the chief, even of the Hebronites, according to their generations by fathers\u2019 households. In the fortieth year of the reign of David they were sought for, and there were found among them mighty men of valor at Jazer of Gilead.",
                "His brothers, men of valor, were two thousand seven hundred, heads of fathers\u2019 households, whom king David made overseers over the Reubenites, and the Gadites, and the half-tribe of the Manassites, for every matter pertaining to God, and for the affairs of the king."
            ],
            27: [
                "Now the children of Israel after their number, the heads of fathers\u2019 households and the captains of thousands and of hundreds, and their officers who served the king, in any matter of the divisions which came in and went out month by month throughout all the months of the year\u2014of every division were twenty-four thousand.",
                "Over the first division for the first month was Jashobeam the son of Zabdiel: and in his division were twenty-four thousand.",
                "He was of the children of Perez, the chief of all the captains of the army for the first month.",
                "Over the division of the second month was Dodai the Ahohite, and his division; and Mikloth the ruler: and in his division were twenty-four thousand.",
                "The third captain of the army for the third month was Benaiah, the son of Jehoiada the priest, chief: and in his division were twenty-four thousand.",
                "This is that Benaiah, who was the mighty man of the thirty, and over the thirty: and of his division was Ammizabad his son.",
                "The fourth captain for the fourth month was Asahel the brother of Joab, and Zebadiah his son after him: and in his division were twenty-four thousand.",
                "The fifth captain for this fifth month was Shamhuth the Izrahite: and in his division were twenty-four thousand.",
                "The sixth captain for the sixth month was Ira the son of Ikkesh the Tekoite: and in his division were twenty-four thousand.",
                "The seventh captain for the seventh month was Helez the Pelonite, of the children of Ephraim: and in his division were twenty-four thousand.",
                "The eighth captain for the eighth month was Sibbecai the Hushathite, of the Zerahites: and in his division were twenty-four thousand.",
                "The ninth captain for the ninth month was Abiezer the Anathothite, of the Benjamites: and in his division were twenty-four thousand.",
                "The tenth captain for the tenth month was Maharai the Netophathite, of the Zerahites: and in his division were Twenty-four thousand.",
                "The eleventh captain for the eleventh month was Benaiah the Pirathonite, of the children of Ephraim: and in his division were twenty-four thousand.",
                "The twelfth captain for the twelfth month was Heldai the Netophathite, of Othniel: and in his division were twenty-four thousand.",
                "Furthermore over the tribes of Israel: of the Reubenites was Eliezer the son of Zichri the ruler: of the Simeonites, Shephatiah the son of Maacah:",
                "of Levi, Hashabiah the son of Kemuel: of Aaron, Zadok:",
                "of Judah, Elihu, one of the brothers of David: of Issachar, Omri the son of Michael:",
                "of Zebulun, Ishmaiah the son of Obadiah: of Naphtali, Jeremoth the son of Azriel:",
                "of the children of Ephraim, Hoshea the son of Azaziah: of the half-tribe of Manasseh, Joel the son of Pedaiah:",
                "of the half-tribe of Manasseh in Gilead, Iddo the son of Zechariah: of Benjamin, Jaasiel the son of Abner:",
                "of Dan, Azarel the son of Jeroham. These were the captains of the tribes of Israel.",
                "But David didn\u2019t take the number of them from twenty years old and under, because Yahweh had said he would increase Israel like the stars of the sky.",
                "Joab the son of Zeruiah began to number, but didn\u2019t finish; and there came wrath for this on Israel; neither was the number put into the account in the chronicles of king David.",
                "Over the king\u2019s treasures was Azmaveth the son of Adiel: and over the treasures in the fields, in the cities, and in the villages, and in the towers, was Jonathan the son of Uzziah:",
                "Over those who did the work of the field for tillage of the ground was Ezri the son of Chelub:",
                "and over the vineyards was Shimei the Ramathite: and over the increase of the vineyards for the winecellars was Zabdi the Shiphmite:",
                "and over the olive trees and the sycamore trees that were in the lowland was Baal Hanan the Gederite: and over the cellars of oil was Joash:",
                "and over the herds that fed in Sharon was Shitrai the Sharonite: and over the herds that were in the valleys was Shaphat the son of Adlai:",
                "and over the camels was Obil the Ishmaelite: and over the donkeys was Jehdeiah the Meronothite: and over the flocks was Jaziz the Hagrite.",
                "All these were the rulers of the substance which was king David\u2019s.",
                "Also Jonathan, David\u2019s uncle, was a counselor, a man of understanding, and a scribe: and Jehiel the son of Hachmoni was with the king\u2019s sons:",
                "Ahithophel was the king\u2019s counselor: and Hushai the Archite was the king\u2019s friend:",
                "and after Ahithophel was Jehoiada the son of Benaiah, and Abiathar: and the captain of the king\u2019s army was Joab."
            ],
            28: [
                "David assembled all the princes of Israel, the princes of the tribes, and the captains of the companies who served the king by division, and the captains of thousands, and the captains of hundreds, and the rulers over all the substance and possessions of the king and of his sons, with the officers, and the mighty men, even all the mighty men of valor, to Jerusalem.",
                "Then David the king stood up on his feet, and said, \"Hear me, my brothers, and my people! As for me, it was in my heart to build a house of rest for the ark of the covenant of Yahweh, and for the footstool of our God; and I had prepared for the building.",
                "But God said to me, \u2018You shall not build a house for my name, because you are a man of war, and have shed blood.\u2019",
                "However Yahweh, the God of Israel, chose me out of all the house of my father to be king over Israel forever. For he has chosen Judah to be prince; and in the house of Judah, the house of my father; and among the sons of my father he took pleasure in me to make me king over all Israel.",
                "Of all my sons (for Yahweh has given me many sons), he has chosen Solomon my son to sit on the throne of Yahweh\u2019s kingdom over Israel.",
                "He said to me, \u2018Solomon, your son, shall build my house and my courts; for I have chosen him to be my son, and I will be his father.",
                "I will establish his kingdom forever, if he continues to do my commandments and my ordinances, as at this day.\u2019",
                "Now therefore, in the sight of all Israel, the assembly of Yahweh, and in the audience of our God, observe and seek out all the commandments of Yahweh your God; that you may possess this good land, and leave it for an inheritance to your children after you forever.",
                "You, Solomon my son, know the God of your father, and serve him with a perfect heart and with a willing mind; for Yahweh searches all hearts, and understands all the imaginations of the thoughts. If you seek him, he will be found by you; but if you forsake him, he will cast you off forever.",
                "Take heed now; for Yahweh has chosen you to build a house for the sanctuary. Be strong, and do it.\"",
                "Then David gave to Solomon his son the pattern of the porch of the temple, and of its houses, and of its treasuries, and of the upper rooms of it, and of the inner rooms of it, and of the place of the mercy seat;",
                "and the pattern of all that he had by the Spirit, for the courts of the house of Yahweh, and for all the surrounding rooms, for the treasuries of the house of God, and for the treasuries of the dedicated things;",
                "also for the divisions of the priests and the Levites, and for all the work of the service of the house of Yahweh, and for all the vessels of service in the house of Yahweh;",
                "of gold by weight for the gold, for all vessels of every kind of service; for all the vessels of silver by weight, for all vessels of every kind of service;",
                "by weight also for the lampstands of gold, and for its lamps, of gold, by weight for every lampstand and for its lamps; and for the lampstands of silver, by weight for every lampstand and for its lamps, according to the use of every lampstand;",
                "and the gold by weight for the tables of show bread, for every table; and silver for the tables of silver;",
                "and the forks, and the basins, and the cups, of pure gold; and for the golden bowls by weight for every bowl; and for the silver bowls by weight for every bowl;",
                "and for the altar of incense refined gold by weight; and gold for the pattern of the chariot, the cherubim, that spread out, and covered the ark of the covenant of Yahweh.",
                "\"All this,\" said David, \"I have been made to understand in writing from the hand of Yahweh, even all the works of this pattern.\"",
                "David said to Solomon his son, \"Be strong and courageous, and do it. Don\u2019t be afraid, nor be dismayed; for Yahweh God, even my God, is with you. He will not fail you, nor forsake you, until all the work for the service of the house of Yahweh is finished.",
                "Behold, there are the divisions of the priests and the Levites, for all the service of the house of God. There shall be with you in all kinds of work every willing man who has skill, for any kind of service. Also the captains and all the people will be entirely at your command.\""
            ],
            29: [
                "David the king said to all the assembly, \"Solomon my son, whom alone God has chosen, is yet young and tender, and the work is great; for the palace is not for man, but for Yahweh God.",
                "Now I have prepared with all my might for the house of my God the gold for the things of gold, and the silver for the things of silver, and the brass for the things of brass, the iron for the things of iron, and wood for the things of wood; onyx stones, and stones to be set, stones for inlaid work, and of various colors, and all kinds of precious stones, and marble stones in abundance.",
                "In addition, because I have set my affection on the house of my God, since I have a treasure of my own of gold and silver, I give it to the house of my God, over and above all that I have prepared for the holy house,",
                "even three thousand talents of gold, of the gold of Ophir, and seven thousand talents of refined silver, with which to overlay the walls of the houses;",
                "of gold for the things of gold, and of silver for the things of silver, and for all kinds of work to be made by the hands of artisans. Who then offers willingly to consecrate himself this day to Yahweh?\"",
                "Then the princes of the fathers\u2019 households, and the princes of the tribes of Israel, and the captains of thousands and of hundreds, with the rulers over the king\u2019s work, offered willingly;",
                "and they gave for the service of the house of God of gold five thousand talents and ten thousand darics, and of silver ten thousand talents, and of brass eighteen thousand talents, and of iron a hundred thousand talents.",
                "They with whom precious stones were found gave them to the treasure of the house of Yahweh, under the hand of Jehiel the Gershonite.",
                "Then the people rejoiced, because they offered willingly, because with a perfect heart they offered willingly to Yahweh: and David the king also rejoiced with great joy.",
                "Therefore David blessed Yahweh before all the assembly; and David said, \"You are blessed, Yahweh, the God of Israel our father, forever and ever.",
                "Yours, Yahweh, is the greatness, the power, the glory, the victory, and the majesty! For all that is in the heavens and in the earth is yours. Yours is the kingdom, Yahweh, and you are exalted as head above all.",
                "Both riches and honor come from you, and you rule over all; and in your hand is power and might; and it is in your hand to make great, and to give strength to all.",
                "Now therefore, our God, we thank you, and praise your glorious name.",
                "But who am I, and what is my people, that we should be able to offer so willingly as this? For all things come from you, and of your own have we given you.",
                "For we are strangers before you, and foreigners, as all our fathers were. Our days on the earth are as a shadow, and there is no remaining.",
                "Yahweh our God, all this store that we have prepared to build you a house for your holy name comes from your hand, and is all your own.",
                "I know also, my God, that you try the heart, and have pleasure in uprightness. As for me, in the uprightness of my heart I have willingly offered all these things. Now have I seen with joy your people, that are present here, offer willingly to you.",
                "Yahweh, the God of Abraham, of Isaac, and of Israel, our fathers, keep this forever in the imagination of the thoughts of the heart of your people, and prepare their heart for you;",
                "and give to Solomon my son a perfect heart, to keep your commandments, your testimonies, and your statutes, and to do all these things, and to build the palace, for which I have made provision.\"",
                "David said to all the assembly, \"Now bless Yahweh your God!\" All the assembly blessed Yahweh, the God of their fathers, and bowed down their heads and prostrated themselves before Yahweh and the king.",
                "They sacrificed sacrifices to Yahweh, and offered burnt offerings to Yahweh, on the next day after that day, even one thousand bulls, one thousand rams, and one thousand lambs, with their drink offerings, and sacrifices in abundance for all Israel,",
                "and ate and drink before Yahweh on that day with great gladness. They made Solomon the son of David king the second time, and anointed him to Yahweh to be prince, and Zadok to be priest.",
                "Then Solomon sat on the throne of Yahweh as king instead of David his father, and prospered; and all Israel obeyed him.",
                "All the princes, the mighty men, and also all of the sons of king David submitted themselves to Solomon the king.",
                "Yahweh magnified Solomon exceedingly in the sight of all Israel, and bestowed on him such royal majesty as had not been on any king before him in Israel.",
                "Now David the son of Jesse reigned over all Israel.",
                "The time that he reigned over Israel was forty years; he reigned seven years in Hebron, and he reigned thirty-three years in Jerusalem.",
                "He died in a good old age, full of days, riches, and honor: and Solomon his son reigned in his place.",
                "Now the acts of David the king, first and last, behold, they are written in the history of Samuel the seer, and in the history of Nathan the prophet, and in the history of Gad the seer,",
                "with all his reign and his might, and the times that went over him, and over Israel, and over all the kingdoms of the countries."
            ]
        },
        14: {
            1: [
                "Solomon the son of David was strengthened in his kingdom, and Yahweh his God was with him, and magnified him exceedingly.",
                "Solomon spoke to all Israel, to the captains of thousands and of hundreds, and to the judges, and to every prince in all Israel, the heads of the fathers\u2019 households.",
                "So Solomon, and all the assembly with him, went to the high place that was at Gibeon; for there was the Tent of Meeting of God, which Moses the servant of Yahweh had made in the wilderness.",
                "But David had brought the ark of God up from Kiriath Jearim to the place that David had prepared for it; for he had pitched a tent for it at Jerusalem.",
                "Moreover the bronze altar, that Bezalel the son of Uri, the son of Hur, had made, was there before the tabernacle of Yahweh: and Solomon and the assembly were seeking counsel there.",
                "Solomon went up there to the bronze altar before Yahweh, which was at the Tent of Meeting, and offered one thousand burnt offerings on it.",
                "In that night God appeared to Solomon, and said to him, \"Ask what I shall give you.\"",
                "Solomon said to God, \"You have shown great loving kindness to David my father, and have made me king in his place.",
                "Now, Yahweh God, let your promise to David my father be established; for you have made me king over a people like the dust of the earth in multitude.",
                "Now give me wisdom and knowledge, that I may go out and come in before this people; for who can judge this your people, that is so great?\"",
                "God said to Solomon, \"Because this was in your heart, and you have not asked riches, wealth, or honor, nor the life of those who hate you, neither yet have asked long life; but have asked wisdom and knowledge for yourself, that you may judge my people, over whom I have made you king:",
                "wisdom and knowledge is granted to you. I will give you riches, wealth, and honor, such as none of the kings have had who have been before you; neither shall there any after you have the like.\"",
                "So Solomon came from the high place that was at Gibeon, from before the Tent of Meeting, to Jerusalem; and he reigned over Israel.",
                "Solomon gathered chariots and horsemen: and he had one thousand four hundred chariots, and twelve thousand horsemen, that he placed in the chariot cities, and with the king at Jerusalem.",
                "The king made silver and gold to be in Jerusalem as stones, and cedars made he to be as the sycamore trees that are in the lowland, for abundance.",
                "The horses which Solomon had were brought out of Egypt and from Kue; the king\u2019s merchants purchased them from Kue.",
                "They brought up and brought out of Egypt a chariot for six hundred pieces of silver, and a horse for one hundred fifty: and so for all the kings of the Hittites, and the kings of Syria, they brought them out by their means."
            ],
            2: [
                "Now Solomon purposed to build a house for the name of Yahweh, and a house for his kingdom.",
                "Solomon counted out seventy thousand men to bear burdens, and eighty thousand men who were stone cutters in the mountains, and three thousand and six hundred to oversee them.",
                "Solomon sent to Huram the king of Tyre, saying, \"As you dealt with David my father, and sent him cedars to build him a house in which to dwell, so deal with me.",
                "Behold, I am about to build a house for the name of Yahweh my God, to dedicate it to him, and to burn before him incense of sweet spices, and for the continual show bread, and for the burnt offerings morning and evening, on the Sabbaths, and on the new moons, and on the set feasts of Yahweh our God. This is an ordinance forever to Israel.",
                "\"The house which I build is great; for our God is great above all gods.",
                "But who is able to build him a house, since heaven and the heaven of heavens can\u2019t contain him? who am I then, that I should build him a house, except just to burn incense before him?",
                "\"Now therefore send me a man skillful to work in gold, and in silver, and in brass, and in iron, and in purple, and crimson, and blue, and who knows how to engrave engravings, to be with the skillful men who are with me in Judah and in Jerusalem, whom David my father provided.",
                "\"Send me also cedar trees, fir trees, and algum trees, out of Lebanon; for I know that your servants know how to cut timber in Lebanon: and behold, my servants shall be with your servants,",
                "even to prepare me timber in abundance; for the house which I am about to build shall be great and wonderful.",
                "Behold, I will give to your servants, the cutters who cut timber, twenty thousand measures of beaten wheat, and twenty thousand measures of barley, and twenty thousand baths of wine, and twenty thousand baths of oil.\"",
                "Then Huram the king of Tyre answered in writing, which he sent to Solomon, \"Because Yahweh loves his people, he has made you king over them.\"",
                "Huram continued, \"Blessed be Yahweh, the God of Israel, that made heaven and earth, who has given to David the king a wise son, endowed with discretion and understanding, that should build a house for Yahweh, and a house for his kingdom.",
                "Now I have sent a skillful man, endowed with understanding, of Huram my father\u2019s,",
                "the son of a woman of the daughters of Dan; and his father was a man of Tyre, skillful to work in gold, and in silver, in brass, in iron, in stone, and in timber, in purple, in blue, and in fine linen, and in crimson, also to engrave any kind of engraving, and to devise any device; that there may be a place appointed to him with your skillful men, and with the skillful men of my lord David your father.",
                "\"Now therefore the wheat and the barley, the oil and the wine, which my lord has spoken of, let him send to his servants:",
                "and we will cut wood out of Lebanon, as much as you shall need; and we will bring it to you in floats by sea to Joppa; and you shall carry it up to Jerusalem.\"",
                "Solomon numbered all the foreigners who were in the land of Israel, after the numbering with which David his father had numbered them; and they were found one hundred fifty-three thousand six hundred.",
                "He set seventy thousand of them to bear burdens, and eighty thousand who were stone cutters in the mountains, and three thousand six hundred overseers to set the people at work."
            ],
            3: [
                "Then Solomon began to build the house of Yahweh at Jerusalem on Mount Moriah, where Yahweh appeared to David his father, which he prepared in the place that David had appointed, in the threshing floor of Ornan the Jebusite.",
                "He began to build in the second day of the second month, in the fourth year of his reign.",
                "Now these are the foundations which Solomon laid for the building of the house of God. The length by cubits after the first measure was sixty cubits, and the breadth twenty cubits.",
                "The porch that was in front, its length, according to the breadth of the house, was twenty cubits, and the height one hundred twenty; and he overlaid it within with pure gold.",
                "The greater house he made a ceiling with fir wood, which he overlaid with fine gold, and ornamented it with palm trees and chains.",
                "He garnished the house with precious stones for beauty: and the gold was gold of Parvaim.",
                "He overlaid also the house, the beams, the thresholds, and its walls, and its doors, with gold; and engraved cherubim on the walls.",
                "He made the most holy house: its length, according to the breadth of the house, was twenty cubits, and its breadth twenty cubits; and he overlaid it with fine gold, amounting to six hundred talents.",
                "The weight of the nails was fifty shekels of gold. He overlaid the upper rooms with gold.",
                "In the most holy house he made two cherubim of image work; and they overlaid them with gold.",
                "The wings of the cherubim were twenty cubits long: the wing of the one was five cubits, reaching to the wall of the house; and the other wing was five cubits, reaching to the wing of the other cherub.",
                "The wing of the other cherub was five cubits, reaching to the wall of the house; and the other wing was five cubits, joining to the wing of the other cherub.",
                "The wings of these cherubim spread themselves forth twenty cubits: and they stood on their feet, and their faces were toward the house.",
                "He made the veil of blue, and purple, and crimson, and fine linen, and ornamented it with cherubim.",
                "Also he made before the house two pillars of thirty-five cubits high, and the capital that was on the top of each of them was five cubits.",
                "He made chains in the oracle, and put them on the tops of the pillars; and he made one hundred pomegranates, and put them on the chains.",
                "He set up the pillars before the temple, one on the right hand, and the other on the left; and called the name of that on the right hand Jachin, and the name of that on the left Boaz."
            ],
            4: [
                "Then he made an altar of brass, twenty cubits its length, and twenty cubits its breadth, and ten cubits its height.",
                "Also he made the molten sea of ten cubits from brim to brim, round in compass; and its height was five cubits; and a line of thirty cubits encircled it.",
                "Under it was the likeness of oxen, which encircled it, for ten cubits, encircling the sea. The oxen were in two rows, cast when it was cast.",
                "It stood on twelve oxen, three looking toward the north, and three looking toward the west, and three looking toward the south, and three looking toward the east: and the sea was set on them above, and all their hinder parts were inward.",
                "It was a handbreadth thick; and its brim was worked like the brim of a cup, like the flower of a lily: it received and held three thousand baths.",
                "He made also ten basins, and put five on the right hand, and five on the left, to wash in them; such things as belonged to the burnt offering they washed in them; but the sea was for the priests to wash in.",
                "He made the ten lampstands of gold according to the ordinance concerning them; and he set them in the temple, five on the right hand, and five on the left.",
                "He made also ten tables, and placed them in the temple, five on the right side, and five on the left. He made one hundred basins of gold.",
                "Furthermore he made the court of the priests, and the great court, and doors for the court, and overlaid their doors with brass.",
                "He set the sea on the right side of the house eastward, toward the south.",
                "Huram made the pots, and the shovels, and the basins. So Huram made an end of doing the work that he did for king Solomon in the house of God:",
                "the two pillars, and the bowls, and the two capitals which were on the top of the pillars, and the two networks to cover the two bowls of the capitals that were on the top of the pillars,",
                "and the four hundred pomegranates for the two networks; two rows of pomegranates for each network, to cover the two bowls of the capitals that were on the pillars.",
                "He made also the bases, and the basins made he on the bases;",
                "one sea, and the twelve oxen under it.",
                "Huram his father also made the pots, the shovels, the forks, and all its vessels for king Solomon for the house of Yahweh of bright brass.",
                "The king cast them in the plain of the Jordan, in the clay ground between Succoth and Zeredah.",
                "Thus Solomon made all these vessels in great abundance: for the weight of the brass could not be found out.",
                "Solomon made all the vessels that were in the house of God, the golden altar also, and the tables with the show bread on them;",
                "and the lampstands with their lamps, to burn according to the ordinance before the oracle, of pure gold;",
                "and the flowers, and the lamps, and the tongs, of gold, and that perfect gold;",
                "and the snuffers, and the basins, and the spoons, and the fire pans, of pure gold: and as for the entry of the house, the inner doors of it for the most holy place, and the doors of the main hall of the temple were of gold."
            ],
            5: [
                "Thus all the work that Solomon did for the house of Yahweh was finished. Solomon brought in the things that David his father had dedicated, even the silver, and the gold, and all the vessels, and put them in the treasuries of the house of God.",
                "Then Solomon assembled the elders of Israel, and all the heads of the tribes, the princes of the fathers\u2019 households of the children of Israel, to Jerusalem, to bring up the ark of the covenant of Yahweh out of the city of David, which is Zion.",
                "And all the men of Israel assembled themselves to the king at the feast, which was in the seventh month.",
                "All the elders of Israel came: and the Levites took up the ark;",
                "and they brought up the ark, and the Tent of Meeting, and all the holy vessels that were in the Tent; these the priests the Levites brought up.",
                "King Solomon and all the congregation of Israel, that were assembled to him, were before the ark, sacrificing sheep and cattle, that could not be counted nor numbered for multitude.",
                "The priests brought in the ark of the covenant of Yahweh to its place, into the oracle of the house, to the most holy place, even under the wings of the cherubim.",
                "For the cherubim spread forth their wings over the place of the ark, and the cherubim covered the ark and its poles above.",
                "The poles were so long that the ends of the poles were seen from the ark before the oracle; but they were not seen outside: and there it is to this day.",
                "There was nothing in the ark except the two tables which Moses put at Horeb, when Yahweh made a covenant with the children of Israel, when they came out of Egypt.",
                "It happened, when the priests had come out of the holy place, (for all the priests who were present had sanctified themselves, and didn\u2019t keep their divisions;",
                "also the Levites who were the singers, all of them, even Asaph, Heman, Jeduthun, and their sons and their brothers, arrayed in fine linen, with cymbals and stringed instruments and harps, stood at the east end of the altar, and with them one hundred twenty priests sounding with trumpets;)",
                "it happened, when the trumpeters and singers were as one, to make one sound to be heard in praising and thanking Yahweh; and when they lifted up their voice with the trumpets and cymbals and instruments of music, and praised Yahweh, saying, \"For he is good; for his loving kindness endures forever!\" that then the house was filled with a cloud, even the house of Yahweh,",
                "so that the priests could not stand to minister by reason of the cloud: for the glory of Yahweh filled the house of God."
            ],
            6: [
                "Then Solomon said, \"Yahweh has said that he would dwell in the thick darkness.",
                "But I have built you a house of habitation, and a place for you to dwell in forever.\"",
                "The king turned his face, and blessed all the assembly of Israel: and all the assembly of Israel stood.",
                "He said, \"Blessed be Yahweh, the God of Israel, who spoke with his mouth to David my father, and has with his hands fulfilled it, saying,",
                "\u2018Since the day that I brought forth my people out of the land of Egypt, I chose no city out of all the tribes of Israel to build a house in, that my name might be there; neither chose I any man to be prince over my people Israel:",
                "but I have chosen Jerusalem, that my name might be there; and have chosen David to be over my people Israel.\u2019",
                "Now it was in the heart of David my father to build a house for the name of Yahweh, the God of Israel.",
                "But Yahweh said to David my father, \u2018Whereas it was in your heart to build a house for my name, you did well that it was in your heart:",
                "nevertheless you shall not build the house; but your son who shall come forth out of your body, he shall build the house for my name.\u2019",
                "\"Yahweh has performed his word that he spoke; for I have risen up in the place of David my father, and sit on the throne of Israel, as Yahweh promised, and have built the house for the name of Yahweh, the God of Israel.",
                "There I have set the ark, in which is the covenant of Yahweh, which he made with the children of Israel.\"",
                "He stood before the altar of Yahweh in the presence of all the assembly of Israel, and spread forth his hands",
                "(for Solomon had made a bronze scaffold, five cubits long, and five cubits broad, and three cubits high, and had set it in the midst of the court; and on it he stood, and kneeled down on his knees before all the assembly of Israel, and spread forth his hands toward heaven;)",
                "and he said, \"Yahweh, the God of Israel, there is no God like you, in heaven, or on earth; you who keep covenant and loving kindness with your servants, who walk before you with all their heart;",
                "who have kept with your servant David my father that which you promised him: yes, you spoke with your mouth, and have fulfilled it with your hand, as it is this day.",
                "\"Now therefore, Yahweh, the God of Israel, keep with your servant David my father that which you have promised him, saying, \u2018There shall not fail you a man in my sight to sit on the throne of Israel, if only your children take heed to their way, to walk in my law as you have walked before me.\u2019",
                "Now therefore, Yahweh, the God of Israel, let your word be verified, which you spoke to your servant David.",
                "\"But will God indeed dwell with men on the earth? Behold, heaven and the heaven of heavens can\u2019t contain you; how much less this house which I have built!",
                "Yet have respect for the prayer of your servant, and to his supplication, Yahweh my God, to listen to the cry and to the prayer which your servant prays before you;",
                "that your eyes may be open toward this house day and night, even toward the place where you have said that you would put your name; to listen to the prayer which your servant shall pray toward this place.",
                "Listen to the petitions of your servant, and of your people Israel, when they shall pray toward this place: yes, hear from your dwelling place, even from heaven; and when you hear, forgive.",
                "\"If a man sin against his neighbor, and an oath is laid on him to cause him to swear, and he comes and swears before your altar in this house;",
                "then hear from heaven, and do, and judge your servants, bringing retribution to the wicked, to bring his way on his own head; and justifying the righteous, to give him according to his righteousness.",
                "\"If your people Israel be struck down before the enemy, because they have sinned against you, and shall turn again and confess your name, and pray and make supplication before you in this house;",
                "then hear from heaven, and forgive the sin of your people Israel, and bring them again to the land which you gave to them and to their fathers.",
                "\"When the sky is shut up, and there is no rain, because they have sinned against you; if they pray toward this place, and confess your name, and turn from their sin, when you afflict them:",
                "then hear in heaven, and forgive the sin of your servants, and of your people Israel, when you teach them the good way in which they should walk; and send rain on your land, which you have given to your people for an inheritance.",
                "\"If there is famine in the land, if there is pestilence, if there is blight or mildew, locust or caterpillar; if their enemies besiege them in the land of their cities; whatever plague or whatever sickness there is;",
                "whatever prayer and supplication be made by any man, or by all your people Israel, who shall know every man his own plague and his own sorrow, and shall spread forth his hands toward this house:",
                "then hear from heaven, your dwelling place and forgive, and render to every man according to all his ways, whose heart you know; (for you, even you only, know the hearts of the children of men;)",
                "that they may fear you, to walk in your ways, so long as they live in the land which you gave to our fathers.",
                "\"Moreover concerning the foreigner, who is not of your people Israel, when he shall come from a far country for your great name\u2019s sake, and your mighty hand, and your outstretched arm; when they shall come and pray toward this house:",
                "then hear from heaven, even from your dwelling place, and do according to all that the foreigner calls to you for; that all the peoples of the earth may know your name, and fear you, as does your people Israel, and that they may know that this house which I have built is called by your name.",
                "\"If your people go out to battle against their enemies, by whatever way you shall send them, and they pray to you toward this city which you have chosen, and the house which I have built for your name;",
                "then hear from heaven their prayer and their supplication, and maintain their cause.",
                "\"If they sin against you (for there is no man who doesn\u2019t sin), and you are angry with them, and deliver them to the enemy, so that they carry them away captive to a land far off or near;",
                "yet if they shall repent themselves in the land where they are carried captive, and turn again, and make supplication to you in the land of their captivity, saying, \u2018We have sinned, we have done perversely, and have dealt wickedly;\u2019",
                "if they return to you with all their heart and with all their soul in the land of their captivity, where they have carried them captive, and pray toward their land, which you gave to their fathers, and the city which you have chosen, and toward the house which I have built for your name:",
                "then hear from heaven, even from your dwelling place, their prayer and their petitions, and maintain their cause, and forgive your people who have sinned against you.",
                "\"Now, my God, let, I beg you, your eyes be open, and let your ears be attentive, to the prayer that is made in this place.",
                "\"Now therefore arise, Yahweh God, into your resting place, you, and the ark of your strength: let your priests, Yahweh God, be clothed with salvation, and let your saints rejoice in goodness.",
                "\"Yahweh God, don\u2019t turn away the face of your anointed. Remember your loving kindnesses to David your servant.\""
            ],
            7: [
                "Now when Solomon had made an end of praying, the fire came down from heaven, and consumed the burnt offering and the sacrifices; and the glory of Yahweh filled the house.",
                "The priests could not enter into the house of Yahweh, because the glory of Yahweh filled Yahweh\u2019s house.",
                "All the children of Israel looked on, when the fire came down, and the glory of Yahweh was on the house; and they bowed themselves with their faces to the ground on the pavement, and worshiped, and gave thanks to Yahweh, saying, \"For he is good; for his loving kindness endures for ever.\"",
                "Then the king and all the people offered sacrifice before Yahweh.",
                "King Solomon offered a sacrifice of twenty-two thousand head of cattle, and a hundred and twenty thousand sheep. So the king and all the people dedicated the house of God.",
                "The priests stood, according to their positions; the Levites also with instruments of music of Yahweh, which David the king had made to give thanks to Yahweh, when David praised by their ministry, saying \"For his loving kindness endures for ever.\" The priests sounded trumpets before them; and all Israel stood.",
                "Moreover Solomon made the middle of the court holy that was before the house of Yahweh; for there he offered the burnt offerings, and the fat of the peace offerings, because the bronze altar which Solomon had made was not able to receive the burnt offering, and the meal offering, and the fat.",
                "So Solomon held the feast at that time seven days, and all Israel with him, a very great assembly, from the entrance of Hamath to the brook of Egypt.",
                "On the eighth day they held a solemn assembly: for they kept the dedication of the altar seven days, and the feast seven days.",
                "On the three and twentieth day of the seventh month he sent the people away to their tents, joyful and glad of heart for the goodness that Yahweh had shown to David, and to Solomon, and to Israel his people.",
                "Thus Solomon finished the house of Yahweh, and the king\u2019s house: and he successfully completed all that came into Solomon\u2019s heart to make in the house of Yahweh, and in his own house.",
                "Yahweh appeared to Solomon by night, and said to him, \"I have heard your prayer, and have chosen this place to myself for a house of sacrifice.",
                "\"If I shut up the sky so that there is no rain, or if I command the locust to devour the land, or if I send pestilence among my people;",
                "if my people, who are called by my name, shall humble themselves, and pray, and seek my face, and turn from their wicked ways; then will I hear from heaven, and will forgive their sin, and will heal their land.",
                "Now my eyes shall be open, and my ears attentive, to the prayer that is made in this place.",
                "For now have I chosen and made this house holy, that my name may be there forever; and my eyes and my heart shall be there perpetually.",
                "\"As for you, if you will walk before me as David your father walked, and do according to all that I have commanded you, and will keep my statutes and my ordinances;",
                "then I will establish the throne of your kingdom, according as I covenanted with David your father, saying, \u2018There shall not fail you a man to be ruler in Israel.\u2019",
                "But if you turn away, and forsake my statutes and my commandments which I have set before you, and shall go and serve other gods, and worship them;",
                "then I will pluck them up by the roots out of my land which I have given them; and this house, which I have made holy for my name, I will cast out of my sight, and I will make it a proverb and a byword among all peoples.",
                "This house, which is so high, everyone who passes by it shall be astonished, and shall say, \u2018Why has Yahweh done thus to this land, and to this house?\u2019",
                "They shall answer, \u2018Because they abandoned Yahweh, the God of their fathers, who brought them forth out of the land of Egypt, and took other gods, worshiped them, and served them. Therefore he has brought all this evil on them.\u2019\""
            ],
            8: [
                "It happened at the end of twenty years, in which Solomon had built the house of Yahweh, and his own house,",
                "that the cities which Huram had given to Solomon, Solomon built them, and caused the children of Israel to dwell there.",
                "Solomon went to Hamath Zobah, and prevailed against it.",
                "He built Tadmor in the wilderness, and all the storage cities, which he built in Hamath.",
                "Also he built Beth Horon the upper, and Beth Horon the lower, fortified cities, with walls, gates, and bars;",
                "and Baalath, and all the storage cities that Solomon had, and all the cities for his chariots, and the cities for his horsemen, and all that Solomon desired to build for his pleasure in Jerusalem, and in Lebanon, and in all the land of his dominion.",
                "As for all the people who were left of the Hittites, and the Amorites, and the Perizzites, and the Hivites, and the Jebusites, who were not of Israel;",
                "of their children who were left after them in the land, whom the children of Israel didn\u2019t consume, of them Solomon conscripted forced labor to this day.",
                "But of the children of Israel, Solomon made no servants for his work; but they were men of war, and chief of his captains, and rulers of his chariots and of his horsemen.",
                "These were the chief officers of king Solomon, even two-hundred fifty, who ruled over the people.",
                "Solomon brought up the daughter of Pharaoh out of the city of David to the house that he had built for her; for he said, \"My wife shall not dwell in the house of David king of Israel, because the places where the ark of Yahweh has come are holy.\"",
                "Then Solomon offered burnt offerings to Yahweh on the altar of Yahweh, which he had built before the porch,",
                "even as the duty of every day required, offering according to the commandment of Moses, on the Sabbaths, and on the new moons, and on the set feasts, three times in the year, in the feast of unleavened bread, and in the feast of weeks, and in the feast of tents.",
                "He appointed, according to the ordinance of David his father, the divisions of the priests to their service, and the Levites to their offices, to praise, and to minister before the priests, as the duty of every day required; the doorkeepers also by their divisions at every gate: for so had David the man of God commanded.",
                "They didn\u2019t depart from the commandment of the king to the priests and Levites concerning any matter, or concerning the treasures.",
                "Now all the work of Solomon was prepared to the day of the foundation of the house of Yahweh, and until it was finished. So the house of Yahweh was completed.",
                "Then went Solomon to Ezion Geber, and to Eloth, on the seashore in the land of Edom.",
                "Huram sent him ships and servants who had knowledge of the sea by the hands of his servants; and they came with the servants of Solomon to Ophir, and fetched from there four hundred fifty talents of gold, and brought them to king Solomon."
            ],
            9: [
                "When the queen of Sheba heard of the fame of Solomon, she came to prove Solomon with hard questions at Jerusalem, with a very great train, and camels that bore spices, and gold in abundance, and precious stones: and when she had come to Solomon, she talked with him of all that was in her heart.",
                "Solomon told her all her questions; and there was not anything hidden from Solomon which he didn\u2019t tell her.",
                "When the queen of Sheba had seen the wisdom of Solomon, and the house that he had built,",
                "and the food of his table, and the sitting of his servants, and the attendance of his ministers, and their clothing, his cup bearers also, and their clothing, and his ascent by which he went up to the house of Yahweh; there was no more spirit in her.",
                "She said to the king, \"It was a true report that I heard in my own land of your acts, and of your wisdom.",
                "However I didn\u2019t believe their words, until I came, and my eyes had seen it; and behold, the half of the greatness of your wisdom was not told me: you exceed the fame that I heard.",
                "Happy are your men, and happy are these your servants, who stand continually before you, and hear your wisdom.",
                "Blessed be Yahweh your God, who delighted in you, to set you on his throne, to be king for Yahweh your God: because your God loved Israel, to establish them forever, therefore made he you king over them, to do justice and righteousness.\"",
                "She gave the king one hundred and twenty talents of gold, and spices in great abundance, and precious stones: neither was there any such spice as the queen of Sheba gave to king Solomon.",
                "The servants also of Huram, and the servants of Solomon, who brought gold from Ophir, brought algum trees and precious stones.",
                "The king made of the algum trees terraces for the house of Yahweh, and for the king\u2019s house, and harps and stringed instruments for the singers: and there were none like these seen before in the land of Judah.",
                "King Solomon gave to the queen of Sheba all her desire, whatever she asked, besides that which she had brought to the king. So she turned, and went to her own land, she and her servants.",
                "Now the weight of gold that came to Solomon in one year was six hundred and sixty-six talents of gold,",
                "besides that which the traders and merchants brought: and all the kings of Arabia and the governors of the country brought gold and silver to Solomon.",
                "King Solomon made two hundred bucklers of beaten gold; six hundred shekels of beaten gold went to one buckler.",
                "He made three hundred shields of beaten gold; three hundred shekels of gold went to one shield: and the king put them in the house of the forest of Lebanon.",
                "Moreover the king made a great throne of ivory, and overlaid it with pure gold.",
                "And there were six steps to the throne, with a footstool of gold, which were fastened to the throne, and stays on either side by the place of the seat, and two lions standing beside the stays.",
                "Twelve lions stood there on the one side and on the other on the six steps: there was nothing like it made in any kingdom.",
                "All king Solomon\u2019s drinking vessels were of gold, and all the vessels of the house of the forest of Lebanon were of pure gold: silver was nothing accounted of in the days of Solomon.",
                "For the king had ships that went to Tarshish with the servants of Huram; once every three years came the ships of Tarshish, bringing gold, and silver, ivory, and apes, and peacocks.",
                "So king Solomon exceeded all the kings of the earth in riches and wisdom.",
                "All the kings of the earth sought the presence of Solomon, to hear his wisdom, which God had put in his heart.",
                "They brought every man his tribute, vessels of silver, and vessels of gold, and clothing, armor, and spices, horses, and mules, a rate year by year.",
                "Solomon had four thousand stalls for horses and chariots, and twelve thousand horsemen, that he stationed in the chariot cities, and with the king at Jerusalem.",
                "He ruled over all the kings from the River even to the land of the Philistines, and to the border of Egypt.",
                "The king made silver to be in Jerusalem as stones, and he made cedars to be as the sycamore trees that are in the lowland, for abundance.",
                "They brought horses for Solomon out of Egypt, and out of all lands.",
                "Now the rest of the acts of Solomon, first and last, aren\u2019t they written in the history of Nathan the prophet, and in the prophecy of Ahijah the Shilonite, and in the visions of Iddo the seer concerning Jeroboam the son of Nebat?",
                "Solomon reigned in Jerusalem over all Israel forty years.",
                "Solomon slept with his fathers, and he was buried in the city of David his father: and Rehoboam his son reigned in his place."
            ],
            10: [
                "Rehoboam went to Shechem; for all Israel had come to Shechem to make him king.",
                "It happened, when Jeroboam the son of Nebat heard of it, (for he was in Egypt, where he had fled from the presence of king Solomon), that Jeroboam returned out of Egypt.",
                "They sent and called him; and Jeroboam and all Israel came, and they spoke to Rehoboam, saying,",
                "\"Your father made our yoke grievous: now therefore make you the grievous service of your father, and his heavy yoke which he put on us, lighter, and we will serve you.\"",
                "He said to them, \"Come again to me after three days.\" The people departed.",
                "King Rehoboam took counsel with the old men, who had stood before Solomon his father while he yet lived, saying, \"What counsel do you give me to return answer to this people?\"",
                "They spoke to him, saying, \"If you are kind to this people, and please them, and speak good words to them, then they will be your servants forever.\"",
                "But he forsook the counsel of the old men which they had given him, and took counsel with the young men who had grown up with him, who stood before him.",
                "He said to them, \"What counsel do you give, that we may return answer to this people, who have spoken to me, saying, \u2018Make the yoke that your father put on us lighter?\u2019\"",
                "The young men who had grown up with him spoke to him, saying, \"Thus you shall tell the people who spoke to you, saying, \u2018Your father made our yoke heavy, but make it lighter on us;\u2019 thus you shall say to them, \u2018My little finger is thicker than my father\u2019s waist.",
                "Now whereas my father burdened you with a heavy yoke, I will add to your yoke. My father chastised you with whips, but I will chastise you with scorpions.\u2019\"",
                "So Jeroboam and all the people came to Rehoboam the third day, as the king asked, saying, \"Come to me again the third day.\"",
                "The king answered them roughly; and king Rehoboam forsook the counsel of the old men,",
                "and spoke to them after the counsel of the young men, saying, \"My father made your yoke heavy, but I will add to it. My father chastised you with whips, but I will chastise you with scorpions.\"",
                "So the king didn\u2019t listen to the people; for it was brought about of God, that Yahweh might establish his word, which he spoke by Ahijah the Shilonite to Jeroboam the son of Nebat.",
                "When all Israel saw that the king didn\u2019t listen to them, the people answered the king, saying, \"What portion have we in David? Neither have we inheritance in the son of Jesse! Every man to your tents, Israel! Now see to your own house, David.\" So all Israel departed to their tents.",
                "But as for the children of Israel who lived in the cities of Judah, Rehoboam reigned over them.",
                "Then king Rehoboam sent Hadoram, who was over the men subject to forced labor; and the children of Israel stoned him to death with stones. King Rehoboam made speed to get himself up to his chariot, to flee to Jerusalem.",
                "So Israel rebelled against the house of David to this day."
            ],
            11: [
                "When Rehoboam had come to Jerusalem, he assembled the house of Judah and Benjamin, one hundred eighty thousand chosen men, who were warriors, to fight against Israel, to bring the kingdom again to Rehoboam.",
                "But the word of Yahweh came to Shemaiah the man of God, saying,",
                "\"Speak to Rehoboam the son of Solomon, king of Judah, and to all Israel in Judah and Benjamin, saying,",
                "\u2018Thus says Yahweh, \"You shall not go up, nor fight against your brothers! Return every man to his house; for this thing is of me.\"\u2019\" So they listened to the words of Yahweh, and returned from going against Jeroboam.",
                "Rehoboam lived in Jerusalem, and built cities for defense in Judah.",
                "He built Bethlehem, and Etam, and Tekoa,",
                "Beth Zur, and Soco, and Adullam,",
                "and Gath, and Mareshah, and Ziph,",
                "and Adoraim, and Lachish, and Azekah,",
                "and Zorah, and Aijalon, and Hebron, which are in Judah and in Benjamin, fortified cities.",
                "He fortified the strongholds, and put captains in them, and stores of food, and oil and wine.",
                "He put shields and spears in every city, and made them exceeding strong. Judah and Benjamin belonged to him.",
                "The priests and the Levites who were in all Israel resorted to him out of all their border.",
                "For the Levites left their suburbs and their possession, and came to Judah and Jerusalem: for Jeroboam and his sons cast them off, that they should not execute the priest\u2019s office to Yahweh;",
                "and he appointed him priests for the high places, and for the male goats, and for the calves which he had made.",
                "After them, out of all the tribes of Israel, such as set their hearts to seek Yahweh, the God of Israel, came to Jerusalem to sacrifice to Yahweh, the God of their fathers.",
                "So they strengthened the kingdom of Judah, and made Rehoboam the son of Solomon strong, three years; for they walked three years in the way of David and Solomon.",
                "Rehoboam took him a wife, Mahalath the daughter of Jerimoth the son of David, and of Abihail the daughter of Eliab the son of Jesse;",
                "and she bore him sons: Jeush, and Shemariah, and Zaham.",
                "After her he took Maacah the daughter of Absalom; and she bore him Abijah, and Attai, and Ziza, and Shelomith.",
                "Rehoboam loved Maacah the daughter of Absalom above all his wives and his concubines: (for he took eighteen wives, and sixty concubines, and became the father of twenty-eight sons and sixty daughters.)",
                "Rehoboam appointed Abijah the son of Maacah to be chief, the prince among his brothers; for he intended to make him king.",
                "He dealt wisely, and dispersed of all his sons throughout all the lands of Judah and Benjamin, to every fortified city: and he gave them food in abundance. He sought for them many wives."
            ],
            12: [
                "It happened, when the kingdom of Rehoboam was established, and he was strong, that he forsook the law of Yahweh, and all Israel with him.",
                "It happened in the fifth year of king Rehoboam, that Shishak king of Egypt came up against Jerusalem, because they had trespassed against Yahweh,",
                "with twelve hundred chariots, and sixty thousand horsemen. The people were without number who came with him out of Egypt: the Lubim, the Sukkiim, and the Ethiopians.",
                "He took the fortified cities which pertained to Judah, and came to Jerusalem.",
                "Now Shemaiah the prophet came to Rehoboam, and to the princes of Judah, who were gathered together to Jerusalem because of Shishak, and said to them, \"Thus says Yahweh, \u2018You have forsaken me, therefore have I also left you in the hand of Shishak.\u2019\"",
                "Then the princes of Israel and the king humbled themselves; and they said, \"Yahweh is righteous.\"",
                "When Yahweh saw that they humbled themselves, the word of Yahweh came to Shemaiah, saying, \"They have humbled themselves. I will not destroy them; but I will grant them some deliverance, and my wrath shall not be poured out on Jerusalem by the hand of Shishak.",
                "Nevertheless they shall be his servants, that they may know my service, and the service of the kingdoms of the countries.\"",
                "So Shishak king of Egypt came up against Jerusalem, and took away the treasures of the house of Yahweh, and the treasures of the king\u2019s house. He took it all away. He also took away the shields of gold which Solomon had made.",
                "King Rehoboam made in their place shields of brass, and committed them to the hands of the captains of the guard, who kept the door of the king\u2019s house.",
                "It was so, that as often as the king entered into the house of Yahweh, the guard came and bore them, and brought them back into the guard room.",
                "When he humbled himself, the wrath of Yahweh turned from him, so as not to destroy him altogether: and moreover in Judah there were good things found.",
                "So king Rehoboam strengthened himself in Jerusalem, and reigned: for Rehoboam was forty-one years old when he began to reign, and he reigned seventeen years in Jerusalem, the city which Yahweh had chosen out of all the tribes of Israel, to put his name there: and his mother\u2019s name was Naamah the Ammonitess.",
                "He did that which was evil, because he didn\u2019t set his heart to seek Yahweh.",
                "Now the acts of Rehoboam, first and last, aren\u2019t they written in the histories of Shemaiah the prophet and of Iddo the seer, after the way of genealogies? There were wars between Rehoboam and Jeroboam continually.",
                "Rehoboam slept with his fathers, and was buried in the city of David: and Abijah his son reigned in his place."
            ],
            13: [
                "In the eighteenth year of king Jeroboam began Abijah to reign over Judah.",
                "He reigned three years in Jerusalem: and his mother\u2019s name was Micaiah the daughter of Uriel of Gibeah. There was war between Abijah and Jeroboam.",
                "Abijah joined battle with an army of valiant men of war, even four hundred thousand chosen men: and Jeroboam set the battle in array against him with eight hundred thousand chosen men, who were mighty men of valor.",
                "Abijah stood up on Mount Zemaraim, which is in the hill country of Ephraim, and said, \"Hear me, Jeroboam and all Israel:",
                "Ought you not to know that Yahweh, the God of Israel, gave the kingdom over Israel to David forever, even to him and to his sons by a covenant of salt?",
                "Yet Jeroboam the son of Nebat, the servant of Solomon the son of David, rose up, and rebelled against his lord.",
                "There were gathered to him worthless men, base fellows, who strengthened themselves against Rehoboam the son of Solomon, when Rehoboam was young and tenderhearted, and could not withstand them.",
                "\"Now you think to withstand the kingdom of Yahweh in the hand of the sons of David; and you are a great multitude, and there are with you the golden calves which Jeroboam made you for gods.",
                "Haven\u2019t you driven out the priests of Yahweh, the sons of Aaron, and the Levites, and made priests for yourselves after the ways of the peoples of other lands? so that whoever comes to consecrate himself with a young bull and seven rams, the same may be a priest of those who are no gods.",
                "\"But as for us, Yahweh is our God, and we have not forsaken him; and we have priests ministering to Yahweh, the sons of Aaron, and the Levites in their work:",
                "and they burn to Yahweh every morning and every evening burnt offerings and sweet incense. They also set the show bread in order on the pure table; and the lampstand of gold with its lamps, to burn every evening: for we keep the instruction of Yahweh our God; but you have forsaken him.",
                "Behold, God is with us at our head, and his priests with the trumpets of alarm to sound an alarm against you. Children of Israel, don\u2019t fight against Yahweh, the God of your fathers; for you shall not prosper.\"",
                "But Jeroboam caused an ambush to come about behind them: so they were before Judah, and the ambush was behind them.",
                "When Judah looked back, behold, the battle was before and behind them; and they cried to Yahweh, and the priests sounded with the trumpets.",
                "Then the men of Judah gave a shout: and as the men of Judah shouted, it happened, that God struck Jeroboam and all Israel before Abijah and Judah.",
                "The children of Israel fled before Judah; and God delivered them into their hand.",
                "Abijah and his people killed them with a great slaughter: so there fell down slain of Israel five hundred thousand chosen men.",
                "Thus the children of Israel were brought under at that time, and the children of Judah prevailed, because they relied on Yahweh, the God of their fathers.",
                "Abijah pursued after Jeroboam, and took cities from him, Bethel with its towns, and Jeshanah with its towns, and Ephron with its towns.",
                "Jeroboam didn\u2019t recover strength again in the days of Abijah. Yahweh struck him, and he died.",
                "But Abijah grew mighty, and took to himself fourteen wives, and became the father of twenty-two sons, and sixteen daughters.",
                "The rest of the acts of Abijah, and his ways, and his sayings, are written in the commentary of the prophet Iddo."
            ],
            14: [
                "So Abijah slept with his fathers, and they buried him in the city of David; and Asa his son reigned in his place. In his days the land was quiet ten years.",
                "Asa did that which was good and right in the eyes of Yahweh his God:",
                "for he took away the foreign altars, and the high places, and broke down the pillars, and cut down the Asherim,",
                "and commanded Judah to seek Yahweh, the God of their fathers, and to do the law and the commandment.",
                "Also he took away out of all the cities of Judah the high places and the sun images: and the kingdom was quiet before him.",
                "He built fortified cities in Judah; for the land was quiet, and he had no war in those years, because Yahweh had given him rest.",
                "For he said to Judah, \"Let us build these cities, and make walls around them, with towers, gates, and bars. The land is yet before us, because we have sought Yahweh our God; we have sought him, and he has given us rest on every side.\" So they built and prospered.",
                "Asa had an army that bore bucklers and spears, out of Judah three hundred thousand; and out of Benjamin, that bore shields and drew bows, two hundred eighty thousand: all these were mighty men of valor.",
                "There came out against them Zerah the Ethiopian with an army of a million troops, and three hundred chariots; and he came to Mareshah.",
                "Then Asa went out to meet him, and they set the battle in array in the valley of Zephathah at Mareshah.",
                "Asa cried to Yahweh his God, and said, \"Yahweh, there is none besides you to help, between the mighty and him who has no strength. Help us, Yahweh our God; for we rely on you, and in your name are we come against this multitude. Yahweh, you are our God. Don\u2019t let man prevail against you.\"",
                "So Yahweh struck the Ethiopians before Asa, and before Judah; and the Ethiopians fled.",
                "Asa and the people who were with him pursued them to Gerar: and there fell of the Ethiopians so many that they could not recover themselves; for they were destroyed before Yahweh, and before his army; and they carried away very much booty.",
                "They struck all the cities around Gerar; for the fear of Yahweh came on them: and they despoiled all the cities; for there was much spoil in them.",
                "They struck also the tents of livestock, and carried away sheep in abundance, and camels, and returned to Jerusalem."
            ],
            15: [
                "The Spirit of God came on Azariah the son of Oded:",
                "and he went out to meet Asa, and said to him, \"Hear me, Asa, and all Judah and Benjamin! Yahweh is with you, while you are with him; and if you seek him, he will be found by you; but if you forsake him, he will forsake you.",
                "Now for a long time Israel was without the true God, and without a teaching priest, and without law.",
                "But when in their distress they turned to Yahweh, the God of Israel, and sought him, he was found by them.",
                "In those times there was no peace to him who went out, nor to him who came in; but great troubles were on all the inhabitants of the lands.",
                "They were broken in pieces, nation against nation, and city against city; for God troubled them with all adversity.",
                "But you be strong, and don\u2019t let your hands be slack; for your work shall be rewarded.\"",
                "When Asa heard these words, and the prophecy of Oded the prophet, he took courage, and put away the abominations out of all the land of Judah and Benjamin, and out of the cities which he had taken from the hill country of Ephraim; and he renewed the altar of Yahweh, that was before the porch of Yahweh.",
                "He gathered all Judah and Benjamin, and those who lived with them out of Ephraim and Manasseh, and out of Simeon: for they fell to him out of Israel in abundance, when they saw that Yahweh his God was with him.",
                "So they gathered themselves together at Jerusalem in the third month, in the fifteenth year of the reign of Asa.",
                "They sacrificed to Yahweh in that day, of the spoil which they had brought, seven hundred head of cattle and seven thousand sheep.",
                "They entered into the covenant to seek Yahweh, the God of their fathers, with all their heart and with all their soul;",
                "and that whoever would not seek Yahweh, the God of Israel, should be put to death, whether small or great, whether man or woman.",
                "They swore to Yahweh with a loud voice, and with shouting, and with trumpets, and with cornets.",
                "All Judah rejoiced at the oath; for they had sworn with all their heart, and sought him with their whole desire; and he was found of them: and Yahweh gave them rest all around.",
                "Also Maacah, the mother of Asa the king, he removed from being queen, because she had made an abominable image for an Asherah; and Asa cut down her image, and made dust of it, and burnt it at the brook Kidron.",
                "But the high places were not taken away out of Israel: nevertheless the heart of Asa was perfect all his days.",
                "He brought into the house of God the things that his father had dedicated, and that he himself had dedicated, silver, and gold, and vessels.",
                "There was no more war to the five and thirtieth year of the reign of Asa."
            ],
            16: [
                "In the six and thirtieth year of the reign of Asa, Baasha king of Israel went up against Judah, and built Ramah, that he might not allow anyone to go out or come in to Asa king of Judah.",
                "Then Asa brought out silver and gold out of the treasures of the house of Yahweh and of the king\u2019s house, and sent to Ben Hadad king of Syria, who lived at Damascus, saying,",
                "\"Let there be a treaty between me and you, as there was between my father and your father. Behold, I have sent you silver and gold. Go, break your treaty with Baasha king of Israel, that he may depart from me.\"",
                "Ben Hadad listened to king Asa, and sent the captains of his armies against the cities of Israel; and they struck Ijon, and Dan, and Abel Maim, and all the storage cities of Naphtali.",
                "It happened, when Baasha heard of it, that he left off building Ramah, and let his work cease.",
                "Then Asa the king took all Judah; and they carried away the stones of Ramah, and its timber, with which Baasha had built; and he built therewith Geba and Mizpah.",
                "At that time Hanani the seer came to Asa king of Judah, and said to him, \"Because you have relied on the king of Syria, and have not relied on Yahweh your God, therefore is the army of the king of Syria escaped out of your hand.",
                "Weren\u2019t the Ethiopians and the Lubim a huge army, with chariots and horsemen exceeding many? Yet, because you relied on Yahweh, he delivered them into your hand.",
                "For the eyes of Yahweh run back and forth throughout the whole earth, to show himself strong in the behalf of them whose heart is perfect toward him. Herein you have done foolishly; for from henceforth you shall have wars.\"",
                "Then Asa was angry with the seer, and put him in the prison; for he was in a rage with him because of this thing. Asa oppressed some of the people at the same time.",
                "Behold, the acts of Asa, first and last, behold, they are written in the book of the kings of Judah and Israel.",
                "In the thirty-ninth year of his reign Asa was diseased in his feet; his disease was exceeding great: yet in his disease he didn\u2019t seek Yahweh, but to the physicians.",
                "Asa slept with his fathers, and died in the one and fortieth year of his reign.",
                "They buried him in his own tombs, which he had dug out for himself in the city of David, and laid him in the bed which was filled with sweet odors and various kinds of spices prepared by the perfumers\u2019 art: and they made a very great burning for him."
            ],
            17: [
                "Jehoshaphat his son reigned in his place, and strengthened himself against Israel.",
                "He placed forces in all the fortified cities of Judah, and set garrisons in the land of Judah, and in the cities of Ephraim, which Asa his father had taken.",
                "Yahweh was with Jehoshaphat, because he walked in the first ways of his father David, and didn\u2019t seek the Baals,",
                "but sought to the God of his father, and walked in his commandments, and not after the doings of Israel.",
                "Therefore Yahweh established the kingdom in his hand; and all Judah brought to Jehoshaphat tribute; and he had riches and honor in abundance.",
                "His heart was lifted up in the ways of Yahweh: and furthermore he took away the high places and the Asherim out of Judah.",
                "Also in the third year of his reign he sent his princes, even Ben Hail, and Obadiah, and Zechariah, and Nethanel, and Micaiah, to teach in the cities of Judah;",
                "and with them the Levites, even Shemaiah, and Nethaniah, and Zebadiah, and Asahel, and Shemiramoth, and Jehonathan, and Adonijah, and Tobijah, and Tobadonijah, the Levites; and with them Elishama and Jehoram, the priests.",
                "They taught in Judah, having the book of the law of Yahweh with them; and they went about throughout all the cities of Judah, and taught among the people.",
                "The fear of Yahweh fell on all the kingdoms of the lands that were around Judah, so that they made no war against Jehoshaphat.",
                "Some of the Philistines brought Jehoshaphat presents, and silver for tribute; the Arabians also brought him flocks, seven thousand and seven hundred rams, and seven thousand and seven hundred male goats.",
                "Jehoshaphat grew great exceedingly; and he built in Judah castles and cities of store.",
                "He had many works in the cities of Judah; and men of war, mighty men of valor, in Jerusalem.",
                "This was the numbering of them according to their fathers\u2019 houses: Of Judah, the captains of thousands: Adnah the captain, and with him mighty men of valor three hundred thousand;",
                "and next to him Jehohanan the captain, and with him two hundred eighty thousand;",
                "and next to him Amasiah the son of Zichri, who willingly offered himself to Yahweh; and with him two hundred thousand mighty men of valor.",
                "Of Benjamin: Eliada a mighty man of valor, and with him two hundred thousand armed with bow and shield;",
                "and next to him Jehozabad and with him one hundred eighty thousand ready prepared for war.",
                "These were those who waited on the king, besides those whom the king put in the fortified cities throughout all Judah."
            ],
            18: [
                "Now Jehoshaphat had riches and honor in abundance; and he joined affinity with Ahab.",
                "After certain years he went down to Ahab to Samaria. Ahab killed sheep and cattle for him in abundance, and for the people who were with him, and moved him to go up with him to Ramoth Gilead.",
                "Ahab king of Israel said to Jehoshaphat king of Judah, \"Will you go with me to Ramoth Gilead?\" He answered him, \"I am as you are, and my people as your people. We will be with you in the war.\"",
                "Jehoshaphat said to the king of Israel, \"Please inquire first for the word of Yahweh.\"",
                "Then the king of Israel gathered the prophets together, four hundred men, and said to them, \"Shall we go to Ramoth Gilead to battle, or shall I forbear?\" They said, \"Go up; for God will deliver it into the hand of the king.\"",
                "But Jehoshaphat said, \"Isn\u2019t there here a prophet of Yahweh besides, that we may inquire of him?\"",
                "The king of Israel said to Jehoshaphat, \"There is yet one man by whom we may inquire of Yahweh; but I hate him, for he never prophesies good concerning me, but always evil. He is Micaiah the son of Imla.\" Jehoshaphat said, \"Don\u2019t let the king say so.\"",
                "Then the king of Israel called an officer, and said, \"Get Micaiah the son of Imla quickly.\"",
                "Now the king of Israel and Jehoshaphat the king of Judah sat each on his throne, arrayed in their robes, and they were sitting in an open place at the entrance of the gate of Samaria; and all the prophets were prophesying before them.",
                "Zedekiah the son of Chenaanah made him horns of iron, and said, \"Thus says Yahweh, \u2018With these you shall push the Syrians, until they are consumed.\u2019\"",
                "All the prophets prophesied so, saying, \"Go up to Ramoth Gilead, and prosper; for Yahweh will deliver it into the hand of the king.\"",
                "The messenger who went to call Micaiah spoke to him, saying, \"Behold, the words of the prophets declare good to the king with one mouth. Let your word therefore, please be like one of theirs, and speak good.\"",
                "Micaiah said, \"As Yahweh lives, what my God says, that will I speak.\"",
                "When he had come to the king, the king said to him, \"Micaiah, shall we go to Ramoth Gilead to battle, or shall I forbear?\" He said, \"Go up, and prosper. They shall be delivered into your hand.\"",
                "The king said to him, \"How many times shall I adjure you that you speak to me nothing but the truth in the name of Yahweh?\"",
                "He said, \"I saw all Israel scattered on the mountains, as sheep that have no shepherd. Yahweh said, \u2018These have no master. Let them return every man to his house in peace.\u2019\"",
                "The king of Israel said to Jehoshaphat, \"Didn\u2019t I tell you that he would not prophesy good concerning me, but evil?\"",
                "Micaiah said, \"Therefore hear the word of Yahweh: I saw Yahweh sitting on his throne, and all the army of heaven standing on his right hand and on his left.",
                "Yahweh said, \u2018Who shall entice Ahab king of Israel, that he may go up and fall at Ramoth Gilead?\u2019 One spoke saying in this way, and another saying in that way.",
                "A spirit came out, stood before Yahweh, and said, \u2018I will entice him.\u2019 \"Yahweh said to him, \u2018How?\u2019",
                "\"He said, \u2018I will go forth, and will be a lying spirit in the mouth of all his prophets.\u2019 \"He said, \u2018You will entice him, and will prevail also. Go forth, and do so.\u2019",
                "\"Now therefore, behold, Yahweh has put a lying spirit in the mouth of these your prophets; and Yahweh has spoken evil concerning you.\"",
                "Then Zedekiah the son of Chenaanah came near, and struck Micaiah on the cheek, and said, \"Which way did the Spirit of Yahweh go from me to speak to you?\"",
                "Micaiah said, \"Behold, you shall see on that day, when you shall go into an inner room to hide yourself.\"",
                "The king of Israel said, \"Take Micaiah, and carry him back to Amon the governor of the city, and to Joash the king\u2019s son;",
                "and say, \u2018Thus says the king, \"Put this fellow in the prison, and feed him with bread of affliction and with water of affliction, until I return in peace.\"\u2019\"",
                "Micaiah said, \"If you return at all in peace, Yahweh has not spoken by me.\" He said, \"Listen, you peoples, all of you!\"",
                "So the king of Israel and Jehoshaphat the king of Judah went up to Ramoth Gilead.",
                "The king of Israel said to Jehoshaphat, \"I will disguise myself, and go into the battle; but you put on your robes.\" So the king of Israel disguised himself; and they went into the battle.",
                "Now the king of Syria had commanded the captains of his chariots, saying, \"Fight neither with small nor great, except only with the king of Israel.\"",
                "It happened, when the captains of the chariots saw Jehoshaphat, that they said, \"It is the king of Israel!\" Therefore they turned around to fight against him. But Jehoshaphat cried out, and Yahweh helped him; and God moved them to depart from him.",
                "It happened, when the captains of the chariots saw that it was not the king of Israel, that they turned back from pursuing him.",
                "A certain man drew his bow at random, and struck the king of Israel between the joints of the armor. Therefore he said to the driver of the chariot, \"Turn your hand, and carry me out of the army; for I am severely wounded.\"",
                "The battle increased that day. However the king of Israel propped himself up in his chariot against the Syrians until the evening; and about the time of the going down of the sun, he died."
            ],
            19: [
                "Jehoshaphat the king of Judah returned to his house in peace to Jerusalem.",
                "Jehu the son of Hanani the seer went out to meet him, and said to king Jehoshaphat, \"Should you help the wicked, and love those who hate Yahweh? Because of this, wrath is on you from before Yahweh.",
                "Nevertheless there are good things found in you, in that you have put away the Asheroth out of the land, and have set your heart to seek God.\"",
                "Jehoshaphat lived at Jerusalem: and he went out again among the people from Beersheba to the hill country of Ephraim, and brought them back to Yahweh, the God of their fathers.",
                "He set judges in the land throughout all the fortified cities of Judah, city by city,",
                "and said to the judges, \"Consider what you do: for you don\u2019t judge for man, but for Yahweh; and he is with you in the judgment.",
                "Now therefore let the fear of Yahweh be on you. Take heed and do it: for there is no iniquity with Yahweh our God, nor respect of persons, nor taking of bribes.\"",
                "Moreover in Jerusalem Jehoshaphat appointed Levites and priests, and of the heads of the fathers\u2019 households of Israel, for the judgment of Yahweh, and for controversies. They returned to Jerusalem.",
                "He commanded them, saying, \"Thus you shall do in the fear of Yahweh, faithfully, and with a perfect heart.",
                "Whenever any controversy shall come to you from your brothers who dwell in their cities, between blood and blood, between law and commandment, statutes and ordinances, you shall warn them, that they not be guilty towards Yahweh, and so wrath come on you and on your brothers. Do this, and you shall not be guilty.",
                "Behold, Amariah the chief priest is over you in all matters of Yahweh; and Zebadiah the son of Ishmael, the ruler of the house of Judah, in all the king\u2019s matters: also the Levites shall be officers before you. Deal courageously, and may Yahweh be with the good.\""
            ],
            20: [
                "It happened after this, that the children of Moab, and the children of Ammon, and with them some of the Ammonites, came against Jehoshaphat to battle.",
                "Then some came who told Jehoshaphat, saying, \"A great multitude is coming against you from beyond the sea from Syria. Behold, they are in Hazazon Tamar\" (that is, En Gedi).",
                "Jehoshaphat was alarmed, and set himself to seek to Yahweh. He proclaimed a fast throughout all Judah.",
                "Judah gathered themselves together, to seek help from Yahweh. They came out of all the cities of Judah to seek Yahweh.",
                "Jehoshaphat stood in the assembly of Judah and Jerusalem, in the house of Yahweh, before the new court;",
                "and he said, \"Yahweh, the God of our fathers, aren\u2019t you God in heaven? Aren\u2019t you ruler over all the kingdoms of the nations? Power and might are in your hand, so that no one is able to withstand you.",
                "Didn\u2019t you, our God, drive out the inhabitants of this land before your people Israel, and give it to the seed of Abraham your friend forever?",
                "They lived in it, and have built you a sanctuary in it for your name, saying,",
                "\u2018If evil comes on us\u2014the sword, judgment, pestilence, or famine\u2014we will stand before this house, and before you, (for your name is in this house), and cry to you in our affliction, and you will hear and save.\u2019",
                "Now, behold, the children of Ammon and Moab and Mount Seir, whom you would not let Israel invade, when they came out of the land of Egypt, but they turned aside from them, and didn\u2019t destroy them;",
                "behold, how they reward us, to come to cast us out of your possession, which you have given us to inherit.",
                "Our God, will you not judge them? For we have no might against this great company that comes against us; neither know we what to do, but our eyes are on you.\"",
                "All Judah stood before Yahweh, with their little ones, their wives, and their children.",
                "Then the Spirit of Yahweh came on Jahaziel the son of Zechariah, the son of Benaiah, the son of Jeiel, the son of Mattaniah, the Levite, of the sons of Asaph, in the midst of the assembly;",
                "and he said, \"Listen, all Judah, and you inhabitants of Jerusalem, and you king Jehoshaphat. Thus says Yahweh to you, \u2018Don\u2019t be afraid, neither be dismayed by reason of this great multitude; for the battle is not yours, but God\u2019s.",
                "Tomorrow go down against them. Behold, they are coming up by the ascent of Ziz. You shall find them at the end of the valley, before the wilderness of Jeruel.",
                "You will not need to fight this battle. Set yourselves, stand still, and see the salvation of Yahweh with you, O Judah and Jerusalem. Don\u2019t be afraid, nor be dismayed. Go out against them tomorrow, for Yahweh is with you.\u2019\"",
                "Jehoshaphat bowed his head with his face to the ground; and all Judah and the inhabitants of Jerusalem fell down before Yahweh, worshipping Yahweh.",
                "The Levites, of the children of the Kohathites and of the children of the Korahites, stood up to praise Yahweh, the God of Israel, with an exceeding loud voice.",
                "They rose early in the morning, and went forth into the wilderness of Tekoa: and as they went forth, Jehoshaphat stood and said, \"Listen to me, Judah, and you inhabitants of Jerusalem! Believe in Yahweh your God, so you shall be established! Believe his prophets, so you shall prosper.\"",
                "When he had taken counsel with the people, he appointed those who should sing to Yahweh, and give praise in holy array, as they went out before the army, and say, Give thanks to Yahweh; for his loving kindness endures forever.",
                "When they began to sing and to praise, Yahweh set ambushers against the children of Ammon, Moab, and Mount Seir, who had come against Judah; and they were struck.",
                "For the children of Ammon and Moab stood up against the inhabitants of Mount Seir, utterly to kill and destroy them: and when they had made an end of the inhabitants of Seir, everyone helped to destroy another.",
                "When Judah came to the place overlooking the wilderness, they looked at the multitude; and behold, they were dead bodies fallen to the earth, and there were none who escaped.",
                "When Jehoshaphat and his people came to take their spoil, they found among them in abundance both riches and dead bodies, and precious jewels, which they stripped off for themselves, more than they could carry away: and they were three days in taking the spoil, it was so much.",
                "On the fourth day they assembled themselves in the valley of Beracah; for there they blessed Yahweh: therefore the name of that place was called The valley of Beracah to this day.",
                "Then they returned, every man of Judah and Jerusalem, and Jehoshaphat in their forefront, to go again to Jerusalem with joy; for Yahweh had made them to rejoice over their enemies.",
                "They came to Jerusalem with stringed instruments and harps and trumpets to the house of Yahweh.",
                "The fear of God was on all the kingdoms of the countries, when they heard that Yahweh fought against the enemies of Israel.",
                "So the realm of Jehoshaphat was quiet; for his God gave him rest all around.",
                "Jehoshaphat reigned over Judah: he was thirty-five years old when he began to reign; and he reigned twenty-five years in Jerusalem: and his mother\u2019s name was Azubah the daughter of Shilhi.",
                "He walked in the way of Asa his father, and didn\u2019t turn aside from it, doing that which was right in the eyes of Yahweh.",
                "However the high places were not taken away; neither as yet had the people set their hearts to the God of their fathers.",
                "Now the rest of the acts of Jehoshaphat, first and last, behold, they are written in the history of Jehu the son of Hanani, which is inserted in the book of the kings of Israel.",
                "After this Jehoshaphat king of Judah joined himself with Ahaziah king of Israel. The same did very wickedly:",
                "and he joined himself with him to make ships to go to Tarshish; and they made the ships in Ezion Geber.",
                "Then Eliezer the son of Dodavahu of Mareshah prophesied against Jehoshaphat, saying, \"Because you have joined yourself with Ahaziah, Yahweh has destroyed your works.\" The ships were broken, so that they were not able to go to Tarshish."
            ],
            21: [
                "Jehoshaphat slept with his fathers, and was buried with his fathers in the city of David: and Jehoram his son reigned in his place.",
                "He had brothers, the sons of Jehoshaphat: Azariah, and Jehiel, and Zechariah, and Azariah, and Michael, and Shephatiah; all these were the sons of Jehoshaphat king of Israel.",
                "Their father gave them great gifts, of silver, and of gold, and of precious things, with fortified cities in Judah: but the kingdom gave he to Jehoram, because he was the firstborn.",
                "Now when Jehoram was risen up over the kingdom of his father, and had strengthened himself, he killed all his brothers with the sword, and various also of the princes of Israel.",
                "Jehoram was thirty-two years old when he began to reign; and he reigned eight years in Jerusalem.",
                "He walked in the way of the kings of Israel, as did the house of Ahab; for he had the daughter of Ahab as wife: and he did that which was evil in the sight of Yahweh.",
                "However Yahweh would not destroy the house of David, because of the covenant that he had made with David, and as he promised to give a lamp to him and to his children always.",
                "In his days Edom revolted from under the hand of Judah, and made a king over themselves.",
                "Then Jehoram passed over with his captains, and all his chariots with him: and he rose up by night, and struck the Edomites who surrounded him, along with the captains of the chariots.",
                "So Edom revolted from under the hand of Judah to this day: then Libnah revolted at the same time from under his hand, because he had forsaken Yahweh, the God of his fathers.",
                "Moreover he made high places in the mountains of Judah, and made the inhabitants of Jerusalem to play the prostitute, and led Judah astray.",
                "A letter came to him from Elijah the prophet, saying, \"Thus says Yahweh, the God of David your father, \u2018Because you have not walked in the ways of Jehoshaphat your father, nor in the ways of Asa king of Judah,",
                "but have walked in the way of the kings of Israel, and have made Judah and the inhabitants of Jerusalem to play the prostitute, like the house of Ahab did, and also have slain your brothers of your father\u2019s house, who were better than yourself:",
                "behold, Yahweh will strike with a great plague your people, and your children, and your wives, and all your substance;",
                "and you shall have great sickness by disease of your bowels, until your bowels fall out by reason of the sickness, day by day.\u2019\"",
                "Yahweh stirred up against Jehoram the spirit of the Philistines, and of the Arabians who are beside the Ethiopians:",
                "and they came up against Judah, and broke into it, and carried away all the substance that was found in the king\u2019s house, and his sons also, and his wives; so that there was never a son left him, except Jehoahaz, the youngest of his sons.",
                "After all this Yahweh struck him in his bowels with an incurable disease.",
                "It happened, in process of time, at the end of two years, that his bowels fell out by reason of his sickness, and he died of severe diseases. His people made no burning for him, like the burning of his fathers.",
                "Thirty-two years old was he when he began to reign, and he reigned in Jerusalem eight years: and he departed without being desired; and they buried him in the city of David, but not in the tombs of the kings."
            ],
            22: [
                "The inhabitants of Jerusalem made Ahaziah his youngest son king in his place; for the band of men who came with the Arabians to the camp had slain all the eldest. So Ahaziah the son of Jehoram king of Judah reigned.",
                "Forty-two years old was Ahaziah when he began to reign; and he reigned one year in Jerusalem: and his mother\u2019s name was Athaliah the daughter of Omri.",
                "He also walked in the ways of the house of Ahab; for his mother was his counselor to do wickedly.",
                "He did that which was evil in the sight of Yahweh, as did the house of Ahab; for they were his counselors after the death of his father, to his destruction.",
                "He walked also after their counsel, and went with Jehoram the son of Ahab king of Israel to war against Hazael king of Syria at Ramoth Gilead: and the Syrians wounded Joram.",
                "He returned to be healed in Jezreel of the wounds which they had given him at Ramah, when he fought against Hazael king of Syria. Azariah the son of Jehoram king of Judah went down to see Jehoram the son of Ahab in Jezreel, because he was sick.",
                "Now the destruction of Ahaziah was of God, in that he went to Joram: for when he had come, he went out with Jehoram against Jehu the son of Nimshi, whom Yahweh had anointed to cut off the house of Ahab.",
                "It happened, when Jehu was executing judgment on the house of Ahab, that he found the princes of Judah, and the sons of the brothers of Ahaziah, ministering to Ahaziah, and killed them.",
                "He sought Ahaziah, and they caught him (now he was hiding in Samaria), and they brought him to Jehu, and killed him; and they buried him, for they said, \"He is the son of Jehoshaphat, who sought Yahweh with all his heart.\" The house of Ahaziah had no power to hold the kingdom.",
                "Now when Athaliah the mother of Ahaziah saw that her son was dead, she arose and destroyed all the royal seed of the house of Judah.",
                "But Jehoshabeath, the daughter of the king, took Joash the son of Ahaziah, and stole him away from among the king\u2019s sons who were slain, and put him and his nurse in the bedroom. So Jehoshabeath, the daughter of king Jehoram, the wife of Jehoiada the priest (for she was the sister of Ahaziah), hid him from Athaliah, so that she didn\u2019t kill him.",
                "He was with them hidden in the house of God six years: and Athaliah reigned over the land."
            ],
            23: [
                "In the seventh year Jehoiada strengthened himself, and took the captains of hundreds, Azariah the son of Jeroham, and Ishmael the son of Jehohanan, and Azariah the son of Obed, and Maaseiah the son of Adaiah, and Elishaphat the son of Zichri, into covenant with him.",
                "They went about in Judah, and gathered the Levites out of all the cities of Judah, and the heads of fathers\u2019 households of Israel, and they came to Jerusalem.",
                "All the assembly made a covenant with the king in the house of God. He said to them, \"Behold, the king\u2019s son shall reign, as Yahweh has spoken concerning the sons of David.",
                "This is the thing that you shall do. A third part of you, who come in on the Sabbath, of the priests and of the Levites, shall be porters of the thresholds.",
                "A third part shall be at the king\u2019s house; and a third part at the gate of the foundation. All the people shall be in the courts of Yahweh\u2019s house.",
                "But let no one come into the house of Yahweh, except the priests, and those who minister of the Levites. They shall come in, for they are holy, but all the people shall follow Yahweh\u2019s instructions.",
                "The Levites shall surround the king, every man with his weapons in his hand. Whoever comes into the house, let him be slain. Be with the king when he comes in, and when he goes out.\"",
                "So the Levites and all Judah did according to all that Jehoiada the priest commanded: and they took every man his men, those who were to come in on the Sabbath; with those who were to go out on the Sabbath; for Jehoiada the priest didn\u2019t dismiss the shift.",
                "Jehoiada the priest delivered to the captains of hundreds the spears, and bucklers, and shields, that had been king David\u2019s, which were in the house of God.",
                "He set all the people, every man with his weapon in his hand, from the right side of the house to the left side of the house, along by the altar and the house, around the king.",
                "Then they brought out the king\u2019s son, and put the crown on him, and gave him the testimony, and made him king: and Jehoiada and his sons anointed him; and they said, \"Long live the king!\"",
                "When Athaliah heard the noise of the people running and praising the king, she came to the people into the house of Yahweh:",
                "and she looked, and, behold, the king stood by his pillar at the entrance, and the captains and the trumpets by the king; and all the people of the land rejoiced, and blew trumpets; the singers also played musical instruments, and led the singing of praise. Then Athaliah tore her clothes, and said, \"Treason! treason!\"",
                "Jehoiada the priest brought out the captains of hundreds who were set over the army, and said to them, \"Bring her out between the ranks; and whoever follows her, let him be slain with the sword.\" For the priest said, \"Don\u2019t kill her in the Yahweh\u2019s house.\"",
                "So they made way for her. She went to the entrance of the horse gate to the king\u2019s house; and they killed her there.",
                "Jehoiada made a covenant between himself, and all the people, and the king, that they should be Yahweh\u2019s people.",
                "All the people went to the house of Baal, and broke it down, and broke his altars and his images in pieces, and killed Mattan the priest of Baal before the altars.",
                "Jehoiada appointed the officers of the house of Yahweh under the hand of the priests the Levites, whom David had distributed in the house of Yahweh, to offer the burnt offerings of Yahweh, as it is written in the law of Moses, with rejoicing and with singing, according to the order of David.",
                "He set the porters at the gates of the house of Yahweh, that no one who was unclean in anything should enter in.",
                "He took the captains of hundreds, and the nobles, and the governors of the people, and all the people of the land, and brought down the king from the house of Yahweh: and they came through the upper gate to the king\u2019s house, and set the king on the throne of the kingdom.",
                "So all the people of the land rejoiced, and the city was quiet. Athaliah they had slain with the sword."
            ],
            24: [
                "Joash was seven years old when he began to reign; and he reigned forty years in Jerusalem: and his mother\u2019s name was Zibiah, of Beersheba.",
                "Joash did that which was right in the eyes of Yahweh all the days of Jehoiada the priest.",
                "Jehoiada took for him two wives; and he became the father of sons and daughters.",
                "It happened after this, that Joash intended to restore the house of Yahweh.",
                "He gathered together the priests and the Levites, and said to them, \"Go out to the cities of Judah, and gather money to repair the house of your God from all Israel from year to year. See that you expedite this matter.\" However the Levites didn\u2019t do it right away.",
                "The king called for Jehoiada the chief, and said to him, \"Why haven\u2019t you required of the Levites to bring in the tax of Moses the servant of Yahweh, and of the assembly of Israel, out of Judah and out of Jerusalem, for the tent of the testimony?\"",
                "For the sons of Athaliah, that wicked woman, had broken up the house of God; and they also gave all the dedicated things of the house of Yahweh to the Baals.",
                "So the king commanded, and they made a chest, and set it outside at the gate of the house of Yahweh.",
                "They made a proclamation through Judah and Jerusalem, to bring in for Yahweh the tax that Moses the servant of God laid on Israel in the wilderness.",
                "All the princes and all the people rejoiced, and brought in, and cast into the chest, until they had made an end.",
                "It was so, that whenever the chest was brought to the king\u2019s officers by the hand of the Levites, and when they saw that there was much money, the king\u2019s scribe and the chief priest\u2019s officer came and emptied the chest, and took it, and carried it to its place again. Thus they did day by day, and gathered money in abundance.",
                "The king and Jehoiada gave it to such as did the work of the service of the house of Yahweh; and they hired masons and carpenters to restore the house of Yahweh, and also such as worked iron and brass to repair the house of Yahweh.",
                "So the workmen worked, and the work of repairing went forward in their hands, and they set up the house of God in its state, and strengthened it.",
                "When they had made an end, they brought the rest of the money before the king and Jehoiada, of which were made vessels for the house of Yahweh, even vessels with which to minister and to offer, and spoons, and vessels of gold and silver. They offered burnt offerings in the house of Yahweh continually all the days of Jehoiada.",
                "But Jehoiada grew old and was full of days, and he died; one hundred thirty years old was he when he died.",
                "They buried him in the city of David among the kings, because he had done good in Israel, and toward God and his house.",
                "Now after the death of Jehoiada came the princes of Judah, and made obeisance to the king. Then the king listened to them.",
                "They forsook the house of Yahweh, the God of their fathers, and served the Asherim and the idols: and wrath came on Judah and Jerusalem for this their guiltiness.",
                "Yet he sent prophets to them, to bring them again to Yahweh; and they testified against them: but they would not give ear.",
                "The Spirit of God came on Zechariah the son of Jehoiada the priest; and he stood above the people, and said to them, \"Thus says God, \u2018Why do you disobey the commandments of Yahweh, so that you can\u2019t prosper? Because you have forsaken Yahweh, he has also forsaken you.\u2019\"",
                "They conspired against him, and stoned him with stones at the commandment of the king in the court of the house of Yahweh.",
                "Thus Joash the king didn\u2019t remember the kindness which Jehoiada his father had done to him, but killed his son. When he died, he said, \"May Yahweh look at it, and repay it.\"",
                "It happened at the end of the year, that the army of the Syrians came up against him: and they came to Judah and Jerusalem, and destroyed all the princes of the people from among the people, and sent all their spoil to the king of Damascus.",
                "For the army of the Syrians came with a small company of men; and Yahweh delivered a very great army into their hand, because they had forsaken Yahweh, the God of their fathers. So they executed judgment on Joash.",
                "When they were departed for him (for they left him very sick), his own servants conspired against him for the blood of the sons of Jehoiada the priest, and killed him on his bed, and he died; and they buried him in the city of David, but they didn\u2019t bury him in the tombs of the kings.",
                "These are those who conspired against him: Zabad the son of Shimeath the Ammonitess, and Jehozabad the son of Shimrith the Moabitess.",
                "Now concerning his sons, and the greatness of the burdens laid on him, and the rebuilding of the house of God, behold, they are written in the commentary of the book of the kings. Amaziah his son reigned in his place."
            ],
            25: [
                "Amaziah was twenty-five years old when he began to reign; and he reigned twenty-nine years in Jerusalem: and his mother\u2019s name was Jehoaddan, of Jerusalem.",
                "He did that which was right in the eyes of Yahweh, but not with a perfect heart.",
                "Now it happened, when the kingdom was established to him, that he killed his servants who had killed the king his father.",
                "But he didn\u2019t put their children to death, but did according to that which is written in the law in the book of Moses, as Yahweh commanded, saying, \"The fathers shall not die for the children, neither shall the children die for the fathers; but every man shall die for his own sin.\"",
                "Moreover Amaziah gathered Judah together, and ordered them according to their fathers\u2019 houses, under captains of thousands and captains of hundreds, even all Judah and Benjamin: and he numbered them from twenty years old and upward, and found them three hundred thousand chosen men, able to go forth to war, who could handle spear and shield.",
                "He hired also one hundred thousand mighty men of valor out of Israel for one hundred talents of silver.",
                "A man of God came to him, saying, \"O king, don\u2019t let the army of Israel go with you; for Yahweh is not with Israel, with all the children of Ephraim.",
                "But if you will go, take action, be strong for the battle. God will overthrow you before the enemy; for God has power to help, and to overthrow.\"",
                "Amaziah said to the man of God, \"But what shall we do for the hundred talents which I have given to the army of Israel?\" The man of God answered, \"Yahweh is able to give you much more than this.\"",
                "Then Amaziah separated them, the army that had come to him out of Ephraim, to go home again: therefore their anger was greatly kindled against Judah, and they returned home in fierce anger.",
                "Amaziah took courage, and led forth his people, and went to the Valley of Salt, and struck ten thousand of the children of Seir.",
                "The children of Judah carry away ten thousand alive, and brought them to the top of the rock, and threw them down from the top of the rock, so that they all were broken in pieces.",
                "But the men of the army whom Amaziah sent back, that they should not go with him to battle, fell on the cities of Judah, from Samaria even to Beth Horon, and struck of them three thousand, and took much spoil.",
                "Now it happened, after that Amaziah had come from the slaughter of the Edomites, that he brought the gods of the children of Seir, and set them up to be his gods, and bowed down himself before them, and burned incense to them.",
                "Therefore the anger of Yahweh was kindled against Amaziah, and he sent to him a prophet, who said to him, \"Why have you sought after the gods of the people, which have not delivered their own people out of your hand?\"",
                "It happened, as he talked with him, that the king said to him, \"Have we made you one of the king\u2019s counselors? Stop! Why should you be struck down?\" Then the prophet stopped, and said, \"I know that God has determined to destroy you, because you have done this, and have not listened to my counsel.\"",
                "Then Amaziah king of Judah consulted his advisers, and sent to Joash, the son of Jehoahaz the son of Jehu, king of Israel, saying, \"Come, let us look one another in the face.\"",
                "Joash king of Israel sent to Amaziah king of Judah, saying, \"The thistle that was in Lebanon sent to the cedar that was in Lebanon, saying, \u2018Give your daughter to my son as his wife; then a wild animal that was in Lebanon passed by, and trampled down the thistle.",
                "You say to yourself that you have struck Edom; and your heart lifts you up to boast. Now stay at home. Why should you meddle with trouble, that you should fall, even you, and Judah with you?\u2019\"",
                "But Amaziah would not listen; for it was of God, that he might deliver them into the hand of their enemies, because they had sought after the gods of Edom.",
                "So Joash king of Israel went up; and he and Amaziah king of Judah looked one another in the face at Beth Shemesh, which belongs to Judah.",
                "Judah was defeated by Israel; and they fled every man to his tent.",
                "Joash king of Israel took Amaziah king of Judah, the son of Joash the son of Jehoahaz, at Beth Shemesh, and brought him to Jerusalem, and broke down the wall of Jerusalem from the gate of Ephraim to the corner gate, four hundred cubits.",
                "He took all the gold and silver, and all the vessels that were found in the house of God with Obed-Edom, and the treasures of the king\u2019s house, the hostages also, and returned to Samaria.",
                "Amaziah the son of Joash king of Judah lived after the death of Joash son of Jehoahaz king of Israel fifteen years.",
                "Now the rest of the acts of Amaziah, first and last, behold, aren\u2019t they written in the book of the kings of Judah and Israel?",
                "Now from the time that Amaziah turned away from following Yahweh, they made a conspiracy against him in Jerusalem. He fled to Lachish; but they sent after him to Lachish, and killed him there.",
                "They brought him on horses, and buried him with his fathers in the city of Judah."
            ],
            26: [
                "All the people of Judah took Uzziah, who was sixteen years old, and made him king in the place of his father Amaziah.",
                "He built Eloth, and restored it to Judah, after that the king slept with his fathers.",
                "Sixteen years old was Uzziah when he began to reign; and he reigned fifty-two years in Jerusalem: and his mother\u2019s name was Jechiliah, of Jerusalem.",
                "He did that which was right in the eyes of Yahweh, according to all that his father Amaziah had done.",
                "He set himself to seek God in the days of Zechariah, who had understanding in the vision of God: and as long as he sought Yahweh, God made him to prosper.",
                "He went forth and warred against the Philistines, and broke down the wall of Gath, and the wall of Jabneh, and the wall of Ashdod; and he built cities in the country of Ashdod, and among the Philistines.",
                "God helped him against the Philistines, and against the Arabians who lived in Gur Baal, and the Meunim.",
                "The Ammonites gave tribute to Uzziah: and his name spread abroad even to the entrance of Egypt; for he grew exceeding strong.",
                "Moreover Uzziah built towers in Jerusalem at the corner gate, and at the valley gate, and at the turning of the wall, and fortified them.",
                "He built towers in the wilderness, and dug out many cisterns, for he had much livestock; in the lowland also, and in the plain: and he had farmers and vineyard keepers in the mountains and in the fruitful fields; for he loved farming.",
                "Moreover Uzziah had an army of fighting men, who went out to war by bands, according to the number of their reckoning made by Jeiel the scribe and Maaseiah the officer, under the hand of Hananiah, one of the king\u2019s captains.",
                "The whole number of the heads of fathers\u2019 households, even the mighty men of valor, was two thousand and six hundred.",
                "Under their hand was an army, three hundred thousand and seven thousand and five hundred, who made war with mighty power, to help the king against the enemy.",
                "Uzziah prepared for them, even for all the army, shields, and spears, and helmets, and coats of mail, and bows, and stones for slinging.",
                "He made in Jerusalem engines, invented by skillful men, to be on the towers and on the battlements, with which to shoot arrows and great stones. His name spread far abroad; for he was marvelously helped, until he was strong.",
                "But when he was strong, his heart was lifted up, so that he did corruptly, and he trespassed against Yahweh his God; for he went into the temple of Yahweh to burn incense on the altar of incense.",
                "Azariah the priest went in after him, and with him eighty priests of Yahweh, who were valiant men:",
                "and they resisted Uzziah the king, and said to him, \"It isn\u2019t for you, Uzziah, to burn incense to Yahweh, but for the priests the sons of Aaron, who are consecrated to burn incense. Go out of the sanctuary; for you have trespassed; neither shall it be for your honor from Yahweh God.\"",
                "Then Uzziah was angry; and he had a censer in his hand to burn incense; and while he was angry with the priests, the leprosy broke forth in his forehead before the priests in the house of Yahweh, beside the altar of incense.",
                "Azariah the chief priest, and all the priests, looked on him, and behold, he was leprous in his forehead, and they thrust him out quickly from there; yes, himself hurried also to go out, because Yahweh had struck him.",
                "Uzziah the king was a leper to the day of his death, and lived in a separate house, being a leper; for he was cut off from the house of Yahweh: and Jotham his son was over the king\u2019s house, judging the people of the land.",
                "Now the rest of the acts of Uzziah, first and last, Isaiah the prophet, the son of Amoz, wrote.",
                "So Uzziah slept with his fathers; and they buried him with his fathers in the field of burial which belonged to the kings; for they said, \"He is a leper.\" Jotham his son reigned in his place."
            ],
            27: [
                "Jotham was twenty-five years old when he began to reign; and he reigned sixteen years in Jerusalem: and his mother\u2019s name was Jerushah the daughter of Zadok.",
                "He did that which was right in the eyes of Yahweh, according to all that his father Uzziah had done: however he didn\u2019t enter into the temple of Yahweh. The people still did corruptly.",
                "He built the upper gate of the house of Yahweh, and on the wall of Ophel he built much.",
                "Moreover he built cities in the hill country of Judah, and in the forests he built castles and towers.",
                "He fought also with the king of the children of Ammon, and prevailed against them. The children of Ammon gave him the same year one hundred talents of silver, and ten thousand measures of wheat, and ten thousand of barley. The children of Ammon gave that much to him in the second year also, and in the third.",
                "So Jotham became mighty, because he ordered his ways before Yahweh his God.",
                "Now the rest of the acts of Jotham, and all his wars, and his ways, behold, they are written in the book of the kings of Israel and Judah.",
                "He was five and twenty years old when he began to reign, and reigned sixteen years in Jerusalem.",
                "Jotham slept with his fathers, and they buried him in the city of David: and Ahaz his son reigned in his place."
            ],
            28: [
                "Ahaz was twenty years old when he began to reign; and he reigned sixteen years in Jerusalem: and he didn\u2019t do that which was right in the eyes of Yahweh, like David his father;",
                "but he walked in the ways of the kings of Israel, and made also molten images for the Baals.",
                "Moreover he burnt incense in the valley of the son of Hinnom, and burnt his children in the fire, according to the abominations of the nations whom Yahweh cast out before the children of Israel.",
                "He sacrificed and burnt incense in the high places, and on the hills, and under every green tree.",
                "Therefore Yahweh his God delivered him into the hand of the king of Syria; and they struck him, and carried away of his a great multitude of captives, and brought them to Damascus. He was also delivered into the hand of the king of Israel, who struck him with a great slaughter.",
                "For Pekah the son of Remaliah killed in Judah one hundred twenty thousand in one day, all of them valiant men; because they had forsaken Yahweh, the God of their fathers.",
                "Zichri, a mighty man of Ephraim, killed Maaseiah the king\u2019s son, and Azrikam the ruler of the house, and Elkanah who was next to the king.",
                "The children of Israel carried away captive of their brothers two hundred thousand, women, sons, and daughters, and took also away much spoil from them, and brought the spoil to Samaria.",
                "But a prophet of Yahweh was there, whose name was Oded: and he went out to meet the army that came to Samaria, and said to them, \"Behold, because Yahweh, the God of your fathers, was angry with Judah, he has delivered them into your hand, and you have slain them in a rage which has reached up to heaven.",
                "Now you purpose to keep under the children of Judah and Jerusalem for bondservants and bondmaids for yourselves. Aren\u2019t there even with you trespasses of your own against Yahweh your God?",
                "Now hear me therefore, and send back the captives, that you have taken captive from your brothers; for the fierce wrath of Yahweh is on you.\"",
                "Then some of the heads of the children of Ephraim, Azariah the son of Johanan, Berechiah the son of Meshillemoth, and Jehizkiah the son of Shallum, and Amasa the son of Hadlai, stood up against those who came from the war,",
                "and said to them, \"You shall not bring in the captives here: for you purpose that which will bring on us a trespass against Yahweh, to add to our sins and to our trespass; for our trespass is great, and there is fierce wrath against Israel.\"",
                "So the armed men left the captives and the spoil before the princes and all the assembly.",
                "The men who have been mentioned by name rose up, and took the captives, and with the spoil clothed all who were naked among them, dressed them, gave them sandals, and gave them something to eat and to drink, anointed them, carried all the feeble of them on donkeys, and brought them to Jericho, the city of palm trees, to their brothers. Then they returned to Samaria.",
                "At that time king Ahaz sent to the kings of Assyria to help him.",
                "For again the Edomites had come and struck Judah, and carried away captives.",
                "The Philistines also had invaded the cities of the lowland, and of the South of Judah, and had taken Beth Shemesh, and Aijalon, and Gederoth, and Soco with its towns, and Timnah with its towns, Gimzo also and its towns: and they lived there.",
                "For Yahweh brought Judah low because of Ahaz king of Israel; for he had dealt wantonly in Judah, and trespassed severely against Yahweh.",
                "Tilgath Pilneser king of Assyria came to him, and distressed him, but didn\u2019t strengthen him.",
                "For Ahaz took away a portion out of the house of Yahweh, and out of the house of the king and of the princes, and gave it to the king of Assyria: but it didn\u2019t help him.",
                "In the time of his distress, he trespassed yet more against Yahweh, this same king Ahaz.",
                "For he sacrificed to the gods of Damascus, which struck him; and he said, \"Because the gods of the kings of Syria helped them, so I will sacrifice to them, that they may help me.\" But they were the ruin of him, and of all Israel.",
                "Ahaz gathered together the vessels of the house of God, and cut in pieces the vessels of the house of God, and shut up the doors of the house of Yahweh; and he made him altars in every corner of Jerusalem.",
                "In every city of Judah he made high places to burn incense to other gods, and provoked to anger Yahweh, the God of his fathers.",
                "Now the rest of his acts, and all his ways, first and last, behold, they are written in the book of the kings of Judah and Israel.",
                "Ahaz slept with his fathers, and they buried him in the city, even in Jerusalem; for they didn\u2019t bring him into the tombs of the kings of Israel: and Hezekiah his son reigned in his place."
            ],
            29: [
                "Hezekiah began to reign when he was twenty-five years old; and he reigned twenty-nine years in Jerusalem: and his mother\u2019s name was Abijah, the daughter of Zechariah.",
                "He did that which was right in the eyes of Yahweh, according to all that David his father had done.",
                "He in the first year of his reign, in the first month, opened the doors of the house of Yahweh, and repaired them.",
                "He brought in the priests and the Levites, and gathered them together into the broad place on the east,",
                "and said to them, \"Listen to me, you Levites! Now sanctify yourselves, and sanctify the house of Yahweh, the God of your fathers, and carry out the filthiness out of the holy place.",
                "For our fathers have trespassed, and done that which was evil in the sight of Yahweh our God, and have forsaken him, and have turned away their faces from the habitation of Yahweh, and turned their backs.",
                "Also they have shut up the doors of the porch, and put out the lamps, and have not burned incense nor offered burnt offerings in the holy place to the God of Israel.",
                "Therefore the wrath of Yahweh was on Judah and Jerusalem, and he has delivered them to be tossed back and forth, to be an astonishment, and a hissing, as you see with your eyes.",
                "For, behold, our fathers have fallen by the sword, and our sons and our daughters and our wives are in captivity for this.",
                "Now it is in my heart to make a covenant with Yahweh, the God of Israel, that his fierce anger may turn away from us.",
                "My sons, don\u2019t be negligent now; for Yahweh has chosen you to stand before him, to minister to him, and that you should be his ministers, and burn incense.\"",
                "Then the Levites arose, Mahath, the son of Amasai, and Joel the son of Azariah, of the sons of the Kohathites; and of the sons of Merari, Kish the son of Abdi, and Azariah the son of Jehallelel; and of the Gershonites, Joah the son of Zimmah, and Eden the son of Joah;",
                "and of the sons of Elizaphan, Shimri and Jeuel; and of the sons of Asaph, Zechariah and Mattaniah;",
                "and of the sons of Heman, Jehuel and Shimei; and of the sons of Jeduthun, Shemaiah and Uzziel.",
                "They gathered their brothers, and sanctified themselves, and went in, according to the commandment of the king by the words of Yahweh, to cleanse the house of Yahweh.",
                "The priests went in to the inner part of the house of Yahweh, to cleanse it, and brought out all the uncleanness that they found in the temple of Yahweh into the court of the house of Yahweh. The Levites took it, to carry it out abroad to the brook Kidron.",
                "Now they began on the first day of the first month to sanctify, and on the eighth day of the month came they to the porch of Yahweh; and they sanctified the house of Yahweh in eight days: and on the sixteenth day of the first month they made an end.",
                "Then they went in to Hezekiah the king within the palace, and said, \"We have cleansed all the house of Yahweh, and the altar of burnt offering, with all its vessels, and the table of show bread, with all its vessels.",
                "Moreover all the vessels, which king Ahaz in his reign threw away when he trespassed, have we prepared and sanctified; and behold, they are before the altar of Yahweh.\"",
                "Then Hezekiah the king arose early, and gathered the princes of the city, and went up to the house of Yahweh.",
                "They brought seven bulls, and seven rams, and seven lambs, and seven male goats, for a sin offering for the kingdom and for the sanctuary and for Judah. He commanded the priests the sons of Aaron to offer them on the altar of Yahweh.",
                "So they killed the bulls, and the priests received the blood, and sprinkled it on the altar: and they killed the rams, and sprinkled the blood on the altar: they killed also the lambs, and sprinkled the blood on the altar.",
                "They brought near the male goats for the sin offering before the king and the assembly; and they laid their hands on them:",
                "and the priests killed them, and they made a sin offering with their blood on the altar, to make atonement for all Israel; for the king commanded that the burnt offering and the sin offering should be made for all Israel.",
                "He set the Levites in the house of Yahweh with cymbals, with stringed instruments, and with harps, according to the commandment of David, and of Gad the king\u2019s seer, and Nathan the prophet; for the commandment was of Yahweh by his prophets.",
                "The Levites stood with the instruments of David, and the priests with the trumpets.",
                "Hezekiah commanded to offer the burnt offering on the altar. When the burnt offering began, the song of Yahweh began also, and the trumpets, together with the instruments of David king of Israel.",
                "All the assembly worshiped, and the singers sang, and the trumpeters sounded; all this continued until the burnt offering was finished.",
                "When they had made an end of offering, the king and all who were present with him bowed themselves and worshiped.",
                "Moreover Hezekiah the king and the princes commanded the Levites to sing praises to Yahweh with the words of David, and of Asaph the seer. They sang praises with gladness, and they bowed their heads and worshiped.",
                "Then Hezekiah answered, \"Now you have consecrated yourselves to Yahweh; come near and bring sacrifices and thank offerings into the house of Yahweh.\" The assembly brought in sacrifices and thank offerings; and as many as were of a willing heart brought burnt offerings.",
                "The number of the burnt offerings which the assembly brought was seventy bulls, one hundred rams, and two hundred lambs: all these were for a burnt offering to Yahweh.",
                "The consecrated things were six hundred head of cattle and three thousand sheep.",
                "But the priests were too few, so that they could not flay all the burnt offerings: therefore their brothers the Levites helped them, until the work was ended, and until the priests had sanctified themselves; for the Levites were more upright in heart to sanctify themselves than the priests.",
                "Also the burnt offerings were in abundance, with the fat of the peace offerings, and with the drink offerings for every burnt offering. So the service of the house of Yahweh was set in order.",
                "Hezekiah rejoiced, and all the people, because of that which God had prepared for the people: for the thing was done suddenly."
            ],
            30: [
                "Hezekiah sent to all Israel and Judah, and wrote letters also to Ephraim and Manasseh, that they should come to the house of Yahweh at Jerusalem, to keep the Passover to Yahweh, the God of Israel.",
                "For the king had taken counsel, and his princes, and all the assembly in Jerusalem, to keep the Passover in the second month.",
                "For they could not keep it at that time, because the priests had not sanctified themselves in sufficient number, neither had the people gathered themselves together to Jerusalem.",
                "The thing was right in the eyes of the king and of all the assembly.",
                "So they established a decree to make proclamation throughout all Israel, from Beersheba even to Dan, that they should come to keep the Passover to Yahweh, the God of Israel, at Jerusalem: for they had not kept it in great numbers in such sort as it is written.",
                "So the couriers went with the letters from the king and his princes throughout all Israel and Judah, and according to the commandment of the king, saying, \"You children of Israel, turn again to Yahweh, the God of Abraham, Isaac, and Israel, that he may return to the remnant that have escaped of you out of the hand of the kings of Assyria.",
                "Don\u2019t be like your fathers, and like your brothers, who trespassed against Yahweh, the God of their fathers, so that he gave them up to desolation, as you see.",
                "Now don\u2019t be stiff-necked, as your fathers were; but yield yourselves to Yahweh, and enter into his sanctuary, which he has sanctified forever, and serve Yahweh your God, that his fierce anger may turn away from you.",
                "For if you turn again to Yahweh, your brothers and your children shall find compassion before those who led them captive, and shall come again into this land: for Yahweh your God is gracious and merciful, and will not turn away his face from you, if you return to him.\"",
                "So the couriers passed from city to city through the country of Ephraim and Manasseh, even to Zebulun: but they ridiculed them, and mocked them.",
                "Nevertheless certain men of Asher and Manasseh and of Zebulun humbled themselves, and came to Jerusalem.",
                "Also on Judah came the hand of God to give them one heart, to do the commandment of the king and of the princes by the word of Yahweh.",
                "Many people assembled at Jerusalem to keep the feast of unleavened bread in the second month, a very great assembly.",
                "They arose and took away the altars that were in Jerusalem, and all the altars for incense took they away, and cast them into the brook Kidron.",
                "Then they killed the Passover on the fourteenth day of the second month: and the priests and the Levites were ashamed, and sanctified themselves, and brought burnt offerings into the house of Yahweh.",
                "They stood in their place after their order, according to the law of Moses the man of God: the priests sprinkled the blood which they received of the hand of the Levites.",
                "For there were many in the assembly who had not sanctified themselves: therefore the Levites were in charge of killing the Passovers for everyone who was not clean, to sanctify them to Yahweh.",
                "For a multitude of the people, even many of Ephraim and Manasseh, Issachar and Zebulun, had not cleansed themselves, yet they ate the Passover otherwise than it is written. For Hezekiah had prayed for them, saying, \"May the good Yahweh pardon everyone",
                "who sets his heart to seek God, Yahweh, the God of his fathers, even if they aren\u2019t clean according to the purification of the sanctuary.\"",
                "Yahweh listened to Hezekiah, and healed the people.",
                "The children of Israel who were present at Jerusalem kept the feast of unleavened bread seven days with great gladness; and the Levites and the priests praised Yahweh day by day, singing with loud instruments to Yahweh.",
                "Hezekiah spoke comfortably to all the Levites who had good understanding in the service of Yahweh. So they ate throughout the feast for the seven days, offering sacrifices of peace offerings, and making confession to Yahweh, the God of their fathers.",
                "The whole assembly took counsel to keep other seven days; and they kept another seven days with gladness.",
                "For Hezekiah king of Judah gave to the assembly for offerings one thousand bulls and seven thousand sheep; and the princes gave to the assembly a thousand bulls and ten thousand sheep: and a great number of priests sanctified themselves.",
                "All the assembly of Judah, with the priests and the Levites, and all the assembly who came out of Israel, and the foreigners who came out of the land of Israel, and who lived in Judah, rejoiced.",
                "So there was great joy in Jerusalem; for since the time of Solomon the son of David king of Israel there was not the like in Jerusalem.",
                "Then the priests the Levites arose and blessed the people: and their voice was heard, and their prayer came up to his holy habitation, even to heaven."
            ],
            31: [
                "Now when all this was finished, all Israel who were present went out to the cities of Judah, and broke in pieces the pillars, and cut down the Asherim, and broke down the high places and the altars out of all Judah and Benjamin, in Ephraim also and Manasseh, until they had destroyed them all. Then all the children of Israel returned, every man to his possession, into their own cities.",
                "Hezekiah appointed the divisions of the priests and the Levites after their divisions, every man according to his service, both the priests and the Levites, for burnt offerings and for peace offerings, to minister, and to give thanks, and to praise in the gates of the camp of Yahweh.",
                "He appointed also the king\u2019s portion of his substance for the burnt offerings, for the morning and evening burnt offerings, and the burnt offerings for the Sabbaths, and for the new moons, and for the set feasts, as it is written in the law of Yahweh.",
                "Moreover he commanded the people who lived in Jerusalem to give the portion of the priests and the Levites, that they might give themselves to the law of Yahweh.",
                "As soon as the commandment came abroad, the children of Israel gave in abundance the first fruits of grain, new wine, and oil, and honey, and of all the increase of the field; and the tithe of all things brought they in abundantly.",
                "The children of Israel and Judah, who lived in the cities of Judah, they also brought in the tithe of cattle and sheep, and the tithe of dedicated things which were consecrated to Yahweh their God, and laid them by heaps.",
                "In the third month they began to lay the foundation of the heaps, and finished them in the seventh month.",
                "When Hezekiah and the princes came and saw the heaps, they blessed Yahweh, and his people Israel.",
                "Then Hezekiah questioned the priests and the Levites concerning the heaps.",
                "Azariah the chief priest, of the house of Zadok, answered him and said, \"Since people began to bring the offerings into the house of Yahweh, we have eaten and had enough, and have left plenty: for Yahweh has blessed his people; and that which is left is this great store.\"",
                "Then Hezekiah commanded them to prepare rooms in the house of Yahweh; and they prepared them.",
                "They brought in the offerings and the tithes and the dedicated things faithfully: and over them Conaniah the Levite was ruler, and Shimei his brother was second.",
                "Jehiel, and Azaziah, and Nahath, and Asahel, and Jerimoth, and Jozabad, and Eliel, and Ismachiah, and Mahath, and Benaiah, were overseers under the hand of Conaniah and Shimei his brother, by the appointment of Hezekiah the king, and Azariah the ruler of the house of God.",
                "Kore the son of Imnah the Levite, the porter at the east gate, was over the freewill offerings of God, to distribute the offerings of Yahweh, and the most holy things.",
                "Under him were Eden, and Miniamin, and Jeshua, and Shemaiah, Amariah, and Shecaniah, in the cities of the priests, in their office of trust, to give to their brothers by divisions, as well to the great as to the small:",
                "besides those who were reckoned by genealogy of males, from three years old and upward, even everyone who entered into the house of Yahweh, as the duty of every day required, for their service in their offices according to their divisions;",
                "and those who were reckoned by genealogy of the priests by their fathers\u2019 houses, and the Levites from twenty years old and upward, in their offices by their divisions;",
                "and those who were reckoned by genealogy of all their little ones, their wives, and their sons, and their daughters, through all the congregation: for in their office of trust they sanctified themselves in holiness.",
                "Also for the sons of Aaron the priests, who were in the fields of the suburbs of their cities, in every city, there were men who were mentioned by name, to give portions to all the males among the priests, and to all who were reckoned by genealogy among the Levites.",
                "Hezekiah did so throughout all Judah; and he worked that which was good and right and faithful before Yahweh his God.",
                "In every work that he began in the service of the house of God, and in the law, and in the commandments, to seek his God, he did it with all his heart, and prospered."
            ],
            32: [
                "After these things, and this faithfulness, Sennacherib king of Assyria came, and entered into Judah, and encamped against the fortified cities, and thought to win them for himself.",
                "When Hezekiah saw that Sennacherib had come, and that he was purposed to fight against Jerusalem,",
                "he took counsel with his princes and his mighty men to stop the waters of the springs which were outside of the city; and they helped him.",
                "So many people gathered together, and they stopped all the springs, and the brook that flowed through the midst of the land, saying, \"Why should the kings of Assyria come, and find much water?\"",
                "He took courage, and built up all the wall that was broken down, and raised it up to the towers, and the other wall outside, and strengthened Millo in the city of David, and made weapons and shields in abundance.",
                "He set captains of war over the people, and gathered them together to him in the broad place at the gate of the city, and spoke comfortably to them, saying,",
                "\"Be strong and courageous, don\u2019t be afraid nor dismayed for the king of Assyria, nor for all the multitude who is with him; for there is a greater with us than with him.",
                "With him is an arm of flesh; but with us is Yahweh our God to help us, and to fight our battles.\" The people rested themselves on the words of Hezekiah king of Judah.",
                "After this, Sennacherib king of Assyria sent his servants to Jerusalem, (now he was before Lachish, and all his power with him), to Hezekiah king of Judah, and to all Judah who were at Jerusalem, saying,",
                "Thus says Sennacherib king of Assyria, \"In whom do you trust, that you remain under siege in Jerusalem?",
                "Doesn\u2019t Hezekiah persuade you, to give you over to die by famine and by thirst, saying, \u2018Yahweh our God will deliver us out of the hand of the king of Assyria?\u2019",
                "Hasn\u2019t the same Hezekiah taken away his high places and his altars, and commanded Judah and Jerusalem, saying, \u2018You shall worship before one altar, and on it you shall burn incense?\u2019",
                "Don\u2019t you know what I and my fathers have done to all the peoples of the lands? Were the gods of the nations of the lands in any way able to deliver their land out of my hand?",
                "Who was there among all the gods of those nations which my fathers utterly destroyed, that could deliver his people out of my hand, that your God should be able to deliver you out of my hand?",
                "Now therefore don\u2019t let Hezekiah deceive you, nor persuade you in this way, neither believe him; for no god of any nation or kingdom was able to deliver his people out of my hand, and out of the hand of my fathers. How much less will your God deliver you out of my hand?\"",
                "His servants spoke yet more against Yahweh God, and against his servant Hezekiah.",
                "He also wrote letters insulting Yahweh, the God of Israel, and to speak against him, saying, \"As the gods of the nations of the lands, which have not delivered their people out of my hand, so shall the God of Hezekiah not deliver his people out of my hand.\"",
                "They cried with a loud voice in the Jews\u2019 language to the people of Jerusalem who were on the wall, to frighten them, and to trouble them; that they might take the city.",
                "They spoke of the God of Jerusalem, as of the gods of the peoples of the earth, which are the work of men\u2019s hands.",
                "Hezekiah the king, and Isaiah the prophet the son of Amoz, prayed because of this, and cried to heaven.",
                "Yahweh sent an angel, who cut off all the mighty men of valor, and the leaders and captains, in the camp of the king of Assyria. So he returned with shame of face to his own land. When he had come into the house of his god, those who came forth from his own bowels killed him there with the sword.",
                "Thus Yahweh saved Hezekiah and the inhabitants of Jerusalem from the hand of Sennacherib the king of Assyria, and from the hand of all others, and guided them on every side.",
                "Many brought gifts to Yahweh to Jerusalem, and precious things to Hezekiah king of Judah; so that he was exalted in the sight of all nations from thenceforth.",
                "In those days Hezekiah was sick even to death: and he prayed to Yahweh; and he spoke to him, and gave him a sign.",
                "But Hezekiah didn\u2019t render again according to the benefit done to him; for his heart was lifted up: therefore there was wrath on him, and on Judah and Jerusalem.",
                "Notwithstanding Hezekiah humbled himself for the pride of his heart, both he and the inhabitants of Jerusalem, so that the wrath of Yahweh didn\u2019t come on them in the days of Hezekiah.",
                "Hezekiah had exceeding much riches and honor: and he provided him treasuries for silver, and for gold, and for precious stones, and for spices, and for shields, and for all kinds of goodly vessels;",
                "storehouses also for the increase of grain and new wine and oil; and stalls for all kinds of animals, and flocks in folds.",
                "Moreover he provided him cities, and possessions of flocks and herds in abundance; for God had given him very much substance.",
                "This same Hezekiah also stopped the upper spring of the waters of Gihon, and brought them straight down on the west side of the city of David. Hezekiah prospered in all his works.",
                "However concerning the ambassadors of the princes of Babylon, who sent to him to inquire of the wonder that was done in the land, God left him, to try him, that he might know all that was in his heart.",
                "Now the rest of the acts of Hezekiah, and his good deeds, behold, they are written in the vision of Isaiah the prophet the son of Amoz, in the book of the kings of Judah and Israel.",
                "Hezekiah slept with his fathers, and they buried him in the ascent of the tombs of the sons of David: and all Judah and the inhabitants of Jerusalem did him honor at his death. Manasseh his son reigned in his place."
            ],
            33: [
                "Manasseh was twelve years old when he began to reign; and he reigned fifty-five years in Jerusalem.",
                "He did that which was evil in the sight of Yahweh, after the abominations of the nations whom Yahweh cast out before the children of Israel.",
                "For he built again the high places which Hezekiah his father had broken down; and he reared up altars for the Baals, and made Asheroth, and worshiped all the army of the sky, and served them.",
                "He built altars in the house of Yahweh, of which Yahweh said, \"My name shall be in Jerusalem forever.\"",
                "He built altars for all the army of the sky in the two courts of the house of Yahweh.",
                "He also made his children to pass through the fire in the valley of the son of Hinnom; and he practiced sorcery, and used enchantments, and practiced sorcery, and dealt with those who had familiar spirits, and with wizards: he worked much evil in the sight of Yahweh, to provoke him to anger.",
                "He set the engraved image of the idol, which he had made, in the house of God, of which God said to David and to Solomon his son, \"In this house, and in Jerusalem, which I have chosen out of all the tribes of Israel, will I put my name forever:",
                "neither will I any more remove the foot of Israel from off the land which I have appointed for your fathers, if only they will observe to do all that I have commanded them, even all the law and the statutes and the ordinances given by Moses.\"",
                "Manasseh seduced Judah and the inhabitants of Jerusalem, so that they did more evil than the nations whom Yahweh destroyed before the children of Israel did.",
                "Yahweh spoke to Manasseh, and to his people; but they gave no heed.",
                "Therefore Yahweh brought on them the captains of the army of the king of Assyria, who took Manasseh in chains, and bound him with fetters, and carried him to Babylon.",
                "When he was in distress, he begged Yahweh his God, and humbled himself greatly before the God of his fathers.",
                "He prayed to him; and he was entreated by him, and heard his supplication, and brought him again to Jerusalem into his kingdom. Then Manasseh knew that Yahweh was God.",
                "Now after this he built an outer wall to the city of David, on the west side of Gihon, in the valley, even to the entrance at the fish gate; and he encircled Ophel with it, and raised it up to a very great height: and he put valiant captains in all the fortified cities of Judah.",
                "He took away the foreign gods, and the idol out of the house of Yahweh, and all the altars that he had built in the mountain of the house of Yahweh, and in Jerusalem, and cast them out of the city.",
                "He built up the altar of Yahweh, and offered thereon sacrifices of peace offerings and of thanksgiving, and commanded Judah to serve Yahweh, the God of Israel.",
                "Nevertheless the people sacrificed still in the high places, but only to Yahweh their God.",
                "Now the rest of the acts of Manasseh, and his prayer to his God, and the words of the seers who spoke to him in the name of Yahweh, the God of Israel, behold, they are written among the acts of the kings of Israel.",
                "His prayer also, and how God was entreated of him, and all his sin and his trespass, and the places in which he built high places, and set up the Asherim and the engraved images, before he humbled himself: behold, they are written in the history of Hozai.",
                "So Manasseh slept with his fathers, and they buried him in his own house: and Amon his son reigned in his place.",
                "Amon was twenty-two years old when he began to reign; and he reigned two years in Jerusalem.",
                "He did that which was evil in the sight of Yahweh, as did Manasseh his father; and Amon sacrificed to all the engraved images which Manasseh his father had made, and served them.",
                "He didn\u2019t humble himself before Yahweh, as Manasseh his father had humbled himself; but this same Amon trespassed more and more.",
                "His servants conspired against him, and put him to death in his own house.",
                "But the people of the land killed all those who had conspired against king Amon; and the people of the land made Josiah his son king in his place."
            ],
            34: [
                "Josiah was eight years old when he began to reign; and he reigned thirty-one years in Jerusalem.",
                "He did that which was right in the eyes of Yahweh, and walked in the ways of David his father, and didn\u2019t turn aside to the right hand or to the left.",
                "For in the eighth year of his reign, while he was yet young, he began to seek after the God of David his father; and in the twelfth year he began to purge Judah and Jerusalem from the high places, and the Asherim, and the engraved images, and the molten images.",
                "They broke down the altars of the Baals in his presence; and the incense altars that were on high above them he cut down; and the Asherim, and the engraved images, and the molten images, he broke in pieces, and made dust of them, and strewed it on the graves of those who had sacrificed to them.",
                "He burnt the bones of the priests on their altars, and purged Judah and Jerusalem.",
                "He did this in the cities of Manasseh and Ephraim and Simeon, even to Naphtali, around in their ruins.",
                "He broke down the altars, and beat the Asherim and the engraved images into powder, and cut down all the incense altars throughout all the land of Israel, and returned to Jerusalem.",
                "Now in the eighteenth year of his reign, when he had purged the land and the house, he sent Shaphan the son of Azaliah, and Maaseiah the governor of the city, and Joah the son of Joahaz the recorder, to repair the house of Yahweh his God.",
                "They came to Hilkiah the high priest, and delivered the money that was brought into the house of God, which the Levites, the keepers of the threshold, had gathered of the hand of Manasseh and Ephraim, and of all the remnant of Israel, and of all Judah and Benjamin, and of the inhabitants of Jerusalem.",
                "They delivered it into the hand of the workmen who had the oversight of the house of Yahweh; and the workmen who labored in the house of Yahweh gave it to mend and repair the house;",
                "even to the carpenters and to the builders gave they it, to buy cut stone, and timber for couplings, and to make beams for the houses which the kings of Judah had destroyed.",
                "The men did the work faithfully: and their overseers were Jahath and Obadiah, the Levites, of the sons of Merari; and Zechariah and Meshullam, of the sons of the Kohathites, to set it forward; and others of the Levites, all who were skillful with instruments of music.",
                "Also they were over the bearers of burdens, and set forward all who did the work in every kind of service: and of the Levites there were scribes, and officers, and porters.",
                "When they brought out the money that was brought into the house of Yahweh, Hilkiah the priest found the book of the law of Yahweh given by Moses.",
                "Hilkiah answered Shaphan the scribe, \"I have found the book of the law in the house of Yahweh.\" Hilkiah delivered the book to Shaphan.",
                "Shaphan carried the book to the king, and moreover brought back word to the king, saying, \"All that was committed to your servants, they are doing.",
                "They have emptied out the money that was found in the house of Yahweh, and have delivered it into the hand of the overseers, and into the hand of the workmen.\"",
                "Shaphan the scribe told the king, saying, \"Hilkiah the priest has delivered me a book.\" Shaphan read therein before the king.",
                "It happened, when the king had heard the words of the law, that he tore his clothes.",
                "The king commanded Hilkiah, and Ahikam the son of Shaphan, and Abdon the son of Micah, and Shaphan the scribe, and Asaiah the king\u2019s servant, saying,",
                "\"Go inquire of Yahweh for me, and for those who are left in Israel and in Judah, concerning the words of the book that is found; for great is the wrath of Yahweh that is poured out on us, because our fathers have not kept the word of Yahweh, to do according to all that is written in this book.\"",
                "So Hilkiah, and they whom the king had commanded, went to Huldah the prophetess, the wife of Shallum the son of Tokhath, the son of Hasrah, keeper of the wardrobe; (now she lived in Jerusalem in the second quarter;) and they spoke to her to that effect.",
                "She said to them, \"Thus says Yahweh, the God of Israel: \u2018Tell the man who sent you to me,",
                "\"Thus says Yahweh, \u2018Behold, I will bring evil on this place, and on its inhabitants, even all the curses that are written in the book which they have read before the king of Judah.",
                "Because they have forsaken me, and have burned incense to other gods, that they might provoke me to anger with all the works of their hands; therefore is my wrath poured out on this place, and it shall not be quenched.\u2019\"\u2019",
                "But to the king of Judah, who sent you to inquire of Yahweh, thus you shall tell him, \u2018Thus says Yahweh, the God of Israel: \"As touching the words which you have heard,",
                "because your heart was tender, and you humbled yourself before God, when you heard his words against this place, and against its inhabitants, and have humbled yourself before me, and have torn your clothes, and wept before me; I also have heard you,\" says Yahweh.",
                "\"Behold, I will gather you to your fathers, and you shall be gathered to your grave in peace, neither shall your eyes see all the evil that I will bring on this place, and on its inhabitants.\"\u2019\" They brought back word to the king.",
                "Then the king sent and gathered together all the elders of Judah and Jerusalem.",
                "The king went up to the house of Yahweh, and all the men of Judah and the inhabitants of Jerusalem, and the priests, and the Levites, and all the people, both great and small: and he read in their ears all the words of the book of the covenant that was found in the house of Yahweh.",
                "The king stood in his place, and made a covenant before Yahweh, to walk after Yahweh, and to keep his commandments, and his testimonies, and his statutes, with all his heart, and with all his soul, to perform the words of the covenant that were written in this book.",
                "He caused all who were found in Jerusalem and Benjamin to stand. The inhabitants of Jerusalem did according to the covenant of God, the God of their fathers.",
                "Josiah took away all the abominations out of all the countries that pertained to the children of Israel, and made all who were found in Israel to serve, even to serve Yahweh their God. All his days they didn\u2019t depart from following Yahweh, the God of their fathers."
            ],
            35: [
                "Josiah kept a Passover to Yahweh in Jerusalem: and they killed the Passover on the fourteenth day of the first month.",
                "He set the priests in their offices, and encouraged them to the service of the house of Yahweh.",
                "He said to the Levites who taught all Israel, who were holy to Yahweh, \"Put the holy ark in the house which Solomon the son of David king of Israel built. There shall no more be a burden on your shoulders. Now serve Yahweh your God, and his people Israel.",
                "Prepare yourselves after your fathers\u2019 houses by your divisions, according to the writing of David king of Israel, and according to the writing of Solomon his son.",
                "Stand in the holy place according to the divisions of the fathers\u2019 houses of your brothers the children of the people, and let there be for each a portion of a fathers\u2019 house of the Levites.",
                "Kill the Passover, and sanctify yourselves, and prepare for your brothers, to do according to the word of Yahweh by Moses.\"",
                "Josiah gave to the children of the people, of the flock, lambs and young goats, all of them for the Passover offerings, to all who were present, to the number of thirty thousand, and three thousand bulls: these were of the king\u2019s substance.",
                "His princes gave for a freewill offering to the people, to the priests, and to the Levites. Hilkiah and Zechariah and Jehiel, the rulers of the house of God, gave to the priests for the Passover offerings two thousand and six hundred small livestock, and three hundred head of cattle.",
                "Conaniah also, and Shemaiah and Nethanel, his brothers, and Hashabiah and Jeiel and Jozabad, the chiefs of the Levites, gave to the Levites for the Passover offerings five thousand small livestock, and five hundred head of cattle.",
                "So the service was prepared, and the priests stood in their place, and the Levites by their divisions, according to the king\u2019s commandment.",
                "They killed the Passover, and the priests sprinkled the blood which they received of their hand, and the Levites flayed them.",
                "They removed the burnt offerings, that they might give them according to the divisions of the fathers\u2019 houses of the children of the people, to offer to Yahweh, as it is written in the book of Moses. So they did with the cattle.",
                "They roasted the Passover with fire according to the ordinance: and the holy offerings boiled they in pots, and in caldrons, and in pans, and carried them quickly to all the children of the people.",
                "Afterward they prepared for themselves, and for the priests, because the priests the sons of Aaron were busy with offering the burnt offerings and the fat until night: therefore the Levites prepared for themselves, and for the priests the sons of Aaron.",
                "The singers the sons of Asaph were in their place, according to the commandment of David, and Asaph, and Heman, and Jeduthun the king\u2019s seer; and the porters were at every gate: they didn\u2019t need to depart from their service; for their brothers the Levites prepared for them.",
                "So all the service of Yahweh was prepared the same day, to keep the Passover, and to offer burnt offerings on the altar of Yahweh, according to the commandment of king Josiah.",
                "The children of Israel who were present kept the Passover at that time, and the feast of unleavened bread seven days.",
                "There was no Passover like that kept in Israel from the days of Samuel the prophet; neither did any of the kings of Israel keep such a Passover as Josiah kept, and the priests, and the Levites, and all Judah and Israel who were present, and the inhabitants of Jerusalem.",
                "In the eighteenth year of the reign of Josiah was this Passover kept.",
                "After all this, when Josiah had prepared the temple, Neco king of Egypt went up to fight against Carchemish by the Euphrates: and Josiah went out against him.",
                "But he sent ambassadors to him, saying, \"What have I to do with you, you king of Judah? I come not against you this day, but against the house with which I have war. God has commanded me to make haste. Beware that it is God who is with me, that he not destroy you.\"",
                "Nevertheless Josiah would not turn his face from him, but disguised himself, that he might fight with him, and didn\u2019t listen to the words of Neco from the mouth of God, and came to fight in the valley of Megiddo.",
                "The archers shot at king Josiah; and the king said to his servants, \"Take me away, because I am seriously wounded!\"",
                "So his servants took him out of the chariot, and put him in the second chariot that he had, and brought him to Jerusalem; and he died, and was buried in the tombs of his fathers. All Judah and Jerusalem mourned for Josiah.",
                "Jeremiah lamented for Josiah: and all the singing men and singing women spoke of Josiah in their lamentations to this day; and they made them an ordinance in Israel: and behold, they are written in the lamentations.",
                "Now the rest of the acts of Josiah, and his good deeds, according to that which is written in the law of Yahweh,",
                "and his acts, first and last, behold, they are written in the book of the kings of Israel and Judah."
            ],
            36: [
                "Then the people of the land took Jehoahaz the son of Josiah, and made him king in his father\u2019s place in Jerusalem.",
                "Joahaz was twenty-three years old when he began to reign; and he reigned three months in Jerusalem.",
                "The king of Egypt deposed him at Jerusalem, and fined the land one hundred talents of silver and a talent of gold.",
                "The king of Egypt made Eliakim his brother king over Judah and Jerusalem, and changed his name to Jehoiakim. Neco took Joahaz his brother, and carried him to Egypt.",
                "Jehoiakim was twenty-five years old when he began to reign; and he reigned eleven years in Jerusalem: and he did that which was evil in the sight of Yahweh his God.",
                "Against him came up Nebuchadnezzar king of Babylon, and bound him in fetters, to carry him to Babylon.",
                "Nebuchadnezzar also carried of the vessels of the house of Yahweh to Babylon, and put them in his temple at Babylon.",
                "Now the rest of the acts of Jehoiakim, and his abominations which he did, and that which was found in him, behold, they are written in the book of the kings of Israel and Judah: and Jehoiachin his son reigned in his place.",
                "Jehoiachin was eight years old when he began to reign; and he reigned three months and ten days in Jerusalem: and he did that which was evil in the sight of Yahweh.",
                "At the return of the year king Nebuchadnezzar sent, and brought him to Babylon, with the goodly vessels of the house of Yahweh, and made Zedekiah his brother king over Judah and Jerusalem.",
                "Zedekiah was twenty-one years old when he began to reign; and he reigned eleven years in Jerusalem:",
                "and he did that which was evil in the sight of Yahweh his God; he didn\u2019t humble himself before Jeremiah the prophet speaking from the mouth of Yahweh.",
                "He also rebelled against king Nebuchadnezzar, who had made him swear by God: but he stiffened his neck, and hardened his heart against turning to Yahweh, the God of Israel.",
                "Moreover all the chiefs of the priests, and the people, trespassed very greatly after all the abominations of the nations; and they polluted the house of Yahweh which he had made holy in Jerusalem.",
                "Yahweh, the God of their fathers, sent to them by his messengers, rising up early and sending, because he had compassion on his people, and on his dwelling place:",
                "but they mocked the messengers of God, and despised his words, and scoffed at his prophets, until the wrath of Yahweh arose against his people, until there was no remedy.",
                "Therefore he brought on them the king of the Chaldeans, who killed their young men with the sword in the house of their sanctuary, and had no compassion on young man or virgin, old man or gray-headed: he gave them all into his hand.",
                "All the vessels of the house of God, great and small, and the treasures of the house of Yahweh, and the treasures of the king, and of his princes, all these he brought to Babylon.",
                "They burnt the house of God, and broke down the wall of Jerusalem, and burnt all its palaces with fire, and destroyed all the goodly vessels of it.",
                "He carried those who had escaped from the sword away to Babylon; and they were servants to him and his sons until the reign of the kingdom of Persia:",
                "to fulfill the word of Yahweh by the mouth of Jeremiah, until the land had enjoyed its Sabbaths. As long as it lay desolate it kept Sabbath, to fulfill seventy years.",
                "Now in the first year of Cyrus king of Persia, that the word of Yahweh by the mouth of Jeremiah might be accomplished, Yahweh stirred up the spirit of Cyrus king of Persia, so that he made a proclamation throughout all his kingdom, and put it also in writing, saying,",
                "\"Thus says Cyrus king of Persia, \u2018Yahweh, the God of heaven, has given all the kingdoms of the earth to me; and he has commanded me to build him a house in Jerusalem, which is in Judah. Whoever there is among you of all his people, Yahweh his God be with him, and let him go up.\u2019\""
            ]
        },
        15: {
            1: [
                "Now in the first year of Cyrus king of Persia, that the word of Yahweh by the mouth of Jeremiah might be accomplished, Yahweh stirred up the spirit of Cyrus king of Persia, so that he made a proclamation throughout all his kingdom, and put it also in writing, saying,",
                "\"Thus says Cyrus king of Persia, \u2018Yahweh, the God of heaven, has given me all the kingdoms of the earth; and he has commanded me to build him a house in Jerusalem, which is in Judah.",
                "Whoever there is among you of all his people, may his God be with him, and let him go up to Jerusalem, which is in Judah, and build the house of Yahweh, the God of Israel (he is God), which is in Jerusalem.",
                "Whoever is left, in any place where he lives, let the men of his place help him with silver, with gold, with goods, and with animals, besides the freewill offering for the house of God which is in Jerusalem.\u2019\"",
                "Then the heads of fathers\u2019 households of Judah and Benjamin, and the priests, and the Levites, even all whose spirit God had stirred to go up rose up to build the house of Yahweh which is in Jerusalem.",
                "All those who were around them strengthened their hands with vessels of silver, with gold, with goods, and with animals, and with precious things, besides all that was willingly offered.",
                "Also Cyrus the king brought forth the vessels of the house of Yahweh, which Nebuchadnezzar had brought out of Jerusalem, and had put in the house of his gods;",
                "even those, Cyrus king of Persia brought out by the hand of Mithredath the treasurer, and numbered them to Sheshbazzar, the prince of Judah.",
                "This is the number of them: thirty platters of gold, one thousand platters of silver, twenty-nine knives,",
                "thirty bowls of gold, silver bowls of a second sort four hundred and ten, and other vessels one thousand.",
                "All the vessels of gold and of silver were five thousand and four hundred. Sheshbazzar brought all these up, when the captives were brought up from Babylon to Jerusalem."
            ],
            2: [
                "Now these are the children of the province, who went up out of the captivity of those who had been carried away, whom Nebuchadnezzar the king of Babylon had carried away to Babylon, and who returned to Jerusalem and Judah, everyone to his city;",
                "who came with Zerubbabel, Jeshua, Nehemiah, Seraiah, Reelaiah, Mordecai, Bilshan, Mispar, Bigvai, Rehum, Baanah. The number of the men of the people of Israel:",
                "The children of Parosh, two thousand one hundred seventy-two.",
                "The children of Shephatiah, three hundred seventy-two.",
                "The children of Arah, seven hundred seventy-five.",
                "The children of Pahathmoab, of the children of Jeshua and Joab, two thousand eight hundred twelve.",
                "The children of Elam, one thousand two hundred fifty-four.",
                "The children of Zattu, nine hundred forty-five.",
                "The children of Zaccai, seven hundred sixty.",
                "The children of Bani, six hundred forty-two.",
                "The children of Bebai, six hundred twenty-three.",
                "The children of Azgad, one thousand two hundred twenty-two.",
                "The children of Adonikam, six hundred sixty-six.",
                "The children of Bigvai, two thousand fifty-six.",
                "The children of Adin, four hundred fifty-four.",
                "The children of Ater, of Hezekiah, ninety-eight.",
                "The children of Bezai, three hundred twenty-three.",
                "The children of Jorah, one hundred twelve.",
                "The children of Hashum, two hundred twenty-three.",
                "The children of Gibbar, ninety-five.",
                "The children of Bethlehem, one hundred twenty-three.",
                "The men of Netophah, fifty-six.",
                "The men of Anathoth, one hundred twenty-eight.",
                "The children of Azmaveth, forty-two.",
                "The children of Kiriath Arim, Chephirah, and Beeroth, seven hundred forty-three.",
                "The children of Ramah and Geba, six hundred twenty-one.",
                "The men of Michmas, one hundred twenty-two.",
                "The men of Bethel and Ai, two hundred twenty-three.",
                "The children of Nebo, fifty-two.",
                "The children of Magbish, one hundred fifty-six.",
                "The children of the other Elam, one thousand two hundred fifty-four.",
                "The children of Harim, three hundred twenty.",
                "The children of Lod, Hadid, and Ono, seven hundred twenty-five.",
                "The children of Jericho, three hundred forty-five.",
                "The children of Senaah, three thousand six hundred thirty.",
                "The priests: the children of Jedaiah, of the house of Jeshua, nine hundred seventy-three.",
                "The children of Immer, one thousand fifty-two.",
                "The children of Pashhur, one thousand two hundred forty-seven.",
                "The children of Harim, one thousand seventeen.",
                "The Levites: the children of Jeshua and Kadmiel, of the children of Hodaviah, seventy-four.",
                "The singers: the children of Asaph, one hundred twenty-eight.",
                "The children of the porters: the children of Shallum, the children of Ater, the children of Talmon, the children of Akkub, the children of Hatita, the children of Shobai, in all one hundred thirty-nine.",
                "The Nethinim: the children of Ziha, the children of Hasupha, the children of Tabbaoth,",
                "the children of Keros, the children of Siaha, the children of Padon,",
                "the children of Lebanah, the children of Hagabah, the children of Akkub,",
                "the children of Hagab, the children of Shamlai, the children of Hanan,",
                "the children of Giddel, the children of Gahar, the children of Reaiah,",
                "the children of Rezin, the children of Nekoda, the children of Gazzam,",
                "the children of Uzza, the children of Paseah, the children of Besai,",
                "the children of Asnah, the children of Meunim, the children of Nephisim,",
                "the children of Bakbuk, the children of Hakupha, the children of Harhur,",
                "the children of Bazluth, the children of Mehida, the children of Harsha,",
                "the children of Barkos, the children of Sisera, the children of Temah,",
                "the children of Neziah, the children of Hatipha.",
                "The children of Solomon\u2019s servants: the children of Sotai, the children of Hassophereth, the children of Peruda,",
                "the children of Jaalah, the children of Darkon, the children of Giddel,",
                "the children of Shephatiah, the children of Hattil, the children of Pochereth Hazzebaim, the children of Ami.",
                "All the Nethinim, and the children of Solomon\u2019s servants, were three hundred ninety-two.",
                "These were those who went up from Tel Melah, Tel Harsha, Cherub, Addan, and Immer; but they could not show their fathers\u2019 houses, and their seed, whether they were of Israel:",
                "the children of Delaiah, the children of Tobiah, the children of Nekoda, six hundred fifty-two.",
                "Of the children of the priests: the children of Habaiah, the children of Hakkoz, the children of Barzillai, who took a wife of the daughters of Barzillai the Gileadite, and was called after their name.",
                "These sought their place among those who were registered by genealogy, but they were not found: therefore were they deemed polluted and put from the priesthood.",
                "The governor said to them, that they should not eat of the most holy things, until there stood up a priest with Urim and with Thummim.",
                "The whole assembly together was forty-two thousand three hundred sixty,",
                "besides their male servants and their female servants, of whom there were seven thousand three hundred thirty-seven: and they had two hundred singing men and singing women.",
                "Their horses were seven hundred thirty-six; their mules, two hundred forty-five;",
                "their camels, four hundred thirty-five; their donkeys, six thousand seven hundred and twenty.",
                "Some of the heads of fathers\u2019 households, when they came to the house of Yahweh which is in Jerusalem, offered willingly for the house of God to set it up in its place:",
                "they gave after their ability into the treasury of the work sixty-one thousand darics of gold, and five thousand minas of silver, and one hundred priests\u2019 garments.",
                "So the priests, and the Levites, and some of the people, and the singers, and the porters, and the Nethinim, lived in their cities, and all Israel in their cities."
            ],
            3: [
                "When the seventh month had come, and the children of Israel were in the cities, the people gathered themselves together as one man to Jerusalem.",
                "Then stood up Jeshua the son of Jozadak, and his brothers the priests, and Zerubbabel the son of Shealtiel, and his brothers, and built the altar of the God of Israel, to offer burnt offerings thereon, as it is written in the law of Moses the man of God.",
                "They set the altar on its base; for fear was on them because of the peoples of the countries: and they offered burnt offerings thereon to Yahweh, even burnt offerings morning and evening.",
                "They kept the feast of tents, as it is written, and offered the daily burnt offerings by number, according to the ordinance, as the duty of every day required;",
                "and afterward the continual burnt offering, and the offerings of the new moons, and of all the set feasts of Yahweh that were consecrated, and of everyone who willingly offered a freewill offering to Yahweh.",
                "From the first day of the seventh month began they to offer burnt offerings to Yahweh: but the foundation of the temple of Yahweh was not yet laid.",
                "They gave money also to the masons, and to the carpenters; and food, and drink, and oil, to them of Sidon, and to them of Tyre, to bring cedar trees from Lebanon to the sea, to Joppa, according to the grant that they had of Cyrus king of Persia.",
                "Now in the second year of their coming to the house of God at Jerusalem, in the second month, began Zerubbabel the son of Shealtiel, and Jeshua the son of Jozadak, and the rest of their brothers the priests and the Levites, and all those who were come out of the captivity to Jerusalem, and appointed the Levites, from twenty years old and upward, to have the oversight of the work of the house of Yahweh.",
                "Then stood Jeshua with his sons and his brothers, Kadmiel and his sons, the sons of Judah, together, to have the oversight of the workmen in the house of God: the sons of Henadad, with their sons and their brothers the Levites.",
                "When the builders laid the foundation of the temple of Yahweh, they set the priests in their clothing with trumpets, and the Levites the sons of Asaph with cymbals, to praise Yahweh, after the order of David king of Israel.",
                "They sang one to another in praising and giving thanks to Yahweh, \"For he is good, for his loving kindness endures forever toward Israel.\" All the people shouted with a great shout, when they praised Yahweh, because the foundation of the house of Yahweh was laid.",
                "But many of the priests and Levites and heads of fathers\u2019 households, the old men who had seen the first house, when the foundation of this house was laid before their eyes, wept with a loud voice; and many shouted aloud for joy:",
                "so that the people could not discern the noise of the shout of joy from the noise of the weeping of the people; for the people shouted with a loud shout, and the noise was heard afar off."
            ],
            4: [
                "Now when the adversaries of Judah and Benjamin heard that the children of the captivity were building a temple to Yahweh, the God of Israel;",
                "then they drew near to Zerubbabel, and to the heads of fathers\u2019 households, and said to them, \"Let us build with you; for we seek your God, as you do; and we sacrifice to him since the days of Esar Haddon king of Assyria, who brought us up here.\"",
                "But Zerubbabel, and Jeshua, and the rest of the heads of fathers\u2019 households of Israel, said to them, \"You have nothing to do with us in building a house to our God; but we ourselves together will build to Yahweh, the God of Israel, as king Cyrus the king of Persia has commanded us.\"",
                "Then the people of the land weakened the hands of the people of Judah, and troubled them in building,",
                "and hired counselors against them, to frustrate their purpose, all the days of Cyrus king of Persia, even until the reign of Darius king of Persia.",
                "In the reign of Ahasuerus, in the beginning of his reign, wrote they an accusation against the inhabitants of Judah and Jerusalem.",
                "In the days of Artaxerxes wrote Bishlam, Mithredath, Tabeel, and the rest of his companions, to Artaxerxes king of Persia; and the writing of the letter was written in Syrian, and set forth in the Syrian language.",
                "Rehum the chancellor and Shimshai the scribe wrote a letter against Jerusalem to Artaxerxes the king in this sort:",
                "then Rehum the chancellor, and Shimshai the scribe, and the rest of their companions, the Dinaites, and the Apharsathchites, the Tarpelites, the Apharsites, the Archevites, the Babylonians, the Shushanchites, the Dehaites, the Elamites,",
                "and the rest of the nations whom the great and noble Osnappar brought over, and set in the city of Samaria, and in the rest of the country beyond the River, and so forth, wrote.",
                "This is the copy of the letter that they sent to Artaxerxes the king: Your servants the men beyond the River, and so forth.",
                "Be it known to the king, that the Jews who came up from you have come to us to Jerusalem; they are building the rebellious and the bad city, and have finished the walls, and repaired the foundations.",
                "Be it known now to the king that if this city is built, and the walls finished, they will not pay tribute, custom, or toll, and in the end it will be hurtful to the kings.",
                "Now because we eat the salt of the palace, and it is not appropriate for us to see the king\u2019s dishonor, therefore have we sent and informed the king;",
                "that search may be made in the book of the records of your fathers: so you shall find in the book of the records, and know that this city is a rebellious city, and hurtful to kings and provinces, and that they have moved sedition within the same of old time; for which cause was this city laid waste.",
                "We inform the king that, if this city be built, and the walls finished, by this means you shall have no portion beyond the River.",
                "Then the king sent an answer to Rehum the chancellor, and to Shimshai the scribe, and to the rest of their companions who dwell in Samaria, and in the rest of the country beyond the River: Peace, and so forth.",
                "The letter which you sent to us has been plainly read before me.",
                "I decreed, and search has been made, and it is found that this city of old time has made insurrection against kings, and that rebellion and sedition have been made therein.",
                "There have been mighty kings also over Jerusalem, who have ruled over all the country beyond the River; and tribute, custom, and toll, was paid to them.",
                "Make a decree now to cause these men to cease, and that this city not be built, until a decree shall be made by me.",
                "Take heed that you not be slack herein: why should damage grow to the hurt of the kings?",
                "Then when the copy of king Artaxerxes\u2019 letter was read before Rehum, and Shimshai the scribe, and their companions, they went in haste to Jerusalem to the Jews, and made them to cease by force and power.",
                "Then ceased the work of the house of God which is at Jerusalem; and it ceased until the second year of the reign of Darius king of Persia."
            ],
            5: [
                "Now the prophets, Haggai the prophet, and Zechariah the son of Iddo, prophesied to the Jews who were in Judah and Jerusalem; in the name of the God of Israel they prophesied to them.",
                "Then rose up Zerubbabel the son of Shealtiel, and Jeshua the son of Jozadak, and began to build the house of God which is at Jerusalem; and with them were the prophets of God, helping them.",
                "At the same time came to them Tattenai, the governor beyond the River, and Shetharbozenai, and their companions, and said thus to them, \"Who gave you a decree to build this house, and to finish this wall?\"",
                "Then we told them in this way, what the names of the men were who were making this building.",
                "But the eye of their God was on the elders of the Jews, and they did not make them cease, until the matter should come to Darius, and then answer should be returned by letter concerning it.",
                "The copy of the letter that Tattenai, the governor beyond the River, and Shetharbozenai, and his companions the Apharsachites, who were beyond the River, sent to Darius the king;",
                "they sent a letter to him, in which was written thus: To Darius the king, all peace.",
                "Be it known to the king, that we went into the province of Judah, to the house of the great God, which is built with great stones, and timber is laid in the walls; and this work goes on with diligence and prospers in their hands.",
                "Then we asked those elders, and said to them thus, \"Who gave you a decree to build this house, and to finish this wall?\"",
                "We asked them their names also, to inform you that we might write the names of the men who were at their head.",
                "Thus they returned us answer, saying, \"We are the servants of the God of heaven and earth, and are building the house that was built these many years ago, which a great king of Israel built and finished.",
                "But after that our fathers had provoked the God of heaven to wrath, he gave them into the hand of Nebuchadnezzar king of Babylon, the Chaldean, who destroyed this house, and carried the people away into Babylon.",
                "But in the first year of Cyrus king of Babylon, Cyrus the king made a decree to build this house of God.",
                "The gold and silver vessels also of the house of God, which Nebuchadnezzar took out of the temple that was in Jerusalem, and brought into the temple of Babylon, those Cyrus the king took out of the temple of Babylon, and they were delivered to one whose name was Sheshbazzar, whom he had made governor;",
                "and he said to him, \u2018Take these vessels, go, put them in the temple that is in Jerusalem, and let the house of God be built in its place.\u2019",
                "Then the same Sheshbazzar came, and laid the foundations of the house of God which is in Jerusalem: and since that time even until now has it been in building, and yet it is not completed.",
                "Now therefore, if it seem good to the king, let a search be made in the king\u2019s treasure house, which is there at Babylon, whether it be so, that a decree was made of Cyrus the king to build this house of God at Jerusalem; and let the king send his pleasure to us concerning this matter.\""
            ],
            6: [
                "Then Darius the king made a decree, and search was made in the house of the archives, where the treasures were laid up in Babylon.",
                "There was found at Achmetha, in the palace that is in the province of Media, a scroll, and therein was thus written for a record:",
                "In the first year of Cyrus the king, Cyrus the king made a decree: Concerning the house of God at Jerusalem, let the house be built, the place where they offer sacrifices, and let its foundations be strongly laid; its height sixty cubits, and its breadth sixty cubits;",
                "with three courses of great stones, and a course of new timber: and let the expenses be given out of the king\u2019s house.",
                "Also let the gold and silver vessels of the house of God, which Nebuchadnezzar took forth out of the temple which is at Jerusalem, and brought to Babylon, be restored, and brought again to the temple which is at Jerusalem, everyone to its place; and you shall put them in the house of God.",
                "Now therefore, Tattenai, governor beyond the River, Shetharbozenai, and your companions the Apharsachites, who are beyond the River, you must stay far from there.",
                "Leave the work of this house of God alone; let the governor of the Jews and the elders of the Jews build this house of God in its place.",
                "Moreover I make a decree what you shall do to these elders of the Jews for the building of this house of God: that of the king\u2019s goods, even of the tribute beyond the River, expenses be given with all diligence to these men, that they be not hindered.",
                "That which they have need of, both young bulls, and rams, and lambs, for burnt offerings to the God of heaven; also wheat, salt, wine, and oil, according to the word of the priests who are at Jerusalem, let it be given them day by day without fail;",
                "that they may offer sacrifices of pleasant aroma to the God of heaven, and pray for the life of the king, and of his sons.",
                "Also I have made a decree, that whoever shall alter this word, let a beam be pulled out from his house, and let him be lifted up and fastened thereon; and let his house be made a dunghill for this:",
                "and the God who has caused his name to dwell there overthrow all kings and peoples who shall put forth their hand to alter the same, to destroy this house of God which is at Jerusalem. I Darius have made a decree; let it be done with all diligence.",
                "Then Tattenai, the governor beyond the River, Shetharbozenai, and their companions, because Darius the king had sent a decree, did accordingly with all diligence.",
                "The elders of the Jews built and prospered, through the prophesying of Haggai the prophet and Zechariah the son of Iddo. They built and finished it, according to the commandment of the God of Israel, and according to the decree of Cyrus, and Darius, and Artaxerxes king of Persia.",
                "This house was finished on the third day of the month Adar, which was in the sixth year of the reign of Darius the king.",
                "The children of Israel, the priests, and the Levites, and the rest of the children of the captivity, kept the dedication of this house of God with joy.",
                "They offered at the dedication of this house of God one hundred bulls, two hundred rams, four hundred lambs; and for a sin offering for all Israel, twelve male goats, according to the number of the tribes of Israel.",
                "They set the priests in their divisions, and the Levites in their courses, for the service of God, which is at Jerusalem; as it is written in the book of Moses.",
                "The children of the captivity kept the Passover on the fourteenth day of the first month.",
                "For the priests and the Levites had purified themselves together; all of them were pure: and they killed the Passover for all the children of the captivity, and for their brothers the priests, and for themselves.",
                "The children of Israel who had come again out of the captivity, and all such as had separated themselves to them from the filthiness of the nations of the land, to seek Yahweh, the God of Israel, ate,",
                "and kept the feast of unleavened bread seven days with joy: for Yahweh had made them joyful, and had turned the heart of the king of Assyria to them, to strengthen their hands in the work of the house of God, the God of Israel."
            ],
            7: [
                "Now after these things, in the reign of Artaxerxes king of Persia, Ezra the son of Seraiah, the son of Azariah, the son of Hilkiah,",
                "the son of Shallum, the son of Zadok, the son of Ahitub,",
                "the son of Amariah, the son of Azariah, the son of Meraioth,",
                "the son of Zerahiah, the son of Uzzi, the son of Bukki,",
                "the son of Abishua, the son of Phinehas, the son of Eleazar, the son of Aaron the chief priest;",
                "this Ezra went up from Babylon: and he was a ready scribe in the law of Moses, which Yahweh, the God of Israel, had given; and the king granted him all his request, according to the hand of Yahweh his God on him.",
                "There went up some of the children of Israel, and of the priests, and the Levites, and the singers, and the porters, and the Nethinim, to Jerusalem, in the seventh year of Artaxerxes the king.",
                "He came to Jerusalem in the fifth month, which was in the seventh year of the king.",
                "For on the first day of the first month began he to go up from Babylon; and on the first day of the fifth month came he to Jerusalem, according to the good hand of his God on him.",
                "For Ezra had set his heart to seek the law of Yahweh, and to do it, and to teach in Israel statutes and ordinances.",
                "Now this is the copy of the letter that the king Artaxerxes gave to Ezra the priest, the scribe, even the scribe of the words of the commandments of Yahweh, and of his statutes to Israel:",
                "Artaxerxes, king of kings, to Ezra the priest, the scribe of the law of the God of heaven, perfect and so forth.",
                "I make a decree, that all those of the people of Israel, and their priests and the Levites, in my realm, who are minded of their own free will to go to Jerusalem, go with you.",
                "Because you are sent of the king and his seven counselors, to inquire concerning Judah and Jerusalem, according to the law of your God which is in your hand,",
                "and to carry the silver and gold, which the king and his counselors have freely offered to the God of Israel, whose habitation is in Jerusalem,",
                "and all the silver and gold that you shall find in all the province of Babylon, with the freewill offering of the people, and of the priests, offering willingly for the house of their God which is in Jerusalem;",
                "therefore you shall with all diligence buy with this money bulls, rams, lambs, with their meal offerings and their drink offerings, and shall offer them on the altar of the house of your God which is in Jerusalem.",
                "Whatever shall seem good to you and to your brothers to do with the rest of the silver and the gold, do that after the will of your God.",
                "The vessels that are given to you for the service of the house of your God, deliver before the God of Jerusalem.",
                "Whatever more shall be needful for the house of your God, which you shall have occasion to bestow, bestow it out of the king\u2019s treasure house.",
                "I, even I Artaxerxes the king, do make a decree to all the treasurers who are beyond the River, that whatever Ezra the priest, the scribe of the law of the God of heaven, shall require of you, it be done with all diligence,",
                "to one hundred talents of silver, and to one hundred measures of wheat, and to one hundred baths of wine, and to one hundred baths of oil, and salt without prescribing how much.",
                "Whatever is commanded by the God of heaven, let it be done exactly for the house of the God of heaven; for why should there be wrath against the realm of the king and his sons?",
                "Also we inform you, that touching any of the priests and Levites, the singers, porters, Nethinim, or servants of this house of God, it shall not be lawful to impose tribute, custom, or toll, on them.",
                "You, Ezra, after the wisdom of your God who is in your hand, appoint magistrates and judges, who may judge all the people who are beyond the River, all such as know the laws of your God; and teach him who doesn\u2019t know them.",
                "Whoever will not do the law of your God, and the law of the king, let judgment be executed on him with all diligence, whether it be to death, or to banishment, or to confiscation of goods, or to imprisonment.",
                "Blessed be Yahweh, the God of our fathers, who has put such a thing as this in the king\u2019s heart, to beautify the house of Yahweh which is in Jerusalem;",
                "and has extended loving kindness to me before the king, and his counselors, and before all the king\u2019s mighty princes. I was strengthened according to the hand of Yahweh my God on me, and I gathered together out of Israel chief men to go up with me."
            ],
            8: [
                "Now these are the heads of their fathers\u2019 households, and this is the genealogy of those who went up with me from Babylon, in the reign of Artaxerxes the king:",
                "Of the sons of Phinehas, Gershom. Of the sons of Ithamar, Daniel. Of the sons of David, Hattush.",
                "Of the sons of Shecaniah, of the sons of Parosh, Zechariah; and with him were reckoned by genealogy of the males one hundred fifty.",
                "Of the sons of Pahathmoab, Eliehoenai the son of Zerahiah; and with him two hundred males.",
                "Of the sons of Shecaniah, the son of Jahaziel; and with him three hundred males.",
                "Of the sons of Adin, Ebed the son of Jonathan; and with him fifty males.",
                "Of the sons of Elam, Jeshaiah the son of Athaliah; and with him seventy males.",
                "Of the sons of Shephatiah, Zebadiah the son of Michael; and with him eighty males.",
                "Of the sons of Joab, Obadiah the son of Jehiel; and with him two hundred and eighteen males.",
                "Of the sons of Shelomith, the son of Josiphiah; and with him one hundred sixty males.",
                "Of the sons of Bebai, Zechariah the son of Bebai; and with him twenty-eight males.",
                "Of the sons of Azgad, Johanan the son of Hakkatan; and with him one hundred ten males.",
                "Of the sons of Adonikam, who were the last; and these are their names: Eliphelet, Jeuel, and Shemaiah; and with them sixty males.",
                "Of the sons of Bigvai, Uthai and Zabbud; and with them seventy males.",
                "I gathered them together to the river that runs to Ahava; and there we encamped three days: and I viewed the people, and the priests, and found there none of the sons of Levi.",
                "Then sent I for Eliezer, for Ariel, for Shemaiah, and for Elnathan, and for Jarib, and for Elnathan, and for Nathan, and for Zechariah, and for Meshullam, chief men; also for Joiarib, and for Elnathan, who were teachers.",
                "I sent them forth to Iddo the chief at the place Casiphia; and I told them what they should tell Iddo, and his brothers the Nethinim, at the place Casiphia, that they should bring to us ministers for the house of our God.",
                "According to the good hand of our God on us they brought us a man of discretion, of the sons of Mahli, the son of Levi, the son of Israel; and Sherebiah, with his sons and his brothers, eighteen;",
                "and Hashabiah, and with him Jeshaiah of the sons of Merari, his brothers and their sons, twenty;",
                "and of the Nethinim, whom David and the princes had given for the service of the Levites, two hundred and twenty Nethinim: all of them were mentioned by name.",
                "Then I proclaimed a fast there, at the river Ahava, that we might humble ourselves before our God, to seek of him a straight way for us, and for our little ones, and for all our substance.",
                "For I was ashamed to ask of the king a band of soldiers and horsemen to help us against the enemy in the way, because we had spoken to the king, saying, \"The hand of our God is on all those who seek him, for good; but his power and his wrath is against all those who forsake him.\"",
                "So we fasted and begged our God for this: and he was entreated of us.",
                "Then I set apart twelve of the chiefs of the priests, even Sherebiah, Hashabiah, and ten of their brothers with them,",
                "and weighed to them the silver, and the gold, and the vessels, even the offering for the house of our God, which the king, and his counselors, and his princes, and all Israel there present, had offered:",
                "I weighed into their hand six hundred fifty talents of silver, and silver vessels one hundred talents; of gold one hundred talents;",
                "and twenty bowls of gold, of one thousand darics; and two vessels of fine bright brass, precious as gold.",
                "I said to them, \"You are holy to Yahweh, and the vessels are holy; and the silver and the gold are a freewill offering to Yahweh, the God of your fathers.",
                "Watch, and keep them, until you weigh them before the chiefs of the priests and the Levites, and the princes of the fathers\u2019 households of Israel, at Jerusalem, in the rooms of the house of Yahweh.\"",
                "So the priests and the Levites received the weight of the silver and the gold, and the vessels, to bring them to Jerusalem to the house of our God.",
                "Then we departed from the river Ahava on the twelfth day of the first month, to go to Jerusalem: and the hand of our God was on us, and he delivered us from the hand of the enemy and the bandit by the way.",
                "We came to Jerusalem, and stayed there three days.",
                "On the fourth day the silver and the gold and the vessels were weighed in the house of our God into the hand of Meremoth the son of Uriah the priest; and with him was Eleazar the son of Phinehas; and with them was Jozabad the son of Jeshua, and Noadiah the son of Binnui, the Levite;",
                "the whole by number and by weight: and all the weight was written at that time.",
                "The children of the captivity, who had come out of exile, offered burnt offerings to the God of Israel, twelve bulls for all Israel, ninety-six rams, seventy-seven lambs, and twelve male goats for a sin offering: all this was a burnt offering to Yahweh.",
                "They delivered the king\u2019s commissions to the king\u2019s satraps, and to the governors beyond the River: and they furthered the people and the house of God."
            ],
            9: [
                "Now when these things were done, the princes drew near to me, saying, \"The people of Israel, and the priests and the Levites, have not separated themselves from the peoples of the lands, following their abominations, even those of the Canaanites, the Hittites, the Perizzites, the Jebusites, the Ammonites, the Moabites, the Egyptians, and the Amorites.",
                "For they have taken of their daughters for themselves and for their sons, so that the holy seed have mixed themselves with the peoples of the lands. Yes, the hand of the princes and rulers has been chief in this trespass.\"",
                "When I heard this thing, I tore my garment and my robe, and plucked off the hair of my head and of my beard, and sat down confounded.",
                "Then were assembled to me everyone who trembled at the words of the God of Israel, because of their trespass of the captivity; and I sat confounded until the evening offering.",
                "At the evening offering I arose up from my humiliation, even with my garment and my robe torn; and I fell on my knees, and spread out my hands to Yahweh my God;",
                "and I said, \"My God, I am ashamed and blush to lift up my face to you, my God; for our iniquities have increased over our head, and our guiltiness has grown up to the heavens.",
                "Since the days of our fathers we have been exceeding guilty to this day; and for our iniquities we, our kings, and our priests, have been delivered into the hand of the kings of the lands, to the sword, to captivity, to plunder, and to confusion of face, as it is this day.",
                "Now for a little moment grace has been shown from Yahweh our God, to leave us a remnant to escape, and to give us a nail in his holy place, that our God may lighten our eyes, and give us a little reviving in our bondage.",
                "For we are bondservants; yet our God has not forsaken us in our bondage, but has extended loving kindness to us in the sight of the kings of Persia, to give us a reviving, to set up the house of our God, and to repair its ruins, and to give us a wall in Judah and in Jerusalem.",
                "\"Now, our God, what shall we say after this? For we have forsaken your commandments,",
                "which you have commanded by your servants the prophets, saying, \u2018The land, to which you go to possess it, is an unclean land through the uncleanness of the peoples of the lands, through their abominations, which have filled it from one end to another with their filthiness.",
                "Now therefore don\u2019t give your daughters to their sons, neither take their daughters to your sons, nor seek their peace or their prosperity forever; that you may be strong, and eat the good of the land, and leave it for an inheritance to your children forever.\u2019",
                "\"After all that has come on us for our evil deeds, and for our great guilt, since you, our God, have punished us less than our iniquities deserve, and have given us such a remnant,",
                "shall we again break your commandments, and join in affinity with the peoples that do these abominations? Wouldn\u2019t you be angry with us until you had consumed us, so that there should be no remnant, nor any to escape?",
                "Yahweh, the God of Israel, you are righteous; for we are left a remnant that has escaped, as it is this day. Behold, we are before you in our guiltiness; for none can stand before you because of this.\""
            ],
            10: [
                "Now while Ezra prayed and made confession, weeping and casting himself down before the house of God, there was gathered together to him out of Israel a very great assembly of men and women and children; for the people wept very bitterly.",
                "Shecaniah the son of Jehiel, one of the sons of Elam, answered Ezra, \"We have trespassed against our God, and have married foreign women of the peoples of the land. Yet now there is hope for Israel concerning this thing.",
                "Now therefore let us make a covenant with our God to put away all the wives, and such as are born of them, according to the counsel of my lord, and of those who tremble at the commandment of our God. Let it be done according to the law.",
                "Arise; for the matter belongs to you, and we are with you. Be courageous, and do it.\"",
                "Then Ezra arose, and made the chiefs of the priests, the Levites, and all Israel, to swear that they would do according to this word. So they swore.",
                "Then Ezra rose up from before the house of God, and went into the room of Jehohanan the son of Eliashib: and when he came there, he ate no bread, nor drank water; for he mourned because of their trespass of the captivity.",
                "They made proclamation throughout Judah and Jerusalem to all the children of the captivity, that they should gather themselves together to Jerusalem;",
                "and that whoever didn\u2019t come within three days, according to the counsel of the princes and the elders, all his substance should be forfeited, and himself separated from the assembly of the captivity.",
                "Then all the men of Judah and Benjamin gathered themselves together to Jerusalem within the three days; it was the ninth month, on the twentieth day of the month: and all the people sat in the broad place before the house of God, trembling because of this matter, and for the great rain.",
                "Ezra the priest stood up, and said to them, \"You have trespassed, and have married foreign women, to increase the guilt of Israel.",
                "Now therefore make confession to Yahweh, the God of your fathers, and do his pleasure; and separate yourselves from the peoples of the land, and from the foreign women.\"",
                "Then all the assembly answered with a loud voice, \"As you have said concerning us, so must we do.",
                "But the people are many, and it is a time of much rain, and we are not able to stand outside; neither is this a work of one day or two; for we have greatly transgressed in this matter.",
                "Let now our princes be appointed for all the assembly, and let all those who are in our cities who have married foreign women come at appointed times, and with them the elders of every city, and its judges, until the fierce wrath of our God be turned from us, until this matter is resolved.\"",
                "Only Jonathan the son of Asahel and Jahzeiah the son of Tikvah stood up against this; and Meshullam and Shabbethai the Levite helped them.",
                "The children of the captivity did so. Ezra the priest, with certain heads of fathers\u2019 households, after their fathers\u2019 houses, and all of them by their names, were set apart; and they sat down in the first day of the tenth month to examine the matter.",
                "They made an end with all the men who had married foreign women by the first day of the first month.",
                "Among the sons of the priests there were found who had married foreign women: of the sons of Jeshua, the son of Jozadak, and his brothers, Maaseiah, and Eliezer, and Jarib, and Gedaliah.",
                "They gave their hand that they would put away their wives; and being guilty, they offered a ram of the flock for their guilt.",
                "Of the sons of Immer: Hanani and Zebadiah.",
                "Of the sons of Harim: Maaseiah, and Elijah, and Shemaiah, and Jehiel, and Uzziah.",
                "Of the sons of Pashhur: Elioenai, Maaseiah, Ishmael, Nethanel, Jozabad, and Elasah.",
                "Of the Levites: Jozabad, and Shimei, and Kelaiah (the same is Kelita), Pethahiah, Judah, and Eliezer.",
                "Of the singers: Eliashib. Of the porters: Shallum, and Telem, and Uri.",
                "Of Israel: Of the sons of Parosh: Ramiah, and Izziah, and Malchijah, and Mijamin, and Eleazar, and Malchijah, and Benaiah.",
                "Of the sons of Elam: Mattaniah, Zechariah, and Jehiel, and Abdi, and Jeremoth, and Elijah.",
                "Of the sons of Zattu: Elioenai, Eliashib, Mattaniah, and Jeremoth, and Zabad, and Aziza.",
                "Of the sons of Bebai: Jehohanan, Hananiah, Zabbai, Athlai.",
                "Of the sons of Bani: Meshullam, Malluch, and Adaiah, Jashub, and Sheal, Jeremoth.",
                "Of the sons of Pahathmoab: Adna, and Chelal, Benaiah, Maaseiah, Mattaniah, Bezalel, and Binnui, and Manasseh.",
                "Of the sons of Harim: Eliezer, Isshijah, Malchijah, Shemaiah, Shimeon,",
                "Benjamin, Malluch, Shemariah.",
                "Of the sons of Hashum: Mattenai, Mattattah, Zabad, Eliphelet, Jeremai, Manasseh, Shimei.",
                "Of the sons of Bani: Maadai, Amram, and Uel,",
                "Benaiah, Bedeiah, Cheluhi,",
                "Vaniah, Meremoth, Eliashib,",
                "Mattaniah, Mattenai, and Jaasu,",
                "and Bani, and Binnui, Shimei,",
                "and Shelemiah, and Nathan, and Adaiah,",
                "Machnadebai, Shashai, Sharai,",
                "Azarel, and Shelemiah, Shemariah,",
                "Shallum, Amariah, Joseph.",
                "Of the sons of Nebo: Jeiel, Mattithiah, Zabad, Zebina, Iddo, and Joel, Benaiah.",
                "All these had taken foreign wives; and some of them had wives by whom they had children."
            ]
        },
        16: {
            1: [
                "The words of Nehemiah the son of Hacaliah. Now it happened in the month Chislev, in the twentieth year, as I was in Shushan the palace,",
                "that Hanani, one of my brothers, came, he and certain men out of Judah; and I asked them concerning the Jews who had escaped, who were left of the captivity, and concerning Jerusalem.",
                "They said to me, \"The remnant who are left of the captivity there in the province are in great affliction and reproach. The wall of Jerusalem also is broken down, and its gates are burned with fire.\"",
                "It happened, when I heard these words, that I sat down and wept, and mourned certain days; and I fasted and prayed before the God of heaven,",
                "and said, \"I beg you, Yahweh, the God of heaven, the great and awesome God, who keeps covenant and loving kindness with those who love him and keep his commandments:",
                "Let your ear now be attentive, and your eyes open, that you may listen to the prayer of your servant, which I pray before you at this time, day and night, for the children of Israel your servants while I confess the sins of the children of Israel, which we have sinned against you. Yes, I and my father\u2019s house have sinned.",
                "We have dealt very corruptly against you, and have not kept the commandments, nor the statutes, nor the ordinances, which you commanded your servant Moses.",
                "\"Remember, I beg you, the word that you commanded your servant Moses, saying, \u2018If you trespass, I will scatter you abroad among the peoples;",
                "but if you return to me, and keep my commandments and do them, though your outcasts were in the uttermost part of the heavens, yet will I gather them from there, and will bring them to the place that I have chosen, to cause my name to dwell there.\u2019",
                "\"Now these are your servants and your people, whom you have redeemed by your great power, and by your strong hand.",
                "Lord, I beg you, let your ear be attentive now to the prayer of your servant, and to the prayer of your servants, who delight to fear your name; and please prosper your servant this day, and grant him mercy in the sight of this man.\" Now I was cup bearer to the king."
            ],
            2: [
                "It happened in the month Nisan, in the twentieth year of Artaxerxes the king, when wine was before him, that I took up the wine, and gave it to the king. Now I had not been sad before in his presence.",
                "The king said to me, \"Why is your face sad, since you are not sick? This is nothing else but sorrow of heart.\" Then I was very much afraid.",
                "I said to the king, \"Let the king live forever! Why shouldn\u2019t my face be sad, when the city, the place of my fathers\u2019 tombs, lies waste, and its gates have been consumed with fire?\"",
                "Then the king said to me, \"For what do you make request?\" So I prayed to the God of heaven.",
                "I said to the king, \"If it pleases the king, and if your servant has found favor in your sight, that you would send me to Judah, to the city of my fathers\u2019 tombs, that I may build it.\"",
                "The king said to me (the queen was also sitting by him), \"For how long shall your journey be? And when will you return?\" So it pleased the king to send me; and I set him a time.",
                "Moreover I said to the king, \"If it pleases the king, let letters be given me to the governors beyond the River, that they may let me pass through until I come to Judah;",
                "and a letter to Asaph the keeper of the king\u2019s forest, that he may give me timber to make beams for the gates of the citadel by the temple, for the wall of the city, and for the house that I shall enter into.\" The king granted my requests, because of the good hand of my God on me.",
                "Then I came to the governors beyond the River, and gave them the king\u2019s letters. Now the king had sent with me captains of the army and horsemen.",
                "When Sanballat the Horonite, and Tobiah the servant, the Ammonite, heard of it, it grieved them exceedingly, because a man had come to seek the welfare of the children of Israel.",
                "So I came to Jerusalem, and was there three days.",
                "I arose in the night, I and some few men with me; neither told I any man what my God put into my heart to do for Jerusalem; neither was there any animal with me, except the animal that I rode on.",
                "I went out by night by the valley gate, even toward the jackal\u2019s well, and to the dung gate, and viewed the walls of Jerusalem, which were broken down, and its gates were consumed with fire.",
                "Then I went on to the spring gate and to the king\u2019s pool: but there was no place for the animal that was under me to pass.",
                "Then went I up in the night by the brook, and viewed the wall; and I turned back, and entered by the valley gate, and so returned.",
                "The rulers didn\u2019t know where I went, or what I did; neither had I as yet told it to the Jews, nor to the priests, nor to the nobles, nor to the rulers, nor to the rest who did the work.",
                "Then I said to them, \"You see the evil case that we are in, how Jerusalem lies waste, and its gates are burned with fire. Come, let us build up the wall of Jerusalem, that we won\u2019t be disgraced.\"",
                "I told them of the hand of my God which was good on me, as also of the king\u2019s words that he had spoken to me. They said, \"Let\u2019s rise up and build.\" So they strengthened their hands for the good work.",
                "But when Sanballat the Horonite, and Tobiah the servant, the Ammonite, and Geshem the Arabian, heard it, they ridiculed us, and despised us, and said, \"What is this thing that you are doing? Will you rebel against the king?\"",
                "Then answered I them, and said to them, \"The God of heaven will prosper us. Therefore we, his servants, will arise and build; but you have no portion, nor right, nor memorial, in Jerusalem.\""
            ],
            3: [
                "Then Eliashib the high priest rose up with his brothers the priests, and they built the sheep gate; they sanctified it, and set up its doors; even to the tower of Hammeah they sanctified it, to the tower of Hananel.",
                "Next to him built the men of Jericho. Next to them built Zaccur the son of Imri.",
                "The sons of Hassenaah built the fish gate. They laid its beams, and set up its doors, its bolts, and its bars.",
                "Next to them, Meremoth the son of Uriah, the son of Hakkoz made repairs. Next to them, Meshullam the son of Berechiah, the son of Meshezabel made repairs. Next to them, Zadok the son of Baana made repairs.",
                "Next to them, the Tekoites made repairs; but their nobles didn\u2019t put their necks to the work of their lord.",
                "Joiada the son of Paseah and Meshullam the son of Besodeiah repaired the old gate. They laid its beams, and set up its doors, and its bolts, and its bars.",
                "Next to them, Melatiah the Gibeonite, and Jadon the Meronothite, the men of Gibeon, and of Mizpah, repaired the residence of the governor beyond the River.",
                "Next to him, Uzziel the son of Harhaiah, goldsmiths, made repairs. Next to him, Hananiah one of the perfumers made repairs, and they fortified Jerusalem even to the broad wall.",
                "Next to them, Rephaiah the son of Hur, the ruler of half the district of Jerusalem, made repairs.",
                "Next to them, Jedaiah the son of Harumaph made repairs across from his house. Next to him, Hattush the son of Hashabneiah made repairs.",
                "Malchijah the son of Harim, and Hasshub the son of Pahathmoab, repaired another portion, and the tower of the furnaces.",
                "Next to him, Shallum the son of Hallohesh, the ruler of half the district of Jerusalem, he and his daughters, made repairs.",
                "Hanun and the inhabitants of Zanoah repaired the valley gate. They built it, and set up its doors, its bolts, and its bars, and one thousand cubits of the wall to the dung gate.",
                "Malchijah the son of Rechab, the ruler of the district of Beth Haccherem repaired the dung gate. He built it, and set up its doors, its bolts, and its bars.",
                "Shallun the son of Colhozeh, the ruler of the district of Mizpah repaired the spring gate. He built it, and covered it, and set up its doors, its bolts, and its bars, and the wall of the pool of Shelah by the king\u2019s garden, even to the stairs that go down from the city of David.",
                "After him, Nehemiah the son of Azbuk, the ruler of half the district of Beth Zur, made repairs to the place opposite the tombs of David, and to the pool that was made, and to the house of the mighty men.",
                "After him, the Levites, Rehum the son of Bani made repairs. Next to him, Hashabiah, the ruler of half the district of Keilah, made repairs for his district.",
                "After him, their brothers, Bavvai the son of Henadad, the ruler of half the district of Keilah made repairs.",
                "Next to him, Ezer the son of Jeshua, the ruler of Mizpah, repaired another portion, across from the ascent to the armory at the turning of the wall.",
                "After him, Baruch the son of Zabbai earnestly repaired another portion, from the turning of the wall to the door of the house of Eliashib the high priest.",
                "After him, Meremoth the son of Uriah the son of Hakkoz repaired another portion, from the door of the house of Eliashib even to the end of the house of Eliashib.",
                "After him, the priests, the men of the Plain made repairs.",
                "After them, Benjamin and Hasshub made repairs across from their house. After them, Azariah the son of Maaseiah the son of Ananiah made repairs beside his own house.",
                "After him, Binnui the son of Henadad repaired another portion, from the house of Azariah to the turning of the wall, and to the corner.",
                "Palal the son of Uzai made repairs opposite the turning of the wall, and the tower that stands out from the upper house of the king, which is by the court of the guard. After him Pedaiah the son of Parosh made repairs.",
                "(Now the Nethinim lived in Ophel, to the place over against the water gate toward the east, and the tower that stands out.)",
                "After him the Tekoites repaired another portion, over against the great tower that stands out, and to the wall of Ophel.",
                "Above the horse gate, the priests made repairs, everyone across from his own house.",
                "After them, Zadok the son of Immer made repairs across from his own house. After him, Shemaiah the son of Shecaniah, the keeper of the east gate made repairs.",
                "After him, Hananiah the son of Shelemiah, and Hanun the sixth son of Zalaph, repaired another portion. After him, Meshullam the son of Berechiah made repairs across from his room.",
                "After him, Malchijah one of the goldsmiths to the house of the Nethinim, and of the merchants, made repairs over against the gate of Hammiphkad, and to the ascent of the corner.",
                "Between the ascent of the corner and the sheep gate, the goldsmiths and the merchants made repairs."
            ],
            4: [
                "But it happened that when Sanballat heard that we were building the wall, he was angry, and took great indignation, and mocked the Jews.",
                "He spoke before his brothers and the army of Samaria, and said, \"What are these feeble Jews doing? Will they fortify themselves? Will they sacrifice? Will they finish in a day? Will they revive the stones out of the heaps of rubbish, since they are burned?\"",
                "Now Tobiah the Ammonite was by him, and he said, \"What they are building, if a fox climbed up it, he would break down their stone wall.\"",
                "\"Hear, our God; for we are despised; and turn back their reproach on their own head, give them up for a spoil in a land of captivity;",
                "don\u2019t cover their iniquity, and don\u2019t let their sin be blotted out from before you; for they have insulted the builders.\"",
                "So we built the wall; and all the wall was joined together to half its height: for the people had a mind to work.",
                "But it happened that when Sanballat, Tobiah, the Arabians, the Ammonites, and the Ashdodites heard that the repairing of the walls of Jerusalem went forward, and that the breaches began to be filled, then they were very angry;",
                "and they conspired all of them together to come and fight against Jerusalem, and to cause confusion therein.",
                "But we made our prayer to our God, and set a watch against them day and night, because of them.",
                "Judah said, \"The strength of the bearers of burdens is fading, and there is much rubbish; so that we are not able to build the wall.\"",
                "Our adversaries said, \"They shall not know, neither see, until we come into their midst, and kill them, and cause the work to cease.\"",
                "It happened that when the Jews who lived by them came, they said to us ten times from all places, \"Wherever you turn, they will attack us.\"",
                "Therefore set I in the lowest parts of the space behind the wall, in the open places, I set the people after their families with their swords, their spears, and their bows.",
                "I looked, and rose up, and said to the nobles, and to the rulers, and to the rest of the people, \"Don\u2019t be afraid of them! Remember the Lord, who is great and awesome, and fight for your brothers, your sons, and your daughters, your wives, and your houses.\"",
                "It happened, when our enemies heard that it was known to us, and God had brought their counsel to nothing, that we returned all of us to the wall, everyone to his work.",
                "It happened from that time forth, that half of my servants worked in the work, and half of them held the spears, the shields, and the bows, and the coats of mail; and the rulers were behind all the house of Judah.",
                "They all built the wall and those who bore burdens loaded themselves; everyone with one of his hands worked in the work, and with the other held his weapon;",
                "and the builders, everyone wore his sword at his side, and so built. He who sounded the trumpet was by me.",
                "I said to the nobles, and to the rulers and to the rest of the people, \"The work is great and large, and we are separated on the wall, one far from another.",
                "Wherever you hear the sound of the trumpet, rally there to us. Our God will fight for us.\"",
                "So we worked in the work: and half of them held the spears from the rising of the morning until the stars appeared.",
                "Likewise at the same time said I to the people, \"Let everyone with his servant lodge within Jerusalem, that in the night they may be a guard to us, and may labor in the day.\"",
                "So neither I, nor my brothers, nor my servants, nor the men of the guard who followed me, none of us took off our clothes. Everyone took his weapon to the water."
            ],
            5: [
                "Then there arose a great cry of the people and of their wives against their brothers the Jews.",
                "For there were that said, \"We, our sons and our daughters, are many. Let us get grain, that we may eat and live.\"",
                "Some also there were that said, \"We are mortgaging our fields, and our vineyards, and our houses. Let us get grain, because of the famine.\"",
                "There were also some who said, \"We have borrowed money for the king\u2019s tribute using our fields and our vineyards as collateral.",
                "Yet now our flesh is as the flesh of our brothers, our children as their children. Behold, we bring into bondage our sons and our daughters to be servants, and some of our daughters have been brought into bondage. Neither is it in our power to help it; for other men have our fields and our vineyards.\"",
                "I was very angry when I heard their cry and these words.",
                "Then I consulted with myself, and contended with the nobles and the rulers, and said to them, \"You exact usury, everyone of his brother.\" I held a great assembly against them.",
                "I said to them, \"We, after our ability, have redeemed our brothers the Jews that were sold to the nations; and would you even sell your brothers, and should they be sold to us?\" Then they held their peace, and found never a word.",
                "Also I said, \"The thing that you do is not good. Ought you not to walk in the fear of our God, because of the reproach of the nations our enemies?",
                "I likewise, my brothers and my servants, lend them money and grain. Please let us stop this usury.",
                "Please restore to them, even this day, their fields, their vineyards, their olive groves, and their houses, also the hundredth part of the money, and of the grain, the new wine, and the oil, that you are charging them.\"",
                "Then they said, \"We will restore them, and will require nothing of them; so will we do, even as you say.\" Then I called the priests, and took an oath of them, that they would do according to this promise.",
                "Also I shook out my lap, and said, \"So may God shake out every man from his house, and from his labor, that doesn\u2019t perform this promise; even thus be he shaken out, and emptied.\" All the assembly said, \"Amen,\" and praised Yahweh. The people did according to this promise.",
                "Moreover from the time that I was appointed to be their governor in the land of Judah, from the twentieth year even to the two and thirtieth year of Artaxerxes the king, that is, twelve years, I and my brothers have not eaten the bread of the governor.",
                "But the former governors who were before me were supported by the people, and took bread and wine from them, besides forty shekels of silver; yes, even their servants ruled over the people: but I didn\u2019t do so, because of the fear of God.",
                "Yes, also I continued in the work of this wall, neither bought we any land: and all my servants were gathered there to the work.",
                "Moreover there were at my table, of the Jews and the rulers, one hundred fifty men, besides those who came to us from among the nations that were around us.",
                "Now that which was prepared for one day was one ox and six choice sheep; also fowls were prepared for me, and once in ten days store of all sorts of wine: yet for all this I didn\u2019t demand the bread of the governor, because the bondage was heavy on this people.",
                "Remember to me, my God, for good, all that I have done for this people."
            ],
            6: [
                "Now it happened, when it was reported to Sanballat and Tobiah, and to Geshem the Arabian, and to the rest of our enemies, that I had built the wall, and that there was no breach left therein; (though even to that time I had not set up the doors in the gates;)",
                "that Sanballat and Geshem sent to me, saying, \"Come, let us meet together in the villages in the plain of Ono.\" But they intended to harm me.",
                "I sent messengers to them, saying, \"I am doing a great work, so that I can\u2019t come down. Why should the work cease, while I leave it, and come down to you?\"",
                "They sent to me four times after this sort; and I answered them the same way.",
                "Then Sanballat sent his servant to me the same way the fifth time with an open letter in his hand,",
                "in which was written, \"It is reported among the nations, and Gashmu says it, that you and the Jews intend to rebel. Because of that, you are building the wall. You would be their king, according to these words.",
                "You have also appointed prophets to preach of you at Jerusalem, saying, \u2018There is a king in Judah!\u2019 Now it will be reported to the king according to these words. Come now therefore, and let us take counsel together.\"",
                "Then I sent to him, saying, \"There are no such things done as you say, but you imagine them out of your own heart.\"",
                "For they all would have made us afraid, saying, \"Their hands will be weakened from the work, that it not be done. But now, strengthen my hands.\"",
                "I went to the house of Shemaiah the son of Delaiah the son of Mehetabel, who was shut in at his home; and he said, \"Let us meet together in the house of God, within the temple, and let us shut the doors of the temple; for they will come to kill you; yes, in the night will they come to kill you.\"",
                "I said, \"Should such a man as I flee? Who is there that, being such as I, would go into the temple to save his life? I will not go in.\"",
                "I discerned, and behold, God had not sent him; but he pronounced this prophecy against me. Tobiah and Sanballat had hired him.",
                "He hired so that I would be afraid, do so, and sin, and that they might have material for an evil report, that they might reproach me.",
                "\"Remember, my God, Tobiah and Sanballat according to these their works, and also the prophetess Noadiah, and the rest of the prophets, that would have put me in fear.\"",
                "So the wall was finished in the twenty-fifth day of Elul, in fifty-two days.",
                "It happened, when all our enemies heard of it, that all the nations that were about us were afraid, and were much cast down in their own eyes; for they perceived that this work was worked of our God.",
                "Moreover in those days the nobles of Judah sent many letters to Tobiah, and Tobiah\u2019s letters came to them.",
                "For there were many in Judah sworn to him, because he was the son-in-law of Shecaniah the son of Arah; and his son Jehohanan had taken the daughter of Meshullam the son of Berechiah as wife.",
                "Also they spoke of his good deeds before me, and reported my words to him. Tobiah sent letters to put me in fear."
            ],
            7: [
                "Now it happened, when the wall was built, and I had set up the doors, and the porters and the singers and the Levites were appointed,",
                "that I put my brother Hanani, and Hananiah the governor of the castle, in charge of Jerusalem; for he was a faithful man, and feared God above many.",
                "I said to them, \"Don\u2019t let the gates of Jerusalem be opened until the sun is hot; and while they stand guard, let them shut the doors, and you bar them: and appoint watches of the inhabitants of Jerusalem, everyone in his watch, with everyone near his house.\"",
                "Now the city was wide and large; but the people were few therein, and the houses were not built.",
                "My God put into my heart to gather together the nobles, and the rulers, and the people, that they might be reckoned by genealogy. I found the book of the genealogy of those who came up at the first, and I found written therein:",
                "These are the children of the province, who went up out of the captivity of those who had been carried away, whom Nebuchadnezzar the king of Babylon had carried away, and who returned to Jerusalem and to Judah, everyone to his city;",
                "who came with Zerubbabel, Jeshua, Nehemiah, Azariah, Raamiah, Nahamani, Mordecai, Bilshan, Mispereth, Bigvai, Nehum, Baanah. The number of the men of the people of Israel:",
                "The children of Parosh, two thousand one hundred seventy-two.",
                "The children of Shephatiah, three hundred seventy-two.",
                "The children of Arah, six hundred fifty-two.",
                "The children of Pahathmoab, of the children of Jeshua and Joab, two thousand eight hundred eighteen.",
                "The children of Elam, one thousand two hundred fifty-four.",
                "The children of Zattu, eight hundred forty-five.",
                "The children of Zaccai, seven hundred sixty.",
                "The children of Binnui, six hundred forty-eight.",
                "The children of Bebai, six hundred twenty-eight.",
                "The children of Azgad, two thousand three hundred twenty-two.",
                "The children of Adonikam, six hundred sixty-seven.",
                "The children of Bigvai, two thousand sixty-seven.",
                "The children of Adin, six hundred fifty-five.",
                "The children of Ater, of Hezekiah, ninety-eight.",
                "The children of Hashum, three hundred Twenty-eight.",
                "The children of Bezai, three hundred twenty-four.",
                "The children of Hariph, one hundred twelve.",
                "The children of Gibeon, ninety-five.",
                "The men of Bethlehem and Netophah, one hundred eighty-eight.",
                "The men of Anathoth, one hundred twenty-eight.",
                "The men of Beth Azmaveth, forty-two.",
                "The men of Kiriath Jearim, Chephirah, and Beeroth, seven hundred forty-three.",
                "The men of Ramah and Geba, six hundred twenty-one.",
                "The men of Michmas, one hundred and twenty-two.",
                "The men of Bethel and Ai, a hundred twenty-three.",
                "The men of the other Nebo, fifty-two.",
                "The children of the other Elam, one thousand two hundred fifty-four.",
                "The children of Harim, three hundred twenty.",
                "The children of Jericho, three hundred forty-five.",
                "The children of Lod, Hadid, and Ono, seven hundred twenty-one.",
                "The children of Senaah, three thousand nine hundred thirty.",
                "The priests: The children of Jedaiah, of the house of Jeshua, nine hundred seventy-three.",
                "The children of Immer, one thousand fifty-two.",
                "The children of Pashhur, one thousand two hundred forty-seven.",
                "The children of Harim, one thousand seventeen.",
                "The Levites: the children of Jeshua, of Kadmiel, of the children of Hodevah, seventy-four.",
                "The singers: the children of Asaph, one hundred forty-eight.",
                "The porters: the children of Shallum, the children of Ater, the children of Talmon, the children of Akkub, the children of Hatita, the children of Shobai, one hundred thirty-eight.",
                "The Nethinim: the children of Ziha, the children of Hasupha, the children of Tabbaoth,",
                "the children of Keros, the children of Sia, the children of Padon,",
                "the children of Lebana, the children of Hagaba, the children of Salmai,",
                "the children of Hanan, the children of Giddel, the children of Gahar,",
                "the children of Reaiah, the children of Rezin, the children of Nekoda,",
                "the children of Gazzam, the children of Uzza, the children of Paseah.",
                "The children of Besai, the children of Meunim, the children of Nephushesim,",
                "the children of Bakbuk, the children of Hakupha, the children of Harhur,",
                "the children of Bazlith, the children of Mehida, the children of Harsha,",
                "the children of Barkos, the children of Sisera, the children of Temah,",
                "the children of Neziah, the children of Hatipha.",
                "The children of Solomon\u2019s servants: the children of Sotai, the children of Sophereth, the children of Perida,",
                "the children of Jaala, the children of Darkon, the children of Giddel,",
                "the children of Shephatiah, the children of Hattil, the children of Pochereth Hazzebaim, the children of Amon.",
                "All the Nethinim, and the children of Solomon\u2019s servants, were three hundred ninety-two.",
                "These were those who went up from Tel Melah, Tel Harsha, Cherub, Addon, and Immer; but they could not show their fathers\u2019 houses, nor their seed, whether they were of Israel:",
                "The children of Delaiah, the children of Tobiah, the children of Nekoda, six hundred forty-two.",
                "Of the priests: the children of Hobaiah, the children of Hakkoz, the children of Barzillai, who took a wife of the daughters of Barzillai the Gileadite, and was called after their name.",
                "These searched for their geneological records, but couldn\u2019t find them. Therefore were they deemed polluted and put from the priesthood.",
                "The governor said to them, that they should not eat of the most holy things, until there stood up a priest with Urim and Thummim.",
                "The whole assembly together was forty-two thousand three hundred sixty,",
                "besides their male servants and their female servants, of whom there were seven thousand three hundred thirty-seven: and they had two hundred forty-five singing men and singing women.",
                "Their horses were seven hundred thirty-six; their mules, two hundred forty-five;",
                "their camels, four hundred thirty-five; their donkeys, six thousand seven hundred twenty.",
                "Some from among the heads of fathers\u2019 households gave to the work. The governor gave to the treasury one thousand darics of gold, fifty basins, and five hundred thirty priests\u2019 garments.",
                "Some of the heads of fathers\u2019 households gave into the treasury of the work twenty thousand darics of gold, and two thousand two hundred minas of silver.",
                "That which the rest of the people gave was twenty thousand darics of gold, and two thousand minas of silver, and sixty-seven priests\u2019 garments.",
                "So the priests, and the Levites, and the porters, and the singers, and some of the people, and the Nethinim, and all Israel, lived in their cities. When the seventh month had come, the children of Israel were in their cities."
            ],
            8: [
                "All the people gathered themselves together as one man into the broad place that was before the water gate; and they spoke to Ezra the scribe to bring the book of the law of Moses, which Yahweh had commanded to Israel.",
                "Ezra the priest brought the law before the assembly, both men and women, and all who could hear with understanding, on the first day of the seventh month.",
                "He read therein before the broad place that was before the water gate from early morning until midday, in the presence of the men and the women, and of those who could understand; and the ears of all the people were attentive to the book of the law.",
                "Ezra the scribe stood on a pulpit of wood, which they had made for the purpose; and beside him stood Mattithiah, and Shema, and Anaiah, and Uriah, and Hilkiah, and Maaseiah, on his right hand; and on his left hand, Pedaiah, and Mishael, and Malchijah, and Hashum, and Hashbaddanah, Zechariah, and Meshullam.",
                "Ezra opened the book in the sight of all the people; (for he was above all the people;) and when he opened it, all the people stood up:",
                "and Ezra blessed Yahweh, the great God. All the people answered, \"Amen, Amen,\" with the lifting up of their hands. They bowed their heads, and worshiped Yahweh with their faces to the ground.",
                "Also Jeshua, and Bani, and Sherebiah, Jamin, Akkub, Shabbethai, Hodiah, Maaseiah, Kelita, Azariah, Jozabad, Hanan, Pelaiah, and the Levites, caused the people to understand the law: and the people stayed in their place.",
                "They read in the book, in the law of God, distinctly; and they gave the sense, so that they understood the reading.",
                "Nehemiah, who was the governor, and Ezra the priest the scribe, and the Levites who taught the people, said to all the people, \"This day is holy to Yahweh your God. Don\u2019t mourn, nor weep.\" For all the people wept, when they heard the words of the law.",
                "Then he said to them, \"Go your way. Eat the fat, drink the sweet, and send portions to him for whom nothing is prepared; for this day is holy to our Lord. Don\u2019t be grieved; for the joy of Yahweh is your strength.\"",
                "So the Levites stilled all the people, saying, \"Hold your peace, for the day is holy; neither be grieved.\"",
                "All the people went their way to eat, and to drink, and to send portions, and to make great mirth, because they had understood the words that were declared to them.",
                "On the second day were gathered together the heads of fathers\u2019 households of all the people, the priests, and the Levites, to Ezra the scribe, even to give attention to the words of the law.",
                "They found written in the law, how that Yahweh had commanded by Moses, that the children of Israel should dwell in booths in the feast of the seventh month;",
                "and that they should publish and proclaim in all their cities, and in Jerusalem, saying, \"Go out to the mountain, and get olive branches, and branches of wild olive, and myrtle branches, and palm branches, and branches of thick trees, to make booths, as it is written.\"",
                "So the people went out, and brought them, and made themselves booths, everyone on the roof of his house, and in their courts, and in the courts of the house of God, and in the broad place of the water gate, and in the broad place of the gate of Ephraim.",
                "All the assembly of those who had come again out of the captivity made booths, and lived in the booths; for since the days of Jeshua the son of Nun to that day the children of Israel had not done so. There was very great gladness.",
                "Also day by day, from the first day to the last day, he read in the book of the law of God. They kept the feast seven days; and on the eighth day was a solemn assembly, according to the ordinance."
            ],
            9: [
                "Now in the twenty-fourth day of this month the children of Israel were assembled with fasting, and with sackcloth, and earth on them.",
                "The seed of Israel separated themselves from all foreigners, and stood and confessed their sins, and the iniquities of their fathers.",
                "They stood up in their place, and read in the book of the law of Yahweh their God a fourth part of the day; and a fourth part they confessed, and worshiped Yahweh their God.",
                "Then Jeshua, and Bani, Kadmiel, Shebaniah, Bunni, Sherebiah, Bani, and Chenani of the Levites stood up on the stairs, and cried with a loud voice to Yahweh their God.",
                "Then the Levites, Jeshua, and Kadmiel, Bani, Hashabneiah, Sherebiah, Hodiah, Shebaniah, and Pethahiah, said, \"Stand up and bless Yahweh your God from everlasting to everlasting! Bessed be your glorious name, which is exalted above all blessing and praise!",
                "You are Yahweh, even you alone. You have made heaven, the heaven of heavens, with all their army, the earth and all things that are on it, the seas and all that is in them, and you preserve them all. The army of heaven worships you.",
                "You are Yahweh, the God who chose Abram, and brought him out of Ur of the Chaldees, and gave him the name of Abraham,",
                "and found his heart faithful before you, and made a covenant with him to give the land of the Canaanite, the Hittite, the Amorite, and the Perizzite, and the Jebusite, and the Girgashite, to give it to his seed, and have performed your words; for you are righteous.",
                "\"You saw the affliction of our fathers in Egypt, and heard their cry by the Red Sea,",
                "and showed signs and wonders against Pharaoh, and against all his servants, and against all the people of his land; for you knew that they dealt proudly against them, and made a name for yourself, as it is this day.",
                "You divided the sea before them, so that they went through the midst of the sea on the dry land; and you cast their pursuers into the depths, as a stone into the mighty waters.",
                "Moreover, in a pillar of cloud you led them by day; and in a pillar of fire by night, to give them light in the way in which they should go.",
                "\"You came down also on Mount Sinai, and spoke with them from heaven, and gave them right ordinances and true laws, good statutes and commandments,",
                "and made known to them your holy Sabbath, and commanded them commandments, and statutes, and a law, by Moses your servant,",
                "and gave them bread from the sky for their hunger, and brought forth water for them out of the rock for their thirst, and commanded them that they should go in to possess the land which you had sworn to give them.",
                "\"But they and our fathers dealt proudly and hardened their neck, didn\u2019t listen to your commandments,",
                "and refused to obey, neither were they mindful of your wonders that you did among them, but hardened their neck, and in their rebellion appointed a captain to return to their bondage. But you are a God ready to pardon, gracious and merciful, slow to anger, and abundant in loving kindness, and didn\u2019t forsake them.",
                "Yes, when they had made them a molten calf, and said, \u2018This is your God who brought you up out of Egypt,\u2019 and had committed awful blasphemies;",
                "yet you in your manifold mercies didn\u2019t forsake them in the wilderness: the pillar of cloud didn\u2019t depart from over them by day, to lead them in the way; neither the pillar of fire by night, to show them light, and the way in which they should go.",
                "You gave also your good Spirit to instruct them, and didn\u2019t withhold your manna from their mouth, and gave them water for their thirst.",
                "\"Yes, forty years you sustained them in the wilderness. They lacked nothing. Their clothes didn\u2019t grow old, and their feet didn\u2019t swell.",
                "Moreover you gave them kingdoms and peoples, which you allotted according to their portions. So they possessed the land of Sihon, even the land of the king of Heshbon, and the land of Og king of Bashan.",
                "You also multiplied their children as the stars of the sky, and brought them into the land concerning which you said to their fathers, that they should go in to possess it.",
                "\"So the children went in and possessed the land, and you subdued before them the inhabitants of the land, the Canaanites, and gave them into their hands, with their kings, and the peoples of the land, that they might do with them as they pleased.",
                "They took fortified cities, and a rich land, and possessed houses full of all good things, cisterns dug out, vineyards, and olive groves, and fruit trees in abundance. So they ate, were filled, became fat, and delighted themselves in your great goodness.",
                "\"Nevertheless they were disobedient, and rebelled against you, and cast your law behind their back, and killed your prophets that testified against them to turn them again to you, and they committed awful blasphemies.",
                "Therefore you delivered them into the hand of their adversaries, who distressed them. In the time of their trouble, when they cried to you, you heard from heaven; and according to your manifold mercies you gave them saviors who saved them out of the hand of their adversaries.",
                "But after they had rest, they did evil again before you; therefore left you them in the hand of their enemies, so that they had the dominion over them; yet when they returned, and cried to you, you heard from heaven; and many times you delivered them according to your mercies,",
                "and testified against them, that you might bring them again to your law. Yet they dealt proudly, and didn\u2019t listen to your commandments, but sinned against your ordinances, (which if a man does, he shall live in them), turned their backs, stiffened their neck, and would not hear.",
                "Yet many years you put up with them, and testified against them by your Spirit through your prophets. Yet would they not give ear. Therefore you gave them into the hand of the peoples of the lands.",
                "\"Nevertheless in your manifold mercies you did not make a full end of them, nor forsake them; for you are a gracious and merciful God.",
                "Now therefore, our God, the great, the mighty, and the awesome God, who keeps covenant and loving kindness, don\u2019t let all the travail seem little before you, that has come on us, on our kings, on our princes, and on our priests, and on our prophets, and on our fathers, and on all your people, since the time of the kings of Assyria to this day.",
                "However you are just in all that has come on us; for you have dealt truly, but we have done wickedly;",
                "neither have our kings, our princes, our priests, nor our fathers, kept your law, nor listened to your commandments and your testimonies with which you testified against them.",
                "For they have not served you in their kingdom, and in your great goodness that you gave them, and in the large and rich land which you gave before them, neither did they turn from their wicked works.",
                "\"Behold, we are servants this day, and as for the land that you gave to our fathers to eat its fruit and its good, behold, we are servants in it.",
                "It yields much increase to the kings whom you have set over us because of our sins: also they have power over our bodies, and over our livestock, at their pleasure, and we are in great distress.",
                "Yet for all this, we make a sure covenant, and write it; and our princes, our Levites, and our priests, seal it.\""
            ],
            10: [
                "Now those who sealed were: Nehemiah the governor, the son of Hacaliah, and Zedekiah,",
                "Seraiah, Azariah, Jeremiah,",
                "Pashhur, Amariah, Malchijah,",
                "Hattush, Shebaniah, Malluch,",
                "Harim, Meremoth, Obadiah,",
                "Daniel, Ginnethon, Baruch,",
                "Meshullam, Abijah, Mijamin,",
                "Maaziah, Bilgai, Shemaiah; these were the priests.",
                "The Levites: namely, Jeshua the son of Azaniah, Binnui of the sons of Henadad, Kadmiel;",
                "and their brothers, Shebaniah, Hodiah, Kelita, Pelaiah, Hanan,",
                "Mica, Rehob, Hashabiah,",
                "Zaccur, Sherebiah, Shebaniah,",
                "Hodiah, Bani, Beninu.",
                "The chiefs of the people: Parosh, Pahathmoab, Elam, Zattu, Bani,",
                "Bunni, Azgad, Bebai,",
                "Adonijah, Bigvai, Adin,",
                "Ater, Hezekiah, Azzur,",
                "Hodiah, Hashum, Bezai,",
                "Hariph, Anathoth, Nobai,",
                "Magpiash, Meshullam, Hezir,",
                "Meshezabel, Zadok, Jaddua,",
                "Pelatiah, Hanan, Anaiah,",
                "Hoshea, Hananiah, Hasshub,",
                "Hallohesh, Pilha, Shobek,",
                "Rehum, Hashabnah, Maaseiah,",
                "and Ahiah, Hanan, Anan,",
                "Malluch, Harim, Baanah.",
                "The rest of the people, the priests, the Levites, the porters, the singers, the Nethinim, and all those who had separated themselves from the peoples of the lands to the law of God, their wives, their sons, and their daughters, everyone who had knowledge, and understanding\u2014",
                "they joined with their brothers, their nobles, and entered into a curse, and into an oath, to walk in God\u2019s law, which was given by Moses the servant of God, and to observe and do all the commandments of Yahweh our Lord, and his ordinances and his statutes;",
                "and that we would not give our daughters to the peoples of the land, nor take their daughters for our sons;",
                "and if the peoples of the land bring wares or any grain on the Sabbath day to sell, that we would not buy of them on the Sabbath, or on a holy day; and that we would forego the seventh year, and the exaction of every debt.",
                "Also we made ordinances for ourselves, to charge ourselves yearly with the third part of a shekel for the service of the house of our God;",
                "for the show bread, and for the continual meal offering, and for the continual burnt offering, for the Sabbaths, for the new moons, for the set feasts, and for the holy things, and for the sin offerings to make atonement for Israel, and for all the work of the house of our God.",
                "We cast lots, the priests, the Levites, and the people, for the wood offering, to bring it into the house of our God, according to our fathers\u2019 houses, at times appointed, year by year, to burn on the altar of Yahweh our God, as it is written in the law;",
                "and to bring the first fruits of our ground, and the first fruits of all fruit of all kinds of trees, year by year, to the house of Yahweh;",
                "also the firstborn of our sons, and of our livestock, as it is written in the law, and the firstborn of our herds and of our flocks, to bring to the house of our God, to the priests who minister in the house of our God;",
                "and that we should bring the first fruits of our dough, and our wave offerings, and the fruit of all kinds of trees, the new wine and the oil, to the priests, to the rooms of the house of our God; and the tithes of our ground to the Levites; for they, the Levites, take the tithes in all the cities of our tillage.",
                "The priest the son of Aaron shall be with the Levites, when the Levites take tithes: and the Levites shall bring up the tithe of the tithes to the house of our God, to the rooms, into the treasure house.",
                "For the children of Israel and the children of Levi shall bring the wave offering of the grain, of the new wine, and of the oil, to the rooms, where are the vessels of the sanctuary, and the priests who minister, and the porters, and the singers. We will not forsake the house of our God."
            ],
            11: [
                "The princes of the people lived in Jerusalem: the rest of the people also cast lots, to bring one of ten to dwell in Jerusalem the holy city, and nine parts in the other cities.",
                "The people blessed all the men who willingly offered themselves to dwell in Jerusalem.",
                "Now these are the chiefs of the province who lived in Jerusalem; but in the cities of Judah everyone lived in his possession in their cities: Israel, the priests, and the Levites, and the Nethinim, and the children of Solomon\u2019s servants.",
                "In Jerusalem lived certain of the children of Judah, and of the children of Benjamin. Of the children of Judah: Athaiah the son of Uzziah, the son of Zechariah, the son of Amariah, the son of Shephatiah, the son of Mahalalel, of the children of Perez;",
                "and Maaseiah the son of Baruch, the son of Colhozeh, the son of Hazaiah, the son of Adaiah, the son of Joiarib, the son of Zechariah, the son of the Shilonite.",
                "All the sons of Perez who lived in Jerusalem were four hundred sixty-eight valiant men.",
                "These are the sons of Benjamin: Sallu the son of Meshullam, the son of Joed, the son of Pedaiah, the son of Kolaiah, the son of Maaseiah, the son of Ithiel, the son of Jeshaiah.",
                "After him Gabbai, Sallai, nine hundred twenty-eight.",
                "Joel the son of Zichri was their overseer; and Judah the son of Hassenuah was second over the city.",
                "Of the priests: Jedaiah the son of Joiarib, Jachin,",
                "Seraiah the son of Hilkiah, the son of Meshullam, the son of Zadok, the son of Meraioth, the son of Ahitub, the ruler of the house of God,",
                "and their brothers who did the work of the house, eight hundred twenty-two; and Adaiah the son of Jeroham, the son of Pelaliah, the son of Amzi, the son of Zechariah, the son of Pashhur, the son of Malchijah,",
                "and his brothers, chiefs of fathers\u2019 households, two hundred forty-two; and Amashsai the son of Azarel, the son of Ahzai, the son of Meshillemoth, the son of Immer,",
                "and their brothers, mighty men of valor, one hundred twenty-eight; and their overseer was Zabdiel, the son of Haggedolim.",
                "Of the Levites: Shemaiah the son of Hasshub, the son of Azrikam, the son of Hashabiah, the son of Bunni;",
                "and Shabbethai and Jozabad, of the chiefs of the Levites, who had the oversight of the outward business of the house of God;",
                "and Mattaniah the son of Mica, the son of Zabdi, the son of Asaph, who was the chief to begin the thanksgiving in prayer, and Bakbukiah, the second among his brothers; and Abda the son of Shammua, the son of Galal, the son of Jeduthun.",
                "All the Levites in the holy city were two hundred eighty-four.",
                "Moreover the porters, Akkub, Talmon, and their brothers, who kept watch at the gates, were one hundred seventy-two.",
                "The residue of Israel, of the priests, the Levites, were in all the cities of Judah, everyone in his inheritance.",
                "But the Nethinim lived in Ophel: and Ziha and Gishpa were over the Nethinim.",
                "The overseer also of the Levites at Jerusalem was Uzzi the son of Bani, the son of Hashabiah, the son of Mattaniah, the son of Mica, of the sons of Asaph, the singers, over the business of the house of God.",
                "For there was a commandment from the king concerning them, and a settled provision for the singers, as every day required.",
                "Pethahiah the son of Meshezabel, of the children of Zerah the son of Judah, was at the king\u2019s hand in all matters concerning the people.",
                "As for the villages, with their fields, some of the children of Judah lived in Kiriath Arba and its towns, and in Dibon and its towns, and in Jekabzeel and its villages,",
                "and in Jeshua, and in Moladah, and Beth Pelet,",
                "and in Hazar Shual, and in Beersheba and its towns,",
                "and in Ziklag, and in Meconah and in its towns,",
                "and in En Rimmon, and in Zorah, and in Jarmuth,",
                "Zanoah, Adullam, and their villages, Lachish and its fields, Azekah and its towns. So they encamped from Beersheba to the valley of Hinnom.",
                "The children of Benjamin also lived from Geba onward, at Michmash and Aija, and at Bethel and its towns,",
                "at Anathoth, Nob, Ananiah,",
                "Hazor, Ramah, Gittaim,",
                "Hadid, Zeboim, Neballat,",
                "Lod, and Ono, the valley of craftsmen.",
                "Of the Levites, certain divisions in Judah settled in Benjamin\u2019s territory."
            ],
            12: [
                "Now these are the priests and the Levites who went up with Zerubbabel the son of Shealtiel, and Jeshua: Seraiah, Jeremiah, Ezra,",
                "Amariah, Malluch, Hattush,",
                "Shecaniah, Rehum, Meremoth,",
                "Iddo, Ginnethoi, Abijah,",
                "Mijamin, Maadiah, Bilgah,",
                "Shemaiah, and Joiarib, Jedaiah.",
                "Sallu, Amok, Hilkiah, Jedaiah. These were the chiefs of the priests and of their brothers in the days of Jeshua.",
                "Moreover the Levites: Jeshua, Binnui, Kadmiel, Sherebiah, Judah, and Mattaniah, who was over the thanksgiving, he and his brothers.",
                "Also Bakbukiah and Unno, their brothers, were over against them according to their offices.",
                "Jeshua became the father of Joiakim, and Joiakim became the father of Eliashib, and Eliashib became the father of Joiada,",
                "and Joiada became the father of Jonathan, and Jonathan became the father of Jaddua.",
                "In the days of Joiakim were priests, heads of fathers\u2019 households: of Seraiah, Meraiah; of Jeremiah, Hananiah;",
                "of Ezra, Meshullam; of Amariah, Jehohanan;",
                "of Malluchi, Jonathan; of Shebaniah, Joseph;",
                "of Harim, Adna; of Meraioth, Helkai;",
                "of Iddo, Zechariah; of Ginnethon, Meshullam;",
                "of Abijah, Zichri; of Miniamin, of Moadiah, Piltai;",
                "of Bilgah, Shammua; of Shemaiah, Jehonathan;",
                "and of Joiarib, Mattenai; of Jedaiah, Uzzi;",
                "of Sallai, Kallai; of Amok, Eber;",
                "of Hilkiah, Hashabiah; of Jedaiah, Nethanel.",
                "As for the Levites, in the days of Eliashib, Joiada, and Johanan, and Jaddua, there were recorded the heads of fathers\u2019 households; also the priests, in the reign of Darius the Persian.",
                "The sons of Levi, heads of fathers\u2019 households, were written in the book of the chronicles, even until the days of Johanan the son of Eliashib.",
                "The chiefs of the Levites: Hashabiah, Sherebiah, and Jeshua the son of Kadmiel, with their brothers over against them, to praise and give thanks, according to the commandment of David the man of God, watch next to watch.",
                "Mattaniah, and Bakbukiah, Obadiah, Meshullam, Talmon, Akkub, were porters keeping the watch at the storehouses of the gates.",
                "These were in the days of Joiakim the son of Jeshua, the son of Jozadak, and in the days of Nehemiah the governor, and of Ezra the priest the scribe.",
                "At the dedication of the wall of Jerusalem they sought the Levites out of all their places, to bring them to Jerusalem, to keep the dedication with gladness, both with giving thanks, and with singing, with cymbals, stringed instruments, and with harps.",
                "The sons of the singers gathered themselves together, both out of the plain around Jerusalem, and from the villages of the Netophathites;",
                "also from Beth Gilgal, and out of the fields of Geba and Azmaveth: for the singers had built them villages around Jerusalem.",
                "The priests and the Levites purified themselves; and they purified the people, and the gates, and the wall.",
                "Then I brought up the princes of Judah on the wall, and appointed two great companies who gave thanks and went in procession. One went on the right hand on the wall toward the dung gate;",
                "and after them went Hoshaiah, and half of the princes of Judah,",
                "and Azariah, Ezra, and Meshullam,",
                "Judah, and Benjamin, and Shemaiah, and Jeremiah,",
                "and certain of the priests\u2019 sons with trumpets: Zechariah the son of Jonathan, the son of Shemaiah, the son of Mattaniah, the son of Micaiah, the son of Zaccur, the son of Asaph;",
                "and his brothers, Shemaiah, and Azarel, Milalai, Gilalai, Maai, Nethanel, and Judah, Hanani, with the musical instruments of David the man of God; and Ezra the scribe was before them.",
                "By the spring gate, and straight before them, they went up by the stairs of the city of David, at the ascent of the wall, above the house of David, even to the water gate eastward.",
                "The other company of those who gave thanks went to meet them, and I after them, with the half of the people, on the wall, above the tower of the furnaces, even to the broad wall,",
                "and above the gate of Ephraim, and by the old gate, and by the fish gate, and the tower of Hananel, and the tower of Hammeah, even to the sheep gate: and they stood still in the gate of the guard.",
                "So stood the two companies of those who gave thanks in the house of God, and I, and the half of the rulers with me;",
                "and the priests, Eliakim, Maaseiah, Miniamin, Micaiah, Elioenai, Zechariah, and Hananiah, with trumpets;",
                "and Maaseiah, and Shemaiah, and Eleazar, and Uzzi, and Jehohanan, and Malchijah, and Elam, and Ezer. The singers sang loud, with Jezrahiah their overseer.",
                "They offered great sacrifices that day, and rejoiced; for God had made them rejoice with great joy; and the women also and the children rejoiced: so that the joy of Jerusalem was heard even afar off.",
                "On that day were men appointed over the rooms for the treasures, for the wave offerings, for the first fruits, and for the tithes, to gather into them, according to the fields of the cities, the portions appointed by the law for the priests and Levites: for Judah rejoiced for the priests and for the Levites who waited.",
                "They performed the duty of their God, and the duty of the purification, and so did the singers and the porters, according to the commandment of David, and of Solomon his son.",
                "For in the days of David and Asaph of old there was a chief of the singers, and songs of praise and thanksgiving to God.",
                "All Israel in the days of Zerubbabel, and in the days of Nehemiah, gave the portions of the singers and the porters, as every day required: and they set apart that which was for the Levites; and the Levites set apart that which was for the sons of Aaron."
            ],
            13: [
                "On that day they read in the book of Moses in the audience of the people; and therein was found written, that an Ammonite and a Moabite should not enter into the assembly of God forever,",
                "because they didn\u2019t meet the children of Israel with bread and with water, but hired Balaam against them, to curse them: however our God turned the curse into a blessing.",
                "It came to pass, when they had heard the law, that they separated from Israel all the mixed multitude.",
                "Now before this, Eliashib the priest, who was appointed over the rooms of the house of our God, being allied to Tobiah,",
                "had prepared for him a great room, where before they laid the meal offerings, the frankincense, and the vessels, and the tithes of the grain, the new wine, and the oil, which were given by commandment to the Levites, and the singers, and the porters; and the wave offerings for the priests.",
                "But in all this, I was not at Jerusalem; for in the two and thirtieth year of Artaxerxes king of Babylon I went to the king: and after certain days asked I leave of the king,",
                "and I came to Jerusalem, and understood the evil that Eliashib had done for Tobiah, in preparing him a room in the courts of the house of God.",
                "It grieved me severely: therefore I cast forth all the household stuff of Tobiah out of the room.",
                "Then I commanded, and they cleansed the rooms: and there brought I again the vessels of the house of God, with the meal offerings and the frankincense.",
                "I perceived that the portions of the Levites had not been given them; so that the Levites and the singers, who did the work, had fled everyone to his field.",
                "Then I contended with the rulers, and said, \"Why is the house of God forsaken?\" I gathered them together, and set them in their place.",
                "Then brought all Judah the tithe of the grain and the new wine and the oil to the treasuries.",
                "I made treasurers over the treasuries, Shelemiah the priest, and Zadok the scribe, and of the Levites, Pedaiah: and next to them was Hanan the son of Zaccur, the son of Mattaniah; for they were counted faithful, and their business was to distribute to their brothers.",
                "Remember me, my God, concerning this, and don\u2019t wipe out my good deeds that I have done for the house of my God, and for its observances.",
                "In those days saw I in Judah some men treading winepresses on the Sabbath, and bringing in sheaves, and loading donkeys; as also wine, grapes, and figs, and all kinds of burdens, which they brought into Jerusalem on the Sabbath day: and I testified against them in the day in which they sold food.",
                "There lived men of Tyre also therein, who brought in fish, and all kinds of wares, and sold on the Sabbath to the children of Judah, and in Jerusalem.",
                "Then I contended with the nobles of Judah, and said to them, \"What evil thing is this that you do, and profane the Sabbath day?",
                "Didn\u2019t your fathers do thus, and didn\u2019t our God bring all this evil on us, and on this city? Yet you bring more wrath on Israel by profaning the Sabbath.\"",
                "It came to pass that, when the gates of Jerusalem began to be dark before the Sabbath, I commanded that the doors should be shut, and commanded that they should not be opened until after the Sabbath. I set some of my servants over the gates, that no burden should be brought in on the Sabbath day.",
                "So the merchants and sellers of all kinds of wares lodged outside of Jerusalem once or twice.",
                "Then I testified against them, and said to them, \"Why do you stay around the wall? If you do so again, I will lay hands on you.\" From that time on, they didn\u2019t come on the Sabbath.",
                "I commanded the Levites that they should purify themselves, and that they should come and keep the gates, to sanctify the Sabbath day. Remember to me, my God, this also, and spare me according to the greatness of your loving kindness.",
                "In those days also saw I the Jews who had married women of Ashdod, of Ammon, and of Moab:",
                "and their children spoke half in the speech of Ashdod, and could not speak in the Jews\u2019 language, but according to the language of each people.",
                "I contended with them, and cursed them, and struck certain of them, and plucked off their hair, and made them swear by God, \"You shall not give your daughters to their sons, nor take their daughters for your sons, or for yourselves.",
                "Didn\u2019t Solomon king of Israel sin by these things? Yet among many nations was there no king like him, and he was beloved of his God, and God made him king over all Israel. Nevertheless foreign women caused even him to sin.",
                "Shall we then listen to you to do all this great evil, to trespass against our God in marrying foreign women?\"",
                "One of the sons of Joiada, the son of Eliashib the high priest, was son-in-law to Sanballat the Horonite: therefore I chased him from me.",
                "Remember them, my God, because they have defiled the priesthood, and the covenant of the priesthood, and of the Levites.",
                "Thus I cleansed them from all foreigners, and appointed duties for the priests and for the Levites, everyone in his work;",
                "and for the wood offering, at times appointed, and for the first fruits. Remember me, my God, for good."
            ]
        },
        17: {
            1: [
                "Now it happened in the days of Ahasuerus (this is Ahasuerus who reigned from India even to Ethiopia, over one hundred twenty-seven provinces),",
                "that in those days, when the King Ahasuerus sat on the throne of his kingdom, which was in Shushan the palace,",
                "in the third year of his reign, he made a feast for all his princes and his servants; the power of Persia and Media, the nobles and princes of the provinces, being before him.",
                "He displayed the riches of his glorious kingdom and the honor of his excellent majesty many days, even one hundred eighty days.",
                "When these days were fulfilled, the king made a seven day feast for all the people who were present in Shushan the palace, both great and small, in the court of the garden of the king\u2019s palace.",
                "There were hangings of white, green, and blue material, fastened with cords of fine linen and purple to silver rings and marble pillars. The couches were of gold and silver, on a pavement of red, white, yellow, and black marble.",
                "They gave them drinks in golden vessels of various kinds, including royal wine in abundance, according to the bounty of the king.",
                "In accordance with the law, the drinking was not compulsory; for so the king had instructed all the officials of his house, that they should do according to every man\u2019s pleasure.",
                "Also Vashti the queen made a feast for the women in the royal house which belonged to King Ahasuerus.",
                "On the seventh day, when the heart of the king was merry with wine, he commanded Mehuman, Biztha, Harbona, Bigtha, and Abagtha, Zethar, and Carcass, the seven eunuchs who served in the presence of Ahasuerus the king,",
                "to bring Vashti the queen before the king with the royal crown, to show the people and the princes her beauty; for she was beautiful.",
                "But the queen Vashti refused to come at the king\u2019s commandment by the eunuchs. Therefore the king was very angry, and his anger burned in him.",
                "Then the king said to the wise men, who knew the times, (for it was the king\u2019s custom to consult those who knew law and judgment;",
                "and the next to him were Carshena, Shethar, Admatha, Tarshish, Meres, Marsena, and Memucan, the seven princes of Persia and Media, who saw the king\u2019s face, and sat first in the kingdom),",
                "\"What shall we do to the queen Vashti according to law, because she has not done the bidding of the King Ahasuerus by the eunuchs?\"",
                "Memucan answered before the king and the princes, \"Vashti the queen has not done wrong to just the king, but also to all the princes, and to all the people who are in all the provinces of the King Ahasuerus.",
                "For this deed of the queen will become known to all women, causing them to show contempt for their husbands, when it is reported, \u2018King Ahasuerus commanded Vashti the queen to be brought in before him, but she didn\u2019t come.\u2019",
                "Today, the princesses of Persia and Media who have heard of the queen\u2019s deed will tell all the king\u2019s princes. This will cause much contempt and wrath.",
                "\"If it please the king, let a royal commandment go from him, and let it be written among the laws of the Persians and the Medes, so that it cannot be altered, that Vashti may never again come before King Ahasuerus; and let the king give her royal estate to another who is better than she.",
                "When the king\u2019s decree which he shall make is published throughout all his kingdom (for it is great), all the wives will give their husbands honor, both great and small.\"",
                "This advice pleased the king and the princes, and the king did according to the word of Memucan:",
                "for he sent letters into all the king\u2019s provinces, into every province according to its writing, and to every people in their language, that every man should rule his own house, speaking in the language of his own people."
            ],
            2: [
                "After these things, when the wrath of King Ahasuerus was pacified, he remembered Vashti, and what she had done, and what was decreed against her.",
                "Then the king\u2019s servants who served him said, \"Let beautiful young virgins be sought for the king.",
                "Let the king appoint officers in all the provinces of his kingdom, that they may gather together all the beautiful young virgins to the citadel of Susa, to the women\u2019s house, to the custody of Hegai the king\u2019s eunuch, keeper of the women. Let cosmetics be given them;",
                "and let the maiden who pleases the king be queen instead of Vashti.\" The thing pleased the king, and he did so.",
                "There was a certain Jew in the citadel of Susa, whose name was Mordecai, the son of Jair, the son of Shimei, the son of Kish, a Benjamite,",
                "who had been carried away from Jerusalem with the captives who had been carried away with Jeconiah king of Judah, whom Nebuchadnezzar the king of Babylon had carried away.",
                "He brought up Hadassah, that is, Esther, his uncle\u2019s daughter; for she had neither father nor mother. The maiden was fair and beautiful; and when her father and mother were dead, Mordecai took her for his own daughter.",
                "So it happened, when the king\u2019s commandment and his decree was heard, and when many maidens were gathered together to the citadel of Susa, to the custody of Hegai, that Esther was taken into the king\u2019s house, to the custody of Hegai, keeper of the women.",
                "The maiden pleased him, and she obtained kindness from him. He quickly gave her cosmetics and her portions of food, and the seven choice maidens who were to be given her out of the king\u2019s house. He moved her and her maidens to the best place in the women\u2019s house.",
                "Esther had not made known her people nor her relatives, because Mordecai had instructed her that she should not make it known.",
                "Mordecai walked every day in front of the court of the women\u2019s house, to find out how Esther was doing, and what would become of her.",
                "Each young woman\u2019s turn came to go in to King Ahasuerus after her purification for twelve months (for so were the days of their purification accomplished, six months with oil of myrrh, and six months with sweet fragrances and with preparations for beautifying women).",
                "The young woman then came to the king like this: whatever she desired was given her to go with her out of the women\u2019s house to the king\u2019s house.",
                "In the evening she went, and on the next day she returned into the second women\u2019s house, to the custody of Shaashgaz, the king\u2019s eunuch, who kept the concubines. She came in to the king no more, unless the king delighted in her, and she was called by name.",
                "Now when the turn of Esther, the daughter of Abihail the uncle of Mordecai, who had taken her for his daughter, came to go in to the king, she required nothing but what Hegai the king\u2019s eunuch, the keeper of the women, advised. Esther obtained favor in the sight of all those who looked at her.",
                "So Esther was taken to King Ahasuerus into his royal house in the tenth month, which is the month Tebeth, in the seventh year of his reign.",
                "The king loved Esther more than all the women, and she obtained favor and kindness in his sight more than all the virgins; so that he set the royal crown on her head, and made her queen instead of Vashti.",
                "Then the king made a great feast for all his princes and his servants, even Esther\u2019s feast; and he proclaimed a holiday in the provinces, and gave gifts according to the king\u2019s bounty.",
                "When the virgins were gathered together the second time, Mordecai was sitting in the king\u2019s gate.",
                "Esther had not yet made known her relatives nor her people, as Mordecai had commanded her; for Esther obeyed Mordecai, like she did when she was brought up by him.",
                "In those days, while Mordecai was sitting in the king\u2019s gate, two of the king\u2019s eunuchs, Bigthan and Teresh, who were doorkeepers, were angry, and sought to lay hands on the King Ahasuerus.",
                "This thing became known to Mordecai, who informed Esther the queen; and Esther informed the king in Mordecai\u2019s name.",
                "When this matter was investigated, and it was found to be so, they were both hanged on a tree; and it was written in the book of the chronicles in the king\u2019s presence."
            ],
            3: [
                "After these things King Ahasuerus promoted Haman the son of Hammedatha the Agagite, and advanced him, and set his seat above all the princes who were with him.",
                "All the king\u2019s servants who were in the king\u2019s gate bowed down, and paid homage to Haman; for the king had so commanded concerning him. But Mordecai didn\u2019t bow down or pay him homage.",
                "Then the king\u2019s servants, who were in the king\u2019s gate, said to Mordecai, \"Why do you disobey the king\u2019s commandment?\"",
                "Now it came to pass, when they spoke daily to him, and he didn\u2019t listen to them, that they told Haman, to see whether Mordecai\u2019s reason would stand; for he had told them that he was a Jew.",
                "When Haman saw that Mordecai didn\u2019t bow down, nor pay him homage, Haman was full of wrath.",
                "But he scorned the thought of laying hands on Mordecai alone, for they had made known to him Mordecai\u2019s people. Therefore Haman sought to destroy all the Jews who were throughout the whole kingdom of Ahasuerus, even Mordecai\u2019s people.",
                "In the first month, which is the month Nisan, in the twelfth year of King Ahasuerus, they cast Pur, that is, the lot, before Haman from day to day, and from month to month, and chose the twelfth month, which is the month Adar.",
                "Haman said to King Ahasuerus, \"There is a certain people scattered abroad and dispersed among the peoples in all the provinces of your kingdom, and their laws are different than other people\u2019s. They don\u2019t keep the king\u2019s laws. Therefore it is not for the king\u2019s profit to allow them to remain.",
                "If it pleases the king, let it be written that they be destroyed; and I will pay ten thousand talents of silver into the hands of those who are in charge of the king\u2019s business, to bring it into the king\u2019s treasuries.\"",
                "The king took his ring from his hand, and gave it to Haman the son of Hammedatha the Agagite, the Jews\u2019 enemy.",
                "The king said to Haman, \"The silver is given to you, the people also, to do with them as it seems good to you.\"",
                "Then the king\u2019s scribes were called in on the first month, on the thirteenth day of the month; and all that Haman commanded was written to the king\u2019s satraps, and to the governors who were over every province, and to the princes of every people, to every province according its writing, and to every people in their language. It was written in the name of King Ahasuerus, and it was sealed with the king\u2019s ring.",
                "Letters were sent by couriers into all the king\u2019s provinces, to destroy, to kill, and to cause to perish, all Jews, both young and old, little children and women, in one day, even on the thirteenth day of the twelfth month, which is the month Adar, and to plunder their possessions.",
                "A copy of the letter, that the decree should be given out in every province, was published to all the peoples, that they should be ready against that day.",
                "The couriers went forth in haste by the king\u2019s commandment, and the decree was given out in the citadel of Susa. The king and Haman sat down to drink; but the city of Shushan was perplexed."
            ],
            4: [
                "Now when Mordecai found out all that was done, Mordecai tore his clothes, and put on sackcloth with ashes, and went out into the midst of the city, and wailed loudly and a bitterly.",
                "He came even before the king\u2019s gate, for no one is allowed inside the king\u2019s gate clothed with sackcloth.",
                "In every province, wherever the king\u2019s commandment and his decree came, there was great mourning among the Jews, and fasting, and weeping, and wailing; and many lay in sackcloth and ashes.",
                "Esther\u2019s maidens and her eunuchs came and told her this, and the queen was exceedingly grieved. She sent clothing to Mordecai, to replace his sackcloth; but he didn\u2019t receive it.",
                "Then Esther called for Hathach, one of the king\u2019s eunuchs, whom he had appointed to attend her, and commanded him to go to Mordecai, to find out what this was, and why it was.",
                "So Hathach went out to Mordecai, to city square which was before the king\u2019s gate.",
                "Mordecai told him of all that had happened to him, and the exact sum of the money that Haman had promised to pay to the king\u2019s treasuries for the destruction of the Jews.",
                "He also gave him the copy of the writing of the decree that was given out in Shushan to destroy them, to show it to Esther, and to declare it to her, and to urge her to go in to the king, to make supplication to him, and to make request before him, for her people.",
                "Hathach came and told Esther the words of Mordecai.",
                "Then Esther spoke to Hathach, and gave him a message to Mordecai:",
                "\"All the king\u2019s servants, and the people of the king\u2019s provinces, know, that whoever, whether man or woman, comes to the king into the inner court without being called, there is one law for him, that he be put to death, except those to whom the king might hold out the golden scepter, that he may live. I have not been called to come in to the king these thirty days.\"",
                "They told to Mordecai Esther\u2019s words.",
                "Then Mordecai asked them return answer to Esther, \"Don\u2019t think to yourself that you will escape in the king\u2019s house any more than all the Jews.",
                "For if you remain silent now, then relief and deliverance will come to the Jews from another place, but you and your father\u2019s house will perish. Who knows if you haven\u2019t come to the kingdom for such a time as this?\"",
                "Then Esther asked them to answer Mordecai,",
                "\"Go, gather together all the Jews who are present in Shushan, and fast for me, and neither eat nor drink three days, night or day. I and my maidens will also fast the same way. Then I will go in to the king, which is against the law; and if I perish, I perish.\"",
                "So Mordecai went his way, and did according to all that Esther had commanded him."
            ],
            5: [
                "Now it happened on the third day that Esther put on her royal clothing, and stood in the inner court of the king\u2019s house, next to the king\u2019s house. The king sat on his royal throne in the royal house, next to the entrance of the house.",
                "When the king saw Esther the queen standing in the court, she obtained favor in his sight; and the king held out to Esther the golden scepter that was in his hand. So Esther came near, and touched the top of the scepter.",
                "Then the king asked her, \"What would you like, queen Esther? What is your request? It shall be given you even to the half of the kingdom.\"",
                "Esther said, \"If it seems good to the king, let the king and Haman come today to the banquet that I have prepared for him.\"",
                "Then the king said, \"Bring Haman quickly, so that it may be done as Esther has said.\" So the king and Haman came to the banquet that Esther had prepared.",
                "The king said to Esther at the banquet of wine, \"What is your petition? It shall be granted you. What is your request? Even to the half of the kingdom it shall be performed.\"",
                "Then Esther answered and said, \"My petition and my request is this.",
                "If I have found favor in the sight of the king, and if it please the king to grant my petition and to perform my request, let the king and Haman come to the banquet that I will prepare for them, and I will do tomorrow as the king has said.\"",
                "Then Haman went out that day joyful and glad of heart, but when Haman saw Mordecai in the king\u2019s gate, that he didn\u2019t stand up nor move for him, he was filled with wrath against Mordecai.",
                "Nevertheless Haman restrained himself, and went home. There, he sent and called for his friends and Zeresh his wife.",
                "Haman recounted to them the glory of his riches, the multitude of his children, all the things in which the king had promoted him, and how he had advanced him above the princes and servants of the king.",
                "Haman also said, \"Yes, Esther the queen let no man come in with the king to the banquet that she had prepared but myself; and tomorrow I am also invited by her together with the king.",
                "Yet all this avails me nothing, so long as I see Mordecai the Jew sitting at the king\u2019s gate.\"",
                "Then Zeresh his wife and all his friends said to him, \"Let a gallows be made fifty cubits high, and in the morning speak to the king about hanging Mordecai on it. Then go in merrily with the king to the banquet.\" This pleased Haman, so he had the gallows made."
            ],
            6: [
                "On that night, the king couldn\u2019t sleep. He commanded the book of records of the chronicles to be brought, and they were read to the king.",
                "It was found written that Mordecai had told of Bigthana and Teresh, two of the king\u2019s eunuchs, who were doorkeepers, who had tried to lay hands on the King Ahasuerus.",
                "The king said, \"What honor and dignity has been bestowed on Mordecai for this?\" Then the king\u2019s servants who attended him said, \"Nothing has been done for him.\"",
                "The king said, \"Who is in the court?\" Now Haman had come into the outer court of the king\u2019s house, to speak to the king about hanging Mordecai on the gallows that he had prepared for him.",
                "The king\u2019s servants said to him, \"Behold, Haman stands in the court.\" The king said, \"Let him come in.\"",
                "So Haman came in. The king said to him, \"What shall be done to the man whom the king delights to honor?\" Now Haman said in his heart, \"Who would the king delight to honor more than myself?\"",
                "Haman said to the king, \"For the man whom the king delights to honor,",
                "let royal clothing be brought which the king uses to wear, and the horse that the king rides on, and on the head of which a crown royal is set.",
                "Let the clothing and the horse be delivered to the hand of one of the king\u2019s most noble princes, that they may array the man whom the king delights to honor with them, and have him ride on horseback through the city square, and proclaim before him, \u2018Thus shall it be done to the man whom the king delights to honor!\u2019\"",
                "Then the king said to Haman, \"Hurry and take the clothing and the horse, as you have said, and do this for Mordecai the Jew, who sits at the king\u2019s gate. Let nothing fail of all that you have spoken.\"",
                "Then Haman took the clothing and the horse, and arrayed Mordecai, and had him ride through the city square, and proclaimed before him, \"Thus shall it be done to the man whom the king delights to honor!\"",
                "Mordecai came back to the king\u2019s gate, but Haman hurried to his house, mourning and having his head covered.",
                "Haman recounted to Zeresh his wife and all his friends everything that had happened to him. Then his wise men and Zeresh his wife said to him, \"If Mordecai, before whom you have begun to fall, is of Jewish descent, you will not prevail against him, but you will surely fall before him.\"",
                "While they were yet talking with him, the king\u2019s eunuchs came, and hurried to bring Haman to the banquet that Esther had prepared."
            ],
            7: [
                "So the king and Haman came to banquet with Esther the queen.",
                "The king said again to Esther on the second day at the banquet of wine, \"What is your petition, queen Esther? It shall be granted you. What is your request? Even to the half of the kingdom it shall be performed.\"",
                "Then Esther the queen answered, \"If I have found favor in your sight, O king, and if it please the king, let my life be given me at my petition, and my people at my request.",
                "For we are sold, I and my people, to be destroyed, to be slain, and to perish. But if we had been sold for bondservants and bondmaids, I would have held my peace, although the adversary could not have compensated for the king\u2019s loss.\"",
                "Then King Ahasuerus said to Esther the queen, \"Who is he, and where is he who dared presume in his heart to do so?\"",
                "Esther said, \"An adversary and an enemy, even this wicked Haman!\" Then Haman was afraid before the king and the queen.",
                "The king arose in his wrath from the banquet of wine and went into the palace garden. Haman stood up to make request for his life to Esther the queen; for he saw that there was evil determined against him by the king.",
                "Then the king returned out of the palace garden into the place of the banquet of wine; and Haman had fallen on the couch where Esther was. Then the king said, \"Will he even assault the queen in front of me in the house?\" As the word went out of the king\u2019s mouth, they covered Haman\u2019s face.",
                "Then Harbonah, one of the eunuchs who were with the king said, \"Behold, the gallows fifty cubits high, which Haman has made for Mordecai, who spoke good for the king, is standing at Haman\u2019s house.\" The king said, \"Hang him on it!\"",
                "So they hanged Haman on the gallows that he had prepared for Mordecai. Then was the king\u2019s wrath pacified."
            ],
            8: [
                "On that day, King Ahasuerus gave the house of Haman, the Jews\u2019 enemy, to Esther the queen. Mordecai came before the king; for Esther had told what he was to her.",
                "The king took off his ring, which he had taken from Haman, and gave it to Mordecai. Esther set Mordecai over the house of Haman.",
                "Esther spoke yet again before the king, and fell down at his feet, and begged him with tears to put away the mischief of Haman the Agagite, and his device that he had devised against the Jews.",
                "Then the king held out to Esther the golden scepter. So Esther arose, and stood before the king.",
                "She said, \"If it pleases the king, and if I have found favor in his sight, and the thing seem right to the king, and I am pleasing in his eyes, let it be written to reverse the letters devised by Haman, the son of Hammedatha the Agagite, which he wrote to destroy the Jews who are in all the king\u2019s provinces.",
                "For how can I endure to see the evil that would come to my people? How can I endure to see the destruction of my relatives?\"",
                "Then King Ahasuerus said to Esther the queen and to Mordecai the Jew, \"See, I have given Esther the house of Haman, and him they have hanged on the gallows, because he laid his hand on the Jews.",
                "Write also to the Jews, as it pleases you, in the king\u2019s name, and seal it with the king\u2019s ring; for the writing which is written in the king\u2019s name, and sealed with the king\u2019s ring, may not be reversed by any man.\"",
                "Then the king\u2019s scribes were called at that time, in the third month Sivan, on the twenty-third day of the month; and it was written according to all that Mordecai commanded to the Jews, and to the satraps, and the governors and princes of the provinces which are from India to Ethiopia, one hundred twenty-seven provinces, to every province according to its writing, and to every people in their language, and to the Jews in their writing, and in their language.",
                "He wrote in the name of King Ahasuerus, and sealed it with the king\u2019s ring, and sent letters by courier on horseback, riding on royal horses that were bread from swift steeds.",
                "In those letters, the king granted the Jews who were in every city to gather themselves together, and to defend their life, to destroy, to kill, and to cause to perish, all the power of the people and province that would assault them, their little ones and women, and to plunder their possessions,",
                "on one day in all the provinces of King Ahasuerus, on the thirteenth day of the twelfth month, which is the month Adar.",
                "A copy of the letter, that the decree should be given out in every province, was published to all the peoples, that the Jews should be ready for that day to avenge themselves on their enemies.",
                "So the couriers who rode on royal horses went out, hastened and pressed on by the king\u2019s commandment. The decree was given out in the citadel of Susa.",
                "Mordecai went out of the presence of the king in royal clothing of blue and white, and with a great crown of gold, and with a robe of fine linen and purple; and the city of Susa shouted and was glad.",
                "The Jews had light, gladness, joy, and honor.",
                "In every province, and in every city, wherever the king\u2019s commandment and his decree came, the Jews had gladness, joy, a feast, and a good day. Many from among the peoples of the land became Jews; for the fear of the Jews was fallen on them."
            ],
            9: [
                "Now in the twelfth month, which is the month Adar, on the thirteenth day of the month, when the king\u2019s commandment and his decree drew near to be put in execution, on the day that the enemies of the Jews hoped to conquer them, (but it was turned out the opposite happened, that the Jews conquered those who hated them),",
                "the Jews gathered themselves together in their cities throughout all the provinces of the King Ahasuerus, to lay hands on those who wanted to harm them. No one could withstand them, because the fear of them had fallen on all the people.",
                "All the princes of the provinces, the satraps, the governors, and those who did the king\u2019s business helped the Jews, because the fear of Mordecai had fallen on them.",
                "For Mordecai was great in the king\u2019s house, and his fame went out throughout all the provinces; for the man Mordecai grew greater and greater.",
                "The Jews struck all their enemies with the stroke of the sword, and with slaughter and destruction, and did what they wanted to those who hated them.",
                "In the citadel of Susa, the Jews killed and destroyed five hundred men.",
                "They killed Parshandatha, Dalphon, Aspatha,",
                "Poratha, Adalia, Aridatha,",
                "Parmashta, Arisai, Aridai, and Vaizatha,",
                "the ten sons of Haman the son of Hammedatha, the Jew\u2019s enemy, but they didn\u2019t lay their hand on the plunder.",
                "On that day, the number of those who were slain in the citadel of Susa was brought before the king.",
                "The king said to Esther the queen, \"The Jews have slain and destroyed five hundred men in the citadel of Susa, including the ten sons of Haman; what then have they done in the rest of the king\u2019s provinces! Now what is your petition? It shall be granted you. What is your further request? It shall be done.\"",
                "Then Esther said, \"If it pleases the king, let it be granted to the Jews who are in Shushan to do tomorrow also according to this day\u2019s decree, and let Haman\u2019s ten sons be hanged on the gallows.\"",
                "The king commanded this to be done. A decree was given out in Shushan; and they hanged Haman\u2019s ten sons.",
                "The Jews who were in Shushan gathered themselves together on the fourteenth day also of the month Adar, and killed three hundred men in Shushan; but they didn\u2019t lay their hand on the spoil.",
                "The other Jews who were in the king\u2019s provinces gathered themselves together, defended their lives, had rest from their enemies, and killed seventy-five thousand of those who hated them; but they didn\u2019t lay their hand on the plunder.",
                "This was done on the thirteenth day of the month Adar; and on the fourteenth day of that month they rested and made it a day of feasting and gladness.",
                "But the Jews who were in Shushan assembled together on the thirteenth and on the fourteenth days of the month; and on the fifteenth day of that month, they rested, and made it a day of feasting and gladness.",
                "Therefore the Jews of the villages, who live in the unwalled towns, make the fourteenth day of the month Adar a day of gladness and feasting, a good day, and a day of sending presents of food to one another.",
                "Mordecai wrote these things, and sent letters to all the Jews who were in all the provinces of the king Ahasuerus, both near and far,",
                "to enjoin them that they should keep the fourteenth and fifteenth days of the month Adar yearly,",
                "as the days in which the Jews had rest from their enemies, and the month which was turned to them from sorrow to gladness, and from mourning into a good day; that they should make them days of feasting and gladness, and of sending presents of food to one another, and gifts to the needy.",
                "The Jews accepted the custom that they had begun, as Mordecai had written to them;",
                "because Haman the son of Hammedatha, the Agagite, the enemy of all the Jews, had plotted against the Jews to destroy them, and had cast \"Pur,\" that is the lot, to consume them, and to destroy them;",
                "but when this became known to the king, he commanded by letters that his wicked device, which he had devised against the Jews, should return on his own head, and that he and his sons should be hanged on the gallows.",
                "Therefore they called these days \"Purim,\" from the word \"Pur.\" Therefore because of all the words of this letter, and of that which they had seen concerning this matter, and that which had come to them,",
                "the Jews established, and imposed on themselves, and on their descendants, and on all those who joined themselves to them, so that it should not fail, that they would keep these two days according to what was written, and according to its appointed time, every year;",
                "and that these days should be remembered and kept throughout every generation, every family, every province, and every city; and that these days of Purim should not fail from among the Jews, nor their memory perish from their seed.",
                "Then Esther the queen, the daughter of Abihail, and Mordecai the Jew, wrote with all authority to confirm this second letter of Purim.",
                "He sent letters to all the Jews, to the hundred twenty-seven provinces of the kingdom of Ahasuerus, with words of peace and truth,",
                "to confirm these days of Purim in their appointed times, as Mordecai the Jew and Esther the queen had decreed, and as they had imposed upon themselves and their descendants, in the matter of the fastings and their cry.",
                "The commandment of Esther confirmed these matters of Purim; and it was written in the book."
            ],
            10: [
                "King Ahasuerus laid a tribute on the land, and on the islands of the sea.",
                "All the acts of his power and of his might, and the full account of the greatness of Mordecai, to which the king advanced him, aren\u2019t they written in the book of the chronicles of the kings of Media and Persia?",
                "For Mordecai the Jew was next to King Ahasuerus, and great among the Jews, and accepted by the multitude of his brothers, seeking the good of his people, and speaking peace to all his descendants."
            ]
        },
        18: {
            1: [
                "There was a man in the land of Uz, whose name was Job. That man was blameless and upright, and one who feared God, and turned away from evil.",
                "There were born to him seven sons and three daughters.",
                "His possessions also were seven thousand sheep, three thousand camels, five hundred yoke of oxen, five hundred female donkeys, and a very great household; so that this man was the greatest of all the children of the east.",
                "His sons went and held a feast in the house of each one on his birthday; and they sent and called for their three sisters to eat and to drink with them.",
                "It was so, when the days of their feasting had run their course, that Job sent and sanctified them, and rose up early in the morning, and offered burnt offerings according to the number of them all. For Job said, \"It may be that my sons have sinned, and renounced God in their hearts.\" Job did so continually.",
                "Now it happened on the day when God\u2019s sons came to present themselves before Yahweh, that Satan also came among them.",
                "Yahweh said to Satan, \"Where have you come from?\" Then Satan answered Yahweh, and said, \"From going back and forth in the earth, and from walking up and down in it.\"",
                "Yahweh said to Satan, \"Have you considered my servant, Job? For there is none like him in the earth, a blameless and an upright man, one who fears God, and turns away from evil.\"",
                "Then Satan answered Yahweh, and said, \"Does Job fear God for nothing?",
                "Haven\u2019t you made a hedge around him, and around his house, and around all that he has, on every side? You have blessed the work of his hands, and his substance is increased in the land.",
                "But put forth your hand now, and touch all that he has, and he will renounce you to your face.\"",
                "Yahweh said to Satan, \"Behold, all that he has is in your power. Only on himself don\u2019t put forth your hand.\" So Satan went forth from the presence of Yahweh.",
                "It fell on a day when his sons and his daughters were eating and drinking wine in their eldest brother\u2019s house,",
                "that there came a messenger to Job, and said, \"The oxen were plowing, and the donkeys feeding beside them,",
                "and the Sabeans attacked, and took them away. Yes, they have killed the servants with the edge of the sword, and I alone have escaped to tell you.\"",
                "While he was still speaking, there also came another, and said, \"The fire of God has fallen from the sky, and has burned up the sheep and the servants, and consumed them, and I alone have escaped to tell you.\"",
                "While he was still speaking, there came also another, and said, \"The Chaldeans made three bands, and swept down on the camels, and have taken them away, yes, and killed the servants with the edge of the sword; and I alone have escaped to tell you.\"",
                "While he was still speaking, there came also another, and said, \"Your sons and your daughters were eating and drinking wine in their eldest brother\u2019s house,",
                "and behold, there came a great wind from the wilderness, and struck the four corners of the house, and it fell on the young men, and they are dead. I alone have escaped to tell you.\"",
                "Then Job arose, and tore his robe, and shaved his head, and fell down on the ground, and worshiped.",
                "He said, \"Naked I came out of my mother\u2019s womb, and naked shall I return there. Yahweh gave, and Yahweh has taken away. Blessed be the name of Yahweh.\"",
                "In all this, Job did not sin, nor charge God with wrongdoing."
            ],
            2: [
                "Again it happened on the day when the God\u2019s sons came to present themselves before Yahweh, that Satan came also among them to present himself before Yahweh.",
                "Yahweh said to Satan, \"Where have you come from?\" Satan answered Yahweh, and said, \"From going back and forth in the earth, and from walking up and down in it.\"",
                "Yahweh said to Satan, \"Have you considered my servant Job? For there is none like him in the earth, a blameless and an upright man, one who fears God, and turns away from evil. He still maintains his integrity, although you incited me against him, to ruin him without cause.\"",
                "Satan answered Yahweh, and said, \"Skin for skin. Yes, all that a man has he will give for his life.",
                "But put forth your hand now, and touch his bone and his flesh, and he will renounce you to your face.\"",
                "Yahweh said to Satan, \"Behold, he is in your hand. Only spare his life.\"",
                "So Satan went forth from the presence of Yahweh, and struck Job with painful sores from the sole of his foot to his head.",
                "He took for himself a potsherd to scrape himself with, and he sat among the ashes.",
                "Then his wife said to him, \"Do you still maintain your integrity? Renounce God, and die.\"",
                "But he said to her, \"You speak as one of the foolish women would speak. What? Shall we receive good at the hand of God, and shall we not receive evil?\" In all this Job didn\u2019t sin with his lips.",
                "Now when Job\u2019s three friends heard of all this evil that had come on him, they each came from his own place: Eliphaz the Temanite, Bildad the Shuhite, and Zophar the Naamathite, and they made an appointment together to come to sympathize with him and to comfort him.",
                "When they lifted up their eyes from a distance, and didn\u2019t recognize him, they raised their voices, and wept; and they each tore his robe, and sprinkled dust on their heads toward the sky.",
                "So they sat down with him on the ground seven days and seven nights, and none spoke a word to him, for they saw that his grief was very great."
            ],
            3: [
                "After this Job opened his mouth, and cursed the day of his birth.",
                "Job answered:",
                "\"Let the day perish in which I was born, the night which said, \u2018There is a boy conceived.\u2019",
                "Let that day be darkness. Don\u2019t let God from above seek for it, neither let the light shine on it.",
                "Let darkness and the shadow of death claim it for their own. Let a cloud dwell on it. Let all that makes black the day terrify it.",
                "As for that night, let thick darkness seize on it. Let it not rejoice among the days of the year. Let it not come into the number of the months.",
                "Behold, let that night be barren. Let no joyful voice come therein.",
                "Let them curse it who curse the day, who are ready to rouse up leviathan.",
                "Let the stars of its twilight be dark. Let it look for light, but have none, neither let it see the eyelids of the morning,",
                "because it didn\u2019t shut up the doors of my mother\u2019s womb, nor did it hide trouble from my eyes.",
                "\"Why didn\u2019t I die from the womb? Why didn\u2019t I give up the spirit when my mother bore me?",
                "Why did the knees receive me? Or why the breast, that I should nurse?",
                "For now should I have lain down and been quiet. I should have slept, then I would have been at rest,",
                "with kings and counselors of the earth, who built up waste places for themselves;",
                "or with princes who had gold, who filled their houses with silver:",
                "or as a hidden untimely birth I had not been, as infants who never saw light.",
                "There the wicked cease from troubling. There the weary are at rest.",
                "There the prisoners are at ease together. They don\u2019t hear the voice of the taskmaster.",
                "The small and the great are there. The servant is free from his master.",
                "\"Why is light given to him who is in misery, life to the bitter in soul,",
                "Who long for death, but it doesn\u2019t come; and dig for it more than for hidden treasures,",
                "who rejoice exceedingly, and are glad, when they can find the grave?",
                "Why is light given to a man whose way is hidden, whom God has hedged in?",
                "For my sighing comes before I eat. My groanings are poured out like water.",
                "For the thing which I fear comes on me, That which I am afraid of comes to me.",
                "I am not at ease, neither am I quiet, neither have I rest; but trouble comes.\""
            ],
            4: [
                "Then Eliphaz the Temanite answered,",
                "\"If someone ventures to talk with you, will you be grieved? But who can withhold himself from speaking?",
                "Behold, you have instructed many, you have strengthened the weak hands.",
                "Your words have supported him who was falling, You have made firm the feeble knees.",
                "But now it has come to you, and you faint. It touches you, and you are troubled.",
                "Isn\u2019t your piety your confidence? Isn\u2019t the integrity of your ways your hope?",
                "\"Remember, now, whoever perished, being innocent? Or where were the upright cut off?",
                "According to what I have seen, those who plow iniquity, and sow trouble, reap the same.",
                "By the breath of God they perish. By the blast of his anger are they consumed.",
                "The roaring of the lion, and the voice of the fierce lion, the teeth of the young lions, are broken.",
                "The old lion perishes for lack of prey. The cubs of the lioness are scattered abroad.",
                "\"Now a thing was secretly brought to me. My ear received a whisper of it.",
                "In thoughts from the visions of the night, when deep sleep falls on men,",
                "fear came on me, and trembling, which made all my bones shake.",
                "Then a spirit passed before my face. The hair of my flesh stood up.",
                "It stood still, but I couldn\u2019t discern its appearance. A form was before my eyes. Silence, then I heard a voice, saying,",
                "\u2018Shall mortal man be more just than God? Shall a man be more pure than his Maker?",
                "Behold, he puts no trust in his servants. He charges his angels with error.",
                "How much more, those who dwell in houses of clay, whose foundation is in the dust, who are crushed before the moth!",
                "Between morning and evening they are destroyed. They perish forever without any regarding it.",
                "Isn\u2019t their tent cord plucked up within them? They die, and that without wisdom.\u2019"
            ],
            5: [
                "\"Call now; is there any who will answer you? To which of the holy ones will you turn?",
                "For resentment kills the foolish man, and jealousy kills the simple.",
                "I have seen the foolish taking root, but suddenly I cursed his habitation.",
                "His children are far from safety. They are crushed in the gate. Neither is there any to deliver them,",
                "whose harvest the hungry eats up, and take it even out of the thorns. The snare gapes for their substance.",
                "For affliction doesn\u2019t come forth from the dust, neither does trouble spring out of the ground;",
                "but man is born to trouble, as the sparks fly upward.",
                "\"But as for me, I would seek God. I would commit my cause to God,",
                "who does great things that can\u2019t be fathomed, marvelous things without number;",
                "who gives rain on the earth, and sends waters on the fields;",
                "so that he sets up on high those who are low, those who mourn are exalted to safety.",
                "He frustrates the devices of the crafty, So that their hands can\u2019t perform their enterprise.",
                "He takes the wise in their own craftiness; the counsel of the cunning is carried headlong.",
                "They meet with darkness in the day time, and grope at noonday as in the night.",
                "But he saves from the sword of their mouth, even the needy from the hand of the mighty.",
                "So the poor has hope, and injustice shuts her mouth.",
                "\"Behold, happy is the man whom God corrects. Therefore do not despise the chastening of the Almighty.",
                "For he wounds, and binds up. He injures, and his hands make whole.",
                "He will deliver you in six troubles; yes, in seven no evil shall touch you.",
                "In famine he will redeem you from death; in war, from the power of the sword.",
                "You shall be hidden from the scourge of the tongue, neither shall you be afraid of destruction when it comes.",
                "At destruction and famine you shall laugh, neither shall you be afraid of the animals of the earth.",
                "For you shall be allied with the stones of the field. The animals of the field shall be at peace with you.",
                "You shall know that your tent is in peace. You shall visit your fold, and shall miss nothing.",
                "You shall know also that your seed shall be great, Your offspring as the grass of the earth.",
                "You shall come to your grave in a full age, like a shock of grain comes in its season.",
                "Look this, we have searched it, so it is. Hear it, and know it for your good.\""
            ],
            6: [
                "Then Job answered,",
                "\"Oh that my anguish were weighed, and all my calamity laid in the balances!",
                "For now it would be heavier than the sand of the seas, therefore have my words been rash.",
                "For the arrows of the Almighty are within me. My spirit drinks up their poison. The terrors of God set themselves in array against me.",
                "Does the wild donkey bray when he has grass? Or does the ox low over his fodder?",
                "Can that which has no flavor be eaten without salt? Or is there any taste in the white of an egg?",
                "My soul refuses to touch them. They are as loathsome food to me.",
                "\"Oh that I might have my request, that God would grant the thing that I long for,",
                "even that it would please God to crush me; that he would let loose his hand, and cut me off!",
                "Be it still my consolation, yes, let me exult in pain that doesn\u2019t spare, that I have not denied the words of the Holy One.",
                "What is my strength, that I should wait? What is my end, that I should be patient?",
                "Is my strength the strength of stones? Or is my flesh of brass?",
                "Isn\u2019t it that I have no help in me, That wisdom is driven quite from me?",
                "\"To him who is ready to faint, kindness should be shown from his friend; even to him who forsakes the fear of the Almighty.",
                "My brothers have dealt deceitfully as a brook, as the channel of brooks that pass away;",
                "Which are black by reason of the ice, in which the snow hides itself.",
                "In the dry season, they vanish. When it is hot, they are consumed out of their place.",
                "The caravans that travel beside them turn aside. They go up into the waste, and perish.",
                "The caravans of Tema looked. The companies of Sheba waited for them.",
                "They were distressed because they were confident. They came there, and were confounded.",
                "For now you are nothing. You see a terror, and are afraid.",
                "Did I say, \u2018Give to me?\u2019 or, \u2018Offer a present for me from your substance?\u2019",
                "or, \u2018Deliver me from the adversary\u2019s hand?\u2019 or, \u2018Redeem me from the hand of the oppressors?\u2019",
                "\"Teach me, and I will hold my peace. Cause me to understand wherein I have erred.",
                "How forcible are words of uprightness! But your reproof, what does it reprove?",
                "Do you intend to reprove words, since the speeches of one who is desperate are as wind?",
                "Yes, you would even cast lots for the fatherless, and make merchandise of your friend.",
                "Now therefore be pleased to look at me, for surely I shall not lie to your face.",
                "Please return. Let there be no injustice. Yes, return again. My cause is righteous.",
                "Is there injustice on my tongue? Can\u2019t my taste discern mischievous things?"
            ],
            7: [
                "\"Isn\u2019t a man forced to labor on earth? Aren\u2019t his days like the days of a hired hand?",
                "As a servant who earnestly desires the shadow, as a hireling who looks for his wages,",
                "so am I made to possess months of misery, wearisome nights are appointed to me.",
                "When I lie down, I say, \u2018When shall I arise, and the night be gone?\u2019 I toss and turn until the dawning of the day.",
                "My flesh is clothed with worms and clods of dust. My skin closes up, and breaks out afresh.",
                "My days are swifter than a weaver\u2019s shuttle, and are spent without hope.",
                "Oh remember that my life is a breath. My eye shall no more see good.",
                "The eye of him who sees me shall see me no more. Your eyes shall be on me, but I shall not be.",
                "As the cloud is consumed and vanishes away, so he who goes down to Sheol shall come up no more.",
                "He shall return no more to his house, neither shall his place know him any more.",
                "\"Therefore I will not keep silent. I will speak in the anguish of my spirit. I will complain in the bitterness of my soul.",
                "Am I a sea, or a sea monster, that you put a guard over me?",
                "When I say, \u2018My bed shall comfort me. My couch shall ease my complaint;\u2019",
                "then you scare me with dreams, and terrify me through visions:",
                "so that my soul chooses strangling, death rather than my bones.",
                "I loathe my life. I don\u2019t want to live forever. Leave me alone, for my days are but a breath.",
                "What is man, that you should magnify him, that you should set your mind on him,",
                "that you should visit him every morning, and test him every moment?",
                "How long will you not look away from me, nor leave me alone until I swallow down my spittle?",
                "If I have sinned, what do I do to you, you watcher of men? Why have you set me as a mark for you, so that I am a burden to myself?",
                "Why do you not pardon my disobedience, and take away my iniquity? For now shall I lie down in the dust. You will seek me diligently, but I shall not be.\""
            ],
            8: [
                "Then Bildad the Shuhite answered,",
                "\"How long will you speak these things? Shall the words of your mouth be a mighty wind?",
                "Does God pervert justice? Or does the Almighty pervert righteousness?",
                "If your children have sinned against him, He has delivered them into the hand of their disobedience.",
                "If you want to seek God diligently, make your supplication to the Almighty.",
                "If you were pure and upright, surely now he would awaken for you, and make the habitation of your righteousness prosperous.",
                "Though your beginning was small, yet your latter end would greatly increase.",
                "\"Please inquire of past generations. Find out about the learning of their fathers.",
                "(For we are but of yesterday, and know nothing, because our days on earth are a shadow.)",
                "Shall they not teach you, tell you, and utter words out of their heart?",
                "\"Can the papyrus grow up without mire? Can the rushes grow without water?",
                "While it is yet in its greenness, not cut down, it withers before any other reed.",
                "So are the paths of all who forget God. The hope of the godless man shall perish,",
                "Whose confidence shall break apart, Whose trust is a spider\u2019s web.",
                "He shall lean on his house, but it shall not stand. He shall cling to it, but it shall not endure.",
                "He is green before the sun. His shoots go forth over his garden.",
                "His roots are wrapped around the rock pile. He sees the place of stones.",
                "If he is destroyed from his place, then it shall deny him, saying, \u2018I have not seen you.\u2019",
                "Behold, this is the joy of his way: out of the earth, others shall spring.",
                "\"Behold, God will not cast away a blameless man, neither will he uphold the evildoers.",
                "He will still fill your mouth with laughter, your lips with shouting.",
                "Those who hate you shall be clothed with shame. The tent of the wicked shall be no more.\""
            ],
            9: [
                "Then Job answered,",
                "\"Truly I know that it is so, but how can man be just with God?",
                "If he is pleased to contend with him, he can\u2019t answer him one time in a thousand.",
                "God who is wise in heart, and mighty in strength: who has hardened himself against him, and prospered?",
                "He removes the mountains, and they don\u2019t know it, when he overturns them in his anger.",
                "He shakes the earth out of its place. Its pillars tremble.",
                "He commands the sun, and it doesn\u2019t rise, and seals up the stars.",
                "He alone stretches out the heavens, and treads on the waves of the sea.",
                "He makes the Bear, Orion, and the Pleiades, and the rooms of the south.",
                "He does great things past finding out; yes, marvelous things without number.",
                "Behold, he goes by me, and I don\u2019t see him. He passes on also, but I don\u2019t perceive him.",
                "Behold, he snatches away. Who can hinder him? Who will ask him, \u2018What are you doing?\u2019",
                "\"God will not withdraw his anger. The helpers of Rahab stoop under him.",
                "How much less shall I answer him, And choose my words to argue with him?",
                "Though I were righteous, yet I wouldn\u2019t answer him. I would make supplication to my judge.",
                "If I had called, and he had answered me, yet I wouldn\u2019t believe that he listened to my voice.",
                "For he breaks me with a storm, and multiplies my wounds without cause.",
                "He will not allow me to catch my breath, but fills me with bitterness.",
                "If it is a matter of strength, behold, he is mighty! If of justice, \u2018Who,\u2019 says he, \u2018will summon me?\u2019",
                "Though I am righteous, my own mouth shall condemn me. Though I am blameless, it shall prove me perverse.",
                "I am blameless. I don\u2019t respect myself. I despise my life.",
                "\"It is all the same. Therefore I say he destroys the blameless and the wicked.",
                "If the scourge kills suddenly, he will mock at the trial of the innocent.",
                "The earth is given into the hand of the wicked. He covers the faces of its judges. If not he, then who is it?",
                "\"Now my days are swifter than a runner. They flee away, they see no good,",
                "They have passed away as the swift ships, as the eagle that swoops on the prey.",
                "If I say, \u2018I will forget my complaint, I will put off my sad face, and cheer up;\u2019",
                "I am afraid of all my sorrows, I know that you will not hold me innocent.",
                "I shall be condemned. Why then do I labor in vain?",
                "If I wash myself with snow, and cleanse my hands with lye,",
                "yet you will plunge me in the ditch. My own clothes shall abhor me.",
                "For he is not a man, as I am, that I should answer him, that we should come together in judgment.",
                "There is no umpire between us, that might lay his hand on us both.",
                "Let him take his rod away from me. Let his terror not make me afraid;",
                "then I would speak, and not fear him, for I am not so in myself."
            ],
            10: [
                "\"My soul is weary of my life. I will give free course to my complaint. I will speak in the bitterness of my soul.",
                "I will tell God, \u2018Do not condemn me. Show me why you contend with me.",
                "Is it good to you that you should oppress, that you should despise the work of your hands, and smile on the counsel of the wicked?",
                "Do you have eyes of flesh? Or do you see as man sees?",
                "Are your days as the days of mortals, or your years as man\u2019s years,",
                "that you inquire after my iniquity, and search after my sin?",
                "Although you know that I am not wicked, there is no one who can deliver out of your hand.",
                "\"\u2018Your hands have framed me and fashioned me altogether, yet you destroy me.",
                "Remember, I beg you, that you have fashioned me as clay. Will you bring me into dust again?",
                "Haven\u2019t you poured me out like milk, and curdled me like cheese?",
                "You have clothed me with skin and flesh, and knit me together with bones and sinews.",
                "You have granted me life and loving kindness. Your visitation has preserved my spirit.",
                "Yet you hid these things in your heart. I know that this is with you:",
                "if I sin, then you mark me. You will not acquit me from my iniquity.",
                "If I am wicked, woe to me. If I am righteous, I still shall not lift up my head, being filled with disgrace, and conscious of my affliction.",
                "If my head is held high, you hunt me like a lion. Again you show yourself powerful to me.",
                "You renew your witnesses against me, and increase your indignation on me. Changes and warfare are with me.",
                "\"\u2018Why, then, have you brought me forth out of the womb? I wish I had given up the spirit, and no eye had seen me.",
                "I should have been as though I had not been. I should have been carried from the womb to the grave.",
                "Aren\u2019t my days few? Cease then. Leave me alone, that I may find a little comfort,",
                "before I go where I shall not return from, to the land of darkness and of the shadow of death;",
                "the land dark as midnight, of the shadow of death, without any order, where the light is as midnight.\u2019\""
            ],
            11: [
                "Then Zophar, the Naamathite, answered,",
                "\"Shouldn\u2019t the multitude of words be answered? Should a man full of talk be justified?",
                "Should your boastings make men hold their peace? When you mock, shall no man make you ashamed?",
                "For you say, \u2018My doctrine is pure. I am clean in your eyes.\u2019",
                "But oh that God would speak, and open his lips against you,",
                "that he would show you the secrets of wisdom! For true wisdom has two sides. Know therefore that God exacts of you less than your iniquity deserves.",
                "\"Can you fathom the mystery of God? Or can you probe the limits of the Almighty?",
                "They are high as heaven. What can you do? They are deeper than Sheol. What can you know?",
                "Its measure is longer than the earth, and broader than the sea.",
                "If he passes by, or confines, or convenes a court, then who can oppose him?",
                "For he knows false men. He sees iniquity also, even though he doesn\u2019t consider it.",
                "An empty-headed man becomes wise when a man is born as a wild donkey\u2019s colt.",
                "\"If you set your heart aright, stretch out your hands toward him.",
                "If iniquity is in your hand, put it far away. Don\u2019t let unrighteousness dwell in your tents.",
                "Surely then you shall lift up your face without spot; Yes, you shall be steadfast, and shall not fear:",
                "for you shall forget your misery. You shall remember it as waters that are passed away.",
                "Life shall be clearer than the noonday. Though there is darkness, it shall be as the morning.",
                "You shall be secure, because there is hope. Yes, you shall search, and shall take your rest in safety.",
                "Also you shall lie down, and none shall make you afraid. Yes, many shall court your favor.",
                "But the eyes of the wicked shall fail. They shall have no way to flee. Their hope shall be the giving up of the spirit.\""
            ],
            12: [
                "Then Job answered,",
                "\"No doubt, but you are the people, and wisdom shall die with you.",
                "But I have understanding as well as you; I am not inferior to you. Yes, who doesn\u2019t know such things as these?",
                "I am like one who is a joke to his neighbor, I, who called on God, and he answered. The just, the blameless man is a joke.",
                "In the thought of him who is at ease there is contempt for misfortune. It is ready for them whose foot slips.",
                "The tents of robbers prosper. Those who provoke God are secure, who carry their God in their hands.",
                "\"But ask the animals, now, and they shall teach you; the birds of the sky, and they shall tell you.",
                "Or speak to the earth, and it shall teach you. The fish of the sea shall declare to you.",
                "Who doesn\u2019t know that in all these, the hand of Yahweh has done this,",
                "in whose hand is the life of every living thing, and the breath of all mankind?",
                "Doesn\u2019t the ear try words, even as the palate tastes its food?",
                "With aged men is wisdom, in length of days understanding.",
                "\"With God is wisdom and might. He has counsel and understanding.",
                "Behold, he breaks down, and it can\u2019t be built again. He imprisons a man, and there can be no release.",
                "Behold, he withholds the waters, and they dry up. Again, he sends them out, and they overturn the earth.",
                "With him is strength and wisdom. The deceived and the deceiver are his.",
                "He leads counselors away stripped. He makes judges fools.",
                "He loosens the bond of kings. He binds their waist with a belt.",
                "He leads priests away stripped, and overthrows the mighty.",
                "He removes the speech of those who are trusted, and takes away the understanding of the elders.",
                "He pours contempt on princes, and loosens the belt of the strong.",
                "He uncovers deep things out of darkness, and brings out to light the shadow of death.",
                "He increases the nations, and he destroys them. He enlarges the nations, and he leads them captive.",
                "He takes away understanding from the chiefs of the people of the earth, and causes them to wander in a wilderness where there is no way.",
                "They grope in the dark without light. He makes them stagger like a drunken man."
            ],
            13: [
                "\"Behold, my eye has seen all this. My ear has heard and understood it.",
                "What you know, I know also. I am not inferior to you.",
                "\"Surely I would speak to the Almighty. I desire to reason with God.",
                "But you are forgers of lies. You are all physicians of no value.",
                "Oh that you would be completely silent! Then you would be wise.",
                "Hear now my reasoning. Listen to the pleadings of my lips.",
                "Will you speak unrighteously for God, and talk deceitfully for him?",
                "Will you show partiality to him? Will you contend for God?",
                "Is it good that he should search you out? Or as one deceives a man, will you deceive him?",
                "He will surely reprove you if you secretly show partiality.",
                "Shall not his majesty make you afraid, And his dread fall on you?",
                "Your memorable sayings are proverbs of ashes, Your defenses are defenses of clay.",
                "\"Be silent, leave me alone, that I may speak. Let come on me what will.",
                "Why should I take my flesh in my teeth, and put my life in my hand?",
                "Behold, he will kill me. I have no hope. Nevertheless, I will maintain my ways before him.",
                "This also shall be my salvation, that a godless man shall not come before him.",
                "Hear diligently my speech. Let my declaration be in your ears.",
                "See now, I have set my cause in order. I know that I am righteous.",
                "Who is he who will contend with me? For then would I hold my peace and give up the spirit.",
                "\"Only don\u2019t do two things to me; then I will not hide myself from your face:",
                "withdraw your hand far from me; and don\u2019t let your terror make me afraid.",
                "Then call, and I will answer; or let me speak, and you answer me.",
                "How many are my iniquities and sins? Make me know my disobedience and my sin.",
                "Why hide you your face, and hold me for your enemy?",
                "Will you harass a driven leaf? Will you pursue the dry stubble?",
                "For you write bitter things against me, and make me inherit the iniquities of my youth:",
                "You also put my feet in the stocks, and mark all my paths. You set a bound to the soles of my feet,",
                "though I am decaying like a rotten thing, like a garment that is moth-eaten."
            ],
            14: [
                "\"Man, who is born of a woman, is of few days, and full of trouble.",
                "He comes forth like a flower, and is cut down. He also flees like a shadow, and doesn\u2019t continue.",
                "Do you open your eyes on such a one, and bring me into judgment with you?",
                "Who can bring a clean thing out of an unclean? Not one.",
                "Seeing his days are determined, the number of his months is with you, and you have appointed his bounds that he can\u2019t pass;",
                "Look away from him, that he may rest, until he shall accomplish, as a hireling, his day.",
                "\"For there is hope for a tree, If it is cut down, that it will sprout again, that the tender branch of it will not cease.",
                "Though its root grows old in the earth, and its stock dies in the ground,",
                "yet through the scent of water it will bud, and put forth boughs like a plant.",
                "But man dies, and is laid low. Yes, man gives up the spirit, and where is he?",
                "As the waters fail from the sea, and the river wastes and dries up,",
                "so man lies down and doesn\u2019t rise. Until the heavens are no more, they shall not awake, nor be roused out of their sleep.",
                "\"Oh that you would hide me in Sheol, that you would keep me secret, until your wrath is past, that you would appoint me a set time, and remember me!",
                "If a man dies, shall he live again? All the days of my warfare would I wait, until my release should come.",
                "You would call, and I would answer you. You would have a desire to the work of your hands.",
                "But now you number my steps. Don\u2019t you watch over my sin?",
                "My disobedience is sealed up in a bag. You fasten up my iniquity.",
                "\"But the mountain falling comes to nothing. The rock is removed out of its place;",
                "The waters wear the stones. The torrents of it wash away the dust of the earth. So you destroy the hope of man.",
                "You forever prevail against him, and he departs. You change his face, and send him away.",
                "His sons come to honor, and he doesn\u2019t know it. They are brought low, but he doesn\u2019t perceive it of them.",
                "But his flesh on him has pain, and his soul within him mourns.\""
            ],
            15: [
                "Then Eliphaz the Temanite answered,",
                "\"Should a wise man answer with vain knowledge, and fill himself with the east wind?",
                "Should he reason with unprofitable talk, or with speeches with which he can do no good?",
                "Yes, you do away with fear, and hinder devotion before God.",
                "For your iniquity teaches your mouth, and you choose the language of the crafty.",
                "Your own mouth condemns you, and not I. Yes, your own lips testify against you.",
                "\"Are you the first man who was born? Or were you brought forth before the hills?",
                "Have you heard the secret counsel of God? Do you limit wisdom to yourself?",
                "What do you know, that we don\u2019t know? What do you understand, which is not in us?",
                "With us are both the gray-headed and the very aged men, much elder than your father.",
                "Are the consolations of God too small for you, even the word that is gentle toward you?",
                "Why does your heart carry you away? Why do your eyes flash,",
                "That you turn your spirit against God, and let such words go out of your mouth?",
                "What is man, that he should be clean? What is he who is born of a woman, that he should be righteous?",
                "Behold, he puts no trust in his holy ones. Yes, the heavens are not clean in his sight;",
                "how much less one who is abominable and corrupt, a man who drinks iniquity like water!",
                "\"I will show you, listen to me; that which I have seen I will declare:",
                "(Which wise men have told by their fathers, and have not hidden it;",
                "to whom alone the land was given, and no stranger passed among them):",
                "the wicked man writhes in pain all his days, even the number of years that are laid up for the oppressor.",
                "A sound of terrors is in his ears. In prosperity the destroyer shall come on him.",
                "He doesn\u2019t believe that he shall return out of darkness. He is waited for by the sword.",
                "He wanders abroad for bread, saying, \u2018Where is it?\u2019 He knows that the day of darkness is ready at his hand.",
                "Distress and anguish make him afraid. They prevail against him, as a king ready to the battle.",
                "Because he has stretched out his hand against God, and behaves himself proudly against the Almighty;",
                "he runs at him with a stiff neck, with the thick shields of his bucklers;",
                "because he has covered his face with his fatness, and gathered fat on his thighs.",
                "He has lived in desolate cities, in houses which no one inhabited, which were ready to become heaps.",
                "He shall not be rich, neither shall his substance continue, neither shall their possessions be extended on the earth.",
                "He shall not depart out of darkness. The flame shall dry up his branches. By the breath of God\u2019s mouth shall he go away.",
                "Let him not trust in emptiness, deceiving himself; for emptiness shall be his reward.",
                "It shall be accomplished before his time. His branch shall not be green.",
                "He shall shake off his unripe grape as the vine, and shall cast off his flower as the olive tree.",
                "For the company of the godless shall be barren, and fire shall consume the tents of bribery.",
                "They conceive mischief, and bring forth iniquity. Their heart prepares deceit.\""
            ],
            16: [
                "Then Job answered,",
                "\"I have heard many such things. You are all miserable comforters!",
                "Shall vain words have an end? Or what provokes you that you answer?",
                "I also could speak as you do. If your soul were in my soul\u2019s place, I could join words together against you, and shake my head at you,",
                "but I would strengthen you with my mouth. The solace of my lips would relieve you.",
                "\"Though I speak, my grief is not subsided. Though I forbear, what am I eased?",
                "But now, God, you have surely worn me out. You have made desolate all my company.",
                "You have shriveled me up. This is a witness against me. My leanness rises up against me. It testifies to my face.",
                "He has torn me in his wrath, and persecuted me. He has gnashed on me with his teeth. My adversary sharpens his eyes on me.",
                "They have gaped on me with their mouth. They have struck me on the cheek reproachfully. They gather themselves together against me.",
                "God delivers me to the ungodly, and casts me into the hands of the wicked.",
                "I was at ease, and he broke me apart. Yes, he has taken me by the neck, and dashed me to pieces. He has also set me up for his target.",
                "His archers surround me. He splits my kidneys apart, and does not spare. He pours out my gall on the ground.",
                "He breaks me with breach on breach. He runs on me like a giant.",
                "I have sewed sackcloth on my skin, and have thrust my horn in the dust.",
                "My face is red with weeping. Deep darkness is on my eyelids.",
                "Although there is no violence in my hands, and my prayer is pure.",
                "\"Earth, don\u2019t cover my blood. Let my cry have no place to rest.",
                "Even now, behold, my witness is in heaven. He who vouches for me is on high.",
                "My friends scoff at me. My eyes pour out tears to God,",
                "that he would maintain the right of a man with God, of a son of man with his neighbor!",
                "For when a few years have come, I shall go the way of no return."
            ],
            17: [
                "\"My spirit is consumed. My days are extinct, And the grave is ready for me.",
                "Surely there are mockers with me. My eye dwells on their provocation.",
                "\"Now give a pledge, be collateral for me with yourself. Who is there who will strike hands with me?",
                "For you have hidden their heart from understanding, Therefore you shall not exalt them.",
                "He who denounces his friends for a prey, Even the eyes of his children shall fail.",
                "\"But he has made me a byword of the people. They spit in my face.",
                "My eye also is dim by reason of sorrow. All my members are as a shadow.",
                "Upright men shall be astonished at this. The innocent shall stir up himself against the godless.",
                "Yet shall the righteous hold on his way. He who has clean hands shall grow stronger and stronger.",
                "But as for you all, come on now again; I shall not find a wise man among you.",
                "My days are past, my plans are broken off, as are the thoughts of my heart.",
                "They change the night into day, saying \u2018The light is near\u2019 in the presence of darkness.",
                "If I look for Sheol as my house, if I have spread my couch in the darkness,",
                "If I have said to corruption, \u2018You are my father;\u2019 to the worm, \u2018My mother,\u2019 and \u2018my sister;\u2019",
                "where then is my hope? as for my hope, who shall see it?",
                "Shall it go down with me to the gates of Sheol, or descend together into the dust?\""
            ],
            18: [
                "Then Bildad the Shuhite answered,",
                "\"How long will you hunt for words? Consider, and afterwards we will speak.",
                "Why are we counted as animals, which have become unclean in your sight?",
                "You who tear yourself in your anger, shall the earth be forsaken for you? Or shall the rock be removed out of its place?",
                "\"Yes, the light of the wicked shall be put out, The spark of his fire shall not shine.",
                "The light shall be dark in his tent. His lamp above him shall be put out.",
                "The steps of his strength shall be shortened. His own counsel shall cast him down.",
                "For he is cast into a net by his own feet, and he wanders into its mesh.",
                "A snare will take him by the heel. A trap will catch him.",
                "A noose is hidden for him in the ground, a trap for him in the way.",
                "Terrors shall make him afraid on every side, and shall chase him at his heels.",
                "His strength shall be famished. Calamity shall be ready at his side.",
                "The members of his body shall be devoured. The firstborn of death shall devour his members.",
                "He shall be rooted out of his tent where he trusts. He shall be brought to the king of terrors.",
                "There shall dwell in his tent that which is none of his. Sulfur shall be scattered on his habitation.",
                "His roots shall be dried up beneath. Above shall his branch be cut off.",
                "His memory shall perish from the earth. He shall have no name in the street.",
                "He shall be driven from light into darkness, and chased out of the world.",
                "He shall have neither son nor grandson among his people, nor any remaining where he lived.",
                "Those who come after shall be astonished at his day, as those who went before were frightened.",
                "Surely such are the dwellings of the unrighteous. This is the place of him who doesn\u2019t know God.\""
            ],
            19: [
                "Then Job answered,",
                "\"How long will you torment me, and crush me with words?",
                "You have reproached me ten times. You aren\u2019t ashamed that you attack me.",
                "If it is true that I have erred, my error remains with myself.",
                "If indeed you will magnify yourselves against me, and plead against me my reproach;",
                "know now that God has subverted me, and has surrounded me with his net.",
                "\"Behold, I cry out of wrong, but I am not heard. I cry for help, but there is no justice.",
                "He has walled up my way so that I can\u2019t pass, and has set darkness in my paths.",
                "He has stripped me of my glory, and taken the crown from my head.",
                "He has broken me down on every side, and I am gone. My hope he has plucked up like a tree.",
                "He has also kindled his wrath against me. He counts me among his adversaries.",
                "His troops come on together, build a siege ramp against me, and encamp around my tent.",
                "\"He has put my brothers far from me. My acquaintances are wholly estranged from me.",
                "My relatives have gone away. My familiar friends have forgotten me.",
                "Those who dwell in my house, and my maids, count me for a stranger. I am an alien in their sight.",
                "I call to my servant, and he gives me no answer. I beg him with my mouth.",
                "My breath is offensive to my wife. I am loathsome to the children of my own mother.",
                "Even young children despise me. If I arise, they speak against me.",
                "All my familiar friends abhor me. They whom I loved have turned against me.",
                "My bones stick to my skin and to my flesh. I have escaped by the skin of my teeth.",
                "\"Have pity on me, have pity on me, you my friends; for the hand of God has touched me.",
                "Why do you persecute me as God, and are not satisfied with my flesh?",
                "\"Oh that my words were now written! Oh that they were inscribed in a book!",
                "That with an iron pen and lead they were engraved in the rock forever!",
                "But as for me, I know that my Redeemer lives. In the end, he will stand upon the earth.",
                "After my skin is destroyed, then in my flesh shall I see God,",
                "Whom I, even I, shall see on my side. My eyes shall see, and not as a stranger. \"My heart is consumed within me.",
                "If you say, \u2018How we will persecute him!\u2019 because the root of the matter is found in me,",
                "be afraid of the sword, for wrath brings the punishments of the sword, that you may know there is a judgment.\""
            ],
            20: [
                "Then Zophar the Naamathite answered,",
                "\"Therefore do my thoughts give answer to me, even by reason of my haste that is in me.",
                "I have heard the reproof which puts me to shame. The spirit of my understanding answers me.",
                "Don\u2019t you know this from old time, since man was placed on earth,",
                "that the triumphing of the wicked is short, the joy of the godless but for a moment?",
                "Though his height mount up to the heavens, and his head reach to the clouds,",
                "yet he shall perish forever like his own dung. Those who have seen him shall say, \u2018Where is he?\u2019",
                "He shall fly away as a dream, and shall not be found. Yes, he shall be chased away like a vision of the night.",
                "The eye which saw him shall see him no more, neither shall his place any more see him.",
                "His children shall seek the favor of the poor. His hands shall give back his wealth.",
                "His bones are full of his youth, but youth shall lie down with him in the dust.",
                "\"Though wickedness is sweet in his mouth, though he hide it under his tongue,",
                "though he spare it, and will not let it go, but keep it still within his mouth;",
                "yet his food in his bowels is turned. It is cobra venom within him.",
                "He has swallowed down riches, and he shall vomit them up again. God will cast them out of his belly.",
                "He shall suck cobra venom. The viper\u2019s tongue shall kill him.",
                "He shall not look at the rivers, the flowing streams of honey and butter.",
                "That for which he labored he shall restore, and shall not swallow it down. According to the substance that he has gotten, he shall not rejoice.",
                "For he has oppressed and forsaken the poor. He has violently taken away a house, and he shall not build it up.",
                "\"Because he knew no quietness within him, he shall not save anything of that in which he delights.",
                "There was nothing left that he didn\u2019t devour, therefore his prosperity shall not endure.",
                "In the fullness of his sufficiency, distress shall overtake him. The hand of everyone who is in misery shall come on him.",
                "When he is about to fill his belly, God will cast the fierceness of his wrath on him. It will rain on him while he is eating.",
                "He shall flee from the iron weapon. The bronze arrow shall strike him through.",
                "He draws it forth, and it comes out of his body. Yes, the glittering point comes out of his liver. Terrors are on him.",
                "All darkness is laid up for his treasures. An unfanned fire shall devour him. It shall consume that which is left in his tent.",
                "The heavens shall reveal his iniquity. The earth shall rise up against him.",
                "The increase of his house shall depart. They shall rush away in the day of his wrath.",
                "This is the portion of a wicked man from God, the heritage appointed to him by God.\""
            ],
            21: [
                "Then Job answered,",
                "\"Listen diligently to my speech. Let this be your consolation.",
                "Allow me, and I also will speak; After I have spoken, mock on.",
                "As for me, is my complaint to man? Why shouldn\u2019t I be impatient?",
                "Look at me, and be astonished. Lay your hand on your mouth.",
                "When I remember, I am troubled. Horror takes hold of my flesh.",
                "\"Why do the wicked live, become old, yes, and grow mighty in power?",
                "Their child is established with them in their sight, their offspring before their eyes.",
                "Their houses are safe from fear, neither is the rod of God upon them.",
                "Their bulls breed without fail. Their cows calve, and don\u2019t miscarry.",
                "They send forth their little ones like a flock. Their children dance.",
                "They sing to the tambourine and harp, and rejoice at the sound of the pipe.",
                "They spend their days in prosperity. In an instant they go down to Sheol.",
                "They tell God, \u2018Depart from us, for we don\u2019t want to know about your ways.",
                "What is the Almighty, that we should serve him? What profit should we have, if we pray to him?\u2019",
                "Behold, their prosperity is not in their hand. The counsel of the wicked is far from me.",
                "\"How often is it that the lamp of the wicked is put out, that their calamity comes on them, that God distributes sorrows in his anger?",
                "How often is it that they are as stubble before the wind, as chaff that the storm carries away?",
                "You say, \u2018God lays up his iniquity for his children.\u2019 Let him recompense it to himself, that he may know it.",
                "Let his own eyes see his destruction. Let him drink of the wrath of the Almighty.",
                "For what does he care for his house after him, when the number of his months is cut off?",
                "\"Shall any teach God knowledge, since he judges those who are high?",
                "One dies in his full strength, being wholly at ease and quiet.",
                "His pails are full of milk. The marrow of his bones is moistened.",
                "Another dies in bitterness of soul, and never tastes of good.",
                "They lie down alike in the dust. The worm covers them.",
                "\"Behold, I know your thoughts, the devices with which you would wrong me.",
                "For you say, \u2018Where is the house of the prince? Where is the tent in which the wicked lived?\u2019",
                "Haven\u2019t you asked wayfaring men? Don\u2019t you know their evidences,",
                "that the evil man is reserved to the day of calamity, That they are led forth to the day of wrath?",
                "Who shall declare his way to his face? Who shall repay him what he has done?",
                "Yet he will be borne to the grave. Men shall keep watch over the tomb.",
                "The clods of the valley shall be sweet to him. All men shall draw after him, as there were innumerable before him.",
                "So how can you comfort me with nonsense, because in your answers there remains only falsehood?\""
            ],
            22: [
                "Then Eliphaz the Temanite answered,",
                "\"Can a man be profitable to God? Surely he who is wise is profitable to himself.",
                "Is it any pleasure to the Almighty, that you are righteous? Or does it benefit him, that you make your ways perfect?",
                "Is it for your piety that he reproves you, that he enters with you into judgment?",
                "Isn\u2019t your wickedness great? Neither is there any end to your iniquities.",
                "For you have taken pledges from your brother for nothing, and stripped the naked of their clothing.",
                "You haven\u2019t given water to the weary to drink, and you have withheld bread from the hungry.",
                "But as for the mighty man, he had the earth. The honorable man, he lived in it.",
                "You have sent widows away empty, and the arms of the fatherless have been broken.",
                "Therefore snares are around you. Sudden fear troubles you,",
                "or darkness, so that you can not see, and floods of waters cover you.",
                "\"Isn\u2019t God in the heights of heaven? See the height of the stars, how high they are!",
                "You say, \u2018What does God know? Can he judge through the thick darkness?",
                "Thick clouds are a covering to him, so that he doesn\u2019t see. He walks on the vault of the sky.\u2019",
                "Will you keep the old way, which wicked men have trodden,",
                "who were snatched away before their time, whose foundation was poured out as a stream,",
                "who said to God, \u2018Depart from us;\u2019 and, \u2018What can the Almighty do for us?\u2019",
                "Yet he filled their houses with good things, but the counsel of the wicked is far from me.",
                "The righteous see it, and are glad. The innocent ridicule them,",
                "saying, \u2018Surely those who rose up against us are cut off. The fire has consumed their remnant.\u2019",
                "\"Acquaint yourself with him, now, and be at peace. Thereby good shall come to you.",
                "Please receive instruction from his mouth, and lay up his words in your heart.",
                "If you return to the Almighty, you shall be built up, if you put away unrighteousness far from your tents.",
                "Lay your treasure in the dust, the gold of Ophir among the stones of the brooks.",
                "The Almighty will be your treasure, and precious silver to you.",
                "For then you will delight yourself in the Almighty, and shall lift up your face to God.",
                "You shall make your prayer to him, and he will hear you. You shall pay your vows.",
                "You shall also decree a thing, and it shall be established to you. Light shall shine on your ways.",
                "When they cast down, you shall say, \u2018be lifted up.\u2019 He will save the humble person.",
                "He will even deliver him who is not innocent. Yes, he shall be delivered through the cleanness of your hands.\""
            ],
            23: [
                "Then Job answered,",
                "\"Even today my complaint is rebellious. His hand is heavy in spite of my groaning.",
                "Oh that I knew where I might find him! That I might come even to his seat!",
                "I would set my cause in order before him, and fill my mouth with arguments.",
                "I would know the words which he would answer me, and understand what he would tell me.",
                "Would he contend with me in the greatness of his power? No, but he would listen to me.",
                "There the upright might reason with him, so I should be delivered forever from my judge.",
                "\"If I go east, he is not there; if west, I can\u2019t find him;",
                "He works to the north, but I can\u2019t see him. He turns south, but I can\u2019t catch a glimpse of him.",
                "But he knows the way that I take. When he has tried me, I shall come forth like gold.",
                "My foot has held fast to his steps. I have kept his way, and not turned aside.",
                "I haven\u2019t gone back from the commandment of his lips. I have treasured up the words of his mouth more than my necessary food.",
                "But he stands alone, and who can oppose him? What his soul desires, even that he does.",
                "For he performs that which is appointed for me. Many such things are with him.",
                "Therefore I am terrified at his presence. When I consider, I am afraid of him.",
                "For God has made my heart faint. The Almighty has terrified me.",
                "Because I was not cut off before the darkness, neither did he cover the thick darkness from my face."
            ],
            24: [
                "\"Why aren\u2019t times laid up by the Almighty? Why don\u2019t those who know him see his days?",
                "There are people who remove the landmarks. They violently take away flocks, and feed them.",
                "They drive away the donkey of the fatherless, and they take the widow\u2019s ox for a pledge.",
                "They turn the needy out of the way. The poor of the earth all hide themselves.",
                "Behold, as wild donkeys in the desert, they go forth to their work, seeking diligently for food. The wilderness yields them bread for their children.",
                "They cut their provender in the field. They glean the vineyard of the wicked.",
                "They lie all night naked without clothing, and have no covering in the cold.",
                "They are wet with the showers of the mountains, and embrace the rock for lack of a shelter.",
                "There are those who pluck the fatherless from the breast, and take a pledge of the poor,",
                "So that they go around naked without clothing. Being hungry, they carry the sheaves.",
                "They make oil within the walls of these men. They tread wine presses, and suffer thirst.",
                "From out of the populous city, men groan. The soul of the wounded cries out, yet God doesn\u2019t regard the folly.",
                "\"These are of those who rebel against the light. They don\u2019t know its ways, nor stay in its paths.",
                "The murderer rises with the light. He kills the poor and needy. In the night he is like a thief.",
                "The eye also of the adulterer waits for the twilight, saying, \u2018No eye shall see me.\u2019 He disguises his face.",
                "In the dark they dig through houses. They shut themselves up in the daytime. They don\u2019t know the light.",
                "For the morning is to all of them like thick darkness, for they know the terrors of the thick darkness.",
                "\"They are foam on the surface of the waters. Their portion is cursed in the earth. They don\u2019t turn into the way of the vineyards.",
                "Drought and heat consume the snow waters, so does Sheol those who have sinned.",
                "The womb shall forget him. The worm shall feed sweetly on him. He shall be no more remembered. Unrighteousness shall be broken as a tree.",
                "He devours the barren who don\u2019t bear. He shows no kindness to the widow.",
                "Yet God preserves the mighty by his power. He rises up who has no assurance of life.",
                "God gives them security, and they rest in it. His eyes are on their ways.",
                "They are exalted; yet a little while, and they are gone. Yes, they are brought low, they are taken out of the way as all others, and are cut off as the tops of the ears of grain.",
                "If it isn\u2019t so now, who will prove me a liar, and make my speech worth nothing?\""
            ],
            25: [
                "Then Bildad the Shuhite answered,",
                "\"Dominion and fear are with him. He makes peace in his high places.",
                "Can his armies be counted? On whom does his light not arise?",
                "How then can man be just with God? Or how can he who is born of a woman be clean?",
                "Behold, even the moon has no brightness, and the stars are not pure in his sight;",
                "How much less man, who is a worm, the son of man, who is a worm!\""
            ],
            26: [
                "Then Job answered,",
                "\"How have you helped him who is without power! How have you saved the arm that has no strength!",
                "How have you counseled him who has no wisdom, and plentifully declared sound knowledge!",
                "To whom have you uttered words? Whose spirit came forth from you?",
                "\"Those who are deceased tremble, those beneath the waters and all that live in them.",
                "Sheol is naked before God, and Abaddon has no covering.",
                "He stretches out the north over empty space, and hangs the earth on nothing.",
                "He binds up the waters in his thick clouds, and the cloud is not burst under them.",
                "He encloses the face of his throne, and spreads his cloud on it.",
                "He has described a boundary on the surface of the waters, and to the confines of light and darkness.",
                "The pillars of heaven tremble and are astonished at his rebuke.",
                "He stirs up the sea with his power, and by his understanding he strikes through Rahab.",
                "By his Spirit the heavens are garnished. His hand has pierced the swift serpent.",
                "Behold, these are but the outskirts of his ways. How small a whisper do we hear of him! But the thunder of his power who can understand?\""
            ],
            27: [
                "Job again took up his parable, and said,",
                "\"As God lives, who has taken away my right, the Almighty, who has made my soul bitter.",
                "(For the length of my life is still in me, and the spirit of God is in my nostrils);",
                "surely my lips shall not speak unrighteousness, neither shall my tongue utter deceit.",
                "Far be it from me that I should justify you. Until I die I will not put away my integrity from me.",
                "I hold fast to my righteousness, and will not let it go. My heart shall not reproach me so long as I live.",
                "\"Let my enemy be as the wicked. Let him who rises up against me be as the unrighteous.",
                "For what is the hope of the godless, when he is cut off, when God takes away his life?",
                "Will God hear his cry when trouble comes on him?",
                "Will he delight himself in the Almighty, and call on God at all times?",
                "I will teach you about the hand of God. That which is with the Almighty will I not conceal.",
                "Behold, all of you have seen it yourselves; why then have you become altogether vain?",
                "\"This is the portion of a wicked man with God, the heritage of oppressors, which they receive from the Almighty.",
                "If his children are multiplied, it is for the sword. His offspring shall not be satisfied with bread.",
                "Those who remain of him shall be buried in death. His widows shall make no lamentation.",
                "Though he heap up silver as the dust, and prepare clothing as the clay;",
                "he may prepare it, but the just shall put it on, and the innocent shall divide the silver.",
                "He builds his house as the moth, as a booth which the watchman makes.",
                "He lies down rich, but he shall not do so again. He opens his eyes, and he is not.",
                "Terrors overtake him like waters. A storm steals him away in the night.",
                "The east wind carries him away, and he departs. It sweeps him out of his place.",
                "For it hurls at him, and does not spare, as he flees away from his hand.",
                "Men shall clap their hands at him, and shall hiss him out of his place."
            ],
            28: [
                "\"Surely there is a mine for silver, and a place for gold which they refine.",
                "Iron is taken out of the earth, and copper is smelted out of the ore.",
                "Man sets an end to darkness, and searches out, to the furthest bound, the stones of obscurity and of thick darkness.",
                "He breaks open a shaft away from where people live. They are forgotten by the foot. They hang far from men, they swing back and forth.",
                "As for the earth, out of it comes bread; Underneath it is turned up as it were by fire.",
                "Sapphires come from its rocks. It has dust of gold.",
                "That path no bird of prey knows, neither has the falcon\u2019s eye seen it.",
                "The proud animals have not trodden it, nor has the fierce lion passed by there.",
                "He puts forth his hand on the flinty rock, and he overturns the mountains by the roots.",
                "He cuts out channels among the rocks. His eye sees every precious thing.",
                "He binds the streams that they don\u2019t trickle. The thing that is hidden he brings forth to light.",
                "\"But where shall wisdom be found? Where is the place of understanding?",
                "Man doesn\u2019t know its price; Neither is it found in the land of the living.",
                "The deep says, \u2018It isn\u2019t in me.\u2019 The sea says, \u2018It isn\u2019t with me.\u2019",
                "It can\u2019t be gotten for gold, neither shall silver be weighed for its price.",
                "It can\u2019t be valued with the gold of Ophir, with the precious onyx, or the sapphire.",
                "Gold and glass can\u2019t equal it, neither shall it be exchanged for jewels of fine gold.",
                "No mention shall be made of coral or of crystal. Yes, the price of wisdom is above rubies.",
                "The topaz of Ethiopia shall not equal it, Neither shall it be valued with pure gold.",
                "Where then does wisdom come from? Where is the place of understanding?",
                "Seeing it is hidden from the eyes of all living, and kept close from the birds of the sky.",
                "Destruction and Death say, \u2018We have heard a rumor of it with our ears.\u2019",
                "\"God understands its way, and he knows its place.",
                "For he looks to the ends of the earth, and sees under the whole sky.",
                "He establishes the force of the wind. Yes, he measures out the waters by measure.",
                "When he made a decree for the rain, and a way for the lightning of the thunder;",
                "then he saw it, and declared it. He established it, yes, and searched it out.",
                "To man he said, \u2018Behold, the fear of the Lord, that is wisdom. To depart from evil is understanding.\u2019\""
            ],
            29: [
                "Job again took up his parable, and said,",
                "\"Oh that I were as in the months of old, as in the days when God watched over me;",
                "when his lamp shone on my head, and by his light I walked through darkness,",
                "as I was in the ripeness of my days, when the friendship of God was in my tent,",
                "when the Almighty was yet with me, and my children were around me,",
                "when my steps were washed with butter, and the rock poured out streams of oil for me,",
                "when I went forth to the city gate, when I prepared my seat in the street.",
                "The young men saw me and hid themselves. The aged rose up and stood.",
                "The princes refrained from talking, and laid their hand on their mouth.",
                "The voice of the nobles was hushed, and their tongue stuck to the roof of their mouth.",
                "For when the ear heard me, then it blessed me; and when the eye saw me, it commended me:",
                "Because I delivered the poor who cried, and the fatherless also, who had none to help him,",
                "the blessing of him who was ready to perish came on me, and I caused the widow\u2019s heart to sing for joy.",
                "I put on righteousness, and it clothed me. My justice was as a robe and a diadem.",
                "I was eyes to the blind, and feet to the lame.",
                "I was a father to the needy. The cause of him who I didn\u2019t know, I searched out.",
                "I broke the jaws of the unrighteous, and plucked the prey out of his teeth.",
                "Then I said, \u2018I shall die in my own house, I shall number my days as the sand.",
                "My root is spread out to the waters. The dew lies all night on my branch.",
                "My glory is fresh in me. My bow is renewed in my hand.\u2019",
                "\"Men listened to me, waited, and kept silence for my counsel.",
                "After my words they didn\u2019t speak again. My speech fell on them.",
                "They waited for me as for the rain. Their mouths drank as with the spring rain.",
                "I smiled on them when they had no confidence. They didn\u2019t reject the light of my face.",
                "I chose out their way, and sat as chief. I lived as a king in the army, as one who comforts the mourners."
            ],
            30: [
                "\"But now those who are younger than I have me in derision, whose fathers I would have disdained to put with my sheep dogs.",
                "Of what use is the strength of their hands to me, men in whom ripe age has perished?",
                "They are gaunt from lack and famine. They gnaw the dry ground, in the gloom of waste and desolation.",
                "They pluck salt herbs by the bushes. The roots of the broom are their food.",
                "They are driven out from the midst of men. They cry after them as after a thief;",
                "So that they dwell in frightful valleys, and in holes of the earth and of the rocks.",
                "Among the bushes they bray; and under the nettles they are gathered together.",
                "They are children of fools, yes, children of base men. They were flogged out of the land.",
                "\"Now I have become their song. Yes, I am a byword to them.",
                "They abhor me, they stand aloof from me, and don\u2019t hesitate to spit in my face.",
                "For he has untied his cord, and afflicted me; and they have thrown off restraint before me.",
                "On my right hand rise the rabble. They thrust aside my feet, They cast up against me their ways of destruction.",
                "They mar my path, They set forward my calamity, without anyone\u2019s help.",
                "As through a wide breach they come, in the midst of the ruin they roll themselves in.",
                "Terrors have turned on me. They chase my honor as the wind. My welfare has passed away as a cloud.",
                "\"Now my soul is poured out within me. Days of affliction have taken hold on me.",
                "In the night season my bones are pierced in me, and the pains that gnaw me take no rest.",
                "By great force is my garment disfigured. It binds me about as the collar of my coat.",
                "He has cast me into the mire. I have become like dust and ashes.",
                "I cry to you, and you do not answer me. I stand up, and you gaze at me.",
                "You have turned to be cruel to me. With the might of your hand you persecute me.",
                "You lift me up to the wind, and drive me with it. You dissolve me in the storm.",
                "For I know that you will bring me to death, To the house appointed for all living.",
                "\"However doesn\u2019t one stretch out a hand in his fall? Or in his calamity therefore cry for help?",
                "Didn\u2019t I weep for him who was in trouble? Wasn\u2019t my soul grieved for the needy?",
                "When I looked for good, then evil came; When I waited for light, there came darkness.",
                "My heart is troubled, and doesn\u2019t rest. Days of affliction have come on me.",
                "I go mourning without the sun. I stand up in the assembly, and cry for help.",
                "I am a brother to jackals, and a companion to ostriches.",
                "My skin grows black and peels from me. My bones are burned with heat.",
                "Therefore my harp has turned to mourning, and my pipe into the voice of those who weep."
            ],
            31: [
                "\"I made a covenant with my eyes, how then should I look lustfully at a young woman?",
                "For what is the portion from God above, and the heritage from the Almighty on high?",
                "Is it not calamity to the unrighteous, and disaster to the workers of iniquity?",
                "Doesn\u2019t he see my ways, and number all my steps?",
                "\"If I have walked with falsehood, and my foot has hurried to deceit",
                "(let me be weighed in an even balance, that God may know my integrity);",
                "if my step has turned out of the way, if my heart walked after my eyes, if any defilement has stuck to my hands,",
                "then let me sow, and let another eat. Yes, let the produce of my field be rooted out.",
                "\"If my heart has been enticed to a woman, and I have laid wait at my neighbor\u2019s door,",
                "then let my wife grind for another, and let others sleep with her.",
                "For that would be a heinous crime. Yes, it would be an iniquity to be punished by the judges:",
                "For it is a fire that consumes to destruction, and would root out all my increase.",
                "\"If I have despised the cause of my male servant or of my female servant, when they contended with me;",
                "What then shall I do when God rises up? When he visits, what shall I answer him?",
                "Didn\u2019t he who made me in the womb make him? Didn\u2019t one fashion us in the womb?",
                "\"If I have withheld the poor from their desire, or have caused the eyes of the widow to fail,",
                "or have eaten my morsel alone, and the fatherless has not eaten of it",
                "(no, from my youth he grew up with me as with a father, her have I guided from my mother\u2019s womb);",
                "if I have seen any perish for want of clothing, or that the needy had no covering;",
                "if his heart hasn\u2019t blessed me, if he hasn\u2019t been warmed with my sheep\u2019s fleece;",
                "if I have lifted up my hand against the fatherless, because I saw my help in the gate,",
                "then let my shoulder fall from the shoulder blade, and my arm be broken from the bone.",
                "For calamity from God is a terror to me. Because his majesty, I can do nothing.",
                "\"If I have made gold my hope, and have said to the fine gold, \u2018You are my confidence;\u2019",
                "If I have rejoiced because my wealth was great, and because my hand had gotten much;",
                "if I have seen the sun when it shined, or the moon moving in splendor,",
                "and my heart has been secretly enticed, and my hand threw a kiss from my mouth,",
                "this also would be an iniquity to be punished by the judges; for I should have denied the God who is above.",
                "\"If I have rejoiced at the destruction of him who hated me, or lifted up myself when evil found him;",
                "(yes, I have not allowed my mouth to sin by asking his life with a curse);",
                "if the men of my tent have not said, \u2018Who can find one who has not been filled with his meat?\u2019",
                "(the foreigner has not lodged in the street, but I have opened my doors to the traveler);",
                "if like Adam I have covered my transgressions, by hiding my iniquity in my heart,",
                "because I feared the great multitude, and the contempt of families terrified me, so that I kept silence, and didn\u2019t go out of the door\u2014",
                "oh that I had one to hear me! (behold, here is my signature, let the Almighty answer me); let the accuser write my indictment!",
                "Surely I would carry it on my shoulder; and I would bind it to me as a crown.",
                "I would declare to him the number of my steps. as a prince would I go near to him.",
                "If my land cries out against me, and its furrows weep together;",
                "if I have eaten its fruits without money, or have caused its owners to lose their life,",
                "let briars grow instead of wheat, and stinkweed instead of barley.\" The words of Job are ended."
            ],
            32: [
                "So these three men ceased to answer Job, because he was righteous in his own eyes.",
                "Then the wrath of Elihu the son of Barachel, the Buzite, of the family of Ram, was kindled against Job. His wrath was kindled because he justified himself rather than God.",
                "Also his wrath was kindled against his three friends, because they had found no answer, and yet had condemned Job.",
                "Now Elihu had waited to speak to Job, because they were elder than he.",
                "When Elihu saw that there was no answer in the mouth of these three men, his wrath was kindled.",
                "Elihu the son of Barachel the Buzite answered, \"I am young, and you are very old; Therefore I held back, and didn\u2019t dare show you my opinion.",
                "I said, \u2018Days should speak, and multitude of years should teach wisdom.\u2019",
                "But there is a spirit in man, and the breath of the Almighty gives them understanding.",
                "It is not the great who are wise, nor the aged who understand justice.",
                "Therefore I said, \u2018Listen to me; I also will show my opinion.\u2019",
                "\"Behold, I waited for your words, and I listened for your reasoning, while you searched out what to say.",
                "Yes, I gave you my full attention, but there was no one who convinced Job, or who answered his words, among you.",
                "Beware lest you say, \u2018We have found wisdom, God may refute him, not man;\u2019",
                "for he has not directed his words against me; neither will I answer him with your speeches.",
                "\"They are amazed. They answer no more. They don\u2019t have a word to say.",
                "Shall I wait, because they don\u2019t speak, because they stand still, and answer no more?",
                "I also will answer my part, and I also will show my opinion.",
                "For I am full of words. The spirit within me constrains me.",
                "Behold, my breast is as wine which has no vent; like new wineskins it is ready to burst.",
                "I will speak, that I may be refreshed. I will open my lips and answer.",
                "Please don\u2019t let me respect any man\u2019s person, neither will I give flattering titles to any man.",
                "For I don\u2019t know how to give flattering titles; or else my Maker would soon take me away."
            ],
            33: [
                "\"However, Job, please hear my speech, and listen to all my words.",
                "See now, I have opened my mouth. My tongue has spoken in my mouth.",
                "My words shall utter the uprightness of my heart. That which my lips know they shall speak sincerely.",
                "The Spirit of God has made me, and the breath of the Almighty gives me life.",
                "If you can, answer me. Set your words in order before me, and stand forth.",
                "Behold, I am toward God even as you are. I am also formed out of the clay.",
                "Behold, my terror shall not make you afraid, neither shall my pressure be heavy on you.",
                "\"Surely you have spoken in my hearing, I have heard the voice of your words, saying,",
                "\u2018I am clean, without disobedience. I am innocent, neither is there iniquity in me.",
                "Behold, he finds occasions against me. He counts me for his enemy.",
                "He puts my feet in the stocks. He marks all my paths.\u2019",
                "\"Behold, I will answer you. In this you are not just, for God is greater than man.",
                "Why do you strive against him, because he doesn\u2019t give account of any of his matters?",
                "For God speaks once, yes twice, though man pays no attention.",
                "In a dream, in a vision of the night, when deep sleep falls on men, in slumbering on the bed;",
                "Then he opens the ears of men, and seals their instruction,",
                "That he may withdraw man from his purpose, and hide pride from man.",
                "He keeps back his soul from the pit, and his life from perishing by the sword.",
                "He is chastened also with pain on his bed, with continual strife in his bones;",
                "So that his life abhors bread, and his soul dainty food.",
                "His flesh is so consumed away, that it can\u2019t be seen. His bones that were not seen stick out.",
                "Yes, his soul draws near to the pit, and his life to the destroyers.",
                "\"If there is beside him an angel, an interpreter, one among a thousand, to show to man what is right for him;",
                "then God is gracious to him, and says, \u2018Deliver him from going down to the pit, I have found a ransom.\u2019",
                "His flesh shall be fresher than a child\u2019s. He returns to the days of his youth.",
                "He prays to God, and he is favorable to him, so that he sees his face with joy. He restores to man his righteousness.",
                "He sings before men, and says, \u2018I have sinned, and perverted that which was right, and it didn\u2019t profit me.",
                "He has redeemed my soul from going into the pit. My life shall see the light.\u2019",
                "\"Behold, God works all these things, twice, yes three times, with a man,",
                "to bring back his soul from the pit, that he may be enlightened with the light of the living.",
                "Mark well, Job, and listen to me. Hold your peace, and I will speak.",
                "If you have anything to say, answer me. Speak, for I desire to justify you.",
                "If not, listen to me. Hold your peace, and I will teach you wisdom.\""
            ],
            34: [
                "Moreover Elihu answered,",
                "\"Hear my words, you wise men. Give ear to me, you who have knowledge.",
                "For the ear tries words, as the palate tastes food.",
                "Let us choose for us that which is right. Let us know among ourselves what is good.",
                "For Job has said, \u2018I am righteous, God has taken away my right:",
                "Notwithstanding my right I am considered a liar. My wound is incurable, though I am without disobedience.\u2019",
                "What man is like Job, who drinks scorn like water,",
                "Who goes in company with the workers of iniquity, and walks with wicked men?",
                "For he has said, \u2018It profits a man nothing that he should delight himself with God.\u2019",
                "\"Therefore listen to me, you men of understanding: far be it from God, that he should do wickedness, from the Almighty, that he should commit iniquity.",
                "For the work of a man he will render to him, and cause every man to find according to his ways.",
                "Yes surely, God will not do wickedly, neither will the Almighty pervert justice.",
                "Who put him in charge of the earth? or who has appointed him over the whole world?",
                "If he set his heart on himself, If he gathered to himself his spirit and his breath,",
                "all flesh would perish together, and man would turn again to dust.",
                "\"If now you have understanding, hear this. Listen to the voice of my words.",
                "Shall even one who hates justice govern? Will you condemn him who is righteous and mighty?\u2014",
                "Who says to a king, \u2018Vile!\u2019 or to nobles, \u2018Wicked!\u2019?",
                "Who doesn\u2019t respect the persons of princes, nor respects the rich more than the poor; for they all are the work of his hands.",
                "In a moment they die, even at midnight. The people are shaken and pass away. The mighty are taken away without a hand.",
                "\"For his eyes are on the ways of a man. He sees all his goings.",
                "There is no darkness, nor thick gloom, where the workers of iniquity may hide themselves.",
                "For he doesn\u2019t need to consider a man further, that he should go before God in judgment.",
                "He breaks in pieces mighty men in ways past finding out, and sets others in their place.",
                "Therefore he takes knowledge of their works. He overturns them in the night, so that they are destroyed.",
                "He strikes them as wicked men in the open sight of others;",
                "because they turned aside from following him, and wouldn\u2019t pay attention to any of his ways,",
                "so that they caused the cry of the poor to come to him. He heard the cry of the afflicted.",
                "When he gives quietness, who then can condemn? When he hides his face, who then can see him? Alike whether to a nation, or to a man,",
                "that the godless man may not reign, that there be no one to ensnare the people.",
                "\"For has any said to God, \u2018I am guilty, but I will not offend any more.",
                "Teach me that which I don\u2019t see. If I have done iniquity, I will do it no more\u2019?",
                "Shall his recompense be as you desire, that you refuse it? For you must choose, and not I. Therefore speak what you know.",
                "Men of understanding will tell me, yes, every wise man who hears me:",
                "\u2018Job speaks without knowledge. His words are without wisdom.\u2019",
                "I wish that Job were tried to the end, because of his answering like wicked men.",
                "For he adds rebellion to his sin. He claps his hands among us, and multiplies his words against God.\""
            ],
            35: [
                "Moreover Elihu answered,",
                "\"Do you think this to be your right, or do you say, \u2018My righteousness is more than God\u2019s,\u2019",
                "That you ask, \u2018What advantage will it be to you? What profit shall I have, more than if I had sinned?\u2019",
                "I will answer you, and your companions with you.",
                "Look to the heavens, and see. See the skies, which are higher than you.",
                "If you have sinned, what effect do you have against him? If your transgressions are multiplied, what do you do to him?",
                "If you are righteous, what do you give him? Or what does he receive from your hand?",
                "Your wickedness may hurt a man as you are, and your righteousness may profit a son of man.",
                "\"By reason of the multitude of oppressions they cry out. They cry for help by reason of the arm of the mighty.",
                "But none says, \u2018Where is God my Maker, who gives songs in the night,",
                "who teaches us more than the animals of the earth, and makes us wiser than the birds of the sky?\u2019",
                "There they cry, but none gives answer, because of the pride of evil men.",
                "Surely God will not hear an empty cry, neither will the Almighty regard it.",
                "How much less when you say you don\u2019t see him. The cause is before him, and you wait for him!",
                "But now, because he has not visited in his anger, neither does he greatly regard arrogance.",
                "Therefore Job opens his mouth with empty talk, and he multiplies words without knowledge.\""
            ],
            36: [
                "Elihu also continued, and said,",
                "\"Bear with me a little, and I will show you; for I still have something to say on God\u2019s behalf.",
                "I will get my knowledge from afar, and will ascribe righteousness to my Maker.",
                "For truly my words are not false. One who is perfect in knowledge is with you.",
                "\"Behold, God is mighty, and doesn\u2019t despise anyone. He is mighty in strength of understanding.",
                "He doesn\u2019t preserve the life of the wicked, but gives to the afflicted their right.",
                "He doesn\u2019t withdraw his eyes from the righteous, but with kings on the throne, he sets them forever, and they are exalted.",
                "If they are bound in fetters, and are taken in the cords of afflictions,",
                "then he shows them their work, and their transgressions, that they have behaved themselves proudly.",
                "He also opens their ears to instruction, and commands that they return from iniquity.",
                "If they listen and serve him, they shall spend their days in prosperity, and their years in pleasures.",
                "But if they don\u2019t listen, they shall perish by the sword; they shall die without knowledge.",
                "\"But those who are godless in heart lay up anger. They don\u2019t cry for help when he binds them.",
                "They die in youth. Their life perishes among the unclean.",
                "He delivers the afflicted by their affliction, and opens their ear in oppression.",
                "Yes, he would have allured you out of distress, into a broad place, where there is no restriction. That which is set on your table would be full of fatness.",
                "\"But you are full of the judgment of the wicked. Judgment and justice take hold of you.",
                "Don\u2019t let riches entice you to wrath, neither let the great size of a bribe turn you aside.",
                "Would your wealth sustain you in distress, or all the might of your strength?",
                "Don\u2019t desire the night, when people are cut off in their place.",
                "Take heed, don\u2019t regard iniquity; for you have chosen this rather than affliction.",
                "Behold, God is exalted in his power. Who is a teacher like him?",
                "Who has prescribed his way for him? Or who can say, \u2018You have committed unrighteousness?\u2019",
                "\"Remember that you magnify his work, whereof men have sung.",
                "All men have looked thereon. Man sees it afar off.",
                "Behold, God is great, and we don\u2019t know him. The number of his years is unsearchable.",
                "For he draws up the drops of water, which distill in rain from his vapor,",
                "Which the skies pour down and which drop on man abundantly.",
                "Yes, can any understand the spreading of the clouds, and the thunderings of his pavilion?",
                "Behold, he spreads his light around him. He covers the bottom of the sea.",
                "For by these he judges the people. He gives food in abundance.",
                "He covers his hands with the lightning, and commands it to strike the mark.",
                "Its noise tells about him, and the livestock also concerning the storm that comes up."
            ],
            37: [
                "\"Yes, at this my heart trembles, and is moved out of its place.",
                "Hear, oh, hear the noise of his voice, the sound that goes out of his mouth.",
                "He sends it forth under the whole sky, and his lightning to the ends of the earth.",
                "After it a voice roars. He thunders with the voice of his majesty. He doesn\u2019t hold back anything when his voice is heard.",
                "God thunders marvelously with his voice. He does great things, which we can\u2019t comprehend.",
                "For he says to the snow, \u2018Fall on the earth;\u2019 likewise to the shower of rain, and to the showers of his mighty rain.",
                "He seals up the hand of every man, that all men whom he has made may know it.",
                "Then the animals take cover, and remain in their dens.",
                "Out of its room comes the storm, and cold out of the north.",
                "By the breath of God, ice is given, and the breadth of the waters is frozen.",
                "Yes, he loads the thick cloud with moisture. He spreads abroad the cloud of his lightning.",
                "It is turned around by his guidance, that they may do whatever he commands them on the surface of the habitable world,",
                "Whether it is for correction, or for his land, or for loving kindness, that he causes it to come.",
                "\"Listen to this, Job. Stand still, and consider the wondrous works of God.",
                "Do you know how God controls them, and causes the lightning of his cloud to shine?",
                "Do you know the workings of the clouds, the wondrous works of him who is perfect in knowledge?",
                "You whose clothing is warm, when the earth is still by reason of the south wind?",
                "Can you, with him, spread out the sky, which is strong as a cast metal mirror?",
                "Teach us what we shall tell him, for we can\u2019t make our case by reason of darkness.",
                "Shall it be told him that I would speak? Or should a man wish that he were swallowed up?",
                "Now men don\u2019t see the light which is bright in the skies, but the wind passes, and clears them.",
                "Out of the north comes golden splendor. With God is awesome majesty.",
                "We can\u2019t reach the Almighty. He is exalted in power. In justice and great righteousness, he will not oppress.",
                "Therefore men revere him. He doesn\u2019t regard any who are wise of heart.\""
            ],
            38: [
                "Then Yahweh answered Job out of the whirlwind,",
                "\"Who is this who darkens counsel by words without knowledge?",
                "Brace yourself like a man, for I will question you, then you answer me!",
                "\"Where were you when I laid the foundations of the earth? Declare, if you have understanding.",
                "Who determined its measures, if you know? Or who stretched the line on it?",
                "Whereupon were its foundations fastened? Or who laid its cornerstone,",
                "when the morning stars sang together, and all the sons of God shouted for joy?",
                "\"Or who shut up the sea with doors, when it broke forth from the womb,",
                "when I made clouds its garment, and wrapped it in thick darkness,",
                "marked out for it my bound, set bars and doors,",
                "and said, \u2018Here you may come, but no further. Here your proud waves shall be stayed?\u2019",
                "\"Have you commanded the morning in your days, and caused the dawn to know its place;",
                "that it might take hold of the ends of the earth, and shake the wicked out of it?",
                "It is changed as clay under the seal, and stands forth as a garment.",
                "From the wicked, their light is withheld. The high arm is broken.",
                "\"Have you entered into the springs of the sea? Or have you walked in the recesses of the deep?",
                "Have the gates of death been revealed to you? Or have you seen the gates of the shadow of death?",
                "Have you comprehended the earth in its breadth? Declare, if you know it all.",
                "\"What is the way to the dwelling of light? As for darkness, where is its place,",
                "that you should take it to its bound, that you should discern the paths to its house?",
                "Surely you know, for you were born then, and the number of your days is great!",
                "Have you entered the treasuries of the snow, or have you seen the treasures of the hail,",
                "which I have reserved against the time of trouble, against the day of battle and war?",
                "By what way is the lightning distributed, or the east wind scattered on the earth?",
                "Who has cut a channel for the flood water, or the path for the thunderstorm;",
                "To cause it to rain on a land where no man is; on the wilderness, in which there is no man;",
                "to satisfy the waste and desolate ground, to cause the tender grass to spring forth?",
                "Does the rain have a father? Or who fathers the drops of dew?",
                "Out of whose womb came the ice? The gray frost of the sky, who has given birth to it?",
                "The waters become hard like stone, when the surface of the deep is frozen.",
                "\"Can you bind the cluster of the Pleiades, or loosen the cords of Orion?",
                "Can you lead forth the constellations in their season? Or can you guide the Bear with her cubs?",
                "Do you know the laws of the heavens? Can you establish its dominion over the earth?",
                "\"Can you lift up your voice to the clouds, That abundance of waters may cover you?",
                "Can you send forth lightnings, that they may go? Do they report to you, \u2018Here we are?\u2019",
                "Who has put wisdom in the inward parts? Or who has given understanding to the mind?",
                "Who can number the clouds by wisdom? Or who can pour out the bottles of the sky,",
                "when the dust runs into a mass, and the clods of earth stick together?",
                "\"Can you hunt the prey for the lioness, or satisfy the appetite of the young lions,",
                "when they crouch in their dens, and lie in wait in the thicket?",
                "Who provides for the raven his prey, when his young ones cry to God, and wander for lack of food?"
            ],
            39: [
                "\"Do you know the time when the mountain goats give birth? Do you watch when the doe bears fawns?",
                "Can you number the months that they fulfill? Or do you know the time when they give birth?",
                "They bow themselves, they bring forth their young, they end their labor pains.",
                "Their young ones become strong. They grow up in the open field. They go forth, and don\u2019t return again.",
                "\"Who has set the wild donkey free? Or who has loosened the bonds of the swift donkey,",
                "Whose home I have made the wilderness, and the salt land his dwelling place?",
                "He scorns the tumult of the city, neither does he hear the shouting of the driver.",
                "The range of the mountains is his pasture, He searches after every green thing.",
                "\"Will the wild ox be content to serve you? Or will he stay by your feeding trough?",
                "Can you hold the wild ox in the furrow with his harness? Or will he till the valleys after you?",
                "Will you trust him, because his strength is great? Or will you leave to him your labor?",
                "Will you confide in him, that he will bring home your seed, and gather the grain of your threshing floor?",
                "\"The wings of the ostrich wave proudly; but are they the feathers and plumage of love?",
                "For she leaves her eggs on the earth, warms them in the dust,",
                "and forgets that the foot may crush them, or that the wild animal may trample them.",
                "She deals harshly with her young ones, as if they were not hers. Though her labor is in vain, she is without fear,",
                "because God has deprived her of wisdom, neither has he imparted to her understanding.",
                "When she lifts up herself on high, she scorns the horse and his rider.",
                "\"Have you given the horse might? Have you clothed his neck with a quivering mane?",
                "Have you made him to leap as a locust? The glory of his snorting is awesome.",
                "He paws in the valley, and rejoices in his strength. He goes out to meet the armed men.",
                "He mocks at fear, and is not dismayed, neither does he turn back from the sword.",
                "The quiver rattles against him, the flashing spear and the javelin.",
                "He eats up the ground with fierceness and rage, neither does he stand still at the sound of the trumpet.",
                "As often as the trumpet sounds he snorts, \u2018Aha!\u2019 He smells the battle afar off, the thunder of the captains, and the shouting.",
                "\"Is it by your wisdom that the hawk soars, and stretches her wings toward the south?",
                "Is it at your command that the eagle mounts up, and makes his nest on high?",
                "On the cliff he dwells, and makes his home, on the point of the cliff, and the stronghold.",
                "From there he spies out the prey. His eyes see it afar off.",
                "His young ones also suck up blood. Where the slain are, there he is.\""
            ],
            40: [
                "Moreover Yahweh answered Job,",
                "\"Shall he who argues contend with the Almighty? He who argues with God, let him answer it.\"",
                "Then Job answered Yahweh,",
                "\"Behold, I am of small account. What shall I answer you? I lay my hand on my mouth.",
                "I have spoken once, and I will not answer; Yes, twice, but I will proceed no further.\"",
                "Then Yahweh answered Job out of the whirlwind,",
                "\"Now brace yourself like a man. I will question you, and you will answer me.",
                "Will you even annul my judgment? Will you condemn me, that you may be justified?",
                "Or do you have an arm like God? Can you thunder with a voice like him?",
                "\"Now deck yourself with excellency and dignity. Array yourself with honor and majesty.",
                "Pour out the fury of your anger. Look at everyone who is proud, and bring him low.",
                "Look at everyone who is proud, and humble him. Crush the wicked in their place.",
                "Hide them in the dust together. Bind their faces in the hidden place.",
                "Then I will also admit to you that your own right hand can save you.",
                "\"See now, behemoth, which I made as well as you. He eats grass as an ox.",
                "Look now, his strength is in his thighs. His force is in the muscles of his belly.",
                "He moves his tail like a cedar. The sinews of his thighs are knit together.",
                "His bones are like tubes of brass. His limbs are like bars of iron.",
                "He is the chief of the ways of God. He who made him gives him his sword.",
                "Surely the mountains produce food for him, where all the animals of the field play.",
                "He lies under the lotus trees, in the covert of the reed, and the marsh.",
                "The lotuses cover him with their shade. The willows of the brook surround him.",
                "Behold, if a river overflows, he doesn\u2019t tremble. He is confident, though the Jordan swells even to his mouth.",
                "Shall any take him when he is on the watch, or pierce through his nose with a snare?"
            ],
            41: [
                "\"Can you draw out Leviathan with a fishhook, or press down his tongue with a cord?",
                "Can you put a rope into his nose, or pierce his jaw through with a hook?",
                "Will he make many petitions to you, or will he speak soft words to you?",
                "Will he make a covenant with you, that you should take him for a servant forever?",
                "Will you play with him as with a bird? Or will you bind him for your girls?",
                "Will traders barter for him? Will they part him among the merchants?",
                "Can you fill his skin with barbed irons, or his head with fish spears?",
                "Lay your hand on him. Remember the battle, and do so no more.",
                "Behold, the hope of him is in vain. Won\u2019t one be cast down even at the sight of him?",
                "None is so fierce that he dare stir him up. Who then is he who can stand before me?",
                "Who has first given to me, that I should repay him? Everything under the heavens is mine.",
                "\"I will not keep silence concerning his limbs, nor his mighty strength, nor his goodly frame.",
                "Who can strip off his outer garment? Who shall come within his jaws?",
                "Who can open the doors of his face? Around his teeth is terror.",
                "Strong scales are his pride, shut up together with a close seal.",
                "One is so near to another, that no air can come between them.",
                "They are joined one to another. They stick together, so that they can\u2019t be pulled apart.",
                "His sneezing flashes out light. His eyes are like the eyelids of the morning.",
                "Out of his mouth go burning torches. Sparks of fire leap forth.",
                "Out of his nostrils a smoke goes, as of a boiling pot over a fire of reeds.",
                "His breath kindles coals. A flame goes forth from his mouth.",
                "There is strength in his neck. Terror dances before him.",
                "The flakes of his flesh are joined together. They are firm on him. They can\u2019t be moved.",
                "His heart is as firm as a stone, yes, firm as the lower millstone.",
                "When he raises himself up, the mighty are afraid. They retreat before his thrashing.",
                "If one attacks him with the sword, it can\u2019t prevail; nor the spear, the dart, nor the pointed shaft.",
                "He counts iron as straw; and brass as rotten wood.",
                "The arrow can\u2019t make him flee. Sling stones are like chaff to him.",
                "Clubs are counted as stubble. He laughs at the rushing of the javelin.",
                "His undersides are like sharp potsherds, leaving a trail in the mud like a threshing sledge.",
                "He makes the deep to boil like a pot. He makes the sea like a pot of ointment.",
                "He makes a path shine after him. One would think the deep had white hair.",
                "On earth there is not his equal, that is made without fear.",
                "He sees everything that is high. He is king over all the sons of pride.\""
            ],
            42: [
                "Then Job answered Yahweh,",
                "\"I know that you can do all things, and that no purpose of yours can be restrained.",
                "You asked, \u2018Who is this who hides counsel without knowledge?\u2019 therefore I have uttered that which I did not understand, things too wonderful for me, which I didn\u2019t know.",
                "You said, \u2018Listen, now, and I will speak; I will question you, and you will answer me.\u2019",
                "I had heard of you by the hearing of the ear, but now my eye sees you.",
                "Therefore I abhor myself, and repent in dust and ashes.\"",
                "It was so, that after Yahweh had spoken these words to Job, Yahweh said to Eliphaz the Temanite, \"My wrath is kindled against you, and against your two friends; for you have not spoken of me the thing that is right, as my servant Job has.",
                "Now therefore, take to yourselves seven bulls and seven rams, and go to my servant Job, and offer up for yourselves a burnt offering; and my servant Job shall pray for you, for I will accept him, that I not deal with you according to your folly. For you have not spoken of me the thing that is right, as my servant Job has.\"",
                "So Eliphaz the Temanite and Bildad the Shuhite and Zophar the Naamathite went, and did what Yahweh commanded them, and Yahweh accepted Job.",
                "Yahweh turned the captivity of Job, when he prayed for his friends. Yahweh gave Job twice as much as he had before.",
                "Then came there to him all his brothers, and all his sisters, and all those who had been of his acquaintance before, and ate bread with him in his house. They comforted him, and consoled him concerning all the evil that Yahweh had brought on him. Everyone also gave him a piece of money, and everyone a ring of gold.",
                "So Yahweh blessed the latter end of Job more than his beginning. He had fourteen thousand sheep, six thousand camels, one thousand yoke of oxen, and a thousand female donkeys.",
                "He had also seven sons and three daughters.",
                "He called the name of the first, Jemimah; and the name of the second, Keziah; and the name of the third, Keren Happuch.",
                "In all the land were no women found so beautiful as the daughters of Job. Their father gave them an inheritance among their brothers.",
                "After this Job lived one hundred forty years, and saw his sons, and his sons\u2019 sons, to four generations.",
                "So Job died, being old and full of days."
            ]
        },
        19: {
            1: [
                "Blessed is the man who doesn\u2019t walk in the counsel of the wicked, nor stand in the way of sinners, nor sit in the seat of scoffers;",
                "but his delight is in Yahweh\u2019s law. On his law he meditates day and night.",
                "He will be like a tree planted by the streams of water, that brings forth its fruit in its season, whose leaf also does not wither. Whatever he does shall prosper.",
                "The wicked are not so, but are like the chaff which the wind drives away.",
                "Therefore the wicked shall not stand in the judgment, nor sinners in the congregation of the righteous.",
                "For Yahweh knows the way of the righteous, but the way of the wicked shall perish."
            ],
            2: [
                "Why do the nations rage, and the peoples plot a vain thing?",
                "The kings of the earth take a stand, and the rulers take counsel together, against Yahweh, and against his Anointed, saying,",
                "\"Let\u2019s break their bonds apart, and cast their cords from us.\"",
                "He who sits in the heavens will laugh. The Lord will have them in derision.",
                "Then he will speak to them in his anger, and terrify them in his wrath:",
                "\"Yet I have set my King on my holy hill of Zion.\"",
                "I will tell of the decree. Yahweh said to me, \"You are my son. Today I have become your father.",
                "Ask of me, and I will give the nations for your inheritance, the uttermost parts of the earth for your possession.",
                "You shall break them with a rod of iron. You shall dash them in pieces like a potter\u2019s vessel.\"",
                "Now therefore be wise, you kings. Be instructed, you judges of the earth.",
                "Serve Yahweh with fear, and rejoice with trembling.",
                "Give sincere homage to the Son, lest he be angry, and you perish in the way, for his wrath will soon be kindled. Blessed are all those who take refuge in him."
            ],
            3: [
                "A Psalm by David, when he fled from Absalom his son. Yahweh, how my adversaries have increased! Many are those who rise up against me.",
                "Many there are who say of my soul, \"There is no help for him in God.\" Selah.",
                "But you, Yahweh, are a shield around me, my glory, and the one who lifts up my head.",
                "I cry to Yahweh with my voice, and he answers me out of his holy hill. Selah.",
                "I laid myself down and slept. I awakened; for Yahweh sustains me.",
                "I will not be afraid of tens of thousands of people who have set themselves against me on every side.",
                "Arise, Yahweh! Save me, my God! For you have struck all of my enemies on the cheek bone. You have broken the teeth of the wicked.",
                "Salvation belongs to Yahweh. Your blessing be on your people. Selah."
            ],
            4: [
                "For the Chief Musician; on stringed instruments. A Psalm by David. Answer me when I call, God of my righteousness. Give me relief from my distress. Have mercy on me, and hear my prayer.",
                "You sons of men, how long shall my glory be turned into dishonor? Will you love vanity, and seek after falsehood? Selah.",
                "But know that Yahweh has set apart for himself him who is godly: Yahweh will hear when I call to him.",
                "Stand in awe, and don\u2019t sin. Search your own heart on your bed, and be still. Selah.",
                "Offer the sacrifices of righteousness. Put your trust in Yahweh.",
                "Many say, \"Who will show us any good?\" Yahweh, let the light of your face shine on us.",
                "You have put gladness in my heart, more than when their grain and their new wine are increased.",
                "In peace I will both lay myself down and sleep, for you, Yahweh alone, make me live in safety."
            ],
            5: [
                "For the Chief Musician, with the flutes. A Psalm by David. Give ear to my words, Yahweh. Consider my meditation.",
                "Listen to the voice of my cry, my King and my God; for to you do I pray.",
                "Yahweh, in the morning you shall hear my voice. In the morning I will lay my requests before you, and will watch expectantly.",
                "For you are not a God who has pleasure in wickedness. Evil can\u2019t live with you.",
                "The arrogant shall not stand in your sight. You hate all workers of iniquity.",
                "You will destroy those who speak lies. Yahweh abhors the bloodthirsty and deceitful man.",
                "But as for me, in the abundance of your loving kindness I will come into your house. I will bow toward your holy temple in reverence of you.",
                "Lead me, Yahweh, in your righteousness because of my enemies. Make your way straight before my face.",
                "For there is no faithfulness in their mouth. Their heart is destruction. Their throat is an open tomb. They flatter with their tongue.",
                "Hold them guilty, God. Let them fall by their own counsels; Thrust them out in the multitude of their transgressions, for they have rebelled against you.",
                "But let all those who take refuge in you rejoice, Let them always shout for joy, because you defend them. Let them also who love your name be joyful in you.",
                "For you will bless the righteous. Yahweh, you will surround him with favor as with a shield."
            ],
            6: [
                "For the Chief Musician; on stringed instruments, upon the eight-stringed lyre. A Psalm by David. Yahweh, don\u2019t rebuke me in your anger, neither discipline me in your wrath.",
                "Have mercy on me, Yahweh, for I am faint. Yahweh, heal me, for my bones are troubled.",
                "My soul is also in great anguish. But you, Yahweh\u2014how long?",
                "Return, Yahweh. Deliver my soul, and save me for your loving kindness\u2019 sake.",
                "For in death there is no memory of you. In Sheol, who shall give you thanks?",
                "I am weary with my groaning. Every night I flood my bed. I drench my couch with my tears.",
                "My eye wastes away because of grief. It grows old because of all my adversaries.",
                "Depart from me, all you workers of iniquity, for Yahweh has heard the voice of my weeping.",
                "Yahweh has heard my supplication. Yahweh accepts my prayer.",
                "May all my enemies be ashamed and dismayed. They shall turn back, they shall be disgraced suddenly."
            ],
            7: [
                "A meditation by David, which he sang to Yahweh, concerning the words of Cush, the Benjamite. Yahweh, my God, I take refuge in you. Save me from all those who pursue me, and deliver me,",
                "lest they tear apart my soul like a lion, ripping it in pieces, while there is none to deliver.",
                "Yahweh, my God, if I have done this, if there is iniquity in my hands,",
                "if I have rewarded evil to him who was at peace with me (yes, if I have delivered him who without cause was my adversary),",
                "let the enemy pursue my soul, and overtake it; yes, let him tread my life down to the earth, and lay my glory in the dust. Selah.",
                "Arise, Yahweh, in your anger. Lift up yourself against the rage of my adversaries. Awake for me. You have commanded judgment.",
                "Let the congregation of the peoples surround you. Rule over them on high.",
                "Yahweh administers judgment to the peoples. Judge me, Yahweh, according to my righteousness, and to my integrity that is in me.",
                "Oh let the wickedness of the wicked come to an end, but establish the righteous; their minds and hearts are searched by the righteous God.",
                "My shield is with God, who saves the upright in heart.",
                "God is a righteous judge, yes, a God who has indignation every day.",
                "If a man doesn\u2019t relent, he will sharpen his sword; he has bent and strung his bow.",
                "He has also prepared for himself the instruments of death. He makes ready his flaming arrows.",
                "Behold, he travails with iniquity. Yes, he has conceived mischief, and brought forth falsehood.",
                "He has dug a hole, and has fallen into the pit which he made.",
                "The trouble he causes shall return to his own head. His violence shall come down on the crown of his own head.",
                "I will give thanks to Yahweh according to his righteousness, and will sing praise to the name of Yahweh Most High."
            ],
            8: [
                "For the Chief Musician; on an instrument of Gath. A Psalm by David. Yahweh, our Lord, how majestic is your name in all the earth, who has set your glory above the heavens!",
                "From the lips of babes and infants you have established strength, because of your adversaries, that you might silence the enemy and the avenger.",
                "When I consider your heavens, the work of your fingers, the moon and the stars, which you have ordained;",
                "what is man, that you think of him? What is the son of man, that you care for him?",
                "For you have made him a little lower than God, and crowned him with glory and honor.",
                "You make him ruler over the works of your hands. You have put all things under his feet:",
                "All sheep and cattle, yes, and the animals of the field,",
                "The birds of the sky, the fish of the sea, and whatever passes through the paths of the seas.",
                "Yahweh, our Lord, how majestic is your name in all the earth!"
            ],
            9: [
                "For the Chief Musician. Set to \"The Death of the Son.\" A Psalm by David. I will give thanks to Yahweh with my whole heart. I will tell of all your marvelous works.",
                "I will be glad and rejoice in you. I will sing praise to your name, O Most High.",
                "When my enemies turn back, they stumble and perish in your presence.",
                "For you have maintained my just cause. You sit on the throne judging righteously.",
                "You have rebuked the nations. You have destroyed the wicked. You have blotted out their name forever and ever.",
                "The enemy is overtaken by endless ruin. The very memory of the cities which you have overthrown has perished.",
                "But Yahweh reigns forever. He has prepared his throne for judgment.",
                "He will judge the world in righteousness. He will administer judgment to the peoples in uprightness.",
                "Yahweh will also be a high tower for the oppressed; a high tower in times of trouble.",
                "Those who know your name will put their trust in you, for you, Yahweh, have not forsaken those who seek you.",
                "Sing praises to Yahweh, who dwells in Zion, and declare among the people what he has done.",
                "For he who avenges blood remembers them. He doesn\u2019t forget the cry of the afflicted.",
                "Have mercy on me, Yahweh. See my affliction by those who hate me, and lift me up from the gates of death;",
                "that I may show forth all your praise. In the gates of the daughter of Zion, I will rejoice in your salvation.",
                "The nations have sunk down in the pit that they made. In the net which they hid, their own foot is taken.",
                "Yahweh has made himself known. He has executed judgment. The wicked is snared by the work of his own hands. Meditation. Selah.",
                "The wicked shall be turned back to Sheol, even all the nations that forget God.",
                "For the needy shall not always be forgotten, nor the hope of the poor perish forever.",
                "Arise, Yahweh! Don\u2019t let man prevail. Let the nations be judged in your sight.",
                "Put them in fear, Yahweh. Let the nations know that they are only men. Selah."
            ],
            10: [
                "Why do you stand far off, Yahweh? Why do you hide yourself in times of trouble?",
                "In arrogance, the wicked hunt down the weak. They are caught in the schemes that they devise.",
                "For the wicked boasts of his heart\u2019s cravings. He blesses the greedy, and condemns Yahweh.",
                "The wicked, in the pride of his face, has no room in his thoughts for God.",
                "His ways are prosperous at all times. He is haughty, and your laws are far from his sight. As for all his adversaries, he sneers at them.",
                "He says in his heart, \"I shall not be shaken. For generations I shall have no trouble.\"",
                "His mouth is full of cursing, deceit, and oppression. Under his tongue is mischief and iniquity.",
                "He lies in wait near the villages. From ambushes, he murders the innocent. His eyes are secretly set against the helpless.",
                "He lurks in secret as a lion in his ambush. He lies in wait to catch the helpless. He catches the helpless, when he draws him in his net.",
                "The helpless are crushed. They collapse. They fall under his strength.",
                "He says in his heart, \"God has forgotten. He hides his face. He will never see it.\"",
                "Arise, Yahweh! God, lift up your hand! Don\u2019t forget the helpless.",
                "Why does the wicked person condemn God, and say in his heart, \"God won\u2019t call me into account?\"",
                "But you do see trouble and grief. You consider it to take it into your hand. You help the victim and the fatherless.",
                "Break the arm of the wicked. As for the evil man, seek out his wickedness until you find none.",
                "Yahweh is King forever and ever! The nations will perish out of his land.",
                "Yahweh, you have heard the desire of the humble. You will prepare their heart. You will cause your ear to hear,",
                "to judge the fatherless and the oppressed, that man who is of the earth may terrify no more."
            ],
            11: [
                "For the Chief Musician. By David. In Yahweh, I take refuge. How can you say to my soul, \"Flee as a bird to your mountain!\"",
                "For, behold, the wicked bend their bows. They set their arrows on the strings, that they may shoot in darkness at the upright in heart.",
                "If the foundations are destroyed, what can the righteous do?",
                "Yahweh is in his holy temple. Yahweh is on his throne in heaven. His eyes observe. His eyes examine the children of men.",
                "Yahweh examines the righteous, but the wicked and him who loves violence his soul hates.",
                "On the wicked he will rain blazing coals; fire, sulfur, and scorching wind shall be the portion of their cup.",
                "For Yahweh is righteous. He loves righteousness. The upright shall see his face."
            ],
            12: [
                "For the Chief Musician; upon an eight-stringed lyre. A Psalm of David. Help, Yahweh; for the godly man ceases. For the faithful fail from among the children of men.",
                "Everyone lies to his neighbor. They speak with flattering lips, and with a double heart.",
                "May Yahweh cut off all flattering lips, and the tongue that boasts,",
                "who have said, \"With our tongue we will prevail. Our lips are our own. Who is lord over us?\"",
                "\"Because of the oppression of the weak and because of the groaning of the needy, I will now arise,\" says Yahweh; \"I will set him in safety from those who malign him.\"",
                "The words of Yahweh are flawless words, as silver refined in a clay furnace, purified seven times.",
                "You will keep them, Yahweh. You will preserve them from this generation forever.",
                "The wicked walk on every side, when what is vile is exalted among the sons of men."
            ],
            13: [
                "For the Chief Musician. A Psalm by David. How long, Yahweh? Will you forget me forever? How long will you hide your face from me?",
                "How long shall I take counsel in my soul, having sorrow in my heart every day? How long shall my enemy triumph over me?",
                "Behold, and answer me, Yahweh, my God. Give light to my eyes, lest I sleep in death;",
                "Lest my enemy say, \"I have prevailed against him;\" Lest my adversaries rejoice when I fall.",
                "But I trust in your loving kindness. My heart rejoices in your salvation.",
                "I will sing to Yahweh, because he has been good to me."
            ],
            14: [
                "For the Chief Musician. By David. The fool has said in his heart, \"There is no God.\" They are corrupt. They have done abominable works. There is none who does good.",
                "Yahweh looked down from heaven on the children of men, to see if there were any who understood, who sought after God.",
                "They have all gone aside. They have together become corrupt. There is none who does good, no, not one.",
                "Have all the workers of iniquity no knowledge, who eat up my people as they eat bread, and don\u2019t call on Yahweh?",
                "There they were in great fear, for God is in the generation of the righteous.",
                "You frustrate the plan of the poor, because Yahweh is his refuge.",
                "Oh that the salvation of Israel would come out of Zion! When Yahweh restores the fortunes of his people, then Jacob shall rejoice, and Israel shall be glad."
            ],
            15: [
                "A Psalm by David. Yahweh, who shall dwell in your sanctuary? Who shall live on your holy hill?",
                "He who walks blamelessly does what is right, and speaks truth in his heart;",
                "He who doesn\u2019t slander with his tongue, nor does evil to his friend, nor casts slurs against his fellow man;",
                "In whose eyes a vile man is despised, but who honors those who fear Yahweh; he who keeps an oath even when it hurts, and doesn\u2019t change;",
                "he who doesn\u2019t lend out his money for usury, nor take a bribe against the innocent. He who does these things shall never be shaken."
            ],
            16: [
                "A Poem by David. Preserve me, God, for in you do I take refuge.",
                "My soul, you have said to Yahweh, \"You are my Lord. Apart from you I have no good thing.\"",
                "As for the saints who are in the earth, they are the excellent ones in whom is all my delight.",
                "Their sorrows shall be multiplied who give gifts to another god. Their drink offerings of blood I will not offer, nor take their names on my lips.",
                "Yahweh assigned my portion and my cup. You made my lot secure.",
                "The lines have fallen to me in pleasant places. Yes, I have a good inheritance.",
                "I will bless Yahweh, who has given me counsel. Yes, my heart instructs me in the night seasons.",
                "I have set Yahweh always before me. Because he is at my right hand, I shall not be moved.",
                "Therefore my heart is glad, and my tongue rejoices. My body shall also dwell in safety.",
                "For you will not leave my soul in Sheol, neither will you allow your holy one to see corruption.",
                "You will show me the path of life. In your presence is fullness of joy. In your right hand there are pleasures forevermore."
            ],
            17: [
                "A Prayer by David. Hear, Yahweh, my righteous plea; Give ear to my prayer, that doesn\u2019t go out of deceitful lips.",
                "Let my sentence come forth from your presence. Let your eyes look on equity.",
                "You have proved my heart. You have visited me in the night. You have tried me, and found nothing. I have resolved that my mouth shall not disobey.",
                "As for the works of men, by the word of your lips, I have kept myself from the ways of the violent.",
                "My steps have held fast to your paths. My feet have not slipped.",
                "I have called on you, for you will answer me, God. Turn your ear to me. Hear my speech.",
                "Show your marvelous loving kindness, you who save those who take refuge by your right hand from their enemies.",
                "Keep me as the apple of your eye. Hide me under the shadow of your wings,",
                "from the wicked who oppress me, my deadly enemies, who surround me.",
                "They close up their callous hearts. With their mouth they speak proudly.",
                "They have now surrounded us in our steps. They set their eyes to cast us down to the earth.",
                "He is like a lion that is greedy of his prey, as it were a young lion lurking in secret places.",
                "Arise, Yahweh, confront him. Cast him down. Deliver my soul from the wicked by your sword;",
                "from men by your hand, Yahweh, from men of the world, whose portion is in this life. You fill the belly of your cherished ones. Your sons have plenty, and they store up wealth for their children.",
                "As for me, I shall see your face in righteousness. I shall be satisfied, when I awake, with seeing your form."
            ],
            18: [
                "For the Chief Musician. By David the servant of Yahweh, who spoke to Yahweh the words of this song in the day that Yahweh delivered him from the hand of all his enemies, and from the hand of Saul. He said, I love you, Yahweh, my strength.",
                "Yahweh is my rock, my fortress, and my deliverer; my God, my rock, in whom I take refuge; my shield, and the horn of my salvation, my high tower.",
                "I call on Yahweh, who is worthy to be praised; and I am saved from my enemies.",
                "The cords of death surrounded me. The floods of ungodliness made me afraid.",
                "The cords of Sheol were around me. The snares of death came on me.",
                "In my distress I called on Yahweh, and cried to my God. He heard my voice out of his temple. My cry before him came into his ears.",
                "Then the earth shook and trembled. The foundations also of the mountains quaked and were shaken, because he was angry.",
                "Smoke went out of his nostrils. Consuming fire came out of his mouth. Coals were kindled by it.",
                "He bowed the heavens also, and came down. Thick darkness was under his feet.",
                "He rode on a cherub, and flew. Yes, he soared on the wings of the wind.",
                "He made darkness his hiding place, his pavilion around him, darkness of waters, thick clouds of the skies.",
                "At the brightness before him his thick clouds passed, hailstones and coals of fire.",
                "Yahweh also thundered in the sky. The Most High uttered his voice: hailstones and coals of fire.",
                "He sent out his arrows, and scattered them; Yes, great lightning bolts, and routed them.",
                "Then the channels of waters appeared. The foundations of the world were laid bare at your rebuke, Yahweh, at the blast of the breath of your nostrils.",
                "He sent from on high. He took me. He drew me out of many waters.",
                "He delivered me from my strong enemy, from those who hated me; for they were too mighty for me.",
                "They came on me in the day of my calamity, but Yahweh was my support.",
                "He brought me forth also into a large place. He delivered me, because he delighted in me.",
                "Yahweh has rewarded me according to my righteousness. According to the cleanness of my hands has he recompensed me.",
                "For I have kept the ways of Yahweh, and have not wickedly departed from my God.",
                "For all his ordinances were before me. I didn\u2019t put away his statutes from me.",
                "I was also blameless with him. I kept myself from my iniquity.",
                "Therefore Yahweh has rewarded me according to my righteousness, according to the cleanness of my hands in his eyesight.",
                "With the merciful you will show yourself merciful. With the perfect man, you will show yourself perfect.",
                "With the pure, you will show yourself pure. With the crooked you will show yourself shrewd.",
                "For you will save the afflicted people, but the haughty eyes you will bring down.",
                "For you will light my lamp, Yahweh. My God will light up my darkness.",
                "For by you, I advance through a troop. By my God, I leap over a wall.",
                "As for God, his way is perfect. The word of Yahweh is tried. He is a shield to all those who take refuge in him.",
                "For who is God, except Yahweh? Who is a rock, besides our God,",
                "the God who arms me with strength, and makes my way perfect?",
                "He makes my feet like deer\u2019s feet, and sets me on my high places.",
                "He teaches my hands to war, so that my arms bend a bow of bronze.",
                "You have also given me the shield of your salvation. Your right hand sustains me. Your gentleness has made me great.",
                "You have enlarged my steps under me, My feet have not slipped.",
                "I will pursue my enemies, and overtake them. Neither will I turn again until they are consumed.",
                "I will strike them through, so that they will not be able to rise. They shall fall under my feet.",
                "For you have armed me with strength to the battle. You have subdued under me those who rose up against me.",
                "You have also made my enemies turn their backs to me, that I might cut off those who hate me.",
                "They cried, but there was none to save; even to Yahweh, but he didn\u2019t answer them.",
                "Then I beat them small as the dust before the wind. I cast them out as the mire of the streets.",
                "You have delivered me from the strivings of the people. You have made me the head of the nations. A people whom I have not known shall serve me.",
                "As soon as they hear of me they shall obey me. The foreigners shall submit themselves to me.",
                "The foreigners shall fade away, and shall come trembling out of their close places.",
                "Yahweh lives; and blessed be my rock. Exalted be the God of my salvation,",
                "even the God who executes vengeance for me, and subdues peoples under me.",
                "He rescues me from my enemies. Yes, you lift me up above those who rise up against me. You deliver me from the violent man.",
                "Therefore I will give thanks to you, Yahweh, among the nations, and will sing praises to your name.",
                "He gives great deliverance to his king, and shows loving kindness to his anointed, to David and to his seed, forevermore."
            ],
            19: [
                "For the Chief Musician. A Psalm by David. The heavens declare the glory of God. The expanse shows his handiwork.",
                "Day after day they pour forth speech, and night after night they display knowledge.",
                "There is no speech nor language, where their voice is not heard.",
                "Their voice has gone out through all the earth, their words to the end of the world. In them he has set a tent for the sun,",
                "which is as a bridegroom coming out of his room, like a strong man rejoicing to run his course.",
                "His going forth is from the end of the heavens, his circuit to its ends; There is nothing hidden from its heat.",
                "Yahweh\u2019s law is perfect, restoring the soul. Yahweh\u2019s testimony is sure, making wise the simple.",
                "Yahweh\u2019s precepts are right, rejoicing the heart. Yahweh\u2019s commandment is pure, enlightening the eyes.",
                "The fear of Yahweh is clean, enduring forever. Yahweh\u2019s ordinances are true, and righteous altogether.",
                "More to be desired are they than gold, yes, than much fine gold; sweeter also than honey and the extract of the honeycomb.",
                "Moreover by them is your servant warned. In keeping them there is great reward.",
                "Who can discern his errors? Forgive me from hidden errors.",
                "Keep back your servant also from presumptuous sins. Let them not have dominion over me. Then I will be upright. I will be blameless and innocent of great transgression.",
                "Let the words of my mouth and the meditation of my heart be acceptable in your sight, Yahweh, my rock, and my redeemer."
            ],
            20: [
                "For the Chief Musician. A Psalm by David. May Yahweh answer you in the day of trouble. May the name of the God of Jacob set you up on high,",
                "send you help from the sanctuary, grant you support from Zion,",
                "remember all your offerings, and accept your burnt sacrifice. Selah.",
                "May He grant you your heart\u2019s desire, and fulfill all your counsel.",
                "We will triumph in your salvation. In the name of our God, we will set up our banners. May Yahweh grant all your requests.",
                "Now I know that Yahweh saves his anointed. He will answer him from his holy heaven, with the saving strength of his right hand.",
                "Some trust in chariots, and some in horses, but we trust the name of Yahweh our God.",
                "They are bowed down and fallen, but we rise up, and stand upright.",
                "Save, Yahweh! Let the King answer us when we call!"
            ],
            21: [
                "For the Chief Musician. A Psalm by David. The king rejoices in your strength, Yahweh! How greatly he rejoices in your salvation!",
                "You have given him his heart\u2019s desire, and have not withheld the request of his lips. Selah.",
                "For you meet him with the blessings of goodness. You set a crown of fine gold on his head.",
                "He asked life of you, you gave it to him, even length of days forever and ever.",
                "His glory is great in your salvation. You lay honor and majesty on him.",
                "For you make him most blessed forever. You make him glad with joy in your presence.",
                "For the king trusts in Yahweh. Through the loving kindness of the Most High, he shall not be moved.",
                "Your hand will find out all of your enemies. Your right hand will find out those who hate you.",
                "You will make them as a fiery furnace in the time of your anger. Yahweh will swallow them up in his wrath. The fire shall devour them.",
                "You will destroy their descendants from the earth, their posterity from among the children of men.",
                "For they intended evil against you. They plotted evil against you which cannot succeed.",
                "For you will make them turn their back, when you aim drawn bows at their face.",
                "Be exalted, Yahweh, in your strength, so we will sing and praise your power."
            ],
            22: [
                "For the Chief Musician; set to \"The Doe of the Morning.\" A Psalm by David. My God, my God, why have you forsaken me? Why are you so far from helping me, and from the words of my groaning?",
                "My God, I cry in the daytime, but you don\u2019t answer; in the night season, and am not silent.",
                "But you are holy, you who inhabit the praises of Israel.",
                "Our fathers trusted in you. They trusted, and you delivered them.",
                "They cried to you, and were delivered. They trusted in you, and were not disappointed.",
                "But I am a worm, and no man; a reproach of men, and despised by the people.",
                "All those who see me mock me. They insult me with their lips. They shake their heads, saying,",
                "\"He trusts in Yahweh; let him deliver him. Let him rescue him, since he delights in him.\"",
                "But you brought me out of the womb. You made me trust at my mother\u2019s breasts.",
                "I was thrown on you from my mother\u2019s womb. You are my God since my mother bore me.",
                "Don\u2019t be far from me, for trouble is near. For there is none to help.",
                "Many bulls have surrounded me. Strong bulls of Bashan have encircled me.",
                "They open their mouths wide against me, lions tearing prey and roaring.",
                "I am poured out like water. All my bones are out of joint. My heart is like wax; it is melted within me.",
                "My strength is dried up like a potsherd. My tongue sticks to the roof of my mouth. You have brought me into the dust of death.",
                "For dogs have surrounded me. A company of evildoers have enclosed me. They have pierced my hands and feet.",
                "I can count all of my bones. They look and stare at me.",
                "They divide my garments among them. They cast lots for my clothing.",
                "But don\u2019t be far off, Yahweh. You are my help: hurry to help me.",
                "Deliver my soul from the sword, my precious life from the power of the dog.",
                "Save me from the lion\u2019s mouth! Yes, from the horns of the wild oxen, you have answered me.",
                "I will declare your name to my brothers. In the midst of the assembly, I will praise you.",
                "You who fear Yahweh, praise him! All you descendants of Jacob, glorify him! Stand in awe of him, all you descendants of Israel!",
                "For he has not despised nor abhorred the affliction of the afflicted, Neither has he hidden his face from him; but when he cried to him, he heard.",
                "Of you comes my praise in the great assembly. I will pay my vows before those who fear him.",
                "The humble shall eat and be satisfied. They shall praise Yahweh who seek after him. Let your hearts live forever.",
                "All the ends of the earth shall remember and turn to Yahweh. All the relatives of the nations shall worship before you.",
                "For the kingdom is Yahweh\u2019s. He is the ruler over the nations.",
                "All the rich ones of the earth shall eat and worship. All those who go down to the dust shall bow before him, even he who can\u2019t keep his soul alive.",
                "Posterity shall serve him. Future generations shall be told about the Lord.",
                "They shall come and shall declare his righteousness to a people that shall be born, for he has done it."
            ],
            23: [
                "A Psalm by David. Yahweh is my shepherd: I shall lack nothing.",
                "He makes me lie down in green pastures. He leads me beside still waters.",
                "He restores my soul. He guides me in the paths of righteousness for his name\u2019s sake.",
                "Even though I walk through the valley of the shadow of death, I will fear no evil, for you are with me. Your rod and your staff, they comfort me.",
                "You prepare a table before me in the presence of my enemies. You anoint my head with oil. My cup runs over.",
                "Surely goodness and loving kindness shall follow me all the days of my life, and I will dwell in Yahweh\u2019s house forever."
            ],
            24: [
                "A Psalm by David. The earth is Yahweh\u2019s, with its fullness; the world, and those who dwell therein.",
                "For he has founded it on the seas, and established it on the floods.",
                "Who may ascend to Yahweh\u2019s hill? Who may stand in his holy place?",
                "He who has clean hands and a pure heart; who has not lifted up his soul to falsehood, and has not sworn deceitfully.",
                "He shall receive a blessing from Yahweh, righteousness from the God of his salvation.",
                "This is the generation of those who seek Him, who seek your face\u2014even Jacob. Selah.",
                "Lift up your heads, you gates! Be lifted up, you everlasting doors, and the King of glory will come in.",
                "Who is the King of glory? Yahweh strong and mighty, Yahweh mighty in battle.",
                "Lift up your heads, you gates; yes, lift them up, you everlasting doors, and the King of glory will come in.",
                "Who is this King of glory? Yahweh of Armies is the King of glory! Selah."
            ],
            25: [
                "By David. To you, Yahweh, do I lift up my soul.",
                "My God, I have trusted in you. Don\u2019t let me be shamed. Don\u2019t let my enemies triumph over me.",
                "Yes, no one who waits for you shall be shamed. They shall be shamed who deal treacherously without cause.",
                "Show me your ways, Yahweh. Teach me your paths.",
                "Guide me in your truth, and teach me, For you are the God of my salvation, I wait for you all day long.",
                "Yahweh, remember your tender mercies and your loving kindness, for they are from old times.",
                "Don\u2019t remember the sins of my youth, nor my transgressions. Remember me according to your loving kindness, for your goodness\u2019 sake, Yahweh.",
                "Good and upright is Yahweh, therefore he will instruct sinners in the way.",
                "He will guide the humble in justice. He will teach the humble his way.",
                "All the paths of Yahweh are loving kindness and truth to such as keep his covenant and his testimonies.",
                "For your name\u2019s sake, Yahweh, pardon my iniquity, for it is great.",
                "What man is he who fears Yahweh? He shall instruct him in the way that he shall choose.",
                "His soul shall dwell at ease. His seed shall inherit the land.",
                "The friendship of Yahweh is with those who fear him. He will show them his covenant.",
                "My eyes are ever on Yahweh, for he will pluck my feet out of the net.",
                "Turn to me, and have mercy on me, for I am desolate and afflicted.",
                "The troubles of my heart are enlarged. Oh bring me out of my distresses.",
                "Consider my affliction and my travail. Forgive all my sins.",
                "Consider my enemies, for they are many. They hate me with cruel hatred.",
                "Oh keep my soul, and deliver me. Let me not be disappointed, for I take refuge in you.",
                "Let integrity and uprightness preserve me, for I wait for you.",
                "Redeem Israel, God, out all of his troubles."
            ],
            26: [
                "By David. Judge me, Yahweh, for I have walked in my integrity. I have trusted also in Yahweh without wavering.",
                "Examine me, Yahweh, and prove me. Try my heart and my mind.",
                "For your loving kindness is before my eyes. I have walked in your truth.",
                "I have not sat with deceitful men, neither will I go in with hypocrites.",
                "I hate the assembly of evildoers, and will not sit with the wicked.",
                "I will wash my hands in innocence, so I will go about your altar, Yahweh;",
                "that I may make the voice of thanksgiving to be heard, and tell of all your wondrous works.",
                "Yahweh, I love the habitation of your house, the place where your glory dwells.",
                "Don\u2019t gather my soul with sinners, nor my life with bloodthirsty men;",
                "in whose hands is wickedness, their right hand is full of bribes.",
                "But as for me, I will walk in my integrity. Redeem me, and be merciful to me.",
                "My foot stands in an even place. In the congregations I will bless Yahweh."
            ],
            27: [
                "By David. Yahweh is my light and my salvation. Whom shall I fear? Yahweh is the strength of my life. Of whom shall I be afraid?",
                "When evildoers came at me to eat up my flesh, even my adversaries and my foes, they stumbled and fell.",
                "Though an army should encamp against me, my heart shall not fear. Though war should rise against me, even then I will be confident.",
                "One thing I have asked of Yahweh, that I will seek after, that I may dwell in the house of Yahweh all the days of my life, to see Yahweh\u2019s beauty, and to inquire in his temple.",
                "For in the day of trouble he will keep me secretly in his pavilion. In the covert of his tabernacle he will hide me. He will lift me up on a rock.",
                "Now my head will be lifted up above my enemies around me. I will offer sacrifices of joy in his tent. I will sing, yes, I will sing praises to Yahweh.",
                "Hear, Yahweh, when I cry with my voice. Have mercy also on me, and answer me.",
                "When you said, \"Seek my face,\" my heart said to you, \"I will seek your face, Yahweh.\"",
                "Don\u2019t hide your face from me. Don\u2019t put your servant away in anger. You have been my help. Don\u2019t abandon me, neither forsake me, God of my salvation.",
                "When my father and my mother forsake me, then Yahweh will take me up.",
                "Teach me your way, Yahweh. Lead me in a straight path, because of my enemies.",
                "Don\u2019t deliver me over to the desire of my adversaries, for false witnesses have risen up against me, such as breathe out cruelty.",
                "I am still confident of this: I will see the goodness of Yahweh in the land of the living.",
                "Wait for Yahweh. Be strong, and let your heart take courage. Yes, wait for Yahweh."
            ],
            28: [
                "By David. To you, Yahweh, I call. My rock, don\u2019t be deaf to me; lest, if you are silent to me, I would become like those who go down into the pit.",
                "Hear the voice of my petitions, when I cry to you, when I lift up my hands toward your Most Holy Place.",
                "Don\u2019t draw me away with the wicked, with the workers of iniquity who speak peace with their neighbors, but mischief is in their hearts.",
                "Give them according to their work, and according to the wickedness of their doings. Give them according to the operation of their hands. Bring back on them what they deserve.",
                "Because they don\u2019t respect the works of Yahweh, nor the operation of his hands, he will break them down and not build them up.",
                "Blessed be Yahweh, because he has heard the voice of my petitions.",
                "Yahweh is my strength and my shield. My heart has trusted in him, and I am helped. Therefore my heart greatly rejoices. With my song I will thank him.",
                "Yahweh is their strength. He is a stronghold of salvation to his anointed.",
                "Save your people, and bless your inheritance. Be their shepherd also, and bear them up forever."
            ],
            29: [
                "A Psalm by David. Ascribe to Yahweh, you sons of the mighty, ascribe to Yahweh glory and strength.",
                "Ascribe to Yahweh the glory due to his name. Worship Yahweh in holy array.",
                "Yahweh\u2019s voice is on the waters. The God of glory thunders, even Yahweh on many waters.",
                "Yahweh\u2019s voice is powerful. Yahweh\u2019s voice is full of majesty.",
                "The voice of Yahweh breaks the cedars. Yes, Yahweh breaks in pieces the cedars of Lebanon.",
                "He makes them also to skip like a calf; Lebanon and Sirion like a young, wild ox.",
                "Yahweh\u2019s voice strikes with flashes of lightning.",
                "Yahweh\u2019s voice shakes the wilderness. Yahweh shakes the wilderness of Kadesh.",
                "Yahweh\u2019s voice makes the deer calve, and strips the forests bare. In his temple everything says, \"Glory!\"",
                "Yahweh sat enthroned at the Flood. Yes, Yahweh sits as King forever.",
                "Yahweh will give strength to his people. Yahweh will bless his people with peace."
            ],
            30: [
                "A Psalm. A Song for the Dedication of the Temple. By David. I will extol you, Yahweh, for you have raised me up, and have not made my foes to rejoice over me.",
                "Yahweh my God, I cried to you, and you have healed me.",
                "Yahweh, you have brought up my soul from Sheol. You have kept me alive, that I should not go down to the pit.",
                "Sing praise to Yahweh, you saints of his. Give thanks to his holy name.",
                "For his anger is but for a moment. His favor is for a lifetime. Weeping may stay for the night, but joy comes in the morning.",
                "As for me, I said in my prosperity, \"I shall never be moved.\"",
                "You, Yahweh, when you favored me, made my mountain stand strong; but when you hid your face, I was troubled.",
                "I cried to you, Yahweh. To Yahweh I made supplication:",
                "\"What profit is there in my destruction, if I go down to the pit? Shall the dust praise you? Shall it declare your truth?",
                "Hear, Yahweh, and have mercy on me. Yahweh, be my helper.\"",
                "You have turned my mourning into dancing for me. You have removed my sackcloth, and clothed me with gladness,",
                "To the end that my heart may sing praise to you, and not be silent. Yahweh my God, I will give thanks to you forever!"
            ],
            31: [
                "For the Chief Musician. A Psalm by David. In you, Yahweh, I take refuge. Let me never be disappointed. Deliver me in your righteousness.",
                "Bow down your ear to me. Deliver me speedily. Be to me a strong rock, a house of defense to save me.",
                "For you are my rock and my fortress, therefore for your name\u2019s sake lead me and guide me.",
                "Pluck me out of the net that they have laid secretly for me, for you are my stronghold.",
                "Into your hand I commend my spirit. You redeem me, Yahweh, God of truth.",
                "I hate those who regard lying vanities, but I trust in Yahweh.",
                "I will be glad and rejoice in your loving kindness, for you have seen my affliction. You have known my soul in adversities.",
                "You have not shut me up into the hand of the enemy. You have set my feet in a large place.",
                "Have mercy on me, Yahweh, for I am in distress. My eye, my soul, and my body waste away with grief.",
                "For my life is spent with sorrow, my years with sighing. My strength fails because of my iniquity. My bones are wasted away.",
                "Because of all my adversaries I have become utterly contemptible to my neighbors, A fear to my acquaintances. Those who saw me on the street fled from me.",
                "I am forgotten from their hearts like a dead man. I am like broken pottery.",
                "For I have heard the slander of many, terror on every side, while they conspire together against me, they plot to take away my life.",
                "But I trust in you, Yahweh. I said, \"You are my God.\"",
                "My times are in your hand. Deliver me from the hand of my enemies, and from those who persecute me.",
                "Make your face to shine on your servant. Save me in your loving kindness.",
                "Let me not be disappointed, Yahweh, for I have called on you. Let the wicked be disappointed. Let them be silent in Sheol.",
                "Let the lying lips be mute, which speak against the righteous insolently, with pride and contempt.",
                "Oh how great is your goodness, which you have laid up for those who fear you, which you have worked for those who take refuge in you, before the sons of men!",
                "In the shelter of your presence you will hide them from the plotting of man. You will keep them secretly in a dwelling away from the strife of tongues.",
                "Praise be to Yahweh, for he has shown me his marvelous loving kindness in a strong city.",
                "As for me, I said in my haste, \"I am cut off from before your eyes.\" Nevertheless you heard the voice of my petitions when I cried to you.",
                "Oh love Yahweh, all you his saints! Yahweh preserves the faithful, and fully recompenses him who behaves arrogantly.",
                "Be strong, and let your heart take courage, all you who hope in Yahweh."
            ],
            32: [
                "By David. A contemplative psalm. Blessed is he whose disobedience is forgiven, whose sin is covered.",
                "Blessed is the man to whom Yahweh doesn\u2019t impute iniquity, in whose spirit there is no deceit.",
                "When I kept silence, my bones wasted away through my groaning all day long.",
                "For day and night your hand was heavy on me. My strength was sapped in the heat of summer. Selah.",
                "I acknowledged my sin to you. I didn\u2019t hide my iniquity. I said, I will confess my transgressions to Yahweh, and you forgave the iniquity of my sin. Selah.",
                "For this, let everyone who is godly pray to you in a time when you may be found. Surely when the great waters overflow, they shall not reach to him.",
                "You are my hiding place. You will preserve me from trouble. You will surround me with songs of deliverance. Selah.",
                "I will instruct you and teach you in the way which you shall go. I will counsel you with my eye on you.",
                "Don\u2019t be like the horse, or like the mule, which have no understanding, who are controlled by bit and bridle, or else they will not come near to you.",
                "Many sorrows come to the wicked, but loving kindness shall surround him who trusts in Yahweh.",
                "Be glad in Yahweh, and rejoice, you righteous! Shout for joy, all you who are upright in heart!"
            ],
            33: [
                "Rejoice in Yahweh, you righteous! Praise is fitting for the upright.",
                "Give thanks to Yahweh with the lyre. Sing praises to him with the harp of ten strings.",
                "Sing to him a new song. Play skillfully with a shout of joy!",
                "For the word of Yahweh is right. All his work is done in faithfulness.",
                "He loves righteousness and justice. The earth is full of the loving kindness of Yahweh.",
                "By Yahweh\u2019s word, the heavens were made; all their army by the breath of his mouth.",
                "He gathers the waters of the sea together as a heap. He lays up the deeps in storehouses.",
                "Let all the earth fear Yahweh. Let all the inhabitants of the world stand in awe of him.",
                "For he spoke, and it was done. He commanded, and it stood firm.",
                "Yahweh brings the counsel of the nations to nothing. He makes the thoughts of the peoples to be of no effect.",
                "The counsel of Yahweh stands fast forever, the thoughts of his heart to all generations.",
                "Blessed is the nation whose God is Yahweh, the people whom he has chosen for his own inheritance.",
                "Yahweh looks from heaven. He sees all the sons of men.",
                "From the place of his habitation he looks out on all the inhabitants of the earth,",
                "he who fashions all of their hearts; and he considers all of their works.",
                "There is no king saved by the multitude of an army. A mighty man is not delivered by great strength.",
                "A horse is a vain thing for safety, neither does he deliver any by his great power.",
                "Behold, Yahweh\u2019s eye is on those who fear him, on those who hope in his loving kindness;",
                "to deliver their soul from death, to keep them alive in famine.",
                "Our soul has waited for Yahweh. He is our help and our shield.",
                "For our heart rejoices in him, because we have trusted in his holy name.",
                "Let your loving kindness be on us, Yahweh, since we have hoped in you."
            ],
            34: [
                "By David; when he pretended to be insane before Abimelech, who drove him away, and he departed. I will bless Yahweh at all times. His praise will always be in my mouth.",
                "My soul shall boast in Yahweh. The humble shall hear of it, and be glad.",
                "Oh magnify Yahweh with me. Let us exalt his name together.",
                "I sought Yahweh, and he answered me, and delivered me from all my fears.",
                "They looked to him, and were radiant. Their faces shall never be covered with shame.",
                "This poor man cried, and Yahweh heard him, and saved him out of all his troubles.",
                "The angel of Yahweh encamps around those who fear him, and delivers them.",
                "Oh taste and see that Yahweh is good. Blessed is the man who takes refuge in him.",
                "Oh fear Yahweh, you his saints, for there is no lack with those who fear him.",
                "The young lions do lack, and suffer hunger, but those who seek Yahweh shall not lack any good thing.",
                "Come, you children, listen to me. I will teach you the fear of Yahweh.",
                "Who is someone who desires life, and loves many days, that he may see good?",
                "Keep your tongue from evil, and your lips from speaking lies.",
                "Depart from evil, and do good. seek peace, and pursue it.",
                "Yahweh\u2019s eyes are toward the righteous. His ears listen to their cry.",
                "Yahweh\u2019s face is against those who do evil, to cut off their memory from the earth.",
                "The righteous cry, and Yahweh hears, and delivers them out of all their troubles.",
                "Yahweh is near to those who have a broken heart, and saves those who have a crushed spirit.",
                "Many are the afflictions of the righteous, but Yahweh delivers him out of them all.",
                "He protects all of his bones. Not one of them is broken.",
                "Evil shall kill the wicked. Those who hate the righteous shall be condemned.",
                "Yahweh redeems the soul of his servants. None of those who take refuge in him shall be condemned."
            ],
            35: [
                "By David. Contend, Yahweh, with those who contend with me. Fight against those who fight against me.",
                "Take hold of shield and buckler, and stand up for my help.",
                "Brandish the spear and block those who pursue me. Tell my soul, \"I am your salvation.\"",
                "Let those who seek after my soul be disappointed and brought to dishonor. Let those who plot my ruin be turned back and confounded.",
                "Let them be as chaff before the wind, Yahweh\u2019s angel driving them on.",
                "Let their way be dark and slippery, Yahweh\u2019s angel pursuing them.",
                "For without cause they have hidden their net in a pit for me. Without cause they have dug a pit for my soul.",
                "Let destruction come on him unawares. Let his net that he has hidden catch himself. Let him fall into that destruction.",
                "My soul shall be joyful in Yahweh. It shall rejoice in his salvation.",
                "All my bones shall say, \"Yahweh, who is like you, who delivers the poor from him who is too strong for him; yes, the poor and the needy from him who robs him?\"",
                "Unrighteous witnesses rise up. They ask me about things that I don\u2019t know about.",
                "They reward me evil for good, to the bereaving of my soul.",
                "But as for me, when they were sick, my clothing was sackcloth. I afflicted my soul with fasting. My prayer returned into my own bosom.",
                "I behaved myself as though it had been my friend or my brother. I bowed down mourning, as one who mourns his mother.",
                "But in my adversity, they rejoiced, and gathered themselves together. The attackers gathered themselves together against me, and I didn\u2019t know it. They tore at me, and didn\u2019t cease.",
                "Like the profane mockers in feasts, they gnashed their teeth at me.",
                "Lord, how long will you look on? Rescue my soul from their destruction, my precious life from the lions.",
                "I will give you thanks in the great assembly. I will praise you among many people.",
                "Don\u2019t let those who are my enemies wrongfully rejoice over me; neither let those who hate me without a cause wink their eyes.",
                "For they don\u2019t speak peace, but they devise deceitful words against those who are quiet in the land.",
                "Yes, they opened their mouth wide against me. They said, \"Aha! Aha! Our eye has seen it!\"",
                "You have seen it, Yahweh. Don\u2019t keep silent. Lord, don\u2019t be far from me.",
                "Wake up! Rise up to defend me, my God! My Lord, contend for me!",
                "Vindicate me, Yahweh my God, according to your righteousness. Don\u2019t let them gloat over me.",
                "Don\u2019t let them say in their heart, \"Aha! That\u2019s the way we want it!\" Don\u2019t let them say, \"We have swallowed him up!\"",
                "Let them be disappointed and confounded together who rejoice at my calamity. Let them be clothed with shame and dishonor who magnify themselves against me.",
                "Let them shout for joy and be glad, who favor my righteous cause. Yes, let them say continually, \"Yahweh be magnified, who has pleasure in the prosperity of his servant!\"",
                "My tongue shall talk about your righteousness and about your praise all day long."
            ],
            36: [
                "For the Chief Musician. By David, the servant of Yahweh. An oracle is within my heart about the disobedience of the wicked: \"There is no fear of God before his eyes.\"",
                "For he flatters himself in his own eyes, too much to detect and hate his sin.",
                "The words of his mouth are iniquity and deceit. He has ceased to be wise and to do good.",
                "He plots iniquity on his bed. He sets himself in a way that is not good. He doesn\u2019t abhor evil.",
                "Your loving kindness, Yahweh, is in the heavens. Your faithfulness reaches to the skies.",
                "Your righteousness is like the mountains of God. Your judgments are like a great deep. Yahweh, you preserve man and animal.",
                "How precious is your loving kindness, God! The children of men take refuge under the shadow of your wings.",
                "They shall be abundantly satisfied with the abundance of your house. You will make them drink of the river of your pleasures.",
                "For with you is the spring of life. In your light shall we see light.",
                "Oh continue your loving kindness to those who know you, your righteousness to the upright in heart.",
                "Don\u2019t let the foot of pride come against me. Don\u2019t let the hand of the wicked drive me away.",
                "There the workers of iniquity are fallen. They are thrust down, and shall not be able to rise."
            ],
            37: [
                "By David. Don\u2019t fret because of evildoers, neither be envious against those who work unrighteousness.",
                "For they shall soon be cut down like the grass, and wither like the green herb.",
                "Trust in Yahweh, and do good. Dwell in the land, and enjoy safe pasture.",
                "Also delight yourself in Yahweh, and he will give you the desires of your heart.",
                "Commit your way to Yahweh. Trust also in him, and he will do this:",
                "he will make your righteousness go forth as the light, and your justice as the noon day sun.",
                "Rest in Yahweh, and wait patiently for him. Don\u2019t fret because of him who prospers in his way, because of the man who makes wicked plots happen.",
                "Cease from anger, and forsake wrath. Don\u2019t fret, it leads only to evildoing.",
                "For evildoers shall be cut off, but those who wait for Yahweh shall inherit the land.",
                "For yet a little while, and the wicked will be no more. Yes, though you look for his place, he isn\u2019t there.",
                "But the humble shall inherit the land, and shall delight themselves in the abundance of peace.",
                "The wicked plots against the just, and gnashes at him with his teeth.",
                "The Lord will laugh at him, for he sees that his day is coming.",
                "The wicked have drawn out the sword, and have bent their bow, to cast down the poor and needy, to kill those who are upright in the way.",
                "Their sword shall enter into their own heart. Their bows shall be broken.",
                "Better is a little that the righteous has, than the abundance of many wicked.",
                "For the arms of the wicked shall be broken, but Yahweh upholds the righteous.",
                "Yahweh knows the days of the perfect. Their inheritance shall be forever.",
                "They shall not be disappointed in the time of evil. In the days of famine they shall be satisfied.",
                "But the wicked shall perish. The enemies of Yahweh shall be like the beauty of the fields. They will vanish\u2014vanish like smoke.",
                "The wicked borrow, and don\u2019t pay back, but the righteous give generously.",
                "For such as are blessed by him shall inherit the land. Those who are cursed by him shall be cut off.",
                "A man\u2019s goings are established by Yahweh. He delights in his way.",
                "Though he stumble, he shall not fall, for Yahweh holds him up with his hand.",
                "I have been young, and now am old, yet I have not seen the righteous forsaken, nor his children begging for bread.",
                "All day long he deals graciously, and lends. His seed is blessed.",
                "Depart from evil, and do good. Live securely forever.",
                "For Yahweh loves justice, and doesn\u2019t forsake his saints. They are preserved forever, but the children of the wicked shall be cut off.",
                "The righteous shall inherit the land, and live in it forever.",
                "The mouth of the righteous talks of wisdom. His tongue speaks justice.",
                "The law of his God is in his heart. None of his steps shall slide.",
                "The wicked watches the righteous, and seeks to kill him.",
                "Yahweh will not leave him in his hand, nor condemn him when he is judged.",
                "Wait for Yahweh, and keep his way, and he will exalt you to inherit the land. When the wicked are cut off, you shall see it.",
                "I have seen the wicked in great power, spreading himself like a green tree in its native soil.",
                "But he passed away, and behold, he was not. Yes, I sought him, but he could not be found.",
                "Mark the perfect man, and see the upright, for there is a future for the man of peace.",
                "As for transgressors, they shall be destroyed together. The future of the wicked shall be cut off.",
                "But the salvation of the righteous is from Yahweh. He is their stronghold in the time of trouble.",
                "Yahweh helps them, and rescues them. He rescues them from the wicked, and saves them, Because they have taken refuge in him."
            ],
            38: [
                "A Psalm by David, for a memorial. Yahweh, don\u2019t rebuke me in your wrath, neither chasten me in your hot displeasure.",
                "For your arrows have pierced me, your hand presses hard on me.",
                "There is no soundness in my flesh because of your indignation, neither is there any health in my bones because of my sin.",
                "For my iniquities have gone over my head. As a heavy burden, they are too heavy for me.",
                "My wounds are loathsome and corrupt, because of my foolishness.",
                "I am pained and bowed down greatly. I go mourning all day long.",
                "For my waist is filled with burning. There is no soundness in my flesh.",
                "I am faint and severely bruised. I have groaned by reason of the anguish of my heart.",
                "Lord, all my desire is before you. My groaning is not hidden from you.",
                "My heart throbs. My strength fails me. As for the light of my eyes, it has also left me.",
                "My lovers and my friends stand aloof from my plague. My kinsmen stand far away.",
                "They also who seek after my life lay snares. Those who seek my hurt speak mischievous things, and meditate deceits all day long.",
                "But I, as a deaf man, don\u2019t hear. I am as a mute man who doesn\u2019t open his mouth.",
                "Yes, I am as a man who doesn\u2019t hear, in whose mouth are no reproofs.",
                "For in you, Yahweh, do I hope. You will answer, Lord my God.",
                "For I said, \"Don\u2019t let them gloat over me, or exalt themselves over me when my foot slips.\"",
                "For I am ready to fall. My pain is continually before me.",
                "For I will declare my iniquity. I will be sorry for my sin.",
                "But my enemies are vigorous and many. Those who hate me without reason are numerous.",
                "They who also render evil for good are adversaries to me, because I follow what is good.",
                "Don\u2019t forsake me, Yahweh. My God, don\u2019t be far from me.",
                "Hurry to help me, Lord, my salvation."
            ],
            39: [
                "For the Chief Musician. For Jeduthun. A Psalm by David. I said, \"I will watch my ways, so that I don\u2019t sin with my tongue. I will keep my mouth with a bridle while the wicked is before me.\"",
                "I was mute with silence. I held my peace, even from good. My sorrow was stirred.",
                "My heart was hot within me. While I meditated, the fire burned: I spoke with my tongue:",
                "\"Yahweh, show me my end, what is the measure of my days. Let me know how frail I am.",
                "Behold, you have made my days handbreadths. My lifetime is as nothing before you. Surely every man stands as a breath.\" Selah.",
                "\"Surely every man walks like a shadow. Surely they busy themselves in vain. He heaps up, and doesn\u2019t know who shall gather.",
                "Now, Lord, what do I wait for? My hope is in you.",
                "Deliver me from all my transgressions. Don\u2019t make me the reproach of the foolish.",
                "I was mute. I didn\u2019t open my mouth, because you did it.",
                "Remove your scourge away from me. I am overcome by the blow of your hand.",
                "When you rebuke and correct man for iniquity, You consume his wealth like a moth. Surely every man is but a breath.\" Selah.",
                "\"Hear my prayer, Yahweh, and give ear to my cry. Don\u2019t be silent at my tears. For I am a stranger with you, a foreigner, as all my fathers were.",
                "Oh spare me, that I may recover strength, before I go away, and exist no more.\""
            ],
            40: [
                "For the Chief Musician. A Psalm by David. I waited patiently for Yahweh. He turned to me, and heard my cry.",
                "He brought me up also out of a horrible pit, out of the miry clay. He set my feet on a rock, and gave me a firm place to stand.",
                "He has put a new song in my mouth, even praise to our God. Many shall see it, and fear, and shall trust in Yahweh.",
                "Blessed is the man who makes Yahweh his trust, and doesn\u2019t respect the proud, nor such as turn aside to lies.",
                "Many, Yahweh, my God, are the wonderful works which you have done, and your thoughts which are toward us. They can\u2019t be declared back to you. If I would declare and speak of them, they are more than can be numbered.",
                "Sacrifice and offering you didn\u2019t desire. You have opened my ears. You have not required burnt offering and sin offering.",
                "Then I said, \"Behold, I have come. It is written about me in the book in the scroll.",
                "I delight to do your will, my God. Yes, your law is within my heart.\"",
                "I have proclaimed glad news of righteousness in the great assembly. Behold, I will not seal my lips, Yahweh, you know.",
                "I have not hidden your righteousness within my heart. I have declared your faithfulness and your salvation. I have not concealed your loving kindness and your truth from the great assembly.",
                "Don\u2019t withhold your tender mercies from me, Yahweh. Let your loving kindness and your truth continually preserve me.",
                "For innumerable evils have surrounded me. My iniquities have overtaken me, so that I am not able to look up. They are more than the hairs of my head. My heart has failed me.",
                "Be pleased, Yahweh, to deliver me. Hurry to help me, Yahweh.",
                "Let them be disappointed and confounded together who seek after my soul to destroy it. Let them be turned backward and brought to dishonor who delight in my hurt.",
                "Let them be desolate by reason of their shame that tell me, \"Aha! Aha!\"",
                "Let all those who seek you rejoice and be glad in you. Let such as love your salvation say continually, \"Let Yahweh be exalted!\"",
                "But I am poor and needy. May the Lord think about me. You are my help and my deliverer. Don\u2019t delay, my God."
            ],
            41: [
                "For the Chief Musician. A Psalm by David. Blessed is he who considers the poor. Yahweh will deliver him in the day of evil.",
                "Yahweh will preserve him, and keep him alive. He shall be blessed on the earth, and he will not surrender him to the will of his enemies.",
                "Yahweh will sustain him on his sickbed, and restore him from his bed of illness.",
                "I said, \"Yahweh, have mercy on me! Heal me, for I have sinned against you.\"",
                "My enemies speak evil against me: \"When will he die, and his name perish?\"",
                "If he comes to see me, he speaks falsehood. His heart gathers iniquity to itself. When he goes abroad, he tells it.",
                "All who hate me whisper together against me. They imagine the worst for me.",
                "\"An evil disease,\" they say, \"has afflicted him. Now that he lies he shall rise up no more.\"",
                "Yes, my own familiar friend, in whom I trusted, who ate bread with me, has lifted up his heel against me.",
                "But you, Yahweh, have mercy on me, and raise me up, that I may repay them.",
                "By this I know that you delight in me, because my enemy doesn\u2019t triumph over me.",
                "As for me, you uphold me in my integrity, and set me in your presence forever.",
                "Blessed be Yahweh, the God of Israel, from everlasting and to everlasting! Amen and amen."
            ],
            42: [
                "For the Chief Musician. A contemplation by the sons of Korah. As the deer pants for the water brooks, so my soul pants after you, God.",
                "My soul thirsts for God, for the living God. When shall I come and appear before God?",
                "My tears have been my food day and night, while they continually ask me, \"Where is your God?\"",
                "These things I remember, and pour out my soul within me, how I used to go with the crowd, and led them to the house of God, with the voice of joy and praise, a multitude keeping a holy day.",
                "Why are you in despair, my soul? Why are you disturbed within me? Hope in God! For I shall still praise him for the saving help of his presence.",
                "My God, my soul is in despair within me. Therefore I remember you from the land of the Jordan, the heights of Hermon, from the hill Mizar.",
                "Deep calls to deep at the noise of your waterfalls. All your waves and your billows have swept over me.",
                "Yahweh will command his loving kindness in the daytime. In the night his song shall be with me: a prayer to the God of my life.",
                "I will ask God, my rock, \"Why have you forgotten me? Why do I go mourning because of the oppression of the enemy?\"",
                "As with a sword in my bones, my adversaries reproach me, while they continually ask me, \"Where is your God?\"",
                "Why are you in despair, my soul? Why are you disturbed within me? Hope in God! For I shall still praise him, the saving help of my countenance, and my God."
            ],
            43: [
                "Vindicate me, God, and plead my cause against an ungodly nation. Oh, deliver me from deceitful and wicked men.",
                "For you are the God of my strength. Why have you rejected me? Why do I go mourning because of the oppression of the enemy?",
                "Oh, send out your light and your truth. Let them lead me. Let them bring me to your holy hill, To your tents.",
                "Then I will go to the altar of God, to God, my exceeding joy. I will praise you on the harp, God, my God.",
                "Why are you in despair, my soul? Why are you disturbed within me? Hope in God! For I shall still praise him: my Savior, my helper, and my God."
            ],
            44: [
                "For the Chief Musician. By the sons of Korah. A contemplative psalm. We have heard with our ears, God; our fathers have told us, what work you did in their days, in the days of old.",
                "You drove out the nations with your hand, but you planted them. You afflicted the peoples, but you spread them abroad.",
                "For they didn\u2019t get the land in possession by their own sword, neither did their own arm save them; but your right hand, and your arm, and the light of your face, because you were favorable to them.",
                "You are my King, God. Command victories for Jacob!",
                "Through you, will we push down our adversaries. Through your name, will we tread them under who rise up against us.",
                "For I will not trust in my bow, neither shall my sword save me.",
                "But you have saved us from our adversaries, and have shamed those who hate us.",
                "In God we have made our boast all day long, we will give thanks to your name forever. Selah.",
                "But now you rejected us, and brought us to dishonor, and don\u2019t go out with our armies.",
                "You make us turn back from the adversary. Those who hate us take spoil for themselves.",
                "You have made us like sheep for food, and have scattered us among the nations.",
                "You sell your people for nothing, and have gained nothing from their sale.",
                "You make us a reproach to our neighbors, a scoffing and a derision to those who are around us.",
                "You make us a byword among the nations, a shaking of the head among the peoples.",
                "All day long my dishonor is before me, and shame covers my face,",
                "At the taunt of one who reproaches and verbally abuses, because of the enemy and the avenger.",
                "All this has come on us, yet have we not forgotten you, Neither have we been false to your covenant.",
                "Our heart has not turned back, neither have our steps strayed from your path,",
                "Though you have crushed us in the haunt of jackals, and covered us with the shadow of death.",
                "If we have forgotten the name of our God, or spread forth our hands to a strange god;",
                "won\u2019t God search this out? For he knows the secrets of the heart.",
                "Yes, for your sake we are killed all day long. We are regarded as sheep for the slaughter.",
                "Wake up! Why do you sleep, Lord? Arise! Don\u2019t reject us forever.",
                "Why do you hide your face, and forget our affliction and our oppression?",
                "For our soul is bowed down to the dust. Our body clings to the earth.",
                "Rise up to help us. Redeem us for your loving kindness\u2019 sake."
            ],
            45: [
                "For the Chief Musician. Set to \"The Lilies.\" A contemplation by the sons of Korah. A wedding song. My heart overflows with a noble theme. I recite my verses for the king. My tongue is like the pen of a skillful writer.",
                "You are the most excellent of the sons of men. Grace has anointed your lips, therefore God has blessed you forever.",
                "Strap your sword on your thigh, mighty one: your splendor and your majesty.",
                "In your majesty ride on victoriously on behalf of truth, humility, and righteousness. Let your right hand display awesome deeds.",
                "Your arrows are sharp. The nations fall under you, with arrows in the heart of the king\u2019s enemies.",
                "Your throne, God, is forever and ever. A scepter of equity is the scepter of your kingdom.",
                "You have loved righteousness, and hated wickedness. Therefore God, your God, has anointed you with the oil of gladness above your fellows.",
                "All your garments smell like myrrh, aloes, and cassia. Out of ivory palaces stringed instruments have made you glad.",
                "Kings\u2019 daughters are among your honorable women. At your right hand the queen stands in gold of Ophir.",
                "Listen, daughter, consider, and turn your ear. Forget your own people, and also your father\u2019s house.",
                "So the king will desire your beauty, honor him, for he is your lord.",
                "The daughter of Tyre comes with a gift. The rich among the people entreat your favor.",
                "The princess inside is all glorious. Her clothing is interwoven with gold.",
                "She shall be led to the king in embroidered work. The virgins, her companions who follow her, shall be brought to you.",
                "With gladness and rejoicing they shall be led. They shall enter into the king\u2019s palace.",
                "Your sons will take the place of your fathers. You shall make them princes in all the earth.",
                "I will make your name to be remembered in all generations. Therefore the peoples shall give you thanks forever and ever."
            ],
            46: [
                "For the Chief Musician. By the sons of Korah. According to Alamoth. God is our refuge and strength, a very present help in trouble.",
                "Therefore we won\u2019t be afraid, though the earth changes, though the mountains are shaken into the heart of the seas;",
                "though its waters roar and are troubled, though the mountains tremble with their swelling. Selah.",
                "There is a river, the streams of which make the city of God glad, the holy place of the tents of the Most High.",
                "God is in her midst. She shall not be moved. God will help her at dawn.",
                "The nations raged. The kingdoms were moved. He lifted his voice, and the earth melted.",
                "Yahweh of Armies is with us. The God of Jacob is our refuge. Selah.",
                "Come, see Yahweh\u2019s works, what desolations he has made in the earth.",
                "He makes wars cease to the end of the earth. He breaks the bow, and shatters the spear. He burns the chariots in the fire.",
                "\"Be still, and know that I am God. I will be exalted among the nations. I will be exalted in the earth.\"",
                "Yahweh of Armies is with us. The God of Jacob is our refuge. Selah."
            ],
            47: [
                "For the Chief Musician. A Psalm by the sons of Korah. Oh clap your hands, all you nations. Shout to God with the voice of triumph!",
                "For Yahweh Most High is awesome. He is a great King over all the earth.",
                "He subdues nations under us, and peoples under our feet.",
                "He chooses our inheritance for us, the glory of Jacob whom he loved. Selah.",
                "God has gone up with a shout, Yahweh with the sound of a trumpet.",
                "Sing praise to God, sing praises. Sing praises to our King, sing praises.",
                "For God is the King of all the earth. Sing praises with understanding.",
                "God reigns over the nations. God sits on his holy throne.",
                "The princes of the peoples are gathered together, the people of the God of Abraham. For the shields of the earth belong to God. He is greatly exalted!"
            ],
            48: [
                "A Song. A Psalm by the sons of Korah. Great is Yahweh, and greatly to be praised, in the city of our God, in his holy mountain.",
                "Beautiful in elevation, the joy of the whole earth, is Mount Zion, on the north sides, the city of the great King.",
                "God has shown himself in her citadels as a refuge.",
                "For, behold, the kings assembled themselves, they passed by together.",
                "They saw it, then they were amazed. They were dismayed. They hurried away.",
                "Trembling took hold of them there, pain, as of a woman in travail.",
                "With the east wind, you break the ships of Tarshish.",
                "As we have heard, so we have seen, in the city of Yahweh of Armies, in the city of our God. God will establish it forever. Selah.",
                "We have thought about your loving kindness, God, in the midst of your temple.",
                "As is your name, God, so is your praise to the ends of the earth. Your right hand is full of righteousness.",
                "Let Mount Zion be glad! Let the daughters of Judah rejoice, Because of your judgments.",
                "Walk about Zion, and go around her. Number its towers.",
                "Mark well her bulwarks. Consider her palaces, that you may tell it to the next generation.",
                "For this God is our God forever and ever. He will be our guide even to death."
            ],
            49: [
                "For the Chief Musician. A Psalm by the sons of Korah. Hear this, all you peoples. Listen, all you inhabitants of the world,",
                "both low and high, rich and poor together.",
                "My mouth will speak words of wisdom. My heart shall utter understanding.",
                "I will incline my ear to a proverb. I will open my riddle on the harp.",
                "Why should I fear in the days of evil, when iniquity at my heels surrounds me?",
                "Those who trust in their wealth, and boast in the multitude of their riches\u2014",
                "none of them can by any means redeem his brother, nor give God a ransom for him.",
                "For the redemption of their life is costly, no payment is ever enough,",
                "That he should live on forever, that he should not see corruption.",
                "For he sees that wise men die; likewise the fool and the senseless perish, and leave their wealth to others.",
                "Their inward thought is that their houses will endure forever, and their dwelling places to all generations. They name their lands after themselves.",
                "But man, despite his riches, doesn\u2019t endure. He is like the animals that perish.",
                "This is the destiny of those who are foolish, and of those who approve their sayings. Selah.",
                "They are appointed as a flock for Sheol. Death shall be their shepherd. The upright shall have dominion over them in the morning. Their beauty shall decay in Sheol, far from their mansion.",
                "But God will redeem my soul from the power of Sheol, for he will receive me. Selah.",
                "Don\u2019t be afraid when a man is made rich, when the glory of his house is increased.",
                "For when he dies he shall carry nothing away. His glory shall not descend after him.",
                "Though while he lived he blessed his soul\u2014and men praise you when you do well for yourself\u2014",
                "he shall go to the generation of his fathers. They shall never see the light.",
                "A man who has riches without understanding, is like the animals that perish."
            ],
            50: [
                "A Psalm by Asaph. The Mighty One, God, Yahweh, speaks, and calls the earth from sunrise to sunset.",
                "Out of Zion, the perfection of beauty, God shines forth.",
                "Our God comes, and does not keep silent. A fire devours before him. It is very stormy around him.",
                "He calls to the heavens above, to the earth, that he may judge his people:",
                "\"Gather my saints together to me, those who have made a covenant with me by sacrifice.\"",
                "The heavens shall declare his righteousness, for God himself is judge. Selah.",
                "\"Hear, my people, and I will speak; Israel, and I will testify against you. I am God, your God.",
                "I don\u2019t rebuke you for your sacrifices. Your burnt offerings are continually before me.",
                "I have no need for a bull from your stall, nor male goats from your pens.",
                "For every animal of the forest is mine, and the livestock on a thousand hills.",
                "I know all the birds of the mountains. The wild animals of the field are mine.",
                "If I were hungry, I would not tell you, for the world is mine, and all that is in it.",
                "Will I eat the flesh of bulls, or drink the blood of goats?",
                "Offer to God the sacrifice of thanksgiving. Pay your vows to the Most High.",
                "Call on me in the day of trouble. I will deliver you, and you will honor me.\"",
                "But to the wicked God says, \"What right do you have to declare my statutes, that you have taken my covenant on your lips,",
                "since you hate instruction, and throw my words behind you?",
                "When you saw a thief, you consented with him, and have participated with adulterers.",
                "\"You give your mouth to evil. Your tongue frames deceit.",
                "You sit and speak against your brother. You slander your own mother\u2019s son.",
                "You have done these things, and I kept silent. You thought that I was just like you. I will rebuke you, and accuse you in front of your eyes.",
                "\"Now consider this, you who forget God, lest I tear you into pieces, and there be none to deliver.",
                "Whoever offers the sacrifice of thanksgiving glorifies me, and prepares his way so that I will show God\u2019s salvation to him.\""
            ],
            51: [
                "For the Chief Musician. A Psalm by David, when Nathan the prophet came to him, after he had gone in to Bathsheba. Have mercy on me, God, according to your loving kindness. According to the multitude of your tender mercies, blot out my transgressions.",
                "Wash me thoroughly from my iniquity. Cleanse me from my sin.",
                "For I know my transgressions. My sin is constantly before me.",
                "Against you, and you only, have I sinned, and done that which is evil in your sight; that you may be proved right when you speak, and justified when you judge.",
                "Behold, I was brought forth in iniquity. In sin my mother conceived me.",
                "Behold, you desire truth in the inward parts. You teach me wisdom in the inmost place.",
                "Purify me with hyssop, and I will be clean. Wash me, and I will be whiter than snow.",
                "Let me hear joy and gladness, That the bones which you have broken may rejoice.",
                "Hide your face from my sins, and blot out all of my iniquities.",
                "Create in me a clean heart, O God. Renew a right spirit within me.",
                "Don\u2019t throw me from your presence, and don\u2019t take your holy Spirit from me.",
                "Restore to me the joy of your salvation. Uphold me with a willing spirit.",
                "Then I will teach transgressors your ways. Sinners shall be converted to you.",
                "Deliver me from bloodguiltiness, O God, the God of my salvation. My tongue shall sing aloud of your righteousness.",
                "Lord, open my lips. My mouth shall declare your praise.",
                "For you don\u2019t delight in sacrifice, or else I would give it. You have no pleasure in burnt offering.",
                "The sacrifices of God are a broken spirit. A broken and contrite heart, O God, you will not despise.",
                "Do well in your good pleasure to Zion. Build the walls of Jerusalem.",
                "Then you will delight in the sacrifices of righteousness, in burnt offerings and in whole burnt offerings. Then they will offer bulls on your altar."
            ],
            52: [
                "For the Chief Musician. A contemplation by David, when Doeg the Edomite came and told Saul, \"David has come to Abimelech\u2019s house.\" Why do you boast of mischief, mighty man? God\u2019s loving kindness endures continually.",
                "Your tongue plots destruction, like a sharp razor, working deceitfully.",
                "You love evil more than good, lying rather than speaking the truth. Selah.",
                "You love all devouring words, you deceitful tongue.",
                "God will likewise destroy you forever. He will take you up, and pluck you out of your tent, and root you out of the land of the living. Selah.",
                "The righteous also will see it, and fear, and laugh at him, saying,",
                "\"Behold, this is the man who didn\u2019t make God his strength, but trusted in the abundance of his riches, and strengthened himself in his wickedness.\"",
                "But as for me, I am like a green olive tree in God\u2019s house. I trust in God\u2019s loving kindness forever and ever.",
                "I will give you thanks forever, because you have done it. I will hope in your name, for it is good, in the presence of your saints."
            ],
            53: [
                "For the Chief Musician. To the tune of \"Mahalath.\" A contemplation by David. The fool has said in his heart, \"There is no God.\" They are corrupt, and have done abominable iniquity. There is no one who does good.",
                "God looks down from heaven on the children of men, to see if there are any who understood, who seek after God.",
                "Every one of them has gone back. They have become filthy together. There is no one who does good, no, not one.",
                "Have the workers of iniquity no knowledge, who eat up my people as they eat bread, and don\u2019t call on God?",
                "There they were in great fear, where no fear was, for God has scattered the bones of him who encamps against you. You have put them to shame, because God has rejected them.",
                "Oh that the salvation of Israel would come out of Zion! When God brings back his people from captivity, then Jacob shall rejoice, and Israel shall be glad."
            ],
            54: [
                "For the Chief Musician. On stringed instruments. A contemplation by David, when the Ziphites came and said to Saul, \"Isn\u2019t David hiding himself among us?\" Save me, God, by your name. Vindicate me in your might.",
                "Hear my prayer, God. Listen to the words of my mouth.",
                "For strangers have risen up against me. Violent men have sought after my soul. They haven\u2019t set God before them. Selah.",
                "Behold, God is my helper. The Lord is the one who sustains my soul.",
                "He will repay the evil to my enemies. Destroy them in your truth.",
                "With a free will offering, I will sacrifice to you. I will give thanks to your name, Yahweh, for it is good.",
                "For he has delivered me out of all trouble. My eye has seen triumph over my enemies."
            ],
            55: [
                "For the Chief Musician. On stringed instruments. A contemplation by David. Listen to my prayer, God. Don\u2019t hide yourself from my supplication.",
                "Attend to me, and answer me. I am restless in my complaint, and moan,",
                "Because of the voice of the enemy, Because of the oppression of the wicked. For they bring suffering on me. In anger they hold a grudge against me.",
                "My heart is severely pained within me. The terrors of death have fallen on me.",
                "Fearfulness and trembling have come on me. Horror has overwhelmed me.",
                "I said, \"Oh that I had wings like a dove! Then I would fly away, and be at rest.",
                "Behold, then I would wander far off. I would lodge in the wilderness.\" Selah.",
                "\"I would hurry to a shelter from the stormy wind and storm.\"",
                "Confuse them, Lord, and confound their language, for I have seen violence and strife in the city.",
                "Day and night they prowl around on its walls. Malice and abuse are also within her.",
                "Destructive forces are within her. Threats and lies don\u2019t depart from her streets.",
                "For it was not an enemy who insulted me, then I could have endured it. Neither was it he who hated me who raised himself up against me, then I would have hidden myself from him.",
                "But it was you, a man like me, my companion, and my familiar friend.",
                "We took sweet fellowship together. We walked in God\u2019s house with company.",
                "Let death come suddenly on them. Let them go down alive into Sheol. For wickedness is in their dwelling, in their midst.",
                "As for me, I will call on God. Yahweh will save me.",
                "Evening, morning, and at noon, I will cry out in distress. He will hear my voice.",
                "He has redeemed my soul in peace from the battle that was against me, although there are many who oppose me.",
                "God, who is enthroned forever, will hear, and answer them. Selah. They never change, who don\u2019t fear God.",
                "He raises his hands against his friends. He has violated his covenant.",
                "His mouth was smooth as butter, but his heart was war. His words were softer than oil, yet they were drawn swords.",
                "Cast your burden on Yahweh, and he will sustain you. He will never allow the righteous to be moved.",
                "But you, God, will bring them down into the pit of destruction. Bloodthirsty and deceitful men shall not live out half their days, but I will trust in you."
            ],
            56: [
                "For the Chief Musician. To the tune of \"Silent Dove in Distant Lands.\" A poem by David, when the Philistines seized him in Gath. Be merciful to me, God, for man wants to swallow me up. All day long, he attacks and oppresses me.",
                "My enemies want to swallow me up all day long, for they are many who fight proudly against me.",
                "When I am afraid, I will put my trust in you.",
                "In God, I praise his word. In God, I put my trust. I will not be afraid. What can flesh do to me?",
                "All day long they twist my words. All their thoughts are against me for evil.",
                "They conspire and lurk, watching my steps, they are eager to take my life.",
                "Shall they escape by iniquity? In anger cast down the peoples, God.",
                "You number my wanderings. You put my tears into your bottle. Aren\u2019t they in your book?",
                "Then my enemies shall turn back in the day that I call. I know this, that God is for me.",
                "In God, I will praise his word. In Yahweh, I will praise his word.",
                "I have put my trust in God. I will not be afraid. What can man do to me?",
                "Your vows are on me, God. I will give thank offerings to you.",
                "For you have delivered my soul from death, and prevented my feet from falling, that I may walk before God in the light of the living."
            ],
            57: [
                "For the Chief Musician. To the tune of \"Do Not Destroy.\" A poem by David, when he fled from Saul, in the cave. Be merciful to me, God, be merciful to me, for my soul takes refuge in you. Yes, in the shadow of your wings, I will take refuge, until disaster has passed.",
                "I cry out to God Most High, to God who accomplishes my requests for me.",
                "He will send from heaven, and save me, he rebukes the one who is pursuing me. Selah. God will send out his loving kindness and his truth.",
                "My soul is among lions. I lie among those who are set on fire, even the sons of men, whose teeth are spears and arrows, and their tongue a sharp sword.",
                "Be exalted, God, above the heavens! Let your glory be above all the earth!",
                "They have prepared a net for my steps. My soul is bowed down. They dig a pit before me. They fall into its midst themselves. Selah.",
                "My heart is steadfast, God, my heart is steadfast. I will sing, yes, I will sing praises.",
                "Wake up, my glory! Wake up, psaltery and harp! I will wake up the dawn.",
                "I will give thanks to you, Lord, among the peoples. I will sing praises to you among the nations.",
                "For your great loving kindness reaches to the heavens, and your truth to the skies.",
                "Be exalted, God, above the heavens. Let your glory be over all the earth."
            ],
            58: [
                "For the Chief Musician. To the tune of \"Do Not Destroy.\" A poem by David. Do you indeed speak righteousness, silent ones? Do you judge blamelessly, you sons of men?",
                "No, in your heart you plot injustice. You measure out the violence of your hands in the earth.",
                "The wicked go astray from the womb. They are wayward as soon as they are born, speaking lies.",
                "Their poison is like the poison of a snake; like a deaf cobra that stops its ear,",
                "which doesn\u2019t listen to the voice of charmers, no matter how skillful the charmer may be.",
                "Break their teeth, God, in their mouth. Break out the great teeth of the young lions, Yahweh.",
                "Let them vanish as water that flows away. When they draw the bow, let their arrows be made blunt.",
                "Let them be like a snail which melts and passes away, like the stillborn child, who has not seen the sun.",
                "Before your pots can feel the heat of the thorns, he will sweep away the green and the burning alike.",
                "The righteous shall rejoice when he sees the vengeance. He shall wash his feet in the blood of the wicked;",
                "so that men shall say, \"Most certainly there is a reward for the righteous. Most certainly there is a God who judges the earth.\""
            ],
            59: [
                "For the Chief Musician. To the tune of \"Do Not Destroy.\" A poem by David, when Saul sent, and they watched the house to kill him. Deliver me from my enemies, my God. Set me on high from those who rise up against me.",
                "Deliver me from the workers of iniquity. Save me from the bloodthirsty men.",
                "For, behold, they lie in wait for my soul. The mighty gather themselves together against me, not for my disobedience, nor for my sin, Yahweh.",
                "I have done no wrong, yet they are ready to attack me. Rise up, behold, and help me!",
                "You, Yahweh God of Armies, the God of Israel, rouse yourself to punish the nations. Show no mercy to the wicked traitors. Selah.",
                "They return at evening, howling like dogs, and prowl around the city.",
                "Behold, they spew with their mouth. Swords are in their lips, \"For,\" they say, \"who hears us?\"",
                "But you, Yahweh, laugh at them. You scoff at all the nations.",
                "Oh, my Strength, I watch for you, for God is my high tower.",
                "My God will go before me with his loving kindness. God will let me look at my enemies in triumph.",
                "Don\u2019t kill them, or my people may forget. Scatter them by your power, and bring them down, Lord our shield.",
                "For the sin of their mouth, and the words of their lips, let them be caught in their pride, for the curses and lies which they utter.",
                "Consume them in wrath. Consume them, and they will be no more. Let them know that God rules in Jacob, to the ends of the earth. Selah.",
                "At evening let them return. Let them howl like a dog, and go around the city.",
                "They shall wander up and down for food, and wait all night if they aren\u2019t satisfied.",
                "But I will sing of your strength. Yes, I will sing aloud of your loving kindness in the morning. For you have been my high tower, a refuge in the day of my distress.",
                "To you, my strength, I will sing praises. For God is my high tower, the God of my mercy."
            ],
            60: [
                "For the Chief Musician. To the tune of \"The Lily of the Covenant.\" A teaching poem by David, when he fought with Aram Naharaim and with Aram Zobah, and Joab returned, and killed twelve thousand of Edom in the Valley of Salt. God, you have rejected us. You have broken us down. You have been angry. Restore us, again.",
                "You have made the land tremble. You have torn it. Mend its fractures, for it quakes.",
                "You have shown your people hard things. You have made us drink the wine that makes us stagger.",
                "You have given a banner to those who fear you, that it may be displayed because of the truth. Selah.",
                "So that your beloved may be delivered, save with your right hand, and answer us.",
                "God has spoken from his sanctuary: \"I will triumph. I will divide Shechem, and measure out the valley of Succoth.",
                "Gilead is mine, and Manasseh is mine. Ephraim also is the defense of my head. Judah is my scepter.",
                "Moab is my wash basin. I will throw my shoe on Edom. I shout in triumph over Philistia.\"",
                "Who will bring me into the strong city? Who has led me to Edom?",
                "Haven\u2019t you, God, rejected us? You don\u2019t go out with our armies, God.",
                "Give us help against the adversary, for the help of man is vain.",
                "Through God we shall do valiantly, for it is he who will tread down our adversaries."
            ],
            61: [
                "For the Chief Musician. For a stringed instrument. By David. Hear my cry, God. Listen to my prayer.",
                "From the end of the earth, I will call to you, when my heart is overwhelmed. Lead me to the rock that is higher than I.",
                "For you have been a refuge for me, a strong tower from the enemy.",
                "I will dwell in your tent forever. I will take refuge in the shelter of your wings. Selah.",
                "For you, God, have heard my vows. You have given me the heritage of those who fear your name.",
                "You will prolong the king\u2019s life; his years shall be for generations.",
                "He shall be enthroned in God\u2019s presence forever. Appoint your loving kindness and truth, that they may preserve him.",
                "So I will sing praise to your name forever, that I may fulfill my vows daily."
            ],
            62: [
                "For the Chief Musician. To Jeduthan. A Psalm by David. My soul rests in God alone. My salvation is from him.",
                "He alone is my rock and my salvation, my fortress\u2014I will never be greatly shaken.",
                "How long will you assault a man, would all of you throw him down, Like a leaning wall, like a tottering fence?",
                "They fully intend to throw him down from his lofty place. They delight in lies. They bless with their mouth, but they curse inwardly. Selah.",
                "My soul, wait in silence for God alone, for my expectation is from him.",
                "He alone is my rock and my salvation, my fortress. I will not be shaken.",
                "With God is my salvation and my honor. The rock of my strength, and my refuge, is in God.",
                "Trust in him at all times, you people. Pour out your heart before him. God is a refuge for us. Selah.",
                "Surely men of low degree are just a breath, and men of high degree are a lie. In the balances they will go up. They are together lighter than a breath.",
                "Don\u2019t trust in oppression. Don\u2019t become vain in robbery. If riches increase, don\u2019t set your heart on them.",
                "God has spoken once; twice I have heard this, that power belongs to God.",
                "Also to you, Lord, belongs loving kindness, for you reward every man according to his work."
            ],
            63: [
                "A Psalm by David, when he was in the desert of Judah. God, you are my God. I will earnestly seek you. My soul thirsts for you. My flesh longs for you, in a dry and weary land, where there is no water.",
                "So I have seen you in the sanctuary, watching your power and your glory.",
                "Because your loving kindness is better than life, my lips shall praise you.",
                "So I will bless you while I live. I will lift up my hands in your name.",
                "My soul shall be satisfied as with the richest food. My mouth shall praise you with joyful lips,",
                "when I remember you on my bed, and think about you in the night watches.",
                "For you have been my help. I will rejoice in the shadow of your wings.",
                "My soul stays close to you. Your right hand holds me up.",
                "But those who seek my soul, to destroy it, shall go into the lower parts of the earth.",
                "They shall be given over to the power of the sword. They shall be jackal food.",
                "But the king shall rejoice in God. Everyone who swears by him will praise him, for the mouth of those who speak lies shall be silenced."
            ],
            64: [
                "For the Chief Musician. A Psalm by David. Hear my voice, God, in my complaint. Preserve my life from fear of the enemy.",
                "Hide me from the conspiracy of the wicked, from the noisy crowd of the ones doing evil;",
                "who sharpen their tongue like a sword, and aim their arrows, deadly words,",
                "to shoot innocent men from ambushes. They shoot at him suddenly and fearlessly.",
                "They encourage themselves in evil plans. They talk about laying snares secretly. They say, \"Who will see them?\"",
                "They plot injustice, saying, \"We have made a perfect plan!\" Surely man\u2019s mind and heart are cunning.",
                "But God will shoot at them. They will be suddenly struck down with an arrow.",
                "Their own tongues shall ruin them. All who see them will shake their heads.",
                "All mankind shall be afraid. They shall declare the work of God, and shall wisely ponder what he has done.",
                "The righteous shall be glad in Yahweh, and shall take refuge in him. All the upright in heart shall praise him!"
            ],
            65: [
                "For the Chief Musician. A Psalm by David. A song. Praise waits for you, God, in Zion. To you shall vows be performed.",
                "You who hear prayer, to you all men will come.",
                "Sins overwhelmed me, but you atoned for our transgressions.",
                "Blessed is one whom you choose, and cause to come near, that he may live in your courts. We will be filled with the goodness of your house, your holy temple.",
                "By awesome deeds of righteousness, you answer us, God of our salvation. You who are the hope of all the ends of the earth, of those who are far away on the sea;",
                "Who by his power forms the mountains, having armed yourself with strength;",
                "who stills the roaring of the seas, the roaring of their waves, and the turmoil of the nations.",
                "They also who dwell in faraway places are afraid at your wonders. You call the morning\u2019s dawn and the evening with songs of joy.",
                "You visit the earth, and water it. You greatly enrich it. The river of God is full of water. You provide them grain, for so you have ordained it.",
                "You drench its furrows. You level its ridges. You soften it with showers. You bless it with a crop.",
                "You crown the year with your bounty. Your carts overflow with abundance.",
                "The wilderness grasslands overflow. The hills are clothed with gladness.",
                "The pastures are covered with flocks. The valleys also are clothed with grain. They shout for joy! They also sing."
            ],
            66: [
                "For the Chief Musician. A song. A Psalm. Make a joyful shout to God, all the earth!",
                "Sing to the glory of his name! Offer glory and praise!",
                "Tell God, \"How awesome are your deeds! Through the greatness of your power, your enemies submit themselves to you.",
                "All the earth will worship you, and will sing to you; they will sing to your name.\" Selah.",
                "Come, and see God\u2019s deeds\u2014awesome work on behalf of the children of men.",
                "He turned the sea into dry land. They went through the river on foot. There, we rejoiced in him.",
                "He rules by his might forever. His eyes watch the nations. Don\u2019t let the rebellious rise up against him. Selah.",
                "Praise our God, you peoples! Make the sound of his praise heard,",
                "who preserves our life among the living, and doesn\u2019t allow our feet to be moved.",
                "For you, God, have tested us. You have refined us, as silver is refined.",
                "You brought us into prison. You laid a burden on our backs.",
                "You allowed men to ride over our heads. We went through fire and through water, but you brought us to the place of abundance.",
                "I will come into your temple with burnt offerings. I will pay my vows to you,",
                "which my lips promised, and my mouth spoke, when I was in distress.",
                "I will offer to you burnt offerings of fat animals, with the offering of rams, I will offer bulls with goats. Selah.",
                "Come, and hear, all you who fear God. I will declare what he has done for my soul.",
                "I cried to him with my mouth. He was extolled with my tongue.",
                "If I cherished sin in my heart, the Lord wouldn\u2019t have listened.",
                "But most certainly, God has listened. He has heard the voice of my prayer.",
                "Blessed be God, who has not turned away my prayer, nor his loving kindness from me."
            ],
            67: [
                "For the Chief Musician. With stringed instruments. A Psalm. A song. May God be merciful to us, bless us, and cause his face to shine on us. Selah.",
                "That your way may be known on earth, and your salvation among all nations,",
                "let the peoples praise you, God. Let all the peoples praise you.",
                "Oh let the nations be glad and sing for joy, for you will judge the peoples with equity, and govern the nations on earth. Selah.",
                "Let the peoples praise you, God. Let all the peoples praise you.",
                "The earth has yielded its increase. God, even our own God, will bless us.",
                "God will bless us. All the ends of the earth shall fear him."
            ],
            68: [
                "For the Chief Musician. A Psalm by David. A song. Let God arise! Let his enemies be scattered! Let them who hate him also flee before him.",
                "As smoke is driven away, so drive them away. As wax melts before the fire, so let the wicked perish at the presence of God.",
                "But let the righteous be glad. Let them rejoice before God. Yes, let them rejoice with gladness.",
                "Sing to God! Sing praises to his name! Extol him who rides on the clouds: to Yah, his name! Rejoice before him!",
                "A father of the fatherless, and a defender of the widows, is God in his holy habitation.",
                "God sets the lonely in families. He brings out the prisoners with singing, but the rebellious dwell in a sun-scorched land.",
                "God, when you went forth before your people, when you marched through the wilderness... Selah.",
                "The earth trembled. The sky also poured down rain at the presence of the God of Sinai\u2014at the presence of God, the God of Israel.",
                "You, God, sent a plentiful rain. You confirmed your inheritance, when it was weary.",
                "Your congregation lived therein. You, God, prepared your goodness for the poor.",
                "The Lord announced the word. The ones who proclaim it are a great company.",
                "\"Kings of armies flee! They flee!\" She who waits at home divides the spoil,",
                "while you sleep among the campfires, the wings of a dove sheathed with silver, her feathers with shining gold.",
                "When the Almighty scattered kings in her, it snowed on Zalmon.",
                "The mountains of Bashan are majestic mountains. The mountains of Bashan are rugged.",
                "Why do you look in envy, you rugged mountains, at the mountain where God chooses to reign? Yes, Yahweh will dwell there forever.",
                "The chariots of God are tens of thousands and thousands of thousands. The Lord is among them, from Sinai, into the sanctuary.",
                "You have ascended on high. You have led away captives. You have received gifts among men, yes, among the rebellious also, that Yah God might dwell there.",
                "Blessed be the Lord, who daily bears our burdens, even the God who is our salvation. Selah.",
                "God is to us a God of deliverance. To Yahweh, the Lord, belongs escape from death.",
                "But God will strike through the head of his enemies, the hairy scalp of such a one as still continues in his guiltiness.",
                "The Lord said, \"I will bring you again from Bashan, I will bring you again from the depths of the sea;",
                "That you may crush them, dipping your foot in blood, that the tongues of your dogs may have their portion from your enemies.\"",
                "They have seen your processions, God, even the processions of my God, my King, into the sanctuary.",
                "The singers went before, the minstrels followed after, in the midst of the ladies playing with tambourines,",
                "\"Bless God in the congregations, even the Lord in the assembly of Israel!\"",
                "There is little Benjamin, their ruler, the princes of Judah, their council, the princes of Zebulun, and the princes of Naphtali.",
                "Your God has commanded your strength. Strengthen, God, that which you have done for us.",
                "Because of your temple at Jerusalem, kings shall bring presents to you.",
                "Rebuke the wild animal of the reeds, the multitude of the bulls, with the calves of the peoples. Being humbled, may it bring bars of silver. Scatter the nations that delight in war.",
                "Princes shall come out of Egypt. Ethiopia shall hurry to stretch out her hands to God.",
                "Sing to God, you kingdoms of the earth! Sing praises to the Lord! Selah.",
                "To him who rides on the heaven of heavens, which are of old; behold, he utters his voice, a mighty voice.",
                "Ascribe strength to God! His excellency is over Israel, his strength is in the skies.",
                "You are awesome, God, in your sanctuaries. The God of Israel gives strength and power to his people. Praise be to God!"
            ],
            69: [
                "For the Chief Musician. To the tune of \"Lilies.\" By David. Save me, God, for the waters have come up to my neck!",
                "I sink in deep mire, where there is no foothold. I have come into deep waters, where the floods overflow me.",
                "I am weary with my crying. My throat is dry. My eyes fail, looking for my God.",
                "Those who hate me without a cause are more than the hairs of my head. Those who want to cut me off, being my enemies wrongfully, are mighty. I have to restore what I didn\u2019t take away.",
                "God, you know my foolishness. My sins aren\u2019t hidden from you.",
                "Don\u2019t let those who wait for you be shamed through me, Lord Yahweh of Armies. Don\u2019t let those who seek you be brought to dishonor through me, God of Israel.",
                "Because for your sake, I have borne reproach. Shame has covered my face.",
                "I have become a stranger to my brothers, an alien to my mother\u2019s children.",
                "For the zeal of your house consumes me. The reproaches of those who reproach you have fallen on me.",
                "When I wept and I fasted, that was to my reproach.",
                "When I made sackcloth my clothing, I became a byword to them.",
                "Those who sit in the gate talk about me. I am the song of the drunkards.",
                "But as for me, my prayer is to you, Yahweh, in an acceptable time. God, in the abundance of your loving kindness, answer me in the truth of your salvation.",
                "Deliver me out of the mire, and don\u2019t let me sink. Let me be delivered from those who hate me, and out of the deep waters.",
                "Don\u2019t let the flood waters overwhelm me, neither let the deep swallow me up. Don\u2019t let the pit shut its mouth on me.",
                "Answer me, Yahweh, for your loving kindness is good. According to the multitude of your tender mercies, turn to me.",
                "Don\u2019t hide your face from your servant, for I am in distress. Answer me speedily!",
                "Draw near to my soul, and redeem it. Ransom me because of my enemies.",
                "You know my reproach, my shame, and my dishonor. My adversaries are all before you.",
                "Reproach has broken my heart, and I am full of heaviness. I looked for some to take pity, but there was none; for comforters, but I found none.",
                "They also gave me gall for my food. In my thirst, they gave me vinegar to drink.",
                "Let their table before them become a snare. May it become a retribution and a trap.",
                "Let their eyes be darkened, so that they can\u2019t see. Let their backs be continually bent.",
                "Pour out your indignation on them. Let the fierceness of your anger overtake them.",
                "Let their habitation be desolate. Let no one dwell in their tents.",
                "For they persecute him whom you have wounded. They tell of the sorrow of those whom you have hurt.",
                "Charge them with crime upon crime. Don\u2019t let them come into your righteousness.",
                "Let them be blotted out of the book of life, and not be written with the righteous.",
                "But I am in pain and distress. Let your salvation, God, protect me.",
                "I will praise the name of God with a song, and will magnify him with thanksgiving.",
                "It will please Yahweh better than an ox, or a bull that has horns and hoofs.",
                "The humble have seen it, and are glad. You who seek after God, let your heart live.",
                "For Yahweh hears the needy, and doesn\u2019t despise his captive people.",
                "Let heaven and earth praise him; the seas, and everything that moves therein!",
                "For God will save Zion, and build the cities of Judah. They shall settle there, and own it.",
                "The children also of his servants shall inherit it. Those who love his name shall dwell therein."
            ],
            70: [
                "For the Chief Musician. By David. A reminder. Hurry, God, to deliver me. Come quickly to help me, Yahweh.",
                "Let them be disappointed and confounded who seek my soul. Let those who desire my ruin be turned back in disgrace.",
                "Let them be turned because of their shame Who say, \"Aha! Aha!\"",
                "Let all those who seek you rejoice and be glad in you. Let those who love your salvation continually say, \"Let God be exalted!\"",
                "But I am poor and needy. Come to me quickly, God. You are my help and my deliverer. Yahweh, don\u2019t delay."
            ],
            71: [
                "In you, Yahweh, I take refuge. Never let me be disappointed.",
                "Deliver me in your righteousness, and rescue me. Turn your ear to me, and save me.",
                "Be to me a rock of refuge to which I may always go. Give the command to save me, for you are my rock and my fortress.",
                "Rescue me, my God, from the hand of the wicked, from the hand of the unrighteous and cruel man.",
                "For you are my hope, Lord Yahweh; my confidence from my youth.",
                "I have relied on you from the womb. You are he who took me out of my mother\u2019s womb. I will always praise you.",
                "I am a marvel to many, but you are my strong refuge.",
                "My mouth shall be filled with your praise, with your honor all the day.",
                "Don\u2019t reject me in my old age. Don\u2019t forsake me when my strength fails.",
                "For my enemies talk about me. Those who watch for my soul conspire together,",
                "saying, \"God has forsaken him. Pursue and take him, for no one will rescue him.\"",
                "God, don\u2019t be far from me. My God, hurry to help me.",
                "Let my accusers be disappointed and consumed. Let them be covered with disgrace and scorn who want to harm me.",
                "But I will always hope, and will add to all of your praise.",
                "My mouth will tell about your righteousness, and of your salvation all day, though I don\u2019t know its full measure.",
                "I will come with the mighty acts of the Lord Yahweh. I will make mention of your righteousness, even of yours alone.",
                "God, you have taught me from my youth. Until now, I have declared your wondrous works.",
                "Yes, even when I am old and gray-haired, God, don\u2019t forsake me, until I have declared your strength to the next generation, your might to everyone who is to come.",
                "Your righteousness also, God, reaches to the heavens; you have done great things. God, who is like you?",
                "You, who have shown us many and bitter troubles, you will let me live. You will bring us up again from the depths of the earth.",
                "Increase my honor, and comfort me again.",
                "I will also praise you with the harp for your faithfulness, my God. I sing praises to you with the lyre, Holy One of Israel.",
                "My lips shall shout for joy! My soul, which you have redeemed, sings praises to you!",
                "My tongue will also talk about your righteousness all day long, for they are disappointed, and they are confounded, who want to harm me."
            ],
            72: [
                "By Solomon. God, give the king your justice; your righteousness to the royal son.",
                "He will judge your people with righteousness, and your poor with justice.",
                "The mountains shall bring prosperity to the people. The hills bring the fruit of righteousness.",
                "He will judge the poor of the people. He will save the children of the needy, and will break the oppressor in pieces.",
                "They shall fear you while the sun endures; and as long as the moon, throughout all generations.",
                "He will come down like rain on the mown grass, as showers that water the earth.",
                "In his days, the righteous shall flourish, and abundance of peace, until the moon is no more.",
                "He shall have dominion also from sea to sea, from the River to the ends of the earth.",
                "Those who dwell in the wilderness shall bow before him. His enemies shall lick the dust.",
                "The kings of Tarshish and of the islands will bring tribute. The kings of Sheba and Seba shall offer gifts.",
                "Yes, all kings shall fall down before him. All nations shall serve him.",
                "For he will deliver the needy when he cries; the poor, who has no helper.",
                "He will have pity on the poor and needy. He will save the souls of the needy.",
                "He will redeem their soul from oppression and violence. Their blood will be precious in his sight.",
                "They shall live, and to him shall be given of the gold of Sheba. Men shall pray for him continually. They shall bless him all day long.",
                "Abundance of grain shall be throughout the land. Its fruit sways like Lebanon. Let it flourish, thriving like the grass of the field.",
                "His name endures forever. His name continues as long as the sun. Men shall be blessed by him. All nations will call him blessed.",
                "Praise be to Yahweh God, the God of Israel, who alone does marvelous deeds.",
                "Blessed be his glorious name forever! Let the whole earth be filled with his glory! Amen and amen.",
                "This ends the prayers by David, the son of Jesse."
            ],
            73: [
                "A Psalm by Asaph. Surely God is good to Israel, to those who are pure in heart.",
                "But as for me, my feet were almost gone. My steps had nearly slipped.",
                "For I was envious of the arrogant, when I saw the prosperity of the wicked.",
                "For there are no struggles in their death, but their strength is firm.",
                "They are free from burdens of men, neither are they plagued like other men.",
                "Therefore pride is like a chain around their neck. Violence covers them like a garment.",
                "Their eyes bulge with fat. Their minds pass the limits of conceit.",
                "They scoff and speak with malice. In arrogance, they threaten oppression.",
                "They have set their mouth in the heavens. Their tongue walks through the earth.",
                "Therefore their people return to them, and they drink up waters of abundance.",
                "They say, \"How does God know? Is there knowledge in the Most High?\"",
                "Behold, these are the wicked. Being always at ease, they increase in riches.",
                "Surely in vain I have cleansed my heart, and washed my hands in innocence,",
                "For all day long have I been plagued, and punished every morning.",
                "If I had said, \"I will speak thus;\" behold, I would have betrayed the generation of your children.",
                "When I tried to understand this, it was too painful for me;",
                "Until I entered God\u2019s sanctuary, and considered their latter end.",
                "Surely you set them in slippery places. You throw them down to destruction.",
                "How they are suddenly destroyed! They are completely swept away with terrors.",
                "As a dream when one wakes up, so, Lord, when you awake, you will despise their fantasies.",
                "For my soul was grieved. I was embittered in my heart.",
                "I was so senseless and ignorant. I was a brute beast before you.",
                "Nevertheless, I am continually with you. You have held my right hand.",
                "You will guide me with your counsel, and afterward receive me to glory.",
                "Whom do I have in heaven? There is no one on earth whom I desire besides you.",
                "My flesh and my heart fails, but God is the strength of my heart and my portion forever.",
                "For, behold, those who are far from you shall perish. You have destroyed all those who are unfaithful to you.",
                "But it is good for me to come close to God. I have made the Lord Yahweh my refuge, that I may tell of all your works."
            ],
            74: [
                "A contemplation by Asaph. God, why have you rejected us forever? Why does your anger smolder against the sheep of your pasture?",
                "Remember your congregation, which you purchased of old, which you have redeemed to be the tribe of your inheritance; Mount Zion, in which you have lived.",
                "Lift up your feet to the perpetual ruins, all the evil that the enemy has done in the sanctuary.",
                "Your adversaries have roared in the midst of your assembly. They have set up their standards as signs.",
                "They behaved like men wielding axes, cutting through a thicket of trees.",
                "Now they break all its carved work down with hatchet and hammers.",
                "They have burned your sanctuary to the ground. They have profaned the dwelling place of your Name.",
                "They said in their heart, \"We will crush them completely.\" They have burned up all the places in the land where God was worshiped.",
                "We see no miraculous signs. There is no longer any prophet, neither is there among us anyone who knows how long.",
                "How long, God, shall the adversary reproach? Shall the enemy blaspheme your name forever?",
                "Why do you draw back your hand, even your right hand? Take it out of your pocket and consume them!",
                "Yet God is my King of old, working salvation in the midst of the earth.",
                "You divided the sea by your strength. You broke the heads of the sea monsters in the waters.",
                "You broke the heads of Leviathan in pieces. You gave him as food to people and desert creatures.",
                "You opened up spring and stream. You dried up mighty rivers.",
                "The day is yours, the night is also yours. You have prepared the light and the sun.",
                "You have set all the boundaries of the earth. You have made summer and winter.",
                "Remember this, that the enemy has mocked you, Yahweh. Foolish people have blasphemed your name.",
                "Don\u2019t deliver the soul of your dove to wild beasts. Don\u2019t forget the life of your poor forever.",
                "Honor your covenant, for haunts of violence fill the dark places of the earth.",
                "Don\u2019t let the oppressed return ashamed. Let the poor and needy praise your name.",
                "Arise, God! Plead your own cause. Remember how the foolish man mocks you all day.",
                "Don\u2019t forget the voice of your adversaries. The tumult of those who rise up against you ascends continually."
            ],
            75: [
                "For the Chief Musician. To the tune of \"Do Not Destroy.\" A Psalm by Asaph. A song. We give thanks to you, God. We give thanks, for your Name is near. Men tell about your wondrous works.",
                "When I choose the appointed time, I will judge blamelessly.",
                "The earth and all its inhabitants quake. I firmly hold its pillars. Selah.",
                "I said to the arrogant, \"Don\u2019t boast!\" I said to the wicked, \"Don\u2019t lift up the horn.",
                "Don\u2019t lift up your horn on high. Don\u2019t speak with a stiff neck.\"",
                "For neither from the east, nor from the west, nor yet from the south, comes exaltation.",
                "But God is the judge. He puts down one, and lifts up another.",
                "For in the hand of Yahweh there is a cup, full of foaming wine mixed with spices. He pours it out. Indeed the wicked of the earth drink and drink it to its very dregs.",
                "But I will declare this forever: I will sing praises to the God of Jacob.",
                "I will cut off all the horns of the wicked, but the horns of the righteous shall be lifted up."
            ],
            76: [
                "For the Chief Musician. On stringed instruments. A Psalm by Asaph. A song. In Judah, God is known. His name is great in Israel.",
                "His tabernacle is also in Salem; His dwelling place in Zion.",
                "There he broke the flaming arrows of the bow, the shield, and the sword, and the weapons of war. Selah.",
                "Glorious are you, and excellent, more than mountains of game.",
                "Valiant men lie plundered, they have slept their last sleep. None of the men of war can lift their hands.",
                "At your rebuke, God of Jacob, both chariot and horse are cast into a deep sleep.",
                "You, even you, are to be feared. Who can stand in your sight when you are angry?",
                "You pronounced judgment from heaven. The earth feared, and was silent,",
                "when God arose to judgment, to save all the afflicted ones of the earth. Selah.",
                "Surely the wrath of man praises you. The survivors of your wrath are restrained.",
                "Make vows to Yahweh your God, and fulfill them! Let all of his neighbors bring presents to him who is to be feared.",
                "He will cut off the spirit of princes. He is feared by the kings of the earth."
            ],
            77: [
                "For the Chief Musician. To Jeduthun. A Psalm by Asaph. My cry goes to God! Indeed, I cry to God for help, and for him to listen to me.",
                "In the day of my trouble I sought the Lord. My hand was stretched out in the night, and didn\u2019t get tired. My soul refused to be comforted.",
                "I remember God, and I groan. I complain, and my spirit is overwhelmed. Selah.",
                "You hold my eyelids open. I am so troubled that I can\u2019t speak.",
                "I have considered the days of old, the years of ancient times.",
                "I remember my song in the night. I consider in my own heart; my spirit diligently inquires:",
                "\"Will the Lord reject us forever? Will he be favorable no more?",
                "Has his loving kindness vanished forever? Does his promise fail for generations?",
                "Has God forgotten to be gracious? Has he, in anger, withheld his compassion?\" Selah.",
                "Then I thought, \"I will appeal to this: the years of the right hand of the Most High.\"",
                "I will remember Yah\u2019s deeds; for I will remember your wonders of old.",
                "I will also meditate on all your work, and consider your doings.",
                "Your way, God, is in the sanctuary. What god is great like God?",
                "You are the God who does wonders. You have made your strength known among the peoples.",
                "You have redeemed your people with your arm, the sons of Jacob and Joseph. Selah.",
                "The waters saw you, God. The waters saw you, and they writhed. The depths also convulsed.",
                "The clouds poured out water. The skies resounded with thunder. Your arrows also flashed around.",
                "The voice of your thunder was in the whirlwind. The lightnings lit up the world. The earth trembled and shook.",
                "Your way was through the sea; your paths through the great waters. Your footsteps were not known.",
                "You led your people like a flock, by the hand of Moses and Aaron."
            ],
            78: [
                "A contemplation by Asaph. Hear my teaching, my people. Turn your ears to the words of my mouth.",
                "I will open my mouth in a parable. I will utter dark sayings of old,",
                "Which we have heard and known, and our fathers have told us.",
                "We will not hide them from their children, telling to the generation to come the praises of Yahweh, his strength, and his wondrous works that he has done.",
                "For he established a testimony in Jacob, and appointed a teaching in Israel, which he commanded our fathers, that they should make them known to their children;",
                "that the generation to come might know, even the children who should be born; who should arise and tell their children,",
                "that they might set their hope in God, and not forget the works of God, but keep his commandments,",
                "and might not be as their fathers, a stubborn and rebellious generation, a generation that didn\u2019t make their hearts loyal, whose spirit was not steadfast with God.",
                "The children of Ephraim, being armed and carrying bows, turned back in the day of battle.",
                "They didn\u2019t keep God\u2019s covenant, and refused to walk in his law.",
                "They forgot his doings, his wondrous works that he had shown them.",
                "He did marvelous things in the sight of their fathers, in the land of Egypt, in the field of Zoan.",
                "He split the sea, and caused them to pass through. He made the waters stand as a heap.",
                "In the daytime he also led them with a cloud, and all night with a light of fire.",
                "He split rocks in the wilderness, and gave them drink abundantly as out of the depths.",
                "He brought streams also out of the rock, and caused waters to run down like rivers.",
                "Yet they still went on to sin against him, to rebel against the Most High in the desert.",
                "They tempted God in their heart by asking food according to their desire.",
                "Yes, they spoke against God. They said, \"Can God prepare a table in the wilderness?",
                "Behold, he struck the rock, so that waters gushed out, and streams overflowed. Can he give bread also? Will he provide flesh for his people?\"",
                "Therefore Yahweh heard, and was angry. A fire was kindled against Jacob, anger also went up against Israel,",
                "because they didn\u2019t believe in God, and didn\u2019t trust in his salvation.",
                "Yet he commanded the skies above, and opened the doors of heaven.",
                "He rained down manna on them to eat, and gave them food from the sky.",
                "Man ate the bread of angels. He sent them food to the full.",
                "He caused the east wind to blow in the sky. By his power he guided the south wind.",
                "He rained also flesh on them as the dust; winged birds as the sand of the seas.",
                "He let them fall in the midst of their camp, around their habitations.",
                "So they ate, and were well filled. He gave them their own desire.",
                "They didn\u2019t turn from their cravings. Their food was yet in their mouths,",
                "when the anger of God went up against them, killed some of their fattest, and struck down the young men of Israel.",
                "For all this they still sinned, and didn\u2019t believe in his wondrous works.",
                "Therefore he consumed their days in vanity, and their years in terror.",
                "When he killed them, then they inquired after him. They returned and sought God earnestly.",
                "They remembered that God was their rock, the Most High God, their redeemer.",
                "But they flattered him with their mouth, and lied to him with their tongue.",
                "For their heart was not right with him, neither were they faithful in his covenant.",
                "But he, being merciful, forgave iniquity, and didn\u2019t destroy them. Yes, many times he turned his anger away, and didn\u2019t stir up all his wrath.",
                "He remembered that they were but flesh, a wind that passes away, and doesn\u2019t come again.",
                "How often they rebelled against him in the wilderness, and grieved him in the desert!",
                "They turned again and tempted God, and provoked the Holy One of Israel.",
                "They didn\u2019t remember his hand, nor the day when he redeemed them from the adversary;",
                "how he set his signs in Egypt, his wonders in the field of Zoan,",
                "he turned their rivers into blood, and their streams, so that they could not drink.",
                "He sent among them swarms of flies, which devoured them; and frogs, which destroyed them.",
                "He gave also their increase to the caterpillar, and their labor to the locust.",
                "He destroyed their vines with hail, their sycamore fig trees with frost.",
                "He gave over their livestock also to the hail, and their flocks to hot thunderbolts.",
                "He threw on them the fierceness of his anger, wrath, indignation, and trouble, and a band of angels of evil.",
                "He made a path for his anger. He didn\u2019t spare their soul from death, but gave their life over to the pestilence,",
                "and struck all the firstborn in Egypt, the chief of their strength in the tents of Ham.",
                "But he led forth his own people like sheep, and guided them in the wilderness like a flock.",
                "He led them safely, so that they weren\u2019t afraid, but the sea overwhelmed their enemies.",
                "He brought them to the border of his sanctuary, to this mountain, which his right hand had taken.",
                "He also drove out the nations before them, allotted them for an inheritance by line, and made the tribes of Israel to dwell in their tents.",
                "Yet they tempted and rebelled against the Most High God, and didn\u2019t keep his testimonies;",
                "but turned back, and dealt treacherously like their fathers. They were turned aside like a deceitful bow.",
                "For they provoked him to anger with their high places, and moved him to jealousy with their engraved images.",
                "When God heard this, he was angry, and greatly abhorred Israel;",
                "So that he forsook the tent of Shiloh, the tent which he placed among men;",
                "and delivered his strength into captivity, his glory into the adversary\u2019s hand.",
                "He also gave his people over to the sword, and was angry with his inheritance.",
                "Fire devoured their young men. Their virgins had no wedding song.",
                "Their priests fell by the sword, and their widows couldn\u2019t weep.",
                "Then the Lord awakened as one out of sleep, like a mighty man who shouts by reason of wine.",
                "He struck his adversaries backward. He put them to a perpetual reproach.",
                "Moreover he rejected the tent of Joseph, and didn\u2019t choose the tribe of Ephraim,",
                "But chose the tribe of Judah, Mount Zion which he loved.",
                "He built his sanctuary like the heights, like the earth which he has established forever.",
                "He also chose David his servant, and took him from the sheepfolds;",
                "from following the ewes that have their young, he brought him to be the shepherd of Jacob, his people, and Israel, his inheritance.",
                "So he was their shepherd according to the integrity of his heart, and guided them by the skillfulness of his hands."
            ],
            79: [
                "A Psalm by Asaph. God, the nations have come into your inheritance. They have defiled your holy temple. They have laid Jerusalem in heaps.",
                "They have given the dead bodies of your servants to be food for the birds of the sky, the flesh of your saints to the animals of the earth.",
                "Their blood they have shed like water around Jerusalem. There was no one to bury them.",
                "We have become a reproach to our neighbors, a scoffing and derision to those who are around us.",
                "How long, Yahweh? Will you be angry forever? Will your jealousy burn like fire?",
                "Pour out your wrath on the nations that don\u2019t know you; on the kingdoms that don\u2019t call on your name;",
                "For they have devoured Jacob, and destroyed his homeland.",
                "Don\u2019t hold the iniquities of our forefathers against us. Let your tender mercies speedily meet us, for we are in desperate need.",
                "Help us, God of our salvation, for the glory of your name. Deliver us, and forgive our sins, for your name\u2019s sake.",
                "Why should the nations say, \"Where is their God?\" Let it be known among the nations, before our eyes, that vengeance for your servants\u2019 blood is being poured out.",
                "Let the sighing of the prisoner come before you. According to the greatness of your power, preserve those who are sentenced to death.",
                "Pay back to our neighbors seven times into their bosom their reproach with which they have reproached you, Lord.",
                "So we, your people and sheep of your pasture, will give you thanks forever. We will praise you forever, to all generations."
            ],
            80: [
                "For the Chief Musician. To the tune of \"The Lilies of the Covenant.\" A Psalm by Asaph. Hear us, Shepherd of Israel, you who lead Joseph like a flock, you who sit above the cherubim, shine forth.",
                "Before Ephraim and Benjamin and Manasseh, stir up your might! Come to save us!",
                "Turn us again, God. Cause your face to shine, and we will be saved.",
                "Yahweh God of Armies, How long will you be angry against the prayer of your people?",
                "You have fed them with the bread of tears, and given them tears to drink in large measure.",
                "You make us a source of contention to our neighbors. Our enemies laugh among themselves.",
                "Turn us again, God of Armies. Cause your face to shine, and we will be saved.",
                "You brought a vine out of Egypt. You drove out the nations, and planted it.",
                "You cleared the ground for it. It took deep root, and filled the land.",
                "The mountains were covered with its shadow. Its boughs were like God\u2019s cedars.",
                "It sent out its branches to the sea, Its shoots to the River.",
                "Why have you broken down its walls, so that all those who pass by the way pluck it?",
                "The boar out of the wood ravages it. The wild animals of the field feed on it.",
                "Turn again, we beg you, God of Armies. Look down from heaven, and see, and visit this vine,",
                "the stock which your right hand planted, the branch that you made strong for yourself.",
                "It\u2019s burned with fire. It\u2019s cut down. They perish at your rebuke.",
                "Let your hand be on the man of your right hand, on the son of man whom you made strong for yourself.",
                "So we will not turn away from you. Revive us, and we will call on your name.",
                "Turn us again, Yahweh God of Armies. Cause your face to shine, and we will be saved."
            ],
            81: [
                "For the Chief Musician. On an instrument of Gath. By Asaph. Sing aloud to God, our strength! Make a joyful shout to the God of Jacob!",
                "Raise a song, and bring here the tambourine, the pleasant lyre with the harp.",
                "Blow the trumpet at the New Moon, at the full moon, on our feast day.",
                "For it is a statute for Israel, an ordinance of the God of Jacob.",
                "He appointed it in Joseph for a testimony, when he went out over the land of Egypt, I heard a language that I didn\u2019t know.",
                "\"I removed his shoulder from the burden. His hands were freed from the basket.",
                "You called in trouble, and I delivered you. I answered you in the secret place of thunder. I tested you at the waters of Meribah.\" Selah.",
                "\"Hear, my people, and I will testify to you, Israel, if you would listen to me!",
                "There shall be no strange god in you, neither shall you worship any foreign god.",
                "I am Yahweh, your God, who brought you up out of the land of Egypt. Open your mouth wide, and I will fill it.",
                "But my people didn\u2019t listen to my voice. Israel desired none of me.",
                "So I let them go after the stubbornness of their hearts, that they might walk in their own counsels.",
                "Oh that my people would listen to me, that Israel would walk in my ways!",
                "I would soon subdue their enemies, and turn my hand against their adversaries.",
                "The haters of Yahweh would cringe before him, and their punishment would last forever.",
                "But he would have also fed them with the finest of the wheat. I will satisfy you with honey out of the rock.\""
            ],
            82: [
                "A Psalm by Asaph. God presides in the great assembly. He judges among the gods.",
                "\"How long will you judge unjustly, and show partiality to the wicked?\" Selah.",
                "\"Defend the weak, the poor, and the fatherless. Maintain the rights of the poor and oppressed.",
                "Rescue the weak and needy. Deliver them out of the hand of the wicked.\"",
                "They don\u2019t know, neither do they understand. They walk back and forth in darkness. All the foundations of the earth are shaken.",
                "I said, \"You are gods, all of you are sons of the Most High.",
                "Nevertheless you shall die like men, and fall like one of the rulers.\"",
                "Arise, God, judge the earth, for you inherit all of the nations."
            ],
            83: [
                "A song. A Psalm by Asaph. God, don\u2019t keep silent. Don\u2019t keep silent, and don\u2019t be still, God.",
                "For, behold, your enemies are stirred up. Those who hate you have lifted up their heads.",
                "They conspire with cunning against your people. They plot against your cherished ones.",
                "\"Come,\" they say, \"and let\u2019s destroy them as a nation, that the name of Israel may be remembered no more.\"",
                "For they have conspired together with one mind. They form an alliance against you.",
                "The tents of Edom and the Ishmaelites; Moab, and the Hagrites;",
                "Gebal, Ammon, and Amalek; Philistia with the inhabitants of Tyre;",
                "Assyria also is joined with them. They have helped the children of Lot. Selah.",
                "Do to them as you did to Midian, as to Sisera, as to Jabin, at the river Kishon;",
                "who perished at Endor, who became as dung for the earth.",
                "Make their nobles like Oreb and Zeeb; yes, all their princes like Zebah and Zalmunna;",
                "who said, \"Let us take possession of God\u2019s pasturelands.\"",
                "My God, make them like tumbleweed; like chaff before the wind.",
                "As the fire that burns the forest, as the flame that sets the mountains on fire,",
                "so pursue them with your tempest, and terrify them with your storm.",
                "Fill their faces with confusion, that they may seek your name, Yahweh.",
                "Let them be disappointed and dismayed forever. Yes, let them be confounded and perish;",
                "that they may know that you alone, whose name is Yahweh, are the Most High over all the earth."
            ],
            84: [
                "For the Chief Musician. On an instrument of Gath. A Psalm by the sons of Korah. How lovely are your dwellings, Yahweh of Armies!",
                "My soul longs, and even faints for the courts of Yahweh. My heart and my flesh cry out for the living God.",
                "Yes, the sparrow has found a home, and the swallow a nest for herself, where she may have her young, near your altars, Yahweh of Armies, my King, and my God.",
                "Blessed are those who dwell in your house. They are always praising you. Selah.",
                "Blessed are those whose strength is in you; who have set their hearts on a pilgrimage.",
                "Passing through the valley of Weeping, they make it a place of springs. Yes, the autumn rain covers it with blessings.",
                "They go from strength to strength. Everyone of them appears before God in Zion.",
                "Yahweh, God of Armies, hear my prayer. Listen, God of Jacob. Selah.",
                "Behold, God our shield, look at the face of your anointed.",
                "For a day in your courts is better than a thousand. I would rather be a doorkeeper in the house of my God, than to dwell in the tents of wickedness.",
                "For Yahweh God is a sun and a shield. Yahweh will give grace and glory. He withholds no good thing from those who walk blamelessly.",
                "Yahweh of Armies, blessed is the man who trusts in you."
            ],
            85: [
                "For the Chief Musician. A Psalm by the sons of Korah. Yahweh, you have been favorable to your land. You have restored the fortunes of Jacob.",
                "You have forgiven the iniquity of your people. You have covered all their sin. Selah.",
                "You have taken away all your wrath. You have turned from the fierceness of your anger.",
                "Turn us, God of our salvation, and cause your indignation toward us to cease.",
                "Will you be angry with us forever? Will you draw out your anger to all generations?",
                "Won\u2019t you revive us again, that your people may rejoice in you?",
                "Show us your loving kindness, Yahweh. Grant us your salvation.",
                "I will hear what God, Yahweh, will speak, for he will speak peace to his people, his saints; but let them not turn again to folly.",
                "Surely his salvation is near those who fear him, that glory may dwell in our land.",
                "Mercy and truth meet together. Righteousness and peace have kissed each other.",
                "Truth springs out of the earth. Righteousness has looked down from heaven.",
                "Yes, Yahweh will give that which is good. Our land will yield its increase.",
                "Righteousness goes before him, And prepares the way for his steps."
            ],
            86: [
                "A Prayer by David. Hear, Yahweh, and answer me, for I am poor and needy.",
                "Preserve my soul, for I am godly. You, my God, save your servant who trusts in you.",
                "Be merciful to me, Lord, for I call to you all day long.",
                "Bring joy to the soul of your servant, for to you, Lord, do I lift up my soul.",
                "For you, Lord, are good, and ready to forgive; abundant in loving kindness to all those who call on you.",
                "Hear, Yahweh, my prayer. Listen to the voice of my petitions.",
                "In the day of my trouble I will call on you, for you will answer me.",
                "There is no one like you among the gods, Lord, nor any deeds like your deeds.",
                "All nations you have made will come and worship before you, Lord. They shall glorify your name.",
                "For you are great, and do wondrous things. You are God alone.",
                "Teach me your way, Yahweh. I will walk in your truth. Make my heart undivided to fear your name.",
                "I will praise you, Lord my God, with my whole heart. I will glorify your name forevermore.",
                "For your loving kindness is great toward me. You have delivered my soul from the lowest Sheol.",
                "God, the proud have risen up against me. A company of violent men have sought after my soul, and they don\u2019t hold regard for you before them.",
                "But you, Lord, are a merciful and gracious God, slow to anger, and abundant in loving kindness and truth.",
                "Turn to me, and have mercy on me! Give your strength to your servant. Save the son of your handmaid.",
                "Show me a sign of your goodness, that those who hate me may see it, and be shamed, because you, Yahweh, have helped me, and comforted me."
            ],
            87: [
                "A Psalm by the sons of Korah; a Song. His foundation is in the holy mountains.",
                "Yahweh loves the gates of Zion more than all the dwellings of Jacob.",
                "Glorious things are spoken about you, city of God. Selah.",
                "I will record Rahab and Babylon among those who acknowledge me. Behold, Philistia, Tyre, and also Ethiopia: \"This one was born there.\"",
                "Yes, of Zion it will be said, \"This one and that one was born in her;\" the Most High himself will establish her.",
                "Yahweh will count, when he writes up the peoples, \"This one was born there.\" Selah.",
                "Those who sing as well as those who dance say, \"All my springs are in you.\""
            ],
            88: [
                "A Song. A Psalm by the sons of Korah. For the Chief Musician. To the tune of \"The Suffering of Affliction.\" A contemplation by Heman, the Ezrahite. Yahweh, the God of my salvation, I have cried day and night before you.",
                "Let my prayer enter into your presence. Turn your ear to my cry.",
                "For my soul is full of troubles. My life draws near to Sheol.",
                "I am counted among those who go down into the pit. I am like a man who has no help,",
                "set apart among the dead, like the slain who lie in the grave, whom you remember no more. They are cut off from your hand.",
                "You have laid me in the lowest pit, in the darkest depths.",
                "Your wrath lies heavily on me. You have afflicted me with all your waves. Selah.",
                "You have taken my friends from me. You have made me an abomination to them. I am confined, and I can\u2019t escape.",
                "My eyes are dim from grief. I have called on you daily, Yahweh. I have spread out my hands to you.",
                "Do you show wonders to the dead? Do the dead rise up and praise you? Selah.",
                "Is your loving kindness declared in the grave? Or your faithfulness in Destruction?",
                "Are your wonders made known in the dark? Or your righteousness in the land of forgetfulness?",
                "But to you, Yahweh, I have cried. In the morning, my prayer comes before you.",
                "Yahweh, why do you reject my soul? Why do you hide your face from me?",
                "I am afflicted and ready to die from my youth up. While I suffer your terrors, I am distracted.",
                "Your fierce wrath has gone over me. Your terrors have cut me off.",
                "They came around me like water all day long. They completely engulfed me.",
                "You have put lover and friend far from me, and my friends into darkness."
            ],
            89: [
                "A contemplation by Ethan, the Ezrahite. I will sing of the loving kindness of Yahweh forever. With my mouth, I will make known your faithfulness to all generations.",
                "I indeed declare, \"Love stands firm forever. You established the heavens. Your faithfulness is in them.\"",
                "\"I have made a covenant with my chosen one, I have sworn to David, my servant,",
                "\u2018I will establish your seed forever, and build up your throne to all generations.\u2019\" Selah.",
                "The heavens will praise your wonders, Yahweh; your faithfulness also in the assembly of the holy ones.",
                "For who in the skies can be compared to Yahweh? Who among the sons of the heavenly beings is like Yahweh,",
                "a very awesome God in the council of the holy ones, to be feared above all those who are around him?",
                "Yahweh, God of Armies, who is a mighty one, like you? Yah, your faithfulness is around you.",
                "You rule the pride of the sea. When its waves rise up, you calm them.",
                "You have broken Rahab in pieces, like one of the slain. You have scattered your enemies with your mighty arm.",
                "The heavens are yours. The earth also is yours; the world and its fullness. You have founded them.",
                "The north and the south, you have created them. Tabor and Hermon rejoice in your name.",
                "You have a mighty arm. Your hand is strong, and your right hand is exalted.",
                "Righteousness and justice are the foundation of your throne. Loving kindness and truth go before your face.",
                "Blessed are the people who learn to acclaim you. They walk in the light of your presence, Yahweh.",
                "In your name they rejoice all day. In your righteousness, they are exalted.",
                "For you are the glory of their strength. In your favor, our horn will be exalted.",
                "For our shield belongs to Yahweh; our king to the Holy One of Israel.",
                "Then you spoke in vision to your saints, and said, \"I have bestowed strength on the warrior. I have exalted a young man from the people.",
                "I have found David, my servant. I have anointed him with my holy oil,",
                "with whom my hand shall be established. My arm will also strengthen him.",
                "No enemy will tax him. No wicked man will oppress him.",
                "I will beat down his adversaries before him, and strike those who hate him.",
                "But my faithfulness and my loving kindness will be with him. In my name, his horn will be exalted.",
                "I will set his hand also on the sea, and his right hand on the rivers.",
                "He will call to me, \u2018You are my Father, my God, and the rock of my salvation!\u2019",
                "I will also appoint him my firstborn, the highest of the kings of the earth.",
                "I will keep my loving kindness for him forevermore. My covenant will stand firm with him.",
                "I will also make his seed endure forever, and his throne as the days of heaven.",
                "If his children forsake my law, and don\u2019t walk in my ordinances;",
                "if they break my statutes, and don\u2019t keep my commandments;",
                "then I will punish their sin with the rod, and their iniquity with stripes.",
                "But I will not completely take my loving kindness from him, nor allow my faithfulness to fail.",
                "I will not break my covenant, nor alter what my lips have uttered.",
                "Once have I sworn by my holiness, I will not lie to David.",
                "His seed will endure forever, his throne like the sun before me.",
                "It will be established forever like the moon, the faithful witness in the sky.\" Selah.",
                "But you have rejected and spurned. You have been angry with your anointed.",
                "You have renounced the covenant of your servant. You have defiled his crown in the dust.",
                "You have broken down all his hedges. You have brought his strongholds to ruin.",
                "All who pass by the way rob him. He has become a reproach to his neighbors.",
                "You have exalted the right hand of his adversaries. You have made all of his enemies rejoice.",
                "Yes, you turn back the edge of his sword, and haven\u2019t supported him in battle.",
                "You have ended his splendor, and thrown his throne down to the ground.",
                "You have shortened the days of his youth. You have covered him with shame. Selah.",
                "How long, Yahweh? Will you hide yourself forever? Will your wrath burn like fire?",
                "Remember how short my time is! For what vanity have you created all the children of men!",
                "What man is he who shall live and not see death, who shall deliver his soul from the power of Sheol? Selah.",
                "Lord, where are your former loving kindnesses, which you swore to David in your faithfulness?",
                "Remember, Lord, the reproach of your servants, how I bear in my heart the taunts of all the mighty peoples,",
                "With which your enemies have mocked, Yahweh, with which they have mocked the footsteps of your anointed one.",
                "Blessed be Yahweh forevermore. Amen, and Amen."
            ],
            90: [
                "A Prayer by Moses, the man of God. Lord, you have been our dwelling place for all generations.",
                "Before the mountains were brought forth, before you had formed the earth and the world, even from everlasting to everlasting, you are God.",
                "You turn man to destruction, saying, \"Return, you children of men.\"",
                "For a thousand years in your sight are just like yesterday when it is past, like a watch in the night.",
                "You sweep them away as they sleep. In the morning they sprout like new grass.",
                "In the morning it sprouts and springs up. By evening, it is withered and dry.",
                "For we are consumed in your anger. We are troubled in your wrath.",
                "You have set our iniquities before you, our secret sins in the light of your presence.",
                "For all our days have passed away in your wrath. We bring our years to an end as a sigh.",
                "The days of our years are seventy, or even by reason of strength eighty years; yet their pride is but labor and sorrow, for it passes quickly, and we fly away.",
                "Who knows the power of your anger, your wrath according to the fear that is due to you?",
                "So teach us to number our days, that we may gain a heart of wisdom.",
                "Relent, Yahweh! How long? Have compassion on your servants!",
                "Satisfy us in the morning with your loving kindness, that we may rejoice and be glad all our days.",
                "Make us glad for as many days as you have afflicted us, for as many years as we have seen evil.",
                "Let your work appear to your servants; your glory to their children.",
                "Let the favor of the Lord our God be on us; establish the work of our hands for us; yes, establish the work of our hands."
            ],
            91: [
                "He who dwells in the secret place of the Most High will rest in the shadow of the Almighty.",
                "I will say of Yahweh, \"He is my refuge and my fortress; my God, in whom I trust.\"",
                "For he will deliver you from the snare of the fowler, and from the deadly pestilence.",
                "He will cover you with his feathers. Under his wings you will take refuge. His faithfulness is your shield and rampart.",
                "You shall not be afraid of the terror by night, nor of the arrow that flies by day;",
                "nor of the pestilence that walks in darkness, nor of the destruction that wastes at noonday.",
                "A thousand may fall at your side, and ten thousand at your right hand; but it will not come near you.",
                "You will only look with your eyes, and see the recompense of the wicked.",
                "Because you have made Yahweh your refuge, and the Most High your dwelling place,",
                "no evil shall happen to you, neither shall any plague come near your dwelling.",
                "For he will put his angels in charge of you, to guard you in all your ways.",
                "They will bear you up in their hands, so that you won\u2019t dash your foot against a stone.",
                "You will tread on the lion and cobra. You will trample the young lion and the serpent underfoot.",
                "\"Because he has set his love on me, therefore I will deliver him. I will set him on high, because he has known my name.",
                "He will call on me, and I will answer him. I will be with him in trouble. I will deliver him, and honor him.",
                "I will satisfy him with long life, and show him my salvation.\""
            ],
            92: [
                "A Psalm. A song for the Sabbath day. It is a good thing to give thanks to Yahweh, to sing praises to your name, Most High;",
                "to proclaim your loving kindness in the morning, and your faithfulness every night,",
                "with the ten-stringed lute, with the harp, and with the melody of the lyre.",
                "For you, Yahweh, have made me glad through your work. I will triumph in the works of your hands.",
                "How great are your works, Yahweh! Your thoughts are very deep.",
                "A senseless man doesn\u2019t know, neither does a fool understand this:",
                "though the wicked spring up as the grass, and all the evildoers flourish, they will be destroyed forever.",
                "But you, Yahweh, are on high forevermore.",
                "For, behold, your enemies, Yahweh, for, behold, your enemies shall perish. All the evildoers will be scattered.",
                "But you have exalted my horn like that of the wild ox. I am anointed with fresh oil.",
                "My eye has also seen my enemies. My ears have heard of the wicked enemies who rise up against me.",
                "The righteous shall flourish like the palm tree. He will grow like a cedar in Lebanon.",
                "They are planted in Yahweh\u2019s house. They will flourish in our God\u2019s courts.",
                "They will still bring forth fruit in old age. They will be full of sap and green,",
                "to show that Yahweh is upright. He is my rock, and there is no unrighteousness in him."
            ],
            93: [
                "Yahweh reigns! He is clothed with majesty! Yahweh is armed with strength. The world also is established. It can\u2019t be moved.",
                "Your throne is established from long ago. You are from everlasting.",
                "The floods have lifted up, Yahweh, the floods have lifted up their voice. The floods lift up their waves.",
                "Above the voices of many waters, the mighty breakers of the sea, Yahweh on high is mighty.",
                "Your statutes stand firm. Holiness adorns your house, Yahweh, forevermore."
            ],
            94: [
                "Yahweh, you God to whom vengeance belongs, you God to whom vengeance belongs, shine forth.",
                "Rise up, you judge of the earth. Pay back the proud what they deserve.",
                "Yahweh, how long will the wicked, how long will the wicked triumph?",
                "They pour out arrogant words. All the evildoers boast.",
                "They break your people in pieces, Yahweh, and afflict your heritage.",
                "They kill the widow and the alien, and murder the fatherless.",
                "They say, \"Yah will not see, neither will Jacob\u2019s God consider.\"",
                "Consider, you senseless among the people; you fools, when will you be wise?",
                "He who implanted the ear, won\u2019t he hear? He who formed the eye, won\u2019t he see?",
                "He who disciplines the nations, won\u2019t he punish? He who teaches man knows.",
                "Yahweh knows the thoughts of man, that they are futile.",
                "Blessed is the man whom you discipline, Yah, and teach out of your law;",
                "that you may give him rest from the days of adversity, until the pit is dug for the wicked.",
                "For Yahweh won\u2019t reject his people, neither will he forsake his inheritance.",
                "For judgment will return to righteousness. All the upright in heart shall follow it.",
                "Who will rise up for me against the wicked? Who will stand up for me against the evildoers?",
                "Unless Yahweh had been my help, my soul would have soon lived in silence.",
                "When I said, \"My foot is slipping!\" Your loving kindness, Yahweh, held me up.",
                "In the multitude of my thoughts within me, your comforts delight my soul.",
                "Shall the throne of wickedness have fellowship with you, which brings about mischief by statute?",
                "They gather themselves together against the soul of the righteous, and condemn the innocent blood.",
                "But Yahweh has been my high tower, my God, the rock of my refuge.",
                "He has brought on them their own iniquity, and will cut them off in their own wickedness. Yahweh, our God, will cut them off."
            ],
            95: [
                "Oh come, let\u2019s sing to Yahweh. Let\u2019s shout aloud to the rock of our salvation!",
                "Let\u2019s come before his presence with thanksgiving. Let\u2019s extol him with songs!",
                "For Yahweh is a great God, a great King above all gods.",
                "In his hand are the deep places of the earth. The heights of the mountains are also his.",
                "The sea is his, and he made it. His hands formed the dry land.",
                "Oh come, let\u2019s worship and bow down. Let\u2019s kneel before Yahweh, our Maker,",
                "for he is our God. We are the people of his pasture, and the sheep in his care. Today, oh that you would hear his voice!",
                "Don\u2019t harden your heart, as at Meribah, as in the day of Massah in the wilderness,",
                "when your fathers tempted me, tested me, and saw my work.",
                "Forty long years I was grieved with that generation, and said, \"It is a people that errs in their heart. They have not known my ways.\"",
                "Therefore I swore in my wrath, \"They won\u2019t enter into my rest.\""
            ],
            96: [
                "Sing to Yahweh a new song! Sing to Yahweh, all the earth.",
                "Sing to Yahweh! Bless his name! Proclaim his salvation from day to day!",
                "Declare his glory among the nations, his marvelous works among all the peoples.",
                "For great is Yahweh, and greatly to be praised! He is to be feared above all gods.",
                "For all the gods of the peoples are idols, but Yahweh made the heavens.",
                "Honor and majesty are before him. Strength and beauty are in his sanctuary.",
                "Ascribe to Yahweh, you families of nations, ascribe to Yahweh glory and strength.",
                "Ascribe to Yahweh the glory due to his name. Bring an offering, and come into his courts.",
                "Worship Yahweh in holy array. Tremble before him, all the earth.",
                "Say among the nations, \"Yahweh reigns.\" The world is also established. It can\u2019t be moved. He will judge the peoples with equity.",
                "Let the heavens be glad, and let the earth rejoice. Let the sea roar, and its fullness!",
                "Let the field and all that is in it exult! Then all the trees of the woods shall sing for joy",
                "before Yahweh; for he comes, for he comes to judge the earth. He will judge the world with righteousness, the peoples with his truth."
            ],
            97: [
                "Yahweh reigns! Let the earth rejoice! Let the multitude of islands be glad!",
                "Clouds and darkness are around him. Righteousness and justice are the foundation of his throne.",
                "A fire goes before him, and burns up his adversaries on every side.",
                "His lightning lights up the world. The earth sees, and trembles.",
                "The mountains melt like wax at the presence of Yahweh, at the presence of the Lord of the whole earth.",
                "The heavens declare his righteousness. All the peoples have seen his glory.",
                "Let all them be shamed who serve engraved images, who boast in their idols. Worship him, all you gods!",
                "Zion heard and was glad. The daughters of Judah rejoiced, because of your judgments, Yahweh.",
                "For you, Yahweh, are most high above all the earth. You are exalted far above all gods.",
                "You who love Yahweh, hate evil. He preserves the souls of his saints. He delivers them out of the hand of the wicked.",
                "Light is sown for the righteous, and gladness for the upright in heart.",
                "Be glad in Yahweh, you righteous people! Give thanks to his holy Name."
            ],
            98: [
                "A Psalm. Sing to Yahweh a new song, for he has done marvelous things! His right hand, and his holy arm, have worked salvation for him.",
                "Yahweh has made known his salvation. He has openly shown his righteousness in the sight of the nations.",
                "He has remembered his loving kindness and his faithfulness toward the house of Israel. All the ends of the earth have seen the salvation of our God.",
                "Make a joyful noise to Yahweh, all the earth! Burst out and sing for joy, yes, sing praises!",
                "Sing praises to Yahweh with the harp, with the harp and the voice of melody.",
                "With trumpets and sound of the ram\u2019s horn, make a joyful noise before the King, Yahweh.",
                "Let the sea roar with its fullness; the world, and those who dwell therein.",
                "Let the rivers clap their hands. Let the mountains sing for joy together.",
                "Let them sing before Yahweh, for he comes to judge the earth. He will judge the world with righteousness, and the peoples with equity."
            ],
            99: [
                "Yahweh reigns! Let the peoples tremble. He sits enthroned among the cherubim. Let the earth be moved.",
                "Yahweh is great in Zion. He is high above all the peoples.",
                "Let them praise your great and awesome name. He is Holy!",
                "The King\u2019s strength also loves justice. You do establish equity. You execute justice and righteousness in Jacob.",
                "Exalt Yahweh our God. Worship at his footstool. He is Holy!",
                "Moses and Aaron were among his priests, Samuel among those who call on his name; they called on Yahweh, and he answered them.",
                "He spoke to them in the pillar of cloud. They kept his testimonies, the statute that he gave them.",
                "You answered them, Yahweh our God. You are a God who forgave them, although you took vengeance for their doings.",
                "Exalt Yahweh, our God. Worship at his holy hill, for Yahweh, our God, is holy!"
            ],
            100: [
                "A Psalm of thanksgiving. Shout for joy to Yahweh, all you lands!",
                "Serve Yahweh with gladness. Come before his presence with singing.",
                "Know that Yahweh, he is God. It is he who has made us, and we are his. We are his people, and the sheep of his pasture.",
                "Enter into his gates with thanksgiving, into his courts with praise. Give thanks to him, and bless his name.",
                "For Yahweh is good. His loving kindness endures forever, his faithfulness to all generations."
            ],
            101: [
                "A Psalm by David. I will sing of loving kindness and justice. To you, Yahweh, I will sing praises.",
                "I will be careful to live a blameless life. When will you come to me? I will walk within my house with a blameless heart.",
                "I will set no vile thing before my eyes. I hate the deeds of faithless men. They will not cling to me.",
                "A perverse heart will be far from me. I will have nothing to do with evil.",
                "I will silence whoever secretly slanders his neighbor. I won\u2019t tolerate one who is haughty and conceited.",
                "My eyes will be on the faithful of the land, that they may dwell with me. He who walks in a perfect way, he will serve me.",
                "He who practices deceit won\u2019t dwell within my house. He who speaks falsehood won\u2019t be established before my eyes.",
                "Morning by morning, I will destroy all the wicked of the land; to cut off all the workers of iniquity from Yahweh\u2019s city."
            ],
            102: [
                "A Prayer of the afflicted, when he is overwhelmed and pours out his complaint before Yahweh. Hear my prayer, Yahweh! Let my cry come to you.",
                "Don\u2019t hide your face from me in the day of my distress. Turn your ear to me. Answer me quickly in the day when I call.",
                "For my days consume away like smoke. My bones are burned as a torch.",
                "My heart is blighted like grass, and withered, for I forget to eat my bread.",
                "By reason of the voice of my groaning, my bones stick to my skin.",
                "I am like a pelican of the wilderness. I have become as an owl of the waste places.",
                "I watch, and have become like a sparrow that is alone on the housetop.",
                "My enemies reproach me all day. Those who are mad at me use my name as a curse.",
                "For I have eaten ashes like bread, and mixed my drink with tears,",
                "Because of your indignation and your wrath, for you have taken me up, and thrown me away.",
                "My days are like a long shadow. I have withered like grass.",
                "But you, Yahweh, will remain forever; your renown endures to all generations.",
                "You will arise and have mercy on Zion; for it is time to have pity on her. Yes, the set time has come.",
                "For your servants take pleasure in her stones, and have pity on her dust.",
                "So the nations will fear the name of Yahweh; all the kings of the earth your glory.",
                "For Yahweh has built up Zion. He has appeared in his glory.",
                "He has responded to the prayer of the destitute, and has not despised their prayer.",
                "This will be written for the generation to come. A people which will be created will praise Yah.",
                "For he has looked down from the height of his sanctuary. From heaven, Yahweh saw the earth;",
                "to hear the groans of the prisoner; to free those who are condemned to death;",
                "that men may declare the name of Yahweh in Zion, and his praise in Jerusalem;",
                "when the peoples are gathered together, the kingdoms, to serve Yahweh.",
                "He weakened my strength along the course. He shortened my days.",
                "I said, \"My God, don\u2019t take me away in the midst of my days. Your years are throughout all generations.",
                "Of old, you laid the foundation of the earth. The heavens are the work of your hands.",
                "They will perish, but you will endure. Yes, all of them will wear out like a garment. You will change them like a cloak, and they will be changed.",
                "But you are the same. Your years will have no end.",
                "The children of your servants will continue. Their seed will be established before you.\""
            ],
            103: [
                "By David. Praise Yahweh, my soul! All that is within me, praise his holy name!",
                "Praise Yahweh, my soul, and don\u2019t forget all his benefits;",
                "who forgives all your sins; who heals all your diseases;",
                "who redeems your life from destruction; who crowns you with loving kindness and tender mercies;",
                "who satisfies your desire with good things, so that your youth is renewed like the eagle\u2019s.",
                "Yahweh executes righteous acts, and justice for all who are oppressed.",
                "He made known his ways to Moses, his deeds to the children of Israel.",
                "Yahweh is merciful and gracious, slow to anger, and abundant in loving kindness.",
                "He will not always accuse; neither will he stay angry forever.",
                "He has not dealt with us according to our sins, nor repaid us for our iniquities.",
                "For as the heavens are high above the earth, so great is his loving kindness toward those who fear him.",
                "As far as the east is from the west, so far has he removed our transgressions from us.",
                "Like a father has compassion on his children, so Yahweh has compassion on those who fear him.",
                "For he knows how we are made. He remembers that we are dust.",
                "As for man, his days are like grass. As a flower of the field, so he flourishes.",
                "For the wind passes over it, and it is gone. Its place remembers it no more.",
                "But Yahweh\u2019s loving kindness is from everlasting to everlasting with those who fear him, his righteousness to children\u2019s children;",
                "to those who keep his covenant, to those who remember to obey his precepts.",
                "Yahweh has established his throne in the heavens. His kingdom rules over all.",
                "Praise Yahweh, you angels of his, who are mighty in strength, who fulfill his word, obeying the voice of his word.",
                "Praise Yahweh, all you armies of his, you servants of his, who do his pleasure.",
                "Praise Yahweh, all you works of his, in all places of his dominion. Praise Yahweh, my soul!"
            ],
            104: [
                "Bless Yahweh, my soul. Yahweh, my God, you are very great. You are clothed with honor and majesty.",
                "He covers himself with light as with a garment. He stretches out the heavens like a curtain.",
                "He lays the beams of his rooms in the waters. He makes the clouds his chariot. He walks on the wings of the wind.",
                "He makes his messengers winds; his servants flames of fire.",
                "He laid the foundations of the earth, that it should not be moved forever.",
                "You covered it with the deep as with a cloak. The waters stood above the mountains.",
                "At your rebuke they fled. At the voice of your thunder they hurried away.",
                "The mountains rose, the valleys sank down, to the place which you had assigned to them.",
                "You have set a boundary that they may not pass over; that they don\u2019t turn again to cover the earth.",
                "He sends forth springs into the valleys. They run among the mountains.",
                "They give drink to every animal of the field. The wild donkeys quench their thirst.",
                "The birds of the sky nest by them. They sing among the branches.",
                "He waters the mountains from his rooms. The earth is filled with the fruit of your works.",
                "He causes the grass to grow for the livestock, and plants for man to cultivate, that he may bring forth food out of the earth:",
                "wine that makes glad the heart of man, oil to make his face to shine, and bread that strengthens man\u2019s heart.",
                "Yahweh\u2019s trees are well watered, the cedars of Lebanon, which he has planted;",
                "where the birds make their nests. The stork makes its home in the fir trees.",
                "The high mountains are for the wild goats. The rocks are a refuge for the rock badgers.",
                "He appointed the moon for seasons. The sun knows when to set.",
                "You make darkness, and it is night, in which all the animals of the forest prowl.",
                "The young lions roar after their prey, and seek their food from God.",
                "The sun rises, and they steal away, and lay down in their dens.",
                "Man goes forth to his work, to his labor until the evening.",
                "Yahweh, how many are your works! In wisdom have you made them all. The earth is full of your riches.",
                "There is the sea, great and wide, in which are innumerable living things, both small and large animals.",
                "There the ships go, and leviathan, whom you formed to play there.",
                "These all wait for you, that you may give them their food in due season.",
                "You give to them; they gather. You open your hand; they are satisfied with good.",
                "You hide your face: they are troubled; you take away their breath: they die, and return to the dust.",
                "You send forth your Spirit: they are created. You renew the face of the ground.",
                "Let the glory of Yahweh endure forever. Let Yahweh rejoice in his works.",
                "He looks at the earth, and it trembles. He touches the mountains, and they smoke.",
                "I will sing to Yahweh as long as I live. I will sing praise to my God while I have any being.",
                "Let your meditation be sweet to him. I will rejoice in Yahweh.",
                "Let sinners be consumed out of the earth. Let the wicked be no more. Bless Yahweh, my soul. Praise Yah!"
            ],
            105: [
                "Give thanks to Yahweh! Call on his name! Make his doings known among the peoples.",
                "Sing to him, sing praises to him! Tell of all his marvelous works.",
                "Glory in his holy name. Let the heart of those who seek Yahweh rejoice.",
                "Seek Yahweh and his strength. Seek his face forever more.",
                "Remember his marvelous works that he has done; his wonders, and the judgments of his mouth,",
                "you seed of Abraham, his servant, you children of Jacob, his chosen ones.",
                "He is Yahweh, our God. His judgments are in all the earth.",
                "He has remembered his covenant forever, the word which he commanded to a thousand generations,",
                "the covenant which he made with Abraham, his oath to Isaac,",
                "and confirmed the same to Jacob for a statute; to Israel for an everlasting covenant,",
                "saying, \"To you I will give the land of Canaan, the lot of your inheritance;\"",
                "when they were but a few men in number, yes, very few, and foreigners in it.",
                "They went about from nation to nation, from one kingdom to another people.",
                "He allowed no one to do them wrong. Yes, he reproved kings for their sakes,",
                "\"Don\u2019t touch my anointed ones! Do my prophets no harm!\"",
                "He called for a famine on the land. He destroyed the food supplies.",
                "He sent a man before them. Joseph was sold for a slave.",
                "They bruised his feet with shackles. His neck was locked in irons,",
                "until the time that his word happened, and Yahweh\u2019s word proved him true.",
                "The king sent and freed him; even the ruler of peoples, and let him go free.",
                "He made him lord of his house, and ruler of all of his possessions;",
                "to discipline his princes at his pleasure, and to teach his elders wisdom.",
                "Israel also came into Egypt. Jacob lived in the land of Ham.",
                "He increased his people greatly, and made them stronger than their adversaries.",
                "He turned their heart to hate his people, to conspire against his servants.",
                "He sent Moses, his servant, and Aaron, whom he had chosen.",
                "They performed miracles among them, and wonders in the land of Ham.",
                "He sent darkness, and made it dark. They didn\u2019t rebel against his words.",
                "He turned their waters into blood, and killed their fish.",
                "Their land swarmed with frogs, even in the rooms of their kings.",
                "He spoke, and swarms of flies came, and lice in all their borders.",
                "He gave them hail for rain, with lightning in their land.",
                "He struck their vines and also their fig trees, and shattered the trees of their country.",
                "He spoke, and the locusts came, and the grasshoppers, without number,",
                "ate up every plant in their land; and ate up the fruit of their ground.",
                "He struck also all the firstborn in their land, the first fruits of all their manhood.",
                "He brought them forth with silver and gold. There was not one feeble person among his tribes.",
                "Egypt was glad when they departed, for the fear of them had fallen on them.",
                "He spread a cloud for a covering, fire to give light in the night.",
                "They asked, and he brought quails, and satisfied them with the bread of the sky.",
                "He opened the rock, and waters gushed out. They ran as a river in the dry places.",
                "For he remembered his holy word, and Abraham, his servant.",
                "He brought forth his people with joy, his chosen with singing.",
                "He gave them the lands of the nations. They took the labor of the peoples in possession,",
                "that they might keep his statutes, and observe his laws. Praise Yah!"
            ],
            106: [
                "Praise Yahweh! Give thanks to Yahweh, for he is good, for his loving kindness endures forever.",
                "Who can utter the mighty acts of Yahweh, or fully declare all his praise?",
                "Blessed are those who keep justice. Blessed is one who does what is right at all times.",
                "Remember me, Yahweh, with the favor that you show to your people. Visit me with your salvation,",
                "that I may see the prosperity of your chosen, that I may rejoice in the gladness of your nation, that I may glory with your inheritance.",
                "We have sinned with our fathers. We have committed iniquity. We have done wickedly.",
                "Our fathers didn\u2019t understand your wonders in Egypt. They didn\u2019t remember the multitude of your loving kindnesses, but were rebellious at the sea, even at the Red Sea.",
                "Nevertheless he saved them for his name\u2019s sake, that he might make his mighty power known.",
                "He rebuked the Red Sea also, and it was dried up; so he led them through the depths, as through a desert.",
                "He saved them from the hand of him who hated them, and redeemed them from the hand of the enemy.",
                "The waters covered their adversaries. There was not one of them left.",
                "Then they believed his words. They sang his praise.",
                "They soon forgot his works. They didn\u2019t wait for his counsel,",
                "but gave in to craving in the desert, and tested God in the wasteland.",
                "He gave them their request, but sent leanness into their soul.",
                "They envied Moses also in the camp, and Aaron, Yahweh\u2019s saint.",
                "The earth opened and swallowed up Dathan, and covered the company of Abiram.",
                "A fire was kindled in their company. The flame burned up the wicked.",
                "They made a calf in Horeb, and worshiped a molten image.",
                "Thus they exchanged their glory for an image of a bull that eats grass.",
                "They forgot God, their Savior, who had done great things in Egypt,",
                "Wondrous works in the land of Ham, and awesome things by the Red Sea.",
                "Therefore he said that he would destroy them, had Moses, his chosen, not stood before him in the breach, to turn away his wrath, so that he wouldn\u2019t destroy them.",
                "Yes, they despised the pleasant land. They didn\u2019t believe his word,",
                "but murmured in their tents, and didn\u2019t listen to Yahweh\u2019s voice.",
                "Therefore he swore to them that he would overthrow them in the wilderness,",
                "that he would overthrow their seed among the nations, and scatter them in the lands.",
                "They joined themselves also to Baal Peor, and ate the sacrifices of the dead.",
                "Thus they provoked him to anger with their deeds. The plague broke in on them.",
                "Then Phinehas stood up, and executed judgment, so the plague was stopped.",
                "That was credited to him for righteousness, for all generations to come.",
                "They angered him also at the waters of Meribah, so that Moses was troubled for their sakes;",
                "because they were rebellious against his spirit, he spoke rashly with his lips.",
                "They didn\u2019t destroy the peoples, as Yahweh commanded them,",
                "but mixed themselves with the nations, and learned their works.",
                "They served their idols, which became a snare to them.",
                "Yes, they sacrificed their sons and their daughters to demons.",
                "They shed innocent blood, even the blood of their sons and of their daughters, whom they sacrificed to the idols of Canaan. The land was polluted with blood.",
                "Thus were they defiled with their works, and prostituted themselves in their deeds.",
                "Therefore Yahweh burned with anger against his people. He abhorred his inheritance.",
                "He gave them into the hand of the nations. Those who hated them ruled over them.",
                "Their enemies also oppressed them. They were brought into subjection under their hand.",
                "Many times he delivered them, but they were rebellious in their counsel, and were brought low in their iniquity.",
                "Nevertheless he regarded their distress, when he heard their cry.",
                "He remembered for them his covenant, and repented according to the multitude of his loving kindnesses.",
                "He made them also to be pitied by all those who carried them captive.",
                "Save us, Yahweh, our God, gather us from among the nations, to give thanks to your holy name, to triumph in your praise!",
                "Blessed be Yahweh, the God of Israel, from everlasting even to everlasting! Let all the people say, \"Amen.\" Praise Yah!"
            ],
            107: [
                "Give thanks to Yahweh, for he is good, for his loving kindness endures forever.",
                "Let the redeemed by Yahweh say so, whom he has redeemed from the hand of the adversary,",
                "And gathered out of the lands, from the east and from the west, from the north and from the south.",
                "They wandered in the wilderness in a desert way. They found no city to live in.",
                "Hungry and thirsty, their soul fainted in them.",
                "Then they cried to Yahweh in their trouble, and he delivered them out of their distresses,",
                "he led them also by a straight way, that they might go to a city to live in.",
                "Let them praise Yahweh for his loving kindness, for his wonderful works to the children of men!",
                "For he satisfies the longing soul. He fills the hungry soul with good.",
                "Some sat in darkness and in the shadow of death, being bound in affliction and iron,",
                "because they rebelled against the words of God, and condemned the counsel of the Most High.",
                "Therefore he brought down their heart with labor. They fell down, and there was none to help.",
                "Then they cried to Yahweh in their trouble, and he saved them out of their distresses.",
                "He brought them out of darkness and the shadow of death, and broke their bonds in sunder.",
                "Let them praise Yahweh for his loving kindness, for his wonderful works to the children of men!",
                "For he has broken the gates of brass, and cut through bars of iron.",
                "Fools are afflicted because of their disobedience, and because of their iniquities.",
                "Their soul abhors all kinds of food. They draw near to the gates of death.",
                "Then they cry to Yahweh in their trouble, he saves them out of their distresses.",
                "He sends his word, and heals them, and delivers them from their graves.",
                "Let them praise Yahweh for his loving kindness, for his wonderful works to the children of men!",
                "Let them offer the sacrifices of thanksgiving, and declare his works with singing.",
                "Those who go down to the sea in ships, who do business in great waters;",
                "These see Yahweh\u2019s works, and his wonders in the deep.",
                "For he commands, and raises the stormy wind, which lifts up its waves.",
                "They mount up to the sky; they go down again to the depths. Their soul melts away because of trouble.",
                "They reel back and forth, and stagger like a drunken man, and are at their wits\u2019 end.",
                "Then they cry to Yahweh in their trouble, and he brings them out of their distress.",
                "He makes the storm a calm, so that its waves are still.",
                "Then they are glad because it is calm, so he brings them to their desired haven.",
                "Let them praise Yahweh for his loving kindness, for his wonderful works for the children of men!",
                "Let them exalt him also in the assembly of the people, and praise him in the seat of the elders.",
                "He turns rivers into a desert, water springs into a thirsty ground,",
                "and a fruitful land into a salt waste, for the wickedness of those who dwell in it.",
                "He turns a desert into a pool of water, and a dry land into water springs.",
                "There he makes the hungry live, that they may prepare a city to live in,",
                "sow fields, plant vineyards, and reap the fruits of increase.",
                "He blesses them also, so that they are multiplied greatly. He doesn\u2019t allow their livestock to decrease.",
                "Again, they are diminished and bowed down through oppression, trouble, and sorrow.",
                "He pours contempt on princes, and causes them to wander in a trackless waste.",
                "Yet he lifts the needy out of their affliction, and increases their families like a flock.",
                "The upright will see it, and be glad. All the wicked will shut their mouths.",
                "Whoever is wise will pay attention to these things. They will consider the loving kindnesses of Yahweh."
            ],
            108: [
                "A Song. A Psalm by David. My heart is steadfast, God. I will sing and I will make music with my soul.",
                "Wake up, harp and lyre! I will wake up the dawn.",
                "I will give thanks to you, Yahweh, among the nations. I will sing praises to you among the peoples.",
                "For your loving kindness is great above the heavens. Your faithfulness reaches to the skies.",
                "Be exalted, God, above the heavens! Let your glory be over all the earth.",
                "That your beloved may be delivered, save with your right hand, and answer us.",
                "God has spoken from his sanctuary: \"In triumph, I will divide Shechem, and measure out the valley of Succoth.",
                "Gilead is mine. Manasseh is mine. Ephraim also is my helmet. Judah is my scepter.",
                "Moab is my wash pot. I will toss my sandal on Edom. I will shout over Philistia.\"",
                "Who will bring me into the fortified city? Who has led me to Edom?",
                "Haven\u2019t you rejected us, God? You don\u2019t go forth, God, with our armies.",
                "Give us help against the enemy, for the help of man is vain.",
                "Through God, we will do valiantly. For it is he who will tread down our enemies."
            ],
            109: [
                "For the Chief Musician. A Psalm by David. God of my praise, don\u2019t remain silent,",
                "for they have opened the mouth of the wicked and the mouth of deceit against me. They have spoken to me with a lying tongue.",
                "They have also surrounded me with words of hatred, and fought against me without a cause.",
                "In return for my love, they are my adversaries; but I am in prayer.",
                "They have rewarded me evil for good, and hatred for my love.",
                "Set a wicked man over him. Let an adversary stand at his right hand.",
                "When he is judged, let him come forth guilty. Let his prayer be turned into sin.",
                "Let his days be few. Let another take his office.",
                "Let his children be fatherless, and his wife a widow.",
                "Let his children be wandering beggars. Let them be sought from their ruins.",
                "Let the creditor seize all that he has. Let strangers plunder the fruit of his labor.",
                "Let there be no one to extend kindness to him, neither let there be anyone to have pity on his fatherless children.",
                "Let his posterity be cut off. In the generation following let their name be blotted out.",
                "Let the iniquity of his fathers be remembered by Yahweh. Don\u2019t let the sin of his mother be blotted out.",
                "Let them be before Yahweh continually, that he may cut off their memory from the earth;",
                "because he didn\u2019t remember to show kindness, but persecuted the poor and needy man, the broken in heart, to kill them.",
                "Yes, he loved cursing, and it came to him. He didn\u2019t delight in blessing, and it was far from him.",
                "He clothed himself also with cursing as with his garment. It came into his inward parts like water, like oil into his bones.",
                "Let it be to him as the clothing with which he covers himself, for the belt that is always around him.",
                "This is the reward of my adversaries from Yahweh, of those who speak evil against my soul.",
                "But deal with me, Yahweh the Lord, for your name\u2019s sake, because your loving kindness is good, deliver me;",
                "for I am poor and needy. My heart is wounded within me.",
                "I fade away like an evening shadow. I am shaken off like a locust.",
                "My knees are weak through fasting. My body is thin and lacks fat.",
                "I have also become a reproach to them. When they see me, they shake their head.",
                "Help me, Yahweh, my God. Save me according to your loving kindness;",
                "that they may know that this is your hand; that you, Yahweh, have done it.",
                "They may curse, but you bless. When they arise, they will be shamed, but your servant shall rejoice.",
                "Let my adversaries be clothed with dishonor. Let them cover themselves with their own shame as with a robe.",
                "I will give great thanks to Yahweh with my mouth. Yes, I will praise him among the multitude.",
                "For he will stand at the right hand of the needy, to save him from those who judge his soul."
            ],
            110: [
                "A Psalm by David. Yahweh says to my Lord, \"Sit at my right hand, until I make your enemies your footstool for your feet.\"",
                "Yahweh will send forth the rod of your strength out of Zion. Rule in the midst of your enemies.",
                "Your people offer themselves willingly in the day of your power, in holy array. Out of the womb of the morning, you have the dew of your youth.",
                "Yahweh has sworn, and will not change his mind: \"You are a priest forever in the order of Melchizedek.\"",
                "The Lord is at your right hand. He will crush kings in the day of his wrath.",
                "He will judge among the nations. He will heap up dead bodies. He will crush the ruler of the whole earth.",
                "He will drink of the brook in the way; therefore he will lift up his head."
            ],
            111: [
                "Praise Yah! I will give thanks to Yahweh with my whole heart, in the council of the upright, and in the congregation.",
                "Yahweh\u2019s works are great, pondered by all those who delight in them.",
                "His work is honor and majesty. His righteousness endures forever.",
                "He has caused his wonderful works to be remembered. Yahweh is gracious and merciful.",
                "He has given food to those who fear him. He always remembers his covenant.",
                "He has shown his people the power of his works, in giving them the heritage of the nations.",
                "The works of his hands are truth and justice. All his precepts are sure.",
                "They are established forever and ever. They are done in truth and uprightness.",
                "He has sent redemption to his people. He has ordained his covenant forever. His name is holy and awesome!",
                "The fear of Yahweh is the beginning of wisdom. All those who do his work have a good understanding. His praise endures forever!"
            ],
            112: [
                "Praise Yah! Blessed is the man who fears Yahweh, who delights greatly in his commandments.",
                "His seed will be mighty in the land. The generation of the upright will be blessed.",
                "Wealth and riches are in his house. His righteousness endures forever.",
                "Light dawns in the darkness for the upright, gracious, merciful, and righteous.",
                "It is well with the man who deals graciously and lends. He will maintain his cause in judgment.",
                "For he will never be shaken. The righteous will be remembered forever.",
                "He will not be afraid of evil news. His heart is steadfast, trusting in Yahweh.",
                "His heart is established. He will not be afraid in the end when he sees his adversaries.",
                "He has dispersed, he has given to the poor. His righteousness endures forever. His horn will be exalted with honor.",
                "The wicked will see it, and be grieved. He shall gnash with his teeth, and melt away. The desire of the wicked will perish."
            ],
            113: [
                "Praise Yah! Praise, you servants of Yahweh, praise the name of Yahweh.",
                "Blessed be the name of Yahweh, from this time forth and forevermore.",
                "From the rising of the sun to the going down of the same, Yahweh\u2019s name is to be praised.",
                "Yahweh is high above all nations, his glory above the heavens.",
                "Who is like Yahweh, our God, who has his seat on high,",
                "Who stoops down to see in heaven and in the earth?",
                "He raises up the poor out of the dust. Lifts up the needy from the ash heap;",
                "that he may set him with princes, even with the princes of his people.",
                "He settles the barren woman in her home, as a joyful mother of children. Praise Yah!"
            ],
            114: [
                "When Israel went forth out of Egypt, the house of Jacob from a people of foreign language;",
                "Judah became his sanctuary, Israel his dominion.",
                "The sea saw it, and fled. The Jordan was driven back.",
                "The mountains skipped like rams, the little hills like lambs.",
                "What was it, you sea, that you fled? You Jordan, that you turned back?",
                "You mountains, that you skipped like rams; you little hills, like lambs?",
                "Tremble, you earth, at the presence of the Lord, at the presence of the God of Jacob,",
                "who turned the rock into a pool of water, the flint into a spring of waters."
            ],
            115: [
                "Not to us, Yahweh, not to us, but to your name give glory, for your loving kindness, and for your truth\u2019s sake.",
                "Why should the nations say, \"Where is their God, now?\"",
                "But our God is in the heavens. He does whatever he pleases.",
                "Their idols are silver and gold, the work of men\u2019s hands.",
                "They have mouths, but they don\u2019t speak. They have eyes, but they don\u2019t see.",
                "They have ears, but they don\u2019t hear. They have noses, but they don\u2019t smell.",
                "They have hands, but they don\u2019t feel. They have feet, but they don\u2019t walk, neither do they speak through their throat.",
                "Those who make them will be like them; yes, everyone who trusts in them.",
                "Israel, trust in Yahweh! He is their help and their shield.",
                "House of Aaron, trust in Yahweh! He is their help and their shield.",
                "You who fear Yahweh, trust in Yahweh! He is their help and their shield.",
                "Yahweh remembers us. He will bless us. He will bless the house of Israel. He will bless the house of Aaron.",
                "He will bless those who fear Yahweh, both small and great.",
                "May Yahweh increase you more and more, you and your children.",
                "Blessed are you by Yahweh, who made heaven and earth.",
                "The heavens are the heavens of Yahweh; but the earth has he given to the children of men.",
                "The dead don\u2019t praise Yah, neither any who go down into silence;",
                "But we will bless Yah, from this time forth and forevermore. Praise Yah!"
            ],
            116: [
                "I love Yahweh, because he listens to my voice, and my cries for mercy.",
                "Because he has turned his ear to me, therefore I will call on him as long as I live.",
                "The cords of death surrounded me, the pains of Sheol got a hold of me. I found trouble and sorrow.",
                "Then I called on the name of Yahweh: \"Yahweh, I beg you, deliver my soul.\"",
                "Yahweh is Gracious and righteous. Yes, our God is merciful.",
                "Yahweh preserves the simple. I was brought low, and he saved me.",
                "Return to your rest, my soul, for Yahweh has dealt bountifully with you.",
                "For you have delivered my soul from death, my eyes from tears, and my feet from falling.",
                "I will walk before Yahweh in the land of the living.",
                "I believed, therefore I said, \"I was greatly afflicted.\"",
                "I said in my haste, \"All men are liars.\"",
                "What will I give to Yahweh for all his benefits toward me?",
                "I will take the cup of salvation, and call on the name of Yahweh.",
                "I will pay my vows to Yahweh, yes, in the presence of all his people.",
                "Precious in the sight of Yahweh is the death of his saints.",
                "Yahweh, truly I am your servant. I am your servant, the son of your handmaid. You have freed me from my chains.",
                "I will offer to you the sacrifice of thanksgiving, and will call on the name of Yahweh.",
                "I will pay my vows to Yahweh, yes, in the presence of all his people,",
                "in the courts of Yahweh\u2019s house, in the midst of you, Jerusalem. Praise Yah!"
            ],
            117: [
                "Praise Yahweh, all you nations! Extol him, all you peoples!",
                "For his loving kindness is great toward us. Yahweh\u2019s faithfulness endures forever. Praise Yah!"
            ],
            118: [
                "Give thanks to Yahweh, for he is good, for his loving kindness endures forever.",
                "Let Israel now say that his loving kindness endures forever.",
                "Let the house of Aaron now say that his loving kindness endures forever.",
                "Now let those who fear Yahweh say that his loving kindness endures forever.",
                "Out of my distress, I called on Yah. Yah answered me with freedom.",
                "Yahweh is on my side. I will not be afraid. What can man do to me?",
                "Yahweh is on my side among those who help me. Therefore I will look in triumph at those who hate me.",
                "It is better to take refuge in Yahweh, than to put confidence in man.",
                "It is better to take refuge in Yahweh, than to put confidence in princes.",
                "All the nations surrounded me, but in the name of Yahweh, I cut them off.",
                "They surrounded me, yes, they surrounded me. In the name of Yahweh I indeed cut them off.",
                "They surrounded me like bees. They are quenched like the burning thorns. In the name of Yahweh I cut them off.",
                "You pushed me back hard, to make me fall, but Yahweh helped me.",
                "Yah is my strength and song. He has become my salvation.",
                "The voice of rejoicing and salvation is in the tents of the righteous. \"The right hand of Yahweh does valiantly.",
                "The right hand of Yahweh is exalted! The right hand of Yahweh does valiantly!\"",
                "I will not die, but live, and declare Yah\u2019s works.",
                "Yah has punished me severely, but he has not given me over to death.",
                "Open to me the gates of righteousness. I will enter into them. I will give thanks to Yah.",
                "This is the gate of Yahweh; the righteous will enter into it.",
                "I will give thanks to you, for you have answered me, and have become my salvation.",
                "The stone which the builders rejected has become the head of the corner.",
                "This is Yahweh\u2019s doing. It is marvelous in our eyes.",
                "This is the day that Yahweh has made. We will rejoice and be glad in it!",
                "Save us now, we beg you, Yahweh! Yahweh, we beg you, send prosperity now.",
                "Blessed is he who comes in the name of Yahweh! We have blessed you out of the house of Yahweh.",
                "Yahweh is God, and he has given us light. Bind the sacrifice with cords, even to the horns of the altar.",
                "You are my God, and I will give thanks to you. You are my God, I will exalt you.",
                "Oh give thanks to Yahweh, for he is good, for his loving kindness endures forever."
            ],
            119: [
                "ALEPH Blessed are those whose ways are blameless, who walk according to Yahweh\u2019s law.",
                "Blessed are those who keep his statutes, who seek him with their whole heart.",
                "Yes, they do nothing wrong. They walk in his ways.",
                "You have commanded your precepts, that we should fully obey them.",
                "Oh that my ways were steadfast to obey your statutes!",
                "Then I wouldn\u2019t be disappointed, when I consider all of your commandments.",
                "I will give thanks to you with uprightness of heart, when I learn your righteous judgments.",
                "I will observe your statutes. Don\u2019t utterly forsake me. BET",
                "How can a young man keep his way pure? By living according to your word.",
                "With my whole heart, I have sought you. Don\u2019t let me wander from your commandments.",
                "I have hidden your word in my heart, that I might not sin against you.",
                "Blessed are you, Yahweh. Teach me your statutes.",
                "With my lips, I have declared all the ordinances of your mouth.",
                "I have rejoiced in the way of your testimonies, as much as in all riches.",
                "I will meditate on your precepts, and consider your ways.",
                "I will delight myself in your statutes. I will not forget your word. GIMEL",
                "Do good to your servant. I will live and I will obey your word.",
                "Open my eyes, that I may see wondrous things out of your law.",
                "I am a stranger on the earth. Don\u2019t hide your commandments from me.",
                "My soul is consumed with longing for your ordinances at all times.",
                "You have rebuked the proud who are cursed, who wander from your commandments.",
                "Take reproach and contempt away from me, for I have kept your statutes.",
                "Though princes sit and slander me, your servant will meditate on your statutes.",
                "Indeed your statutes are my delight, and my counselors. DALED",
                "My soul is laid low in the dust. Revive me according to your word!",
                "I declared my ways, and you answered me. Teach me your statutes.",
                "Let me understand the teaching of your precepts! Then I will meditate on your wondrous works.",
                "My soul is weary with sorrow: strengthen me according to your word.",
                "Keep me from the way of deceit. Grant me your law graciously!",
                "I have chosen the way of truth. I have set your ordinances before me.",
                "I cling to your statutes, Yahweh. Don\u2019t let me be disappointed.",
                "I run in the path of your commandments, for you have set my heart free. HEY",
                "Teach me, Yahweh, the way of your statutes. I will keep them to the end.",
                "Give me understanding, and I will keep your law. Yes, I will obey it with my whole heart.",
                "Direct me in the path of your commandments, for I delight in them.",
                "Turn my heart toward your statutes, not toward selfish gain.",
                "Turn my eyes away from looking at worthless things. Revive me in your ways.",
                "Fulfill your promise to your servant, that you may be feared.",
                "Take away my disgrace that I dread, for your ordinances are good.",
                "Behold, I long for your precepts! Revive me in your righteousness. WAW",
                "Let your loving kindness also come to me, Yahweh, your salvation, according to your word.",
                "So I will have an answer for him who reproaches me, for I trust in your word.",
                "Don\u2019t snatch the word of truth out of my mouth, for I put my hope in your ordinances.",
                "So I will obey your law continually, forever and ever.",
                "I will walk in liberty, for I have sought your precepts.",
                "I will also speak of your statutes before kings, and will not be disappointed.",
                "I will delight myself in your commandments, because I love them.",
                "I reach out my hands for your commandments, which I love. I will meditate on your statutes. ZAYIN",
                "Remember your word to your servant, because you gave me hope.",
                "This is my comfort in my affliction, for your word has revived me.",
                "The arrogant mock me excessively, but I don\u2019t swerve from your law.",
                "I remember your ordinances of old, Yahweh, and have comforted myself.",
                "Indignation has taken hold on me, because of the wicked who forsake your law.",
                "Your statutes have been my songs, in the house where I live.",
                "I have remembered your name, Yahweh, in the night, and I obey your law.",
                "This is my way, that I keep your precepts. CHET",
                "Yahweh is my portion. I promised to obey your words.",
                "I sought your favor with my whole heart. Be merciful to me according to your word.",
                "I considered my ways, and turned my steps to your statutes.",
                "I will hurry, and not delay, to obey your commandments.",
                "The ropes of the wicked bind me, but I won\u2019t forget your law.",
                "At midnight I will rise to give thanks to you, because of your righteous ordinances.",
                "I am a friend of all those who fear you, of those who observe your precepts.",
                "The earth is full of your loving kindness, Yahweh. Teach me your statutes. TET",
                "Do good to your servant, according to your word, Yahweh.",
                "Teach me good judgment and knowledge, for I believe in your commandments.",
                "Before I was afflicted, I went astray; but now I observe your word.",
                "You are good, and do good. Teach me your statutes.",
                "The proud have smeared a lie upon me. With my whole heart, I will keep your precepts.",
                "Their heart is as callous as the fat, but I delight in your law.",
                "It is good for me that I have been afflicted, that I may learn your statutes.",
                "The law of your mouth is better to me than thousands of pieces of gold and silver. YUD",
                "Your hands have made me and formed me. Give me understanding, that I may learn your commandments.",
                "Those who fear you will see me and be glad, because I have put my hope in your word.",
                "Yahweh, I know that your judgments are righteous, that in faithfulness you have afflicted me.",
                "Please let your loving kindness be for my comfort, according to your word to your servant.",
                "Let your tender mercies come to me, that I may live; for your law is my delight.",
                "Let the proud be disappointed, for they have overthrown me wrongfully. I will meditate on your precepts.",
                "Let those who fear you turn to me. They will know your statutes.",
                "Let my heart be blameless toward your decrees, that I may not be disappointed. KAF",
                "My soul faints for your salvation. I hope in your word.",
                "My eyes fail for your word. I say, \"When will you comfort me?\"",
                "For I have become like a wineskin in the smoke. I don\u2019t forget your statutes.",
                "How many are the days of your servant? When will you execute judgment on those who persecute me?",
                "The proud have dug pits for me, contrary to your law.",
                "All of your commandments are faithful. They persecute me wrongfully. Help me!",
                "They had almost wiped me from the earth, but I didn\u2019t forsake your precepts.",
                "Preserve my life according to your loving kindness, so I will obey the statutes of your mouth. LAMED",
                "Yahweh, your word is settled in heaven forever.",
                "Your faithfulness is to all generations. You have established the earth, and it remains.",
                "Your laws remain to this day, for all things serve you.",
                "Unless your law had been my delight, I would have perished in my affliction.",
                "I will never forget your precepts, for with them, you have revived me.",
                "I am yours. Save me, for I have sought your precepts.",
                "The wicked have waited for me, to destroy me. I will consider your statutes.",
                "I have seen a limit to all perfection, but your commands are boundless. MEM",
                "How I love your law! It is my meditation all day.",
                "Your commandments make me wiser than my enemies, for your commandments are always with me.",
                "I have more understanding than all my teachers, for your testimonies are my meditation.",
                "I understand more than the aged, because I have kept your precepts.",
                "I have kept my feet from every evil way, that I might observe your word.",
                "I have not turned aside from your ordinances, for you have taught me.",
                "How sweet are your promises to my taste, more than honey to my mouth!",
                "Through your precepts, I get understanding; therefore I hate every false way. NUN",
                "Your word is a lamp to my feet, and a light for my path.",
                "I have sworn, and have confirmed it, that I will obey your righteous ordinances.",
                "I am afflicted very much. Revive me, Yahweh, according to your word.",
                "Accept, I beg you, the willing offerings of my mouth. Yahweh, teach me your ordinances.",
                "My soul is continually in my hand, yet I won\u2019t forget your law.",
                "The wicked have laid a snare for me, yet I haven\u2019t gone astray from your precepts.",
                "I have taken your testimonies as a heritage forever, for they are the joy of my heart.",
                "I have set my heart to perform your statutes forever, even to the end. SAMEKH",
                "I hate double-minded men, but I love your law.",
                "You are my hiding place and my shield. I hope in your word.",
                "Depart from me, you evildoers, that I may keep the commandments of my God.",
                "Uphold me according to your word, that I may live. Let me not be ashamed of my hope.",
                "Hold me up, and I will be safe, and will have respect for your statutes continually.",
                "You reject all those who stray from your statutes, for their deceit is in vain.",
                "You put away all the wicked of the earth like dross. Therefore I love your testimonies.",
                "My flesh trembles for fear of you. I am afraid of your judgments. AYIN",
                "I have done what is just and righteous. Don\u2019t leave me to my oppressors.",
                "Ensure your servant\u2019s well-being. Don\u2019t let the proud oppress me.",
                "My eyes fail looking for your salvation, for your righteous word.",
                "Deal with your servant according to your loving kindness. Teach me your statutes.",
                "I am your servant. Give me understanding, that I may know your testimonies.",
                "It is time to act, Yahweh, for they break your law.",
                "Therefore I love your commandments more than gold, yes, more than pure gold.",
                "Therefore I consider all of your precepts to be right. I hate every false way. PEY",
                "Your testimonies are wonderful, therefore my soul keeps them.",
                "The entrance of your words gives light. It gives understanding to the simple.",
                "I opened my mouth wide and panted, for I longed for your commandments.",
                "Turn to me, and have mercy on me, as you always do to those who love your name.",
                "Establish my footsteps in your word. Don\u2019t let any iniquity have dominion over me.",
                "Redeem me from the oppression of man, so I will observe your precepts.",
                "Make your face shine on your servant. Teach me your statutes.",
                "Streams of tears run down my eyes, because they don\u2019t observe your law. TZADI",
                "You are righteous, Yahweh. Your judgments are upright.",
                "You have commanded your statutes in righteousness. They are fully trustworthy.",
                "My zeal wears me out, because my enemies ignore your words.",
                "Your promises have been thoroughly tested, and your servant loves them.",
                "I am small and despised. I don\u2019t forget your precepts.",
                "Your righteousness is an everlasting righteousness. Your law is truth.",
                "Trouble and anguish have taken hold of me. Your commandments are my delight.",
                "Your testimonies are righteous forever. Give me understanding, that I may live. KUF",
                "I have called with my whole heart. Answer me, Yahweh! I will keep your statutes.",
                "I have called to you. Save me! I will obey your statutes.",
                "I rise before dawn and cry for help. I put my hope in your words.",
                "My eyes stay open through the night watches, that I might meditate on your word.",
                "Hear my voice according to your loving kindness. Revive me, Yahweh, according to your ordinances.",
                "They draw near who follow after wickedness. They are far from your law.",
                "You are near, Yahweh. All your commandments are truth.",
                "Of old I have known from your testimonies, that you have founded them forever. RESH",
                "Consider my affliction, and deliver me, for I don\u2019t forget your law.",
                "Plead my cause, and redeem me! Revive me according to your promise.",
                "Salvation is far from the wicked, for they don\u2019t seek your statutes.",
                "Great are your tender mercies, Yahweh. Revive me according to your ordinances.",
                "Many are my persecutors and my adversaries. I haven\u2019t swerved from your testimonies.",
                "I look at the faithless with loathing, because they don\u2019t observe your word.",
                "Consider how I love your precepts. Revive me, Yahweh, according to your loving kindness.",
                "All of your words are truth. Every one of your righteous ordinances endures forever. SIN AND SHIN",
                "Princes have persecuted me without a cause, but my heart stands in awe of your words.",
                "I rejoice at your word, as one who finds great spoil.",
                "I hate and abhor falsehood. I love your law.",
                "Seven times a day, I praise you, because of your righteous ordinances.",
                "Those who love your law have great peace. Nothing causes them to stumble.",
                "I have hoped for your salvation, Yahweh. I have done your commandments.",
                "My soul has observed your testimonies. I love them exceedingly.",
                "I have obeyed your precepts and your testimonies, for all my ways are before you. TAV",
                "Let my cry come before you, Yahweh. Give me understanding according to your word.",
                "Let my supplication come before you. Deliver me according to your word.",
                "Let my lips utter praise, for you teach me your statutes.",
                "Let my tongue sing of your word, for all your commandments are righteousness.",
                "Let your hand be ready to help me, for I have chosen your precepts.",
                "I have longed for your salvation, Yahweh. Your law is my delight.",
                "Let my soul live, that I may praise you. Let your ordinances help me.",
                "I have gone astray like a lost sheep. Seek your servant, for I don\u2019t forget your commandments."
            ],
            120: [
                "A Song of Ascents. In my distress, I cried to Yahweh. He answered me.",
                "Deliver my soul, Yahweh, from lying lips, from a deceitful tongue.",
                "What will be given to you, and what will be done more to you, you deceitful tongue?",
                "Sharp arrows of the mighty, with coals of juniper.",
                "Woe is me, that I live in Meshech, that I dwell among the tents of Kedar!",
                "My soul has had her dwelling too long with him who hates peace.",
                "I am for peace, but when I speak, they are for war."
            ],
            121: [
                "A Song of Ascents. I will lift up my eyes to the hills. Where does my help come from?",
                "My help comes from Yahweh, who made heaven and earth.",
                "He will not allow your foot to be moved. He who keeps you will not slumber.",
                "Behold, he who keeps Israel will neither slumber nor sleep.",
                "Yahweh is your keeper. Yahweh is your shade on your right hand.",
                "The sun will not harm you by day, nor the moon by night.",
                "Yahweh will keep you from all evil. He will keep your soul.",
                "Yahweh will keep your going out and your coming in, from this time forth, and forevermore."
            ],
            122: [
                "A Song of Ascents. By David. I was glad when they said to me, \"Let\u2019s go to Yahweh\u2019s house!\"",
                "Our feet are standing within your gates, Jerusalem;",
                "Jerusalem, that is built as a city that is compact together;",
                "where the tribes go up, even Yah\u2019s tribes, according to an ordinance for Israel, to give thanks to the name of Yahweh.",
                "For there are set thrones for judgment, the thrones of David\u2019s house.",
                "Pray for the peace of Jerusalem. Those who love you will prosper.",
                "Peace be within your walls, and prosperity within your palaces.",
                "For my brothers\u2019 and companions\u2019 sakes, I will now say, \"Peace be within you.\"",
                "For the sake of the house of Yahweh our God, I will seek your good."
            ],
            123: [
                "A Song of Ascents. To you I do lift up my eyes, you who sit in the heavens.",
                "Behold, as the eyes of servants look to the hand of their master, as the eyes of a maid to the hand of her mistress; so our eyes look to Yahweh, our God, until he has mercy on us.",
                "Have mercy on us, Yahweh, have mercy on us, for we have endured much contempt.",
                "Our soul is exceedingly filled with the scoffing of those who are at ease, with the contempt of the proud."
            ],
            124: [
                "A Song of Ascents. By David. If it had not been Yahweh who was on our side, let Israel now say,",
                "if it had not been Yahweh who was on our side, when men rose up against us;",
                "then they would have swallowed us up alive, when their wrath was kindled against us;",
                "then the waters would have overwhelmed us, the stream would have gone over our soul;",
                "then the proud waters would have gone over our soul.",
                "Blessed be Yahweh, who has not given us as a prey to their teeth.",
                "Our soul has escaped like a bird out of the fowler\u2019s snare. The snare is broken, and we have escaped.",
                "Our help is in the name of Yahweh, who made heaven and earth."
            ],
            125: [
                "A Song of Ascents. Those who trust in Yahweh are as Mount Zion, which can\u2019t be moved, but remains forever.",
                "As the mountains surround Jerusalem, so Yahweh surrounds his people from this time forth and forevermore.",
                "For the scepter of wickedness won\u2019t remain over the allotment of the righteous; so that the righteous won\u2019t use their hands to do evil.",
                "Do good, Yahweh, to those who are good, to those who are upright in their hearts.",
                "But as for those who turn aside to their crooked ways, Yahweh will lead them away with the workers of iniquity. Peace be on Israel."
            ],
            126: [
                "A Song of Ascents. When Yahweh brought back those who returned to Zion, we were like those who dream.",
                "Then our mouth was filled with laughter, and our tongue with singing. Then they said among the nations, \"Yahweh has done great things for them.\"",
                "Yahweh has done great things for us, and we are glad.",
                "Restore our fortunes again, Yahweh, like the streams in the Negev.",
                "Those who sow in tears will reap in joy.",
                "He who goes out weeping, carrying seed for sowing, will certainly come again with joy, carrying his sheaves."
            ],
            127: [
                "A Song of Ascents. By Solomon. Unless Yahweh builds the house, they labor in vain who build it. Unless Yahweh watches over the city, the watchman guards it in vain.",
                "It is vain for you to rise up early, to stay up late, eating the bread of toil; for he gives sleep to his loved ones.",
                "Behold, children are a heritage of Yahweh. The fruit of the womb is his reward.",
                "As arrows in the hand of a mighty man, so are the children of youth.",
                "Happy is the man who has his quiver full of them. They won\u2019t be disappointed when they speak with their enemies in the gate."
            ],
            128: [
                "A Song of Ascents. Blessed is everyone who fears Yahweh, who walks in his ways.",
                "For you will eat the labor of your hands. You will be happy, and it will be well with you.",
                "Your wife will be as a fruitful vine, in the innermost parts of your house; your children like olive plants, around your table.",
                "Behold, thus is the man blessed who fears Yahweh.",
                "May Yahweh bless you out of Zion, and may you see the good of Jerusalem all the days of your life.",
                "Yes, may you see your children\u2019s children. Peace be upon Israel."
            ],
            129: [
                "A Song of Ascents. Many times they have afflicted me from my youth up. Let Israel now say,",
                "many times they have afflicted me from my youth up, yet they have not prevailed against me.",
                "The plowers plowed on my back. They made their furrows long.",
                "Yahweh is righteous. He has cut apart the cords of the wicked.",
                "Let them be disappointed and turned backward, all those who hate Zion.",
                "Let them be as the grass on the housetops, which withers before it grows up;",
                "with which the reaper doesn\u2019t fill his hand, nor he who binds sheaves, his bosom.",
                "Neither do those who go by say, \"The blessing of Yahweh be on you. We bless you in the name of Yahweh.\""
            ],
            130: [
                "A Song of Ascents. Out of the depths I have cried to you, Yahweh.",
                "Lord, hear my voice. Let your ears be attentive to the voice of my petitions.",
                "If you, Yah, kept a record of sins, Lord, who could stand?",
                "But there is forgiveness with you, therefore you are feared.",
                "I wait for Yahweh. My soul waits. I hope in his word.",
                "My soul longs for the Lord more than watchmen long for the morning; more than watchmen for the morning.",
                "Israel, hope in Yahweh, for with Yahweh there is loving kindness. With him is abundant redemption.",
                "He will redeem Israel from all their sins."
            ],
            131: [
                "A Song of Ascents. By David. Yahweh, my heart isn\u2019t haughty, nor my eyes lofty; nor do I concern myself with great matters, or things too wonderful for me.",
                "Surely I have stilled and quieted my soul, like a weaned child with his mother, like a weaned child is my soul within me.",
                "Israel, hope in Yahweh, from this time forth and forevermore."
            ],
            132: [
                "A Song of Ascents. Yahweh, remember David and all his affliction,",
                "how he swore to Yahweh, and vowed to the Mighty One of Jacob:",
                "\"Surely I will not come into the structure of my house, nor go up into my bed;",
                "I will not give sleep to my eyes, or slumber to my eyelids;",
                "until I find out a place for Yahweh, a dwelling for the Mighty One of Jacob.\"",
                "Behold, we heard of it in Ephrathah. We found it in the field of Jaar:",
                "\"We will go into his dwelling place. We will worship at his footstool.",
                "Arise, Yahweh, into your resting place; you, and the ark of your strength.",
                "Let your priest be clothed with righteousness. Let your saints shout for joy!\"",
                "For your servant David\u2019s sake, don\u2019t turn away the face of your anointed one.",
                "Yahweh has sworn to David in truth. He will not turn from it: \"I will set the fruit of your body on your throne.",
                "If your children will keep my covenant, my testimony that I will teach them, their children also will sit on your throne forevermore.\"",
                "For Yahweh has chosen Zion. He has desired it for his habitation.",
                "\"This is my resting place forever. Here I will live, for I have desired it.",
                "I will abundantly bless her provision. I will satisfy her poor with bread.",
                "Her priests I will also clothe with salvation. Her saints will shout aloud for joy.",
                "There I will make the horn of David to bud. I have ordained a lamp for my anointed.",
                "I will clothe his enemies with shame, but on himself, his crown will be resplendent.\""
            ],
            133: [
                "A Song of Ascents. By David. See how good and how pleasant it is for brothers to live together in unity!",
                "It is like the precious oil on the head, that ran down on the beard, even Aaron\u2019s beard; that came down on the edge of his robes;",
                "like the dew of Hermon, that comes down on the hills of Zion: for there Yahweh gives the blessing, even life forevermore."
            ],
            134: [
                "A Song of Ascents. Look! Praise Yahweh, all you servants of Yahweh, who stand by night in Yahweh\u2019s house!",
                "Lift up your hands in the sanctuary. Praise Yahweh!",
                "May Yahweh bless you from Zion; even he who made heaven and earth."
            ],
            135: [
                "Praise Yah! Praise the name of Yahweh! Praise him, you servants of Yahweh,",
                "you who stand in the house of Yahweh, in the courts of our God\u2019s house.",
                "Praise Yah, for Yahweh is good. Sing praises to his name, for that is pleasant.",
                "For Yah has chosen Jacob for himself; Israel for his own possession.",
                "For I know that Yahweh is great, that our Lord is above all gods.",
                "Whatever Yahweh pleased, that he has done, in heaven and in earth, in the seas and in all deeps;",
                "who causes the clouds to rise from the ends of the earth; who makes lightnings with the rain; who brings forth the wind out of his treasuries;",
                "Who struck the firstborn of Egypt, both of man and animal;",
                "Who sent signs and wonders into the midst of you, Egypt, on Pharaoh, and on all his servants;",
                "who struck many nations, and killed mighty kings,",
                "Sihon king of the Amorites, Og king of Bashan, and all the kingdoms of Canaan,",
                "and gave their land for a heritage, a heritage to Israel, his people.",
                "Your name, Yahweh, endures forever; your renown, Yahweh, throughout all generations.",
                "For Yahweh will judge his people, and have compassion on his servants.",
                "The idols of the nations are silver and gold, the work of men\u2019s hands.",
                "They have mouths, but they can\u2019t speak. They have eyes, but they can\u2019t see.",
                "They have ears, but they can\u2019t hear; neither is there any breath in their mouths.",
                "Those who make them will be like them; yes, everyone who trusts in them.",
                "House of Israel, praise Yahweh! House of Aaron, praise Yahweh!",
                "House of Levi, praise Yahweh! You who fear Yahweh, praise Yahweh!",
                "Blessed be Yahweh from Zion, Who dwells at Jerusalem. Praise Yah!"
            ],
            136: [
                "Give thanks to Yahweh, for he is good; for his loving kindness endures forever.",
                "Give thanks to the God of gods; for his loving kindness endures forever.",
                "Give thanks to the Lord of lords; for his loving kindness endures forever:",
                "To him who alone does great wonders; for his loving kindness endures forever:",
                "To him who by understanding made the heavens; for his loving kindness endures forever:",
                "To him who spread out the earth above the waters; for his loving kindness endures forever:",
                "To him who made the great lights; for his loving kindness endures forever:",
                "The sun to rule by day; for his loving kindness endures forever;",
                "The moon and stars to rule by night; for his loving kindness endures forever:",
                "To him who struck down the Egyptian firstborn; for his loving kindness endures forever;",
                "And brought out Israel from among them; for his loving kindness endures forever;",
                "With a strong hand, and with an outstretched arm; for his loving kindness endures forever:",
                "To him who divided the Red Sea apart; for his loving kindness endures forever;",
                "And made Israel to pass through its midst; for his loving kindness endures forever;",
                "But overthrew Pharaoh and his army in the Red Sea; for his loving kindness endures forever:",
                "To him who led his people through the wilderness; for his loving kindness endures forever:",
                "To him who struck great kings; for his loving kindness endures forever;",
                "And killed mighty kings; for his loving kindness endures forever:",
                "Sihon king of the Amorites; for his loving kindness endures forever;",
                "Og king of Bashan; for his loving kindness endures forever;",
                "And gave their land as an inheritance; for his loving kindness endures forever;",
                "Even a heritage to Israel his servant; for his loving kindness endures forever:",
                "Who remembered us in our low estate; for his loving kindness endures forever;",
                "And has delivered us from our adversaries; for his loving kindness endures forever:",
                "Who gives food to every creature; for his loving kindness endures forever.",
                "Oh give thanks to the God of heaven; for his loving kindness endures forever."
            ],
            137: [
                "By the rivers of Babylon, there we sat down. Yes, we wept, when we remembered Zion.",
                "On the willows in its midst, we hung up our harps.",
                "For there, those who led us captive asked us for songs. Those who tormented us demanded songs of joy: \"Sing us one of the songs of Zion!\"",
                "How can we sing Yahweh\u2019s song in a foreign land?",
                "If I forget you, Jerusalem, let my right hand forget its skill.",
                "Let my tongue stick to the roof of my mouth if I don\u2019t remember you; if I don\u2019t prefer Jerusalem above my chief joy.",
                "Remember, Yahweh, against the children of Edom, the day of Jerusalem; who said, \"Raze it! Raze it even to its foundation!\"",
                "Daughter of Babylon, doomed to destruction, he will be happy who rewards you, as you have served us.",
                "Happy shall he be, who takes and dashes your little ones against the rock."
            ],
            138: [
                "By David. I will give you thanks with my whole heart. Before the gods, I will sing praises to you.",
                "I will bow down toward your holy temple, and give thanks to your Name for your loving kindness and for your truth; for you have exalted your Name and your Word above all.",
                "In the day that I called, you answered me. You encouraged me with strength in my soul.",
                "All the kings of the earth will give you thanks, Yahweh, for they have heard the words of your mouth.",
                "Yes, they will sing of the ways of Yahweh; for great is Yahweh\u2019s glory.",
                "For though Yahweh is high, yet he looks after the lowly; but the proud, he knows from afar.",
                "Though I walk in the midst of trouble, you will revive me. You will stretch forth your hand against the wrath of my enemies. Your right hand will save me.",
                "Yahweh will fulfill that which concerns me; your loving kindness, Yahweh, endures forever. Don\u2019t forsake the works of your own hands."
            ],
            139: [
                "For the Chief Musician. A Psalm by David. Yahweh, you have searched me, and you know me.",
                "You know my sitting down and my rising up. You perceive my thoughts from afar.",
                "You search out my path and my lying down, and are acquainted with all my ways.",
                "For there is not a word on my tongue, but, behold, Yahweh, you know it altogether.",
                "You hem me in behind and before. You laid your hand on me.",
                "This knowledge is beyond me. It\u2019s lofty. I can\u2019t attain it.",
                "Where could I go from your Spirit? Or where could I flee from your presence?",
                "If I ascend up into heaven, you are there. If I make my bed in Sheol, behold, you are there!",
                "If I take the wings of the dawn, and settle in the uttermost parts of the sea;",
                "Even there your hand will lead me, and your right hand will hold me.",
                "If I say, \"Surely the darkness will overwhelm me; the light around me will be night;\"",
                "even the darkness doesn\u2019t hide from you, but the night shines as the day. The darkness is like light to you.",
                "For you formed my inmost being. You knit me together in my mother\u2019s womb.",
                "I will give thanks to you, for I am fearfully and wonderfully made. Your works are wonderful. My soul knows that very well.",
                "My frame wasn\u2019t hidden from you, when I was made in secret, woven together in the depths of the earth.",
                "Your eyes saw my body. In your book they were all written, the days that were ordained for me, when as yet there were none of them.",
                "How precious to me are your thoughts, God! How vast is their sum!",
                "If I would count them, they are more in number than the sand. When I wake up, I am still with you.",
                "If only you, God, would kill the wicked. Get away from me, you bloodthirsty men!",
                "For they speak against you wickedly. Your enemies take your name in vain.",
                "Yahweh, don\u2019t I hate those who hate you? Am I not grieved with those who rise up against you?",
                "I hate them with perfect hatred. They have become my enemies.",
                "Search me, God, and know my heart. Try me, and know my thoughts.",
                "See if there is any wicked way in me, and lead me in the everlasting way."
            ],
            140: [
                "For the Chief Musician. A Psalm by David. Deliver me, Yahweh, from the evil man. Preserve me from the violent man;",
                "those who devise mischief in their hearts. They continually gather themselves together for war.",
                "They have sharpened their tongues like a serpent. Viper\u2019s poison is under their lips. Selah.",
                "Yahweh, keep me from the hands of the wicked. Preserve me from the violent men who have determined to trip my feet.",
                "The proud have hidden a snare for me, they have spread the cords of a net by the path. They have set traps for me. Selah.",
                "I said to Yahweh, \"You are my God.\" Listen to the cry of my petitions, Yahweh.",
                "Yahweh, the Lord, the strength of my salvation, you have covered my head in the day of battle.",
                "Yahweh, don\u2019t grant the desires of the wicked. Don\u2019t let their evil plans succeed, or they will become proud. Selah.",
                "As for the head of those who surround me, let the mischief of their own lips cover them.",
                "Let burning coals fall on them. Let them be thrown into the fire, into miry pits, from where they never rise.",
                "An evil speaker won\u2019t be established in the earth. Evil will hunt the violent man to overthrow him.",
                "I know that Yahweh will maintain the cause of the afflicted, and justice for the needy.",
                "Surely the righteous will give thanks to your name. The upright will dwell in your presence."
            ],
            141: [
                "A Psalm by David. Yahweh, I have called on you. Come to me quickly! Listen to my voice when I call to you.",
                "Let my prayer be set before you like incense; the lifting up of my hands like the evening sacrifice.",
                "Set a watch, Yahweh, before my mouth. Keep the door of my lips.",
                "Don\u2019t incline my heart to any evil thing, to practice deeds of wickedness with men who work iniquity. Don\u2019t let me eat of their delicacies.",
                "Let the righteous strike me, it is kindness; let him reprove me, it is like oil on the head; don\u2019t let my head refuse it; Yet my prayer is always against evil deeds.",
                "Their judges are thrown down by the sides of the rock. They will hear my words, for they are well spoken.",
                "\"As when one plows and breaks up the earth, our bones are scattered at the mouth of Sheol.\"",
                "For my eyes are on you, Yahweh, the Lord. In you, I take refuge. Don\u2019t leave my soul destitute.",
                "Keep me from the snare which they have laid for me, from the traps of the workers of iniquity.",
                "Let the wicked fall together into their own nets, while I pass by."
            ],
            142: [
                "A contemplation by David, when he was in the cave. A Prayer. I cry with my voice to Yahweh. With my voice, I ask Yahweh for mercy.",
                "I pour out my complaint before him. I tell him my troubles.",
                "When my spirit was overwhelmed within me, you knew my path. In the way in which I walk, they have hidden a snare for me.",
                "Look on my right, and see; for there is no one who is concerned for me. Refuge has fled from me. No one cares for my soul.",
                "I cried to you, Yahweh. I said, \"You are my refuge, my portion in the land of the living.\"",
                "Listen to my cry, for I am in desperate need. deliver me from my persecutors, For they are stronger than me.",
                "Bring my soul out of prison, that I may give thanks to your name. The righteous will surround me, for you will be good to me."
            ],
            143: [
                "A Psalm by David. Hear my prayer, Yahweh. Listen to my petitions. In your faithfulness and righteousness, relieve me.",
                "Don\u2019t enter into judgment with your servant, for in your sight no man living is righteous.",
                "For the enemy pursues my soul. He has struck my life down to the ground. He has made me live in dark places, as those who have been long dead.",
                "Therefore my spirit is overwhelmed within me. My heart within me is desolate.",
                "I remember the days of old. I meditate on all your doings. I contemplate the work of your hands.",
                "I spread forth my hands to you. My soul thirsts for you, like a parched land. Selah.",
                "Hurry to answer me, Yahweh. My spirit fails. Don\u2019t hide your face from me, so that I don\u2019t become like those who go down into the pit.",
                "Cause me to hear your loving kindness in the morning, for I trust in you. Cause me to know the way in which I should walk, for I lift up my soul to you.",
                "Deliver me, Yahweh, from my enemies. I flee to you to hide me.",
                "Teach me to do your will, for you are my God. Your Spirit is good. Lead me in the land of uprightness.",
                "Revive me, Yahweh, for your name\u2019s sake. In your righteousness, bring my soul out of trouble.",
                "In your loving kindness, cut off my enemies, and destroy all those who afflict my soul, For I am your servant."
            ],
            144: [
                "By David. Blessed be Yahweh, my rock, who teaches my hands to war, and my fingers to battle:",
                "my loving kindness, my fortress, my high tower, my deliverer, my shield, and he in whom I take refuge; who subdues my people under me.",
                "Yahweh, what is man, that you care for him? Or the son of man, that you think of him?",
                "Man is like a breath. His days are like a shadow that passes away.",
                "Part your heavens, Yahweh, and come down. Touch the mountains, and they will smoke.",
                "Throw out lightning, and scatter them. Send out your arrows, and rout them.",
                "Stretch out your hand from above, rescue me, and deliver me out of great waters, out of the hands of foreigners;",
                "whose mouths speak deceit, Whose right hand is a right hand of falsehood.",
                "I will sing a new song to you, God. On a ten-stringed lyre, I will sing praises to you.",
                "You are he who gives salvation to kings, who rescues David, his servant, from the deadly sword.",
                "Rescue me, and deliver me out of the hands of foreigners, whose mouths speak deceit, whose right hand is a right hand of falsehood.",
                "Then our sons will be like well-nurtured plants, our daughters like pillars carved to adorn a palace.",
                "Our barns are full, filled with all kinds of provision. Our sheep bring forth thousands and ten thousands in our fields.",
                "Our oxen will pull heavy loads. There is no breaking in, and no going away, and no outcry in our streets.",
                "Happy are the people who are in such a situation. Happy are the people whose God is Yahweh."
            ],
            145: [
                "A praise psalm by David. I will exalt you, my God, the King. I will praise your name forever and ever.",
                "Every day I will praise you. I will extol your name forever and ever.",
                "Great is Yahweh, and greatly to be praised! His greatness is unsearchable.",
                "One generation will commend your works to another, and will declare your mighty acts.",
                "Of the glorious majesty of your honor, of your wondrous works, I will meditate.",
                "Men will speak of the might of your awesome acts. I will declare your greatness.",
                "They will utter the memory of your great goodness, and will sing of your righteousness.",
                "Yahweh is gracious, merciful, slow to anger, and of great loving kindness.",
                "Yahweh is good to all. His tender mercies are over all his works.",
                "All your works will give thanks to you, Yahweh. Your saints will extol you.",
                "They will speak of the glory of your kingdom, and talk about your power;",
                "to make known to the sons of men his mighty acts, the glory of the majesty of his kingdom.",
                "Your kingdom is an everlasting kingdom. Your dominion endures throughout all generations. Yahweh is faithful in all his words, and loving in all his deeds.",
                "Yahweh upholds all who fall, and raises up all those who are bowed down.",
                "The eyes of all wait for you. You give them their food in due season.",
                "You open your hand, and satisfy the desire of every living thing.",
                "Yahweh is righteous in all his ways, and gracious in all his works.",
                "Yahweh is near to all those who call on him, to all who call on him in truth.",
                "He will fulfill the desire of those who fear him. He also will hear their cry, and will save them.",
                "Yahweh preserves all those who love him, but all the wicked he will destroy.",
                "My mouth will speak the praise of Yahweh. Let all flesh bless his holy name forever and ever."
            ],
            146: [
                "Praise Yah! Praise Yahweh, my soul.",
                "While I live, I will praise Yahweh. I will sing praises to my God as long as I exist.",
                "Don\u2019t put your trust in princes, each a son of man in whom there is no help.",
                "His spirit departs, and he returns to the earth. In that very day, his thoughts perish.",
                "Happy is he who has the God of Jacob for his help, whose hope is in Yahweh, his God:",
                "who made heaven and earth, the sea, and all that is in them; who keeps truth forever;",
                "who executes justice for the oppressed; who gives food to the hungry. Yahweh frees the prisoners.",
                "Yahweh opens the eyes of the blind. Yahweh raises up those who are bowed down. Yahweh loves the righteous.",
                "Yahweh preserves the foreigners. He upholds the fatherless and widow, but the way of the wicked he turns upside down.",
                "Yahweh will reign forever; your God, O Zion, to all generations. Praise Yah!"
            ],
            147: [
                "Praise Yah, for it is good to sing praises to our God; for it is pleasant and fitting to praise him.",
                "Yahweh builds up Jerusalem. He gathers together the outcasts of Israel.",
                "He heals the broken in heart, and binds up their wounds.",
                "He counts the number of the stars. He calls them all by their names.",
                "Great is our Lord, and mighty in power. His understanding is infinite.",
                "Yahweh upholds the humble. He brings the wicked down to the ground.",
                "Sing to Yahweh with thanksgiving. Sing praises on the harp to our God,",
                "who covers the sky with clouds, who prepares rain for the earth, who makes grass grow on the mountains.",
                "He provides food for the livestock, and for the young ravens when they call.",
                "He doesn\u2019t delight in the strength of the horse. He takes no pleasure in the legs of a man.",
                "Yahweh takes pleasure in those who fear him, in those who hope in his loving kindness.",
                "Praise Yahweh, Jerusalem! Praise your God, Zion!",
                "For he has strengthened the bars of your gates. He has blessed your children within you.",
                "He makes peace in your borders. He fills you with the finest of the wheat.",
                "He sends out his commandment to the earth. His word runs very swiftly.",
                "He gives snow like wool, and scatters frost like ashes.",
                "He hurls down his hail like pebbles. Who can stand before his cold?",
                "He sends out his word, and melts them. He causes his wind to blow, and the waters flow.",
                "He shows his word to Jacob; his statutes and his ordinances to Israel.",
                "He has not done this for just any nation. They don\u2019t know his ordinances. Praise Yah!"
            ],
            148: [
                "Praise Yah! Praise Yahweh from the heavens! Praise him in the heights!",
                "Praise him, all his angels! Praise him, all his army!",
                "Praise him, sun and moon! Praise him, all you shining stars!",
                "Praise him, you heavens of heavens, You waters that are above the heavens.",
                "Let them praise the name of Yahweh, For he commanded, and they were created.",
                "He has also established them forever and ever. He has made a decree which will not pass away.",
                "Praise Yahweh from the earth, you great sea creatures, and all depths!",
                "Lightning and hail, snow and clouds; stormy wind, fulfilling his word;",
                "mountains and all hills; fruit trees and all cedars;",
                "wild animals and all livestock; small creatures and flying birds;",
                "kings of the earth and all peoples; princes and all judges of the earth;",
                "both young men and maidens; old men and children:",
                "let them praise the name of Yahweh, for his name alone is exalted. His glory is above the earth and the heavens.",
                "He has lifted up the horn of his people, the praise of all his saints; even of the children of Israel, a people near to him. Praise Yah!"
            ],
            149: [
                "Praise Yahweh! Sing to Yahweh a new song, his praise in the assembly of the saints.",
                "Let Israel rejoice in him who made them. Let the children of Zion be joyful in their King.",
                "Let them praise his name in the dance! Let them sing praises to him with tambourine and harp!",
                "For Yahweh takes pleasure in his people. He crowns the humble with salvation.",
                "Let the saints rejoice in honor. Let them sing for joy on their beds.",
                "May the high praises of God be in their mouths, and a two-edged sword in their hand;",
                "To execute vengeance on the nations, and punishments on the peoples;",
                "To bind their kings with chains, and their nobles with fetters of iron;",
                "to execute on them the written judgment. All his saints have this honor. Praise Yah!"
            ],
            150: [
                "Praise Yah! Praise God in his sanctuary! Praise him in his heavens for his acts of power!",
                "Praise him for his mighty acts! Praise him according to his excellent greatness!",
                "Praise him with the sounding of the trumpet! Praise him with harp and lyre!",
                "Praise him with tambourine and dancing! Praise him with stringed instruments and flute!",
                "Praise him with loud cymbals! Praise him with resounding cymbals!",
                "Let everything that has breath praise Yah! Praise Yah!"
            ]
        },
        20: {
            1: [
                "The proverbs of Solomon, the son of David, king of Israel:",
                "to know wisdom and instruction; to discern the words of understanding;",
                "to receive instruction in wise dealing, in righteousness, justice, and equity;",
                "to give prudence to the simple, knowledge and discretion to the young man:",
                "that the wise man may hear, and increase in learning; that the man of understanding may attain to sound counsel:",
                "to understand a proverb, and parables, the words and riddles of the wise.",
                "The fear of Yahweh is the beginning of knowledge; but the foolish despise wisdom and instruction.",
                "My son, listen to your father\u2019s instruction, and don\u2019t forsake your mother\u2019s teaching:",
                "for they will be a garland to grace your head, and chains around your neck.",
                "My son, if sinners entice you, don\u2019t consent.",
                "If they say, \"Come with us, Let\u2019s lay in wait for blood; let\u2019s lurk secretly for the innocent without cause;",
                "let\u2019s swallow them up alive like Sheol, and whole, like those who go down into the pit.",
                "We\u2019ll find all valuable wealth. We\u2019ll fill our houses with spoil.",
                "You shall cast your lot among us. We\u2019ll all have one purse.\"",
                "My son, don\u2019t walk in the way with them. Keep your foot from their path,",
                "for their feet run to evil. They hurry to shed blood.",
                "For in vain is the net spread in the sight of any bird:",
                "but these lay wait for their own blood. They lurk secretly for their own lives.",
                "So are the ways of everyone who is greedy for gain. It takes away the life of its owners.",
                "Wisdom calls aloud in the street. She utters her voice in the public squares.",
                "She calls at the head of noisy places. At the entrance of the city gates, she utters her words:",
                "\"How long, you simple ones, will you love simplicity? How long will mockers delight themselves in mockery, and fools hate knowledge?",
                "Turn at my reproof. Behold, I will pour out my spirit on you. I will make known my words to you.",
                "Because I have called, and you have refused; I have stretched out my hand, and no one has paid attention;",
                "but you have ignored all my counsel, and wanted none of my reproof;",
                "I also will laugh at your disaster. I will mock when calamity overtakes you;",
                "when calamity overtakes you like a storm, when your disaster comes on like a whirlwind; when distress and anguish come on you.",
                "Then will they call on me, but I will not answer. They will seek me diligently, but they will not find me;",
                "because they hated knowledge, and didn\u2019t choose the fear of Yahweh.",
                "They wanted none of my counsel. They despised all my reproof.",
                "Therefore they will eat of the fruit of their own way, and be filled with their own schemes.",
                "For the backsliding of the simple will kill them. The careless ease of fools will destroy them.",
                "But whoever listens to me will dwell securely, and will be at ease, without fear of harm.\""
            ],
            2: [
                "My son, if you will receive my words, and store up my commandments within you;",
                "So as to turn your ear to wisdom, and apply your heart to understanding;",
                "Yes, if you call out for discernment, and lift up your voice for understanding;",
                "If you seek her as silver, and search for her as for hidden treasures:",
                "then you will understand the fear of Yahweh, and find the knowledge of God.",
                "For Yahweh gives wisdom. Out of his mouth comes knowledge and understanding.",
                "He lays up sound wisdom for the upright. He is a shield to those who walk in integrity;",
                "that he may guard the paths of justice, and preserve the way of his saints.",
                "Then you will understand righteousness and justice, equity and every good path.",
                "For wisdom will enter into your heart. Knowledge will be pleasant to your soul.",
                "Discretion will watch over you. Understanding will keep you,",
                "to deliver you from the way of evil, from the men who speak perverse things;",
                "who forsake the paths of uprightness, to walk in the ways of darkness;",
                "who rejoice to do evil, and delight in the perverseness of evil;",
                "who are crooked in their ways, and wayward in their paths:",
                "To deliver you from the strange woman, even from the foreigner who flatters with her words;",
                "who forsakes the friend of her youth, and forgets the covenant of her God:",
                "for her house leads down to death, her paths to the dead.",
                "None who go to her return again, neither do they attain to the paths of life:",
                "that you may walk in the way of good men, and keep the paths of the righteous.",
                "For the upright will dwell in the land. The perfect will remain in it.",
                "But the wicked will be cut off from the land. The treacherous will be rooted out of it."
            ],
            3: [
                "My son, don\u2019t forget my teaching; but let your heart keep my commandments:",
                "for length of days, and years of life, and peace, will they add to you.",
                "Don\u2019t let kindness and truth forsake you. Bind them around your neck. Write them on the tablet of your heart.",
                "So you will find favor, and good understanding in the sight of God and man.",
                "Trust in Yahweh with all your heart, and don\u2019t lean on your own understanding.",
                "In all your ways acknowledge him, and he will make your paths straight.",
                "Don\u2019t be wise in your own eyes. Fear Yahweh, and depart from evil.",
                "It will be health to your body, and nourishment to your bones.",
                "Honor Yahweh with your substance, with the first fruits of all your increase:",
                "so your barns will be filled with plenty, and your vats will overflow with new wine.",
                "My son, don\u2019t despise Yahweh\u2019s discipline, neither be weary of his reproof:",
                "for whom Yahweh loves, he reproves; even as a father reproves the son in whom he delights.",
                "Happy is the man who finds wisdom, the man who gets understanding.",
                "For her good profit is better than getting silver, and her return is better than fine gold.",
                "She is more precious than rubies. None of the things you can desire are to be compared to her.",
                "Length of days is in her right hand. In her left hand are riches and honor.",
                "Her ways are ways of pleasantness. All her paths are peace.",
                "She is a tree of life to those who lay hold of her. Happy is everyone who retains her.",
                "By wisdom Yahweh founded the earth. By understanding, he established the heavens.",
                "By his knowledge, the depths were broken up, and the skies drop down the dew.",
                "My son, let them not depart from your eyes. Keep sound wisdom and discretion:",
                "so they will be life to your soul, and grace for your neck.",
                "Then you shall walk in your way securely. Your foot won\u2019t stumble.",
                "When you lie down, you will not be afraid. Yes, you will lie down, and your sleep will be sweet.",
                "Don\u2019t be afraid of sudden fear, neither of the desolation of the wicked, when it comes:",
                "for Yahweh will be your confidence, and will keep your foot from being taken.",
                "Don\u2019t withhold good from those to whom it is due, when it is in the power of your hand to do it.",
                "Don\u2019t say to your neighbor, \"Go, and come again; tomorrow I will give it to you,\" when you have it by you.",
                "Don\u2019t devise evil against your neighbor, since he dwells securely by you.",
                "Don\u2019t strive with a man without cause, if he has done you no harm.",
                "Don\u2019t envy the man of violence. Choose none of his ways.",
                "For the perverse is an abomination to Yahweh, but his friendship is with the upright.",
                "Yahweh\u2019s curse is in the house of the wicked, but he blesses the habitation of the righteous.",
                "Surely he mocks the mockers, but he gives grace to the humble.",
                "The wise will inherit glory, but shame will be the promotion of fools."
            ],
            4: [
                "Listen, sons, to a father\u2019s instruction. Pay attention and know understanding;",
                "for I give you sound learning. Don\u2019t forsake my law.",
                "For I was a son to my father, tender and an only child in the sight of my mother.",
                "He taught me, and said to me: \"Let your heart retain my words. Keep my commandments, and live.",
                "Get wisdom. Get understanding. Don\u2019t forget, neither swerve from the words of my mouth.",
                "Don\u2019t forsake her, and she will preserve you. Love her, and she will keep you.",
                "Wisdom is supreme. Get wisdom. Yes, though it costs all your possessions, get understanding.",
                "Esteem her, and she will exalt you. She will bring you to honor, when you embrace her.",
                "She will give to your head a garland of grace. She will deliver a crown of splendor to you.\"",
                "Listen, my son, and receive my sayings. The years of your life will be many.",
                "I have taught you in the way of wisdom. I have led you in straight paths.",
                "When you go, your steps will not be hampered. When you run, you will not stumble.",
                "Take firm hold of instruction. Don\u2019t let her go. Keep her, for she is your life.",
                "Don\u2019t enter into the path of the wicked. Don\u2019t walk in the way of evil men.",
                "Avoid it, and don\u2019t pass by it. Turn from it, and pass on.",
                "For they don\u2019t sleep, unless they do evil. Their sleep is taken away, unless they make someone fall.",
                "For they eat the bread of wickedness, and drink the wine of violence.",
                "But the path of the righteous is like the dawning light, that shines more and more until the perfect day.",
                "The way of the wicked is like darkness. They don\u2019t know what they stumble over.",
                "My son, attend to my words. Turn your ear to my sayings.",
                "Let them not depart from your eyes. Keep them in the midst of your heart.",
                "For they are life to those who find them, and health to their whole body.",
                "Keep your heart with all diligence, for out of it is the wellspring of life.",
                "Put away from yourself a perverse mouth. Put corrupt lips far from you.",
                "Let your eyes look straight ahead. Fix your gaze directly before you.",
                "Make the path of your feet level. Let all of your ways be established.",
                "Don\u2019t turn to the right hand nor to the left. Remove your foot from evil."
            ],
            5: [
                "My son, pay attention to my wisdom. Turn your ear to my understanding:",
                "that you may maintain discretion, that your lips may preserve knowledge.",
                "For the lips of an adulteress drip honey. Her mouth is smoother than oil,",
                "But in the end she is as bitter as wormwood, and as sharp as a two-edged sword.",
                "Her feet go down to death. Her steps lead straight to Sheol.",
                "She gives no thought to the way of life. Her ways are crooked, and she doesn\u2019t know it.",
                "Now therefore, my sons, listen to me. Don\u2019t depart from the words of my mouth.",
                "Remove your way far from her. Don\u2019t come near the door of her house,",
                "lest you give your honor to others, and your years to the cruel one;",
                "lest strangers feast on your wealth, and your labors enrich another man\u2019s house.",
                "You will groan at your latter end, when your flesh and your body are consumed,",
                "and say, \"How I have hated instruction, and my heart despised reproof;",
                "neither have I obeyed the voice of my teachers, nor turned my ear to those who instructed me!",
                "I have come to the brink of utter ruin, in the midst of the gathered assembly.\"",
                "Drink water out of your own cistern, running water out of your own well.",
                "Should your springs overflow in the streets, streams of water in the public squares?",
                "Let them be for yourself alone, not for strangers with you.",
                "Let your spring be blessed. Rejoice in the wife of your youth.",
                "A loving doe and a graceful deer\u2014let her breasts satisfy you at all times. Be captivated always with her love.",
                "For why should you, my son, be captivated with an adulteress? Why embrace the bosom of another?",
                "For the ways of man are before the eyes of Yahweh. He examines all his paths.",
                "The evil deeds of the wicked ensnare him. The cords of his sin hold him firmly.",
                "He will die for lack of instruction. In the greatness of his folly, he will go astray."
            ],
            6: [
                "My son, if you have become collateral for your neighbor, if you have struck your hands in pledge for a stranger;",
                "You are trapped by the words of your mouth. You are ensnared with the words of your mouth.",
                "Do this now, my son, and deliver yourself, since you have come into the hand of your neighbor. Go, humble yourself. Press your plea with your neighbor.",
                "Give no sleep to your eyes, nor slumber to your eyelids.",
                "Free yourself, like a gazelle from the hand of the hunter, like a bird from the snare of the fowler.",
                "Go to the ant, you sluggard. Consider her ways, and be wise;",
                "which having no chief, overseer, or ruler,",
                "provides her bread in the summer, and gathers her food in the harvest.",
                "How long will you sleep, sluggard? When will you arise out of your sleep?",
                "A little sleep, a little slumber, a little folding of the hands to sleep:",
                "so your poverty will come as a robber, and your scarcity as an armed man.",
                "A worthless person, a man of iniquity, is he who walks with a perverse mouth;",
                "who winks with his eyes, who signals with his feet, who motions with his fingers;",
                "in whose heart is perverseness, who devises evil continually, who always sows discord.",
                "Therefore his calamity will come suddenly. He will be broken suddenly, and that without remedy.",
                "There are six things which Yahweh hates; yes, seven which are an abomination to him:",
                "haughty eyes, a lying tongue, hands that shed innocent blood;",
                "a heart that devises wicked schemes, feet that are swift in running to mischief,",
                "a false witness who utters lies, and he who sows discord among brothers.",
                "My son, keep your father\u2019s commandment, and don\u2019t forsake your mother\u2019s teaching.",
                "Bind them continually on your heart. Tie them around your neck.",
                "When you walk, it will lead you. When you sleep, it will watch over you. When you awake, it will talk with you.",
                "For the commandment is a lamp, and the law is light. Reproofs of instruction are the way of life,",
                "to keep you from the immoral woman, from the flattery of the wayward wife\u2019s tongue.",
                "Don\u2019t lust after her beauty in your heart, neither let her captivate you with her eyelids.",
                "For a prostitute reduces you to a piece of bread. The adulteress hunts for your precious life.",
                "Can a man scoop fire into his lap, and his clothes not be burned?",
                "Or can one walk on hot coals, and his feet not be scorched?",
                "So is he who goes in to his neighbor\u2019s wife. Whoever touches her will not be unpunished.",
                "Men don\u2019t despise a thief, if he steals to satisfy himself when he is hungry:",
                "but if he is found, he shall restore seven times. He shall give all the wealth of his house.",
                "He who commits adultery with a woman is void of understanding. He who does it destroys his own soul.",
                "He will get wounds and dishonor. His reproach will not be wiped away.",
                "For jealousy arouses the fury of the husband. He won\u2019t spare in the day of vengeance.",
                "He won\u2019t regard any ransom, neither will he rest content, though you give many gifts."
            ],
            7: [
                "My son, keep my words. Lay up my commandments within you.",
                "Keep my commandments and live! Guard my teaching as the apple of your eye.",
                "Bind them on your fingers. Write them on the tablet of your heart.",
                "Tell wisdom, \"You are my sister.\" Call understanding your relative,",
                "that they may keep you from the strange woman, from the foreigner who flatters with her words.",
                "For at the window of my house, I looked out through my lattice.",
                "I saw among the simple ones. I discerned among the youths a young man void of understanding,",
                "passing through the street near her corner, he went the way to her house,",
                "in the twilight, in the evening of the day, in the middle of the night and in the darkness.",
                "Behold, there a woman met him with the attire of a prostitute, and with crafty intent.",
                "She is loud and defiant. Her feet don\u2019t stay in her house.",
                "Now she is in the streets, now in the squares, and lurking at every corner.",
                "So she caught him, and kissed him. With an impudent face she said to him:",
                "\"Sacrifices of peace offerings are with me. This day I have paid my vows.",
                "Therefore I came out to meet you, to diligently seek your face, and I have found you.",
                "I have spread my couch with carpets of tapestry, with striped cloths of the yarn of Egypt.",
                "I have perfumed my bed with myrrh, aloes, and cinnamon.",
                "Come, let\u2019s take our fill of loving until the morning. Let\u2019s solace ourselves with loving.",
                "For my husband isn\u2019t at home. He has gone on a long journey.",
                "He has taken a bag of money with him. He will come home at the full moon.\"",
                "With persuasive words, she led him astray. With the flattering of her lips, she seduced him.",
                "He followed her immediately, as an ox goes to the slaughter, as a fool stepping into a noose.",
                "Until an arrow strikes through his liver, as a bird hurries to the snare, and doesn\u2019t know that it will cost his life.",
                "Now therefore, sons, listen to me. Pay attention to the words of my mouth.",
                "Don\u2019t let your heart turn to her ways. Don\u2019t go astray in her paths,",
                "for she has thrown down many wounded. Yes, all her slain are a mighty army.",
                "Her house is the way to Sheol, going down to the rooms of death."
            ],
            8: [
                "Doesn\u2019t wisdom cry out? Doesn\u2019t understanding raise her voice?",
                "On the top of high places by the way, where the paths meet, she stands.",
                "Beside the gates, at the entry of the city, at the entry doors, she cries aloud:",
                "\"To you men, I call! I send my voice to the sons of mankind.",
                "You simple, understand prudence. You fools, be of an understanding heart.",
                "Hear, for I will speak excellent things. The opening of my lips is for right things.",
                "For my mouth speaks truth. Wickedness is an abomination to my lips.",
                "All the words of my mouth are in righteousness. There is nothing crooked or perverse in them.",
                "They are all plain to him who understands, right to those who find knowledge.",
                "Receive my instruction rather than silver; knowledge rather than choice gold.",
                "For wisdom is better than rubies. All the things that may be desired can\u2019t be compared to it.",
                "\"I, wisdom, have made prudence my dwelling. Find out knowledge and discretion.",
                "The fear of Yahweh is to hate evil. I hate pride, arrogance, the evil way, and the perverse mouth.",
                "Counsel and sound knowledge are mine. I have understanding and power.",
                "By me kings reign, and princes decree justice.",
                "By me princes rule; nobles, and all the righteous rulers of the earth.",
                "I love those who love me. Those who seek me diligently will find me.",
                "With me are riches, honor, enduring wealth, and prosperity.",
                "My fruit is better than gold, yes, than fine gold; my yield than choice silver.",
                "I walk in the way of righteousness, in the midst of the paths of justice;",
                "That I may give wealth to those who love me. I fill their treasuries.",
                "\"Yahweh possessed me in the beginning of his work, before his deeds of old.",
                "I was set up from everlasting, from the beginning, before the earth existed.",
                "When there were no depths, I was brought forth, when there were no springs abounding with water.",
                "Before the mountains were settled in place, before the hills, I was brought forth;",
                "while as yet he had not made the earth, nor the fields, nor the beginning of the dust of the world.",
                "When he established the heavens, I was there; when he set a circle on the surface of the deep,",
                "when he established the clouds above, when the springs of the deep became strong,",
                "when he gave to the sea its boundary, that the waters should not violate his commandment, when he marked out the foundations of the earth;",
                "then I was the craftsman by his side. I was a delight day by day, always rejoicing before him,",
                "Rejoicing in his whole world. My delight was with the sons of men.",
                "\"Now therefore, my sons, listen to me, for blessed are those who keep my ways.",
                "Hear instruction, and be wise. Don\u2019t refuse it.",
                "Blessed is the man who hears me, watching daily at my gates, waiting at my door posts.",
                "For whoever finds me, finds life, and will obtain favor from Yahweh.",
                "But he who sins against me wrongs his own soul. All those who hate me love death.\""
            ],
            9: [
                "Wisdom has built her house. She has carved out her seven pillars.",
                "She has prepared her meat. She has mixed her wine. She has also set her table.",
                "She has sent out her maidens. She cries from the highest places of the city:",
                "\"Whoever is simple, let him turn in here!\" As for him who is void of understanding, she says to him,",
                "\"Come, eat some of my bread, Drink some of the wine which I have mixed!",
                "Leave your simple ways, and live. Walk in the way of understanding.\"",
                "He who corrects a mocker invites insult. He who reproves a wicked man invites abuse.",
                "Don\u2019t reprove a scoffer, lest he hate you. Reprove a wise man, and he will love you.",
                "Instruct a wise man, and he will be still wiser. Teach a righteous man, and he will increase in learning.",
                "The fear of Yahweh is the beginning of wisdom. The knowledge of the Holy One is understanding.",
                "For by me your days will be multiplied. The years of your life will be increased.",
                "If you are wise, you are wise for yourself. If you mock, you alone will bear it.",
                "The foolish woman is loud, Undisciplined, and knows nothing.",
                "She sits at the door of her house, on a seat in the high places of the city,",
                "To call to those who pass by, who go straight on their ways,",
                "\"Whoever is simple, let him turn in here.\" as for him who is void of understanding, she says to him,",
                "\"Stolen water is sweet. Food eaten in secret is pleasant.\"",
                "But he doesn\u2019t know that the dead are there, that her guests are in the depths of Sheol."
            ],
            10: [
                "The proverbs of Solomon. A wise son makes a glad father; but a foolish son brings grief to his mother.",
                "Treasures of wickedness profit nothing, but righteousness delivers from death.",
                "Yahweh will not allow the soul of the righteous to go hungry, but he thrusts away the desire of the wicked.",
                "He becomes poor who works with a lazy hand, but the hand of the diligent brings wealth.",
                "He who gathers in summer is a wise son, but he who sleeps during the harvest is a son who causes shame.",
                "Blessings are on the head of the righteous, but violence covers the mouth of the wicked.",
                "The memory of the righteous is blessed, but the name of the wicked will rot.",
                "The wise in heart accept commandments, but a chattering fool will fall.",
                "He who walks blamelessly walks surely, but he who perverts his ways will be found out.",
                "One winking with the eye causes sorrow, but a chattering fool will fall.",
                "The mouth of the righteous is a spring of life, but violence covers the mouth of the wicked.",
                "Hatred stirs up strife, but love covers all wrongs.",
                "Wisdom is found on the lips of him who has discernment, but a rod is for the back of him who is void of understanding.",
                "Wise men lay up knowledge, but the mouth of the foolish is near ruin.",
                "The rich man\u2019s wealth is his strong city. The destruction of the poor is their poverty.",
                "The labor of the righteous leads to life. The increase of the wicked leads to sin.",
                "He is in the way of life who heeds correction, but he who forsakes reproof leads others astray.",
                "He who hides hatred has lying lips. He who utters a slander is a fool.",
                "In the multitude of words there is no lack of disobedience, but he who restrains his lips does wisely.",
                "The tongue of the righteous is like choice silver. The heart of the wicked is of little worth.",
                "The lips of the righteous feed many, but the foolish die for lack of understanding.",
                "Yahweh\u2019s blessing brings wealth, and he adds no trouble to it.",
                "It is a fool\u2019s pleasure to do wickedness, but wisdom is a man of understanding\u2019s pleasure.",
                "What the wicked fear, will overtake them, but the desire of the righteous will be granted.",
                "When the whirlwind passes, the wicked is no more; but the righteous stand firm forever.",
                "As vinegar to the teeth, and as smoke to the eyes, so is the sluggard to those who send him.",
                "The fear of Yahweh prolongs days, but the years of the wicked shall be shortened.",
                "The prospect of the righteous is joy, but the hope of the wicked will perish.",
                "The way of Yahweh is a stronghold to the upright, but it is a destruction to the workers of iniquity.",
                "The righteous will never be removed, but the wicked will not dwell in the land.",
                "The mouth of the righteous brings forth wisdom, but the perverse tongue will be cut off.",
                "The lips of the righteous know what is acceptable, but the mouth of the wicked is perverse."
            ],
            11: [
                "A false balance is an abomination to Yahweh, but accurate weights are his delight.",
                "When pride comes, then comes shame, but with humility comes wisdom.",
                "The integrity of the upright shall guide them, but the perverseness of the treacherous shall destroy them.",
                "Riches don\u2019t profit in the day of wrath, but righteousness delivers from death.",
                "The righteousness of the blameless will direct his way, but the wicked shall fall by his own wickedness.",
                "The righteousness of the upright shall deliver them, but the unfaithful will be trapped by evil desires.",
                "When a wicked man dies, hope perishes, and expectation of power comes to nothing.",
                "A righteous person is delivered out of trouble, and the wicked takes his place.",
                "With his mouth the godless man destroys his neighbor, but the righteous will be delivered through knowledge.",
                "When it goes well with the righteous, the city rejoices. When the wicked perish, there is shouting.",
                "By the blessing of the upright, the city is exalted, but it is overthrown by the mouth of the wicked.",
                "One who despises his neighbor is void of wisdom, but a man of understanding holds his peace.",
                "One who brings gossip betrays a confidence, but one who is of a trustworthy spirit is one who keeps a secret.",
                "Where there is no wise guidance, the nation falls, but in the multitude of counselors there is victory.",
                "He who is collateral for a stranger will suffer for it, but he who refuses pledges of collateral is secure.",
                "A gracious woman obtains honor, but violent men obtain riches.",
                "The merciful man does good to his own soul, but he who is cruel troubles his own flesh.",
                "Wicked people earn deceitful wages, but one who sows righteousness reaps a sure reward.",
                "He who is truly righteous gets life. He who pursues evil gets death.",
                "Those who are perverse in heart are an abomination to Yahweh, but those whose ways are blameless are his delight.",
                "Most certainly, the evil man will not be unpunished, but the seed of the righteous will be delivered.",
                "Like a gold ring in a pig\u2019s snout, is a beautiful woman who lacks discretion.",
                "The desire of the righteous is only good. The expectation of the wicked is wrath.",
                "There is one who scatters, and increases yet more. There is one who withholds more than is appropriate, but gains poverty.",
                "The liberal soul shall be made fat. He who waters shall be watered also himself.",
                "People curse someone who withholds grain, but blessing will be on the head of him who sells it.",
                "He who diligently seeks good seeks favor, but he who searches after evil, it shall come to him.",
                "He who trusts in his riches will fall, but the righteous shall flourish as the green leaf.",
                "He who troubles his own house shall inherit the wind. The foolish shall be servant to the wise of heart.",
                "The fruit of the righteous is a tree of life. He who is wise wins souls.",
                "Behold, the righteous shall be repaid in the earth; how much more the wicked and the sinner!"
            ],
            12: [
                "Whoever loves correction loves knowledge, but he who hates reproof is stupid.",
                "A good man shall obtain favor from Yahweh, but he will condemn a man of wicked devices.",
                "A man shall not be established by wickedness, but the root of the righteous shall not be moved.",
                "A worthy woman is the crown of her husband, but a disgraceful wife is as rottenness in his bones.",
                "The thoughts of the righteous are just, but the advice of the wicked is deceitful.",
                "The words of the wicked are about lying in wait for blood, but the speech of the upright rescues them.",
                "The wicked are overthrown, and are no more, but the house of the righteous shall stand.",
                "A man shall be commended according to his wisdom, but he who has a warped mind shall be despised.",
                "Better is he who is lightly esteemed, and has a servant, than he who honors himself, and lacks bread.",
                "A righteous man respects the life of his animal, but the tender mercies of the wicked are cruel.",
                "He who tills his land shall have plenty of bread, but he who chases fantasies is void of understanding.",
                "The wicked desires the plunder of evil men, but the root of the righteous flourishes.",
                "An evil man is trapped by sinfulness of lips, but the righteous shall come out of trouble.",
                "A man shall be satisfied with good by the fruit of his mouth. The work of a man\u2019s hands shall be rewarded to him.",
                "The way of a fool is right in his own eyes, but he who is wise listens to counsel.",
                "A fool shows his annoyance the same day, but one who overlooks an insult is prudent.",
                "He who is truthful testifies honestly, but a false witness lies.",
                "There is one who speaks rashly like the piercing of a sword, but the tongue of the wise heals.",
                "Truth\u2019s lips will be established forever, but a lying tongue is only momentary.",
                "Deceit is in the heart of those who plot evil, but joy comes to the promoters of peace.",
                "No mischief shall happen to the righteous, but the wicked shall be filled with evil.",
                "Lying lips are an abomination to Yahweh, but those who do the truth are his delight.",
                "A prudent man keeps his knowledge, but the hearts of fools proclaim foolishness.",
                "The hands of the diligent ones shall rule, but laziness ends in slave labor.",
                "Anxiety in a man\u2019s heart weighs it down, but a kind word makes it glad.",
                "A righteous person is cautious in friendship, but the way of the wicked leads them astray.",
                "The slothful man doesn\u2019t roast his game, but the possessions of diligent men are prized.",
                "In the way of righteousness is life; in its path there is no death."
            ],
            13: [
                "A wise son listens to his father\u2019s instruction, but a scoffer doesn\u2019t listen to rebuke.",
                "By the fruit of his lips, a man enjoys good things; but the unfaithful crave violence.",
                "He who guards his mouth guards his soul. One who opens wide his lips comes to ruin.",
                "The soul of the sluggard desires, and has nothing, but the desire of the diligent shall be fully satisfied.",
                "A righteous man hates lies, but a wicked man brings shame and disgrace.",
                "Righteousness guards the way of integrity, but wickedness overthrows the sinner.",
                "There are some who pretend to be rich, yet have nothing. There are some who pretend to be poor, yet have great wealth.",
                "The ransom of a man\u2019s life is his riches, but the poor hear no threats.",
                "The light of the righteous shines brightly, but the lamp of the wicked is snuffed out.",
                "Pride only breeds quarrels, but with ones who take advice is wisdom.",
                "Wealth gained dishonestly dwindles away, but he who gathers by hand makes it grow.",
                "Hope deferred makes the heart sick, but when longing is fulfilled, it is a tree of life.",
                "Whoever despises instruction will pay for it, but he who respects a command will be rewarded.",
                "The teaching of the wise is a spring of life, to turn from the snares of death.",
                "Good understanding wins favor; but the way of the unfaithful is hard.",
                "Every prudent man acts from knowledge, but a fool exposes folly.",
                "A wicked messenger falls into trouble, but a trustworthy envoy gains healing.",
                "Poverty and shame come to him who refuses discipline, but he who heeds correction shall be honored.",
                "Longing fulfilled is sweet to the soul, but fools detest turning from evil.",
                "One who walks with wise men grows wise, but a companion of fools suffers harm.",
                "Misfortune pursues sinners, but prosperity rewards the righteous.",
                "A good man leaves an inheritance to his children\u2019s children, but the wealth of the sinner is stored for the righteous.",
                "An abundance of food is in poor people\u2019s fields, but injustice sweeps it away.",
                "One who spares the rod hates his son, but one who loves him is careful to discipline him.",
                "The righteous one eats to the satisfying of his soul, but the belly of the wicked goes hungry."
            ],
            14: [
                "Every wise woman builds her house, but the foolish one tears it down with her own hands.",
                "He who walks in his uprightness fears Yahweh, but he who is perverse in his ways despises him.",
                "The fool\u2019s talk brings a rod to his back, but the lips of the wise protect them.",
                "Where no oxen are, the crib is clean, but much increase is by the strength of the ox.",
                "A truthful witness will not lie, but a false witness pours out lies.",
                "A scoffer seeks wisdom, and doesn\u2019t find it, but knowledge comes easily to a discerning person.",
                "Stay away from a foolish man, for you won\u2019t find knowledge on his lips.",
                "The wisdom of the prudent is to think about his way, but the folly of fools is deceit.",
                "Fools mock at making atonement for sins, but among the upright there is good will.",
                "The heart knows its own bitterness and joy; he will not share these with a stranger.",
                "The house of the wicked will be overthrown, but the tent of the upright will flourish.",
                "There is a way which seems right to a man, but in the end it leads to death.",
                "Even in laughter the heart may be sorrowful, and mirth may end in heaviness.",
                "The unfaithful will be repaid for his own ways; likewise a good man will be rewarded for his ways.",
                "A simple man believes everything, but the prudent man carefully considers his ways.",
                "A wise man fears, and shuns evil, but the fool is hotheaded and reckless.",
                "He who is quick to become angry will commit folly, and a crafty man is hated.",
                "The simple inherit folly, but the prudent are crowned with knowledge.",
                "The evil bow down before the good, and the wicked at the gates of the righteous.",
                "The poor person is shunned even by his own neighbor, but the rich person has many friends.",
                "He who despises his neighbor sins, but blessed is he who has pity on the poor.",
                "Don\u2019t they go astray who plot evil? But love and faithfulness belong to those who plan good.",
                "In all hard work there is profit, but the talk of the lips leads only to poverty.",
                "The crown of the wise is their riches, but the folly of fools crowns them with folly.",
                "A truthful witness saves souls, but a false witness is deceitful.",
                "In the fear of Yahweh is a secure fortress, and he will be a refuge for his children.",
                "The fear of Yahweh is a fountain of life, turning people from the snares of death.",
                "In the multitude of people is the king\u2019s glory, but in the lack of people is the destruction of the prince.",
                "He who is slow to anger has great understanding, but he who has a quick temper displays folly.",
                "The life of the body is a heart at peace, but envy rots the bones.",
                "He who oppresses the poor shows contempt for his Maker, but he who is kind to the needy honors him.",
                "The wicked is brought down in his calamity, but in death, the righteous has a refuge.",
                "Wisdom rests in the heart of one who has understanding, and is even made known in the inward part of fools.",
                "Righteousness exalts a nation, but sin is a disgrace to any people.",
                "The king\u2019s favor is toward a servant who deals wisely, but his wrath is toward one who causes shame."
            ],
            15: [
                "A gentle answer turns away wrath, but a harsh word stirs up anger.",
                "The tongue of the wise commends knowledge, but the mouth of fools gush out folly.",
                "Yahweh\u2019s eyes are everywhere, keeping watch on the evil and the good.",
                "A gentle tongue is a tree of life, but deceit in it crushes the spirit.",
                "A fool despises his father\u2019s correction, but he who heeds reproof shows prudence.",
                "In the house of the righteous is much treasure, but the income of the wicked brings trouble.",
                "The lips of the wise spread knowledge; not so with the heart of fools.",
                "The sacrifice made by the wicked is an abomination to Yahweh, but the prayer of the upright is his delight.",
                "The way of the wicked is an abomination to Yahweh, but he loves him who follows after righteousness.",
                "There is stern discipline for one who forsakes the way: whoever hates reproof shall die.",
                "Sheol and Abaddon are before Yahweh\u2014how much more then the hearts of the children of men!",
                "A scoffer doesn\u2019t love to be reproved; he will not go to the wise.",
                "A glad heart makes a cheerful face; but an aching heart breaks the spirit.",
                "The heart of one who has understanding seeks knowledge, but the mouths of fools feed on folly.",
                "All the days of the afflicted are wretched, but one who has a cheerful heart enjoys a continual feast.",
                "Better is little, with the fear of Yahweh, than great treasure with trouble.",
                "Better is a dinner of herbs, where love is, than a fattened calf with hatred.",
                "A wrathful man stirs up contention, but one who is slow to anger appeases strife.",
                "The way of the sluggard is like a thorn patch, but the path of the upright is a highway.",
                "A wise son makes a father glad, but a foolish man despises his mother.",
                "Folly is joy to one who is void of wisdom, but a man of understanding keeps his way straight.",
                "Where there is no counsel, plans fail; but in a multitude of counselors they are established.",
                "Joy comes to a man with the reply of his mouth. How good is a word at the right time!",
                "The path of life leads upward for the wise, to keep him from going downward to Sheol.",
                "Yahweh will uproot the house of the proud, but he will keep the widow\u2019s borders intact.",
                "Yahweh detests the thoughts of the wicked, but the thoughts of the pure are pleasing.",
                "He who is greedy for gain troubles his own house, but he who hates bribes will live.",
                "The heart of the righteous weighs answers, but the mouth of the wicked gushes out evil.",
                "Yahweh is far from the wicked, but he hears the prayer of the righteous.",
                "The light of the eyes rejoices the heart. Good news gives health to the bones.",
                "The ear that listens to reproof lives, and will be at home among the wise.",
                "He who refuses correction despises his own soul, but he who listens to reproof gets understanding.",
                "The fear of Yahweh teaches wisdom. Before honor is humility."
            ],
            16: [
                "The plans of the heart belong to man, but the answer of the tongue is from Yahweh.",
                "All the ways of a man are clean in his own eyes; but Yahweh weighs the motives.",
                "Commit your deeds to Yahweh, and your plans shall succeed.",
                "Yahweh has made everything for its own end\u2014yes, even the wicked for the day of evil.",
                "Everyone who is proud in heart is an abomination to Yahweh: they shall certainly not be unpunished.",
                "By mercy and truth iniquity is atoned for. By the fear of Yahweh men depart from evil.",
                "When a man\u2019s ways please Yahweh, he makes even his enemies to be at peace with him.",
                "Better is a little with righteousness, than great revenues with injustice.",
                "A man\u2019s heart plans his course, but Yahweh directs his steps.",
                "Inspired judgments are on the lips of the king. He shall not betray his mouth.",
                "Honest balances and scales are Yahweh\u2019s; all the weights in the bag are his work.",
                "It is an abomination for kings to do wrong, for the throne is established by righteousness.",
                "Righteous lips are the delight of kings. They value one who speaks the truth.",
                "The king\u2019s wrath is a messenger of death, but a wise man will pacify it.",
                "In the light of the king\u2019s face is life. His favor is like a cloud of the spring rain.",
                "How much better it is to get wisdom than gold! Yes, to get understanding is to be chosen rather than silver.",
                "The highway of the upright is to depart from evil. He who keeps his way preserves his soul.",
                "Pride goes before destruction, and a haughty spirit before a fall.",
                "It is better to be of a lowly spirit with the poor, than to divide the plunder with the proud.",
                "He who heeds the Word finds prosperity. Whoever trusts in Yahweh is blessed.",
                "The wise in heart shall be called prudent. Pleasantness of the lips promotes instruction.",
                "Understanding is a fountain of life to one who has it, but the punishment of fools is their folly.",
                "The heart of the wise instructs his mouth, and adds learning to his lips.",
                "Pleasant words are a honeycomb, sweet to the soul, and health to the bones.",
                "There is a way which seems right to a man, but in the end it leads to death.",
                "The appetite of the laboring man labors for him; for his mouth urges him on.",
                "A worthless man devises mischief. His speech is like a scorching fire.",
                "A perverse man stirs up strife. A whisperer separates close friends.",
                "A man of violence entices his neighbor, and leads him in a way that is not good.",
                "One who winks his eyes to plot perversities, one who compresses his lips, is bent on evil.",
                "Gray hair is a crown of glory. It is attained by a life of righteousness.",
                "One who is slow to anger is better than the mighty; one who rules his spirit, than he who takes a city.",
                "The lot is cast into the lap, but its every decision is from Yahweh."
            ],
            17: [
                "Better is a dry morsel with quietness, than a house full of feasting with strife.",
                "A servant who deals wisely will rule over a son who causes shame, and shall have a part in the inheritance among the brothers.",
                "The refining pot is for silver, and the furnace for gold, but Yahweh tests the hearts.",
                "An evildoer heeds wicked lips. A liar gives ear to a mischievous tongue.",
                "Whoever mocks the poor reproaches his Maker. He who is glad at calamity shall not be unpunished.",
                "Children\u2019s children are the crown of old men; the glory of children are their parents.",
                "Arrogant speech isn\u2019t fitting for a fool, much less do lying lips fit a prince.",
                "A bribe is a precious stone in the eyes of him who gives it; wherever he turns, he prospers.",
                "He who covers an offense promotes love; but he who repeats a matter separates best friends.",
                "A rebuke enters deeper into one who has understanding than a hundred lashes into a fool.",
                "An evil man seeks only rebellion; therefore a cruel messenger shall be sent against him.",
                "Let a bear robbed of her cubs meet a man, rather than a fool in his folly.",
                "Whoever rewards evil for good, evil shall not depart from his house.",
                "The beginning of strife is like breaching a dam, therefore stop contention before quarreling breaks out.",
                "He who justifies the wicked, and he who condemns the righteous, both of them alike are an abomination to Yahweh.",
                "Why is there money in the hand of a fool to buy wisdom, since he has no understanding?",
                "A friend loves at all times; and a brother is born for adversity.",
                "A man void of understanding strikes hands, and becomes collateral in the presence of his neighbor.",
                "He who loves disobedience loves strife. One who builds a high gate seeks destruction.",
                "One who has a perverse heart doesn\u2019t find prosperity, and one who has a deceitful tongue falls into trouble.",
                "He who becomes the father of a fool grieves. The father of a fool has no joy.",
                "A cheerful heart makes good medicine, but a crushed spirit dries up the bones.",
                "A wicked man receives a bribe in secret, to pervert the ways of justice.",
                "Wisdom is before the face of one who has understanding, but the eyes of a fool wander to the ends of the earth.",
                "A foolish son brings grief to his father, and bitterness to her who bore him.",
                "Also to punish the righteous is not good, nor to flog officials for their integrity.",
                "He who spares his words has knowledge. He who is even tempered is a man of understanding.",
                "Even a fool, when he keeps silent, is counted wise. When he shuts his lips, he is thought to be discerning."
            ],
            18: [
                "An unfriendly man pursues selfishness, and defies all sound judgment.",
                "A fool has no delight in understanding, but only in revealing his own opinion.",
                "When wickedness comes, contempt also comes, and with shame comes disgrace.",
                "The words of a man\u2019s mouth are like deep waters. The fountain of wisdom is like a flowing brook.",
                "To be partial to the faces of the wicked is not good, nor to deprive the innocent of justice.",
                "A fool\u2019s lips come into strife, and his mouth invites beatings.",
                "A fool\u2019s mouth is his destruction, and his lips are a snare to his soul.",
                "The words of a gossip are like dainty morsels: they go down into a person\u2019s innermost parts.",
                "One who is slack in his work is brother to him who is a master of destruction.",
                "The name of Yahweh is a strong tower: the righteous run to him, and are safe.",
                "The rich man\u2019s wealth is his strong city, like an unscalable wall in his own imagination.",
                "Before destruction the heart of man is proud, but before honor is humility.",
                "He who gives answer before he hears, that is folly and shame to him.",
                "A man\u2019s spirit will sustain him in sickness, but a crushed spirit, who can bear?",
                "The heart of the discerning gets knowledge. The ear of the wise seeks knowledge.",
                "A man\u2019s gift makes room for him, and brings him before great men.",
                "He who pleads his cause first seems right; until another comes and questions him.",
                "The lot settles disputes, and keeps strong ones apart.",
                "A brother offended is more difficult than a fortified city; and disputes are like the bars of a castle.",
                "A man\u2019s stomach is filled with the fruit of his mouth. With the harvest of his lips he is satisfied.",
                "Death and life are in the power of the tongue; those who love it will eat its fruit.",
                "Whoever finds a wife finds a good thing, and obtains favor of Yahweh.",
                "The poor plead for mercy, but the rich answer harshly.",
                "A man of many companions may be ruined, but there is a friend who sticks closer than a brother."
            ],
            19: [
                "Better is the poor who walks in his integrity than he who is perverse in his lips and is a fool.",
                "It isn\u2019t good to have zeal without knowledge; nor being hasty with one\u2019s feet and missing the way.",
                "The foolishness of man subverts his way; his heart rages against Yahweh.",
                "Wealth adds many friends, but the poor is separated from his friend.",
                "A false witness shall not be unpunished. He who pours out lies shall not go free.",
                "Many will entreat the favor of a ruler, and everyone is a friend to a man who gives gifts.",
                "All the relatives of the poor shun him: how much more do his friends avoid him! He pursues them with pleas, but they are gone.",
                "He who gets wisdom loves his own soul. He who keeps understanding shall find good.",
                "A false witness shall not be unpunished. He who utters lies shall perish.",
                "Delicate living is not appropriate for a fool, much less for a servant to have rule over princes.",
                "The discretion of a man makes him slow to anger. It is his glory to overlook an offense.",
                "The king\u2019s wrath is like the roaring of a lion, but his favor is like dew on the grass.",
                "A foolish son is the calamity of his father. A wife\u2019s quarrels are a continual dripping.",
                "House and riches are an inheritance from fathers, but a prudent wife is from Yahweh.",
                "Slothfulness casts into a deep sleep. The idle soul shall suffer hunger.",
                "He who keeps the commandment keeps his soul, but he who is contemptuous in his ways shall die.",
                "He who has pity on the poor lends to Yahweh; he will reward him.",
                "Discipline your son, for there is hope; don\u2019t be a willing party to his death.",
                "A hot-tempered man must pay the penalty, for if you rescue him, you must do it again.",
                "Listen to counsel and receive instruction, that you may be wise in your latter end.",
                "There are many plans in a man\u2019s heart, but Yahweh\u2019s counsel will prevail.",
                "That which makes a man to be desired is his kindness. A poor man is better than a liar.",
                "The fear of Yahweh leads to life, then contentment; he rests and will not be touched by trouble.",
                "The sluggard buries his hand in the dish; he will not so much as bring it to his mouth again.",
                "Flog a scoffer, and the simple will learn prudence; rebuke one who has understanding, and he will gain knowledge.",
                "He who robs his father and drives away his mother, is a son who causes shame and brings reproach.",
                "If you stop listening to instruction, my son, you will stray from the words of knowledge.",
                "A corrupt witness mocks justice, and the mouth of the wicked gulps down iniquity.",
                "Penalties are prepared for scoffers, and beatings for the backs of fools."
            ],
            20: [
                "Wine is a mocker, and beer is a brawler. Whoever is led astray by them is not wise.",
                "The terror of a king is like the roaring of a lion. He who provokes him to anger forfeits his own life.",
                "It is an honor for a man to keep aloof from strife; but every fool will be quarreling.",
                "The sluggard will not plow by reason of the winter; therefore he shall beg in harvest, and have nothing.",
                "Counsel in the heart of man is like deep water; but a man of understanding will draw it out.",
                "Many men claim to be men of unfailing love, but who can find a faithful man?",
                "A righteous man walks in integrity. Blessed are his children after him.",
                "A king who sits on the throne of judgment scatters away all evil with his eyes.",
                "Who can say, \"I have made my heart pure. I am clean and without sin?\"",
                "Differing weights and differing measures, both of them alike are an abomination to Yahweh.",
                "Even a child makes himself known by his doings, whether his work is pure, and whether it is right.",
                "The hearing ear, and the seeing eye, Yahweh has made even both of them.",
                "Don\u2019t love sleep, lest you come to poverty. Open your eyes, and you shall be satisfied with bread.",
                "\"It\u2019s no good, it\u2019s no good,\" says the buyer; but when he is gone his way, then he boasts.",
                "There is gold and abundance of rubies; but the lips of knowledge are a rare jewel.",
                "Take the garment of one who puts up collateral for a stranger; and hold him in pledge for a wayward woman.",
                "Fraudulent food is sweet to a man, but afterwards his mouth is filled with gravel.",
                "Plans are established by advice; by wise guidance you wage war!",
                "He who goes about as a tale-bearer reveals secrets; therefore don\u2019t keep company with him who opens wide his lips.",
                "Whoever curses his father or his mother, his lamp shall be put out in blackness of darkness.",
                "An inheritance quickly gained at the beginning, won\u2019t be blessed in the end.",
                "Don\u2019t say, \"I will pay back evil.\" Wait for Yahweh, and he will save you.",
                "Yahweh detests differing weights, and dishonest scales are not pleasing.",
                "A man\u2019s steps are from Yahweh; how then can man understand his way?",
                "It is a snare to a man to make a rash dedication, then later to consider his vows.",
                "A wise king winnows out the wicked, and drives the threshing wheel over them.",
                "The spirit of man is Yahweh\u2019s lamp, searching all his innermost parts.",
                "Love and faithfulness keep the king safe. His throne is sustained by love.",
                "The glory of young men is their strength. The splendor of old men is their gray hair.",
                "Wounding blows cleanse away evil, and beatings purge the innermost parts."
            ],
            21: [
                "The king\u2019s heart is in Yahweh\u2019s hand like the watercourses. He turns it wherever he desires.",
                "Every way of a man is right in his own eyes, but Yahweh weighs the hearts.",
                "To do righteousness and justice is more acceptable to Yahweh than sacrifice.",
                "A high look, and a proud heart, the lamp of the wicked, is sin.",
                "The plans of the diligent surely lead to profit; and everyone who is hasty surely rushes to poverty.",
                "Getting treasures by a lying tongue is a fleeting vapor for those who seek death.",
                "The violence of the wicked will drive them away, because they refuse to do what is right.",
                "The way of the guilty is devious, but the conduct of the innocent is upright.",
                "It is better to dwell in the corner of the housetop, than to share a house with a contentious woman.",
                "The soul of the wicked desires evil; his neighbor finds no mercy in his eyes.",
                "When the mocker is punished, the simple gains wisdom. When the wise is instructed, he receives knowledge.",
                "The Righteous One considers the house of the wicked, and brings the wicked to ruin.",
                "Whoever stops his ears at the cry of the poor, he will also cry out, but shall not be heard.",
                "A gift in secret pacifies anger; and a bribe in the cloak, strong wrath.",
                "It is joy to the righteous to do justice; but it is a destruction to the workers of iniquity.",
                "The man who wanders out of the way of understanding shall rest in the assembly of the dead.",
                "He who loves pleasure shall be a poor man. He who loves wine and oil shall not be rich.",
                "The wicked is a ransom for the righteous; the treacherous for the upright.",
                "It is better to dwell in a desert land, than with a contentious and fretful woman.",
                "There is precious treasure and oil in the dwelling of the wise; but a foolish man swallows it up.",
                "He who follows after righteousness and kindness finds life, righteousness, and honor.",
                "A wise man scales the city of the mighty, and brings down the strength of its confidence.",
                "Whoever guards his mouth and his tongue keeps his soul from troubles.",
                "The proud and haughty man, \"scoffer\" is his name; he works in the arrogance of pride.",
                "The desire of the sluggard kills him, for his hands refuse to labor.",
                "There are those who covet greedily all day long; but the righteous give and don\u2019t withhold.",
                "The sacrifice of the wicked is an abomination: how much more, when he brings it with a wicked mind!",
                "A false witness will perish, and a man who listens speaks to eternity.",
                "A wicked man hardens his face; but as for the upright, he establishes his ways.",
                "There is no wisdom nor understanding nor counsel against Yahweh.",
                "The horse is prepared for the day of battle; but victory is with Yahweh."
            ],
            22: [
                "A good name is more desirable than great riches, and loving favor is better than silver and gold.",
                "The rich and the poor have this in common: Yahweh is the maker of them all.",
                "A prudent man sees danger, and hides himself; but the simple pass on, and suffer for it.",
                "The result of humility and the fear of Yahweh is wealth, honor, and life.",
                "Thorns and snares are in the path of the wicked: whoever guards his soul stays from them.",
                "Train up a child in the way he should go, and when he is old he will not depart from it.",
                "The rich rule over the poor. The borrower is servant to the lender.",
                "He who sows wickedness reaps trouble, and the rod of his fury will be destroyed.",
                "He who has a generous eye will be blessed; for he shares his food with the poor.",
                "Drive out the mocker, and strife will go out; yes, quarrels and insults will stop.",
                "He who loves purity of heart and speaks gracefully is the king\u2019s friend.",
                "The eyes of Yahweh watch over knowledge; but he frustrates the words of the unfaithful.",
                "The sluggard says, \"There is a lion outside! I will be killed in the streets!\"",
                "The mouth of an adulteress is a deep pit: he who is under Yahweh\u2019s wrath will fall into it.",
                "Folly is bound up in the heart of a child: the rod of discipline drives it far from him.",
                "Whoever oppresses the poor for his own increase and whoever gives to the rich, both come to poverty.",
                "Turn your ear, and listen to the words of the wise. Apply your heart to my teaching.",
                "For it is a pleasant thing if you keep them within you, if all of them are ready on your lips.",
                "That your trust may be in Yahweh, I teach you today, even you.",
                "Haven\u2019t I written to you thirty excellent things of counsel and knowledge,",
                "To teach you truth, reliable words, to give sound answers to the ones who sent you?",
                "Don\u2019t exploit the poor, because he is poor; and don\u2019t crush the needy in court;",
                "for Yahweh will plead their case, and plunder the life of those who plunder them.",
                "Don\u2019t befriend a hot-tempered man, and don\u2019t associate with one who harbors anger:",
                "lest you learn his ways, and ensnare your soul.",
                "Don\u2019t you be one of those who strike hands, of those who are collateral for debts.",
                "If you don\u2019t have means to pay, why should he take away your bed from under you?",
                "Don\u2019t move the ancient boundary stone, which your fathers have set up.",
                "Do you see a man skilled in his work? He will serve kings. He won\u2019t serve obscure men."
            ],
            23: [
                "When you sit to eat with a ruler, consider diligently what is before you;",
                "put a knife to your throat, if you are a man given to appetite.",
                "Don\u2019t be desirous of his dainties, since they are deceitful food.",
                "Don\u2019t weary yourself to be rich. In your wisdom, show restraint.",
                "Why do you set your eyes on that which is not? For it certainly sprouts wings like an eagle and flies in the sky.",
                "Don\u2019t eat the food of him who has a stingy eye, and don\u2019t crave his delicacies:",
                "for as he thinks about the cost, so he is. \"Eat and drink!\" he says to you, but his heart is not with you.",
                "The morsel which you have eaten you shall vomit up, and lose your good words.",
                "Don\u2019t speak in the ears of a fool, for he will despise the wisdom of your words.",
                "Don\u2019t move the ancient boundary stone. Don\u2019t encroach on the fields of the fatherless:",
                "for their Defender is strong. He will plead their case against you.",
                "Apply your heart to instruction, and your ears to the words of knowledge.",
                "Don\u2019t withhold correction from a child. If you punish him with the rod, he will not die.",
                "Punish him with the rod, and save his soul from Sheol.",
                "My son, if your heart is wise, then my heart will be glad, even mine:",
                "yes, my heart will rejoice, when your lips speak what is right.",
                "Don\u2019t let your heart envy sinners; but rather fear Yahweh all the day long.",
                "Indeed surely there is a future hope, and your hope will not be cut off.",
                "Listen, my son, and be wise, and keep your heart on the right path!",
                "Don\u2019t be among ones drinking too much wine, or those who gorge themselves on meat:",
                "for the drunkard and the glutton shall become poor; and drowsiness clothes them in rags.",
                "Listen to your father who gave you life, and don\u2019t despise your mother when she is old.",
                "Buy the truth, and don\u2019t sell it. Get wisdom, discipline, and understanding.",
                "The father of the righteous has great joy. Whoever fathers a wise child delights in him.",
                "Let your father and your mother be glad! Let her who bore you rejoice!",
                "My son, give me your heart; and let your eyes keep in my ways.",
                "For a prostitute is a deep pit; and a wayward wife is a narrow well.",
                "Yes, she lies in wait like a robber, and increases the unfaithful among men.",
                "Who has woe? Who has sorrow? Who has strife? Who has complaints? Who has needless bruises? Who has bloodshot eyes?",
                "Those who stay long at the wine; those who go to seek out mixed wine.",
                "Don\u2019t look at the wine when it is red, when it sparkles in the cup, when it goes down smoothly.",
                "In the end, it bites like a snake, and poisons like a viper.",
                "Your eyes will see strange things, and your mind will imagine confusing things.",
                "Yes, you will be as he who lies down in the midst of the sea, or as he who lies on top of the rigging:",
                "\"They hit me, and I was not hurt! They beat me, and I don\u2019t feel it! When will I wake up? I can do it again. I can find another.\""
            ],
            24: [
                "Don\u2019t be envious of evil men; neither desire to be with them:",
                "for their hearts plot violence, and their lips talk about mischief.",
                "Through wisdom a house is built; by understanding it is established;",
                "by knowledge the rooms are filled with all rare and beautiful treasure.",
                "A wise man has great power; and a knowledgeable man increases strength;",
                "for by wise guidance you wage your war; and victory is in many advisors.",
                "Wisdom is too high for a fool: he doesn\u2019t open his mouth in the gate.",
                "One who plots to do evil will be called a schemer.",
                "The schemes of folly are sin. The mocker is detested by men.",
                "If you falter in the time of trouble, your strength is small.",
                "Rescue those who are being led away to death! Indeed, hold back those who are staggering to the slaughter!",
                "If you say, \"Behold, we didn\u2019t know this;\" doesn\u2019t he who weighs the hearts consider it? He who keeps your soul, doesn\u2019t he know it? Shall he not render to every man according to his work?",
                "My son, eat honey, for it is good; the droppings of the honeycomb, which are sweet to your taste:",
                "so you shall know wisdom to be to your soul; if you have found it, then there will be a reward, your hope will not be cut off.",
                "Don\u2019t lay in wait, wicked man, against the habitation of the righteous. Don\u2019t destroy his resting place:",
                "for a righteous man falls seven times, and rises up again; but the wicked are overthrown by calamity.",
                "Don\u2019t rejoice when your enemy falls. Don\u2019t let your heart be glad when he is overthrown;",
                "lest Yahweh see it, and it displease him, and he turn away his wrath from him.",
                "Don\u2019t fret yourself because of evildoers; neither be envious of the wicked:",
                "for there will be no reward to the evil man; and the lamp of the wicked shall be snuffed out.",
                "My son, fear Yahweh and the king. Don\u2019t join those who are rebellious:",
                "for their calamity will rise suddenly; the destruction from them both\u2014who knows?",
                "These also are sayings of the wise. To show partiality in judgment is not good.",
                "He who says to the wicked, \"You are righteous;\" peoples shall curse him, and nations shall abhor him\u2014",
                "but it will go well with those who convict the guilty, and a rich blessing will come on them.",
                "An honest answer is like a kiss on the lips.",
                "Prepare your work outside, and get your fields ready. Afterwards, build your house.",
                "Don\u2019t be a witness against your neighbor without cause. Don\u2019t deceive with your lips.",
                "Don\u2019t say, \"I will do to him as he has done to me; I will render to the man according to his work.\"",
                "I went by the field of the sluggard, by the vineyard of the man void of understanding;",
                "Behold, it was all grown over with thorns. Its surface was covered with nettles, and its stone wall was broken down.",
                "Then I saw, and considered well. I saw, and received instruction:",
                "a little sleep, a little slumber, a little folding of the hands to sleep;",
                "so your poverty will come as a robber, and your want as an armed man."
            ],
            25: [
                "These also are proverbs of Solomon, which the men of Hezekiah king of Judah copied out.",
                "It is the glory of God to conceal a thing, but the glory of kings is to search out a matter.",
                "As the heavens for height, and the earth for depth, so the hearts of kings are unsearchable.",
                "Take away the dross from the silver, and material comes out for the refiner;",
                "Take away the wicked from the king\u2019s presence, and his throne will be established in righteousness.",
                "Don\u2019t exalt yourself in the presence of the king, or claim a place among great men;",
                "for it is better that it be said to you, \"Come up here,\" than that you should be put lower in the presence of the prince, whom your eyes have seen.",
                "Don\u2019t be hasty in bringing charges to court. What will you do in the end when your neighbor shames you?",
                "Debate your case with your neighbor, and don\u2019t betray the confidence of another;",
                "lest one who hears it put you to shame, and your bad reputation never depart.",
                "A word fitly spoken is like apples of gold in settings of silver.",
                "As an earring of gold, and an ornament of fine gold, so is a wise reprover to an obedient ear.",
                "As the cold of snow in the time of harvest, so is a faithful messenger to those who send him; for he refreshes the soul of his masters.",
                "As clouds and wind without rain, so is he who boasts of gifts deceptively.",
                "By patience a ruler is persuaded. A soft tongue breaks the bone.",
                "Have you found honey? Eat as much as is sufficient for you, lest you eat too much, and vomit it.",
                "Let your foot be seldom in your neighbor\u2019s house, lest he be weary of you, and hate you.",
                "A man who gives false testimony against his neighbor is like a club, a sword, or a sharp arrow.",
                "Confidence in someone unfaithful in time of trouble is like a bad tooth, or a lame foot.",
                "As one who takes away a garment in cold weather, or vinegar on soda, so is one who sings songs to a heavy heart.",
                "If your enemy is hungry, give him food to eat. If he is thirsty, give him water to drink:",
                "for you will heap coals of fire on his head, and Yahweh will reward you.",
                "The north wind brings forth rain: so a backbiting tongue brings an angry face.",
                "It is better to dwell in the corner of the housetop, than to share a house with a contentious woman.",
                "Like cold water to a thirsty soul, so is good news from a far country.",
                "Like a muddied spring, and a polluted well, so is a righteous man who gives way before the wicked.",
                "It is not good to eat much honey; nor is it honorable to seek one\u2019s own honor.",
                "Like a city that is broken down and without walls is a man whose spirit is without restraint."
            ],
            26: [
                "Like snow in summer, and as rain in harvest, so honor is not fitting for a fool.",
                "Like a fluttering sparrow, like a darting swallow, so the undeserved curse doesn\u2019t come to rest.",
                "A whip is for the horse, a bridle for the donkey, and a rod for the back of fools!",
                "Don\u2019t answer a fool according to his folly, lest you also be like him.",
                "Answer a fool according to his folly, lest he be wise in his own eyes.",
                "One who sends a message by the hand of a fool is cutting off feet and drinking violence.",
                "Like the legs of the lame that hang loose: so is a parable in the mouth of fools.",
                "As one who binds a stone in a sling, so is he who gives honor to a fool.",
                "Like a thornbush that goes into the hand of a drunkard, so is a parable in the mouth of fools.",
                "As an archer who wounds all, so is he who hires a fool or he who hires those who pass by.",
                "As a dog that returns to his vomit, so is a fool who repeats his folly.",
                "Do you see a man wise in his own eyes? There is more hope for a fool than for him.",
                "The sluggard says, \"There is a lion in the road! A fierce lion roams the streets!\"",
                "As the door turns on its hinges, so does the sluggard on his bed.",
                "The sluggard buries his hand in the dish. He is too lazy to bring it back to his mouth.",
                "The sluggard is wiser in his own eyes than seven men who answer with discretion.",
                "Like one who grabs a dog\u2019s ears is one who passes by and meddles in a quarrel not his own.",
                "Like a madman who shoots torches, arrows, and death,",
                "is the man who deceives his neighbor and says, \"Am I not joking?\"",
                "For lack of wood a fire goes out. Without gossip, a quarrel dies down.",
                "As coals are to hot embers, and wood to fire, so is a contentious man to kindling strife.",
                "The words of a whisperer are as dainty morsels, they go down into the innermost parts.",
                "Like silver dross on an earthen vessel are the lips of a fervent one with an evil heart.",
                "A malicious man disguises himself with his lips, but he harbors evil in his heart.",
                "When his speech is charming, don\u2019t believe him; for there are seven abominations in his heart.",
                "His malice may be concealed by deception, but his wickedness will be exposed in the assembly.",
                "Whoever digs a pit shall fall into it. Whoever rolls a stone, it will come back on him.",
                "A lying tongue hates those it hurts; and a flattering mouth works ruin."
            ],
            27: [
                "Don\u2019t boast about tomorrow; for you don\u2019t know what a day may bring forth.",
                "Let another man praise you, and not your own mouth; a stranger, and not your own lips.",
                "A stone is heavy, and sand is a burden; but a fool\u2019s provocation is heavier than both.",
                "Wrath is cruel, and anger is overwhelming; but who is able to stand before jealousy?",
                "Better is open rebuke than hidden love.",
                "Faithful are the wounds of a friend; although the kisses of an enemy are profuse.",
                "A full soul loathes a honeycomb; but to a hungry soul, every bitter thing is sweet.",
                "As a bird that wanders from her nest, so is a man who wanders from his home.",
                "Perfume and incense bring joy to the heart; so does earnest counsel from a man\u2019s friend.",
                "Don\u2019t forsake your friend and your father\u2019s friend. Don\u2019t go to your brother\u2019s house in the day of your disaster: better is a neighbor who is near than a distant brother.",
                "Be wise, my son, and bring joy to my heart, then I can answer my tormentor.",
                "A prudent man sees danger and takes refuge; but the simple pass on, and suffer for it.",
                "Take his garment when he puts up collateral for a stranger. Hold it for a wayward woman!",
                "He who blesses his neighbor with a loud voice early in the morning, it will be taken as a curse by him.",
                "A continual dropping on a rainy day and a contentious wife are alike:",
                "restraining her is like restraining the wind, or like grasping oil in his right hand.",
                "Iron sharpens iron; so a man sharpens his friend\u2019s countenance.",
                "Whoever tends the fig tree shall eat its fruit. He who looks after his master shall be honored.",
                "As water reflects a face, so a man\u2019s heart reflects the man.",
                "Sheol and Abaddon are never satisfied; and a man\u2019s eyes are never satisfied.",
                "The crucible is for silver, and the furnace for gold; but man is refined by his praise.",
                "Though you grind a fool in a mortar with a pestle along with grain, yet his foolishness will not be removed from him.",
                "Know well the state of your flocks, and pay attention to your herds:",
                "for riches are not forever, nor does even the crown endure to all generations.",
                "The hay is removed, and the new growth appears, the grasses of the hills are gathered in.",
                "The lambs are for your clothing, and the goats are the price of a field.",
                "There will be plenty of goats\u2019 milk for your food, for your family\u2019s food, and for the nourishment of your servant girls."
            ],
            28: [
                "The wicked flee when no one pursues; but the righteous are as bold as a lion.",
                "In rebellion, a land has many rulers, but order is maintained by a man of understanding and knowledge.",
                "A needy man who oppresses the poor is like a driving rain which leaves no crops.",
                "Those who forsake the law praise the wicked; but those who keep the law contend with them.",
                "Evil men don\u2019t understand justice; but those who seek Yahweh understand it fully.",
                "Better is the poor who walks in his integrity, than he who is perverse in his ways, and he is rich.",
                "Whoever keeps the law is a wise son; but he who is a companion of gluttons shames his father.",
                "He who increases his wealth by excessive interest gathers it for one who has pity on the poor.",
                "He who turns away his ear from hearing the law, even his prayer is an abomination.",
                "Whoever causes the upright to go astray in an evil way, he will fall into his own trap; but the blameless will inherit good.",
                "The rich man is wise in his own eyes; but the poor who has understanding sees through him.",
                "When the righteous triumph, there is great glory; but when the wicked rise, men hide themselves.",
                "He who conceals his sins doesn\u2019t prosper, but whoever confesses and renounces them finds mercy.",
                "Blessed is the man who always fears; but one who hardens his heart falls into trouble.",
                "As a roaring lion or a charging bear, so is a wicked ruler over helpless people.",
                "A tyrannical ruler lacks judgment. One who hates ill-gotten gain will have long days.",
                "A man who is tormented by life blood will be a fugitive until death; no one will support him.",
                "Whoever walks blamelessly is kept safe; but one with perverse ways will fall suddenly.",
                "One who works his land will have an abundance of food; but one who chases fantasies will have his fill of poverty.",
                "A faithful man is rich with blessings; but one who is eager to be rich will not go unpunished.",
                "To show partiality is not good; yet a man will do wrong for a piece of bread.",
                "A stingy man hurries after riches, and doesn\u2019t know that poverty waits for him.",
                "One who rebukes a man will afterward find more favor than one who flatters with the tongue.",
                "Whoever robs his father or his mother, and says, \"It\u2019s not wrong.\" He is a partner with a destroyer.",
                "One who is greedy stirs up strife; but one who trusts in Yahweh will prosper.",
                "One who trusts in himself is a fool; but one who walks in wisdom is kept safe.",
                "One who gives to the poor has no lack; but one who closes his eyes will have many curses.",
                "When the wicked rise, men hide themselves; but when they perish, the righteous thrive."
            ],
            29: [
                "He who is often rebuked and stiffens his neck will be destroyed suddenly, with no remedy.",
                "When the righteous thrive, the people rejoice; but when the wicked rule, the people groan.",
                "Whoever loves wisdom brings joy to his father; but a companion of prostitutes squanders his wealth.",
                "The king by justice makes the land stable, but he who takes bribes tears it down.",
                "A man who flatters his neighbor spreads a net for his feet.",
                "An evil man is snared by his sin, but the righteous can sing and be glad.",
                "The righteous care about justice for the poor. The wicked aren\u2019t concerned about knowledge.",
                "Mockers stir up a city, but wise men turn away anger.",
                "If a wise man goes to court with a foolish man, the fool rages or scoffs, and there is no peace.",
                "The bloodthirsty hate a man of integrity; and they seek the life of the upright.",
                "A fool vents all of his anger, but a wise man brings himself under control.",
                "If a ruler listens to lies, all of his officials are wicked.",
                "The poor man and the oppressor have this in common: Yahweh gives sight to the eyes of both.",
                "The king who fairly judges the poor, his throne shall be established forever.",
                "The rod of correction gives wisdom, but a child left to himself causes shame to his mother.",
                "When the wicked increase, sin increases; but the righteous will see their downfall.",
                "Correct your son, and he will give you peace; yes, he will bring delight to your soul.",
                "Where there is no revelation, the people cast off restraint; but one who keeps the law is blessed.",
                "A servant can\u2019t be corrected by words. Though he understands, yet he will not respond.",
                "Do you see a man who is hasty in his words? There is more hope for a fool than for him.",
                "He who pampers his servant from youth will have him become a son in the end.",
                "An angry man stirs up strife, and a wrathful man abounds in sin.",
                "A man\u2019s pride brings him low, but one of lowly spirit gains honor.",
                "Whoever is an accomplice of a thief is an enemy of his own soul. He takes an oath, but dares not testify.",
                "The fear of man proves to be a snare, but whoever puts his trust in Yahweh is kept safe.",
                "Many seek the ruler\u2019s favor, but a man\u2019s justice comes from Yahweh.",
                "A dishonest man detests the righteous, and the upright in their ways detest the wicked."
            ],
            30: [
                "The words of Agur the son of Jakeh, the oracle: the man says to Ithiel, to Ithiel and Ucal:",
                "\"Surely I am the most ignorant man, and don\u2019t have a man\u2019s understanding.",
                "I have not learned wisdom, neither do I have the knowledge of the Holy One.",
                "Who has ascended up into heaven, and descended? Who has gathered the wind in his fists? Who has bound the waters in his garment? Who has established all the ends of the earth? What is his name, and what is his son\u2019s name, if you know?",
                "\"Every word of God is flawless. He is a shield to those who take refuge in him.",
                "Don\u2019t you add to his words, lest he reprove you, and you be found a liar.",
                "\"Two things I have asked of you; don\u2019t deny me before I die:",
                "Remove far from me falsehood and lies. Give me neither poverty nor riches. Feed me with the food that is needful for me;",
                "lest I be full, deny you, and say, \u2018Who is Yahweh?\u2019 or lest I be poor, and steal, and so dishonor the name of my God.",
                "\"Don\u2019t slander a servant to his master, lest he curse you, and you be held guilty.",
                "There is a generation that curses their father, and doesn\u2019t bless their mother.",
                "There is a generation that is pure in their own eyes, yet are not washed from their filthiness.",
                "There is a generation, oh how lofty are their eyes! Their eyelids are lifted up.",
                "There is a generation whose teeth are like swords, and their jaws like knives, to devour the poor from the earth, and the needy from among men.",
                "\"The leach has two daughters: \u2018Give, give.\u2019 \"There are three things that are never satisfied; four that don\u2019t say, \u2018Enough:\u2019",
                "Sheol, the barren womb; the earth that is not satisfied with water; and the fire that doesn\u2019t say, \u2018Enough.\u2019",
                "\"The eye that mocks at his father, and scorns obedience to his mother: the ravens of the valley shall pick it out, the young eagles shall eat it.",
                "\"There are three things which are too amazing for me, four which I don\u2019t understand:",
                "The way of an eagle in the air; the way of a serpent on a rock; the way of a ship in the midst of the sea; and the way of a man with a maiden.",
                "\"So is the way of an adulterous woman: she eats and wipes her mouth, and says, \u2018I have done nothing wrong.\u2019",
                "\"For three things the earth tremble, and under four, it can\u2019t bear up:",
                "For a servant when he is king; a fool when he is filled with food;",
                "for an unloved woman when she is married; and a handmaid who is heir to her mistress.",
                "\"There are four things which are little on the earth, but they are exceedingly wise:",
                "the ants are not a strong people, yet they provide their food in the summer.",
                "The conies are but a feeble folk, yet make they their houses in the rocks.",
                "The locusts have no king, yet they advance in ranks.",
                "You can catch a lizard with your hands, yet it is in kings\u2019 palaces.",
                "\"There are three things which are stately in their march, four which are stately in going:",
                "The lion, which is mightiest among animals, and doesn\u2019t turn away for any;",
                "the greyhound, the male goat also; and the king against whom there is no rising up.",
                "\"If you have done foolishly in lifting up yourself, or if you have thought evil, put your hand over your mouth.",
                "For as the churning of milk brings forth butter, and the wringing of the nose brings forth blood; so the forcing of wrath brings forth strife.\""
            ],
            31: [
                "The words of king Lemuel; the oracle which his mother taught him.",
                "\"Oh, my son! Oh, son of my womb! Oh, son of my vows!",
                "Don\u2019t give your strength to women, nor your ways to that which destroys kings.",
                "It is not for kings, Lemuel; it is not for kings to drink wine; nor for princes to say, \u2018Where is strong drink?\u2019",
                "lest they drink, and forget the law, and pervert the justice due to anyone who is afflicted.",
                "Give strong drink to him who is ready to perish; and wine to the bitter in soul:",
                "Let him drink, and forget his poverty, and remember his misery no more.",
                "Open your mouth for the mute, in the cause of all who are left desolate.",
                "Open your mouth, judge righteously, and serve justice to the poor and needy.\"",
                "Who can find a worthy woman? For her price is far above rubies.",
                "The heart of her husband trusts in her. He shall have no lack of gain.",
                "She does him good, and not harm, all the days of her life.",
                "She seeks wool and flax, and works eagerly with her hands.",
                "She is like the merchant ships. She brings her bread from afar.",
                "She rises also while it is yet night, gives food to her household, and portions for her servant girls.",
                "She considers a field, and buys it. With the fruit of her hands, she plants a vineyard.",
                "She arms her waist with strength, and makes her arms strong.",
                "She perceives that her merchandise is profitable. Her lamp doesn\u2019t go out by night.",
                "She lays her hands to the distaff, and her hands hold the spindle.",
                "She opens her arms to the poor; yes, she extends her hands to the needy.",
                "She is not afraid of the snow for her household; for all her household are clothed with scarlet.",
                "She makes for herself carpets of tapestry. Her clothing is fine linen and purple.",
                "Her husband is respected in the gates, when he sits among the elders of the land.",
                "She makes linen garments and sells them, and delivers sashes to the merchant.",
                "Strength and dignity are her clothing. She laughs at the time to come.",
                "She opens her mouth with wisdom. Faithful instruction is on her tongue.",
                "She looks well to the ways of her household, and doesn\u2019t eat the bread of idleness.",
                "Her children rise up and call her blessed. Her husband also praises her:",
                "\"Many women do noble things, but you excel them all.\"",
                "Charm is deceitful, and beauty is vain; but a woman who fears Yahweh, she shall be praised.",
                "Give her of the fruit of her hands! Let her works praise her in the gates!"
            ]
        },
        21: {
            1: [
                "The words of the Preacher, the son of David, king in Jerusalem:",
                "\"Vanity of vanities,\" says the Preacher; \"Vanity of vanities, all is vanity.\"",
                "What does man gain from all his labor in which he labors under the sun?",
                "One generation goes, and another generation comes; but the earth remains forever.",
                "The sun also rises, and the sun goes down, and hurries to its place where it rises.",
                "The wind goes toward the south, and turns around to the north. It turns around continually as it goes, and the wind returns again to its courses.",
                "All the rivers run into the sea, yet the sea is not full. To the place where the rivers flow, there they flow again.",
                "All things are full of weariness beyond uttering. The eye is not satisfied with seeing, nor the ear filled with hearing.",
                "That which has been is that which shall be; and that which has been done is that which shall be done: and there is no new thing under the sun.",
                "Is there a thing of which it may be said, \"Behold, this is new?\" It has been long ago, in the ages which were before us.",
                "There is no memory of the former; neither shall there be any memory of the latter that are to come, among those that shall come after.",
                "I, the Preacher, was king over Israel in Jerusalem.",
                "I applied my heart to seek and to search out by wisdom concerning all that is done under the sky. It is a heavy burden that God has given to the sons of men to be afflicted with.",
                "I have seen all the works that are done under the sun; and behold, all is vanity and a chasing after wind.",
                "That which is crooked can\u2019t be made straight; and that which is lacking can\u2019t be counted.",
                "I said to myself, \"Behold, I have obtained for myself great wisdom above all who were before me in Jerusalem. Yes, my heart has had great experience of wisdom and knowledge.\"",
                "I applied my heart to know wisdom, and to know madness and folly. I perceived that this also was a chasing after wind.",
                "For in much wisdom is much grief; and he who increases knowledge increases sorrow."
            ],
            2: [
                "I said in my heart, \"Come now, I will test you with mirth: therefore enjoy pleasure;\" and behold, this also was vanity.",
                "I said of laughter, \"It is foolishness;\" and of mirth, \"What does it accomplish?\"",
                "I searched in my heart how to cheer my flesh with wine, my heart yet guiding me with wisdom, and how to lay hold of folly, until I might see what it was good for the sons of men that they should do under heaven all the days of their lives.",
                "I made myself great works. I built myself houses. I planted myself vineyards.",
                "I made myself gardens and parks, and I planted trees in them of all kinds of fruit.",
                "I made myself pools of water, to water from it the forest where trees were reared.",
                "I bought male servants and female servants, and had servants born in my house. I also had great possessions of herds and flocks, above all who were before me in Jerusalem;",
                "I also gathered silver and gold for myself, and the treasure of kings and of the provinces. I got myself male and female singers, and the delights of the sons of men\u2014musical instruments, and that of all sorts.",
                "So I was great, and increased more than all who were before me in Jerusalem. My wisdom also remained with me.",
                "Whatever my eyes desired, I didn\u2019t keep from them. I didn\u2019t withhold my heart from any joy, for my heart rejoiced because of all my labor, and this was my portion from all my labor.",
                "Then I looked at all the works that my hands had worked, and at the labor that I had labored to do; and behold, all was vanity and a chasing after wind, and there was no profit under the sun.",
                "I turned myself to consider wisdom, madness, and folly: for what can the king\u2019s successor do? Just that which has been done long ago.",
                "Then I saw that wisdom excels folly, as far as light excels darkness.",
                "The wise man\u2019s eyes are in his head, and the fool walks in darkness\u2014and yet I perceived that one event happens to them all.",
                "Then I said in my heart, \"As it happens to the fool, so will it happen even to me; and why was I then more wise?\" Then I said in my heart that this also is vanity.",
                "For of the wise man, even as of the fool, there is no memory for ever, since in the days to come all will have been long forgotten. Indeed, the wise man must die just like the fool!",
                "So I hated life, because the work that is worked under the sun was grievous to me; for all is vanity and a chasing after wind.",
                "I hated all my labor in which I labored under the sun, because I must leave it to the man who comes after me.",
                "Who knows whether he will be a wise man or a fool? Yet he will have rule over all of my labor in which I have labored, and in which I have shown myself wise under the sun. This also is vanity.",
                "Therefore I began to cause my heart to despair concerning all the labor in which I had labored under the sun.",
                "For there is a man whose labor is with wisdom, with knowledge, and with skillfulness; yet he shall leave it for his portion to a man who has not labored for it. This also is vanity and a great evil.",
                "For what has a man of all his labor, and of the striving of his heart, in which he labors under the sun?",
                "For all his days are sorrows, and his travail is grief; yes, even in the night his heart takes no rest. This also is vanity.",
                "There is nothing better for a man than that he should eat and drink, and make his soul enjoy good in his labor. This also I saw, that it is from the hand of God.",
                "For who can eat, or who can have enjoyment, more than I?",
                "For to the man who pleases him, God gives wisdom, knowledge, and joy; but to the sinner he gives travail, to gather and to heap up, that he may give to him who pleases God. This also is vanity and a chasing after wind."
            ],
            3: [
                "For everything there is a season, and a time for every purpose under heaven:",
                "a time to be born, and a time to die; a time to plant, and a time to pluck up that which is planted;",
                "a time to kill, and a time to heal; a time to break down, and a time to build up;",
                "a time to weep, and a time to laugh; a time to mourn, and a time to dance;",
                "a time to cast away stones, and a time to gather stones together; a time to embrace, and a time to refrain from embracing;",
                "a time to seek, and a time to lose; a time to keep, and a time to cast away;",
                "a time to tear, and a time to sew; a time to keep silence, and a time to speak;",
                "a time to love, and a time to hate; a time for war, and a time for peace.",
                "What profit has he who works in that in which he labors?",
                "I have seen the burden which God has given to the sons of men to be afflicted with.",
                "He has made everything beautiful in its time. He has also set eternity in their hearts, yet so that man can\u2019t find out the work that God has done from the beginning even to the end.",
                "I know that there is nothing better for them than to rejoice, and to do good as long as they live.",
                "Also that every man should eat and drink, and enjoy good in all his labor, is the gift of God.",
                "I know that whatever God does, it shall be forever. Nothing can be added to it, nor anything taken from it; and God has done it, that men should fear before him.",
                "That which is has been long ago, and that which is to be has been long ago: and God seeks again that which is passed away.",
                "Moreover I saw under the sun, in the place of justice, that wickedness was there; and in the place of righteousness, that wickedness was there.",
                "I said in my heart, \"God will judge the righteous and the wicked; for there is a time there for every purpose and for every work.\"",
                "I said in my heart, \"As for the sons of men, God tests them, so that they may see that they themselves are like animals.",
                "For that which happens to the sons of men happens to animals. Even one thing happens to them. As the one dies, so the other dies. Yes, they have all one breath; and man has no advantage over the animals: for all is vanity.",
                "All go to one place. All are from the dust, and all turn to dust again.",
                "Who knows the spirit of man, whether it goes upward, and the spirit of the animal, whether it goes downward to the earth?\"",
                "Therefore I saw that there is nothing better, than that a man should rejoice in his works; for that is his portion: for who can bring him to see what will be after him?"
            ],
            4: [
                "Then I returned and saw all the oppressions that are done under the sun: and behold, the tears of those who were oppressed, and they had no comforter; and on the side of their oppressors there was power; but they had no comforter.",
                "Therefore I praised the dead who have been long dead more than the living who are yet alive.",
                "Yes, better than them both is him who has not yet been, who has not seen the evil work that is done under the sun.",
                "Then I saw all the labor and achievement that is the envy of a man\u2019s neighbor. This also is vanity and a striving after wind.",
                "The fool folds his hands together and ruins himself.",
                "Better is a handful, with quietness, than two handfuls with labor and chasing after wind.",
                "Then I returned and saw vanity under the sun.",
                "There is one who is alone, and he has neither son nor brother. There is no end to all of his labor, neither are his eyes satisfied with wealth. \"For whom then, do I labor, and deprive my soul of enjoyment?\" This also is vanity. Yes, it is a miserable business.",
                "Two are better than one, because they have a good reward for their labor.",
                "For if they fall, the one will lift up his fellow; but woe to him who is alone when he falls, and doesn\u2019t have another to lift him up.",
                "Again, if two lie together, then they have warmth; but how can one keep warm alone?",
                "If a man prevails against one who is alone, two shall withstand him; and a threefold cord is not quickly broken.",
                "Better is a poor and wise youth than an old and foolish king who doesn\u2019t know how to receive admonition any more.",
                "For out of prison he came forth to be king; yes, even in his kingdom he was born poor.",
                "I saw all the living who walk under the sun, that they were with the youth, the other, who succeeded him.",
                "There was no end of all the people, even of all them over whom he was\u2014yet those who come after shall not rejoice in him. Surely this also is vanity and a chasing after wind."
            ],
            5: [
                "Guard your steps when you go to God\u2019s house; for to draw near to listen is better than to give the sacrifice of fools, for they don\u2019t know that they do evil.",
                "Don\u2019t be rash with your mouth, and don\u2019t let your heart be hasty to utter anything before God; for God is in heaven, and you on earth. Therefore let your words be few.",
                "For as a dream comes with a multitude of cares, so a fool\u2019s speech with a multitude of words.",
                "When you vow a vow to God, don\u2019t defer to pay it; for he has no pleasure in fools. Pay that which you vow.",
                "It is better that you should not vow, than that you should vow and not pay.",
                "Don\u2019t allow your mouth to lead you into sin. Don\u2019t protest before the messenger that this was a mistake. Why should God be angry at your voice, and destroy the work of your hands?",
                "For in the multitude of dreams there are vanities, as well as in many words: but you must fear God.",
                "If you see the oppression of the poor, and the violent taking away of justice and righteousness in a district, don\u2019t marvel at the matter: for one official is eyed by a higher one; and there are officials over them.",
                "Moreover the profit of the earth is for all. The king profits from the field.",
                "He who loves silver shall not be satisfied with silver; nor he who loves abundance, with increase: this also is vanity.",
                "When goods increase, those who eat them are increased; and what advantage is there to its owner, except to feast on them with his eyes?",
                "The sleep of a laboring man is sweet, whether he eats little or much; but the abundance of the rich will not allow him to sleep.",
                "There is a grievous evil which I have seen under the sun: wealth kept by its owner to his harm.",
                "Those riches perish by misfortune, and if he has fathered a son, there is nothing in his hand.",
                "As he came forth from his mother\u2019s womb, naked shall he go again as he came, and shall take nothing for his labor, which he may carry away in his hand.",
                "This also is a grievous evil, that in all points as he came, so shall he go. And what profit does he have who labors for the wind?",
                "All his days he also eats in darkness, he is frustrated, and has sickness and wrath.",
                "Behold, that which I have seen to be good and proper is for one to eat and to drink, and to enjoy good in all his labor, in which he labors under the sun, all the days of his life which God has given him; for this is his portion.",
                "Every man also to whom God has given riches and wealth, and has given him power to eat of it, and to take his portion, and to rejoice in his labor\u2014this is the gift of God.",
                "For he shall not often reflect on the days of his life; because God occupies him with the joy of his heart."
            ],
            6: [
                "There is an evil which I have seen under the sun, and it is heavy on men:",
                "a man to whom God gives riches, wealth, and honor, so that he lacks nothing for his soul of all that he desires, yet God gives him no power to eat of it, but an alien eats it. This is vanity, and it is an evil disease.",
                "If a man fathers a hundred children, and lives many years, so that the days of his years are many, but his soul is not filled with good, and moreover he has no burial; I say, that a stillborn child is better than he:",
                "for it comes in vanity, and departs in darkness, and its name is covered with darkness.",
                "Moreover it has not seen the sun nor known it. This has rest rather than the other.",
                "Yes, though he live a thousand years twice told, and yet fails to enjoy good, don\u2019t all go to one place?",
                "All the labor of man is for his mouth, and yet the appetite is not filled.",
                "For what advantage has the wise more than the fool? What has the poor man, that knows how to walk before the living?",
                "Better is the sight of the eyes than the wandering of the desire. This also is vanity and a chasing after wind.",
                "Whatever has been, its name was given long ago; and it is known what man is; neither can he contend with him who is mightier than he.",
                "For there are many words that create vanity. What does that profit man?",
                "For who knows what is good for man in life, all the days of his vain life which he spends like a shadow? For who can tell a man what will be after him under the sun?"
            ],
            7: [
                "A good name is better than fine perfume; and the day of death better than the day of one\u2019s birth.",
                "It is better to go to the house of mourning than to go to the house of feasting: for that is the end of all men, and the living should take this to heart.",
                "Sorrow is better than laughter; for by the sadness of the face the heart is made good.",
                "The heart of the wise is in the house of mourning; but the heart of fools is in the house of mirth.",
                "It is better to hear the rebuke of the wise, than for a man to hear the song of fools.",
                "For as the crackling of thorns under a pot, so is the laughter of the fool. This also is vanity.",
                "Surely extortion makes the wise man foolish; and a bribe destroys the understanding.",
                "Better is the end of a thing than its beginning. The patient in spirit is better than the proud in spirit.",
                "Don\u2019t be hasty in your spirit to be angry, for anger rests in the bosom of fools.",
                "Don\u2019t say, \"Why were the former days better than these?\" For you do not ask wisely about this.",
                "Wisdom is as good as an inheritance. Yes, it is more excellent for those who see the sun.",
                "For wisdom is a defense, even as money is a defense; but the excellency of knowledge is that wisdom preserves the life of him who has it.",
                "Consider the work of God, for who can make that straight, which he has made crooked?",
                "In the day of prosperity be joyful, and in the day of adversity consider; yes, God has made the one side by side with the other, to the end that man should not find out anything after him.",
                "All this have I seen in my days of vanity: there is a righteous man who perishes in his righteousness, and there is a wicked man who lives long in his evildoing.",
                "Don\u2019t be overly righteous, neither make yourself overly wise. Why should you destroy yourself?",
                "Don\u2019t be too wicked, neither be foolish. Why should you die before your time?",
                "It is good that you should take hold of this. Yes, also from that don\u2019t withdraw your hand; for he who fears God will come forth from them all.",
                "Wisdom is a strength to the wise man more than ten rulers who are in a city.",
                "Surely there is not a righteous man on earth, who does good and doesn\u2019t sin.",
                "Also don\u2019t take heed to all words that are spoken, lest you hear your servant curse you;",
                "for often your own heart knows that you yourself have likewise cursed others.",
                "All this have I proved in wisdom. I said, \"I will be wise;\" but it was far from me.",
                "That which is, is far off and exceedingly deep. Who can find it out?",
                "I turned around, and my heart sought to know and to search out, and to seek wisdom and the scheme of things, and to know that wickedness is stupidity, and that foolishness is madness.",
                "I find more bitter than death the woman whose heart is snares and traps, whose hands are chains. Whoever pleases God shall escape from her; but the sinner will be ensnared by her.",
                "\"Behold, I have found this,\" says the Preacher, \"one to another, to find out the scheme;",
                "which my soul still seeks; but I have not found. One man among a thousand have I found; but I have not found a woman among all those.",
                "Behold, this only have I found: that God made man upright; but they search for many schemes.\""
            ],
            8: [
                "Who is like the wise man? And who knows the interpretation of a thing? A man\u2019s wisdom makes his face shine, and the hardness of his face is changed.",
                "I say, \"Keep the king\u2019s command!\" because of the oath to God.",
                "Don\u2019t be hasty to go out of his presence. Don\u2019t persist in an evil thing, for he does whatever pleases him,",
                "for the king\u2019s word is supreme. Who can say to him, \"What are you doing?\"",
                "Whoever keeps the commandment shall not come to harm, and his wise heart will know the time and procedure.",
                "For there is a time and procedure for every purpose, although the misery of man is heavy on him.",
                "For he doesn\u2019t know that which will be; for who can tell him how it will be?",
                "There is no man who has power over the spirit to contain the spirit; neither does he have power over the day of death. There is no discharge in war; neither shall wickedness deliver those who practice it.",
                "All this have I seen, and applied my mind to every work that is done under the sun. There is a time in which one man has power over another to his hurt.",
                "So I saw the wicked buried. Indeed they came also from holiness. They went and were forgotten in the city where they did this. This also is vanity.",
                "Because sentence against an evil work is not executed speedily, therefore the heart of the sons of men is fully set in them to do evil.",
                "Though a sinner commits crimes a hundred times, and lives long, yet surely I know that it will be better with those who fear God, who are reverent before him.",
                "But it shall not be well with the wicked, neither shall he lengthen days like a shadow; because he doesn\u2019t fear God.",
                "There is a vanity which is done on the earth, that there are righteous men to whom it happens according to the work of the wicked. Again, there are wicked men to whom it happens according to the work of the righteous. I said that this also is vanity.",
                "Then I commended mirth, because a man has no better thing under the sun, than to eat, and to drink, and to be joyful: for that will accompany him in his labor all the days of his life which God has given him under the sun.",
                "When I applied my heart to know wisdom, and to see the business that is done on the earth (for also there is that neither day nor night sees sleep with his eyes),",
                "then I saw all the work of God, that man can\u2019t find out the work that is done under the sun, because however much a man labors to seek it out, yet he won\u2019t find it. Yes even though a wise man thinks he can comprehend it, he won\u2019t be able to find it."
            ],
            9: [
                "For all this I laid to my heart, even to explore all this: that the righteous, and the wise, and their works, are in the hand of God; whether it is love or hatred, man doesn\u2019t know it; all is before them.",
                "All things come alike to all. There is one event to the righteous and to the wicked; to the good, to the clean, to the unclean, to him who sacrifices, and to him who doesn\u2019t sacrifice. As is the good, so is the sinner; he who takes an oath, as he who fears an oath.",
                "This is an evil in all that is done under the sun, that there is one event to all: yes also, the heart of the sons of men is full of evil, and madness is in their heart while they live, and after that they go to the dead.",
                "For to him who is joined with all the living there is hope; for a living dog is better than a dead lion.",
                "For the living know that they will die, but the dead don\u2019t know anything, neither do they have any more a reward; for their memory is forgotten.",
                "Also their love, their hatred, and their envy has perished long ago; neither have they any more a portion forever in anything that is done under the sun.",
                "Go your way\u2014eat your bread with joy, and drink your wine with a merry heart; for God has already accepted your works.",
                "Let your garments be always white, and don\u2019t let your head lack oil.",
                "Live joyfully with the wife whom you love all the days of your life of vanity, which he has given you under the sun, all your days of vanity: for that is your portion in life, and in your labor in which you labor under the sun.",
                "Whatever your hand finds to do, do it with your might; for there is no work, nor device, nor knowledge, nor wisdom, in Sheol, where you are going.",
                "I returned, and saw under the sun, that the race is not to the swift, nor the battle to the strong, neither yet bread to the wise, nor yet riches to men of understanding, nor yet favor to men of skill; but time and chance happen to them all.",
                "For man also doesn\u2019t know his time. As the fish that are taken in an evil net, and as the birds that are caught in the snare, even so are the sons of men snared in an evil time, when it falls suddenly on them.",
                "I have also seen wisdom under the sun in this way, and it seemed great to me.",
                "There was a little city, and few men within it; and a great king came against it, besieged it, and built great bulwarks against it.",
                "Now a poor wise man was found in it, and he by his wisdom delivered the city; yet no man remembered that same poor man.",
                "Then I said, \"Wisdom is better than strength.\" Nevertheless the poor man\u2019s wisdom is despised, and his words are not heard.",
                "The words of the wise heard in quiet are better than the cry of him who rules among fools.",
                "Wisdom is better than weapons of war; but one sinner destroys much good."
            ],
            10: [
                "Dead flies cause the oil of the perfumer to send forth an evil odor; so does a little folly outweigh wisdom and honor.",
                "A wise man\u2019s heart is at his right hand, but a fool\u2019s heart at his left.",
                "Yes also, when the fool walks by the way, his understanding fails him, and he says to everyone that he is a fool.",
                "If the spirit of the ruler rises up against you, don\u2019t leave your place; for gentleness lays great offenses to rest.",
                "There is an evil which I have seen under the sun, the sort of error which proceeds from the ruler.",
                "Folly is set in great dignity, and the rich sit in a low place.",
                "I have seen servants on horses, and princes walking like servants on the earth.",
                "He who digs a pit may fall into it; and whoever breaks through a wall may be bitten by a snake.",
                "Whoever carves out stones may be injured by them. Whoever splits wood may be endangered thereby.",
                "If the axe is blunt, and one doesn\u2019t sharpen the edge, then he must use more strength; but skill brings success.",
                "If the snake bites before it is charmed, then is there no profit for the charmer\u2019s tongue.",
                "The words of a wise man\u2019s mouth are gracious; but a fool is swallowed by his own lips.",
                "The beginning of the words of his mouth is foolishness; and the end of his talk is mischievous madness.",
                "A fool also multiplies words. Man doesn\u2019t know what will be; and that which will be after him, who can tell him?",
                "The labor of fools wearies every one of them; for he doesn\u2019t know how to go to the city.",
                "Woe to you, land, when your king is a child, and your princes eat in the morning!",
                "Happy are you, land, when your king is the son of nobles, and your princes eat in due season, for strength, and not for drunkenness!",
                "By slothfulness the roof sinks in; and through idleness of the hands the house leaks.",
                "A feast is made for laughter, and wine makes the life glad; and money is the answer for all things.",
                "Don\u2019t curse the king, no, not in your thoughts; and don\u2019t curse the rich in your bedroom: for a bird of the sky may carry your voice, and that which has wings may tell the matter."
            ],
            11: [
                "Cast your bread on the waters; for you shall find it after many days.",
                "Give a portion to seven, yes, even to eight; for you don\u2019t know what evil will be on the earth.",
                "If the clouds are full of rain, they empty themselves on the earth; and if a tree falls toward the south, or toward the north, in the place where the tree falls, there shall it be.",
                "He who observes the wind won\u2019t sow; and he who regards the clouds won\u2019t reap.",
                "As you don\u2019t know what is the way of the wind, nor how the bones grow in the womb of her who is with child; even so you don\u2019t know the work of God who does all.",
                "In the morning sow your seed, and in the evening don\u2019t withhold your hand; for you don\u2019t know which will prosper, whether this or that, or whether they both will be equally good.",
                "Truly the light is sweet, and a pleasant thing it is for the eyes to see the sun.",
                "Yes, if a man lives many years, let him rejoice in them all; but let him remember the days of darkness, for they shall be many. All that comes is vanity.",
                "Rejoice, young man, in your youth, and let your heart cheer you in the days of your youth, and walk in the ways of your heart, and in the sight of your eyes; but know that for all these things God will bring you into judgment.",
                "Therefore remove sorrow from your heart, and put away evil from your flesh; for youth and the dawn of life are vanity."
            ],
            12: [
                "Remember also your Creator in the days of your youth, before the evil days come, and the years draw near, when you will say, \"I have no pleasure in them;\"",
                "Before the sun, the light, the moon, and the stars are darkened, and the clouds return after the rain;",
                "in the day when the keepers of the house shall tremble, and the strong men shall bow themselves, and the grinders cease because they are few, and those who look out of the windows are darkened,",
                "and the doors shall be shut in the street; when the sound of the grinding is low, and one shall rise up at the voice of a bird, and all the daughters of music shall be brought low;",
                "yes, they shall be afraid of heights, and terrors will be in the way; and the almond tree shall blossom, and the grasshopper shall be a burden, and desire shall fail; because man goes to his everlasting home, and the mourners go about the streets:",
                "before the silver cord is severed, or the golden bowl is broken, or the pitcher is broken at the spring, or the wheel broken at the cistern,",
                "and the dust returns to the earth as it was, and the spirit returns to God who gave it.",
                "\"Vanity of vanities,\" says the Preacher. \"All is vanity!\"",
                "Further, because the Preacher was wise, he still taught the people knowledge. Yes, he pondered, sought out, and set in order many proverbs.",
                "The Preacher sought to find out acceptable words, and that which was written blamelessly, words of truth.",
                "The words of the wise are like goads; and like nails well fastened are words from the masters of assemblies, which are given from one shepherd.",
                "Furthermore, my son, be admonished: of making many books there is no end; and much study is a weariness of the flesh.",
                "This is the end of the matter. All has been heard. Fear God, and keep his commandments; for this is the whole duty of man.",
                "For God will bring every work into judgment, with every hidden thing, whether it is good, or whether it is evil."
            ]
        },
        22: {
            1: [
                "The Song of songs, which is Solomon\u2019s.",
                "Let him kiss me with the kisses of his mouth; for your love is better than wine.",
                "Your oils have a pleasing fragrance. Your name is oil poured forth, therefore the virgins love you.",
                "Take me away with you. Let us hurry. The king has brought me into his rooms. We will be glad and rejoice in you. We will praise your love more than wine! They are right to love you.",
                "I am dark, but lovely, you daughters of Jerusalem, like Kedar\u2019s tents, like Solomon\u2019s curtains.",
                "Don\u2019t stare at me because I am dark, because the sun has scorched me. My mother\u2019s sons were angry with me. They made me keeper of the vineyards. I haven\u2019t kept my own vineyard.",
                "Tell me, you whom my soul loves, where you graze your flock, where you rest them at noon; For why should I be as one who is veiled beside the flocks of your companions?",
                "If you don\u2019t know, most beautiful among women, follow the tracks of the sheep. Graze your young goats beside the shepherds\u2019 tents.",
                "I have compared you, my love, to a steed in Pharaoh\u2019s chariots.",
                "Your cheeks are beautiful with earrings, your neck with strings of jewels.",
                "We will make you earrings of gold, with studs of silver.",
                "While the king sat at his table, my perfume spread its fragrance.",
                "My beloved is to me a sachet of myrrh, that lies between my breasts.",
                "My beloved is to me a cluster of henna blossoms from the vineyards of En Gedi.",
                "Behold, you are beautiful, my love. Behold, you are beautiful. Your eyes are doves.",
                "Behold, you are beautiful, my beloved, yes, pleasant; and our couch is verdant.",
                "The beams of our house are cedars. Our rafters are firs."
            ],
            2: [
                "I am a rose of Sharon, a lily of the valleys.",
                "As a lily among thorns, so is my love among the daughters.",
                "As the apple tree among the trees of the wood, so is my beloved among the sons. I sat down under his shadow with great delight, his fruit was sweet to my taste.",
                "He brought me to the banquet hall. His banner over me is love.",
                "Strengthen me with raisins, refresh me with apples; For I am faint with love.",
                "His left hand is under my head. His right hand embraces me.",
                "I adjure you, daughters of Jerusalem, by the roes, or by the hinds of the field, that you not stir up, nor awaken love, until it so desires.",
                "The voice of my beloved! Behold, he comes, leaping on the mountains, skipping on the hills.",
                "My beloved is like a roe or a young hart. Behold, he stands behind our wall! He looks in at the windows. He glances through the lattice.",
                "My beloved spoke, and said to me, \"Rise up, my love, my beautiful one, and come away.",
                "For, behold, the winter is past. The rain is over and gone.",
                "The flowers appear on the earth. The time of the singing has come, and the voice of the turtledove is heard in our land.",
                "The fig tree ripens her green figs. The vines are in blossom. They give forth their fragrance. Arise, my love, my beautiful one, and come away.\"",
                "My dove in the clefts of the rock, In the hiding places of the mountainside, Let me see your face. Let me hear your voice; for your voice is sweet, and your face is lovely.",
                "Catch for us the foxes, the little foxes that spoil the vineyards; for our vineyards are in blossom.",
                "My beloved is mine, and I am his. He browses among the lilies.",
                "Until the day is cool, and the shadows flee away, turn, my beloved, and be like a roe or a young hart on the mountains of Bether."
            ],
            3: [
                "By night on my bed, I sought him whom my soul loves. I sought him, but I didn\u2019t find him.",
                "I will get up now, and go about the city; in the streets and in the squares I will seek him whom my soul loves. I sought him, but I didn\u2019t find him.",
                "The watchmen who go about the city found me; \"Have you seen him whom my soul loves?\"",
                "I had scarcely passed from them, when I found him whom my soul loves. I held him, and would not let him go, until I had brought him into my mother\u2019s house, into the room of her who conceived me.",
                "I adjure you, daughters of Jerusalem, by the roes, or by the hinds of the field, that you not stir up, nor awaken love, until it so desires.",
                "Who is this who comes up from the wilderness like pillars of smoke, perfumed with myrrh and frankincense, with all spices of the merchant?",
                "Behold, it is Solomon\u2019s carriage! Sixty mighty men are around it, of the mighty men of Israel.",
                "They all handle the sword, and are expert in war. Every man has his sword on his thigh, because of fear in the night.",
                "King Solomon made himself a carriage of the wood of Lebanon.",
                "He made its pillars of silver, its bottom of gold, its seat of purple, its midst being paved with love, from the daughters of Jerusalem.",
                "Go forth, you daughters of Zion, and see king Solomon, with the crown with which his mother has crowned him, in the day of his weddings, in the day of the gladness of his heart."
            ],
            4: [
                "Behold, you are beautiful, my love. Behold, you are beautiful. Your eyes are doves behind your veil. Your hair is as a flock of goats, that descend from Mount Gilead.",
                "Your teeth are like a newly shorn flock, which have come up from the washing, where every one of them has twins. None is bereaved among them.",
                "Your lips are like scarlet thread. Your mouth is lovely. Your temples are like a piece of a pomegranate behind your veil.",
                "Your neck is like David\u2019s tower built for an armory, whereon a thousand shields hang, all the shields of the mighty men.",
                "Your two breasts are like two fawns that are twins of a roe, which feed among the lilies.",
                "Until the day is cool, and the shadows flee away, I will go to the mountain of myrrh, to the hill of frankincense.",
                "You are all beautiful, my love. There is no spot in you.",
                "Come with me from Lebanon, my bride, with me from Lebanon. Look from the top of Amana, from the top of Senir and Hermon, from the lions\u2019 dens, from the mountains of the leopards.",
                "You have ravished my heart, my sister, my bride. You have ravished my heart with one of your eyes, with one chain of your neck.",
                "How beautiful is your love, my sister, my bride! How much better is your love than wine! The fragrance of your perfumes than all kinds of spices!",
                "Your lips, my bride, drip like the honeycomb. Honey and milk are under your tongue. The smell of your garments is like the smell of Lebanon.",
                "A locked up garden is my sister, my bride; a locked up spring, a sealed fountain.",
                "Your shoots are an orchard of pomegranates, with precious fruits: henna with spikenard plants,",
                "spikenard and saffron, calamus and cinnamon, with every kind of incense tree; myrrh and aloes, with all the best spices,",
                "a fountain of gardens, a well of living waters, flowing streams from Lebanon.",
                "Awake, north wind; and come, you south! Blow on my garden, that its spices may flow out. Let my beloved come into his garden, and taste his precious fruits."
            ],
            5: [
                "I have come into my garden, my sister, my bride. I have gathered my myrrh with my spice; I have eaten my honeycomb with my honey; I have drunk my wine with my milk. Eat, friends! Drink, yes, drink abundantly, beloved.",
                "I was asleep, but my heart was awake. It is the voice of my beloved who knocks: \"Open to me, my sister, my love, my dove, my undefiled; for my head is filled with dew, and my hair with the dampness of the night.\"",
                "I have taken off my robe. Indeed, must I put it on? I have washed my feet. Indeed, must I soil them?",
                "My beloved thrust his hand in through the latch opening. My heart pounded for him.",
                "I rose up to open for my beloved. My hands dripped with myrrh, my fingers with liquid myrrh, on the handles of the lock.",
                "I opened to my beloved; but my beloved left; and had gone away. My heart went out when he spoke. I looked for him, but I didn\u2019t find him. I called him, but he didn\u2019t answer.",
                "The watchmen who go about the city found me. They beat me. They bruised me. The keepers of the walls took my cloak away from me.",
                "I adjure you, daughters of Jerusalem, If you find my beloved, that you tell him that I am faint with love.",
                "How is your beloved better than another beloved, you fairest among women? How is your beloved better than another beloved, that you do so adjure us?",
                "My beloved is white and ruddy. The best among ten thousand.",
                "His head is like the purest gold. His hair is bushy, black as a raven.",
                "His eyes are like doves beside the water brooks, washed with milk, mounted like jewels.",
                "His cheeks are like a bed of spices with towers of perfumes. His lips are like lilies, dropping liquid myrrh.",
                "His hands are like rings of gold set with beryl. His body is like ivory work overlaid with sapphires.",
                "His legs are like pillars of marble set on sockets of fine gold. His appearance is like Lebanon, excellent as the cedars.",
                "His mouth is sweetness; yes, he is altogether lovely. This is my beloved, and this is my friend, daughters of Jerusalem."
            ],
            6: [
                "Where has your beloved gone, you fairest among women? Where has your beloved turned, that we may seek him with you?",
                "My beloved has gone down to his garden, to the beds of spices, to feed in the gardens, and to gather lilies.",
                "I am my beloved\u2019s, and my beloved is mine. He browses among the lilies,",
                "You are beautiful, my love, as Tirzah, lovely as Jerusalem, awesome as an army with banners.",
                "Turn away your eyes from me, for they have overcome me. Your hair is like a flock of goats, that lie along the side of Gilead.",
                "Your teeth are like a flock of ewes, which have come up from the washing; of which every one has twins; none is bereaved among them.",
                "Your temples are like a piece of a pomegranate behind your veil.",
                "There are sixty queens, eighty concubines, and virgins without number.",
                "My dove, my perfect one, is unique. She is her mother\u2019s only daughter. She is the favorite one of her who bore her. The daughters saw her, and called her blessed; the queens and the concubines, and they praised her.",
                "Who is she who looks forth as the morning, beautiful as the moon, clear as the sun, and awesome as an army with banners?",
                "I went down into the nut tree grove, to see the green plants of the valley, to see whether the vine budded, and the pomegranates were in flower.",
                "Without realizing it, my desire set me with my royal people\u2019s chariots.",
                "Return, return, Shulammite! Return, return, that we may gaze at you. Why do you desire to gaze at the Shulammite, as at the dance of Mahanaim?"
            ],
            7: [
                "How beautiful are your feet in sandals, prince\u2019s daughter! Your rounded thighs are like jewels, the work of the hands of a skillful workman.",
                "Your body is like a round goblet, no mixed wine is wanting. Your waist is like a heap of wheat, set about with lilies.",
                "Your two breasts are like two fawns, that are twins of a roe.",
                "Your neck is like an ivory tower. Your eyes are like the pools in Heshbon by the gate of Bathrabbim. Your nose is like the tower of Lebanon which looks toward Damascus.",
                "Your head on you is like Carmel. The hair of your head like purple. The king is held captive in its tresses.",
                "How beautiful and how pleasant you are, love, for delights!",
                "This, your stature, is like a palm tree, your breasts like its fruit.",
                "I said, \"I will climb up into the palm tree. I will take hold of its fruit.\" Let your breasts be like clusters of the vine, the smell of your breath like apples,",
                "Your mouth like the best wine, that goes down smoothly for my beloved, gliding through the lips of those who are asleep.",
                "I am my beloved\u2019s. His desire is toward me.",
                "Come, my beloved, let us go forth into the field. Let us lodge in the villages.",
                "Let\u2019s go early up to the vineyards. Let\u2019s see whether the vine has budded, its blossom is open, and the pomegranates are in flower. There I will give you my love.",
                "The mandrakes give forth fragrance. At our doors are all kinds of precious fruits, new and old, which I have stored up for you, my beloved."
            ],
            8: [
                "Oh that you were like my brother, who nursed from the breasts of my mother! If I found you outside, I would kiss you; yes, and no one would despise me.",
                "I would lead you, bringing you into my mother\u2019s house, who would instruct me. I would have you drink spiced wine, of the juice of my pomegranate.",
                "His left hand would be under my head. His right hand would embrace me.",
                "I adjure you, daughters of Jerusalem, that you not stir up, nor awaken love, until it so desires.",
                "Who is this who comes up from the wilderness, leaning on her beloved? Under the apple tree I aroused you. There your mother conceived you. There she was in labor and bore you.",
                "Set me as a seal on your heart, as a seal on your arm; for love is strong as death. Jealousy is as cruel as Sheol. Its flashes are flashes of fire, a very flame of Yahweh.",
                "Many waters can\u2019t quench love, neither can floods drown it. If a man would give all the wealth of his house for love, he would be utterly scorned.",
                "We have a little sister. She has no breasts. What shall we do for our sister in the day when she is to be spoken for?",
                "If she is a wall, we will build on her a turret of silver. if she is a door, we will enclose her with boards of cedar.",
                "I am a wall, and my breasts like towers, then I was in his eyes like one who found peace.",
                "Solomon had a vineyard at Baal Hamon. He leased out the vineyard to keepers. Each was to bring a thousand shekels of silver for its fruit.",
                "My own vineyard is before me. The thousand are for you, Solomon; two hundred for those who tend its fruit.",
                "You who dwell in the gardens, with friends in attendance, let me hear your voice!",
                "Come away, my beloved! Be like a gazelle or a young stag on the mountains of spices!"
            ]
        },
        23: {
            1: [
                "The vision of Isaiah the son of Amoz, which he saw concerning Judah and Jerusalem, in the days of Uzziah, Jotham, Ahaz, and Hezekiah, kings of Judah.",
                "Hear, heavens, and listen, earth; for Yahweh has spoken: \"I have nourished and brought up children, and they have rebelled against me.",
                "The ox knows his owner, and the donkey his master\u2019s crib; but Israel doesn\u2019t know, my people don\u2019t consider.\"",
                "Ah sinful nation, a people loaded with iniquity, a seed of evildoers, children who deal corruptly! They have forsaken Yahweh. They have despised the Holy One of Israel. They are estranged and backward.",
                "Why should you be beaten more, that you revolt more and more? The whole head is sick, and the whole heart faint.",
                "From the sole of the foot even to the head there is no soundness in it: wounds, welts, and open sores. They haven\u2019t been closed, neither bandaged, neither soothed with oil.",
                "Your country is desolate. Your cities are burned with fire. Strangers devour your land in your presence, and it is desolate, as overthrown by strangers.",
                "The daughter of Zion is left like a shelter in a vineyard, like a hut in a field of melons, like a besieged city.",
                "Unless Yahweh of Armies had left to us a very small remnant, we would have been as Sodom; we would have been like Gomorrah.",
                "Hear the word of Yahweh, you rulers of Sodom! Listen to the law of our God, you people of Gomorrah!",
                "\"What are the multitude of your sacrifices to me?,\" says Yahweh. \"I have had enough of the burnt offerings of rams, and the fat of fed animals. I don\u2019t delight in the blood of bulls, or of lambs, or of male goats.",
                "When you come to appear before me, who has required this at your hand, to trample my courts?",
                "Bring no more vain offerings. Incense is an abomination to me; new moons, Sabbaths, and convocations: I can\u2019t bear with evil assemblies.",
                "My soul hates your New Moons and your appointed feasts. They are a burden to me. I am weary of bearing them.",
                "When you spread forth your hands, I will hide my eyes from you. Yes, when you make many prayers, I will not hear. Your hands are full of blood.",
                "Wash yourselves, make yourself clean. Put away the evil of your doings from before my eyes. Cease to do evil.",
                "Learn to do well. Seek justice. Relieve the oppressed. Judge the fatherless. Plead for the widow.\"",
                "\"Come now, and let us reason together,\" says Yahweh: \"Though your sins be as scarlet, they shall be as white as snow. Though they be red like crimson, they shall be as wool.",
                "If you are willing and obedient, you shall eat the good of the land;",
                "but if you refuse and rebel, you shall be devoured with the sword; for the mouth of Yahweh has spoken it.\"",
                "How the faithful city has become a prostitute! She was full of justice; righteousness lodged in her, but now murderers.",
                "Your silver has become dross, your wine mixed with water.",
                "Your princes are rebellious, and companions of thieves. Everyone loves bribes, and follows after rewards. They don\u2019t judge the fatherless, neither does the cause of the widow come to them.",
                "Therefore the Lord, Yahweh of Armies, the Mighty One of Israel, says: \"Ah, I will get relief from my adversaries, and avenge myself of my enemies;",
                "and I will turn my hand on you, thoroughly purge away your dross, and will take away all your tin.",
                "I will restore your judges as at the first, and your counselors as at the beginning. Afterward you shall be called \u2018The city of righteousness, a faithful town.\u2019",
                "Zion shall be redeemed with justice, and her converts with righteousness.",
                "But the destruction of transgressors and sinners shall be together, and those who forsake Yahweh shall be consumed.",
                "For they shall be ashamed of the oaks which you have desired, and you shall be confounded for the gardens that you have chosen.",
                "For you shall be as an oak whose leaf fades, and as a garden that has no water.",
                "The strong will be like tinder, and his work like a spark. They will both burn together, and no one will quench them.\""
            ],
            2: [
                "This is what Isaiah the son of Amoz saw concerning Judah and Jerusalem.",
                "It shall happen in the latter days, that the mountain of Yahweh\u2019s house shall be established on the top of the mountains, and shall be raised above the hills; and all nations shall flow to it.",
                "Many peoples shall go and say, \"Come, let\u2019s go up to the mountain of Yahweh, to the house of the God of Jacob; and he will teach us of his ways, and we will walk in his paths.\" For out of Zion the law shall go forth, and the word of Yahweh from Jerusalem.",
                "He will judge between the nations, and will decide concerning many peoples; and they shall beat their swords into plowshares, and their spears into pruning hooks. Nation shall not lift up sword against nation, neither shall they learn war any more.",
                "House of Jacob, come, and let us walk in the light of Yahweh.",
                "For you have forsaken your people, the house of Jacob, because they are filled from the east, with those who practice divination like the Philistines, and they clasp hands with the children of foreigners.",
                "Their land is full of silver and gold, neither is there any end of their treasures. Their land also is full of horses, neither is there any end of their chariots.",
                "Their land also is full of idols. They worship the work of their own hands, that which their own fingers have made.",
                "Man is brought low, and mankind is humbled; therefore don\u2019t forgive them.",
                "Enter into the rock, and hide in the dust, from before the terror of Yahweh, and from the glory of his majesty.",
                "The lofty looks of man will be brought low, the haughtiness of men will be bowed down, and Yahweh alone will be exalted in that day.",
                "For there will be a day of Yahweh of Armies for all that is proud and haughty, and for all that is lifted up; and it shall be brought low:",
                "For all the cedars of Lebanon, that are high and lifted up, for all the oaks of Bashan,",
                "For all the high mountains, for all the hills that are lifted up,",
                "For every lofty tower, for every fortified wall,",
                "For all the ships of Tarshish, and for all pleasant imagery.",
                "The loftiness of man shall be bowed down, and the haughtiness of men shall be brought low; and Yahweh alone shall be exalted in that day.",
                "The idols shall utterly pass away.",
                "Men shall go into the caves of the rocks, and into the holes of the earth, from before the terror of Yahweh, and from the glory of his majesty, when he arises to shake the earth mightily.",
                "In that day, men shall cast away their idols of silver, and their idols of gold, which have been made for themselves to worship, to the moles and to the bats;",
                "To go into the caverns of the rocks, and into the clefts of the ragged rocks, from before the terror of Yahweh, and from the glory of his majesty, when he arises to shake the earth mightily.",
                "Stop trusting in man, whose breath is in his nostrils; for of what account is he?"
            ],
            3: [
                "For, behold, the Lord, Yahweh of Armies, takes away from Jerusalem and from Judah supply and support, the whole supply of bread, and the whole supply of water;",
                "the mighty man, the man of war, the judge, the prophet, the diviner, the elder,",
                "the captain of fifty, the honorable man, the counselor, the skilled craftsman, and the clever enchanter.",
                "I will give boys to be their princes, and children shall rule over them.",
                "The people will be oppressed, everyone by another, and everyone by his neighbor. The child will behave himself proudly against the old man, and the base against the honorable.",
                "Indeed a man shall take hold of his brother in the house of his father, saying, \"You have clothing, you be our ruler, and let this ruin be under your hand.\"",
                "In that day he will cry out, saying, \"I will not be a healer; for in my house is neither bread nor clothing. You shall not make me ruler of the people.\"",
                "For Jerusalem is ruined, and Judah is fallen; because their tongue and their doings are against Yahweh, to provoke the eyes of his glory.",
                "The look of their faces testify against them. They parade their sin like Sodom. They don\u2019t hide it. Woe to their soul! For they have brought disaster upon themselves.",
                "Tell the righteous \"Good!\" For they shall eat the fruit of their deeds.",
                "Woe to the wicked! Disaster is upon them; for the deeds of his hands will be paid back to him.",
                "As for my people, children are their oppressors, and women rule over them. My people, those who lead you cause you to err, and destroy the way of your paths.",
                "Yahweh stands up to contend, and stands to judge the peoples.",
                "Yahweh will enter into judgment with the elders of his people, and their leaders: \"It is you who have eaten up the vineyard. The spoil of the poor is in your houses.",
                "What do you mean that you crush my people, and grind the face of the poor?\" says the Lord, Yahweh of Armies.",
                "Moreover Yahweh said, \"Because the daughters of Zion are haughty, and walk with outstretched necks and flirting eyes, walking to trip as they go, jingling ornaments on their feet;",
                "therefore the Lord brings sores on the crown of the head of the women of Zion, and Yahweh will make their scalps bald.\"",
                "In that day the Lord will take away the beauty of their anklets, the headbands, the crescent necklaces,",
                "the earrings, the bracelets, the veils,",
                "the headdresses, the ankle chains, the sashes, the perfume bottles, the charms,",
                "the signet rings, the nose rings,",
                "the fine robes, the capes, the cloaks, the purses,",
                "the hand mirrors, the fine linen garments, the tiaras, and the shawls.",
                "It shall happen that instead of sweet spices, there shall be rottenness; instead of a belt, a rope; instead of well set hair, baldness; instead of a robe, a wearing of sackcloth; and branding instead of beauty.",
                "Your men shall fall by the sword, and your mighty in the war.",
                "Her gates shall lament and mourn; and she shall be desolate and sit on the ground."
            ],
            4: [
                "Seven women shall take hold of one man in that day, saying, \"We will eat our own bread, and wear our own clothing: only let us be called by your name. Take away our reproach.\"",
                "In that day, Yahweh\u2019s branch will be beautiful and glorious, and the fruit of the land will be the beauty and glory of the survivors of Israel.",
                "It will happen, that he who is left in Zion, and he who remains in Jerusalem, shall be called holy, even everyone who is written among the living in Jerusalem;",
                "when the Lord shall have washed away the filth of the daughters of Zion, and shall have purged the blood of Jerusalem from its midst, by the spirit of justice, and by the spirit of burning.",
                "Yahweh will create over the whole habitation of Mount Zion, and over her assemblies, a cloud and smoke by day, and the shining of a flaming fire by night; for over all the glory will be a canopy.",
                "There will be a pavilion for a shade in the daytime from the heat, and for a refuge and for a shelter from storm and from rain."
            ],
            5: [
                "Let me sing for my well beloved a song of my beloved about his vineyard. My beloved had a vineyard on a very fruitful hill.",
                "He dug it up, gathered out its stones, planted it with the choicest vine, built a tower in its midst, and also cut out a winepress therein. He looked for it to yield grapes, but it yielded wild grapes.",
                "\"Now, inhabitants of Jerusalem and men of Judah, please judge between me and my vineyard.",
                "What could have been done more to my vineyard, that I have not done in it? Why, when I looked for it to yield grapes, did it yield wild grapes?",
                "Now I will tell you what I will do to my vineyard. I will take away its hedge, and it will be eaten up. I will break down its wall of it, and it will be trampled down.",
                "I will lay it a wasteland. It won\u2019t be pruned nor hoed, but it will grow briers and thorns. I will also command the clouds that they rain no rain on it.\"",
                "For the vineyard of Yahweh of Armies is the house of Israel, and the men of Judah his pleasant plant: and he looked for justice, but, behold, oppression; for righteousness, but, behold, a cry of distress.",
                "Woe to those who join house to house, who lay field to field, until there is no room, and you are made to dwell alone in the midst of the land!",
                "In my ears, Yahweh of Armies says: \"Surely many houses will be desolate, even great and beautiful, unoccupied.",
                "For ten acres of vineyard shall yield one bath, and a homer of seed shall yield an ephah.\"",
                "Woe to those who rise up early in the morning, that they may follow strong drink; who stay late into the night, until wine inflames them!",
                "The harp, lyre, tambourine, and flute, with wine, are at their feasts; but they don\u2019t respect the work of Yahweh, neither have they considered the operation of his hands.",
                "Therefore my people go into captivity for lack of knowledge. Their honorable men are famished, and their multitudes are parched with thirst.",
                "Therefore Sheol has enlarged its desire, and opened its mouth without measure; and their glory, their multitude, their pomp, and he who rejoices among them, descend into it.",
                "So man is brought low, mankind is humbled, and the eyes of the arrogant ones are humbled;",
                "but Yahweh of Armies is exalted in justice, and God the Holy One is sanctified in righteousness.",
                "Then the lambs will graze as in their pasture, and strangers will eat the ruins of the rich.",
                "Woe to those who draw iniquity with cords of falsehood, and wickedness as with cart rope;",
                "Who say, \"Let him make speed, let him hasten his work, that we may see it; and let the counsel of the Holy One of Israel draw near and come, that we may know it!\"",
                "Woe to those who call evil good, and good evil; who put darkness for light, and light for darkness; who put bitter for sweet, and sweet for bitter!",
                "Woe to those who are wise in their own eyes, and prudent in their own sight!",
                "Woe to those who are mighty to drink wine, and champions at mixing strong drink;",
                "who acquit the guilty for a bribe, but deny justice for the innocent!",
                "Therefore as the tongue of fire devours the stubble, and as the dry grass sinks down in the flame, so their root shall be as rottenness, and their blossom shall go up as dust; because they have rejected the law of Yahweh of Armies, and despised the word of the Holy One of Israel.",
                "Therefore Yahweh\u2019s anger burns against his people, and he has stretched out his hand against them, and has struck them. The mountains tremble, and their dead bodies are as refuse in the midst of the streets. For all this, his anger is not turned away, but his hand is still stretched out.",
                "He will lift up a banner to the nations from far, and he will whistle for them from the end of the earth. Behold, they will come speedily and swiftly.",
                "None shall be weary nor stumble among them; none shall slumber nor sleep; neither shall the belt of their waist be untied, nor the latchet of their shoes be broken:",
                "whose arrows are sharp, and all their bows bent. Their horses\u2019 hoofs will be like flint, and their wheels like a whirlwind.",
                "Their roaring will be like a lioness. They will roar like young lions. Yes, they shall roar, and seize their prey and carry it off, and there will be no one to deliver.",
                "They will roar against them in that day like the roaring of the sea. If one looks to the land behold, darkness and distress. The light is darkened in its clouds."
            ],
            6: [
                "In the year that king Uzziah died, I saw the Lord sitting on a throne, high and lifted up; and his train filled the temple.",
                "Above him stood the seraphim. Each one had six wings. With two he covered his face. With two he covered his feet. With two he flew.",
                "One called to another, and said, \"Holy, holy, holy, is Yahweh of Armies! The whole earth is full of his glory!\"",
                "The foundations of the thresholds shook at the voice of him who called, and the house was filled with smoke.",
                "Then I said, \"Woe is me! For I am undone, because I am a man of unclean lips, and I dwell in the midst of a people of unclean lips: for my eyes have seen the King, Yahweh of Armies!\"",
                "Then one of the seraphim flew to me, having a live coal in his hand, which he had taken with the tongs from off the altar.",
                "He touched my mouth with it, and said, \"Behold, this has touched your lips; and your iniquity is taken away, and your sin forgiven.\"",
                "I heard the Lord\u2019s voice, saying, \"Whom shall I send, and who will go for us?\" Then I said, \"Here I am. Send me!\"",
                "He said, \"Go, and tell this people, \u2018You hear indeed, but don\u2019t understand; and you see indeed, but don\u2019t perceive.\u2019",
                "Make the heart of this people fat. Make their ears heavy, and shut their eyes; lest they see with their eyes, and hear with their ears, and understand with their heart, and turn again, and be healed.\"",
                "Then I said, \"Lord, how long?\" He answered, \"Until cities are waste without inhabitant, and houses without man, and the land becomes utterly waste,",
                "And Yahweh has removed men far away, and the forsaken places are many in the midst of the land.",
                "If there is a tenth left in it, that also will in turn be consumed: as a terebinth, and as an oak, whose stock remains when they are felled; so the holy seed is its stock.\""
            ],
            7: [
                "It happened in the days of Ahaz the son of Jotham, the son of Uzziah, king of Judah, that Rezin the king of Syria, and Pekah the son of Remaliah, king of Israel, went up to Jerusalem to war against it, but could not prevail against it.",
                "It was told the house of David, saying, \"Syria is allied with Ephraim.\" His heart trembled, and the heart of his people, as the trees of the forest tremble with the wind.",
                "Then Yahweh said to Isaiah, \"Go out now to meet Ahaz, you, and Shearjashub your son, at the end of the conduit of the upper pool, on the highway of the fuller\u2019s field.",
                "Tell him, \u2018Be careful, and keep calm. Don\u2019t be afraid, neither let your heart be faint because of these two tails of smoking torches, for the fierce anger of Rezin and Syria, and of the son of Remaliah.",
                "Because Syria, Ephraim, and the son of Remaliah, have plotted evil against you, saying,",
                "\"Let\u2019s go up against Judah, and tear it apart, and let\u2019s divide it among ourselves, and set up a king in its midst, even the son of Tabeel.\"",
                "This is what the Lord Yahweh says: \"It shall not stand, neither shall it happen.\"",
                "For the head of Syria is Damascus, and the head of Damascus is Rezin; and within sixty-five years Ephraim shall be broken in pieces, so that it shall not be a people;",
                "and the head of Ephraim is Samaria, and the head of Samaria is Remaliah\u2019s son. If you will not believe, surely you shall not be established.\u2019\"",
                "Yahweh spoke again to Ahaz, saying,",
                "\"Ask a sign of Yahweh your God; ask it either in the depth, or in the height above.\"",
                "But Ahaz said, \"I will not ask, neither will I tempt Yahweh.\"",
                "He said, \"Listen now, house of David. Is it not enough for you to try the patience of men, that you will try the patience of my God also?",
                "Therefore the Lord himself will give you a sign. Behold, the virgin will conceive, and bear a son, and shall call his name Immanuel.",
                "He shall eat butter and honey when he knows to refuse the evil, and choose the good.",
                "For before the child knows to refuse the evil, and choose the good, the land whose two kings you abhor shall be forsaken.",
                "Yahweh will bring on you, on your people, and on your father\u2019s house, days that have not come, from the day that Ephraim departed from Judah; even the king of Assyria.",
                "It will happen in that day that Yahweh will whistle for the fly that is in the uttermost part of the rivers of Egypt, and for the bee that is in the land of Assyria.",
                "They shall come, and shall all rest in the desolate valleys, in the clefts of the rocks, on all thorn hedges, and on all pastures.",
                "In that day the Lord will shave with a razor that is hired in the parts beyond the River, even with the king of Assyria, the head and the hair of the feet; and it shall also consume the beard.",
                "It shall happen in that day that a man shall keep alive a young cow, and two sheep;",
                "and it shall happen, that because of the abundance of milk which they shall give he shall eat butter: for everyone will eat butter and honey that is left in the midst of the land.",
                "It will happen in that day that every place where there were a thousand vines at a thousand silver shekels, shall be for briers and thorns.",
                "People will go there with arrows and with bow, because all the land will be briers and thorns.",
                "All the hills that were cultivated with the hoe, you shall not come there for fear of briers and thorns; but it shall be for the sending forth of oxen, and for the treading of sheep.\""
            ],
            8: [
                "Yahweh said to me, \"Take a large tablet, and write on it with a man\u2019s pen, \u2018For Maher Shalal Hash Baz;\u2019",
                "and I will take for myself faithful witnesses to testify: Uriah the priest, and Zechariah the son of Jeberechiah.\"",
                "I went to the prophetess, and she conceived, and bore a son. Then Yahweh said to me, \"Call his name \u2018Maher Shalal Hash Baz.\u2019",
                "For before the child knows how to say, \u2018My father,\u2019 and, \u2018My mother,\u2019 the riches of Damascus and the spoil of Samaria will be carried away by the king of Assyria.\"",
                "Yahweh spoke to me yet again, saying,",
                "\"Because this people have refused the waters of Shiloah that go softly, and rejoice in Rezin and Remaliah\u2019s son;",
                "now therefore, behold, the Lord brings upon them the mighty flood waters of the River: the king of Assyria and all his glory. It will come up over all its channels, and go over all its banks.",
                "It will sweep onward into Judah. It will overflow and pass through; it will reach even to the neck; and the stretching out of its wings will fill the breadth of your land, Immanuel.",
                "Make an uproar, you peoples, and be broken in pieces! Listen, all you from far countries: dress for battle, and be shattered! Dress for battle, and be shattered!",
                "Take counsel together, and it will be brought to nothing; speak the word, and it will not stand: for God is with us.\"",
                "For Yahweh spoke thus to me with a strong hand, and instructed me not to walk in the way of this people, saying,",
                "\"Don\u2019t say, \u2018A conspiracy!\u2019 concerning all about which this people say, \u2018A conspiracy!\u2019 neither fear their threats, nor be terrorized.",
                "Yahweh of Armies is who you must respect as holy. He is the one you must fear. He is the one you must dread.",
                "He will be a sanctuary, but for both houses of Israel, he will be a trap and a snare for the inhabitants of Jerusalem.",
                "Many will stumble over it, fall, be broken, be snared, and be captured.\"",
                "Wrap up the testimony. Seal the law among my disciples.",
                "I will wait for Yahweh, who hides his face from the house of Jacob, and I will look for him.",
                "Behold, I and the children whom Yahweh has given me are for signs and for wonders in Israel from Yahweh of Armies, who dwells in Mount Zion.",
                "When they tell you, \"Consult with those who have familiar spirits and with the wizards, who chirp and who mutter:\" shouldn\u2019t a people consult with their God? Should they consult the dead on behalf of the living?",
                "Turn to the law and to the testimony! If they don\u2019t speak according to this word, surely there is no morning for them.",
                "They will pass through it, very distressed and hungry; and it will happen that when they are hungry, they will worry, and curse by their king and by their God. They will turn their faces upward,",
                "and look to the earth, and see distress, darkness, and the gloom of anguish. They will be driven into thick darkness."
            ],
            9: [
                "But there shall be no more gloom for her who was in anguish. In the former time, he brought into contempt the land of Zebulun and the land of Naphtali; but in the latter time he has made it glorious, by the way of the sea, beyond the Jordan, Galilee of the nations.",
                "The people who walked in darkness have seen a great light. Those who lived in the land of the shadow of death, on them the light has shined.",
                "You have multiplied the nation. You have increased their joy. They rejoice before you according to the joy in harvest, as men rejoice when they divide the spoil.",
                "For the yoke of his burden, and the staff of his shoulder, the rod of his oppressor, you have broken as in the day of Midian.",
                "For all the armor of the armed man in the noisy battle, and the garments rolled in blood, will be for burning, fuel for the fire.",
                "For to us a child is born. To us a son is given; and the government will be on his shoulders. His name will be called Wonderful, Counselor, Mighty God, Everlasting Father, Prince of Peace.",
                "Of the increase of his government and of peace there shall be no end, on the throne of David, and on his kingdom, to establish it, and to uphold it with justice and with righteousness from that time on, even forever. The zeal of Yahweh of Armies will perform this.",
                "The Lord sent a word into Jacob, and it falls on Israel.",
                "All the people will know, including Ephraim and the inhabitants of Samaria, who say in pride and in arrogance of heart,",
                "\"The bricks have fallen, but we will build with cut stone. The sycamore fig trees have been cut down, but we will put cedars in their place.\"",
                "Therefore Yahweh will set up on high against him the adversaries of Rezin, and will stir up his enemies,",
                "The Syrians in front, and the Philistines behind; and they will devour Israel with open mouth. For all this, his anger is not turned away, but his hand is stretched out still.",
                "Yet the people have not turned to him who struck them, neither have they sought Yahweh of Armies.",
                "Therefore Yahweh will cut off from Israel head and tail, palm branch and reed, in one day.",
                "The elder and the honorable man is the head, and the prophet who teaches lies is the tail.",
                "For those who lead this people lead them astray; and those who are led by them are destroyed.",
                "Therefore the Lord will not rejoice over their young men, neither will he have compassion on their fatherless and widows; for everyone is profane and an evildoer, and every mouth speaks folly. For all this his anger is not turned away, but his hand is stretched out still.",
                "For wickedness burns like a fire. It devours the briers and thorns; yes, it kindles in the thickets of the forest, and they roll upward in a column of smoke.",
                "Through the wrath of Yahweh of Armies, the land is burnt up; and the people are the fuel for the fire. No one spares his brother.",
                "One will devour on the right hand, and be hungry; and he will eat on the left hand, and they will not be satisfied. Everyone will eat the flesh of his own arm:",
                "Manasseh, Ephraim; and Ephraim, Manasseh; and they together shall be against Judah. For all this his anger is not turned away, but his hand is stretched out still."
            ],
            10: [
                "Woe to those who decree unrighteous decrees, and to the writers who write oppressive decrees;",
                "to deprive the needy from justice, and to rob the poor among my people of their rights, that widows may be their spoil, and that they may make the fatherless their prey!",
                "What will you do in the day of visitation, and in the desolation which will come from afar? To whom will you flee for help? Where will you leave your wealth?",
                "They will only bow down under the prisoners, and will fall under the slain. For all this his anger is not turned away, but his hand is stretched out still.",
                "Alas Assyrian, the rod of my anger, the staff in whose hand is my indignation!",
                "I will send him against a profane nation, and against the people who anger me will I give him a command to take the spoil and to take the prey, and to tread them down like the mire of the streets.",
                "However he doesn\u2019t mean so, neither does his heart think so; but it is in his heart to destroy, and to cut off not a few nations.",
                "For he says, \"Aren\u2019t all of my princes kings?",
                "Isn\u2019t Calno like Carchemish? Isn\u2019t Hamath like Arpad? Isn\u2019t Samaria like Damascus?\"",
                "As my hand has found the kingdoms of the idols, whose engraved images exceeded those of Jerusalem and of Samaria;",
                "shall I not, as I have done to Samaria and her idols, so do to Jerusalem and her idols?",
                "Therefore it will happen that, when the Lord has performed his whole work on Mount Zion and on Jerusalem, I will punish the fruit of the willful proud heart of the king of Assyria, and the insolence of his haughty looks.",
                "For he has said, \"By the strength of my hand I have done it, and by my wisdom; for I have understanding: and I have removed the boundaries of the peoples, and have robbed their treasures. Like a valiant man I have brought down their rulers.",
                "My hand has found the riches of the peoples like a nest, and like one gathers eggs that are abandoned, have I gathered all the earth. There was no one who moved their wing, or that opened their mouth, or chirped.\"",
                "Should an axe brag against him who chops with it? Should a saw exalt itself above him who saws with it? As if a rod should lift those who lift it up, or as if a staff should lift up someone who is not wood.",
                "Therefore the Lord, Yahweh of Armies, will send among his fat ones leanness; and under his glory a burning will be kindled like the burning of fire.",
                "The light of Israel will be for a fire, and his Holy One for a flame; and it will burn and devour his thorns and his briers in one day.",
                "He will consume the glory of his forest, and of his fruitful field, both soul and body. It will be as when a standard bearer faints.",
                "The remnant of the trees of his forest shall be few, so that a child could write their number.",
                "It will come to pass in that day that the remnant of Israel, and those who have escaped from the house of Jacob will no more again lean on him who struck them, but shall lean on Yahweh, the Holy One of Israel, in truth.",
                "A remnant will return, even the remnant of Jacob, to the mighty God.",
                "For though your people, Israel, are like the sand of the sea, only a remnant of them will return. A destruction is determined, overflowing with righteousness.",
                "For the Lord, Yahweh of Armies, will make a full end, and that determined, in the midst of all the earth.",
                "Therefore the Lord, Yahweh of Armies, says \"My people who dwell in Zion, don\u2019t be afraid of the Assyrian, though he strike you with the rod, and lift up his staff against you, as Egypt did.",
                "For yet a very little while, and the indignation against you will be accomplished, and my anger will be directed to his destruction.\"",
                "Yahweh of Armies will stir up a scourge against him, as in the slaughter of Midian at the rock of Oreb. His rod will be over the sea, and he will lift it up like he did against Egypt.",
                "It will happen in that day, that his burden will depart from off your shoulder, and his yoke from off your neck, and the yoke shall be destroyed because of the anointing oil.",
                "He has come to Aiath. He has passed through Migron. At Michmash he stores his baggage.",
                "They have gone over the pass. They have taken up their lodging at Geba. Ramah trembles. Gibeah of Saul has fled.",
                "Cry aloud with your voice, daughter of Gallim! Listen, Laishah! You poor Anathoth!",
                "Madmenah is a fugitive. The inhabitants of Gebim flee for safety.",
                "This very day he will halt at Nob. He shakes his hand at the mountain of the daughter of Zion, the hill of Jerusalem.",
                "Behold, the Lord, Yahweh of Armies, will lop the boughs with terror. The tall will be cut down, and the lofty will be brought low.",
                "He will cut down the thickets of the forest with iron, and Lebanon will fall by the Mighty One."
            ],
            11: [
                "A shoot will come out of the stock of Jesse, and a branch out of his roots will bear fruit.",
                "The Spirit of Yahweh will rest on him: the spirit of wisdom and understanding, the spirit of counsel and might, the spirit of knowledge and of the fear of Yahweh.",
                "His delight will be in the fear of Yahweh. He will not judge by the sight of his eyes, neither decide by the hearing of his ears;",
                "but with righteousness he will judge the poor, and decide with equity for the humble of the earth. He will strike the earth with the rod of his mouth; and with the breath of his lips he will kill the wicked.",
                "Righteousness will be the belt of his waist, and faithfulness the belt of his waist.",
                "The wolf will live with the lamb, and the leopard will lie down with the young goat; The calf, the young lion, and the fattened calf together; and a little child will lead them.",
                "The cow and the bear will graze. Their young ones will lie down together. The lion will eat straw like the ox.",
                "The nursing child will play near a cobra\u2019s hole, and the weaned child will put his hand on the viper\u2019s den.",
                "They will not hurt nor destroy in all my holy mountain; for the earth will be full of the knowledge of Yahweh, as the waters cover the sea.",
                "It will happen in that day that the nations will seek the root of Jesse, who stands as a banner of the peoples; and his resting place will be glorious.",
                "It will happen in that day that the Lord will set his hand again the second time to recover the remnant that is left of his people from Assyria, from Egypt, from Pathros, from Cush, from Elam, from Shinar, from Hamath, and from the islands of the sea.",
                "He will set up a banner for the nations, and will assemble the outcasts of Israel, and gather together the dispersed of Judah from the four corners of the earth.",
                "The envy also of Ephraim will depart, and those who persecute Judah will be cut off. Ephraim won\u2019t envy Judah, and Judah won\u2019t persecute Ephraim.",
                "They will fly down on the shoulders of the Philistines on the west. Together they will plunder the children of the east. They will extend their power over Edom and Moab, and the children of Ammon will obey them.",
                "Yahweh will utterly destroy the tongue of the Egyptian sea; and with his scorching wind he will wave his hand over the River, and will split it into seven streams, and cause men to march over in sandals.",
                "There will be a highway for the remnant that is left of his people from Assyria, like there was for Israel in the day that he came up out of the land of Egypt."
            ],
            12: [
                "In that day you will say, \"I will give thanks to you, Yahweh; for though you were angry with me, your anger has turned away and you comfort me.",
                "Behold, God is my salvation. I will trust, and will not be afraid; for Yah, Yahweh, is my strength and song; and he has become my salvation.\"",
                "Therefore with joy you will draw water out of the wells of salvation.",
                "In that day you will say, \"Give thanks to Yahweh! Call on his name. Declare his doings among the peoples. Proclaim that his name is exalted!",
                "Sing to Yahweh, for he has done excellent things! Let this be known in all the earth!",
                "Cry aloud and shout, you inhabitant of Zion; for the Holy One of Israel is great in the midst of you!\""
            ],
            13: [
                "The burden of Babylon, which Isaiah the son of Amoz saw:",
                "Set up a banner on the bare mountain! Lift up your voice to them! Wave your hand, that they may go into the gates of the nobles.",
                "I have commanded my consecrated ones; yes, I have called my mighty men for my anger, even my proudly exulting ones.",
                "The noise of a multitude is in the mountains, as of a great people; the noise of an uproar of the kingdoms of the nations gathered together! Yahweh of Armies is mustering the army for the battle.",
                "They come from a far country, from the uttermost part of heaven, even Yahweh, and the weapons of his indignation, to destroy the whole land.",
                "Wail; for the day of Yahweh is at hand! It will come as destruction from the Almighty.",
                "Therefore all hands will be feeble, and everyone\u2019s heart will melt.",
                "They will be dismayed. Pangs and sorrows will seize them. They will be in pain like a woman in labor. They will look in amazement one at another. Their faces will be faces of flame.",
                "Behold, the day of Yahweh comes, cruel, with wrath and fierce anger; to make the land a desolation, and to destroy its sinners out of it.",
                "For the stars of the sky and its constellations will not give their light. The sun will be darkened in its going forth, and the moon will not cause its light to shine.",
                "I will punish the world for their evil, and the wicked for their iniquity. I will cause the arrogance of the proud to cease, and will humble the haughtiness of the terrible.",
                "I will make people more rare than fine gold, even a person than the pure gold of Ophir.",
                "Therefore I will make the heavens tremble, and the earth will be shaken out of its place in the wrath of Yahweh of Armies, and in the day of his fierce anger.",
                "It will happen that like a hunted gazelle, and like sheep that no one gathers, they will each turn to their own people, and will each flee to their own land.",
                "Everyone who is found will be thrust through. Everyone who is captured will fall by the sword.",
                "Their infants also will be dashed in pieces before their eyes. Their houses will be ransacked, and their wives raped.",
                "Behold, I will stir up the Medes against them, who will not value silver, and as for gold, they will not delight in it.",
                "Their bows will dash the young men in pieces; and they shall have no pity on the fruit of the womb. Their eyes will not spare children.",
                "Babylon, the glory of kingdoms, the beauty of the Chaldeans\u2019 pride, will be like when God overthrew Sodom and Gomorrah.",
                "It will never be inhabited, neither will it be lived in from generation to generation. The Arabian will not pitch a tent there, neither will shepherds make their flocks lie down there.",
                "But wild animals of the desert will lie there, and their houses will be full of jackals. Ostriches will dwell there, and wild goats will frolic there.",
                "Wolves will cry in their castles, and jackals in the pleasant palaces. Her time is near to come, and her days will not be prolonged."
            ],
            14: [
                "For Yahweh will have compassion on Jacob, and will yet choose Israel, and set them in their own land. The foreigner will join himself with them, and they will unite with the house of Jacob.",
                "The peoples will take them, and bring them to their place. The house of Israel will possess them in Yahweh\u2019s land for servants and for handmaids. They will take as captives those whose captives they were; and they shall rule over their oppressors.",
                "It will happen in the day that Yahweh will give you rest from your sorrow, from your trouble, and from the hard service in which you were made to serve,",
                "that you will take up this parable against the king of Babylon, and say, \"How the oppressor has ceased! The golden city has ceased!\"",
                "Yahweh has broken the staff of the wicked, the scepter of the rulers,",
                "who struck the peoples in wrath with a continual stroke, who ruled the nations in anger, with a persecution that none restrained.",
                "The whole earth is at rest, and is quiet. They break out song.",
                "Yes, the fir trees rejoice with you, with the cedars of Lebanon, saying, \"Since you are humbled, no lumberjack has come up against us.\"",
                "Sheol from beneath has moved for you to meet you at your coming. It stirs up the dead for you, even all the rulers of the earth. It has raised up from their thrones all the kings of the nations.",
                "They all will answer and ask you, \"Have you also become as weak as we are? Have you become like us?\"",
                "Your pomp is brought down to Sheol, with the sound of your stringed instruments. Maggots are spread out under you, and worms cover you.",
                "How you have fallen from heaven, morning star, son of the dawn! How you are cut down to the ground, who laid the nations low!",
                "You said in your heart, \"I will ascend into heaven! I will exalt my throne above the stars of God! I will sit on the mountain of assembly, in the far north!",
                "I will ascend above the heights of the clouds! I will make myself like the Most High!\"",
                "Yet you shall be brought down to Sheol, to the depths of the pit.",
                "Those who see you will stare at you. They will ponder you, saying, \"Is this the man who made the earth to tremble, who shook kingdoms;",
                "who made the world like a wilderness, and overthrew its cities; who didn\u2019t release his prisoners to their home?\"",
                "All the kings of the nations, sleep in glory, everyone in his own house.",
                "But you are cast away from your tomb like an abominable branch, clothed with the slain, who are thrust through with the sword, who go down to the stones of the pit; like a dead body trodden under foot.",
                "You will not join them in burial, because you have destroyed your land. You have killed your people. The seed of evildoers will not be named forever.",
                "Prepare for slaughter of his children because of the iniquity of their fathers, that they not rise up and possess the earth, and fill the surface of the world with cities.",
                "\"I will rise up against them,\" says Yahweh of Armies, \"and cut off from Babylon name and remnant, and son and son\u2019s son,\" says Yahweh.",
                "\"I will also make it a possession for the porcupine, and pools of water. I will sweep it with the broom of destruction,\" says Yahweh of Armies.",
                "Yahweh of Armies has sworn, saying, \"Surely, as I have thought, so shall it happen; and as I have purposed, so shall it stand:",
                "that I will break the Assyrian in my land, and tread him under foot on my mountains. Then his yoke will leave them, and his burden leave their shoulders.",
                "This is the plan that is determined for the whole earth. This is the hand that is stretched out over all the nations.",
                "For Yahweh of Armies has planned, and who can stop it? His hand is stretched out, and who can turn it back?\"",
                "This burden was in the year that king Ahaz died.",
                "Don\u2019t rejoice, O Philistia, all of you, because the rod that struck you is broken; for out of the serpent\u2019s root an adder will emerge, and his fruit will be a fiery flying serpent.",
                "The firstborn of the poor will eat, and the needy will lie down in safety; and I will kill your root with famine, and your remnant will be killed.",
                "Howl, gate! Cry, city! You are melted away, Philistia, all of you; for smoke comes out of the north, and there is no straggler in his ranks.",
                "What will they answer the messengers of the nation? That Yahweh has founded Zion, and in her the afflicted of his people will take refuge."
            ],
            15: [
                "The burden of Moab: for in a night, Ar of Moab is laid waste, and brought to nothing; for in a night Kir of Moab is laid waste, and brought to nothing.",
                "They have gone up to Bayith, and to Dibon, to the high places, to weep. Moab wails over Nebo and over Medeba. Baldness is on all of their heads. Every beard is cut off.",
                "In their streets, they clothe themselves in sackcloth. In their streets and on their housetops, everyone wails, weeping abundantly.",
                "Heshbon cries out with Elealeh. Their voice is heard even to Jahaz. Therefore the armed men of Moab cry aloud. Their souls tremble within them.",
                "My heart cries out for Moab! Her nobles flee to Zoar, to Eglath Shelishiyah; for they go up by the ascent of Luhith with weeping; for in the way of Horonaim, they raise up a cry of destruction.",
                "For the waters of Nimrim will be desolate; for the grass has withered away, the tender grass fails, there is no green thing.",
                "Therefore they will carry away the abundance they have gotten, and that which they have stored up, over the brook of the willows.",
                "For the cry has gone around the borders of Moab; its wailing to Eglaim, and its wailing to Beer Elim.",
                "For the waters of Dimon are full of blood; for I will bring yet more on Dimon, a lion on those of Moab who escape, and on the remnant of the land."
            ],
            16: [
                "Send the lambs for the ruler of the land from Selah to the wilderness, to the mountain of the daughter of Zion.",
                "For it will be that as wandering birds, as a scattered nest, so will the daughters of Moab be at the fords of the Arnon.",
                "Give counsel! Execute justice! Make your shade like the night in the midst of the noonday! Hide the outcasts! Don\u2019t betray the fugitive!",
                "Let my outcasts dwell with you! As for Moab, be a hiding place for him from the face of the destroyer. For the extortioner is brought to nothing. Destruction ceases. The oppressors are consumed out of the land.",
                "A throne will be established in loving kindness. One will sit on it in truth, in the tent of David, judging, seeking justice, and swift to do righteousness.",
                "We have heard of the pride of Moab, that he is very proud; even of his arrogance, his pride, and his wrath. His boastings are nothing.",
                "Therefore Moab will wail for Moab. Everyone will wail. You will mourn for the raisin cakes of Kir Hareseth, utterly stricken.",
                "For the fields of Heshbon languish with the vine of Sibmah. The lords of the nations have broken down its choice branches, which reached even to Jazer, which wandered into the wilderness. Its shoots were spread abroad. They passed over the sea.",
                "Therefore I will weep with the weeping of Jazer for the vine of Sibmah. I will water you with my tears, Heshbon, and Elealeh: for on your summer fruits and on your harvest the battle shout has fallen.",
                "Gladness is taken away, and joy out of the fruitful field; and in the vineyards there will be no singing, neither joyful noise. Nobody will tread out wine in the presses. I have made the shouting stop.",
                "Therefore my heart sounds like a harp for Moab, and my inward parts for Kir Heres.",
                "It will happen that when Moab presents himself, when he wearies himself on the high place, and comes to his sanctuary to pray, that he will not prevail.",
                "This is the word that Yahweh spoke concerning Moab in time past.",
                "But now Yahweh has spoken, saying, \"Within three years, as a worker bound by contract would count them, the glory of Moab shall be brought into contempt, with all his great multitude; and the remnant will be very small and feeble.\""
            ],
            17: [
                "The burden of Damascus: \"Behold, Damascus is taken away from being a city, and it will be a ruinous heap.",
                "The cities of Aroer are forsaken. They will be for flocks, which shall lie down, and none shall make them afraid.",
                "The fortress shall cease from Ephraim, and the kingdom from Damascus, and the remnant of Syria. They will be as the glory of the children of Israel,\" says Yahweh of Armies.",
                "\"It will happen in that day that the glory of Jacob will be made thin, and the fatness of his flesh will become lean.",
                "It will be like when the harvester gathers the wheat, and his arm reaps the grain. Yes, it will be like when one gleans grain in the valley of Rephaim.",
                "Yet gleanings will be left there, like the shaking of an olive tree, two or three olives in the top of the uppermost bough, four or five in the outermost branches of a fruitful tree,\" says Yahweh, the God of Israel.",
                "In that day, people will look to their Maker, and their eyes will have respect for the Holy One of Israel.",
                "They will not look to the altars, the work of their hands; neither shall they respect that which their fingers have made, either the Asherim, or the incense altars.",
                "In that day, their strong cities will be like the forsaken places in the woods and on the mountain top, which were forsaken from before the children of Israel; and it will be a desolation.",
                "For you have forgotten the God of your salvation, and have not remembered the rock of your strength. Therefore you plant pleasant plants, and set out foreign seedlings.",
                "In the day of your planting, you hedge it in. In the morning, you make your seed blossom, but the harvest flees away in the day of grief and of desperate sorrow.",
                "Ah, the uproar of many peoples, who roar like the roaring of the seas; and the rushing of nations, that rush like the rushing of mighty waters!",
                "The nations will rush like the rushing of many waters: but he will rebuke them, and they will flee far off, and will be chased like the chaff of the mountains before the wind, and like the whirling dust before the storm.",
                "At evening, behold, terror! Before the morning, they are no more. This is the portion of those who plunder us, and the lot of those who rob us."
            ],
            18: [
                "Ah, the land of the rustling of wings, which is beyond the rivers of Ethiopia;",
                "that sends ambassadors by the sea, even in vessels of papyrus on the waters, saying, \"Go, you swift messengers, to a nation tall and smooth, to a people awesome from their beginning onward, a nation that measures out and treads down, whose land the rivers divide!\"",
                "All you inhabitants of the world, and you dwellers on the earth, when a banner is lifted up on the mountains, look! When the trumpet is blown, listen!",
                "For Yahweh said to me, \"I will be still, and I will see in my dwelling place, like clear heat in sunshine, like a cloud of dew in the heat of harvest.\"",
                "For before the harvest, when the blossom is over, and the flower becomes a ripening grape, he will cut off the sprigs with pruning hooks, and he will cut down and take away the spreading branches.",
                "They will be left together for the ravenous birds of the mountains, and for the animals of the earth. The ravenous birds will summer on them, and all the animals of the earth will winter on them.",
                "In that time, a present will be brought to Yahweh of Armies from a people tall and smooth, even from a people awesome from their beginning onward, a nation that measures out and treads down, whose land the rivers divide, to the place of the name of Yahweh of Armies, Mount Zion."
            ],
            19: [
                "The burden of Egypt: \"Behold, Yahweh rides on a swift cloud, and comes to Egypt. The idols of Egypt will tremble at his presence; and the heart of Egypt will melt in its midst.",
                "I will stir up the Egyptians against the Egyptians, and they will fight everyone against his brother, and everyone against his neighbor; city against city, and kingdom against kingdom.",
                "The spirit of Egypt will fail in its midst. I will destroy its counsel. They will seek the idols, the charmers, those who have familiar spirits, and the wizards.",
                "I will give over the Egyptians into the hand of a cruel lord. A fierce king will rule over them,\" says the Lord, Yahweh of Armies.",
                "The waters will fail from the sea, and the river will be wasted and become dry.",
                "The rivers will become foul. The streams of Egypt will be diminished and dried up. The reeds and flags will wither away.",
                "The meadows by the Nile, by the brink of the Nile, and all the sown fields of the Nile, will become dry, be driven away, and be no more.",
                "The fishermen will lament, and all those who fish in the Nile will mourn, and those who spread nets on the waters will languish.",
                "Moreover those who work in combed flax, and those who weave white cloth, will be confounded.",
                "The pillars will be broken in pieces. All those who work for hire will be grieved in soul.",
                "The princes of Zoan are utterly foolish. The counsel of the wisest counselors of Pharaoh has become stupid. How do you say to Pharaoh, \"I am the son of the wise, the son of ancient kings?\"",
                "Where then are your wise men? Let them tell you now; and let them know what Yahweh of Armies has purposed concerning Egypt.",
                "The princes of Zoan have become fools. The princes of Memphis are deceived. They have caused Egypt to go astray, who are the cornerstone of her tribes.",
                "Yahweh has mixed a spirit of perverseness in the midst of her; and they have caused Egypt to go astray in all of its works, like a drunken man staggers in his vomit.",
                "Neither shall there be any work for Egypt, which head or tail, palm branch or rush, may do.",
                "In that day the Egyptians will be like women. They will tremble and fear because of the shaking of the hand of Yahweh of Armies, which he shakes over them.",
                "The land of Judah will become a terror to Egypt. Everyone to whom mention is made of it will be afraid, because of the plans of Yahweh of Armies, which he determines against it.",
                "In that day, there will be five cities in the land of Egypt that speak the language of Canaan, and swear to Yahweh of Armies. One will be called \"The city of destruction.\"",
                "In that day, there will be an altar to Yahweh in the midst of the land of Egypt, and a pillar to Yahweh at its border.",
                "It will be for a sign and for a witness to Yahweh of Armies in the land of Egypt; for they will cry to Yahweh because of oppressors, and he will send them a savior and a defender, and he will deliver them.",
                "Yahweh will be known to Egypt, and the Egyptians will know Yahweh in that day. Yes, they will worship with sacrifice and offering, and will vow a vow to Yahweh, and will perform it.",
                "Yahweh will strike Egypt, striking and healing. They will return to Yahweh, and he will be entreated by them, and will heal them.",
                "In that day there will be a highway out of Egypt to Assyria, and the Assyrian shall come into Egypt, and the Egyptian into Assyria; and the Egyptians will worship with the Assyrians.",
                "In that day, Israel will be the third with Egypt and with Assyria, a blessing in the midst of the earth;",
                "because Yahweh of Armies has blessed them, saying, \"Blessed be Egypt my people, Assyria the work of my hands, and Israel my inheritance.\""
            ],
            20: [
                "In the year that Tartan came to Ashdod, when Sargon the king of Assyria sent him, and he fought against Ashdod and took it;",
                "at that time Yahweh spoke by Isaiah the son of Amoz, saying, \"Go, and loosen the sackcloth from off your waist, and take your shoes from off your feet.\" He did so, walking naked and barefoot.",
                "Yahweh said, \"As my servant Isaiah has walked naked and barefoot three years for a sign and a wonder concerning Egypt and concerning Ethiopia,",
                "so the king of Assyria will lead away the captives of Egypt and the exiles of Ethiopia, young and old, naked and barefoot, and with buttocks uncovered, to the shame of Egypt.",
                "They will be dismayed and confounded, because of Ethiopia their expectation, and of Egypt their glory.",
                "The inhabitants of this coast land will say in that day, \u2018Behold, this is our expectation, where we fled for help to be delivered from the king of Assyria. And we, how will we escape?\u2019\""
            ],
            21: [
                "The burden of the wilderness of the sea. As whirlwinds in the South sweep through, it comes from the wilderness, from an awesome land.",
                "A grievous vision is declared to me. The treacherous man deals treacherously, and the destroyer destroys. Go up, Elam; attack! I have stopped all of Media\u2019s sighing.",
                "Therefore my thighs are filled with anguish. Pains have taken hold on me, like the pains of a woman in labor. I am in so much pain that I can\u2019t hear. I so am dismayed that I can\u2019t see.",
                "My heart flutters. Horror has frightened me. The twilight that I desired has been turned into trembling for me.",
                "They prepare the table. They set the watch. They eat. They drink. Rise up, you princes, oil the shield!",
                "For the Lord said to me, \"Go, set a watchman. Let him declare what he sees.",
                "When he sees a troop, horsemen in pairs, a troop of donkeys, a troop of camels, he shall listen diligently with great attentiveness.\"",
                "He cried like a lion: \"Lord, I stand continually on the watchtower in the daytime, and every night I stay at my post.",
                "Behold, here comes a troop of men, horsemen in pairs.\" He answered, \"Fallen, fallen is Babylon; and all the engraved images of her gods are broken to the ground.",
                "You are my threshing, and the grain of my floor!\" That which I have heard from Yahweh of Armies, the God of Israel, I have declared to you.",
                "The burden of Dumah. One calls to me out of Seir, \"Watchman, what of the night? Watchman, what of the night?\"",
                "The watchman said, \"The morning comes, and also the night. If you will inquire, inquire. Come back again.\"",
                "The burden on Arabia. In the forest in Arabia you will lodge, you caravans of Dedanites.",
                "They brought water to him who was thirsty. The inhabitants of the land of Tema met the fugitives with their bread.",
                "For they fled away from the swords, from the drawn sword, from the bent bow, and from the heat of battle.",
                "For the Lord said to me, \"Within a year, as a worker bound by contract would count it, all the glory of Kedar will fail,",
                "and the residue of the number of the archers, the mighty men of the children of Kedar, will be few; for Yahweh, the God of Israel, has spoken it.\""
            ],
            22: [
                "The burden of the valley of vision. What ails you now, that you have all gone up to the housetops?",
                "You that are full of shouting, a tumultuous city, a joyous town; your slain are not slain with the sword, neither are they dead in battle.",
                "All your rulers fled away together. They were bound by the archers. All who were found by you were bound together. They fled far away.",
                "Therefore I said, \"Look away from me. I will weep bitterly. Don\u2019t labor to comfort me for the destruction of the daughter of my people.",
                "For it is a day of confusion, and of treading down, and of perplexity, from the Lord, Yahweh of Armies, in the valley of vision; a breaking down of the walls, and a crying to the mountains.\"",
                "Elam carried his quiver, with chariots of men and horsemen; and Kir uncovered the shield.",
                "It happened that your choicest valleys were full of chariots, and the horsemen set themselves in array at the gate.",
                "He took away the covering of Judah; and you looked in that day to the armor in the house of the forest.",
                "You saw the breaches of the city of David, that they were many; and you gathered together the waters of the lower pool.",
                "You numbered the houses of Jerusalem, and you broke down the houses to fortify the wall.",
                "You also made a reservoir between the two walls for the water of the old pool. But you didn\u2019t look to him who had done this, neither did you have respect for him who purposed it long ago.",
                "In that day, the Lord, Yahweh of Armies, called to weeping, and to mourning, and to baldness, and to dressing in sackcloth:",
                "and behold, joy and gladness, killing cattle and killing sheep, eating flesh and drinking wine: \"Let us eat and drink, for tomorrow we will die.\"",
                "Yahweh of Armies revealed himself in my ears, \"Surely this iniquity will not be forgiven you until you die,\" says the Lord, Yahweh of Armies.",
                "Thus says the Lord, Yahweh of Armies, \"Go, get yourself to this treasurer, even to Shebna, who is over the house, and say,",
                "\u2018What are you doing here? Who has you here, that you have dug out a tomb here?\u2019 Cutting himself out a tomb on high, chiseling a habitation for himself in the rock!\"",
                "Behold, Yahweh will overcome you and hurl you away violently. Yes, he will grasp you firmly.",
                "He will surely wind you around and around, and throw you like a ball into a large country. There you will die, and there the chariots of your glory will be, you shame of your lord\u2019s house.",
                "I will thrust you from your office. You will be pulled down from your station.",
                "It will happen in that day that I will call my servant Eliakim the son of Hilkiah,",
                "and I will clothe him with your robe, and strengthen him with your belt. I will commit your government into his hand; and he will be a father to the inhabitants of Jerusalem, and to the house of Judah.",
                "I will lay the key of the house of David on his shoulder. He will open, and no one will shut. He will shut, and no one will open.",
                "I will fasten him like a nail in a sure place. He will be for a throne of glory to his father\u2019s house.",
                "They will hang on him all the glory of his father\u2019s house, the offspring and the issue, every small vessel, from the cups even to all the pitchers.",
                "\"In that day,\" says Yahweh of Armies, \"the nail that was fastened in a sure place will give way. It will be cut down, and fall. The burden that was on it will be cut off, for Yahweh has spoken it.\""
            ],
            23: [
                "The burden of Tyre. Howl, you ships of Tarshish! For it is laid waste, so that there is no house, no entering in. From the land of Kittim it is revealed to them.",
                "Be still, you inhabitants of the coast, you whom the merchants of Sidon, that pass over the sea, have replenished.",
                "On great waters, the seed of the Shihor, the harvest of the Nile, was her revenue. She was the market of nations.",
                "Be ashamed, Sidon; for the sea has spoken, the stronghold of the sea, saying, \"I have not travailed, nor brought forth, neither have I nourished young men, nor brought up virgins.\"",
                "When the report comes to Egypt, they will be in anguish at the report of Tyre.",
                "Pass over to Tarshish! Wail, you inhabitants of the coast!",
                "Is this your joyous city, whose antiquity is of ancient days, whose feet carried her far away to travel?",
                "Who has planned this against Tyre, the giver of crowns, whose merchants are princes, whose traffickers are the honorable of the earth?",
                "Yahweh of Armies has planned it, to stain the pride of all glory, to bring into contempt all the honorable of the earth.",
                "Pass through your land like the Nile, daughter of Tarshish. There is no restraint any more.",
                "He has stretched out his hand over the sea. He has shaken the kingdoms. Yahweh has ordered the destruction of Canaan\u2019s strongholds.",
                "He said, \"You shall rejoice no more, you oppressed virgin daughter of Sidon. Arise, pass over to Kittim. Even there you will have no rest.\"",
                "Behold, the land of the Chaldeans. This people was not. The Assyrians founded it for those who dwell in the wilderness. They set up their towers. They overthrew its palaces. They made it a ruin.",
                "Howl, you ships of Tarshish, for your stronghold is laid waste!",
                "It will come to pass in that day that Tyre will be forgotten seventy years, according to the days of one king. After the end of seventy years it will be to Tyre like in the song of the prostitute.",
                "Take a harp; go about the city, you prostitute that has been forgotten. Make sweet melody. Sing many songs, that you may be remembered.",
                "It will happen after the end of seventy years that Yahweh will visit Tyre, and she shall return to her wages, and will play the prostitute with all the kingdoms of the world on the surface of the earth.",
                "Her merchandise and her wages will be holiness to Yahweh. It will not be treasured nor laid up; for her merchandise will be for those who dwell before Yahweh, to eat sufficiently, and for durable clothing."
            ],
            24: [
                "Behold, Yahweh makes the earth empty, makes it waste, turns it upside down, and scatters its inhabitants.",
                "It will be as with the people, so with the priest; as with the servant, so with his master; as with the maid, so with her mistress; as with the buyer, so with the seller; as with the creditor, so with the debtor; as with the taker of interest, so with the giver of interest.",
                "The earth will be utterly emptied and utterly laid waste; for Yahweh has spoken this word.",
                "The earth mourns and fades away. The world languishes and fades away. The lofty people of the earth languish.",
                "The earth also is polluted under its inhabitants, because they have transgressed the laws, violated the statutes, and broken the everlasting covenant.",
                "Therefore the curse has devoured the earth, and those who dwell therein are found guilty. Therefore the inhabitants of the earth are burned, and few men left.",
                "The new wine mourns. The vine languishes. All the merry-hearted sigh.",
                "The mirth of tambourines ceases. The sound of those who rejoice ends. The joy of the harp ceases.",
                "They will not drink wine with a song. Strong drink will be bitter to those who drink it.",
                "The confused city is broken down. Every house is shut up, that no man may come in.",
                "There is a crying in the streets because of the wine. All joy is darkened. The mirth of the land is gone.",
                "The city is left in desolation, and the gate is struck with destruction.",
                "For it will be so in the midst of the earth among the peoples, as the shaking of an olive tree, as the gleanings when the vintage is done.",
                "These shall lift up their voice. They will shout for the majesty of Yahweh. They cry aloud from the sea.",
                "Therefore glorify Yahweh in the east, even the name of Yahweh, the God of Israel, in the islands of the sea!",
                "From the uttermost part of the earth have we heard songs. Glory to the righteous! But I said, \"I pine away! I pine away! woe is me!\" The treacherous have dealt treacherously. Yes, the treacherous have dealt very treacherously.",
                "Fear, the pit, and the snare, are on you who inhabitant the earth.",
                "It will happen that he who flees from the noise of the fear will fall into the pit; and he who comes up out of the midst of the pit will be taken in the snare; for the windows on high are opened, and the foundations of the earth tremble.",
                "The earth is utterly broken. The earth is torn apart. The earth is shaken violently.",
                "The earth will stagger like a drunken man, and will sway back and forth like a hammock. Its disobedience will be heavy on it, and it will fall and not rise again.",
                "It shall happen in that day that Yahweh will punish the army of the high ones on high, and the kings of the earth on the earth.",
                "They shall be gathered together, as prisoners are gathered in the pit, and shall be shut up in the prison; and after many days shall they be visited.",
                "Then the moon shall be confounded, and the sun ashamed; for Yahweh of Armies will reign on Mount Zion, and in Jerusalem; and before his elders will be glory."
            ],
            25: [
                "Yahweh, you are my God. I will exalt you! I will praise your name, for you have done wonderful things, things planned long ago, in complete faithfulness and truth.",
                "For you have made a city into a heap, a fortified city into a ruin, a palace of strangers to be no city. It will never be built.",
                "Therefore a strong people will glorify you. A city of awesome nations will fear you.",
                "For you have been a stronghold to the poor, a stronghold to the needy in his distress, a refuge from the storm, a shade from the heat, when the blast of the dreaded ones is like a storm against the wall.",
                "As the heat in a dry place will you bring down the noise of strangers; as the heat by the shade of a cloud, the song of the dreaded ones will be brought low.",
                "In this mountain, Yahweh of Armies will make all peoples a feast of fat things, a feast of choice wines, of fat things full of marrow, of well refined choice wines.",
                "He will destroy in this mountain the surface of the covering that covers all peoples, and the veil that is spread over all nations.",
                "He has swallowed up death forever! The Lord Yahweh will wipe away tears from off all faces. He will take the reproach of his people away from off all the earth, for Yahweh has spoken it.",
                "It shall be said in that day, \"Behold, this is our God! We have waited for him, and he will save us! This is Yahweh! We have waited for him. We will be glad and rejoice in his salvation!\"",
                "For in this mountain the hand of Yahweh will rest. Moab will be trodden down in his place, even like straw is trodden down in the water of the dunghill.",
                "He will spread out his hands in its midst, like one who swims spreads out hands to swim, but his pride will be humbled together with the craft of his hands.",
                "He has brought the high fortress of your walls down, laid low, and brought to the ground, even to the dust."
            ],
            26: [
                "In that day, this song will be sung in the land of Judah: \"We have a strong city. God appoints salvation for walls and bulwarks.",
                "Open the gates, that the righteous nation may enter: the one which keeps faith.",
                "You will keep whoever\u2019s mind is steadfast in perfect peace, because he trusts in you.",
                "Trust in Yahweh forever; for in Yah, Yahweh, is an everlasting Rock.",
                "For he has brought down those who dwell on high, the lofty city. He lays it low. He lays it low even to the ground. He brings it even to the dust.",
                "The foot shall tread it down; Even the feet of the poor, and the steps of the needy.\"",
                "The way of the just is uprightness. You who are upright make the path of the righteous level.",
                "Yes, in the way of your judgments, Yahweh, have we waited for you. Your name and your renown are the desire of our soul.",
                "With my soul have I desired you in the night. Yes, with my spirit within me will I seek you earnestly; for when your judgments are in the earth, the inhabitants of the world learn righteousness.",
                "Let favor be shown to the wicked, yet he will not learn righteousness. In the land of uprightness he will deal wrongfully, and will not see Yahweh\u2019s majesty.",
                "Yahweh, your hand is lifted up, yet they don\u2019t see; but they will see your zeal for the people, and be disappointed. Yes, fire will consume your adversaries.",
                "Yahweh, you will ordain peace for us, for you have also worked all our works for us.",
                "Yahweh our God, other lords besides you have had dominion over us, but by you only will we make mention of your name.",
                "The dead shall not live. The deceased shall not rise. Therefore have you visited and destroyed them, and caused all memory of them to perish.",
                "You have increased the nation, O Yahweh. You have increased the nation! You are glorified! You have enlarged all the borders of the land.",
                "Yahweh, in trouble they have visited you. They poured out a prayer when your chastening was on them.",
                "Like as a woman with child, who draws near the time of her delivery, is in pain and cries out in her pangs; so we have been before you, Yahweh.",
                "We have been with child. We have been in pain. We gave birth, it seems, only to wind. We have not worked any deliverance in the earth; neither have the inhabitants of the world fallen.",
                "Your dead shall live. My dead bodies shall arise. Awake and sing, you who dwell in the dust; for your dew is like the dew of herbs, and the earth will cast forth the dead.",
                "Come, my people, enter into your rooms, and shut your doors behind you. Hide yourself for a little moment, until the indignation is past.",
                "For, behold, Yahweh comes forth out of his place to punish the inhabitants of the earth for their iniquity. The earth also will disclose her blood, and will no longer cover her slain."
            ],
            27: [
                "In that day, Yahweh with his hard and great and strong sword will punish leviathan, the fleeing serpent, and leviathan the twisted serpent; and he will kill the dragon that is in the sea.",
                "In that day, sing to her, \"A pleasant vineyard!",
                "I, Yahweh, am its keeper. I will water it every moment. Lest anyone damage it, I will keep it night and day.",
                "Wrath is not in me, but if I should find briers and thorns, I would do battle! I would march on them and I would burn them together.",
                "Or else let him take hold of my strength, that he may make peace with me. Let him make peace with me.\"",
                "In days to come, Jacob will take root. Israel will blossom and bud. They will fill the surface of the world with fruit.",
                "Has he struck them as he struck those who struck them? Or are they killed like those who killed them were killed?",
                "In measure, when you send them away, you contend with them. He has removed them with his rough blast in the day of the east wind.",
                "Therefore, by this the iniquity of Jacob will be forgiven, and this is all the fruit of taking away his sin: that he makes all the stones of the altar as chalk stones that are beaten in pieces, so that the Asherim and the incense altars shall rise no more.",
                "For the fortified city is solitary, a habitation deserted and forsaken, like the wilderness. The calf will feed there, and there he will lie down, and consume its branches.",
                "When its boughs are withered, they will be broken off. The women will come and set them on fire, for they are a people of no understanding. Therefore he who made them will not have compassion on them, and he who formed them will show them no favor.",
                "It will happen in that day, that Yahweh will thresh from the flowing stream of the Euphrates to the brook of Egypt; and you will be gathered one by one, children of Israel.",
                "It will happen in that day that a great trumpet will be blown; and those who were ready to perish in the land of Assyria, and those who were outcasts in the land of Egypt, shall come; and they will worship Yahweh in the holy mountain at Jerusalem."
            ],
            28: [
                "Woe to the crown of pride of the drunkards of Ephraim, and to the fading flower of his glorious beauty, which is on the head of the fertile valley of those who are overcome with wine!",
                "Behold, the Lord has a mighty and strong one. Like a storm of hail, a destroying storm, and like a storm of mighty waters overflowing, he will cast them down to the earth with his hand.",
                "The crown of pride of the drunkards of Ephraim will be trodden under foot.",
                "The fading flower of his glorious beauty, which is on the head of the fertile valley, shall be like the first-ripe fig before the summer; which someone picks and eats as soon as he sees it.",
                "In that day, Yahweh of Armies will become a crown of glory, and a diadem of beauty, to the residue of his people;",
                "and a spirit of justice to him who sits in judgment, and strength to those who turn back the battle at the gate.",
                "They also reel with wine, and stagger with strong drink. The priest and the prophet reel with strong drink. They are swallowed up by wine. They stagger with strong drink. They err in vision. They stumble in judgment.",
                "For all tables are completely full of filthy vomit and filthiness.",
                "Whom will he teach knowledge? To whom will he explain the message? Those who are weaned from the milk, and drawn from the breasts?",
                "For it is precept on precept, precept on precept; line on line, line on line; here a little, there a little.",
                "But he will speak to this nation with stammering lips and in another language;",
                "to whom he said, \"This is the resting place. Give rest to weary;\" and \"This is the refreshing;\" yet they would not hear.",
                "Therefore the word of Yahweh will be to them precept on precept, precept on precept; line on line, line on line; here a little, there a little; that they may go, fall backward, be broken, be snared, and be taken.",
                "Therefore hear the word of Yahweh, you scoffers, that rule this people in Jerusalem:",
                "\"Because you have said, \u2018We have made a covenant with death, and with Sheol are we in agreement. When the overflowing scourge passes through, it won\u2019t come to us; for we have made lies our refuge, and we have hidden ourselves under falsehood.\u2019\"",
                "Therefore thus says the Lord Yahweh, \"Behold, I lay in Zion for a foundation a stone, a tried stone, a precious cornerstone of a sure foundation. He who believes shall not act hastily.",
                "I will make justice the measuring line, and righteousness the plumb line. The hail will sweep away the refuge of lies, and the waters will overflow the hiding place.",
                "Your covenant with death shall be annulled, and your agreement with Sheol shall not stand. When the overflowing scourge passes through, then you will be trampled down by it.",
                "As often as it passes through, it will seize you; for morning by morning it will pass through, by day and by night; and it will be nothing but terror to understand the message.\"",
                "For the bed is too short to stretch out on, and the blanket is too narrow to wrap oneself in.",
                "For Yahweh will rise up as on Mount Perazim. He will be angry as in the valley of Gibeon; that he may do his work, his unusual work, and bring to pass his act, his extraordinary act.",
                "Now therefore don\u2019t be scoffers, lest your bonds be made strong; for I have heard a decree of destruction from the Lord, Yahweh of Armies, on the whole earth.",
                "Give ear, and hear my voice! Listen, and hear my speech!",
                "Does he who plows to sow plow continually? Does he keep turning the soil and breaking the clods?",
                "When he has leveled its surface, doesn\u2019t he plant the dill, and scatter the cumin seed, and put in the wheat in rows, the barley in the appointed place, and the spelt in its place?",
                "For his God instructs him in right judgment, and teaches him.",
                "For the dill are not threshed with a sharp instrument, neither is a cart wheel turned over the cumin; but the dill is beaten out with a stick, and the cumin with a rod.",
                "Bread flour must be ground; so he will not always be threshing it. Although he drives the wheel of his threshing cart over it, his horses don\u2019t grind it.",
                "This also comes forth from Yahweh of Armies, who is wonderful in counsel, and excellent in wisdom."
            ],
            29: [
                "Woe to Ariel! Ariel, the city where David encamped! Add year to year; let the feasts come around;",
                "then I will distress Ariel, and there will be mourning and lamentation. She shall be to me as an altar hearth.",
                "I will encamp against you all around you, and will lay siege against you with posted troops. I will raise siege works against you.",
                "You will be brought down, and will speak out of the ground. Your speech will mumble out of the dust. Your voice will be as of one who has a familiar spirit, out of the ground, and your speech will whisper out of the dust.",
                "But the multitude of your foes will be like fine dust, and the multitude of the ruthless ones like chaff that blows away. Yes, it will be in an instant, suddenly.",
                "She will be visited by Yahweh of Armies with thunder, with earthquake, with great noise, with whirlwind and storm, and with the flame of a devouring fire.",
                "The multitude of all the nations that fight against Ariel, even all who fight against her and her stronghold, and who distress her, will be like a dream, a vision of the night.",
                "It will be like when a hungry man dreams, and behold, he eats; but he awakes, and his hunger isn\u2019t satisfied; or like when a thirsty man dreams, and behold, he drinks; but he awakes, and behold, he is faint, and he is still thirsty. The multitude of all the nations that fight against Mount Zion will be like that.",
                "Pause and wonder! Blind yourselves and be blind! They are drunken, but not with wine; they stagger, but not with strong drink.",
                "For Yahweh has poured out on you a spirit of deep sleep, and has closed your eyes, the prophets; and he has covered your heads, the seers.",
                "All vision has become to you like the words of a book that is sealed, which men deliver to one who is educated, saying, \"Read this, please;\" and he says, \"I can\u2019t, for it is sealed:\"",
                "and the book is delivered to one who is not educated, saying, \"Read this, please;\" and he says, \"I can\u2019t read.\"",
                "The Lord said, \"Because this people draws near with their mouth and with their lips to honor me, but they have removed their heart far from me, and their fear of me is a commandment of men which has been taught;",
                "therefore, behold, I will proceed to do a marvelous work among this people, even a marvelous work and a wonder; and the wisdom of their wise men will perish, and the understanding of their prudent men will be hidden.\"",
                "Woe to those who deeply hide their counsel from Yahweh, and whose works are in the dark, and who say, \"Who sees us?\" and \"Who knows us?\"",
                "You turn things upside down! Should the potter be thought to be like clay; that the thing made should say about him who made it, \"He didn\u2019t make me;\" or the thing formed say of him who formed it, \"He has no understanding?\"",
                "Isn\u2019t it yet a very little while, and Lebanon will be turned into a fruitful field, and the fruitful field will be regarded as a forest?",
                "In that day, the deaf will hear the words of the book, and the eyes of the blind will see out of obscurity and out of darkness.",
                "The humble also will increase their joy in Yahweh, and the poor among men will rejoice in the Holy One of Israel.",
                "For the ruthless is brought to nothing, and the scoffer ceases, and all those who are alert to do evil are cut off\u2014",
                "who cause a person to be indicted by a word, and lay a snare for the arbiter in the gate, and who deprive the innocent of justice with false testimony.",
                "Therefore thus says Yahweh, who redeemed Abraham, concerning the house of Jacob: \"Jacob shall no longer be ashamed, neither shall his face grow pale.",
                "But when he sees his children, the work of my hands, in the midst of him, they will sanctify my name. Yes, they will sanctify the Holy One of Jacob, and will stand in awe of the God of Israel.",
                "They also who err in spirit will come to understanding, and those who grumble will receive instruction.\""
            ],
            30: [
                "\"Woe to the rebellious children,\" says Yahweh, \"who take counsel, but not from me; and who make an alliance, but not with my Spirit, that they may add sin to sin,",
                "who set out to go down into Egypt, and have not asked my advice; to strengthen themselves in the strength of Pharaoh, and to take refuge in the shadow of Egypt!",
                "Therefore the strength of Pharaoh will be your shame, and the refuge in the shadow of Egypt your confusion.",
                "For their princes are at Zoan, and their ambassadors have come to Hanes.",
                "They shall all be ashamed because of a people that can\u2019t profit them, that are not a help nor profit, but a shame, and also a reproach.\"",
                "The burden of the animals of the South. Through the land of trouble and anguish, of the lioness and the lion, the viper and fiery flying serpent, they carry their riches on the shoulders of young donkeys, and their treasures on the humps of camels, to an unprofitable people.",
                "For Egypt helps in vain, and to no purpose; therefore have I called her Rahab who sits still.",
                "Now go, write it before them on a tablet, and inscribe it in a book, that it may be for the time to come forever and ever.",
                "For it is a rebellious people, lying children, children who will not hear the law of Yahweh;",
                "who tell the seers, \"Don\u2019t see!\" and to the prophets, \"Don\u2019t prophesy to us right things. Tell us pleasant things. Prophesy deceits.",
                "Get out of the way. Turn aside from the path. Cause the Holy One of Israel to cease from before us.\"",
                "Therefore thus says the Holy One of Israel, \"Because you despise this word, and trust in oppression and perverseness, and rely on it;",
                "therefore this iniquity shall be to you like a breach ready to fall, swelling out in a high wall, whose breaking comes suddenly in an instant.",
                "He will break it as a potter\u2019s vessel is broken, breaking it in pieces without sparing, so that there won\u2019t be found among the broken piece a piece good enough to take fire from the hearth, or to dip up water out of the cistern.\"",
                "For thus said the Lord Yahweh, the Holy One of Israel, \"You will be saved in returning and rest. Your strength will be in quietness and in confidence.\" You refused,",
                "but you said, \"No, for we will flee on horses;\" therefore you will flee; and, \"We will ride on the swift;\" therefore those who pursue you will be swift.",
                "One thousand will flee at the threat of one. At the threat of five, you will flee until you are left like a beacon on the top of a mountain, and like a banner on a hill.",
                "Therefore Yahweh will wait, that he may be gracious to you; and therefore he will be exalted, that he may have mercy on you, for Yahweh is a God of justice. Blessed are all those who wait for him.",
                "For the people will dwell in Zion at Jerusalem. You will weep no more. He will surely be gracious to you at the voice of your cry. When he hears you, he will answer you.",
                "Though the Lord may give you the bread of adversity and the water of affliction, yet your teachers won\u2019t be hidden anymore, but your eyes will see your teachers;",
                "and when you turn to the right hand, and when you turn to the left, your ears will hear a voice behind you, saying, \"This is the way. Walk in it.\"",
                "You shall defile the overlaying of your engraved images of silver, and the plating of your molten images of gold. You shall cast them away as an unclean thing. You shall tell it, \"Go away!\"",
                "He will give the rain for your seed, with which you will sow the ground; and bread of the increase of the ground will be rich and plentiful. In that day, your livestock will feed in large pastures.",
                "The oxen likewise and the young donkeys that till the ground will eat savory provender, which has been winnowed with the shovel and with the fork.",
                "There shall be brooks and streams of water on every lofty mountain and on every high hill in the day of the great slaughter, when the towers fall.",
                "Moreover the light of the moon will be like the light of the sun, and the light of the sun will be seven times brighter, like the light of seven days, in the day that Yahweh binds up the fracture of his people, and heals the wound they were struck with.",
                "Behold, the name of Yahweh comes from far away, burning with his anger, and in thick rising smoke. His lips are full of indignation, and his tongue is as a devouring fire.",
                "His breath is as an overflowing stream that reaches even to the neck, to sift the nations with the sieve of destruction; and a bridle that leads to ruin will be in the jaws of the peoples.",
                "You will have a song, as in the night when a holy feast is kept; and gladness of heart, as when one goes with a flute to come to Yahweh\u2019s mountain, to Israel\u2019s Rock.",
                "Yahweh will cause his glorious voice to be heard, and will show the descent of his arm, with the indignation of his anger, and the flame of a devouring fire, with a blast, storm, and hailstones.",
                "For through the voice of Yahweh the Assyrian will be dismayed. He will strike him with his rod.",
                "Every stroke of the rod of punishment, which Yahweh will lay on him, will be with the sound of tambourines and harps. He will fight with them in battles, brandishing weapons.",
                "For his burning place has long been ready. Yes, for the king it is prepared. He has made its pyre deep and large with fire and much wood. Yahweh\u2019s breath, like a stream of sulfur, kindles it."
            ],
            31: [
                "Woe to those who go down to Egypt for help, and rely on horses, and trust in chariots because they are many, and in horsemen because they are very strong, but they don\u2019t look to the Holy One of Israel, and they don\u2019t seek Yahweh!",
                "Yet he also is wise, and will bring disaster, and will not call back his words, but will arise against the house of the evildoers, and against the help of those who work iniquity.",
                "Now the Egyptians are men, and not God; and their horses flesh, and not spirit. When Yahweh stretches out his hand, both he who helps shall stumble, and he who is helped shall fall, and they all shall be consumed together.",
                "For thus says Yahweh to me, \"As the lion and the young lion growling over his prey, if a multitude of shepherds is called together against him, will not be dismayed at their voice, nor abase himself for their noise, so Yahweh of Armies will come down to fight on Mount Zion and on its heights.",
                "As birds hovering, so Yahweh of Armies will protect Jerusalem. He will protect and deliver it. He will pass over and preserve it.\"",
                "Return to him from whom you have deeply revolted, children of Israel.",
                "For in that day everyone shall cast away his idols of silver and his idols of gold\u2014sin which your own hands have made for you.",
                "\"The Assyrian will fall by the sword, not of man; and the sword, not of mankind, shall devour him. He will flee from the sword, and his young men will become subject to forced labor.",
                "His rock will pass away by reason of terror, and his princes will be afraid of the banner,\" says Yahweh, whose fire is in Zion, and his furnace in Jerusalem."
            ],
            32: [
                "Behold, a king shall reign in righteousness, and princes shall rule in justice.",
                "A man shall be as a hiding place from the wind, and a covert from the storm, as streams of water in a dry place, as the shade of a large rock in a weary land.",
                "The eyes of those who see will not be dim, and the ears of those who hear will listen.",
                "The heart of the rash will understand knowledge, and the tongue of the stammerers will be ready to speak plainly.",
                "The fool will no longer be called noble, nor the scoundrel be highly respected.",
                "For the fool will speak folly, and his heart will work iniquity, to practice profanity, and to utter error against Yahweh, To make empty the soul of the hungry, and to cause the drink of the thirsty to fail.",
                "The ways of the scoundrel are evil. He devises wicked devices to destroy the humble with lying words, even when the needy speaks right.",
                "But the noble devises noble things; and he will continue in noble things.",
                "Rise up, you women who are at ease! Hear my voice! You careless daughters, give ear to my speech!",
                "For days beyond a year you will be troubled, you careless women; for the vintage shall fail. The harvest won\u2019t come.",
                "Tremble, you women who are at ease! Be troubled, you careless ones! Strip yourselves, make yourselves naked, and put sackcloth on your waist.",
                "Beat your breasts for the pleasant fields, for the fruitful vine.",
                "Thorns and briars will come up on my people\u2019s land; yes, on all the houses of joy in the joyous city.",
                "For the palace will be forsaken. The populous city will be deserted. The hill and the watchtower will be for dens forever, a delight for wild donkeys, a pasture of flocks;",
                "Until the Spirit is poured on us from on high, and the wilderness becomes a fruitful field, and the fruitful field is considered a forest.",
                "Then justice will dwell in the wilderness; and righteousness will remain in the fruitful field.",
                "The work of righteousness will be peace; and the effect of righteousness, quietness and confidence forever.",
                "My people will live in a peaceful habitation, in safe dwellings, and in quiet resting places.",
                "Though hail flattens the forest, and the city is leveled completely.",
                "Blessed are you who sow beside all waters, who send out the feet of the ox and the donkey."
            ],
            33: [
                "Woe to you who destroy, but you weren\u2019t destroyed; and who betray, but nobody betrayed you! When you have finished destroying, you will be destroyed; and when you have made an end of betrayal, you will be betrayed.",
                "Yahweh, be gracious to us. We have waited for you. Be our strength every morning, our salvation also in the time of trouble.",
                "At the noise of the thunder, the peoples have fled. When you lift yourself up, the nations are scattered.",
                "Your spoil will be gathered as the caterpillar gathers. Men will leap on it as locusts leap.",
                "Yahweh is exalted, for he dwells on high. He has filled Zion with justice and righteousness.",
                "There will be stability in your times, abundance of salvation, wisdom, and knowledge. The fear of Yahweh is your treasure.",
                "Behold, their valiant ones cry outside; the ambassadors of peace weep bitterly.",
                "The highways are desolate. The traveling man ceases. The covenant is broken. He has despised the cities. He doesn\u2019t respect man.",
                "The land mourns and languishes. Lebanon is confounded and withers away. Sharon is like a desert, and Bashan and Carmel are stripped bare.",
                "\"Now I will arise,\" says Yahweh; \"Now I will lift myself up. Now I will be exalted.",
                "You will conceive chaff. You will bring forth stubble. Your breath is a fire that will devour you.",
                "The peoples will be like the burning of lime, like thorns that are cut down and burned in the fire.",
                "Hear, you who are far off, what I have done; and, you who are near, acknowledge my might.\"",
                "The sinners in Zion are afraid. Trembling has seized the godless ones. Who among us can live with the devouring fire? Who among us can live with everlasting burning?",
                "He who walks righteously, and speaks blamelessly; He who despises the gain of oppressions, who gestures with his hands, refusing to take a bribe, who stops his ears from hearing of blood, and shuts his eyes from looking at evil\u2014",
                "he will dwell on high. His place of defense will be the fortress of rocks. His bread will be supplied. His waters will be sure.",
                "Your eyes will see the king in his beauty. They will see a distant land.",
                "Your heart will meditate on the terror. Where is he who counted? Where is he who weighed? Where is he who counted the towers?",
                "You will no longer see the fierce people, a people of a deep speech that you can\u2019t comprehend, with a strange language that you can\u2019t understand.",
                "Look at Zion, the city of our appointed festivals. Your eyes will see Jerusalem, a quiet habitation, a tent that won\u2019t be removed. Its stakes will never be plucked up, nor will any of its cords be broken.",
                "But there Yahweh will be with us in majesty, a place of broad rivers and streams, in which no galley with oars will go, neither will any gallant ship pass by there.",
                "For Yahweh is our judge. Yahweh is our lawgiver. Yahweh is our king. He will save us.",
                "Your rigging is untied. They couldn\u2019t strengthen the foot of their mast. They couldn\u2019t spread the sail. Then the prey of a great spoil was divided. The lame took the prey.",
                "The inhabitant won\u2019t say, \"I am sick.\" The people who dwell therein will be forgiven their iniquity."
            ],
            34: [
                "Come near, you nations, to hear! Listen, you peoples. Let the earth and all it contains hear; the world, and everything that comes from it.",
                "For Yahweh is enraged against all the nations, and angry with all their armies. He has utterly destroyed them. He has given them over for slaughter.",
                "Their slain will also be cast out, and the stench of their dead bodies will come up; and the mountains will melt in their blood.",
                "All of the army of the sky will be dissolved. The sky will be rolled up like a scroll, and all its armies will fade away, as a leaf fades from off a vine or a fig tree.",
                "For my sword has drunk its fill in the sky. Behold, it will come down on Edom, and on the people of my curse, for judgment.",
                "Yahweh\u2019s sword is filled with blood. It is covered with fat, with the blood of lambs and goats, with the fat of the kidneys of rams; for Yahweh has a sacrifice in Bozrah, And a great slaughter in the land of Edom.",
                "The wild oxen will come down with them, and the young bulls with the mighty bulls; and their land will be drunken with blood, and their dust made greasy with fat.",
                "For Yahweh has a day of vengeance, a year of recompense for the cause of Zion.",
                "Its streams will be turned into pitch, its dust into sulfur, And its land will become burning pitch.",
                "It won\u2019t be quenched night nor day. Its smoke will go up forever. From generation to generation, it will lie waste. No one will pass through it forever and ever.",
                "But the pelican and the porcupine will possess it. The owl and the raven will dwell in it. He will stretch the line of confusion over it, and the plumb line of emptiness.",
                "They shall call its nobles to the kingdom, but none shall be there; and all its princes shall be nothing.",
                "Thorns will come up in its palaces, nettles and thistles in its fortresses; and it will be a habitation of jackals, a court for ostriches.",
                "The wild animals of the desert will meet with the wolves, and the wild goat will cry to his fellow. Yes, the night creature shall settle there, and shall find herself a place of rest.",
                "The arrow snake will make her nest there, and lay, hatch, and gather under her shade. Yes, the kites will be gathered there, every one with her mate.",
                "Search in the book of Yahweh, and read: not one of these will be missing. none will lack her mate. For my mouth has commanded, and his Spirit has gathered them.",
                "He has cast the lot for them, and his hand has divided it to them with a measuring line. They shall possess it forever. From generation to generation they will dwell in it."
            ],
            35: [
                "The wilderness and the dry land will be glad. The desert will rejoice and blossom like a rose.",
                "It will blossom abundantly, and rejoice even with joy and singing. Lebanon\u2019s glory Lebanon will be given to it, the excellence of Carmel and Sharon. They will see Yahweh\u2019s glory, the excellence of our God.",
                "Strengthen the weak hands, and make firm the feeble knees.",
                "Tell those who have a fearful heart, \"Be strong. Don\u2019t be afraid. Behold, your God will come with vengeance, God\u2019s retribution. He will come and save you.",
                "Then the eyes of the blind will be opened, and the ears of the deaf will be unstopped.",
                "Then the lame man will leap like a deer, and the tongue of the mute will sing; for waters will break out in the wilderness, and streams in the desert.",
                "The burning sand will become a pool, and the thirsty ground springs of water. Grass with reeds and rushes will be in the habitation of jackals, where they lay.",
                "A highway will be there, a road, and it will be called The Holy Way. The unclean shall not pass over it, but it will be for those who walk in the Way. Wicked fools will not go there.",
                "No lion will be there, nor will any ravenous animal go up on it. They will not be found there; but the redeemed will walk there.",
                "The Yahweh\u2019s ransomed ones will return, and come with singing to Zion; and everlasting joy will be on their heads. They will obtain gladness and joy, and sorrow and sighing will flee away.\""
            ],
            36: [
                "Now it happened in the fourteenth year of king Hezekiah, that Sennacherib king of Assyria attacked all of the fortified cities of Judah, and captured them.",
                "The king of Assyria sent Rabshakeh from Lachish to Jerusalem to king Hezekiah with a large army. He stood by the aqueduct from the upper pool in the fuller\u2019s field highway.",
                "Then Eliakim the son of Hilkiah, who was over the household, and Shebna the scribe, and Joah, the son of Asaph, the recorder came out to him.",
                "Rabshakeh said to them, \"Now tell Hezekiah, \u2018Thus says the great king, the king of Assyria, \"What confidence is this in which you trust?",
                "I say that your counsel and strength for the war are only vain words. Now in whom do you trust, that you have rebelled against me?",
                "Behold, you trust in the staff of this bruised reed, even in Egypt, which if a man leans on it, it will go into his hand and pierce it. So is Pharaoh king of Egypt to all who trust in him.",
                "But if you tell me, \u2018We trust in Yahweh our God,\u2019 isn\u2019t that he whose high places and whose altars Hezekiah has taken away, and has said to Judah and to Jerusalem, \u2018You shall worship before this altar?\u2019\"",
                "Now therefore, please make a pledge to my master the king of Assyria, and I will give you two thousand horses, if you are able on your part to set riders on them.",
                "How then can you turn away the face of one captain of the least of my master\u2019s servants, and put your trust on Egypt for chariots and for horsemen?",
                "Have I come up now without Yahweh against this land to destroy it? Yahweh said to me, \"Go up against this land, and destroy it.\"\u2019\"",
                "Then Eliakim, Shebna and Joah said to Rabshakeh, \"Please speak to your servants in Aramaic, for we understand it; and don\u2019t speak to us in the Jews\u2019 language in the hearing of the people who are on the wall.\"",
                "But Rabshakeh said, \"Has my master sent me only to your master and to you, to speak these words, and not to the men who sit on the wall, who will eat their own dung and drink their own urine with you?\"",
                "Then Rabshakeh stood, and called out with a loud voice in the Jews\u2019 language, and said, \"Hear the words of the great king, the king of Assyria!",
                "Thus says the king, \u2018Don\u2019t let Hezekiah deceive you; for he will not be able to deliver you.",
                "Don\u2019t let Hezekiah make you trust in Yahweh, saying, \"Yahweh will surely deliver us. This city won\u2019t be given into the hand of the king of Assyria.\"\u2019",
                "Don\u2019t listen to Hezekiah, for thus says the king of Assyria, \u2018Make your peace with me, and come out to me; and each of you eat from his vine, and each one from his fig tree, and each one of you drink the waters of his own cistern;",
                "until I come and take you away to a land like your own land, a land of grain and new wine, a land of bread and vineyards.",
                "Beware lest Hezekiah persuade you, saying, \"Yahweh will deliver us.\" Have any of the gods of the nations delivered their lands from the hand of the king of Assyria?",
                "Where are the gods of Hamath and Arpad? Where are the gods of Sepharvaim? Have they delivered Samaria from my hand?",
                "Who are they among all the gods of these countries that have delivered their country out of my hand, that Yahweh should deliver Jerusalem out of my hand?\u2019\"",
                "But they remained silent, and said nothing in reply, for the king\u2019s commandment was, \"Don\u2019t answer him.\"",
                "Then Eliakim the son of Hilkiah, who was over the household, and Shebna the scribe, and Joah, the son of Asaph, the recorder, came to Hezekiah with their clothes torn, and told him the words of Rabshakeh."
            ],
            37: [
                "It happened, when king Hezekiah heard it, that he tore his clothes, covered himself with sackcloth, and went into Yahweh\u2019s house.",
                "He sent Eliakim, who was over the household, and Shebna the scribe, and the elders of the priests, covered with sackcloth, to Isaiah the prophet, the son of Amoz.",
                "They said to him, \"Thus says Hezekiah, \u2018This day is a day of trouble, and of rebuke, and of rejection; for the children have come to the birth, and there is no strength to bring forth.",
                "It may be Yahweh your God will hear the words of Rabshakeh, whom the king of Assyria his master has sent to defy the living God, and will rebuke the words which Yahweh your God has heard. Therefore lift up your prayer for the remnant that is left.\u2019\"",
                "So the servants of king Hezekiah came to Isaiah.",
                "Isaiah said to them, \"Tell your master, \u2018Thus says Yahweh, \"Don\u2019t be afraid of the words that you have heard, with which the servants of the king of Assyria have blasphemed me.",
                "Behold, I will put a spirit in him and he will hear news, and will return to his own land. I will cause him to fall by the sword in his own land.\"\u2019\"",
                "So Rabshakeh returned, and found the king of Assyria warring against Libnah, for he had heard that he was departed from Lachish.",
                "He heard news concerning Tirhakah king of Ethiopia, \"He has come out to fight against you.\" When he heard it, he sent messengers to Hezekiah, saying,",
                "\"Thus you shall speak to Hezekiah king of Judah, saying, \u2018Don\u2019t let your God in whom you trust deceive you, saying, \"Jerusalem won\u2019t be given into the hand of the king of Assyria.\"",
                "Behold, you have heard what the kings of Assyria have done to all lands, by destroying them utterly. Shall you be delivered?",
                "Have the gods of the nations delivered them, which my fathers have destroyed, Gozan, Haran, Rezeph, and the children of Eden who were in Telassar?",
                "Where is the king of Hamath, and the king of Arpad, and the king of the city of Sepharvaim, of Hena, and Ivvah?\u2019\"",
                "Hezekiah received the letter from the hand of the messengers and read it. Then Hezekiah went up to Yahweh\u2019s house, and spread it before Yahweh.",
                "Hezekiah prayed to Yahweh, saying,",
                "\"Yahweh of Armies, the God of Israel, who is enthroned among the cherubim, you are the God, even you alone, of all the kingdoms of the earth. You have made heaven and earth.",
                "Turn your ear, Yahweh, and hear. Open your eyes, Yahweh, and behold. Hear all of the words of Sennacherib, who has sent to defy the living God.",
                "Truly, Yahweh, the kings of Assyria have destroyed all the countries and their land,",
                "and have cast their gods into the fire; for they were no gods, but the work of men\u2019s hands, wood and stone; therefore they have destroyed them.",
                "Now therefore, Yahweh our God, save us from his hand, that all the kingdoms of the earth may know that you are Yahweh, even you only.\"",
                "Then Isaiah the son of Amoz sent to Hezekiah, saying, \"Thus says Yahweh, the God of Israel, \u2018Because you have prayed to me against Sennacherib king of Assyria,",
                "this is the word which Yahweh has spoken concerning him. The virgin daughter of Zion has despised you and ridiculed you. The daughter of Jerusalem has shaken her head at you.",
                "Whom have you defied and blasphemed? Against whom have you exalted your voice and lifted up your eyes on high? Against the Holy One of Israel.",
                "By your servants, have you defied the Lord, and have said, \"With the multitude of my chariots I have come up to the height of the mountains, to the innermost parts of Lebanon. I will cut down its tall cedars and its choice fir trees. I will enter into its farthest height, the forest of its fruitful field.",
                "I have dug and drunk water, and with the sole of my feet I will dry up all the rivers of Egypt.\"",
                "Have you not heard how I have done it long ago, and formed it in ancient times? Now I have brought it to pass, that it should be yours to destroy fortified cities, turning them into ruinous heaps.",
                "Therefore their inhabitants had little power. They were dismayed and confounded. They were like the grass of the field, and like the green herb, like the grass on the housetops, and like a field before its crop has grown.",
                "But I know your sitting down, your going out, your coming in, and your raging against me.",
                "Because of your raging against me, and because your arrogance has come up into my ears, therefore will I put my hook in your nose and my bridle in your lips, and I will turn you back by the way by which you came.",
                "This shall be the sign to you. You will eat this year that which grows of itself, and in the second year that which springs from the same; and in the third year sow and reap and plant vineyards, and eat their fruit.",
                "The remnant that is escaped of the house of Judah will again take root downward, and bear fruit upward.",
                "For out of Jerusalem a remnant will go forth, and survivors will escape from Mount Zion. The zeal of Yahweh of Armies will perform this.\u2019",
                "Therefore thus says Yahweh concerning the king of Assyria, \u2018He will not come to this city, nor shoot an arrow there, neither will he come before it with shield, nor cast up a mound against it.",
                "By the way that he came, by the same he shall return, and he shall not come to this city,\u2019 says Yahweh.",
                "\u2018For I will defend this city to save it, for my own sake, and for my servant David\u2019s sake.\u2019\"",
                "The angel of Yahweh went out and struck one hundred and eighty-five thousand men in the camp of the Assyrians. When men arose early in the morning, behold, these were all dead bodies.",
                "So Sennacherib king of Assyria departed, went away, returned to Nineveh, and stayed there.",
                "It happened, as he was worshipping in the house of Nisroch his god, that Adrammelech and Sharezer his sons struck him with the sword; and they escaped into the land of Ararat. Esar Haddon his son reigned in his place."
            ],
            38: [
                "In those days was Hezekiah sick and near death. Isaiah the prophet, the son of Amoz, came to him, and said to him, \"Thus says Yahweh, \u2018Set your house in order, for you will die, and not live.\u2019\"",
                "Then Hezekiah turned his face to the wall and prayed to Yahweh,",
                "and said, \"Remember now, Yahweh, I beg you, how I have walked before you in truth and with a perfect heart, and have done that which is good in your sight.\" Hezekiah wept bitterly.",
                "Then the word of Yahweh came to Isaiah, saying,",
                "\"Go, and tell Hezekiah, \u2018Thus says Yahweh, the God of David your father, \"I have heard your prayer. I have seen your tears. Behold, I will add fifteen years to your life.",
                "I will deliver you and this city out of the hand of the king of Assyria, and I will defend this city.",
                "This shall be the sign to you from Yahweh, that Yahweh will do this thing that he has spoken.",
                "Behold, I will cause the shadow on the sundial, which has gone down on the sundial of Ahaz with the sun, to return backward ten steps. So the sun returned ten steps on the sundial on which it had gone down.\"\u2019\"",
                "The writing of Hezekiah king of Judah, when he had been sick, and had recovered of his sickness.",
                "I said, \"In the middle of my life I go into the gates of Sheol. I am deprived of the residue of my years.\"",
                "I said, \"I won\u2019t see Yah, Yah in the land of the living. I will see man no more with the inhabitants of the world.",
                "My dwelling is removed, and is carried away from me like a shepherd\u2019s tent. I have rolled up, like a weaver, my life. He will cut me off from the loom. From day even to night you will make an end of me.",
                "I waited patiently until morning. He breaks all my bones like a lion. From day even to night you will make an end of me.",
                "I chattered like a swallow or a crane. I moaned like a dove. My eyes weaken looking upward. Lord, I am oppressed. Be my security.\"",
                "What will I say? He has both spoken to me, and himself has done it. I will walk carefully all my years because of the anguish of my soul.",
                "Lord, men live by these things; and my spirit finds life in all of them: you restore me, and cause me to live.",
                "Behold, for peace I had great anguish, but you have in love for my soul delivered it from the pit of corruption; for you have cast all my sins behind your back.",
                "For Sheol can\u2019t praise you. Death can\u2019t celebrate you. Those who go down into the pit can\u2019t hope for your truth.",
                "The living, the living, he shall praise you, as I do this day. The father shall make known your truth to the children.",
                "Yahweh will save me. Therefore we will sing my songs with stringed instruments all the days of our life in the house of Yahweh.",
                "Now Isaiah had said, \"Let them take a cake of figs, and lay it for a poultice on the boil, and he shall recover.\"",
                "Hezekiah also had said, \"What is the sign that I will go up to the house of Yahweh?\""
            ],
            39: [
                "At that time, Merodach Baladan the son of Baladan, king of Babylon, sent letters and a present to Hezekiah; for he heard that he had been sick, and had recovered.",
                "Hezekiah was pleased with them, and showed them the house of his precious things, the silver, and the gold, the spices, and the precious oil, and all the house of his armor, and all that was found in his treasures. There was nothing in his house, nor in all his dominion, that Hezekiah didn\u2019t show them.",
                "Then Isaiah the prophet came to king Hezekiah, and asked him, \"What did these men say? Where did they come from to you?\" Hezekiah said, \"They have come from a country far from me, even from Babylon.\"",
                "Then he asked, \"What have they seen in your house?\" Hezekiah answered, \"They have seen all that is in my house. There is nothing among my treasures that I have not shown them.\"",
                "Then Isaiah said to Hezekiah, \"Hear the word of Yahweh of Armies:",
                "\u2018Behold, the days are coming when all that is in your house, and that which your fathers have stored up until this day, will be carried to Babylon. Nothing will be left,\u2019 says Yahweh.",
                "\u2018They will take away your sons who will issue from you, whom you shall father, and they will be eunuchs in the king of Babylon\u2019s palace.\u2019\"",
                "Then Hezekiah said to Isaiah, \"Yahweh\u2019s word which you have spoken is good.\" He said moreover, \"For there will be peace and truth in my days.\""
            ],
            40: [
                "\"Comfort, comfort my people,\" says your God.",
                "\"Speak comfortably to Jerusalem; and call out to her that her warfare is accomplished, that her iniquity is pardoned, that she has received of Yahweh\u2019s hand double for all her sins.\"",
                "The voice of one who calls out, \"Prepare the way of Yahweh in the wilderness! Make a level highway in the desert for our God.",
                "Every valley shall be exalted, and every mountain and hill shall be made low. The uneven shall be made level, and the rough places a plain.",
                "The glory of Yahweh shall be revealed, and all flesh shall see it together; for the mouth of Yahweh has spoken it.\"",
                "The voice of one saying, \"Cry!\" One said, \"What shall I cry?\" \"All flesh is like grass, and all its glory is like the flower of the field.",
                "The grass withers, the flower fades, because Yahweh\u2019s breath blows on it. Surely the people are like grass.",
                "The grass withers, the flower fades; but the word of our God stands forever.\"",
                "You who tell good news to Zion, go up on a high mountain. You who tell good news to Jerusalem, lift up your voice with strength. Lift it up. Don\u2019t be afraid. Say to the cities of Judah, \"Behold, your God!\"",
                "Behold, the Lord Yahweh will come as a mighty one, and his arm will rule for him. Behold, his reward is with him, and his recompense before him.",
                "He will feed his flock like a shepherd. He will gather the lambs in his arm, and carry them in his bosom. He will gently lead those who have their young.",
                "Who has measured the waters in the hollow of his hand, and marked off the sky with his span, and calculated the dust of the earth in a measure, and weighed the mountains in scales, and the hills in a balance?",
                "Who has directed the Spirit of Yahweh, or has taught him as his counselor?",
                "Who did he take counsel with, and who instructed him, and taught him in the path of justice, and taught him knowledge, and showed him the way of understanding?",
                "Behold, the nations are like a drop in a bucket, and are regarded as a speck of dust on a balance. Behold, he lifts up the islands like a very little thing.",
                "Lebanon is not sufficient to burn, nor its animals sufficient for a burnt offering.",
                "All the nations are like nothing before him. They are regarded by him as less than nothing, and vanity.",
                "To whom then will you liken God? Or what likeness will you compare to him?",
                "A workman has cast an image, and the goldsmith overlays it with gold, and casts silver chains for it.",
                "He who is too impoverished for such an offering chooses a tree that will not rot. He seeks a skillful workman to set up an engraved image for him that will not be moved.",
                "Haven\u2019t you known? Haven\u2019t you heard, yet? Haven\u2019t you been told from the beginning? Haven\u2019t you understood from the foundations of the earth?",
                "It is he who sits above the circle of the earth, and its inhabitants are like grasshoppers; who stretches out the heavens like a curtain, and spreads them out like a tent to dwell in;",
                "who brings princes to nothing; who makes the judges of the earth like meaningless.",
                "They are planted scarcely. They are sown scarcely. Their stock has scarcely taken root in the ground. He merely blows on them, and they wither, and the whirlwind takes them away as stubble.",
                "\"To whom then will you liken me? Who is my equal?\" says the Holy One.",
                "Lift up your eyes on high, and see who has created these, who brings out their army by number. He calls them all by name. by the greatness of his might, and because he is strong in power, Not one is lacking.",
                "Why do you say, Jacob, and speak, Israel, \"My way is hidden from Yahweh, and the justice due me is disregarded by my God?\"",
                "Haven\u2019t you known? Haven\u2019t you heard? The everlasting God, Yahweh, The Creator of the ends of the earth, doesn\u2019t faint. He isn\u2019t weary. His understanding is unsearchable.",
                "He gives power to the weak. He increases the strength of him who has no might.",
                "Even the youths faint and get weary, and the young men utterly fall;",
                "But those who wait for Yahweh will renew their strength. They will mount up with wings like eagles. They will run, and not be weary. They will walk, and not faint."
            ],
            41: [
                "\"Keep silent before me, islands, and let the peoples renew their strength. Let them come near, then let them speak. Let\u2019s meet together for judgment.",
                "Who has raised up one from the east? Who called him to his foot in righteousness? He hands over nations to him, and makes him rule over kings. He gives them like the dust to his sword, like the driven stubble to his bow.",
                "He pursues them, and passes by safely, Even by a way that he had not gone with his feet.",
                "Who has worked and done it, calling the generations from the beginning? I, Yahweh, the first, and with the last, I am he.\"",
                "The islands have seen, and fear. The ends of the earth tremble. They approach, and come.",
                "Everyone helps his neighbor. They say to their brothers, \"Be strong!\"",
                "So the carpenter encourages the goldsmith. He who smoothes with the hammer encourages him who strikes the anvil, saying of the soldering, \"It is good;\" and he fastens it with nails, that it might not totter.",
                "\"But you, Israel, my servant, Jacob whom I have chosen, the seed of Abraham my friend,",
                "You whom I have taken hold of from the ends of the earth, and called from its corners, and said to you, \u2018You are my servant, I have chosen you and not cast you away;\u2019",
                "Don\u2019t you be afraid, for I am with you. Don\u2019t be dismayed, for I am your God. I will strengthen you. Yes, I will help you. Yes, I will uphold you with the right hand of my righteousness.",
                "Behold, all those who are incensed against you will be disappointed and confounded. Those who strive with you will be like nothing, and shall perish.",
                "You will seek them, and won\u2019t find them, even those who contend with you. Those who war against you will be as nothing, as a non-existent thing.",
                "For I, Yahweh your God, will hold your right hand, saying to you, \u2018Don\u2019t be afraid. I will help you.\u2019",
                "Don\u2019t be afraid, you worm Jacob, and you men of Israel. I will help you,\" says Yahweh, \"and your Redeemer is the Holy One of Israel.",
                "Behold, I have made you into a new sharp threshing instrument with teeth. You will thresh the mountains, and beat them small, and will make the hills like chaff.",
                "You will winnow them, and the wind will carry them away, and the whirlwind will scatter them. You will rejoice in Yahweh. You will glory in the Holy One of Israel.",
                "The poor and needy seek water, and there is none. Their tongue fails for thirst. I, Yahweh, will answer them. I, the God of Israel, will not forsake them.",
                "I will open rivers on the bare heights, and springs in the midst of the valleys. I will make the wilderness a pool of water, and the dry land springs of water.",
                "I will put cedar, acacia, myrtle, and oil trees in the wilderness. I will set fir trees, pine, and box trees together in the desert;",
                "that they may see, know, consider, and understand together, that the hand of Yahweh has done this, and the Holy One of Israel has created it.",
                "Produce your cause,\" says Yahweh. \"Bring forth your strong reasons,\" says the King of Jacob.",
                "\"Let them announce, and declare to us what shall happen. Declare the former things, what they are, that we may consider them, and know the latter end of them; or show us things to come.",
                "Declare the things that are to come hereafter, that we may know that you are gods. Yes, do good, or do evil, that we may be dismayed, and see it together.",
                "Behold, you are of nothing, and your work is of nothing. He who chooses you is an abomination.",
                "\"I have raised up one from the north, and he has come; from the rising of the sun, one who calls on my name; and he shall come on rulers as on mortar, and as the potter treads clay.",
                "Who has declared it from the beginning, that we may know? And before, that we may say, \u2018He is right?\u2019 Surely, there is no one who declares. Surely, there is no one who shows. Surely, there is no one who hears your words.",
                "I am the first to say to Zion, \u2018Behold, look at them;\u2019 and I will give one who brings good news to Jerusalem.",
                "When I look, there is no man; even among them there is no counselor who, when I ask of them, can answer a word.",
                "Behold, all of them, their works are vanity and nothing. Their molten images are wind and confusion."
            ],
            42: [
                "\"Behold, my servant, whom I uphold; my chosen, in whom my soul delights\u2014I have put my Spirit on him. He will bring justice to the nations.",
                "He will not shout, nor raise his voice, nor cause it to be heard in the street.",
                "He won\u2019t break a bruised reed. He won\u2019t quench a dimly burning wick. He will faithfully bring justice.",
                "He will not fail nor be discouraged, until he has set justice in the earth, and the islands will wait for his law.\"",
                "Thus says God Yahweh, he who created the heavens and stretched them out, he who spread out the earth and that which comes out of it, he who gives breath to its people and spirit to those who walk in it.",
                "\"I, Yahweh, have called you in righteousness, and will hold your hand, and will keep you, and make you a covenant for the people, as a light for the nations;",
                "to open the blind eyes, to bring the prisoners out of the dungeon, and those who sit in darkness out of the prison.",
                "\"I am Yahweh. That is my name. I will not give my glory to another, nor my praise to engraved images.",
                "Behold, the former things have happened, and I declare new things. I tell you about them before they come up.\"",
                "Sing to Yahweh a new song, and his praise from the end of the earth, you who go down to the sea, and all that is therein, the islands and their inhabitants.",
                "Let the wilderness and its cities raise their voices, with the villages that Kedar inhabits. Let the inhabitants of Sela sing. Let them shout from the top of the mountains!",
                "Let them give glory to Yahweh, and declare his praise in the islands.",
                "Yahweh will go out like a mighty man. He will stir up zeal like a man of war. He will raise a war cry. Yes, he will shout aloud. He will triumph over his enemies.",
                "\"I have been silent a long time. I have been quiet and restrained myself. Now I will cry out like a travailing woman. I will both gasp and pant.",
                "I will destroy mountains and hills, and dry up all their herbs. I will make the rivers islands, and will dry up the pools.",
                "I will bring the blind by a way that they don\u2019t know. I will lead them in paths that they don\u2019t know. I will make darkness light before them, and crooked places straight. I will do these things, and I will not forsake them.",
                "\"Those who trust in engraved images, who tell molten images, \u2018You are our gods\u2019 will be turned back. They will be utterly disappointed.",
                "\"Hear, you deaf, and look, you blind, that you may see.",
                "Who is blind, but my servant? Or who is as deaf as my messenger whom I send? Who is as blind as he who is at peace, and as blind as Yahweh\u2019s servant?",
                "You see many things, but don\u2019t observe. His ears are open, but he doesn\u2019t listen.",
                "It pleased Yahweh, for his righteousness\u2019 sake, to magnify the law, and make it honorable.",
                "But this is a robbed and plundered people. All of them are snared in holes, and they are hidden in prisons. They have become a prey, and no one delivers; and a spoil, and no one says, \u2018Restore them!\u2019",
                "Who is there among you who will give ear to this? Who will listen and hear for the time to come?",
                "Who gave Jacob as plunder, and Israel to the robbers? Didn\u2019t Yahweh, he against whom we have sinned? For they would not walk in his ways, and they disobeyed his law.",
                "Therefore he poured the fierceness of his anger on him, and the strength of battle; and it set him on fire all around, but he didn\u2019t know; and it burned him, but he didn\u2019t take it to heart.\""
            ],
            43: [
                "But now thus says Yahweh who created you, Jacob, and he who formed you, Israel: \"Don\u2019t be afraid, for I have redeemed you. I have called you by your name. You are mine.",
                "When you pass through the waters, I will be with you; and through the rivers, they will not overflow you. When you walk through the fire, you will not be burned, and flame will not scorch you.",
                "For I am Yahweh your God, the Holy One of Israel, your Savior. I have given Egypt as your ransom, Ethiopia and Seba in your place.",
                "Since you have been precious and honored in my sight, and I have loved you; therefore I will give people in your place, and nations instead of your life.",
                "Don\u2019t be afraid; for I am with you. I will bring your seed from the east, and gather you from the west.",
                "I will tell the north, \u2018Give them up!\u2019 and tell the south, \u2018Don\u2019t hold them back! Bring my sons from far, and my daughters from the ends of the earth\u2014",
                "everyone who is called by my name, and whom I have created for my glory, whom I have formed, yes, whom I have made.\u2019\"",
                "Bring out the blind people who have eyes, and the deaf who have ears.",
                "Let all the nations be gathered together, and let the peoples be assembled. Who among them can declare this, and show us former things? Let them bring their witnesses, that they may be justified; or let them hear, and say, \"That is true.\"",
                "\"You are my witnesses,\" says Yahweh, \"With my servant whom I have chosen; that you may know and believe me, and understand that I am he. Before me there was no God formed, neither will there be after me.",
                "I myself am Yahweh; and besides me there is no savior.",
                "I have declared, I have saved, and I have shown; and there was no strange god among you. Therefore you are my witnesses,\" says Yahweh, \"and I am God.",
                "Yes, since the day was I am he; and there is no one who can deliver out of my hand. I will work, and who can hinder it?\"",
                "Thus says Yahweh, your Redeemer, the Holy One of Israel: \"For your sake, I have sent to Babylon, and I will bring all of them down as fugitives, even the Chaldeans, in the ships of their rejoicing.",
                "I am Yahweh, your Holy One, the Creator of Israel, your King.\"",
                "Thus says Yahweh, who makes a way in the sea, and a path in the mighty waters;",
                "who brings forth the chariot and horse, the army and the mighty man (they lie down together, they shall not rise; they are extinct, they are quenched like a wick):",
                "\"Don\u2019t remember the former things, and don\u2019t consider the things of old.",
                "Behold, I will do a new thing. It springs forth now. Don\u2019t you know it? I will even make a way in the wilderness, and rivers in the desert.",
                "The animals of the field shall honor me, the jackals and the ostriches; because I give water in the wilderness and rivers in the desert, to give drink to my people, my chosen,",
                "the people which I formed for myself, that they might set forth my praise.",
                "Yet you have not called on me, Jacob; but you have been weary of me, Israel.",
                "You have not brought me of your sheep for burnt offerings; neither have you honored me with your sacrifices. I have not burdened you with offerings, nor wearied you with frankincense.",
                "You have bought me no sweet cane with money, nor have you filled me with the fat of your sacrifices; but you have burdened me with your sins. You have wearied me with your iniquities.",
                "I, even I, am he who blots out your transgressions for my own sake; and I will not remember your sins.",
                "Put me in remembrance. Let us plead together. Set forth your case, that you may be justified.",
                "Your first father sinned, and your teachers have transgressed against me.",
                "Therefore I will profane the princes of the sanctuary; and I will make Jacob a curse, and Israel an insult.\""
            ],
            44: [
                "Yet listen now, Jacob my servant, and Israel, whom I have chosen.",
                "This is what Yahweh who made you, and formed you from the womb, who will help you says: \"Don\u2019t be afraid, Jacob my servant; and you, Jeshurun, whom I have chosen.",
                "For I will pour water on him who is thirsty, and streams on the dry ground. I will pour my Spirit on your seed, and my blessing on your offspring:",
                "and they will spring up among the grass, as willows by the watercourses.",
                "One will say, \u2018I am Yahweh\u2019s;\u2019 and another will be called by the name of Jacob; and another will write with his hand \u2018to Yahweh,\u2019 and honor the name of Israel.\"",
                "This is what Yahweh, the King of Israel, and his Redeemer, Yahweh of Armies, says: \"I am the first, and I am the last; and besides me there is no God.",
                "Who is like me? Who will call, and will declare it, and set it in order for me, since I established the ancient people? Let them declare the things that are coming, and that will happen.",
                "Don\u2019t fear, neither be afraid. Haven\u2019t I declared it to you long ago, and shown it? You are my witnesses. Is there a God besides me? Indeed, there is not. I don\u2019t know any other Rock.\"",
                "Everyone who makes an engraved image is vain. The things that they delight in will not profit. Their own witnesses don\u2019t see, nor know, that they may be disappointed.",
                "Who has fashioned a god, or molds an image that is profitable for nothing?",
                "Behold, all his fellows will be disappointed; and the workmen are mere men. Let them all be gathered together. Let them stand up. They will fear. They will be put to shame together.",
                "The blacksmith takes an axe, works in the coals, fashions it with hammers, and works it with his strong arm. He is hungry, and his strength fails; he drinks no water, and is faint.",
                "The carpenter stretches out a line. He marks it out with a pencil. He shapes it with planes. He marks it out with compasses, and shapes it like the figure of a man, with the beauty of a man, to reside in a house.",
                "He cuts down cedars for himself, and takes the cypress and the oak, and strengthens for himself one among the trees of the forest. He plants a fir tree, and the rain nourishes it.",
                "Then it will be for a man to burn; and he takes some of it, and warms himself. Yes, he burns it, and bakes bread. Yes, he makes a god, and worships it; he makes it an engraved image, and falls down to it.",
                "He burns part of it in the fire. With part of it, he eats meat. He roasts a roast, and is satisfied. Yes, he warms himself, and says, \"Aha! I am warm. I have seen the fire.\"",
                "The rest of it he makes into a god, even his engraved image. He bows down to it and worships, and prays to it, and says, \"Deliver me; for you are my god!\"",
                "They don\u2019t know, neither do they consider: for he has shut their eyes, that they can\u2019t see; and their hearts, that they can\u2019t understand.",
                "No one thinks, neither is there knowledge nor understanding to say, \"I have burned part of it in the fire. Yes, I have also baked bread on its coals. I have roasted meat and eaten it. Shall I make the rest of it into an abomination? Shall I bow down to a tree trunk?\"",
                "He feeds on ashes. A deceived heart has turned him aside; and he can\u2019t deliver his soul, nor say, \"Isn\u2019t there a lie in my right hand?\"",
                "Remember these things, Jacob and Israel; for you are my servant. I have formed you. You are my servant. Israel, you will not be forgotten by me.",
                "I have blotted out, as a thick cloud, your transgressions, and, as a cloud, your sins. Return to me, for I have redeemed you.",
                "Sing, you heavens, for Yahweh has done it! Shout, you lower parts of the earth! Break out into singing, you mountains, O forest, all of your trees, for Yahweh has redeemed Jacob, and will glorify himself in Israel.",
                "Thus says Yahweh, your Redeemer, and he who formed you from the womb: \"I am Yahweh, who makes all things; who alone stretches out the heavens; who spreads out the earth by myself;",
                "who frustrates the signs of the liars, and makes diviners mad; who turns wise men backward, and makes their knowledge foolish;",
                "who confirms the word of his servant, and performs the counsel of his messengers; who says of Jerusalem, \u2018She will be inhabited;\u2019 and of the cities of Judah, \u2018They will be built,\u2019 and \u2018I will raise up its waste places;\u2019",
                "who says to the deep, \u2018Be dry,\u2019 and \u2018I will dry up your rivers;\u2019",
                "Who says of Cyrus, \u2018He is my shepherd, and shall perform all my pleasure,\u2019 even saying of Jerusalem, \u2018She will be built;\u2019 and of the temple, \u2018Your foundation will be laid.\u2019\""
            ],
            45: [
                "Thus says Yahweh to his anointed, to Cyrus, whose right hand I have held, to subdue nations before him, and strip kings of their armor; to open the doors before him, and the gates shall not be shut:",
                "\"I will go before you, and make the rough places smooth. I will break the doors of brass in pieces, and cut apart the bars of iron.",
                "I will give you the treasures of darkness, and hidden riches of secret places, that you may know that it is I, Yahweh, who call you by your name, even the God of Israel.",
                "For Jacob my servant\u2019s sake, and Israel my chosen, I have called you by your name. I have surnamed you, though you have not known me.",
                "I am Yahweh, and there is none else. Besides me, there is no God. I will strengthen you, though you have not known me;",
                "that they may know from the rising of the sun, and from the west, that there is none besides me. I am Yahweh, and there is no one else.",
                "I form the light, and create darkness. I make peace, and create calamity. I am Yahweh, who does all these things.",
                "Distil, you heavens, from above, and let the skies pour down righteousness. Let the earth open, that it may bring forth salvation, and let it cause righteousness to spring up with it. I, Yahweh, have created it.",
                "Woe to him who strives with his Maker\u2014a clay pot among the clay pots of the earth! Shall the clay ask him who fashions it, \u2018What are you making?\u2019 or your work, \u2018He has no hands?\u2019",
                "Woe to him who says to a father, \u2018What have you become the father of?\u2019 or to a mother, \u2018To what have you given birth?\u2019\"",
                "Thus says Yahweh, the Holy One of Israel, and his Maker: \"You ask me about the things that are to come, concerning my sons, and you command me concerning the work of my hands!",
                "I have made the earth, and created man on it. I, even my hands, have stretched out the heavens; and I have commanded all their army.",
                "I have raised him up in righteousness, and I will make straight all his ways. He shall build my city, and he shall let my exiles go free, not for price nor reward,\" says Yahweh of Armies.",
                "Thus says Yahweh: \"The labor of Egypt, and the merchandise of Ethiopia, and the Sabeans, men of stature, shall come over to you, and they shall be yours. They will go after you. They shall come over in chains; and they will bow down to you. They will make supplication to you: \u2018Surely God is in you; and there is none else. There is no other god.",
                "Most certainly you are a God who hidden yourself, God of Israel, the Savior.\u2019\"",
                "They will be disappointed, yes, confounded, all of them. Those who are makers of idols will go into confusion together.",
                "Israel will be saved by Yahweh with an everlasting salvation. You will not be disappointed nor confounded to ages everlasting.",
                "For thus says Yahweh who created the heavens, the God who formed the earth and made it, who established it and didn\u2019t create it a waste, who formed it to be inhabited: \"I am Yahweh; and there is no other.",
                "I have not spoken in secret, in a place of the land of darkness. I didn\u2019t say to the seed of Jacob, \u2018Seek me in vain.\u2019 I, Yahweh, speak righteousness. I declare things that are right.",
                "\"Assemble yourselves and come. Draw near together, you who have escaped from the nations. Those have no knowledge who carry the wood of their engraved image, and pray to a god that can\u2019t save.",
                "Declare and present it. Yes, let them take counsel together. Who has shown this from ancient time? Who has declared it of old? Haven\u2019t I, Yahweh? There is no other God besides me, a just God and a Savior; There is no one besides me.",
                "\"Look to me, and be saved, all the ends of the earth; for I am God, and there is no other.",
                "I have sworn by myself, the word has gone out of my mouth in righteousness, and will not return, that to me every knee shall bow, every tongue shall take an oath.",
                "They will say of me, \u2018There is righteousness and strength only in Yahweh.\u2019\" Even to him shall men come; and all those who were incensed against him shall be disappointed.",
                "In Yahweh shall all the seed of Israel be justified, and shall glory."
            ],
            46: [
                "Bel bows down, Nebo stoops; their idols are on the animals, and on the livestock: the things that you carried about are made a load, a burden to the weary.",
                "They stoop, they bow down together; they could not deliver the burden, but themselves have gone into captivity.",
                "\"Listen to me, house of Jacob, and all the remnant of the house of Israel, that have been borne from their birth, that have been carried from the womb;",
                "and even to old age I am he, and even to gray hairs will I carry you. I have made, and I will bear; yes, I will carry, and will deliver.",
                "\"To whom will you liken me, and make me equal, and compare me, that we may be like?",
                "Some pour out gold from the bag, and weigh silver in the balance. They hire a goldsmith, and he makes it a god. They fall down\u2014yes, they worship.",
                "They bear it on the shoulder, they carry it, and set it in its place, and it stands, from its place it shall not move: yes, one may cry to it, yet it can not answer, nor save him out of his trouble.",
                "\"Remember this, and show yourselves men; bring it again to mind, you transgressors.",
                "Remember the former things of old: for I am God, and there is none else; I am God, and there is none like me;",
                "declaring the end from the beginning, and from ancient times things that are not yet done; saying, My counsel shall stand, and I will do all my pleasure;",
                "calling a ravenous bird from the east, the man of my counsel from a far country; yes, I have spoken, I will also bring it to pass; I have purposed, I will also do it.",
                "Listen to me, you stout-hearted, who are far from righteousness:",
                "I bring near my righteousness, it shall not be far off, and my salvation shall not wait; and I will place salvation in Zion for Israel my glory."
            ],
            47: [
                "\"Come down, and sit in the dust, virgin daughter of Babylon; sit on the ground without a throne, daughter of the Chaldeans: for you shall no more be called tender and delicate.",
                "Take the millstones, and grind meal; remove your veil, strip off the train, uncover the leg, pass through the rivers.",
                "Your nakedness shall be uncovered, yes, your shame shall be seen: I will take vengeance, and will spare no man.\"",
                "Our Redeemer, Yahweh of Armies is his name, the Holy One of Israel.",
                "\"Sit in silence, and go into darkness, daughter of the Chaldeans; for you shall no more be called the mistress of kingdoms.",
                "I was angry with my people, I profaned my inheritance, and gave them into your hand: you showed them no mercy; on the aged you have very heavily laid your yoke.",
                "You said, \u2018I shall be a mistress forever;\u2019 so that you did not lay these things to your heart, nor did you remember the latter end of it.",
                "\"Now therefore hear this, you who are given to pleasures, who sit securely, who say in your heart, \u2018I am, and there is none else besides me; I shall not sit as a widow, neither shall I know the loss of children:\u2019",
                "but these two things shall come to you in a moment in one day, the loss of children, and widowhood; in their full measure shall they come on you, in the multitude of your sorceries, and the great abundance of your enchantments.",
                "For you have trusted in your wickedness; you have said, No one sees me; your wisdom and your knowledge, it has perverted you, and you have said in your heart, I am, and there is none else besides me.",
                "Therefore evil will come on you; you won\u2019t know when it dawns: and mischief will fall on you; you will not be able to put it away: and desolation shall come on you suddenly, which you don\u2019t know.",
                "\"Stand now with your enchantments, and with the multitude of your sorceries, in which you have labored from your youth; if so be you shall be able to profit, if so be you may prevail.",
                "You are wearied in the multitude of your counsels: let now the astrologers, the stargazers, the monthly prognosticators, stand up, and save you from the things that shall come on you.",
                "Behold, they shall be as stubble; the fire shall burn them; they shall not deliver themselves from the power of the flame: it shall not be a coal to warm at, nor a fire to sit before.",
                "Thus shall the things be to you in which you have labored: those who have trafficked with you from your youth shall wander everyone to his quarter; there shall be none to save you."
            ],
            48: [
                "\"Hear this, house of Jacob, you who are called by the name of Israel, and have come forth out of the waters of Judah; who swear by the name of Yahweh, and make mention of the God of Israel, but not in truth, nor in righteousness",
                "(for they call themselves of the holy city, and stay themselves on the God of Israel; Yahweh of Armies is his name):",
                "I have declared the former things from of old; yes, they went forth out of my mouth, and I showed them: suddenly I did them, and they happened.",
                "Because I knew that you are obstinate, and your neck is an iron sinew, and your brow brass;",
                "therefore I have declared it to you from of old; before it came to pass I showed it to you; lest you should say, \u2018My idol has done them, and my engraved image, and my molten image, has commanded them.\u2019",
                "You have heard it; see all this; and you, will you not declare it? \"I have shown you new things from this time, even hidden things, which you have not known.",
                "They are created now, and not from of old; and before this day you didn\u2019t hear them; lest you should say, \u2018Behold, I knew them.\u2019",
                "Yes, you didn\u2019t hear; yes, you didn\u2019t know; yes, from of old your ear was not opened: for I knew that you dealt very treacherously, and was called a transgressor from the womb.",
                "For my name\u2019s sake will I defer my anger, and for my praise will I refrain for you, that I not cut you off.",
                "Behold, I have refined you, but not as silver; I have chosen you in the furnace of affliction.",
                "For my own sake, for my own sake, will I do it; for how should my name be profaned? I will not give my glory to another.",
                "\"Listen to me, O Jacob, and Israel my called: I am he; I am the first, I also am the last.",
                "Yes, my hand has laid the foundation of the earth, and my right hand has spread out the heavens: when I call to them, they stand up together.",
                "\"Assemble yourselves, all you, and hear; who among them has declared these things? He whom Yahweh loves shall perform his pleasure on Babylon, and his arm shall be on the Chaldeans.",
                "I, even I, have spoken; yes, I have called him; I have brought him, and he shall make his way prosperous.",
                "\"Come near to me and hear this: \"From the beginning I have not spoken in secret; from the time that it was, there am I.\" Now the Lord Yahweh has sent me, with his Spirit.",
                "Thus says Yahweh, your Redeemer, the Holy One of Israel: I am Yahweh your God, who teaches you to profit, who leads you by the way that you should go.",
                "Oh that you had listened to my commandments! then your peace would have been like a river, and your righteousness like the waves of the sea:",
                "your seed also had been as the sand, and the offspring of your body like its grains: his name would not be cut off nor destroyed from before me.",
                "Go forth from Babylon, flee from the Chaldeans; with a voice of singing declare, tell this, utter it even to the end of the earth: say, Yahweh has redeemed his servant Jacob.",
                "They didn\u2019t thirst when he led them through the deserts; he caused the waters to flow out of the rock for them; he split the rock also, and the waters gushed out.",
                "\"There is no peace,\" says Yahweh, \"for the wicked.\""
            ],
            49: [
                "Listen, islands, to me; and listen, you peoples, from far: Yahweh has called me from the womb; from the bowels of my mother has he made mention of my name:",
                "and he has made my mouth like a sharp sword; in the shadow of his hand, he has hidden me: and he has made me a polished shaft; in his quiver has he kept me close:",
                "and he said to me, \"You are my servant; Israel, in whom I will be glorified.\"",
                "But I said, \"I have labored in vain, I have spent my strength for nothing and vanity; yet surely the justice due to me is with Yahweh, and my reward with my God.\"",
                "Now says Yahweh who formed me from the womb to be his servant, to bring Jacob again to him, and that Israel be gathered to him (for I am honorable in the eyes of Yahweh, and my God has become my strength);",
                "yes, he says, \"It is too light a thing that you should be my servant to raise up the tribes of Jacob, and to restore the preserved of Israel: I will also give you for a light to the nations, that you may be my salvation to the end of the earth.\"",
                "Thus says Yahweh, the Redeemer of Israel, and his Holy One, to him whom man despises, to him whom the nation abhors, to a servant of rulers: \"Kings shall see and arise; princes, and they shall worship; because of Yahweh who is faithful, even the Holy One of Israel, who has chosen you.\"",
                "Thus says Yahweh, \"In an acceptable time have I answered you, and in a day of salvation have I helped you; and I will preserve you, and give you for a covenant of the people, to raise up the land, to make them inherit the desolate heritage:",
                "saying to those who are bound, \u2018Come out!\u2019; to those who are in darkness, \u2018Show yourselves!\u2019 \"They shall feed in the ways, and on all bare heights shall be their pasture.",
                "They shall not hunger nor thirst; neither shall the heat nor sun strike them: for he who has mercy on them will lead them, even by springs of water he will guide them.",
                "I will make all my mountains a way, and my highways shall be exalted.",
                "Behold, these shall come from far; and behold, these from the north and from the west; and these from the land of Sinim.\"",
                "Sing, heavens; and be joyful, earth; and break forth into singing, mountains: for Yahweh has comforted his people, and will have compassion on his afflicted.",
                "But Zion said, \"Yahweh has forsaken me, and the Lord has forgotten me.\"",
                "\"Can a woman forget her nursing child, that she should not have compassion on the son of her womb? Yes, these may forget, yet I will not forget you!",
                "Behold, I have engraved you on the palms of my hands; your walls are continually before me.",
                "Your children make haste; your destroyers and those who made you waste shall go forth from you.",
                "Lift up your eyes all around, and see: all these gather themselves together, and come to you. As I live,\" says Yahweh, \"you shall surely clothe yourself with them all as with an ornament, and dress yourself with them, like a bride.",
                "\"For, as for your waste and your desolate places, and your land that has been destroyed, surely now you shall be too small for the inhabitants, and those who swallowed you up shall be far away.",
                "The children of your bereavement shall yet say in your ears, The place is too small for me; give place to me that I may dwell.",
                "Then you will say in your heart, \u2018Who has conceived these for me, since I have been bereaved of my children, and am solitary, an exile, and wandering back and forth? Who has brought up these? Behold, I was left alone; these, where were they?\u2019\"",
                "Thus says the Lord Yahweh, \"Behold, I will lift up my hand to the nations, and set up my banner to the peoples; and they shall bring your sons in their bosom, and your daughters shall be carried on their shoulders.",
                "Kings shall be your nursing fathers, and their queens your nursing mothers: they shall bow down to you with their faces to the earth, and lick the dust of your feet; and you shall know that I am Yahweh; and those who wait for me shall not be disappointed.\"",
                "Shall the prey be taken from the mighty, or the lawful captives be delivered?",
                "But thus says Yahweh, \"Even the captives of the mighty shall be taken away, and the prey of the terrible shall be delivered; for I will contend with him who contends with you, and I will save your children.",
                "I will feed those who oppress you with their own flesh; and they shall be drunken with their own blood, as with sweet wine: and all flesh shall know that I, Yahweh, am your Savior, and your Redeemer, the Mighty One of Jacob.\""
            ],
            50: [
                "Thus says Yahweh, \"Where is the bill of your mother\u2019s divorce, with which I have put her away? or which of my creditors is it to whom I have sold you? Behold, for your iniquities were you sold, and for your transgressions was your mother put away.",
                "Why, when I came, was there no man? when I called, was there none to answer? Is my hand shortened at all, that it can\u2019t redeem? or have I no power to deliver? Behold, at my rebuke I dry up the sea, I make the rivers a wilderness: their fish stink, because there is no water, and die for thirst.",
                "I clothe the heavens with blackness, and I make sackcloth their covering.\"",
                "The Lord Yahweh has given me the tongue of those who are taught, that I may know how to sustain with words him who is weary: he wakens morning by morning, he wakens my ear to hear as those who are taught.",
                "The Lord Yahweh has opened my ear, and I was not rebellious, neither turned away backward.",
                "I gave my back to the strikers, and my cheeks to those who plucked off the hair; I didn\u2019t hide my face from shame and spitting.",
                "For the Lord Yahweh will help me; therefore I have not been confounded: therefore have I set my face like a flint, and I know that I shall not be disappointed.",
                "He is near who justifies me; who will bring charges against me? Let us stand up together: who is my adversary? Let him come near to me.",
                "Behold, the Lord Yahweh will help me; who is he who shall condemn me? Behold, all they shall wax old as a garment, the moth shall eat them up.",
                "Who is among you who fears Yahweh, who obeys the voice of his servant? He who walks in darkness, and has no light, let him trust in the name of Yahweh, and rely on his God.",
                "Behold, all you who kindle a fire, who adorn yourselves with torches around yourselves; walk in the flame of your fire, and among the brands that you have kindled. You shall have this of my hand; you shall lie down in sorrow."
            ],
            51: [
                "\"Listen to me, you who follow after righteousness, you who seek Yahweh: look to the rock you were cut from, and to the hold of the pit you were dug from.",
                "Look to Abraham your father, and to Sarah who bore you; for when he was but one I called him, and I blessed him, and made him many.",
                "For Yahweh has comforted Zion; he has comforted all her waste places, and has made her wilderness like Eden, and her desert like the garden of Yahweh; joy and gladness shall be found therein, thanksgiving, and the voice of melody.",
                "\"Attend to me, my people; and give ear to me, my nation: for a law shall go forth from me, and I will establish my justice for a light of the peoples.",
                "My righteousness is near, my salvation is gone forth, and my arms shall judge the peoples; the islands shall wait for me, and on my arm shall they trust.",
                "Lift up your eyes to the heavens, and look on the earth beneath; for the heavens shall vanish away like smoke, and the earth shall wax old like a garment; and those who dwell therein shall die in the same way: but my salvation shall be forever, and my righteousness shall not be abolished.",
                "\"Listen to me, you who know righteousness, the people in whose heart is my law; don\u2019t fear the reproach of men, neither be dismayed at their insults.",
                "For the moth shall eat them up like a garment, and the worm shall eat them like wool; but my righteousness shall be forever, and my salvation to all generations.\"",
                "Awake, awake, put on strength, arm of Yahweh; awake, as in the days of old, the generations of ancient times. Isn\u2019t it you who cut Rahab in pieces, who pierced the monster?",
                "Isn\u2019t it you who dried up the sea, the waters of the great deep; who made the depths of the sea a way for the redeemed to pass over?",
                "The ransomed of Yahweh shall return, and come with singing to Zion; and everlasting joy shall be on their heads. They shall obtain gladness and joy. Sorrow and sighing shall flee away.",
                "\"I, even I, am he who comforts you: who are you, that you are afraid of man who shall die, and of the son of man who shall be made as grass;",
                "and have forgotten Yahweh your Maker, who stretched forth the heavens, and laid the foundations of the earth; and fear continually all the day because of the fury of the oppressor, when he makes ready to destroy? and where is the fury of the oppressor?",
                "The captive exile shall speedily be freed; and he shall not die and go down into the pit, neither shall his bread fail.",
                "For I am Yahweh your God, who stirs up the sea, so that its waves roar: Yahweh of Armies is his name.",
                "I have put my words in your mouth, and have covered you in the shadow of my hand, that I may plant the heavens, and lay the foundations of the earth, and tell Zion, \u2018You are my people.\u2019\"",
                "Awake, awake, stand up, Jerusalem, that have drunk at the hand of Yahweh the cup of his wrath; you have drunken the bowl of the cup of staggering, and drained it.",
                "There is none to guide her among all the sons whom she has brought forth; neither is there any who takes her by the hand among all the sons who she has brought up.",
                "These two things have happened to you. Who will bemoan you? Desolation and destruction, and the famine and the sword; how shall I comfort you?",
                "Your sons have fainted, they lie at the head of all the streets, as an antelope in a net; they are full of the wrath of Yahweh, the rebuke of your God.",
                "Therefore hear now this, you afflicted, and drunken, but not with wine:",
                "Thus says your Lord Yahweh, and your God who pleads the cause of his people, \"Behold, I have taken out of your hand the cup of staggering, even the bowl of the cup of my wrath; you shall no more drink it again:",
                "and I will put it into the hand of those who afflict you, who have said to your soul, \u2018Bow down, that we may walk over you;\u2019 and you have laid your back as the ground, and as the street, to those who walk over.\""
            ],
            52: [
                "Awake, awake, put on your strength, Zion; put on your beautiful garments, Jerusalem, the holy city: for henceforth there shall no more come into you the uncircumcised and the unclean.",
                "Shake yourself from the dust! Arise, sit up, Jerusalem! Release yourself from the bonds of your neck, captive daughter of Zion!",
                "For thus says Yahweh, \"You were sold for nothing; and you shall be redeemed without money.\"",
                "For thus says the Lord Yahweh, \"My people went down at the first into Egypt to live there: and the Assyrian has oppressed them without cause.",
                "\"Now therefore, what do I do here,\" says Yahweh, \"seeing that my people are taken away for nothing? Those who rule over them mock,\" says Yahweh, \"and my name continually all the day is blasphemed.",
                "Therefore my people shall know my name. Therefore they shall know in that day that I am he who speaks; behold, it is I.\"",
                "How beautiful on the mountains are the feet of him who brings good news, who publishes peace, who brings good news of good, who publishes salvation, who says to Zion, \"Your God reigns!\"",
                "The voice of your watchmen! they lift up the voice, together do they sing; for they shall see eye to eye, when Yahweh returns to Zion.",
                "Break forth into joy, sing together, you waste places of Jerusalem; for Yahweh has comforted his people, he has redeemed Jerusalem.",
                "Yahweh has made bare his holy arm in the eyes of all the nations; and all the ends of the earth have seen the salvation of our God.",
                "Depart, depart, go out from there, touch no unclean thing! Go out of the midst of her! Cleanse yourselves, you who bear the vessels of Yahweh.",
                "For you shall not go out in haste, neither shall you go by flight: for Yahweh will go before you; and the God of Israel will be your rear guard.",
                "Behold, my servant shall deal wisely, he shall be exalted and lifted up, and shall be very high.",
                "Like as many were astonished at you (his appearance was marred more than any man, and his form more than the sons of men),",
                "so shall he sprinkle many nations; kings shall shut their mouths at him: for that which had not been told them shall they see; and that which they had not heard shall they understand."
            ],
            53: [
                "Who has believed our message? To whom has the arm of Yahweh been revealed?",
                "For he grew up before him as a tender plant, and as a root out of dry ground. He has no good looks or majesty. When we see him, there is no beauty that we should desire him.",
                "He was despised, and rejected by men; a man of suffering, and acquainted with disease. He was despised as one from whom men hide their face; and we didn\u2019t respect him.",
                "Surely he has borne our sickness, and carried our suffering; yet we considered him plagued, struck by God, and afflicted.",
                "But he was pierced for our transgressions. He was crushed for our iniquities. The punishment that brought our peace was on him; and by his wounds we are healed.",
                "All we like sheep have gone astray. Everyone has turned to his own way; and Yahweh has laid on him the iniquity of us all.",
                "He was oppressed, yet when he was afflicted he didn\u2019t open his mouth. As a lamb that is led to the slaughter, and as a sheep that before its shearers is mute, so he didn\u2019t open his mouth.",
                "He was taken away by oppression and judgment; and as for his generation, who considered that he was cut off out of the land of the living and stricken for the disobedience of my people?",
                "They made his grave with the wicked, and with a rich man in his death; although he had done no violence, neither was any deceit in his mouth.",
                "Yet it pleased Yahweh to bruise him. He has caused him to suffer. When you make his soul an offering for sin, he shall see his seed. He shall prolong his days, and the pleasure of Yahweh shall prosper in his hand.",
                "After the suffering of his soul, he will see the light and be satisfied. My righteous servant will justify many by the knowledge of himself; and he will bear their iniquities.",
                "Therefore will I divide him a portion with the great, and he shall divide the spoil with the strong; because he poured out his soul to death, and was numbered with the transgressors; yet he bore the sin of many, and made intercession for the transgressors."
            ],
            54: [
                "\"Sing, barren, you who didn\u2019t bear; break forth into singing, and cry aloud, you who did not travail with child: for more are the children of the desolate than the children of the married wife,\" says Yahweh.",
                "\"Enlarge the place of your tent, and let them stretch forth the curtains of your habitations; don\u2019t spare: lengthen your cords, and strengthen your stakes.",
                "For you shall spread out on the right hand and on the left; and your seed shall possess the nations, and make the desolate cities to be inhabited.",
                "\"Don\u2019t be afraid; for you shall not be ashamed: neither be confounded; for you shall not be disappointed: for you shall forget the shame of your youth; and the reproach of your widowhood you shall remember no more.",
                "For your Maker is your husband; Yahweh of Armies is his name: and the Holy One of Israel is your Redeemer; the God of the whole earth shall he be called.",
                "For Yahweh has called you as a wife forsaken and grieved in spirit, even a wife of youth, when she is cast off,\" says your God.",
                "\"For a small moment have I forsaken you; but with great mercies will I gather you.",
                "In overflowing wrath I hid my face from you for a moment; but with everlasting loving kindness will I have mercy on you,\" says Yahweh your Redeemer.",
                "\"For this is like the waters of Noah to me; for as I have sworn that the waters of Noah shall no more go over the earth, so have I sworn that I will not be angry with you, nor rebuke you.",
                "For the mountains may depart, and the hills be removed; but my loving kindness shall not depart from you, neither shall my covenant of peace be removed,\" says Yahweh who has mercy on you.",
                "\"You afflicted, tossed with storms, and not comforted, behold, I will set your stones in beautiful colors, and lay your foundations with sapphires.",
                "I will make your pinnacles of rubies, and your gates of sparkling jewels, and all your walls of precious stones.",
                "All your children shall be taught of Yahweh; and great shall be the peace of your children.",
                "In righteousness you shall be established: you shall be far from oppression, for you shall not be afraid; and from terror, for it shall not come near you.",
                "Behold, they may gather together, but not by me: whoever shall gather together against you shall fall because of you.",
                "\"Behold, I have created the smith who blows the fire of coals, and brings forth a weapon for his work; and I have created the waster to destroy.",
                "No weapon that is formed against you will prevail; and you will condemn every tongue that rises against you in judgment. This is the heritage of the servants of Yahweh, and their righteousness which is of me,\" says Yahweh."
            ],
            55: [
                "\"Come, everyone who thirsts, to the waters! Come, he who has no money, buy, and eat! Yes, come, buy wine and milk without money and without price.",
                "Why do you spend money for that which is not bread? and your labor for that which doesn\u2019t satisfy? listen diligently to me, and eat you that which is good, and let your soul delight itself in fatness.",
                "Turn your ear, and come to me; hear, and your soul shall live: and I will make an everlasting covenant with you, even the sure mercies of David.",
                "Behold, I have given him for a witness to the peoples, a leader and commander to the peoples.",
                "Behold, you shall call a nation that you don\u2019t know; and a nation that didn\u2019t know you shall run to you, because of Yahweh your God, and for the Holy One of Israel; for he has glorified you.\"",
                "Seek Yahweh while he may be found; call you on him while he is near:",
                "let the wicked forsake his way, and the unrighteous man his thoughts; and let him return to Yahweh, and he will have mercy on him; and to our God, for he will abundantly pardon.",
                "\"For my thoughts are not your thoughts, neither are your ways my ways,\" says Yahweh.",
                "\"For as the heavens are higher than the earth, so are my ways higher than your ways, and my thoughts than your thoughts.",
                "For as the rain comes down and the snow from the sky, and doesn\u2019t return there, but waters the earth, and makes it bring forth and bud, and gives seed to the sower and bread to the eater;",
                "so shall my word be that goes forth out of my mouth: it shall not return to me void, but it shall accomplish that which I please, and it shall prosper in the thing I sent it to do.",
                "For you shall go out with joy, and be led forth with peace: the mountains and the hills shall break forth before you into singing; and all the trees of the fields shall clap their hands.",
                "Instead of the thorn shall come up the fir tree; and instead of the brier shall come up the myrtle tree: and it shall be to Yahweh for a name, for an everlasting sign that shall not be cut off.\""
            ],
            56: [
                "Thus says Yahweh, \"Keep justice, and do righteousness; for my salvation is near to come, and my righteousness to be revealed.",
                "Blessed is the man who does this, and the son of man who holds it fast; who keeps the Sabbath from profaning it, and keeps his hand from doing any evil.\"",
                "Neither let the foreigner, who has joined himself to Yahweh, speak, saying, \"Yahweh will surely separate me from his people;\" neither let the eunuch say, \"Behold, I am a dry tree.\"",
                "For thus says Yahweh, \"To the eunuchs who keep my Sabbaths, and choose the things that please me, and hold fast my covenant:",
                "to them I will give in my house and within my walls a memorial and a name better than of sons and of daughters; I will give them an everlasting name, that shall not be cut off.",
                "Also the foreigners who join themselves to Yahweh, to minister to him, and to love the name of Yahweh, to be his servants, everyone who keeps the Sabbath from profaning it, and holds fast my covenant;",
                "even them will I bring to my holy mountain, and make them joyful in my house of prayer: their burnt offerings and their sacrifices shall be accepted on my altar; for my house shall be called a house of prayer for all peoples.\"",
                "The Lord Yahweh, who gathers the outcasts of Israel, says, \"Yet will I gather others to him, besides his own who are gathered.\"",
                "All you animals of the field, come to devour, all you animals in the forest.",
                "His watchmen are blind, they are all without knowledge; they are all mute dogs, they can\u2019t bark; dreaming, lying down, loving to slumber.",
                "Yes, the dogs are greedy, they can never have enough; and these are shepherds who can\u2019t understand: they have all turned to their own way, each one to his gain, from every quarter.",
                "\"Come,\" say they, \"I will get wine, and we will fill ourselves with strong drink; and tomorrow shall be as this day, great beyond measure.\""
            ],
            57: [
                "The righteous perishes, and no man lays it to heart; and merciful men are taken away, none considering that the righteous is taken away from the evil.",
                "He enters into peace; they rest in their beds, each one who walks in his uprightness.",
                "\"But draw near here, you sons of the sorceress, the seed of the adulterer and the prostitute.",
                "Against whom do you sport yourselves? Against whom do you make a wide mouth, and stick out your tongue? Aren\u2019t you children of disobedience, a seed of falsehood,",
                "you who inflame yourselves among the oaks, under every green tree; who kill the children in the valleys, under the clefts of the rocks?",
                "Among the smooth stones of the valley is your portion; they, they are your lot; you have even poured a drink offering to them. You have offered an offering. Shall I be appeased for these things?",
                "On a high and lofty mountain you have set your bed; there also you went up to offer sacrifice.",
                "Behind the doors and the posts you have set up your memorial: for you have uncovered to someone besides me, and have gone up; you have enlarged your bed, and made you a covenant with them: you loved their bed where you saw it.",
                "You went to the king with oil, and increased your perfumes, and sent your ambassadors far off, and debased yourself even to Sheol.",
                "You were wearied with the length of your way; yet you didn\u2019t say, \u2018It is in vain.\u2019 You found a reviving of your strength; therefore you weren\u2019t faint.",
                "\"Of whom have you been afraid and in fear, that you lie, and have not remembered me, nor laid it to your heart? Haven\u2019t I held my peace even of long time, and you don\u2019t fear me?",
                "I will declare your righteousness; and as for your works, they shall not profit you.",
                "When you cry, let those who you have gathered deliver you; but the wind shall take them, a breath shall carry them all away: but he who takes refuge in me shall possess the land, and shall inherit my holy mountain.\"",
                "He will say, \"Cast up, cast up, prepare the way, take up the stumbling-block out of the way of my people.\"",
                "For thus says the high and lofty One who inhabits eternity, whose name is Holy: \"I dwell in the high and holy place, with him also who is of a contrite and humble spirit, to revive the spirit of the humble, and to revive the heart of the contrite.",
                "For I will not contend forever, neither will I be always angry; for the spirit would faint before me, and the souls who I have made.",
                "For the iniquity of his covetousness was I angry, and struck him; I hid myself and was angry; and he went on backsliding in the way of his heart.",
                "I have seen his ways, and will heal him: I will lead him also, and restore comforts to him and to his mourners.",
                "I create the fruit of the lips: Peace, peace, to him who is far off and to him who is near,\" says Yahweh; \"and I will heal them.\"",
                "But the wicked are like the troubled sea; for it can\u2019t rest, and its waters cast up mire and dirt.",
                "\"There is no peace,\" says my God, \"for the wicked.\""
            ],
            58: [
                "\"Cry aloud, don\u2019t spare, lift up your voice like a trumpet, and declare to my people their disobedience, and to the house of Jacob their sins.",
                "Yet they seek me daily, and delight to know my ways: as a nation that did righteousness, and didn\u2019t forsake the ordinance of their God, they ask of me righteous judgments; they delight to draw near to God.",
                "\u2018Why have we fasted,\u2019 say they, \u2018and you don\u2019t see? Why have we afflicted our soul, and you take no knowledge?\u2019 \"Behold, in the day of your fast you find pleasure, and exact all your labors.",
                "Behold, you fast for strife and contention, and to strike with the fist of wickedness: you don\u2019t fast this day so as to make your voice to be heard on high.",
                "Is such the fast that I have chosen? the day for a man to afflict his soul? Is it to bow down his head as a rush, and to spread sackcloth and ashes under him? Will you call this a fast, and an acceptable day to Yahweh?",
                "\"Isn\u2019t this the fast that I have chosen: to release the bonds of wickedness, to undo the bands of the yoke, and to let the oppressed go free, and that you break every yoke?",
                "Isn\u2019t it to distribute your bread to the hungry, and that you bring the poor who are cast out to your house? When you see the naked, that you cover him; and that you not hide yourself from your own flesh?",
                "Then your light shall break forth as the morning, and your healing shall spring forth speedily; and your righteousness shall go before you; the glory of Yahweh shall be your rear guard.",
                "Then you shall call, and Yahweh will answer; you shall cry, and he will say, \u2018Here I am.\u2019 \"If you take away from the midst of you the yoke, the putting forth of the finger, and speaking wickedly;",
                "and if you draw out your soul to the hungry, and satisfy the afflicted soul: then your light shall rise in darkness, and your obscurity be as the noonday;",
                "and Yahweh will guide you continually, and satisfy your soul in dry places, and make strong your bones; and you shall be like a watered garden, and like a spring of water, whose waters don\u2019t fail.",
                "Those who shall be of you shall build the old waste places; you shall raise up the foundations of many generations; and you shall be called The repairer of the breach, The restorer of paths to dwell in.",
                "\"If you turn away your foot from the Sabbath, from doing your pleasure on my holy day; and call the Sabbath a delight, and the holy of Yahweh honorable; and shall honor it, not doing your own ways, nor finding your own pleasure, nor speaking your own words:",
                "then you shall delight yourself in Yahweh; and I will make you to ride on the high places of the earth; and I will feed you with the heritage of Jacob your father:\" for the mouth of Yahweh has spoken it."
            ],
            59: [
                "Behold, Yahweh\u2019s hand is not shortened, that it can\u2019t save; neither his ear heavy, that it can\u2019t hear:",
                "but your iniquities have separated between you and your God, and your sins have hidden his face from you, so that he will not hear.",
                "For your hands are defiled with blood, and your fingers with iniquity; your lips have spoken lies, your tongue mutters wickedness.",
                "None sues in righteousness, and none pleads in truth: they trust in vanity, and speak lies; they conceive mischief, and bring forth iniquity.",
                "They hatch adders\u2019 eggs, and weave the spider\u2019s web: he who eats of their eggs dies; and that which is crushed breaks out into a viper.",
                "Their webs shall not become garments, neither shall they cover themselves with their works: their works are works of iniquity, and the act of violence is in their hands.",
                "Their feet run to evil, and they make haste to shed innocent blood: their thoughts are thoughts of iniquity; desolation and destruction are in their paths.",
                "The way of peace they don\u2019t know; and there is no justice in their goings: they have made them crooked paths; whoever goes therein does not know peace.",
                "Therefore is justice far from us, neither does righteousness overtake us: we look for light, but, behold, darkness; for brightness, but we walk in obscurity.",
                "We grope for the wall like the blind; yes, we grope as those who have no eyes: we stumble at noonday as in the twilight; among those who are lusty we are as dead men.",
                "We roar all like bears, and moan bitterly like doves: we look for justice, but there is none; for salvation, but it is far off from us.",
                "For our transgressions are multiplied before you, and our sins testify against us; for our transgressions are with us, and as for our iniquities, we know them:",
                "transgressing and denying Yahweh, and turning away from following our God, speaking oppression and revolt, conceiving and uttering from the heart words of falsehood.",
                "Justice is turned away backward, and righteousness stands afar off; for truth is fallen in the street, and uprightness can\u2019t enter.",
                "Yes, truth is lacking; and he who departs from evil makes himself a prey. Yahweh saw it, and it displeased him that there was no justice.",
                "He saw that there was no man, and wondered that there was no intercessor: therefore his own arm brought salvation to him; and his righteousness, it upheld him.",
                "He put on righteousness as a breastplate, and a helmet of salvation on his head; and he put on garments of vengeance for clothing, and was clad with zeal as a mantle.",
                "According to their deeds, accordingly he will repay, wrath to his adversaries, recompense to his enemies; to the islands he will repay recompense.",
                "So shall they fear the name of Yahweh from the west, and his glory from the rising of the sun; for he will come as a rushing stream, which the breath of Yahweh drives.",
                "\"A Redeemer will come to Zion, and to those who turn from disobedience in Jacob,\" says Yahweh.",
                "\"As for me, this is my covenant with them,\" says Yahweh. \"My Spirit who is on you, and my words which I have put in your mouth, shall not depart out of your mouth, nor out of the mouth of your seed, nor out of the mouth of your seed\u2019s seed,\" says Yahweh, \"from henceforth and forever.\""
            ],
            60: [
                "\"Arise, shine; for your light has come, and the glory of Yahweh is risen on you.",
                "For, behold, darkness shall cover the earth, and gross darkness the peoples; but Yahweh will arise on you, and his glory shall be seen on you.",
                "Nations shall come to your light, and kings to the brightness of your rising.",
                "\"Lift up your eyes all around, and see: they all gather themselves together, they come to you; your sons shall come from far, and your daughters shall be carried in the arms.",
                "Then you shall see and be radiant, and your heart shall thrill and be enlarged; because the abundance of the sea shall be turned to you, the wealth of the nations shall come to you.",
                "The multitude of camels shall cover you, the dromedaries of Midian and Ephah; all they from Sheba shall come; they shall bring gold and frankincense, and shall proclaim the praises of Yahweh.",
                "All the flocks of Kedar shall be gathered together to you, the rams of Nebaioth shall minister to you; they shall come up with acceptance on my altar; and I will glorify the house of my glory.",
                "\"Who are these who fly as a cloud, and as the doves to their windows?",
                "Surely the islands shall wait for me, and the ships of Tarshish first, to bring your sons from far, their silver and their gold with them, for the name of Yahweh your God, and for the Holy One of Israel, because he has glorified you.",
                "\"Foreigners shall build up your walls, and their kings shall minister to you: for in my wrath I struck you, but in my favor have I had mercy on you.",
                "Your gates also shall be open continually; they shall not be shut day nor night; that men may bring to you the wealth of the nations, and their kings led captive.",
                "For that nation and kingdom that will not serve you shall perish; yes, those nations shall be utterly wasted.",
                "\"The glory of Lebanon shall come to you, the fir tree, the pine, and the box tree together, to beautify the place of my sanctuary; and I will make the place of my feet glorious.",
                "The sons of those who afflicted you shall come bending to you; and all those who despised you shall bow themselves down at the soles of your feet; and they shall call you The city of Yahweh, The Zion of the Holy One of Israel.",
                "\"Whereas you have been forsaken and hated, so that no man passed through you, I will make you an eternal excellency, a joy of many generations.",
                "You shall also drink the milk of the nations, and shall nurse from royal breasts; and you shall know that I, Yahweh, am your Savior, and your Redeemer, the Mighty One of Jacob.",
                "For brass I will bring gold, and for iron I will bring silver, and for wood brass, and for stones iron. I will also make your officers peace, and righteousness your ruler.",
                "Violence shall no more be heard in your land, desolation nor destruction within your borders; but you shall call your walls Salvation, and your gates Praise.",
                "The sun shall be no more your light by day; neither for brightness shall the moon give light to you: but Yahweh will be to you an everlasting light, and your God your glory.",
                "Your sun shall no more go down, neither shall your moon withdraw itself; for Yahweh will be your everlasting light, and the days of your mourning shall be ended.",
                "Your people also shall be all righteous; they shall inherit the land forever, the branch of my planting, the work of my hands, that I may be glorified.",
                "The little one shall become a thousand, and the small one a strong nation; I, Yahweh, will hasten it in its time.\""
            ],
            61: [
                "The Spirit of the Lord Yahweh is on me; because Yahweh has anointed me to preach good news to the humble. He has sent me to bind up the brokenhearted, to proclaim liberty to the captives, and release to those who are bound;",
                "to proclaim the year of Yahweh\u2019s favor, and the day of vengeance of our God; to comfort all who mourn;",
                "to appoint to those who mourn in Zion, to give to them a garland for ashes, the oil of joy for mourning, the garment of praise for the spirit of heaviness; that they may be called trees of righteousness, the planting of Yahweh, that he may be glorified.",
                "They shall build the old wastes, they shall raise up the former desolations, and they shall repair the waste cities, the desolations of many generations.",
                "Strangers shall stand and feed your flocks, and foreigners shall be your plowmen and your vinedressers.",
                "But you shall be named the priests of Yahweh; men will call you the ministers of our God: you will eat the wealth of the nations, and you will boast in their glory.",
                "Instead of your shame you shall have double; and instead of dishonor they shall rejoice in their portion: therefore in their land they shall possess double; everlasting joy shall be to them.",
                "\"For I, Yahweh, love justice, I hate robbery with iniquity; and I will give them their recompense in truth, and I will make an everlasting covenant with them.",
                "Their seed shall be known among the nations, and their offspring among the peoples; all who see them shall acknowledge them, that they are the seed which Yahweh has blessed.\"",
                "I will greatly rejoice in Yahweh, my soul shall be joyful in my God; for he has clothed me with the garments of salvation, he has covered me with the robe of righteousness, as a bridegroom decks himself with a garland, and as a bride adorns herself with her jewels.",
                "For as the earth brings forth its bud, and as the garden causes the things that are sown in it to spring forth; so the Lord Yahweh will cause righteousness and praise to spring forth before all the nations."
            ],
            62: [
                "For Zion\u2019s sake will I not hold my peace, and for Jerusalem\u2019s sake I will not rest, until her righteousness go forth as brightness, and her salvation as a lamp that burns.",
                "The nations shall see your righteousness, and all kings your glory, and you shall be called by a new name, which the mouth of Yahweh shall name.",
                "You shall also be a crown of beauty in the hand of Yahweh, and a royal diadem in the hand of your God.",
                "You shall no more be termed Forsaken; neither shall your land any more be termed Desolate: but you shall be called Hephzibah, and your land Beulah; for Yahweh delights in you, and your land shall be married.",
                "For as a young man marries a virgin, so your sons shall marry you; and as the bridegroom rejoices over the bride, so your God will rejoice over you.",
                "I have set watchmen on your walls, Jerusalem; they shall never hold their peace day nor night: you who call on Yahweh, take no rest,",
                "and give him no rest, until he establishes, and until he makes Jerusalem a praise in the earth.",
                "Yahweh has sworn by his right hand, and by the arm of his strength, \"Surely I will no more give your grain to be food for your enemies; and foreigners shall not drink your new wine, for which you have labored:",
                "but those who have garnered it shall eat it, and praise Yahweh; and those who have gathered it shall drink it in the courts of my sanctuary.\"",
                "Go through, go through the gates! Prepare the way of the people! Cast up, cast up the highway! Gather out the stones! Lift up a banner for the peoples.",
                "Behold, Yahweh has proclaimed to the end of the earth, \"Say to the daughter of Zion, \u2018Behold, your salvation comes. Behold, his reward is with him, and his recompense before him.\u2019\"",
                "They shall call them The holy people, The redeemed of Yahweh: and you shall be called Sought out, A city not forsaken."
            ],
            63: [
                "Who is this who comes from Edom, with dyed garments from Bozrah? this who is glorious in his clothing, marching in the greatness of his strength? \"It is I who speak in righteousness, mighty to save.\"",
                "Why are you red in your clothing, and your garments like him who treads in the wine vat?",
                "\"I have trodden the winepress alone; and of the peoples there was no man with me: yes, I trod them in my anger, and trampled them in my wrath; and their lifeblood is sprinkled on my garments, and I have stained all my clothing.",
                "For the day of vengeance was in my heart, and the year of my redeemed has come.",
                "I looked, and there was none to help; and I wondered that there was none to uphold: therefore my own arm brought salvation to me; and my wrath, it upheld me.",
                "I trod down the peoples in my anger, and made them drunk in my wrath, and I poured out their lifeblood on the earth.\"",
                "I will make mention of the loving kindnesses of Yahweh and the praises of Yahweh, according to all that Yahweh has bestowed on us, and the great goodness toward the house of Israel, which he has bestowed on them according to his mercies, and according to the multitude of his loving kindnesses.",
                "For he said, \"Surely, they are my people, children who will not deal falsely:\" so he was their Savior.",
                "In all their affliction he was afflicted, and the angel of his presence saved them: in his love and in his pity he redeemed them; and he bore them, and carried them all the days of old.",
                "But they rebelled, and grieved his holy Spirit: therefore he was turned to be their enemy, and he himself fought against them.",
                "Then he remembered the days of old, Moses and his people, saying, Where is he who brought them up out of the sea with the shepherds of his flock? where is he who put his holy Spirit in their midst?",
                "who caused his glorious arm to go at the right hand of Moses? who divided the waters before them, to make himself an everlasting name?",
                "who led them through the depths, as a horse in the wilderness, so that they didn\u2019t stumble?",
                "As the livestock that go down into the valley, the Spirit of Yahweh caused them to rest; so you led your people, to make yourself a glorious name.",
                "Look down from heaven, and see from the habitation of your holiness and of your glory: where are your zeal and your mighty acts? the yearning of your heart and your compassion is restrained toward me.",
                "For you are our Father, though Abraham doesn\u2019t know us, and Israel does not acknowledge us: you, Yahweh, are our Father; our Redeemer from everlasting is your name.",
                "O Yahweh, why do you make us to err from your ways, and harden our heart from your fear? Return for your servants\u2019 sake, the tribes of your inheritance.",
                "Your holy people possessed it but a little while: our adversaries have trodden down your sanctuary.",
                "We have become as they over whom you never bear rule, as those who were not called by your name."
            ],
            64: [
                "Oh that you would tear the heavens, that you would come down, that the mountains might quake at your presence,",
                "as when fire kindles the brushwood, and the fire causes the waters to boil; to make your name known to your adversaries, that the nations may tremble at your presence!",
                "When you did awesome things which we didn\u2019t look for, you came down, the mountains quaked at your presence.",
                "For from of old men have not heard, nor perceived by the ear, neither has the eye seen a God besides you, who works for him who waits for him.",
                "You meet him who rejoices and works righteousness, those who remember you in your ways. Behold, you were angry, and we sinned. We have been in sin for a long time; and shall we be saved?",
                "For we have all become as one who is unclean, and all our righteousness is as a polluted garment: and we all fade as a leaf; and our iniquities, like the wind, take us away.",
                "There is none who calls on your name, who stirs up himself to take hold of you; for you have hidden your face from us, and have consumed us by means of our iniquities.",
                "But now, Yahweh, you are our Father; we are the clay, and you our potter; and we all are the work of your hand.",
                "Don\u2019t be furious, Yahweh, neither remember iniquity forever: see, look, we beg you, we are all your people.",
                "Your holy cities are become a wilderness, Zion has become a wilderness, Jerusalem a desolation.",
                "Our holy and our beautiful house, where our fathers praised you, is burned with fire; and all our pleasant places are laid waste.",
                "Will you refrain yourself for these things, Yahweh? Will you hold your peace, and afflict us very severely?"
            ],
            65: [
                "\"I am inquired of by those who didn\u2019t ask; I am found by those who didn\u2019t seek me: I said, See me, see me, to a nation that was not called by my name.",
                "I have spread out my hands all the day to a rebellious people, who walk in a way that is not good, after their own thoughts;",
                "a people who provoke me to my face continually, sacrificing in gardens, and burning incense on bricks;",
                "who sit among the graves, and lodge in the secret places; who eat pig\u2019s flesh, and broth of abominable things is in their vessels;",
                "who say, Stand by yourself, don\u2019t come near to me, for I am holier than you. These are a smoke in my nose, a fire that burns all the day.",
                "\"Behold, it is written before me: I will not keep silence, but will recompense, yes, I will recompense into their bosom,",
                "your own iniquities, and the iniquities of your fathers together,\" says Yahweh, \"who have burned incense on the mountains, and blasphemed me on the hills; therefore will I first measure their work into their bosom.\"",
                "Thus says Yahweh, \"As the new wine is found in the cluster, and one says, \u2018Don\u2019t destroy it, for a blessing is in it:\u2019 so will I do for my servants\u2019 sake, that I may not destroy them all.",
                "I will bring forth a seed out of Jacob, and out of Judah an inheritor of my mountains; and my chosen shall inherit it, and my servants shall dwell there.",
                "Sharon shall be a fold of flocks, and the valley of Achor a place for herds to lie down in, for my people who have sought me.",
                "\"But you who forsake Yahweh, who forget my holy mountain, who prepare a table for Fortune, and who fill up mixed wine to Destiny;",
                "I will destine you to the sword, and you shall all bow down to the slaughter; because when I called, you didn\u2019t answer; when I spoke, you didn\u2019t listen; but you did that which was evil in my eyes, and chose that in which I didn\u2019t delight.\"",
                "Therefore thus says the Lord Yahweh, \"Behold, my servants shall eat, but you shall be hungry; behold, my servants shall drink, but you shall be thirsty; behold, my servants shall rejoice, but you shall be disappointed;",
                "behold, my servants shall sing for joy of heart, but you shall cry for sorrow of heart, and shall wail for anguish of spirit.",
                "You shall leave your name for a curse to my chosen; and the Lord Yahweh will kill you; and he will call his servants by another name:",
                "so that he who blesses himself in the earth shall bless himself in the God of truth; and he who swears in the earth shall swear by the God of truth; because the former troubles are forgotten, and because they are hidden from my eyes.",
                "\"For, behold, I create new heavens and a new earth; and the former things shall not be remembered, nor come into mind.",
                "But be you glad and rejoice forever in that which I create; for, behold, I create Jerusalem a rejoicing, and her people a joy.",
                "I will rejoice in Jerusalem, and joy in my people; and there shall be heard in her no more the voice of weeping and the voice of crying.",
                "\"There shall be no more there an infant of days, nor an old man who has not filled his days; for the child shall die one hundred years old, and the sinner being one hundred years old shall be accursed.",
                "They shall build houses, and inhabit them; and they shall plant vineyards, and eat their fruit.",
                "They shall not build, and another inhabit; they shall not plant, and another eat: for as the days of a tree shall be the days of my people, and my chosen shall long enjoy the work of their hands.",
                "They shall not labor in vain, nor bring forth for calamity; for they are the seed of the blessed of Yahweh, and their offspring with them.",
                "It shall happen that, before they call, I will answer; and while they are yet speaking, I will hear.",
                "The wolf and the lamb shall feed together, and the lion shall eat straw like the ox; and dust shall be the serpent\u2019s food. They shall not hurt nor destroy in all my holy mountain,\" says Yahweh."
            ],
            66: [
                "Thus says Yahweh, \"Heaven is my throne, and the earth is my footstool: what kind of house will you build to me? and what place shall be my rest?",
                "For all these things has my hand made, and so all these things came to be,\" says Yahweh: \"but to this man will I look, even to him who is poor and of a contrite spirit, and who trembles at my word.",
                "He who kills an ox is as he who kills a man; he who sacrifices a lamb, as he who breaks a dog\u2019s neck; he who offers an offering, as he who offers pig\u2019s blood; he who burns frankincense, as he who blesses an idol. Yes, they have chosen their own ways, and their soul delights in their abominations:",
                "I also will choose their delusions, and will bring their fears on them; because when I called, no one answered; when I spoke, they didn\u2019t listen; but they did that which was evil in my eyes, and chose that in which I didn\u2019t delight.\"",
                "Hear the word of Yahweh, you who tremble at his word: \"Your brothers who hate you, who cast you out for my name\u2019s sake, have said, \u2018Let Yahweh be glorified, that we may see your joy;\u2019 but it is those who shall be disappointed.",
                "A voice of tumult from the city, a voice from the temple, a voice of Yahweh that renders recompense to his enemies.",
                "\"Before she travailed, she brought forth; before her pain came, she delivered a son.",
                "Who has heard such a thing? who has seen such things? Shall a land be born in one day? shall a nation be brought forth at once? for as soon as Zion travailed, she brought forth her children.",
                "Shall I bring to the birth, and not cause to bring forth?\" says Yahweh: \"shall I who cause to bring forth shut the womb?\" says your God.",
                "\"Rejoice with Jerusalem, and be glad for her, all you who love her: rejoice for joy with her, all you who mourn over her;",
                "that you may nurse and be satisfied at the comforting breasts; that you may drink deeply, and be delighted with the abundance of her glory.\"",
                "For thus says Yahweh, \"Behold, I will extend peace to her like a river, and the glory of the nations like an overflowing stream: and you will nurse. You will be carried on her side, and will be dandled on her knees.",
                "As one whom his mother comforts, so will I comfort you; and you will be comforted in Jerusalem.\"",
                "You will see it, and your heart shall rejoice, and your bones shall flourish like the tender grass: and the hand of Yahweh shall be known toward his servants; and he will have indignation against his enemies.",
                "For, behold, Yahweh will come with fire, and his chariots shall be like the whirlwind; to render his anger with fierceness, and his rebuke with flames of fire.",
                "For by fire will Yahweh execute judgment, and by his sword, on all flesh; and the slain of Yahweh shall be many.",
                "\"Those who sanctify themselves and purify themselves to go to the gardens, behind one in the midst, eating pig\u2019s flesh, and the abomination, and the mouse, they shall come to an end together,\" says Yahweh.",
                "\"For I know their works and their thoughts: the time comes, that I will gather all nations and languages; and they shall come, and shall see my glory.",
                "\"I will set a sign among them, and I will send such as escape of them to the nations, to Tarshish, Pul, and Lud, who draw the bow, to Tubal and Javan, to the islands afar off, who have not heard my fame, neither have seen my glory; and they shall declare my glory among the nations.",
                "They shall bring all your brothers out of all the nations for an offering to Yahweh, on horses, and in chariots, and in litters, and on mules, and on dromedaries, to my holy mountain Jerusalem, says Yahweh, as the children of Israel bring their offering in a clean vessel into the house of Yahweh.",
                "Of them also will I take for priests and for Levites,\" says Yahweh.",
                "\"For as the new heavens and the new earth, which I will make, shall remain before me,\" says Yahweh, \"so your seed and your name shall remain.",
                "It shall happen, that from one new moon to another, and from one Sabbath to another, shall all flesh come to worship before me,\" says Yahweh.",
                "\"They shall go forth, and look on the dead bodies of the men who have transgressed against me: for their worm shall not die, neither shall their fire be quenched; and they will be loathsome to all mankind.\""
            ]
        },
        24: {
            1: [
                "The words of Jeremiah the son of Hilkiah, of the priests who were in Anathoth in the land of Benjamin:",
                "to whom the word of Yahweh came in the days of Josiah the son of Amon, king of Judah, in the thirteenth year of his reign.",
                "It came also in the days of Jehoiakim the son of Josiah, king of Judah, to the end of the eleventh year of Zedekiah, the son of Josiah, king of Judah, to the carrying away of Jerusalem captive in the fifth month.",
                "Now the word of Yahweh came to me, saying,",
                "\"Before I formed you in the belly, I knew you. Before you came forth out of the womb, I sanctified you. I have appointed you a prophet to the nations.\"",
                "Then I said, \"Ah, Lord Yahweh! Behold, I don\u2019t know how to speak; for I am a child.\"",
                "But Yahweh said to me, \"Don\u2019t say, \u2018I am a child;\u2019 for to whoever I shall send you, you shall go, and whatever I shall command you, you shall speak.",
                "Don\u2019t be afraid because of them; for I am with you to deliver you,\" says Yahweh.",
                "Then Yahweh put forth his hand, and touched my mouth; and Yahweh said to me, \"Behold, I have put my words in your mouth.",
                "Behold, I have this day set you over the nations and over the kingdoms, to pluck up and to break down and to destroy and to overthrow, to build and to plant.\"",
                "Moreover the word of Yahweh came to me, saying, \"Jeremiah, what do you see?\" I said, \"I see a branch of an almond tree.\"",
                "Then Yahweh said to me, \"You have seen well; for I watch over my word to perform it.\"",
                "The word of Yahweh came to me the second time, saying, \"What do you see?\" I said, \"I see a boiling caldron; and it is tipping away from the north.\"",
                "Then Yahweh said to me, \"Out of the north evil will break out on all the inhabitants of the land.",
                "For, behold, I will call all the families of the kingdoms of the north,\" says Yahweh; \"and they shall come, and they shall each set his throne at the entrance of the gates of Jerusalem, and against all its walls all around, and against all the cities of Judah.",
                "I will utter my judgments against them touching all their wickedness, in that they have forsaken me, and have burned incense to other gods, and worshiped the works of their own hands.",
                "\"You therefore put your belt on your waist, arise, and speak to them all that I command you. Don\u2019t be dismayed at them, lest I dismay you before them.",
                "For, behold, I have made you this day a fortified city, and an iron pillar, and bronze walls, against the whole land, against the kings of Judah, against its princes, against its priests, and against the people of the land.",
                "They will fight against you; but they will not prevail against you; for I am with you,\" says Yahweh, \"to deliver you.\""
            ],
            2: [
                "The word of Yahweh came to me, saying,",
                "\"Go, and cry in the ears of Jerusalem, saying, \u2018Thus says Yahweh, \"I remember for you the kindness of your youth, the love of your weddings; how you went after me in the wilderness, in a land that was not sown.",
                "Israel was holiness to Yahweh, the first fruits of his increase. All who devour him shall be held guilty. Evil shall come on them,\"\u2019 says Yahweh.\"",
                "Hear the word of Yahweh, O house of Jacob, and all the families of the house of Israel!",
                "Thus says Yahweh, \"What unrighteousness have your fathers found in me, that they have gone far from me, and have walked after vanity, and are become vain?",
                "Neither did they say, \u2018Where is Yahweh who brought us up out of the land of Egypt, who led us through the wilderness, through a land of deserts and of pits, through a land of drought and of the shadow of death, through a land that none passed through, and where no man lived?\u2019",
                "I brought you into a plentiful land, to eat its fruit and its goodness; but when you entered, you defiled my land, and made my heritage an abomination.",
                "The priests didn\u2019t say, \u2018Where is Yahweh?\u2019 and those who handle the law didn\u2019t know me. The rulers also transgressed against me, and the prophets prophesied by Baal, and walked after things that do not profit.",
                "\"Therefore I will yet contend with you,\" says Yahweh, \"and I will contend with your children\u2019s children.",
                "For pass over to the islands of Kittim, and see; and send to Kedar, and consider diligently; and see if there has been such a thing.",
                "Has a nation changed its gods, which really are no gods? But my people have changed their glory for that which does not profit.",
                "\"Be astonished, you heavens, at this, and be horribly afraid. Be very desolate,\" says Yahweh.",
                "\"For my people have committed two evils: they have forsaken me, the spring of living waters, and cut them out cisterns, broken cisterns, that can hold no water.",
                "Is Israel a servant? Is he a native-born slave? Why has he become a prey?",
                "The young lions have roared at him, and yelled. They have made his land waste. His cities are burned up, without inhabitant.",
                "The children also of Memphis and Tahpanhes have broken the crown of your head.",
                "\"Haven\u2019t you procured this to yourself, in that you have forsaken Yahweh your God, when he led you by the way?",
                "Now what have you to do in the way to Egypt, to drink the waters of the Shihor? Or what have you to do in the way to Assyria, to drink the waters of the River?",
                "\"Your own wickedness shall correct you, and your backsliding shall reprove you. Know therefore and see that it is an evil thing and a bitter, that you have forsaken Yahweh your God, and that my fear is not in you,\" says the Lord, Yahweh of Armies.",
                "\"For of old time I have broken your yoke, and burst your bonds; and you said, \u2018I will not serve;\u2019 for on every high hill and under every green tree you bowed yourself, playing the prostitute.",
                "Yet I had planted you a noble vine, wholly a right seed. How then have you turned into the degenerate branches of a foreign vine to me?",
                "For though you wash yourself with lye, and use much soap, yet your iniquity is marked before me,\" says the Lord Yahweh.",
                "\"How can you say, \u2018I am not defiled. I have not gone after the Baals\u2019? See your way in the valley. Know what you have done. You are a swift dromedary traversing her ways;",
                "a wild donkey used to the wilderness, that snuffs up the wind in her desire. When she is in heat, who can turn her away? All those who seek her will not weary themselves. In her month, they will find her.",
                "\"Withhold your foot from being unshod, and your throat from thirst. But you said, \u2018It is in vain. No, for I have loved strangers, and I will go after them.\u2019",
                "As the thief is ashamed when he is found, so is the house of Israel ashamed; they, their kings, their princes, and their priests, and their prophets;",
                "who tell wood, \u2018You are my father;\u2019 and a stone, \u2018You have brought me out:\u2019 for they have turned their back to me, and not their face; but in the time of their trouble they will say, \u2018Arise, and save us.\u2019",
                "\"But where are your gods that you have made for yourselves? Let them arise, if they can save you in the time of your trouble: for according to the number of your cities are your gods, Judah.",
                "\"Why will you contend with me? You all have transgressed against me,\" says Yahweh.",
                "\"I have struck your children in vain. They received no correction. Your own sword has devoured your prophets, like a destroying lion.",
                "Generation, consider the word of Yahweh. Have I been a wilderness to Israel? Or a land of thick darkness? Why do my people say, \u2018We have broken loose. We will come to you no more?\u2019",
                "\"Can a virgin forget her ornaments, or a bride her attire? Yet my people have forgotten me for days without number.",
                "How well you prepare your way to seek love! Therefore you have taught even the wicked women your ways.",
                "Also the blood of the souls of the innocent poor is found in your skirts. You did not find them breaking in; but it is because of all these things.",
                "\"Yet you said, \u2018I am innocent. Surely his anger has turned away from me.\u2019 \"Behold, I will judge you, because you say, \u2018I have not sinned.\u2019",
                "Why do you go about so much to change your way? You will be ashamed of Egypt also, as you were ashamed of Assyria.",
                "From there also you shall go forth, with your hands on your head; for Yahweh has rejected those in whom you trust, and you shall not prosper with them."
            ],
            3: [
                "\"They say, \u2018If a man puts away his wife, and she goes from him, and become another man\u2019s, will he return to her again?\u2019 Wouldn\u2019t that land be greatly polluted? But you have played the prostitute with many lovers; yet return again to me,\" says Yahweh.",
                "\"Lift up your eyes to the bare heights, and see! Where have you not been lain with? You have sat for them by the ways, as an Arabian in the wilderness. You have polluted the land with your prostitution and with your wickedness.",
                "Therefore the showers have been withheld, and there has been no latter rain; yet you have a prostitute\u2019s forehead, you refused to be ashamed.",
                "Will you not from this time cry to me, \u2018My Father, you are the guide of my youth?\u2019",
                "\"\u2018Will he retain his anger forever? Will he keep it to the end?\u2019 Behold, you have spoken and have done evil things, and have had your way.\"",
                "Moreover, Yahweh said to me in the days of Josiah the king, \"Have you seen that which backsliding Israel has done? She has gone up on every high mountain and under every green tree, and there has played the prostitute.",
                "I said after she had done all these things, \u2018She will return to me;\u2019 but she didn\u2019t return; and her treacherous sister Judah saw it.",
                "I saw, when, for this very cause that backsliding Israel had committed adultery, I had put her away and given her a bill of divorce, yet treacherous Judah, her sister, didn\u2019t fear; but she also went and played the prostitute.",
                "It happened through the lightness of her prostitution, that the land was polluted, and she committed adultery with stones and with stocks.",
                "Yet for all this her treacherous sister, Judah, has not returned to me with her whole heart, but only in pretense,\" says Yahweh.",
                "Yahweh said to me, \"Backsliding Israel has shown herself more righteous than treacherous Judah.",
                "Go, and proclaim these words toward the north, and say, \u2018Return, you backsliding Israel,\u2019 says Yahweh; \u2018I will not look in anger on you; for I am merciful,\u2019 says Yahweh. \u2018I will not keep anger forever.",
                "Only acknowledge your iniquity, that you have transgressed against Yahweh your God, and have scattered your ways to the strangers under every green tree, and you have not obeyed my voice,\u2019 says Yahweh.\"",
                "\"Return, backsliding children,\" says Yahweh; \"for I am a husband to you. I will take you one of a city, and two of a family, and I will bring you to Zion.",
                "I will give you shepherds according to my heart, who shall feed you with knowledge and understanding.",
                "It shall come to pass, when you are multiplied and increased in the land, in those days,\" says Yahweh, \"they shall say no more, \u2018The ark of the covenant of Yahweh!\u2019 neither shall it come to mind; neither shall they remember it; neither shall they miss it; neither shall it be made any more.",
                "At that time they shall call Jerusalem \u2018The throne of Yahweh;\u2019 and all the nations shall be gathered to it, to the name of Yahweh, to Jerusalem. Neither shall they walk any more after the stubbornness of their evil heart.",
                "In those days the house of Judah shall walk with the house of Israel, and they shall come together out of the land of the north to the land that I gave for an inheritance to your fathers.",
                "\"But I said, \u2018How I would put you among the children, and give you a pleasant land, a goodly heritage of the armies of the nations!\u2019 and I said, \u2018You shall call me \"My Father,\" and shall not turn away from following me.\u2019",
                "\"Surely as a wife treacherously departs from her husband, so you have dealt treacherously with me, house of Israel,\" says Yahweh.",
                "A voice is heard on the bare heights, the weeping and the petitions of the children of Israel; because they have perverted their way, they have forgotten Yahweh their God.",
                "Return, you backsliding children, I will heal your backsliding. \"Behold, we have come to you; for you are Yahweh our God.",
                "Truly in vain is help from the hills, the tumult on the mountains. Truly the salvation of Israel is in Yahweh our God.",
                "But the shameful thing has devoured the labor of our fathers from our youth, their flocks and their herds, their sons and their daughters.",
                "Let us lie down in our shame, and let our confusion cover us; for we have sinned against Yahweh our God, we and our fathers, from our youth even to this day. We have not obeyed the voice of Yahweh our God.\""
            ],
            4: [
                "\"If you will return, Israel,\" says Yahweh, \"if you will return to me, and if you will put away your abominations out of my sight; then you shall not be removed;",
                "and you shall swear, \u2018As Yahweh lives,\u2019 in truth, in justice, and in righteousness. The nations shall bless themselves in him, and in him shall they glory.\"",
                "For thus says Yahweh to the men of Judah and to Jerusalem, \"Break up your fallow ground, and don\u2019t sow among thorns.",
                "Circumcise yourselves to Yahweh, and take away the foreskins of your heart, you men of Judah and inhabitants of Jerusalem; lest my wrath go forth like fire, and burn so that none can quench it, because of the evil of your doings.",
                "Declare in Judah, and publish in Jerusalem; and say, \u2018Blow the trumpet in the land!\u2019 Cry aloud and say, \u2018Assemble yourselves! Let us go into the fortified cities!\u2019",
                "Set up a standard toward Zion. Flee for safety! Don\u2019t wait; for I will bring evil from the north, and a great destruction.\"",
                "A lion is gone up from his thicket, and a destroyer of nations; he is on his way, he is gone forth from his place, to make your land desolate, that your cities be laid waste, without inhabitant.",
                "For this clothe yourself with sackcloth, lament and wail; for the fierce anger of Yahweh hasn\u2019t turned back from us.",
                "\"It shall happen at that day,\" says Yahweh, \"that the heart of the king shall perish, and the heart of the princes; and the priests shall be astonished, and the prophets shall wonder.\"",
                "Then I said, \"Ah, Lord Yahweh! Surely you have greatly deceived this people and Jerusalem, saying, \u2018You shall have peace;\u2019 whereas the sword reaches to the heart.\"",
                "At that time shall it be said to this people and to Jerusalem, \"A hot wind from the bare heights in the wilderness toward the daughter of my people, not to winnow, nor to cleanse;",
                "a full wind from these shall come for me. Now I will also utter judgments against them.\"",
                "Behold, he shall come up as clouds, and his chariots shall be as the whirlwind: his horses are swifter than eagles. Woe to us! For we are ruined.",
                "Jerusalem, wash your heart from wickedness, that you may be saved. How long shall your evil thoughts lodge within you?",
                "For a voice declares from Dan, and publishes evil from the hills of Ephraim:",
                "\"Tell the nations; behold, publish against Jerusalem, \u2018Watchers come from a far country, and raise their voice against the cities of Judah.",
                "As keepers of a field, they are against her all around, because she has been rebellious against me,\u2019\" says Yahweh.",
                "\"Your way and your doings have brought these things to you. This is your wickedness; for it is bitter, for it reaches to your heart.\"",
                "My anguish, my anguish! I am pained at my very heart; my heart is disquieted in me; I can\u2019t hold my peace; because you have heard, O my soul, the sound of the trumpet, the alarm of war.",
                "Destruction on destruction is cried; for the whole land is laid waste: suddenly are my tents destroyed, and my curtains in a moment.",
                "How long shall I see the standard, and hear the sound of the trumpet?",
                "\"For my people are foolish, they don\u2019t know me. They are foolish children, and they have no understanding. They are skillful in doing evil, but to do good they have no knowledge.\"",
                "I saw the earth, and, behold, it was waste and void; and the heavens, and they had no light.",
                "I saw the mountains, and behold, they trembled, and all the hills moved back and forth.",
                "I saw, and behold, there was no man, and all the birds of the sky had fled.",
                "I saw, and behold, the fruitful field was a wilderness, and all its cities were broken down at the presence of Yahweh, before his fierce anger.",
                "For thus says Yahweh, \"The whole land shall be a desolation; yet will I not make a full end.",
                "For this the earth will mourn, and the heavens above be black; because I have spoken it, I have purposed it, and I have not repented, neither will I turn back from it.\"",
                "Every city flees for the noise of the horsemen and archers; they go into the thickets, and climb up on the rocks: every city is forsaken, and not a man dwells therein.",
                "You, when you are made desolate, what will you do? Though you clothe yourself with scarlet, though you deck you with ornaments of gold, though you enlarge your eyes with paint, in vain do you make yourself beautiful; your lovers despise you, they seek your life.",
                "For I have heard a voice as of a woman in travail, the anguish as of her who brings forth her first child, the voice of the daughter of Zion, who gasps for breath, who spreads her hands, saying, \"Woe is me now! For my soul faints before the murderers.\""
            ],
            5: [
                "\"Run back and forth through the streets of Jerusalem, and see now, and know, and seek in the broad places of it, if you can find a man, if there are any who does justly, who seeks truth; and I will pardon her.",
                "Though they say, \u2018As Yahweh lives;\u2019 surely they swear falsely.\"",
                "O Yahweh, don\u2019t your eyes look on truth? You have stricken them, but they were not grieved. You have consumed them, but they have refused to receive correction. They have made their faces harder than a rock. They have refused to return.",
                "Then I said, \"Surely these are poor. They are foolish; for they don\u2019t know the way of Yahweh, nor the law of their God.",
                "I will go to the great men, and will speak to them; for they know the way of Yahweh, and the law of their God.\" But these with one accord have broken the yoke, and burst the bonds.",
                "Therefore a lion out of the forest shall kill them, a wolf of the evenings shall destroy them, a leopard shall watch against their cities; everyone who goes out there shall be torn in pieces; because their transgressions are many, and their backsliding is increased.",
                "\"How can I pardon you? Your children have forsaken me, and sworn by what are no gods. When I had fed them to the full, they committed adultery, and assembled themselves in troops at the prostitutes\u2019 houses.",
                "They were as fed horses roaming at large: everyone neighed after his neighbor\u2019s wife.",
                "Shouldn\u2019t I punish them for these things?\" says Yahweh; \"and shouldn\u2019t my soul be avenged on such a nation as this?",
                "\"Go up on her walls, and destroy; but don\u2019t make a full end. Take away her branches; for they are not Yahweh\u2019s.",
                "For the house of Israel and the house of Judah have dealt very treacherously against me,\" says Yahweh.",
                "They have denied Yahweh, and said, \"It is not he; neither shall evil come on us; neither shall we see sword nor famine.",
                "The prophets shall become wind, and the word is not in them. Thus shall it be done to them.\"",
                "Therefore thus says Yahweh, the God of Armies, \"Because you speak this word, behold, I will make my words in your mouth fire, and this people wood, and it shall devour them.",
                "Behold, I will bring a nation on you from far, house of Israel,\" says Yahweh. \"It is a mighty nation. It is an ancient nation, a nation whose language you don\u2019t know, neither understand what they say.",
                "Their quiver is an open tomb, they are all mighty men.",
                "They shall eat up your harvest, and your bread, which your sons and your daughters should eat. They shall eat up your flocks and your herds. They shall eat up your vines and your fig trees. They shall beat down your fortified cities, in which you trust, with the sword.",
                "\"But even in those days,\" says Yahweh, \"I will not make a full end with you.",
                "It will happen, when you say, \u2018Why has Yahweh our God done all these things to us?\u2019 Then you shall say to them, \u2018Just like you have forsaken me, and served foreign gods in your land, so you shall serve strangers in a land that is not yours.\u2019",
                "\"Declare this in the house of Jacob, and publish it in Judah, saying,",
                "\u2018Hear now this, foolish people, and without understanding; who have eyes, and don\u2019t see; who have ears, and don\u2019t hear:",
                "Don\u2019t you fear me?\u2019 says Yahweh \u2018Won\u2019t you tremble at my presence, who have placed the sand for the bound of the sea, by a perpetual decree, that it can\u2019t pass it? and though its waves toss themselves, yet they can\u2019t prevail; though they roar, yet they can\u2019t pass over it.\u2019",
                "\"But this people has a revolting and a rebellious heart; they have revolted and gone.",
                "Neither do they say in their heart, \u2018Let us now fear Yahweh our God, who gives rain, both the former and the latter, in its season; who preserves to us the appointed weeks of the harvest.\u2019",
                "\"Your iniquities have turned away these things, and your sins have withheld good from you.",
                "For among my people are found wicked men. They watch, as fowlers lie in wait. They set a trap. They catch men.",
                "As a cage is full of birds, so are their houses full of deceit. Therefore they have become great, and grew rich.",
                "They have grown fat. They shine; yes, they excell in deeds of wickedness. They don\u2019t plead the cause, the cause of the fatherless, that they may prosper; and they don\u2019t judge the right of the needy.",
                "\"Shall I not punish for these things?\" says Yahweh. \"Shall not my soul be avenged on such a nation as this?",
                "\"An astonishing and horrible thing has happened in the land.",
                "The prophets prophesy falsely, and the priests rule by their own authority; and my people love to have it so. What will you do in the end of it?"
            ],
            6: [
                "\"Flee for safety, you children of Benjamin, out of the midst of Jerusalem, and blow the trumpet in Tekoa, and raise up a signal on Beth Haccherem; for evil looks forth from the north, and a great destruction.",
                "The comely and delicate one, the daughter of Zion, will I cut off.",
                "Shepherds with their flocks shall come to her; they shall pitch their tents against her all around; they shall feed everyone in his place.\"",
                "\"Prepare war against her; arise, and let us go up at noon. Woe to us! For the day declines, for the shadows of the evening are stretched out.",
                "Arise, and let us go up by night, and let us destroy her palaces.\"",
                "For Yahweh of Armies said, \"Cut down trees, and cast up a mound against Jerusalem: this is the city to be visited; she is wholly oppression in the midst of her.",
                "As a well casts forth its waters, so she casts forth her wickedness: violence and destruction is heard in her; before me continually is sickness and wounds.",
                "Be instructed, Jerusalem, lest my soul be alienated from you; lest I make you a desolation, a land not inhabited.\"",
                "Thus says Yahweh of Armies, \"They shall thoroughly glean the remnant of Israel like a vine. Turn again your hand as a grape gatherer into the baskets.\"",
                "To whom shall I speak and testify, that they may hear? Behold, their ear is uncircumcised, and they can\u2019t listen. Behold, the word of Yahweh has become a reproach to them. They have no delight in it.",
                "Therefore I am full of the wrath of Yahweh. I am weary with holding in. \"Pour it out on the children in the street, and on the assembly of young men together; for even the husband with the wife shall be taken, the aged with him who is full of days.",
                "Their houses shall be turned to others, their fields and their wives together; for I will stretch out my hand on the inhabitants of the land, says Yahweh.\"",
                "\"For from their least even to their greatest, everyone is given to covetousness; and from the prophet even to the priest, everyone deals falsely.",
                "They have healed also the hurt of my people superficially, saying, \u2018Peace, peace!\u2019 when there is no peace.",
                "Were they ashamed when they had committed abomination? No, they were not at all ashamed, neither could they blush. Therefore they shall fall among those who fall; at the time that I visit them, they shall be cast down,\" says Yahweh.",
                "Thus says Yahweh, \"Stand in the ways and see, and ask for the old paths, \u2018Where is the good way?\u2019 and walk in it, and you will find rest for your souls. But they said, \u2018We will not walk in it.\u2019",
                "I set watchmen over you, saying, \u2018Listen to the sound of the trumpet!\u2019 But they said, \u2018We will not listen!\u2019",
                "Therefore hear, you nations, and know, congregation, what is among them.",
                "Hear, earth! Behold, I will bring evil on this people, even the fruit of their thoughts, because they have not listened to my words; and as for my law, they have rejected it.",
                "To what purpose comes there to me frankincense from Sheba, and the sweet cane from a far country? your burnt offerings are not acceptable, nor your sacrifices pleasing to me.\"",
                "Therefore thus says Yahweh, \"Behold, I will lay stumbling blocks before this people. The fathers and the sons together shall stumble against them. The neighbor and his friend shall perish.\"",
                "Thus says Yahweh, \"Behold, a people comes from the north country. A great nation shall be stirred up from the uttermost parts of the earth.",
                "They take hold of bow and spear. They are cruel, and have no mercy. Their voice roars like the sea, and they ride on horses, everyone set in array, as a man to the battle, against you, daughter of Zion.\"",
                "We have heard its report; our hands become feeble: anguish has taken hold of us, and pains as of a woman in labor.",
                "Don\u2019t go forth into the field, nor walk by the way; for the sword of the enemy and terror, are on every side.",
                "Daughter of my people, clothe yourself with sackcloth, and wallow in ashes! Mourn, as for an only son, most bitter lamentation; for the destroyer shall suddenly come on us.",
                "\"I have made you a tester of metals and a fortress among my people; that you may know and try their way.",
                "They are all grievous rebels, going about with slanders; they are brass and iron: they all of them deal corruptly.",
                "The bellows blow fiercely; the lead is consumed of the fire: in vain do they go on refining; for the wicked are not plucked away.",
                "Men will call them rejected silver, because Yahweh has rejected them.\""
            ],
            7: [
                "The word that came to Jeremiah from Yahweh, saying,",
                "\"Stand in the gate of Yahweh\u2019s house, and proclaim there this word, and say, \u2018Hear the word of Yahweh, all you of Judah, who enter in at these gates to worship Yahweh.",
                "Thus says Yahweh of Armies, the God of Israel, Amend your ways and your doings, and I will cause you to dwell in this place.",
                "Don\u2019t trust in lying words, saying, The temple of Yahweh, the temple of Yahweh, the temple of Yahweh, are these.",
                "For if you thoroughly amend your ways and your doings; if you thoroughly execute justice between a man and his neighbor;",
                "if you don\u2019t oppress the foreigner, the fatherless, and the widow, and don\u2019t shed innocent blood in this place, neither walk after other gods to your own hurt:",
                "then will I cause you to dwell in this place, in the land that I gave to your fathers, from of old even forevermore.",
                "Behold, you trust in lying words, that can\u2019t profit.",
                "Will you steal, murder, and commit adultery, and swear falsely, and burn incense to Baal, and walk after other gods that you have not known,",
                "and come and stand before me in this house, which is called by my name, and say, We are delivered; that you may do all these abominations?",
                "Is this house, which is called by my name, become a den of robbers in your eyes? Behold, I, even I, have seen it, says Yahweh.",
                "But go now to my place which was in Shiloh, where I caused my name to dwell at the first, and see what I did to it for the wickedness of my people Israel.",
                "Now, because you have done all these works, says Yahweh, and I spoke to you, rising up early and speaking, but you didn\u2019t hear; and I called you, but you didn\u2019t answer:",
                "therefore will I do to the house which is called by my name, in which you trust, and to the place which I gave to you and to your fathers, as I did to Shiloh.",
                "I will cast you out of my sight, as I have cast out all your brothers, even the whole seed of Ephraim.",
                "Therefore don\u2019t pray for this people, neither lift up a cry nor prayer for them, neither make intercession to me; for I will not hear you.",
                "Don\u2019t you see what they do in the cities of Judah and in the streets of Jerusalem?",
                "The children gather wood, and the fathers kindle the fire, and the women knead the dough, to make cakes to the queen of the sky, and to pour out drink offerings to other gods, that they may provoke me to anger.",
                "Do they provoke me to anger? says Yahweh. Don\u2019t they provoke themselves, to the confusion of their own faces?",
                "Therefore thus says the Lord Yahweh: Behold, my anger and my wrath shall be poured out on this place, on man, and on animal, and on the trees of the field, and on the fruit of the ground; and it shall burn, and shall not be quenched.",
                "Thus says Yahweh of Armies, the God of Israel: Add your burnt offerings to your sacrifices, and eat meat.",
                "For I didn\u2019t speak to your fathers, nor command them in the day that I brought them out of the land of Egypt, concerning burnt offerings or sacrifices:",
                "but this thing I commanded them, saying, Listen to my voice, and I will be your God, and you shall be my people; and walk in all the way that I command you, that it may be well with you.",
                "But they didn\u2019t listen nor turn their ear, but walked in their own counsels and in the stubbornness of their evil heart, and went backward, and not forward.",
                "Since the day that your fathers came forth out of the land of Egypt to this day, I have sent to you all my servants the prophets, daily rising up early and sending them:",
                "yet they didn\u2019t listen to me, nor inclined their ear, but made their neck stiff: they did worse than their fathers.",
                "You shall speak all these words to them; but they will not listen to you: you shall also call to them; but they will not answer you.",
                "You shall tell them, This is the nation that has not listened to the voice of Yahweh their God, nor received instruction: truth is perished, and is cut off from their mouth.",
                "Cut off your hair, and throw it away, and take up a lamentation on the bare heights; for Yahweh has rejected and forsaken the generation of his wrath.",
                "For the children of Judah have done that which is evil in my sight, says Yahweh: they have set their abominations in the house which is called by my name, to defile it.",
                "They have built the high places of Topheth, which is in the valley of the son of Hinnom, to burn their sons and their daughters in the fire; which I didn\u2019t command, nor did it come into my mind.",
                "Therefore, behold, the days come, says Yahweh, that it shall no more be called Topheth, nor The valley of the son of Hinnom, but The valley of Slaughter: for they shall bury in Topheth, until there is no place to bury.",
                "The dead bodies of this people shall be food for the birds of the sky, and for the animals of the earth; and none shall frighten them away.",
                "Then will I cause to cease from the cities of Judah, and from the streets of Jerusalem, the voice of mirth and the voice of gladness, the voice of the bridegroom and the voice of the bride; for the land shall become a waste."
            ],
            8: [
                "At that time, says Yahweh, they shall bring out the bones of the kings of Judah, and the bones of his princes, and the bones of the priests, and the bones of the prophets, and the bones of the inhabitants of Jerusalem, out of their graves;",
                "and they shall spread them before the sun, and the moon, and all the army of the sky, which they have loved, and which they have served, and after which they have walked, and which they have sought, and which they have worshiped: they shall not be gathered, nor be buried, they shall be for dung on the surface of the earth.",
                "Death shall be chosen rather than life by all the residue that remain of this evil family, that remain in all the places where I have driven them, says Yahweh of Armies.",
                "Moreover you shall tell them, Thus says Yahweh: Shall men fall, and not rise up again? Shall one turn away, and not return?",
                "Why then is this people of Jerusalem slidden back by a perpetual backsliding? they hold fast deceit, they refuse to return.",
                "I listened and heard, but they didn\u2019t speak aright: no man repents him of his wickedness, saying, What have I done? everyone turns to his course, as a horse that rushes headlong in the battle.",
                "Yes, the stork in the sky knows her appointed times; and the turtledove and the swallow and the crane observe the time of their coming; but my people don\u2019t know Yahweh\u2019s law.",
                "How do you say, We are wise, and the law of Yahweh is with us? But, behold, the false pen of the scribes has worked falsely.",
                "The wise men are disappointed, they are dismayed and taken: behold, they have rejected the word of Yahweh; and what kind of wisdom is in them?",
                "Therefore will I give their wives to others, and their fields to those who shall possess them: for everyone from the least even to the greatest is given to covetousness; from the prophet even to the priest every one deals falsely.",
                "They have healed the hurt of the daughter of my people slightly, saying, Peace, peace; when there is no peace.",
                "Were they ashamed when they had committed abomination? nay, they were not at all ashamed, neither could they blush: therefore shall they fall among those who fall; in the time of their visitation they shall be cast down, says Yahweh.",
                "I will utterly consume them, says Yahweh: no grapes shall be on the vine, nor figs on the fig tree, and the leaf shall fade; and the things that I have given them shall pass away from them.",
                "Why do we sit still? Assemble yourselves, and let us enter into the fortified cities, and let us be silent there; for Yahweh our God has put us to silence, and given us water of gall to drink, because we have sinned against Yahweh.",
                "We looked for peace, but no good came; and for a time of healing, and behold, dismay!",
                "The snorting of his horses is heard from Dan: at the sound of the neighing of his strong ones the whole land trembles; for they have come, and have devoured the land and all that is in it; the city and those who dwell therein.",
                "For, behold, I will send serpents, adders, among you, which will not be charmed; and they shall bite you, says Yahweh.",
                "Oh that I could comfort myself against sorrow! My heart is faint within me.",
                "Behold, the voice of the cry of the daughter of my people from a land that is very far off: isn\u2019t Yahweh in Zion? Isn\u2019t her King in her? Why have they provoked me to anger with their engraved images, and with foreign vanities?",
                "The harvest is past, the summer is ended, and we are not saved.",
                "For the hurt of the daughter of my people am I hurt: I mourn; dismay has taken hold on me.",
                "Is there no balm in Gilead? is there no physician there? why then isn\u2019t the health of the daughter of my people recovered?"
            ],
            9: [
                "Oh that my head were waters, and my eyes a spring of tears, that I might weep day and night for the slain of the daughter of my people!",
                "Oh that I had in the wilderness a lodging place of wayfaring men; that I might leave my people, and go from them! for they are all adulterers, an assembly of treacherous men.",
                "They bend their tongue, as their bow, for falsehood; and they are grown strong in the land, but not for truth: for they proceed from evil to evil, and they don\u2019t know me, says Yahweh.",
                "Take heed everyone of his neighbor, and don\u2019t trust in any brother; for every brother will utterly supplant, and every neighbor will go about with slanders.",
                "They will deceive everyone his neighbor, and will not speak the truth: they have taught their tongue to speak lies; they weary themselves to commit iniquity.",
                "Your habitation is in the midst of deceit; through deceit they refuse to know me, says Yahweh.",
                "Therefore thus says Yahweh of Armies, Behold, I will melt them, and try them; for how should I deal with the daughter of my people?",
                "Their tongue is a deadly arrow; it speaks deceit: one speaks peaceably to his neighbor with his mouth, but in his heart he lays wait for him.",
                "Shall I not visit them for these things? says Yahweh; shall not my soul be avenged on such a nation as this?",
                "For the mountains will I take up a weeping and wailing, and for the pastures of the wilderness a lamentation, because they are burned up, so that none passes through; neither can men hear the voice of the livestock; both the birds of the sky and the animals are fled, they are gone.",
                "I will make Jerusalem heaps, a dwelling place of jackals; and I will make the cities of Judah a desolation, without inhabitant.",
                "Who is the wise man, that may understand this? Who is he to whom the mouth of Yahweh has spoken, that he may declare it? Why is the land perished and burned up like a wilderness, so that none passes through?",
                "Yahweh says, Because they have forsaken my law which I set before them, and have not obeyed my voice, neither walked therein,",
                "but have walked after the stubbornness of their own heart, and after the Baals, which their fathers taught them;",
                "therefore thus says Yahweh of Armies, the God of Israel, Behold, I will feed them, even this people, with wormwood, and give them water of gall to drink.",
                "I will scatter them also among the nations, whom neither they nor their fathers have known; and I will send the sword after them, until I have consumed them.",
                "Thus says Yahweh of Armies, Consider, and call for the mourning women, that they may come; and send for the skillful women, that they may come:",
                "and let them make haste, and take up a wailing for us, that our eyes may run down with tears, and our eyelids gush out with waters.",
                "For a voice of wailing is heard out of Zion, How are we ruined! we are greatly confounded, because we have forsaken the land, because they have cast down our dwellings.",
                "Yet hear the word of Yahweh, you women, and let your ear receive the word of his mouth; and teach your daughters wailing, and everyone her neighbor lamentation.",
                "For death has come up into our windows, it is entered into our palaces; to cut off the children from outside, and the young men from the streets.",
                "Speak, Thus says Yahweh, The dead bodies of men shall fall as dung on the open field, and as the handful after the harvester; and none shall gather them.",
                "Thus says Yahweh, Don\u2019t let the wise man glory in his wisdom, neither let the mighty man glory in his might, don\u2019t let the rich man glory in his riches;",
                "but let him who glories glory in this, that he has understanding, and knows me, that I am Yahweh who exercises loving kindness, justice, and righteousness, in the earth: for in these things I delight, says Yahweh.",
                "Behold, the days come, says Yahweh, that I will punish all those who are circumcised in uncircumcision:",
                "Egypt, and Judah, and Edom, and the children of Ammon, and Moab, and all that have the corners of their hair cut off, who dwell in the wilderness; for all the nations are uncircumcised, and all the house of Israel are uncircumcised in heart."
            ],
            10: [
                "Hear the word which Yahweh speaks to you, house of Israel!",
                "Thus says Yahweh, \"Don\u2019t learn the way of the nations, and don\u2019t be dismayed at the signs of the sky; for the nations are dismayed at them.",
                "For the customs of the peoples are vanity; for one cuts a tree out of the forest, the work of the hands of the workman with the axe.",
                "They deck it with silver and with gold; they fasten it with nails and with hammers, that it not move.",
                "They are like a palm tree, of turned work, and don\u2019t speak: they must be carried, because they can\u2019t go. Don\u2019t be afraid of them; for they can\u2019t do evil, neither is it in them to do good.\"",
                "There is none like you, Yahweh; you are great, and your name is great in might.",
                "Who should not fear you, King of the nations? For it appertains to you; because among all the wise men of the nations, and in all their royal estate, there is none like you.",
                "But they are together brutish and foolish: the instruction of idols! it is but a stock.",
                "There is silver beaten into plates, which is brought from Tarshish, and gold from Uphaz, the work of the artificer and of the hands of the goldsmith; blue and purple for their clothing; they are all the work of skillful men.",
                "But Yahweh is the true God; he is the living God, and an everlasting King: at his wrath the earth trembles, and the nations are not able to withstand his indignation.",
                "You shall say this to them: The gods that have not made the heavens and the earth, these shall perish from the earth, and from under the heavens.",
                "He has made the earth by his power, he has established the world by his wisdom, and by his understanding has he stretched out the heavens:",
                "when he utters his voice, there is a tumult of waters in the heavens, and he causes the vapors to ascend from the ends of the earth; he makes lightnings for the rain, and brings forth the wind out of his treasuries.",
                "Every man has become brutish and without knowledge; every goldsmith is disappointed by his engraved image; for his molten image is falsehood, and there is no breath in them.",
                "They are vanity, a work of delusion: in the time of their visitation they shall perish.",
                "The portion of Jacob is not like these; for he is the former of all things; and Israel is the tribe of his inheritance: Yahweh of Armies is his name.",
                "Gather up your wares out of the land, you who live under siege.",
                "For thus says Yahweh, Behold, I will sling out the inhabitants of the land at this time, and will distress them, that they may feel it.",
                "Woe is me because of my hurt! my wound is grievous: but I said, Truly this is my grief, and I must bear it.",
                "My tent is destroyed, and all my cords are broken: my children are gone forth from me, and they are no more: there is none to spread my tent any more, and to set up my curtains.",
                "For the shepherds are become brutish, and have not inquired of Yahweh: therefore they have not prospered, and all their flocks are scattered.",
                "The voice of news, behold, it comes, and a great commotion out of the north country, to make the cities of Judah a desolation, a dwelling place of jackals.",
                "Yahweh, I know that the way of man is not in himself: it is not in man who walks to direct his steps.",
                "Yahweh, correct me, but in measure: not in your anger, lest you bring me to nothing.",
                "Pour out your wrath on the nations that don\u2019t know you, and on the families that don\u2019t call on your name: for they have devoured Jacob, yes, they have devoured him and consumed him, and have laid waste his habitation."
            ],
            11: [
                "The word that came to Jeremiah from Yahweh, saying,",
                "Hear the words of this covenant, and speak to the men of Judah, and to the inhabitants of Jerusalem;",
                "and say to them, Thus says Yahweh, the God of Israel: Cursed is the man who doesn\u2019t hear the words of this covenant,",
                "which I commanded your fathers in the day that I brought them forth out of the land of Egypt, out of the iron furnace, saying, Obey my voice, and do them, according to all which I command you: so you shall be my people, and I will be your God;",
                "that I may establish the oath which I swore to your fathers, to give them a land flowing with milk and honey, as at this day. Then answered I, and said, Amen, Yahweh.",
                "Yahweh said to me, Proclaim all these words in the cities of Judah, and in the streets of Jerusalem, saying, Hear the words of this covenant, and do them.",
                "For I earnestly protested to your fathers in the day that I brought them up out of the land of Egypt, even to this day, rising early and protesting, saying, Obey my voice.",
                "Yet they didn\u2019t obey, nor turn their ear, but walked everyone in the stubbornness of their evil heart: therefore I brought on them all the words of this covenant, which I commanded them to do, but they didn\u2019t do them.",
                "Yahweh said to me, A conspiracy is found among the men of Judah, and among the inhabitants of Jerusalem.",
                "They are turned back to the iniquities of their forefathers, who refused to hear my words; and they are gone after other gods to serve them: the house of Israel and the house of Judah have broken my covenant which I made with their fathers.",
                "Therefore thus says Yahweh, Behold, I will bring evil on them, which they shall not be able to escape; and they shall cry to me, but I will not listen to them.",
                "Then shall the cities of Judah and the inhabitants of Jerusalem go and cry to the gods to which they offer incense: but they will not save them at all in the time of their trouble.",
                "For according to the number of your cities are your gods, Judah; and according to the number of the streets of Jerusalem have you set up altars to the shameful thing, even altars to burn incense to Baal.",
                "Therefore don\u2019t pray for this people, neither lift up cry nor prayer for them; for I will not hear them in the time that they cry to me because of their trouble.",
                "What has my beloved to do in my house, since she has worked lewdness with many, and the holy flesh is passed from you? when you do evil, then you rejoice.",
                "Yahweh called your name, A green olive tree, beautiful with goodly fruit: with the noise of a great tumult he has kindled fire on it, and its branches are broken.",
                "For Yahweh of Armies, who planted you, has pronounced evil against you, because of the evil of the house of Israel and of the house of Judah, which they have worked for themselves in provoking me to anger by offering incense to Baal.",
                "Yahweh gave me knowledge of it, and I knew it: then you showed me their doings.",
                "But I was like a gentle lamb that is led to the slaughter; and I didn\u2019t know that they had devised devices against me, saying, Let us destroy the tree with its fruit, and let us cut him off from the land of the living, that his name may be no more remembered.",
                "But, Yahweh of Armies, who judges righteously, who tests the heart and the mind, I shall see your vengeance on them; for to you have I revealed my cause.",
                "Therefore thus says Yahweh concerning the men of Anathoth, who seek your life, saying, You shall not prophesy in the name of Yahweh, that you not die by our hand;",
                "therefore thus says Yahweh of Armies, Behold, I will punish them: the young men shall die by the sword; their sons and their daughters shall die by famine;",
                "and there shall be no remnant to them: for I will bring evil on the men of Anathoth, even the year of their visitation."
            ],
            12: [
                "You are righteous, Yahweh, when I contend with you; yet I would reason the cause with you: why does the way of the wicked prosper? why are all they at ease who deal very treacherously?",
                "You have planted them, yes, they have taken root; they grow, yes, they bring forth fruit: you are near in their mouth, and far from their heart.",
                "But you, Yahweh, know me; you see me, and try my heart toward you: pull them out like sheep for the slaughter, and prepare them for the day of slaughter.",
                "How long shall the land mourn, and the herbs of the whole country wither? for the wickedness of those who dwell therein, the animals are consumed, and the birds; because they said, He shall not see our latter end.",
                "If you have run with the footmen, and they have wearied you, then how can you contend with horses? and though in a land of peace you are secure, yet how will you do in the pride of the Jordan?",
                "For even your brothers, and the house of your father, even they have dealt treacherously with you; even they have cried aloud after you: don\u2019t believe them, though they speak beautiful words to you.",
                "I have forsaken my house, I have cast off my heritage; I have given the dearly beloved of my soul into the hand of her enemies.",
                "My heritage has become to me as a lion in the forest: she has uttered her voice against me; therefore I have hated her.",
                "Is my heritage to me as a speckled bird of prey? are the birds of prey against her all around? Go, assemble all the animals of the field, bring them to devour.",
                "Many shepherds have destroyed my vineyard, they have trodden my portion under foot, they have made my pleasant portion a desolate wilderness.",
                "They have made it a desolation; it mourns to me, being desolate; the whole land is made desolate, because no man lays it to heart.",
                "Destroyers have come on all the bare heights in the wilderness; for the sword of Yahweh devours from the one end of the land even to the other end of the land: no flesh has peace.",
                "They have sown wheat, and have reaped thorns; they have put themselves to pain, and profit nothing: and you shall be ashamed of your fruits, because of the fierce anger of Yahweh.",
                "Thus says Yahweh against all my evil neighbors, who touch the inheritance which I have caused my people Israel to inherit: behold, I will pluck them up from off their land, and will pluck up the house of Judah from among them.",
                "It shall happen, after that I have plucked them up, I will return and have compassion on them; and I will bring them again, every man to his heritage, and every man to his land.",
                "It shall happen, if they will diligently learn the ways of my people, to swear by my name, As Yahweh lives; even as they taught my people to swear by Baal; then shall they be built up in the midst of my people.",
                "But if they will not hear, then will I pluck up that nation, plucking up and destroying it, says Yahweh."
            ],
            13: [
                "Thus says Yahweh to me, Go, and buy yourself a linen belt, and put it on your waist, and don\u2019t put it in water.",
                "So I bought a belt according to the word of Yahweh, and put it on my waist.",
                "The word of Yahweh came to me the second time, saying,",
                "Take the belt that you have bought, which is on your waist, and arise, go to the Euphrates, and hide it there in a cleft of the rock.",
                "So I went, and hid it by the Euphrates, as Yahweh commanded me.",
                "It happened after many days, that Yahweh said to me, Arise, go to the Euphrates, and take the belt from there, which I commanded you to hide there.",
                "Then I went to the Euphrates, and dug, and took the belt from the place where I had hidden it; and behold, the belt was marred, it was profitable for nothing.",
                "Then the word of Yahweh came to me, saying,",
                "Thus says Yahweh, In this way I will mar the pride of Judah, and the great pride of Jerusalem.",
                "This evil people, who refuse to hear my words, who walk in the stubbornness of their heart, and are gone after other gods to serve them, and to worship them, shall even be as this belt, which is profitable for nothing.",
                "For as the belt clings to the waist of a man, so have I caused to cling to me the whole house of Israel and the whole house of Judah, says Yahweh; that they may be to me for a people, and for a name, and for a praise, and for a glory: but they would not hear.",
                "Therefore you shall speak to them this word: Thus says Yahweh, the God of Israel, Every bottle shall be filled with wine: and they shall tell you, Do we not certainly know that every bottle shall be filled with wine?",
                "Then you shall tell them, Thus says Yahweh, Behold, I will fill all the inhabitants of this land, even the kings who sit on David\u2019s throne, and the priests, and the prophets, and all the inhabitants of Jerusalem, with drunkenness.",
                "I will dash them one against another, even the fathers and the sons together, says Yahweh: I will not pity, nor spare, nor have compassion, that I should not destroy them.",
                "Hear, and give ear; don\u2019t be proud; for Yahweh has spoken.",
                "Give glory to Yahweh your God, before he causes darkness, and before your feet stumble on the dark mountains, and, while you look for light, he turns it into the shadow of death, and makes it gross darkness.",
                "But if you will not hear it, my soul shall weep in secret for your pride; and my eye shall weep bitterly, and run down with tears, because Yahweh\u2019s flock is taken captive.",
                "Say to the king and to the queen mother, Humble yourselves, sit down; for your headdresses have come down, even the crown of your glory.",
                "The cities of the South are shut up, and there is none to open them: Judah is carried away captive, all of it; it is wholly carried away captive.",
                "Lift up your eyes, and see those who come from the north: where is the flock that was given you, your beautiful flock?",
                "What will you say, when he shall set over you as head those whom you have yourself taught to be friends to you? shall not sorrows take hold of you, as of a woman in travail?",
                "If you say in your heart, Why are these things come on me? for the greatness of your iniquity are your skirts uncovered, and your heels suffer violence.",
                "Can the Ethiopian change his skin, or the leopard his spots? then may you also do good, who are accustomed to do evil.",
                "Therefore will I scatter them, as the stubble that passes away, by the wind of the wilderness.",
                "This is your lot, the portion measured to you from me, says Yahweh; because you have forgotten me, and trusted in falsehood.",
                "Therefore will I also uncover your skirts on your face, and your shame shall appear.",
                "I have seen your abominations, even your adulteries, and your neighing, the lewdness of your prostitution, on the hills in the field. Woe to you, Jerusalem! You will not be made clean; how long shall it yet be?"
            ],
            14: [
                "The word of Yahweh that came to Jeremiah concerning the drought.",
                "Judah mourns, and its gates languish, they sit in black on the ground; and the cry of Jerusalem is gone up.",
                "Their nobles send their little ones to the waters: they come to the cisterns, and find no water; they return with their vessels empty; they are disappointed and confounded, and cover their heads.",
                "Because of the ground which is cracked, because no rain has been in the land, the plowmen are disappointed, they cover their heads.",
                "Yes, the hind also in the field calves, and forsakes her young, because there is no grass.",
                "The wild donkeys stand on the bare heights, they pant for air like jackals; their eyes fail, because there is no herbage.",
                "Though our iniquities testify against us, work for your name\u2019s sake, Yahweh; for our backslidings are many; we have sinned against you.",
                "You hope of Israel, its Savior in the time of trouble, why should you be as a foreigner in the land, and as a wayfaring man who turns aside to stay for a night?",
                "Why should you be like a scared man, as a mighty man who can\u2019t save? Yet you, Yahweh, are in the midst of us, and we are called by your name; don\u2019t leave us.",
                "Thus says Yahweh to this people, Even so have they loved to wander; they have not refrained their feet: therefore Yahweh does not accept them; now he will remember their iniquity, and visit their sins.",
                "Yahweh said to me, Don\u2019t pray for this people for their good.",
                "When they fast, I will not hear their cry; and when they offer burnt offering and meal offering, I will not accept them; but I will consume them by the sword, and by the famine, and by the pestilence.",
                "Then I said, Ah, Lord Yahweh! behold, the prophets tell them, You shall not see the sword, neither shall you have famine; but I will give you assured peace in this place.",
                "Then Yahweh said to me, The prophets prophesy lies in my name; I didn\u2019t send them, neither have I commanded them, neither spoke I to them: they prophesy to you a lying vision, and divination, and a thing of nothing, and the deceit of their own heart.",
                "Therefore thus says Yahweh concerning the prophets who prophesy in my name, and I didn\u2019t send them, yet they say, Sword and famine shall not be in this land: By sword and famine shall those prophets be consumed.",
                "The people to whom they prophesy shall be cast out in the streets of Jerusalem because of the famine and the sword; and they shall have none to bury them\u2014them, their wives, nor their sons, nor their daughters: for I will pour their wickedness on them.",
                "You shall say this word to them, Let my eyes run down with tears night and day, and let them not cease; for the virgin daughter of my people is broken with a great breach, with a very grievous wound.",
                "If I go forth into the field, then, behold, the slain with the sword! and if I enter into the city, then, behold, those who are sick with famine! for both the prophet and the priest go about in the land, and have no knowledge.",
                "Have you utterly rejected Judah? has your soul loathed Zion? why have you struck us, and there is no healing for us? We looked for peace, but no good came; and for a time of healing, and behold, dismay!",
                "We acknowledge, Yahweh, our wickedness, and the iniquity of our fathers; for we have sinned against you.",
                "Do not abhor us, for your name\u2019s sake; do not disgrace the throne of your glory: remember, don\u2019t break your covenant with us.",
                "Are there any among the vanities of the nations that can cause rain? or can the sky give showers? Aren\u2019t you he, Yahweh our God? therefore we will wait for you; for you have made all these things."
            ],
            15: [
                "Then Yahweh said to me, Though Moses and Samuel stood before me, yet my mind would not be toward this people: cast them out of my sight, and let them go forth.",
                "It shall happen, when they tell you, Where shall we go forth? Then you shall tell them, Thus says Yahweh: Such as are for death, to death; and such as are for the sword, to the sword; and such as are for the famine, to the famine; and such as are for captivity, to captivity.",
                "I will appoint over them four kinds, says Yahweh: the sword to kill, and the dogs to tear, and the birds of the sky, and the animals of the earth, to devour and to destroy.",
                "I will cause them to be tossed back and forth among all the kingdoms of the earth, because of Manasseh, the son of Hezekiah, king of Judah, for that which he did in Jerusalem.",
                "For who will have pity on you, Jerusalem? or who will bemoan you? or who will turn aside to ask of your welfare?",
                "You have rejected me, says Yahweh, you have gone backward: therefore have I stretched out my hand against you, and destroyed you; I am weary with repenting.",
                "I have winnowed them with a fan in the gates of the land; I have bereaved them of children, I have destroyed my people; they didn\u2019t return from their ways.",
                "Their widows are increased to me above the sand of the seas; I have brought on them against the mother of the young men a destroyer at noonday: I have caused anguish and terrors to fall on her suddenly.",
                "She who has borne seven languishes; she has given up the spirit; her sun is gone down while it was yet day; she has been disappointed and confounded: and their residue will I deliver to the sword before their enemies, says Yahweh.",
                "Woe is me, my mother, that you have borne me a man of strife and a man of contention to the whole earth! I have not lent, neither have men lent to me; yet everyone of them curses me.",
                "Yahweh said, Most certainly I will strengthen you for good; most certainly I will cause the enemy to make supplication to you in the time of evil and in the time of affliction.",
                "Can one break iron, even iron from the north, and brass?",
                "Your substance and your treasures will I give for a spoil without price, and that for all your sins, even in all your borders.",
                "I will make them to pass with your enemies into a land which you don\u2019t know; for a fire is kindled in my anger, which shall burn on you.",
                "Yahweh, you know; remember me, and visit me, and avenge me of my persecutors; don\u2019t take me away in your longsuffering: know that for your sake I have suffered reproach.",
                "Your words were found, and I ate them; and your words were to me a joy and the rejoicing of my heart: for I am called by your name, Yahweh, God of Armies.",
                "I didn\u2019t sit in the assembly of those who make merry, nor rejoiced; I sat alone because of your hand; for you have filled me with indignation.",
                "Why is my pain perpetual, and my wound incurable, which refuses to be healed? Will you indeed be to me as a deceitful brook, as waters that fail?",
                "Therefore thus says Yahweh, If you return, then will I bring you again, that you may stand before me; and if you take forth the precious from the vile, you shall be as my mouth: they shall return to you, but you shall not return to them.",
                "I will make you to this people a fortified bronze wall; and they shall fight against you, but they shall not prevail against you; for I am with you to save you and to deliver you, says Yahweh.",
                "I will deliver you out of the hand of the wicked, and I will redeem you out of the hand of the terrible."
            ],
            16: [
                "The word of Yahweh came also to me, saying,",
                "You shall not take a wife, neither shall you have sons or daughters, in this place.",
                "For thus says Yahweh concerning the sons and concerning the daughters who are born in this place, and concerning their mothers who bore them, and concerning their fathers who became their father in this land:",
                "They shall die grievous deaths: they shall not be lamented, neither shall they be buried; they shall be as dung on the surface of the ground; and they shall be consumed by the sword, and by famine; and their dead bodies shall be food for the birds of the sky, and for the animals of the earth.",
                "For thus says Yahweh, Don\u2019t enter into the house of mourning, neither go to lament, neither bemoan them; for I have taken away my peace from this people, says Yahweh, even loving kindness and tender mercies.",
                "Both great and small shall die in this land; they shall not be buried, neither shall men lament for them, nor cut themselves, nor make themselves bald for them;",
                "neither shall men break bread for them in mourning, to comfort them for the dead; neither shall men give them the cup of consolation to drink for their father or for their mother.",
                "You shall not go into the house of feasting to sit with them, to eat and to drink.",
                "For thus says Yahweh of Armies, the God of Israel: Behold, I will cause to cease out of this place, before your eyes and in your days, the voice of mirth and the voice of gladness, the voice of the bridegroom and the voice of the bride.",
                "It shall happen, when you shall show this people all these words, and they shall tell you, Why has Yahweh pronounced all this great evil against us? or what is our iniquity? or what is our sin that we have committed against Yahweh our God?",
                "Then you shall tell them, Because your fathers have forsaken me, says Yahweh, and have walked after other gods, and have served them, and have worshiped them, and have forsaken me, and have not kept my law;",
                "and you have done evil more than your fathers; for, behold, you walk every one after the stubbornness of his evil heart, so that you don\u2019t listen to me:",
                "therefore will I cast you forth out of this land into the land that you have not known, neither you nor your fathers; and there you shall serve other gods day and night; for I will show you no favor.",
                "Therefore, behold, the days come, says Yahweh, that it shall no more be said, As Yahweh lives, who brought up the children of Israel out of the land of Egypt;",
                "but, As Yahweh lives, who brought up the children of Israel from the land of the north, and from all the countries where he had driven them. I will bring them again into their land that I gave to their fathers.",
                "Behold, I will send for many fishermen, says Yahweh, and they shall fish them up; and afterward I will send for many hunters, and they shall hunt them from every mountain, and from every hill, and out of the clefts of the rocks.",
                "For my eyes are on all their ways; they are not hidden from my face, neither is their iniquity concealed from my eyes.",
                "First I will recompense their iniquity and their sin double, because they have polluted my land with the carcasses of their detestable things, and have filled my inheritance with their abominations.",
                "Yahweh, my strength, and my stronghold, and my refuge in the day of affliction, to you shall the nations come from the ends of the earth, and shall say, Our fathers have inherited nothing but lies, vanity and things in which there is no profit.",
                "Shall a man make to himself gods, which yet are no gods?",
                "Therefore, behold, I will cause them to know, this once will I cause them to know my hand and my might; and they shall know that my name is Yahweh."
            ],
            17: [
                "The sin of Judah is written with a pen of iron, and with the point of a diamond: it is engraved on the tablet of their heart, and on the horns of your altars;",
                "while their children remember their altars and their Asherim by the green trees on the high hills.",
                "My mountain in the field, I will give your substance and all your treasures for a spoil, and your high places, because of sin, throughout all your borders.",
                "You, even of yourself, shall discontinue from your heritage that I gave you; and I will cause you to serve your enemies in the land which you don\u2019t know: for you have kindled a fire in my anger which shall burn forever.",
                "Thus says Yahweh: Cursed is the man who trusts in man, and makes flesh his arm, and whose heart departs from Yahweh.",
                "For he shall be like the heath in the desert, and shall not see when good comes, but shall inhabit the parched places in the wilderness, a salt land and not inhabited.",
                "Blessed is the man who trusts in Yahweh, and whose trust Yahweh is.",
                "For he shall be as a tree planted by the waters, who spreads out its roots by the river, and shall not fear when heat comes, but its leaf shall be green; and shall not be careful in the year of drought, neither shall cease from yielding fruit.",
                "The heart is deceitful above all things, and it is exceedingly corrupt: who can know it?",
                "I, Yahweh, search the mind, I try the heart, even to give every man according to his ways, according to the fruit of his doings.",
                "As the partridge that sits on eggs which she has not laid, so is he who gets riches, and not by right; in the midst of his days they shall leave him, and at his end he shall be a fool.",
                "A glorious throne, set on high from the beginning, is the place of our sanctuary.",
                "Yahweh, the hope of Israel, all who forsake you shall be disappointed. Those who depart from me shall be written in the earth, because they have forsaken Yahweh, the spring of living waters.",
                "Heal me, O Yahweh, and I shall be healed; save me, and I shall be saved: for you are my praise.",
                "Behold, they tell me, Where is the word of Yahweh? let it come now.",
                "As for me, I have not hurried from being a shepherd after you; neither have I desired the woeful day; you know: that which came out of my lips was before your face.",
                "Don\u2019t be a terror to me: you are my refuge in the day of evil.",
                "Let them be disappointed who persecute me, but let not me be disappointed; let them be dismayed, but don\u2019t let me be dismayed; bring on them the day of evil, and destroy them with double destruction.",
                "Thus said Yahweh to me: Go, and stand in the gate of the children of the people, through which the kings of Judah come in, and by which they go out, and in all the gates of Jerusalem;",
                "and tell them, Hear the word of Yahweh, you kings of Judah, and all Judah, and all the inhabitants of Jerusalem, that enter in by these gates:",
                "Thus says Yahweh, Take heed to yourselves, and bear no burden on the Sabbath day, nor bring it in by the gates of Jerusalem;",
                "neither carry forth a burden out of your houses on the Sabbath day holy, neither do any work: but make the Sabbath day, as I commanded your fathers.",
                "But they didn\u2019t listen, neither turn their ear, but made their neck stiff, that they might not hear, and might not receive instruction.",
                "It shall happen, if you diligently listen to me, says Yahweh, to bring in no burden through the gates of this city on the Sabbath day, but to make the Sabbath day holy, to do no work therein;",
                "then shall there enter in by the gates of this city kings and princes sitting on the throne of David, riding in chariots and on horses, they, and their princes, the men of Judah, and the inhabitants of Jerusalem; and this city shall remain forever.",
                "They shall come from the cities of Judah, and from the places around Jerusalem, and from the land of Benjamin, and from the lowland, and from the hill country, and from the South, bringing burnt offerings, and sacrifices, and meal offerings, and frankincense, and bringing sacrifices of thanksgiving, to the house of Yahweh.",
                "But if you will not listen to me to make the Sabbath day holy, and not to bear a burden and enter in at the gates of Jerusalem on the Sabbath day; then will I kindle a fire in its gates, and it shall devour the palaces of Jerusalem, and it shall not be quenched."
            ],
            18: [
                "The word which came to Jeremiah from Yahweh, saying,",
                "Arise, and go down to the potter\u2019s house, and there I will cause you to hear my words.",
                "Then I went down to the potter\u2019s house, and behold, he was making a work on the wheels.",
                "When the vessel that he made of the clay was marred in the hand of the potter, he made it again another vessel, as seemed good to the potter to make it.",
                "Then the word of Yahweh came to me, saying,",
                "House of Israel, can\u2019t I do with you as this potter? says Yahweh. Behold, as the clay in the potter\u2019s hand, so are you in my hand, house of Israel.",
                "At what instant I shall speak concerning a nation, and concerning a kingdom, to pluck up and to break down and to destroy it;",
                "if that nation, concerning which I have spoken, turn from their evil, I will repent of the evil that I thought to do to them.",
                "At what instant I shall speak concerning a nation, and concerning a kingdom, to build and to plant it;",
                "if they do that which is evil in my sight, that they not obey my voice, then I will repent of the good, with which I said I would benefit them.",
                "Now therefore, speak to the men of Judah, and to the inhabitants of Jerusalem, saying, Thus says Yahweh: Behold, I frame evil against you, and devise a device against you: return you now everyone from his evil way, and amend your ways and your doings.",
                "But they say, It is in vain; for we will walk after our own devices, and we will do everyone after the stubbornness of his evil heart.",
                "Therefore thus says Yahweh: Ask now among the nations, who has heard such things; the virgin of Israel has done a very horrible thing.",
                "Shall the snow of Lebanon fail from the rock of the field? Shall the cold waters that flow down from afar be dried up?",
                "For my people have forgotten me, they have burned incense to false gods; and they have been made to stumble in their ways, in the ancient paths, to walk in byways, in a way not built up;",
                "to make their land an astonishment, and a perpetual hissing; everyone who passes thereby shall be astonished, and shake his head.",
                "I will scatter them as with an east wind before the enemy; I will show them the back, and not the face, in the day of their calamity.",
                "Then they said, Come, and let us devise devices against Jeremiah; for the law shall not perish from the priest, nor counsel from the wise, nor the word from the prophet. Come, and let us strike him with the tongue, and let us not give heed to any of his words.",
                "Give heed to me, Yahweh, and listen to the voice of those who contend with me.",
                "Shall evil be recompensed for good? for they have dug a pit for my soul. Remember how I stood before you to speak good for them, to turn away your wrath from them.",
                "Therefore deliver up their children to the famine, and give them over to the power of the sword; and let their wives become childless, and widows; and let their men be slain of death, and their young men struck of the sword in battle.",
                "Let a cry be heard from their houses, when you shall bring a troop suddenly on them; for they have dug a pit to take me, and hid snares for my feet.",
                "Yet, Yahweh, you know all their counsel against me to kill me; don\u2019t forgive their iniquity, neither blot out their sin from your sight; but let them be overthrown before you; deal you with them in the time of your anger."
            ],
            19: [
                "Thus said Yahweh, Go, and buy a potter\u2019s earthen bottle, and take some of the elders of the people, and of the elders of the priests;",
                "and go forth to the valley of the son of Hinnom, which is by the entry of the gate Harsith, and proclaim there the words that I shall tell you;",
                "and say, Hear the word of Yahweh, kings of Judah, and inhabitants of Jerusalem: thus says Yahweh of Armies, the God of Israel, Behold, I will bring evil on this place, which whoever hears, his ears shall tingle.",
                "Because they have forsaken me, and have estranged this place, and have burned incense in it to other gods, that they didn\u2019t know, they and their fathers and the kings of Judah; and have filled this place with the blood of innocents,",
                "and have built the high places of Baal, to burn their sons in the fire for burnt offerings to Baal; which I didn\u2019t command, nor spoke it, neither came it into my mind:",
                "therefore, behold, the days come, says Yahweh, that this place shall no more be called Topheth, nor The valley of the son of Hinnom, but The valley of Slaughter.",
                "I will make void the counsel of Judah and Jerusalem in this place; and I will cause them to fall by the sword before their enemies, and by the hand of those who seek their life: and their dead bodies will I give to be food for the birds of the sky, and for the animals of the earth.",
                "I will make this city an astonishment, and a hissing; everyone who passes thereby shall be astonished and hiss because of all its plagues.",
                "I will cause them to eat the flesh of their sons and the flesh of their daughters; and they shall eat everyone the flesh of his friend, in the siege and in the distress, with which their enemies, and those who seek their life, shall distress them.",
                "Then you shall break the bottle in the sight of the men who go with you,",
                "and shall tell them, Thus says Yahweh of Armies: Even so will I break this people and this city, as one breaks a potter\u2019s vessel, that can\u2019t be made whole again; and they shall bury in Topheth, until there is no place to bury.",
                "Thus will I do to this place, says Yahweh, and to its inhabitants, even making this city as Topheth:",
                "and the houses of Jerusalem, and the houses of the kings of Judah, which are defiled, shall be as the place of Topheth, even all the houses on whose roofs they have burned incense to all the army of the sky, and have poured out drink offerings to other gods.",
                "Then came Jeremiah from Topheth, where Yahweh had sent him to prophesy; and he stood in the court of Yahweh\u2019s house, and said to all the people:",
                "Thus says Yahweh of Armies, the God of Israel, Behold, I will bring on this city and on all its towns all the evil that I have pronounced against it; because they have made their neck stiff, that they may not hear my words."
            ],
            20: [
                "Now Pashhur, the son of Immer the priest, who was chief officer in the house of Yahweh, heard Jeremiah prophesying these things.",
                "Then Pashhur struck Jeremiah the prophet, and put him in the stocks that were in the upper gate of Benjamin, which was in the house of Yahweh.",
                "It happened on the next day, that Pashhur brought forth Jeremiah out of the stocks. Then Jeremiah said to him, Yahweh has not called your name Pashhur, but Magormissabib.",
                "For thus says Yahweh, Behold, I will make you a terror to yourself, and to all your friends; and they shall fall by the sword of their enemies, and your eyes shall see it; and I will give all Judah into the hand of the king of Babylon, and he shall carry them captive to Babylon, and shall kill them with the sword.",
                "Moreover I will give all the riches of this city, and all its gains, and all the precious things of it, yes, all the treasures of the kings of Judah will I give into the hand of their enemies; and they shall make them a prey, and take them, and carry them to Babylon.",
                "You, Pashhur, and all who dwell in your house shall go into captivity; and you shall come to Babylon, and there you shall die, and there you shall be buried, you, and all your friends, to whom you have prophesied falsely.",
                "Yahweh, you have persuaded me, and I was persuaded; you are stronger than I, and have prevailed: I am become a laughing-stock all the day, every one mocks me.",
                "For as often as I speak, I cry out; I cry, Violence and destruction! because the word of Yahweh is made a reproach to me, and a derision, all the day.",
                "If I say, I will not make mention of him, nor speak any more in his name, then there is in my heart as it were a burning fire shut up in my bones, and I am weary with forbearing, and I can\u2019t.",
                "For I have heard the defaming of many, terror on every side. Denounce, and we will denounce him, say all my familiar friends, those who watch for my fall; perhaps he will be persuaded, and we shall prevail against him, and we shall take our revenge on him.",
                "But Yahweh is with me as an awesome mighty one: therefore my persecutors shall stumble, and they shall not prevail; they shall be utterly disappointed, because they have not dealt wisely, even with an everlasting dishonor which shall never be forgotten.",
                "But, Yahweh of Armies, who tests the righteous, who sees the heart and the mind, let me see your vengeance on them; for to you have I revealed my cause.",
                "Sing to Yahweh, praise Yahweh; for he has delivered the soul of the needy from the hand of evildoers.",
                "Cursed is the day in which I was born: don\u2019t let the day in which my mother bore me be blessed.",
                "Cursed is the man who brought news to my father, saying, A boy is born to you; making him very glad.",
                "Let that man be as the cities which Yahweh overthrew, and didn\u2019t repent: and let him hear a cry in the morning, and shouting at noontime;",
                "because he didn\u2019t kill me from the womb; and so my mother would have been my grave, and her womb always great.",
                "Why came I forth out of the womb to see labor and sorrow, that my days should be consumed with shame?"
            ],
            21: [
                "The word which came to Jeremiah from Yahweh, when king Zedekiah sent to him Pashhur the son of Malchijah, and Zephaniah the son of Maaseiah, the priest, saying,",
                "Please inquire of Yahweh for us; for Nebuchadnezzar king of Babylon makes war against us: perhaps Yahweh will deal with us according to all his wondrous works, that he may go up from us.",
                "Then Jeremiah said to them, You shall tell Zedekiah:",
                "Thus says Yahweh, the God of Israel, Behold, I will turn back the weapons of war that are in your hands, with which you fight against the king of Babylon, and against the Chaldeans who besiege you, without the walls; and I will gather them into the midst of this city.",
                "I myself will fight against you with an outstretched hand and with a strong arm, even in anger, and in wrath, and in great indignation.",
                "I will strike the inhabitants of this city, both man and animal: they shall die of a great pestilence.",
                "Afterward, says Yahweh, I will deliver Zedekiah king of Judah, and his servants, and the people, even such as are left in this city from the pestilence, from the sword, and from the famine, into the hand of Nebuchadnezzar king of Babylon, and into the hand of their enemies, and into the hand of those who seek their life: and he shall strike them with the edge of the sword; he shall not spare them, neither have pity, nor have mercy.",
                "To this people you shall say, Thus says Yahweh: Behold, I set before you the way of life and the way of death.",
                "He who remains in this city shall die by the sword, and by the famine, and by the pestilence; but he who goes out, and passes over to the Chaldeans who besiege you, he shall live, and his life shall be to him for a prey.",
                "For I have set my face on this city for evil, and not for good, says Yahweh: it shall be given into the hand of the king of Babylon, and he shall burn it with fire.",
                "Touching the house of the king of Judah, hear the word of Yahweh:",
                "House of David, thus says Yahweh, Execute justice in the morning, and deliver him who is robbed out of the hand of the oppressor, lest my wrath go forth like fire, and burn so that none can quench it, because of the evil of your doings.",
                "Behold, I am against you, O inhabitant of the valley, and of the rock of the plain, says Yahweh; you that say, Who shall come down against us? or who shall enter into our habitations?",
                "I will punish you according to the fruit of your doings, says Yahweh; and I will kindle a fire in her forest, and it shall devour all that is around her."
            ],
            22: [
                "Thus said Yahweh: Go down to the house of the king of Judah, and speak there this word,",
                "Say, Hear the word of Yahweh, king of Judah, who sits on the throne of David, you, and your servants, and your people who enter in by these gates.",
                "Thus says Yahweh: Execute justice and righteousness, and deliver him who is robbed out of the hand of the oppressor: and do no wrong, do no violence, to the foreigner, the fatherless, nor the widow; neither shed innocent blood in this place.",
                "For if you do this thing indeed, then shall there enter in by the gates of this house kings sitting on the throne of David, riding in chariots and on horses, he, and his servants, and his people.",
                "But if you will not hear these words, I swear by myself, says Yahweh, that this house shall become a desolation.",
                "For thus says Yahweh concerning the house of the king of Judah: You are Gilead to me, the head of Lebanon. Yet surely I will make you a wilderness, cities which are not inhabited.",
                "I will prepare destroyers against you, everyone with his weapons; and they shall cut down your choice cedars, and cast them into the fire.",
                "Many nations shall pass by this city, and they shall say every man to his neighbor, Why has Yahweh done thus to this great city?",
                "Then they shall answer, Because they forsook the covenant of Yahweh their God, and worshiped other gods, and served them.",
                "Don\u2019t weep for the dead, neither bemoan him; but weep bitterly for him who goes away; for he shall return no more, nor see his native country.",
                "For thus says Yahweh touching Shallum the son of Josiah, king of Judah, who reigned instead of Josiah his father, and who went forth out of this place: He shall not return there any more.",
                "But in the place where they have led him captive, there shall he die, and he shall see this land no more.",
                "Woe to him who builds his house by unrighteousness, and his rooms by injustice; who uses his neighbor\u2019s service without wages, and doesn\u2019t give him his hire;",
                "who says, I will build me a wide house and spacious rooms, and cuts him out windows; and it is ceiling with cedar, and painted with vermilion.",
                "Shall you reign, because you strive to excel in cedar? Didn\u2019t your father eat and drink, and do justice and righteousness? then it was well with him.",
                "He judged the cause of the poor and needy; then it was well. Wasn\u2019t this to know me? says Yahweh.",
                "But your eyes and your heart are not but for your covetousness, and for shedding innocent blood, and for oppression, and for violence, to do it.",
                "Therefore thus says Yahweh concerning Jehoiakim the son of Josiah, king of Judah: they shall not lament for him, saying, Ah my brother! or, Ah sister! They shall not lament for him, saying Ah lord! or, Ah his glory!",
                "He shall be buried with the burial of a donkey, drawn and cast forth beyond the gates of Jerusalem.",
                "Go up to Lebanon, and cry; and lift up your voice in Bashan, and cry from Abarim; for all your lovers are destroyed.",
                "I spoke to you in your prosperity; but you said, I will not hear. This has been your way from your youth, that you didn\u2019t obey my voice.",
                "The wind shall feed all your shepherds, and your lovers shall go into captivity: surely then you will be ashamed and confounded for all your wickedness.",
                "Inhabitant of Lebanon, who makes your nest in the cedars, how greatly to be pitied you will be when pangs come on you, the pain as of a woman in travail!",
                "As I live, says Yahweh, though Coniah the son of Jehoiakim king of Judah were the signet on my right hand, yet would I pluck you there;",
                "and I will give you into the hand of those who seek your life, and into the hand of them of whom you are afraid, even into the hand of Nebuchadnezzar king of Babylon, and into the hand of the Chaldeans.",
                "I will cast you out, and your mother who bore you, into another country, where you were not born; and there you will die.",
                "But to the land whereunto their soul longs to return, there shall they not return.",
                "Is this man Coniah a despised broken vessel? is he a vessel in which none delights? why are they cast out, he and his seed, and are cast into the land which they don\u2019t know?",
                "O earth, earth, earth, hear the word of Yahweh.",
                "Thus says Yahweh, Write you this man childless, a man who shall not prosper in his days; for no more shall a man of his seed prosper, sitting on the throne of David, and ruling in Judah."
            ],
            23: [
                "Woe to the shepherds who destroy and scatter the sheep of my pasture! says Yahweh.",
                "Therefore thus says Yahweh, the God of Israel, against the shepherds who feed my people: You have scattered my flock, and driven them away, and have not visited them; behold, I will visit on you the evil of your doings, says Yahweh.",
                "I will gather the remnant of my flock out of all the countries where I have driven them, and will bring them again to their folds; and they shall be fruitful and multiply.",
                "I will set up shepherds over them, who shall feed them; and they shall fear no more, nor be dismayed, neither shall any be lacking, says Yahweh.",
                "Behold, the days come, says Yahweh, that I will raise to David a righteous Branch, and he shall reign as king and deal wisely, and shall execute justice and righteousness in the land.",
                "In his days Judah shall be saved, and Israel shall dwell safely; and this is his name by which he shall be called: Yahweh our righteousness.",
                "Therefore, behold, the days come, says Yahweh, that they shall no more say, As Yahweh lives, who brought up the children of Israel out of the land of Egypt;",
                "but, As Yahweh lives, who brought up and who led the seed of the house of Israel out of the north country, and from all the countries where I had driven them. They shall dwell in their own land.",
                "Concerning the prophets. My heart within me is broken, all my bones shake; I am like a drunken man, and like a man whom wine has overcome, because of Yahweh, and because of his holy words.",
                "For the land is full of adulterers; for because of swearing the land mourns; the pastures of the wilderness are dried up. Their course is evil, and their might is not right;",
                "for both prophet and priest are profane; yes, in my house have I found their wickedness, says Yahweh.",
                "Therefore their way shall be to them as slippery places in the darkness: they shall be driven on, and fall therein; for I will bring evil on them, even the year of their visitation, says Yahweh.",
                "I have seen folly in the prophets of Samaria; they prophesied by Baal, and caused my people Israel to err.",
                "In the prophets of Jerusalem also I have seen a horrible thing: they commit adultery, and walk in lies; and they strengthen the hands of evildoers, so that none does return from his wickedness: they are all of them become to me as Sodom, and its inhabitants as Gomorrah.",
                "Therefore thus says Yahweh of Armies concerning the prophets: Behold, I will feed them with wormwood, and make them drink the water of gall; for from the prophets of Jerusalem is ungodliness gone forth into all the land.",
                "Thus says Yahweh of Armies, Don\u2019t listen to the words of the prophets who prophesy to you: they teach you vanity; they speak a vision of their own heart, and not out of the mouth of Yahweh.",
                "They say continually to those who despise me, Yahweh has said, You shall have peace; and to everyone who walks in the stubbornness of his own heart they say, No evil shall come on you.",
                "For who has stood in the council of Yahweh, that he should perceive and hear his word? who has marked my word, and heard it?",
                "Behold, the storm of Yahweh, his wrath, has gone forth. Yes, a whirling storm. It shall burst on the head of the wicked.",
                "The anger of Yahweh shall not return, until he has executed, and until he have performed the intents of his heart: in the latter days you shall understand it perfectly.",
                "I sent not these prophets, yet they ran: I didn\u2019t speak to them, yet they prophesied.",
                "But if they had stood in my council, then had they caused my people to hear my words, and had turned them from their evil way, and from the evil of their doings.",
                "Am I a God at hand, says Yahweh, and not a God afar off?",
                "Can any hide himself in secret places so that I shall not see him? says Yahweh. Don\u2019t I fill heaven and earth? says Yahweh.",
                "I have heard what the prophets have said, who prophesy lies in my name, saying, I have dreamed, I have dreamed.",
                "How long shall this be in the heart of the prophets who prophesy lies, even the prophets of the deceit of their own heart?",
                "who think to cause my people to forget my name by their dreams which they tell every man to his neighbor, as their fathers forgot my name for Baal.",
                "The prophet who has a dream, let him tell a dream; and he who has my word, let him speak my word faithfully. What is the straw to the wheat? says Yahweh.",
                "Isn\u2019t my word like fire? says Yahweh; and like a hammer that breaks the rock in pieces?",
                "Therefore, behold, I am against the prophets, says Yahweh, who steal my words everyone from his neighbor.",
                "Behold, I am against the prophets, says Yahweh, who use their tongues, and say, He says.",
                "Behold, I am against those who prophesy lying dreams, says Yahweh, and do tell them, and cause my people to err by their lies, and by their vain boasting: yet I didn\u2019t send them, nor commanded them; neither do they profit this people at all, says Yahweh.",
                "When this people, or the prophet, or a priest, shall ask you, saying, What is the burden of Yahweh? Then you shall tell them, What burden! I will cast you off, says Yahweh.",
                "As for the prophet, and the priest, and the people, who shall say, The burden of Yahweh, I will even punish that man and his house.",
                "You shall say everyone to his neighbor, and everyone to his brother, What has Yahweh answered? and, What has Yahweh spoken?",
                "You shall mention the burden of Yahweh no more: for every man\u2019s own word shall be his burden; for you have perverted the words of the living God, of Yahweh of Armies our God.",
                "You shall say to the prophet, What has Yahweh answered you? and, What has Yahweh spoken?",
                "But if you say, The burden of Yahweh; therefore thus says Yahweh: Because you say this word, The burden of Yahweh, and I have sent to you, saying, You shall not say, The burden of Yahweh;",
                "therefore, behold, I will utterly forget you, and I will cast you off, and the city that I gave to you and to your fathers, away from my presence:",
                "and I will bring an everlasting reproach on you, and a perpetual shame, which shall not be forgotten."
            ],
            24: [
                "Yahweh showed me, and behold, two baskets of figs set before the temple of Yahweh, after that Nebuchadnezzar king of Babylon had carried away captive Jeconiah the son of Jehoiakim, king of Judah, and the princes of Judah, with the craftsmen and smiths, from Jerusalem, and had brought them to Babylon.",
                "One basket had very good figs, like the figs that are first-ripe; and the other basket had very bad figs, which could not be eaten, they were so bad.",
                "Then Yahweh said to me, What do you see, Jeremiah? I said, Figs; the good figs, very good; and the bad, very bad, that can\u2019t be eaten, they are so bad.",
                "The word of Yahweh came to me, saying,",
                "Thus says Yahweh, the God of Israel: Like these good figs, so will I regard the captives of Judah, whom I have sent out of this place into the land of the Chaldeans, for good.",
                "For I will set my eyes on them for good, and I will bring them again to this land: and I will build them, and not pull them down; and I will plant them, and not pluck them up.",
                "I will give them a heart to know me, that I am Yahweh: and they shall be my people, and I will be their God; for they shall return to me with their whole heart.",
                "As the bad figs, which can\u2019t be eaten, they are so bad, surely thus says Yahweh, So will I give up Zedekiah the king of Judah, and his princes, and the residue of Jerusalem, who remain in this land, and those who dwell in the land of Egypt,",
                "I will even give them up to be tossed back and forth among all the kingdoms of the earth for evil; to be a reproach and a proverb, a taunt and a curse, in all places where I shall drive them.",
                "I will send the sword, the famine, and the pestilence, among them, until they be consumed from off the land that I gave to them and to their fathers."
            ],
            25: [
                "The word that came to Jeremiah concerning all the people of Judah, in the fourth year of Jehoiakim the son of Josiah, king of Judah (the same was the first year of Nebuchadnezzar king of Babylon),",
                "which Jeremiah the prophet spoke to all the people of Judah, and to all the inhabitants of Jerusalem, saying:",
                "From the thirteenth year of Josiah the son of Amon, king of Judah, even to this day, these twenty-three years, the word of Yahweh has come to me, and I have spoken to you, rising up early and speaking; but you have not listened.",
                "Yahweh has sent to you all his servants the prophets, rising up early and sending them (but you have not listened, nor inclined your ear to hear)",
                "saying, Return now everyone from his evil way, and from the evil of your doings, and dwell in the land that Yahweh has given to you and to your fathers, from of old and even forevermore;",
                "and don\u2019t go after other gods to serve them or worship them, and don\u2019t provoke me to anger with the work of your hands; and I will do you no harm.",
                "Yet you have not listened to me, says Yahweh; that you may provoke me to anger with the work of your hands to your own hurt.",
                "Therefore thus says Yahweh of Armies: Because you have not heard my words,",
                "behold, I will send and take all the families of the north, says Yahweh, and I will send to Nebuchadnezzar the king of Babylon, my servant, and will bring them against this land, and against its inhabitants, and against all these nations around; and I will utterly destroy them, and make them an astonishment, and a hissing, and perpetual desolations.",
                "Moreover I will take from them the voice of mirth and the voice of gladness, the voice of the bridegroom and the voice of the bride, the sound of the millstones, and the light of the lamp.",
                "This whole land shall be a desolation, and an astonishment; and these nations shall serve the king of Babylon seventy years.",
                "It shall happen, when seventy years are accomplished, that I will punish the king of Babylon, and that nation, says Yahweh, for their iniquity, and the land of the Chaldeans; and I will make it desolate forever.",
                "I will bring on that land all my words which I have pronounced against it, even all that is written in this book, which Jeremiah has prophesied against all the nations.",
                "For many nations and great kings shall make bondservants of them, even of them; and I will recompense them according to their deeds, and according to the work of their hands.",
                "For thus says Yahweh, the God of Israel, to me: take this cup of the wine of wrath at my hand, and cause all the nations, to whom I send you, to drink it.",
                "They shall drink, and reel back and forth, and be mad, because of the sword that I will send among them.",
                "Then took I the cup at Yahweh\u2019s hand, and made all the nations to drink, to whom Yahweh had sent me:",
                "Jerusalem, and the cities of Judah, and its kings, and its princes, to make them a desolation, an astonishment, a hissing, and a curse, as it is this day;",
                "Pharaoh king of Egypt, and his servants, and his princes, and all his people;",
                "and all the mixed people, and all the kings of the land of the Uz, and all the kings of the Philistines, and Ashkelon, and Gaza, and Ekron, and the remnant of Ashdod;",
                "Edom, and Moab, and the children of Ammon;",
                "and all the kings of Tyre, and all the kings of Sidon, and the kings of the isle which is beyond the sea;",
                "Dedan, and Tema, and Buz, and all who have the corners of their beard cut off;",
                "and all the kings of Arabia, and all the kings of the mixed people who dwell in the wilderness;",
                "and all the kings of Zimri, and all the kings of Elam, and all the kings of the Medes;",
                "and all the kings of the north, far and near, one with another; and all the kingdoms of the world, which are on the surface of the earth: and the king of Sheshach shall drink after them.",
                "You shall tell them, Thus says Yahweh of Armies, the God of Israel: Drink, and be drunk, vomit, fall, and rise no more, because of the sword which I will send among you.",
                "It shall be, if they refuse to take the cup at your hand to drink, then you shall tell them, Thus says Yahweh of Armies: You shall surely drink.",
                "For, behold, I begin to work evil at the city which is called by my name; and should you be utterly unpunished? You shall not be unpunished; for I will call for a sword on all the inhabitants of the earth, says Yahweh of Armies.",
                "Therefore prophesy you against them all these words, and tell them, Yahweh will roar from on high, and utter his voice from his holy habitation; he will mightily roar against his fold; he will give a shout, as those who tread grapes, against all the inhabitants of the earth.",
                "A noise shall come even to the end of the earth; for Yahweh has a controversy with the nations; he will enter into judgment with all flesh: as for the wicked, he will give them to the sword, says Yahweh.",
                "Thus says Yahweh of Armies, Behold, evil shall go forth from nation to nation, and a great storm shall be raised up from the uttermost parts of the earth.",
                "The slain of Yahweh shall be at that day from one end of the earth even to the other end of the earth: they shall not be lamented, neither gathered, nor buried; they shall be dung on the surface of the ground.",
                "Wail, you shepherds, and cry; and wallow in dust, you principal of the flock; for the days of your slaughter and of your dispersions are fully come, and you shall fall like a goodly vessel.",
                "The shepherds shall have no way to flee, nor the principal of the flock to escape.",
                "A voice of the cry of the shepherds, and the wailing of the principal of the flock! for Yahweh lays waste their pasture.",
                "The peaceable folds are brought to silence because of the fierce anger of Yahweh.",
                "He has left his covert, as the lion; for their land has become an astonishment because of the fierceness of the oppression, and because of his fierce anger."
            ],
            26: [
                "In the beginning of the reign of Jehoiakim the son of Josiah, king of Judah, came this word from Yahweh, saying,",
                "Thus says Yahweh: Stand in the court of Yahweh\u2019s house, and speak to all the cities of Judah, which come to worship in Yahweh\u2019s house, all the words that I command you to speak to them; don\u2019t diminish a word.",
                "It may be they will listen, and turn every man from his evil way; that I may repent me of the evil which I purpose to do to them because of the evil of their doings.",
                "You shall tell them, Thus says Yahweh: If you will not listen to me, to walk in my law, which I have set before you,",
                "to listen to the words of my servants the prophets, whom I send to you, even rising up early and sending them, but you have not listened;",
                "then will I make this house like Shiloh, and will make this city a curse to all the nations of the earth.",
                "The priests and the prophets and all the people heard Jeremiah speaking these words in the house of Yahweh.",
                "It happened, when Jeremiah had made an end of speaking all that Yahweh had commanded him to speak to all the people, that the priests and the prophets and all the people laid hold on him, saying, You shall surely die.",
                "Why have you prophesied in the name of Yahweh, saying, This house shall be like Shiloh, and this city shall be desolate, without inhabitant? All the people were gathered to Jeremiah in the house of Yahweh.",
                "When the princes of Judah heard these things, they came up from the king\u2019s house to the house of Yahweh; and they sat in the entry of the new gate of Yahweh\u2019s house.",
                "Then spoke the priests and the prophets to the princes and to all the people, saying, This man is worthy of death; for he has prophesied against this city, as you have heard with your ears.",
                "Then spoke Jeremiah to all the princes and to all the people, saying, Yahweh sent me to prophesy against this house and against this city all the words that you have heard.",
                "Now therefore amend your ways and your doings, and obey the voice of Yahweh your God; and Yahweh will repent him of the evil that he has pronounced against you.",
                "But as for me, behold, I am in your hand: do with me as is good and right in your eyes.",
                "Only know for certain that, if you put me to death, you will bring innocent blood on yourselves, and on this city, and on its inhabitants; for of a truth Yahweh has sent me to you to speak all these words in your ears.",
                "Then the princes and all the people said to the priests and to the prophets: This man is not worthy of death; for he has spoken to us in the name of Yahweh our God.",
                "Then rose up certain of the elders of the land, and spoke to all the assembly of the people, saying,",
                "Micah the Morashtite prophesied in the days of Hezekiah king of Judah; and he spoke to all the people of Judah, saying, Thus says Yahweh of Armies: Zion shall be plowed as a field, and Jerusalem shall become heaps, and the mountain of the house as the high places of a forest.",
                "Did Hezekiah king of Judah and all Judah put him to death? Didn\u2019t he fear Yahweh, and entreat the favor of Yahweh, and Yahweh relented of the disaster which he had pronounced against them? Thus should we commit great evil against our own souls.",
                "There was also a man who prophesied in the name of Yahweh, Uriah the son of Shemaiah of Kiriath Jearim; and he prophesied against this city and against this land according to all the words of Jeremiah:",
                "and when Jehoiakim the king, with all his mighty men, and all the princes, heard his words, the king sought to put him to death; but when Uriah heard it, he was afraid, and fled, and went into Egypt:",
                "and Jehoiakim the king sent men into Egypt, Elnathan the son of Achbor, and certain men with him, into Egypt;",
                "and they fetched forth Uriah out of Egypt, and brought him to Jehoiakim the king, who killed him with the sword, and cast his dead body into the graves of the common people.",
                "But the hand of Ahikam the son of Shaphan was with Jeremiah, that they should not give him into the hand of the people to put him to death."
            ],
            27: [
                "In the beginning of the reign of Jehoiakim the son of Josiah, king of Judah, came this word to Jeremiah from Yahweh, saying,",
                "Thus says Yahweh to me: Make bonds and bars, and put them on your neck;",
                "and send them to the king of Edom, and to the king of Moab, and to the king of the children of Ammon, and to the king of Tyre, and to the king of Sidon, by the hand of the messengers who come to Jerusalem to Zedekiah king of Judah;",
                "and give them a command to their masters, saying, Thus says Yahweh of Armies, the God of Israel, You shall tell your masters:",
                "I have made the earth, the men and the animals that are on the surface of the earth, by my great power and by my outstretched arm; and I give it to whom it seems right to me.",
                "Now have I given all these lands into the hand of Nebuchadnezzar the king of Babylon, my servant; and the animals of the field also have I given him to serve him.",
                "All the nations shall serve him, and his son, and his son\u2019s son, until the time of his own land come: and then many nations and great kings shall make him their bondservant.",
                "It shall happen, that the nation and the kingdom which will not serve the same Nebuchadnezzar king of Babylon, and that will not put their neck under the yoke of the king of Babylon, that nation will I punish, says Yahweh, with the sword, and with the famine, and with the pestilence, until I have consumed them by his hand.",
                "But as for you, don\u2019t you listen to your prophets, nor to your diviners, nor to your dreams, nor to your soothsayers, nor to your sorcerers, who speak to you, saying, You shall not serve the king of Babylon:",
                "for they prophesy a lie to you, to remove you far from your land, and that I should drive you out, and you should perish.",
                "But the nation that shall bring their neck under the yoke of the king of Babylon, and serve him, that nation will I let remain in their own land, says Yahweh; and they shall till it, and dwell therein.",
                "I spoke to Zedekiah king of Judah according to all these words, saying, Bring your necks under the yoke of the king of Babylon, and serve him and his people, and live.",
                "Why will you die, you and your people, by the sword, by the famine, and by the pestilence, as Yahweh has spoken concerning the nation that will not serve the king of Babylon?",
                "Don\u2019t listen to the words of the prophets who speak to you, saying, You shall not serve the king of Babylon; for they prophesy a lie to you.",
                "For I have not sent them, says Yahweh, but they prophesy falsely in my name; that I may drive you out, and that you may perish, you, and the prophets who prophesy to you.",
                "Also I spoke to the priests and to all this people, saying, Thus says Yahweh: Don\u2019t listen to the words of your prophets who prophesy to you, saying, Behold, the vessels of Yahweh\u2019s house shall now shortly be brought again from Babylon; for they prophesy a lie to you.",
                "Don\u2019t listen to them; serve the king of Babylon, and live: why should this city become a desolation?",
                "But if they be prophets, and if the word of Yahweh be with them, let them now make intercession to Yahweh of Armies, that the vessels which are left in the house of Yahweh, and in the house of the king of Judah, and at Jerusalem, don\u2019t go to Babylon.",
                "For thus says Yahweh of Armies concerning the pillars, and concerning the sea, and concerning the bases, and concerning the residue of the vessels that are left in this city,",
                "which Nebuchadnezzar king of Babylon didn\u2019t take, when he carried away captive Jeconiah the son of Jehoiakim, king of Judah, from Jerusalem to Babylon, and all the nobles of Judah and Jerusalem;",
                "yes, thus says Yahweh of Armies, the God of Israel, concerning the vessels that are left in the house of Yahweh, and in the house of the king of Judah, and at Jerusalem:",
                "They shall be carried to Babylon, and there shall they be, until the day that I visit them, says Yahweh; then will I bring them up, and restore them to this place."
            ],
            28: [
                "It happened the same year, in the beginning of the reign of Zedekiah king of Judah, in the fourth year, in the fifth month, that Hananiah the son of Azzur, the prophet, who was of Gibeon, spoke to me in the house of Yahweh, in the presence of the priests and of all the people, saying,",
                "Thus speaks Yahweh of Armies, the God of Israel, saying, I have broken the yoke of the king of Babylon.",
                "Within two full years will I bring again into this place all the vessels of Yahweh\u2019s house, that Nebuchadnezzar king of Babylon took away from this place, and carried to Babylon:",
                "and I will bring again to this place Jeconiah the son of Jehoiakim, king of Judah, with all the captives of Judah, who went to Babylon, says Yahweh; for I will break the yoke of the king of Babylon.",
                "Then the prophet Jeremiah said to the prophet Hananiah in the presence of the priests, and in the presence of all the people who stood in the house of Yahweh,",
                "even the prophet Jeremiah said, Amen: Yahweh do so; Yahweh perform your words which you have prophesied, to bring again the vessels of Yahweh\u2019s house, and all them of the captivity, from Babylon to this place.",
                "Nevertheless hear you now this word that I speak in your ears, and in the ears of all the people:",
                "The prophets who have been before me and before you of old prophesied against many countries, and against great kingdoms, of war, and of evil, and of pestilence.",
                "The prophet who prophesies of peace, when the word of the prophet shall happen, then shall the prophet be known, that Yahweh has truly sent him.",
                "Then Hananiah the prophet took the bar from off the prophet Jeremiah\u2019s neck, and broke it.",
                "Hananiah spoke in the presence of all the people, saying, Thus says Yahweh: Even so will I break the yoke of Nebuchadnezzar king of Babylon within two full years from off the neck of all the nations. The prophet Jeremiah went his way.",
                "Then the word of Yahweh came to Jeremiah, after that Hananiah the prophet had broken the bar from off the neck of the prophet Jeremiah, saying,",
                "Go, and tell Hananiah, saying, Thus says Yahweh: You have broken the bars of wood; but you have made in their place bars of iron.",
                "For thus says Yahweh of Armies, the God of Israel: I have put a yoke of iron on the neck of all these nations, that they may serve Nebuchadnezzar king of Babylon; and they shall serve him: and I have given him the animals of the field also.",
                "Then the prophet Jeremiah said to Hananiah the prophet, Hear now, Hananiah: Yahweh has not sent you; but you make this people to trust in a lie.",
                "Therefore thus says Yahweh, Behold, I will send you away from off the surface of the earth: this year you shall die, because you have spoken rebellion against Yahweh.",
                "So Hananiah the prophet died the same year in the seventh month."
            ],
            29: [
                "Now these are the words of the letter that Jeremiah the prophet sent from Jerusalem to the residue of the elders of the captivity, and to the priests, to the prophets, and to all the people, whom Nebuchadnezzar had carried away captive from Jerusalem to Babylon,",
                "(after that Jeconiah the king, the queen mother, the eunuchs, the princes of Judah and Jerusalem, the craftsmen, and the smiths, had departed from Jerusalem),",
                "by the hand of Elasah the son of Shaphan, and Gemariah the son of Hilkiah, (whom Zedekiah king of Judah sent to Babylon to Nebuchadnezzar king of Babylon), saying,",
                "Thus says Yahweh of Armies, the God of Israel, to all the captivity, whom I have caused to be carried away captive from Jerusalem to Babylon:",
                "Build houses, and dwell in them; and plant gardens, and eat their fruit.",
                "Take wives, and father sons and daughters; and take wives for your sons, and give your daughters to husbands, that they may bear sons and daughters; and multiply there, and don\u2019t be diminished.",
                "Seek the peace of the city where I have caused you to be carried away captive, and pray to Yahweh for it; for in its peace you shall have peace.",
                "For thus says Yahweh of Armies, the God of Israel: Don\u2019t let your prophets who are in the midst of you, and your diviners, deceive you; neither listen to your dreams which you cause to be dreamed.",
                "For they prophesy falsely to you in my name: I have not sent them, says Yahweh.",
                "For thus says Yahweh, After seventy years are accomplished for Babylon, I will visit you, and perform my good word toward you, in causing you to return to this place.",
                "For I know the thoughts that I think toward you, says Yahweh, thoughts of peace, and not of evil, to give you hope and a future.",
                "You shall call on me, and you shall go and pray to me, and I will listen to you.",
                "You shall seek me, and find me, when you shall search for me with all your heart.",
                "I will be found by you, says Yahweh, and I will turn again your captivity, and I will gather you from all the nations, and from all the places where I have driven you, says Yahweh; and I will bring you again to the place from where I caused you to be carried away captive.",
                "Because you have said, Yahweh has raised us up prophets in Babylon;",
                "thus says Yahweh concerning the king who sits on the throne of David, and concerning all the people who dwell in this city, your brothers who haven\u2019t gone forth with you into captivity;",
                "thus says Yahweh of Armies; Behold, I will send on them the sword, the famine, and the pestilence, and will make them like vile figs, that can\u2019t be eaten, they are so bad.",
                "I will pursue after them with the sword, with the famine, and with the pestilence, and will deliver them to be tossed back and forth among all the kingdoms of the earth, to be an object of horror, and an astonishment, and a hissing, and a reproach, among all the nations where I have driven them;",
                "because they have not listened to my words, says Yahweh, with which I sent to them my servants the prophets, rising up early and sending them; but you would not hear, says Yahweh.",
                "Hear therefore the word of Yahweh, all you of the captivity, whom I have sent away from Jerusalem to Babylon.",
                "Thus says Yahweh of Armies, the God of Israel, concerning Ahab the son of Kolaiah, and concerning Zedekiah the son of Maaseiah, who prophesy a lie to you in my name: Behold, I will deliver them into the hand of Nebuchadnezzar king of Babylon; and he shall kill them before your eyes;",
                "and of them shall be taken up a curse by all the captives of Judah who are in Babylon, saying, Yahweh make you like Zedekiah and like Ahab, whom the king of Babylon roasted in the fire;",
                "because they have worked folly in Israel, and have committed adultery with their neighbors\u2019 wives, and have spoken words in my name falsely, which I didn\u2019t command them; and I am he who knows, and am witness, says Yahweh.",
                "Concerning Shemaiah the Nehelamite you shall speak, saying,",
                "Thus speaks Yahweh of Armies, the God of Israel, saying, Because you have sent letters in your own name to all the people who are at Jerusalem, and to Zephaniah the son of Maaseiah, the priest, and to all the priests, saying,",
                "Yahweh has made you priest in the place of Jehoiada the priest, that there may be officers in the house of Yahweh, for every man who is mad, and makes himself a prophet, that you should put him in the stocks and in shackles.",
                "Now therefore, why have you not rebuked Jeremiah of Anathoth, who makes himself a prophet to you,",
                "because he has sent to us in Babylon, saying, The captivity is long: build houses, and dwell in them; and plant gardens, and eat their fruit?",
                "Zephaniah the priest read this letter in the ears of Jeremiah the prophet.",
                "Then came the word of Yahweh to Jeremiah, saying,",
                "Send to all them of the captivity, saying, Thus says Yahweh concerning Shemaiah the Nehelamite: Because Shemaiah has prophesied to you, and I didn\u2019t send him, and he has caused you to trust in a lie;",
                "therefore thus says Yahweh, Behold, I will punish Shemaiah the Nehelamite, and his seed; he shall not have a man to dwell among this people, neither shall he see the good that I will do to my people, says Yahweh, because he has spoken rebellion against Yahweh."
            ],
            30: [
                "The word that came to Jeremiah from Yahweh, saying,",
                "Thus speaks Yahweh, the God of Israel, saying, Write all the words that I have spoken to you in a book.",
                "For, behold, the days come, says Yahweh, that I will turn again the captivity of my people Israel and Judah, says Yahweh; and I will cause them to return to the land that I gave to their fathers, and they shall possess it.",
                "These are the words that Yahweh spoke concerning Israel and concerning Judah.",
                "For thus says Yahweh: We have heard a voice of trembling, of fear, and not of peace.",
                "Ask now, and see whether a man does travail with child: why do I see every man with his hands on his waist, as a woman in travail, and all faces are turned into paleness?",
                "Alas! for that day is great, so that none is like it: it is even the time of Jacob\u2019s trouble; but he shall be saved out of it.",
                "It shall come to pass in that day, says Yahweh of Armies, that I will break his yoke from off your neck, and will burst your bonds; and strangers shall no more make him their bondservant;",
                "but they shall serve Yahweh their God, and David their king, whom I will raise up to them.",
                "Therefore don\u2019t you be afraid, O Jacob my servant, says Yahweh; neither be dismayed, Israel: for, behold, I will save you from afar, and your seed from the land of their captivity; and Jacob shall return, and shall be quiet and at ease, and none shall make him afraid.",
                "For I am with you, says Yahweh, to save you: for I will make a full end of all the nations where I have scattered you, but I will not make a full end of you; but I will correct you in measure, and will in no way leave you unpunished.",
                "For thus says Yahweh, Your hurt is incurable, and your wound grievous.",
                "There is none to plead your cause, that you may be bound up: you have no healing medicines.",
                "All your lovers have forgotten you; they don\u2019t seek you: for I have wounded you with the wound of an enemy, with the chastisement of a cruel one, for the greatness of your iniquity, because your sins were increased.",
                "Why do you cry for your hurt? Your pain is incurable: for the greatness of your iniquity, because your sins were increased, I have done these things to you.",
                "Therefore all those who devour you shall be devoured; and all your adversaries, everyone of them, shall go into captivity; and those who despoil you shall be a spoil, and all who prey on you will I give for a prey.",
                "For I will restore health to you, and I will heal you of your wounds, says Yahweh; because they have called you an outcast, saying, It is Zion, whom no man seeks after.",
                "Thus says Yahweh: Behold, I will turn again the captivity of Jacob\u2019s tents, and have compassion on his dwelling places; and the city shall be built on its own hill, and the palace shall be inhabited in its own way.",
                "Out of them shall proceed thanksgiving and the voice of those who make merry: and I will multiply them, and they shall not be few; I will also glorify them, and they shall not be small.",
                "Their children also shall be as before, and their congregation shall be established before me; and I will punish all who oppress them.",
                "Their prince shall be of themselves, and their ruler shall proceed from their midst; and I will cause him to draw near, and he shall approach to me: for who is he who has had boldness to approach to me? says Yahweh.",
                "You shall be my people, and I will be your God.",
                "Behold, the storm of Yahweh, his wrath, has gone forth, a sweeping storm: it shall burst on the head of the wicked.",
                "The fierce anger of Yahweh will not return, until he has executed, and until he has performed the intentions of his heart. In the latter days you will understand it."
            ],
            31: [
                "At that time, says Yahweh, will I be the God of all the families of Israel, and they shall be my people.",
                "Thus says Yahweh, The people who were left of the sword found favor in the wilderness; even Israel, when I went to cause him to rest.",
                "Yahweh appeared of old to me, saying, Yes, I have loved you with an everlasting love: therefore with loving kindness have I drawn you.",
                "Again will I build you, and you shall be built, O virgin of Israel: again you shall be adorned with your tambourines, and shall go forth in the dances of those who make merry.",
                "Again you shall plant vineyards on the mountains of Samaria; the planters shall plant, and shall enjoy its fruit.",
                "For there shall be a day, that the watchmen on the hills of Ephraim shall cry, Arise, and let us go up to Zion to Yahweh our God.",
                "For thus says Yahweh, Sing with gladness for Jacob, and shout for the chief of the nations: publish, praise, and say, Yahweh, save your people, the remnant of Israel.",
                "Behold, I will bring them from the north country, and gather them from the uttermost parts of the earth, along with the blind and the lame, the woman with child and her who travails with child together: a great company shall they return here.",
                "They shall come with weeping; and with petitions will I lead them: I will cause them to walk by rivers of waters, in a straight way in which they shall not stumble; for I am a father to Israel, and Ephraim is my firstborn.",
                "Hear the word of Yahweh, you nations, and declare it in the islands afar off; and say, He who scattered Israel will gather him, and keep him, as shepherd does his flock.",
                "For Yahweh has ransomed Jacob, and redeemed him from the hand of him who was stronger than he.",
                "They shall come and sing in the height of Zion, and shall flow to the goodness of Yahweh, to the grain, and to the new wine, and to the oil, and to the young of the flock and of the herd: and their soul shall be as a watered garden; and they shall not sorrow any more at all.",
                "Then shall the virgin rejoice in the dance, and the young men and the old together; for I will turn their mourning into joy, and will comfort them, and make them rejoice from their sorrow.",
                "I will satiate the soul of the priests with fatness, and my people shall be satisfied with my goodness, says Yahweh.",
                "Thus says Yahweh: A voice is heard in Ramah, lamentation, and bitter weeping, Rachel weeping for her children; she refuses to be comforted for her children, because they are no more.",
                "Thus says Yahweh: Refrain your voice from weeping, and your eyes from tears; for your work shall be rewarded, says Yahweh; and they shall come again from the land of the enemy.",
                "There is hope for your latter end, says Yahweh; and your children shall come again to their own border.",
                "I have surely heard Ephraim bemoaning himself thus, You have chastised me, and I was chastised, as an untrained calf: turn me, and I shall be turned; for you are Yahweh my God.",
                "Surely after that I was turned, I repented; and after that I was instructed, I struck on my thigh: I was ashamed, yes, even confounded, because I bore the reproach of my youth.",
                "Is Ephraim my dear son? is he a darling child? for as often as I speak against him, I do earnestly remember him still: therefore my heart yearns for him; I will surely have mercy on him, says Yahweh.",
                "Set up road signs, make guideposts; set your heart toward the highway, even the way by which you went: turn again, virgin of Israel, turn again to these your cities.",
                "How long will you go here and there, you backsliding daughter? for Yahweh has created a new thing in the earth: a woman shall encompass a man.",
                "Thus says Yahweh of Armies, the God of Israel, Yet again shall they use this speech in the land of Judah and in its cities, when I shall bring again their captivity: Yahweh bless you, habitation of righteousness, mountain of holiness.",
                "Judah and all its cities shall dwell therein together, the farmers, and those who go about with flocks.",
                "For I have satiated the weary soul, and every sorrowful soul have I replenished.",
                "On this I awakened, and saw; and my sleep was sweet to me.",
                "Behold, the days come, says Yahweh, that I will sow the house of Israel and the house of Judah with the seed of man, and with the seed of animal.",
                "It shall happen that, like as I have watched over them to pluck up and to break down and to overthrow and to destroy and to afflict, so will I watch over them to build and to plant, says Yahweh.",
                "In those days they shall say no more, The fathers have eaten sour grapes, and the children\u2019s teeth are set on edge.",
                "But everyone shall die for his own iniquity: every man who eats the sour grapes, his teeth shall be set on edge.",
                "Behold, the days come, says Yahweh, that I will make a new covenant with the house of Israel, and with the house of Judah:",
                "not according to the covenant that I made with their fathers in the day that I took them by the hand to bring them out of the land of Egypt; which my covenant they broke, although I was a husband to them, says Yahweh.",
                "But this is the covenant that I will make with the house of Israel after those days, says Yahweh: I will put my law in their inward parts, and in their heart will I write it; and I will be their God, and they shall be my people:",
                "and they shall teach no more every man his neighbor, and every man his brother, saying, Know Yahweh; for they shall all know me, from their least to their greatest, says Yahweh: for I will forgive their iniquity, and their sin will I remember no more.",
                "Thus says Yahweh, who gives the sun for a light by day, and the ordinances of the moon and of the stars for a light by night, who stirs up the sea, so that its waves roar; Yahweh of Armies is his name:",
                "If these ordinances depart from before me, says Yahweh, then the seed of Israel also shall cease from being a nation before me forever.",
                "Thus says Yahweh: If heaven above can be measured, and the foundations of the earth searched out beneath, then will I also cast off all the seed of Israel for all that they have done, says Yahweh.",
                "Behold, the days come, says Yahweh, that the city shall be built to Yahweh from the tower of Hananel to the gate of the corner.",
                "The measuring line shall go out further straight onward to the hill Gareb, and shall turn about to Goah.",
                "The whole valley of the dead bodies and of the ashes, and all the fields to the brook Kidron, to the corner of the horse gate toward the east, shall be holy to Yahweh; it shall not be plucked up, nor thrown down any more forever."
            ],
            32: [
                "The word that came to Jeremiah from Yahweh in the tenth year of Zedekiah king of Judah, which was the eighteenth year of Nebuchadnezzar.",
                "Now at that time the king of Babylon\u2019s army was besieging Jerusalem; and Jeremiah the prophet was shut up in the court of the guard, which was in the king of Judah\u2019s house.",
                "For Zedekiah king of Judah had shut him up, saying, Why do you prophesy, and say, Thus says Yahweh, Behold, I will give this city into the hand of the king of Babylon, and he shall take it;",
                "and Zedekiah king of Judah shall not escape out of the hand of the Chaldeans, but shall surely be delivered into the hand of the king of Babylon, and shall speak with him mouth to mouth, and his eyes shall see his eyes;",
                "and he shall bring Zedekiah to Babylon, and he shall be there until I visit him, says Yahweh: though you fight with the Chaldeans, you shall not prosper?",
                "Jeremiah said, The word of Yahweh came to me, saying,",
                "Behold, Hanamel the son of Shallum your uncle shall come to you, saying, Buy my field that is in Anathoth; for the right of redemption is yours to buy it.",
                "So Hanamel my uncle\u2019s son came to me in the court of the guard according to the word of Yahweh, and said to me, Please buy my field that is in Anathoth, which is in the land of Benjamin; for the right of inheritance is yours, and the redemption is yours; buy it for yourself. Then I knew that this was the word of Yahweh.",
                "I bought the field that was in Anathoth of Hanamel my uncle\u2019s son, and weighed him the money, even seventeen shekels of silver.",
                "I subscribed the deed, and sealed it, and called witnesses, and weighed him the money in the balances.",
                "So I took the deed of the purchase, both that which was sealed, containing the terms and conditions, and that which was open;",
                "and I delivered the deed of the purchase to Baruch the son of Neriah, the son of Mahseiah, in the presence of Hanamel my uncle\u2019s son, and in the presence of the witnesses who subscribed the deed of the purchase, before all the Jews who sat in the court of the guard.",
                "I commanded Baruch before them, saying,",
                "Thus says Yahweh of Armies, the God of Israel: Take these deeds, this deed of the purchase which is sealed, and this deed which is open, and put them in an earthen vessel; that they may continue many days.",
                "For thus says Yahweh of Armies, the God of Israel: Houses and fields and vineyards shall yet again be bought in this land.",
                "Now after I had delivered the deed of the purchase to Baruch the son of Neriah, I prayed to Yahweh, saying,",
                "Ah Lord Yahweh! Behold, you have made the heavens and the earth by your great power and by your outstretched arm; there is nothing too hard for you,",
                "who show loving kindness to thousands, and recompense the iniquity of the fathers into the bosom of their children after them; the great, the mighty God, Yahweh of Armies is his name;",
                "great in counsel, and mighty in work; whose eyes are open on all the ways of the sons of men, to give everyone according to his ways, and according to the fruit of his doings:",
                "who performed signs and wonders in the land of Egypt, even to this day, both in Israel and among other men; and made yourself a name, as in this day;",
                "and brought forth your people Israel out of the land of Egypt with signs, and with wonders, and with a strong hand, and with an outstretched arm, and with great terror;",
                "and gave them this land, which you swore to their fathers to give them, a land flowing with milk and honey;",
                "and they came in, and possessed it, but they didn\u2019t obey your voice, neither walked in your law; they have done nothing of all that you commanded them to do: therefore you have caused all this evil to come on them.",
                "Behold, the mounds, they have come to the city to take it; and the city is given into the hand of the Chaldeans who fight against it, because of the sword, and of the famine, and of the pestilence; and what you have spoken has happened; and behold, you see it.",
                "You have said to me, Lord Yahweh, Buy the field for money, and call witnesses; whereas the city is given into the hand of the Chaldeans.",
                "Then came the word of Yahweh to Jeremiah, saying,",
                "Behold, I am Yahweh, the God of all flesh: is there anything too hard for me?",
                "Therefore thus says Yahweh: Behold, I will give this city into the hand of the Chaldeans, and into the hand of Nebuchadnezzar king of Babylon, and he shall take it:",
                "and the Chaldeans, who fight against this city, shall come and set this city on fire, and burn it, with the houses, on whose roofs they have offered incense to Baal, and poured out drink offerings to other gods, to provoke me to anger.",
                "For the children of Israel and the children of Judah have done only that which was evil in my sight from their youth; for the children of Israel have only provoked me to anger with the work of their hands, says Yahweh.",
                "For this city has been to me a provocation of my anger and of my wrath from the day that they built it even to this day; that I should remove it from before my face,",
                "because of all the evil of the children of Israel and of the children of Judah, which they have done to provoke me to anger, they, their kings, their princes, their priests, and their prophets, and the men of Judah, and the inhabitants of Jerusalem.",
                "They have turned to me the back, and not the face: and though I taught them, rising up early and teaching them, yet they have not listened to receive instruction.",
                "But they set their abominations in the house which is called by my name, to defile it.",
                "They built the high places of Baal, which are in the valley of the son of Hinnom, to cause their sons and their daughters to pass through fire to Molech; which I didn\u2019t command them, neither did it come into my mind, that they should do this abomination, to cause Judah to sin.",
                "Now therefore thus says Yahweh, the God of Israel, concerning this city, about which you say, It is given into the hand of the king of Babylon by the sword, and by the famine, and by the pestilence:",
                "Behold, I will gather them out of all the countries, where I have driven them in my anger, and in my wrath, and in great indignation; and I will bring them again to this place, and I will cause them to dwell safely:",
                "and they shall be my people, and I will be their God:",
                "and I will give them one heart and one way, that they may fear me forever, for their good, and of their children after them:",
                "and I will make an everlasting covenant with them, that I will not turn away from following them, to do them good; and I will put my fear in their hearts, that they may not depart from me.",
                "Yes, I will rejoice over them to do them good, and I will plant them in this land assuredly with my whole heart and with my whole soul.",
                "For thus says Yahweh: Like as I have brought all this great evil on this people, so will I bring on them all the good that I have promised them.",
                "Fields shall be bought in this land, about which you say, It is desolate, without man or animal; it is given into the hand of the Chaldeans.",
                "Men shall buy fields for money, and subscribe the deeds, and seal them, and call witnesses, in the land of Benjamin, and in the places about Jerusalem, and in the cities of Judah, and in the cities of the hill country, and in the cities of the lowland, and in the cities of the South: for I will cause their captivity to return, says Yahweh."
            ],
            33: [
                "Moreover the word of Yahweh came to Jeremiah the second time, while he was yet shut up in the court of the guard, saying,",
                "Thus says Yahweh who does it, Yahweh who forms it to establish it; Yahweh is his name:",
                "Call to me, and I will answer you, and will show you great things, and difficult, which you don\u2019t know.",
                "For thus says Yahweh, the God of Israel, concerning the houses of this city, and concerning the houses of the kings of Judah, which are broken down to make a defense against the mounds and against the sword;",
                "while men come to fight with the Chaldeans, and to fill them with the dead bodies of men, whom I have killed in my anger and in my wrath, and for all whose wickedness I have hidden my face from this city:",
                "Behold, I will bring it health and cure, and I will cure them; and I will reveal to them abundance of peace and truth.",
                "I will cause the captivity of Judah and the captivity of Israel to return, and will build them, as at the first.",
                "I will cleanse them from all their iniquity, by which they have sinned against me; and I will pardon all their iniquities, by which they have sinned against me, and by which they have transgressed against me.",
                "This city shall be to me for a name of joy, for a praise and for a glory, before all the nations of the earth, which shall hear all the good that I do to them, and shall fear and tremble for all the good and for all the peace that I procure to it.",
                "Thus says Yahweh: Yet again there shall be heard in this place, about which you say, It is waste, without man and without animal, even in the cities of Judah, and in the streets of Jerusalem, that are desolate, without man and without inhabitant and without animal,",
                "the voice of joy and the voice of gladness, the voice of the bridegroom and the voice of the bride, the voice of those who say, Give thanks to Yahweh of Armies, for Yahweh is good, for his loving kindness endures forever; who bring thanksgiving into the house of Yahweh. For I will cause the captivity of the land to return as at the first, says Yahweh.",
                "Thus says Yahweh of Armies: Yet again shall there be in this place, which is waste, without man and without animal, and in all its cities, a habitation of shepherds causing their flocks to lie down.",
                "In the cities of the hill country, in the cities of the lowland, and in the cities of the South, and in the land of Benjamin, and in the places about Jerusalem, and in the cities of Judah, shall the flocks again pass under the hands of him who numbers them, says Yahweh.",
                "Behold, the days come, says Yahweh, that I will perform that good word which I have spoken concerning the house of Israel and concerning the house of Judah.",
                "In those days, and at that time, will I cause a Branch of righteousness to grow up to David; and he shall execute justice and righteousness in the land.",
                "In those days shall Judah be saved, and Jerusalem shall dwell safely; and this is the name by which she shall be called: Yahweh our righteousness.",
                "For thus says Yahweh: David shall never want a man to sit on the throne of the house of Israel;",
                "neither shall the priests the Levites want a man before me to offer burnt offerings, and to burn meal offerings, and to do sacrifice continually.",
                "The word of Yahweh came to Jeremiah, saying,",
                "Thus says Yahweh: If you can break my covenant of the day, and my covenant of the night, so that there shall not be day and night in their season;",
                "then may also my covenant be broken with David my servant, that he shall not have a son to reign on his throne; and with the Levites the priests, my ministers.",
                "As the army of the sky can\u2019t be numbered, neither the sand of the sea measured; so will I multiply the seed of David my servant, and the Levites who minister to me.",
                "The word of Yahweh came to Jeremiah, saying,",
                "Don\u2019t consider what this people has spoken, saying, The two families which Yahweh chose, he has cast them off? thus do they despise my people, that they should be no more a nation before them.",
                "Thus says Yahweh: If my covenant of day and night fails, if I have not appointed the ordinances of heaven and earth;",
                "then will I also cast away the seed of Jacob, and of David my servant, so that I will not take of his seed to be rulers over the seed of Abraham, Isaac, and Jacob: for I will cause their captivity to return, and will have mercy on them."
            ],
            34: [
                "The word which came to Jeremiah from Yahweh, when Nebuchadnezzar king of Babylon, and all his army, and all the kingdoms of the earth that were under his dominion, and all the peoples, were fighting against Jerusalem, and against all the cities of it, saying:",
                "Thus says Yahweh, the God of Israel, Go, and speak to Zedekiah king of Judah, and tell him, Thus says Yahweh, Behold, I will give this city into the hand of the king of Babylon, and he shall burn it with fire:",
                "and you shall not escape out of his hand, but shall surely be taken, and delivered into his hand; and your eyes shall see the eyes of the king of Babylon, and he shall speak with you mouth to mouth, and you shall go to Babylon.",
                "Yet hear the word of Yahweh, O Zedekiah king of Judah: thus says Yahweh concerning you, You shall not die by the sword;",
                "you shall die in peace; and with the burnings of your fathers, the former kings who were before you, so shall they make a burning for you; and they shall lament you, saying, Ah Lord! for I have spoken the word, says Yahweh.",
                "Then Jeremiah the prophet spoke all these words to Zedekiah king of Judah in Jerusalem,",
                "when the king of Babylon\u2019s army was fighting against Jerusalem, and against all the cities of Judah that were left, against Lachish and against Azekah; for these alone remained of the cities of Judah as fortified cities.",
                "The word that came to Jeremiah from Yahweh, after that the king Zedekiah had made a covenant with all the people who were at Jerusalem, to proclaim liberty to them;",
                "that every man should let his male servant, and every man his female servant, who is a Hebrew or a Hebrewess, go free; that none should make bondservants of them, of a Jew his brother.",
                "All the princes and all the people obeyed, who had entered into the covenant, that everyone should let his male servant, and everyone his female servant, go free, that none should make bondservants of them any more; they obeyed, and let them go:",
                "but afterwards they turned, and caused the servants and the handmaids, whom they had let go free, to return, and brought them into subjection for servants and for handmaids.",
                "Therefore the word of Yahweh came to Jeremiah from Yahweh, saying,",
                "Thus says Yahweh, the God of Israel: I made a covenant with your fathers in the day that I brought them forth out of the land of Egypt, out of the house of bondage, saying,",
                "At the end of seven years you shall let go every man his brother who is a Hebrew, who has been sold to you, and has served you six years, you shall let him go free from you: but your fathers didn\u2019t listen to me, neither inclined their ear.",
                "You had now turned, and had done that which is right in my eyes, in proclaiming liberty every man to his neighbor; and you had made a covenant before me in the house which is called by my name:",
                "but you turned and profaned my name, and caused every man his servant, and every man his handmaid, whom you had let go free at their pleasure, to return; and you brought them into subjection, to be to you for servants and for handmaids.",
                "Therefore thus says Yahweh: you have not listened to me, to proclaim liberty, every man to his brother, and every man to his neighbor: behold, I proclaim to you a liberty, says Yahweh, to the sword, to the pestilence, and to the famine; and I will make you to be tossed back and forth among all the kingdoms of the earth.",
                "I will give the men who have transgressed my covenant, who have not performed the words of the covenant which they made before me, when they cut the calf in two and passed between its parts;",
                "the princes of Judah, and the princes of Jerusalem, the eunuchs, and the priests, and all the people of the land, who passed between the parts of the calf;",
                "I will even give them into the hand of their enemies, and into the hand of those who seek their life; and their dead bodies shall be for food to the birds of the sky, and to the animals of the earth.",
                "Zedekiah king of Judah and his princes will I give into the hand of their enemies, and into the hand of those who seek their life, and into the hand of the king of Babylon\u2019s army, who have gone away from you.",
                "Behold, I will command, says Yahweh, and cause them to return to this city; and they shall fight against it, and take it, and burn it with fire: and I will make the cities of Judah a desolation, without inhabitant."
            ],
            35: [
                "The word which came to Jeremiah from Yahweh in the days of Jehoiakim the son of Josiah, king of Judah, saying,",
                "Go to the house of the Rechabites, and speak to them, and bring them into the house of Yahweh, into one of the rooms, and give them wine to drink.",
                "Then I took Jaazaniah the son of Jeremiah, the son of Habazziniah, and his brothers, and all his sons, and the whole house of the Rechabites;",
                "and I brought them into the house of Yahweh, into the room of the sons of Hanan the son of Igdaliah, the man of God, which was by the room of the princes, which was above the room of Maaseiah the son of Shallum, the keeper of the threshold.",
                "I set before the sons of the house of the Rechabites bowls full of wine, and cups; and I said to them, Drink wine!",
                "But they said, We will drink no wine; for Jonadab the son of Rechab, our father, commanded us, saying, You shall drink no wine, neither you, nor your sons, forever:",
                "neither shall you build house, nor sow seed, nor plant vineyard, nor have any; but all your days you shall dwell in tents; that you may live many days in the land in which you live.",
                "We have obeyed the voice of Jonadab the son of Rechab, our father, in all that he commanded us, to drink no wine all our days, we, our wives, our sons, or our daughters;",
                "nor to build houses for us to dwell in; neither have we vineyard, nor field, nor seed:",
                "but we have lived in tents, and have obeyed, and done according to all that Jonadab our father commanded us.",
                "But it happened, when Nebuchadnezzar king of Babylon came up into the land, that we said, Come, and let us go to Jerusalem for fear of the army of the Chaldeans, and for fear of the army of the Syrians; so we dwell at Jerusalem.",
                "Then came the word of Yahweh to Jeremiah, saying,",
                "Thus says Yahweh of Armies, the God of Israel: Go, and tell the men of Judah and the inhabitants of Jerusalem, Will you not receive instruction to listen to my words? says Yahweh.",
                "The words of Jonadab the son of Rechab, that he commanded his sons, not to drink wine, are performed; and to this day they drink none, for they obey their father\u2019s commandment: but I have spoken to you, rising up early and speaking; and you have not listened to me.",
                "I have sent also to you all my servants the prophets, rising up early and sending them, saying, Return now every man from his evil way, and amend your doings, and don\u2019t go after other gods to serve them, and you shall dwell in the land which I have given to you and to your fathers: but you have not inclined your ear, nor listened to me.",
                "Because the sons of Jonadab the son of Rechab have performed the commandment of their father which he commanded them, but this people has not listened to me;",
                "therefore thus says Yahweh, the God of Armies, the God of Israel: Behold, I will bring on Judah and on all the inhabitants of Jerusalem all the evil that I have pronounced against them; because I have spoken to them, but they have not heard; and I have called to them, but they have not answered.",
                "Jeremiah said to the house of the Rechabites, Thus says Yahweh of Armies, the God of Israel: Because you have obeyed the commandment of Jonadab your father, and kept all his precepts, and done according to all that he commanded you;",
                "therefore thus says Yahweh of Armies, the God of Israel: Jonadab the son of Rechab shall not want a man to stand before me forever."
            ],
            36: [
                "It happened in the fourth year of Jehoiakim the son of Josiah, king of Judah, that this word came to Jeremiah from Yahweh, saying,",
                "Take a scroll of a book, and write therein all the words that I have spoken to you against Israel, and against Judah, and against all the nations, from the day I spoke to you, from the days of Josiah, even to this day.",
                "It may be that the house of Judah will hear all the evil which I purpose to do to them; that they may return every man from his evil way; that I may forgive their iniquity and their sin.",
                "Then Jeremiah called Baruch the son of Neriah; and Baruch wrote from the mouth of Jeremiah all the words of Yahweh, which he had spoken to him, on a scroll of a book.",
                "Jeremiah commanded Baruch, saying, I am shut up; I can\u2019t go into the house of Yahweh:",
                "therefore you go, and read in the scroll, which you have written from my mouth, the words of Yahweh in the ears of the people in Yahweh\u2019s house on the fast day; and also you shall read them in the ears of all Judah who come out of their cities.",
                "It may be they will present their supplication before Yahweh, and will return everyone from his evil way; for great is the anger and the wrath that Yahweh has pronounced against this people.",
                "Baruch the son of Neriah did according to all that Jeremiah the prophet commanded him, reading in the book the words of Yahweh in Yahweh\u2019s house.",
                "Now it happened in the fifth year of Jehoiakim the son of Josiah, king of Judah, in the ninth month, that all the people in Jerusalem, and all the people who came from the cities of Judah to Jerusalem, proclaimed a fast before Yahweh.",
                "Then read Baruch in the book the words of Jeremiah in the house of Yahweh, in the room of Gemariah the son of Shaphan, the scribe, in the upper court, at the entry of the new gate of Yahweh\u2019s house, in the ears of all the people.",
                "When Micaiah the son of Gemariah, the son of Shaphan, had heard out of the book all the words of Yahweh,",
                "he went down into the king\u2019s house, into the scribe\u2019s room: and behold, all the princes were sitting there, Elishama the scribe, and Delaiah the son of Shemaiah, and Elnathan the son of Achbor, and Gemariah the son of Shaphan, and Zedekiah the son of Hananiah, and all the princes.",
                "Then Micaiah declared to them all the words that he had heard, when Baruch read the book in the ears of the people.",
                "Therefore all the princes sent Jehudi the son of Nethaniah, the son of Shelemiah, the son of Cushi, to Baruch, saying, Take in your hand the scroll in which you have read in the ears of the people, and come. So Baruch the son of Neriah took the scroll in his hand, and came to them.",
                "They said to him, Sit down now, and read it in our ears. So Baruch read it in their ears.",
                "Now it happened, when they had heard all the words, they turned in fear one toward another, and said to Baruch, We will surely tell the king of all these words.",
                "They asked Baruch, saying, Tell us now, How did you write all these words at his mouth?",
                "Then Baruch answered them, He pronounced all these words to me with his mouth, and I wrote them with ink in the book.",
                "Then the princes said to Baruch, Go, hide, you and Jeremiah; and let no man know where you are.",
                "They went in to the king into the court; but they had laid up the scroll in the room of Elishama the scribe; and they told all the words in the ears of the king.",
                "So the king sent Jehudi to get the scroll; and he took it out of the room of Elishama the scribe. Jehudi read it in the ears of the king, and in the ears of all the princes who stood beside the king.",
                "Now the king was sitting in the winter house in the ninth month: and there was a fire in the brazier burning before him.",
                "It happened, when Jehudi had read three or four leaves, that the king cut it with the penknife, and cast it into the fire that was in the brazier, until all the scroll was consumed in the fire that was in the brazier.",
                "They were not afraid, nor tore their garments, neither the king, nor any of his servants who heard all these words.",
                "Moreover Elnathan and Delaiah and Gemariah had made intercession to the king that he would not burn the scroll; but he would not hear them.",
                "The king commanded Jerahmeel the king\u2019s son, and Seraiah the son of Azriel, and Shelemiah the son of Abdeel, to take Baruch the scribe and Jeremiah the prophet; but Yahweh hid them.",
                "Then the word of Yahweh came to Jeremiah, after that the king had burned the scroll, and the words which Baruch wrote at the mouth of Jeremiah, saying,",
                "Take again another scroll, and write in it all the former words that were in the first scroll, which Jehoiakim the king of Judah has burned.",
                "Concerning Jehoiakim king of Judah you shall say, Thus says Yahweh: You have burned this scroll, saying, Why have you written therein, saying, The king of Babylon shall certainly come and destroy this land, and shall cause to cease from there man and animal?",
                "Therefore thus says Yahweh concerning Jehoiakim king of Judah: He shall have none to sit on the throne of David; and his dead body shall be cast out in the day to the heat, and in the night to the frost.",
                "I will punish him and his seed and his servants for their iniquity; and I will bring on them, and on the inhabitants of Jerusalem, and on the men of Judah, all the evil that I have pronounced against them, but they didn\u2019t listen.",
                "Then took Jeremiah another scroll, and gave it to Baruch the scribe, the son of Neriah, who wrote therein from the mouth of Jeremiah all the words of the book which Jehoiakim king of Judah had burned in the fire; and there were added besides to them many like words."
            ],
            37: [
                "Zedekiah the son of Josiah reigned as king, instead of Coniah the son of Jehoiakim, whom Nebuchadnezzar king of Babylon made king in the land of Judah.",
                "But neither he, nor his servants, nor the people of the land, listened to the words of Yahweh, which he spoke by the prophet Jeremiah.",
                "Zedekiah the king sent Jehucal the son of Shelemiah, and Zephaniah the son of Maaseiah, the priest, to the prophet Jeremiah, saying, Pray now to Yahweh our God for us.",
                "Now Jeremiah came in and went out among the people; for they had not put him into prison.",
                "Pharaoh\u2019s army had come forth out of Egypt; and when the Chaldeans who were besieging Jerusalem heard news of them, they broke up from Jerusalem.",
                "Then came the word of Yahweh to the prophet Jeremiah, saying,",
                "Thus says Yahweh, the God of Israel, You shall tell the king of Judah, who sent you to me to inquire of me: Behold, Pharaoh\u2019s army, which has come forth to help you, shall return to Egypt into their own land.",
                "The Chaldeans shall come again, and fight against this city; and they shall take it, and burn it with fire.",
                "Thus says Yahweh, Don\u2019t deceive yourselves, saying, The Chaldeans shall surely depart from us; for they shall not depart.",
                "For though you had struck the whole army of the Chaldeans who fight against you, and there remained but wounded men among them, yes would they rise up every man in his tent, and burn this city with fire.",
                "It happened that, when the army of the Chaldeans was broken up from Jerusalem for fear of Pharaoh\u2019s army,",
                "then Jeremiah went forth out of Jerusalem to go into the land of Benjamin, to receive his portion there, in the midst of the people.",
                "When he was in the gate of Benjamin, a captain of the guard was there, whose name was Irijah, the son of Shelemiah, the son of Hananiah; and he laid hold on Jeremiah the prophet, saying, You are falling away to the Chaldeans.",
                "Then Jeremiah said, It is false; I am not falling away to the Chaldeans. But he didn\u2019t listen to him; so Irijah laid hold on Jeremiah, and brought him to the princes.",
                "The princes were angry with Jeremiah, and struck him, and put him in prison in the house of Jonathan the scribe; for they had made that the prison.",
                "When Jeremiah had come into the dungeon house, and into the cells, and Jeremiah had remained there many days;",
                "Then Zedekiah the king sent, and fetched him: and the king asked him secretly in his house, and said, Is there any word from Yahweh? Jeremiah said, There is. He said also, You shall be delivered into the hand of the king of Babylon.",
                "Moreover Jeremiah said to king Zedekiah, Wherein have I sinned against you, or against your servants, or against this people, that you have put me in prison?",
                "Where now are your prophets who prophesied to you, saying, The king of Babylon shall not come against you, nor against this land?",
                "Now please hear, my lord the king: please let my supplication be presented before you, that you not cause me to return to the house of Jonathan the scribe, lest I die there.",
                "Then Zedekiah the king commanded, and they committed Jeremiah into the court of the guard; and they gave him daily a loaf of bread out of the bakers\u2019 street, until all the bread in the city was spent. Thus Jeremiah remained in the court of the guard."
            ],
            38: [
                "Shephatiah the son of Mattan, and Gedaliah the son of Pashhur, and Jucal the son of Shelemiah, and Pashhur the son of Malchijah, heard the words that Jeremiah spoke to all the people, saying,",
                "Thus says Yahweh, He who remains in this city shall die by the sword, by the famine, and by the pestilence; but he who goes forth to the Chaldeans shall live, and his life shall be to him for a prey, and he shall live.",
                "Thus says Yahweh, This city shall surely be given into the hand of the army of the king of Babylon, and he shall take it.",
                "Then the princes said to the king, \"Please let this man be put to death; because he weakens the hands of the men of war who remain in this city, and the hands of all the people, in speaking such words to them: for this man doesn\u2019t seek the welfare of this people, but the hurt.\"",
                "Zedekiah the king said, Behold, he is in your hand; for the king is not he who can do anything against you.",
                "Then took they Jeremiah, and cast him into the dungeon of Malchijah the king\u2019s son, that was in the court of the guard: and they let down Jeremiah with cords. In the dungeon there was no water, but mire; and Jeremiah sank in the mire.",
                "Now when Ebedmelech the Ethiopian, a eunuch, who was in the king\u2019s house, heard that they had put Jeremiah in the dungeon (the king then sitting in the gate of Benjamin),",
                "Ebedmelech went forth out of the king\u2019s house, and spoke to the king, saying,",
                "My lord the king, these men have done evil in all that they have done to Jeremiah the prophet, whom they have cast into the dungeon; and he is likely to die in the place where he is, because of the famine; for there is no more bread in the city.",
                "Then the king commanded Ebedmelech the Ethiopian, saying, Take from here thirty men with you, and take up Jeremiah the prophet out of the dungeon, before he dies.",
                "So Ebedmelech took the men with him, and went into the house of the king under the treasury, and took there rags and worn-out garments, and let them down by cords into the dungeon to Jeremiah.",
                "Ebedmelech the Ethiopian said to Jeremiah, Put now these rags and worn-out garments under your armpits under the cords. Jeremiah did so.",
                "So they drew up Jeremiah with the cords, and took him up out of the dungeon: and Jeremiah remained in the court of the guard.",
                "Then Zedekiah the king sent, and took Jeremiah the prophet to him into the third entry that is in the house of Yahweh: and the king said to Jeremiah, I will ask you something. Hide nothing from me.",
                "Then Jeremiah said to Zedekiah, If I declare it to you, will you not surely put me to death? and if I give you counsel, you will not listen to me.",
                "So Zedekiah the king swore secretly to Jeremiah, saying, As Yahweh lives, who made us this soul, I will not put you to death, neither will I give you into the hand of these men who seek your life.",
                "Then Jeremiah said to Zedekiah, Thus says Yahweh, the God of Armies, the God of Israel: If you will go forth to the king of Babylon\u2019s princes, then your soul shall live, and this city shall not be burned with fire; and you shall live, and your house.",
                "But if you will not go forth to the king of Babylon\u2019s princes, then shall this city be given into the hand of the Chaldeans, and they shall burn it with fire, and you shall not escape out of their hand.",
                "Zedekiah the king said to Jeremiah, I am afraid of the Jews who are fallen away to the Chaldeans, lest they deliver me into their hand, and they mock me.",
                "But Jeremiah said, They shall not deliver you. Obey, I beg you, the voice of Yahweh, in that which I speak to you: so it shall be well with you, and your soul shall live.",
                "But if you refuse to go forth, this is the word that Yahweh has shown me:",
                "behold, all the women who are left in the king of Judah\u2019s house shall be brought forth to the king of Babylon\u2019s princes, and those women shall say, Your familiar friends have set you on, and have prevailed over you. Your feet are sunk in the mire, they have turned away back.",
                "They shall bring out all your wives and your children to the Chaldeans; and you shall not escape out of their hand, but shall be taken by the hand of the king of Babylon: and you shall cause this city to be burned with fire.",
                "Then Zedekiah said to Jeremiah, Let no man know of these words, and you shall not die.",
                "But if the princes hear that I have talked with you, and they come to you, and tell you, Declare to us now what you have said to the king; don\u2019t hide it from us, and we will not put you to death; also what the king said to you:",
                "then you shall tell them, I presented my supplication before the king, that he would not cause me to return to Jonathan\u2019s house, to die there.",
                "Then came all the princes to Jeremiah, and asked him; and he told them according to all these words that the king had commanded. So they left off speaking with him; for the matter was not perceived.",
                "So Jeremiah stayed in the court of the guard until the day that Jerusalem was taken."
            ],
            39: [
                "It happened when Jerusalem was taken, (in the ninth year of Zedekiah king of Judah, in the tenth month, came Nebuchadnezzar king of Babylon and all his army against Jerusalem, and besieged it;",
                "in the eleventh year of Zedekiah, in the fourth month, the ninth day of the month, a breach was made in the city),",
                "that all the princes of the king of Babylon came in, and sat in the middle gate, Nergal Sharezer, Samgarnebo, Sarsechim, Rabsaris, Nergal Sharezer, Rabmag, with all the rest of the princes of the king of Babylon.",
                "It happened that, when Zedekiah the king of Judah and all the men of war saw them, then they fled, and went forth out of the city by night, by the way of the king\u2019s garden, through the gate between the two walls; and he went out toward the Arabah.",
                "But the army of the Chaldeans pursued after them, and overtook Zedekiah in the plains of Jericho: and when they had taken him, they brought him up to Nebuchadnezzar king of Babylon to Riblah in the land of Hamath; and he gave judgment on him.",
                "Then the king of Babylon killed the sons of Zedekiah in Riblah before his eyes: also the king of Babylon killed all the nobles of Judah.",
                "Moreover he put out Zedekiah\u2019s eyes, and bound him in fetters, to carry him to Babylon.",
                "The Chaldeans burned the king\u2019s house, and the houses of the people, with fire, and broke down the walls of Jerusalem.",
                "Then Nebuzaradan the captain of the guard carried away captive into Babylon the residue of the people who remained in the city, the deserters also who fell away to him, and the residue of the people who remained.",
                "But Nebuzaradan the captain of the guard left of the poor of the people, who had nothing, in the land of Judah, and gave them vineyards and fields at the same time.",
                "Now Nebuchadnezzar king of Babylon commanded Nebuzaradan the captain of the guard concerning Jeremiah, saying,",
                "Take him, and look well to him, and do him no harm; but do to him even as he shall tell you.",
                "So Nebuzaradan the captain of the guard sent, and Nebushazban, Rabsaris, and Nergal Sharezer, Rabmag, and all the chief officers of the king of Babylon;",
                "they sent, and took Jeremiah out of the court of the guard, and committed him to Gedaliah the son of Ahikam, the son of Shaphan, that he should carry him home: so he lived among the people.",
                "Now the word of Yahweh came to Jeremiah, while he was shut up in the court of the guard, saying,",
                "Go, and speak to Ebedmelech the Ethiopian, saying, Thus says Yahweh of Armies, the God of Israel: Behold, I will bring my words on this city for evil, and not for good; and they shall be accomplished before you in that day.",
                "But I will deliver you in that day, says Yahweh; and you shall not be given into the hand of the men of whom you are afraid.",
                "For I will surely save you, and you shall not fall by the sword, but your life shall be for a prey to you; because you have put your trust in me, says Yahweh."
            ],
            40: [
                "The word which came to Jeremiah from Yahweh, after that Nebuzaradan the captain of the guard had let him go from Ramah, when he had taken him being bound in chains among all the captives of Jerusalem and Judah, who were carried away captive to Babylon.",
                "The captain of the guard took Jeremiah, and said to him, Yahweh your God pronounced this evil on this place;",
                "and Yahweh has brought it, and done according as he spoke: because you have sinned against Yahweh, and have not obeyed his voice, therefore this thing has come on you.",
                "Now, behold, I release you this day from the chains which are on your hand. If it seems good to you to come with me into Babylon, come, and I will take care of you; but if it seems bad to you to come with me into Babylon, don\u2019t: behold, all the land is before you; where it seems good and right to you to go, there go.",
                "Now while he had not yet gone back, Go back then, he said, to Gedaliah the son of Ahikam, the son of Shaphan, whom the king of Babylon has made governor over the cities of Judah, and dwell with him among the people; or go wherever it seems right to you to go. So the captain of the guard gave him food and a present, and let him go.",
                "Then went Jeremiah to Gedaliah the son of Ahikam to Mizpah, and lived with him among the people who were left in the land.",
                "Now when all the captains of the forces who were in the fields, even they and their men, heard that the king of Babylon had made Gedaliah the son of Ahikam governor in the land, and had committed to him men, and women, and children, and of the poorest of the land, of those who were not carried away captive to Babylon;",
                "then they came to Gedaliah to Mizpah, Ishmael the son of Nethaniah, and Johanan and Jonathan the sons of Kareah, and Seraiah the son of Tanhumeth, and the sons of Ephai the Netophathite, and Jezaniah the son of the Maacathite, they and their men.",
                "Gedaliah the son of Ahikam the son of Shaphan swore to them and to their men, saying, Don\u2019t be afraid to serve the Chaldeans: dwell in the land, and serve the king of Babylon, and it shall be well with you.",
                "As for me, behold, I will dwell at Mizpah, to stand before the Chaldeans who shall come to us: but you, gather wine and summer fruits and oil, and put them in your vessels, and dwell in your cities that you have taken.",
                "Likewise when all the Jews who were in Moab, and among the children of Ammon, and in Edom, and who were in all the countries, heard that the king of Babylon had left a remnant of Judah, and that he had set over them Gedaliah the son of Ahikam, the son of Shaphan;",
                "then all the Jews returned out of all places where they were driven, and came to the land of Judah, to Gedaliah, to Mizpah, and gathered wine and summer fruits very much.",
                "Moreover Johanan the son of Kareah, and all the captains of the forces who were in the fields, came to Gedaliah to Mizpah,",
                "and said to him, Do you know that Baalis the king of the children of Ammon has sent Ishmael the son of Nethaniah to take your life? But Gedaliah the son of Ahikam didn\u2019t believe them.",
                "Then Johanan the son of Kareah spoke to Gedaliah in Mizpah secretly, saying, Please let me go, and I will kill Ishmael the son of Nethaniah, and no man shall know it: why should he take your life, that all the Jews who are gathered to you should be scattered, and the remnant of Judah perish?",
                "But Gedaliah the son of Ahikam said to Johanan the son of Kareah, You shall not do this thing; for you speak falsely of Ishmael."
            ],
            41: [
                "Now it happened in the seventh month, that Ishmael the son of Nethaniah, the son of Elishama, of the seed royal and one of the chief officers of the king, and ten men with him, came to Gedaliah the son of Ahikam to Mizpah; and there they ate bread together in Mizpah.",
                "Then arose Ishmael the son of Nethaniah, and the ten men who were with him, and struck Gedaliah the son of Ahikam the son of Shaphan with the sword, and killed him, whom the king of Babylon had made governor over the land.",
                "Ishmael also killed all the Jews who were with him, with Gedaliah, at Mizpah, and the Chaldeans who were found there, the men of war.",
                "It happened the second day after he had killed Gedaliah, and no man knew it,",
                "that there came men from Shechem, from Shiloh, and from Samaria, even eighty men, having their beards shaved and their clothes torn, and having cut themselves, with meal offerings and frankincense in their hand, to bring them to the house of Yahweh.",
                "Ishmael the son of Nethaniah went forth from Mizpah to meet them, weeping all along as he went: and it happened, as he met them, he said to them, Come to Gedaliah the son of Ahikam.",
                "It was so, when they came into the midst of the city, that Ishmael the son of Nethaniah killed them, and cast them into the midst of the pit, he, and the men who were with him.",
                "But ten men were found among those who said to Ishmael, Don\u2019t kill us; for we have stores hidden in the field, of wheat, and of barley, and of oil, and of honey. So he stopped, and didn\u2019t kill them among their brothers.",
                "Now the pit in which Ishmael cast all the dead bodies of the men whom he had killed, by the side of Gedaliah (the same was who which Asa the king had made for fear of Baasha king of Israel), Ishmael the son of Nethaniah filled it with those who were killed.",
                "Then Ishmael carried away captive all the residue of the people who were in Mizpah, even the king\u2019s daughters, and all the people who remained in Mizpah, whom Nebuzaradan the captain of the guard had committed to Gedaliah the son of Ahikam; Ishmael the son of Nethaniah carried them away captive, and departed to go over to the children of Ammon.",
                "But when Johanan the son of Kareah, and all the captains of the forces who were with him, heard of all the evil that Ishmael the son of Nethaniah had done,",
                "then they took all the men, and went to fight with Ishmael the son of Nethaniah, and found him by the great waters that are in Gibeon.",
                "Now it happened that, when all the people who were with Ishmael saw Johanan the son of Kareah, and all the captains of the forces who were with him, then they were glad.",
                "So all the people who Ishmael had carried away captive from Mizpah turned about and came back, and went to Johanan the son of Kareah.",
                "But Ishmael the son of Nethaniah escaped from Johanan with eight men, and went to the children of Ammon.",
                "Then took Johanan the son of Kareah, and all the captains of the forces who were with him, all the remnant of the people whom he had recovered from Ishmael the son of Nethaniah, from Mizpah, after that he had killed Gedaliah the son of Ahikam, the men of war, and the women, and the children, and the eunuchs, whom he had brought back from Gibeon:",
                "and they departed, and lived in Geruth Chimham, which is by Bethlehem, to go to enter into Egypt,",
                "because of the Chaldeans; for they were afraid of them, because Ishmael the son of Nethaniah had killed Gedaliah the son of Ahikam, whom the king of Babylon made governor over the land."
            ],
            42: [
                "Then all the captains of the forces, and Johanan the son of Kareah, and Jezaniah the son of Hoshaiah, and all the people from the least even to the greatest, came near,",
                "and said to Jeremiah the prophet, Please let our supplication be presented before you, and pray for us to Yahweh your God, even for all this remnant; for we are left but a few of many, as your eyes do see us:",
                "that Yahweh your God may show us the way in which we should walk, and the thing that we should do.",
                "Then Jeremiah the prophet said to them, I have heard you; behold, I will pray to Yahweh your God according to your words; and it shall happen that whatever thing Yahweh shall answer you, I will declare it to you; I will keep nothing back from you.",
                "Then they said to Jeremiah, Yahweh be a true and faithful witness among us, if we don\u2019t do according to all the word with which Yahweh your God shall send you to us.",
                "Whether it be good, or whether it be evil, we will obey the voice of Yahweh our God, to whom we send you; that it may be well with us, when we obey the voice of Yahweh our God.",
                "It happened after ten days, that the word of Yahweh came to Jeremiah.",
                "Then called he Johanan the son of Kareah, and all the captains of the forces who were with him, and all the people from the least even to the greatest,",
                "and said to them, Thus says Yahweh, the God of Israel, to whom you sent me to present your supplication before him:",
                "If you will still live in this land, then will I build you, and not pull you down, and I will plant you, and not pluck you up; for I grieve over the distress that I have brought on you.",
                "Don\u2019t be afraid of the king of Babylon, of whom you are afraid; don\u2019t be afraid of him, says Yahweh: for I am with you to save you, and to deliver you from his hand.",
                "I will grant you mercy, that he may have mercy on you, and cause you to return to your own land.",
                "But if you say, We will not dwell in this land; so that you don\u2019t obey the voice of Yahweh your God,",
                "saying, No; but we will go into the land of Egypt, where we shall see no war, nor hear the sound of the trumpet, nor have hunger of bread; and there will we dwell:",
                "now therefore hear the word of Yahweh, O remnant of Judah: Thus says Yahweh of Armies, the God of Israel, If you indeed set your faces to enter into Egypt, and go to live there;",
                "then it shall happen, that the sword, which you fear, shall overtake you there in the land of Egypt; and the famine, about which you are afraid, shall follow close behind you there in Egypt; and there you shall die.",
                "So shall it be with all the men who set their faces to go into Egypt to live there: they shall die by the sword, by the famine, and by the pestilence; and none of them shall remain or escape from the evil that I will bring on them.",
                "For thus says Yahweh of Armies, the God of Israel: As my anger and my wrath has been poured forth on the inhabitants of Jerusalem, so shall my wrath be poured forth on you, when you shall enter into Egypt; and you shall be an object of horror, and an astonishment, and a curse, and a reproach; and you shall see this place no more.",
                "Yahweh has spoken concerning you, remnant of Judah, Don\u2019t you go into Egypt: know certainly that I have testified to you this day.",
                "For you have dealt deceitfully against your own souls; for you sent me to Yahweh your God, saying, Pray for us to Yahweh our God; and according to all that Yahweh our God shall say, so declare to us, and we will do it:",
                "and I have this day declared it to you; but you have not obeyed the voice of Yahweh your God in anything for which he has sent me to you.",
                "Now therefore know certainly that you shall die by the sword, by the famine, and by the pestilence, in the place where you desire to go to live there."
            ],
            43: [
                "It happened that, when Jeremiah had made an end of speaking to all the people all the words of Yahweh their God, with which Yahweh their God had sent him to them, even all these words,",
                "then spoke Azariah the son of Hoshaiah, and Johanan the son of Kareah, and all the proud men, saying to Jeremiah, You speak falsely: Yahweh our God has not sent you to say, You shall not go into Egypt to live there;",
                "but Baruch the son of Neriah sets you on against us, to deliver us into the hand of the Chaldeans, that they may put us to death, and carry us away captive to Babylon.",
                "So Johanan the son of Kareah, and all the captains of the forces, and all the people, didn\u2019t obey the voice of Yahweh, to dwell in the land of Judah.",
                "But Johanan the son of Kareah, and all the captains of the forces, took all the remnant of Judah, who were returned from all the nations where they had been driven, to live in the land of Judah;",
                "the men, and the women, and the children, and the king\u2019s daughters, and every person who Nebuzaradan the captain of the guard had left with Gedaliah the son of Ahikam, the son of Shaphan; and Jeremiah the prophet, and Baruch the son of Neriah;",
                "and they came into the land of Egypt; for they didn\u2019t obey the voice of Yahweh: and they came to Tahpanhes.",
                "Then came the word of Yahweh to Jeremiah in Tahpanhes, saying,",
                "Take great stones in your hand, and hide them in mortar in the brick work, which is at the entry of Pharaoh\u2019s house in Tahpanhes, in the sight of the men of Judah;",
                "and tell them, Thus says Yahweh of Armies, the God of Israel: Behold, I will send and take Nebuchadnezzar the king of Babylon, my servant, and will set his throne on these stones that I have hidden; and he shall spread his royal pavilion over them.",
                "He shall come, and shall strike the land of Egypt; such as are for death shall be put to death, and such as are for captivity to captivity, and such as are for the sword to the sword.",
                "I will kindle a fire in the houses of the gods of Egypt; and he shall burn them, and carry them away captive: and he shall array himself with the land of Egypt, as a shepherd puts on his garment; and he shall go forth from there in peace.",
                "He shall also break the pillars of Beth Shemesh, that is in the land of Egypt; and the houses of the gods of Egypt shall he burn with fire."
            ],
            44: [
                "The word that came to Jeremiah concerning all the Jews who lived in the land of Egypt, who lived at Migdol, and at Tahpanhes, and at Memphis, and in the country of Pathros, saying,",
                "Thus says Yahweh of Armies, the God of Israel: You have seen all the evil that I have brought on Jerusalem, and on all the cities of Judah; and behold, this day they are a desolation, and no man dwells therein,",
                "because of their wickedness which they have committed to provoke me to anger, in that they went to burn incense, to serve other gods, that they didn\u2019t know, neither they, nor you, nor your fathers.",
                "However I sent to you all my servants the prophets, rising up early and sending them, saying, Oh, don\u2019t do this abominable thing that I hate.",
                "But they didn\u2019t listen, nor inclined their ear to turn from their wickedness, to burn no incense to other gods.",
                "Therefore my wrath and my anger was poured forth, and was kindled in the cities of Judah and in the streets of Jerusalem; and they are wasted and desolate, as it is this day.",
                "Therefore now thus says Yahweh, the God of Armies, the God of Israel: Why do you commit great evil against your own souls, to cut off from yourselves man and woman, infant and nursing child out of the midst of Judah, to leave yourselves none remaining;",
                "in that you provoke me to anger with the works of your hands, burning incense to other gods in the land of Egypt, where you have gone to live; that you may be cut off, and that you may be a curse and a reproach among all the nations of the earth?",
                "Have you forgotten the wickedness of your fathers, and the wickedness of the kings of Judah, and the wickedness of their wives, and your own wickedness, and the wickedness of your wives which they committed in the land of Judah, and in the streets of Jerusalem?",
                "They are not humbled even to this day, neither have they feared, nor walked in my law, nor in my statutes, that I set before you and before your fathers.",
                "Therefore thus says Yahweh of Armies, the God of Israel: Behold, I will set my face against you for evil, even to cut off all Judah.",
                "I will take the remnant of Judah, that have set their faces to go into the land of Egypt to live there, and they shall all be consumed; in the land of Egypt shall they fall; they shall be consumed by the sword and by the famine; they shall die, from the least even to the greatest, by the sword and by the famine; and they shall be an object of horror, an astonishment, and a curse, and a reproach.",
                "For I will punish those who dwell in the land of Egypt, as I have punished Jerusalem, by the sword, by the famine, and by the pestilence;",
                "so that none of the remnant of Judah, who have gone into the land of Egypt to live there, shall escape or be left, to return into the land of Judah, to which they have a desire to return to dwell there: for none shall return save such as shall escape.",
                "Then all the men who knew that their wives burned incense to other gods, and all the women who stood by, a great assembly, even all the people who lived in the land of Egypt, in Pathros, answered Jeremiah, saying,",
                "As for the word that you have spoken to us in the name of Yahweh, we will not listen to you.",
                "But we will certainly perform every word that is gone forth out of our mouth, to burn incense to the queen of the sky, and to pour out drink offerings to her, as we have done, we and our fathers, our kings and our princes, in the cities of Judah, and in the streets of Jerusalem; for then had we plenty of food, and were well, and saw no evil.",
                "But since we left off burning incense to the queen of the sky, and pouring out drink offerings to her, we have wanted all things, and have been consumed by the sword and by the famine.",
                "When we burned incense to the queen of the sky, and poured out drink offerings to her, did we make her cakes to worship her, and pour out drink offerings to her, without our husbands?",
                "Then Jeremiah said to all the people, to the men, and to the women, even to all the people who had given him an answer, saying,",
                "The incense that you burned in the cities of Judah, and in the streets of Jerusalem, you and your fathers, your kings and your princes, and the people of the land, didn\u2019t Yahweh remember them, and didn\u2019t it come into his mind?",
                "so that Yahweh could no longer bear, because of the evil of your doings, and because of the abominations which you have committed; therefore your land has become a desolation, and an astonishment, and a curse, without inhabitant, as it is this day.",
                "Because you have burned incense, and because you have sinned against Yahweh, and have not obeyed the voice of Yahweh, nor walked in his law, nor in his statutes, nor in his testimonies; therefore this evil has happened to you, as it is this day.",
                "Moreover Jeremiah said to all the people, and to all the women, Hear the word of Yahweh, all Judah who are in the land of Egypt:",
                "Thus says Yahweh of Armies, the God of Israel, saying, You and your wives have both spoken with your mouths, and with your hands have fulfilled it, saying, We will surely perform our vows that we have vowed, to burn incense to the queen of the sky, and to pour out drink offerings to her: establish then your vows, and perform your vows.",
                "Therefore hear the word of Yahweh, all Judah who dwell in the land of Egypt: Behold, I have sworn by my great name, says Yahweh, that my name shall no more be named in the mouth of any man of Judah in all the land of Egypt, saying, As the Lord Yahweh lives.",
                "Behold, I watch over them for evil, and not for good; and all the men of Judah who are in the land of Egypt shall be consumed by the sword and by the famine, until they are all gone.",
                "Those who escape the sword shall return out of the land of Egypt into the land of Judah, few in number; and all the remnant of Judah, who have gone into the land of Egypt to live there, shall know whose word shall stand, mine, or theirs.",
                "This shall be the sign to you, says Yahweh, that I will punish you in this place, that you may know that my words shall surely stand against you for evil:",
                "Thus says Yahweh, Behold, I will give Pharaoh Hophra king of Egypt into the hand of his enemies, and into the hand of those who seek his life; as I gave Zedekiah king of Judah into the hand of Nebuchadnezzar king of Babylon, who was his enemy, and sought his life."
            ],
            45: [
                "The message that Jeremiah the prophet spoke to Baruch the son of Neriah, when he wrote these words in a book at the mouth of Jeremiah, in the fourth year of Jehoiakim the son of Josiah, king of Judah, saying,",
                "Thus says Yahweh, the God of Israel, to you, Baruch:",
                "You said, Woe is me now! for Yahweh has added sorrow to my pain; I am weary with my groaning, and I find no rest.",
                "You shall tell him, Thus says Yahweh: Behold, that which I have built will I break down, and that which I have planted I will pluck up; and this in the whole land.",
                "Do you seek great things for yourself? Don\u2019t seek them; for, behold, I will bring evil on all flesh, says Yahweh; but your life will I give to you for a prey in all places where you go."
            ],
            46: [
                "The word of Yahweh which came to Jeremiah the prophet concerning the nations.",
                "Of Egypt: concerning the army of Pharaoh Necoh king of Egypt, which was by the river Euphrates in Carchemish, which Nebuchadnezzar king of Babylon struck in the fourth year of Jehoiakim the son of Josiah, king of Judah.",
                "Prepare the buckler and shield, and draw near to battle!",
                "Harness the horses, and get up, you horsemen, and stand forth with your helmets; furbish the spears, put on the coats of mail.",
                "Why have I seen it? they are dismayed and are turned backward; and their mighty ones are beaten down, and have fled apace, and don\u2019t look back: terror is on every side, says Yahweh.",
                "Don\u2019t let the swift flee away, nor the mighty man escape; in the north by the river Euphrates have they stumbled and fallen.",
                "Who is this who rises up like the Nile, whose waters toss themselves like the rivers?",
                "Egypt rises up like the Nile, and his waters toss themselves like the rivers: and he says, I will rise up, I will cover the earth; I will destroy cities and its inhabitants.",
                "Go up, you horses; and rage, you chariots; and let the mighty men go forth: Cush and Put, who handle the shield; and the Ludim, who handle and bend the bow.",
                "For that day is of the Lord, Yahweh of Armies, a day of vengeance, that he may avenge him of his adversaries: and the sword shall devour and be satiate, and shall drink its fill of their blood; for the Lord, Yahweh of Armies, has a sacrifice in the north country by the river Euphrates.",
                "Go up into Gilead, and take balm, virgin daughter of Egypt: in vain do you use many medicines; there is no healing for you.",
                "The nations have heard of your shame, and the earth is full of your cry; for the mighty man has stumbled against the mighty, they are fallen both of them together.",
                "The word that Yahweh spoke to Jeremiah the prophet, how that Nebuchadnezzar king of Babylon should come and strike the land of Egypt.",
                "Declare in Egypt, and publish in Migdol, and publish in Memphis and in Tahpanhes: say, Stand forth, and prepare; for the sword has devoured around you.",
                "Why are your strong ones swept away? they didn\u2019t stand, because Yahweh pushed them.",
                "He made many to stumble, yes, they fell one on another: and they said, Arise, and let us go again to our own people, and to the land of our birth, from the oppressing sword.",
                "They cried there, Pharaoh king of Egypt is but a noise; he has let the appointed time pass by.",
                "As I live, says the King, whose name is Yahweh of Armies, surely like Tabor among the mountains, and like Carmel by the sea, so shall he come.",
                "You daughter who dwells in Egypt, furnish yourself to go into captivity; for Memphis shall become a desolation, and shall be burnt up, without inhabitant.",
                "Egypt is a very beautiful heifer; but destruction out of the north has come, it has come.",
                "Also her hired men in the midst of her are like calves of the stall; for they also are turned back, they are fled away together, they didn\u2019t stand: for the day of their calamity has come on them, the time of their visitation.",
                "The sound of it shall go like the serpent; for they shall march with an army, and come against her with axes, as wood cutters.",
                "They shall cut down her forest, says Yahweh, though it can\u2019t be searched; because they are more than the locusts, and are innumerable.",
                "The daughter of Egypt shall be disappointed; she shall be delivered into the hand of the people of the north.",
                "Yahweh of Armies, the God of Israel, says: Behold, I will punish Amon of No, and Pharaoh, and Egypt, with her gods, and her kings; even Pharaoh, and those who trust in him:",
                "and I will deliver them into the hand of those who seek their lives, and into the hand of Nebuchadnezzar king of Babylon, and into the hand of his servants; and afterwards it shall be inhabited, as in the days of old, says Yahweh.",
                "But don\u2019t you be afraid, Jacob my servant, neither be dismayed, Israel: for, behold, I will save you from afar, and your seed from the land of their captivity; and Jacob shall return, and shall be quiet and at ease, and none shall make him afraid.",
                "Don\u2019t you be afraid, O Jacob my servant, says Yahweh; for I am with you: for I will make a full end of all the nations where I have driven you; but I will not make a full end of you, but I will correct you in measure, and will in no way leave you unpunished."
            ],
            47: [
                "The word of Yahweh that came to Jeremiah the prophet concerning the Philistines, before that Pharaoh struck Gaza.",
                "Thus says Yahweh: Behold, waters rise up out of the north, and shall become an overflowing stream, and shall overflow the land and all that is therein, the city and those who dwell therein; and the men shall cry, and all the inhabitants of the land shall wail.",
                "At the noise of the stamping of the hoofs of his strong ones, at the rushing of his chariots, at the rumbling of his wheels, the fathers don\u2019t look back to their children for feebleness of hands;",
                "because of the day that comes to destroy all the Philistines, to cut off from Tyre and Sidon every helper who remains: for Yahweh will destroy the Philistines, the remnant of the isle of Caphtor.",
                "Baldness has come on Gaza; Ashkelon is brought to nothing, the remnant of their valley: how long will you cut yourself?",
                "You sword of Yahweh, how long will it be before you be quiet? Put up yourself into your scabbard; rest, and be still.",
                "How can you be quiet, since Yahweh has given you a command? Against Ashkelon, and against the seashore, there has he appointed it."
            ],
            48: [
                "Of Moab. Thus says Yahweh of Armies, the God of Israel: Woe to Nebo! for it is laid waste; Kiriathaim is disappointed, it is taken; Misgab is put to shame and broken down.",
                "The praise of Moab is no more; in Heshbon they have devised evil against her: Come, and let us cut her off from being a nation. You also, Madmen, shall be brought to silence: the sword shall pursue you.",
                "The sound of a cry from Horonaim, desolation and great destruction!",
                "Moab is destroyed; her little ones have caused a cry to be heard.",
                "For by the ascent of Luhith with continual weeping shall they go up; for at the descent of Horonaim they have heard the distress of the cry of destruction.",
                "Flee, save your lives, and be like the heath in the wilderness.",
                "For, because you have trusted in your works and in your treasures, you also shall be taken: and Chemosh shall go forth into captivity, his priests and his princes together.",
                "The destroyer shall come on every city, and no city shall escape; the valley also shall perish, and the plain shall be destroyed; as Yahweh has spoken.",
                "Give wings to Moab, that she may fly and get her away: and her cities shall become a desolation, without any to dwell therein.",
                "Cursed is he who does the work of Yahweh negligently; and cursed is he who keeps back his sword from blood.",
                "Moab has been at ease from his youth, and he has settled on his lees, and has not been emptied from vessel to vessel, neither has he gone into captivity: therefore his taste remains in him, and his scent is not changed.",
                "Therefore, behold, the days come, says Yahweh, that I will send to him those who pour off, and they shall pour him off; and they shall empty his vessels, and break their bottles in pieces.",
                "Moab shall be ashamed of Chemosh, as the house of Israel was ashamed of Bethel their confidence.",
                "How do you say, We are mighty men, and valiant men for the war?",
                "Moab is laid waste, and they are gone up into his cities, and his chosen young men are gone down to the slaughter, says the King, whose name is Yahweh of Armies.",
                "The calamity of Moab is near to come, and his affliction hurries fast.",
                "All you who are around him, bemoan him, and all you who know his name; say, How is the strong staff broken, the beautiful rod!",
                "You daughter who dwells in Dibon, come down from your glory, and sit in thirst; for the destroyer of Moab has come up against you, he has destroyed your strongholds.",
                "Inhabitant of Aroer, stand by the way, and watch: ask him who flees, and her who escapes; say, What has been done?",
                "Moab is disappointed; for it is broken down: wail and cry; tell it by the Arnon, that Moab is laid waste.",
                "Judgment has come on the plain country, on Holon, and on Jahzah, and on Mephaath,",
                "and on Dibon, and on Nebo, and on Beth Diblathaim,",
                "and on Kiriathaim, and on Beth Gamul, and on Beth Meon,",
                "and on Kerioth, and on Bozrah, and on all the cities of the land of Moab, far or near.",
                "The horn of Moab is cut off, and his arm is broken, says Yahweh.",
                "Make him drunken; for he magnified himself against Yahweh: and Moab shall wallow in his vomit, and he also shall be in derision.",
                "For wasn\u2019t Israel a derision to you? was he found among thieves? for as often as you speak of him, you shake your head.",
                "You inhabitants of Moab, leave the cities, and dwell in the rock; and be like the dove that makes her nest over the mouth of the abyss.",
                "We have heard of the pride of Moab. He is very proud; his loftiness, and his pride, and his arrogance, and the haughtiness of his heart.",
                "I know his wrath, says Yahweh, that it is nothing; his boastings have worked nothing.",
                "Therefore will I wail for Moab; yes, I will cry out for all Moab: for the men of Kir Heres shall they mourn.",
                "With more than the weeping of Jazer will I weep for you, vine of Sibmah: your branches passed over the sea, they reached even to the sea of Jazer: on your summer fruits and on your vintage the destroyer is fallen.",
                "Gladness and joy is taken away from the fruitful field and from the land of Moab; and I have caused wine to cease from the wine presses: none shall tread with shouting; the shouting shall be no shouting.",
                "From the cry of Heshbon even to Elealeh, even to Jahaz have they uttered their voice, from Zoar even to Horonaim, to Eglath Shelishiyah: for the waters of Nimrim also shall become desolate.",
                "Moreover I will cause to cease in Moab, says Yahweh, him who offers in the high place, and him who burns incense to his gods.",
                "Therefore my heart sounds for Moab like pipes, and my heart sounds like pipes for the men of Kir Heres: therefore the abundance that he has gotten is perished.",
                "For every head is bald, and every beard clipped: on all the hands are cuttings, and on the waist sackcloth.",
                "On all the housetops of Moab and in its streets there is lamentation every where; for I have broken Moab like a vessel in which none delights, says Yahweh.",
                "How it is broken down! How they wail! How Moab has turned the back with shame! So shall Moab become a derision and a terror to all who are around him.",
                "For thus says Yahweh: Behold, he shall fly as an eagle, and shall spread out his wings against Moab.",
                "Kerioth is taken, and the strongholds are seized, and the heart of the mighty men of Moab at that day shall be as the heart of a woman in her pangs.",
                "Moab shall be destroyed from being a people, because he has magnified himself against Yahweh.",
                "Fear, and the pit, and the snare, are on you, inhabitant of Moab, says Yahweh.",
                "He who flees from the fear shall fall into the pit; and he who gets up out of the pit shall be taken in the snare: for I will bring on him, even on Moab, the year of their visitation, says Yahweh.",
                "Those who fled stand without strength under the shadow of Heshbon; for a fire is gone forth out of Heshbon, and a flame from the midst of Sihon, and has devoured the corner of Moab, and the crown of the head of the tumultuous ones.",
                "Woe to you, O Moab! the people of Chemosh is undone; for your sons are taken away captive, and your daughters into captivity.",
                "Yet will I bring back the captivity of Moab in the latter days, says Yahweh. Thus far is the judgment of Moab."
            ],
            49: [
                "Of the children of Ammon. Thus says Yahweh: Has Israel no sons? has he no heir? why then does Malcam possess Gad, and his people well in its cities?",
                "Therefore, behold, the days come, says Yahweh, that I will cause an alarm of war to be heard against Rabbah of the children of Ammon; and it shall become a desolate heap, and her daughters shall be burned with fire: then shall Israel possess those who possessed him, says Yahweh.",
                "Wail, Heshbon, for Ai is laid waste; cry, you daughters of Rabbah, clothe yourself in sackcloth: lament, and run back and forth among the fences; for Malcam shall go into captivity, his priests and his princes together.",
                "Why do you glory in the valleys, your flowing valley, backsliding daughter? who trusted in her treasures, saying, Who shall come to me?",
                "Behold, I will bring a fear on you, says the Lord, Yahweh of Armies, from all who are around you; and you shall be driven out every man right forth, and there shall be none to gather together the fugitives.",
                "But afterward I will bring back the captivity of the children of Ammon, says Yahweh.",
                "Of Edom. Thus says Yahweh of Armies: Is wisdom no more in Teman? is counsel perished from the prudent? is their wisdom vanished?",
                "Flee, turn back, dwell in the depths, inhabitants of Dedan; for I will bring the calamity of Esau on him, the time that I shall visit him.",
                "If grape gatherers came to you, would they not leave some gleaning grapes? if thieves by night, wouldn\u2019t they destroy until they had enough?",
                "But I have made Esau bare, I have uncovered his secret places, and he shall not be able to hide himself: his seed is destroyed, and his brothers, and his neighbors; and he is no more.",
                "Leave your fatherless children, I will preserve them alive; and let your widows trust in me.",
                "For thus says Yahweh: Behold, they to whom it didn\u2019t pertain to drink of the cup shall certainly drink; and are you he who shall altogether go unpunished? You shall not go unpunished, but you shall surely drink.",
                "For I have sworn by myself, says Yahweh, that Bozrah shall become an astonishment, a reproach, a waste, and a curse; and all its cities shall be perpetual wastes.",
                "I have heard news from Yahweh, and an ambassador is sent among the nations, saying, Gather yourselves together, and come against her, and rise up to the battle.",
                "For, behold, I have made you small among the nations, and despised among men.",
                "As for your terror, the pride of your heart has deceived you, O you who dwell in the clefts of the rock, who hold the height of the hill: though you should make your nest as high as the eagle, I will bring you down from there, says Yahweh.",
                "Edom shall become an astonishment: everyone who passes by it shall be astonished, and shall hiss at all its plagues.",
                "As in the overthrow of Sodom and Gomorrah and the neighbor cities of it, says Yahweh, no man shall dwell there, neither shall any son of man live therein.",
                "Behold, he shall come up like a lion from the pride of the Jordan against the strong habitation: for I will suddenly make them run away from it; and whoever is chosen, him will I appoint over it: for who is like me? and who will appoint me a time? and who is the shepherd who will stand before me?",
                "Therefore hear the counsel of Yahweh, that he has taken against Edom; and his purposes, that he has purposed against the inhabitants of Teman: Surely they shall drag them away, the little ones of the flock; surely he shall make their habitation desolate over them.",
                "The earth trembles at the noise of their fall; there is a cry, the noise which is heard in the Red Sea.",
                "Behold, he shall come up and fly as the eagle, and spread out his wings against Bozrah: and the heart of the mighty men of Edom at that day shall be as the heart of a woman in her pangs.",
                "Of Damascus. Hamath is confounded, and Arpad; for they have heard evil news, they are melted away: there is sorrow on the sea; it can\u2019t be quiet.",
                "Damascus has grown feeble, she turns herself to flee, and trembling has seized on her: anguish and sorrows have taken hold of her, as of a woman in travail.",
                "How is the city of praise not forsaken, the city of my joy?",
                "Therefore her young men shall fall in her streets, and all the men of war shall be brought to silence in that day, says Yahweh of Armies.",
                "I will kindle a fire in the wall of Damascus, and it shall devour the palaces of Ben Hadad.",
                "Of Kedar, and of the kingdoms of Hazor, which Nebuchadnezzar king of Babylon struck. Thus says Yahweh: Arise, go up to Kedar, and destroy the children of the east.",
                "Their tents and their flocks shall they take; they shall carry away for themselves their curtains, and all their vessels, and their camels; and they shall cry to them, Terror on every side!",
                "Flee, wander far off, dwell in the depths, you inhabitants of Hazor, says Yahweh; for Nebuchadnezzar king of Babylon has taken counsel against you, and has conceived a purpose against you.",
                "Arise, go up to a nation that is at ease, that dwells without care, says Yahweh; that have neither gates nor bars, that dwell alone.",
                "Their camels shall be a booty, and the multitude of their livestock a spoil: and I will scatter to all winds those who have the corners of their beards cut off; and I will bring their calamity from every side of them, says Yahweh.",
                "Hazor shall be a dwelling place of jackals, a desolation forever: no man shall dwell there, neither shall any son of man live therein.",
                "The word of Yahweh that came to Jeremiah the prophet concerning Elam, in the beginning of the reign of Zedekiah king of Judah, saying,",
                "Thus says Yahweh of Armies: Behold, I will break the bow of Elam, the chief of their might.",
                "On Elam will I bring the four winds from the four quarters of the sky, and will scatter them toward all those winds; and there shall be no nation where the outcasts of Elam shall not come.",
                "I will cause Elam to be dismayed before their enemies, and before those who seek their life; and I will bring evil on them, even my fierce anger, says Yahweh; and I will send the sword after them, until I have consumed them;",
                "and I will set my throne in Elam, and will destroy from there king and princes, says Yahweh.",
                "But it shall happen in the latter days, that I will bring back the captivity of Elam, says Yahweh."
            ],
            50: [
                "The word that Yahweh spoke concerning Babylon, concerning the land of the Chaldeans, by Jeremiah the prophet.",
                "Declare among the nations and publish, and set up a standard; publish, and don\u2019t conceal: say, Babylon is taken, Bel is disappointed, Merodach is dismayed; her images are disappointed, her idols are dismayed.",
                "For out of the north there comes up a nation against her, which shall make her land desolate, and none shall dwell therein: they are fled, they are gone, both man and animal.",
                "In those days, and in that time, says Yahweh, the children of Israel shall come, they and the children of Judah together; they shall go on their way weeping, and shall seek Yahweh their God.",
                "They shall inquire concerning Zion with their faces turned toward it, saying, Come, and join yourselves to Yahweh in an everlasting covenant that shall not be forgotten.",
                "My people have been lost sheep: their shepherds have caused them to go astray; they have turned them away on the mountains; they have gone from mountain to hill; they have forgotten their resting place.",
                "All who found them have devoured them; and their adversaries said, We are not guilty, because they have sinned against Yahweh, the habitation of righteousness, even Yahweh, the hope of their fathers.",
                "Flee out of the midst of Babylon, and go forth out of the land of the Chaldeans, and be as the male goats before the flocks.",
                "For, behold, I will stir up and cause to come up against Babylon a company of great nations from the north country; and they shall set themselves in array against her; from there she shall be taken: their arrows shall be as of an expert mighty man; none shall return in vain.",
                "Chaldea shall be a prey: all who prey on her shall be satisfied, says Yahweh.",
                "Because you are glad, because you rejoice, O you who plunder my heritage, because you are wanton as a heifer that treads out the grain, and neigh as strong horses;",
                "your mother shall be utterly disappointed; she who bore you shall be confounded: behold, she shall be the least of the nations, a wilderness, a dry land, and a desert.",
                "Because of the wrath of Yahweh she shall not be inhabited, but she shall be wholly desolate: everyone who goes by Babylon shall be astonished, and hiss at all her plagues.",
                "Set yourselves in array against Babylon all around, all you who bend the bow; shoot at her, spare no arrows: for she has sinned against Yahweh.",
                "Shout against her all around: she has submitted herself; her bulwarks are fallen, her walls are thrown down; for it is the vengeance of Yahweh: take vengeance on her; as she has done, do to her.",
                "Cut off the sower from Babylon, and him who handles the sickle in the time of harvest: for fear of the oppressing sword they shall turn everyone to his people, and they shall flee everyone to his own land.",
                "Israel is a hunted sheep; the lions have driven him away: first, the king of Assyria devoured him; and now at last Nebuchadnezzar king of Babylon has broken his bones.",
                "Therefore thus says Yahweh of Armies, the God of Israel: Behold, I will punish the king of Babylon and his land, as I have punished the king of Assyria.",
                "I will bring Israel again to his pasture, and he shall feed on Carmel and Bashan, and his soul shall be satisfied on the hills of Ephraim and in Gilead.",
                "In those days, and in that time, says Yahweh, the iniquity of Israel shall be sought for, and there shall be none; and the sins of Judah, and they shall not be found: for I will pardon them whom I leave as a remnant.",
                "Go up against the land of Merathaim, even against it, and against the inhabitants of Pekod: kill and utterly destroy after them, says Yahweh, and do according to all that I have commanded you.",
                "A sound of battle is in the land, and of great destruction.",
                "How is the hammer of the whole earth cut apart and broken! how is Babylon become a desolation among the nations!",
                "I have laid a snare for you, and you are also taken, Babylon, and you weren\u2019t aware: you are found, and also caught, because you have striven against Yahweh.",
                "Yahweh has opened his armory, and has brought forth the weapons of his indignation; for the Lord, Yahweh of Armies, has a work to do in the land of the Chaldeans.",
                "Come against her from the utmost border; open her storehouses; cast her up as heaps, and destroy her utterly; let nothing of her be left.",
                "Kill all her bulls; let them go down to the slaughter: woe to them! for their day has come, the time of their visitation.",
                "The voice of those who flee and escape out of the land of Babylon, to declare in Zion the vengeance of Yahweh our God, the vengeance of his temple.",
                "Call together the archers against Babylon, all those who bend the bow; encamp against her all around; let none of it escape: recompense her according to her work; according to all that she has done, do to her; for she has been proud against Yahweh, against the Holy One of Israel.",
                "Therefore her young men will fall in her streets, and all her men of war will be brought to silence in that day, says Yahweh.",
                "Behold, I am against you, you proud one, says the Lord, Yahweh of Armies; for your day has come, the time that I will visit you.",
                "The proud one shall stumble and fall, and none shall raise him up; and I will kindle a fire in his cities, and it shall devour all who are around him.",
                "Thus says Yahweh of Armies: The children of Israel and the children of Judah are oppressed together; and all who took them captive hold them fast; they refuse to let them go.",
                "Their Redeemer is strong; Yahweh of Armies is his name: he will thoroughly plead their cause, that he may give rest to the earth, and disquiet the inhabitants of Babylon.",
                "A sword is on the Chaldeans, says Yahweh, and on the inhabitants of Babylon, and on her princes, and on her wise men.",
                "A sword is on the boasters, and they shall become fools; a sword is on her mighty men, and they shall be dismayed.",
                "A sword is on their horses, and on their chariots, and on all the mixed people who are in the midst of her; and they shall become as women: a sword is on her treasures, and they shall be robbed.",
                "A drought is on her waters, and they shall be dried up; for it is a land of engraved images, and they are mad over idols.",
                "Therefore the wild animals of the desert with the wolves shall dwell there, and the ostriches shall dwell therein: and it shall be no more inhabited forever; neither shall it be lived in from generation to generation.",
                "As when God overthrew Sodom and Gomorrah and the neighbor cities of it, says Yahweh, so shall no man dwell there, neither shall any son of man live therein.",
                "Behold, a people comes from the north; and a great nation and many kings shall be stirred up from the uttermost parts of the earth.",
                "They lay hold on bow and spear; they are cruel, and have no mercy; their voice roars like the sea; and they ride on horses, everyone set in array, as a man to the battle, against you, daughter of Babylon.",
                "The king of Babylon has heard the news of them, and his hands wax feeble: anguish has taken hold of him, pains as of a woman in labor.",
                "Behold, the enemy shall come up like a lion from the pride of the Jordan against the strong habitation: for I will suddenly make them run away from it; and whoever is chosen, him will I appoint over it: for who is like me? and who will appoint me a time? and who is the shepherd who can stand before me?",
                "Therefore hear the counsel of Yahweh, that he has taken against Babylon; and his purposes, that he has purposed against the land of the Chaldeans: Surely they shall drag them away, even the little ones of the flock; surely he shall make their habitation desolate over them.",
                "At the noise of the taking of Babylon the earth trembles, and the cry is heard among the nations."
            ],
            51: [
                "Thus says Yahweh: Behold, I will raise up against Babylon, and against those who dwell in Lebkamai, a destroying wind.",
                "I will send to Babylon strangers, who shall winnow her; and they shall empty her land: for in the day of trouble they shall be against her around.",
                "Against him who bends let the archer bend his bow, and against him who lifts himself up in his coat of mail: and don\u2019t spare her young men; utterly destroy all her army.",
                "They shall fall down slain in the land of the Chaldeans, and thrust through in her streets.",
                "For Israel is not forsaken, nor Judah, of his God, of Yahweh of Armies; though their land is full of guilt against the Holy One of Israel.",
                "Flee out of the midst of Babylon, and save every man his life; don\u2019t be cut off in her iniquity: for it is the time of Yahweh\u2019s vengeance; he will render to her a recompense.",
                "Babylon has been a golden cup in Yahweh\u2019s hand, who made all the earth drunken: the nations have drunk of her wine; therefore the nations are mad.",
                "Babylon is suddenly fallen and destroyed: wail for her; take balm for her pain, if so be she may be healed.",
                "We would have healed Babylon, but she is not healed: forsake her, and let us go everyone into his own country; for her judgment reaches to heaven, and is lifted up even to the skies.",
                "Yahweh has brought forth our righteousness: come, and let us declare in Zion the work of Yahweh our God.",
                "Make sharp the arrows; hold firm the shields: Yahweh has stirred up the spirit of the kings of the Medes; because his purpose is against Babylon, to destroy it: for it is the vengeance of Yahweh, the vengeance of his temple.",
                "Set up a standard against the walls of Babylon, make the watch strong, set the watchmen, prepare the ambushes; for Yahweh has both purposed and done that which he spoke concerning the inhabitants of Babylon.",
                "You who dwell on many waters, abundant in treasures, your end has come, the measure of your covetousness.",
                "Yahweh of Armies has sworn by himself, saying, Surely I will fill you with men, as with the canker worm; and they shall lift up a shout against you.",
                "He has made the earth by his power, he has established the world by his wisdom, and by his understanding has he stretched out the heavens:",
                "when he utters his voice, there is a tumult of waters in the heavens, and he causes the vapors to ascend from the ends of the earth; he makes lightning for the rain, and brings forth the wind out of his treasuries.",
                "Every man has become brutish without knowledge. Every goldsmith is disappointed by his image; for his molten image is falsehood, and there is no breath in them.",
                "They are vanity, a work of delusion: in the time of their visitation they shall perish.",
                "The portion of Jacob is not like these; for he is the former of all things; and is the tribe of his inheritance: Yahweh of Armies is his name.",
                "You are my battle axe and weapons of war: and with you will I break in pieces the nations; and with you will I destroy kingdoms;",
                "and with you will I break in pieces the horse and his rider;",
                "and with you will I break in pieces the chariot and him who rides therein; and with you will I break in pieces man and woman; and with you will I break in pieces the old man and the youth; and with you will I break in pieces the young man and the virgin;",
                "and with you will I break in pieces the shepherd and his flock; and with you will I break in pieces the farmer and his yoke; and with you will I break in pieces governors and deputies.",
                "I will render to Babylon and to all the inhabitants of Chaldea all their evil that they have done in Zion in your sight, says Yahweh.",
                "Behold, I am against you, destroying mountain, says Yahweh, which destroys all the earth; and I will stretch out my hand on you, and roll you down from the rocks, and will make you a burnt mountain.",
                "They shall not take of you a stone for a corner, nor a stone for foundations; but you shall be desolate for ever, says Yahweh.",
                "Set up a standard in the land, blow the trumpet among the nations, prepare the nations against her, call together against her the kingdoms of Ararat, Minni, and Ashkenaz: appoint a marshal against her; cause the horses to come up as the rough canker worm.",
                "Prepare against her the nations, the kings of the Medes, its governors, and all its deputies, and all the land of their dominion.",
                "The land trembles and is in pain; for the purposes of Yahweh against Babylon do stand, to make the land of Babylon a desolation, without inhabitant.",
                "The mighty men of Babylon have forborne to fight, they remain in their strongholds; their might has failed; they are become as women: her dwelling places are set on fire; her bars are broken.",
                "One runner will run to meet another, and one messenger to meet another, to show the king of Babylon that his city is taken on every quarter:",
                "and the passages are seized, and the reeds they have burned with fire, and the men of war are frightened.",
                "For thus says Yahweh of Armies, the God of Israel: The daughter of Babylon is like a threshing floor at the time when it is trodden; yet a little while, and the time of harvest shall come for her.",
                "Nebuchadnezzar the king of Babylon has devoured me, he has crushed me, he has made me an empty vessel, he has, like a monster, swallowed me up, he has filled his maw with my delicacies; he has cast me out.",
                "The violence done to me and to my flesh be on Babylon, shall the inhabitant of Zion say; and, My blood be on the inhabitants of Chaldea, shall Jerusalem say.",
                "Therefore thus says Yahweh: Behold, I will plead your cause, and take vengeance for you; and I will dry up her sea, and make her fountain dry.",
                "Babylon shall become heaps, a dwelling place for jackals, an astonishment, and a hissing, without inhabitant.",
                "They shall roar together like young lions; they shall growl as lions\u2019 cubs.",
                "When they are heated, I will make their feast, and I will make them drunken, that they may rejoice, and sleep a perpetual sleep, and not wake, says Yahweh.",
                "I will bring them down like lambs to the slaughter, like rams with male goats.",
                "How is Sheshach taken! and the praise of the whole earth seized! how is Babylon become a desolation among the nations!",
                "The sea has come up on Babylon; she is covered with the multitude of its waves.",
                "Her cities are become a desolation, a dry land, and a desert, a land in which no man dwells, neither does any son of man pass thereby.",
                "I will execute judgment on Bel in Babylon, and I will bring forth out of his mouth that which he has swallowed up; and the nations shall not flow any more to him: yes, the wall of Babylon shall fall.",
                "My people, go away from the midst of her, and save yourselves every man from the fierce anger of Yahweh.",
                "Don\u2019t let your heart faint, neither fear for the news that shall be heard in the land; for news shall come one year, and after that in another year shall come news, and violence in the land, ruler against ruler.",
                "Therefore, behold, the days come, that I will execute judgment on the engraved images of Babylon; and her whole land shall be confounded; and all her slain shall fall in the midst of her.",
                "Then the heavens and the earth, and all that is therein, shall sing for joy over Babylon; for the destroyers shall come to her from the north, says Yahweh.",
                "As Babylon has caused the slain of Israel to fall, so at Babylon shall fall the slain of all the land.",
                "You who have escaped the sword, go, don\u2019t stand still; remember Yahweh from afar, and let Jerusalem come into your mind.",
                "We are confounded, because we have heard reproach; confusion has covered our faces: for strangers have come into the sanctuaries of Yahweh\u2019s house.",
                "Therefore, behold, the days come, says Yahweh, that I will execute judgment on her engraved images; and through all her land the wounded shall groan.",
                "Though Babylon should mount up to the sky, and though she should fortify the height of her strength, yet from me shall destroyers come to her, says Yahweh.",
                "The sound of a cry from Babylon, and of great destruction from the land of the Chaldeans!",
                "For Yahweh lays Babylon waste, and destroys out of her the great voice; and their waves roar like many waters; the noise of their voice is uttered:",
                "for the destroyer has come on her, even on Babylon, and her mighty men are taken, their bows are broken in pieces; for Yahweh is a God of recompenses, he will surely requite.",
                "I will make drunk her princes and her wise men, her governors and her deputies, and her mighty men; and they shall sleep a perpetual sleep, and not wake up, says the King, whose name is Yahweh of Armies.",
                "Thus says Yahweh of Armies: The broad walls of Babylon shall be utterly overthrown, and her high gates shall be burned with fire; and the peoples shall labor for vanity, and the nations for the fire; and they shall be weary.",
                "The word which Jeremiah the prophet commanded Seraiah the son of Neriah, the son of Mahseiah, when he went with Zedekiah the king of Judah to Babylon in the fourth year of his reign. Now Seraiah was chief quartermaster.",
                "Jeremiah wrote in a book all the evil that should come on Babylon, even all these words that are written concerning Babylon.",
                "Jeremiah said to Seraiah, When you come to Babylon, then see that you read all these words,",
                "and say, Yahweh, you have spoken concerning this place, to cut it off, that none shall dwell therein, neither man nor animal, but that it shall be desolate forever.",
                "It shall be, when you have made an end of reading this book, that you shall bind a stone to it, and cast it into the midst of the Euphrates:",
                "and you shall say, Thus shall Babylon sink, and shall not rise again because of the evil that I will bring on her; and they shall be weary. Thus far are the words of Jeremiah."
            ],
            52: [
                "Zedekiah was twenty-one years old when he began to reign; and he reigned eleven years in Jerusalem: and his mother\u2019s name was Hamutal the daughter of Jeremiah of Libnah.",
                "He did that which was evil in the sight of Yahweh, according to all that Jehoiakim had done.",
                "For through the anger of Yahweh it happened in Jerusalem and Judah, until he had cast them out from his presence. Zedekiah rebelled against the king of Babylon.",
                "It happened in the ninth year of his reign, in the tenth month, in the tenth day of the month, that Nebuchadnezzar king of Babylon came, he and all his army, against Jerusalem, and encamped against it; and they built forts against it round about.",
                "So the city was besieged to the eleventh year of king Zedekiah.",
                "In the fourth month, in the ninth day of the month, the famine was severe in the city, so that there was no bread for the people of the land.",
                "Then a breach was made in the city, and all the men of war fled, and went forth out of the city by night by the way of the gate between the two walls, which was by the king\u2019s garden; (now the Chaldeans were against the city all around;) and they went toward the Arabah.",
                "But the army of the Chaldeans pursued after the king, and overtook Zedekiah in the plains of Jericho; and all his army was scattered from him.",
                "Then they took the king, and carried him up to the king of Babylon to Riblah in the land of Hamath; and he gave judgment on him.",
                "The king of Babylon killed the sons of Zedekiah before his eyes: he killed also all the princes of Judah in Riblah.",
                "He put out the eyes of Zedekiah; and the king of Babylon bound him in fetters, and carried him to Babylon, and put him in prison until the day of his death.",
                "Now in the fifth month, in the tenth day of the month, which was the nineteenth year of king Nebuchadnezzar, king of Babylon, came Nebuzaradan the captain of the guard, who stood before the king of Babylon, into Jerusalem:",
                "and he burned the house of Yahweh, and the king\u2019s house; and all the houses of Jerusalem, even every great house, burned he with fire.",
                "All the army of the Chaldeans, who were with the captain of the guard, broke down all the walls of Jerusalem all around.",
                "Then Nebuzaradan the captain of the guard carried away captive of the poorest of the people, and the residue of the people who were left in the city, and those who fell away, who fell to the king of Babylon, and the residue of the multitude.",
                "But Nebuzaradan the captain of the guard left of the poorest of the land to be vineyard keepers and farmers.",
                "The Chaldeans broke the pillars of brass that were in the house of Yahweh, and the bases and the bronze sea that were in the house of Yahweh in pieces, and carried all the of their brass to Babylon.",
                "They also took away the pots, the shovels, the snuffers, the basins, the spoons, and all the vessels of brass with which they ministered.",
                "The captain of the guard took away the cups, the fire pans, the basins, the pots, the lampstands, the spoons, and the bowls; that which was of gold, in gold, and that which was of silver, in silver.",
                "They took the two pillars, the one sea, and the twelve bronze bulls that were under the bases, which king Solomon had made for the house of Yahweh. The brass of all these vessels was without weight.",
                "As for the pillars, the height of the one pillar was eighteen cubits; and a line of twelve cubits encircled it; and its thickness was four fingers. It was hollow.",
                "A capital of brass was on it; and the height of the one capital was five cubits, with network and pomegranates on the capital all around, all of brass: and the second pillar also had like these, and pomegranates.",
                "There were ninety-six pomegranates on the sides; all the pomegranates were one hundred on the network all around.",
                "The captain of the guard took Seraiah the chief priest, and Zephaniah the second priest, and the three keepers of the threshold:",
                "and out of the city he took an officer who was set over the men of war; and seven men of those who saw the king\u2019s face, who were found in the city; and the scribe of the captain of the army, who mustered the people of the land; and sixty men of the people of the land, who were found in the midst of the city.",
                "Nebuzaradan the captain of the guard took them, and brought them to the king of Babylon to Riblah.",
                "The king of Babylon struck them, and put them to death at Riblah in the land of Hamath. So Judah was carried away captive out of his land.",
                "This is the people whom Nebuchadnezzar carried away captive: in the seventh year three thousand twenty-three Jews;",
                "in the eighteenth year of Nebuchadnezzar he carried away captive from Jerusalem eight hundred thirty-two persons;",
                "in the three and twentieth year of Nebuchadnezzar Nebuzaradan the captain of the guard carried away captive of the Jews seven hundred forty-five persons: all the persons were four thousand and six hundred.",
                "It happened in the seven and thirtieth year of the captivity of Jehoiachin king of Judah, in the twelfth month, in the five and twentieth day of the month, that Evilmerodach king of Babylon, in the first year of his reign, lifted up the head of Jehoiachin king of Judah, and brought him forth out of prison;",
                "and he spoke kindly to him, and set his throne above the throne of the kings who were with him in Babylon,",
                "and changed his prison garments. Jehoiachin ate bread before him continually all the days of his life:",
                "and for his allowance, there was a continual allowance given him by the king of Babylon, every day a portion until the day of his death, all the days of his life."
            ]
        },
        25: {
            1: [
                "How the city sits solitary, that was full of people! She has become as a widow, who was great among the nations! She who was a princess among the provinces has become tributary!",
                "She weeps bitterly in the night, and her tears are on her cheeks; among all her lovers she has none to comfort her: All her friends have dealt treacherously with her; they are become her enemies.",
                "Judah is gone into captivity because of affliction, and because of great servitude; she dwells among the nations, she finds no rest: all her persecutors overtook her within the straits.",
                "The ways of Zion do mourn, because none come to the solemn assembly; all her gates are desolate, her priests do sigh: her virgins are afflicted, and she herself is in bitterness.",
                "Her adversaries are become the head, her enemies prosper; for Yahweh has afflicted her for the multitude of her transgressions: her young children are gone into captivity before the adversary.",
                "From the daughter of Zion all her majesty is departed: her princes are become like harts that find no pasture, they are gone without strength before the pursuer.",
                "Jerusalem remembers in the days of her affliction and of her miseries all her pleasant things that were from the days of old: when her people fell into the hand of the adversary, and no one helped her, The adversaries saw her, they mocked at her desolations.",
                "Jerusalem has grievously sinned; therefore she has become as an unclean thing; all who honored her despise her, because they have seen her nakedness: yes, she sighs, and turns backward.",
                "Her filthiness was in her skirts; she didn\u2019t remember her latter end; therefore is she come down wonderfully; she has no comforter: see, Yahweh, my affliction; for the enemy has magnified himself.",
                "The adversary has spread out his hand on all her pleasant things: for she has seen that the nations are entered into her sanctuary, concerning whom you commanded that they should not enter into your assembly.",
                "All her people sigh, they seek bread; they have given their pleasant things for food to refresh the soul: look, Yahweh, and see; for I am become abject.",
                "Is it nothing to you, all you who pass by? Look, and see if there is any sorrow like my sorrow, which is brought on me, With which Yahweh has afflicted me in the day of his fierce anger.",
                "From on high has he sent fire into my bones, and it prevails against them; He has spread a net for my feet, he has turned me back: He has made me desolate and faint all the day.",
                "The yoke of my transgressions is bound by his hand; They are knit together, they have come up on my neck; he has made my strength to fail: The Lord has delivered me into their hands, against whom I am not able to stand.",
                "The Lord has set at nothing all my mighty men in the midst of me; He has called a solemn assembly against me to crush my young men: The Lord has trodden as in a winepress the virgin daughter of Judah.",
                "For these things I weep; my eye, my eye runs down with water; Because the comforter who should refresh my soul is far from me: My children are desolate, because the enemy has prevailed.",
                "Zion spreads forth her hands; there is none to comfort her; Yahweh has commanded concerning Jacob, that those who are around him should be his adversaries: Jerusalem is among them as an unclean thing.",
                "Yahweh is righteous; for I have rebelled against his commandment: Please hear all you peoples, and see my sorrow: My virgins and my young men are gone into captivity.",
                "I called for my lovers, but they deceived me: My priests and my elders gave up the spirit in the city, While they sought them food to refresh their souls.",
                "See, Yahweh; for I am in distress; my heart is troubled; My heart is turned within me; for I have grievously rebelled: Abroad the sword bereaves, at home there is as death.",
                "They have heard that I sigh; there is none to comfort me; All my enemies have heard of my trouble; they are glad that you have done it: You will bring the day that you have proclaimed, and they shall be like me.",
                "Let all their wickedness come before you; Do to them, as you have done to me for all my transgressions: For my sighs are many, and my heart is faint."
            ],
            2: [
                "How has the Lord covered the daughter of Zion with a cloud in his anger! He has cast down from heaven to the earth the beauty of Israel, And hasn\u2019t remembered his footstool in the day of his anger.",
                "The Lord has swallowed up all the habitations of Jacob, and has not pitied: He has thrown down in his wrath the strongholds of the daughter of Judah; He has brought them down to the ground; he has profaned the kingdom and its princes.",
                "He has cut off in fierce anger all the horn of Israel; He has drawn back his right hand from before the enemy: He has burned up Jacob like a flaming fire, which devours all around.",
                "He has bent his bow like an enemy, he has stood with his right hand as an adversary, Has killed all that were pleasant to the eye: In the tent of the daughter of Zion he has poured out his wrath like fire.",
                "The Lord has become as an enemy, he has swallowed up Israel; He has swallowed up all her palaces, he has destroyed his strongholds; He has multiplied in the daughter of Judah mourning and lamentation.",
                "He has violently taken away his tabernacle, as if it were of a garden; he has destroyed his place of assembly: Yahweh has caused solemn assembly and Sabbath to be forgotten in Zion, Has despised in the indignation of his anger the king and the priest.",
                "The Lord has cast off his altar, he has abhorred his sanctuary; He has given up into the hand of the enemy the walls of her palaces: They have made a noise in the house of Yahweh, as in the day of a solemn assembly.",
                "Yahweh has purposed to destroy the wall of the daughter of Zion; He has stretched out the line, he has not withdrawn his hand from destroying; He has made the rampart and wall to lament; they languish together.",
                "Her gates are sunk into the ground; he has destroyed and broken her bars: Her king and her princes are among the nations where the law is not; Yes, her prophets find no vision from Yahweh.",
                "The elders of the daughter of Zion sit on the ground, they keep silence; They have cast up dust on their heads; they have clothed themselves with sackcloth: The virgins of Jerusalem hang down their heads to the ground.",
                "My eyes do fail with tears, my heart is troubled; My liver is poured on the earth, because of the destruction of the daughter of my people, Because the young children and the infants swoon in the streets of the city.",
                "They tell their mothers, Where is grain and wine? When they swoon as the wounded in the streets of the city, When their soul is poured out into their mothers\u2019 bosom.",
                "What shall I testify to you? what shall I liken to you, daughter of Jerusalem? What shall I compare to you, that I may comfort you, virgin daughter of Zion? For your breach is great like the sea: who can heal you?",
                "Your prophets have seen for you false and foolish visions; They have not uncovered your iniquity, to bring back your captivity, but have seen for you false oracles and causes of banishment.",
                "All that pass by clap their hands at you. They hiss and wag their head at the daughter of Jerusalem, saying, Is this the city that men called The perfection of beauty, The joy of the whole earth?",
                "All your enemies have opened their mouth wide against you; They hiss and gnash the teeth; they say, We have swallowed her up; Certainly this is the day that we looked for; we have found, we have seen it.",
                "Yahweh has done that which he purposed; he has fulfilled his word that he commanded in the days of old; He has thrown down, and has not pitied: He has caused the enemy to rejoice over you; he has exalted the horn of your adversaries.",
                "Their heart cried to the Lord: wall of the daughter of Zion, let tears run down like a river day and night; Give yourself no respite; don\u2019t let the apple of your eye cease.",
                "Arise, cry out in the night, at the beginning of the watches; Pour out your heart like water before the face of the Lord: Lift up your hands toward him for the life of your young children, that faint for hunger at the head of every street.",
                "Look, Yahweh, and see to whom you have done thus! Shall the women eat their fruit, the children that are dandled in the hands? Shall the priest and the prophet be killed in the sanctuary of the Lord?",
                "The youth and the old man lie on the ground in the streets; My virgins and my young men are fallen by the sword: You have killed them in the day of your anger; you have slaughtered, and not pitied.",
                "You have called, as in the day of a solemn assembly, my terrors on every side; There was none that escaped or remained in the day of Yahweh\u2019s anger: Those that I have dandled and brought up has my enemy consumed."
            ],
            3: [
                "I am the man that has seen affliction by the rod of his wrath.",
                "He has led me and caused me to walk in darkness, and not in light.",
                "Surely against me he turns his hand again and again all the day.",
                "My flesh and my skin has he made old; he has broken my bones.",
                "He has built against me, and surrounded me with gall and travail.",
                "He has made me to dwell in dark places, as those that have been long dead.",
                "He has walled me about, that I can\u2019t go forth; he has made my chain heavy.",
                "Yes, when I cry, and call for help, he shuts out my prayer.",
                "He has walled up my ways with cut stone; he has made my paths crooked.",
                "He is to me as a bear lying in wait, as a lion in secret places.",
                "He has turned aside my ways, and pulled me in pieces; he has made me desolate.",
                "He has bent his bow, and set me as a mark for the arrow.",
                "He has caused the shafts of his quiver to enter into my kidneys.",
                "I am become a derision to all my people, and their song all the day.",
                "He has filled me with bitterness, he has sated me with wormwood.",
                "He has also broken my teeth with gravel stones; he has covered me with ashes.",
                "You have removed my soul far off from peace; I forgot prosperity.",
                "I said, My strength is perished, and my expectation from Yahweh.",
                "Remember my affliction and my misery, the wormwood and the gall.",
                "My soul still remembers them, and is bowed down within me.",
                "This I recall to my mind; therefore have I hope.",
                "It is because of Yahweh\u2019s loving kindnesses that we are not consumed, because his compassion doesn\u2019t fail.",
                "They are new every morning; great is your faithfulness.",
                "Yahweh is my portion, says my soul; therefore will I hope in him.",
                "Yahweh is good to those who wait for him, to the soul that seeks him.",
                "It is good that a man should hope and quietly wait for the salvation of Yahweh.",
                "It is good for a man that he bear the yoke in his youth.",
                "Let him sit alone and keep silence, because he has laid it on him.",
                "Let him put his mouth in the dust, if so be there may be hope.",
                "Let him give his cheek to him who strikes him; let him be filled full with reproach.",
                "For the Lord will not cast off forever.",
                "For though he cause grief, yet he will have compassion according to the multitude of his loving kindnesses.",
                "For he does not afflict willingly, nor grieve the children of men.",
                "To crush under foot all the prisoners of the earth,",
                "To turn aside the right of a man before the face of the Most High,",
                "To subvert a man in his cause, the Lord doesn\u2019t approve.",
                "Who is he who says, and it comes to pass, when the Lord doesn\u2019t command it?",
                "Doesn\u2019t evil and good come out of the mouth of the Most High?",
                "Why does a living man complain, a man for the punishment of his sins?",
                "Let us search and try our ways, and turn again to Yahweh.",
                "Let us lift up our heart with our hands to God in the heavens.",
                "We have transgressed and have rebelled; you have not pardoned.",
                "You have covered with anger and pursued us; you have killed, you have not pitied.",
                "You have covered yourself with a cloud, so that no prayer can pass through.",
                "You have made us an off-scouring and refuse in the midst of the peoples.",
                "All our enemies have opened their mouth wide against us.",
                "Fear and the pit have come on us, devastation and destruction.",
                "My eye runs down with streams of water, for the destruction of the daughter of my people.",
                "My eye pours down, and doesn\u2019t cease, without any intermission,",
                "Until Yahweh look down, and see from heaven.",
                "My eye affects my soul, because of all the daughters of my city.",
                "They have chased me relentlessly like a bird, those who are my enemies without cause.",
                "They have cut off my life in the dungeon, and have cast a stone on me.",
                "Waters flowed over my head; I said, I am cut off.",
                "I called on your name, Yahweh, out of the lowest dungeon.",
                "You heard my voice; don\u2019t hide your ear at my breathing, at my cry.",
                "You drew near in the day that I called on you; you said, Don\u2019t be afraid.",
                "Lord, you have pleaded the causes of my soul; you have redeemed my life.",
                "Yahweh, you have seen my wrong. Judge my cause.",
                "You have seen all their vengeance and all their devices against me.",
                "You have heard their reproach, Yahweh, and all their devices against me,",
                "The lips of those that rose up against me, and their device against me all the day.",
                "You see their sitting down, and their rising up; I am their song.",
                "You will render to them a recompense, Yahweh, according to the work of their hands.",
                "You will give them hardness of heart, your curse to them.",
                "You will pursue them in anger, and destroy them from under the heavens of Yahweh."
            ],
            4: [
                "How the gold has become dim! The most pure gold has changed! The stones of the sanctuary are poured out at the head of every street.",
                "The precious sons of Zion, comparable to fine gold, How are they esteemed as earthen pitchers, the work of the hands of the potter!",
                "Even the jackals draw out the breast, they nurse their young ones: The daughter of my people has become cruel, like the ostriches in the wilderness.",
                "The tongue of the nursing child clings to the roof of his mouth for thirst: The young children ask bread, and no man breaks it to them.",
                "Those who fed delicately are desolate in the streets: Those who were brought up in scarlet embrace dunghills.",
                "For the iniquity of the daughter of my people is greater than the sin of Sodom, That was overthrown as in a moment, and no hands were laid on her.",
                "Her nobles were purer than snow, they were whiter than milk; They were more ruddy in body than rubies, their polishing was as of sapphire.",
                "Their appearance is blacker than a coal; they are not known in the streets: Their skin clings to their bones; it is withered, it has become like a stick.",
                "Those who are killed with the sword are better than those who are killed with hunger; For these pine away, stricken through, for want of the fruits of the field.",
                "The hands of the pitiful women have boiled their own children; They were their food in the destruction of the daughter of my people.",
                "Yahweh has accomplished his wrath, he has poured out his fierce anger; He has kindled a fire in Zion, which has devoured its foundations.",
                "The kings of the earth didn\u2019t believe, neither all the inhabitants of the world, That the adversary and the enemy would enter into the gates of Jerusalem.",
                "It is because of the sins of her prophets, and the iniquities of her priests, That have shed the blood of the just in the midst of her.",
                "They wander as blind men in the streets, they are polluted with blood, So that men can\u2019t touch their garments.",
                "Depart! they cried to them, Unclean! depart, depart, don\u2019t touch! When they fled away and wandered, men said among the nations, They shall not live here any more.",
                "The anger of Yahweh has scattered them; he will no more regard them: They didn\u2019t respect the persons of the priests, they didn\u2019t favor the elders.",
                "Our eyes still fail, looking in vain for our help: In our watching we have watched for a nation that could not save.",
                "They hunt our steps, so that we can\u2019t go in our streets: Our end is near, our days are fulfilled; for our end has come.",
                "Our pursuers were swifter than the eagles of the sky: They chased us on the mountains, they laid wait for us in the wilderness.",
                "The breath of our nostrils, the anointed of Yahweh, was taken in their pits; Of whom we said, Under his shadow we shall live among the nations.",
                "Rejoice and be glad, daughter of Edom, that dwell in the land of Uz: The cup shall pass through to you also; you shall be drunken, and shall make yourself naked.",
                "The punishment of your iniquity is accomplished, daughter of Zion; he will no more carry you away into captivity: He will visit your iniquity, daughter of Edom; he will uncover your sins."
            ],
            5: [
                "Remember, Yahweh, what has come on us: Look, and see our reproach.",
                "Our inheritance is turned to strangers, Our houses to aliens.",
                "We are orphans and fatherless; Our mothers are as widows.",
                "We have drunken our water for money; Our wood is sold to us.",
                "Our pursuers are on our necks: We are weary, and have no rest.",
                "We have given the hand to the Egyptians, To the Assyrians, to be satisfied with bread.",
                "Our fathers sinned, and are no more; We have borne their iniquities.",
                "Servants rule over us: There is none to deliver us out of their hand.",
                "We get our bread at the peril of our lives, Because of the sword of the wilderness.",
                "Our skin is black like an oven, Because of the burning heat of famine.",
                "They ravished the women in Zion, The virgins in the cities of Judah.",
                "Princes were hanged up by their hand: The faces of elders were not honored.",
                "The young men bare the mill; The children stumbled under the wood.",
                "The elders have ceased from the gate, The young men from their music.",
                "The joy of our heart is ceased; Our dance is turned into mourning.",
                "The crown is fallen from our head: Woe to us! for we have sinned.",
                "For this our heart is faint; For these things our eyes are dim;",
                "For the mountain of Zion, which is desolate: The foxes walk on it.",
                "You, Yahweh, remain forever; Your throne is from generation to generation.",
                "Why do you forget us forever, And forsake us so long time?",
                "Turn us to yourself, Yahweh, and we shall be turned. Renew our days as of old.",
                "But you have utterly rejected us; You are very angry against us."
            ]
        },
        26: {
            1: [
                "Now it happened in the thirtieth year, in the fourth month, in the fifth of the month, as I was among the captives by the river Chebar, that the heavens were opened, and I saw visions of God.",
                "In the fifth of the month, which was the fifth year of king Jehoiachin\u2019s captivity,",
                "the word of Yahweh came expressly to Ezekiel the priest, the son of Buzi, in the land of the Chaldeans by the river Chebar; and the hand of Yahweh was there on him.",
                "I looked, and behold, a stormy wind came out of the north, a great cloud, with flashing lightning, and a brightness around it, and out of its midst as it were glowing metal, out of the midst of the fire.",
                "Out of its midst came the likeness of four living creatures. This was their appearance: they had the likeness of a man.",
                "Everyone had four faces, and each one of them had four wings.",
                "Their feet were straight feet; and the sole of their feet was like the sole of a calf\u2019s foot; and they sparkled like burnished brass.",
                "They had the hands of a man under their wings on their four sides; and the four of them had their faces and their wings thus:",
                "their wings were joined one to another; they didn\u2019t turn when they went; each one went straight forward.",
                "As for the likeness of their faces, they had the face of a man; and the four of them had the face of a lion on the right side; and the four of them had the face of an ox on the left side; the four of them also had the face of an eagle.",
                "Such were their faces. Their wings were spread out above. Two wings of each one touched another, and two covered their bodies.",
                "Each one went straight forward: where the spirit was to go, they went; they didn\u2019t turn when they went.",
                "As for the likeness of the living creatures, their appearance was like burning coals of fire, like the appearance of torches: the fire went up and down among the living creatures; and the fire was bright, and out of the fire went forth lightning.",
                "The living creatures ran and returned as the appearance of a flash of lightning.",
                "Now as I saw the living creatures, behold, one wheel on the earth beside the living creatures, for each of the four faces of it.",
                "The appearance of the wheels and their work was like a beryl: and the four of them had one likeness; and their appearance and their work was as it were a wheel within a wheel.",
                "When they went, they went in their four directions: they didn\u2019t turn when they went.",
                "As for their rims, they were high and dreadful; and the four of them had their rims full of eyes all around.",
                "When the living creatures went, the wheels went beside them; and when the living creatures were lifted up from the earth, the wheels were lifted up.",
                "Wherever the spirit was to go, they went; there was the spirit to go: and the wheels were lifted up beside them; for the spirit of the living creature was in the wheels.",
                "When those went, these went; and when those stood, these stood; and when those were lifted up from the earth, the wheels were lifted up beside them: for the spirit of the living creature was in the wheels.",
                "Over the head of the living creature there was the likeness of an expanse, like the awesome crystal to look on, stretched forth over their heads above.",
                "Under the expanse were their wings straight, the one toward the other: each one had two which covered on this side, and every one had two which covered on that side, their bodies.",
                "When they went, I heard the noise of their wings like the noise of great waters, like the voice of the Almighty, a noise of tumult like the noise of an army: when they stood, they let down their wings.",
                "There was a voice above the expanse that was over their heads: when they stood, they let down their wings.",
                "Above the expanse that was over their heads was the likeness of a throne, as the appearance of a sapphire stone; and on the likeness of the throne was a likeness as the appearance of a man on it above.",
                "I saw as it were glowing metal, as the appearance of fire within it all around, from the appearance of his waist and upward; and from the appearance of his waist and downward I saw as it were the appearance of fire, and there was brightness around him.",
                "As the appearance of the rainbow that is in the cloud in the day of rain, so was the appearance of the brightness all around. This was the appearance of the likeness of the glory of Yahweh. When I saw it, I fell on my face, and I heard a voice of one that spoke."
            ],
            2: [
                "He said to me, Son of man, stand on your feet, and I will speak with you.",
                "The Spirit entered into me when he spoke to me, and set me on my feet; and I heard him who spoke to me.",
                "He said to me, Son of man, I send you to the children of Israel, to a nation of rebels who have rebelled against me. They and their fathers have transgressed against me even to this very day.",
                "The children are impudent and stiff-hearted: I am sending you to them; and you shall tell them, Thus says the Lord Yahweh.",
                "They, whether they will hear, or whether they will forbear, (for they are a rebellious house), yet shall know that there has been a prophet among them.",
                "You, son of man, don\u2019t be afraid of them, neither be afraid of their words, though briers and thorns are with you, and you do dwell among scorpions: don\u2019t be afraid of their words, nor be dismayed at their looks, though they are a rebellious house.",
                "You shall speak my words to them, whether they will hear, or whether they will forbear; for they are most rebellious.",
                "But you, son of man, hear what I tell you; don\u2019t be rebellious like that rebellious house: open your mouth, and eat that which I give you.",
                "When I looked, behold, a hand was put forth to me; and, behold, a scroll of a book was therein;",
                "He spread it before me: and it was written within and without; and there were written therein lamentations, and mourning, and woe."
            ],
            3: [
                "He said to me, Son of man, eat that which you find. Eat this scroll, and go, speak to the house of Israel.",
                "So I opened my mouth, and he caused me to eat the scroll.",
                "He said to me, Son of man, cause your belly to eat, and fill your bowels with this scroll that I give you. Then I ate it; and it was as sweet as honey in my mouth.",
                "He said to me, Son of man, go to the house of Israel, and speak my words to them.",
                "For you are not sent to a people of a strange speech and of a hard language, but to the house of Israel;",
                "not to many peoples of a strange speech and of a hard language, whose words you can not understand. Surely, if I sent you to them, they would listen to you.",
                "But the house of Israel will not listen to you; for they will not listen to me: for all the house of Israel are obstinate and hard-hearted.",
                "Behold, I have made your face hard against their faces, and your forehead hard against their foreheads.",
                "As an adamant harder than flint have I made your forehead: don\u2019t be afraid of them, neither be dismayed at their looks, though they are a rebellious house.",
                "Moreover he said to me, Son of man, all my words that I shall speak to you receive in your heart, and hear with your ears.",
                "Go to them of the captivity, to the children of your people, and speak to them, and tell them, Thus says the Lord Yahweh; whether they will hear, or whether they will forbear.",
                "Then the Spirit lifted me up, and I heard behind me the voice of a great rushing, saying, Blessed be the glory of Yahweh from his place.",
                "I heard the noise of the wings of the living creatures as they touched one another, and the noise of the wheels beside them, even the noise of a great rushing.",
                "So the Spirit lifted me up, and took me away; and I went in bitterness, in the heat of my spirit; and the hand of Yahweh was strong on me.",
                "Then I came to them of the captivity at Tel Aviv, that lived by the river Chebar, and to where they lived; and I sat there overwhelmed among them seven days.",
                "It happened at the end of seven days, that the word of Yahweh came to me, saying,",
                "Son of man, I have made you a watchman to the house of Israel: therefore hear the word from my mouth, and give them warning from me.",
                "When I tell the wicked, You shall surely die; and you give him no warning, nor speak to warn the wicked from his wicked way, to save his life; the same wicked man shall die in his iniquity; but his blood will I require at your hand.",
                "Yet if you warn the wicked, and he doesn\u2019t turn from his wickedness, nor from his wicked way, he shall die in his iniquity; but you have delivered your soul.",
                "Again, when a righteous man does turn from his righteousness, and commit iniquity, and I lay a stumbling block before him, he shall die: because you have not given him warning, he shall die in his sin, and his righteous deeds which he has done shall not be remembered; but his blood will I require at your hand.",
                "Nevertheless if you warn the righteous man, that the righteous not sin, and he does not sin, he shall surely live, because he took warning; and you have delivered your soul.",
                "The hand of Yahweh was there on me; and he said to me, Arise, go forth into the plain, and I will there talk with you.",
                "Then I arose, and went forth into the plain: and behold, the glory of Yahweh stood there, as the glory which I saw by the river Chebar; and I fell on my face.",
                "Then the Spirit entered into me, and set me on my feet; and he spoke with me, and said to me, Go, shut yourself inside your house.",
                "But you, son of man, behold, they shall lay bands on you, and shall bind you with them, and you shall not go out among them:",
                "and I will make your tongue stick to the roof of your mouth, that you shall be mute, and shall not be to them a reprover; for they are a rebellious house.",
                "But when I speak with you, I will open your mouth, and you shall tell them, Thus says the Lord Yahweh: He who hears, let him hear; and he who forbears, let him forbear: for they are a rebellious house."
            ],
            4: [
                "You also, son of man, take a tile, and lay it before yourself, and portray on it a city, even Jerusalem:",
                "and lay siege against it, and build forts against it, and cast up a mound against it; set camps also against it, and plant battering rams against it all around.",
                "Take for yourself an iron pan, and set it for a wall of iron between you and the city: and set your face toward it, and it shall be besieged, and you shall lay siege against it. This shall be a sign to the house of Israel.",
                "Moreover lie on your left side, and lay the iniquity of the house of Israel on it; according to the number of the days that you shall lie on it, you shall bear their iniquity.",
                "For I have appointed the years of their iniquity to be to you a number of days, even three hundred ninety days: so you shall bear the iniquity of the house of Israel.",
                "Again, when you have accomplished these, you shall lie on your right side, and shall bear the iniquity of the house of Judah: forty days, each day for a year, have I appointed it to you.",
                "You shall set your face toward the siege of Jerusalem, with your arm uncovered; and you shall prophesy against it.",
                "Behold, I lay bands on you, and you shall not turn you from one side to the other, until you have accomplished the days of your siege.",
                "Take for yourself also wheat, and barley, and beans, and lentils, and millet, and spelt, and put them in one vessel, and make bread of it; according to the number of the days that you shall lie on your side, even three hundred ninety days, you shall eat of it.",
                "Your food which you shall eat shall be by weight, twenty shekels a day: from time to time you shall eat it.",
                "You shall drink water by measure, the sixth part of a hin: from time to time you shall drink.",
                "You shall eat it as barley cakes, and you shall bake it in their sight with dung that comes out of man.",
                "Yahweh said, Even thus shall the children of Israel eat their bread unclean, among the nations where I will drive them.",
                "Then I said, Ah Lord Yahweh! behold, my soul has not been polluted; for from my youth up even until now have I not eaten of that which dies of itself, or is torn of animals; neither came there abominable flesh into my mouth.",
                "Then he said to me, Behold, I have given you cow\u2019s dung for man\u2019s dung, and you shall prepare your bread thereon.",
                "Moreover he said to me, Son of man, behold, I will break the staff of bread in Jerusalem: and they shall eat bread by weight, and with fearfulness; and they shall drink water by measure, and in dismay:",
                "that they may want bread and water, and be dismayed one with another, and pine away in their iniquity."
            ],
            5: [
                "You, son of man, take a sharp sword; You shall take it as a barber\u2019s razor to you, and shall cause it to pass on your head and on your beard: then take balances to weigh, and divide the hair.",
                "A third part you shall burn in the fire in the midst of the city, when the days of the siege are fulfilled; and you shall take a third part, and strike with the sword around it; and a third part you shall scatter to the wind, and I will draw out a sword after them.",
                "You shall take of it a few in number, and bind them in your skirts.",
                "Of these again you shall take, and cast them into the midst of the fire, and burn them in the fire; from it shall a fire come forth into all the house of Israel.",
                "Thus says the Lord Yahweh: This is Jerusalem; I have set her in the midst of the nations, and countries are around her.",
                "She has rebelled against my ordinances in doing wickedness more than the nations, and against my statutes more than the countries that are around her; for they have rejected my ordinances, and as for my statutes, they have not walked in them.",
                "Therefore thus says the Lord Yahweh: Because you are turbulent more than the nations that are around you, and have not walked in my statutes, neither have kept my ordinances, neither have done after the ordinances of the nations that are around you;",
                "therefore thus says the Lord Yahweh: Behold, I, even I, am against you; and I will execute judgments in the midst of you in the sight of the nations.",
                "I will do in you that which I have not done, and whereunto I will not do any more the like, because of all your abominations.",
                "Therefore the fathers shall eat the sons in the midst of you, and the sons shall eat their fathers; and I will execute judgments on you; and the whole remnant of you will I scatter to all the winds.",
                "Therefore, as I live, says the Lord Yahweh, surely, because you have defiled my sanctuary with all your detestable things, and with all your abominations, therefore will I also diminish you; neither shall my eye spare, and I also will have no pity.",
                "A third part of you shall die with the pestilence, and with famine shall they be consumed in the midst of you; and a third part shall fall by the sword around you; and a third part I will scatter to all the winds, and will draw out a sword after them.",
                "Thus shall my anger be accomplished, and I will cause my wrath toward them to rest, and I shall be comforted; and they shall know that I, Yahweh, have spoken in my zeal, when I have accomplished my wrath on them.",
                "Moreover I will make you a desolation and a reproach among the nations that are around you, in the sight of all that pass by.",
                "So it shall be a reproach and a taunt, an instruction and an astonishment, to the nations that are around you, when I shall execute judgments on you in anger and in wrath, and in wrathful rebukes; (I, Yahweh, have spoken it;)",
                "when I shall send on them the evil arrows of famine, that are for destruction, which I will send to destroy you: and I will increase the famine on you, and will break your staff of bread;",
                "and I will send on you famine and evil animals, and they shall bereave you; and pestilence and blood shall pass through you; and I will bring the sword on you: I, Yahweh, have spoken it."
            ],
            6: [
                "The word of Yahweh came to me, saying,",
                "Son of man, set your face toward the mountains of Israel, and prophesy to them,",
                "and say, You mountains of Israel, hear the word of the Lord Yahweh: Thus says the Lord Yahweh to the mountains and to the hills, to the watercourses and to the valleys: Behold, I, even I, will bring a sword on you, and I will destroy your high places.",
                "Your altars shall become desolate, and your incense altars shall be broken; and I will cast down your slain men before your idols.",
                "I will lay the dead bodies of the children of Israel before their idols; and I will scatter your bones around your altars.",
                "In all your dwelling places the cities shall be laid waste, and the high places shall be desolate; that your altars may be laid waste and made desolate, and your idols may be broken and cease, and your incense altars may be cut down, and your works may be abolished.",
                "The slain shall fall in the midst of you, and you shall know that I am Yahweh.",
                "Yet will I leave a remnant, in that you shall have some that escape the sword among the nations, when you shall be scattered through the countries.",
                "Those of you that escape shall remember me among the nations where they shall be carried captive, how that I have been broken with their lewd heart, which has departed from me, and with their eyes, which play the prostitute after their idols: and they shall loathe themselves in their own sight for the evils which they have committed in all their abominations.",
                "They shall know that I am Yahweh: I have not said in vain that I would do this evil to them.",
                "Thus says the Lord Yahweh: Strike with your hand, and stamp with your foot, and say, Alas! because of all the evil abominations of the house of Israel; for they shall fall by the sword, by the famine, and by the pestilence.",
                "He who is far off shall die of the pestilence; and he who is near shall fall by the sword; and he who remains and is besieged shall die by the famine: thus will I accomplish my wrath on them.",
                "You shall know that I am Yahweh, when their slain men shall be among their idols around their altars, on every high hill, on all the tops of the mountains, and under every green tree, and under every thick oak, the places where they offered pleasant aroma to all their idols.",
                "I will stretch out my hand on them, and make the land desolate and waste, from the wilderness toward Diblah, throughout all their habitations: and they shall know that I am Yahweh."
            ],
            7: [
                "Moreover the word of Yahweh came to me, saying,",
                "You, son of man, thus says the Lord Yahweh to the land of Israel, An end: the end has come on the four corners of the land.",
                "Now is the end on you, and I will send my anger on you, and will judge you according to your ways; and I will bring on you all your abominations.",
                "My eye shall not spare you, neither will I have pity; but I will bring your ways on you, and your abominations shall be in the midst of you: and you shall know that I am Yahweh.",
                "Thus says the Lord Yahweh: An evil, an only evil; behold, it comes.",
                "An end has come, the end has come; it awakes against you; behold, it comes.",
                "Your doom has come to you, inhabitant of the land: the time has come, the day is near, a day of tumult, and not of joyful shouting, on the mountains.",
                "Now will I shortly pour out my wrath on you, and accomplish my anger against you, and will judge you according to your ways; and I will bring on you all your abominations.",
                "My eye shall not spare, neither will I have pity: I will bring on you according to your ways; and your abominations shall be in the midst of you; and you shall know that I, Yahweh, do strike.",
                "Behold, the day, behold, it comes: your doom is gone forth; the rod has blossomed, pride has budded.",
                "Violence has risen up into a rod of wickedness. None of them shall remain, nor of their multitude, nor of their wealth. There shall be nothing of value among them.",
                "The time has come, the day draws near: don\u2019t let the buyer rejoice, nor the seller mourn; for wrath is on all its multitude.",
                "For the seller shall not return to that which is sold, although they be yet alive: for the vision is touching the whole multitude of it, none shall return; neither shall any strengthen himself in the iniquity of his life.",
                "They have blown the trumpet, and have made all ready; but none goes to the battle; for my wrath is on all its multitude.",
                "The sword is outside, and the pestilence and the famine within: he who is in the field shall die with the sword: and he who is in the city, famine and pestilence shall devour him.",
                "But those of those who escape shall escape, and shall be on the mountains like doves of the valleys, all of them moaning, every one in his iniquity.",
                "All hands shall be feeble, and all knees shall be weak as water.",
                "They shall also clothe themselves with sackcloth, and horror shall cover them; and shame shall be on all faces, and baldness on all their heads.",
                "They shall cast their silver in the streets, and their gold shall be as an unclean thing; their silver and their gold shall not be able to deliver them in the day of the wrath of Yahweh: they shall not satisfy their souls, neither fill their bowels; because it has been the stumbling block of their iniquity.",
                "As for the beauty of his ornament, he set it in majesty; but they made the images of their abominations and their detestable things therein: therefore have I made it to them as an unclean thing.",
                "I will give it into the hands of the strangers for a prey, and to the wicked of the earth for a spoil; and they shall profane it.",
                "My face will I turn also from them, and they shall profane my secret place; and robbers shall enter into it, and profane it.",
                "Make the chain; for the land is full of bloody crimes, and the city is full of violence.",
                "Therefore I will bring the worst of the nations, and they shall possess their houses: I will also make the pride of the strong to cease; and their holy places shall be profaned.",
                "Destruction comes; and they shall seek peace, and there shall be none.",
                "Mischief shall come on mischief, and rumor shall be on rumor; and they shall seek a vision of the prophet; but the law shall perish from the priest, and counsel from the elders.",
                "The king shall mourn, and the prince shall be clothed with desolation, and the hands of the people of the land shall be troubled: I will do to them after their way, and according to their own judgments will I judge them; and they shall know that I am Yahweh."
            ],
            8: [
                "It happened in the sixth year, in the sixth month, in the fifth day of the month, as I sat in my house, and the elders of Judah sat before me, that the hand of the Lord Yahweh fell there on me.",
                "Then I saw, and behold, a likeness as the appearance of fire; from the appearance of his waist and downward, fire; and from his waist and upward, as the appearance of brightness, as it were glowing metal.",
                "He put forth the form of a hand, and took me by a lock of my head; and the Spirit lifted me up between earth and the sky, and brought me in the visions of God to Jerusalem, to the door of the gate of the inner court that looks toward the north; where there was the seat of the image of jealousy, which provokes to jealousy.",
                "Behold, the glory of the God of Israel was there, according to the appearance that I saw in the plain.",
                "Then he said to me, Son of man, lift up your eyes now the way toward the north. So I lifted up my eyes the way toward the north, and see, northward of the gate of the altar this image of jealousy in the entry.",
                "He said to me, Son of man, do you see what they do? even the great abominations that the house of Israel do commit here, that I should go far off from my sanctuary? But you shall again see yet other great abominations.",
                "He brought me to the door of the court; and when I looked, behold, a hole in the wall.",
                "Then he said to me, Son of man, dig now in the wall: and when I had dug in the wall, behold, a door.",
                "He said to me, Go in, and see the wicked abominations that they do here.",
                "So I went in and saw; and see, every form of creeping things, and abominable animals, and all the idols of the house of Israel, portrayed around on the wall.",
                "There stood before them seventy men of the elders of the house of Israel; and in their midst stood Jaazaniah the son of Shaphan, every man with his censer in his hand; and the odor of the cloud of incense went up.",
                "Then he said to me, Son of man, have you seen what the elders of the house of Israel do in the dark, every man in his rooms of imagery? for they say, Yahweh doesn\u2019t see us; Yahweh has forsaken the land.",
                "He said also to me, You shall again see yet other great abominations which they do.",
                "Then he brought me to the door of the gate of Yahweh\u2019s house which was toward the north; and see, there sat the women weeping for Tammuz.",
                "Then he said to me, Have you seen this, son of man? You shall again see yet greater abominations than these.",
                "He brought me into the inner court of Yahweh\u2019s house; and see, at the door of the temple of Yahweh, between the porch and the altar, were about twenty-five men, with their backs toward the temple of Yahweh, and their faces toward the east; and they were worshipping the sun toward the east.",
                "Then he said to me, Have you seen this, son of man? Is it a light thing to the house of Judah that they commit the abominations which they commit here? for they have filled the land with violence, and have turned again to provoke me to anger: and behold, they put the branch to their nose.",
                "Therefore will I also deal in wrath; my eye shall not spare, neither will I have pity; and though they cry in my ears with a loud voice, yet will I not hear them."
            ],
            9: [
                "Then he cried in my ears with a loud voice, saying, Cause those who are in charge of the city to draw near, every man with his destroying weapon in his hand.",
                "Behold, six men came from the way of the upper gate, which lies toward the north, every man with his slaughter weapon in his hand; and one man in their midst clothed in linen, with a writer\u2019s inkhorn by his side. They went in, and stood beside the bronze altar.",
                "The glory of the God of Israel was gone up from the cherub, whereupon it was, to the threshold of the house: and he called to the man clothed in linen, who had the writer\u2019s inkhorn by his side.",
                "Yahweh said to him, Go through the midst of the city, through the midst of Jerusalem, and set a mark on the foreheads of the men that sigh and that cry over all the abominations that are done in its midst.",
                "To the others he said in my hearing, Go through the city after him, and strike: don\u2019t let your eye spare, neither have pity;",
                "kill utterly the old man, the young man and the virgin, and little children and women; but don\u2019t come near any man on whom is the mark: and begin at my sanctuary. Then they began at the old men that were before the house.",
                "He said to them, Defile the house, and fill the courts with the slain. Go forth! They went forth, and struck in the city.",
                "It happened, while they were smiting, and I was left, that I fell on my face, and cried, and said, Ah Lord Yahweh! will you destroy all the residue of Israel in your pouring out of your wrath on Jerusalem?",
                "Then he said to me, The iniquity of the house of Israel and Judah is exceedingly great, and the land is full of blood, and the city full of perversion: for they say, Yahweh has forsaken the land, and Yahweh doesn\u2019t see.",
                "As for me also, my eye shall not spare, neither will I have pity, but I will bring their way on their head.",
                "Behold, the man clothed in linen, who had the inkhorn by his side, reported the matter, saying, I have done as you have commanded me."
            ],
            10: [
                "Then I looked, and see, in the expanse that was over the head of the cherubim there appeared above them as it were a sapphire stone, as the appearance of the likeness of a throne.",
                "He spoke to the man clothed in linen, and said, Go in between the whirling wheels, even under the cherub, and fill both your hands with coals of fire from between the cherubim, and scatter them over the city. He went in as I watched.",
                "Now the cherubim stood on the right side of the house, when the man went in; and the cloud filled the inner court.",
                "The glory of Yahweh mounted up from the cherub, and stood over the threshold of the house; and the house was filled with the cloud, and the court was full of the brightness of Yahweh\u2019s glory.",
                "The sound of the wings of the cherubim was heard even to the outer court, as the voice of God Almighty when he speaks.",
                "It came to pass, when he commanded the man clothed in linen, saying, Take fire from between the whirling wheels, from between the cherubim, that he went in, and stood beside a wheel.",
                "The cherub stretched forth his hand from between the cherubim to the fire that was between the cherubim, and took of it, and put it into the hands of him who was clothed in linen, who took it and went out.",
                "There appeared in the cherubim the form of a man\u2019s hand under their wings.",
                "I looked, and behold, four wheels beside the cherubim, one wheel beside one cherub, and another wheel beside another cherub; and the appearance of the wheels was like a beryl stone.",
                "As for their appearance, the four of them had one likeness, like a wheel within a wheel.",
                "When they went, they went in their four directions: they didn\u2019t turn as they went, but to the place where the head looked they followed it; they didn\u2019t turn as they went.",
                "Their whole body, and their backs, and their hands, and their wings, and the wheels, were full of eyes all around, even the wheels that the four of them had.",
                "As for the wheels, they were called in my hearing, the whirling wheels.",
                "Every one had four faces: the first face was the face of the cherub, and the second face was the face of a man, and the third face the face of a lion, and the fourth the face of an eagle.",
                "The cherubim mounted up: this is the living creature that I saw by the river Chebar.",
                "When the cherubim went, the wheels went beside them; and when the cherubim lifted up their wings to mount up from the earth, the wheels also didn\u2019t turn from beside them.",
                "When they stood, these stood; and when they mounted up, these mounted up with them: for the spirit of the living creature was in them.",
                "The glory of Yahweh went forth from over the threshold of the house, and stood over the cherubim.",
                "The cherubim lifted up their wings, and mounted up from the earth in my sight when they went forth, and the wheels beside them: and they stood at the door of the east gate of Yahweh\u2019s house; and the glory of the God of Israel was over them above.",
                "This is the living creature that I saw under the God of Israel by the river Chebar; and I knew that they were cherubim.",
                "Every one had four faces, and every one four wings; and the likeness of the hands of a man was under their wings.",
                "As for the likeness of their faces, they were the faces which I saw by the river Chebar, their appearances and themselves; they went every one straight forward."
            ],
            11: [
                "Moreover the Spirit lifted me up, and brought me to the east gate of Yahweh\u2019s house, which looks eastward: and see, at the door of the gate twenty-five men; and I saw in their midst Jaazaniah the son of Azzur, and Pelatiah the son of Benaiah, princes of the people.",
                "He said to me, Son of man, these are the men who devise iniquity, and who give wicked counsel in this city;",
                "who say, The time is not near to build houses: this is the caldron, and we are the meat.",
                "Therefore prophesy against them, prophesy, son of man.",
                "The Spirit of Yahweh fell on me, and he said to me, Speak, Thus says Yahweh: Thus you have said, house of Israel; for I know the things that come into your mind.",
                "You have multiplied your slain in this city, and you have filled its streets with the slain.",
                "Therefore thus says the Lord Yahweh: Your slain whom you have laid in its midst, they are the meat, and this is the caldron; but you shall be brought out of its midst.",
                "You have feared the sword; and I will bring the sword on you, says the Lord Yahweh.",
                "I will bring you forth out of its midst, and deliver you into the hands of strangers, and will execute judgments among you.",
                "You shall fall by the sword; I will judge you in the border of Israel; and you shall know that I am Yahweh.",
                "This shall not be your caldron, neither shall you be the meat in its midst; I will judge you in the border of Israel;",
                "and you shall know that I am Yahweh: for you have not walked in my statutes, neither have you executed my ordinances, but have done after the ordinances of the nations that are around you.",
                "It happened, when I prophesied, that Pelatiah the son of Benaiah died. Then fell I down on my face, and cried with a loud voice, and said, Ah Lord Yahweh! will you make a full end of the remnant of Israel?",
                "The word of Yahweh came to me, saying,",
                "Son of man, your brothers, even your brothers, the men of your relatives, and all the house of Israel, all of them, to whom the inhabitants of Jerusalem have said, Go far away from Yahweh. This land has been given to us for a possession.",
                "Therefore say, Thus says the Lord Yahweh: Whereas I have removed them far off among the nations, and whereas I have scattered them among the countries, yet will I be to them a sanctuary for a little while in the countries where they have come.",
                "Therefore say, Thus says the Lord Yahweh: I will gather you from the peoples, and assemble you out of the countries where you have been scattered, and I will give you the land of Israel.",
                "They shall come there, and they shall take away all the detestable things of it and all its abominations from there.",
                "I will give them one heart, and I will put a new spirit within you; and I will take the stony heart out of their flesh, and will give them a heart of flesh;",
                "that they may walk in my statutes, and keep my ordinances, and do them: and they shall be my people, and I will be their God.",
                "But as for them whose heart walks after the heart of their detestable things and their abominations, I will bring their way on their own heads, says the Lord Yahweh.",
                "Then the cherubim lifted up their wings, and the wheels were beside them; and the glory of the God of Israel was over them above.",
                "The glory of Yahweh went up from the midst of the city, and stood on the mountain which is on the east side of the city.",
                "The Spirit lifted me up, and brought me in the vision by the Spirit of God into Chaldea, to them of the captivity. So the vision that I had seen went up from me.",
                "Then I spoke to them of the captivity all the things that Yahweh had shown me."
            ],
            12: [
                "The word of Yahweh also came to me, saying,",
                "Son of man, you dwell in the midst of the rebellious house, who have eyes to see, and don\u2019t see, who have ears to hear, and don\u2019t hear; for they are a rebellious house.",
                "Therefore, you son of man, prepare your stuff for moving, and move by day in their sight; and you shall move from your place to another place in their sight: it may be they will consider, though they are a rebellious house.",
                "You shall bring forth your stuff by day in their sight, as stuff for moving; and you shall go forth yourself at even in their sight, as when men go forth into exile.",
                "Dig through the wall in their sight, and carry your stuff out that way.",
                "In their sight you shall bear it on your shoulder, and carry it forth in the dark; you shall cover your face, so that you don\u2019t see the land: for I have set you for a sign to the house of Israel.",
                "I did so as I was commanded: I brought forth my stuff by day, as stuff for removing, and in the even I dug through the wall with my hand; I brought it forth in the dark, and bore it on my shoulder in their sight.",
                "In the morning came the word of Yahweh to me, saying,",
                "Son of man, has not the house of Israel, the rebellious house, said to you, What are you doing?",
                "Say to them, Thus says the Lord Yahweh: This burden concerns the prince in Jerusalem, and all the house of Israel among whom they are.",
                "Say, I am your sign: like as I have done, so shall it be done to them; they shall go into exile, into captivity.",
                "The prince who is among them shall bear on his shoulder in the dark, and shall go forth: they shall dig through the wall to carry out thereby: he shall cover his face, because he shall not see the land with his eyes.",
                "My net also will I spread on him, and he shall be taken in my snare; and I will bring him to Babylon to the land of the Chaldeans; yet shall he not see it, though he shall die there.",
                "I will scatter toward every wind all who are around him to help him, and all his bands; and I will draw out the sword after them.",
                "They shall know that I am Yahweh, when I shall disperse them among the nations, and scatter them through the countries.",
                "But I will leave a few men of them from the sword, from the famine, and from the pestilence; that they may declare all their abominations among the nations where they come; and they shall know that I am Yahweh.",
                "Moreover the word of Yahweh came to me, saying,",
                "Son of man, eat your bread with quaking, and drink your water with trembling and with fearfulness;",
                "and tell the people of the land, Thus says the Lord Yahweh concerning the inhabitants of Jerusalem, and the land of Israel: They shall eat their bread with fearfulness, and drink their water in dismay, that her land may be desolate, and all that is therein, because of the violence of all those who dwell therein.",
                "The cities that are inhabited shall be laid waste, and the land shall be a desolation; and you shall know that I am Yahweh.",
                "The word of Yahweh came to me, saying,",
                "Son of man, what is this proverb that you have in the land of Israel, saying, The days are prolonged, and every vision fails?",
                "Tell them therefore, Thus says the Lord Yahweh: I will make this proverb to cease, and they shall no more use it as a proverb in Israel; but tell them, The days are at hand, and the fulfillment of every vision.",
                "For there shall be no more any false vision nor flattering divination within the house of Israel.",
                "For I am Yahweh; I will speak, and the word that I shall speak shall be performed; it shall be no more deferred: for in your days, rebellious house, will I speak the word, and will perform it, says the Lord Yahweh.",
                "Again the word of Yahweh came to me, saying,",
                "Son of man, behold, they of the house of Israel say, The vision that he sees is for many day to come, and he prophesies of times that are far off.",
                "Therefore tell them, Thus says the Lord Yahweh: None of my words shall be deferred any more, but the word which I shall speak shall be performed, says the Lord Yahweh."
            ],
            13: [
                "The word of Yahweh came to me, saying,",
                "Son of man, prophesy against the prophets of Israel who prophesy, and say to those who prophesy out of their own heart, Hear the word of Yahweh:",
                "Thus says the Lord Yahweh, Woe to the foolish prophets, who follow their own spirit, and have seen nothing!",
                "Israel, your prophets have been like foxes in the waste places.",
                "You have not gone up into the gaps, neither built up the wall for the house of Israel, to stand in the battle in the day of Yahweh.",
                "They have seen falsehood and lying divination, who say, Yahweh says; but Yahweh has not sent them: and they have made men to hope that the word would be confirmed.",
                "Haven\u2019t you seen a false vision, and haven\u2019t you spoken a lying divination, in that you say, Yahweh says; but I have not spoken?",
                "Therefore thus says the Lord Yahweh: Because you have spoken falsehood, and seen lies, therefore, behold, I am against you, says the Lord Yahweh.",
                "My hand shall be against the prophets who see false visions, and who divine lies: they shall not be in the council of my people, neither shall they be written in the writing of the house of Israel, neither shall they enter into the land of Israel; and you shall know that I am the Lord Yahweh.",
                "Because, even because they have seduced my people, saying, Peace; and there is no peace; and when one builds up a wall, behold, they plaster it with whitewash:",
                "tell those who plaster it with whitewash, that it shall fall: there shall be an overflowing shower; and you, great hailstones, shall fall; and a stormy wind shall tear it.",
                "Behold, when the wall has fallen, shall it not be said to you, Where is the plaster with which you have plastered it?",
                "Therefore thus says the Lord Yahweh: I will even tear it with a stormy wind in my wrath; and there shall be an overflowing shower in my anger, and great hailstones in wrath to consume it.",
                "So will I break down the wall that you have plastered with whitewash, and bring it down to the ground, so that its foundation shall be uncovered; and it shall fall, and you shall be consumed in its midst: and you shall know that I am Yahweh.",
                "Thus will I accomplish my wrath on the wall, and on those who have plastered it with whitewash; and I will tell you, The wall is no more, neither those who plastered it;",
                "to wit, the prophets of Israel who prophesy concerning Jerusalem, and who see visions of peace for her, and there is no peace, says the Lord Yahweh.",
                "You, son of man, set your face against the daughters of your people, who prophesy out of their own heart; and prophesy against them,",
                "and say, Thus says the Lord Yahweh: Woe to the women who sew pillows on all elbows, and make kerchiefs for the head of persons of every stature to hunt souls! Will you hunt the souls of my people, and save souls alive for yourselves?",
                "You have profaned me among my people for handfuls of barley and for pieces of bread, to kill the souls who should not die, and to save the souls alive who should not live, by your lying to my people who listen to lies.",
                "Therefore thus says the Lord Yahweh: Behold, I am against your pillows, with which you there hunt the souls to make them fly, and I will tear them from your arms; and I will let the souls go, even the souls whom you hunt to make them fly.",
                "Your kerchiefs also will I tear, and deliver my people out of your hand, and they shall be no more in your hand to be hunted; and you shall know that I am Yahweh.",
                "Because with lies you have grieved the heart of the righteous, whom I have not made sad; and strengthened the hands of the wicked, that he should not return from his wicked way, and be saved alive:",
                "Therefore you shall no more see false visions, nor practice divination. I will deliver my people out of your hand; and you shall know that I am Yahweh."
            ],
            14: [
                "Then came certain of the elders of Israel to me, and sat before me.",
                "The word of Yahweh came to me, saying,",
                "Son of man, these men have taken their idols into their heart, and put the stumbling block of their iniquity before their face: should I be inquired of at all by them?",
                "Therefore speak to them, and tell them, Thus says the Lord Yahweh: Every man of the house of Israel who takes his idols into his heart, and puts the stumbling block of his iniquity before his face, and comes to the prophet; I Yahweh will answer him therein according to the multitude of his idols;",
                "that I may take the house of Israel in their own heart, because they are all estranged from me through their idols.",
                "Therefore tell the house of Israel, Thus says the Lord Yahweh: Return, and turn yourselves from your idols; and turn away your faces from all your abominations.",
                "For everyone of the house of Israel, or of the strangers who live in Israel, who separates himself from me, and takes his idols into his heart, and puts the stumbling block of his iniquity before his face, and comes to the prophet to inquire for himself of me; I Yahweh will answer him by myself:",
                "and I will set my face against that man, and will make him an astonishment, for a sign and a proverb, and I will cut him off from the midst of my people; and you shall know that I am Yahweh.",
                "If the prophet is deceived and speak a word, I, Yahweh, have deceived that prophet, and I will stretch out my hand on him, and will destroy him from the midst of my people Israel.",
                "They shall bear their iniquity: the iniquity of the prophet shall be even as the iniquity of him who seeks him;",
                "that the house of Israel may go no more astray from me, neither defile themselves any more with all their transgressions; but that they may be my people, and I may be their God, says the Lord Yahweh.",
                "The word of Yahweh came to me, saying,",
                "Son of man, when a land sins against me by committing a trespass, and I stretch out my hand on it, and break the staff of its bread, and send famine on it, and cut off from it man and animal;",
                "though these three men, Noah, Daniel, and Job, were in it, they should deliver but their own souls by their righteousness, says the Lord Yahweh.",
                "If I cause evil animals to pass through the land, and they ravage it, and it be made desolate, so that no man may pass through because of the animals;",
                "though these three men were in it, as I live, says the Lord Yahweh, they should deliver neither sons nor daughters; they only should be delivered, but the land should be desolate.",
                "Or if I bring a sword on that land, and say, Sword, go through the land; so that I cut off from it man and animal;",
                "though these three men were in it, as I live, says the Lord Yahweh, they should deliver neither sons nor daughters, but they only should be delivered themselves.",
                "Or if I send a pestilence into that land, and pour out my wrath on it in blood, to cut off from it man and animal;",
                "though Noah, Daniel, and Job, were in it, as I live, says the Lord Yahweh, they should deliver neither son nor daughter; they should but deliver their own souls by their righteousness.",
                "For thus says the Lord Yahweh: How much more when I send my four severe judgments on Jerusalem, the sword, and the famine, and the evil animals, and the pestilence, to cut off from it man and animal!",
                "Yet, behold, therein shall be left a remnant that shall be carried forth, both sons and daughters: behold, they shall come forth to you, and you shall see their way and their doings; and you shall be comforted concerning the evil that I have brought on Jerusalem, even concerning all that I have brought on it.",
                "They shall comfort you, when you see their way and their doings; and you shall know that I have not done without cause all that I have done in it, says the Lord Yahweh."
            ],
            15: [
                "The word of Yahweh came to me, saying,",
                "Son of man, what is the vine tree more than any tree, the vine-branch which is among the trees of the forest?",
                "Shall wood be taken of it to make any work? or will men take a pin of it to hang any vessel thereon?",
                "Behold, it is cast into the fire for fuel; the fire has devoured both its ends, and its midst is burned: is it profitable for any work?",
                "Behold, when it was whole, it was meet for no work: how much less, when the fire has devoured it, and it is burned, shall it yet be meet for any work!",
                "Therefore thus says the Lord Yahweh: As the vine tree among the trees of the forest, which I have given to the fire for fuel, so will I give the inhabitants of Jerusalem.",
                "I will set my face against them; they shall go forth from the fire, but the fire shall devour them; and you shall know that I am Yahweh, when I set my face against them.",
                "I will make the land desolate, because they have committed a trespass, says the Lord Yahweh."
            ],
            16: [
                "Again the word of Yahweh came to me, saying,",
                "Son of man, cause Jerusalem to know her abominations;",
                "and say, Thus says the Lord Yahweh to Jerusalem: Your birth and your birth is of the land of the Canaanite; the Amorite was your father, and your mother was a Hittite.",
                "As for your birth, in the day you were born your navel was not cut, neither were you washed in water to cleanse you; you weren\u2019t salted at all, nor swaddled at all.",
                "No eye pitied you, to do any of these things to you, to have compassion on you; but you were cast out in the open field, for that your person was abhorred, in the day that you were born.",
                "When I passed by you, and saw you wallowing in your blood, I said to you, Though you are in your blood, live; yes, I said to you, Though you are in your blood, live.",
                "I caused you to multiply as that which grows in the field, and you increased and grew great, and you attained to excellent ornament; your breasts were fashioned, and your hair was grown; yet you were naked and bare.",
                "Now when I passed by you, and looked at you, behold, your time was the time of love; and I spread my skirt over you, and covered your nakedness: yes, I swore to you, and entered into a covenant with you, says the Lord Yahweh, and you became mine.",
                "Then washed I you with water; yes, I thoroughly washed away your blood from you, and I anointed you with oil.",
                "I clothed you also with embroidered work, and shod you with sealskin, and I dressed you about with fine linen, and covered you with silk.",
                "I decked you with ornaments, and I put bracelets on your hands, and a chain on your neck.",
                "I put a ring on your nose, and earrings in your ears, and a beautiful crown on your head.",
                "Thus you were decked with gold and silver; and your clothing was of fine linen, and silk, and embroidered work; you ate fine flour, and honey, and oil; and you were exceeding beautiful, and you prospered to royal estate.",
                "Your renown went forth among the nations for your beauty; for it was perfect, through my majesty which I had put on you, says the Lord Yahweh.",
                "But you trusted in your beauty, and played the prostitute because of your renown, and poured out your prostitution on everyone who passed by; his it was.",
                "You took of your garments, and made for yourselves high places decked with various colors, and played the prostitute on them: the like things shall not come, neither shall it be so.",
                "You also took your beautiful jewels of my gold and of my silver, which I had given you, and made for yourself images of men, and played the prostitute with them;",
                "and you took your embroidered garments, and covered them, and set my oil and my incense before them.",
                "My bread also which I gave you, fine flour, and oil, and honey, with which I fed you, you even set it before them for a pleasant aroma; and thus it was, says the Lord Yahweh.",
                "Moreover you have taken your sons and your daughters, whom you have borne to me, and you have sacrificed these to them to be devoured. Was your prostitution a small matter,",
                "that you have slain my children, and delivered them up, in causing them to pass through the fire to them?",
                "In all your abominations and your prostitution you have not remembered the days of your youth, when you were naked and bare, and were wallowing in your blood.",
                "It has happened after all your wickedness, (woe, woe to you! says the Lord Yahweh),",
                "that you have built for yourselves a vaulted place, and have made yourselves a lofty place in every street.",
                "You have built your lofty place at the head of every way, and have made your beauty an abomination, and have opened your feet to everyone who passed by, and multiplied your prostitution.",
                "You have also committed sexual immorality with the Egyptians, your neighbors, great of flesh; and have multiplied your prostitution, to provoke me to anger.",
                "See therefore, I have stretched out my hand over you, and have diminished your ordinary food, and delivered you to the will of those who hate you, the daughters of the Philistines, who are ashamed of your lewd way.",
                "You have played the prostitute also with the Assyrians, because you were insatiable; yes, you have played the prostitute with them, and yet you weren\u2019t satisfied.",
                "You have moreover multiplied your prostitution to the land of merchants, to Chaldea; and yet you weren\u2019t satisfied with this.",
                "How weak is your heart, says the Lord Yahweh, since you do all these things, the work of an impudent prostitute;",
                "in that you build your vaulted place at the head of every way, and make your lofty place in every street, and have not been as a prostitute, in that you scorn pay.",
                "A wife who commits adultery! who takes strangers instead of her husband!",
                "They give gifts to all prostitutes; but you give your gifts to all your lovers, and bribe them, that they may come to you on every side for your prostitution.",
                "You are different from other women in your prostitution, in that no one follows you to play the prostitute; and whereas you give hire, and no hire is given to you, therefore you are different.",
                "Therefore, prostitute, hear the word of Yahweh:",
                "Thus says the Lord Yahweh, Because your filthiness was poured out, and your nakedness uncovered through your prostitution with your lovers; and because of all the idols of your abominations, and for the blood of your children, that you gave to them;",
                "therefore see, I will gather all your lovers, with whom you have taken pleasure, and all those who you have loved, with all those who you have hated; I will even gather them against you on every side, and will uncover your nakedness to them, that they may see all your nakedness.",
                "I will judge you, as women who break wedlock and shed blood are judged; and I will bring on you the blood of wrath and jealousy.",
                "I will also give you into their hand, and they shall throw down your vaulted place, and break down your lofty places; and they shall strip you of your clothes, and take your beautiful jewels; and they shall leave you naked and bare.",
                "They shall also bring up a company against you, and they shall stone you with stones, and thrust you through with their swords.",
                "They shall burn your houses with fire, and execute judgments on you in the sight of many women; and I will cause you to cease from playing the prostitute, and you shall also give no hire any more.",
                "So will I cause my wrath toward you to rest, and my jealousy shall depart from you, and I will be quiet, and will be no more angry.",
                "Because you have not remembered the days of your youth, but have raged against me in all these things; therefore, behold, I also will bring your way on your head, says the Lord Yahweh: and you shall not commit this lewdness with all your abominations.",
                "Behold, everyone who uses proverbs shall use this proverb against you, saying, As is the mother, so is her daughter.",
                "You are the daughter of your mother, who loathes her husband and her children; and you are the sister of your sisters, who loathed their husbands and their children: your mother was a Hittite, and your father an Amorite.",
                "Your elder sister is Samaria, who dwells at your left hand, she and her daughters; and your younger sister, who dwells at your right hand, is Sodom and her daughters.",
                "Yet have you not walked in their ways, nor done after their abominations; but, as if that were a very little thing, you were more corrupt than they in all your ways.",
                "As I live, says the Lord Yahweh, Sodom your sister has not done, she nor her daughters, as you have done, you and your daughters.",
                "Behold, this was the iniquity of your sister Sodom: pride, fullness of bread, and prosperous ease was in her and in her daughters; neither did she strengthen the hand of the poor and needy.",
                "They were haughty, and committed abomination before me: therefore I took them away as I saw good.",
                "Neither has Samaria committed half of your sins; but you have multiplied your abominations more than they, and have justified your sisters by all your abominations which you have done.",
                "You also, bear you your own shame, in that you have given judgment for your sisters; through your sins that you have committed more abominable than they, they are more righteous that you: yes, be also confounded, and bear your shame, in that you have justified your sisters.",
                "I will turn again their captivity, the captivity of Sodom and her daughters, and the captivity of Samaria and her daughters, and the captivity of your captives in their midst;",
                "that you may bear your own shame, and may be ashamed because of all that you have done, in that you are a comfort to them.",
                "Your sisters, Sodom and her daughters, shall return to their former estate; and Samaria and her daughters shall return to their former estate; and you and your daughters shall return to your former estate.",
                "For your sister Sodom was not mentioned by your mouth in the day of your pride,",
                "before your wickedness was uncovered, as at the time of the reproach of the daughters of Syria, and of all who are around her, the daughters of the Philistines, who do despite to you all around.",
                "You have borne your lewdness and your abominations, says Yahweh.",
                "For thus says the Lord Yahweh: I will also deal with you as you have done, who have despised the oath in breaking the covenant.",
                "Nevertheless I will remember my covenant with you in the days of your youth, and I will establish to you an everlasting covenant.",
                "Then you shall remember your ways, and be ashamed, when you shall receive your sisters, your elder sisters and your younger; and I will give them to you for daughters, but not by your covenant.",
                "I will establish my covenant with you; and you shall know that I am Yahweh;",
                "that you may remember, and be confounded, and never open your mouth any more, because of your shame, when I have forgiven you all that you have done, says the Lord Yahweh."
            ],
            17: [
                "The word of Yahweh came to me, saying,",
                "Son of man, put forth a riddle, and speak a parable to the house of Israel;",
                "and say, Thus says the Lord Yahweh: A great eagle with great wings and long feathers, full of feathers, which had various colors, came to Lebanon, and took the top of the cedar:",
                "he cropped off the topmost of the young twigs of it, and carried it to a land of traffic; he set it in a city of merchants.",
                "He took also of the seed of the land, and planted it in a fruitful soil; he placed it beside many waters; he set it as a willow tree.",
                "It grew, and became a spreading vine of low stature, whose branches turned toward him, and its roots were under him: so it became a vine, and brought forth branches, and shot forth sprigs.",
                "There was also another great eagle with great wings and many feathers: and behold, this vine bent its roots toward him, and shot forth its branches toward him, from the beds of its plantation, that he might water it.",
                "It was planted in a good soil by many waters, that it might bring forth branches, and that it might bear fruit, that it might be a goodly vine.",
                "Say, Thus says the Lord Yahweh: Shall it prosper? shall he not pull up its roots, and cut off its fruit, that it may wither; that all its fresh springing leaves may wither? and not by a strong arm or many people can it be raised from its roots.",
                "Yes, behold, being planted, shall it prosper? shall it not utterly wither, when the east wind touches it? it shall wither in the beds where it grew.",
                "Moreover the word of Yahweh came to me, saying,",
                "Say now to the rebellious house, Don\u2019t you know what these things mean? tell them, Behold, the king of Babylon came to Jerusalem, and took its king, and its princes, and brought them to him to Babylon:",
                "and he took of the seed royal, and made a covenant with him; he also brought him under an oath, and took away the mighty of the land;",
                "that the kingdom might be base, that it might not lift itself up, but that by keeping his covenant it might stand.",
                "But he rebelled against him in sending his ambassadors into Egypt, that they might give him horses and many people. Shall he prosper? shall he escape who does such things? shall he break the covenant, and yet escape?",
                "As I live, says the Lord Yahweh, surely in the place where the king dwells who made him king, whose oath he despised, and whose covenant he broke, even with him in the midst of Babylon he shall die.",
                "Neither shall Pharaoh with his mighty army and great company help him in the war, when they cast up mounds and build forts, to cut off many persons.",
                "For he has despised the oath by breaking the covenant; and behold, he had given his hand, and yet has done all these things; he shall not escape.",
                "Therefore thus says the Lord Yahweh: As I live, surely my oath that he has despised, and my covenant that he has broken, I will even bring it on his own head.",
                "I will spread my net on him, and he shall be taken in my snare, and I will bring him to Babylon, and will enter into judgment with him there for his trespass that he has trespassed against me.",
                "All his fugitives in all his bands shall fall by the sword, and those who remain shall be scattered toward every wind: and you shall know that I, Yahweh, have spoken it.",
                "Thus says the Lord Yahweh: I will also take of the lofty top of the cedar, and will set it; I will crop off from the topmost of its young twigs a tender one, and I will plant it on a high and lofty mountain:",
                "in the mountain of the height of Israel will I plant it; and it shall bring forth boughs, and bear fruit, and be a goodly cedar: and under it shall dwell all birds of every wing; in the shade of its branches shall they dwell.",
                "All the trees of the field shall know that I, Yahweh, have brought down the high tree, have exalted the low tree, have dried up the green tree, and have made the dry tree to flourish; I, Yahweh, have spoken and have done it."
            ],
            18: [
                "The word of Yahweh came to me again, saying,",
                "What do you mean, that you use this proverb concerning the land of Israel, saying, The fathers have eaten sour grapes, and the children\u2019s teeth are set on edge?",
                "As I live, says the Lord Yahweh, you shall not have occasion any more to use this proverb in Israel.",
                "Behold, all souls are mine; as the soul of the father, so also the soul of the son is mine: the soul who sins, he shall die.",
                "But if a man is just, and does that which is lawful and right,",
                "and has not eaten on the mountains, neither has lifted up his eyes to the idols of the house of Israel, neither has defiled his neighbor\u2019s wife, neither has come near to a woman in her impurity,",
                "and has not wronged any, but has restored to the debtor his pledge, has taken nothing by robbery, has given his bread to the hungry, and has covered the naked with a garment;",
                "he who has not given forth on interest, neither has taken any increase, who has withdrawn his hand from iniquity, has executed true justice between man and man,",
                "has walked in my statutes, and has kept my ordinances, to deal truly; he is just, he shall surely live, says the Lord Yahweh.",
                "If he fathers a son who is a robber, a shedder of blood, and who does any one of these things,",
                "and who does not any of those duties, but even has eaten on the mountains, and defiled his neighbor\u2019s wife,",
                "has wronged the poor and needy, has taken by robbery, has not restored the pledge, and has lifted up his eyes to the idols, has committed abomination,",
                "has given forth on interest, and has taken increase; shall he then live? he shall not live: he has done all these abominations; he shall surely die; his blood shall be on him.",
                "Now, behold, if he fathers a son, who sees all his father\u2019s sins, which he has done, and fears, and does not such like;",
                "who has not eaten on the mountains, neither has lifted up his eyes to the idols of the house of Israel, has not defiled his neighbor\u2019s wife,",
                "neither has wronged any, has not taken anything to pledge, neither has taken by robbery, but has given his bread to the hungry, and has covered the naked with a garment;",
                "who has withdrawn his hand from the poor, who has not received interest nor increase, has executed my ordinances, has walked in my statutes; he shall not die for the iniquity of his father, he shall surely live.",
                "As for his father, because he cruelly oppressed, robbed his brother, and did that which is not good among his people, behold, he shall die in his iniquity.",
                "Yet you say, Why doesn\u2019t the son bear the iniquity of the father? When the son has done that which is lawful and right, and has kept all my statutes, and has done them, he shall surely live.",
                "The soul who sins, he shall die: the son shall not bear the iniquity of the father, neither shall the father bear the iniquity of the son; the righteousness of the righteous shall be on him, and the wickedness of the wicked shall be on him.",
                "But if the wicked turn from all his sins that he has committed, and keep all my statutes, and do that which is lawful and right, he shall surely live, he shall not die.",
                "None of his transgressions that he has committed shall be remembered against him: in his righteousness that he has done he shall live.",
                "Have I any pleasure in the death of the wicked? says the Lord Yahweh; and not rather that he should return from his way, and live?",
                "But when the righteous turns away from his righteousness, and commits iniquity, and does according to all the abominations that the wicked man does, shall he live? None of his righteous deeds that he has done shall be remembered: in his trespass that he has trespassed, and in his sin that he has sinned, in them shall he die.",
                "Yet you say, The way of the Lord is not equal. Hear now, house of Israel: Is my way not equal? Aren\u2019t your ways unequal?",
                "When the righteous man turns away from his righteousness, and commits iniquity, and dies therein; in his iniquity that he has done shall he die.",
                "Again, when the wicked man turns away from his wickedness that he has committed, and does that which is lawful and right, he shall save his soul alive.",
                "Because he considers, and turns away from all his transgressions that he has committed, he shall surely live, he shall not die.",
                "Yet the house of Israel says, \"The way of the Lord is not fair.\" House of Israel, aren\u2019t my ways fair? Aren\u2019t your ways unfair?",
                "Therefore I will judge you, house of Israel, everyone according to his ways, says the Lord Yahweh. Return, and turn yourselves from all your transgressions; so iniquity shall not be your ruin.",
                "Cast away from you all your transgressions, in which you have transgressed; and make yourself a new heart and a new spirit: for why will you die, house of Israel?",
                "For I have no pleasure in the death of him who dies, says the Lord Yahweh: therefore turn yourselves, and live."
            ],
            19: [
                "Moreover, take up a lamentation for the princes of Israel,",
                "and say, What was your mother? A lioness: she couched among lions, in the midst of the young lions she nourished her cubs.",
                "She brought up one of her cubs: he became a young lion, and he learned to catch the prey; he devoured men.",
                "The nations also heard of him; he was taken in their pit; and they brought him with hooks to the land of Egypt.",
                "Now when she saw that she had waited, and her hope was lost, then she took another of her cubs, and made him a young lion.",
                "He went up and down among the lions; he became a young lion, and he learned to catch the prey; he devoured men.",
                "He knew their palaces, and laid waste their cities; and the land was desolate, and its fullness, because of the noise of his roaring.",
                "Then the nations set against him on every side from the provinces; and they spread their net over him; he was taken in their pit.",
                "They put him in a cage with hooks, and brought him to the king of Babylon; they brought him into strongholds, that his voice should no more be heard on the mountains of Israel.",
                "Your mother was like a vine, in your blood, planted by the waters: it was fruitful and full of branches by reason of many waters.",
                "It had strong rods for the scepters of those who bore rule, and their stature was exalted among the thick boughs, and they were seen in their height with the multitude of their branches.",
                "But it was plucked up in fury, it was cast down to the ground, and the east wind dried up its fruit: its strong rods were broken off and withered; the fire consumed them.",
                "Now it is planted in the wilderness, in a dry and thirsty land.",
                "Fire is gone out of the rods of its branches, it has devoured its fruit, so that there is in it no strong rod to be a scepter to rule. This is a lamentation, and shall be for a lamentation."
            ],
            20: [
                "It happened in the seventh year, in the fifth month, the tenth day of the month, that certain of the elders of Israel came to inquire of Yahweh, and sat before me.",
                "The word of Yahweh came to me, saying,",
                "Son of man, speak to the elders of Israel, and tell them, Thus says the Lord Yahweh: Is it to inquire of me that you have come? As I live, says the Lord Yahweh, I will not be inquired of by you.",
                "Will you judge them, son of man, will you judge them? Cause them to know the abominations of their fathers;",
                "and tell them, Thus says the Lord Yahweh: In the day when I chose Israel, and swore to the seed of the house of Jacob, and made myself known to them in the land of Egypt, when I swore to them, saying, I am Yahweh your God;",
                "in that day I swore to them, to bring them forth out of the land of Egypt into a land that I had searched out for them, flowing with milk and honey, which is the glory of all lands.",
                "I said to them, Cast away every man the abominations of his eyes, and don\u2019t defile yourselves with the idols of Egypt; I am Yahweh your God.",
                "But they rebelled against me, and would not listen to me; they each didn\u2019t throw away the abominations of their eyes, neither did they forsake the idols of Egypt. Then I said I would pour out my wrath on them, to accomplish my anger against them in the midst of the land of Egypt.",
                "But I worked for my name\u2019s sake, that it should not be profaned in the sight of the nations, among which they were, in whose sight I made myself known to them, in bringing them forth out of the land of Egypt.",
                "So I caused them to go forth out of the land of Egypt, and brought them into the wilderness.",
                "I gave them my statutes, and showed them my ordinances, which if a man does, he shall live in them.",
                "Moreover also I gave them my Sabbaths, to be a sign between me and them, that they might know that I am Yahweh who sanctifies them.",
                "But the house of Israel rebelled against me in the wilderness: they didn\u2019t walk in my statutes, and they rejected my ordinances, which if a man keep, he shall live in them; and my Sabbaths they greatly profaned. Then I said I would pour out my wrath on them in the wilderness, to consume them.",
                "But I worked for my name\u2019s sake, that it should not be profaned in the sight of the nations, in whose sight I brought them out.",
                "Moreover also I swore to them in the wilderness, that I would not bring them into the land which I had given them, flowing with milk and honey, which is the glory of all lands;",
                "because they rejected my ordinances, and didn\u2019t walk in my statutes, and profaned my Sabbaths: for their heart went after their idols.",
                "Nevertheless my eye spared them, and I didn\u2019t destroy them, neither did I make a full end of them in the wilderness.",
                "I said to their children in the wilderness, Don\u2019t walk in the statutes of your fathers, neither observe their ordinances, nor defile yourselves with their idols.",
                "I am Yahweh your God: walk in my statutes, and keep my ordinances, and do them;",
                "and make my Sabbaths holy; and they shall be a sign between me and you, that you may know that I am Yahweh your God.",
                "But the children rebelled against me; they didn\u2019t walk in my statutes, neither kept my ordinances to do them, which if a man do, he shall live in them; they profaned my Sabbaths. Then I said I would pour out my wrath on them, to accomplish my anger against them in the wilderness.",
                "Nevertheless I withdrew my hand, and worked for my name\u2019s sake, that it should not be profaned in the sight of the nations, in whose sight I brought them forth.",
                "Moreover I swore to them in the wilderness, that I would scatter them among the nations, and disperse them through the countries;",
                "because they had not executed my ordinances, but had rejected my statutes, and had profaned my Sabbaths, and their eyes were after their fathers\u2019 idols.",
                "Moreover also I gave them statutes that were not good, and ordinances in which they should not live;",
                "and I polluted them in their own gifts, in that they caused to pass through the fire all that opens the womb, that I might make them desolate, to the end that they might know that I am Yahweh.",
                "Therefore, son of man, speak to the house of Israel, and tell them, Thus says the Lord Yahweh: In this moreover have your fathers blasphemed me, in that they have committed a trespass against me.",
                "For when I had brought them into the land, which I swore to give to them, then they saw every high hill, and every thick tree, and they offered there their sacrifices, and there they presented the provocation of their offering; there also they made their pleasant aroma, and they poured out there their drink offerings.",
                "Then I said to them, What does the high place where you go mean? So its name is called Bamah to this day.",
                "Therefore tell the house of Israel, Thus says the Lord Yahweh: Do you pollute yourselves in the way of your fathers? and do you play the prostitute after their abominations?",
                "and when you offer your gifts, when you make your sons to pass through the fire, do you pollute yourselves with all your idols to this day? and shall I be inquired of by you, house of Israel? As I live, says the Lord Yahweh, I will not be inquired of by you;",
                "and that which comes into your mind shall not be at all, in that you say, We will be as the nations, as the families of the countries, to serve wood and stone.",
                "As I live, says the Lord Yahweh, surely with a mighty hand, and with an outstretched arm, and with wrath poured out, will I be king over you:",
                "and I will bring you out from the peoples, and will gather you out of the countries in which you are scattered, with a mighty hand, and with an outstretched arm, and with wrath poured out;",
                "and I will bring you into the wilderness of the peoples, and there will I enter into judgment with you face to face.",
                "Like as I entered into judgment with your fathers in the wilderness of the land of Egypt, so will I enter into judgment with you, says the Lord Yahweh.",
                "I will cause you to pass under the rod, and I will bring you into the bond of the covenant;",
                "and I will purge out from among you the rebels, and those who disobey against me; I will bring them forth out of the land where they live, but they shall not enter into the land of Israel: and you shall know that I am Yahweh.",
                "As for you, house of Israel, thus says the Lord Yahweh: Go, serve everyone his idols, and hereafter also, if you will not listen to me; but my holy name you shall no more profane with your gifts, and with your idols.",
                "For in my holy mountain, in the mountain of the height of Israel, says the Lord Yahweh, there shall all the house of Israel, all of them, serve me in the land: there will I accept them, and there will I require your offerings, and the first fruits of your offerings, with all your holy things.",
                "As a pleasant aroma will I accept you, when I bring you out from the peoples, and gather you out of the countries in which you have been scattered; and I will be sanctified in you in the sight of the nations.",
                "You shall know that I am Yahweh, when I shall bring you into the land of Israel, into the country which I swore to give to your fathers.",
                "There you shall remember your ways, and all your doings, in which you have polluted yourselves; and you shall loathe yourselves in your own sight for all your evils that you have committed.",
                "You shall know that I am Yahweh, when I have dealt with you for my name\u2019s sake, not according to your evil ways, nor according to your corrupt doings, you house of Israel, says the Lord Yahweh.",
                "The word of Yahweh came to me, saying,",
                "Son of man, set your face toward the south, and drop your word toward the south, and prophesy against the forest of the field in the South;",
                "and tell the forest of the South, Hear the word of Yahweh: Thus says the Lord Yahweh, Behold, I will kindle a fire in you, and it shall devour every green tree in you, and every dry tree: the flaming flame shall not be quenched, and all faces from the south to the north shall be burnt thereby.",
                "All flesh shall see that I, Yahweh, have kindled it; it shall not be quenched.",
                "Then I said, Ah Lord Yahweh! they say of me, Isn\u2019t he a speaker of parables?"
            ],
            21: [
                "The word of Yahweh came to me, saying,",
                "Son of man, set your face toward Jerusalem, and drop your word toward the sanctuaries, and prophesy against the land of Israel;",
                "and tell the land of Israel, Thus says Yahweh: Behold, I am against you, and will draw forth my sword out of its sheath, and will cut off from you the righteous and the wicked.",
                "Seeing then that I will cut off from you the righteous and the wicked, therefore shall my sword go forth out of its sheath against all flesh from the south to the north:",
                "and all flesh shall know that I, Yahweh, have drawn forth my sword out of its sheath; it shall not return any more.",
                "Sigh therefore, you son of man; with the breaking of your thighs and with bitterness you will sigh before their eyes.",
                "It shall be, when they tell you, Why do you sigh? that you shall say, Because of the news, for it comes; and every heart shall melt, and all hands shall be feeble, and every spirit shall faint, and all knees shall be weak as water: behold, it comes, and it shall be done, says the Lord Yahweh.",
                "The word of Yahweh came to me, saying,",
                "Son of man, prophesy, and say, Thus says Yahweh: Say, A sword, a sword, it is sharpened, and also furbished;",
                "it is sharpened that it may make a slaughter; it is furbished that it may be as lightning: shall we then make mirth? the rod of my son, it condemns every tree.",
                "It is given to be furbished, that it may be handled: the sword, it is sharpened, yes, it is furbished, to give it into the hand of the killer.",
                "Cry and wail, son of man; for it is on my people, it is on all the princes of Israel: they are delivered over to the sword with my people; strike therefore on your thigh.",
                "For there is a trial; and what if even the rod that condemns shall be no more? says the Lord Yahweh.",
                "You therefore, son of man, prophesy, and strike your hands together; and let the sword be doubled the third time, the sword of the deadly wounded: it is the sword of the great one who is deadly wounded, which enters into their rooms.",
                "I have set the threatening sword against all their gates, that their heart may melt, and their stumblings be multiplied: ah! it is made as lightning, it is pointed for slaughter.",
                "Gather yourselves together, go to the right, set yourselves in array, go to the left, wherever your face is set.",
                "I will also strike my hands together, and I will cause my wrath to rest: I, Yahweh, have spoken it.",
                "The word of Yahweh came to me again, saying,",
                "Also, you son of man, appoint two ways, that the sword of the king of Babylon may come; they both shall come forth out of one land: and mark out a place, mark it out at the head of the way to the city.",
                "You shall appoint a way for the sword to come to Rabbah of the children of Ammon, and to Judah in Jerusalem the fortified.",
                "For the king of Babylon stood at the parting of the way, at the head of the two ways, to use divination: he shook the arrows back and forth, he consulted the teraphim, he looked in the liver.",
                "In his right hand was the divination for Jerusalem, to set battering rams, to open the mouth in the slaughter, to lift up the voice with shouting, to set battering rams against the gates, to cast up mounds, to build forts.",
                "It shall be to them as a false divination in their sight, who have sworn oaths to them; but he brings iniquity to memory, that they may be taken.",
                "Therefore thus says the Lord Yahweh: Because you have made your iniquity to be remembered, in that your transgressions are uncovered, so that in all your doings your sins appear; because you have come to memory, you shall be taken with the hand.",
                "You, deadly wounded wicked one, the prince of Israel, whose day has come, in the time of the iniquity of the end,",
                "thus says the Lord Yahweh: Remove the turban, and take off the crown; this shall be no more the same; exalt that which is low, and abase that which is high.",
                "I will overturn, overturn, overturn it: this also shall be no more, until he come whose right it is; and I will give it him.",
                "You, son of man, prophesy, and say, Thus says the Lord Yahweh concerning the children of Ammon, and concerning their reproach; and say, A sword, a sword is drawn, for the slaughter it is furbished, to cause it to devour, that it may be as lightning;",
                "while they see for you false visions, while they divine lies to you, to lay you on the necks of the wicked who are deadly wounded, whose day has come in the time of the iniquity of the end.",
                "Cause it to return into its sheath. In the place where you were created, in the land of your birth, will I judge you.",
                "I will pour out my indignation on you; I will blow on you with the fire of my wrath; and I will deliver you into the hand of brutish men, skillful to destroy.",
                "You shall be for fuel to the fire; your blood shall be in the midst of the land; you shall be remembered no more: for I, Yahweh, have spoken it."
            ],
            22: [
                "Moreover the word of Yahweh came to me, saying,",
                "You, son of man, will you judge, will you judge the bloody city? then cause her to know all her abominations.",
                "You shall say, Thus says the Lord Yahweh: A city that sheds blood in the midst of her, that her time may come, and that makes idols against herself to defile her!",
                "You have become guilty in your blood that you have shed, and are defiled in your idols which you have made; and you have caused your days to draw near, and have come even to your years: therefore have I made you a reproach to the nations, and a mocking to all the countries.",
                "Those who are near, and those who are far from you, shall mock you, you infamous one and full of tumult.",
                "Behold, the princes of Israel, everyone according to his power, have been in you to shed blood.",
                "In you have they set light by father and mother; in the midst of you have they dealt by oppression with the foreigner; in you have they wronged the fatherless and the widow.",
                "You have despised my holy things, and have profaned my Sabbaths.",
                "Slanderous men have been in you to shed blood; and in you they have eaten on the mountains: in the midst of you they have committed lewdness.",
                "In you have they uncovered their fathers\u2019 nakedness; in you have they humbled her who was unclean in her impurity.",
                "One has committed abomination with his neighbor\u2019s wife; and another has lewdly defiled his daughter-in-law; and another in you has humbled his sister, his father\u2019s daughter.",
                "In you have they taken bribes to shed blood; you have taken interest and increase, and you have greedily gained of your neighbors by oppression, and have forgotten me, says the Lord Yahweh.",
                "Behold, therefore, I have struck my hand at your dishonest gain which you have made, and at your blood which has been in the midst of you.",
                "Can your heart endure, or can your hands be strong, in the days that I shall deal with you? I, Yahweh, have spoken it, and will do it.",
                "I will scatter you among the nations, and disperse you through the countries; and I will consume your filthiness out of you.",
                "You shall be profaned in yourself, in the sight of the nations; and you shall know that I am Yahweh.",
                "The word of Yahweh came to me, saying,",
                "Son of man, the house of Israel has become dross to me: all of them are brass and tin and iron and lead, in the midst of the furnace; they are the dross of silver.",
                "Therefore thus says the Lord Yahweh: Because you have all become dross, therefore, behold, I will gather you into the midst of Jerusalem.",
                "As they gather silver and brass and iron and lead and tin into the midst of the furnace, to blow the fire on it, to melt it; so will I gather you in my anger and in my wrath, and I will lay you there, and melt you.",
                "Yes, I will gather you, and blow on you with the fire of my wrath, and you shall be melted in its midst.",
                "As silver is melted in the midst of the furnace, so you will be melted in its midst; and you will know that I, Yahweh, have poured out my wrath on you.",
                "The word of Yahweh came to me, saying,",
                "Son of man, tell her, You are a land that is not cleansed, nor rained on in the day of indignation.",
                "There is a conspiracy of her prophets in its midst, like a roaring lion ravening the prey: they have devoured souls; they take treasure and precious things; they have made her widows many in its midst.",
                "Her priests have done violence to my law, and have profaned my holy things: they have made no distinction between the holy and the common, neither have they caused men to discern between the unclean and the clean, and have hidden their eyes from my Sabbaths, and I am profaned among them.",
                "Her princes in its midst are like wolves ravening the prey, to shed blood, and to destroy souls, that they may get dishonest gain.",
                "Her prophets have plastered for them with whitewash, seeing false visions, and divining lies to them, saying, Thus says the Lord Yahweh, when Yahweh has not spoken.",
                "The people of the land have used oppression, and exercised robbery; yes, they have troubled the poor and needy, and have oppressed the foreigner wrongfully.",
                "I sought for a man among them, who should build up the wall, and stand in the gap before me for the land, that I should not destroy it; but I found none.",
                "Therefore have I poured out my indignation on them; I have consumed them with the fire of my wrath: their own way have I brought on their heads, says the Lord Yahweh."
            ],
            23: [
                "The word of Yahweh came again to me, saying,",
                "Son of man, there were two women, the daughters of one mother:",
                "and they played the prostitute in Egypt; they played the prostitute in their youth; there were their breasts pressed, and there was handled the bosom of their virginity.",
                "Their names were Oholah the elder, and Oholibah her sister: and they became mine, and they bore sons and daughters. As for their names, Samaria is Oholah, and Jerusalem Oholibah.",
                "Oholah played the prostitute when she was mine; and she doted on her lovers, on the Assyrians her neighbors,",
                "who were clothed with blue, governors and rulers, all of them desirable young men, horsemen riding on horses.",
                "She bestowed her prostitution on them, the choicest men of Assyria all of them; and on whoever she doted, with all their idols she defiled herself.",
                "Neither has she left her prostitution since the days of Egypt; for in her youth they lay with her, and they handled the bosom of her virginity; and they poured out their prostitution on her.",
                "Therefore I delivered her into the hand of her lovers, into the hand of the Assyrians, on whom she doted.",
                "These uncovered her nakedness; they took her sons and her daughters; and her they killed with the sword: and she became a byword among women; for they executed judgments on her.",
                "Her sister Oholibah saw this, yet was she more corrupt in her doting than she, and in her prostitution which were more than the prostitution of her sister.",
                "She doted on the Assyrians, governors and rulers, her neighbors, clothed most gorgeously, horsemen riding on horses, all of them desirable young men.",
                "I saw that she was defiled; they both took one way.",
                "She increased her prostitution; for she saw men portrayed on the wall, the images of the Chaldeans portrayed with vermilion,",
                "dressed with girdles on their waists, with flowing turbans on their heads, all of them princes to look on, after the likeness of the Babylonians in Chaldea, the land of their birth.",
                "As soon as she saw them she doted on them, and sent messengers to them into Chaldea.",
                "The Babylonians came to her into the bed of love, and they defiled her with their prostitution, and she was polluted with them, and her soul was alienated from them.",
                "So she uncovered her prostitution, and uncovered her nakedness: then my soul was alienated from her, like as my soul was alienated from her sister.",
                "Yet she multiplied her prostitution, remembering the days of her youth, in which she had played the prostitute in the land of Egypt.",
                "She doted on their paramours, whose flesh is as the flesh of donkeys, and whose issue is like the issue of horses.",
                "Thus you called to memory the lewdness of your youth, in the handling of your bosom by the Egyptians for the breasts of your youth.",
                "Therefore, Oholibah, thus says the Lord Yahweh: Behold, I will raise up your lovers against you, from whom your soul is alienated, and I will bring them against you on every side:",
                "the Babylonians and all the Chaldeans, Pekod and Shoa and Koa, and all the Assyrians with them; desirable young men, governors and rulers all of them, princes and men of renown, all of them riding on horses.",
                "They shall come against you with weapons, chariots, and wagons, and with a company of peoples; they shall set themselves against you with buckler and shield and helmet all around; and I will commit the judgment to them, and they shall judge you according to their judgments.",
                "I will set my jealousy against you, and they shall deal with you in fury; they shall take away your nose and your ears; and your residue shall fall by the sword: they shall take your sons and your daughters; and your residue shall be devoured by the fire.",
                "They shall also strip you of your clothes, and take away your beautiful jewels.",
                "Thus will I make your lewdness to cease from you, and your prostitution brought from the land of Egypt; so that you shall not lift up your eyes to them, nor remember Egypt any more.",
                "For thus says the Lord Yahweh: Behold, I will deliver you into the hand of them whom you hate, into the hand of them from whom your soul is alienated;",
                "and they shall deal with you in hatred, and shall take away all your labor, and shall leave you naked and bare; and the nakedness of your prostitution shall be uncovered, both your lewdness and your prostitution.",
                "These things shall be done to you, because you have played the prostitute after the nations, and because you are polluted with their idols.",
                "You have walked in the way of your sister; therefore will I give her cup into your hand.",
                "Thus says the Lord Yahweh: You will drink of your sister\u2019s cup, which is deep and large; you will be ridiculed and held in derision; it contains much.",
                "You shall be filled with drunkenness and sorrow, with the cup of astonishment and desolation, with the cup of your sister Samaria.",
                "You shall even drink it and drain it out, and you shall gnaw the broken pieces of it, and shall tear your breasts; for I have spoken it, says the Lord Yahweh.",
                "Therefore thus says the Lord Yahweh: Because you have forgotten me, and cast me behind your back, therefore you also bear your lewdness and your prostitution.",
                "Yahweh said moreover to me: Son of man, will you judge Oholah and Oholibah? then declare to them their abominations.",
                "For they have committed adultery, and blood is in their hands; and with their idols have they committed adultery; and they have also caused their sons, whom they bore to me, to pass through the fire to them to be devoured.",
                "Moreover this they have done to me: they have defiled my sanctuary in the same day, and have profaned my Sabbaths.",
                "For when they had slain their children to their idols, then they came the same day into my sanctuary to profane it; and behold, thus have they done in the midst of my house.",
                "Furthermore you have sent for men who come from far, to whom a messenger was sent, and behold, they came; for whom you did wash yourself, paint your eyes, and decorate yourself with ornaments,",
                "and sit on a stately bed, with a table prepared before it, whereupon you set my incense and my oil.",
                "The voice of a multitude being at ease was with her: and with men of the common sort were brought drunkards from the wilderness; and they put bracelets on their hands twain, and beautiful crowns on their heads.",
                "Then I said of her who was old in adulteries, Now will they play the prostitute with her, and she with them.",
                "They went in to her, as they go in to a prostitute: so went they in to Oholah and to Oholibah, the lewd women.",
                "Righteous men, they shall judge them with the judgment of adulteresses, and with the judgment of women who shed blood; because they are adulteresses, and blood is in their hands.",
                "For thus says the Lord Yahweh: I will bring up a company against them, and will give them to be tossed back and forth and robbed.",
                "The company shall stone them with stones, and dispatch them with their swords; they shall kill their sons and their daughters, and burn up their houses with fire.",
                "Thus will I cause lewdness to cease out of the land, that all women may be taught not to do after your lewdness.",
                "They shall recompense your lewdness on you, and you shall bear the sins of your idols; and you shall know that I am the Lord Yahweh."
            ],
            24: [
                "Again, in the ninth year, in the tenth month, in the tenth day of the month, the word of Yahweh came to me, saying,",
                "Son of man, write the name of the day, even of this same day: the king of Babylon drew close to Jerusalem this same day.",
                "Utter a parable to the rebellious house, and tell them, Thus says the Lord Yahweh, Set on the caldron, set it on, and also pour water into it:",
                "gather its pieces into it, even every good piece, the thigh, and the shoulder; fill it with the choice bones.",
                "Take the choice of the flock, and also a pile of wood for the bones under the caldron; make it boil well; yes, let its bones be boiled in its midst.",
                "Therefore thus says the Lord Yahweh: Woe to the bloody city, to the caldron whose rust is therein, and whose rust is not gone out of it! take out of it piece after piece; No lot is fallen on it.",
                "For her blood is in the midst of her; she set it on the bare rock; she didn\u2019t pour it on the ground, to cover it with dust.",
                "That it may cause wrath to come up to take vengeance, I have set her blood on the bare rock, that it should not be covered.",
                "Therefore thus says the Lord Yahweh: Woe to the bloody city! I also will make the pile great.",
                "Heap on the wood, make the fire hot, boil well the flesh, and make thick the broth, and let the bones be burned.",
                "Then set it empty on its coals, that it may be hot, and its brass may burn, and that its filthiness may be molten in it, that its rust may be consumed.",
                "She has wearied herself with toil; yet her great rust doesn\u2019t go forth out of her; her rust doesn\u2019t go forth by fire.",
                "In your filthiness is lewdness: because I have cleansed you and you weren\u2019t cleansed, you shall not be cleansed from your filthiness any more, until I have caused my wrath toward you to rest.",
                "I, Yahweh, have spoken it: it shall happen, and I will do it: I will not go back, neither will I spare, neither will I repent; according to your ways, and according to your doings, shall they judge you, says the Lord Yahweh.",
                "Also the word of Yahweh came to me, saying,",
                "Son of man, behold, I will take away from you the desire of your eyes with a stroke: yet you shall neither mourn nor weep, neither shall your tears run down.",
                "Sigh, but not aloud, make no mourning for the dead; bind your headdress on you, and put your shoes on your feet, and don\u2019t cover your lips, and don\u2019t eat men\u2019s bread.",
                "So I spoke to the people in the morning; and at even my wife died; and I did in the morning as I was commanded.",
                "The people said to me, Won\u2019t you tell us what these things are to us, that you do so?",
                "Then I said to them, The word of Yahweh came to me, saying,",
                "Speak to the house of Israel, Thus says the Lord Yahweh: Behold, I will profane my sanctuary, the pride of your power, the desire of your eyes, and that which your soul pities; and your sons and your daughters whom you have left behind shall fall by the sword.",
                "You shall do as I have done: you shall not cover your lips, nor eat the bread of men.",
                "Your tires shall be on your heads, and your shoes on your feet: you shall not mourn nor weep; but you shall pine away in your iniquities, and moan one toward another.",
                "Thus Ezekiel shall be a sign to you; according to all that he has done, you will do. When this comes, then you will know that I am the Lord Yahweh.",
                "You, son of man, shall it not be in the day when I take from them their strength, the joy of their glory, the desire of their eyes, and that whereupon they set their heart, their sons and their daughters,",
                "that in that day he who escapes shall come to you, to cause you to hear it with your ears?",
                "In that day your mouth will be opened to him who has escaped, and you shall speak, and be no more mute: so you will be a sign to them; and they shall know that I am Yahweh."
            ],
            25: [
                "The word of Yahweh came to me, saying,",
                "Son of man, set your face toward the children of Ammon, and prophesy against them:",
                "and tell the children of Ammon, Hear the word of the Lord Yahweh: Thus says the Lord Yahweh, Because you said, Aha, against my sanctuary, when it was profaned; and against the land of Israel, when it was made desolate; and against the house of Judah, when they went into captivity:",
                "therefore, behold, I will deliver you to the children of the east for a possession, and they shall set their encampments in you, and make their dwellings in you; they shall eat your fruit, and they shall drink your milk.",
                "I will make Rabbah a stable for camels, and the children of Ammon a resting place for flocks: and you shall know that I am Yahweh.",
                "For thus says the Lord Yahweh: Because you have clapped your hands, and stamped with the feet, and rejoiced with all the despite of your soul against the land of Israel;",
                "therefore, behold, I have stretched out my hand on you, and will deliver you for a spoil to the nations; and I will cut you off from the peoples, and I will cause you to perish out of the countries: I will destroy you; and you shall know that I am Yahweh.",
                "Thus says the Lord Yahweh: Because Moab and Seir say, Behold, the house of Judah is like all the nations;",
                "therefore, behold, I will open the side of Moab from the cities, from his cities which are on his frontiers, the glory of the country, Beth Jeshimoth, Baal Meon, and Kiriathaim,",
                "to the children of the east, to go against the children of Ammon; and I will give them for a possession, that the children of Ammon may not be remembered among the nations.",
                "and I will execute judgments on Moab; and they shall know that I am Yahweh.",
                "Thus says the Lord Yahweh: Because Edom has dealt against the house of Judah by taking vengeance, and has greatly offended, and revenged himself on them;",
                "therefore thus says the Lord Yahweh, I will stretch out my hand on Edom, and will cut off man and animal from it; and I will make it desolate from Teman; even to Dedan shall they fall by the sword.",
                "I will lay my vengeance on Edom by the hand of my people Israel; and they shall do in Edom according to my anger and according to my wrath; and they shall know my vengeance, says the Lord Yahweh.",
                "Thus says the Lord Yahweh: Because the Philistines have dealt by revenge, and have taken vengeance with despite of soul to destroy with perpetual enmity;",
                "therefore thus says the Lord Yahweh, Behold, I will stretch out my hand on the Philistines, and I will cut off the Cherethites, and destroy the remnant of the sea coast.",
                "I will execute great vengeance on them with wrathful rebukes; and they shall know that I am Yahweh, when I shall lay my vengeance on them."
            ],
            26: [
                "It happened in the eleventh year, in the first day of the month, that the word of Yahweh came to me, saying,",
                "Son of man, because Tyre has said against Jerusalem, Aha, she is broken: the gate of the peoples; she is turned to me; I shall be replenished, now that she is laid waste:",
                "therefore thus says the Lord Yahweh, Behold, I am against you, Tyre, and will cause many nations to come up against you, as the sea causes its waves to come up.",
                "They shall destroy the walls of Tyre, and break down her towers: I will also scrape her dust from her, and make her a bare rock.",
                "She shall be a place for the spreading of nets in the midst of the sea; for I have spoken it, says the Lord Yahweh; and she shall become a spoil to the nations.",
                "Her daughters who are in the field shall be slain with the sword: and they shall know that I am Yahweh.",
                "For thus says the Lord Yahweh: Behold, I will bring on Tyre Nebuchadnezzar king of Babylon, king of kings, from the north, with horses, and with chariots, and with horsemen, and a company, and many people.",
                "He shall kill your daughters in the field with the sword; and he shall make forts against you, and cast up a mound against you, and raise up the buckler against you.",
                "He shall set his battering engines against your walls, and with his axes he shall break down your towers.",
                "By reason of the abundance of his horses their dust shall cover you: your walls shall shake at the noise of the horsemen, and of the wagons, and of the chariots, when he shall enter into your gates, as men enter into a city in which is made a breach.",
                "With the hoofs of his horses shall he tread down all your streets; he shall kill your people with the sword; and the pillars of your strength shall go down to the ground.",
                "They shall make a spoil of your riches, and make a prey of your merchandise; and they shall break down your walls, and destroy your pleasant houses; and they shall lay your stones and your timber and your dust in the midst of the waters.",
                "I will cause the noise of your songs to cease; and the sound of your harps shall be no more heard.",
                "I will make you a bare rock; you shall be a place for the spreading of nets; you shall be built no more: for I Yahweh have spoken it, says the Lord Yahweh.",
                "Thus says the Lord Yahweh to Tyre: shall not the islands shake at the sound of your fall, when the wounded groan, when the slaughter is made in the midst of you?",
                "Then all the princes of the sea shall come down from their thrones, and lay aside their robes, and strip off their embroidered garments: they shall clothe themselves with trembling; they shall sit on the ground, and shall tremble every moment, and be astonished at you.",
                "They shall take up a lamentation over you, and tell you, How you are destroyed, who were inhabited by seafaring men, the renowned city, who was strong in the sea, she and her inhabitants, who caused their terror to be on all who lived there!",
                "Now shall the islands tremble in the day of your fall; yes, the islands that are in the sea shall be dismayed at your departure.",
                "For thus says the Lord Yahweh: When I shall make you a desolate city, like the cities that are not inhabited; when I shall bring up the deep on you, and the great waters shall cover you;",
                "then will I bring you down with those who descend into the pit, to the people of old time, and will make you to dwell in the lower parts of the earth, in the places that are desolate of old, with those who go down to the pit, that you be not inhabited; and I will set glory in the land of the living:",
                "I will make you a terror, and you shall no more have any being; though you are sought for, yet you will never be found again, says the Lord Yahweh."
            ],
            27: [
                "The word of Yahweh came again to me, saying,",
                "You, son of man, take up a lamentation over Tyre;",
                "and tell Tyre, you who dwell at the entry of the sea, who are the merchant of the peoples to many islands, thus says the Lord Yahweh: You, Tyre, have said, I am perfect in beauty.",
                "Your borders are in the heart of the seas; your builders have perfected your beauty.",
                "They have made all your planks of fir trees from Senir; they have taken a cedar from Lebanon to make a mast for you.",
                "Of the oaks of Bashan have they made your oars; they have made your benches of ivory inlaid in boxwood, from the islands of Kittim.",
                "Of fine linen with embroidered work from Egypt was your sail, that it might be to you for a banner; blue and purple from the islands of Elishah was your awning.",
                "The inhabitants of Sidon and Arvad were your rowers: your wise men, Tyre, were in you, they were your pilots.",
                "The old men of Gebal and the wise men of it were in you your repairers of ship seams: all the ships of the sea with their mariners were in you to deal in your merchandise.",
                "Persia and Lud and Put were in your army, your men of war: they hanged the shield and helmet in you; they set forth your comeliness.",
                "The men of Arvad with your army were on your walls all around, and valorous men were in your towers; they hanged their shields on your walls all around; they have perfected your beauty.",
                "Tarshish was your merchant by reason of the multitude of all kinds of riches; with silver, iron, tin, and lead, they traded for your wares.",
                "Javan, Tubal, and Meshech, they were your traffickers; they traded the persons of men and vessels of brass for your merchandise.",
                "They of the house of Togarmah traded for your wares with horses and war horses and mules.",
                "The men of Dedan were your traffickers; many islands were the market of your hand: they brought you in exchange horns of ivory and ebony.",
                "Syria was your merchant by reason of the multitude of your handiworks: they traded for your wares with emeralds, purple, and embroidered work, and fine linen, and coral, and rubies.",
                "Judah, and the land of Israel, they were your traffickers: they traded for your merchandise wheat of Minnith, and confections, and honey, and oil, and balm.",
                "Damascus was your merchant for the multitude of your handiworks, by reason of the multitude of all kinds of riches, with the wine of Helbon, and white wool.",
                "Vedan and Javan traded with yarn for your wares: bright iron, cassia, and calamus, were among your merchandise.",
                "Dedan was your trafficker in precious cloths for riding.",
                "Arabia, and all the princes of Kedar, they were the merchants of your hand; in lambs, and rams, and goats, in these were they your merchants.",
                "The traffickers of Sheba and Raamah, they were your traffickers; they traded for your wares with the chief of all spices, and with all precious stones, and gold.",
                "Haran and Canneh and Eden, the traffickers of Sheba, Asshur and Chilmad, were your traffickers.",
                "These were your traffickers in choice wares, in wrappings of blue and embroidered work, and in chests of rich clothing, bound with cords and made of cedar, among your merchandise.",
                "The ships of Tarshish were your caravans for your merchandise: and you were replenished, and made very glorious in the heart of the seas.",
                "Your rowers have brought you into great waters: the east wind has broken you in the heart of the seas.",
                "Your riches, and your wares, your merchandise, your mariners, and your pilots, your repairers of ship seams, and the dealers in your merchandise, and all your men of war, who are in you, with all your company which is in the midst of you, shall fall into the heart of the seas in the day of your ruin.",
                "At the sound of the cry of your pilots the suburbs shall shake.",
                "All who handled the oar, the mariners, and all the pilots of the sea, shall come down from their ships; they shall stand on the land,",
                "and shall cause their voice to be heard over you, and shall cry bitterly, and shall cast up dust on their heads, they shall wallow themselves in the ashes:",
                "and they shall make themselves bald for you, and clothe them with sackcloth, and they shall weep for you in bitterness of soul with bitter mourning.",
                "In their wailing they shall take up a lamentation for you, and lament over you, saying, Who is there like Tyre, like her who is brought to silence in the midst of the sea?",
                "When your wares went forth out of the seas, you filled many peoples; you enriched the kings of the earth with the multitude of your riches and of your merchandise.",
                "In the time that you were broken by the seas in the depths of the waters, your merchandise and all your company fell in your midst.",
                "All the inhabitants of the islands are astonished at you, and their kings are horribly afraid; they are troubled in their face.",
                "The merchants among the peoples hiss at you; you are become a terror, and you shall nevermore have any being."
            ],
            28: [
                "The word of Yahweh came again to me, saying,",
                "Son of man, tell the prince of Tyre, Thus says the Lord Yahweh: Because your heart is lifted up, and you have said, I am a god, I sit in the seat of God, in the midst of the seas; yet you are man, and not God, though you set your heart as the heart of God\u2014",
                "behold, you are wiser than Daniel; there is no secret that is hidden from you;",
                "by your wisdom and by your understanding you have gotten yourself riches, and have gotten gold and silver into your treasures;",
                "by your great wisdom and by your traffic you have increased your riches, and your heart is lifted up because of your riches\u2014",
                "therefore thus says the Lord Yahweh: Because you have set your heart as the heart of God,",
                "therefore, behold, I will bring strangers on you, the terrible of the nations; and they shall draw their swords against the beauty of your wisdom, and they shall defile your brightness.",
                "They shall bring you down to the pit; and you shall die the death of those who are slain, in the heart of the seas.",
                "Will you yet say before him who kills you, I am God? but you are man, and not God, in the hand of him who wounds you.",
                "You shall die the death of the uncircumcised by the hand of strangers: for I have spoken it, says the Lord Yahweh.",
                "Moreover the word of Yahweh came to me, saying,",
                "Son of man, take up a lamentation over the king of Tyre, and tell him, Thus says the Lord Yahweh: You seal up the sum, full of wisdom, and perfect in beauty.",
                "You were in Eden, the garden of God; every precious stone adorned you: ruby, topaz, emerald, chrysolite, onyx, jasper, sapphire, turquoise, and beryl. Gold work of tambourines and of pipes was in you. In the day that you were created they were prepared.",
                "You were the anointed cherub who covers: and I set you, so that you were on the holy mountain of God; you have walked up and down in the midst of the stones of fire.",
                "You were perfect in your ways from the day that you were created, until unrighteousness was found in you.",
                "By the abundance of your traffic they filled the midst of you with violence, and you have sinned: therefore I have cast you as profane out of the mountain of God; and I have destroyed you, covering cherub, from the midst of the stones of fire.",
                "Your heart was lifted up because of your beauty; you have corrupted your wisdom by reason of your brightness: I have cast you to the ground; I have laid you before kings, that they may see you.",
                "By the multitude of your iniquities, in the unrighteousness of your traffic, you have profaned your sanctuaries; therefore have I brought forth a fire from the midst of you; it has devoured you, and I have turned you to ashes on the earth in the sight of all those who see you.",
                "All those who know you among the peoples shall be astonished at you: you have become a terror, and you shall nevermore have any being.",
                "The word of Yahweh came to me, saying,",
                "Son of man, set your face toward Sidon, and prophesy against it,",
                "and say, Thus says the Lord Yahweh: Behold, I am against you, Sidon; and I will be glorified in the midst of you; and they shall know that I am Yahweh, when I shall have executed judgments in her, and shall be sanctified in her.",
                "For I will send pestilence into her, and blood into her streets; and the wounded shall fall in the midst of her, with the sword on her on every side; and they shall know that I am Yahweh.",
                "There shall be no more a pricking brier to the house of Israel, nor a hurting thorn of any that are around them, that scorned them; and they shall know that I am the Lord Yahweh.",
                "Thus says the Lord Yahweh: When I shall have gathered the house of Israel from the peoples among whom they are scattered, and shall be sanctified in them in the sight of the nations, then shall they dwell in their own land which I gave to my servant Jacob.",
                "They shall dwell securely therein; yes, they shall build houses, and plant vineyards, and shall dwell securely, when I have executed judgments on all those who do them despite all around them; and they shall know that I am Yahweh their God."
            ],
            29: [
                "In the tenth year, in the tenth month, on the twelfth day of the month, the word of Yahweh came to me, saying,",
                "\"Son of man, set your face against Pharaoh king of Egypt, and prophesy against him and against all Egypt.",
                "Speak and say, \u2018Thus says the Lord Yahweh: \"Behold, I am against you, Pharaoh king of Egypt, the great monster that lies in the midst of his rivers, that has said, \u2018My river is my own, and I have made it for myself.\u2019",
                "I will put hooks in your jaws, and I will make the fish of your rivers stick to your scales; and I will bring you up out of the midst of your rivers, with all the fish of your rivers which stick to your scales.",
                "I\u2019ll cast you forth into the wilderness, you and all the fish of your rivers. You\u2019ll fall on the open field. You won\u2019t be brought together, nor gathered. I have given you for food to the animals of the earth and to the birds of the sky.",
                "All the inhabitants of Egypt will know that I am Yahweh, because they have been a staff of reed to the house of Israel.",
                "When they took hold of you by your hand, you broke, and tore all their shoulders; and when they leaned on you, you broke, and paralyzed all of their thighs.\"",
                "Therefore thus says the Lord Yahweh: \"Behold, I will bring a sword on you, and will cut off man and animal from you.",
                "The land of Egypt shall be a desolation and a waste; and they shall know that I am Yahweh. Because he has said, \u2018The river is mine, and I have made it;\u2019",
                "therefore, behold, I am against you, and against your rivers, and I will make the land of Egypt an utter waste and desolation, from the tower of Seveneh even to the border of Ethiopia.",
                "No foot of man shall pass through it, nor foot of animal shall pass through it, neither shall it be inhabited forty years.",
                "I will make the land of Egypt a desolation in the midst of the countries that are desolate; and her cities among the cities that are laid waste shall be a desolation forty years; and I will scatter the Egyptians among the nations, and will disperse them through the countries.\"",
                "For thus says the Lord Yahweh: \"At the end of forty years will I gather the Egyptians from the peoples where they were scattered;",
                "and I will bring back the captivity of Egypt, and will cause them to return into the land of Pathros, into the land of their birth; and they shall be there a base kingdom.",
                "It shall be the base of the kingdoms; neither shall it any more lift itself up above the nations: and I will diminish them, that they shall no more rule over the nations.",
                "It shall be no more the confidence of the house of Israel, bringing iniquity to memory, when they turn to look after them: and they shall know that I am the Lord Yahweh.\"\u2019\"",
                "It came to pass in the seven and twentieth year, in the first month, in the first day of the month, the word of Yahweh came to me, saying,",
                "Son of man, Nebuchadnezzar king of Babylon caused his army to serve a great service against Tyre: every head was made bald, and every shoulder was worn; yet had he no wages, nor his army, from Tyre, for the service that he had served against it.",
                "Therefore thus says the Lord Yahweh: Behold, I will give the land of Egypt to Nebuchadnezzar king of Babylon; and he shall carry off her multitude, and take her spoil, and take her prey; and it shall be the wages for his army.",
                "I have given him the land of Egypt as his recompense for which he served, because they worked for me, says the Lord Yahweh.",
                "In that day will I cause a horn to bud forth to the house of Israel, and I will give you the opening of the mouth in their midst; and they shall know that I am Yahweh."
            ],
            30: [
                "The word of Yahweh came again to me, saying,",
                "Son of man, prophesy, and say, Thus says the Lord Yahweh: Wail, Alas for the day!",
                "For the day is near, even the day of Yahweh is near; it shall be a day of clouds, a time of the nations.",
                "A sword shall come on Egypt, and anguish shall be in Ethiopia, when the slain shall fall in Egypt; and they shall take away her multitude, and her foundations shall be broken down.",
                "Ethiopia, and Put, and Lud, and all the mixed people, and Cub, and the children of the land that is allied with them, shall fall with them by the sword.",
                "Thus says Yahweh: They also who uphold Egypt shall fall; and the pride of her power shall come down: from the tower of Seveneh shall they fall in it by the sword, says the Lord Yahweh.",
                "They shall be desolate in the midst of the countries that are desolate; and her cities shall be in the midst of the cities that are wasted.",
                "They shall know that I am Yahweh, when I have set a fire in Egypt, and all her helpers are destroyed.",
                "In that day shall messengers go forth from before me in ships to make the careless Ethiopians afraid; and there shall be anguish on them, as in the day of Egypt; for, behold, it comes.",
                "Thus says the Lord Yahweh: I will also make the multitude of Egypt to cease, by the hand of Nebuchadnezzar king of Babylon.",
                "He and his people with him, the terrible of the nations, shall be brought in to destroy the land; and they shall draw their swords against Egypt, and fill the land with the slain.",
                "I will make the rivers dry, and will sell the land into the hand of evil men; and I will make the land desolate, and all that is therein, by the hand of strangers: I, Yahweh, have spoken it.",
                "Thus says the Lord Yahweh: I will also destroy the idols, and I will cause the images to cease from Memphis; and there shall be no more a prince from the land of Egypt: and I will put a fear in the land of Egypt.",
                "I will make Pathros desolate, and will set a fire in Zoan, and will execute judgments on No.",
                "I will pour my wrath on Sin, the stronghold of Egypt; and I will cut off the multitude of No.",
                "I will set a fire in Egypt: Sin shall be in great anguish, and No shall be broken up; and Memphis shall have adversaries in the daytime.",
                "The young men of Aven and of Pibeseth shall fall by the sword; and these cities shall go into captivity.",
                "At Tehaphnehes also the day shall withdraw itself, when I shall break there the yokes of Egypt, and the pride of her power shall cease in her: as for her, a cloud shall cover her, and her daughters shall go into captivity.",
                "Thus will I execute judgments on Egypt; and they shall know that I am Yahweh.",
                "It happened in the eleventh year, in the first month, in the seventh day of the month, that the word of Yahweh came to me, saying,",
                "Son of man, I have broken the arm of Pharaoh king of Egypt; and behold, it has not been bound up, to apply healing medicines, to put a bandage to bind it, that it be strong to hold the sword.",
                "Therefore thus says the Lord Yahweh: Behold, I am against Pharaoh king of Egypt, and will break his arms, the strong arm, and that which was broken; and I will cause the sword to fall out of his hand.",
                "I will scatter the Egyptians among the nations, and will disperse them through the countries.",
                "I will strengthen the arms of the king of Babylon, and put my sword in his hand: but I will break the arms of Pharaoh, and he shall groan before him with the groanings of a deadly wounded man.",
                "I will hold up the arms of the king of Babylon; and the arms of Pharaoh shall fall down; and they shall know that I am Yahweh, when I shall put my sword into the hand of the king of Babylon, and he shall stretch it out on the land of Egypt.",
                "I will scatter the Egyptians among the nations, and disperse them through the countries; and they shall know that I am Yahweh."
            ],
            31: [
                "It happened in the eleventh year, in the third month, in the first day of the month, that the word of Yahweh came to me, saying,",
                "Son of man, tell Pharaoh king of Egypt, and to his multitude: Whom are you like in your greatness?",
                "Behold, the Assyrian was a cedar in Lebanon with beautiful branches, and with a forest-like shade, and of high stature; and its top was among the thick boughs.",
                "The waters nourished it, the deep made it to grow: its rivers ran all around its plantation; and it sent out its channels to all the trees of the field.",
                "Therefore its stature was exalted above all the trees of the field; and its boughs were multiplied, and its branches became long by reason of many waters, when it shot them forth.",
                "All the birds of the sky made their nests in its boughs; and under its branches all the animals of the field brought forth their young; and all great nations lived under its shadow.",
                "Thus was it beautiful in its greatness, in the length of its branches; for its root was by many waters.",
                "The cedars in the garden of God could not hide it; the fir trees were not like its boughs, and the plane trees were not as its branches; nor was any tree in the garden of God like it in its beauty.",
                "I made it beautiful by the multitude of its branches, so that all the trees of Eden, that were in the garden of God, envied it.",
                "Therefore thus said the Lord Yahweh: Because you are exalted in stature, and he has set his top among the thick boughs, and his heart is lifted up in his height;",
                "I will even deliver him into the hand of the mighty one of the nations; he shall surely deal with him; I have driven him out for his wickedness.",
                "Strangers, the terrible of the nations, have cut him off, and have left him: on the mountains and in all the valleys his branches are fallen, and his boughs are broken by all the watercourses of the land; and all the peoples of the earth are gone down from his shadow, and have left him.",
                "On his ruin all the birds of the sky shall dwell, and all the animals of the field shall be on his branches;",
                "to the end that none of all the trees by the waters exalt themselves in their stature, neither set their top among the thick boughs, nor that their mighty ones stand up on their height, even all who drink water: for they are all delivered to death, to the lower parts of the earth, in the midst of the children of men, with those who go down to the pit.",
                "Thus says the Lord Yahweh: In the day when he went down to Sheol I caused a mourning: I covered the deep for him, and I restrained its rivers; and the great waters were stayed; and I caused Lebanon to mourn for him, and all the trees of the field fainted for him.",
                "I made the nations to shake at the sound of his fall, when I cast him down to Sheol with those who descend into the pit; and all the trees of Eden, the choice and best of Lebanon, all that drink water, were comforted in the lower parts of the earth.",
                "They also went down into Sheol with him to those who are slain by the sword; yes, those who were his arm, that lived under his shadow in the midst of the nations.",
                "To whom are you thus like in glory and in greatness among the trees of Eden? yet you will be brought down with the trees of Eden to the lower parts of the earth: you shall lie in the midst of the uncircumcised, with those who are slain by the sword. This is Pharaoh and all his multitude, says the Lord Yahweh."
            ],
            32: [
                "It happened in the twelfth year, in the twelfth month, in the first day of the month, that the word of Yahweh came to me, saying,",
                "Son of man, take up a lamentation over Pharaoh king of Egypt, and tell him, You were likened to a young lion of the nations: yet you are as a monster in the seas; and you broke out with your rivers, and troubled the waters with your feet, and fouled their rivers.",
                "Thus says the Lord Yahweh: I will spread out my net on you with a company of many peoples; and they shall bring you up in my net.",
                "I will leave you on the land, I will cast you forth on the open field, and will cause all the birds of the sky to settle on you, and I will satisfy the animals of the whole earth with you.",
                "I will lay your flesh on the mountains, and fill the valleys with your height.",
                "I will also water with your blood the land in which you swim, even to the mountains; and the watercourses shall be full of you.",
                "When I shall extinguish you, I will cover the heavens, and make its stars dark; I will cover the sun with a cloud, and the moon shall not give its light.",
                "All the bright lights of the sky will I make dark over you, and set darkness on your land, says the Lord Yahweh.",
                "I will also trouble the hearts of many peoples, when I shall bring your destruction among the nations, into the countries which you have not known.",
                "Yes, I will make many peoples amazed at you, and their kings shall be horribly afraid for you, when I shall brandish my sword before them; and they shall tremble at every moment, every man for his own life, in the day of your fall.",
                "For thus says the Lord Yahweh: The sword of the king of Babylon shall come on you.",
                "By the swords of the mighty will I cause your multitude to fall; the terrible of the nations are they all: and they shall bring to nothing the pride of Egypt, and all its multitude shall be destroyed.",
                "I will destroy also all its animals from beside many waters; neither shall the foot of man trouble them any more, nor the hoofs of animals trouble them.",
                "Then will I make their waters clear, and cause their rivers to run like oil, says the Lord Yahweh.",
                "When I shall make the land of Egypt desolate and waste, a land destitute of that of which it was full, when I shall strike all those who dwell therein, then shall they know that I am Yahweh.",
                "This is the lamentation with which they shall lament; the daughters of the nations shall lament therewith; over Egypt, and over all her multitude, shall they lament therewith, says the Lord Yahweh.",
                "It happened also in the twelfth year, in the fifteenth day of the month, that the word of Yahweh came to me, saying,",
                "Son of man, wail for the multitude of Egypt, and cast them down, even her, and the daughters of the famous nations, to the lower parts of the earth, with those who go down into the pit.",
                "Whom do you pass in beauty? Go down, and be laid with the uncircumcised.",
                "They shall fall in the midst of those who are slain by the sword: she is delivered to the sword; draw her away and all her multitudes.",
                "The strong among the mighty shall speak to him out of the midst of Sheol with those who help him: they are gone down, they lie still, even the uncircumcised, slain by the sword.",
                "Asshur is there and all her company; her graves are all around her; all of them slain, fallen by the sword;",
                "whose graves are set in the uttermost parts of the pit, and her company is around her grave; all of them slain, fallen by the sword, who caused terror in the land of the living.",
                "There is Elam and all her multitude around her grave; all of them slain, fallen by the sword, who are gone down uncircumcised into the lower parts of the earth, who caused their terror in the land of the living, and have borne their shame with those who go down to the pit.",
                "They have set her a bed in the midst of the slain with all her multitude; her graves are around her; all of them uncircumcised, slain by the sword; for their terror was caused in the land of the living, and they have borne their shame with those who go down to the pit: he is put in the midst of those who are slain.",
                "There is Meshech, Tubal, and all their multitude; their graves are around them; all of them uncircumcised, slain by the sword; for they caused their terror in the land of the living.",
                "They shall not lie with the mighty who are fallen of the uncircumcised, who are gone down to Sheol with their weapons of war, and have laid their swords under their heads, and their iniquities are on their bones; for they were the terror of the mighty in the land of the living.",
                "But you shall be broken in the midst of the uncircumcised, and shall lie with those who are slain by the sword.",
                "There is Edom, her kings and all her princes, who in their might are laid with those who are slain by the sword: they shall lie with the uncircumcised, and with those who go down to the pit.",
                "There are the princes of the north, all of them, and all the Sidonians, who are gone down with the slain; in the terror which they caused by their might they are put to shame; and they lie uncircumcised with those who are slain by the sword, and bear their shame with those who go down to the pit.",
                "Pharaoh shall see them, and shall be comforted over all his multitude, even Pharaoh and all his army, slain by the sword, says the Lord Yahweh.",
                "For I have put his terror in the land of the living; and he shall be laid in the midst of the uncircumcised, with those who are slain by the sword, even Pharaoh and all his multitude, says the Lord Yahweh."
            ],
            33: [
                "The word of Yahweh came to me, saying,",
                "Son of man, speak to the children of your people, and tell them, When I bring the sword on a land, and the people of the land take a man from among them, and set him for their watchman;",
                "if, when he sees the sword come on the land, he blow the trumpet, and warn the people;",
                "then whoever hears the sound of the trumpet, and doesn\u2019t take warning, if the sword come, and take him away, his blood shall be on his own head.",
                "He heard the sound of the trumpet, and didn\u2019t take warning; his blood shall be on him; whereas if he had taken warning, he would have delivered his soul.",
                "But if the watchman sees the sword come, and doesn\u2019t blow the trumpet, and the people aren\u2019t warned, and the sword comes, and take any person from among them; he is taken away in his iniquity, but his blood will I require at the watchman\u2019s hand.",
                "So you, son of man, I have set you a watchman to the house of Israel; therefore hear the word at my mouth, and give them warning from me.",
                "When I tell the wicked, O wicked man, you shall surely die, and you don\u2019t speak to warn the wicked from his way; that wicked man shall die in his iniquity, but his blood will I require at your hand.",
                "Nevertheless, if you warn the wicked of his way to turn from it, and he doesn\u2019t turn from his way; he shall die in his iniquity, but you have delivered your soul.",
                "You, son of man, tell the house of Israel: Thus you speak, saying, Our transgressions and our sins are on us, and we pine away in them; how then can we live?",
                "Tell them, As I live, says the Lord Yahweh, I have no pleasure in the death of the wicked; but that the wicked turn from his way and live: turn, turn from your evil ways; for why will you die, house of Israel?",
                "You, son of man, tell the children of your people, The righteousness of the righteous shall not deliver him in the day of his disobedience; and as for the wickedness of the wicked, he shall not fall thereby in the day that he turns from his wickedness; neither shall he who is righteous be able to live thereby in the day that he sins.",
                "When I tell the righteous, that he shall surely live; if he trust to his righteousness, and commit iniquity, none of his righteous deeds shall be remembered; but in his iniquity that he has committed, therein shall he die.",
                "Again, when I say to the wicked, You shall surely die; if he turn from his sin, and do that which is lawful and right;",
                "if the wicked restore the pledge, give again that which he had taken by robbery, walk in the statutes of life, committing no iniquity; he shall surely live, he shall not die.",
                "None of his sins that he has committed shall be remembered against him: he has done that which is lawful and right; he shall surely live.",
                "Yet the children of your people say, The way of the Lord is not equal: but as for them, their way is not equal.",
                "When the righteous turns from his righteousness, and commits iniquity, he shall even die therein.",
                "When the wicked turns from his wickedness, and does that which is lawful and right, he shall live thereby.",
                "Yet you say, The way of the Lord is not equal. House of Israel, I will judge every one of you after his ways.",
                "It happened in the twelfth year of our captivity, in the tenth month, in the fifth day of the month, that one who had escaped out of Jerusalem came to me, saying, The city has been struck.",
                "Now the hand of Yahweh had been on me in the evening, before he who was escaped came; and he had opened my mouth, until he came to me in the morning; and my mouth was opened, and I was no more mute.",
                "The word of Yahweh came to me, saying,",
                "Son of man, they who inhabit those waste places in the land of Israel speak, saying, Abraham was one, and he inherited the land: but we are many; the land is given us for inheritance.",
                "Therefore tell them, Thus says the Lord Yahweh: You eat with the blood, and lift up your eyes to your idols, and shed blood: and shall you possess the land?",
                "You stand on your sword, you work abomination, and every one of you defiles his neighbor\u2019s wife: and shall you possess the land?",
                "You shall tell them, Thus says the Lord Yahweh: As I live, surely those who are in the waste places shall fall by the sword; and him who is in the open field will I give to the animals to be devoured; and those who are in the strongholds and in the caves shall die of the pestilence.",
                "I will make the land a desolation and an astonishment; and the pride of her power shall cease; and the mountains of Israel shall be desolate, so that none shall pass through.",
                "Then shall they know that I am Yahweh, when I have made the land a desolation and an astonishment, because of all their abominations which they have committed.",
                "As for you, son of man, the children of your people talk of you by the walls and in the doors of the houses, and speak one to another, everyone to his brother, saying, Please come and hear what is the word that comes forth from Yahweh.",
                "They come to you as the people come, and they sit before you as my people, and they hear your words, but don\u2019t do them; for with their mouth they show much love, but their heart goes after their gain.",
                "Behold, you are to them as a very lovely song of one who has a pleasant voice, and can play well on an instrument; for they hear your words, but they don\u2019t do them.",
                "When this comes to pass, (behold, it comes), then shall they know that a prophet has been among them."
            ],
            34: [
                "The word of Yahweh came to me, saying,",
                "Son of man, prophesy against the shepherds of Israel, prophesy, and tell them, even to the shepherds, Thus says the Lord Yahweh: Woe to the shepherds of Israel who feed themselves! Shouldn\u2019t the shepherds feed the sheep?",
                "You eat the fat, and you clothe yourself with the wool, you kill the fatlings; but you don\u2019t feed the sheep.",
                "You haven\u2019t strengthened the diseased, neither have you healed that which was sick, neither have you bound up that which was broken, neither have you brought back that which was driven away, neither have you sought that which was lost; but with force and with rigor you have ruled over them.",
                "They were scattered, because there was no shepherd; and they became food to all the animals of the field, and were scattered.",
                "My sheep wandered through all the mountains, and on every high hill: yes, my sheep were scattered on all the surface of the earth; and there was none who searched or sought.",
                "Therefore, you shepherds, hear the word of Yahweh:",
                "As I live, says the Lord Yahweh, surely because my sheep became a prey, and my sheep became food to all the animals of the field, because there was no shepherd, neither did my shepherds search for my sheep, but the shepherds fed themselves, and didn\u2019t feed my sheep;",
                "therefore, you shepherds, hear the word of Yahweh:",
                "Thus says the Lord Yahweh: Behold, I am against the shepherds; and I will require my sheep at their hand, and cause them to cease from feeding the sheep; neither shall the shepherds feed themselves any more; and I will deliver my sheep from their mouth, that they may not be food for them.",
                "For thus says the Lord Yahweh: Behold, I myself, even I, will search for my sheep, and will seek them out.",
                "As a shepherd seeks out his flock in the day that he is among his sheep that are scattered abroad, so will I seek out my sheep; and I will deliver them out of all places where they have been scattered in the cloudy and dark day.",
                "I will bring them out from the peoples, and gather them from the countries, and will bring them into their own land; and I will feed them on the mountains of Israel, by the watercourses, and in all the inhabited places of the country.",
                "I will feed them with good pasture; and on the mountains of the height of Israel shall their fold be: there shall they lie down in a good fold; and on fat pasture shall they feed on the mountains of Israel.",
                "I myself will be the shepherd of my sheep, and I will cause them to lie down, says the Lord Yahweh.",
                "I will seek that which was lost, and will bring back that which was driven away, and will bind up that which was broken, and will strengthen that which was sick: but the fat and the strong I will destroy; I will feed them in justice.",
                "As for you, O my flock, thus says the Lord Yahweh: Behold, I judge between sheep and sheep, the rams and the male goats.",
                "Does it seem a small thing to you to have fed on the good pasture, but you must tread down with your feet the residue of your pasture? and to have drunk of the clear waters, but you must foul the residue with your feet?",
                "As for my sheep, they eat that which you have trodden with your feet, and they drink that which you have fouled with your feet.",
                "Therefore thus says the Lord Yahweh to them: Behold, I, even I, will judge between the fat sheep and the lean sheep.",
                "Because you thrust with side and with shoulder, and push all the diseased with your horns, until you have scattered them abroad;",
                "therefore will I save my flock, and they shall no more be a prey; and I will judge between sheep and sheep.",
                "I will set up one shepherd over them, and he shall feed them, even my servant David; he shall feed them, and he shall be their shepherd.",
                "I, Yahweh, will be their God, and my servant David prince among them; I, Yahweh, have spoken it.",
                "I will make with them a covenant of peace, and will cause evil animals to cease out of the land; and they shall dwell securely in the wilderness, and sleep in the woods.",
                "I will make them and the places around my hill a blessing; and I will cause the shower to come down in its season; there shall be showers of blessing.",
                "The tree of the field shall yield its fruit, and the earth shall yield its increase, and they shall be secure in their land; and they shall know that I am Yahweh, when I have broken the bars of their yoke, and have delivered them out of the hand of those who made slaves of them.",
                "They shall no more be a prey to the nations, neither shall the animals of the earth devour them; but they shall dwell securely, and none shall make them afraid.",
                "I will raise up to them a plantation for renown, and they shall be no more consumed with famine in the land, neither bear the shame of the nations any more.",
                "They shall know that I, Yahweh, their God am with them, and that they, the house of Israel, are my people, says the Lord Yahweh.",
                "You my sheep, the sheep of my pasture, are men, and I am your God, says the Lord Yahweh."
            ],
            35: [
                "Moreover the word of Yahweh came to me, saying,",
                "Son of man, set your face against Mount Seir, and prophesy against it,",
                "and tell it, Thus says the Lord Yahweh: Behold, I am against you, Mount Seir, and I will stretch out my hand against you, and I will make you a desolation and an astonishment.",
                "I will lay your cities waste, and you shall be desolate; and you shall know that I am Yahweh.",
                "Because you have had a perpetual enmity, and have given over the children of Israel to the power of the sword in the time of their calamity, in the time of the iniquity of the end;",
                "therefore, as I live, says the Lord Yahweh, I will prepare you for blood, and blood shall pursue you: since you have not hated blood, therefore blood shall pursue you.",
                "Thus will I make Mount Seir an astonishment and a desolation; and I will cut off from it him who passes through and him who returns.",
                "I will fill its mountains with its slain: in your hills and in your valleys and in all your watercourses shall they fall who are slain with the sword.",
                "I will make you a perpetual desolation, and your cities shall not be inhabited; and you shall know that I am Yahweh.",
                "Because you have said, These two nations and these two countries shall be mine, and we will possess it; whereas Yahweh was there:",
                "therefore, as I live, says the Lord Yahweh, I will do according to your anger, and according to your envy which you have shown out of your hatred against them; and I will make myself known among them, when I shall judge you.",
                "You shall know that I, Yahweh, have heard all your insults which you have spoken against the mountains of Israel, saying, They are laid desolate, they have been given us to devour.",
                "You have magnified yourselves against me with your mouth, and have multiplied your words against me: I have heard it.",
                "Thus says the Lord Yahweh: When the whole earth rejoices, I will make you desolate.",
                "As you rejoiced over the inheritance of the house of Israel, because it was desolate, so will I do to you: you shall be desolate, Mount Seir, and all Edom, even all of it; and they shall know that I am Yahweh."
            ],
            36: [
                "You, son of man, prophesy to the mountains of Israel, and say, You mountains of Israel, hear the word of Yahweh.",
                "Thus says the Lord Yahweh: Because the enemy has said against you, Aha! and, The ancient high places are ours in possession;",
                "therefore prophesy, and say, Thus says the Lord Yahweh: Because, even because they have made you desolate, and swallowed you up on every side, that you might be a possession to the residue of the nations, and you are taken up in the lips of talkers, and the evil report of the people;",
                "therefore, you mountains of Israel, hear the word of the Lord Yahweh: Thus says the Lord Yahweh to the mountains and to the hills, to the watercourses and to the valleys, to the desolate wastes and to the cities that are forsaken, which are become a prey and derision to the residue of the nations that are all around;",
                "therefore thus says the Lord Yahweh: Surely in the fire of my jealousy have I spoken against the residue of the nations, and against all Edom, that have appointed my land to themselves for a possession with the joy of all their heart, with despite of soul, to cast it out for a prey.",
                "Therefore prophesy concerning the land of Israel, and tell the mountains and to the hills, to the watercourses and to the valleys, Thus says the Lord Yahweh: Behold, I have spoken in my jealousy and in my wrath, because you have borne the shame of the nations:",
                "therefore thus says the Lord Yahweh: I have sworn, saying, Surely the nations that are around you, they shall bear their shame.",
                "But you, mountains of Israel, you shall shoot forth your branches, and yield your fruit to my people Israel; for they are at hand to come.",
                "For, behold, I am for you, and I will turn into you, and you shall be tilled and sown;",
                "and I will multiply men on you, all the house of Israel, even all of it; and the cities shall be inhabited, and the waste places shall be built;",
                "and I will multiply on you man and animal; and they shall increase and be fruitful; and I will cause you to be inhabited after your former estate, and will do better to you than at your beginnings: and you shall know that I am Yahweh.",
                "Yes, I will cause men to walk on you, even my people Israel; and they shall possess you, and you shall be their inheritance, and you shall no more henceforth bereave them of children.",
                "Thus says the Lord Yahweh: Because they say to you, You are a devourer of men, and have been a bereaver of your nation;",
                "therefore you shall devour men no more, neither bereave your nation any more, says the Lord Yahweh;",
                "neither will I let you hear any more the shame of the nations, neither shall you bear the reproach of the peoples any more, neither shall you cause your nation to stumble any more, says the Lord Yahweh.",
                "Moreover the word of Yahweh came to me, saying,",
                "Son of man, when the house of Israel lived in their own land, they defiled it by their way and by their doings: their way before me was as the uncleanness of a woman in her impurity.",
                "Therefore I poured out my wrath on them for the blood which they had poured out on the land, and because they had defiled it with their idols;",
                "and I scattered them among the nations, and they were dispersed through the countries: according to their way and according to their doings I judged them.",
                "When they came to the nations, where they went, they profaned my holy name; in that men said of them, These are the people of Yahweh, and are gone forth out of his land.",
                "But I had respect for my holy name, which the house of Israel had profaned among the nations, where they went.",
                "Therefore tell the house of Israel, Thus says the Lord Yahweh: I don\u2019t do this for your sake, house of Israel, but for my holy name, which you have profaned among the nations, where you went.",
                "I will sanctify my great name, which has been profaned among the nations, which you have profaned in their midst; and the nations shall know that I am Yahweh, says the Lord Yahweh, when I shall be sanctified in you before their eyes.",
                "For I will take you from among the nations, and gather you out of all the countries, and will bring you into your own land.",
                "I will sprinkle clean water on you, and you shall be clean: from all your filthiness, and from all your idols, will I cleanse you.",
                "I will also give you a new heart, and I will put a new spirit within you; and I will take away the stony heart out of your flesh, and I will give you a heart of flesh.",
                "I will put my Spirit within you, and cause you to walk in my statutes, and you shall keep my ordinances, and do them.",
                "You shall dwell in the land that I gave to your fathers; and you shall be my people, and I will be your God.",
                "I will save you from all your uncleanness: and I will call for the grain, and will multiply it, and lay no famine on you.",
                "I will multiply the fruit of the tree, and the increase of the field, that you may receive no more the reproach of famine among the nations.",
                "Then you shall remember your evil ways, and your doings that were not good; and you shall loathe yourselves in your own sight for your iniquities and for your abominations.",
                "Nor for your sake do I this, says the Lord Yahweh, be it known to you: be ashamed and confounded for your ways, house of Israel.",
                "Thus says the Lord Yahweh: In the day that I cleanse you from all your iniquities, I will cause the cities to be inhabited, and the waste places shall be built.",
                "The land that was desolate shall be tilled, whereas it was a desolation in the sight of all who passed by.",
                "They shall say, This land that was desolate has become like the garden of Eden; and the waste and desolate and ruined cities are fortified and inhabited.",
                "Then the nations that are left around you shall know that I, Yahweh, have built the ruined places, and planted that which was desolate: I, Yahweh, have spoken it, and I will do it.",
                "Thus says the Lord Yahweh: For this, moreover, will I be inquired of by the house of Israel, to do it for them: I will increase them with men like a flock.",
                "As the flock for sacrifice, as the flock of Jerusalem in her appointed feasts, so shall the waste cities be filled with flocks of men; and they shall know that I am Yahweh."
            ],
            37: [
                "The hand of Yahweh was on me, and he brought me out in the Spirit of Yahweh, and set me down in the midst of the valley; and it was full of bones.",
                "He caused me to pass by them all around: and behold, there were very many in the open valley; and behold, they were very dry.",
                "He said to me, Son of man, can these bones live? I answered, Lord Yahweh, you know.",
                "Again he said to me, Prophesy over these bones, and tell them, you dry bones, hear the word of Yahweh.",
                "Thus says the Lord Yahweh to these bones: Behold, I will cause breath to enter into you, and you shall live.",
                "I will lay sinews on you, and will bring up flesh on you, and cover you with skin, and put breath in you, and you shall live; and you shall know that I am Yahweh.",
                "So I prophesied as I was commanded: and as I prophesied, there was a noise, and behold, an earthquake; and the bones came together, bone to its bone.",
                "I saw, and, behold, there were sinews on them, and flesh came up, and skin covered them above; but there was no breath in them.",
                "Then he said to me, Prophesy to the wind, prophesy, son of man, and tell the wind, Thus says the Lord Yahweh: Come from the four winds, breath, and breathe on these slain, that they may live.",
                "So I prophesied as he commanded me, and the breath came into them, and they lived, and stood up on their feet, an exceedingly great army.",
                "Then he said to me, Son of man, these bones are the whole house of Israel: behold, they say, Our bones are dried up, and our hope is lost; we are clean cut off.",
                "Therefore prophesy, and tell them, Thus says the Lord Yahweh: Behold, I will open your graves, and cause you to come up out of your graves, my people; and I will bring you into the land of Israel.",
                "You shall know that I am Yahweh, when I have opened your graves, and caused you to come up out of your graves, my people.",
                "I will put my Spirit in you, and you shall live, and I will place you in your own land: and you shall know that I, Yahweh, have spoken it and performed it, says Yahweh.",
                "The word of Yahweh came again to me, saying,",
                "You, son of man, take one stick, and write on it, For Judah, and for the children of Israel his companions: then take another stick, and write on it, For Joseph, the stick of Ephraim, and for all the house of Israel his companions:",
                "and join them for you one to another into one stick, that they may become one in your hand.",
                "When the children of your people shall speak to you, saying, Will you not show us what you mean by these?",
                "tell them, Thus says the Lord Yahweh: Behold, I will take the stick of Joseph, which is in the hand of Ephraim, and the tribes of Israel his companions; and I will put them with it, even with the stick of Judah, and make them one stick, and they shall be one in my hand.",
                "The sticks whereon you write shall be in your hand before their eyes.",
                "Say to them, Thus says the Lord Yahweh: Behold, I will take the children of Israel from among the nations, where they are gone, and will gather them on every side, and bring them into their own land:",
                "and I will make them one nation in the land, on the mountains of Israel; and one king shall be king to them all; and they shall be no more two nations, neither shall they be divided into two kingdoms any more at all;",
                "neither shall they defile themselves any more with their idols, nor with their detestable things, nor with any of their transgressions; but I will save them out of all their dwelling places, in which they have sinned, and will cleanse them: so shall they be my people, and I will be their God.",
                "My servant David shall be king over them; and they all shall have one shepherd: they shall also walk in my ordinances, and observe my statutes, and do them.",
                "They shall dwell in the land that I have given to Jacob my servant, in which your fathers lived; and they shall dwell therein, they, and their children, and their children\u2019s children, forever: and David my servant shall be their prince for ever.",
                "Moreover I will make a covenant of peace with them; it shall be an everlasting covenant with them; and I will place them, and multiply them, and will set my sanctuary in their midst forevermore.",
                "My tent also shall be with them; and I will be their God, and they shall be my people.",
                "The nations shall know that I am Yahweh who sanctifies Israel, when my sanctuary shall be in their midst forevermore."
            ],
            38: [
                "The word of Yahweh came to me, saying,",
                "Son of man, set your face toward Gog, of the land of Magog, the prince of Rosh, Meshech, and Tubal, and prophesy against him,",
                "and say, Thus says the Lord Yahweh: Behold, I am against you, Gog, prince of Rosh, Meshech, and Tubal:",
                "and I will turn you around, and put hooks into your jaws, and I will bring you forth, with all your army, horses and horsemen, all of them clothed in full armor, a great company with buckler and shield, all of them handling swords;",
                "Persia, Cush, and Put with them, all of them with shield and helmet;",
                "Gomer, and all his hordes; the house of Togarmah in the uttermost parts of the north, and all his hordes; even many peoples with you.",
                "Be prepared, yes, prepare yourself, you, and all your companies who are assembled to you, and be a guard to them.",
                "After many days you shall be visited: in the latter years you shall come into the land that is brought back from the sword, that is gathered out of many peoples, on the mountains of Israel, which have been a continual waste; but it is brought forth out of the peoples, and they shall dwell securely, all of them.",
                "You shall ascend, you shall come like a storm, you shall be like a cloud to cover the land, you, and all your hordes, and many peoples with you.",
                "Thus says the Lord Yahweh: It shall happen in that day, that things shall come into your mind, and you shall devise an evil device:",
                "and you shall say, I will go up to the land of unwalled villages; I will go to those who are at rest, who dwell securely, all of them dwelling without walls, and having neither bars nor gates;",
                "to take the spoil and to take the prey; to turn your hand against the waste places that are now inhabited, and against the people who are gathered out of the nations, who have gotten livestock and goods, who dwell in the middle of the earth.",
                "Sheba, and Dedan, and the merchants of Tarshish, with all the young lions of it, shall tell you, Have you come to take the spoil? have you assembled your company to take the prey? to carry away silver and gold, to take away livestock and goods, to take great spoil?",
                "Therefore, son of man, prophesy, and tell Gog, Thus says the Lord Yahweh: In that day when my people Israel dwells securely, shall you not know it?",
                "You shall come from your place out of the uttermost parts of the north, you, and many peoples with you, all of them riding on horses, a great company and a mighty army;",
                "and you shall come up against my people Israel, as a cloud to cover the land: it shall happen in the latter days, that I will bring you against my land, that the nations may know me, when I shall be sanctified in you, Gog, before their eyes.",
                "Thus says the Lord Yahweh: Are you he of whom I spoke in old time by my servants the prophets of Israel, who prophesied in those days for many years that I would bring you against them?",
                "It shall happen in that day, when Gog shall come against the land of Israel, says the Lord Yahweh, that my wrath shall come up into my nostrils.",
                "For in my jealousy and in the fire of my wrath have I spoken, Surely in that day there shall be a great shaking in the land of Israel;",
                "so that the fish of the sea, and the birds of the sky, and the animals of the field, and all creeping things who creep on the earth, and all the men who are on the surface of the earth, shall shake at my presence, and the mountains shall be thrown down, and the steep places shall fall, and every wall shall fall to the ground.",
                "I will call for a sword against him to all my mountains, says the Lord Yahweh: every man\u2019s sword shall be against his brother.",
                "With pestilence and with blood will I enter into judgment with him; and I will rain on him, and on his hordes, and on the many peoples who are with him, an overflowing shower, and great hailstones, fire, and sulfur.",
                "I will magnify myself, and sanctify myself, and I will make myself known in the eyes of many nations; and they shall know that I am Yahweh."
            ],
            39: [
                "You, son of man, prophesy against Gog, and say, Thus says the Lord Yahweh: Behold, I am against you, Gog, prince of Rosh, Meshech, and Tubal:",
                "and I will turn you around, and will lead you on, and will cause you to come up from the uttermost parts of the north; and I will bring you on the mountains of Israel;",
                "and I will strike your bow out of your left hand, and will cause your arrows to fall out of your right hand.",
                "You shall fall on the mountains of Israel, you, and all your hordes, and the peoples who are with you: I will give you to the ravenous birds of every sort, and to the animals of the field to be devoured.",
                "You shall fall on the open field; for I have spoken it, says the Lord Yahweh.",
                "I will send a fire on Magog, and on those who dwell securely in the islands; and they shall know that I am Yahweh.",
                "My holy name will I make known in the midst of my people Israel; neither will I allow my holy name to be profaned any more: and the nations shall know that I am Yahweh, the Holy One in Israel.",
                "Behold, it comes, and it shall be done, says the Lord Yahweh; this is the day about which I have spoken.",
                "Those who dwell in the cities of Israel shall go forth, and shall make fires of the weapons and burn them, both the shields and the bucklers, the bows and the arrows, and the war clubs, and the spears, and they shall make fires of them seven years;",
                "so that they shall take no wood out of the field, neither cut down any out of the forests; for they shall make fires of the weapons; and they shall plunder those who plundered them, and rob those who robbed them, says the Lord Yahweh.",
                "It shall happen in that day, that I will give to Gog a place for burial in Israel, the valley of those who pass through on the east of the sea; and it shall stop those who pass through: and there shall they bury Gog and all his multitude; and they shall call it The valley of Hamon Gog.",
                "Seven months shall the house of Israel be burying them, that they may cleanse the land.",
                "Yes, all the people of the land shall bury them; and it shall be to them a renown in the day that I shall be glorified, says the Lord Yahweh.",
                "They shall set apart men of continual employment, who shall pass through the land, and, with those who pass through, those who bury those who remain on the surface of the land, to cleanse it: after the end of seven months shall they search.",
                "Those who pass through the land shall pass through; and when any sees a man\u2019s bone, then shall he set up a sign by it, until the undertakers have buried it in the valley of Hamon Gog.",
                "Hamonah shall also be the name of a city. Thus shall they cleanse the land.",
                "You, son of man, thus says the Lord Yahweh: Speak to the birds of every sort, and to every animal of the field, Assemble yourselves, and come; gather yourselves on every side to my sacrifice that I do sacrifice for you, even a great sacrifice on the mountains of Israel, that you may eat flesh and drink blood.",
                "You shall eat the flesh of the mighty, and drink the blood of the princes of the earth, of rams, of lambs, and of goats, of bulls, all of them fatlings of Bashan.",
                "You shall eat fat until you be full, and drink blood until you are drunk, of my sacrifice which I have sacrificed for you.",
                "You shall be filled at my table with horses and chariots, with mighty men, and with all men of war, says the Lord Yahweh.",
                "I will set my glory among the nations; and all the nations shall see my judgment that I have executed, and my hand that I have laid on them.",
                "So the house of Israel shall know that I am Yahweh their God, from that day and forward.",
                "The nations shall know that the house of Israel went into captivity for their iniquity; because they trespassed against me, and I hid my face from them: so I gave them into the hand of their adversaries, and they fell all of them by the sword.",
                "According to their uncleanness and according to their transgressions did I to them; and I hid my face from them.",
                "Therefore thus says the Lord Yahweh: Now will I bring back the captivity of Jacob, and have mercy on the whole house of Israel; and I will be jealous for my holy name.",
                "They shall bear their shame, and all their trespasses by which they have trespassed against me, when they shall dwell securely in their land, and none shall make them afraid;",
                "when I have brought them back from the peoples, and gathered them out of their enemies\u2019 lands, and am sanctified in them in the sight of many nations.",
                "They shall know that I am Yahweh their God, in that I caused them to go into captivity among the nations, and have gathered them to their own land; and I will leave none of them any more there;",
                "neither will I hide my face any more from them; for I have poured out my Spirit on the house of Israel, says the Lord Yahweh."
            ],
            40: [
                "In the five and twentieth year of our captivity, in the beginning of the year, in the tenth day of the month, in the fourteenth year after that the city was struck, in the same day, the hand of Yahweh was on me, and he brought me there.",
                "In the visions of God brought he me into the land of Israel, and set me down on a very high mountain, whereon was as it were the frame of a city on the south.",
                "He brought me there; and, behold, there was a man, whose appearance was like the appearance of brass, with a line of flax in his hand, and a measuring reed; and he stood in the gate.",
                "The man said to me, Son of man, see with your eyes, and hear with your ears, and set your heart on all that I shall show you; for, to the intent that I may show them to you, you are brought here: declare all that you see to the house of Israel.",
                "Behold, a wall on the outside of the house all around, and in the man\u2019s hand a measuring reed six cubits long, of a cubit and a handbreadth each: so he measured the thickness of the building, one reed; and the height, one reed.",
                "Then came he to the gate which looks toward the east, and went up its steps: and he measured the threshold of the gate, one reed broad; and the other threshold, one reed broad.",
                "Every lodge was one reed long, and one reed broad; and the space between the lodges was five cubits; and the threshold of the gate by the porch of the gate toward the house was one reed.",
                "He measured also the porch of the gate toward the house, one reed.",
                "Then measured he the porch of the gate, eight cubits; and its posts, two cubits; and the porch of the gate was toward the house.",
                "The lodges of the gate eastward were three on this side, and three on that side; they three were of one measure: and the posts had one measure on this side and on that side.",
                "He measured the breadth of the opening of the gate, ten cubits; and the length of the gate, thirteen cubits;",
                "and a border before the lodges, one cubit on this side, and a border, one cubit on that side; and the lodges, six cubits on this side, and six cubits on that side.",
                "He measured the gate from the roof of the one lodge to the roof of the other, a breadth of twenty-five cubits; door against door.",
                "He made also posts, sixty cubits; and the court reached to the posts, around the gate.",
                "From the forefront of the gate at the entrance to the forefront of the inner porch of the gate were fifty cubits.",
                "There were closed windows to the lodges, and to their posts within the gate all around, and likewise to the arches; and windows were around inward; and on each post were palm trees.",
                "Then brought he me into the outer court; and behold, there were rooms and a pavement, made for the court all around: thirty rooms were on the pavement.",
                "The pavement was by the side of the gates, answerable to the length of the gates, even the lower pavement.",
                "Then he measured the breadth from the forefront of the lower gate to the forefront of the inner court outside, one hundred cubits, both on the east and on the north.",
                "The gate of the outer court whose prospect is toward the north, he measured its length and its breadth.",
                "The lodges of it were three on this side and three on that side; and its posts and its arches were after the measure of the first gate: its length was fifty cubits, and the breadth twenty-five cubits.",
                "The windows of it, and its arches, and the palm trees of it, were after the measure of the gate whose prospect is toward the east; and they went up to it by seven steps; and its arches were before them.",
                "There was a gate to the inner court over against the other gate, both on the north and on the east; and he measured from gate to gate one hundred cubits.",
                "He led me toward the south; and behold, a gate toward the south: and he measured its posts and its arches according to these measures.",
                "There were windows in it and in its arches all around, like those windows: the length was fifty cubits, and the breadth twenty-five cubits.",
                "There were seven steps to go up to it, and its arches were before them; and it had palm trees, one on this side, and another on that side, on its posts.",
                "There was a gate to the inner court toward the south: and he measured from gate to gate toward the south a hundred cubits.",
                "Then he brought me to the inner court by the south gate: and he measured the south gate according to these measures;",
                "and its lodges, and its posts, and its arches, according to these measures: and there were windows in it and in its arches all around; it was fifty cubits long, and twenty-five cubits broad.",
                "There were arches all around, twenty-five cubits long, and five cubits broad.",
                "The arches of it were toward the outer court; and palm trees were on its posts: and the ascent to it had eight steps.",
                "He brought me into the inner court toward the east: and he measured the gate according to these measures;",
                "and its lodges, and its posts, and its arches, according to these measures: and there were windows therein and in its arches all around; it was fifty cubits long, and twenty-five cubits broad.",
                "The arches of it were toward the outer court; and palm trees were on its posts, on this side, and on that side: and the ascent to it had eight steps.",
                "He brought me to the north gate: and he measured it according to these measures;",
                "its lodges, its posts, and its arches: and there were windows therein all around; the length was fifty cubits, and the breadth twenty-five cubits.",
                "The posts of it were toward the outer court; and palm trees were on its posts, on this side, and on that side: and the ascent to it had eight steps.",
                "A room with its door was by the posts at the gates; there they washed the burnt offering.",
                "In the porch of the gate were two tables on this side, and two tables on that side, to kill thereon the burnt offering and the sin offering and the trespass offering.",
                "On the one side outside, as one goes up to the entry of the gate toward the north, were two tables; and on the other side, which belonged to the porch of the gate, were two tables.",
                "Four tables were on this side, and four tables on that side, by the side of the gate; eight tables, whereupon they killed the sacrifices.",
                "There were four tables for the burnt offering, of cut stone, a cubit and a half long, and a cubit and a half broad, and one cubit high; whereupon they laid the instruments with which they killed the burnt offering and the sacrifice.",
                "The hooks, a handbreadth long, were fastened within all around: and on the tables was the flesh of the offering.",
                "Outside of the inner gate were rooms for the singers in the inner court, which was at the side of the north gate; and their prospect was toward the south; one at the side of the east gate having the prospect toward the north.",
                "He said to me, This room, whose prospect is toward the south, is for the priests, the keepers of the duty of the house;",
                "and the room whose prospect is toward the north is for the priests, the keepers of the duty of the altar: these are the sons of Zadok, who from among the sons of Levi come near to Yahweh to minister to him.",
                "He measured the court, one hundred cubits long, and a hundred cubits broad, foursquare; and the altar was before the house.",
                "Then he brought me to the porch of the house, and measured each post of the porch, five cubits on this side, and five cubits on that side: and the breadth of the gate was three cubits on this side, and three cubits on that side.",
                "The length of the porch was twenty cubits, and the breadth eleven cubits; even by the steps by which they went up to it: and there were pillars by the posts, one on this side, and another on that side."
            ],
            41: [
                "He brought me to the temple, and measured the posts, six cubits broad on the one side, and six cubits broad on the other side, which was the breadth of the tent.",
                "The breadth of the entrance was ten cubits; and the sides of the entrance were five cubits on the one side, and five cubits on the other side: and he measured its length, forty cubits, and the breadth, twenty cubits.",
                "Then went he inward, and measured each post of the entrance, two cubits; and the entrance, six cubits; and the breadth of the entrance, seven cubits.",
                "He measured its length, twenty cubits, and the breadth, twenty cubits, before the temple: and he said to me, This is the most holy place.",
                "Then he measured the wall of the house, six cubits; and the breadth of every side room, four cubits, all around the house on every side.",
                "The side rooms were in three stories, one over another, and thirty in order; and they entered into the wall which belonged to the house for the side rooms all around, that they might have hold therein, and not have hold in the wall of the house.",
                "The side rooms were broader as they encompassed the house higher and higher; for the encompassing of the house went higher and higher around the house: therefore the breadth of the house continued upward; and so one went up from the lowest room to the highest by the middle room.",
                "I saw also that the house had a raised base all around: the foundations of the side rooms were a full reed of six great cubits.",
                "The thickness of the wall, which was for the side rooms, on the outside, was five cubits: and that which was left was the place of the side rooms that belonged to the house.",
                "Between the rooms was a breadth of twenty cubits around the house on every side.",
                "The doors of the side rooms were toward the place that was left, one door toward the north, and another door toward the south: and the breadth of the place that was left was five cubits all around.",
                "The building that was before the separate place at the side toward the west was seventy cubits broad; and the wall of the building was five cubits thick all around, and its length ninety cubits.",
                "So he measured the house, one hundred cubits long; and the separate place, and the building, with its walls, one hundred cubits long;",
                "also the breadth of the face of the house, and of the separate place toward the east, one hundred cubits.",
                "He measured the length of the building before the separate place which was at its back, and its galleries on the one side and on the other side, one hundred cubits; and the inner temple, and the porches of the court;",
                "the thresholds, and the closed windows, and the galleries around on their three stories, over against the threshold, with wood ceilings all around, and from the ground up to the windows, (now the windows were covered),",
                "to the space above the door, even to the inner house, and outside, and by all the wall all around inside and outside, by measure.",
                "It was made with cherubim and palm trees; and a palm tree was between cherub and cherub, and every cherub had two faces;",
                "so that there was the face of a man toward the palm tree on the one side, and the face of a young lion toward the palm tree on the other side. thus was it made through all the house all around:",
                "from the ground to above the door were cherubim and palm trees made: thus was the wall of the temple.",
                "As for the temple, the door posts were squared; and as for the face of the sanctuary, the appearance of it was as the appearance of the temple.",
                "The altar was of wood, three cubits high, and its length two cubits; and its corners, and its length, and its walls, were of wood: and he said to me, This is the table that is before Yahweh.",
                "The temple and the sanctuary had two doors.",
                "The doors had two leaves apiece, two turning leaves: two leaves for the one door, and two leaves for the other.",
                "There were made on them, on the doors of the temple, cherubim and palm trees, like as were made on the walls; and there was a threshold of wood on the face of the porch outside.",
                "There were closed windows and palm trees on the one side and on the other side, on the sides of the porch: thus were the side rooms of the house, and the thresholds."
            ],
            42: [
                "Then he brought me forth into the outer court, the way toward the north: and he brought me into the room that was over against the separate place, and which was over against the building toward the north.",
                "Before the length of one hundred cubits was the north door, and the breadth was fifty cubits.",
                "Over against the twenty cubits which belonged to the inner court, and over against the pavement which belonged to the outer court, was gallery against gallery in the third story.",
                "Before the rooms was a walk of ten cubits\u2019 breadth inward, a way of one cubit; and their doors were toward the north.",
                "Now the upper rooms were shorter; for the galleries took away from these, more than from the lower and the middle, in the building.",
                "For they were in three stories, and they didn\u2019t have pillars as the pillars of the courts: therefore the uppermost was straitened more than the lowest and the middle from the ground.",
                "The wall that was outside by the side of the rooms, toward the outer court before the rooms, its length was fifty cubits.",
                "For the length of the rooms that were in the outer court was fifty cubits: and behold, before the temple were one hundred cubits.",
                "From under these rooms was the entry on the east side, as one goes into them from the outer court.",
                "In the thickness of the wall of the court toward the east, before the separate place, and before the building, there were rooms.",
                "The way before them was like the appearance of the way of the rooms which were toward the north; according to their length so was their breadth: and all their exits were both according to their fashions, and according to their doors.",
                "According to the doors of the rooms that were toward the south was a door at the head of the way, even the way directly before the wall toward the east, as one enters into them.",
                "Then he said to me, The north rooms and the south rooms, which are before the separate place, they are the holy rooms, where the priests who are near to Yahweh shall eat the most holy things: there shall they lay the most holy things, and the meal offering, and the sin offering, and the trespass offering; for the place is holy.",
                "When the priests enter in, then shall they not go out of the holy place into the outer court, but there they shall lay their garments in which they minister; for they are holy: and they shall put on other garments, and shall approach to that which pertains to the people.",
                "Now when he had made an end of measuring the inner house, he brought me forth by the way of the gate whose prospect is toward the east, and measured it all around.",
                "He measured on the east side with the measuring reed five hundred reeds, with the measuring reed all around.",
                "He measured on the north side five hundred reeds with the measuring reed all around.",
                "He measured on the south side five hundred reeds with the measuring reed.",
                "He turned about to the west side, and measured five hundred reeds with the measuring reed.",
                "He measured it on the four sides: it had a wall around it, the length five hundred, and the breadth five hundred, to make a separation between that which was holy and that which was common."
            ],
            43: [
                "Afterward he brought me to the gate, even the gate that looks toward the east.",
                "Behold, the glory of the God of Israel came from the way of the east: and his voice was like the sound of many waters; and the earth shined with his glory.",
                "It was according to the appearance of the vision which I saw, even according to the vision that I saw when I came to destroy the city; and the visions were like the vision that I saw by the river Chebar; and I fell on my face.",
                "The glory of Yahweh came into the house by the way of the gate whose prospect is toward the east.",
                "The Spirit took me up, and brought me into the inner court; and behold, the glory of Yahweh filled the house.",
                "I heard one speaking to me out of the house; and a man stood by me.",
                "He said to me, Son of man, this is the place of my throne, and the place of the soles of my feet, where I will dwell in the midst of the children of Israel forever. The house of Israel shall no more defile my holy name, neither they, nor their kings, by their prostitution, and by the dead bodies of their kings in their high places;",
                "in their setting of their threshold by my threshold, and their doorpost beside my doorpost, and there was but the wall between me and them; and they have defiled my holy name by their abominations which they have committed: therefore I have consumed them in my anger.",
                "Now let them put away their prostitution, and the dead bodies of their kings, far from me; and I will dwell in their midst forever.",
                "You, son of man, show the house to the house of Israel, that they may be ashamed of their iniquities; and let them measure the pattern.",
                "If they be ashamed of all that they have done, make known to them the form of the house, and its fashion, and its exits, and its entrances, and all its forms, and all its ordinances, and all its forms, and all its laws; and write it in their sight; that they may keep the whole form of it, and all its ordinances, and do them.",
                "This is the law of the house: on the top of the mountain the whole limit around it shall be most holy. Behold, this is the law of the house.",
                "These are the measures of the altar by cubits (the cubit is a cubit and a handbreadth): the bottom shall be a cubit, and the breadth a cubit, and its border around its edge a span; and this shall be the base of the altar.",
                "From the bottom on the ground to the lower ledge shall be two cubits, and the breadth one cubit; and from the lesser ledge to the greater ledge shall be four cubits, and the breadth a cubit.",
                "The upper altar shall be four cubits; and from the altar hearth and upward there shall be four horns.",
                "The altar hearth shall be twelve cubits long by twelve broad, square in the four sides of it.",
                "The ledge shall be fourteen cubits long by fourteen broad in the four sides of it; and the border about it shall be half a cubit; and its bottom shall be a cubit around; and its steps shall look toward the east.",
                "He said to me, Son of man, thus says the Lord Yahweh: These are the ordinances of the altar in the day when they shall make it, to offer burnt offerings thereon, and to sprinkle blood thereon.",
                "You shall give to the priests the Levites who are of the seed of Zadok, who are near to me, to minister to me, says the Lord Yahweh, a young bull for a sin offering.",
                "You shall take of its blood, and put it on the four horns of it, and on the four corners of the ledge, and on the border all around: thus you shall cleanse it and make atonement for it.",
                "You shall also take the bull of the sin offering, and it shall be burnt in the appointed place of the house, outside of the sanctuary.",
                "On the second day you shall offer a male goat without blemish for a sin offering; and they shall cleanse the altar, as they cleansed it with the bull.",
                "When you have finished cleansing it, you shall offer a young bull without blemish, and a ram out of the flock without blemish.",
                "You shall bring them near to Yahweh, and the priests shall cast salt on them, and they shall offer them up for a burnt offering to Yahweh.",
                "Seven days you shall prepare every day a goat for a sin offering: they shall also prepare a young bull, and a ram out of the flock, without blemish.",
                "Seven days shall they make atonement for the altar and purify it; so shall they consecrate it.",
                "When they have accomplished the days, it shall be that on the eighth day, and forward, the priests shall make your burnt offerings on the altar, and your peace offerings; and I will accept you, says the Lord Yahweh."
            ],
            44: [
                "Then he brought me back by the way of the outer gate of the sanctuary, which looks toward the east; and it was shut.",
                "Yahweh said to me, This gate shall be shut; it shall not be opened, neither shall any man enter in by it; for Yahweh, the God of Israel, has entered in by it; therefore it shall be shut.",
                "As for the prince, he shall sit therein as prince to eat bread before Yahweh; he shall enter by the way of the porch of the gate, and shall go out by the way of the same.",
                "Then he brought me by the way of the north gate before the house; and I looked, and behold, the glory of Yahweh filled the house of Yahweh: and I fell on my face.",
                "Yahweh said to me, Son of man, mark well, and see with your eyes, and hear with your ears all that I tell you concerning all the ordinances of the house of Yahweh, and all its laws; and mark well the entrance of the house, with every exit of the sanctuary.",
                "You shall tell the rebellious, even to the house of Israel, Thus says the Lord Yahweh: you house of Israel, let it suffice you of all your abominations,",
                "in that you have brought in foreigners, uncircumcised in heart and uncircumcised in flesh, to be in my sanctuary, to profane it, even my house, when you offer my bread, the fat and the blood, and they have broken my covenant, to add to all your abominations.",
                "You have not performed the duty of my holy things; but you have set performers of my duty in my sanctuary for yourselves.",
                "Thus says the Lord Yahweh, No foreigner, uncircumcised in heart and uncircumcised in flesh, shall enter into my sanctuary, of any foreigners who are among the children of Israel.",
                "But the Levites who went far from me, when Israel went astray, who went astray from me after their idols, they shall bear their iniquity.",
                "Yet they shall be ministers in my sanctuary, having oversight at the gates of the house, and ministering in the house: they shall kill the burnt offering and the sacrifice for the people, and they shall stand before them to minister to them.",
                "Because they ministered to them before their idols, and became a stumbling block of iniquity to the house of Israel; therefore have I lifted up my hand against them, says the Lord Yahweh, and they shall bear their iniquity.",
                "They shall not come near to me, to execute the office of priest to me, nor to come near to any of my holy things, to the things that are most holy; but they shall bear their shame, and their abominations which they have committed.",
                "Yet will I make them performers of the duty of the house, for all its service, and for all that shall be done therein.",
                "But the priests the Levites, the sons of Zadok, who performed the duty of my sanctuary when the children of Israel went astray from me, they shall come near to me to minister to me; and they shall stand before me to offer to me the fat and the blood, says the Lord Yahweh:",
                "they shall enter into my sanctuary, and they shall come near to my table, to minister to me, and they shall keep my instruction.",
                "It shall be that, when they enter in at the gates of the inner court, they shall be clothed with linen garments; and no wool shall come on them, while they minister in the gates of the inner court, and within.",
                "They shall have linen tires on their heads, and shall have linen breeches on their waists; they shall not clothe themselves with anything that causes sweat.",
                "When they go forth into the outer court, even into the outer court to the people, they shall put off their garments in which they minister, and lay them in the holy rooms; and they shall put on other garments, that they not sanctify the people with their garments.",
                "Neither shall they shave their heads, nor allow their locks to grow long; they shall only cut off the hair of their heads.",
                "Neither shall any of the priests drink wine, when they enter into the inner court.",
                "Neither shall they take for their wives a widow, nor her who is put away; but they shall take virgins of the seed of the house of Israel, or a widow who is the widow of a priest.",
                "They shall teach my people the difference between the holy and the common, and cause them to discern between the unclean and the clean.",
                "In a controversy they shall stand to judge; according to my ordinances shall they judge it: and they shall keep my laws and my statutes in all my appointed feasts; and they shall make my Sabbaths holy.",
                "They shall go in to no dead person to defile themselves; but for father, or for mother, or for son, or for daughter, for brother, or for sister who has had no husband, they may defile themselves.",
                "After he is cleansed, they shall reckon to him seven days.",
                "In the day that he goes into the sanctuary, into the inner court, to minister in the sanctuary, he shall offer his sin offering, says the Lord Yahweh.",
                "They shall have an inheritance: I am their inheritance; and you shall give them no possession in Israel; I am their possession.",
                "They shall eat the meal offering, and the sin offering, and the trespass offering; and every devoted thing in Israel shall be theirs.",
                "The first of all the first fruits of every thing, and every offering of everything, of all your offerings, shall be for the priest: you shall also give to the priests the first of your dough, to cause a blessing to rest on your house.",
                "The priests shall not eat of anything that dies of itself, or is torn, whether it be bird or animal."
            ],
            45: [
                "Moreover, when you shall divide by lot the land for inheritance, you shall offer an offering to Yahweh, a holy portion of the land; the length shall be the length of twenty-five thousand reeds, and the breadth shall be ten thousand: it shall be holy in all its border all around.",
                "Of this there shall be for the holy place five hundred in length by five hundred in breadth, square all around; and fifty cubits for its suburbs all around.",
                "Of this measure you shall measure a length of twenty-five thousand, and a breadth of ten thousand: and in it shall be the sanctuary, which is most holy.",
                "It is a holy portion of the land; it shall be for the priests, the ministers of the sanctuary, who come near to minister to Yahweh; and it shall be a place for their houses, and a holy place for the sanctuary.",
                "Twenty-five thousand in length, and ten thousand in breadth, shall be to the Levites, the ministers of the house, for a possession to themselves, for twenty rooms.",
                "You shall appoint the possession of the city five thousand broad, and twenty-five thousand long, side by side with the offering of the holy portion: it shall be for the whole house of Israel.",
                "Whatever is for the prince shall be on the one side and on the other side of the holy offering and of the possession of the city, in front of the holy offering and in front of the possession of the city, on the west side westward, and on the east side eastward; and in length answerable to one of the portions, from the west border to the east border.",
                "In the land it shall be to him for a possession in Israel: and my princes shall no more oppress my people; but they shall give the land to the house of Israel according to their tribes.",
                "Thus says the Lord Yahweh: Let it suffice you, princes of Israel: remove violence and spoil, and execute justice and righteousness; dispossessing my people, says the Lord Yahweh.",
                "You shall have just balances, and a just ephah, and a just bath.",
                "The ephah and the bath shall be of one measure, that the bath may contain the tenth part of a homer, and the ephah the tenth part of a homer: its measure shall be after the homer.",
                "The shekel shall be twenty gerahs. Twenty shekels plus twenty-five shekels plus fifteen shekels shall be your mina.",
                "This is the offering that you shall offer: the sixth part of an ephah from a homer of wheat; and you shall give the sixth part of an ephah from a homer of barley;",
                "and the set portion of oil, of the bath of oil, the tenth part of a bath out of the cor, which is ten baths, even a homer; (for ten baths are a homer;)",
                "and one lamb of the flock, out of two hundred, from the well-watered pastures of Israel\u2014for a meal offering, and for a burnt offering, and for peace offerings, to make atonement for them, says the Lord Yahweh.",
                "All the people of the land shall give to this offering for the prince in Israel.",
                "It shall be the prince\u2019s part to give the burnt offerings, and the meal offerings, and the drink offerings, in the feasts, and on the new moons, and on the Sabbaths, in all the appointed feasts of the house of Israel: he shall prepare the sin offering, and the meal offering, and the burnt offering, and the peace offerings, to make atonement for the house of Israel.",
                "Thus says the Lord Yahweh: In the first month, in the first day of the month, you shall take a young bull without blemish; and you shall cleanse the sanctuary.",
                "The priest shall take of the blood of the sin offering, and put it on the door posts of the house, and on the four corners of the ledge of the altar, and on the posts of the gate of the inner court.",
                "So you shall do on the seventh day of the month for everyone who errs, and for him who is simple: so you shall make atonement for the house.",
                "In the first month, in the fourteenth day of the month, you shall have the Passover, a feast of seven days; unleavened bread shall be eaten.",
                "On that day shall the prince prepare for himself and for all the people of the land a bull for a sin offering.",
                "The seven days of the feast he shall prepare a burnt offering to Yahweh, seven bulls and seven rams without blemish daily the seven days; and a male goat daily for a sin offering.",
                "He shall prepare a meal offering, an ephah for a bull, and an ephah for a ram, and a hin of oil to an ephah.",
                "In the seventh month, in the fifteenth day of the month, in the feast, shall he do the like the seven days; according to the sin offering, according to the burnt offering, and according to the meal offering, and according to the oil."
            ],
            46: [
                "Thus says the Lord Yahweh: The gate of the inner court that looks toward the east shall be shut the six working days; but on the Sabbath day it shall be opened, and on the day of the new moon it shall be opened.",
                "The prince shall enter by the way of the porch of the gate outside, and shall stand by the post of the gate; and the priests shall prepare his burnt offering and his peace offerings, and he shall worship at the threshold of the gate: then he shall go forth; but the gate shall not be shut until the evening.",
                "The people of the land shall worship at the door of that gate before Yahweh on the Sabbaths and on the new moons.",
                "The burnt offering that the prince shall offer to Yahweh shall be on the Sabbath day six lambs without blemish and a ram without blemish;",
                "and the meal offering shall be an ephah for the ram, and the meal offering for the lambs as he is able to give, and a hin of oil to an ephah.",
                "On the day of the new moon it shall be a young bull without blemish, and six lambs, and a ram; they shall be without blemish:",
                "and he shall prepare a meal offering, an ephah for the bull, and an ephah for the ram, and for the lambs according as he is able, and a hin of oil to an ephah.",
                "When the prince shall enter, he shall go in by the way of the porch of the gate, and he shall go forth by its way.",
                "But when the people of the land shall come before Yahweh in the appointed feasts, he who enters by the way of the north gate to worship shall go forth by the way of the south gate; and he who enters by the way of the south gate shall go forth by the way of the north gate: he shall not return by the way of the gate by which he came in, but shall go forth straight before him.",
                "The prince, when they go in, shall go in with of them; and when they go out, he shall go out.",
                "In the feasts and in the solemnities the meal offering shall be an ephah for a bull, and an ephah for a ram, and for the lambs as he is able to give, and a hin of oil to an ephah.",
                "When the prince shall prepare a freewill offering, a burnt offering or peace offerings as a freewill offering to Yahweh, one shall open for him the gate that looks toward the east; and he shall prepare his burnt offering and his peace offerings, as he does on the Sabbath day: then he shall go forth; and after his going forth one shall shut the gate.",
                "You shall prepare a lamb a year old without blemish for a burnt offering to Yahweh daily: morning by morning you shall prepare it.",
                "You shall prepare a meal offering with it morning by morning, the sixth part of an ephah, and the third part of a hin of oil, to moisten the fine flour; a meal offering to Yahweh continually by a perpetual ordinance.",
                "Thus shall they prepare the lamb, and the meal offering, and the oil, morning by morning, for a continual burnt offering.",
                "Thus says the Lord Yahweh: If the prince give a gift to any of his sons, it is his inheritance, it shall belong to his sons; it is their possession by inheritance.",
                "But if he give of his inheritance a gift to one of his servants, it shall be his to the year of liberty; then it shall return to the prince; but as for his inheritance, it shall be for his sons.",
                "Moreover the prince shall not take of the people\u2019s inheritance, to thrust them out of their possession; he shall give inheritance to his sons out of his own possession, that my people not be scattered every man from his possession.",
                "Then he brought me through the entry, which was at the side of the gate, into the holy rooms for the priests, which looked toward the north: and behold, there was a place on the hinder part westward.",
                "He said to me, This is the place where the priests shall boil the trespass offering and the sin offering, and where they shall bake the meal offering; that they not bring them forth into the outer court, to sanctify the people.",
                "Then he brought me forth into the outer court, and caused me to pass by the four corners of the court; and behold, in every corner of the court there was a court.",
                "In the four corners of the court there were courts enclosed, forty cubits long and thirty broad: these four in the corners were of one measure.",
                "There was a wall around in them, around the four, and boiling places were made under the walls all around.",
                "Then he said to me, These are the boiling houses, where the ministers of the house shall boil the sacrifice of the people."
            ],
            47: [
                "He brought me back to the door of the house; and behold, waters issued out from under the threshold of the house eastward; (for the forefront of the house was toward the east;) and the waters came down from under, from the right side of the house, on the south of the altar.",
                "Then he brought me out by the way of the gate northward, and led me round by the way outside to the outer gate, by the way of the gate that looks toward the east; and behold, there ran out waters on the right side.",
                "When the man went forth eastward with the line in his hand, he measured one thousand cubits, and he caused me to pass through the waters, waters that were to the ankles.",
                "Again he measured one thousand, and caused me to pass through the waters, waters that were to the knees. Again he measured one thousand, and caused me to pass through the waters, waters that were to the waist.",
                "Afterward he measured one thousand; and it was a river that I could not pass through; for the waters were risen, waters to swim in, a river that could not be passed through.",
                "He said to me, Son of man, have you seen this? Then he brought me, and caused me to return to the bank of the river.",
                "Now when I had returned, behold, on the bank of the river were very many trees on the one side and on the other.",
                "Then he said to me, These waters issue forth toward the eastern region, and shall go down into the Arabah; and they shall go toward the sea; into the sea shall the waters go which were made to issue forth; and the waters shall be healed.",
                "It shall happen, that every living creature which swarms, in every place where the rivers come, shall live; and there shall be a very great multitude of fish; for these waters have come there, and the waters of the sea shall be healed, and everything shall live wherever the river comes.",
                "It shall happen, that fishermen shall stand by it: from En Gedi even to En Eglaim shall be a place for the spreading of nets; their fish shall be after their kinds, as the fish of the great sea, exceeding many.",
                "But the miry places of it, and its marshes, shall not be healed; they shall be given up to salt.",
                "By the river on its bank, on this side and on that side, shall grow every tree for food, whose leaf shall not wither, neither shall its fruit fail: it shall bring forth new fruit every month, because its waters issue out of the sanctuary; and its fruit shall be for food, and its leaf for healing.",
                "Thus says the Lord Yahweh: This shall be the border, by which you shall divide the land for inheritance according to the twelve tribes of Israel: Joseph shall have two portions.",
                "You shall inherit it, one as well as another; for I swore to give it to your fathers: and this land shall fall to you for inheritance.",
                "This shall be the border of the land: On the north side, from the great sea, by the way of Hethlon, to the entrance of Zedad;",
                "Hamath, Berothah, Sibraim, which is between the border of Damascus and the border of Hamath; Hazer Hatticon, which is by the border of Hauran.",
                "The border from the sea, shall be Hazar Enon at the border of Damascus; and on the north northward is the border of Hamath. This is the north side.",
                "The east side, between Hauran and Damascus and Gilead, and the land of Israel, shall be the Jordan; from the north border to the east sea you shall measure. This is the east side.",
                "The south side southward shall be from Tamar as far as the waters of Meriboth Kadesh, to the brook of Egypt, to the great sea. This is the south side southward.",
                "The west side shall be the great sea, from the south border as far as over against the entrance of Hamath. This is the west side.",
                "So you shall divide this land to you according to the tribes of Israel.",
                "It shall happen, that you shall divide it by lot for an inheritance to you and to the aliens who live among you, who shall father children among you; and they shall be to you as the native-born among the children of Israel; they shall have inheritance with you among the tribes of Israel.",
                "It shall happen, that in what tribe the stranger lives, there you shall give him his inheritance, says the Lord Yahweh."
            ],
            48: [
                "Now these are the names of the tribes: From the north end, beside the way of Hethlon to the entrance of Hamath, Hazar Enan at the border of Damascus, northward beside Hamath, (and they shall have their sides east and west), Dan, one portion.",
                "By the border of Dan, from the east side to the west side, Asher, one portion.",
                "By the border of Asher, from the east side even to the west side, Naphtali, one portion.",
                "By the border of Naphtali, from the east side to the west side, Manasseh, one portion.",
                "By the border of Manasseh, from the east side to the west side, Ephraim, one portion.",
                "By the border of Ephraim, from the east side even to the west side, Reuben, one portion.",
                "By the border of Reuben, from the east side to the west side, Judah, one portion.",
                "By the border of Judah, from the east side to the west side, shall be the offering which you shall offer, twenty-five thousand reeds in breadth, and in length as one of the portions, from the east side to the west side: and the sanctuary shall be in its midst.",
                "The offering that you shall offer to Yahweh shall be twenty-five thousand reeds in length, and ten thousand in breadth.",
                "For these, even for the priests, shall be the holy offering: toward the north twenty-five thousand in length, and toward the west ten thousand in breadth, and toward the east ten thousand in breadth, and toward the south twenty-five thousand in length: and the sanctuary of Yahweh shall be in its midst.",
                "It shall be for the priests who are sanctified of the sons of Zadok, who have kept my instruction, who didn\u2019t go astray when the children of Israel went astray, as the Levites went astray.",
                "It shall be to them an offering from the offering of the land, a thing most holy, by the border of the Levites.",
                "Answerable to the border of the priests, the Levites shall have twenty-five thousand in length, and ten thousand in breadth: all the length shall be twenty-five thousand, and the breadth ten thousand.",
                "They shall sell none of it, nor exchange it, nor shall the first fruits of the land be alienated; for it is holy to Yahweh.",
                "The five thousand that are left in the breadth, in front of the twenty-five thousand, shall be for common use, for the city, for dwelling and for suburbs; and the city shall be in its midst.",
                "These shall be its measures: the north side four thousand and five hundred, and the south side four thousand and five hundred, and on the east side four thousand and five hundred, and the west side four thousand and five hundred.",
                "The city shall have suburbs: toward the north two hundred fifty, and toward the south two hundred fifty, and toward the east two hundred fifty, and toward the west two hundred fifty.",
                "The remainder in the length, answerable to the holy offering, shall be ten thousand eastward, and ten thousand westward; and it shall be answerable to the holy offering; and its increase shall be for food to those who labor in the city.",
                "Those who labor in the city, out of all the tribes of Israel, shall cultivate it.",
                "All the offering shall be twenty-five thousand by twenty-five thousand: you shall offer the holy offering foursquare, with the possession of the city.",
                "The residue shall be for the prince, on the one side and on the other of the holy offering and of the possession of the city; in front of the twenty-five thousand of the offering toward the east border, and westward in front of the twenty-five thousand toward the west border, answerable to the portions, it shall be for the prince: and the holy offering and the sanctuary of the house shall be in its midst.",
                "Moreover from the possession of the Levites, and from the possession of the city, being in the midst of that which is the prince\u2019s, between the border of Judah and the border of Benjamin, it shall be for the prince.",
                "As for the rest of the tribes: from the east side to the west side, Benjamin, one portion.",
                "By the border of Benjamin, from the east side to the west side, Simeon, one portion.",
                "By the border of Simeon, from the east side to the west side, Issachar, one portion.",
                "By the border of Issachar, from the east side to the west side, Zebulun, one portion.",
                "By the border of Zebulun, from the east side to the west side, Gad, one portion.",
                "By the border of Gad, at the south side southward, the border shall be even from Tamar to the waters of Meribath Kadesh, to the brook of Egypt, to the great sea.",
                "This is the land which you shall divide by lot to the tribes of Israel for inheritance, and these are their several portions, says the Lord Yahweh.",
                "These are the exits of the city: On the north side four thousand and five hundred reeds by measure;",
                "and the gates of the city shall be after the names of the tribes of Israel, three gates northward: the gate of Reuben, one; the gate of Judah, one; the gate of Levi, one.",
                "At the east side four thousand and five hundred reeds, and three gates: even the gate of Joseph, one; the gate of Benjamin, one; the gate of Dan, one.",
                "At the south side four thousand and five hundred reeds by measure, and three gates: the gate of Simeon, one; the gate of Issachar, one; the gate of Zebulun, one.",
                "At the west side four thousand and five hundred reeds, with their three gates: the gate of Gad, one; the gate of Asher, one; the gate of Naphtali, one.",
                "It shall be eighteen thousand reeds around: and the name of the city from that day shall be, Yahweh is there."
            ]
        },
        27: {
            1: [
                "In the third year of the reign of Jehoiakim king of Judah came Nebuchadnezzar king of Babylon to Jerusalem, and besieged it.",
                "The Lord gave Jehoiakim king of Judah into his hand, with part of the vessels of the house of God; and he carried them into the land of Shinar to the house of his god: and he brought the vessels into the treasure house of his god.",
                "The king spoke to Ashpenaz the master of his eunuchs, that he should bring in certain of the children of Israel, even of the seed royal and of the nobles;",
                "youths in whom was no blemish, but well-favored, and skillful in all wisdom, and endowed with knowledge, and understanding science, and such as had ability to stand in the king\u2019s palace; and that he should teach them the learning and the language of the Chaldeans.",
                "The king appointed for them a daily portion of the king\u2019s dainties, and of the wine which he drank, and that they should be nourished three years; that at its end they should stand before the king.",
                "Now among these were, of the children of Judah, Daniel, Hananiah, Mishael, and Azariah.",
                "The prince of the eunuchs gave names to them: to Daniel he gave the name of Belteshazzar; and to Hananiah, of Shadrach; and to Mishael, of Meshach; and to Azariah, of Abednego.",
                "But Daniel purposed in his heart that he would not defile himself with the king\u2019s dainties, nor with the wine which he drank: therefore he requested of the prince of the eunuchs that he might not defile himself.",
                "Now God made Daniel to find kindness and compassion in the sight of the prince of the eunuchs.",
                "The prince of the eunuchs said to Daniel, I fear my lord the king, who has appointed your food and your drink: for why should he see your faces worse looking than the youths who are of your own age? so would you endanger my head with the king.",
                "Then Daniel said to the steward whom the prince of the eunuchs had appointed over Daniel, Hananiah, Mishael, and Azariah:",
                "Test your servants, I beg you, ten days; and let them give us vegetables to eat, and water to drink.",
                "Then let our faces be looked on before you, and the face of the youths who eat of the king\u2019s dainties; and as you see, deal with your servants.",
                "So he listened to them in this matter, and proved them ten days.",
                "At the end of ten days their faces appeared fairer, and they were fatter in flesh, than all the youths who ate of the king\u2019s dainties.",
                "So the steward took away their dainties, and the wine that they should drink, and gave them pulse.",
                "Now as for these four youths, God gave them knowledge and skill in all learning and wisdom: and Daniel had understanding in all visions and dreams.",
                "At the end of the days which the king had appointed for bringing them in, the prince of the eunuchs brought them in before Nebuchadnezzar.",
                "The king talked with them; and among them all was found none like Daniel, Hananiah, Mishael, and Azariah: therefore stood they before the king.",
                "In every matter of wisdom and understanding, concerning which the king inquired of them, he found them ten times better than all the magicians and enchanters who were in all his realm.",
                "Daniel continued even to the first year of king Cyrus."
            ],
            2: [
                "In the second year of the reign of Nebuchadnezzar, Nebuchadnezzar dreamed dreams; and his spirit was troubled, and his sleep went from him.",
                "Then the king commanded to call the magicians, and the enchanters, and the sorcerers, and the Chaldeans, to tell the king his dreams. So they came in and stood before the king.",
                "The king said to them, I have dreamed a dream, and my spirit is troubled to know the dream.",
                "Then spoke the Chaldeans to the king in the Syrian language, O king, live forever: tell your servants the dream, and we will show the interpretation.",
                "The king answered the Chaldeans, The thing is gone from me: if you don\u2019t make known to me the dream and its interpretation, you shall be cut in pieces, and your houses shall be made a dunghill.",
                "But if you show the dream and its interpretation, you shall receive of me gifts and rewards and great honor: therefore show me the dream and its interpretation.",
                "They answered the second time and said, Let the king tell his servants the dream, and we will show the interpretation.",
                "The king answered, I know of a certainty that you would gain time, because you see the thing is gone from me.",
                "But if you don\u2019t make known to me the dream, there is but one law for you; for you have prepared lying and corrupt words to speak before me, until the time be changed: therefore tell me the dream, and I shall know that you can show me its interpretation.",
                "The Chaldeans answered before the king, and said, There is not a man on the earth who can show the king\u2019s matter, because no king, lord, or ruler, has asked such a thing of any magician, or enchanter, or Chaldean.",
                "It is a rare thing that the king requires, and there is no other who can show it before the king, except the gods, whose dwelling is not with flesh.",
                "For this cause the king was angry and very furious, and commanded to destroy all the wise men of Babylon.",
                "So the decree went forth, and the wise men were to be slain; and they sought Daniel and his companions to be slain.",
                "Then Daniel returned answer with counsel and prudence to Arioch the captain of the king\u2019s guard, who was gone forth to kill the wise men of Babylon;",
                "he answered Arioch the king\u2019s captain, Why is the decree so urgent from the king? Then Arioch made the thing known to Daniel.",
                "Daniel went in, and desired of the king that he would appoint him a time, and he would show the king the interpretation.",
                "Then Daniel went to his house, and made the thing known to Hananiah, Mishael, and Azariah, his companions:",
                "that they would desire mercies of the God of heaven concerning this secret; that Daniel and his companions should not perish with the rest of the wise men of Babylon.",
                "Then was the secret revealed to Daniel in a vision of the night. Then Daniel blessed the God of heaven.",
                "Daniel answered, Blessed be the name of God forever and ever; for wisdom and might are his.",
                "He changes the times and the seasons; he removes kings, and sets up kings; he gives wisdom to the wise, and knowledge to those who have understanding;",
                "he reveals the deep and secret things; he knows what is in the darkness, and the light dwells with him.",
                "I thank you, and praise you, you God of my fathers, who have given me wisdom and might, and have now made known to me what we desired of you; for you have made known to us the king\u2019s matter.",
                "Therefore Daniel went in to Arioch, whom the king had appointed to destroy the wise men of Babylon; he went and said thus to him: Don\u2019t destroy the wise men of Babylon; bring me in before the king, and I will show to the king the interpretation.",
                "Then Arioch brought in Daniel before the king in haste, and said thus to him, I have found a man of the children of the captivity of Judah, who will make known to the king the interpretation.",
                "The king answered Daniel, whose name was Belteshazzar, Are you able to make known to me the dream which I have seen, and its interpretation?",
                "Daniel answered before the king, and said, The secret which the king has demanded can neither wise men, enchanters, magicians, nor soothsayers, show to the king;",
                "but there is a God in heaven who reveals secrets, and he has made known to the king Nebuchadnezzar what shall be in the latter days. Your dream, and the visions of your head on your bed, are these:",
                "as for you, O king, your thoughts came into your mind on your bed, what should happen hereafter; and he who reveals secrets has made known to you what shall happen.",
                "But as for me, this secret is not revealed to me for any wisdom that I have more than any living, but to the intent that the interpretation may be made known to the king, and that you may know the thoughts of your heart.",
                "You, O king, saw, and behold, a great image. This image, which was mighty, and whose brightness was excellent, stood before you; and its aspect was awesome.",
                "As for this image, its head was of fine gold, its breast and its arms of silver, its belly and its thighs of brass,",
                "its legs of iron, its feet part of iron, and part of clay.",
                "You saw until a stone was cut out without hands, which struck the image on its feet that were of iron and clay, and broke them in pieces.",
                "Then was the iron, the clay, the brass, the silver, and the gold, broken in pieces together, and became like the chaff of the summer threshing floors; and the wind carried them away, so that no place was found for them: and the stone that struck the image became a great mountain, and filled the whole earth.",
                "This is the dream; and we will tell its interpretation before the king.",
                "You, O king, are king of kings, to whom the God of heaven has given the kingdom, the power, and the strength, and the glory;",
                "and wherever the children of men dwell, the animals of the field and the birds of the sky has he given into your hand, and has made you to rule over them all: you are the head of gold.",
                "After you shall arise another kingdom inferior to you; and another third kingdom of brass, which shall bear rule over all the earth.",
                "The fourth kingdom shall be strong as iron, because iron breaks in pieces and subdues all things; and as iron that crushes all these, shall it break in pieces and crush.",
                "Whereas you saw the feet and toes, part of potters\u2019 clay, and part of iron, it shall be a divided kingdom; but there shall be in it of the strength of the iron, because you saw the iron mixed with miry clay.",
                "As the toes of the feet were part of iron, and part of clay, so the kingdom shall be partly strong, and partly broken.",
                "Whereas you saw the iron mixed with miry clay, they shall mingle themselves with the seed of men; but they shall not cling to one another, even as iron does not mingle with clay.",
                "In the days of those kings shall the God of heaven set up a kingdom which shall never be destroyed, nor shall its sovereignty be left to another people; but it shall break in pieces and consume all these kingdoms, and it shall stand forever.",
                "Because you saw that a stone was cut out of the mountain without hands, and that it broke in pieces the iron, the brass, the clay, the silver, and the gold; the great God has made known to the king what shall happen hereafter: and the dream is certain, and its interpretation sure.",
                "Then the king Nebuchadnezzar fell on his face, and worshiped Daniel, and commanded that they should offer an offering and sweet odors to him.",
                "The king answered to Daniel, and said, Of a truth your God is the God of gods, and the Lord of kings, and a revealer of secrets, since you have been able to reveal this secret.",
                "Then the king made Daniel great, and gave him many great gifts, and made him to rule over the whole province of Babylon, and to be chief governor over all the wise men of Babylon.",
                "Daniel requested of the king, and he appointed Shadrach, Meshach, and Abednego, over the affairs of the province of Babylon: but Daniel was in the gate of the king."
            ],
            3: [
                "Nebuchadnezzar the king made an image of gold, whose height was sixty cubits, and its breadth six cubits: he set it up in the plain of Dura, in the province of Babylon.",
                "Then Nebuchadnezzar the king sent to gather together the satraps, the deputies, and the governors, the judges, the treasurers, the counselors, the sheriffs, and all the rulers of the provinces, to come to the dedication of the image which Nebuchadnezzar the king had set up.",
                "Then the satraps, the deputies, and the governors, the judges, the treasurers, the counselors, the sheriffs, and all the rulers of the provinces, were gathered together to the dedication of the image that Nebuchadnezzar the king had set up; and they stood before the image that Nebuchadnezzar had set up.",
                "Then the herald cried aloud, To you it is commanded, peoples, nations, and languages,",
                "that whenever you hear the sound of the horn, flute, zither, lyre, harp, pipe, and all kinds of music, you fall down and worship the golden image that Nebuchadnezzar the king has set up;",
                "and whoever doesn\u2019t fall down and worship shall the same hour be cast into the midst of a burning fiery furnace.",
                "Therefore at that time, when all the peoples heard the sound of the horn, flute, zither, lyre, harp, pipe, and all kinds of music, all the peoples, the nations, and the languages, fell down and worshiped the golden image that Nebuchadnezzar the king had set up.",
                "Therefore at that time certain Chaldeans came near, and brought accusation against the Jews.",
                "They answered Nebuchadnezzar the king, O king, live for ever.",
                "You, O king, have made a decree, that every man that shall hear the sound of the horn, flute, zither, lyre, harp, pipe, and all kinds of music, shall fall down and worship the golden image;",
                "and whoever doesn\u2019t fall down and worship shall be cast into the midst of a burning fiery furnace.",
                "There are certain Jews whom you have appointed over the affairs of the province of Babylon: Shadrach, Meshach, and Abednego; these men, O king, have not respected you. They don\u2019t serve your gods, nor worship the golden image which you have set up.",
                "Then Nebuchadnezzar in his rage and fury commanded to bring Shadrach, Meshach, and Abednego. Then they brought these men before the king.",
                "Nebuchadnezzar answered them, Is it on purpose, Shadrach, Meshach, and Abednego, that you don\u2019t serve my god, nor worship the golden image which I have set up?",
                "Now if you are ready whenever you hear the sound of the horn, flute, zither, lyre, harp, pipe, and all kinds of music to fall down and worship the image which I have made, well: but if you don\u2019t worship, you shall be cast the same hour into the midst of a burning fiery furnace; and who is that god that shall deliver you out of my hands?",
                "Shadrach, Meshach, and Abednego answered the king, Nebuchadnezzar, we have no need to answer you in this matter.",
                "If it be so, our God whom we serve is able to deliver us from the burning fiery furnace; and he will deliver us out of your hand, O king.",
                "But if not, be it known to you, O king, that we will not serve your gods, nor worship the golden image which you have set up.",
                "Then was Nebuchadnezzar full of fury, and the form of his appearance was changed against Shadrach, Meshach, and Abednego: therefore he spoke, and commanded that they should heat the furnace seven times more than it was usually heated.",
                "He commanded certain mighty men who were in his army to bind Shadrach, Meshach, and Abednego, and to cast them into the burning fiery furnace.",
                "Then these men were bound in their pants, their tunics, and their mantles, and their other garments, and were cast into the midst of the burning fiery furnace.",
                "Therefore because the king\u2019s commandment was urgent, and the furnace exceeding hot, the flame of the fire killed those men who took up Shadrach, Meshach, and Abednego.",
                "These three men, Shadrach, Meshach, and Abednego, fell down bound into the midst of the burning fiery furnace.",
                "Then Nebuchadnezzar the king was astonished, and rose up in haste: he spoke and said to his counselors, Didn\u2019t we cast three men bound into the midst of the fire? They answered the king, True, O king.",
                "He answered, Look, I see four men loose, walking in the midst of the fire, and they are unharmed; and the aspect of the fourth is like a son of the gods.",
                "Then Nebuchadnezzar came near to the mouth of the burning fiery furnace: he spoke and said, Shadrach, Meshach, and Abednego, you servants of the Most High God, come forth, and come here. Then Shadrach, Meshach, and Abednego came forth out of the midst of the fire.",
                "The satraps, the deputies, and the governors, and the king\u2019s counselors, being gathered together, saw these men, that the fire had no power on their bodies, nor was the hair of their head singed, neither were their pants changed, nor had the smell of fire passed on them.",
                "Nebuchadnezzar spoke and said, Blessed be the God of Shadrach, Meshach, and Abednego, who has sent his angel, and delivered his servants who trusted in him, and have changed the king\u2019s word, and have yielded their bodies, that they might not serve nor worship any god, except their own God.",
                "Therefore I make a decree, that every people, nation, and language, which speak anything evil against the God of Shadrach, Meshach, and Abednego, shall be cut in pieces, and their houses shall be made a dunghill; because there is no other god who is able to deliver after this sort.",
                "Then the king promoted Shadrach, Meshach, and Abednego in the province of Babylon."
            ],
            4: [
                "Nebuchadnezzar the king, to all the peoples, nations, and languages, who dwell in all the earth: Peace be multiplied to you.",
                "It has seemed good to me to show the signs and wonders that the Most High God has worked toward me.",
                "How great are his signs! and how mighty are his wonders! his kingdom is an everlasting kingdom, and his dominion is from generation to generation.",
                "I, Nebuchadnezzar, was at rest in my house, and flourishing in my palace.",
                "I saw a dream which made me afraid; and the thoughts on my bed and the visions of my head troubled me.",
                "Therefore made I a decree to bring in all the wise men of Babylon before me, that they might make known to me the interpretation of the dream.",
                "Then came in the magicians, the enchanters, the Chaldeans, and the soothsayers; and I told the dream before them; but they did not make known to me its interpretation.",
                "But at the last Daniel came in before me, whose name was Belteshazzar, according to the name of my god, and in whom is the spirit of the holy gods: and I told the dream before him, saying,",
                "Belteshazzar, master of the magicians, because I know that the spirit of the holy gods is in you, and no secret troubles you, tell me the visions of my dream that I have seen, and its interpretation.",
                "Thus were the visions of my head on my bed: I saw, and behold, a tree in the midst of the earth; and its height was great.",
                "The tree grew, and was strong, and its height reached to the sky, and its sight to the end of all the earth.",
                "The leaves of it were beautiful, and its fruit much, and in it was food for all: the animals of the field had shadow under it, and the birds of the sky lived in its branches, and all flesh was fed from it.",
                "I saw in the visions of my head on my bed, and behold, a watcher and a holy one came down from the sky.",
                "He cried aloud, and said thus, Cut down the tree, and cut off its branches, shake off its leaves, and scatter its fruit: let the animals get away from under it, and the fowls from its branches.",
                "Nevertheless leave the stump of its roots in the earth, even with a band of iron and brass, in the tender grass of the field; and let it be wet with the dew of the sky: and let his portion be with the animals in the grass of the earth:",
                "let his heart be changed from man\u2019s, and let an animal\u2019s heart be given to him; and let seven times pass over him.",
                "The sentence is by the decree of the watchers, and the demand by the word of the holy ones; to the intent that the living may know that the Most High rules in the kingdom of men, and gives it to whomever he will, and sets up over it the lowest of men.",
                "This dream I, king Nebuchadnezzar, have seen; and you, Belteshazzar, declare the interpretation, because all the wise men of my kingdom are not able to make known to me the interpretation; but you are able; for the spirit of the holy gods is in you.",
                "Then Daniel, whose name was Belteshazzar, was stricken mute for a while, and his thoughts troubled him. The king answered, Belteshazzar, don\u2019t let the dream, or the interpretation, trouble you. Belteshazzar answered, My lord, the dream be to those who hate you, and its interpretation to your adversaries.",
                "The tree that you saw, which grew, and was strong, whose height reached to the sky, and its sight to all the earth;",
                "whose leaves were beautiful, and its fruit much, and in it was food for all; under which the animals of the field lived, and on whose branches the birds of the sky had their habitation:",
                "it is you, O king, that are grown and become strong; for your greatness is grown, and reaches to the sky, and your dominion to the end of the earth.",
                "Whereas the king saw a watcher and a holy one coming down from the sky, and saying, Cut down the tree, and destroy it; nevertheless leave the stump of its roots in the earth, even with a band of iron and brass, in the tender grass of the field, and let it be wet with the dew of the sky: and let his portion be with the animals of the field, until seven times pass over him;",
                "this is the interpretation, O king, and it is the decree of the Most High, which has come on my lord the king:",
                "that you shall be driven from men, and your dwelling shall be with the animals of the field, and you shall be made to eat grass as oxen, and shall be wet with the dew of the sky, and seven times shall pass over you; until you know that the Most High rules in the kingdom of men, and gives it to whomever he will.",
                "Whereas they commanded to leave the stump of the roots of the tree; your kingdom shall be sure to you, after that you shall have known that the heavens do rule.",
                "Therefore, O king, let my counsel be acceptable to you, and break off your sins by righteousness, and your iniquities by showing mercy to the poor; if there may be a lengthening of your tranquility.",
                "All this came on the king Nebuchadnezzar.",
                "At the end of twelve months he was walking in the royal palace of Babylon.",
                "The king spoke and said, Is not this great Babylon, which I have built for the royal dwelling place, by the might of my power and for the glory of my majesty?",
                "While the word was in the king\u2019s mouth, there fell a voice from the sky, saying, O king Nebuchadnezzar, to you it is spoken: The kingdom has departed from you:",
                "and you shall be driven from men; and your dwelling shall be with the animals of the field; you shall be made to eat grass as oxen; and seven times shall pass over you; until you know that the Most High rules in the kingdom of men, and gives it to whomever he will.",
                "The same hour was the thing fulfilled on Nebuchadnezzar: and he was driven from men, and ate grass as oxen, and his body was wet with the dew of the sky, until his hair was grown like eagles\u2019 feathers, and his nails like birds\u2019 claws.",
                "At the end of the days I, Nebuchadnezzar, lifted up my eyes to heaven, and my understanding returned to me, and I blessed the Most High, and I praised and honored him who lives forever; for his dominion is an everlasting dominion, and his kingdom from generation to generation.",
                "All the inhabitants of the earth are reputed as nothing; and he does according to his will in the army of heaven, and among the inhabitants of the earth; and none can stay his hand, or ask him, What are you doing?",
                "At the same time my understanding returned to me; and for the glory of my kingdom, my majesty and brightness returned to me; and my counselors and my lords sought to me; and I was established in my kingdom, and excellent greatness was added to me.",
                "Now I, Nebuchadnezzar, praise and extol and honor the King of heaven; for all his works are truth, and his ways justice; and those who walk in pride he is able to abase."
            ],
            5: [
                "Belshazzar the king made a great feast to a thousand of his lords, and drank wine before the thousand.",
                "Belshazzar, while he tasted the wine, commanded to bring the golden and silver vessels which Nebuchadnezzar his father had taken out of the temple which was in Jerusalem; that the king and his lords, his wives and his concubines, might drink from them.",
                "Then they brought the golden vessels that were taken out of the temple of the house of God which was at Jerusalem; and the king and his lords, his wives and his concubines, drank from them.",
                "They drank wine, and praised the gods of gold, and of silver, of brass, of iron, of wood, and of stone.",
                "In the same hour came forth the fingers of a man\u2019s hand, and wrote over against the lampstand on the plaster of the wall of the king\u2019s palace: and the king saw the part of the hand that wrote.",
                "Then the king\u2019s face was changed in him, and his thoughts troubled him; and the joints of his thighs were loosened, and his knees struck one against another.",
                "The king cried aloud to bring in the enchanters, the Chaldeans, and the soothsayers. The king spoke and said to the wise men of Babylon, Whoever shall read this writing, and show me its interpretation, shall be clothed with purple, and have a chain of gold about his neck, and shall be the third ruler in the kingdom.",
                "Then came in all the king\u2019s wise men; but they could not read the writing, nor make known to the king the interpretation.",
                "Then was king Belshazzar greatly troubled, and his face was changed in him, and his lords were perplexed.",
                "Now the queen by reason of the words of the king and his lords came into the banquet house: the queen spoke and said, O king, live forever; don\u2019t let your thoughts trouble you, nor let your face be changed.",
                "There is a man in your kingdom, in whom is the spirit of the holy gods; and in the days of your father light and understanding and wisdom, like the wisdom of the gods, were found in him; and the king Nebuchadnezzar your father, the king, I say, your father, made him master of the magicians, enchanters, Chaldeans, and soothsayers;",
                "because an excellent spirit, and knowledge, and understanding, interpreting of dreams, and showing of dark sentences, and dissolving of doubts, were found in the same Daniel, whom the king named Belteshazzar. Now let Daniel be called, and he will show the interpretation.",
                "Then was Daniel brought in before the king. The king spoke and said to Daniel, Are you that Daniel, who are of the children of the captivity of Judah, whom the king my father brought out of Judah?",
                "I have heard of you, that the spirit of the gods is in you, and that light and understanding and excellent wisdom are found in you.",
                "Now the wise men, the enchanters, have been brought in before me, that they should read this writing, and make known to me its interpretation; but they could not show the interpretation of the thing.",
                "But I have heard of you, that you can give interpretations, and dissolve doubts; now if you can read the writing, and make known to me its interpretation, you shall be clothed with purple, and have a chain of gold about your neck, and shall be the third ruler in the kingdom.",
                "Then Daniel answered before the king, Let your gifts be to yourself, and give your rewards to another; nevertheless I will read the writing to the king, and make known to him the interpretation.",
                "You, king, the Most High God gave Nebuchadnezzar your father the kingdom, and greatness, and glory, and majesty:",
                "and because of the greatness that he gave him, all the peoples, nations, and languages trembled and feared before him: whom he would he killed, and whom he would he kept alive; and whom he would he raised up, and whom he would he put down.",
                "But when his heart was lifted up, and his spirit was hardened so that he dealt proudly, he was deposed from his kingly throne, and they took his glory from him:",
                "and he was driven from the sons of men, and his heart was made like the animals\u2019, and his dwelling was with the wild donkeys; he was fed with grass like oxen, and his body was wet with the dew of the sky; until he knew that the Most High God rules in the kingdom of men, and that he sets up over it whomever he will.",
                "You, his son, Belshazzar, have not humbled your heart, though you knew all this,",
                "but have lifted up yourself against the Lord of heaven; and they have brought the vessels of his house before you, and you and your lords, your wives and your concubines, have drunk wine from them; and you have praised the gods of silver and gold, of brass, iron, wood, and stone, which don\u2019t see, nor hear, nor know; and the God in whose hand your breath is, and whose are all your ways, you have not glorified.",
                "Then was the part of the hand sent from before him, and this writing was inscribed.",
                "This is the writing that was inscribed: MENE, MENE, TEKEL, UPHARSIN.",
                "This is the interpretation of the thing: MENE; God has numbered your kingdom, and brought it to an end;",
                "TEKEL; you are weighed in the balances, and are found wanting.",
                "PERES; your kingdom is divided, and given to the Medes and Persians.",
                "Then commanded Belshazzar, and they clothed Daniel with purple, and put a chain of gold about his neck, and made proclamation concerning him, that he should be the third ruler in the kingdom.",
                "In that night Belshazzar the Chaldean King was slain.",
                "Darius the Mede received the kingdom, being about sixty-two years old."
            ],
            6: [
                "It pleased Darius to set over the kingdom one hundred twenty satraps, who should be throughout the whole kingdom;",
                "and over them three presidents, of whom Daniel was one; that these satraps might give account to them, and that the king should have no damage.",
                "Then this Daniel was distinguished above the presidents and the satraps, because an excellent spirit was in him; and the king thought to set him over the whole realm.",
                "Then the presidents and the satraps sought to find occasion against Daniel as touching the kingdom; but they could find no occasion nor fault, because he was faithful, neither was there any error or fault found in him.",
                "Then these men said, We shall not find any occasion against this Daniel, except we find it against him concerning the law of his God.",
                "Then these presidents and satraps assembled together to the king, and said thus to him, King Darius, live forever.",
                "All the presidents of the kingdom, the deputies and the satraps, the counselors and the governors, have consulted together to establish a royal statute, and to make a strong decree, that whoever shall ask a petition of any god or man for thirty days, except of you, O king, he shall be cast into the den of lions.",
                "Now, O king, establish the decree, and sign the writing, that it not be changed, according to the law of the Medes and Persians, which doesn\u2019t alter.",
                "Therefore king Darius signed the writing and the decree.",
                "When Daniel knew that the writing was signed, he went into his house (now his windows were open in his room toward Jerusalem) and he kneeled on his knees three times a day, and prayed, and gave thanks before his God, as he did before.",
                "Then these men assembled together, and found Daniel making petition and supplication before his God.",
                "Then they came near, and spoke before the king concerning the king\u2019s decree: Haven\u2019t you signed an decree, that every man who shall make petition to any god or man within thirty days, except to you, O king, shall be cast into the den of lions? The king answered, The thing is true, according to the law of the Medes and Persians, which doesn\u2019t alter.",
                "Then answered they and said before the king, That Daniel, who is of the children of the captivity of Judah, doesn\u2019t respect you, O king, nor the decree that you have signed, but makes his petition three times a day.",
                "Then the king, when he heard these words, was very displeased, and set his heart on Daniel to deliver him; and he labored until the going down of the sun to rescue him.",
                "Then these men assembled together to the king, and said to the king, Know, O king, that it is a law of the Medes and Persians, that no decree nor statute which the king establishes may be changed.",
                "Then the king commanded, and they brought Daniel, and cast him into the den of lions. Now the king spoke and said to Daniel, Your God whom you serve continually, he will deliver you.",
                "A stone was brought, and laid on the mouth of the den; and the king sealed it with his own signet, and with the signet of his lords; that nothing might be changed concerning Daniel.",
                "Then the king went to his palace, and passed the night fasting; neither were instruments of music brought before him: and his sleep fled from him.",
                "Then the king arose very early in the morning, and went in haste to the den of lions.",
                "When he came near to the den to Daniel, he cried with a lamentable voice; the king spoke and said to Daniel, Daniel, servant of the living God, is your God, whom you serve continually, able to deliver you from the lions?",
                "Then Daniel said to the king, O king, live forever.",
                "My God has sent his angel, and has shut the lions\u2019 mouths, and they have not hurt me; because as before him innocence was found in me; and also before you, O king, have I done no hurt.",
                "Then was the king exceeding glad, and commanded that they should take Daniel up out of the den. So Daniel was taken up out of the den, and no kind of harm was found on him, because he had trusted in his God.",
                "The king commanded, and they brought those men who had accused Daniel, and they cast them into the den of lions, them, their children, and their wives; and the lions mauled them, and broke all their bones in pieces, before they came to the bottom of the den.",
                "Then king Darius wrote to all the peoples, nations, and languages, who dwell in all the earth: Peace be multiplied to you.",
                "I make a decree, that in all the dominion of my kingdom men tremble and fear before the God of Daniel; for he is the living God, and steadfast forever, His kingdom that which shall not be destroyed; and his dominion shall be even to the end.",
                "He delivers and rescues, and he works signs and wonders in heaven and in earth, who has delivered Daniel from the power of the lions.",
                "So this Daniel prospered in the reign of Darius, and in the reign of Cyrus the Persian."
            ],
            7: [
                "In the first year of Belshazzar king of Babylon Daniel had a dream and visions of his head on his bed: then he wrote the dream and told the sum of the matters.",
                "Daniel spoke and said, I saw in my vision by night, and, behold, the four winds of the sky broke forth on the great sea.",
                "Four great animals came up from the sea, diverse one from another.",
                "The first was like a lion, and had eagle\u2019s wings: I saw until its wings were plucked, and it was lifted up from the earth, and made to stand on two feet as a man; and a man\u2019s heart was given to it.",
                "Behold, another animal, a second, like a bear; and it was raised up on one side, and three ribs were in its mouth between its teeth: and they said thus to it, Arise, devour much flesh.",
                "After this I saw, and behold, another, like a leopard, which had on its back four wings of a bird; the animal had also four heads; and dominion was given to it.",
                "After this I saw in the night visions, and, behold, a fourth animal, awesome and powerful, and strong exceedingly; and it had great iron teeth; it devoured and broke in pieces, and stamped the residue with its feet: and it was diverse from all the animals that were before it; and it had ten horns.",
                "I considered the horns, and behold, there came up among them another horn, a little one, before which three of the first horns were plucked up by the roots: and behold, in this horn were eyes like the eyes of a man, and a mouth speaking great things.",
                "I saw until thrones were placed, and one who was ancient of days sat: his clothing was white as snow, and the hair of his head like pure wool; his throne was fiery flames, and its wheels burning fire.",
                "A fiery stream issued and came forth from before him: thousands of thousands ministered to him, and ten thousand times ten thousand stood before him: the judgment was set, and the books were opened.",
                "I saw at that time because of the voice of the great words which the horn spoke; I saw even until the animal was slain, and its body destroyed, and it was given to be burned with fire.",
                "As for the rest of the animals, their dominion was taken away: yet their lives were prolonged for a season and a time.",
                "I saw in the night visions, and behold, there came with the clouds of the sky one like a son of man, and he came even to the ancient of days, and they brought him near before him.",
                "There was given him dominion, and glory, and a kingdom, that all the peoples, nations, and languages should serve him: his dominion is an everlasting dominion, which shall not pass away, and his kingdom that which shall not be destroyed.",
                "As for me, Daniel, my spirit was grieved in the midst of my body, and the visions of my head troubled me.",
                "I came near to one of those who stood by, and asked him the truth concerning all this. So he told me, and made me know the interpretation of the things.",
                "These great animals, which are four, are four kings, who shall arise out of the earth.",
                "But the saints of the Most High shall receive the kingdom, and possess the kingdom forever, even forever and ever.",
                "Then I desired to know the truth concerning the fourth animal, which was diverse from all of them, exceedingly terrible, whose teeth were of iron, and its nails of brass; which devoured, broke in pieces, and stamped the residue with its feet;",
                "and concerning the ten horns that were on its head, and the other horn which came up, and before which three fell, even that horn that had eyes, and a mouth that spoke great things, whose look was more stout than its fellows.",
                "I saw, and the same horn made war with the saints, and prevailed against them;",
                "until the ancient of days came, and judgment was given to the saints of the Most High, and the time came that the saints possessed the kingdom.",
                "Thus he said, The fourth animal shall be a fourth kingdom on earth, which shall be diverse from all the kingdoms, and shall devour the whole earth, and shall tread it down, and break it in pieces.",
                "As for the ten horns, out of this kingdom shall ten kings arise: and another shall arise after them; and he shall be diverse from the former, and he shall put down three kings.",
                "He shall speak words against the Most High, and shall wear out the saints of the Most High; and he shall think to change the times and the law; and they shall be given into his hand until a time and times and half a time.",
                "But the judgment shall be set, and they shall take away his dominion, to consume and to destroy it to the end.",
                "The kingdom and the dominion, and the greatness of the kingdoms under the whole sky, shall be given to the people of the saints of the Most High: his kingdom is an everlasting kingdom, and all dominions shall serve and obey him.",
                "Here is the end of the matter. As for me, Daniel, my thoughts much troubled me, and my face was changed in me: but I kept the matter in my heart."
            ],
            8: [
                "In the third year of the reign of king Belshazzar a vision appeared to me, even to me, Daniel, after that which appeared to me at the first.",
                "I saw in the vision; now it was so, that when I saw, I was in the citadel of Susa, which is in the province of Elam; and I saw in the vision, and I was by the river Ulai.",
                "Then I lifted up my eyes, and saw, and behold, there stood before the river a ram which had two horns: and the two horns were high; but one was higher than the other, and the higher came up last.",
                "I saw the ram pushing westward, and northward, and southward; and no animals could stand before him, neither was there any who could deliver out of his hand; but he did according to his will, and magnified himself.",
                "As I was considering, behold, a male goat came from the west over the surface of the whole earth, and didn\u2019t touch the ground: and the goat had a notable horn between his eyes.",
                "He came to the ram that had the two horns, which I saw standing before the river, and ran on him in the fury of his power.",
                "I saw him come close to the ram, and he was moved with anger against him, and struck the ram, and broke his two horns; and there was no power in the ram to stand before him; but he cast him down to the ground, and trampled on him; and there was none who could deliver the ram out of his hand.",
                "The male goat magnified himself exceedingly: and when he was strong, the great horn was broken; and instead of it there came up four notable horns toward the four winds of the sky.",
                "Out of one of them came forth a little horn, which grew exceeding great, toward the south, and toward the east, and toward the glorious land.",
                "It grew great, even to the army of the sky; and some of the army and of the stars it cast down to the ground, and trampled on them.",
                "Yes, it magnified itself, even to the prince of the army; and it took away from him the continual burnt offering, and the place of his sanctuary was cast down.",
                "The army was given over to it together with the continual burnt offering through disobedience; and it cast down truth to the ground, and it did its pleasure and prospered.",
                "Then I heard a holy one speaking; and another holy one said to that certain one who spoke, How long shall be the vision concerning the continual burnt offering, and the disobedience that makes desolate, to give both the sanctuary and the army to be trodden under foot?",
                "He said to me, To two thousand and three hundred evenings and mornings; then shall the sanctuary be cleansed.",
                "It happened, when I, even I Daniel, had seen the vision, that I sought to understand it; and behold, there stood before me as the appearance of a man.",
                "I heard a man\u2019s voice between the banks of the Ulai, which called, and said, Gabriel, make this man to understand the vision.",
                "So he came near where I stood; and when he came, I was frightened, and fell on my face: but he said to me, Understand, son of man; for the vision belongs to the time of the end.",
                "Now as he was speaking with me, I fell into a deep sleep with my face toward the ground; but he touched me, and set me upright.",
                "He said, Behold, I will make you know what shall be in the latter time of the indignation; for it belongs to the appointed time of the end.",
                "The ram which you saw, that had the two horns, they are the kings of Media and Persia.",
                "The rough male goat is the king of Greece: and the great horn that is between his eyes is the first king.",
                "As for that which was broken, in the place where four stood up, four kingdoms shall stand up out of the nation, but not with his power.",
                "In the latter time of their kingdom, when the transgressors have come to the full, a king of fierce face, and understanding dark sentences, shall stand up.",
                "His power shall be mighty, but not by his own power; and he shall destroy wonderfully, and shall prosper and do his pleasure; and he shall destroy the mighty ones and the holy people.",
                "Through his policy he shall cause craft to prosper in his hand; and he shall magnify himself in his heart, and in their security shall he destroy many: he shall also stand up against the prince of princes; but he shall be broken without hand.",
                "The vision of the evenings and mornings which has been told is true: but seal up the vision; for it belongs to many days to come.",
                "I, Daniel, fainted, and was sick certain days; then I rose up, and did the king\u2019s business: and I wondered at the vision, but none understood it."
            ],
            9: [
                "In the first year of Darius the son of Ahasuerus, of the seed of the Medes, who was made king over the realm of the Chaldeans,",
                "in the first year of his reign I, Daniel, understood by the books the number of the years about which the word of Yahweh came to Jeremiah the prophet, for the accomplishing of the desolations of Jerusalem, even seventy years.",
                "I set my face to the Lord God, to seek by prayer and petitions, with fasting and sackcloth and ashes.",
                "I prayed to Yahweh my God, and made confession, and said, Oh, Lord, the great and dreadful God, who keeps covenant and loving kindness with those who love him and keep his commandments,",
                "we have sinned, and have dealt perversely, and have done wickedly, and have rebelled, even turning aside from your precepts and from your ordinances;",
                "neither have we listened to your servants the prophets, who spoke in your name to our kings, our princes, and our fathers, and to all the people of the land.",
                "Lord, righteousness belongs to you, but to us confusion of face, as at this day; to the men of Judah, and to the inhabitants of Jerusalem, and to all Israel, who are near, and who are far off, through all the countries where you have driven them, because of their trespass that they have trespassed against you.",
                "Lord, to us belongs confusion of face, to our kings, to our princes, and to our fathers, because we have sinned against you.",
                "To the Lord our God belong mercies and forgiveness; for we have rebelled against him;",
                "neither have we obeyed the voice of Yahweh our God, to walk in his laws, which he set before us by his servants the prophets.",
                "Yes, all Israel have transgressed your law, turning aside, that they should not obey your voice: therefore the curse and the oath written in the law of Moses the servant of God has been poured out on us; for we have sinned against him.",
                "He has confirmed his words, which he spoke against us, and against our judges who judged us, by bringing on us a great evil; for under the whole sky, such has not been done as has been done to Jerusalem.",
                "As it is written in the law of Moses, all this evil has come on us: yet have we not entreated the favor of Yahweh our God, that we should turn from our iniquities, and have discernment in your truth.",
                "Therefore has Yahweh watched over the evil, and brought it on us; for Yahweh our God is righteous in all his works which he does, and we have not obeyed his voice.",
                "Now, Lord our God, who has brought your people forth out of the land of Egypt with a mighty hand, and have gotten yourself renown, as at this day; we have sinned, we have done wickedly.",
                "Lord, according to all your righteousness, let your anger and please let your wrath be turned away from your city Jerusalem, your holy mountain; because for our sins, and for the iniquities of our fathers, Jerusalem and your people have become a reproach to all who are around us.",
                "Now therefore, our God, listen to the prayer of your servant, and to his petitions, and cause your face to shine on your sanctuary that is desolate, for the Lord\u2019s sake.",
                "My God, turn your ear, and hear; open your eyes, and see our desolations, and the city which is called by your name: for we do not present our petitions before you for our righteousness, but for your great mercies\u2019 sake.",
                "Lord, hear; Lord, forgive; Lord, listen and do; don\u2019t defer, for your own sake, my God, because your city and your people are called by your name.",
                "While I was speaking, and praying, and confessing my sin and the sin of my people Israel, and presenting my supplication before Yahweh my God for the holy mountain of my God;",
                "yes, while I was speaking in prayer, the man Gabriel, whom I had seen in the vision at the beginning, being caused to fly swiftly, touched me about the time of the evening offering.",
                "He instructed me, and talked with me, and said, Daniel, I am now come forth to give you wisdom and understanding.",
                "At the beginning of your petitions the commandment went forth, and I have come to tell you; for you are greatly beloved: therefore consider the matter, and understand the vision.",
                "Seventy weeks are decreed on your people and on your holy city, to finish disobedience, and to make an end of sins, and to make reconciliation for iniquity, and to bring in everlasting righteousness, and to seal up vision and prophecy, and to anoint the most holy.",
                "Know therefore and discern, that from the going forth of the commandment to restore and to build Jerusalem to the Anointed One, the prince, shall be seven weeks, and sixty-two weeks: it shall be built again, with street and moat, even in troubled times.",
                "After the sixty-two weeks the Anointed One shall be cut off, and shall have nothing: and the people of the prince who shall come shall destroy the city and the sanctuary; and its end shall be with a flood, and even to the end shall be war; desolations are determined.",
                "He shall make a firm covenant with many for one week: and in the midst of the week he shall cause the sacrifice and the offering to cease; and on the wing of abominations shall come one who makes desolate; and even to the full end, and that determined, shall wrath be poured out on the desolate."
            ],
            10: [
                "In the third year of Cyrus king of Persia a thing was revealed to Daniel, whose name was called Belteshazzar; and the thing was true, even a great warfare: and he understood the thing, and had understanding of the vision.",
                "In those days I, Daniel, was mourning three whole weeks.",
                "I ate no pleasant bread, neither came flesh nor wine into my mouth, neither did I anoint myself at all, until three whole weeks were fulfilled.",
                "In the four and twentieth day of the first month, as I was by the side of the great river, which is Hiddekel,",
                "I lifted up my eyes, and looked, and behold, a man clothed in linen, whose thighs were adorned with pure gold of Uphaz:",
                "his body also was like the beryl, and his face as the appearance of lightning, and his eyes as flaming torches, and his arms and his feet like burnished brass, and the voice of his words like the voice of a multitude.",
                "I, Daniel, alone saw the vision; for the men who were with me didn\u2019t see the vision; but a great quaking fell on them, and they fled to hide themselves.",
                "So I was left alone, and saw this great vision, and there remained no strength in me; for my comeliness was turned in me into corruption, and I retained no strength.",
                "Yet heard I the voice of his words; and when I heard the voice of his words, then was I fallen into a deep sleep on my face, with my face toward the ground.",
                "Behold, a hand touched me, which set me on my knees and on the palms of my hands.",
                "He said to me, Daniel, you man greatly beloved, understand the words that I speak to you, and stand upright; for am I now sent to you. When he had spoken this word to me, I stood trembling.",
                "Then he said to me, Don\u2019t be afraid, Daniel; for from the first day that you set your heart to understand, and to humble yourself before your God, your words were heard: and I have come for your words\u2019 sake.",
                "But the prince of the kingdom of Persia withstood me twenty-one days; but, behold, Michael, one of the chief princes, came to help me: and I remained there with the kings of Persia.",
                "Now I have come to make you understand what shall happen to your people in the latter days; for the vision is yet for many days:",
                "and when he had spoken to me according to these words, I set my face toward the ground, and was mute.",
                "Behold, one in the likeness of the sons of men touched my lips: then I opened my mouth, and spoke and said to him who stood before me, my lord, by reason of the vision my sorrows are turned on me, and I retain no strength.",
                "For how can the servant of this my lord talk with this my lord? for as for me, immediately there remained no strength in me, neither was there breath left in me.",
                "Then there touched me again one like the appearance of a man, and he strengthened me.",
                "He said, \"Greatly beloved man, don\u2019t be afraid: peace be to you, be strong, yes, be strong.\" When he spoke to me, I was strengthened, and said, \"Let my lord speak; for you have strengthened me.\"",
                "Then he said, \"Do you know why I have come to you? Now I will return to fight with the prince of Persia. When I go forth, behold, the prince of Greece shall come.",
                "But I will tell you that which is inscribed in the writing of truth: and there is none who holds with me against these, but Michael your prince.\""
            ],
            11: [
                "\"As for me, in the first year of Darius the Mede, I stood up to confirm and strengthen him.",
                "Now will I show you the truth. Behold, there shall stand up yet three kings in Persia; and the fourth shall be far richer than they all: and when he has grown strong through his riches, he shall stir up all against the realm of Greece.",
                "A mighty king shall stand up, who shall rule with great dominion, and do according to his will.",
                "When he shall stand up, his kingdom shall be broken, and shall be divided toward the four winds of the sky, but not to his posterity, nor according to his dominion with which he ruled; for his kingdom shall be plucked up, even for others besides these.",
                "The king of the south shall be strong, and one of his princes; and he shall be strong above him, and have dominion; his dominion shall be a great dominion.",
                "At the end of years they shall join themselves together; and the daughter of the king of the south shall come to the king of the north to make an agreement: but she shall not retain the strength of her arm; neither shall he stand, nor his arm; but she shall be given up, and those who brought her, and he who became the father of her, and he who strengthened her in those times.",
                "But out of a shoot from her roots shall one stand up in his place, who shall come to the army, and shall enter into the fortress of the king of the north, and shall deal against them, and shall prevail.",
                "Also their gods, with their molten images, and with their goodly vessels of silver and of gold, shall he carry captive into Egypt; and he shall refrain some years from the king of the north.",
                "He shall come into the realm of the king of the south, but he shall return into his own land.",
                "His sons shall war, and shall assemble a multitude of great forces, which shall come on, and overflow, and pass through; and they shall return and war, even to his fortress.",
                "The king of the south shall be moved with anger, and shall come forth and fight with him, even with the king of the north; and he shall set forth a great multitude, and the multitude shall be given into his hand.",
                "The multitude shall be lifted up, and his heart shall be exalted; and he shall cast down tens of thousands, but he shall not prevail.",
                "The king of the north shall return, and shall set forth a multitude greater than the former; and he shall come on at the end of the times, even of years, with a great army and with much substance.",
                "In those times there shall many stand up against the king of the south: also the children of the violent among your people shall lift themselves up to establish the vision; but they shall fall.",
                "So the king of the north shall come, and cast up a mound, and take a well-fortified city: and the forces of the south shall not stand, neither his chosen people, neither shall there be any strength to stand.",
                "But he who comes against him shall do according to his own will, and none shall stand before him; and he shall stand in the glorious land, and in his hand shall be destruction.",
                "He shall set his face to come with the strength of his whole kingdom, and with him equitable conditions; and he shall perform them: and he shall give him the daughter of women, to corrupt her; but she shall not stand, neither be for him.",
                "After this shall he turn his face to the islands, and shall take many: but a prince shall cause the reproach offered by him to cease; yes, moreover, he shall cause his reproach to turn on him.",
                "Then he shall turn his face toward the fortresses of his own land; but he shall stumble and fall, and shall not be found.",
                "Then shall stand up in his place one who shall cause a tax collector to pass through the kingdom to maintain its glory; but within few days he shall be destroyed, neither in anger, nor in battle.",
                "In his place shall stand up a contemptible person, to whom they had not given the honor of the kingdom: but he shall come in time of security, and shall obtain the kingdom by flatteries.",
                "The overwhelming forces shall be overwhelmed from before him, and shall be broken; yes, also the prince of the covenant.",
                "After the treaty made with him he shall work deceitfully; for he shall come up, and shall become strong, with a small people.",
                "In time of security shall he come even on the fattest places of the province; and he shall do that which his fathers have not done, nor his fathers\u2019 fathers; he shall scatter among them prey, and spoil, and substance: yes, he shall devise his devices against the strongholds, even for a time.",
                "He shall stir up his power and his courage against the king of the south with a great army; and the king of the south shall war in battle with an exceeding great and mighty army; but he shall not stand; for they shall devise devices against him.",
                "Yes, they who eat of his dainties shall destroy him, and his army shall overflow; and many shall fall down slain.",
                "As for both these kings, their hearts shall be to do mischief, and they shall speak lies at one table: but it shall not prosper; for yet the end shall be at the time appointed.",
                "Then shall he return into his land with great substance; and his heart shall be against the holy covenant; and he shall do his pleasure, and return to his own land.",
                "At the time appointed he shall return, and come into the south; but it shall not be in the latter time as it was in the former.",
                "For ships of Kittim shall come against him; therefore he shall be grieved, and shall return, and have indignation against the holy covenant, and shall do his pleasure: he shall even return, and have regard to those who forsake the holy covenant.",
                "Forces shall stand on his part, and they shall profane the sanctuary, even the fortress, and shall take away the continual burnt offering, and they shall set up the abomination that makes desolate.",
                "Such as do wickedly against the covenant shall he pervert by flatteries; but the people who know their God shall be strong, and do exploits.",
                "Those who are wise among the people shall instruct many; yet they shall fall by the sword and by flame, by captivity and by spoil, many days.",
                "Now when they shall fall, they shall be helped with a little help; but many shall join themselves to them with flatteries.",
                "Some of those who are wise shall fall, to refine them, and to purify, and to make them white, even to the time of the end; because it is yet for the time appointed.",
                "The king shall do according to his will; and he shall exalt himself, and magnify himself above every god, and shall speak marvelous things against the God of gods; and he shall prosper until the indignation be accomplished; for that which is determined shall be done.",
                "Neither shall he regard the gods of his fathers, nor the desire of women, nor regard any god; for he shall magnify himself above all.",
                "But in his place shall he honor the god of fortresses; and a god whom his fathers didn\u2019t know shall he honor with gold, and silver, and with precious stones, and pleasant things.",
                "He shall deal with the strongest fortresses by the help of a foreign god: whoever acknowledges him he will increase with glory; and he shall cause them to rule over many, and shall divide the land for a price.",
                "At the time of the end shall the king of the south contend with him; and the king of the north shall come against him like a whirlwind, with chariots, and with horsemen, and with many ships; and he shall enter into the countries, and shall overflow and pass through.",
                "He shall enter also into the glorious land, and many countries shall be overthrown; but these shall be delivered out of his hand: Edom, and Moab, and the chief of the children of Ammon.",
                "He shall stretch forth his hand also on the countries; and the land of Egypt shall not escape.",
                "But he shall have power over the treasures of gold and of silver, and over all the precious things of Egypt; and the Libyans and the Ethiopians shall be at his steps.",
                "But news out of the east and out of the north shall trouble him; and he shall go forth with great fury to destroy and utterly to sweep away many.",
                "He shall plant the tents of his palace between the sea and the glorious holy mountain; yet he shall come to his end, and none shall help him."
            ],
            12: [
                "\"At that time shall Michael stand up, the great prince who stands for the children of your people; and there shall be a time of trouble, such as never was since there was a nation even to that same time: and at that time your people shall be delivered, everyone who shall be found written in the book.",
                "Many of those who sleep in the dust of the earth shall awake, some to everlasting life, and some to shame and everlasting contempt.",
                "Those who are wise shall shine as the brightness of the expanse; and those who turn many to righteousness as the stars forever and ever.",
                "But you, Daniel, shut up the words, and seal the book, even to the time of the end: many shall run back and forth, and knowledge shall be increased.\"",
                "Then I, Daniel, looked, and behold, two others stood, one on the river bank on this side, and the other on the river bank on that side.",
                "One said to the man clothed in linen, who was above the waters of the river, How long shall it be to the end of these wonders?",
                "I heard the man clothed in linen, who was above the waters of the river, when he held up his right hand and his left hand to heaven, and swore by him who lives forever that it shall be for a time, times, and a half; and when they have made an end of breaking in pieces the power of the holy people, all these things shall be finished.",
                "I heard, but I didn\u2019t understand: then I said, my lord, what shall be the issue of these things?",
                "He said, Go your way, Daniel; for the words are shut up and sealed until the time of the end.",
                "Many shall purify themselves, and make themselves white, and be refined; but the wicked shall do wickedly; and none of the wicked shall understand; but those who are wise shall understand.",
                "From the time that the continual burnt offering shall be taken away, and the abomination that makes desolate set up, there shall be one thousand two hundred ninety days.",
                "Blessed is he who waits, and comes to the one thousand three hundred thirty-five days.",
                "But go you your way until the end; for you shall rest, and shall stand in your lot, at the end of the days."
            ]
        },
        28: {
            1: [
                "The word of Yahweh that came to Hosea the son of Beeri, in the days of Uzziah, Jotham, Ahaz, and Hezekiah, kings of Judah, and in the days of Jeroboam the son of Joash, king of Israel.",
                "When Yahweh spoke at first by Hosea, Yahweh said to Hosea, \"Go, take for yourself a wife of prostitution and children of unfaithfulness; for the land commits great adultery, forsaking Yahweh.\"",
                "So he went and took Gomer the daughter of Diblaim; and she conceived, and bore him a son.",
                "Yahweh said to him, \"Call his name Jezreel; for yet a little while, and I will avenge the blood of Jezreel on the house of Jehu, and will cause the kingdom of the house of Israel to cease.",
                "It will happen in that day that I will break the bow of Israel in the valley of Jezreel.\"",
                "She conceived again, and bore a daughter. Then he said to him, \"Call her name Lo-Ruhamah; for I will no longer have mercy on the house of Israel, that I should in any way pardon them.",
                "But I will have mercy on the house of Judah, and will save them by Yahweh their God, and will not save them by bow, sword, battle, horses, or horsemen.\"",
                "Now when she had weaned Lo-Ruhamah, she conceived, and bore a son.",
                "He said, \"Call his name Lo-Ammi; for you are not my people, and I will not be yours.",
                "Yet the number of the children of Israel will be as the sand of the sea, which can\u2019t be measured nor numbered; and it will come to pass that, in the place where it was said to them, \u2018You are not my people,\u2019 they will be called \u2018sons of the living God.\u2019",
                "The children of Judah and the children of Israel will be gathered together, and they will appoint themselves one head, and will go up from the land; for great will be the day of Jezreel."
            ],
            2: [
                "\"Say to your brothers, \u2018My people!\u2019 and to your sisters, \u2018My loved one!\u2019",
                "Contend with your mother! Contend, for she is not my wife, neither am I her husband; and let her put away her prostitution from her face, and her adulteries from between her breasts;",
                "Lest I strip her naked, and make her bare as in the day that she was born, and make her like a wilderness, and set her like a dry land, and kill her with thirst.",
                "Indeed, on her children I will have no mercy; for they are children of unfaithfulness;",
                "For their mother has played the prostitute. She who conceived them has done shamefully; for she said, \u2018I will go after my lovers, who give me my bread and my water, my wool and my flax, my oil and my drink.\u2019",
                "Therefore, behold, I will hedge up your way with thorns, and I will build a wall against her, that she can\u2019t find her way.",
                "She will follow after her lovers, but she won\u2019t overtake them; and she will seek them, but won\u2019t find them. Then she will say, \u2018I will go and return to my first husband; for then was it better with me than now.\u2019",
                "For she did not know that I gave her the grain, the new wine, and the oil, and multiplied to her silver and gold, which they used for Baal.",
                "Therefore I will take back my grain in its time, and my new wine in its season, and will pluck away my wool and my flax which should have covered her nakedness.",
                "Now I will uncover her lewdness in the sight of her lovers, and no one will deliver her out of my hand.",
                "I will also cause all her celebrations to cease: her feasts, her new moons, her Sabbaths, and all her solemn assemblies.",
                "I will lay waste her vines and her fig trees, about which she has said, \u2018These are my wages that my lovers have given me; and I will make them a forest,\u2019 and the animals of the field shall eat them.",
                "I will visit on her the days of the Baals, to which she burned incense, when she decked herself with her earrings and her jewels, and went after her lovers, and forgot me,\" says Yahweh.",
                "\"Therefore, behold, I will allure her, and bring her into the wilderness, and speak tenderly to her.",
                "I will give her vineyards from there, and the valley of Achor for a door of hope; and she will respond there, as in the days of her youth, and as in the day when she came up out of the land of Egypt.",
                "It will be in that day,\" says Yahweh, \"that you will call me \u2018my husband,\u2019 and no longer call me \u2018my master.\u2019",
                "For I will take away the names of the Baals out of her mouth, and they will no longer be mentioned by name.",
                "In that day I will make a covenant for them with the animals of the field, and with the birds of the sky, and with the creeping things of the ground. I will break the bow, the sword, and the battle out of the land, and will make them lie down safely.",
                "I will betroth you to me forever. Yes, I will betroth you to me in righteousness, in justice, in loving kindness, and in compassion.",
                "I will even betroth you to me in faithfulness; and you shall know Yahweh.",
                "It will happen in that day, I will respond,\" says Yahweh, \"I will respond to the heavens, and they will respond to the earth;",
                "and the earth will respond to the grain, and the new wine, and the oil; and they will respond to Jezreel.",
                "I will sow her to me in the earth; and I will have mercy on her who had not obtained mercy; and I will tell those who were not my people, \u2018You are my people;\u2019 and they will say, \u2018My God!\u2019\""
            ],
            3: [
                "Yahweh said to me, \"Go again, love a woman loved by another, and an adulteress, even as Yahweh loves the children of Israel, though they turn to other gods, and love cakes of raisins.\"",
                "So I bought her for myself for fifteen pieces of silver and a homer and a half of barley.",
                "I said to her, \"You shall stay with me many days. You shall not play the prostitute, and you shall not be with any other man. I will also be so toward you.\"",
                "For the children of Israel shall live many days without king, and without prince, and without sacrifice, and without sacred stone, and without ephod or idols.",
                "Afterward the children of Israel shall return, and seek Yahweh their God, and David their king, and shall come with trembling to Yahweh and to his blessings in the last days."
            ],
            4: [
                "Hear the word of Yahweh, you children of Israel; for Yahweh has a charge against the inhabitants of the land: \"Indeed there is no truth, nor goodness, nor knowledge of God in the land.",
                "There is cursing, lying, murder, stealing, and committing adultery; they break boundaries, and bloodshed causes bloodshed.",
                "Therefore the land will mourn, and everyone who dwells therein will waste away. all living things in her, even the animals of the field and the birds of the sky; yes, the fish of the sea also die.",
                "\"Yet let no man bring a charge, neither let any man accuse; For your people are like those who bring charges against a priest.",
                "You will stumble in the day, and the prophet will also stumble with you in the night; and I will destroy your mother.",
                "My people are destroyed for lack of knowledge. Because you have rejected knowledge, I will also reject you, that you may be no priest to me. Because you have forgotten your God\u2019s law, I will also forget your children.",
                "As they were multiplied, so they sinned against me. I will change their glory into shame.",
                "They feed on the sin of my people, and set their heart on their iniquity.",
                "It will be, like people, like priest; and I will punish them for their ways, and will repay them for their deeds.",
                "They will eat, and not have enough. They will play the prostitute, and will not increase; because they have abandoned giving to Yahweh.",
                "Prostitution, wine, and new wine take away understanding.",
                "My people consult with their wooden idol, and answer to a stick of wood. Indeed the spirit of prostitution has led them astray, and they have been unfaithful to their God.",
                "They sacrifice on the tops of the mountains, and burn incense on the hills, under oaks and poplars and terebinths, because its shade is good. Therefore your daughters play the prostitute, and your brides commit adultery.",
                "I will not punish your daughters when they play the prostitute, nor your brides when they commit adultery; because the men consort with prostitutes, and they sacrifice with the shrine prostitutes; so the people without understanding will come to ruin.",
                "\"Though you, Israel, play the prostitute, yet don\u2019t let Judah offend; and don\u2019t come to Gilgal, neither go up to Beth Aven, nor swear, \u2018As Yahweh lives.\u2019",
                "For Israel has behaved extremely stubbornly, like a stubborn heifer. Then how will Yahweh feed them like a lamb in a meadow.",
                "Ephraim is joined to idols. Leave him alone!",
                "Their drink has become sour. They play the prostitute continually. Her rulers dearly love their shameful way.",
                "The wind has wrapped her up in its wings; and they shall be disappointed because of their sacrifices."
            ],
            5: [
                "\"Listen to this, you priests! Listen, house of Israel, and give ear, house of the king! For the judgment is against you; for you have been a snare at Mizpah, and a net spread on Tabor.",
                "The rebels are deep in slaughter; but I discipline all of them.",
                "I know Ephraim, and Israel is not hidden from me; for now, Ephraim, you have played the prostitute. Israel is defiled.",
                "Their deeds won\u2019t allow them to turn to their God; for the spirit of prostitution is within them, and they don\u2019t know Yahweh.",
                "The pride of Israel testifies to his face. Therefore Israel and Ephraim will stumble in their iniquity. Judah also will stumble with them.",
                "They will go with their flocks and with their herds to seek Yahweh; but they won\u2019t find him. He has withdrawn himself from them.",
                "They are unfaithful to Yahweh; for they have borne illegitimate children. Now the new moon will devour them with their fields.",
                "\"Blow the cornet in Gibeah, and the trumpet in Ramah! Sound a battle cry at Beth Aven, behind you, Benjamin!",
                "Ephraim will become a desolation in the day of rebuke. Among the tribes of Israel, I have made known that which will surely be.",
                "The princes of Judah are like those who remove a landmark. I will pour out my wrath on them like water.",
                "Ephraim is oppressed, he is crushed in judgment; Because he is intent in his pursuit of idols.",
                "Therefore I am to Ephraim like a moth, and to the house of Judah like rottenness.",
                "\"When Ephraim saw his sickness, and Judah his wound, Then Ephraim went to Assyria, and sent to king Jareb: but he is not able to heal you, neither will he cure you of your wound.",
                "For I will be to Ephraim like a lion, and like a young lion to the house of Judah. I myself will tear in pieces and go away. I will carry off, and there will be no one to deliver.",
                "I will go and return to my place, until they acknowledge their offense, and seek my face. In their affliction they will seek me earnestly.\""
            ],
            6: [
                "\"Come, and let us return to Yahweh; for he has torn us to pieces, and he will heal us; he has injured us, and he will bind up our wounds.",
                "After two days he will revive us. On the third day he will raise us up, and we will live before him.",
                "Let us acknowledge Yahweh. Let us press on to know Yahweh. As surely as the sun rises, Yahweh will appear. He will come to us like the rain, like the spring rain that waters the earth.\"",
                "\"Ephraim, what shall I do to you? Judah, what shall I do to you? For your love is like a morning cloud, and like the dew that disappears early.",
                "Therefore I have cut them to pieces with the prophets; I killed them with the words of my mouth. Your judgments are like a flash of lightning.",
                "For I desire mercy, and not sacrifice; and the knowledge of God more than burnt offerings.",
                "But they, like Adam, have broken the covenant. They were unfaithful to me, there.",
                "Gilead is a city of those who work iniquity; it is stained with blood.",
                "As gangs of robbers wait to ambush a man, so the company of priests murder in the way toward Shechem, committing shameful crimes.",
                "In the house of Israel I have seen a horrible thing. There is prostitution in Ephraim. Israel is defiled.",
                "\"Also, Judah, there is a harvest appointed for you, when I restore the fortunes of my people."
            ],
            7: [
                "When I would heal Israel, then the iniquity of Ephraim is uncovered, also the wickedness of Samaria; for they commit falsehood, and the thief enters in, and the gang of robbers ravages outside.",
                "They don\u2019t consider in their hearts that I remember all their wickedness. Now their own deeds have engulfed them. They are before my face.",
                "They make the king glad with their wickedness, and the princes with their lies.",
                "They are all adulterers. They are burning like an oven that the baker stops stirring, from the kneading of the dough, until it is leavened.",
                "On the day of our king, the princes made themselves sick with the heat of wine. He joined his hand with mockers.",
                "For they have prepared their heart like an oven, while they lie in wait. Their baker sleeps all the night. In the morning it burns as a flaming fire.",
                "They are all hot as an oven, and devour their judges. All their kings have fallen. There is no one among them who calls to me.",
                "Ephraim, he mixes himself among the nations. Ephraim is a pancake not turned over.",
                "Strangers have devoured his strength, and he doesn\u2019t realize it. Indeed, gray hairs are here and there on him, and he doesn\u2019t realize it.",
                "The pride of Israel testifies to his face; yet they haven\u2019t returned to Yahweh their God, nor sought him, for all this.",
                "\"Ephraim is like an easily deceived dove, without understanding. They call to Egypt. They go to Assyria.",
                "When they go, I will spread my net on them. I will bring them down like the birds of the sky. I will chastise them, as their congregation has heard.",
                "Woe to them! For they have wandered from me. Destruction to them! For they have trespassed against me. Though I would redeem them, yet they have spoken lies against me.",
                "They haven\u2019t cried to me with their heart, but they howl on their beds. They assemble themselves for grain and new wine. They turn away from me.",
                "Though I have taught and strengthened their arms, yet they plot evil against me.",
                "They return, but not to the Most High. They are like a faulty bow. Their princes will fall by the sword for the rage of their tongue. This will be their derision in the land of Egypt."
            ],
            8: [
                "\"Put the trumpet to your lips! Something like an eagle is over Yahweh\u2019s house, because they have broken my covenant, and rebelled against my law.",
                "They cry to me, \u2018My God, we Israel acknowledge you!\u2019",
                "Israel has cast off that which is good. The enemy will pursue him.",
                "They have set up kings, but not by me. They have made princes, and I didn\u2019t approve. Of their silver and their gold they have made themselves idols, that they may be cut off.",
                "Let Samaria throw out his calf idol! My anger burns against them! How long will it be until they are capable of purity?",
                "For this is even from Israel! The workman made it, and it is no God; indeed, the calf of Samaria shall be broken in pieces.",
                "For they sow the wind, and they will reap the whirlwind. He has no standing grain. The stalk will yield no head. If it does yield, strangers will swallow it up.",
                "Israel is swallowed up. Now they are among the nations like a worthless thing.",
                "For they have gone up to Assyria, like a wild donkey wandering alone. Ephraim has hired lovers for himself.",
                "But although they sold themselves among the nations, I will now gather them; and they begin to waste away because of the oppression of the king of mighty ones.",
                "Because Ephraim has multiplied altars for sinning, they became for him altars for sinning.",
                "I wrote for him the many things of my law; but they were regarded as a strange thing.",
                "As for the sacrifices of my offerings, they sacrifice flesh and eat it; But Yahweh doesn\u2019t accept them. Now he will remember their iniquity, and punish their sins. They will return to Egypt.",
                "For Israel has forgotten his Maker and built palaces; and Judah has multiplied fortified cities; but I will send a fire on his cities, and it will devour its fortresses.\""
            ],
            9: [
                "Don\u2019t rejoice, Israel, to jubilation like the nations; for you were unfaithful to your God. You love the wages of a prostitute at every grain threshing floor.",
                "The threshing floor and the winepress won\u2019t feed them, and the new wine will fail her.",
                "They won\u2019t dwell in Yahweh\u2019s land; but Ephraim will return to Egypt, and they will eat unclean food in Assyria.",
                "They won\u2019t pour out wine offerings to Yahweh, neither will they be pleasing to him. Their sacrifices will be to them like the bread of mourners; all who eat of it will be polluted; for their bread will be for their appetite. It will not come into the house of Yahweh.",
                "What will you do in the day of solemn assembly, and in the day of the feast of Yahweh?",
                "For, behold, they have gone away from destruction. Egypt will gather them up. Memphis will bury them. Nettles will possess their pleasant things of silver. Thorns will be in their tents.",
                "The days of visitation have come. The days of reckoning have come. Israel will consider the prophet to be a fool, and the man who is inspired to be insane, because of the abundance of your sins, and because your hostility is great.",
                "A prophet watches over Ephraim with my God. A fowler\u2019s snare is on all of his paths, and hostility in the house of his God.",
                "They have deeply corrupted themselves, as in the days of Gibeah. He will remember their iniquity. He will punish them for their sins.",
                "I found Israel like grapes in the wilderness. I saw your fathers as the first ripe in the fig tree at its first season; but they came to Baal Peor, and consecrated themselves to the shameful thing, and became abominable like that which they loved.",
                "As for Ephraim, their glory will fly away like a bird. There will be no birth, none with child, and no conception.",
                "Though they bring up their children, yet I will bereave them, so that not a man shall be left. Indeed, woe also to them when I depart from them!",
                "I have seen Ephraim, like Tyre, planted in a pleasant place; but Ephraim will bring out his children to the murderer.",
                "Give them\u2014Yahweh what will you give? Give them a miscarrying womb and dry breasts.",
                "\"All their wickedness is in Gilgal; for there I hated them. Because of the wickedness of their deeds I will drive them out of my house! I will love them no more. All their princes are rebels.",
                "Ephraim is struck. Their root has dried up. They will bear no fruit. Even though they bring forth, yet I will kill the beloved ones of their womb.\"",
                "My God will cast them away, because they did not listen to him; and they will be wanderers among the nations."
            ],
            10: [
                "Israel is a luxuriant vine that puts forth his fruit. According to the abundance of his fruit he has multiplied his altars. As their land has prospered, they have adorned their sacred stones.",
                "Their heart is divided. Now they will be found guilty. He will demolish their altars. He will destroy their sacred stones.",
                "Surely now they will say, \"We have no king; for we don\u2019t fear Yahweh; and the king, what can he do for us?\"",
                "They make promises, swearing falsely in making covenants. Therefore judgment springs up like poisonous weeds in the furrows of the field.",
                "The inhabitants of Samaria will be in terror for the calves of Beth Aven; for its people will mourn over it, Along with its priests who rejoiced over it, for its glory, because it has departed from it.",
                "It also will be carried to Assyria for a present to a great king. Ephraim will receive shame, and Israel will be ashamed of his own counsel.",
                "Samaria and her king float away, like a twig on the water.",
                "The high places also of Aven, the sin of Israel, will be destroyed. The thorn and the thistle will come up on their altars. They will tell the mountains, \"Cover us!\" and the hills, \"Fall on us!\"",
                "\"Israel, you have sinned from the days of Gibeah. There they remained. The battle against the children of iniquity doesn\u2019t overtake them in Gibeah.",
                "When it is my desire, I will chastise them; and the nations will be gathered against them, when they are bound to their two transgressions.",
                "Ephraim is a trained heifer that loves to thresh; so I will put a yoke on her beautiful neck. I will set a rider on Ephraim. Judah will plow. Jacob will break his clods.",
                "Sow to yourselves in righteousness, reap according to kindness. Break up your fallow ground; for it is time to seek Yahweh, until he comes and rains righteousness on you.",
                "You have plowed wickedness. You have reaped iniquity. You have eaten the fruit of lies, for you trusted in your way, in the multitude of your mighty men.",
                "Therefore a battle roar will arise among your people, and all your fortresses will be destroyed, as Shalman destroyed Beth Arbel in the day of battle. The mother was dashed in pieces with her children.",
                "So Bethel will do to you because of your great wickedness. At daybreak the king of Israel will be destroyed."
            ],
            11: [
                "\"When Israel was a child, then I loved him, and called my son out of Egypt.",
                "They called to them, so they went from them. They sacrificed to the Baals, and burned incense to engraved images.",
                "Yet I taught Ephraim to walk. I took them by his arms; but they didn\u2019t know that I healed them.",
                "I drew them with cords of a man, with ties of love; and I was to them like those who lift up the yoke on their necks; and I bent down to him and I fed him.",
                "\"They won\u2019t return into the land of Egypt; but the Assyrian will be their king, because they refused to repent.",
                "The sword will fall on their cities, and will destroy the bars of their gates, and will put an end to their plans.",
                "My people are determined to turn from me. Though they call to the Most High, he certainly won\u2019t exalt them.",
                "\"How can I give you up, Ephraim? How can I hand you over, Israel? How can I make you like Admah? How can I make you like Zeboiim? My heart is turned within me, my compassion is aroused.",
                "I will not execute the fierceness of my anger. I will not return to destroy Ephraim: for I am God, and not man; the Holy One in the midst of you; and I will not come in wrath.",
                "They will walk after Yahweh, who will roar like a lion; for he will roar, and the children will come trembling from the west.",
                "They will come trembling like a bird out of Egypt, and like a dove out of the land of Assyria; and I will settle them in their houses,\" says Yahweh.",
                "Ephraim surrounds me with falsehood, and the house of Israel with deceit. Judah still strays from God, and is unfaithful to the Holy One."
            ],
            12: [
                "Ephraim feeds on wind, and chases the east wind. He continually multiplies lies and desolation. They make a covenant with Assyria, and oil is carried into Egypt.",
                "Yahweh also has a controversy with Judah, and will punish Jacob according to his ways; according to his deeds he will repay him.",
                "In the womb he took his brother by the heel; and in his manhood he contended with God.",
                "Indeed, he struggled with the angel, and prevailed; he wept, and made supplication to him. He found him at Bethel, and there he spoke with us,",
                "even Yahweh, the God of Armies; Yahweh is his name of renown!",
                "Therefore turn to your God. Keep kindness and justice, and wait continually for your God.",
                "A merchant has dishonest scales in his hand. He loves to defraud.",
                "Ephraim said, \"Surely I have become rich, I have found myself wealth. In all my wealth they won\u2019t find in me any iniquity that is sin.\"",
                "\"But I am Yahweh your God from the land of Egypt. I will yet again make you dwell in tents, as in the days of the solemn feast.",
                "I have also spoken to the prophets, and I have multiplied visions; and by the ministry of the prophets I have used parables.",
                "If Gilead is wicked, surely they are worthless. In Gilgal they sacrifice bulls. Indeed, their altars are like heaps in the furrows of the field.",
                "Jacob fled into the country of Aram, and Israel served to get a wife, and for a wife he tended flocks and herds.",
                "By a prophet Yahweh brought Israel up out of Egypt, and by a prophet he was preserved.",
                "Ephraim has bitterly provoked anger. Therefore his blood will be left on him, and his Lord will repay his contempt."
            ],
            13: [
                "When Ephraim spoke, there was trembling. He exalted himself in Israel, but when he became guilty in Baal, he died.",
                "Now they sin more and more, and have made themselves molten images of their silver, even idols according to their own understanding, all of them the work of the craftsmen. They say of them, \u2018They offer human sacrifice and kiss the calves.\u2019",
                "Therefore they will be like the morning mist, and like the dew that passes away early, like the chaff that is driven with the whirlwind out of the threshing floor, and like the smoke out of the chimney.",
                "\"Yet I am Yahweh your God from the land of Egypt; and you shall acknowledge no god but me, and besides me there is no savior.",
                "I knew you in the wilderness, in the land of great drought.",
                "According to their pasture, so were they filled; they were filled, and their heart was exalted. Therefore they have forgotten me.",
                "Therefore I am like a lion to them. Like a leopard, I will lurk by the path.",
                "I will meet them like a bear that is bereaved of her cubs, and will tear the covering of their heart. There I will devour them like a lioness. The wild animal will tear them.",
                "You are destroyed, Israel, because you are against me, against your help.",
                "Where is your king now, that he may save you in all your cities? And your judges, of whom you said, \u2018Give me a king and princes?\u2019",
                "I have given you a king in my anger, and have taken him away in my wrath.",
                "The guilt of Ephraim is stored up. His sin is stored up.",
                "The sorrows of a travailing woman will come on him. He is an unwise son; for when it is time, he doesn\u2019t come to the opening of the womb.",
                "I will ransom them from the power of Sheol. I will redeem them from death! Death, where are your plagues? Sheol, where is your destruction? \"Compassion will be hidden from my eyes.",
                "Though he is fruitful among his brothers, an east wind will come, the breath of Yahweh coming up from the wilderness; and his spring will become dry, and his fountain will be dried up. He will plunder the storehouse of treasure.",
                "Samaria will bear her guilt; for she has rebelled against her God. They will fall by the sword. Their infants will be dashed in pieces, and their pregnant women will be ripped open.\""
            ],
            14: [
                "Israel, return to Yahweh your God; for you have fallen because of your sin.",
                "Take words with you, and return to Yahweh. Tell him, \"Forgive all our sins, and accept that which is good: so we offer our lips like bulls.",
                "Assyria can\u2019t save us. We won\u2019t ride on horses; neither will we say any more to the work of our hands, \u2018Our gods!\u2019 for in you the fatherless finds mercy.\"",
                "\"I will heal their waywardness. I will love them freely; for my anger is turned away from him.",
                "I will be like the dew to Israel. He will blossom like the lily, and send down his roots like Lebanon.",
                "His branches will spread, and his beauty will be like the olive tree, and his fragrance like Lebanon.",
                "Men will dwell in his shade. They will revive like the grain, and blossom like the vine. Their fragrance will be like the wine of Lebanon.",
                "Ephraim, what have I to do any more with idols? I answer, and will take care of him. I am like a green fir tree; from me your fruit is found.\"",
                "Who is wise, that he may understand these things? Who is prudent, that he may know them? For the ways of Yahweh are right, and the righteous walk in them; But the rebellious stumble in them."
            ]
        },
        29: {
            1: [
                "The Word of Yahweh that came to Joel, the son of Pethuel.",
                "Hear this, you elders, And listen, all you inhabitants of the land. Has this ever happened in your days, or in the days of your fathers?",
                "Tell your children about it, and have your children tell their children, and their children, another generation.",
                "What the swarming locust has left, the great locust has eaten. What the great locust has left, the grasshopper has eaten. What the grasshopper has left, the caterpillar has eaten.",
                "Wake up, you drunkards, and weep! Wail, all you drinkers of wine, because of the sweet wine; for it is cut off from your mouth.",
                "For a nation has come up on my land, strong, and without number. His teeth are the teeth of a lion, and he has the fangs of a lioness.",
                "He has laid my vine waste, and stripped my fig tree. He has stripped its bark, and thrown it away. Its branches are made white.",
                "Mourn like a virgin dressed in sackcloth for the husband of her youth!",
                "The meal offering and the drink offering are cut off from Yahweh\u2019s house. The priests, Yahweh\u2019s ministers, mourn.",
                "The field is laid waste. The land mourns, for the grain is destroyed, The new wine has dried up, and the oil languishes.",
                "Be confounded, you farmers! Wail, you vineyard keepers; for the wheat and for the barley; for the harvest of the field has perished.",
                "The vine has dried up, and the fig tree withered; the pomegranate tree, the palm tree also, and the apple tree, even all of the trees of the field are withered; for joy has withered away from the sons of men.",
                "Put on sackcloth and mourn, you priests! Wail, you ministers of the altar. Come, lie all night in sackcloth, you ministers of my God, for the meal offering and the drink offering are withheld from your God\u2019s house.",
                "Sanctify a fast. Call a solemn assembly. Gather the elders, and all the inhabitants of the land, to the house of Yahweh, your God, and cry to Yahweh.",
                "Alas for the day! For the day of Yahweh is at hand, and it will come as destruction from the Almighty.",
                "Isn\u2019t the food cut off before our eyes; joy and gladness from the house of our God?",
                "The seeds rot under their clods. The granaries are laid desolate. The barns are broken down, for the grain has withered.",
                "How the animals groan! The herds of livestock are perplexed, because they have no pasture. Yes, the flocks of sheep are made desolate.",
                "Yahweh, I cry to you, For the fire has devoured the pastures of the wilderness, and the flame has burned all the trees of the field.",
                "Yes, the animals of the field pant to you, for the water brooks have dried up, And the fire has devoured the pastures of the wilderness."
            ],
            2: [
                "Blow the trumpet in Zion, and sound an alarm in my holy mountain! Let all the inhabitants of the land tremble, for the day of Yahweh comes, for it is close at hand:",
                "A day of darkness and gloominess, a day of clouds and thick darkness. As the dawn spreading on the mountains, a great and strong people; there has never been the like, neither will there be any more after them, even to the years of many generations.",
                "A fire devours before them, and behind them, a flame burns. The land is as the garden of Eden before them, and behind them, a desolate wilderness. Yes, and no one has escaped them.",
                "Their appearance is as the appearance of horses, and as horsemen, so do they run.",
                "Like the noise of chariots on the tops of the mountains do they leap, like the noise of a flame of fire that devours the stubble, as a strong people set in battle array.",
                "At their presence the peoples are in anguish. All faces have grown pale.",
                "They run like mighty men. They climb the wall like warriors. They each march in his line, and they don\u2019t swerve off course.",
                "Neither does one jostle another; they march everyone in his path, and they burst through the defenses, and don\u2019t break ranks.",
                "They rush on the city. They run on the wall. They climb up into the houses. They enter in at the windows like thieves.",
                "The earth quakes before them. The heavens tremble. The sun and the moon are darkened, and the stars withdraw their shining.",
                "Yahweh thunders his voice before his army; for his forces are very great; for he is strong who obeys his command; for the day of Yahweh is great and very awesome, and who can endure it?",
                "\"Yet even now,\" says Yahweh, \"turn to me with all your heart, and with fasting, and with weeping, and with mourning.\"",
                "Tear your heart, and not your garments, and turn to Yahweh, your God; for he is gracious and merciful, slow to anger, and abundant in loving kindness, and relents from sending calamity.",
                "Who knows? He may turn and relent, and leave a blessing behind him, even a meal offering and a drink offering to Yahweh, your God.",
                "Blow the trumpet in Zion! Sanctify a fast. Call a solemn assembly.",
                "Gather the people. Sanctify the assembly. Assemble the elders. Gather the children, and those who nurse from breasts. Let the bridegroom go forth from his room, and the bride out of her room.",
                "Let the priests, the ministers of Yahweh, weep between the porch and the altar, and let them say, \"Spare your people, Yahweh, and don\u2019t give your heritage to reproach, that the nations should rule over them. Why should they say among the peoples, \u2018Where is their God?\u2019\"",
                "Then Yahweh was jealous for his land, And had pity on his people.",
                "Yahweh answered his people, \"Behold, I will send you grain, new wine, and oil, and you will be satisfied with them; and I will no more make you a reproach among the nations.",
                "But I will remove the northern army far away from you, and will drive it into a barren and desolate land, its front into the eastern sea, and its back into the western sea; and its stench will come up, and its bad smell will rise.\" Surely he has done great things.",
                "Land, don\u2019t be afraid. Be glad and rejoice, for Yahweh has done great things.",
                "Don\u2019t be afraid, you animals of the field; for the pastures of the wilderness spring up, for the tree bears its fruit. The fig tree and the vine yield their strength.",
                "\"Be glad then, you children of Zion, and rejoice in Yahweh, your God; for he gives you the former rain in just measure, and he causes the rain to come down for you, the former rain and the latter rain, as before.",
                "The threshing floors will be full of wheat, and the vats will overflow with new wine and oil.",
                "I will restore to you the years that the swarming locust has eaten, the great locust, the grasshopper, and the caterpillar, my great army, which I sent among you.",
                "You will have plenty to eat, and be satisfied, and will praise the name of Yahweh, your God, who has dealt wondrously with you; and my people will never again be disappointed.",
                "You will know that I am in the midst of Israel, and that I am Yahweh, your God, and there is no one else; and my people will never again be disappointed.",
                "\"It will happen afterward, that I will pour out my Spirit on all flesh; and your sons and your daughters will prophesy. Your old men will dream dreams. Your young men will see visions.",
                "And also on the servants and on the handmaids in those days, I will pour out my Spirit.",
                "I will show wonders in the heavens and in the earth: blood, fire, and pillars of smoke.",
                "The sun will be turned into darkness, and the moon into blood, before the great and terrible day of Yahweh comes.",
                "It will happen that whoever will call on the name of Yahweh shall be saved; for in Mount Zion and in Jerusalem there will be those who escape, as Yahweh has said, and among the remnant, those whom Yahweh calls."
            ],
            3: [
                "\"For, behold, in those days, and in that time, when I restore the fortunes of Judah and Jerusalem,",
                "I will gather all nations, and will bring them down into the valley of Jehoshaphat; and I will execute judgment on them there for my people, and for my heritage, Israel, whom they have scattered among the nations. They have divided my land,",
                "and have cast lots for my people, and have given a boy for a prostitute, and sold a girl for wine, that they may drink.",
                "\"Yes, and what are you to me, Tyre, and Sidon, and all the regions of Philistia? Will you repay me? And if you repay me, I will swiftly and speedily return your repayment on your own head.",
                "Because you have taken my silver and my gold, and have carried my finest treasures into your temples,",
                "and have sold the children of Judah and the children of Jerusalem to the sons of the Greeks, that you may remove them far from their border.",
                "Behold, I will stir them up out of the place where you have sold them, and will return your repayment on your own head;",
                "and I will sell your sons and your daughters into the hands of the children of Judah, and they will sell them to the men of Sheba, to a faraway nation, for Yahweh has spoken it.\"",
                "Proclaim this among the nations: \"Prepare for war! Stir up the mighty men. Let all the warriors draw near. Let them come up.",
                "Beat your plowshares into swords, and your pruning hooks into spears. Let the weak say, \u2018I am strong.\u2019",
                "Hurry and come, all you surrounding nations, and gather yourselves together.\" Cause your mighty ones to come down there, Yahweh.",
                "\"Let the nations arouse themselves, and come up to the valley of Jehoshaphat; for there will I sit to judge all the surrounding nations.",
                "Put in the sickle; for the harvest is ripe. Come, tread, for the winepress is full, the vats overflow, for their wickedness is great.\"",
                "Multitudes, multitudes in the valley of decision! For the day of Yahweh is near, in the valley of decision.",
                "The sun and the moon are darkened, and the stars withdraw their shining.",
                "Yahweh will roar from Zion, and thunder from Jerusalem; and the heavens and the earth will shake; but Yahweh will be a refuge to his people, and a stronghold to the children of Israel.",
                "\"So you will know that I am Yahweh, your God, dwelling in Zion, my holy mountain. Then Jerusalem will be holy, and no strangers will pass through her any more.",
                "It will happen in that day, that the mountains will drop down sweet wine, the hills will flow with milk, all the brooks of Judah will flow with waters, and a fountain will come forth from the house of Yahweh, and will water the valley of Shittim.",
                "Egypt will be a desolation, and Edom will be a desolate wilderness, for the violence done to the children of Judah, because they have shed innocent blood in their land.",
                "But Judah will be inhabited forever, and Jerusalem from generation to generation.",
                "I will cleanse their blood, that I have not cleansed: for Yahweh dwells in Zion.\""
            ]
        },
        30: {
            1: [
                "The words of Amos, who was among the herdsmen of Tekoa, which he saw concerning Israel in the days of Uzziah king of Judah, and in the days of Jeroboam the son of Joash king of Israel, two years before the earthquake.",
                "He said: \"Yahweh will roar from Zion, and utter his voice from Jerusalem; and the pastures of the shepherds will mourn, and the top of Carmel will wither.\"",
                "Thus says Yahweh: \"For three transgressions of Damascus, yes, for four, I will not turn away its punishment; because they have threshed Gilead with threshing instruments of iron;",
                "but I will send a fire into the house of Hazael, and it will devour the palaces of Ben Hadad.",
                "I will break the bar of Damascus, and cut off the inhabitant from the valley of Aven, and him who holds the scepter from the house of Eden; and the people of Syria shall go into captivity to Kir,\" says Yahweh.",
                "Thus says Yahweh: \"For three transgressions of Gaza, yes, for four, I will not turn away its punishment; because they carried away captive the whole community, to deliver them up to Edom;",
                "but I will send a fire on the wall of Gaza, and it will devour its palaces.",
                "I will cut off the inhabitant from Ashdod, and him who holds the scepter from Ashkelon; and I will turn my hand against Ekron; and the remnant of the Philistines will perish,\" says the Lord Yahweh.",
                "Thus says Yahweh: \"For three transgressions of Tyre, yes, for four, I will not turn away its punishment; because they delivered up the whole community to Edom, and didn\u2019t remember the brotherly covenant;",
                "but I will send a fire on the wall of Tyre, and it will devour its palaces.\"",
                "Thus says Yahweh: \"For three transgressions of Edom, yes, for four, I will not turn away its punishment; because he pursued his brother with the sword, and cast off all pity, and his anger raged continually, and he kept his wrath forever;",
                "but I will send a fire on Teman, and it will devour the palaces of Bozrah.\"",
                "Thus says Yahweh: \"For three transgressions of the children of Ammon, yes, for four, I will not turn away its punishment; because they have ripped open the pregnant women of Gilead, that they may enlarge their border.",
                "But I will kindle a fire in the wall of Rabbah, and it will devour its palaces, with shouting in the day of battle, with a storm in the day of the whirlwind;",
                "and their king will go into captivity, he and his princes together,\" says Yahweh."
            ],
            2: [
                "Thus says Yahweh: \"For three transgressions of Moab, yes, for four, I will not turn away its punishment; because he burned the bones of the king of Edom into lime;",
                "but I will send a fire on Moab, and it will devour the palaces of Kerioth; and Moab will die with tumult, with shouting, and with the sound of the trumpet;",
                "and I will cut off the judge from their midst, and will kill all its princes with him,\" says Yahweh.",
                "Thus says Yahweh: \"For three transgressions of Judah, yes, for four, I will not turn away its punishment; because they have rejected Yahweh\u2019s law, and have not kept his statutes, and their lies have led them astray, after which their fathers walked;",
                "But I will send a fire on Judah, and it will devour the palaces of Jerusalem.\"",
                "Thus says Yahweh: \"For three transgressions of Israel, yes, for four, I will not turn away its punishment; because they have sold the righteous for silver, and the needy for a pair of shoes;",
                "They trample on the dust of the earth on the head of the poor, and deny justice to the oppressed; and a man and his father use the same maiden, to profane my holy name;",
                "and they lay themselves down beside every altar on clothes taken in pledge; and in the house of their God they drink the wine of those who have been fined.",
                "Yet I destroyed the Amorite before them, whose height was like the height of the cedars, and he was strong as the oaks; yet I destroyed his fruit from above, and his roots from beneath.",
                "Also I brought you up out of the land of Egypt, and led you forty years in the wilderness, to possess the land of the Amorite.",
                "I raised up some of your sons for prophets, and some of your young men for Nazirites. Isn\u2019t this true, you children of Israel?\" says Yahweh.",
                "\"But you gave the Nazirites wine to drink, and commanded the prophets, saying, \u2018Don\u2019t prophesy!\u2019",
                "Behold, I will crush you in your place, as a cart crushes that is full of grain.",
                "Flight will perish from the swift; and the strong won\u2019t strengthen his force; neither shall the mighty deliver himself;",
                "neither shall he stand who handles the bow; and he who is swift of foot won\u2019t escape; neither shall he who rides the horse deliver himself;",
                "and he who is courageous among the mighty will flee away naked on that day,\" says Yahweh."
            ],
            3: [
                "Hear this word that Yahweh has spoken against you, children of Israel, against the whole family which I brought up out of the land of Egypt, saying:",
                "\"You only have I chosen of all the families of the earth. Therefore I will punish you for all of your sins.\"",
                "Do two walk together, unless they have agreed?",
                "Will a lion roar in the thicket, when he has no prey? Does a young lion cry out of his den, if he has caught nothing?",
                "Can a bird fall in a trap on the earth, where no snare is set for him? Does a snare spring up from the ground, when there is nothing to catch?",
                "Does the trumpet alarm sound in a city, without the people being afraid? Does evil happen to a city, and Yahweh hasn\u2019t done it?",
                "Surely the Lord Yahweh will do nothing, unless he reveals his secret to his servants the prophets.",
                "The lion has roared. Who will not fear? The Lord Yahweh has spoken. Who can but prophesy?",
                "Proclaim in the palaces at Ashdod, and in the palaces in the land of Egypt, and say, \"Assemble yourselves on the mountains of Samaria, and see what unrest is in her, and what oppression is among them.\"",
                "\"Indeed they don\u2019t know to do right,\" says Yahweh, \"Who hoard plunder and loot in their palaces.\"",
                "Therefore thus says the Lord Yahweh: \"An adversary will overrun the land; and he will pull down your strongholds, and your fortresses will be plundered.\"",
                "Thus says Yahweh: \"As the shepherd rescues out of the mouth of the lion two legs, or a piece of an ear, so shall the children of Israel be rescued who sit in Samaria on the corner of a couch, and on the silken cushions of a bed.\"",
                "\"Listen, and testify against the house of Jacob,\" says the Lord Yahweh, the God of Armies.",
                "\"For in the day that I visit the transgressions of Israel on him, I will also visit the altars of Bethel; and the horns of the altar will be cut off, and fall to the ground.",
                "I will strike the winter house with the summer house; and the houses of ivory will perish, and the great houses will have an end,\" says Yahweh."
            ],
            4: [
                "Listen to this word, you cows of Bashan, who are on the mountain of Samaria, who oppress the poor, who crush the needy, who tell their husbands, \"Bring us drinks!\"",
                "The Lord Yahweh has sworn by his holiness that behold, \"The days shall come on you that they will take you away with hooks, and the last of you with fish hooks.",
                "You will go out at the breaks in the wall, everyone straight before her; and you will cast yourselves into Harmon,\" says Yahweh.",
                "\"Go to Bethel, and sin; to Gilgal, and sin more. Bring your sacrifices every morning, your tithes every three days,",
                "offer a sacrifice of thanksgiving of that which is leavened, and proclaim free will offerings and brag about them: for this pleases you, you children of Israel,\" says the Lord Yahweh.",
                "\"I also have given you cleanness of teeth in all your cities, and lack of bread in every town; yet you haven\u2019t returned to me,\" says Yahweh.",
                "\"I also have withheld the rain from you, when there were yet three months to the harvest; and I caused it to rain on one city, and caused it not to rain on another city. One place was rained on, and the piece where it didn\u2019t rain withered.",
                "So two or three cities staggered to one city to drink water, and were not satisfied: yet you haven\u2019t returned to me,\" says Yahweh.",
                "\"I struck you with blight and mildew many times in your gardens and your vineyards; and your fig trees and your olive trees have the swarming locust devoured: yet you haven\u2019t returned to me,\" says Yahweh.",
                "\"I sent plagues among you like I did Egypt. I have slain your young men with the sword, and have carried away your horses; and I filled your nostrils with the stench of your camp, yet you haven\u2019t returned to me,\" says Yahweh.",
                "\"I have overthrown some of you, as when God overthrew Sodom and Gomorrah, and you were like a burning stick plucked out of the fire; yet you haven\u2019t returned to me,\" says Yahweh.",
                "\"Therefore thus will I do to you, Israel; because I will do this to you, prepare to meet your God, Israel.",
                "For, behold, he who forms the mountains, and creates the wind, and declares to man what is his thought; who makes the morning darkness, and treads on the high places of the earth: Yahweh, the God of Armies, is his name.\""
            ],
            5: [
                "Listen to this word which I take up for a lamentation over you, O house of Israel.",
                "\"The virgin of Israel has fallen; She shall rise no more. She is cast down on her land; there is no one to raise her up.\"",
                "For thus says the Lord Yahweh: \"The city that went forth a thousand shall have a hundred left, and that which went forth one hundred shall have ten left to the house of Israel.\"",
                "For thus says Yahweh to the house of Israel: \"Seek me, and you will live;",
                "but don\u2019t seek Bethel, nor enter into Gilgal, and don\u2019t pass to Beersheba: for Gilgal shall surely go into captivity, and Bethel shall come to nothing.",
                "Seek Yahweh, and you will live; lest he break out like fire in the house of Joseph, and it devour, and there be no one to quench it in Bethel.",
                "You who turn justice to wormwood, and cast down righteousness to the earth:",
                "seek him who made the Pleiades and Orion, and turns the shadow of death into the morning, and makes the day dark with night; who calls for the waters of the sea, and pours them out on the surface of the earth, Yahweh is his name,",
                "who brings sudden destruction on the strong, so that destruction comes on the fortress.",
                "They hate him who reproves in the gate, and they abhor him who speaks blamelessly.",
                "Forasmuch therefore as you trample on the poor, and take taxes from him of wheat: You have built houses of cut stone, but you will not dwell in them. You have planted pleasant vineyards, but you shall not drink their wine.",
                "For I know how many your offenses, and how great are your sins\u2014you who afflict the just, who take a bribe, and who turn aside the needy in the courts.",
                "Therefore a prudent person keeps silent in such a time, for it is an evil time.",
                "Seek good, and not evil, that you may live; and so Yahweh, the God of Armies, will be with you, as you say.",
                "Hate evil, love good, and establish justice in the courts. It may be that Yahweh, the God of Armies, will be gracious to the remnant of Joseph.\"",
                "Therefore thus says Yahweh, the God of Armies, the Lord: \"Wailing will be in all the broad ways; and they will say in all the streets, \u2018Alas! Alas!\u2019 and they will call the farmer to mourning, and those who are skillful in lamentation to wailing.",
                "In all vineyards there will be wailing; for I will pass through the midst of you,\" says Yahweh.",
                "\"Woe to you who desire the day of Yahweh! Why do you long for the day of Yahweh? It is darkness, and not light.",
                "As if a man fled from a lion, and a bear met him; Or he went into the house and leaned his hand on the wall, and a snake bit him.",
                "Won\u2019t the day of Yahweh be darkness, and not light? Even very dark, and no brightness in it?",
                "I hate, I despise your feasts, and I can\u2019t stand your solemn assemblies.",
                "Yes, though you offer me your burnt offerings and meal offerings, I will not accept them; neither will I regard the peace offerings of your fat animals.",
                "Take away from me the noise of your songs! I will not listen to the music of your harps.",
                "But let justice roll on like rivers, and righteousness like a mighty stream.",
                "\"Did you bring to me sacrifices and offerings in the wilderness forty years, house of Israel?",
                "You also carried the tent of your king and the shrine of your images, the star of your god, which you made for yourselves.",
                "Therefore will I cause you to go into captivity beyond Damascus,\" says Yahweh, whose name is the God of Armies."
            ],
            6: [
                "Woe to those who are at ease in Zion, and to those who are secure on the mountain of Samaria, the notable men of the chief of the nations, to whom the house of Israel come!",
                "Go to Calneh, and see; and from there go to Hamath the great; then go down to Gath of the Philistines. are they better than these kingdoms? or is their border greater than your border?",
                "Those who put far away the evil day, and cause the seat of violence to come near;",
                "Who lie on beds of ivory, and stretch themselves on their couches, and eat the lambs out of the flock, and the calves out of the midst of the stall;",
                "who strum on the strings of a harp; who invent for themselves instruments of music, like David;",
                "who drink wine in bowls, and anoint themselves with the best oils; but they are not grieved for the affliction of Joseph.",
                "Therefore they will now go captive with the first who go captive; and the feasting and lounging will end.",
                "\"The Lord Yahweh has sworn by himself,\" says Yahweh, the God of Armies: \"I abhor the pride of Jacob, and detest his fortresses. Therefore I will deliver up the city with all that is in it.",
                "It will happen, if there remain ten men in one house, that they shall die.",
                "\"When a man\u2019s relative carries him, even he who burns him, to bring bodies out of the house, and asks him who is in the innermost parts of the house, \u2018Is there yet any with you?\u2019 And he says, \u2018No;\u2019 then he will say, \u2018Hush! Indeed we must not mention the name of Yahweh.\u2019",
                "\"For, behold, Yahweh commands, and the great house will be smashed to pieces, and the little house into bits.",
                "Do horses run on the rocky crags? Does one plow there with oxen? But you have turned justice into poison, and the fruit of righteousness into bitterness;",
                "you who rejoice in a thing of nothing, who say, \u2018Haven\u2019t we taken for ourselves horns by our own strength?\u2019",
                "For, behold, I will raise up against you a nation, house of Israel,\" says Yahweh, the God of Armies; \"and they will afflict you from the entrance of Hamath to the brook of the Arabah.\""
            ],
            7: [
                "Thus the Lord Yahweh showed me: and behold, he formed locusts in the beginning of the shooting up of the latter growth; and behold, it was the latter growth after the king\u2019s harvest.",
                "It happened that, when they made an end of eating the grass of the land, then I said, \"Lord Yahweh, forgive, I beg you! How could Jacob stand? For he is small.\"",
                "Yahweh relented concerning this. \"It shall not be,\" says Yahweh.",
                "Thus the Lord Yahweh showed me and behold, the Lord Yahweh called for judgment by fire; and it dried up the great deep, and would have devoured the land.",
                "Then I said, \"Lord Yahweh, stop, I beg you! How could Jacob stand? For he is small.\"",
                "Yahweh relented concerning this. \"This also shall not be,\" says the Lord Yahweh.",
                "Thus he showed me and behold, the Lord stood beside a wall made by a plumb line, with a plumb line in his hand.",
                "Yahweh said to me, \"Amos, what do you see?\" I said, \"A plumb line.\" Then the Lord said, \"Behold, I will set a plumb line in the midst of my people Israel. I will not again pass by them any more.",
                "The high places of Isaac will be desolate, the sanctuaries of Israel will be laid waste; and I will rise against the house of Jeroboam with the sword.\"",
                "Then Amaziah the priest of Bethel sent to Jeroboam king of Israel, saying, \"Amos has conspired against you in the midst of the house of Israel. The land is not able to bear all his words.",
                "For Amos says, \u2018Jeroboam will die by the sword, and Israel shall surely be led away captive out of his land.\u2019\"",
                "Amaziah also said to Amos, \"You seer, go, flee away into the land of Judah, and there eat bread, and prophesy there:",
                "but don\u2019t prophesy again any more at Bethel; for it is the king\u2019s sanctuary, and it is a royal house!\"",
                "Then Amos answered Amaziah, \"I was no prophet, neither was I a prophet\u2019s son; but I was a herdsman, and a farmer of sycamore figs;",
                "and Yahweh took me from following the flock, and Yahweh said to me, \u2018Go, prophesy to my people Israel.\u2019",
                "Now therefore listen to the word of Yahweh: \u2018You say, Don\u2019t prophesy against Israel, and don\u2019t preach against the house of Isaac.\u2019",
                "Therefore thus says Yahweh: \u2018Your wife shall be a prostitute in the city, and your sons and your daughters shall fall by the sword, and your land shall be divided by line; and you yourself shall die in a land that is unclean, and Israel shall surely be led away captive out of his land.\u2019\""
            ],
            8: [
                "Thus the Lord Yahweh showed me: behold, a basket of summer fruit.",
                "He said, \"Amos, what do you see?\" I said, \"A basket of summer fruit.\" Then Yahweh said to me, \"The end has come on my people Israel. I will not again pass by them any more.",
                "The songs of the temple will be wailings in that day,\" says the Lord Yahweh. \"The dead bodies will be many. In every place they will throw them out with silence.",
                "Hear this, you who desire to swallow up the needy, and cause the poor of the land to fail,",
                "Saying, \u2018When will the new moon be gone, that we may sell grain? And the Sabbath, that we may market wheat, making the ephah small, and the shekel large, and dealing falsely with balances of deceit;",
                "that we may buy the poor for silver, and the needy for a pair of shoes, and sell the sweepings with the wheat?\u2019\"",
                "Yahweh has sworn by the pride of Jacob, \"Surely I will never forget any of their works.",
                "Won\u2019t the land tremble for this, and everyone mourn who dwells in it? Yes, it will rise up wholly like the River; and it will be stirred up and sink again, like the River of Egypt.",
                "It will happen in that day,\" says the Lord Yahweh, \"that I will cause the sun to go down at noon, and I will darken the earth in the clear day.",
                "I will turn your feasts into mourning, and all your songs into lamentation; and I will make you wear sackcloth on all your bodies, and baldness on every head. I will make it like the mourning for an only son, and its end like a bitter day.",
                "Behold, the days come,\" says the Lord Yahweh, \"that I will send a famine in the land, not a famine of bread, nor a thirst for water, but of hearing the words of Yahweh.",
                "They will wander from sea to sea, and from the north even to the east; they will run back and forth to seek the word of Yahweh, and will not find it.",
                "In that day the beautiful virgins and the young men will faint for thirst.",
                "Those who swear by the sin of Samaria, and say, \u2018As your god, Dan, lives;\u2019 and, \u2018As the way of Beersheba lives;\u2019 they will fall, and never rise up again.\""
            ],
            9: [
                "I saw the Lord standing beside the altar, and he said, \"Strike the tops of the pillars, that the thresholds may shake; and break them in pieces on the head of all of them; and I will kill the last of them with the sword: there shall not one of them flee away, and there shall not one of them escape.",
                "Though they dig into Sheol, there my hand will take them; and though they climb up to heaven, there I will bring them down.",
                "Though they hide themselves in the top of Carmel, I will search and take them out there; and though they be hidden from my sight in the bottom of the sea, there I will command the serpent, and it will bite them.",
                "Though they go into captivity before their enemies, there I will command the sword, and it will kill them. I will set my eyes on them for evil, and not for good.",
                "For the Lord, Yahweh of Armies, is he who touches the land and it melts, and all who dwell in it will mourn; and it will rise up wholly like the River, and will sink again, like the River of Egypt.",
                "It is he who builds his rooms in the heavens, and has founded his vault on the earth; he who calls for the waters of the sea, and pours them out on the surface of the earth; Yahweh is his name.",
                "Are you not like the children of the Ethiopians to me, children of Israel?\" says Yahweh. \"Haven\u2019t I brought up Israel out of the land of Egypt, and the Philistines from Caphtor, and the Syrians from Kir?",
                "Behold, the eyes of the Lord Yahweh are on the sinful kingdom, and I will destroy it from off the surface of the earth; except that I will not utterly destroy the house of Jacob,\" says Yahweh.",
                "\"For, behold, I will command, and I will sift the house of Israel among all the nations, as grain is sifted in a sieve, yet not the least kernel will fall on the earth.",
                "All the sinners of my people will die by the sword, who say, \u2018Evil won\u2019t overtake nor meet us.\u2019",
                "In that day I will raise up the tent of David who is fallen, and close up its breaches, and I will raise up its ruins, and I will build it as in the days of old;",
                "that they may possess the remnant of Edom, and all the nations who are called by my name,\" says Yahweh who does this.",
                "\"Behold, the days come,\" says Yahweh, \"that the plowman shall overtake the reaper, and the one treading grapes him who sows seed; and sweet wine will drip from the mountains, and flow from the hills.",
                "I will bring my people Israel back from captivity, and they will rebuild the ruined cities, and inhabit them; and they will plant vineyards, and drink wine from them. They shall also make gardens, and eat their fruit.",
                "I will plant them on their land, and they will no more be plucked up out of their land which I have given them,\" says Yahweh your God."
            ]
        },
        31: {
            1: [
                "The vision of Obadiah. This is what the Lord Yahweh says about Edom. We have heard news from Yahweh, and an ambassador is sent among the nations, saying, \"Arise, and let\u2019s rise up against her in battle.",
                "Behold, I have made you small among the nations. You are greatly despised.",
                "The pride of your heart has deceived you, you who dwell in the clefts of the rock, whose habitation is high, who says in his heart, \u2018Who will bring me down to the ground?\u2019",
                "Though you mount on high as the eagle, and though your nest is set among the stars, I will bring you down from there,\" says Yahweh.",
                "\"If thieves came to you, if robbers by night\u2014oh, what disaster awaits you\u2014wouldn\u2019t they only steal until they had enough? If grape pickers came to you, wouldn\u2019t they leave some gleaning grapes?",
                "How Esau will be ransacked! How his hidden treasures are sought out!",
                "All the men of your alliance have brought you on your way, even to the border. The men who were at peace with you have deceived you, and prevailed against you. Friends who eat your bread lay a snare under you. There is no understanding in him.\"",
                "\"Won\u2019t I in that day,\" says Yahweh, \"destroy the wise men out of Edom, and understanding out of the mountain of Esau?",
                "Your mighty men, Teman, will be dismayed, to the end that everyone may be cut off from the mountain of Esau by slaughter.",
                "For the violence done to your brother Jacob, shame will cover you, and you will be cut off forever.",
                "In the day that you stood on the other side, in the day that strangers carried away his substance, and foreigners entered into his gates, and cast lots for Jerusalem, even you were like one of them.",
                "But don\u2019t look down on your brother in the day of his disaster, and don\u2019t rejoice over the children of Judah in the day of their destruction. Don\u2019t speak proudly in the day of distress.",
                "Don\u2019t enter into the gate of my people in the day of their calamity. Don\u2019t look down on their affliction in the day of their calamity, neither seize their wealth on the day of their calamity.",
                "Don\u2019t stand in the crossroads to cut off those of his who escape. Don\u2019t deliver up those of his who remain in the day of distress.",
                "For the day of Yahweh is near all the nations! As you have done, it will be done to you. Your deeds will return upon your own head.",
                "For as you have drunk on my holy mountain, so will all the nations drink continually. Yes, they will drink, swallow down, and will be as though they had not been.",
                "But in Mount Zion, there will be those who escape, and it will be holy. The house of Jacob will possess their possessions.",
                "The house of Jacob will be a fire, the house of Joseph a flame, and the house of Esau for stubble. They will burn among them, and devour them. There will not be any remaining to the house of Esau.\" Indeed, Yahweh has spoken.",
                "Those of the South will possess the mountain of Esau, and those of the lowland, the Philistines. They will possess the field of Ephraim, and the field of Samaria. Benjamin will possess Gilead.",
                "The captives of this army of the children of Israel, who are among the Canaanites, will possess even to Zarephath; and the captives of Jerusalem, who are in Sepharad, will possess the cities of the Negev.",
                "Saviors will go up on Mount Zion to judge the mountains of Esau, and the kingdom will be Yahweh\u2019s."
            ]
        },
        32: {
            1: [
                "Now the word of Yahweh came to Jonah the son of Amittai, saying,",
                "\"Arise, go to Nineveh, that great city, and preach against it, for their wickedness has come up before me.\"",
                "But Jonah rose up to flee to Tarshish from the presence of Yahweh. He went down to Joppa, and found a ship going to Tarshish; so he paid its fare, and went down into it, to go with them to Tarshish from the presence of Yahweh.",
                "But Yahweh sent out a great wind on the sea, and there was a mighty storm on the sea, so that the ship was likely to break up.",
                "Then the mariners were afraid, and every man cried to his god. They threw the cargo that was in the ship into the sea to lighten the ship. But Jonah had gone down into the innermost parts of the ship, and he was laying down, and was fast asleep.",
                "So the shipmaster came to him, and said to him, \"What do you mean, sleeper? Arise, call on your God! Maybe your God will notice us, so that we won\u2019t perish.\"",
                "They all said to each other, \"Come, let us cast lots, that we may know who is responsible for this evil that is on us.\" So they cast lots, and the lot fell on Jonah.",
                "Then they asked him, \"Tell us, please, for whose cause this evil is on us. What is your occupation? Where do you come from? What is your country? Of what people are you?\"",
                "He said to them, \"I am a Hebrew, and I fear Yahweh, the God of heaven, who has made the sea and the dry land.\"",
                "Then were the men exceedingly afraid, and said to him, \"What is this that you have done?\" For the men knew that he was fleeing from the presence of Yahweh, because he had told them.",
                "Then they said to him, \"What shall we do to you, that the sea may be calm to us?\" For the sea grew more and more stormy.",
                "He said to them, \"Take me up, and throw me into the sea. Then the sea will be calm for you; for I know that because of me this great storm is on you.\"",
                "Nevertheless the men rowed hard to get them back to the land; but they could not, for the sea grew more and more stormy against them.",
                "Therefore they cried to Yahweh, and said, \"We beg you, Yahweh, we beg you, don\u2019t let us die for this man\u2019s life, and don\u2019t lay on us innocent blood; for you, Yahweh, have done as it pleased you.\"",
                "So they took up Jonah, and threw him into the sea; and the sea ceased its raging.",
                "Then the men feared Yahweh exceedingly; and they offered a sacrifice to Yahweh, and made vows.",
                "Yahweh prepared a great fish to swallow up Jonah, and Jonah was in the belly of the fish three days and three nights."
            ],
            2: [
                "Then Jonah prayed to Yahweh, his God, out of the fish\u2019s belly.",
                "He said, \"I called because of my affliction to Yahweh. He answered me. Out of the belly of Sheol I cried. You heard my voice.",
                "For you threw me into the depths, in the heart of the seas. The flood was all around me. All your waves and your billows passed over me.",
                "I said, \u2018I have been banished from your sight; yet I will look again toward your holy temple.\u2019",
                "The waters surrounded me, even to the soul. The deep was around me. The weeds were wrapped around my head.",
                "I went down to the bottoms of the mountains. The earth barred me in forever: yet have you brought up my life from the pit, Yahweh my God.",
                "\"When my soul fainted within me, I remembered Yahweh. My prayer came in to you, into your holy temple.",
                "Those who regard lying vanities forsake their own mercy.",
                "But I will sacrifice to you with the voice of thanksgiving. I will pay that which I have vowed. Salvation belongs to Yahweh.\"",
                "Yahweh spoke to the fish, and it vomited out Jonah on the dry land."
            ],
            3: [
                "The word of Yahweh came to Jonah the second time, saying,",
                "\"Arise, go to Nineveh, that great city, and preach to it the message that I give you.\"",
                "So Jonah arose, and went to Nineveh, according to the word of Yahweh. Now Nineveh was an exceedingly great city, three days\u2019 journey across.",
                "Jonah began to enter into the city a day\u2019s journey, and he cried out, and said, \"In forty days, Nineveh will be overthrown!\"",
                "The people of Nineveh believed God; and they proclaimed a fast, and put on sackcloth, from their greatest even to their least.",
                "The news reached the king of Nineveh, and he arose from his throne, and took off his royal robe, covered himself with sackcloth, and sat in ashes.",
                "He made a proclamation and published through Nineveh by the decree of the king and his nobles, saying, \"Let neither man nor animal, herd nor flock, taste anything; let them not feed, nor drink water;",
                "but let them be covered with sackcloth, both man and animal, and let them cry mightily to God. Yes, let them turn everyone from his evil way, and from the violence that is in his hands.",
                "Who knows whether God will not turn and relent, and turn away from his fierce anger, so that we might not perish?\"",
                "God saw their works, that they turned from their evil way. God relented of the disaster which he said he would do to them, and he didn\u2019t do it."
            ],
            4: [
                "But it displeased Jonah exceedingly, and he was angry.",
                "He prayed to Yahweh, and said, \"Please, Yahweh, wasn\u2019t this what I said when I was still in my own country? Therefore I hurried to flee to Tarshish, for I knew that you are a gracious God, and merciful, slow to anger, and abundant in loving kindness, and you relent of doing harm.",
                "Therefore now, Yahweh, take, I beg you, my life from me; for it is better for me to die than to live.\"",
                "Yahweh said, \"Is it right for you to be angry?\"",
                "Then Jonah went out of the city, and sat on the east side of the city, and there made himself a booth, and sat under it in the shade, until he might see what would become of the city.",
                "Yahweh God prepared a vine, and made it to come up over Jonah, that it might be a shade over his head, to deliver him from his discomfort. So Jonah was exceedingly glad because of the vine.",
                "But God prepared a worm at dawn the next day, and it chewed on the vine, so that it withered.",
                "It happened, when the sun arose, that God prepared a sultry east wind; and the sun beat on Jonah\u2019s head, so that he fainted, and requested for himself that he might die, and said, \"It is better for me to die than to live.\"",
                "God said to Jonah, \"Is it right for you to be angry about the vine?\" He said, \"I am right to be angry, even to death.\"",
                "Yahweh said, \"You have been concerned for the vine, for which you have not labored, neither made it grow; which came up in a night, and perished in a night.",
                "Shouldn\u2019t I be concerned for Nineveh, that great city, in which are more than one hundred twenty thousand persons who can\u2019t discern between their right hand and their left hand; and also much livestock?\""
            ]
        },
        33: {
            1: [
                "The word of Yahweh that came to Micah the Morashtite in the days of Jotham, Ahaz, and Hezekiah, kings of Judah, which he saw concerning Samaria and Jerusalem.",
                "Hear, you peoples, all of you. Listen, O earth, and all that is therein: and let the Lord Yahweh be witness against you, the Lord from his holy temple.",
                "For, behold, Yahweh comes forth out of his place, and will come down and tread on the high places of the earth.",
                "The mountains melt under him, and the valleys split apart, like wax before the fire, like waters that are poured down a steep place.",
                "\"All this is for the disobedience of Jacob, and for the sins of the house of Israel. What is the disobedience of Jacob? Isn\u2019t it Samaria? And what are the high places of Judah? Aren\u2019t they Jerusalem?",
                "Therefore I will make Samaria like a rubble heap of the field, like places for planting vineyards; and I will pour down its stones into the valley, and I will uncover its foundations.",
                "All her idols will be beaten to pieces, and all her temple gifts will be burned with fire, and all her images I will destroy; for of the hire of a prostitute has she gathered them, and to the hire of a prostitute shall they return.\"",
                "For this I will lament and wail; I will go stripped and naked; I will howl like the jackals, and moan like the daughters of owls.",
                "For her wounds are incurable; for it has come even to Judah. It reaches to the gate of my people, even to Jerusalem.",
                "Don\u2019t tell it in Gath. Don\u2019t weep at all. At Beth Ophrah I have rolled myself in the dust.",
                "Pass on, inhabitant of Shaphir, in nakedness and shame. The inhabitant of Zaanan won\u2019t come out. The wailing of Beth Ezel will take from you his protection.",
                "For the inhabitant of Maroth waits anxiously for good, because evil has come down from Yahweh to the gate of Jerusalem.",
                "Harness the chariot to the swift steed, inhabitant of Lachish. She was the beginning of sin to the daughter of Zion; For the transgressions of Israel were found in you.",
                "Therefore you will give a parting gift to Moresheth Gath. The houses of Achzib will be a deceitful thing to the kings of Israel.",
                "I will yet bring to you, inhabitant of Mareshah. He who is the glory of Israel will come to Adullam.",
                "Shave your heads, and cut off your hair for the children of your delight. Enlarge your baldness like the vulture; for they have gone into captivity from you!"
            ],
            2: [
                "Woe to those who devise iniquity and work evil on their beds! When the morning is light, they practice it, because it is in the power of their hand.",
                "They covet fields, and seize them; and houses, and take them away: and they oppress a man and his house, even a man and his heritage.",
                "Therefore thus says Yahweh: \"Behold, I am planning against these people a disaster, from which you will not remove your necks, neither will you walk haughtily; for it is an evil time.",
                "In that day they will take up a parable against you, and lament with a doleful lamentation, saying, \u2018We are utterly ruined! My people\u2019s possession is divided up. Indeed he takes it from me and assigns our fields to traitors!\u2019\"",
                "Therefore you will have no one who divides the land by lot in the assembly of Yahweh.",
                "\"Don\u2019t prophesy!\" They prophesy. \"Don\u2019t prophesy about these things. Disgrace won\u2019t overtake us.\"",
                "Shall it be said, O house of Jacob: \"Is the Spirit of Yahweh angry? Are these his doings? Don\u2019t my words do good to him who walks blamelessly?\"",
                "But lately my people have risen up as an enemy. You strip the robe and clothing from those who pass by without a care, returning from battle.",
                "You drive the women of my people out from their pleasant houses; from their young children you take away my blessing forever.",
                "Arise, and depart! For this is not your resting place, because of uncleanness that destroys, even with a grievous destruction.",
                "If a man walking in a spirit of falsehood lies: \"I will prophesy to you of wine and of strong drink;\" he would be the prophet of this people.",
                "I will surely assemble, Jacob, all of you; I will surely gather the remnant of Israel; I will put them together as the sheep of Bozrah, as a flock in the midst of their pasture; they will swarm with people.",
                "He who breaks open the way goes up before them. They break through the gate, and go out. And their king passes on before them, with Yahweh at their head."
            ],
            3: [
                "I said, \"Please listen, you heads of Jacob, and rulers of the house of Israel: Isn\u2019t it for you to know justice?",
                "You who hate the good, and love the evil; who tear off their skin, and their flesh from off their bones;",
                "who also eat the flesh of my people, and flay their skin from off them, and break their bones, and chop them in pieces, as for the pot, and as flesh within the caldron.",
                "Then they will cry to Yahweh, but he will not answer them. Yes, he will hide his face from them at that time, because they made their deeds evil.\"",
                "Thus says Yahweh concerning the prophets who lead my people astray; for those who feed their teeth, they proclaim, \"Peace!\" and whoever doesn\u2019t provide for their mouths, they prepare war against him:",
                "\"Therefore night is over you, with no vision, and it is dark to you, that you may not divine; and the sun will go down on the prophets, and the day will be black over them.",
                "The seers shall be disappointed, and the diviners confounded. Yes, they shall all cover their lips; for there is no answer from God.\"",
                "But as for me, I am full of power by the Spirit of Yahweh, and of judgment, and of might, to declare to Jacob his disobedience, and to Israel his sin.",
                "Please listen to this, you heads of the house of Jacob, and rulers of the house of Israel, who abhor justice, and pervert all equity.",
                "They build up Zion with blood, and Jerusalem with iniquity.",
                "Her leaders judge for bribes, and her priests teach for a price, and her prophets of it tell fortunes for money: yet they lean on Yahweh, and say, \"Isn\u2019t Yahweh in the midst of us? No disaster will come on us.\"",
                "Therefore Zion for your sake will be plowed like a field, and Jerusalem will become heaps of rubble, and the mountain of the temple like the high places of a forest."
            ],
            4: [
                "But in the latter days, it will happen that the mountain of Yahweh\u2019s temple will be established on the top of the mountains, and it will be exalted above the hills; and peoples will stream to it.",
                "Many nations will go and say, \"Come, and let us go up to the mountain of Yahweh, and to the house of the God of Jacob; and he will teach us of his ways, and we will walk in his paths.\" For out of Zion will go forth the law, and the word of Yahweh from Jerusalem;",
                "and he will judge between many peoples, and will decide concerning strong nations afar off. They will beat their swords into plowshares, and their spears into pruning hooks. Nation will not lift up sword against nation, neither will they learn war any more.",
                "But they will sit every man under his vine and under his fig tree; and no one will make them afraid: For the mouth of Yahweh of Armies has spoken.",
                "Indeed all the nations may walk in the name of their gods; but we will walk in the name of Yahweh our God forever and ever.",
                "\"In that day,\" says Yahweh, \"I will assemble that which is lame, and I will gather that which is driven away, and that which I have afflicted;",
                "and I will make that which was lame a remnant, and that which was cast far off a strong nation: and Yahweh will reign over them on Mount Zion from then on, even forever.\"",
                "You, tower of the flock, the hill of the daughter of Zion, to you it will come, yes, the former dominion will come, the kingdom of the daughter of Jerusalem.",
                "Now why do you cry out aloud? Is there no king in you? Has your counselor perished, that pains have taken hold of you as of a woman in travail?",
                "Be in pain, and labor to bring forth, daughter of Zion, like a woman in travail; for now you will go forth out of the city, and will dwell in the field, and will come even to Babylon. There you will be rescued. There Yahweh will redeem you from the hand of your enemies.",
                "Now many nations have assembled against you, that say, \"Let her be defiled, and let our eye gloat over Zion.\"",
                "But they don\u2019t know the thoughts of Yahweh, neither do they understand his counsel; for he has gathered them like the sheaves to the threshing floor.",
                "Arise and thresh, daughter of Zion; for I will make your horn iron, and I will make your hoofs brass; and you will beat in pieces many peoples: and I will devote their gain to Yahweh, and their substance to the Lord of the whole earth."
            ],
            5: [
                "Now you shall gather yourself in troops, daughter of troops. He has laid siege against us. They will strike the judge of Israel with a rod on the cheek.",
                "But you, Bethlehem Ephrathah, being small among the clans of Judah, out of you one will come forth to me that is to be ruler in Israel; whose goings forth are from of old, from everlasting.",
                "Therefore he will abandon them until the time that she who is in labor gives birth. Then the rest of his brothers will return to the children of Israel.",
                "He shall stand, and shall shepherd in the strength of Yahweh, in the majesty of the name of Yahweh his God: and they will live, for then he will be great to the ends of the earth.",
                "He will be our peace when Assyria invades our land, and when he marches through our fortresses, then we will raise against him seven shepherds, and eight leaders of men.",
                "They will rule the land of Assyria with the sword, and the land of Nimrod in its gates. He will deliver us from the Assyrian, when he invades our land, and when he marches within our border.",
                "The remnant of Jacob will be in the midst of many peoples, like dew from Yahweh, like showers on the grass, that don\u2019t wait for man, nor wait for the sons of men.",
                "The remnant of Jacob will be among the nations, in the midst of many peoples, like a lion among the animals of the forest, like a young lion among the flocks of sheep; who, if he goes through, treads down and tears in pieces, and there is no one to deliver.",
                "Let your hand be lifted up above your adversaries, and let all of your enemies be cut off.",
                "\"It will happen in that day,\" says Yahweh, \"That I will cut off your horses out of the midst of you, and will destroy your chariots.",
                "I will cut off the cities of your land, and will tear down all your strongholds.",
                "I will destroy witchcraft from your hand; and you shall have no soothsayers.",
                "I will cut off your engraved images and your pillars out of your midst; and you shall no more worship the work of your hands.",
                "I will uproot your Asherim out of your midst; and I will destroy your cities.",
                "I will execute vengeance in anger, and wrath on the nations that didn\u2019t listen.\""
            ],
            6: [
                "Listen now to what Yahweh says: \"Arise, plead your case before the mountains, and let the hills hear what you have to say.",
                "Hear, you mountains, Yahweh\u2019s controversy, and you enduring foundations of the earth; for Yahweh has a controversy with his people, and he will contend with Israel.",
                "My people, what have I done to you? How have I burdened you? Answer me!",
                "For I brought you up out of the land of Egypt, and redeemed you out of the house of bondage. I sent before you Moses, Aaron, and Miriam.",
                "My people, remember now what Balak king of Moab devised, and what Balaam the son of Beor answered him from Shittim to Gilgal, that you may know the righteous acts of Yahweh.\"",
                "How shall I come before Yahweh, and bow myself before the exalted God? Shall I come before him with burnt offerings, with calves a year old?",
                "Will Yahweh be pleased with thousands of rams? With tens of thousands of rivers of oil? Shall I give my firstborn for my disobedience? The fruit of my body for the sin of my soul?",
                "He has shown you, O man, what is good. What does Yahweh require of you, but to act justly, to love mercy, and to walk humbly with your God?",
                "Yahweh\u2019s voice calls to the city, and wisdom sees your name: \"Listen to the rod, and he who appointed it.",
                "Are there yet treasures of wickedness in the house of the wicked, and a short ephah that is accursed?",
                "Shall I be pure with dishonest scales, and with a bag of deceitful weights?",
                "Her rich men are full of violence, her inhabitants speak lies, and their tongue is deceitful in their speech.",
                "Therefore I also have struck you with a grievous wound. I have made you desolate because of your sins.",
                "You shall eat, but not be satisfied. Your humiliation will be in your midst. You will store up, but not save; and that which you save I will give up to the sword.",
                "You will sow, but won\u2019t reap. You will tread the olives, but won\u2019t anoint yourself with oil; and crush grapes, but won\u2019t drink the wine.",
                "For the statutes of Omri are kept, and all the works of the house of Ahab. You walk in their counsels, that I may make you a ruin, and her inhabitants a hissing; And you will bear the reproach of my people.\""
            ],
            7: [
                "Misery is mine! Indeed, I am like one who gathers the summer fruits, as gleanings of the vineyard: There is no cluster of grapes to eat. My soul desires to eat the early fig.",
                "The godly man has perished out of the earth, and there is no one upright among men. They all lie in wait for blood; every man hunts his brother with a net.",
                "Their hands are on that which is evil to do it diligently. The ruler and judge ask for a bribe; and the powerful man dictates the evil desire of his soul. Thus they conspire together.",
                "The best of them is like a brier. The most upright is worse than a thorn hedge. The day of your watchmen, even your visitation, has come; now is the time of their confusion.",
                "Don\u2019t trust in a neighbor. Don\u2019t put confidence in a friend. With the woman lying in your embrace, be careful of the words of your mouth!",
                "For the son dishonors the father, the daughter rises up against her mother, the daughter-in-law against her mother-in-law; a man\u2019s enemies are the men of his own house.",
                "But as for me, I will look to Yahweh. I will wait for the God of my salvation. My God will hear me.",
                "Don\u2019t rejoice against me, my enemy. When I fall, I will arise. When I sit in darkness, Yahweh will be a light to me.",
                "I will bear the indignation of Yahweh, because I have sinned against him, until he pleads my case, and executes judgment for me. He will bring me forth to the light. I will see his righteousness.",
                "Then my enemy will see it, and shame will cover her who said to me, where is Yahweh your God? Then my enemy will see me and will cover her shame. Now she will be trodden down like the mire of the streets.",
                "A day to build your walls\u2014In that day, he will extend your boundary.",
                "In that day they will come to you from Assyria and the cities of Egypt, and from Egypt even to the River, and from sea to sea, and mountain to mountain.",
                "Yet the land will be desolate because of those who dwell therein, for the fruit of their doings.",
                "Shepherd your people with your staff, the flock of your heritage, who dwell by themselves in a forest, in the midst of fertile pasture land, let them feed; in Bashan and Gilead, as in the days of old.",
                "\"As in the days of your coming forth out of the land of Egypt, I will show them marvelous things.\"",
                "The nations will see and be ashamed of all their might. They will lay their hand on their mouth. Their ears will be deaf.",
                "They will lick the dust like a serpent. Like crawling things of the earth they shall come trembling out of their dens. They will come with fear to Yahweh our God, and will be afraid because of you.",
                "Who is a God like you, who pardons iniquity, and passes over the disobedience of the remnant of his heritage? He doesn\u2019t retain his anger forever, because he delights in loving kindness.",
                "He will again have compassion on us. He will tread our iniquities under foot; and you will cast all their sins into the depths of the sea.",
                "You will give truth to Jacob, and mercy to Abraham, as you have sworn to our fathers from the days of old."
            ]
        },
        34: {
            1: [
                "An oracle about Nineveh. The book of the vision of Nahum the Elkoshite.",
                "Yahweh is a jealous God and avenges. Yahweh avenges and is full of wrath. Yahweh takes vengeance on his adversaries, and he maintains wrath against his enemies.",
                "Yahweh is slow to anger, and great in power, and will by no means leave the guilty unpunished. Yahweh has his way in the whirlwind and in the storm, and the clouds are the dust of his feet.",
                "He rebukes the sea, and makes it dry, and dries up all the rivers. Bashan languishes, and Carmel; and the flower of Lebanon languishes.",
                "The mountains quake before him, and the hills melt away. The earth trembles at his presence, yes, the world, and all who dwell in it.",
                "Who can stand before his indignation? Who can endure the fierceness of his anger? His wrath is poured out like fire, and the rocks are broken apart by him.",
                "Yahweh is good, a stronghold in the day of trouble; and he knows those who take refuge in him.",
                "But with an overflowing flood, he will make a full end of her place, and will pursue his enemies into darkness.",
                "What do you plot against Yahweh? He will make a full end. Affliction won\u2019t rise up the second time.",
                "For entangled like thorns, and drunken as with their drink, they are consumed utterly like dry stubble.",
                "There is one gone forth out of you, who devises evil against Yahweh, who counsels wickedness.",
                "Thus says Yahweh: \"Though they be in full strength, and likewise many, even so they will be cut down, and he shall pass away. Though I have afflicted you, I will afflict you no more.",
                "Now will I break his yoke from off you, and will burst your bonds apart.\"",
                "Yahweh has commanded concerning you: \"No more descendants will bear your name. Out of the house of your gods, will I cut off the engraved image and the molten image. I will make your grave, for you are vile.\"",
                "Behold, on the mountains the feet of him who brings good news, who publishes peace! Keep your feasts, Judah! Perform your vows, for the wicked one will no more pass through you. He is utterly cut off."
            ],
            2: [
                "He who dashes in pieces has come up against you. Keep the fortress! Watch the way! Strengthen your waist! Fortify your power mightily!",
                "For Yahweh restores the excellency of Jacob, as the excellency of Israel; for the destroyers have destroyed them, and ruined their vine branches.",
                "The shield of his mighty men is made red. The valiant men are in scarlet. The chariots flash with steel in the day of his preparation, and the pine spears are brandished.",
                "The chariots rage in the streets. They rush back and forth in the broad ways. Their appearance is like torches. They run like the lightnings.",
                "He summons his picked troops. They stumble on their way. They dash to its wall, and the protective shield is put in place.",
                "The gates of the rivers are opened, and the palace is dissolved.",
                "It is decreed: she is uncovered, she is carried away; and her handmaids moan as with the voice of doves, beating on their breasts.",
                "But Nineveh has been from of old like a pool of water, yet they flee away. \"Stop! Stop!\" they cry, but no one looks back.",
                "Take the spoil of silver. Take the spoil of gold, for there is no end of the store, the glory of all goodly furniture.",
                "She is empty, void, and waste. The heart melts, the knees knock together, their bodies and faces have grown pale.",
                "Where is the den of the lions, and the feeding place of the young lions, where the lion and the lioness walked, the lion\u2019s cubs, and no one made them afraid?",
                "The lion tore in pieces enough for his cubs, and strangled for his lionesses, and filled his caves with the kill, and his dens with prey.",
                "\"Behold, I am against you,\" says Yahweh of Armies, \"and I will burn her chariots in the smoke, and the sword will devour your young lions; and I will cut off your prey from the earth, and the voice of your messengers will no longer be heard.\""
            ],
            3: [
                "Woe to the bloody city! It is all full of lies and robbery. The prey doesn\u2019t depart.",
                "The noise of the whip, the noise of the rattling of wheels, prancing horses, and bounding chariots,",
                "the horseman mounting, and the flashing sword, the glittering spear, and a multitude of slain, and a great heap of corpses, and there is no end of the bodies. They stumble on their bodies,",
                "because of the multitude of the prostitution of the alluring prostitute, the mistress of witchcraft, who sells nations through her prostitution, and families through her witchcraft.",
                "\"Behold, I am against you,\" says Yahweh of Armies, \"and I will lift your skirts over your face. I will show the nations your nakedness, and the kingdoms your shame.",
                "I will throw abominable filth on you, and make you vile, and will set you a spectacle.",
                "It will happen that all those who look at you will flee from you, and say, \u2018Nineveh is laid waste! Who will mourn for her?\u2019 Where will I seek comforters for you?\"",
                "Are you better than No-Amon, who was situated among the rivers, who had the waters around her; whose rampart was the sea, and her wall was of the sea?",
                "Cush and Egypt were her boundless strength. Put and Libya were her helpers.",
                "Yet was she carried away. She went into captivity. Her young children also were dashed in pieces at the head of all the streets, and they cast lots for her honorable men, and all her great men were bound in chains.",
                "You also will be drunken. You will be hidden. You also will seek a stronghold because of the enemy.",
                "All your fortresses will be like fig trees with the first-ripe figs: if they are shaken, they fall into the mouth of the eater.",
                "Behold, your troops in your midst are women. The gates of your land are set wide open to your enemies. The fire has devoured your bars.",
                "Draw water for the siege. Strengthen your fortresses. Go into the clay, and tread the mortar. Make the brick kiln strong.",
                "There the fire will devour you. The sword will cut you off. It will devour you like the grasshopper. Multiply like grasshoppers. Multiply like the locust.",
                "You have increased your merchants more than the stars of the skies. The grasshopper strips, and flees away.",
                "Your guards are like the locusts, and your officials like the swarms of locusts, which settle on the walls on a cold day, but when the sun appears, they flee away, and their place is not known where they are.",
                "Your shepherds slumber, king of Assyria. Your nobles lie down. Your people are scattered on the mountains, and there is no one to gather them.",
                "There is no healing your wound, for your injury is fatal. All who hear the report of you clap their hands over you; for who hasn\u2019t felt your endless cruelty?"
            ]
        },
        35: {
            1: [
                "The oracle which Habakkuk the prophet saw.",
                "Yahweh, how long will I cry, and you will not hear? I cry out to you \"Violence!\" and will you not save?",
                "Why do you show me iniquity, and look at perversity? For destruction and violence are before me. There is strife, and contention rises up.",
                "Therefore the law is paralyzed, and justice never goes forth; for the wicked surround the righteous; therefore justice goes forth perverted.",
                "\"Look among the nations, watch, and wonder marvelously; for I am working a work in your days, which you will not believe though it is told you.",
                "For, behold, I raise up the Chaldeans, that bitter and hasty nation, that march through the breadth of the earth, to possess dwelling places that are not theirs.",
                "They are feared and dreaded. Their judgment and their dignity proceed from themselves.",
                "Their horses also are swifter than leopards, and are more fierce than the evening wolves. Their horsemen press proudly on. Yes, their horsemen come from afar. They fly as an eagle that hurries to devour.",
                "All of them come for violence. Their hordes face the desert. He gathers prisoners like sand.",
                "Yes, he scoffs at kings, and princes are a derision to him. He laughs at every stronghold, for he builds up an earthen ramp, and takes it.",
                "Then he sweeps by like the wind, and goes on. He is indeed guilty, whose strength is his god.\"",
                "Aren\u2019t you from everlasting, Yahweh my God, my Holy One? We will not die. Yahweh, you have appointed him for judgment. You, Rock, have established him to punish.",
                "You who have purer eyes than to see evil, and who cannot look on perversity, why do you tolerate those who deal treacherously, and keep silent when the wicked swallows up the man who is more righteous than he,",
                "and make men like the fish of the sea, like the creeping things, that have no ruler over them?",
                "He takes up all of them with the hook. He catches them in his net, and gathers them in his dragnet. Therefore he rejoices and is glad.",
                "Therefore he sacrifices to his net, and burns incense to his dragnet, because by them his life is luxurious, and his food is good.",
                "Will he therefore continually empty his net, and kill the nations without mercy?"
            ],
            2: [
                "I will stand at my watch, and set myself on the ramparts, and will look out to see what he will say to me, and what I will answer concerning my complaint.",
                "Yahweh answered me, \"Write the vision, and make it plain on tablets, that he who runs may read it.",
                "For the vision is yet for the appointed time, and it hurries toward the end, and won\u2019t prove false. Though it takes time, wait for it; because it will surely come. It won\u2019t delay.",
                "Behold, his soul is puffed up. It is not upright in him, but the righteous will live by his faith.",
                "Yes, moreover, wine is treacherous. A haughty man who doesn\u2019t stay at home, who enlarges his desire as Sheol, and he is like death, and can\u2019t be satisfied, but gathers to himself all nations, and heaps to himself all peoples.",
                "Won\u2019t all these take up a parable against him, and a taunting proverb against him, and say, \u2018Woe to him who increases that which is not his, and who enriches himself by extortion! How long?\u2019",
                "Won\u2019t your debtors rise up suddenly, and wake up those who make you tremble, and you will be their victim?",
                "Because you have plundered many nations, all the remnant of the peoples will plunder you, because of men\u2019s blood, and for the violence done to the land, to the city and to all who dwell in it.",
                "Woe to him who gets an evil gain for his house, that he may set his nest on high, that he may be delivered from the hand of evil!",
                "You have devised shame to your house, by cutting off many peoples, and have sinned against your soul.",
                "For the stone will cry out of the wall, and the beam out of the woodwork will answer it.",
                "Woe to him who builds a town with blood, and establishes a city by iniquity!",
                "Behold, isn\u2019t it of Yahweh of Armies that the peoples labor for the fire, and the nations weary themselves for vanity?",
                "For the earth will be filled with the knowledge of the glory of Yahweh, as the waters cover the sea.",
                "\"Woe to him who gives his neighbor drink, pouring your inflaming wine until they are drunk, so that you may gaze at their naked bodies!",
                "You are filled with shame, and not glory. You will also drink, and be exposed! The cup of Yahweh\u2019s right hand will come around to you, and disgrace will cover your glory.",
                "For the violence done to Lebanon will overwhelm you, and the destruction of the animals, which made them afraid; because of men\u2019s blood, and for the violence done to the land, to every city and to those who dwell in them.",
                "\"What value does the engraved image have, that its maker has engraved it; the molten image, even the teacher of lies, that he who fashions its form trusts in it, to make mute idols?",
                "Woe to him who says to the wood, \u2018Awake!\u2019 or to the mute stone, \u2018Arise!\u2019 Shall this teach? Behold, it is overlaid with gold and silver, and there is no breath at all in its midst.",
                "But Yahweh is in his holy temple. Let all the earth be silent before him!\""
            ],
            3: [
                "A prayer of Habakkuk, the prophet, set to victorious music.",
                "Yahweh, I have heard of your fame. I stand in awe of your deeds, Yahweh. Renew your work in the midst of the years. In the midst of the years make it known. In wrath, you remember mercy.",
                "God came from Teman, the Holy One from Mount Paran. Selah. His glory covered the heavens, and his praise filled the earth.",
                "His splendor is like the sunrise. Rays shine from his hand, where his power is hidden.",
                "Plague went before him, and pestilence followed his feet.",
                "He stood, and shook the earth. He looked, and made the nations tremble. The ancient mountains were crumbled. The age-old hills collapsed. His ways are eternal.",
                "I saw the tents of Cushan in affliction. The dwellings of the land of Midian trembled.",
                "Was Yahweh displeased with the rivers? Was your anger against the rivers, or your wrath against the sea, that you rode on your horses, on your chariots of salvation?",
                "You uncovered your bow. You called for your sworn arrows. Selah. You split the earth with rivers.",
                "The mountains saw you, and were afraid. The storm of waters passed by. The deep roared and lifted up its hands on high.",
                "The sun and moon stood still in the sky, at the light of your arrows as they went, at the shining of your glittering spear.",
                "You marched through the land in wrath. You threshed the nations in anger.",
                "You went forth for the salvation of your people, for the salvation of your anointed. You crushed the head of the land of wickedness. You stripped them head to foot. Selah.",
                "You pierced the heads of his warriors with their own spears. They came as a whirlwind to scatter me, gloating as if to devour the wretched in secret.",
                "You trampled the sea with your horses, churning mighty waters.",
                "I heard, and my body trembled. My lips quivered at the voice. Rottenness enters into my bones, and I tremble in my place, because I must wait quietly for the day of trouble, for the coming up of the people who invade us.",
                "For though the fig tree doesn\u2019t flourish, nor fruit be in the vines; the labor of the olive fails, the fields yield no food; the flocks are cut off from the fold, and there is no herd in the stalls:",
                "yet I will rejoice in Yahweh. I will be joyful in the God of my salvation!",
                "Yahweh, the Lord, is my strength. He makes my feet like deer\u2019s feet, and enables me to go in high places. For the music director, on my stringed instruments."
            ]
        },
        36: {
            1: [
                "The word of Yahweh which came to Zephaniah, the son of Cushi, the son of Gedaliah, the son of Amariah, the son of Hezekiah, in the days of Josiah, the son of Amon, king of Judah.",
                "I will utterly sweep away everything off of the surface of the earth, says Yahweh.",
                "I will sweep away man and animal. I will sweep away the birds of the sky, the fish of the sea, and the heaps of rubble with the wicked. I will cut off man from the surface of the earth, says Yahweh.",
                "I will stretch out my hand against Judah, and against all the inhabitants of Jerusalem. I will cut off the remnant of Baal from this place: the name of the idolatrous and pagan priests,",
                "those who worship the army of the sky on the housetops, those who worship and swear by Yahweh and also swear by Malcam,",
                "those who have turned back from following Yahweh, and those who haven\u2019t sought Yahweh nor inquired after him.",
                "Be silent at the presence of the Lord Yahweh, for the day of Yahweh is at hand. For Yahweh has prepared a sacrifice. He has consecrated his guests.",
                "It will happen in the day of Yahweh\u2019s sacrifice, that I will punish the princes, the king\u2019s sons, and all those who are clothed with foreign clothing.",
                "In that day, I will punish all those who leap over the threshold, who fill their master\u2019s house with violence and deceit.",
                "In that day, says Yahweh, there will be the noise of a cry from the fish gate, a wailing from the second quarter, and a great crashing from the hills.",
                "Wail, you inhabitants of Maktesh, for all the people of Canaan are undone! All those who were loaded with silver are cut off.",
                "It will happen at that time, that I will search Jerusalem with lamps, and I will punish the men who are settled on their dregs, who say in their heart, \"Yahweh will not do good, neither will he do evil.\"",
                "Their wealth will become a spoil, and their houses a desolation. Yes, they will build houses, but won\u2019t inhabit them. They will plant vineyards, but won\u2019t drink their wine.",
                "The great day of Yahweh is near. It is near, and hurries greatly, the voice of the day of Yahweh. The mighty man cries there bitterly.",
                "That day is a day of wrath, a day of distress and anguish, a day of trouble and ruin, a day of darkness and gloom, a day of clouds and blackness,",
                "a day of the trumpet and alarm, against the fortified cities, and against the high battlements.",
                "I will bring distress on men, that they will walk like blind men, because they have sinned against Yahweh, and their blood will be poured out like dust, and their flesh like dung.",
                "Neither their silver nor their gold will be able to deliver them in the day of Yahweh\u2019s wrath, but the whole land will be devoured by the fire of his jealousy; for he will make an end, yes, a terrible end, of all those who dwell in the land."
            ],
            2: [
                "Gather yourselves together, yes, gather together, you nation that has no shame,",
                "before the appointed time when the day passes as the chaff, before the fierce anger of Yahweh comes on you, before the day of Yahweh\u2019s anger comes on you.",
                "Seek Yahweh, all you humble of the land, who have kept his ordinances. Seek righteousness. Seek humility. It may be that you will be hidden in the day of Yahweh\u2019s anger.",
                "For Gaza will be forsaken, and Ashkelon a desolation. They will drive out Ashdod at noonday, and Ekron will be rooted up.",
                "Woe to the inhabitants of the sea coast, the nation of the Cherethites! The word of Yahweh is against you, Canaan, the land of the Philistines. I will destroy you, that there will be no inhabitant.",
                "The sea coast will be pastures, with cottages for shepherds and folds for flocks.",
                "The coast will be for the remnant of the house of Judah. They will find pasture. In the houses of Ashkelon, they will lie down in the evening, for Yahweh, their God, will visit them, and restore them.",
                "I have heard the reproach of Moab, and the insults of the children of Ammon, with which they have reproached my people, and magnified themselves against their border.",
                "Therefore as I live, says Yahweh of Armies, the God of Israel, surely Moab will be as Sodom, and the children of Ammon as Gomorrah, a possession of nettles, and salt pits, and a perpetual desolation. The remnant of my people will plunder them, and the survivors of my nation will inherit them.",
                "This they will have for their pride, because they have reproached and magnified themselves against the people of Yahweh of Armies.",
                "Yahweh will be awesome to them, for he will famish all the gods of the land. Men will worship him, everyone from his place, even all the shores of the nations.",
                "You Cushites also, you will be killed by my sword.",
                "He will stretch out his hand against the north, destroy Assyria, and will make Nineveh a desolation, as dry as the wilderness.",
                "Herds will lie down in the midst of her, all the animals of the nations. Both the pelican and the porcupine will lodge in its capitals. Their calls will echo through the windows. Desolation will be in the thresholds, for he has laid bare the cedar beams.",
                "This is the joyous city that lived carelessly, that said in her heart, \"I am, and there is none besides me.\" How she has become a desolation, a place for animals to lie down in! Everyone who passes by her will hiss, and shake their fists."
            ],
            3: [
                "Woe to her who is rebellious and polluted, the oppressing city!",
                "She didn\u2019t obey the voice. She didn\u2019t receive correction. She didn\u2019t trust in Yahweh. She didn\u2019t draw near to her God.",
                "Her princes in the midst of her are roaring lions. Her judges are evening wolves. They leave nothing until the next day.",
                "Her prophets are arrogant and treacherous people. Her priests have profaned the sanctuary. They have done violence to the law.",
                "Yahweh, in the midst of her, is righteous. He will do no wrong. Every morning he brings his justice to light. He doesn\u2019t fail, but the unjust know no shame.",
                "I have cut off nations. Their battlements are desolate. I have made their streets waste, so that no one passes by. Their cities are destroyed, so that there is no man, so that there is no inhabitant.",
                "I said, \"Just fear me. Receive correction, so that her dwelling won\u2019t be cut off, according to all that I have appointed concerning her.\" But they rose early and corrupted all their doings.",
                "\"Therefore wait for me,\" says Yahweh, \"until the day that I rise up to the prey, for my determination is to gather the nations, that I may assemble the kingdoms, to pour on them my indignation, even all my fierce anger, for all the earth will be devoured with the fire of my jealousy.",
                "For then I will purify the lips of the peoples, that they may all call on the name of Yahweh, to serve him shoulder to shoulder.",
                "From beyond the rivers of Cush, my worshipers, even the daughter of my dispersed people, will bring my offering.",
                "In that day you will not be disappointed for all your doings, in which you have transgressed against me; for then I will take away out of the midst of you your proudly exulting ones, and you will no more be haughty in my holy mountain.",
                "But I will leave in the midst of you an afflicted and poor people, and they will take refuge in the name of Yahweh.",
                "The remnant of Israel will not do iniquity, nor speak lies, neither will a deceitful tongue be found in their mouth, for they will feed and lie down, and no one will make them afraid.\"",
                "Sing, daughter of Zion! Shout, Israel! Be glad and rejoice with all your heart, daughter of Jerusalem.",
                "Yahweh has taken away your judgments. He has thrown out your enemy. The King of Israel, Yahweh, is in the midst of you. You will not be afraid of evil any more.",
                "In that day, it will be said to Jerusalem, \"Don\u2019t be afraid, Zion. Don\u2019t let your hands be weak.\"",
                "Yahweh, your God, is in the midst of you, a mighty one who will save. He will rejoice over you with joy. He will calm you in his love. He will rejoice over you with singing.",
                "I will remove those who grieve about the appointed feasts from you. They are a burden and a reproach to you.",
                "Behold, at that time I will deal with all those who afflict you, and I will save those who are lame, and gather those who were driven away. I will give them praise and honor, whose shame has been in all the earth.",
                "At that time will I bring you in, and at that time will I gather you; for I will give you honor and praise among all the peoples of the earth, when I restore your fortunes before your eyes, says Yahweh."
            ]
        },
        37: {
            1: [
                "In the second year of Darius the king, in the sixth month, in the first day of the month, the Word of Yahweh came by Haggai, the prophet, to Zerubbabel, the son of Shealtiel, governor of Judah, and to Joshua, the son of Jehozadak, the high priest, saying,",
                "\"This is what Yahweh of Armies says: These people say, \u2018The time hasn\u2019t yet come, the time for Yahweh\u2019s house to be built.\u2019\"",
                "Then the Word of Yahweh came by Haggai, the prophet, saying,",
                "\"Is it a time for you yourselves to dwell in your paneled houses, while this house lies waste?",
                "Now therefore this is what Yahweh of Armies says: Consider your ways.",
                "You have sown much, and bring in little. You eat, but you don\u2019t have enough. You drink, but you aren\u2019t filled with drink. You clothe yourselves, but no one is warm, and he who earns wages earns wages to put them into a bag with holes in it.\"",
                "This is what Yahweh of Armies says: \"Consider your ways.",
                "Go up to the mountain, bring wood, and build the house. I will take pleasure in it, and I will be glorified,\" says Yahweh.",
                "\"You looked for much, and, behold, it came to little; and when you brought it home, I blew it away. Why?\" says Yahweh of Armies, \"Because of my house that lies waste, while each of you is busy with his own house.",
                "Therefore for your sake the heavens withhold the dew, and the earth withholds its fruit.",
                "I called for a drought on the land, on the mountains, on the grain, on the new wine, on the oil, on that which the ground brings forth, on men, on livestock, and on all the labor of the hands.\"",
                "Then Zerubbabel, the son of Shealtiel, and Joshua, the son of Jehozadak, the high priest, with all the remnant of the people, obeyed the voice of Yahweh, their God, and the words of Haggai, the prophet, as Yahweh, their God, had sent him; and the people feared Yahweh.",
                "Then Haggai, Yahweh\u2019s messenger, spoke Yahweh\u2019s message to the people, saying, \"I am with you,\" says Yahweh.",
                "Yahweh stirred up the spirit of Zerubbabel, the son of Shealtiel, governor of Judah, and the spirit of Joshua, the son of Jehozadak, the high priest, and the spirit of all the remnant of the people; and they came and worked on the house of Yahweh of Armies, their God,",
                "in the twenty-fourth day of the month, in the sixth month, in the second year of Darius the king."
            ],
            2: [
                "In the seventh month, in the twenty-first day of the month, the Word of Yahweh came by Haggai the prophet, saying,",
                "\"Speak now to Zerubbabel, the son of Shealtiel, governor of Judah, and to Joshua, the son of Jehozadak, the high priest, and to the remnant of the people, saying,",
                "\u2018Who is left among you who saw this house in its former glory? How do you see it now? Isn\u2019t it in your eyes as nothing?",
                "Yet now be strong, Zerubbabel,\u2019 says Yahweh. \u2018Be strong, Joshua, son of Jehozadak, the high priest. Be strong, all you people of the land,\u2019 says Yahweh, \u2018and work, for I am with you,\u2019 says Yahweh of Armies.",
                "This is the word that I covenanted with you when you came out of Egypt, and my Spirit lived among you. \u2018Don\u2019t be afraid.\u2019",
                "For this is what Yahweh of Armies says: \u2018Yet once, it is a little while, and I will shake the heavens, the earth, the sea, and the dry land;",
                "and I will shake all nations. The precious things of all nations will come, and I will fill this house with glory, says Yahweh of Armies.",
                "The silver is mine, and the gold is mine,\u2019 says Yahweh of Armies.",
                "\u2018The latter glory of this house will be greater than the former,\u2019 says Yahweh of Armies; \u2018and in this place will I give peace,\u2019 says Yahweh of Armies.\"",
                "In the twenty-fourth day of the ninth month, in the second year of Darius, the Word of Yahweh came by Haggai the prophet, saying,",
                "\"Thus says Yahweh of Armies: Ask now the priests concerning the law, saying,",
                "\u2018If someone carries holy meat in the fold of his garment, and with his fold touches bread, stew, wine, oil, or any food, will it become holy?\u2019\" The priests answered, \"No.\"",
                "Then Haggai said, \"If one who is unclean by reason of a dead body touch any of these, will it be unclean?\" The priests answered, \"It will be unclean.\"",
                "Then Haggai answered, \"\u2018So is this people, and so is this nation before me,\u2019 says Yahweh; \u2018and so is every work of their hands. That which they offer there is unclean.",
                "Now, please consider from this day and backward, before a stone was laid on a stone in the temple of Yahweh.",
                "Through all that time, when one came to a heap of twenty measures, there were only ten. When one came to the wine vat to draw out fifty, there were only twenty.",
                "I struck you with blight, mildew, and hail in all the work of your hands; yet you didn\u2019t turn to me,\u2019 says Yahweh.",
                "\u2018Consider, please, from this day and backward, from the twenty-fourth day of the ninth month, since the day that the foundation of Yahweh\u2019s temple was laid, consider it.",
                "Is the seed yet in the barn? Yes, the vine, the fig tree, the pomegranate, and the olive tree haven\u2019t brought forth. From this day will I bless you.\u2019\"",
                "The Word of Yahweh came the second time to Haggai in the twenty-fourth day of the month, saying,",
                "\"Speak to Zerubbabel, governor of Judah, saying, \u2018I will shake the heavens and the earth.",
                "I will overthrow the throne of kingdoms. I will destroy the strength of the kingdoms of the nations. I will overthrow the chariots, and those who ride in them. The horses and their riders will come down, everyone by the sword of his brother.",
                "In that day, says Yahweh of Armies, will I take you, Zerubbabel, my servant, the son of Shealtiel,\u2019 says Yahweh, \u2018and will make you as a signet, for I have chosen you,\u2019 says Yahweh of Armies.\""
            ]
        },
        38: {
            1: [
                "In the eighth month, in the second year of Darius, the word of Yahweh came to Zechariah the son of Berechiah, the son of Iddo, the prophet, saying,",
                "\"Yahweh was very displeased with your fathers.",
                "Therefore tell them: Thus says Yahweh of Armies: \u2018Return to me,\u2019 says Yahweh of Armies, \u2018and I will return to you,\u2019 says Yahweh of Armies.",
                "Don\u2019t you be like your fathers, to whom the former prophets proclaimed, saying: Thus says Yahweh of Armies, \u2018Return now from your evil ways, and from your evil doings;\u2019 but they did not hear, nor listen to me, says Yahweh.",
                "Your fathers, where are they? And the prophets, do they live forever?",
                "But my words and my decrees, which I commanded my servants the prophets, didn\u2019t they overtake your fathers? \"Then they repented and said, \u2018Just as Yahweh of Armies determined to do to us, according to our ways, and according to our practices, so he has dealt with us.\u2019\"",
                "On the twenty-fourth day of the eleventh month, which is the month Shebat, in the second year of Darius, the word of Yahweh came to Zechariah the son of Berechiah, the son of Iddo, the prophet, saying,",
                "\"I had a vision in the night, and behold, a man riding on a red horse, and he stood among the myrtle trees that were in a ravine; and behind him there were red, brown, and white horses.",
                "Then I asked, \u2018My lord, what are these?\u2019\" The angel who talked with me said to me, \"I will show you what these are.\"",
                "The man who stood among the myrtle trees answered, \"They are the ones Yahweh has sent to go back and forth through the earth.\"",
                "They reported to the angel of Yahweh who stood among the myrtle trees, and said, \"We have walked back and forth through the earth, and behold, all the earth is at rest and in peace.\"",
                "Then the angel of Yahweh replied, \"O Yahweh of Armies, how long will you not have mercy on Jerusalem and on the cities of Judah, against which you have had indignation these seventy years?\"",
                "Yahweh answered the angel who talked with me with kind and comforting words.",
                "So the angel who talked with me said to me, \"Proclaim, saying, \u2018Thus says Yahweh of Armies: \"I am jealous for Jerusalem and for Zion with a great jealousy.",
                "I am very angry with the nations that are at ease; for I was but a little displeased, but they added to the calamity.\"",
                "Therefore thus says Yahweh: \"I have returned to Jerusalem with mercy. My house shall be built in it,\" says Yahweh of Armies, \"and a line shall be stretched forth over Jerusalem.\"\u2019",
                "\"Proclaim further, saying, \u2018Thus says Yahweh of Armies: \"My cities will again overflow with prosperity, and Yahweh will again comfort Zion, and will again choose Jerusalem.\"\u2019\"",
                "I lifted up my eyes, and saw, and behold, four horns.",
                "I asked the angel who talked with me, \"What are these?\" He answered me, \"These are the horns which have scattered Judah, Israel, and Jerusalem.\"",
                "Yahweh showed me four craftsmen.",
                "Then I asked, \"What are these coming to do?\" He said, \"These are the horns which scattered Judah, so that no man lifted up his head; but these have come to terrify them, to cast down the horns of the nations, which lifted up their horn against the land of Judah to scatter it.\""
            ],
            2: [
                "I lifted up my eyes, and saw, and behold, a man with a measuring line in his hand.",
                "Then I asked, \"Where are you going?\" He said to me, \"To measure Jerusalem, to see what is its breadth and what is its length.\"",
                "Behold, the angel who talked with me went forth, and another angel went out to meet him,",
                "and said to him, \"Run, speak to this young man, saying, \u2018Jerusalem will be inhabited as villages without walls, because of the multitude of men and livestock in it.",
                "For I,\u2019 says Yahweh, \u2018will be to her a wall of fire around it, and I will be the glory in the midst of her.",
                "Come! Come! Flee from the land of the north,\u2019 says Yahweh; \u2018for I have spread you abroad as the four winds of the sky,\u2019 says Yahweh.",
                "\u2018Come, Zion! Escape, you who dwell with the daughter of Babylon.\u2019",
                "For thus says Yahweh of Armies: \u2018For honor he has sent me to the nations which plundered you; for he who touches you touches the apple of his eye.",
                "For, behold, I will shake my hand over them, and they will be a spoil to those who served them; and you will know that Yahweh of Armies has sent me.",
                "Sing and rejoice, daughter of Zion; for, behold, I come, and I will dwell in the midst of you,\u2019 says Yahweh.",
                "Many nations shall join themselves to Yahweh in that day, and shall be my people; and I will dwell in the midst of you, and you shall know that Yahweh of Armies has sent me to you.",
                "Yahweh will inherit Judah as his portion in the holy land, and will again choose Jerusalem.",
                "Be silent, all flesh, before Yahweh; for he has roused himself from his holy habitation!\""
            ],
            3: [
                "He showed me Joshua the high priest standing before the angel of Yahweh, and Satan standing at his right hand to be his adversary.",
                "Yahweh said to Satan, \"Yahweh rebuke you, Satan! Yes, Yahweh who has chosen Jerusalem rebuke you! Isn\u2019t this a burning stick plucked out of the fire?\"",
                "Now Joshua was clothed with filthy garments, and was standing before the angel.",
                "He answered and spoke to those who stood before him, saying, \"Take the filthy garments off of him.\" To him he said, \"Behold, I have caused your iniquity to pass from you, and I will clothe you with rich clothing.\"",
                "I said, \"Let them set a clean turban on his head.\" So they set a clean turban on his head, and clothed him; and the angel of Yahweh was standing by.",
                "The angel of Yahweh protested to Joshua, saying,",
                "\"Thus says Yahweh of Armies: \u2018If you will walk in my ways, and if you will follow my instructions, then you also shall judge my house, and shall also keep my courts, and I will give you a place of access among these who stand by.",
                "Hear now, Joshua the high priest, you and your fellows who sit before you; for they are men who are a sign: for, behold, I will bring forth my servant, the Branch.",
                "For, behold, the stone that I have set before Joshua; on one stone are seven eyes: behold, I will engrave its engraving,\u2019 says Yahweh of Armies, \u2018and I will remove the iniquity of that land in one day.",
                "In that day,\u2019 says Yahweh of Armies, \u2018you will invite every man his neighbor under the vine and under the fig tree.\u2019\""
            ],
            4: [
                "The angel who talked with me came again, and wakened me, as a man who is wakened out of his sleep.",
                "He said to me, \"What do you see?\" I said, \"I have seen, and behold, a lampstand all of gold, with its bowl on the top of it, and its seven lamps thereon; there are seven pipes to each of the lamps, which are on the top of it;",
                "and two olive trees by it, one on the right side of the bowl, and the other on the left side of it.\"",
                "I answered and spoke to the angel who talked with me, saying, \"What are these, my lord?\"",
                "Then the angel who talked with me answered me, \"Don\u2019t you know what these are?\" I said, \"No, my lord.\"",
                "Then he answered and spoke to me, saying, \"This is the word of Yahweh to Zerubbabel, saying, \u2018Not by might, nor by power, but by my Spirit,\u2019 says Yahweh of Armies.",
                "Who are you, great mountain? Before Zerubbabel you are a plain; and he will bring out the capstone with shouts of \u2018Grace, grace, to it!\u2019\"",
                "Moreover the word of Yahweh came to me, saying,",
                "\"The hands of Zerubbabel have laid the foundation of this house. His hands shall also finish it; and you will know that Yahweh of Armies has sent me to you.",
                "Indeed, who despises the day of small things? For these seven shall rejoice, and shall see the plumb line in the hand of Zerubbabel. These are the eyes of Yahweh, which run back and forth through the whole earth.\"",
                "Then I asked him, \"What are these two olive trees on the right side of the lampstand and on the left side of it?\"",
                "I asked him the second time, \"What are these two olive branches, which are beside the two golden spouts, that pour the golden oil out of themselves?\"",
                "He answered me, \"Don\u2019t you know what these are?\" I said, \"No, my lord.\"",
                "Then he said, \"These are the two anointed ones who stand by the Lord of the whole earth.\""
            ],
            5: [
                "Then again I lifted up my eyes, and saw, and behold, a flying scroll.",
                "He said to me, \"What do you see?\" I answered, \"I see a flying scroll; its length is twenty cubits, and its breadth ten cubits.\"",
                "Then he said to me, \"This is the curse that goes out over the surface of the whole land; for everyone who steals shall be cut off according to it on the one side; and everyone who swears falsely shall be cut off according to it on the other side.",
                "I will cause it to go out,\" says Yahweh of Armies, \"and it will enter into the house of the thief, and into the house of him who swears falsely by my name; and it will remain in the midst of his house, and will destroy it with its timber and its stones.\"",
                "Then the angel who talked with me came forward, and said to me, \"Lift up now your eyes, and see what is this that is appearing.\"",
                "I said, \"What is it?\" He said, \"This is the ephah basket that is appearing.\" He said moreover, \"This is their appearance in all the land",
                "(and behold, a talent of lead was lifted up); and this is a woman sitting in the midst of the ephah basket.\"",
                "He said, \"This is Wickedness;\" and he threw her down into the midst of the ephah basket; and he threw the weight of lead on its mouth.",
                "Then lifted I up my eyes, and saw, and behold, there were two women, and the wind was in their wings. Now they had wings like the wings of a stork, and they lifted up the ephah basket between earth and the sky.",
                "Then I said to the angel who talked with me, \"Where are these carrying the ephah basket?\"",
                "He said to me, \"To build her a house in the land of Shinar. When it is prepared, she will be set there in her own place.\""
            ],
            6: [
                "Again I lifted up my eyes, and saw, and behold, four chariots came out from between two mountains; and the mountains were mountains of brass.",
                "In the first chariot were red horses; in the second chariot black horses;",
                "in the third chariot white horses; and in the fourth chariot dappled horses, all of them powerful.",
                "Then I asked the angel who talked with me, \"What are these, my lord?\"",
                "The angel answered me, \"These are the four winds of the sky, which go forth from standing before the Lord of all the earth.",
                "The one with the black horses goes out toward the north country; and the white went out after them; and the dappled went forth toward the south country.\"",
                "The strong went out, and sought to go that they might walk back and forth through the earth: and he said, \"Go around and through the earth!\" So they walked back and forth through the earth.",
                "Then he called to me, and spoke to me, saying, \"Behold, those who go toward the north country have quieted my spirit in the north country.\"",
                "The word of Yahweh came to me, saying,",
                "\"Take of them of the captivity, even of Heldai, of Tobijah, and of Jedaiah; and come the same day, and go into the house of Josiah the son of Zephaniah, where they have come from Babylon.",
                "Yes, take silver and gold, and make crowns, and set them on the head of Joshua the son of Jehozadak, the high priest;",
                "and speak to him, saying, \u2018Thus says Yahweh of Armies, \"Behold, the man whose name is the Branch: and he shall grow up out of his place; and he shall build the temple of Yahweh;",
                "even he shall build the temple of Yahweh; and he shall bear the glory, and shall sit and rule on his throne; and he shall be a priest on his throne; and the counsel of peace shall be between them both.",
                "The crowns shall be to Helem, and to Tobijah, and to Jedaiah, and to Hen the son of Zephaniah, for a memorial in the temple of Yahweh.",
                "Those who are far off shall come and build in the temple of Yahweh; and you shall know that Yahweh of Armies has sent me to you. This will happen, if you will diligently obey the voice of Yahweh your God.\"\u2019\""
            ],
            7: [
                "It happened in the fourth year of king Darius that the word of Yahweh came to Zechariah in the fourth day of the ninth month, the month of Chislev.",
                "The people of Bethel sent Sharezer and Regem Melech, and their men, to entreat Yahweh\u2019s favor,",
                "and to speak to the priests of the house of Yahweh of Armies, and to the prophets, saying, \"Should I weep in the fifth month, separating myself, as I have done these so many years?\"",
                "Then the word of Yahweh of Armies came to me, saying,",
                "\"Speak to all the people of the land, and to the priests, saying, \u2018When you fasted and mourned in the fifth and in the seventh month for these seventy years, did you at all fast to me, really to me?",
                "When you eat, and when you drink, don\u2019t you eat for yourselves, and drink for yourselves?",
                "Aren\u2019t these the words which Yahweh proclaimed by the former prophets, when Jerusalem was inhabited and in prosperity, and its cities around her, and the South and the lowland were inhabited?\u2019\"",
                "The word of Yahweh came to Zechariah, saying,",
                "\"Thus has Yahweh of Armies spoken, saying, \u2018Execute true judgment, and show kindness and compassion every man to his brother.",
                "Don\u2019t oppress the widow, nor the fatherless, the foreigner, nor the poor; and let none of you devise evil against his brother in your heart.\u2019",
                "But they refused to listen, and turned their backs, and stopped their ears, that they might not hear.",
                "Yes, they made their hearts as hard as flint, lest they might hear the law, and the words which Yahweh of Armies had sent by his Spirit by the former prophets. Therefore great wrath came from Yahweh of Armies.",
                "It has come to pass that, as he called, and they refused to listen, so they will call, and I will not listen,\" said Yahweh of Armies;",
                "\"but I will scatter them with a whirlwind among all the nations which they have not known. Thus the land was desolate after them, so that no man passed through nor returned: for they made the pleasant land desolate.\""
            ],
            8: [
                "The word of Yahweh of Armies came to me.",
                "Thus says Yahweh of Armies: \"I am jealous for Zion with great jealousy, and I am jealous for her with great wrath.\"",
                "Thus says Yahweh: \"I have returned to Zion, and will dwell in the midst of Jerusalem. Jerusalem shall be called \u2018The City of Truth;\u2019 and the mountain of Yahweh of Armies, \u2018The Holy Mountain.\u2019\"",
                "Thus says Yahweh of Armies: \"Old men and old women will again dwell in the streets of Jerusalem, every man with his staff in his hand for very age.",
                "The streets of the city will be full of boys and girls playing in its streets.\"",
                "Thus says Yahweh of Armies: \"If it is marvelous in the eyes of the remnant of this people in those days, should it also be marvelous in my eyes?\" says Yahweh of Armies.",
                "Thus says Yahweh of Armies: \"Behold, I will save my people from the east country, and from the west country;",
                "and I will bring them, and they will dwell in the midst of Jerusalem; and they will be my people, and I will be their God, in truth and in righteousness.\"",
                "Thus says Yahweh of Armies: \"Let your hands be strong, you who hear in these days these words from the mouth of the prophets who were in the day that the foundation of the house of Yahweh of Armies was laid, even the temple, that it might be built.",
                "For before those days there was no wages for man, nor any wages for an animal; neither was there any peace to him who went out or came in, because of the adversary. For I set all men everyone against his neighbor.",
                "But now I will not be to the remnant of this people as in the former days,\" says Yahweh of Armies.",
                "\"For the seed of peace and the vine will yield its fruit, and the ground will give its increase, and the heavens will give their dew; and I will cause the remnant of this people to inherit all these things.",
                "It shall come to pass that, as you were a curse among the nations, house of Judah and house of Israel, so will I save you, and you shall be a blessing. Don\u2019t be afraid. Let your hands be strong.\"",
                "For thus says Yahweh of Armies: \"As I thought to do evil to you, when your fathers provoked me to wrath,\" says Yahweh of Armies, \"and I didn\u2019t repent;",
                "so again have I thought in these days to do good to Jerusalem and to the house of Judah. Don\u2019t be afraid.",
                "These are the things that you shall do: speak every man the truth with his neighbor. Execute the judgment of truth and peace in your gates,",
                "and let none of you devise evil in your hearts against his neighbor, and love no false oath: for all these are things that I hate,\" says Yahweh.",
                "The word of Yahweh of Armies came to me.",
                "Thus says Yahweh of Armies: \"The fasts of the fourth fifth, seventh, and tenth months shall be for the house of Judah joy and gladness, and cheerful feasts. Therefore love truth and peace.\"",
                "Thus says Yahweh of Armies: \"Many peoples, and the inhabitants of many cities will yet come;",
                "and the inhabitants of one shall go to another, saying, \u2018Let us go speedily to entreat the favor of Yahweh, and to seek Yahweh of Armies. I will go also.\u2019",
                "Yes, many peoples and strong nations will come to seek Yahweh of Armies in Jerusalem, and to entreat the favor of Yahweh.\"",
                "Thus says Yahweh of Armies: \"In those days, ten men will take hold, out of all the languages of the nations, they will take hold of the skirt of him who is a Jew, saying, \u2018We will go with you, for we have heard that God is with you.\u2019\""
            ],
            9: [
                "An oracle. The word of Yahweh is against the land of Hadrach, and will rest upon Damascus; for the eye of man and of all the tribes of Israel is toward Yahweh;",
                "and Hamath, also, which borders on it; Tyre and Sidon, because they are very wise.",
                "Tyre built herself a stronghold, and heaped up silver like the dust, and fine gold like the mire of the streets.",
                "Behold, the Lord will dispossess her, and he will strike her power in the sea; and she will be devoured with fire.",
                "Ashkelon will see it, and fear; Gaza also, and will writhe in agony; as will Ekron, for her expectation will be disappointed; and the king will perish from Gaza, and Ashkelon will not be inhabited.",
                "Foreigners will dwell in Ashdod, and I will cut off the pride of the Philistines.",
                "I will take away his blood out of his mouth, and his abominations from between his teeth; and he also will be a remnant for our God; and he will be as a chieftain in Judah, and Ekron as a Jebusite.",
                "I will encamp around my house against the army, that none pass through or return; and no oppressor will pass through them any more: for now I have seen with my eyes.",
                "Rejoice greatly, daughter of Zion! Shout, daughter of Jerusalem! Behold, your King comes to you! He is righteous, and having salvation; lowly, and riding on a donkey, even on a colt, the foal of a donkey.",
                "I will cut off the chariot from Ephraim, and the horse from Jerusalem; and the battle bow will be cut off; and he will speak peace to the nations: and his dominion will be from sea to sea, and from the River to the ends of the earth.",
                "As for you also, because of the blood of your covenant, I have set free your prisoners from the pit in which is no water.",
                "Turn to the stronghold, you prisoners of hope! Even today I declare that I will restore double to you.",
                "For indeed I bend Judah as a bow for me. I have filled the bow with Ephraim; and I will stir up your sons, Zion, against your sons, Greece, and will make you like the sword of a mighty man.",
                "Yahweh will be seen over them; and his arrow will go flash like lightning; and the Lord Yahweh will blow the trumpet, and will go with whirlwinds of the south.",
                "Yahweh of Armies will defend them; and they will destroy and overcome with sling stones; and they will drink, and roar as through wine; and they will be filled like bowls, like the corners of the altar.",
                "Yahweh their God will save them in that day as the flock of his people; for they are like the jewels of a crown, lifted on high over his land.",
                "For how great is his goodness, and how great is his beauty! Grain will make the young men flourish, and new wine the virgins."
            ],
            10: [
                "Ask of Yahweh rain in the spring time, Yahweh who makes storm clouds, and he gives rain showers to everyone for the plants in the field.",
                "For the teraphim have spoken vanity, and the diviners have seen a lie; and they have told false dreams. They comfort in vain. Therefore they go their way like sheep. They are oppressed, because there is no shepherd.",
                "My anger is kindled against the shepherds, and I will punish the male goats; For Yahweh of Armies has visited his flock, the house of Judah, and will make them as his majestic horse in the battle.",
                "From him will come forth the cornerstone, from him the nail, from him the battle bow, from him every ruler together.",
                "They shall be as mighty men, treading down muddy streets in the battle; and they shall fight, because Yahweh is with them; and the riders on horses will be confounded.",
                "\"I will strengthen the house of Judah, and I will save the house of Joseph, and I will bring them back; for I have mercy on them; and they will be as though I had not cast them off: for I am Yahweh their God, and I will hear them.",
                "Ephraim will be like a mighty man, and their heart will rejoice as through wine; yes, their children will see it, and rejoice. Their heart will be glad in Yahweh.",
                "I will signal for them, and gather them; for I have redeemed them; and they will increase as they have increased.",
                "I will sow them among the peoples; and they will remember me in far countries; and they will live with their children, and will return.",
                "I will bring them again also out of the land of Egypt, and gather them out of Assyria; and I will bring them into the land of Gilead and Lebanon; and there won\u2019t be room enough for them.",
                "He will pass through the sea of affliction, and will strike the waves in the sea, and all the depths of the Nile will dry up; and the pride of Assyria will be brought down, and the scepter of Egypt will depart.",
                "I will strengthen them in Yahweh; and they will walk up and down in his name,\" says Yahweh."
            ],
            11: [
                "Open your doors, Lebanon, that the fire may devour your cedars.",
                "Wail, fir tree, for the cedar has fallen, because the stately ones are destroyed. Wail, you oaks of Bashan, for the strong forest has come down.",
                "A voice of the wailing of the shepherds! For their glory is destroyed: a voice of the roaring of young lions! For the pride of the Jordan is ruined.",
                "Thus says Yahweh my God: \"Feed the flock of slaughter.",
                "Their buyers slaughter them, and go unpunished. Those who sell them say, \u2018Blessed be Yahweh, for I am rich;\u2019 and their own shepherds don\u2019t pity them.",
                "For I will no more pity the inhabitants of the land,\" says Yahweh; \"but, behold, I will deliver the men everyone into his neighbor\u2019s hand, and into the hand of his king. They will strike the land, and out of their hand I will not deliver them.\"",
                "So I fed the flock of slaughter, especially the oppressed of the flock. I took for myself two staffs. The one I called \"Favor,\" and the other I called \"Union,\" and I fed the flock.",
                "I cut off the three shepherds in one month; for my soul was weary of them, and their soul also loathed me.",
                "Then I said, \"I will not feed you. That which dies, let it die; and that which is to be cut off, let it be cut off; and let those who are left eat each other\u2019s flesh.\"",
                "I took my staff Favor, and cut it apart, that I might break my covenant that I had made with all the peoples.",
                "It was broken in that day; and thus the poor of the flock that listened to me knew that it was the word of Yahweh.",
                "I said to them, \"If you think it best, give me my wages; and if not, keep them.\" So they weighed for my wages thirty pieces of silver.",
                "Yahweh said to me, \"Throw it to the potter, the handsome price that I was valued at by them!\" I took the thirty pieces of silver, and threw them to the potter, in the house of Yahweh.",
                "Then I cut apart my other staff, even Union, that I might break the brotherhood between Judah and Israel.",
                "Yahweh said to me, \"Take for yourself yet again the equipment of a foolish shepherd.",
                "For, behold, I will raise up a shepherd in the land, who will not visit those who are cut off, neither will seek those who are scattered, nor heal that which is broken, nor feed that which is sound; but he will eat the flesh of the fat sheep, and will tear their hoofs in pieces.",
                "Woe to the worthless shepherd who leaves the flock! The sword will be on his arm, and on his right eye. His arm will be completely withered, and his right eye will be totally blinded!\""
            ],
            12: [
                "An oracle. The word of Yahweh concerning Israel. Yahweh, who stretches out the heavens, and lays the foundation of the earth, and forms the spirit of man within him says:",
                "\"Behold, I will make Jerusalem a cup of reeling to all the surrounding peoples, and on Judah also will it be in the siege against Jerusalem.",
                "It will happen in that day, that I will make Jerusalem a burdensome stone for all the peoples. All who burden themselves with it will be severely wounded, and all the nations of the earth will be gathered together against it.",
                "In that day,\" says Yahweh, \"I will strike every horse with terror, and his rider with madness; and I will open my eyes on the house of Judah, and will strike every horse of the peoples with blindness.",
                "The chieftains of Judah will say in their heart, \u2018The inhabitants of Jerusalem are my strength in Yahweh of Armies their God.\u2019",
                "In that day I will make the chieftains of Judah like a pan of fire among wood, and like a flaming torch among sheaves; and they will devour all the surrounding peoples, on the right hand and on the left; and Jerusalem will yet again dwell in their own place, even in Jerusalem.",
                "Yahweh also will save the tents of Judah first, that the glory of the house of David and the glory of the inhabitants of Jerusalem not be magnified above Judah.",
                "In that day Yahweh will defend the inhabitants of Jerusalem. He who is feeble among them at that day will be like David, and the house of David will be like God, like the angel of Yahweh before them.",
                "It will happen in that day, that I will seek to destroy all the nations that come against Jerusalem.",
                "I will pour on the house of David, and on the inhabitants of Jerusalem, the spirit of grace and of supplication; and they will look to me whom they have pierced; and they shall mourn for him, as one mourns for his only son, and will grieve bitterly for him, as one grieves for his firstborn.",
                "In that day there will be a great mourning in Jerusalem, like the mourning of Hadadrimmon in the valley of Megiddon.",
                "The land will mourn, every family apart; the family of the house of David apart, and their wives apart; the family of the house of Nathan apart, and their wives apart;",
                "the family of the house of Levi apart, and their wives apart; the family of the Shimeites apart, and their wives apart;",
                "all the families who remain, every family apart, and their wives apart."
            ],
            13: [
                "\"In that day there will be a spring opened to the house of David and to the inhabitants of Jerusalem, for sin and for uncleanness.",
                "It will come to pass in that day, says Yahweh of Armies, that I will cut off the names of the idols out of the land, and they will be remembered no more. I will also cause the prophets and the spirit of impurity to pass out of the land.",
                "It will happen that, when anyone still prophesies, then his father and his mother who bore him will tell him, \u2018You must die, because you speak lies in the name of Yahweh;\u2019 and his father and his mother who bore him will stab him when he prophesies.",
                "It will happen in that day, that the prophets will each be ashamed of his vision, when he prophesies; neither will they wear a hairy mantle to deceive:",
                "but he will say, \u2018I am no prophet, I am a tiller of the ground; for I have been made a bondservant from my youth.\u2019",
                "One will say to him, \u2018What are these wounds between your arms?\u2019 Then he will answer, \u2018Those with which I was wounded in the house of my friends.\u2019",
                "\"Awake, sword, against my shepherd, and against the man who is close to me,\" says Yahweh of Armies. \"Strike the shepherd, and the sheep will be scattered; and I will turn my hand against the little ones.",
                "It shall happen that in all the land,\" says Yahweh, \"two parts in it will be cut off and die; but the third will be left in it.",
                "I will bring the third part into the fire, and will refine them as silver is refined, and will test them like gold is tested. They will call on my name, and I will hear them. I will say, \u2018It is my people;\u2019 and they will say, \u2018Yahweh is my God.\u2019\""
            ],
            14: [
                "Behold, a day of Yahweh comes, when your spoil will be divided in your midst.",
                "For I will gather all nations against Jerusalem to battle; and the city will be taken, the houses rifled, and the women ravished. Half of the city will go out into captivity, and the rest of the people will not be cut off from the city.",
                "Then Yahweh will go out and fight against those nations, as when he fought in the day of battle.",
                "His feet will stand in that day on the Mount of Olives, which is before Jerusalem on the east; and the Mount of Olives will be split in two, from east to west, making a very great valley. Half of the mountain will move toward the north, and half of it toward the south.",
                "You shall flee by the valley of my mountains; for the valley of the mountains shall reach to Azel; yes, you shall flee, just like you fled from before the earthquake in the days of Uzziah king of Judah. Yahweh my God will come, and all the holy ones with you.",
                "It will happen in that day, that there will not be light, cold, or frost.",
                "It will be a unique day which is known to Yahweh; not day, and not night; but it will come to pass, that at evening time there will be light.",
                "It will happen in that day, that living waters will go out from Jerusalem; half of them toward the eastern sea, and half of them toward the western sea; in summer and in winter will it be.",
                "Yahweh will be King over all the earth. In that day Yahweh will be one, and his name one.",
                "All the land will be made like the Arabah, from Geba to Rimmon south of Jerusalem; and she will be lifted up, and will dwell in her place, from Benjamin\u2019s gate to the place of the first gate, to the corner gate, and from the tower of Hananel to the king\u2019s winepresses.",
                "Men will dwell therein, and there will be no more curse; but Jerusalem will dwell safely.",
                "This will be the plague with which Yahweh will strike all the peoples who have warred against Jerusalem: their flesh will consume away while they stand on their feet, and their eyes will consume away in their sockets, and their tongue will consume away in their mouth.",
                "It will happen in that day, that a great panic from Yahweh will be among them; and they will lay hold everyone on the hand of his neighbor, and his hand will rise up against the hand of his neighbor.",
                "Judah also will fight at Jerusalem; and the wealth of all the surrounding nations will be gathered together: gold, and silver, and clothing, in great abundance.",
                "So will be the plague of the horse, of the mule, of the camel, and of the donkey, and of all the animals that will be in those camps, as that plague.",
                "It will happen that everyone who is left of all the nations that came against Jerusalem will go up from year to year to worship the King, Yahweh of Armies, and to keep the feast of tents.",
                "It will be, that whoever of all the families of the earth doesn\u2019t go up to Jerusalem to worship the King, Yahweh of Armies, on them there will be no rain.",
                "If the family of Egypt doesn\u2019t go up, and doesn\u2019t come, neither will it rain on them. This will be the plague with which Yahweh will strike the nations that don\u2019t go up to keep the feast of tents.",
                "This will be the punishment of Egypt, and the punishment of all the nations that don\u2019t go up to keep the feast of tents.",
                "In that day there will be on the bells of the horses, \"HOLY TO YAHWEH;\" and the pots in Yahweh\u2019s house will be like the bowls before the altar.",
                "Yes, every pot in Jerusalem and in Judah will be holy to Yahweh of Armies; and all those who sacrifice will come and take of them, and cook in them. In that day there will no longer be a Canaanite in the house of Yahweh of Armies."
            ]
        },
        39: {
            1: [
                "An oracle: the word of Yahweh to Israel by Malachi.",
                "\"I have loved you,\" says Yahweh. Yet you say, \"How have you loved us?\" \"Wasn\u2019t Esau Jacob\u2019s brother?\" says Yahweh, \"Yet I loved Jacob;",
                "but Esau I hated, and made his mountains a desolation, and gave his heritage to the jackals of the wilderness.\"",
                "Whereas Edom says, \"We are beaten down, but we will return and build the waste places;\" thus says Yahweh of Armies, \"They shall build, but I will throw down; and men will call them \u2018The Wicked Land,\u2019 even the people against whom Yahweh shows wrath forever.\"",
                "Your eyes will see, and you will say, \"Yahweh is great\u2014even beyond the border of Israel!\"",
                "\"A son honors his father, and a servant his master. If I am a father, then where is my honor? And if I am a master, where is the respect due me? Says Yahweh of Armies to you, priests, who despise my name. You say, \u2018How have we despised your name?\u2019",
                "You offer polluted bread on my altar. You say, \u2018How have we polluted you?\u2019 In that you say, \u2018Yahweh\u2019s table contemptible.\u2019",
                "When you offer the blind for sacrifice, isn\u2019t that evil? And when you offer the lame and sick, isn\u2019t that evil? Present it now to your governor! Will he be pleased with you? Or will he accept your person?\" says Yahweh of Armies.",
                "\"Now, please entreat the favor of God, that he may be gracious to us. With this, will he accept any of you?\" says Yahweh of Armies.",
                "\"Oh that there were one among you who would shut the doors, that you might not kindle fire on my altar in vain! I have no pleasure in you,\" says Yahweh of Armies, \"neither will I accept an offering at your hand.",
                "For from the rising of the sun even to the going down of the same, my name is great among the nations, and in every place incense will be offered to my name, and a pure offering: for my name is great among the nations,\" says Yahweh of Armies.",
                "\"But you profane it, in that you say, \u2018Yahweh\u2019s table is polluted, and its fruit, even its food, is contemptible.\u2019",
                "You say also, \u2018Behold, what a weariness it is!\u2019 and you have sniffed at it,\" says Yahweh of Armies; \"and you have brought that which was taken by violence, the lame, and the sick; thus you bring the offering. Should I accept this at your hand?\" says Yahweh.",
                "\"But the deceiver is cursed, who has in his flock a male, and vows, and sacrifices to the Lord a blemished thing; for I am a great King,\" says Yahweh of Armies, \"and my name is awesome among the nations.\""
            ],
            2: [
                "\"Now, you priests, this commandment is for you.",
                "If you will not listen, and if you will not lay it to heart, to give glory to my name,\" says Yahweh of Armies, \"then will I send the curse on you, and I will curse your blessings. Indeed, I have cursed them already, because you do not lay it to heart.",
                "Behold, I will rebuke your seed, and will spread dung on your faces, even the dung of your feasts; and you will be taken away with it.",
                "You will know that I have sent this commandment to you, that my covenant may be with Levi,\" says Yahweh of Armies.",
                "\"My covenant was with him of life and peace; and I gave them to him that he might be reverent toward me; and he was reverent toward me, and stood in awe of my name.",
                "The law of truth was in his mouth, and unrighteousness was not found in his lips. He walked with me in peace and uprightness, and turned many away from iniquity.",
                "For the priest\u2019s lips should keep knowledge, and they should seek the law at his mouth; for he is the messenger of Yahweh of Armies.",
                "But you have turned aside out of the way. You have caused many to stumble in the law. You have corrupted the covenant of Levi,\" says Yahweh of Armies.",
                "\"Therefore I have also made you contemptible and base before all the people, according to the way you have not kept my ways, but have had respect for persons in the law.",
                "Don\u2019t we all have one father? Hasn\u2019t one God created us? Why do we deal treacherously every man against his brother, profaning the covenant of our fathers?",
                "Judah has dealt treacherously, and an abomination is committed in Israel and in Jerusalem; for Judah has profaned the holiness of Yahweh which he loves, and has married the daughter of a foreign god.",
                "Yahweh will cut off, to the man who does this, him who wakes and him who answers, out of the tents of Jacob, and him who offers an offering to Yahweh of Armies.",
                "This again you do: you cover the altar of Yahweh with tears, with weeping, and with sighing, because he doesn\u2019t regard the offering any more, neither receives it with good will at your hand.",
                "Yet you say, \u2018Why?\u2019 Because Yahweh has been witness between you and the wife of your youth, against whom you have dealt treacherously, though she is your companion, and the wife of your covenant.",
                "Did he not make you one, although he had the residue of the Spirit? Why one? He sought a godly seed. Therefore take heed to your spirit, and let no one deal treacherously against the wife of his youth.",
                "For I hate divorce,\" says Yahweh, the God of Israel, \"and him who covers his garment with violence!\" says Yahweh of Armies. \"Therefore take heed to your spirit, that you don\u2019t deal treacherously.",
                "You have wearied Yahweh with your words. Yet you say, \u2018How have we wearied him?\u2019 In that you say, \u2018Everyone who does evil is good in the sight of Yahweh, and he delights in them;\u2019 or \u2018Where is the God of justice?\u2019"
            ],
            3: [
                "\"Behold, I send my messenger, and he will prepare the way before me; and the Lord, whom you seek, will suddenly come to his temple; and the messenger of the covenant, whom you desire, behold, he comes!\" says Yahweh of Armies.",
                "\"But who can endure the day of his coming? And who will stand when he appears? For he is like a refiner\u2019s fire, and like launderer\u2019s soap;",
                "and he will sit as a refiner and purifier of silver, and he will purify the sons of Levi, and refine them as gold and silver; and they shall offer to Yahweh offerings in righteousness.",
                "Then the offering of Judah and Jerusalem will be pleasant to Yahweh, as in the days of old, and as in ancient years.",
                "I will come near to you to judgment; and I will be a swift witness against the sorcerers, and against the adulterers, and against the perjurers, and against those who oppress the hireling in his wages, the widow, and the fatherless, and who deprive the foreigner of justice, and don\u2019t fear me,\" says Yahweh of Armies.",
                "\"For I, Yahweh, don\u2019t change; therefore you, sons of Jacob, are not consumed.",
                "From the days of your fathers you have turned aside from my ordinances, and have not kept them. Return to me, and I will return to you,\" says Yahweh of Armies. \"But you say, \u2018How shall we return?\u2019",
                "Will a man rob God? Yet you rob me! But you say, \u2018How have we robbed you?\u2019 In tithes and offerings.",
                "You are cursed with the curse; for you rob me, even this whole nation.",
                "Bring the whole tithe into the storehouse, that there may be food in my house, and test me now in this,\" says Yahweh of Armies, \"if I will not open you the windows of heaven, and pour you out a blessing, that there shall not be room enough for.",
                "I will rebuke the devourer for your sakes, and he shall not destroy the fruits of your ground; neither shall your vine cast its fruit before its time in the field,\" says Yahweh of Armies.",
                "\"All nations shall call you blessed, for you will be a delightful land,\" says Yahweh of Armies.",
                "\"Your words have been stout against me,\" says Yahweh. \"Yet you say, \u2018What have we spoken against you?\u2019",
                "You have said, \u2018It is vain to serve God;\u2019 and \u2018What profit is it that we have followed his instructions, and that we have walked mournfully before Yahweh of Armies?",
                "Now we call the proud happy; yes, those who work wickedness are built up; yes, they tempt God, and escape.\u2019",
                "Then those who feared Yahweh spoke one with another; and Yahweh listened, and heard, and a book of memory was written before him, for those who feared Yahweh, and who honored his name.",
                "They shall be mine,\" says Yahweh of Armies, \"my own possession in the day that I make, and I will spare them, as a man spares his own son who serves him.",
                "Then you shall return and discern between the righteous and the wicked, between him who serves God and him who doesn\u2019t serve him."
            ],
            4: [
                "\"For, behold, the day comes, it burns as a furnace; and all the proud, and all who work wickedness, will be stubble; and the day that comes will burn them up,\" says Yahweh of Armies, \"that it shall leave them neither root nor branch.",
                "But to you who fear my name shall the sun of righteousness arise with healing in its wings. You will go out, and leap like calves of the stall.",
                "You shall tread down the wicked; for they will be ashes under the soles of your feet in the day that I make,\" says Yahweh of Armies.",
                "\"Remember the law of Moses my servant, which I commanded to him in Horeb for all Israel, even statutes and ordinances.",
                "Behold, I will send you Elijah the prophet before the great and terrible day of Yahweh comes.",
                "He will turn the hearts of the fathers to the children, and the hearts of the children to their fathers, lest I come and strike the earth with a curse.\""
            ]
        },
        40: {
            1: [
                "The book of the genealogy of Jesus Christ, the son of David, the son of Abraham.",
                "Abraham became the father of Isaac. Isaac became the father of Jacob. Jacob became the father of Judah and his brothers.",
                "Judah became the father of Perez and Zerah by Tamar. Perez became the father of Hezron. Hezron became the father of Ram.",
                "Ram became the father of Amminadab. Amminadab became the father of Nahshon. Nahshon became the father of Salmon.",
                "Salmon became the father of Boaz by Rahab. Boaz became the father of Obed by Ruth. Obed became the father of Jesse.",
                "Jesse became the father of King David. David became the father of Solomon by her who had been the wife of Uriah.",
                "Solomon became the father of Rehoboam. Rehoboam became the father of Abijah. Abijah became the father of Asa.",
                "Asa became the father of Jehoshaphat. Jehoshaphat became the father of Joram. Joram became the father of Uzziah.",
                "Uzziah became the father of Jotham. Jotham became the father of Ahaz. Ahaz became the father of Hezekiah.",
                "Hezekiah became the father of Manasseh. Manasseh became the father of Amon. Amon became the father of Josiah.",
                "Josiah became the father of Jechoniah and his brothers, at the time of the exile to Babylon.",
                "After the exile to Babylon, Jechoniah became the father of Shealtiel. Shealtiel became the father of Zerubbabel.",
                "Zerubbabel became the father of Abiud. Abiud became the father of Eliakim. Eliakim became the father of Azor.",
                "Azor became the father of Sadoc. Sadoc became the father of Achim. Achim became the father of Eliud.",
                "Eliud became the father of Eleazar. Eleazar became the father of Matthan. Matthan became the father of Jacob.",
                "Jacob became the father of Joseph, the husband of Mary, from whom was born Jesus, who is called Christ.",
                "So all the generations from Abraham to David are fourteen generations; from David to the exile to Babylon fourteen generations; and from the carrying away to Babylon to the Christ, fourteen generations.",
                "Now the birth of Jesus Christ was like this; for after his mother, Mary, was engaged to Joseph, before they came together, she was found pregnant by the Holy Spirit.",
                "Joseph, her husband, being a righteous man, and not willing to make her a public example, intended to put her away secretly.",
                "But when he thought about these things, behold, an angel of the Lord appeared to him in a dream, saying, \"Joseph, son of David, don\u2019t be afraid to take to yourself Mary, your wife, for that which is conceived in her is of the Holy Spirit.",
                "She shall bring forth a son. You shall call his name Jesus, for it is he who shall save his people from their sins.\"",
                "Now all this has happened, that it might be fulfilled which was spoken by the Lord through the prophet, saying,",
                "\"Behold, the virgin shall be with child, and shall bring forth a son. They shall call his name Immanuel;\" which is, being interpreted, \"God with us.\"",
                "Joseph arose from his sleep, and did as the angel of the Lord commanded him, and took his wife to himself;",
                "and didn\u2019t know her sexually until she had brought forth her firstborn son. He named him Jesus."
            ],
            2: [
                "Now when Jesus was born in Bethlehem of Judea in the days of King Herod, behold, wise men from the east came to Jerusalem, saying,",
                "\"Where is he who is born King of the Jews? For we saw his star in the east, and have come to worship him.\"",
                "When King Herod heard it, he was troubled, and all Jerusalem with him.",
                "Gathering together all the chief priests and scribes of the people, he asked them where the Christ would be born.",
                "They said to him, \"In Bethlehem of Judea, for this is written through the prophet,",
                "\u2018You Bethlehem, land of Judah, are in no way least among the princes of Judah: for out of you shall come forth a governor, who shall shepherd my people, Israel.\u2019\"",
                "Then Herod secretly called the wise men, and learned from them exactly what time the star appeared.",
                "He sent them to Bethlehem, and said, \"Go and search diligently for the young child. When you have found him, bring me word, so that I also may come and worship him.\"",
                "They, having heard the king, went their way; and behold, the star, which they saw in the east, went before them, until it came and stood over where the young child was.",
                "When they saw the star, they rejoiced with exceedingly great joy.",
                "They came into the house and saw the young child with Mary, his mother, and they fell down and worshiped him. Opening their treasures, they offered to him gifts: gold, frankincense, and myrrh.",
                "Being warned in a dream that they shouldn\u2019t return to Herod, they went back to their own country another way.",
                "Now when they had departed, behold, an angel of the Lord appeared to Joseph in a dream, saying, \"Arise and take the young child and his mother, and flee into Egypt, and stay there until I tell you, for Herod will seek the young child to destroy him.\"",
                "He arose and took the young child and his mother by night, and departed into Egypt,",
                "and was there until the death of Herod; that it might be fulfilled which was spoken by the Lord through the prophet, saying, \"Out of Egypt I called my son.\"",
                "Then Herod, when he saw that he was mocked by the wise men, was exceedingly angry, and sent out, and killed all the male children who were in Bethlehem and in all the surrounding countryside, from two years old and under, according to the exact time which he had learned from the wise men.",
                "Then that which was spoken by Jeremiah the prophet was fulfilled, saying,",
                "\"A voice was heard in Ramah, lamentation, weeping and great mourning, Rachel weeping for her children; she wouldn\u2019t be comforted, because they are no more.\"",
                "But when Herod was dead, behold, an angel of the Lord appeared in a dream to Joseph in Egypt, saying,",
                "\"Arise and take the young child and his mother, and go into the land of Israel, for those who sought the young child\u2019s life are dead.\"",
                "He arose and took the young child and his mother, and came into the land of Israel.",
                "But when he heard that Archelaus was reigning over Judea in the place of his father, Herod, he was afraid to go there. Being warned in a dream, he withdrew into the region of Galilee,",
                "and came and lived in a city called Nazareth; that it might be fulfilled which was spoken through the prophets: \"He will be called a Nazarene.\""
            ],
            3: [
                "In those days, John the Baptizer came, preaching in the wilderness of Judea, saying,",
                "\"Repent, for the Kingdom of Heaven is at hand!\"",
                "For this is he who was spoken of by Isaiah the prophet, saying, \"The voice of one crying in the wilderness, make ready the way of the Lord. Make his paths straight.\"",
                "Now John himself wore clothing made of camel\u2019s hair, with a leather belt around his waist. His food was locusts and wild honey.",
                "Then people from Jerusalem, all of Judea, and all the region around the Jordan went out to him.",
                "They were baptized by him in the Jordan, confessing their sins.",
                "But when he saw many of the Pharisees and Sadducees coming for his baptism, he said to them, \"You offspring of vipers, who warned you to flee from the wrath to come?",
                "Therefore bring forth fruit worthy of repentance!",
                "Don\u2019t think to yourselves, \u2018We have Abraham for our father,\u2019 for I tell you that God is able to raise up children to Abraham from these stones.",
                "\"Even now the axe lies at the root of the trees. Therefore, every tree that doesn\u2019t bring forth good fruit is cut down, and cast into the fire.",
                "I indeed baptize you in water for repentance, but he who comes after me is mightier than I, whose shoes I am not worthy to carry. He will baptize you in the Holy Spirit.",
                "His winnowing fork is in his hand, and he will thoroughly cleanse his threshing floor. He will gather his wheat into the barn, but the chaff he will burn up with unquenchable fire.\"",
                "Then Jesus came from Galilee to the Jordan to John, to be baptized by him.",
                "But John would have hindered him, saying, \"I need to be baptized by you, and you come to me?\"",
                "But Jesus, answering, said to him, \"Allow it now, for this is the fitting way for us to fulfill all righteousness.\" Then he allowed him.",
                "Jesus, when he was baptized, went up directly from the water: and behold, the heavens were opened to him. He saw the Spirit of God descending as a dove, and coming on him.",
                "Behold, a voice out of the heavens said, \"This is my beloved Son, with whom I am well pleased.\""
            ],
            4: [
                "Then Jesus was led up by the Spirit into the wilderness to be tempted by the devil.",
                "When he had fasted forty days and forty nights, he was hungry afterward.",
                "The tempter came and said to him, \"If you are the Son of God, command that these stones become bread.\"",
                "But he answered, \"It is written, \u2018Man shall not live by bread alone, but by every word that proceeds out of the mouth of God.\u2019\"",
                "Then the devil took him into the holy city. He set him on the pinnacle of the temple,",
                "and said to him, \"If you are the Son of God, throw yourself down, for it is written, \u2018He will put his angels in charge of you.\u2019 and, \u2018On their hands they will bear you up, so that you don\u2019t dash your foot against a stone.\u2019\"",
                "Jesus said to him, \"Again, it is written, \u2018You shall not test the Lord, your God.\u2019\"",
                "Again, the devil took him to an exceedingly high mountain, and showed him all the kingdoms of the world, and their glory.",
                "He said to him, \"I will give you all of these things, if you will fall down and worship me.\"",
                "Then Jesus said to him, \"Get behind me, Satan! For it is written, \u2018You shall worship the Lord your God, and you shall serve him only.\u2019\"",
                "Then the devil left him, and behold, angels came and served him.",
                "Now when Jesus heard that John was delivered up, he withdrew into Galilee.",
                "Leaving Nazareth, he came and lived in Capernaum, which is by the sea, in the region of Zebulun and Naphtali,",
                "that it might be fulfilled which was spoken through Isaiah the prophet, saying,",
                "\"The land of Zebulun and the land of Naphtali, toward the sea, beyond the Jordan, Galilee of the Gentiles,",
                "the people who sat in darkness saw a great light, to those who sat in the region and shadow of death, to them light has dawned.\"",
                "From that time, Jesus began to preach, and to say, \"Repent! For the Kingdom of Heaven is at hand.\"",
                "Walking by the sea of Galilee, he saw two brothers: Simon, who is called Peter, and Andrew, his brother, casting a net into the sea; for they were fishermen.",
                "He said to them, \"Come after me, and I will make you fishers for men.\"",
                "They immediately left their nets and followed him.",
                "Going on from there, he saw two other brothers, James the son of Zebedee, and John his brother, in the boat with Zebedee their father, mending their nets. He called them.",
                "They immediately left the boat and their father, and followed him.",
                "Jesus went about in all Galilee, teaching in their synagogues, preaching the Good News of the Kingdom, and healing every disease and every sickness among the people.",
                "The report about him went out into all Syria. They brought to him all who were sick, afflicted with various diseases and torments, possessed with demons, epileptics, and paralytics; and he healed them.",
                "Great multitudes from Galilee, Decapolis, Jerusalem, Judea and from beyond the Jordan followed him."
            ],
            5: [
                "Seeing the multitudes, he went up onto the mountain. When he had sat down, his disciples came to him.",
                "He opened his mouth and taught them, saying,",
                "\"Blessed are the poor in spirit, for theirs is the Kingdom of Heaven.",
                "Blessed are those who mourn, for they shall be comforted.",
                "Blessed are the gentle, for they shall inherit the earth.",
                "Blessed are those who hunger and thirst after righteousness, for they shall be filled.",
                "Blessed are the merciful, for they shall obtain mercy.",
                "Blessed are the pure in heart, for they shall see God.",
                "Blessed are the peacemakers, for they shall be called children of God.",
                "Blessed are those who have been persecuted for righteousness\u2019 sake, for theirs is the Kingdom of Heaven.",
                "\"Blessed are you when people reproach you, persecute you, and say all kinds of evil against you falsely, for my sake.",
                "Rejoice, and be exceedingly glad, for great is your reward in heaven. For that is how they persecuted the prophets who were before you.",
                "\"You are the salt of the earth, but if the salt has lost its flavor, with what will it be salted? It is then good for nothing, but to be cast out and trodden under the feet of men.",
                "You are the light of the world. A city located on a hill can\u2019t be hidden.",
                "Neither do you light a lamp, and put it under a measuring basket, but on a stand; and it shines to all who are in the house.",
                "Even so, let your light shine before men; that they may see your good works, and glorify your Father who is in heaven.",
                "\"Don\u2019t think that I came to destroy the law or the prophets. I didn\u2019t come to destroy, but to fulfill.",
                "For most certainly, I tell you, until heaven and earth pass away, not even one smallest letter or one tiny pen stroke shall in any way pass away from the law, until all things are accomplished.",
                "Whoever, therefore, shall break one of these least commandments, and teach others to do so, shall be called least in the Kingdom of Heaven; but whoever shall do and teach them shall be called great in the Kingdom of Heaven.",
                "For I tell you that unless your righteousness exceeds that of the scribes and Pharisees, there is no way you will enter into the Kingdom of Heaven.",
                "\"You have heard that it was said to the ancient ones, \u2018You shall not murder;\u2019 and \u2018Whoever shall murder shall be in danger of the judgment.\u2019",
                "But I tell you, that everyone who is angry with his brother without a cause shall be in danger of the judgment; and whoever shall say to his brother, \u2018Raca!\u2019 shall be in danger of the council; and whoever shall say, \u2018You fool!\u2019 shall be in danger of the fire of Gehenna.",
                "\"If therefore you are offering your gift at the altar, and there remember that your brother has anything against you,",
                "leave your gift there before the altar, and go your way. First be reconciled to your brother, and then come and offer your gift.",
                "Agree with your adversary quickly, while you are with him in the way; lest perhaps the prosecutor deliver you to the judge, and the judge deliver you to the officer, and you be cast into prison.",
                "Most certainly I tell you, you shall by no means get out of there, until you have paid the last penny.",
                "\"You have heard that it was said, \u2018You shall not commit adultery;\u2019",
                "but I tell you that everyone who gazes at a woman to lust after her has committed adultery with her already in his heart.",
                "If your right eye causes you to stumble, pluck it out and throw it away from you. For it is more profitable for you that one of your members should perish, than for your whole body to be cast into Gehenna.",
                "If your right hand causes you to stumble, cut it off, and throw it away from you. For it is more profitable for you that one of your members should perish, than for your whole body to be cast into Gehenna.",
                "\"It was also said, \u2018Whoever shall put away his wife, let him give her a writing of divorce,\u2019",
                "but I tell you that whoever puts away his wife, except for the cause of sexual immorality, makes her an adulteress; and whoever marries her when she is put away commits adultery.",
                "\"Again you have heard that it was said to them of old time, \u2018You shall not make false vows, but shall perform to the Lord your vows,\u2019",
                "but I tell you, don\u2019t swear at all: neither by heaven, for it is the throne of God;",
                "nor by the earth, for it is the footstool of his feet; nor by Jerusalem, for it is the city of the great King.",
                "Neither shall you swear by your head, for you can\u2019t make one hair white or black.",
                "But let your \u2018Yes\u2019 be \u2018Yes\u2019 and your \u2018No\u2019 be \u2018No.\u2019 Whatever is more than these is of the evil one.",
                "\"You have heard that it was said, \u2018An eye for an eye, and a tooth for a tooth.\u2019",
                "But I tell you, don\u2019t resist him who is evil; but whoever strikes you on your right cheek, turn to him the other also.",
                "If anyone sues you to take away your coat, let him have your cloak also.",
                "Whoever compels you to go one mile, go with him two.",
                "Give to him who asks you, and don\u2019t turn away him who desires to borrow from you.",
                "\"You have heard that it was said, \u2018You shall love your neighbor, and hate your enemy.\u2019",
                "But I tell you, love your enemies, bless those who curse you, do good to those who hate you, and pray for those who mistreat you and persecute you,",
                "that you may be children of your Father who is in heaven. For he makes his sun to rise on the evil and the good, and sends rain on the just and the unjust.",
                "For if you love those who love you, what reward do you have? Don\u2019t even the tax collectors do the same?",
                "If you only greet your friends, what more do you do than others? Don\u2019t even the tax collectors do the same?",
                "Therefore you shall be perfect, just as your Father in heaven is perfect."
            ],
            6: [
                "\"Be careful that you don\u2019t do your charitable giving before men, to be seen by them, or else you have no reward from your Father who is in heaven.",
                "Therefore when you do merciful deeds, don\u2019t sound a trumpet before yourself, as the hypocrites do in the synagogues and in the streets, that they may get glory from men. Most certainly I tell you, they have received their reward.",
                "But when you do merciful deeds, don\u2019t let your left hand know what your right hand does,",
                "so that your merciful deeds may be in secret, then your Father who sees in secret will reward you openly.",
                "\"When you pray, you shall not be as the hypocrites, for they love to stand and pray in the synagogues and in the corners of the streets, that they may be seen by men. Most certainly, I tell you, they have received their reward.",
                "But you, when you pray, enter into your inner room, and having shut your door, pray to your Father who is in secret, and your Father who sees in secret will reward you openly.",
                "In praying, don\u2019t use vain repetitions, as the Gentiles do; for they think that they will be heard for their much speaking.",
                "Therefore don\u2019t be like them, for your Father knows what things you need, before you ask him.",
                "Pray like this: \u2018Our Father in heaven, may your name be kept holy.",
                "Let your Kingdom come. Let your will be done, as in heaven, so on earth.",
                "Give us today our daily bread.",
                "Forgive us our debts, as we also forgive our debtors.",
                "Bring us not into temptation, but deliver us from the evil one. For yours is the Kingdom, the power, and the glory forever. Amen.\u2019",
                "\"For if you forgive men their trespasses, your heavenly Father will also forgive you.",
                "But if you don\u2019t forgive men their trespasses, neither will your Father forgive your trespasses.",
                "\"Moreover when you fast, don\u2019t be like the hypocrites, with sad faces. For they disfigure their faces, that they may be seen by men to be fasting. Most certainly I tell you, they have received their reward.",
                "But you, when you fast, anoint your head, and wash your face;",
                "so that you are not seen by men to be fasting, but by your Father who is in secret, and your Father, who sees in secret, will reward you.",
                "\"Don\u2019t lay up treasures for yourselves on the earth, where moth and rust consume, and where thieves break through and steal;",
                "but lay up for yourselves treasures in heaven, where neither moth nor rust consume, and where thieves don\u2019t break through and steal;",
                "for where your treasure is, there your heart will be also.",
                "\"The lamp of the body is the eye. If therefore your eye is sound, your whole body will be full of light.",
                "But if your eye is evil, your whole body will be full of darkness. If therefore the light that is in you is darkness, how great is the darkness!",
                "\"No one can serve two masters, for either he will hate the one and love the other; or else he will be devoted to one and despise the other. You can\u2019t serve both God and Mammon.",
                "Therefore, I tell you, don\u2019t be anxious for your life: what you will eat, or what you will drink; nor yet for your body, what you will wear. Isn\u2019t life more than food, and the body more than clothing?",
                "See the birds of the sky, that they don\u2019t sow, neither do they reap, nor gather into barns. Your heavenly Father feeds them. Aren\u2019t you of much more value than they?",
                "\"Which of you, by being anxious, can add one moment to his lifespan?",
                "Why are you anxious about clothing? Consider the lilies of the field, how they grow. They don\u2019t toil, neither do they spin,",
                "yet I tell you that even Solomon in all his glory was not dressed like one of these.",
                "But if God so clothes the grass of the field, which today exists, and tomorrow is thrown into the oven, won\u2019t he much more clothe you, you of little faith?",
                "\"Therefore don\u2019t be anxious, saying, \u2018What will we eat?\u2019, \u2018What will we drink?\u2019 or, \u2018With what will we be clothed?\u2019",
                "For the Gentiles seek after all these things; for your heavenly Father knows that you need all these things.",
                "But seek first God\u2019s Kingdom, and his righteousness; and all these things will be given to you as well.",
                "Therefore don\u2019t be anxious for tomorrow, for tomorrow will be anxious for itself. Each day\u2019s own evil is sufficient."
            ],
            7: [
                "\"Don\u2019t judge, so that you won\u2019t be judged.",
                "For with whatever judgment you judge, you will be judged; and with whatever measure you measure, it will be measured to you.",
                "Why do you see the speck that is in your brother\u2019s eye, but don\u2019t consider the beam that is in your own eye?",
                "Or how will you tell your brother, \u2018Let me remove the speck from your eye;\u2019 and behold, the beam is in your own eye?",
                "You hypocrite! First remove the beam out of your own eye, and then you can see clearly to remove the speck out of your brother\u2019s eye.",
                "\"Don\u2019t give that which is holy to the dogs, neither throw your pearls before the pigs, lest perhaps they trample them under their feet, and turn and tear you to pieces.",
                "\"Ask, and it will be given you. Seek, and you will find. Knock, and it will be opened for you.",
                "For everyone who asks receives. He who seeks finds. To him who knocks it will be opened.",
                "Or who is there among you, who, if his son asks him for bread, will give him a stone?",
                "Or if he asks for a fish, who will give him a serpent?",
                "If you then, being evil, know how to give good gifts to your children, how much more will your Father who is in heaven give good things to those who ask him!",
                "Therefore whatever you desire for men to do to you, you shall also do to them; for this is the law and the prophets.",
                "\"Enter in by the narrow gate; for wide is the gate and broad is the way that leads to destruction, and many are those who enter in by it.",
                "How narrow is the gate, and restricted is the way that leads to life! Few are those who find it.",
                "\"Beware of false prophets, who come to you in sheep\u2019s clothing, but inwardly are ravening wolves.",
                "By their fruits you will know them. Do you gather grapes from thorns, or figs from thistles?",
                "Even so, every good tree produces good fruit; but the corrupt tree produces evil fruit.",
                "A good tree can\u2019t produce evil fruit, neither can a corrupt tree produce good fruit.",
                "Every tree that doesn\u2019t grow good fruit is cut down, and thrown into the fire.",
                "Therefore, by their fruits you will know them.",
                "Not everyone who says to me, \u2018Lord, Lord,\u2019 will enter into the Kingdom of Heaven; but he who does the will of my Father who is in heaven.",
                "Many will tell me in that day, \u2018Lord, Lord, didn\u2019t we prophesy in your name, in your name cast out demons, and in your name do many mighty works?\u2019",
                "Then I will tell them, \u2018I never knew you. Depart from me, you who work iniquity.\u2019",
                "\"Everyone therefore who hears these words of mine, and does them, I will liken him to a wise man, who built his house on a rock.",
                "The rain came down, the floods came, and the winds blew, and beat on that house; and it didn\u2019t fall, for it was founded on the rock.",
                "Everyone who hears these words of mine, and doesn\u2019t do them will be like a foolish man, who built his house on the sand.",
                "The rain came down, the floods came, and the winds blew, and beat on that house; and it fell\u2014and great was its fall.\"",
                "It happened, when Jesus had finished saying these things, that the multitudes were astonished at his teaching,",
                "for he taught them with authority, and not like the scribes."
            ],
            8: [
                "When he came down from the mountain, great multitudes followed him.",
                "Behold, a leper came to him and worshiped him, saying, \"Lord, if you want to, you can make me clean.\"",
                "Jesus stretched out his hand, and touched him, saying, \"I want to. Be made clean.\" Immediately his leprosy was cleansed.",
                "Jesus said to him, \"See that you tell nobody, but go, show yourself to the priest, and offer the gift that Moses commanded, as a testimony to them.\"",
                "When he came into Capernaum, a centurion came to him, asking him,",
                "and saying, \"Lord, my servant lies in the house paralyzed, grievously tormented.\"",
                "Jesus said to him, \"I will come and heal him.\"",
                "The centurion answered, \"Lord, I\u2019m not worthy for you to come under my roof. Just say the word, and my servant will be healed.",
                "For I am also a man under authority, having under myself soldiers. I tell this one, \u2018Go,\u2019 and he goes; and tell another, \u2018Come,\u2019 and he comes; and tell my servant, \u2018Do this,\u2019 and he does it.\"",
                "When Jesus heard it, he marveled, and said to those who followed, \"Most certainly I tell you, I haven\u2019t found so great a faith, not even in Israel.",
                "I tell you that many will come from the east and the west, and will sit down with Abraham, Isaac, and Jacob in the Kingdom of Heaven,",
                "but the children of the Kingdom will be thrown out into the outer darkness. There will be weeping and gnashing of teeth.\"",
                "Jesus said to the centurion, \"Go your way. Let it be done for you as you have believed.\" His servant was healed in that hour.",
                "When Jesus came into Peter\u2019s house, he saw his wife\u2019s mother lying sick with a fever.",
                "He touched her hand, and the fever left her. She got up and served him.",
                "When evening came, they brought to him many possessed with demons. He cast out the spirits with a word, and healed all who were sick;",
                "that it might be fulfilled which was spoken through Isaiah the prophet, saying: \"He took our infirmities, and bore our diseases.\"",
                "Now when Jesus saw great multitudes around him, he gave the order to depart to the other side.",
                "A scribe came, and said to him, \"Teacher, I will follow you wherever you go.\"",
                "Jesus said to him, \"The foxes have holes, and the birds of the sky have nests, but the Son of Man has nowhere to lay his head.\"",
                "Another of his disciples said to him, \"Lord, allow me first to go and bury my father.\"",
                "But Jesus said to him, \"Follow me, and leave the dead to bury their own dead.\"",
                "When he got into a boat, his disciples followed him.",
                "Behold, a violent storm came up on the sea, so much that the boat was covered with the waves, but he was asleep.",
                "They came to him, and woke him up, saying, \"Save us, Lord! We are dying!\"",
                "He said to them, \"Why are you fearful, O you of little faith?\" Then he got up, rebuked the wind and the sea, and there was a great calm.",
                "The men marveled, saying, \"What kind of man is this, that even the wind and the sea obey him?\"",
                "When he came to the other side, into the country of the Gergesenes, two people possessed by demons met him there, coming out of the tombs, exceedingly fierce, so that nobody could pass that way.",
                "Behold, they cried out, saying, \"What do we have to do with you, Jesus, Son of God? Have you come here to torment us before the time?\"",
                "Now there was a herd of many pigs feeding far away from them.",
                "The demons begged him, saying, \"If you cast us out, permit us to go away into the herd of pigs.\"",
                "He said to them, \"Go!\" They came out, and went into the herd of pigs: and behold, the whole herd of pigs rushed down the cliff into the sea, and died in the water.",
                "Those who fed them fled, and went away into the city, and told everything, including what happened to those who were possessed with demons.",
                "Behold, all the city came out to meet Jesus. When they saw him, they begged that he would depart from their borders."
            ],
            9: [
                "He entered into a boat, and crossed over, and came into his own city.",
                "Behold, they brought to him a man who was paralyzed, lying on a bed. Jesus, seeing their faith, said to the paralytic, \"Son, cheer up! Your sins are forgiven you.\"",
                "Behold, some of the scribes said to themselves, \"This man blasphemes.\"",
                "Jesus, knowing their thoughts, said, \"Why do you think evil in your hearts?",
                "For which is easier, to say, \u2018Your sins are forgiven;\u2019 or to say, \u2018Get up, and walk?\u2019",
                "But that you may know that the Son of Man has authority on earth to forgive sins...\" (then he said to the paralytic), \"Get up, and take up your mat, and go up to your house.\"",
                "He arose and departed to his house.",
                "But when the multitudes saw it, they marveled and glorified God, who had given such authority to men.",
                "As Jesus passed by from there, he saw a man called Matthew sitting at the tax collection office. He said to him, \"Follow me.\" He got up and followed him.",
                "It happened as he sat in the house, behold, many tax collectors and sinners came and sat down with Jesus and his disciples.",
                "When the Pharisees saw it, they said to his disciples, \"Why does your teacher eat with tax collectors and sinners?\"",
                "When Jesus heard it, he said to them, \"Those who are healthy have no need for a physician, but those who are sick do.",
                "But you go and learn what this means: \u2018I desire mercy, and not sacrifice,\u2019 for I came not to call the righteous, but sinners to repentance.\"",
                "Then John\u2019s disciples came to him, saying, \"Why do we and the Pharisees fast often, but your disciples don\u2019t fast?\"",
                "Jesus said to them, \"Can the friends of the bridegroom mourn, as long as the bridegroom is with them? But the days will come when the bridegroom will be taken away from them, and then they will fast.",
                "No one puts a piece of unshrunk cloth on an old garment; for the patch would tear away from the garment, and a worse hole is made.",
                "Neither do people put new wine into old wineskins, or else the skins would burst, and the wine be spilled, and the skins ruined. No, they put new wine into fresh wineskins, and both are preserved.\"",
                "While he told these things to them, behold, a ruler came and worshiped him, saying, \"My daughter has just died, but come and lay your hand on her, and she will live.\"",
                "Jesus got up and followed him, as did his disciples.",
                "Behold, a woman who had an issue of blood for twelve years came behind him, and touched the fringe of his garment;",
                "for she said within herself, \"If I just touch his garment, I will be made well.\"",
                "But Jesus, turning around and seeing her, said, \"Daughter, cheer up! Your faith has made you well.\" And the woman was made well from that hour.",
                "When Jesus came into the ruler\u2019s house, and saw the flute players, and the crowd in noisy disorder,",
                "he said to them, \"Make room, because the girl isn\u2019t dead, but sleeping.\" They were ridiculing him.",
                "But when the crowd was put out, he entered in, took her by the hand, and the girl arose.",
                "The report of this went out into all that land.",
                "As Jesus passed by from there, two blind men followed him, calling out and saying, \"Have mercy on us, son of David!\"",
                "When he had come into the house, the blind men came to him. Jesus said to them, \"Do you believe that I am able to do this?\" They told him, \"Yes, Lord.\"",
                "Then he touched their eyes, saying, \"According to your faith be it done to you.\"",
                "Their eyes were opened. Jesus strictly commanded them, saying, \"See that no one knows about this.\"",
                "But they went out and spread abroad his fame in all that land.",
                "As they went out, behold, a mute man who was demon possessed was brought to him.",
                "When the demon was cast out, the mute man spoke. The multitudes marveled, saying, \"Nothing like this has ever been seen in Israel!\"",
                "But the Pharisees said, \"By the prince of the demons, he casts out demons.\"",
                "Jesus went about all the cities and the villages, teaching in their synagogues, and preaching the Good News of the Kingdom, and healing every disease and every sickness among the people.",
                "But when he saw the multitudes, he was moved with compassion for them, because they were harassed and scattered, like sheep without a shepherd.",
                "Then he said to his disciples, \"The harvest indeed is plentiful, but the laborers are few.",
                "Pray therefore that the Lord of the harvest will send out laborers into his harvest.\""
            ],
            10: [
                "He called to himself his twelve disciples, and gave them authority over unclean spirits, to cast them out, and to heal every disease and every sickness.",
                "Now the names of the twelve apostles are these. The first, Simon, who is called Peter; Andrew, his brother; James the son of Zebedee; John, his brother;",
                "Philip; Bartholomew; Thomas; Matthew the tax collector; James the son of Alphaeus; Lebbaeus, whose surname was Thaddaeus;",
                "Simon the Canaanite; and Judas Iscariot, who also betrayed him.",
                "Jesus sent these twelve out, and commanded them, saying, \"Don\u2019t go among the Gentiles, and don\u2019t enter into any city of the Samaritans.",
                "Rather, go to the lost sheep of the house of Israel.",
                "As you go, preach, saying, \u2018The Kingdom of Heaven is at hand!\u2019",
                "Heal the sick, cleanse the lepers, and cast out demons. Freely you received, so freely give.",
                "Don\u2019t take any gold, nor silver, nor brass in your money belts.",
                "Take no bag for your journey, neither two coats, nor shoes, nor staff: for the laborer is worthy of his food.",
                "Into whatever city or village you enter, find out who in it is worthy; and stay there until you go on.",
                "As you enter into the household, greet it.",
                "If the household is worthy, let your peace come on it, but if it isn\u2019t worthy, let your peace return to you.",
                "Whoever doesn\u2019t receive you, nor hear your words, as you go out of that house or that city, shake off the dust from your feet.",
                "Most certainly I tell you, it will be more tolerable for the land of Sodom and Gomorrah in the day of judgment than for that city.",
                "\"Behold, I send you out as sheep in the midst of wolves. Therefore be wise as serpents, and harmless as doves.",
                "But beware of men: for they will deliver you up to councils, and in their synagogues they will scourge you.",
                "Yes, and you will be brought before governors and kings for my sake, for a testimony to them and to the nations.",
                "But when they deliver you up, don\u2019t be anxious how or what you will say, for it will be given you in that hour what you will say.",
                "For it is not you who speak, but the Spirit of your Father who speaks in you.",
                "\"Brother will deliver up brother to death, and the father his child. Children will rise up against parents, and cause them to be put to death.",
                "You will be hated by all men for my name\u2019s sake, but he who endures to the end will be saved.",
                "But when they persecute you in this city, flee into the next, for most certainly I tell you, you will not have gone through the cities of Israel, until the Son of Man has come.",
                "\"A disciple is not above his teacher, nor a servant above his lord.",
                "It is enough for the disciple that he be like his teacher, and the servant like his lord. If they have called the master of the house Beelzebul, how much more those of his household!",
                "Therefore don\u2019t be afraid of them, for there is nothing covered that will not be revealed; and hidden that will not be known.",
                "What I tell you in the darkness, speak in the light; and what you hear whispered in the ear, proclaim on the housetops.",
                "Don\u2019t be afraid of those who kill the body, but are not able to kill the soul. Rather, fear him who is able to destroy both soul and body in Gehenna.",
                "\"Aren\u2019t two sparrows sold for an assarion coin? Not one of them falls on the ground apart from your Father\u2019s will,",
                "but the very hairs of your head are all numbered.",
                "Therefore don\u2019t be afraid. You are of more value than many sparrows.",
                "Everyone therefore who confesses me before men, him I will also confess before my Father who is in heaven.",
                "But whoever denies me before men, him I will also deny before my Father who is in heaven.",
                "\"Don\u2019t think that I came to send peace on the earth. I didn\u2019t come to send peace, but a sword.",
                "For I came to set a man at odds against his father, and a daughter against her mother, and a daughter-in-law against her mother-in-law.",
                "A man\u2019s foes will be those of his own household.",
                "He who loves father or mother more than me is not worthy of me; and he who loves son or daughter more than me isn\u2019t worthy of me.",
                "He who doesn\u2019t take his cross and follow after me, isn\u2019t worthy of me.",
                "He who seeks his life will lose it; and he who loses his life for my sake will find it.",
                "He who receives you receives me, and he who receives me receives him who sent me.",
                "He who receives a prophet in the name of a prophet will receive a prophet\u2019s reward. He who receives a righteous man in the name of a righteous man will receive a righteous man\u2019s reward.",
                "Whoever gives one of these little ones just a cup of cold water to drink in the name of a disciple, most certainly I tell you he will in no way lose his reward.\""
            ],
            11: [
                "It happened that when Jesus had finished directing his twelve disciples, he departed from there to teach and preach in their cities.",
                "Now when John heard in the prison the works of Christ, he sent two of his disciples",
                "and said to him, \"Are you he who comes, or should we look for another?\"",
                "Jesus answered them, \"Go and tell John the things which you hear and see:",
                "the blind receive their sight, the lame walk, the lepers are cleansed, the deaf hear, the dead are raised up, and the poor have good news preached to them.",
                "Blessed is he who finds no occasion for stumbling in me.\"",
                "As these went their way, Jesus began to say to the multitudes concerning John, \"What did you go out into the wilderness to see? A reed shaken by the wind?",
                "But what did you go out to see? A man in soft clothing? Behold, those who wear soft clothing are in king\u2019s houses.",
                "But why did you go out? To see a prophet? Yes, I tell you, and much more than a prophet.",
                "For this is he, of whom it is written, \u2018Behold, I send my messenger before your face, who will prepare your way before you.\u2019",
                "Most certainly I tell you, among those who are born of women there has not arisen anyone greater than John the Baptizer; yet he who is least in the Kingdom of Heaven is greater than he.",
                "From the days of John the Baptizer until now, the Kingdom of Heaven suffers violence, and the violent take it by force.",
                "For all the prophets and the law prophesied until John.",
                "If you are willing to receive it, this is Elijah, who is to come.",
                "He who has ears to hear, let him hear.",
                "\"But to what shall I compare this generation? It is like children sitting in the marketplaces, who call to their companions",
                "and say, \u2018We played the flute for you, and you didn\u2019t dance. We mourned for you, and you didn\u2019t lament.\u2019",
                "For John came neither eating nor drinking, and they say, \u2018He has a demon.\u2019",
                "The Son of Man came eating and drinking, and they say, \u2018Behold, a gluttonous man and a drunkard, a friend of tax collectors and sinners!\u2019 But wisdom is justified by her children.\"",
                "Then he began to denounce the cities in which most of his mighty works had been done, because they didn\u2019t repent.",
                "\"Woe to you, Chorazin! Woe to you, Bethsaida! For if the mighty works had been done in Tyre and Sidon which were done in you, they would have repented long ago in sackcloth and ashes.",
                "But I tell you, it will be more tolerable for Tyre and Sidon on the day of judgment than for you.",
                "You, Capernaum, who are exalted to heaven, you will go down to Hades. For if the mighty works had been done in Sodom which were done in you, it would have remained until this day.",
                "But I tell you that it will be more tolerable for the land of Sodom, on the day of judgment, than for you.\"",
                "At that time, Jesus answered, \"I thank you, Father, Lord of heaven and earth, that you hid these things from the wise and understanding, and revealed them to infants.",
                "Yes, Father, for so it was well-pleasing in your sight.",
                "All things have been delivered to me by my Father. No one knows the Son, except the Father; neither does anyone know the Father, except the Son, and he to whom the Son desires to reveal him.",
                "\"Come to me, all you who labor and are heavily burdened, and I will give you rest.",
                "Take my yoke upon you, and learn from me, for I am gentle and lowly in heart; and you will find rest for your souls.",
                "For my yoke is easy, and my burden is light.\""
            ],
            12: [
                "At that time, Jesus went on the Sabbath day through the grain fields. His disciples were hungry and began to pluck heads of grain and to eat.",
                "But the Pharisees, when they saw it, said to him, \"Behold, your disciples do what is not lawful to do on the Sabbath.\"",
                "But he said to them, \"Haven\u2019t you read what David did, when he was hungry, and those who were with him;",
                "how he entered into the house of God, and ate the show bread, which was not lawful for him to eat, neither for those who were with him, but only for the priests?",
                "Or have you not read in the law, that on the Sabbath day, the priests in the temple profane the Sabbath, and are guiltless?",
                "But I tell you that one greater than the temple is here.",
                "But if you had known what this means, \u2018I desire mercy, and not sacrifice,\u2019 you would not have condemned the guiltless.",
                "For the Son of Man is Lord of the Sabbath.\"",
                "He departed there, and went into their synagogue.",
                "And behold there was a man with a withered hand. They asked him, \"Is it lawful to heal on the Sabbath day?\" that they might accuse him.",
                "He said to them, \"What man is there among you, who has one sheep, and if this one falls into a pit on the Sabbath day, won\u2019t he grab on to it, and lift it out?",
                "Of how much more value then is a man than a sheep! Therefore it is lawful to do good on the Sabbath day.\"",
                "Then he told the man, \"Stretch out your hand.\" He stretched it out; and it was restored whole, just like the other.",
                "But the Pharisees went out, and conspired against him, how they might destroy him.",
                "Jesus, perceiving that, withdrew from there. Great multitudes followed him; and he healed them all,",
                "and commanded them that they should not make him known:",
                "that it might be fulfilled which was spoken through Isaiah the prophet, saying,",
                "\"Behold, my servant whom I have chosen; my beloved in whom my soul is well pleased: I will put my Spirit on him. He will proclaim justice to the nations.",
                "He will not strive, nor shout; neither will anyone hear his voice in the streets.",
                "He won\u2019t break a bruised reed. He won\u2019t quench a smoking flax, until he leads justice to victory.",
                "In his name, the nations will hope.\"",
                "Then one possessed by a demon, blind and mute, was brought to him and he healed him, so that the blind and mute man both spoke and saw.",
                "All the multitudes were amazed, and said, \"Can this be the son of David?\"",
                "But when the Pharisees heard it, they said, \"This man does not cast out demons, except by Beelzebul, the prince of the demons.\"",
                "Knowing their thoughts, Jesus said to them, \"Every kingdom divided against itself is brought to desolation, and every city or house divided against itself will not stand.",
                "If Satan casts out Satan, he is divided against himself. How then will his kingdom stand?",
                "If I by Beelzebul cast out demons, by whom do your children cast them out? Therefore they will be your judges.",
                "But if I by the Spirit of God cast out demons, then the Kingdom of God has come upon you.",
                "Or how can one enter into the house of the strong man, and plunder his goods, unless he first bind the strong man? Then he will plunder his house.",
                "\"He who is not with me is against me, and he who doesn\u2019t gather with me, scatters.",
                "Therefore I tell you, every sin and blasphemy will be forgiven men, but the blasphemy against the Spirit will not be forgiven men.",
                "Whoever speaks a word against the Son of Man, it will be forgiven him; but whoever speaks against the Holy Spirit, it will not be forgiven him, neither in this age, nor in that which is to come.",
                "\"Either make the tree good, and its fruit good, or make the tree corrupt, and its fruit corrupt; for the tree is known by its fruit.",
                "You offspring of vipers, how can you, being evil, speak good things? For out of the abundance of the heart, the mouth speaks.",
                "The good man out of his good treasure brings out good things, and the evil man out of his evil treasure brings out evil things.",
                "I tell you that every idle word that men speak, they will give account of it in the day of judgment.",
                "For by your words you will be justified, and by your words you will be condemned.\"",
                "Then certain of the scribes and Pharisees answered, \"Teacher, we want to see a sign from you.\"",
                "But he answered them, \"An evil and adulterous generation seeks after a sign, but no sign will be given it but the sign of Jonah the prophet.",
                "For as Jonah was three days and three nights in the belly of the whale, so will the Son of Man be three days and three nights in the heart of the earth.",
                "The men of Nineveh will stand up in the judgment with this generation, and will condemn it, for they repented at the preaching of Jonah; and behold, someone greater than Jonah is here.",
                "The queen of the south will rise up in the judgment with this generation, and will condemn it, for she came from the ends of the earth to hear the wisdom of Solomon; and behold, someone greater than Solomon is here.",
                "But the unclean spirit, when he is gone out of the man, passes through waterless places, seeking rest, and doesn\u2019t find it.",
                "Then he says, \u2018I will return into my house from which I came out,\u2019 and when he has come back, he finds it empty, swept, and put in order.",
                "Then he goes, and takes with himself seven other spirits more evil than he is, and they enter in and dwell there. The last state of that man becomes worse than the first. Even so will it be also to this evil generation.\"",
                "While he was yet speaking to the multitudes, behold, his mother and his brothers stood outside, seeking to speak to him.",
                "One said to him, \"Behold, your mother and your brothers stand outside, seeking to speak to you.\"",
                "But he answered him who spoke to him, \"Who is my mother? Who are my brothers?\"",
                "He stretched out his hand towards his disciples, and said, \"Behold, my mother and my brothers!",
                "For whoever does the will of my Father who is in heaven, he is my brother, and sister, and mother.\""
            ],
            13: [
                "On that day Jesus went out of the house, and sat by the seaside.",
                "Great multitudes gathered to him, so that he entered into a boat, and sat, and all the multitude stood on the beach.",
                "He spoke to them many things in parables, saying, \"Behold, a farmer went out to sow.",
                "As he sowed, some seeds fell by the roadside, and the birds came and devoured them.",
                "Others fell on rocky ground, where they didn\u2019t have much soil, and immediately they sprang up, because they had no depth of earth.",
                "When the sun had risen, they were scorched. Because they had no root, they withered away.",
                "Others fell among thorns. The thorns grew up and choked them.",
                "Others fell on good soil, and yielded fruit: some one hundred times as much, some sixty, and some thirty.",
                "He who has ears to hear, let him hear.\"",
                "The disciples came, and said to him, \"Why do you speak to them in parables?\"",
                "He answered them, \"To you it is given to know the mysteries of the Kingdom of Heaven, but it is not given to them.",
                "For whoever has, to him will be given, and he will have abundance, but whoever doesn\u2019t have, from him will be taken away even that which he has.",
                "Therefore I speak to them in parables, because seeing they don\u2019t see, and hearing, they don\u2019t hear, neither do they understand.",
                "In them the prophecy of Isaiah is fulfilled, which says, \u2018By hearing you will hear, and will in no way understand; Seeing you will see, and will in no way perceive:",
                "for this people\u2019s heart has grown callous, their ears are dull of hearing, they have closed their eyes; or else perhaps they might perceive with their eyes, hear with their ears, understand with their heart, and should turn again; and I would heal them.\u2019",
                "\"But blessed are your eyes, for they see; and your ears, for they hear.",
                "For most certainly I tell you that many prophets and righteous men desired to see the things which you see, and didn\u2019t see them; and to hear the things which you hear, and didn\u2019t hear them.",
                "\"Hear, then, the parable of the farmer.",
                "When anyone hears the word of the Kingdom, and doesn\u2019t understand it, the evil one comes, and snatches away that which has been sown in his heart. This is what was sown by the roadside.",
                "What was sown on the rocky places, this is he who hears the word, and immediately with joy receives it;",
                "yet he has no root in himself, but endures for a while. When oppression or persecution arises because of the word, immediately he stumbles.",
                "What was sown among the thorns, this is he who hears the word, but the cares of this age and the deceitfulness of riches choke the word, and he becomes unfruitful.",
                "What was sown on the good ground, this is he who hears the word, and understands it, who most certainly bears fruit, and brings forth, some one hundred times as much, some sixty, and some thirty.\"",
                "He set another parable before them, saying, \"The Kingdom of Heaven is like a man who sowed good seed in his field,",
                "but while people slept, his enemy came and sowed darnel weeds also among the wheat, and went away.",
                "But when the blade sprang up and brought forth fruit, then the darnel weeds appeared also.",
                "The servants of the householder came and said to him, \u2018Sir, didn\u2019t you sow good seed in your field? Where did this darnel come from?\u2019",
                "\"He said to them, \u2018An enemy has done this.\u2019 \"The servants asked him, \u2018Do you want us to go and gather them up?\u2019",
                "\"But he said, \u2018No, lest perhaps while you gather up the darnel weeds, you root up the wheat with them.",
                "Let both grow together until the harvest, and in the harvest time I will tell the reapers, \"First, gather up the darnel weeds, and bind them in bundles to burn them; but gather the wheat into my barn.\"\u2019\"",
                "He set another parable before them, saying, \"The Kingdom of Heaven is like a grain of mustard seed, which a man took, and sowed in his field;",
                "which indeed is smaller than all seeds. But when it is grown, it is greater than the herbs, and becomes a tree, so that the birds of the air come and lodge in its branches.\"",
                "He spoke another parable to them. \"The Kingdom of Heaven is like yeast, which a woman took, and hid in three measures of meal, until it was all leavened.\"",
                "Jesus spoke all these things in parables to the multitudes; and without a parable, he didn\u2019t speak to them,",
                "that it might be fulfilled which was spoken through the prophet, saying, \"I will open my mouth in parables; I will utter things hidden from the foundation of the world.\"",
                "Then Jesus sent the multitudes away, and went into the house. His disciples came to him, saying, \"Explain to us the parable of the darnel weeds of the field.\"",
                "He answered them, \"He who sows the good seed is the Son of Man,",
                "the field is the world; and the good seed, these are the children of the Kingdom; and the darnel weeds are the children of the evil one.",
                "The enemy who sowed them is the devil. The harvest is the end of the age, and the reapers are angels.",
                "As therefore the darnel weeds are gathered up and burned with fire; so will it be at the end of this age.",
                "The Son of Man will send out his angels, and they will gather out of his Kingdom all things that cause stumbling, and those who do iniquity,",
                "and will cast them into the furnace of fire. There will be weeping and the gnashing of teeth.",
                "Then the righteous will shine forth like the sun in the Kingdom of their Father. He who has ears to hear, let him hear.",
                "\"Again, the Kingdom of Heaven is like a treasure hidden in the field, which a man found, and hid. In his joy, he goes and sells all that he has, and buys that field.",
                "\"Again, the Kingdom of Heaven is like a man who is a merchant seeking fine pearls,",
                "who having found one pearl of great price, he went and sold all that he had, and bought it.",
                "\"Again, the Kingdom of Heaven is like a dragnet, that was cast into the sea, and gathered some fish of every kind,",
                "which, when it was filled, they drew up on the beach. They sat down, and gathered the good into containers, but the bad they threw away.",
                "So will it be in the end of the world. The angels will come forth, and separate the wicked from among the righteous,",
                "and will cast them into the furnace of fire. There will be the weeping and the gnashing of teeth.\"",
                "Jesus said to them, \"Have you understood all these things?\" They answered him, \"Yes, Lord.\"",
                "He said to them, \"Therefore, every scribe who has been made a disciple in the Kingdom of Heaven is like a man who is a householder, who brings out of his treasure new and old things.\"",
                "It happened that when Jesus had finished these parables, he departed from there.",
                "Coming into his own country, he taught them in their synagogue, so that they were astonished, and said, \"Where did this man get this wisdom, and these mighty works?",
                "Isn\u2019t this the carpenter\u2019s son? Isn\u2019t his mother called Mary, and his brothers, James, Joses, Simon, and Judas?",
                "Aren\u2019t all of his sisters with us? Where then did this man get all of these things?\"",
                "They were offended by him. But Jesus said to them, \"A prophet is not without honor, except in his own country, and in his own house.\"",
                "He didn\u2019t do many mighty works there because of their unbelief."
            ],
            14: [
                "At that time, Herod the tetrarch heard the report concerning Jesus,",
                "and said to his servants, \"This is John the Baptizer. He is risen from the dead. That is why these powers work in him.\"",
                "For Herod had laid hold of John, and bound him, and put him in prison for the sake of Herodias, his brother Philip\u2019s wife.",
                "For John said to him, \"It is not lawful for you to have her.\"",
                "When he would have put him to death, he feared the multitude, because they counted him as a prophet.",
                "But when Herod\u2019s birthday came, the daughter of Herodias danced among them and pleased Herod.",
                "Whereupon he promised with an oath to give her whatever she should ask.",
                "She, being prompted by her mother, said, \"Give me here on a platter the head of John the Baptizer.\"",
                "The king was grieved, but for the sake of his oaths, and of those who sat at the table with him, he commanded it to be given,",
                "and he sent and beheaded John in the prison.",
                "His head was brought on a platter, and given to the young lady: and she brought it to her mother.",
                "His disciples came, and took the body, and buried it; and they went and told Jesus.",
                "Now when Jesus heard this, he withdrew from there in a boat, to a deserted place apart. When the multitudes heard it, they followed him on foot from the cities.",
                "Jesus went out, and he saw a great multitude. He had compassion on them, and healed their sick.",
                "When evening had come, his disciples came to him, saying, \"This place is deserted, and the hour is already late. Send the multitudes away, that they may go into the villages, and buy themselves food.\"",
                "But Jesus said to them, \"They don\u2019t need to go away. You give them something to eat.\"",
                "They told him, \"We only have here five loaves and two fish.\"",
                "He said, \"Bring them here to me.\"",
                "He commanded the multitudes to sit down on the grass; and he took the five loaves and the two fish, and looking up to heaven, he blessed, broke and gave the loaves to the disciples, and the disciples gave to the multitudes.",
                "They all ate, and were filled. They took up twelve baskets full of that which remained left over from the broken pieces.",
                "Those who ate were about five thousand men, besides women and children.",
                "Immediately Jesus made the disciples get into the boat, and to go ahead of him to the other side, while he sent the multitudes away.",
                "After he had sent the multitudes away, he went up into the mountain by himself to pray. When evening had come, he was there alone.",
                "But the boat was now in the middle of the sea, distressed by the waves, for the wind was contrary.",
                "In the fourth watch of the night, Jesus came to them, walking on the sea.",
                "When the disciples saw him walking on the sea, they were troubled, saying, \"It\u2019s a ghost!\" and they cried out for fear.",
                "But immediately Jesus spoke to them, saying \"Cheer up! It is I! Don\u2019t be afraid.\"",
                "Peter answered him and said, \"Lord, if it is you, command me to come to you on the waters.\"",
                "He said, \"Come!\" Peter stepped down from the boat, and walked on the waters to come to Jesus.",
                "But when he saw that the wind was strong, he was afraid, and beginning to sink, he cried out, saying, \"Lord, save me!\"",
                "Immediately Jesus stretched out his hand, took hold of him, and said to him, \"You of little faith, why did you doubt?\"",
                "When they got up into the boat, the wind ceased.",
                "Those who were in the boat came and worshiped him, saying, \"You are truly the Son of God!\"",
                "When they had crossed over, they came to the land of Gennesaret.",
                "When the people of that place recognized him, they sent into all that surrounding region, and brought to him all who were sick,",
                "and they begged him that they might just touch the fringe of his garment. As many as touched it were made whole."
            ],
            15: [
                "Then Pharisees and scribes came to Jesus from Jerusalem, saying,",
                "\"Why do your disciples disobey the tradition of the elders? For they don\u2019t wash their hands when they eat bread.\"",
                "He answered them, \"Why do you also disobey the commandment of God because of your tradition?",
                "For God commanded, \u2018Honor your father and your mother,\u2019 and, \u2018He who speaks evil of father or mother, let him be put to death.\u2019",
                "But you say, \u2018Whoever may tell his father or his mother, \"Whatever help you might otherwise have gotten from me is a gift devoted to God,\"",
                "he shall not honor his father or mother.\u2019 You have made the commandment of God void because of your tradition.",
                "You hypocrites! Well did Isaiah prophesy of you, saying,",
                "\u2018These people draw near to me with their mouth, and honor me with their lips; but their heart is far from me.",
                "And in vain do they worship me, teaching as doctrine rules made by men.\u2019\"",
                "He summoned the multitude, and said to them, \"Hear, and understand.",
                "That which enters into the mouth doesn\u2019t defile the man; but that which proceeds out of the mouth, this defiles the man.\"",
                "Then the disciples came, and said to him, \"Do you know that the Pharisees were offended, when they heard this saying?\"",
                "But he answered, \"Every plant which my heavenly Father didn\u2019t plant will be uprooted.",
                "Leave them alone. They are blind guides of the blind. If the blind guide the blind, both will fall into a pit.\"",
                "Peter answered him, \"Explain the parable to us.\"",
                "So Jesus said, \"Do you also still not understand?",
                "Don\u2019t you understand that whatever goes into the mouth passes into the belly, and then out of the body?",
                "But the things which proceed out of the mouth come out of the heart, and they defile the man.",
                "For out of the heart come forth evil thoughts, murders, adulteries, sexual sins, thefts, false testimony, and blasphemies.",
                "These are the things which defile the man; but to eat with unwashed hands doesn\u2019t defile the man.\"",
                "Jesus went out from there, and withdrew into the region of Tyre and Sidon.",
                "Behold, a Canaanite woman came out from those borders, and cried, saying, \"Have mercy on me, Lord, you son of David! My daughter is severely demonized!\"",
                "But he answered her not a word. His disciples came and begged him, saying, \"Send her away; for she cries after us.\"",
                "But he answered, \"I wasn\u2019t sent to anyone but the lost sheep of the house of Israel.\"",
                "But she came and worshiped him, saying, \"Lord, help me.\"",
                "But he answered, \"It is not appropriate to take the children\u2019s bread and throw it to the dogs.\"",
                "But she said, \"Yes, Lord, but even the dogs eat the crumbs which fall from their masters\u2019 table.\"",
                "Then Jesus answered her, \"Woman, great is your faith! Be it done to you even as you desire.\" And her daughter was healed from that hour.",
                "Jesus departed there, and came near to the sea of Galilee; and he went up into the mountain, and sat there.",
                "Great multitudes came to him, having with them the lame, blind, mute, maimed, and many others, and they put them down at his feet. He healed them,",
                "so that the multitude wondered when they saw the mute speaking, injured whole, lame walking, and blind seeing\u2014and they glorified the God of Israel.",
                "Jesus summoned his disciples and said, \"I have compassion on the multitude, because they continue with me now three days and have nothing to eat. I don\u2019t want to send them away fasting, or they might faint on the way.\"",
                "The disciples said to him, \"Where should we get so many loaves in a deserted place as to satisfy so great a multitude?\"",
                "Jesus said to them, \"How many loaves do you have?\" They said, \"Seven, and a few small fish.\"",
                "He commanded the multitude to sit down on the ground;",
                "and he took the seven loaves and the fish. He gave thanks and broke them, and gave to the disciples, and the disciples to the multitudes.",
                "They all ate, and were filled. They took up seven baskets full of the broken pieces that were left over.",
                "Those who ate were four thousand men, besides women and children.",
                "Then he sent away the multitudes, got into the boat, and came into the borders of Magdala."
            ],
            16: [
                "The Pharisees and Sadducees came, and testing him, asked him to show them a sign from heaven.",
                "But he answered them, \"When it is evening, you say, \u2018It will be fair weather, for the sky is red.\u2019",
                "In the morning, \u2018It will be foul weather today, for the sky is red and threatening.\u2019 Hypocrites! You know how to discern the appearance of the sky, but you can\u2019t discern the signs of the times!",
                "An evil and adulterous generation seeks after a sign, and there will be no sign given to it, except the sign of the prophet Jonah.\" He left them, and departed.",
                "The disciples came to the other side and had forgotten to take bread.",
                "Jesus said to them, \"Take heed and beware of the yeast of the Pharisees and Sadducees.\"",
                "They reasoned among themselves, saying, \"We brought no bread.\"",
                "Jesus, perceiving it, said, \"Why do you reason among yourselves, you of little faith, \u2018because you have brought no bread?\u2019",
                "Don\u2019t you yet perceive, neither remember the five loaves for the five thousand, and how many baskets you took up?",
                "Nor the seven loaves for the four thousand, and how many baskets you took up?",
                "How is it that you don\u2019t perceive that I didn\u2019t speak to you concerning bread? But beware of the yeast of the Pharisees and Sadducees.\"",
                "Then they understood that he didn\u2019t tell them to beware of the yeast of bread, but of the teaching of the Pharisees and Sadducees.",
                "Now when Jesus came into the parts of Caesarea Philippi, he asked his disciples, saying, \"Who do men say that I, the Son of Man, am?\"",
                "They said, \"Some say John the Baptizer, some, Elijah, and others, Jeremiah, or one of the prophets.\"",
                "He said to them, \"But who do you say that I am?\"",
                "Simon Peter answered, \"You are the Christ, the Son of the living God.\"",
                "Jesus answered him, \"Blessed are you, Simon Bar Jonah, for flesh and blood has not revealed this to you, but my Father who is in heaven.",
                "I also tell you that you are Peter, and on this rock I will build my assembly, and the gates of Hades will not prevail against it.",
                "I will give to you the keys of the Kingdom of Heaven, and whatever you bind on earth will have been bound in heaven; and whatever you release on earth will have been released in heaven.\"",
                "Then he commanded the disciples that they should tell no one that he was Jesus the Christ.",
                "From that time, Jesus began to show his disciples that he must go to Jerusalem and suffer many things from the elders, chief priests, and scribes, and be killed, and the third day be raised up.",
                "Peter took him aside, and began to rebuke him, saying, \"Far be it from you, Lord! This will never be done to you.\"",
                "But he turned, and said to Peter, \"Get behind me, Satan! You are a stumbling block to me, for you are not setting your mind on the things of God, but on the things of men.\"",
                "Then Jesus said to his disciples, \"If anyone desires to come after me, let him deny himself, and take up his cross, and follow me.",
                "For whoever desires to save his life will lose it, and whoever will lose his life for my sake will find it.",
                "For what will it profit a man, if he gains the whole world, and forfeits his life? Or what will a man give in exchange for his life?",
                "For the Son of Man will come in the glory of his Father with his angels, and then he will render to everyone according to his deeds.",
                "Most certainly I tell you, there are some standing here who will in no way taste of death, until they see the Son of Man coming in his Kingdom.\""
            ],
            17: [
                "After six days, Jesus took with him Peter, James, and John his brother, and brought them up into a high mountain by themselves.",
                "He was transfigured before them. His face shone like the sun, and his garments became as white as the light.",
                "Behold, Moses and Elijah appeared to them talking with him.",
                "Peter answered, and said to Jesus, \"Lord, it is good for us to be here. If you want, let\u2019s make three tents here: one for you, one for Moses, and one for Elijah.\"",
                "While he was still speaking, behold, a bright cloud overshadowed them. Behold, a voice came out of the cloud, saying, \"This is my beloved Son, in whom I am well pleased. Listen to him.\"",
                "When the disciples heard it, they fell on their faces, and were very afraid.",
                "Jesus came and touched them and said, \"Get up, and don\u2019t be afraid.\"",
                "Lifting up their eyes, they saw no one, except Jesus alone.",
                "As they were coming down from the mountain, Jesus commanded them, saying, \"Don\u2019t tell anyone what you saw, until the Son of Man has risen from the dead.\"",
                "His disciples asked him, saying, \"Then why do the scribes say that Elijah must come first?\"",
                "Jesus answered them, \"Elijah indeed comes first, and will restore all things,",
                "but I tell you that Elijah has come already, and they didn\u2019t recognize him, but did to him whatever they wanted to. Even so the Son of Man will also suffer by them.\"",
                "Then the disciples understood that he spoke to them of John the Baptizer.",
                "When they came to the multitude, a man came to him, kneeling down to him, saying,",
                "\"Lord, have mercy on my son, for he is epileptic, and suffers grievously; for he often falls into the fire, and often into the water.",
                "So I brought him to your disciples, and they could not cure him.\"",
                "Jesus answered, \"Faithless and perverse generation! How long will I be with you? How long will I bear with you? Bring him here to me.\"",
                "Jesus rebuked him, the demon went out of him, and the boy was cured from that hour.",
                "Then the disciples came to Jesus privately, and said, \"Why weren\u2019t we able to cast it out?\"",
                "He said to them, \"Because of your unbelief. For most certainly I tell you, if you have faith as a grain of mustard seed, you will tell this mountain, \u2018Move from here to there,\u2019 and it will move; and nothing will be impossible for you.",
                "But this kind doesn\u2019t go out except by prayer and fasting.\"",
                "While they were staying in Galilee, Jesus said to them, \"The Son of Man is about to be delivered up into the hands of men,",
                "and they will kill him, and the third day he will be raised up.\" They were exceedingly sorry.",
                "When they had come to Capernaum, those who collected the didrachma coins came to Peter, and said, \"Doesn\u2019t your teacher pay the didrachma?\"",
                "He said, \"Yes.\" When he came into the house, Jesus anticipated him, saying, \"What do you think, Simon? From whom do the kings of the earth receive toll or tribute? From their children, or from strangers?\"",
                "Peter said to him, \"From strangers.\" Jesus said to him, \"Therefore the children are exempt.",
                "But, lest we cause them to stumble, go to the sea, cast a hook, and take up the first fish that comes up. When you have opened its mouth, you will find a stater coin. Take that, and give it to them for me and you.\""
            ],
            18: [
                "In that hour the disciples came to Jesus, saying, \"Who then is greatest in the Kingdom of Heaven?\"",
                "Jesus called a little child to himself, and set him in their midst,",
                "and said, \"Most certainly I tell you, unless you turn, and become as little children, you will in no way enter into the Kingdom of Heaven.",
                "Whoever therefore humbles himself as this little child, the same is the greatest in the Kingdom of Heaven.",
                "Whoever receives one such little child in my name receives me,",
                "but whoever causes one of these little ones who believe in me to stumble, it would be better for him that a huge millstone should be hung around his neck, and that he should be sunk in the depths of the sea.",
                "\"Woe to the world because of occasions of stumbling! For it must be that the occasions come, but woe to that person through whom the occasion comes!",
                "If your hand or your foot causes you to stumble, cut it off, and cast it from you. It is better for you to enter into life maimed or crippled, rather than having two hands or two feet to be cast into the eternal fire.",
                "If your eye causes you to stumble, pluck it out, and cast it from you. It is better for you to enter into life with one eye, rather than having two eyes to be cast into the Gehenna of fire.",
                "See that you don\u2019t despise one of these little ones, for I tell you that in heaven their angels always see the face of my Father who is in heaven.",
                "For the Son of Man came to save that which was lost.",
                "\"What do you think? If a man has one hundred sheep, and one of them goes astray, doesn\u2019t he leave the ninety-nine, go to the mountains, and seek that which has gone astray?",
                "If he finds it, most certainly I tell you, he rejoices over it more than over the ninety-nine which have not gone astray.",
                "Even so it is not the will of your Father who is in heaven that one of these little ones should perish.",
                "\"If your brother sins against you, go, show him his fault between you and him alone. If he listens to you, you have gained back your brother.",
                "But if he doesn\u2019t listen, take one or two more with you, that at the mouth of two or three witnesses every word may be established.",
                "If he refuses to listen to them, tell it to the assembly. If he refuses to hear the assembly also, let him be to you as a Gentile or a tax collector.",
                "Most certainly I tell you, whatever things you bind on earth will have been bound in heaven, and whatever things you release on earth will have been released in heaven.",
                "Again, assuredly I tell you, that if two of you will agree on earth concerning anything that they will ask, it will be done for them by my Father who is in heaven.",
                "For where two or three are gathered together in my name, there I am in their midst.\"",
                "Then Peter came and said to him, \"Lord, how often shall my brother sin against me, and I forgive him? Until seven times?\"",
                "Jesus said to him, \"I don\u2019t tell you until seven times, but, until seventy times seven.",
                "Therefore the Kingdom of Heaven is like a certain king, who wanted to reconcile accounts with his servants.",
                "When he had begun to reconcile, one was brought to him who owed him ten thousand talents.",
                "But because he couldn\u2019t pay, his lord commanded him to be sold, with his wife, his children, and all that he had, and payment to be made.",
                "The servant therefore fell down and kneeled before him, saying, \u2018Lord, have patience with me, and I will repay you all!\u2019",
                "The lord of that servant, being moved with compassion, released him, and forgave him the debt.",
                "\"But that servant went out, and found one of his fellow servants, who owed him one hundred denarii, and he grabbed him, and took him by the throat, saying, \u2018Pay me what you owe!\u2019",
                "\"So his fellow servant fell down at his feet and begged him, saying, \u2018Have patience with me, and I will repay you!\u2019",
                "He would not, but went and cast him into prison, until he should pay back that which was due.",
                "So when his fellow servants saw what was done, they were exceedingly sorry, and came and told to their lord all that was done.",
                "Then his lord called him in, and said to him, \u2018You wicked servant! I forgave you all that debt, because you begged me.",
                "Shouldn\u2019t you also have had mercy on your fellow servant, even as I had mercy on you?\u2019",
                "His lord was angry, and delivered him to the tormentors, until he should pay all that was due to him.",
                "So my heavenly Father will also do to you, if you don\u2019t each forgive your brother from your hearts for his misdeeds.\""
            ],
            19: [
                "It happened when Jesus had finished these words, he departed from Galilee, and came into the borders of Judea beyond the Jordan.",
                "Great multitudes followed him, and he healed them there.",
                "Pharisees came to him, testing him, and saying, \"Is it lawful for a man to divorce his wife for any reason?\"",
                "He answered, \"Haven\u2019t you read that he who made them from the beginning made them male and female,",
                "and said, \u2018For this cause a man shall leave his father and mother, and shall join to his wife; and the two shall become one flesh?\u2019",
                "So that they are no more two, but one flesh. What therefore God has joined together, don\u2019t let man tear apart.\"",
                "They asked him, \"Why then did Moses command us to give her a bill of divorce, and divorce her?\"",
                "He said to them, \"Moses, because of the hardness of your hearts, allowed you to divorce your wives, but from the beginning it has not been so.",
                "I tell you that whoever divorces his wife, except for sexual immorality, and marries another, commits adultery; and he who marries her when she is divorced commits adultery.\"",
                "His disciples said to him, \"If this is the case of the man with his wife, it is not expedient to marry.\"",
                "But he said to them, \"Not all men can receive this saying, but those to whom it is given.",
                "For there are eunuchs who were born that way from their mother\u2019s womb, and there are eunuchs who were made eunuchs by men; and there are eunuchs who made themselves eunuchs for the Kingdom of Heaven\u2019s sake. He who is able to receive it, let him receive it.\"",
                "Then little children were brought to him, that he should lay his hands on them and pray; and the disciples rebuked them.",
                "But Jesus said, \"Allow the little children, and don\u2019t forbid them to come to me; for the Kingdom of Heaven belongs to ones like these.\"",
                "He laid his hands on them, and departed from there.",
                "Behold, one came to him and said, \"Good teacher, what good thing shall I do, that I may have eternal life?\"",
                "He said to him, \"Why do you call me good? No one is good but one, that is, God. But if you want to enter into life, keep the commandments.\"",
                "He said to him, \"Which ones?\" Jesus said, \"\u2018You shall not murder.\u2019 \u2018You shall not commit adultery.\u2019 \u2018You shall not steal.\u2019 \u2018You shall not offer false testimony.\u2019",
                "\u2018Honor your father and mother.\u2019 And, \u2018You shall love your neighbor as yourself.\u2019\"",
                "The young man said to him, \"All these things I have observed from my youth. What do I still lack?\"",
                "Jesus said to him, \"If you want to be perfect, go, sell what you have, and give to the poor, and you will have treasure in heaven; and come, follow me.\"",
                "But when the young man heard the saying, he went away sad, for he was one who had great possessions.",
                "Jesus said to his disciples, \"Most certainly I say to you, a rich man will enter into the Kingdom of Heaven with difficulty.",
                "Again I tell you, it is easier for a camel to go through a needle\u2019s eye, than for a rich man to enter into the Kingdom of God.\"",
                "When the disciples heard it, they were exceedingly astonished, saying, \"Who then can be saved?\"",
                "Looking at them, Jesus said, \"With men this is impossible, but with God all things are possible.\"",
                "Then Peter answered, \"Behold, we have left everything, and followed you. What then will we have?\"",
                "Jesus said to them, \"Most certainly I tell you that you who have followed me, in the regeneration when the Son of Man will sit on the throne of his glory, you also will sit on twelve thrones, judging the twelve tribes of Israel.",
                "Everyone who has left houses, or brothers, or sisters, or father, or mother, or wife, or children, or lands, for my name\u2019s sake, will receive one hundred times, and will inherit eternal life.",
                "But many will be last who are first; and first who are last."
            ],
            20: [
                "\"For the Kingdom of Heaven is like a man who was the master of a household, who went out early in the morning to hire laborers for his vineyard.",
                "When he had agreed with the laborers for a denarius a day, he sent them into his vineyard.",
                "He went out about the third hour, and saw others standing idle in the marketplace.",
                "To them he said, \u2018You also go into the vineyard, and whatever is right I will give you.\u2019 So they went their way.",
                "Again he went out about the sixth and the ninth hour, and did likewise.",
                "About the eleventh hour he went out, and found others standing idle. He said to them, \u2018Why do you stand here all day idle?\u2019",
                "\"They said to him, \u2018Because no one has hired us.\u2019 \"He said to them, \u2018You also go into the vineyard, and you will receive whatever is right.\u2019",
                "When evening had come, the lord of the vineyard said to his manager, \u2018Call the laborers and pay them their wages, beginning from the last to the first.\u2019",
                "\"When those who were hired at about the eleventh hour came, they each received a denarius.",
                "When the first came, they supposed that they would receive more; and they likewise each received a denarius.",
                "When they received it, they murmured against the master of the household,",
                "saying, \u2018These last have spent one hour, and you have made them equal to us, who have borne the burden of the day and the scorching heat!\u2019",
                "\"But he answered one of them, \u2018Friend, I am doing you no wrong. Didn\u2019t you agree with me for a denarius?",
                "Take that which is yours, and go your way. It is my desire to give to this last just as much as to you.",
                "Isn\u2019t it lawful for me to do what I want to with what I own? Or is your eye evil, because I am good?\u2019",
                "So the last will be first, and the first last. For many are called, but few are chosen.\"",
                "As Jesus was going up to Jerusalem, he took the twelve disciples aside, and on the way he said to them,",
                "\"Behold, we are going up to Jerusalem, and the Son of Man will be delivered to the chief priests and scribes, and they will condemn him to death,",
                "and will hand him over to the Gentiles to mock, to scourge, and to crucify; and the third day he will be raised up.\"",
                "Then the mother of the sons of Zebedee came to him with her sons, kneeling and asking a certain thing of him.",
                "He said to her, \"What do you want?\" She said to him, \"Command that these, my two sons, may sit, one on your right hand, and one on your left hand, in your Kingdom.\"",
                "But Jesus answered, \"You don\u2019t know what you are asking. Are you able to drink the cup that I am about to drink, and be baptized with the baptism that I am baptized with?\" They said to him, \"We are able.\"",
                "He said to them, \"You will indeed drink my cup, and be baptized with the baptism that I am baptized with, but to sit on my right hand and on my left hand is not mine to give; but it is for whom it has been prepared by my Father.\"",
                "When the ten heard it, they were indignant with the two brothers.",
                "But Jesus summoned them, and said, \"You know that the rulers of the nations lord it over them, and their great ones exercise authority over them.",
                "It shall not be so among you, but whoever desires to become great among you shall be your servant.",
                "Whoever desires to be first among you shall be your bondservant,",
                "even as the Son of Man came not to be served, but to serve, and to give his life as a ransom for many.\"",
                "As they went out from Jericho, a great multitude followed him.",
                "Behold, two blind men sitting by the road, when they heard that Jesus was passing by, cried out, \"Lord, have mercy on us, you son of David!\"",
                "The multitude rebuked them, telling them that they should be quiet, but they cried out even more, \"Lord, have mercy on us, you son of David!\"",
                "Jesus stood still, and called them, and asked, \"What do you want me to do for you?\"",
                "They told him, \"Lord, that our eyes may be opened.\"",
                "Jesus, being moved with compassion, touched their eyes; and immediately their eyes received their sight, and they followed him."
            ],
            21: [
                "When they drew near to Jerusalem, and came to Bethsphage, to the Mount of Olives, then Jesus sent two disciples,",
                "saying to them, \"Go into the village that is opposite you, and immediately you will find a donkey tied, and a colt with her. Untie them, and bring them to me.",
                "If anyone says anything to you, you shall say, \u2018The Lord needs them,\u2019 and immediately he will send them.\"",
                "All this was done, that it might be fulfilled which was spoken through the prophet, saying,",
                "\"Tell the daughter of Zion, behold, your King comes to you, humble, and riding on a donkey, on a colt, the foal of a donkey.\"",
                "The disciples went, and did just as Jesus commanded them,",
                "and brought the donkey and the colt, and laid their clothes on them; and he sat on them.",
                "A very great multitude spread their clothes on the road. Others cut branches from the trees, and spread them on the road.",
                "The multitudes who went before him, and who followed kept shouting, \"Hosanna to the son of David! Blessed is he who comes in the name of the Lord! Hosanna in the highest!\"",
                "When he had come into Jerusalem, all the city was stirred up, saying, \"Who is this?\"",
                "The multitudes said, \"This is the prophet, Jesus, from Nazareth of Galilee.\"",
                "Jesus entered into the temple of God, and drove out all of those who sold and bought in the temple, and overthrew the money changers\u2019 tables and the seats of those who sold the doves.",
                "He said to them, \"It is written, \u2018My house shall be called a house of prayer,\u2019 but you have made it a den of robbers!\"",
                "The blind and the lame came to him in the temple, and he healed them.",
                "But when the chief priests and the scribes saw the wonderful things that he did, and the children who were crying in the temple and saying, \"Hosanna to the son of David!\" they were indignant,",
                "and said to him, \"Do you hear what these are saying?\" Jesus said to them, \"Yes. Did you never read, \u2018Out of the mouth of babes and nursing babies you have perfected praise?\u2019\"",
                "He left them, and went out of the city to Bethany, and lodged there.",
                "Now in the morning, as he returned to the city, he was hungry.",
                "Seeing a fig tree by the road, he came to it, and found nothing on it but leaves. He said to it, \"Let there be no fruit from you forever!\" Immediately the fig tree withered away.",
                "When the disciples saw it, they marveled, saying, \"How did the fig tree immediately wither away?\"",
                "Jesus answered them, \"Most certainly I tell you, if you have faith, and don\u2019t doubt, you will not only do what was done to the fig tree, but even if you told this mountain, \u2018Be taken up and cast into the sea,\u2019 it would be done.",
                "All things, whatever you ask in prayer, believing, you will receive.\"",
                "When he had come into the temple, the chief priests and the elders of the people came to him as he was teaching, and said, \"By what authority do you do these things? Who gave you this authority?\"",
                "Jesus answered them, \"I also will ask you one question, which if you tell me, I likewise will tell you by what authority I do these things.",
                "The baptism of John, where was it from? From heaven or from men?\" They reasoned with themselves, saying, \"If we say, \u2018From heaven,\u2019 he will ask us, \u2018Why then did you not believe him?\u2019",
                "But if we say, \u2018From men,\u2019 we fear the multitude, for all hold John as a prophet.\"",
                "They answered Jesus, and said, \"We don\u2019t know.\" He also said to them, \"Neither will I tell you by what authority I do these things.",
                "But what do you think? A man had two sons, and he came to the first, and said, \u2018Son, go work today in my vineyard.\u2019",
                "He answered, \u2018I will not,\u2019 but afterward he changed his mind, and went.",
                "He came to the second, and said the same thing. He answered, \u2018I go, sir,\u2019 but he didn\u2019t go.",
                "Which of the two did the will of his father?\" They said to him, \"The first.\" Jesus said to them, \"Most certainly I tell you that the tax collectors and the prostitutes are entering into the Kingdom of God before you.",
                "For John came to you in the way of righteousness, and you didn\u2019t believe him, but the tax collectors and the prostitutes believed him. When you saw it, you didn\u2019t even repent afterward, that you might believe him.",
                "\"Hear another parable. There was a man who was a master of a household, who planted a vineyard, set a hedge about it, dug a winepress in it, built a tower, leased it out to farmers, and went into another country.",
                "When the season for the fruit drew near, he sent his servants to the farmers, to receive his fruit.",
                "The farmers took his servants, beat one, killed another, and stoned another.",
                "Again, he sent other servants more than the first: and they treated them the same way.",
                "But afterward he sent to them his son, saying, \u2018They will respect my son.\u2019",
                "But the farmers, when they saw the son, said among themselves, \u2018This is the heir. Come, let\u2019s kill him, and seize his inheritance.\u2019",
                "So they took him, and threw him out of the vineyard, and killed him.",
                "When therefore the lord of the vineyard comes, what will he do to those farmers?\"",
                "They told him, \"He will miserably destroy those miserable men, and will lease out the vineyard to other farmers, who will give him the fruit in its season.\"",
                "Jesus said to them, \"Did you never read in the Scriptures, \u2018The stone which the builders rejected, the same was made the head of the corner. This was from the Lord. It is marvelous in our eyes?\u2019",
                "\"Therefore I tell you, the Kingdom of God will be taken away from you, and will be given to a nation bringing forth its fruit.",
                "He who falls on this stone will be broken to pieces, but on whomever it will fall, it will scatter him as dust.\"",
                "When the chief priests and the Pharisees heard his parables, they perceived that he spoke about them.",
                "When they sought to seize him, they feared the multitudes, because they considered him to be a prophet."
            ],
            22: [
                "Jesus answered and spoke again in parables to them, saying,",
                "\"The Kingdom of Heaven is like a certain king, who made a marriage feast for his son,",
                "and sent out his servants to call those who were invited to the marriage feast, but they would not come.",
                "Again he sent out other servants, saying, \u2018Tell those who are invited, \"Behold, I have prepared my dinner. My cattle and my fatlings are killed, and all things are ready. Come to the marriage feast!\"\u2019",
                "But they made light of it, and went their ways, one to his own farm, another to his merchandise,",
                "and the rest grabbed his servants, and treated them shamefully, and killed them.",
                "When the king heard that, he was angry, and sent his armies, destroyed those murderers, and burned their city.",
                "\"Then he said to his servants, \u2018The wedding is ready, but those who were invited weren\u2019t worthy.",
                "Go therefore to the intersections of the highways, and as many as you may find, invite to the marriage feast.\u2019",
                "Those servants went out into the highways, and gathered together as many as they found, both bad and good. The wedding was filled with guests.",
                "But when the king came in to see the guests, he saw there a man who didn\u2019t have on wedding clothing,",
                "and he said to him, \u2018Friend, how did you come in here not wearing wedding clothing?\u2019 He was speechless.",
                "Then the king said to the servants, \u2018Bind him hand and foot, take him away, and throw him into the outer darkness; there is where the weeping and grinding of teeth will be.\u2019",
                "For many are called, but few chosen.\"",
                "Then the Pharisees went and took counsel how they might entrap him in his talk.",
                "They sent their disciples to him, along with the Herodians, saying, \"Teacher, we know that you are honest, and teach the way of God in truth, no matter whom you teach, for you aren\u2019t partial to anyone.",
                "Tell us therefore, what do you think? Is it lawful to pay taxes to Caesar, or not?\"",
                "But Jesus perceived their wickedness, and said, \"Why do you test me, you hypocrites?",
                "Show me the tax money.\" They brought to him a denarius.",
                "He asked them, \"Whose is this image and inscription?\"",
                "They said to him, \"Caesar\u2019s.\" Then he said to them, \"Give therefore to Caesar the things that are Caesar\u2019s, and to God the things that are God\u2019s.\"",
                "When they heard it, they marveled, and left him, and went away.",
                "On that day Sadducees (those who say that there is no resurrection) came to him. They asked him,",
                "saying, \"Teacher, Moses said, \u2018If a man dies, having no children, his brother shall marry his wife, and raise up seed for his brother.\u2019",
                "Now there were with us seven brothers. The first married and died, and having no seed left his wife to his brother.",
                "In the same way, the second also, and the third, to the seventh.",
                "After them all, the woman died.",
                "In the resurrection therefore, whose wife will she be of the seven? For they all had her.\"",
                "But Jesus answered them, \"You are mistaken, not knowing the Scriptures, nor the power of God.",
                "For in the resurrection they neither marry, nor are given in marriage, but are like God\u2019s angels in heaven.",
                "But concerning the resurrection of the dead, haven\u2019t you read that which was spoken to you by God, saying,",
                "\u2018I am the God of Abraham, and the God of Isaac, and the God of Jacob?\u2019 God is not the God of the dead, but of the living.\"",
                "When the multitudes heard it, they were astonished at his teaching.",
                "But the Pharisees, when they heard that he had silenced the Sadducees, gathered themselves together.",
                "One of them, a lawyer, asked him a question, testing him.",
                "\"Teacher, which is the greatest commandment in the law?\"",
                "Jesus said to him, \"\u2018You shall love the Lord your God with all your heart, with all your soul, and with all your mind.\u2019",
                "This is the first and great commandment.",
                "A second likewise is this, \u2018You shall love your neighbor as yourself.\u2019",
                "The whole law and the prophets depend on these two commandments.\"",
                "Now while the Pharisees were gathered together, Jesus asked them a question,",
                "saying, \"What do you think of the Christ? Whose son is he?\" They said to him, \"Of David.\"",
                "He said to them, \"How then does David in the Spirit call him Lord, saying,",
                "\u2018The Lord said to my Lord, sit on my right hand, until I make your enemies a footstool for your feet?\u2019",
                "\"If then David calls him Lord, how is he his son?\"",
                "No one was able to answer him a word, neither did any man dare ask him any more questions from that day forth."
            ],
            23: [
                "Then Jesus spoke to the multitudes and to his disciples,",
                "saying, \"The scribes and the Pharisees sat on Moses\u2019 seat.",
                "All things therefore whatever they tell you to observe, observe and do, but don\u2019t do their works; for they say, and don\u2019t do.",
                "For they bind heavy burdens that are grievous to be borne, and lay them on men\u2019s shoulders; but they themselves will not lift a finger to help them.",
                "But all their works they do to be seen by men. They make their phylacteries broad, enlarge the fringes of their garments,",
                "and love the place of honor at feasts, the best seats in the synagogues,",
                "the salutations in the marketplaces, and to be called \u2018Rabbi, Rabbi\u2019 by men.",
                "But don\u2019t you be called \u2018Rabbi,\u2019 for one is your teacher, the Christ, and all of you are brothers.",
                "Call no man on the earth your father, for one is your Father, he who is in heaven.",
                "Neither be called masters, for one is your master, the Christ.",
                "But he who is greatest among you will be your servant.",
                "Whoever exalts himself will be humbled, and whoever humbles himself will be exalted.",
                "\"Woe to you, scribes and Pharisees, hypocrites! For you devour widows\u2019 houses, and as a pretense you make long prayers. Therefore you will receive greater condemnation.",
                "\"But woe to you, scribes and Pharisees, hypocrites! Because you shut up the Kingdom of Heaven against men; for you don\u2019t enter in yourselves, neither do you allow those who are entering in to enter.",
                "Woe to you, scribes and Pharisees, hypocrites! For you travel around by sea and land to make one proselyte; and when he becomes one, you make him twice as much of a son of Gehenna as yourselves.",
                "\"Woe to you, you blind guides, who say, \u2018Whoever swears by the temple, it is nothing; but whoever swears by the gold of the temple, he is obligated.\u2019",
                "You blind fools! For which is greater, the gold, or the temple that sanctifies the gold?",
                "\u2018Whoever swears by the altar, it is nothing; but whoever swears by the gift that is on it, he is obligated?\u2019",
                "You blind fools! For which is greater, the gift, or the altar that sanctifies the gift?",
                "He therefore who swears by the altar, swears by it, and by everything on it.",
                "He who swears by the temple, swears by it, and by him who was living in it.",
                "He who swears by heaven, swears by the throne of God, and by him who sits on it.",
                "\"Woe to you, scribes and Pharisees, hypocrites! For you tithe mint, dill, and cumin, and have left undone the weightier matters of the law: justice, mercy, and faith. But you ought to have done these, and not to have left the other undone.",
                "You blind guides, who strain out a gnat, and swallow a camel!",
                "\"Woe to you, scribes and Pharisees, hypocrites! For you clean the outside of the cup and of the platter, but within they are full of extortion and unrighteousness.",
                "You blind Pharisee, first clean the inside of the cup and of the platter, that its outside may become clean also.",
                "\"Woe to you, scribes and Pharisees, hypocrites! For you are like whitened tombs, which outwardly appear beautiful, but inwardly are full of dead men\u2019s bones, and of all uncleanness.",
                "Even so you also outwardly appear righteous to men, but inwardly you are full of hypocrisy and iniquity.",
                "\"Woe to you, scribes and Pharisees, hypocrites! For you build the tombs of the prophets, and decorate the tombs of the righteous,",
                "and say, \u2018If we had lived in the days of our fathers, we wouldn\u2019t have been partakers with them in the blood of the prophets.\u2019",
                "Therefore you testify to yourselves that you are children of those who killed the prophets.",
                "Fill up, then, the measure of your fathers.",
                "You serpents, you offspring of vipers, how will you escape the judgment of Gehenna?",
                "Therefore, behold, I send to you prophets, wise men, and scribes. Some of them you will kill and crucify; and some of them you will scourge in your synagogues, and persecute from city to city;",
                "that on you may come all the righteous blood shed on the earth, from the blood of righteous Abel to the blood of Zachariah son of Barachiah, whom you killed between the sanctuary and the altar.",
                "Most certainly I tell you, all these things will come upon this generation.",
                "\"Jerusalem, Jerusalem, who kills the prophets, and stones those who are sent to her! How often I would have gathered your children together, even as a hen gathers her chicks under her wings, and you would not!",
                "Behold, your house is left to you desolate.",
                "For I tell you, you will not see me from now on, until you say, \u2018Blessed is he who comes in the name of the Lord!\u2019\""
            ],
            24: [
                "Jesus went out from the temple, and was going on his way. His disciples came to him to show him the buildings of the temple.",
                "But he answered them, \"You see all of these things, don\u2019t you? Most certainly I tell you, there will not be left here one stone on another, that will not be thrown down.\"",
                "As he sat on the Mount of Olives, the disciples came to him privately, saying, \"Tell us, when will these things be? What is the sign of your coming, and of the end of the age?\"",
                "Jesus answered them, \"Be careful that no one leads you astray.",
                "For many will come in my name, saying, \u2018I am the Christ,\u2019 and will lead many astray.",
                "You will hear of wars and rumors of wars. See that you aren\u2019t troubled, for all this must happen, but the end is not yet.",
                "For nation will rise against nation, and kingdom against kingdom; and there will be famines, plagues, and earthquakes in various places.",
                "But all these things are the beginning of birth pains.",
                "Then they will deliver you up to oppression, and will kill you. You will be hated by all of the nations for my name\u2019s sake.",
                "Then many will stumble, and will deliver up one another, and will hate one another.",
                "Many false prophets will arise, and will lead many astray.",
                "Because iniquity will be multiplied, the love of many will grow cold.",
                "But he who endures to the end, the same will be saved.",
                "This Good News of the Kingdom will be preached in the whole world for a testimony to all the nations, and then the end will come.",
                "\"When, therefore, you see the abomination of desolation, which was spoken of through Daniel the prophet, standing in the holy place (let the reader understand),",
                "then let those who are in Judea flee to the mountains.",
                "Let him who is on the housetop not go down to take out things that are in his house.",
                "Let him who is in the field not return back to get his clothes.",
                "But woe to those who are with child and to nursing mothers in those days!",
                "Pray that your flight will not be in the winter, nor on a Sabbath,",
                "for then there will be great oppression, such as has not been from the beginning of the world until now, no, nor ever will be.",
                "Unless those days had been shortened, no flesh would have been saved. But for the sake of the chosen ones, those days will be shortened.",
                "\"Then if any man tells you, \u2018Behold, here is the Christ,\u2019 or, \u2018There,\u2019 don\u2019t believe it.",
                "For there will arise false christs, and false prophets, and they will show great signs and wonders, so as to lead astray, if possible, even the chosen ones.",
                "\"Behold, I have told you beforehand.",
                "If therefore they tell you, \u2018Behold, he is in the wilderness,\u2019 don\u2019t go out; \u2018Behold, he is in the inner rooms,\u2019 don\u2019t believe it.",
                "For as the lightning flashes from the east, and is seen even to the west, so will be the coming of the Son of Man.",
                "For wherever the carcass is, there is where the vultures gather together.",
                "But immediately after the oppression of those days, the sun will be darkened, the moon will not give its light, the stars will fall from the sky, and the powers of the heavens will be shaken;",
                "and then the sign of the Son of Man will appear in the sky. Then all the tribes of the earth will mourn, and they will see the Son of Man coming on the clouds of the sky with power and great glory.",
                "He will send out his angels with a great sound of a trumpet, and they will gather together his chosen ones from the four winds, from one end of the sky to the other.",
                "\"Now from the fig tree learn this parable. When its branch has now become tender, and puts forth its leaves, you know that the summer is near.",
                "Even so you also, when you see all these things, know that it is near, even at the doors.",
                "Most certainly I tell you, this generation will not pass away, until all these things are accomplished.",
                "Heaven and earth will pass away, but my words will not pass away.",
                "But no one knows of that day and hour, not even the angels of heaven, but my Father only.",
                "\"As the days of Noah were, so will be the coming of the Son of Man.",
                "For as in those days which were before the flood they were eating and drinking, marrying and giving in marriage, until the day that Noah entered into the ship,",
                "and they didn\u2019t know until the flood came, and took them all away, so will be the coming of the Son of Man.",
                "Then two men will be in the field: one will be taken and one will be left;",
                "two women grinding at the mill, one will be taken and one will be left.",
                "Watch therefore, for you don\u2019t know in what hour your Lord comes.",
                "But know this, that if the master of the house had known in what watch of the night the thief was coming, he would have watched, and would not have allowed his house to be broken into.",
                "Therefore also be ready, for in an hour that you don\u2019t expect, the Son of Man will come.",
                "\"Who then is the faithful and wise servant, whom his lord has set over his household, to give them their food in due season?",
                "Blessed is that servant whom his lord finds doing so when he comes.",
                "Most certainly I tell you that he will set him over all that he has.",
                "But if that evil servant should say in his heart, \u2018My lord is delaying his coming,\u2019",
                "and begins to beat his fellow servants, and eat and drink with the drunkards,",
                "the lord of that servant will come in a day when he doesn\u2019t expect it, and in an hour when he doesn\u2019t know it,",
                "and will cut him in pieces, and appoint his portion with the hypocrites. There is where the weeping and grinding of teeth will be."
            ],
            25: [
                "\"Then the Kingdom of Heaven will be like ten virgins, who took their lamps, and went out to meet the bridegroom.",
                "Five of them were foolish, and five were wise.",
                "Those who were foolish, when they took their lamps, took no oil with them,",
                "but the wise took oil in their vessels with their lamps.",
                "Now while the bridegroom delayed, they all slumbered and slept.",
                "But at midnight there was a cry, \u2018Behold! The bridegroom is coming! Come out to meet him!\u2019",
                "Then all those virgins arose, and trimmed their lamps.",
                "The foolish said to the wise, \u2018Give us some of your oil, for our lamps are going out.\u2019",
                "But the wise answered, saying, \u2018What if there isn\u2019t enough for us and you? You go rather to those who sell, and buy for yourselves.\u2019",
                "While they went away to buy, the bridegroom came, and those who were ready went in with him to the marriage feast, and the door was shut.",
                "Afterward the other virgins also came, saying, \u2018Lord, Lord, open to us.\u2019",
                "But he answered, \u2018Most certainly I tell you, I don\u2019t know you.\u2019",
                "Watch therefore, for you don\u2019t know the day nor the hour in which the Son of Man is coming.",
                "\"For it is like a man, going into another country, who called his own servants, and entrusted his goods to them.",
                "To one he gave five talents, to another two, to another one; to each according to his own ability. Then he went on his journey.",
                "Immediately he who received the five talents went and traded with them, and made another five talents.",
                "In the same way, he also who got the two gained another two.",
                "But he who received the one went away and dug in the earth, and hid his lord\u2019s money.",
                "\"Now after a long time the lord of those servants came, and reconciled accounts with them.",
                "He who received the five talents came and brought another five talents, saying, \u2018Lord, you delivered to me five talents. Behold, I have gained another five talents besides them.\u2019",
                "\"His lord said to him, \u2018Well done, good and faithful servant. You have been faithful over a few things, I will set you over many things. Enter into the joy of your lord.\u2019",
                "\"He also who got the two talents came and said, \u2018Lord, you delivered to me two talents. Behold, I have gained another two talents besides them.\u2019",
                "\"His lord said to him, \u2018Well done, good and faithful servant. You have been faithful over a few things, I will set you over many things. Enter into the joy of your lord.\u2019",
                "\"He also who had received the one talent came and said, \u2018Lord, I knew you that you are a hard man, reaping where you did not sow, and gathering where you did not scatter.",
                "I was afraid, and went away and hid your talent in the earth. Behold, you have what is yours.\u2019",
                "\"But his lord answered him, \u2018You wicked and slothful servant. You knew that I reap where I didn\u2019t sow, and gather where I didn\u2019t scatter.",
                "You ought therefore to have deposited my money with the bankers, and at my coming I should have received back my own with interest.",
                "Take away therefore the talent from him, and give it to him who has the ten talents.",
                "For to everyone who has will be given, and he will have abundance, but from him who doesn\u2019t have, even that which he has will be taken away.",
                "Throw out the unprofitable servant into the outer darkness, where there will be weeping and gnashing of teeth.\u2019",
                "\"But when the Son of Man comes in his glory, and all the holy angels with him, then he will sit on the throne of his glory.",
                "Before him all the nations will be gathered, and he will separate them one from another, as a shepherd separates the sheep from the goats.",
                "He will set the sheep on his right hand, but the goats on the left.",
                "Then the King will tell those on his right hand, \u2018Come, blessed of my Father, inherit the Kingdom prepared for you from the foundation of the world;",
                "for I was hungry, and you gave me food to eat. I was thirsty, and you gave me drink. I was a stranger, and you took me in.",
                "I was naked, and you clothed me. I was sick, and you visited me. I was in prison, and you came to me.\u2019",
                "\"Then the righteous will answer him, saying, \u2018Lord, when did we see you hungry, and feed you; or thirsty, and give you a drink?",
                "When did we see you as a stranger, and take you in; or naked, and clothe you?",
                "When did we see you sick, or in prison, and come to you?\u2019",
                "\"The King will answer them, \u2018Most certainly I tell you, inasmuch as you did it to one of the least of these my brothers, you did it to me.\u2019",
                "Then he will say also to those on the left hand, \u2018Depart from me, you cursed, into the eternal fire which is prepared for the devil and his angels;",
                "for I was hungry, and you didn\u2019t give me food to eat; I was thirsty, and you gave me no drink;",
                "I was a stranger, and you didn\u2019t take me in; naked, and you didn\u2019t clothe me; sick, and in prison, and you didn\u2019t visit me.\u2019",
                "\"Then they will also answer, saying, \u2018Lord, when did we see you hungry, or thirsty, or a stranger, or naked, or sick, or in prison, and didn\u2019t help you?\u2019",
                "\"Then he will answer them, saying, \u2018Most certainly I tell you, inasmuch as you didn\u2019t do it to one of the least of these, you didn\u2019t do it to me.\u2019",
                "These will go away into eternal punishment, but the righteous into eternal life.\""
            ],
            26: [
                "It happened, when Jesus had finished all these words, that he said to his disciples,",
                "\"You know that after two days the Passover is coming, and the Son of Man will be delivered up to be crucified.\"",
                "Then the chief priests, the scribes, and the elders of the people were gathered together in the court of the high priest, who was called Caiaphas.",
                "They took counsel together that they might take Jesus by deceit, and kill him.",
                "But they said, \"Not during the feast, lest a riot occur among the people.\"",
                "Now when Jesus was in Bethany, in the house of Simon the leper,",
                "a woman came to him having an alabaster jar of very expensive ointment, and she poured it on his head as he sat at the table.",
                "But when his disciples saw this, they were indignant, saying, \"Why this waste?",
                "For this ointment might have been sold for much, and given to the poor.\"",
                "However, knowing this, Jesus said to them, \"Why do you trouble the woman? Because she has done a good work for me.",
                "For you always have the poor with you; but you don\u2019t always have me.",
                "For in pouring this ointment on my body, she did it to prepare me for burial.",
                "Most certainly I tell you, wherever this Good News is preached in the whole world, what this woman has done will also be spoken of as a memorial of her.\"",
                "Then one of the twelve, who was called Judas Iscariot, went to the chief priests,",
                "and said, \"What are you willing to give me, that I should deliver him to you?\" They weighed out for him thirty pieces of silver.",
                "From that time he sought opportunity to betray him.",
                "Now on the first day of unleavened bread, the disciples came to Jesus, saying to him, \"Where do you want us to prepare for you to eat the Passover?\"",
                "He said, \"Go into the city to a certain person, and tell him, \u2018The Teacher says, \"My time is at hand. I will keep the Passover at your house with my disciples.\"\u2019\"",
                "The disciples did as Jesus commanded them, and they prepared the Passover.",
                "Now when evening had come, he was reclining at the table with the twelve disciples.",
                "As they were eating, he said, \"Most certainly I tell you that one of you will betray me.\"",
                "They were exceedingly sorrowful, and each began to ask him, \"It isn\u2019t me, is it, Lord?\"",
                "He answered, \"He who dipped his hand with me in the dish, the same will betray me.",
                "The Son of Man goes, even as it is written of him, but woe to that man through whom the Son of Man is betrayed! It would be better for that man if he had not been born.\"",
                "Judas, who betrayed him, answered, \"It isn\u2019t me, is it, Rabbi?\" He said to him, \"You said it.\"",
                "As they were eating, Jesus took bread, gave thanks for it, and broke it. He gave to the disciples, and said, \"Take, eat; this is my body.\"",
                "He took the cup, gave thanks, and gave to them, saying, \"All of you drink it,",
                "for this is my blood of the new covenant, which is poured out for many for the remission of sins.",
                "But I tell you that I will not drink of this fruit of the vine from now on, until that day when I drink it anew with you in my Father\u2019s Kingdom.\"",
                "When they had sung a hymn, they went out to the Mount of Olives.",
                "Then Jesus said to them, \"All of you will be made to stumble because of me tonight, for it is written, \u2018I will strike the shepherd, and the sheep of the flock will be scattered.\u2019",
                "But after I am raised up, I will go before you into Galilee.\"",
                "But Peter answered him, \"Even if all will be made to stumble because of you, I will never be made to stumble.\"",
                "Jesus said to him, \"Most certainly I tell you that tonight, before the rooster crows, you will deny me three times.\"",
                "Peter said to him, \"Even if I must die with you, I will not deny you.\" All of the disciples also said likewise.",
                "Then Jesus came with them to a place called Gethsemane, and said to his disciples, \"Sit here, while I go there and pray.\"",
                "He took with him Peter and the two sons of Zebedee, and began to be sorrowful and severely troubled.",
                "Then he said to them, \"My soul is exceedingly sorrowful, even to death. Stay here, and watch with me.\"",
                "He went forward a little, fell on his face, and prayed, saying, \"My Father, if it is possible, let this cup pass away from me; nevertheless, not what I desire, but what you desire.\"",
                "He came to the disciples, and found them sleeping, and said to Peter, \"What, couldn\u2019t you watch with me for one hour?",
                "Watch and pray, that you don\u2019t enter into temptation. The spirit indeed is willing, but the flesh is weak.\"",
                "Again, a second time he went away, and prayed, saying, \"My Father, if this cup can\u2019t pass away from me unless I drink it, your desire be done.\"",
                "He came again and found them sleeping, for their eyes were heavy.",
                "He left them again, went away, and prayed a third time, saying the same words.",
                "Then he came to his disciples, and said to them, \"Sleep on now, and take your rest. Behold, the hour is at hand, and the Son of Man is betrayed into the hands of sinners.",
                "Arise, let\u2019s be going. Behold, he who betrays me is at hand.\"",
                "While he was still speaking, behold, Judas, one of the twelve, came, and with him a great multitude with swords and clubs, from the chief priest and elders of the people.",
                "Now he who betrayed him gave them a sign, saying, \"Whoever I kiss, he is the one. Seize him.\"",
                "Immediately he came to Jesus, and said, \"Hail, Rabbi!\" and kissed him.",
                "Jesus said to him, \"Friend, why are you here?\" Then they came and laid hands on Jesus, and took him.",
                "Behold, one of those who were with Jesus stretched out his hand, and drew his sword, and struck the servant of the high priest, and struck off his ear.",
                "Then Jesus said to him, \"Put your sword back into its place, for all those who take the sword will die by the sword.",
                "Or do you think that I couldn\u2019t ask my Father, and he would even now send me more than twelve legions of angels?",
                "How then would the Scriptures be fulfilled that it must be so?\"",
                "In that hour Jesus said to the multitudes, \"Have you come out as against a robber with swords and clubs to seize me? I sat daily in the temple teaching, and you didn\u2019t arrest me.",
                "But all this has happened, that the Scriptures of the prophets might be fulfilled.\" Then all the disciples left him, and fled.",
                "Those who had taken Jesus led him away to Caiaphas the high priest, where the scribes and the elders were gathered together.",
                "But Peter followed him from a distance, to the court of the high priest, and entered in and sat with the officers, to see the end.",
                "Now the chief priests, the elders, and the whole council sought false testimony against Jesus, that they might put him to death;",
                "and they found none. Even though many false witnesses came forward, they found none. But at last two false witnesses came forward,",
                "and said, \"This man said, \u2018I am able to destroy the temple of God, and to build it in three days.\u2019\"",
                "The high priest stood up, and said to him, \"Have you no answer? What is this that these testify against you?\"",
                "But Jesus held his peace. The high priest answered him, \"I adjure you by the living God, that you tell us whether you are the Christ, the Son of God.\"",
                "Jesus said to him, \"You have said it. Nevertheless, I tell you, after this you will see the Son of Man sitting at the right hand of Power, and coming on the clouds of the sky.\"",
                "Then the high priest tore his clothing, saying, \"He has spoken blasphemy! Why do we need any more witnesses? Behold, now you have heard his blasphemy.",
                "What do you think?\" They answered, \"He is worthy of death!\"",
                "Then they spit in his face and beat him with their fists, and some slapped him,",
                "saying, \"Prophesy to us, you Christ! Who hit you?\"",
                "Now Peter was sitting outside in the court, and a maid came to him, saying, \"You were also with Jesus, the Galilean!\"",
                "But he denied it before them all, saying, \"I don\u2019t know what you are talking about.\"",
                "When he had gone out onto the porch, someone else saw him, and said to those who were there, \"This man also was with Jesus of Nazareth.\"",
                "Again he denied it with an oath, \"I don\u2019t know the man.\"",
                "After a little while those who stood by came and said to Peter, \"Surely you are also one of them, for your speech makes you known.\"",
                "Then he began to curse and to swear, \"I don\u2019t know the man!\" Immediately the rooster crowed.",
                "Peter remembered the word which Jesus had said to him, \"Before the rooster crows, you will deny me three times.\" He went out and wept bitterly."
            ],
            27: [
                "Now when morning had come, all the chief priests and the elders of the people took counsel against Jesus to put him to death:",
                "and they bound him, and led him away, and delivered him up to Pontius Pilate, the governor.",
                "Then Judas, who betrayed him, when he saw that Jesus was condemned, felt remorse, and brought back the thirty pieces of silver to the chief priests and elders,",
                "saying, \"I have sinned in that I betrayed innocent blood.\" But they said, \"What is that to us? You see to it.\"",
                "He threw down the pieces of silver in the sanctuary, and departed. He went away and hanged himself.",
                "The chief priests took the pieces of silver, and said, \"It\u2019s not lawful to put them into the treasury, since it is the price of blood.\"",
                "They took counsel, and bought the potter\u2019s field with them, to bury strangers in.",
                "Therefore that field was called \"The Field of Blood\" to this day.",
                "Then that which was spoken through Jeremiah the prophet was fulfilled, saying, \"They took the thirty pieces of silver, the price of him upon whom a price had been set, whom some of the children of Israel priced,",
                "and they gave them for the potter\u2019s field, as the Lord commanded me.\"",
                "Now Jesus stood before the governor: and the governor asked him, saying, \"Are you the King of the Jews?\" Jesus said to him, \"So you say.\"",
                "When he was accused by the chief priests and elders, he answered nothing.",
                "Then Pilate said to him, \"Don\u2019t you hear how many things they testify against you?\"",
                "He gave him no answer, not even one word, so that the governor marveled greatly.",
                "Now at the feast the governor was accustomed to release to the multitude one prisoner, whom they desired.",
                "They had then a notable prisoner, called Barabbas.",
                "When therefore they were gathered together, Pilate said to them, \"Whom do you want me to release to you? Barabbas, or Jesus, who is called Christ?\"",
                "For he knew that because of envy they had delivered him up.",
                "While he was sitting on the judgment seat, his wife sent to him, saying, \"Have nothing to do with that righteous man, for I have suffered many things this day in a dream because of him.\"",
                "Now the chief priests and the elders persuaded the multitudes to ask for Barabbas, and destroy Jesus.",
                "But the governor answered them, \"Which of the two do you want me to release to you?\" They said, \"Barabbas!\"",
                "Pilate said to them, \"What then shall I do to Jesus, who is called Christ?\" They all said to him, \"Let him be crucified!\"",
                "But the governor said, \"Why? What evil has he done?\" But they cried out exceedingly, saying, \"Let him be crucified!\"",
                "So when Pilate saw that nothing was being gained, but rather that a disturbance was starting, he took water, and washed his hands before the multitude, saying, \"I am innocent of the blood of this righteous person. You see to it.\"",
                "All the people answered, \"May his blood be on us, and on our children!\"",
                "Then he released to them Barabbas, but Jesus he flogged and delivered to be crucified.",
                "Then the governor\u2019s soldiers took Jesus into the Praetorium, and gathered the whole garrison together against him.",
                "They stripped him, and put a scarlet robe on him.",
                "They braided a crown of thorns and put it on his head, and a reed in his right hand; and they kneeled down before him, and mocked him, saying, \"Hail, King of the Jews!\"",
                "They spat on him, and took the reed and struck him on the head.",
                "When they had mocked him, they took the robe off of him, and put his clothes on him, and led him away to crucify him.",
                "As they came out, they found a man of Cyrene, Simon by name, and they compelled him to go with them, that he might carry his cross.",
                "They came to a place called \"Golgotha,\" that is to say, \"The place of a skull.\"",
                "They gave him sour wine to drink mixed with gall. When he had tasted it, he would not drink.",
                "When they had crucified him, they divided his clothing among them, casting lots,",
                "and they sat and watched him there.",
                "They set up over his head the accusation against him written, \"THIS IS JESUS, THE KING OF THE JEWS.\"",
                "Then there were two robbers crucified with him, one on his right hand and one on the left.",
                "Those who passed by blasphemed him, wagging their heads,",
                "and saying, \"You who destroy the temple, and build it in three days, save yourself! If you are the Son of God, come down from the cross!\"",
                "Likewise the chief priests also mocking, with the scribes, the Pharisees, and the elders, said,",
                "\"He saved others, but he can\u2019t save himself. If he is the King of Israel, let him come down from the cross now, and we will believe in him.",
                "He trusts in God. Let God deliver him now, if he wants him; for he said, \u2018I am the Son of God.\u2019\"",
                "The robbers also who were crucified with him cast on him the same reproach.",
                "Now from the sixth hour there was darkness over all the land until the ninth hour.",
                "About the ninth hour Jesus cried with a loud voice, saying, \"Eli, Eli, lima sabachthani?\" That is, \"My God, my God, why have you forsaken me?\"",
                "Some of them who stood there, when they heard it, said, \"This man is calling Elijah.\"",
                "Immediately one of them ran, and took a sponge, and filled it with vinegar, and put it on a reed, and gave him a drink.",
                "The rest said, \"Let him be. Let\u2019s see whether Elijah comes to save him.\"",
                "Jesus cried again with a loud voice, and yielded up his spirit.",
                "Behold, the veil of the temple was torn in two from the top to the bottom. The earth quaked and the rocks were split.",
                "The tombs were opened, and many bodies of the saints who had fallen asleep were raised;",
                "and coming out of the tombs after his resurrection, they entered into the holy city and appeared to many.",
                "Now the centurion, and those who were with him watching Jesus, when they saw the earthquake, and the things that were done, feared exceedingly, saying, \"Truly this was the Son of God.\"",
                "Many women were there watching from afar, who had followed Jesus from Galilee, serving him.",
                "Among them were Mary Magdalene, Mary the mother of James and Joses, and the mother of the sons of Zebedee.",
                "When evening had come, a rich man from Arimathaea, named Joseph, who himself was also Jesus\u2019 disciple came.",
                "This man went to Pilate, and asked for Jesus\u2019 body. Then Pilate commanded the body to be given up.",
                "Joseph took the body, and wrapped it in a clean linen cloth,",
                "and laid it in his own new tomb, which he had cut out in the rock, and he rolled a great stone to the door of the tomb, and departed.",
                "Mary Magdalene was there, and the other Mary, sitting opposite the tomb.",
                "Now on the next day, which was the day after the Preparation Day, the chief priests and the Pharisees were gathered together to Pilate,",
                "saying, \"Sir, we remember what that deceiver said while he was still alive: \u2018After three days I will rise again.\u2019",
                "Command therefore that the tomb be made secure until the third day, lest perhaps his disciples come at night and steal him away, and tell the people, \u2018He is risen from the dead;\u2019 and the last deception will be worse than the first.\"",
                "Pilate said to them, \"You have a guard. Go, make it as secure as you can.\"",
                "So they went with the guard and made the tomb secure, sealing the stone."
            ],
            28: [
                "Now after the Sabbath, as it began to dawn on the first day of the week, Mary Magdalene and the other Mary came to see the tomb.",
                "Behold, there was a great earthquake, for an angel of the Lord descended from the sky, and came and rolled away the stone from the door, and sat on it.",
                "His appearance was like lightning, and his clothing white as snow.",
                "For fear of him, the guards shook, and became like dead men.",
                "The angel answered the women, \"Don\u2019t be afraid, for I know that you seek Jesus, who has been crucified.",
                "He is not here, for he has risen, just like he said. Come, see the place where the Lord was lying.",
                "Go quickly and tell his disciples, \u2018He has risen from the dead, and behold, he goes before you into Galilee; there you will see him.\u2019 Behold, I have told you.\"",
                "They departed quickly from the tomb with fear and great joy, and ran to bring his disciples word.",
                "As they went to tell his disciples, behold, Jesus met them, saying, \"Rejoice!\" They came and took hold of his feet, and worshiped him.",
                "Then Jesus said to them, \"Don\u2019t be afraid. Go tell my brothers that they should go into Galilee, and there they will see me.\"",
                "Now while they were going, behold, some of the guards came into the city, and told the chief priests all the things that had happened.",
                "When they were assembled with the elders, and had taken counsel, they gave a large amount of silver to the soldiers,",
                "saying, \"Say that his disciples came by night, and stole him away while we slept.",
                "If this comes to the governor\u2019s ears, we will persuade him and make you free of worry.\"",
                "So they took the money and did as they were told. This saying was spread abroad among the Jews, and continues until this day.",
                "But the eleven disciples went into Galilee, to the mountain where Jesus had sent them.",
                "When they saw him, they bowed down to him, but some doubted.",
                "Jesus came to them and spoke to them, saying, \"All authority has been given to me in heaven and on earth.",
                "Go, and make disciples of all nations, baptizing them in the name of the Father and of the Son and of the Holy Spirit,",
                "teaching them to observe all things that I commanded you. Behold, I am with you always, even to the end of the age.\" Amen."
            ]
        },
        41: {
            1: [
                "The beginning of the Good News of Jesus Christ, the Son of God.",
                "As it is written in the prophets, \"Behold, I send my messenger before your face, who will prepare your way before you.",
                "The voice of one crying in the wilderness, \u2018Make ready the way of the Lord! Make his paths straight!\u2019\"",
                "John came baptizing in the wilderness and preaching the baptism of repentance for forgiveness of sins.",
                "All the country of Judea and all those of Jerusalem went out to him. They were baptized by him in the Jordan river, confessing their sins.",
                "John was clothed with camel\u2019s hair and a leather belt around his waist. He ate locusts and wild honey.",
                "He preached, saying, \"After me comes he who is mightier than I, the thong of whose sandals I am not worthy to stoop down and loosen.",
                "I baptized you in water, but he will baptize you in the Holy Spirit.\"",
                "It happened in those days, that Jesus came from Nazareth of Galilee, and was baptized by John in the Jordan.",
                "Immediately coming up from the water, he saw the heavens parting, and the Spirit descending on him like a dove.",
                "A voice came out of the sky, \"You are my beloved Son, in whom I am well pleased.\"",
                "Immediately the Spirit drove him out into the wilderness.",
                "He was there in the wilderness forty days tempted by Satan. He was with the wild animals; and the angels were serving him.",
                "Now after John was taken into custody, Jesus came into Galilee, preaching the Good News of the Kingdom of God,",
                "and saying, \"The time is fulfilled, and the Kingdom of God is at hand! Repent, and believe in the Good News.\"",
                "Passing along by the sea of Galilee, he saw Simon and Andrew the brother of Simon casting a net into the sea, for they were fishermen.",
                "Jesus said to them, \"Come after me, and I will make you into fishers for men.\"",
                "Immediately they left their nets, and followed him.",
                "Going on a little further from there, he saw James the son of Zebedee, and John, his brother, who were also in the boat mending the nets.",
                "Immediately he called them, and they left their father, Zebedee, in the boat with the hired servants, and went after him.",
                "They went into Capernaum, and immediately on the Sabbath day he entered into the synagogue and taught.",
                "They were astonished at his teaching, for he taught them as having authority, and not as the scribes.",
                "Immediately there was in their synagogue a man with an unclean spirit, and he cried out,",
                "saying, \"Ha! What do we have to do with you, Jesus, you Nazarene? Have you come to destroy us? I know you who you are: the Holy One of God!\"",
                "Jesus rebuked him, saying, \"Be quiet, and come out of him!\"",
                "The unclean spirit, convulsing him and crying with a loud voice, came out of him.",
                "They were all amazed, so that they questioned among themselves, saying, \"What is this? A new teaching? For with authority he commands even the unclean spirits, and they obey him!\"",
                "The report of him went out immediately everywhere into all the region of Galilee and its surrounding area.",
                "Immediately, when they had come out of the synagogue, they came into the house of Simon and Andrew, with James and John.",
                "Now Simon\u2019s wife\u2019s mother lay sick with a fever, and immediately they told him about her.",
                "He came and took her by the hand, and raised her up. The fever left her, and she served them.",
                "At evening, when the sun had set, they brought to him all who were sick, and those who were possessed by demons.",
                "All the city was gathered together at the door.",
                "He healed many who were sick with various diseases, and cast out many demons. He didn\u2019t allow the demons to speak, because they knew him.",
                "Early in the morning, while it was still dark, he rose up and went out, and departed into a deserted place, and prayed there.",
                "Simon and those who were with him followed after him;",
                "and they found him, and told him, \"Everyone is looking for you.\"",
                "He said to them, \"Let\u2019s go elsewhere into the next towns, that I may preach there also, because I came out for this reason.\"",
                "He went into their synagogues throughout all Galilee, preaching and casting out demons.",
                "A leper came to him, begging him, kneeling down to him, and saying to him, \"If you want to, you can make me clean.\"",
                "Being moved with compassion, he stretched out his hand, and touched him, and said to him, \"I want to. Be made clean.\"",
                "When he had said this, immediately the leprosy departed from him, and he was made clean.",
                "He strictly warned him, and immediately sent him out,",
                "and said to him, \"See you say nothing to anybody, but go show yourself to the priest, and offer for your cleansing the things which Moses commanded, for a testimony to them.\"",
                "But he went out, and began to proclaim it much, and to spread about the matter, so that Jesus could no more openly enter into a city, but was outside in desert places: and they came to him from everywhere."
            ],
            2: [
                "When he entered again into Capernaum after some days, it was heard that he was in the house.",
                "Immediately many were gathered together, so that there was no more room, not even around the door; and he spoke the word to them.",
                "Four people came, carrying a paralytic to him.",
                "When they could not come near to him for the crowd, they removed the roof where he was. When they had broken it up, they let down the mat that the paralytic was lying on.",
                "Jesus, seeing their faith, said to the paralytic, \"Son, your sins are forgiven you.\"",
                "But there were some of the scribes sitting there, and reasoning in their hearts,",
                "\"Why does this man speak blasphemies like that? Who can forgive sins but God alone?\"",
                "Immediately Jesus, perceiving in his spirit that they so reasoned within themselves, said to them, \"Why do you reason these things in your hearts?",
                "Which is easier, to tell the paralytic, \u2018Your sins are forgiven;\u2019 or to say, \u2018Arise, and take up your bed, and walk?\u2019",
                "But that you may know that the Son of Man has authority on earth to forgive sins\"\u2014he said to the paralytic\u2014",
                "\"I tell you, arise, take up your mat, and go to your house.\"",
                "He arose, and immediately took up the mat, and went out in front of them all; so that they were all amazed, and glorified God, saying, \"We never saw anything like this!\"",
                "He went out again by the seaside. All the multitude came to him, and he taught them.",
                "As he passed by, he saw Levi, the son of Alphaeus, sitting at the tax office, and he said to him, \"Follow me.\" And he arose and followed him.",
                "It happened, that he was reclining at the table in his house, and many tax collectors and sinners sat down with Jesus and his disciples, for there were many, and they followed him.",
                "The scribes and the Pharisees, when they saw that he was eating with the sinners and tax collectors, said to his disciples, \"Why is it that he eats and drinks with tax collectors and sinners?\"",
                "When Jesus heard it, he said to them, \"Those who are healthy have no need for a physician, but those who are sick. I came not to call the righteous, but sinners to repentance.\"",
                "John\u2019s disciples and the Pharisees were fasting, and they came and asked him, \"Why do John\u2019s disciples and the disciples of the Pharisees fast, but your disciples don\u2019t fast?\"",
                "Jesus said to them, \"Can the groomsmen fast while the bridegroom is with them? As long as they have the bridegroom with them, they can\u2019t fast.",
                "But the days will come when the bridegroom will be taken away from them, and then will they fast in that day.",
                "No one sews a piece of unshrunk cloth on an old garment, or else the patch shrinks and the new tears away from the old, and a worse hole is made.",
                "No one puts new wine into old wineskins, or else the new wine will burst the skins, and the wine pours out, and the skins will be destroyed; but they put new wine into fresh wineskins.\"",
                "It happened that he was going on the Sabbath day through the grain fields, and his disciples began, as they went, to pluck the ears of grain.",
                "The Pharisees said to him, \"Behold, why do they do that which is not lawful on the Sabbath day?\"",
                "He said to them, \"Did you never read what David did, when he had need, and was hungry\u2014he, and those who were with him?",
                "How he entered into the house of God when Abiathar was high priest, and ate the show bread, which is not lawful to eat except for the priests, and gave also to those who were with him?\"",
                "He said to them, \"The Sabbath was made for man, not man for the Sabbath.",
                "Therefore the Son of Man is lord even of the Sabbath.\""
            ],
            3: [
                "He entered again into the synagogue, and there was a man there who had his hand withered.",
                "They watched him, whether he would heal him on the Sabbath day, that they might accuse him.",
                "He said to the man who had his hand withered, \"Stand up.\"",
                "He said to them, \"Is it lawful on the Sabbath day to do good, or to do harm? To save a life, or to kill?\" But they were silent.",
                "When he had looked around at them with anger, being grieved at the hardening of their hearts, he said to the man, \"Stretch out your hand.\" He stretched it out, and his hand was restored as healthy as the other.",
                "The Pharisees went out, and immediately conspired with the Herodians against him, how they might destroy him.",
                "Jesus withdrew to the sea with his disciples, and a great multitude followed him from Galilee, from Judea,",
                "from Jerusalem, from Idumaea, beyond the Jordan, and those from around Tyre and Sidon. A great multitude, hearing what great things he did, came to him.",
                "He spoke to his disciples that a little boat should stay near him because of the crowd, so that they wouldn\u2019t press on him.",
                "For he had healed many, so that as many as had diseases pressed on him that they might touch him.",
                "The unclean spirits, whenever they saw him, fell down before him, and cried, \"You are the Son of God!\"",
                "He sternly warned them that they should not make him known.",
                "He went up into the mountain, and called to himself those whom he wanted, and they went to him.",
                "He appointed twelve, that they might be with him, and that he might send them out to preach,",
                "and to have authority to heal sicknesses and to cast out demons:",
                "Simon, to whom he gave the name Peter;",
                "James the son of Zebedee; John, the brother of James, and he surnamed them Boanerges, which means, Sons of Thunder;",
                "Andrew; Philip; Bartholomew; Matthew; Thomas; James, the son of Alphaeus; Thaddaeus; Simon the Zealot;",
                "and Judas Iscariot, who also betrayed him. He came into a house.",
                "The multitude came together again, so that they could not so much as eat bread.",
                "When his friends heard it, they went out to seize him: for they said, \"He is insane.\"",
                "The scribes who came down from Jerusalem said, \"He has Beelzebul,\" and, \"By the prince of the demons he casts out the demons.\"",
                "He summoned them, and said to them in parables, \"How can Satan cast out Satan?",
                "If a kingdom is divided against itself, that kingdom cannot stand.",
                "If a house is divided against itself, that house cannot stand.",
                "If Satan has risen up against himself, and is divided, he can\u2019t stand, but has an end.",
                "But no one can enter into the house of the strong man to plunder, unless he first binds the strong man; and then he will plunder his house.",
                "Most certainly I tell you, all sins of the descendants of man will be forgiven, including their blasphemies with which they may blaspheme;",
                "but whoever may blaspheme against the Holy Spirit never has forgiveness, but is guilty of an eternal sin\"",
                "\u2014because they said, \"He has an unclean spirit.\"",
                "His mother and his brothers came, and standing outside, they sent to him, calling him.",
                "A multitude was sitting around him, and they told him, \"Behold, your mother, your brothers, and your sisters are outside looking for you.\"",
                "He answered them, \"Who are my mother and my brothers?\"",
                "Looking around at those who sat around him, he said, \"Behold, my mother and my brothers!",
                "For whoever does the will of God, the same is my brother, and my sister, and mother.\""
            ],
            4: [
                "Again he began to teach by the seaside. A great multitude was gathered to him, so that he entered into a boat in the sea, and sat down. All the multitude were on the land by the sea.",
                "He taught them many things in parables, and told them in his teaching,",
                "\"Listen! Behold, the farmer went out to sow,",
                "and it happened, as he sowed, some seed fell by the road, and the birds came and devoured it.",
                "Others fell on the rocky ground, where it had little soil, and immediately it sprang up, because it had no depth of soil.",
                "When the sun had risen, it was scorched; and because it had no root, it withered away.",
                "Others fell among the thorns, and the thorns grew up, and choked it, and it yielded no fruit.",
                "Others fell into the good ground, and yielded fruit, growing up and increasing. Some brought forth thirty times, some sixty times, and some one hundred times as much.\"",
                "He said, \"Whoever has ears to hear, let him hear.\"",
                "When he was alone, those who were around him with the twelve asked him about the parables.",
                "He said to them, \"To you is given the mystery of the Kingdom of God, but to those who are outside, all things are done in parables,",
                "that \u2018seeing they may see, and not perceive; and hearing they may hear, and not understand; lest perhaps they should turn again, and their sins should be forgiven them.\u2019\"",
                "He said to them, \"Don\u2019t you understand this parable? How will you understand all of the parables?",
                "The farmer sows the word.",
                "The ones by the road are the ones where the word is sown; and when they have heard, immediately Satan comes, and takes away the word which has been sown in them.",
                "These in the same way are those who are sown on the rocky places, who, when they have heard the word, immediately receive it with joy.",
                "They have no root in themselves, but are short-lived. When oppression or persecution arises because of the word, immediately they stumble.",
                "Others are those who are sown among the thorns. These are those who have heard the word,",
                "and the cares of this age, and the deceitfulness of riches, and the lusts of other things entering in choke the word, and it becomes unfruitful.",
                "Those which were sown on the good ground are those who hear the word, and accept it, and bear fruit, some thirty times, some sixty times, and some one hundred times.\"",
                "He said to them, \"Is the lamp brought to be put under a basket or under a bed? Isn\u2019t it put on a stand?",
                "For there is nothing hidden, except that it should be made known; neither was anything made secret, but that it should come to light.",
                "If any man has ears to hear, let him hear.\"",
                "He said to them, \"Take heed what you hear. With whatever measure you measure, it will be measured to you, and more will be given to you who hear.",
                "For whoever has, to him will more be given, and he who doesn\u2019t have, even that which he has will be taken away from him.\"",
                "He said, \"The Kingdom of God is as if a man should cast seed on the earth,",
                "and should sleep and rise night and day, and the seed should spring up and grow, he doesn\u2019t know how.",
                "For the earth bears fruit: first the blade, then the ear, then the full grain in the ear.",
                "But when the fruit is ripe, immediately he puts forth the sickle, because the harvest has come.\"",
                "He said, \"How will we liken the Kingdom of God? Or with what parable will we illustrate it?",
                "It\u2019s like a grain of mustard seed, which, when it is sown in the earth, though it is less than all the seeds that are on the earth,",
                "yet when it is sown, grows up, and becomes greater than all the herbs, and puts out great branches, so that the birds of the sky can lodge under its shadow.\"",
                "With many such parables he spoke the word to them, as they were able to hear it.",
                "Without a parable he didn\u2019t speak to them; but privately to his own disciples he explained everything.",
                "On that day, when evening had come, he said to them, \"Let\u2019s go over to the other side.\"",
                "Leaving the multitude, they took him with them, even as he was, in the boat. Other small boats were also with him.",
                "A big wind storm arose, and the waves beat into the boat, so much that the boat was already filled.",
                "He himself was in the stern, asleep on the cushion, and they woke him up, and told him, \"Teacher, don\u2019t you care that we are dying?\"",
                "He awoke, and rebuked the wind, and said to the sea, \"Peace! Be still!\" The wind ceased, and there was a great calm.",
                "He said to them, \"Why are you so afraid? How is it that you have no faith?\"",
                "They were greatly afraid, and said to one another, \"Who then is this, that even the wind and the sea obey him?\""
            ],
            5: [
                "They came to the other side of the sea, into the country of the Gadarenes.",
                "When he had come out of the boat, immediately a man with an unclean spirit met him out of the tombs.",
                "He lived in the tombs. Nobody could bind him any more, not even with chains,",
                "because he had been often bound with fetters and chains, and the chains had been torn apart by him, and the fetters broken in pieces. Nobody had the strength to tame him.",
                "Always, night and day, in the tombs and in the mountains, he was crying out, and cutting himself with stones.",
                "When he saw Jesus from afar, he ran and bowed down to him,",
                "and crying out with a loud voice, he said, \"What have I to do with you, Jesus, you Son of the Most High God? I adjure you by God, don\u2019t torment me.\"",
                "For he said to him, \"Come out of the man, you unclean spirit!\"",
                "He asked him, \"What is your name?\" He said to him, \"My name is Legion, for we are many.\"",
                "He begged him much that he would not send them away out of the country.",
                "Now on the mountainside there was a great herd of pigs feeding.",
                "All the demons begged him, saying, \"Send us into the pigs, that we may enter into them.\"",
                "At once Jesus gave them permission. The unclean spirits came out and entered into the pigs. The herd of about two thousand rushed down the steep bank into the sea, and they were drowned in the sea.",
                "Those who fed them fled, and told it in the city and in the country. The people came to see what it was that had happened.",
                "They came to Jesus, and saw him who had been possessed by demons sitting, clothed, and in his right mind, even him who had the legion; and they were afraid.",
                "Those who saw it declared to them how it happened to him who was possessed by demons, and about the pigs.",
                "They began to beg him to depart from their region.",
                "As he was entering into the boat, he who had been possessed by demons begged him that he might be with him.",
                "He didn\u2019t allow him, but said to him, \"Go to your house, to your friends, and tell them what great things the Lord has done for you, and how he had mercy on you.\"",
                "He went his way, and began to proclaim in Decapolis how Jesus had done great things for him, and everyone marveled.",
                "When Jesus had crossed back over in the boat to the other side, a great multitude was gathered to him; and he was by the sea.",
                "Behold, one of the rulers of the synagogue, Jairus by name, came; and seeing him, he fell at his feet,",
                "and begged him much, saying, \"My little daughter is at the point of death. Please come and lay your hands on her, that she may be made healthy, and live.\"",
                "He went with him, and a great multitude followed him, and they pressed upon him on all sides.",
                "A certain woman, who had an issue of blood for twelve years,",
                "and had suffered many things by many physicians, and had spent all that she had, and was no better, but rather grew worse,",
                "having heard the things concerning Jesus, came up behind him in the crowd, and touched his clothes.",
                "For she said, \"If I just touch his clothes, I will be made well.\"",
                "Immediately the flow of her blood was dried up, and she felt in her body that she was healed of her affliction.",
                "Immediately Jesus, perceiving in himself that the power had gone out from him, turned around in the crowd, and asked, \"Who touched my clothes?\"",
                "His disciples said to him, \"You see the multitude pressing against you, and you say, \u2018Who touched me?\u2019\"",
                "He looked around to see her who had done this thing.",
                "But the woman, fearing and trembling, knowing what had been done to her, came and fell down before him, and told him all the truth.",
                "He said to her, \"Daughter, your faith has made you well. Go in peace, and be cured of your disease.\"",
                "While he was still speaking, people came from the synagogue ruler\u2019s house saying, \"Your daughter is dead. Why bother the Teacher any more?\"",
                "But Jesus, when he heard the message spoken, immediately said to the ruler of the synagogue, \"Don\u2019t be afraid, only believe.\"",
                "He allowed no one to follow him, except Peter, James, and John the brother of James.",
                "He came to the synagogue ruler\u2019s house, and he saw an uproar, weeping, and great wailing.",
                "When he had entered in, he said to them, \"Why do you make an uproar and weep? The child is not dead, but is asleep.\"",
                "They ridiculed him. But he, having put them all out, took the father of the child, her mother, and those who were with him, and went in where the child was lying.",
                "Taking the child by the hand, he said to her, \"Talitha cumi!\" which means, being interpreted, \"Girl, I tell you, get up!\"",
                "Immediately the girl rose up and walked, for she was twelve years old. They were amazed with great amazement.",
                "He strictly ordered them that no one should know this, and commanded that something should be given to her to eat."
            ],
            6: [
                "He went out from there. He came into his own country, and his disciples followed him.",
                "When the Sabbath had come, he began to teach in the synagogue, and many hearing him were astonished, saying, \"Where did this man get these things?\" and, \"What is the wisdom that is given to this man, that such mighty works come about by his hands?",
                "Isn\u2019t this the carpenter, the son of Mary, and brother of James, Joses, Judah, and Simon? Aren\u2019t his sisters here with us?\" They were offended at him.",
                "Jesus said to them, \"A prophet is not without honor, except in his own country, and among his own relatives, and in his own house.\"",
                "He could do no mighty work there, except that he laid his hands on a few sick people, and healed them.",
                "He marveled because of their unbelief. He went around the villages teaching.",
                "He called to himself the twelve, and began to send them out two by two; and he gave them authority over the unclean spirits.",
                "He commanded them that they should take nothing for their journey, except a staff only: no bread, no wallet, no money in their purse,",
                "but to wear sandals, and not put on two tunics.",
                "He said to them, \"Wherever you enter into a house, stay there until you depart from there.",
                "Whoever will not receive you nor hear you, as you depart from there, shake off the dust that is under your feet for a testimony against them. Assuredly, I tell you, it will be more tolerable for Sodom and Gomorrah in the day of judgment than for that city!\"",
                "They went out and preached that people should repent.",
                "They cast out many demons, and anointed many with oil who were sick, and healed them.",
                "King Herod heard this, for his name had become known, and he said, \"John the Baptizer has risen from the dead, and therefore these powers are at work in him.\"",
                "But others said, \"He is Elijah.\" Others said, \"He is a prophet, or like one of the prophets.\"",
                "But Herod, when he heard this, said, \"This is John, whom I beheaded. He has risen from the dead.\"",
                "For Herod himself had sent out and arrested John, and bound him in prison for the sake of Herodias, his brother Philip\u2019s wife, for he had married her.",
                "For John said to Herod, \"It is not lawful for you to have your brother\u2019s wife.\"",
                "Herodias set herself against him, and desired to kill him, but she couldn\u2019t,",
                "for Herod feared John, knowing that he was a righteous and holy man, and kept him safe. When he heard him, he did many things, and he heard him gladly.",
                "Then a convenient day came, that Herod on his birthday made a supper for his nobles, the high officers, and the chief men of Galilee.",
                "When the daughter of Herodias herself came in and danced, she pleased Herod and those sitting with him. The king said to the young lady, \"Ask me whatever you want, and I will give it to you.\"",
                "He swore to her, \"Whatever you shall ask of me, I will give you, up to half of my kingdom.\"",
                "She went out, and said to her mother, \"What shall I ask?\" She said, \"The head of John the Baptizer.\"",
                "She came in immediately with haste to the king, and asked, \"I want you to give me right now the head of John the Baptizer on a platter.\"",
                "The king was exceedingly sorry, but for the sake of his oaths, and of his dinner guests, he didn\u2019t wish to refuse her.",
                "Immediately the king sent out a soldier of his guard, and commanded to bring John\u2019s head, and he went and beheaded him in the prison,",
                "and brought his head on a platter, and gave it to the young lady; and the young lady gave it to her mother.",
                "When his disciples heard this, they came and took up his corpse, and laid it in a tomb.",
                "The apostles gathered themselves together to Jesus, and they told him all things, whatever they had done, and whatever they had taught.",
                "He said to them, \"You come apart into a deserted place, and rest awhile.\" For there were many coming and going, and they had no leisure so much as to eat.",
                "They went away in the boat to a deserted place by themselves.",
                "They saw them going, and many recognized him and ran there on foot from all the cities. They arrived before them and came together to him.",
                "Jesus came out, saw a great multitude, and he had compassion on them, because they were like sheep without a shepherd, and he began to teach them many things.",
                "When it was late in the day, his disciples came to him, and said, \"This place is deserted, and it is late in the day.",
                "Send them away, that they may go into the surrounding country and villages, and buy themselves bread, for they have nothing to eat.\"",
                "But he answered them, \"You give them something to eat.\" They asked him, \"Shall we go and buy two hundred denarii worth of bread, and give them something to eat?\"",
                "He said to them, \"How many loaves do you have? Go see.\" When they knew, they said, \"Five, and two fish.\"",
                "He commanded them that everyone should sit down in groups on the green grass.",
                "They sat down in ranks, by hundreds and by fifties.",
                "He took the five loaves and the two fish, and looking up to heaven, he blessed and broke the loaves, and he gave to his disciples to set before them, and he divided the two fish among them all.",
                "They all ate, and were filled.",
                "They took up twelve baskets full of broken pieces and also of the fish.",
                "Those who ate the loaves were five thousand men.",
                "Immediately he made his disciples get into the boat, and to go ahead to the other side, to Bethsaida, while he himself sent the multitude away.",
                "After he had taken leave of them, he went up the mountain to pray.",
                "When evening had come, the boat was in the midst of the sea, and he was alone on the land.",
                "Seeing them distressed in rowing, for the wind was contrary to them, about the fourth watch of the night he came to them, walking on the sea, and he would have passed by them,",
                "but they, when they saw him walking on the sea, supposed that it was a ghost, and cried out;",
                "for they all saw him, and were troubled. But he immediately spoke with them, and said to them, \"Cheer up! It is I! Don\u2019t be afraid.\"",
                "He got into the boat with them; and the wind ceased, and they were very amazed among themselves, and marveled;",
                "for they hadn\u2019t understood about the loaves, but their hearts were hardened.",
                "When they had crossed over, they came to land at Gennesaret, and moored to the shore.",
                "When they had come out of the boat, immediately the people recognized him,",
                "and ran around that whole region, and began to bring those who were sick, on their mats, to where they heard he was.",
                "Wherever he entered, into villages, or into cities, or into the country, they laid the sick in the marketplaces, and begged him that they might touch just the fringe of his garment; and as many as touched him were made well."
            ],
            7: [
                "Then the Pharisees, and some of the scribes gathered together to him, having come from Jerusalem.",
                "Now when they saw some of his disciples eating bread with defiled, that is, unwashed, hands, they found fault.",
                "(For the Pharisees, and all the Jews, don\u2019t eat unless they wash their hands and forearms, holding to the tradition of the elders.",
                "They don\u2019t eat when they come from the marketplace, unless they bathe themselves, and there are many other things, which they have received to hold to: washings of cups, pitchers, bronze vessels, and couches.)",
                "The Pharisees and the scribes asked him, \"Why don\u2019t your disciples walk according to the tradition of the elders, but eat their bread with unwashed hands?\"",
                "He answered them, \"Well did Isaiah prophesy of you hypocrites, as it is written, \u2018This people honors me with their lips, but their heart is far from me.",
                "But in vain do they worship me, teaching as doctrines the commandments of men.\u2019",
                "\"For you set aside the commandment of God, and hold tightly to the tradition of men\u2014the washing of pitchers and cups, and you do many other such things.\"",
                "He said to them, \"Full well do you reject the commandment of God, that you may keep your tradition.",
                "For Moses said, \u2018Honor your father and your mother;\u2019 and, \u2018He who speaks evil of father or mother, let him be put to death.\u2019",
                "But you say, \u2018If a man tells his father or his mother, \"Whatever profit you might have received from me is Corban, that is to say, given to God;\"\u2019",
                "then you no longer allow him to do anything for his father or his mother,",
                "making void the word of God by your tradition, which you have handed down. You do many things like this.\"",
                "He called all the multitude to himself, and said to them, \"Hear me, all of you, and understand.",
                "There is nothing from outside of the man, that going into him can defile him; but the things which proceed out of the man are those that defile the man.",
                "If anyone has ears to hear, let him hear!\"",
                "When he had entered into a house away from the multitude, his disciples asked him about the parable.",
                "He said to them, \"Are you thus without understanding also? Don\u2019t you perceive that whatever goes into the man from outside can\u2019t defile him,",
                "because it doesn\u2019t go into his heart, but into his stomach, then into the latrine, thus purifying all foods?\"",
                "He said, \"That which proceeds out of the man, that defiles the man.",
                "For from within, out of the hearts of men, proceed evil thoughts, adulteries, sexual sins, murders, thefts,",
                "covetings, wickedness, deceit, lustful desires, an evil eye, blasphemy, pride, and foolishness.",
                "All these evil things come from within, and defile the man.\"",
                "From there he arose, and went away into the borders of Tyre and Sidon. He entered into a house, and didn\u2019t want anyone to know it, but he couldn\u2019t escape notice.",
                "For a woman, whose little daughter had an unclean spirit, having heard of him, came and fell down at his feet.",
                "Now the woman was a Greek, a Syrophoenician by race. She begged him that he would cast the demon out of her daughter.",
                "But Jesus said to her, \"Let the children be filled first, for it is not appropriate to take the children\u2019s bread and throw it to the dogs.\"",
                "But she answered him, \"Yes, Lord. Yet even the dogs under the table eat the children\u2019s crumbs.\"",
                "He said to her, \"For this saying, go your way. The demon has gone out of your daughter.\"",
                "She went away to her house, and found the child having been laid on the bed, with the demon gone out.",
                "Again he departed from the borders of Tyre and Sidon, and came to the sea of Galilee, through the midst of the region of Decapolis.",
                "They brought to him one who was deaf and had an impediment in his speech. They begged him to lay his hand on him.",
                "He took him aside from the multitude, privately, and put his fingers into his ears, and he spat, and touched his tongue.",
                "Looking up to heaven, he sighed, and said to him, \"Ephphatha!\" that is, \"Be opened!\"",
                "Immediately his ears were opened, and the impediment of his tongue was released, and he spoke clearly.",
                "He commanded them that they should tell no one, but the more he commanded them, so much the more widely they proclaimed it.",
                "They were astonished beyond measure, saying, \"He has done all things well. He makes even the deaf hear, and the mute speak!\""
            ],
            8: [
                "In those days, when there was a very great multitude, and they had nothing to eat, Jesus called his disciples to himself, and said to them,",
                "\"I have compassion on the multitude, because they have stayed with me now three days, and have nothing to eat.",
                "If I send them away fasting to their home, they will faint on the way, for some of them have come a long way.\"",
                "His disciples answered him, \"From where could one satisfy these people with bread here in a deserted place?\"",
                "He asked them, \"How many loaves do you have?\" They said, \"Seven.\"",
                "He commanded the multitude to sit down on the ground, and he took the seven loaves. Having given thanks, he broke them, and gave them to his disciples to serve, and they served the multitude.",
                "They had a few small fish. Having blessed them, he said to serve these also.",
                "They ate, and were filled. They took up seven baskets of broken pieces that were left over.",
                "Those who had eaten were about four thousand. Then he sent them away.",
                "Immediately he entered into the boat with his disciples, and came into the region of Dalmanutha.",
                "The Pharisees came out and began to question him, seeking from him a sign from heaven, and testing him.",
                "He sighed deeply in his spirit, and said, \"Why does this generation seek a sign? Most certainly I tell you, no sign will be given to this generation.\"",
                "He left them, and again entering into the boat, departed to the other side.",
                "They forgot to take bread; and they didn\u2019t have more than one loaf in the boat with them.",
                "He warned them, saying, \"Take heed: beware of the yeast of the Pharisees and the yeast of Herod.\"",
                "They reasoned with one another, saying, \"It\u2019s because we have no bread.\"",
                "Jesus, perceiving it, said to them, \"Why do you reason that it\u2019s because you have no bread? Don\u2019t you perceive yet, neither understand? Is your heart still hardened?",
                "Having eyes, don\u2019t you see? Having ears, don\u2019t you hear? Don\u2019t you remember?",
                "When I broke the five loaves among the five thousand, how many baskets full of broken pieces did you take up?\" They told him, \"Twelve.\"",
                "\"When the seven loaves fed the four thousand, how many baskets full of broken pieces did you take up?\" They told him, \"Seven.\"",
                "He asked them, \"Don\u2019t you understand, yet?\"",
                "He came to Bethsaida. They brought a blind man to him, and begged him to touch him.",
                "He took hold of the blind man by the hand, and brought him out of the village. When he had spit on his eyes, and laid his hands on him, he asked him if he saw anything.",
                "He looked up, and said, \"I see men; for I see them like trees walking.\"",
                "Then again he laid his hands on his eyes. He looked intently, and was restored, and saw everyone clearly.",
                "He sent him away to his house, saying, \"Don\u2019t enter into the village, nor tell anyone in the village.\"",
                "Jesus went out, with his disciples, into the villages of Caesarea Philippi. On the way he asked his disciples, \"Who do men say that I am?\"",
                "They told him, \"John the Baptizer, and others say Elijah, but others: one of the prophets.\"",
                "He said to them, \"But who do you say that I am?\" Peter answered, \"You are the Christ.\"",
                "He commanded them that they should tell no one about him.",
                "He began to teach them that the Son of Man must suffer many things, and be rejected by the elders, the chief priests, and the scribes, and be killed, and after three days rise again.",
                "He spoke to them openly. Peter took him, and began to rebuke him.",
                "But he, turning around, and seeing his disciples, rebuked Peter, and said, \"Get behind me, Satan! For you have in mind not the things of God, but the things of men.\"",
                "He called the multitude to himself with his disciples, and said to them, \"Whoever wants to come after me, let him deny himself, and take up his cross, and follow me.",
                "For whoever wants to save his life will lose it; and whoever will lose his life for my sake and the sake of the Good News will save it.",
                "For what does it profit a man, to gain the whole world, and forfeit his life?",
                "For what will a man give in exchange for his life?",
                "For whoever will be ashamed of me and of my words in this adulterous and sinful generation, the Son of Man also will be ashamed of him, when he comes in the glory of his Father with the holy angels.\""
            ],
            9: [
                "He said to them, \"Most certainly I tell you, there are some standing here who will in no way taste death until they see the Kingdom of God come with power.\"",
                "After six days Jesus took with him Peter, James, and John, and brought them up onto a high mountain privately by themselves, and he was changed into another form in front of them.",
                "His clothing became glistening, exceedingly white, like snow, such as no launderer on earth can whiten them.",
                "Elijah and Moses appeared to them, and they were talking with Jesus.",
                "Peter answered Jesus, \"Rabbi, it is good for us to be here. Let\u2019s make three tents: one for you, one for Moses, and one for Elijah.\"",
                "For he didn\u2019t know what to say, for they were very afraid.",
                "A cloud came, overshadowing them, and a voice came out of the cloud, \"This is my beloved Son. Listen to him.\"",
                "Suddenly looking around, they saw no one with them any more, except Jesus only.",
                "As they were coming down from the mountain, he commanded them that they should tell no one what things they had seen, until after the Son of Man had risen from the dead.",
                "They kept this saying to themselves, questioning what the \"rising from the dead\" meant.",
                "They asked him, saying, \"Why do the scribes say that Elijah must come first?\"",
                "He said to them, \"Elijah indeed comes first, and restores all things. How is it written about the Son of Man, that he should suffer many things and be despised?",
                "But I tell you that Elijah has come, and they have also done to him whatever they wanted to, even as it is written about him.\"",
                "Coming to the disciples, he saw a great multitude around them, and scribes questioning them.",
                "Immediately all the multitude, when they saw him, were greatly amazed, and running to him greeted him.",
                "He asked the scribes, \"What are you asking them?\"",
                "One of the multitude answered, \"Teacher, I brought to you my son, who has a mute spirit;",
                "and wherever it seizes him, it throws him down, and he foams at the mouth, and grinds his teeth, and wastes away. I asked your disciples to cast it out, and they weren\u2019t able.\"",
                "He answered him, \"Unbelieving generation, how long shall I be with you? How long shall I bear with you? Bring him to me.\"",
                "They brought him to him, and when he saw him, immediately the spirit convulsed him, and he fell on the ground, wallowing and foaming at the mouth.",
                "He asked his father, \"How long has it been since this has come to him?\" He said, \"From childhood.",
                "Often it has cast him both into the fire and into the water, to destroy him. But if you can do anything, have compassion on us, and help us.\"",
                "Jesus said to him, \"If you can believe, all things are possible to him who believes.\"",
                "Immediately the father of the child cried out with tears, \"I believe. Help my unbelief!\"",
                "When Jesus saw that a multitude came running together, he rebuked the unclean spirit, saying to him, \"You mute and deaf spirit, I command you, come out of him, and never enter him again!\"",
                "Having cried out, and convulsed greatly, it came out of him. The boy became like one dead; so much that most of them said, \"He is dead.\"",
                "But Jesus took him by the hand, and raised him up; and he arose.",
                "When he had come into the house, his disciples asked him privately, \"Why couldn\u2019t we cast it out?\"",
                "He said to them, \"This kind can come out by nothing, except by prayer and fasting.\"",
                "They went out from there, and passed through Galilee. He didn\u2019t want anyone to know it.",
                "For he was teaching his disciples, and said to them, \"The Son of Man is being handed over to the hands of men, and they will kill him; and when he is killed, on the third day he will rise again.\"",
                "But they didn\u2019t understand the saying, and were afraid to ask him.",
                "He came to Capernaum, and when he was in the house he asked them, \"What were you arguing among yourselves on the way?\"",
                "But they were silent, for they had disputed one with another on the way about who was the greatest.",
                "He sat down, and called the twelve; and he said to them, \"If any man wants to be first, he shall be last of all, and servant of all.\"",
                "He took a little child, and set him in their midst. Taking him in his arms, he said to them,",
                "\"Whoever receives one such little child in my name, receives me, and whoever receives me, doesn\u2019t receive me, but him who sent me.\"",
                "John said to him, \"Teacher, we saw someone who doesn\u2019t follow us casting out demons in your name; and we forbade him, because he doesn\u2019t follow us.\"",
                "But Jesus said, \"Don\u2019t forbid him, for there is no one who will do a mighty work in my name, and be able quickly to speak evil of me.",
                "For whoever is not against us is on our side.",
                "For whoever will give you a cup of water to drink in my name, because you are Christ\u2019s, most certainly I tell you, he will in no way lose his reward.",
                "Whoever will cause one of these little ones who believe in me to stumble, it would be better for him if he were thrown into the sea with a millstone hung around his neck.",
                "If your hand causes you to stumble, cut it off. It is better for you to enter into life maimed, rather than having your two hands to go into Gehenna, into the unquenchable fire,",
                "\u2018where their worm doesn\u2019t die, and the fire is not quenched.\u2019",
                "If your foot causes you to stumble, cut it off. It is better for you to enter into life lame, rather than having your two feet to be cast into Gehenna, into the fire that will never be quenched\u2014",
                "\u2018where their worm doesn\u2019t die, and the fire is not quenched.\u2019",
                "If your eye causes you to stumble, cast it out. It is better for you to enter into the Kingdom of God with one eye, rather than having two eyes to be cast into the Gehenna of fire,",
                "\u2018where their worm doesn\u2019t die, and the fire is not quenched.\u2019",
                "For everyone will be salted with fire, and every sacrifice will be seasoned with salt.",
                "Salt is good, but if the salt has lost its saltiness, with what will you season it? Have salt in yourselves, and be at peace with one another.\""
            ],
            10: [
                "He arose from there and came into the borders of Judea and beyond the Jordan. Multitudes came together to him again. As he usually did, he was again teaching them.",
                "Pharisees came to him testing him, and asked him, \"Is it lawful for a man to divorce his wife?\"",
                "He answered, \"What did Moses command you?\"",
                "They said, \"Moses allowed a certificate of divorce to be written, and to divorce her.\"",
                "But Jesus said to them, \"For your hardness of heart, he wrote you this commandment.",
                "But from the beginning of the creation, God made them male and female.",
                "For this cause a man will leave his father and mother, and will join to his wife,",
                "and the two will become one flesh, so that they are no longer two, but one flesh.",
                "What therefore God has joined together, let no man separate.\"",
                "In the house, his disciples asked him again about the same matter.",
                "He said to them, \"Whoever divorces his wife, and marries another, commits adultery against her.",
                "If a woman herself divorces her husband, and marries another, she commits adultery.\"",
                "They were bringing to him little children, that he should touch them, but the disciples rebuked those who were bringing them.",
                "But when Jesus saw it, he was moved with indignation, and said to them, \"Allow the little children to come to me! Don\u2019t forbid them, for the Kingdom of God belongs to such as these.",
                "Most certainly I tell you, whoever will not receive the Kingdom of God like a little child, he will in no way enter into it.\"",
                "He took them in his arms, and blessed them, laying his hands on them.",
                "As he was going out into the way, one ran to him, knelt before him, and asked him, \"Good Teacher, what shall I do that I may inherit eternal life?\"",
                "Jesus said to him, \"Why do you call me good? No one is good except one\u2014God.",
                "You know the commandments: \u2018Do not murder,\u2019 \u2018Do not commit adultery,\u2019 \u2018Do not steal,\u2019 \u2018Do not give false testimony,\u2019 \u2018Do not defraud,\u2019 \u2018Honor your father and mother.\u2019\"",
                "He said to him, \"Teacher, I have observed all these things from my youth.\"",
                "Jesus looking at him loved him, and said to him, \"One thing you lack. Go, sell whatever you have, and give to the poor, and you will have treasure in heaven; and come, follow me, taking up the cross.\"",
                "But his face fell at that saying, and he went away sorrowful, for he was one who had great possessions.",
                "Jesus looked around, and said to his disciples, \"How difficult it is for those who have riches to enter into the Kingdom of God!\"",
                "The disciples were amazed at his words. But Jesus answered again, \"Children, how hard is it for those who trust in riches to enter into the Kingdom of God!",
                "It is easier for a camel to go through a needle\u2019s eye than for a rich man to enter into the Kingdom of God.\"",
                "They were exceedingly astonished, saying to him, \"Then who can be saved?\"",
                "Jesus, looking at them, said, \"With men it is impossible, but not with God, for all things are possible with God.\"",
                "Peter began to tell him, \"Behold, we have left all, and have followed you.\"",
                "Jesus said, \"Most certainly I tell you, there is no one who has left house, or brothers, or sisters, or father, or mother, or wife, or children, or land, for my sake, and for the sake of the Good News,",
                "but he will receive one hundred times more now in this time, houses, brothers, sisters, mothers, children, and land, with persecutions; and in the age to come eternal life.",
                "But many who are first will be last; and the last first.\"",
                "They were on the way, going up to Jerusalem; and Jesus was going in front of them, and they were amazed; and those who followed were afraid. He again took the twelve, and began to tell them the things that were going to happen to him.",
                "\"Behold, we are going up to Jerusalem. The Son of Man will be delivered to the chief priests and the scribes. They will condemn him to death, and will deliver him to the Gentiles.",
                "They will mock him, spit on him, scourge him, and kill him. On the third day he will rise again.\"",
                "James and John, the sons of Zebedee, came near to him, saying, \"Teacher, we want you to do for us whatever we will ask.\"",
                "He said to them, \"What do you want me to do for you?\"",
                "They said to him, \"Grant to us that we may sit, one at your right hand, and one at your left hand, in your glory.\"",
                "But Jesus said to them, \"You don\u2019t know what you are asking. Are you able to drink the cup that I drink, and to be baptized with the baptism that I am baptized with?\"",
                "They said to him, \"We are able.\" Jesus said to them, \"You shall indeed drink the cup that I drink, and you shall be baptized with the baptism that I am baptized with;",
                "but to sit at my right hand and at my left hand is not mine to give, but for whom it has been prepared.\"",
                "When the ten heard it, they began to be indignant towards James and John.",
                "Jesus summoned them, and said to them, \"You know that they who are recognized as rulers over the nations lord it over them, and their great ones exercise authority over them.",
                "But it shall not be so among you, but whoever wants to become great among you shall be your servant.",
                "Whoever of you wants to become first among you, shall be bondservant of all.",
                "For the Son of Man also came not to be served, but to serve, and to give his life as a ransom for many.\"",
                "They came to Jericho. As he went out from Jericho, with his disciples and a great multitude, the son of Timaeus, Bartimaeus, a blind beggar, was sitting by the road.",
                "When he heard that it was Jesus the Nazarene, he began to cry out, and say, \"Jesus, you son of David, have mercy on me!\"",
                "Many rebuked him, that he should be quiet, but he cried out much more, \"You son of David, have mercy on me!\"",
                "Jesus stood still, and said, \"Call him.\" They called the blind man, saying to him, \"Cheer up! Get up. He is calling you!\"",
                "He, casting away his cloak, sprang up, and came to Jesus.",
                "Jesus asked him, \"What do you want me to do for you?\" The blind man said to him, \"Rabboni, that I may see again.\"",
                "Jesus said to him, \"Go your way. Your faith has made you well.\" Immediately he received his sight, and followed Jesus in the way."
            ],
            11: [
                "When they drew near to Jerusalem, to Bethsphage and Bethany, at the Mount of Olives, he sent two of his disciples,",
                "and said to them, \"Go your way into the village that is opposite you. Immediately as you enter into it, you will find a young donkey tied, on which no one has sat. Untie him, and bring him.",
                "If anyone asks you, \u2018Why are you doing this?\u2019 say, \u2018The Lord needs him;\u2019 and immediately he will send him back here.\"",
                "They went away, and found a young donkey tied at the door outside in the open street, and they untied him.",
                "Some of those who stood there asked them, \"What are you doing, untying the young donkey?\"",
                "They said to them just as Jesus had said, and they let them go.",
                "They brought the young donkey to Jesus, and threw their garments on it, and Jesus sat on it.",
                "Many spread their garments on the way, and others were cutting down branches from the trees, and spreading them on the road.",
                "Those who went in front, and those who followed, cried out, \"Hosanna! Blessed is he who comes in the name of the Lord!",
                "Blessed is the kingdom of our father David that is coming in the name of the Lord! Hosanna in the highest!\"",
                "Jesus entered into the temple in Jerusalem. When he had looked around at everything, it being now evening, he went out to Bethany with the twelve.",
                "The next day, when they had come out from Bethany, he was hungry.",
                "Seeing a fig tree afar off having leaves, he came to see if perhaps he might find anything on it. When he came to it, he found nothing but leaves, for it was not the season for figs.",
                "Jesus told it, \"May no one ever eat fruit from you again!\" and his disciples heard it.",
                "They came to Jerusalem, and Jesus entered into the temple, and began to throw out those who sold and those who bought in the temple, and overthrew the tables of the money changers, and the seats of those who sold the doves.",
                "He would not allow anyone to carry a container through the temple.",
                "He taught, saying to them, \"Isn\u2019t it written, \u2018My house will be called a house of prayer for all the nations?\u2019 But you have made it a den of robbers!\"",
                "The chief priests and the scribes heard it, and sought how they might destroy him. For they feared him, because all the multitude was astonished at his teaching.",
                "When evening came, he went out of the city.",
                "As they passed by in the morning, they saw the fig tree withered away from the roots.",
                "Peter, remembering, said to him, \"Rabbi, look! The fig tree which you cursed has withered away.\"",
                "Jesus answered them, \"Have faith in God.",
                "For most certainly I tell you, whoever may tell this mountain, \u2018Be taken up and cast into the sea,\u2019 and doesn\u2019t doubt in his heart, but believes that what he says is happening; he shall have whatever he says.",
                "Therefore I tell you, all things whatever you pray and ask for, believe that you have received them, and you shall have them.",
                "Whenever you stand praying, forgive, if you have anything against anyone; so that your Father, who is in heaven, may also forgive you your transgressions.",
                "But if you do not forgive, neither will your Father in heaven forgive your transgressions.\"",
                "They came again to Jerusalem, and as he was walking in the temple, the chief priests, and the scribes, and the elders came to him,",
                "and they began saying to him, \"By what authority do you do these things? Or who gave you this authority to do these things?\"",
                "Jesus said to them, \"I will ask you one question. Answer me, and I will tell you by what authority I do these things.",
                "The baptism of John\u2014was it from heaven, or from men? Answer me.\"",
                "They reasoned with themselves, saying, \"If we should say, \u2018From heaven;\u2019 he will say, \u2018Why then did you not believe him?\u2019",
                "If we should say, \u2018From men\u2019\"\u2014they feared the people, for all held John to really be a prophet.",
                "They answered Jesus, \"We don\u2019t know.\" Jesus said to them, \"Neither do I tell you by what authority I do these things.\""
            ],
            12: [
                "He began to speak to them in parables. \"A man planted a vineyard, put a hedge around it, dug a pit for the winepress, built a tower, rented it out to a farmer, and went into another country.",
                "When it was time, he sent a servant to the farmer to get from the farmer his share of the fruit of the vineyard.",
                "They took him, beat him, and sent him away empty.",
                "Again, he sent another servant to them; and they threw stones at him, wounded him in the head, and sent him away shamefully treated.",
                "Again he sent another; and they killed him; and many others, beating some, and killing some.",
                "Therefore still having one, his beloved son, he sent him last to them, saying, \u2018They will respect my son.\u2019",
                "But those farmers said among themselves, \u2018This is the heir. Come, let\u2019s kill him, and the inheritance will be ours.\u2019",
                "They took him, killed him, and cast him out of the vineyard.",
                "What therefore will the lord of the vineyard do? He will come and destroy the farmers, and will give the vineyard to others.",
                "Haven\u2019t you even read this Scripture: \u2018The stone which the builders rejected, the same was made the head of the corner.",
                "This was from the Lord, it is marvelous in our eyes\u2019?\"",
                "They tried to seize him, but they feared the multitude; for they perceived that he spoke the parable against them. They left him, and went away.",
                "They sent some of the Pharisees and of the Herodians to him, that they might trap him with words.",
                "When they had come, they asked him, \"Teacher, we know that you are honest, and don\u2019t defer to anyone; for you aren\u2019t partial to anyone, but truly teach the way of God. Is it lawful to pay taxes to Caesar, or not?",
                "Shall we give, or shall we not give?\" But he, knowing their hypocrisy, said to them, \"Why do you test me? Bring me a denarius, that I may see it.\"",
                "They brought it. He said to them, \"Whose is this image and inscription?\" They said to him, \"Caesar\u2019s.\"",
                "Jesus answered them, \"Render to Caesar the things that are Caesar\u2019s, and to God the things that are God\u2019s.\" They marveled greatly at him.",
                "There came to him Sadducees, who say that there is no resurrection. They asked him, saying,",
                "\"Teacher, Moses wrote to us, \u2018If a man\u2019s brother dies, and leaves a wife behind him, and leaves no children, that his brother should take his wife, and raise up offspring for his brother.\u2019",
                "There were seven brothers. The first took a wife, and dying left no offspring.",
                "The second took her, and died, leaving no children behind him. The third likewise;",
                "and the seven took her and left no children. Last of all the woman also died.",
                "In the resurrection, when they rise, whose wife will she be of them? For the seven had her as a wife.\"",
                "Jesus answered them, \"Isn\u2019t this because you are mistaken, not knowing the Scriptures, nor the power of God?",
                "For when they will rise from the dead, they neither marry, nor are given in marriage, but are like angels in heaven.",
                "But about the dead, that they are raised; haven\u2019t you read in the book of Moses, about the Bush, how God spoke to him, saying, \u2018I am the God of Abraham, the God of Isaac, and the God of Jacob\u2019?",
                "He is not the God of the dead, but of the living. You are therefore badly mistaken.\"",
                "One of the scribes came, and heard them questioning together. Knowing that he had answered them well, asked him, \"Which commandment is the greatest of all?\"",
                "Jesus answered, \"The greatest is, \u2018Hear, Israel, the Lord our God, the Lord is one:",
                "you shall love the Lord your God with all your heart, and with all your soul, and with all your mind, and with all your strength.\u2019 This is the first commandment.",
                "The second is like this, \u2018You shall love your neighbor as yourself.\u2019 There is no other commandment greater than these.\"",
                "The scribe said to him, \"Truly, teacher, you have said well that he is one, and there is none other but he,",
                "and to love him with all the heart, and with all the understanding, with all the soul, and with all the strength, and to love his neighbor as himself, is more important than all whole burnt offerings and sacrifices.\"",
                "When Jesus saw that he answered wisely, he said to him, \"You are not far from the Kingdom of God.\" No one dared ask him any question after that.",
                "Jesus responded, as he taught in the temple, \"How is it that the scribes say that the Christ is the son of David?",
                "For David himself said in the Holy Spirit, \u2018The Lord said to my Lord, \"Sit at my right hand, until I make your enemies the footstool of your feet.\"\u2019",
                "Therefore David himself calls him Lord, so how can he be his son?\" The common people heard him gladly.",
                "In his teaching he said to them, \"Beware of the scribes, who like to walk in long robes, and to get greetings in the marketplaces,",
                "and the best seats in the synagogues, and the best places at feasts:",
                "those who devour widows\u2019 houses, and for a pretense make long prayers. These will receive greater condemnation.\"",
                "Jesus sat down opposite the treasury, and saw how the multitude cast money into the treasury. Many who were rich cast in much.",
                "A poor widow came, and she cast in two small brass coins, which equal a quadrans coin.",
                "He called his disciples to himself, and said to them, \"Most certainly I tell you, this poor widow gave more than all those who are giving into the treasury,",
                "for they all gave out of their abundance, but she, out of her poverty, gave all that she had to live on.\""
            ],
            13: [
                "As he went out of the temple, one of his disciples said to him, \"Teacher, see what kind of stones and what kind of buildings!\"",
                "Jesus said to him, \"Do you see these great buildings? There will not be left here one stone on another, which will not be thrown down.\"",
                "As he sat on the Mount of Olives opposite the temple, Peter, James, John, and Andrew asked him privately,",
                "\"Tell us, when will these things be? What is the sign that these things are all about to be fulfilled?\"",
                "Jesus, answering, began to tell them, \"Be careful that no one leads you astray.",
                "For many will come in my name, saying, \u2018I am he!\u2019 and will lead many astray.",
                "\"When you hear of wars and rumors of wars, don\u2019t be troubled. For those must happen, but the end is not yet.",
                "For nation will rise against nation, and kingdom against kingdom. There will be earthquakes in various places. There will be famines and troubles. These things are the beginning of birth pains.",
                "But watch yourselves, for they will deliver you up to councils. You will be beaten in synagogues. You will stand before rulers and kings for my sake, for a testimony to them.",
                "The Good News must first be preached to all the nations.",
                "When they lead you away and deliver you up, don\u2019t be anxious beforehand, or premeditate what you will say, but say whatever will be given you in that hour. For it is not you who speak, but the Holy Spirit.",
                "\"Brother will deliver up brother to death, and the father his child. Children will rise up against parents, and cause them to be put to death.",
                "You will be hated by all men for my name\u2019s sake, but he who endures to the end, the same will be saved.",
                "But when you see the abomination of desolation, spoken of by Daniel the prophet, standing where it ought not (let the reader understand), then let those who are in Judea flee to the mountains,",
                "and let him who is on the housetop not go down, nor enter in, to take anything out of his house.",
                "Let him who is in the field not return back to take his cloak.",
                "But woe to those who are with child and to those who nurse babies in those days!",
                "Pray that your flight won\u2019t be in the winter.",
                "For in those days there will be oppression, such as there has not been the like from the beginning of the creation which God created until now, and never will be.",
                "Unless the Lord had shortened the days, no flesh would have been saved; but for the sake of the chosen ones, whom he picked out, he shortened the days.",
                "Then if anyone tells you, \u2018Look, here is the Christ!\u2019 or, \u2018Look, there!\u2019 don\u2019t believe it.",
                "For there will arise false christs and false prophets, and will show signs and wonders, that they may lead astray, if possible, even the chosen ones.",
                "But you watch. \"Behold, I have told you all things beforehand.",
                "But in those days, after that oppression, the sun will be darkened, the moon will not give its light,",
                "the stars will be falling from the sky, and the powers that are in the heavens will be shaken.",
                "Then they will see the Son of Man coming in clouds with great power and glory.",
                "Then he will send out his angels, and will gather together his chosen ones from the four winds, from the ends of the earth to the ends of the sky.",
                "\"Now from the fig tree, learn this parable. When the branch has now become tender, and puts forth its leaves, you know that the summer is near;",
                "even so you also, when you see these things coming to pass, know that it is near, at the doors.",
                "Most certainly I say to you, this generation will not pass away until all these things happen.",
                "Heaven and earth will pass away, but my words will not pass away.",
                "But of that day or that hour no one knows, not even the angels in heaven, nor the Son, but only the Father.",
                "Watch, keep alert, and pray; for you don\u2019t know when the time is.",
                "\"It is like a man, traveling to another country, having left his house, and given authority to his servants, and to each one his work, and also commanded the doorkeeper to keep watch.",
                "Watch therefore, for you don\u2019t know when the lord of the house is coming, whether at evening, or at midnight, or when the rooster crows, or in the morning;",
                "lest coming suddenly he might find you sleeping.",
                "What I tell you, I tell all: Watch.\""
            ],
            14: [
                "It was now two days before the feast of the Passover and the unleavened bread, and the chief priests and the scribes sought how they might seize him by deception, and kill him.",
                "For they said, \"Not during the feast, because there might be a riot of the people.\"",
                "While he was at Bethany, in the house of Simon the leper, as he sat at the table, a woman came having an alabaster jar of ointment of pure nard\u2014very costly. She broke the jar, and poured it over his head.",
                "But there were some who were indignant among themselves, saying, \"Why has this ointment been wasted?",
                "For this might have been sold for more than three hundred denarii, and given to the poor.\" They grumbled against her.",
                "But Jesus said, \"Leave her alone. Why do you trouble her? She has done a good work for me.",
                "For you always have the poor with you, and whenever you want to, you can do them good; but you will not always have me.",
                "She has done what she could. She has anointed my body beforehand for the burying.",
                "Most certainly I tell you, wherever this Good News may be preached throughout the whole world, that which this woman has done will also be spoken of for a memorial of her.\"",
                "Judas Iscariot, who was one of the twelve, went away to the chief priests, that he might deliver him to them.",
                "They, when they heard it, were glad, and promised to give him money. He sought how he might conveniently deliver him.",
                "On the first day of unleavened bread, when they sacrificed the Passover, his disciples asked him, \"Where do you want us to go and prepare that you may eat the Passover?\"",
                "He sent two of his disciples, and said to them, \"Go into the city, and there you will meet a man carrying a pitcher of water. Follow him,",
                "and wherever he enters in, tell the master of the house, \u2018The Teacher says, \"Where is the guest room, where I may eat the Passover with my disciples?\"\u2019",
                "He will himself show you a large upper room furnished and ready. Get ready for us there.\"",
                "His disciples went out, and came into the city, and found things as he had said to them, and they prepared the Passover.",
                "When it was evening he came with the twelve.",
                "As they sat and were eating, Jesus said, \"Most certainly I tell you, one of you will betray me\u2014he who eats with me.\"",
                "They began to be sorrowful, and to ask him one by one, \"Surely not I?\" And another said, \"Surely not I?\"",
                "He answered them, \"It is one of the twelve, he who dips with me in the dish.",
                "For the Son of Man goes, even as it is written about him, but woe to that man by whom the Son of Man is betrayed! It would be better for that man if he had not been born.\"",
                "As they were eating, Jesus took bread, and when he had blessed, he broke it, and gave to them, and said, \"Take, eat. This is my body.\"",
                "He took the cup, and when he had given thanks, he gave to them. They all drank of it.",
                "He said to them, \"This is my blood of the new covenant, which is poured out for many.",
                "Most certainly I tell you, I will no more drink of the fruit of the vine, until that day when I drink it anew in the Kingdom of God.\"",
                "When they had sung a hymn, they went out to the Mount of Olives.",
                "Jesus said to them, \"All of you will be made to stumble because of me tonight, for it is written, \u2018I will strike the shepherd, and the sheep will be scattered.\u2019",
                "However, after I am raised up, I will go before you into Galilee.\"",
                "But Peter said to him, \"Although all will be offended, yet I will not.\"",
                "Jesus said to him, \"Most certainly I tell you, that you today, even this night, before the rooster crows twice, you will deny me three times.\"",
                "But he spoke all the more, \"If I must die with you, I will not deny you.\" They all said the same thing.",
                "They came to a place which was named Gethsemane. He said to his disciples, \"Sit here, while I pray.\"",
                "He took with him Peter, James, and John, and began to be greatly troubled and distressed.",
                "He said to them, \"My soul is exceedingly sorrowful, even to death. Stay here, and watch.\"",
                "He went forward a little, and fell on the ground, and prayed that, if it were possible, the hour might pass away from him.",
                "He said, \"Abba, Father, all things are possible to you. Please remove this cup from me. However, not what I desire, but what you desire.\"",
                "He came and found them sleeping, and said to Peter, \"Simon, are you sleeping? Couldn\u2019t you watch one hour?",
                "Watch and pray, that you may not enter into temptation. The spirit indeed is willing, but the flesh is weak.\"",
                "Again he went away, and prayed, saying the same words.",
                "Again he returned, and found them sleeping, for their eyes were very heavy, and they didn\u2019t know what to answer him.",
                "He came the third time, and said to them, \"Sleep on now, and take your rest. It is enough. The hour has come. Behold, the Son of Man is betrayed into the hands of sinners.",
                "Arise, let us be going. Behold, he who betrays me is at hand.\"",
                "Immediately, while he was still speaking, Judas, one of the twelve, came\u2014and with him a multitude with swords and clubs, from the chief priests, the scribes, and the elders.",
                "Now he who betrayed him had given them a sign, saying, \"Whomever I will kiss, that is he. Seize him, and lead him away safely.\"",
                "When he had come, immediately he came to him, and said, \"Rabbi! Rabbi!\" and kissed him.",
                "They laid their hands on him, and seized him.",
                "But a certain one of those who stood by drew his sword, and struck the servant of the high priest, and cut off his ear.",
                "Jesus answered them, \"Have you come out, as against a robber, with swords and clubs to seize me?",
                "I was daily with you in the temple teaching, and you didn\u2019t arrest me. But this is so that the Scriptures might be fulfilled.\"",
                "They all left him, and fled.",
                "A certain young man followed him, having a linen cloth thrown around himself, over his naked body. The young men grabbed him,",
                "but he left the linen cloth, and fled from them naked.",
                "They led Jesus away to the high priest. All the chief priests, the elders, and the scribes came together with him.",
                "Peter had followed him from a distance, until he came into the court of the high priest. He was sitting with the officers, and warming himself in the light of the fire.",
                "Now the chief priests and the whole council sought witnesses against Jesus to put him to death, and found none.",
                "For many gave false testimony against him, and their testimony didn\u2019t agree with each other.",
                "Some stood up, and gave false testimony against him, saying,",
                "\"We heard him say, \u2018I will destroy this temple that is made with hands, and in three days I will build another made without hands.\u2019\"",
                "Even so, their testimony did not agree.",
                "The high priest stood up in the midst, and asked Jesus, \"Have you no answer? What is it which these testify against you?\"",
                "But he stayed quiet, and answered nothing. Again the high priest asked him, \"Are you the Christ, the Son of the Blessed?\"",
                "Jesus said, \"I am. You will see the Son of Man sitting at the right hand of Power, and coming with the clouds of the sky.\"",
                "The high priest tore his clothes, and said, \"What further need have we of witnesses?",
                "You have heard the blasphemy! What do you think?\" They all condemned him to be worthy of death.",
                "Some began to spit on him, and to cover his face, and to beat him with fists, and to tell him, \"Prophesy!\" The officers struck him with the palms of their hands.",
                "As Peter was in the courtyard below, one of the maids of the high priest came,",
                "and seeing Peter warming himself, she looked at him, and said, \"You were also with the Nazarene, Jesus!\"",
                "But he denied it, saying, \"I neither know, nor understand what you are saying.\" He went out on the porch, and the rooster crowed.",
                "The maid saw him, and began again to tell those who stood by, \"This is one of them.\"",
                "But he again denied it. After a little while again those who stood by said to Peter, \"You truly are one of them, for you are a Galilean, and your speech shows it.\"",
                "But he began to curse, and to swear, \"I don\u2019t know this man of whom you speak!\"",
                "The rooster crowed the second time. Peter remembered the word, how that Jesus said to him, \"Before the rooster crows twice, you will deny me three times.\" When he thought about that, he wept."
            ],
            15: [
                "Immediately in the morning the chief priests, with the elders and scribes, and the whole council, held a consultation, and bound Jesus, and carried him away, and delivered him up to Pilate.",
                "Pilate asked him, \"Are you the King of the Jews?\" He answered, \"So you say.\"",
                "The chief priests accused him of many things.",
                "Pilate again asked him, \"Have you no answer? See how many things they testify against you!\"",
                "But Jesus made no further answer, so that Pilate marveled.",
                "Now at the feast he used to release to them one prisoner, whom they asked of him.",
                "There was one called Barabbas, bound with those who had made insurrection, men who in the insurrection had committed murder.",
                "The multitude, crying aloud, began to ask him to do as he always did for them.",
                "Pilate answered them, saying, \"Do you want me to release to you the King of the Jews?\"",
                "For he perceived that for envy the chief priests had delivered him up.",
                "But the chief priests stirred up the multitude, that he should release Barabbas to them instead.",
                "Pilate again asked them, \"What then should I do to him whom you call the King of the Jews?\"",
                "They cried out again, \"Crucify him!\"",
                "Pilate said to them, \"Why, what evil has he done?\" But they cried out exceedingly, \"Crucify him!\"",
                "Pilate, wishing to please the multitude, released Barabbas to them, and handed over Jesus, when he had flogged him, to be crucified.",
                "The soldiers led him away within the court, which is the Praetorium; and they called together the whole cohort.",
                "They clothed him with purple, and weaving a crown of thorns, they put it on him.",
                "They began to salute him, \"Hail, King of the Jews!\"",
                "They struck his head with a reed, and spat on him, and bowing their knees, did homage to him.",
                "When they had mocked him, they took the purple off of him, and put his own garments on him. They led him out to crucify him.",
                "They compelled one passing by, coming from the country, Simon of Cyrene, the father of Alexander and Rufus, to go with them, that he might bear his cross.",
                "They brought him to the place called Golgotha, which is, being interpreted, \"The place of a skull.\"",
                "They offered him wine mixed with myrrh to drink, but he didn\u2019t take it.",
                "Crucifying him, they parted his garments among them, casting lots on them, what each should take.",
                "It was the third hour, and they crucified him.",
                "The superscription of his accusation was written over him, \"THE KING OF THE JEWS.\"",
                "With him they crucified two robbers; one on his right hand, and one on his left.",
                "The Scripture was fulfilled, which says, \"He was numbered with transgressors.\"",
                "Those who passed by blasphemed him, wagging their heads, and saying, \"Ha! You who destroy the temple, and build it in three days,",
                "save yourself, and come down from the cross!\"",
                "Likewise, also the chief priests mocking among themselves with the scribes said, \"He saved others. He can\u2019t save himself.",
                "Let the Christ, the King of Israel, now come down from the cross, that we may see and believe him.\" Those who were crucified with him insulted him.",
                "When the sixth hour had come, there was darkness over the whole land until the ninth hour.",
                "At the ninth hour Jesus cried with a loud voice, saying, \"Eloi, Eloi, lama sabachthani?\" which is, being interpreted, \"My God, my God, why have you forsaken me?\"",
                "Some of those who stood by, when they heard it, said, \"Behold, he is calling Elijah.\"",
                "One ran, and filling a sponge full of vinegar, put it on a reed, and gave it to him to drink, saying, \"Let him be. Let\u2019s see whether Elijah comes to take him down.\"",
                "Jesus cried out with a loud voice, and gave up the spirit.",
                "The veil of the temple was torn in two from the top to the bottom.",
                "When the centurion, who stood by opposite him, saw that he cried out like this and breathed his last, he said, \"Truly this man was the Son of God!\"",
                "There were also women watching from afar, among whom were both Mary Magdalene, and Mary the mother of James the less and of Joses, and Salome;",
                "who, when he was in Galilee, followed him, and served him; and many other women who came up with him to Jerusalem.",
                "When evening had now come, because it was the Preparation Day, that is, the day before the Sabbath,",
                "Joseph of Arimathaea, a prominent council member who also himself was looking for the Kingdom of God, came. He boldly went in to Pilate, and asked for Jesus\u2019 body.",
                "Pilate marveled if he were already dead; and summoning the centurion, he asked him whether he had been dead long.",
                "When he found out from the centurion, he granted the body to Joseph.",
                "He bought a linen cloth, and taking him down, wound him in the linen cloth, and laid him in a tomb which had been cut out of a rock. He rolled a stone against the door of the tomb.",
                "Mary Magdalene and Mary, the mother of Joses, saw where he was laid."
            ],
            16: [
                "When the Sabbath was past, Mary Magdalene, and Mary the mother of James, and Salome, bought spices, that they might come and anoint him.",
                "Very early on the first day of the week, they came to the tomb when the sun had risen.",
                "They were saying among themselves, \"Who will roll away the stone from the door of the tomb for us?\"",
                "for it was very big. Looking up, they saw that the stone was rolled back.",
                "Entering into the tomb, they saw a young man sitting on the right side, dressed in a white robe, and they were amazed.",
                "He said to them, \"Don\u2019t be amazed. You seek Jesus, the Nazarene, who has been crucified. He has risen. He is not here. Behold, the place where they laid him!",
                "But go, tell his disciples and Peter, \u2018He goes before you into Galilee. There you will see him, as he said to you.\u2019\"",
                "They went out, and fled from the tomb, for trembling and astonishment had come on them. They said nothing to anyone; for they were afraid.",
                "Now when he had risen early on the first day of the week, he appeared first to Mary Magdalene, from whom he had cast out seven demons.",
                "She went and told those who had been with him, as they mourned and wept.",
                "When they heard that he was alive, and had been seen by her, they disbelieved.",
                "After these things he was revealed in another form to two of them, as they walked, on their way into the country.",
                "They went away and told it to the rest. They didn\u2019t believe them, either.",
                "Afterward he was revealed to the eleven themselves as they sat at the table, and he rebuked them for their unbelief and hardness of heart, because they didn\u2019t believe those who had seen him after he had risen.",
                "He said to them, \"Go into all the world, and preach the Good News to the whole creation.",
                "He who believes and is baptized will be saved; but he who disbelieves will be condemned.",
                "These signs will accompany those who believe: in my name they will cast out demons; they will speak with new languages;",
                "they will take up serpents; and if they drink any deadly thing, it will in no way hurt them; they will lay hands on the sick, and they will recover.\"",
                "So then the Lord, after he had spoken to them, was received up into heaven, and sat down at the right hand of God.",
                "They went out, and preached everywhere, the Lord working with them, and confirming the word by the signs that followed. Amen."
            ]
        },
        42: {
            1: [
                "Since many have undertaken to set in order a narrative concerning those matters which have been fulfilled among us,",
                "even as those who from the beginning were eyewitnesses and servants of the word delivered them to us,",
                "it seemed good to me also, having traced the course of all things accurately from the first, to write to you in order, most excellent Theophilus;",
                "that you might know the certainty concerning the things in which you were instructed.",
                "There was in the days of Herod, the king of Judea, a certain priest named Zacharias, of the priestly division of Abijah. He had a wife of the daughters of Aaron, and her name was Elizabeth.",
                "They were both righteous before God, walking blamelessly in all the commandments and ordinances of the Lord.",
                "But they had no child, because Elizabeth was barren, and they both were well advanced in years.",
                "Now it happened, while he executed the priest\u2019s office before God in the order of his division,",
                "according to the custom of the priest\u2019s office, his lot was to enter into the temple of the Lord and burn incense.",
                "The whole multitude of the people were praying outside at the hour of incense.",
                "An angel of the Lord appeared to him, standing on the right side of the altar of incense.",
                "Zacharias was troubled when he saw him, and fear fell upon him.",
                "But the angel said to him, \"Don\u2019t be afraid, Zacharias, because your request has been heard, and your wife, Elizabeth, will bear you a son, and you shall call his name John.",
                "You will have joy and gladness; and many will rejoice at his birth.",
                "For he will be great in the sight of the Lord, and he will drink no wine nor strong drink. He will be filled with the Holy Spirit, even from his mother\u2019s womb.",
                "He will turn many of the children of Israel to the Lord, their God.",
                "He will go before him in the spirit and power of Elijah, \u2018to turn the hearts of the fathers to the children,\u2019 and the disobedient to the wisdom of the just; to prepare a people prepared for the Lord.\"",
                "Zacharias said to the angel, \"How can I be sure of this? For I am an old man, and my wife is well advanced in years.\"",
                "The angel answered him, \"I am Gabriel, who stands in the presence of God. I was sent to speak to you, and to bring you this good news.",
                "Behold, you will be silent and not able to speak, until the day that these things will happen, because you didn\u2019t believe my words, which will be fulfilled in their proper time.\"",
                "The people were waiting for Zacharias, and they marveled that he delayed in the temple.",
                "When he came out, he could not speak to them, and they perceived that he had seen a vision in the temple. He continued making signs to them, and remained mute.",
                "It happened, when the days of his service were fulfilled, he departed to his house.",
                "After these days Elizabeth, his wife, conceived, and she hid herself five months, saying,",
                "\"Thus has the Lord done to me in the days in which he looked at me, to take away my reproach among men.\"",
                "Now in the sixth month, the angel Gabriel was sent from God to a city of Galilee, named Nazareth,",
                "to a virgin pledged to be married to a man whose name was Joseph, of the house of David. The virgin\u2019s name was Mary.",
                "Having come in, the angel said to her, \"Rejoice, you highly favored one! The Lord is with you. Blessed are you among women!\"",
                "But when she saw him, she was greatly troubled at the saying, and considered what kind of salutation this might be.",
                "The angel said to her, \"Don\u2019t be afraid, Mary, for you have found favor with God.",
                "Behold, you will conceive in your womb, and bring forth a son, and will call his name \u2018Jesus.\u2019",
                "He will be great, and will be called the Son of the Most High. The Lord God will give him the throne of his father, David,",
                "and he will reign over the house of Jacob forever. There will be no end to his Kingdom.\"",
                "Mary said to the angel, \"How can this be, seeing I am a virgin?\"",
                "The angel answered her, \"The Holy Spirit will come on you, and the power of the Most High will overshadow you. Therefore also the holy one who is born from you will be called the Son of God.",
                "Behold, Elizabeth, your relative, also has conceived a son in her old age; and this is the sixth month with her who was called barren.",
                "For everything spoken by God is possible.\"",
                "Mary said, \"Behold, the handmaid of the Lord; be it to me according to your word.\" The angel departed from her.",
                "Mary arose in those days and went into the hill country with haste, into a city of Judah,",
                "and entered into the house of Zacharias and greeted Elizabeth.",
                "It happened, when Elizabeth heard Mary\u2019s greeting, that the baby leaped in her womb, and Elizabeth was filled with the Holy Spirit.",
                "She called out with a loud voice, and said, \"Blessed are you among women, and blessed is the fruit of your womb!",
                "Why am I so favored, that the mother of my Lord should come to me?",
                "For behold, when the voice of your greeting came into my ears, the baby leaped in my womb for joy!",
                "Blessed is she who believed, for there will be a fulfillment of the things which have been spoken to her from the Lord!\"",
                "Mary said, \"My soul magnifies the Lord.",
                "My spirit has rejoiced in God my Savior,",
                "for he has looked at the humble state of his handmaid. For behold, from now on, all generations will call me blessed.",
                "For he who is mighty has done great things for me. Holy is his name.",
                "His mercy is for generations of generations on those who fear him.",
                "He has shown strength with his arm. He has scattered the proud in the imagination of their hearts.",
                "He has put down princes from their thrones. And has exalted the lowly.",
                "He has filled the hungry with good things. He has sent the rich away empty.",
                "He has given help to Israel, his servant, that he might remember mercy,",
                "As he spoke to our fathers, to Abraham and his seed forever.\"",
                "Mary stayed with her about three months, and then returned to her house.",
                "Now the time that Elizabeth should give birth was fulfilled, and she brought forth a son.",
                "Her neighbors and her relatives heard that the Lord had magnified his mercy towards her, and they rejoiced with her.",
                "It happened on the eighth day, that they came to circumcise the child; and they would have called him Zacharias, after the name of the father.",
                "His mother answered, \"Not so; but he will be called John.\"",
                "They said to her, \"There is no one among your relatives who is called by this name.\"",
                "They made signs to his father, what he would have him called.",
                "He asked for a writing tablet, and wrote, \"His name is John.\" They all marveled.",
                "His mouth was opened immediately, and his tongue freed, and he spoke, blessing God.",
                "Fear came on all who lived around them, and all these sayings were talked about throughout all the hill country of Judea.",
                "All who heard them laid them up in their heart, saying, \"What then will this child be?\" The hand of the Lord was with him.",
                "His father, Zacharias, was filled with the Holy Spirit, and prophesied, saying,",
                "\"Blessed be the Lord, the God of Israel, for he has visited and worked redemption for his people;",
                "and has raised up a horn of salvation for us in the house of his servant David",
                "(as he spoke by the mouth of his holy prophets who have been from of old),",
                "salvation from our enemies, and from the hand of all who hate us;",
                "to show mercy towards our fathers, to remember his holy covenant,",
                "the oath which he spoke to Abraham, our father,",
                "to grant to us that we, being delivered out of the hand of our enemies, should serve him without fear,",
                "In holiness and righteousness before him all the days of our life.",
                "And you, child, will be called a prophet of the Most High, for you will go before the face of the Lord to prepare his ways,",
                "to give knowledge of salvation to his people by the remission of their sins,",
                "because of the tender mercy of our God, whereby the dawn from on high will visit us,",
                "to shine on those who sit in darkness and the shadow of death; to guide our feet into the way of peace.\"",
                "The child was growing, and becoming strong in spirit, and was in the desert until the day of his public appearance to Israel."
            ],
            2: [
                "Now it happened in those days, that a decree went out from Caesar Augustus that all the world should be enrolled.",
                "This was the first enrollment made when Quirinius was governor of Syria.",
                "All went to enroll themselves, everyone to his own city.",
                "Joseph also went up from Galilee, out of the city of Nazareth, into Judea, to the city of David, which is called Bethlehem, because he was of the house and family of David;",
                "to enroll himself with Mary, who was pledged to be married to him as wife, being pregnant.",
                "It happened, while they were there, that the day had come that she should give birth.",
                "She brought forth her firstborn son, and she wrapped him in bands of cloth, and laid him in a feeding trough, because there was no room for them in the inn.",
                "There were shepherds in the same country staying in the field, and keeping watch by night over their flock.",
                "Behold, an angel of the Lord stood by them, and the glory of the Lord shone around them, and they were terrified.",
                "The angel said to them, \"Don\u2019t be afraid, for behold, I bring you good news of great joy which will be to all the people.",
                "For there is born to you, this day, in the city of David, a Savior, who is Christ the Lord.",
                "This is the sign to you: you will find a baby wrapped in strips of cloth, lying in a feeding trough.\"",
                "Suddenly, there was with the angel a multitude of the heavenly army praising God, and saying,",
                "\"Glory to God in the highest, on earth peace, good will toward men.\"",
                "It happened, when the angels went away from them into the sky, that the shepherds said one to another, \"Let\u2019s go to Bethlehem, now, and see this thing that has happened, which the Lord has made known to us.\"",
                "They came with haste, and found both Mary and Joseph, and the baby was lying in the feeding trough.",
                "When they saw it, they publicized widely the saying which was spoken to them about this child.",
                "All who heard it wondered at the things which were spoken to them by the shepherds.",
                "But Mary kept all these sayings, pondering them in her heart.",
                "The shepherds returned, glorifying and praising God for all the things that they had heard and seen, just as it was told them.",
                "When eight days were fulfilled for the circumcision of the child, his name was called Jesus, which was given by the angel before he was conceived in the womb.",
                "When the days of their purification according to the law of Moses were fulfilled, they brought him up to Jerusalem, to present him to the Lord",
                "(as it is written in the law of the Lord, \"Every male who opens the womb shall be called holy to the Lord\"),",
                "and to offer a sacrifice according to that which is said in the law of the Lord, \"A pair of turtledoves, or two young pigeons.\"",
                "Behold, there was a man in Jerusalem whose name was Simeon. This man was righteous and devout, looking for the consolation of Israel, and the Holy Spirit was on him.",
                "It had been revealed to him by the Holy Spirit that he should not see death before he had seen the Lord\u2019s Christ.",
                "He came in the Spirit into the temple. When the parents brought in the child, Jesus, that they might do concerning him according to the custom of the law,",
                "then he received him into his arms, and blessed God, and said,",
                "\"Now you are releasing your servant, Master, according to your word, in peace;",
                "for my eyes have seen your salvation,",
                "which you have prepared before the face of all peoples;",
                "a light for revelation to the nations, and the glory of your people Israel.\"",
                "Joseph and his mother were marveling at the things which were spoken concerning him,",
                "and Simeon blessed them, and said to Mary, his mother, \"Behold, this child is set for the falling and the rising of many in Israel, and for a sign which is spoken against.",
                "Yes, a sword will pierce through your own soul, that the thoughts of many hearts may be revealed.\"",
                "There was one Anna, a prophetess, the daughter of Phanuel, of the tribe of Asher (she was of a great age, having lived with a husband seven years from her virginity,",
                "and she had been a widow for about eighty-four years), who didn\u2019t depart from the temple, worshipping with fastings and petitions night and day.",
                "Coming up at that very hour, she gave thanks to the Lord, and spoke of him to all those who were looking for redemption in Jerusalem.",
                "When they had accomplished all things that were according to the law of the Lord, they returned into Galilee, to their own city, Nazareth.",
                "The child was growing, and was becoming strong in spirit, being filled with wisdom, and the grace of God was upon him.",
                "His parents went every year to Jerusalem at the feast of the Passover.",
                "When he was twelve years old, they went up to Jerusalem according to the custom of the feast,",
                "and when they had fulfilled the days, as they were returning, the boy Jesus stayed behind in Jerusalem. Joseph and his mother didn\u2019t know it,",
                "but supposing him to be in the company, they went a day\u2019s journey, and they looked for him among their relatives and acquaintances.",
                "When they didn\u2019t find him, they returned to Jerusalem, looking for him.",
                "It happened after three days they found him in the temple, sitting in the midst of the teachers, both listening to them, and asking them questions.",
                "All who heard him were amazed at his understanding and his answers.",
                "When they saw him, they were astonished, and his mother said to him, \"Son, why have you treated us this way? Behold, your father and I were anxiously looking for you.\"",
                "He said to them, \"Why were you looking for me? Didn\u2019t you know that I must be in my Father\u2019s house?\"",
                "They didn\u2019t understand the saying which he spoke to them.",
                "And he went down with them, and came to Nazareth. He was subject to them, and his mother kept all these sayings in her heart.",
                "And Jesus increased in wisdom and stature, and in favor with God and men."
            ],
            3: [
                "Now in the fifteenth year of the reign of Tiberius Caesar, Pontius Pilate being governor of Judea, and Herod being tetrarch of Galilee, and his brother Philip tetrarch of the region of Ituraea and Trachonitis, and Lysanias tetrarch of Abilene,",
                "in the high priesthood of Annas and Caiaphas, the word of God came to John, the son of Zacharias, in the wilderness.",
                "He came into all the region around the Jordan, preaching the baptism of repentance for remission of sins.",
                "As it is written in the book of the words of Isaiah the prophet, \"The voice of one crying in the wilderness, \u2018Make ready the way of the Lord. Make his paths straight.",
                "Every valley will be filled. Every mountain and hill will be brought low. The crooked will become straight, and the rough ways smooth.",
                "All flesh will see God\u2019s salvation.\u2019\"",
                "He said therefore to the multitudes who went out to be baptized by him, \"You offspring of vipers, who warned you to flee from the wrath to come?",
                "Bring forth therefore fruits worthy of repentance, and don\u2019t begin to say among yourselves, \u2018We have Abraham for our father;\u2019 for I tell you that God is able to raise up children to Abraham from these stones!",
                "Even now the axe also lies at the root of the trees. Every tree therefore that doesn\u2019t bring forth good fruit is cut down, and thrown into the fire.\"",
                "The multitudes asked him, \"What then must we do?\"",
                "He answered them, \"He who has two coats, let him give to him who has none. He who has food, let him do likewise.\"",
                "Tax collectors also came to be baptized, and they said to him, \"Teacher, what must we do?\"",
                "He said to them, \"Collect no more than that which is appointed to you.\"",
                "Soldiers also asked him, saying, \"What about us? What must we do?\" He said to them, \"Extort from no one by violence, neither accuse anyone wrongfully. Be content with your wages.\"",
                "As the people were in expectation, and all men reasoned in their hearts concerning John, whether perhaps he was the Christ,",
                "John answered them all, \"I indeed baptize you with water, but he comes who is mightier than I, the latchet of whose sandals I am not worthy to loosen. He will baptize you in the Holy Spirit and fire,",
                "whose fan is in his hand, and he will thoroughly cleanse his threshing floor, and will gather the wheat into his barn; but he will burn up the chaff with unquenchable fire.\"",
                "Then with many other exhortations he preached good news to the people,",
                "but Herod the tetrarch, being reproved by him for Herodias, his brother\u2019s wife, and for all the evil things which Herod had done,",
                "added this also to them all, that he shut up John in prison.",
                "Now it happened, when all the people were baptized, Jesus also had been baptized, and was praying. The sky was opened,",
                "and the Holy Spirit descended in a bodily form as a dove on him; and a voice came out of the sky, saying \"You are my beloved Son. In you I am well pleased.\"",
                "Jesus himself, when he began to teach, was about thirty years old, being the son (as was supposed) of Joseph, the son of Heli,",
                "the son of Matthat, the son of Levi, the son of Melchi, the son of Jannai, the son of Joseph,",
                "the son of Mattathias, the son of Amos, the son of Nahum, the son of Esli, the son of Naggai,",
                "the son of Maath, the son of Mattathias, the son of Semein, the son of Joseph, the son of Judah,",
                "the son of Joanan, the son of Rhesa, the son of Zerubbabel, the son of Shealtiel, the son of Neri,",
                "the son of Melchi, the son of Addi, the son of Cosam, the son of Elmodam, the son of Er,",
                "the son of Jose, the son of Eliezer, the son of Jorim, the son of Matthat, the son of Levi,",
                "the son of Simeon, the son of Judah, the son of Joseph, the son of Jonan, the son of Eliakim,",
                "the son of Melea, the son of Menan, the son of Mattatha, the son of Nathan, the son of David,",
                "the son of Jesse, the son of Obed, the son of Boaz, the son of Salmon, the son of Nahshon,",
                "the son of Amminadab, the son of Aram, the son of Hezron, the son of Perez, the son of Judah,",
                "the son of Jacob, the son of Isaac, the son of Abraham, the son of Terah, the son of Nahor,",
                "the son of Serug, the son of Reu, the son of Peleg, the son of Eber, the son of Shelah,",
                "the son of Cainan, the son of Arphaxad, the son of Shem, the son of Noah, the son of Lamech,",
                "the son of Methuselah, the son of Enoch, the son of Jared, the son of Mahalaleel, the son of Cainan,",
                "the son of Enos, the son of Seth, the son of Adam, the son of God."
            ],
            4: [
                "Jesus, full of the Holy Spirit, returned from the Jordan, and was led by the Spirit into the wilderness",
                "for forty days, being tempted by the devil. He ate nothing in those days. Afterward, when they were completed, he was hungry.",
                "The devil said to him, \"If you are the Son of God, command this stone to become bread.\"",
                "Jesus answered him, saying, \"It is written, \u2018Man shall not live by bread alone, but by every word of God.\u2019\"",
                "The devil, leading him up on a high mountain, showed him all the kingdoms of the world in a moment of time.",
                "The devil said to him, \"I will give you all this authority, and their glory, for it has been delivered to me; and I give it to whomever I want.",
                "If you therefore will worship before me, it will all be yours.\"",
                "Jesus answered him, \"Get behind me Satan! For it is written, \u2018You shall worship the Lord your God, and you shall serve him only.\u2019\"",
                "He led him to Jerusalem, and set him on the pinnacle of the temple, and said to him, \"If you are the Son of God, cast yourself down from here,",
                "for it is written, \u2018He will put his angels in charge of you, to guard you;\u2019",
                "and, \u2018On their hands they will bear you up, lest perhaps you dash your foot against a stone.\u2019\"",
                "Jesus answering, said to him, \"It has been said, \u2018You shall not tempt the Lord your God.\u2019\"",
                "When the devil had completed every temptation, he departed from him until another time.",
                "Jesus returned in the power of the Spirit into Galilee, and news about him spread through all the surrounding area.",
                "He taught in their synagogues, being glorified by all.",
                "He came to Nazareth, where he had been brought up. He entered, as was his custom, into the synagogue on the Sabbath day, and stood up to read.",
                "The book of the prophet Isaiah was handed to him. He opened the book, and found the place where it was written,",
                "\"The Spirit of the Lord is on me, because he has anointed me to preach good news to the poor. He has sent me to heal the brokenhearted, to proclaim release to the captives, recovering of sight to the blind, to deliver those who are crushed,",
                "and to proclaim the acceptable year of the Lord.\"",
                "He closed the book, gave it back to the attendant, and sat down. The eyes of all in the synagogue were fastened on him.",
                "He began to tell them, \"Today, this Scripture has been fulfilled in your hearing.\"",
                "All testified about him, and wondered at the gracious words which proceeded out of his mouth, and they said, \"Isn\u2019t this Joseph\u2019s son?\"",
                "He said to them, \"Doubtless you will tell me this parable, \u2018Physician, heal yourself! Whatever we have heard done at Capernaum, do also here in your hometown.\u2019\"",
                "He said, \"Most certainly I tell you, no prophet is acceptable in his hometown.",
                "But truly I tell you, there were many widows in Israel in the days of Elijah, when the sky was shut up three years and six months, when a great famine came over all the land.",
                "Elijah was sent to none of them, except to Zarephath, in the land of Sidon, to a woman who was a widow.",
                "There were many lepers in Israel in the time of Elisha the prophet, yet not one of them was cleansed, except Naaman, the Syrian.\"",
                "They were all filled with wrath in the synagogue, as they heard these things.",
                "They rose up, threw him out of the city, and led him to the brow of the hill that their city was built on, that they might throw him off the cliff.",
                "But he, passing through their midst, went his way.",
                "He came down to Capernaum, a city of Galilee. He was teaching them on the Sabbath day,",
                "and they were astonished at his teaching, for his word was with authority.",
                "In the synagogue there was a man who had a spirit of an unclean demon, and he cried out with a loud voice,",
                "saying, \"Ah! what have we to do with you, Jesus of Nazareth? Have you come to destroy us? I know you who you are: the Holy One of God!\"",
                "Jesus rebuked him, saying, \"Be silent, and come out of him!\" When the demon had thrown him down in their midst, he came out of him, having done him no harm.",
                "Amazement came on all, and they spoke together, one with another, saying, \"What is this word? For with authority and power he commands the unclean spirits, and they come out!\"",
                "News about him went out into every place of the surrounding region.",
                "He rose up from the synagogue, and entered into Simon\u2019s house. Simon\u2019s mother-in-law was afflicted with a great fever, and they begged him for her.",
                "He stood over her, and rebuked the fever; and it left her. Immediately she rose up and served them.",
                "When the sun was setting, all those who had any sick with various diseases brought them to him; and he laid his hands on every one of them, and healed them.",
                "Demons also came out from many, crying out, and saying, \"You are the Christ, the Son of God!\" Rebuking them, he didn\u2019t allow them to speak, because they knew that he was the Christ.",
                "When it was day, he departed and went into an uninhabited place, and the multitudes looked for him, and came to him, and held on to him, so that he wouldn\u2019t go away from them.",
                "But he said to them, \"I must preach the good news of the Kingdom of God to the other cities also. For this reason I have been sent.\"",
                "He was preaching in the synagogues of Galilee."
            ],
            5: [
                "Now it happened, while the multitude pressed on him and heard the word of God, that he was standing by the lake of Gennesaret.",
                "He saw two boats standing by the lake, but the fishermen had gone out of them, and were washing their nets.",
                "He entered into one of the boats, which was Simon\u2019s, and asked him to put out a little from the land. He sat down and taught the multitudes from the boat.",
                "When he had finished speaking, he said to Simon, \"Put out into the deep, and let down your nets for a catch.\"",
                "Simon answered him, \"Master, we worked all night, and took nothing; but at your word I will let down the net.\"",
                "When they had done this, they caught a great multitude of fish, and their net was breaking.",
                "They beckoned to their partners in the other boat, that they should come and help them. They came, and filled both boats, so that they began to sink.",
                "But Simon Peter, when he saw it, fell down at Jesus\u2019 knees, saying, \"Depart from me, for I am a sinful man, Lord.\"",
                "For he was amazed, and all who were with him, at the catch of fish which they had caught;",
                "and so also were James and John, sons of Zebedee, who were partners with Simon. Jesus said to Simon, \"Don\u2019t be afraid. From now on you will be catching people alive.\"",
                "When they had brought their boats to land, they left everything, and followed him.",
                "It happened, while he was in one of the cities, behold, there was a man full of leprosy. When he saw Jesus, he fell on his face, and begged him, saying, \"Lord, if you want to, you can make me clean.\"",
                "He stretched out his hand, and touched him, saying, \"I want to. Be made clean.\" Immediately the leprosy left him.",
                "He commanded him to tell no one, \"But go your way, and show yourself to the priest, and offer for your cleansing according to what Moses commanded, for a testimony to them.\"",
                "But the report concerning him spread much more, and great multitudes came together to hear, and to be healed by him of their infirmities.",
                "But he withdrew himself into the desert, and prayed.",
                "It happened on one of those days, that he was teaching; and there were Pharisees and teachers of the law sitting by, who had come out of every village of Galilee, Judea, and Jerusalem. The power of the Lord was with him to heal them.",
                "Behold, men brought a paralyzed man on a cot, and they sought to bring him in to lay before Jesus.",
                "Not finding a way to bring him in because of the multitude, they went up to the housetop, and let him down through the tiles with his cot into the midst before Jesus.",
                "Seeing their faith, he said to him, \"Man, your sins are forgiven you.\"",
                "The scribes and the Pharisees began to reason, saying, \"Who is this that speaks blasphemies? Who can forgive sins, but God alone?\"",
                "But Jesus, perceiving their thoughts, answered them, \"Why are you reasoning so in your hearts?",
                "Which is easier to say, \u2018Your sins are forgiven you;\u2019 or to say, \u2018Arise and walk?\u2019",
                "But that you may know that the Son of Man has authority on earth to forgive sins\" (he said to the paralyzed man), \"I tell you, arise, and take up your cot, and go to your house.\"",
                "Immediately he rose up before them, and took up that which he was laying on, and departed to his house, glorifying God.",
                "Amazement took hold on all, and they glorified God. They were filled with fear, saying, \"We have seen strange things today.\"",
                "After these things he went out, and saw a tax collector named Levi sitting at the tax office, and said to him, \"Follow me!\"",
                "He left everything, and rose up and followed him.",
                "Levi made a great feast for him in his house. There was a great crowd of tax collectors and others who were reclining with them.",
                "Their scribes and the Pharisees murmured against his disciples, saying, \"Why do you eat and drink with the tax collectors and sinners?\"",
                "Jesus answered them, \"Those who are healthy have no need for a physician, but those who are sick do.",
                "I have not come to call the righteous, but sinners to repentance.\"",
                "They said to him, \"Why do John\u2019s disciples often fast and pray, likewise also the disciples of the Pharisees, but yours eat and drink?\"",
                "He said to them, \"Can you make the friends of the bridegroom fast, while the bridegroom is with them?",
                "But the days will come when the bridegroom will be taken away from them. Then they will fast in those days.\"",
                "He also told a parable to them. \"No one puts a piece from a new garment on an old garment, or else he will tear the new, and also the piece from the new will not match the old.",
                "No one puts new wine into old wineskins, or else the new wine will burst the skins, and it will be spilled, and the skins will be destroyed.",
                "But new wine must be put into fresh wineskins, and both are preserved.",
                "No man having drunk old wine immediately desires new, for he says, \u2018The old is better.\u2019\""
            ],
            6: [
                "Now it happened on the second Sabbath after the first, that he was going through the grain fields. His disciples plucked the heads of grain, and ate, rubbing them in their hands.",
                "But some of the Pharisees said to them, \"Why do you do that which is not lawful to do on the Sabbath day?\"",
                "Jesus, answering them, said, \"Haven\u2019t you read what David did when he was hungry, he, and those who were with him;",
                "how he entered into the house of God, and took and ate the show bread, and gave also to those who were with him, which is not lawful to eat except for the priests alone?\"",
                "He said to them, \"The Son of Man is lord of the Sabbath.\"",
                "It also happened on another Sabbath that he entered into the synagogue and taught. There was a man there, and his right hand was withered.",
                "The scribes and the Pharisees watched him, to see whether he would heal on the Sabbath, that they might find an accusation against him.",
                "But he knew their thoughts; and he said to the man who had the withered hand, \"Rise up, and stand in the middle.\" He arose and stood.",
                "Then Jesus said to them, \"I will ask you something: Is it lawful on the Sabbath to do good, or to do harm? To save a life, or to kill?\"",
                "He looked around at them all, and said to the man, \"Stretch out your hand.\" He did, and his hand was restored as sound as the other.",
                "But they were filled with rage, and talked with one another about what they might do to Jesus.",
                "It happened in these days, that he went out to the mountain to pray, and he continued all night in prayer to God.",
                "When it was day, he called his disciples, and from them he chose twelve, whom he also named apostles:",
                "Simon, whom he also named Peter; Andrew, his brother; James; John; Philip; Bartholomew;",
                "Matthew; Thomas; James, the son of Alphaeus; Simon, who was called the Zealot;",
                "Judas the son of James; and Judas Iscariot, who also became a traitor.",
                "He came down with them, and stood on a level place, with a crowd of his disciples, and a great number of the people from all Judea and Jerusalem, and the sea coast of Tyre and Sidon, who came to hear him and to be healed of their diseases;",
                "as well as those who were troubled by unclean spirits, and they were being healed.",
                "All the multitude sought to touch him, for power came out from him and healed them all.",
                "He lifted up his eyes to his disciples, and said, \"Blessed are you who are poor, for yours is the Kingdom of God.",
                "Blessed are you who hunger now, for you will be filled. Blessed are you who weep now, for you will laugh.",
                "Blessed are you when men shall hate you, and when they shall exclude and mock you, and throw out your name as evil, for the Son of Man\u2019s sake.",
                "Rejoice in that day, and leap for joy, for behold, your reward is great in heaven, for their fathers did the same thing to the prophets.",
                "\"But woe to you who are rich! For you have received your consolation.",
                "Woe to you, you who are full now, for you will be hungry. Woe to you who laugh now, for you will mourn and weep.",
                "Woe, when men speak well of you, for their fathers did the same thing to the false prophets.",
                "\"But I tell you who hear: love your enemies, do good to those who hate you,",
                "bless those who curse you, and pray for those who mistreat you.",
                "To him who strikes you on the cheek, offer also the other; and from him who takes away your cloak, don\u2019t withhold your coat also.",
                "Give to everyone who asks you, and don\u2019t ask him who takes away your goods to give them back again.",
                "\"As you would like people to do to you, do exactly so to them.",
                "If you love those who love you, what credit is that to you? For even sinners love those who love them.",
                "If you do good to those who do good to you, what credit is that to you? For even sinners do the same.",
                "If you lend to those from whom you hope to receive, what credit is that to you? Even sinners lend to sinners, to receive back as much.",
                "But love your enemies, and do good, and lend, expecting nothing back; and your reward will be great, and you will be children of the Most High; for he is kind toward the unthankful and evil.",
                "Therefore be merciful, even as your Father is also merciful.",
                "Don\u2019t judge, and you won\u2019t be judged. Don\u2019t condemn, and you won\u2019t be condemned. Set free, and you will be set free.",
                "\"Give, and it will be given to you: good measure, pressed down, shaken together, and running over, will be given to you. For with the same measure you measure it will be measured back to you.\"",
                "He spoke a parable to them. \"Can the blind guide the blind? Won\u2019t they both fall into a pit?",
                "A disciple is not above his teacher, but everyone when he is fully trained will be like his teacher.",
                "Why do you see the speck of chaff that is in your brother\u2019s eye, but don\u2019t consider the beam that is in your own eye?",
                "Or how can you tell your brother, \u2018Brother, let me remove the speck of chaff that is in your eye,\u2019 when you yourself don\u2019t see the beam that is in your own eye? You hypocrite! First remove the beam from your own eye, and then you can see clearly to remove the speck of chaff that is in your brother\u2019s eye.",
                "For there is no good tree that brings forth rotten fruit; nor again a rotten tree that brings forth good fruit.",
                "For each tree is known by its own fruit. For people don\u2019t gather figs from thorns, nor do they gather grapes from a bramble bush.",
                "The good man out of the good treasure of his heart brings out that which is good, and the evil man out of the evil treasure of his heart brings out that which is evil, for out of the abundance of the heart, his mouth speaks.",
                "\"Why do you call me, \u2018Lord, Lord,\u2019 and don\u2019t do the things which I say?",
                "Everyone who comes to me, and hears my words, and does them, I will show you who he is like.",
                "He is like a man building a house, who dug and went deep, and laid a foundation on the rock. When a flood arose, the stream broke against that house, and could not shake it, because it was founded on the rock.",
                "But he who hears, and doesn\u2019t do, is like a man who built a house on the earth without a foundation, against which the stream broke, and immediately it fell, and the ruin of that house was great.\""
            ],
            7: [
                "After he had finished speaking in the hearing of the people, he entered into Capernaum.",
                "A certain centurion\u2019s servant, who was dear to him, was sick and at the point of death.",
                "When he heard about Jesus, he sent to him elders of the Jews, asking him to come and save his servant.",
                "When they came to Jesus, they begged him earnestly, saying, \"He is worthy for you to do this for him,",
                "for he loves our nation, and he built our synagogue for us.\"",
                "Jesus went with them. When he was now not far from the house, the centurion sent friends to him, saying to him, \"Lord, don\u2019t trouble yourself, for I am not worthy for you to come under my roof.",
                "Therefore I didn\u2019t even think myself worthy to come to you; but say the word, and my servant will be healed.",
                "For I also am a man placed under authority, having under myself soldiers. I tell this one, \u2018Go!\u2019 and he goes; and to another, \u2018Come!\u2019 and he comes; and to my servant, \u2018Do this,\u2019 and he does it.\"",
                "When Jesus heard these things, he marveled at him, and turned and said to the multitude who followed him, \"I tell you, I have not found such great faith, no, not in Israel.\"",
                "Those who were sent, returning to the house, found that the servant who had been sick was well.",
                "It happened soon afterwards, that he went to a city called Nain. Many of his disciples, along with a great multitude, went with him.",
                "Now when he drew near to the gate of the city, behold, one who was dead was carried out, the only son of his mother, and she was a widow. Many people of the city were with her.",
                "When the Lord saw her, he had compassion on her, and said to her, \"Don\u2019t cry.\"",
                "He came near and touched the coffin, and the bearers stood still. He said, \"Young man, I tell you, arise!\"",
                "He who was dead sat up, and began to speak. And he gave him to his mother.",
                "Fear took hold of all, and they glorified God, saying, \"A great prophet has arisen among us!\" and, \"God has visited his people!\"",
                "This report went out concerning him in the whole of Judea, and in all the surrounding region.",
                "The disciples of John told him about all these things.",
                "John, calling to himself two of his disciples, sent them to Jesus, saying, \"Are you the one who is coming, or should we look for another?\"",
                "When the men had come to him, they said, \"John the Baptizer has sent us to you, saying, \u2018Are you he who comes, or should we look for another?\u2019\"",
                "In that hour he cured many of diseases and plagues and evil spirits; and to many who were blind he gave sight.",
                "Jesus answered them, \"Go and tell John the things which you have seen and heard: that the blind receive their sight, the lame walk, the lepers are cleansed, the deaf hear, the dead are raised up, and the poor have good news preached to them.",
                "Blessed is he who finds no occasion for stumbling in me.\"",
                "When John\u2019s messengers had departed, he began to tell the multitudes about John, \"What did you go out into the wilderness to see? A reed shaken by the wind?",
                "But what did you go out to see? A man clothed in soft clothing? Behold, those who are gorgeously dressed, and live delicately, are in kings\u2019 courts.",
                "But what did you go out to see? A prophet? Yes, I tell you, and much more than a prophet.",
                "This is he of whom it is written, \u2018Behold, I send my messenger before your face, who will prepare your way before you.\u2019",
                "\"For I tell you, among those who are born of women there is not a greater prophet than John the Baptizer, yet he who is least in the Kingdom of God is greater than he.\"",
                "When all the people and the tax collectors heard this, they declared God to be just, having been baptized with John\u2019s baptism.",
                "But the Pharisees and the lawyers rejected the counsel of God, not being baptized by him themselves.",
                "\"To what then will I liken the people of this generation? What are they like?",
                "They are like children who sit in the marketplace, and call one to another, saying, \u2018We piped to you, and you didn\u2019t dance. We mourned, and you didn\u2019t weep.\u2019",
                "For John the Baptizer came neither eating bread nor drinking wine, and you say, \u2018He has a demon.\u2019",
                "The Son of Man has come eating and drinking, and you say, \u2018Behold, a gluttonous man, and a drunkard; a friend of tax collectors and sinners!\u2019",
                "Wisdom is justified by all her children.\"",
                "One of the Pharisees invited him to eat with him. He entered into the Pharisee\u2019s house, and sat at the table.",
                "Behold, a woman in the city who was a sinner, when she knew that he was reclining in the Pharisee\u2019s house, she brought an alabaster jar of ointment.",
                "Standing behind at his feet weeping, she began to wet his feet with her tears, and she wiped them with the hair of her head, kissed his feet, and anointed them with the ointment.",
                "Now when the Pharisee who had invited him saw it, he said to himself, \"This man, if he were a prophet, would have perceived who and what kind of woman this is who touches him, that she is a sinner.\"",
                "Jesus answered him, \"Simon, I have something to tell you.\" He said, \"Teacher, say on.\"",
                "\"A certain lender had two debtors. The one owed five hundred denarii, and the other fifty.",
                "When they couldn\u2019t pay, he forgave them both. Which of them therefore will love him most?\"",
                "Simon answered, \"He, I suppose, to whom he forgave the most.\" He said to him, \"You have judged correctly.\"",
                "Turning to the woman, he said to Simon, \"Do you see this woman? I entered into your house, and you gave me no water for my feet, but she has wet my feet with her tears, and wiped them with the hair of her head.",
                "You gave me no kiss, but she, since the time I came in, has not ceased to kiss my feet.",
                "You didn\u2019t anoint my head with oil, but she has anointed my feet with ointment.",
                "Therefore I tell you, her sins, which are many, are forgiven, for she loved much. But to whom little is forgiven, the same loves little.\"",
                "He said to her, \"Your sins are forgiven.\"",
                "Those who sat at the table with him began to say to themselves, \"Who is this who even forgives sins?\"",
                "He said to the woman, \"Your faith has saved you. Go in peace.\""
            ],
            8: [
                "It happened soon afterwards, that he went about through cities and villages, preaching and bringing the good news of the Kingdom of God. With him were the twelve,",
                "and certain women who had been healed of evil spirits and infirmities: Mary who was called Magdalene, from whom seven demons had gone out;",
                "and Joanna, the wife of Chuzas, Herod\u2019s steward; Susanna; and many others; who served them from their possessions.",
                "When a great multitude came together, and people from every city were coming to him, he spoke by a parable.",
                "\"The farmer went out to sow his seed. As he sowed, some fell along the road, and it was trampled under foot, and the birds of the sky devoured it.",
                "Other seed fell on the rock, and as soon as it grew, it withered away, because it had no moisture.",
                "Other fell amid the thorns, and the thorns grew with it, and choked it.",
                "Other fell into the good ground, and grew, and brought forth fruit one hundred times.\" As he said these things, he called out, \"He who has ears to hear, let him hear!\"",
                "Then his disciples asked him, \"What does this parable mean?\"",
                "He said, \"To you it is given to know the mysteries of the Kingdom of God, but to the rest in parables; that \u2018seeing they may not see, and hearing they may not understand.\u2019",
                "Now the parable is this: The seed is the word of God.",
                "Those along the road are those who hear, then the devil comes, and takes away the word from their heart, that they may not believe and be saved.",
                "Those on the rock are they who, when they hear, receive the word with joy; but these have no root, who believe for a while, then fall away in time of temptation.",
                "That which fell among the thorns, these are those who have heard, and as they go on their way they are choked with cares, riches, and pleasures of life, and bring no fruit to maturity.",
                "That in the good ground, these are such as in an honest and good heart, having heard the word, hold it tightly, and bring forth fruit with patience.",
                "\"No one, when he has lit a lamp, covers it with a container, or puts it under a bed; but puts it on a stand, that those who enter in may see the light.",
                "For nothing is hidden, that will not be revealed; nor anything secret, that will not be known and come to light.",
                "Be careful therefore how you hear. For whoever has, to him will be given; and whoever doesn\u2019t have, from him will be taken away even that which he thinks he has.\"",
                "His mother and brothers came to him, and they could not come near him for the crowd.",
                "It was told him by some saying, \"Your mother and your brothers stand outside, desiring to see you.\"",
                "But he answered them, \"My mother and my brothers are these who hear the word of God, and do it.\"",
                "Now it happened on one of those days, that he entered into a boat, himself and his disciples, and he said to them, \"Let\u2019s go over to the other side of the lake.\" So they launched out.",
                "But as they sailed, he fell asleep. A wind storm came down on the lake, and they were taking on dangerous amounts of water.",
                "They came to him, and awoke him, saying, \"Master, master, we are dying!\" He awoke, and rebuked the wind and the raging of the water, and they ceased, and it was calm.",
                "He said to them, \"Where is your faith?\" Being afraid they marveled, saying one to another, \"Who is this, then, that he commands even the winds and the water, and they obey him?\"",
                "They arrived at the country of the Gadarenes, which is opposite Galilee.",
                "When Jesus stepped ashore, a certain man out of the city who had demons for a long time met him. He wore no clothes, and didn\u2019t live in a house, but in the tombs.",
                "When he saw Jesus, he cried out, and fell down before him, and with a loud voice said, \"What do I have to do with you, Jesus, you Son of the Most High God? I beg you, don\u2019t torment me!\"",
                "For Jesus was commanding the unclean spirit to come out of the man. For the unclean spirit had often seized the man. He was kept under guard, and bound with chains and fetters. Breaking the bands apart, he was driven by the demon into the desert.",
                "Jesus asked him, \"What is your name?\" He said, \"Legion,\" for many demons had entered into him.",
                "They begged him that he would not command them to go into the abyss.",
                "Now there was there a herd of many pigs feeding on the mountain, and they begged him that he would allow them to enter into those. He allowed them.",
                "The demons came out from the man, and entered into the pigs, and the herd rushed down the steep bank into the lake, and were drowned.",
                "When those who fed them saw what had happened, they fled, and told it in the city and in the country.",
                "People went out to see what had happened. They came to Jesus, and found the man from whom the demons had gone out, sitting at Jesus\u2019 feet, clothed and in his right mind; and they were afraid.",
                "Those who saw it told them how he who had been possessed by demons was healed.",
                "All the people of the surrounding country of the Gadarenes asked him to depart from them, for they were very much afraid. He entered into the boat, and returned.",
                "But the man from whom the demons had gone out begged him that he might go with him, but Jesus sent him away, saying,",
                "\"Return to your house, and declare what great things God has done for you.\" He went his way, proclaiming throughout the whole city what great things Jesus had done for him.",
                "It happened, when Jesus returned, that the multitude welcomed him, for they were all waiting for him.",
                "Behold, there came a man named Jairus, and he was a ruler of the synagogue. He fell down at Jesus\u2019 feet, and begged him to come into his house,",
                "for he had an only daughter, about twelve years of age, and she was dying. But as he went, the multitudes pressed against him.",
                "A woman who had a flow of blood for twelve years, who had spent all her living on physicians, and could not be healed by any,",
                "came behind him, and touched the fringe of his cloak, and immediately the flow of her blood stopped.",
                "Jesus said, \"Who touched me?\" When all denied it, Peter and those with him said, \"Master, the multitudes press and jostle you, and you say, \u2018Who touched me?\u2019\"",
                "But Jesus said, \"Someone did touch me, for I perceived that power has gone out of me.\"",
                "When the woman saw that she was not hidden, she came trembling, and falling down before him declared to him in the presence of all the people the reason why she had touched him, and how she was healed immediately.",
                "He said to her, \"Daughter, cheer up. Your faith has made you well. Go in peace.\"",
                "While he still spoke, one from the ruler of the synagogue\u2019s house came, saying to him, \"Your daughter is dead. Don\u2019t trouble the Teacher.\"",
                "But Jesus hearing it, answered him, \"Don\u2019t be afraid. Only believe, and she will be healed.\"",
                "When he came to the house, he didn\u2019t allow anyone to enter in, except Peter, John, James, the father of the child, and her mother.",
                "All were weeping and mourning her, but he said, \"Don\u2019t weep. She isn\u2019t dead, but sleeping.\"",
                "They were ridiculing him, knowing that she was dead.",
                "But he put them all outside, and taking her by the hand, he called, saying, \"Child, arise!\"",
                "Her spirit returned, and she rose up immediately. He commanded that something be given to her to eat.",
                "Her parents were amazed, but he commanded them to tell no one what had been done."
            ],
            9: [
                "He called the twelve together, and gave them power and authority over all demons, and to cure diseases.",
                "He sent them forth to preach the Kingdom of God, and to heal the sick.",
                "He said to them, \"Take nothing for your journey\u2014neither staffs, nor wallet, nor bread, nor money; neither have two coats apiece.",
                "Into whatever house you enter, stay there, and depart from there.",
                "As many as don\u2019t receive you, when you depart from that city, shake off even the dust from your feet for a testimony against them.\"",
                "They departed, and went throughout the villages, preaching the Good News, and healing everywhere.",
                "Now Herod the tetrarch heard of all that was done by him; and he was very perplexed, because it was said by some that John had risen from the dead,",
                "and by some that Elijah had appeared, and by others that one of the old prophets had risen again.",
                "Herod said, \"John I beheaded, but who is this, about whom I hear such things?\" He sought to see him.",
                "The apostles, when they had returned, told him what things they had done. He took them, and withdrew apart to a deserted place of a city called Bethsaida.",
                "But the multitudes, perceiving it, followed him. He welcomed them, and spoke to them of the Kingdom of God, and he cured those who needed healing.",
                "The day began to wear away; and the twelve came, and said to him, \"Send the multitude away, that they may go into the surrounding villages and farms, and lodge, and get food, for we are here in a deserted place.\"",
                "But he said to them, \"You give them something to eat.\" They said, \"We have no more than five loaves and two fish, unless we should go and buy food for all these people.\"",
                "For they were about five thousand men. He said to his disciples, \"Make them sit down in groups of about fifty each.\"",
                "They did so, and made them all sit down.",
                "He took the five loaves and the two fish, and looking up to the sky, he blessed them, and broke them, and gave them to the disciples to set before the multitude.",
                "They ate, and were all filled. They gathered up twelve baskets of broken pieces that were left over.",
                "It happened, as he was praying alone, that the disciples were with him, and he asked them, \"Who do the multitudes say that I am?\"",
                "They answered, \"\u2018John the Baptizer,\u2019 but others say, \u2018Elijah,\u2019 and others, that one of the old prophets is risen again.\"",
                "He said to them, \"But who do you say that I am?\" Peter answered, \"The Christ of God.\"",
                "But he warned them, and commanded them to tell this to no one,",
                "saying, \"The Son of Man must suffer many things, and be rejected by the elders, chief priests, and scribes, and be killed, and the third day be raised up.\"",
                "He said to all, \"If anyone desires to come after me, let him deny himself, take up his cross, and follow me.",
                "For whoever desires to save his life will lose it, but whoever will lose his life for my sake, the same will save it.",
                "For what does it profit a man if he gains the whole world, and loses or forfeits his own self?",
                "For whoever will be ashamed of me and of my words, of him will the Son of Man be ashamed, when he comes in his glory, and the glory of the Father, and of the holy angels.",
                "But I tell you the truth: There are some of those who stand here, who will in no way taste of death, until they see the Kingdom of God.\"",
                "It happened about eight days after these sayings, that he took with him Peter, John, and James, and went up onto the mountain to pray.",
                "As he was praying, the appearance of his face was altered, and his clothing became white and dazzling.",
                "Behold, two men were talking with him, who were Moses and Elijah,",
                "who appeared in glory, and spoke of his departure, which he was about to accomplish at Jerusalem.",
                "Now Peter and those who were with him were heavy with sleep, but when they were fully awake, they saw his glory, and the two men who stood with him.",
                "It happened, as they were parting from him, that Peter said to Jesus, \"Master, it is good for us to be here. Let\u2019s make three tents: one for you, and one for Moses, and one for Elijah,\" not knowing what he said.",
                "While he said these things, a cloud came and overshadowed them, and they were afraid as they entered into the cloud.",
                "A voice came out of the cloud, saying, \"This is my beloved Son. Listen to him!\"",
                "When the voice came, Jesus was found alone. They were silent, and told no one in those days any of the things which they had seen.",
                "It happened on the next day, when they had come down from the mountain, that a great multitude met him.",
                "Behold, a man from the crowd called out, saying, \"Teacher, I beg you to look at my son, for he is my only child.",
                "Behold, a spirit takes him, he suddenly cries out, and it convulses him so that he foams, and it hardly departs from him, bruising him severely.",
                "I begged your disciples to cast it out, and they couldn\u2019t.\"",
                "Jesus answered, \"Faithless and perverse generation, how long shall I be with you and bear with you? Bring your son here.\"",
                "While he was still coming, the demon threw him down and convulsed him violently. But Jesus rebuked the unclean spirit, and healed the boy, and gave him back to his father.",
                "They were all astonished at the majesty of God. But while all were marveling at all the things which Jesus did, he said to his disciples,",
                "\"Let these words sink into your ears, for the Son of Man will be delivered up into the hands of men.\"",
                "But they didn\u2019t understand this saying. It was concealed from them, that they should not perceive it, and they were afraid to ask him about this saying.",
                "There arose an argument among them about which of them was the greatest.",
                "Jesus, perceiving the reasoning of their hearts, took a little child, and set him by his side,",
                "and said to them, \"Whoever receives this little child in my name receives me. Whoever receives me receives him who sent me. For whoever is least among you all, this one will be great.\"",
                "John answered, \"Master, we saw someone casting out demons in your name, and we forbade him, because he doesn\u2019t follow with us.\"",
                "Jesus said to him, \"Don\u2019t forbid him, for he who is not against us is for us.\"",
                "It came to pass, when the days were near that he should be taken up, he intently set his face to go to Jerusalem,",
                "and sent messengers before his face. They went, and entered into a village of the Samaritans, so as to prepare for him.",
                "They didn\u2019t receive him, because he was traveling with his face set towards Jerusalem.",
                "When his disciples, James and John, saw this, they said, \"Lord, do you want us to command fire to come down from the sky, and destroy them, just as Elijah did?\"",
                "But he turned and rebuked them, \"You don\u2019t know of what kind of spirit you are.",
                "For the Son of Man didn\u2019t come to destroy men\u2019s lives, but to save them.\" They went to another village.",
                "As they went on the way, a certain man said to him, \"I want to follow you wherever you go, Lord.\"",
                "Jesus said to him, \"The foxes have holes, and the birds of the sky have nests, but the Son of Man has no place to lay his head.\"",
                "He said to another, \"Follow me!\" But he said, \"Lord, allow me first to go and bury my father.\"",
                "But Jesus said to him, \"Leave the dead to bury their own dead, but you go and announce the Kingdom of God.\"",
                "Another also said, \"I want to follow you, Lord, but first allow me to say good-bye to those who are at my house.\"",
                "But Jesus said to him, \"No one, having put his hand to the plow, and looking back, is fit for the Kingdom of God.\""
            ],
            10: [
                "Now after these things, the Lord also appointed seventy others, and sent them two by two ahead of him into every city and place, where he was about to come.",
                "Then he said to them, \"The harvest is indeed plentiful, but the laborers are few. Pray therefore to the Lord of the harvest, that he may send out laborers into his harvest.",
                "Go your ways. Behold, I send you out as lambs among wolves.",
                "Carry no purse, nor wallet, nor sandals. Greet no one on the way.",
                "Into whatever house you enter, first say, \u2018Peace be to this house.\u2019",
                "If a son of peace is there, your peace will rest on him; but if not, it will return to you.",
                "Remain in that same house, eating and drinking the things they give, for the laborer is worthy of his wages. Don\u2019t go from house to house.",
                "Into whatever city you enter, and they receive you, eat the things that are set before you.",
                "Heal the sick who are therein, and tell them, \u2018The Kingdom of God has come near to you.\u2019",
                "But into whatever city you enter, and they don\u2019t receive you, go out into its streets and say,",
                "\u2018Even the dust from your city that clings to us, we wipe off against you. Nevertheless know this, that the Kingdom of God has come near to you.\u2019",
                "I tell you, it will be more tolerable in that day for Sodom than for that city.",
                "\"Woe to you, Chorazin! Woe to you, Bethsaida! For if the mighty works had been done in Tyre and Sidon which were done in you, they would have repented long ago, sitting in sackcloth and ashes.",
                "But it will be more tolerable for Tyre and Sidon in the judgment than for you.",
                "You, Capernaum, who are exalted to heaven, will be brought down to Hades.",
                "Whoever listens to you listens to me, and whoever rejects you rejects me. Whoever rejects me rejects him who sent me.\"",
                "The seventy returned with joy, saying, \"Lord, even the demons are subject to us in your name!\"",
                "He said to them, \"I saw Satan having fallen like lightning from heaven.",
                "Behold, I give you authority to tread on serpents and scorpions, and over all the power of the enemy. Nothing will in any way hurt you.",
                "Nevertheless, don\u2019t rejoice in this, that the spirits are subject to you, but rejoice that your names are written in heaven.\"",
                "In that same hour Jesus rejoiced in the Holy Spirit, and said, \"I thank you, O Father, Lord of heaven and earth, that you have hidden these things from the wise and understanding, and revealed them to little children. Yes, Father, for so it was well-pleasing in your sight.\"",
                "Turning to the disciples, he said, \"All things have been delivered to me by my Father. No one knows who the Son is, except the Father, and who the Father is, except the Son, and he to whomever the Son desires to reveal him.\"",
                "Turning to the disciples, he said privately, \"Blessed are the eyes which see the things that you see,",
                "for I tell you that many prophets and kings desired to see the things which you see, and didn\u2019t see them, and to hear the things which you hear, and didn\u2019t hear them.\"",
                "Behold, a certain lawyer stood up and tested him, saying, \"Teacher, what shall I do to inherit eternal life?\"",
                "He said to him, \"What is written in the law? How do you read it?\"",
                "He answered, \"You shall love the Lord your God with all your heart, with all your soul, with all your strength, and with all your mind; and your neighbor as yourself.\"",
                "He said to him, \"You have answered correctly. Do this, and you will live.\"",
                "But he, desiring to justify himself, asked Jesus, \"Who is my neighbor?\"",
                "Jesus answered, \"A certain man was going down from Jerusalem to Jericho, and he fell among robbers, who both stripped him and beat him, and departed, leaving him half dead.",
                "By chance a certain priest was going down that way. When he saw him, he passed by on the other side.",
                "In the same way a Levite also, when he came to the place, and saw him, passed by on the other side.",
                "But a certain Samaritan, as he traveled, came where he was. When he saw him, he was moved with compassion,",
                "came to him, and bound up his wounds, pouring on oil and wine. He set him on his own animal, and brought him to an inn, and took care of him.",
                "On the next day, when he departed, he took out two denarii, and gave them to the host, and said to him, \u2018Take care of him. Whatever you spend beyond that, I will repay you when I return.\u2019",
                "Now which of these three do you think seemed to be a neighbor to him who fell among the robbers?\"",
                "He said, \"He who showed mercy on him.\" Then Jesus said to him, \"Go and do likewise.\"",
                "It happened as they went on their way, he entered into a certain village, and a certain woman named Martha received him into her house.",
                "She had a sister called Mary, who also sat at Jesus\u2019 feet, and heard his word.",
                "But Martha was distracted with much serving, and she came up to him, and said, \"Lord, don\u2019t you care that my sister left me to serve alone? Ask her therefore to help me.\"",
                "Jesus answered her, \"Martha, Martha, you are anxious and troubled about many things,",
                "but one thing is needed. Mary has chosen the good part, which will not be taken away from her.\""
            ],
            11: [
                "It happened, that when he finished praying in a certain place, one of his disciples said to him, \"Lord, teach us to pray, just as John also taught his disciples.\"",
                "He said to them, \"When you pray, say, \u2018Our Father in heaven, may your name be kept holy. May your Kingdom come. May your will be done on earth, as it is in heaven.",
                "Give us day by day our daily bread.",
                "Forgive us our sins, for we ourselves also forgive everyone who is indebted to us. Bring us not into temptation, but deliver us from the evil one.\u2019\"",
                "He said to them, \"Which of you, if you go to a friend at midnight, and tell him, \u2018Friend, lend me three loaves of bread,",
                "for a friend of mine has come to me from a journey, and I have nothing to set before him,\u2019",
                "and he from within will answer and say, \u2018Don\u2019t bother me. The door is now shut, and my children are with me in bed. I can\u2019t get up and give it to you\u2019?",
                "I tell you, although he will not rise and give it to him because he is his friend, yet because of his persistence, he will get up and give him as many as he needs.",
                "\"I tell you, keep asking, and it will be given you. Keep seeking, and you will find. Keep knocking, and it will be opened to you.",
                "For everyone who asks receives. He who seeks finds. To him who knocks it will be opened.",
                "\"Which of you fathers, if your son asks for bread, will give him a stone? Or if he asks for a fish, he won\u2019t give him a snake instead of a fish, will he?",
                "Or if he asks for an egg, he won\u2019t give him a scorpion, will he?",
                "If you then, being evil, know how to give good gifts to your children, how much more will your heavenly Father give the Holy Spirit to those who ask him?\"",
                "He was casting out a demon, and it was mute. It happened, when the demon had gone out, the mute man spoke; and the multitudes marveled.",
                "But some of them said, \"He casts out demons by Beelzebul, the prince of the demons.\"",
                "Others, testing him, sought from him a sign from heaven.",
                "But he, knowing their thoughts, said to them, \"Every kingdom divided against itself is brought to desolation. A house divided against itself falls.",
                "If Satan also is divided against himself, how will his kingdom stand? For you say that I cast out demons by Beelzebul.",
                "But if I cast out demons by Beelzebul, by whom do your children cast them out? Therefore will they be your judges.",
                "But if I by the finger of God cast out demons, then the Kingdom of God has come to you.",
                "\"When the strong man, fully armed, guards his own dwelling, his goods are safe.",
                "But when someone stronger attacks him and overcomes him, he takes from him his whole armor in which he trusted, and divides his spoils.",
                "\"He that is not with me is against me. He who doesn\u2019t gather with me scatters.",
                "The unclean spirit, when he has gone out of the man, passes through dry places, seeking rest, and finding none, he says, \u2018I will turn back to my house from which I came out.\u2019",
                "When he returns, he finds it swept and put in order.",
                "Then he goes, and takes seven other spirits more evil than himself, and they enter in and dwell there. The last state of that man becomes worse than the first.\"",
                "It came to pass, as he said these things, a certain woman out of the multitude lifted up her voice, and said to him, \"Blessed is the womb that bore you, and the breasts which nursed you!\"",
                "But he said, \"On the contrary, blessed are those who hear the word of God, and keep it.\"",
                "When the multitudes were gathering together to him, he began to say, \"This is an evil generation. It seeks after a sign. No sign will be given to it but the sign of Jonah, the prophet.",
                "For even as Jonah became a sign to the Ninevites, so will also the Son of Man be to this generation.",
                "The Queen of the South will rise up in the judgment with the men of this generation, and will condemn them: for she came from the ends of the earth to hear the wisdom of Solomon; and behold, one greater than Solomon is here.",
                "The men of Nineveh will stand up in the judgment with this generation, and will condemn it: for they repented at the preaching of Jonah, and behold, one greater than Jonah is here.",
                "\"No one, when he has lit a lamp, puts it in a cellar or under a basket, but on a stand, that those who come in may see the light.",
                "The lamp of the body is the eye. Therefore when your eye is good, your whole body is also full of light; but when it is evil, your body also is full of darkness.",
                "Therefore see whether the light that is in you isn\u2019t darkness.",
                "If therefore your whole body is full of light, having no part dark, it will be wholly full of light, as when the lamp with its bright shining gives you light.\"",
                "Now as he spoke, a certain Pharisee asked him to dine with him. He went in, and sat at the table.",
                "When the Pharisee saw it, he marveled that he had not first washed himself before dinner.",
                "The Lord said to him, \"Now you Pharisees cleanse the outside of the cup and of the platter, but your inward part is full of extortion and wickedness.",
                "You foolish ones, didn\u2019t he who made the outside make the inside also?",
                "But give for gifts to the needy those things which are within, and behold, all things will be clean to you.",
                "But woe to you Pharisees! For you tithe mint and rue and every herb, but you bypass justice and the love of God. You ought to have done these, and not to have left the other undone.",
                "Woe to you Pharisees! For you love the best seats in the synagogues, and the greetings in the marketplaces.",
                "Woe to you, scribes and Pharisees, hypocrites! For you are like hidden graves, and the men who walk over them don\u2019t know it.\"",
                "One of the lawyers answered him, \"Teacher, in saying this you insult us also.\"",
                "He said, \"Woe to you lawyers also! For you load men with burdens that are difficult to carry, and you yourselves won\u2019t even lift one finger to help carry those burdens.",
                "Woe to you! For you build the tombs of the prophets, and your fathers killed them.",
                "So you testify and consent to the works of your fathers. For they killed them, and you build their tombs.",
                "Therefore also the wisdom of God said, \u2018I will send to them prophets and apostles; and some of them they will kill and persecute,",
                "that the blood of all the prophets, which was shed from the foundation of the world, may be required of this generation;",
                "from the blood of Abel to the blood of Zachariah, who perished between the altar and the sanctuary.\u2019 Yes, I tell you, it will be required of this generation.",
                "Woe to you lawyers! For you took away the key of knowledge. You didn\u2019t enter in yourselves, and those who were entering in, you hindered.\"",
                "As he said these things to them, the scribes and the Pharisees began to be terribly angry, and to draw many things out of him;",
                "lying in wait for him, and seeking to catch him in something he might say, that they might accuse him."
            ],
            12: [
                "Meanwhile, when a multitude of many thousands had gathered together, so much so that they trampled on each other, he began to tell his disciples first of all, \"Beware of the yeast of the Pharisees, which is hypocrisy.",
                "But there is nothing covered up, that will not be revealed, nor hidden, that will not be known.",
                "Therefore whatever you have said in the darkness will be heard in the light. What you have spoken in the ear in the inner rooms will be proclaimed on the housetops.",
                "\"I tell you, my friends, don\u2019t be afraid of those who kill the body, and after that have no more that they can do.",
                "But I will warn you whom you should fear. Fear him, who after he has killed, has power to cast into Gehenna. Yes, I tell you, fear him.",
                "\"Aren\u2019t five sparrows sold for two assaria coins? Not one of them is forgotten by God.",
                "But the very hairs of your head are all numbered. Therefore don\u2019t be afraid. You are of more value than many sparrows.",
                "\"I tell you, everyone who confesses me before men, him will the Son of Man also confess before the angels of God;",
                "but he who denies me in the presence of men will be denied in the presence of the angels of God.",
                "Everyone who speaks a word against the Son of Man will be forgiven, but those who blaspheme against the Holy Spirit will not be forgiven.",
                "When they bring you before the synagogues, the rulers, and the authorities, don\u2019t be anxious how or what you will answer, or what you will say;",
                "for the Holy Spirit will teach you in that same hour what you must say.\"",
                "One of the multitude said to him, \"Teacher, tell my brother to divide the inheritance with me.\"",
                "But he said to him, \"Man, who made me a judge or an arbitrator over you?\"",
                "He said to them, \"Beware! Keep yourselves from covetousness, for a man\u2019s life doesn\u2019t consist of the abundance of the things which he possesses.\"",
                "He spoke a parable to them, saying, \"The ground of a certain rich man brought forth abundantly.",
                "He reasoned within himself, saying, \u2018What will I do, because I don\u2019t have room to store my crops?\u2019",
                "He said, \u2018This is what I will do. I will pull down my barns, and build bigger ones, and there I will store all my grain and my goods.",
                "I will tell my soul, \"Soul, you have many goods laid up for many years. Take your ease, eat, drink, be merry.\"\u2019",
                "\"But God said to him, \u2018You foolish one, tonight your soul is required of you. The things which you have prepared\u2014whose will they be?\u2019",
                "So is he who lays up treasure for himself, and is not rich toward God.\"",
                "He said to his disciples, \"Therefore I tell you, don\u2019t be anxious for your life, what you will eat, nor yet for your body, what you will wear.",
                "Life is more than food, and the body is more than clothing.",
                "Consider the ravens: they don\u2019t sow, they don\u2019t reap, they have no warehouse or barn, and God feeds them. How much more valuable are you than birds!",
                "Which of you by being anxious can add a cubit to his height?",
                "If then you aren\u2019t able to do even the least things, why are you anxious about the rest?",
                "Consider the lilies, how they grow. They don\u2019t toil, neither do they spin; yet I tell you, even Solomon in all his glory was not arrayed like one of these.",
                "But if this is how God clothes the grass in the field, which today exists, and tomorrow is cast into the oven, how much more will he clothe you, O you of little faith?",
                "Don\u2019t seek what you will eat or what you will drink; neither be anxious.",
                "For the nations of the world seek after all of these things, but your Father knows that you need these things.",
                "But seek God\u2019s Kingdom, and all these things will be added to you.",
                "Don\u2019t be afraid, little flock, for it is your Father\u2019s good pleasure to give you the Kingdom.",
                "Sell that which you have, and give gifts to the needy. Make for yourselves purses which don\u2019t grow old, a treasure in the heavens that doesn\u2019t fail, where no thief approaches, neither moth destroys.",
                "For where your treasure is, there will your heart be also.",
                "\"Let your waist be dressed and your lamps burning.",
                "Be like men watching for their lord, when he returns from the marriage feast; that, when he comes and knocks, they may immediately open to him.",
                "Blessed are those servants, whom the lord will find watching when he comes. Most certainly I tell you, that he will dress himself, and make them recline, and will come and serve them.",
                "They will be blessed if he comes in the second or third watch, and finds them so.",
                "But know this, that if the master of the house had known in what hour the thief was coming, he would have watched, and not allowed his house to be broken into.",
                "Therefore be ready also, for the Son of Man is coming in an hour that you don\u2019t expect him.\"",
                "Peter said to him, \"Lord, are you telling this parable to us, or to everybody?\"",
                "The Lord said, \"Who then is the faithful and wise steward, whom his lord will set over his household, to give them their portion of food at the right times?",
                "Blessed is that servant whom his lord will find doing so when he comes.",
                "Truly I tell you, that he will set him over all that he has.",
                "But if that servant says in his heart, \u2018My lord delays his coming,\u2019 and begins to beat the menservants and the maidservants, and to eat and drink, and to be drunken,",
                "then the lord of that servant will come in a day when he isn\u2019t expecting him, and in an hour that he doesn\u2019t know, and will cut him in two, and place his portion with the unfaithful.",
                "That servant, who knew his lord\u2019s will, and didn\u2019t prepare, nor do what he wanted, will be beaten with many stripes,",
                "but he who didn\u2019t know, and did things worthy of stripes, will be beaten with few stripes. To whomever much is given, of him will much be required; and to whom much was entrusted, of him more will be asked.",
                "\"I came to throw fire on the earth. I wish it were already kindled.",
                "But I have a baptism to be baptized with, and how distressed I am until it is accomplished!",
                "Do you think that I have come to give peace in the earth? I tell you, no, but rather division.",
                "For from now on, there will be five in one house divided, three against two, and two against three.",
                "They will be divided, father against son, and son against father; mother against daughter, and daughter against her mother; mother-in-law against her daughter-in-law, and daughter-in-law against her mother-in-law.\"",
                "He said to the multitudes also, \"When you see a cloud rising from the west, immediately you say, \u2018A shower is coming,\u2019 and so it happens.",
                "When a south wind blows, you say, \u2018There will be a scorching heat,\u2019 and it happens.",
                "You hypocrites! You know how to interpret the appearance of the earth and the sky, but how is it that you don\u2019t interpret this time?",
                "Why don\u2019t you judge for yourselves what is right?",
                "For when you are going with your adversary before the magistrate, try diligently on the way to be released from him, lest perhaps he drag you to the judge, and the judge deliver you to the officer, and the officer throw you into prison.",
                "I tell you, you will by no means get out of there, until you have paid the very last penny.\""
            ],
            13: [
                "Now there were some present at the same time who told him about the Galileans, whose blood Pilate had mixed with their sacrifices.",
                "Jesus answered them, \"Do you think that these Galileans were worse sinners than all the other Galileans, because they suffered such things?",
                "I tell you, no, but unless you repent, you will all perish in the same way.",
                "Or those eighteen, on whom the tower in Siloam fell, and killed them; do you think that they were worse offenders than all the men who dwell in Jerusalem?",
                "I tell you, no, but, unless you repent, you will all perish in the same way.\"",
                "He spoke this parable. \"A certain man had a fig tree planted in his vineyard, and he came seeking fruit on it, and found none.",
                "He said to the vine dresser, \u2018Behold, these three years I have come looking for fruit on this fig tree, and found none. Cut it down. Why does it waste the soil?\u2019",
                "He answered, \u2018Lord, leave it alone this year also, until I dig around it, and fertilize it.",
                "If it bears fruit, fine; but if not, after that, you can cut it down.\u2019\"",
                "He was teaching in one of the synagogues on the Sabbath day.",
                "Behold, there was a woman who had a spirit of infirmity eighteen years, and she was bent over, and could in no way straighten herself up.",
                "When Jesus saw her, he called her, and said to her, \"Woman, you are freed from your infirmity.\"",
                "He laid his hands on her, and immediately she stood up straight, and glorified God.",
                "The ruler of the synagogue, being indignant because Jesus had healed on the Sabbath, said to the multitude, \"There are six days in which men ought to work. Therefore come on those days and be healed, and not on the Sabbath day!\"",
                "Therefore the Lord answered him, \"You hypocrites! Doesn\u2019t each one of you free his ox or his donkey from the stall on the Sabbath, and lead him away to water?",
                "Ought not this woman, being a daughter of Abraham, whom Satan had bound eighteen long years, be freed from this bondage on the Sabbath day?\"",
                "As he said these things, all his adversaries were disappointed, and all the multitude rejoiced for all the glorious things that were done by him.",
                "He said, \"What is the Kingdom of God like? To what shall I compare it?",
                "It is like a grain of mustard seed, which a man took, and put in his own garden. It grew, and became a large tree, and the birds of the sky lodged in its branches.\"",
                "Again he said, \"To what shall I compare the Kingdom of God?",
                "It is like yeast, which a woman took and hid in three measures of flour, until it was all leavened.\"",
                "He went on his way through cities and villages, teaching, and traveling on to Jerusalem.",
                "One said to him, \"Lord, are they few who are saved?\" He said to them,",
                "\"Strive to enter in by the narrow door, for many, I tell you, will seek to enter in, and will not be able.",
                "When once the master of the house has risen up, and has shut the door, and you begin to stand outside, and to knock at the door, saying, \u2018Lord, Lord, open to us!\u2019 then he will answer and tell you, \u2018I don\u2019t know you or where you come from.\u2019",
                "Then you will begin to say, \u2018We ate and drank in your presence, and you taught in our streets.\u2019",
                "He will say, \u2018I tell you, I don\u2019t know where you come from. Depart from me, all you workers of iniquity.\u2019",
                "There will be weeping and gnashing of teeth, when you see Abraham, Isaac, Jacob, and all the prophets, in the Kingdom of God, and yourselves being thrown outside.",
                "They will come from the east, west, north, and south, and will sit down in the Kingdom of God.",
                "Behold, there are some who are last who will be first, and there are some who are first who will be last.\"",
                "On that same day, some Pharisees came, saying to him, \"Get out of here, and go away, for Herod wants to kill you.\"",
                "He said to them, \"Go and tell that fox, \u2018Behold, I cast out demons and perform cures today and tomorrow, and the third day I complete my mission.",
                "Nevertheless I must go on my way today and tomorrow and the next day, for it can\u2019t be that a prophet perish outside of Jerusalem.\u2019",
                "\"Jerusalem, Jerusalem, that kills the prophets, and stones those who are sent to her! How often I wanted to gather your children together, like a hen gathers her own brood under her wings, and you refused!",
                "Behold, your house is left to you desolate. I tell you, you will not see me, until you say, \u2018Blessed is he who comes in the name of the Lord!\u2019\""
            ],
            14: [
                "It happened, when he went into the house of one of the rulers of the Pharisees on a Sabbath to eat bread, that they were watching him.",
                "Behold, a certain man who had dropsy was in front of him.",
                "Jesus, answering, spoke to the lawyers and Pharisees, saying, \"Is it lawful to heal on the Sabbath?\"",
                "But they were silent. He took him, and healed him, and let him go.",
                "He answered them, \"Which of you, if your son or an ox fell into a well, wouldn\u2019t immediately pull him out on a Sabbath day?\"",
                "They couldn\u2019t answer him regarding these things.",
                "He spoke a parable to those who were invited, when he noticed how they chose the best seats, and said to them,",
                "\"When you are invited by anyone to a marriage feast, don\u2019t sit in the best seat, since perhaps someone more honorable than you might be invited by him,",
                "and he who invited both of you would come and tell you, \u2018Make room for this person.\u2019 Then you would begin, with shame, to take the lowest place.",
                "But when you are invited, go and sit in the lowest place, so that when he who invited you comes, he may tell you, \u2018Friend, move up higher.\u2019 Then you will be honored in the presence of all who sit at the table with you.",
                "For everyone who exalts himself will be humbled, and whoever humbles himself will be exalted.\"",
                "He also said to the one who had invited him, \"When you make a dinner or a supper, don\u2019t call your friends, nor your brothers, nor your kinsmen, nor rich neighbors, or perhaps they might also return the favor, and pay you back.",
                "But when you make a feast, ask the poor, the maimed, the lame, or the blind;",
                "and you will be blessed, because they don\u2019t have the resources to repay you. For you will be repaid in the resurrection of the righteous.\"",
                "When one of those who sat at the table with him heard these things, he said to him, \"Blessed is he who will feast in the Kingdom of God!\"",
                "But he said to him, \"A certain man made a great supper, and he invited many people.",
                "He sent out his servant at supper time to tell those who were invited, \u2018Come, for everything is ready now.\u2019",
                "They all as one began to make excuses. \"The first said to him, \u2018I have bought a field, and I must go and see it. Please have me excused.\u2019",
                "\"Another said, \u2018I have bought five yoke of oxen, and I must go try them out. Please have me excused.\u2019",
                "\"Another said, \u2018I have married a wife, and therefore I can\u2019t come.\u2019",
                "\"That servant came, and told his lord these things. Then the master of the house, being angry, said to his servant, \u2018Go out quickly into the streets and lanes of the city, and bring in the poor, maimed, blind, and lame.\u2019",
                "\"The servant said, \u2018Lord, it is done as you commanded, and there is still room.\u2019",
                "\"The lord said to the servant, \u2018Go out into the highways and hedges, and compel them to come in, that my house may be filled.",
                "For I tell you that none of those men who were invited will taste of my supper.\u2019\"",
                "Now great multitudes were going with him. He turned and said to them,",
                "\"If anyone comes to me, and doesn\u2019t disregard his own father, mother, wife, children, brothers, and sisters, yes, and his own life also, he can\u2019t be my disciple.",
                "Whoever doesn\u2019t bear his own cross, and come after me, can\u2019t be my disciple.",
                "For which of you, desiring to build a tower, doesn\u2019t first sit down and count the cost, to see if he has enough to complete it?",
                "Or perhaps, when he has laid a foundation, and is not able to finish, everyone who sees begins to mock him,",
                "saying, \u2018This man began to build, and wasn\u2019t able to finish.\u2019",
                "Or what king, as he goes to encounter another king in war, will not sit down first and consider whether he is able with ten thousand to meet him who comes against him with twenty thousand?",
                "Or else, while the other is yet a great way off, he sends an envoy, and asks for conditions of peace.",
                "So therefore whoever of you who doesn\u2019t renounce all that he has, he can\u2019t be my disciple.",
                "Salt is good, but if the salt becomes flat and tasteless, with what do you season it?",
                "It is fit neither for the soil nor for the manure pile. It is thrown out. He who has ears to hear, let him hear.\""
            ],
            15: [
                "Now all the tax collectors and sinners were coming close to him to hear him.",
                "The Pharisees and the scribes murmured, saying, \"This man welcomes sinners, and eats with them.\"",
                "He told them this parable.",
                "\"Which of you men, if you had one hundred sheep, and lost one of them, wouldn\u2019t leave the ninety-nine in the wilderness, and go after the one that was lost, until he found it?",
                "When he has found it, he carries it on his shoulders, rejoicing.",
                "When he comes home, he calls together his friends and his neighbors, saying to them, \u2018Rejoice with me, for I have found my sheep which was lost!\u2019",
                "I tell you that even so there will be more joy in heaven over one sinner who repents, than over ninety-nine righteous people who need no repentance.",
                "Or what woman, if she had ten drachma coins, if she lost one drachma coin, wouldn\u2019t light a lamp, sweep the house, and seek diligently until she found it?",
                "When she has found it, she calls together her friends and neighbors, saying, \u2018Rejoice with me, for I have found the drachma which I had lost.\u2019",
                "Even so, I tell you, there is joy in the presence of the angels of God over one sinner repenting.\"",
                "He said, \"A certain man had two sons.",
                "The younger of them said to his father, \u2018Father, give me my share of your property.\u2019 He divided his livelihood between them.",
                "Not many days after, the younger son gathered all of this together and traveled into a far country. There he wasted his property with riotous living.",
                "When he had spent all of it, there arose a severe famine in that country, and he began to be in need.",
                "He went and joined himself to one of the citizens of that country, and he sent him into his fields to feed pigs.",
                "He wanted to fill his belly with the husks that the pigs ate, but no one gave him any.",
                "But when he came to himself he said, \u2018How many hired servants of my father\u2019s have bread enough to spare, and I\u2019m dying with hunger!",
                "I will get up and go to my father, and will tell him, \"Father, I have sinned against heaven, and in your sight.",
                "I am no more worthy to be called your son. Make me as one of your hired servants.\"\u2019",
                "\"He arose, and came to his father. But while he was still far off, his father saw him, and was moved with compassion, and ran, and fell on his neck, and kissed him.",
                "The son said to him, \u2018Father, I have sinned against heaven, and in your sight. I am no longer worthy to be called your son.\u2019",
                "\"But the father said to his servants, \u2018Bring out the best robe, and put it on him. Put a ring on his hand, and shoes on his feet.",
                "Bring the fattened calf, kill it, and let us eat, and celebrate;",
                "for this, my son, was dead, and is alive again. He was lost, and is found.\u2019 They began to celebrate.",
                "\"Now his elder son was in the field. As he came near to the house, he heard music and dancing.",
                "He called one of the servants to him, and asked what was going on.",
                "He said to him, \u2018Your brother has come, and your father has killed the fattened calf, because he has received him back safe and healthy.\u2019",
                "But he was angry, and would not go in. Therefore his father came out, and begged him.",
                "But he answered his father, \u2018Behold, these many years I have served you, and I never disobeyed a commandment of yours, but you never gave me a goat, that I might celebrate with my friends.",
                "But when this, your son, came, who has devoured your living with prostitutes, you killed the fattened calf for him.\u2019",
                "\"He said to him, \u2018Son, you are always with me, and all that is mine is yours.",
                "But it was appropriate to celebrate and be glad, for this, your brother, was dead, and is alive again. He was lost, and is found.\u2019\""
            ],
            16: [
                "He also said to his disciples, \"There was a certain rich man who had a manager. An accusation was made to him that this man was wasting his possessions.",
                "He called him, and said to him, \u2018What is this that I hear about you? Give an accounting of your management, for you can no longer be manager.\u2019",
                "\"The manager said within himself, \u2018What will I do, seeing that my lord is taking away the management position from me? I don\u2019t have strength to dig. I am ashamed to beg.",
                "I know what I will do, so that when I am removed from management, they may receive me into their houses.\u2019",
                "Calling each one of his lord\u2019s debtors to him, he said to the first, \u2018How much do you owe to my lord?\u2019",
                "He said, \u2018A hundred batos of oil.\u2019 He said to him, \u2018Take your bill, and sit down quickly and write fifty.\u2019",
                "Then he said to another, \u2018How much do you owe?\u2019 He said, \u2018A hundred cors of wheat.\u2019 He said to him, \u2018Take your bill, and write eighty.\u2019",
                "\"His lord commended the dishonest manager because he had done wisely, for the children of this world are, in their own generation, wiser than the children of the light.",
                "I tell you, make for yourselves friends by means of unrighteous mammon, so that when you fail, they may receive you into the eternal tents.",
                "He who is faithful in a very little is faithful also in much. He who is dishonest in a very little is also dishonest in much.",
                "If therefore you have not been faithful in the unrighteous mammon, who will commit to your trust the true riches?",
                "If you have not been faithful in that which is another\u2019s, who will give you that which is your own?",
                "No servant can serve two masters, for either he will hate the one, and love the other; or else he will hold to one, and despise the other. You aren\u2019t able to serve God and mammon.\"",
                "The Pharisees, who were lovers of money, also heard all these things, and they scoffed at him.",
                "He said to them, \"You are those who justify yourselves in the sight of men, but God knows your hearts. For that which is exalted among men is an abomination in the sight of God.",
                "The law and the prophets were until John. From that time the Good News of the Kingdom of God is preached, and everyone is forcing his way into it.",
                "But it is easier for heaven and earth to pass away, than for one tiny stroke of a pen in the law to fall.",
                "Everyone who divorces his wife, and marries another, commits adultery. He who marries one who is divorced from a husband commits adultery.",
                "\"Now there was a certain rich man, and he was clothed in purple and fine linen, living in luxury every day.",
                "A certain beggar, named Lazarus, was laid at his gate, full of sores,",
                "and desiring to be fed with the crumbs that fell from the rich man\u2019s table. Yes, even the dogs came and licked his sores.",
                "It happened that the beggar died, and that he was carried away by the angels to Abraham\u2019s bosom. The rich man also died, and was buried.",
                "In Hades, he lifted up his eyes, being in torment, and saw Abraham far off, and Lazarus at his bosom.",
                "He cried and said, \u2018Father Abraham, have mercy on me, and send Lazarus, that he may dip the tip of his finger in water, and cool my tongue! For I am in anguish in this flame.\u2019",
                "\"But Abraham said, \u2018Son, remember that you, in your lifetime, received your good things, and Lazarus, in the same way, bad things. But now here he is comforted and you are in anguish.",
                "Besides all this, between us and you there is a great gulf fixed, that those who want to pass from here to you are not able, and that none may cross over from there to us.\u2019",
                "\"He said, \u2018I ask you therefore, father, that you would send him to my father\u2019s house;",
                "for I have five brothers, that he may testify to them, so they won\u2019t also come into this place of torment.\u2019",
                "\"But Abraham said to him, \u2018They have Moses and the prophets. Let them listen to them.\u2019",
                "\"He said, \u2018No, father Abraham, but if one goes to them from the dead, they will repent.\u2019",
                "\"He said to him, \u2018If they don\u2019t listen to Moses and the prophets, neither will they be persuaded if one rises from the dead.\u2019\""
            ],
            17: [
                "He said to the disciples, \"It is impossible that no occasions of stumbling should come, but woe to him through whom they come!",
                "It would be better for him if a millstone were hung around his neck, and he were thrown into the sea, rather than that he should cause one of these little ones to stumble.",
                "Be careful. If your brother sins against you, rebuke him. If he repents, forgive him.",
                "If he sins against you seven times in the day, and seven times returns, saying, \u2018I repent,\u2019 you shall forgive him.\"",
                "The apostles said to the Lord, \"Increase our faith.\"",
                "The Lord said, \"If you had faith like a grain of mustard seed, you would tell this sycamore tree, \u2018Be uprooted, and be planted in the sea,\u2019 and it would obey you.",
                "But who is there among you, having a servant plowing or keeping sheep, that will say, when he comes in from the field, \u2018Come immediately and sit down at the table,\u2019",
                "and will not rather tell him, \u2018Prepare my supper, clothe yourself properly, and serve me, while I eat and drink. Afterward you shall eat and drink\u2019?",
                "Does he thank that servant because he did the things that were commanded? I think not.",
                "Even so you also, when you have done all the things that are commanded you, say, \u2018We are unworthy servants. We have done our duty.\u2019\"",
                "It happened as he was on his way to Jerusalem, that he was passing along the borders of Samaria and Galilee.",
                "As he entered into a certain village, ten men who were lepers met him, who stood at a distance.",
                "They lifted up their voices, saying, \"Jesus, Master, have mercy on us!\"",
                "When he saw them, he said to them, \"Go and show yourselves to the priests.\" It happened that as they went, they were cleansed.",
                "One of them, when he saw that he was healed, turned back, glorifying God with a loud voice.",
                "He fell on his face at Jesus\u2019 feet, giving him thanks; and he was a Samaritan.",
                "Jesus answered, \"Weren\u2019t the ten cleansed? But where are the nine?",
                "Were there none found who returned to give glory to God, except this stranger?\"",
                "Then he said to him, \"Get up, and go your way. Your faith has healed you.\"",
                "Being asked by the Pharisees when the Kingdom of God would come, he answered them, \"The Kingdom of God doesn\u2019t come with observation;",
                "neither will they say, \u2018Look, here!\u2019 or, \u2018Look, there!\u2019 for behold, the Kingdom of God is within you.\"",
                "He said to the disciples, \"The days will come, when you will desire to see one of the days of the Son of Man, and you will not see it.",
                "They will tell you, \u2018Look, here!\u2019 or \u2018Look, there!\u2019 Don\u2019t go away, nor follow after them,",
                "for as the lightning, when it flashes out of the one part under the sky, shines to the other part under the sky; so will the Son of Man be in his day.",
                "But first, he must suffer many things and be rejected by this generation.",
                "As it happened in the days of Noah, even so will it be also in the days of the Son of Man.",
                "They ate, they drank, they married, they were given in marriage, until the day that Noah entered into the ship, and the flood came, and destroyed them all.",
                "Likewise, even as it happened in the days of Lot: they ate, they drank, they bought, they sold, they planted, they built;",
                "but in the day that Lot went out from Sodom, it rained fire and sulfur from the sky, and destroyed them all.",
                "It will be the same way in the day that the Son of Man is revealed.",
                "In that day, he who will be on the housetop, and his goods in the house, let him not go down to take them away. Let him who is in the field likewise not turn back.",
                "Remember Lot\u2019s wife!",
                "Whoever seeks to save his life loses it, but whoever loses his life preserves it.",
                "I tell you, in that night there will be two people in one bed. The one will be taken, and the other will be left.",
                "There will be two grinding grain together. One will be taken, and the other will be left.\"",
                "They, answering, asked him, \"Where, Lord?\" He said to them, \"Where the body is, there will the vultures also be gathered together.\""
            ],
            18: [
                "He also spoke a parable to them that they must always pray, and not give up,",
                "saying, \"There was a judge in a certain city who didn\u2019t fear God, and didn\u2019t respect man.",
                "A widow was in that city, and she often came to him, saying, \u2018Defend me from my adversary!\u2019",
                "He wouldn\u2019t for a while, but afterward he said to himself, \u2018Though I neither fear God, nor respect man,",
                "yet because this widow bothers me, I will defend her, or else she will wear me out by her continual coming.\u2019\"",
                "The Lord said, \"Listen to what the unrighteous judge says.",
                "Won\u2019t God avenge his chosen ones, who are crying out to him day and night, and yet he exercises patience with them?",
                "I tell you that he will avenge them quickly. Nevertheless, when the Son of Man comes, will he find faith on the earth?\"",
                "He spoke also this parable to certain people who were convinced of their own righteousness, and who despised all others.",
                "\"Two men went up into the temple to pray; one was a Pharisee, and the other was a tax collector.",
                "The Pharisee stood and prayed to himself like this: \u2018God, I thank you, that I am not like the rest of men, extortioners, unrighteous, adulterers, or even like this tax collector.",
                "I fast twice a week. I give tithes of all that I get.\u2019",
                "But the tax collector, standing far away, wouldn\u2019t even lift up his eyes to heaven, but beat his breast, saying, \u2018God, be merciful to me, a sinner!\u2019",
                "I tell you, this man went down to his house justified rather than the other; for everyone who exalts himself will be humbled, but he who humbles himself will be exalted.\"",
                "They were also bringing their babies to him, that he might touch them. But when the disciples saw it, they rebuked them.",
                "Jesus summoned them, saying, \"Allow the little children to come to me, and don\u2019t hinder them, for the Kingdom of God belongs to such as these.",
                "Most certainly, I tell you, whoever doesn\u2019t receive the Kingdom of God like a little child, he will in no way enter into it.\"",
                "A certain ruler asked him, saying, \"Good Teacher, what shall I do to inherit eternal life?\"",
                "Jesus asked him, \"Why do you call me good? No one is good, except one\u2014God.",
                "You know the commandments: \u2018Don\u2019t commit adultery,\u2019 \u2018Don\u2019t murder,\u2019 \u2018Don\u2019t steal,\u2019 \u2018Don\u2019t give false testimony,\u2019 \u2018Honor your father and your mother.\u2019\"",
                "He said, \"I have observed all these things from my youth up.\"",
                "When Jesus heard these things, he said to him, \"You still lack one thing. Sell all that you have, and distribute it to the poor. You will have treasure in heaven. Come, follow me.\"",
                "But when he heard these things, he became very sad, for he was very rich.",
                "Jesus, seeing that he became very sad, said, \"How hard it is for those who have riches to enter into the Kingdom of God!",
                "For it is easier for a camel to enter in through a needle\u2019s eye, than for a rich man to enter into the Kingdom of God.\"",
                "Those who heard it said, \"Then who can be saved?\"",
                "But he said, \"The things which are impossible with men are possible with God.\"",
                "Peter said, \"Look, we have left everything, and followed you.\"",
                "He said to them, \"Most certainly I tell you, there is no one who has left house, or wife, or brothers, or parents, or children, for the Kingdom of God\u2019s sake,",
                "who will not receive many times more in this time, and in the world to come, eternal life.\"",
                "He took the twelve aside, and said to them, \"Behold, we are going up to Jerusalem, and all the things that are written through the prophets concerning the Son of Man will be completed.",
                "For he will be delivered up to the Gentiles, will be mocked, treated shamefully, and spit on.",
                "They will scourge and kill him. On the third day, he will rise again.\"",
                "They understood none of these things. This saying was hidden from them, and they didn\u2019t understand the things that were said.",
                "It happened, as he came near Jericho, a certain blind man sat by the road, begging.",
                "Hearing a multitude going by, he asked what this meant.",
                "They told him that Jesus of Nazareth was passing by.",
                "He cried out, \"Jesus, you son of David, have mercy on me!\"",
                "Those who led the way rebuked him, that he should be quiet; but he cried out all the more, \"You son of David, have mercy on me!\"",
                "Standing still, Jesus commanded him to be brought to him. When he had come near, he asked him,",
                "\"What do you want me to do?\" He said, \"Lord, that I may see again.\"",
                "Jesus said to him, \"Receive your sight. Your faith has healed you.\"",
                "Immediately he received his sight, and followed him, glorifying God. All the people, when they saw it, praised God."
            ],
            19: [
                "He entered and was passing through Jericho.",
                "There was a man named Zacchaeus. He was a chief tax collector, and he was rich.",
                "He was trying to see who Jesus was, and couldn\u2019t because of the crowd, because he was short.",
                "He ran on ahead, and climbed up into a sycamore tree to see him, for he was to pass that way.",
                "When Jesus came to the place, he looked up and saw him, and said to him, \"Zacchaeus, hurry and come down, for today I must stay at your house.\"",
                "He hurried, came down, and received him joyfully.",
                "When they saw it, they all murmured, saying, \"He has gone in to lodge with a man who is a sinner.\"",
                "Zacchaeus stood and said to the Lord, \"Behold, Lord, half of my goods I give to the poor. If I have wrongfully exacted anything of anyone, I restore four times as much.\"",
                "Jesus said to him, \"Today, salvation has come to this house, because he also is a son of Abraham.",
                "For the Son of Man came to seek and to save that which was lost.\"",
                "As they heard these things, he went on and told a parable, because he was near Jerusalem, and they supposed that the Kingdom of God would be revealed immediately.",
                "He said therefore, \"A certain nobleman went into a far country to receive for himself a kingdom, and to return.",
                "He called ten servants of his, and gave them ten mina coins, and told them, \u2018Conduct business until I come.\u2019",
                "But his citizens hated him, and sent an envoy after him, saying, \u2018We don\u2019t want this man to reign over us.\u2019",
                "\"It happened when he had come back again, having received the kingdom, that he commanded these servants, to whom he had given the money, to be called to him, that he might know what they had gained by conducting business.",
                "The first came before him, saying, \u2018Lord, your mina has made ten more minas.\u2019",
                "\"He said to him, \u2018Well done, you good servant! Because you were found faithful with very little, you shall have authority over ten cities.\u2019",
                "\"The second came, saying, \u2018Your mina, Lord, has made five minas.\u2019",
                "\"So he said to him, \u2018And you are to be over five cities.\u2019",
                "Another came, saying, \u2018Lord, behold, your mina, which I kept laid away in a handkerchief,",
                "for I feared you, because you are an exacting man. You take up that which you didn\u2019t lay down, and reap that which you didn\u2019t sow.\u2019",
                "\"He said to him, \u2018Out of your own mouth will I judge you, you wicked servant! You knew that I am an exacting man, taking up that which I didn\u2019t lay down, and reaping that which I didn\u2019t sow.",
                "Then why didn\u2019t you deposit my money in the bank, and at my coming, I might have earned interest on it?\u2019",
                "He said to those who stood by, \u2018Take the mina away from him, and give it to him who has the ten minas.\u2019",
                "\"They said to him, \u2018Lord, he has ten minas!\u2019",
                "\u2018For I tell you that to everyone who has, will more be given; but from him who doesn\u2019t have, even that which he has will be taken away from him.",
                "But bring those enemies of mine who didn\u2019t want me to reign over them here, and kill them before me.\u2019\"",
                "Having said these things, he went on ahead, going up to Jerusalem.",
                "It happened, when he drew near to Bethsphage and Bethany, at the mountain that is called Olivet, he sent two of his disciples,",
                "saying, \"Go your way into the village on the other side, in which, as you enter, you will find a colt tied, whereon no man ever yet sat. Untie it, and bring it.",
                "If anyone asks you, \u2018Why are you untying it?\u2019 say to him: \u2018The Lord needs it.\u2019\"",
                "Those who were sent went away, and found things just as he had told them.",
                "As they were untying the colt, its owners said to them, \"Why are you untying the colt?\"",
                "They said, \"The Lord needs it.\"",
                "They brought it to Jesus. They threw their cloaks on the colt, and set Jesus on them.",
                "As he went, they spread their cloaks in the way.",
                "As he was now getting near, at the descent of the Mount of Olives, the whole multitude of the disciples began to rejoice and praise God with a loud voice for all the mighty works which they had seen,",
                "saying, \"Blessed is the King who comes in the name of the Lord! Peace in heaven, and glory in the highest!\"",
                "Some of the Pharisees from the multitude said to him, \"Teacher, rebuke your disciples!\"",
                "He answered them, \"I tell you that if these were silent, the stones would cry out.\"",
                "When he drew near, he saw the city and wept over it,",
                "saying, \"If you, even you, had known today the things which belong to your peace! But now, they are hidden from your eyes.",
                "For the days will come on you, when your enemies will throw up a barricade against you, surround you, hem you in on every side,",
                "and will dash you and your children within you to the ground. They will not leave in you one stone on another, because you didn\u2019t know the time of your visitation.\"",
                "He entered into the temple, and began to drive out those who bought and sold in it,",
                "saying to them, \"It is written, \u2018My house is a house of prayer,\u2019 but you have made it a \u2018den of robbers\u2019!\"",
                "He was teaching daily in the temple, but the chief priests and the scribes and the leading men among the people sought to destroy him.",
                "They couldn\u2019t find what they might do, for all the people hung on to every word that he said."
            ],
            20: [
                "It happened on one of those days, as he was teaching the people in the temple and preaching the Good News, that the priests and scribes came to him with the elders.",
                "They asked him, \"Tell us: by what authority do you do these things? Or who is giving you this authority?\"",
                "He answered them, \"I also will ask you one question. Tell me:",
                "the baptism of John, was it from heaven, or from men?\"",
                "They reasoned with themselves, saying, \"If we say, \u2018From heaven,\u2019 he will say, \u2018Why didn\u2019t you believe him?\u2019",
                "But if we say, \u2018From men,\u2019 all the people will stone us, for they are persuaded that John was a prophet.\"",
                "They answered that they didn\u2019t know where it was from.",
                "Jesus said to them, \"Neither will I tell you by what authority I do these things.\"",
                "He began to tell the people this parable. \"A man planted a vineyard, and rented it out to some farmers, and went into another country for a long time.",
                "At the proper season, he sent a servant to the farmers to collect his share of the fruit of the vineyard. But the farmers beat him, and sent him away empty.",
                "He sent yet another servant, and they also beat him, and treated him shamefully, and sent him away empty.",
                "He sent yet a third, and they also wounded him, and threw him out.",
                "The lord of the vineyard said, \u2018What shall I do? I will send my beloved son. It may be that seeing him, they will respect him.\u2019",
                "\"But when the farmers saw him, they reasoned among themselves, saying, \u2018This is the heir. Come, let\u2019s kill him, that the inheritance may be ours.\u2019",
                "They threw him out of the vineyard, and killed him. What therefore will the lord of the vineyard do to them?",
                "He will come and destroy these farmers, and will give the vineyard to others.\" When they heard it, they said, \"May it never be!\"",
                "But he looked at them, and said, \"Then what is this that is written, \u2018The stone which the builders rejected, the same was made the chief cornerstone?\u2019",
                "Everyone who falls on that stone will be broken to pieces, but it will crush whomever it falls on to dust.\"",
                "The chief priests and the scribes sought to lay hands on him that very hour, but they feared the people\u2014for they knew he had spoken this parable against them.",
                "They watched him, and sent out spies, who pretended to be righteous, that they might trap him in something he said, so as to deliver him up to the power and authority of the governor.",
                "They asked him, \"Teacher, we know that you say and teach what is right, and aren\u2019t partial to anyone, but truly teach the way of God.",
                "Is it lawful for us to pay taxes to Caesar, or not?\"",
                "But he perceived their craftiness, and said to them, \"Why do you test me?",
                "Show me a denarius. Whose image and inscription are on it?\" They answered, \"Caesar\u2019s.\"",
                "He said to them, \"Then give to Caesar the things that are Caesar\u2019s, and to God the things that are God\u2019s.\"",
                "They weren\u2019t able to trap him in his words before the people. They marveled at his answer, and were silent.",
                "Some of the Sadducees came to him, those who deny that there is a resurrection.",
                "They asked him, \"Teacher, Moses wrote to us that if a man\u2019s brother dies having a wife, and he is childless, his brother should take the wife, and raise up children for his brother.",
                "There were therefore seven brothers. The first took a wife, and died childless.",
                "The second took her as wife, and he died childless.",
                "The third took her, and likewise the seven all left no children, and died.",
                "Afterward the woman also died.",
                "Therefore in the resurrection whose wife of them will she be? For the seven had her as a wife.\"",
                "Jesus said to them, \"The children of this age marry, and are given in marriage.",
                "But those who are considered worthy to attain to that age and the resurrection from the dead, neither marry, nor are given in marriage.",
                "For they can\u2019t die any more, for they are like the angels, and are children of God, being children of the resurrection.",
                "But that the dead are raised, even Moses showed at the bush, when he called the Lord \u2018The God of Abraham, the God of Isaac, and the God of Jacob.\u2019",
                "Now he is not the God of the dead, but of the living, for all are alive to him.\"",
                "Some of the scribes answered, \"Teacher, you speak well.\"",
                "They didn\u2019t dare to ask him any more questions.",
                "He said to them, \"Why do they say that the Christ is David\u2019s son?",
                "David himself says in the book of Psalms, \u2018The Lord said to my Lord, \"Sit at my right hand,",
                "until I make your enemies the footstool of your feet.\"\u2019",
                "\"David therefore calls him Lord, so how is he his son?\"",
                "In the hearing of all the people, he said to his disciples,",
                "\"Beware of the scribes, who like to walk in long robes, and love greetings in the marketplaces, the best seats in the synagogues, and the best places at feasts;",
                "who devour widows\u2019 houses, and for a pretense make long prayers: these will receive greater condemnation.\""
            ],
            21: [
                "He looked up, and saw the rich people who were putting their gifts into the treasury.",
                "He saw a certain poor widow casting in two small brass coins.",
                "He said, \"Truly I tell you, this poor widow put in more than all of them,",
                "for all these put in gifts for God from their abundance, but she, out of her poverty, put in all that she had to live on.\"",
                "As some were talking about the temple and how it was decorated with beautiful stones and gifts, he said,",
                "\"As for these things which you see, the days will come, in which there will not be left here one stone on another that will not be thrown down.\"",
                "They asked him, \"Teacher, so when will these things be? What is the sign that these things are about to happen?\"",
                "He said, \"Watch out that you don\u2019t get led astray, for many will come in my name, saying, \u2018I am he,\u2019 and, \u2018The time is at hand.\u2019 Therefore don\u2019t follow them.",
                "When you hear of wars and disturbances, don\u2019t be terrified, for these things must happen first, but the end won\u2019t come immediately.\"",
                "Then he said to them, \"Nation will rise against nation, and kingdom against kingdom.",
                "There will be great earthquakes, famines, and plagues in various places. There will be terrors and great signs from heaven.",
                "But before all these things, they will lay their hands on you and will persecute you, delivering you up to synagogues and prisons, bringing you before kings and governors for my name\u2019s sake.",
                "It will turn out as a testimony for you.",
                "Settle it therefore in your hearts not to meditate beforehand how to answer,",
                "for I will give you a mouth and wisdom which all your adversaries will not be able to withstand or to contradict.",
                "You will be handed over even by parents, brothers, relatives, and friends. They will cause some of you to be put to death.",
                "You will be hated by all men for my name\u2019s sake.",
                "And not a hair of your head will perish.",
                "\"By your endurance you will win your lives.",
                "\"But when you see Jerusalem surrounded by armies, then know that its desolation is at hand.",
                "Then let those who are in Judea flee to the mountains. Let those who are in the midst of her depart. Let those who are in the country not enter therein.",
                "For these are days of vengeance, that all things which are written may be fulfilled.",
                "Woe to those who are pregnant and to those who nurse infants in those days! For there will be great distress in the land, and wrath to this people.",
                "They will fall by the edge of the sword, and will be led captive into all the nations. Jerusalem will be trampled down by the Gentiles, until the times of the Gentiles are fulfilled.",
                "There will be signs in the sun, moon, and stars; and on the earth anxiety of nations, in perplexity for the roaring of the sea and the waves;",
                "men fainting for fear, and for expectation of the things which are coming on the world: for the powers of the heavens will be shaken.",
                "Then they will see the Son of Man coming in a cloud with power and great glory.",
                "But when these things begin to happen, look up, and lift up your heads, because your redemption is near.\"",
                "He told them a parable. \"See the fig tree, and all the trees.",
                "When they are already budding, you see it and know by your own selves that the summer is already near.",
                "Even so you also, when you see these things happening, know that the Kingdom of God is near.",
                "Most certainly I tell you, this generation will not pass away until all things are accomplished.",
                "Heaven and earth will pass away, but my words will by no means pass away.",
                "\"So be careful, or your hearts will be loaded down with carousing, drunkenness, and cares of this life, and that day will come on you suddenly.",
                "For it will come like a snare on all those who dwell on the surface of all the earth.",
                "Therefore be watchful all the time, praying that you may be counted worthy to escape all these things that will happen, and to stand before the Son of Man.\"",
                "Every day Jesus was teaching in the temple, and every night he would go out and spend the night on the mountain that is called Olivet.",
                "All the people came early in the morning to him in the temple to hear him."
            ],
            22: [
                "Now the feast of unleavened bread, which is called the Passover, drew near.",
                "The chief priests and the scribes sought how they might put him to death, for they feared the people.",
                "Satan entered into Judas, who was surnamed Iscariot, who was numbered with the twelve.",
                "He went away, and talked with the chief priests and captains about how he might deliver him to them.",
                "They were glad, and agreed to give him money.",
                "He consented, and sought an opportunity to deliver him to them in the absence of the multitude.",
                "The day of unleavened bread came, on which the Passover must be sacrificed.",
                "He sent Peter and John, saying, \"Go and prepare the Passover for us, that we may eat.\"",
                "They said to him, \"Where do you want us to prepare?\"",
                "He said to them, \"Behold, when you have entered into the city, a man carrying a pitcher of water will meet you. Follow him into the house which he enters.",
                "Tell the master of the house, \u2018The Teacher says to you, \"Where is the guest room, where I may eat the Passover with my disciples?\"\u2019",
                "He will show you a large, furnished upper room. Make preparations there.\"",
                "They went, found things as he had told them, and they prepared the Passover.",
                "When the hour had come, he sat down with the twelve apostles.",
                "He said to them, \"I have earnestly desired to eat this Passover with you before I suffer,",
                "for I tell you, I will no longer by any means eat of it until it is fulfilled in the Kingdom of God.\"",
                "He received a cup, and when he had given thanks, he said, \"Take this, and share it among yourselves,",
                "for I tell you, I will not drink at all again from the fruit of the vine, until the Kingdom of God comes.\"",
                "He took bread, and when he had given thanks, he broke it, and gave to them, saying, \"This is my body which is given for you. Do this in memory of me.\"",
                "Likewise, he took the cup after supper, saying, \"This cup is the new covenant in my blood, which is poured out for you.",
                "But behold, the hand of him who betrays me is with me on the table.",
                "The Son of Man indeed goes, as it has been determined, but woe to that man through whom he is betrayed!\"",
                "They began to question among themselves, which of them it was who would do this thing.",
                "There arose also a contention among them, which of them was considered to be greatest.",
                "He said to them, \"The kings of the nations lord it over them, and those who have authority over them are called \u2018benefactors.\u2019",
                "But not so with you. But one who is the greater among you, let him become as the younger, and one who is governing, as one who serves.",
                "For who is greater, one who sits at the table, or one who serves? Isn\u2019t it he who sits at the table? But I am in the midst of you as one who serves.",
                "But you are those who have continued with me in my trials.",
                "I confer on you a kingdom, even as my Father conferred on me,",
                "that you may eat and drink at my table in my Kingdom. You will sit on thrones, judging the twelve tribes of Israel.\"",
                "The Lord said, \"Simon, Simon, behold, Satan asked to have you, that he might sift you as wheat,",
                "but I prayed for you, that your faith wouldn\u2019t fail. You, when once you have turned again, establish your brothers.\"",
                "He said to him, \"Lord, I am ready to go with you both to prison and to death!\"",
                "He said, \"I tell you, Peter, the rooster will by no means crow today until you deny that you know me three times.\"",
                "He said to them, \"When I sent you out without purse, and wallet, and shoes, did you lack anything?\" They said, \"Nothing.\"",
                "Then he said to them, \"But now, whoever has a purse, let him take it, and likewise a wallet. Whoever has none, let him sell his cloak, and buy a sword.",
                "For I tell you that this which is written must still be fulfilled in me: \u2018He was counted with transgressors.\u2019 For that which concerns me has an end.\"",
                "They said, \"Lord, behold, here are two swords.\" He said to them, \"That is enough.\"",
                "He came out, and went, as his custom was, to the Mount of Olives. His disciples also followed him.",
                "When he was at the place, he said to them, \"Pray that you don\u2019t enter into temptation.\"",
                "He was withdrawn from them about a stone\u2019s throw, and he knelt down and prayed,",
                "saying, \"Father, if you are willing, remove this cup from me. Nevertheless, not my will, but yours, be done.\"",
                "An angel from heaven appeared to him, strengthening him.",
                "Being in agony he prayed more earnestly. His sweat became like great drops of blood falling down on the ground.",
                "When he rose up from his prayer, he came to the disciples, and found them sleeping because of grief,",
                "and said to them, \"Why do you sleep? Rise and pray that you may not enter into temptation.\"",
                "While he was still speaking, behold, a multitude, and he who was called Judas, one of the twelve, was leading them. He came near to Jesus to kiss him.",
                "But Jesus said to him, \"Judas, do you betray the Son of Man with a kiss?\"",
                "When those who were around him saw what was about to happen, they said to him, \"Lord, shall we strike with the sword?\"",
                "A certain one of them struck the servant of the high priest, and cut off his right ear.",
                "But Jesus answered, \"Let me at least do this\"\u2014and he touched his ear, and healed him.",
                "Jesus said to the chief priests, captains of the temple, and elders, who had come against him, \"Have you come out as against a robber, with swords and clubs?",
                "When I was with you in the temple daily, you didn\u2019t stretch out your hands against me. But this is your hour, and the power of darkness.\"",
                "They seized him, and led him away, and brought him into the high priest\u2019s house. But Peter followed from a distance.",
                "When they had kindled a fire in the middle of the courtyard, and had sat down together, Peter sat among them.",
                "A certain servant girl saw him as he sat in the light, and looking intently at him, said, \"This man also was with him.\"",
                "He denied Jesus, saying, \"Woman, I don\u2019t know him.\"",
                "After a little while someone else saw him, and said, \"You also are one of them!\" But Peter answered, \"Man, I am not!\"",
                "After about one hour passed, another confidently affirmed, saying, \"Truly this man also was with him, for he is a Galilean!\"",
                "But Peter said, \"Man, I don\u2019t know what you are talking about!\" Immediately, while he was still speaking, a rooster crowed.",
                "The Lord turned, and looked at Peter. Then Peter remembered the Lord\u2019s word, how he said to him, \"Before the rooster crows you will deny me three times.\"",
                "He went out, and wept bitterly.",
                "The men who held Jesus mocked him and beat him.",
                "Having blindfolded him, they struck him on the face and asked him, \"Prophesy! Who is the one who struck you?\"",
                "They spoke many other things against him, insulting him.",
                "As soon as it was day, the assembly of the elders of the people was gathered together, both chief priests and scribes, and they led him away into their council, saying,",
                "\"If you are the Christ, tell us.\" But he said to them, \"If I tell you, you won\u2019t believe,",
                "and if I ask, you will in no way answer me or let me go.",
                "From now on, the Son of Man will be seated at the right hand of the power of God.\"",
                "They all said, \"Are you then the Son of God?\" He said to them, \"You say it, because I am.\"",
                "They said, \"Why do we need any more witness? For we ourselves have heard from his own mouth!\""
            ],
            23: [
                "The whole company of them rose up and brought him before Pilate.",
                "They began to accuse him, saying, \"We found this man perverting the nation, forbidding paying taxes to Caesar, and saying that he himself is Christ, a king.\"",
                "Pilate asked him, \"Are you the King of the Jews?\" He answered him, \"So you say.\"",
                "Pilate said to the chief priests and the multitudes, \"I find no basis for a charge against this man.\"",
                "But they insisted, saying, \"He stirs up the people, teaching throughout all Judea, beginning from Galilee even to this place.\"",
                "But when Pilate heard Galilee mentioned, he asked if the man was a Galilean.",
                "When he found out that he was in Herod\u2019s jurisdiction, he sent him to Herod, who was also in Jerusalem during those days.",
                "Now when Herod saw Jesus, he was exceedingly glad, for he had wanted to see him for a long time, because he had heard many things about him. He hoped to see some miracle done by him.",
                "He questioned him with many words, but he gave no answers.",
                "The chief priests and the scribes stood, vehemently accusing him.",
                "Herod with his soldiers humiliated him and mocked him. Dressing him in luxurious clothing, they sent him back to Pilate.",
                "Herod and Pilate became friends with each other that very day, for before that they were enemies with each other.",
                "Pilate called together the chief priests and the rulers and the people,",
                "and said to them, \"You brought this man to me as one that perverts the people, and see, I have examined him before you, and found no basis for a charge against this man concerning those things of which you accuse him.",
                "Neither has Herod, for I sent you to him, and see, nothing worthy of death has been done by him.",
                "I will therefore chastise him and release him.\"",
                "Now he had to release one prisoner to them at the feast.",
                "But they all cried out together, saying, \"Away with this man! Release to us Barabbas!\"\u2014",
                "one who was thrown into prison for a certain revolt in the city, and for murder.",
                "Then Pilate spoke to them again, wanting to release Jesus,",
                "but they shouted, saying, \"Crucify! Crucify him!\"",
                "He said to them the third time, \"Why? What evil has this man done? I have found no capital crime in him. I will therefore chastise him and release him.\"",
                "But they were urgent with loud voices, asking that he might be crucified. Their voices and the voices of the chief priests prevailed.",
                "Pilate decreed that what they asked for should be done.",
                "He released him who had been thrown into prison for insurrection and murder, for whom they asked, but he delivered Jesus up to their will.",
                "When they led him away, they grabbed one Simon of Cyrene, coming from the country, and laid on him the cross, to carry it after Jesus.",
                "A great multitude of the people followed him, including women who also mourned and lamented him.",
                "But Jesus, turning to them, said, \"Daughters of Jerusalem, don\u2019t weep for me, but weep for yourselves and for your children.",
                "For behold, the days are coming in which they will say, \u2018Blessed are the barren, the wombs that never bore, and the breasts that never nursed.\u2019",
                "Then they will begin to tell the mountains, \u2018Fall on us!\u2019 and tell the hills, \u2018Cover us.\u2019",
                "For if they do these things in the green tree, what will be done in the dry?\"",
                "There were also others, two criminals, led with him to be put to death.",
                "When they came to the place that is called The Skull, they crucified him there with the criminals, one on the right and the other on the left.",
                "Jesus said, \"Father, forgive them, for they don\u2019t know what they are doing.\" Dividing his garments among them, they cast lots.",
                "The people stood watching. The rulers with them also scoffed at him, saying, \"He saved others. Let him save himself, if this is the Christ of God, his chosen one!\"",
                "The soldiers also mocked him, coming to him and offering him vinegar,",
                "and saying, \"If you are the King of the Jews, save yourself!\"",
                "An inscription was also written over him in letters of Greek, Latin, and Hebrew: \"THIS IS THE KING OF THE JEWS.\"",
                "One of the criminals who was hanged insulted him, saying, \"If you are the Christ, save yourself and us!\"",
                "But the other answered, and rebuking him said, \"Don\u2019t you even fear God, seeing you are under the same condemnation?",
                "And we indeed justly, for we receive the due reward for our deeds, but this man has done nothing wrong.\"",
                "He said to Jesus, \"Lord, remember me when you come into your Kingdom.\"",
                "Jesus said to him, \"Assuredly I tell you, today you will be with me in Paradise.\"",
                "It was now about the sixth hour, and darkness came over the whole land until the ninth hour.",
                "The sun was darkened, and the veil of the temple was torn in two.",
                "Jesus, crying with a loud voice, said, \"Father, into your hands I commit my spirit!\" Having said this, he breathed his last.",
                "When the centurion saw what was done, he glorified God, saying, \"Certainly this was a righteous man.\"",
                "All the multitudes that came together to see this, when they saw the things that were done, returned home beating their breasts.",
                "All his acquaintances, and the women who followed with him from Galilee, stood at a distance, watching these things.",
                "Behold, a man named Joseph, who was a member of the council, a good and righteous man",
                "(he had not consented to their counsel and deed), from Arimathaea, a city of the Jews, who was also waiting for the Kingdom of God:",
                "this man went to Pilate, and asked for Jesus\u2019 body.",
                "He took it down, and wrapped it in a linen cloth, and laid him in a tomb that was cut in stone, where no one had ever been laid.",
                "It was the day of the Preparation, and the Sabbath was drawing near.",
                "The women, who had come with him out of Galilee, followed after, and saw the tomb, and how his body was laid.",
                "They returned, and prepared spices and ointments. On the Sabbath they rested according to the commandment."
            ],
            24: [
                "But on the first day of the week, at early dawn, they and some others came to the tomb, bringing the spices which they had prepared.",
                "They found the stone rolled away from the tomb.",
                "They entered in, and didn\u2019t find the Lord Jesus\u2019 body.",
                "It happened, while they were greatly perplexed about this, behold, two men stood by them in dazzling clothing.",
                "Becoming terrified, they bowed their faces down to the earth. They said to them, \"Why do you seek the living among the dead?",
                "He isn\u2019t here, but is risen. Remember what he told you when he was still in Galilee,",
                "saying that the Son of Man must be delivered up into the hands of sinful men, and be crucified, and the third day rise again?\"",
                "They remembered his words,",
                "returned from the tomb, and told all these things to the eleven, and to all the rest.",
                "Now they were Mary Magdalene, Joanna, and Mary the mother of James. The other women with them told these things to the apostles.",
                "These words seemed to them to be nonsense, and they didn\u2019t believe them.",
                "But Peter got up and ran to the tomb. Stooping and looking in, he saw the strips of linen lying by themselves, and he departed to his home, wondering what had happened.",
                "Behold, two of them were going that very day to a village named Emmaus, which was sixty stadia from Jerusalem.",
                "They talked with each other about all of these things which had happened.",
                "It happened, while they talked and questioned together, that Jesus himself came near, and went with them.",
                "But their eyes were kept from recognizing him.",
                "He said to them, \"What are you talking about as you walk, and are sad?\"",
                "One of them, named Cleopas, answered him, \"Are you the only stranger in Jerusalem who doesn\u2019t know the things which have happened there in these days?\"",
                "He said to them, \"What things?\" They said to him, \"The things concerning Jesus, the Nazarene, who was a prophet mighty in deed and word before God and all the people;",
                "and how the chief priests and our rulers delivered him up to be condemned to death, and crucified him.",
                "But we were hoping that it was he who would redeem Israel. Yes, and besides all this, it is now the third day since these things happened.",
                "Also, certain women of our company amazed us, having arrived early at the tomb;",
                "and when they didn\u2019t find his body, they came saying that they had also seen a vision of angels, who said that he was alive.",
                "Some of us went to the tomb, and found it just like the women had said, but they didn\u2019t see him.\"",
                "He said to them, \"Foolish men, and slow of heart to believe in all that the prophets have spoken!",
                "Didn\u2019t the Christ have to suffer these things and to enter into his glory?\"",
                "Beginning from Moses and from all the prophets, he explained to them in all the Scriptures the things concerning himself.",
                "They drew near to the village, where they were going, and he acted like he would go further.",
                "They urged him, saying, \"Stay with us, for it is almost evening, and the day is almost over.\" He went in to stay with them.",
                "It happened, that when he had sat down at the table with them, he took the bread and gave thanks. Breaking it, he gave to them.",
                "Their eyes were opened, and they recognized him, and he vanished out of their sight.",
                "They said one to another, \"Weren\u2019t our hearts burning within us, while he spoke to us along the way, and while he opened the Scriptures to us?\"",
                "They rose up that very hour, returned to Jerusalem, and found the eleven gathered together, and those who were with them,",
                "saying, \"The Lord is risen indeed, and has appeared to Simon!\"",
                "They related the things that happened along the way, and how he was recognized by them in the breaking of the bread.",
                "As they said these things, Jesus himself stood among them, and said to them, \"Peace be to you.\"",
                "But they were terrified and filled with fear, and supposed that they had seen a spirit.",
                "He said to them, \"Why are you troubled? Why do doubts arise in your hearts?",
                "See my hands and my feet, that it is truly me. Touch me and see, for a spirit doesn\u2019t have flesh and bones, as you see that I have.\"",
                "When he had said this, he showed them his hands and his feet.",
                "While they still didn\u2019t believe for joy, and wondered, he said to them, \"Do you have anything here to eat?\"",
                "They gave him a piece of a broiled fish and some honeycomb.",
                "He took them, and ate in front of them.",
                "He said to them, \"This is what I told you, while I was still with you, that all things which are written in the law of Moses, the prophets, and the psalms, concerning me must be fulfilled.\"",
                "Then he opened their minds, that they might understand the Scriptures.",
                "He said to them, \"Thus it is written, and thus it was necessary for the Christ to suffer and to rise from the dead the third day,",
                "and that repentance and remission of sins should be preached in his name to all the nations, beginning at Jerusalem.",
                "You are witnesses of these things.",
                "Behold, I send forth the promise of my Father on you. But wait in the city of Jerusalem until you are clothed with power from on high.\"",
                "He led them out as far as Bethany, and he lifted up his hands, and blessed them.",
                "It happened, while he blessed them, that he withdrew from them, and was carried up into heaven.",
                "They worshiped him, and returned to Jerusalem with great joy,",
                "and were continually in the temple, praising and blessing God. Amen."
            ]
        },
        43: {
            1: [
                "In the beginning was the Word, and the Word was with God, and the Word was God.",
                "The same was in the beginning with God.",
                "All things were made through him. Without him was not anything made that has been made.",
                "In him was life, and the life was the light of men.",
                "The light shines in the darkness, and the darkness hasn\u2019t overcome it.",
                "There came a man, sent from God, whose name was John.",
                "The same came as a witness, that he might testify about the light, that all might believe through him.",
                "He was not the light, but was sent that he might testify about the light.",
                "The true light that enlightens everyone was coming into the world.",
                "He was in the world, and the world was made through him, and the world didn\u2019t recognize him.",
                "He came to his own, and those who were his own didn\u2019t receive him.",
                "But as many as received him, to them he gave the right to become God\u2019s children, to those who believe in his name:",
                "who were born not of blood, nor of the will of the flesh, nor of the will of man, but of God.",
                "The Word became flesh, and lived among us. We saw his glory, such glory as of the one and only Son of the Father, full of grace and truth.",
                "John testified about him. He cried out, saying, \"This was he of whom I said, \u2018He who comes after me has surpassed me, for he was before me.\u2019\"",
                "From his fullness we all received grace upon grace.",
                "For the law was given through Moses. Grace and truth were realized through Jesus Christ.",
                "No one has seen God at any time. The one and only Son, who is in the bosom of the Father, he has declared him.",
                "This is John\u2019s testimony, when the Jews sent priests and Levites from Jerusalem to ask him, \"Who are you?\"",
                "He declared, and didn\u2019t deny, but he declared, \"I am not the Christ.\"",
                "They asked him, \"What then? Are you Elijah?\" He said, \"I am not.\" \"Are you the prophet?\" He answered, \"No.\"",
                "They said therefore to him, \"Who are you? Give us an answer to take back to those who sent us. What do you say about yourself?\"",
                "He said, \"I am the voice of one crying in the wilderness, \u2018Make straight the way of the Lord,\u2019 as Isaiah the prophet said.\"",
                "The ones who had been sent were from the Pharisees.",
                "They asked him, \"Why then do you baptize, if you are not the Christ, nor Elijah, nor the prophet?\"",
                "John answered them, \"I baptize in water, but among you stands one whom you don\u2019t know.",
                "He is the one who comes after me, who is preferred before me, whose sandal strap I\u2019m not worthy to loosen.\"",
                "These things were done in Bethany beyond the Jordan, where John was baptizing.",
                "The next day, he saw Jesus coming to him, and said, \"Behold, the Lamb of God, who takes away the sin of the world!",
                "This is he of whom I said, \u2018After me comes a man who is preferred before me, for he was before me.\u2019",
                "I didn\u2019t know him, but for this reason I came baptizing in water: that he would be revealed to Israel.\"",
                "John testified, saying, \"I have seen the Spirit descending like a dove out of heaven, and it remained on him.",
                "I didn\u2019t recognize him, but he who sent me to baptize in water, he said to me, \u2018On whomever you will see the Spirit descending, and remaining on him, the same is he who baptizes in the Holy Spirit.\u2019",
                "I have seen, and have testified that this is the Son of God.\"",
                "Again, the next day, John was standing with two of his disciples,",
                "and he looked at Jesus as he walked, and said, \"Behold, the Lamb of God!\"",
                "The two disciples heard him speak, and they followed Jesus.",
                "Jesus turned, and saw them following, and said to them, \"What are you looking for?\" They said to him, \"Rabbi\" (which is to say, being interpreted, Teacher), \"where are you staying?\"",
                "He said to them, \"Come, and see.\" They came and saw where he was staying, and they stayed with him that day. It was about the tenth hour.",
                "One of the two who heard John, and followed him, was Andrew, Simon Peter\u2019s brother.",
                "He first found his own brother, Simon, and said to him, \"We have found the Messiah!\" (which is, being interpreted, Christ).",
                "He brought him to Jesus. Jesus looked at him, and said, \"You are Simon the son of Jonah. You shall be called Cephas\" (which is by interpretation, Peter).",
                "On the next day, he was determined to go out into Galilee, and he found Philip. Jesus said to him, \"Follow me.\"",
                "Now Philip was from Bethsaida, of the city of Andrew and Peter.",
                "Philip found Nathanael, and said to him, \"We have found him, of whom Moses in the law, and the prophets, wrote: Jesus of Nazareth, the son of Joseph.\"",
                "Nathanael said to him, \"Can any good thing come out of Nazareth?\" Philip said to him, \"Come and see.\"",
                "Jesus saw Nathanael coming to him, and said about him, \"Behold, an Israelite indeed, in whom is no deceit!\"",
                "Nathanael said to him, \"How do you know me?\" Jesus answered him, \"Before Philip called you, when you were under the fig tree, I saw you.\"",
                "Nathanael answered him, \"Rabbi, you are the Son of God! You are King of Israel!\"",
                "Jesus answered him, \"Because I told you, \u2018I saw you underneath the fig tree,\u2019 do you believe? You will see greater things than these!\"",
                "He said to him, \"Most certainly, I tell you, hereafter you will see heaven opened, and the angels of God ascending and descending on the Son of Man.\""
            ],
            2: [
                "The third day, there was a marriage in Cana of Galilee. Jesus\u2019 mother was there.",
                "Jesus also was invited, with his disciples, to the marriage.",
                "When the wine ran out, Jesus\u2019 mother said to him, \"They have no wine.\"",
                "Jesus said to her, \"Woman, what does that have to do with you and me? My hour has not yet come.\"",
                "His mother said to the servants, \"Whatever he says to you, do it.\"",
                "Now there were six water pots of stone set there after the Jews\u2019 way of purifying, containing two or three metretes apiece.",
                "Jesus said to them, \"Fill the water pots with water.\" They filled them up to the brim.",
                "He said to them, \"Now draw some out, and take it to the ruler of the feast.\" So they took it.",
                "When the ruler of the feast tasted the water now become wine, and didn\u2019t know where it came from (but the servants who had drawn the water knew), the ruler of the feast called the bridegroom,",
                "and said to him, \"Everyone serves the good wine first, and when the guests have drunk freely, then that which is worse. You have kept the good wine until now!\"",
                "This beginning of his signs Jesus did in Cana of Galilee, and revealed his glory; and his disciples believed in him.",
                "After this, he went down to Capernaum, he, and his mother, his brothers, and his disciples; and they stayed there a few days.",
                "The Passover of the Jews was at hand, and Jesus went up to Jerusalem.",
                "He found in the temple those who sold oxen, sheep, and doves, and the changers of money sitting.",
                "He made a whip of cords, and threw all out of the temple, both the sheep and the oxen; and he poured out the changers\u2019 money, and overthrew their tables.",
                "To those who sold the doves, he said, \"Take these things out of here! Don\u2019t make my Father\u2019s house a marketplace!\"",
                "His disciples remembered that it was written, \"Zeal for your house will eat me up.\"",
                "The Jews therefore answered him, \"What sign do you show us, seeing that you do these things?\"",
                "Jesus answered them, \"Destroy this temple, and in three days I will raise it up.\"",
                "The Jews therefore said, \"It took forty-six years to build this temple! Will you raise it up in three days?\"",
                "But he spoke of the temple of his body.",
                "When therefore he was raised from the dead, his disciples remembered that he said this, and they believed the Scripture, and the word which Jesus had said.",
                "Now when he was in Jerusalem at the Passover, during the feast, many believed in his name, observing his signs which he did.",
                "But Jesus didn\u2019t trust himself to them, because he knew everyone,",
                "and because he didn\u2019t need for anyone to testify concerning man; for he himself knew what was in man."
            ],
            3: [
                "Now there was a man of the Pharisees named Nicodemus, a ruler of the Jews.",
                "The same came to him by night, and said to him, \"Rabbi, we know that you are a teacher come from God, for no one can do these signs that you do, unless God is with him.\"",
                "Jesus answered him, \"Most certainly, I tell you, unless one is born anew, he can\u2019t see the Kingdom of God.\"",
                "Nicodemus said to him, \"How can a man be born when he is old? Can he enter a second time into his mother\u2019s womb, and be born?\"",
                "Jesus answered, \"Most certainly I tell you, unless one is born of water and spirit, he can\u2019t enter into the Kingdom of God!",
                "That which is born of the flesh is flesh. That which is born of the Spirit is spirit.",
                "Don\u2019t marvel that I said to you, \u2018You must be born anew.\u2019",
                "The wind blows where it wants to, and you hear its sound, but don\u2019t know where it comes from and where it is going. So is everyone who is born of the Spirit.\"",
                "Nicodemus answered him, \"How can these things be?\"",
                "Jesus answered him, \"Are you the teacher of Israel, and don\u2019t understand these things?",
                "Most certainly I tell you, we speak that which we know, and testify of that which we have seen, and you don\u2019t receive our witness.",
                "If I told you earthly things and you don\u2019t believe, how will you believe if I tell you heavenly things?",
                "No one has ascended into heaven, but he who descended out of heaven, the Son of Man, who is in heaven.",
                "As Moses lifted up the serpent in the wilderness, even so must the Son of Man be lifted up,",
                "that whoever believes in him should not perish, but have eternal life.",
                "For God so loved the world, that he gave his one and only Son, that whoever believes in him should not perish, but have eternal life.",
                "For God didn\u2019t send his Son into the world to judge the world, but that the world should be saved through him.",
                "He who believes in him is not judged. He who doesn\u2019t believe has been judged already, because he has not believed in the name of the one and only Son of God.",
                "This is the judgment, that the light has come into the world, and men loved the darkness rather than the light; for their works were evil.",
                "For everyone who does evil hates the light, and doesn\u2019t come to the light, lest his works would be exposed.",
                "But he who does the truth comes to the light, that his works may be revealed, that they have been done in God.\"",
                "After these things, Jesus came with his disciples into the land of Judea. He stayed there with them, and baptized.",
                "John also was baptizing in Enon near Salim, because there was much water there. They came, and were baptized.",
                "For John was not yet thrown into prison.",
                "There arose therefore a questioning on the part of John\u2019s disciples with some Jews about purification.",
                "They came to John, and said to him, \"Rabbi, he who was with you beyond the Jordan, to whom you have testified, behold, the same baptizes, and everyone is coming to him.\"",
                "John answered, \"A man can receive nothing, unless it has been given him from heaven.",
                "You yourselves testify that I said, \u2018I am not the Christ,\u2019 but, \u2018I have been sent before him.\u2019",
                "He who has the bride is the bridegroom; but the friend of the bridegroom, who stands and hears him, rejoices greatly because of the bridegroom\u2019s voice. This, my joy, therefore is made full.",
                "He must increase, but I must decrease.",
                "He who comes from above is above all. He who is from the earth belongs to the earth, and speaks of the earth. He who comes from heaven is above all.",
                "What he has seen and heard, of that he testifies; and no one receives his witness.",
                "He who has received his witness has set his seal to this, that God is true.",
                "For he whom God has sent speaks the words of God; for God gives the Spirit without measure.",
                "The Father loves the Son, and has given all things into his hand.",
                "One who believes in the Son has eternal life, but one who disobeys the Son won\u2019t see life, but the wrath of God remains on him.\""
            ],
            4: [
                "Therefore when the Lord knew that the Pharisees had heard that Jesus was making and baptizing more disciples than John",
                "(although Jesus himself didn\u2019t baptize, but his disciples),",
                "he left Judea, and departed into Galilee.",
                "He needed to pass through Samaria.",
                "So he came to a city of Samaria, called Sychar, near the parcel of ground that Jacob gave to his son, Joseph.",
                "Jacob\u2019s well was there. Jesus therefore, being tired from his journey, sat down by the well. It was about the sixth hour.",
                "A woman of Samaria came to draw water. Jesus said to her, \"Give me a drink.\"",
                "For his disciples had gone away into the city to buy food.",
                "The Samaritan woman therefore said to him, \"How is it that you, being a Jew, ask for a drink from me, a Samaritan woman?\" (For Jews have no dealings with Samaritans.)",
                "Jesus answered her, \"If you knew the gift of God, and who it is who says to you, \u2018Give me a drink,\u2019 you would have asked him, and he would have given you living water.\"",
                "The woman said to him, \"Sir, you have nothing to draw with, and the well is deep. From where then have you that living water?",
                "Are you greater than our father, Jacob, who gave us the well, and drank of it himself, as did his children, and his livestock?\"",
                "Jesus answered her, \"Everyone who drinks of this water will thirst again,",
                "but whoever drinks of the water that I will give him will never thirst again; but the water that I will give him will become in him a well of water springing up to eternal life.\"",
                "The woman said to him, \"Sir, give me this water, so that I don\u2019t get thirsty, neither come all the way here to draw.\"",
                "Jesus said to her, \"Go, call your husband, and come here.\"",
                "The woman answered, \"I have no husband.\" Jesus said to her, \"You said well, \u2018I have no husband,\u2019",
                "for you have had five husbands; and he whom you now have is not your husband. This you have said truly.\"",
                "The woman said to him, \"Sir, I perceive that you are a prophet.",
                "Our fathers worshiped in this mountain, and you Jews say that in Jerusalem is the place where people ought to worship.\"",
                "Jesus said to her, \"Woman, believe me, the hour comes, when neither in this mountain, nor in Jerusalem, will you worship the Father.",
                "You worship that which you don\u2019t know. We worship that which we know; for salvation is from the Jews.",
                "But the hour comes, and now is, when the true worshippers will worship the Father in spirit and truth, for the Father seeks such to be his worshippers.",
                "God is spirit, and those who worship him must worship in spirit and truth.\"",
                "The woman said to him, \"I know that Messiah comes,\" (he who is called Christ). \"When he has come, he will declare to us all things.\"",
                "Jesus said to her, \"I am he, the one who speaks to you.\"",
                "At this, his disciples came. They marveled that he was speaking with a woman; yet no one said, \"What are you looking for?\" or, \"Why do you speak with her?\"",
                "So the woman left her water pot, and went away into the city, and said to the people,",
                "\"Come, see a man who told me everything that I did. Can this be the Christ?\"",
                "They went out of the city, and were coming to him.",
                "In the meanwhile, the disciples urged him, saying, \"Rabbi, eat.\"",
                "But he said to them, \"I have food to eat that you don\u2019t know about.\"",
                "The disciples therefore said one to another, \"Has anyone brought him something to eat?\"",
                "Jesus said to them, \"My food is to do the will of him who sent me, and to accomplish his work.",
                "Don\u2019t you say, \u2018There are yet four months until the harvest?\u2019 Behold, I tell you, lift up your eyes, and look at the fields, that they are white for harvest already.",
                "He who reaps receives wages, and gathers fruit to eternal life; that both he who sows and he who reaps may rejoice together.",
                "For in this the saying is true, \u2018One sows, and another reaps.\u2019",
                "I sent you to reap that for which you haven\u2019t labored. Others have labored, and you have entered into their labor.\"",
                "From that city many of the Samaritans believed in him because of the word of the woman, who testified, \"He told me everything that I did.\"",
                "So when the Samaritans came to him, they begged him to stay with them. He stayed there two days.",
                "Many more believed because of his word.",
                "They said to the woman, \"Now we believe, not because of your speaking; for we have heard for ourselves, and know that this is indeed the Christ, the Savior of the world.\"",
                "After the two days he went out from there and went into Galilee.",
                "For Jesus himself testified that a prophet has no honor in his own country.",
                "So when he came into Galilee, the Galileans received him, having seen all the things that he did in Jerusalem at the feast, for they also went to the feast.",
                "Jesus came therefore again to Cana of Galilee, where he made the water into wine. There was a certain nobleman whose son was sick at Capernaum.",
                "When he heard that Jesus had come out of Judea into Galilee, he went to him, and begged him that he would come down and heal his son, for he was at the point of death.",
                "Jesus therefore said to him, \"Unless you see signs and wonders, you will in no way believe.\"",
                "The nobleman said to him, \"Sir, come down before my child dies.\"",
                "Jesus said to him, \"Go your way. Your son lives.\" The man believed the word that Jesus spoke to him, and he went his way.",
                "As he was now going down, his servants met him and reported, saying \"Your child lives!\"",
                "So he inquired of them the hour when he began to get better. They said therefore to him, \"Yesterday at the seventh hour, the fever left him.\"",
                "So the father knew that it was at that hour in which Jesus said to him, \"Your son lives.\" He believed, as did his whole house.",
                "This is again the second sign that Jesus did, having come out of Judea into Galilee."
            ],
            5: [
                "After these things, there was a feast of the Jews, and Jesus went up to Jerusalem.",
                "Now in Jerusalem by the sheep gate, there is a pool, which is called in Hebrew, \"Bethesda,\" having five porches.",
                "In these lay a great multitude of those who were sick, blind, lame, or paralyzed, waiting for the moving of the water;",
                "for an angel went down at certain times into the pool, and stirred up the water. Whoever stepped in first after the stirring of the water was made whole of whatever disease he had.",
                "A certain man was there, who had been sick for thirty-eight years.",
                "When Jesus saw him lying there, and knew that he had been sick for a long time, he asked him, \"Do you want to be made well?\"",
                "The sick man answered him, \"Sir, I have no one to put me into the pool when the water is stirred up, but while I\u2019m coming, another steps down before me.\"",
                "Jesus said to him, \"Arise, take up your mat, and walk.\"",
                "Immediately, the man was made well, and took up his mat and walked. Now it was the Sabbath on that day.",
                "So the Jews said to him who was cured, \"It is the Sabbath. It is not lawful for you to carry the mat.\"",
                "He answered them, \"He who made me well, the same said to me, \u2018Take up your mat, and walk.\u2019\"",
                "Then they asked him, \"Who is the man who said to you, \u2018Take up your mat, and walk\u2019?\"",
                "But he who was healed didn\u2019t know who it was, for Jesus had withdrawn, a crowd being in the place.",
                "Afterward Jesus found him in the temple, and said to him, \"Behold, you are made well. Sin no more, so that nothing worse happens to you.\"",
                "The man went away, and told the Jews that it was Jesus who had made him well.",
                "For this cause the Jews persecuted Jesus, and sought to kill him, because he did these things on the Sabbath.",
                "But Jesus answered them, \"My Father is still working, so I am working, too.\"",
                "For this cause therefore the Jews sought all the more to kill him, because he not only broke the Sabbath, but also called God his own Father, making himself equal with God.",
                "Jesus therefore answered them, \"Most certainly, I tell you, the Son can do nothing of himself, but what he sees the Father doing. For whatever things he does, these the Son also does likewise.",
                "For the Father has affection for the Son, and shows him all things that he himself does. He will show him greater works than these, that you may marvel.",
                "For as the Father raises the dead and gives them life, even so the Son also gives life to whom he desires.",
                "For the Father judges no one, but he has given all judgment to the Son,",
                "that all may honor the Son, even as they honor the Father. He who doesn\u2019t honor the Son doesn\u2019t honor the Father who sent him.",
                "\"Most certainly I tell you, he who hears my word, and believes him who sent me, has eternal life, and doesn\u2019t come into judgment, but has passed out of death into life.",
                "Most certainly, I tell you, the hour comes, and now is, when the dead will hear the Son of God\u2019s voice; and those who hear will live.",
                "For as the Father has life in himself, even so he gave to the Son also to have life in himself.",
                "He also gave him authority to execute judgment, because he is a son of man.",
                "Don\u2019t marvel at this, for the hour comes, in which all that are in the tombs will hear his voice,",
                "and will come out; those who have done good, to the resurrection of life; and those who have done evil, to the resurrection of judgment.",
                "I can of myself do nothing. As I hear, I judge, and my judgment is righteous; because I don\u2019t seek my own will, but the will of my Father who sent me.",
                "\"If I testify about myself, my witness is not valid.",
                "It is another who testifies about me. I know that the testimony which he testifies about me is true.",
                "You have sent to John, and he has testified to the truth.",
                "But the testimony which I receive is not from man. However, I say these things that you may be saved.",
                "He was the burning and shining lamp, and you were willing to rejoice for a while in his light.",
                "But the testimony which I have is greater than that of John, for the works which the Father gave me to accomplish, the very works that I do, testify about me, that the Father has sent me.",
                "The Father himself, who sent me, has testified about me. You have neither heard his voice at any time, nor seen his form.",
                "You don\u2019t have his word living in you; because you don\u2019t believe him whom he sent.",
                "\"You search the Scriptures, because you think that in them you have eternal life; and these are they which testify about me.",
                "Yet you will not come to me, that you may have life.",
                "I don\u2019t receive glory from men.",
                "But I know you, that you don\u2019t have God\u2019s love in yourselves.",
                "I have come in my Father\u2019s name, and you don\u2019t receive me. If another comes in his own name, you will receive him.",
                "How can you believe, who receive glory from one another, and you don\u2019t seek the glory that comes from the only God?",
                "\"Don\u2019t think that I will accuse you to the Father. There is one who accuses you, even Moses, on whom you have set your hope.",
                "For if you believed Moses, you would believe me; for he wrote about me.",
                "But if you don\u2019t believe his writings, how will you believe my words?\""
            ],
            6: [
                "After these things, Jesus went away to the other side of the sea of Galilee, which is also called the Sea of Tiberias.",
                "A great multitude followed him, because they saw his signs which he did on those who were sick.",
                "Jesus went up into the mountain, and he sat there with his disciples.",
                "Now the Passover, the feast of the Jews, was at hand.",
                "Jesus therefore lifting up his eyes, and seeing that a great multitude was coming to him, said to Philip, \"Where are we to buy bread, that these may eat?\"",
                "This he said to test him, for he himself knew what he would do.",
                "Philip answered him, \"Two hundred denarii worth of bread is not sufficient for them, that everyone of them may receive a little.\"",
                "One of his disciples, Andrew, Simon Peter\u2019s brother, said to him,",
                "\"There is a boy here who has five barley loaves and two fish, but what are these among so many?\"",
                "Jesus said, \"Have the people sit down.\" Now there was much grass in that place. So the men sat down, in number about five thousand.",
                "Jesus took the loaves; and having given thanks, he distributed to the disciples, and the disciples to those who were sitting down; likewise also of the fish as much as they desired.",
                "When they were filled, he said to his disciples, \"Gather up the broken pieces which are left over, that nothing be lost.\"",
                "So they gathered them up, and filled twelve baskets with broken pieces from the five barley loaves, which were left over by those who had eaten.",
                "When therefore the people saw the sign which Jesus did, they said, \"This is truly the prophet who comes into the world.\"",
                "Jesus therefore, perceiving that they were about to come and take him by force, to make him king, withdrew again to the mountain by himself.",
                "When evening came, his disciples went down to the sea,",
                "and they entered into the boat, and were going over the sea to Capernaum. It was now dark, and Jesus had not come to them.",
                "The sea was tossed by a great wind blowing.",
                "When therefore they had rowed about twenty-five or thirty stadia, they saw Jesus walking on the sea, and drawing near to the boat; and they were afraid.",
                "But he said to them, \"It is I. Don\u2019t be afraid.\"",
                "They were willing therefore to receive him into the boat. Immediately the boat was at the land where they were going.",
                "On the next day, the multitude that stood on the other side of the sea saw that there was no other boat there, except the one in which his disciples had embarked, and that Jesus hadn\u2019t entered with his disciples into the boat, but his disciples had gone away alone.",
                "However boats from Tiberias came near to the place where they ate the bread after the Lord had given thanks.",
                "When the multitude therefore saw that Jesus wasn\u2019t there, nor his disciples, they themselves got into the boats, and came to Capernaum, seeking Jesus.",
                "When they found him on the other side of the sea, they asked him, \"Rabbi, when did you come here?\"",
                "Jesus answered them, \"Most certainly I tell you, you seek me, not because you saw signs, but because you ate of the loaves, and were filled.",
                "Don\u2019t work for the food which perishes, but for the food which remains to eternal life, which the Son of Man will give to you. For God the Father has sealed him.\"",
                "They said therefore to him, \"What must we do, that we may work the works of God?\"",
                "Jesus answered them, \"This is the work of God, that you believe in him whom he has sent.\"",
                "They said therefore to him, \"What then do you do for a sign, that we may see, and believe you? What work do you do?",
                "Our fathers ate the manna in the wilderness. As it is written, \u2018He gave them bread out of heaven to eat.\u2019\"",
                "Jesus therefore said to them, \"Most certainly, I tell you, it wasn\u2019t Moses who gave you the bread out of heaven, but my Father gives you the true bread out of heaven.",
                "For the bread of God is that which comes down out of heaven, and gives life to the world.\"",
                "They said therefore to him, \"Lord, always give us this bread.\"",
                "Jesus said to them, \"I am the bread of life. He who comes to me will not be hungry, and he who believes in me will never be thirsty.",
                "But I told you that you have seen me, and yet you don\u2019t believe.",
                "All those whom the Father gives me will come to me. He who comes to me I will in no way throw out.",
                "For I have come down from heaven, not to do my own will, but the will of him who sent me.",
                "This is the will of my Father who sent me, that of all he has given to me I should lose nothing, but should raise him up at the last day.",
                "This is the will of the one who sent me, that everyone who sees the Son, and believes in him, should have eternal life; and I will raise him up at the last day.\"",
                "The Jews therefore murmured concerning him, because he said, \"I am the bread which came down out of heaven.\"",
                "They said, \"Isn\u2019t this Jesus, the son of Joseph, whose father and mother we know? How then does he say, \u2018I have come down out of heaven?\u2019\"",
                "Therefore Jesus answered them, \"Don\u2019t murmur among yourselves.",
                "No one can come to me unless the Father who sent me draws him, and I will raise him up in the last day.",
                "It is written in the prophets, \u2018They will all be taught by God.\u2019 Therefore everyone who hears from the Father, and has learned, comes to me.",
                "Not that anyone has seen the Father, except he who is from God. He has seen the Father.",
                "Most certainly, I tell you, he who believes in me has eternal life.",
                "I am the bread of life.",
                "Your fathers ate the manna in the wilderness, and they died.",
                "This is the bread which comes down out of heaven, that anyone may eat of it and not die.",
                "I am the living bread which came down out of heaven. If anyone eats of this bread, he will live forever. Yes, the bread which I will give for the life of the world is my flesh.\"",
                "The Jews therefore contended with one another, saying, \"How can this man give us his flesh to eat?\"",
                "Jesus therefore said to them, \"Most certainly I tell you, unless you eat the flesh of the Son of Man and drink his blood, you don\u2019t have life in yourselves.",
                "He who eats my flesh and drinks my blood has eternal life, and I will raise him up at the last day.",
                "For my flesh is food indeed, and my blood is drink indeed.",
                "He who eats my flesh and drinks my blood lives in me, and I in him.",
                "As the living Father sent me, and I live because of the Father; so he who feeds on me, he will also live because of me.",
                "This is the bread which came down out of heaven\u2014not as our fathers ate the manna, and died. He who eats this bread will live forever.\"",
                "He said these things in the synagogue, as he taught in Capernaum.",
                "Therefore many of his disciples, when they heard this, said, \"This is a hard saying! Who can listen to it?\"",
                "But Jesus knowing in himself that his disciples murmured at this, said to them, \"Does this cause you to stumble?",
                "Then what if you would see the Son of Man ascending to where he was before?",
                "It is the spirit who gives life. The flesh profits nothing. The words that I speak to you are spirit, and are life.",
                "But there are some of you who don\u2019t believe.\" For Jesus knew from the beginning who they were who didn\u2019t believe, and who it was who would betray him.",
                "He said, \"For this cause have I said to you that no one can come to me, unless it is given to him by my Father.\"",
                "At this, many of his disciples went back, and walked no more with him.",
                "Jesus said therefore to the twelve, \"You don\u2019t also want to go away, do you?\"",
                "Simon Peter answered him, \"Lord, to whom would we go? You have the words of eternal life.",
                "We have come to believe and know that you are the Christ, the Son of the living God.\"",
                "Jesus answered them, \"Didn\u2019t I choose you, the twelve, and one of you is a devil?\"",
                "Now he spoke of Judas, the son of Simon Iscariot, for it was he who would betray him, being one of the twelve."
            ],
            7: [
                "After these things, Jesus was walking in Galilee, for he wouldn\u2019t walk in Judea, because the Jews sought to kill him.",
                "Now the feast of the Jews, the Feast of Booths, was at hand.",
                "His brothers therefore said to him, \"Depart from here, and go into Judea, that your disciples also may see your works which you do.",
                "For no one does anything in secret, and himself seeks to be known openly. If you do these things, reveal yourself to the world.\"",
                "For even his brothers didn\u2019t believe in him.",
                "Jesus therefore said to them, \"My time has not yet come, but your time is always ready.",
                "The world can\u2019t hate you, but it hates me, because I testify about it, that its works are evil.",
                "You go up to the feast. I am not yet going up to this feast, because my time is not yet fulfilled.\"",
                "Having said these things to them, he stayed in Galilee.",
                "But when his brothers had gone up to the feast, then he also went up, not publicly, but as it were in secret.",
                "The Jews therefore sought him at the feast, and said, \"Where is he?\"",
                "There was much murmuring among the multitudes concerning him. Some said, \"He is a good man.\" Others said, \"Not so, but he leads the multitude astray.\"",
                "Yet no one spoke openly of him for fear of the Jews.",
                "But when it was now the midst of the feast, Jesus went up into the temple and taught.",
                "The Jews therefore marveled, saying, \"How does this man know letters, having never been educated?\"",
                "Jesus therefore answered them, \"My teaching is not mine, but his who sent me.",
                "If anyone desires to do his will, he will know about the teaching, whether it is from God, or if I am speaking from myself.",
                "He who speaks from himself seeks his own glory, but he who seeks the glory of him who sent him is true, and no unrighteousness is in him.",
                "Didn\u2019t Moses give you the law, and yet none of you keeps the law? Why do you seek to kill me?\"",
                "The multitude answered, \"You have a demon! Who seeks to kill you?\"",
                "Jesus answered them, \"I did one work, and you all marvel because of it.",
                "Moses has given you circumcision (not that it is of Moses, but of the fathers), and on the Sabbath you circumcise a boy.",
                "If a boy receives circumcision on the Sabbath, that the law of Moses may not be broken, are you angry with me, because I made a man completely healthy on the Sabbath?",
                "Don\u2019t judge according to appearance, but judge righteous judgment.\"",
                "Therefore some of them of Jerusalem said, \"Isn\u2019t this he whom they seek to kill?",
                "Behold, he speaks openly, and they say nothing to him. Can it be that the rulers indeed know that this is truly the Christ?",
                "However we know where this man comes from, but when the Christ comes, no one will know where he comes from.\"",
                "Jesus therefore cried out in the temple, teaching and saying, \"You both know me, and know where I am from. I have not come of myself, but he who sent me is true, whom you don\u2019t know.",
                "I know him, because I am from him, and he sent me.\"",
                "They sought therefore to take him; but no one laid a hand on him, because his hour had not yet come.",
                "But of the multitude, many believed in him. They said, \"When the Christ comes, he won\u2019t do more signs than those which this man has done, will he?\"",
                "The Pharisees heard the multitude murmuring these things concerning him, and the chief priests and the Pharisees sent officers to arrest him.",
                "Then Jesus said, \"I will be with you a little while longer, then I go to him who sent me.",
                "You will seek me, and won\u2019t find me; and where I am, you can\u2019t come.\"",
                "The Jews therefore said among themselves, \"Where will this man go that we won\u2019t find him? Will he go to the Dispersion among the Greeks, and teach the Greeks?",
                "What is this word that he said, \u2018You will seek me, and won\u2019t find me; and where I am, you can\u2019t come\u2019?\"",
                "Now on the last and greatest day of the feast, Jesus stood and cried out, \"If anyone is thirsty, let him come to me and drink!",
                "He who believes in me, as the Scripture has said, from within him will flow rivers of living water.\"",
                "But he said this about the Spirit, which those believing in him were to receive. For the Holy Spirit was not yet given, because Jesus wasn\u2019t yet glorified.",
                "Many of the multitude therefore, when they heard these words, said, \"This is truly the prophet.\"",
                "Others said, \"This is the Christ.\" But some said, \"What, does the Christ come out of Galilee?",
                "Hasn\u2019t the Scripture said that the Christ comes of the seed of David, and from Bethlehem, the village where David was?\"",
                "So there arose a division in the multitude because of him.",
                "Some of them would have arrested him, but no one laid hands on him.",
                "The officers therefore came to the chief priests and Pharisees, and they said to them, \"Why didn\u2019t you bring him?\"",
                "The officers answered, \"No man ever spoke like this man!\"",
                "The Pharisees therefore answered them, \"You aren\u2019t also led astray, are you?",
                "Have any of the rulers believed in him, or of the Pharisees?",
                "But this multitude that doesn\u2019t know the law is accursed.\"",
                "Nicodemus (he who came to him by night, being one of them) said to them,",
                "\"Does our law judge a man, unless it first hears from him personally and knows what he does?\"",
                "They answered him, \"Are you also from Galilee? Search, and see that no prophet has arisen out of Galilee.\"",
                "Everyone went to his own house,"
            ],
            8: [
                "but Jesus went to the Mount of Olives.",
                "Now very early in the morning, he came again into the temple, and all the people came to him. He sat down, and taught them.",
                "The scribes and the Pharisees brought a woman taken in adultery. Having set her in the midst,",
                "they told him, \"Teacher, we found this woman in adultery, in the very act.",
                "Now in our law, Moses commanded us to stone such. What then do you say about her?\"",
                "They said this testing him, that they might have something to accuse him of. But Jesus stooped down, and wrote on the ground with his finger.",
                "But when they continued asking him, he looked up and said to them, \"He who is without sin among you, let him throw the first stone at her.\"",
                "Again he stooped down, and with his finger wrote on the ground.",
                "They, when they heard it, being convicted by their conscience, went out one by one, beginning from the oldest, even to the last. Jesus was left alone with the woman where she was, in the middle.",
                "Jesus, standing up, saw her and said, \"Woman, where are your accusers? Did no one condemn you?\"",
                "She said, \"No one, Lord.\" Jesus said, \"Neither do I condemn you. Go your way. From now on, sin no more.\"",
                "Again, therefore, Jesus spoke to them, saying, \"I am the light of the world. He who follows me will not walk in the darkness, but will have the light of life.\"",
                "The Pharisees therefore said to him, \"You testify about yourself. Your testimony is not valid.\"",
                "Jesus answered them, \"Even if I testify about myself, my testimony is true, for I know where I came from, and where I am going; but you don\u2019t know where I came from, or where I am going.",
                "You judge according to the flesh. I judge no one.",
                "Even if I do judge, my judgment is true, for I am not alone, but I am with the Father who sent me.",
                "It\u2019s also written in your law that the testimony of two people is valid.",
                "I am one who testifies about myself, and the Father who sent me testifies about me.\"",
                "They said therefore to him, \"Where is your Father?\" Jesus answered, \"You know neither me, nor my Father. If you knew me, you would know my Father also.\"",
                "Jesus spoke these words in the treasury, as he taught in the temple. Yet no one arrested him, because his hour had not yet come.",
                "Jesus said therefore again to them, \"I am going away, and you will seek me, and you will die in your sins. Where I go, you can\u2019t come.\"",
                "The Jews therefore said, \"Will he kill himself, that he says, \u2018Where I am going, you can\u2019t come?\u2019\"",
                "He said to them, \"You are from beneath. I am from above. You are of this world. I am not of this world.",
                "I said therefore to you that you will die in your sins; for unless you believe that I am he, you will die in your sins.\"",
                "They said therefore to him, \"Who are you?\" Jesus said to them, \"Just what I have been saying to you from the beginning.",
                "I have many things to speak and to judge concerning you. However he who sent me is true; and the things which I heard from him, these I say to the world.\"",
                "They didn\u2019t understand that he spoke to them about the Father.",
                "Jesus therefore said to them, \"When you have lifted up the Son of Man, then you will know that I am he, and I do nothing of myself, but as my Father taught me, I say these things.",
                "He who sent me is with me. The Father hasn\u2019t left me alone, for I always do the things that are pleasing to him.\"",
                "As he spoke these things, many believed in him.",
                "Jesus therefore said to those Jews who had believed him, \"If you remain in my word, then you are truly my disciples.",
                "You will know the truth, and the truth will make you free.\"",
                "They answered him, \"We are Abraham\u2019s seed, and have never been in bondage to anyone. How do you say, \u2018You will be made free?\u2019\"",
                "Jesus answered them, \"Most certainly I tell you, everyone who commits sin is the bondservant of sin.",
                "A bondservant doesn\u2019t live in the house forever. A son remains forever.",
                "If therefore the Son makes you free, you will be free indeed.",
                "I know that you are Abraham\u2019s seed, yet you seek to kill me, because my word finds no place in you.",
                "I say the things which I have seen with my Father; and you also do the things which you have seen with your father.\"",
                "They answered him, \"Our father is Abraham.\" Jesus said to them, \"If you were Abraham\u2019s children, you would do the works of Abraham.",
                "But now you seek to kill me, a man who has told you the truth, which I heard from God. Abraham didn\u2019t do this.",
                "You do the works of your father.\" They said to him, \"We were not born of sexual immorality. We have one Father, God.\"",
                "Therefore Jesus said to them, \"If God were your father, you would love me, for I came out and have come from God. For I haven\u2019t come of myself, but he sent me.",
                "Why don\u2019t you understand my speech? Because you can\u2019t hear my word.",
                "You are of your father, the devil, and you want to do the desires of your father. He was a murderer from the beginning, and doesn\u2019t stand in the truth, because there is no truth in him. When he speaks a lie, he speaks on his own; for he is a liar, and its father.",
                "But because I tell the truth, you don\u2019t believe me.",
                "Which of you convicts me of sin? If I tell the truth, why do you not believe me?",
                "He who is of God hears the words of God. For this cause you don\u2019t hear, because you are not of God.\"",
                "Then the Jews answered him, \"Don\u2019t we say well that you are a Samaritan, and have a demon?\"",
                "Jesus answered, \"I don\u2019t have a demon, but I honor my Father, and you dishonor me.",
                "But I don\u2019t seek my own glory. There is one who seeks and judges.",
                "Most certainly, I tell you, if a person keeps my word, he will never see death.\"",
                "Then the Jews said to him, \"Now we know that you have a demon. Abraham died, and the prophets; and you say, \u2018If a man keeps my word, he will never taste of death.\u2019",
                "Are you greater than our father, Abraham, who died? The prophets died. Who do you make yourself out to be?\"",
                "Jesus answered, \"If I glorify myself, my glory is nothing. It is my Father who glorifies me, of whom you say that he is our God.",
                "You have not known him, but I know him. If I said, \u2018I don\u2019t know him,\u2019 I would be like you, a liar. But I know him, and keep his word.",
                "Your father Abraham rejoiced to see my day. He saw it, and was glad.\"",
                "The Jews therefore said to him, \"You are not yet fifty years old, and have you seen Abraham?\"",
                "Jesus said to them, \"Most certainly, I tell you, before Abraham came into existence, I AM.\"",
                "Therefore they took up stones to throw at him, but Jesus was hidden, and went out of the temple, having gone through their midst, and so passed by."
            ],
            9: [
                "As he passed by, he saw a man blind from birth.",
                "His disciples asked him, \"Rabbi, who sinned, this man or his parents, that he was born blind?\"",
                "Jesus answered, \"Neither did this man sin, nor his parents; but, that the works of God might be revealed in him.",
                "I must work the works of him who sent me, while it is day. The night is coming, when no one can work.",
                "While I am in the world, I am the light of the world.\"",
                "When he had said this, he spat on the ground, made mud with the saliva, anointed the blind man\u2019s eyes with the mud,",
                "and said to him, \"Go, wash in the pool of Siloam\" (which means \"Sent\"). So he went away, washed, and came back seeing.",
                "The neighbors therefore, and those who saw that he was blind before, said, \"Isn\u2019t this he who sat and begged?\"",
                "Others were saying, \"It is he.\" Still others were saying, \"He looks like him.\" He said, \"I am he.\"",
                "They therefore were asking him, \"How were your eyes opened?\"",
                "He answered, \"A man called Jesus made mud, anointed my eyes, and said to me, \u2018Go to the pool of Siloam, and wash.\u2019 So I went away and washed, and I received sight.\"",
                "Then they asked him, \"Where is he?\" He said, \"I don\u2019t know.\"",
                "They brought him who had been blind to the Pharisees.",
                "It was a Sabbath when Jesus made the mud and opened his eyes.",
                "Again therefore the Pharisees also asked him how he received his sight. He said to them, \"He put mud on my eyes, I washed, and I see.\"",
                "Some therefore of the Pharisees said, \"This man is not from God, because he doesn\u2019t keep the Sabbath.\" Others said, \"How can a man who is a sinner do such signs?\" There was division among them.",
                "Therefore they asked the blind man again, \"What do you say about him, because he opened your eyes?\" He said, \"He is a prophet.\"",
                "The Jews therefore did not believe concerning him, that he had been blind, and had received his sight, until they called the parents of him who had received his sight,",
                "and asked them, \"Is this your son, whom you say was born blind? How then does he now see?\"",
                "His parents answered them, \"We know that this is our son, and that he was born blind;",
                "but how he now sees, we don\u2019t know; or who opened his eyes, we don\u2019t know. He is of age. Ask him. He will speak for himself.\"",
                "His parents said these things because they feared the Jews; for the Jews had already agreed that if any man would confess him as Christ, he would be put out of the synagogue.",
                "Therefore his parents said, \"He is of age. Ask him.\"",
                "So they called the man who was blind a second time, and said to him, \"Give glory to God. We know that this man is a sinner.\"",
                "He therefore answered, \"I don\u2019t know if he is a sinner. One thing I do know: that though I was blind, now I see.\"",
                "They said to him again, \"What did he do to you? How did he open your eyes?\"",
                "He answered them, \"I told you already, and you didn\u2019t listen. Why do you want to hear it again? You don\u2019t also want to become his disciples, do you?\"",
                "They insulted him and said, \"You are his disciple, but we are disciples of Moses.",
                "We know that God has spoken to Moses. But as for this man, we don\u2019t know where he comes from.\"",
                "The man answered them, \"How amazing! You don\u2019t know where he comes from, yet he opened my eyes.",
                "We know that God doesn\u2019t listen to sinners, but if anyone is a worshipper of God, and does his will, he listens to him.",
                "Since the world began it has never been heard of that anyone opened the eyes of someone born blind.",
                "If this man were not from God, he could do nothing.\"",
                "They answered him, \"You were altogether born in sins, and do you teach us?\" They threw him out.",
                "Jesus heard that they had thrown him out, and finding him, he said, \"Do you believe in the Son of God?\"",
                "He answered, \"Who is he, Lord, that I may believe in him?\"",
                "Jesus said to him, \"You have both seen him, and it is he who speaks with you.\"",
                "He said, \"Lord, I believe!\" and he worshiped him.",
                "Jesus said, \"I came into this world for judgment, that those who don\u2019t see may see; and that those who see may become blind.\"",
                "Those of the Pharisees who were with him heard these things, and said to him, \"Are we also blind?\"",
                "Jesus said to them, \"If you were blind, you would have no sin; but now you say, \u2018We see.\u2019 Therefore your sin remains."
            ],
            10: [
                "\"Most certainly, I tell you, one who doesn\u2019t enter by the door into the sheep fold, but climbs up some other way, the same is a thief and a robber.",
                "But one who enters in by the door is the shepherd of the sheep.",
                "The gatekeeper opens the gate for him, and the sheep listen to his voice. He calls his own sheep by name, and leads them out.",
                "Whenever he brings out his own sheep, he goes before them, and the sheep follow him, for they know his voice.",
                "They will by no means follow a stranger, but will flee from him; for they don\u2019t know the voice of strangers.\"",
                "Jesus spoke this parable to them, but they didn\u2019t understand what he was telling them.",
                "Jesus therefore said to them again, \"Most certainly, I tell you, I am the sheep\u2019s door.",
                "All who came before me are thieves and robbers, but the sheep didn\u2019t listen to them.",
                "I am the door. If anyone enters in by me, he will be saved, and will go in and go out, and will find pasture.",
                "The thief only comes to steal, kill, and destroy. I came that they may have life, and may have it abundantly.",
                "I am the good shepherd. The good shepherd lays down his life for the sheep.",
                "He who is a hired hand, and not a shepherd, who doesn\u2019t own the sheep, sees the wolf coming, leaves the sheep, and flees. The wolf snatches the sheep, and scatters them.",
                "The hired hand flees because he is a hired hand, and doesn\u2019t care for the sheep.",
                "I am the good shepherd. I know my own, and I\u2019m known by my own;",
                "even as the Father knows me, and I know the Father. I lay down my life for the sheep.",
                "I have other sheep, which are not of this fold. I must bring them also, and they will hear my voice. They will become one flock with one shepherd.",
                "Therefore the Father loves me, because I lay down my life, that I may take it again.",
                "No one takes it away from me, but I lay it down by myself. I have power to lay it down, and I have power to take it again. I received this commandment from my Father.\"",
                "Therefore a division arose again among the Jews because of these words.",
                "Many of them said, \"He has a demon, and is insane! Why do you listen to him?\"",
                "Others said, \"These are not the sayings of one possessed by a demon. It isn\u2019t possible for a demon to open the eyes of the blind, is it?\"",
                "It was the Feast of the Dedication at Jerusalem.",
                "It was winter, and Jesus was walking in the temple, in Solomon\u2019s porch.",
                "The Jews therefore came around him and said to him, \"How long will you hold us in suspense? If you are the Christ, tell us plainly.\"",
                "Jesus answered them, \"I told you, and you don\u2019t believe. The works that I do in my Father\u2019s name, these testify about me.",
                "But you don\u2019t believe, because you are not of my sheep, as I told you.",
                "My sheep hear my voice, and I know them, and they follow me.",
                "I give eternal life to them. They will never perish, and no one will snatch them out of my hand.",
                "My Father, who has given them to me, is greater than all. No one is able to snatch them out of my Father\u2019s hand.",
                "I and the Father are one.\"",
                "Therefore Jews took up stones again to stone him.",
                "Jesus answered them, \"I have shown you many good works from my Father. For which of those works do you stone me?\"",
                "The Jews answered him, \"We don\u2019t stone you for a good work, but for blasphemy: because you, being a man, make yourself God.\"",
                "Jesus answered them, \"Isn\u2019t it written in your law, \u2018I said, you are gods?\u2019",
                "If he called them gods, to whom the word of God came (and the Scripture can\u2019t be broken),",
                "do you say of him whom the Father sanctified and sent into the world, \u2018You blaspheme,\u2019 because I said, \u2018I am the Son of God?\u2019",
                "If I don\u2019t do the works of my Father, don\u2019t believe me.",
                "But if I do them, though you don\u2019t believe me, believe the works; that you may know and believe that the Father is in me, and I in the Father.\"",
                "They sought again to seize him, and he went out of their hand.",
                "He went away again beyond the Jordan into the place where John was baptizing at first, and there he stayed.",
                "Many came to him. They said, \"John indeed did no sign, but everything that John said about this man is true.\"",
                "Many believed in him there."
            ],
            11: [
                "Now a certain man was sick, Lazarus from Bethany, of the village of Mary and her sister, Martha.",
                "It was that Mary who had anointed the Lord with ointment, and wiped his feet with her hair, whose brother, Lazarus, was sick.",
                "The sisters therefore sent to him, saying, \"Lord, behold, he for whom you have great affection is sick.\"",
                "But when Jesus heard it, he said, \"This sickness is not to death, but for the glory of God, that God\u2019s Son may be glorified by it.\"",
                "Now Jesus loved Martha, and her sister, and Lazarus.",
                "When therefore he heard that he was sick, he stayed two days in the place where he was.",
                "Then after this he said to the disciples, \"Let\u2019s go into Judea again.\"",
                "The disciples told him, \"Rabbi, the Jews were just trying to stone you, and are you going there again?\"",
                "Jesus answered, \"Aren\u2019t there twelve hours of daylight? If a man walks in the day, he doesn\u2019t stumble, because he sees the light of this world.",
                "But if a man walks in the night, he stumbles, because the light isn\u2019t in him.\"",
                "He said these things, and after that, he said to them, \"Our friend, Lazarus, has fallen asleep, but I am going so that I may awake him out of sleep.\"",
                "The disciples therefore said, \"Lord, if he has fallen asleep, he will recover.\"",
                "Now Jesus had spoken of his death, but they thought that he spoke of taking rest in sleep.",
                "So Jesus said to them plainly then, \"Lazarus is dead.",
                "I am glad for your sakes that I was not there, so that you may believe. Nevertheless, let\u2019s go to him.\"",
                "Thomas therefore, who is called Didymus, said to his fellow disciples, \"Let\u2019s go also, that we may die with him.\"",
                "So when Jesus came, he found that he had been in the tomb four days already.",
                "Now Bethany was near Jerusalem, about fifteen stadia away.",
                "Many of the Jews had joined the women around Martha and Mary, to console them concerning their brother.",
                "Then when Martha heard that Jesus was coming, she went and met him, but Mary stayed in the house.",
                "Therefore Martha said to Jesus, \"Lord, if you would have been here, my brother wouldn\u2019t have died.",
                "Even now I know that, whatever you ask of God, God will give you.\"",
                "Jesus said to her, \"Your brother will rise again.\"",
                "Martha said to him, \"I know that he will rise again in the resurrection at the last day.\"",
                "Jesus said to her, \"I am the resurrection and the life. He who believes in me will still live, even if he dies.",
                "Whoever lives and believes in me will never die. Do you believe this?\"",
                "She said to him, \"Yes, Lord. I have come to believe that you are the Christ, God\u2019s Son, he who comes into the world.\"",
                "When she had said this, she went away, and called Mary, her sister, secretly, saying, \"The Teacher is here, and is calling you.\"",
                "When she heard this, she arose quickly, and went to him.",
                "Now Jesus had not yet come into the village, but was in the place where Martha met him.",
                "Then the Jews who were with her in the house, and were consoling her, when they saw Mary, that she rose up quickly and went out, followed her, saying, \"She is going to the tomb to weep there.\"",
                "Therefore when Mary came to where Jesus was, and saw him, she fell down at his feet, saying to him, \"Lord, if you would have been here, my brother wouldn\u2019t have died.\"",
                "When Jesus therefore saw her weeping, and the Jews weeping who came with her, he groaned in the spirit, and was troubled,",
                "and said, \"Where have you laid him?\" They told him, \"Lord, come and see.\"",
                "Jesus wept.",
                "The Jews therefore said, \"See how much affection he had for him!\"",
                "Some of them said, \"Couldn\u2019t this man, who opened the eyes of him who was blind, have also kept this man from dying?\"",
                "Jesus therefore, again groaning in himself, came to the tomb. Now it was a cave, and a stone lay against it.",
                "Jesus said, \"Take away the stone.\" Martha, the sister of him who was dead, said to him, \"Lord, by this time there is a stench, for he has been dead four days.\"",
                "Jesus said to her, \"Didn\u2019t I tell you that if you believed, you would see God\u2019s glory?\"",
                "So they took away the stone from the place where the dead man was lying. Jesus lifted up his eyes, and said, \"Father, I thank you that you listened to me.",
                "I know that you always listen to me, but because of the multitude that stands around I said this, that they may believe that you sent me.\"",
                "When he had said this, he cried with a loud voice, \"Lazarus, come out!\"",
                "He who was dead came out, bound hand and foot with wrappings, and his face was wrapped around with a cloth. Jesus said to them, \"Free him, and let him go.\"",
                "Therefore many of the Jews, who came to Mary and saw what Jesus did, believed in him.",
                "But some of them went away to the Pharisees, and told them the things which Jesus had done.",
                "The chief priests therefore and the Pharisees gathered a council, and said, \"What are we doing? For this man does many signs.",
                "If we leave him alone like this, everyone will believe in him, and the Romans will come and take away both our place and our nation.\"",
                "But a certain one of them, Caiaphas, being high priest that year, said to them, \"You know nothing at all,",
                "nor do you consider that it is advantageous for us that one man should die for the people, and that the whole nation not perish.\"",
                "Now he didn\u2019t say this of himself, but being high priest that year, he prophesied that Jesus would die for the nation,",
                "and not for the nation only, but that he might also gather together into one the children of God who are scattered abroad.",
                "So from that day forward they took counsel that they might put him to death.",
                "Jesus therefore walked no more openly among the Jews, but departed from there into the country near the wilderness, to a city called Ephraim. He stayed there with his disciples.",
                "Now the Passover of the Jews was at hand. Many went up from the country to Jerusalem before the Passover, to purify themselves.",
                "Then they sought for Jesus and spoke one with another, as they stood in the temple, \"What do you think\u2014that he isn\u2019t coming to the feast at all?\"",
                "Now the chief priests and the Pharisees had commanded that if anyone knew where he was, he should report it, that they might seize him."
            ],
            12: [
                "Then six days before the Passover, Jesus came to Bethany, where Lazarus was, who had been dead, whom he raised from the dead.",
                "So they made him a supper there. Martha served, but Lazarus was one of those who sat at the table with him.",
                "Mary, therefore, took a pound of ointment of pure nard, very precious, and anointed the feet of Jesus, and wiped his feet with her hair. The house was filled with the fragrance of the ointment.",
                "Then Judas Iscariot, Simon\u2019s son, one of his disciples, who would betray him, said,",
                "\"Why wasn\u2019t this ointment sold for three hundred denarii, and given to the poor?\"",
                "Now he said this, not because he cared for the poor, but because he was a thief, and having the money box, used to steal what was put into it.",
                "But Jesus said, \"Leave her alone. She has kept this for the day of my burial.",
                "For you always have the poor with you, but you don\u2019t always have me.\"",
                "A large crowd therefore of the Jews learned that he was there, and they came, not for Jesus\u2019 sake only, but that they might see Lazarus also, whom he had raised from the dead.",
                "But the chief priests conspired to put Lazarus to death also,",
                "because on account of him many of the Jews went away and believed in Jesus.",
                "On the next day a great multitude had come to the feast. When they heard that Jesus was coming to Jerusalem,",
                "they took the branches of the palm trees, and went out to meet him, and cried out, \"Hosanna! Blessed is he who comes in the name of the Lord, the King of Israel!\"",
                "Jesus, having found a young donkey, sat on it. As it is written,",
                "\"Don\u2019t be afraid, daughter of Zion. Behold, your King comes, sitting on a donkey\u2019s colt.\"",
                "His disciples didn\u2019t understand these things at first, but when Jesus was glorified, then they remembered that these things were written about him, and that they had done these things to him.",
                "The multitude therefore that was with him when he called Lazarus out of the tomb, and raised him from the dead, was testifying about it.",
                "For this cause also the multitude went and met him, because they heard that he had done this sign.",
                "The Pharisees therefore said among themselves, \"See how you accomplish nothing. Behold, the world has gone after him.\"",
                "Now there were certain Greeks among those that went up to worship at the feast.",
                "These, therefore, came to Philip, who was from Bethsaida of Galilee, and asked him, saying, \"Sir, we want to see Jesus.\"",
                "Philip came and told Andrew, and in turn, Andrew came with Philip, and they told Jesus.",
                "Jesus answered them, \"The time has come for the Son of Man to be glorified.",
                "Most certainly I tell you, unless a grain of wheat falls into the earth and dies, it remains by itself alone. But if it dies, it bears much fruit.",
                "He who loves his life will lose it. He who hates his life in this world will keep it to eternal life.",
                "If anyone serves me, let him follow me. Where I am, there will my servant also be. If anyone serves me, the Father will honor him.",
                "\"Now my soul is troubled. What shall I say? \u2018Father, save me from this time?\u2019 But for this cause I came to this time.",
                "Father, glorify your name!\" Then there came a voice out of the sky, saying, \"I have both glorified it, and will glorify it again.\"",
                "The multitude therefore, who stood by and heard it, said that it had thundered. Others said, \"An angel has spoken to him.\"",
                "Jesus answered, \"This voice hasn\u2019t come for my sake, but for your sakes.",
                "Now is the judgment of this world. Now the prince of this world will be cast out.",
                "And I, if I am lifted up from the earth, will draw all people to myself.\"",
                "But he said this, signifying by what kind of death he should die.",
                "The multitude answered him, \"We have heard out of the law that the Christ remains forever. How do you say, \u2018The Son of Man must be lifted up?\u2019 Who is this Son of Man?\"",
                "Jesus therefore said to them, \"Yet a little while the light is with you. Walk while you have the light, that darkness doesn\u2019t overtake you. He who walks in the darkness doesn\u2019t know where he is going.",
                "While you have the light, believe in the light, that you may become children of light.\" Jesus said these things, and he departed and hid himself from them.",
                "But though he had done so many signs before them, yet they didn\u2019t believe in him,",
                "that the word of Isaiah the prophet might be fulfilled, which he spoke, \"Lord, who has believed our report? To whom has the arm of the Lord been revealed?\"",
                "For this cause they couldn\u2019t believe, for Isaiah said again,",
                "\"He has blinded their eyes and he hardened their heart, lest they should see with their eyes, and perceive with their heart, and would turn, and I would heal them.\"",
                "Isaiah said these things when he saw his glory, and spoke of him.",
                "Nevertheless even of the rulers many believed in him, but because of the Pharisees they didn\u2019t confess it, so that they wouldn\u2019t be put out of the synagogue,",
                "for they loved men\u2019s praise more than God\u2019s praise.",
                "Jesus cried out and said, \"Whoever believes in me, believes not in me, but in him who sent me.",
                "He who sees me sees him who sent me.",
                "I have come as a light into the world, that whoever believes in me may not remain in the darkness.",
                "If anyone listens to my sayings, and doesn\u2019t believe, I don\u2019t judge him. For I came not to judge the world, but to save the world.",
                "He who rejects me, and doesn\u2019t receive my sayings, has one who judges him. The word that I spoke, the same will judge him in the last day.",
                "For I spoke not from myself, but the Father who sent me, he gave me a commandment, what I should say, and what I should speak.",
                "I know that his commandment is eternal life. The things therefore which I speak, even as the Father has said to me, so I speak.\""
            ],
            13: [
                "Now before the feast of the Passover, Jesus, knowing that his time had come that he would depart from this world to the Father, having loved his own who were in the world, he loved them to the end.",
                "During supper, the devil having already put into the heart of Judas Iscariot, Simon\u2019s son, to betray him,",
                "Jesus, knowing that the Father had given all things into his hands, and that he came forth from God, and was going to God,",
                "arose from supper, and laid aside his outer garments. He took a towel, and wrapped a towel around his waist.",
                "Then he poured water into the basin, and began to wash the disciples\u2019 feet, and to wipe them with the towel that was wrapped around him.",
                "Then he came to Simon Peter. He said to him, \"Lord, do you wash my feet?\"",
                "Jesus answered him, \"You don\u2019t know what I am doing now, but you will understand later.\"",
                "Peter said to him, \"You will never wash my feet!\" Jesus answered him, \"If I don\u2019t wash you, you have no part with me.\"",
                "Simon Peter said to him, \"Lord, not my feet only, but also my hands and my head!\"",
                "Jesus said to him, \"Someone who has bathed only needs to have his feet washed, but is completely clean. You are clean, but not all of you.\"",
                "For he knew him who would betray him, therefore he said, \"You are not all clean.\"",
                "So when he had washed their feet, put his outer garment back on, and sat down again, he said to them, \"Do you know what I have done to you?",
                "You call me, \u2018Teacher\u2019 and \u2018Lord.\u2019 You say so correctly, for so I am.",
                "If I then, the Lord and the Teacher, have washed your feet, you also ought to wash one another\u2019s feet.",
                "For I have given you an example, that you also should do as I have done to you.",
                "Most certainly I tell you, a servant is not greater than his lord, neither one who is sent greater than he who sent him.",
                "If you know these things, blessed are you if you do them.",
                "I don\u2019t speak concerning all of you. I know whom I have chosen. But that the Scripture may be fulfilled, \u2018He who eats bread with me has lifted up his heel against me.\u2019",
                "From now on, I tell you before it happens, that when it happens, you may believe that I am he.",
                "Most certainly I tell you, he who receives whomever I send, receives me; and he who receives me, receives him who sent me.\"",
                "When Jesus had said this, he was troubled in spirit, and testified, \"Most certainly I tell you that one of you will betray me.\"",
                "The disciples looked at one another, perplexed about whom he spoke.",
                "One of his disciples, whom Jesus loved, was at the table, leaning against Jesus\u2019 breast.",
                "Simon Peter therefore beckoned to him, and said to him, \"Tell us who it is of whom he speaks.\"",
                "He, leaning back, as he was, on Jesus\u2019 breast, asked him, \"Lord, who is it?\"",
                "Jesus therefore answered, \"It is he to whom I will give this piece of bread when I have dipped it.\" So when he had dipped the piece of bread, he gave it to Judas, the son of Simon Iscariot.",
                "After the piece of bread, then Satan entered into him. Then Jesus said to him, \"What you do, do quickly.\"",
                "Now no man at the table knew why he said this to him.",
                "For some thought, because Judas had the money box, that Jesus said to him, \"Buy what things we need for the feast,\" or that he should give something to the poor.",
                "Therefore, having received that morsel, he went out immediately. It was night.",
                "When he had gone out, Jesus said, \"Now the Son of Man has been glorified, and God has been glorified in him.",
                "If God has been glorified in him, God will also glorify him in himself, and he will glorify him immediately.",
                "Little children, I will be with you a little while longer. You will seek me, and as I said to the Jews, \u2018Where I am going, you can\u2019t come,\u2019 so now I tell you.",
                "A new commandment I give to you, that you love one another, just like I have loved you; that you also love one another.",
                "By this everyone will know that you are my disciples, if you have love for one another.\"",
                "Simon Peter said to him, \"Lord, where are you going?\" Jesus answered, \"Where I am going, you can\u2019t follow now, but you will follow afterwards.\"",
                "Peter said to him, \"Lord, why can\u2019t I follow you now? I will lay down my life for you.\"",
                "Jesus answered him, \"Will you lay down your life for me? Most certainly I tell you, the rooster won\u2019t crow until you have denied me three times."
            ],
            14: [
                "\"Don\u2019t let your heart be troubled. Believe in God. Believe also in me.",
                "In my Father\u2019s house are many homes. If it weren\u2019t so, I would have told you. I am going to prepare a place for you.",
                "If I go and prepare a place for you, I will come again, and will receive you to myself; that where I am, you may be there also.",
                "Where I go, you know, and you know the way.\"",
                "Thomas said to him, \"Lord, we don\u2019t know where you are going. How can we know the way?\"",
                "Jesus said to him, \"I am the way, the truth, and the life. No one comes to the Father, except through me.",
                "If you had known me, you would have known my Father also. From now on, you know him, and have seen him.\"",
                "Philip said to him, \"Lord, show us the Father, and that will be enough for us.\"",
                "Jesus said to him, \"Have I been with you such a long time, and do you not know me, Philip? He who has seen me has seen the Father. How do you say, \u2018Show us the Father?\u2019",
                "Don\u2019t you believe that I am in the Father, and the Father in me? The words that I tell you, I speak not from myself; but the Father who lives in me does his works.",
                "Believe me that I am in the Father, and the Father in me; or else believe me for the very works\u2019 sake.",
                "Most certainly I tell you, he who believes in me, the works that I do, he will do also; and he will do greater works than these, because I am going to my Father.",
                "Whatever you will ask in my name, that will I do, that the Father may be glorified in the Son.",
                "If you will ask anything in my name, I will do it.",
                "If you love me, keep my commandments.",
                "I will pray to the Father, and he will give you another Counselor, that he may be with you forever,\u2014",
                "the Spirit of truth, whom the world can\u2019t receive; for it doesn\u2019t see him, neither knows him. You know him, for he lives with you, and will be in you.",
                "I will not leave you orphans. I will come to you.",
                "Yet a little while, and the world will see me no more; but you will see me. Because I live, you will live also.",
                "In that day you will know that I am in my Father, and you in me, and I in you.",
                "One who has my commandments, and keeps them, that person is one who loves me. One who loves me will be loved by my Father, and I will love him, and will reveal myself to him.\"",
                "Judas (not Iscariot) said to him, \"Lord, what has happened that you are about to reveal yourself to us, and not to the world?\"",
                "Jesus answered him, \"If a man loves me, he will keep my word. My Father will love him, and we will come to him, and make our home with him.",
                "He who doesn\u2019t love me doesn\u2019t keep my words. The word which you hear isn\u2019t mine, but the Father\u2019s who sent me.",
                "I have said these things to you, while still living with you.",
                "But the Counselor, the Holy Spirit, whom the Father will send in my name, he will teach you all things, and will remind you of all that I said to you.",
                "Peace I leave with you. My peace I give to you; not as the world gives, give I to you. Don\u2019t let your heart be troubled, neither let it be fearful.",
                "You heard how I told you, \u2018I go away, and I come to you.\u2019 If you loved me, you would have rejoiced, because I said \u2018I am going to my Father;\u2019 for the Father is greater than I.",
                "Now I have told you before it happens so that, when it happens, you may believe.",
                "I will no more speak much with you, for the prince of the world comes, and he has nothing in me.",
                "But that the world may know that I love the Father, and as the Father commanded me, even so I do. Arise, let us go from here."
            ],
            15: [
                "\"I am the true vine, and my Father is the farmer.",
                "Every branch in me that doesn\u2019t bear fruit, he takes away. Every branch that bears fruit, he prunes, that it may bear more fruit.",
                "You are already pruned clean because of the word which I have spoken to you.",
                "Remain in me, and I in you. As the branch can\u2019t bear fruit by itself, unless it remains in the vine, so neither can you, unless you remain in me.",
                "I am the vine. You are the branches. He who remains in me, and I in him, the same bears much fruit, for apart from me you can do nothing.",
                "If a man doesn\u2019t remain in me, he is thrown out as a branch, and is withered; and they gather them, throw them into the fire, and they are burned.",
                "If you remain in me, and my words remain in you, you will ask whatever you desire, and it will be done for you.",
                "\"In this is my Father glorified, that you bear much fruit; and so you will be my disciples.",
                "Even as the Father has loved me, I also have loved you. Remain in my love.",
                "If you keep my commandments, you will remain in my love; even as I have kept my Father\u2019s commandments, and remain in his love.",
                "I have spoken these things to you, that my joy may remain in you, and that your joy may be made full.",
                "\"This is my commandment, that you love one another, even as I have loved you.",
                "Greater love has no one than this, that someone lay down his life for his friends.",
                "You are my friends, if you do whatever I command you.",
                "No longer do I call you servants, for the servant doesn\u2019t know what his lord does. But I have called you friends, for everything that I heard from my Father, I have made known to you.",
                "You didn\u2019t choose me, but I chose you, and appointed you, that you should go and bear fruit, and that your fruit should remain; that whatever you will ask of the Father in my name, he may give it to you.",
                "\"I command these things to you, that you may love one another.",
                "If the world hates you, you know that it has hated me before it hated you.",
                "If you were of the world, the world would love its own. But because you are not of the world, since I chose you out of the world, therefore the world hates you.",
                "Remember the word that I said to you: \u2018A servant is not greater than his lord.\u2019 If they persecuted me, they will also persecute you. If they kept my word, they will keep yours also.",
                "But all these things will they do to you for my name\u2019s sake, because they don\u2019t know him who sent me.",
                "If I had not come and spoken to them, they would not have had sin; but now they have no excuse for their sin.",
                "He who hates me, hates my Father also.",
                "If I hadn\u2019t done among them the works which no one else did, they wouldn\u2019t have had sin. But now have they seen and also hated both me and my Father.",
                "But this happened so that the word may be fulfilled which was written in their law, \u2018They hated me without a cause.\u2019",
                "\"When the Counselor has come, whom I will send to you from the Father, the Spirit of truth, who proceeds from the Father, he will testify about me.",
                "You will also testify, because you have been with me from the beginning."
            ],
            16: [
                "\"These things have I spoken to you, so that you wouldn\u2019t be caused to stumble.",
                "They will put you out of the synagogues. Yes, the time comes that whoever kills you will think that he offers service to God.",
                "They will do these things because they have not known the Father, nor me.",
                "But I have told you these things, so that when the time comes, you may remember that I told you about them. I didn\u2019t tell you these things from the beginning, because I was with you.",
                "But now I am going to him who sent me, and none of you asks me, \u2018Where are you going?\u2019",
                "But because I have told you these things, sorrow has filled your heart.",
                "Nevertheless I tell you the truth: It is to your advantage that I go away, for if I don\u2019t go away, the Counselor won\u2019t come to you. But if I go, I will send him to you.",
                "When he has come, he will convict the world about sin, about righteousness, and about judgment;",
                "about sin, because they don\u2019t believe in me;",
                "about righteousness, because I am going to my Father, and you won\u2019t see me any more;",
                "about judgment, because the prince of this world has been judged.",
                "\"I have yet many things to tell you, but you can\u2019t bear them now.",
                "However when he, the Spirit of truth, has come, he will guide you into all truth, for he will not speak from himself; but whatever he hears, he will speak. He will declare to you things that are coming.",
                "He will glorify me, for he will take from what is mine, and will declare it to you.",
                "All things whatever the Father has are mine; therefore I said that he takes of mine, and will declare it to you.",
                "A little while, and you will not see me. Again a little while, and you will see me.\"",
                "Some of his disciples therefore said to one another, \"What is this that he says to us, \u2018A little while, and you won\u2019t see me, and again a little while, and you will see me;\u2019 and, \u2018Because I go to the Father?\u2019\"",
                "They said therefore, \"What is this that he says, \u2018A little while?\u2019 We don\u2019t know what he is saying.\"",
                "Therefore Jesus perceived that they wanted to ask him, and he said to them, \"Do you inquire among yourselves concerning this, that I said, \u2018A little while, and you won\u2019t see me, and again a little while, and you will see me?\u2019",
                "Most certainly I tell you, that you will weep and lament, but the world will rejoice. You will be sorrowful, but your sorrow will be turned into joy.",
                "A woman, when she gives birth, has sorrow, because her time has come. But when she has delivered the child, she doesn\u2019t remember the anguish any more, for the joy that a human being is born into the world.",
                "Therefore you now have sorrow, but I will see you again, and your heart will rejoice, and no one will take your joy away from you.",
                "\"In that day you will ask me no questions. Most certainly I tell you, whatever you may ask of the Father in my name, he will give it to you.",
                "Until now, you have asked nothing in my name. Ask, and you will receive, that your joy may be made full.",
                "I have spoken these things to you in figures of speech. But the time is coming when I will no more speak to you in figures of speech, but will tell you plainly about the Father.",
                "In that day you will ask in my name; and I don\u2019t say to you, that I will pray to the Father for you,",
                "for the Father himself loves you, because you have loved me, and have believed that I came forth from God.",
                "I came out from the Father, and have come into the world. Again, I leave the world, and go to the Father.\"",
                "His disciples said to him, \"Behold, now you speak plainly, and speak no figures of speech.",
                "Now we know that you know all things, and don\u2019t need for anyone to question you. By this we believe that you came forth from God.\"",
                "Jesus answered them, \"Do you now believe?",
                "Behold, the time is coming, yes, and has now come, that you will be scattered, everyone to his own place, and you will leave me alone. Yet I am not alone, because the Father is with me.",
                "I have told you these things, that in me you may have peace. In the world you have oppression; but cheer up! I have overcome the world.\""
            ],
            17: [
                "Jesus said these things, and lifting up his eyes to heaven, he said, \"Father, the time has come. Glorify your Son, that your Son may also glorify you;",
                "even as you gave him authority over all flesh, he will give eternal life to all whom you have given him.",
                "This is eternal life, that they should know you, the only true God, and him whom you sent, Jesus Christ.",
                "I glorified you on the earth. I have accomplished the work which you have given me to do.",
                "Now, Father, glorify me with your own self with the glory which I had with you before the world existed.",
                "I revealed your name to the people whom you have given me out of the world. They were yours, and you have given them to me. They have kept your word.",
                "Now they have known that all things whatever you have given me are from you,",
                "for the words which you have given me I have given to them, and they received them, and knew for sure that I came forth from you, and they have believed that you sent me.",
                "I pray for them. I don\u2019t pray for the world, but for those whom you have given me, for they are yours.",
                "All things that are mine are yours, and yours are mine, and I am glorified in them.",
                "I am no more in the world, but these are in the world, and I am coming to you. Holy Father, keep them through your name which you have given me, that they may be one, even as we are.",
                "While I was with them in the world, I kept them in your name. Those whom you have given me I have kept. None of them is lost, except the son of destruction, that the Scripture might be fulfilled.",
                "But now I come to you, and I say these things in the world, that they may have my joy made full in themselves.",
                "I have given them your word. The world hated them, because they are not of the world, even as I am not of the world.",
                "I pray not that you would take them from the world, but that you would keep them from the evil one.",
                "They are not of the world even as I am not of the world.",
                "Sanctify them in your truth. Your word is truth.",
                "As you sent me into the world, even so I have sent them into the world.",
                "For their sakes I sanctify myself, that they themselves also may be sanctified in truth.",
                "Not for these only do I pray, but for those also who believe in me through their word,",
                "that they may all be one; even as you, Father, are in me, and I in you, that they also may be one in us; that the world may believe that you sent me.",
                "The glory which you have given me, I have given to them; that they may be one, even as we are one;",
                "I in them, and you in me, that they may be perfected into one; that the world may know that you sent me, and loved them, even as you loved me.",
                "Father, I desire that they also whom you have given me be with me where I am, that they may see my glory, which you have given me, for you loved me before the foundation of the world.",
                "Righteous Father, the world hasn\u2019t known you, but I knew you; and these knew that you sent me.",
                "I made known to them your name, and will make it known; that the love with which you loved me may be in them, and I in them.\""
            ],
            18: [
                "When Jesus had spoken these words, he went out with his disciples over the brook Kidron, where there was a garden, into which he and his disciples entered.",
                "Now Judas, who betrayed him, also knew the place, for Jesus often met there with his disciples.",
                "Judas then, having taken a detachment of soldiers and officers from the chief priests and the Pharisees, came there with lanterns, torches, and weapons.",
                "Jesus therefore, knowing all the things that were happening to him, went forth, and said to them, \"Who are you looking for?\"",
                "They answered him, \"Jesus of Nazareth.\" Jesus said to them, \"I am he.\" Judas also, who betrayed him, was standing with them.",
                "When therefore he said to them, \"I am he,\" they went backward, and fell to the ground.",
                "Again therefore he asked them, \"Who are you looking for?\" They said, \"Jesus of Nazareth.\"",
                "Jesus answered, \"I told you that I am he. If therefore you seek me, let these go their way,\"",
                "that the word might be fulfilled which he spoke, \"Of those whom you have given me, I have lost none.\"",
                "Simon Peter therefore, having a sword, drew it, and struck the high priest\u2019s servant, and cut off his right ear. The servant\u2019s name was Malchus.",
                "Jesus therefore said to Peter, \"Put the sword into its sheath. The cup which the Father has given me, shall I not surely drink it?\"",
                "So the detachment, the commanding officer, and the officers of the Jews, seized Jesus and bound him,",
                "and led him to Annas first, for he was father-in-law to Caiaphas, who was high priest that year.",
                "Now it was Caiaphas who advised the Jews that it was expedient that one man should perish for the people.",
                "Simon Peter followed Jesus, as did another disciple. Now that disciple was known to the high priest, and entered in with Jesus into the court of the high priest;",
                "but Peter was standing at the door outside. So the other disciple, who was known to the high priest, went out and spoke to her who kept the door, and brought in Peter.",
                "Then the maid who kept the door said to Peter, \"Are you also one of this man\u2019s disciples?\" He said, \"I am not.\"",
                "Now the servants and the officers were standing there, having made a fire of coals, for it was cold. They were warming themselves. Peter was with them, standing and warming himself.",
                "The high priest therefore asked Jesus about his disciples, and about his teaching.",
                "Jesus answered him, \"I spoke openly to the world. I always taught in synagogues, and in the temple, where the Jews always meet. I said nothing in secret.",
                "Why do you ask me? Ask those who have heard me what I said to them. Behold, these know the things which I said.\"",
                "When he had said this, one of the officers standing by slapped Jesus with his hand, saying, \"Do you answer the high priest like that?\"",
                "Jesus answered him, \"If I have spoken evil, testify of the evil; but if well, why do you beat me?\"",
                "Annas sent him bound to Caiaphas, the high priest.",
                "Now Simon Peter was standing and warming himself. They said therefore to him, \"You aren\u2019t also one of his disciples, are you?\" He denied it, and said, \"I am not.\"",
                "One of the servants of the high priest, being a relative of him whose ear Peter had cut off, said, \"Didn\u2019t I see you in the garden with him?\"",
                "Peter therefore denied it again, and immediately the rooster crowed.",
                "They led Jesus therefore from Caiaphas into the Praetorium. It was early, and they themselves didn\u2019t enter into the Praetorium, that they might not be defiled, but might eat the Passover.",
                "Pilate therefore went out to them, and said, \"What accusation do you bring against this man?\"",
                "They answered him, \"If this man weren\u2019t an evildoer, we wouldn\u2019t have delivered him up to you.\"",
                "Pilate therefore said to them, \"Take him yourselves, and judge him according to your law.\" Therefore the Jews said to him, \"It is not lawful for us to put anyone to death,\"",
                "that the word of Jesus might be fulfilled, which he spoke, signifying by what kind of death he should die.",
                "Pilate therefore entered again into the Praetorium, called Jesus, and said to him, \"Are you the King of the Jews?\"",
                "Jesus answered him, \"Do you say this by yourself, or did others tell you about me?\"",
                "Pilate answered, \"I\u2019m not a Jew, am I? Your own nation and the chief priests delivered you to me. What have you done?\"",
                "Jesus answered, \"My Kingdom is not of this world. If my Kingdom were of this world, then my servants would fight, that I wouldn\u2019t be delivered to the Jews. But now my Kingdom is not from here.\"",
                "Pilate therefore said to him, \"Are you a king then?\" Jesus answered, \"You say that I am a king. For this reason I have been born, and for this reason I have come into the world, that I should testify to the truth. Everyone who is of the truth listens to my voice.\"",
                "Pilate said to him, \"What is truth?\" When he had said this, he went out again to the Jews, and said to them, \"I find no basis for a charge against him.",
                "But you have a custom, that I should release someone to you at the Passover. Therefore do you want me to release to you the King of the Jews?\"",
                "Then they all shouted again, saying, \"Not this man, but Barabbas!\" Now Barabbas was a robber."
            ],
            19: [
                "So Pilate then took Jesus, and flogged him.",
                "The soldiers twisted thorns into a crown, and put it on his head, and dressed him in a purple garment.",
                "They kept saying, \"Hail, King of the Jews!\" and they kept slapping him.",
                "Then Pilate went out again, and said to them, \"Behold, I bring him out to you, that you may know that I find no basis for a charge against him.\"",
                "Jesus therefore came out, wearing the crown of thorns and the purple garment. Pilate said to them, \"Behold, the man!\"",
                "When therefore the chief priests and the officers saw him, they shouted, saying, \"Crucify! Crucify!\" Pilate said to them, \"Take him yourselves, and crucify him, for I find no basis for a charge against him.\"",
                "The Jews answered him, \"We have a law, and by our law he ought to die, because he made himself the Son of God.\"",
                "When therefore Pilate heard this saying, he was more afraid.",
                "He entered into the Praetorium again, and said to Jesus, \"Where are you from?\" But Jesus gave him no answer.",
                "Pilate therefore said to him, \"Aren\u2019t you speaking to me? Don\u2019t you know that I have power to release you, and have power to crucify you?\"",
                "Jesus answered, \"You would have no power at all against me, unless it were given to you from above. Therefore he who delivered me to you has greater sin.\"",
                "At this, Pilate was seeking to release him, but the Jews cried out, saying, \"If you release this man, you aren\u2019t Caesar\u2019s friend! Everyone who makes himself a king speaks against Caesar!\"",
                "When Pilate therefore heard these words, he brought Jesus out, and sat down on the judgment seat at a place called \"The Pavement,\" but in Hebrew, \"Gabbatha.\"",
                "Now it was the Preparation Day of the Passover, at about the sixth hour. He said to the Jews, \"Behold, your King!\"",
                "They cried out, \"Away with him! Away with him! Crucify him!\" Pilate said to them, \"Shall I crucify your King?\" The chief priests answered, \"We have no king but Caesar!\"",
                "So then he delivered him to them to be crucified. So they took Jesus and led him away.",
                "He went out, bearing his cross, to the place called \"The Place of a Skull,\" which is called in Hebrew, \"Golgotha,\"",
                "where they crucified him, and with him two others, on either side one, and Jesus in the middle.",
                "Pilate wrote a title also, and put it on the cross. There was written, \"JESUS OF NAZARETH, THE KING OF THE JEWS.\"",
                "Therefore many of the Jews read this title, for the place where Jesus was crucified was near the city; and it was written in Hebrew, in Latin, and in Greek.",
                "The chief priests of the Jews therefore said to Pilate, \"Don\u2019t write, \u2018The King of the Jews,\u2019 but, \u2018he said, I am King of the Jews.\u2019\"",
                "Pilate answered, \"What I have written, I have written.\"",
                "Then the soldiers, when they had crucified Jesus, took his garments and made four parts, to every soldier a part; and also the coat. Now the coat was without seam, woven from the top throughout.",
                "Then they said to one another, \"Let\u2019s not tear it, but cast lots for it to decide whose it will be,\" that the Scripture might be fulfilled, which says, \"They parted my garments among them. For my cloak they cast lots.\" Therefore the soldiers did these things.",
                "But there were standing by the cross of Jesus his mother, and his mother\u2019s sister, Mary the wife of Clopas, and Mary Magdalene.",
                "Therefore when Jesus saw his mother, and the disciple whom he loved standing there, he said to his mother, \"Woman, behold your son!\"",
                "Then he said to the disciple, \"Behold, your mother!\" From that hour, the disciple took her to his own home.",
                "After this, Jesus, seeing that all things were now finished, that the Scripture might be fulfilled, said, \"I am thirsty.\"",
                "Now a vessel full of vinegar was set there; so they put a sponge full of the vinegar on hyssop, and held it at his mouth.",
                "When Jesus therefore had received the vinegar, he said, \"It is finished.\" He bowed his head, and gave up his spirit.",
                "Therefore the Jews, because it was the Preparation Day, so that the bodies wouldn\u2019t remain on the cross on the Sabbath (for that Sabbath was a special one), asked of Pilate that their legs might be broken, and that they might be taken away.",
                "Therefore the soldiers came, and broke the legs of the first, and of the other who was crucified with him;",
                "but when they came to Jesus, and saw that he was already dead, they didn\u2019t break his legs.",
                "However one of the soldiers pierced his side with a spear, and immediately blood and water came out.",
                "He who has seen has testified, and his testimony is true. He knows that he tells the truth, that you may believe.",
                "For these things happened, that the Scripture might be fulfilled, \"A bone of him will not be broken.\"",
                "Again another Scripture says, \"They will look on him whom they pierced.\"",
                "After these things, Joseph of Arimathaea, being a disciple of Jesus, but secretly for fear of the Jews, asked of Pilate that he might take away Jesus\u2019 body. Pilate gave him permission. He came therefore and took away his body.",
                "Nicodemus, who at first came to Jesus by night, also came bringing a mixture of myrrh and aloes, about a hundred Roman pounds.",
                "So they took Jesus\u2019 body, and bound it in linen cloths with the spices, as the custom of the Jews is to bury.",
                "Now in the place where he was crucified there was a garden. In the garden was a new tomb in which no man had ever yet been laid.",
                "Then because of the Jews\u2019 Preparation Day (for the tomb was near at hand) they laid Jesus there."
            ],
            20: [
                "Now on the first day of the week, Mary Magdalene went early, while it was still dark, to the tomb, and saw the stone taken away from the tomb.",
                "Therefore she ran and came to Simon Peter, and to the other disciple whom Jesus loved, and said to them, \"They have taken away the Lord out of the tomb, and we don\u2019t know where they have laid him!\"",
                "Therefore Peter and the other disciple went out, and they went toward the tomb.",
                "They both ran together. The other disciple outran Peter, and came to the tomb first.",
                "Stooping and looking in, he saw the linen cloths lying, yet he didn\u2019t enter in.",
                "Then Simon Peter came, following him, and entered into the tomb. He saw the linen cloths lying,",
                "and the cloth that had been on his head, not lying with the linen cloths, but rolled up in a place by itself.",
                "So then the other disciple who came first to the tomb also entered in, and he saw and believed.",
                "For as yet they didn\u2019t know the Scripture, that he must rise from the dead.",
                "So the disciples went away again to their own homes.",
                "But Mary was standing outside at the tomb weeping. So, as she wept, she stooped and looked into the tomb,",
                "and she saw two angels in white sitting, one at the head, and one at the feet, where the body of Jesus had lain.",
                "They told her, \"Woman, why are you weeping?\" She said to them, \"Because they have taken away my Lord, and I don\u2019t know where they have laid him.\"",
                "When she had said this, she turned around and saw Jesus standing, and didn\u2019t know that it was Jesus.",
                "Jesus said to her, \"Woman, why are you weeping? Who are you looking for?\" She, supposing him to be the gardener, said to him, \"Sir, if you have carried him away, tell me where you have laid him, and I will take him away.\"",
                "Jesus said to her, \"Mary.\" She turned and said to him, \"Rabboni!\" which is to say, \"Teacher!\"",
                "Jesus said to her, \"Don\u2019t hold me, for I haven\u2019t yet ascended to my Father; but go to my brothers, and tell them, \u2018I am ascending to my Father and your Father, to my God and your God.\u2019\"",
                "Mary Magdalene came and told the disciples that she had seen the Lord, and that he had said these things to her.",
                "When therefore it was evening, on that day, the first day of the week, and when the doors were locked where the disciples were assembled, for fear of the Jews, Jesus came and stood in the midst, and said to them, \"Peace be to you.\"",
                "When he had said this, he showed them his hands and his side. The disciples therefore were glad when they saw the Lord.",
                "Jesus therefore said to them again, \"Peace be to you. As the Father has sent me, even so I send you.\"",
                "When he had said this, he breathed on them, and said to them, \"Receive the Holy Spirit!",
                "If you forgive anyone\u2019s sins, they have been forgiven them. If you retain anyone\u2019s sins, they have been retained.\"",
                "But Thomas, one of the twelve, called Didymus, wasn\u2019t with them when Jesus came.",
                "The other disciples therefore said to him, \"We have seen the Lord!\" But he said to them, \"Unless I see in his hands the print of the nails, and put my hand into his side, I will not believe.\"",
                "After eight days again his disciples were inside, and Thomas was with them. Jesus came, the doors being locked, and stood in the midst, and said, \"Peace be to you.\"",
                "Then he said to Thomas, \"Reach here your finger, and see my hands. Reach here your hand, and put it into my side. Don\u2019t be unbelieving, but believing.\"",
                "Thomas answered him, \"My Lord and my God!\"",
                "Jesus said to him, \"Because you have seen me, you have believed. Blessed are those who have not seen, and have believed.\"",
                "Therefore Jesus did many other signs in the presence of his disciples, which are not written in this book;",
                "but these are written, that you may believe that Jesus is the Christ, the Son of God, and that believing you may have life in his name."
            ],
            21: [
                "After these things, Jesus revealed himself again to the disciples at the sea of Tiberias. He revealed himself this way.",
                "Simon Peter, Thomas called Didymus, Nathanael of Cana in Galilee, and the sons of Zebedee, and two others of his disciples were together.",
                "Simon Peter said to them, \"I\u2019m going fishing.\" They told him, \"We are also coming with you.\" They immediately went out, and entered into the boat. That night, they caught nothing.",
                "But when day had already come, Jesus stood on the beach, yet the disciples didn\u2019t know that it was Jesus.",
                "Jesus therefore said to them, \"Children, have you anything to eat?\" They answered him, \"No.\"",
                "He said to them, \"Cast the net on the right side of the boat, and you will find some.\" They cast it therefore, and now they weren\u2019t able to draw it in for the multitude of fish.",
                "That disciple therefore whom Jesus loved said to Peter, \"It\u2019s the Lord!\" So when Simon Peter heard that it was the Lord, he wrapped his coat around him (for he was naked), and threw himself into the sea.",
                "But the other disciples came in the little boat (for they were not far from the land, but about two hundred cubits away), dragging the net full of fish.",
                "So when they got out on the land, they saw a fire of coals there, and fish laid on it, and bread.",
                "Jesus said to them, \"Bring some of the fish which you have just caught.\"",
                "Simon Peter went up, and drew the net to land, full of great fish, one hundred fifty-three; and even though there were so many, the net wasn\u2019t torn.",
                "Jesus said to them, \"Come and eat breakfast.\" None of the disciples dared inquire of him, \"Who are you?\" knowing that it was the Lord.",
                "Then Jesus came and took the bread, gave it to them, and the fish likewise.",
                "This is now the third time that Jesus was revealed to his disciples, after he had risen from the dead.",
                "So when they had eaten their breakfast, Jesus said to Simon Peter, \"Simon, son of Jonah, do you love me more than these?\" He said to him, \"Yes, Lord; you know that I have affection for you.\" He said to him, \"Feed my lambs.\"",
                "He said to him again a second time, \"Simon, son of Jonah, do you love me?\" He said to him, \"Yes, Lord; you know that I have affection for you.\" He said to him, \"Tend my sheep.\"",
                "He said to him the third time, \"Simon, son of Jonah, do you have affection for me?\" Peter was grieved because he asked him the third time, \"Do you have affection for me?\" He said to him, \"Lord, you know everything. You know that I have affection for you.\" Jesus said to him, \"Feed my sheep.",
                "Most certainly I tell you, when you were young, you dressed yourself, and walked where you wanted to. But when you are old, you will stretch out your hands, and another will dress you, and carry you where you don\u2019t want to go.\"",
                "Now he said this, signifying by what kind of death he would glorify God. When he had said this, he said to him, \"Follow me.\"",
                "Then Peter, turning around, saw a disciple following. This was the disciple whom Jesus sincerely loved, the one who had also leaned on Jesus\u2019 breast at the supper and asked, \"Lord, who is going to betray You?\"",
                "Peter seeing him, said to Jesus, \"Lord, what about this man?\"",
                "Jesus said to him, \"If I desire that he stay until I come, what is that to you? You follow me.\"",
                "This saying therefore went out among the brothers, that this disciple wouldn\u2019t die. Yet Jesus didn\u2019t say to him that he wouldn\u2019t die, but, \"If I desire that he stay until I come, what is that to you?\"",
                "This is the disciple who testifies about these things, and wrote these things. We know that his witness is true.",
                "There are also many other things which Jesus did, which if they would all be written, I suppose that even the world itself wouldn\u2019t have room for the books that would be written."
            ]
        },
        44: {
            1: [
                "The first book I wrote, Theophilus, concerned all that Jesus began both to do and to teach,",
                "until the day in which he was received up, after he had given commandment through the Holy Spirit to the apostles whom he had chosen.",
                "To these he also showed himself alive after he suffered, by many proofs, appearing to them over a period of forty days, and speaking about God\u2019s Kingdom.",
                "Being assembled together with them, he commanded them, \"Don\u2019t depart from Jerusalem, but wait for the promise of the Father, which you heard from me.",
                "For John indeed baptized in water, but you will be baptized in the Holy Spirit not many days from now.\"",
                "Therefore, when they had come together, they asked him, \"Lord, are you now restoring the kingdom to Israel?\"",
                "He said to them, \"It isn\u2019t for you to know times or seasons which the Father has set within his own authority.",
                "But you will receive power when the Holy Spirit has come upon you. You will be witnesses to me in Jerusalem, in all Judea and Samaria, and to the uttermost parts of the earth.\"",
                "When he had said these things, as they were looking, he was taken up, and a cloud received him out of their sight.",
                "While they were looking steadfastly into the sky as he went, behold, two men stood by them in white clothing,",
                "who also said, \"You men of Galilee, why do you stand looking into the sky? This Jesus, who was received up from you into the sky will come back in the same way as you saw him going into the sky.\"",
                "Then they returned to Jerusalem from the mountain called Olivet, which is near Jerusalem, a Sabbath day\u2019s journey away.",
                "When they had come in, they went up into the upper room, where they were staying; that is Peter, John, James, Andrew, Philip, Thomas, Bartholomew, Matthew, James the son of Alphaeus, Simon the Zealot, and Judas the son of James.",
                "All these with one accord continued steadfastly in prayer and supplication, along with the women, and Mary the mother of Jesus, and with his brothers.",
                "In these days, Peter stood up in the midst of the disciples (and the number of names was about one hundred twenty), and said,",
                "\"Brothers, it was necessary that this Scripture should be fulfilled, which the Holy Spirit spoke before by the mouth of David concerning Judas, who was guide to those who took Jesus.",
                "For he was numbered with us, and received his portion in this ministry.",
                "Now this man obtained a field with the reward for his wickedness, and falling headlong, his body burst open, and all his intestines gushed out.",
                "It became known to everyone who lived in Jerusalem that in their language that field was called \u2018Akeldama,\u2019 that is, \u2018The field of blood.\u2019",
                "For it is written in the book of Psalms, \u2018Let his habitation be made desolate. Let no one dwell therein;\u2019 and, \u2018Let another take his office.\u2019",
                "\"Of the men therefore who have accompanied us all the time that the Lord Jesus went in and out among us,",
                "beginning from the baptism of John, to the day that he was received up from us, of these one must become a witness with us of his resurrection.\"",
                "They put forward two, Joseph called Barsabbas, who was surnamed Justus, and Matthias.",
                "They prayed, and said, \"You, Lord, who know the hearts of all men, show which one of these two you have chosen",
                "to take part in this ministry and apostleship from which Judas fell away, that he might go to his own place.\"",
                "They drew lots for them, and the lot fell on Matthias, and he was numbered with the eleven apostles."
            ],
            2: [
                "Now when the day of Pentecost had come, they were all with one accord in one place.",
                "Suddenly there came from the sky a sound like the rushing of a mighty wind, and it filled all the house where they were sitting.",
                "Tongues like fire appeared and were distributed to them, and one sat on each of them.",
                "They were all filled with the Holy Spirit, and began to speak with other languages, as the Spirit gave them the ability to speak.",
                "Now there were dwelling in Jerusalem Jews, devout men, from every nation under the sky.",
                "When this sound was heard, the multitude came together, and were bewildered, because everyone heard them speaking in his own language.",
                "They were all amazed and marveled, saying to one another, \"Behold, aren\u2019t all these who speak Galileans?",
                "How do we hear, everyone in our own native language?",
                "Parthians, Medes, Elamites, and people from Mesopotamia, Judea, Cappadocia, Pontus, Asia,",
                "Phrygia, Pamphylia, Egypt, the parts of Libya around Cyrene, visitors from Rome, both Jews and proselytes,",
                "Cretans and Arabians: we hear them speaking in our languages the mighty works of God!\"",
                "They were all amazed, and were perplexed, saying one to another, \"What does this mean?\"",
                "Others, mocking, said, \"They are filled with new wine.\"",
                "But Peter, standing up with the eleven, lifted up his voice, and spoke out to them, \"You men of Judea, and all you who dwell at Jerusalem, let this be known to you, and listen to my words.",
                "For these aren\u2019t drunken, as you suppose, seeing it is only the third hour of the day.",
                "But this is what has been spoken through the prophet Joel:",
                "\u2018It will be in the last days, says God, that I will pour out my Spirit on all flesh. Your sons and your daughters will prophesy. Your young men will see visions. Your old men will dream dreams.",
                "Yes, and on my servants and on my handmaidens in those days, I will pour out my Spirit, and they will prophesy.",
                "I will show wonders in the sky above, and signs on the earth beneath; blood, and fire, and billows of smoke.",
                "The sun will be turned into darkness, and the moon into blood, before the great and glorious day of the Lord comes.",
                "It will be, that whoever will call on the name of the Lord will be saved.\u2019",
                "\"Men of Israel, hear these words! Jesus of Nazareth, a man approved by God to you by mighty works and wonders and signs which God did by him in the midst of you, even as you yourselves know,",
                "him, being delivered up by the determined counsel and foreknowledge of God, you have taken by the hand of lawless men, crucified and killed;",
                "whom God raised up, having freed him from the agony of death, because it was not possible that he should be held by it.",
                "For David says concerning him, \u2018I saw the Lord always before my face, For he is on my right hand, that I should not be moved.",
                "Therefore my heart was glad, and my tongue rejoiced. Moreover my flesh also will dwell in hope;",
                "because you will not leave my soul in Hades, neither will you allow your Holy One to see decay.",
                "You made known to me the ways of life. You will make me full of gladness with your presence.\u2019",
                "\"Brothers, I may tell you freely of the patriarch David, that he both died and was buried, and his tomb is with us to this day.",
                "Therefore, being a prophet, and knowing that God had sworn with an oath to him that of the fruit of his body, according to the flesh, he would raise up the Christ to sit on his throne,",
                "he foreseeing this spoke about the resurrection of the Christ, that neither was his soul left in Hades, nor did his flesh see decay.",
                "This Jesus God raised up, to which we all are witnesses.",
                "Being therefore exalted by the right hand of God, and having received from the Father the promise of the Holy Spirit, he has poured out this, which you now see and hear.",
                "For David didn\u2019t ascend into the heavens, but he says himself, \u2018The Lord said to my Lord, \"Sit by my right hand,",
                "until I make your enemies a footstool for your feet.\"\u2019",
                "\"Let all the house of Israel therefore know certainly that God has made him both Lord and Christ, this Jesus whom you crucified.\"",
                "Now when they heard this, they were cut to the heart, and said to Peter and the rest of the apostles, \"Brothers, what shall we do?\"",
                "Peter said to them, \"Repent, and be baptized, every one of you, in the name of Jesus Christ for the forgiveness of sins, and you will receive the gift of the Holy Spirit.",
                "For the promise is to you, and to your children, and to all who are far off, even as many as the Lord our God will call to himself.\"",
                "With many other words he testified, and exhorted them, saying, \"Save yourselves from this crooked generation!\"",
                "Then those who gladly received his word were baptized. There were added that day about three thousand souls.",
                "They continued steadfastly in the apostles\u2019 teaching and fellowship, in the breaking of bread, and prayer.",
                "Fear came on every soul, and many wonders and signs were done through the apostles.",
                "All who believed were together, and had all things in common.",
                "They sold their possessions and goods, and distributed them to all, according as anyone had need.",
                "Day by day, continuing steadfastly with one accord in the temple, and breaking bread at home, they took their food with gladness and singleness of heart,",
                "praising God, and having favor with all the people. The Lord added to the assembly day by day those who were being saved."
            ],
            3: [
                "Peter and John were going up into the temple at the hour of prayer, the ninth hour.",
                "A certain man who was lame from his mother\u2019s womb was being carried, whom they laid daily at the door of the temple which is called Beautiful, to ask gifts for the needy of those who entered into the temple.",
                "Seeing Peter and John about to go into the temple, he asked to receive gifts for the needy.",
                "Peter, fastening his eyes on him, with John, said, \"Look at us.\"",
                "He listened to them, expecting to receive something from them.",
                "But Peter said, \"Silver and gold have I none, but what I have, that I give you. In the name of Jesus Christ of Nazareth, get up and walk!\"",
                "He took him by the right hand, and raised him up. Immediately his feet and his ankle bones received strength.",
                "Leaping up, he stood, and began to walk. He entered with them into the temple, walking, leaping, and praising God.",
                "All the people saw him walking and praising God.",
                "They recognized him, that it was he who used to sit begging for gifts for the needy at the Beautiful Gate of the temple. They were filled with wonder and amazement at what had happened to him.",
                "As the lame man who was healed held on to Peter and John, all the people ran together to them in the porch that is called Solomon\u2019s, greatly wondering.",
                "When Peter saw it, he responded to the people, \"You men of Israel, why do you marvel at this man? Why do you fasten your eyes on us, as though by our own power or godliness we had made him walk?",
                "The God of Abraham, Isaac, and Jacob, the God of our fathers, has glorified his Servant Jesus, whom you delivered up, and denied in the presence of Pilate, when he had determined to release him.",
                "But you denied the Holy and Righteous One, and asked for a murderer to be granted to you,",
                "and killed the Prince of life, whom God raised from the dead, to which we are witnesses.",
                "By faith in his name, his name has made this man strong, whom you see and know. Yes, the faith which is through him has given him this perfect soundness in the presence of you all.",
                "\"Now, brothers, I know that you did this in ignorance, as did also your rulers.",
                "But the things which God announced by the mouth of all his prophets, that Christ should suffer, he thus fulfilled.",
                "\"Repent therefore, and turn again, that your sins may be blotted out, so that there may come times of refreshing from the presence of the Lord,",
                "and that he may send Christ Jesus, who was ordained for you before,",
                "whom heaven must receive until the times of restoration of all things, which God spoke long ago by the mouth of his holy prophets.",
                "For Moses indeed said to the fathers, \u2018The Lord God will raise up a prophet for you from among your brothers, like me. You shall listen to him in all things whatever he says to you.",
                "It will be, that every soul that will not listen to that prophet will be utterly destroyed from among the people.\u2019",
                "Yes, and all the prophets from Samuel and those who followed after, as many as have spoken, they also told of these days.",
                "You are the children of the prophets, and of the covenant which God made with our fathers, saying to Abraham, \u2018In your seed will all the families of the earth be blessed.\u2019",
                "God, having raised up his servant, Jesus, sent him to you first, to bless you, in turning away everyone of you from your wickedness.\""
            ],
            4: [
                "As they spoke to the people, the priests and the captain of the temple and the Sadducees came to them,",
                "being upset because they taught the people and proclaimed in Jesus the resurrection from the dead.",
                "They laid hands on them, and put them in custody until the next day, for it was now evening.",
                "But many of those who heard the word believed, and the number of the men came to be about five thousand.",
                "It happened in the morning, that their rulers, elders, and scribes were gathered together in Jerusalem.",
                "Annas the high priest was there, with Caiaphas, John, Alexander, and as many as were relatives of the high priest.",
                "When they had stood them in the middle of them, they inquired, \"By what power, or in what name, have you done this?\"",
                "Then Peter, filled with the Holy Spirit, said to them, \"You rulers of the people, and elders of Israel,",
                "if we are examined today concerning a good deed done to a crippled man, by what means this man has been healed,",
                "be it known to you all, and to all the people of Israel, that in the name of Jesus Christ of Nazareth, whom you crucified, whom God raised from the dead, in him does this man stand here before you whole.",
                "He is \u2018the stone which was regarded as worthless by you, the builders, which has become the head of the corner.\u2019",
                "There is salvation in none other, for neither is there any other name under heaven, that is given among men, by which we must be saved!\"",
                "Now when they saw the boldness of Peter and John, and had perceived that they were unlearned and ignorant men, they marveled. They recognized that they had been with Jesus.",
                "Seeing the man who was healed standing with them, they could say nothing against it.",
                "But when they had commanded them to go aside out of the council, they conferred among themselves,",
                "saying, \"What shall we do to these men? Because indeed a notable miracle has been done through them, as can be plainly seen by all who dwell in Jerusalem, and we can\u2019t deny it.",
                "But so that this spreads no further among the people, let\u2019s threaten them, that from now on they don\u2019t speak to anyone in this name.\"",
                "They called them, and commanded them not to speak at all nor teach in the name of Jesus.",
                "But Peter and John answered them, \"Whether it is right in the sight of God to listen to you rather than to God, judge for yourselves,",
                "for we can\u2019t help telling the things which we saw and heard.\"",
                "When they had further threatened them, they let them go, finding no way to punish them, because of the people; for everyone glorified God for that which was done.",
                "For the man on whom this miracle of healing was performed was more than forty years old.",
                "Being let go, they came to their own company, and reported all that the chief priests and the elders had said to them.",
                "When they heard it, they lifted up their voice to God with one accord, and said, \"O Lord, you are God, who made the heaven, the earth, the sea, and all that is in them;",
                "who by the mouth of your servant, David, said, \u2018Why do the nations rage, and the peoples plot a vain thing?",
                "The kings of the earth take a stand, and the rulers take council together, against the Lord, and against his Christ.\u2019",
                "\"For truly, in this city against your holy servant, Jesus, whom you anointed, both Herod and Pontius Pilate, with the Gentiles and the people of Israel, were gathered together",
                "to do whatever your hand and your council foreordained to happen.",
                "Now, Lord, look at their threats, and grant to your servants to speak your word with all boldness,",
                "while you stretch out your hand to heal; and that signs and wonders may be done through the name of your holy Servant Jesus.\"",
                "When they had prayed, the place was shaken where they were gathered together. They were all filled with the Holy Spirit, and they spoke the word of God with boldness.",
                "The multitude of those who believed were of one heart and soul. Not one of them claimed that anything of the things which he possessed was his own, but they had all things in common.",
                "With great power, the apostles gave their testimony of the resurrection of the Lord Jesus. Great grace was on them all.",
                "For neither was there among them any who lacked, for as many as were owners of lands or houses sold them, and brought the proceeds of the things that were sold,",
                "and laid them at the apostles\u2019 feet, and distribution was made to each, according as anyone had need.",
                "Joses, who by the apostles was surnamed Barnabas (which is, being interpreted, Son of Encouragement), a Levite, a man of Cyprus by race,",
                "having a field, sold it, and brought the money and laid it at the apostles\u2019 feet."
            ],
            5: [
                "But a certain man named Ananias, with Sapphira, his wife, sold a possession,",
                "and kept back part of the price, his wife also being aware of it, and brought a certain part, and laid it at the apostles\u2019 feet.",
                "But Peter said, \"Ananias, why has Satan filled your heart to lie to the Holy Spirit, and to keep back part of the price of the land?",
                "While you kept it, didn\u2019t it remain your own? After it was sold, wasn\u2019t it in your power? How is it that you have conceived this thing in your heart? You haven\u2019t lied to men, but to God.\"",
                "Ananias, hearing these words, fell down and died. Great fear came on all who heard these things.",
                "The young men arose and wrapped him up, and they carried him out and buried him.",
                "About three hours later, his wife, not knowing what had happened, came in.",
                "Peter answered her, \"Tell me whether you sold the land for so much.\" She said, \"Yes, for so much.\"",
                "But Peter asked her, \"How is it that you have agreed together to tempt the Spirit of the Lord? Behold, the feet of those who have buried your husband are at the door, and they will carry you out.\"",
                "She fell down immediately at his feet, and died. The young men came in and found her dead, and they carried her out and buried her by her husband.",
                "Great fear came on the whole assembly, and on all who heard these things.",
                "By the hands of the apostles many signs and wonders were done among the people. They were all with one accord in Solomon\u2019s porch.",
                "None of the rest dared to join them, however the people honored them.",
                "More believers were added to the Lord, multitudes of both men and women.",
                "They even carried out the sick into the streets, and laid them on cots and mattresses, so that as Peter came by, at the least his shadow might overshadow some of them.",
                "Multitudes also came together from the cities around Jerusalem, bringing sick people, and those who were tormented by unclean spirits: and they were all healed.",
                "But the high priest rose up, and all those who were with him (which is the sect of the Sadducees), and they were filled with jealousy,",
                "and laid hands on the apostles, and put them in public custody.",
                "But an angel of the Lord opened the prison doors by night, and brought them out, and said,",
                "\"Go stand and speak in the temple to the people all the words of this life.\"",
                "When they heard this, they entered into the temple about daybreak, and taught. But the high priest came, and those who were with him, and called the council together, and all the senate of the children of Israel, and sent to the prison to have them brought.",
                "But the officers who came didn\u2019t find them in the prison. They returned and reported,",
                "\"We found the prison shut and locked, and the guards standing before the doors, but when we opened them, we found no one inside!\"",
                "Now when the high priest, the captain of the temple, and the chief priests heard these words, they were very perplexed about them and what might become of this.",
                "One came and told them, \"Behold, the men whom you put in prison are in the temple, standing and teaching the people.\"",
                "Then the captain went with the officers, and brought them without violence, for they were afraid that the people might stone them.",
                "When they had brought them, they set them before the council. The high priest questioned them,",
                "saying, \"Didn\u2019t we strictly command you not to teach in this name? Behold, you have filled Jerusalem with your teaching, and intend to bring this man\u2019s blood on us.\"",
                "But Peter and the apostles answered, \"We must obey God rather than men.",
                "The God of our fathers raised up Jesus, whom you killed, hanging him on a tree.",
                "God exalted him with his right hand to be a Prince and a Savior, to give repentance to Israel, and remission of sins.",
                "We are His witnesses of these things; and so also is the Holy Spirit, whom God has given to those who obey him.\"",
                "But they, when they heard this, were cut to the heart, and determined to kill them.",
                "But one stood up in the council, a Pharisee named Gamaliel, a teacher of the law, honored by all the people, and commanded to put the apostles out for a little while.",
                "He said to them, \"You men of Israel, be careful concerning these men, what you are about to do.",
                "For before these days Theudas rose up, making himself out to be somebody; to whom a number of men, about four hundred, joined themselves: who was slain; and all, as many as obeyed him, were dispersed, and came to nothing.",
                "After this man, Judas of Galilee rose up in the days of the enrollment, and drew away some people after him. He also perished, and all, as many as obeyed him, were scattered abroad.",
                "Now I tell you, withdraw from these men, and leave them alone. For if this counsel or this work is of men, it will be overthrown.",
                "But if it is of God, you will not be able to overthrow it, and you would be found even to be fighting against God!\"",
                "They agreed with him. Summoning the apostles, they beat them and commanded them not to speak in the name of Jesus, and let them go.",
                "They therefore departed from the presence of the council, rejoicing that they were counted worthy to suffer dishonor for Jesus\u2019 name.",
                "Every day, in the temple and at home, they never stopped teaching and preaching Jesus, the Christ."
            ],
            6: [
                "Now in those days, when the number of the disciples was multiplying, a complaint arose from the Hellenists against the Hebrews, because their widows were neglected in the daily service.",
                "The twelve summoned the multitude of the disciples and said, \"It is not appropriate for us to forsake the word of God and serve tables.",
                "Therefore select from among you, brothers, seven men of good report, full of the Holy Spirit and of wisdom, whom we may appoint over this business.",
                "But we will continue steadfastly in prayer and in the ministry of the word.\"",
                "These words pleased the whole multitude. They chose Stephen, a man full of faith and of the Holy Spirit, Philip, Prochorus, Nicanor, Timon, Parmenas, and Nicolaus, a proselyte of Antioch;",
                "whom they set before the apostles. When they had prayed, they laid their hands on them.",
                "The word of God increased and the number of the disciples multiplied in Jerusalem exceedingly. A great company of the priests were obedient to the faith.",
                "Stephen, full of faith and power, performed great wonders and signs among the people.",
                "But some of those who were of the synagogue called \"The Libertines,\" and of the Cyrenians, of the Alexandrians, and of those of Cilicia and Asia arose, disputing with Stephen.",
                "They weren\u2019t able to withstand the wisdom and the Spirit by which he spoke.",
                "Then they secretly induced men to say, \"We have heard him speak blasphemous words against Moses and God.\"",
                "They stirred up the people, the elders, and the scribes, and came against him and seized him, and brought him in to the council,",
                "and set up false witnesses who said, \"This man never stops speaking blasphemous words against this holy place and the law.",
                "For we have heard him say that this Jesus of Nazareth will destroy this place, and will change the customs which Moses delivered to us.\"",
                "All who sat in the council, fastening their eyes on him, saw his face like it was the face of an angel."
            ],
            7: [
                "The high priest said, \"Are these things so?\"",
                "He said, \"Brothers and fathers, listen. The God of glory appeared to our father Abraham, when he was in Mesopotamia, before he lived in Haran,",
                "and said to him, \u2018Get out of your land, and from your relatives, and come into a land which I will show you.\u2019",
                "Then he came out of the land of the Chaldaeans, and lived in Haran. From there, when his father was dead, God moved him into this land, where you are now living.",
                "He gave him no inheritance in it, no, not so much as to set his foot on. He promised that he would give it to him for a possession, and to his seed after him, when he still had no child.",
                "God spoke in this way: that his seed would live as aliens in a strange land, and that they would be enslaved and mistreated for four hundred years.",
                "\u2018I will judge the nation to which they will be in bondage,\u2019 said God, \u2018and after that will they come out, and serve me in this place.\u2019",
                "He gave him the covenant of circumcision. So Abraham became the father of Isaac, and circumcised him the eighth day. Isaac became the father of Jacob, and Jacob became the father of the twelve patriarchs.",
                "\"The patriarchs, moved with jealousy against Joseph, sold him into Egypt. God was with him,",
                "and delivered him out of all his afflictions, and gave him favor and wisdom before Pharaoh, king of Egypt. He made him governor over Egypt and all his house.",
                "Now a famine came over all the land of Egypt and Canaan, and great affliction. Our fathers found no food.",
                "But when Jacob heard that there was grain in Egypt, he sent out our fathers the first time.",
                "On the second time Joseph was made known to his brothers, and Joseph\u2019s race was revealed to Pharaoh.",
                "Joseph sent, and summoned Jacob, his father, and all his relatives, seventy-five souls.",
                "Jacob went down into Egypt, and he died, himself and our fathers,",
                "and they were brought back to Shechem, and laid in the tomb that Abraham bought for a price in silver from the children of Hamor of Shechem.",
                "\"But as the time of the promise came close which God had sworn to Abraham, the people grew and multiplied in Egypt,",
                "until there arose a different king, who didn\u2019t know Joseph.",
                "The same took advantage of our race, and mistreated our fathers, and forced them to throw out their babies, so that they wouldn\u2019t stay alive.",
                "At that time Moses was born, and was exceedingly handsome. He was nourished three months in his father\u2019s house.",
                "When he was thrown out, Pharaoh\u2019s daughter took him up, and reared him as her own son.",
                "Moses was instructed in all the wisdom of the Egyptians. He was mighty in his words and works.",
                "But when he was forty years old, it came into his heart to visit his brothers, the children of Israel.",
                "Seeing one of them suffer wrong, he defended him, and avenged him who was oppressed, striking the Egyptian.",
                "He supposed that his brothers understood that God, by his hand, was giving them deliverance; but they didn\u2019t understand.",
                "\"The day following, he appeared to them as they fought, and urged them to be at peace again, saying, \u2018Sirs, you are brothers. Why do you wrong one another?\u2019",
                "But he who did his neighbor wrong pushed him away, saying, \u2018Who made you a ruler and a judge over us?",
                "Do you want to kill me, as you killed the Egyptian yesterday?\u2019",
                "Moses fled at this saying, and became a stranger in the land of Midian, where he became the father of two sons.",
                "\"When forty years were fulfilled, an angel of the Lord appeared to him in the wilderness of Mount Sinai, in a flame of fire in a bush.",
                "When Moses saw it, he wondered at the sight. As he came close to see, a voice of the Lord came to him,",
                "\u2018I am the God of your fathers, the God of Abraham, the God of Isaac, and the God of Jacob.\u2019 Moses trembled, and dared not look.",
                "The Lord said to him, \u2018Take your sandals off of your feet, for the place where you stand is holy ground.",
                "I have surely seen the affliction of my people that is in Egypt, and have heard their groaning. I have come down to deliver them. Now come, I will send you into Egypt.\u2019",
                "\"This Moses, whom they refused, saying, \u2018Who made you a ruler and a judge?\u2019\u2014God has sent him as both a ruler and a deliverer by the hand of the angel who appeared to him in the bush.",
                "This man led them out, having worked wonders and signs in Egypt, in the Red Sea, and in the wilderness for forty years.",
                "This is that Moses, who said to the children of Israel, \u2018The Lord our God will raise up a prophet for you from among your brothers, like me.\u2019",
                "This is he who was in the assembly in the wilderness with the angel that spoke to him on Mount Sinai, and with our fathers, who received living oracles to give to us,",
                "to whom our fathers wouldn\u2019t be obedient, but rejected him, and turned back in their hearts to Egypt,",
                "saying to Aaron, \u2018Make us gods that will go before us, for as for this Moses, who led us out of the land of Egypt, we don\u2019t know what has become of him.\u2019",
                "They made a calf in those days, and brought a sacrifice to the idol, and rejoiced in the works of their hands.",
                "But God turned, and gave them up to serve the army of the sky, as it is written in the book of the prophets, \u2018Did you offer to me slain animals and sacrifices forty years in the wilderness, O house of Israel?",
                "You took up the tabernacle of Moloch, the star of your god Rephan, the figures which you made to worship. I will carry you away beyond Babylon.\u2019",
                "\"Our fathers had the tabernacle of the testimony in the wilderness, even as he who spoke to Moses commanded him to make it according to the pattern that he had seen;",
                "which also our fathers, in their turn, brought in with Joshua when they entered into the possession of the nations, whom God drove out before the face of our fathers, to the days of David,",
                "who found favor in the sight of God, and asked to find a habitation for the God of Jacob.",
                "But Solomon built him a house.",
                "However, the Most High doesn\u2019t dwell in temples made with hands, as the prophet says,",
                "\u2018heaven is my throne, and the earth a footstool for my feet. What kind of house will you build me?\u2019 says the Lord; \u2018or what is the place of my rest?",
                "Didn\u2019t my hand make all these things?\u2019",
                "\"You stiff-necked and uncircumcised in heart and ears, you always resist the Holy Spirit! As your fathers did, so you do.",
                "Which of the prophets didn\u2019t your fathers persecute? They killed those who foretold the coming of the Righteous One, of whom you have now become betrayers and murderers.",
                "You received the law as it was ordained by angels, and didn\u2019t keep it!\"",
                "Now when they heard these things, they were cut to the heart, and they gnashed at him with their teeth.",
                "But he, being full of the Holy Spirit, looked up steadfastly into heaven, and saw the glory of God, and Jesus standing on the right hand of God,",
                "and said, \"Behold, I see the heavens opened, and the Son of Man standing at the right hand of God!\"",
                "But they cried out with a loud voice, and stopped their ears, and rushed at him with one accord.",
                "They threw him out of the city, and stoned him. The witnesses placed their garments at the feet of a young man named Saul.",
                "They stoned Stephen as he called out, saying, \"Lord Jesus, receive my spirit!\"",
                "He kneeled down, and cried with a loud voice, \"Lord, don\u2019t hold this sin against them!\" When he had said this, he fell asleep."
            ],
            8: [
                "Saul was consenting to his death. A great persecution arose against the assembly which was in Jerusalem in that day. They were all scattered abroad throughout the regions of Judea and Samaria, except for the apostles.",
                "Devout men buried Stephen, and lamented greatly over him.",
                "But Saul ravaged the assembly, entering into every house, and dragged both men and women off to prison.",
                "Therefore those who were scattered abroad went around preaching the word.",
                "Philip went down to the city of Samaria, and proclaimed to them the Christ.",
                "The multitudes listened with one accord to the things that were spoken by Philip, when they heard and saw the signs which he did.",
                "For unclean spirits came out of many of those who had them. They came out, crying with a loud voice. Many who had been paralyzed and lame were healed.",
                "There was great joy in that city.",
                "But there was a certain man, Simon by name, who used to practice sorcery in the city, and amazed the people of Samaria, making himself out to be some great one,",
                "to whom they all listened, from the least to the greatest, saying, \"This man is that great power of God.\"",
                "They listened to him, because for a long time he had amazed them with his sorceries.",
                "But when they believed Philip preaching good news concerning the Kingdom of God and the name of Jesus Christ, they were baptized, both men and women.",
                "Simon himself also believed. Being baptized, he continued with Philip. Seeing signs and great miracles occurring, he was amazed.",
                "Now when the apostles who were at Jerusalem heard that Samaria had received the word of God, they sent Peter and John to them,",
                "who, when they had come down, prayed for them, that they might receive the Holy Spirit;",
                "for as yet he had fallen on none of them. They had only been baptized in the name of Christ Jesus.",
                "Then they laid their hands on them, and they received the Holy Spirit.",
                "Now when Simon saw that the Holy Spirit was given through the laying on of the apostles\u2019 hands, he offered them money,",
                "saying, \"Give me also this power, that whomever I lay my hands on may receive the Holy Spirit.\"",
                "But Peter said to him, \"May your silver perish with you, because you thought you could obtain the gift of God with money!",
                "You have neither part nor lot in this matter, for your heart isn\u2019t right before God.",
                "Repent therefore of this, your wickedness, and ask God if perhaps the thought of your heart may be forgiven you.",
                "For I see that you are in the gall of bitterness and in the bondage of iniquity.\"",
                "Simon answered, \"Pray for me to the Lord, that none of the things which you have spoken happen to me.\"",
                "They therefore, when they had testified and spoken the word of the Lord, returned to Jerusalem, and preached the Good News to many villages of the Samaritans.",
                "But an angel of the Lord spoke to Philip, saying, \"Arise, and go toward the south to the way that goes down from Jerusalem to Gaza. This is a desert.\"",
                "He arose and went; and behold, there was a man of Ethiopia, a eunuch of great authority under Candace, queen of the Ethiopians, who was over all her treasure, who had come to Jerusalem to worship.",
                "He was returning and sitting in his chariot, and was reading the prophet Isaiah.",
                "The Spirit said to Philip, \"Go near, and join yourself to this chariot.\"",
                "Philip ran to him, and heard him reading Isaiah the prophet, and said, \"Do you understand what you are reading?\"",
                "He said, \"How can I, unless someone explains it to me?\" He begged Philip to come up and sit with him.",
                "Now the passage of the Scripture which he was reading was this, \"He was led as a sheep to the slaughter. As a lamb before his shearer is silent, so he doesn\u2019t open his mouth.",
                "In his humiliation, his judgment was taken away. Who will declare His generation? For his life is taken from the earth.\"",
                "The eunuch answered Philip, \"Who is the prophet talking about? About himself, or about someone else?\"",
                "Philip opened his mouth, and beginning from this Scripture, preached to him Jesus.",
                "As they went on the way, they came to some water, and the eunuch said, \"Behold, here is water. What is keeping me from being baptized?\"",
                "He commanded the chariot to stand still, and they both went down into the water, both Philip and the eunuch, and he baptized him.",
                "When they came up out of the water, the Spirit of the Lord caught Philip away, and the eunuch didn\u2019t see him any more, for he went on his way rejoicing.",
                "But Philip was found at Azotus. Passing through, he preached the Good News to all the cities, until he came to Caesarea."
            ],
            9: [
                "But Saul, still breathing threats and slaughter against the disciples of the Lord, went to the high priest,",
                "and asked for letters from him to the synagogues of Damascus, that if he found any who were of the Way, whether men or women, he might bring them bound to Jerusalem.",
                "As he traveled, it happened that he got close to Damascus, and suddenly a light from the sky shone around him.",
                "He fell on the earth, and heard a voice saying to him, \"Saul, Saul, why do you persecute me?\"",
                "He said, \"Who are you, Lord?\" The Lord said, \"I am Jesus, whom you are persecuting.",
                "But rise up, and enter into the city, and you will be told what you must do.\"",
                "The men who traveled with him stood speechless, hearing the sound, but seeing no one.",
                "Saul arose from the ground, and when his eyes were opened, he saw no one. They led him by the hand, and brought him into Damascus.",
                "He was without sight for three days, and neither ate nor drank.",
                "Now there was a certain disciple at Damascus named Ananias. The Lord said to him in a vision, \"Ananias!\" He said, \"Behold, it\u2019s me, Lord.\"",
                "The Lord said to him, \"Arise, and go to the street which is called Straight, and inquire in the house of Judah for one named Saul, a man of Tarsus. For behold, he is praying,",
                "and in a vision he has seen a man named Ananias coming in, and laying his hands on him, that he might receive his sight.\"",
                "But Ananias answered, \"Lord, I have heard from many about this man, how much evil he did to your saints at Jerusalem.",
                "Here he has authority from the chief priests to bind all who call on your name.\"",
                "But the Lord said to him, \"Go your way, for he is my chosen vessel to bear my name before the nations and kings, and the children of Israel.",
                "For I will show him how many things he must suffer for my name\u2019s sake.\"",
                "Ananias departed, and entered into the house. Laying his hands on him, he said, \"Brother Saul, the Lord, who appeared to you on the road by which you came, has sent me, that you may receive your sight, and be filled with the Holy Spirit.\"",
                "Immediately something like scales fell from his eyes, and he received his sight. He arose and was baptized.",
                "He took food and was strengthened. Saul stayed several days with the disciples who were at Damascus.",
                "Immediately in the synagogues he proclaimed the Christ, that he is the Son of God.",
                "All who heard him were amazed, and said, \"Isn\u2019t this he who in Jerusalem made havoc of those who called on this name? And he had come here intending to bring them bound before the chief priests!\"",
                "But Saul increased more in strength, and confounded the Jews who lived at Damascus, proving that this is the Christ.",
                "When many days were fulfilled, the Jews conspired together to kill him,",
                "but their plot became known to Saul. They watched the gates both day and night that they might kill him,",
                "but his disciples took him by night, and let him down through the wall, lowering him in a basket.",
                "When Saul had come to Jerusalem, he tried to join himself to the disciples; but they were all afraid of him, not believing that he was a disciple.",
                "But Barnabas took him, and brought him to the apostles, and declared to them how he had seen the Lord in the way, and that he had spoken to him, and how at Damascus he had preached boldly in the name of Jesus.",
                "He was with them entering into Jerusalem,",
                "preaching boldly in the name of the Lord Jesus. He spoke and disputed against the Hellenists, but they were seeking to kill him.",
                "When the brothers knew it, they brought him down to Caesarea, and sent him off to Tarsus.",
                "So the assemblies throughout all Judea and Galilee and Samaria had peace, and were built up. They were multiplied, walking in the fear of the Lord and in the comfort of the Holy Spirit.",
                "It happened, as Peter went throughout all those parts, he came down also to the saints who lived at Lydda.",
                "There he found a certain man named Aeneas, who had been bedridden for eight years, because he was paralyzed.",
                "Peter said to him, \"Aeneas, Jesus Christ heals you. Get up and make your bed!\" Immediately he arose.",
                "All who lived at Lydda and in Sharon saw him, and they turned to the Lord.",
                "Now there was at Joppa a certain disciple named Tabitha, which when translated, means Dorcas. This woman was full of good works and acts of mercy which she did.",
                "It happened in those days that she fell sick, and died. When they had washed her, they laid her in an upper room.",
                "As Lydda was near Joppa, the disciples, hearing that Peter was there, sent two men to him, imploring him not to delay in coming to them.",
                "Peter got up and went with them. When he had come, they brought him into the upper room. All the widows stood by him weeping, and showing the coats and garments which Dorcas had made while she was with them.",
                "Peter put them all out, and kneeled down and prayed. Turning to the body, he said, \"Tabitha, get up!\" She opened her eyes, and when she saw Peter, she sat up.",
                "He gave her his hand, and raised her up. Calling the saints and widows, he presented her alive.",
                "And it became known throughout all Joppa, and many believed in the Lord.",
                "It happened, that he stayed many days in Joppa with one Simon, a tanner."
            ],
            10: [
                "Now there was a certain man in Caesarea, Cornelius by name, a centurion of what was called the Italian Regiment,",
                "a devout man, and one who feared God with all his house, who gave gifts for the needy generously to the people, and always prayed to God.",
                "At about the ninth hour of the day, he clearly saw in a vision an angel of God coming to him, and saying to him, \"Cornelius!\"",
                "He, fastening his eyes on him, and being frightened, said, \"What is it, Lord?\" He said to him, \"Your prayers and your gifts to the needy have gone up for a memorial before God.",
                "Now send men to Joppa, and get Simon, who is surnamed Peter.",
                "He lodges with one Simon, a tanner, whose house is by the seaside.\"",
                "When the angel who spoke to him had departed, Cornelius called two of his household servants and a devout soldier of those who waited on him continually.",
                "Having explained everything to them, he sent them to Joppa.",
                "Now on the next day as they were on their journey, and got close to the city, Peter went up on the housetop to pray at about noon.",
                "He became hungry and desired to eat, but while they were preparing, he fell into a trance.",
                "He saw heaven opened and a certain container descending to him, like a great sheet let down by four corners on the earth,",
                "in which were all kinds of four-footed animals of the earth, wild animals, reptiles, and birds of the sky.",
                "A voice came to him, \"Rise, Peter, kill and eat!\"",
                "But Peter said, \"Not so, Lord; for I have never eaten anything that is common or unclean.\"",
                "A voice came to him again the second time, \"What God has cleansed, you must not call unclean.\"",
                "This was done three times, and immediately the vessel was received up into heaven.",
                "Now while Peter was very perplexed in himself what the vision which he had seen might mean, behold, the men who were sent by Cornelius, having made inquiry for Simon\u2019s house, stood before the gate,",
                "and called and asked whether Simon, who was surnamed Peter, was lodging there.",
                "While Peter was pondering the vision, the Spirit said to him, \"Behold, three men seek you.",
                "But arise, get down, and go with them, doubting nothing; for I have sent them.\"",
                "Peter went down to the men, and said, \"Behold, I am he whom you seek. Why have you come?\"",
                "They said, \"Cornelius, a centurion, a righteous man and one who fears God, and well spoken of by all the nation of the Jews, was directed by a holy angel to invite you to his house, and to listen to what you say.\"",
                "So he called them in and lodged them. On the next day Peter arose and went out with them, and some of the brothers from Joppa accompanied him.",
                "On the next day they entered into Caesarea. Cornelius was waiting for them, having called together his relatives and his near friends.",
                "When it happened that Peter entered, Cornelius met him, fell down at his feet, and worshiped him.",
                "But Peter raised him up, saying, \"Stand up! I myself am also a man.\"",
                "As he talked with him, he went in and found many gathered together.",
                "He said to them, \"You yourselves know how it is an unlawful thing for a man who is a Jew to join himself or come to one of another nation, but God has shown me that I shouldn\u2019t call any man unholy or unclean.",
                "Therefore also I came without complaint when I was sent for. I ask therefore, why did you send for me?\"",
                "Cornelius said, \"Four days ago, I was fasting until this hour, and at the ninth hour, I prayed in my house, and behold, a man stood before me in bright clothing,",
                "and said, \u2018Cornelius, your prayer is heard, and your gifts to the needy are remembered in the sight of God.",
                "Send therefore to Joppa, and summon Simon, who is surnamed Peter. He lodges in the house of Simon a tanner, by the seaside. When he comes, he will speak to you.\u2019",
                "Therefore I sent to you at once, and it was good of you to come. Now therefore we are all here present in the sight of God to hear all things that have been commanded you by God.\"",
                "Peter opened his mouth and said, \"Truly I perceive that God doesn\u2019t show favoritism;",
                "but in every nation he who fears him and works righteousness is acceptable to him.",
                "The word which he sent to the children of Israel, preaching good news of peace by Jesus Christ\u2014he is Lord of all\u2014",
                "you yourselves know what happened, which was proclaimed throughout all Judea, beginning from Galilee, after the baptism which John preached;",
                "even Jesus of Nazareth, how God anointed him with the Holy Spirit and with power, who went about doing good and healing all who were oppressed by the devil, for God was with him.",
                "We are witnesses of everything he did both in the country of the Jews, and in Jerusalem; whom they also killed, hanging him on a tree.",
                "God raised him up the third day, and gave him to be revealed,",
                "not to all the people, but to witnesses who were chosen before by God, to us, who ate and drank with him after he rose from the dead.",
                "He commanded us to preach to the people and to testify that this is he who is appointed by God as the Judge of the living and the dead.",
                "All the prophets testify about him, that through his name everyone who believes in him will receive remission of sins.\"",
                "While Peter was still speaking these words, the Holy Spirit fell on all those who heard the word.",
                "They of the circumcision who believed were amazed, as many as came with Peter, because the gift of the Holy Spirit was also poured out on the Gentiles.",
                "For they heard them speaking in other languages and magnifying God. Then Peter answered,",
                "\"Can any man forbid the water, that these who have received the Holy Spirit as well as we should not be baptized?\"",
                "He commanded them to be baptized in the name of Jesus Christ. Then they asked him to stay some days."
            ],
            11: [
                "Now the apostles and the brothers who were in Judea heard that the Gentiles had also received the word of God.",
                "When Peter had come up to Jerusalem, those who were of the circumcision contended with him,",
                "saying, \"You went in to uncircumcised men, and ate with them!\"",
                "But Peter began, and explained to them in order, saying,",
                "\"I was in the city of Joppa praying, and in a trance I saw a vision: a certain container descending, like it was a great sheet let down from heaven by four corners. It came as far as me.",
                "When I had looked intently at it, I considered, and saw the four-footed animals of the earth, wild animals, creeping things, and birds of the sky.",
                "I also heard a voice saying to me, \u2018Rise, Peter, kill and eat!\u2019",
                "But I said, \u2018Not so, Lord, for nothing unholy or unclean has ever entered into my mouth.\u2019",
                "But a voice answered me the second time out of heaven, \u2018What God has cleansed, don\u2019t you call unclean.\u2019",
                "This was done three times, and all were drawn up again into heaven.",
                "Behold, immediately three men stood before the house where I was, having been sent from Caesarea to me.",
                "The Spirit told me to go with them, without discriminating. These six brothers also accompanied me, and we entered into the man\u2019s house.",
                "He told us how he had seen the angel standing in his house, and saying to him, \u2018Send to Joppa, and get Simon, whose surname is Peter,",
                "who will speak to you words by which you will be saved, you and all your house.\u2019",
                "As I began to speak, the Holy Spirit fell on them, even as on us at the beginning.",
                "I remembered the word of the Lord, how he said, \u2018John indeed baptized in water, but you will be baptized in the Holy Spirit.\u2019",
                "If then God gave to them the same gift as us, when we believed in the Lord Jesus Christ, who was I, that I could withstand God?\"",
                "When they heard these things, they held their peace, and glorified God, saying, \"Then God has also granted to the Gentiles repentance to life!\"",
                "They therefore who were scattered abroad by the oppression that arose about Stephen traveled as far as Phoenicia, Cyprus, and Antioch, speaking the word to no one except to Jews only.",
                "But there were some of them, men of Cyprus and Cyrene, who, when they had come to Antioch, spoke to the Hellenists, preaching the Lord Jesus.",
                "The hand of the Lord was with them, and a great number believed and turned to the Lord.",
                "The report concerning them came to the ears of the assembly which was in Jerusalem. They sent out Barnabas to go as far as Antioch,",
                "who, when he had come, and had seen the grace of God, was glad. He exhorted them all, that with purpose of heart they should remain near to the Lord.",
                "For he was a good man, and full of the Holy Spirit and of faith, and many people were added to the Lord.",
                "Barnabas went out to Tarsus to look for Saul.",
                "When he had found him, he brought him to Antioch. It happened, that for a whole year they were gathered together with the assembly, and taught many people. The disciples were first called Christians in Antioch.",
                "Now in these days, prophets came down from Jerusalem to Antioch.",
                "One of them named Agabus stood up, and indicated by the Spirit that there should be a great famine all over the world, which also happened in the days of Claudius.",
                "As any of the disciples had plenty, each determined to send relief to the brothers who lived in Judea;",
                "which they also did, sending it to the elders by the hands of Barnabas and Saul."
            ],
            12: [
                "Now about that time, King Herod stretched out his hands to oppress some of the assembly.",
                "He killed James, the brother of John, with the sword.",
                "When he saw that it pleased the Jews, he proceeded to seize Peter also. This was during the days of unleavened bread.",
                "When he had arrested him, he put him in prison, and delivered him to four squads of four soldiers each to guard him, intending to bring him out to the people after the Passover.",
                "Peter therefore was kept in the prison, but constant prayer was made by the assembly to God for him.",
                "The same night when Herod was about to bring him out, Peter was sleeping between two soldiers, bound with two chains. Guards in front of the door kept the prison.",
                "And behold, an angel of the Lord stood by him, and a light shone in the cell. He struck Peter on the side, and woke him up, saying, \"Stand up quickly!\" His chains fell off from his hands.",
                "The angel said to him, \"Get dressed and put on your sandals.\" He did so. He said to him, \"Put on your cloak, and follow me.\"",
                "And he went out and followed him. He didn\u2019t know that what was being done by the angel was real, but thought he saw a vision.",
                "When they were past the first and the second guard, they came to the iron gate that leads into the city, which opened to them by itself. They went out, and went down one street, and immediately the angel departed from him.",
                "When Peter had come to himself, he said, \"Now I truly know that the Lord has sent out his angel and delivered me out of the hand of Herod, and from everything the Jewish people were expecting.\"",
                "Thinking about that, he came to the house of Mary, the mother of John whose surname was Mark, where many were gathered together and were praying.",
                "When Peter knocked at the door of the gate, a maid named Rhoda came to answer.",
                "When she recognized Peter\u2019s voice, she didn\u2019t open the gate for joy, but ran in, and reported that Peter was standing in front of the gate.",
                "They said to her, \"You are crazy!\" But she insisted that it was so. They said, \"It is his angel.\"",
                "But Peter continued knocking. When they had opened, they saw him, and were amazed.",
                "But he, beckoning to them with his hand to be silent, declared to them how the Lord had brought him out of the prison. He said, \"Tell these things to James, and to the brothers.\" Then he departed, and went to another place.",
                "Now as soon as it was day, there was no small stir among the soldiers about what had become of Peter.",
                "When Herod had sought for him, and didn\u2019t find him, he examined the guards, and commanded that they should be put to death. He went down from Judea to Caesarea, and stayed there.",
                "Now Herod was very angry with the people of Tyre and Sidon. They came with one accord to him, and, having made Blastus, the king\u2019s personal aide, their friend, they asked for peace, because their country depended on the king\u2019s country for food.",
                "On an appointed day, Herod dressed himself in royal clothing, sat on the throne, and gave a speech to them.",
                "The people shouted, \"The voice of a god, and not of a man!\"",
                "Immediately an angel of the Lord struck him, because he didn\u2019t give God the glory, and he was eaten by worms and died.",
                "But the word of God grew and multiplied.",
                "Barnabas and Saul returned to Jerusalem, when they had fulfilled their service, also taking with them John whose surname was Mark."
            ],
            13: [
                "Now in the assembly that was at Antioch there were some prophets and teachers: Barnabas, Simeon who was called Niger, Lucius of Cyrene, Manaen the foster brother of Herod the tetrarch, and Saul.",
                "As they served the Lord and fasted, the Holy Spirit said, \"Separate Barnabas and Saul for me, for the work to which I have called them.\"",
                "Then, when they had fasted and prayed and laid their hands on them, they sent them away.",
                "So, being sent out by the Holy Spirit, they went down to Seleucia. From there they sailed to Cyprus.",
                "When they were at Salamis, they proclaimed the word of God in the Jewish synagogues. They had also John as their attendant.",
                "When they had gone through the island to Paphos, they found a certain sorcerer, a false prophet, a Jew, whose name was Bar Jesus,",
                "who was with the proconsul, Sergius Paulus, a man of understanding. This man summoned Barnabas and Saul, and sought to hear the word of God.",
                "But Elymas the sorcerer (for so is his name by interpretation) withstood them, seeking to turn aside the proconsul from the faith.",
                "But Saul, who is also called Paul, filled with the Holy Spirit, fastened his eyes on him,",
                "and said, \"Full of all deceit and all cunning, you son of the devil, you enemy of all righteousness, will you not cease to pervert the right ways of the Lord?",
                "Now, behold, the hand of the Lord is on you, and you will be blind, not seeing the sun for a season!\" Immediately a mist and darkness fell on him. He went around seeking someone to lead him by the hand.",
                "Then the proconsul, when he saw what was done, believed, being astonished at the teaching of the Lord.",
                "Now Paul and his company set sail from Paphos, and came to Perga in Pamphylia. John departed from them and returned to Jerusalem.",
                "But they, passing on from Perga, came to Antioch of Pisidia. They went into the synagogue on the Sabbath day, and sat down.",
                "After the reading of the law and the prophets, the rulers of the synagogue sent to them, saying, \"Brothers, if you have any word of exhortation for the people, speak.\"",
                "Paul stood up, and beckoning with his hand said, \"Men of Israel, and you who fear God, listen.",
                "The God of this people chose our fathers, and exalted the people when they stayed as aliens in the land of Egypt, and with an uplifted arm, he led them out of it.",
                "For a period of about forty years he put up with them in the wilderness.",
                "When he had destroyed seven nations in the land of Canaan, he gave them their land for an inheritance, for about four hundred fifty years.",
                "After these things he gave them judges until Samuel the prophet.",
                "Afterward they asked for a king, and God gave to them Saul the son of Kish, a man of the tribe of Benjamin, for forty years.",
                "When he had removed him, he raised up David to be their king, to whom he also testified, \u2018I have found David the son of Jesse, a man after my heart, who will do all my will.\u2019",
                "From this man\u2019s seed, God has brought salvation to Israel according to his promise,",
                "before his coming, when John had first preached the baptism of repentance to Israel.",
                "As John was fulfilling his course, he said, \u2018What do you suppose that I am? I am not he. But behold, one comes after me the sandals of whose feet I am not worthy to untie.\u2019",
                "Brothers, children of the stock of Abraham, and those among you who fear God, the word of this salvation is sent out to you.",
                "For those who dwell in Jerusalem, and their rulers, because they didn\u2019t know him, nor the voices of the prophets which are read every Sabbath, fulfilled them by condemning him.",
                "Though they found no cause for death, they still asked Pilate to have him killed.",
                "When they had fulfilled all things that were written about him, they took him down from the tree, and laid him in a tomb.",
                "But God raised him from the dead,",
                "and he was seen for many days by those who came up with him from Galilee to Jerusalem, who are his witnesses to the people.",
                "We bring you good news of the promise made to the fathers,",
                "that God has fulfilled the same to us, their children, in that he raised up Jesus. As it is also written in the second psalm, \u2018You are my Son. Today I have become your father.\u2019",
                "\"Concerning that he raised him up from the dead, now no more to return to corruption, he has spoken thus: \u2018I will give you the holy and sure blessings of David.\u2019",
                "Therefore he says also in another psalm, \u2018You will not allow your Holy One to see decay.\u2019",
                "For David, after he had in his own generation served the counsel of God, fell asleep, and was laid with his fathers, and saw decay.",
                "But he whom God raised up saw no decay.",
                "Be it known to you therefore, brothers, that through this man is proclaimed to you remission of sins,",
                "and by him everyone who believes is justified from all things, from which you could not be justified by the law of Moses.",
                "Beware therefore, lest that come on you which is spoken in the prophets:",
                "\u2018Behold, you scoffers, and wonder, and perish; for I work a work in your days, a work which you will in no way believe, if one declares it to you.\u2019\"",
                "So when the Jews went out of the synagogue, the Gentiles begged that these words might be preached to them the next Sabbath.",
                "Now when the synagogue broke up, many of the Jews and of the devout proselytes followed Paul and Barnabas; who, speaking to them, urged them to continue in the grace of God.",
                "The next Sabbath almost the whole city was gathered together to hear the word of God.",
                "But when the Jews saw the multitudes, they were filled with jealousy, and contradicted the things which were spoken by Paul, and blasphemed.",
                "Paul and Barnabas spoke out boldly, and said, \"It was necessary that God\u2019s word should be spoken to you first. Since indeed you thrust it from you, and judge yourselves unworthy of eternal life, behold, we turn to the Gentiles.",
                "For so has the Lord commanded us, saying, \u2018I have set you as a light for the Gentiles, that you should bring salvation to the uttermost parts of the earth.\u2019\"",
                "As the Gentiles heard this, they were glad, and glorified the word of God. As many as were appointed to eternal life believed.",
                "The Lord\u2019s word was spread abroad throughout all the region.",
                "But the Jews stirred up the devout and prominent women and the chief men of the city, and stirred up a persecution against Paul and Barnabas, and threw them out of their borders.",
                "But they shook off the dust of their feet against them, and came to Iconium.",
                "The disciples were filled with joy with the Holy Spirit."
            ],
            14: [
                "It happened in Iconium that they entered together into the synagogue of the Jews, and so spoke that a great multitude both of Jews and of Greeks believed.",
                "But the disbelieving Jews stirred up and embittered the souls of the Gentiles against the brothers.",
                "Therefore they stayed there a long time, speaking boldly in the Lord, who testified to the word of his grace, granting signs and wonders to be done by their hands.",
                "But the multitude of the city was divided. Part sided with the Jews, and part with the apostles.",
                "When some of both the Gentiles and the Jews, with their rulers, made a violent attempt to mistreat and stone them,",
                "they became aware of it, and fled to the cities of Lycaonia, Lystra, Derbe, and the surrounding region.",
                "There they preached the Good News.",
                "At Lystra a certain man sat, impotent in his feet, a cripple from his mother\u2019s womb, who never had walked.",
                "He was listening to Paul speaking, who, fastening eyes on him, and seeing that he had faith to be made whole,",
                "said with a loud voice, \"Stand upright on your feet!\" He leaped up and walked.",
                "When the multitude saw what Paul had done, they lifted up their voice, saying in the language of Lycaonia, \"The gods have come down to us in the likeness of men!\"",
                "They called Barnabas \"Jupiter,\" and Paul \"Mercury,\" because he was the chief speaker.",
                "The priest of Jupiter, whose temple was in front of their city, brought oxen and garlands to the gates, and would have made a sacrifice along with the multitudes.",
                "But when the apostles, Barnabas and Paul, heard of it, they tore their clothes, and sprang into the multitude, crying out,",
                "\"Men, why are you doing these things? We also are men of like passions with you, and bring you good news, that you should turn from these vain things to the living God, who made the sky and the earth and the sea, and all that is in them;",
                "who in the generations gone by allowed all the nations to walk in their own ways.",
                "Yet he didn\u2019t leave himself without witness, in that he did good and gave you rains from the sky and fruitful seasons, filling our hearts with food and gladness.\"",
                "Even saying these things, they hardly stopped the multitudes from making a sacrifice to them.",
                "But some Jews from Antioch and Iconium came there, and having persuaded the multitudes, they stoned Paul, and dragged him out of the city, supposing that he was dead.",
                "But as the disciples stood around him, he rose up, and entered into the city. On the next day he went out with Barnabas to Derbe.",
                "When they had preached the Good News to that city, and had made many disciples, they returned to Lystra, Iconium, and Antioch,",
                "confirming the souls of the disciples, exhorting them to continue in the faith, and that through many afflictions we must enter into the Kingdom of God.",
                "When they had appointed elders for them in every assembly, and had prayed with fasting, they commended them to the Lord, on whom they had believed.",
                "They passed through Pisidia, and came to Pamphylia.",
                "When they had spoken the word in Perga, they went down to Attalia.",
                "From there they sailed to Antioch, from where they had been committed to the grace of God for the work which they had fulfilled.",
                "When they had arrived, and had gathered the assembly together, they reported all the things that God had done with them, and that he had opened a door of faith to the nations.",
                "They stayed there with the disciples for a long time."
            ],
            15: [
                "Some men came down from Judea and taught the brothers, \"Unless you are circumcised after the custom of Moses, you can\u2019t be saved.\"",
                "Therefore when Paul and Barnabas had no small discord and discussion with them, they appointed Paul and Barnabas, and some others of them, to go up to Jerusalem to the apostles and elders about this question.",
                "They, being sent on their way by the assembly, passed through both Phoenicia and Samaria, declaring the conversion of the Gentiles. They caused great joy to all the brothers.",
                "When they had come to Jerusalem, they were received by the assembly and the apostles and the elders, and they reported all things that God had done with them.",
                "But some of the sect of the Pharisees who believed rose up, saying, \"It is necessary to circumcise them, and to command them to keep the law of Moses.\"",
                "The apostles and the elders were gathered together to see about this matter.",
                "When there had been much discussion, Peter rose up and said to them, \"Brothers, you know that a good while ago God made a choice among you, that by my mouth the nations should hear the word of the Good News, and believe.",
                "God, who knows the heart, testified about them, giving them the Holy Spirit, just like he did to us.",
                "He made no distinction between us and them, cleansing their hearts by faith.",
                "Now therefore why do you tempt God, that you should put a yoke on the neck of the disciples which neither our fathers nor we were able to bear?",
                "But we believe that we are saved through the grace of the Lord Jesus, just as they are.\"",
                "All the multitude kept silence, and they listened to Barnabas and Paul reporting what signs and wonders God had done among the nations through them.",
                "After they were silent, James answered, \"Brothers, listen to me.",
                "Simeon has reported how God first visited the nations, to take out of them a people for his name.",
                "This agrees with the words of the prophets. As it is written,",
                "\u2018After these things I will return. I will again build the tabernacle of David, which has fallen. I will again build its ruins. I will set it up,",
                "That the rest of men may seek after the Lord; All the Gentiles who are called by my name, Says the Lord, who does all these things.",
                "All his works are known to God from eternity.\u2019",
                "\"Therefore my judgment is that we don\u2019t trouble those from among the Gentiles who turn to God,",
                "but that we write to them that they abstain from the pollution of idols, from sexual immorality, from what is strangled, and from blood.",
                "For Moses from generations of old has in every city those who preach him, being read in the synagogues every Sabbath.\"",
                "Then it seemed good to the apostles and the elders, with the whole assembly, to choose men out of their company, and send them to Antioch with Paul and Barnabas: Judas called Barsabbas, and Silas, chief men among the brothers.",
                "They wrote these things by their hand: \"The apostles, the elders, and the brothers, to the brothers who are of the Gentiles in Antioch, Syria, and Cilicia: greetings.",
                "Because we have heard that some who went out from us have troubled you with words, unsettling your souls, saying, \u2018You must be circumcised and keep the law,\u2019 to whom we gave no commandment;",
                "it seemed good to us, having come to one accord, to choose out men and send them to you with our beloved Barnabas and Paul,",
                "men who have risked their lives for the name of our Lord Jesus Christ.",
                "We have sent therefore Judas and Silas, who themselves will also tell you the same things by word of mouth.",
                "For it seemed good to the Holy Spirit, and to us, to lay no greater burden on you than these necessary things:",
                "that you abstain from things sacrificed to idols, from blood, from things strangled, and from sexual immorality, from which if you keep yourselves, it will be well with you. Farewell.\"",
                "So, when they were sent off, they came to Antioch. Having gathered the multitude together, they delivered the letter.",
                "When they had read it, they rejoiced over the encouragement.",
                "Judas and Silas, also being prophets themselves, encouraged the brothers with many words, and strengthened them.",
                "After they had spent some time there, they were sent back with greetings from the brothers to the apostles.",
                "But Paul and Barnabas stayed in Antioch, teaching and preaching the word of the Lord, with many others also.",
                "After some days Paul said to Barnabas, \"Let\u2019s return now and visit our brothers in every city in which we proclaimed the word of the Lord, to see how they are doing.\"",
                "Barnabas planned to take John, who was called Mark, with them also.",
                "But Paul didn\u2019t think that it was a good idea to take with them someone who had withdrawn from them in Pamphylia, and didn\u2019t go with them to do the work.",
                "Then the contention grew so sharp that they separated from each other. Barnabas took Mark with him, and sailed away to Cyprus,",
                "but Paul chose Silas, and went out, being commended by the brothers to the grace of God.",
                "He went through Syria and Cilicia, strengthening the assemblies."
            ],
            16: [
                "He came to Derbe and Lystra: and behold, a certain disciple was there, named Timothy, the son of a Jewess who believed; but his father was a Greek.",
                "The brothers who were at Lystra and Iconium gave a good testimony about him.",
                "Paul wanted to have him go out with him, and he took and circumcised him because of the Jews who were in those parts; for they all knew that his father was a Greek.",
                "As they went on their way through the cities, they delivered the decrees to them to keep which had been ordained by the apostles and elders who were at Jerusalem.",
                "So the assemblies were strengthened in the faith, and increased in number daily.",
                "When they had gone through the region of Phrygia and Galatia, they were forbidden by the Holy Spirit to speak the word in Asia.",
                "When they had come opposite Mysia, they tried to go into Bithynia, but the Spirit didn\u2019t allow them.",
                "Passing by Mysia, they came down to Troas.",
                "A vision appeared to Paul in the night. There was a man of Macedonia standing, begging him, and saying, \"Come over into Macedonia and help us.\"",
                "When he had seen the vision, immediately we sought to go out to Macedonia, concluding that the Lord had called us to preach the Good News to them.",
                "Setting sail therefore from Troas, we made a straight course to Samothrace, and the day following to Neapolis;",
                "and from there to Philippi, which is a city of Macedonia, the foremost of the district, a Roman colony. We were staying some days in this city.",
                "On the Sabbath day we went forth outside of the city by a riverside, where we supposed there was a place of prayer, and we sat down, and spoke to the women who had come together.",
                "A certain woman named Lydia, a seller of purple, of the city of Thyatira, one who worshiped God, heard us; whose heart the Lord opened to listen to the things which were spoken by Paul.",
                "When she and her household were baptized, she begged us, saying, \"If you have judged me to be faithful to the Lord, come into my house, and stay.\" So she persuaded us.",
                "It happened, as we were going to prayer, that a certain girl having a spirit of divination met us, who brought her masters much gain by fortune telling.",
                "Following Paul and us, she cried out, \"These men are servants of the Most High God, who proclaim to us a way of salvation!\"",
                "She was doing this for many days. But Paul, becoming greatly annoyed, turned and said to the spirit, \"I command you in the name of Jesus Christ to come out of her!\" It came out that very hour.",
                "But when her masters saw that the hope of their gain was gone, they seized Paul and Silas, and dragged them into the marketplace before the rulers.",
                "When they had brought them to the magistrates, they said, \"These men, being Jews, are agitating our city,",
                "and set forth customs which it is not lawful for us to accept or to observe, being Romans.\"",
                "The multitude rose up together against them, and the magistrates tore their clothes off of them, and commanded them to be beaten with rods.",
                "When they had laid many stripes on them, they threw them into prison, charging the jailer to keep them safely,",
                "who, having received such a command, threw them into the inner prison, and secured their feet in the stocks.",
                "But about midnight Paul and Silas were praying and singing hymns to God, and the prisoners were listening to them.",
                "Suddenly there was a great earthquake, so that the foundations of the prison were shaken; and immediately all the doors were opened, and everyone\u2019s bonds were loosened.",
                "The jailer, being roused out of sleep and seeing the prison doors open, drew his sword and was about to kill himself, supposing that the prisoners had escaped.",
                "But Paul cried with a loud voice, saying, \"Don\u2019t harm yourself, for we are all here!\"",
                "He called for lights and sprang in, and, fell down trembling before Paul and Silas,",
                "and brought them out and said, \"Sirs, what must I do to be saved?\"",
                "They said, \"Believe in the Lord Jesus Christ, and you will be saved, you and your household.\"",
                "They spoke the word of the Lord to him, and to all who were in his house.",
                "He took them the same hour of the night, and washed their stripes, and was immediately baptized, he and all his household.",
                "He brought them up into his house, and set food before them, and rejoiced greatly, with all his household, having believed in God.",
                "But when it was day, the magistrates sent the sergeants, saying, \"Let those men go.\"",
                "The jailer reported these words to Paul, saying, \"The magistrates have sent to let you go; now therefore come out, and go in peace.\"",
                "But Paul said to them, \"They have beaten us publicly, without a trial, men who are Romans, and have cast us into prison! Do they now release us secretly? No, most certainly, but let them come themselves and bring us out!\"",
                "The sergeants reported these words to the magistrates, and they were afraid when they heard that they were Romans,",
                "and they came and begged them. When they had brought them out, they asked them to depart from the city.",
                "They went out of the prison, and entered into Lydia\u2019s house. When they had seen the brothers, they encouraged them, and departed."
            ],
            17: [
                "Now when they had passed through Amphipolis and Apollonia, they came to Thessalonica, where there was a Jewish synagogue.",
                "Paul, as was his custom, went in to them, and for three Sabbath days reasoned with them from the Scriptures,",
                "explaining and demonstrating that the Christ had to suffer and rise again from the dead, and saying, \"This Jesus, whom I proclaim to you, is the Christ.\"",
                "Some of them were persuaded, and joined Paul and Silas, of the devout Greeks a great multitude, and not a few of the chief women.",
                "But the unpersuaded Jews took along some wicked men from the marketplace, and gathering a crowd, set the city in an uproar. Assaulting the house of Jason, they sought to bring them out to the people.",
                "When they didn\u2019t find them, they dragged Jason and certain brothers before the rulers of the city, crying, \"These who have turned the world upside down have come here also,",
                "whom Jason has received. These all act contrary to the decrees of Caesar, saying that there is another king, Jesus!\"",
                "The multitude and the rulers of the city were troubled when they heard these things.",
                "When they had taken security from Jason and the rest, they let them go.",
                "The brothers immediately sent Paul and Silas away by night to Beroea. When they arrived, they went into the Jewish synagogue.",
                "Now these were more noble than those in Thessalonica, in that they received the word with all readiness of the mind, examining the Scriptures daily to see whether these things were so.",
                "Many of them therefore believed; also of the prominent Greek women, and not a few men.",
                "But when the Jews of Thessalonica had knowledge that the word of God was proclaimed by Paul at Beroea also, they came there likewise, agitating the multitudes.",
                "Then the brothers immediately sent out Paul to go as far as to the sea, and Silas and Timothy still stayed there.",
                "But those who escorted Paul brought him as far as Athens. Receiving a commandment to Silas and Timothy that they should come to him very quickly, they departed.",
                "Now while Paul waited for them at Athens, his spirit was provoked within him as he saw the city full of idols.",
                "So he reasoned in the synagogue with the Jews and the devout persons, and in the marketplace every day with those who met him.",
                "Some of the Epicurean and Stoic philosophers also were conversing with him. Some said, \"What does this babbler want to say?\" Others said, \"He seems to be advocating foreign deities,\" because he preached Jesus and the resurrection.",
                "They took hold of him, and brought him to the Areopagus, saying, \"May we know what this new teaching is, which is spoken by you?",
                "For you bring certain strange things to our ears. We want to know therefore what these things mean.\"",
                "Now all the Athenians and the strangers living there spent their time in nothing else, but either to tell or to hear some new thing.",
                "Paul stood in the middle of the Areopagus, and said, \"You men of Athens, I perceive that you are very religious in all things.",
                "For as I passed along, and observed the objects of your worship, I found also an altar with this inscription: \u2018TO AN UNKNOWN GOD.\u2019 What therefore you worship in ignorance, this I announce to you.",
                "The God who made the world and all things in it, he, being Lord of heaven and earth, doesn\u2019t dwell in temples made with hands,",
                "neither is he served by men\u2019s hands, as though he needed anything, seeing he himself gives to all life and breath, and all things.",
                "He made from one blood every nation of men to dwell on all the surface of the earth, having determined appointed seasons, and the boundaries of their dwellings,",
                "that they should seek the Lord, if perhaps they might reach out for him and find him, though he is not far from each one of us.",
                "\u2018For in him we live, and move, and have our being.\u2019 As some of your own poets have said, \u2018For we are also his offspring.\u2019",
                "Being then the offspring of God, we ought not to think that the Divine Nature is like gold, or silver, or stone, engraved by art and design of man.",
                "The times of ignorance therefore God overlooked. But now he commands that all people everywhere should repent,",
                "because he has appointed a day in which he will judge the world in righteousness by the man whom he has ordained; of which he has given assurance to all men, in that he has raised him from the dead.\"",
                "Now when they heard of the resurrection of the dead, some mocked; but others said, \"We want to hear you again concerning this.\"",
                "Thus Paul went out from among them.",
                "But certain men joined with him, and believed, among whom also was Dionysius the Areopagite, and a woman named Damaris, and others with them."
            ],
            18: [
                "After these things Paul departed from Athens, and came to Corinth.",
                "He found a certain Jew named Aquila, a man of Pontus by race, who had recently come from Italy, with his wife Priscilla, because Claudius had commanded all the Jews to depart from Rome. He came to them,",
                "and because he practiced the same trade, he lived with them and worked, for by trade they were tent makers.",
                "He reasoned in the synagogue every Sabbath, and persuaded Jews and Greeks.",
                "But when Silas and Timothy came down from Macedonia, Paul was compelled by the Spirit, testifying to the Jews that Jesus was the Christ.",
                "When they opposed him and blasphemed, he shook out his clothing and said to them, \"Your blood be on your own heads! I am clean. From now on, I will go to the Gentiles!\"",
                "He departed there, and went into the house of a certain man named Justus, one who worshiped God, whose house was next door to the synagogue.",
                "Crispus, the ruler of the synagogue, believed in the Lord with all his house. Many of the Corinthians, when they heard, believed and were baptized.",
                "The Lord said to Paul in the night by a vision, \"Don\u2019t be afraid, but speak and don\u2019t be silent;",
                "for I am with you, and no one will attack you to harm you, for I have many people in this city.\"",
                "He lived there a year and six months, teaching the word of God among them.",
                "But when Gallio was proconsul of Achaia, the Jews with one accord rose up against Paul and brought him before the judgment seat,",
                "saying, \"This man persuades men to worship God contrary to the law.\"",
                "But when Paul was about to open his mouth, Gallio said to the Jews, \"If indeed it were a matter of wrong or of wicked crime, you Jews, it would be reasonable that I should bear with you;",
                "but if they are questions about words and names and your own law, look to it yourselves. For I don\u2019t want to be a judge of these matters.\"",
                "He drove them from the judgment seat.",
                "Then all the Greeks laid hold on Sosthenes, the ruler of the synagogue, and beat him before the judgment seat. Gallio didn\u2019t care about any of these things.",
                "Paul, having stayed after this many more days, took his leave of the brothers, and sailed from there for Syria, together with Priscilla and Aquila. He shaved his head in Cenchreae, for he had a vow.",
                "He came to Ephesus, and he left them there; but he himself entered into the synagogue, and reasoned with the Jews.",
                "When they asked him to stay with them a longer time, he declined;",
                "but taking his leave of them, and saying, \"I must by all means keep this coming feast in Jerusalem, but I will return again to you if God wills,\" he set sail from Ephesus.",
                "When he had landed at Caesarea, he went up and greeted the assembly, and went down to Antioch.",
                "Having spent some time there, he departed, and went through the region of Galatia, and Phrygia, in order, establishing all the disciples.",
                "Now a certain Jew named Apollos, an Alexandrian by race, an eloquent man, came to Ephesus. He was mighty in the Scriptures.",
                "This man had been instructed in the way of the Lord; and being fervent in spirit, he spoke and taught accurately the things concerning Jesus, although he knew only the baptism of John.",
                "He began to speak boldly in the synagogue. But when Priscilla and Aquila heard him, they took him aside, and explained to him the way of God more accurately.",
                "When he had determined to pass over into Achaia, the brothers encouraged him, and wrote to the disciples to receive him. When he had come, he greatly helped those who had believed through grace;",
                "for he powerfully refuted the Jews, publicly showing by the Scriptures that Jesus was the Christ."
            ],
            19: [
                "It happened that, while Apollos was at Corinth, Paul, having passed through the upper country, came to Ephesus, and found certain disciples.",
                "He said to them, \"Did you receive the Holy Spirit when you believed?\" They said to him, \"No, we haven\u2019t even heard that there is a Holy Spirit.\"",
                "He said, \"Into what then were you baptized?\" They said, \"Into John\u2019s baptism.\"",
                "Paul said, \"John indeed baptized with the baptism of repentance, saying to the people that they should believe in the one who would come after him, that is, in Jesus.\"",
                "When they heard this, they were baptized in the name of the Lord Jesus.",
                "When Paul had laid his hands on them, the Holy Spirit came on them, and they spoke with other languages and prophesied.",
                "They were about twelve men in all.",
                "He entered into the synagogue, and spoke boldly for a period of three months, reasoning and persuading about the things concerning the Kingdom of God.",
                "But when some were hardened and disobedient, speaking evil of the Way before the multitude, he departed from them, and separated the disciples, reasoning daily in the school of Tyrannus.",
                "This continued for two years, so that all those who lived in Asia heard the word of the Lord Jesus, both Jews and Greeks.",
                "God worked special miracles by the hands of Paul,",
                "so that even handkerchiefs or aprons were carried away from his body to the sick, and the evil spirits went out.",
                "But some of the itinerant Jews, exorcists, took on themselves to invoke over those who had the evil spirits the name of the Lord Jesus, saying, \"We adjure you by Jesus whom Paul preaches.\"",
                "There were seven sons of one Sceva, a Jewish chief priest, who did this.",
                "The evil spirit answered, \"Jesus I know, and Paul I know, but who are you?\"",
                "The man in whom the evil spirit was leaped on them, and overpowered them, and prevailed against them, so that they fled out of that house naked and wounded.",
                "This became known to all, both Jews and Greeks, who lived at Ephesus. Fear fell on them all, and the name of the Lord Jesus was magnified.",
                "Many also of those who had believed came, confessing, and declaring their deeds.",
                "Many of those who practiced magical arts brought their books together and burned them in the sight of all. They counted their price, and found it to be fifty thousand pieces of silver.",
                "So the word of the Lord was growing and becoming mighty.",
                "Now after these things had ended, Paul determined in the spirit, when he had passed through Macedonia and Achaia, to go to Jerusalem, saying, \"After I have been there, I must also see Rome.\"",
                "Having sent into Macedonia two of those who served him, Timothy and Erastus, he himself stayed in Asia for a while.",
                "About that time there arose no small stir concerning the Way.",
                "For a certain man named Demetrius, a silversmith, who made silver shrines of Artemis, brought no little business to the craftsmen,",
                "whom he gathered together, with the workmen of like occupation, and said, \"Sirs, you know that by this business we have our wealth.",
                "You see and hear, that not at Ephesus alone, but almost throughout all Asia, this Paul has persuaded and turned away many people, saying that they are no gods, that are made with hands.",
                "Not only is there danger that this our trade come into disrepute, but also that the temple of the great goddess Artemis will be counted as nothing, and her majesty destroyed, whom all Asia and the world worships.\"",
                "When they heard this they were filled with anger, and cried out, saying, \"Great is Artemis of the Ephesians!\"",
                "The whole city was filled with confusion, and they rushed with one accord into the theater, having seized Gaius and Aristarchus, men of Macedonia, Paul\u2019s companions in travel.",
                "When Paul wanted to enter in to the people, the disciples didn\u2019t allow him.",
                "Certain also of the Asiarchs, being his friends, sent to him and begged him not to venture into the theater.",
                "Some therefore cried one thing, and some another, for the assembly was in confusion. Most of them didn\u2019t know why they had come together.",
                "They brought Alexander out of the multitude, the Jews putting him forward. Alexander beckoned with his hand, and would have made a defense to the people.",
                "But when they perceived that he was a Jew, all with one voice for a time of about two hours cried out, \"Great is Artemis of the Ephesians!\"",
                "When the town clerk had quieted the multitude, he said, \"You men of Ephesus, what man is there who doesn\u2019t know that the city of the Ephesians is temple keeper of the great goddess Artemis, and of the image which fell down from Zeus?",
                "Seeing then that these things can\u2019t be denied, you ought to be quiet, and to do nothing rash.",
                "For you have brought these men here, who are neither robbers of temples nor blasphemers of your goddess.",
                "If therefore Demetrius and the craftsmen who are with him have a matter against anyone, the courts are open, and there are proconsuls. Let them press charges against one another.",
                "But if you seek anything about other matters, it will be settled in the regular assembly.",
                "For indeed we are in danger of being accused concerning this day\u2019s riot, there being no cause. Concerning it, we wouldn\u2019t be able to give an account of this commotion.\"",
                "When he had thus spoken, he dismissed the assembly."
            ],
            20: [
                "After the uproar had ceased, Paul sent for the disciples, took leave of them, and departed to go into Macedonia.",
                "When he had gone through those parts, and had encouraged them with many words, he came into Greece.",
                "When he had spent three months there, and a plot was made against him by Jews as he was about to set sail for Syria, he determined to return through Macedonia.",
                "These accompanied him as far as Asia: Sopater of Beroea; Aristarchus and Secundus of the Thessalonians; Gaius of Derbe; Timothy; and Tychicus and Trophimus of Asia.",
                "But these had gone ahead, and were waiting for us at Troas.",
                "We sailed away from Philippi after the days of Unleavened Bread, and came to them at Troas in five days, where we stayed seven days.",
                "On the first day of the week, when the disciples were gathered together to break bread, Paul talked with them, intending to depart on the next day, and continued his speech until midnight.",
                "There were many lights in the upper room where we were gathered together.",
                "A certain young man named Eutychus sat in the window, weighed down with deep sleep. As Paul spoke still longer, being weighed down by his sleep, he fell down from the third story, and was taken up dead.",
                "Paul went down, and fell upon him, and embracing him said, \"Don\u2019t be troubled, for his life is in him.\"",
                "When he had gone up, and had broken bread, and eaten, and had talked with them a long while, even until break of day, he departed.",
                "They brought the boy in alive, and were greatly comforted.",
                "But we who went ahead to the ship set sail for Assos, intending to take Paul aboard there, for he had so arranged, intending himself to go by land.",
                "When he met us at Assos, we took him aboard, and came to Mitylene.",
                "Sailing from there, we came the following day opposite Chios. The next day we touched at Samos and stayed at Trogyllium, and the day after we came to Miletus.",
                "For Paul had determined to sail past Ephesus, that he might not have to spend time in Asia; for he was hastening, if it were possible for him, to be in Jerusalem on the day of Pentecost.",
                "From Miletus he sent to Ephesus, and called to himself the elders of the assembly.",
                "When they had come to him, he said to them, \"You yourselves know, from the first day that I set foot in Asia, how I was with you all the time,",
                "serving the Lord with all humility, with many tears, and with trials which happened to me by the plots of the Jews;",
                "how I didn\u2019t shrink from declaring to you anything that was profitable, teaching you publicly and from house to house,",
                "testifying both to Jews and to Greeks repentance toward God, and faith toward our Lord Jesus.",
                "Now, behold, I go bound by the Spirit to Jerusalem, not knowing what will happen to me there;",
                "except that the Holy Spirit testifies in every city, saying that bonds and afflictions wait for me.",
                "But these things don\u2019t count; nor do I hold my life dear to myself, so that I may finish my race with joy, and the ministry which I received from the Lord Jesus, to fully testify to the Good News of the grace of God.",
                "\"Now, behold, I know that you all, among whom I went about preaching the Kingdom of God, will see my face no more.",
                "Therefore I testify to you this day that I am clean from the blood of all men,",
                "for I didn\u2019t shrink from declaring to you the whole counsel of God.",
                "Take heed, therefore, to yourselves, and to all the flock, in which the Holy Spirit has made you overseers, to shepherd the assembly of the Lord and God which he purchased with his own blood.",
                "For I know that after my departure, vicious wolves will enter in among you, not sparing the flock.",
                "Men will arise from among your own selves, speaking perverse things, to draw away the disciples after them.",
                "Therefore watch, remembering that for a period of three years I didn\u2019t cease to admonish everyone night and day with tears.",
                "Now, brothers, I entrust you to God, and to the word of his grace, which is able to build up, and to give you the inheritance among all those who are sanctified.",
                "I coveted no one\u2019s silver, or gold, or clothing.",
                "You yourselves know that these hands served my necessities, and those who were with me.",
                "In all things I gave you an example, that so laboring you ought to help the weak, and to remember the words of the Lord Jesus, that he himself said, \u2018It is more blessed to give than to receive.\u2019\"",
                "When he had spoken these things, he knelt down and prayed with them all.",
                "They all wept a lot, and fell on Paul\u2019s neck and kissed him,",
                "sorrowing most of all because of the word which he had spoken, that they should see his face no more. And they accompanied him to the ship."
            ],
            21: [
                "When it happened that we had parted from them and had set sail, we came with a straight course to Cos, and the next day to Rhodes, and from there to Patara.",
                "Having found a ship crossing over to Phoenicia, we went aboard, and set sail.",
                "When we had come in sight of Cyprus, leaving it on the left hand, we sailed to Syria, and landed at Tyre, for there the ship was to unload her cargo.",
                "Having found disciples, we stayed there seven days. These said to Paul through the Spirit, that he should not go up to Jerusalem.",
                "When it happened that we had accomplished the days, we departed and went on our journey. They all, with wives and children, brought us on our way until we were out of the city. Kneeling down on the beach, we prayed.",
                "After saying goodbye to each other, we went on board the ship, and they returned home again.",
                "When we had finished the voyage from Tyre, we arrived at Ptolemais. We greeted the brothers, and stayed with them one day.",
                "On the next day, we, who were Paul\u2019s companions, departed, and came to Caesarea. We entered into the house of Philip the evangelist, who was one of the seven, and stayed with him.",
                "Now this man had four virgin daughters who prophesied.",
                "As we stayed there some days, a certain prophet named Agabus came down from Judea.",
                "Coming to us, and taking Paul\u2019s belt, he bound his own feet and hands, and said, \"Thus says the Holy Spirit: \u2018So will the Jews at Jerusalem bind the man who owns this belt, and will deliver him into the hands of the Gentiles.\u2019\"",
                "When we heard these things, both we and they of that place begged him not to go up to Jerusalem.",
                "Then Paul answered, \"What are you doing, weeping and breaking my heart? For I am ready not only to be bound, but also to die at Jerusalem for the name of the Lord Jesus.\"",
                "When he would not be persuaded, we ceased, saying, \"The Lord\u2019s will be done.\"",
                "After these days we took up our baggage and went up to Jerusalem.",
                "Some of the disciples from Caesarea also went with us, bringing one Mnason of Cyprus, an early disciple, with whom we would stay.",
                "When we had come to Jerusalem, the brothers received us gladly.",
                "The day following, Paul went in with us to James; and all the elders were present.",
                "When he had greeted them, he reported one by one the things which God had worked among the Gentiles through his ministry.",
                "They, when they heard it, glorified God. They said to him, \"You see, brother, how many thousands there are among the Jews of those who have believed, and they are all zealous for the law.",
                "They have been informed about you, that you teach all the Jews who are among the Gentiles to forsake Moses, telling them not to circumcise their children neither to walk after the customs.",
                "What then? The assembly must certainly meet, for they will hear that you have come.",
                "Therefore do what we tell you. We have four men who have taken a vow.",
                "Take them, and purify yourself with them, and pay their expenses for them, that they may shave their heads. Then all will know that there is no truth in the things that they have been informed about you, but that you yourself also walk keeping the law.",
                "But concerning the Gentiles who believe, we have written our decision that they should observe no such thing, except that they should keep themselves from food offered to idols, from blood, from strangled things, and from sexual immorality.\"",
                "Then Paul took the men, and the next day, purified himself and went with them into the temple, declaring the fulfillment of the days of purification, until the offering was offered for every one of them.",
                "When the seven days were almost completed, the Jews from Asia, when they saw him in the temple, stirred up all the multitude and laid hands on him,",
                "crying out, \"Men of Israel, help! This is the man who teaches all men everywhere against the people, and the law, and this place. Moreover, he also brought Greeks into the temple, and has defiled this holy place!\"",
                "For they had seen Trophimus, the Ephesian, with him in the city, and they supposed that Paul had brought him into the temple.",
                "All the city was moved, and the people ran together. They seized Paul and dragged him out of the temple. Immediately the doors were shut.",
                "As they were trying to kill him, news came up to the commanding officer of the regiment that all Jerusalem was in an uproar.",
                "Immediately he took soldiers and centurions, and ran down to them. They, when they saw the chief captain and the soldiers, stopped beating Paul.",
                "Then the commanding officer came near, arrested him, commanded him to be bound with two chains, and inquired who he was and what he had done.",
                "Some shouted one thing, and some another, among the crowd. When he couldn\u2019t find out the truth because of the noise, he commanded him to be brought into the barracks.",
                "When he came to the stairs, it happened that he was carried by the soldiers because of the violence of the crowd;",
                "for the multitude of the people followed after, crying out, \"Away with him!\"",
                "As Paul was about to be brought into the barracks, he asked the commanding officer, \"May I speak to you?\" He said, \"Do you know Greek?",
                "Aren\u2019t you then the Egyptian, who before these days stirred up to sedition and led out into the wilderness the four thousand men of the Assassins?\"",
                "But Paul said, \"I am a Jew, from Tarsus in Cilicia, a citizen of no insignificant city. I beg you, allow me to speak to the people.\"",
                "When he had given him permission, Paul, standing on the stairs, beckoned with his hand to the people. When there was a great silence, he spoke to them in the Hebrew language, saying,"
            ],
            22: [
                "\"Brothers and fathers, listen to the defense which I now make to you.\"",
                "When they heard that he spoke to them in the Hebrew language, they were even more quiet. He said,",
                "\"I am indeed a Jew, born in Tarsus of Cilicia, but brought up in this city at the feet of Gamaliel, instructed according to the strict tradition of the law of our fathers, being zealous for God, even as you all are this day.",
                "I persecuted this Way to the death, binding and delivering into prisons both men and women.",
                "As also the high priest and all the council of the elders testify, from whom also I received letters to the brothers, and traveled to Damascus to bring them also who were there to Jerusalem in bonds to be punished.",
                "It happened that, as I made my journey, and came close to Damascus, about noon, suddenly there shone from the sky a great light around me.",
                "I fell to the ground, and heard a voice saying to me, \u2018Saul, Saul, why are you persecuting me?\u2019",
                "I answered, \u2018Who are you, Lord?\u2019 He said to me, \u2018I am Jesus of Nazareth, whom you persecute.\u2019",
                "\"Those who were with me indeed saw the light and were afraid, but they didn\u2019t understand the voice of him who spoke to me.",
                "I said, \u2018What shall I do, Lord?\u2019 The Lord said to me, \u2018Arise, and go into Damascus. There you will be told about all things which are appointed for you to do.\u2019",
                "When I couldn\u2019t see for the glory of that light, being led by the hand of those who were with me, I came into Damascus.",
                "One Ananias, a devout man according to the law, well reported of by all the Jews who lived in Damascus,",
                "came to me, and standing by me said to me, \u2018Brother Saul, receive your sight!\u2019 In that very hour I looked up at him.",
                "He said, \u2018The God of our fathers has appointed you to know his will, and to see the Righteous One, and to hear a voice from his mouth.",
                "For you will be a witness for him to all men of what you have seen and heard.",
                "Now why do you wait? Arise, be baptized, and wash away your sins, calling on the name of the Lord.\u2019",
                "\"It happened that, when I had returned to Jerusalem, and while I prayed in the temple, I fell into a trance,",
                "and saw him saying to me, \u2018Hurry and get out of Jerusalem quickly, because they will not receive testimony concerning me from you.\u2019",
                "I said, \u2018Lord, they themselves know that I imprisoned and beat in every synagogue those who believed in you.",
                "When the blood of Stephen, your witness, was shed, I also was standing by, and consenting to his death, and guarding the cloaks of those who killed him.\u2019",
                "\"He said to me, \u2018Depart, for I will send you out far from here to the Gentiles.\u2019\"",
                "They listened to him until he said that; then they lifted up their voice, and said, \"Rid the earth of this fellow, for he isn\u2019t fit to live!\"",
                "As they cried out, and threw off their cloaks, and threw dust into the air,",
                "the commanding officer commanded him to be brought into the barracks, ordering him to be examined by scourging, that he might know for what crime they shouted against him like that.",
                "When they had tied him up with thongs, Paul asked the centurion who stood by, \"Is it lawful for you to scourge a man who is a Roman, and not found guilty?\"",
                "When the centurion heard it, he went to the commanding officer and told him, \"Watch what you are about to do, for this man is a Roman!\"",
                "The commanding officer came and asked him, \"Tell me, are you a Roman?\" He said, \"Yes.\"",
                "The commanding officer answered, \"I bought my citizenship for a great price.\" Paul said, \"But I was born a Roman.\"",
                "Immediately those who were about to examine him departed from him, and the commanding officer also was afraid when he realized that he was a Roman, because he had bound him.",
                "But on the next day, desiring to know the truth about why he was accused by the Jews, he freed him from the bonds, and commanded the chief priests and all the council to come together, and brought Paul down and set him before them."
            ],
            23: [
                "Paul, looking steadfastly at the council, said, \"Brothers, I have lived before God in all good conscience until this day.\"",
                "The high priest, Ananias, commanded those who stood by him to strike him on the mouth.",
                "Then Paul said to him, \"God will strike you, you whitewashed wall! Do you sit to judge me according to the law, and command me to be struck contrary to the law?\"",
                "Those who stood by said, \"Do you malign God\u2019s high priest?\"",
                "Paul said, \"I didn\u2019t know, brothers, that he was high priest. For it is written, \u2018You shall not speak evil of a ruler of your people.\u2019\"",
                "But when Paul perceived that the one part were Sadducees and the other Pharisees, he cried out in the council, \"Men and brothers, I am a Pharisee, a son of Pharisees. Concerning the hope and resurrection of the dead I am being judged!\"",
                "When he had said this, an argument arose between the Pharisees and Sadducees, and the assembly was divided.",
                "For the Sadducees say that there is no resurrection, nor angel, nor spirit; but the Pharisees confess all of these.",
                "A great clamor arose, and some of the scribes of the Pharisees part stood up, and contended, saying, \"We find no evil in this man. But if a spirit or angel has spoken to him, let\u2019s not fight against God!\"",
                "When a great argument arose, the commanding officer, fearing that Paul would be torn in pieces by them, commanded the soldiers to go down and take him by force from among them, and bring him into the barracks.",
                "The following night, the Lord stood by him, and said, \"Cheer up, Paul, for as you have testified about me at Jerusalem, so you must testify also at Rome.\"",
                "When it was day, some of the Jews banded together, and bound themselves under a curse, saying that they would neither eat nor drink until they had killed Paul.",
                "There were more than forty people who had made this conspiracy.",
                "They came to the chief priests and the elders, and said, \"We have bound ourselves under a great curse, to taste nothing until we have killed Paul.",
                "Now therefore, you with the council inform the commanding officer that he should bring him down to you tomorrow, as though you were going to judge his case more exactly. We are ready to kill him before he comes near.\"",
                "But Paul\u2019s sister\u2019s son heard of their lying in wait, and he came and entered into the barracks and told Paul.",
                "Paul summoned one of the centurions, and said, \"Bring this young man to the commanding officer, for he has something to tell him.\"",
                "So he took him, and brought him to the commanding officer, and said, \"Paul, the prisoner, summoned me and asked me to bring this young man to you, who has something to tell you.\"",
                "The commanding officer took him by the hand, and going aside, asked him privately, \"What is it that you have to tell me?\"",
                "He said, \"The Jews have agreed to ask you to bring Paul down to the council tomorrow, as though intending to inquire somewhat more accurately concerning him.",
                "Therefore don\u2019t yield to them, for more than forty men lie in wait for him, who have bound themselves under a curse neither to eat nor to drink until they have killed him. Now they are ready, looking for the promise from you.\"",
                "So the commanding officer let the young man go, charging him, \"Tell no one that you have revealed these things to me.\"",
                "He called to himself two of the centurions, and said, \"Prepare two hundred soldiers to go as far as Caesarea, with seventy horsemen, and two hundred men armed with spears, at the third hour of the night.\"",
                "He asked them to provide animals, that they might set Paul on one, and bring him safely to Felix the governor.",
                "He wrote a letter like this:",
                "\"Claudius Lysias to the most excellent governor Felix: Greetings.",
                "\"This man was seized by the Jews, and was about to be killed by them, when I came with the soldiers and rescued him, having learned that he was a Roman.",
                "Desiring to know the cause why they accused him, I brought him down to their council.",
                "I found him to be accused about questions of their law, but not to be charged with anything worthy of death or of imprisonment.",
                "When I was told that the Jews lay in wait for the man, I sent him to you immediately, charging his accusers also to bring their accusations against him before you. Farewell.\"",
                "So the soldiers, carrying out their orders, took Paul and brought him by night to Antipatris.",
                "But on the next day they left the horsemen to go with him, and returned to the barracks.",
                "When they came to Caesarea and delivered the letter to the governor, they also presented Paul to him.",
                "When the governor had read it, he asked what province he was from. When he understood that he was from Cilicia, he said,",
                "\"I will hear you fully when your accusers also arrive.\" He commanded that he be kept in Herod\u2019s palace."
            ],
            24: [
                "After five days, the high priest, Ananias, came down with certain elders and an orator, one Tertullus. They informed the governor against Paul.",
                "When he was called, Tertullus began to accuse him, saying, \"Seeing that by you we enjoy much peace, and that excellent measures are coming to this nation,",
                "we accept it in all ways and in all places, most excellent Felix, with all thankfulness.",
                "But, that I don\u2019t delay you, I entreat you to bear with us and hear a few words.",
                "For we have found this man to be a plague, an instigator of insurrections among all the Jews throughout the world, and a ringleader of the sect of the Nazarenes.",
                "He even tried to profane the temple, and we arrested him.",
                "By examining him yourself you may ascertain all these things of which we accuse him.\"",
                "The Jews also joined in the attack, affirming that these things were so.",
                "When the governor had beckoned to him to speak, Paul answered, \"Because I know that you have been a judge of this nation for many years, I cheerfully make my defense,",
                "seeing that you can recognize that it is not more than twelve days since I went up to worship at Jerusalem.",
                "In the temple they didn\u2019t find me disputing with anyone or stirring up a crowd, either in the synagogues, or in the city.",
                "Nor can they prove to you the things of which they now accuse me.",
                "But this I confess to you, that after the Way, which they call a sect, so I serve the God of our fathers, believing all things which are according to the law, and which are written in the prophets;",
                "having hope toward God, which these also themselves look for, that there will be a resurrection of the dead, both of the just and unjust.",
                "Herein I also practice always having a conscience void of offense toward God and men.",
                "Now after some years, I came to bring gifts for the needy to my nation, and offerings;",
                "amid which certain Jews from Asia found me purified in the temple, not with a mob, nor with turmoil.",
                "They ought to have been here before you, and to make accusation, if they had anything against me.",
                "Or else let these men themselves say what injustice they found in me when I stood before the council,",
                "unless it is for this one thing that I cried standing among them, \u2018Concerning the resurrection of the dead I am being judged before you today!\u2019\"",
                "But Felix, having more exact knowledge concerning the Way, deferred them, saying, \"When Lysias, the commanding officer, comes down, I will decide your case.\"",
                "He ordered the centurion that Paul should be kept in custody, and should have some privileges, and not to forbid any of his friends to serve him or to visit him.",
                "But after some days, Felix came with Drusilla, his wife, who was a Jewess, and sent for Paul, and heard him concerning the faith in Christ Jesus.",
                "As he reasoned about righteousness, self-control, and the judgment to come, Felix was terrified, and answered, \"Go your way for this time, and when it is convenient for me, I will summon you.\"",
                "Meanwhile, he also hoped that money would be given to him by Paul, that he might release him. Therefore also he sent for him more often, and talked with him.",
                "But when two years were fulfilled, Felix was succeeded by Porcius Festus, and desiring to gain favor with the Jews, Felix left Paul in bonds."
            ],
            25: [
                "Festus therefore, having come into the province, after three days went up to Jerusalem from Caesarea.",
                "Then the high priest and the principal men of the Jews informed him against Paul, and they begged him,",
                "asking a favor against him, that he would summon him to Jerusalem; plotting to kill him on the way.",
                "However Festus answered that Paul should be kept in custody at Caesarea, and that he himself was about to depart shortly.",
                "\"Let them therefore,\" said he, \"that are in power among you go down with me, and if there is anything wrong in the man, let them accuse him.\"",
                "When he had stayed among them more than ten days, he went down to Caesarea, and on the next day he sat on the judgment seat, and commanded Paul to be brought.",
                "When he had come, the Jews who had come down from Jerusalem stood around him, bringing against him many and grievous charges which they could not prove,",
                "while he said in his defense, \"Neither against the law of the Jews, nor against the temple, nor against Caesar, have I sinned at all.\"",
                "But Festus, desiring to gain favor with the Jews, answered Paul and said, \"Are you willing to go up to Jerusalem, and be judged by me there concerning these things?\"",
                "But Paul said, \"I am standing before Caesar\u2019s judgment seat, where I ought to be tried. I have done no wrong to the Jews, as you also know very well.",
                "For if I have done wrong, and have committed anything worthy of death, I don\u2019t refuse to die; but if none of those things is true that they accuse me of, no one can give me up to them. I appeal to Caesar!\"",
                "Then Festus, when he had conferred with the council, answered, \"You have appealed to Caesar. To Caesar you shall go.\"",
                "Now when some days had passed, King Agrippa and Bernice arrived at Caesarea, and greeted Festus.",
                "As he stayed there many days, Festus laid Paul\u2019s case before the king, saying, \"There is a certain man left a prisoner by Felix;",
                "about whom, when I was at Jerusalem, the chief priests and the elders of the Jews informed me, asking for a sentence against him.",
                "To whom I answered that it is not the custom of the Romans to give up any man to destruction, before the accused has met the accusers face to face, and has had opportunity to make his defense concerning the matter laid against him.",
                "When therefore they had come together here, I didn\u2019t delay, but on the next day sat on the judgment seat, and commanded the man to be brought.",
                "Concerning whom, when the accusers stood up, they brought no charge of such things as I supposed;",
                "but had certain questions against him about their own religion, and about one Jesus, who was dead, whom Paul affirmed to be alive.",
                "Being perplexed how to inquire concerning these things, I asked whether he was willing to go to Jerusalem and there be judged concerning these matters.",
                "But when Paul had appealed to be kept for the decision of the emperor, I commanded him to be kept until I could send him to Caesar.\"",
                "Agrippa said to Festus, \"I also would like to hear the man myself.\" \"Tomorrow,\" he said, \"you shall hear him.\"",
                "So on the next day, when Agrippa and Bernice had come with great pomp, and they had entered into the place of hearing with the commanding officers and principal men of the city, at the command of Festus, Paul was brought in.",
                "Festus said, \"King Agrippa, and all men who are here present with us, you see this man, about whom all the multitude of the Jews petitioned me, both at Jerusalem and here, crying that he ought not to live any longer.",
                "But when I found that he had committed nothing worthy of death, and as he himself appealed to the emperor I determined to send him.",
                "Of whom I have no certain thing to write to my lord. Therefore I have brought him forth before you, and especially before you, King Agrippa, that, after examination, I may have something to write.",
                "For it seems to me unreasonable, in sending a prisoner, not to also specify the charges against him.\""
            ],
            26: [
                "Agrippa said to Paul, \"You may speak for yourself.\" Then Paul stretched out his hand, and made his defense.",
                "\"I think myself happy, King Agrippa, that I am to make my defense before you this day concerning all the things that I am accused by the Jews,",
                "especially because you are expert in all customs and questions which are among the Jews. Therefore I beg you to hear me patiently.",
                "\"Indeed, all the Jews know my way of life from my youth up, which was from the beginning among my own nation and at Jerusalem;",
                "having known me from the first, if they are willing to testify, that after the strictest sect of our religion I lived a Pharisee.",
                "Now I stand here to be judged for the hope of the promise made by God to our fathers,",
                "which our twelve tribes, earnestly serving night and day, hope to attain. Concerning this hope I am accused by the Jews, King Agrippa!",
                "Why is it judged incredible with you, if God does raise the dead?",
                "\"I myself most certainly thought that I ought to do many things contrary to the name of Jesus of Nazareth.",
                "This I also did in Jerusalem. I both shut up many of the saints in prisons, having received authority from the chief priests, and when they were put to death I gave my vote against them.",
                "Punishing them often in all the synagogues, I tried to make them blaspheme. Being exceedingly enraged against them, I persecuted them even to foreign cities.",
                "\"Whereupon as I traveled to Damascus with the authority and commission from the chief priests,",
                "at noon, O king, I saw on the way a light from the sky, brighter than the sun, shining around me and those who traveled with me.",
                "When we had all fallen to the earth, I heard a voice saying to me in the Hebrew language, \u2018Saul, Saul, why are you persecuting me? It is hard for you to kick against the goads.\u2019",
                "\"I said, \u2018Who are you, Lord?\u2019 \"He said, \u2018I am Jesus, whom you are persecuting.",
                "But arise, and stand on your feet, for I have appeared to you for this purpose: to appoint you a servant and a witness both of the things which you have seen, and of the things which I will reveal to you;",
                "delivering you from the people, and from the Gentiles, to whom I send you,",
                "to open their eyes, that they may turn from darkness to light and from the power of Satan to God, that they may receive remission of sins and an inheritance among those who are sanctified by faith in me.\u2019",
                "\"Therefore, King Agrippa, I was not disobedient to the heavenly vision,",
                "but declared first to them of Damascus, at Jerusalem, and throughout all the country of Judea, and also to the Gentiles, that they should repent and turn to God, doing works worthy of repentance.",
                "For this reason the Jews seized me in the temple, and tried to kill me.",
                "Having therefore obtained the help that is from God, I stand to this day testifying both to small and great, saying nothing but what the prophets and Moses said would happen,",
                "how the Christ must suffer, and how, by the resurrection of the dead, he would be first to proclaim light both to these people and to the Gentiles.\"",
                "As he thus made his defense, Festus said with a loud voice, \"Paul, you are crazy! Your great learning is driving you insane!\"",
                "But he said, \"I am not crazy, most excellent Festus, but boldly declare words of truth and reasonableness.",
                "For the king knows of these things, to whom also I speak freely. For I am persuaded that none of these things is hidden from him, for this has not been done in a corner.",
                "King Agrippa, do you believe the prophets? I know that you believe.\"",
                "Agrippa said to Paul, \"With a little persuasion are you trying to make me a Christian?\"",
                "Paul said, \"I pray to God, that whether with little or with much, not only you, but also all that hear me this day, might become such as I am, except for these bonds.\"",
                "The king rose up with the governor, and Bernice, and those who sat with them.",
                "When they had withdrawn, they spoke one to another, saying, \"This man does nothing worthy of death or of bonds.\"",
                "Agrippa said to Festus, \"This man might have been set free if he had not appealed to Caesar.\""
            ],
            27: [
                "When it was determined that we should sail for Italy, they delivered Paul and certain other prisoners to a centurion named Julius, of the Augustan band.",
                "Embarking in a ship of Adramyttium, which was about to sail to places on the coast of Asia, we put to sea; Aristarchus, a Macedonian of Thessalonica, being with us.",
                "The next day, we touched at Sidon. Julius treated Paul kindly, and gave him permission to go to his friends and refresh himself.",
                "Putting to sea from there, we sailed under the lee of Cyprus, because the winds were contrary.",
                "When we had sailed across the sea which is off Cilicia and Pamphylia, we came to Myra, a city of Lycia.",
                "There the centurion found a ship of Alexandria sailing for Italy, and he put us on board.",
                "When we had sailed slowly many days, and had come with difficulty opposite Cnidus, the wind not allowing us further, we sailed under the lee of Crete, opposite Salmone.",
                "With difficulty sailing along it we came to a certain place called Fair Havens, near the city of Lasea.",
                "When much time had passed and the voyage was now dangerous, because the Fast had now already gone by, Paul admonished them,",
                "and said to them, \"Sirs, I perceive that the voyage will be with injury and much loss, not only of the cargo and the ship, but also of our lives.\"",
                "But the centurion gave more heed to the master and to the owner of the ship than to those things which were spoken by Paul.",
                "Because the haven was not suitable to winter in, the majority advised going to sea from there, if by any means they could reach Phoenix, and winter there, which is a port of Crete, looking northeast and southeast.",
                "When the south wind blew softly, supposing that they had obtained their purpose, they weighed anchor and sailed along Crete, close to shore.",
                "But before long, a stormy wind beat down from shore, which is called Euroclydon.",
                "When the ship was caught, and couldn\u2019t face the wind, we gave way to it, and were driven along.",
                "Running under the lee of a small island called Clauda, we were able, with difficulty, to secure the boat.",
                "After they had hoisted it up, they used cables to help reinforce the ship. Fearing that they would run aground on the Syrtis sand bars, they lowered the sea anchor, and so were driven along.",
                "As we labored exceedingly with the storm, the next day they began to throw things overboard.",
                "On the third day, they threw out the ship\u2019s tackle with their own hands.",
                "When neither sun nor stars shone on us for many days, and no small storm pressed on us, all hope that we would be saved was now taken away.",
                "When they had been long without food, Paul stood up in the middle of them, and said, \"Sirs, you should have listened to me, and not have set sail from Crete, and have gotten this injury and loss.",
                "Now I exhort you to cheer up, for there will be no loss of life among you, but only of the ship.",
                "For there stood by me this night an angel, belonging to the God whose I am and whom I serve,",
                "saying, \u2018Don\u2019t be afraid, Paul. You must stand before Caesar. Behold, God has granted you all those who sail with you.\u2019",
                "Therefore, sirs, cheer up! For I believe God, that it will be just as it has been spoken to me.",
                "But we must run aground on a certain island.\"",
                "But when the fourteenth night had come, as we were driven back and forth in the Adriatic Sea, about midnight the sailors surmised that they were drawing near to some land.",
                "They took soundings, and found twenty fathoms. After a little while, they took soundings again, and found fifteen fathoms.",
                "Fearing that we would run aground on rocky ground, they let go four anchors from the stern, and wished for daylight.",
                "As the sailors were trying to flee out of the ship, and had lowered the boat into the sea, pretending that they would lay out anchors from the bow,",
                "Paul said to the centurion and to the soldiers, \"Unless these stay in the ship, you can\u2019t be saved.\"",
                "Then the soldiers cut away the ropes of the boat, and let it fall off.",
                "While the day was coming on, Paul begged them all to take some food, saying, \"This day is the fourteenth day that you wait and continue fasting, having taken nothing.",
                "Therefore I beg you to take some food, for this is for your safety; for not a hair will perish from any of your heads.\"",
                "When he had said this, and had taken bread, he gave thanks to God in the presence of all, and he broke it, and began to eat.",
                "Then they all cheered up, and they also took food.",
                "In all, we were two hundred seventy-six souls on the ship.",
                "When they had eaten enough, they lightened the ship, throwing out the wheat into the sea.",
                "When it was day, they didn\u2019t recognize the land, but they noticed a certain bay with a beach, and they decided to try to drive the ship onto it.",
                "Casting off the anchors, they left them in the sea, at the same time untying the rudder ropes. Hoisting up the foresail to the wind, they made for the beach.",
                "But coming to a place where two seas met, they ran the vessel aground. The bow struck and remained immovable, but the stern began to break up by the violence of the waves.",
                "The soldiers\u2019 counsel was to kill the prisoners, so that none of them would swim out and escape.",
                "But the centurion, desiring to save Paul, stopped them from their purpose, and commanded that those who could swim should throw themselves overboard first to go toward the land;",
                "and the rest should follow, some on planks, and some on other things from the ship. So it happened that they all escaped safely to the land."
            ],
            28: [
                "When we had escaped, then they learned that the island was called Malta.",
                "The natives showed us uncommon kindness; for they kindled a fire, and received us all, because of the present rain, and because of the cold.",
                "But when Paul had gathered a bundle of sticks and laid them on the fire, a viper came out because of the heat, and fastened on his hand.",
                "When the natives saw the creature hanging from his hand, they said one to another, \"No doubt this man is a murderer, whom, though he has escaped from the sea, yet Justice has not allowed to live.\"",
                "However he shook off the creature into the fire, and wasn\u2019t harmed.",
                "But they expected that he would have swollen or fallen down dead suddenly, but when they watched for a long time and saw nothing bad happen to him, they changed their minds, and said that he was a god.",
                "Now in the neighborhood of that place were lands belonging to the chief man of the island, named Publius, who received us, and courteously entertained us for three days.",
                "It happened that the father of Publius lay sick of fever and dysentery. Paul entered in to him, prayed, and laying his hands on him, healed him.",
                "Then when this was done, the rest also who had diseases in the island came, and were cured.",
                "They also honored us with many honors, and when we sailed, they put on board the things that we needed.",
                "After three months, we set sail in a ship of Alexandria which had wintered in the island, whose sign was \"The Twin Brothers.\"",
                "Touching at Syracuse, we stayed there three days.",
                "From there we circled around and arrived at Rhegium. After one day, a south wind sprang up, and on the second day we came to Puteoli,",
                "where we found brothers, and were entreated to stay with them for seven days. So we came to Rome.",
                "From there the brothers, when they heard of us, came to meet us as far as The Market of Appius and The Three Taverns. When Paul saw them, he thanked God, and took courage.",
                "When we entered into Rome, the centurion delivered the prisoners to the captain of the guard, but Paul was allowed to stay by himself with the soldier who guarded him.",
                "It happened that after three days Paul called together those who were the leaders of the Jews. When they had come together, he said to them, \"I, brothers, though I had done nothing against the people, or the customs of our fathers, still was delivered prisoner from Jerusalem into the hands of the Romans,",
                "who, when they had examined me, desired to set me free, because there was no cause of death in me.",
                "But when the Jews spoke against it, I was constrained to appeal to Caesar, not that I had anything about which to accuse my nation.",
                "For this cause therefore I asked to see you and to speak with you. For because of the hope of Israel I am bound with this chain.\"",
                "They said to him, \"We neither received letters from Judea concerning you, nor did any of the brothers come here and report or speak any evil of you.",
                "But we desire to hear from you what you think. For, as concerning this sect, it is known to us that everywhere it is spoken against.\"",
                "When they had appointed him a day, many people came to him at his lodging. He explained to them, testifying about the Kingdom of God, and persuading them concerning Jesus, both from the law of Moses and from the prophets, from morning until evening.",
                "Some believed the things which were spoken, and some disbelieved.",
                "When they didn\u2019t agree among themselves, they departed after Paul had spoken one word, \"The Holy Spirit spoke rightly through Isaiah, the prophet, to our fathers,",
                "saying, \u2018Go to this people, and say, in hearing, you will hear, but will in no way understand. In seeing, you will see, but will in no way perceive.",
                "For this people\u2019s heart has grown callous. Their ears are dull of hearing. Their eyes they have closed. Lest they should see with their eyes, hear with their ears, understand with their heart, and would turn again, and I would heal them.\u2019",
                "\"Be it known therefore to you, that the salvation of God is sent to the nations. They will also listen.\"",
                "When he had said these words, the Jews departed, having a great dispute among themselves.",
                "Paul stayed two whole years in his own rented house, and received all who were coming to him,",
                "preaching the Kingdom of God, and teaching the things concerning the Lord Jesus Christ with all boldness, without hindrance."
            ]
        },
        45: {
            1: [
                "Paul, a servant of Jesus Christ, called to be an apostle, set apart for the Good News of God,",
                "which he promised before through his prophets in the holy Scriptures,",
                "concerning his Son, who was born of the seed of David according to the flesh,",
                "who was declared to be the Son of God with power, according to the Spirit of holiness, by the resurrection from the dead, Jesus Christ our Lord,",
                "through whom we received grace and apostleship, for obedience of faith among all the nations, for his name\u2019s sake;",
                "among whom you are also called to belong to Jesus Christ;",
                "to all who are in Rome, beloved of God, called to be saints: Grace to you and peace from God our Father and the Lord Jesus Christ.",
                "First, I thank my God through Jesus Christ for all of you, that your faith is proclaimed throughout the whole world.",
                "For God is my witness, whom I serve in my spirit in the Good News of his Son, how unceasingly I make mention of you always in my prayers,",
                "requesting, if by any means now at last I may be prospered by the will of God to come to you.",
                "For I long to see you, that I may impart to you some spiritual gift, to the end that you may be established;",
                "that is, that I with you may be encouraged in you, each of us by the other\u2019s faith, both yours and mine.",
                "Now I don\u2019t desire to have you unaware, brothers, that I often planned to come to you, and was hindered so far, that I might have some fruit among you also, even as among the rest of the Gentiles.",
                "I am debtor both to Greeks and to foreigners, both to the wise and to the foolish.",
                "So, as much as is in me, I am eager to preach the Good News to you also who are in Rome.",
                "For I am not ashamed of the Good News of Christ, for it is the power of God for salvation for everyone who believes; for the Jew first, and also for the Greek.",
                "For in it is revealed God\u2019s righteousness from faith to faith. As it is written, \"But the righteous shall live by faith.\"",
                "For the wrath of God is revealed from heaven against all ungodliness and unrighteousness of men, who suppress the truth in unrighteousness,",
                "because that which is known of God is revealed in them, for God revealed it to them.",
                "For the invisible things of him since the creation of the world are clearly seen, being perceived through the things that are made, even his everlasting power and divinity; that they may be without excuse.",
                "Because, knowing God, they didn\u2019t glorify him as God, neither gave thanks, but became vain in their reasoning, and their senseless heart was darkened.",
                "Professing themselves to be wise, they became fools,",
                "and traded the glory of the incorruptible God for the likeness of an image of corruptible man, and of birds, and four-footed animals, and creeping things.",
                "Therefore God also gave them up in the lusts of their hearts to uncleanness, that their bodies should be dishonored among themselves,",
                "who exchanged the truth of God for a lie, and worshiped and served the creature rather than the Creator, who is blessed forever. Amen.",
                "For this reason, God gave them up to vile passions. For their women changed the natural function into that which is against nature.",
                "Likewise also the men, leaving the natural function of the woman, burned in their lust toward one another, men doing what is inappropriate with men, and receiving in themselves the due penalty of their error.",
                "Even as they refused to have God in their knowledge, God gave them up to a reprobate mind, to do those things which are not fitting;",
                "being filled with all unrighteousness, sexual immorality, wickedness, covetousness, malice; full of envy, murder, strife, deceit, evil habits, secret slanderers,",
                "backbiters, hateful to God, insolent, haughty, boastful, inventors of evil things, disobedient to parents,",
                "without understanding, covenant breakers, without natural affection, unforgiving, unmerciful;",
                "who, knowing the ordinance of God, that those who practice such things are worthy of death, not only do the same, but also approve of those who practice them."
            ],
            2: [
                "Therefore you are without excuse, O man, whoever you are who judge. For in that which you judge another, you condemn yourself. For you who judge practice the same things.",
                "We know that the judgment of God is according to truth against those who practice such things.",
                "Do you think this, O man who judges those who practice such things, and do the same, that you will escape the judgment of God?",
                "Or do you despise the riches of his goodness, forbearance, and patience, not knowing that the goodness of God leads you to repentance?",
                "But according to your hardness and unrepentant heart you are treasuring up for yourself wrath in the day of wrath, revelation, and of the righteous judgment of God;",
                "who \"will pay back to everyone according to their works:\"",
                "to those who by patience in well-doing seek for glory, honor, and incorruptibility, eternal life;",
                "but to those who are self-seeking, and don\u2019t obey the truth, but obey unrighteousness, will be wrath and indignation,",
                "oppression and anguish, on every soul of man who works evil, to the Jew first, and also to the Greek.",
                "But glory, honor, and peace go to every man who works good, to the Jew first, and also to the Greek.",
                "For there is no partiality with God.",
                "For as many as have sinned without law will also perish without the law. As many as have sinned under the law will be judged by the law.",
                "For it isn\u2019t the hearers of the law who are righteous before God, but the doers of the law will be justified",
                "(for when Gentiles who don\u2019t have the law do by nature the things of the law, these, not having the law, are a law to themselves,",
                "in that they show the work of the law written in their hearts, their conscience testifying with them, and their thoughts among themselves accusing or else excusing them)",
                "in the day when God will judge the secrets of men, according to my Good News, by Jesus Christ.",
                "Indeed you bear the name of a Jew, and rest on the law, and glory in God,",
                "and know his will, and approve the things that are excellent, being instructed out of the law,",
                "and are confident that you yourself are a guide of the blind, a light to those who are in darkness,",
                "a corrector of the foolish, a teacher of babies, having in the law the form of knowledge and of the truth.",
                "You therefore who teach another, don\u2019t you teach yourself? You who preach that a man shouldn\u2019t steal, do you steal?",
                "You who say a man shouldn\u2019t commit adultery. Do you commit adultery? You who abhor idols, do you rob temples?",
                "You who glory in the law, through your disobedience of the law do you dishonor God?",
                "For \"the name of God is blasphemed among the Gentiles because of you,\" just as it is written.",
                "For circumcision indeed profits, if you are a doer of the law, but if you are a transgressor of the law, your circumcision has become uncircumcision.",
                "If therefore the uncircumcised keep the ordinances of the law, won\u2019t his uncircumcision be accounted as circumcision?",
                "Won\u2019t the uncircumcision which is by nature, if it fulfills the law, judge you, who with the letter and circumcision are a transgressor of the law?",
                "For he is not a Jew who is one outwardly, neither is that circumcision which is outward in the flesh;",
                "but he is a Jew who is one inwardly, and circumcision is that of the heart, in the spirit not in the letter; whose praise is not from men, but from God."
            ],
            3: [
                "Then what advantage does the Jew have? Or what is the profit of circumcision?",
                "Much in every way! Because first of all, they were entrusted with the oracles of God.",
                "For what if some were without faith? Will their lack of faith nullify the faithfulness of God?",
                "May it never be! Yes, let God be found true, but every man a liar. As it is written, \"That you might be justified in your words, and might prevail when you come into judgment.\"",
                "But if our unrighteousness commends the righteousness of God, what will we say? Is God unrighteous who inflicts wrath? I speak like men do.",
                "May it never be! For then how will God judge the world?",
                "For if the truth of God through my lie abounded to his glory, why am I also still judged as a sinner?",
                "Why not (as we are slanderously reported, and as some affirm that we say), \"Let us do evil, that good may come?\" Those who say so are justly condemned.",
                "What then? Are we better than they? No, in no way. For we previously warned both Jews and Greeks, that they are all under sin.",
                "As it is written, \"There is no one righteous; no, not one.",
                "There is no one who understands. There is no one who seeks after God.",
                "They have all turned aside. They have together become unprofitable. There is no one who does good, no, not, so much as one.\"",
                "\"Their throat is an open tomb. With their tongues they have used deceit.\" \"The poison of vipers is under their lips;\"",
                "\"whose mouth is full of cursing and bitterness.\"",
                "\"Their feet are swift to shed blood.",
                "Destruction and misery are in their ways.",
                "The way of peace, they haven\u2019t known.\"",
                "\"There is no fear of God before their eyes.\"",
                "Now we know that whatever things the law says, it speaks to those who are under the law, that every mouth may be closed, and all the world may be brought under the judgment of God.",
                "Because by the works of the law, no flesh will be justified in his sight. For through the law comes the knowledge of sin.",
                "But now apart from the law, a righteousness of God has been revealed, being testified by the law and the prophets;",
                "even the righteousness of God through faith in Jesus Christ to all and on all those who believe. For there is no distinction,",
                "for all have sinned, and fall short of the glory of God;",
                "being justified freely by his grace through the redemption that is in Christ Jesus;",
                "whom God set forth to be an atoning sacrifice, through faith in his blood, for a demonstration of his righteousness through the passing over of prior sins, in God\u2019s forbearance;",
                "to demonstrate his righteousness at this present time; that he might himself be just, and the justifier of him who has faith in Jesus.",
                "Where then is the boasting? It is excluded. By what kind of law? Of works? No, but by a law of faith.",
                "We maintain therefore that a man is justified by faith apart from the works of the law.",
                "Or is God the God of Jews only? Isn\u2019t he the God of Gentiles also? Yes, of Gentiles also,",
                "since indeed there is one God who will justify the circumcised by faith, and the uncircumcised through faith.",
                "Do we then nullify the law through faith? May it never be! No, we establish the law."
            ],
            4: [
                "What then will we say that Abraham, our forefather, has found according to the flesh?",
                "For if Abraham was justified by works, he has something to boast about, but not toward God.",
                "For what does the Scripture say? \"Abraham believed God, and it was accounted to him for righteousness.\"",
                "Now to him who works, the reward is not counted as grace, but as something owed.",
                "But to him who doesn\u2019t work, but believes in him who justifies the ungodly, his faith is accounted for righteousness.",
                "Even as David also pronounces blessing on the man to whom God counts righteousness apart from works,",
                "\"Blessed are they whose iniquities are forgiven, whose sins are covered.",
                "Blessed is the man whom the Lord will by no means charge with sin.\"",
                "Is this blessing then pronounced on the circumcised, or on the uncircumcised also? For we say that faith was accounted to Abraham for righteousness.",
                "How then was it counted? When he was in circumcision, or in uncircumcision? Not in circumcision, but in uncircumcision.",
                "He received the sign of circumcision, a seal of the righteousness of the faith which he had while he was in uncircumcision, that he might be the father of all those who believe, though they might be in uncircumcision, that righteousness might also be accounted to them.",
                "He is the father of circumcision to those who not only are of the circumcision, but who also walk in the steps of that faith of our father Abraham, which he had in uncircumcision.",
                "For the promise to Abraham and to his seed that he should be heir of the world wasn\u2019t through the law, but through the righteousness of faith.",
                "For if those who are of the law are heirs, faith is made void, and the promise is made of no effect.",
                "For the law works wrath, for where there is no law, neither is there disobedience.",
                "For this cause it is of faith, that it may be according to grace, to the end that the promise may be sure to all the seed, not to that only which is of the law, but to that also which is of the faith of Abraham, who is the father of us all.",
                "As it is written, \"I have made you a father of many nations.\" This is in the presence of him whom he believed: God, who gives life to the dead, and calls the things that are not, as though they were.",
                "Who in hope believed against hope, to the end that he might become a father of many nations, according to that which had been spoken, \"So will your seed be.\"",
                "Without being weakened in faith, he didn\u2019t consider his own body, already having been worn out, (he being about a hundred years old), and the deadness of Sarah\u2019s womb.",
                "Yet, looking to the promise of God, he didn\u2019t waver through unbelief, but grew strong through faith, giving glory to God,",
                "and being fully assured that what he had promised, he was also able to perform.",
                "Therefore it also was \"reckoned to him for righteousness.\"",
                "Now it was not written that it was accounted to him for his sake alone,",
                "but for our sake also, to whom it will be accounted, who believe in him who raised Jesus, our Lord, from the dead,",
                "who was delivered up for our trespasses, and was raised for our justification."
            ],
            5: [
                "Being therefore justified by faith, we have peace with God through our Lord Jesus Christ;",
                "through whom we also have our access by faith into this grace in which we stand. We rejoice in hope of the glory of God.",
                "Not only this, but we also rejoice in our sufferings, knowing that suffering works perseverance;",
                "and perseverance, proven character; and proven character, hope:",
                "and hope doesn\u2019t disappoint us, because God\u2019s love has been poured out into our hearts through the Holy Spirit who was given to us.",
                "For while we were yet weak, at the right time Christ died for the ungodly.",
                "For one will hardly die for a righteous man. Yet perhaps for a righteous person someone would even dare to die.",
                "But God commends his own love toward us, in that while we were yet sinners, Christ died for us.",
                "Much more then, being now justified by his blood, we will be saved from God\u2019s wrath through him.",
                "For if, while we were enemies, we were reconciled to God through the death of his Son, much more, being reconciled, we will be saved by his life.",
                "Not only so, but we also rejoice in God through our Lord Jesus Christ, through whom we have now received the reconciliation.",
                "Therefore, as sin entered into the world through one man, and death through sin; and so death passed to all men, because all sinned.",
                "For until the law, sin was in the world; but sin is not charged when there is no law.",
                "Nevertheless death reigned from Adam until Moses, even over those whose sins weren\u2019t like Adam\u2019s disobedience, who is a foreshadowing of him who was to come.",
                "But the free gift isn\u2019t like the trespass. For if by the trespass of the one the many died, much more did the grace of God, and the gift by the grace of the one man, Jesus Christ, abound to the many.",
                "The gift is not as through one who sinned: for the judgment came by one to condemnation, but the free gift came of many trespasses to justification.",
                "For if by the trespass of the one, death reigned through the one; so much more will those who receive the abundance of grace and of the gift of righteousness reign in life through the one, Jesus Christ.",
                "So then as through one trespass, all men were condemned; even so through one act of righteousness, all men were justified to life.",
                "For as through the one man\u2019s disobedience many were made sinners, even so through the obedience of the one, many will be made righteous.",
                "The law came in besides, that the trespass might abound; but where sin abounded, grace abounded more exceedingly;",
                "that as sin reigned in death, even so grace might reign through righteousness to eternal life through Jesus Christ our Lord."
            ],
            6: [
                "What shall we say then? Shall we continue in sin, that grace may abound?",
                "May it never be! We who died to sin, how could we live in it any longer?",
                "Or don\u2019t you know that all we who were baptized into Christ Jesus were baptized into his death?",
                "We were buried therefore with him through baptism to death, that just like Christ was raised from the dead through the glory of the Father, so we also might walk in newness of life.",
                "For if we have become united with him in the likeness of his death, we will also be part of his resurrection;",
                "knowing this, that our old man was crucified with him, that the body of sin might be done away with, so that we would no longer be in bondage to sin.",
                "For he who has died has been freed from sin.",
                "But if we died with Christ, we believe that we will also live with him;",
                "knowing that Christ, being raised from the dead, dies no more. Death no more has dominion over him!",
                "For the death that he died, he died to sin one time; but the life that he lives, he lives to God.",
                "Thus consider yourselves also to be dead to sin, but alive to God in Christ Jesus our Lord.",
                "Therefore don\u2019t let sin reign in your mortal body, that you should obey it in its lusts.",
                "Neither present your members to sin as instruments of unrighteousness, but present yourselves to God, as alive from the dead, and your members as instruments of righteousness to God.",
                "For sin will not have dominion over you. For you are not under law, but under grace.",
                "What then? Shall we sin, because we are not under law, but under grace? May it never be!",
                "Don\u2019t you know that to whom you present yourselves as servants to obedience, his servants you are whom you obey; whether of sin to death, or of obedience to righteousness?",
                "But thanks be to God, that, whereas you were bondservants of sin, you became obedient from the heart to that form of teaching whereunto you were delivered.",
                "Being made free from sin, you became bondservants of righteousness.",
                "I speak in human terms because of the weakness of your flesh, for as you presented your members as servants to uncleanness and to wickedness upon wickedness, even so now present your members as servants to righteousness for sanctification.",
                "For when you were servants of sin, you were free in regard to righteousness.",
                "What fruit then did you have at that time in the things of which you are now ashamed? For the end of those things is death.",
                "But now, being made free from sin, and having become servants of God, you have your fruit of sanctification, and the result of eternal life.",
                "For the wages of sin is death, but the free gift of God is eternal life in Christ Jesus our Lord."
            ],
            7: [
                "Or don\u2019t you know, brothers (for I speak to men who know the law), that the law has dominion over a man for as long as he lives?",
                "For the woman that has a husband is bound by law to the husband while he lives, but if the husband dies, she is discharged from the law of the husband.",
                "So then if, while the husband lives, she is joined to another man, she would be called an adulteress. But if the husband dies, she is free from the law, so that she is no adulteress, though she is joined to another man.",
                "Therefore, my brothers, you also were made dead to the law through the body of Christ, that you would be joined to another, to him who was raised from the dead, that we might bring forth fruit to God.",
                "For when we were in the flesh, the sinful passions which were through the law, worked in our members to bring forth fruit to death.",
                "But now we have been discharged from the law, having died to that in which we were held; so that we serve in newness of the spirit, and not in oldness of the letter.",
                "What shall we say then? Is the law sin? May it never be! However, I wouldn\u2019t have known sin, except through the law. For I wouldn\u2019t have known coveting, unless the law had said, \"You shall not covet.\"",
                "But sin, finding occasion through the commandment, produced in me all kinds of coveting. For apart from the law, sin is dead.",
                "I was alive apart from the law once, but when the commandment came, sin revived, and I died.",
                "The commandment, which was for life, this I found to be for death;",
                "for sin, finding occasion through the commandment, deceived me, and through it killed me.",
                "Therefore the law indeed is holy, and the commandment holy, and righteous, and good.",
                "Did then that which is good become death to me? May it never be! But sin, that it might be shown to be sin, by working death to me through that which is good; that through the commandment sin might become exceeding sinful.",
                "For we know that the law is spiritual, but I am fleshly, sold under sin.",
                "For I don\u2019t know what I am doing. For I don\u2019t practice what I desire to do; but what I hate, that I do.",
                "But if what I don\u2019t desire, that I do, I consent to the law that it is good.",
                "So now it is no more I that do it, but sin which dwells in me.",
                "For I know that in me, that is, in my flesh, dwells no good thing. For desire is present with me, but I don\u2019t find it doing that which is good.",
                "For the good which I desire, I don\u2019t do; but the evil which I don\u2019t desire, that I practice.",
                "But if what I don\u2019t desire, that I do, it is no more I that do it, but sin which dwells in me.",
                "I find then the law, that, to me, while I desire to do good, evil is present.",
                "For I delight in God\u2019s law after the inward man,",
                "but I see a different law in my members, warring against the law of my mind, and bringing me into captivity under the law of sin which is in my members.",
                "What a wretched man I am! Who will deliver me out of the body of this death?",
                "I thank God through Jesus Christ, our Lord! So then with the mind, I myself serve God\u2019s law, but with the flesh, the sin\u2019s law."
            ],
            8: [
                "There is therefore now no condemnation to those who are in Christ Jesus, who don\u2019t walk according to the flesh, but according to the Spirit.",
                "For the law of the Spirit of life in Christ Jesus made me free from the law of sin and of death.",
                "For what the law couldn\u2019t do, in that it was weak through the flesh, God did, sending his own Son in the likeness of sinful flesh and for sin, he condemned sin in the flesh;",
                "that the ordinance of the law might be fulfilled in us, who walk not after the flesh, but after the Spirit.",
                "For those who live according to the flesh set their minds on the things of the flesh, but those who live according to the Spirit, the things of the Spirit.",
                "For the mind of the flesh is death, but the mind of the Spirit is life and peace;",
                "because the mind of the flesh is hostile towards God; for it is not subject to God\u2019s law, neither indeed can it be.",
                "Those who are in the flesh can\u2019t please God.",
                "But you are not in the flesh but in the Spirit, if it is so that the Spirit of God dwells in you. But if any man doesn\u2019t have the Spirit of Christ, he is not his.",
                "If Christ is in you, the body is dead because of sin, but the spirit is alive because of righteousness.",
                "But if the Spirit of him who raised up Jesus from the dead dwells in you, he who raised up Christ Jesus from the dead will also give life to your mortal bodies through his Spirit who dwells in you.",
                "So then, brothers, we are debtors, not to the flesh, to live after the flesh.",
                "For if you live after the flesh, you must die; but if by the Spirit you put to death the deeds of the body, you will live.",
                "For as many as are led by the Spirit of God, these are children of God.",
                "For you didn\u2019t receive the spirit of bondage again to fear, but you received the Spirit of adoption, by whom we cry, \"Abba! Father!\"",
                "The Spirit himself testifies with our spirit that we are children of God;",
                "and if children, then heirs; heirs of God, and joint heirs with Christ; if indeed we suffer with him, that we may also be glorified with him.",
                "For I consider that the sufferings of this present time are not worthy to be compared with the glory which will be revealed toward us.",
                "For the creation waits with eager expectation for the children of God to be revealed.",
                "For the creation was subjected to vanity, not of its own will, but because of him who subjected it, in hope",
                "that the creation itself also will be delivered from the bondage of decay into the liberty of the glory of the children of God.",
                "For we know that the whole creation groans and travails in pain together until now.",
                "Not only so, but ourselves also, who have the first fruits of the Spirit, even we ourselves groan within ourselves, waiting for adoption, the redemption of our body.",
                "For we were saved in hope, but hope that is seen is not hope. For who hopes for that which he sees?",
                "But if we hope for that which we don\u2019t see, we wait for it with patience.",
                "In the same way, the Spirit also helps our weaknesses, for we don\u2019t know how to pray as we ought. But the Spirit himself makes intercession for us with groanings which can\u2019t be uttered.",
                "He who searches the hearts knows what is on the Spirit\u2019s mind, because he makes intercession for the saints according to God.",
                "We know that all things work together for good for those who love God, to those who are called according to his purpose.",
                "For whom he foreknew, he also predestined to be conformed to the image of his Son, that he might be the firstborn among many brothers.",
                "Whom he predestined, those he also called. Whom he called, those he also justified. Whom he justified, those he also glorified.",
                "What then shall we say about these things? If God is for us, who can be against us?",
                "He who didn\u2019t spare his own Son, but delivered him up for us all, how would he not also with him freely give us all things?",
                "Who could bring a charge against God\u2019s chosen ones? It is God who justifies.",
                "Who is he who condemns? It is Christ who died, yes rather, who was raised from the dead, who is at the right hand of God, who also makes intercession for us.",
                "Who shall separate us from the love of Christ? Could oppression, or anguish, or persecution, or famine, or nakedness, or peril, or sword?",
                "Even as it is written, \"For your sake we are killed all day long. We were accounted as sheep for the slaughter.\"",
                "No, in all these things, we are more than conquerors through him who loved us.",
                "For I am persuaded, that neither death, nor life, nor angels, nor principalities, nor things present, nor things to come, nor powers,",
                "nor height, nor depth, nor any other created thing, will be able to separate us from the love of God, which is in Christ Jesus our Lord."
            ],
            9: [
                "I tell the truth in Christ. I am not lying, my conscience testifying with me in the Holy Spirit,",
                "that I have great sorrow and unceasing pain in my heart.",
                "For I could wish that I myself were accursed from Christ for my brothers\u2019 sake, my relatives according to the flesh,",
                "who are Israelites; whose is the adoption, the glory, the covenants, the giving of the law, the service, and the promises;",
                "of whom are the fathers, and from whom is Christ as concerning the flesh, who is over all, God, blessed forever. Amen.",
                "But it is not as though the word of God has come to nothing. For they are not all Israel, that are of Israel.",
                "Neither, because they are Abraham\u2019s seed, are they all children. But, \"In Isaac will your seed be called.\"",
                "That is, it is not the children of the flesh who are children of God, but the children of the promise are counted as a seed.",
                "For this is a word of promise, \"At the appointed time I will come, and Sarah will have a son.\"",
                "Not only so, but Rebecca also conceived by one, by our father Isaac.",
                "For being not yet born, neither having done anything good or bad, that the purpose of God according to election might stand, not of works, but of him who calls,",
                "it was said to her, \"The elder will serve the younger.\"",
                "Even as it is written, \"Jacob I loved, but Esau I hated.\"",
                "What shall we say then? Is there unrighteousness with God? May it never be!",
                "For he said to Moses, \"I will have mercy on whom I have mercy, and I will have compassion on whom I have compassion.\"",
                "So then it is not of him who wills, nor of him who runs, but of God who has mercy.",
                "For the Scripture says to Pharaoh, \"For this very purpose I caused you to be raised up, that I might show in you my power, and that my name might be proclaimed in all the earth.\"",
                "So then, he has mercy on whom he desires, and he hardens whom he desires.",
                "You will say then to me, \"Why does he still find fault? For who withstands his will?\"",
                "But indeed, O man, who are you to reply against God? Will the thing formed ask him who formed it, \"Why did you make me like this?\"",
                "Or hasn\u2019t the potter a right over the clay, from the same lump to make one part a vessel for honor, and another for dishonor?",
                "What if God, willing to show his wrath, and to make his power known, endured with much patience vessels of wrath made for destruction,",
                "and that he might make known the riches of his glory on vessels of mercy, which he prepared beforehand for glory,",
                "us, whom he also called, not from the Jews only, but also from the Gentiles?",
                "As he says also in Hosea, \"I will call them \u2018my people,\u2019 which were not my people; and her \u2018beloved,\u2019 who was not beloved.\"",
                "\"It will be that in the place where it was said to them, \u2018You are not my people,\u2019 There they will be called \u2018children of the living God.\u2019\"",
                "Isaiah cries concerning Israel, \"If the number of the children of Israel are as the sand of the sea, it is the remnant who will be saved;",
                "for He will finish the work and cut it short in righteousness, because the LORD will make a short work upon the earth.\"",
                "As Isaiah has said before, \"Unless the Lord of Armies had left us a seed, we would have become like Sodom, and would have been made like Gomorrah.\"",
                "What shall we say then? That the Gentiles, who didn\u2019t follow after righteousness, attained to righteousness, even the righteousness which is of faith;",
                "but Israel, following after a law of righteousness, didn\u2019t arrive at the law of righteousness.",
                "Why? Because they didn\u2019t seek it by faith, but as it were by works of the law. They stumbled over the stumbling stone;",
                "even as it is written, \"Behold, I lay in Zion a stumbling stone and a rock of offense; and no one who believes in him will be disappointed.\""
            ],
            10: [
                "Brothers, my heart\u2019s desire and my prayer to God is for Israel, that they may be saved.",
                "For I testify about them that they have a zeal for God, but not according to knowledge.",
                "For being ignorant of God\u2019s righteousness, and seeking to establish their own righteousness, they didn\u2019t subject themselves to the righteousness of God.",
                "For Christ is the fulfillment of the law for righteousness to everyone who believes.",
                "For Moses writes about the righteousness of the law, \"The one who does them will live by them.\"",
                "But the righteousness which is of faith says this, \"Don\u2019t say in your heart, \u2018Who will ascend into heaven?\u2019 (that is, to bring Christ down);",
                "or, \u2018Who will descend into the abyss?\u2019 (that is, to bring Christ up from the dead.)\"",
                "But what does it say? \"The word is near you, in your mouth, and in your heart;\" that is, the word of faith, which we preach:",
                "that if you will confess with your mouth that Jesus is Lord, and believe in your heart that God raised him from the dead, you will be saved.",
                "For with the heart, one believes unto righteousness; and with the mouth confession is made unto salvation.",
                "For the Scripture says, \"Whoever believes in him will not be disappointed.\"",
                "For there is no distinction between Jew and Greek; for the same Lord is Lord of all, and is rich to all who call on him.",
                "For, \"Whoever will call on the name of the Lord will be saved.\"",
                "How then will they call on him in whom they have not believed? How will they believe in him whom they have not heard? How will they hear without a preacher?",
                "And how will they preach unless they are sent? As it is written: \"How beautiful are the feet of those who preach the Good News of peace, who bring glad tidings of good things!\"",
                "But they didn\u2019t all listen to the glad news. For Isaiah says, \"Lord, who has believed our report?\"",
                "So faith comes by hearing, and hearing by the word of God.",
                "But I say, didn\u2019t they hear? Yes, most certainly, \"Their sound went out into all the earth, their words to the ends of the world.\"",
                "But I ask, didn\u2019t Israel know? First Moses says, \"I will provoke you to jealousy with that which is no nation, with a nation void of understanding I will make you angry.\"",
                "Isaiah is very bold, and says, \"I was found by those who didn\u2019t seek me. I was revealed to those who didn\u2019t ask for me.\"",
                "But as to Israel he says, \"All day long I stretched out my hands to a disobedient and contrary people.\""
            ],
            11: [
                "I ask then, did God reject his people? May it never be! For I also am an Israelite, a descendant of Abraham, of the tribe of Benjamin.",
                "God didn\u2019t reject his people, which he foreknew. Or don\u2019t you know what the Scripture says about Elijah? How he pleads with God against Israel:",
                "\"Lord, they have killed your prophets, they have broken down your altars; and I am left alone, and they seek my life.\"",
                "But how does God answer him? \"I have reserved for myself seven thousand men, who have not bowed the knee to Baal.\"",
                "Even so then at this present time also there is a remnant according to the election of grace.",
                "And if by grace, then it is no longer of works; otherwise grace is no longer grace. But if it is of works, it is no longer grace; otherwise work is no longer work.",
                "What then? That which Israel seeks for, that he didn\u2019t obtain, but the chosen ones obtained it, and the rest were hardened.",
                "According as it is written, \"God gave them a spirit of stupor, eyes that they should not see, and ears that they should not hear, to this very day.\"",
                "David says, \"Let their table be made a snare, and a trap, a stumbling block, and a retribution to them.",
                "Let their eyes be darkened, that they may not see. Bow down their back always.\"",
                "I ask then, did they stumble that they might fall? May it never be! But by their fall salvation has come to the Gentiles, to provoke them to jealousy.",
                "Now if their fall is the riches of the world, and their loss the riches of the Gentiles; how much more their fullness?",
                "For I speak to you who are Gentiles. Since then as I am an apostle to Gentiles, I glorify my ministry;",
                "if by any means I may provoke to jealousy those who are my flesh, and may save some of them.",
                "For if the rejection of them is the reconciling of the world, what would their acceptance be, but life from the dead?",
                "If the first fruit is holy, so is the lump. If the root is holy, so are the branches.",
                "But if some of the branches were broken off, and you, being a wild olive, were grafted in among them, and became partaker with them of the root and of the richness of the olive tree;",
                "don\u2019t boast over the branches. But if you boast, it is not you who support the root, but the root supports you.",
                "You will say then, \"Branches were broken off, that I might be grafted in.\"",
                "True; by their unbelief they were broken off, and you stand by your faith. Don\u2019t be conceited, but fear;",
                "for if God didn\u2019t spare the natural branches, neither will he spare you.",
                "See then the goodness and severity of God. Toward those who fell, severity; but toward you, goodness, if you continue in his goodness; otherwise you also will be cut off.",
                "They also, if they don\u2019t continue in their unbelief, will be grafted in, for God is able to graft them in again.",
                "For if you were cut out of that which is by nature a wild olive tree, and were grafted contrary to nature into a good olive tree, how much more will these, which are the natural branches, be grafted into their own olive tree?",
                "For I don\u2019t desire you to be ignorant, brothers, of this mystery, so that you won\u2019t be wise in your own conceits, that a partial hardening has happened to Israel, until the fullness of the Gentiles has come in,",
                "and so all Israel will be saved. Even as it is written, \"There will come out of Zion the Deliverer, and he will turn away ungodliness from Jacob.",
                "This is my covenant to them, when I will take away their sins.\"",
                "Concerning the Good News, they are enemies for your sake. But concerning the election, they are beloved for the fathers\u2019 sake.",
                "For the gifts and the calling of God are irrevocable.",
                "For as you in time past were disobedient to God, but now have obtained mercy by their disobedience,",
                "even so these also have now been disobedient, that by the mercy shown to you they may also obtain mercy.",
                "For God has shut up all to disobedience, that he might have mercy on all.",
                "Oh the depth of the riches both of the wisdom and the knowledge of God! How unsearchable are his judgments, and his ways past tracing out!",
                "\"For who has known the mind of the Lord? Or who has been his counselor?\"",
                "\"Or who has first given to him, and it will be repaid to him again?\"",
                "For of him, and through him, and to him, are all things. To him be the glory for ever! Amen."
            ],
            12: [
                "Therefore I urge you, brothers, by the mercies of God, to present your bodies a living sacrifice, holy, acceptable to God, which is your spiritual service.",
                "Don\u2019t be conformed to this world, but be transformed by the renewing of your mind, so that you may prove what is the good, well-pleasing, and perfect will of God.",
                "For I say, through the grace that was given me, to every man who is among you, not to think of himself more highly than he ought to think; but to think reasonably, as God has apportioned to each person a measure of faith.",
                "For even as we have many members in one body, and all the members don\u2019t have the same function,",
                "so we, who are many, are one body in Christ, and individually members one of another.",
                "Having gifts differing according to the grace that was given to us, if prophecy, let us prophesy according to the proportion of our faith;",
                "or service, let us give ourselves to service; or he who teaches, to his teaching;",
                "or he who exhorts, to his exhorting: he who gives, let him do it with liberality; he who rules, with diligence; he who shows mercy, with cheerfulness.",
                "Let love be without hypocrisy. Abhor that which is evil. Cling to that which is good.",
                "In love of the brothers be tenderly affectionate one to another; in honor preferring one another;",
                "not lagging in diligence; fervent in spirit; serving the Lord;",
                "rejoicing in hope; enduring in troubles; continuing steadfastly in prayer;",
                "contributing to the needs of the saints; given to hospitality.",
                "Bless those who persecute you; bless, and don\u2019t curse.",
                "Rejoice with those who rejoice. Weep with those who weep.",
                "Be of the same mind one toward another. Don\u2019t set your mind on high things, but associate with the humble. Don\u2019t be wise in your own conceits.",
                "Repay no one evil for evil. Respect what is honorable in the sight of all men.",
                "If it is possible, as much as it is up to you, be at peace with all men.",
                "Don\u2019t seek revenge yourselves, beloved, but give place to God\u2019s wrath. For it is written, \"Vengeance belongs to me; I will repay, says the Lord.\"",
                "Therefore \"If your enemy is hungry, feed him. If he is thirsty, give him a drink; for in doing so, you will heap coals of fire on his head.\"",
                "Don\u2019t be overcome by evil, but overcome evil with good."
            ],
            13: [
                "Let every soul be in subjection to the higher authorities, for there is no authority except from God, and those who exist are ordained by God.",
                "Therefore he who resists the authority, withstands the ordinance of God; and those who withstand will receive to themselves judgment.",
                "For rulers are not a terror to the good work, but to the evil. Do you desire to have no fear of the authority? Do that which is good, and you will have praise from the same,",
                "for he is a servant of God to you for good. But if you do that which is evil, be afraid, for he doesn\u2019t bear the sword in vain; for he is a servant of God, an avenger for wrath to him who does evil.",
                "Therefore you need to be in subjection, not only because of the wrath, but also for conscience\u2019 sake.",
                "For this reason you also pay taxes, for they are servants of God\u2019s service, attending continually on this very thing.",
                "Give therefore to everyone what you owe: taxes to whom taxes are due; customs to whom customs; respect to whom respect; honor to whom honor.",
                "Owe no one anything, except to love one another; for he who loves his neighbor has fulfilled the law.",
                "For the commandments, \"You shall not commit adultery,\" \"You shall not murder,\" \"You shall not steal,\" \"You shall not give false testimony,\" \"You shall not covet,\" and whatever other commandments there are, are all summed up in this saying, namely, \"You shall love your neighbor as yourself.\"",
                "Love doesn\u2019t harm a neighbor. Love therefore is the fulfillment of the law.",
                "Do this, knowing the time, that it is already time for you to awaken out of sleep, for salvation is now nearer to us than when we first believed.",
                "The night is far gone, and the day is near. Let\u2019s therefore throw off the works of darkness, and let\u2019s put on the armor of light.",
                "Let us walk properly, as in the day; not in reveling and drunkenness, not in sexual promiscuity and lustful acts, and not in strife and jealousy.",
                "But put on the Lord Jesus Christ, and make no provision for the flesh, for its lusts."
            ],
            14: [
                "Now accept one who is weak in faith, but not for disputes over opinions.",
                "One man has faith to eat all things, but he who is weak eats only vegetables.",
                "Don\u2019t let him who eats despise him who doesn\u2019t eat. Don\u2019t let him who doesn\u2019t eat judge him who eats, for God has accepted him.",
                "Who are you who judge another\u2019s servant? To his own lord he stands or falls. Yes, he will be made to stand, for God has power to make him stand.",
                "One man esteems one day as more important. Another esteems every day alike. Let each man be fully assured in his own mind.",
                "He who observes the day, observes it to the Lord; and he who does not observe the day, to the Lord he does not observe it. He who eats, eats to the Lord, for he gives God thanks. He who doesn\u2019t eat, to the Lord he doesn\u2019t eat, and gives God thanks.",
                "For none of us lives to himself, and none dies to himself.",
                "For if we live, we live to the Lord. Or if we die, we die to the Lord. If therefore we live or die, we are the Lord\u2019s.",
                "For to this end Christ died, rose, and lived again, that he might be Lord of both the dead and the living.",
                "But you, why do you judge your brother? Or you again, why do you despise your brother? For we will all stand before the judgment seat of Christ.",
                "For it is written, \"\u2018As I live,\u2019 says the Lord, \u2018to me every knee will bow. Every tongue will confess to God.\u2019\"",
                "So then each one of us will give account of himself to God.",
                "Therefore let\u2019s not judge one another any more, but judge this rather, that no man put a stumbling block in his brother\u2019s way, or an occasion for falling.",
                "I know, and am persuaded in the Lord Jesus, that nothing is unclean of itself; except that to him who considers anything to be unclean, to him it is unclean.",
                "Yet if because of food your brother is grieved, you walk no longer in love. Don\u2019t destroy with your food him for whom Christ died.",
                "Then don\u2019t let your good be slandered,",
                "for the Kingdom of God is not eating and drinking, but righteousness, peace, and joy in the Holy Spirit.",
                "For he who serves Christ in these things is acceptable to God and approved by men.",
                "So then, let us follow after things which make for peace, and things by which we may build one another up.",
                "Don\u2019t overthrow God\u2019s work for food\u2019s sake. All things indeed are clean, however it is evil for that man who creates a stumbling block by eating.",
                "It is good to not eat meat, drink wine, nor do anything by which your brother stumbles, is offended, or is made weak.",
                "Do you have faith? Have it to yourself before God. Happy is he who doesn\u2019t judge himself in that which he approves.",
                "But he who doubts is condemned if he eats, because it isn\u2019t of faith; and whatever is not of faith is sin. (14:24) Now to him who is able to establish you according to my Good News and the preaching of Jesus Christ, according to the revelation of the mystery which has been kept secret through long ages, (14:25) but now is revealed, and by the Scriptures of the prophets, according to the commandment of the eternal God, is made known for obedience of faith to all the nations; (14:26) to the only wise God, through Jesus Christ, to whom be the glory forever! Amen."
            ],
            15: [
                "Now we who are strong ought to bear the weaknesses of the weak, and not to please ourselves.",
                "Let each one of us please his neighbor for that which is good, to be building him up.",
                "For even Christ didn\u2019t please himself. But, as it is written, \"The reproaches of those who reproached you fell on me.\"",
                "For whatever things were written before were written for our learning, that through patience and through encouragement of the Scriptures we might have hope.",
                "Now the God of patience and of encouragement grant you to be of the same mind one with another according to Christ Jesus,",
                "that with one accord you may with one mouth glorify the God and Father of our Lord Jesus Christ.",
                "Therefore accept one another, even as Christ also accepted you, to the glory of God.",
                "Now I say that Christ has been made a servant of the circumcision for the truth of God, that he might confirm the promises given to the fathers,",
                "and that the Gentiles might glorify God for his mercy. As it is written, \"Therefore will I give praise to you among the Gentiles, and sing to your name.\"",
                "Again he says, \"Rejoice, you Gentiles, with his people.\"",
                "Again, \"Praise the Lord, all you Gentiles! Let all the peoples praise him.\"",
                "Again, Isaiah says, \"There will be the root of Jesse, he who arises to rule over the Gentiles; in him the Gentiles will hope.\"",
                "Now may the God of hope fill you with all joy and peace in believing, that you may abound in hope, in the power of the Holy Spirit.",
                "I myself am also persuaded about you, my brothers, that you yourselves are full of goodness, filled with all knowledge, able also to admonish others.",
                "But I write the more boldly to you in part, as reminding you, because of the grace that was given to me by God,",
                "that I should be a servant of Christ Jesus to the Gentiles, serving as a priest the Good News of God, that the offering up of the Gentiles might be made acceptable, sanctified by the Holy Spirit.",
                "I have therefore my boasting in Christ Jesus in things pertaining to God.",
                "For I will not dare to speak of any things except those which Christ worked through me, for the obedience of the Gentiles, by word and deed,",
                "in the power of signs and wonders, in the power of God\u2019s Spirit; so that from Jerusalem, and around as far as to Illyricum, I have fully preached the Good News of Christ;",
                "yes, making it my aim to preach the Good News, not where Christ was already named, that I might not build on another\u2019s foundation.",
                "But, as it is written, \"They will see, to whom no news of him came. They who haven\u2019t heard will understand.\"",
                "Therefore also I was hindered these many times from coming to you,",
                "but now, no longer having any place in these regions, and having these many years a longing to come to you,",
                "whenever I journey to Spain, I will come to you. For I hope to see you on my journey, and to be helped on my way there by you, if first I may enjoy your company for a while.",
                "But now, I say, I am going to Jerusalem, serving the saints.",
                "For it has been the good pleasure of Macedonia and Achaia to make a certain contribution for the poor among the saints who are at Jerusalem.",
                "Yes, it has been their good pleasure, and they are their debtors. For if the Gentiles have been made partakers of their spiritual things, they owe it to them also to serve them in fleshly things.",
                "When therefore I have accomplished this, and have sealed to them this fruit, I will go on by way of you to Spain.",
                "I know that, when I come to you, I will come in the fullness of the blessing of the Good News of Christ.",
                "Now I beg you, brothers, by our Lord Jesus Christ, and by the love of the Spirit, that you strive together with me in your prayers to God for me,",
                "that I may be delivered from those who are disobedient in Judea, and that my service which I have for Jerusalem may be acceptable to the saints;",
                "that I may come to you in joy through the will of God, and together with you, find rest.",
                "Now the God of peace be with you all. Amen."
            ],
            16: [
                "I commend to you Phoebe, our sister, who is a servant of the assembly that is at Cenchreae,",
                "that you receive her in the Lord, in a way worthy of the saints, and that you assist her in whatever matter she may need from you, for she herself also has been a helper of many, and of my own self.",
                "Greet Prisca and Aquila, my fellow workers in Christ Jesus,",
                "who for my life, laid down their own necks; to whom not only I give thanks, but also all the assemblies of the Gentiles.",
                "Greet the assembly that is in their house. Greet Epaenetus, my beloved, who is the first fruits of Achaia to Christ.",
                "Greet Mary, who labored much for us.",
                "Greet Andronicus and Junia, my relatives and my fellow prisoners, who are notable among the apostles, who also were in Christ before me.",
                "Greet Amplias, my beloved in the Lord.",
                "Greet Urbanus, our fellow worker in Christ, and Stachys, my beloved.",
                "Greet Apelles, the approved in Christ. Greet those who are of the household of Aristobulus.",
                "Greet Herodion, my kinsman. Greet them of the household of Narcissus, who are in the Lord.",
                "Greet Tryphaena and Tryphosa, who labor in the Lord. Greet Persis, the beloved, who labored much in the Lord.",
                "Greet Rufus, the chosen in the Lord, and his mother and mine.",
                "Greet Asyncritus, Phlegon, Hermes, Patrobas, Hermas, and the brothers who are with them.",
                "Greet Philologus and Julia, Nereus and his sister, and Olympas, and all the saints who are with them.",
                "Greet one another with a holy kiss. The assemblies of Christ greet you.",
                "Now I beg you, brothers, look out for those who are causing the divisions and occasions of stumbling, contrary to the doctrine which you learned, and turn away from them.",
                "For those who are such don\u2019t serve our Lord, Jesus Christ, but their own belly; and by their smooth and flattering speech, they deceive the hearts of the innocent.",
                "For your obedience has become known to all. I rejoice therefore over you. But I desire to have you wise in that which is good, but innocent in that which is evil.",
                "And the God of peace will quickly crush Satan under your feet. The grace of our Lord Jesus Christ be with you.",
                "Timothy, my fellow worker, greets you, as do Lucius, Jason, and Sosipater, my relatives.",
                "I, Tertius, who write the letter, greet you in the Lord.",
                "Gaius, my host and host of the whole assembly, greets you. Erastus, the treasurer of the city, greets you, as does Quartus, the brother.",
                "The grace of our Lord Jesus Christ be with you all! Amen."
            ]
        },
        46: {
            1: [
                "Paul, called to be an apostle of Jesus Christ through the will of God, and our brother Sosthenes,",
                "to the assembly of God which is at Corinth; those who are sanctified in Christ Jesus, called to be saints, with all who call on the name of our Lord Jesus Christ in every place, both theirs and ours:",
                "Grace to you and peace from God our Father and the Lord Jesus Christ.",
                "I always thank my God concerning you, for the grace of God which was given you in Christ Jesus;",
                "that in everything you were enriched in him, in all speech and all knowledge;",
                "even as the testimony of Christ was confirmed in you:",
                "so that you come behind in no gift; waiting for the revelation of our Lord Jesus Christ;",
                "who will also confirm you until the end, blameless in the day of our Lord Jesus Christ.",
                "God is faithful, through whom you were called into the fellowship of his Son, Jesus Christ, our Lord.",
                "Now I beg you, brothers, through the name of our Lord, Jesus Christ, that you all speak the same thing and that there be no divisions among you, but that you be perfected together in the same mind and in the same judgment.",
                "For it has been reported to me concerning you, my brothers, by those who are from Chloe\u2019s household, that there are contentions among you.",
                "Now I mean this, that each one of you says, \"I follow Paul,\" \"I follow Apollos,\" \"I follow Cephas,\" and, \"I follow Christ.\"",
                "Is Christ divided? Was Paul crucified for you? Or were you baptized into the name of Paul?",
                "I thank God that I baptized none of you, except Crispus and Gaius,",
                "so that no one should say that I had baptized you into my own name.",
                "(I also baptized the household of Stephanas; besides them, I don\u2019t know whether I baptized any other.)",
                "For Christ sent me not to baptize, but to preach the Good News\u2014not in wisdom of words, so that the cross of Christ wouldn\u2019t be made void.",
                "For the word of the cross is foolishness to those who are dying, but to us who are saved it is the power of God.",
                "For it is written, \"I will destroy the wisdom of the wise, I will bring the discernment of the discerning to nothing.\"",
                "Where is the wise? Where is the scribe? Where is the lawyer of this world? Hasn\u2019t God made foolish the wisdom of this world?",
                "For seeing that in the wisdom of God, the world through its wisdom didn\u2019t know God, it was God\u2019s good pleasure through the foolishness of the preaching to save those who believe.",
                "For Jews ask for signs, Greeks seek after wisdom,",
                "but we preach Christ crucified; a stumbling block to Jews, and foolishness to Greeks,",
                "but to those who are called, both Jews and Greeks, Christ is the power of God and the wisdom of God.",
                "Because the foolishness of God is wiser than men, and the weakness of God is stronger than men.",
                "For you see your calling, brothers, that not many are wise according to the flesh, not many mighty, and not many noble;",
                "but God chose the foolish things of the world that he might put to shame those who are wise. God chose the weak things of the world, that he might put to shame the things that are strong;",
                "and God chose the lowly things of the world, and the things that are despised, and the things that are not, that he might bring to nothing the things that are:",
                "that no flesh should boast before God.",
                "But of him, you are in Christ Jesus, who was made to us wisdom from God, and righteousness and sanctification, and redemption:",
                "that, according as it is written, \"He who boasts, let him boast in the Lord.\""
            ],
            2: [
                "When I came to you, brothers, I didn\u2019t come with excellence of speech or of wisdom, proclaiming to you the testimony of God.",
                "For I determined not to know anything among you, except Jesus Christ, and him crucified.",
                "I was with you in weakness, in fear, and in much trembling.",
                "My speech and my preaching were not in persuasive words of human wisdom, but in demonstration of the Spirit and of power,",
                "that your faith wouldn\u2019t stand in the wisdom of men, but in the power of God.",
                "We speak wisdom, however, among those who are full grown; yet a wisdom not of this world, nor of the rulers of this world, who are coming to nothing.",
                "But we speak God\u2019s wisdom in a mystery, the wisdom that has been hidden, which God foreordained before the worlds for our glory,",
                "which none of the rulers of this world has known. For had they known it, they wouldn\u2019t have crucified the Lord of glory.",
                "But as it is written, \"Things which an eye didn\u2019t see, and an ear didn\u2019t hear, which didn\u2019t enter into the heart of man, these God has prepared for those who love him.\"",
                "But to us, God revealed them through the Spirit. For the Spirit searches all things, yes, the deep things of God.",
                "For who among men knows the things of a man, except the spirit of the man, which is in him? Even so, no one knows the things of God, except God\u2019s Spirit.",
                "But we received, not the spirit of the world, but the Spirit which is from God, that we might know the things that were freely given to us by God.",
                "Which things also we speak, not in words which man\u2019s wisdom teaches, but which the Holy Spirit teaches, comparing spiritual things with spiritual things.",
                "Now the natural man doesn\u2019t receive the things of God\u2019s Spirit, for they are foolishness to him, and he can\u2019t know them, because they are spiritually discerned.",
                "But he who is spiritual discerns all things, and he himself is judged by no one.",
                "\"For who has known the mind of the Lord, that he should instruct him?\" But we have Christ\u2019s mind."
            ],
            3: [
                "Brothers, I couldn\u2019t speak to you as to spiritual, but as to fleshly, as to babies in Christ.",
                "I fed you with milk, not with meat; for you weren\u2019t yet ready. Indeed, not even now are you ready,",
                "for you are still fleshly. For insofar as there is jealousy, strife, and factions among you, aren\u2019t you fleshly, and don\u2019t you walk in the ways of men?",
                "For when one says, \"I follow Paul,\" and another, \"I follow Apollos,\" aren\u2019t you fleshly?",
                "Who then is Apollos, and who is Paul, but servants through whom you believed; and each as the Lord gave to him?",
                "I planted. Apollos watered. But God gave the increase.",
                "So then neither he who plants is anything, nor he who waters, but God who gives the increase.",
                "Now he who plants and he who waters are the same, but each will receive his own reward according to his own labor.",
                "For we are God\u2019s fellow workers. You are God\u2019s farming, God\u2019s building.",
                "According to the grace of God which was given to me, as a wise master builder I laid a foundation, and another builds on it. But let each man be careful how he builds on it.",
                "For no one can lay any other foundation than that which has been laid, which is Jesus Christ.",
                "But if anyone builds on the foundation with gold, silver, costly stones, wood, hay, or stubble;",
                "each man\u2019s work will be revealed. For the Day will declare it, because it is revealed in fire; and the fire itself will test what sort of work each man\u2019s work is.",
                "If any man\u2019s work remains which he built on it, he will receive a reward.",
                "If any man\u2019s work is burned, he will suffer loss, but he himself will be saved, but as through fire.",
                "Don\u2019t you know that you are a temple of God, and that God\u2019s Spirit lives in you?",
                "If anyone destroys the temple of God, God will destroy him; for God\u2019s temple is holy, which you are.",
                "Let no one deceive himself. If anyone thinks that he is wise among you in this world, let him become a fool, that he may become wise.",
                "For the wisdom of this world is foolishness with God. For it is written, \"He has taken the wise in their craftiness.\"",
                "And again, \"The Lord knows the reasoning of the wise, that it is worthless.\"",
                "Therefore let no one boast in men. For all things are yours,",
                "whether Paul, or Apollos, or Cephas, or the world, or life, or death, or things present, or things to come. All are yours,",
                "and you are Christ\u2019s, and Christ is God\u2019s."
            ],
            4: [
                "So let a man think of us as Christ\u2019s servants, and stewards of God\u2019s mysteries.",
                "Here, moreover, it is required of stewards, that they be found faithful.",
                "But with me it is a very small thing that I should be judged by you, or by man\u2019s judgment. Yes, I don\u2019t judge my own self.",
                "For I know nothing against myself. Yet I am not justified by this, but he who judges me is the Lord.",
                "Therefore judge nothing before the time, until the Lord comes, who will both bring to light the hidden things of darkness, and reveal the counsels of the hearts. Then each man will get his praise from God.",
                "Now these things, brothers, I have in a figure transferred to myself and Apollos for your sakes, that in us you might learn not to think beyond the things which are written, that none of you be puffed up against one another.",
                "For who makes you different? And what do you have that you didn\u2019t receive? But if you did receive it, why do you boast as if you had not received it?",
                "You are already filled. You have already become rich. You have come to reign without us. Yes, and I wish that you did reign, that we also might reign with you.",
                "For, I think that God has displayed us, the apostles, last of all, like men sentenced to death. For we are made a spectacle to the world, both to angels and men.",
                "We are fools for Christ\u2019s sake, but you are wise in Christ. We are weak, but you are strong. You have honor, but we have dishonor.",
                "Even to this present hour we hunger, thirst, are naked, are beaten, and have no certain dwelling place.",
                "We toil, working with our own hands. When people curse us, we bless. Being persecuted, we endure.",
                "Being defamed, we entreat. We are made as the filth of the world, the dirt wiped off by all, even until now.",
                "I don\u2019t write these things to shame you, but to admonish you as my beloved children.",
                "For though you have ten thousand tutors in Christ, yet not many fathers. For in Christ Jesus, I became your father through the Good News.",
                "I beg you therefore, be imitators of me.",
                "Because of this I have sent Timothy to you, who is my beloved and faithful child in the Lord, who will remind you of my ways which are in Christ, even as I teach everywhere in every assembly.",
                "Now some are puffed up, as though I were not coming to you.",
                "But I will come to you shortly, if the Lord is willing. And I will know, not the word of those who are puffed up, but the power.",
                "For the Kingdom of God is not in word, but in power.",
                "What do you want? Shall I come to you with a rod, or in love and a spirit of gentleness?"
            ],
            5: [
                "It is actually reported that there is sexual immorality among you, and such sexual immorality as is not even named among the Gentiles, that one has his father\u2019s wife.",
                "You are puffed up, and didn\u2019t rather mourn, that he who had done this deed might be removed from among you.",
                "For I most certainly, as being absent in body but present in spirit, have already, as though I were present, judged him who has done this thing.",
                "In the name of our Lord Jesus Christ, you being gathered together, and my spirit, with the power of our Lord Jesus Christ,",
                "are to deliver such a one to Satan for the destruction of the flesh, that the spirit may be saved in the day of the Lord Jesus.",
                "Your boasting is not good. Don\u2019t you know that a little yeast leavens the whole lump?",
                "Purge out the old yeast, that you may be a new lump, even as you are unleavened. For indeed Christ, our Passover, has been sacrificed in our place.",
                "Therefore let us keep the feast, not with old yeast, neither with the yeast of malice and wickedness, but with the unleavened bread of sincerity and truth.",
                "I wrote to you in my letter to have no company with sexual sinners;",
                "yet not at all meaning with the sexual sinners of this world, or with the covetous and extortioners, or with idolaters; for then you would have to leave the world.",
                "But as it is, I wrote to you not to associate with anyone who is called a brother who is a sexual sinner, or covetous, or an idolater, or a slanderer, or a drunkard, or an extortioner. Don\u2019t even eat with such a person.",
                "For what have I to do with also judging those who are outside? Don\u2019t you judge those who are within?",
                "But those who are outside, God judges. \"Put away the wicked man from among yourselves.\""
            ],
            6: [
                "Dare any of you, having a matter against his neighbor, go to law before the unrighteous, and not before the saints?",
                "Don\u2019t you know that the saints will judge the world? And if the world is judged by you, are you unworthy to judge the smallest matters?",
                "Don\u2019t you know that we will judge angels? How much more, things that pertain to this life?",
                "If then, you have to judge things pertaining to this life, do you set them to judge who are of no account in the assembly?",
                "I say this to move you to shame. Isn\u2019t there even one wise man among you who would be able to decide between his brothers?",
                "But brother goes to law with brother, and that before unbelievers!",
                "Therefore it is already altogether a defect in you, that you have lawsuits one with another. Why not rather be wronged? Why not rather be defrauded?",
                "No, but you yourselves do wrong, and defraud, and that against your brothers.",
                "Or don\u2019t you know that the unrighteous will not inherit the Kingdom of God? Don\u2019t be deceived. Neither the sexually immoral, nor idolaters, nor adulterers, nor male prostitutes, nor homosexuals,",
                "nor thieves, nor covetous, nor drunkards, nor slanderers, nor extortioners, will inherit the Kingdom of God.",
                "Such were some of you, but you were washed. But you were sanctified. But you were justified in the name of the Lord Jesus, and in the Spirit of our God.",
                "\"All things are lawful for me,\" but not all things are expedient. \"All things are lawful for me,\" but I will not be brought under the power of anything.",
                "\"Foods for the belly, and the belly for foods,\" but God will bring to nothing both it and them. But the body is not for sexual immorality, but for the Lord; and the Lord for the body.",
                "Now God raised up the Lord, and will also raise us up by his power.",
                "Don\u2019t you know that your bodies are members of Christ? Shall I then take the members of Christ, and make them members of a prostitute? May it never be!",
                "Or don\u2019t you know that he who is joined to a prostitute is one body? For, \"The two,\" says he, \"will become one flesh.\"",
                "But he who is joined to the Lord is one spirit.",
                "Flee sexual immorality! \"Every sin that a man does is outside the body,\" but he who commits sexual immorality sins against his own body.",
                "Or don\u2019t you know that your body is a temple of the Holy Spirit which is in you, which you have from God? You are not your own,",
                "for you were bought with a price. Therefore glorify God in your body and in your spirit, which are God\u2019s."
            ],
            7: [
                "Now concerning the things about which you wrote to me: it is good for a man not to touch a woman.",
                "But, because of sexual immoralities, let each man have his own wife, and let each woman have her own husband.",
                "Let the husband render to his wife the affection owed her, and likewise also the wife to her husband.",
                "The wife doesn\u2019t have authority over her own body, but the husband. Likewise also the husband doesn\u2019t have authority over his own body, but the wife.",
                "Don\u2019t deprive one another, unless it is by consent for a season, that you may give yourselves to fasting and prayer, and may be together again, that Satan doesn\u2019t tempt you because of your lack of self-control.",
                "But this I say by way of concession, not of commandment.",
                "Yet I wish that all men were like me. However each man has his own gift from God, one of this kind, and another of that kind.",
                "But I say to the unmarried and to widows, it is good for them if they remain even as I am.",
                "But if they don\u2019t have self-control, let them marry. For it\u2019s better to marry than to burn.",
                "But to the married I command\u2014not I, but the Lord\u2014that the wife not leave her husband",
                "(but if she departs, let her remain unmarried, or else be reconciled to her husband), and that the husband not leave his wife.",
                "But to the rest I\u2014not the Lord\u2014say, if any brother has an unbelieving wife, and she is content to live with him, let him not leave her.",
                "The woman who has an unbelieving husband, and he is content to live with her, let her not leave her husband.",
                "For the unbelieving husband is sanctified in the wife, and the unbelieving wife is sanctified in the husband. Otherwise your children would be unclean, but now they are holy.",
                "Yet if the unbeliever departs, let there be separation. The brother or the sister is not under bondage in such cases, but God has called us in peace.",
                "For how do you know, wife, whether you will save your husband? Or how do you know, husband, whether you will save your wife?",
                "Only, as the Lord has distributed to each man, as God has called each, so let him walk. So I command in all the assemblies.",
                "Was anyone called having been circumcised? Let him not become uncircumcised. Has anyone been called in uncircumcision? Let him not be circumcised.",
                "Circumcision is nothing, and uncircumcision is nothing, but the keeping of the commandments of God.",
                "Let each man stay in that calling in which he was called.",
                "Were you called being a bondservant? Don\u2019t let that bother you, but if you get an opportunity to become free, use it.",
                "For he who was called in the Lord being a bondservant is the Lord\u2019s free man. Likewise he who was called being free is Christ\u2019s bondservant.",
                "You were bought with a price. Don\u2019t become bondservants of men.",
                "Brothers, let each man, in whatever condition he was called, stay in that condition with God.",
                "Now concerning virgins, I have no commandment from the Lord, but I give my judgment as one who has obtained mercy from the Lord to be trustworthy.",
                "I think that it is good therefore, because of the distress that is on us, that it is good for a man to be as he is.",
                "Are you bound to a wife? Don\u2019t seek to be freed. Are you free from a wife? Don\u2019t seek a wife.",
                "But if you marry, you have not sinned. If a virgin marries, she has not sinned. Yet such will have oppression in the flesh, and I want to spare you.",
                "But I say this, brothers: the time is short, that from now on, both those who have wives may be as though they had none;",
                "and those who weep, as though they didn\u2019t weep; and those who rejoice, as though they didn\u2019t rejoice; and those who buy, as though they didn\u2019t possess;",
                "and those who use the world, as not using it to the fullest. For the mode of this world passes away.",
                "But I desire to have you to be free from cares. He who is unmarried is concerned for the things of the Lord, how he may please the Lord;",
                "but he who is married is concerned about the things of the world, how he may please his wife.",
                "There is also a difference between a wife and a virgin. The unmarried woman cares about the things of the Lord, that she may be holy both in body and in spirit. But she who is married cares about the things of the world\u2014how she may please her husband.",
                "This I say for your own profit; not that I may ensnare you, but for that which is appropriate, and that you may attend to the Lord without distraction.",
                "But if any man thinks that he is behaving inappropriately toward his virgin, if she is past the flower of her age, and if need so requires, let him do what he desires. He doesn\u2019t sin. Let them marry.",
                "But he who stands steadfast in his heart, having no necessity, but has power over his own heart, to keep his own virgin, does well.",
                "So then both he who gives his own virgin in marriage does well, and he who doesn\u2019t give her in marriage does better.",
                "A wife is bound by law for as long as her husband lives; but if the husband is dead, she is free to be married to whomever she desires, only in the Lord.",
                "But she is happier if she stays as she is, in my judgment, and I think that I also have God\u2019s Spirit."
            ],
            8: [
                "Now concerning things sacrificed to idols: We know that we all have knowledge. Knowledge puffs up, but love builds up.",
                "But if anyone thinks that he knows anything, he doesn\u2019t yet know as he ought to know.",
                "But if anyone loves God, the same is known by him.",
                "Therefore concerning the eating of things sacrificed to idols, we know that no idol is anything in the world, and that there is no other God but one.",
                "For though there are things that are called \"gods,\" whether in the heavens or on earth; as there are many \"gods\" and many \"lords;\"",
                "yet to us there is one God, the Father, of whom are all things, and we for him; and one Lord, Jesus Christ, through whom are all things, and we live through him.",
                "However, that knowledge isn\u2019t in all men. But some, with consciousness of the idol until now, eat as of a thing sacrificed to an idol, and their conscience, being weak, is defiled.",
                "But food will not commend us to God. For neither, if we don\u2019t eat, are we the worse; nor, if we eat, are we the better.",
                "But be careful that by no means does this liberty of yours become a stumbling block to the weak.",
                "For if a man sees you who have knowledge sitting in an idol\u2019s temple, won\u2019t his conscience, if he is weak, be emboldened to eat things sacrificed to idols?",
                "And through your knowledge, he who is weak perishes, the brother for whose sake Christ died.",
                "Thus, sinning against the brothers, and wounding their conscience when it is weak, you sin against Christ.",
                "Therefore, if food causes my brother to stumble, I will eat no meat forevermore, that I don\u2019t cause my brother to stumble."
            ],
            9: [
                "Am I not free? Am I not an apostle? Haven\u2019t I seen Jesus Christ, our Lord? Aren\u2019t you my work in the Lord?",
                "If to others I am not an apostle, yet at least I am to you; for you are the seal of my apostleship in the Lord.",
                "My defense to those who examine me is this.",
                "Have we no right to eat and to drink?",
                "Have we no right to take along a wife who is a believer, even as the rest of the apostles, and the brothers of the Lord, and Cephas?",
                "Or have only Barnabas and I no right to not work?",
                "What soldier ever serves at his own expense? Who plants a vineyard, and doesn\u2019t eat of its fruit? Or who feeds a flock, and doesn\u2019t drink from the flock\u2019s milk?",
                "Do I speak these things according to the ways of men? Or doesn\u2019t the law also say the same thing?",
                "For it is written in the law of Moses, \"You shall not muzzle an ox while it treads out the grain.\" Is it for the oxen that God cares,",
                "or does he say it assuredly for our sake? Yes, it was written for our sake, because he who plows ought to plow in hope, and he who threshes in hope should partake of his hope.",
                "If we sowed to you spiritual things, is it a great thing if we reap your fleshly things?",
                "If others partake of this right over you, don\u2019t we yet more? Nevertheless we did not use this right, but we bear all things, that we may cause no hindrance to the Good News of Christ.",
                "Don\u2019t you know that those who serve around sacred things eat from the things of the temple, and those who wait on the altar have their portion with the altar?",
                "Even so the Lord ordained that those who proclaim the Good News should live from the Good News.",
                "But I have used none of these things, and I don\u2019t write these things that it may be done so in my case; for I would rather die, than that anyone should make my boasting void.",
                "For if I preach the Good News, I have nothing to boast about; for necessity is laid on me; but woe is to me, if I don\u2019t preach the Good News.",
                "For if I do this of my own will, I have a reward. But if not of my own will, I have a stewardship entrusted to me.",
                "What then is my reward? That, when I preach the Good News, I may present the Good News of Christ without charge, so as not to abuse my authority in the Good News.",
                "For though I was free from all, I brought myself under bondage to all, that I might gain the more.",
                "To the Jews I became as a Jew, that I might gain Jews; to those who are under the law, as under the law, that I might gain those who are under the law;",
                "to those who are without law, as without law (not being without law toward God, but under law toward Christ), that I might win those who are without law.",
                "To the weak I became as weak, that I might gain the weak. I have become all things to all men, that I may by all means save some.",
                "Now I do this for the sake of the Good News, that I may be a joint partaker of it.",
                "Don\u2019t you know that those who run in a race all run, but one receives the prize? Run like that, that you may win.",
                "Every man who strives in the games exercises self-control in all things. Now they do it to receive a corruptible crown, but we an incorruptible.",
                "I therefore run like that, as not uncertainly. I fight like that, as not beating the air,",
                "but I beat my body and bring it into submission, lest by any means, after I have preached to others, I myself should be rejected."
            ],
            10: [
                "Now I would not have you ignorant, brothers, that our fathers were all under the cloud, and all passed through the sea;",
                "and were all baptized into Moses in the cloud and in the sea;",
                "and all ate the same spiritual food;",
                "and all drank the same spiritual drink. For they drank of a spiritual rock that followed them, and the rock was Christ.",
                "However with most of them, God was not well pleased, for they were overthrown in the wilderness.",
                "Now these things were our examples, to the intent we should not lust after evil things, as they also lusted.",
                "Neither be idolaters, as some of them were. As it is written, \"The people sat down to eat and drink, and rose up to play.\"",
                "Neither let us commit sexual immorality, as some of them committed, and in one day twenty-three thousand fell.",
                "Neither let us test the Lord, as some of them tested, and perished by the serpents.",
                "Neither grumble, as some of them also grumbled, and perished by the destroyer.",
                "Now all these things happened to them by way of example, and they were written for our admonition, on whom the ends of the ages have come.",
                "Therefore let him who thinks he stands be careful that he doesn\u2019t fall.",
                "No temptation has taken you except what is common to man. God is faithful, who will not allow you to be tempted above what you are able, but will with the temptation also make the way of escape, that you may be able to endure it.",
                "Therefore, my beloved, flee from idolatry.",
                "I speak as to wise men. Judge what I say.",
                "The cup of blessing which we bless, isn\u2019t it a sharing of the blood of Christ? The bread which we break, isn\u2019t it a sharing of the body of Christ?",
                "Because there is one loaf of bread, we, who are many, are one body; for we all partake of the one loaf of bread.",
                "Consider Israel according to the flesh. Don\u2019t those who eat the sacrifices participate in the altar?",
                "What am I saying then? That a thing sacrificed to idols is anything, or that an idol is anything?",
                "But I say that the things which the Gentiles sacrifice, they sacrifice to demons, and not to God, and I don\u2019t desire that you would have fellowship with demons.",
                "You can\u2019t both drink the cup of the Lord and the cup of demons. You can\u2019t both partake of the table of the Lord, and of the table of demons.",
                "Or do we provoke the Lord to jealousy? Are we stronger than he?",
                "\"All things are lawful for me,\" but not all things are profitable. \"All things are lawful for me,\" but not all things build up.",
                "Let no one seek his own, but each one his neighbor\u2019s good.",
                "Whatever is sold in the butcher shop, eat, asking no question for the sake of conscience,",
                "for \"the earth is the Lord\u2019s, and its fullness.\"",
                "But if one of those who don\u2019t believe invites you to a meal, and you are inclined to go, eat whatever is set before you, asking no questions for the sake of conscience.",
                "But if anyone says to you, \"This was offered to idols,\" don\u2019t eat it for the sake of the one who told you, and for the sake of conscience. For \"the earth is the Lord\u2019s, and all its fullness.\"",
                "Conscience, I say, not your own, but the other\u2019s conscience. For why is my liberty judged by another conscience?",
                "If I partake with thankfulness, why am I denounced for that for which I give thanks?",
                "Whether therefore you eat, or drink, or whatever you do, do all to the glory of God.",
                "Give no occasions for stumbling, either to Jews, or to Greeks, or to the assembly of God;",
                "even as I also please all men in all things, not seeking my own profit, but the profit of the many, that they may be saved."
            ],
            11: [
                "Be imitators of me, even as I also am of Christ.",
                "Now I praise you, brothers, that you remember me in all things, and hold firm the traditions, even as I delivered them to you.",
                "But I would have you know that the head of every man is Christ, and the head of the woman is the man, and the head of Christ is God.",
                "Every man praying or prophesying, having his head covered, dishonors his head.",
                "But every woman praying or prophesying with her head unveiled dishonors her head. For it is one and the same thing as if she were shaved.",
                "For if a woman is not covered, let her also be shorn. But if it is shameful for a woman to be shorn or shaved, let her be covered.",
                "For a man indeed ought not to have his head covered, because he is the image and glory of God, but the woman is the glory of the man.",
                "For man is not from woman, but woman from man;",
                "for neither was man created for the woman, but woman for the man.",
                "For this cause the woman ought to have authority on her head, because of the angels.",
                "Nevertheless, neither is the woman independent of the man, nor the man independent of the woman, in the Lord.",
                "For as woman came from man, so a man also comes through a woman; but all things are from God.",
                "Judge for yourselves. Is it appropriate that a woman pray to God unveiled?",
                "Doesn\u2019t even nature itself teach you that if a man has long hair, it is a dishonor to him?",
                "But if a woman has long hair, it is a glory to her, for her hair is given to her for a covering.",
                "But if any man seems to be contentious, we have no such custom, neither do God\u2019s assemblies.",
                "But in giving you this command, I don\u2019t praise you, that you come together not for the better but for the worse.",
                "For first of all, when you come together in the assembly, I hear that divisions exist among you, and I partly believe it.",
                "For there also must be factions among you, that those who are approved may be revealed among you.",
                "When therefore you assemble yourselves together, it is not the Lord\u2019s supper that you eat.",
                "For in your eating each one takes his own supper first. One is hungry, and another is drunken.",
                "What, don\u2019t you have houses to eat and to drink in? Or do you despise God\u2019s assembly, and put them to shame who don\u2019t have? What shall I tell you? Shall I praise you? In this I don\u2019t praise you.",
                "For I received from the Lord that which also I delivered to you, that the Lord Jesus on the night in which he was betrayed took bread.",
                "When he had given thanks, he broke it, and said, \"Take, eat. This is my body, which is broken for you. Do this in memory of me.\"",
                "In the same way he also took the cup, after supper, saying, \"This cup is the new covenant in my blood. Do this, as often as you drink, in memory of me.\"",
                "For as often as you eat this bread and drink this cup, you proclaim the Lord\u2019s death until he comes.",
                "Therefore whoever eats this bread or drinks the Lord\u2019s cup in a way unworthy of the Lord will be guilty of the body and the blood of the Lord.",
                "But let a man examine himself, and so let him eat of the bread, and drink of the cup.",
                "For he who eats and drinks in an unworthy way eats and drinks judgment to himself, if he doesn\u2019t discern the Lord\u2019s body.",
                "For this cause many among you are weak and sickly, and not a few sleep.",
                "For if we discerned ourselves, we wouldn\u2019t be judged.",
                "But when we are judged, we are punished by the Lord, that we may not be condemned with the world.",
                "Therefore, my brothers, when you come together to eat, wait for one another.",
                "But if anyone is hungry, let him eat at home, lest your coming together be for judgment. The rest I will set in order whenever I come."
            ],
            12: [
                "Now concerning spiritual things, brothers, I don\u2019t want you to be ignorant.",
                "You know that when you were heathen, you were led away to those mute idols, however you might be led.",
                "Therefore I make known to you that no man speaking by God\u2019s Spirit says, \"Jesus is accursed.\" No one can say, \"Jesus is Lord,\" but by the Holy Spirit.",
                "Now there are various kinds of gifts, but the same Spirit.",
                "There are various kinds of service, and the same Lord.",
                "There are various kinds of workings, but the same God, who works all things in all.",
                "But to each one is given the manifestation of the Spirit for the profit of all.",
                "For to one is given through the Spirit the word of wisdom, and to another the word of knowledge, according to the same Spirit;",
                "to another faith, by the same Spirit; and to another gifts of healings, by the same Spirit;",
                "and to another workings of miracles; and to another prophecy; and to another discerning of spirits; to another different kinds of languages; and to another the interpretation of languages.",
                "But the one and the same Spirit works all of these, distributing to each one separately as he desires.",
                "For as the body is one, and has many members, and all the members of the body, being many, are one body; so also is Christ.",
                "For in one Spirit we were all baptized into one body, whether Jews or Greeks, whether bond or free; and were all given to drink into one Spirit.",
                "For the body is not one member, but many.",
                "If the foot would say, \"Because I\u2019m not the hand, I\u2019m not part of the body,\" it is not therefore not part of the body.",
                "If the ear would say, \"Because I\u2019m not the eye, I\u2019m not part of the body,\" it\u2019s not therefore not part of the body.",
                "If the whole body were an eye, where would the hearing be? If the whole were hearing, where would the smelling be?",
                "But now God has set the members, each one of them, in the body, just as he desired.",
                "If they were all one member, where would the body be?",
                "But now they are many members, but one body.",
                "The eye can\u2019t tell the hand, \"I have no need for you,\" or again the head to the feet, \"I have no need for you.\"",
                "No, much rather, those members of the body which seem to be weaker are necessary.",
                "Those parts of the body which we think to be less honorable, on those we bestow more abundant honor; and our unpresentable parts have more abundant propriety;",
                "whereas our presentable parts have no such need. But God composed the body together, giving more abundant honor to the inferior part,",
                "that there should be no division in the body, but that the members should have the same care for one another.",
                "When one member suffers, all the members suffer with it. Or when one member is honored, all the members rejoice with it.",
                "Now you are the body of Christ, and members individually.",
                "God has set some in the assembly: first apostles, second prophets, third teachers, then miracle workers, then gifts of healings, helps, governments, and various kinds of languages.",
                "Are all apostles? Are all prophets? Are all teachers? Are all miracle workers?",
                "Do all have gifts of healings? Do all speak with various languages? Do all interpret?",
                "But earnestly desire the best gifts. Moreover, I show a most excellent way to you."
            ],
            13: [
                "If I speak with the languages of men and of angels, but don\u2019t have love, I have become sounding brass, or a clanging cymbal.",
                "If I have the gift of prophecy, and know all mysteries and all knowledge; and if I have all faith, so as to remove mountains, but don\u2019t have love, I am nothing.",
                "If I dole out all my goods to feed the poor, and if I give my body to be burned, but don\u2019t have love, it profits me nothing.",
                "Love is patient and is kind; love doesn\u2019t envy. Love doesn\u2019t brag, is not proud,",
                "doesn\u2019t behave itself inappropriately, doesn\u2019t seek its own way, is not provoked, takes no account of evil;",
                "doesn\u2019t rejoice in unrighteousness, but rejoices with the truth;",
                "bears all things, believes all things, hopes all things, endures all things.",
                "Love never fails. But where there are prophecies, they will be done away with. Where there are various languages, they will cease. Where there is knowledge, it will be done away with.",
                "For we know in part, and we prophesy in part;",
                "but when that which is complete has come, then that which is partial will be done away with.",
                "When I was a child, I spoke as a child, I felt as a child, I thought as a child. Now that I have become a man, I have put away childish things.",
                "For now we see in a mirror, dimly, but then face to face. Now I know in part, but then I will know fully, even as I was also fully known.",
                "But now faith, hope, and love remain\u2014these three. The greatest of these is love."
            ],
            14: [
                "Follow after love, and earnestly desire spiritual gifts, but especially that you may prophesy.",
                "For he who speaks in another language speaks not to men, but to God; for no one understands; but in the Spirit he speaks mysteries.",
                "But he who prophesies speaks to men for their edification, exhortation, and consolation.",
                "He who speaks in another language edifies himself, but he who prophesies edifies the assembly.",
                "Now I desire to have you all speak with other languages, but rather that you would prophesy. For he is greater who prophesies than he who speaks with other languages, unless he interprets, that the assembly may be built up.",
                "But now, brothers, if I come to you speaking with other languages, what would I profit you, unless I speak to you either by way of revelation, or of knowledge, or of prophesying, or of teaching?",
                "Even things without life, giving a voice, whether pipe or harp, if they didn\u2019t give a distinction in the sounds, how would it be known what is piped or harped?",
                "For if the trumpet gave an uncertain sound, who would prepare himself for war?",
                "So also you, unless you uttered by the tongue words easy to understand, how would it be known what is spoken? For you would be speaking into the air.",
                "There are, it may be, so many kinds of sounds in the world, and none of them is without meaning.",
                "If then I don\u2019t know the meaning of the sound, I would be to him who speaks a foreigner, and he who speaks would be a foreigner to me.",
                "So also you, since you are zealous for spiritual gifts, seek that you may abound to the building up of the assembly.",
                "Therefore let him who speaks in another language pray that he may interpret.",
                "For if I pray in another language, my spirit prays, but my understanding is unfruitful.",
                "What is it then? I will pray with the spirit, and I will pray with the understanding also. I will sing with the spirit, and I will sing with the understanding also.",
                "Otherwise if you bless with the spirit, how will he who fills the place of the unlearned say the \"Amen\" at your giving of thanks, seeing he doesn\u2019t know what you say?",
                "For you most certainly give thanks well, but the other person is not built up.",
                "I thank my God, I speak with other languages more than you all.",
                "However in the assembly I would rather speak five words with my understanding, that I might instruct others also, than ten thousand words in another language.",
                "Brothers, don\u2019t be children in thoughts, yet in malice be babies, but in thoughts be mature.",
                "In the law it is written, \"By men of strange languages and by the lips of strangers I will speak to this people. Not even thus will they hear me, says the Lord.\"",
                "Therefore other languages are for a sign, not to those who believe, but to the unbelieving; but prophesying is for a sign, not to the unbelieving, but to those who believe.",
                "If therefore the whole assembly is assembled together and all speak with other languages, and unlearned or unbelieving people come in, won\u2019t they say that you are crazy?",
                "But if all prophesy, and someone unbelieving or unlearned comes in, he is reproved by all, and he is judged by all.",
                "And thus the secrets of his heart are revealed. So he will fall down on his face and worship God, declaring that God is among you indeed.",
                "What is it then, brothers? When you come together, each one of you has a psalm, has a teaching, has a revelation, has another language, has an interpretation. Let all things be done to build each other up.",
                "If any man speaks in another language, let it be two, or at the most three, and in turn; and let one interpret.",
                "But if there is no interpreter, let him keep silent in the assembly, and let him speak to himself, and to God.",
                "Let the prophets speak, two or three, and let the others discern.",
                "But if a revelation is made to another sitting by, let the first keep silent.",
                "For you all can prophesy one by one, that all may learn, and all may be exhorted.",
                "The spirits of the prophets are subject to the prophets,",
                "for God is not a God of confusion, but of peace. As in all the assemblies of the saints,",
                "let your wives keep silent in the assemblies, for it has not been permitted for them to speak; but let them be in subjection, as the law also says.",
                "If they desire to learn anything, let them ask their own husbands at home, for it is shameful for a woman to chatter in the assembly.",
                "What? Was it from you that the word of God went out? Or did it come to you alone?",
                "If any man thinks himself to be a prophet, or spiritual, let him recognize the things which I write to you, that they are the commandment of the Lord.",
                "But if anyone is ignorant, let him be ignorant.",
                "Therefore, brothers, desire earnestly to prophesy, and don\u2019t forbid speaking with other languages.",
                "Let all things be done decently and in order."
            ],
            15: [
                "Now I declare to you, brothers, the Good News which I preached to you, which also you received, in which you also stand,",
                "by which also you are saved, if you hold firmly the word which I preached to you\u2014unless you believed in vain.",
                "For I delivered to you first of all that which I also received: that Christ died for our sins according to the Scriptures,",
                "that he was buried, that he was raised on the third day according to the Scriptures,",
                "and that he appeared to Cephas, then to the twelve.",
                "Then he appeared to over five hundred brothers at once, most of whom remain until now, but some have also fallen asleep.",
                "Then he appeared to James, then to all the apostles,",
                "and last of all, as to the child born at the wrong time, he appeared to me also.",
                "For I am the least of the apostles, who is not worthy to be called an apostle, because I persecuted the assembly of God.",
                "But by the grace of God I am what I am. His grace which was bestowed on me was not futile, but I worked more than all of them; yet not I, but the grace of God which was with me.",
                "Whether then it is I or they, so we preach, and so you believed.",
                "Now if Christ is preached, that he has been raised from the dead, how do some among you say that there is no resurrection of the dead?",
                "But if there is no resurrection of the dead, neither has Christ been raised.",
                "If Christ has not been raised, then our preaching is in vain, and your faith also is in vain.",
                "Yes, we are found false witnesses of God, because we testified about God that he raised up Christ, whom he didn\u2019t raise up, if it is so that the dead are not raised.",
                "For if the dead aren\u2019t raised, neither has Christ been raised.",
                "If Christ has not been raised, your faith is vain; you are still in your sins.",
                "Then they also who are fallen asleep in Christ have perished.",
                "If we have only hoped in Christ in this life, we are of all men most pitiable.",
                "But now Christ has been raised from the dead. He became the first fruits of those who are asleep.",
                "For since death came by man, the resurrection of the dead also came by man.",
                "For as in Adam all die, so also in Christ all will be made alive.",
                "But each in his own order: Christ the first fruits, then those who are Christ\u2019s, at his coming.",
                "Then the end comes, when he will deliver up the Kingdom to God, even the Father; when he will have abolished all rule and all authority and power.",
                "For he must reign until he has put all his enemies under his feet.",
                "The last enemy that will be abolished is death.",
                "For, \"He put all things in subjection under his feet.\" But when he says, \"All things are put in subjection,\" it is evident that he is excepted who subjected all things to him.",
                "When all things have been subjected to him, then the Son will also himself be subjected to him who subjected all things to him, that God may be all in all.",
                "Or else what will they do who are baptized for the dead? If the dead aren\u2019t raised at all, why then are they baptized for the dead?",
                "Why do we also stand in jeopardy every hour?",
                "I affirm, by the boasting in you which I have in Christ Jesus our Lord, I die daily.",
                "If I fought with animals at Ephesus for human purposes, what does it profit me? If the dead are not raised, then \"let us eat and drink, for tomorrow we die.\"",
                "Don\u2019t be deceived! \"Evil companionships corrupt good morals.\"",
                "Wake up righteously, and don\u2019t sin, for some have no knowledge of God. I say this to your shame.",
                "But someone will say, \"How are the dead raised?\" and, \"With what kind of body do they come?\"",
                "You foolish one, that which you yourself sow is not made alive unless it dies.",
                "That which you sow, you don\u2019t sow the body that will be, but a bare grain, maybe of wheat, or of some other kind.",
                "But God gives it a body even as it pleased him, and to each seed a body of its own.",
                "All flesh is not the same flesh, but there is one flesh of men, another flesh of animals, another of fish, and another of birds.",
                "There are also celestial bodies, and terrestrial bodies; but the glory of the celestial differs from that of the terrestrial.",
                "There is one glory of the sun, another glory of the moon, and another glory of the stars; for one star differs from another star in glory.",
                "So also is the resurrection of the dead. It is sown in corruption; it is raised in incorruption.",
                "It is sown in dishonor; it is raised in glory. It is sown in weakness; it is raised in power.",
                "It is sown a natural body; it is raised a spiritual body. There is a natural body and there is also a spiritual body.",
                "So also it is written, \"The first man, Adam, became a living soul.\" The last Adam became a life-giving spirit.",
                "However that which is spiritual isn\u2019t first, but that which is natural, then that which is spiritual.",
                "The first man is of the earth, made of dust. The second man is the Lord from heaven.",
                "As is the one made of dust, such are those who are also made of dust; and as is the heavenly, such are they also that are heavenly.",
                "As we have borne the image of those made of dust, let\u2019s also bear the image of the heavenly.",
                "Now I say this, brothers, that flesh and blood can\u2019t inherit the Kingdom of God; neither does corruption inherit incorruption.",
                "Behold, I tell you a mystery. We will not all sleep, but we will all be changed,",
                "in a moment, in the twinkling of an eye, at the last trumpet. For the trumpet will sound, and the dead will be raised incorruptible, and we will be changed.",
                "For this corruptible must put on incorruption, and this mortal must put on immortality.",
                "But when this corruptible will have put on incorruption, and this mortal will have put on immortality, then what is written will happen: \"Death is swallowed up in victory.\"",
                "\"Death, where is your sting? Hades, where is your victory?\"",
                "The sting of death is sin, and the power of sin is the law.",
                "But thanks be to God, who gives us the victory through our Lord Jesus Christ.",
                "Therefore, my beloved brothers, be steadfast, immovable, always abounding in the Lord\u2019s work, because you know that your labor is not in vain in the Lord."
            ],
            16: [
                "Now concerning the collection for the saints, as I commanded the assemblies of Galatia, you do likewise.",
                "On the first day of the week, let each one of you save, as he may prosper, that no collections be made when I come.",
                "When I arrive, I will send whoever you approve with letters to carry your gracious gift to Jerusalem.",
                "If it is appropriate for me to go also, they will go with me.",
                "But I will come to you when I have passed through Macedonia, for I am passing through Macedonia.",
                "But with you it may be that I will stay, or even winter, that you may send me on my journey wherever I go.",
                "For I do not wish to see you now in passing, but I hope to stay a while with you, if the Lord permits.",
                "But I will stay at Ephesus until Pentecost,",
                "for a great and effective door has opened to me, and there are many adversaries.",
                "Now if Timothy comes, see that he is with you without fear, for he does the work of the Lord, as I also do.",
                "Therefore let no one despise him. But set him forward on his journey in peace, that he may come to me; for I expect him with the brothers.",
                "Now concerning Apollos, the brother, I strongly urged him to come to you with the brothers; and it was not at all his desire to come now; but he will come when he has an opportunity.",
                "Watch! Stand firm in the faith! Be courageous! Be strong!",
                "Let all that you do be done in love.",
                "Now I beg you, brothers (you know the house of Stephanas, that it is the first fruits of Achaia, and that they have set themselves to serve the saints),",
                "that you also be in subjection to such, and to everyone who helps in the work and labors.",
                "I rejoice at the coming of Stephanas, Fortunatus, and Achaicus; for that which was lacking on your part, they supplied.",
                "For they refreshed my spirit and yours. Therefore acknowledge those who are like that.",
                "The assemblies of Asia greet you. Aquila and Priscilla greet you much in the Lord, together with the assembly that is in their house.",
                "All the brothers greet you. Greet one another with a holy kiss.",
                "This greeting is by me, Paul, with my own hand.",
                "If any man doesn\u2019t love the Lord Jesus Christ, let him be accursed. Come, Lord!",
                "The grace of the Lord Jesus Christ be with you.",
                "My love to all of you in Christ Jesus. Amen."
            ]
        },
        47: {
            1: [
                "Paul, an apostle of Christ Jesus through the will of God, and Timothy our brother, to the assembly of God which is at Corinth, with all the saints who are in the whole of Achaia:",
                "Grace to you and peace from God our Father and the Lord Jesus Christ.",
                "Blessed be the God and Father of our Lord Jesus Christ, the Father of mercies and God of all comfort;",
                "who comforts us in all our affliction, that we may be able to comfort those who are in any affliction, through the comfort with which we ourselves are comforted by God.",
                "For as the sufferings of Christ abound to us, even so our comfort also abounds through Christ.",
                "But if we are afflicted, it is for your comfort and salvation. If we are comforted, it is for your comfort, which produces in you the patient enduring of the same sufferings which we also suffer.",
                "Our hope for you is steadfast, knowing that, since you are partakers of the sufferings, so also are you of the comfort.",
                "For we don\u2019t desire to have you uninformed, brothers, concerning our affliction which happened to us in Asia, that we were weighed down exceedingly, beyond our power, so much that we despaired even of life.",
                "Yes, we ourselves have had the sentence of death within ourselves, that we should not trust in ourselves, but in God who raises the dead,",
                "who delivered us out of so great a death, and does deliver; on whom we have set our hope that he will also still deliver us;",
                "you also helping together on our behalf by your supplication; that, for the gift bestowed on us by means of many, thanks may be given by many persons on your behalf.",
                "For our boasting is this: the testimony of our conscience, that in holiness and sincerity of God, not in fleshly wisdom but in the grace of God we behaved ourselves in the world, and more abundantly toward you.",
                "For we write no other things to you, than what you read or even acknowledge, and I hope you will acknowledge to the end;",
                "as also you acknowledged us in part, that we are your boasting, even as you also are ours, in the day of our Lord Jesus.",
                "In this confidence, I was determined to come first to you, that you might have a second benefit;",
                "and by you to pass into Macedonia, and again from Macedonia to come to you, and to be sent forward by you on my journey to Judea.",
                "When I therefore was thus determined, did I show fickleness? Or the things that I purpose, do I purpose according to the flesh, that with me there should be the \"Yes, yes\" and the \"No, no?\"",
                "But as God is faithful, our word toward you was not \"Yes and no.\"",
                "For the Son of God, Jesus Christ, who was preached among you by us, by me, Silvanus, and Timothy, was not \"Yes and no,\" but in him is \"Yes.\"",
                "For however many are the promises of God, in him is the \"Yes.\" Therefore also through him is the \"Amen,\" to the glory of God through us.",
                "Now he who establishes us with you in Christ, and anointed us, is God;",
                "who also sealed us, and gave us the down payment of the Spirit in our hearts.",
                "But I call God for a witness to my soul, that I didn\u2019t come to Corinth to spare you.",
                "Not that we have lordship over your faith, but are fellow workers with you for your joy. For you stand firm in faith."
            ],
            2: [
                "But I determined this for myself, that I would not come to you again in sorrow.",
                "For if I make you sorry, then who will make me glad but he who is made sorry by me?",
                "And I wrote this very thing to you, so that, when I came, I wouldn\u2019t have sorrow from them of whom I ought to rejoice; having confidence in you all, that my joy would be shared by all of you.",
                "For out of much affliction and anguish of heart I wrote to you with many tears, not that you should be made sorry, but that you might know the love that I have so abundantly for you.",
                "But if any has caused sorrow, he has caused sorrow, not to me, but in part (that I not press too heavily) to you all.",
                "Sufficient to such a one is this punishment which was inflicted by the many;",
                "so that on the contrary you should rather forgive him and comfort him, lest by any means such a one should be swallowed up with his excessive sorrow.",
                "Therefore I beg you to confirm your love toward him.",
                "For to this end I also wrote, that I might know the proof of you, whether you are obedient in all things.",
                "Now I also forgive whomever you forgive anything. For if indeed I have forgiven anything, I have forgiven that one for your sakes in the presence of Christ,",
                "that no advantage may be gained over us by Satan; for we are not ignorant of his schemes.",
                "Now when I came to Troas for the Good News of Christ, and when a door was opened to me in the Lord,",
                "I had no relief for my spirit, because I didn\u2019t find Titus, my brother, but taking my leave of them, I went out into Macedonia.",
                "Now thanks be to God, who always leads us in triumph in Christ, and reveals through us the sweet aroma of his knowledge in every place.",
                "For we are a sweet aroma of Christ to God, in those who are saved, and in those who perish;",
                "to the one a stench from death to death; to the other a sweet aroma from life to life. Who is sufficient for these things?",
                "For we are not as so many, peddling the word of God. But as of sincerity, but as of God, in the sight of God, we speak in Christ."
            ],
            3: [
                "Are we beginning again to commend ourselves? Or do we need, as do some, letters of commendation to you or from you?",
                "You are our letter, written in our hearts, known and read by all men;",
                "being revealed that you are a letter of Christ, served by us, written not with ink, but with the Spirit of the living God; not in tablets of stone, but in tablets that are hearts of flesh.",
                "Such confidence we have through Christ toward God;",
                "not that we are sufficient of ourselves, to account anything as from ourselves; but our sufficiency is from God;",
                "who also made us sufficient as servants of a new covenant; not of the letter, but of the Spirit. For the letter kills, but the Spirit gives life.",
                "But if the service of death, written engraved on stones, came with glory, so that the children of Israel could not look steadfastly on the face of Moses for the glory of his face; which was passing away:",
                "won\u2019t service of the Spirit be with much more glory?",
                "For if the service of condemnation has glory, the service of righteousness exceeds much more in glory.",
                "For most certainly that which has been made glorious has not been made glorious in this respect, by reason of the glory that surpasses.",
                "For if that which passes away was with glory, much more that which remains is in glory.",
                "Having therefore such a hope, we use great boldness of speech,",
                "and not as Moses, who put a veil on his face, that the children of Israel wouldn\u2019t look steadfastly on the end of that which was passing away.",
                "But their minds were hardened, for until this very day at the reading of the old covenant the same veil remains, because in Christ it passes away.",
                "But to this day, when Moses is read, a veil lies on their heart.",
                "But whenever one turns to the Lord, the veil is taken away.",
                "Now the Lord is the Spirit and where the Spirit of the Lord is, there is liberty.",
                "But we all, with unveiled face beholding as in a mirror the glory of the Lord, are transformed into the same image from glory to glory, even as from the Lord, the Spirit."
            ],
            4: [
                "Therefore seeing we have this ministry, even as we obtained mercy, we don\u2019t faint.",
                "But we have renounced the hidden things of shame, not walking in craftiness, nor handling the word of God deceitfully; but by the manifestation of the truth commending ourselves to every man\u2019s conscience in the sight of God.",
                "Even if our Good News is veiled, it is veiled in those who perish;",
                "in whom the god of this world has blinded the minds of the unbelieving, that the light of the Good News of the glory of Christ, who is the image of God, should not dawn on them.",
                "For we don\u2019t preach ourselves, but Christ Jesus as Lord, and ourselves as your servants for Jesus\u2019 sake;",
                "seeing it is God who said, \"Light will shine out of darkness,\" who has shone in our hearts, to give the light of the knowledge of the glory of God in the face of Jesus Christ.",
                "But we have this treasure in clay vessels, that the exceeding greatness of the power may be of God, and not from ourselves.",
                "We are pressed on every side, yet not crushed; perplexed, yet not to despair;",
                "pursued, yet not forsaken; struck down, yet not destroyed;",
                "always carrying in the body the putting to death of the Lord Jesus, that the life of Jesus may also be revealed in our body.",
                "For we who live are always delivered to death for Jesus\u2019 sake, that the life also of Jesus may be revealed in our mortal flesh.",
                "So then death works in us, but life in you.",
                "But having the same spirit of faith, according to that which is written, \"I believed, and therefore I spoke.\" We also believe, and therefore also we speak;",
                "knowing that he who raised the Lord Jesus will raise us also with Jesus, and will present us with you.",
                "For all things are for your sakes, that the grace, being multiplied through the many, may cause the thanksgiving to abound to the glory of God.",
                "Therefore we don\u2019t faint, but though our outward man is decaying, yet our inward man is renewed day by day.",
                "For our light affliction, which is for the moment, works for us more and more exceedingly an eternal weight of glory;",
                "while we don\u2019t look at the things which are seen, but at the things which are not seen. For the things which are seen are temporal, but the things which are not seen are eternal."
            ],
            5: [
                "For we know that if the earthly house of our tent is dissolved, we have a building from God, a house not made with hands, eternal, in the heavens.",
                "For most certainly in this we groan, longing to be clothed with our habitation which is from heaven;",
                "if so be that being clothed we will not be found naked.",
                "For indeed we who are in this tent do groan, being burdened; not that we desire to be unclothed, but that we desire to be clothed, that what is mortal may be swallowed up by life.",
                "Now he who made us for this very thing is God, who also gave to us the down payment of the Spirit.",
                "Therefore, we are always confident and know that while we are at home in the body, we are absent from the Lord;",
                "for we walk by faith, not by sight.",
                "We are courageous, I say, and are willing rather to be absent from the body, and to be at home with the Lord.",
                "Therefore also we make it our aim, whether at home or absent, to be well pleasing to him.",
                "For we must all be revealed before the judgment seat of Christ; that each one may receive the things in the body, according to what he has done, whether good or bad.",
                "Knowing therefore the fear of the Lord, we persuade men, but we are revealed to God; and I hope that we are revealed also in your consciences.",
                "For we are not commending ourselves to you again, but speak as giving you occasion of boasting on our behalf, that you may have something to answer those who boast in appearance, and not in heart.",
                "For if we are beside ourselves, it is for God. Or if we are of sober mind, it is for you.",
                "For the love of Christ constrains us; because we judge thus, that one died for all, therefore all died.",
                "He died for all, that those who live should no longer live to themselves, but to him who for their sakes died and rose again.",
                "Therefore we know no one after the flesh from now on. Even though we have known Christ after the flesh, yet now we know him so no more.",
                "Therefore if anyone is in Christ, he is a new creation. The old things have passed away. Behold, all things have become new.",
                "But all things are of God, who reconciled us to himself through Jesus Christ, and gave to us the ministry of reconciliation;",
                "namely, that God was in Christ reconciling the world to himself, not reckoning to them their trespasses, and having committed to us the word of reconciliation.",
                "We are therefore ambassadors on behalf of Christ, as though God were entreating by us: we beg you on behalf of Christ, be reconciled to God.",
                "For him who knew no sin he made to be sin on our behalf; so that in him we might become the righteousness of God."
            ],
            6: [
                "Working together, we entreat also that you not receive the grace of God in vain,",
                "for he says, \"At an acceptable time I listened to you, in a day of salvation I helped you.\" Behold, now is the acceptable time. Behold, now is the day of salvation.",
                "We give no occasion of stumbling in anything, that our service may not be blamed,",
                "but in everything commending ourselves, as servants of God, in great endurance, in afflictions, in hardships, in distresses,",
                "in beatings, in imprisonments, in riots, in labors, in watchings, in fastings;",
                "in pureness, in knowledge, in patience, in kindness, in the Holy Spirit, in sincere love,",
                "in the word of truth, in the power of God; by the armor of righteousness on the right hand and on the left,",
                "by glory and dishonor, by evil report and good report; as deceivers, and yet true;",
                "as unknown, and yet well known; as dying, and behold, we live; as punished, and not killed;",
                "as sorrowful, yet always rejoicing; as poor, yet making many rich; as having nothing, and yet possessing all things.",
                "Our mouth is open to you, Corinthians. Our heart is enlarged.",
                "You are not restricted by us, but you are restricted by your own affections.",
                "Now in return, I speak as to my children, you also be open wide.",
                "Don\u2019t be unequally yoked with unbelievers, for what fellowship have righteousness and iniquity? Or what fellowship has light with darkness?",
                "What agreement has Christ with Belial? Or what portion has a believer with an unbeliever?",
                "What agreement has a temple of God with idols? For you are a temple of the living God. Even as God said, \"I will dwell in them, and walk in them; and I will be their God, and they will be my people.\"",
                "Therefore, \"\u2018Come out from among them, and be separate,\u2019 says the Lord. \u2018Touch no unclean thing. I will receive you.",
                "I will be to you a Father. You will be to me sons and daughters,\u2019 says the Lord Almighty.\""
            ],
            7: [
                "Having therefore these promises, beloved, let us cleanse ourselves from all defilement of flesh and spirit, perfecting holiness in the fear of God.",
                "Open your hearts to us. We wronged no one. We corrupted no one. We took advantage of no one.",
                "I say this not to condemn you, for I have said before, that you are in our hearts to die together and live together.",
                "Great is my boldness of speech toward you. Great is my boasting on your behalf. I am filled with comfort. I overflow with joy in all our affliction.",
                "For even when we had come into Macedonia, our flesh had no relief, but we were afflicted on every side. Fightings were outside. Fear was inside.",
                "Nevertheless, he who comforts the lowly, God, comforted us by the coming of Titus;",
                "and not by his coming only, but also by the comfort with which he was comforted in you, while he told us of your longing, your mourning, and your zeal for me; so that I rejoiced still more.",
                "For though I made you sorry with my letter, I do not regret it, though I did regret it. For I see that my letter made you sorry, though just for a while.",
                "I now rejoice, not that you were made sorry, but that you were made sorry to repentance. For you were made sorry in a godly way, that you might suffer loss by us in nothing.",
                "For godly sorrow works repentance to salvation, which brings no regret. But the sorrow of the world works death.",
                "For behold, this same thing, that you were made sorry in a godly way, what earnest care it worked in you. Yes, what defense, indignation, fear, longing, zeal, and vengeance! In everything you demonstrated yourselves to be pure in the matter.",
                "So although I wrote to you, I wrote not for his cause that did the wrong, nor for his cause that suffered the wrong, but that your earnest care for us might be revealed in you in the sight of God.",
                "Therefore we have been comforted. In our comfort we rejoiced the more exceedingly for the joy of Titus, because his spirit has been refreshed by you all.",
                "For if in anything I have boasted to him on your behalf, I was not disappointed. But as we spoke all things to you in truth, so our glorying also which I made before Titus was found to be truth.",
                "His affection is more abundantly toward you, while he remembers all of your obedience, how with fear and trembling you received him.",
                "I rejoice that in everything I am confident concerning you."
            ],
            8: [
                "Moreover, brothers, we make known to you the grace of God which has been given in the assemblies of Macedonia;",
                "how that in much proof of affliction the abundance of their joy and their deep poverty abounded to the riches of their liberality.",
                "For according to their power, I testify, yes and beyond their power, they gave of their own accord,",
                "begging us with much entreaty to receive this grace and the fellowship in the service to the saints.",
                "This was not as we had hoped, but first they gave their own selves to the Lord, and to us through the will of God.",
                "So we urged Titus, that as he made a beginning before, so he would also complete in you this grace.",
                "But as you abound in everything, in faith, utterance, knowledge, all earnestness, and in your love to us, see that you also abound in this grace.",
                "I speak not by way of commandment, but as proving through the earnestness of others the sincerity also of your love.",
                "For you know the grace of our Lord Jesus Christ, that, though he was rich, yet for your sakes he became poor, that you through his poverty might become rich.",
                "I give a judgment in this: for this is expedient for you, who were the first to start a year ago, not only to do, but also to be willing.",
                "But now complete the doing also, that as there was the readiness to be willing, so there may be the completion also out of your ability.",
                "For if the readiness is there, it is acceptable according to what you have, not according to what you don\u2019t have.",
                "For this is not that others may be eased and you distressed,",
                "but for equality. Your abundance at this present time supplies their lack, that their abundance also may become a supply for your lack; that there may be equality.",
                "As it is written, \"He who gathered much had nothing left over, and he who gathered little had no lack.\"",
                "But thanks be to God, who puts the same earnest care for you into the heart of Titus.",
                "For he indeed accepted our exhortation, but being himself very earnest, he went out to you of his own accord.",
                "We have sent together with him the brother whose praise in the Good News is known through all the assemblies.",
                "Not only so, but who was also appointed by the assemblies to travel with us in this grace, which is served by us to the glory of the Lord himself, and to show our readiness.",
                "We are avoiding this, that any man should blame us concerning this abundance which is administered by us.",
                "Having regard for honorable things, not only in the sight of the Lord, but also in the sight of men.",
                "We have sent with them our brother, whom we have many times proved earnest in many things, but now much more earnest, by reason of the great confidence which he has in you.",
                "As for Titus, he is my partner and fellow worker for you. As for our brothers, they are the apostles of the assemblies, the glory of Christ.",
                "Therefore show the proof of your love to them in front of the assemblies, and of our boasting on your behalf."
            ],
            9: [
                "It is indeed unnecessary for me to write to you concerning the service to the saints,",
                "for I know your readiness, of which I boast on your behalf to them of Macedonia, that Achaia has been prepared for a year past. Your zeal has stirred up very many of them.",
                "But I have sent the brothers that our boasting on your behalf may not be in vain in this respect, that, just as I said, you may be prepared,",
                "so that I won\u2019t by any means, if there come with me any of Macedonia and find you unprepared, we (to say nothing of you) should be disappointed in this confident boasting.",
                "I thought it necessary therefore to entreat the brothers that they would go before to you, and arrange ahead of time the generous gift that you promised before, that the same might be ready as a matter of generosity, and not of greediness.",
                "Remember this: he who sows sparingly will also reap sparingly. He who sows bountifully will also reap bountifully.",
                "Let each man give according as he has determined in his heart; not grudgingly, or under compulsion; for God loves a cheerful giver.",
                "And God is able to make all grace abound to you, that you, always having all sufficiency in everything, may abound to every good work.",
                "As it is written, \"He has scattered abroad, he has given to the poor. His righteousness remains forever.\"",
                "Now may he who supplies seed to the sower and bread for food, supply and multiply your seed for sowing, and increase the fruits of your righteousness;",
                "you being enriched in everything to all liberality, which works through us thanksgiving to God.",
                "For this service of giving that you perform not only makes up for lack among the saints, but abounds also through many givings of thanks to God;",
                "seeing that through the proof given by this service, they glorify God for the obedience of your confession to the Good News of Christ, and for the liberality of your contribution to them and to all;",
                "while they themselves also, with supplication on your behalf, yearn for you by reason of the exceeding grace of God in you.",
                "Now thanks be to God for his unspeakable gift!"
            ],
            10: [
                "Now I Paul, myself, entreat you by the humility and gentleness of Christ; I who in your presence am lowly among you, but being absent am bold toward you.",
                "Yes, I beg you that I may not, when present, show courage with the confidence with which I intend to be bold against some, who consider us to be walking according to the flesh.",
                "For though we walk in the flesh, we don\u2019t wage war according to the flesh;",
                "for the weapons of our warfare are not of the flesh, but mighty before God to the throwing down of strongholds,",
                "throwing down imaginations and every high thing that is exalted against the knowledge of God, and bringing every thought into captivity to the obedience of Christ;",
                "and being in readiness to avenge all disobedience, when your obedience will be made full.",
                "Do you look at things only as they appear in front of your face? If anyone trusts in himself that he is Christ\u2019s, let him consider this again with himself, that, even as he is Christ\u2019s, so also we are Christ\u2019s.",
                "For though I should boast somewhat abundantly concerning our authority, (which the Lord gave for building you up, and not for casting you down) I will not be disappointed,",
                "that I may not seem as if I desire to terrify you by my letters.",
                "For, \"His letters,\" they say, \"are weighty and strong, but his bodily presence is weak, and his speech is despised.\"",
                "Let such a person consider this, that what we are in word by letters when we are absent, such are we also in deed when we are present.",
                "For we are not bold to number or compare ourselves with some of those who commend themselves. But they themselves, measuring themselves by themselves, and comparing themselves with themselves, are without understanding.",
                "But we will not boast beyond proper limits, but within the boundaries with which God appointed to us, which reach even to you.",
                "For we don\u2019t stretch ourselves too much, as though we didn\u2019t reach to you. For we came even as far as to you with the Good News of Christ,",
                "not boasting beyond proper limits in other men\u2019s labors, but having hope that as your faith grows, we will be abundantly enlarged by you in our sphere of influence,",
                "so as to preach the Good News even to the parts beyond you, not to boast in what someone else has already done.",
                "But \"he who boasts, let him boast in the Lord.\"",
                "For it isn\u2019t he who commends himself who is approved, but whom the Lord commends."
            ],
            11: [
                "I wish that you would bear with me in a little foolishness, but indeed you do bear with me.",
                "For I am jealous over you with a godly jealousy. For I married you to one husband, that I might present you as a pure virgin to Christ.",
                "But I am afraid that somehow, as the serpent deceived Eve in his craftiness, so your minds might be corrupted from the simplicity that is in Christ.",
                "For if he who comes preaches another Jesus, whom we did not preach, or if you receive a different spirit, which you did not receive, or a different \"good news\", which you did not accept, you put up with that well enough.",
                "For I reckon that I am not at all behind the very best apostles.",
                "But though I am unskilled in speech, yet I am not unskilled in knowledge. No, in every way we have been revealed to you in all things.",
                "Or did I commit a sin in humbling myself that you might be exalted, because I preached to you God\u2019s Good News free of charge?",
                "I robbed other assemblies, taking wages from them that I might serve you.",
                "When I was present with you and was in need, I wasn\u2019t a burden on anyone, for the brothers, when they came from Macedonia, supplied the measure of my need. In everything I kept myself from being burdensome to you, and I will continue to do so.",
                "As the truth of Christ is in me, no one will stop me from this boasting in the regions of Achaia.",
                "Why? Because I don\u2019t love you? God knows.",
                "But what I do, that I will do, that I may cut off occasion from them that desire an occasion, that in which they boast, they may be found even as we.",
                "For such men are false apostles, deceitful workers, masquerading as Christ\u2019s apostles.",
                "And no wonder, for even Satan masquerades as an angel of light.",
                "It is no great thing therefore if his servants also masquerade as servants of righteousness, whose end will be according to their works.",
                "I say again, let no one think me foolish. But if so, yet receive me as foolish, that I also may boast a little.",
                "That which I speak, I don\u2019t speak according to the Lord, but as in foolishness, in this confidence of boasting.",
                "Seeing that many boast after the flesh, I will also boast.",
                "For you bear with the foolish gladly, being wise.",
                "For you bear with a man, if he brings you into bondage, if he devours you, if he takes you captive, if he exalts himself, if he strikes you on the face.",
                "I speak by way of disparagement, as though we had been weak. Yet however any is bold (I speak in foolishness), I am bold also.",
                "Are they Hebrews? So am I. Are they Israelites? So am I. Are they the seed of Abraham? So am I.",
                "Are they servants of Christ? (I speak as one beside himself) I am more so; in labors more abundantly, in prisons more abundantly, in stripes above measure, in deaths often.",
                "Five times from the Jews I received forty stripes minus one.",
                "Three times I was beaten with rods. Once I was stoned. Three times I suffered shipwreck. I have been a night and a day in the deep.",
                "I have been in travels often, perils of rivers, perils of robbers, perils from my countrymen, perils from the Gentiles, perils in the city, perils in the wilderness, perils in the sea, perils among false brothers;",
                "in labor and travail, in watchings often, in hunger and thirst, in fastings often, and in cold and nakedness.",
                "Besides those things that are outside, there is that which presses on me daily, anxiety for all the assemblies.",
                "Who is weak, and I am not weak? Who is caused to stumble, and I don\u2019t burn with indignation?",
                "If I must boast, I will boast of the things that concern my weakness.",
                "The God and Father of the Lord Jesus Christ, he who is blessed forevermore, knows that I don\u2019t lie.",
                "In Damascus the governor under King Aretas guarded the city of the Damascenes desiring to arrest me.",
                "Through a window I was let down in a basket by the wall, and escaped his hands."
            ],
            12: [
                "It is doubtless not profitable for me to boast. For I will come to visions and revelations of the Lord.",
                "I know a man in Christ, fourteen years ago (whether in the body, I don\u2019t know, or whether out of the body, I don\u2019t know; God knows), such a one caught up into the third heaven.",
                "I know such a man (whether in the body, or outside of the body, I don\u2019t know; God knows),",
                "how he was caught up into Paradise, and heard unspeakable words, which it is not lawful for a man to utter.",
                "On behalf of such a one I will boast, but on my own behalf I will not boast, except in my weaknesses.",
                "For if I would desire to boast, I will not be foolish; for I will speak the truth. But I refrain, so that no man may think more of me than that which he sees in me, or hears from me.",
                "By reason of the exceeding greatness of the revelations, that I should not be exalted excessively, there was given to me a thorn in the flesh, a messenger of Satan to torment me, that I should not be exalted excessively.",
                "Concerning this thing, I begged the Lord three times that it might depart from me.",
                "He has said to me, \"My grace is sufficient for you, for my power is made perfect in weakness.\" Most gladly therefore I will rather glory in my weaknesses, that the power of Christ may rest on me.",
                "Therefore I take pleasure in weaknesses, in injuries, in necessities, in persecutions, in distresses, for Christ\u2019s sake. For when I am weak, then am I strong.",
                "I have become foolish in boasting. You compelled me, for I ought to have been commended by you, for in nothing was I inferior to the very best apostles, though I am nothing.",
                "Truly the signs of an apostle were worked among you in all patience, in signs and wonders and mighty works.",
                "For what is there in which you were made inferior to the rest of the assemblies, unless it is that I myself was not a burden to you? Forgive me this wrong.",
                "Behold, this is the third time I am ready to come to you, and I will not be a burden to you; for I seek not your possessions, but you. For the children ought not to save up for the parents, but the parents for the children.",
                "I will most gladly spend and be spent for your souls. If I love you more abundantly, am I loved the less?",
                "But be it so, I did not myself burden you. But, being crafty, I caught you with deception.",
                "Did I take advantage of you by anyone of them whom I have sent to you?",
                "I exhorted Titus, and I sent the brother with him. Did Titus take any advantage of you? Didn\u2019t we walk in the same spirit? Didn\u2019t we walk in the same steps?",
                "Again, do you think that we are excusing ourselves to you? In the sight of God we speak in Christ. But all things, beloved, are for your edifying.",
                "For I am afraid that by any means, when I come, I might find you not the way I want to, and that I might be found by you as you don\u2019t desire; that by any means there would be strife, jealousy, outbursts of anger, factions, slander, whisperings, proud thoughts, riots;",
                "that again when I come my God would humble me before you, and I would mourn for many of those who have sinned before now, and not repented of the uncleanness and sexual immorality and lustfulness which they committed."
            ],
            13: [
                "This is the third time I am coming to you. \"At the mouth of two or three witnesses shall every word be established.\"",
                "I have said beforehand, and I do say beforehand, as when I was present the second time, so now, being absent, I write to those who have sinned before now, and to all the rest, that, if I come again, I will not spare;",
                "seeing that you seek a proof of Christ who speaks in me; who toward you is not weak, but is powerful in you.",
                "For he was crucified through weakness, yet he lives through the power of God. For we also are weak in him, but we will live with him through the power of God toward you.",
                "Test your own selves, whether you are in the faith. Test your own selves. Or don\u2019t you know as to your own selves, that Jesus Christ is in you?\u2014unless indeed you are disqualified.",
                "But I hope that you will know that we aren\u2019t disqualified.",
                "Now I pray to God that you do no evil; not that we may appear approved, but that you may do that which is honorable, though we are as reprobate.",
                "For we can do nothing against the truth, but for the truth.",
                "For we rejoice when we are weak and you are strong. And this we also pray for, even your perfecting.",
                "For this cause I write these things while absent, that I may not deal sharply when present, according to the authority which the Lord gave me for building up, and not for tearing down.",
                "Finally, brothers, rejoice. Be perfected, be comforted, be of the same mind, live in peace, and the God of love and peace will be with you.",
                "Greet one another with a holy kiss.",
                "All the saints greet you.",
                "The grace of the Lord Jesus Christ, the love of God, and the fellowship of the Holy Spirit, be with you all. Amen."
            ]
        },
        48: {
            1: [
                "Paul, an apostle (not from men, neither through man, but through Jesus Christ, and God the Father, who raised him from the dead),",
                "and all the brothers who are with me, to the assemblies of Galatia:",
                "Grace to you and peace from God the Father, and our Lord Jesus Christ,",
                "who gave himself for our sins, that he might deliver us out of this present evil age, according to the will of our God and Father\u2014",
                "to whom be the glory forever and ever. Amen.",
                "I marvel that you are so quickly deserting him who called you in the grace of Christ to a different \"good news\";",
                "and there isn\u2019t another \"good news.\" Only there are some who trouble you, and want to pervert the Good News of Christ.",
                "But even though we, or an angel from heaven, should preach to you any \"good news\" other than that which we preached to you, let him be cursed.",
                "As we have said before, so I now say again: if any man preaches to you any \"good news\" other than that which you received, let him be cursed.",
                "For am I now seeking the favor of men, or of God? Or am I striving to please men? For if I were still pleasing men, I wouldn\u2019t be a servant of Christ.",
                "But I make known to you, brothers, concerning the Good News which was preached by me, that it is not according to man.",
                "For neither did I receive it from man, nor was I taught it, but it came to me through revelation of Jesus Christ.",
                "For you have heard of my way of living in time past in the Jews\u2019 religion, how that beyond measure I persecuted the assembly of God, and ravaged it.",
                "I advanced in the Jews\u2019 religion beyond many of my own age among my countrymen, being more exceedingly zealous for the traditions of my fathers.",
                "But when it was the good pleasure of God, who separated me from my mother\u2019s womb, and called me through his grace,",
                "to reveal his Son in me, that I might preach him among the Gentiles, I didn\u2019t immediately confer with flesh and blood,",
                "nor did I go up to Jerusalem to those who were apostles before me, but I went away into Arabia. Then I returned to Damascus.",
                "Then after three years I went up to Jerusalem to visit Peter, and stayed with him fifteen days.",
                "But of the other apostles I saw no one, except James, the Lord\u2019s brother.",
                "Now about the things which I write to you, behold, before God, I\u2019m not lying.",
                "Then I came to the regions of Syria and Cilicia.",
                "I was still unknown by face to the assemblies of Judea which were in Christ,",
                "but they only heard: \"He who once persecuted us now preaches the faith that he once tried to destroy.\"",
                "And they glorified God in me."
            ],
            2: [
                "Then after a period of fourteen years I went up again to Jerusalem with Barnabas, taking Titus also with me.",
                "I went up by revelation, and I laid before them the Good News which I preach among the Gentiles, but privately before those who were respected, for fear that I might be running, or had run, in vain.",
                "But not even Titus, who was with me, being a Greek, was compelled to be circumcised.",
                "This was because of the false brothers secretly brought in, who stole in to spy out our liberty which we have in Christ Jesus, that they might bring us into bondage;",
                "to whom we gave no place in the way of subjection, not for an hour, that the truth of the Good News might continue with you.",
                "But from those who were reputed to be important (whatever they were, it makes no difference to me; God doesn\u2019t show partiality to man)\u2014they, I say, who were respected imparted nothing to me,",
                "but to the contrary, when they saw that I had been entrusted with the Good News for the uncircumcision, even as Peter with the Good News for the circumcision",
                "(for he who appointed Peter to the apostleship of the circumcision appointed me also to the Gentiles);",
                "and when they perceived the grace that was given to me, James and Cephas and John, they who were reputed to be pillars, gave to me and Barnabas the right hand of fellowship, that we should go to the Gentiles, and they to the circumcision.",
                "They only asked us to remember the poor\u2014which very thing I was also zealous to do.",
                "But when Peter came to Antioch, I resisted him to his face, because he stood condemned.",
                "For before some people came from James, he ate with the Gentiles. But when they came, he drew back and separated himself, fearing those who were of the circumcision.",
                "And the rest of the Jews joined him in his hypocrisy; so that even Barnabas was carried away with their hypocrisy.",
                "But when I saw that they didn\u2019t walk uprightly according to the truth of the Good News, I said to Peter before them all, \"If you, being a Jew, live as the Gentiles do, and not as the Jews do, why do you compel the Gentiles to live as the Jews do?",
                "\"We, being Jews by nature, and not Gentile sinners,",
                "yet knowing that a man is not justified by the works of the law but through faith in Jesus Christ, even we believed in Christ Jesus, that we might be justified by faith in Christ, and not by the works of the law, because no flesh will be justified by the works of the law.",
                "But if, while we sought to be justified in Christ, we ourselves also were found sinners, is Christ a servant of sin? Certainly not!",
                "For if I build up again those things which I destroyed, I prove myself a law-breaker.",
                "For I, through the law, died to the law, that I might live to God.",
                "I have been crucified with Christ, and it is no longer I that live, but Christ living in me. That life which I now live in the flesh, I live by faith in the Son of God, who loved me, and gave himself up for me.",
                "I don\u2019t make void the grace of God. For if righteousness is through the law, then Christ died for nothing!\""
            ],
            3: [
                "Foolish Galatians, who has bewitched you not to obey the truth, before whose eyes Jesus Christ was openly set forth among you as crucified?",
                "I just want to learn this from you. Did you receive the Spirit by the works of the law, or by hearing of faith?",
                "Are you so foolish? Having begun in the Spirit, are you now completed in the flesh?",
                "Did you suffer so many things in vain, if it is indeed in vain?",
                "He therefore who supplies the Spirit to you, and works miracles among you, does he do it by the works of the law, or by hearing of faith?",
                "Even as Abraham \"believed God, and it was counted to him for righteousness.\"",
                "Know therefore that those who are of faith, the same are children of Abraham.",
                "The Scripture, foreseeing that God would justify the Gentiles by faith, preached the Good News beforehand to Abraham, saying, \"In you all the nations will be blessed.\"",
                "So then, those who are of faith are blessed with the faithful Abraham.",
                "For as many as are of the works of the law are under a curse. For it is written, \"Cursed is everyone who doesn\u2019t continue in all things that are written in the book of the law, to do them.\"",
                "Now that no man is justified by the law before God is evident, for, \"The righteous will live by faith.\"",
                "The law is not of faith, but, \"The man who does them will live by them.\"",
                "Christ redeemed us from the curse of the law, having become a curse for us. For it is written, \"Cursed is everyone who hangs on a tree,\"",
                "that the blessing of Abraham might come on the Gentiles through Christ Jesus; that we might receive the promise of the Spirit through faith.",
                "Brothers, speaking of human terms, though it is only a man\u2019s covenant, yet when it has been confirmed, no one makes it void, or adds to it.",
                "Now the promises were spoken to Abraham and to his seed. He doesn\u2019t say, \"To seeds,\" as of many, but as of one, \"To your seed,\" which is Christ.",
                "Now I say this. A covenant confirmed beforehand by God in Christ, the law, which came four hundred thirty years after, does not annul, so as to make the promise of no effect.",
                "For if the inheritance is of the law, it is no more of promise; but God has granted it to Abraham by promise.",
                "What then is the law? It was added because of transgressions, until the seed should come to whom the promise has been made. It was ordained through angels by the hand of a mediator.",
                "Now a mediator is not between one, but God is one.",
                "Is the law then against the promises of God? Certainly not! For if there had been a law given which could make alive, most certainly righteousness would have been of the law.",
                "But the Scriptures imprisoned all things under sin, that the promise by faith in Jesus Christ might be given to those who believe.",
                "But before faith came, we were kept in custody under the law, confined for the faith which should afterwards be revealed.",
                "So that the law has become our tutor to bring us to Christ, that we might be justified by faith.",
                "But now that faith has come, we are no longer under a tutor.",
                "For you are all children of God, through faith in Christ Jesus.",
                "For as many of you as were baptized into Christ have put on Christ.",
                "There is neither Jew nor Greek, there is neither slave nor free man, there is neither male nor female; for you are all one in Christ Jesus.",
                "If you are Christ\u2019s, then you are Abraham\u2019s seed and heirs according to promise."
            ],
            4: [
                "But I say that so long as the heir is a child, he is no different from a bondservant, though he is lord of all;",
                "but is under guardians and stewards until the day appointed by the father.",
                "So we also, when we were children, were held in bondage under the elemental principles of the world.",
                "But when the fullness of the time came, God sent out his Son, born to a woman, born under the law,",
                "that he might redeem those who were under the law, that we might receive the adoption of children.",
                "And because you are children, God sent out the Spirit of his Son into your hearts, crying, \"Abba, Father!\"",
                "So you are no longer a bondservant, but a son; and if a son, then an heir of God through Christ.",
                "However at that time, not knowing God, you were in bondage to those who by nature are not gods.",
                "But now that you have come to know God, or rather to be known by God, why do you turn back again to the weak and miserable elemental principles, to which you desire to be in bondage all over again?",
                "You observe days, months, seasons, and years.",
                "I am afraid for you, that I might have wasted my labor for you.",
                "I beg you, brothers, become as I am, for I also have become as you are. You did me no wrong,",
                "but you know that because of weakness of the flesh I preached the Good News to you the first time.",
                "That which was a temptation to you in my flesh, you didn\u2019t despise nor reject; but you received me as an angel of God, even as Christ Jesus.",
                "What was the blessing you enjoyed? For I testify to you that, if possible, you would have plucked out your eyes and given them to me.",
                "So then, have I become your enemy by telling you the truth?",
                "They zealously seek you in no good way. No, they desire to alienate you, that you may seek them.",
                "But it is always good to be zealous in a good cause, and not only when I am present with you.",
                "My little children, of whom I am again in travail until Christ is formed in you\u2014",
                "but I could wish to be present with you now, and to change my tone, for I am perplexed about you.",
                "Tell me, you that desire to be under the law, don\u2019t you listen to the law?",
                "For it is written that Abraham had two sons, one by the handmaid, and one by the free woman.",
                "However, the son by the handmaid was born according to the flesh, but the son by the free woman was born through promise.",
                "These things contain an allegory, for these are two covenants. One is from Mount Sinai, bearing children to bondage, which is Hagar.",
                "For this Hagar is Mount Sinai in Arabia, and answers to the Jerusalem that exists now, for she is in bondage with her children.",
                "But the Jerusalem that is above is free, which is the mother of us all.",
                "For it is written, \"Rejoice, you barren who don\u2019t bear. Break forth and shout, you that don\u2019t travail. For more are the children of the desolate than of her who has a husband.\"",
                "Now we, brothers, as Isaac was, are children of promise.",
                "But as then, he who was born according to the flesh persecuted him who was born according to the Spirit, so also it is now.",
                "However what does the Scripture say? \"Throw out the handmaid and her son, for the son of the handmaid will not inherit with the son of the free woman.\"",
                "So then, brothers, we are not children of a handmaid, but of the free woman."
            ],
            5: [
                "Stand firm therefore in the liberty by which Christ has made us free, and don\u2019t be entangled again with a yoke of bondage.",
                "Behold, I, Paul, tell you that if you receive circumcision, Christ will profit you nothing.",
                "Yes, I testify again to every man who receives circumcision, that he is a debtor to do the whole law.",
                "You are alienated from Christ, you who desire to be justified by the law. You have fallen away from grace.",
                "For we, through the Spirit, by faith wait for the hope of righteousness.",
                "For in Christ Jesus neither circumcision amounts to anything, nor uncircumcision, but faith working through love.",
                "You were running well! Who interfered with you that you should not obey the truth?",
                "This persuasion is not from him who calls you.",
                "A little yeast grows through the whole lump.",
                "I have confidence toward you in the Lord that you will think no other way. But he who troubles you will bear his judgment, whoever he is.",
                "But I, brothers, if I still preach circumcision, why am I still persecuted? Then the stumbling block of the cross has been removed.",
                "I wish that those who disturb you would cut themselves off.",
                "For you, brothers, were called for freedom. Only don\u2019t use your freedom for gain to the flesh, but through love be servants to one another.",
                "For the whole law is fulfilled in one word, in this: \"You shall love your neighbor as yourself.\"",
                "But if you bite and devour one another, be careful that you don\u2019t consume one another.",
                "But I say, walk by the Spirit, and you won\u2019t fulfill the lust of the flesh.",
                "For the flesh lusts against the Spirit, and the Spirit against the flesh; and these are contrary to one another, that you may not do the things that you desire.",
                "But if you are led by the Spirit, you are not under the law.",
                "Now the works of the flesh are obvious, which are: adultery, sexual immorality, uncleanness, lustfulness,",
                "idolatry, sorcery, hatred, strife, jealousies, outbursts of anger, rivalries, divisions, heresies,",
                "envyings, murders, drunkenness, orgies, and things like these; of which I forewarn you, even as I also forewarned you, that those who practice such things will not inherit the Kingdom of God.",
                "But the fruit of the Spirit is love, joy, peace, patience, kindness, goodness, faith,",
                "gentleness, and self-control. Against such things there is no law.",
                "Those who belong to Christ have crucified the flesh with its passions and lusts.",
                "If we live by the Spirit, let\u2019s also walk by the Spirit.",
                "Let\u2019s not become conceited, provoking one another, and envying one another."
            ],
            6: [
                "Brothers, even if a man is caught in some fault, you who are spiritual must restore such a one in a spirit of gentleness; looking to yourself so that you also aren\u2019t tempted.",
                "Bear one another\u2019s burdens, and so fulfill the law of Christ.",
                "For if a man thinks himself to be something when he is nothing, he deceives himself.",
                "But let each man test his own work, and then he will take pride in himself and not in his neighbor.",
                "For each man will bear his own burden.",
                "But let him who is taught in the word share all good things with him who teaches.",
                "Don\u2019t be deceived. God is not mocked, for whatever a man sows, that he will also reap.",
                "For he who sows to his own flesh will from the flesh reap corruption. But he who sows to the Spirit will from the Spirit reap eternal life.",
                "Let us not be weary in doing good, for we will reap in due season, if we don\u2019t give up.",
                "So then, as we have opportunity, let\u2019s do what is good toward all men, and especially toward those who are of the household of the faith.",
                "See with what large letters I write to you with my own hand.",
                "As many as desire to look good in the flesh, they compel you to be circumcised; only that they may not be persecuted for the cross of Christ.",
                "For even they who receive circumcision don\u2019t keep the law themselves, but they desire to have you circumcised, that they may boast in your flesh.",
                "But far be it from me to boast, except in the cross of our Lord Jesus Christ, through which the world has been crucified to me, and I to the world.",
                "For in Christ Jesus neither is circumcision anything, nor uncircumcision, but a new creation.",
                "As many as walk by this rule, peace and mercy be on them, and on God\u2019s Israel.",
                "From now on, let no one cause me any trouble, for I bear the marks of the Lord Jesus branded on my body.",
                "The grace of our Lord Jesus Christ be with your spirit, brothers. Amen."
            ]
        },
        49: {
            1: [
                "Paul, an apostle of Christ Jesus through the will of God, to the saints who are at Ephesus, and the faithful in Christ Jesus:",
                "Grace to you and peace from God our Father and the Lord Jesus Christ.",
                "Blessed be the God and Father of our Lord Jesus Christ, who has blessed us with every spiritual blessing in the heavenly places in Christ;",
                "even as he chose us in him before the foundation of the world, that we would be holy and without blemish before him in love;",
                "having predestined us for adoption as children through Jesus Christ to himself, according to the good pleasure of his desire,",
                "to the praise of the glory of his grace, by which he freely bestowed favor on us in the Beloved,",
                "in whom we have our redemption through his blood, the forgiveness of our trespasses, according to the riches of his grace,",
                "which he made to abound toward us in all wisdom and prudence,",
                "making known to us the mystery of his will, according to his good pleasure which he purposed in him",
                "to an administration of the fullness of the times, to sum up all things in Christ, the things in the heavens, and the things on the earth, in him;",
                "in whom also we were assigned an inheritance, having been foreordained according to the purpose of him who works all things after the counsel of his will;",
                "to the end that we should be to the praise of his glory, we who had before hoped in Christ:",
                "in whom you also, having heard the word of the truth, the Good News of your salvation\u2014in whom, having also believed, you were sealed with the Holy Spirit of promise,",
                "who is a pledge of our inheritance, to the redemption of God\u2019s own possession, to the praise of his glory.",
                "For this cause I also, having heard of the faith in the Lord Jesus which is among you, and the love which you have toward all the saints,",
                "don\u2019t cease to give thanks for you, making mention of you in my prayers,",
                "that the God of our Lord Jesus Christ, the Father of glory, may give to you a spirit of wisdom and revelation in the knowledge of him;",
                "having the eyes of your hearts enlightened, that you may know what is the hope of his calling, and what are the riches of the glory of his inheritance in the saints,",
                "and what is the exceeding greatness of his power toward us who believe, according to that working of the strength of his might",
                "which he worked in Christ, when he raised him from the dead, and made him to sit at his right hand in the heavenly places,",
                "far above all rule, and authority, and power, and dominion, and every name that is named, not only in this age, but also in that which is to come.",
                "He put all things in subjection under his feet, and gave him to be head over all things for the assembly,",
                "which is his body, the fullness of him who fills all in all."
            ],
            2: [
                "You were made alive when you were dead in transgressions and sins,",
                "in which you once walked according to the course of this world, according to the prince of the power of the air, the spirit who now works in the children of disobedience;",
                "among whom we also all once lived in the lust of our flesh, doing the desires of the flesh and of the mind, and were by nature children of wrath, even as the rest.",
                "But God, being rich in mercy, for his great love with which he loved us,",
                "even when we were dead through our trespasses, made us alive together with Christ (by grace you have been saved),",
                "and raised us up with him, and made us to sit with him in the heavenly places in Christ Jesus,",
                "that in the ages to come he might show the exceeding riches of his grace in kindness toward us in Christ Jesus;",
                "for by grace you have been saved through faith, and that not of yourselves; it is the gift of God,",
                "not of works, that no one would boast.",
                "For we are his workmanship, created in Christ Jesus for good works, which God prepared before that we would walk in them.",
                "Therefore remember that once you, the Gentiles in the flesh, who are called \"uncircumcision\" by that which is called \"circumcision,\" (in the flesh, made by hands);",
                "that you were at that time separate from Christ, alienated from the commonwealth of Israel, and strangers from the covenants of the promise, having no hope and without God in the world.",
                "But now in Christ Jesus you who once were far off are made near in the blood of Christ.",
                "For he is our peace, who made both one, and broke down the middle wall of partition,",
                "having abolished in the flesh the hostility, the law of commandments contained in ordinances, that he might create in himself one new man of the two, making peace;",
                "and might reconcile them both in one body to God through the cross, having killed the hostility thereby.",
                "He came and preached peace to you who were far off and to those who were near.",
                "For through him we both have our access in one Spirit to the Father.",
                "So then you are no longer strangers and foreigners, but you are fellow citizens with the saints, and of the household of God,",
                "being built on the foundation of the apostles and prophets, Christ Jesus himself being the chief cornerstone;",
                "in whom the whole building, fitted together, grows into a holy temple in the Lord;",
                "in whom you also are built together for a habitation of God in the Spirit."
            ],
            3: [
                "For this cause I, Paul, am the prisoner of Christ Jesus on behalf of you Gentiles,",
                "if it is so that you have heard of the administration of that grace of God which was given me toward you;",
                "how that by revelation the mystery was made known to me, as I wrote before in few words,",
                "by which, when you read, you can perceive my understanding in the mystery of Christ;",
                "which in other generations was not made known to the children of men, as it has now been revealed to his holy apostles and prophets in the Spirit;",
                "that the Gentiles are fellow heirs, and fellow members of the body, and fellow partakers of his promise in Christ Jesus through the Good News,",
                "of which I was made a servant, according to the gift of that grace of God which was given me according to the working of his power.",
                "To me, the very least of all saints, was this grace given, to preach to the Gentiles the unsearchable riches of Christ,",
                "and to make all men see what is the administration of the mystery which for ages has been hidden in God, who created all things through Jesus Christ;",
                "to the intent that now through the assembly the manifold wisdom of God might be made known to the principalities and the powers in the heavenly places,",
                "according to the eternal purpose which he purposed in Christ Jesus our Lord;",
                "in whom we have boldness and access in confidence through our faith in him.",
                "Therefore I ask that you may not lose heart at my troubles for you, which are your glory.",
                "For this cause, I bow my knees to the Father of our Lord Jesus Christ,",
                "from whom every family in heaven and on earth is named,",
                "that he would grant you, according to the riches of his glory, that you may be strengthened with power through his Spirit in the inward man;",
                "that Christ may dwell in your hearts through faith; to the end that you, being rooted and grounded in love,",
                "may be strengthened to comprehend with all the saints what is the breadth and length and height and depth,",
                "and to know Christ\u2019s love which surpasses knowledge, that you may be filled with all the fullness of God.",
                "Now to him who is able to do exceedingly abundantly above all that we ask or think, according to the power that works in us,",
                "to him be the glory in the assembly and in Christ Jesus to all generations forever and ever. Amen."
            ],
            4: [
                "I therefore, the prisoner in the Lord, beg you to walk worthily of the calling with which you were called,",
                "with all lowliness and humility, with patience, bearing with one another in love;",
                "being eager to keep the unity of the Spirit in the bond of peace.",
                "There is one body, and one Spirit, even as you also were called in one hope of your calling;",
                "one Lord, one faith, one baptism,",
                "one God and Father of all, who is over all, and through all, and in us all.",
                "But to each one of us was the grace given according to the measure of the gift of Christ.",
                "Therefore he says, \"When he ascended on high, he led captivity captive, and gave gifts to men.\"",
                "Now this, \"He ascended,\" what is it but that he also first descended into the lower parts of the earth?",
                "He who descended is the one who also ascended far above all the heavens, that he might fill all things.",
                "He gave some to be apostles; and some, prophets; and some, evangelists; and some, shepherds and teachers;",
                "for the perfecting of the saints, to the work of serving, to the building up of the body of Christ;",
                "until we all attain to the unity of the faith, and of the knowledge of the Son of God, to a full grown man, to the measure of the stature of the fullness of Christ;",
                "that we may no longer be children, tossed back and forth and carried about with every wind of doctrine, by the trickery of men, in craftiness, after the wiles of error;",
                "but speaking truth in love, we may grow up in all things into him, who is the head, Christ;",
                "from whom all the body, being fitted and knit together through that which every joint supplies, according to the working in measure of each individual part, makes the body increase to the building up of itself in love.",
                "This I say therefore, and testify in the Lord, that you no longer walk as the rest of the Gentiles also walk, in the futility of their mind,",
                "being darkened in their understanding, alienated from the life of God, because of the ignorance that is in them, because of the hardening of their hearts;",
                "who having become callous gave themselves up to lust, to work all uncleanness with greediness.",
                "But you did not learn Christ that way;",
                "if indeed you heard him, and were taught in him, even as truth is in Jesus:",
                "that you put away, as concerning your former way of life, the old man, that grows corrupt after the lusts of deceit;",
                "and that you be renewed in the spirit of your mind,",
                "and put on the new man, who in the likeness of God has been created in righteousness and holiness of truth.",
                "Therefore, putting away falsehood, speak truth each one with his neighbor. For we are members of one another.",
                "\"Be angry, and don\u2019t sin.\" Don\u2019t let the sun go down on your wrath,",
                "neither give place to the devil.",
                "Let him who stole steal no more; but rather let him labor, working with his hands the thing that is good, that he may have something to give to him who has need.",
                "Let no corrupt speech proceed out of your mouth, but such as is good for building up as the need may be, that it may give grace to those who hear.",
                "Don\u2019t grieve the Holy Spirit of God, in whom you were sealed for the day of redemption.",
                "Let all bitterness, wrath, anger, outcry, and slander, be put away from you, with all malice.",
                "And be kind to one another, tenderhearted, forgiving each other, just as God also in Christ forgave you."
            ],
            5: [
                "Be therefore imitators of God, as beloved children.",
                "Walk in love, even as Christ also loved you, and gave himself up for us, an offering and a sacrifice to God for a sweet-smelling fragrance.",
                "But sexual immorality, and all uncleanness, or covetousness, let it not even be mentioned among you, as becomes saints;",
                "nor filthiness, nor foolish talking, nor jesting, which are not appropriate; but rather giving of thanks.",
                "Know this for sure, that no sexually immoral person, nor unclean person, nor covetous man, who is an idolater, has any inheritance in the Kingdom of Christ and God.",
                "Let no one deceive you with empty words. For because of these things, the wrath of God comes on the children of disobedience.",
                "Therefore don\u2019t be partakers with them.",
                "For you were once darkness, but are now light in the Lord. Walk as children of light,",
                "for the fruit of the Spirit is in all goodness and righteousness and truth,",
                "proving what is well pleasing to the Lord.",
                "Have no fellowship with the unfruitful works of darkness, but rather even reprove them.",
                "For the things which are done by them in secret, it is a shame even to speak of.",
                "But all things, when they are reproved, are revealed by the light, for everything that reveals is light.",
                "Therefore he says, \"Awake, you who sleep, and arise from the dead, and Christ will shine on you.\"",
                "Therefore watch carefully how you walk, not as unwise, but as wise;",
                "redeeming the time, because the days are evil.",
                "Therefore don\u2019t be foolish, but understand what the will of the Lord is.",
                "Don\u2019t be drunken with wine, in which is dissipation, but be filled with the Spirit,",
                "speaking to one another in psalms, hymns, and spiritual songs; singing, and making melody in your heart to the Lord;",
                "giving thanks always concerning all things in the name of our Lord Jesus Christ, to God, even the Father;",
                "subjecting yourselves one to another in the fear of Christ.",
                "Wives, be subject to your own husbands, as to the Lord.",
                "For the husband is the head of the wife, and Christ also is the head of the assembly, being himself the savior of the body.",
                "But as the assembly is subject to Christ, so let the wives also be to their own husbands in everything.",
                "Husbands, love your wives, even as Christ also loved the assembly, and gave himself up for it;",
                "that he might sanctify it, having cleansed it by the washing of water with the word,",
                "that he might present the assembly to himself gloriously, not having spot or wrinkle or any such thing; but that it should be holy and without blemish.",
                "Even so husbands also ought to love their own wives as their own bodies. He who loves his own wife loves himself.",
                "For no man ever hated his own flesh; but nourishes and cherishes it, even as the Lord also does the assembly;",
                "because we are members of his body, of his flesh and bones.",
                "\"For this cause a man will leave his father and mother, and will be joined to his wife. The two will become one flesh.\"",
                "This mystery is great, but I speak concerning Christ and of the assembly.",
                "Nevertheless each of you must also love his own wife even as himself; and let the wife see that she respects her husband."
            ],
            6: [
                "Children, obey your parents in the Lord, for this is right.",
                "\"Honor your father and mother,\" which is the first commandment with a promise:",
                "\"that it may be well with you, and you may live long on the earth.\"",
                "You fathers, don\u2019t provoke your children to wrath, but nurture them in the discipline and instruction of the Lord.",
                "Servants, be obedient to those who according to the flesh are your masters, with fear and trembling, in singleness of your heart, as to Christ;",
                "not in the way of service only when eyes are on you, as men pleasers; but as servants of Christ, doing the will of God from the heart;",
                "with good will doing service, as to the Lord, and not to men;",
                "knowing that whatever good thing each one does, he will receive the same again from the Lord, whether he is bound or free.",
                "You masters, do the same things to them, and give up threatening, knowing that he who is both their Master and yours is in heaven, and there is no partiality with him.",
                "Finally, be strong in the Lord, and in the strength of his might.",
                "Put on the whole armor of God, that you may be able to stand against the wiles of the devil.",
                "For our wrestling is not against flesh and blood, but against the principalities, against the powers, against the world\u2019s rulers of the darkness of this age, and against the spiritual forces of wickedness in the heavenly places.",
                "Therefore, put on the whole armor of God, that you may be able to withstand in the evil day, and, having done all, to stand.",
                "Stand therefore, having the utility belt of truth buckled around your waist, and having put on the breastplate of righteousness,",
                "and having fitted your feet with the preparation of the Good News of peace;",
                "above all, taking up the shield of faith, with which you will be able to quench all the fiery darts of the evil one.",
                "And take the helmet of salvation, and the sword of the Spirit, which is the word of God;",
                "with all prayer and requests, praying at all times in the Spirit, and being watchful to this end in all perseverance and requests for all the saints:",
                "on my behalf, that utterance may be given to me in opening my mouth, to make known with boldness the mystery of the Good News,",
                "for which I am an ambassador in chains; that in it I may speak boldly, as I ought to speak.",
                "But that you also may know my affairs, how I am doing, Tychicus, the beloved brother and faithful servant in the Lord, will make known to you all things;",
                "whom I have sent to you for this very purpose, that you may know our state, and that he may comfort your hearts.",
                "Peace be to the brothers, and love with faith, from God the Father and the Lord Jesus Christ.",
                "Grace be with all those who love our Lord Jesus Christ with incorruptible love. Amen."
            ]
        },
        50: {
            1: [
                "Paul and Timothy, servants of Jesus Christ; To all the saints in Christ Jesus who are at Philippi, with the overseers and servants:",
                "Grace to you, and peace from God, our Father, and the Lord Jesus Christ.",
                "I thank my God whenever I remember you,",
                "always in every request of mine on behalf of you all making my requests with joy,",
                "for your partnership in furtherance of the Good News from the first day until now;",
                "being confident of this very thing, that he who began a good work in you will complete it until the day of Jesus Christ.",
                "It is even right for me to think this way on behalf of all of you, because I have you in my heart, because, both in my bonds and in the defense and confirmation of the Good News, you all are partakers with me of grace.",
                "For God is my witness, how I long after all of you in the tender mercies of Christ Jesus.",
                "This I pray, that your love may abound yet more and more in knowledge and all discernment;",
                "so that you may approve the things that are excellent; that you may be sincere and without offense to the day of Christ;",
                "being filled with the fruits of righteousness, which are through Jesus Christ, to the glory and praise of God.",
                "Now I desire to have you know, brothers, that the things which happened to me have turned out rather to the progress of the Good News;",
                "so that it became evident to the whole palace guard, and to all the rest, that my bonds are in Christ;",
                "and that most of the brothers in the Lord, being confident through my bonds, are more abundantly bold to speak the word of God without fear.",
                "Some indeed preach Christ even out of envy and strife, and some also out of good will.",
                "The former insincerely preach Christ from selfish ambition, thinking that they add affliction to my chains;",
                "but the latter out of love, knowing that I am appointed for the defense of the Good News.",
                "What does it matter? Only that in every way, whether in pretense or in truth, Christ is proclaimed. I rejoice in this, yes, and will rejoice.",
                "For I know that this will turn out to my salvation, through your supplication and the supply of the Spirit of Jesus Christ,",
                "according to my earnest expectation and hope, that I will in no way be disappointed, but with all boldness, as always, now also Christ will be magnified in my body, whether by life, or by death.",
                "For to me to live is Christ, and to die is gain.",
                "But if I live on in the flesh, this will bring fruit from my work; yet I don\u2019t know what I will choose.",
                "But I am in a dilemma between the two, having the desire to depart and be with Christ, which is far better.",
                "Yet, to remain in the flesh is more needful for your sake.",
                "Having this confidence, I know that I will remain, yes, and remain with you all, for your progress and joy in the faith,",
                "that your rejoicing may abound in Christ Jesus in me through my presence with you again.",
                "Only let your way of life be worthy of the Good News of Christ, that, whether I come and see you or am absent, I may hear of your state, that you stand firm in one spirit, with one soul striving for the faith of the Good News;",
                "and in nothing frightened by the adversaries, which is for them a proof of destruction, but to you of salvation, and that from God.",
                "Because it has been granted to you on behalf of Christ, not only to believe in him, but also to suffer on his behalf,",
                "having the same conflict which you saw in me, and now hear is in me."
            ],
            2: [
                "If there is therefore any exhortation in Christ, if any consolation of love, if any fellowship of the Spirit, if any tender mercies and compassion,",
                "make my joy full, by being like-minded, having the same love, being of one accord, of one mind;",
                "doing nothing through rivalry or through conceit, but in humility, each counting others better than himself;",
                "each of you not just looking to his own things, but each of you also to the things of others.",
                "Have this in your mind, which was also in Christ Jesus,",
                "who, existing in the form of God, didn\u2019t consider equality with God a thing to be grasped,",
                "but emptied himself, taking the form of a servant, being made in the likeness of men.",
                "And being found in human form, he humbled himself, becoming obedient to death, yes, the death of the cross.",
                "Therefore God also highly exalted him, and gave to him the name which is above every name;",
                "that at the name of Jesus every knee should bow, of those in heaven, those on earth, and those under the earth,",
                "and that every tongue should confess that Jesus Christ is Lord, to the glory of God the Father.",
                "So then, my beloved, even as you have always obeyed, not only in my presence, but now much more in my absence, work out your own salvation with fear and trembling.",
                "For it is God who works in you both to will and to work, for his good pleasure.",
                "Do all things without murmurings and disputes,",
                "that you may become blameless and harmless, children of God without blemish in the midst of a crooked and perverse generation, among whom you are seen as lights in the world,",
                "holding up the word of life; that I may have something to boast in the day of Christ, that I didn\u2019t run in vain nor labor in vain.",
                "Yes, and if I am poured out on the sacrifice and service of your faith, I rejoice, and rejoice with you all.",
                "In the same way, you also rejoice, and rejoice with me.",
                "But I hope in the Lord Jesus to send Timothy to you soon, that I also may be cheered up when I know how you are doing.",
                "For I have no one else like-minded, who will truly care about you.",
                "For they all seek their own, not the things of Jesus Christ.",
                "But you know the proof of him, that, as a child serves a father, so he served with me in furtherance of the Good News.",
                "Therefore I hope to send him at once, as soon as I see how it will go with me.",
                "But I trust in the Lord that I myself also will come shortly.",
                "But I counted it necessary to send to you Epaphroditus, my brother, fellow worker, fellow soldier, and your apostle and servant of my need;",
                "since he longed for you all, and was very troubled, because you had heard that he was sick.",
                "For indeed he was sick, nearly to death, but God had mercy on him; and not on him only, but on me also, that I might not have sorrow on sorrow.",
                "I have sent him therefore the more diligently, that, when you see him again, you may rejoice, and that I may be the less sorrowful.",
                "Receive him therefore in the Lord with all joy, and hold such in honor,",
                "because for the work of Christ he came near to death, risking his life to supply that which was lacking in your service toward me."
            ],
            3: [
                "Finally, my brothers, rejoice in the Lord. To write the same things to you, to me indeed is not tiresome, but for you it is safe.",
                "Beware of the dogs, beware of the evil workers, beware of the false circumcision.",
                "For we are the circumcision, who worship God in the Spirit, and rejoice in Christ Jesus, and have no confidence in the flesh;",
                "though I myself might have confidence even in the flesh. If any other man thinks that he has confidence in the flesh, I yet more:",
                "circumcised the eighth day, of the stock of Israel, of the tribe of Benjamin, a Hebrew of Hebrews; concerning the law, a Pharisee;",
                "concerning zeal, persecuting the assembly; concerning the righteousness which is in the law, found blameless.",
                "However, what things were gain to me, these have I counted loss for Christ.",
                "Yes most certainly, and I count all things to be loss for the excellency of the knowledge of Christ Jesus, my Lord, for whom I suffered the loss of all things, and count them nothing but refuse, that I may gain Christ",
                "and be found in him, not having a righteousness of my own, that which is of the law, but that which is through faith in Christ, the righteousness which is from God by faith;",
                "that I may know him, and the power of his resurrection, and the fellowship of his sufferings, becoming conformed to his death;",
                "if by any means I may attain to the resurrection from the dead.",
                "Not that I have already obtained, or am already made perfect; but I press on, if it is so that I may take hold of that for which also I was taken hold of by Christ Jesus.",
                "Brothers, I don\u2019t regard myself as yet having taken hold, but one thing I do. Forgetting the things which are behind, and stretching forward to the things which are before,",
                "I press on toward the goal for the prize of the high calling of God in Christ Jesus.",
                "Let us therefore, as many as are perfect, think this way. If in anything you think otherwise, God will also reveal that to you.",
                "Nevertheless, to the extent that we have already attained, let us walk by the same rule. Let us be of the same mind.",
                "Brothers, be imitators together of me, and note those who walk this way, even as you have us for an example.",
                "For many walk, of whom I told you often, and now tell you even weeping, as the enemies of the cross of Christ,",
                "whose end is destruction, whose god is the belly, and whose glory is in their shame, who think about earthly things.",
                "For our citizenship is in heaven, from where we also wait for a Savior, the Lord Jesus Christ;",
                "who will change the body of our humiliation to be conformed to the body of his glory, according to the working by which he is able even to subject all things to himself."
            ],
            4: [
                "Therefore, my brothers, beloved and longed for, my joy and crown, so stand firm in the Lord, my beloved.",
                "I exhort Euodia, and I exhort Syntyche, to think the same way in the Lord.",
                "Yes, I beg you also, true yokefellow, help these women, for they labored with me in the Good News, with Clement also, and the rest of my fellow workers, whose names are in the book of life.",
                "Rejoice in the Lord always! Again I will say, \"Rejoice!\"",
                "Let your gentleness be known to all men. The Lord is at hand.",
                "In nothing be anxious, but in everything, by prayer and petition with thanksgiving, let your requests be made known to God.",
                "And the peace of God, which surpasses all understanding, will guard your hearts and your thoughts in Christ Jesus.",
                "Finally, brothers, whatever things are true, whatever things are honorable, whatever things are just, whatever things are pure, whatever things are lovely, whatever things are of good report; if there is any virtue, and if there is any praise, think about these things.",
                "The things which you learned, received, heard, and saw in me: do these things, and the God of peace will be with you.",
                "But I rejoice in the Lord greatly, that now at length you have revived your thought for me; in which you did indeed take thought, but you lacked opportunity.",
                "Not that I speak in respect to lack, for I have learned in whatever state I am, to be content in it.",
                "I know how to be humbled, and I know also how to abound. In everything and in all things I have learned the secret both to be filled and to be hungry, both to abound and to be in need.",
                "I can do all things through Christ, who strengthens me.",
                "However you did well that you shared in my affliction.",
                "You yourselves also know, you Philippians, that in the beginning of the Good News, when I departed from Macedonia, no assembly shared with me in the matter of giving and receiving but you only.",
                "For even in Thessalonica you sent once and again to my need.",
                "Not that I seek for the gift, but I seek for the fruit that increases to your account.",
                "But I have all things, and abound. I am filled, having received from Epaphroditus the things that came from you, a sweet-smelling fragrance, an acceptable and well-pleasing sacrifice to God.",
                "My God will supply every need of yours according to his riches in glory in Christ Jesus.",
                "Now to our God and Father be the glory forever and ever! Amen.",
                "Greet every saint in Christ Jesus. The brothers who are with me greet you.",
                "All the saints greet you, especially those who are of Caesar\u2019s household.",
                "The grace of the Lord Jesus Christ be with you all. Amen."
            ]
        },
        51: {
            1: [
                "Paul, an apostle of Christ Jesus through the will of God, and Timothy our brother,",
                "to the saints and faithful brothers in Christ at Colossae: Grace to you and peace from God our Father, and the Lord Jesus Christ.",
                "We give thanks to God the Father of our Lord Jesus Christ, praying always for you,",
                "having heard of your faith in Christ Jesus, and of the love which you have toward all the saints,",
                "because of the hope which is laid up for you in the heavens, of which you heard before in the word of the truth of the Good News,",
                "which has come to you; even as it is in all the world and is bearing fruit and growing, as it does in you also, since the day you heard and knew the grace of God in truth;",
                "even as you learned of Epaphras our beloved fellow servant, who is a faithful servant of Christ on our behalf,",
                "who also declared to us your love in the Spirit.",
                "For this cause, we also, since the day we heard this, don\u2019t cease praying and making requests for you, that you may be filled with the knowledge of his will in all spiritual wisdom and understanding,",
                "that you may walk worthily of the Lord, to please him in all respects, bearing fruit in every good work, and increasing in the knowledge of God;",
                "strengthened with all power, according to the might of his glory, for all endurance and perseverance with joy;",
                "giving thanks to the Father, who made us fit to be partakers of the inheritance of the saints in light;",
                "who delivered us out of the power of darkness, and translated us into the Kingdom of the Son of his love;",
                "in whom we have our redemption, the forgiveness of our sins;",
                "who is the image of the invisible God, the firstborn of all creation.",
                "For by him all things were created, in the heavens and on the earth, things visible and things invisible, whether thrones or dominions or principalities or powers; all things have been created through him, and for him.",
                "He is before all things, and in him all things are held together.",
                "He is the head of the body, the assembly, who is the beginning, the firstborn from the dead; that in all things he might have the preeminence.",
                "For all the fullness was pleased to dwell in him;",
                "and through him to reconcile all things to himself, by him, whether things on the earth, or things in the heavens, having made peace through the blood of his cross.",
                "You, being in past times alienated and enemies in your mind in your evil works,",
                "yet now he has reconciled in the body of his flesh through death, to present you holy and without blemish and blameless before him,",
                "if it is so that you continue in the faith, grounded and steadfast, and not moved away from the hope of the Good News which you heard, which is being proclaimed in all creation under heaven; of which I, Paul, was made a servant.",
                "Now I rejoice in my sufferings for your sake, and fill up on my part that which is lacking of the afflictions of Christ in my flesh for his body\u2019s sake, which is the assembly;",
                "of which I was made a servant, according to the stewardship of God which was given me toward you, to fulfill the word of God,",
                "the mystery which has been hidden for ages and generations. But now it has been revealed to his saints,",
                "to whom God was pleased to make known what are the riches of the glory of this mystery among the Gentiles, which is Christ in you, the hope of glory;",
                "whom we proclaim, admonishing every man and teaching every man in all wisdom, that we may present every man perfect in Christ Jesus;",
                "for which I also labor, striving according to his working, which works in me mightily."
            ],
            2: [
                "For I desire to have you know how greatly I struggle for you, and for those at Laodicea, and for as many as have not seen my face in the flesh;",
                "that their hearts may be comforted, they being knit together in love, and gaining all riches of the full assurance of understanding, that they may know the mystery of God, both of the Father and of Christ,",
                "in whom are all the treasures of wisdom and knowledge hidden.",
                "Now this I say that no one may delude you with persuasiveness of speech.",
                "For though I am absent in the flesh, yet am I with you in the spirit, rejoicing and seeing your order, and the steadfastness of your faith in Christ.",
                "As therefore you received Christ Jesus, the Lord, walk in him,",
                "rooted and built up in him, and established in the faith, even as you were taught, abounding in it in thanksgiving.",
                "Be careful that you don\u2019t let anyone rob you through his philosophy and vain deceit, after the tradition of men, after the elements of the world, and not after Christ.",
                "For in him all the fullness of the Godhead dwells bodily,",
                "and in him you are made full, who is the head of all principality and power;",
                "in whom you were also circumcised with a circumcision not made with hands, in the putting off of the body of the sins of the flesh, in the circumcision of Christ;",
                "having been buried with him in baptism, in which you were also raised with him through faith in the working of God, who raised him from the dead.",
                "You were dead through your trespasses and the uncircumcision of your flesh. He made you alive together with him, having forgiven us all our trespasses,",
                "wiping out the handwriting in ordinances which was against us; and he has taken it out of the way, nailing it to the cross;",
                "having stripped the principalities and the powers, he made a show of them openly, triumphing over them in it.",
                "Let no one therefore judge you in eating, or in drinking, or with respect to a feast day or a new moon or a Sabbath day,",
                "which are a shadow of the things to come; but the body is Christ\u2019s.",
                "Let no one rob you of your prize by a voluntary humility and worshipping of the angels, dwelling in the things which he has not seen, vainly puffed up by his fleshly mind,",
                "and not holding firmly to the Head, from whom all the body, being supplied and knit together through the joints and ligaments, grows with God\u2019s growth.",
                "If you died with Christ from the elements of the world, why, as though living in the world, do you subject yourselves to ordinances,",
                "\"Don\u2019t handle, nor taste, nor touch\"",
                "(all of which perish with use), according to the precepts and doctrines of men?",
                "Which things indeed appear like wisdom in self-imposed worship, and humility, and severity to the body; but aren\u2019t of any value against the indulgence of the flesh."
            ],
            3: [
                "If then you were raised together with Christ, seek the things that are above, where Christ is, seated on the right hand of God.",
                "Set your mind on the things that are above, not on the things that are on the earth.",
                "For you died, and your life is hidden with Christ in God.",
                "When Christ, our life, is revealed, then you will also be revealed with him in glory.",
                "Put to death therefore your members which are on the earth: sexual immorality, uncleanness, depraved passion, evil desire, and covetousness, which is idolatry;",
                "for which things\u2019 sake the wrath of God comes on the children of disobedience.",
                "You also once walked in those, when you lived in them;",
                "but now you also put them all away: anger, wrath, malice, slander, and shameful speaking out of your mouth.",
                "Don\u2019t lie to one another, seeing that you have put off the old man with his doings,",
                "and have put on the new man, who is being renewed in knowledge after the image of his Creator,",
                "where there can\u2019t be Greek and Jew, circumcision and uncircumcision, barbarian, Scythian, bondservant, freeman; but Christ is all, and in all.",
                "Put on therefore, as God\u2019s chosen ones, holy and beloved, a heart of compassion, kindness, lowliness, humility, and perseverance;",
                "bearing with one another, and forgiving each other, if any man has a complaint against any; even as Christ forgave you, so you also do.",
                "Above all these things, walk in love, which is the bond of perfection.",
                "And let the peace of God rule in your hearts, to which also you were called in one body; and be thankful.",
                "Let the word of Christ dwell in you richly; in all wisdom teaching and admonishing one another with psalms, hymns, and spiritual songs, singing with grace in your heart to the Lord.",
                "Whatever you do, in word or in deed, do all in the name of the Lord Jesus, giving thanks to God the Father, through him.",
                "Wives, be in subjection to your husbands, as is fitting in the Lord.",
                "Husbands, love your wives, and don\u2019t be bitter against them.",
                "Children, obey your parents in all things, for this pleases the Lord.",
                "Fathers, don\u2019t provoke your children, so that they won\u2019t be discouraged.",
                "Servants, obey in all things those who are your masters according to the flesh, not just when they are looking, as men pleasers, but in singleness of heart, fearing God.",
                "And whatever you do, work heartily, as for the Lord, and not for men,",
                "knowing that from the Lord you will receive the reward of the inheritance; for you serve the Lord Christ.",
                "But he who does wrong will receive again for the wrong that he has done, and there is no partiality."
            ],
            4: [
                "Masters, give to your servants that which is just and equal, knowing that you also have a Master in heaven.",
                "Continue steadfastly in prayer, watching therein with thanksgiving;",
                "praying together for us also, that God may open to us a door for the word, to speak the mystery of Christ, for which I am also in bonds;",
                "that I may reveal it as I ought to speak.",
                "Walk in wisdom toward those who are outside, redeeming the time.",
                "Let your speech always be with grace, seasoned with salt, that you may know how you ought to answer each one.",
                "All my affairs will be made known to you by Tychicus, the beloved brother, faithful servant, and fellow bondservant in the Lord.",
                "I am sending him to you for this very purpose, that he may know your circumstances and comfort your hearts,",
                "together with Onesimus, the faithful and beloved brother, who is one of you. They will make known to you everything that is going on here.",
                "Aristarchus, my fellow prisoner, greets you, and Mark, the cousin of Barnabas (concerning whom you received commandments, \"if he comes to you, receive him\"),",
                "and Jesus who is called Justus. These are my only fellow workers for the Kingdom of God who are of the circumcision, men who have been a comfort to me.",
                "Epaphras, who is one of you, a servant of Christ, salutes you, always striving for you in his prayers, that you may stand perfect and complete in all the will of God.",
                "For I testify about him, that he has great zeal for you, and for those in Laodicea, and for those in Hierapolis.",
                "Luke, the beloved physician, and Demas greet you.",
                "Greet the brothers who are in Laodicea, and Nymphas, and the assembly that is in his house.",
                "When this letter has been read among you, cause it to be read also in the assembly of the Laodiceans; and that you also read the letter from Laodicea.",
                "Tell Archippus, \"Take heed to the ministry which you have received in the Lord, that you fulfill it.\"",
                "The salutation of me, Paul, with my own hand: remember my bonds. Grace be with you. Amen."
            ]
        },
        52: {
            1: [
                "Paul, Silvanus, and Timothy, to the assembly of the Thessalonians in God the Father and the Lord Jesus Christ: Grace to you and peace from God our Father and the Lord Jesus Christ.",
                "We always give thanks to God for all of you, mentioning you in our prayers,",
                "remembering without ceasing your work of faith and labor of love and patience of hope in our Lord Jesus Christ, before our God and Father.",
                "We know, brothers loved by God, that you are chosen,",
                "and that our Good News came to you not in word only, but also in power, and in the Holy Spirit, and with much assurance. You know what kind of men we showed ourselves to be among you for your sake.",
                "You became imitators of us, and of the Lord, having received the word in much affliction, with joy of the Holy Spirit,",
                "so that you became an example to all who believe in Macedonia and in Achaia.",
                "For from you the word of the Lord has been declared, not only in Macedonia and Achaia, but also in every place your faith toward God has gone out; so that we need not to say anything.",
                "For they themselves report concerning us what kind of a reception we had from you; and how you turned to God from idols, to serve a living and true God,",
                "and to wait for his Son from heaven, whom he raised from the dead\u2014Jesus, who delivers us from the wrath to come."
            ],
            2: [
                "For you yourselves know, brothers, our visit to you wasn\u2019t in vain,",
                "but having suffered before and been shamefully treated, as you know, at Philippi, we grew bold in our God to tell you the Good News of God in much conflict.",
                "For our exhortation is not of error, nor of uncleanness, nor in deception.",
                "But even as we have been approved by God to be entrusted with the Good News, so we speak; not as pleasing men, but God, who tests our hearts.",
                "For neither were we at any time found using words of flattery, as you know, nor a cloak of covetousness (God is witness),",
                "nor seeking glory from men (neither from you nor from others), when we might have claimed authority as apostles of Christ.",
                "But we were gentle among you, like a nursing mother cherishes her own children.",
                "Even so, affectionately longing for you, we were well pleased to impart to you, not the Good News of God only, but also our own souls, because you had become very dear to us.",
                "For you remember, brothers, our labor and travail; for working night and day, that we might not burden any of you, we preached to you the Good News of God.",
                "You are witnesses with God, how holy, righteously, and blamelessly we behaved ourselves toward you who believe.",
                "As you know, we exhorted, comforted, and implored every one of you, as a father does his own children,",
                "to the end that you should walk worthily of God, who calls you into his own Kingdom and glory.",
                "For this cause we also thank God without ceasing, that, when you received from us the word of the message of God, you accepted it not as the word of men, but, as it is in truth, the word of God, which also works in you who believe.",
                "For you, brothers, became imitators of the assemblies of God which are in Judea in Christ Jesus; for you also suffered the same things from your own countrymen, even as they did from the Jews;",
                "who killed both the Lord Jesus and their own prophets, and drove us out, and didn\u2019t please God, and are contrary to all men;",
                "forbidding us to speak to the Gentiles that they may be saved; to fill up their sins always. But wrath has come on them to the uttermost.",
                "But we, brothers, being bereaved of you for a short season, in presence, not in heart, tried even harder to see your face with great desire,",
                "because we wanted to come to you\u2014indeed, I, Paul, once and again\u2014but Satan hindered us.",
                "For what is our hope, or joy, or crown of rejoicing? Isn\u2019t it even you, before our Lord Jesus at his coming?",
                "For you are our glory and our joy."
            ],
            3: [
                "Therefore, when we couldn\u2019t stand it any longer, we thought it good to be left behind at Athens alone,",
                "and sent Timothy, our brother and God\u2019s servant in the Good News of Christ, to establish you, and to comfort you concerning your faith;",
                "that no one be moved by these afflictions. For you know that we are appointed to this task.",
                "For most certainly, when we were with you, we told you beforehand that we are to suffer affliction, even as it happened, and you know.",
                "For this cause I also, when I couldn\u2019t stand it any longer, sent that I might know your faith, for fear that by any means the tempter had tempted you, and our labor would have been in vain.",
                "But when Timothy came just now to us from you, and brought us glad news of your faith and love, and that you have good memories of us always, longing to see us, even as we also long to see you;",
                "for this cause, brothers, we were comforted over you in all our distress and affliction through your faith.",
                "For now we live, if you stand fast in the Lord.",
                "For what thanksgiving can we render again to God for you, for all the joy with which we rejoice for your sakes before our God;",
                "night and day praying exceedingly that we may see your face, and may perfect that which is lacking in your faith?",
                "Now may our God and Father himself, and our Lord Jesus Christ, direct our way to you;",
                "and the Lord make you to increase and abound in love one toward another, and toward all men, even as we also do toward you,",
                "to the end he may establish your hearts blameless in holiness before our God and Father, at the coming of our Lord Jesus with all his saints."
            ],
            4: [
                "Finally then, brothers, we beg and exhort you in the Lord Jesus, that as you received from us how you ought to walk and to please God, that you abound more and more.",
                "For you know what instructions we gave you through the Lord Jesus.",
                "For this is the will of God: your sanctification, that you abstain from sexual immorality,",
                "that each one of you know how to possess himself of his own vessel in sanctification and honor,",
                "not in the passion of lust, even as the Gentiles who don\u2019t know God;",
                "that no one should take advantage of and wrong a brother or sister in this matter; because the Lord is an avenger in all these things, as also we forewarned you and testified.",
                "For God called us not for uncleanness, but in sanctification.",
                "Therefore he who rejects this doesn\u2019t reject man, but God, who has also given his Holy Spirit to you.",
                "But concerning brotherly love, you have no need that one write to you. For you yourselves are taught by God to love one another,",
                "for indeed you do it toward all the brothers who are in all Macedonia. But we exhort you, brothers, that you abound more and more;",
                "and that you make it your ambition to lead a quiet life, and to do your own business, and to work with your own hands, even as we instructed you;",
                "that you may walk properly toward those who are outside, and may have need of nothing.",
                "But we don\u2019t want you to be ignorant, brothers, concerning those who have fallen asleep, so that you don\u2019t grieve like the rest, who have no hope.",
                "For if we believe that Jesus died and rose again, even so God will bring with him those who have fallen asleep in Jesus.",
                "For this we tell you by the word of the Lord, that we who are alive, who are left to the coming of the Lord, will in no way precede those who have fallen asleep.",
                "For the Lord himself will descend from heaven with a shout, with the voice of the archangel, and with God\u2019s trumpet. The dead in Christ will rise first,",
                "then we who are alive, who are left, will be caught up together with them in the clouds, to meet the Lord in the air. So we will be with the Lord forever.",
                "Therefore comfort one another with these words."
            ],
            5: [
                "But concerning the times and the seasons, brothers, you have no need that anything be written to you.",
                "For you yourselves know well that the day of the Lord comes like a thief in the night.",
                "For when they are saying, \"Peace and safety,\" then sudden destruction will come on them, like birth pains on a pregnant woman; and they will in no way escape.",
                "But you, brothers, aren\u2019t in darkness, that the day should overtake you like a thief.",
                "You are all children of light, and children of the day. We don\u2019t belong to the night, nor to darkness,",
                "so then let\u2019s not sleep, as the rest do, but let\u2019s watch and be sober.",
                "For those who sleep, sleep in the night, and those who are drunk are drunk in the night.",
                "But let us, since we belong to the day, be sober, putting on the breastplate of faith and love, and, for a helmet, the hope of salvation.",
                "For God didn\u2019t appoint us to wrath, but to the obtaining of salvation through our Lord Jesus Christ,",
                "who died for us, that, whether we wake or sleep, we should live together with him.",
                "Therefore exhort one another, and build each other up, even as you also do.",
                "But we beg you, brothers, to know those who labor among you, and are over you in the Lord, and admonish you,",
                "and to respect and honor them in love for their work\u2019s sake. Be at peace among yourselves.",
                "We exhort you, brothers, admonish the disorderly, encourage the fainthearted, support the weak, be patient toward all.",
                "See that no one returns evil for evil to anyone, but always follow after that which is good, for one another, and for all.",
                "Rejoice always.",
                "Pray without ceasing.",
                "In everything give thanks, for this is the will of God in Christ Jesus toward you.",
                "Don\u2019t quench the Spirit.",
                "Don\u2019t despise prophesies.",
                "Test all things, and hold firmly that which is good.",
                "Abstain from every form of evil.",
                "May the God of peace himself sanctify you completely. May your whole spirit, soul, and body be preserved blameless at the coming of our Lord Jesus Christ.",
                "He who calls you is faithful, who will also do it.",
                "Brothers, pray for us.",
                "Greet all the brothers with a holy kiss.",
                "I solemnly command you by the Lord that this letter be read to all the holy brothers.",
                "The grace of our Lord Jesus Christ be with you. Amen."
            ]
        },
        53: {
            1: [
                "Paul, Silvanus, and Timothy, to the assembly of the Thessalonians in God our Father, and the Lord Jesus Christ:",
                "Grace to you and peace from God our Father and the Lord Jesus Christ.",
                "We are bound to always give thanks to God for you, brothers, even as it is appropriate, because your faith grows exceedingly, and the love of each and every one of you towards one another abounds;",
                "so that we ourselves boast about you in the assemblies of God for your patience and faith in all your persecutions and in the afflictions which you endure.",
                "This is an obvious sign of the righteous judgment of God, to the end that you may be counted worthy of the Kingdom of God, for which you also suffer.",
                "Since it is a righteous thing with God to repay affliction to those who afflict you,",
                "and to give relief to you who are afflicted with us, when the Lord Jesus is revealed from heaven with his mighty angels in flaming fire,",
                "giving vengeance to those who don\u2019t know God, and to those who don\u2019t obey the Good News of our Lord Jesus,",
                "who will pay the penalty: eternal destruction from the face of the Lord and from the glory of his might,",
                "when he comes to be glorified in his saints, and to be admired among all those who have believed (because our testimony to you was believed) in that day.",
                "To this end we also pray always for you, that our God may count you worthy of your calling, and fulfill every desire of goodness and work of faith, with power;",
                "that the name of our Lord Jesus may be glorified in you, and you in him, according to the grace of our God and the Lord Jesus Christ."
            ],
            2: [
                "Now, brothers, concerning the coming of our Lord Jesus Christ, and our gathering together to him, we ask you",
                "not to be quickly shaken in your mind, nor yet be troubled, either by spirit, or by word, or by letter as from us, saying that the day of Christ had come.",
                "Let no one deceive you in any way. For it will not be, unless the departure comes first, and the man of sin is revealed, the son of destruction,",
                "he who opposes and exalts himself against all that is called God or that is worshiped; so that he sits as God in the temple of God, setting himself up as God.",
                "Don\u2019t you remember that, when I was still with you, I told you these things?",
                "Now you know what is restraining him, to the end that he may be revealed in his own season.",
                "For the mystery of lawlessness already works. Only there is one who restrains now, until he is taken out of the way.",
                "Then the lawless one will be revealed, whom the Lord will kill with the breath of his mouth, and destroy by the manifestation of his coming;",
                "even he whose coming is according to the working of Satan with all power and signs and lying wonders,",
                "and with all deception of wickedness for those who are being lost, because they didn\u2019t receive the love of the truth, that they might be saved.",
                "Because of this, God sends them a working of error, that they should believe a lie;",
                "that they all might be judged who didn\u2019t believe the truth, but had pleasure in unrighteousness.",
                "But we are bound to always give thanks to God for you, brothers loved by the Lord, because God chose you from the beginning for salvation through sanctification of the Spirit and belief in the truth;",
                "to which he called you through our Good News, for the obtaining of the glory of our Lord Jesus Christ.",
                "So then, brothers, stand firm, and hold the traditions which you were taught by us, whether by word, or by letter.",
                "Now our Lord Jesus Christ himself, and God our Father, who loved us and gave us eternal comfort and good hope through grace,",
                "comfort your hearts and establish you in every good work and word."
            ],
            3: [
                "Finally, brothers, pray for us, that the word of the Lord may spread rapidly and be glorified, even as also with you;",
                "and that we may be delivered from unreasonable and evil men; for not all have faith.",
                "But the Lord is faithful, who will establish you, and guard you from the evil one.",
                "We have confidence in the Lord concerning you, that you both do and will do the things we command.",
                "May the Lord direct your hearts into the love of God, and into the patience of Christ.",
                "Now we command you, brothers, in the name of our Lord Jesus Christ, that you withdraw yourselves from every brother who walks in rebellion, and not after the tradition which they received from us.",
                "For you know how you ought to imitate us. For we didn\u2019t behave ourselves rebelliously among you,",
                "neither did we eat bread from anyone\u2019s hand without paying for it, but in labor and travail worked night and day, that we might not burden any of you;",
                "not because we don\u2019t have the right, but to make ourselves an example to you, that you should imitate us.",
                "For even when we were with you, we commanded you this: \"If anyone will not work, neither let him eat.\"",
                "For we hear of some who walk among you in rebellion, who don\u2019t work at all, but are busybodies.",
                "Now those who are that way, we command and exhort in the Lord Jesus Christ, that with quietness they work, and eat their own bread.",
                "But you, brothers, don\u2019t be weary in doing well.",
                "If any man doesn\u2019t obey our word in this letter, note that man, that you have no company with him, to the end that he may be ashamed.",
                "Don\u2019t count him as an enemy, but admonish him as a brother.",
                "Now may the Lord of peace himself give you peace at all times in all ways. The Lord be with you all.",
                "The greeting of me, Paul, with my own hand, which is the sign in every letter: this is how I write.",
                "The grace of our Lord Jesus Christ be with you all. Amen."
            ]
        },
        54: {
            1: [
                "Paul, an apostle of Christ Jesus according to the commandment of God our Savior, and Christ Jesus our hope;",
                "to Timothy, my true child in faith: Grace, mercy, and peace, from God our Father and Christ Jesus our Lord.",
                "As I urged you when I was going into Macedonia, stay at Ephesus that you might command certain men not to teach a different doctrine,",
                "neither to pay attention to myths and endless genealogies, which cause disputes, rather than God\u2019s stewardship, which is in faith\u2014",
                "but the goal of this command is love, out of a pure heart and a good conscience and sincere faith;",
                "from which things some, having missed the mark, have turned aside to vain talking;",
                "desiring to be teachers of the law, though they understand neither what they say, nor about what they strongly affirm.",
                "But we know that the law is good, if a man uses it lawfully,",
                "as knowing this, that law is not made for a righteous man, but for the lawless and insubordinate, for the ungodly and sinners, for the unholy and profane, for murderers of fathers and murderers of mothers, for manslayers,",
                "for the sexually immoral, for homosexuals, for slave-traders, for liars, for perjurers, and for any other thing contrary to the sound doctrine;",
                "according to the Good News of the glory of the blessed God, which was committed to my trust.",
                "And I thank him who enabled me, Christ Jesus our Lord, because he counted me faithful, appointing me to service;",
                "although I was before a blasphemer, a persecutor, and insolent. However, I obtained mercy, because I did it ignorantly in unbelief.",
                "The grace of our Lord abounded exceedingly with faith and love which is in Christ Jesus.",
                "The saying is faithful and worthy of all acceptance, that Christ Jesus came into the world to save sinners; of whom I am chief.",
                "However, for this cause I obtained mercy, that in me first, Jesus Christ might display all his patience, for an example of those who were going to believe in him for eternal life.",
                "Now to the King eternal, immortal, invisible, to God who alone is wise, be honor and glory forever and ever. Amen.",
                "This instruction I commit to you, my child Timothy, according to the prophecies which led the way to you, that by them you may wage the good warfare;",
                "holding faith and a good conscience; which some having thrust away made a shipwreck concerning the faith;",
                "of whom is Hymenaeus and Alexander; whom I delivered to Satan, that they might be taught not to blaspheme."
            ],
            2: [
                "I exhort therefore, first of all, that petitions, prayers, intercessions, and givings of thanks, be made for all men:",
                "for kings and all who are in high places; that we may lead a tranquil and quiet life in all godliness and reverence.",
                "For this is good and acceptable in the sight of God our Savior;",
                "who desires all people to be saved and come to full knowledge of the truth.",
                "For there is one God, and one mediator between God and men, the man Christ Jesus,",
                "who gave himself as a ransom for all; the testimony in its own times;",
                "to which I was appointed a preacher and an apostle (I am telling the truth in Christ, not lying), a teacher of the Gentiles in faith and truth.",
                "I desire therefore that the men in every place pray, lifting up holy hands without anger and doubting.",
                "In the same way, that women also adorn themselves in decent clothing, with modesty and propriety; not just with braided hair, gold, pearls, or expensive clothing;",
                "but (which becomes women professing godliness) with good works.",
                "Let a woman learn in quietness with all subjection.",
                "But I don\u2019t permit a woman to teach, nor to exercise authority over a man, but to be in quietness.",
                "For Adam was first formed, then Eve.",
                "Adam wasn\u2019t deceived, but the woman, being deceived, has fallen into disobedience;",
                "but she will be saved through her childbearing, if they continue in faith, love, and sanctification with sobriety."
            ],
            3: [
                "This is a faithful saying: if a man seeks the office of an overseer, he desires a good work.",
                "The overseer therefore must be without reproach, the husband of one wife, temperate, sensible, modest, hospitable, good at teaching;",
                "not a drinker, not violent, not greedy for money, but gentle, not quarrelsome, not covetous;",
                "one who rules his own house well, having children in subjection with all reverence;",
                "(but if a man doesn\u2019t know how to rule his own house, how will he take care of the assembly of God?)",
                "not a new convert, lest being puffed up he fall into the same condemnation as the devil.",
                "Moreover he must have good testimony from those who are outside, to avoid falling into reproach and the snare of the devil.",
                "Servants, in the same way, must be reverent, not double-tongued, not addicted to much wine, not greedy for money;",
                "holding the mystery of the faith in a pure conscience.",
                "Let them also first be tested; then let them serve if they are blameless.",
                "Their wives in the same way must be reverent, not slanderers, temperate, faithful in all things.",
                "Let servants be husbands of one wife, ruling their children and their own houses well.",
                "For those who have served well gain for themselves a good standing, and great boldness in the faith which is in Christ Jesus.",
                "These things I write to you, hoping to come to you shortly;",
                "but if I wait long, that you may know how men ought to behave themselves in the house of God, which is the assembly of the living God, the pillar and ground of the truth.",
                "Without controversy, the mystery of godliness is great: God was revealed in the flesh, justified in the spirit, seen by angels, preached among the nations, believed on in the world, and received up in glory."
            ],
            4: [
                "But the Spirit says expressly that in later times some will fall away from the faith, paying attention to seducing spirits and doctrines of demons,",
                "through the hypocrisy of men who speak lies, branded in their own conscience as with a hot iron;",
                "forbidding marriage and commanding to abstain from foods which God created to be received with thanksgiving by those who believe and know the truth.",
                "For every creature of God is good, and nothing is to be rejected, if it is received with thanksgiving.",
                "For it is sanctified through the word of God and prayer.",
                "If you instruct the brothers of these things, you will be a good servant of Christ Jesus, nourished in the words of the faith, and of the good doctrine which you have followed.",
                "But refuse profane and old wives\u2019 fables. Exercise yourself toward godliness.",
                "For bodily exercise has some value, but godliness has value in all things, having the promise of the life which is now, and of that which is to come.",
                "This saying is faithful and worthy of all acceptance.",
                "For to this end we both labor and suffer reproach, because we have set our trust in the living God, who is the Savior of all men, especially of those who believe.",
                "Command and teach these things.",
                "Let no man despise your youth; but be an example to those who believe, in word, in your way of life, in love, in spirit, in faith, and in purity.",
                "Until I come, pay attention to reading, to exhortation, and to teaching.",
                "Don\u2019t neglect the gift that is in you, which was given to you by prophecy, with the laying on of the hands of the elders.",
                "Be diligent in these things. Give yourself wholly to them, that your progress may be revealed to all.",
                "Pay attention to yourself, and to your teaching. Continue in these things, for in doing this you will save both yourself and those who hear you."
            ],
            5: [
                "Don\u2019t rebuke an older man, but exhort him as a father; the younger men as brothers;",
                "the elder women as mothers; the younger as sisters, in all purity.",
                "Honor widows who are widows indeed.",
                "But if any widow has children or grandchildren, let them learn first to show piety towards their own family, and to repay their parents, for this is acceptable in the sight of God.",
                "Now she who is a widow indeed, and desolate, has her hope set on God, and continues in petitions and prayers night and day.",
                "But she who gives herself to pleasure is dead while she lives.",
                "Also command these things, that they may be without reproach.",
                "But if anyone doesn\u2019t provide for his own, and especially his own household, he has denied the faith, and is worse than an unbeliever.",
                "Let no one be enrolled as a widow under sixty years old, having been the wife of one man,",
                "being approved by good works, if she has brought up children, if she has been hospitable to strangers, if she has washed the saints\u2019 feet, if she has relieved the afflicted, and if she has diligently followed every good work.",
                "But refuse younger widows, for when they have grown wanton against Christ, they desire to marry;",
                "having condemnation, because they have rejected their first pledge.",
                "Besides, they also learn to be idle, going about from house to house. Not only idle, but also gossips and busybodies, saying things which they ought not.",
                "I desire therefore that the younger widows marry, bear children, rule the household, and give no occasion to the adversary for insulting.",
                "For already some have turned aside after Satan.",
                "If any man or woman who believes has widows, let them relieve them, and don\u2019t let the assembly be burdened; that it might relieve those who are widows indeed.",
                "Let the elders who rule well be counted worthy of double honor, especially those who labor in the word and in teaching.",
                "For the Scripture says, \"You shall not muzzle the ox when it treads out the grain.\" And, \"The laborer is worthy of his wages.\"",
                "Don\u2019t receive an accusation against an elder, except at the word of two or three witnesses.",
                "Those who sin, reprove in the sight of all, that the rest also may be in fear.",
                "I command you in the sight of God, and Christ Jesus, and the chosen angels, that you observe these things without prejudice, doing nothing by partiality.",
                "Lay hands hastily on no one, neither be a participant in other men\u2019s sins. Keep yourself pure.",
                "Be no longer a drinker of water only, but use a little wine for your stomach\u2019s sake and your frequent infirmities.",
                "Some men\u2019s sins are evident, preceding them to judgment, and some also follow later.",
                "In the same way also there are good works that are obvious, and those that are otherwise can\u2019t be hidden."
            ],
            6: [
                "Let as many as are bondservants under the yoke count their own masters worthy of all honor, that the name of God and the doctrine not be blasphemed.",
                "Those who have believing masters, let them not despise them, because they are brothers, but rather let them serve them, because those who partake of the benefit are believing and beloved. Teach and exhort these things.",
                "If anyone teaches a different doctrine, and doesn\u2019t consent to sound words, the words of our Lord Jesus Christ, and to the doctrine which is according to godliness,",
                "he is conceited, knowing nothing, but obsessed with arguments, disputes, and word battles, from which come envy, strife, insulting, evil suspicions,",
                "constant friction of people of corrupt minds and destitute of the truth, who suppose that godliness is a means of gain. Withdraw yourself from such.",
                "But godliness with contentment is great gain.",
                "For we brought nothing into the world, and we certainly can\u2019t carry anything out.",
                "But having food and clothing, we will be content with that.",
                "But those who are determined to be rich fall into a temptation and a snare and many foolish and harmful lusts, such as drown men in ruin and destruction.",
                "For the love of money is a root of all kinds of evil. Some have been led astray from the faith in their greed, and have pierced themselves through with many sorrows.",
                "But you, man of God, flee these things, and follow after righteousness, godliness, faith, love, patience, and gentleness.",
                "Fight the good fight of faith. Lay hold of the eternal life to which you were called, and you confessed the good confession in the sight of many witnesses.",
                "I command you before God, who gives life to all things, and before Christ Jesus, who before Pontius Pilate testified the good confession,",
                "that you keep the commandment without spot, blameless, until the appearing of our Lord Jesus Christ;",
                "which in its own times he will show, who is the blessed and only Ruler, the King of kings, and Lord of lords;",
                "who alone has immortality, dwelling in unapproachable light; whom no man has seen, nor can see: to whom be honor and eternal power. Amen.",
                "Charge those who are rich in this present world that they not be haughty, nor have their hope set on the uncertainty of riches, but on the living God, who richly provides us with everything to enjoy;",
                "that they do good, that they be rich in good works, that they be ready to distribute, willing to communicate;",
                "laying up in store for themselves a good foundation against the time to come, that they may lay hold of eternal life.",
                "Timothy, guard that which is committed to you, turning away from the empty chatter and oppositions of the knowledge which is falsely so called;",
                "which some professing have erred concerning the faith. Grace be with you. Amen."
            ]
        },
        55: {
            1: [
                "Paul, an apostle of Jesus Christ through the will of God, according to the promise of the life which is in Christ Jesus,",
                "to Timothy, my beloved child: Grace, mercy, and peace, from God the Father and Christ Jesus our Lord.",
                "I thank God, whom I serve as my forefathers did, with a pure conscience. How unceasing is my memory of you in my petitions, night and day",
                "longing to see you, remembering your tears, that I may be filled with joy;",
                "having been reminded of the sincere faith that is in you; which lived first in your grandmother Lois, and your mother Eunice, and, I am persuaded, in you also.",
                "For this cause, I remind you that you should stir up the gift of God which is in you through the laying on of my hands.",
                "For God didn\u2019t give us a spirit of fear, but of power, love, and self-control.",
                "Therefore don\u2019t be ashamed of the testimony of our Lord, nor of me his prisoner; but endure hardship for the Good News according to the power of God,",
                "who saved us and called us with a holy calling, not according to our works, but according to his own purpose and grace, which was given to us in Christ Jesus before times eternal,",
                "but has now been revealed by the appearing of our Savior, Christ Jesus, who abolished death, and brought life and immortality to light through the Good News.",
                "For this, I was appointed as a preacher, an apostle, and a teacher of the Gentiles.",
                "For this cause I also suffer these things. Yet I am not ashamed, for I know him whom I have believed, and I am persuaded that he is able to guard that which I have committed to him against that day.",
                "Hold the pattern of sound words which you have heard from me, in faith and love which is in Christ Jesus.",
                "That good thing which was committed to you, guard through the Holy Spirit who dwells in us.",
                "This you know, that all who are in Asia turned away from me; of whom are Phygelus and Hermogenes.",
                "May the Lord grant mercy to the house of Onesiphorus, for he often refreshed me, and was not ashamed of my chain,",
                "but when he was in Rome, he sought me diligently, and found me",
                "(the Lord grant to him to find the Lord\u2019s mercy in that day); and in how many things he served at Ephesus, you know very well."
            ],
            2: [
                "You therefore, my child, be strengthened in the grace that is in Christ Jesus.",
                "The things which you have heard from me among many witnesses, commit the same to faithful men, who will be able to teach others also.",
                "You therefore must endure hardship, as a good soldier of Christ Jesus.",
                "No soldier on duty entangles himself in the affairs of life, that he may please him who enrolled him as a soldier.",
                "Also, if anyone competes in athletics, he isn\u2019t crowned unless he has competed by the rules.",
                "The farmers who labor must be the first to get a share of the crops.",
                "Consider what I say, and may the Lord give you understanding in all things.",
                "Remember Jesus Christ, risen from the dead, of the seed of David, according to my Good News,",
                "in which I suffer hardship to the point of chains as a criminal. But God\u2019s word isn\u2019t chained.",
                "Therefore I endure all things for the chosen ones\u2019 sake, that they also may obtain the salvation which is in Christ Jesus with eternal glory.",
                "This saying is faithful: \"For if we died with him, we will also live with him.",
                "If we endure, we will also reign with him. If we deny him, he also will deny us.",
                "If we are faithless, he remains faithful. He can\u2019t deny himself.\"",
                "Remind them of these things, charging them in the sight of the Lord, that they don\u2019t argue about words, to no profit, to the subverting of those who hear.",
                "Give diligence to present yourself approved by God, a workman who doesn\u2019t need to be ashamed, properly handling the Word of Truth.",
                "But shun empty chatter, for it will go further in ungodliness,",
                "and those words will consume like gangrene, of whom is Hymenaeus and Philetus;",
                "men who have erred concerning the truth, saying that the resurrection is already past, and overthrowing the faith of some.",
                "However God\u2019s firm foundation stands, having this seal, \"The Lord knows those who are his,\" and, \"Let every one who names the name of the Lord depart from unrighteousness.\"",
                "Now in a large house there are not only vessels of gold and of silver, but also of wood and of clay. Some are for honor, and some for dishonor.",
                "If anyone therefore purges himself from these, he will be a vessel for honor, sanctified, and suitable for the master\u2019s use, prepared for every good work.",
                "Flee from youthful lusts; but pursue righteousness, faith, love, and peace with those who call on the Lord out of a pure heart.",
                "But refuse foolish and ignorant questionings, knowing that they generate strife.",
                "The Lord\u2019s servant must not quarrel, but be gentle towards all, able to teach, patient,",
                "in gentleness correcting those who oppose him: perhaps God may give them repentance leading to a full knowledge of the truth,",
                "and they may recover themselves out of the devil\u2019s snare, having been taken captive by him to his will."
            ],
            3: [
                "But know this, that in the last days, grievous times will come.",
                "For men will be lovers of self, lovers of money, boastful, arrogant, blasphemers, disobedient to parents, unthankful, unholy,",
                "without natural affection, unforgiving, slanderers, without self-control, fierce, no lovers of good,",
                "traitors, headstrong, conceited, lovers of pleasure rather than lovers of God;",
                "holding a form of godliness, but having denied its power. Turn away from these, also.",
                "For some of these are people who creep into houses, and take captive gullible women loaded down with sins, led away by various lusts,",
                "always learning, and never able to come to the knowledge of the truth.",
                "Even as Jannes and Jambres opposed Moses, so do these also oppose the truth; men corrupted in mind, who concerning the faith, are rejected.",
                "But they will proceed no further. For their folly will be evident to all men, as theirs also came to be.",
                "But you did follow my teaching, conduct, purpose, faith, patience, love, steadfastness,",
                "persecutions, and sufferings: those things that happened to me at Antioch, Iconium, and Lystra. I endured those persecutions. Out of them all the Lord delivered me.",
                "Yes, and all who desire to live godly in Christ Jesus will suffer persecution.",
                "But evil men and impostors will grow worse and worse, deceiving and being deceived.",
                "But you remain in the things which you have learned and have been assured of, knowing from whom you have learned them.",
                "From infancy, you have known the holy Scriptures which are able to make you wise for salvation through faith, which is in Christ Jesus.",
                "Every Scripture is God-breathed and profitable for teaching, for reproof, for correction, and for instruction in righteousness,",
                "that the man of God may be complete, thoroughly equipped for every good work."
            ],
            4: [
                "I command you therefore before God and the Lord Jesus Christ, who will judge the living and the dead at his appearing and his Kingdom:",
                "preach the word; be urgent in season and out of season; reprove, rebuke, and exhort, with all patience and teaching.",
                "For the time will come when they will not listen to the sound doctrine, but, having itching ears, will heap up for themselves teachers after their own lusts;",
                "and will turn away their ears from the truth, and turn aside to fables.",
                "But you be sober in all things, suffer hardship, do the work of an evangelist, and fulfill your ministry.",
                "For I am already being offered, and the time of my departure has come.",
                "I have fought the good fight. I have finished the course. I have kept the faith.",
                "From now on, there is stored up for me the crown of righteousness, which the Lord, the righteous judge, will give to me on that day; and not to me only, but also to all those who have loved his appearing.",
                "Be diligent to come to me soon,",
                "for Demas left me, having loved this present world, and went to Thessalonica; Crescens to Galatia, and Titus to Dalmatia.",
                "Only Luke is with me. Take Mark, and bring him with you, for he is useful to me for service.",
                "But I sent Tychicus to Ephesus.",
                "Bring the cloak that I left at Troas with Carpus when you come, and the books, especially the parchments.",
                "Alexander, the coppersmith, did much evil to me. The Lord will repay him according to his works,",
                "of whom you also must beware; for he greatly opposed our words.",
                "At my first defense, no one came to help me, but all left me. May it not be held against them.",
                "But the Lord stood by me, and strengthened me, that through me the message might be fully proclaimed, and that all the Gentiles might hear; and I was delivered out of the mouth of the lion.",
                "And the Lord will deliver me from every evil work, and will preserve me for his heavenly Kingdom; to whom be the glory forever and ever. Amen.",
                "Greet Prisca and Aquila, and the house of Onesiphorus.",
                "Erastus remained at Corinth, but I left Trophimus at Miletus sick.",
                "Be diligent to come before winter. Eubulus salutes you, as do Pudens, Linus, Claudia, and all the brothers.",
                "The Lord Jesus Christ be with your spirit. Grace be with you. Amen."
            ]
        },
        56: {
            1: [
                "Paul, a servant of God, and an apostle of Jesus Christ, according to the faith of God\u2019s chosen ones, and the knowledge of the truth which is according to godliness,",
                "in hope of eternal life, which God, who can\u2019t lie, promised before time began;",
                "but in his own time revealed his word in the message with which I was entrusted according to the commandment of God our Savior;",
                "to Titus, my true child according to a common faith: Grace, mercy, and peace from God the Father and the Lord Jesus Christ our Savior.",
                "I left you in Crete for this reason, that you would set in order the things that were lacking, and appoint elders in every city, as I directed you;",
                "if anyone is blameless, the husband of one wife, having children who believe, who are not accused of loose or unruly behavior.",
                "For the overseer must be blameless, as God\u2019s steward; not self-pleasing, not easily angered, not given to wine, not violent, not greedy for dishonest gain;",
                "but given to hospitality, a lover of good, sober minded, fair, holy, self-controlled;",
                "holding to the faithful word which is according to the teaching, that he may be able to exhort in the sound doctrine, and to convict those who contradict him.",
                "For there are also many unruly men, vain talkers and deceivers, especially those of the circumcision,",
                "whose mouths must be stopped; men who overthrow whole houses, teaching things which they ought not, for dishonest gain\u2019s sake.",
                "One of them, a prophet of their own, said, \"Cretans are always liars, evil beasts, and idle gluttons.\"",
                "This testimony is true. For this cause, reprove them sharply, that they may be sound in the faith,",
                "not paying attention to Jewish fables and commandments of men who turn away from the truth.",
                "To the pure, all things are pure; but to those who are defiled and unbelieving, nothing is pure; but both their mind and their conscience are defiled.",
                "They profess that they know God, but by their works they deny him, being abominable, disobedient, and unfit for any good work."
            ],
            2: [
                "But say the things which fit sound doctrine,",
                "that older men should be temperate, sensible, sober minded, sound in faith, in love, and in patience:",
                "and that older women likewise be reverent in behavior, not slanderers nor enslaved to much wine, teachers of that which is good;",
                "that they may train the young women to love their husbands, to love their children,",
                "to be sober minded, chaste, workers at home, kind, being in subjection to their own husbands, that God\u2019s word may not be blasphemed.",
                "Likewise, exhort the younger men to be sober minded;",
                "in all things showing yourself an example of good works; in your teaching showing integrity, seriousness, incorruptibility,",
                "and soundness of speech that can\u2019t be condemned; that he who opposes you may be ashamed, having no evil thing to say about us.",
                "Exhort servants to be in subjection to their own masters, and to be well-pleasing in all things; not contradicting;",
                "not stealing, but showing all good fidelity; that they may adorn the doctrine of God, our Savior, in all things.",
                "For the grace of God has appeared, bringing salvation to all men,",
                "instructing us to the intent that, denying ungodliness and worldly lusts, we would live soberly, righteously, and godly in this present world;",
                "looking for the blessed hope and appearing of the glory of our great God and Savior, Jesus Christ;",
                "who gave himself for us, that he might redeem us from all iniquity, and purify for himself a people for his own possession, zealous for good works.",
                "Say these things and exhort and reprove with all authority. Let no man despise you."
            ],
            3: [
                "Remind them to be in subjection to rulers and to authorities, to be obedient, to be ready for every good work,",
                "to speak evil of no one, not to be contentious, to be gentle, showing all humility toward all men.",
                "For we were also once foolish, disobedient, deceived, serving various lusts and pleasures, living in malice and envy, hateful, and hating one another.",
                "But when the kindness of God our Savior and his love toward mankind appeared,",
                "not by works of righteousness, which we did ourselves, but according to his mercy, he saved us, through the washing of regeneration and renewing by the Holy Spirit,",
                "whom he poured out on us richly, through Jesus Christ our Savior;",
                "that, being justified by his grace, we might be made heirs according to the hope of eternal life.",
                "This saying is faithful, and concerning these things I desire that you affirm confidently, so that those who have believed God may be careful to maintain good works. These things are good and profitable to men;",
                "but shun foolish questionings, genealogies, strife, and disputes about the law; for they are unprofitable and vain.",
                "Avoid a factious man after a first and second warning;",
                "knowing that such a one is perverted, and sins, being self-condemned.",
                "When I send Artemas to you, or Tychicus, be diligent to come to me to Nicopolis, for I have determined to winter there.",
                "Send Zenas, the lawyer, and Apollos on their journey speedily, that nothing may be lacking for them.",
                "Let our people also learn to maintain good works for necessary uses, that they may not be unfruitful.",
                "All who are with me greet you. Greet those who love us in faith. Grace be with you all. Amen."
            ]
        },
        57: {
            1: [
                "Paul, a prisoner of Christ Jesus, and Timothy our brother, to Philemon, our beloved fellow worker,",
                "to the beloved Apphia, to Archippus, our fellow soldier, and to the assembly in your house:",
                "Grace to you and peace from God our Father and the Lord Jesus Christ.",
                "I thank my God always, making mention of you in my prayers,",
                "hearing of your love, and of the faith which you have toward the Lord Jesus, and toward all the saints;",
                "that the fellowship of your faith may become effective, in the knowledge of every good thing which is in us in Christ Jesus.",
                "For we have much joy and comfort in your love, because the hearts of the saints have been refreshed through you, brother.",
                "Therefore, though I have all boldness in Christ to command you that which is appropriate,",
                "yet for love\u2019s sake I rather beg, being such a one as Paul, the aged, but also a prisoner of Jesus Christ.",
                "I beg you for my child, whom I have become the father of in my chains, Onesimus,",
                "who once was useless to you, but now is useful to you and to me.",
                "I am sending him back. Therefore receive him, that is, my own heart,",
                "whom I desired to keep with me, that on your behalf he might serve me in my chains for the Good News.",
                "But I was willing to do nothing without your consent, that your goodness would not be as of necessity, but of free will.",
                "For perhaps he was therefore separated from you for a while, that you would have him forever,",
                "no longer as a slave, but more than a slave, a beloved brother, especially to me, but how much rather to you, both in the flesh and in the Lord.",
                "If then you count me a partner, receive him as you would receive me.",
                "But if he has wronged you at all, or owes you anything, put that to my account.",
                "I, Paul, write this with my own hand: I will repay it (not to mention to you that you owe to me even your own self besides).",
                "Yes, brother, let me have joy from you in the Lord. Refresh my heart in the Lord.",
                "Having confidence in your obedience, I write to you, knowing that you will do even beyond what I say.",
                "Also, prepare a guest room for me, for I hope that through your prayers I will be restored to you.",
                "Epaphras, my fellow prisoner in Christ Jesus, greets you,",
                "as do Mark, Aristarchus, Demas, and Luke, my fellow workers.",
                "The grace of our Lord Jesus Christ be with your spirit. Amen."
            ]
        },
        58: {
            1: [
                "God, having in the past spoken to the fathers through the prophets at many times and in various ways,",
                "has at the end of these days spoken to us by his Son, whom he appointed heir of all things, through whom also he made the worlds.",
                "His Son is the radiance of his glory, the very image of his substance, and upholding all things by the word of his power, when he had by himself made purification for our sins, sat down on the right hand of the Majesty on high;",
                "having become so much better than the angels, as he has inherited a more excellent name than they have.",
                "For to which of the angels did he say at any time, \"You are my Son. Today have I become your father?\" and again, \"I will be to him a Father, and he will be to me a Son?\"",
                "Again, when he brings in the firstborn into the world he says, \"Let all the angels of God worship him.\"",
                "Of the angels he says, \"Who makes his angels winds, and his servants a flame of fire.\"",
                "But of the Son he says, \"Your throne, O God, is forever and ever. The scepter of uprightness is the scepter of your Kingdom.",
                "You have loved righteousness, and hated iniquity; therefore God, your God, has anointed you with the oil of gladness above your fellows.\"",
                "And, \"You, Lord, in the beginning, laid the foundation of the earth. The heavens are the works of your hands.",
                "They will perish, but you continue. They all will grow old like a garment does.",
                "As a mantle, you will roll them up, and they will be changed; but you are the same. Your years will not fail.\"",
                "But which of the angels has he told at any time, \"Sit at my right hand, until I make your enemies the footstool of your feet?\"",
                "Aren\u2019t they all serving spirits, sent out to do service for the sake of those who will inherit salvation?"
            ],
            2: [
                "Therefore we ought to pay greater attention to the things that were heard, lest perhaps we drift away.",
                "For if the word spoken through angels proved steadfast, and every transgression and disobedience received a just recompense;",
                "how will we escape if we neglect so great a salvation\u2014which at the first having been spoken through the Lord, was confirmed to us by those who heard;",
                "God also testifying with them, both by signs and wonders, by various works of power, and by gifts of the Holy Spirit, according to his own will?",
                "For he didn\u2019t subject the world to come, of which we speak, to angels.",
                "But one has somewhere testified, saying, \"What is man, that you think of him? Or the son of man, that you care for him?",
                "You made him a little lower than the angels. You crowned him with glory and honor.",
                "You have put all things in subjection under his feet.\" For in that he subjected all things to him, he left nothing that is not subject to him. But now we don\u2019t see all things subjected to him, yet.",
                "But we see him who has been made a little lower than the angels, Jesus, because of the suffering of death crowned with glory and honor, that by the grace of God he should taste of death for everyone.",
                "For it became him, for whom are all things, and through whom are all things, in bringing many children to glory, to make the author of their salvation perfect through sufferings.",
                "For both he who sanctifies and those who are sanctified are all from one, for which cause he is not ashamed to call them brothers,",
                "saying, \"I will declare your name to my brothers. In the midst of the congregation I will sing your praise.\"",
                "Again, \"I will put my trust in him.\" Again, \"Behold, here I am with the children whom God has given me.\"",
                "Since then the children have shared in flesh and blood, he also himself in the same way partook of the same, that through death he might bring to nothing him who had the power of death, that is, the devil,",
                "and might deliver all of them who through fear of death were all their lifetime subject to bondage.",
                "For most certainly, he doesn\u2019t give help to angels, but he gives help to the seed of Abraham.",
                "Therefore he was obligated in all things to be made like his brothers, that he might become a merciful and faithful high priest in things pertaining to God, to make atonement for the sins of the people.",
                "For in that he himself has suffered being tempted, he is able to help those who are tempted."
            ],
            3: [
                "Therefore, holy brothers, partakers of a heavenly calling, consider the Apostle and High Priest of our confession, Jesus;",
                "who was faithful to him who appointed him, as also was Moses in all his house.",
                "For he has been counted worthy of more glory than Moses, inasmuch as he who built the house has more honor than the house.",
                "For every house is built by someone; but he who built all things is God.",
                "Moses indeed was faithful in all his house as a servant, for a testimony of those things which were afterward to be spoken,",
                "but Christ is faithful as a Son over his house; whose house we are, if we hold fast our confidence and the glorying of our hope firm to the end.",
                "Therefore, even as the Holy Spirit says, \"Today if you will hear his voice,",
                "don\u2019t harden your hearts, as in the rebellion, like as in the day of the trial in the wilderness,",
                "where your fathers tested me by proving me, and saw my works for forty years.",
                "Therefore I was displeased with that generation, and said, \u2018They always err in their heart, but they didn\u2019t know my ways;\u2019",
                "as I swore in my wrath, \u2018They will not enter into my rest.\u2019\"",
                "Beware, brothers, lest perhaps there be in any one of you an evil heart of unbelief, in falling away from the living God;",
                "but exhort one another day by day, so long as it is called \"today;\" lest any one of you be hardened by the deceitfulness of sin.",
                "For we have become partakers of Christ, if we hold fast the beginning of our confidence firm to the end:",
                "while it is said, \"Today if you will hear his voice, don\u2019t harden your hearts, as in the rebellion.\"",
                "For who, when they heard, rebelled? No, didn\u2019t all those who came out of Egypt by Moses?",
                "With whom was he displeased forty years? Wasn\u2019t it with those who sinned, whose bodies fell in the wilderness?",
                "To whom did he swear that they wouldn\u2019t enter into his rest, but to those who were disobedient?",
                "We see that they were not able to enter in because of unbelief."
            ],
            4: [
                "Let us fear therefore, lest perhaps anyone of you should seem to have come short of a promise of entering into his rest.",
                "For indeed we have had good news preached to us, even as they also did, but the word they heard didn\u2019t profit them, because it wasn\u2019t mixed with faith by those who heard.",
                "For we who have believed do enter into that rest, even as he has said, \"As I swore in my wrath, they will not enter into my rest;\" although the works were finished from the foundation of the world.",
                "For he has said this somewhere about the seventh day, \"God rested on the seventh day from all his works;\"",
                "and in this place again, \"They will not enter into my rest.\"",
                "Seeing therefore it remains that some should enter therein, and they to whom the good news was before preached failed to enter in because of disobedience,",
                "he again defines a certain day, today, saying through David so long a time afterward (just as has been said), \"Today if you will hear his voice, don\u2019t harden your hearts.\"",
                "For if Joshua had given them rest, he would not have spoken afterward of another day.",
                "There remains therefore a Sabbath rest for the people of God.",
                "For he who has entered into his rest has himself also rested from his works, as God did from his.",
                "Let us therefore give diligence to enter into that rest, lest anyone fall after the same example of disobedience.",
                "For the word of God is living, and active, and sharper than any two-edged sword, and piercing even to the dividing of soul and spirit, of both joints and marrow, and is able to discern the thoughts and intentions of the heart.",
                "There is no creature that is hidden from his sight, but all things are naked and laid open before the eyes of him with whom we have to do.",
                "Having then a great high priest, who has passed through the heavens, Jesus, the Son of God, let us hold tightly to our confession.",
                "For we don\u2019t have a high priest who can\u2019t be touched with the feeling of our infirmities, but one who has been in all points tempted like we are, yet without sin.",
                "Let us therefore draw near with boldness to the throne of grace, that we may receive mercy, and may find grace for help in time of need."
            ],
            5: [
                "For every high priest, being taken from among men, is appointed for men in things pertaining to God, that he may offer both gifts and sacrifices for sins.",
                "The high priest can deal gently with those who are ignorant and going astray, because he himself is also surrounded with weakness.",
                "Because of this, he must offer sacrifices for sins for the people, as well as for himself.",
                "Nobody takes this honor on himself, but he is called by God, just like Aaron was.",
                "So also Christ didn\u2019t glorify himself to be made a high priest, but it was he who said to him, \"You are my Son. Today I have become your father.\"",
                "As he says also in another place, \"You are a priest forever, after the order of Melchizedek.\"",
                "He, in the days of his flesh, having offered up prayers and petitions with strong crying and tears to him who was able to save him from death, and having been heard for his godly fear,",
                "though he was a Son, yet learned obedience by the things which he suffered.",
                "Having been made perfect, he became to all of those who obey him the author of eternal salvation,",
                "named by God a high priest after the order of Melchizedek.",
                "About him we have many words to say, and hard to interpret, seeing you have become dull of hearing.",
                "For although by this time you should be teachers, you again need to have someone teach you the rudiments of the first principles of the oracles of God. You have come to need milk, and not solid food.",
                "For everyone who lives on milk is not experienced in the word of righteousness, for he is a baby.",
                "But solid food is for those who are full grown, who by reason of use have their senses exercised to discern good and evil."
            ],
            6: [
                "Therefore leaving the teaching of the first principles of Christ, let us press on to perfection\u2014not laying again a foundation of repentance from dead works, of faith toward God,",
                "of the teaching of baptisms, of laying on of hands, of resurrection of the dead, and of eternal judgment.",
                "This will we do, if God permits.",
                "For concerning those who were once enlightened and tasted of the heavenly gift, and were made partakers of the Holy Spirit,",
                "and tasted the good word of God, and the powers of the age to come,",
                "and then fell away, it is impossible to renew them again to repentance; seeing they crucify the Son of God for themselves again, and put him to open shame.",
                "For the land which has drunk the rain that comes often on it, and brings forth a crop suitable for them for whose sake it is also tilled, receives blessing from God;",
                "but if it bears thorns and thistles, it is rejected and near being cursed, whose end is to be burned.",
                "But, beloved, we are persuaded of better things for you, and things that accompany salvation, even though we speak like this.",
                "For God is not unrighteous, so as to forget your work and the labor of love which you showed toward his name, in that you served the saints, and still do serve them.",
                "We desire that each one of you may show the same diligence to the fullness of hope even to the end,",
                "that you won\u2019t be sluggish, but imitators of those who through faith and patience inherited the promises.",
                "For when God made a promise to Abraham, since he could swear by none greater, he swore by himself,",
                "saying, \"Surely blessing I will bless you, and multiplying I will multiply you.\"",
                "Thus, having patiently endured, he obtained the promise.",
                "For men indeed swear by a greater one, and in every dispute of theirs the oath is final for confirmation.",
                "In this way God, being determined to show more abundantly to the heirs of the promise the immutability of his counsel, interposed with an oath;",
                "that by two immutable things, in which it is impossible for God to lie, we may have a strong encouragement, who have fled for refuge to take hold of the hope set before us.",
                "This hope we have as an anchor of the soul, a hope both sure and steadfast and entering into that which is within the veil;",
                "where as a forerunner Jesus entered for us, having become a high priest forever after the order of Melchizedek."
            ],
            7: [
                "For this Melchizedek, king of Salem, priest of God Most High, who met Abraham returning from the slaughter of the kings and blessed him,",
                "to whom also Abraham divided a tenth part of all (being first, by interpretation, king of righteousness, and then also king of Salem, which is king of peace;",
                "without father, without mother, without genealogy, having neither beginning of days nor end of life, but made like the Son of God), remains a priest continually.",
                "Now consider how great this man was, to whom even Abraham, the patriarch, gave a tenth out of the best spoils.",
                "They indeed of the sons of Levi who receive the priest\u2019s office have a commandment to take tithes of the people according to the law, that is, of their brothers, though these have come out of the body of Abraham,",
                "but he whose genealogy is not counted from them has accepted tithes from Abraham, and has blessed him who has the promises.",
                "But without any dispute the lesser is blessed by the greater.",
                "Here people who die receive tithes, but there one receives tithes of whom it is testified that he lives.",
                "We can say that through Abraham even Levi, who receives tithes, has paid tithes,",
                "for he was yet in the body of his father when Melchizedek met him.",
                "Now if there were perfection through the Levitical priesthood (for under it the people have received the law), what further need was there for another priest to arise after the order of Melchizedek, and not be called after the order of Aaron?",
                "For the priesthood being changed, there is of necessity a change made also in the law.",
                "For he of whom these things are said belongs to another tribe, from which no one has officiated at the altar.",
                "For it is evident that our Lord has sprung out of Judah, about which tribe Moses spoke nothing concerning priesthood.",
                "This is yet more abundantly evident, if after the likeness of Melchizedek there arises another priest,",
                "who has been made, not after the law of a fleshly commandment, but after the power of an endless life:",
                "for it is testified, \"You are a priest forever, according to the order of Melchizedek.\"",
                "For there is an annulling of a foregoing commandment because of its weakness and uselessness",
                "(for the law made nothing perfect), and a bringing in of a better hope, through which we draw near to God.",
                "Inasmuch as he was not made priest without the taking of an oath",
                "(for they indeed have been made priests without an oath), but he with an oath by him that says of him, \"The Lord swore and will not change his mind, \u2018You are a priest forever, according to the order of Melchizedek.\u2019\"",
                "By so much, Jesus has become the collateral of a better covenant.",
                "Many, indeed, have been made priests, because they are hindered from continuing by death.",
                "But he, because he lives forever, has his priesthood unchangeable.",
                "Therefore he is also able to save to the uttermost those who draw near to God through him, seeing that he lives forever to make intercession for them.",
                "For such a high priest was fitting for us: holy, guiltless, undefiled, separated from sinners, and made higher than the heavens;",
                "who doesn\u2019t need, like those high priests, to offer up sacrifices daily, first for his own sins, and then for the sins of the people. For he did this once for all, when he offered up himself.",
                "For the law appoints men as high priests who have weakness, but the word of the oath which came after the law appoints a Son forever who has been perfected."
            ],
            8: [
                "Now in the things which we are saying, the main point is this. We have such a high priest, who sat down on the right hand of the throne of the Majesty in the heavens,",
                "a servant of the sanctuary, and of the true tabernacle, which the Lord pitched, not man.",
                "For every high priest is appointed to offer both gifts and sacrifices. Therefore it is necessary that this high priest also have something to offer.",
                "For if he were on earth, he would not be a priest at all, seeing there are priests who offer the gifts according to the law;",
                "who serve a copy and shadow of the heavenly things, even as Moses was warned by God when he was about to make the tabernacle, for he said, \"See, you shall make everything according to the pattern that was shown to you on the mountain.\"",
                "But now he has obtained a more excellent ministry, by so much as he is also the mediator of a better covenant, which on better promises has been given as law.",
                "For if that first covenant had been faultless, then no place would have been sought for a second.",
                "For finding fault with them, he said, \"Behold, the days come,\" says the Lord, \"that I will make a new covenant with the house of Israel and with the house of Judah;",
                "not according to the covenant that I made with their fathers, in the day that I took them by the hand to lead them out of the land of Egypt; for they didn\u2019t continue in my covenant, and I disregarded them,\" says the Lord.",
                "\"For this is the covenant that I will make with the house of Israel. After those days,\" says the Lord; \"I will put my laws into their mind, I will also write them on their heart. I will be their God, and they will be my people.",
                "They will not teach every man his fellow citizen, and every man his brother, saying, \u2018Know the Lord,\u2019 for all will know me, from their least to their greatest.",
                "For I will be merciful to their unrighteousness. I will remember their sins and lawless deeds no more.\"",
                "In that he says, \"A new covenant,\" he has made the first old. But that which is becoming old and grows aged is near to vanishing away."
            ],
            9: [
                "Now indeed even the first covenant had ordinances of divine service, and an earthly sanctuary.",
                "For a tabernacle was prepared. In the first part were the lampstand, the table, and the show bread; which is called the Holy Place.",
                "After the second veil was the tabernacle which is called the Holy of Holies,",
                "having a golden altar of incense, and the ark of the covenant overlaid on all sides with gold, in which was a golden pot holding the manna, Aaron\u2019s rod that budded, and the tablets of the covenant;",
                "and above it cherubim of glory overshadowing the mercy seat, of which things we can\u2019t speak now in detail.",
                "Now these things having been thus prepared, the priests go in continually into the first tabernacle, accomplishing the services,",
                "but into the second the high priest alone, once in the year, not without blood, which he offers for himself, and for the errors of the people.",
                "The Holy Spirit is indicating this, that the way into the Holy Place wasn\u2019t yet revealed while the first tabernacle was still standing;",
                "which is a symbol of the present age, where gifts and sacrifices are offered that are incapable, concerning the conscience, of making the worshipper perfect;",
                "being only (with meats and drinks and various washings) fleshly ordinances, imposed until a time of reformation.",
                "But Christ having come as a high priest of the coming good things, through the greater and more perfect tabernacle, not made with hands, that is to say, not of this creation,",
                "nor yet through the blood of goats and calves, but through his own blood, entered in once for all into the Holy Place, having obtained eternal redemption.",
                "For if the blood of goats and bulls, and the ashes of a heifer sprinkling those who have been defiled, sanctify to the cleanness of the flesh:",
                "how much more will the blood of Christ, who through the eternal Spirit offered himself without blemish to God, cleanse your conscience from dead works to serve the living God?",
                "For this reason he is the mediator of a new covenant, since a death has occurred for the redemption of the transgressions that were under the first covenant, that those who have been called may receive the promise of the eternal inheritance.",
                "For where a last will and testament is, there must of necessity be the death of him who made it.",
                "For a will is in force where there has been death, for it is never in force while he who made it lives.",
                "Therefore even the first covenant has not been dedicated without blood.",
                "For when every commandment had been spoken by Moses to all the people according to the law, he took the blood of the calves and the goats, with water and scarlet wool and hyssop, and sprinkled both the book itself and all the people,",
                "saying, \"This is the blood of the covenant which God has commanded you.\"",
                "Moreover he sprinkled the tabernacle and all the vessels of the ministry in the same way with the blood.",
                "According to the law, nearly everything is cleansed with blood, and apart from shedding of blood there is no remission.",
                "It was necessary therefore that the copies of the things in the heavens should be cleansed with these; but the heavenly things themselves with better sacrifices than these.",
                "For Christ hasn\u2019t entered into holy places made with hands, which are representations of the true, but into heaven itself, now to appear in the presence of God for us;",
                "nor yet that he should offer himself often, as the high priest enters into the holy place year by year with blood not his own,",
                "or else he must have suffered often since the foundation of the world. But now once at the end of the ages, he has been revealed to put away sin by the sacrifice of himself.",
                "Inasmuch as it is appointed for men to die once, and after this, judgment,",
                "so Christ also, having been offered once to bear the sins of many, will appear a second time, without sin, to those who are eagerly waiting for him for salvation."
            ],
            10: [
                "For the law, having a shadow of the good to come, not the very image of the things, can never with the same sacrifices year by year, which they offer continually, make perfect those who draw near.",
                "Or else wouldn\u2019t they have ceased to be offered, because the worshippers, having been once cleansed, would have had no more consciousness of sins?",
                "But in those sacrifices there is a yearly reminder of sins.",
                "For it is impossible that the blood of bulls and goats should take away sins.",
                "Therefore when he comes into the world, he says, \"Sacrifice and offering you didn\u2019t desire, but you prepared a body for me;",
                "You had no pleasure in whole burnt offerings and sacrifices for sin.",
                "Then I said, \u2018Behold, I have come (in the scroll of the book it is written of me) to do your will, O God.\u2019\"",
                "Previously saying, \"Sacrifices and offerings and whole burnt offerings and sacrifices for sin you didn\u2019t desire, neither had pleasure in them\" (those which are offered according to the law),",
                "then he has said, \"Behold, I have come to do your will.\" He takes away the first, that he may establish the second,",
                "by which will we have been sanctified through the offering of the body of Jesus Christ once for all.",
                "Every priest indeed stands day by day serving and often offering the same sacrifices, which can never take away sins,",
                "but he, when he had offered one sacrifice for sins forever, sat down on the right hand of God;",
                "from that time waiting until his enemies are made the footstool of his feet.",
                "For by one offering he has perfected forever those who are being sanctified.",
                "The Holy Spirit also testifies to us, for after saying,",
                "\"This is the covenant that I will make with them: \u2018After those days,\u2019 says the Lord, \u2018I will put my laws on their heart, I will also write them on their mind;\u2019\" then he says,",
                "\"I will remember their sins and their iniquities no more.\"",
                "Now where remission of these is, there is no more offering for sin.",
                "Having therefore, brothers, boldness to enter into the holy place by the blood of Jesus,",
                "by the way which he dedicated for us, a new and living way, through the veil, that is to say, his flesh;",
                "and having a great priest over the house of God,",
                "let\u2019s draw near with a true heart in fullness of faith, having our hearts sprinkled from an evil conscience, and having our body washed with pure water,",
                "let us hold fast the confession of our hope without wavering; for he who promised is faithful.",
                "Let us consider how to provoke one another to love and good works,",
                "not forsaking our own assembling together, as the custom of some is, but exhorting one another; and so much the more, as you see the Day approaching.",
                "For if we sin willfully after we have received the knowledge of the truth, there remains no more a sacrifice for sins,",
                "but a certain fearful expectation of judgment, and a fierceness of fire which will devour the adversaries.",
                "A man who disregards Moses\u2019 law dies without compassion on the word of two or three witnesses.",
                "How much worse punishment, do you think, will he be judged worthy of, who has trodden under foot the Son of God, and has counted the blood of the covenant with which he was sanctified an unholy thing, and has insulted the Spirit of grace?",
                "For we know him who said, \"Vengeance belongs to me,\" says the Lord, \"I will repay.\" Again, \"The Lord will judge his people.\"",
                "It is a fearful thing to fall into the hands of the living God.",
                "But remember the former days, in which, after you were enlightened, you endured a great struggle with sufferings;",
                "partly, being exposed to both reproaches and oppressions; and partly, becoming partakers with those who were treated so.",
                "For you both had compassion on me in my chains, and joyfully accepted the plundering of your possessions, knowing that you have for yourselves a better possession and an enduring one in the heavens.",
                "Therefore don\u2019t throw away your boldness, which has a great reward.",
                "For you need endurance so that, having done the will of God, you may receive the promise.",
                "\"In a very little while, he who comes will come, and will not wait.",
                "But the righteous will live by faith. If he shrinks back, my soul has no pleasure in him.\"",
                "But we are not of those who shrink back to destruction, but of those who have faith to the saving of the soul."
            ],
            11: [
                "Now faith is assurance of things hoped for, proof of things not seen.",
                "For by this, the elders obtained testimony.",
                "By faith, we understand that the universe has been framed by the word of God, so that what is seen has not been made out of things which are visible.",
                "By faith, Abel offered to God a more excellent sacrifice than Cain, through which he had testimony given to him that he was righteous, God testifying with respect to his gifts; and through it he, being dead, still speaks.",
                "By faith, Enoch was taken away, so that he wouldn\u2019t see death, and he was not found, because God translated him. For he has had testimony given to him that before his translation he had been well pleasing to God.",
                "Without faith it is impossible to be well pleasing to him, for he who comes to God must believe that he exists, and that he is a rewarder of those who seek him.",
                "By faith, Noah, being warned about things not yet seen, moved with godly fear, prepared a ship for the saving of his house, through which he condemned the world, and became heir of the righteousness which is according to faith.",
                "By faith, Abraham, when he was called, obeyed to go out to the place which he was to receive for an inheritance. He went out, not knowing where he went.",
                "By faith, he lived as an alien in the land of promise, as in a land not his own, dwelling in tents, with Isaac and Jacob, the heirs with him of the same promise.",
                "For he looked for the city which has the foundations, whose builder and maker is God.",
                "By faith, even Sarah herself received power to conceive, and she bore a child when she was past age, since she counted him faithful who had promised.",
                "Therefore as many as the stars of the sky in multitude, and as innumerable as the sand which is by the sea shore, were fathered by one man, and him as good as dead.",
                "These all died in faith, not having received the promises, but having seen them and embraced them from afar, and having confessed that they were strangers and pilgrims on the earth.",
                "For those who say such things make it clear that they are seeking a country of their own.",
                "If indeed they had been thinking of that country from which they went out, they would have had enough time to return.",
                "But now they desire a better country, that is, a heavenly one. Therefore God is not ashamed of them, to be called their God, for he has prepared a city for them.",
                "By faith, Abraham, being tested, offered up Isaac. Yes, he who had gladly received the promises was offering up his one and only son;",
                "even he to whom it was said, \"In Isaac will your seed be called;\"",
                "concluding that God is able to raise up even from the dead. Figuratively speaking, he also did receive him back from the dead.",
                "By faith, Isaac blessed Jacob and Esau, even concerning things to come.",
                "By faith, Jacob, when he was dying, blessed each of the sons of Joseph, and worshiped, leaning on the top of his staff.",
                "By faith, Joseph, when his end was near, made mention of the departure of the children of Israel; and gave instructions concerning his bones.",
                "By faith, Moses, when he was born, was hidden for three months by his parents, because they saw that he was a beautiful child, and they were not afraid of the king\u2019s commandment.",
                "By faith, Moses, when he had grown up, refused to be called the son of Pharaoh\u2019s daughter,",
                "choosing rather to share ill treatment with God\u2019s people, than to enjoy the pleasures of sin for a time;",
                "accounting the reproach of Christ greater riches than the treasures of Egypt; for he looked to the reward.",
                "By faith, he left Egypt, not fearing the wrath of the king; for he endured, as seeing him who is invisible.",
                "By faith, he kept the Passover, and the sprinkling of the blood, that the destroyer of the firstborn should not touch them.",
                "By faith, they passed through the Red Sea as on dry land. When the Egyptians tried to do so, they were swallowed up.",
                "By faith, the walls of Jericho fell down, after they had been encircled for seven days.",
                "By faith, Rahab the prostitute, didn\u2019t perish with those who were disobedient, having received the spies in peace.",
                "What more shall I say? For the time would fail me if I told of Gideon, Barak, Samson, Jephthah, David, Samuel, and the prophets;",
                "who, through faith subdued kingdoms, worked out righteousness, obtained promises, stopped the mouths of lions,",
                "quenched the power of fire, escaped the edge of the sword, from weakness were made strong, grew mighty in war, and caused foreign armies to flee.",
                "Women received their dead by resurrection. Others were tortured, not accepting their deliverance, that they might obtain a better resurrection.",
                "Others were tried by mocking and scourging, yes, moreover by bonds and imprisonment.",
                "They were stoned. They were sawn apart. They were tempted. They were slain with the sword. They went around in sheep skins and in goat skins; being destitute, afflicted, ill-treated",
                "(of whom the world was not worthy), wandering in deserts, mountains, caves, and the holes of the earth.",
                "These all, having had testimony given to them through their faith, didn\u2019t receive the promise,",
                "God having provided some better thing concerning us, so that apart from us they should not be made perfect."
            ],
            12: [
                "Therefore let us also, seeing we are surrounded by so great a cloud of witnesses, lay aside every weight and the sin which so easily entangles us, and let us run with patience the race that is set before us,",
                "looking to Jesus, the author and perfecter of faith, who for the joy that was set before him endured the cross, despising its shame, and has sat down at the right hand of the throne of God.",
                "For consider him who has endured such contradiction of sinners against himself, that you don\u2019t grow weary, fainting in your souls.",
                "You have not yet resisted to blood, striving against sin;",
                "and you have forgotten the exhortation which reasons with you as with children, \"My son, don\u2019t take lightly the chastening of the Lord, nor faint when you are reproved by him;",
                "For whom the Lord loves, he chastens, and scourges every son whom he receives.\"",
                "It is for discipline that you endure. God deals with you as with children, for what son is there whom his father doesn\u2019t discipline?",
                "But if you are without discipline, of which all have been made partakers, then are you illegitimate, and not children.",
                "Furthermore, we had the fathers of our flesh to chasten us, and we paid them respect. Shall we not much rather be in subjection to the Father of spirits, and live?",
                "For they indeed, for a few days, punished us as seemed good to them; but he for our profit, that we may be partakers of his holiness.",
                "All chastening seems for the present to be not joyous but grievous; yet afterward it yields the peaceful fruit of righteousness to those who have been exercised thereby.",
                "Therefore, lift up the hands that hang down and the feeble knees,",
                "and make straight paths for your feet, so that which is lame may not be dislocated, but rather be healed.",
                "Follow after peace with all men, and the sanctification without which no man will see the Lord,",
                "looking carefully lest there be any man who falls short of the grace of God; lest any root of bitterness springing up trouble you, and many be defiled by it;",
                "lest there be any sexually immoral person, or profane person, like Esau, who sold his birthright for one meal.",
                "For you know that even when he afterward desired to inherit the blessing, he was rejected, for he found no place for a change of mind though he sought it diligently with tears.",
                "For you have not come to a mountain that might be touched, and that burned with fire, and to blackness, darkness, storm,",
                "the sound of a trumpet, and the voice of words; which those who heard it begged that not one more word should be spoken to them,",
                "for they could not stand that which was commanded, \"If even an animal touches the mountain, it shall be stoned;\"",
                "and so fearful was the appearance, that Moses said, \"I am terrified and trembling.\"",
                "But you have come to Mount Zion, and to the city of the living God, the heavenly Jerusalem, and to innumerable multitudes of angels,",
                "to the general assembly and assembly of the firstborn who are enrolled in heaven, to God the Judge of all, to the spirits of just men made perfect,",
                "to Jesus, the mediator of a new covenant, and to the blood of sprinkling that speaks better than that of Abel.",
                "See that you don\u2019t refuse him who speaks. For if they didn\u2019t escape when they refused him who warned on the earth, how much more will we not escape who turn away from him who warns from heaven,",
                "whose voice shook the earth then, but now he has promised, saying, \"Yet once more I will shake not only the earth, but also the heavens.\"",
                "This phrase, \"Yet once more,\" signifies the removing of those things that are shaken, as of things that have been made, that those things which are not shaken may remain.",
                "Therefore, receiving a Kingdom that can\u2019t be shaken, let us have grace, through which we serve God acceptably, with reverence and awe,",
                "for our God is a consuming fire."
            ],
            13: [
                "Let brotherly love continue.",
                "Don\u2019t forget to show hospitality to strangers, for in doing so, some have entertained angels without knowing it.",
                "Remember those who are in bonds, as bound with them; and those who are ill-treated, since you are also in the body.",
                "Let marriage be held in honor among all, and let the bed be undefiled: but God will judge the sexually immoral and adulterers.",
                "Be free from the love of money, content with such things as you have, for he has said, \"I will in no way leave you, neither will I in any way forsake you.\"",
                "So that with good courage we say, \"The Lord is my helper. I will not fear. What can man do to me?\"",
                "Remember your leaders, men who spoke to you the word of God, and considering the results of their conduct, imitate their faith.",
                "Jesus Christ is the same yesterday, today, and forever.",
                "Don\u2019t be carried away by various and strange teachings, for it is good that the heart be established by grace, not by food, through which those who were so occupied were not benefited.",
                "We have an altar from which those who serve the holy tabernacle have no right to eat.",
                "For the bodies of those animals, whose blood is brought into the holy place by the high priest as an offering for sin, are burned outside of the camp.",
                "Therefore Jesus also, that he might sanctify the people through his own blood, suffered outside of the gate.",
                "Let us therefore go out to him outside of the camp, bearing his reproach.",
                "For we don\u2019t have here an enduring city, but we seek that which is to come.",
                "Through him, then, let us offer up a sacrifice of praise to God continually, that is, the fruit of lips which proclaim allegiance to his name.",
                "But don\u2019t forget to be doing good and sharing, for with such sacrifices God is well pleased.",
                "Obey your leaders and submit to them, for they watch on behalf of your souls, as those who will give account, that they may do this with joy, and not with groaning, for that would be unprofitable for you.",
                "Pray for us, for we are persuaded that we have a good conscience, desiring to live honorably in all things.",
                "I strongly urge you to do this, that I may be restored to you sooner.",
                "Now may the God of peace, who brought again from the dead the great shepherd of the sheep with the blood of an eternal covenant, our Lord Jesus,",
                "make you complete in every good work to do his will, working in you that which is well pleasing in his sight, through Jesus Christ, to whom be the glory forever and ever. Amen.",
                "But I exhort you, brothers, endure the word of exhortation, for I have written to you in few words.",
                "Know that our brother Timothy has been freed, with whom, if he comes shortly, I will see you.",
                "Greet all of your leaders and all the saints. The Italians greet you.",
                "Grace be with you all. Amen."
            ]
        },
        59: {
            1: [
                "James, a servant of God and of the Lord Jesus Christ, to the twelve tribes which are in the Dispersion: Greetings.",
                "Count it all joy, my brothers, when you fall into various temptations,",
                "knowing that the testing of your faith produces endurance.",
                "Let endurance have its perfect work, that you may be perfect and complete, lacking in nothing.",
                "But if any of you lacks wisdom, let him ask of God, who gives to all liberally and without reproach; and it will be given to him.",
                "But let him ask in faith, without any doubting, for he who doubts is like a wave of the sea, driven by the wind and tossed.",
                "For let that man not think that he will receive anything from the Lord.",
                "He is a double-minded man, unstable in all his ways.",
                "But let the brother in humble circumstances glory in his high position;",
                "and the rich, in that he is made humble, because like the flower in the grass, he will pass away.",
                "For the sun arises with the scorching wind, and withers the grass, and the flower in it falls, and the beauty of its appearance perishes. So also will the rich man fade away in his pursuits.",
                "Blessed is the man who endures temptation, for when he has been approved, he will receive the crown of life, which the Lord promised to those who love him.",
                "Let no man say when he is tempted, \"I am tempted by God,\" for God can\u2019t be tempted by evil, and he himself tempts no one.",
                "But each one is tempted, when he is drawn away by his own lust, and enticed.",
                "Then the lust, when it has conceived, bears sin; and the sin, when it is full grown, brings forth death.",
                "Don\u2019t be deceived, my beloved brothers.",
                "Every good gift and every perfect gift is from above, coming down from the Father of lights, with whom can be no variation, nor turning shadow.",
                "Of his own will he brought us forth by the word of truth, that we should be a kind of first fruits of his creatures.",
                "So, then, my beloved brothers, let every man be swift to hear, slow to speak, and slow to anger;",
                "for the anger of man doesn\u2019t produce the righteousness of God.",
                "Therefore, putting away all filthiness and overflowing of wickedness, receive with humility the implanted word, which is able to save your souls.",
                "But be doers of the word, and not only hearers, deluding your own selves.",
                "For if anyone is a hearer of the word and not a doer, he is like a man looking at his natural face in a mirror;",
                "for he sees himself, and goes away, and immediately forgets what kind of man he was.",
                "But he who looks into the perfect law of freedom, and continues, not being a hearer who forgets, but a doer of the work, this man will be blessed in what he does.",
                "If anyone among you thinks himself to be religious while he doesn\u2019t bridle his tongue, but deceives his heart, this man\u2019s religion is worthless.",
                "Pure religion and undefiled before our God and Father is this: to visit the fatherless and widows in their affliction, and to keep oneself unstained by the world."
            ],
            2: [
                "My brothers, don\u2019t hold the faith of our Lord Jesus Christ of glory with partiality.",
                "For if a man with a gold ring, in fine clothing, comes into your synagogue, and a poor man in filthy clothing also comes in;",
                "and you pay special attention to him who wears the fine clothing, and say, \"Sit here in a good place;\" and you tell the poor man, \"Stand there,\" or \"Sit by my footstool;\"",
                "haven\u2019t you shown partiality among yourselves, and become judges with evil thoughts?",
                "Listen, my beloved brothers. Didn\u2019t God choose those who are poor in this world to be rich in faith, and heirs of the Kingdom which he promised to those who love him?",
                "But you have dishonored the poor man. Don\u2019t the rich oppress you, and personally drag you before the courts?",
                "Don\u2019t they blaspheme the honorable name by which you are called?",
                "However, if you fulfill the royal law, according to the Scripture, \"You shall love your neighbor as yourself,\" you do well.",
                "But if you show partiality, you commit sin, being convicted by the law as transgressors.",
                "For whoever keeps the whole law, and yet stumbles in one point, he has become guilty of all.",
                "For he who said, \"Do not commit adultery,\" also said, \"Do not commit murder.\" Now if you do not commit adultery, but murder, you have become a transgressor of the law.",
                "So speak, and so do, as men who are to be judged by a law of freedom.",
                "For judgment is without mercy to him who has shown no mercy. Mercy triumphs over judgment.",
                "What good is it, my brothers, if a man says he has faith, but has no works? Can faith save him?",
                "And if a brother or sister is naked and in lack of daily food,",
                "and one of you tells them, \"Go in peace, be warmed and filled;\" and yet you didn\u2019t give them the things the body needs, what good is it?",
                "Even so faith, if it has no works, is dead in itself.",
                "Yes, a man will say, \"You have faith, and I have works.\" Show me your faith without works, and I by my works will show you my faith.",
                "You believe that God is one. You do well. The demons also believe, and shudder.",
                "But do you want to know, vain man, that faith apart from works is dead?",
                "Wasn\u2019t Abraham our father justified by works, in that he offered up Isaac his son on the altar?",
                "You see that faith worked with his works, and by works faith was perfected;",
                "and the Scripture was fulfilled which says, \"Abraham believed God, and it was accounted to him as righteousness;\" and he was called the friend of God.",
                "You see then that by works, a man is justified, and not only by faith.",
                "In the same way, wasn\u2019t Rahab the prostitute also justified by works, in that she received the messengers, and sent them out another way?",
                "For as the body apart from the spirit is dead, even so faith apart from works is dead."
            ],
            3: [
                "Let not many of you be teachers, my brothers, knowing that we will receive heavier judgment.",
                "For in many things we all stumble. If anyone doesn\u2019t stumble in word, the same is a perfect man, able to bridle the whole body also.",
                "Indeed, we put bits into the horses\u2019 mouths so that they may obey us, and we guide their whole body.",
                "Behold, the ships also, though they are so big and are driven by fierce winds, are yet guided by a very small rudder, wherever the pilot desires.",
                "So the tongue is also a little member, and boasts great things. See how a small fire can spread to a large forest!",
                "And the tongue is a fire. The world of iniquity among our members is the tongue, which defiles the whole body, and sets on fire the course of nature, and is set on fire by Gehenna.",
                "For every kind of animal, bird, creeping thing, and thing in the sea, is tamed, and has been tamed by mankind.",
                "But nobody can tame the tongue. It is a restless evil, full of deadly poison.",
                "With it we bless our God and Father, and with it we curse men, who are made in the image of God.",
                "Out of the same mouth comes forth blessing and cursing. My brothers, these things ought not to be so.",
                "Does a spring send out from the same opening fresh and bitter water?",
                "Can a fig tree, my brothers, yield olives, or a vine figs? Thus no spring yields both salt water and fresh water.",
                "Who is wise and understanding among you? Let him show by his good conduct that his deeds are done in gentleness of wisdom.",
                "But if you have bitter jealousy and selfish ambition in your heart, don\u2019t boast and don\u2019t lie against the truth.",
                "This wisdom is not that which comes down from above, but is earthly, sensual, and demonic.",
                "For where jealousy and selfish ambition are, there is confusion and every evil deed.",
                "But the wisdom that is from above is first pure, then peaceful, gentle, reasonable, full of mercy and good fruits, without partiality, and without hypocrisy.",
                "Now the fruit of righteousness is sown in peace by those who make peace."
            ],
            4: [
                "Where do wars and fightings among you come from? Don\u2019t they come from your pleasures that war in your members?",
                "You lust, and don\u2019t have. You kill, covet, and can\u2019t obtain. You fight and make war. You don\u2019t have, because you don\u2019t ask.",
                "You ask, and don\u2019t receive, because you ask with wrong motives, so that you may spend it for your pleasures.",
                "You adulterers and adulteresses, don\u2019t you know that friendship with the world is enmity with God? Whoever therefore wants to be a friend of the world makes himself an enemy of God.",
                "Or do you think that the Scripture says in vain, \"The Spirit who lives in us yearns jealously\"?",
                "But he gives more grace. Therefore it says, \"God resists the proud, but gives grace to the humble.\"",
                "Be subject therefore to God. But resist the devil, and he will flee from you.",
                "Draw near to God, and he will draw near to you. Cleanse your hands, you sinners; and purify your hearts, you double-minded.",
                "Lament, mourn, and weep. Let your laughter be turned to mourning, and your joy to gloom.",
                "Humble yourselves in the sight of the Lord, and he will exalt you.",
                "Don\u2019t speak against one another, brothers. He who speaks against a brother and judges his brother, speaks against the law and judges the law. But if you judge the law, you are not a doer of the law, but a judge.",
                "Only one is the lawgiver, who is able to save and to destroy. But who are you to judge another?",
                "Come now, you who say, \"Today or tomorrow let\u2019s go into this city, and spend a year there, trade, and make a profit.\"",
                "Whereas you don\u2019t know what your life will be like tomorrow. For what is your life? For you are a vapor, that appears for a little time, and then vanishes away.",
                "For you ought to say, \"If the Lord wills, we will both live, and do this or that.\"",
                "But now you glory in your boasting. All such boasting is evil.",
                "To him therefore who knows to do good, and doesn\u2019t do it, to him it is sin."
            ],
            5: [
                "Come now, you rich, weep and howl for your miseries that are coming on you.",
                "Your riches are corrupted and your garments are moth-eaten.",
                "Your gold and your silver are corroded, and their corrosion will be for a testimony against you, and will eat your flesh like fire. You have laid up your treasure in the last days.",
                "Behold, the wages of the laborers who mowed your fields, which you have kept back by fraud, cry out, and the cries of those who reaped have entered into the ears of the Lord of Armies.",
                "You have lived delicately on the earth, and taken your pleasure. You have nourished your hearts as in a day of slaughter.",
                "You have condemned, you have murdered the righteous one. He doesn\u2019t resist you.",
                "Be patient therefore, brothers, until the coming of the Lord. Behold, the farmer waits for the precious fruit of the earth, being patient over it, until it receives the early and late rain.",
                "You also be patient. Establish your hearts, for the coming of the Lord is at hand.",
                "Don\u2019t grumble, brothers, against one another, so that you won\u2019t be judged. Behold, the judge stands at the door.",
                "Take, brothers, for an example of suffering and of patience, the prophets who spoke in the name of the Lord.",
                "Behold, we call them blessed who endured. You have heard of the patience of Job, and have seen the Lord in the outcome, and how the Lord is full of compassion and mercy.",
                "But above all things, my brothers, don\u2019t swear, neither by heaven, nor by the earth, nor by any other oath; but let your \"yes\" be \"yes,\" and your \"no,\" \"no;\" so that you don\u2019t fall into hypocrisy.",
                "Is any among you suffering? Let him pray. Is any cheerful? Let him sing praises.",
                "Is any among you sick? Let him call for the elders of the assembly, and let them pray over him, anointing him with oil in the name of the Lord,",
                "and the prayer of faith will heal him who is sick, and the Lord will raise him up. If he has committed sins, he will be forgiven.",
                "Confess your offenses to one another, and pray for one another, that you may be healed. The insistent prayer of a righteous person is powerfully effective.",
                "Elijah was a man with a nature like ours, and he prayed earnestly that it might not rain, and it didn\u2019t rain on the earth for three years and six months.",
                "He prayed again, and the sky gave rain, and the earth brought forth its fruit.",
                "Brothers, if any among you wanders from the truth, and someone turns him back,",
                "let him know that he who turns a sinner from the error of his way will save a soul from death, and will cover a multitude of sins."
            ]
        },
        60: {
            1: [
                "Peter, an apostle of Jesus Christ, to the chosen ones who are living as foreigners in the Dispersion in Pontus, Galatia, Cappadocia, Asia, and Bithynia,",
                "according to the foreknowledge of God the Father, in sanctification of the Spirit, that you may obey Jesus Christ and be sprinkled with his blood: Grace to you and peace be multiplied.",
                "Blessed be the God and Father of our Lord Jesus Christ, who according to his great mercy became our father again to a living hope through the resurrection of Jesus Christ from the dead,",
                "to an incorruptible and undefiled inheritance that doesn\u2019t fade away, reserved in Heaven for you,",
                "who by the power of God are guarded through faith for a salvation ready to be revealed in the last time.",
                "Wherein you greatly rejoice, though now for a little while, if need be, you have been put to grief in various trials,",
                "that the proof of your faith, which is more precious than gold that perishes even though it is tested by fire, may be found to result in praise, glory, and honor at the revelation of Jesus Christ\u2014",
                "whom not having known you love; in whom, though now you don\u2019t see him, yet believing, you rejoice greatly with joy unspeakable and full of glory\u2014",
                "receiving the result of your faith, the salvation of your souls.",
                "Concerning this salvation, the prophets sought and searched diligently, who prophesied of the grace that would come to you,",
                "searching for who or what kind of time the Spirit of Christ, which was in them, pointed to, when he predicted the sufferings of Christ, and the glories that would follow them.",
                "To them it was revealed, that not to themselves, but to you, they ministered these things, which now have been announced to you through those who preached the Good News to you by the Holy Spirit sent out from heaven; which things angels desire to look into.",
                "Therefore, prepare your minds for action, be sober and set your hope fully on the grace that will be brought to you at the revelation of Jesus Christ\u2014",
                "as children of obedience, not conforming yourselves according to your former lusts as in your ignorance,",
                "but just as he who called you is holy, you yourselves also be holy in all of your behavior;",
                "because it is written, \"You shall be holy; for I am holy.\"",
                "If you call on him as Father, who without respect of persons judges according to each man\u2019s work, pass the time of your living as foreigners here in reverent fear:",
                "knowing that you were redeemed, not with corruptible things, with silver or gold, from the useless way of life handed down from your fathers,",
                "but with precious blood, as of a faultless and pure lamb, the blood of Christ;",
                "who was foreknown indeed before the foundation of the world, but was revealed at the end of times for your sake,",
                "who through him are believers in God, who raised him from the dead, and gave him glory; so that your faith and hope might be in God.",
                "Seeing you have purified your souls in your obedience to the truth through the Spirit in sincere brotherly affection, love one another from the heart fervently:",
                "having been born again, not of corruptible seed, but of incorruptible, through the word of God, which lives and remains forever.",
                "For, \"All flesh is like grass, and all of man\u2019s glory like the flower in the grass. The grass withers, and its flower falls;",
                "but the Lord\u2019s word endures forever.\" This is the word of Good News which was preached to you."
            ],
            2: [
                "Putting away therefore all wickedness, all deceit, hypocrisies, envies, and all evil speaking,",
                "as newborn babies, long for the pure milk of the Word, that you may grow thereby,",
                "if indeed you have tasted that the Lord is gracious:",
                "coming to him, a living stone, rejected indeed by men, but chosen by God, precious.",
                "You also, as living stones, are built up as a spiritual house, to be a holy priesthood, to offer up spiritual sacrifices, acceptable to God through Jesus Christ.",
                "Because it is contained in Scripture, \"Behold, I lay in Zion a chief cornerstone, chosen, and precious: He who believes in him will not be disappointed.\"",
                "For you who believe therefore is the honor, but for those who are disobedient, \"The stone which the builders rejected, has become the chief cornerstone,\"",
                "and, \"a stone of stumbling, and a rock of offense.\" For they stumble at the word, being disobedient, to which also they were appointed.",
                "But you are a chosen race, a royal priesthood, a holy nation, a people for God\u2019s own possession, that you may proclaim the excellence of him who called you out of darkness into his marvelous light:",
                "who in time past were no people, but now are God\u2019s people, who had not obtained mercy, but now have obtained mercy.",
                "Beloved, I beg you as foreigners and pilgrims, to abstain from fleshly lusts, which war against the soul;",
                "having good behavior among the nations, so in that of which they speak against you as evildoers, they may by your good works, which they see, glorify God in the day of visitation.",
                "Therefore subject yourselves to every ordinance of man for the Lord\u2019s sake: whether to the king, as supreme;",
                "or to governors, as sent by him for vengeance on evildoers and for praise to those who do well.",
                "For this is the will of God, that by well-doing you should put to silence the ignorance of foolish men:",
                "as free, and not using your freedom for a cloak of wickedness, but as bondservants of God.",
                "Honor all men. Love the brotherhood. Fear God. Honor the king.",
                "Servants, be in subjection to your masters with all fear; not only to the good and gentle, but also to the wicked.",
                "For it is commendable if someone endures pain, suffering unjustly, because of conscience toward God.",
                "For what glory is it if, when you sin, you patiently endure beating? But if, when you do well, you patiently endure suffering, this is commendable with God.",
                "For to this you were called, because Christ also suffered for us, leaving you an example, that you should follow his steps,",
                "who did not sin, \"neither was deceit found in his mouth.\"",
                "Who, when he was cursed, didn\u2019t curse back. When he suffered, didn\u2019t threaten, but committed himself to him who judges righteously;",
                "who his own self bore our sins in his body on the tree, that we, having died to sins, might live to righteousness; by whose stripes you were healed.",
                "For you were going astray like sheep; but now have returned to the Shepherd and Overseer of your souls."
            ],
            3: [
                "In the same way, wives, be in subjection to your own husbands; so that, even if any don\u2019t obey the Word, they may be won by the behavior of their wives without a word;",
                "seeing your pure behavior in fear.",
                "Let your beauty be not just the outward adorning of braiding the hair, and of wearing jewels of gold, or of putting on fine clothing;",
                "but in the hidden person of the heart, in the incorruptible adornment of a gentle and quiet spirit, which is in the sight of God very precious.",
                "For this is how the holy women before, who hoped in God also adorned themselves, being in subjection to their own husbands:",
                "as Sarah obeyed Abraham, calling him lord, whose children you now are, if you do well, and are not put in fear by any terror.",
                "You husbands, in the same way, live with your wives according to knowledge, giving honor to the woman, as to the weaker vessel, as being also joint heirs of the grace of life; that your prayers may not be hindered.",
                "Finally, be all like-minded, compassionate, loving as brothers, tenderhearted, courteous,",
                "not rendering evil for evil, or insult for insult; but instead blessing; knowing that to this were you called, that you may inherit a blessing.",
                "For, \"He who would love life, and see good days, let him keep his tongue from evil, and his lips from speaking deceit.",
                "Let him turn away from evil, and do good. Let him seek peace, and pursue it.",
                "For the eyes of the Lord are on the righteous, and his ears open to their prayer; but the face of the Lord is against those who do evil.\"",
                "Now who is he who will harm you, if you become imitators of that which is good?",
                "But even if you should suffer for righteousness\u2019 sake, you are blessed. \"Don\u2019t fear what they fear, neither be troubled.\"",
                "But sanctify the Lord God in your hearts; and always be ready to give an answer to everyone who asks you a reason concerning the hope that is in you, with humility and fear:",
                "having a good conscience; that, while you are spoken against as evildoers, they may be disappointed who curse your good way of life in Christ.",
                "For it is better, if it is God\u2019s will, that you suffer for doing well than for doing evil.",
                "Because Christ also suffered for sins once, the righteous for the unrighteous, that he might bring you to God; being put to death in the flesh, but made alive in the spirit;",
                "in which he also went and preached to the spirits in prison,",
                "who before were disobedient, when God waited patiently in the days of Noah, while the ship was being built. In it, few, that is, eight souls, were saved through water.",
                "This is a symbol of baptism, which now saves you\u2014not the putting away of the filth of the flesh, but the answer of a good conscience toward God, through the resurrection of Jesus Christ,",
                "who is at the right hand of God, having gone into heaven, angels and authorities and powers being made subject to him."
            ],
            4: [
                "Forasmuch then as Christ suffered for us in the flesh, arm yourselves also with the same mind; for he who has suffered in the flesh has ceased from sin;",
                "that you no longer should live the rest of your time in the flesh for the lusts of men, but for the will of God.",
                "For we have spent enough of our past time doing the desire of the Gentiles, and having walked in lewdness, lusts, drunken binges, orgies, carousings, and abominable idolatries.",
                "They think it is strange that you don\u2019t run with them into the same excess of riot, blaspheming:",
                "who will give account to him who is ready to judge the living and the dead.",
                "For to this end the Good News was preached even to the dead, that they might be judged indeed as men in the flesh, but live as to God in the spirit.",
                "But the end of all things is near. Therefore be of sound mind, self-controlled, and sober in prayer.",
                "And above all things be earnest in your love among yourselves, for love covers a multitude of sins.",
                "Be hospitable to one another without grumbling.",
                "As each has received a gift, employ it in serving one another, as good managers of the grace of God in its various forms.",
                "If anyone speaks, let it be as it were the very words of God. If anyone serves, let it be as of the strength which God supplies, that in all things God may be glorified through Jesus Christ, to whom belong the glory and the dominion forever and ever. Amen.",
                "Beloved, don\u2019t be astonished at the fiery trial which has come upon you, to test you, as though a strange thing happened to you.",
                "But because you are partakers of Christ\u2019s sufferings, rejoice; that at the revelation of his glory you also may rejoice with exceeding joy.",
                "If you are insulted for the name of Christ, you are blessed; because the Spirit of glory and of God rests on you. On their part he is blasphemed, but on your part he is glorified.",
                "For let none of you suffer as a murderer, or a thief, or an evil doer, or a meddler in other men\u2019s matters.",
                "But if one of you suffers for being a Christian, let him not be ashamed; but let him glorify God in this matter.",
                "For the time has come for judgment to begin with the household of God. If it begins first with us, what will happen to those who don\u2019t obey the Good News of God?",
                "\"If it is hard for the righteous to be saved, what will happen to the ungodly and the sinner?\"",
                "Therefore let them also who suffer according to the will of God in doing good entrust their souls to him, as to a faithful Creator."
            ],
            5: [
                "I exhort the elders among you, as a fellow elder, and a witness of the sufferings of Christ, and who will also share in the glory that will be revealed.",
                "Shepherd the flock of God which is among you, exercising the oversight, not under compulsion, but voluntarily, not for dishonest gain, but willingly;",
                "neither as lording it over those entrusted to you, but making yourselves examples to the flock.",
                "When the chief Shepherd is revealed, you will receive the crown of glory that doesn\u2019t fade away.",
                "Likewise, you younger ones, be subject to the elder. Yes, all of you clothe yourselves with humility, to subject yourselves to one another; for \"God resists the proud, but gives grace to the humble.\"",
                "Humble yourselves therefore under the mighty hand of God, that he may exalt you in due time;",
                "casting all your worries on him, because he cares for you.",
                "Be sober and self-controlled. Be watchful. Your adversary, the devil, walks around like a roaring lion, seeking whom he may devour.",
                "Withstand him steadfast in your faith, knowing that your brothers who are in the world are undergoing the same sufferings.",
                "But may the God of all grace, who called you to his eternal glory by Christ Jesus, after you have suffered a little while, perfect, establish, strengthen, and settle you.",
                "To him be the glory and the power forever and ever. Amen.",
                "Through Silvanus, our faithful brother, as I consider him, I have written to you briefly, exhorting, and testifying that this is the true grace of God in which you stand.",
                "She who is in Babylon, chosen together with you, greets you; and so does Mark, my son.",
                "Greet one another with a kiss of love. Peace be to you all who are in Christ Jesus. Amen."
            ]
        },
        61: {
            1: [
                "Simon Peter, a servant and apostle of Jesus Christ, to those who have obtained a like precious faith with us in the righteousness of our God and Savior, Jesus Christ:",
                "Grace to you and peace be multiplied in the knowledge of God and of Jesus our Lord,",
                "seeing that his divine power has granted to us all things that pertain to life and godliness, through the knowledge of him who called us by his own glory and virtue;",
                "by which he has granted to us his precious and exceedingly great promises; that through these you may become partakers of the divine nature, having escaped from the corruption that is in the world by lust.",
                "Yes, and for this very cause adding on your part all diligence, in your faith supply moral excellence; and in moral excellence, knowledge;",
                "and in knowledge, self-control; and in self-control patience; and in patience godliness;",
                "and in godliness brotherly affection; and in brotherly affection, love.",
                "For if these things are yours and abound, they make you to be not idle nor unfruitful to the knowledge of our Lord Jesus Christ.",
                "For he who lacks these things is blind, seeing only what is near, having forgotten the cleansing from his old sins.",
                "Therefore, brothers, be more diligent to make your calling and election sure. For if you do these things, you will never stumble.",
                "For thus you will be richly supplied with the entrance into the eternal Kingdom of our Lord and Savior, Jesus Christ.",
                "Therefore I will not be negligent to remind you of these things, though you know them, and are established in the present truth.",
                "I think it right, as long as I am in this tent, to stir you up by reminding you;",
                "knowing that the putting off of my tent comes swiftly, even as our Lord Jesus Christ made clear to me.",
                "Yes, I will make every effort that you may always be able to remember these things even after my departure.",
                "For we did not follow cunningly devised fables, when we made known to you the power and coming of our Lord Jesus Christ, but we were eyewitnesses of his majesty.",
                "For he received from God the Father honor and glory, when the voice came to him from the Majestic Glory, \"This is my beloved Son, in whom I am well pleased.\"",
                "We heard this voice come out of heaven when we were with him on the holy mountain.",
                "We have the more sure word of prophecy; and you do well that you heed it, as to a lamp shining in a dark place, until the day dawns, and the morning star arises in your hearts:",
                "knowing this first, that no prophecy of Scripture is of private interpretation.",
                "For no prophecy ever came by the will of man: but holy men of God spoke, being moved by the Holy Spirit."
            ],
            2: [
                "But false prophets also arose among the people, as false teachers will also be among you, who will secretly bring in destructive heresies, denying even the Master who bought them, bringing on themselves swift destruction.",
                "Many will follow their immoral ways, and as a result, the way of the truth will be maligned.",
                "In covetousness they will exploit you with deceptive words: whose sentence now from of old doesn\u2019t linger, and their destruction will not slumber.",
                "For if God didn\u2019t spare angels when they sinned, but cast them down to Tartarus, and committed them to pits of darkness, to be reserved for judgment;",
                "and didn\u2019t spare the ancient world, but preserved Noah with seven others, a preacher of righteousness, when he brought a flood on the world of the ungodly;",
                "and turning the cities of Sodom and Gomorrah into ashes, condemned them to destruction, having made them an example to those who would live ungodly;",
                "and delivered righteous Lot, who was very distressed by the lustful life of the wicked",
                "(for that righteous man dwelling among them, was tormented in his righteous soul from day to day with seeing and hearing lawless deeds):",
                "the Lord knows how to deliver the godly out of temptation and to keep the unrighteous under punishment for the day of judgment;",
                "but chiefly those who walk after the flesh in the lust of defilement, and despise authority. Daring, self-willed, they are not afraid to speak evil of dignitaries;",
                "whereas angels, though greater in might and power, don\u2019t bring a railing judgment against them before the Lord.",
                "But these, as unreasoning creatures, born natural animals to be taken and destroyed, speaking evil in matters about which they are ignorant, will in their destroying surely be destroyed,",
                "receiving the wages of unrighteousness; people who count it pleasure to revel in the daytime, spots and blemishes, reveling in their deceit while they feast with you;",
                "having eyes full of adultery, and who can\u2019t cease from sin; enticing unsettled souls; having a heart trained in greed; children of cursing;",
                "forsaking the right way, they went astray, having followed the way of Balaam the son of Beor, who loved the wages of wrongdoing;",
                "but he was rebuked for his own disobedience. A mute donkey spoke with a man\u2019s voice and stopped the madness of the prophet.",
                "These are wells without water, clouds driven by a storm; for whom the blackness of darkness has been reserved forever.",
                "For, uttering great swelling words of emptiness, they entice in the lusts of the flesh, by licentiousness, those who are indeed escaping from those who live in error;",
                "promising them liberty, while they themselves are bondservants of corruption; for a man is brought into bondage by whoever overcomes him.",
                "For if, after they have escaped the defilement of the world through the knowledge of the Lord and Savior Jesus Christ, they are again entangled in it and overcome, the last state has become worse for them than the first.",
                "For it would be better for them not to have known the way of righteousness, than, after knowing it, to turn back from the holy commandment delivered to them.",
                "But it has happened to them according to the true proverb, \"The dog turns to his own vomit again,\" and \"the sow that has washed to wallowing in the mire.\""
            ],
            3: [
                "This is now, beloved, the second letter that I have written to you; and in both of them I stir up your sincere mind by reminding you;",
                "that you should remember the words which were spoken before by the holy prophets, and the commandments of us, the apostles of the Lord and Savior:",
                "knowing this first, that in the last days mockers will come, walking after their own lusts,",
                "and saying, \"Where is the promise of his coming? For, from the day that the fathers fell asleep, all things continue as they were from the beginning of the creation.\"",
                "For this they willfully forget, that there were heavens from of old, and an earth formed out of water and amid water, by the word of God;",
                "by which means the world that then was, being overflowed with water, perished.",
                "But the heavens that now are, and the earth, by the same word have been stored up for fire, being reserved against the day of judgment and destruction of ungodly men.",
                "But don\u2019t forget this one thing, beloved, that one day is with the Lord as a thousand years, and a thousand years as one day.",
                "The Lord is not slow concerning his promise, as some count slowness; but is patient with us, not wishing that any should perish, but that all should come to repentance.",
                "But the day of the Lord will come as a thief in the night; in which the heavens will pass away with a great noise, and the elements will be dissolved with fervent heat, and the earth and the works that are in it will be burned up.",
                "Therefore since all these things will be destroyed like this, what kind of people ought you to be in holy living and godliness,",
                "looking for and earnestly desiring the coming of the day of God, which will cause the burning heavens to be dissolved, and the elements will melt with fervent heat?",
                "But, according to his promise, we look for new heavens and a new earth, in which righteousness dwells.",
                "Therefore, beloved, seeing that you look for these things, be diligent to be found in peace, without blemish and blameless in his sight.",
                "Regard the patience of our Lord as salvation; even as our beloved brother Paul also, according to the wisdom given to him, wrote to you;",
                "as also in all of his letters, speaking in them of these things. In those, there are some things that are hard to understand, which the ignorant and unsettled twist, as they also do to the other Scriptures, to their own destruction.",
                "You therefore, beloved, knowing these things beforehand, beware, lest being carried away with the error of the wicked, you fall from your own steadfastness.",
                "But grow in the grace and knowledge of our Lord and Savior Jesus Christ. To him be the glory both now and forever. Amen."
            ]
        },
        62: {
            1: [
                "That which was from the beginning, that which we have heard, that which we have seen with our eyes, that which we saw, and our hands touched, concerning the Word of life",
                "(and the life was revealed, and we have seen, and testify, and declare to you the life, the eternal life, which was with the Father, and was revealed to us);",
                "that which we have seen and heard we declare to you, that you also may have fellowship with us. Yes, and our fellowship is with the Father, and with his Son, Jesus Christ.",
                "And we write these things to you, that our joy may be fulfilled.",
                "This is the message which we have heard from him and announce to you, that God is light, and in him is no darkness at all.",
                "If we say that we have fellowship with him and walk in the darkness, we lie, and don\u2019t tell the truth.",
                "But if we walk in the light, as he is in the light, we have fellowship with one another, and the blood of Jesus Christ, his Son, cleanses us from all sin.",
                "If we say that we have no sin, we deceive ourselves, and the truth is not in us.",
                "If we confess our sins, he is faithful and righteous to forgive us the sins, and to cleanse us from all unrighteousness.",
                "If we say that we haven\u2019t sinned, we make him a liar, and his word is not in us."
            ],
            2: [
                "My little children, I write these things to you so that you may not sin. If anyone sins, we have a Counselor with the Father, Jesus Christ, the righteous.",
                "And he is the atoning sacrifice for our sins, and not for ours only, but also for the whole world.",
                "This is how we know that we know him: if we keep his commandments.",
                "One who says, \"I know him,\" and doesn\u2019t keep his commandments, is a liar, and the truth isn\u2019t in him.",
                "But whoever keeps his word, God\u2019s love has most certainly been perfected in him. This is how we know that we are in him:",
                "he who says he remains in him ought himself also to walk just like he walked.",
                "Brothers, I write no new commandment to you, but an old commandment which you had from the beginning. The old commandment is the word which you heard from the beginning.",
                "Again, I write a new commandment to you, which is true in him and in you; because the darkness is passing away, and the true light already shines.",
                "He who says he is in the light and hates his brother, is in the darkness even until now.",
                "He who loves his brother remains in the light, and there is no occasion for stumbling in him.",
                "But he who hates his brother is in the darkness, and walks in the darkness, and doesn\u2019t know where he is going, because the darkness has blinded his eyes.",
                "I write to you, little children, because your sins are forgiven you for his name\u2019s sake.",
                "I write to you, fathers, because you know him who is from the beginning. I write to you, young men, because you have overcome the evil one. I write to you, little children, because you know the Father.",
                "I have written to you, fathers, because you know him who is from the beginning. I have written to you, young men, because you are strong, and the word of God remains in you, and you have overcome the evil one.",
                "Don\u2019t love the world, neither the things that are in the world. If anyone loves the world, the Father\u2019s love isn\u2019t in him.",
                "For all that is in the world, the lust of the flesh, the lust of the eyes, and the pride of life, isn\u2019t the Father\u2019s, but is the world\u2019s.",
                "The world is passing away with its lusts, but he who does God\u2019s will remains forever.",
                "Little children, these are the end times, and as you heard that the Antichrist is coming, even now many antichrists have arisen. By this we know that it is the final hour.",
                "They went out from us, but they didn\u2019t belong to us; for if they had belonged to us, they would have continued with us. But they left, that they might be revealed that none of them belong to us.",
                "You have an anointing from the Holy One, and you all have knowledge.",
                "I have not written to you because you don\u2019t know the truth, but because you know it, and because no lie is of the truth.",
                "Who is the liar but he who denies that Jesus is the Christ? This is the Antichrist, he who denies the Father and the Son.",
                "Whoever denies the Son, the same doesn\u2019t have the Father. He who confesses the Son has the Father also.",
                "Therefore, as for you, let that remain in you which you heard from the beginning. If that which you heard from the beginning remains in you, you also will remain in the Son, and in the Father.",
                "This is the promise which he promised us, the eternal life.",
                "These things I have written to you concerning those who would lead you astray.",
                "As for you, the anointing which you received from him remains in you, and you don\u2019t need for anyone to teach you. But as his anointing teaches you concerning all things, and is true, and is no lie, and even as it taught you, you will remain in him.",
                "Now, little children, remain in him, that when he appears, we may have boldness, and not be ashamed before him at his coming.",
                "If you know that he is righteous, you know that everyone who practices righteousness is born of him."
            ],
            3: [
                "Behold, how great a love the Father has bestowed on us, that we should be called children of God! For this cause the world doesn\u2019t know us, because it didn\u2019t know him.",
                "Beloved, now we are children of God, and it is not yet revealed what we will be. But we know that, when he is revealed, we will be like him; for we will see him just as he is.",
                "Everyone who has this hope set on him purifies himself, even as he is pure.",
                "Everyone who sins also commits lawlessness. Sin is lawlessness.",
                "You know that he was revealed to take away our sins, and in him is no sin.",
                "Whoever remains in him doesn\u2019t sin. Whoever sins hasn\u2019t seen him, neither knows him.",
                "Little children, let no one lead you astray. He who does righteousness is righteous, even as he is righteous.",
                "He who sins is of the devil, for the devil has been sinning from the beginning. To this end the Son of God was revealed, that he might destroy the works of the devil.",
                "Whoever is born of God doesn\u2019t commit sin, because his seed remains in him; and he can\u2019t sin, because he is born of God.",
                "In this the children of God are revealed, and the children of the devil. Whoever doesn\u2019t do righteousness is not of God, neither is he who doesn\u2019t love his brother.",
                "For this is the message which you heard from the beginning, that we should love one another;",
                "unlike Cain, who was of the evil one, and killed his brother. Why did he kill him? Because his works were evil, and his brother\u2019s righteous.",
                "Don\u2019t be surprised, my brothers, if the world hates you.",
                "We know that we have passed out of death into life, because we love the brothers. He who doesn\u2019t love his brother remains in death.",
                "Whoever hates his brother is a murderer, and you know that no murderer has eternal life remaining in him.",
                "By this we know love, because he laid down his life for us. And we ought to lay down our lives for the brothers.",
                "But whoever has the world\u2019s goods, and sees his brother in need, and closes his heart of compassion against him, how does the love of God remain in him?",
                "My little children, let\u2019s not love in word only, neither with the tongue only, but in deed and truth.",
                "And by this we know that we are of the truth, and persuade our hearts before him,",
                "because if our heart condemns us, God is greater than our heart, and knows all things.",
                "Beloved, if our hearts don\u2019t condemn us, we have boldness toward God;",
                "and whatever we ask, we receive from him, because we keep his commandments and do the things that are pleasing in his sight.",
                "This is his commandment, that we should believe in the name of his Son, Jesus Christ, and love one another, even as he commanded.",
                "He who keeps his commandments remains in him, and he in him. By this we know that he remains in us, by the Spirit which he gave us."
            ],
            4: [
                "Beloved, don\u2019t believe every spirit, but test the spirits, whether they are of God, because many false prophets have gone out into the world.",
                "By this you know the Spirit of God: every spirit who confesses that Jesus Christ has come in the flesh is of God,",
                "and every spirit who doesn\u2019t confess that Jesus Christ has come in the flesh is not of God, and this is the spirit of the Antichrist, of whom you have heard that it comes. Now it is in the world already.",
                "You are of God, little children, and have overcome them; because greater is he who is in you than he who is in the world.",
                "They are of the world. Therefore they speak of the world, and the world hears them.",
                "We are of God. He who knows God listens to us. He who is not of God doesn\u2019t listen to us. By this we know the spirit of truth, and the spirit of error.",
                "Beloved, let us love one another, for love is of God; and everyone who loves is born of God, and knows God.",
                "He who doesn\u2019t love doesn\u2019t know God, for God is love.",
                "By this God\u2019s love was revealed in us, that God has sent his one and only Son into the world that we might live through him.",
                "In this is love, not that we loved God, but that he loved us, and sent his Son as the atoning sacrifice for our sins.",
                "Beloved, if God loved us in this way, we also ought to love one another.",
                "No one has seen God at any time. If we love one another, God remains in us, and his love has been perfected in us.",
                "By this we know that we remain in him and he in us, because he has given us of his Spirit.",
                "We have seen and testify that the Father has sent the Son as the Savior of the world.",
                "Whoever confesses that Jesus is the Son of God, God remains in him, and he in God.",
                "We know and have believed the love which God has for us. God is love, and he who remains in love remains in God, and God remains in him.",
                "In this love has been made perfect among us, that we may have boldness in the day of judgment, because as he is, even so are we in this world.",
                "There is no fear in love; but perfect love casts out fear, because fear has punishment. He who fears is not made perfect in love.",
                "We love him, because he first loved us.",
                "If a man says, \"I love God,\" and hates his brother, he is a liar; for he who doesn\u2019t love his brother whom he has seen, how can he love God whom he has not seen?",
                "This commandment we have from him, that he who loves God should also love his brother."
            ],
            5: [
                "Whoever believes that Jesus is the Christ is born of God. Whoever loves the Father also loves the child who is born of him.",
                "By this we know that we love the children of God, when we love God and keep his commandments.",
                "For this is the love of God, that we keep his commandments. His commandments are not grievous.",
                "For whatever is born of God overcomes the world. This is the victory that has overcome the world: your faith.",
                "Who is he who overcomes the world, but he who believes that Jesus is the Son of God?",
                "This is he who came by water and blood, Jesus Christ; not with the water only, but with the water and the blood. It is the Spirit who testifies, because the Spirit is the truth.",
                "For there are three who testify:",
                "the Spirit, the water, and the blood; and the three agree as one.",
                "If we receive the witness of men, the witness of God is greater; for this is God\u2019s testimony which he has testified concerning his Son.",
                "He who believes in the Son of God has the testimony in himself. He who doesn\u2019t believe God has made him a liar, because he has not believed in the testimony that God has given concerning his Son.",
                "The testimony is this, that God gave to us eternal life, and this life is in his Son.",
                "He who has the Son has the life. He who doesn\u2019t have God\u2019s Son doesn\u2019t have the life.",
                "These things I have written to you who believe in the name of the Son of God, that you may know that you have eternal life, and that you may continue to believe in the name of the Son of God.",
                "This is the boldness which we have toward him, that, if we ask anything according to his will, he listens to us.",
                "And if we know that he listens to us, whatever we ask, we know that we have the petitions which we have asked of him.",
                "If anyone sees his brother sinning a sin not leading to death, he shall ask, and God will give him life for those who sin not leading to death. There is a sin leading to death. I don\u2019t say that he should make a request concerning this.",
                "All unrighteousness is sin, and there is a sin not leading to death.",
                "We know that whoever is born of God doesn\u2019t sin, but he who was born of God keeps himself, and the evil one doesn\u2019t touch him.",
                "We know that we are of God, and the whole world lies in the power of the evil one.",
                "We know that the Son of God has come, and has given us an understanding, that we know him who is true, and we are in him who is true, in his Son Jesus Christ. This is the true God, and eternal life.",
                "Little children, keep yourselves from idols."
            ]
        },
        63: {
            1: [
                "The elder, to the chosen lady and her children, whom I love in truth; and not I only, but also all those who know the truth;",
                "for the truth\u2019s sake, which remains in us, and it will be with us forever:",
                "Grace, mercy, and peace will be with us, from God the Father, and from the Lord Jesus Christ, the Son of the Father, in truth and love.",
                "I rejoice greatly that I have found some of your children walking in truth, even as we have been commanded by the Father.",
                "Now I beg you, dear lady, not as though I wrote to you a new commandment, but that which we had from the beginning, that we love one another.",
                "This is love, that we should walk according to his commandments. This is the commandment, even as you heard from the beginning, that you should walk in it.",
                "For many deceivers have gone out into the world, those who don\u2019t confess that Jesus Christ came in the flesh. This is the deceiver and the Antichrist.",
                "Watch yourselves, that we don\u2019t lose the things which we have accomplished, but that we receive a full reward.",
                "Whoever transgresses and doesn\u2019t remain in the teaching of Christ, doesn\u2019t have God. He who remains in the teaching, the same has both the Father and the Son.",
                "If anyone comes to you, and doesn\u2019t bring this teaching, don\u2019t receive him into your house, and don\u2019t welcome him,",
                "for he who welcomes him participates in his evil works.",
                "Having many things to write to you, I don\u2019t want to do so with paper and ink, but I hope to come to you, and to speak face to face, that our joy may be made full.",
                "The children of your chosen sister greet you. Amen."
            ]
        },
        64: {
            1: [
                "The elder to Gaius the beloved, whom I love in truth.",
                "Beloved, I pray that you may prosper in all things and be healthy, even as your soul prospers.",
                "For I rejoiced greatly, when brothers came and testified about your truth, even as you walk in truth.",
                "I have no greater joy than this, to hear about my children walking in truth.",
                "Beloved, you do a faithful work in whatever you accomplish for those who are brothers and strangers.",
                "They have testified about your love before the assembly. You will do well to send them forward on their journey in a way worthy of God,",
                "because for the sake of the Name they went out, taking nothing from the Gentiles.",
                "We therefore ought to receive such, that we may be fellow workers for the truth.",
                "I wrote to the assembly, but Diotrephes, who loves to be first among them, doesn\u2019t accept what we say.",
                "Therefore, if I come, I will call attention to his deeds which he does, unjustly accusing us with wicked words. Not content with this, neither does he himself receive the brothers, and those who would, he forbids and throws out of the assembly.",
                "Beloved, don\u2019t imitate that which is evil, but that which is good. He who does good is of God. He who does evil hasn\u2019t seen God.",
                "Demetrius has the testimony of all, and of the truth itself; yes, we also testify, and you know that our testimony is true.",
                "I had many things to write to you, but I am unwilling to write to you with ink and pen;",
                "but I hope to see you soon, and we will speak face to face. Peace be to you. The friends greet you. Greet the friends by name."
            ]
        },
        65: {
            1: [
                "Jude, a servant of Jesus Christ, and brother of James, to those who are called, sanctified by God the Father, and kept for Jesus Christ:",
                "Mercy to you and peace and love be multiplied.",
                "Beloved, while I was very eager to write to you about our common salvation, I was constrained to write to you exhorting you to contend earnestly for the faith which was once for all delivered to the saints.",
                "For there are certain men who crept in secretly, even those who were long ago written about for this condemnation: ungodly men, turning the grace of our God into indecency, and denying our only Master, God, and Lord, Jesus Christ.",
                "Now I desire to remind you, though you already know this, that the Lord, having saved a people out of the land of Egypt, afterward destroyed those who didn\u2019t believe.",
                "Angels who didn\u2019t keep their first domain, but deserted their own dwelling place, he has kept in everlasting bonds under darkness for the judgment of the great day.",
                "Even as Sodom and Gomorrah, and the cities around them, having, in the same way as these, given themselves over to sexual immorality and gone after strange flesh, are set forth as an example, suffering the punishment of eternal fire.",
                "Yet in the same way, these also in their dreaming defile the flesh, despise authority, and slander celestial beings.",
                "But Michael, the archangel, when contending with the devil and arguing about the body of Moses, dared not bring against him an abusive condemnation, but said, \"May the Lord rebuke you!\"",
                "But these speak evil of whatever things they don\u2019t know. What they understand naturally, like the creatures without reason, they are destroyed in these things.",
                "Woe to them! For they went in the way of Cain, and ran riotously in the error of Balaam for hire, and perished in Korah\u2019s rebellion.",
                "These are hidden rocky reefs in your love feasts when they feast with you, shepherds who without fear feed themselves; clouds without water, carried along by winds; autumn leaves without fruit, twice dead, plucked up by the roots;",
                "wild waves of the sea, foaming out their own shame; wandering stars, for whom the blackness of darkness has been reserved forever.",
                "About these also Enoch, the seventh from Adam, prophesied, saying, \"Behold, the Lord came with ten thousands of his holy ones,",
                "to execute judgment on all, and to convict all the ungodly of all their works of ungodliness which they have done in an ungodly way, and of all the hard things which ungodly sinners have spoken against him.\"",
                "These are murmurers and complainers, walking after their lusts (and their mouth speaks proud things), showing respect of persons to gain advantage.",
                "But you, beloved, remember the words which have been spoken before by the apostles of our Lord Jesus Christ.",
                "They said to you that \"In the last time there will be mockers, walking after their own ungodly lusts.\"",
                "These are they who cause divisions, and are sensual, not having the Spirit.",
                "But you, beloved, keep building up yourselves on your most holy faith, praying in the Holy Spirit.",
                "Keep yourselves in the love of God, looking for the mercy of our Lord Jesus Christ to eternal life.",
                "On some have compassion, making a distinction,",
                "and some save, snatching them out of the fire with fear, hating even the clothing stained by the flesh.",
                "Now to him who is able to keep them from stumbling, and to present you faultless before the presence of his glory in great joy,",
                "to God our Savior, who alone is wise, be glory and majesty, dominion and power, both now and forever. Amen."
            ]
        },
        66: {
            1: [
                "This is the Revelation of Jesus Christ, which God gave him to show to his servants the things which must happen soon, which he sent and made known by his angel to his servant, John,",
                "who testified to God\u2019s word, and of the testimony of Jesus Christ, about everything that he saw.",
                "Blessed is he who reads and those who hear the words of the prophecy, and keep the things that are written in it, for the time is at hand.",
                "John, to the seven assemblies that are in Asia: Grace to you and peace, from God, who is and who was and who is to come; and from the seven Spirits who are before his throne;",
                "and from Jesus Christ, the faithful witness, the firstborn of the dead, and the ruler of the kings of the earth. To him who loves us, and washed us from our sins by his blood;",
                "and he made us to be a Kingdom, priests to his God and Father; to him be the glory and the dominion forever and ever. Amen.",
                "Behold, he is coming with the clouds, and every eye will see him, including those who pierced him. All the tribes of the earth will mourn over him. Even so, Amen.",
                "\"I am the Alpha and the Omega,\" says the Lord God, \"who is and who was and who is to come, the Almighty.\"",
                "I John, your brother and partner with you in oppression, Kingdom, and perseverance in Christ Jesus, was on the isle that is called Patmos because of God\u2019s Word and the testimony of Jesus Christ.",
                "I was in the Spirit on the Lord\u2019s day, and I heard behind me a loud voice, like a trumpet",
                "saying, \"What you see, write in a book and send to the seven assemblies: to Ephesus, Smyrna, Pergamum, Thyatira, Sardis, Philadelphia, and to Laodicea.\"",
                "I turned to see the voice that spoke with me. Having turned, I saw seven golden lampstands.",
                "And among the lampstands was one like a son of man, clothed with a robe reaching down to his feet, and with a golden sash around his chest.",
                "His head and his hair were white as white wool, like snow. His eyes were like a flame of fire.",
                "His feet were like burnished brass, as if it had been refined in a furnace. His voice was like the voice of many waters.",
                "He had seven stars in his right hand. Out of his mouth proceeded a sharp two-edged sword. His face was like the sun shining at its brightest.",
                "When I saw him, I fell at his feet like a dead man. He laid his right hand on me, saying, \"Don\u2019t be afraid. I am the first and the last,",
                "and the Living one. I was dead, and behold, I am alive forevermore. Amen. I have the keys of Death and of Hades.",
                "Write therefore the things which you have seen, and the things which are, and the things which will happen hereafter;",
                "the mystery of the seven stars which you saw in my right hand, and the seven golden lampstands. The seven stars are the angels of the seven assemblies. The seven lampstands are seven assemblies."
            ],
            2: [
                "\"To the angel of the assembly in Ephesus write: \"He who holds the seven stars in his right hand, he who walks among the seven golden lampstands says these things:",
                "\"I know your works, and your toil and perseverance, and that you can\u2019t tolerate evil men, and have tested those who call themselves apostles, and they are not, and found them false.",
                "You have perseverance and have endured for my name\u2019s sake, and have not grown weary.",
                "But I have this against you, that you left your first love.",
                "Remember therefore from where you have fallen, and repent and do the first works; or else I am coming to you swiftly, and will move your lampstand out of its place, unless you repent.",
                "But this you have, that you hate the works of the Nicolaitans, which I also hate.",
                "He who has an ear, let him hear what the Spirit says to the assemblies. To him who overcomes I will give to eat of the tree of life, which is in the Paradise of my God.",
                "\"To the angel of the assembly in Smyrna write: \"The first and the last, who was dead, and has come to life says these things:",
                "\"I know your works, oppression, and your poverty (but you are rich), and the blasphemy of those who say they are Jews, and they are not, but are a synagogue of Satan.",
                "Don\u2019t be afraid of the things which you are about to suffer. Behold, the devil is about to throw some of you into prison, that you may be tested; and you will have oppression for ten days. Be faithful to death, and I will give you the crown of life.",
                "He who has an ear, let him hear what the Spirit says to the assemblies. He who overcomes won\u2019t be harmed by the second death.",
                "\"To the angel of the assembly in Pergamum write: \"He who has the sharp two-edged sword says these things:",
                "\"I know your works and where you dwell, where Satan\u2019s throne is. You hold firmly to my name, and didn\u2019t deny my faith in the days of Antipas my witness, my faithful one, who was killed among you, where Satan dwells.",
                "But I have a few things against you, because you have there some who hold the teaching of Balaam, who taught Balak to throw a stumbling block before the children of Israel, to eat things sacrificed to idols, and to commit sexual immorality.",
                "So you also have some who hold to the teaching of the Nicolaitans likewise.",
                "Repent therefore, or else I am coming to you quickly, and I will make war against them with the sword of my mouth.",
                "He who has an ear, let him hear what the Spirit says to the assemblies. To him who overcomes, to him I will give of the hidden manna, and I will give him a white stone, and on the stone a new name written, which no one knows but he who receives it.",
                "\"To the angel of the assembly in Thyatira write: \"The Son of God, who has his eyes like a flame of fire, and his feet are like burnished brass, says these things:",
                "\"I know your works, your love, faith, service, patient endurance, and that your last works are more than the first.",
                "But I have this against you, that you tolerate your woman, Jezebel, who calls herself a prophetess. She teaches and seduces my servants to commit sexual immorality, and to eat things sacrificed to idols.",
                "I gave her time to repent, but she refuses to repent of her sexual immorality.",
                "Behold, I will throw her into a bed, and those who commit adultery with her into great oppression, unless they repent of her works.",
                "I will kill her children with Death, and all the assemblies will know that I am he who searches the minds and hearts. I will give to each one of you according to your deeds.",
                "But to you I say, to the rest who are in Thyatira, as many as don\u2019t have this teaching, who don\u2019t know what some call \u2018the deep things of Satan,\u2019 to you I say, I am not putting any other burden on you.",
                "Nevertheless, hold that which you have firmly until I come.",
                "He who overcomes, and he who keeps my works to the end, to him I will give authority over the nations.",
                "He will rule them with a rod of iron, shattering them like clay pots; as I also have received of my Father:",
                "and I will give him the morning star.",
                "He who has an ear, let him hear what the Spirit says to the assemblies."
            ],
            3: [
                "\"And to the angel of the assembly in Sardis write: \"He who has the seven Spirits of God, and the seven stars says these things: \"I know your works, that you have a reputation of being alive, but you are dead.",
                "Wake up, and keep the things that remain, which you were about to throw away, for I have found no works of yours perfected before my God.",
                "Remember therefore how you have received and heard. Keep it, and repent. If therefore you won\u2019t watch, I will come as a thief, and you won\u2019t know what hour I will come upon you.",
                "Nevertheless you have a few names in Sardis that did not defile their garments. They will walk with me in white, for they are worthy.",
                "He who overcomes will be arrayed in white garments, and I will in no way blot his name out of the book of life, and I will confess his name before my Father, and before his angels.",
                "He who has an ear, let him hear what the Spirit says to the assemblies.",
                "\"To the angel of the assembly in Philadelphia write: \"He who is holy, he who is true, he who has the key of David, he who opens and no one can shut, and who shuts and no one opens, says these things:",
                "\"I know your works (behold, I have set before you an open door, which no one can shut), that you have a little power, and kept my word, and didn\u2019t deny my name.",
                "Behold, I give of the synagogue of Satan, of those who say they are Jews, and they are not, but lie. Behold, I will make them to come and worship before your feet, and to know that I have loved you.",
                "Because you kept my command to endure, I also will keep you from the hour of testing, which is to come on the whole world, to test those who dwell on the earth.",
                "I am coming quickly! Hold firmly that which you have, so that no one takes your crown.",
                "He who overcomes, I will make him a pillar in the temple of my God, and he will go out from there no more. I will write on him the name of my God, and the name of the city of my God, the new Jerusalem, which comes down out of heaven from my God, and my own new name.",
                "He who has an ear, let him hear what the Spirit says to the assemblies.",
                "\"To the angel of the assembly in Laodicea write: \"The Amen, the Faithful and True Witness, the Head of God\u2019s creation, says these things:",
                "\"I know your works, that you are neither cold nor hot. I wish you were cold or hot.",
                "So, because you are lukewarm, and neither hot nor cold, I will vomit you out of my mouth.",
                "Because you say, \u2018I am rich, and have gotten riches, and have need of nothing;\u2019 and don\u2019t know that you are the wretched one, miserable, poor, blind, and naked;",
                "I counsel you to buy from me gold refined by fire, that you may become rich; and white garments, that you may clothe yourself, and that the shame of your nakedness may not be revealed; and eye salve to anoint your eyes, that you may see.",
                "As many as I love, I reprove and chasten. Be zealous therefore, and repent.",
                "Behold, I stand at the door and knock. If anyone hears my voice and opens the door, then I will come in to him, and will dine with him, and he with me.",
                "He who overcomes, I will give to him to sit down with me on my throne, as I also overcame, and sat down with my Father on his throne.",
                "He who has an ear, let him hear what the Spirit says to the assemblies.\""
            ],
            4: [
                "After these things I looked and saw a door opened in heaven, and the first voice that I heard, like a trumpet speaking with me, was one saying, \"Come up here, and I will show you the things which must happen after this.\"",
                "Immediately I was in the Spirit. Behold, there was a throne set in heaven, and one sitting on the throne",
                "that looked like a jasper stone and a sardius. There was a rainbow around the throne, like an emerald to look at.",
                "Around the throne were twenty-four thrones. On the thrones were twenty-four elders sitting, dressed in white garments, with crowns of gold on their heads.",
                "Out of the throne proceed lightnings, sounds, and thunders. There were seven lamps of fire burning before his throne, which are the seven Spirits of God.",
                "Before the throne was something like a sea of glass, similar to crystal. In the midst of the throne, and around the throne were four living creatures full of eyes before and behind.",
                "The first creature was like a lion, and the second creature like a calf, and the third creature had a face like a man, and the fourth was like a flying eagle.",
                "The four living creatures, each one of them having six wings, are full of eyes around and within. They have no rest day and night, saying, \"Holy, holy, holy is the Lord God, the Almighty, who was and who is and who is to come!\"",
                "When the living creatures give glory, honor, and thanks to him who sits on the throne, to him who lives forever and ever,",
                "the twenty-four elders fall down before him who sits on the throne, and worship him who lives forever and ever, and throw their crowns before the throne, saying,",
                "\"Worthy are you, our Lord and God, the Holy One, to receive the glory, the honor, and the power, for you created all things, and because of your desire they existed, and were created!\""
            ],
            5: [
                "I saw, in the right hand of him who sat on the throne, a book written inside and outside, sealed shut with seven seals.",
                "I saw a mighty angel proclaiming with a loud voice, \"Who is worthy to open the book, and to break its seals?\"",
                "No one in heaven above, or on the earth, or under the earth, was able to open the book, or to look in it.",
                "And I wept much, because no one was found worthy to open the book, or to look in it.",
                "One of the elders said to me, \"Don\u2019t weep. Behold, the Lion who is of the tribe of Judah, the Root of David, has overcome; he who opens the book and its seven seals.\"",
                "I saw in the midst of the throne and of the four living creatures, and in the midst of the elders, a Lamb standing, as though it had been slain, having seven horns, and seven eyes, which are the seven Spirits of God, sent out into all the earth.",
                "Then he came, and he took it out of the right hand of him who sat on the throne.",
                "Now when he had taken the book, the four living creatures and the twenty-four elders fell down before the Lamb, each one having a harp, and golden bowls full of incense, which are the prayers of the saints.",
                "They sang a new song, saying, \"You are worthy to take the book, and to open its seals: for you were killed, and bought us for God with your blood, out of every tribe, language, people, and nation,",
                "and made us kings and priests to our God, and we will reign on earth.\"",
                "I saw, and I heard something like a voice of many angels around the throne, the living creatures, and the elders; and the number of them was ten thousands of ten thousands, and thousands of thousands;",
                "saying with a loud voice, \"Worthy is the Lamb who has been killed to receive the power, wealth, wisdom, strength, honor, glory, and blessing!\"",
                "I heard every created thing which is in heaven, on the earth, under the earth, on the sea, and everything in them, saying, \"To him who sits on the throne, and to the Lamb be the blessing, the honor, the glory, and the dominion, forever and ever! Amen!\"",
                "The four living creatures said, \"Amen!\" The elders fell down and worshiped."
            ],
            6: [
                "I saw that the Lamb opened one of the seven seals, and I heard one of the four living creatures saying, as with a voice of thunder, \"Come and see!\"",
                "And behold, a white horse, and he who sat on it had a bow. A crown was given to him, and he came forth conquering, and to conquer.",
                "When he opened the second seal, I heard the second living creature saying, \"Come!\"",
                "Another came forth, a red horse. To him who sat on it was given power to take peace from the earth, and that they should kill one another. There was given to him a great sword.",
                "When he opened the third seal, I heard the third living creature saying, \"Come and see!\" And behold, a black horse, and he who sat on it had a balance in his hand.",
                "I heard a voice in the midst of the four living creatures saying, \"A choenix of wheat for a denarius, and three choenix of barley for a denarius! Don\u2019t damage the oil and the wine!\"",
                "When he opened the fourth seal, I heard the fourth living creature saying, \"Come and see!\"",
                "And behold, a pale horse, and he who sat on it, his name was Death. Hades followed with him. Authority over one fourth of the earth, to kill with the sword, with famine, with death, and by the wild animals of the earth was given to him.",
                "When he opened the fifth seal, I saw underneath the altar the souls of those who had been killed for the Word of God, and for the testimony of the Lamb which they had.",
                "They cried with a loud voice, saying, \"How long, Master, the holy and true, until you judge and avenge our blood on those who dwell on the earth?\"",
                "A long white robe was given to each of them. They were told that they should rest yet for a while, until their fellow servants and their brothers, who would also be killed even as they were, should complete their course.",
                "I saw when he opened the sixth seal, and there was a great earthquake. The sun became black as sackcloth made of hair, and the whole moon became as blood.",
                "The stars of the sky fell to the earth, like a fig tree dropping its unripe figs when it is shaken by a great wind.",
                "The sky was removed like a scroll when it is rolled up. Every mountain and island were moved out of their places.",
                "The kings of the earth, the princes, the commanding officers, the rich, the strong, and every slave and free person, hid themselves in the caves and in the rocks of the mountains.",
                "They told the mountains and the rocks, \"Fall on us, and hide us from the face of him who sits on the throne, and from the wrath of the Lamb,",
                "for the great day of his wrath has come; and who is able to stand?\""
            ],
            7: [
                "After this, I saw four angels standing at the four corners of the earth, holding the four winds of the earth, so that no wind would blow on the earth, or on the sea, or on any tree.",
                "I saw another angel ascend from the sunrise, having the seal of the living God. He cried with a loud voice to the four angels to whom it was given to harm the earth and the sea,",
                "saying, \"Don\u2019t harm the earth, neither the sea, nor the trees, until we have sealed the bondservants of our God on their foreheads!\"",
                "I heard the number of those who were sealed, one hundred forty-four thousand, sealed out of every tribe of the children of Israel:",
                "of the tribe of Judah were sealed twelve thousand, of the tribe of Reuben twelve thousand, of the tribe of Gad twelve thousand,",
                "of the tribe of Asher twelve thousand, of the tribe of Naphtali twelve thousand, of the tribe of Manasseh twelve thousand,",
                "of the tribe of Simeon twelve thousand, of the tribe of Levi twelve thousand, of the tribe of Issachar twelve thousand,",
                "of the tribe of Zebulun twelve thousand, of the tribe of Joseph twelve thousand, of the tribe of Benjamin were sealed twelve thousand.",
                "After these things I looked, and behold, a great multitude, which no man could number, out of every nation and of all tribes, peoples, and languages, standing before the throne and before the Lamb, dressed in white robes, with palm branches in their hands.",
                "They cried with a loud voice, saying, \"Salvation be to our God, who sits on the throne, and to the Lamb!\"",
                "All the angels were standing around the throne, the elders, and the four living creatures; and they fell on their faces before his throne, and worshiped God,",
                "saying, \"Amen! Blessing, glory, wisdom, thanksgiving, honor, power, and might, be to our God forever and ever! Amen.\"",
                "One of the elders answered, saying to me, \"These who are arrayed in white robes, who are they, and from where did they come?\"",
                "I told him, \"My lord, you know.\" He said to me, \"These are those who came out of the great tribulation. They washed their robes, and made them white in the Lamb\u2019s blood.",
                "Therefore they are before the throne of God, they serve him day and night in his temple. He who sits on the throne will spread his tabernacle over them.",
                "They will never be hungry, neither thirsty any more; neither will the sun beat on them, nor any heat;",
                "for the Lamb who is in the midst of the throne shepherds them, and leads them to springs of waters of life. And God will wipe away every tear from their eyes.\""
            ],
            8: [
                "When he opened the seventh seal, there was silence in heaven for about half an hour.",
                "I saw the seven angels who stand before God, and seven trumpets were given to them.",
                "Another angel came and stood over the altar, having a golden censer. Much incense was given to him, that he should add it to the prayers of all the saints on the golden altar which was before the throne.",
                "The smoke of the incense, with the prayers of the saints, went up before God out of the angel\u2019s hand.",
                "The angel took the censer, and he filled it with the fire of the altar, and threw it on the earth. There followed thunders, sounds, lightnings, and an earthquake.",
                "The seven angels who had the seven trumpets prepared themselves to sound.",
                "The first sounded, and there followed hail and fire, mixed with blood, and they were thrown to the earth. One third of the earth was burnt up, and one third of the trees were burnt up, and all green grass was burnt up.",
                "The second angel sounded, and something like a great burning mountain was thrown into the sea. One third of the sea became blood,",
                "and one third of the living creatures which were in the sea died. One third of the ships were destroyed.",
                "The third angel sounded, and a great star fell from the sky, burning like a torch, and it fell on one third of the rivers, and on the springs of the waters.",
                "The name of the star is called \"Wormwood.\" One third of the waters became wormwood. Many people died from the waters, because they were made bitter.",
                "The fourth angel sounded, and one third of the sun was struck, and one third of the moon, and one third of the stars; so that one third of them would be darkened, and the day wouldn\u2019t shine for one third of it, and the night in the same way.",
                "I saw, and I heard an eagle, flying in mid heaven, saying with a loud voice, \"Woe! Woe! Woe for those who dwell on the earth, because of the other voices of the trumpets of the three angels, who are yet to sound!\""
            ],
            9: [
                "The fifth angel sounded, and I saw a star from the sky which had fallen to the earth. The key to the pit of the abyss was given to him.",
                "He opened the pit of the abyss, and smoke went up out of the pit, like the smoke from a burning furnace. The sun and the air were darkened because of the smoke from the pit.",
                "Then out of the smoke came forth locusts on the earth, and power was given to them, as the scorpions of the earth have power.",
                "They were told that they should not hurt the grass of the earth, neither any green thing, neither any tree, but only those people who don\u2019t have God\u2019s seal on their foreheads.",
                "They were given power not to kill them, but to torment them for five months. Their torment was like the torment of a scorpion, when it strikes a person.",
                "In those days people will seek death, and will in no way find it. They will desire to die, and death will flee from them.",
                "The shapes of the locusts were like horses prepared for war. On their heads were something like golden crowns, and their faces were like people\u2019s faces.",
                "They had hair like women\u2019s hair, and their teeth were like those of lions.",
                "They had breastplates, like breastplates of iron. The sound of their wings was like the sound of chariots, or of many horses rushing to war.",
                "They have tails like those of scorpions, and stings. In their tails they have power to harm men for five months.",
                "They have over them as king the angel of the abyss. His name in Hebrew is \"Abaddon,\" but in Greek, he has the name \"Apollyon.\"",
                "The first woe is past. Behold, there are still two woes coming after this.",
                "The sixth angel sounded. I heard a voice from the horns of the golden altar which is before God,",
                "saying to the sixth angel who had one trumpet, \"Free the four angels who are bound at the great river Euphrates!\"",
                "The four angels were freed who had been prepared for that hour and day and month and year, so that they might kill one third of mankind.",
                "The number of the armies of the horsemen was two hundred million. I heard the number of them.",
                "Thus I saw the horses in the vision, and those who sat on them, having breastplates of fiery red, hyacinth blue, and sulfur yellow; and the heads of lions. Out of their mouths proceed fire, smoke, and sulfur.",
                "By these three plagues were one third of mankind killed: by the fire, the smoke, and the sulfur, which proceeded out of their mouths.",
                "For the power of the horses is in their mouths, and in their tails. For their tails are like serpents, and have heads, and with them they harm.",
                "The rest of mankind, who were not killed with these plagues, didn\u2019t repent of the works of their hands, that they wouldn\u2019t worship demons, and the idols of gold, and of silver, and of brass, and of stone, and of wood; which can neither see, nor hear, nor walk.",
                "They didn\u2019t repent of their murders, nor of their sorceries, nor of their sexual immorality, nor of their thefts."
            ],
            10: [
                "I saw a mighty angel coming down out of the sky, clothed with a cloud. A rainbow was on his head. His face was like the sun, and his feet like pillars of fire.",
                "He had in his hand a little open book. He set his right foot on the sea, and his left on the land.",
                "He cried with a loud voice, as a lion roars. When he cried, the seven thunders uttered their voices.",
                "When the seven thunders sounded, I was about to write; but I heard a voice from the sky saying, \"Seal up the things which the seven thunders said, and don\u2019t write them.\"",
                "The angel whom I saw standing on the sea and on the land lifted up his right hand to the sky,",
                "and swore by him who lives forever and ever, who created heaven and the things that are in it, the earth and the things that are in it, and the sea and the things that are in it, that there will no longer be delay,",
                "but in the days of the voice of the seventh angel, when he is about to sound, then the mystery of God is finished, as he declared to his servants, the prophets.",
                "The voice which I heard from heaven, again speaking with me, said, \"Go, take the book which is open in the hand of the angel who stands on the sea and on the land.\"",
                "I went to the angel, telling him to give me the little book. He said to me, \"Take it, and eat it up. It will make your stomach bitter, but in your mouth it will be as sweet as honey.\"",
                "I took the little book out of the angel\u2019s hand, and ate it up. It was as sweet as honey in my mouth. When I had eaten it, my stomach was made bitter.",
                "They told me, \"You must prophesy again over many peoples, nations, languages, and kings.\""
            ],
            11: [
                "A reed like a rod was given to me. Someone said, \"Rise, and measure God\u2019s temple, and the altar, and those who worship in it.",
                "Leave out the court which is outside of the temple, and don\u2019t measure it, for it has been given to the nations. They will tread the holy city under foot for forty-two months.",
                "I will give power to my two witnesses, and they will prophesy one thousand two hundred sixty days, clothed in sackcloth.\"",
                "These are the two olive trees and the two lampstands, standing before the Lord of the earth.",
                "If anyone desires to harm them, fire proceeds out of their mouth and devours their enemies. If anyone desires to harm them, he must be killed in this way.",
                "These have the power to shut up the sky, that it may not rain during the days of their prophecy. They have power over the waters, to turn them into blood, and to strike the earth with every plague, as often as they desire.",
                "When they have finished their testimony, the beast that comes up out of the abyss will make war with them, and overcome them, and kill them.",
                "Their dead bodies will be in the street of the great city, which spiritually is called Sodom and Egypt, where also their Lord was crucified.",
                "From among the peoples, tribes, languages, and nations people will look at their dead bodies for three and a half days, and will not allow their dead bodies to be laid in a tomb.",
                "Those who dwell on the earth rejoice over them, and they will be glad. They will give gifts to one another, because these two prophets tormented those who dwell on the earth.",
                "After the three and a half days, the breath of life from God entered into them, and they stood on their feet. Great fear fell on those who saw them.",
                "I heard a loud voice from heaven saying to them, \"Come up here!\" They went up into heaven in the cloud, and their enemies saw them.",
                "In that day there was a great earthquake, and a tenth of the city fell. Seven thousand people were killed in the earthquake, and the rest were terrified, and gave glory to the God of heaven.",
                "The second woe is past. Behold, the third woe comes quickly.",
                "The seventh angel sounded, and great voices in heaven followed, saying, \"The kingdom of the world has become the Kingdom of our Lord, and of his Christ. He will reign forever and ever!\"",
                "The twenty-four elders, who sit on their thrones before God\u2019s throne, fell on their faces and worshiped God,",
                "saying: \"We give you thanks, Lord God, the Almighty, the one who is and who was; because you have taken your great power, and reigned.",
                "The nations were angry, and your wrath came, as did the time for the dead to be judged, and to give your bondservants the prophets, their reward, as well as to the saints, and those who fear your name, to the small and the great; and to destroy those who destroy the earth.\"",
                "God\u2019s temple that is in heaven was opened, and the ark of the Lord\u2019s covenant was seen in his temple. Lightnings, sounds, thunders, an earthquake, and great hail followed."
            ],
            12: [
                "A great sign was seen in heaven: a woman clothed with the sun, and the moon under her feet, and on her head a crown of twelve stars.",
                "She was with child. She cried out in pain, laboring to give birth.",
                "Another sign was seen in heaven. Behold, a great red dragon, having seven heads and ten horns, and on his heads seven crowns.",
                "His tail drew one third of the stars of the sky, and threw them to the earth. The dragon stood before the woman who was about to give birth, so that when she gave birth he might devour her child.",
                "She gave birth to a son, a male child, who is to rule all the nations with a rod of iron. Her child was caught up to God, and to his throne.",
                "The woman fled into the wilderness, where she has a place prepared by God, that there they may nourish her one thousand two hundred sixty days.",
                "There was war in the sky. Michael and his angels made war on the dragon. The dragon and his angels made war.",
                "They didn\u2019t prevail, neither was a place found for him any more in heaven.",
                "The great dragon was thrown down, the old serpent, he who is called the devil and Satan, the deceiver of the whole world. He was thrown down to the earth, and his angels were thrown down with him.",
                "I heard a loud voice in heaven, saying, \"Now the salvation, the power, and the Kingdom of our God, and the authority of his Christ has come; for the accuser of our brothers has been thrown down, who accuses them before our God day and night.",
                "They overcame him because of the Lamb\u2019s blood, and because of the word of their testimony. They didn\u2019t love their life, even to death.",
                "Therefore rejoice, heavens, and you who dwell in them. Woe to the earth and to the sea, because the devil has gone down to you, having great wrath, knowing that he has but a short time.\"",
                "When the dragon saw that he was thrown down to the earth, he persecuted the woman who gave birth to the male child.",
                "Two wings of the great eagle were given to the woman, that she might fly into the wilderness to her place, so that she might be nourished for a time, and times, and half a time, from the face of the serpent.",
                "The serpent spewed water out of his mouth after the woman like a river, that he might cause her to be carried away by the stream.",
                "The earth helped the woman, and the earth opened its mouth and swallowed up the river which the dragon spewed out of his mouth.",
                "The dragon grew angry with the woman, and went away to make war with the rest of her seed, who keep God\u2019s commandments and hold Jesus\u2019 testimony."
            ],
            13: [
                "Then I stood on the sand of the sea. I saw a beast coming up out of the sea, having ten horns and seven heads. On his horns were ten crowns, and on his heads, blasphemous names.",
                "The beast which I saw was like a leopard, and his feet were like those of a bear, and his mouth like the mouth of a lion. The dragon gave him his power, his throne, and great authority.",
                "One of his heads looked like it had been wounded fatally. His fatal wound was healed, and the whole earth marveled at the beast.",
                "They worshiped the dragon, because he gave his authority to the beast, and they worshiped the beast, saying, \"Who is like the beast? Who is able to make war with him?\"",
                "A mouth speaking great things and blasphemy was given to him. Authority to make war for forty-two months was given to him.",
                "He opened his mouth for blasphemy against God, to blaspheme his name, and his dwelling, those who dwell in heaven.",
                "It was given to him to make war with the saints, and to overcome them. Authority over every tribe, people, language, and nation was given to him.",
                "All who dwell on the earth will worship him, everyone whose name has not been written from the foundation of the world in the book of life of the Lamb who has been killed.",
                "If anyone has an ear, let him hear.",
                "If anyone is to go into captivity, he will go into captivity. If anyone is to be killed with the sword, he must be killed. Here is the endurance and the faith of the saints.",
                "I saw another beast coming up out of the earth. He had two horns like a lamb, and he spoke like a dragon.",
                "He exercises all the authority of the first beast in his presence. He makes the earth and those who dwell in it to worship the first beast, whose fatal wound was healed.",
                "He performs great signs, even making fire come down out of the sky to the earth in the sight of people.",
                "He deceives my own people who dwell on the earth because of the signs he was granted to do in front of the beast; saying to those who dwell on the earth, that they should make an image to the beast who had the sword wound and lived.",
                "It was given to him to give breath to it, to the image of the beast, that the image of the beast should both speak, and cause as many as wouldn\u2019t worship the image of the beast to be killed.",
                "He causes all, the small and the great, the rich and the poor, and the free and the slave, to be given marks on their right hands, or on their foreheads;",
                "and that no one would be able to buy or to sell, unless he has that mark, the name of the beast or the number of his name.",
                "Here is wisdom. He who has understanding, let him calculate the number of the beast, for it is the number of a man. His number is six hundred sixty-six."
            ],
            14: [
                "I saw, and behold, the Lamb standing on Mount Zion, and with him a number, one hundred forty-four thousand, having his name, and the name of his Father, written on their foreheads.",
                "I heard a sound from heaven, like the sound of many waters, and like the sound of a great thunder. The sound which I heard was like that of harpists playing on their harps.",
                "They sing a new song before the throne, and before the four living creatures and the elders. No one could learn the song except the one hundred forty-four thousand, those who had been redeemed out of the earth.",
                "These are those who were not defiled with women, for they are virgins. These are those who follow the Lamb wherever he goes. These were redeemed by Jesus from among men, the first fruits to God and to the Lamb.",
                "In their mouth was found no lie, for they are blameless.",
                "I saw an angel flying in mid heaven, having an eternal Good News to proclaim to those who dwell on the earth, and to every nation, tribe, language, and people.",
                "He said with a loud voice, \"Fear the Lord, and give him glory; for the hour of his judgment has come. Worship him who made the heaven, the earth, the sea, and the springs of waters!\"",
                "Another, a second angel, followed, saying, \"Babylon the great has fallen, which has made all the nations to drink of the wine of the wrath of her sexual immorality.\"",
                "Another angel, a third, followed them, saying with a great voice, \"If anyone worships the beast and his image, and receives a mark on his forehead, or on his hand,",
                "he also will drink of the wine of the wrath of God, which is prepared unmixed in the cup of his anger. He will be tormented with fire and sulfur in the presence of the holy angels, and in the presence of the Lamb.",
                "The smoke of their torment goes up forever and ever. They have no rest day and night, those who worship the beast and his image, and whoever receives the mark of his name.",
                "Here is the patience of the saints, those who keep the commandments of God, and the faith of Jesus.\"",
                "I heard the voice from heaven saying, \"Write, \u2018Blessed are the dead who die in the Lord from now on.\u2019\" \"Yes,\" says the Spirit, \"that they may rest from their labors; for their works follow with them.\"",
                "I looked, and behold, a white cloud; and on the cloud one sitting like a son of man, having on his head a golden crown, and in his hand a sharp sickle.",
                "Another angel came out from the temple, crying with a loud voice to him who sat on the cloud, \"Send forth your sickle, and reap; for the hour to reap has come; for the harvest of the earth is ripe!\"",
                "He who sat on the cloud thrust his sickle on the earth, and the earth was reaped.",
                "Another angel came out from the temple which is in heaven. He also had a sharp sickle.",
                "Another angel came out from the altar, he who has power over fire, and he called with a great voice to him who had the sharp sickle, saying, \"Send forth your sharp sickle, and gather the clusters of the vine of the earth, for the earth\u2019s grapes are fully ripe!\"",
                "The angel thrust his sickle into the earth, and gathered the vintage of the earth, and threw it into the great winepress of the wrath of God.",
                "The winepress was trodden outside of the city, and blood came out from the winepress, even to the bridles of the horses, as far as one thousand six hundred stadia."
            ],
            15: [
                "I saw another great and marvelous sign in the sky: seven angels having the seven last plagues, for in them God\u2019s wrath is finished.",
                "I saw something like a sea of glass mixed with fire, and those who overcame the beast, his image, and the number of his name, standing on the sea of glass, having harps of God.",
                "They sang the song of Moses, the servant of God, and the song of the Lamb, saying, \"Great and marvelous are your works, Lord God, the Almighty! Righteous and true are your ways, you King of the nations.",
                "Who wouldn\u2019t fear you, Lord, and glorify your name? For you only are holy. For all the nations will come and worship before you. For your righteous acts have been revealed.\"",
                "After these things I looked, and the temple of the tabernacle of the testimony in heaven was opened.",
                "The seven angels who had the seven plagues came out, clothed with pure, bright linen, and wearing golden sashes around their breasts.",
                "One of the four living creatures gave to the seven angels seven golden bowls full of the wrath of God, who lives forever and ever.",
                "The temple was filled with smoke from the glory of God, and from his power. No one was able to enter into the temple, until the seven plagues of the seven angels would be finished."
            ],
            16: [
                "I heard a loud voice out of the temple, saying to the seven angels, \"Go and pour out the seven bowls of the wrath of God on the earth!\"",
                "The first went, and poured out his bowl into the earth, and it became a harmful and evil sore on the people who had the mark of the beast, and who worshiped his image.",
                "The second angel poured out his bowl into the sea, and it became blood as of a dead man. Every living thing in the sea died.",
                "The third poured out his bowl into the rivers and springs of water, and they became blood.",
                "I heard the angel of the waters saying, \"You are righteous, who are and who were, you Holy One, because you have judged these things.",
                "For they poured out the blood of the saints and the prophets, and you have given them blood to drink. They deserve this.\"",
                "I heard the altar saying, \"Yes, Lord God, the Almighty, true and righteous are your judgments.\"",
                "The fourth poured out his bowl on the sun, and it was given to him to scorch men with fire.",
                "People were scorched with great heat, and people blasphemed the name of God who has the power over these plagues. They didn\u2019t repent and give him glory.",
                "The fifth poured out his bowl on the throne of the beast, and his kingdom was darkened. They gnawed their tongues because of the pain,",
                "and they blasphemed the God of heaven because of their pains and their sores. They didn\u2019t repent of their works.",
                "The sixth poured out his bowl on the great river, the Euphrates. Its water was dried up, that the way might be prepared for the kings that come from the sunrise.",
                "I saw coming out of the mouth of the dragon, and out of the mouth of the beast, and out of the mouth of the false prophet, three unclean spirits, something like frogs;",
                "for they are spirits of demons, performing signs; which go forth to the kings of the whole inhabited earth, to gather them together for the war of that great day of God, the Almighty.",
                "\"Behold, I come like a thief. Blessed is he who watches, and keeps his clothes, so that he doesn\u2019t walk naked, and they see his shame.\"",
                "He gathered them together into the place which is called in Hebrew, Megiddo.",
                "The seventh poured out his bowl into the air. A loud voice came forth out of the temple of heaven, from the throne, saying, \"It is done!\"",
                "There were lightnings, sounds, and thunders; and there was a great earthquake, such as was not since there were men on the earth, so great an earthquake, so mighty.",
                "The great city was divided into three parts, and the cities of the nations fell. Babylon the great was remembered in the sight of God, to give to her the cup of the wine of the fierceness of his wrath.",
                "Every island fled away, and the mountains were not found.",
                "Great hailstones, about the weight of a talent, came down out of the sky on people. People blasphemed God because of the plague of the hail, for this plague is exceedingly severe."
            ],
            17: [
                "One of the seven angels who had the seven bowls came and spoke with me, saying, \"Come here. I will show you the judgment of the great prostitute who sits on many waters,",
                "with whom the kings of the earth committed sexual immorality, and those who dwell in the earth were made drunken with the wine of her sexual immorality.\"",
                "He carried me away in the Spirit into a wilderness. I saw a woman sitting on a scarlet-colored animal, full of blasphemous names, having seven heads and ten horns.",
                "The woman was dressed in purple and scarlet, and decked with gold and precious stones and pearls, having in her hand a golden cup full of abominations and the impurities of the sexual immorality of the earth.",
                "And on her forehead a name was written, \"MYSTERY, BABYLON THE GREAT, THE MOTHER OF THE PROSTITUTES AND OF THE ABOMINATIONS OF THE EARTH.\"",
                "I saw the woman drunken with the blood of the saints, and with the blood of the martyrs of Jesus. When I saw her, I wondered with great amazement.",
                "The angel said to me, \"Why do you wonder? I will tell you the mystery of the woman, and of the beast that carries her, which has the seven heads and the ten horns.",
                "The beast that you saw was, and is not; and is about to come up out of the abyss and to go into destruction. Those who dwell on the earth and whose names have not been written in the book of life from the foundation of the world will marvel when they see that the beast was, and is not, and shall be present.",
                "Here is the mind that has wisdom. The seven heads are seven mountains, on which the woman sits.",
                "They are seven kings. Five have fallen, the one is, the other has not yet come. When he comes, he must continue a little while.",
                "The beast that was, and is not, is himself also an eighth, and is of the seven; and he goes to destruction.",
                "The ten horns that you saw are ten kings who have received no kingdom as yet, but they receive authority as kings, with the beast, for one hour.",
                "These have one mind, and they give their power and authority to the beast.",
                "These will war against the Lamb, and the Lamb will overcome them, for he is Lord of lords, and King of kings. They also will overcome who are with him, called and chosen and faithful.\"",
                "He said to me, \"The waters which you saw, where the prostitute sits, are peoples, multitudes, nations, and languages.",
                "The ten horns which you saw, and the beast, these will hate the prostitute, and will make her desolate, and will make her naked, and will eat her flesh, and will burn her utterly with fire.",
                "For God has put in their hearts to do what he has in mind, and to be of one mind, and to give their kingdom to the beast, until the words of God should be accomplished.",
                "The woman whom you saw is the great city, which reigns over the kings of the earth.\""
            ],
            18: [
                "After these things, I saw another angel coming down out of the sky, having great authority. The earth was illuminated with his glory.",
                "He cried with a mighty voice, saying, \"Fallen, fallen is Babylon the great, and she has become a habitation of demons, a prison of every unclean spirit, and a prison of every unclean and hateful bird!",
                "For all the nations have drunk of the wine of the wrath of her sexual immorality, the kings of the earth committed sexual immorality with her, and the merchants of the earth grew rich from the abundance of her luxury.\"",
                "I heard another voice from heaven, saying, \"Come out of her, my people, that you have no participation in her sins, and that you don\u2019t receive of her plagues,",
                "for her sins have reached to the sky, and God has remembered her iniquities.",
                "Return to her just as she returned, and repay her double as she did, and according to her works. In the cup which she mixed, mix to her double.",
                "However much she glorified herself, and grew wanton, so much give her of torment and mourning. For she says in her heart, \u2018I sit a queen, and am no widow, and will in no way see mourning.\u2019",
                "Therefore in one day her plagues will come: death, mourning, and famine; and she will be utterly burned with fire; for the Lord God who has judged her is strong.",
                "The kings of the earth, who committed sexual immorality and lived wantonly with her, will weep and wail over her, when they look at the smoke of her burning,",
                "standing far away for the fear of her torment, saying, \u2018Woe, woe, the great city, Babylon, the strong city! For your judgment has come in one hour.\u2019",
                "The merchants of the earth weep and mourn over her, for no one buys their merchandise any more;",
                "merchandise of gold, silver, precious stones, pearls, fine linen, purple, silk, scarlet, all expensive wood, every vessel of ivory, every vessel made of most precious wood, and of brass, and iron, and marble;",
                "and cinnamon, incense, perfume, frankincense, wine, olive oil, fine flour, wheat, sheep, horses, chariots, and people\u2019s bodies and souls.",
                "The fruits which your soul lusted after have been lost to you, and all things that were dainty and sumptuous have perished from you, and you will find them no more at all.",
                "The merchants of these things, who were made rich by her, will stand far away for the fear of her torment, weeping and mourning;",
                "saying, \u2018Woe, woe, the great city, she who was dressed in fine linen, purple, and scarlet, and decked with gold and precious stones and pearls!",
                "For in an hour such great riches are made desolate.\u2019 Every shipmaster, and everyone who sails anywhere, and mariners, and as many as gain their living by sea, stood far away,",
                "and cried out as they looked at the smoke of her burning, saying, \u2018What is like the great city?\u2019",
                "They cast dust on their heads, and cried, weeping and mourning, saying, \u2018Woe, woe, the great city, in which all who had their ships in the sea were made rich by reason of her great wealth!\u2019 For in one hour is she made desolate.",
                "\"Rejoice over her, O heaven, you saints, apostles, and prophets; for God has judged your judgment on her.\"",
                "A mighty angel took up a stone like a great millstone and cast it into the sea, saying, \"Thus with violence will Babylon, the great city, be thrown down, and will be found no more at all.",
                "The voice of harpists, minstrels, flute players, and trumpeters will be heard no more at all in you. No craftsman, of whatever craft, will be found any more at all in you. The sound of a mill will be heard no more at all in you.",
                "The light of a lamp will shine no more at all in you. The voice of the bridegroom and of the bride will be heard no more at all in you; for your merchants were the princes of the earth; for with your sorcery all the nations were deceived.",
                "In her was found the blood of prophets and of saints, and of all who have been slain on the earth.\""
            ],
            19: [
                "After these things I heard something like a loud voice of a great multitude in heaven, saying, \"Hallelujah! Salvation, power, and glory belong to our God:",
                "for true and righteous are his judgments. For he has judged the great prostitute, who corrupted the earth with her sexual immorality, and he has avenged the blood of his servants at her hand.\"",
                "A second said, \"Hallelujah! Her smoke goes up forever and ever.\"",
                "The twenty-four elders and the four living creatures fell down and worshiped God who sits on the throne, saying, \"Amen! Hallelujah!\"",
                "A voice came forth from the throne, saying, \"Give praise to our God, all you his servants, you who fear him, the small and the great!\"",
                "I heard something like the voice of a great multitude, and like the voice of many waters, and like the voice of mighty thunders, saying, \"Hallelujah! For the Lord our God, the Almighty, reigns!",
                "Let us rejoice and be exceedingly glad, and let us give the glory to him. For the marriage of the Lamb has come, and his wife has made herself ready.\"",
                "It was given to her that she would array herself in bright, pure, fine linen: for the fine linen is the righteous acts of the saints.",
                "He said to me, \"Write, \u2018Blessed are those who are invited to the marriage supper of the Lamb.\u2019\" He said to me, \"These are true words of God.\"",
                "I fell down before his feet to worship him. He said to me, \"Look! Don\u2019t do it! I am a fellow bondservant with you and with your brothers who hold the testimony of Jesus. Worship God, for the testimony of Jesus is the Spirit of Prophecy.\"",
                "I saw the heaven opened, and behold, a white horse, and he who sat on it is called Faithful and True. In righteousness he judges and makes war.",
                "His eyes are a flame of fire, and on his head are many crowns. He has names written and a name written which no one knows but he himself.",
                "He is clothed in a garment sprinkled with blood. His name is called \"The Word of God.\"",
                "The armies which are in heaven followed him on white horses, clothed in white, pure, fine linen.",
                "Out of his mouth proceeds a sharp, double-edged sword, that with it he should strike the nations. He will rule them with an iron rod. He treads the winepress of the fierceness of the wrath of God, the Almighty.",
                "He has on his garment and on his thigh a name written, \"KING OF KINGS, AND LORD OF LORDS.\"",
                "I saw an angel standing in the sun. He cried with a loud voice, saying to all the birds that fly in the sky, \"Come! Be gathered together to the great supper of God,",
                "that you may eat the flesh of kings, the flesh of captains, the flesh of mighty men, and the flesh of horses and of those who sit on them, and the flesh of all men, both free and slave, and small and great.\"",
                "I saw the beast, and the kings of the earth, and their armies, gathered together to make war against him who sat on the horse, and against his army.",
                "The beast was taken, and with him the false prophet who worked the signs in his sight, with which he deceived those who had received the mark of the beast and those who worshiped his image. These two were thrown alive into the lake of fire that burns with sulfur.",
                "The rest were killed with the sword of him who sat on the horse, the sword which came forth out of his mouth. All the birds were filled with their flesh."
            ],
            20: [
                "I saw an angel coming down out of heaven, having the key of the abyss and a great chain in his hand.",
                "He seized the dragon, the old serpent, which is the devil and Satan, who deceives the whole inhabited earth, and bound him for a thousand years,",
                "and cast him into the abyss, and shut it, and sealed it over him, that he should deceive the nations no more, until the thousand years were finished. After this, he must be freed for a short time.",
                "I saw thrones, and they sat on them, and judgment was given to them. I saw the souls of those who had been beheaded for the testimony of Jesus, and for the word of God, and such as didn\u2019t worship the beast nor his image, and didn\u2019t receive the mark on their forehead and on their hand. They lived, and reigned with Christ for a thousand years.",
                "The rest of the dead didn\u2019t live until the thousand years were finished. This is the first resurrection.",
                "Blessed and holy is he who has part in the first resurrection. Over these, the second death has no power, but they will be priests of God and of Christ, and will reign with him one thousand years.",
                "And after the thousand years, Satan will be released from his prison,",
                "and he will come out to deceive the nations which are in the four corners of the earth, Gog and Magog, to gather them together to the war; the number of whom is as the sand of the sea.",
                "They went up over the breadth of the earth, and surrounded the camp of the saints, and the beloved city. Fire came down out of heaven from God, and devoured them.",
                "The devil who deceived them was thrown into the lake of fire and sulfur, where the beast and the false prophet are also. They will be tormented day and night forever and ever.",
                "I saw a great white throne, and him who sat on it, from whose face the earth and the heaven fled away. There was found no place for them.",
                "I saw the dead, the great and the small, standing before the throne, and they opened books. Another book was opened, which is the book of life. The dead were judged out of the things which were written in the books, according to their works.",
                "The sea gave up the dead who were in it. Death and Hades gave up the dead who were in them. They were judged, each one according to his works.",
                "Death and Hades were thrown into the lake of fire. This is the second death, the lake of fire.",
                "If anyone was not found written in the book of life, he was cast into the lake of fire."
            ],
            21: [
                "I saw a new heaven and a new earth: for the first heaven and the first earth have passed away, and the sea is no more.",
                "I saw the holy city, New Jerusalem, coming down out of heaven from God, prepared like a bride adorned for her husband.",
                "I heard a loud voice out of heaven saying, \"Behold, God\u2019s dwelling is with people, and he will dwell with them, and they will be his people, and God himself will be with them as their God.",
                "He will wipe away from them every tear from their eyes. Death will be no more; neither will there be mourning, nor crying, nor pain, any more. The first things have passed away.\"",
                "He who sits on the throne said, \"Behold, I am making all things new.\" He said, \"Write, for these words of God are faithful and true.\"",
                "He said to me, \"It is done! I am the Alpha and the Omega, the Beginning and the End. I will give freely to him who is thirsty from the spring of the water of life.",
                "He who overcomes, I will give him these things. I will be his God, and he will be my son.",
                "But for the cowardly, unbelieving, sinners, abominable, murderers, sexually immoral, sorcerers, idolaters, and all liars, their part is in the lake that burns with fire and sulfur, which is the second death.\"",
                "One of the seven angels who had the seven bowls, who were loaded with the seven last plagues came, and he spoke with me, saying, \"Come here. I will show you the wife, the Lamb\u2019s bride.\"",
                "He carried me away in the Spirit to a great and high mountain, and showed me the holy city, Jerusalem, coming down out of heaven from God,",
                "having the glory of God. Her light was like a most precious stone, as if it were a jasper stone, clear as crystal;",
                "having a great and high wall; having twelve gates, and at the gates twelve angels; and names written on them, which are the names of the twelve tribes of the children of Israel.",
                "On the east were three gates; and on the north three gates; and on the south three gates; and on the west three gates.",
                "The wall of the city had twelve foundations, and on them twelve names of the twelve Apostles of the Lamb.",
                "He who spoke with me had for a measure, a golden reed, to measure the city, its gates, and its walls.",
                "The city lies foursquare, and its length is as great as its breadth. He measured the city with the reed, Twelve thousand twelve stadia. Its length, breadth, and height are equal.",
                "Its wall is one hundred forty-four cubits, by the measure of a man, that is, of an angel.",
                "The construction of its wall was jasper. The city was pure gold, like pure glass.",
                "The foundations of the city\u2019s wall were adorned with all kinds of precious stones. The first foundation was jasper; the second, sapphire; the third, chalcedony; the fourth, emerald;",
                "the fifth, sardonyx; the sixth, sardius; the seventh, chrysolite; the eighth, beryl; the ninth, topaz; the tenth, chrysoprasus; the eleventh, jacinth; and the twelfth, amethyst.",
                "The twelve gates were twelve pearls. Each one of the gates was made of one pearl. The street of the city was pure gold, like transparent glass.",
                "I saw no temple in it, for the Lord God, the Almighty, and the Lamb, are its temple.",
                "The city has no need for the sun, neither of the moon, to shine, for the very glory of God illuminated it, and its lamp is the Lamb.",
                "The nations will walk in its light. The kings of the earth bring the glory and honor of the nations into it.",
                "Its gates will in no way be shut by day (for there will be no night there),",
                "and they shall bring the glory and the honor of the nations into it so that they may enter.",
                "There will in no way enter into it anything profane, or one who causes an abomination or a lie, but only those who are written in the Lamb\u2019s book of life."
            ],
            22: [
                "He showed me a river of water of life, clear as crystal, proceeding out of the throne of God and of the Lamb,",
                "in the middle of its street. On this side of the river and on that was the tree of life, bearing twelve kinds of fruits, yielding its fruit every month. The leaves of the tree were for the healing of the nations.",
                "There will be no curse any more. The throne of God and of the Lamb will be in it, and his servants serve him.",
                "They will see his face, and his name will be on their foreheads.",
                "There will be no night, and they need no lamp light; for the Lord God will illuminate them. They will reign forever and ever.",
                "He said to me, \"These words are faithful and true. The Lord God of the spirits of the prophets sent his angel to show to his bondservants the things which must happen soon.\"",
                "\"Behold, I come quickly. Blessed is he who keeps the words of the prophecy of this book.\"",
                "Now I, John, am the one who heard and saw these things. When I heard and saw, I fell down to worship before the feet of the angel who had shown me these things.",
                "He said to me, \"See you don\u2019t do it! I am a fellow bondservant with you and with your brothers, the prophets, and with those who keep the words of this book. Worship God.\"",
                "He said to me, \"Don\u2019t seal up the words of the prophecy of this book, for the time is at hand.",
                "He who acts unjustly, let him act unjustly still. He who is filthy, let him be filthy still. He who is righteous, let him do righteousness still. He who is holy, let him be holy still.\"",
                "\"Behold, I come quickly. My reward is with me, to repay to each man according to his work.",
                "I am the Alpha and the Omega, the First and the Last, the Beginning and the End.",
                "Blessed are those who do his commandments, that they may have the right to the tree of life, and may enter in by the gates into the city.",
                "Outside are the dogs, the sorcerers, the sexually immoral, the murderers, the idolaters, and everyone who loves and practices falsehood.",
                "I, Jesus, have sent my angel to testify these things to you for the assemblies. I am the root and the offspring of David; the Bright and Morning Star.\"",
                "The Spirit and the bride say, \"Come!\" He who hears, let him say, \"Come!\" He who is thirsty, let him come. He who desires, let him take the water of life freely.",
                "I testify to everyone who hears the words of the prophecy of this book, if anyone adds to them, may God add to him the plagues which are written in this book.",
                "If anyone takes away from the words of the book of this prophecy, may God take away his part from the tree of life, and out of the holy city, which are written in this book.",
                "He who testifies these things says, \"Yes, I come quickly.\" Amen! Yes, come, Lord Jesus.",
                "The grace of the Lord Jesus Christ be with all the saints. Amen."
            ]
        }
    }];

}

export default WEBFullText;
